import { useState } from 'react';
import axios from 'axios';

/**
 * Хук запроса к серверу
 * Добавлен в рамках BCW-2543 из-за удаления useFetchData из utils/hooks/fetch.js
 */
export const useFetchData = (url) => {
  const [result, setResult] = useState(null);

  useState(() => {
    axios
      .get(url)
      .then((resp) => {
        setResult(resp.data);
      })
      .catch((e) => console.log(e));
  });

  return result;
};
