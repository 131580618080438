import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TWrapper = {
  children: ReactNode;
};

/** Обертка попапа */
export const Wrapper: FC<TWrapper> = ({ children }) => (
  <div className={cx('component')}>{children}</div>
);
