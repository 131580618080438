// Примерная высота одного параметра;
const PARAM_HEIGHT = 26;
// Примерная высота одного заголовка;
const TITLE_HEIGHT = 55;
// Высота отступа;
const INDENT = 8;
/** Расчитываем высоту среднего блока (с additionalParams) карточки для отрисовки горизонта бордюров; */
export const getAdditionalHeight = (tariffList) => {
  const maxLength = tariffList.reduce((acc, curr) => {
    if (curr.additionalParameters) {
      const leng = curr.additionalParameters.length;
      if (leng > acc) {
        acc = leng;
      }
    }
    return acc;
  }, 0);

  return maxLength * (PARAM_HEIGHT + INDENT) + TITLE_HEIGHT;
};

export const getIconStaticUrl = (iconName) => {
  let icon = `//static.beeline.ru/upload/images/icons/alive-ruler-icons/${iconName}.svg`;
  // считаем, что это не alias и показываем как есть
  if (iconName.includes('.') || iconName.includes('/')) {
    icon = iconName;
  }

  return {
    iconName,
    icon,
  };
};

/** Вытаскивает тарифы из групп превращая список в плоский */
const getFlatTariffsList = (tariffsGroup) =>
  tariffsGroup.reduce((arr, curr) => {
    arr.push(...curr.entries);
    return arr;
  }, []);

/** Функция вычисляет высоту блока со скидкой */
export const getHeight = (tariffsGroup) => {
  const tariffsArray = getFlatTariffsList(tariffsGroup);
  const priceHeight = tariffsArray.some(
    (item) =>
      ((item.discount || {}).priceWithoutDiscount && (item.discount || {}).label) ||
      (item.priceDescription || {}).showDiscount,
  );

  return priceHeight ? 100 : 60;
};

/** Проверяет наличие eSim в тарифах */
export const getIsUpperBarTariff = (tariffsGroup) => {
  const tariffsArray = getFlatTariffsList(tariffsGroup);
  return tariffsArray.some((item) => item.forEsim || item.isConnected);
};

export const getInfinitySymbol = (params, cardStyle) =>
  params && params.match(/infinitySymbol/) && `${cardStyle ? 'white' : 'black'}-infinity`;

export const getLabelSymbol = (priceData) => {
  const symbol = priceData.value.match(/infinitySymbol|presentSymbol/);
  return symbol && symbol.input.replace('∞', '');
};
