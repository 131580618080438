import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { TextInput } from '@beef/legacy-ui-kit';
import { Button } from '@beef/ui-kit';

import { ymSimFavoriteNumbers } from 'utils/analytics/simOrder';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class FavoriteNumber extends Component {
  state = {
    value: '',
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit(this.props.extData.method || 'favourite', `?number=${this.state.value}`);
    ymSimFavoriteNumbers();
  };

  handleNumberChange = (value) => this.setState({ value });

  isValueValid = () => {
    const maxDigits = this.props.extData.maxDigits || 4;
    const minDigits = this.props.extData.minDigits || 1;
    const { value } = this.state;
    return value.length >= minDigits && value.length <= maxDigits;
  };

  render() {
    const mask = Array(this.props.extData.maxDigits + 1 || 4).join('_');

    return (
      <form className={cx('form')} onSubmit={this.handleSubmit}>
        <TextInput
          className={cx('input')}
          clearable
          mask={mask}
          onChange={this.handleNumberChange}
          size="big"
        />
        <Button disabled={!this.isValueValid()} size="l" type="submit" variant="main">
          Подобрать
        </Button>
      </form>
    );
  }
}

FavoriteNumber.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  extData: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default FavoriteNumber;
