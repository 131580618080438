import { pushYm } from '../../../tempAnalytics/metricUtils';

type TOptionType = 'option' | 'service';

const optionNameType = {
  option: 'option_name',
  service: 'service_name',
};

enum EBlockOptionTypes {
  'delete',
  'choose',
}

/** Ф-я отправки параметров для клика по блоку пассивок */
export const sendYm = (name: string, isActive: boolean, type: TOptionType) => {
  pushYm({
    /* В зависимости от type, на основе isActive формируем строку block_options. Если например
     опция включена (isActive), то в строку добавляем delete из EBlockOptionTypes,
     т.к. опция отключается пользователем */
    block_options: `${EBlockOptionTypes[Number(!isActive)]}_${type}`,
    [optionNameType[type]]: name,
  });
};
