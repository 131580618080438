import { getHasBrowserWindowAPI } from 'utils/window';

import { ymPushParams } from '../ym';

export const getLocationPath = () => (getHasBrowserWindowAPI() ? window.location.pathname : '');

export const notificationYM = (text, buttonText, notificationKey, url = '') => {
  ymPushParams({
    [`LP_${getLocationPath()}`]: { [notificationKey]: { [text]: { [buttonText]: url } } },
  });
};
