import React, { FC } from 'react';
import { Button, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { BaseStatusProps } from 'pages/Services/RedirectServiceCardLanding/components/Status/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const BaseStatus: FC<BaseStatusProps> = ({
  img,
  title,
  description,
  buttons,
  onClickMain,
  onClickCancel,
  hideButtons,
}) => (
  <div>
    <img alt="status" src={img?.url} width="192" />
    <Text className={cx('title')} size="size2-m">
      {title}
    </Text>
    <Text size="size5-r-s">{description}</Text>
    {!hideButtons && (
      <div className={cx('actions')}>
        <Button onClick={onClickMain}>{buttons?.main}</Button>
        {buttons?.cancel && onClickCancel && (
          <Button onClick={onClickCancel}>{buttons?.cancel}</Button>
        )}
      </div>
    )}
  </div>
);
