import React from 'react';
import classnames from 'classnames/bind';
import { Button, Text } from '@beef/ui-kit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { selectContent } from '../../store/selectors';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const StoryCardLayout = ({
  image,
  title,
  text,
  buttonText,
  onClick,
  onImageClick,
  onButtonClick,
}) => (
  <div className={cx('card')} onClick={onClick}>
    <div>
      <div onClick={onImageClick}>
        <img alt="" className={cx('image', 'mar-b-m')} src={image} />
      </div>
      <Text size="size4-m">{title}</Text>
      {text && (
        <div className={cx('mar-t-xs')}>
          <Text color="grey60" size="size5-r-l">
            {text}
          </Text>
        </div>
      )}
    </div>
    {buttonText && (
      <div className={cx('button')}>
        <Button onClick={onButtonClick} variant="tertiary">
          {buttonText}
        </Button>
      </div>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  buttonText: selectContent(state).storiesList.openButton,
});

StoryCardLayout.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

export const StoryCard = connect(mapStateToProps)(StoryCardLayout);
