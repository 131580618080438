import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';

import { getArticles, getIsArticles } from 'pages/SearchResultsPage/store/selectors';
import { ArticleWithAnalytics } from 'pages/SearchResultsPage/components/ArticlesList/ArticleWithAnalytics';

import { Article } from './Article';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _ArticlesList = ({ articles }) => (
  <ul className={cx('articles')}>
    {articles.map((article) => (
      <ArticleWithAnalytics>
        <Article
          annotation={article.annotation}
          key={article.link}
          link={article.link}
          title={article.title}
        />
      </ArticleWithAnalytics>
    ))}
  </ul>
);

_ArticlesList.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      annotation: PropTypes.string,
    }),
  ),
};

const mapStateToProps = (state) => ({
  articles: getArticles(state),
  shouldRender: getIsArticles(state),
});

export const ArticlesList = compose(connect(mapStateToProps), withRenderFlag)(_ArticlesList);
