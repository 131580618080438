import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { PhoneInput } from '@beef/ui-kit';

import { checkIsValid } from 'pages/Login/Authorization2021/utils/ctn-string';

import styles from './styles.pcss';
import { YMAuthEvent } from '../../utils/ym';

const cx = classNames.bind(styles);

export const MobileIdInitialForm = ({
  data,
  loading,
  clickMobileIdAuth,
  setAuthCtn,
  serviceError,
  ctn,
  autofocus,
  handleInput,
  formId,
}) => {
  const [value, setValue] = useState(ctn);
  const [error, setError] = useState('');

  useEffect(() => {
    handleInput(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
    /* бросаем наверх вэлью чтобы в экшнбаре модалки кнопка могла дизэйблиться когда инпут не заполнен */
    handleInput(event.target.value);
    setAuthCtn(event.target.value);
    const validatedCtn = checkIsValid(event.target.value);
    if (validatedCtn) {
      YMAuthEvent.mobileIdInitialCtnFill();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validatedCtn = checkIsValid(value);
    if (!validatedCtn) {
      const errorMessage = 'Неправильный формат номера телефона';
      YMAuthEvent.error('mob_id', errorMessage);
      setError(errorMessage);
    }

    if (validatedCtn) {
      setError('');
      clickMobileIdAuth();
      handleInput('');
    }
  };

  const handleFocus = () => {
    YMAuthEvent.mobileIdInitialInputClick();
    setError('');
  };

  return (
    <div className={cx('mobile-id-initial-form')}>
      <form id={formId} onSubmit={handleSubmit}>
        <PhoneInput
          className={cx('input-wrapper')}
          disabled={loading}
          errorMessage={error || serviceError}
          extraAttributes={
            autofocus ?
              {
                autoFocus: true,
              }
            : null
          }
          label={data.label}
          maskSettings={{
            mask: '_',
            format: '+7 ### ###-##-##',
          }}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={data.placeholder}
          /* type='' костыль для легаси страниц  */
          type=""
          value={value}
        />
      </form>
    </div>
  );
};

MobileIdInitialForm.propTypes = {
  serviceError: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  setAuthCtn: PropTypes.func,
  autofocus: PropTypes.bool,
  handleInput: PropTypes.func,
  formId: PropTypes.string,
  loading: PropTypes.bool,
  clickMobileIdAuth: PropTypes.func,
  ctn: PropTypes.string,
};
