import React, { FC } from 'react';
import { Tag, Tooltip } from '@beef/ui-kit';
import { useIsDesktopDimension } from '@beef/ui-kit/hooks';

import { PromotionTagPropsType } from './types';

export const PromotionTag: FC<PromotionTagPropsType> = ({
  tagText,
  tagSize = 'xs',
  tooltip,
  className,
}) => {
  const isDesktop = useIsDesktopDimension();
  const renderTag = () => {
    const tagComponent = <Tag size={tagSize}>{tagText}</Tag>;
    return tooltip && isDesktop ?
        <Tooltip
          buttonConfig={tooltip.buttonConfig}
          maxWidth="174"
          message={tooltip.message}
          position="top"
        >
          {tagComponent}
        </Tooltip>
      : tagComponent;
  };
  return <div className={className}>{renderTag()}</div>;
};
