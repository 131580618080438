import React from 'react';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';
import { compose } from '@beef/redux';

import { PromotionBannerPropsType } from 'pages/FancyNumber2022/components/PromotionBanner/types';

import { getPromotionBanner } from '../../store/selectors/content';
import { getIsChangeNumber } from '../../store/selectors/change';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const _PromotionBanner = ({ title, description, img, link }: PromotionBannerPropsType) => (
  <a className={cx('wrapper')} href={link}>
    <div className={cx('content')}>
      {title && (
        <Text className={cx('title')} size="size4-m">
          {title}
        </Text>
      )}
      {description && (
        <Text className={cx('description')} size="size6-r">
          {description}
        </Text>
      )}
    </div>
    {img && (
      <div className={cx('img-wrapper')}>
        <img alt={img.alt} className={cx('img')} src={img.src} />
      </div>
    )}
  </a>
);

const mapStateToProps = (state) => ({
  title: getPromotionBanner(state)?.title,
  description: getPromotionBanner(state)?.description,
  img: getPromotionBanner(state)?.img,
  link: getPromotionBanner(state)?.link,
  shouldRender: !!getPromotionBanner(state)?.title && !getIsChangeNumber(state),
});

export const PromotionBanner = compose(connect(mapStateToProps), withRenderFlag)(_PromotionBanner);
