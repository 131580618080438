import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@beef/redux';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';
import classNames from 'classnames/bind';

import {
  getFilters,
  getMaxCountToOpenFilter,
  getSelectedFilters,
} from 'pages/SearchResultsPage/store/selectors';
import { FILTER_NAMES, TEXT_BRAND } from 'pages/SearchResultsPage/utils/constsFilters';

import { FiltersBlock } from './FiltersBlock';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _FiltersMapper = ({ filters, name, withoutWrapper, maxInitialOpened, selectedFilters }) => {
  const filterName = name === TEXT_BRAND ? FILTER_NAMES.brands : name;

  return (
    <div className={cx('container')}>
      {name && (
        <FiltersBlock
          filters={filters[filterName]}
          name={filterName}
          withoutWrapper={withoutWrapper}
        />
      )}
      {!name &&
        Object.keys(filters).map((key, index) => (
          <FiltersBlock
            filters={filters[key]}
            isOpened={index < maxInitialOpened || !!selectedFilters[key]}
            key={key}
            name={key}
          />
        ))}
    </div>
  );
};

_FiltersMapper.propTypes = {
  setFilter: PropTypes.func,
  filters: PropTypes.shape({}),
  onClick: PropTypes.func,
  withoutWrapper: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  filters: getFilters(state),
  maxInitialOpened: getMaxCountToOpenFilter(state),
  selectedFilters: getSelectedFilters(state),
  shouldRender: !!Object.keys?.(getFilters(state))?.length,
});

export const FiltersMapper = compose(connect(mapStateToProps), withRenderFlag)(_FiltersMapper);
