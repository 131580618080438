import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TEditIcon = {
  icon?: string;
};

/** Компонент иконки для плашки редактирования адреса ШПД */
export const EditIcon: FC<TEditIcon> = ({ icon }) =>
  icon ?
    <div className={cx('wrapper')}>
      <img alt="" loading="lazy" src={icon} />
    </div>
  : null;
