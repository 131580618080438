import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Benefit, Benefits, Link } from '@beef/legacy-ui-kit';

import PopupStateless from 'pages-components/Popup';

import style from './style.pcss';

const cx = classNames.bind(style);

class BenefitsWithPopups extends PureComponent {
  state = {
    showPopup: false,
    popupContent: '',
  };

  render() {
    const { showTitles, benefits, className, iconType, benefitLayout } = this.props;
    const { showPopup, popupContent } = this.state;
    return (
      <div className={cx('benefitsWrapper')}>
        <Benefits className={className} showTitles={showTitles}>
          {benefits.map((benefit = {}, index) => {
            const hasPopup = benefit.legal && benefit.popupContent;
            const onClick =
              hasPopup ?
                () => this.setState({ showPopup: true, popupContent: benefit.popupContent })
              : null;
            const iconProp = {};
            iconProp[iconType] = benefit.icon;
            return (
              <Benefit
                benefitLayout={benefitLayout}
                className={cx({
                  [`benefit-layout--${benefitLayout}`]: !!benefitLayout,
                })}
                iconWrapClass={cx({
                  [`benefit-layout__icon--${benefitLayout}`]: !!benefitLayout,
                })}
                key={index}
                onClick={onClick}
                textWrapClass={cx({
                  [`benefit-layout__text--${benefitLayout}`]: !!benefitLayout,
                })}
                {...benefit}
                {...iconProp}
              >
                <div dangerouslySetInnerHTML={{ __html: benefit.text }} />
                {hasPopup ?
                  <Link className={cx('link')} onClick={onClick}>
                    <span dangerouslySetInnerHTML={{ __html: benefit.legal }} />
                  </Link>
                : null}
              </Benefit>
            );
          })}
        </Benefits>
        <PopupStateless onClose={() => this.setState({ showPopup: false })} opened={showPopup}>
          <div className={cx('content')} dangerouslySetInnerHTML={{ __html: popupContent }} />
        </PopupStateless>
      </div>
    );
  }
}

BenefitsWithPopups.propTypes = {
  showTitles: PropTypes.bool,
  benefits: PropTypes.array,
  iconType: PropTypes.string,
  benefitLayout: PropTypes.string,
};

export default BenefitsWithPopups;
