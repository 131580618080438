import React, { useEffect } from 'react';
import { RequestStatus } from '@beef/smart-kit';

import { LOGIN_PAGE_URL, LOGIN_TEXTS } from 'pages/Login/utils';
import { YMAuthEvent } from 'pages/Login/Authorization2021/utils/ym';
import { LoginPropsType } from 'pages/Login/types';

export const Error = ({ step }: Pick<LoginPropsType, 'step'>) => {
  useEffect(() => {
    YMAuthEvent.errorShow(step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RequestStatus
      button={{
        text: LOGIN_TEXTS.ERROR_BUTTON,
        variant: 'main',
      }}
      description={LOGIN_TEXTS.ERROR_DESC}
      img={{ src: LOGIN_TEXTS.ERROR_IMG }}
      onConfirm={() => {
        YMAuthEvent.errorButtonClick(step);
        window.location.href = LOGIN_PAGE_URL;
      }}
      title={LOGIN_TEXTS.ERROR_HEADING}
    />
  );
};
