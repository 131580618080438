import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TeaserBanner } from '@beef/smart-kit';

import { ymPushParams } from 'utils/ym';
import { determineOrderModal } from 'pages/ProductCard/actions/tariffConnect/handleTariffConnectionPopup';
import TariffConnectPopup from 'pages/ProductCard/components/TariffConnectPopup/TariffConnectPopup';
import withStoreProvider from 'pages/FTTB/hoc/withStoreProvider';
import {
  changePartnerSubscription,
  disableSpecialOffer,
  getPresetOptions,
  resetTotalOptions,
  setActivePresetOnTab,
  setDefaultInfoAndSlides,
  setPresetOptions,
  setSpecialOfferInfo,
  setTariffInfo,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff/actions';
import { CharactersTabs } from 'pages/ProductCard/Tariff/components/CharactersTabs';
import { FamilyBox } from 'pages/ProductCard/Tariff/components/FamilyBox';
import { ConvergenceBlock } from 'pages/ProductCard/Tariff/components/ConvergenceBlock';
import { getRandomInt } from 'utils/fn';
import { getCookie, setCookie } from 'utils/cookie';
import { ymOrderTariffUpFormBidView } from 'pages/FTTB2021/components/OrderTariffFormPopup/analytics';

import Slides from '../../components/Slides';
import Options from '../../components/Options';
import Check from '../../components/Check';
import { Tabs } from '../../components/Tabs';
import { UpSell } from '../../components/UpSell';
import { ConvergenceLayout } from '../../components/ConvergenceLayout';
import { WithoutConvergenceBlock } from '../../components/WithoutConvergenceBlock';
import { Header, LeftSide, MainContent, MainWrapper, RightSide } from '../../components/Grid';
import {
  checkChangeSettings,
  convertedSum,
  curriedCalculateDiscount,
  getActiveCharacter,
  getConvergenceDescription,
  getDefaultCharacter,
  getDifference,
  getRequestV2Discount,
  initOptions,
  initSlidesObject,
  initializeOptions,
  makeUrl,
  sendInternetLiveYaAnalytics,
  useCurrentPrice,
  useDisableButtonForFamily,
  usePrepareAnalytics,
} from '../../../utils';
import DispatchContext, {
  COOKIE_LIFE_TIME,
  ConstructorContext,
  DEFAULT_FANCY_NUMBER_INDEX,
  MAX,
  MAX_REQUEST_VALUE,
  MIN,
  PANDA_TYPE_NUMBER,
  REQUEST_COUNTER_STEP,
  SPECIAL_NUMBERS,
  TEST_NAME,
} from './constants';
import { reducer } from './reducer';
import { PartnershipProducts } from '../../components/PartnershipProducts';
import { usePrepareConvergenceData } from './hooks/usePrepareConvergenceData';
import { useConvergenceRequestParams } from './hooks/useConvergenceRequestParams';
import { useConvergenceCheckConnection } from './hooks/useConvergenceCheckConnection';
import { checkChosenSocs, checkSocsInTariffOptions, getDefaultHeightPreset } from './utils';
import { calculateSubscriptionsCheckoutPrice } from '../../utils';

const ConstructorTariff = ({
  data,
  content = {},
  slides,
  defaultInfo,
  specialOfferInfo,
  hasSpecialOffer,
  bannerInfo,
  hasTariffConstructor,
  isTariffAvailableForConnect,
  actions,
  requestStatus,
  presetDiscounts,
  isDataTariffLayout,
  checkBanners,
  isAnimalsTariffLayout,
  charactersData,
  familyData,
  checkData,
  socBaseConnected,
  isConnectedCharacter,
  convergenceContent,
  homeInternet,
  currentTabNumber,
  availableFancyNumbers,
}) => {
  const gbytesSlide = slides?.find((item) => item.type === 'Gbytes');
  const [activeOptionSoc, setActiveOptionSoc] = useState(
    getDefaultCharacter(charactersData?.characters).optionSoc,
  );
  const activeAnimalSoc = getDefaultCharacter(charactersData?.characters)?.optionSoc;

  const [state, _dispatch] = useReducer(reducer, {
    defaultInfo,
    slides,
    info: {
      ...defaultInfo,
      defaultInfo: true,
    },
    total: {
      ...initSlidesObject(slides),
      options: initOptions(defaultInfo.tariffOptions, activeOptionSoc),
      subscriptions: [],
      initOptions: Object.values(
        initializeOptions(defaultInfo.tariffOptions, activeAnimalSoc),
      ).filter((item) => item.checked),
    },
    activeTab: null,
    activePresetTab: 'default',
    isUpsellSwitch: false,
    sliders: slides,
    convergence: null,
    presets: null,
    showConvergenceModal: false,
    currentGBSlideIndex: gbytesSlide?.defaultIndex,
    connectConvergenceTariffs: [],
    isHighPreset: getDefaultHeightPreset(data),
    // Хранит дефолтное состояние опции на текущем пресете
    presetOptions: defaultInfo.tariffOptions,
    fttbDiscountOnPreset: '',
  });
  const cachedRequests = useRef({});

  /**
   * Бесшовный переход: BCP-6900
   * Получение и проверка наличия в URL query-параметров от ШПД.
   */
  const seamlessUrlParams = useMemo(() => {
    if (typeof window !== 'undefined') {
      return qs.parse(window.location.search);
    }
    return {};
  }, [state.defaultInfo]);
  const hasSeamlessUrlParams = !!Object?.keys(seamlessUrlParams)?.length;
  const hasInacId = Object?.keys(seamlessUrlParams)?.includes('inacId');

  const [chosenSocs, setChosenSocs] = useState([]);
  const [chosenSubscriptions, setChosenSubscriptions] = useState([]);
  const [orderModalPending, setOrderModalPending] = useState(false);
  const currentTariffsGeneration = getDefaultCharacter(charactersData?.characters).generation;
  const { presets, convergence } = state;
  const isArchivedWithoutConvergence =
    data.isArchived && !homeInternet?.isConvergence && !convergence?.tariff;
  const isOldConvergence =
    isArchivedWithoutConvergence || (homeInternet?.isConvergence && !convergence?.tariff);
  const [showConvergence, setShowConvergence] = useState(false);

  const handleConvergencePopup = (activePreset) => {
    if (hasRequestConvergenceError) {
      if (requestCounter > MAX_REQUEST_VALUE) {
        setRequestCounter(requestCounter - REQUEST_COUNTER_STEP);
      }
      setIsCheckedConvergence(false);
      setHasRequestConvergenceError(false);
      return;
    }

    if (showConvergence && convergence?.isConnected && convergence?.isSuccess) {
      ymOrderTariffUpFormBidView('fmc_bid_close');
    }

    if (!showConvergence) {
      ymPushParams({ fttb_ev_: 'fmc_form_to_settings' });
    }

    if (activePreset) {
      _dispatch(setActivePresetOnTab(activePreset));
    }

    setShowConvergence(!showConvergence);
  };

  /** В рамках задачи 9918 Скидка для тарифа */
  const [discount, setDiscount] = useState(0);
  const [shortRateDiscount, setShortRateDiscount] = useState(0);

  const isChangeTariff = useMemo(
    () => state.activeTab !== 'SimOrderForm' && state.activeTab !== 'MNPForm',
    [state.activeTab],
  );

  /** В рамках задачи 3037 флаг для бека на определение подключения tariffUp */
  const connectingConstructor =
    (isAnimalsTariffLayout || isDataTariffLayout) && !hasTariffConstructor && isChangeTariff;

  /** В рамках задачи 2952 флаг для бека на определение смены пакетов/опций в tariffUp */
  const updateTariffUp = hasTariffConstructor && isAnimalsTariffLayout && isChangeTariff;

  const gbAndMinutesLongRate = state.info?.accumulators?.gbytesAndMinutes?.longRcRate || 0;
  const gbAndMinutesShortRate = state.info?.accumulators?.gbytesAndMinutes?.shortRcRate || 0;
  const gbAndMinutesLongRateWithDiscount =
    state.info?.accumulators?.gbytesAndMinutes?.longRcRateWithDiscountChangeTariff;
  const temporaryDiscount = state.info?.accumulators?.gbytesAndMinutes?.temporaryDiscount;
  const characterDiscountType = state.info?.accumulators?.gbytesAndMinutes?.characterDiscountType;
  const chargeTariffAmount = state.info?.chargeAmount || 0;
  const chargePresetAmount = state.info?.chargePresetAmount || 0;
  const initialBalancePayment = state.info?.initialBalancePayment || 0;
  const isAvailableConstructorConvergence = state.info?.isAvailableConstructorConvergence;
  const hasSomeConvergencePreset = state.info?.hasSomeConvergencePreset;
  const fttbPresetConnectedDiscount = state.fttbDiscountOnPreset;

  const activeCharacter = getActiveCharacter(charactersData?.characters, activeOptionSoc);
  const familyNumberedPriceToggleEnabled =
    familyData?.familyContent?.familyNumberedPriceToggleEnabled;

  const changeTariffAmountCondition = useMemo(
    () =>
      !!state.info?.chargeAmount && isChangeTariff && data.isAuthenticated && !hasTariffConstructor,
    [state.info?.chargeAmount, isChangeTariff, data.isAuthenticated, hasTariffConstructor],
  );

  /** В рамках задачи 3272 флаг для определения типа "Панда" в кастомном попапе для отображения скидки tariffUp */
  const isPandaTypeActive =
    isAnimalsTariffLayout && activeCharacter.type === PANDA_TYPE_NUMBER && activeCharacter.isActive;

  const initialBalancePaymentCondition = useMemo(
    () => !!state.info?.initialBalancePayment && !isChangeTariff,
    [state.info?.initialBalancePayment, isChangeTariff],
  );

  const changePresetAmountCondition = useMemo(
    () =>
      !!state.info?.chargePresetAmount &&
      isChangeTariff &&
      data.isAuthenticated &&
      hasTariffConstructor,
    [state.info?.chargePresetAmount, isChangeTariff, data.isAuthenticated, hasTariffConstructor],
  );

  const isChangePreset = useMemo(
    () =>
      !checkChangeSettings(state?.defaultInfo?.accumulators, state?.info?.accumulators) &&
      isChangeTariff &&
      data.isAuthenticated &&
      hasTariffConstructor,
    [isChangeTariff, data.isAuthenticated, hasTariffConstructor, state.info?.accumulators],
  );

  /**
   *  Проброс выбранного номера из таба в попап подключения нового номера: BCP-4882
   *  rootCtnChosenIndex: индекс текущего, выбрнного пользователем, номера в массиве доступных fancy-номеров.
   *  Позволяет на этапе отображения попапа корректно подтянуть выбранный ранее номер.
   *  numbers: массив доступных fancy-номеров;
   *  currentIndex: индекс текущего выбранного номера (по-умолчанию берем нулевой)
   * @type {*|number}
   */
  const rootCtnChosenIndex = useMemo(() => {
    const numbers = availableFancyNumbers?.find(
      (tariff) => tariff?.alias === SPECIAL_NUMBERS,
    )?.numbers;
    const currentIndex = numbers?.findIndex((el) => el?.value === currentTabNumber);
    return currentIndex > DEFAULT_FANCY_NUMBER_INDEX ? currentIndex : DEFAULT_FANCY_NUMBER_INDEX;
  }, [currentTabNumber, availableFancyNumbers]);

  /**
   * Получение подключенного тарифа ШПД, а также доступных к подключению тарифов,
   * если юзер авторизован и является конвергентом.
   */
  const {
    requestCounter,
    setRequestCounter,
    isCheckedConvergence,
    setIsCheckedConvergence,
    hasRequestConvergenceError,
    setHasRequestConvergenceError,
  } = useConvergenceCheckConnection({
    _dispatch,
    hasInacId,
    homeInternet,
    activeOptionSoc,
    seamlessUrlParams,
    convergenceContent,
    hasSeamlessUrlParams,
    isAnimalsTariffLayout,
    currentTariffsGeneration,
    isOldConvergence: convergenceContent?.userInfo?.isConvergenceUser && !isConnectedCharacter,
  });

  const showDiscountOffText = isChangePreset && !isPandaTypeActive;

  /** Определение параметров для блока конвергента */
  const isShowConvergenceNoAuth = !data.isAuthenticated && hasSomeConvergencePreset;
  const isConvergence = convergenceContent?.userInfo?.isConvergenceUser;
  const isShowConvergenceAuth = data.isAuthenticated && (isConvergence || hasSomeConvergencePreset);
  const isShowConvergence = isShowConvergenceNoAuth || isShowConvergenceAuth;
  const isSimOrderAvailableFttb =
    !data?.isAuthenticated &&
    activeCharacter?.isSimOrderAvailableFttb &&
    state.activeTab === 'SimOrderForm';
  const isShowAvailableSettings =
    state.activeTab !== 'MNPForm' &&
    !isSimOrderAvailableFttb &&
    (!data?.isAuthenticated || isChangeTariff) &&
    isAvailableConstructorConvergence;
  const convergenceDescription = getConvergenceDescription({
    homeInternet,
    isConvergence,
    requestCounter,
    isCheckedConvergence,
    isShowAvailableSettings,
    activeTab: state.activeTab,
    hasRequestConvergenceError,
    isAuthenticated: data?.isAuthenticated,
    isSimOrderAvailableFttb,
    isOldConvergence,
  });

  /** Определение дизейбла кнопки в чеке при смене основных табов и наличия блока семьи */
  const { isDisableFamilyButton } = useDisableButtonForFamily({
    isChangeTariff,
    hasAttentionText: !!familyData?.familyContent?.attentionText,
  });

  /** Определение цены для блока семьи */
  const { showDiscount, currentDiscountPrice, currentPricePeriod, tabPrice } = useCurrentPrice({
    price: familyData?.familyContent?.price,
    tabs: charactersData?.characters,
    activeOptionSoc,
    familyNumberedPriceToggleEnabled,
  });

  /** Формирование объекта в метрику для TariffUp */
  const { minutes, gbytes, options } = state.total;
  const { prepareToSendYaAnimalAnalytics } = usePrepareAnalytics({
    options,
    unitGb: gbytes?.unit,
    gbytes: gbytes?.value,
    unitMin: minutes?.unit,
    minutes: minutes?.value,
    activeTab: state?.activeTab,
    content: content?.tabs?.content,
  });

  /** Функция отправки объекта аналитики в метрику для TariffUp */
  const sendYaAnimalAnalytics = (toConnect) => {
    if (isAnimalsTariffLayout) {
      prepareToSendYaAnimalAnalytics(toConnect);
    }
  };

  /** Функция отправки объекта аналитики в метрику для InternetLive */
  const sendYaLiveAnalytics = (value) => {
    if (isDataTariffLayout) {
      const groupType =
        getCookie(TEST_NAME) || (getRandomInt(MIN, MAX) % 2 ? 'Variant_A' : 'Variant_B');

      setCookie({
        name: TEST_NAME,
        value: groupType,
        maxAge: COOKIE_LIFE_TIME,
      });

      sendInternetLiveYaAnalytics({ groupType, value });
    }
  };

  const {
    homeInternetOptions,
    needVerificationInternetOptions,
    disabledOptions,
    allCheckedConvergenceOptions,
    isNeedConnectedConvergence,
    hasChangedConvergenceOptions,
    getOptionsNames,
    getOptionsPrice,
    homeInternetOptionsSum,
    needVerificationInternetOptionsSum,
    isNeedCallBackOptions,
    isNeedDefaultCallBackOptions,
    isNeedCallBackAndRemoveEquipment,
    removalEquipment,
    hasChangedConnectedConvergenceOptions,
  } = usePrepareConvergenceData({
    convergenceContent,
    isShowAvailableSettings,
    activeTab: state.activeTab,
    isAuthenticated: data?.isAuthenticated,
    isChangeTariff,
    convergence,
    isConvergence,
  });

  const { fttbServices, requestConvergenceParams } = useConvergenceRequestParams({
    isConvergence,
    allCheckedConvergenceOptions,
    convergence,
    isAuthenticated: data?.isAuthenticated,
    isNeedCallBackAndRemoveEquipment,
    removalEquipment,
  });

  const getOptionalDiscount = () => {
    let optionalDiscount;
    if (state.total?.options) {
      optionalDiscount = Object.values(state.total.options)
        .filter((item) => item.checked)
        .reduce((acc, item) => {
          if (item.sum !== item.discountSum) {
            return true;
          }
          return acc;
        }, false);
    }
    return optionalDiscount;
  };

  const getInitialDisc = () => {
    if (discount) return discount;
    if (getOptionalDiscount()) return gbAndMinutesLongRate;
    return 0;
  };

  const totalSum = useMemo(() => {
    let sum = gbAndMinutesLongRate;
    let btnSum = discount || gbAndMinutesLongRate;
    let btnHasTariffSum = state.info.defaultInfo ? 0 : discount || gbAndMinutesLongRate;
    let discountSum = getInitialDisc();
    const familySum = familyData?.familyContent?.isConnected ? currentDiscountPrice : 0;

    const changeTotalSum = (additionalSum, isOnlySum) => {
      sum += additionalSum;
      if (!isOnlySum) {
        btnSum += additionalSum;
      }
      if (discountSum) {
        discountSum += additionalSum;
      }
    };

    if (initialBalancePaymentCondition) {
      changeTotalSum(initialBalancePayment);
    }

    if (changeTariffAmountCondition && !isAnimalsTariffLayout) {
      changeTotalSum(chargeTariffAmount);
    }

    if (familyData && Number(familyData) && isChangeTariff) {
      changeTotalSum(familySum, isAnimalsTariffLayout);
    }

    if (changePresetAmountCondition && !isAnimalsTariffLayout) {
      changeTotalSum(chargePresetAmount);
    }

    if (homeInternetOptionsSum) {
      changeTotalSum(homeInternetOptionsSum);
    }

    if (needVerificationInternetOptionsSum) {
      changeTotalSum(needVerificationInternetOptionsSum, isAnimalsTariffLayout);
    }

    if (state.total?.options) {
      Object.values(state.total.options)
        .filter((item) => item.checked)
        .forEach((option) => {
          /* BCP-17658 Так как тихонечко подменили количество опций на разных пресетх, дополнительно сверяем соки */
          if (!checkSocsInTariffOptions(option.soc, state.info?.tariffOptions)) {
            return;
          }
          sum += option.sum;
          btnSum += isAnimalsTariffLayout ? option.discountSum : option.discountSumDay;
          if (discountSum) {
            discountSum += option.discountSum;
          }

          if (hasTariffConstructor) {
            if (!state.total.initOptions.find((o) => o.title === option.title)) {
              btnHasTariffSum += isAnimalsTariffLayout ? option.discountSum : option.discountSumDay;
            } else {
              btnHasTariffSum += state.total.initOptions.reduce((acc, item) => {
                // todo change logic for option soc
                if (
                  item.title === option.title &&
                  JSON.stringify(item.soc) !== JSON.stringify(option.soc)
                ) {
                  if (!getDifference(item.soc, option.soc).length) {
                    return acc + option.discountSumDay - item.discountSumDay;
                  }
                  return acc + (isAnimalsTariffLayout ? option.discountSum : option.discountSumDay);
                }

                return acc;
              }, 0);
            }
          }
        });
    }

    // NOTE: Добавление стоимости подписок в чек
    if (state.total?.subscriptions) {
      const subscritpionsCheckoutPrice = calculateSubscriptionsCheckoutPrice(
        state.total.subscriptions,
      );
      const subscriptionsCheckoutPriceButtonValue = calculateSubscriptionsCheckoutPrice(
        state.total.subscriptions,
        true,
      );

      sum += subscritpionsCheckoutPrice;
      btnSum += subscriptionsCheckoutPriceButtonValue;
      btnHasTariffSum += subscriptionsCheckoutPriceButtonValue;
    }

    if (checkData?.numberPortation?.price?.value && state.activeTab === 'MNPForm') {
      btnSum += checkData.numberPortation.price.value;
    }

    if (checkData?.simOrder?.price?.value && state.activeTab === 'SimOrderForm') {
      btnSum += checkData.simOrder.price.value;
    }
    if (changeTariffAmountCondition && isAnimalsTariffLayout) {
      btnSum += chargeTariffAmount;
    }
    if (changePresetAmountCondition && isAnimalsTariffLayout) {
      btnSum += chargePresetAmount;
    }

    return {
      sum: convertedSum(sum),
      btnSum: convertedSum(btnSum),
      btnHasTariffSum: convertedSum(btnHasTariffSum),
      discountSum: discountSum ? convertedSum(discountSum) : null,
    };
  }, [
    state.total,
    state.info,
    discount,
    changeTariffAmountCondition,
    changePresetAmountCondition,
    convergence,
    state.activeTab,
    activeCharacter.optionSoc,
  ]);

  // Стандартная проверка на изменения уже подключенного тарифа
  const yourConstructorTariff = useMemo(() => {
    let isYourTariff =
      data?.isAuthenticated && state.info.defaultInfo && hasTariffConstructor && isChangeTariff;

    if (state.total?.initOptions) {
      const checkedOptions = Object.values(state.total.options).filter((item) => item.checked);
      isYourTariff =
        isYourTariff && JSON.stringify(checkedOptions) === JSON.stringify(state.total.initOptions);
    }

    if (state.total?.initOptions) {
      isYourTariff = isYourTariff && !state.total?.subscriptions?.length;
    }

    if (activeCharacter) {
      isYourTariff = isYourTariff && activeCharacter.isActive;
    }

    return isYourTariff;
  }, [data, hasTariffConstructor, isChangeTariff, state.info, state.total, activeCharacter]);

  let yourTariff = yourConstructorTariff;
  if (
    (isConvergence && !convergence?.tariff?.isConnected) ||
    hasChangedConnectedConvergenceOptions ||
    isNeedConnectedConvergence
  ) {
    yourTariff = false;
  }

  const isYourPreset =
    data?.isAuthenticated && state.info.defaultInfo && hasTariffConstructor && isChangeTariff;

  const btnPopupPrice = useMemo(() => {
    if (data.isAuthenticated && isChangeTariff && !yourTariff && hasTariffConstructor) {
      return totalSum.btnHasTariffSum;
    }

    return totalSum.btnSum;
  }, [
    state.info,
    totalSum,
    yourTariff,
    isChangeTariff,
    hasTariffConstructor,
    data.isAuthenticated,
  ]);

  const connectedTariffUp =
    data?.isAuthenticated &&
    hasTariffConstructor &&
    isChangeTariff &&
    isAnimalsTariffLayout &&
    isConnectedCharacter;

  let isOptionsDefault = true;
  if (state.total?.initOptions) {
    const checkedOptions = Object.values(state.total.options).filter((item) => item.checked);
    isOptionsDefault = JSON.stringify(checkedOptions) === JSON.stringify(state.total.initOptions);
  }

  /**
   * Флаг отвечает за отображение опций в чеке.
   * Вариант !(connectedTariffUp && (isOptionsDefault || state.info.defaultInfo)) работает некорректно с опциями.
   */
  const showOptions =
    !(connectedTariffUp && isOptionsDefault) || !(connectedTariffUp && state.info.defaultInfo);
  /** Флаг старый. Отвечает за отображение пресетов в чеке */
  const showFee = !(connectedTariffUp && state.info.defaultInfo && !showOptions);

  const showConvergenceOptions = !(
    connectedTariffUp && !(hasChangedConnectedConvergenceOptions || isNeedConnectedConvergence)
  );

  const chosen = useMemo(() => {
    const checkedSocs = checkChosenSocs(chosenSocs, state.info?.tariffOptions);

    if (activeOptionSoc) {
      return [...checkedSocs, activeOptionSoc];
    }
    return checkedSocs;
  }, [chosenSocs, activeOptionSoc]);

  /**
   * hasConnectedConvergence: запуск сценария обновления тарифа при условии
   * подключенной конвергенции.
   */
  const hasConnectedConvergence =
    data.isAuthenticated &&
    isConvergence &&
    hasTariffConstructor &&
    isAnimalsTariffLayout &&
    isConnectedCharacter;

  /**
   * isChangeMobile: флаг определения смены мобильных опций для отправки pricePlaneName в новом методе.
   */
  const isChangeMobile =
    hasConnectedConvergence && (!hasChangedConvergenceOptions || !yourConstructorTariff);

  const isChangeOnlyMobileOptions = isYourPreset && !yourConstructorTariff;
  /**
   * isConnectUpConvergence: флаг определения перехода на тариф UP
   */

  const isBaseUpdateUpCondition =
    isNeedConnectedConvergence && data.isAuthenticated && isAnimalsTariffLayout;
  const isConnectUpConvergence = isBaseUpdateUpCondition && !yourConstructorTariff;

  /**
   * isChangeConvergencePreset: смена пресета подключенного конвергента без смены персонажа.
   */
  const isChangeConvergencePreset =
    hasConnectedConvergence && yourConstructorTariff && !convergence?.tariff?.isConnected;

  /**
   * isNeedDefaultCallBack: CB для кнопки сохранить.
   */
  const isNeedDefaultCallBack =
    (isConvergence && !isConnectedCharacter) ||
    isNeedDefaultCallBackOptions ||
    isChangeConvergencePreset;

  /**
   * isNeedConnectCallBack: CB для метода подключения.
   */
  const isNeedConnectCallBack = isNeedCallBackOptions || isNeedCallBackAndRemoveEquipment;

  /**
   * isAppendConvergenceToUp: условиие для запроса на бронбенд при аз и тариф ап и добавлении только опций шпд.
   */
  const isAppendConvergenceToUp =
    isBaseUpdateUpCondition && isConnectedCharacter && yourConstructorTariff;

  const handleOrderModal = (tariff) => {
    if (!data.connectPopupUrl || orderModalPending || tariff) {
      return;
    }
    setOrderModalPending(true);
    const tariffUpSoc = !isConnectedCharacter && activeCharacter.soc;

    actions.determineOrderModal({
      isNeedCallBackOptions: isNeedConnectCallBack,
      hasChangedConvergenceOptions,
      tariffUpSoc,
      activeTab: state.activeTab,
      requestConvergenceParams,
      pricePlaneName: data.soc,
      hasConnectedConvergence,
      isConnectUpConvergence,
      connectingConstructor,
      setOrderModalPending,
      chosenSubscriptions,
      chosenSocs: chosen,
      info: state.info,
      isChangeMobile,
      isChangeOnlyMobileOptions,
      updateTariffUp,
      fttbServices,
      data,
    });
  };

  // Меняет персонажа и сбрасывает опции
  const handleTabClick = (currentSoc) => {
    if (currentSoc === activeOptionSoc) return;
    setActiveOptionSoc(currentSoc);
    _dispatch(
      setPresetOptions({
        presetOptions: state?.presetOptions,
        options: initOptions(state?.presetOptions, activeOptionSoc),
      }),
    );
  };

  const resetOptions = useCallback((opts) => {
    if (!opts || opts?.length === 0) {
      _dispatch(resetTotalOptions());
    }
  }, []);

  const getTariffInfo = useCallback(
    (item) => {
      const newTotal = { ...state.total, ...item };
      const cachedKey = `gbytes:${newTotal.gbytes.value}minutes:${newTotal.minutes.value}`;

      if (cachedRequests.current[cachedKey]) {
        const cachedInfo = cachedRequests.current[cachedKey];
        resetOptions(cachedInfo?.tariffOptions);
        _dispatch(setTariffInfo(cachedInfo));
        _dispatch(getPresetOptions(cachedInfo?.tariffOptions));
      } else {
        const apiParams = {
          market: data.marketCode,
          soc: socBaseConnected || data.soc,
          gbytes: newTotal.gbytes,
          minutes: newTotal.minutes,
          isTariffUp: isAnimalsTariffLayout,
        };
        const apiPath = makeUrl(
          '/constructor-api/info/',
          apiParams,
          (param) => param?.value ?? param,
        );

        axios
          .get(apiPath)
          .then((response) => {
            cachedRequests.current[cachedKey] = {
              ...response.data,
              defaultInfo: false,
            };
            _dispatch(
              setTariffInfo({
                ...response.data,
                defaultInfo: false,
              }),
            );
            _dispatch(getPresetOptions(response.data?.tariffOptions));
            resetOptions(response.data?.tariffOptions);
          })
          .catch(() => {
            _dispatch(
              setTariffInfo({
                ...defaultInfo,
                defaultInfo: true,
              }),
            );
            resetOptions(defaultInfo.tariffOptions);
          });
      }
    },
    [data, state.total, cachedRequests],
  );

  const getUpsellOfferInfo = useCallback(() => {
    _dispatch(
      setSpecialOfferInfo({
        ...specialOfferInfo,
        defaultInfo: false,
      }),
    );
    resetOptions(specialOfferInfo?.tariffOptions);
  }, []);

  useEffect(() => {
    if (defaultInfo && slides.length) {
      const key = slides.reduce(
        (str, item) => `${str}${item.type.toLowerCase()}:${item.steps[item.defaultIndex].value}`,
        '',
      );
      cachedRequests.current[key] = {
        ...defaultInfo,
        defaultInfo: true,
      };
    }
  }, [defaultInfo, slides]);

  useEffect(() => {
    const totalSocs = [];
    if (state.total?.options) {
      Object.values(state.total.options)
        .filter((item) => item.checked)
        .forEach((option) => {
          option.soc.forEach((soc) => totalSocs.push(soc));
        });
    }
    setChosenSocs(totalSocs);
  }, [state.total]);

  useEffect(() => {
    const totalSocs = state.total?.subscriptions.map((subscription) => subscription.id);
    setChosenSubscriptions(totalSocs);
  }, [state.total]);

  /** Отключает спецпредложение, если Таб !== Изменить тариф и включает если равен */
  useEffect(() => {
    if (!isChangeTariff && state.isUpsellSwitch) {
      _dispatch(setDefaultInfoAndSlides());
      _dispatch(disableSpecialOffer());
    }
  }, [isChangeTariff, state.isUpsellSwitch]);

  /** В рамках задачи 9918 Скидка для тарифа */
  useEffect(() => {
    const { activeTab } = state;
    const needLongRcRateWithDiscount =
      (data.isAuthenticated && isAnimalsTariffLayout && isChangeTariff) || null;
    const preset =
      needLongRcRateWithDiscount ?
        {
          simOrderForm: null,
          mnpForm: null,
          changeTariffForm: null,
        }
      : presetDiscounts;
    const priceWithDiscount = curriedCalculateDiscount(
      activeTab,
      isChangeTariff,
      preset,
      activeCharacter?.presetDiscounts,
    );
    const longRateWithDiscount = priceWithDiscount(gbAndMinutesLongRate);
    const shortRateWithDiscount = priceWithDiscount(gbAndMinutesShortRate);
    const currentLongDiscount = getRequestV2Discount({
      needLongRcRateWithDiscount,
      gbAndMinutesLongRateWithDiscount,
      characterDiscountType,
      activeCharacterType: activeCharacter?.type,
      longRateWithDiscount,
    });
    setDiscount(currentLongDiscount);
    setShortRateDiscount(shortRateWithDiscount);
  }, [state.activeTab, state.info, presetDiscounts, activeCharacter, state.total?.options]);

  /** В рамках задачи 3300 метрика для InternetLive */
  useEffect(() => {
    if (typeof window !== 'undefined') {
      sendYaLiveAnalytics('view');
    }
  }, []);

  /**
   * BCP-6900
   * Открываем попап ШПД, если совершен "бесшовный" переход в НЗ.
   */
  useEffect(() => {
    if (hasSeamlessUrlParams && hasInacId) {
      setShowConvergence(hasSeamlessUrlParams);
    }
  }, [isCheckedConvergence]);

  /** Добавление Партнерских подписок */
  const handlePartnershipClick = useCallback((subscription) => {
    _dispatch(changePartnerSubscription(subscription));
  }, []);

  const isNotDefaultPreset = useMemo(
    () => isConnectedCharacter && !state.info.defaultInfo,
    [isConnectedCharacter, state.info.defaultInfo],
  );

  const handleRefundDiscount = () => {
    _dispatch(setDefaultInfoAndSlides());
  };

  useEffect(() => {
    setActiveOptionSoc(
      state.isHighPreset ?
        activeCharacter?.highPower?.optionSoc
      : activeCharacter?.power?.optionSoc,
    );
  }, [state.isHighPreset]);

  return (
    <MainWrapper>
      {bannerInfo && <Header bannerInfo={bannerInfo} title={data.pageTitle} />}
      {content?.bannerInfo && !bannerInfo && (
        <TeaserBanner
          backgroundColor={content.bannerInfo?.backgroundColor}
          img={content.bannerInfo.img}
          text={content.bannerInfo.text}
          textColor={content.bannerInfo.textColor}
          title={content.bannerInfo.title}
          withDivider
        />
      )}
      <DispatchContext.Provider value={_dispatch}>
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <ConstructorContext.Provider
          value={{
            activeCharacter,
            activeOptionSoc,
            passivePopups: content.passivePopups,
            isHighPreset: state.isHighPreset,
            characters: charactersData?.characters,
          }}
        >
          <MainContent>
            <LeftSide>
              <Tabs
                currentTabNumber={currentTabNumber}
                data={content.tabs}
                isAnimalsTariffLayout={isAnimalsTariffLayout}
                isAuthenticated={data.isAuthenticated}
                isTariffAvailableForConnect={isTariffAvailableForConnect}
                isTariffConnected={hasTariffConstructor}
                rootChosenIndex={rootCtnChosenIndex}
              />
              {hasSpecialOffer && isChangeTariff && (
                <UpSell
                  content={content?.upsell}
                  getUpsellOfferInfo={getUpsellOfferInfo}
                  isUpsellSwitch={state.isUpsellSwitch}
                />
              )}
              {charactersData?.characters?.length > 0 && (
                <CharactersTabs
                  activeOptionSoc={activeOptionSoc}
                  content={content}
                  hasTariffConstructor={!!data?.layoutData?.hasTariffConstructor}
                  isChangeTariff={isChangeTariff}
                  isHighPreset={state.isHighPreset}
                  onClick={handleTabClick}
                  tabs={charactersData.characters}
                  title={charactersData?.title}
                />
              )}
              {/* Блок обновления тарифа UP для подключения конвергенции.
               Отображается в случае, если тариф Up архивный и конвергенция не подключена */}
              {isArchivedWithoutConvergence && (
                <WithoutConvergenceBlock {...content?.noFttbServiceData} />
              )}
              {homeInternet && isShowConvergence && !isArchivedWithoutConvergence && (
                <ConvergenceBlock
                  activePresetTab={state.activePresetTab}
                  activeTab={state.activeTab}
                  archiveUrl={homeInternet?.archiveUrl}
                  canSetup={!data.layoutData.isOffer}
                  content={homeInternet?.content}
                  description={convergenceDescription}
                  discountDescription={homeInternet?.content?.discountDescription}
                  fttbPresetConnectedDiscount={fttbPresetConnectedDiscount}
                  handleClick={handleConvergencePopup}
                  hasRequestConvergenceError={hasRequestConvergenceError}
                  homeInternetOptionsSum={homeInternetOptionsSum}
                  isAuthenticated={data.isAuthenticated}
                  isAvailableConstructorConvergence={isAvailableConstructorConvergence}
                  isChangeTariff={isChangeTariff}
                  isCheckedConvergence={isCheckedConvergence}
                  isConvergence={isConvergence}
                  isOldConvergence={isOldConvergence}
                  isShowAvailableSettings={isShowAvailableSettings}
                  isSimOrderAvailableFttb={activeCharacter.isSimOrderAvailableFttb}
                  needForRetry={homeInternet?.needForRetry}
                  needVerificationInternetOptionsSum={needVerificationInternetOptionsSum}
                  presets={presets}
                  userAddress={convergence?.userAddress}
                  userBlockDiscountText={convergenceContent.userBlockDiscountText}
                  userNumber={convergence?.userNumber}
                />
              )}
              <Slides
                content={convergenceContent ? convergenceContent.slides : content.slides}
                discount={discount}
                gbAndMinutesLongRate={gbAndMinutesLongRate}
                getTariffInfo={getTariffInfo}
                isNotDefaultPreset={isNotDefaultPreset}
                isNotEnoughMoney={state.info?.isNotEnoughMoney}
                isUpsellSwitch={state.isUpsellSwitch}
                longRcRateUnit={state.info?.longRcRateUnit}
                slides={state.sliders}
                suitableGenerationForPassive={state.info?.suitableGenerationForPassive ?? false}
                tooltip={state.info?.tooltipText}
              />
              {familyData && (
                <FamilyBox
                  currentDiscountPrice={currentDiscountPrice}
                  currentPricePeriod={currentPricePeriod}
                  data={familyData}
                  familyNumberedPriceToggleEnabled={familyNumberedPriceToggleEnabled}
                  isAuthenticated={data.isAuthenticated}
                  isChangeTariff={isChangeTariff}
                  isDisableFamilyButton={isDisableFamilyButton}
                  showDiscount={showDiscount}
                  tabPrice={tabPrice}
                />
              )}
              {state.info?.tariffOptions?.length > 0 && (
                <Options
                  activeOptionSoc={activeOptionSoc}
                  benefitLinkText={content?.benefitLinkText}
                  options={state.info.tariffOptions}
                  popupTetering={content?.popupTetering}
                  title={content.options.title}
                  unit={state.info.longRcRateUnit}
                  withoutDiscountUnit={content.options?.withoutDiscountUnit}
                />
              )}
              <PartnershipProducts onPartnershipClick={handlePartnershipClick} soc={data?.soc} />
            </LeftSide>
            <RightSide>
              <Check
                activeCharacter={activeCharacter}
                activeTab={state.activeTab}
                alias={data?.alias}
                changePresetAmountCondition={changePresetAmountCondition}
                changeTariffAmountCondition={changeTariffAmountCondition}
                chargeTariffAmount={chargeTariffAmount}
                checkData={checkData}
                content={content.check.content}
                currentFamilyPrice={currentDiscountPrice}
                currentPricePeriod={currentPricePeriod}
                disabledOptions={disabledOptions}
                discount={discount}
                familyContent={familyData?.familyContent}
                familyNumberedPriceToggleEnabled={familyNumberedPriceToggleEnabled}
                fttbPresetConnectedDiscount={fttbPresetConnectedDiscount}
                gbAndMinutesLongRate={gbAndMinutesLongRate}
                gbAndMinutesShortRate={gbAndMinutesShortRate}
                getOptionsNames={getOptionsNames}
                getOptionsPrice={getOptionsPrice}
                handleOrderModal={handleOrderModal}
                handleRefundDiscount={handleRefundDiscount}
                hasNotification={isNotDefaultPreset}
                hasRequestConvergenceError={hasRequestConvergenceError}
                hasTariffConstructor={hasTariffConstructor}
                homeInternetOptions={homeInternetOptions}
                info={state.info}
                initialBalancePaymentCondition={initialBalancePaymentCondition}
                isAnimalsTariffLayout={isAnimalsTariffLayout}
                isAppendConvergenceToUp={isAppendConvergenceToUp}
                isAuthenticated={data.isAuthenticated}
                isChangeConvergencePreset={isChangeConvergencePreset}
                isChangeTariff={isChangeTariff}
                isCheckedConvergence={isCheckedConvergence}
                isConnectedCharacter={isConnectedCharacter}
                isConnectedFamily={familyData?.familyContent?.isConnected}
                isConvergence={isConvergence}
                isDataTariffLayout={isDataTariffLayout}
                isDisableFamilyButton={isDisableFamilyButton}
                isNeedConnectedConvergence={isNeedConnectedConvergence}
                isNeedDefaultCallBack={isNeedDefaultCallBack}
                isOptionsDefault={isOptionsDefault}
                isShowAvailableSettings={isShowAvailableSettings}
                isYourPreset={isYourPreset}
                needVerificationInternetOptions={needVerificationInternetOptions}
                requestConvergenceParams={requestConvergenceParams}
                requestStatus={requestStatus}
                sendAnalytics={sendYaAnimalAnalytics}
                sendYaLiveAnalytics={sendYaLiveAnalytics}
                shortRateDiscount={shortRateDiscount}
                showConvergenceOptions={showConvergenceOptions}
                showFee={showFee}
                showOptions={showOptions}
                temporaryDiscount={temporaryDiscount}
                total={state.total}
                totalSum={totalSum}
                userNumber={convergenceContent?.userInfo?.activeCTN}
                yourTariff={yourTariff}
              />
            </RightSide>
          </MainContent>
        </ConstructorContext.Provider>
        {isCheckedConvergence && (
          <ConvergenceLayout
            activePresetTab={state.activePresetTab}
            connectConvergenceTariffs={state.connectConvergenceTariffs}
            convergenceContent={convergenceContent}
            discountDescription={homeInternet?.content?.discountDescription}
            generation={currentTariffsGeneration}
            handleConvergencePopup={handleConvergencePopup}
            hasInacId={hasInacId}
            hasSeamlessUrlParams={hasSeamlessUrlParams}
            isOldConvergence={
              convergenceContent?.userInfo?.isConvergenceUser && !isConnectedCharacter
            }
            isOpen={showConvergence}
            seamlessUrlParams={seamlessUrlParams}
            soc={activeOptionSoc}
          />
        )}
      </DispatchContext.Provider>
      <TariffConnectPopup
        activeTab={state.activeTab}
        constructorParams={state.total}
        constructorPopupContent={{
          popupContent: {
            popupDefaultConstructor: content?.popup,
            popupCashBackConstructor: content?.popupCashBack,
          },
          showNotificationText: isChangePreset,
          price: btnPopupPrice,
          showDiscountOffText,
          chargePresetAmount,
        }}
        includeNumber={false}
        rootCtnChosenIndex={rootCtnChosenIndex}
        sendAnalytics={sendYaAnimalAnalytics}
      />
    </MainWrapper>
  );
};

ConstructorTariff.propTypes = {
  data: PropTypes.shape({
    popup: PropTypes.shape({
      content: PropTypes.shape({}),
    }),
    convergenceData: PropTypes.shape({
      discountDescription: PropTypes.string,
    }),
  }),
  content: PropTypes.shape({
    popup: PropTypes.shape({}),
    tabs: PropTypes.shape({}),
    slides: PropTypes.shape({}),
    check: PropTypes.shape({
      content: PropTypes.shape({}),
    }),
    upsell: PropTypes.shape({}),
    options: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  bannerInfo: PropTypes.shape({
    image: PropTypes.string,
    benefits: PropTypes.arrayOf(PropTypes.string),
  }),
  hasTariffConstructor: PropTypes.bool,
  hasSpecialOffer: PropTypes.bool,
  isTariffAvailableForConnect: PropTypes.bool,
  slides: PropTypes.arrayOf(PropTypes.shape({})),
  defaultInfo: PropTypes.shape({}),
  specialOfferInfo: PropTypes.shape({}),
  requestStatus: PropTypes.string,
  presetDiscounts: PropTypes.shape({
    simOrderForm: PropTypes.number,
    mnpForm: PropTypes.number,
    changeTariffForm: PropTypes.number,
  }),
  actions: PropTypes.shape({
    determineOrderModal: PropTypes.func,
  }),
  isDataTariffLayout: PropTypes.bool,
  charactersData: PropTypes.shape({
    title: PropTypes.string,
    activeOptionSoc: PropTypes.string,
    onClick: PropTypes.func,
    characters: PropTypes.arrayOf(
      PropTypes.shape({
        optionSoc: PropTypes.string,
        name: PropTypes.string,
        power: PropTypes.string,
        imageSrc: PropTypes.string,
        activeColor: PropTypes.string,
        type: PropTypes.number,
      }),
    ),
  }),
  socBaseConnected: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ determineOrderModal }, dispatch),
});

const ConnectionConstructorTariff = withStoreProvider(
  connect((state) => {
    const external = state?.external;
    return {
      requestStatus: external?.tariffConnectionPopup?.resultPopupData?.requestStatus,
      currentTabNumber: external?.funcyNumberOrder?.newChosenNumber,
      availableFancyNumbers: external?.funcyNumberOrder?.numbers,
    };
  }, mapDispatchToProps)(ConstructorTariff),
);

export default ConnectionConstructorTariff;
