import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { LeftPart } from './components/LeftPart';
import { RightPart } from './components/RightPart';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TPrice = {
  extraUnit?: string;
  oldPrice?: number;
  prefix?: string;
  price?: number;
  unit?: string;
};

/** Компонент блока с ценой для InfoPrice.
 * Отображает скидку персонажа и стоимость услуги */
export const Price: FC<TPrice> = ({ unit = '₽', price, prefix, oldPrice, extraUnit }) => {
  const priceWithUnit = price ? `${price} ${unit}` : undefined;
  const oldPriceWithUnit = oldPrice ? `${oldPrice} ${unit}` : undefined;

  return price ?
      <div className={cx('wrapper')}>
        <LeftPart {...{ prefix, priceWithUnit }} />
        <RightPart {...{ extraUnit, oldPriceWithUnit }} />
      </div>
    : null;
};
