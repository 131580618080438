import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

import { EUserStoryType } from '../types';
import { setUserStoryType } from '../index';
import { choseUserType } from '../../../../utils/choseUserType';
import { TabTypes } from '../../../../components/constructorPage/Tabs/constants';

/** Вспомогательный экшен для установки текущего типа пользователя
 * и открытии информационных модальных окон */
export const changeCurrentUserType = createAsyncThunk<
  void,
  { handleModalOpen: () => void },
  { state: RootState }
>('stepper/setCurrentUserType', ({ handleModalOpen }, { dispatch, getState }) => {
  /* Объект параметров для определения текущего пользователя через функцию choseUserType */
  const choseUserTypeParams = {
    isArchived: getState().common.userInfo.isArchived,
    currentTariffTab: getState().total.currentTariffTab,
    isConnected: getState().common.userInfo.isConnected,
    tariffInacId: getState().total.convergenceInfo.tariffInacId,
    isAuthenticated: getState().common.userInfo.isAuthenticated,
    isConvergentUser: getState().common.userInfo.isConvergentUser,
    currentViewedTariffTab: getState().total.currentViewedTariffTab,
    isPartnerFmcTariffSelected: getState().total.convergenceInfo.isPartnerFmcTariffSelected,
  };

  /* Общие параметры choseUserTypeParams,
     использующиеся вне ф-ции choseUserType (определяет текущий тип пользователя) */
  const { isAuthenticated, currentTariffTab, currentViewedTariffTab } = choseUserTypeParams;

  /* Если просматриваемый таб (currentViewedTariffTab не может быть активным) способа подключения тарифа
      является eSIMForm или ChangeTariffForm в НЗ - показываем попап.
      * Таб eSIMForm при любых условиях должен отобразить попап.
      * Таб ChangeTariffForm отображает попап ТОЛЬКО в НЗ! */
  const showInfoModal =
    currentViewedTariffTab === TabTypes.eSIMForm ||
    (!isAuthenticated && currentViewedTariffTab === TabTypes.ChangeTariffForm);

  if (showInfoModal) {
    handleModalOpen();
    return;
  }

  /* Логика определения пользователя по основным табам способа подключения тарифа.
   * p.s. SimOrderForm и ChangeTariffForm пока разделены на отдельные кейсы,
   * т.к. в будущем это может оказаться полезным */
  switch (currentTariffTab) {
    case TabTypes.SimOrderForm:
      dispatch(setUserStoryType(choseUserType(choseUserTypeParams)));
      break;
    case TabTypes.ChangeTariffForm:
      dispatch(setUserStoryType(choseUserType(choseUserTypeParams)));
      break;
    case TabTypes.MNPForm:
      dispatch(setUserStoryType(EUserStoryType.mnp));
      break;
    default:
      break;
  }
});
