const getTariffData = (tariff) => ({
  event: 'event_popup_constructor_',
  itemProduct: 'Мобильная связь',
  itemCat: 'Tariffs',
  itemTitle: tariff.pageTitle,
  itemSoc: tariff.soc,
  itemMarketCode: tariff.marketCode,
});

/*
 * Показ попапа с конструктором
 * - настройка подключенного тарифа
 * - АЗ
 * - НЗ
 * */

export const pushInitial = (payload) => {
  const tariff = payload.tariff.data;
  const data = getTariffData(tariff);

  if (tariff.isConnected) {
    data.event += 'change';
  } else if (tariff.isAuthenticated) {
    data.event += 'confirmation';
  } else {
    data.event += 'interaction';
  }

  window.dataLayer.push(data);
};

const messages = {
  balanceNotEnough: 'недостаточно средств',
  restNotEnough: 'недостаточно остатков пакетов',
  blocked: 'номер заблокирован',
  error: 'что-то пошло не так',
};

/*
 * Показ ошибок - выше перечислены возможные
 * */
export const pushError = (step, payload) => {
  const data = getTariffData(payload.tariff.data);

  data.event += 'error';
  data.connectionErrorType = messages[step];

  window.dataLayer.push(data);
};
