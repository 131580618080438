import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { LayoutGroup, motion } from 'framer-motion';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { useAppSelector } from 'constructorV1/store';
import { selectCBillContent } from 'constructorV1/store/selectors/selectCommon';

import { CDisableOptions, TCDisableOptions } from '../CDisableOptions';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCDisableContainer = Partial<TCDisableOptions>;

/** Блок отключаемых опций */
export const CDisableContainer: FC<TCDisableContainer> = ({ options = [] }) => {
  /* Контентный заголовок для блока отключаемых опций */
  const { disablingTitle: title } = useAppSelector(selectCBillContent);

  return options?.length ?
      <LayoutGroup>
        <motion.section className={cx('wrapper')} layout>
          {title && (
            <motion.div className={cx('wrapper__title')} layout>
              <Text align="left" color="primary" lineHeight={20} size="m" weight="regular">
                {title}
              </Text>
            </motion.div>
          )}
          <CDisableOptions {...{ options }} />
        </motion.section>
      </LayoutGroup>
    : null;
};
