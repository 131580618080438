import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Actions
import { RangeSlider } from '@beef/ui-kit';

import { setPackageParams } from 'pages/ProductCard/Tariff/containers/ZTariff/store/actions';

// Constants
import DispatchContext from 'pages/ProductCard/Tariff/containers/ZTariff/store/constants';

// Components
import { SliderTabs } from './components/SlidesTabs';
import { AutoProlong } from '../../AutoProlong';

// Utils
import { initActivePeriodTab } from '../../../../../../utils';
import { createPackageParams, getSliderTemplate } from './utils';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Slider = memo(
  ({
    slide,
    activeTab,
    hasZTariff,
    saveDataTab,
    autoProlong,
    titleContent,
    isChangeTariff,
    persistentEnabledAutoProlong,
  }) => {
    const _dispatch = useContext(DispatchContext);
    const defaultPeriodTab = initActivePeriodTab(slide?.tabs);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [activeTabPeriod, setActiveTabPeriod] = useState(defaultPeriodTab);

    const {
      steps,
      value,
      benefit,
      rightText,
      rightSteps,
      rightUnit,
      lastIndex,
      isInfinity,
      handleChange,
      defaultEnabled,
      hasAutoProlong,
      textBeforeValue,
      isDisableAutoProlong,
      isPersistentEnabledAutoProlong,
    } = useMemo(
      () =>
        getSliderTemplate({
          slide,
          hasZTariff,
          autoProlong,
          currentIndex,
          isChangeTariff,
          activeTabPeriod,
          setCurrentIndex,
          defaultPeriodTab,
          setActiveTabPeriod,
          persistentEnabledAutoProlong,
        }),
      [slide, hasZTariff, currentIndex, isChangeTariff, activeTabPeriod],
    );

    const [enabled, setEnabled] = useState(defaultEnabled);

    useEffect(() => {
      if (
        !((activeTab === 'MNPForm' || activeTab === 'SimOrderForm' || !hasZTariff) && saveDataTab)
      ) {
        setCurrentIndex(0);
      }
    }, [saveDataTab, activeTab]);

    useEffect(() => {
      if (!!currentIndex && !enabled && slide.type.toLowerCase() === 'gbytes') {
        setEnabled(!enabled);
      }

      if (currentIndex === 0) {
        setEnabled(defaultEnabled);
      }
    }, [currentIndex, isChangeTariff, slide, activeTab]);

    useEffect(() => {
      _dispatch(
        setPackageParams(createPackageParams(slide, currentIndex, enabled, activeTabPeriod)),
      );
    }, [slide, enabled, currentIndex, activeTabPeriod, activeTab]);

    return (
      <div className={cx('wrapper')}>
        <div className={cx('wrapper__slider')}>
          <RangeSlider
            defaultIndex={currentIndex}
            infinity={isInfinity}
            lastIndex={lastIndex}
            onChange={handleChange}
            rightSteps={rightSteps}
            rightText={rightText}
            rightUnit={rightUnit}
            steps={steps}
            textBeforeValue={textBeforeValue}
            title={titleContent}
            unit={slide.unit}
          />
        </div>
        {slide?.tabs && (
          <div className={cx('wrapper__tabs', { 'wrapper__tabs--show': !!currentIndex })}>
            <SliderTabs
              activeTabPeriod={activeTabPeriod}
              setActiveTabPeriod={setActiveTabPeriod}
              slide={slide}
            />
          </div>
        )}
        <div
          className={cx('wrapper__prolong', {
            'wrapper__prolong--show': hasAutoProlong && !isDisableAutoProlong,
          })}
        >
          <AutoProlong
            benefit={benefit}
            defaultEnabled={enabled}
            persistentEnabledAutoProlong={isPersistentEnabledAutoProlong}
            setEnabled={setEnabled}
            title={autoProlong.title}
            unit={slide.unit}
            value={value}
          />
        </div>
      </div>
    );
  },
);

Slider.propTypes = {
  title: PropTypes.string,
  slide: PropTypes.shape({
    type: PropTypes.string,
    unit: PropTypes.string,
    lastIndex: PropTypes.number,
    initialSize: PropTypes.number,
    steps: PropTypes.arrayOf(PropTypes.number),
    remainingSize: PropTypes.number,
    initialPeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  }),
  hasZTariff: PropTypes.bool,
  activeTab: PropTypes.string,
  saveDataTab: PropTypes.bool,
  autoProlong: PropTypes.shape({
    title: PropTypes.string,
  }),
  isChangeTariff: PropTypes.bool,
  titleContent: PropTypes.string,
  persistentEnabledAutoProlong: PropTypes.bool,
};
