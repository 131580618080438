import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import { Accordion, Text } from '@carnica/ui-kit';
import { useIsMobileDimension } from '@beef/ui-kit';
import { IconChevronUp } from '@carnica/graphics';

import styles from './styles.pcss';
import { useFAQSection } from './hooks/useFAQSection';
import { Metric } from '../../analytics';

const cx = classNames.bind(styles);

/**
 * Блок FAQ
 */
export const FAQSection: FC = memo(() => {
  const { tabs, title, handleSendFAQAnalitic } = useFAQSection();
  const isMobile = useIsMobileDimension();

  return (
    <Metric asChild events={{ yaMNPMetric: ['handleViewFAQBlock'] }} mode="viewport">
      <section>
        <Text
          align={isMobile ? 'left' : 'center'}
          className={cx('wrapper-title')}
          variant={isMobile ? 'display-small' : 'display-large'}
        >
          {title}
        </Text>

        <div className={cx('accrodions-wrapper')}>
          {tabs.map((tab) => (
            <Accordion
              className={cx('accrodion')}
              closeIcon={IconChevronUp}
              key={tab.id}
              label={tab.title}
              onLabelClick={(flag) => handleSendFAQAnalitic(flag, tab.title)}
              size={isMobile ? 's' : 'l'}
            >
              {tab.description}
            </Accordion>
          ))}
        </div>
      </section>
    </Metric>
  );
});
