import React from 'react';
import classNames from 'classnames/bind';
import { BreadCrumbItemType, BreadCrumbs, Text } from '@beef/ui-kit';
import { connect } from 'react-redux';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

type TitleType = {
  breadcrumbs: BreadCrumbItemType[];
  title: string;
};

export const _Title = ({ title, breadcrumbs }: TitleType) => (
  <>
    {breadcrumbs && (
      <div className={cn('breadcrumbs')}>
        <BreadCrumbs path={breadcrumbs} />
      </div>
    )}
    <Text className={cn('title')} size="size0-b" tagType="h1">
      {title}
    </Text>
  </>
);

// @ts-expect-error state not typed in ts yet
export const Title = connect(
  ({ content }): TitleType => ({ title: content.title, breadcrumbs: content.breadcrumbs }),
)(_Title);
