import { useLayoutEffect, useRef } from 'react';

/* Ширина плитки гб/мин */
const UNIT_TILE_WIDTH = 48;
/* Ширин плитки количиства гб/мин */
const TILE_WIDTH = 72;
/* Отступ между ними */
const TILE_GAP = 4;

/** Подготавливает компонент пресетов ЕК */
export const usePreparePresetPicker = () => {
  /* Ссылка на активный элемент */
  const activeRef = useRef<HTMLButtonElement>(null);
  /* Ссылка на контейнер */
  const containerRef = useRef<HTMLDivElement>(null);
  /* Счетчик */
  const counterRef = useRef(0);

  /* Функция для вычисления смещения */
  const setOffset = (blocksCount = 0) => UNIT_TILE_WIDTH + blocksCount * (TILE_WIDTH + TILE_GAP);

  /* Вычисляем смещение, чтобы активный элемент был виден */
  useLayoutEffect(() => {
    if (counterRef.current <= 1 && activeRef.current && containerRef.current) {
      const elemLeft = activeRef.current.offsetLeft;
      const elemLeftWithOffset = elemLeft - setOffset(1.3);
      containerRef.current.scrollTo(elemLeftWithOffset, 0);
      counterRef.current += 1;
    }
  });

  /* Возвращаем необходимые значения и функции */
  return {
    activeRef,
    containerRef,
  };
};
