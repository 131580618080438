import { ymPushEvent } from 'utils/ym';

/** Успешно отправленная заявка ШПД. ВК пиксель */
const successfullFTTBFormSubmissionVK = () => {
  if (typeof window === 'undefined' || !window.VK) {
    return null;
  }
  VK.Retargeting.Init('VK-RTRG-1279932-blgvG');
  VK.Goal('submit_application');
};

/** Успешно отправленная заявка ШПД. MyTarget пиксель */
const successfullFTTBFormSubmissionMT = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  const _tmr = window._tmr || (window._tmr = []);
  _tmr.push({ type: 'reachGoal', id: 3117532, goal: 'shpd_order_success' });
};

const sendSuccessCallbackLongFormAnalitics = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  ymPushEvent('HomeTariffCallbackLongForm', { form_type: 'main', URL: window.location.href });
};

export {
  sendSuccessCallbackLongFormAnalitics,
  successfullFTTBFormSubmissionMT,
  successfullFTTBFormSubmissionVK,
};
