import { Button, Link, Text } from '@beef/ui-kit';
import React, { useState } from 'react';

import { ModalsControllerProps } from './ModalsController/types';
import { ActionBar, LimitModalContent } from './components';

type ActivateLimitModalDataProps = Pick<
  ModalsControllerProps,
  'barValues' | 'limit' | 'onActivateLimit'
> & {
  data: ModalsControllerProps['data']['limit'];
  defaultValue?: number;
  isLoading: boolean;
  setConnectedLimit: (val: number) => void;
};

export const useActivateLimitModalData = ({
  onActivateLimit,
  data,
  limit,
  barValues,
  isLoading,
  defaultValue = 0,
  setConnectedLimit,
}: ActivateLimitModalDataProps) => {
  const { title, description, buttons, agreement } = data || {};
  const { activate } = buttons || {};
  const desc = description.replace('{{limit}}', limit.toString());

  const [limitValue, setLimitValue] = useState(defaultValue);

  const onConfirmButtonClick = () => {
    onActivateLimit(limitValue);
    setConnectedLimit(limitValue);
  };

  return {
    title,
    content: (
      <LimitModalContent
        barValues={barValues}
        defaultValue={defaultValue}
        description={desc}
        onChange={(value) => setLimitValue(value)}
      />
    ),
    actionBar: (
      <ActionBar>
        <Button isLoading={isLoading} onClick={onConfirmButtonClick}>
          {activate.title}
        </Button>
        <div>
          <Text color="grey60" size="size5-r-s">
            {agreement?.text}
          </Text>
          <Link href={agreement?.url || ''} target="_blank">
            <Text color="contentAccent1" size="size5-r-s">
              {agreement?.link}
            </Text>
          </Link>
        </div>
      </ActionBar>
    ),
  };
};
