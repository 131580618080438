import React from 'react';
import { PageFixer } from '@beef/smart-kit';

import { BLOCK_COMPONENTS } from './utils';

const Help2022 = ({ data: { blocks } }) => (
  <>
    {blocks.map((blockItem) => {
      const block = BLOCK_COMPONENTS[blockItem.type];
      if (!block?.component) return null;
      if (block.withoutWrapper) {
        return (
          <block.component content={blockItem.content} data={blockItem.data} key={blockItem.id} />
        );
      }

      return (
        <PageFixer backgroundColor="main" blockSpacersPreset="m" key={blockItem.id} size="m">
          <block.component content={blockItem.content} data={blockItem.data} />
        </PageFixer>
      );
    })}
  </>
);

export default Help2022;
