import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text, TextPropsType } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TLeftPart = {
  prefix: string;
  priceWithUnit: string;
} & TextPropsType;

/**
 * Левая часть компонента InfoPrice.
 * Включает в себя префикс (от) цену и общий unit.
 */
export const LeftPart: FC<Partial<TLeftPart>> = ({ prefix, priceWithUnit }) => (
  <div className={cx('wrapper')}>
    {prefix && (
      <Text color="grey60" size="size5-r-l">
        {prefix}
      </Text>
    )}
    <Text size="size2-m">{priceWithUnit}</Text>
  </div>
);
