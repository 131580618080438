import { TOptionsListItem, TOptionsSlice } from '../store/slices/optionsSlice/types';

/**
 * Функция-костыль для преобразования characterSoc
 *
 * Из-за ограничений бэкенда в опциях (внутри characterTypes) соки персонажа приходят в формате,
 * отличном от characterSoc tariffInfo – у опций ключи соков имеют 4 первых символа
 * в нижнем регистре.
 * Трансформация: POWER11(норма, используется везде) -> poweR11 (только для characterTypes)
 */
export const transformCharacterSoc = (rawCharacterSoc: string) => {
  // первые 4 символа в lowerCase;
  const firstPartOfSoc = rawCharacterSoc?.slice(0, 4).toLowerCase();
  // остальные символы обычного characterSoc
  const secondPartOfSoc = rawCharacterSoc?.slice(4);
  return firstPartOfSoc?.concat(secondPartOfSoc);
};

/** Вспомогательная функция маппинга опций
 *
 * ВНИМАНИЕ! Содержится костыль с трансформацией characterSoc!
 * */
function transformOptionsList(rawCharacterSoc: string) {
  const characterSoc = transformCharacterSoc(rawCharacterSoc);
  return (opt: TOptionsListItem) => {
    /* Если у опции есть альтернативные цены по пассивке и по ключу сока персонажа имеются данные, то
     * переписываем цены, активность и блокировку, а также бэкапируем данные по ключу default */
    if (opt.characterTypes && characterSoc in opt.characterTypes) {
      return {
        ...opt,
        ...opt.characterTypes[characterSoc],
        characterTypes: {
          ...opt.characterTypes,
          default: {
            price: opt.price,
            oldPrice: opt.oldPrice,
            isActive: opt.isActive,
            isBlocked: opt.isBlocked,
          },
        },
      };
    }
    /* Если есть бэкапированные данные, то забираем их */
    if (opt.characterTypes && 'default' in opt.characterTypes) {
      return {
        ...opt,
        ...opt.characterTypes.default,
      };
    }
    /* Если нет ни бэкапов, ни совпадения с соком текущего сока аппера, то просто вернем опцию */
    return opt;
  };
}

/** Функция подготовки массива опций для использования в сторе
 * На основе текущего сока персонажа модифицирует цены, активность и блокировку опций
 * в зависимости от пассивок текущего аппера, а также делает бэкап дефотных значений в объекте characterTypes */
export const prepareOptionsForPassiveAbility = (
  rawOptions: TOptionsSlice,
  characterSoc: string,
) => {
  /* Делаем проверку на наличие изменений опции от пассивок персонажа и меняем часть данных, если нужно */
  const options: TOptionsSlice = rawOptions.map((optionGroup) => {
    const preparedOptionsList = optionGroup.optionsList?.map(transformOptionsList(characterSoc));
    const preparedParentOptionsList = optionGroup.parentOptionsList?.map(
      transformOptionsList(characterSoc),
    );
    return {
      ...optionGroup,
      optionsList: preparedOptionsList,
      parentOptionsList: preparedParentOptionsList ?? optionGroup.parentOptionsList,
    };
  });
  return options;
};
