import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

const getTotalPrice = (price, discount) => +(price - price * discount).toFixed(2);

// todo: discuss moving this component to the ui-kit
export const Price = ({ price, discount, size = 'size5-r-s', color }) => (
  <Text color="" size={size}>
    <span className={cx('text')}>
      {discount ?
        <span className={cx('text_strike-through', color)}>{`${price} ₽`}</span>
      : null}
      {`${discount ? getTotalPrice(price, discount) : price} ₽`}
    </span>
  </Text>
);

export const PriceShape = {
  price: PropTypes.number,
  discount: PropTypes.number,
  size: PropTypes.string,
  color: PropTypes.string,
};

Price.propTypes = PriceShape;
