import { ChangeEvent, useEffect } from 'react';
import { ChipsPropsType } from '@beef/ui-kit';

import { TPriceListItem } from '../types';
import { sendFmcYm } from '../tempYmUtils';
import { usePrepareFmcTabs } from './usePrepareFmcTabs';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setNetSpeed } from '../../../../store/slices/totalSlice';
import { useSelectActiveInacId } from '../selectors/useSelectActiveInacId';
import { useSelectIsPartnerFmcInRegion } from '../selectors/useSelectIsFmcRegion';
import { setConvergenceBlock } from '../../../../store/slices/tariffServicesSlice';
import { extractFmcTabs, getCurrentConvergenceInfo, prepareFmcTabs } from '../utils';
import { changeFmcInacId } from '../../../../store/slices/totalSlice/asyncThunks/changeFmcInacId';
import { changeCurrentUserType } from '../../../../store/slices/stepperSlice/asyncThunks/changeCurrentUserType';

/** Хук установки данных по выбранному табу */
export const useSetTabsInformation = () => {
  const dispatch = useAppDispatch();

  /** признак партнёрского шпд-тарифа, нужен для установки ссылок на табы */
  const isPartnerFmc = useSelectIsPartnerFmcInRegion();

  /** Текущий активный inacId ШПД (на его основе отображается текущий активный таб) */
  const currentActiveInacId = useSelectActiveInacId();

  /* Текущая ценовая категория пресета */
  const priceCategory = useAppSelector((state) => state.total.priceCategory);

  /* Общая информация по ШПД табам (весь контент) */
  const { preparedFmcInfo, isLoadingFmcRequest } = usePrepareFmcTabs();

  /** Функция-обработчик клика по табу ШПД */
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    /* Текущий выбираемый InacId пользователем */
    const activeInacId = e.target?.value;

    /* Получаем данные для активного ШПД-таба */
    const {
      link,
      convergenceBlock = {},
      priceList,
    } = getCurrentConvergenceInfo(preparedFmcInfo, activeInacId) ?? {};

    /* Забираем speedText для активного ШПД-таба */
    const { speedText = '' } = priceList as TPriceListItem;

    /* При клике на таб - проверить наличие признака партнёрского шпд, если true - редирект на страницу тарифа */
    if (isPartnerFmc && link && window.location.pathname !== link) {
      window.location.pathname = link;
    }

    /* Выставляем текущий активный таб ШПД (определяется путем обновления tariffInacId в total) */
    await dispatch(changeFmcInacId(activeInacId));

    /* Перезаписываем convergenceBlock исходя из параметров запроса по доступным ШПД */
    dispatch(setConvergenceBlock(convergenceBlock));

    /* Определение текущего типа пользователя через общий AsyncThunk */
    dispatch(changeCurrentUserType({ handleModalOpen: () => {} })).catch((error: Error) =>
      console.error(error),
    );

    /* Перезаписываем netSpeed для блока Домашний интернет и ТВ */
    dispatch(setNetSpeed(speedText));

    /* Отправка аналитики при клике по табам */
    sendFmcYm(speedText);
  };

  /* Собираем данные для китовых (ChipsStateless) табов (title, description и value)
   отфильтрованных по наличию priceCategory */
  const options = prepareFmcTabs(priceCategory, extractFmcTabs(preparedFmcInfo));

  /* Пропы для китового компонента ChipsStateless */
  const chipsStatelessFmcProps: ChipsPropsType = {
    options,
    onChange,
    variant: 'card',
    checkedValues: currentActiveInacId,
  };

  useEffect(() => {
    /* Если НИ одна опция не имеет текущий активный inacID (currentActiveInacId), то
     * действуем по правилу из ТЗ:
     * Выбором должна стать следующая доступная скорость вниз
     * (например, если было 300 мб/с и при новом пресете мобильной связи нет шпд интернета со скоростью 300, то
     * выбором должно стать 100 мб/с. Если нет 100 мб/с, то "Без интернета") */
    if (!options?.some((option) => option.value === currentActiveInacId)) {
      /* Выбираем недоступный индекс пресета ШПД (без priceList) - скорость ШПД недоступно по текущей priceCategory */
      const unavailableIndex = preparedFmcInfo.findIndex(
        (item) => item.priceList === undefined && item.inacId === currentActiveInacId,
      );

      /* Если была найдена недоступная скорость, переключаем пользователя на более низкий пресет ШПД */
      if (unavailableIndex > 0) {
        dispatch(changeFmcInacId(preparedFmcInfo[unavailableIndex - 1].inacId)).catch((e: Error) =>
          console.error(e),
        );
      }
    }
  }, [priceCategory]);

  return { isLoadingFmcRequest, chipsStatelessFmcProps };
};
