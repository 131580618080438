import React, { FC } from 'react';

import { Layout } from 'constructorV1/components/commonComponents/Layout';
import { Benefits } from 'constructorV1/components/tempComponents/Benefits';
import { Container } from 'constructorV1/components/commonComponents/Container';
import { CustomFaq } from 'constructorV1/components/commonComponents/CustomFaq';
import { PresetPickers } from 'constructorV1/components/constructorPage/PresetPickers';
import { BillContainer } from 'constructorV1/components/constructorPage/BillContainer';
import { MainContainer } from 'constructorV1/components/commonComponents/MainContainer';
import { FloatingTotal } from 'constructorV1/components/commonComponents/FloatingTotal';
import { OptionContainer } from 'constructorV1/components/constructorPage/OptionContainer';
import MainTitle from 'constructorV1/components/commonComponents/MainTitle';

/** Контейнер главной страницы ConstructorV1 */
export const ConstructorMain: FC = () => {
  return (
    <>
      {/* Главный заголовок */}
      <MainTitle />
      {/* Общий контейнер ЕК */}
      <MainContainer>
        <Container>
          {/* Левая часть ЕК с основными настройками */}
          <Layout>
            {/* Опциональный блок с описанием тарифа для НЗ */}
            <Benefits />
            {/* Пресеты */}
            <PresetPickers />
            {/* Опции */}
            <OptionContainer />
          </Layout>
          {/* Правая часть ЕК с чеком */}
          <Layout sideType="right">
            {/* Чек ЕК */}
            <BillContainer />
          </Layout>
        </Container>
        {/* FAQ ЕК */}
        <CustomFaq />
        {/* Плавающий блок с ценой ЕК (для мобильной версии) */}
        <FloatingTotal />
      </MainContainer>
    </>
  );
};
