import { cloneElement } from 'react';
import { connect } from 'react-redux';

import { pushClick } from 'pages/SearchResultsPage/utils/analytics';
import { getPlaceForAnalytics } from 'pages/SearchResultsPage/store/selectors';

export const _SortWithAnalytics = ({ children, location }) => {
  const handleChange = (args) => {
    children.props.onChange?.(args);
    pushClick({ target: 'sort', location, data: args });
  };
  return cloneElement(children, { onChange: handleChange });
};

export const SortWithAnalytics = connect((state) => ({ location: getPlaceForAnalytics(state) }))(
  _SortWithAnalytics,
);
