import React, { FC } from 'react';
import { ReloadIcon, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { usePrepareErrorLoadingProps } from './hooks/usePrepareErrorLoadingProps';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент отрисовки ошибки и отправки повторого запроса на получение ШПД */
export const FmcLoadingError: FC<React.HtmlHTMLAttributes<HTMLButtonElement>> = () => {
  const { handleClick, errorLoadingText } = usePrepareErrorLoadingProps();

  return (
    <section className={cx('error')}>
      <button className={cx('wrapper', 'button-reset')} onClick={handleClick} type="button">
        <Text color="grey60" size="size4-r">
          {errorLoadingText}
        </Text>
        <ReloadIcon />
      </button>
    </section>
  );
};
