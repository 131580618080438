import React, { useMemo } from 'react';

type Ref<T> = React.MutableRefObject<T> | React.RefCallback<T> | undefined;

export const setRef = <T>(ref: Ref<T>, value: T) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
  }
};

export const forkRef = <T>(refs: (React.Ref<T> | undefined)[]): React.RefCallback<T> | null => {
  if (!refs.length) {
    return null;
  }
  return (refValue) => {
    refs.forEach((ref) => {
      setRef(ref as React.MutableRefObject<T>, refValue);
    });
  };
};

export const useForkRef = <T>(refs: (React.Ref<T> | undefined)[]) =>
  useMemo(() => forkRef(refs), [refs]);
