import React from 'react';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import { FixedOverlay } from 'pages/SearchResultsPage/components/Settings/FixedOverlay';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const MobileFilterPanel = ({ isOpen, onClose, children }) => (
  <div className={cx('container')}>
    <CSSTransition
      classNames={{
        enter: cx('enter'),
        enterActive: cx('enter-active'),
        exit: cx('exit'),
        exitActive: cx('exit-active'),
      }}
      in={isOpen}
      timeout={300}
      unmountOnExit
    >
      <FixedOverlay className={cx('overlay')} isOpen onClose={onClose}>
        {children}
      </FixedOverlay>
    </CSSTransition>
  </div>
);

MobileFilterPanel.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
