import { MutableRefObject, useMemo } from 'react';
import { FaqPropsInterface } from '@beef/smart-kit/';

import { useAppSelector } from '../../../../store';
import { useOnScreenAnalytics } from '../../../../tempAnalytics/hooks/useOnScreenAnalytics';
import { pushYm } from '../../../../tempAnalytics/metricUtils';

export type TOnClick = {
  panelId: string;
  tabId: string;
};

type TUseSelectFAQContent = {
  faqRef: MutableRefObject<HTMLDivElement | null>;
};

/* TODO: разобраться с ошибкой TS2742 */
/** Селектор выборки FAQ */
export const useSelectFAQContent = ({ faqRef }: TUseSelectFAQContent) => {
  /* Подготовка контента для faq */
  const content = useAppSelector((state) => state.common?.content);
  const faq: FaqPropsInterface | undefined = content?.faq;

  /* Ниже только логика для отправки метрики */

  /* Отправка аналитики если блок видим больше 2х секунд */
  useOnScreenAnalytics({ ref: faqRef, payload: { block_faq: 'view' } });

  /* Используется для идентификации выбранного таба фака */
  let tempTabId = '1';

  /* Смена выбранного таба фака */
  const closureTabId = (tabId: string) => {
    tempTabId = tabId;
  };

  /* Забирает title необходимый для метрики */
  const getTitle = (panelId: string) => {
    const { tabs } = faq?.data?.[Number(tempTabId) - 1] || {};
    return tabs?.find((item: Record<string, any>) => item.id === panelId)?.title;
  };

  /* Сет для поиска уникальности */
  const set = useMemo(() => new Set(), []);

  /** Ф-я проп для Faq из @beef. При клике плитку табов получает tabId: string, panelId: ''
    при елике на expand panel получает  tabId: '', panelId: string
   Отправляет метрику только на разворачивание списка
   */
  const onClick = ({ panelId, tabId }: TOnClick) => {
    if (tabId) {
      closureTabId(tabId);
      return;
    }

    const title = getTitle(panelId);

    /* Для уникальности берется id списка и номер таба */
    const tempId = `${panelId}_${Number(tempTabId) - 1}`;
    if (set.has(tempId)) {
      set.delete(tempId);
    } else {
      set.add(tempId);

      /* Оправка метрики */
      pushYm({
        block_faq: 'click',
        faq_title: title,
      });
    }
  };

  return { faq, onClick };
};
