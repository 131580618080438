import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';

const ICON_URL = '//static.beeline.ru/upload/images/digital-tariffs/';
const IMAGES_TYPES = {
  beelinemusic: 'terentyi_music',
  bookspackage: 'terentyi_kiozk',
  cloudpackage: 'terentyi_cloud',
  filmspackage: 'terentyi_movie',
  beelinecommunication: 'terentyi_communication',
};

class DigitalBenefitsHoc extends Component {
  state = {
    benefits: [],
  };

  componentDidMount() {
    const benefits = this.props.benefits.map(this.getBenefits);
    this.setState({ benefits });
  }

  getImageUrl = ({ widgetTypeName, widgetType }) => {
    if (!widgetType) {
      return null;
    }
    return IMAGES_TYPES[widgetTypeName] ?
        `${ICON_URL}${IMAGES_TYPES[widgetTypeName]}`
      : `${ICON_URL}${widgetType}`;
  };

  getBenefits = (item) => {
    // Переводим в нижний регистр, так как параметры приходят из DPC в верхнем регистре или CamelCase;
    const widgetTypeName = item.widgetType.toLowerCase();
    const imageUrl = this.getImageUrl({ widgetTypeName, widgetType: item.widgetType });

    return {
      ...item,
      widgetType: widgetTypeName,
      picture: imageUrl ? `${imageUrl}.svg` : null,
    };
  };

  render() {
    const { benefits } = this.state;

    return cloneElement(this.props.children, {
      onClick: this.getBenefits,
      benefits,
    });
  }
}

DigitalBenefitsHoc.propTypes = {
  children: PropTypes.object,
  benefits: PropTypes.array,
};

export default DigitalBenefitsHoc;
