import { useSetTabsInformation } from './useSetTabsInformation';
import { useFmcTabPopupActivation } from './useFmcTabPopupActivation';
import { useStepNavigation } from '../../../../hooks/useStepNavigation';
import { EUserStoryType } from '../../../../store/slices/stepperSlice/types';
import { useSelectLoadingFmcError } from '../selectors/useSelectLoadingFmcError';
import { useArchivedCases } from '../../../../hooks/tariffStateHooks/useArchivedCases';
import { useIsArchivedTariff } from '../../../../hooks/tariffStateHooks/useIsArchivedTariff';
import {
  useSelectIsFmcRegion,
  useSelectIsPartnerFmcInRegion,
} from '../selectors/useSelectIsFmcRegion';

/** Хук подготовки общих данных для компонента FmcTabsContainer */
export const usePrepareFmcTabsContainer = () => {
  const isArchived = useIsArchivedTariff();
  /* Устанавливаем дефолтный активный таб и подготавливаем контент для модального окна */
  const { isOpen, fmcModalData, handleShowPopup } = useFmcTabPopupActivation();
  const { userType } = useStepNavigation();

  const hasFmcRegion = useSelectIsFmcRegion();
  const hasPartnerFmcRegion = useSelectIsPartnerFmcInRegion();
  const hasErrorFmcPresets = useSelectLoadingFmcError();
  const { isLoadingFmcRequest, chipsStatelessFmcProps } = useSetTabsInformation();
  const isAuthConnectedSettingsFTTBUserType = userType === EUserStoryType.authConnectedSettingsFTTB;
  const isShowFmcTabs =
    !isAuthConnectedSettingsFTTBUserType &&
    !isLoadingFmcRequest &&
    !hasErrorFmcPresets &&
    !isArchived;

  /* Тариф не ап с конвергенцией */
  const isArchivedNoTariffUpWithtFttb =
    userType === EUserStoryType.tariffUpTransitionFromArchiveTariff;

  /* Вычисляем показ блока архивного ап без шпд */
  const { isArchivedTariffUpWithoutFttb } = useArchivedCases();
  const isAuthConnectedWithoutFTTB = userType === EUserStoryType.authConnectedWithoutFTTB;
  const isShowArchivedWithoutFttbBlock =
    (isArchivedTariffUpWithoutFttb && isAuthConnectedWithoutFTTB) ||
    userType === EUserStoryType.tariffUpArchiveWithoutFTTB;

  return {
    fmcTabModalProps: {
      isOpen,
      data: fmcModalData,
      onClose: handleShowPopup,
    },
    hasFmcRegion,
    isShowFmcTabs,
    hasErrorFmcPresets,
    isLoadingFmcRequest,
    hasPartnerFmcRegion,
    chipsStatelessFmcProps,
    isArchivedNoTariffUpWithtFttb,
    isShowArchivedWithoutFttbBlock,
    isAuthConnectedSettingsFTTBUserType,
  };
};
