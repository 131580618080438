import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import type { TButton } from './types';

const cx = classNames.bind(styles);

export const Button: FC<TButton> = ({
  children,
  className,
  color = 'yellow',
  onClick,
  size = 'l',
  type = 'button',
}) => (
  <button
    className={cx(
      'button',
      'active',
      {
        [`active--${color}`]: Boolean(color),
        [`active--${size}`]: Boolean(size),
        [`button--${color}`]: Boolean(color),
        [`button--${size}`]: Boolean(size),
      },
      className,
    )}
    onClick={onClick}
    /* из-за динамического проброса типа дизейблим правило */
    // eslint-disable-next-line react/button-has-type
    type={type}
    // disabled
  >
    {children}
  </button>
);
