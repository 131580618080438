import React from 'react';
import PropTypes from 'prop-types';

const ContentWrapper = ({ isVisiblePanel, children }) => {
  if (!isVisiblePanel) {
    return null;
  }

  return <>{children}</>;
};

ContentWrapper.propTypes = {
  isVisiblePanel: PropTypes.bool,
  children: PropTypes.element,
};

export { ContentWrapper };
