import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from '@beef/legacy-ui-kit';

import SocialButtons from 'pages-components/SocialButtons/SocialButtons';
import SmsForm from 'widgets/AppLinkSmsPopup/SmsForm';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class MobileAppBanner extends Component {
  state = {
    displayType: 'plain',
    step: 'ready',
    error: null,
  };

  onMobileDetect = (device) => {
    this.setState({ displayType: device ? 'buttons' : 'form' });
  };

  onStepChange = (step, error = null) => {
    this.setState({ step, error });
  };

  onMoreClick = () => {
    this.setState({
      step: 'ready',
      error: null,
    });
  };

  render() {
    const {
      texts,
      desktopImage,
      levitateImage,
      shadowImage,
      descriptionLink,
      sendMessageUrl,
      appButtons,
      currentCtn,
      className,
    } = this.props;

    const { displayType, step, error } = this.state;

    return (
      <div className={cx('component', className)}>
        <div className={cx('mobilePhones')}>
          <img alt="" className={cx('shadow')} src={shadowImage} />
          <img alt="" className={cx('phonesImage')} src={desktopImage} />
          <img alt="" className={cx('levitatingPhone')} src={levitateImage} />
        </div>
        <div className={cx('content')}>
          <div className={cx('heading')} dangerouslySetInnerHTML={{ __html: texts.title }} />
          <Link className={cx('description')} href={descriptionLink}>
            <span dangerouslySetInnerHTML={{ __html: texts.description }} />
          </Link>
          <div className={cx('appButtons', { hidden: displayType !== 'buttons' })}>
            <SocialButtons data={appButtons} onMobileDetect={this.onMobileDetect} />
          </div>
          {displayType === 'form' && step === 'ready' && (
            <SmsForm
              className={cx('smsForm')}
              currentCtn={currentCtn}
              onStepChange={this.onStepChange}
              sendMessageUrl={sendMessageUrl}
              texts={texts}
            />
          )}
          {displayType === 'form' && step === 'finished' && (
            <div className={cx('result')}>
              {error ? texts.resultError : texts.resultSuccess}
              <br />
              <Link onClick={this.onMoreClick}>Отправить еще</Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

MobileAppBanner.propTypes = {
  texts: PropTypes.object,
  desktopImage: PropTypes.string,
  levitateImage: PropTypes.string,
  shadowImage: PropTypes.string,
  descriptionLink: PropTypes.string,
  sendMessageUrl: PropTypes.string,
  appButtons: PropTypes.array,
  currentCtn: PropTypes.number,
};

export default MobileAppBanner;
