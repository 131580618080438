// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { useAppSelector } from 'constructorV1/store';
import { selectCBillContent } from 'constructorV1/store/selectors/selectCommon';

/** Хук подготовки данных для аккордеона */
export const useCAccordion = (isWithoutPay: boolean = false) => {
  /* Контентные заголовки для аккордеона в возможных вариациях */
  const { conditionsTitle, conditionsWTitle } = useAppSelector(selectCBillContent);

  /** Результирующий заголовок аккордеона */
  const title = isWithoutPay ? conditionsWTitle : conditionsTitle;

  /** Результирующий цвет */
  const color = isWithoutPay ? 'primary-invert' : 'primary';

  return { color, title };
};
