import { shallowEqual } from 'react-redux';

import { vkPushEvent } from 'utils/analytics/vkEvents';
import { VkGoalEvent } from 'pages/FTTB2022/CatalogPage/deprecated/blocks/constants';
import { ETariffsVkGoals, vkPushSimTariffEvent } from 'utils/analytics/simTariffVkEvents';

import { TabTypes } from '../../../constructorPage/Tabs/constants';
import { useSelectTariffTab } from '../../../constructorPage/Tabs/selectors/useSelectTariffTab';
import { usePrepareTotalYm } from '../../../../tempAnalytics/hooks/usePrepareTotalYm';
import { useAppSelector } from '../../../../store/index';
import { useStepNavigation } from '../../../../hooks/useStepNavigation';
import { pushYm } from '../../../../tempAnalytics/metricUtils';
import { EConstructorStep } from '../../../../store/slices/stepperSlice/types';

type TGetAnalyticsByStep = Partial<Record<EConstructorStep, () => void>>;

/** Отправка метрики по клику "Далее" на разных страницах */
export const useSendBillYm = () => {
  /* Подготовка данных и отправка метрики */
  const handlePushYm = usePrepareTotalYm({ type: 'bill' });

  const { currentStep } = useStepNavigation();
  const { currentTariffTab } = useSelectTariffTab();

  /* Получаем установленное значение netSpeed */
  const { netSpeed, tariffAlias } = useAppSelector(
    (state) => ({
      netSpeed: state.total.convergenceInfo.netSpeed,
      tariffAlias: state.total.alias,
    }),
    shallowEqual,
  );

  const getAnalyticsByStep: TGetAnalyticsByStep = {
    /*  Отправка метрики по клику "Далее" на странице конструктора */
    [EConstructorStep.constructor]: () => {
      handlePushYm();
      vkPushEvent(VkGoalEvent.shpdTvIntOpenForm);
      if (currentTariffTab === TabTypes.SimOrderForm) {
        vkPushSimTariffEvent(tariffAlias, ETariffsVkGoals.SimTariffBasket);
      }
    },
    /*  Отправка метрики по клику "Далее" на странице settingsFttbAddressPage */
    [EConstructorStep.fttbAddress]: () => {
      pushYm({
        fmc_step: 'address',
        action: 'click_next',
      });
      vkPushEvent(VkGoalEvent.shpdTvIntInitiateCheckout);
    },
    /* Отправка метрики по клику "Далее" на странице settingsFttbAndTvPage */
    [EConstructorStep.fttbSettings]: () => {
      pushYm({
        fmc_step: 'custom',
        action: 'click_next',
        net_speed: netSpeed,
      });
    },
  };

  return getAnalyticsByStep[currentStep] || (() => {});
};
