// Constants
import { DEFAULT_TAB_VALUE, popupSteps } from '../constants';
/**
 * dataConverter
 * @param soc
 * @param hasInacId
 * @param isOldConvergence
 * @param seamlessUrlParams
 * @param convergenceContent
 * @param hasSeamlessUrlParams
 * @param connectConvergenceTariffs
 * @param discountDescription
 * @param activePresetTab
 * connectedTariff: текущий подключенный тариф.
 * connectedTariffIdx: индекс подключенного тарифа в массиве доступных тарифов.
 * Определяет активный таб (connectedTab) при открытии попапа.
 * connectedTab: интекс таба подключенного тарифа.
 * currentStep: текущий шаг в попапе ШПД.
 * isArchiveTariff: является ли текущий тариф архивным.
 * isConnectedTariff: является ли текущий добавленный тариф в totalSettings уже подключенным.
 * discountDescription: текст скидки для табов тарифов
 * activePresetTab: выбранный таб
 */
export const dataConverter = ({
  soc,
  hasInacId,
  isOldConvergence,
  seamlessUrlParams,
  convergenceContent,
  hasSeamlessUrlParams,
  connectConvergenceTariffs,
  discountDescription,
}) => {
  const filterConvergenceTariffs = () =>
    connectConvergenceTariffs?.find(
      (tariff) => tariff.isConnected || tariff?.inacId === seamlessUrlParams?.inacId,
    ) || {};
  // connectConvergenceTariffs в некоторых ситуациях может быть пуст
  const connectedTariff =
    isOldConvergence ?
      connectConvergenceTariffs[DEFAULT_TAB_VALUE] || {}
    : filterConvergenceTariffs();
  const connectedTariffIdx = connectConvergenceTariffs?.indexOf(connectedTariff);
  const connectedTab =
    connectedTariffIdx >= 0 ? connectedTariff?.inacId : connectConvergenceTariffs?.[0]?.inacId;
  const showTariffDetailsForm =
    isOldConvergence ||
    Object?.keys(connectedTariff)?.length ||
    (hasInacId && hasSeamlessUrlParams);
  const currentStep =
    showTariffDetailsForm ? popupSteps.tariffDetailsForm : popupSteps.addressRequestForm;
  const isConnectedTariff = (connectedTariffIdx >= 0 || isOldConvergence) && !hasSeamlessUrlParams;
  const isArchiveTariff = connectConvergenceTariffs?.some((tariff) => tariff.isArchive);

  return {
    common: {
      content: convergenceContent,
      region: convergenceContent?.currentRegion,
      isAuthenticated: convergenceContent?.userInfo?.isAuthorized,
    },
    orderTariffUserData: {
      selectedRegion: convergenceContent?.currentRegion,
      userPhoneNumber: convergenceContent?.userInfo?.activeCTN,
      isConvergent: convergenceContent?.userInfo?.isConvergenceUser,
    },
    orderTariffDetailsData: {
      discountDescription,
      hasInacId,
      animalSoc: soc,
      seamlessUrlParams,
      hasSeamlessUrlParams,
      activeTab: connectedTab,
      activeSettingsTab: connectedTab,
      tariffs: connectConvergenceTariffs,
      activateDisconnectConvergent: convergenceContent?.activateDisconnectConvergent,
    },
    modal: {
      currentStep,
    },
    totalSettings: {
      tariff: connectedTariff,
      isArchive: isArchiveTariff,
      isConnected: isConnectedTariff,
    },
  };
};
