/**
 * @desc: получение данных из мета-тега по имени мета-тега
 * @param name
 * @returns {null|*}
 */
const getMetaValueByName = (name) => {
  try {
    return document.querySelector(`meta[name="${name}"]`).content;
  } catch (e) {
    if (process.env.NODE_ENV !== 'development') {
      console.error(e);
    }
  }
  return null;
};

export default getMetaValueByName;
