import React from 'react';
import { Faq } from '@beef/smart-kit';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { useYMFaq } from 'pages/MnpOrderNew/components/Faq/hook';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const FaqWrapper = ({ data }) => {
  const { handleClick, rootRef } = useYMFaq(data);

  return (
    <div className={cx('faq')} ref={rootRef}>
      <Faq {...data} onClick={handleClick} />
    </div>
  );
};

Faq.propTypes = {
  data: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.shape({
      title: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
};
