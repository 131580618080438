// @ts-expect-error legacy-ui-kit не типизировн
import { Link } from '@beef/legacy-ui-kit';
import React, { FC } from 'react';

import { ZoneLinkType } from 'pages/FTTB/StreetsMainPage/components/ZonesList/types';

export const ZoneLink: FC<ZoneLinkType> = ({ zone }) => (
  <Link className="hideVisitedColor" href={zone?.url}>
    <span dangerouslySetInnerHTML={{ __html: zone.title }} />
  </Link>
);
