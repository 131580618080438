import { FaqPropsInterface } from '@beef/smart-kit';

import { ANALYTICS } from 'pages/PromotionsPage/analytics';
import { RootPageStore } from 'pages/PromotionsPage/types';

export const onFAQClickYm =
  ({ panelId }: FaqPropsInterface) =>
  (dispatch: any, getState: () => RootPageStore) => {
    const [faq] = getState().content?.faq?.data || [];
    const faqTitle = faq.tabs.find(({ id }: FaqPropsInterface) => id === panelId)?.title;
    ANALYTICS.FAQ_CLICK.sendParams(faqTitle);
  };
