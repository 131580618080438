import React from 'react';
import PropTypes from 'prop-types';
import { Button, CollapsedContent, Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';

import TextContainer from '../TextContainer/TextContainer';
import commonStyles from '../SharingService.pcss';
import styles from './InvitationPopup.pcss';

const cx = classNames.bind({ ...styles, ...commonStyles });

const InvitationPopup = ({
  numbers,
  opened,
  onClose,
  maxNumbers,
  onAddNumber,
  switchInvitationHelp,
  switchHowItWorks,
}) => {
  const addedNumber = numbers[numbers.length - 1] || {};

  return (
    <PopupStateless onClose={onClose} opened={opened}>
      <div className={cx('content')}>
        <Heading level={1}>
          <TextContainer className={cx('title')} path={['texts', 'invitationTitle']} />
        </Heading>
        <div>
          <TextContainer
            className={cx('description')}
            path={['texts', 'invitationDescription']}
            variables={{ lastNumber: addedNumber.number || ', который вы указали' }}
          />
        </div>
        <div>
          <TextContainer className={cx('description')} path={['texts', 'invitationInfo']} />
        </div>
        {numbers.length >= maxNumbers ? null : (
          <div className={cx('addNumberButton')}>
            <Button className="big" onClick={onAddNumber} wide>
              <TextContainer path={['texts', 'invitationButtonTitle']} />
            </Button>
          </div>
        )}
        <div className={cx('links', 'closeLink')}>
          <Link onClick={onClose}>
            <TextContainer className={cx('infoLink')} path={['texts', 'invitationLinkClose']} />
          </Link>
        </div>
        <div className={cx(['links', 'help-links'])}>
          <CollapsedContent
            contentClass={cx(['description', 'collapsed-content'])}
            title={switchInvitationHelp}
          >
            <TextContainer path={['texts', 'invitationHelp']} />
          </CollapsedContent>
          <CollapsedContent
            contentClass={cx(['description', 'collapsed-content'])}
            title={switchHowItWorks}
          >
            <TextContainer path={['texts', 'invitationHowItWorks']} />
          </CollapsedContent>
        </div>
      </div>
    </PopupStateless>
  );
};

InvitationPopup.defaultProps = {
  numbers: [],
};

InvitationPopup.propTypes = {
  opened: PropTypes.bool,
  maxNumbers: PropTypes.number,
  numbers: PropTypes.array,
  onAddNumber: PropTypes.func,
  onClose: PropTypes.func,
  switchInvitationHelp: PropTypes.string,
  switchHowItWorks: PropTypes.string,
};

export default InvitationPopup;
