/** Ф-я возввращает строковое представление цены,
 * округленное до 2х символов после запятой, если число не целое */
export const getRoundedPrice = (value?: number | string): string => {
  const isNumber = typeof value === 'number' && (value || value === 0);
  const isNumberString = typeof value === 'string' && value.trim() && !Number.isNaN(Number(value));

  if (!(isNumber || isNumberString)) {
    return '';
  }

  const num = typeof value === 'string' ? Number(value) : value;

  return Number.isInteger(num) ? num.toString() : num?.toFixed(2);
};
