import React from 'react';

import { selectHeadBlockData } from 'pages/PartnerServiceCard2022/store/selectors/index';

import { ContentInfo } from '../ContentInfo/index';

export const TeaserBannerContent: React.FC<{
  flags: {
    isConnected: boolean;
    isOnScreen: boolean;
    isSticky: boolean;
    isYandex: boolean;
  };
  info: ReturnType<typeof selectHeadBlockData>['content']['info'];
}> = (props) => {
  const { info, flags } = props;
  return (
    <>
      <ContentInfo content={info} isConnected={!!flags?.isConnected} isYandex={!!flags?.isYandex} />
      {!!flags.isSticky && !flags.isOnScreen && (
        <ContentInfo content={info} isConnected={!!flags.isConnected} isSticky />
      )}
    </>
  );
};
