import React, { useLayoutEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { constructorV1Pages } from 'constructorV1/router/pathes';
import { ConstructorMain } from 'constructorV1/pages/ConstructorMain';
import { ConstructorBill } from 'constructorV1/pages/ConstructorBill';
import { getEnvBaseUrl } from 'constructorV1/router/utils/getEnvBaseUrl';
import { ConstructorResult } from 'constructorV1/pages/ConstructorResult';
import { useStartConstructor } from 'constructorV1/hooks/useStartConstructor';

import { WithReactRouterProps, withReactRouter } from '../../../enhancers/withReactRouter';

/* Главный роутер конструктора. Содержит HOC для корректной работы SSR */
const ConstructorV1Router = ({ baseUrl: serverBaseUrl }: WithReactRouterProps) => {
  // Используем baseUrl из пропов гидрейта для предотвращения задержки получения данных из стора
  // Мемоизируем актуальный baseUrl в зависимости от окружения.
  // Здесь нельзя использовать утилиту getEnvBaseUrl, так как для локалки важно получать "/" как baseUrl
  const baseUrl = useMemo(() => {
    return process.env.NODE_ENV === 'development' ? '/' : serverBaseUrl;
  }, [serverBaseUrl]);

  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  // Этот урл нужен только для корректной работы дефолтного редиректа на локалке.
  // В прод режиме будет baseUrl === defaultUrl
  const defaultUrl = getEnvBaseUrl(serverBaseUrl || '/');

  /* Стартовый хук ConstructorV1 */
  useStartConstructor();

  return (
    <Routes>
      <Route element={<ConstructorMain />} errorElement={<p>error</p>} path={baseUrl} />
      <Route element={<ConstructorBill />} path={`${baseUrl}${constructorV1Pages.bill}`} />
      <Route element={<ConstructorResult />} path={`${baseUrl}${constructorV1Pages.result}`} />
      <Route element={<Navigate replace to={defaultUrl} />} path="*" />
    </Routes>
  );
};

export default withReactRouter(ConstructorV1Router);
