import axios, { AxiosRequestConfig } from 'axios';

import { ALLINONE_GET_HOUSE } from 'constants/Endpoints';

type THouseRest = {
  id: number;
  isConnected: boolean;
  label: string;
};

/* Функция проверки доступности подключения выбранного дома */
export async function getHouseSearchResults(streetId: number | undefined, term: string) {
  if (!streetId) {
    return [];
  }
  return (
    await axios.post(ALLINONE_GET_HOUSE, { streetId, term }, {
      'X-Requested-With': 'XMLHttpRequest',
    } as AxiosRequestConfig)
  ).data.map(({ alias, ...rest }: { alias: string; rest: THouseRest }) => ({
    slug: alias,
    alias,
    ...rest,
  }));
}
