import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Button, Heading, Link } from '@beef/legacy-ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CallBack = ({ data, onSubmit, setAnalytics }) => (
  <div>
    <Heading level={1}>
      <span dangerouslySetInnerHTML={{ __html: data.title }} />
    </Heading>
    <div className={cx('description')} dangerouslySetInnerHTML={{ __html: data.description }} />
    {data.buttonText && data.buttonUrl && (
      <Button
        className="big"
        onClick={() => {
          onSubmit(data.buttonUrl, null, true);
          setAnalytics('activation');
        }}
        transformer
      >
        {data.buttonText}
      </Button>
    )}
    {data.buttonResetText && data.buttonResetUrl && (
      <Link
        className={cx('decline')}
        onClick={() => {
          onSubmit(data.buttonResetUrl);
          setAnalytics('refuse');
        }}
      >
        {data.buttonResetText}
      </Link>
    )}
  </div>
);

CallBack.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  setAnalytics: PropTypes.func,
};

export default CallBack;
