import React from 'react';
import { TagType } from '@beef/ui-kit';

import { ComponentWithAs, PropsWithTagAttributes } from './PropsWithTagAttributes';

type ForwardRefWithAs<Props> = <Tag extends TagType>(
  props: PropsWithTagAttributes<Props, Tag>,
  ref: React.ForwardedRef<HTMLElement>,
) => React.ReactElement | null;

export function forwardRefWithTag<Props, Tag extends TagType = 'div'>(
  render: ForwardRefWithAs<Props>,
) {
  return React.forwardRef<HTMLElement, PropsWithTagAttributes<Props, Tag>>(
    render,
  ) as unknown as ComponentWithAs<Props, Tag>;
}
