import { useThrowAsyncError } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/hooks/useThrowAsyncError';
import { goToFinalPageStep } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/stepperSlice';
import { fetchSubmitCallbackRequest } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/api/fetchSubmitCallbackRequest';
import {
  useAppDispatch,
  useAppSelector,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';
import { CALLBACK_CASE_TILE_SUPPORT } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/constants';

/** Хук подготовки ф-ции отправки заявки на обратный звонок */
export const useSubmitCallbackRequest = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();
  const phoneNumber = useAppSelector((state) => state.total.userPhoneNumber) as string;

  return () =>
    dispatch(fetchSubmitCallbackRequest({ phoneNumber, caseTile: CALLBACK_CASE_TILE_SUPPORT }))
      .unwrap()
      .then(() => dispatch(goToFinalPageStep()))
      .catch((err: Error) => throwAsyncError(err));
};
