import { Subscription } from 'rxjs';

import { tryGetYandexSubscriptions } from './actions';
import { selectPowersProps } from './selectors';
import { TariffUpMetaData } from './store/TariffUpMetaData';
import { TariffUpPowers } from './store/TariffUpPowers';
import { TCharactersTabsProps } from './types';

const processProps = (props: Partial<TCharactersTabsProps>) => {
  const isAuthenticated = TariffUpMetaData.IsAuthenticated;
  const products = TariffUpMetaData.Products;
  const powers = selectPowersProps([products, props, isAuthenticated]);

  return { ...props, powers };
};

export const converter = (props: Partial<TCharactersTabsProps>) => {
  let subscription: Subscription | undefined;

  if (props) {
    TariffUpPowers.CharactersTabsData = props;
  }

  const onMount = async () => {
    subscription = TariffUpMetaData.ObservableState.subscribe(async () => {
      if (TariffUpMetaData.IsAuthenticated) {
        await tryGetYandexSubscriptions();
        subscription?.unsubscribe();
      }
    });
  };

  const onUnmount = () => {
    subscription?.unsubscribe();
  };

  const nextProps = processProps(props);

  return {
    ...nextProps,
    onMount,
    onUnmount,
  };
};
