import React from 'react';

const sidebarPropPosition = ['left', 'bottom', 'right', 'top'] as const;
type SidebarPropPosition = (typeof sidebarPropPosition)[number];
export const sidebarPropPositionDefault: SidebarPropPosition = sidebarPropPosition[0];

export interface ISidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  container?: HTMLElement | undefined;
  hasOverlay?: boolean;
  isOpen?: boolean;
  onClickOutside?: (event: MouseEvent) => void;
  onEsc?: (event: KeyboardEvent) => void;
  position?: SidebarPropPosition;
  rootClassName?: string;
}
