import React, { FC } from 'react';

import { ModalHeader } from '../../../../modalComponents/ModalHeader';
import { ModalContainer } from '../../../../modalComponents/ModalContainer';
import type { TPartnerFmcPopup } from '../../../../../store/slices/commonContentSlice/types';

type TFmcTabModal = {
  data: TPartnerFmcPopup;
  isOpen: boolean;
  onClose: () => void;
};

export const FmcTabModal: FC<TFmcTabModal> = ({ data, isOpen, onClose }) => (
  <ModalContainer
    isOpen={isOpen}
    modalActionBarProps={{
      buttonText: data.buttonText,
      onClick: onClose,
    }}
    onClose={onClose}
    size="m"
  >
    <ModalHeader {...data} />
  </ModalContainer>
);
