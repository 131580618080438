/** Типы sim-карты */
export enum ESimCardType {
  sim = 'sim',
  mnp = 'eSim',
}

/** Типы подключения */
export enum EConnectionType {
  newSim = 'newSim',
  mnp = 'mnp',
  changeTariff = 'changeTariff',
}

/** Тип слайса юзера */
export type TUserSlice = {
  activeCTN?: string;
  connectionType: EConnectionType;
  isArchived: boolean;
  isAuthenticated: boolean;
  isConnected: boolean;
  isFamilyConnected: boolean;
  simCardType?: ESimCardType;
};
