import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffBonus = ({ benefit }) => {
  const { icon, textValue, legal } = benefit;

  return (
    <div className={cx('bonus')}>
      <div className={cx('bonusRow')}>
        <Picture className={cx('bonusIcon')} src={icon} />
        <div>
          <span dangerouslySetInnerHTML={{ __html: textValue }} />
          {legal && (
            <Tooltip className={cx('bonusTooltip')}>
              <span dangerouslySetInnerHTML={{ __html: legal }} />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

TariffBonus.propTypes = {
  benefit: PropTypes.shape({
    icon: PropTypes.string,
    textValue: PropTypes.string,
    legal: PropTypes.string,
  }),
};

export default TariffBonus;
