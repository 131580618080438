import React from 'react';

import { CustomCardsWrapper } from './CardsWrapper';
import { CardsLayoutPropsType } from './types';
import { CustomCard } from './Card';

export const CardsLayout = ({
  type = 'imageCard',
  content = [],
  title,
  description,
  link,
  size = 's',
}: CardsLayoutPropsType) => (
  <CustomCardsWrapper {...{ title, description, link, size, type }}>
    {content.map((card, index) => (
      <CustomCard {...card} key={+index} size={size} type={type} />
    ))}
  </CustomCardsWrapper>
);

export { CustomCard } from './Card';
export { CustomCardsWrapper } from './CardsWrapper';
