import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Emoji, Tooltip } from '@beef/legacy-ui-kit';

// Styles
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Accumulator = ({ icon, value, label, disabled, emptyText, children, inline, tooltip }) => (
  <div className={cx('accumulator', { disabled }, { inline })}>
    <div className={cx('label')}>
      <div className={cx('icon')}>
        <Emoji name={icon} />
      </div>
      {label}
      {tooltip && (
        <Tooltip>
          <span dangerouslySetInnerHTML={{ __html: tooltip }} />
        </Tooltip>
      )}
    </div>
    <div className={cx('value')}>
      <span dangerouslySetInnerHTML={{ __html: emptyText || value }} />
      {children}
    </div>
  </div>
);

Accumulator.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  emptyText: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
};

Accumulator.defaultProps = {
  disabled: false,
  emptyText: '',
};

export default Accumulator;
