import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { AnimatedWrapper } from 'constructorV1/components/tempComponents/AnimatedWrapper';

import { TGiftTile } from './types';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

/** Плитка подарка плд пресетами ЕК */
export const GiftTile: FC<TGiftTile> = ({ title, subTitle, icon }) => (
  <AnimatedWrapper>
    <div className={cx('component')}>
      <img alt="" className={cx('icon')} src={icon} />
      <div className={cx('block')}>
        <Text className={cx('title')} size="size5-r-s">
          {title}
        </Text>
        <Text className={cx('description')} size="size5-r-s">
          {subTitle}
        </Text>
      </div>
    </div>
  </AnimatedWrapper>
);
