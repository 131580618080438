import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { TotalOldPrice } from '../TotalOldPrice';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TTotalSum = {
  oldPrice?: string;
  period?: string;
  price: string;
  unit?: string;
};

/** Блок с итоговой и старой ценой тарифа */
export const TotalSum = memo<TTotalSum>(({ unit = '₽', price, period, oldPrice }) => (
  <div className={cx('wrapper', { wrapper__discount: oldPrice })}>
    {price && <Text size="size1-m">{`${price} ${unit}`}</Text>}
    <TotalOldPrice {...{ unit, period, oldPrice }} />
  </div>
));
