import qs from 'query-string';
import axios from 'axios';

import { maskedPhone, notEmpty, withMinLength } from 'utils/validators';
import { unformatPhone } from 'utils/format-string';

export const getDefaultState = (fields) =>
  fields.reduce(
    (resultValue, fieldParams) => ({
      ...resultValue,
      [fieldParams.name]: fieldParams.initialValue || '',
    }),
    {},
  );

const documentFormValidator = (validateFunc) => (value) => {
  const regExpResult = value && value.match(/[a-z]/gi);

  if (regExpResult && regExpResult.length) {
    return {
      status: 'fail',
      msg: 'Введите текст кириллицей',
    };
  }

  return validateFunc(value);
};

export const digitsValidator = (validateFunc) => (value) => {
  const regExpResult = value && value.match(/[.,\/#!$%\^&\*;:{}=+\-_`~()\\\[\]a-zа-я]+/g);

  if (regExpResult && regExpResult.length) {
    return {
      status: 'fail',
      msg: 'Введите числовое значение',
    };
  }

  return validateFunc(value);
};

export const validators = {
  ctn: maskedPhone,
  lastName: documentFormValidator(withMinLength(1)),
  firstName: documentFormValidator(withMinLength(2)),
  secondName: (value) =>
    value?.length > 0 ? documentFormValidator(withMinLength(2))(value) : { status: 'ok' },
  documentSeries: withMinLength(4),
  documentNumber: withMinLength(6),
  deliveryAddress: documentFormValidator(notEmpty),
  contactPhone: maskedPhone,
  captcha: (value) =>
    value && value.length === 5 ?
      { status: 'ok' }
    : { status: 'fail', msg: 'Введите символы с картинки' },
};

export const simReplaceValidators = {
  ctn: maskedPhone,
  balance: (value) => notEmpty(value),
  phone1: digitsValidator(withMinLength(1)),
  phone2: digitsValidator(withMinLength(1)),
  lastName: documentFormValidator(withMinLength(1)),
  firstName: documentFormValidator(withMinLength(2)),
  patronymic: (value) =>
    value?.length > 0 ? documentFormValidator(withMinLength(2))(value) : { status: 'ok' },
  serial: withMinLength(4),
  number: withMinLength(6),
  deliveryAddress: documentFormValidator(notEmpty),
  contactPhone: maskedPhone,
  captcha: (value) =>
    value && value.length === 5 ?
      { status: 'ok' }
    : { status: 'fail', msg: 'Введите символы с картинки' },
};

export const getUniqueProps = (name) => {
  switch (name) {
    case 'lastName':
    case 'firstName':
    case 'secondName':
      return {
        maxLength: '100',
        filterInput: (value) => value.replace(/[^а-яa-z'`\- ]/gi, ''),
      };
  }

  return null;
};

export const checkBeelineNumber = async (url, value) => {
  const ctn = unformatPhone(value);

  try {
    const { data } = await axios.post(url, { ctn });
    return data.isBeelineNumber;
  } catch (er) {
    return false;
  }
};

export const sendData = async (url, formattedData) => {
  try {
    const { data } = await axios.post(url, qs.stringify(formattedData));
    return {
      isSucceeded: data.isSucceeded,
      showCaptcha: data.isSucceeded ? !data.isSucceeded : data.isCaptchaRequired,
      showCaptchaError: data.isCaptchaError,
    };
  } catch (er) {
    return {
      snippedDataStatus: 'attention',
    };
  }
};

export const sendOwnerData = async (url, formattedData) => {
  const { data } = await axios.post(url, qs.stringify(formattedData));
  return {
    isOwner: data.isOwner,
    isSucceeded: data.isSucceeded,
    showCaptcha: data.isSucceeded ? !data.isSucceeded : data.isCaptchaRequired,
    showCaptchaError: data.isCaptchaError,
  };
};

export const sendAdditionalInfo = async (url, formattedData) => {
  try {
    const { data } = await axios.post(url, qs.stringify(formattedData));
    return {
      isSucceeded: data.isSucceeded,
    };
  } catch (error) {
    return { step: 'error' };
  }
};
