import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { useAppSelector } from '../../../store';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Информациооный блок Условия переноса номера. Наполнен только контентными данными* */
const TermsBlock = () => {
  const termsBlock = useAppSelector((state) => state.common?.content?.mnpPage?.termsBlock);
  const { title, terms } = termsBlock || {};

  return (
    <div>
      <Text className={cx('title')} size="size3-m">
        {title}
      </Text>
      {terms?.map(({ iconUrl, description }) => (
        <div className={cx('container')} key={iconUrl}>
          <img alt="terms_icon" src={iconUrl} />
          <Text size="size5-r-s">{description}</Text>
        </div>
      ))}
    </div>
  );
};

export { TermsBlock };
