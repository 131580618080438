import React, { FC } from 'react';
import { ErrorBoundary } from '@beef/react';
import { withReduxStore } from '@beef/redux';

import { IData } from 'constructorV1/types';
import { initializeStore } from 'constructorV1/store';
import ConstructorV1Router from 'constructorV1/router';
import { dataConverter } from 'constructorV1/dataConverter';
import { PageContainer } from 'constructorV1/components/commonComponents/PageContainer';
import { ErrorBoundaryFallback } from 'constructorV1/components/commonComponents/ErrorBoundaryFallback';

/** Основная задача: BCP-18768
 * Главный компонент ConstructorV1 ЕК */
export const ConstructorV1Layout: FC<IData> = (props) => (
  /* Обертка для ЕК (PageFixer не подходит по дизайну) */
  <PageContainer>
    {/* @ts-expect-error Неверная типизация ErrorBoundary */}
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      {/* Роутер ЕК */}
      <ConstructorV1Router baseUrl={props.data?.baseUrl} />
    </ErrorBoundary>
  </PageContainer>
);

export const ConstructorV1 = withReduxStore(initializeStore, {
  passThroughProps: ({ data }: IData) => ({ data }),
  mapPropsToState: dataConverter,
})(ConstructorV1Layout);
