import { ECBillRequestErrors } from 'constructorV1/store/commonBillSlice/types';

/** Сценарии, в которых возможно открыть чат поддержки кнопкой */
export const openChatScenarios = [
  ECBillRequestErrors.number_is_blocked,
  ECBillRequestErrors.changed_error,
];

/** Сценарии, в которых возможно отправить повторный запрос /get-total-bill/ */
export const repeatRequestScenarios = [
  ECBillRequestErrors.personal_data_error,
  ECBillRequestErrors.something_error,
];
