export { getMobileOS } from '@beef/utils';

export function getWebView() {
  if (typeof navigator === 'undefined' || typeof window === 'undefined') {
    return null;
  }
  if (window.Android) {
    return 'android';
  }
  if (typeof window?.webkit?.messageHandlers?.callbackHandler?.postMessage === 'function') {
    return 'ios';
  }
  return null;
}
