import React, { FC } from 'react';
import classNames from 'classnames/bind';
// Components
import { InfoIcon, Text, Tooltip } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type HeadingProps = {
  isHideSum: boolean;
  oldPrice: null | string;
  price: string;
  title: string;
  tooltip?: string;
};

const Heading: FC<HeadingProps> = ({ title, price, isHideSum, tooltip, oldPrice }) => (
  <div className={cx('component')}>
    <div className={cx('title')}>
      <Text size="size5-m">{title}</Text>
      {tooltip && (
        <Tooltip message={tooltip}>
          <InfoIcon fill="#727281" size="s" />
        </Tooltip>
      )}
    </div>
    {!isHideSum && (
      <div className={cx('price')}>
        {oldPrice && (
          <Text color="grey50" isCrossedOut size="size6-r">
            {oldPrice}
          </Text>
        )}
        <Text size="size5-r-s">{price}</Text>
      </div>
    )}
  </div>
);

export { Heading };
