import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const ColumnFlex: React.FC<ColumnFlexProps> = ({
  children,
  className,
  direction = 'column',
  gap = 0,
}) => (
  <div
    className={cn('component', className)}
    style={{
      flexDirection: direction,
      gap: `${gap}px`,
    }}
  >
    {children}
  </div>
);

type ColumnFlexProps = {
  children: React.ReactNode;
  className?: string;
  direction?: FlexDirection;
  gap?: number;
};

type FlexDirection = 'column' | 'row';
