import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { ModalContainer } from '../../modalComponents/ModalContainer';
import { ModalHeader } from '../../modalComponents/ModalHeader';
import { BillBanner } from '../BillBanner';
import { useFamilyPopup, useSelectFamily } from './selectors';
import styles from './styles.pcss';

const cx = classNames.bind(styles);
export const FamilyContainer = memo(() => {
  const { bgColor, contentProps, image, isShowBanner } = useSelectFamily();
  const { modalContainerProps, modalHeaderProps, onOpen } = useFamilyPopup();

  if (!isShowBanner) {
    return null;
  }

  if (modalContainerProps && modalHeaderProps) {
    return (
      <>
        {/*  eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button className={cx('button', 'banner-button')} onClick={onOpen} type="button">
          <BillBanner bgColor={bgColor} contentProps={contentProps} image={image} />
        </button>
        <ModalContainer {...modalContainerProps}>
          <ModalHeader {...modalHeaderProps} />
        </ModalContainer>
      </>
    );
  }

  return null;
});
