import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Link, Text } from '@beef/ui-kit';

import Icon from '../../../../../../../components/Icon';

// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const PartnerDetails = ({
  logoPath,
  name,
  rateText,
  price,
  extDescription,
  partnerCardLink,
  detailCardBtnText,
}) => (
  <div className={cn('details')}>
    <div className={cn('details__logo-wrapper')}>
      <img alt="логотип подписки" className={cn('details__logo')} src={logoPath} />
    </div>
    <div className={cn('details__title')}>
      <Text size="size3-m">{name}</Text>
    </div>
    <div className={cn('details__rate')}>
      <Text size="size4-m">{rateText}</Text>
      <Text size="size4-m">{price}</Text>
    </div>
    <div>
      <Text size="size5-r-s">{extDescription}</Text>
    </div>
    <div>
      <Link
        href={partnerCardLink}
        isNoUnderline
        rightIcon={<Icon className={cn('arrow')} name="chevronRight" />}
      >
        {detailCardBtnText}
      </Link>
    </div>
  </div>
);

PartnerDetails.propTypes = {
  logoPath: PropTypes.string,
  name: PropTypes.string,
  rateText: PropTypes.string,
  price: PropTypes.string,
  extDescription: PropTypes.string,
  partnerCardLink: PropTypes.string,
  detailCardBtnText: PropTypes.string,
};

export { PartnerDetails };
