import React from 'react';
import classNames from 'classnames/bind';
import { ChevronRightIcon, Link, Text } from '@beef/ui-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import style from './styles.pcss';
import { HeadingPropsType } from './types';

const cn = classNames.bind(style);

export const Heading = ({ title, description, link }: HeadingPropsType) => {
  const isMobile = useIsMobileDimension();
  return (
    <div className={cn('heading')}>
      <div className={cn('wrapper')}>
        <Text size="size2-m" tagType="h2">
          {title}
        </Text>
        {link && (!isMobile || (isMobile && link.withMobileText)) && (
          <Link
            extraAttributes={{
              rel: 'noreferrer nofollow',
            }}
            href={link.href}
            isNoUnderline
            rightIcon={link.withIcon ? <ChevronRightIcon /> : undefined}
            size="size5-m"
            target="_blank"
          >
            {isMobile && link.withMobileText ? link.withMobileText : link.text}
          </Link>
        )}
      </div>
      {description && (
        <div className={cn('description')}>
          <Text color="grey60" size="size5-r-s">
            {description}
          </Text>
        </div>
      )}
    </div>
  );
};
