// TODO: Файл использует расширение .tsx по причине того,что для китового компонента
//  "ChipsStateless" требуется пробрасывать JSX-элемент для перечеркивания старой цены (скидка)!
import React from 'react';
import { emptyObj } from '@beef/utils';

import { FmcDescription } from './components/FmcDescription';
import { DEFAULT_TAB_ID, DEFAULT_TAB_TEXT, FREE_PRICE } from './constants';
import type { TConvergenceBlock } from '../../../store/slices/tariffServicesSlice/types';
import type { TPreviewTariff, TPriceListItem } from './types';

/** Функция извлечения ШПД-табов (priceList и inacId)
 *  из общего объекта (включающего в себя priceList, inacId и convergenceBlock) */

export const extractFmcTabs = (tabs: TPreviewTariff[]) =>
  tabs.map((tab) => ({ inacId: tab.inacId, ...tab.priceList }));

/** Функция выборки актуальных данных по табу с учетом priceCategory.
 * Если el.priceCategory (priceCategory ШПД-пресета) === priceCategory (priceCategory моб. пресета),
 * возвращаем объект с настройками таба для отрисовки */
const selectPresetFmcList = (priceList: TPriceListItem[], priceCategory: string) =>
  priceList.find((el) => el.priceCategory === priceCategory);

/** Функция выборки подходящих пресетов ШПД (из доступных в текущем регионе) по текущей priceCategory.
 * Также функция добавляет информацию для default-таба */
export const selectSuitableFmcTabs = (
  previewTariffs: TPreviewTariff[],
  priceCategory: string,
  defaultConvergenceBlock: Partial<TConvergenceBlock>,
) => {
  /** на случай, если признаки указывают на доступность fmc/partner fmc, но самих тарифов нет - вернуть пустой массив */
  if (!previewTariffs.length) {
    return [];
  }

  /** Общая информация по табу.
   *  Выбираем inacId, convergenceBlock для перерисовки блока ТВ и priceList с данными для самого таба */
  const fmcTabsInfo = previewTariffs.map((previewTariff) => ({
    ...previewTariff,
    priceList: selectPresetFmcList(previewTariff.priceList || [], priceCategory),
  }));

  /** Информация по дефолтному табу "Без интернета" */
  const defaultFmcTabInfo = {
    inacId: DEFAULT_TAB_ID,
    convergenceBlock: defaultConvergenceBlock,
    link: fmcTabsInfo[0]?.baseTariffLink,
    priceList: {
      priceCategory,
      price: FREE_PRICE,
      oldPrice: FREE_PRICE,
      speedText: DEFAULT_TAB_TEXT,
    },
  };

  return [defaultFmcTabInfo, ...fmcTabsInfo] as TPreviewTariff[];
};

/** Функция преобразования структуры ШПД-объекта табов таким образом,
 * чтобы она соответствовала ожидаемым пропам "китового" компонента ChipsStateless */
export const prepareFmcTabs = (priceCategory: string, suitableFmcTabs: TPriceListItem[]) =>
  suitableFmcTabs
    /* Для работы динамического ШПД требуется фильтрация по priceCategory из мобильных пресетов (гб/мин).
     * Если priceCategory не пришла (''), то оставляем suitableFmcTabs в оригинальном виде без фильтрации
     * (регионы без динамического ШПД). Иначе фильтруем по priceCategory */
    ?.filter(
      (suitableFmcTab) => priceCategory === '' || suitableFmcTab.priceCategory === priceCategory,
    )
    .map((suitableFmcTab) => {
      /* Проверка на default-таб (используется для игнорирования description в табе) */
      const isDefaultTab = suitableFmcTab?.inacId === DEFAULT_TAB_ID;

      /* Извлекаем данные по текущему табу */
      const { price, inacId, oldPrice, speedText } = suitableFmcTab;

      /* Выбираем текущее описание таба. Если это default-таб - игнорируем вызов */
      const currentDescription = !isDefaultTab ? <FmcDescription {...{ price, oldPrice }} /> : '';

      /* Результирующий объект по табу для ChipsStateless */
      return {
        value: inacId,
        title: speedText,
        description: currentDescription,
      };
    });

/** Функция получения актуального пресета (объекта с данными) ШПД, в зависимости от выбранного InacId) */
export const getCurrentConvergenceInfo = (
  preparedFmcInfo?: TPreviewTariff[],
  activeInacId?: string,
) => preparedFmcInfo?.find((item) => item.inacId === activeInacId) || (emptyObj as TPreviewTariff);

// TODO: Указанный ранее тип TPreviewTariff НЕ соответствует партнерским тарифам.
/** Функция получения активного партнёрского шпд-тарифа */
export const getActiveFmcTabId = (tariffs?: Record<string, any>[]) =>
  tariffs?.find(({ isSelectedTab }) => isSelectedTab)?.inacId;
