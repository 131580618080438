import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { fetchTariffUpPreset } from '../../../api/tariffupPreset/fetchTariffUpPreset';
import { fetchFmcServices } from '../../../api/fmcServices';
import { fetchFmcPresets } from '../../../api/fmсPresets';
import { TPresetsSlice } from './types';

const initialState: TPresetsSlice = {
  price: 0,
  oldPrice: 0,
  gbytes: [],
  minutes: [],
  isLoadingPresets: false,
  priceCategory: '',
};

/** Slice для пресетов (Гб, Мин) тарифа */
export const presetsSlice = createSlice({
  name: 'presets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(fetchFmcPresets.pending, fetchFmcServices.pending, fetchTariffUpPreset.pending),
      (state) => {
        state.isLoadingPresets = true;
      },
    );
    builder.addMatcher(
      isAnyOf(fetchFmcPresets.fulfilled, fetchFmcServices.fulfilled, fetchTariffUpPreset.fulfilled),
      (state) => {
        state.isLoadingPresets = false;
      },
    );
    builder.addMatcher(
      isAnyOf(fetchFmcPresets.rejected, fetchFmcServices.rejected, fetchTariffUpPreset.rejected),
      (state) => {
        state.isLoadingPresets = false;
      },
    );
  },
});

export const presetsReducer = presetsSlice.reducer;
