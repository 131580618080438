import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

// Components
import { Button, CollapsedContent } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { BenefitsSlider } from 'pages-components/Benefits';
import Grid from 'pages-components/Grid';
import Ctx from 'pages-components/Ctx';

import tilesMap from './tilesMap';
import PageDescriptions from '../PageDescriptions';

// Mapper
import { getParams } from '../TariffCardBody/mapper';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Комопнент который отрисовывает tiles выбирая из из карты TilesMap;
 * @param param - Объект с параметрами tiles, важный параметр widgetType, который является ключем в карте TilesMap;
 */
const TilesComponent = ({ param, discountParams }) => {
  const tileObject = tilesMap[param.widgetType] && tilesMap[param.widgetType].componentName;

  if (!tileObject) return null;

  const TileComp = tileObject;
  const { tileType } = tilesMap[param.widgetType];

  const tileBubbleClass = param.widgetType === 'bubble' && 'tileBubble';
  const tileCrossingClass = param.widgetType === 'crossing' && 'tileCrossing';

  return (
    <TileComp
      hideRow
      {...getParams(tileType, param, discountParams)}
      className={cx(tileBubbleClass, tileCrossingClass)}
    />
  );
};

TilesComponent.propTypes = {
  discountParams: PropTypes.object,
  param: PropTypes.shape({
    widgetType: PropTypes.string,
  }),
};

/**
 * Компонент отрисовывается в модальном окне, представляет
 * из себя копию крточки тарифа с набором tiles;
 * @param dataModal - Данные которые приходят с бэка, берется из поля downsellTariffCardModal;
 * @param connectTariff - Callback который отвечает за подключение тарифа;
 */
const TariffCardBodyPopup = ({ dataModal, connectDownsellTariff, cancelDownsellTariff }) => {
  const {
    benefits,
    pageTitle,
    descriptions,
    pageSubtitle,
    pageTitleIcon,
    connectButton,
    discountParams,
    resetAdsButton,
    parameterGroups,
    fullDescriptionLink,
  } = dataModal;

  // Проверяем наличие бенефитов;
  const isBenefits = benefits && !isEmpty(benefits);
  // Текст кнопки подключения
  const connectButtonText = connectButton || Ctx.tariff.buttonText.connect;

  return (
    <div className={cx('modalTariffCard')}>
      <Grid.ProductCard
        className={cx('resetHeading')}
        icon={pageTitleIcon}
        subTitle={pageSubtitle}
        title={pageTitle}
      >
        {isBenefits && <BenefitsSlider benefits={benefits} />}

        <div className={cx('tariffCardBody')}>
          {parameterGroups.map((item, key) => (
            <Grid.ProductParameters className={cx('group')} key={key} title={item.title}>
              {item.parameters.map((param, index) => (
                <TilesComponent discountParams={discountParams} key={index} param={param} />
              ))}
            </Grid.ProductParameters>
          ))}

          <div className={cx('buttonsGroup')}>
            <Button className="big" onClick={connectDownsellTariff} transformer>
              {connectButtonText}
            </Button>

            <Button className={['default', 'big']} onClick={cancelDownsellTariff} transformer>
              {resetAdsButton}
            </Button>
          </div>
        </div>
      </Grid.ProductCard>

      <CollapsedContent
        contentClass={cx('question')}
        title="Дополнительная информация о тарифе"
        titleClass={cx('collapsedTitle')}
      >
        <div className={cx('modalDescription')}>
          <PageDescriptions descriptions={descriptions} fullDescriptionLink={fullDescriptionLink} />
        </div>
      </CollapsedContent>
    </div>
  );
};

TariffCardBodyPopup.propTypes = {
  connectDownsellTariff: PropTypes.func,
  cancelDownsellTariff: PropTypes.func,
  dataModal: PropTypes.shape({
    pageTitleIcon: PropTypes.string,
    fullDescriptionLink: PropTypes.object,
    connectButton: PropTypes.string,
    descriptions: PropTypes.array,
    parameterGroups: PropTypes.array,
    resetAdsButton: PropTypes.string,
    pageSubtitle: PropTypes.string,
    pageTitle: PropTypes.string,
    benefits: PropTypes.array,
    discountParams: PropTypes.object,
  }),
};

export default TariffCardBodyPopup;
