import { shallowEqual } from '@beef/utils';

import { useAppSelector } from '../../../../store';

/** Селектор выборки контентных данных для TariffInfoTiles */
export const useSelectContent = () => {
  const { okBtnText, changeUpperTitle, changeUpperDescription, buttonTextChangeTariff } =
    useAppSelector(
      (state) => ({
        okBtnText: state.common.content.okBtnText,
        changeUpperTitle: state.common.content.changeUpperTitle,
        buttonTextChangeTariff: state.common.content.buttonTextChangeTariff,
        changeUpperDescription: state.common.content.changeUpperDescription,
      }),
      shallowEqual,
    );

  return {
    okBtnText,
    changeUpperTitle,
    changeUpperDescription,
    buttonTextChangeTariff,
  };
};
