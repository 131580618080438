import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { currentStateList } from 'pages/FTTB/constants';

import { CaptchaPopup } from './components/CaptchaPopup';
import { RenderPopup, RenderRequestStatus } from './components';

export const RenderPopupFormsNewDesign = memo(
  ({ renderPopup, closePopup, isOpen, data, isLoading }) => (
    <>
      {renderPopup === currentStateList.success && isOpen && (
        <RenderRequestStatus
          isOpen={isOpen}
          onClose={closePopup}
          texts={data.texts.redesignPopupTexts?.successPopup}
        />
      )}
      {renderPopup === currentStateList.fail && isOpen && (
        <RenderRequestStatus
          isOpen={isOpen}
          onClose={closePopup}
          texts={data.texts.redesignPopupTexts?.errorPopup}
        />
      )}
      {(renderPopup === currentStateList.none || renderPopup === currentStateList.abbreviated) && (
        <RenderPopup data={data} isLoading={isLoading} isOpen={isOpen} onClose={closePopup} />
      )}
      {renderPopup === currentStateList.captcha && isOpen && (
        <CaptchaPopup data={data} isLoading={isLoading} isOpen={isOpen} onClose={closePopup} />
      )}
    </>
  ),
);

RenderPopupFormsNewDesign.propTypes = {
  data: PropTypes.shape({}),
  renderPopup: PropTypes.string,
  isOpen: PropTypes.bool,
  closePopup: PropTypes.func,
  isLoading: PropTypes.bool,
};
