import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { useAppSelector } from 'constructorV1/store';
import { ESkeletonBillTypes } from 'constructorV1/types';
import { breakdownPrice } from 'constructorV1/utils/breakdownPrice';
import { SkeletonBill } from 'constructorV1/components/tempComponents/SkeletonBill';
import { selectOptionsLoading } from 'constructorV1/components/constructorPage/OptionContainer/selectors/selectOptionsLoading';

import styles from '../AmountText/styles.pcss';

const cx = classNames.bind(styles);

export type TAmountTextContent = {
  oldPrice?: number;
  period?: string;
  price?: number;
  unit?: string;
};

/** Компонент базовой стоимости выбранных пресетов/опций чека ЕК */
export const AmountTextContent: FC<TAmountTextContent> = ({
  unit = '₽',
  price,
  oldPrice,
  period,
}) => {
  /** Флаг загрузки опций */
  const isLoading = useAppSelector(selectOptionsLoading);

  /** Флаг определяет, требуется ли показывать старую цену в блоке.
   * Если доступны старая (oldPrice) и новая (price) цены, а также старая цена больше новой - покажем блок с oldPrice */
  const isVisibleOldPrice = oldPrice && price && oldPrice > price;

  return isLoading ?
      <SkeletonBill type={ESkeletonBillTypes.price} />
    : <div className={cx('wrapper__price')}>
        {isVisibleOldPrice && (
          <Text
            align="left"
            className={cx('wrapper__price--discount')}
            color="secondary"
            lineHeight={20}
            size="xs"
            weight="regular"
          >
            {breakdownPrice(oldPrice)} {unit}
          </Text>
        )}
        <Text align="left" color="primary" lineHeight={24} size="m" weight="medium">
          {price} {unit}
        </Text>
        {period && (
          <Text align="left" color="primary" lineHeight={20} size="m" weight="regular">
            {period}
          </Text>
        )}
      </div>;
};
