import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { handleDevicePurchase } from 'utils/purchaseDeviceHelper';
import { DEVICE_BUTTON_TYPE } from 'utils/devicePurchaseButtonDataHelpers';

import { flocktoryAddToCart } from '../analytics/flocktory';

//  требуется для работы превью товара (состояния, контролы, обработчики)
export const useDevicePreveiw = (cartApi, handleSetCartData, additionPurchaseData = {}) => {
  // data
  const [previewData, setPreviewData] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  // controls
  const [isFetchingPreview, setIsFetchingPreview] = useState(false);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [previewType, setPreviewType] = useState(DEVICE_BUTTON_TYPE.previewToBasket);
  const [device, setDevice] = useState(null);

  const onClosePreview = useCallback(() => {
    setIsOpenPreview(false);
    setPreviewData(null);
    setIsPurchaseLoading(false);
    setPreviewType(DEVICE_BUTTON_TYPE.previewToBasket);
  }, []);

  useEffect(() => {
    if (deviceId) {
      const promotionId = device?.promotionId || null;

      const purchaseDevice = async () => {
        setIsPurchaseLoading(true);

        await handleDevicePurchase({
          cartApi,
          id: deviceId,
          handleSetCartData,
          details: { promotionId },
          ...additionPurchaseData,
        });

        setIsPurchaseLoading(false);
        flocktoryAddToCart(device);
      };

      purchaseDevice();
    }
  }, [deviceId]);

  const fetchData = useCallback(async (slug) => {
    const { data } = await axios.get(`/shop/popupdetails/${slug}/`);

    return data;
  }, []);

  return {
    previewData,
    isFetchingPreview,
    isPurchaseLoading,
    isOpenPreview,
    previewType,
    setPreviewData,
    setIsFetchingPreview,
    setIsPurchaseLoading,
    setIsOpenPreview,
    onClosePreview,
    onPurchase: setDeviceId,
    fetchData,
    setPreviewType,
    setDevice,
  };
};
