import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { ScrollContainer } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import InfoPopup from 'pages-components/InfoPopup';

import Benefit from '../Benefit';
import BenefitPopup from '../BenefitPopup';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const BenefitsSlider = ({ benefits, className }) => {
  const [benefitIndex, setOpenModal] = useState(null);
  return (
    <>
      <ScrollContainer handleShouldPlayDemo={() => false}>
        <ul className={cx('benefits-list', className)}>
          {benefits.map((item, index) => (
            <li key={index}>
              <Benefit benefitData={item} onClick={() => setOpenModal(index)} />
            </li>
          ))}
        </ul>
      </ScrollContainer>

      {/* На данный момент поддерживаем две реализации попапов в бенефитах */}
      {benefitIndex !== null && (
        <>
          {/* Данные для попапа тянутся из DPC где лежит html в виде строки в
          поле popupContent и приходят сразу в модель страницы */}
          {benefits[benefitIndex].popupContent && (
            <BenefitPopup
              onPopupClose={() => setOpenModal(null)}
              popupContent={benefits[benefitIndex].popupContent}
            />
          )}

          {/* Данные для попапа тянутся из модели в QP. Данные запрашиваются с помощью ajax */}
          {benefits[benefitIndex].popupUrl && (
            <InfoPopup
              fetchUrl={benefits[benefitIndex].popupUrl}
              onClose={() => setOpenModal(null)}
            />
          )}
        </>
      )}
    </>
  );
};

BenefitsSlider.propTypes = {
  benefits: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default BenefitsSlider;
