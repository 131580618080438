import { createSelector } from '@reduxjs/toolkit';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { RootState } from 'constructorV1/store';

/** Селектор выборки данных результирующего чека ЕК */
export const selectCommonBill = (state: RootState) => state.commonBill;

/** Селектор выборки состояния загрузки запроса метода результирующего чека ЕК */
export const selectCBillIsLoading = createSelector(selectCommonBill, (slice) => slice.isLoading);

/** Селектор лаадера во время подключения тарифа ЕК */
export const selectIsChangeTariffLoading = createSelector(
  selectCommonBill,
  (slice) => slice.isChangeTariffLoading,
);

/** Селектор выборки типа ошибки запроса метода результирующего чека ЕК */
export const selectCBillErrorRequestType = createSelector(
  selectCommonBill,
  (slice) => slice.errorRequestType,
);

/** Селектор выборки контента из метода запроса результирующего чека ЕК */
export const selectCBillData = createSelector(selectCommonBill, (slice) => slice.data);

/** Селектор выборки параметров для смены тарифа из метода запроса результирующего чека ЕК */
export const selectRequestParams = createSelector(
  selectCommonBill,
  (slice) => slice.data?.requestParams,
);
