import { createSlice } from '@reduxjs/toolkit';

import { TTariffServices, TTariffServicesReducer } from './types';

const initialState: TTariffServices = {
  extraServices: [],
  convergenceBlock: {},
};

/** Slice для плиток доп.сервисов (семья, конвергенция, ТВ и т.п.) */
export const tariffServicesSlice = createSlice<TTariffServices, TTariffServicesReducer>({
  name: 'tariffServices',
  initialState,
  reducers: {
    /* Обновление блока extraServices */
    setExtraServices: (state, action) => {
      state.extraServices = action.payload;
    },
    /* Обновление блока convergenceBlock */
    setConvergenceBlock: (state, action) => {
      state.convergenceBlock = { ...state.convergenceBlock, ...action.payload };
    },
  },
});

export const { setExtraServices, setConvergenceBlock } = tariffServicesSlice.actions;

export const tariffServicesReducer = tariffServicesSlice.reducer;
