import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import format from 'date-fns/format';
import classNames from 'classnames/bind';
import {
  Button,
  DatePicker,
  Emoji,
  Heading,
  SmartForm,
  SmartInput,
  Textarea,
  // Accordion,
} from '@beef/legacy-ui-kit';

import { maskPhone } from 'utils/format-string';
// import FileUploader from './FileUploader';
import PopupStateless from 'pages-components/Popup';
import Captcha from 'pages/Feedback/components/Captcha/Captcha';
import { email as validateEmail, maskedPhone as validatePhone } from 'utils/validators';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successPopup: false,
      description: '',
      username: '',
      phone: '+7 ',
      email: '',
      damage: '',
      issueTimeMinute: '00',
      issueTimeHour: '00',
      fraudPhone: '+7 ',
      website: '',
      date: new Date(),
      // files: [],
      serverError: '',
      captcha: '',
    };
  }

  onNameInput = (username) => {
    this.setState({ username });
  };

  onPhoneInput = (phone) => {
    this.setState({ phone });
  };

  onFraudPhoneInput = (fraudPhone) => {
    this.setState({ fraudPhone });
  };

  onWebsiteInput = (website) => {
    this.setState({ website });
  };

  onDamageInput = (damage) => {
    this.setState({ damage });
  };

  onIssueTimeMinuteInput = (issueTimeMinute) => {
    this.setState({ issueTimeMinute });
  };

  onIssueTimeHourInput = (issueTimeHour) => {
    this.setState({ issueTimeHour });
  };

  onEmailInput = (email) => {
    this.setState({ email });
  };

  onDescriptionChange = (value) => {
    this.setState({ description: value.slice(0, 1000) });
  };

  onCaptchaChange = (captcha) => {
    this.setState({ captcha, serverError: '' });
  };

  isPhoneValid = (value = this.state.phone) => {
    if (validateEmail(this.state.email).status === 'ok') {
      return { status: 'ok' };
    }
    return validatePhone(value);
  };

  isEmailValid = (value = this.state.email) => {
    if (validatePhone(this.state.phone).status === 'ok') {
      return { status: 'ok' };
    }
    return validateEmail(value);
  };

  handleFilterMinuteInput = (value) => {
    const newValue = value.length > 2 ? value.slice(0, 2) : value;
    return +newValue > 59 ? '59' : value;
  };

  handleFilterHourInput = (value) => {
    const newValue = value.length > 2 ? value.slice(0, 2) : value;
    return +newValue > 23 ? '23' : value;
  };

  onSubmit = (values) => {
    const data = new FormData();

    const {
      email,
      phone,
      username,
      date,
      description,
      damage,
      issueTimeMinute,
      issueTimeHour,
      fraudPhone,
      website,
    } = this.state;

    /* this.state.files.forEach((f) => {
      data.append('file', f.file);
    }); */

    data.set('email', email);
    data.set('phone', phone);
    data.set('username', username);
    data.set('date', format(date, 'YYYY-MM-DD'));
    data.set('description', description);
    data.set('captcha', values.captcha);
    data.set('damage', damage);
    data.set('fraudPhone', fraudPhone);
    data.set('issueTimeHour', issueTimeHour);
    data.set('issueTimeMinute', issueTimeMinute);
    data.set('website', website);
    data.set('_captcha_key', values._captcha_key);

    axios
      .post('/feedback/sendfraudform', data)
      .then((resp) => {
        if (resp.data.IsCaptchaError) {
          return this.setState({ serverError: 'Неверный код с картинки' });
        }
        if (!resp.data.IsSuccess) {
          return this.setState({ serverError: 'Сервис не доступен, попробуйте позднее' });
        }
        return this.setState({
          successPopup: true,
          serverError: '',
        });
      })
      .catch(() => this.setState({ serverError: 'Сервис не доступен, попробуйте позднее' }));
  };

  render() {
    const { className } = this.props;
    const {
      phone,
      captcha,
      email,
      damage,
      issueTimeMinute,
      issueTimeHour,
      fraudPhone,
      website,
      username,
    } = this.state;

    return (
      <div className={cx('container', className)}>
        <SmartForm
          className={cx('form')}
          onSubmit={this.onSubmit}
          validator={{
            phone: this.isPhoneValid,
            email: this.isEmailValid,
          }}
        >
          <Heading level={2}>Мошенничество и SMS-спам</Heading>
          <div className={cx('description')}>
            Если на ваш номер поступают спам-сообщения или у вас есть подозрение в мошенничестве, вы
            можете направить нам обращение, заполнив форму ниже.
          </div>

          <InputWrapper
            className={cx('dateTime')}
            id="feedbackIssueDate"
            label="Дата\время инцидента"
          >
            <div className={cx('date')}>
              <DatePicker id="feedbackIssueDate" onChange={(date) => this.setState({ date })} />
            </div>
            <div className={cx('minute')}>
              <SmartInput
                filterInput={this.handleFilterHourInput}
                inputId="issueTimeHour"
                mask="__"
                name="issueTimeHour"
                onChange={this.onIssueTimeHourInput}
                value={issueTimeHour}
              />
            </div>
            <span className={cx('delimiter')}>:</span>
            <div className={cx('minute')}>
              <SmartInput
                filterInput={this.handleFilterMinuteInput}
                inputId="issueTimeMinute"
                mask="__"
                name="issueTimeMinute"
                onChange={this.onIssueTimeMinuteInput}
                value={issueTimeMinute}
              />
            </div>
          </InputWrapper>

          <InputWrapper id="feedbackFraudPhone" label="Телефон предполагаемого мошенника">
            <SmartInput
              className="bigSize"
              filterInput={maskPhone}
              id="feedbackFraudPhone"
              inputId="fraudPhone"
              keepInitialValue
              name="fraudPhone"
              onChange={this.onFraudPhoneInput}
              value={fraudPhone}
            />
          </InputWrapper>

          <InputWrapper id="feedbackWebsite" label="Web-сайт предполагаемого мошенника">
            <SmartInput
              id="feedbackWebsite"
              inputId="website"
              name="website"
              onChange={this.onWebsiteInput}
              value={website}
            />
          </InputWrapper>

          <InputWrapper id="feedbackDamage" label="Сумма ущерба в рублях">
            <SmartInput
              filterInput={(value) => value.replace(/\D/g, '')}
              id="feedbackDamage"
              inputId="damage"
              name="damage"
              onChange={this.onDamageInput}
              value={damage}
            />
          </InputWrapper>

          <InputWrapper className={cx('description')} id="description" label="Подробное описание">
            <Textarea onChange={this.onDescriptionChange} value={this.state.description} />
          </InputWrapper>

          <InputWrapper id="feedbackUsername" label="Как к вам обращаться?">
            <SmartInput
              id="feedbackUsername"
              inputId="username"
              name="username"
              onChange={this.onNameInput}
              value={username}
            />
          </InputWrapper>

          <InputWrapper id="feedbackPhone" label="Контактный телефон">
            <SmartInput
              className="bigSize"
              filterInput={maskPhone}
              id="feedbackPhone"
              inputId="phone"
              name="phone"
              onChange={this.onPhoneInput}
              value={phone}
            />
          </InputWrapper>

          <InputWrapper id="feedbackEmail" label="Контактный e-mail">
            <SmartInput
              id="feedbackEmail"
              inputId="email"
              name="email"
              onChange={this.onEmailInput}
              value={email}
            />
          </InputWrapper>

          {/* <div className={cx('fileUploaderWrapper')}>
            <Accordion>
              <div title="Приложить файл">
                <FileUploader onChange={files => this.setState({ files })} />
              </div>
            </Accordion>
          </div> */}

          <div className={cx('captchaWrapper')}>
            <Captcha
              captchaKey="feedback-form"
              inputId="captcha"
              name="captcha"
              onChange={this.onCaptchaChange}
            />
            <SmartInput
              inputId="_captcha_key"
              name="_captcha_key"
              type="hidden"
              value="feedback-form"
            />
          </div>
          {this.state.serverError && (
            <div className={cx('serverError')}>{this.state.serverError}</div>
          )}
          <div className={cx('button')}>
            <Button
              className="big"
              disabled={
                (this.isEmailValid().status !== 'ok' && this.isPhoneValid().status !== 'ok') ||
                captcha.length !== 5
              }
              type="submit"
              wide
            >
              Отправить
            </Button>
          </div>
        </SmartForm>
        <PopupStateless onClose={() => window.location.reload()} opened={this.state.successPopup}>
          <Heading level={2}>
            Ваше обращение передано в службу поддержки{' '}
            <Emoji className={cx('emoji')} name="smile-happy" />
          </Heading>
        </PopupStateless>
      </div>
    );
  }
}
const InputWrapper = ({ className, label, id, children }) => (
  <div className={cx('input', className)}>
    <label className={cx('label')} htmlFor={id}>
      <span>{label}</span>
    </label>
    {children}
  </div>
);

InputWrapper.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
};

export default Index;
