import React from 'react';
import { useIsMobileDimension } from '@carnica/utils/hooks';
import classNames from 'classnames/bind';

import { BenefitsCardText } from '../BenefitsCardText';
import type { TBenefitCard } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент Card - карточка преимущества */
export const BenefitsCard = ({ title, description, iconUrl, isActive, onClick }: TBenefitCard) => {
  const isMobile = useIsMobileDimension();

  return (
    <div className={cx('wrap', { wrap__active: isActive || isMobile })} onClick={onClick}>
      <BenefitsCardText description={description} title={title} />
      <div className={cx('icon', { icon__active: isActive || isMobile })}>
        <img alt="" src={iconUrl} />
      </div>
    </div>
  );
};
