import { useEffect, useRef } from 'react';

import { EventType, KeyHandlers } from './types';

export const useGlobalKeys = (keyHandlers: KeyHandlers, eventType: EventType = 'keyup') => {
  const keyHandlersRef = useRef<KeyHandlers>({});

  useEffect(() => {
    keyHandlersRef.current = keyHandlers;
  }, [keyHandlers]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) =>
      (keyHandlersRef.current[e.code] || keyHandlersRef.current[e.key])?.(e);

    document.addEventListener(eventType, handler);

    return () => {
      document.removeEventListener(eventType, handler);
    };
  }, [eventType]);
};
