import { ExtendedSteps } from '@beef/landing-constructor';
import type { ExtendedStepsPropsType } from '@beef/landing-constructor';
import { PageRow } from '@beef/smart-kit';
import classNames from 'classnames/bind';
import React from 'react';

import { PartnerServiceCardMergeState } from '../../store';
import { useContent } from './hooks';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TExtendedStepsProps = PartnerServiceCardMergeState['extendedBlockData'];

export const ExtendedStepsBlock: React.FC<TExtendedStepsProps> = (props) => {
  const { title, content, button } = props;
  const _content = useContent(button, content);

  return (
    <PageRow blockSpacersPreset="m" tagName="section">
      <div className={cx('container')}>
        <ExtendedSteps content={_content as ExtendedStepsPropsType['content']} title={title} />
      </div>
    </PageRow>
  );
};
