import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ScrollContainer } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { mapToPromoTariff } from 'pages/FTTB/utils/mapToPromoTariff';
import { throwAsyncError } from 'utils/errors';
import TariffCardContainer from 'pages/FTTB/CatalogPage/containers/TariffCardContainer';
import OrderTariffPopupsGroupContainer from 'pages/FTTB/CatalogPage/containers/OrderTariffPopupsGroupContainer';

import ChannelsPopupContainer from '../../containers/ChannelsPopupContainer';
import getTariffDiscountValue from '../../../utils/getTariffFeeValue';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const triggerResizeEvent = () => {
  const resizeEvent = window.document.createEvent('UIEvents');
  resizeEvent.initUIEvent('resize', true, false, window, 0);
  window.dispatchEvent(resizeEvent);
};

const getIsPseudoColorTariff = (showPromoAsColorTarif, isTopTariff, index) =>
  showPromoAsColorTarif && isTopTariff && index === 0;

const TariffsList = (props) => {
  const {
    tariffs,
    specTariffModel,
    showSpecTariffIfExist,
    isSpecTariffCheckForCurrentHouse,
    forceShowBackCallPopup,

    fetchTvChannelsData,
  } = props;
  const colorTariffChecked = showSpecTariffIfExist && isSpecTariffCheckForCurrentHouse;
  const showColorTariff = !!specTariffModel && colorTariffChecked;
  const showPromoAsColorTarif = !showColorTariff && colorTariffChecked;

  useEffect(triggerResizeEvent, [showColorTariff]);

  const [showOrderPopups, setShowOrderPopups] = useState(false);
  const [orderPopupsData, setOrderPopupsData] = useState(null);

  const [showTvChannelsPopup, setShowTvChannelsPopup] = useState(false);
  const onCloseOrderTariffPopups = useCallback(() => setShowOrderPopups(false), []);
  const onConnectTariffClick = useCallback(
    (tariffId) => {
      if (showColorTariff && specTariffModel.id === tariffId) {
        setOrderPopupsData({ isColorTariff: true });
        setShowOrderPopups(true);
        return;
      }

      const tariffIndex = tariffs.findIndex((tariff) => tariff.id === tariffId);
      if (tariffIndex === -1) {
        throwAsyncError(`Тариф с id=${tariffId} не найден`);
        return;
      }

      const tariff = tariffs[tariffIndex];
      setOrderPopupsData({
        promoTariff: mapToPromoTariff(tariff),
        isPseudoColorTariff: getIsPseudoColorTariff(
          showPromoAsColorTarif,
          tariff.isTopTariff,
          tariffIndex,
        ),
      });
      setShowOrderPopups(true);
    },
    [showColorTariff, specTariffModel, tariffs, showPromoAsColorTarif],
  );

  const onTvChannelsClick = (tariff) => {
    setShowTvChannelsPopup(true);

    fetchTvChannelsData(tariff.fetchTvChannelsUrl, tariff.alias);
  };
  const onCloseTvChannelsPopup = useCallback(() => setShowTvChannelsPopup(false), []);

  return (
    <>
      <ScrollContainer className={cx('scrollContainer')} handleShouldPlayDemo={() => {}}>
        <div className={cx('wrapper')}>
          {showColorTariff && (
            <div className={cx('tariffCard', 'specTariff')} key="color-spec-tariff">
              <TariffCardContainer
                isColorTariff
                onConnectTariffClick={onConnectTariffClick}
                onTvChannelsClick={onTvChannelsClick}
                tariff={specTariffModel}
              />
            </div>
          )}
          {tariffs.map((tariff, i) => {
            const isPseudoColorTariff = getIsPseudoColorTariff(
              showPromoAsColorTarif,
              tariff.isTopTariff,
              i,
            );
            return (
              <div className={cx('tariffCard', { specTariff: isPseudoColorTariff })} key={i}>
                <TariffCardContainer
                  isPromo={tariff.isTopTariff && !isPseudoColorTariff}
                  isPseudoColorTariff={isPseudoColorTariff}
                  onConnectTariffClick={onConnectTariffClick}
                  onTvChannelsClick={onTvChannelsClick}
                  tariff={tariff}
                />
              </div>
            );
          })}
        </div>
      </ScrollContainer>
      {showOrderPopups && (
        <OrderTariffPopupsGroupContainer
          backCallAnalyticsData={
            orderPopupsData?.promoTariff ?
              {
                ordered_itemTitle: orderPopupsData.promoTariff.title,
                ordered_itemPrice:
                  orderPopupsData.promoTariff?.oldFee?.value || orderPopupsData.promoTariff?.fee,
                ordered_itemAlias: orderPopupsData.promoTariff.alias,
                ordered_itemDiscount: getTariffDiscountValue(
                  orderPopupsData.promoTariff?.oldFee?.value,
                  orderPopupsData.promoTariff?.fee,
                ),
              }
            : null
          }
          forceShowBackCallPopup={forceShowBackCallPopup}
          isColorTariff={orderPopupsData.isColorTariff || orderPopupsData.isPseudoColorTariff}
          onCloseAllPopups={onCloseOrderTariffPopups}
          promoTariff={orderPopupsData.promoTariff}
        />
      )}
      {showTvChannelsPopup && (
        <ChannelsPopupContainer isWithoutUpsaleSwitch onClose={onCloseTvChannelsPopup} />
      )}
    </>
  );
};

TariffsList.propTypes = {
  tariffs: PropTypes.array,
  specTariffModel: PropTypes.object,
  showSpecTariffIfExist: PropTypes.bool,
  isSpecTariffCheckForCurrentHouse: PropTypes.bool,
  forceShowBackCallPopup: PropTypes.bool,

  fetchTvChannelsData: PropTypes.func,
};

export default TariffsList;
