import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const BottomSideItem = ({ title, description }) => (
  <li className={cx('wrapper')}>
    {title && (
      <div className={cx('wrapper__title')}>
        <Text size="size4-m" tagType="h3">
          {title}
        </Text>
      </div>
    )}
    {description && (
      <Text color="grey60" size="size5-r-s">
        {description}
      </Text>
    )}
  </li>
);

BottomSideItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
