import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import axios from 'axios';

import {
  getAFAnalyticsURL,
  getMyBeePageURL,
  getMyBeelineURL,
} from 'utils/analytics/customServiceCard';
import { getMobileOS } from 'utils/detect-mobile';
import { unformatPhone } from 'utils/format-string';
import { simBuyAction } from 'pages/ProductCard/actions/simBuy';

const onStoreButtonClick = (alias) => (appStore, linkUrl) => {
  let urlWithAnalytic = null;

  if (alias === 'gigi-za-son-new') {
    urlWithAnalytic = getAFAnalyticsURL(window.location.search, appStore);
  }

  if (alias === 'zashhita-ekrana' && getMobileOS()) {
    urlWithAnalytic = getMyBeePageURL(window.location.search);
  }

  if (alias === 'gigi-za-mozgi-new' && getMobileOS()) {
    urlWithAnalytic = getMyBeelineURL(window.location.search);
  }

  return urlWithAnalytic || linkUrl;
};

const sendSmsFormSubmit = async (url, uglyPhone, captcha, templateName) => {
  const phone = unformatPhone(uglyPhone);

  try {
    const region = await axios.get(url, { params: { phone } });

    if (!region.data.homeRegion) {
      return { nextStep: 'mnpPopup' };
    }

    const { data } = await axios.post(
      '/smssend/applicationmessage/',
      qs.stringify({
        ctn: phone,
        templateName,
        captcha,
        _captcha_key: 'mobile-app-form',
      }),
    );

    if (data.isCaptchaError) {
      return {
        isCaptchaError: data.isCaptchaError,
        isCaptchaRequired: data.isCaptchaRequired,
      };
    }

    if (data.error || !data.isSuccess) {
      throw {
        error: 'sms error',
        isCaptchaRequired: data.isCaptchaRequired,
      };
    }

    return {
      nextStep: 'successPopup',
      isCaptchaRequired: data.isCaptchaRequired,
      isCaptchaError: data.isCaptchaError,
    };
  } catch ({ isCaptchaRequired }) {
    return {
      nextStep: 'errorPopup',
      isCaptchaRequired,
    };
  }
};

const ProductListParameterHOC = ({ children, alias }) =>
  cloneElement(children, {
    handlerClick: onStoreButtonClick(alias),
    sendSmsFormSubmit,
    callOrderAction: simBuyAction,
    alias,
  });

ProductListParameterHOC.propTypes = {
  alias: PropTypes.string,
  children: PropTypes.any,
};

export default ProductListParameterHOC;
