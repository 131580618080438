import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Link, Status } from '@beef/legacy-ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const DisconnectionStatus = ({ connectedText, isConnectedLater, isConnected, handleClick }) => (
  <Status className={['success']}>
    <span className={cx('content')}>
      <span className={cx('text')}>{connectedText}</span>
      <span className={cx('text')}>
        <Link className="light" onClick={handleClick}>
          {isConnected && !isConnectedLater ? 'Отключить' : null}
          {isConnectedLater ? 'Отменить подключение' : null}
        </Link>
      </span>
    </span>
  </Status>
);

DisconnectionStatus.propType = {
  isConnected: PropTypes.bool,
  isConnectedLater: PropTypes.bool,
  connectedText: PropTypes.string,
};

export default DisconnectionStatus;
