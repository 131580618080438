import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
// Components
import { ChipsStatefulSingleChoice } from '@beef/ui-kit';
import { useIsMobileDimension } from '@beef/ui-kit/hooks';

// Actions
import { setActiveTab } from '../../../../../store/actions/orderTariffDetailsData';
// Selectors
import { getActiveTab } from '../../../../../store/selectors/orderTariffDetailsData';
// Utils
import { composeTabsData } from './utils';
// PropTypes
import { tariffShape } from '../../../../../PropTypes';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TabsItemContainerLayout = ({ data, handleClick, activeTab }) => {
  const tabs = composeTabsData(data);
  const defaultValue = tabs.find(({ value }) => value === activeTab)?.value || tabs[0]?.value;
  const isMobile = useIsMobileDimension();

  return (
    <ChipsStatefulSingleChoice
      className={cx('chips-container')}
      defaultValue={defaultValue}
      isScrollable
      isVertical={isMobile}
      onChange={handleClick}
      options={tabs}
      type="radio"
      variant="card"
    />
  );
};

const mapStateToProps = (state) => ({
  activeTab: getActiveTab(state),
});

const mapDispatchToProps = {
  handleClick: setActiveTab,
};

TabsItemContainerLayout.propTypes = {
  data: PropTypes.arrayOf(tariffShape),
  handleClick: PropTypes.func,
  activeTab: PropTypes.string,
};

export const TabsItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabsItemContainerLayout);
