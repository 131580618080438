const hasTabs = (element) => element.querySelector('.help-and-support-tab-title');

export const mapData = (data) => {
  let needMapping = false;
  let mapped = {};
  const { html } = data;
  const div = document.createElement('div');
  div.innerHTML = html;

  if (hasTabs(div)) {
    needMapping = true;
    const titles = Array.prototype.slice
      .call(div.querySelectorAll('.help-and-support-tab-title'))
      .map((element) => ({ title: element.textContent.trim() }));
    const contents = Array.prototype.slice
      .call(div.querySelectorAll('.help-and-support-tab-content'))
      .map((element) => ({ html: element.innerHTML }));

    const imgs = Array.prototype.slice
      .call(div.querySelectorAll('.help-and-support-tab-image'))
      .map((element) => ({ imgUrl: element.src }));

    let longest = titles.length > contents.length ? titles : contents;
    longest = longest.length > imgs ? longest : imgs;

    mapped = {
      ...data,
      html: '',
      tabs: longest.map((item, key) => ({
        ...titles[key],
        ...contents[key],
        ...imgs[key],
      })),
    };
  }

  return { needMapping, mapped };
};

export const mapBreadCrumbs = (breadCrumbs = []) =>
  breadCrumbs.map((item) => ({ label: item.label, url: item.url }));
