import { Button, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import React, { useContext } from 'react';

import { ServiceStatus } from 'pages/Services/ScopeZeroBalanceServices/components/ServiceStatus';
import { ConnectButtonContainer } from 'pages/Services/ZeroBalanceSupport/Component/ConnectButtonContainer';
import { ZeroBalanceSupportContext } from 'pages/Services/ZeroBalanceSupport/context';

import { selectContent, selectData } from '../../selectors';
import { TButtonProps } from '../ModalActionBar/types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const BannerDetailsNode: React.FC<{
  button: TButtonProps;
}> = ({ button }) => {
  const context = useContext(ZeroBalanceSupportContext);
  const { banner } = selectContent(context);
  const { isConnected } = selectData(context);

  const hasTooltip = !!button?.hasTooltip;

  return (
    <div className={cx('container')}>
      <ServiceStatus isConnected={isConnected} text="Подключено" />
      {banner?.title && (
        <Text color="grey95" size="size0-b" tagType="h1">
          {banner?.title}
        </Text>
      )}
      <div className={cx('text')}>
        <Text color="grey60" size="size4-r">
          {banner?.text}
        </Text>
      </div>
      <ConnectButtonContainer hasTooltip={hasTooltip}>
        <Button
          disabled={button.disabled}
          fullWidth
          onClick={button.onClick}
          rightSideNode={button?.rightSideNode}
          size="l"
          variant={button.variant}
        >
          {button.text}
        </Button>
      </ConnectButtonContainer>
    </div>
  );
};
