import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Text = ({ data, children }) => (
  <>
    <div className={cx('component')} dangerouslySetInnerHTML={{ __html: data }} />
    {children}
  </>
);

export default Text;
