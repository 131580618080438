import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { CompareOutlineIcon, Counter, Text } from '@beef/ui-kit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getOpenedFilterType,
  getSelectedFiltersCountMobile,
} from 'pages/SearchResultsPage/store/selectors';
import {
  applyMobileFilters,
  clearFilter,
  initialSearchParams,
} from 'pages/SearchResultsPage/store/actions';
import { TITLE_FILTERS, TYPE_METHOD } from 'pages/SearchResultsPage/store/constants';
import {
  getUrlSearchParams,
  updateProductsWithCache,
} from 'pages/SearchResultsPage/utils/helperFilters';

import { Content, SidebarFooter, SidebarHeader } from './MobileFilterPanel/components';
import styles from './styles.pcss';
import { MobileFilterPanel } from './MobileFilterPanel';

const cx = classNames.bind(styles);

export const _MobileFilter = ({
  countSelectedFilters,
  openedFilterType,
  onApplyFilters,
  onCloseModal,
  onClearFilter,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const cacheParameters = useRef(undefined);

  const handleClick = (typeMethod) =>
    ({
      open: () => {
        cacheParameters.current = getUrlSearchParams();
        setIsOpen(!isOpen);
      },
      clear: async () => {
        await onClearFilter({ isMobile: true });
      },
      apply: () => {
        if (openedFilterType) {
          onApplyFilters();
        } else {
          cacheParameters.current = undefined;
          setIsOpen(false);
        }
      },
      close: async () => {
        setIsOpen(false);
        updateProductsWithCache(cacheParameters.current, onCloseModal);
      },
    })[typeMethod];

  useEffect(() => {
    ref?.current?.scrollIntoView();
  }, [openedFilterType]);

  return (
    <>
      <button className={cx('settings-icon')} onClick={handleClick(TYPE_METHOD.open)} type="submit">
        <div className={cx('text')}>
          <Text size="size5-r-s">{TITLE_FILTERS}</Text>
        </div>
        <CompareOutlineIcon size={20} />
        {!!countSelectedFilters && (
          <div className={cx('counter')}>
            <Counter count={countSelectedFilters} />
          </div>
        )}
      </button>

      <MobileFilterPanel isOpen={isOpen} onClose={handleClick(TYPE_METHOD.close)}>
        <div className={cx('filtersWrapper', 'mobile')} ref={ref} role="presentation">
          <SidebarHeader
            onClear={handleClick(TYPE_METHOD.clear)}
            onClose={handleClick(TYPE_METHOD.close)}
          />
          <div className={cx('overflowWrapper')}>
            <Content />
          </div>
          <SidebarFooter
            isMainFiltersStep={!openedFilterType}
            onClick={handleClick(TYPE_METHOD.apply)}
          />
        </div>
      </MobileFilterPanel>
    </>
  );
};

_MobileFilter.propTypes = {
  onClearFilter: PropTypes.func,
  onApplyFilters: PropTypes.func,
  onCloseModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  countSelectedFilters: getSelectedFiltersCountMobile(state),
  openedFilterType: getOpenedFilterType(state),
});

const mapDispatchToProps = {
  onApplyFilters: applyMobileFilters,
  onCloseModal: initialSearchParams,
  onClearFilter: clearFilter,
};

export const MobileFilter = connect(mapStateToProps, mapDispatchToProps)(_MobileFilter);
