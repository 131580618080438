import React, { memo } from 'react';
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type BannerProps = {
  image: string;
};

export const Banner = memo<BannerProps>(({ image }: BannerProps) => (
  <div className={cx('image-area')}>
    <Picture alt="logo" className={cx('logo')} src={image} />
  </div>
));
