import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { RenderRequestFrom } from '../RenderRequestFrom';
import styles from '../../styles.pcss';

const cx = classNames.bind(styles);

export const RenderAbbreviatedPopup = ({ data }) => {
  const { texts } = data;
  const { abbreviatedPopup } = texts;
  const { abbreviatedPopupTitle, abbreviatedPopupText } = abbreviatedPopup;

  return (
    <>
      <Heading className={cx('popupHeading')} level={1}>
        <span dangerouslySetInnerHTML={{ __html: abbreviatedPopupTitle }} />
      </Heading>
      <span
        className={cx('techSupportText')}
        dangerouslySetInnerHTML={{ __html: abbreviatedPopupText }}
      />
      <RenderRequestFrom data={data} />
    </>
  );
};

RenderAbbreviatedPopup.propTypes = {
  data: PropTypes.shape({
    texts: PropTypes.shape({
      abbreviatedPopup: PropTypes.shape({
        abbreviatedPopupTitle: PropTypes.string,
        abbreviatedPopupText: PropTypes.string,
      }),
    }),
  }),
};
