import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { Button } from '@beef/ui-kit';
import qs from 'query-string';

import Ctx from 'pages-components/Ctx';

// Actions
import { parseUrlForNeedOpenOrderPaymentResultPopup } from 'widgets/actions/orderPaymentForm';

// Helper functions
import documentReady from 'utils/document-ready';

import { toggleDivorcingTariffPopup } from '../../../actions/tariffConnect/tariffConnectExtension';
import { toggleTariffConnectionPopup } from '../../../actions/tariffConnect/toggleTariffConnectionPopup';

/**
 * Кнопка + модальное окно подключения тарифа;
 */
class ConnectTariffButton extends Component {
  componentDidMount() {
    const { isPostpaid, isConnected } = this.props.popupData;
    const params = qs.parse(window.location.search);

    if (!isPostpaid && !isConnected && params.connect === 'true') {
      documentReady(this.handleClick);
    }

    this.props.parseUrlForNeedOpenOrderPaymentResultPopup(this.props.popupData);
  }

  // Открываем модально окно подключения тарифа, вызываем функцию аналитики;
  handleClick = () => {
    const { isFloatingButton, popupData, tabsToDisplay } = this.props;
    const { offerCode, convergentOrderPopup, isAuthenticated } = popupData;
    localStorage.removeItem('tariffConstructorState');

    this.props.onClick();

    if (convergentOrderPopup && isAuthenticated) {
      this.props.toggleDivorcingTariffPopup(offerCode);
    } else {
      this.props.toggleTariffConnectionPopup(
        true,
        offerCode,
        tabsToDisplay || null,
        isFloatingButton,
      );
    }
  };

  render() {
    const { buttonText, className } = this.props;

    return (
      <div>
        <Button extraClasses={className} fullWidth onClick={this.handleClick}>
          {buttonText}
        </Button>
      </div>
    );
  }
}

ConnectTariffButton.defaultProps = {
  buttonText: Ctx.tariff.buttonText.connect,
  onClick: () => {},
};

ConnectTariffButton.propTypes = {
  isFloatingButton: PropTypes.bool,
  toggleTariffConnectionPopup: PropTypes.func,
  toggleDivorcingTariffPopup: PropTypes.func,
  tabsToDisplay: PropTypes.array,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  parseUrlForNeedOpenOrderPaymentResultPopup: PropTypes.func,
  popupData: PropTypes.shape({
    convergentOrderPopup: PropTypes.object,
    marketCode: PropTypes.string,
    pageTitle: PropTypes.string,
    offerCode: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    isConnected: PropTypes.bool,
    isPostpaid: PropTypes.bool,
    soc: PropTypes.string,
    offerType: PropTypes.string,
    deliveryForm: PropTypes.object,
  }),
};

const mapDispatchToProps = {
  toggleTariffConnectionPopup,
  toggleDivorcingTariffPopup,
  parseUrlForNeedOpenOrderPaymentResultPopup,
};

export default connect(null, mapDispatchToProps)(ConnectTariffButton);
