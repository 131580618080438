import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { pathOr } from 'ramda';

import store from 'store';
import PopupStateless from 'pages-components/Popup';
import { SingleRowButton } from 'pages-components/Tiles';

import TariffConstructorComponent from './components/TariffConstructor';
import {
  connectConstructor,
  getConstructors,
  resetValues,
  toggleConstructor,
} from '../../actions/tariffConstructor';

class TariffConstructor extends Component {
  onClick = () => {
    if (this.props.onClickCallback) {
      this.props.onClickCallback();
    }

    this.props.openConstructor();
  };

  renderTariffConstructorPopup = () => {
    const {
      texts,
      step,
      resetDate,
      selectorDataUrl,
      connectUrl,
      checkStatusUrl,
      authenticated,
      tariffConnected,
      tariffName,
      onConnectTariffClick,
    } = this.props;

    return (
      <TariffConstructorComponent
        authenticated={authenticated}
        connectConstructor={() => this.props.connectConstructor(connectUrl, checkStatusUrl)}
        getConstructors={() => this.props.getConstructors(selectorDataUrl)}
        onClose={this.props.toggleConstructor}
        onConnectTariffClick={onConnectTariffClick}
        resetDate={resetDate}
        step={step}
        tariffConnected={tariffConnected}
        tariffName={tariffName}
        texts={texts}
      />
    );
  };

  render() {
    const { bannerData, opened, rowButton, bannerClassName } = this.props;

    return rowButton ?
        <div>
          <SingleRowButton
            className={bannerClassName}
            onClick={this.onClick}
            text={bannerData.description}
          />
          <PopupStateless onClose={this.props.toggleConstructor} opened={opened}>
            {opened && this.renderTariffConstructorPopup()}
          </PopupStateless>
        </div>
      : <>{this.renderTariffConstructorPopup()}</>;
  }
}

TariffConstructor.defaultProps = {
  selectorDataUrl: '/get-constructors/',
  connectUrl: '/apply-constructor/',
  checkStatusUrl: '/check-constructor-status/',
  texts: {},
  bannerData: {},
  showBannerArrow: true,
  rowButton: true,
};

TariffConstructor.propTypes = {
  rowButton: PropTypes.bool,
  texts: PropTypes.object,
  bannerData: PropTypes.object,
  opened: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool.isRequired,
  tariffConnected: PropTypes.bool.isRequired,
  step: PropTypes.string.isRequired,
  resetDate: PropTypes.string,
  selectorDataUrl: PropTypes.string,
  connectUrl: PropTypes.string,
  checkStatusUrl: PropTypes.string,
  toggleConstructor: PropTypes.func.isRequired,
  getConstructors: PropTypes.func.isRequired,
  connectConstructor: PropTypes.func.isRequired,
  tariffName: PropTypes.string,
  bannerClassName: PropTypes.any,
  onConnectTariffClick: PropTypes.func,
  openConstructor: PropTypes.func,
  onClickCallback: PropTypes.func,
};

const ConnectedTariffConstructor = connect(
  (state) => {
    const tariffConstructor = pathOr({}, ['external', 'tariffConstructor'], state);

    return {
      texts: pathOr({}, ['data', 'texts'], tariffConstructor),
      opened: tariffConstructor.opened,
      step: tariffConstructor.step,
    };
  },
  (dispatch, props) => ({
    toggleConstructor: () => dispatch(toggleConstructor),
    getConstructors: (url) => dispatch(getConstructors(url)),
    openConstructor: () => {
      dispatch(toggleConstructor);
      dispatch(resetValues);
    },
    connectConstructor: (connectUrl, checkStatusUrl) => {
      dispatch(connectConstructor(connectUrl, checkStatusUrl));
      if (props.closePopup) {
        props.closePopup();
      }
    },
  }),
)(TariffConstructor);

export default (props) => (
  <Provider store={store}>
    <ConnectedTariffConstructor {...props} />
  </Provider>
);
