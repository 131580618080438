import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { path, pathOr } from 'ramda';
import qs from 'query-string';
import { Bubble, Emoji, Link, RangeSlider, Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import {
  SET_ALWAYS_IN_POSITIVE_VALUE,
  changeLimitConfirmation,
} from 'pages/ProductCard/actions/alwaysInPositive';
import store from 'store';

import AlwaysInPositivePopup from '../AlwaysInPositivePopup/AlwaysInPositivePopup';
import styles from './AlwaysInPositiveExtension.pcss';
import { initConnectionPopup, toggleServiceConnectionPopup } from '../../actions/serviceConnect';

const cx = classNames.bind(styles);

class AlwaysInPositiveExtension extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changeLimit: false,
      labelHidden: true,
    };
  }

  componentDidMount() {
    this.props.setData();
    this.setState({ labelHidden: false });
  }

  handleChange = (amount) => {
    this.setState({
      changeLimit: true,
    });
    this.props.handleChange(amount);
  };

  render() {
    const {
      amount,
      credit,
      isConnected,
      options,
      deposit,
      className,
      onScrollToInfo,
      isAvailable,
      isLimitChanged,
      isConnectingInProgress,
    } = this.props;
    const slider = options.find((option) => +option.value === +amount);
    const initialPosition = slider && +slider.position;

    return (
      <div className={className}>
        <table className={cx('params')}>
          <tbody>
            {credit.currentValueLabel ?
              <tr className={cx('row')}>
                <td
                  className={cx('param', 'description')}
                  dangerouslySetInnerHTML={{ __html: credit.currentValueLabel }}
                />
                <td className={cx('param', 'amount')}>
                  {deposit.isReturn ? `${-1 * +amount}${credit.currency}` : null}
                  {deposit.isReturn && credit.howToChangeText ?
                    <Link className={cx('anchor')} href="#" onClick={onScrollToInfo}>
                      {credit.howToChangeText}
                    </Link>
                  : null}
                </td>
              </tr>
            : null}

            {!isLimitChanged && credit.recalculatedTip ?
              <tr className={cx('row')}>
                <td className={cx('param', 'hasBubble')} colSpan="2">
                  <Bubble className="bottomLeft">
                    <div className={cx('bubble')}>
                      <Emoji className={cx('bubbleEmoji')} name="statusOk" />
                      <div className={cx('bubbleItem')}>{credit.recalculatedTip}</div>
                    </div>
                  </Bubble>
                </td>
              </tr>
            : null}

            {initialPosition !== undefined ?
              <tr className={cx('row')}>
                <td className={cx('param', 'hasSlider')} colSpan="2">
                  <div className={cx('sliderContainer')}>
                    <RangeSlider
                      disabled={credit.disabled || !isAvailable}
                      initialPosition={initialPosition}
                      markSelectedLabel={!!(isConnected || isConnectingInProgress)}
                      markedValue={credit.currentValue}
                      onValueChange={this.handleChange}
                      reverse
                      sticky
                      values={options}
                    />
                  </div>
                </td>
                <td />
              </tr>
            : null}

            {!this.state.labelHidden && deposit.label ?
              <tr className={cx('row')}>
                <td className={cx('param')}>
                  {deposit.label}
                  {deposit.tip ?
                    <Tooltip>{deposit.tip}</Tooltip>
                  : null}
                </td>
                <td className={cx(['param', 'amount'])}>
                  {!deposit.isReturn && amount}
                  {deposit.amountText}
                </td>
              </tr>
            : null}
          </tbody>
        </table>
        <AlwaysInPositivePopup />
      </div>
    );
  }
}

AlwaysInPositiveExtension.defaultProps = {
  credit: {},
  deposit: {},
  isConnected: false,
  options: [],
  onScrollToInfo: () => {},
};

AlwaysInPositiveExtension.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.any,
  credit: PropTypes.object,
  deposit: PropTypes.object,
  isConnected: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  onScrollToInfo: PropTypes.func,
  setData: PropTypes.func.isRequired,
  isAvailable: PropTypes.bool,
  isLimitChanged: PropTypes.bool,
  isConnectingInProgress: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const personalCreditLimit = pathOr({}, ['data', 'extData', 'personalCreditLimit'], props);
  const credit = personalCreditLimit.creditAmount;

  return {
    credit,
    amount: path(['external', 'alwaysInPositive', 'amount'], state),
    isLimitChanged: path(['external', 'alwaysInPositive', 'isChanged'], state),
    deposit: personalCreditLimit.deposit,
    options: path(['options'], credit),
    isAvailable: path(['data', 'availableForConnect'], props),
    isConnected: path(['data', 'isConnected'], props),
    isConnectingInProgress: personalCreditLimit.isConnectingInProgress,
  };
};

const mapDispatchToProps = (dispatch, { data = {} }) => ({
  handleChange: (amount) => {
    const currentAmount = +pathOr(
      0,
      ['extData', 'personalCreditLimit', 'creditAmount', 'currentValue'],
      data,
    );

    if (!data.isAuthenticated) {
      const { connectPopupUrl, alias, soc } = data;
      dispatch(initConnectionPopup(connectPopupUrl, alias, `true&amount=${amount}`, soc));
    }

    dispatch({
      type: SET_ALWAYS_IN_POSITIVE_VALUE,
      payload: {
        amount,
        isChanged: +amount !== currentAmount,
      },
    });
  },
  setData: () => {
    let amount;
    const urlParams = qs.parse(window.location.search);
    if (data.isAuthenticated && urlParams.amount) {
      const options = path(['extData', 'personalCreditLimit', 'creditAmount', 'options'], data);
      if (options) {
        const newAmount = +urlParams.amount;
        amount = options.some((item) => +item.value === newAmount) ? newAmount : null;
      }
    }
    if (!amount) {
      amount = +pathOr(0, ['extData', 'personalCreditLimit', 'creditAmount', 'currentValue'], data);
    }

    dispatch({
      type: SET_ALWAYS_IN_POSITIVE_VALUE,
      payload: {
        amount,
        isReturn: path(['extData', 'personalCreditLimit', 'deposit', 'isReturn'], data),
        changeLimitUrl: pathOr(
          '',
          ['extData', 'personalCreditLimit', 'creditAmount', 'changeLimitUrl'],
          data,
        ),
        confirmationPopupData: pathOr(
          '',
          ['extData', 'personalCreditLimit', 'confirmationPopup'],
          data,
        ),
        errorPopupData: data.errorPopup,
        soc: data.soc,
        serviceName: data.serviceName,
        marketCode: data.marketCode,
      },
    });

    dispatch(initConnectionPopup(data.connectPopupUrl, data.alias, 'true', data.soc));

    const { availableForConnect, isAuthenticated, isConnected, isConnectedLater } = data;
    if (qs.parse(window.location.search).connect === 'true') {
      if (!isAuthenticated) {
        dispatch(toggleServiceConnectionPopup(true));
      } else if (availableForConnect && !isConnected && !isConnectedLater) {
        dispatch(changeLimitConfirmation());
      }
    }
  },
});

const ConnectedAlwaysInPositive = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlwaysInPositiveExtension);

export default (props) => (
  <Provider store={store}>
    <ConnectedAlwaysInPositive {...props} />
  </Provider>
);
