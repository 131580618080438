import axios from 'axios';

import { ENDPOINT } from '../../constants';
import { mapChangeNumbersResponse, mapNumbersResponse } from '../mappers/numbers';
import { getFancyNumberPattern } from '../mappers/filters';

export const fetchSettings = () =>
  axios(ENDPOINT.settings).then(({ data: { defCodes } }) => defCodes);

export const fetchAllNumbers = async () => {
  return axios({
    url: ENDPOINT.all,
    method: 'GET',
  }).then(({ data }) => mapNumbersResponse(data, true));
};

export const fetchFavoriteNumbers = async (number, options = {}) => {
  return axios({
    url: ENDPOINT.favorite,
    method: options?.method ?? 'POST',
    params: { number },
    ...options,
  }).then(({ data }) => mapNumbersResponse(data));
};

const mapFilterToRule = {
  'krasiviy-hvost': 2,
  'pohozhiy-nomer': 6,
};

export const fetchChangeNumbers = ({ query, filter, defCode }) =>
  axios(ENDPOINT.change, {
    params: {
      pattern: query,
      rule: mapFilterToRule[filter] || 9,
      defcode: defCode === 'XXX' ? undefined : defCode,
    },
  }).then(({ data }) => mapChangeNumbersResponse(data));

export const fetchSimilarNumbers = async ({ filter, number, defCode }, options = {}) => {
  return axios({
    url: ENDPOINT.similar,
    method: options?.method ?? 'POST',
    params: {
      pattern: getFancyNumberPattern(filter, number),
      defnumber: defCode,
      type: filter,
    },
    ...options,
  }).then(({ data }) => mapNumbersResponse(data));
};
