import { useEffect, useState } from 'react';

import store from 'store';

export const useUserNumber = () => {
  const [state, setState] = useState();

  useEffect(() => {
    const handleChange = () => {
      const { external } = store.getState();
      const { user } = external || {};
      const { fttbNumber } = user || {};

      setState(fttbNumber);
    };
    handleChange();

    const unsubscribe = store.subscribe(handleChange);
    return () => unsubscribe();
  }, []);

  return state;
};
