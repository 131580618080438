import { ReactElement, cloneElement } from 'react';
import { connect } from 'react-redux';

import { pushViewNotFound } from 'pages/SearchResultsPage/utils/analytics';
import { useOnScreenEffect } from 'utils/hooks/useOnScreenEffect';
import { getTerm } from 'pages/SearchResultsPage/store/selectors';

type NotFoundWithAnalyticsPropsType = {
  children: ReactElement;
  term: string;
};

export const _NotFoundWithAnalytics = ({ children, term }: NotFoundWithAnalyticsPropsType) => {
  const nodeRef = useOnScreenEffect(() => {
    pushViewNotFound(term);
  }, []);

  return cloneElement(children, { nodeRef });
};

export const NotFoundWithAnalytics = connect((state) => ({ term: getTerm(state) }))(
  _NotFoundWithAnalytics,
);
