import { errorsType } from 'constructorV1/types';
import { TMnpValidateErrors } from 'constructorV1/components/commonComponents/MNPContainer/components/Form/types';

/** Функция устанавливает ошибку валидации Mnp формы по ключу */
export const getErrorStatus = (
  error: keyof typeof errorsType,
  mnpValidateErrors: TMnpValidateErrors,
) => {
  if (error in errorsType) {
    return mnpValidateErrors?.[error];
  }
  return mnpValidateErrors?.failedRequest;
};
