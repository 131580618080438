import { createModalContainer } from '../../enhancers/createModalContainer';
import { ModalMap } from './modals/map';
import { EModal } from './modals/types';

import './modals/BlockedDisconnectServiceStep/index';
import './modals/BlockedServiceStep/index';
import './modals/ConfirmDisconnectStep/index';
import './modals/Error/index';
import './modals/TrustPaymentSuccessStep/index';
import './modals/UnavailableServiceStep/index';

import './modals/ConnectConfirmStep/index';
import './modals/ConnectionErrorStep';
import './modals/ConnectionSuccessStep';

import './modals/DisconnectErrorStep';
import './modals/DisconnectStep/index';
import './modals/DisconnectSuccessStep';

import './modals/RequestErrorStep';

export const ModalContainer = createModalContainer<EModal>(ModalMap);
