import { toISOStringWithoutTimeZone } from 'utils/date';

import { serviceTypes } from '../constants';

/**
 * @param {number} baseFee Изначальная стоимость
 * @param {{ fee: (number|string) }} additionalPackages Массив дополнитальных услуг
 */
export const getFeeWithAdditionalPackages = (baseFee, additionalPackages = []) =>
  Number(
    additionalPackages
      .map((additionalPackage) => additionalPackage.fee)
      .reduce((sum, additionalFee) => sum + Number(additionalFee), Number(baseFee))
      .toFixed(2),
  );

export const mapProductDataFromPromo = (promoTariff, additionalServices, excludedServices = []) => {
  const services = additionalServices || [];
  const mappedAdditionServices = services.map((service) => ({
    Alias: service.alias,
    IsService: service.type !== serviceTypes.tvPackage,
    IsPackage: service.type === serviceTypes.tvPackage,
  }));
  const mappedExcludedServices = excludedServices.map((service) => ({ alias: service.alias }));

  const fee = getFeeWithAdditionalPackages(promoTariff.fee, services);
  return {
    Alias: promoTariff.alias,
    ProductType: promoTariff.productType,
    Name: promoTariff.title,
    ProductInitialFee: {
      Value: fee,
      Unit: promoTariff.feeUnit,
    },
    ExcludedServces: mappedExcludedServices,
    AdditionServces: mappedAdditionServices,
  };
};

export const getCreateOrderData = (
  product,
  connectionData,
  availableScheduleData,
  phone,
  name = '',
) => {
  const { city, street, house, flat } = connectionData;
  const { timePeriod, entrance, intercom } = availableScheduleData;
  const { startDate = '', endDate = '' } = timePeriod || {};

  return {
    City: city.label,
    CityId: city.id,
    Street: street.label,
    StreetId: street.id,
    House: house.label,
    HouseId: house.id,
    Flat: flat,
    Number: phone,
    FullName: name,
    Product: product,
    StartDate: startDate && toISOStringWithoutTimeZone(startDate),
    EndDate: endDate && toISOStringWithoutTimeZone(endDate),
    Entrance: entrance,
    IntercomCode: intercom,
  };
};
