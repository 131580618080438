import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

import { Description } from './components/Description';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TInfoContainer = {
  children: ReactNode;
  description: string;
  title: string;
  variant: 'horizontal' | 'vertical';
};

/** Плитка доп.сервсиов.
 * Имеет два варианта отображения - vertical' | 'horizontal.
 * В качестве children можно добавлять любой компонент */
export const InfoContainer: FC<TInfoContainer> = ({ title, variant, children, description }) => (
  <div className={cx('wrapper', `wrapper--${variant}`)}>
    <Description {...{ title, description }} />
    {children}
  </div>
);
