import { useCallback, useMemo, useState } from 'react';

import { httpRequestApi } from 'pages/ProductCard/actions/serviceConnect';
import { TUseMapStateToProps } from 'pages/Services/ZeroBalanceSupport/enhancers/withContext/types';
import {
  selectConfirmDisconnect,
  selectConfirmDisconnectArchived,
  selectData,
} from 'pages/Services/ZeroBalanceSupport/selectors';
import { TZeroBalanceContext } from 'pages/Services/ZeroBalanceSupport/types';

import { TModalWrapperProps } from '../../../ModalWrapper/types';
import { EModal } from '../types';

export const useMapStateToProps: TUseMapStateToProps<TZeroBalanceContext, TModalWrapperProps> = ({
  state,
  ownProps: { setCurrentModal },
}) => {
  const {
    soc = '',
    disconnectServiceUrl = '',
    pageTitle,
    isAvailableForNextConnection,
  } = selectData(state);

  const confirmDisconnect = selectConfirmDisconnect(state);
  const confirmDisconnectArchived = selectConfirmDisconnectArchived(state);

  const modalData = useMemo(
    () => (isAvailableForNextConnection ? confirmDisconnect : confirmDisconnectArchived),
    [isAvailableForNextConnection, confirmDisconnect, confirmDisconnectArchived],
  );

  const [loading, setLoading] = useState(false);

  const onClose = useCallback(() => setCurrentModal(null, false), []);

  const handleConfirm = useCallback(() => {
    setLoading(true);
    try {
      const disconnectDate = new Date();
      setLoading(true);
      httpRequestApi(disconnectServiceUrl, {
        soc,
        serviceName: pageTitle,
        disconnectDate: disconnectDate.toISOString(),
      })
        .then((data) => {
          if (data && data.isSucceeded) {
            setCurrentModal(EModal.DisconnectSuccess, true);
            setLoading(false);
          } else {
            setCurrentModal(EModal.DisconnectError, true);
          }
        })
        .catch(() => {
          setLoading(false);
          setCurrentModal(EModal.DisconnectError, true);
        });
    } catch (e) {
      console.error(e);
    }
  }, [setLoading, setCurrentModal, disconnectServiceUrl, soc, pageTitle]);

  return {
    onClose,
    setCurrentModal,
    content: {
      modalData,
      actionBarProps: {
        loading,
        confirmButton: {
          content: modalData?.button || {},
          onClick: handleConfirm,
        },
        rejectButton: {
          content: modalData?.buttonReject || {},
          onClick: onClose,
        },
      },
    },
  };
};
