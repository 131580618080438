import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Button, Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PopupButton = ({ price, btnText, onClick }) => (
  <div className={cx('wrapper')}>
    <Button onClick={onClick} rightSideNode={price ? <Text size="size5-r-s">{price}</Text> : null}>
      {btnText}
    </Button>
  </div>
);

PopupButton.propTypes = {
  price: PropTypes.string,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
};

export { PopupButton };
