import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Button, Emoji, Heading } from '@beef/legacy-ui-kit';

import Wizard, { Step } from 'pages-components/Wizard/Wizard';
import SocialButtons from 'pages-components/SocialButtons/SocialButtons';

import SmsForm from './SmsForm';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

class AppLinkSmsPopup extends Component {
  state = {
    activeStep: 'ready',
    error: null,
  };

  componentDidMount() {
    window.dataLayer.push({
      event: 'app_popup',
      popupType: this.props.formMode === 'seeInApp' ? 'offerSms' : 'orderSms',
    });
  }

  getResultText = () => {
    const { texts } = this.props;
    const { error } = this.state;

    if (error) {
      if (error === 'limit') {
        return texts.headingLimit;
      }

      return 'Произошла ошибка';
    }

    return texts.headingSuccess;
  };

  onStepChange = (activeStep, error = null) => {
    this.setState({ activeStep, error });
  };

  render() {
    const { activeStep, error } = this.state;
    const { onClose, texts, appButtons, formMode, sendMessageUrl, currentCtn } = this.props;

    return (
      <Wizard activeStep={activeStep} className={cx('component')} onClose={onClose} opened>
        <Step name="ready">
          <Heading level={1}>
            {formMode === 'seeInApp' ? texts.headingSeeInApp : texts.heading}
          </Heading>
          <Heading level={4}>{texts.description}</Heading>
          <SmsForm
            currentCtn={currentCtn}
            onStepChange={this.onStepChange}
            sendMessageUrl={sendMessageUrl}
            texts={texts}
          />
        </Step>
        <Step name="finished">
          <Emoji className={cx('icon')} name={error ? 'statusFail' : 'statusOk'} />
          <Heading level={1}>{this.getResultText()}</Heading>
          {error && (
            <Heading level={4}>
              {texts.descriptionError}
              <div className={cx('socialButtons')}>
                <SocialButtons data={appButtons} />
              </div>
            </Heading>
          )}
          {!error && (
            <Button className="big" onClick={onClose}>
              {texts.buttonTextSuccess}
            </Button>
          )}
        </Step>
      </Wizard>
    );
  }
}

AppLinkSmsPopup.propTypes = {
  sendMessageUrl: PropTypes.string,
  currentCtn: PropTypes.object,
  appButtons: PropTypes.object,
  formMode: PropTypes.any,
  texts: PropTypes.object,
  onClose: PropTypes.func,
};

export default AppLinkSmsPopup;
