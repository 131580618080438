import {
  SET_ACTIVE_TAB,
  SET_CHOSEN_OPTIONS,
  SET_LOADING,
  SET_PACKAGE_PARAM,
  SET_SOCS_OFF,
  SET_SOCS_ON,
  SET_TARIFF_INFO,
  SET_TOTAL_PRICE,
} from './constants';

const setTotalPrice = (payload) => ({
  type: SET_TOTAL_PRICE,
  payload,
});

const setSocsOn = (payload) => ({
  type: SET_SOCS_ON,
  payload,
});

const setSocsOff = (payload) => ({
  type: SET_SOCS_OFF,
  payload,
});

const setPackageParams = (payload) => ({
  type: SET_PACKAGE_PARAM,
  payload,
});

const setOptionParams = (payload) => ({
  type: SET_CHOSEN_OPTIONS,
  payload,
});

const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

const setTariffInfo = (payload) => ({
  type: SET_TARIFF_INFO,
  payload,
});

const setActiveTab = (payload) => ({
  type: SET_ACTIVE_TAB,
  payload,
});

export {
  setActiveTab,
  setLoading,
  setOptionParams,
  setPackageParams,
  setSocsOff,
  setSocsOn,
  setTariffInfo,
  setTotalPrice,
};
