import { useEffect, useState } from 'react';

/** Кастомный хук для отслеживания видимости секций и скрытия фиксированного элемента */
export const useHideOnScroll = (sectionIds: string[]) => {
  const [isHiddenBlock, setIsHiddenBlock] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const anySectionVisible = entries.some((entry) => entry.isIntersecting);
        setIsHiddenBlock(anySectionVisible);
      },
      { rootMargin: '-140px 0px -140px 0px' },
    );

    const sections = sectionIds.map((id) => document.getElementById(id));

    sections.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sections.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, [sectionIds]);

  return isHiddenBlock;
};
