import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { ModalIcon } from '../ModalIcon';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TModalIcons = {
  pictures?: Array<{ image: string }>;
};

/** Компонент-обертка массива иконок модального окна опции */
export const ModalIcons: FC<TModalIcons> = ({ pictures }) =>
  pictures?.length ?
    <ul className={cx('wrapper')}>
      {pictures.map(({ image }, index) => (
        <ModalIcon icon={image} key={image} testId={index} />
      ))}
    </ul>
  : null;
