import { useEffect, useState } from 'react';

export const useInView = (target, options = {}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [observer, setObserver] = useState(null);
  useEffect(() => {
    const handleIntersect = (entries) => {
      setIsIntersecting(entries[0].isIntersecting);
    };

    observer?.disconnect();

    if (target.current) {
      const _observer = new IntersectionObserver(handleIntersect, options);
      _observer.observe(target.current);
      setObserver(_observer);
    }
  }, [target.current, options.root, options.rootMargin, options.threshold]);

  useEffect(
    () => () => {
      observer?.disconnect();
    },
    [],
  );

  return isIntersecting;
};
