import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text, TextPropsType } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TDescription = {
  description?: string;
} & Omit<TextPropsType, 'size'>;

/** Компонент описания для InfoPrice */
export const Description: FC<Partial<TDescription>> = ({ color, description }) =>
  description ?
    <div className={cx('wrapper')}>
      <Text size="size6-m" {...{ color }}>
        {description}
      </Text>
    </div>
  : null;
