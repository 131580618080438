import { connect } from 'react-redux';

import OrderTariffPopupsGroup from 'pages/FTTB/components/OrderTariffPopupsGroup';
import { catalogPageTypeList } from 'pages/FTTB/constants';

const mapStateToProps = ({ external: { fttbCatalogData } }) => ({
  orderFormData: fttbCatalogData.orderFormData,
  connectingRequestUrl: fttbCatalogData.urlCreateOrder,
  shouldFetchSpecTariff: fttbCatalogData.specialTariffsEnabled,
  backCallPopupData: fttbCatalogData.callBackRequestData,
  topTariffPromoModel: fttbCatalogData.topTariffPromoModel,
  availableScheduleData: fttbCatalogData.availableScheduleData,
  isFttbWithTv: fttbCatalogData.pageType === catalogPageTypeList.kit,
});

export default connect(mapStateToProps)(OrderTariffPopupsGroup);
