import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { connect } from 'react-redux';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

type TitleType = {
  title: string;
};

const _Title = ({ title }: TitleType) => (
  <Text className={cn('title')} size="size0-b" tagType="h1">
    {title}
  </Text>
);

// @ts-expect-error state not typed in ts yet
export const Title = connect(({ content }): TitleType => ({ title: content.title }))(_Title);
