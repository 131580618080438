import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

// Components
import { Switch } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import LegalTooltip from 'pages/ProductCard/components/SecretaryService/components/LegalTooltip';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ERROR_TYPES = {
  ID_DISABLED: 'IDDISABLED',
  ID_ENABLED: 'IDENABLED',
  NO_SERVICE: 'NOSERVICE',
};

class ServiceParameters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  handleRequest = (url) => {
    const { name, id, openPopup, onSwitchClick, popupContent } = this.props;

    const { ID_DISABLED, ID_ENABLED, NO_SERVICE } = ERROR_TYPES;

    axios(url, {
      method: 'post',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      data: { serviceId: id },
    })
      .then((response) => {
        if (response?.data?.IsSucceeded || response?.data?.isSucceeded) {
          onSwitchClick(id);
        } else {
          switch (response?.data?.ErrorCode || response?.data?.errorCode) {
            case NO_SERVICE: {
              openPopup(popupContent?.errorNOSERVICE, name);
              break;
            }
            case ID_ENABLED: {
              openPopup(popupContent?.errorIDENABLED, name);
              break;
            }
            case ID_DISABLED: {
              openPopup(popupContent?.errorIDDISABLED, name);
              break;
            }
            default: {
              openPopup(popupContent?.errorDefault, name);
            }
          }
        }
      })
      .catch((err) => {
        console.error(err);
        openPopup(popupContent?.errorDefault, name);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleSwitchClick = () => {
    const { checkIfLastConnectedService, isConnected, handleLastConnectedService } = this.props;
    const lastConnectedService = checkIfLastConnectedService();

    this.setState({ isLoading: true });

    if (isConnected) {
      if (lastConnectedService) {
        handleLastConnectedService();

        this.setState({ isLoading: false });
      } else {
        this.handleRequest('/beelinesekretarapi/remove/');
      }

      return null;
    }

    this.handleRequest('/beelinesekretarapi/add/');
  };

  render() {
    const {
      name,
      description,
      areOptionsVisible,
      value,
      unit,
      legal,
      isSecretaryServiceDisconnected,
      isConnected,
    } = this.props;

    const { isLoading } = this.state;

    return (
      <div className={cx('secretaryServiceListItem')}>
        <div className={cx('leftBox')}>
          <div className={cx('name')} dangerouslySetInnerHTML={{ __html: name }} />
          {description && (
            <div className={cx('description')} dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </div>

        {areOptionsVisible && (
          <div className={cx('rightBox')}>
            <Switch
              checked={isSecretaryServiceDisconnected ? false : isConnected}
              disabled={isSecretaryServiceDisconnected || isLoading}
              isLoading={isLoading}
              onChange={this.handleSwitchClick}
            />
          </div>
        )}

        {!isEmpty(value) && (
          <div className={cx('rightBox')}>
            <span className={cx('valueNumber', { withUnit: unit })}>{value}</span>
            &nbsp;
            {value === 0 ?
              <span className={cx('valueUnit')}>₽</span>
            : <span className={cx('valueUnit')} dangerouslySetInnerHTML={{ __html: unit }} />}
            {legal && <LegalTooltip className={cx('tooltip')} legal={legal} />}
          </div>
        )}
      </div>
    );
  }
}

ServiceParameters.defaultProps = {
  popupContent: {},
  description: null,
};

ServiceParameters.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  areOptionsVisible: PropTypes.bool,
  isConnected: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
  legal: PropTypes.string,
  openPopup: PropTypes.func,
  isSecretaryServiceDisconnected: PropTypes.bool,
  onSwitchClick: PropTypes.func,
  checkIfLastConnectedService: PropTypes.func,
  handleLastConnectedService: PropTypes.func,
  popupContent: PropTypes.shape({
    errorNOSERVICE: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      errorEmoji: PropTypes.string,
      buttonText: PropTypes.string,
    }),
    errorIDDISABLED: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      errorEmoji: PropTypes.string,
      buttonText: PropTypes.string,
    }),
    errorIDENABLED: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      errorEmoji: PropTypes.string,
      buttonText: PropTypes.string,
    }),
    errorDefault: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      errorEmoji: PropTypes.string,
      buttonText: PropTypes.string,
    }),
  }),
};

export default ServiceParameters;
