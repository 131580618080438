import { useCallback, useState } from 'react';
import { getCookie } from '@beef/utils';
import { formatOrderData, handleSuccessRequest } from '@beef/layout-kit/utils';

import { getOnlyDigitsPhone, isFilledPhone } from 'utils/format-string';
import { isNumberBought } from 'utils/cartHelpers';
import { pushEcommerceBuyMnp, pushEcommerceSuccessBuyMnp } from 'utils/analytics/simOrderAnalytics';

import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { useAppSelector } from '../../../../../../../store';
import {
  selectInputBlockContent,
  selectMainContent,
} from '../../../../../../../store/slices/commonSlice/selectors';
import { validateMNPOrder } from '../../../api/validateMNPOrder';
import { createMNPOrder } from '../../../api/createMNPOrder';
import { getRegionId } from '../../../utils/getRegionId';
import { fetchBasket } from '../../../api/fetchBasket';
import { getPurchasedItems } from '../../../utils/getPurchasedItems';
import {
  selectIsMNPService,
  selectTarrifId,
} from '../../../../../../../store/slices/additionalDataSlice/selectors';
import { DEFAULT_CONSTRUCTOR_ID } from '../../../../../../../constants';
import { createBasket } from '../../../api/createBasket';

/**
 * Хук для инпута переноса номера
 */
export const useInputForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { errors } = useAppSelector(selectMainContent);
  const inputContent = useAppSelector(selectInputBlockContent);
  const initialTarrifId = useAppSelector(selectTarrifId);
  const isMNPService = useAppSelector(selectIsMNPService);

  /** хендлер для изменения значения в инпуте */
  const handleChangeNumber = (newValue?: string) => {
    if (!newValue) {
      return;
    }

    /** Чистим ошибку перед вводом нового символа, если она есть */
    if (error) {
      setError('');
    }

    /**
     * Если номер имеет валидную длину, но пользователь продолжает
     * вводить символы - не меняем значения в стейте
     * */
    if (isFilledPhone(phoneNumber) && newValue.length > phoneNumber.length) return;

    /** Форматируем введенный номер под шаблон "+7 XXX XXX XX XX" */
    setPhoneNumber(formatPhoneNumber(newValue));
  };

  /** Когда инпут оказывается в фокусе, то по-умолчанию ставим "+7" */
  const handleFocus = () => {
    if (!phoneNumber || !phoneNumber.startsWith('+7')) {
      setPhoneNumber('+7');
    }
  };

  /** Если человек ничего не ввел, то чистим префикс (+7) */
  const handleBlur = () => {
    if (phoneNumber && phoneNumber.length <= 2) {
      setPhoneNumber('');
      setError('');
    }
  };

  /** Очистка инпута */
  const handleClear = () => {
    setPhoneNumber('');
    setError('');
  };

  /** Хеендлер для установки ошибки, используется в сабмите */
  const handleSetError = (errorText: string) => {
    setError(errorText);
    setIsLoading(false);
  };

  /**
   * Сабмит формы
   *
   * Большая часть логики перенесена из старого МНП, также работу
   * с запросами помещаем здесь, поскольку с большой долей вероятности
   * данная форма может быть использована на главной странице, на которой
   * на текущий момент неясно как будут обстоять дела со стором, поэтому в
   * целях менее болезненного переноса основная логика прописана в хуке.
   *
   * TODO когда решится вопрос с главной - здесь перенести все на полноценный стор,
   * а работу с запросами на asyncThunk
   */
  const handleSubmit = useCallback(async () => {
    try {
      /** Чистим номер от всех пробелов и префикса */
      const clearPhoneNumber: string = getOnlyDigitsPhone(phoneNumber);

      /** Проверяем введенный номер на корректность */
      if (!isFilledPhone(clearPhoneNumber)) {
        setError(errors.notFilledCtn);
        return;
      }

      setIsLoading(true);

      /** Запрос валидации номера */
      const validationResult = await validateMNPOrder(clearPhoneNumber);

      /** Если запрос упал, то ставим ошибку упавшего запроса */
      if (typeof validationResult === 'string' || !validationResult.IsSucceeded) {
        handleSetError(errors.failedRequest);
        return;
      }

      /** Если в ответе IsExistingCtn = true, значит номер уже в билайне */
      if (validationResult.View.IsExistingCtn) {
        handleSetError(errors.isExistingCtn);
        return;
      }

      /** Получаеем регион пользователя */
      const regionId = getRegionId();

      if (!regionId) {
        console.error('not found region');
        return;
      }

      /** Перенесено из старого MNP */
      pushEcommerceBuyMnp(initialTarrifId);

      /** Запрос на создание заказа по переносу номера */
      const createOrderResult = await createMNPOrder(clearPhoneNumber, regionId);

      /** Если запрос упал, то ставим ошибку упавшего запроса */
      if (typeof createOrderResult === 'string' || !createOrderResult.IsSucceeded) {
        handleSetError(errors.failedRequest);
        return;
      }

      /** Получаем айдишник корзины */
      const basketId = getCookie('basket_id');

      /**
       * Если айдишник есть, то необходимо узнать, нет ли
       * в ней уже введенного пользователем номера.
       *
       * Поскольку в новой шапке нет функционала по работе с корзиной,
       * то есть этих данных нет в глобальном сторе, делаем запрос за корзиной
       */
      if (basketId) {
        const fetchBasketResult = await fetchBasket(basketId);

        /** Если запрос упал, то ставим ошибку упавшего запроса */
        if (typeof fetchBasketResult === 'string') {
          handleSetError(errors.failedRequest);
          return;
        }

        /** Форматируем данные из корзины */
        const basketItems = getPurchasedItems(fetchBasketResult.items);

        /**
         * Если в корзине уже есть номер,
         * который ввел пользоатель,
         * то ставим соответствуующую ошибку
         */
        if (isNumberBought(basketItems.numbers, clearPhoneNumber)) {
          handleSetError(errors.isCtnAlreadyInBasket);
          return;
        }
      }

      /** Перенесено из старого МНП */
      // Дефолтный объект с данными для корзины, на случай пустого пропа constructorTariffData
      const defaultConstructorTariffData = {
        // Значение захардкожено, так как соответствует дефолтному тарифу. Согласовано с аналитиком
        tariffConstructorId: DEFAULT_CONSTRUCTOR_ID,
        optionSocs: '',
      };

      // PEB-4891: в ответе createMnpOrder теперь присутствуют соки тарифа (и опций) для корректной работы корзины
      if (Array.isArray(createOrderResult?.View?.Options)) {
        defaultConstructorTariffData.optionSocs = createOrderResult?.View?.Options.join(',');
      }

      /** Формируем данные для корзины */
      const request = formatOrderData({
        constructorTariffData: defaultConstructorTariffData,
        mnp: clearPhoneNumber,
        simOrderPlace: 'mnp',
        tariffId: `${
          !initialTarrifId || isMNPService ? createOrderResult.View.TariffId : initialTarrifId
        }`,
        additionalCtnOptions: {},
      });

      /** Запрос на создание корзины */
      const createBasketResult = await createBasket(request);

      /** Если запрос упал, то ставим ошибку упавшего запроса */
      if (typeof createBasketResult === 'string') {
        handleSetError(errors.failedRequest);
        return;
      }

      /** Перенесено из старого MNP */
      pushEcommerceSuccessBuyMnp(initialTarrifId);

      /** Редиректим на страницу с корзиной */
      handleSuccessRequest({
        cartId: createBasketResult.id,
        items: createBasketResult.items,
        redirectToCart: true,
      });

      setIsLoading(false);
    } catch (catchError) {
      console.error(catchError);
    }
  }, [errors, initialTarrifId, isMNPService, phoneNumber]);

  return {
    handleChangeNumber,
    handleFocus,
    handleBlur,
    handleClear,
    phoneNumber,
    buttonTitle: inputContent.buttonTitle,
    placeholder: inputContent.placeholder,
    isLoading,
    error,
    handleSubmit,
  };
};
