import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import qs from 'query-string';
import axios from 'axios';
import { equals, map, omit } from 'ramda';
import classNames from 'classnames/bind';
import {
  AdaptiveMenu,
  Breadcrumbs,
  Button,
  Emoji,
  Heading,
  Link,
  Preloader,
  ProductParameter,
  ProductParameters,
  SaleRow,
  Status,
  TextInput,
} from '@beef/legacy-ui-kit';

import { debounce } from 'utils/timed-functions';
import store from 'store';

import styles from './SiteSearch.pcss';
import LoginForm from './components/LoginForm';

const cx = classNames.bind(styles);

const getColor = (value) => {
  const color = /(.+);(#[a-f0-9]{6})/i.exec(value);
  if (!color || !color[1]) {
    return value;
  }

  return `${color[1]}<span class="colorDot" style="background:${color[2]}" />`;
};

const omitActive = map(omit(['active']));

class SiteSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      groups: [],
      omitedGroups: [],
      search: {
        query: '',
        skip: 0,
        groups: qs.parse(window.location.search).groups || props.defaultGroup,
      },
      isLoading: true,
    };
  }

  componentDidMount() {
    const search = qs.parse(window.location.search);
    this.setState(
      (prevState) => ({
        search: {
          ...prevState.search,
          query: search.query || '',
        },
        isLoading: false,
      }),
      this.startSearch,
    );
  }

  handleSearch = (query) => {
    this.setState(
      (prevState) => ({
        results: [],
        groups: [],
        search: {
          ...prevState.search,
          query,
          skip: 0,
        },
        isLoading: false,
      }),
      () => this.updateActiveGroup({ alias: this.props.defaultGroup }),
    );
  };

  startSearch = () => {
    this.updateLocation();

    if (!this.state.search.query) return;

    this.setState({ isLoading: true });
    this.updateSearch();
  };

  updateSearch = debounce(() => {
    const { apiUrl, pageLength } = this.props;
    const search = update(this.state.search, {
      $merge: {
        take: pageLength,
      },
    });

    axios.get(`${apiUrl}?${qs.stringify(search)}`).then((response) => {
      const { results, groups } = response.data;
      if (!results || results.length === 0) {
        if (!groups || results.length === 0) {
          this.setState({
            isLoading: false,
          });
        } else {
          this.updateActiveGroup(groups[0]);
        }
        return;
      }

      this.setState((prevState) => {
        const omitedGroups = omitActive(groups);
        const groupsChanged = !equals(omitedGroups, prevState.omitedGroups);

        return {
          results: update(this.state.results, { $push: results }),
          groups,
          omitedGroups: groupsChanged ? omitedGroups : prevState.omitedGroups,
          isLoading: false,
        };
      });
    });
  }, 1000);

  loadMore = () => {
    const { search } = this.state;
    const { pageLength } = this.props;

    this.setState(
      {
        isLoading: true,
        search: update(search, {
          $merge: {
            skip: search.skip + pageLength,
          },
        }),
      },
      this.startSearch,
    );
  };

  updateActiveGroup = (group) => {
    this.setState(
      {
        results: [],
        search: update(this.state.search, {
          $merge: {
            groups: group.alias,
            skip: 0,
          },
        }),
      },
      this.startSearch,
    );
  };

  updateLocation = () => {
    const search = omit(['skip'], this.state.search);

    if (search.query) {
      window.history.replaceState({}, '', `${window.location.pathname}?${qs.stringify(search)}`);
    } else {
      window.history.replaceState({}, '', window.location.pathname);
    }
  };

  renderGetMoreButton = () => {
    const { groups, search } = this.state;
    const { pageLength } = this.props;
    const activeGroup = groups.find((el) => el.alias === search.groups);
    const resultsToSkip = activeGroup.count - pageLength - search.skip;

    if (resultsToSkip <= 0) return null;

    const buttonCount = resultsToSkip > pageLength ? pageLength : resultsToSkip;

    return (
      <div className={cx('moreButton')}>
        <Button className="default" onClick={this.loadMore}>
          {`Показать ещё ${buttonCount}`}
        </Button>
      </div>
    );
  };

  render() {
    const { results, groups, omitedGroups, search, isLoading } = this.state;

    return (
      <div className={cx('pageWrapper')}>
        <div className={cx('pageHeader')}>
          <div className={cx('contentWrapper')}>
            <Heading className={cx('pageHeading')} level={1}>
              Поиск по сайту
            </Heading>

            <div className={cx('searchField')}>
              <TextInput
                onChange={this.handleSearch}
                onClear={() => this.handleSearch('')}
                type="search"
                value={search.query}
              />
            </div>

            <div className={cx('pageHeaderContent')}>
              {groups.length ?
                <AdaptiveMenu
                  activeEntry={groups.findIndex((el) => el.alias === search.groups)}
                  className={cx('categories')}
                  entries={omitedGroups}
                  onChange={this.updateActiveGroup}
                  tickId={-1}
                />
              : null}

              {!isLoading && search.query && !results.length ?
                <div className={cx('noResults')}>
                  <span className={cx('text')}>Ничего не нашлось</span>
                  <Emoji className={cx('emoji')} name="smile-sad" />
                  <span className={cx('text')}>уточните, пожалуйста, запрос</span>
                </div>
              : null}

              {!isLoading && !search.query && !results.length ?
                <div className={cx('noResults')}>
                  <span className={cx('text')}>Начните вводить поисковый запрос</span>
                  <Emoji className={cx('emoji')} name="smile-happy" />
                </div>
              : null}

              {isLoading && !groups.length ?
                <div className={cx('preloader')}>
                  <Preloader />
                </div>
              : null}
            </div>
          </div>
        </div>

        <div className={cx('contentWrapper', 'searchSection')}>
          {results.length ?
            <div className={cx('searchResults')}>
              {results.map((item, index) => {
                const result = item.data;
                const isLoginForm = result.type === 'personalpage' || item._type === 'personalpage';

                if (isLoginForm) {
                  return (
                    <LoginForm
                      authorized={this.props.authorized}
                      description={result.description}
                      title={result.title}
                      titleLink={result.link}
                    />
                  );
                }

                return (
                  <div className={cx('searchResult')} key={index}>
                    <Heading className={cx('resultTitle')} level={4}>
                      <Link href={result.link}>
                        <span dangerouslySetInnerHTML={{ __html: result.title }} />
                      </Link>
                      {result.archived ?
                        <div className={cx('archivedStatus')}>
                          <Status className={['compact']}>В архиве</Status>
                        </div>
                      : null}
                    </Heading>
                    <div className={cx('richContent')}>
                      {result.image ?
                        <a className={cx('image')} href={result.link}>
                          <img alt="" src={result.image} />
                        </a>
                      : null}
                      <div className={cx('text')}>
                        {result.description ?
                          <div
                            className={cx('description')}
                            dangerouslySetInnerHTML={{ __html: result.description }}
                          />
                        : null}
                        {result.parameters && result.parameters.length ?
                          <ProductParameters className={['tight', cx('parameters')]}>
                            {result.parameters.map((parameter, paramIndex) => (
                              <ProductParameter
                                key={paramIndex}
                                label={parameter.name}
                                subscriptionFeeDiscount={parameter.subscriptionFeeDiscount}
                                type="raw"
                                unit={parameter.unit}
                                value={
                                  parameter.name === 'Цвет' ?
                                    getColor(parameter.value)
                                  : parameter.value
                                }
                              />
                            ))}
                          </ProductParameters>
                        : null}
                        {result.offer ?
                          <SaleRow className={cx('offer')}>{result.offer}</SaleRow>
                        : null}
                        {result.oldPrice ?
                          <div className={cx('oldPrice')}>{result.oldPrice} ₽</div>
                        : null}
                        {result.price ?
                          <div className={cx('priceGroup')}>
                            <div className={cx('price')}>{result.price} ₽</div>
                            {result.buyLink ?
                              <div className={cx('buyButton')}>
                                <Button href={result.buyLink.url} wide>
                                  {result.buyLink.title}
                                </Button>
                              </div>
                            : null}
                          </div>
                        : null}
                      </div>
                    </div>

                    {result.type !== 'shopItem' && result.buyLink && !result.archived ?
                      <div className={cx('connectButton')}>
                        <div className={cx('button')}>
                          <Button href={result.buyLink.url} wide>
                            {result.buyLink.title}
                          </Button>
                        </div>
                        {result.otherLink ?
                          <div className={cx('otherLink')}>
                            <Link href={result.otherLink.url}>{result.otherLink.title}</Link>
                          </div>
                        : null}
                      </div>
                    : null}

                    {result.breadcrumbs && result.breadcrumbs.length ?
                      <Breadcrumbs className={cx('breadcrumbs')} entries={result.breadcrumbs} />
                    : null}
                  </div>
                );
              })}
            </div>
          : null}

          {isLoading && (results.length || groups.length) ?
            <div className={cx('preloader')}>
              <Preloader />
            </div>
          : null}

          {!isLoading && results.length ? this.renderGetMoreButton() : null}
        </div>
      </div>
    );
  }
}

SiteSearch.defaultProps = {
  apiUrl: '/customers/search',
  defaultGroup: '_all',
  pageLength: 10,
};

SiteSearch.propTypes = {
  apiUrl: PropTypes.string,
  defaultGroup: PropTypes.string,
  pageLength: PropTypes.number,
  authorized: PropTypes.bool,
};

const ConnectedLoginPage = connect((state) => ({
  authorized: !!state.external.currentCtn,
}))(SiteSearch);

export default (props) => (
  <Provider store={store}>
    <ConnectedLoginPage {...props} />
  </Provider>
);
