import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { BannerContent } from './components';
import styles from './styles.pcss';
import { TBillBanner } from './types';

const cx = classNames.bind(styles);

/** баннер, включающий компонент с описанием и картинку */
export const BillBanner = memo(({ bgColor, contentProps, image }: TBillBanner) => (
  <div className={cx('wrapper')} style={{ backgroundColor: bgColor }}>
    <BannerContent {...contentProps} />
    {image && <img alt="" className={cx('wrapper__image')} loading="lazy" src={image} />}
  </div>
));
