import { cloneElement } from 'react';
import PropTypes from 'prop-types';

import Ctx from 'pages-components/Ctx';

import { getHeight, getIsUpperBarTariff } from './actions';
import { getTariffParams } from './getTariffParams';
import { getDigitalCardParams } from './getDigitalTariffsParams';
import { getAdditionalParams } from './getAdditionalParams';
import { getPrice } from './getPrice';
import { getBottomBubble, getTopBubble } from './getBubble';

// Components

/**
 * @param additionalParameters - Список дополнительных параметров, выводится под бордюром;
 * @param availableOnlyInOffice - Доступен для подключения в офисе;
 * @param newYearDiscountText - Новогодний текст скидки для желтой плашки под заголовком;
 * @param nameplateDiscount - Скидка-текст для углового шильдика;
 * @param discount - Скидка;
 * @param priceDescription - Текст желтой плашки со стрелочкой, рядом со скидкой.
 * Флаг showDiscount управляет позицией скидки;
 * @param onButtonClick - Callback для кнопки;
 * @param buttonGroup - props для компонента GroupButton, если не указаны отображается FlatButton;
 * @param isConnected - Значение указывает как отображать кнопку карточки тарифа;
 * @param parameters - Список параметров;
 * @param clickable - Отвечает за кликабельность карточки;
 * @param hideButton - Отвечает за показ кнопки;
 * @param type - Тип виджета, влияет на отображение карточки;
 * @param isYandex - Флаг. Овечает за окрас первой буквы для Я.Тарифов и особого градиента;
 *
 * ------------------ ВАЖНО! --------------------
 * @param tariffsGroup - Список тарифов, необходим для вычисления высоты блока additionParameters,
 *                     если не пмередан этот параметр, высота блока additionalParameters вычисляться не будет;
 * ----------------------------------------------
 *
 * @param cardStyle - Стиль отображения карточки (черная/белая);
 * @param children - Дочерний элемент;
 * @param price - Цена;
 * @param priceNotLoaded - цена не загружена - надо обновить страницу;
 * @param title - Заголовок карточки;
 * @param alias - Уникальный алиас карточки;
 * @param link - Ссылка на карточку тарифа;
 * @param portal - Как будет рендерится компонент: через портал или в текущий блок;
 * @param titleIcon - Иконка в верхнем правом углу карточки тарифа;
 * @param forEsim - Булевое значение указывающее на наличие eSim в тарифе, благодаря этому флагу мы рисуем плашку;
 * @param bubble - Баблик рекомендации вывдится под карточкой;
 * @param discount.bubble - Баблик скидки вывдится над карточкой;
 * @param id - Уникальный идентификатор тарифа;
 * @param showTopBubble - Булевое значение задающее отображение верхнего бабла;
 * @param showBottomBubble - Булевое значение задающее отображение нижнего бабла;
 * @param withLevitate - Анимация по ховеру;
 * @param paramsGridView - Отображение параметров плиткой;
 */

const TariffCardParamsHoc = ({
  additionalParameters,
  actionLabel,
  availableOnlyInOffice,
  buttonGroup,
  portal,
  bubble,
  newYearDiscountText,
  nameplateDiscount,
  priceDescription,
  showTopBubble,
  showBottomBubble,
  discount,
  onButtonClick,
  isConnected,
  parameters,
  tariffsGroup,
  hideButton,
  cardStyle,
  titleIcon,
  clickable,
  children,
  forEsim,
  price,
  priceNotLoaded,
  title,
  alias,
  link,
  type,
  id,
  withLevitate,
  paramsGridView,
  orderButton,
  isYandex,
  offerType,
}) => {
  let priceHeight = null;
  let getCardParams = null;
  let cardAdditionalParams = {};
  let isCardsHasUpperBar = null;

  if (type === 'digital-tariff') {
    getCardParams = getDigitalCardParams(parameters);
  } else {
    getCardParams = parameters.map(getTariffParams(cardStyle)).map((item) => ({
      ...item,
      parameterType: item.widgetType === 'empty' ? 'empty' : 'tariff',
    }));
  }

  const bottomButtonType = buttonGroup ? 'group' : 'flat';

  // Если это не НоваяГлавная начинаем вычисления;
  if (tariffsGroup) {
    cardAdditionalParams = getAdditionalParams(tariffsGroup, additionalParameters);
    // Вычисляет высоту блока скидки
    priceHeight = getHeight(tariffsGroup);

    // Проверяет, доступность eSim в тарифе, если хотябы один тариф доступен
    // функция вернет true. После чего мы будем знать, делать отступ у карточки тарифа или нет.
    isCardsHasUpperBar = getIsUpperBarTariff(tariffsGroup);
  } else {
    isCardsHasUpperBar =
      tariffsGroup && tariffsGroup.some((item) => item.forEsim || item.isConnected);
  }

  // Если есть eSim в тарифе, создаем объект с данными и прокидываем в карточку;
  let upperBarObj = null;
  if (forEsim && !isConnected) {
    upperBarObj = Ctx.tariffCard.upperBar.eSim;
  } else if (isConnected) {
    // Если тариф подключен
    upperBarObj =
      cardStyle === 'young' ?
        Ctx.tariffCard.upperBar.connectedYoung
      : Ctx.tariffCard.upperBar.connected;
  } else if (availableOnlyInOffice) {
    // Если тариф доступен только для подключения в офисе либо он архивный, выодим серую плашку
    upperBarObj = Ctx.tariffCard.upperBar.onlyInOffice;
  }

  // Для главной отображаем иконку домика и кнопку с градиентом;
  const nameplateIcon = titleIcon;

  const priceObj = getPrice(price, discount, priceDescription);

  const bubbleObj = { id, portal };
  // Объект для баблика над карточкой
  const topBubble =
    showTopBubble &&
    (discount || {}).bubble &&
    getTopBubble({ bubble: discount.bubble, ...bubbleObj });
  // Объект для баблика под карточкой
  const bottomBubble = showBottomBubble && bubble && getBottomBubble({ bubble, ...bubbleObj });

  return cloneElement(children, {
    ...cardAdditionalParams,
    actionLabel,
    inlineDiscountText: newYearDiscountText,
    bottomBubble,
    topBubble,
    hideButton,
    parameters: getCardParams,
    isConnected,
    clickable,
    isCardsHasUpperBar,
    upperBar: upperBarObj,
    nameplateIcon,
    nameplateDiscount,
    onButtonClick,
    buttonText: Ctx.tariff.buttonText.learnMoreTariffCard,
    cardStyle,
    title,
    alias,
    link,
    bottomButtonType,
    buttonGroup,
    paramsGridView,
    isYandex,
    orderButton,
    price: {
      priceHeight,
      ...priceObj,
    },
    priceNotLoaded,
    withLevitate,
    offerType,
  });
};

TariffCardParamsHoc.defaultProps = {
  actionLabel: '',
  additionalParameters: [],
  discountLabel: null,
  showTopBubble: true,
  showBottomBubble: true,
  hideButton: false,
  clickable: true,
  paramsGridView: false,
  isYandex: false,
  parameters: [],
};

TariffCardParamsHoc.propTypes = {
  portal: PropTypes.bool,
  showTopBubble: PropTypes.bool,
  paramsGridView: PropTypes.bool,
  isYandex: PropTypes.bool,
  showBottomBubble: PropTypes.bool,
  isConnected: PropTypes.bool,
  clickable: PropTypes.bool,
  hideButton: PropTypes.bool,
  forEsim: PropTypes.bool,
  withLevitate: PropTypes.bool,
  priceNotLoaded: PropTypes.bool,

  newYearDiscountText: PropTypes.string,
  nameplateDiscount: PropTypes.string,
  cardStyle: PropTypes.string,
  title: PropTypes.string,
  alias: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
  actionLabel: PropTypes.string,
  offerType: PropTypes.string,

  id: PropTypes.number,

  children: PropTypes.object,
  discount: PropTypes.object,
  priceDescription: PropTypes.object,
  buttonGroup: PropTypes.object,
  bubble: PropTypes.object,
  price: PropTypes.object,
  orderButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),

  parameters: PropTypes.array,
  tariffsGroup: PropTypes.array,
  additionalParameters: PropTypes.array,

  onButtonClick: PropTypes.func,
};

export default TariffCardParamsHoc;
