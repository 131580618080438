import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { setActiveTab } from 'pages/ProductCard/Tariff/containers/ConstructorTariff/actions';
import DispatchContext from 'pages/ProductCard/Tariff/containers/ConstructorTariff/constants';
import { ymPushEvent } from 'utils/ym';

import { Tab } from './Tab';
import styles from './styles.pcss';
import { getTabsFromContentArray, sendMainTabsYaAnalytics } from '../../../utils';

const cx = classNames.bind(styles);

const ymKeyByTabId = {
  SimOrderForm: 'poluchit_novy_nomer',
  ChangeTariffForm: 'izmenit_tariff',
  MNPForm: 'pereiti_s_sohraneniem_nomera',
};

export const Tabs = memo(
  ({
    data: { title, content },
    isAuthenticated,
    isAnimalsTariffLayout,
    isTariffAvailableForConnect,
    isTariffConnected,
    rootChosenIndex,
  }) => {
    const _dispatch = useContext(DispatchContext);
    const defaultTab =
      isAuthenticated && (isTariffAvailableForConnect || isTariffConnected) ? 1 : 0;
    const [selectedTab, setSelectedTab] = useState(defaultTab);
    const [filteredTabs, setFilteredTabs] = useState(content);

    const handleSetTabClick = useCallback((idx, { id }) => {
      if (isAnimalsTariffLayout) {
        sendMainTabsYaAnalytics({ id });
      }
      ymPushEvent(ymKeyByTabId[id]);
      setSelectedTab(idx);
    }, []);

    useEffect(() => {
      if (!isAuthenticated && filteredTabs[selectedTab].id === 'ChangeTariffForm') {
        _dispatch(setActiveTab('LoginForm'));
        return;
      }
      _dispatch(setActiveTab(filteredTabs[selectedTab].id));
    }, [selectedTab]);

    useEffect(() => {
      const tabs = getTabsFromContentArray({
        tabs: content,
        isAuth: isAuthenticated,
        isConnected: isTariffConnected,
        isAvailable: isTariffAvailableForConnect,
      });
      setFilteredTabs(tabs);
    }, []);

    return (
      <>
        {title && (
          <Text size="size3-m" tagType="h2">
            {title}
          </Text>
        )}
        <div className={cx('wrapper-tabs')}>
          {filteredTabs.map((tab, idx) => (
            <Tab
              fancyNumber={tab?.fancyNumber}
              id={idx}
              key={idx}
              onClick={() => handleSetTabClick(idx, tab)}
              rootChosenIndex={rootChosenIndex}
              selected={selectedTab}
              title={tab?.text}
            />
          ))}
        </div>
      </>
    );
  },
);

Tabs.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.array,
  }),
  isAuthenticated: PropTypes.bool,
  isTariffAvailableForConnect: PropTypes.bool,
  isTariffConnected: PropTypes.bool,
  rootChosenIndex: PropTypes.number,
  isAnimalsTariffLayout: PropTypes.bool,
};
