import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import store from 'store';
import { ZeroBalanceSupportWithContext } from 'pages/Services/ZeroBalanceSupport/ZeroBalanceSupportWithContext';
import {
  connectShape,
  contentShape,
  disconnectServiceSuccess,
  serviceDataShape,
  successStepShape,
  unavailableServiceShape,
} from 'pages/Services/ScopeZeroBalanceServices/propTypes/zeroBalanceSupportTypes';

import { ZeroBalanceSupportProvider } from './ZeroBalanceContextProvider/index';

const ZeroBalanceSupport = (props) => {
  const contextValue = useMemo(
    () => ({
      data: props?.data,
      content: props?.data?.extData?.content,
      requestStatus: props?.data?.extData?.content?.requestStatus,
    }),
    [props.data],
  );

  return (
    <Provider store={store}>
      <ZeroBalanceSupportProvider data={contextValue}>
        <ZeroBalanceSupportWithContext
          faq={contextValue.content.faq}
          isConnected={contextValue.data.isConnected}
          layout={contextValue.content.layout}
        />
      </ZeroBalanceSupportProvider>
    </Provider>
  );
};

ZeroBalanceSupport.propTypes = {
  data: serviceDataShape,
  content: contentShape,
  extData: PropTypes.shape({
    content: PropTypes.shape({
      requestStatus: PropTypes.shape({
        unavailableService: unavailableServiceShape,
        confirmConnect: connectShape,
        disconnectStep: connectShape,
        disconnectStepArchive: connectShape,
        errorConnectStep: connectShape,
        success: successStepShape,
        disconnectServiceSuccess,
      }),
    }),
  }),
};

export default ZeroBalanceSupport;
