import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Utils
import { Button } from '@beef/legacy-ui-kit';

import { STEPS_CONFIG, getCurrentStep } from 'utils/ConvertToEsimServiceHelper';

// Components
import { BenefitsSlider } from 'pages-components/Benefits';

import RequestSteps from './component/RequestSteps';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SimReplacement = ({ data }) => {
  const content = data?.extData.content;
  const errorData = content?.defaultError || {};
  const successData = content?.successData || {};

  const [step, setStep] = useState(getCurrentStep());
  const [popupOpen, setPopupOpen] = useState(false);

  const onButtonClick = () => {
    setStep(STEPS_CONFIG.initial || setStep(STEPS_CONFIG.error));
    setPopupOpen(true);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPopupOpen(setStep(STEPS_CONFIG.error) || setStep(STEPS_CONFIG.initial));
    }
  }, []);

  return (
    <div className={cx('component')}>
      {content.benefits && (
        <div className={cx('benefits')}>
          <BenefitsSlider benefits={content.benefits} className={cx('benefits-slider')} />
        </div>
      )}
      {content && (
        <div className={cx('content')}>
          {content?.preview?.text && (
            <div className={cx('content-row')}>
              <div className={cx('text')}>{content.preview.text}</div>
            </div>
          )}
          {content?.preview?.buttonText && (
            <div className={cx('content-row')}>
              <Button className="big" onClick={onButtonClick} wide>
                {content.preview.buttonText}
              </Button>
            </div>
          )}
          {content?.preview?.note && <div className={cx('note')}>{content.preview.note}</div>}
        </div>
      )}
      <RequestSteps
        data={data}
        errorData={errorData}
        extData={content}
        onClose={() => setPopupOpen(false)}
        opened={popupOpen}
        setStep={setStep}
        step={step}
        successData={successData}
      />
    </div>
  );
};

SimReplacement.propTypes = {
  content: PropTypes.shape({
    benefits: PropTypes.array,
    preview: PropTypes.string,
  }),
};

export default SimReplacement;
