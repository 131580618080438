import { combineReducers } from '@reduxjs/toolkit';
import { composeStoreInitializer } from '@beef/redux';

import { cart, category, change, content, filter, numbers } from './reducers/index';

const rootReducer = combineReducers({
  content,
  category,
  filter,
  numbers,
  cart,
  change,
});

export const initializeStore = composeStoreInitializer(rootReducer);
