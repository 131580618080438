import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from '../../styles.pcss';

const cx = classNames.bind(styles);

export type TTariffFeature = {
  description?: string;
  icon?: string;
  style?: { backgroundColor?: string };
  title?: string;
};

/** Компонент описания апперов для попапа смены персонажа */
export const TariffFeature: FC<TTariffFeature> = ({ icon, style, title, description }) => (
  <li className={cx('wrapper__item')} {...{ style }}>
    {!title && icon && <img alt="" className={cx('wrapper__icon')} loading="lazy" src={icon} />}
    {title && <Text size="size5-m">{title}</Text>}
    {description && (
      <Text color="grey60" size="size5-r-s">
        {description}
      </Text>
    )}
  </li>
);
