import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

// Components
import { Notification, Text } from '@beef/ui-kit';

// Selectors
import { getIsArchiveTariff } from '../../../../store/selectors/totalSettings';
import { getIsConvergentUser } from '../../../../store/selectors/orderTariffUserData';
import { getTariffDetailsSubTitle, getTariffDetailsTitle } from '../../../../store/selectors/modal';
import {
  getArchiveNotificationButtonText,
  getArchiveNotificationText,
} from '../../../../store/selectors/userData';

// Actions
import { archiveNotificationHandler } from '../../../../store/actions/common';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _Header = ({ text, title, onClick, subTitle, isArchive, buttonText, isConvergentUser }) => (
  <div className={cx('wrapper')}>
    {title && <Text size="size2-m">{title}</Text>}
    {subTitle && isConvergentUser && <Text size="size4-r">{subTitle}</Text>}
    {isArchive && <Notification {...{ text, onClick, buttonText, variant: 'secondary' }} />}
  </div>
);

const mapStateToProps = (state) => ({
  title: getTariffDetailsTitle(state),
  isArchive: getIsArchiveTariff(state),
  text: getArchiveNotificationText(state),
  subTitle: getTariffDetailsSubTitle(state),
  isConvergentUser: getIsConvergentUser(state),
  buttonText: getArchiveNotificationButtonText(state),
});

const mapDispatchToProps = {
  onClick: archiveNotificationHandler,
};

_Header.propTypes = {
  title: PropTypes.string,
  isArchive: PropTypes.bool,
  subTitle: PropTypes.string,
  isConvergentUser: PropTypes.bool,
};

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(_Header);
