import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { Button } from '@beef/ui-kit';

import store from 'store';
import { toggleTariffConnectionPopup } from 'pages/ProductCard/actions/tariffConnect/toggleTariffConnectionPopup';
import { initConnectionPopup } from 'pages/ProductCard/actions/tariffConnect/tariffConnectExtension';
import { mobTariffDetailConnectYM } from 'utils/analytics/tariffsYandexMetrica';
import { ETariffsVkGoals, vkPushSimTariffEvent } from 'utils/analytics/simTariffVkEvents';

const TariffConnectButtonContent = ({
  handleToggleTariffConnectionPopup,
  onInit,
  tariffData,
  tariffConnectionPopup,
  buttonText,
  buttonProps,
  activeTabName,
}) => {
  useEffect(() => {
    if (!tariffConnectionPopup) {
      onInit({
        connectPopupUrl: tariffData.connectPopupUrl,
        alias: tariffData.alias,
        soc: tariffData.soc,
      });
    }
  }, [onInit, tariffData, tariffConnectionPopup]);

  const handleConnectPopupClick = () => {
    /** Клик на Подключить, Хочу этот тариф Yandex Metrika */
    mobTariffDetailConnectYM(document.location.href);
    vkPushSimTariffEvent(tariffData.alias, ETariffsVkGoals.SimTariffOpenForm);

    handleToggleTariffConnectionPopup(activeTabName);
  };

  return (
    <Button fullWidth onClick={handleConnectPopupClick} {...buttonProps}>
      {buttonText}
    </Button>
  );
};

const mapDispatchToProps = {
  handleToggleTariffConnectionPopup: (activeTabName) =>
    toggleTariffConnectionPopup(true, null, null, true, activeTabName),
  onInit: ({ connectPopupUrl, alias, soc }) => initConnectionPopup(connectPopupUrl, alias, soc),
};

const mapStateToProps = ({ external }) => {
  const tariffData = external?.tariff?.data ?? {};
  const tariffConnectionPopup = external?.tariffConnectionPopup;
  return {
    tariffData,
    tariffConnectionPopup,
  };
};

TariffConnectButtonContent.propTypes = {
  buttonText: PropTypes.string,
  activeTabName: PropTypes.string,

  handleToggleTariffConnectionPopup: PropTypes.func,
  onInit: PropTypes.func,

  tariffData: PropTypes.object,
  buttonProps: PropTypes.object,
  togglePopupParams: PropTypes.object,
  tariffConnectionPopup: PropTypes.object,
};

TariffConnectButtonContent.defaultProps = {
  buttonText: 'Хочу этот тариф',
  togglePopupParams: {
    popupIsActive: true,
    offerCode: null,
    tabsToDisplay: null,
    isFloatingButtonClicked: true,
  },
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(TariffConnectButtonContent);

const TariffConnectButton = (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);

export default TariffConnectButton;
