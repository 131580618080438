import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link, Preloader, Status } from '@beef/legacy-ui-kit';

import { transformRequest } from 'utils/globalAjaxHandler';
import { unformatPhone } from 'utils/format-string';
import SmsForm from 'widgets/Authorization/SmsLoginForm/SmsForm';

const defaultState = {
  data: {},
  loading: true,
  error: false,
  lockForm: false,
};

class SmsLoginForm extends Component {
  state = defaultState;

  componentDidMount() {
    this.props.onMount();
    this.sendCode();
  }

  sendCode = async () => {
    const { myNumber } = this.props;
    const ctn = unformatPhone(myNumber);

    this.setState(defaultState);

    try {
      const { data } = await axios.get(
        `/menu/loginmodel/?CTN=${ctn}&returnUrl=${window.location.href}`,
      );
      const { loginType, requiredSendSms, bisUrl } = data;

      if ((loginType === 'mobile' || loginType === 'mobileConvergence') && requiredSendSms) {
        const oneTimePassResp = await axios.get(
          `${bisUrl}/identity/generateonetimepassword?login=${ctn}`,
          { transformRequest },
        );

        if (oneTimePassResp.data && oneTimePassResp.data.Status === 'Error') {
          this.setState({ error: true, loading: false });
          return;
        }
        this.setState({ data, loading: false });
        return;
      }

      this.setState({
        lockForm: true,
        loading: false,
      });
    } catch (err) {
      if (err.response && err.response.data && err.response.data.Status === 'Error') {
        this.setState({ error: true });
      }

      this.setState({
        lockForm: true,
        loading: false,
      });
    }
  };

  render() {
    const { myNumber, confirmButtonText, sendSmsAgainText } = this.props;
    const { data, loading, error, lockForm } = this.state;

    if (loading) {
      return (
        <div style={{ padding: '50px 0;' }}>
          <Preloader />
        </div>
      );
    }
    if (error) {
      return (
        <Status className={['error', 'compact']}>
          Произошла ошибка. <Link onClick={this.sendCode}>Попробовать снова</Link>
        </Status>
      );
    }

    return (
      <SmsForm
        clientId={data.clientId}
        confirmButtonText={confirmButtonText}
        disabled={lockForm}
        hidePhone
        nonce={data.nonce}
        phone={myNumber}
        postUrl={data.postUrl || '/'}
        redirectUrl={data.returnUrl}
        returnUrl={data.returnUrl}
        sendSmsAgain={this.sendCode}
        sendSmsAgainText={sendSmsAgainText}
        state={data.state}
      />
    );
  }
}

SmsLoginForm.defaultProps = { onMount: () => {} };

SmsLoginForm.propTypes = {
  onMount: PropTypes.func,
  myNumber: PropTypes.string,
  confirmButtonText: PropTypes.string,
  sendSmsAgainText: PropTypes.string,
};

export default SmsLoginForm;
