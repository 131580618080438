import { createReducer } from '@reduxjs/toolkit';

// Actions
import { setAuthenticated, setGlobalContent, setUserRegion } from '../actions/common';

export const common = createReducer(
  {
    content: {},
    region: {},
    isAuthenticated: false,
  },
  {
    [setGlobalContent.type]: (state, { payload }) => {
      state.content = payload;
    },
    [setAuthenticated.type]: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
    [setUserRegion.type]: (state, { payload }) => {
      state.region = payload;
    },
  },
);
