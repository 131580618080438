import { onReloadWithCrash } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/utils/onReloadWithCrash';

import { useAppSelector } from '../store';
import { useStepNavigation } from './useStepNavigation';
import { chooseFinalPageVariant } from '../utils/chooseFinalPageVariant';
import { useCheckAddressResults } from '../components/constructorPage/FmcContainer/selectors/useCheckAddressResults';

/** Хук подготовки данных для страницы ThanksFTTBPage */
export const usePrepareFTTBPageProps = () => {
  /* Тип текущего пользователя */
  const { userType } = useStepNavigation();

  /* Флаги наличия запроса на подключение ШПД (isHasRequest) и уже подключенного ШПД (isAlreadyConnected)  */
  const { isHasRequest, isAlreadyConnected } = useCheckAddressResults();

  /* Если у пользователя есть запрос на подключение ШПД по проверяемому адресу (isHasRequest) или
   * ШПД уже подключен (isAlreadyConnected), возвращаем true.
   * На основе флага isCallbackRequest показываем финальную страницу с информацией по callback */
  const isCallbackRequest = (isHasRequest || isAlreadyConnected) ?? false;

  /** Извлекаем контентные данные для финальной страницы.
   * Объект содержит все возможные варианты заголовков, описаний и иконок финальной страницы (отдельные объекты) */
  const commonContent = useAppSelector((state) => state.common.content.finalPage);

  /** Получаем результирующий объект с данными для отрисовки на финальной странице,
   * на основании текущего типа пользователя (userType) */
  const pageContent = commonContent?.[chooseFinalPageVariant(userType, isCallbackRequest)] ?? {};

  /* Выбираем контентные данные из pageContent, полученные на основе типа пользователя (userType) */
  const { icon, title, btnText, description } = pageContent;

  /* Функция-обработчик для кнопки страницы "благодарности".
   * Очищает текущий кэш и перезагружает страницу, отправляя пользователя на главный экран */
  const handleClick = () => onReloadWithCrash();

  /* Формируем объект пропов для компонента DescriptionContainer */
  const descriptionProps = { title, btnText, description, onClick: handleClick };

  return { icon, descriptionProps };
};
