import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ChevronLeftIcon, ChevronRightIcon } from '@beef/ui-kit';

import { ENTITY_TYPE } from 'pages/SearchResultsPage/utils/constants';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const LinkBlock = ({ type, children, href, onClick, className }) => {
  const Tag = href ? 'a' : 'div';

  return (
    <Tag
      className={cx(className, { link: href || onClick })}
      data-component="Link"
      {...{ href, onClick }}
    >
      {type === ENTITY_TYPE.prev && <ChevronLeftIcon size={24} />}
      {type === ENTITY_TYPE.item && children}
      {type === ENTITY_TYPE.next && <ChevronRightIcon size={24} />}
    </Tag>
  );
};

LinkBlock.defaultProps = {
  children: null,
};

LinkBlock.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
