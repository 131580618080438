import { EStatus } from 'pages/MainPage2021/pageBlocks/PersonalInfoBlock/types';

import { EModal } from '../ModalContainer/modals/types';

const IS_DISABLED = 'is-zero-balance-support-disabled';

// TODO: Remove when server support is available
export const persistDisabled = (isDisabled: boolean) => {
  try {
    if (typeof Storage !== undefined && sessionStorage) {
      sessionStorage.setItem(IS_DISABLED, JSON.stringify(isDisabled));
    }
  } catch (e) {
    console.error(e);
  }
};

// TODO: Remove when server support is available
export const retrieveIsDisabled = (): boolean => {
  try {
    if (typeof Storage !== undefined && sessionStorage) {
      const item = sessionStorage.getItem(IS_DISABLED) || 'false';
      return JSON.parse(item);
    }

    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getModal = ({
  isConnected,
  isAvailableToConnect,
  isAvailableForNextConnection,
  status,
}: {
  isAvailableForNextConnection: boolean;
  isAvailableToConnect: boolean;
  isConnected: boolean;
  status?: EStatus;
}): EModal => {
  const isNotActive = status !== EStatus.Active;
  const hasInsufficientFunds = status === EStatus.NotEnoughMoney;
  const isUnavailable = (isNotActive && !hasInsufficientFunds) || !isAvailableToConnect;

  return [
    isConnected && !isAvailableForNextConnection && EModal.DisconnectStep,
    isConnected && hasInsufficientFunds && EModal.ConfirmDisconnectStep,
    isConnected && EModal.DisconnectStep,
    isUnavailable && EModal.UnavailableServiceStep,
    !isConnected && hasInsufficientFunds && EModal.BlockedServiceStep,
    !isConnected && isAvailableToConnect && EModal.ConnectConfirmStep,
    EModal.UnavailableServiceStep,
  ].find(Boolean) as EModal;
};
