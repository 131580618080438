export enum TARIFF_GROUP {
  homeInternet = 'homeInternet',
  homeInternetAndTv = 'homeInternetAndTv',
  homeInternetTvAndMobile = 'homeInternetTvAndMobile',
  presetUp = 'presetUp',
}

export enum TARIFF_TYPES {
  internet = 0,
  kit = 2,
  partnerFmc = 5,
  phone = 3,
  preset = 4,
  presetUp = 100, // тарифы ап являются частью preset, но для удобства вынесены в отдельный тип
  tv = 1,
}

export const PRESET_FORM_BASE_URL = '/customers/products/mobile/convorder';

export enum DEVICE_TAB_TYPE {
  deviceDocumentation = 3,
  deviceInformation = 1,
  deviceParameters = 2,
}

export enum DEVICE_CARD_SIZE {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export enum DEVICE_TYPE {
  router = 'router',
  tvConsole = 'tvConsole',
}

export enum PAGE_TYPE_EVENT_NAMES {
  fmc = 'fmc_page_ev_',
  fttb_kit = 'fttb-kit_page_ev_',
  fttb_net = 'fttb-net_page_ev_',
}

export enum PAGE_TYPES {
  fmc = 'fmc',
}
