import { deviceSelectionTypes } from 'pages/FTTB/constants';

export const routersStates = {
  added: 'added',
  excluded: 'excluded',
  included: 'included',
  available: 'available',
};

export const getRoutersState = ({ isIncluded, selectedRouters, excludedRouters }) => {
  if (selectedRouters.length) return routersStates.added;
  if (excludedRouters.length) return routersStates.excluded;
  if (isIncluded) return routersStates.included;

  return routersStates.available;
};

export const routerSelectionStates = {
  available: 'available',
  added: 'added',
  included: 'included',
  excluded: 'excluded',
  replace: 'replace',
  replaceToPreEnabled: 'replaceToPreEnabled',
};

export const getPreEnabledRouter = (allRouters) =>
  allRouters.find((router) => router.selection === deviceSelectionTypes.preEnabled);

export const getCurrentRouterSelectionState = (
  alias,
  routerSelection,
  allRouters = [],
  selectedRouters = [],
  excludedRouters = [],
) => {
  const isSelected = !!selectedRouters.find((router) => router.alias === alias);
  const isExcluded = !!excludedRouters.find((router) => router.alias === alias);
  const hasSelectedRouter = selectedRouters.length > 0;
  const hasPreSelecterRouter = excludedRouters.length === 0 && !!getPreEnabledRouter(allRouters);

  if (routerSelection === deviceSelectionTypes.preEnabled) {
    if (hasSelectedRouter && isExcluded) return routerSelectionStates.replaceToPreEnabled;
    if (isExcluded) return routerSelectionStates.excluded;

    return routerSelectionStates.included;
  }

  if (routerSelection === deviceSelectionTypes.available) {
    if (isSelected) return routerSelectionStates.added;
    if (hasSelectedRouter || hasPreSelecterRouter) return routerSelectionStates.replace;

    return routerSelectionStates.available;
  }

  return null;
};
