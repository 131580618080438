import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ScrollContainer } from '@beef/legacy-ui-kit';
import { Text } from '@beef/ui-kit';

import ConnectionStep from './ConnectionStep';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ConnectionSteps = ({ title, steps, availableInShop }) => (
  <div className={cx('wrap')}>
    {title && (
      <div className={cx('heading')}>
        <Text size="size1-m" tagType="h2">
          {title}
        </Text>
      </div>
    )}
    {steps?.length > 0 && (
      <ScrollContainer handleShouldPlayDemo={() => false}>
        <ul className={cx('steps-list')}>
          {steps.map((step, i) => (
            <li className={cx('steps-list__item')} key={i}>
              <ConnectionStep
                appButtons={step.appButtons}
                simOrder={availableInShop && step.simOrder}
                stepNumber={i + 1}
                text={step.text}
              />
            </li>
          ))}
        </ul>
      </ScrollContainer>
    )}
  </div>
);

ConnectionSteps.propTypes = {
  /* доступно для подключения */
  availableInShop: PropTypes.bool,
  title: PropTypes.string,
  /* шаги подключения */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      /* шаг подключения тарифа  */
      simOrder: PropTypes.bool,
      /* кнопки приложения */
      appButtons: PropTypes.object,
    }),
  ),
};

export default ConnectionSteps;
