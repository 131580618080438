import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { CollapseContainer } from '@beef/smart-kit';

import { LoadingWrap } from 'pages-components/LoadingWrap';
import { PreloadLayout } from 'pages/FancyNumber2022/components/NumbersList/PreloadLayout';

import { EmptyList, Error } from './Status/index';
import {
  getChangeNumberList,
  getError,
  getIsLoading,
  getNumbersList,
  getShowEmptyList,
} from '../../store/selectors/numbers';
import { getPreviewCounters } from '../../store/selectors/content';
import { getIsChangeNumber } from '../../store/selectors/change';
import { getCartLoading } from '../../store/selectors/cart';
import { pushShowMore } from '../../analytics/filter';
import { NumberShape } from '../../shapes';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const NumbersListLayout = ({
  numbers,
  isLoading,
  showEmptyList,
  showError,
  previewCounters,
  onExpandClick,
  children,
}) => (
  <LoadingWrap
    className={cx('numbers')}
    isLoading={isLoading}
    preloadLayout={!numbers.length && <PreloadLayout />}
  >
    <CollapseContainer
      className={cx('numbers-list')}
      onExpandClick={onExpandClick}
      {...previewCounters}
    >
      {numbers.map(children)}
    </CollapseContainer>
    {showEmptyList && <EmptyList />}
    {showError && <Error />}
  </LoadingWrap>
);

NumbersListLayout.propTypes = {
  numbers: PropTypes.arrayOf(PropTypes.shape(NumberShape)),
  isLoading: PropTypes.bool,
  showEmptyList: PropTypes.bool,
  showError: PropTypes.bool,
  onExpandClick: PropTypes.func,
  children: PropTypes.func,
  previewCounters: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({
  numbers: getIsChangeNumber(state) ? getChangeNumberList(state) : getNumbersList(state),
  isLoading: getIsLoading(state) || getCartLoading(state),
  showEmptyList: getShowEmptyList(state),
  showError: getError(state),
  previewCounters: getPreviewCounters(state),
  onExpandClick: pushShowMore,
});

export const NumbersList = connect(mapStateToProps)(NumbersListLayout);
