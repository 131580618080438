const NUMBERS_CATEGORIES = {
  zolotie: 'zolotie',
  serebryanie: 'serebryanie',
  bronzovie: 'bronzovie',
  spetsialnyie: 'spetsialnyie',
};

const NUMBERS_CATEGORIES_DICTIONARY = {
  [NUMBERS_CATEGORIES.spetsialnyie]: {
    label: 'Простые',
    order: 1,
    analyticsLabel: 'Бесплатный номер',
  },
  [NUMBERS_CATEGORIES.bronzovie]: {
    label: 'Бронзовые',
    order: 2,
    analyticsLabel: 'Бронзовый номер',
  },
  [NUMBERS_CATEGORIES.serebryanie]: {
    label: 'Серебряные',
    order: 3,
    analyticsLabel: 'Серебряный номер',
  },
  [NUMBERS_CATEGORIES.zolotie]: {
    label: 'Золотые',
    order: 4,
    analyticsLabel: 'Золотой номер',
  },
};

/**
 * @desc: формирует список категорий номеров в нужном для компонента формате
 * @param {array} categories
 * @returns { array }
 */
const setCategories = (categories) => {
  if (!categories?.length) {
    return [];
  }
  return categories
    .map((category) => {
      const categoryDictionaryData = NUMBERS_CATEGORIES_DICTIONARY[category.alias];
      return {
        price: category.price,
        title: categoryDictionaryData?.label,
        order: categoryDictionaryData?.order,
        alias: category.alias,
      };
    })
    .sort((a, b) => a.order - b.order);
};

const formatNumbersListData = (numbersList) => {
  if (!numbersList?.length) {
    return [];
  }

  return numbersList.map((category) => ({
    alias: category.alias,
    list: category.numbers.map((listItem) => ({
      value: listItem.value,
      price: listItem.price ?? category.price,
      formattedValue: listItem.formattedValue,
      featureCode: listItem.featureCode,
    })),
  }));
};

const filterNumberByCategory = ({ numbersListData, boughtNumbers, currentCategory }) =>
  numbersListData.reduce((acc, curr) => {
    if (curr.alias === currentCategory) {
      let numbersCategoryData = curr.list;
      if (boughtNumbers.length) {
        numbersCategoryData = numbersCategoryData.filter(
          ({ value }) => !boughtNumbers.includes(value),
        );
      }
      return [...acc, ...numbersCategoryData];
    }
    return acc;
  }, []);

const SEARCH_INPUT_MIN_LENGTH = 2;

export {
  NUMBERS_CATEGORIES,
  NUMBERS_CATEGORIES_DICTIONARY,
  SEARCH_INPUT_MIN_LENGTH,
  filterNumberByCategory,
  formatNumbersListData,
  setCategories,
};
