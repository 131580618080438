import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, CollapsedContent, Heading, SmartForm, SmartInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';
import { maskedPhone } from 'utils/validators';

import commonStyles from '../SharingService.pcss';
import TextContainer from '../TextContainer/TextContainer';

const cx = classNames.bind(commonStyles);

class SwitchNumberPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number: '+7 ',
      cleared: false,
    };
  }

  handleNumberChange = (number) => {
    const newState = { number };
    if (this.state.cleared) newState.cleared = false;
    this.setState(newState);
  };

  handleFormSubmit = (data) => {
    if (this.state.cleared) return;
    this.props
      .onSwitchNumberSubmit(this.props.number.number, data.phone)
      .then(() => this.setState({ number: '+7 ', cleared: true }))
      .catch(() => this.setState({ number: '+7 ', cleared: true }));
  };

  render() {
    const { maxNumbers, unit, price, number } = this.props;

    return (
      <PopupStateless onClose={this.props.onClose} opened={this.props.opened}>
        <div className={cx('content')}>
          <Heading level={1}>
            <TextContainer className={cx('title')} path={['texts', 'switchNumberTitle']} />
          </Heading>
          <div>
            <TextContainer
              className={cx('description')}
              path={['texts', 'switchNumberDescription']}
              variables={{ number: number.number }}
            />
          </div>
          <TextContainer className={cx('description')} path={['texts', 'switchNumberInfo']} />
          {this.props.price > 0 ?
            <TextContainer
              className={cx('description')}
              path={['texts', 'switchNumberInfoPrice']}
              variables={{
                priceUnit: unit,
                maxNumbers,
                price,
              }}
            />
          : null}
          <SmartForm
            className={cx('form')}
            onSubmit={this.handleFormSubmit}
            validator={this.state.cleared ? {} : { phone: maskedPhone }}
          >
            <div className={cx('input')}>
              <TextContainer
                className={cx('inputTitle')}
                path={['texts', 'switchNumberInputTitle']}
              />
              <SmartInput
                className={cx('phoneInput')}
                inputId="phone"
                mask="+7 ___ ___ __ __"
                onChange={this.handleNumberChange}
                textInputClass="bigSize"
                value={this.state.number}
              />
            </div>
            <div className={cx('submit')}>
              <Button className={['big']} type="submit" wide>
                <TextContainer path={['texts', 'switchNumberInvitationButtonTitle']} />
              </Button>
            </div>
          </SmartForm>
          {this.props.noLinks ? null : (
            <div className={cx('links')}>
              <CollapsedContent
                contentClass={cx(['description', 'collapsed-content'])}
                title={this.props.switchNumberInfoLink}
              >
                <TextContainer path={['texts', 'howFindNumber']} />
              </CollapsedContent>
              <CollapsedContent
                contentClass={cx(['description', 'collapsed-content'])}
                title={this.props.switchNumberSimOrder}
              >
                <TextContainer path={['texts', 'howToBuy']} />
              </CollapsedContent>
            </div>
          )}
        </div>
      </PopupStateless>
    );
  }
}

SwitchNumberPopup.propTypes = {
  opened: PropTypes.bool,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSwitchNumberSubmit: PropTypes.func,
  onClose: PropTypes.func,
  number: PropTypes.object,
  noLinks: PropTypes.bool,
  unit: PropTypes.string,
  maxNumbers: PropTypes.number,
  switchNumberInfoLink: PropTypes.string,
  switchNumberSimOrder: PropTypes.string,
};

export default SwitchNumberPopup;
