import React, { memo } from 'react';

import { Metric } from '../../../../metric';
import { ISlides } from '../../../../types';

export const Analytics: React.FC<Omit<ISlides, 'fixedButton' | 'isActive'>> = memo(
  ({ slides, activeTitle }) => {
    return slides?.map(
      (slide) =>
        slide?.title === activeTitle && (
          <Metric
            data={slide?.title}
            events={{
              mobileApp2024Metric: ['handleViewBlock'],
            }}
            mode="render"
          />
        ),
    );
  },
);
