import { TSectionsDO } from 'constructorV1/store/tariffSettingsSlice/types';

interface IPrepareActiveOptionsSocs {
  sections: TSectionsDO[];
}

/** Подготавливает активные опции */
export const prepareActiveOptionsSocs = ({ sections }: IPrepareActiveOptionsSocs) =>
  sections
    ?.map((section) => {
      /* Проверяем каждый вложенный массив options на наличие активных опций */
      return section?.options?.reduce((acc: string[], option) => {
        /* Если опция активна, то добавляем soc (serviceCategory) опции в результирующий массив */
        if (option?.isTurnedOn) {
          acc = [...acc, option?.serviceCategory || ''];
        }
        /* Возвращаем acc */
        return acc;
      }, []);
    })
    .flat();
