import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useDebounce } from 'utils/hooks/useDebounce';
import {
  useAppDispatch,
  useAppSelector,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';
import { setFlat } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/fttbAddressSlice';

export const useCheckFlatInput = () => {
  const dispatch = useAppDispatch();

  /* Значение input региона из стора fttbAddress */
  const defaultValue =
    useAppSelector((state) => state.fttbAddress.checkConnectionRes.flat)?.toString() || '';
  const [value, setValue] = useState(() => defaultValue);
  const deferredValue = useDebounce(value, 500);

  const disabled = useAppSelector((state) => state.fttbAddress.isDisabledFlat);
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  /* Отправляем в стор значения только через debounce, чтобы избежать лишних запросов checkConnection */
  useEffect(() => {
    dispatch(setFlat({ flat: deferredValue }));
  }, [deferredValue]);

  /* Сбрасываем локальное значение, если было изменено зависимое значение региона, улицы или дома */
  useEffect(() => {
    if (disabled) {
      setValue('');
    }
  }, [disabled]);

  return { value, onChange, disabled };
};
