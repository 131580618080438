import { Button } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ModalButtons: React.FC<{
  isLoading: boolean;
  mainBtnText: string;
  onCancel: () => void;
  onConfirm: () => void;
  secondBtnText: string;
}> = ({ onCancel, onConfirm, secondBtnText, mainBtnText, isLoading }) => (
  <div className={cx('container')}>
    {mainBtnText && (
      <Button isLoading={isLoading} onClick={onConfirm}>
        {mainBtnText}
      </Button>
    )}

    {secondBtnText && (
      <Button onClick={onCancel} variant="tertiary">
        {secondBtnText}
      </Button>
    )}
  </div>
);
