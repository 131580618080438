import { createSlice } from '@reduxjs/toolkit';

import { TModal } from '../types';

const modalInitialState = {
  isModalOpen: false,
  isModalConfirmed: false,
  isLoading: false,
  modalType: '',
  modalCancelText: '',
  url: '',
};

// Slices
// FIXME: Рефакторить для соответствия стандартному подходу
export const modalSlice = createSlice({
  name: 'modal',
  initialState: modalInitialState,
  reducers: {
    setUrl: (state, action: { payload: string }) => {
      state.url = action.payload;
    },
    openModal: (state, action: { payload: TModal }) => {
      state.isModalOpen = true;
      state.modalType = action.payload || 'defaultError';
      state.isModalConfirmed = false;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
      state.modalCancelText = '';
    },
    confirmModal: (state) => {
      state.isModalConfirmed = true;
      state.isModalOpen = false;
    },
    setModalCancelText: (state, action: { payload: string }) => {
      state.modalCancelText = action.payload;
    },
    setIsLoading: (state, action: { payload: boolean }) => {
      state.isLoading = !!action.payload;
    },
  },
});
