import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { selectContent } from '../../../store/selectors';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const RatingLayoutComponent = ({ headingElement, chartElement, chartContent }) => (
  <div className={cx('wrap')}>
    <div className={cx('layout')}>
      <div className={cx('heading')}>{headingElement}</div>
      <div className={cx('chart')}>
        <div className={cx('chart-title')}>
          <Text size="size2-m">{chartContent.title}</Text>
        </div>
        {chartElement}
        <div className={cx('chart-tip')}>
          <Text color="grey50" size="size7-r">
            {chartContent.tip}
          </Text>
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  chartContent: selectContent(state).rating.chart,
});

export const RatingLayout = connect(mapStateToProps)(RatingLayoutComponent);
