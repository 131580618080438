import { createBeefCoPage, createLibrary, withBeefCoProject } from '@beefco/sdk';
import { compose, withReduxStore } from '@beef/redux';
import {
  AppBannerView,
  InfoBannerView,
  LandingRow,
  TeaserBannerView,
} from '@beef/landing-constructor/library';

import { initializeStore } from 'pages/ContactPage/store';
import { extractBeefCoDSL } from 'pages/ContactPage/utils';

import { CustomCard, CustomCardsWrapper } from './library';

const library = createLibrary(
  {
    LandingRow,
    TeaserBannerView,
    InfoBannerView,
    AppBannerView,
    CustomCardsWrapper,
    CustomCard,
  },
  module,
);

const _BeefCoContactPage = createBeefCoPage({
  library,
  pageSlug: 'BeefCoContactPage',
});

export const BeefCoContactPage = compose(
  withBeefCoProject(undefined, extractBeefCoDSL),
  withReduxStore(initializeStore),
)(_BeefCoContactPage);
