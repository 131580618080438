import { Button } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import React, { useMemo } from 'react';

import { PartnerServiceCardMergeState } from 'pages/PartnerServiceCard2022/store';

import { Price } from '../Price';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TExtendedStepsProps = PartnerServiceCardMergeState['extendedBlockData'];

export const useContent = (
  button: TExtendedStepsProps['button'],
  content: TExtendedStepsProps['content'],
) => {
  const lastItemIndex = content.length - 1;
  return useMemo(
    () =>
      content.map((contentObj, i) => {
        if (i < lastItemIndex || button?.isConnected) {
          return contentObj;
        }

        // NOTE: Add subscription button to the last item
        return {
          ...contentObj,
          actionPanel: (
            <div className={cx('btn-container')}>
              <Button
                fullWidth={false}
                onClick={button.onClick}
                rightSideNode={<Price buttonSettings={button.settings} />}
                size="l"
                type="button"
                variant="main"
              >
                {button.text || ''}
              </Button>
            </div>
          ),
        };
      }),
    [button, content, lastItemIndex],
  );
};
