import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { usePrepareAnimalTabSettings } from '../../hooks/usePrepareAnimalTabSettings';
import { DEFAULT_INDEX } from '../../../../../constants';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TAnimalTab = {
  activeCharacter?: string;
  activeColor: string;
  highOptionSoc: string;
  imageSrc: string;
  name: string;
  onClick: (soc: string) => void;
  optionSoc: string;
};

/** Таб персонажа */
export const AnimalTab: FC<TAnimalTab> = ({ name, imageSrc, ...rest }) => {
  const { isActive, handleClick, backgroundColor } = usePrepareAnimalTabSettings({ ...rest });
  return (
    <div className={cx('wrapper')}>
      <button
        className={cx('wrapper__character', { 'wrapper__character--active': isActive })}
        onClick={handleClick}
        style={{ backgroundColor }}
        tabIndex={DEFAULT_INDEX}
        type="button"
      >
        {imageSrc && <img alt="" className={cx('wrapper__image')} loading="lazy" src={imageSrc} />}
      </button>
      {name && <Text size="size6-m">{name}</Text>}
    </div>
  );
};
