import React, { memo, useMemo } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
// Components
import { Button, Text } from '@beef/ui-kit';

import { useMainOptionsData } from 'pages/ProductCard/Tariff/components/Check/hooks/useMainOptionsData';
import { templateParser } from 'utils/format-string';
import { SubscriptionFeeBlock } from 'pages/ProductCard/Tariff/components/Check/SubscriptionFeeBlock';
import { MainPackageBlock } from 'pages/ProductCard/Tariff/components/Check/MainPackageBlock';
import { Header } from 'pages/ProductCard/Tariff/components/Check/Header';
import { useConnectConvergence } from 'pages/ProductCard/Tariff/components/Check/hooks/useConnectConvergence';
import { CommonPopup } from 'pages/ProductCard/Tariff/components/CommonPopup';
import { checkSocsInTariffOptions } from 'pages/ProductCard/Tariff/containers/ConstructorTariff/utils';
import { ETariffsVkGoals, vkPushSimTariffEvent } from 'utils/analytics/simTariffVkEvents';
import { CONSTRUCTOR_TABS } from 'pages/ProductCard/constants';

import { BannersContainer } from './BannersContainer';
import Options from './Options';
import Presets from './Presets';
import Summary from './Summary';
import AdditionalCost from './AdditionalCost';
import { Subscriptions } from './PartnerSubscriptions';
import { FamilyInfo } from './FamilyInfo';
// Constants
import {
  FAMILY_PRICING_PERIOD,
  REPLACED_RATES,
  accumulatorUnitPropTypesShape,
  bannerPropTypesShape,
  totalUnitPropTypesShape,
} from './constants';
// Utils
import {
  convertSum,
  getCurrentStringParts,
  getStringFromParts,
  isSingleSubscriptionHasTrial,
  totalPartnersPrice,
} from '../../../utils';
import { getRoundedPrice } from './utils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);
const THIRTY_DAYS_IN_MONTH = 30;

const Check = memo(
  ({
    alias,
    info,
    total,
    content,
    totalSum,
    yourTariff,
    isChangeTariff,
    requestStatus,
    isAuthenticated,
    handleOrderModal,
    isCheckedConvergence,
    sendYaLiveAnalytics,
    hasTariffConstructor,
    gbAndMinutesLongRate,
    discount,
    changeTariffAmountCondition,
    changePresetAmountCondition,
    initialBalancePaymentCondition,
    gbAndMinutesShortRate,
    shortRateDiscount,
    chargeTariffAmount,
    isDataTariffLayout,
    isAnimalsTariffLayout,
    activeCharacter,
    isConnectedFamily,
    familyContent,
    currentFamilyPrice,
    isDisableFamilyButton,
    checkData,
    hasNotification,
    handleRefundDiscount,
    sendAnalytics,
    activeTab,
    isYourPreset,
    temporaryDiscount,
    homeInternetOptions,
    needVerificationInternetOptions,
    disabledOptions,
    getOptionsPrice,
    getOptionsNames,
    showFee,
    showOptions,
    showConvergenceOptions,
    currentPricePeriod,
    isNeedConnectedConvergence,
    requestConvergenceParams,
    isNeedDefaultCallBack,
    isShowAvailableSettings,
    userNumber,
    isConvergence,
    isAppendConvergenceToUp,
    hasRequestConvergenceError,
    familyNumberedPriceToggleEnabled,
    isOptionsDefault,
    fttbPresetConnectedDiscount,
  }) => {
    const { joinedOptions } = useMainOptionsData({
      info,
      total,
    });

    const isTariffCompleted = useMemo(() => requestStatus === 'COMPLETE', [requestStatus]);
    const hideTooltipInShortCycle =
      isAnimalsTariffLayout && (isConvergence || isNeedConnectedConvergence);

    const periodSumVariants = {
      sum: 'sum',
      sumDay: 'sumDay',
      discountSum: 'discountSum',
      discountSumDay: 'discountSumDay',
    };

    const periodSum = isAnimalsTariffLayout ? periodSumVariants.sum : periodSumVariants.sumDay;
    const periodDiscountSum =
      isAnimalsTariffLayout ? periodSumVariants.discountSum : periodSumVariants.discountSumDay;

    const totalOptionsSums = useMemo(
      () =>
        Object.values(total?.options).reduce(
          (acc, current) => {
            const currentPeriodSum = current[periodSum];
            const currentPeriodDiscountSum = current[periodDiscountSum];
            const isCheckedSocsInTariffOptions = checkSocsInTariffOptions(
              current.soc,
              info?.tariffOptions,
            );
            // eslint-disable-next-line max-len
            const totalOptionsSum =
              current.checked && currentPeriodSum && isCheckedSocsInTariffOptions ?
                acc.totalOptionsSum + currentPeriodSum
              : acc.totalOptionsSum;
            // eslint-disable-next-line max-len
            const totalOptionsDiscountSum =
              current.checked && currentPeriodDiscountSum && isCheckedSocsInTariffOptions ?
                acc.totalOptionsDiscountSum + currentPeriodDiscountSum
              : acc.totalOptionsDiscountSum;
            return {
              totalOptionsSum,
              totalOptionsDiscountSum,
            };
          },
          { totalOptionsSum: 0, totalOptionsDiscountSum: 0 },
        ),
      [total],
    );

    const { totalOptionsSum, totalOptionsDiscountSum } = totalOptionsSums;

    const totalPartnersSum = useMemo(() => totalPartnersPrice(total?.subscriptions), [total]);

    const trialSubscription = useMemo(() => {
      if (total.subscriptions[0]) {
        const { price, subscriptionStatusText, paymentPeriod } = total.subscriptions[0];
        return [subscriptionStatusText.toLowerCase(), `${price} ${paymentPeriod}`];
      }
      return [];
    }, [total]);

    const familyPriceTariffInMonthly = `${currentFamilyPrice} ₽`;

    const rateValues = useMemo(
      () => ({
        [REPLACED_RATES.shortRate]: convertSum(
          (shortRateDiscount || gbAndMinutesShortRate) + totalOptionsDiscountSum,
        ),
        [REPLACED_RATES.longRate]: convertSum(discount || gbAndMinutesLongRate),
        [REPLACED_RATES.optionsRate]: convertSum(totalOptionsDiscountSum),
        [REPLACED_RATES.chargeAmount]: chargeTariffAmount,
        [REPLACED_RATES.partners]: convertSum(totalPartnersSum),
        [REPLACED_RATES.partnerTrial]: trialSubscription[0],
        [REPLACED_RATES.partnerSumAfterTrial]: trialSubscription[1],
        [REPLACED_RATES.chargeTariffAmount]: info.chargeAmount,
        [REPLACED_RATES.chargePresetAmount]: info.chargePresetAmount,
        [REPLACED_RATES.connectedFamilyPriceTariff]: familyPriceTariffInMonthly,
      }),
      [
        shortRateDiscount,
        gbAndMinutesShortRate,
        totalOptionsDiscountSum,
        discount,
        chargeTariffAmount,
        totalPartnersSum,
        trialSubscription,
        gbAndMinutesLongRate,
        currentFamilyPrice,
      ],
    );

    const isHideSum = isDataTariffLayout;
    const isFamilyChecked = isChangeTariff && isConnectedFamily;

    // Формирование текста над кнопкой К подключению
    const additionalInfo = useMemo(() => {
      if (!isChangeTariff) {
        const customersText = {
          customersBaseText: true,
          customersAdditionalText: !!totalOptionsDiscountSum,
        };

        const currentStringParts = getCurrentStringParts(customersText);
        const currentString = getStringFromParts(currentStringParts, content);
        return templateParser(currentString, rateValues);
      }

      // для Пономерной Семьи если выбран Checkbox
      if (isAuthenticated && isFamilyChecked && familyNumberedPriceToggleEnabled) {
        const hasConstructorText = {
          connectedFamilyPriceTariffText: true,
        };

        const currentStringParts = getCurrentStringParts(hasConstructorText);
        const currentString = getStringFromParts(currentStringParts, content);
        return templateParser(currentString, rateValues);
      }

      if (isAuthenticated && hasTariffConstructor) {
        const hasConstructorText = {
          constructorTariffBaseText: true,
          partnersPriceBaseText: !!total?.subscriptions?.length,
          partnerPriceTrialText: isSingleSubscriptionHasTrial(total?.subscriptions),
          constructorTariffOptionsText: !!totalOptionsSum,
        };

        const currentStringParts = getCurrentStringParts(hasConstructorText);
        const currentString = getStringFromParts(currentStringParts, content);
        return templateParser(currentString, rateValues);
      }

      if (isAuthenticated && !hasTariffConstructor) {
        const otherTariffText = {
          otherTariffBaseText: true,
          partnersPriceBaseText: !!total?.subscriptions?.length,
          partnerPriceTrialText: isSingleSubscriptionHasTrial(total?.subscriptions),
          otherTariffOptionsPriceText: !!totalOptionsDiscountSum,
          otherTariffChangeText: !!chargeTariffAmount,
          otherTariffTailText: true,
        };

        const currentStringParts = getCurrentStringParts(otherTariffText);
        const currentString = getStringFromParts(currentStringParts, content);
        return templateParser(currentString, rateValues);
      }

      return content.additionalCommonText;
    }, [
      total,
      isChangeTariff,
      isAuthenticated,
      hasTariffConstructor,
      rateValues,
      totalPartnersSum,
    ]);

    const note =
      (isAnimalsTariffLayout &&
        ((changePresetAmountCondition && templateParser(content.changePresetText, rateValues)) ||
          (changeTariffAmountCondition && templateParser(content.changeTariffText, rateValues)) ||
          (isChangeTariff && ' ') ||
          content.buyNewSimText)) ||
      additionalInfo;

    let noteText;

    if (activeCharacter?.discountText && !activeCharacter?.presetDiscounts?.changeTariffForm) {
      noteText = `${activeCharacter?.discountText}${note}`;
    } else if (isFamilyChecked && isAuthenticated && familyNumberedPriceToggleEnabled) {
      noteText = additionalInfo;
    } else {
      noteText = note;
    }

    const btnText = useMemo(() => {
      if (isTariffCompleted) {
        return content.btnTextComplete;
      }

      if (yourTariff) {
        return checkData?.btnTextHasTariff;
      }

      if (isAnimalsTariffLayout && hasTariffConstructor && isChangeTariff && isAuthenticated) {
        return 'Сохранить';
      }

      if (isAnimalsTariffLayout) {
        return `${content.btnTextSave}&nbsp;&nbsp;${totalSum?.btnSum} ₽`;
      }

      return checkData?.btnText;
    }, [isTariffCompleted, yourTariff, isChangeTariff, totalSum]);

    const btnRightSideNode = useMemo(() => {
      if (
        isTariffCompleted ||
        isAnimalsTariffLayout ||
        !isAuthenticated ||
        !isChangeTariff ||
        yourTariff
      ) {
        return null;
      }

      const sum = hasTariffConstructor ? totalSum.btnHasTariffSum : totalSum.btnSum;

      return <Text size="size5-r-s">{`${sum}&nbsp;${info.chargeAmountUnit}`}</Text>;
    }, [
      info,
      totalSum,
      yourTariff,
      isChangeTariff,
      isAuthenticated,
      isTariffCompleted,
      hasTariffConstructor,
      isAnimalsTariffLayout,
    ]);

    const mainPackageTitle =
      isAnimalsTariffLayout ? content.mobileConnectionTitle : content.mainPackageTitle;

    const handleClick = () => {
      if (activeTab === CONSTRUCTOR_TABS.SimOrderForm) {
        vkPushSimTariffEvent(alias, ETariffsVkGoals.SimTariffOpenForm);
      }
      if (sendAnalytics) {
        sendAnalytics({ isConnect: false });
      }
      if (sendYaLiveAnalytics) {
        sendYaLiveAnalytics('click');
      }
      handleOrderModal(yourTariff);
    };

    const {
      modalContent: connectConvergenceModalContent,
      connectConvergence,
      isModalOpen: isConnectConvergenceModalOpen,
      closeModal: connectConvergenceModalClose,
      isLoading,
      sendCallBackAuthZone,
      confirmChangeMobilePreset,
      resetMobilePresetToDefault,
    } = useConnectConvergence(
      isAuthenticated,
      requestConvergenceParams,
      content.requestSent,
      content.confirmConnect,
      isShowAvailableSettings,
      handleRefundDiscount,
    );

    const isNeedConnectDefaultConvergence =
      (isNeedConnectedConvergence && !isAuthenticated) || isAppendConvergenceToUp;

    const getRequest = () => {
      if (isAuthenticated && isConvergence && !isChangeTariff) {
        return handleClick();
      }
      if (isNeedDefaultCallBack && isOptionsDefault && isYourPreset) {
        // прокинули userNumber в рамках фикса BCP-5702
        return sendCallBackAuthZone(userNumber);
      }
      if (isAuthenticated && !isShowAvailableSettings && isConvergence) {
        return confirmChangeMobilePreset();
      }
      return isNeedConnectDefaultConvergence ?
          connectConvergence({
            totalSum,
            data: content,
            total,
            joinedOptions,
            getOptionsNames,
            homeInternetOptions,
          })
        : handleClick();
    };

    const onReject = () => sendCallBackAuthZone(userNumber);
    const onConfirm =
      !isShowAvailableSettings ? resetMobilePresetToDefault : connectConvergenceModalClose;
    const isDisableCheckConvergence = isAnimalsTariffLayout && !isCheckedConvergence;
    // eslint-disable-next-line max-len
    const isDisabled =
      isTariffCompleted ||
      yourTariff ||
      isDisableFamilyButton ||
      hasRequestConvergenceError ||
      isDisableCheckConvergence;

    // Для Пономерной отображения цены в Check
    const getChangedSumOfFamily = () => {
      if (currentPricePeriod === FAMILY_PRICING_PERIOD.DAILY) {
        return currentFamilyPrice * THIRTY_DAYS_IN_MONTH;
      }
      return currentFamilyPrice;
    };

    const changedSumOfFamily = getChangedSumOfFamily();

    // Округление цены за семью
    const roundedSumOfFamily = getRoundedPrice(changedSumOfFamily);

    return (
      <>
        <CommonPopup
          {...{ onReject, onConfirm }}
          data={connectConvergenceModalContent}
          isOpen={isConnectConvergenceModalOpen}
          onClose={connectConvergenceModalClose}
        />

        <div className={cx('wrapper')}>
          <div className={cx('check')} data-component="Check">
            {/* Заголовок */}
            <Header
              activeCharacter={activeCharacter}
              handleRefundDiscount={handleRefundDiscount}
              hasNotification={hasNotification}
              isAnimalsTariffLayout={isAnimalsTariffLayout}
              refundDiscount={content.refundDiscount}
              title={checkData?.title}
              yourTariff={yourTariff}
            />

            {/* Абонентская плата */}
            {isAnimalsTariffLayout && (
              <SubscriptionFeeBlock
                activeColor={activeCharacter?.activeColor}
                fttbPresetConnectedDiscount={fttbPresetConnectedDiscount}
                isChangeTariff={isChangeTariff}
                isCheckedConvergence={isCheckedConvergence}
                monthlyPeriod={content.monthlyPeriod}
                price={totalSum.sum}
                priceWithDiscount={totalSum.discountSum}
                temporaryDiscount={temporaryDiscount}
                title={content.subscriptionFee}
                unit={content.unit}
              />
            )}

            {/* Стартовое пополнение баланса в нз на первом табе */}
            {initialBalancePaymentCondition && (
              <AdditionalCost
                price={info.initialBalancePayment}
                title={content.titleInitialBalance}
                unit={info.chargeAmountUnit}
              />
            )}

            {/* Оплата за смену тарифа */}
            {changeTariffAmountCondition && (
              <AdditionalCost
                description={content.oncePayment}
                price={info.chargeAmount}
                title={checkData?.titleChangeTariff}
                unit={info.chargeAmountUnit}
              />
            )}

            {/* Оплата за смену пресета */}
            {changePresetAmountCondition && (
              <AdditionalCost
                description={content.oncePayment}
                price={info.chargePresetAmount}
                title={checkData?.titleChangePreset}
                unit={info.chargeAmountUnit}
              />
            )}

            {/* Оплата по пресету гб и минуты */}
            {showFee && (
              <>
                <MainPackageBlock
                  discount={discount}
                  gbAndMinutesLongRate={gbAndMinutesLongRate}
                  hideTooltipInShortCycle={hideTooltipInShortCycle}
                  info={info}
                  mainPackageTitle={mainPackageTitle}
                />
                <Presets total={total} />
              </>
            )}

            {/* Опции конструктора  */}
            {showOptions && total?.options && (
              <Options
                free={content.free}
                info={info}
                isAnimalsTariffLayout={isAnimalsTariffLayout}
                isHideSum={isHideSum}
                joinedOptions={joinedOptions}
                oldPriseSum={totalOptionsSum}
                optionTitle={content.titleOptions}
                totalOptionsSum={totalOptionsDiscountSum}
              />
            )}
            {/* Опции Домашний интернет и ТВ  */}
            {!!homeInternetOptions?.length && showConvergenceOptions && (
              <Options
                free={content.free}
                info={info}
                isAnimalsTariffLayout={isAnimalsTariffLayout}
                joinedOptions={getOptionsNames(homeInternetOptions)}
                optionTitle={content.titleInternetOptions}
                totalOptionsSum={getOptionsPrice(homeInternetOptions)}
              />
            )}
            {/* Опции Оплата после уточнения заказа  */}
            {!!needVerificationInternetOptions.length && showConvergenceOptions && (
              <Options
                free={content.free}
                info={info}
                isAnimalsTariffLayout={isAnimalsTariffLayout}
                joinedOptions={getOptionsNames(needVerificationInternetOptions)}
                optionTitle={content.titleVerificationOptions}
                tooltip={content.verificationOptionsTooltip}
                totalOptionsSum={getOptionsPrice(needVerificationInternetOptions)}
              />
            )}
            {/* Опции Отключенные опции  */}
            {!!disabledOptions.length && (
              <Options
                free={content.free}
                info={info}
                isAnimalsTariffLayout={isAnimalsTariffLayout}
                joinedOptions={getOptionsNames(disabledOptions)}
                optionTitle={content.titleDisabledOptions}
                totalOptionsSum={getOptionsPrice(disabledOptions)}
              />
            )}

            {!!total?.subscriptions?.length && (
              <Subscriptions subscriptions={total.subscriptions} />
            )}

            {/* Семья в билайне */}
            {isConnectedFamily && isChangeTariff && !yourTariff && (
              <FamilyInfo
                price={roundedSumOfFamily}
                title={familyContent.title}
                unit={FAMILY_PRICING_PERIOD.MONTHLY}
              />
            )}

            {/* Оплата перенос */}
            {checkData?.numberPortation && activeTab === 'MNPForm' && (
              <AdditionalCost
                description={checkData?.numberPortation.description}
                price={checkData?.numberPortation.price?.value}
                title={checkData?.numberPortation.title}
                unit={checkData?.numberPortation.price?.unit}
              />
            )}

            {/* Оплата за сим */}
            {checkData?.simOrder && activeTab === 'SimOrderForm' && (
              <AdditionalCost
                description={checkData?.simOrder.description}
                price={checkData?.simOrder.price?.value}
                title={checkData?.simOrder.title}
                unit={checkData?.simOrder.price?.unit}
              />
            )}

            {/* Итого для интернет лайв и твое решение */}
            {!isAnimalsTariffLayout && (
              <Summary content={content} info={info} totalSum={totalSum} />
            )}

            {((!yourTariff && noteText) || isFamilyChecked) && (
              <div className={cx('description')}>
                <Text color="grey50" size="size5-r-s">
                  {noteText}
                </Text>
              </div>
            )}

            {!(yourTariff && isAnimalsTariffLayout) && (
              <div className={cx('button')}>
                <Button
                  disabled={!!isDisabled}
                  extra
                  fullWidth
                  isLoading={isLoading}
                  onClick={getRequest}
                  rightSideNode={btnRightSideNode}
                  size="l"
                  testName="Check_button"
                  type="button"
                  variant={isTariffCompleted || yourTariff ? 'tertiary' : 'main'}
                >
                  {btnText}
                </Button>
              </div>
            )}
          </div>

          {content.banners && <BannersContainer familyBanner={content.banners.familyBanner} />}
        </div>
      </>
    );
  },
);

Check.propTypes = {
  content: PropTypes.shape({
    banners: PropTypes.shape(bannerPropTypesShape),
    customersBaseText: PropTypes.string,
    customersAdditionalText: PropTypes.string,
    otherTariffBaseText: PropTypes.string,
    otherTariffOptionsPriceText: PropTypes.string,
    otherTariffChangeText: PropTypes.string,
    otherTariffTailText: PropTypes.string,
    constructorTariffBaseText: PropTypes.string,
    constructorTariffOptionsText: PropTypes.string,
    additionalCommonText: PropTypes.string,
    partnersPriceBaseText: PropTypes.string,
    partnerPriceTrialText: PropTypes.string,
    title: PropTypes.string,
    titleOptions: PropTypes.string,
    titleChangeTariff: PropTypes.string,
    titleInitialBalance: PropTypes.string,
    btnText: PropTypes.string,
    btnTextHasTariff: PropTypes.string,
    btnTextComplete: PropTypes.string,
    additionalInfo: PropTypes.string,
    additionalInfoOptions: PropTypes.string,
    additionalInfoSecondVariant: PropTypes.string,
    additionalInfoThirdVariant: PropTypes.string,
    additionalInfoSecondVariantOptions: PropTypes.string,
    additionalInfoThirdVariantOptions: PropTypes.string,
    additionalInfoThirdVariantChargeAmount: PropTypes.string,
    additionalInfoThirdVariantTail: PropTypes.string,
    additionalCommonInfo: PropTypes.string,
    changePresetText: PropTypes.string,
    changeTariffText: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        leftText: PropTypes.string,
        rightText: PropTypes.string,
      }),
    ),
    totalPriceText: PropTypes.string,
    btnTextSave: PropTypes.string,
  }),
  total: PropTypes.shape({
    gbytes: PropTypes.shape(totalUnitPropTypesShape),
    minutes: PropTypes.shape(totalUnitPropTypesShape),
    sms: PropTypes.shape(totalUnitPropTypesShape),
    options: PropTypes.shape({}),
    initOptions: PropTypes.arrayOf(PropTypes.shape({})),
    subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  info: PropTypes.shape({
    chargeAmount: PropTypes.number,
    initialBalancePayment: PropTypes.number,
    chargeAmountUnit: PropTypes.string,
    longRcRateUnit: PropTypes.string,
    shortRcRateUnit: PropTypes.string,
    defaultInfo: PropTypes.bool,
    accumulators: PropTypes.shape({
      sms: PropTypes.shape(accumulatorUnitPropTypesShape),
      gbytesAndMinutes: PropTypes.shape(accumulatorUnitPropTypesShape),
    }),
  }),
  yourTariff: PropTypes.bool,
  isChangeTariff: PropTypes.bool,
  handleOrderModal: PropTypes.func,
  totalSum: PropTypes.shape({
    sum: PropTypes.string,
    btnSum: PropTypes.string,
    btnHasTariffSum: PropTypes.string,
    discountSum: PropTypes.string,
  }),
  alias: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  hasTariffConstructor: PropTypes.bool,
  gbAndMinutesLongRate: PropTypes.number,
  currentPricePeriod: PropTypes.string,
  requestStatus: PropTypes.string,
  discount: PropTypes.number,
  changeTariffAmountCondition: PropTypes.bool,
  changePresetAmountCondition: PropTypes.bool,
  initialBalancePaymentCondition: PropTypes.bool,
  gbAndMinutesShortRate: PropTypes.number,
  shortRateDiscount: PropTypes.number,
  chargeTariffAmount: PropTypes.number,
  chargePresetAmount: PropTypes.number,
  isDataTariffLayout: PropTypes.bool,
  familyContent: PropTypes.shape({
    unit: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
  }),
  checkData: PropTypes.shape({
    titleChangeTariff: PropTypes.string,
    tooltipText: PropTypes.string,
    isNotEnoughMoney: PropTypes.bool,
    btnText: PropTypes.string,
    btnTextHasTariff: PropTypes.bool,
    titleChangePreset: PropTypes.string,
    title: PropTypes.string,
  }),
  hasNotification: PropTypes.bool,
  handleRefundDiscount: PropTypes.func,
  familyNumberedPriceToggleEnabled: PropTypes.bool,
  fttbPresetConnectedDiscount: PropTypes.string,
};

export default Check;
