import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import BackCallPopup from '../BackCallPopup';

const DEFAULT_TIMEOUT = 40000;

const LeaveYourPhonePopupWidget = ({
  backCallPopupData,
  selfCallingPopupData,
  redesign,
  showActionBar,
  isConvergent,
}) => {
  const isMobile = useIsMobileDimension();
  const { setPopupTimeout } = selfCallingPopupData;
  const [showPopup, setShowPopup] = useState(false);
  const [popupTimer] = useState(() => {
    if (typeof setPopupTimeout !== 'number') {
      return DEFAULT_TIMEOUT;
    }
    return setPopupTimeout < 1000 ? setPopupTimeout * 1000 : setPopupTimeout;
  });

  const handlePopupVisibility = () => setShowPopup(!showPopup);
  const getFlagFromSessionStorage = () => sessionStorage.getItem('backCallPopupIsShown');

  const mouseMoveEventHandler = (evt) => {
    if (getFlagFromSessionStorage()) {
      document.removeEventListener('mousemove', mouseMoveEventHandler);
      return;
    }

    if (evt.clientY < 10) {
      handlePopupVisibility();
    }
  };

  const setDesktopPopup = () => {
    setTimeout(() => {
      document.addEventListener('mousemove', mouseMoveEventHandler);
    }, popupTimer);
  };

  const setMobilePopup = () => {
    setTimeout(() => {
      if (getFlagFromSessionStorage()) return;

      handlePopupVisibility();
    }, DEFAULT_TIMEOUT);
  };

  useEffect(() => {
    if (isMobile) {
      setMobilePopup();
    } else {
      setDesktopPopup();
    }
  }, [isMobile]);

  useEffect(() => {
    const invokePopupImmediately = new URLSearchParams(window.location.search).get('callback');

    if (invokePopupImmediately) {
      handlePopupVisibility();

      const newUrl = window.location.href.replace(window.location.search, '');
      window.history.replaceState({}, '', newUrl);
    }
  }, []);

  return showPopup ?
      <BackCallPopup
        data={backCallPopupData}
        isConvergent={isConvergent}
        isOpen={showPopup}
        onClose={handlePopupVisibility}
        redesign={redesign}
        showAbbreviatedPopup={showPopup}
        showActionBar={showActionBar}
        showCaptcha={backCallPopupData.showCaptcha}
      />
    : null;
};

LeaveYourPhonePopupWidget.propTypes = {
  showActionBar: PropTypes.bool,
  backCallPopupData: PropTypes.shape({
    showCaptcha: PropTypes.bool,
  }),
  selfCallingPopupData: PropTypes.shape({
    setPopupTimeout: PropTypes.number,
  }),
  redesign: PropTypes.bool,
  isConvergent: PropTypes.bool,
};

export default LeaveYourPhonePopupWidget;
