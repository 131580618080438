import { shallowEqual } from '@beef/utils';

import { EConstructorStep } from '../../../../store/slices/stepperSlice/types';
import { useAppSelector } from '../../../../store';
import { useIsMyTariff } from '../../../../hooks/useIsMyTariff';
import { useStepNavigation } from '../../../../hooks/useStepNavigation';

/** Хук возвращяет событие onClick для компонета Bill */
export const useBillButtonText = () => {
  /* Получение общих контентных данных */
  const { nextButton, connectedButton, saveFttbSettingsButton } = useAppSelector(
    (state) => ({
      nextButton: state.common.content.nextButton,
      connectedButton: state.common.content.connectedButton,
      saveFttbSettingsButton: state.common.content.saveFttbSettingsButton,
    }),
    shallowEqual,
  );
  const isMyTariff = useIsMyTariff();
  const { currentStep } = useStepNavigation();
  const isFttbConnectedSettings = currentStep === EConstructorStep.fttbConnectedSettings;

  const getNextButtonText = () => {
    /* если isMyTariff то всегда текст: Подключено */
    if (isMyTariff) return connectedButton;
    /* если шаг настройки оборудования, то всегда текст: Сохранить настройки */
    if (isFttbConnectedSettings) return saveFttbSettingsButton;
    /* дефолтный текст: Далее */
    return nextButton;
  };

  return getNextButtonText();
};
