const selectContent = (state) => state?.extData?.content?.card;
const selectModalsContent = (state) => state?.extData?.content?.modals;
const selectModalsDetails = (state) => state?.extData;
const selectMainStepData = (state) => state?.external?.spn?.mainStepData;
const selectIsModalOpen = (state) => state?.external?.spn?.isModalOpen;
const selectStateSpn = (state) => state?.external?.spn;
const selectWaitingRequest = (state) => state?.waitingRequest;
const selectIsLoadingModal = (state) => state?.isLoadingModal;
const selectHasErrorValidation = (state) => state?.hasErrorValidation;
const selectErrorText = (state) => state?.errorText;
const selectLoaderData = (state) => state?.external?.spn?.extData?.content?.modals?.loader;
const selectLoaderAnimationName = (state) =>
  state?.external?.spn?.extData?.content?.modals?.loader?.animationName;
const selectIsAuthenticated = (state) => selectStateSpn(state)?.isAuthenticated;
const selectErrorTextInModalsContent = (state) => state?.main?.childSocsEditable?.errors;
const selectAuthData = (state) => state?.external?.authData;
const selectInputPlaceholder = (state) =>
  selectModalsContent(selectStateSpn(state))?.main?.childSocsEditable?.placeholder;
const selectSavedTempEditableSpn = (state) => selectStateSpn(state)?.tempEditableSpn;

export {
  selectAuthData,
  selectContent,
  selectErrorText,
  selectErrorTextInModalsContent,
  selectHasErrorValidation,
  selectInputPlaceholder,
  selectIsAuthenticated,
  selectIsLoadingModal,
  selectIsModalOpen,
  selectLoaderAnimationName,
  selectLoaderData,
  selectMainStepData,
  selectModalsContent,
  selectModalsDetails,
  selectSavedTempEditableSpn,
  selectStateSpn,
  selectWaitingRequest,
};
