import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Switch, Text } from '@beef/ui-kit';

// Utils
import { formatTemplate } from '../../../../../utils';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const AutoProlong = memo(
  ({ unit, value, title, benefit, setEnabled, defaultEnabled, persistentEnabledAutoProlong }) => {
    const onChange = (e) => setEnabled(e.target.checked);
    const currentText = formatTemplate(benefit, { value, unit });
    return (
      <div className={cx('wrapper')}>
        <div className={cx('wrapper__title')}>
          {title && (
            <Text color="grey95" size="size4-r" tagType="div">
              {title}
            </Text>
          )}
          {benefit && (
            <div className={cx('wrapper__description')}>
              <Text color="grey50" size="size5-r-s" tagType="div">
                {currentText}
              </Text>
            </div>
          )}
        </div>
        {title && (
          <Switch
            checked={defaultEnabled}
            disabled={persistentEnabledAutoProlong}
            name={title}
            onChange={onChange}
          />
        )}
      </div>
    );
  },
);

AutoProlong.propTypes = {
  value: PropTypes.number,
  unit: PropTypes.string,
  title: PropTypes.string,
  benefit: PropTypes.string,
  setEnabled: PropTypes.func,
  defaultEnabled: PropTypes.bool,
  persistentEnabledAutoProlong: PropTypes.bool,
};
