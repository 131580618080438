import { JSONValue, MinimalPageDSL } from '@beefco/types';

export interface SiteModelWithBeefCo {
  baseUrl: string;
  data: {
    [key: string]: JSONValue;
    beefCo: MinimalPageDSL | null;
  };
  renderUrl: string;
}

export const extractBeefCoDSL = <T extends SiteModelWithBeefCo>({
  data: { beefCo, ...data },
  ...model
}: T) => ({
  beefCo,
  model: {
    ...model,
    data,
  },
});
