import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import TariffConstructor from 'pages/ProductCard/components/TariffConstructor';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const onConnectTariffClick = () => {
  const connectButton = document.querySelector('#totalButtonConnect');

  if (connectButton) connectButton.click();
};

const ConvergencyTariffConstructor = ({
  selectorDataUrl,
  bannerData,
  tariffName,
  authenticated,
  tariffConnected,
  showBannerArrow,
}) => (
  <TariffConstructor
    authenticated={authenticated}
    bannerClassName={cx('constructorBanner')}
    bannerData={bannerData}
    onConnectTariffClick={onConnectTariffClick}
    selectorDataUrl={selectorDataUrl}
    showBannerArrow={showBannerArrow}
    tariffConnected={tariffConnected}
    tariffName={tariffName}
  />
);

ConvergencyTariffConstructor.defaultProps = {
  authenticated: false,
  tariffConnected: false,
  showBannerArrow: false,
};

ConvergencyTariffConstructor.propTypes = {
  selectorDataUrl: PropTypes.string,
  bannerData: PropTypes.object,
  tariffName: PropTypes.string,
  authenticated: PropTypes.bool,
  tariffConnected: PropTypes.bool,
  showBannerArrow: PropTypes.bool,
};

export default ConvergencyTariffConstructor;
