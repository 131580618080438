import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link, SmartInput } from '@beef/legacy-ui-kit';

import { requestCaptcha } from './services';
import { CAPTCHA_STATUS } from '../../pages/FTTB2021/hoc/CaptchaWithCallbackPopupHOC/index';
import styles from './Captcha.pcss';

const cx = classNames.bind(styles);
const DEFAULT_CAPTCHA_WIDTH = 148;
const DEFAULT_CAPTCHA_HEIGHT = 39;

class Captcha extends Component {
  constructor(props) {
    super(props);
    this.state = { pic: null, captcha: '', captchaKey: '' };
    this.fetchCaptcha = this.fetchCaptcha.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  componentDidMount() {
    const { onMount } = this.props;
    this.fetchCaptcha();
    onMount?.(this);
  }

  componentDidUpdate() {
    const { newCaptcha, changeNewCaptcha } = this.props;

    if (newCaptcha) {
      this.fetchCaptcha();
      this.setValue('');
      changeNewCaptcha(!newCaptcha);
    }
  }

  setValue(captcha) {
    this.setState({ captcha });
  }

  handleChange = (value) => {
    const { onChange } = this.props;

    this.setValue(value);
    onChange?.(value);
  };

  fetchCaptcha() {
    const {
      width,
      height,
      setCaptchaKey,
      changeCaptchaMsg,
      changeCaptchaStatus,
      changeCaptchaToken,
    } = this.props;

    const { captchaKey } = this.state;

    const params = new URLSearchParams({
      w: width,
      h: height,
      ...(captchaKey ? { key: captchaKey } : {}),
      r: new Date().getTime(),
    }).toString();

    requestCaptcha(`/captcha.ashx?${params}`)
      .then(({ pic, key }) => {
        if (!captchaKey && key) {
          setCaptchaKey(key);
        }

        this.setState({ pic, captchaKey: key });
      })
      .catch(console.error);

    this.setValue('');
    changeCaptchaMsg('');
    changeCaptchaToken('');
    changeCaptchaStatus(CAPTCHA_STATUS.success);
  }

  render() {
    const { name, onBlur, onFocus, status, msg, inputId, errorMsg } = this.props;
    const { pic, captcha } = this.state;

    return (
      <div className={cx('captcha')}>
        <span>{msg.title}</span>
        <SmartInput
          captcha={pic}
          className={cx('captchaInput')}
          inputId={inputId}
          mask="_____"
          msg={errorMsg}
          name={name}
          onBlur={onBlur}
          onChange={this.handleChange}
          onFocus={onFocus}
          status={status}
          value={captcha}
        />
        <div className={cx('reset')}>
          <Link onClick={this.fetchCaptcha}>{msg.reloadText}</Link>
        </div>
      </div>
    );
  }
}

Captcha.defaultProps = {
  width: DEFAULT_CAPTCHA_WIDTH,
  height: DEFAULT_CAPTCHA_HEIGHT,
};

Captcha.propTypes = {
  name: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  changeCaptchaToken: PropTypes.func,
  status: PropTypes.string,
  changeCaptchaStatus: PropTypes.func,
  msg: PropTypes.shape({
    title: PropTypes.string,
    reloadText: PropTypes.string,
  }),
  setCaptchaKey: PropTypes.func,
  onMount: PropTypes.func,
  inputId: PropTypes.string,
  onChange: PropTypes.func,
  errorMsg: PropTypes.string,
  changeCaptchaMsg: PropTypes.func,
  newCaptcha: PropTypes.bool,
  changeNewCaptcha: PropTypes.func,
};

export default Captcha;
