import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { Link, Status } from '@beef/legacy-ui-kit';

import { showServiceDisconnectionPopup } from 'pages/ProductCard/actions/serviceConnect';
import { toggleErrorPopup } from 'pages/ProductCard/components/ServiceStatusPopup/actions';

import style from './style.pcss';
import cardStyle from '../../ProductCard.pcss';

const cx = classnames.bind({ ...cardStyle, ...style });

export class ServiceConnectionStatus extends Component {
  handlerToggleErrorPopup = () => {
    const {
      data: { errorPopup },
    } = this.props;

    this.props.toggleErrorPopup({
      opened: true,
      ...errorPopup,
    });
  };

  handleDisconnectClick = () => {
    const {
      onDisconnect,
      data: { isConnectedLater, marketCode, pageTitle, soc },
      disconnectPartnerService,
    } = this.props;

    if (typeof onDisconnect === 'function') {
      onDisconnect({
        disconnectPartnerService,
        isConnectedLater,
        marketCode,
        pageTitle,
        soc,
      });
    }
  };

  renderConnected() {
    const {
      serviceDisconnectionPopup,
      disconnectContent,
      isConnectedLater,
      connectedContent,
      serviceDateTime,
      serviceProcess,
      serviceType,
      canBeRemoved,
      data,
    } = this.props;

    const { productTypeTitle } = data;

    if (serviceProcess === 'progress') return null;

    if (
      (serviceDisconnectionPopup && serviceDisconnectionPopup.isDisconnected) ||
      (serviceDateTime && serviceDateTime.date)
    ) {
      let { statusText } = serviceDisconnectionPopup;
      if (serviceDateTime.date) {
        statusText = `${productTypeTitle || 'Услуга'} будет ${
          serviceType === 'connect' ? 'подключена' : 'отключена'
        } ${serviceDateTime.date}`;
      }

      return (
        <div className={cx('connectButtons')}>
          <Status className="attention" emoji="statusOk">
            <p className={cx('deferredConnect')}>{statusText}</p>
          </Status>
        </div>
      );
    }

    const classNames = isConnectedLater && canBeRemoved ? ['attention'] : ['success'];

    if (canBeRemoved) {
      return (
        <div className={cx('connectButtons')}>
          <Status className={classNames} emoji={isConnectedLater ? 'statusOk' : null}>
            <span
              className={cx(
                'content',
                { later: isConnectedLater },
                'connectionStatusContent',
                classNames,
              )}
            >
              <span className={cx('text', 'connectionStatusText')}>{connectedContent}</span>
              <span className={cx('link', 'connectionStatusText', classNames)}>
                {typeof disconnectContent === 'string' ?
                  <Link
                    className={!isConnectedLater ? 'light' : null}
                    onClick={this.handleDisconnectClick}
                  >
                    {disconnectContent}
                  </Link>
                : disconnectContent}
              </span>
            </span>
          </Status>
        </div>
      );
    }

    return (
      <div className={cx('connectButtons')}>
        <Status className={classNames} text={connectedContent} />
      </div>
    );
  }

  renderError() {
    const { serviceType, data } = this.props;
    const { productTypeTitle } = data;

    const statusType = serviceType === 'connect' ? 'подключилась' : 'отключилась';

    return (
      <div className={cx('connectButtons')}>
        <Status className={['error']}>
          <span className={cx('content', 'connectionStatusContent')}>
            <span className={cx('text', 'connectionStatusText')}>
              {`${productTypeTitle || 'Услуга'}`} не {statusType}
            </span>
            <span className={cx('link', 'connectionStatusText')}>
              <Link className={cx('light')} onClick={this.handlerToggleErrorPopup}>
                Подробности
              </Link>
            </span>
          </span>
        </Status>
      </div>
    );
  }

  renderProgress() {
    return (
      <div className={cx('pendingContainer')}>
        <div className={cx('pendingWrapper')}>Заявка обрабатывается...</div>
      </div>
    );
  }

  renderTimeout() {
    return (
      <div className={cx('connectButtons')}>
        <Status className="attention" emoji="statusOk">
          <p className={cx('deferredConnect')}>Заявка отправлена</p>
        </Status>
      </div>
    );
  }

  render() {
    const {
      serviceProcess,
      archiveContent,
      warningContent,
      isConnected,
      isArchived,
      className,
      soc,
    } = this.props;

    return (
      <div className={cx('status', className)}>
        {warningContent && soc && (
          <div className={cx('connectButtons')}>
            <Status className={['attention']} emoji="statusFail" text={warningContent} />
          </div>
        )}
        {serviceProcess === 'error' && this.renderError()}
        {serviceProcess === 'progress' && this.renderProgress()}
        {serviceProcess === 'timeout' && this.renderTimeout()}
        {(isConnected || serviceProcess === 'complete') &&
          serviceProcess !== 'error' &&
          this.renderConnected()}
        {isArchived && (
          <div className={cx('connectButtons')}>
            <Status className={['centeredText']} text={archiveContent} />
          </div>
        )}
      </div>
    );
  }
}

ServiceConnectionStatus.propTypes = {
  disconnectContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  connectedContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  archiveContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  serviceDisconnectionPopup: PropTypes.object,
  toggleErrorPopup: PropTypes.func,
  isConnectedLater: PropTypes.bool,
  serviceDateTime: PropTypes.object,
  warningContent: PropTypes.string,
  serviceProcess: PropTypes.string,
  serviceType: PropTypes.string,
  onDisconnect: PropTypes.func,
  canBeRemoved: PropTypes.bool,
  isConnected: PropTypes.bool,
  isArchived: PropTypes.bool,
  data: PropTypes.object,
  soc: PropTypes.string,
  disconnectPartnerService: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { resultPopupData, serviceDateTime, serviceProcess, serviceType, view } = pathOr(
    {},
    ['external', 'serviceConnectionPopup'],
    state,
  );

  const { serviceDisconnectionPopup } = pathOr({}, ['external'], state);

  return {
    serviceDisconnectionPopup,
    resultPopupData,
    serviceDateTime,
    serviceProcess,
    serviceType,
    view,
  };
};

export default connect(mapStateToProps, (dispatch) => ({
  onDisconnect: ({ disconnectPartnerService, ...params }) => {
    if (disconnectPartnerService) {
      disconnectPartnerService();
    } else {
      dispatch(showServiceDisconnectionPopup(params));
    }
  },
  toggleErrorPopup: (params) => dispatch(toggleErrorPopup(params)),
}))(ServiceConnectionStatus);
