/* eslint-disable react/forbid-prop-types */
import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import axios from 'axios';
import { path, pathOr } from 'ramda';

import store from 'store';

// Actions
import { getMobileOS } from 'utils/detect-mobile';
import { handleChosenSocs } from 'pages/ProductCard/actions/tariffConnect';

import {
  isUpsalePopupIntoTariffCard,
  serviceSettingsPopup,
  settingsPopup as sharingServiceSettingPopup,
  upsalePopup,
  upsalePopupContent,
} from '../../actions/sharing';
import { showError } from '../../actions/actionsApi';

// Utils
import { formatAvailablePackages } from './mappers';

class TariffBodyHOC extends Component {
  state = {
    isResetAdsPreloader: false,
    isMobile: false,
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const isMobile = getMobileOS();
      this.setState({ isMobile: !!isMobile });
    }
  }

  openUpsaleOfferPopup = async () => {
    const { info, links } = this.props.tariffData.sharing;

    try {
      if (info.invites && info.invites.length === info.count) {
        throw new Error('No Upsale needed');
      }

      const { data } = await axios.get(links.upsaleUrl);

      if (!data) throw new Error('No Upsale Popup Content');

      this.props.fetchUpsalePopupContent(data);
      this.props.openUpsalePopup();
      this.props.isUpsalePopupIntoTariffCard();
    } catch (e) {
      this.openSebPopup();
    }
  };

  openSebPopup = () => {
    const { tariffData, invites } = this.props;
    const isSentStatus = invites.some((invite) => invite.status.toLowerCase() === 'sent');

    if (tariffData.isConnected && invites && isSentStatus) {
      return this.props.sharingServiceSettingPopup();
    }

    return this.props.serviceSettingsPopup();
  };

  // Функция вызывается в случает нажатия кнопки отказа от КонтрОффера;
  // В ответе мы обязаны полочить URl и сделать по нему редирект;
  resetAdsButtonHandler = async () => {
    const { tariffData } = this.props;
    this.setState({ isResetAdsPreloader: true });

    try {
      const { data } = await axios.post(tariffData.resetAdsUrl);

      if (data.isSucceeded) {
        window.location.href = data.view.redirectUrl;
      }
    } catch (error) {
      this.setState({ isResetAdsPreloader: false });
      this.props.showError(error || {});
    }
  };

  checkAvailableForConnect = () => {
    const { isConnected, availableForConnect, isArchived } = this.props.tariffData;
    return !isConnected && availableForConnect && !isArchived;
  };

  render() {
    const { children, tariffData } = this.props;
    const { isResetAdsPreloader, isMobile } = this.state;
    const availableForConnect = this.checkAvailableForConnect();

    if (!tariffData.parameterGroups) {
      return null;
    }

    return cloneElement(children, {
      isResetAdsPreloader,
      isMobile,
      availableForConnect,
      additionalOptions: this.props.additionalOptions,
      setESimOpenModal: this.props.setESimOpenModal,
      resetAdsButtonHandler: this.resetAdsButtonHandler,
      resetAdsButton: tariffData.resetAdsButton,
      resetAdsUrl: tariffData.resetAdsUrl,
      parameterGroups: tariffData.parameterGroups,
      isAuthenticated: tariffData.isAuthenticated,
      discountValue: tariffData.discountValue,
      availablePackages: formatAvailablePackages(tariffData.availablePackages),
      layoutType: tariffData.layoutType,
      isConnected: tariffData.isConnected,
      isForYoung: tariffData.isForYoung,
      isForYoungDetailEnabled: tariffData.isForYoungDetailEnabled,
      accumulators: tariffData.accumulators,
      isSimDiscountDeliveryOnly: tariffData?.simDiscount?.deliveryOnly,
      onSetChosenSocs: this.props.onSetChosenSocs,
      alias: tariffData.alias,
      esim: (tariffData || {}).esim,
      openUpsaleOfferPopup: this.openUpsaleOfferPopup,
      openSebPopup: this.openSebPopup,
      amountNumbersForShare: this.props.amountNumbersForShare,
    });
  }
}

TariffBodyHOC.propTypes = {
  setESimOpenModal: PropTypes.func,
  children: PropTypes.object,
  invites: PropTypes.object,
  additionalOptions: PropTypes.object,
  sharingServiceSettingPopup: PropTypes.func,
  serviceSettingsPopup: PropTypes.func,
  fetchUpsalePopupContent: PropTypes.func,
  openUpsalePopup: PropTypes.func,
  isUpsalePopupIntoTariffCard: PropTypes.func,
  showError: PropTypes.func,
  onSetChosenSocs: PropTypes.func,
  tariffData: PropTypes.shape({
    alias: PropTypes.string,
    esim: PropTypes.object,
    isArchived: PropTypes.bool,
    isConnected: PropTypes.bool,
    isForYoung: PropTypes.bool,
    isForYoungDetailEnabled: PropTypes.bool,
    availableForConnect: PropTypes.bool,
    simDiscount: PropTypes.shape({ deliveryOnly: PropTypes.bool }),
    resetAdsButton: PropTypes.string,
    resetAdsUrl: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    parameterGroups: PropTypes.array,
    discountValue: PropTypes.any,
    accumulators: PropTypes.array,
    sharing: PropTypes.object,
    availablePackages: PropTypes.shape({
      title: PropTypes.string,
      groups: PropTypes.arrayOf(
        PropTypes.shape({
          gift: PropTypes.string,
          title: PropTypes.string,
          parameters: PropTypes.arrayOf(
            PropTypes.shape({
              price: PropTypes.string,
              unit: PropTypes.string,
              value: PropTypes.string,
              soc: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
    layoutType: PropTypes.string,
  }),
  isConnected: PropTypes.bool,
  amountNumbersForShare: PropTypes.number,
};

const mapStateToProps = ({ external }) => {
  const tariffData = pathOr({}, ['tariff', 'data'], external);
  const invites = pathOr({}, ['sharing', 'info', 'invites'], tariffData);
  const isConnected = path(['isConnected'], tariffData);
  const additionalOptions = pathOr(null, ['tariffConnectionPopup', 'additionalOptions'], external);
  const amountNumbersForShare = path(['amountNumbersForShare'], tariffData);

  return {
    isConnected,
    tariffData,
    invites,
    additionalOptions,
    amountNumbersForShare,
  };
};

const ConnectedComponent = connect(mapStateToProps, (dispatch) => ({
  showError: () => dispatch(showError),
  sharingServiceSettingPopup: () => dispatch(sharingServiceSettingPopup.update({ opened: true })),
  serviceSettingsPopup: () => dispatch(serviceSettingsPopup.update({ opened: true })),
  fetchUpsalePopupContent: (data) => dispatch(upsalePopupContent.update(data)),
  openUpsalePopup: () => dispatch(upsalePopup.update({ opened: true })),
  isUpsalePopupIntoTariffCard: () => dispatch(isUpsalePopupIntoTariffCard.update(true)),
  onSetChosenSocs: (socs) => dispatch(handleChosenSocs(socs)),
}))(TariffBodyHOC);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
