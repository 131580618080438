
      export function getHardcodedPageDSL(slug) {
       switch (slug) {
          
            case 'BackToSchool': return import.meta.webpackHot ? require('./BackToSchool.json') : import('./BackToSchool.json');
            case 'BeefCoContactPage': return import.meta.webpackHot ? require('./BeefCoContactPage.json') : import('./BeefCoContactPage.json');
          default:
            throw new Error('Attempting to import non-existing coreDSL file for beefCo on the client');
        }
      }
      
      if (import.meta.webpackHot) {
        import.meta.webpackHot.accept();
      }
    