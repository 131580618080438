import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AccessibleInternetParameters = ({ data }) => {
  const { content, params } = data?.extData;

  return (
    <div className={cx('wrap')}>
      {content && (
        <>
          {content?.preview?.text && (
            <div className={cx('content-row')}>
              <Text color="grey80" size="size5-r-s" tagtype="p">
                {content.preview.text}
              </Text>
            </div>
          )}
        </>
      )}
      {!!params?.length && (
        <>
          {params.map((param) => (
            <div className={cx('content-row')}>
              <Text color="grey80" size="size5-r-s" tagtype="p">
                {param.label}
                &nbsp;
                {param.value}
              </Text>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

AccessibleInternetParameters.propTypes = {
  data: PropTypes.object,
};

export default AccessibleInternetParameters;
