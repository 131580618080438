import React, { FC } from 'react';
/* @ts-expect-error ошибка импорта carnica */
import { Text, textPropColor } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCAccordionItem = {
  color?: textPropColor;
  entityName?: string;
  /* Цена приходит от бека типом string с unit, поэтому 0-я стоимость отобразится */
  price?: string;
};

/** Компонент с названием опции и ценой для CAccordion */
export const CAccordionItem: FC<TCAccordionItem> = ({ color = 'primary', entityName, price }) => (
  <div className={cx('wrapper')}>
    {entityName && (
      <Text align="left" color={color} lineHeight={20} size="xs" weight="regular">
        {entityName}
      </Text>
    )}
    {price && (
      <Text align="left" color={color} lineHeight={20} size="xs" weight="regular">
        {price}
      </Text>
    )}
  </div>
);
