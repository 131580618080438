import React from 'react';
import PropTypes from 'prop-types';
import { Button, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const GroupButton = ({
  orderLink,
  aboutLink,
  orderButtonText,
  onButtonClick,
  aboutLinkText,
  onLinkClick,
  backCallPopupButton,
}) => (
  <div className={cx('bottomBtnWrap')}>
    {backCallPopupButton?.forceShowBackCallPopup && (
      <Button className={['big', cx('bottomBtn')]} onClick={backCallPopupButton?.setShowPopup} wide>
        <span dangerouslySetInnerHTML={{ __html: orderButtonText }} />
      </Button>
    )}
    {orderButtonText && !backCallPopupButton?.forceShowBackCallPopup && (
      <Button className={['big', cx('bottomBtn')]} href={orderLink} onClick={onButtonClick} wide>
        <span dangerouslySetInnerHTML={{ __html: orderButtonText }} />
      </Button>
    )}
    {aboutLinkText && (
      <Link
        className={['hideVisitedColor', cx('aboutLink')]}
        href={aboutLink}
        onClick={onLinkClick}
      >
        <span dangerouslySetInnerHTML={{ __html: aboutLinkText }} />
      </Link>
    )}
  </div>
);

GroupButton.propTypes = {
  orderLink: PropTypes.string,
  aboutLink: PropTypes.string,
  orderButtonText: PropTypes.string,
  aboutLinkText: PropTypes.string,
  onButtonClick: PropTypes.func,
  onLinkClick: PropTypes.func,
  backCallPopupButton: PropTypes.shape({
    forceShowBackCallPopup: PropTypes.bool,
    setShowPopup: PropTypes.func,
  }),
};

export default GroupButton;
