import { combineReducers } from '@reduxjs/toolkit';
import { composeStoreInitializer } from '@beef/redux';

// Reducers
import { modal } from './reducers/modal';
import { common } from './reducers/common';
import { totalSettings } from './reducers/totalSettings';
import { orderTariffUserData } from './reducers/orderTariffUserData';
import { orderTariffDetailsData } from './reducers/orderTariffDetailsData';

const rootReducer = combineReducers({
  modal,
  common,
  orderTariffUserData,
  orderTariffDetailsData,
  totalSettings,
});

export const initializeStore = composeStoreInitializer(rootReducer);
