import { createSelector } from '@reduxjs/toolkit';

import { selectTotal } from 'constructorV1/store/selectors/selectTotal';
import { selectTariffSettings } from 'constructorV1/store/selectors/selectTariffSettings';

/** Селектор лоадинга кнопки чека.
 * Если идет запрос на корзину или запрос на смену опций, показываем лоадер в кнопке */
export const selectButtonLoading = createSelector(
  selectTotal,
  selectTariffSettings,
  (total, options) => total.isLoadingBasketQuery || options.isOptionsLoading,
);
