import axios, { AxiosResponse } from 'axios';

import { ALLINONE_GET_REGION } from 'constants/Endpoints';

/** Тип объекта города/области */
type TQueryCity = {
  Area: null | string;
  CustomCity: null | string;
  Id: null | string;
  IsMarketing: boolean;
  Lat: null | number;
  Lon: null | number;
  MarketingRegionId: number;
  Title: string;
};

/** Тип объекта ответа метода /region/getsuggestions/ */
type TResponse = {
  /* Не удалось выяснить структуру объекта Errors */
  Errors: Record<string, any>;
  IsSucceeded: boolean;
  View: TQueryCity[];
  camelCase: boolean;
};

/* Запрос доступных регионов по поисковой строке */
export const getCitySearchResults = async (query: string) => {
  let options: Partial<TResponse> = {};
  try {
    const { data }: AxiosResponse<TResponse> = await axios({
      url: ALLINONE_GET_REGION,
      params: {
        boldFont: false,
        startsWith: query,
        'ui-culture': 'ru-ru',
      },
    });
    options = data;
  } catch (err) {
    return options;
  }

  return options?.View?.map((option: TQueryCity) => ({
    label: option.Title.replaceAll(/<[^>]*>/g, '') || '',
    slug: option?.MarketingRegionId || '',
  }));
};
