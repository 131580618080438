import React, { FC, useRef } from 'react';
import { Faq } from '@beef/smart-kit';

import { useSelectFAQContent } from './selectors/useSelectFAQContent';

/** Обертка над компонентом FAQ */
export const FAQContainer: FC = () => {
  const faqRef = useRef(null);

  const { faq, onClick } = useSelectFAQContent({ faqRef });
  // Не рендерим блок, если нет контента для FAQ
  if (!faq) {
    return null;
  }
  return (
    <div ref={faqRef}>
      <Faq {...faq} onClick={onClick} />
    </div>
  );
};
