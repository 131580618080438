import React from 'react';
import classnames from 'classnames/bind';
import { ImageCard } from '@beef/smart-kit';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const CardPlaceholder = ({ image }) => (
  <div className={cx('placeholder')}>
    <ImageCard bordered={false} img={{ src: image }} size="s" />
  </div>
);
