import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import BigButton from 'pages/FTTB/components/BigButton';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const OrderBtnSection = (props) => {
  const { disabledBtn, onClick, data } = props;

  return (
    <div className={cx('wrapper')}>
      <BigButton className={cx('orderBtn')} disabled={disabledBtn} onClick={onClick} wide>
        <span dangerouslySetInnerHTML={{ __html: data.orderBtnText }} />
      </BigButton>
    </div>
  );
};

OrderBtnSection.propTypes = {
  data: PropTypes.shape({
    orderBtnText: PropTypes.string,
  }),
  disabledBtn: PropTypes.bool,
  onClick: PropTypes.func,
};

export default OrderBtnSection;
