import React from 'react';
import classNames from 'classnames/bind';

import { PreChangesConflicts } from './components/PreChangesConflicts';
import { PreChangesInfo } from './components/PreChangesInfo';
import { PreChangesHeader } from './components/PreChangesHeader';
import { PreChangesSkeleton } from './components/PreChangesSkeleton';
import { usePreparePreChangeTariff } from './hooks/usePreparePreChangeTariff';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Контейнер предварительной проверки изменений по подключенному тарифу Up */
export const PreChangesTariffContainer = () => {
  const { showSkeleton, preChangesInfoProps, preChangesConflictProps } =
    usePreparePreChangeTariff();

  if (showSkeleton) {
    return (
      <section className={cx('wrapper')}>
        <PreChangesHeader isShowTitle={!preChangesConflictProps} />
        <PreChangesSkeleton />
      </section>
    );
  }

  return (
    <section className={cx('wrapper')}>
      <PreChangesHeader isShowTitle={!preChangesConflictProps} />
      {preChangesConflictProps ?
        <PreChangesConflicts preChangesConflictProps={preChangesConflictProps} />
      : <PreChangesInfo {...preChangesInfoProps} />}
    </section>
  );
};
