/** Типы табов выбора способа подключения тарифа */
export enum TabTypes {
  /** Изменение текущего тарифа */
  ChangeTariffForm = 'ChangeTariffForm',
  /** Переход со своим номером в билайн */
  MNPForm = 'MNPForm',
  /** Заказа новой Sim */
  SimOrderForm = 'SimOrderForm',
  /** Заказ новой eSIM */
  eSIMForm = 'eSIMForm',
}
