import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { ModalHeader } from '../ModalHeader';
import { UppersTabsContainer } from '../../constructorPage/UppersTabsContainer';
import { TModalContent } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Обертка, отвечающая за отображение плиток тарифа.
 * ModalHeader: заголовок и описание модалки. UppersTabsContainer: табы выбора персонажа */
export const ModalContent: FC<TModalContent> = ({
  isMainKey,
  modalHeaderProps,
  uppersTabsProps,
}) => (
  <div className={cx('wrapper')}>
    <ModalHeader {...modalHeaderProps} />
    {isMainKey ?
      <UppersTabsContainer {...uppersTabsProps} />
    : null}
  </div>
);

export type { TModalContent };
