import axios from 'axios';
import { path, pathOr } from 'ramda';
import qs from 'query-string';

import store from 'store';
import { transformRequest } from 'utils/globalAjaxHandler';
import { formatPhone, formatPhoneWithDefaultPrefix, unformatPhone } from 'utils/format-string';
import generateGUID from 'utils/guid-generator';
import { getAuthErrorByCode } from 'widgets/actions/authorization';
import { toggleOrderPaymentForm } from 'widgets/actions/orderPaymentForm';
import { removeUsedNumber } from 'widgets/actions/funcyNumberOrder';
import {
  getGAClientId,
  pushMnpErrorEvent,
  pushMnpSuccessEvent,
  pushMobileTariffConnectEvent,
  pushNumberErrorEvent,
  pushSimOrderErrorEvent,
  pushSimOrderEvent,
  pushTariffConnectionErrorEvent,
  pushTariffDownsellConnectSuccessEvent,
} from 'utils/ga';
import { slAddToCart } from 'utils/analytics/sl';
import { pushSmsFormEvent } from 'utils/analytics/tariffCard';
import { trackCPAEvent } from 'utils/track';
import { gaYandexTariffDisconnected } from 'utils/analytics/yandexTariffGA';
import {
  mobTariffDetailSMSConnectYM,
  yandexTariffConnectedSuccessYM,
  yandexTariffDisconnectedYM,
} from 'utils/analytics/tariffsYandexMetrica';
import {
  AUTH_YM_ACTIONS,
  AUTH_YM_EVENTS,
  YMAuthEvent,
  ymChangeTariff,
} from 'utils/analytics/ymCommonEvents';
import { ModalKeys, postConnectTariff } from 'pages/ProductCard/Tariff/components/Check/api';
import { recordPayload } from 'pages/ProductCard/actions/actionsApi';
import submitCallbackRequest from 'pages/FTTB/services/submitCallbackRequest';
import { TARIFF_WRONG_REGION_API } from 'pages/ProductCard/constants';
import { fetchWrongRegionPopup } from 'pages/ProductCard/services/fetchWrongRegionPopup';

import { toggleTariffConnectionPopup } from './tariffConnect/toggleTariffConnectionPopup';
import {
  initConnectionPopup,
  setTariffConnectionPopup,
  toggleConnectPopup,
} from './tariffConnect/tariffConnectExtension';
import { replaceDownsellTariff } from './downsellTariff/replaceDownsellTariff';
import {
  OPEN_TARIFF_DESCRIPTION_POPUP,
  SET_ADDITIONAL_SIM_COUNT,
  SET_CHOSEN_SOCS,
  SET_CONNECTION_RESULT_POPUP,
  SET_TARIFF_CHANGE_RESULT_POPUP,
  SET_TARIFF_CONNECTION_POPUP,
} from './actionTypes';
import { setConfirmPopup } from './confirmNumber';
import { setConstructorStep, toggleConstructor } from './tariffConstructor';

const CURRENT_TARIFF_NAME = 'Близкие люди с Яндекс Плюс';

/**
 * !!!- ПЕРЕНЕС И ПЕРЕПОДКЛЮЧИЛ -!!!
 * -------------------------------------------------------------------------
 * initConnectionPopup В tariffConnect/tariffConnectExtension;
 * -------------------------------------------------------------------------
 * getCurrentTariffData В tariffConnect/tariffConnectionAnalytics;
 * -------------------------------------------------------------------------
 * getTariffConnectionPopupData В tariffConnect/toggleTariffConnectionPopup;
 * -------------------------------------------------------------------------
 * toggleTariffConnectionPopup В tariffConnect/toggleTariffConnectionPopup;
 * -------------------------------------------------------------------------
 * changeTariff В tariffConnect/changeTariff;
 * -------------------------------------------------------------------------
 * replaceDownsellTariff В downsellTariff/replaceDownsellTariff;
 * -------------------------------------------------------------------------
 */

// После того, как будет протестирован и удален лишний код, надо удалить эту функуию.
// Отрефакториная функция getCurrentTariffData лежит в дерриктории tariffConnect/tariffConnectionAnalytics;
const getCurrentTariffData = () => {
  const state = store.getState();
  const currentTariff = path(['external', 'tariff', 'data'], state);

  return {
    currentTariffName: currentTariff.currentTariffName,
    currentTariffSoc: currentTariff.currentTariffSoc,
    ctn: pathOr('', ['external', 'currentCtn', 'ctn'], state),
  };
};

export const trackADSEvent = (action) => (dispatch, getState) => {
  const adsData = path(['external', 'tariffConnectionPopup', 'data', 'adsButtons'], getState());

  if (adsData) {
    axios.post(
      action === 'accept' ? adsData.changeTariffButtonTrackUrl : adsData.cancelButtonTrackUrl,
    );
  }
};

/**
 * Reset SMS login form data.
 */
export const resetSmsForm = () => (dispatch) => {
  dispatch(setConfirmPopup({ tariff: false }));
  dispatch({
    type: SET_TARIFF_CONNECTION_POPUP,
    payload: {
      step: 'smsFormPhone',
      error: {},
    },
  });
};

/**
 * Show error pop-up.
 * @param error {object} Error data object.
 */
const showError =
  (error = {}) =>
  (dispatch) => {
    const defaultErrorPopup = path(['external', 'tariff', 'data', 'errorPopup'], store.getState());
    dispatch({
      type: SET_CONNECTION_RESULT_POPUP,
      payload: {
        ...defaultErrorPopup,
        ...error,
      },
    });
    dispatch(setTariffConnectionPopup({ isPending: false, isSubmitting: false }));
  };

/**
 * Get name for SIM order method.
 * @param isMnp {boolean} True, if MNP request.
 * @param includeNumber {boolean} True, if include fancy number in request.
 * @returns {string} Order method name.
 */
const getOrderMethodName = (isMnp = false, includeNumber = false) => {
  if (isMnp) return 'MNP';
  if (includeNumber) return 'SimNumber';
  return 'Sim';
};

/**
 * Check if number is Beeline.
 * @param phone {string} Number without '+7' and formatting.
 * @param checkUrl {url} Check number URL.
 * @returns {AxiosPromise}
 */
export const checkPhone = (phone, checkUrl = null) => {
  const url =
    checkUrl ||
    path(
      ['external', 'tariffConnectionPopup', 'data', 'smsConfirmationForm', 'checkNumberUrl'],
      store.getState(),
    );
  return axios.get(`${url}?number=${phone}`);
};

/**
 * Show captcha in SIM order form (new SIM or MNP).
 * @param isMnp {boolean} True if MNP request.
 * @param isCaptchaError {boolean} True if is captcha error;
 */
const showOrderCaptcha = (isMnp, isCaptchaError) => (dispatch) => {
  const data = path(['external', 'tariffConnectionPopup', 'data'], store.getState());

  const mnpSetup =
    isMnp ?
      {
        step: 'mnpWithCaptcha',
        popupIsActive: true,
      }
    : {};

  dispatch(
    setTariffConnectionPopup({
      isPending: false,
      ...mnpSetup,
      data: {
        ...data,
        simOrderForm: {
          ...data.simOrderForm,
          step: 'form',
          isCaptchaRequired: true,
          serverErrors:
            isCaptchaError ?
              {
                Captcha: { status: 'fail', msg: 'Введите символы', touched: true },
              }
            : {},
        },
      },
    }),
  );
};

export const mapConfirmNumberPopup = (smsForm) => ({
  ...smsForm,
  content: `${smsForm.smsWasSentText || ''} ${smsForm.paymentNotice || ''}`,
});

/**
 * Check phone number and request one-time password (number is beeline) or MNP (no-beeline).
 * @param phone {string} Formatted phone number.
 * @param captcha {string} Captcha code.
 * @param captchaKey {string} Captcha key.
 */
export const sendPhone =
  ({ phone, captcha, captchaKey }) =>
  async (dispatch) => {
    const state = store.getState();
    const data = path(['external', 'tariffConnectionPopup', 'data'], state);
    const url = path(['smsConfirmationForm', 'oneTimePasswordSmsUrl'], data);
    const checkRegionUrl = path(['smsConfirmationForm', 'checkRegionUrl'], data);

    const login = unformatPhone(phone);
    dispatch(setTariffConnectionPopup({ contactPhone: phone, phone, isPending: true, error: {} }));

    pushSmsFormEvent('submit');
    ymChangeTariff();

    /** Изменить свой тариф Клик на Отправить */
    mobTariffDetailSMSConnectYM(document.location.href);

    try {
      let resp = { data: { view: {} } };
      let sameRegion = true;

      if (checkRegionUrl) {
        resp = await axios.get(
          `${checkRegionUrl}${checkRegionUrl.indexOf('?') > -1 ? '&' : '?'}phone=${login}`,
          {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          },
        );
        sameRegion = resp.data.isSucceeded;
      }

      if (sameRegion) {
        // Send request for one time password
        try {
          await axios({
            url,
            method: 'get',
            params: { login, hash: generateGUID(), captcha, captchaKey },
            transformRequest,
          });
          dispatch(
            setConfirmPopup({
              ...mapConfirmNumberPopup(data.smsConfirmationForm),
              ...resp.data.view,
              phone,
              tariff: true,
            }),
          );
          dispatch(setTariffConnectionPopup({ isPending: false }));
          pushSmsFormEvent('success');
          YMAuthEvent.clickAuthButton(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS, AUTH_YM_ACTIONS.SEND_SMS);
          YMAuthEvent.visible(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS, AUTH_YM_ACTIONS.STEP_INPUT_CODE);
        } catch ({ response }) {
          const code = response && response.data && response.data.ErrorCode;
          dispatch(setTariffConnectionPopup({ ...getAuthErrorByCode(code), isPending: false }));
          pushSmsFormEvent('error');
          if (code === 'NEED_CAPTCH') {
            YMAuthEvent.visible(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS, AUTH_YM_ACTIONS.STEP_CAPTCHA);
          } else if (code === 'ERROR_CAPTCH') {
            YMAuthEvent.error(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS, AUTH_YM_ACTIONS.ERROR_CAPTCHA);
          }
        }
      } else {
        const view = resp.data && resp.data.view;
        dispatch(showError({ ...view, step: 'submitLogin', isWrongRegionError: true }));
        pushNumberErrorEvent();
        pushSmsFormEvent('error');
        YMAuthEvent.error(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS, AUTH_YM_ACTIONS.VISIBLE_WRONG_REGION);
      }
    } catch ({ response, message }) {
      if (response && response.data && response.data.view) {
        dispatch(showError(response.data.view));
      } else {
        dispatch(showError());
      }
      pushSmsFormEvent('error');
      YMAuthEvent.error(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS, {
        error: response?.data?.ErrorCode || message,
      });
    }
  };

export const sendLogin = (form, formCallback) => async (dispatch) => {
  const state = store.getState();
  const data = path(['external', 'tariffConnectionPopup', 'data'], state);
  const checkRegionUrl = path(['loginForm', 'checkRegionUrl'], data);

  const { login } = form;
  const phone = login[0] === '9' ? formatPhone(login) : login;
  dispatch(
    setTariffConnectionPopup({
      contactPhone: phone,
      phone,
      isSubmitting: true,
    }),
  );

  try {
    let sameRegion = true;
    let resp = { data: { view: {} } };

    if (checkRegionUrl) {
      resp = await axios.get(
        `${checkRegionUrl}${checkRegionUrl.indexOf('?') > -1 ? '&' : '?'}login=${login}`,
        {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        },
      );
      sameRegion = resp.data.isSucceeded;
    }

    if (sameRegion) {
      formCallback();
    } else {
      let view = resp.data && resp.data.view;
      if (view.buttonUrl) {
        dispatch(
          setTariffConnectionPopup({
            data: {
              ...data,
              loginForm: {
                ...data.loginForm,
                returnUrl: view.buttonUrl,
                login: form.login,
                password: form.password,
              },
            },
            isSubmitting: false,
          }),
        );
        view = { ...view, buttonUrl: null, step: 'submitLogin', isWrongRegionError: true };
      }
      dispatch(showError(view));
      YMAuthEvent.visible(
        AUTH_YM_EVENTS.CHANGE_TARIFF_PASSWORD,
        AUTH_YM_ACTIONS.VISIBLE_WRONG_REGION,
      );
    }
  } catch ({ response, message }) {
    if (response && response.data && response.data.view) {
      dispatch(showError(response.data.view));
    } else {
      dispatch(showError());
    }
    YMAuthEvent.error(AUTH_YM_EVENTS.CHANGE_TARIFF_PASSWORD, {
      error: response?.data?.ErrorCode || message,
    });
  }
};

/**
 * Make new SIM order request.
 * @param contactNumber {string} Formatted contact phone number.
 * @param phone {string} Formatted chosen number.
 * @param includeNumber {boolean} True if chosen fancy number.
 * @param captcha {string} Captcha code.
 * @param captchaKey {string} Captcha key.
 * @param isMnp {boolean} True if MNP request.
 * @param selfregAction {boolean} Значение чекбокса (для акции при покупки SIM).
 * @param delivery {object} Данные о доставке
 */
const makeOrder =
  ({
    contactNumber,
    phone,
    includeNumber,
    captcha,
    captchaKey,
    orderPlace,
    selfregAction,
    isForYoung,
    additionalSocs,
    delivery = {},
    isMnp = false,
    optionSocs,
    constructorId,
  }) =>
  (dispatch) => {
    const state = store.getState();
    const id = path(['external', 'tariff', 'data', 'id'], state);
    const data = path(['external', 'tariffConnectionPopup', 'data'], state);
    const url = path(['simOrderForm', 'simOrderUrl'], data);
    const additionalOptions = pathOr(
      {},
      ['external', 'tariffConnectionPopup', 'additionalOptions'],
      state,
    );
    const tariffData = pathOr({}, ['external', 'tariff', 'data'], state);
    const promocode = pathOr({}, ['external'], state);
    const tariffId = id;
    const { tariffName } = data;
    const { soc } = data;
    const { marketCode } = data;

    dispatch(
      setTariffConnectionPopup({
        data: {
          ...data,
          simOrderForm: {
            ...data.simOrderForm,
            step: isMnp ? 'form ' : 'loading',
          },
        },
        phone: contactNumber,
        orderedFancyNumber:
          typeof phone === 'string' && includeNumber ?
            formatPhoneWithDefaultPrefix(phone)
          : undefined,
      }),
    );

    let sendObject = {
      number: typeof phone === 'string' && includeNumber ? unformatPhone(phone) : undefined,
      contactNumber: unformatPhone(contactNumber),
      tariffId,
      isForYoung,
      additionalSocs,
      Captcha: captcha || undefined,
    _captcha_key: captchaKey, // eslint-disable-line
      gaClientId: getGAClientId(),
      isMnp,
      orderPlace,
      optionSocs,
      constructorId,
      ...delivery,
    };

    if (additionalOptions?.additionalSimCount) {
      sendObject.additionalSimCount = additionalOptions.additionalSimCount;
    }

    if (
      (data?.isForYoungDetailEnabled || tariffData?.isForYoungDetailEnabled) &&
      additionalOptions?.chosenSocs
    ) {
      sendObject.additionalSocs = additionalOptions.chosenSocs;
      if (additionalOptions.promoSoc) {
        sendObject.additionalSocs = `${sendObject.additionalSocs},${additionalOptions?.promoSoc}`;
      }
      if (promocode?.promocode) {
        sendObject.promocode = promocode.promocode;
      }
    }

    if (selfregAction) {
      sendObject = {
        ...sendObject,
        selfregAction: true,
      };
    }

    axios
      .post(url, qs.stringify(sendObject), { withCredentials: true })
      .then((resp) => {
        if (resp.data.isSucceeded) {
          dispatch({
            type: SET_CONNECTION_RESULT_POPUP,
            payload: {
              ...resp.data.view,
              emailSubscription: true,
            },
          });

          if (includeNumber && phone) {
            dispatch(removeUsedNumber(phone));
          }

          if (isMnp) {
            pushMnpSuccessEvent({
              orderId: resp.data.view.orderId,
              shipping: delivery && delivery['delivery.type'],
            });
          } else {
            const { orderId, price } = resp.data.view;
            pushSimOrderEvent({
              orderId,
              price,
              tariffName,
              soc,
              marketCode,
              shipping: delivery && delivery['delivery.type'],
              method: getOrderMethodName(isMnp, includeNumber),
            });

            trackCPAEvent({
              orderId,
              price,
              type: 'Tariff',
              soc,
            });
          }
          return;
        }

        if (resp.data.IsCaptchaRequired || resp.data.IsCaptchaError) {
          dispatch(showOrderCaptcha(isMnp, resp.data && resp.data.IsCaptchaError));
          return;
        }

        dispatch(setTariffConnectionPopup({ data: null, isPending: false }));
        dispatch(showError(resp.data.view));

        if (isMnp) {
          pushMnpErrorEvent();
        } else {
          if (includeNumber && phone) {
            dispatch(removeUsedNumber(phone));
          }
          pushSimOrderErrorEvent({
            error:
              resp.data.view ?
                resp.data.view.content.replace('{{number}}', '').replace('{{contactNumber}}', '')
              : '',
            tariffName,
            soc,
            marketCode,
            method: getOrderMethodName(isMnp, includeNumber),
          });
        }
      })
      .catch(({ resp }) => {
        dispatch(setTariffConnectionPopup({ data: null, isPending: false }));
        dispatch(showError());

        pushSimOrderErrorEvent({
          error:
            resp && resp.data.view ?
              resp.data.view.content.replace('{{number}}', '').replace('{{contactNumber}}', '')
            : '',
          tariffName,
          soc,
          marketCode,
          method: getOrderMethodName(isMnp, includeNumber),
        });
      });
  };

/**
 * Make new SIM order.
 * @param contactNumber {string} Formatted contact phone number.
 * @param phone {string} Formatted chosen fancy number.
 * @param includeNumber {boolean} True, if include chosen number in request.
 * @param captcha {string} Captcha code.
 * @param captchaKey {string} Captcha key.
 * @param sameRegion {boolean} True, if number from same region.
 * @param orderPlace {string} Place, where this form uses
 * @param selfregAction {string} Self registration checkbox
 */
export const orderSim = (object) => (dispatch) => {
  dispatch(makeOrder({ ...object }));
};

/**
 * Check number and make postpaid request.
 * @param phone {string} Formatted phone number.
 */
export const connectPostpaidTariff = (phone) => (dispatch) => {
  const data = path(['external', 'tariffConnectionPopup', 'data'], store.getState());
  const url = path(['postpaidForm', 'checkNumberUrl'], data);

  dispatch(setTariffConnectionPopup({ phone, isPending: true }));

  checkPhone(unformatPhone(phone), url)
    .then((resp) => {
      if (resp) {
        const isBeeline = resp.data;
        if (isBeeline) {
          dispatch({
            type: SET_CONNECTION_RESULT_POPUP,
            payload: {
              ...data.postpaidForm.successPopup,
              type: 'postpaid',
            },
          });
          YMAuthEvent.authSuccess(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS);
        } else {
          dispatch(
            setTariffConnectionPopup({
              isPending: false,
              data: {
                ...data,
                postpaidForm: {
                  ...data.postpaidForm,
                  error: { msg: data.postpaidForm.errorText, type: 'CTN_NOT_FOUND' },
                },
              },
            }),
          );
        }
      } else {
        dispatch(showError());
      }
    })
    .catch(({ message }) => {
      dispatch(showError());
      YMAuthEvent.error(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS, { error: message });
    });
};

/**
 * Clear and hide result pop-up.
 */
export const resetResultPopup = () => (dispatch) => {
  dispatch({
    type: SET_CONNECTION_RESULT_POPUP,
    payload: null,
  });
};

/**
 * Show/hide waiting pop-up.
 * @param opened {boolean} True, if need show.
 * @param pending {boolean} True, if it's pending.
 */
export const toggleWaitingPopup =
  (opened = false, pending = false) =>
  (dispatch) => {
    const data = path(['external', 'tariffConnectionPopup', 'data'], store.getState());

    dispatch(
      setTariffConnectionPopup({
        isPending: false,
        data: {
          ...data,
          waitingPopup: {
            ...data.waitingPopup,
            step: pending ? 'pending' : 'main',
            opened,
          },
        },
      }),
    );
  };

/**
 * Show/hide pop-up with result of tariff change.
 * @param opened {boolean} True is need show.
 */
export const toggleChangeResultPopup =
  (opened = false) =>
  (dispatch) => {
    dispatch({
      type: SET_TARIFF_CHANGE_RESULT_POPUP,
      payload: { opened },
    });
  };

const setWaitingPopup = (data) => (dispatch) => {
  const state = store.getState();
  const connectionPopup = path(['external', 'tariffConnectionPopup'], state);
  const connectData = connectionPopup.data;
  const { waitingPopup } = connectData;

  dispatch(
    setTariffConnectionPopup({
      isPending: false,
      data: {
        ...connectData,
        waitingPopup: { ...waitingPopup, ...data },
      },
    }),
  );
};

export const setChangeResultPopup = (data) => ({
  type: SET_CONNECTION_RESULT_POPUP,
  payload: data,
});

export const resetDownsell = (dispatch) => {
  dispatch(
    setTariffConnectionPopup({
      downSellAlias: null,
      downsellStep: null,
      downSellPayload: null,
    }),
  );
};

/**
 * Check tariff connection status.
 * @param requestId {number} ID of tariff request.
 * @param isFinalCheck {boolean} True, if check is final.
 */
export const checkTariffRequestState =
  (requestId, isFinalCheck = true) =>
  (dispatch) => {
    const state = store.getState();
    const connectionPopup = path(['external', 'tariffConnectionPopup'], state);
    const connectData = connectionPopup.data;
    const isDownsell = connectionPopup.downSellAlias;
    const constructorId = path(['external', 'tariffConstructor', 'constructorId'], state);
    const marketCode = path(['external', 'tariff', 'data', 'marketCode'], state);
    const { soc, tariffName, updateTariffUp } = connectData;
    const url = path(['changeTariffForm', 'checkRequestStateUrl'], connectData);
    const currentTariffName = path(['external', 'tariff', 'data', 'currentTariffName'], state);
    const newTariffName = state?.external?.tariffConnectionPopup?.data?.tariffName;
    const { isConnectUpConvergence, requestConvergenceParams } = connectData;

    let data = {};

    if (connectData.layoutType === 'constructor') {
      data = {
        requestId,
        ServiceName: tariffName,
        Soc: soc,
        IsForceSuccess: true,
        updateTariffUp,
      };
    } else {
      data = {
        requestId,
        ServiceName: tariffName,
        Soc: soc,
      };
    }

    axios({
      url,
      method: 'post',
      data,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((response) => {
        const respData = response.data;

        if (respData.isSucceeded) {
          // eslint-disable-next-line no-shadow
          const data = respData.view;
          if (
            data.requestStatus === 'COMPLETE' ||
            (isFinalCheck && data.requestStatus === 'IN_PROGRESS')
          ) {
            if (constructorId) {
              // NOTE: Необходимо передать событие о том, что подключение прошло
              // TariffRequestSubject.next({ type: 'connect', isSuccessful: true });
              dispatch(resetSmsForm());
              dispatch(resetDownsell);
              dispatch(toggleTariffConnectionPopup(false));
              dispatch(toggleConstructor);
              dispatch(setConstructorStep('connected'));
              return;
            }

            if (isConnectUpConvergence) {
              postConnectTariff(requestConvergenceParams).then(
                ({ isSuccessed, isSucceeded, content, isExistOrder, hasFttbConflicts }) => {
                  let modalContent;
                  if (isExistOrder) {
                    modalContent = content[ModalKeys.hasOpenRequest];
                  } else if (isSuccessed || isSucceeded) {
                    if (hasFttbConflicts) {
                      modalContent = content[ModalKeys.fttbConflictsConnection];
                      submitCallbackRequest({
                        phoneNumber: requestConvergenceParams?.ctn,
                        caseTitle: 'sale',
                      });
                    } else {
                      modalContent = content[ModalKeys.tariffConnection];
                    }
                  } else if (!isSuccessed) {
                    modalContent = content[ModalKeys.somethingWentWrong];
                  }
                  dispatch(
                    setTariffConnectionPopup({ currentChangeConvergenceTariff: modalContent }),
                  );
                  dispatch(
                    recordPayload(SET_TARIFF_CONNECTION_POPUP, {
                      isPending: false,
                      data: { isConvergence: true },
                      resultPopupData: { requestStatus: 'COMPLETE' },
                    }),
                  );
                },
              );
            } else {
              dispatch(
                setWaitingPopup({
                  result: data,
                  opened: true,
                  step: 'result',
                }),
              );
            }

            pushMobileTariffConnectEvent({
              ctn: data.ctn,
              tariffName,
              soc,
              marketCode,
              orderId: data.orderId,
              isOrder: data.requestStatus === 'IN_PROGRESS',
              ...getCurrentTariffData(),
            });

            if (isDownsell) {
              pushTariffDownsellConnectSuccessEvent({
                downsellTariffName: tariffName,
                downsellSoc: soc,
                downsellMarketCode: marketCode,
              });
            }

            // Отправка ГА в случае успешного ухода с Яндекс Тариф
            if (currentTariffName === CURRENT_TARIFF_NAME) {
              gaYandexTariffDisconnected();
            }

            // Отправка Yandex Metrika в случае успешного ухода с тарифа
            yandexTariffDisconnectedYM(document.location.href, currentTariffName);

            // Отправка аналитики при успешном переходе на новый тариф
            yandexTariffConnectedSuccessYM(document.location.href, newTariffName);
          } else if (data.requestStatus !== 'IN_PROGRESS') {
            dispatch(
              setWaitingPopup({
                result: data,
                opened: true,
                step: 'result',
              }),
            );
          }
        } else {
          pushTariffConnectionErrorEvent({
            error: respData && respData.view && respData.view.content,
            tariffName: connectData.tariffName,
            marketCode: connectData.marketCode,
            soc: connectData.soc,
            ...getCurrentTariffData(),
          });
          dispatch(
            setWaitingPopup({
              result: respData.view,
              opened: true,
              step: 'result',
            }),
          );
        }
      })
      .catch(({ response }) => {
        pushTariffConnectionErrorEvent({
          error: response && response.data && response.data.view && response.data.view.content,
          tariffName: connectData.tariffName,
          marketCode: connectData.marketCode,
          soc: connectData.soc,
          ...getCurrentTariffData(),
        });
        dispatch(toggleWaitingPopup(false));
        dispatch(showError(response && response.data ? response.data.view : {}));
      });
  };

export const showWrongRegionPopup = (previousRegion) => (dispatch, getState) => {
  const state = getState();
  const { connectPopupUrl, alias, soc } = pathOr({}, ['external', 'tariff', 'data'], state);
  dispatch(initConnectionPopup(connectPopupUrl, alias, soc));
  dispatch(setTariffConnectionPopup({ data: null, isPending: true }));
  dispatch(toggleConnectPopup(true));
  fetchWrongRegionPopup(TARIFF_WRONG_REGION_API, {
    tariffAlias: alias,
    previousRegion,
  })
    .then(
      (data) =>
        data?.view &&
        dispatch(
          showError({
            ...data.view,
            type: 'tariffWrongRegion',
          }),
        ),
    )
    .catch((error) => console.error(error));
};

export const openTariffDescriptionPopup = (isOpen) => ({
  type: OPEN_TARIFF_DESCRIPTION_POPUP,
  payload: isOpen,
});

export const onClosePopup = (dispatch) => {
  dispatch(resetSmsForm());
  dispatch(resetDownsell);
  dispatch(toggleConnectPopup(false));
  dispatch(toggleOrderPaymentForm(false));
};

export const onDownsellPopupClose = (dispatch, getState) => {
  const downsellData = pathOr({}, ['external', 'tariffConnectionPopup', 'data'], getState());

  // eslint-disable-next-line default-case
  switch (downsellData.type) {
    case 'question':
      dispatch(replaceDownsellTariff(downsellData.buttonResetUrl, null, {}));
      break;
    case 'callBackOk':
      dispatch(toggleConnectPopup(false));
      break;
  }
};

export const onConfirmPopupClose = (dispatch) => {
  dispatch(trackADSEvent('reject'));
  dispatch(onClosePopup);
};

export const onResultPopupClose = (openTariffConnectPopupAgain) => (dispatch, getState) => {
  const connectData = pathOr({}, ['external', 'tariffConnectionPopup'], getState());

  if (connectData.canRetry) {
    dispatch(replaceDownsellTariff(null, null, null, connectData.canRetry));
  } else if (openTariffConnectPopupAgain) {
    dispatch({
      type: SET_CONNECTION_RESULT_POPUP,
      payload: null,
    });
  } else {
    dispatch(onClosePopup);
  }
};

export const setAdditionalSimCount = (count) => (dispatch) => {
  const additionalOptions = pathOr(
    {},
    ['external', 'tariffConnectionPopup', 'additionalOptions'],
    store.getState(),
  );
  dispatch({
    type: SET_ADDITIONAL_SIM_COUNT,
    payload: {
      ...additionalOptions,
      additionalSimCount: count,
    },
  });
};

export const handleChosenSocs = (socs) => (dispatch) => {
  const additionalOptions = pathOr(
    {},
    ['external', 'tariffConnectionPopup', 'additionalOptions'],
    store.getState(),
  );
  dispatch({
    type: SET_CHOSEN_SOCS,
    payload: {
      ...additionalOptions,
      chosenSocs: socs,
    },
  });
};

export const showErrorPopup = (params) => (dispatch) => {
  dispatch(setTariffConnectionPopup({ data: null, isPending: false }));
  dispatch(showError(params));
};

export const sendEsimPurchaseEvent = (_, getState) => {
  const { subscriptionFee, id } = getState().external.tariff.data;
  slAddToCart({
    price: subscriptionFee,
    productId: id,
  });
};
