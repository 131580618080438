import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';

// Utils
import { translate } from 'utils/format-string';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ShowMoreButton = ({ number, onClick }) => (
  <div className={cx('showMoreButton')}>
    <EmptyButton className={cx('button')} onClick={onClick}>
      {`Еще ${number} ${translate('tariff', number)} ›`}
    </EmptyButton>
  </div>
);

ShowMoreButton.propTypes = {
  number: PropTypes.number,
  onClick: PropTypes.func,
};

export default ShowMoreButton;
