import {
  EConstructorStep,
  EUserStoryType,
  TConstructorSteps,
} from '../store/slices/stepperSlice/types';

/** Все сценарии в НЗ */
/** Без ШПД */
const withoutFTTBSteps: TConstructorSteps = [EConstructorStep.constructor];

/** С ШПД */
const FTTBSteps: TConstructorSteps = [
  EConstructorStep.constructor,
  EConstructorStep.fttbAddress,
  EConstructorStep.fttbSettings,
  EConstructorStep.specialistCall,
  EConstructorStep.finalBill,
  EConstructorStep.finalPage,
];

/** ШПД от партнёров */
const PartnerFmcSteps: TConstructorSteps = [
  EConstructorStep.constructor,
  EConstructorStep.fttbCallbackRequest,
  EConstructorStep.finalBill,
  EConstructorStep.finalPage,
];

/** Все сценарии в АЗ */

/** Тариф не подключен, БЕЗ ШПД  */
const AuthNotConnectedWithoutFTTBSteps: TConstructorSteps = [
  EConstructorStep.constructor,
  EConstructorStep.finalBill,
  EConstructorStep.finalPage,
];

/** Тариф НЕ подключен и добавляется настройка ШПД */
const AuthNotConnectedWidthFttbSteps: TConstructorSteps = [
  EConstructorStep.constructor,
  EConstructorStep.fttbAddress,
  EConstructorStep.fttbSettings,
  EConstructorStep.specialistCall,
  EConstructorStep.finalBill,
  EConstructorStep.finalPage,
];

// TODO: После finalBill требуется НЕ переходить в корзину,
//  а вызывать метод подключения тарифа. Доработать метод в кнопке finalBill по UserStoryType.
/** Тариф ПОДКЛЮЧЕН БЕЗ ШПД */
const AuthConnectedWithoutFTTBSteps: TConstructorSteps = [
  EConstructorStep.constructor,
  EConstructorStep.tariffConnectChange,
  EConstructorStep.finalBill,
  EConstructorStep.finalPage,
];

/** Тариф ПОДКЛЮЧЕН, добавляется ШПД */
const AuthConnectedWithFTTBSteps: TConstructorSteps = [
  EConstructorStep.constructor,
  EConstructorStep.fttbAddress,
  EConstructorStep.fttbSettings,
  EConstructorStep.specialistCall,
  EConstructorStep.finalBill,
  EConstructorStep.finalPage,
];

/** Тариф ПОДКЛЮЧЕН С АКТИВНЫМ ШПД (конвергент) */
const AuthConnectedSettingsFTTBSteps: TConstructorSteps = [
  EConstructorStep.constructor,
  /* Промежуточный шаг при клике на настроить fttbConnectedSettings */
  EConstructorStep.tariffConnectChange,
  /* При смене оборудования перейдем на finalPage, проигнорировав finalBill */
  EConstructorStep.finalBill,
  EConstructorStep.finalPage,
];

// TODO: После finalBill требуется НЕ переходить в корзину,
//  а вызывать метод подключения тарифа. Доработать метод в кнопке finalBill по UserStoryType.
/** Пользователь покупает новую SIM (SimOrderForm) в АЗ, БЕЗ добавления ШПД */
const AuthNewSimNotConnectedWithoutFTTBSteps: TConstructorSteps = [
  // TODO: Для АЗ уже изменена логика в dev по отбрасыванию finalBill. После ребейза шаг finalBill уйдет!
  EConstructorStep.constructor,
  EConstructorStep.finalBill,
];

/** Пользователь покупает новую SIM (SimOrderForm) в АЗ, С добавлением ШПД */
const AuthNewSimNotConnectedWithtFTTBSteps: TConstructorSteps = [
  EConstructorStep.constructor,
  EConstructorStep.fttbAddress,
  EConstructorStep.fttbSettings,
  EConstructorStep.specialistCall,
  EConstructorStep.finalBill,
  EConstructorStep.finalPage,
];

// TODO: ни в ТЗ, ни в макетах нет того, что должно произойти при попытке юзера кликнуть на "Далее"!
/** Переход из более ранних архивных версий конвергента (Близкие люди+, Для тебя и дома и прочие) в новый UP */
const TariffUpTransitionFromArchiveTariffSteps: TConstructorSteps = [
  EConstructorStep.constructor,
  EConstructorStep.tariffConnectChange,
  EConstructorStep.finalPage,
];

/** Старый тариф АП, где недоступно ШПД */
// TODO: ни в ТЗ, ни в макетах нет того, что должно произойти при попытке юзера кликнуть на "Далее"!
const TariffUpArchiveWithoutFTTBSteps: TConstructorSteps = [
  EConstructorStep.constructor,
  EConstructorStep.tariffConnectChange,
  EConstructorStep.finalBill,
];

/** Общие сценарии на тарифе */
/** Сценарий перехода со своим номером */
const MnpSteps: TConstructorSteps = [
  EConstructorStep.constructor,
  EConstructorStep.mnp,
  EConstructorStep.finalBill,
];

/** Объект с сопоставлением сценариев пользователей и их шагов конструктора.
 * Все заведенные пользовательские сценарии обязаны иметь сопоставление с шагами, ts напомнит об этом ошибкой */
export const userSteps: Record<EUserStoryType, TConstructorSteps> = {
  /** Все сценарии в НЗ */

  [EUserStoryType.notAuthWithFTTB]: FTTBSteps,
  [EUserStoryType.notAuthWithoutFTTB]: withoutFTTBSteps,
  [EUserStoryType.tariffUpWithPartnerFTTB]: PartnerFmcSteps,

  /** Все сценарии в АЗ */

  [EUserStoryType.authNotConnectedWithoutFTTB]: AuthNotConnectedWithoutFTTBSteps,
  [EUserStoryType.authNotConnectedWithFTTB]: AuthNotConnectedWidthFttbSteps,
  [EUserStoryType.authConnectedWithoutFTTB]: AuthConnectedWithoutFTTBSteps,
  [EUserStoryType.authConnectedWithFTTB]: AuthConnectedWithFTTBSteps,
  [EUserStoryType.authConnectedSettingsFTTB]: AuthConnectedSettingsFTTBSteps,
  [EUserStoryType.authNewSimNotConnectedWithoutFTTB]: AuthNewSimNotConnectedWithoutFTTBSteps,
  [EUserStoryType.authNewSimNotConnectedWithFTTB]: AuthNewSimNotConnectedWithtFTTBSteps,
  [EUserStoryType.tariffUpTransitionFromArchiveTariff]: TariffUpTransitionFromArchiveTariffSteps,
  [EUserStoryType.tariffUpArchiveWithoutFTTB]: TariffUpArchiveWithoutFTTBSteps,

  /** Общие сценарии на тарифе */

  [EUserStoryType.mnp]: MnpSteps,
};
