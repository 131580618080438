import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { axiosBaseRequest } from '@beef/utils';
import { Button, Link, Text } from '@beef/ui-kit';

import { AUTH_STEPS, MESSAGES } from '../../actions/constants';
import Progress from '../Progress';
import BeautyCtn from '../BeautyCtn/BeautyCtn';
import {
  POLL_INTERVAL,
  POLL_STATUSES,
  REQUEST_TIMEOUT,
  getProgressColor,
} from '../../utils/mobileId';
import { parseCtn } from '../../utils/ctn-string';
import { onResetForm, onStepChange, onSwitchForm } from '../../actions/authorization';
import styles from './styles.pcss';
import { YMAuthEvent } from '../../utils/ym';

const cx = classNames.bind(styles);

class MobileIdForm extends PureComponent {
  constructor(props) {
    super(props);
    const timer = props.mobileIdData.content.form.timer || REQUEST_TIMEOUT;
    this.state = {
      timer,
      timeLeft: timer,
      error: '',
      pending: false,
      progressBg: getProgressColor(REQUEST_TIMEOUT),
      interval: null,
      pollTimeout: null,
    };
  }

  componentDidMount() {
    this.setTimer();
    this.pollAuth();
  }

  componentDidUpdate(prevProps) {
    if (this.props.mobileIdToken && prevProps.mobileIdToken !== this.props.mobileIdToken) {
      // начинаем опрашивать бекенд, авторизовался ли пользователь
      this.pollAuth();
    }
  }

  componentWillUnmount() {
    this.props.setMobileIdToken();
    this.clearIntervals();
  }

  setTimer = () => {
    const interval = setInterval(() => {
      if (this.state.timeLeft >= 1) {
        this.setState((prevState) => ({
          timeLeft: prevState.timeLeft - 1,
          progressBg: getProgressColor(prevState.timeLeft - 1),
        }));
      } else {
        clearInterval(interval);
      }
    }, 1000);

    this.setState({
      interval,
    });
  };

  clearIntervals = () => {
    clearInterval(this.state.interval);
    clearInterval(this.state.pollTimeout);
  };

  pollAuth = async () => {
    const {
      mobileIdToken,
      bisUrl,
      bisIncludeCredentials,
      mobileIdData: { connectUrlTemplate },
    } = this.props;

    if (this.state.timeLeft <= 0 || !mobileIdToken) {
      this.clearIntervals();
      YMAuthEvent.mobileIdPushTimeout();
      return;
    }

    this.setState({
      error: '',
    });

    try {
      const res = await axiosBaseRequest({
        url: `${bisUrl}/identity/mobileid/status?mobileidtoken=${mobileIdToken}`,
        withCredentials: bisIncludeCredentials,
        transformRequest: [
          (payload, headers) => {
            delete headers.common['X-Requested-With'];
            delete headers['X-Requested-With'];
            delete headers['X-Request-Id'];
            delete headers['X-Request-Sign'];
            delete headers['X-Request-Timestamp'];
            return payload;
          },
        ],
      });

      if (res?.status === POLL_STATUSES.Succeeded) {
        YMAuthEvent.mobileIdSuccess();
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
          document.location.href = connectUrlTemplate.replace('MOBILE_ID_TOKEN', mobileIdToken);
          return;
        }
      }

      if (
        !res?.status ||
        res?.status === POLL_STATUSES.Failed ||
        res?.status === POLL_STATUSES.NotStarted
      ) {
        this.clearIntervals();
        YMAuthEvent.mobileIdPushError(MESSAGES.AUTH_MOBILE_ID_DEFAULT_ERROR);
        this.setState({
          error: MESSAGES.AUTH_MOBILE_ID_DEFAULT_ERROR,
          timeLeft: REQUEST_TIMEOUT,
          pending: false,
          progressBg: getProgressColor(REQUEST_TIMEOUT),
        });
        return;
      }

      if (res?.status === POLL_STATUSES.InProgress && this.state.timeLeft > 0) {
        const pollTimeout = setTimeout(() => {
          this.pollAuth();
        }, POLL_INTERVAL);

        this.setState({
          pollTimeout,
        });
      }
    } catch (e) {
      this.setState({
        error: MESSAGES.AUTH_MOBILE_ID_DEFAULT_ERROR,
      });
    }
  };

  handleLinkPasswordClick = (event) => {
    event.preventDefault();
    YMAuthEvent.clickTypePassword();
    YMAuthEvent.mobileIdPasswordAuthClick();
    this.props.switchForm(AUTH_STEPS.password);
  };

  handleLinkSmsClick = (event) => {
    event.preventDefault();
    YMAuthEvent.authTypeSMS();
    YMAuthEvent.mobileIdSmsAuthClick();
    this.props.submitCTN(this.props.ctn);
    this.props.switchForm(AUTH_STEPS.smsCode);
  };

  repeatAuth = async () => {
    const { ctn, setMobileIdToken, bisUrl, bisIncludeCredentials } = this.props;

    this.setState({
      pending: true,
      error: '',
    });

    YMAuthEvent.mobileIdPushRepeat();
    setMobileIdToken();

    try {
      const res = await axiosBaseRequest({
        method: 'post',
        url: `${bisUrl}/identity/mobileid`,
        data: { ctn: parseCtn(ctn).ctn },
        withCredentials: bisIncludeCredentials,
        transformRequest: [
          (payload, headers) => {
            delete headers.common['X-Requested-With'];
            delete headers['X-Requested-With'];
            delete headers['X-Request-Id'];
            delete headers['X-Request-Sign'];
            delete headers['X-Request-Timestamp'];
            return payload;
          },
        ],
      });

      if (res.validationPassed && res.mobileidtoken) {
        this.setState({
          timeLeft: REQUEST_TIMEOUT,
          progressBg: getProgressColor(REQUEST_TIMEOUT),
        });

        setMobileIdToken(res.mobileidtoken);
        this.setTimer();
      } else {
        this.setState({
          error: MESSAGES.AUTH_MOBILE_ID_DEFAULT_ERROR,
        });
      }
    } catch (e) {
      if (e?.response?.data?.ErrorCode === 'NEED_CAPTCH') {
        this.props.handleStepChange(AUTH_STEPS.mobileIdCaptcha);
        return;
      }

      this.setState({
        error: MESSAGES.AUTH_MOBILE_ID_DEFAULT_ERROR,
      });
    }

    this.setState({ pending: false });
  };

  handleResetForm = () => {
    this.props.resetForm();
    YMAuthEvent.mobileIdChangeNumber();
  };

  render() {
    const {
      mobileIdData: { content },
      ctn,
      loginType,
    } = this.props;

    const { error, pending, timeLeft, progressBg, timer } = this.state;

    return (
      <div className={cx('mobile-id-form')}>
        <Text size="size3-m">{content.form.head}</Text>
        <div className={cx('mobile-id-form__number')}>
          <BeautyCtn ctn={ctn} loginType={loginType} resetForm={this.handleResetForm} />
        </div>
        {error && (
          <>
            <div className={cx('mobile-id-form__text')}>
              <Text size="size5-r-s">{error}</Text>
            </div>
            <div className={cx('mobile-id-form__button')}>
              <Button
                disabled={pending}
                fullWidth
                onClick={this.repeatAuth}
                size="m"
                variant="secondary"
              >
                {content.form.repeatButton}
              </Button>
            </div>
          </>
        )}
        {!error && (
          <div className={cx('mobile-id-form__sending')}>
            {timeLeft > 0 ?
              <>
                <Progress
                  bg={progressBg}
                  className={cx('mobile-id-form__progress')}
                  percent={(timeLeft / timer) * 100}
                  text={`${timeLeft} сек`}
                />
                <div className={cx('mobile-id-form__text')}>
                  <Text size="size5-r-s">{content.form.text}</Text>
                </div>
              </>
            : <>
                <div className={cx('mobile-id-form__text')}>
                  <Text size="size5-r-s">{content.form.timeoutText}</Text>
                </div>
                <div className={cx('mobile-id-form__button')}>
                  <Button
                    disabled={pending}
                    fullWidth
                    onClick={this.repeatAuth}
                    size="m"
                    variant="secondary"
                  >
                    {content.form.repeatButton}
                  </Button>
                </div>
              </>
            }
          </div>
        )}
        <div className={cx('mobile-id-form__links')}>
          <div
            className={cx('mobile-id-form__choice', {
              'mobile-id-form__choice--error': !error && timeLeft === 0,
            })}
          >
            <Text size="size5-r-s">или</Text>
          </div>
          <div className={cx('mobile-id-form__link')}>
            <Link href="#" isNoUnderline onClick={this.handleLinkPasswordClick} size="size5-m">
              Войти с постоянным паролем
            </Link>
          </div>
          <Link href="#" isNoUnderline onClick={this.handleLinkSmsClick} size="size5-m">
            Получить пароль в SMS
          </Link>
        </div>
      </div>
    );
  }
}

MobileIdForm.defaultProps = {
  ctn: '',
  loginType: '',

  resetForm: () => {},
  setMobileIdToken: () => {},

  mobileIdData: {
    connectUrlTemplate:
      // eslint-disable-next-line max-len
      'https://bis.webtest.vimpelcom.ru/identity/connect/authorize/?client_id=quantumartapp_marge&response_type=id_token&redirect_uri=https:%2f%2fwebtest.vimpelcom.ru%2flogincallback&response_mode=form_post&scope=openid%20selfservice_identity%20usss_token%20profile&state={state}&nonce={nonce}&acr_values=idp%3amobileid+mobileidtoken%3aMOBILE_ID_TOKEN+return_unsuccessful_result%3atrue&prompt=login',
    content: {
      form: {
        head: 'Mobile ID',
        text: 'Сейчас на экране вашего телефона отобразится запрос на вход в личный кабинет.',
        timeoutText:
          'Время ожидания запроса истекло. Попробуйте ещё раз или войдите другим способом.',
        repeatButton: 'Повторить запрос',
      },
    },
  },
};

MobileIdForm.propTypes = {
  ctn: PropTypes.string,
  loginType: PropTypes.string,
  mobileIdToken: PropTypes.string,
  bisUrl: PropTypes.string,

  bisIncludeCredentials: PropTypes.bool,

  resetForm: PropTypes.func,
  setMobileIdToken: PropTypes.func,
  handleStepChange: PropTypes.func,
  switchForm: PropTypes.func,
  submitCTN: PropTypes.func,

  mobileIdData: PropTypes.shape({
    connectUrlTemplate: PropTypes.string,
    content: PropTypes.shape({
      form: PropTypes.shape({
        head: PropTypes.string,
        text: PropTypes.string,
        timeoutText: PropTypes.string,
        repeatButton: PropTypes.string,
      }),
    }),
  }),
};

export default connect(null, {
  switchForm: onSwitchForm,
  resetForm: onResetForm,
  handleStepChange: onStepChange,
})(MobileIdForm);
