import React, { FC } from 'react';

import ServiceCard from 'pages/ProductCard/Service';
import RedirectExtension from 'pages/ProductCard/components/RedirectService';
import RedirectConnection from 'pages/ProductCard/components/RedirectService/components/RedirectConnection';
import { useChoseVersionRedirectServiceCardLanding } from 'pages/Services/RedirectServiceCardLanding/hook';
import { SkeletonLayout } from 'pages/Services/RedirectServiceCardLanding/components/SkeletonLayout';
import { MainError } from 'pages/Services/RedirectServiceCardLanding/components/Status/MainError';

import { RedirectServiceCard } from './RedirectServiceCard';

export const RedirectServiceCardLanding: FC = (props) => {
  const { loading, contentQp } = useChoseVersionRedirectServiceCardLanding();

  if (loading === 'failed') {
    return <MainError />;
  }

  if (['idle', 'pending'].includes(loading)) {
    return <SkeletonLayout />;
  }

  if (contentQp?.isNewPereadresatsiya === false) {
    return (
      <ServiceCard
        {...props}
        connectButtonExtension={RedirectConnection}
        parametersExtension={RedirectExtension}
      />
    );
  }

  return (
    <RedirectServiceCard
      content={{
        contentDpc: props?.data,
        contentQp,
      }}
    />
  );
};
