import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const PowerName = ({ icon, title }) => (
  <div className={cx('wrapper')}>
    {icon && <img alt={`${title}-img`} loading="lazy" src={icon} />}
    {title && (
      <Text size="size5-m" tagType="h3">
        {title}
      </Text>
    )}
  </div>
);

PowerName.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
};
