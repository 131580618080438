import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'constructorV1/store';
import { selectIsAuthenticated } from 'constructorV1/store/selectors/selectUser';
import { useConstructorLink } from 'constructorV1/router/hooks/useConstructorLink';
import { useConnectBasket } from 'constructorV1/components/constructorPage/BillContainer/hooks/useConnectBasket';

/** Хук подготовки функции-обработчика клика по кнопке малого чека ЕК */
export const useBillButton = () => {
  const navigate = useNavigate();
  /** Хук подготовки функции отправки запроса в корзину */
  const { handleBasketSubmit } = useConnectBasket();
  /** Ссылка для перехода на страницу результирующего чека ЕК */
  const billUrl = useConstructorLink('bill');
  /** Флаг определяет, является ли текущий тариф ЕК подключенным */
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  /** Результирующая функция-обработчик клика по кнопке чека ЕК */
  return () => (isAuthenticated ? navigate(billUrl) : handleBasketSubmit());
};
