import { throwAsyncError } from 'utils/errors';
import { fetchFmcPresets } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/api/fmсPresets';
import {
  useAppDispatch,
  useAppSelector,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

import { useSelectErrorText } from '../../../selectors/useSelectErrorText';

/** Хук подготовки данных для блока повторной отправки запроса на получение ШПД-пресетов */
export const usePrepareErrorLoadingProps = () => {
  const dispatch = useAppDispatch();

  /** Текущий id региона пользователя */
  const regionId = useAppSelector((state) => state.common?.userRegion?.regionId) ?? 0;

  /** Сок активного персонажа и поколение тарифа */
  const { characterSoc, generation, tariffSoc } = useAppSelector((state) => state.total) || {};

  /** Текст в блок ошибки загрузки ШПД-пресетов */
  const errorLoadingText = useSelectErrorText();

  /** Для единого конструктора на шпд берется сок тарифа тк нет сока персонажа */
  const soc = characterSoc ?? tariffSoc;

  /** Обработчик клика по кнопке блока ошибки. Сбрасывает состояние ошибки в false,
   *  после чего начинается повторный зпрос */
  const handleClick = () => {
    dispatch(fetchFmcPresets({ regionId, generation, soc })).catch((e: Error) =>
      throwAsyncError(e),
    );
  };

  return { handleClick, errorLoadingText };
};
