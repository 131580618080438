import * as CONSTANTS from './constants';
import {
  changeCurrentZInfo,
  getInitData,
  initOptionsZ,
  initSlidesObjectZ,
} from '../../../../utils';

export const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.SET_LOADING:
      return {
        ...state,
        pending: action.payload,
      };
    case CONSTANTS.SET_TOTAL_PRICE:
      return {
        ...state,
        totalPrice: action.payload,
      };
    case CONSTANTS.SET_SOCS_ON:
      return {
        ...state,
        socsOn: action.payload,
      };
    case CONSTANTS.SET_SOCS_OFF:
      return {
        ...state,
        socsOff: action.payload,
      };
    case CONSTANTS.SET_PACKAGE_PARAM:
      return {
        ...state,
        resultPackages: { ...state.resultPackages, ...action.payload },
      };
    case CONSTANTS.SET_CHOSEN_OPTIONS:
      return {
        ...state,
        resultOptions: { ...state.resultOptions, ...action.payload },
      };
    case CONSTANTS.SET_TARIFF_INFO:
      // eslint-disable-next-line no-case-declarations
      const type =
        (
          (state.activeTab !== 'ChangeTariffForm' && state.hasZTariff) ||
          (state.activeTab === 'ChangeTariffForm' && !state.hasZTariff) ||
          (!state.isAvailable && !state.hasZTariff) ||
          (state.isAvailable && !state.hasZTariff)
        ) ?
          'newNumber'
        : 'currentNumber';
      // eslint-disable-next-line no-case-declarations
      const { slides, tariffOptions, checkInfo } = action.payload[type];
      return {
        ...state,
        ...action.payload,
        slides,
        tariffOptions,
        checkInfo,
        resultPackages: initSlidesObjectZ(slides),
        resultOptions: initOptionsZ(tariffOptions),
        newNumber: getInitData(action.payload, 'newNumber'),
        currentNumber: getInitData(action.payload, 'currentNumber'),
      };
    case CONSTANTS.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
        ...changeCurrentZInfo(state, action.payload),
      };
    default:
      return state;
  }
};
