import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const renderHeadColumns = (headColumns) =>
  headColumns.map((text, i) => (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <th className={cx('tableTd', 'tableTh')} dangerouslySetInnerHTML={{ __html: text }} key={i} />
  ));

const renderRows = (rows) =>
  rows.map((row, i) => (
    <tr className={cx('tableRow', { firstRow: i === 0 })} key={i}>
      <td className={cx('tableTd', 'titleTd')} dangerouslySetInnerHTML={{ __html: row.title }} />
      {row.columns.map((text, index) => (
        <td className={cx('tableTd')} dangerouslySetInnerHTML={{ __html: text }} key={index} />
      ))}
    </tr>
  ));

const ComparesTable = (props) => {
  const { title, description, afterTableText, headColumns, rows } = props;

  return (
    <div className={cx('wrapper')}>
      <Heading className={cx('title')} level={2}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Heading>
      <p className={cx('description')} dangerouslySetInnerHTML={{ __html: description }} />

      <div className={cx('tableWrapper')}>
        <table className={cx('table')}>
          <thead>
            <tr className={cx('tableRow', 'tableHead')}>{renderHeadColumns(headColumns)}</tr>
          </thead>
          <tbody>{renderRows(rows)}</tbody>
        </table>
      </div>

      <p className={cx('afterTableText')} dangerouslySetInnerHTML={{ __html: afterTableText }} />
    </div>
  );
};

ComparesTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  afterTableText: PropTypes.string,
  headColumns: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      columns: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

export default ComparesTable;
