import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@beef/ui-kit';

// import { AUTH_STEPS, LOGIN_TYPES } from '../../actions/constants';

// пересмотреть не используется ли это еще где в адаптиве и можно убрать

const BroadbandLinks = (props) => {
  const { /* step, loginType, switchForm, requestSMS, */ resetPassword } = props;
  const data = props.data || {};
  const links = data.links || {};
  const { /* getOneTimePassword, howToGetLogin, enterByPassword, */ getPassword } = links;

  return (
    <Link href="#" isNoUnderline onClick={resetPassword} size="size5-b">
      {getPassword}
    </Link>
  );
};

BroadbandLinks.propTypes = {
  // recoverPasswordUrl: PropTypes.string,
  data: PropTypes.shape({}),
  // step: PropTypes.oneOf(Object.keys(AUTH_STEPS).map((key) => AUTH_STEPS[key])),
  // loginType: PropTypes.oneOf(Object.keys(LOGIN_TYPES).map((key) => LOGIN_TYPES[key])),
  // switchForm: PropTypes.func,
  // requestSMS: PropTypes.func,
};

export default BroadbandLinks;
