import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { convertSum } from 'pages/ProductCard/utils';

import { Heading } from './Heading';
import { OptionsList } from './OptionsList';

const Options = memo(
  ({
    info,
    isHideSum,
    optionTitle,
    totalOptionsSum,
    isAnimalsTariffLayout,
    joinedOptions,
    tooltip,
    free,
    oldPriseSum,
  }) => {
    const currentUnit = isAnimalsTariffLayout ? info.longRcRateUnit : info.shortRcRateUnit;
    const optionsPrice =
      totalOptionsSum === 0 ? free : `${convertSum(totalOptionsSum)} ${currentUnit}`;
    const oldPrice =
      oldPriseSum && totalOptionsSum !== oldPriseSum ?
        `${convertSum(oldPriseSum)} ${info.longPeriod}`
      : null;

    return (
      <>
        <Heading
          isHideSum={isHideSum}
          oldPrice={oldPrice}
          oldPrise={oldPrice}
          price={optionsPrice}
          title={optionTitle}
          tooltip={tooltip}
        />
        {joinedOptions && <OptionsList options={joinedOptions} />}
      </>
    );
  },
);

Options.propTypes = {
  content: PropTypes.string,
  total: PropTypes.shape({
    options: PropTypes.object,
  }),
  info: PropTypes.shape({
    tariffOptions: PropTypes.array,
    shortRcRateUnit: PropTypes.string,
  }),
  totalOptionsSum: PropTypes.number,
  isHideSum: PropTypes.bool,
  isAnimalsTariffLayout: PropTypes.bool,
  isYourPreset: PropTypes.bool,
  oldPriseSum: PropTypes.number,
};

export default Options;
