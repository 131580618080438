import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { apiConnectTariffUrl } from './constants';

/** Тип ответа запроса на смену тарифа ConstructorV1 */
export type TChangeTariffRes = {
  isSucceeded: boolean;
  requestId: number;
};

/** Запрос на получение мобильных пакетов ConstructorV1 */
export const fetchChangeTariff = async ({
  params,
  ...config
}: AxiosRequestConfig): Promise<AxiosResponse<TChangeTariffRes>> =>
  axios.post(apiConnectTariffUrl, params, config);
