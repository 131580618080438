import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TItemInfoList = {
  icon: string;
  text: string;
};

/** Item списка компонента InfoList */
export const ItemInfoList: FC<TItemInfoList> = ({ icon, text }) => (
  <li className={cx('wrapper')}>
    {icon && <img alt="" loading="lazy" src={icon} />}
    {text && (
      <Text color="grey60" size="size5-r-l">
        {text}
      </Text>
    )}
  </li>
);
