import { shallowEqual } from '@beef/utils';

import { useAppSelector } from '../../../../store';

/** Селектор выборки табов способов подключения тарифа */
export const useSelectTabs = () => {
  const { tariffTabs = [], ...params } = useAppSelector(
    (state) => ({
      eSIMModal: state.common.content.eSIMModal,
      authModal: state.common.content.authModal,
      tariffTabs: state.common.content.tariffTabs,
      connectionMethodTitle: state.common.content.connectionMethodTitle,
    }),
    shallowEqual,
  );

  return {
    options: tariffTabs,
    ...params,
  };
};
