import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ActionBar, Modal, Text } from '@beef/ui-kit';
import { emptyObj } from '@beef/utils';

import Picture from 'pages-components/Picture';

import { ActionButton } from '../RenderRequestForm/ActionButton';
import { RenderRequestForm } from '../RenderRequestForm';
import styles from './styles.pcss';

const cx = classNames.bind(styles);
const FORM_ID = 'callback-form-modal';

export const RenderPopup = ({ data, isOpen, isLoading, onClose }) => {
  const { texts: { redesignPopupTexts: texts = emptyObj } = emptyObj } = data;

  return (
    <Modal
      actionBar={
        <ActionBar isLoading={isLoading} isWithText size="s">
          <ActionButton
            agreementText={texts.userAgreementText}
            buttonText={texts.backCallBtnText}
            formId={FORM_ID}
            isLoading={isLoading}
            tooltipText={texts.termsOfUse}
          />
        </ActionBar>
      }
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      size="m"
    >
      <>
        {texts.imgUrl && (
          <div className={cx('main-popup__picture')}>
            <Picture src={texts.imgUrl} />
          </div>
        )}
        {texts.title && (
          <Text className={cx('main-popup__title')} color="grey95" size="size3-m">
            {texts.title}
          </Text>
        )}
        <Text className={cx('main-popup__description')} color="grey50" size="size5-r-s">
          {texts.backCallTabSubTitle}
        </Text>
        <RenderRequestForm data={data} formId={FORM_ID} />
      </>
    </Modal>
  );
};

RenderPopup.propTypes = {
  data: PropTypes.shape({
    texts: PropTypes.shape({
      redesignPopupTexts: PropTypes.shape({
        title: PropTypes.string,
        backCallTabTitle: PropTypes.string,
        backCallTabSubTitle: PropTypes.string,
        techSupportTabTitle: PropTypes.string,
        techSupportTabSubTitle: PropTypes.string,
      }),
    }),
  }),
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
