import { optionsListForByConditions } from 'pages/Services/RedirectServiceCardLanding/const';
import { BlockInfoProps } from 'pages/Services/RedirectServiceCardLanding/components/BlockInfo/types';

export const getListForBlockInfo = ({
  options,
  settings,
}: Pick<BlockInfoProps, 'options' | 'settings'>) => {
  if (options?.doNotRedirect) {
    return [];
  }
  if (options?.redirectAlways) {
    return [
      {
        title: options.phoneAlwaysRedirect,
        subtitle: settings?.redirectAlways?.tab,
      },
    ];
  }
  if (options?.redirectByConditions) {
    return optionsListForByConditions
      .map((option) => ({
        title: options[option],
        subtitle: settings?.redirectByConditions?.value[option]?.title,
      }))
      .filter((item) => item?.title);
  }
  return [];
};
