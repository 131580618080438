import React from 'react';

import { Button } from './Button';
import { Timer } from './Timer';
import { Layout } from './Layout';
import { useProgress } from './hooks/useProgress';
import { usePlayAudio } from './hooks/usePlayAudio';

export const AudioPlayer = ({ src, onPlay, className }) => {
  const { isPlaying, progress, duration, togglePlay, setProgress, audioRef } = usePlayAudio({
    onPlay,
  });
  const { currentProgress, progressNodeRef } = useProgress({ progress, duration, setProgress });

  return (
    <>
      <Layout
        buttonNode={<Button onClick={togglePlay} type={isPlaying ? 'pause' : 'play'} />}
        className={className}
        isPlaying={isPlaying}
        progress={currentProgress}
        rulerRef={progressNodeRef}
        timerNode={<Timer duration={duration} progress={currentProgress} />}
      />
      <audio ref={audioRef}>
        <source src={src} type="audio/mpeg" />
      </audio>
    </>
  );
};
