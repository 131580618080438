import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import axios from 'axios';

// Components
import { Link } from '@beef/ui-kit';

import PopupStateless from 'pages-components/Popup';

import Benefits from './component/Benefits';
import ProductParameters from './component/ProductParameters';
import DisconnectionStatus from './component/DisconnectionStatus';

// Styles
import styles from './styles.pcss';
import ConnectionSteps from './ConnectionStep';

const cx = classNames.bind(styles);

/**
 ** serviceType - тип сервиса (если serviceType === 'insurance', то выводится кнопка),
 ** infoText - текст-предупреждение под ProductParameters (серый),
 ** errorText - текст-предупреждение под ProductParameters (красный),
 ** warningText - кастомный текст-предупреждение под ProductParameters (черный в серой рамочке),
 ** data.warningText - пропс-предупреждение, стандартный для услуги, из DPC
 * */

const ScreenProtectionService = ({ data }) => {
  const {
    serviceType,
    content,
    content: { redirectUrl, benefits, howToArrange, statusIcon },
  } = data.extData;
  const { isAuthenticated, isConnected, isArchived, isConnectedLater, connectedText, soc } = data;

  const [buttonVisible, setButtonVisible] = useState(false);
  const [parametersData, setParametersData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    try {
      axios
        .get(`/api/alfa-insurance/contract-info?soc=${soc}`)
        .then(({ data }) => {
          if (data) {
            setParametersData(data);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    setButtonVisible(
      !data.warningText && !isConnected && !isArchived && serviceType === 'insurance',
    );
  }, [parametersData]);

  return (
    <div className={cx('component')}>
      {benefits && <Benefits benefits={benefits} className={cx('benefit')} />}
      <div className={cx('steps')}>
        <Benefits
          benefits={howToArrange?.benefits}
          className={cx('step')}
          isCounter
          title={howToArrange?.title}
        />
      </div>
      <div className={cx('wrap')}>
        {isAuthenticated && parametersData?.productParameters && (
          <ProductParameters
            errorText={parametersData?.errorText}
            infoText={parametersData?.infoText}
            loading={loading}
            productParameters={parametersData?.productParameters}
            statusIcon={statusIcon}
            warningText={parametersData?.warningText}
          />
        )}
        {buttonVisible && (
          <div className={cx('button')}>
            <Link buttonSize="l" buttonVariant="main" href={redirectUrl} variant="button">
              {parametersData?.connectButtonTitle}
            </Link>
          </div>
        )}
        {isConnected && (
          <DisconnectionStatus
            connectedText={connectedText}
            handleClick={() => setModalOpen(true)}
            isConnected={isConnected}
            isConnectedLater={isConnectedLater}
          />
        )}
      </div>
      {modalOpen && (
        <PopupStateless onClose={() => setModalOpen(false)} opened>
          <ConnectionSteps
            content={content}
            insuranceType={parametersData?.insuranceType}
            onClose={() => setModalOpen(false)}
            soc={soc}
          />
        </PopupStateless>
      )}
    </div>
  );
};

ScreenProtectionService.propType = {
  infoText: PropTypes.string,
  warningText: PropTypes.string,
  errorText: PropTypes.string,
  data: PropTypes.object,
  redirectUrl: PropTypes.string,
  serviceType: PropTypes.string,
  insurancePolicyButton: PropTypes.string,
  statusIcon: PropTypes.object,
};

export default ScreenProtectionService;
