import { useEffect, useState } from 'react';

import { isEmptyValue } from 'utils/isEmptyValue';
import store from 'store';
import {
  setDataFromMainStep,
  setErrorText,
  setErrorValidation,
  setTempEditableSpn,
} from 'pages/SPN/store/actions';
import { ChoosenOptionType, OptionsDataForMainModalTypes } from 'pages/SPN/types';
import {
  selectInputPlaceholder,
  selectSavedTempEditableSpn,
} from 'pages/SPN/store/actions/selectors';

import { getPriceInfo, isRefersToEditable, validationAndSetError } from '../../common';
import { MODAL_STEPS_SPN } from '../../../store/constants';
import { spnAnalyticsParams } from '../../../ym';

export const useInitializeMainInfoSPN = ({ data }: { data: OptionsDataForMainModalTypes }) => {
  const [choosenOption, setChoosenOption] = useState<ChoosenOptionType>({});
  const { dispatch } = store;
  const state = store.getState();

  const {
    content,
    haveChildSocs,
    choosenSocEditable,
    defaultSocChild,
    childSocs,
    errorText,
    preselectedSoc,
    placeholder,
    step,
  } = data;

  useEffect(() => {
    const newChoosenOption = { spn: placeholder ?? '', soc: preselectedSoc };
    setChoosenOption(newChoosenOption);
    dispatch(setDataFromMainStep({ spn: placeholder, soc: preselectedSoc }));
  }, [preselectedSoc, placeholder]);

  useEffect(() => {
    if (choosenOption) {
      dispatch(setDataFromMainStep(choosenOption));
    }
  }, [choosenOption]);

  if (isEmptyValue(data)) return null;

  const defaultPriceInfo = getPriceInfo(choosenSocEditable);

  if (isEmptyValue(content)) return null;

  const infoText = step === MODAL_STEPS_SPN.MAIN && content?.childSocsEditable?.infoText;
  const errors = step === MODAL_STEPS_SPN.MAIN && content?.childSocsEditable?.errors;

  // берёт сохранненый временный spn для childSocsEditable
  const savedTempEditableSpn = selectSavedTempEditableSpn(state);

  // Handlers
  const handleChangeInput = (value: string, soc: string) => {
    const { maxQuantityOfSymbols: maxLength } = validationAndSetError({ value, errors, dispatch });

    if (value.length < maxLength) {
      setChoosenOption((prev) => ({ ...prev, spn: value, soc }));
    }
  };

  const handleBlurInput = () => {
    validationAndSetError({ value: choosenOption.spn, errors, dispatch });
    dispatch(setDataFromMainStep({ spn: choosenOption?.spn, soc: choosenOption?.soc }));

    // analytics
    spnAnalyticsParams({ name_variant: choosenOption?.spn });
  };

  const handleFocusInput = () => {
    setChoosenOption((prev) => ({ ...prev, soc: choosenSocEditable?.soc }));

    if (!savedTempEditableSpn) {
      dispatch(setDataFromMainStep({ spn: choosenOption?.spn, soc: choosenSocEditable?.soc }));
    } else {
      dispatch(setDataFromMainStep({ spn: savedTempEditableSpn, soc: choosenSocEditable?.soc }));
      setChoosenOption((prev) => ({
        ...prev,
        soc: choosenSocEditable?.soc,
        spn: savedTempEditableSpn,
      }));
    }

    // очистить временное название spn
    dispatch(setTempEditableSpn(''));

    // analytics
    spnAnalyticsParams('insert_name');
  };

  const handleRadioChange = (radioData: { soc: string; spn: string }, isEditableSocs: boolean) => {
    setChoosenOption((prev) => {
      const updatedOptions = { ...prev, ...radioData };

      if (isEditableSocs) {
        dispatch(setTempEditableSpn(''));
        validationAndSetError({ value: updatedOptions.spn, errors, dispatch });
        dispatch(
          setDataFromMainStep({
            spn: savedTempEditableSpn || updatedOptions.spn,
            soc: updatedOptions.soc,
          }),
        );
      }

      if (!isEditableSocs) {
        dispatch(setTempEditableSpn(prev.spn));
        dispatch(setDataFromMainStep({ spn: updatedOptions.spn, soc: updatedOptions.soc }));
      }

      return updatedOptions;
    });

    if (!isRefersToEditable()) {
      dispatch(setErrorValidation(false));
      dispatch(setErrorText(''));
    }
  };

  return {
    handleRadioChange,
    handleBlurInput,
    handleChangeInput,
    handleFocusInput,
    haveChildSocs,
    defaultSocChild,
    choosenSocEditable,
    childSocs,
    infoText,
    choosenOption,
    defaultPriceInfo,
    errorText,
    inputPlaceholder: selectInputPlaceholder(state),
    savedTempEditableSpn,
  };
};
