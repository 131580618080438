import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Preloader } from '@beef/legacy-ui-kit';

import RequestStatus from 'pages-components/RequestStatus';

const STEPS = {
  confirmation: 'confirmation',
  disconnect: 'disconnect',
  error: 'error',
  success: 'success',
};

const ConnectionSteps = ({ content, onClose, steps, soc, insuranceType }) => {
  const [disconnectionContent, setDisconnectionContent] = useState(null);
  const [currentStep, setCurrentStep] = useState(STEPS.disconnect);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      axios
        .get(`/api/alfa-insurance/deactivation-info?soc=${soc}`)
        .then(({ data }) => {
          if (data) {
            setDisconnectionContent(data);
          }
          setLoading(false);
        })
        .catch(() => {
          setCurrentStep('error');
          setLoading(false);
        });
    } catch (error) {
      setCurrentStep('error');
      console.error(error);
    }
  }, []);

  const onDisconnectClick = () => {
    const requestData = {
      insuranceType,
      contractNumber: disconnectionContent?.contractNumber,
    };

    try {
      setLoading(true);
      axios
        .post('/api/alfa-insurance/deactivate', requestData)
        .then(({ data }) => {
          if (!data.isSucceeded) {
            setLoading(false);
            setCurrentStep('error');
          } else {
            setLoading(false);
            setCurrentStep('success');
          }
        })
        .catch(() => {
          setLoading(false);
          setCurrentStep('error');
        });
    } catch (error) {
      setCurrentStep('error');
      console.error(error);
    }
  };

  if (loading) {
    return (
      <div>
        <Preloader size="40" />
      </div>
    );
  }

  return (
    <div>
      {currentStep === STEPS.disconnect && (
        <RequestStatus
          data={{
            description: disconnectionContent?.confirmationPopup.description,
            ...content.disconnect,
          }}
          isLeftCentered
          onClick={onDisconnectClick}
        />
      )}
      {currentStep === STEPS.success && (
        <RequestStatus
          data={{
            description: disconnectionContent?.successPopup.description,
            ...content.success,
          }}
          isLeftCentered
          onClick={onClose}
        />
      )}
      {currentStep === STEPS.error && (
        <RequestStatus data={content.error} isLeftCentered onClick={onClose} />
      )}
    </div>
  );
};

ConnectionSteps.propTypes = {
  content: PropTypes.shape({
    success: PropTypes.object,
    error: PropTypes.object,
    connect: PropTypes.object,
    blocked: PropTypes.object,
    isNotEnoughMoney: PropTypes.object,
    pending: PropTypes.object,
    pollIntervalTime: PropTypes.number,
    pollTimeOut: PropTypes.number,
  }),
  onClose: PropTypes.func,
  yandexLink: PropTypes.string,
  disconnected: PropTypes.string,
  steps: PropTypes.object,
};

export default ConnectionSteps;
