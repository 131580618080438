import { ymPushEvent } from '../../../../utils/ym';
import { EResponseCode } from './constants';
import { pushPartnerServiceParams } from './utils';

export const sendPreconnectAnalytics = (code: EResponseCode, state: any) => {
  if (code === EResponseCode.Success) {
    ymPushEvent('ServiceConnectSuccess', {
      connection_success: {
        partner: {
          [state.data?.alias]: state.data?.productParameters?.value,
        },
      },
    });
    return;
  }

  pushPartnerServiceParams({
    connection_error: {
      [code]: {
        partner: state.data?.alias,
      },
    },
  });
};
