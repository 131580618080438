import { withDataConverter, withMountListener } from '@beef/react';
import { compose, withReduxStore } from '@beef/redux';
import { Button, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { initializeStoreTimeCapsuleLanding } from '../../../store';
import { confirmModal as confirmModalAction } from '../../../store/modal';
import { selectHasErrors } from '../../../store/selectors';
import { getTimeCapsuleFieldNames, setValue } from '../../../store/time-capsule';
import {
  initializeForm,
  submitTimeCapsule,
  validateTimeCapsule,
} from '../../../store/time-capsule/actions';
import {
  selectButtonContent,
  selectContent,
  selectInfo,
  selectSmsContent,
  selectSmsData,
  selectTitle,
} from '../../../store/time-capsule/selectors';
import { StorageHelper, processTextWithCustomRegex } from '../../../utils';
import { pushAnniversaryEvent } from '../../../utils/analytics';
import { LandingModal } from '../../LandingModal/LandingModal';
import { Textarea } from '../Textarea';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const TimeCapsuleFormLayout = (props) => {
  const state = props;
  const { setSmsValue, handleSubmit, modal, confirmModal, content, hasErrors, data, strip } = state;
  const modalType = modal?.modalType;

  const shouldScrollIntoView = data.shouldScrollIntoView?.value;
  const ref = useRef();

  useEffect(() => {
    if (shouldScrollIntoView) {
      const top = ref.current.getBoundingClientRect().top + window.scrollY - 150;
      window.scroll({
        top,
        behavior: 'smooth',
      });
    }
  }, [shouldScrollIntoView]);

  return (
    <>
      <form className={cn('form')} ref={ref}>
        <div className={cn('title')}>
          <Text color="grey95" size="size1-m" tagType="h2">
            {selectTitle(state) || ''}
          </Text>
          <Text color="grey60" size="size4-r">
            {selectInfo(state)}
          </Text>
        </div>
        <div className={cn('textarea')}>
          <Textarea
            disabled={false}
            errorMessage={selectSmsData(state)?.error || ''}
            extraAttributes={{
              style: {
                minHeight: '186px',
              },
            }}
            fullWidth
            isTextAreaResizable={false}
            label={selectSmsContent(state)?.label || ''}
            maxLength={396}
            onChange={(e) =>
              setSmsValue(
                processTextWithCustomRegex(
                  e.target.value,
                  strip?.preprocess ? strip?.value : '',
                  strip?.placeholder,
                ),
              )
            }
            placeholder={selectSmsContent(state)?.placeholder}
            required
            type="text"
            value={selectSmsData(state)?.value}
          />
        </div>
        <div className={cn('button-container')}>
          <Button disabled={hasErrors} onClick={handleSubmit} size="l" type="submit" variant="main">
            {selectButtonContent(state)?.text || ''}
          </Button>
        </div>
      </form>
      {modalType && <LandingModal confirmModal={() => confirmModal(modalType)} content={content} />}
    </>
  );
};

TimeCapsuleFormLayout.propTypes = {
  formContent: PropTypes.shape({
    title: PropTypes.string,
    phone: PropTypes.shape({
      label: PropTypes.string,
    }),
    sms: PropTypes.shape({
      label: PropTypes.string,
    }),
    button: PropTypes.shape({
      text: PropTypes.string,
    }),
  }),
};

const mapStateToProps = ({ form, modal }) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 5);
  const fiveYearsFromNow = date.toLocaleDateString('ru');
  const content = {
    ...selectContent(form),
    success: {
      ...selectContent(form)?.success,
      text: `${selectContent(form)?.success?.text} ${fiveYearsFromNow}`,
    },
  };
  const strip = content?.sms?.strip;
  const hasErrors = selectHasErrors(form);

  if (modal.modalType === 'confirmation' && !modal.isModalConfirmed) {
    pushAnniversaryEvent('time_capsule_popup');
  }

  return {
    ...form,
    modal,
    content,
    hasErrors,
    strip,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSmsValue: (value) => dispatch(setValue({ value, fieldName: 'sms' })),
  setPhoneValue: (value) => dispatch(setValue({ value, fieldName: 'phone' })),
  handleSubmit: (e) => {
    e.preventDefault();
    pushAnniversaryEvent('time_capsule_send_form');
    dispatch(validateTimeCapsule());
  },
  confirmModal: (modalType) => {
    dispatch(confirmModalAction());
    if (modalType === 'confirmation') {
      pushAnniversaryEvent('time_capsule_confirm');
      dispatch(submitTimeCapsule());
    }
  },
  onMount: () => {
    dispatch(initializeForm('timeCapsule'));

    const _hasValue = getTimeCapsuleFieldNames().reduce((hasValue, fieldName) => {
      const value = StorageHelper[fieldName];
      dispatch(setValue({ value, fieldName }));
      return hasValue || !!value;
    }, false);

    dispatch(setValue({ value: _hasValue, fieldName: 'shouldScrollIntoView' }));
  },
});

export const TimeCapsuleForm = compose(
  withDataConverter((data) => ({
    form: data,
    modal: data,
  })),
  withReduxStore(initializeStoreTimeCapsuleLanding),
  connect(mapStateToProps, mapDispatchToProps),
  withMountListener,
)(TimeCapsuleFormLayout);
