import { useEffect, useState } from 'react';

const genHash = () => `${Math.random().toString().substring(2, 12)}`;

export const useClientId = (name = 'id') => {
  const [pseudoHash] = useState(genHash);
  return `${name}-${pseudoHash}`;
};

export const useHighlyFlamableUnsafeHackyIdCrutch = (name = 'id') => {
  const [pseudoHash, setHash] = useState(genHash);
  useEffect(() => {
    /**
     * Делаем перерендер с новым ID, чтобы вправить мозг react'у
     * По факту этот перерендер не нужен, но без него virtual-dom будет рассинхронизирован с реальным
     */
    setHash(genHash());
  }, []);
  return `${name}-${pseudoHash}`;
};
