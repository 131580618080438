import React, { FC } from 'react';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';

import { useAppSelector } from 'constructorV1/store';
import { exhaustiveCheck } from 'constructorV1/utils/exhaustiveCheck';
import { Bill } from 'constructorV1/components/commonComponents/Bill';
import { selectCurrentBillStep } from 'constructorV1/store/selectors/selectTotal';
import { MNPContainer } from 'constructorV1/components/commonComponents/MNPContainer';
import { EBillStepper } from 'constructorV1/components/constructorPage/BillStepper/types';

/** Варианты анимации общего контейнера в чеке ЕК */
const variantsBillStepper = {
  hidden: {
    height: 'auto',
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
};

/** Обертка возвращает текущий вариант отображаемого блока внутри контейнера чека ЕК */
export const BillStepper: FC = () => {
  /* Выборка подготовленных данных для чека ЕК */
  const currentBillStep = useAppSelector(selectCurrentBillStep);

  /** Функция определения текущего блока для BillStepper.
   * Возвращает компонент с настройками тарифа (Bill), либо компонент получения номера для переноса (MNPContainer) */
  const defineBillBlock = () => {
    switch (currentBillStep) {
      case EBillStepper.bill:
        return <Bill />;
      case EBillStepper.mnpForm:
        return <MNPContainer />;
      default:
        exhaustiveCheck(currentBillStep);
        return <Bill />;
    }
  };

  return (
    <LayoutGroup>
      <motion.div
        animate="visible"
        exit="exit"
        initial="hidden"
        layout
        variants={variantsBillStepper}
      >
        <AnimatePresence initial={false}>{defineBillBlock()}</AnimatePresence>
      </motion.div>
    </LayoutGroup>
  );
};
