import { TServiceTile } from '../tempComponents/ServiceTile/types';

/** Функция определения текущего children для ServiceTile */
export const defineChildren = (service: TServiceTile) => {
  /* Получаем данные по текущему сервису */
  const { info, variant = 'horizontal' } = service || {};

  /* Информация по infoList (первый вариант отрисовки карточки сервиса) */
  const infoList = info?.infoList;

  /* Информация по infoPrice (второй вариантов отрисовки карточки сервиса) */
  const infoPrice = info?.infoPrice;

  return { variant, infoList, infoPrice };
};
