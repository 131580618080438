import React, { FC } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames/bind';

import { Title } from 'constructorV1/components/commonComponents/ConstructorTitle';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCTotalPrice = {
  subTitle?: string;
  title?: string;
};

/** Блок с ценой и периодом оплаты */
export const CTotalPrice: FC<TCTotalPrice> = ({ title, subTitle }) =>
  title ?
    <motion.section className={cx('wrapper')} layout>
      <Title align="left" className={cx('wrapper__title')} size="s" {...{ title, subTitle }} />
    </motion.section>
  : null;
