import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';

import { setParametersAction } from 'pages/SearchResultsPage/store/actions';
import { getIsArticles, getSpecialCategories } from 'pages/SearchResultsPage/store/selectors';
import { CategoriesWithAnalytics } from 'pages/SearchResultsPage/components/Filter/Categories/CategoriesWithAnalytics';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _SpecialCategories = ({ categories, setFilter }) => (
  <div className={cx('container')}>
    {categories.map((category) => (
      <CategoriesWithAnalytics isSpecial key={category.id} name={category.name}>
        <button className={cx('button')} onClick={() => setFilter(category.id)} type="submit">
          <img alt="" className={cx('img')} src={category.pictureUrl} />
          <Text color="grey60" size="size6-r">
            {category.name}
          </Text>
        </button>
      </CategoriesWithAnalytics>
    ))}
  </div>
);

_SpecialCategories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      pictureUrl: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  setFilter: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  setFilter: (val) => dispatch(setParametersAction('categories', val)),
});

const mapStateToProps = (state) => ({
  categories: getSpecialCategories(state),
  shouldRender: getSpecialCategories(state)?.length > 1 && !getIsArticles(state),
});

export const SpecialCategories = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRenderFlag,
)(_SpecialCategories);
