export const phone = (value) => ({
  status: value && value.length === 10 ? 'ok' : 'fail',
  msg: 'Неправильный формат номера телефона',
});

export const validateMaskedPhone = (value) => /\+7 \d{3} \d{3} \d{2} \d{2}/.test(value);
export const validateMaskedAlternative = (value) => /\d{11}/.test(value.replaceAll(/\D/g, ''));
export const validateAllowedCharacters = (value) =>
  /[^a-zA-Zа-яА-ЯёЁ!№%:,.;"'()?_+= ,\d-]+/.test(value);
export const validateName = (value) => /[^А-Яа-яЁёA-Za-z.-]+/.test(value);
export const latinWordsRegex = (value) => /[a-zA-Z]+/.test(value);
export const cyrillicWordsRegex = (value) => /[\u0400-\u04FF]+/.test(value);
export const digitsRegex = (value) => /\d+/.test(value);
export const symbolsRegex = (value) =>
  // eslint-disable-next-line no-useless-escape
  /[@Δ¿£_!$Φ\"¥#èΛ¤éΩ%ù&ìΨòΣ\(ÇΘ\)Ξ\*:Ø\+;ÄäøÆ,<Ööæ\-=ÑñÅß\.>ÜüåÉ\/\?\§à]/g.test(value);

export const validateMaskedPhoneVariants = (value, variant) => {
  const val = typeof variant === 'number' ? variant.toString() : variant;

  switch (val) {
    case '0': {
      return /^(\+7|\+7\s)?\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/.test(value);
    }
    case '1': {
      return /\+7 (\d{3}) \d{3} \d{2} \d{2}/.test(value);
    }
    default:
      return /\+7 \d{3} \d{3} \d{2} \d{2}/.test(value);
  }
};

export const maskedPhone = (value) => ({
  status: validateMaskedPhone(value) ? 'ok' : 'fail',
  msg: 'Неправильный формат номера телефона',
});

export const validatePhone = (value) => ({
  status: validateMaskedPhone(value) ? 'ok' : 'fail',
  msg: '',
});

export const email = (value) => ({
  // form emailregex.com
  status:
    (
      // eslint-disable-next-line no-useless-escape
      /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i.test(
        value,
      )
    ) ?
      'ok'
    : 'fail',
  msg: 'Неправильный формат почты',
});

export const emailForEsim = (value) => ({
  status:
    (
      /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z00-9_-]+)*\.[a-zA-Z0]{2,6}$/i.test(
        value,
      )
    ) ?
      'ok'
    : 'fail',
  msg: 'Неправильный формат почты',
});

export const emailMayBeEmpty = (value) => {
  if (!value) {
    return {
      status: 'ok',
      msg: '',
    };
  }

  return email(value);
};

export const sms = (value) => ({
  status: value.length > 1 ? 'ok' : 'fail',
  msg: 'Неправильный формат sms кода',
});

export const notEmpty = (value, msg = 'Некорректно заполненное поле') => ({
  status: value && value.length > 0 ? 'ok' : 'fail',
  msg,
});

export const withMinLength =
  (minLength = 1) =>
  (value) => ({
    status: value && value.length >= minLength ? 'ok' : 'typo',
  });

export const shpdLogin = (value) => ({
  status: value.indexOf('08') === 0 && value.length === 10 ? 'ok' : 'fail',
  msg: 'Неправильный формат логина. Введите логин в формате: 08XXXXXXXX',
});
