// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { useAppSelector } from 'constructorV1/store';
import { selectBaseUrl } from 'constructorV1/store/selectors/selectCommon';
import { constructorV1Pages } from 'constructorV1/router/pathes';

/* Хук для получения ссылки на зарегистрированные страницы.
 * Обязательно используем его для навигации из-за особенностей SSR */
export const useConstructorLink = (page: keyof typeof constructorV1Pages) => {
  const baseUrl = useAppSelector(selectBaseUrl);
  if (page === 'main') {
    return baseUrl;
  }
  return [baseUrl || '/', constructorV1Pages[page]].join('');
};
