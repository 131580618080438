import React from 'react';
import classNames from 'classnames/bind';

import { TBroadBandInternetPopupProps } from 'pages/Services/TrustPayment/types';
import { RecursivePartial } from 'utils/types';

import styles from './styles.pcss';
import { ModalSection } from './components/ModalSection/index';

const cn = classNames.bind(styles);

export const ModalSections: React.FC<{
  sections: RecursivePartial<TBroadBandInternetPopupProps>['sections'];
}> = ({ sections }) => (
  <div className={cn('section-wrapper')}>
    {sections?.map((section) => <ModalSection key={section.title} section={section} />)}
  </div>
);
