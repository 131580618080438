import React from 'react';

import { Devices } from '../Devices';
import { usePrepareDevices } from './hooks/usePrepareDevices';

/** Обертка над компонентом Devices для Internet Live */
export const DevicesContainer = () => {
  const devicesProps = usePrepareDevices();
  return <Devices {...devicesProps} />;
};
