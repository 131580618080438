import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Tabs } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { debounce } from 'utils/timed-functions';

import { ORIGINS } from '../../constants';
import * as actions from '../../store/actions';
import SearchForm from './SearchForm';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

class Controls extends Component {
  // onGeolocationButtonClick = () => {
  //   if (this.state.geolocationLoading) return;
  //
  //   this.setState({ geolocationLoading: true });
  //   this.context.mapController.moveToCurrentLocation((location) => {
  //     this.setState({ geolocationLoading: false });
  //     this.props.setCurrentLocation(location);
  //   });
  // };

  // onZoomButtonClick = (value) => {
  //   const { map } = this.context.mapController;
  //   map.setZoom(map.getZoom() + value);
  // };

  state = {
    geolocationLoading: false,
  };

  searchOnMap = debounce((query) => {
    if (!query) return;

    this.props.searchOnMap(query);
  }, 400);

  onPointChoose = (point) => {
    this.context.mapController.setBounds(point.lats, point.lons);
    this.props.setSearchResults();
  };

  render() {
    const {
      analyticsEvents,
      foundPoints,
      hideMenuButton,
      mapMode,
      onModeChange,
      origin,
      qualityLinkHref,
      qualityLinkText,
      searchOnMapError,
      sng,
      isCarnicaRedesign,
    } = this.props;
    const isLandingPage = origin === ORIGINS.landingPage;
    const TabList = [
      { id: 'offices', title: 'Адреса салонов' },
      { id: 'coverZone', title: 'Карта покрытия' },
    ];
    return (
      <div className={cx('wrapper-controls')}>
        <div
          className={cx(isCarnicaRedesign ? 'redesign-component' : 'component', {
            absolute: !hideMenuButton && (isLandingPage || sng),
          })}
        >
          {!isLandingPage && !sng && (
            <div className={cx('tabs')}>
              <Tabs
                isScrollable={false}
                onChange={onModeChange}
                selectedTab={mapMode}
                tabs={TabList}
                variant="custom"
              />
            </div>
          )}
          <SearchForm
            analyticsEvents={analyticsEvents}
            foundPoints={foundPoints}
            isCarnicaRedesign={isCarnicaRedesign}
            onPointChoose={this.onPointChoose}
            searchOnMap={this.searchOnMap}
            searchOnMapError={searchOnMapError}
          />
          {Boolean(qualityLinkText && qualityLinkHref) && (
            <div className={cx('quality-link')}>
              <Link
                buttonSize="l"
                buttonVariant="secondary"
                href={qualityLinkHref}
                isFullWidth
                variant="button"
              >
                {qualityLinkText}
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Controls.contextTypes = {
  mapController: PropTypes.shape({}),
};

Controls.propTypes = {
  isCarnicaRedesign: PropTypes.bool,
  analyticsEvents: PropTypes.shape({
    onSegmentedControlClick: PropTypes.func,
    onCheckboxClick: PropTypes.func,
    onChoosePoint: PropTypes.func,
    onGeolocationButtonClick: PropTypes.func,
    onSearchInputFill: PropTypes.func,
    onSearchInputClick: PropTypes.func,
    onZoomButtonClick: PropTypes.func,
  }),
  foundPoints: PropTypes.arrayOf(PropTypes.string),
  mapMode: PropTypes.string,
  onModeChange: PropTypes.func,
  origin: PropTypes.string,
  qualityLinkHref: PropTypes.string,
  qualityLinkText: PropTypes.string,
  searchOnMap: PropTypes.func,
  searchOnMapError: PropTypes.bool,
  // setCurrentLocation: PropTypes.func,
  setSearchResults: PropTypes.func,
};

export default connect(
  ({ external: { maps = {} } }) => ({
    foundPoints: maps.foundPoints,
    searchOnMapError: maps.searchOnMapError,
  }),
  (dispatch) => ({
    searchOnMap: (query) => dispatch(actions.searchOnMap(query)),
    setCurrentLocation: (payload) => dispatch(actions.setCurrentLocation(payload)),
    setSearchResults: (payload) => dispatch(actions.setSearchResults(payload)),
  }),
)(Controls);
