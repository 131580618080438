import React, { useCallback, useMemo, useState } from 'react';
import { Provider, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';

// Components

import Picture from 'pages-components/Picture';
import SocialButtons from 'pages-components/SocialButtons/SocialButtons';
import AppLinkSmsPopup from 'widgets/AppLinkSmsPopup';

// Utils
import { isMobileDevice } from 'utils/device';

// Styles

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MyBeelineBanner = ({
  text,
  bannerImage,
  appButtons,
  downloadButton,
  smsFormData,
  currentCtn,
  isMobile,
}) => {
  const [smsFormShown, setShowSmsForm] = useState(false);
  const action = useMemo(
    () => smsFormData?.connectButtonAction,
    [smsFormData?.connectButtonAction],
  );

  const onMainButtonClick = useCallback(() => {
    setShowSmsForm(action === 'linkOverSms');
  }, [action]);

  return (
    <div className={cx('banner')}>
      <div className={cx('container')}>
        <div className={cx('info')}>
          <Heading className={cx('title')} isStyled={false} level={3}>
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Heading>
          <div className={cx('appButtons')}>
            <div className={cx('downloadButton')}>
              {!isMobile && downloadButton && downloadButton?.text && (
                <Button
                  className="big"
                  color="light"
                  onClick={smsFormData?.smsForm && onMainButtonClick}
                >
                  {downloadButton.text}
                </Button>
              )}
            </div>
            <div className={cx('socialButtons')}>
              <SocialButtons data={appButtons} />
            </div>
          </div>
        </div>

        <div className={cx('picture')}>
          <Picture alt="" src={bannerImage} />
        </div>
      </div>

      {smsFormShown && (
        <AppLinkSmsPopup
          appButtons={appButtons}
          currentCtn={currentCtn}
          onClose={() => {
            setShowSmsForm(!smsFormShown);
          }}
          sendMessageUrl={smsFormData?.sendMessageUrl}
          texts={smsFormData?.smsForm}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentCtn: state.external.currentCtn,
  isMobile: isMobileDevice(state),
  authenticated: state.external.currentCtn && !state.external.currentCtn.isXbr,
});

const MyBeelineBannerConnected = connect(mapStateToProps)(MyBeelineBanner);

const MyBeelineBannerWithStore = (props) => (
  <Provider store={store}>
    <MyBeelineBannerConnected {...props} />
  </Provider>
);

MyBeelineBanner.propTypes = {
  text: PropTypes.string,
  bannerImage: PropTypes.string,
  appButtons: PropTypes.object,
  downloadButton: PropTypes.shape({
    text: PropTypes.string,
  }),
  smsFormData: PropTypes.shape({
    connectButtonAction: PropTypes.string,
    sendMessageUrl: PropTypes.string,
    smsForm: PropTypes.object,
  }),
  isMobile: PropTypes.bool,
  currentCtn: PropTypes.object,
};

export default MyBeelineBannerWithStore;
