import React from 'react';
import classNames from 'classnames/bind';

import type { IAnalyticsEvents } from 'pages/CarnicaDSLandings/ui/Footer';

import { ISocialLink, SocialLink } from './components/SocialLink';
import style from './styles.pcss';

const cn = classNames.bind(style);

export interface ISocialLinks {
  analyticsEvents?: IAnalyticsEvents;
  socialsLinks: Array<ISocialLink>;
}

export const SocialLinks: React.FC<ISocialLinks> = ({ socialsLinks, analyticsEvents }) => (
  <ul className={cn('social-links')}>
    {socialsLinks.map(({ name, srcImage, url }) => (
      <SocialLink
        analyticsEvents={analyticsEvents}
        key={name}
        name={name}
        srcImage={srcImage}
        url={url}
      />
    ))}
  </ul>
);
