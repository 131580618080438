import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TTotalDescription = {
  description?: string;
};

/** Блок описания в чеке тарифа */
export const TotalDescription = memo<TTotalDescription>(({ description }) =>
  description ?
    <div className={cx('wrapper')}>
      <Text color="grey60" size="size6-r">
        {description}
      </Text>
    </div>
  : null,
);
