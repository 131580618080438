import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@beef/ui-kit';

import { ModalActionBar } from 'pages/OffsetLanding/component/RequestModal/ModalActionBar';

const RequestStatusWithLoader = ({ onClose, onConfirm, isOpen, isLoading, data }) => {
  const buttonData = useMemo(
    () => ({
      text: data.buttonText,
      url: data.buttonUrl,
      variant: 'main',
    }),
    [data.buttonText, data.buttonUrl],
  );
  return (
    <Modal
      actionBar={
        <ModalActionBar button={buttonData} isLoading={isLoading} onConfirm={onConfirm} size="s" />
      }
      headerBarConfig={{
        headerText: data.title,
        subtitleText: data.description,
      }}
      isCloseButtonVisible={!isLoading}
      isLoading={isLoading}
      isNoFixHeight={!isLoading}
      isOpen={isOpen}
      onClose={onClose}
      size="s"
    />
  );
};

RequestStatusWithLoader.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    buttonUrl: PropTypes.string,
    buttonText: PropTypes.string,
  }),
};

export default RequestStatusWithLoader;
