import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Heading } from '@beef/legacy-ui-kit';

// Style
import classNames from 'classnames/bind';

import styles from '../styles.pcss';

const cx = classNames.bind(styles);

const ConfirmStep = ({
  onClose,
  onClick,
  data: { buttonConfirmText, buttonCancelText, contentText, title },
}) => (
  <div>
    {title && (
      <Heading isStyled={false} level={1}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Heading>
    )}
    <div className={cx('content')}>
      {contentText && <p dangerouslySetInnerHTML={{ __html: contentText }} />}
      <div className={cx('buttonsGroup')}>
        <Button className={cx('big')} color="light" onClick={onClose} wide>
          {buttonCancelText}
        </Button>
        <Button className={cx('big')} onClick={onClick} wide>
          {buttonConfirmText}
        </Button>
      </div>
    </div>
  </div>
);

ConfirmStep.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  data: PropTypes.shape({
    contentText: PropTypes.string,
    title: PropTypes.string,
    buttonCancelText: PropTypes.string,
    buttonConfirmText: PropTypes.string,
  }),
};

export default ConfirmStep;
