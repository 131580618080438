import React, { FC } from 'react';
import { FinalForm } from '@beef/form';
import { CellAction, Notification, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { MultipleOptionsFormProps } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/types';
import { RInput } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/RInput';
import { phoneMaskSettings } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/const';
import { RedirectByConditionsTypes } from 'pages/Services/RedirectServiceCardLanding/types';
import { useFormRedirectByConditions } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/hook';
import { idFormRedirectSettings } from 'pages/Services/RedirectServiceCardLanding/const';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const FormRedirectByConditions: FC<MultipleOptionsFormProps> = ({
  options,
  content,
  onChangePhone,
  onClear,
  onSubmitHandle,
  onChangeActive,
}) => {
  const { getValidators, onSubmit, optionsType, onChangeForm } = useFormRedirectByConditions({
    options,
    onChangePhone,
    onSubmitHandle,
  });

  const onClickItem = (type: RedirectByConditionsTypes) => {
    onChangeActive?.(type);
  };

  const item = (type: RedirectByConditionsTypes) => {
    const it = content.redirectByConditions?.value[type];
    const { title, description, notification, placeholder } = it;

    return (
      <div className={cx('item')} key={type}>
        {/* В мобильной версии CellAction onClick не работает */}
        <div onClick={() => onClickItem(type)}>
          <CellAction
            id={type}
            isActive={options[type] !== null}
            key={options[type]}
            leftSubtitle={description}
            leftTitle={title}
            onClick={() => {}}
            type="switch"
          />
        </div>
        {options[type] !== null && (
          <RInput
            maskSettings={phoneMaskSettings}
            name={type}
            onClear={() => onClear(type)}
            placeholder={placeholder}
            value={options[type]}
          />
        )}
        {notification && (
          <div className={cx('item__notification')}>
            <Notification text={notification} variant="secondary" />
          </div>
        )}
      </div>
    );
  };
  return (
    <FinalForm
      getValidators={getValidators}
      id={idFormRedirectSettings}
      onChange={onChangeForm}
      onSubmit={onSubmit}
      values={options}
    >
      <Text className={cx('title')} size="size4-m">
        {content?.redirectByConditions?.title}
      </Text>
      {optionsType.map((type) => item(type))}
    </FinalForm>
  );
};
