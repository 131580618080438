import { TSubscription } from 'pages/ProductCard/Tariff/types';
import { translate } from 'utils/format-string';

enum EPeriod {
  Day = 'день',
  Week = 'нед',
  Month = 'мес',
  Quarter = '3 мес',
  HalfYear = '6 мес',
  Year = 'год',
}

const DAYS = 'days';

/**
 * Функция, которая вычисляет отображение периода у подписки
 *
 * Например: Р/мес, Р/год, Р/3 дня и т.д.
 *
 * @param subscription подписка
 *
 * @returns период подписки
 */
export const calculatePaymentPeriod = (subscription: TSubscription) => {
  const { paymentPeriodDaysCount: days, paymentPeriod } = subscription;

  if (!paymentPeriod || !days) return paymentPeriod;

  const [currency] = paymentPeriod.split('/');
  const _period = `${days} ${translate(DAYS, days) as string}`;
  const period = [
    days === 1 && EPeriod.Day,
    days === 7 && EPeriod.Week,
    days === 30 && EPeriod.Month,
    days === 90 && EPeriod.Quarter,
    days === 180 && EPeriod.HalfYear,
    days === 365 && EPeriod.Year,
    _period,
  ].find((v) => !!v) as string;

  return `${currency}/${period}`;
};

/**
 * Функция, которая считает сумму стоимостей подписок, которые
 * нужно будет добавить в чек
 *
 * @param subscriptions Подписки
 *
 * @returns стоимость подписок
 */
export const calculateSubscriptionsCheckoutPrice = (
  subscriptions: TSubscription[],
  isButton = false,
) =>
  subscriptions.reduce(
    (sum, { hasTrial = false, price, trialPrice, paymentPeriodDaysCount: days, longRcRate }) => {
      if (hasTrial) return sum + (trialPrice ?? 0);

      const priceWithFallback = price ?? 0;
      if (days) {
        const customDurationPrice = Math.ceil((priceWithFallback / days) * 30);
        const calculatedPrice = [
          days === 1 && priceWithFallback * 30,
          days > 1 && days < 30 && customDurationPrice,
          isButton && days > 30 && priceWithFallback,
          days > 30 && 0,
          priceWithFallback,
        ].find((v) => typeof v === 'number') as number;

        return sum + calculatedPrice;
      }

      // NOTE: longRcRate это уже посчитаная цена
      // Если она есть, а дней нет, то нужно поставить ее
      if (longRcRate && longRcRate > priceWithFallback) {
        return sum + longRcRate;
      }

      return sum + (price ?? 0);
    },
    0,
  );

export const getSubscriptionIds = (subscriptions: TSubscription[]) =>
  subscriptions.map(({ id }) => id);

/** Функция возвращает значение href из входящей строки
 *
 * @param inputString строка со значение href
 *
 * @returns строку с содержимым href
 */
export const extractHref = (inputString: string) => {
  const regex = /<a\s+(?:[^>]*?\s+)?href=('[^']+'|"[^"]+")/;
  const match = inputString.match(regex);

  if (match && match[1]) {
    return match[1];
  }
  return null;
};
