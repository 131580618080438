/**
 * Вспомогательная утилита для расстановки "мягких" переносов
 *
 * Пришлось добавить это из-за невозможности расстановки переносов &shy; через
 * контент, а css свойство hyphens: auto не работало у пользователей
 * на Windows из-за отсутствия системного русского словаря.
 *
 * ВНИМАНИЕ! Требуется доработка, так как переносы описанные ниже очень условные,
 * надо найти более надежный метод или изменить визуал плиток опций
 * @deprecated
 * @param title
 */
export function tileTitleHyphens(title: string) {
  const words = title.split(' ');
  return words
    .map((word) => {
      // если длина одного слова меньше 8, то не трогаем его
      if (word.length < 8) {
        return word;
      }
      // Иначе – добавляем мягкий перенос после 6 буквы
      // это плохое решение, оно разумеется не универсальное. Надо переделать
      const letters = word.split('');
      letters.splice(6, 0, '&#173;');
      return letters.join('');
    })
    .join(' ');
}
