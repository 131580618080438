import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Description = ({ discountText, description, subDescription }) => (
  <>
    {discountText && (
      <div className={cx('discount')}>
        <Text color="grey60" size="size4-r">
          {discountText}
        </Text>
      </div>
    )}
    {description && (
      <Text color="grey60" size="size4-r">
        {description}
      </Text>
    )}
    {subDescription && (
      <div className={cx('additionally')}>
        <Text color="grey60" size="size4-r">
          {subDescription}
        </Text>
      </div>
    )}
  </>
);

Description.propTypes = {
  description: PropTypes.string,
  discountText: PropTypes.string,
  subDescription: PropTypes.string,
};

export { Description };
