import React from 'react';
import { compose, withReduxStore } from '@beef/redux';
import { connect } from 'react-redux';
import { withDataConverter } from '@beef/react';
import { LandingConstructor } from '@beef/landing-constructor';

import { BeepBanner } from '../components/BeepBanner';
import { BeepConnectBanner } from '../components/BeepConnectBanner';
import { StatusModal } from '../components/StatusModal';
import { pimpMyBeepDataConverter } from '../store/dataConverters';
import { initializePimpMyBeepLanding } from '../store';
import { selectContent } from '../store/selectors';

const PimpMyBeepLandingLayout = ({ constructorBlocks }) => (
  <>
    <BeepBanner />
    <BeepConnectBanner />
    <LandingConstructor blocks={constructorBlocks} />
    <StatusModal />
  </>
);

const mapStateToProps = (state) => ({
  constructorBlocks: selectContent(state).blocks,
});

export const PimpMyBeepLanding = compose(
  withDataConverter(pimpMyBeepDataConverter),
  withReduxStore(initializePimpMyBeepLanding),
  connect(mapStateToProps),
)(PimpMyBeepLandingLayout);
