import React, { memo, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Button, Text } from '@beef/ui-kit';

import Icon from '../../../../../../../components/Icon';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Attention = memo(({ text, hasChange }) => {
  const [isIgnored, setIgnored] = useState(false);
  const onClose = () => setIgnored(true);
  const shouldShow = hasChange && !isIgnored;

  return (
    <div className={cx('wrapper', { 'wrapper--hide': !shouldShow })}>
      <div className={cx('wrapper__content')}>
        {text && (
          <div className={cx('wrapper__title')}>
            <Text color="grey95" size="size5-r-s">
              {text}
            </Text>
          </div>
        )}
        <div className={cx('wrapper__icon')}>
          <Button
            onClick={onClose}
            rightIcon={<Icon name="crossZ" />}
            size="l"
            type="button"
            variant="plain"
          />
        </div>
      </div>
    </div>
  );
});

Attention.propTypes = {
  text: PropTypes.string,
  hasChange: PropTypes.bool,
};
