import React, { FC } from 'react';
import { CarouselWithNavigation } from '@beef/smart-kit';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { SLIDER_SETTINGS_DATE_TIME } from '../../../../../constants';
import type { ISpecialistCall } from '../../types';
import { DateTimeChip } from '../DateTimeChip';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/* Компонент обертка карусель для слотов монтажника */
export const CarouselSlots: FC<ISpecialistCall> = ({
  isDateSlot,
  label,
  onChange,
  options,
  value,
}) => (
  <div className={cx('container')}>
    {!!options?.length && (
      <>
        {label && <Text size="size5-m">{label}</Text>}
        <CarouselWithNavigation
          className={cx('carousel')}
          nextBtnClassname={cx('btn-next')}
          paginatePadding={false}
          prevBtnClassname={cx('btn-prev')}
          settings={{
            ...SLIDER_SETTINGS_DATE_TIME,
            slidesPerView: 'auto',
          }}
          slideWidthAuto
          withDefaultNavigation
        >
          {options?.map((item) => (
            // используем кастомный хук, так как ChipsStateless для карусели не подходит полностью
            // - карусель воспринимает китовский компонент как одну ноду
            <DateTimeChip
              {...item}
              isActive={isDateSlot ? item.label === value : item.id === value}
              isDateSlot={isDateSlot}
              key={item.id}
              onClick={() => onChange(item.id)}
            />
          ))}
        </CarouselWithNavigation>
      </>
    )}
  </div>
);
