import { axiosBeelineRequest } from '@beef/utils';

import { EResponseCode } from './constants';
import { selectActivateEndpoint } from './store/selectors';
import { IPartnerServiceCardState } from './types';

export const sendPostRequestWithId = async (id: string, url: string, body = {}) => {
  return axiosBeelineRequest({
    method: 'post',
    url,
    data: { id, ...body },
    headers: { 'Content-Type': 'application/json' },
    originalResponse: false,
  });
};

export const getResponseCode = (data: { code?: string }) =>
  (data?.code as EResponseCode) || EResponseCode.Default;

export const getPartnerUrl = (data: { partnerUrl?: string }) => data.partnerUrl;

export const getOAuthEndpoint = async (href: string, state: IPartnerServiceCardState) => {
  const { yandexOAuthUrl } = (await axiosBeelineRequest<{ yandexOAuthUrl?: string }>({
    url: `${selectActivateEndpoint(state.data, href)}`,
    headers: { 'Content-Type': 'application/json' },
    originalResponse: false,
  })) as { yandexOAuthUrl?: string };
  return yandexOAuthUrl || '';
};
