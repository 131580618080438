import React from 'react';
import { Modal } from '@beef/ui-kit';

import { TPopupContent } from 'pages/ProductCard/Tariff/containers/ConstructorTariff/types';

import { ModalActionBar } from '../../FamilyBox/components/ModalActionBar';
import { ModalContent } from './ModalContent';

type TModalContainerProps = {
  content: TPopupContent[number];
  handleModal: (id?: string) => void;
  isOpen: boolean;
};

export const ModalContainer: React.FC<Partial<TModalContainerProps>> = ({
  isOpen = false,
  content = {},
  handleModal = () => {},
}) => (
  <Modal
    actionBar={<ModalActionBar buttonText={content?.buttonText} handleModal={handleModal} />}
    isOpen={isOpen}
    onClose={handleModal}
    size="m"
  >
    <ModalContent
      description={content?.description}
      icon={content?.icon}
      subDescription={content?.subDescription || ''}
      title={content?.title}
    />
  </Modal>
);
