import React, { FC } from 'react';
import { Faq, FaqPropsInterface, PageRow } from '@beef/smart-kit';
import { withRenderFlag } from '@beef/react';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';

import { withElementInView } from 'utils/hocs/withElementInView';
import { ANALYTICS } from 'pages/PromotionsPage/analytics';
import { onFAQClickYm } from 'pages/PromotionsPage/store/actions';

type MapStateToPropsType = {
  shouldRender: boolean;
} & FaqPropsInterface;

const _FAQ: FC<FaqPropsInterface> = ({ elementRef, ...props }) => (
  <PageRow blockSpacersPreset="l" tagName="section">
    <div ref={elementRef}>
      <Faq {...props} />
    </div>
  </PageRow>
);

const _FaqWithInView = withElementInView(_FAQ)(() => {
  ANALYTICS.FAQ_VIEW.sendParams();
});

// @ts-expect-error state not typed in ts yet
export const FAQ = compose(
  connect(({ content }): MapStateToPropsType => ({ ...content.faq, shouldRender: content.faq }), {
    onClick: onFAQClickYm,
  }),
  withRenderFlag,
)(_FaqWithInView);
