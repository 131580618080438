import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { getMobileOS } from 'utils/detect-mobile';
import { pushAppLinkClick } from 'utils/ga';
import {
  openTariffConnectPopup,
  setTariffConnectPopupInitialData,
} from 'pages/ProductCard/actions/tariffConnect/handleTariffConnectionPopup';
import withStoreProvider from 'pages/FTTB/hoc/withStoreProvider';
import SocialButtons from 'pages-components/SocialButtons/SocialButtons';
import UssdForm from 'pages-components/UssdForm/UssdForm';
import Icon from 'pages-components/Icon';
import TariffConnectPopup from 'pages/ProductCard/components/TariffConnectPopup/TariffConnectPopup';
import formatConnectPopupUrlResponse from 'utils/formatConnectPopupUrlResponse';

import CallOrderPopup from './components/CallOrderPopup';
import SendSmsForm from './components/SendSmsForm';
import AccordionContent from './components/AccordionContent';
import TariffsWidget from './components/TariffsWidget';
import styles from './ProductListParameter.pcss';

const cx = classNames.bind(styles);

const ProductListParameter = ({
  content,
  handlerClick,
  sendSmsFormSubmit,
  callOrderAction,
  openConnectPopup,
  setConnectPopupData,
}) => {
  // Hooks
  const [showAccordionContent, toggleAccordionContent] = useState(false);
  const [showCallOrderPopup, toggleCallOrderPopup] = useState(false);
  const [orderModalPending, setOrderModalPending] = useState(false);
  const [orderModalFetched, setOrderModalFetched] = useState(false);

  const onDescriptionClick = (e) => {
    if (e.target.nodeName === 'IMG' && e.target.parentElement.nodeName === 'A') {
      pushAppLinkClick(e.target.alt, e.target.parentElement.href);
    }
  };

  const handleOrderModal = (url) => {
    if (!url) {
      return;
    }

    if (orderModalFetched) {
      openConnectPopup();
      return;
    }

    setOrderModalPending(true);

    axios
      .post(url)
      .then(({ data: { isSucceeded, view } }) => {
        if (isSucceeded && view) {
          const tariffData = formatConnectPopupUrlResponse({
            data: {
              ...view,
              isSucceeded,
            },
            simOrderPlace: 'ServiceCard',
          });

          const popupData = {
            alias: view.tariffDetails.alias,
            dataFetched: true,
          };
          setConnectPopupData({ data: tariffData, popupData });
          setOrderModalFetched(true);
        } else {
          setConnectPopupData({
            data: {
              isSucceeded,
              dataFetched: true,
              ...view,
            },
            popupData: { dataFetched: true },
          });
        }
        openConnectPopup();
      })
      .finally(() => {
        setOrderModalPending(false);
      });
  };

  const renderAccordionTitle = (title) => (
    <div
      className={cx('accordionTitle')}
      onClick={() => toggleAccordionContent(!showAccordionContent)}
      role="button"
      tabIndex="0"
    >
      <span className={cx('accordionTitleText')}>{title}</span>
      &nbsp;
      <Icon className={cx({ titleArrow: true, showAccordionContent })} name="dropdownArrow" />
    </div>
  );

  return (
    <div className={cx('productList')}>
      {content.map(({ text, image, data, widgetType }, index) => (
        <Fragment key={index}>
          {widgetType === 'tarifs-for-promotion' && <TariffsWidget {...data} title={text} />}
          {widgetType !== 'tarifs-for-promotion' && (
            <div className={cx('productListItem', image && 'withBackgroundImg')}>
              <div className={cx('listItemNum')}>{index + 1}</div>
              <div className={cx('description')}>
                <div
                  className={cx('descriptionText', { withImage: !!image })}
                  dangerouslySetInnerHTML={{ __html: text }}
                  onClick={onDescriptionClick}
                  role="button"
                  tabIndex="0"
                />

                {widgetType === 'button-link' && data && data.buttonText && (
                  <div className={cx('buttonLinkWrapper')}>
                    <Button className={(getMobileOS() || !image) && ['big']} href={data.buttonLink}>
                      {data.buttonText}
                    </Button>
                  </div>
                )}

                {showCallOrderPopup &&
                  widgetType === 'call-order' &&
                  data &&
                  data.popupsContent && (
                    <CallOrderPopup
                      callOrderAction={callOrderAction}
                      closePopup={() => toggleCallOrderPopup(false)}
                      content={data.popupsContent}
                    />
                  )}

                {(widgetType === 'call-order' || widgetType === 'call-order-popup') &&
                  data &&
                  data.buttonText && (
                    <div>
                      <Button
                        className={['big', cx('callOrderButton')]}
                        disabled={orderModalPending}
                        onClick={() => {
                          if (widgetType === 'call-order-popup') {
                            handleOrderModal(data.connectPopupUrl);
                          } else {
                            toggleCallOrderPopup(true);
                          }
                        }}
                      >
                        {data.buttonText}
                      </Button>
                    </div>
                  )}

                {widgetType === 'call-order-popup' && data?.connectPopupUrl && (
                  <TariffConnectPopup includeNumber={false} />
                )}

                {widgetType === 'send-sms-with-app-link' && data && (
                  <>
                    {data.sendSmsForm && data.popupsContent && (
                      <SendSmsForm
                        onSubmit={sendSmsFormSubmit}
                        popupContent={data.popupsContent}
                        sendSmsForm={data.sendSmsForm}
                      />
                    )}

                    {data.app && (
                      <div className={cx('social')}>
                        <SocialButtons
                          changeUrl={handlerClick}
                          className={cx('socialButtons')}
                          data={getMobileOS() ? data.app.mobile : data.app.desktop}
                        />
                        {data.accordion && (
                          <div className={cx('accordionTitleWrap')}>
                            {renderAccordionTitle(data.accordion.title)}
                          </div>
                        )}
                      </div>
                    )}

                    {data.ussd && (
                      <UssdForm mobileClassName={cx('ussdForm')} onlyMobileUssd ussd={data.ussd} />
                    )}
                  </>
                )}

                {widgetType === 'app' && data && (
                  <div className={cx('social')}>
                    <SocialButtons changeUrl={handlerClick} data={data} />
                  </div>
                )}

                {image && (
                  <div className={cx('imageContainer')}>
                    <div
                      className={cx('phoneImage')}
                      style={{
                        backgroundImage: `url("${image}")`,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {data && data.accordion && (
            <div className={cx('productListItem', 'accordionTitleWrap')}>
              {renderAccordionTitle(data.accordion.title)}
            </div>
          )}

          {data && data.accordion && showAccordionContent && (
            <AccordionContent {...data.accordion} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

ProductListParameter.defaultProps = {
  openConnectPopup: () => {},
  setConnectPopupData: () => {},
};

ProductListParameter.propTypes = {
  alias: PropTypes.string,

  handlerClick: PropTypes.func,
  sendSmsFormSubmit: PropTypes.func,
  callOrderAction: PropTypes.func,
  openConnectPopup: PropTypes.func,
  setConnectPopupData: PropTypes.func,

  content: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      image: PropTypes.string,
      widgetType: PropTypes.string,
      data: PropTypes.object,
    }),
  ).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setConnectPopupData: (data) => dispatch(setTariffConnectPopupInitialData(data)),
  openConnectPopup: () => dispatch(openTariffConnectPopup()),
});

export default withStoreProvider(connect(null, mapDispatchToProps)(ProductListParameter));
