import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IPriceProps {
  buttonSettings: { previous: string; unit?: string; value: string };
}

export const Price: React.FC<IPriceProps> = (props) => {
  const {
    buttonSettings: { previous, value },
  } = props;

  return (
    <div className={cx('content')}>
      {previous && (
        <Text color="sienna" size="size5-r-s">
          <span className={cx('oldPrice')}>{previous}</span>
        </Text>
      )}
      &nbsp;&nbsp;
      <Text size="size5-r-s">{value}</Text>
    </div>
  );
};
