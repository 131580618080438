import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { countAllSubscriptionsFee } from 'pages/ProductCard/Tariff/components/ConvergenceLayout/utils';

import { ItemTitle } from '../ItemTopBlock/ItemTitle';
import styles from '../TariffContentContainer/styles.pcss';

const cx = classNames.bind(styles);

type FullOrderServiceType = {
  checked: boolean;
  descriptionFee: string;
  subscriptionFee: number;
};
type ItemSingleRowProps = {
  fullOrderServices: FullOrderServiceType[];
  serviceTitle: string;
  tooltip?: string;
};

export const ItemHiddenFees: FC<ItemSingleRowProps> = ({
  serviceTitle,
  tooltip,
  fullOrderServices,
}) => {
  const [{ descriptionFee }] = fullOrderServices;
  const allSubscriptionsFee = countAllSubscriptionsFee(fullOrderServices);
  const hiddenFees = descriptionFee?.replace('{0}', allSubscriptionsFee.toString());

  return (
    <div className={cx('wrapper__top')}>
      <ItemTitle name={serviceTitle} showToolTip={!!tooltip} text={tooltip} />
      <div className={cx('wrapper__price')}>
        <Text color="grey60" size="size4-r">
          {hiddenFees}
        </Text>
      </div>
    </div>
  );
};
