import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { Title } from 'constructorV1/components/commonComponents/ConstructorTitle';
import { GiftContainer } from 'constructorV1/components/tempComponents/GiftContainer';
import { AlertContainer } from 'constructorV1/components/tempComponents/AlertContainer';
import { GbPresetContainer } from 'constructorV1/components/tempComponents/GbPresetContainer';
import { MinPresetContainer } from 'constructorV1/components/tempComponents/MinPresetContainer';
import { SkeletonPresetPicker } from 'constructorV1/components/tempComponents/SkeletonPresetPicker';

import { useHandlePresetChanges } from './hooks/useHandlePresetChanges';
import { usePreparePresetsPickers } from './hooks/usePreparePresetsPickers';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Секция пресетов интернета и минут ЕК */
export const PresetPickers = memo(() => {
  const { titleContentProps, isLoading } = usePreparePresetsPickers();

  useHandlePresetChanges();

  return (
    <section className={cx('wrapper')}>
      <Title {...titleContentProps} />
      <div className={cx('pickers')}>
        {isLoading ?
          <SkeletonPresetPicker />
        : <GbPresetContainer />}
        {isLoading ?
          <SkeletonPresetPicker />
        : <MinPresetContainer />}
      </div>

      <AlertContainer />
      <GiftContainer />
    </section>
  );
});
