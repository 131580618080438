import type { AnyAction } from '@reduxjs/toolkit';
import { Dispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { RootState } from 'constructorV1/store';
import { getCommonBill } from 'constructorV1/store/commonBillSlice/api/getCommonBill';
import {
  selectIdConstructor,
  selectTariffSoc,
  selectTotalOptionsSocs,
} from 'constructorV1/store/selectors/selectTotal';
import { selectCBillContent } from 'constructorV1/store/selectors/selectCommon';
import { createCommonBillProps } from 'constructorV1/components/commonComponents/BillCommon/utils/createCommonBillProps';
import { changeTariff } from 'constructorV1/store/commonBillSlice/api/changeTariff';

import { TCommonBillSlice } from './types';

/** Вспомогательный экшен для */
export const getCommonBillData = createAsyncThunk<
  Promise<void>,
  () => void,
  {
    dispatch: Dispatch;
    state: RootState;
  }
>('api/request/commonBill', async (terminalError, { dispatch, getState, signal }) => {
  /** Сок текущего тарифа */
  const soc = selectTariffSoc(getState());
  /* Контент кнопки результирующего чека */
  const { btnCText, btnCWText } = selectCBillContent(getState());
  /** Соки активных опций текущего тарифа.
   * Преобразуем в строку через запятую для последующей передачи в качестве параметров запроса к бекенду */
  const optionsSocs = selectTotalOptionsSocs(getState())?.join(',');
  /** id текущего тарифа */
  const constructorId = selectIdConstructor(getState());

  try {
    const promise = await dispatch(
      getCommonBill({ soc, optionsSocs, constructorId, signal }) as unknown as AnyAction,
    ).unwrap();

    const { data, error, success } = promise;

    /* Обработка базовой ошибки недоступности контента, либо ошибки запроса (ErrorBoundaryFallback) */
    if (!data || !success) {
      throw new Error();
    }

    /* Обработка терминальных конфликтов подключения ЕК */
    if (error) {
      dispatch(setCBillErrorRequestType(error));
      terminalError();
      return;
    }

    /** Текст в результирующей кнопки */
    const btnCResultText = data.zeroPresetsConditions?.length ? btnCWText : btnCText;

    /* Устанавливаем подготовленный контент (данные) чека в store */
    dispatch(setCBillData(createCommonBillProps(btnCResultText, data)));
    /* @ts-expect-error ошибка типизации error */
  } catch (error: Error) {
    if (error.message !== 'Cancel') {
      throw new Error(error);
    }
  }
});

const initialState: TCommonBillSlice = {
  /* Состояние загрузки результирующего чека (базовое подключение тарифа) */
  isLoading: false,
  /* Тип ошибки результирующего чека (поле error в ответе метода /get-total-bill) */
  errorRequestType: null,
  /* Данные ответа метода /get-total-bill */
  data: null,
  /* Состояние загрузки результирующего чека (при изменении тарифа) */
  isChangeTariffLoading: false,
};

/** Slice результирующего чека ЕК */
export const commonBillSlice = createSlice({
  name: 'commonBill',
  initialState,
  reducers: {
    /** Изменение состояний загрузки результирующего чека */
    setCBillIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    /** Изменение состояний ошибки результирующего чека */
    setCBillErrorRequestType: (state, action) => {
      state.errorRequestType = action.payload;
    },
    /** Изменение объекта data результирующего чека */
    setCBillData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /* Обработка запроса на получение данных для результирующего чека ЕК */
      .addCase(getCommonBillData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCommonBillData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCommonBillData.fulfilled, (state) => {
        state.isLoading = false;
      })
      /* Обработка запроса на получение данных для результирующего чека ЕК */
      .addCase(changeTariff.rejected, (state) => {
        state.isChangeTariffLoading = false;
      })
      .addCase(changeTariff.pending, (state) => {
        state.isChangeTariffLoading = true;
      })
      .addCase(changeTariff.fulfilled, (state) => {
        state.isChangeTariffLoading = false;
      });
  },
});

export const { setCBillIsLoading, setCBillErrorRequestType, setCBillData } =
  commonBillSlice.actions;

export const commonBillReducer = commonBillSlice.reducer;
