import React from 'react';
import classnames from 'classnames/bind';
import { Faq } from '@beef/smart-kit';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const FAQBlock = ({ content: { faq } }) => (
  <div className={cx('faq')}>
    <Faq {...faq} />
  </div>
);
