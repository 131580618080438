import { RecursivePartial } from 'utils/types';

import { TContent } from '../../containers/ConstructorTariff/types';

export type TBasePower = {
  abilityIcon?: string;
  description: string;
  href?: string;
  icon: string;
  id: string;
  isVisible?: string;
  linkText?: string;
  optionSoc?: string;
  title: string;
};

export type TPresetDiscounts = {
  changeTariffForm: Record<string, unknown>;
  mnpForm: Record<string, unknown>;
  simOrderForm: Record<string, unknown>;
};

export type TTab = {
  activeColor: string;
  alias: string;
  discount: string;
  familyDiscount: number;
  highPower: TBasePower;
  iconSrc: string;
  imageSrc: string;
  isActive: boolean;
  name: string;
  optionSoc: string;
  power: TBasePower;
  presetDiscounts: TPresetDiscounts;
  relatedTariffs?: string[];
  soc: string;
  superPower: TBasePower;
  type: number;
};

export type TPartnerProductInfoResponse = RecursivePartial<{
  content: {
    connectButton: string;
    defaultTabLabel: string;
    detailCardBtnText: string;
    detailsButton: string;
    disableButton: string;
    partnerSubscriptionsTitle: string;
    rateText: string;
  };
  howToPayRedirectUrl: string;
  isSucceeded: boolean;
  popupContent: {
    btnText: string;
    code: string;
    description: string;
    imgUrl: string;
    title: string;
  }[];
  productList: {
    categoryName: string;
    checked: boolean;
    description: string;
    hasTrial: boolean;
    id: number;
    imageUrl: string;
    isConnected: boolean;
    isSubscriptionStatus: boolean;
    longRcRate: number;
    name: string;
    partnerDetails: {
      disconnectUrl: string;
      extDescription: string;
      logoPath: string;
      partnerCardLink: string;
    };
    paymentPeriod: string;
    paymentPeriodTrial: string;
    periodAlias: string;
    price: number;
    relatedTariffs: string[];
    subscriptionStatusText: string;
    trialExpireDateWithUnit: string;
    trialPeriodWithUnit: string;
    trialPrice: string;
    trialPriceWithUnit: string;
    yandexAssigned: boolean;
  }[];
}>;

export type TCharactersTabsProps = {
  activeOptionSoc: string;
  content: Partial<TContent>;
  hasTariffConstructor?: boolean;
  isChangeTariff: boolean;
  isHighPreset?: boolean;
  onClick: () => void;
  onMount: () => void;
  powers: {
    activeColor: string;
    isYandex: boolean;
    powers: TBasePower[];
  };
  tabs: TTab[];
  title: string;
};

export enum EDescriptionName {
  NotConnectedNotActivated = 'notConnectedNotActivated',
  ConnectedWithTrialNotActivated = 'connectedWithTrialNotActivated',
  ConnectedWithoutTrialNotActivated = 'connectedWithoutTrialNotActivated',
  DisconnectedButTrialInProgress = 'disconnectedButTrialInProgress',
  ConnectedWithTrialActivated = 'connectedWithTrialActivated',
  ConnectedWithoutTrialActivated = 'connectedWithoutTrialActivated',
  DisconnectedButSubscriptionInProgress = 'disconnectedButSubscriptionInProgress',
}

export enum EPowerType {
  Power = 'power',
  SuperPower = 'superPower',
  YandexPower = 'yandexPower',
}

export enum EYandexPopupType {
  Activation = 'activationPopup',
  Activated = 'activatedPopup',
  Error = 'errorPopup',
  Updated = 'tariffUpdatePopup',
  Connected = 'tariffConnectedPopup',
  Family = 'Family',
}

export enum EYandexCommonErrorPopupType {
  Activation = 'activationPopup',
  Activated = 'activatedPopup',
  Error = 'errorPopup',
  Updated = 'tariffUpdatePopup',
  Connected = 'tariffConnectedPopup',
}

export enum EYandexResponseType {
  Region = 'RULE_CODE_REGION',
  Soc = 'RULE_CODE_SOC',
  Balance = 'RULE_CODE_BALANCE',
  Account = 'RULE_CODE_ACCOUNT',
  Duplicate = 'RULE_CODE_DUPLICATE',
  Error = 'SOMETHING_GOING_WRONG',
}

export type TPopupType = EYandexPopupType | EYandexResponseType | undefined;
