import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { useIsMobile } from 'utils/hooks/useIsMobile';
import ChannelsList from 'pages-components/ChannelsPopup/components/ChannelsList';
import OpenArrowIcon from 'pages-components/LinkWithArrow/components/OpenArrowIcon';
import LinkWithArrow from 'pages-components/LinkWithArrow';
import SlidingDown from 'pages-components/SlidingDown';
import EmptyButton from 'pages-components/EmptyButton';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TvPackage = ({
  tvPackage,
  onActiveChange,
  forceOpenChannels,
  isActive,
  isWithoutUpsaleSwitch,
}) => {
  const [isChannelsOpen, setIsChannelsOpen] = useState(false);
  const isMobile = useIsMobile();

  const onSwitchChange = useCallback(() => {
    onActiveChange(tvPackage.alias);
  }, [onActiveChange, tvPackage.alias]);

  const onOpenChannelsClick = useCallback(() => {
    setIsChannelsOpen((prevIsChannelsOpen) => !prevIsChannelsOpen);
  }, []);

  const hasTvChannels = tvPackage.tvChannels && !!tvPackage.tvChannels.length;
  const isShowChannelsButton = hasTvChannels && !forceOpenChannels;

  return (
    <div>
      <div className={cx('titleRow')}>
        {!isWithoutUpsaleSwitch && (
          <Switch checked={isActive} className={cx('switch')} onChange={onSwitchChange} />
        )}
        {isMobile ?
          <EmptyButton
            className={cx('mobileShowChannelsBtn')}
            disabled={!isShowChannelsButton}
            onClick={onOpenChannelsClick}
          >
            <div className={cx('titleWrap')}>
              <span
                className={cx('title')}
                dangerouslySetInnerHTML={{ __html: `«${tvPackage.name}»` }}
              />
              {isShowChannelsButton && <OpenArrowIcon isOpen={isChannelsOpen} />}
            </div>
            {isShowChannelsButton && (
              <div
                className={cx('channelsCount')}
                dangerouslySetInnerHTML={{ __html: tvPackage.channelsCountLabel }}
              />
            )}
          </EmptyButton>
        : <>
            <div
              className={cx('title')}
              dangerouslySetInnerHTML={{ __html: `«${tvPackage.name}»` }}
            />
            {isShowChannelsButton && (
              <LinkWithArrow
                className={cx('openChannelsLink')}
                isOpen={isChannelsOpen}
                onClick={onOpenChannelsClick}
              >
                <span dangerouslySetInnerHTML={{ __html: tvPackage.channelsCountLabel }} />
              </LinkWithArrow>
            )}
          </>
        }
        <div className={cx('price')}>
          <span dangerouslySetInnerHTML={{ __html: tvPackage.fee.value }} />{' '}
          <span dangerouslySetInnerHTML={{ __html: tvPackage.fee.unit }} />
        </div>
      </div>

      {hasTvChannels && (
        <div className={cx('slidingDownWrap')}>
          <SlidingDown disableOnMobile show={isChannelsOpen || forceOpenChannels}>
            <div className={cx('channelsList')}>
              <ChannelsList channels={tvPackage.tvChannels} />
            </div>
          </SlidingDown>
        </div>
      )}
      <div
        className={cx('description')}
        dangerouslySetInnerHTML={{ __html: tvPackage.description }}
      />
    </div>
  );
};

TvPackage.propTypes = {
  tvPackage: PropTypes.shape({
    alias: PropTypes.string,
    tvChannels: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
    channelsCountLabel: PropTypes.string,
    fee: PropTypes.shape({
      value: PropTypes.string,
      unit: PropTypes.string,
    }),
    description: PropTypes.string,
  }),
  onActiveChange: PropTypes.func,
  forceOpenChannels: PropTypes.bool,
  isWithoutUpsaleSwitch: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default TvPackage;
