export const limitValue = (value, min, max) => {
  if (value < min) return min;
  if (value > max) return max;
  return value;
};
export const getCorrectFromToValue = ({ limitFrom, limitTo }) =>
  +limitFrom > +limitTo ?
    {
      min: limitTo,
      max: limitFrom,
    }
  : {
      min: limitFrom,
      max: limitTo,
    };

export const getCurrentPriceValues = ({ valueFrom, valueTo, min, max }) => {
  const limitFrom = limitValue(valueFrom || min, min, max);
  const limitTo = limitValue(valueTo || max, min, max);
  return getCorrectFromToValue({ limitFrom, limitTo });
};

export const getObjPrice = (price) => {
  const priceArray = price?.split?.(';');
  if (priceArray?.length === 2) {
    return {
      min: !Number.isNaN(+priceArray[0]) ? +priceArray[0] : 0,
      max: !Number.isNaN(+priceArray[0]) ? +priceArray[1] : 0,
    };
  }
  return { min: 0, max: 0 };
};

export const getInitialObjPrice = (price) => (price ? getObjPrice(price) : { min: '', max: '' });

export const preparePriceInput = ({ min, max, price }) => {
  const correctPrice =
    min === '' || max === '' ?
      { min, max }
    : getCorrectFromToValue({ limitFrom: min, limitTo: max });

  return {
    min:
      correctPrice.min === '' ?
        correctPrice.min
      : limitValue(correctPrice.min, price.min, price.max),
    max:
      correctPrice.max === '' ?
        correctPrice.max
      : limitValue(correctPrice.max, price.min, price.max),
  };
};
