import { useIsMobileDimension } from '@beef/ui-kit';
import { useEffect } from 'react';

import { pushEcommerceShowMnp } from 'utils/analytics/simOrderAnalytics';

import { useAppSelector } from '../store';
import { selectChatContent } from '../store/slices/commonSlice/selectors';
import { selectIsAuth, selectTarrifId } from '../store/slices/additionalDataSlice/selectors';
import { decorateHandler, yaMNPMetric } from '../analytics';

/**
 * Хук для получения контента для блоков
 * в index.tsx файле лендинга
 */
export const useMainContent = () => {
  const chatContent = useAppSelector(selectChatContent);
  const isMobile = useIsMobileDimension();
  const isAuth = useAppSelector(selectIsAuth);
  const tarrifId = useAppSelector(selectTarrifId);

  /** Перенесено из старого MNP */
  useEffect(() => {
    pushEcommerceShowMnp(tarrifId);
  }, [tarrifId]);

  const handleAnalyticsSidebarOpen = decorateHandler(() => {}, {
    yaMNPMetric: ['handleClickOpenSidebar'],
  });

  const handleAnalyticsSidebarClose = decorateHandler(() => {}, {
    yaMNPMetric: ['handleClickCloseSidebar'],
  });

  /**
   * Используем нативный вызов аналитики, так как нет возможности
   * отделить клик по кнопке меню через decorateHandler
   */
  const handleAnalyticsLoginClick = (button_text: string) => {
    if (button_text.toLowerCase() === 'войти') {
      yaMNPMetric.handleClickLogin();
    }
  };

  /**
   * Используем нативный вызов аналитики, так как нет возможности
   * пробросить текст элемента меню через decorateHandler
   */
  const handleAnalyticsMenuItemClick = (link: string) => {
    yaMNPMetric.handleClickSidebarItem(link);
  };

  /**
   * Костыль, поскольку на стендах при использовании хуков для темы
   * меняются только иконки, а цвет фона нет.
   */
  useEffect(() => {
    const header = document.querySelector('header');
    if (!header) {
      return;
    }

    const firstChild = header.children?.[0] as HTMLElement;

    if (!firstChild) {
      return;
    }

    firstChild.style.background = 'transparent';
  }, []);

  return {
    chatContent,
    isMobile,
    isAuth,
    analyticsEvents: {
      onSidebarOpen: handleAnalyticsSidebarOpen,
      onSidebarClose: handleAnalyticsSidebarClose,
      onTopButtonClick: handleAnalyticsLoginClick,
      onSidebarLinkClick: handleAnalyticsMenuItemClick,
    },
  };
};
