import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { getTotalBill } from '../../../api/getTotalBill';
import { changeTariff } from '../../../api/changeTariff';
import { getUpperPrice } from '../../../api/getUpperPrice';
import { fetchBasketQuery } from '../../../api/basketQuery';
import type { TTotalSlice, TTotalSliceReducer } from './types';
import { getOptionsSocs } from '../../../utils/getOptionsSocs';
import { fetchTariffChanges } from '../../../api/getTariffChanges';
import { TabTypes } from '../../../components/constructorPage/Tabs/constants';
import { fetchSubmitCallbackRequest } from '../../../api/fetchSubmitCallbackRequest';
import { fetchTariffUpPreset } from '../../../api/tariffupPreset/fetchTariffUpPreset';
import { fetchTariffWithConvergence } from '../../../api/connectTariffWithConvergence';
import { DEFAULT_TAB_ID } from '../../../components/constructorPage/FmcTabsContainer/constants';
import { prepareOptionsForPassiveAbility } from '../../../utils/prepareOptionsForPassiveAbility';

const initialState: TTotalSlice = {
  id: null,
  ctn: '',
  alias: '',
  tariffSoc: '',
  characterSoc: '',
  gbValue: 0,
  minValue: 0,
  generation: 1,
  presetsPrice: 0,
  presetsOldPrice: 0,
  priceCategory: '',
  optionsSoc: [],
  isLoading: false,
  convergenceInfo: {
    convergencePrice: 0,
    convergenceOldPrice: 0,
    isPartnerFmcTariffSelected: false,
    netSpeed: '',
    tariffInacId: DEFAULT_TAB_ID,
    servicesInacIdList: [],
    flat: '',
    houseId: 0,
    highSpeedAvailable: 0,
    connectionAddress: '',
  },
  constructorId: undefined,
  hasLoadingError: false,
  isLoadingConnectQuery: false,
  currentTariffTab: TabTypes.SimOrderForm,
  currentViewedTariffTab: TabTypes.SimOrderForm,
  userPhoneNumber: null,
};

/** Slice для пресетов (Гб, Мин) тарифа */
export const totalSlice = createSlice<TTotalSlice, TTotalSliceReducer>({
  name: 'total',
  initialState,
  reducers: {
    /* Состояние запроса данных по total чеку от бекенда */
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    /* Ошибка запроса данных по total чеку от бекенда */
    setLoadingError: (state, action) => {
      state.hasLoadingError = action.payload;
    },
    /* Soc выбранного тарифа */
    setTariffSoc: (state, action) => {
      state.tariffSoc = action.payload;
    },
    /* Soc выбранного персонажа */
    setCharacterSoc: (state, action) => {
      state.characterSoc = action.payload;
    },
    /* Soc выбранного пресета Гб */
    setGbytes: (state, action) => {
      state.gbValue = action.payload;
    },
    /* Soc выбранного пресета Минут */
    setMinutes: (state, action) => {
      state.minValue = action.payload;
    },
    /* Soc выбранной скорости из блока Домашний интернет и ТВ */
    setNetSpeed: (state, action) => {
      state.convergenceInfo.netSpeed = action.payload;
    },
    /* Текущий таб (способ подключения тарифа)  */
    setTariffTab: (state, action) => {
      state.currentTariffTab = action.payload;
    },
    /* Текущий просматриваемый таб способа подключения тарифа.
     * В "текущий таб" попадают только переключаемые табы (SimOrderForm, MNPForm).
     * В currentViewedTariffTab попадают все табы. */
    setViewedTariffTab: (state, action) => {
      state.currentViewedTariffTab = action.payload;
    },
    /* Включаем все активные по-умолчанию сервисы */
    setDefaultActiveServices: (state, action) => {
      state.convergenceInfo.servicesInacIdList = action.payload;
    },
    /* Устанавливаем выбранные слоты монтажника */
    setTotalDateTimeSlot: (state, action) => {
      state.convergenceInfo.totalDateTimeSlot = action.payload;
    },
    /* Socs выбранных опций тарифа.
     *  Добавляем только родительские соки, если выбраны все дочерние (вся логика в getOptionsSocs) */
    setTotalOptionsSoc: (state, action) => {
      state.optionsSoc = getOptionsSocs(action.payload);
    },
    /* Установка текущего ативного InacId таба ШПД */
    setActiveInacId: (state, action) => {
      state.convergenceInfo.tariffInacId = action.payload;
    },
    /* Установка текущих цен (старая, новая) таба ШПД */
    setConvergencePrice: (state, action) => {
      const { price, oldPrice } = action.payload;
      state.convergenceInfo.convergencePrice = price;
      state.convergenceInfo.convergenceOldPrice = oldPrice;
    },
    /* Установка текущего подключенного адреса ШПД. Отобразится в блоке изменения адреса */
    setConnectedConvergenceAddress: (state, action) => {
      state.convergenceInfo.connectionAddress = action.payload;
    },
    /* Включение/выключение активных сервисов по табу ШПД */
    setToggleService: (state, action) => {
      /* Текущий inacId сервиса, выбранный пользователем  */
      const targetInacId = action.payload;

      /* Если текущий массив активных сервисов (servicesInacIdList) содержит targetInacId,
       * убираем его через filter */
      if (state.convergenceInfo.servicesInacIdList?.includes(targetInacId)) {
        state.convergenceInfo.servicesInacIdList = state.convergenceInfo.servicesInacIdList?.filter(
          (inacId: string) => inacId !== targetInacId,
        );
      } else {
        /* Если текущий массив активных сервисов (servicesInacIdList) НЕ содержит targetInacId,
         * добавляем его */
        state.convergenceInfo.servicesInacIdList?.push(targetInacId);
      }
    },
    /* TODO: типизируем позже! */
    /* Установка данных в totalSlice из кеша (persisted) */
    setPersistedTotal: (_, action) => action.payload,
    /* Установка */
    setConvergenceDataFromCheckConnection: (state, action) => {
      state.convergenceInfo.connectionAddress = action.payload.connectionAddress;
      state.convergenceInfo.flat = action.payload.flat;
      state.convergenceInfo.houseId = action.payload.houseId;
      state.convergenceInfo.highSpeedAvailable = action.payload.highSpeedAvailable;
    },
    /** Сохраняет номер mnp для перехода после проверки на валидацию */
    setUserPhoneNumber: (state, action) => {
      state.userPhoneNumber = action.payload;
    },
    setIsPartnerFmcTariffSelected: (state, { payload }) => {
      state.convergenceInfo.isPartnerFmcTariffSelected = payload;
    },
    /* Установка выбранного alias тарифа из массива апперов */
    setTariffAlias: (state, { payload }) => {
      state.alias = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /* Успешный ответ метода запроса на получение актуальной стоимости (presetsPrice) присета по апперу  */
      .addCase(getUpperPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasLoadingError = false;
        state.presetsPrice = action.payload.commonBill?.totalPrice;
      })
      /* Получили ответ от бэка по total чеку: выставляем статус загрузки (isLoading) в false
       и ошибку (hasLoadingError) в зависимости от isSuccess запроса */
      .addCase(getTotalBill.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasLoadingError = !action.payload.isSuccess;
      })
      /* Установка лоадера на кнопку оплатить в финальном чеке TotalBill при запросе к Basket */
      .addCase(fetchBasketQuery.pending, (state) => {
        state.hasLoadingError = false;
        state.isLoadingConnectQuery = true;
      })
      .addCase(fetchBasketQuery.fulfilled, (state) => {
        state.hasLoadingError = false;
        state.isLoadingConnectQuery = false;
      })
      /* При rejected устанавливаем hasLoadingError в true для отображения страницы ошибки */
      .addCase(fetchBasketQuery.rejected, (state) => {
        state.hasLoadingError = true;
        state.isLoadingConnectQuery = false;
      })
      /* Установка лоадера на кнопку оплатить в финальном чеке TotalBill при запросе к Basket */
      .addCase(changeTariff.pending, (state) => {
        state.isLoadingConnectQuery = true;
      })
      .addCase(changeTariff.fulfilled, (state) => {
        state.isLoadingConnectQuery = false;
      })
      /* При rejected устанавливаем hasLoadingError в true для отображения страницы ошибки */
      .addCase(changeTariff.rejected, (state) => {
        state.isLoadingConnectQuery = false;
      })
      .addCase(fetchTariffWithConvergence.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasLoadingError = !action.payload.isSuccess;
      })
      /* При rejected устанавливаем hasLoadingError в true для отображения страницы ошибки */
      .addCase(fetchTariffWithConvergence.rejected, (state) => {
        state.isLoading = false;
        state.hasLoadingError = true;
      })
      .addCase(fetchSubmitCallbackRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasLoadingError = !action.payload.isSuccess;
      })
      /* Установка лоадера при запросе на изменения по подключенному тарифу */
      .addCase(fetchTariffChanges.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTariffChanges.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchTariffChanges.rejected, (state) => {
        state.isLoading = false;
      })
      /* общая установка лоадера:
       * getUpperPrice.pending - Начало запроса на получение актуальной стоимости (presetsPrice) пресета по апперу
       * getTotalBill.pending - Начало запроса по total чеку
       * fetchTariffWithConvergence.pending - начало запроса на подключение тарифа с ШПД
       * fetchSubmitCallbackRequest.pending - начало запроса на отправку заявки обратного звонка (ПК ШПД)  */
      .addMatcher(
        isAnyOf(
          getUpperPrice.pending,
          getTotalBill.pending,
          fetchTariffWithConvergence.pending,
          fetchSubmitCallbackRequest.pending,
        ),
        (state) => {
          state.isLoading = true;
          state.hasLoadingError = false;
        },
      )
      /* Сброс лоадера при возникновении ошибки/отмены запроса */
      .addMatcher(
        isAnyOf(getUpperPrice.rejected, getTotalBill.rejected, fetchSubmitCallbackRequest.rejected),
        (state) => {
          state.isLoading = false;
        },
      )
      .addMatcher(isAnyOf(fetchTariffUpPreset.fulfilled), (state, action) => {
        const { options = [], presets } = action.payload;
        const { price, oldPrice, constructorId, isHighPreset, priceCategory = '' } = presets || {};

        /* Обработка пришедших данных по presets (price, oldPrice, constructorId, isHighPreset, priceCategory) */
        state.presetsPrice = price;
        state.presetsOldPrice = oldPrice;
        state.isHighPreset = isHighPreset;
        state.constructorId = constructorId;
        state.priceCategory = priceCategory;

        /* Обработка пришедших опций, поиск и выборка соков активных опций */
        state.optionsSoc =
          prepareOptionsForPassiveAbility(options, state.characterSoc)
            ?.map((optionContent) => [
              ...optionContent.optionsList,
              ...(optionContent?.parentOptionsList || []),
            ])
            .flat()
            .filter((option) => option.isActive)
            .map((option) => option.soc) || [];
      });
  },
});

export const {
  setTariffSoc,
  setGbytes,
  setMinutes,
  setNetSpeed,
  setIsLoading,
  setTariffTab,
  setCharacterSoc,
  setLoadingError,
  setTotalOptionsSoc,
  setViewedTariffTab,
  setActiveInacId,
  setToggleService,
  setDefaultActiveServices,
  setUserPhoneNumber,
  setConvergenceDataFromCheckConnection,
  setPersistedTotal,
  setIsPartnerFmcTariffSelected,
  setTariffAlias,
  setConvergencePrice,
  setTotalDateTimeSlot,
  setConnectedConvergenceAddress,
} = totalSlice.actions;

export const totalReducer = totalSlice.reducer;
