import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Description = ({ text, icon, fill, small, className, children }) => (
  <div className={cx('description', className, { small })}>
    {text && icon && <Icon className={cx('icon')} fill={fill} name={icon} />}
    <Text color="grey95" size="size6-r">
      {text}
    </Text>
    {children}
  </div>
);

Description.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  fill: PropTypes.string,
  small: PropTypes.bool,
};

export default Description;
