import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { TTariffFeature, TariffFeature } from './components/TariffFeature';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TTariffFeatures = {
  activeColor?: string;
  tariffFeatures?: TTariffFeature[];
};

/** Контейнер блоков описания апперов для попапа смены персонажа */
export const TariffFeatures: FC<TTariffFeatures> = ({ activeColor, tariffFeatures }) => (
  <ul className={cx('wrapper')}>
    {tariffFeatures?.map((feature) => (
      <TariffFeature
        {...feature}
        key={feature.description}
        style={{ backgroundColor: activeColor }}
      />
    ))}
  </ul>
);
