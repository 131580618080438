export const slEventSend = (payload) => {
  if (typeof window !== 'undefined') {
    window.SL?.API?.('event', payload);
  }
};

export const slConstructorClick = ({ cardStyle }) => {
  if (cardStyle === 'constructor') {
    slEventSend({
      name: 'set_up_tariff',
    });
  }
};

export const slPurchase = (payload) => {
  slEventSend({
    name: 'Purchase',
    properties: {
      eventType: 'purchase-v1',
      uniqueTransactionIdvalue: payload.orderId,
      cart: [
        {
          productId: payload.productId,
          quantity: 1,
          itemPrice: payload.price,
        },
      ],
    },
  });
};

export const slAddToCart = (payload) => {
  slEventSend({
    name: 'Add to Cart',
    properties: {
      eventType: 'add-to-cart-v1',
      value: payload.price,
      productId: payload.productId,
      quantity: 1,
      cart: [],
    },
  });
};
