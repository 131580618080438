import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

type CardSectionType = {
  children: React.ReactNode;
  side: 'bottom' | 'top';
};

export const CardSection = ({ children, side }: CardSectionType) => (
  <div className={cn('component', side && `${side}-side`)}>
    <div className={cn('content')}>{children}</div>
  </div>
);
