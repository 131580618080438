import React, { useEffect, useRef, useState } from 'react';
import lottie, {
  AnimationConfigWithData,
  AnimationDirection,
  AnimationItem,
  AnimationSegment,
  RendererType,
} from 'lottie-web';

import { Listener, LottieOptions, LottieRefCurrentProps, PartialListener } from '../../types';

export const useLottie = <T extends RendererType = 'svg'>(
  animationContainer: React.RefObject<HTMLDivElement>,
  props: LottieOptions<T>,
): LottieRefCurrentProps => {
  const {
    animationData,
    autoplay,
    initialSegment,
    loop,
    onComplete,
    onConfigReady,
    onDOMLoaded,
    onDataFailed,
    onDataReady,
    onDestroy,
    onEnterFrame,
    onLoadedImages,
    onLoopComplete,
    onSegmentStart,
  } = props;

  const [animationLoaded, setAnimationLoaded] = useState(false);
  const animationInstanceRef = useRef<AnimationItem>();
  const [animationInstance, setAnimationInstance] = useState<AnimationItem>();

  const play = (): void => {
    animationInstanceRef.current?.play();
  };

  const stop = (): void => {
    animationInstanceRef.current?.stop();
  };

  const pause = (): void => {
    animationInstanceRef.current?.pause();
  };

  const setSpeed = (speed: number): void => {
    animationInstanceRef.current?.setSpeed(speed);
  };

  const goToAndPlay = (value: number, isFrame?: boolean): void => {
    animationInstanceRef.current?.goToAndPlay(value, isFrame);
  };

  const goToAndStop = (value: number, isFrame?: boolean): void => {
    animationInstanceRef.current?.goToAndStop(value, isFrame);
  };

  const setDirection = (direction: AnimationDirection): void => {
    animationInstanceRef.current?.setDirection(direction);
  };

  const playSegments = (
    segments: AnimationSegment | AnimationSegment[],
    forceFlag?: boolean,
  ): void => {
    animationInstanceRef.current?.playSegments(segments, forceFlag);
  };

  const setSubframe = (useSubFrames: boolean): void => {
    animationInstanceRef.current?.setSubframe(useSubFrames);
  };

  const getDuration = (inFrames?: boolean): number | undefined =>
    animationInstanceRef.current?.getDuration(inFrames);

  const destroy = (): void => {
    animationInstanceRef.current?.destroy();
    animationInstanceRef.current = undefined;
  };

  const loadAnimation = (forcedConfigs = {}) => {
    if (!animationContainer.current) {
      return;
    }

    animationInstanceRef.current?.destroy();

    const config: AnimationConfigWithData<T> = {
      ...props,
      ...forcedConfigs,
      container: animationContainer.current,
    };

    const animation = lottie.loadAnimation(config);

    setAnimationInstance(animation);
    animationInstanceRef.current = animation;

    setAnimationLoaded(!!animationInstanceRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      animationInstanceRef.current?.destroy();
      animationInstanceRef.current = undefined;
    };
  };

  useEffect(() => {
    const onUnmount = loadAnimation();

    return () => onUnmount?.();
  }, [animationData, loop]);

  useEffect(() => {
    if (!animationInstanceRef.current) {
      return;
    }

    animationInstanceRef.current.autoplay = !!autoplay;
  }, [autoplay]);

  useEffect(() => {
    if (!animationInstanceRef.current) {
      return;
    }

    if (!initialSegment) {
      animationInstanceRef.current.resetSegments(true);
      return;
    }

    if (!Array.isArray(initialSegment) || !initialSegment.length) {
      return;
    }
    const [startFrame, stopFrame] = initialSegment;

    if (
      animationInstanceRef.current.currentRawFrame < startFrame ||
      animationInstanceRef.current.currentRawFrame > stopFrame
    ) {
      animationInstanceRef.current.currentRawFrame = startFrame;
    }

    animationInstanceRef.current.setSegment(startFrame, stopFrame);
  }, [initialSegment]);

  useEffect(() => {
    const partialListeners: PartialListener[] = [
      { handler: onComplete, name: 'complete' },
      { handler: onLoopComplete, name: 'loopComplete' },
      { handler: onEnterFrame, name: 'enterFrame' },
      { handler: onSegmentStart, name: 'segmentStart' },
      { handler: onConfigReady, name: 'config_ready' },
      { handler: onDataReady, name: 'data_ready' },
      { handler: onDataFailed, name: 'data_failed' },
      { handler: onLoadedImages, name: 'loaded_images' },
      { handler: onDOMLoaded, name: 'DOMLoaded' },
      { handler: onDestroy, name: 'destroy' },
    ];

    const listeners = partialListeners.filter(
      (listener: PartialListener): listener is Listener => listener.handler != null,
    );

    if (!listeners.length) {
      return;
    }

    const deregisterList = listeners.map((listener) => {
      animationInstanceRef.current?.addEventListener(listener.name, listener.handler);

      return () => {
        animationInstanceRef.current?.removeEventListener(listener.name, listener.handler);
      };
    });

    // eslint-disable-next-line consistent-return
    return () => {
      deregisterList.forEach((deregister) => deregister());
    };
  }, [
    onComplete,
    onLoopComplete,
    onEnterFrame,
    onSegmentStart,
    onConfigReady,
    onDataReady,
    onDataFailed,
    onLoadedImages,
    onDOMLoaded,
    onDestroy,
  ]);

  return {
    animationContainerRef: animationContainer,
    animationItem: animationInstance,
    animationLoaded,
    destroy,
    getDuration,
    goToAndPlay,
    goToAndStop,
    pause,
    play,
    playSegments,
    setDirection,
    setSpeed,
    setSubframe,
    stop,
  };
};
