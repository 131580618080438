import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type THeading = {
  description: string;
  title: string;
};

/** Компонент заголовка и описания для блока НЕдоступной конвергенции */
export const Heading = memo<THeading>(({ title, description }) => (
  <div className={cx('wrapper')}>
    {title && <Text size="size2-m">{title}</Text>}
    {description && (
      <Text color="grey60" size="size5-r-s">
        {description}
      </Text>
    )}
  </div>
));
