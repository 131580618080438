import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';
import EmptyButton from 'pages-components/EmptyButton';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FloatingBackCallSection = ({ data: { title, btnText, phone }, onClose, onPhoneClick }) => (
  <div className={cx('wrapper')}>
    <EmptyButton className={cx('close')} onClick={onClose}>
      <Icon name="cross" />
    </EmptyButton>
    <div className={cx('container')}>
      <div className={cx('title')} dangerouslySetInnerHTML={{ __html: title }} />
      <div className={cx('phoneWrap')}>
        <Icon className={cx('phoneIcon')} name="phoneIconWithShadow" />
        <div className={cx('phone')} dangerouslySetInnerHTML={{ __html: phone }} />
      </div>
      <Button className={cx('phoneBtn')} onClick={onPhoneClick}>
        <span dangerouslySetInnerHTML={{ __html: btnText }} />
      </Button>
    </div>
  </div>
);

FloatingBackCallSection.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    btnText: PropTypes.string,
    phone: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onPhoneClick: PropTypes.func,
};

export default FloatingBackCallSection;
