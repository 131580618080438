import { shallowEqual } from '@beef/utils';

import { getDefaultCharacter } from '../../TariffInfoTiles/utils';
import { useAppSelector } from '../../../../store';
import { prepareServicesInacIdList } from '../../../../utils/prepareServicesInacIdList';
import { formatNetSpeed } from '../../../../utils/formatNetSpeed';

/** Селектор выборки параметров для отправки запроса по пречеку */
export const useSelectParams = () => {
  const {
    gbValue,
    minValue,
    characterSoc,
    optionsSoc,
    convergenceInfo,
    isPartnerFmcInternetSelected,
    generation,
    priceCategory,
    netSpeed,
    defaultTariffInacId,
    tariffsInfo,
  } = useAppSelector(
    (state) => ({
      gbValue: state.total.gbValue,
      minValue: state.total.minValue,
      optionsSoc: state.total.optionsSoc,
      characterSoc: state.total.characterSoc,
      convergenceInfo: state.total.convergenceInfo,
      isPartnerFmcInternetSelected: state.total.convergenceInfo.isPartnerFmcTariffSelected,
      generation: state.total.generation,
      priceCategory: state.total.priceCategory,
      netSpeed: state.total.convergenceInfo.netSpeed,
      defaultTariffInacId: state.common.defaultConstructor.defaultTariffData?.tariffInacId,
      tariffsInfo: state.tariffsInfo,
    }),
    shallowEqual,
  );
  const { tariffInacId = '', servicesInacIdList = [] } = convergenceInfo;

  /* Дефолтный сок тарифа */
  const defaultTariffSoc = getDefaultCharacter(tariffsInfo)?.soc;

  /** Объект параметров для get-запроса */
  return {
    gbValue,
    minValue,
    tariffSoc: defaultTariffSoc,
    characterSoc,
    generation,
    priceCategory,
    optionsSoc: optionsSoc?.join(','),
    /* Если в convergenceInfo есть какие-то настройки (servicesInacIdList имеет inacId),
   собираем все inacId ШПД-сервисов для отображения в total-чеке и добавляем к параметрам запроса (в params) */
    servicesInacIdList: prepareServicesInacIdList(tariffInacId, servicesInacIdList),
    isPartnerFmcInternetSelected,
    /* Обновленная скорость интернета, значение отлично от null только при ихмененной скорости */
    updatedNetSpeed: formatNetSpeed(tariffInacId, defaultTariffInacId, netSpeed),
  };
};
