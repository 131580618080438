import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Description,
  Descriptions,
  ProductParameter,
  ProductParameters,
} from '@beef/legacy-ui-kit';

// Inner components
import classNames from 'classnames/bind';

import Additional from '../Additional';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Описание на сером фоне в нижней части страницы;
 * -----------------------------------------------
 * @param fullDescriptionLink - Объект содержит ссылку для скачивания PDF о тарифе;
 * @param descriptions - Список объектов с описанием и заголовком;
 * @param className - Дополнительный класс;
 * @param ymEvents - Функции для метрики;
 */
const PageDescriptions = ({ fullDescriptionLink, descriptions, onDescriptionClick, className }) => (
  <div className={cx('pageDescriptions')} id="pageDescriptions">
    <Descriptions
      additional={fullDescriptionLink && <Additional fullDescriptionLink={fullDescriptionLink} />}
      className={cx('descriptions', className)}
      onClick={onDescriptionClick}
    >
      {descriptions
        .filter((d) => d.content)
        .map((description, dIndex) => (
          <Description key={dIndex} title={description.title}>
            <ProductParameters>
              {description.content.map((parameter, pIndex) => (
                <ProductParameter
                  className={cx('productParameter')}
                  key={pIndex}
                  label={parameter.label}
                  legal={parameter.legal}
                  subscriptionFeeDiscount={parameter.subscriptionFeeDiscount}
                  unit={parameter.unit}
                  value={parameter.value}
                />
              ))}
            </ProductParameters>
          </Description>
        ))}
    </Descriptions>
  </div>
);

PageDescriptions.defaultProps = {
  descriptions: [],
  className: '',
};

PageDescriptions.propTypes = {
  fullDescriptionLink: PropTypes.object,
  descriptions: PropTypes.array,
  className: PropTypes.any,
};

export default PageDescriptions;
