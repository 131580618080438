import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { PhoneInput } from '../../../commonComponents/PhoneInput';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Обёртка над компонентом PhoneInput для ограничения ширины поля */
export const CallbackRequestPhoneInput: FC = () => (
  <div className={cx('container')}>
    <PhoneInput />
  </div>
);
