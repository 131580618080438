import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, DatePickerStateless as DatePicker, Link, Status } from '@beef/legacy-ui-kit';

// Utils
import addDays from 'date-fns/add_days';
import classNames from 'classnames/bind';

import { templateParser } from 'utils/format-string';
import { getMobileOS } from 'utils/detect-mobile';

// Styles

import styles from './ConnectServiceForm.pcss';

const cx = classNames.bind(styles);

class ConnectServiceForm extends PureComponent {
  constructor(props) {
    super(props);

    const { initialDate } = this.props;

    this.state = {
      startDate: new Date(initialDate),
      endDate: this.getEndDate(initialDate),
      hideNativeView: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.setState({ hideNativeView: getMobileOS() === 'ios' });
  }

  getEndDate(startDate) {
    const { expireDays } = this.props;

    let endDate = null;
    if (expireDays > 0) {
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + expireDays - 1);
    }

    return endDate;
  }

  handleChangeStartDate = (startDate) => {
    this.setState({ startDate });
    const { expireDays } = this.props;
    if (expireDays > 0) {
      this.setState({
        endDate: this.getEndDate(startDate),
      });
    } else if (startDate && startDate > this.state.endDate) {
      this.setState({ endDate: null });
    }
  };

  handleClick() {
    const { startDate, endDate } = this.state;
    let connectDate = null;
    let disconnectDate = null;

    if (startDate) {
      connectDate = new Date(
        Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
      );
    }
    if (endDate) {
      disconnectDate = new Date(
        Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
      );
    }

    if (this.props.onConfirm) {
      this.props.onConfirm({ connectDate, disconnectDate });
    }
  }

  renderFormattedText(text) {
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  }

  render() {
    const {
      description,
      disconnectDateHint,
      confirmButtonText,
      cancelButtonText,
      expireDays,
      warningText,
      showDates,
    } = this.props;

    const { startDate, endDate, hideNativeView } = this.state;

    return (
      <div className={cx('connectForm')}>
        <div className={cx('description')}>
          {this.renderFormattedText(
            templateParser(description, {
              expireDate: this.state.endDate && this.state.endDate.toLocaleDateString('ru'),
            }),
          )}
        </div>
        {showDates && (
          <div className={cx('settings')}>
            <div className={cx('dateInputGroup')}>
              <div className={cx('dateInput')}>
                <div className={cx('dateLabel')}>Подключить</div>
                <DatePicker
                  className={cx('wide')}
                  hideNativeView={hideNativeView}
                  max={this.props.maxDateStart}
                  min={this.props.initialDate}
                  onChange={this.handleChangeStartDate}
                  value={startDate || null}
                />
              </div>
              <div className={cx('dateInput')}>
                <div className={cx('dateLabel')}>Последний день работы услуги</div>
                <DatePicker
                  className={cx('wide')}
                  disabled={expireDays > 0}
                  hideNativeView={hideNativeView}
                  max={this.props.maxDateEnd}
                  min={startDate}
                  onChange={(date) => this.setState({ endDate: date })}
                  value={endDate && (!startDate || endDate > startDate) ? endDate : null}
                />
                {!(expireDays > 0) && <div className={cx('dateHint')}>{disconnectDateHint}</div>}
              </div>
            </div>
          </div>
        )}
        {warningText ?
          <div className={cx('warning')}>
            <Status className="attention" emoji="pointingHandUp">
              {this.renderFormattedText(warningText)}
            </Status>
          </div>
        : null}
        <div className={cx('buttons')}>
          <div className={cx('confirm-button')}>
            <Button className="big" onClick={this.handleClick}>
              {confirmButtonText}
            </Button>
          </div>
          {cancelButtonText && (
            <div className={cx('cancel-button')}>
              <Link onClick={this.props.onCancel}>{cancelButtonText}</Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ConnectServiceForm.defaultProps = {
  initialDate: new Date(),
  maxDateStart: addDays(new Date().getTime(), 30), // 30 дней
  maxDateEnd: addDays(new Date().getTime(), 31), // 31 день
  showDates: true,
};

ConnectServiceForm.propTypes = {
  description: PropTypes.string,
  disconnectDateHint: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  initialDate: PropTypes.object,
  maxDateStart: PropTypes.object,
  maxDateEnd: PropTypes.object,
  expireDays: PropTypes.number,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  warningText: PropTypes.func,
  showDates: PropTypes.bool,
};

export default ConnectServiceForm;
