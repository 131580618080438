import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteInput, Button, Emoji, Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { currentStateList } from 'pages/FTTB/constants';
import getStreetsForCity from 'pages/FTTB/services/getStreetsForCity';
import { getStreetPageUrl } from 'pages/FTTB/utils/streetsHelper';
import PopupRequestHouse from 'pages/AllInOneForm/components/PopupRequestHouse/PopupRequestHouse';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const valuesFilter = (value, items) => items;
const itemCaptionExtractor = (item) => item.label;

class StreetConnection extends PureComponent {
  state = {
    currentState: currentStateList.requestForm,
    streets: [],
    street: {},
    showPopupRequestHouse: false,
  };

  onStreetChange = (street) => {
    if (street.isConnected) {
      const { streetBaseUrl } = this.props;
      window.location.href = getStreetPageUrl(streetBaseUrl, street);
    } else {
      this.setState({ currentState: currentStateList.fail, street });
    }
  };

  onShowPopupRequestHouse = () => this.setState({ showPopupRequestHouse: true });

  onClosePopupRequestHouse = () => this.setState({ showPopupRequestHouse: false });

  changeCity = () => {
    const regionsButton = document.querySelector('#toggleButton-regions-desktop');
    if (regionsButton) regionsButton.click();
  };

  fetchStreets = (term) => {
    const { id } = this.props.currentCity;

    getStreetsForCity(id, term).then(({ data }) => this.setState({ streets: data }));
  };

  resetCurrentState = () => this.setState({ currentState: currentStateList.requestForm });

  renderForm() {
    const { requestFormTitle, streetInputLabel, currentCity, withHeaderOnMainPage } = this.props;
    const { streets } = this.state;

    return (
      <div>
        <Heading className={cx('title')} level={withHeaderOnMainPage ? 2 : 1}>
          <span dangerouslySetInnerHTML={{ __html: requestFormTitle }} />
        </Heading>

        <div className={cx('streetInputWrapper')}>
          <div className={cx('streetInputLabel')}>
            <span dangerouslySetInnerHTML={{ __html: streetInputLabel }} />
            <Link className={cx('cityLink')} onClick={this.changeCity}>
              <span dangerouslySetInnerHTML={{ __html: currentCity.label }} />
            </Link>
          </div>
          <AutocompleteInput
            filter={valuesFilter}
            itemCaptionExtractor={itemCaptionExtractor}
            items={streets}
            onChange={this.onStreetChange}
            onFetchRequested={this.fetchStreets}
            showEmptyList
            showPending
            size="big"
          />
        </div>
      </div>
    );
  }

  renderFail() {
    const {
      failTitlePrefix,
      failTitlePostfix,
      failDescription,
      failButtonText,
      withHeaderOnMainPage,
    } = this.props;
    const { street, showPopupRequestHouse } = this.state;

    return (
      <div>
        <Heading className={cx('title')} level={withHeaderOnMainPage ? 2 : 1}>
          <span dangerouslySetInnerHTML={{ __html: failTitlePrefix }} />
          <Link onClick={this.resetCurrentState}>
            <span dangerouslySetInnerHTML={{ __html: street.label }} />
          </Link>
          <span dangerouslySetInnerHTML={{ __html: failTitlePostfix }} />
          <Emoji className={cx('emoji')} name="smile-sad" />
        </Heading>

        <p
          className={cx('failDescription')}
          dangerouslySetInnerHTML={{ __html: failDescription }}
        />

        <div className={cx('failBtnWrapper')}>
          <Button className="big" onClick={this.onShowPopupRequestHouse} transformer>
            <span dangerouslySetInnerHTML={{ __html: failButtonText }} />
          </Button>
        </div>

        <PopupRequestHouse
          onClose={this.onClosePopupRequestHouse}
          popupIsOpen={showPopupRequestHouse}
          street={street}
        />
      </div>
    );
  }

  render() {
    const { currentState } = this.state;

    return (
      <div className={cx('wrapper')}>
        {currentState === currentStateList.requestForm && this.renderForm()}
        {currentState === currentStateList.fail && this.renderFail()}
      </div>
    );
  }
}

StreetConnection.propTypes = {
  requestFormTitle: PropTypes.string,
  streetBaseUrl: PropTypes.string,
  streetInputLabel: PropTypes.string,
  currentCity: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number,
  }),
  failTitlePrefix: PropTypes.string,
  failTitlePostfix: PropTypes.string,
  failDescription: PropTypes.string,
  failButtonText: PropTypes.string,
  withHeaderOnMainPage: PropTypes.bool,
};

export default StreetConnection;
