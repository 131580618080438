import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

// Selectors
import { Text } from '@beef/ui-kit';

import { popupSteps } from 'pages/ProductCard/Tariff/components/ConvergenceLayout/constants';

import { getCurrentStep, getWarningDescription } from '../../../../store/selectors/modal';

// Components
import { WarningInfo } from '../WarningInfo';

// Constants

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const WarningContentLayout = ({ currentStep, description }) => (
  <div className={cx('wrapper')}>
    {currentStep !== popupSteps.upTariffSpeedFromArchive && <WarningInfo />}
    {description && <Text size="size5-r-s">{description}</Text>}
  </div>
);

const mapStateToProps = (state) => ({
  currentStep: getCurrentStep(state),
  description: getWarningDescription(state),
});

WarningContentLayout.propTypes = {
  currentStep: PropTypes.number,
  description: PropTypes.string,
};

export const WarningContent = connect(mapStateToProps)(WarningContentLayout);
