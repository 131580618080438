import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';

import { BillStepper } from 'constructorV1/components/constructorPage/BillStepper';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Варианты анимации общего контейнера в чеке ЕК */
const variantsBillContainer = {
  hidden: {
    height: 'auto',
  },
  visible: {
    height: 'auto',
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
  exit: {
    height: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
};

/** Контейнер для компонентов чека ЕК */
export const BillContainer: FC = () => {
  return (
    <LayoutGroup>
      <motion.section
        animate="visible"
        className={cx('wrapper')}
        exit="exit"
        initial="hidden"
        layout
        style={{ borderRadius: '40px' }}
        variants={variantsBillContainer}
      >
        <AnimatePresence initial={false}>
          <BillStepper />
        </AnimatePresence>
      </motion.section>
    </LayoutGroup>
  );
};
