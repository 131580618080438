import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TBonusText = {
  discount?: string;
  subTitle?: string;
  title?: string;
};

/** Компонент c заголовком, описанием и скидкой бонуса */
export const BonusText: FC<TBonusText> = ({ title, subTitle, discount }) => (
  <div className={cx('wrapper')}>
    {title && (
      <Text align="left" color="primary-invert" lineHeight={20} size="xs" weight="regular">
        {title}
      </Text>
    )}
    <div className={cx('wrapper__extra')}>
      {subTitle && (
        <Text align="left" color="secondary-invert" lineHeight={20} size="xs" weight="regular">
          {subTitle}
        </Text>
      )}
      {discount && (
        <Text
          align="left"
          className={cx('wrapper__extra--discount')}
          color="secondary"
          lineHeight={20}
          size="xs"
          weight="regular"
        >
          {discount}
        </Text>
      )}
    </div>
  </div>
);
