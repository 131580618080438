import { useEffect, useState } from 'react';

interface UseAuthPopupProps {
  authenticated: boolean;
  currentCtn: string;
  setConnectPopupData: () => void;
}

export const useAuthPopup = ({
  currentCtn,
  authenticated,
  setConnectPopupData,
}: UseAuthPopupProps) => {
  const [pending, setPending] = useState(true);

  useEffect(() => {
    if (typeof currentCtn !== 'undefined' || currentCtn === null || !authenticated) {
      setPending(false);
    }
    setConnectPopupData();
  }, [currentCtn]);

  return { pending };
};
