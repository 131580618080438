import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { DeviceContainer, PromoInstallment } from '@beef/smart-kit';
import { connect } from 'react-redux';

import {
  getCartLoadingId,
  selectBasketIdExpiration,
} from 'pages/SearchResultsPage/store/selectors';
import { DeviceWithAnalytics } from 'pages/SearchResultsPage/components/DeviceList/SmartDevice/DeviceWithAnalytics';
import { SmartDevice } from 'pages/SearchResultsPage/components/DeviceList/SmartDevice';
import { useGetDimensionGroup } from 'utils/hooks/useGetDimensionGroup';
import { setLoading } from 'pages/SearchResultsPage/store/actions/cart';
import { DevicePreview } from 'pages/MainPage2021/pageBlocks/common/components/DevicePreview';
import { onOpenDeviceModal } from 'utils/onOpenDeviceModal';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const DEVICE_LIST_ROW_PADDING = {
  MOBILE: 40,
  DESKTOP: 64,
};

const _DeviceList = ({
  devices,
  isLoading,
  isLoadingPurchased,
  setIsLoadingPurchased,
  isCategoriesSelected,
  isShopModalEnabled,
  basketIdExpiration,
}) => {
  const [slug, setSlug] = useState('');
  const deviceType = useGetDimensionGroup();
  const isMobile = deviceType === 'mobile';
  const notFullRow = devices.length < 4;
  const isSingle = devices.length === 1;

  const handleClick = (device) => {
    if (device?.isServices || device?.isTariff || device?.isElk || !device?.slug) {
      window.location = device?.link;
    } else {
      onOpenDeviceModal({ isShopModalEnabled, setSlug, deviceSlug: device?.slug });
    }
  };

  return (
    <>
      <DeviceContainer
        className={cx(
          deviceType === 'desktop' && notFullRow && 'pcDeviceListWrap',
          deviceType === 'tablet' && notFullRow && 'tabletDeviceListWrap',
          isMobile && 'mobDeviceListWrapFlex',
          isMobile && isSingle && 'mobDeviceListWrap',
        )}
        contentMaxWidth={167}
        isFetching={isLoading}
        previewCardsLength={4}
        rowsSpace={isMobile ? DEVICE_LIST_ROW_PADDING.MOBILE : DEVICE_LIST_ROW_PADDING.DESKTOP}
      >
        {!!devices?.length &&
          devices.map((device) => {
            const hasNoPrice = device.isServices || device.isElk;

            return (
              <div
                className={cx({
                  'no-price': hasNoPrice,
                  category_service_elk: isCategoriesSelected && hasNoPrice,
                })}
                data-index-search={device.id}
                key={device.id}
              >
                <DeviceWithAnalytics>
                  <SmartDevice
                    basketIdExpiration={basketIdExpiration}
                    deviceData={{
                      ...device,
                      promoInstallments: device.installments?.map((installment) => (
                        <PromoInstallment {...installment} />
                      )),
                    }}
                    isLoading={device.id === isLoadingPurchased}
                    onClick={() => handleClick(device)}
                    setLoading={setIsLoadingPurchased}
                    setSlug={setSlug}
                  />
                </DeviceWithAnalytics>
              </div>
            );
          })}
      </DeviceContainer>
      <DevicePreview {...{ slug, setSlug }} />
    </>
  );
};

_DeviceList.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  isShopModalEnabled: PropTypes.bool,
  isLoadingPurchased: PropTypes.string,
  isCategoriesSelected: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoadingPurchased: getCartLoadingId(state),
  basketIdExpiration: selectBasketIdExpiration(state),
});

export const DeviceList = connect(mapStateToProps, { setIsLoadingPurchased: setLoading })(
  _DeviceList,
);
