import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';

// Components
import { getSubstate } from '@beef/legacy-beeline-store/utils';

import { templateParser } from 'utils/format-string';

import DisconnectConfirmPopup from '../../ServicesExtension/SharingService/DisconnectConfirmPopup/DisconnectConfirmPopup';
import DisconnectSuccessPopup from '../../ServicesExtension/SharingService/DisconnectSuccessPopup/DisconnectSuccessPopup';
import DisconnectWarningPopup from '../../ServicesExtension/SharingService/DisconnectWarningPopup/DisconnectWarningPopup';

// Actions
import { changedNumber, textContent } from '../../../actions/sharing';
import {
  removeNumber,
  removeSdbNumber,
  toggleDisconnectNumberPopup,
  toggleDisconnectSuccessPopup,
  toggleDisconnectWarningPopup,
} from '../../../actions/sharingTariffConnect';
import { toggleTariffConnectionPopup } from '../../../actions/tariffConnect/toggleTariffConnectionPopup';

// Utils

class SebDisconnect extends Component {
  getConfirmTexts = () => {
    const { isSdb, currentDeletedNumber, disconnectConfirmPopupData } = this.props;
    if (isSdb) {
      if (
        currentDeletedNumber.status === 'connected' ||
        currentDeletedNumber.status.toLowerCase() === 'confirmed'
      ) {
        return {
          title: disconnectConfirmPopupData.title,
          text: templateParser(disconnectConfirmPopupData.textConnected, {
            number: currentDeletedNumber.number,
          }),
          buttonText: disconnectConfirmPopupData.buttonText,
        };
      }
      return {
        title: disconnectConfirmPopupData.titleSent,
        text: templateParser(disconnectConfirmPopupData.textConnectedSent, {
          number: currentDeletedNumber.number,
        }),
        buttonText: disconnectConfirmPopupData.buttonTextSent,
      };
    }

    return null;
  };

  render() {
    const { texts, isSdb } = this.props;
    const connectedNumber =
      this.props.numbers && this.props.numbers.length ?
        this.props.numbers.reduce(
          (result, number) =>
            number.status === 'connected' || number.status.toLowerCase() === 'confirmed' ?
              result + 1
            : result,
          0,
        )
      : 0;

    const disconnectTitle = texts && texts.disconnectSuccessInfoTitle;

    return (
      <div>
        {this.props.disconnectConfirmOpened && (
          <DisconnectConfirmPopup
            connectedNumber={connectedNumber}
            number={this.props.currentDeletedNumber}
            onClose={this.props.onDisconnectConfirmClose}
            onRemoveNumber={isSdb ? this.props.onRemoveSdbNumber : this.props.onRemoveNumber}
            opened={this.props.disconnectConfirmOpened}
            texts={this.getConfirmTexts()}
          />
        )}
        {this.props.disconnectSuccessOpened && (
          <DisconnectSuccessPopup
            infoTitle={disconnectTitle}
            number={this.props.currentDeletedNumber}
            onClose={this.props.onDisconnectSuccessClose}
            opened={this.props.disconnectSuccessOpened}
            type={this.props.disconnectSuccessType}
          />
        )}

        {this.props.disconnectWarningOpened ?
          <DisconnectWarningPopup
            number={this.props.currentDeletedNumber}
            onClose={this.props.onDisconnectWarningClose}
            onDisconnect={this.props.onRemoveNumber}
            onSwitchNumber={this.props.onSwitchNumber}
            opened={this.props.disconnectWarningOpened}
          />
        : null}
      </div>
    );
  }
}

SebDisconnect.propTypes = {
  isSdb: PropTypes.bool,
  numbers: PropTypes.array,
  disconnectConfirmOpened: PropTypes.bool,
  disconnectSuccessOpened: PropTypes.bool,
  currentDeletedNumber: PropTypes.object,
  onDisconnectConfirmClose: PropTypes.func,
  onRemoveNumber: PropTypes.func,
  onRemoveSdbNumber: PropTypes.func,
  onSwitchNumber: PropTypes.func,
  texts: PropTypes.object,
  onDisconnectSuccessClose: PropTypes.func,
  disconnectSuccessType: PropTypes.string,
  disconnectWarningOpened: PropTypes.bool,
  onDisconnectWarningClose: PropTypes.func,
  disconnectConfirmPopupData: PropTypes.object,
};

const mapStateToProps = (state) => {
  const connectionPopup = pathOr({}, ['external', 'tariffConnectionPopup'], state);
  const isSdb = pathOr(false, ['data', 'sebConnectForm', 'isSdb'], connectionPopup);

  return {
    isSdb,
    numbers: pathOr([], ['data', 'sebConnectForm', 'numbers'], connectionPopup),
    texts: getSubstate(state, textContent),
    disconnectConfirmOpened: pathOr(false, ['disconnectNumberPopup', 'opened'], connectionPopup),
    disconnectConfirmPopupData: pathOr(
      {},
      ['data', 'sebConnectForm', 'disconnectConfirmPopup'],
      connectionPopup,
    ),
    currentDeletedNumber: getSubstate(state, changedNumber),
    disconnectSuccessOpened: pathOr(false, ['disconnectSuccessPopup', 'opened'], connectionPopup),
    disconnectWarningOpened: pathOr(false, ['disconnectWarningPopup', 'opened'], connectionPopup),
    disconnectSuccessType: pathOr(null, ['disconnectSuccessPopup', 'type'], connectionPopup),
  };
};

const mapDispatchToProps = () => (dispatch) => ({
  onRemoveNumber: (number, force) => dispatch(removeNumber(number, force)),
  onRemoveSdbNumber: (number, force) => dispatch(removeSdbNumber(number, force)),
  onDisconnectConfirmClose: () => dispatch(toggleDisconnectNumberPopup(false)),
  onDisconnectSuccessClose: () => {
    dispatch(toggleDisconnectSuccessPopup()(false));
    dispatch(toggleTariffConnectionPopup(true));
  },
  onDisconnectWarningClose: () => dispatch(toggleDisconnectWarningPopup(false)),
});

const ConnectedSebDisconnect = connect(mapStateToProps, mapDispatchToProps)(SebDisconnect);

export default ConnectedSebDisconnect;
