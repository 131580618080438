import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { createImageItems } from 'constructorV1/utils/createImageItems';

import { COptionsTile } from '../COptionsTile';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCDisableOptions = {
  options: Array<{
    entityName: string;
    pictures: string[];
  }>;
};

/** Компонент заголовка и иконок опции для результирующего чека */
export const CDisableOptions: FC<TCDisableOptions> = ({ options }) => (
  <div className={cx('wrapper')}>
    {options.map(({ entityName, pictures }) => (
      <COptionsTile
        entityName={entityName}
        key={entityName}
        pictures={createImageItems(pictures)}
      />
    ))}
  </div>
);
