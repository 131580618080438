export const DEVICE_BUTTON_TYPE = {
  inBasket: 'inBasket',
  preOrder: 'preOrder',
  previewPreOrder: 'previewPreOrder',
  previewToBasket: 'previewToBasket',
  toBasket: 'toBasket',
  isServices: 'isServices',
  isElk: 'isElk',
};

const DEVICE_BUTTON_VARIANT = {
  [DEVICE_BUTTON_TYPE.inBasket]: 'secondary',
  [DEVICE_BUTTON_TYPE.preOrder]: 'tertiary',
  [DEVICE_BUTTON_TYPE.previewPreOrder]: 'main',
  [DEVICE_BUTTON_TYPE.previewToBasket]: 'main',
  [DEVICE_BUTTON_TYPE.toBasket]: 'tertiary',
  [DEVICE_BUTTON_TYPE.isServices]: 'tertiary',
  [DEVICE_BUTTON_TYPE.isElk]: 'tertiary',
};

const DEVICE_BUTTON_CAPTION = {
  [DEVICE_BUTTON_TYPE.inBasket]: 'В корзине',
  [DEVICE_BUTTON_TYPE.preOrder]: 'Предзаказ',
  [DEVICE_BUTTON_TYPE.previewPreOrder]: 'Оформить предзаказ',
  [DEVICE_BUTTON_TYPE.previewToBasket]: 'В корзину',
  [DEVICE_BUTTON_TYPE.toBasket]: 'В корзину',
  [DEVICE_BUTTON_TYPE.isServices]: 'Подробнее',
  [DEVICE_BUTTON_TYPE.isElk]: 'Подробнее',
};

export const devicePurchaseButtonDataHelper = ({ type, onClick, isLoading }) => ({
  variant: DEVICE_BUTTON_VARIANT[type],
  title: DEVICE_BUTTON_CAPTION[type],
  onClick,
  isLoading,
});

export const getPurchaseButtonType = ({
  isInBasket,
  isPreorder,
  isService,
  isElk,
  isDeviceModal,
}) => {
  if (isInBasket) {
    return DEVICE_BUTTON_TYPE.inBasket;
  }
  if (isPreorder) {
    return DEVICE_BUTTON_TYPE.preOrder;
  }
  if (isService) {
    return DEVICE_BUTTON_TYPE.isServices;
  }
  if (isDeviceModal) {
    return DEVICE_BUTTON_TYPE.previewToBasket;
  }
  if (isElk) {
    return DEVICE_BUTTON_TYPE.isElk;
  }

  return DEVICE_BUTTON_TYPE.toBasket;
};
