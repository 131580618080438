import { useCallback, useEffect, useState } from 'react';

import globalStore from 'store';
import { errorsType } from 'constructorV1/types';
import { PHONE_MASK_LENGTH } from 'constructorV1/constants';
import { setConnectionType } from 'constructorV1/store/userSlice';
import { getErrorStatus } from 'constructorV1/utils/getErrorStatus';
import { isNumberBought } from 'constructorV1/utils/isNumberBought';
import { useAppDispatch, useAppSelector } from 'constructorV1/store';
import { TValidateMnpRes } from 'constructorV1/api/fetchValidateMnp';
import { EConnectionType } from 'constructorV1/store/userSlice/types';
import { prepareMnpNumber } from 'constructorV1/utils/prepareMnpNumber';
import { getOnlyDigitsPhone } from 'constructorV1/utils/getOnlyDigitsPhone';
import { validateMnp } from 'constructorV1/store/commonSlice/api/validateMnp';
import { selectMnpFormErrors } from 'constructorV1/store/selectors/selectCommon';
import { selectUserPhoneNumber } from 'constructorV1/store/selectors/selectTotal';
import { setCurrentBillStep, setUserPhoneNumber } from 'constructorV1/store/totalSlice';
import { EBillStepper } from 'constructorV1/components/constructorPage/BillStepper/types';
import { TMnpValidateErrors } from 'constructorV1/components/commonComponents/MNPContainer/components/Form/types';

/** Хук подготовки обработчиков для формы сбора номера Mnp */
export const usePhone = () => {
  const dispatch = useAppDispatch();

  /** Введенный пользователем номер из total */
  const savedUserPhone = useAppSelector(selectUserPhoneNumber);

  /** Контентные данные для Mnp формы */
  const mnpValidateErrors = useAppSelector(selectMnpFormErrors);

  /* Локальный state для работы с вводимым значением номера телефона */
  const [userPhone, setUserPhone] = useState<string>(savedUserPhone || '');

  /* Локальный state для работы кнопкой в Mnp форме.
   * Содержит в себе сообщение об ошибке, флаг блокировки кнопки и флаг статуса загрузки данных */
  const [btnValidate, setBtnValidate] = useState({
    errorMessage: '',
    isDisabled: false,
    isLoading: false,
  });

  /** Номера в корзине */
  const boughtNumbers = globalStore.getState().external.cartData?.currentCart?.numbers ?? [];

  /** Функция установки ошибки при валидации Mnp номера */
  const handleError = useCallback((errors: keyof typeof errorsType) => {
    setBtnValidate((state) => ({
      ...state,
      isDisabled: true,
      errorMessage: getErrorStatus(errors, mnpValidateErrors as TMnpValidateErrors),
    }));
  }, []);

  /** Обработчик ввода номера телефона */
  const onChange = (value?: string) => setUserPhone(value || '');

  /** Обработчик клика на кнопку "сохранить" */
  const onClick = () => {
    /* Если длина введенного номера равна PHONE_MASK_LENGTH, пушим номер в store.
     * Иначе, сбрасываем неполноценный номер */
    if (userPhone.length === PHONE_MASK_LENGTH) {
      dispatch(setUserPhoneNumber(userPhone));
    } else {
      dispatch(setConnectionType(EConnectionType.newSim));
    }

    /* Возвращаемся к настройке конструктора после заполнения номера */
    dispatch(setCurrentBillStep(EBillStepper.bill));
  };

  /** Функция предстартовой проверки.
   * Сбрасывает ошибки, проверяет корзину с номерами, устанавливает лоадер и блокирует кнопку по условию */
  const preStartExams = () => {
    /* Если на момент запуска ф-ции есть ошибка при проверке номера, сбрасываем ее */
    if (btnValidate.errorMessage) {
      setBtnValidate((state) => ({
        ...state,
        errorMessage: '',
      }));
    }
    /* Если номер пользователем уже сохранен в store, снимаем блокировку с кнопки (доп запрос на валидацию не делаем) */
    if (savedUserPhone === userPhone) {
      setBtnValidate((state) => ({
        ...state,
        isDisabled: false,
      }));
      return false;
    }

    /* Если номер в PhoneInput заполнен не полностью, блокируем кнопку "Сохранить" */
    if (userPhone.length !== PHONE_MASK_LENGTH) {
      setBtnValidate((state) => ({
        ...state,
        isDisabled: true,
      }));
      return false;
    }

    /* Ошибка, если номер уже в корзине */
    if (isNumberBought(boughtNumbers, getOnlyDigitsPhone(userPhone))) {
      handleError(errorsType.isCtnAlreadyInBasket);
      setBtnValidate((state) => ({
        ...state,
        isDisabled: true,
      }));
      return false;
    }

    /* Выставляем флаг отправки запроса (isLoading) в true (старт запроса) */
    setBtnValidate((state) => ({
      ...state,
      isLoading: true,
    }));
    return true;
  };

  useEffect(() => {
    let promise: any = null;

    /** Функция запроса и обработки Mnp данных с установкой сообщения об ошибки */
    const validateRequest = () => {
      /* Запрос валидации номера */
      promise = dispatch(validateMnp({ phone: prepareMnpNumber(userPhone) }));
      promise
        ?.unwrap()
        .then((data: TValidateMnpRes) => {
          /* Ошибка неуспешного запроса */
          if (!data.IsSucceeded) {
            handleError(errorsType.notSuccess);
            return;
          }
          /* Ошибка номер существует */
          if (data.View?.IsExistingCtn) {
            handleError(errorsType.isExistingCtn);
            return;
          }

          /* Разблокируем кнопку */
          setBtnValidate((state) => ({
            ...state,
            isDisabled: false,
          }));
        })
        .catch((e: Error) => {
          if (e.message !== 'Aborted') {
            handleError(errorsType.failedRequest);
          }
        })
        .finally(() =>
          setBtnValidate((state) => ({
            ...state,
            isLoading: false,
          })),
        );
    };

    /** Функция старта запроса валидации и обработки ответа */
    const startValidateRequest = () => {
      /* Запускаем пре-стартовую функцию управления состоянием кнопки и сброса ошибок */
      const isCheckPassed = preStartExams();

      if (isCheckPassed) {
        /* Запускаем запрос на валидацию номера */
        validateRequest();
      }
    };

    /* Выполняем запрос валидации номера */
    startValidateRequest();

    return () => promise?.abort();
  }, [userPhone]);

  return { onChange, onClick, ...btnValidate };
};
