/* eslint-disable react/forbid-prop-types */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { type } from 'ramda';

// Components
import { Preloader, Switch } from '@beef/legacy-ui-kit';
import { Button, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import OrderPaymentResultPopup from 'widgets/OrderPaymentForm/OrderPaymentResultPopup';
import {
  Actions,
  AdditionalSim,
  BubbleTiles,
  Discount,
  GroupRowButton,
  Info,
  InfoWithPopup,
  RefreshTileCardBody,
  Regular,
  RowButton,
  RowButtonWithPopup,
  Stats,
  StrikedOutValue,
} from 'pages-components/Tiles';
import Grid from 'pages-components/Grid';
import { pushTariffOption } from 'utils/ga';
import { TARIFF_LAYOUTS } from 'pages/ProductCard/Tariff/tariffTypes';
import { AmountNumbersTag } from 'pages-components/AmountNumbersTag';

import FancyNumberWidget from '../../../components/FancyNumberWidget/FancyNumberWidget';
import { Accumulators } from '../../../components/TariffConstructor/components/Accumulators';
import AccumulatorsList from '../../../components/TariffConstructor/components/AccumulatorsList';
import TariffConstructor from '../../../components/TariffConstructor';
import SharingService from '../../../components/ServicesExtension/SharingService/SharingService';
import ServiceConnectPopup from '../../../components/ServiceConnectPopup/ServiceConnectPopup';
import TariffConnectPopup from '../../../components/TariffConnectPopup/TariffConnectPopup';
import TariffConnect from '../TariffConnect';
// HOC's
import FancyNumberWidgetHOC from '../../../hoc/FancyNumberWidgetHOC';
import TariffConnectHOC from '../../../hoc/TariffConnectHOC';
import { PACKS_TITLES, getParams, getParamsGroup } from './mapper';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const getTotalPrice = (prices) =>
  prices?.reduce((acc, item) => {
    const price = item || 0;
    return price + acc;
  }, 0);

const setAutoProlongText = (autoProlongPackage) =>
  `Добавим ${autoProlongPackage?.value} ${autoProlongPackage?.unit} за 
                             ${autoProlongPackage?.priceValue} ${autoProlongPackage?.priceUnit},
                             когда интернет закончится`;

/**
 * Тело карточки тарифа, содержит в себе Tiles, Кнопку подключения тарифа и SimOrderBenefits.
 * ------------------------------------------------------------------------------------------
 * @param parameterGroups - Список параметров тела карточки;
 * @param isAuthenticated - Булевый парамерт авторизации пользователя;
 * @param isConnected - Булевый парамерт состояния подключения;
 * @param discountValue - Скидка;
 */
class TariffCardBody extends Component {
  state = {
    chosenNumber: null,
    isRenderConnectButton: false,
    isLayoutYoung: false,
    selectedPackagesSocs: [],
    selectedPackagesPrices: [],
    autoProlongSwitch: true,
    ctnChosenIndex: 0,
  };

  componentDidMount() {
    const { onSetChosenSocs } = this.props;

    let selectedPackagesSocs = [];
    let selectedPackagesPrices = [];

    if (this.props.isForYoung && this.props.isForYoungDetailEnabled) {
      selectedPackagesSocs = this.props.availablePackages?.groups?.map(
        (group) => group?.parameters?.[0]?.soc,
      );
      selectedPackagesPrices = this.props.availablePackages?.groups?.map((group) => {
        const firstParam = group?.parameters?.[0];
        return firstParam?.priceValue && firstParam?.soc ? firstParam.priceValue : 0;
      });

      let tariffSocs = null;

      if (selectedPackagesSocs) {
        const filteredPackages = selectedPackagesSocs.filter((item) => !!item).join(',');
        if (filteredPackages) {
          tariffSocs = filteredPackages;
        }
      }

      onSetChosenSocs(tariffSocs);
    }

    this.setState({
      isRenderConnectButton: true,
      isLayoutYoung: this.props.layoutType === TARIFF_LAYOUTS.young,
      selectedPackagesSocs,
      selectedPackagesPrices,
    });
  }

  setChosenNumber = (number) => {
    this.setState({ chosenNumber: number });
  };

  setCtnChosenIndex = (ctnChosenIndex) => {
    this.setState({
      ctnChosenIndex,
    });
  };

  handlePackageSelect = ({ soc, price, index, packageTitle }) => {
    const { selectedPackagesPrices, autoProlongSwitch, selectedPackagesSocs } = this.state;
    const { onSetChosenSocs } = this.props;
    const newSoc = selectedPackagesSocs[index] === soc ? null : soc;

    if (
      !newSoc &&
      packageTitle &&
      typeof packageTitle === 'string' &&
      (packageTitle.toLowerCase() === PACKS_TITLES.kbyte.toLowerCase() ||
        packageTitle.toLowerCase() === PACKS_TITLES.seconds.toLowerCase())
    ) {
      return false;
    }

    const newPrice = newSoc ? price : 0;
    selectedPackagesSocs[index] = newSoc;
    selectedPackagesPrices[index] = newPrice;
    if (index === 0) {
      const socAutoProlong = this.props.availablePackages?.groups?.[0]?.parameters?.find(
        (param) => param?.soc === soc,
      )?.socAutoProlong;
      selectedPackagesSocs[3] = autoProlongSwitch && newSoc ? socAutoProlong : null;
    }

    onSetChosenSocs(selectedPackagesSocs.filter((item) => !!item).join(',') || null);

    this.setState({
      selectedPackagesSocs,
      selectedPackagesPrices,
    });
  };

  handleSwitch = () => {
    const { selectedPackagesSocs } = this.state;
    const { onSetChosenSocs } = this.props;
    const socAutoProlong = this.props.availablePackages?.groups?.[0]?.parameters?.find(
      ({ soc }) => soc === selectedPackagesSocs[0],
    )?.socAutoProlong;
    selectedPackagesSocs[3] = !this.state.autoProlongSwitch ? socAutoProlong : null;

    onSetChosenSocs(selectedPackagesSocs.filter((item) => !!item).join(',') || null);

    this.setState({
      autoProlongSwitch: !this.state.autoProlongSwitch,
      selectedPackagesSocs,
    });
  };

  /**
   * Отрисовка обычного текстового кирпичика;
   * ----------------------------------------
   * @param param - Объект с параметрами;
   */
  renderRegular = (param) => {
    let paramType = null;

    switch (param.widgetType) {
      case 'bee-logo':
        paramType = 'beeLogo';
        break;
      case 'with-image':
        paramType = 'withImage';
        break;
      case 'messengers':
        paramType = 'messengers';
        break;
      case 'tethering':
        paramType = 'tethering';
        break;
      case 'starting-balance':
        paramType = 'starting-balance';
        break;
      default:
        paramType = 'regularButton';
    }

    const params = getParams(paramType, param);

    let className = null;
    if (!param.value && param.widgetType === 'normal') {
      className = cx('imitateTitle');
    }

    return <Regular className={className} {...params} />;
  };

  /**
   * Отрисовка Tiles Конструктора c двумя парамерами в группе.
   * Модальное окно конструктора прокидывается и вызывается из GroupRowButton;
   * ------------------------------------------------------------------
   * @param param - Объект с параметрами;
   */
  renderRegularGroup = (param) => {
    const { connectUrl } = param.data;

    const paramsList = getParamsGroup(param.data.items);

    return (
      <GroupRowButton isButton={connectUrl} paramsList={paramsList}>
        {this.renderTariffConstructor(param, false)}
      </GroupRowButton>
    );
  };

  /**
   * Отрисовка Tiles Конструктора. Модальное окно конструктора вызывается изнутри;
   * Для вызова модального окна внутрь прокидывается urls для вызовов;
   * ------------------------------------------------------------------
   * @param param - Объект с параметрами;
   * @param rowButton - Булевое значение по которому определяется как
   *                    отображать конструктор, с кнопкой или только popup;
   */
  renderTariffConstructor = (param, rowButton = true) => {
    const { isAuthenticated, isConnected } = this.props;
    const { selectorDataUrl, connectUrl, checkStatusUrl } = param.data;

    return (
      <TariffConstructor
        authenticated={isAuthenticated}
        bannerData={{ description: param.label }}
        checkStatusUrl={checkStatusUrl}
        connectUrl={connectUrl}
        onClickCallback={() => pushTariffOption('Настройте минуты')}
        rowButton={rowButton}
        selectorDataUrl={selectorDataUrl}
        tariffConnected={isConnected}
      />
    );
  };

  /**
   * Добавляет аккумуляторы на страницу тарифов;
   */
  renderAccumulators = () => {
    const { accumulators } = this.props;

    if (!accumulators) {
      return null;
    }

    return type(accumulators) === 'Array' ?
        <AccumulatorsList accumulators={accumulators} />
      : <Accumulators basicRests={accumulators} inline />;
  };

  /** Отрисовка перечеркнутой цены */
  renderCrossing = (param) => (
    <div className={cx('hideBorder')}>
      <StrikedOutValue unit={param.unit} value={param.value} />
    </div>
  );

  /** Отрисовка Бабла для downsell тарифа; */
  renderBubbleTile = (param) => (
    <div className={cx('hideBorder')}>
      <div className={cx('tileBubble')}>
        <BubbleTiles icon={param.icon} iconName="present" label={param.label} />
      </div>
    </div>
  );

  render() {
    const {
      chosenNumber,
      isRenderConnectButton,
      isLayoutYoung,
      selectedPackagesSocs,
      selectedPackagesPrices,
      autoProlongSwitch,
      ctnChosenIndex,
    } = this.state;

    const {
      availableForConnect,
      availablePackages,
      isSimDiscountDeliveryOnly,
      isResetAdsPreloader,
      isForYoungDetailEnabled,
      parameterGroups,
      resetAdsButton,
      resetAdsUrl,
      esim,
      amountNumbersForShare,
    } = this.props;

    // Отображаем на сценарии подключения тарифа-контроффера. При переходе из модального окна контроффера
    // приходит два параметра: resetAdsButton и resetAdsUrl; Если они есть, рисуем кнопку
    // возврата на странцу с которой пришли;
    const isResetAdsButton = !!(resetAdsButton && resetAdsUrl);

    const autoProlongPackage = availablePackages?.groups?.[0]?.parameters?.find?.(
      ({ soc }) => soc === selectedPackagesSocs[0],
    );
    const autoProlongText = setAutoProlongText(autoProlongPackage);

    return (
      <div className={cx('tariffCardBody')}>
        {!!availablePackages && (
          <Grid.ProductParameters
            className={cx('group', isLayoutYoung && 'youngFont')}
            isLayoutYoung={isLayoutYoung}
            noItemBorder
            parameterNote={availablePackages.parameterNote}
            selectedPackagesPrices={getTotalPrice(selectedPackagesPrices)}
            title={availablePackages.title}
          >
            {availablePackages.groups.map((group, index) => (
              <Fragment key={+index}>
                <Stats
                  {...group}
                  bodyClass={cx('statsBody')}
                  className={cx('stats', isLayoutYoung && 'youngFont')}
                  handlePackageSelect={(soc, price) => {
                    this.handlePackageSelect({ soc, price, index, packageTitle: group.title });
                  }}
                  isForYoungDetailEnabled={isForYoungDetailEnabled}
                  selectedSoc={selectedPackagesSocs[index]}
                />
                {group.parameters.find(
                  (param) => param.socAutoProlong && selectedPackagesSocs.includes(param.soc),
                ) && (
                  <div className={cx('switch')}>
                    <div className={cx('switch-left')}>
                      <div className={cx('switch-left__title')}>Продлевать интернет</div>
                      <div className={cx('switch-left__price')}>{autoProlongText}</div>
                    </div>
                    <Switch
                      checked={autoProlongSwitch}
                      className={cx('switch-toggle')}
                      onChange={this.handleSwitch}
                    />
                  </div>
                )}
              </Fragment>
            ))}
          </Grid.ProductParameters>
        )}

        {parameterGroups.map((item, key) => (
          <Grid.ProductParameters
            className={cx('group', isLayoutYoung && 'youngFont')}
            isLayoutYoung={isLayoutYoung}
            key={+key}
            title={item.title}
          >
            {item.parameters.map((param, index) => (
              <Fragment key={+index}>
                {(param.widgetType === 'normal' ||
                  param.widgetType === 'constructor-minutes' ||
                  param.widgetType === 'bee-logo' ||
                  param.widgetType === 'tethering' ||
                  param.widgetType === 'messengers' ||
                  param.widgetType === 'unlim-call') &&
                  this.renderRegular(param)}

                {typeof window !== 'undefined' &&
                  param.widgetType === 'starting-balance' &&
                  this.renderRegular({
                    ...param,
                    youthTariffPrice: getTotalPrice(selectedPackagesPrices),
                  })}

                {param.widgetType === 'refresh' && (
                  <RefreshTileCardBody direction="row" label={param.label} text={param.value} />
                )}

                {(amountNumbersForShare || amountNumbersForShare !== 0) &&
                  param.widgetType === 'sharing' && (
                    <AmountNumbersTag
                      amount={amountNumbersForShare}
                      className={cx('amount-numbers')}
                    />
                  )}

                {param.widgetType === 'sharing' && item.description && (
                  <div className={cx('param__description')}>
                    <Text color="grey60" size="size6-r">
                      {item.description}
                    </Text>
                  </div>
                )}

                {param.widgetType === 'sharing' && (
                  <div className={cx('greyRow')}>
                    <RowButtonWithPopup {...getParams('withPopup', param)} />
                  </div>
                )}

                {param.widgetType === 'additional-sim' && (
                  <AdditionalSim
                    {...param}
                    data={{
                      ...param.data,
                      defaultSimCount:
                        param.data.defaultSimCount > param.data.maxSimCount ?
                          param.data.maxSimCount
                        : param.data.defaultSimCount,
                    }}
                  />
                )}

                {param.widgetType === 'with-image' && this.renderRegular(param)}

                {param.widgetType === 'constructor-group' && this.renderRegularGroup(param)}

                {param.widgetType === 'crossing' && this.renderCrossing(param)}

                {param.widgetType === 'bubble' && this.renderBubbleTile(param)}

                {param.widgetType === 'with-link' && (
                  <RowButton {...getParams('rowButton', param)} link={param.data.url} />
                )}

                {param.widgetType === 'seb-popup' && (
                  <RowButton
                    {...getParams('rowButton', param)}
                    onClick={this.props.openUpsaleOfferPopup}
                  />
                )}

                {param.widgetType === 'constructor' && this.renderTariffConstructor(param)}

                {param.widgetType === 'action' && <Actions {...getParams('action', param)} />}

                {param.widgetType === 'rests' && this.renderAccumulators()}

                {param.widgetType === 'discounted-sim' && (
                  <Discount
                    {...getParams('discountedSim', {
                      ...param,
                      isSimDiscountDeliveryOnly,
                    })}
                  />
                )}

                {param.widgetType === 'discounted-price' && (
                  <Discount
                    {...getParams('discountedPrice', {
                      ...param,
                      isSimDiscountDeliveryOnly: true,
                    })}
                  />
                )}

                {param.widgetType === 'extra-value' && (
                  <Discount {...getParams('extraValue', param)} />
                )}

                {(param.widgetType === 'with-popup' || param.widgetType === 'amediateka') && (
                  <RowButtonWithPopup {...getParams('withPopup', param)} />
                )}

                {param.widgetType === 'choose-service' && (
                  <InfoWithPopup
                    fetchUrl={param?.data?.popupUrl}
                    isLayoutYoung={isLayoutYoung}
                    popupClassName={cx(isLayoutYoung && 'youngFont')}
                    value={param.value}
                  />
                )}

                {param.widgetType === 'fancy-number' && (
                  <Regular
                    className={cx(isLayoutYoung && 'youngFont', 'fancyNumber')}
                    description={param.label}
                  >
                    <FancyNumberWidgetHOC
                      chosenNumber={chosenNumber}
                      onNumberChange={this.setChosenNumber}
                      onRootChosenIndexChange={this.setCtnChosenIndex}
                      rootChosenIndex={ctnChosenIndex}
                    >
                      <FancyNumberWidget />
                    </FancyNumberWidgetHOC>
                  </Regular>
                )}

                {param.widgetType === 'additional-service' && (
                  <Info {...param} connected={!!param.connected} isLayoutYoung={isLayoutYoung} />
                )}
              </Fragment>
            ))}
          </Grid.ProductParameters>
        ))}

        {/*
          Кнопки подключения тарифа
        */}
        <div id="bottomConnectButton">
          {isRenderConnectButton && (
            <TariffConnectHOC chosenNumber={chosenNumber}>
              <TariffConnect />
            </TariffConnectHOC>
          )}

          {esim && availableForConnect && (
            <div className={cx('eSimButtonBlock', !!isLayoutYoung && 'youngFont')}>
              <Button fullWidth onClick={this.props.setESimOpenModal} variant="secondary">
                {esim.text}
              </Button>
            </div>
          )}
        </div>

        {/*
          Кнопка для отказа от предложения Контр Оффера, выводится
          только в случае перехода из контроффера в карточку тарифа
         */}
        {isResetAdsButton && (
          <div className={cx('buttonReset')}>
            {isResetAdsPreloader ?
              <Preloader />
            : <Button fullWidth onClick={this.props.resetAdsButtonHandler} variant="secondary">
                {resetAdsButton}
              </Button>
            }
          </div>
        )}

        <SharingService
          isAuthenticated={this.props.isAuthenticated}
          openSebPopup={this.props.openSebPopup}
        />

        <ServiceConnectPopup />
        <TariffConnectPopup
          cartAnalyticsData={{
            mnpAnalyticsPlace: 'Tariff_card',
            mnpAnalyticsCategory: 'tariffs/details',
            cartAnalyticsCategory: 'tariffs/details',
            cartAnalyticsList: 'Tariff_card',
          }}
          includeNumber={!!chosenNumber}
          newNumber={chosenNumber}
          onNumberChange={this.setChosenNumber}
          onRootCtnChosenIndexChange={this.setCtnChosenIndex}
          rootCtnChosenIndex={ctnChosenIndex}
        />

        <OrderPaymentResultPopup />
      </div>
    );
  }
}

TariffCardBody.propTypes = {
  esim: PropTypes.shape({
    text: PropTypes.string,
  }),
  layoutType: PropTypes.string,
  availableForConnect: PropTypes.bool,
  setESimOpenModal: PropTypes.func,
  isSimDiscountDeliveryOnly: PropTypes.bool,
  isResetAdsPreloader: PropTypes.bool,
  resetAdsButton: PropTypes.string,
  resetAdsUrl: PropTypes.string,
  resetAdsButtonHandler: PropTypes.func,
  parameterGroups: PropTypes.array,
  availablePackages: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  discountValue: PropTypes.number,
  accumulators: PropTypes.array,
  openSebPopup: PropTypes.func,
  openUpsaleOfferPopup: PropTypes.func,
  onSetChosenSocs: PropTypes.func,
  isConnected: PropTypes.bool,
  isForYoung: PropTypes.bool,
  isForYoungDetailEnabled: PropTypes.bool,
  amountNumbersForShare: PropTypes.number,
};

export default TariffCardBody;
