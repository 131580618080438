import { setConnectionType } from 'constructorV1/store/userSlice';
import { useAppDispatch, useAppSelector } from 'constructorV1/store';
import { EConnectionType } from 'constructorV1/store/userSlice/types';
import { setCurrentBillStep, setUserPhoneNumber } from 'constructorV1/store/totalSlice';
import { EBillStepper } from 'constructorV1/components/constructorPage/BillStepper/types';
import { selectMnpProps } from 'constructorV1/components/constructorPage/BillContainer/selectors/selectMnpProps';

/** Хук подготовки данных Mnp-формы */
export const useMnpProps = () => {
  const dispatch = useAppDispatch();

  /* Общие контентные данные и выбранный номер телефона пользователя */
  const {
    billContent: {
      mnpContent: { title, btnText },
    },
    userPhoneNumber,
  } = useAppSelector(selectMnpProps);

  /** Общая функция сброса настройки MNP-формы и возврата к покупке newSim */
  const onResetForm = () => {
    /* Обнуляем введенный номер MNP */
    dispatch(setUserPhoneNumber(''));
    /* Меняем connectionType на newSim, что переключит таб способа подключения тарифа */
    dispatch(setConnectionType(EConnectionType.newSim));
    /* Переходим на главный чек ЕК */
    dispatch(setCurrentBillStep(EBillStepper.bill));
  };

  return {
    headerProps: {
      title,
      /** Обработчик сброса mnp-номера и возврат к newSim */
      onClick: () => {
        /* Если телефон для mnp-сценария добавлен, перейдем в общий чек. Иначе, сбросим настройку mnp */
        if (userPhoneNumber) {
          dispatch(setCurrentBillStep(EBillStepper.bill));
        } else {
          onResetForm();
        }
      },
    },
    formProps: {
      phone: userPhoneNumber,
      btnText,
    },
  };
};
