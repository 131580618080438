import { createSelector } from '@reduxjs/toolkit';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { RootState } from 'constructorV1/store';
import { selectOptionsSections } from 'constructorV1/store/selectors/selectTariffSettings';

/** Селектор выборки total ЕК */
export const selectTotal = (state: RootState) => state.total;

/** Селектор выборки введенного номера пользователя (используется в Mnp-сценарии) */
export const selectUserPhoneNumber = createSelector(selectTotal, (total) => total.userPhoneNumber);

/** Селектор выборки активных опций total ЕК */
export const selectTotalOptionsSocs = createSelector(selectTotal, (total) => total.optionsSocs);

/** Селектор выборки текущего шага в чеке (bill, mnpForm) */
export const selectCurrentBillStep = createSelector(selectTotal, (total) => total.currentBillStep);

/** Селектор выборки текущего сока тарифа */
export const selectTariffSoc = createSelector(selectTotal, (total) => total.soc);

/** Селектор выборки текущего id тарифа */
export const selectIdConstructor = createSelector(selectTotal, (total) => total.constructorId);

/** Селектор выборки текущей цены */
export const selectTariffPrice = createSelector(selectTotal, (total) => total.price);

/** Селектор выборки текущей цены со скидкой */
export const selectTariffPriceWithDiscount = createSelector(
  selectTotal,
  (total) => total.priceWithDiscount,
);

/** Селектор выборки текущего значения пресета Гб */
export const selectGbValue = createSelector(selectTotal, (total) => total.gbValue);

/** Селектор выборки текущего значения пресета Мин */
export const selectMinValue = createSelector(selectTotal, (total) => total.minValue);

/** Селектор подсчета стоимости активных опций и пресетов ЕК */
export const selectTotalPrice = createSelector(
  selectOptionsSections,
  selectTotalOptionsSocs,
  selectTariffPriceWithDiscount,
  selectTariffPrice,
  (sections, optionsSocs, price, oldPrice) => {
    /* Итоговая новая/старая цена пресетов, опций и доп. опций */
    const totalTariffPrice = { totalPrice: price, totalOldPrice: oldPrice };

    /* Перебираем все доступные секции с опциями */
    sections?.forEach((section) => {
      /* Перебираем все доступные секции с опциями */
      return section.options?.reduce((acc, option) => {
        /* Если в option присутствует сок (serviceCategory) и массив активных опций (optionsSoc)
         * включает в себя текущий сок, суммируем стоимость опции в price */
        if (option.serviceCategory && optionsSocs.includes(option.serviceCategory)) {
          /* rcRate может содержать текст "бесплатно" */
          if (typeof option.rcRate === 'number') {
            acc.totalPrice += option.rcRate;
            /* Если в option присутствует старая цена (rcRateWithDiscount) суммируем ее в oldPrice, иначе прибавляем текущую цену */
            acc.totalOldPrice +=
              option.rcRateWithDiscount ? option.rcRateWithDiscount : option.rcRate;
          }
          return acc;
        }
        return acc;
      }, totalTariffPrice);
    });

    return { ...totalTariffPrice };
  },
);
