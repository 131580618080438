import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, TabsList } from '@beef/legacy-ui-kit';
import { path } from 'ramda';

import Icon from 'pages-components/Icon';
import styles from 'pages/ProductCard/components/FamilyNavigation/FamilyNavigation.pcss';

const cx = classNames.bind(styles);

const FamilyNavigation = ({ familyIsConnected, familyNav, alias, isLightTheme }) => {
  const connectedTab =
    (familyIsConnected && alias) || (familyNav.find((item) => item.isConnected) || {}).alias;

  return (
    <TabsList className={cx('familyNavigation')} theme={isLightTheme ? 'white' : 'black'}>
      {familyNav.map((tab, index) => (
        <Tab
          active={tab.alias === alias}
          className={cx('tabsClassName')}
          href={tab.alias === alias ? null : tab.link}
          key={index}
        >
          {tab.label}
          {connectedTab === tab.alias && (
            <Icon className={cx('iconTariffCheck')} name="tariffCheck" />
          )}
        </Tab>
      ))}
    </TabsList>
  );
};

FamilyNavigation.propTypes = {
  familyIsConnected: PropTypes.bool,
  familyNav: PropTypes.array,
  alias: PropTypes.string,
  isLightTheme: PropTypes.bool,
};

export default connect((state) => ({
  familyIsConnected: path(['external', 'serviceConnectionPopup', 'familyIsConnected'], state),
}))(FamilyNavigation);
