import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import { motion } from 'framer-motion';
import classNames from 'classnames/bind';

import { EConnectionType } from 'constructorV1/store/userSlice/types';

import { TTariffTab, TariffTab } from '../TariffTab';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TTariffTabs = {
  activeTab?: EConnectionType;
  /* Флаг позволяет определить показ элемента, без необходимости реализации такой логики в родителе по условию */
  isVisible?: boolean;
  onChange: (id: EConnectionType) => void;
  tabs?: TTariffTab[];
  title?: string;
};

/** Общий компонент выбора варианта подключения тарифа (simOrderForm, mnp) */
export const TariffTabs: FC<TTariffTabs> = ({
  tabs,
  isVisible = true,
  onChange,
  title,
  activeTab,
}) => {
  return isVisible && tabs?.length ?
      <motion.section className={cx('wrapper')} layout>
        {title && (
          <Text align="left" color="secondary" lineHeight={20} size="xs" weight="regular">
            {title}
          </Text>
        )}
        <div className={cx('wrapper__tabs')}>
          {tabs.map((tab) => (
            <TariffTab key={tab.title} {...{ ...tab, onChange }} isActive={activeTab === tab.id} />
          ))}
        </div>
      </motion.section>
    : null;
};
