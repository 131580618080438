import { shallowEqual } from '@beef/utils';

import { useAppDispatch, useAppSelector } from '../store';
import {
  goToFirstStep as goToFirstStepAction,
  nextStep as nextStepAction,
  prevStep as prevStepAction,
} from '../store/slices/stepperSlice';

/** Хук получения данных степпера и методами управления переходов */
export const useStepNavigation = () => {
  const dispatch = useAppDispatch();
  const { currentStep, userType, isLoadingNext } = useAppSelector(
    (state) => ({
      userType: state.stepper.userType,
      currentStep: state.stepper.currentStep,
      isLoadingNext: state.stepper.isLoadingNext,
    }),
    shallowEqual,
  );

  const nextStep = (skip?: number) => dispatch(nextStepAction(skip));
  const prevStep = (skip?: number) => dispatch(prevStepAction(skip));
  const goToFirstStep = () => dispatch(goToFirstStepAction());

  return {
    currentStep,
    userType,
    nextStep,
    prevStep,
    goToFirstStep,
    isLoadingNext,
  };
};
