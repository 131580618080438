import React from 'react';
import { StatefulSearchInput } from '@beef/ui-kit';

import { useStreetInput } from './hooks/useStreetInput';
import { useCheckAddressInputsTexts } from '../../selectors/useCheckAddressInputsTexts';

export const FmcStreetInput = () => {
  const { streetPlaceholder, streetTitle } = useCheckAddressInputsTexts();
  const inputProps = useStreetInput();
  const placeholder = !inputProps.disabled ? streetPlaceholder : undefined;
  return (
    <StatefulSearchInput
      debounceInterval={500}
      label={streetTitle}
      leftAside={undefined}
      placeholder={placeholder}
      {...inputProps}
    />
  );
};
