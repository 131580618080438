import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withReduxStore } from '@beef/redux';

// Components
import { initializeStore } from './store';
import { dataConverter } from './store/dataConverter';
import { ModalContainer } from './components/ModalContainer';
import { AddressRequestForm } from './components/ModalSteps/AddressRequestForm';
import { WarningAnswers } from './components/ModalSteps/WarningAnswers';
import { CallBackRequest } from './components/ModalSteps/CallBackRequest';
import { TariffDetailsForm } from './components/ModalSteps/TariffDetailsForm';
// Selectors
import { getCurrentStep } from './store/selectors/modal';
// Constants
import { popupSteps } from './constants';
// Actions
import { setActiveTab } from './store/actions/orderTariffDetailsData';

const ConvergenceLayout = ({
  soc,
  isOpen,
  generation,
  currentStep,
  handleConvergencePopup,
  activePresetTab,
  setPresetTab,
}) => {
  useEffect(() => {
    setPresetTab(activePresetTab);
  }, [activePresetTab]);

  return (
    <ModalContainer {...{ soc, isOpen, generation, handleConvergencePopup }}>
      {currentStep === popupSteps.addressRequestForm && <AddressRequestForm />}
      {(currentStep === popupSteps.requestExists ||
        currentStep === popupSteps.connectCompleted ||
        currentStep === popupSteps.upTariffSpeedFromArchive) && <WarningAnswers />}
      {(currentStep === popupSteps.callBackRequest ||
        currentStep === popupSteps.connectFail ||
        currentStep === popupSteps.confirmCancelConnect) && <CallBackRequest />}
      {currentStep === popupSteps.tariffDetailsForm && (
        <TariffDetailsForm {...{ handleConvergencePopup }} />
      )}
    </ModalContainer>
  );
};

const mapStateToProps = (state) => ({
  currentStep: getCurrentStep(state),
});

const mapDispatchToProps = {
  setPresetTab: setActiveTab,
};

ConvergenceLayout.propTypes = {
  soc: PropTypes.string,
  isOpen: PropTypes.bool,
  generation: PropTypes.number,
  currentStep: PropTypes.number,
  handleConvergencePopup: PropTypes.func,
  activePresetTab: PropTypes.string,
  setPresetTab: PropTypes.func,
};

const ConvergenceLayoutWithStore = compose(
  withReduxStore(initializeStore, {
    passThroughProps: ({
      soc,
      isOpen,
      generation,
      handleConvergencePopup,
      discountDescription,
      activePresetTab,
    }) => ({
      soc,
      isOpen,
      generation,
      handleConvergencePopup,
      discountDescription,
      activePresetTab,
    }),
    mapPropsToState: dataConverter,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(ConvergenceLayout);

export { ConvergenceLayoutWithStore as ConvergenceLayout };
