import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@beef/ui-kit';

import { useSelectLoadingStatus } from './selectors/useSelectLoadingStatus';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TButtonContainer = {
  onClick: () => void;
  payButton?: string;
  totalPriceText?: string;
};

/** Обертка над блоком кнопки и доп. описанием */
export const ButtonContainer = memo<TButtonContainer>(({ onClick, payButton, totalPriceText }) => {
  const { isButtonLoading: isLoading } = useSelectLoadingStatus();
  return (
    <div className={cx('wrapper')}>
      {payButton && (
        <Button fullWidth size="m" variant="main" {...{ onClick, isLoading }}>
          {payButton}
        </Button>
      )}
      {totalPriceText && (
        <Text color="grey60" size="size6-r">
          {totalPriceText}
        </Text>
      )}
    </div>
  );
});
