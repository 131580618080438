import { openAuthModal } from '@beef/layout-kit/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getTimeCapsuleFieldNames, getValidateAndSetError, setError, setMetaFlag } from '.';
import { postTimeCapsule } from '../../api/index';
import { StorageHelper, getHasErrors, processTextWithCustomRegex, retry } from '../../utils';
import { pushAnniversaryEvent } from '../../utils/analytics';
import { openModal } from '../modal';
import { selectContent } from '../selectors';
import {
  selectData,
  selectIsAuthorized,
  selectIsPhoneUser,
  selectIsSmsSent,
  selectSmsData,
  selectTimeCapsule,
} from './selectors';

export const validateTimeCapsule = createAsyncThunk(
  'time-capsule/validate',
  async (_, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    try {
      const state = selectTimeCapsule(getState());
      const content = selectContent(state);

      const phoneErrorContent = content?.phone?.error;

      const validateAndSetError = getValidateAndSetError(dispatch, state);

      const requiredValidator = ['required', (v) => !v];
      const smsValidator = [
        'format',
        (v) =>
          new RegExp(content?.sms?.regex || '[^a-zA-Zа-яА-ЯёЁ!№%:,.;"\'()?_+= ,\\d-]+').test(v),
      ];

      const fieldNameValidators = {
        sms: [requiredValidator, smsValidator],
      };

      const hasErrors = getHasErrors(fieldNameValidators, validateAndSetError);

      if (hasErrors) return;

      const isPhoneUser = selectIsPhoneUser(state);

      if (!isPhoneUser) {
        dispatch(openModal('nonPhoneUser'));
        return;
      }

      const isAuthorized = !!selectIsAuthorized(state);

      if (!isAuthorized) {
        // NOTE: Persist values
        getTimeCapsuleFieldNames().forEach((fieldName) => {
          const value = selectData(state)?.[fieldName]?.value;
          StorageHelper[fieldName] = value;
        });
        openAuthModal();
        return;
      }

      const hasParticipated = selectIsSmsSent(state);
      if (hasParticipated) {
        dispatch(
          setError({
            fieldName: 'sms',
            value: phoneErrorContent?.hasParticipated,
          }),
        );
        return;
      }

      dispatch(openModal('confirmation'));
    } catch (e) {
      pushAnniversaryEvent({
        error: {
          code: undefined,
          text: e.message,
        },
      });
      dispatch(openModal('error'));
    }
  },
);

export const submitTimeCapsule = createAsyncThunk('time-capsule/submit', async (_, thunkApi) => {
  const { dispatch, getState } = thunkApi;
  try {
    const state = selectTimeCapsule(getState());
    const content = selectContent(state);
    const strip = content?.sms?.strip;

    const { value: _smsText } = selectSmsData(state);

    const smsText = processTextWithCustomRegex(_smsText, strip?.value, strip?.placeholder);

    await retry(() => postTimeCapsule(smsText));
    pushAnniversaryEvent('time_capsule_sent');

    dispatch(
      setMetaFlag({
        key: 'isSmsSent',
        value: true,
      }),
    );

    dispatch(openModal('success'));
  } catch (e) {
    pushAnniversaryEvent({
      error: {
        code: undefined,
        text: e.message,
      },
    });
    dispatch(openModal('error'));
  }
});

export const initializeForm = createAsyncThunk(
  'time-capsule/initialize-form',
  async (_, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    try {
      const state = getState()?.form || {};

      const isAuthorized = state?.meta?.isAuthorized;

      if (!isAuthorized) {
        getTimeCapsuleFieldNames().forEach((fieldName) => StorageHelper[fieldName]);
        return;
      }

      pushAnniversaryEvent('time_capsule_auth_success');
    } catch (e) {
      pushAnniversaryEvent({
        error: {
          code: undefined,
          text: e.message,
        },
      });
      dispatch(openModal('error'));
    }
  },
);
