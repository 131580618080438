import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { HowItWorksBlock } from './components/HowItWorksBlock';
import { BenefitsBlock } from './components/BenefitsBlock';
import { AdditionalInfoBlock } from './components/AdditionalInfoBlock';

const cx = classNames.bind(styles);

/**
 * Блок с информацией о том, как работает услуга MNP
 */
export const InfoAboutServiceSection: FC = () => {
  return (
    <section className={cx('section-wrapper')} id="infoAboutServiceSection">
      <div className={cx('content-wrapper')}>
        <HowItWorksBlock />

        <BenefitsBlock />

        <AdditionalInfoBlock />
      </div>
    </section>
  );
};
