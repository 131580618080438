import React, { FC } from 'react';
import { FinalForm } from '@beef/form';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import { map } from '@beef/utils';

import {
  getValidatorsRedirectAlways,
  phoneMaskSettings,
} from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/const';
import {
  MultipleOptionsFormProps,
  RedirectFormItem,
} from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/types';
import { RInput } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/RInput';
import { getPhoneWithoutFormat } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/utils';
import {
  defaultOptions,
  idFormRedirectSettings,
} from 'pages/Services/RedirectServiceCardLanding/const';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const FormRedirectAlways: FC<MultipleOptionsFormProps> = ({
  options,
  content,
  onChangePhone,
  onSubmitHandle,
}) => {
  const onSubmit = () => {
    const newOptionsByConditions = { ...map(options, getPhoneWithoutFormat), redirectAlways: true };
    const dataForStartRequest = newOptionsByConditions;
    const newOptions = { ...defaultOptions, doNotRedirect: false, ...newOptionsByConditions };
    onSubmitHandle({
      dataForStartRequest,
      options: newOptions,
    });
  };

  const onChange = (value: object | undefined) => {
    onChangePhone(value as RedirectFormItem);
  };

  return (
    <FinalForm
      getValidators={getValidatorsRedirectAlways}
      id={idFormRedirectSettings}
      onChange={onChange}
      onSubmit={onSubmit}
      values={options}
    >
      <Text className={cx('title')} size="size4-m">
        {content?.redirectAlways?.title}
      </Text>
      <div className={cx('item')}>
        <RInput
          maskSettings={phoneMaskSettings}
          name="phoneAlwaysRedirect"
          placeholder={content.placeholder}
          value={options.phoneAlwaysRedirect}
        />
      </div>
    </FinalForm>
  );
};
