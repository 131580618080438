import React, { memo, useRef } from 'react';

import { BillBanner } from '../BillBanner';
import { usePromoPopup } from './selectors/usePromoPopup';
import { LinkContainer } from './components/LinkContainer';
import { useSelectPromo } from './selectors/useSelectPromo';
import { ButtonContainer } from './components/ButtonContainer';

/** Компонент обертка над промо-баннером */
export const PromoContainer = memo(() => {
  const bannerRef = useRef(null);

  const { bgColor, contentProps, image, isShowPromoBanner, link, onClick } =
    useSelectPromo(bannerRef);
  const { modalContainerProps, modalHeaderProps, onOpen } = usePromoPopup();
  const banner = (
    <div onClick={onClick} ref={bannerRef}>
      <BillBanner {...{ bgColor, contentProps, image }} />
    </div>
  );

  if (!isShowPromoBanner) {
    return null;
  }

  if (link) {
    return <LinkContainer {...{ link }}>{banner}</LinkContainer>;
  }

  if (modalContainerProps && modalHeaderProps) {
    return (
      <ButtonContainer {...{ onOpen, modalHeaderProps, modalContainerProps }}>
        {banner}
      </ButtonContainer>
    );
  }

  return null;
});
