import { useCallback } from 'react';

import { useAppSelector } from '../../../store';
import { selectMainContent } from '../../../store/slices/commonSlice/selectors';

/**
 * Хук для контента стартового экрана лендинга
 */
export const useStartSectionContent = () => {
  const mainContent = useAppSelector(selectMainContent);

  const handleScrollToInfoAboutServiceSection = useCallback(() => {
    const element = document?.getElementById('howItWorks');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  return {
    ...mainContent,
    handleScrollToInfoAboutServiceSection,
  };
};
