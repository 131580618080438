import { createSlice } from '@reduxjs/toolkit';

import {
  getRedirectData,
  setRedirectData,
} from 'pages/Services/RedirectServiceCardLanding/store/redirect/asyncActions';
import { RedirectState } from 'pages/Services/RedirectServiceCardLanding/store/redirect/types';
import { defaultOptions } from 'pages/Services/RedirectServiceCardLanding/const';

const initialState: RedirectState = {
  loading: 'idle',
  loadingAction: 'idle',
};
export const redirectSlice = createSlice({
  name: 'redirectSlice',
  initialState,
  reducers: {
    resetLoadingAction(state) {
      state.loadingAction = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRedirectData.fulfilled, (state, { payload }) => {
        state.options = payload;
        state.loading = 'succeeded';
      })
      .addCase(getRedirectData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getRedirectData.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(setRedirectData.fulfilled, (state, { payload }) => {
        state.options = payload || defaultOptions;
        state.loadingAction = 'succeeded';
      })
      .addCase(setRedirectData.pending, (state) => {
        state.loadingAction = 'pending';
      })
      .addCase(setRedirectData.rejected, (state) => {
        state.loadingAction = 'failed';
      });
  },
});
export const { resetLoadingAction } = redirectSlice.actions;
