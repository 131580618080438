import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { CloseIcon, SearchIcon } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const InputLayout = ({ defCodeNode, onReset, children }) => (
  <div className={cx('input', defCodeNode && 'input--with-defcode')}>
    {defCodeNode && <div className={cx('input-def-code')}>{defCodeNode}</div>}
    {children({
      searchIcon: defCodeNode ? null : <SearchIcon fill="#bebec9" size={20} />,
      resetButton: (
        <button className={cx('input-reset')} onClick={onReset} type="button">
          <CloseIcon fill="#727281" size={20} />
        </button>
      ),
    })}
  </div>
);

InputLayout.propTypes = {
  defCodeNode: PropTypes.node,
  onReset: PropTypes.func,
  children: PropTypes.func,
};
