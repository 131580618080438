/**
 * Утилита для получения региона
 *
 * Используется пока в новой шапке не появится
 * работа с регионом пользователя
 */
export const getRegionId = () => {
  const regionId = document.querySelector<HTMLMetaElement>('meta[name="REGION_ID"]');

  if (regionId) {
    return +regionId.content;
  }
  return null;
};
