import React from 'react';
import { Text } from '@beef/ui-kit';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const DisconnectionWarning = ({ text, src }) => (
  <div className={cx('yandex-plus-warning')}>
    <img alt="яндекс-плюс" className={cx('yandex-plus-warning__image')} src={src} />
    <div className={cx('yandex-plus-warning__text')}>
      <Text color="grey95" size="size5-r-s" tagType="span">
        {text}
      </Text>
    </div>
  </div>
);

DisconnectionWarning.propTypes = {
  text: PropTypes.string,
  src: PropTypes.string,
};

export default DisconnectionWarning;
