import React from 'react';
import classNames from 'classnames/bind';
import { ChipsStateless } from '@beef/ui-kit';

import { prepareFilterListChips } from 'pages/SearchResultsPage/utils/helperFilters';

import { Props } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Chips: React.FC<Props> = ({ options, checkedValues, onChange }) => {
  const currentOptions = prepareFilterListChips(options);

  return (
    <div className={cx('chips')}>
      <ChipsStateless
        checkedValues={checkedValues}
        isScrollable={false}
        onChange={onChange}
        options={currentOptions}
        type="checkbox"
        variant="text"
      />
    </div>
  );
};
