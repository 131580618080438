import React from 'react';
import classnames from 'classnames/bind';
import { Link, Text } from '@beef/ui-kit';
import { CollapseContainer } from '@beef/smart-kit';

import Icon from 'pages-components/Icon';

import { CommandCard } from './CommandCard';
import styles from './styles.module.pcss';

const cx = classnames.bind(styles);

export const CommandsBlock = ({ content: { title, linkText, linkUrl }, data: { commands } }) => (
  <>
    <div className={cx('header')}>
      <div className={cx('header-title')}>
        <Text size="size2-m">{title}</Text>
      </div>
      <Link href={linkUrl} isNoUnderline rightIcon={<Icon name="chevronRight" />} size="size5-m">
        {linkText}
      </Link>
    </div>
    <CollapseContainer className={cx('collapse-container')} limits={{ mobile: 3 }}>
      {commands.map((command, index) => (
        <CommandCard className={cx('card')} key={index} text={command.text} title={command.title} />
      ))}
    </CollapseContainer>
  </>
);
