import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Button, Link, Text } from '@beef/ui-kit';
import { Preloader } from '@beef/legacy-ui-kit';

import Icon from 'pages-components/Icon';

import CheckStatus from './CheckStatus';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ConnectOfferYandexPlusActivation = ({ tariffTitle, content, pollConfig }) => {
  const [texts, setTexts] = useState(null);
  const [activationSuccess, setActivationSuccess] = useState(false);
  const [activationLink, setActivationLink] = useState(null);
  const [status, setStatus] = useState('initial');

  useEffect(() => {
    const key = activationSuccess ? 'subscriptionOffer' : 'subscriptionFallback';
    setTexts(content[key]);
  }, [activationSuccess, content]);

  const handleFetchData = (res) => {
    setActivationSuccess(res.success);
    setActivationLink(res.link);
    setStatus('dataFetched');
  };

  const tariffName = content.title || tariffTitle;

  if (status === 'initial') {
    return (
      <div className={cx('wrap')}>
        <CheckStatus onFetchData={handleFetchData} pollConfig={pollConfig} />
      </div>
    );
  }

  if (status === 'pending') {
    return (
      <div className={cx('wrap')}>
        {tariffName && (
          <div className={cx('heading')}>
            <Text size="size2-m" tagType="h1">
              <div className={cx('heading__text')}>{tariffName}</div>
            </Text>
          </div>
        )}
        <div className={cx('preloader-wrap')}>
          <Preloader size="100" />
        </div>
        <Text size="size6-r">Подождите, пожалуйста</Text>
      </div>
    );
  }

  return (
    <div className={cx('wrap')}>
      <header className={cx('header')}>
        {tariffName && (
          <div className={cx('heading')}>
            <Text size="size2-m" tagType="h1">
              <div className={cx('heading__text')}>{tariffName}</div>
            </Text>
          </div>
        )}
        {content.subtitle && (
          <div className={cx('subtitle')}>
            <Text size="size5-r-s" tagType="div">
              <div className={cx('subtitle__content')}>
                <div className={cx('subtitle__icon')}>
                  <Icon name="checked" />
                </div>
                <div className={cx('subtitle__text')}>{content.subtitle}</div>
              </div>
            </Text>
          </div>
        )}
      </header>
      {texts && (
        <div className={cx('body')}>
          {texts.title && (
            <div className={cx('row')}>
              <Text size="size6-m">{texts.title}</Text>
            </div>
          )}
          {texts.description && (
            <div className={cx('row')}>
              <Text size="size6-r">{texts.description}</Text>
            </div>
          )}
          {content?.iconsList?.length > 0 && (
            <div className={cx('icons-list-wrap')}>
              <ul className={cx('icons-list')}>
                {content.iconsList.map((icon) => (
                  <li className={cx('icons-list__item')} key={icon.src}>
                    <img alt={icon.alt || ''} src={icon.src} />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {texts.buttonText && (
            <div className={cx('button-wrap')}>
              {activationSuccess && activationLink ?
                <Link
                  buttonSize="l"
                  buttonVariant="main"
                  href={activationLink}
                  isFullWidth
                  variant="button"
                >
                  {texts.buttonText}
                </Link>
              : <Button fullWidth onClick={() => window.location.reload()}>
                  {texts.buttonText}
                </Button>
              }
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ConnectOfferYandexPlusActivation.propTypes = {
  tariffTitle: PropTypes.string,

  content: PropTypes.object,
  pollConfig: PropTypes.shape({
    interval: PropTypes.number,
    timeout: PropTypes.number,
  }),
};

export default ConnectOfferYandexPlusActivation;
