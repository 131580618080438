import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { InfoCard } from '@beef/smart-kit';
import { Text } from '@beef/ui-kit';

import { FeaturesType } from 'pages/SPN/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const Features: FC<FeaturesType> = ({ title, descriptions }) => (
  <div className={cn('wrapper')}>
    {title && (
      <div className={cn('title')}>
        <Text size="size1-m">{title}</Text>
      </div>
    )}

    <div className={cn('block')}>
      {descriptions?.map((el, idx) => (
        <div className={cn('block__card')} key={+idx}>
          {el?.text && <InfoCard text={el.text} />}
        </div>
      ))}
    </div>
  </div>
);
