import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import { Modal } from '@beef/ui-kit';

import { setConvergence } from 'pages/ProductCard/Tariff/containers/ConstructorTariff/actions';

import { ModalActionBar } from './ModalActionBar';
// Actions
import { setCloseModal } from '../../store/actions/common';
import {
  setFlat,
  setHouse,
  setPhoneNumber,
  setStatusConnection,
  setStreet,
} from '../../store/actions/orderTariffUserData';
import { resetCurrentUserTariff, setIsFinishedSettings } from '../../store/actions/totalSettings';
import {
  callBackRequestLoading,
  setBackToPreviousStep,
  setButtonText,
  setCallBackStatus,
  setCurrentStep,
  setIsFetchRegion,
  setIsSettled,
  setLoadingStatus,
  setSecondButtonText,
} from '../../store/actions/modal';
import {
  setAccessibleTariffs,
  setActiveSettingsTab,
  setActiveTab,
  setCurrentAnimalSoc,
} from '../../store/actions/orderTariffDetailsData';
// Selectors
import { getAgreementText, getRegion } from '../../store/selectors/userData';
import {
  getButtonDisabledStatus,
  getCallBackStatus,
  getCurrentButtonText,
  getCurrentSecondButtonText,
  getCurrentStep,
  getFlagIsHeaderConfigRender,
  getLoadingStatus,
} from '../../store/selectors/modal';
import {
  getIsConvergentUser,
  getStatusConnection,
  getUserSelectedPhoneNumber,
  getUserSelectedRegionID,
} from '../../store/selectors/orderTariffUserData';
import {
  getAccessTariff,
  getActiveTab,
  getActiveTabServiceType,
  getActiveTabSpeedName,
  getHasInacId,
  getHasSeamlessUrlParams,
  getIsActivateDisconnectConvergent,
  getSeamlessUrlParams,
  getSettingsActiveTab,
  getTotalPriceWithUnit,
} from '../../store/selectors/orderTariffDetailsData';
import {
  getIsConnectedTariff,
  getIsFinishedSettings,
  getSettingsTariff,
} from '../../store/selectors/totalSettings';
// Hooks
import { usePrepareHandlers } from './Hooks/usePrepareHandlers';
import { useGetRegionTariff } from '../../Hooks/useGetRegionTariff';
// Constants
import { btnTexts } from '../../constants';

const ModalContainerLayout = ({
  text,
  isOpen,
  children,
  headerBarClick,
  isHeaderConfig,
  ...props
}) => {
  useGetRegionTariff({ isOpen, ...props });
  const { size, onClose, onClick, sentCallBackRequest, showAgreementText } = usePrepareHandlers({
    text,
    ...props,
  });
  return (
    <Modal
      {...{ size, isOpen, onClose }}
      actionBar={
        <ModalActionBar {...{ text, showAgreementText, onClick, sentCallBackRequest, ...props }} />
      }
      headerBarConfig={
        isHeaderConfig && {
          backButtonClick: headerBarClick,
          backButtonText: btnTexts.backButton,
        }
      }
    >
      {children}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  step: getCurrentStep(state),
  text: getAgreementText(state),
  activeTab: getActiveTab(state),
  hasInacId: getHasInacId(state),
  defaultRegion: getRegion(state),
  isLoading: getLoadingStatus(state),
  buttonText: getCurrentButtonText(state),
  disabled: getButtonDisabledStatus(state),
  regionId: getUserSelectedRegionID(state),
  isConvergent: getIsConvergentUser(state),
  allAccessTariffs: getAccessTariff(state),
  isConnected: getIsConnectedTariff(state),
  priceWithUnit: getTotalPriceWithUnit(state),
  lastSeenSpeed: getActiveTabSpeedName(state),
  isCallBackLoading: getCallBackStatus(state),
  userSettingsTariff: getSettingsTariff(state),
  userNumber: getUserSelectedPhoneNumber(state),
  activeSettingTab: getSettingsActiveTab(state),
  seamlessUrlParams: getSeamlessUrlParams(state),
  isFinishedSettings: getIsFinishedSettings(state),
  isHeaderConfig: getFlagIsHeaderConfigRender(state),
  secondButtonText: getCurrentSecondButtonText(state),
  currentConnectionStatus: getStatusConnection(state),
  hasSeamlessUrlParams: getHasSeamlessUrlParams(state),
  isActivateDisconnectConvergent: getIsActivateDisconnectConvergent(state),
  tariffType: getActiveTabServiceType(state),
});

const mapDispatchToProps = {
  setStep: setCurrentStep,
  setUrlQueryFlat: setFlat,
  setUrlQueryHouse: setHouse,
  setUrlQueryStreet: setStreet,
  setDefaultTab: setActiveTab,
  setLoading: setLoadingStatus,
  setResetAndClose: setCloseModal,
  setUrlQueryPhone: setPhoneNumber,
  setIsSettledOption: setIsSettled,
  setAnimalSoc: setCurrentAnimalSoc,
  setRegionLoading: setIsFetchRegion,
  setCurrentButtonText: setButtonText,
  headerBarClick: setBackToPreviousStep,
  setUserTariffSettings: setConvergence,
  setAccessTariffs: setAccessibleTariffs,
  setAddressChecked: setStatusConnection,
  setIsCallBackLoading: setCallBackStatus,
  setFinishedSettings: setIsFinishedSettings,
  resetSettingsTariff: resetCurrentUserTariff,
  setActiveSettingsUserTab: setActiveSettingsTab,
  setCurrentSecondButtonText: setSecondButtonText,
  setRequestCallBackSLoading: callBackRequestLoading,
};

ModalContainerLayout.propTypes = {
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.node,
  headerBarClick: PropTypes.func,
  isHeaderConfig: PropTypes.bool,
};

export const ModalContainer = connect(mapStateToProps, mapDispatchToProps)(ModalContainerLayout);
