import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { TextInput } from '@beef/legacy-ui-kit';

import { templateParser } from 'utils/format-string';

import styles from './style.pcss';

const cx = classNames.bind(styles);

class Calculator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: props.price,
      isValid: true,
    };
  }

  onChange = (value) => {
    const currentValue = parseFloat(value.replace(/,/, '.'), 10);
    if (!Number.isNaN(currentValue) || value === '') {
      this.setState({
        price: currentValue,
        isValid: true,
      });
    } else {
      this.setState({ isValid: false });
    }
  };

  get price() {
    const { price } = this.state;
    const isFractionalNumber = price - parseInt(price, 10) !== 0;
    if (isFractionalNumber) {
      const priceNumberArray = price.toString().split('.');
      let fractionalPrice = priceNumberArray[1];
      if (parseInt(fractionalPrice, 10) < 10) {
        fractionalPrice += '0';
        return `${priceNumberArray[0]}.${fractionalPrice}`;
      }
    }
    return price;
  }

  get oldVAT() {
    return this.price ? ((this.price / 118) * 18).toFixed(2) : 0;
  }

  get newVAT() {
    return this.price ? ((this.price / 120) * 20).toFixed(2) : 0;
  }

  get newPrice() {
    return this.price ?
        (parseFloat(this.difference, 10) + parseFloat(this.price, 10)).toFixed(2)
      : 0;
  }

  get difference() {
    return this.price ? (this.newVAT - this.oldVAT).toFixed(2) : 0;
  }

  render() {
    const {
      state: { isValid },
      props: { className, beforeInputContent, afterInputContent, additionalContent, tax },
      oldVAT,
      newVAT,
      newPrice,
      difference,
      onChange,
      price,
    } = this;

    const parsedAfterInputContent = templateParser(afterInputContent, {
      oldVAT: tax === '18' ? oldVAT : newVAT,
      tax,
    });

    return (
      <div className={cx('wrapper', className)}>
        <div className={cx('container')}>
          <p className={cx('txt')} dangerouslySetInnerHTML={{ __html: beforeInputContent }} />
          <TextInput
            className={cx('input')}
            onChange={onChange}
            status={isValid ? '' : 'fail'}
            value={price}
          />
          <p className={cx('txt')} dangerouslySetInnerHTML={{ __html: parsedAfterInputContent }} />
        </div>
        {additionalContent && (
          <p
            className={cx('txt')}
            dangerouslySetInnerHTML={{
              __html: templateParser(additionalContent, { newVAT, difference, newPrice }),
            }}
          />
        )}
      </div>
    );
  }
}

Calculator.propTypes = {
  price: PropTypes.string,
  beforeInputContent: PropTypes.string,
  afterInputContent: PropTypes.string,
  additionalContent: PropTypes.string,
  tax: PropTypes.string,
};

Calculator.defaultProps = {
  price: '',
  beforeInputContent: 'В абонентской плате',
  afterInputContent: '&#8381; включен НДС {{tax}}% &#8212; {{oldVAT}}&nbsp;&#8381;.',
};

export default Calculator;
