import React, { Children } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ColumnsList = ({ children, className }) => {
  const items = Children.toArray(children);

  return (
    <div className={cx('wrapper', className)}>
      {items.map((item, i) => (
        <div className={cx('itemWrap')} key={i}>
          {item}
        </div>
      ))}
    </div>
  );
};

ColumnsList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ColumnsList;
