import classNames from 'classnames/bind';
import React from 'react';

// Components
import { Text } from '@beef/ui-kit';

import { Tab } from 'pages/ProductCard/Tariff/components/CharactersTabs/Tab';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TTabsContainerProps = {
  activeOptionSoc: string;
  isChangeTariff: boolean;
  onClick: () => void;
  tabs: Tab[];
  title: string;
};

export const TabsContainer: React.FC<Partial<TTabsContainerProps>> = ({
  tabs,
  title,
  onClick,
  isChangeTariff,
  activeOptionSoc,
}) => (
  <div className={cx('wrapper')}>
    {title && (
      <div className={cx('wrapper__title')}>
        <Text size="size3-m" tagType="h2">
          {title}
        </Text>
      </div>
    )}
    <div className={cx('wrapper__tabs')}>
      {tabs?.map((tab) => (
        <Tab
          activeColor={tab.activeColor}
          activeOptionSoc={activeOptionSoc}
          discount={tab.discount}
          highSoc={tab.highPower?.optionSoc}
          imageSrc={tab.imageSrc}
          isChangeTariff={isChangeTariff}
          key={tab.optionSoc}
          lowSoc={tab.power?.optionSoc}
          name={tab.name}
          onClick={onClick}
          optionSoc={tab.optionSoc}
        />
      ))}
    </div>
  </div>
);
