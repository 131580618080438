import React, { FC } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames/bind';

import { OptionsMapper, TOptionsMapper } from '../OptionsMapper';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TOptions = {
  activeOptions?: TOptionsMapper[];
};

/** Блок с выбранными опциями для чека */
export const Options: FC<TOptions> = ({ activeOptions }) => (
  <motion.div className={cx('wrapper')} layout>
    {activeOptions?.map((activeOption) => (
      <OptionsMapper key={activeOption.title} {...activeOption} />
    ))}
  </motion.div>
);
