import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import styles from './styles.pcss';
import { IQRCode } from './types';

const cn = classNames.bind(styles);

export const QRCode: React.FC<IQRCode> = ({ title, logoUrl, qrCodeUrl, titleMobile }) => {
  const isMobile = useIsMobileDimension();

  return (
    <div className={cn('qr-code')}>
      <img alt="Иконка билайн" className={cn('logo')} src={logoUrl} />
      <Text className={cn('title')} size="2xl">
        {isMobile ? titleMobile : title}
      </Text>
      <img alt="QR-код для скачивания приложения" className={cn('img')} src={qrCodeUrl} />
    </div>
  );
};
