import { pushRecommendTariffEvent } from 'utils/analytics/tariffCard';

const getWidgetType = (param) => param.widgetType === 'bubble';

/**
 * Маппер проверяет наличие баблика в заголовке и баблика в контенте;
 * Если есть хоть один баблик отправляем push в аналитику;
 * -------------------------------------------------------
 * @param tariff - Объект тарифа;
 */
export const isRecommendTariff = (tariff) => {
  const isTitleBubble = !!tariff?.pageTitleBubble;
  const isContentBubble = tariff?.parameterGroups.some((param) =>
    param.parameters.some(getWidgetType),
  );

  if (isTitleBubble || isContentBubble) {
    pushRecommendTariffEvent(tariff);
  }
};
