import { callOnce } from 'utils/fn';
import { ymPushEvent, ymPushParams } from 'utils/ym';
import { debounce } from 'utils/timed-functions';

const wrapYmEvent = (event, type, payload) => ({
  [event]: { [`LP_${window.location.pathname}`]: { [type]: payload } },
});

const regExp = /[^/]+$/;
const formatVideoSrc = (src) => src.match(regExp)[0];

const pushReachGoal = (src, type) => {
  const pathname = window.location.pathname || '';
  const event = `${pathname}+${type}+${src}`;
  const prepareEvent = event.replace(/(\/|\.)/g, '_');
  const key = encodeURIComponent(prepareEvent);
  ymPushEvent(key);
};

const HELP_STATIC_EVENTS = {
  video: 'video_ev',
};

const ANALYTICS_HELP_STATIC = {
  video: {
    start: callOnce((event) => {
      const { currentSrc } = event.target;
      ymPushParams(
        wrapYmEvent(HELP_STATIC_EVENTS.video, 'video_start', formatVideoSrc(currentSrc)),
      );
      pushReachGoal(formatVideoSrc(currentSrc), 'VideoStart');
    }),
    complete: (event) => {
      const { currentSrc } = event.target;
      ymPushParams(
        wrapYmEvent(HELP_STATIC_EVENTS.video, 'video_complete', formatVideoSrc(currentSrc)),
      );
    },
    pauseAndSeek: debounce((event) => {
      const {
        target: { currentSrc, currentTime, duration },
        type,
      } = event;
      if (currentTime === duration) return;
      ymPushParams(
        wrapYmEvent(HELP_STATIC_EVENTS.video, `video_${type === 'seeked' ? 'seek' : 'pause'}`, {
          [formatVideoSrc(currentSrc)]: `${Math.round(currentTime)}s`,
        }),
      );
    }, 250),
    changeVolume: (event) => {
      const { currentSrc, muted } = event.target;
      ymPushParams(
        wrapYmEvent(
          HELP_STATIC_EVENTS.video,
          `video_${muted ? 'mute' : 'unmute'}`,
          formatVideoSrc(currentSrc),
        ),
      );
    },
    fullscreen: (event) => {
      const { currentSrc } = event.target;
      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen)
        return;
      ymPushParams(
        wrapYmEvent(HELP_STATIC_EVENTS.video, 'video_fullscreen_mode', formatVideoSrc(currentSrc)),
      );
    },
  },
};

export const ANALYTICS_LISTENERS = {
  video: {
    play: (event) => ANALYTICS_HELP_STATIC.video.start(event),
    ended: (event) => ANALYTICS_HELP_STATIC.video.complete(event),
    pause: (event) => ANALYTICS_HELP_STATIC.video.pauseAndSeek(event),
    seeked: (event) => ANALYTICS_HELP_STATIC.video.pauseAndSeek(event),
    volumechange: (event) => ANALYTICS_HELP_STATIC.video.changeVolume(event),
    fullscreenchange: (event) => ANALYTICS_HELP_STATIC.video.fullscreen(event),
    mozfullscreenchange: (event) => ANALYTICS_HELP_STATIC.video.fullscreen(event),
    MSFullscreenChange: (event) => ANALYTICS_HELP_STATIC.video.fullscreen(event),
    webkitfullscreenchange: (event) => ANALYTICS_HELP_STATIC.video.fullscreen(event),
  },
};
