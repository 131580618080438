import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosBeelineRequest } from '@beef/utils';

import { IPeriods } from '../components/constructorPage/SpecialistVisitContainer/types';
import { apiAvailableSchedule } from './constants';

/** Тип параметров запроса метода */
type TRequestParams = {
  flat?: string;
  houseId?: number;
};

/** Тип ответа метода */
export type TSpecialistVisitResponse = {
  endDateOffsetInDays?: number;
  isGlobalProblem?: boolean;
  isSucceeded?: boolean;
  startDateOffsetInDays?: number;
  view: {
    periods: IPeriods[];
  };
};

/** Запрос данных для получения слотов графика монтажника по выбранному адресу */
export const fetchAvailableSchedule = createAsyncThunk<TSpecialistVisitResponse, TRequestParams>(
  'api/fetchAvailableSchedule',
  async (params) => {
    try {
      const urlParams = new URLSearchParams(params as URLSearchParams).toString();
      const url = `${apiAvailableSchedule}?${urlParams}`;
      const response = await axiosBeelineRequest<TSpecialistVisitResponse>({ url });
      if (!response?.isSucceeded) {
        throw new Error();
      }
      return response;
    } catch (e) {
      throw new Error();
    }
  },
);
