import axios from 'axios';

import { CONNECTION_STATUS } from 'pages/OffsetLanding/utils';
import { SERVICE_CONTEXTS_ENDPOINT } from 'constants/Endpoints';
import { SERVICE_SOCS, URL_CONFIG } from 'pages/Services/ScopeZeroBalanceServices/constants';

/**
 * todo: удалить fetchServiceNotification после
 * todo: влития TP-1078 в dev, импортнуть хук из
 * todo: ScopeZeroBalanceServices
 */

export const errorText = (view) =>
  view.connectionStatus > CONNECTION_STATUS.available ?
    'request was failed'
  : CONNECTION_STATUS.error;

export const warningText = (view) =>
  view?.serviceConnectForm?.warningText || view?.serviceConnectByDateForm?.warningText;

export const filterServiceInfoBySoc = (serviceInfo, soc) =>
  serviceInfo?.find((item) => soc.includes(item.name));

export const fetchServiceNotification = (screenId) =>
  axios
    .get(SERVICE_CONTEXTS_ENDPOINT, { params: { screenId } })
    .then(({ data: { contexts } }) =>
      contexts?.filter((serviceInfoElem) => SERVICE_SOCS.includes(serviceInfoElem?.name)),
    )
    .catch((error) => {
      throw new Error(error);
    });

export const composeTrustServiceUrl = (status, blocked) => {
  if (status === 'black') {
    return blocked ? URL_CONFIG.trustPaymentService : URL_CONFIG.topUpAccount;
  }
  return '';
};

export const composeActionButtonText = (status, blocked) => {
  if (status === 'black') {
    return blocked ? 'Доверительный платёж' : 'Пополнить баланс';
  }
  return 'Подробнее';
};
