import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from '../styles.pcss';

const cx = classNames.bind(styles);

export const InputLayout = ({ type, text, children }) => (
  <div className={cx([`wrapper__${type}`, 'wrapper__item'])}>
    {text && (
      <Text color="grey60" size="size5-r-s">
        {text}
      </Text>
    )}
    {children}
  </div>
);

InputLayout.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node,
};
