import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from '@beef/legacy-ui-kit';

import Icon from 'pages-components/Icon';

import styles from './DocumentLink.pcss';

const cx = classNames.bind(styles);

export default function DocumentLink(props) {
  const { href, text, format, size } = props;

  return (
    <div className={cx('wrapper')}>
      <div className={cx('iconWrapper')}>
        <div className={cx('icon')}>
          <Icon name="documents" />
          <span className={cx('format')}>
            {typeof format === 'string' ? format.toUpperCase() : ''}
          </span>
        </div>
      </div>
      <div className={cx('text')}>
        <Link href={href} target="_blank">
          {text}
        </Link>
        {size ?
          <span className={cx('info')}>,{size}</span>
        : null}
      </div>
    </div>
  );
}

DocumentLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  format: PropTypes.string,
  size: PropTypes.string,
};
