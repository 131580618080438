import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TPreChangesInfoItem = {
  description?: string[];
  icon?: string;
  title?: string;
};

/** Блок с информацией об изменениях тарифа */
export const PreChangesInfoItem: FC<TPreChangesInfoItem> = ({ icon, title, description }) => (
  <li className={cx('wrapper')}>
    {icon && <img alt="" className={cx('wrapper__icon')} loading="lazy" src={icon} />}
    <div className={cx('wrapper__description')}>
      {title && <Text size="size5-r-s">{title}</Text>}
      {description?.map((text, idx) => (
        <Text color="grey60" key={idx} size="size6-r">
          {text}
        </Text>
      ))}
    </div>
  </li>
);
