import React from 'react';
import { Banner } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { useAppSelector } from 'constructorV1/store';
import { selectBenefitsSettings } from 'constructorV1/store/selectors/selectCommon';

import type { TBenefitsWithSwiper } from './types';
import { BenefitsCard } from '../BenefitsCard';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент ЕК BenefitsWithSwiper состоит из карусели карточек, которые можно свайпать */
export const BenefitsWithSwiper = ({ benefits }: TBenefitsWithSwiper) => {
  /** Текущие настройки автоматического пролистывания benefits.
   * Включают в себя autoPlay и duration */
  const settings = useAppSelector(selectBenefitsSettings);

  return (
    <Banner className={cx('cards')} {...settings} infinite loop pauseOnHover>
      {benefits?.map((benefit) => (
        <li className={cx('card')} key={benefit.title}>
          <BenefitsCard
            description={benefit.description}
            iconUrl={benefit.iconUrl}
            title={benefit.title}
          />
        </li>
      ))}
    </Banner>
  );
};
