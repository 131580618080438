/**
 * @desc: поиск параметра в строке с квери-параметром
 * @param string
 * @param targetKey
 * @returns {null|string}
 */
const getQueryParamByKey = (string, targetKey) => {
  if (!string || !targetKey) {
    return null;
  }
  let res = null;
  string
    .replace('#', '')
    .split('&')
    .forEach((param) => {
      const [key, value] = param.split('=');
      if (key === targetKey) {
        res = value;
      }
    }, '');

  return res;
};

export { getQueryParamByKey };
