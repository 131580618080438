import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';

import { visibleOptionsSection } from 'constructorV1/utils/visibleOptionsSection';

import { TOptionsMapper } from '../OptionsMapper';
import { BonusMapper } from '../BonusMapper';
import { Options } from '../Options';
import { TBonus } from '../Bonus';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TPlanSettings = {
  activeOptions?: TOptionsMapper[];
  bonuses?: TBonus[];
  presetsText?: string;
};

/** Варианты анимации "черного" (black block) блока с настройками в чеке ЕК */
const variantsSettings = {
  hidden: {
    height: 0,
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
};

/** Блок с выбранными пресетами/опциями/бонусами для чека (black block) */
export const PlanSettings: FC<TPlanSettings> = ({
  presetsText,
  bonuses = [],
  activeOptions = [],
}) => {
  return (
    <motion.section className={cx('wrapper')} layout style={{ borderRadius: '24px' }}>
      <LayoutGroup>
        {presetsText && (
          <motion.div layout>
            <Text align="left" color="primary-invert" lineHeight={40} size="xl" weight="regular">
              {presetsText}
            </Text>
          </motion.div>
        )}
        {visibleOptionsSection(activeOptions, bonuses) && (
          <AnimatePresence initial={false}>
            <motion.div
              animate="visible"
              className={cx('wrapper__options')}
              exit="exit"
              initial="hidden"
              layout
              variants={variantsSettings}
            >
              <LayoutGroup>
                <Options {...{ activeOptions }} />
                <BonusMapper {...{ bonuses }} />
              </LayoutGroup>
            </motion.div>
          </AnimatePresence>
        )}
      </LayoutGroup>
    </motion.section>
  );
};
