import React, { FC, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import { FILTERS_FOR_BACKEND } from 'pages/Maps/constants';
import { getUrlParamsByFilters } from 'pages/Maps/utils/index';
import { setFiltersMapParams, setIsUpdateFilterList } from 'pages/Maps/store/actions';

import { URLListenerPropsType } from './types';

const _UrlFiltersListener: FC<URLListenerPropsType> = ({
  isUpdateFilters,
  onFlagToggle,
  onFiltersChange,
}) => {
  useEffect(() => {
    const filters = getUrlParamsByFilters(FILTERS_FOR_BACKEND);

    onFiltersChange(filters);
    onFlagToggle(false);
  }, [isUpdateFilters]);

  return null;
};

const mapStateToProps = ({ external: { maps = {} } }) => ({
  isUpdateFilters: maps.isUpdateFilters,
});

const mapDispatchToProps = {
  onFiltersChange: setFiltersMapParams,
  onFlagToggle: setIsUpdateFilterList,
};

export const UrlFiltersListener = connect(mapStateToProps, mapDispatchToProps)(_UrlFiltersListener);
