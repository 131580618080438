import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

// Components
import Ctx from 'pages-components/Ctx';

import { getTariffParams } from '../TariffCardParamsHoc/getTariffParams';
import { getBottomBubble, getTopBubble } from '../TariffCardParamsHoc/getBubble';
import { getPrice } from '../TariffCardParamsHoc/getPrice';

const SimpleTariffCardHoc = ({
  priceDescription,
  nameplateDiscount,
  showTopBubble,
  showBottomBubble,
  bubble,
  titleIcon,
  discount,
  children,
  cardIndex,
  image,
  tariffsGroup,
  mobileImage,
  description,
  parameters,
  hideButton,
  forEsim,
  isConnected,
  availableOnlyInOffice,
  title,
  link,
  alias,
  price,
  id,
  withLevitate,
  flatButtonText,
  orderButton,
}) => {
  // Проверяем наличие upperBar хоть у одной крточки, если есть, добавляем padding для всех карточек
  const isCardsHasUpperBar =
    tariffsGroup &&
    tariffsGroup.some((item) => item.forEsim || item.isConnected || item.availableOnlyInOffice);

  const tariffCardParams =
    parameters &&
    parameters.map(getTariffParams()).map((item) => ({
      ...item,
      parameterType: 'simpleTariff',
    }));

  // Текст для кнопки
  const buttonText = flatButtonText || Ctx.tariff.buttonText.learnMoreTariffCard;

  // Объект для баблика над карточкой
  const topBubble =
    showTopBubble && (discount || {}).bubble && getTopBubble({ bubble: discount.bubble, id });
  // Объект для баблика под карточкой
  const bottomBubble = showBottomBubble && bubble && getBottomBubble({ bubble, id });

  // Формирует цену
  const priceObj = getPrice(price, discount, priceDescription);

  // Если есть eSim в тарифе, создаем объект с данными и прокидываем в карточку;
  let upperBarObj = null;
  if (forEsim) {
    upperBarObj = Ctx.tariffCard.upperBar.eSim;
  } else if (isConnected) {
    // Если тариф подключен
    upperBarObj = Ctx.tariffCard.upperBar.connected;
  } else if (availableOnlyInOffice) {
    // Если тариф доступен только для подключения в офисе либо он архивный, выодим серую плашку
    upperBarObj = Ctx.tariffCard.upperBar.onlyInOffice;
  }

  return cloneElement(children, {
    parameters: tariffCardParams,
    topBubble,
    bottomBubble,
    cardIndex,
    description,
    buttonText,
    title,
    alias,
    link,
    isCardsHasUpperBar,
    upperBar: upperBarObj,
    nameplateIcon: titleIcon,
    nameplateDiscount,
    fullLink: link,
    bottomButtonType: 'flat',
    hideButton,
    clickable: true,
    images: image &&
      mobileImage && {
        desktop: image,
        mobile: mobileImage,
      },
    price: {
      priceHeight: '105',
      ...priceObj,
    },
    withLevitate,
    orderButton,
  });
};

SimpleTariffCardHoc.defaultProps = {
  hideButton: false,
  showTopBubble: true,
  showBottomBubble: true,
};

SimpleTariffCardHoc.propTypes = {
  bubble: PropTypes.object,
  discount: PropTypes.shape({ bubble: PropTypes.object }),
  showTopBubble: PropTypes.bool,
  showBottomBubble: PropTypes.bool,
  mobileImage: PropTypes.string,
  titleIcon: PropTypes.string,
  description: PropTypes.string,
  nameplateDiscount: PropTypes.string,
  children: PropTypes.object,
  hideButton: PropTypes.bool,
  image: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  alias: PropTypes.string,
  flatButtonText: PropTypes.string,
  cardIndex: PropTypes.number,
  price: PropTypes.shape({
    value: PropTypes.string,
    unit: PropTypes.string,
  }),
  orderButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  tariffsGroup: PropTypes.array,
  forEsim: PropTypes.bool,
  isConnected: PropTypes.bool,
  availableOnlyInOffice: PropTypes.bool,
  id: PropTypes.number,
  withLevitate: PropTypes.bool,
};

export default SimpleTariffCardHoc;
