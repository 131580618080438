import { emptyArr, emptyObj } from '@beef/utils';
import { createSelector } from '@reduxjs/toolkit';

import { popupSteps } from 'pages/FTTB2021/components/OrderTariffFormPopup/constants';
import { TARIFF_TYPES } from 'pages/FTTB2022/CatalogPage/deprecated/store/blocks/constants';
import { USER_TYPES } from 'pages/FTTB2022/CatalogPage/deprecated/store/constants';
import {
  getPageSettings,
  getUserType,
} from 'pages/FTTB2022/CatalogPage/deprecated/store/shared/selectors';

/*
 * TODO: В рамках задачи FIRST-1329 переименовать селекторы get => select
 * */

export const getOrderTariffData = (state) => state.orderTariffPopup || emptyObj;

export const getOrderTariffCommonData = (state) =>
  getOrderTariffData(state).orderProgress || emptyObj;
export const getTariffDetailsFromStore = (state) =>
  getOrderTariffData(state).tariffServices || emptyObj;

// wrapper selectors
export const getCurrentFormStep = (state) => getOrderTariffCommonData(state).currentStep;
export const selectCommonData = (state) => getOrderTariffCommonData(state).popupData || emptyObj;
export const getFullFromFlag = (state) => getOrderTariffCommonData(state).showFullForm;
export const getAddressView = (state) => getOrderTariffCommonData(state).addressView;
export const getCaptchaFlag = (state) => selectCommonData(state).showCaptcha || false;
export const getIsOpenPopupFlag = (state) => getOrderTariffCommonData(state).isOpen;

// селектор определяет по какому флоу будет осуществляться проверка адреса
// (с переходом сразу в конструктор либо с с полной формой отправки заявки)
export const getIsRedirectToFTTBDetailsPage = (state) =>
  getOrderTariffCommonData(state).isRedirectToFTTBDetailsPage;

// селекторы для текстов
export const getOrderTariffTexts = (state) =>
  selectCommonData(state).texts.fullFormPopupTexts || emptyObj;
export const getAddressRequestTexts = (state) =>
  getOrderTariffTexts(state).addressRequestTexts || emptyObj;
export const getAddressNotAvailableTexts = (state) =>
  getOrderTariffTexts(state).notAvailableTexts || emptyObj;

export const getUserAgreementTexts = createSelector(
  [getAddressRequestTexts, getIsRedirectToFTTBDetailsPage],
  (addressRequestTexts, isToggle) => (!isToggle ? addressRequestTexts.userAgreement : null),
);

// селекторы для получения тарифа
export const getAlternativeTariffType = (state) =>
  getOrderTariffCommonData(state).alternativeTariffType;
export const getTargetTariffType = (state) =>
  getOrderTariffCommonData(state).tariffType || emptyObj;
export const getMobileTariffsAliases = (state) =>
  getOrderTariffCommonData(state).mobileTariffsAliases || emptyArr;

export const getTariffType = (state) => {
  const currentStep = getCurrentFormStep(state);

  return currentStep === popupSteps.alternativeTariff ?
      getAlternativeTariffType(state)
    : getTargetTariffType(state);
};

export const selectIsFttbTariffType = createSelector([getTariffType], ({ tariffType }) =>
  [TARIFF_TYPES.internet, TARIFF_TYPES.kit].includes(tariffType),
);

export const getIsShowFullFormPopup = createSelector(
  [getFullFromFlag, getTariffType, getPageSettings, getUserType],
  (showFullForm, { tariffType }, { isAuth }, userType) => {
    if (userType === USER_TYPES.FTTB_USER) {
      return false;
    }

    const isAllowedTariffTypes = [
      TARIFF_TYPES.internet,
      TARIFF_TYPES.kit,
      TARIFF_TYPES.presetUp,
    ].includes(tariffType);

    return !isAuth && showFullForm && isAllowedTariffTypes;
  },
);

export const getIsPresetUpFlag = createSelector(
  [getTargetTariffType],
  (tariffData) => tariffData.tariffType === TARIFF_TYPES.presetUp,
);
