import axios from 'axios';
import { pathOr } from 'ramda';

import { pushShowTariffConnectionPopupEvent } from 'utils/ga';
import { tariffData } from 'pages/ProductCard/actions/servicesExtension';

import { pollingDownsellTariff } from './pollingDownsellTariff';
import { toggleTariffConnectionPopup } from '../tariffConnect/toggleTariffConnectionPopup';
import { setTariffConnectionPopup } from '../tariffConnect/tariffConnectExtension';
import { setValues as setConstructor } from '../tariffConstructor';

// Events Analytics

const showPopupResult = (downsellData, isSucceeded) => (dispatch) => {
  dispatch(
    setTariffConnectionPopup({
      data: { ...downsellData.texts, isSucceeded },
      downsellStep: 'counteroffer',
      isDownsellPending: false,
    }),
  );
};

/**
 * Replace data for tariff connection pop-up.
 * ------------------------------------------
 * @param url - Url для кнопки подключения тарифа в модальном окне;
 * @param tariff - Объект тарифа;
 * @param downSell - Объект с данными для некоторых типов контроферов;
 * @param canRetry - Возможность сделать запрос заново в случае ошибки
 */
export const replaceDownsellTariff = (url, tariff, downSell, canRetry) => (dispatch, getState) => {
  const tariffConnectionPopup = pathOr({}, ['external', 'tariffConnectionPopup'], getState());
  const connectPopupData = pathOr({}, ['data'], tariffConnectionPopup);
  const downSellPayload = downSell || tariffConnectionPopup.downSellPayload;

  if (connectPopupData.isDownsell && connectPopupData.downsellData) {
    // Начинаем пулинг на бэк если есть requestId, иначе обычное подключение;
    return axios.post(connectPopupData.buttonUrl).then((response) => {
      const { downsellData } = connectPopupData;
      const {
        data: { requestId, isSucceeded },
      } = response;

      if (isSucceeded && requestId === null) {
        // Выводим модальное окно успеха;
        dispatch(showPopupResult(downsellData, true));
      } else if (isSucceeded && requestId) {
        // Начинаем polling запросов на бэк;
        pollingDownsellTariff(downsellData, requestId, dispatch, downsellData.timeout);
      } else {
        // Выводим ошибку
        dispatch(showPopupResult(downsellData, false));
      }
    });
  }

  const popupPayload = {
    connectPopupUrl: url || tariffConnectionPopup.connectPopupUrl,
    downSellPayload,
    canRetry,
  };

  if (tariff) {
    popupPayload.downSellAlias = tariff.alias || null;
    popupPayload.constructorId = tariff.constructorId || null;
  } else if (downSellPayload && downSellPayload.items) {
    popupPayload.constructorId = (
      downSellPayload.items.find((o) => !!o.constructorId) || {}
    ).constructorId;
  } else {
    popupPayload.alias = null;
    popupPayload.soc = null;
  }

  dispatch(toggleTariffConnectionPopup(false));
  dispatch(setTariffConnectionPopup(popupPayload));
  dispatch(tariffData.update({ dataFetched: false }));
  dispatch(toggleTariffConnectionPopup(true));
  dispatch(setConstructor({ value: 0 }));

  pushShowTariffConnectionPopupEvent({
    tariffName: tariff && tariff.title,
    soc: tariff && tariff.soc,
    marketCode: tariff && tariff.marketCode,
    isAuthenticated: true,
  });
};
