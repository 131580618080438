import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IRepeatConf {
  autoFit?: boolean;
  columnsCount?: number;
  itemMinSize?: number;
}

export interface IColumnGridProps {
  children: React.ReactNode;
  className?: string;
  repeatConf?: IRepeatConf;
  template?: Array<string>;
  useRepeat?: boolean;
}

const composeRepeatRule = (conf: IRepeatConf) => `repeat(
  ${conf.autoFit ? 'auto-fit' : conf.columnsCount},
  minmax(${conf.itemMinSize ? `${conf.itemMinSize}px` : 'auto'}, 1fr)
)`;

export const ColumnGrid: React.FC<IColumnGridProps> = ({
  children,
  className,
  repeatConf,
  template = [],
  useRepeat,
}) => (
  <div
    className={cx('component', className)}
    style={{
      gridTemplateColumns:
        useRepeat ? composeRepeatRule(repeatConf as IRepeatConf) : template.join(' '),
    }}
  >
    {children}
  </div>
);
