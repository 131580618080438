import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { apiGetOptionsUrl } from './constants';
import { TSectionsDO } from '../store/tariffSettingsSlice/types';
import { TCommonRequest } from './types';

/** Тип ответа запроса fetchOptions обильных опций ConstructorV1 */
export type TFetchOptionsRes = {
  sections: TSectionsDO[];
} & TCommonRequest;

/** Запрос на получение мобильных опций ConstructorV1 */
export const fetchOptions = async (
  config: AxiosRequestConfig,
): Promise<AxiosResponse<TFetchOptionsRes>> => axios.get(apiGetOptionsUrl, config);
