import axios from 'axios';
import { path } from 'ramda';
import qs from 'query-string';

import store from 'store';
import { stripTags, unformatPhone } from 'utils/format-string';
import { longFormSubmitExistYM, longFormSubmitNewYM } from 'utils/analytics/tariffsYandexMetrica';
import { getCookie } from 'utils/cookie';
import {
  ALLINONE_GET_CONVERGENT_ORDER_DATA,
  ALLINONE_GET_HOUSE,
  ALLINONE_GET_REGION,
  ALLINONE_GET_STREET,
  ALLINONE_REQUEST,
  ALLINONE_REQUEST_HOUSE,
} from 'constants/Endpoints';

import { SET_ALLINONE_VALUE } from './actionTypes';

const houseNullPayload = {
  house: {},
  houses: [],
  flat: '',
  houseIsNotFound: false,
};

const streetNullPayload = {
  ...houseNullPayload,
  streets: [],
  street: {},
  streetIsNotFound: false,
};

const regionNullPayload = {
  ...streetNullPayload,
  regions: [],
  region: {},
  regionIsNotFound: false,
  tariffNotFoundInRegion: false,
};

const pushEventToDataLayer = (eventContent) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      ...eventContent,
    });
  }
};

export const getTariffData = (regionId) => (dispatch) => {
  axios({
    url: ALLINONE_GET_CONVERGENT_ORDER_DATA,
    method: 'get',
    params: { ...qs.parse(window.location.search), regionId },
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
    .then(({ data }) => {
      if (data.isSucceeded) {
        const previousServerData = store.getState()?.external?.allInOne?.serverData || {};
        const newServerData = {
          ...data.view,
          token: previousServerData.token,
          selectedAddressType: previousServerData.selectedAddressType,
        };
        dispatch({
          type: SET_ALLINONE_VALUE,
          payload: {
            formRegionServerData: newServerData,
            serverData: newServerData,
            tariffNotFoundInRegion: false,
          },
        });
      } else {
        dispatch({
          type: SET_ALLINONE_VALUE,
          payload: {
            tariffNotFoundInRegion: true,
            tariffNotFoundInRegionMessage: data.errorMessage,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: SET_ALLINONE_VALUE,
        payload: {
          tariffNotFoundInRegion: true,
          tariffNotFoundInRegionMessage:
            'Не удалось получить тариф для региона по причине ошибки на сервере',
        },
      });
    });
};

export const fetchRegions = (term) => (dispatch) => {
  const dispatchedObject = {
    type: SET_ALLINONE_VALUE,
    resetError: !term?.length >= 3,
    payload: {
      ...regionNullPayload,
    },
  };

  if (!term?.length >= 3) {
    dispatch(dispatchedObject);
    return;
  }

  axios({
    url: ALLINONE_GET_REGION,
    method: 'get',
    params: {
      boldFont: false,
      startsWith: term,
      'ui-culture': 'ru-ru',
    },
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
    .then(({ data }) => {
      dispatchedObject.payload = {
        ...regionNullPayload,
        regions: data.View.map((item) => ({
          label: stripTags(item.Title),
          id: item.MarketingRegionId,
        })),
      };
      dispatch(dispatchedObject);
    })
    .catch(() => {
      dispatchedObject.payload = {
        ...regionNullPayload,
        tariffNotFoundInRegion: true,
        tariffNotFoundInRegionMessage:
          'Не удалось получить подсказку для регионов из-за ошибки на сервере',
      };
      dispatch(dispatchedObject);
    });
};

export const fetchStreets = (cityId, term) => (dispatch) => {
  if (!term || term.length < 3) {
    dispatch({
      type: SET_ALLINONE_VALUE,
      resetError: true,
      payload: {
        ...streetNullPayload,
      },
    });
  } else {
    axios({
      url: ALLINONE_GET_STREET,
      method: 'post',
      data: {
        cityId,
        term,
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then(({ data }) => {
      dispatch({
        type: SET_ALLINONE_VALUE,
        payload: {
          ...streetNullPayload,
          streets: data,
        },
      });
    });
  }
};

export const fetchHouses = (streetId, term) => (dispatch) => {
  if (!term) {
    dispatch({
      type: SET_ALLINONE_VALUE,
      resetError: true,
      payload: {
        ...houseNullPayload,
      },
    });
  } else {
    axios({
      url: ALLINONE_GET_HOUSE,
      method: 'post',
      data: {
        streetId,
        term,
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then(({ data }) => {
      dispatch({
        type: SET_ALLINONE_VALUE,
        payload: {
          houses: data,
          houseIsNotFound: false,
        },
      });
    });
  }
};

export const sendRequiestHouse = (form) => (dispatch) => {
  const phone = unformatPhone(form.PhoneNumber).match(/([0-9]{3,3})([0-9]{7,7})/);

  if (!phone.length) {
    dispatch({
      type: SET_ALLINONE_VALUE,
      resetError: true,
      payload: {
        requestHouse: 'fail',
      },
    });
  }

  axios({
    url: ALLINONE_REQUEST_HOUSE,
    method: 'post',
    data: {
      ...form,
      PhoneCode: phone[1],
      PhoneNumber: phone[2],
    },
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
    .then(({ data }) => {
      const payload = data.IsSucceeded ? { requestHouse: 'success' } : { requestHouse: 'fail' };

      dispatch({
        type: SET_ALLINONE_VALUE,
        payload,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_ALLINONE_VALUE,
        payload: {
          requestHouse: 'fail',
        },
      });
    });
};

const pushFrameMessage = (message) => {
  const isInFrame = window && window !== window.top;

  if (isInFrame && window.parent) {
    window.parent.postMessage(message, '*');
  }
};

export const sendRequiest = (form) => (dispatch, getState) => {
  const allInOne = path(['external', 'allInOne'], store.getState()) || {};
  const serverData = allInOne.serverData || {};
  const isNewUsed = form.SelectedAddressType === 'New';

  const eventContent = {
    clientType: 'B2C',
    ordered_itemSoc: serverData.soc,
    ordered_Type: isNewUsed ? 'New order' : 'Home User',
    ordered_itemTitle: serverData.mobileTariffName,
    ordered_itemPrice: serverData.feePerMonth,
    ordered_itemProduct: 'Convorder',
  };

  const ctn = getCookie('USSS_gaUID');
  if (ctn !== undefined && ctn !== null) {
    eventContent.clientCTN = ctn;
  }

  if (serverData.connectedInacFromParams) {
    eventContent.ordered_addServices = serverData.connectedInacFromParams.replace(/;/g, '|');
  } else {
    eventContent.ordered_addServices = '';
  }

  axios({
    url: ALLINONE_REQUEST,
    method: 'post',
    data: {
      ...form,
      Preset: serverData.preset,
      Soc: serverData.soc,
      Step: serverData.step,
      ConnectedInacFromParams: serverData.connectedInacFromParams,
      DisableInacFromParams: serverData.disableInacFromParams,
    },
    headers: {
      __RequestVerificationToken: serverData.token,
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
    .then(({ data }) => {
      if (data.IsSucceeded) {
        eventContent.event = 'event_item_order_sent_success';

        if (data.View.RequestId !== undefined && data.View.RequestId !== null) {
          eventContent.orderId = data.View.RequestId;
        }

        if (isNewUsed) {
          longFormSubmitNewYM(eventContent.orderId);
        } else {
          longFormSubmitExistYM(eventContent.orderId);
        }
        pushFrameMessage('Convorder_OrderSuccess');
        window.dataLayer.push({
          event: 'viewTransaction',
          tariff: form.TariffName,
          price: path(['external', 'allInOne', 'serverData', 'feePerMonth'], getState()) || null,
          number: data.View.RequestId,
        });
      }

      if (
        data.IsSucceeded === false &&
        path(['Errors', 'Error', 'ErrorMessage'], data) ===
          'Заявка для данного адреса уже существует!'
      ) {
        eventContent.event = 'event_item_order_sent_double';
        eventContent.ordered_itemPrice = serverData.feePerMonth;
        pushFrameMessage('Convorder_OrderError');
      } else if (!data.IsSucceeded) {
        eventContent.event = 'event_item_order_sent_server_error';
        eventContent.error_type = 'server_error';
      }

      pushEventToDataLayer(eventContent);

      dispatch({
        type: SET_ALLINONE_VALUE,
        payload: {
          requestData: data,
        },
      });
      return data;
    })
    .catch(({ data }) => {
      /* TODO */
      /* error text */
      eventContent.event = 'event_item_order_sent_server_error';
      eventContent.error_type = 'technical_error';

      pushFrameMessage('Convorder_OrderError');
      pushEventToDataLayer(eventContent);

      dispatch({
        type: SET_ALLINONE_VALUE,
        payload: {
          requestData: data || {
            IsSucceeded: false,
            ErrorMessage:
              'Во время отправки заявки произошла ошибка. Мы уже работаем над её исправлением, приносим свои извинения. Пожалуйста, позвоните по номеру 8(800)700&nbsp8000 и мы примем вашу заявку. Звонок бесплатный.',
            Errors: {
              CreateRequestError: true,
            },
          },
        },
      });
    });
};
