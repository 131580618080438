import React from 'react';
import { ActionBar, Button } from '@beef/ui-kit';

import { TActionBarProps } from '../types';

export const CommonActionBar: React.FC<TActionBarProps> = ({
  onConfirm,
  confirmText,
  onCancel,
  cancelText,
}) => (
  <ActionBar size="m">
    {onConfirm && confirmText && <Button onClick={onConfirm}>{confirmText}</Button>}
    {onCancel && cancelText && <Button onClick={onCancel}>{cancelText}</Button>}
  </ActionBar>
);
