import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text, Tooltip } from '@beef/ui-kit';

import { tileTitleHyphens } from './utils/tileTitleHyphens';
import { OptionPickerIcon } from './components/OptionPickerIcon';
import { OptionPickerPrice } from './components/OptionPickerPrice';
import { TOptionPickerTooltip } from './components/OptionPickerTooltip';
import { TOptionPickerTile } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент – плитка одной опции */
export const OptionPickerTile = memo<TOptionPickerTile>(
  ({
    soc,
    icon,
    activeIcon,
    price,
    title: rawTitle,
    tooltipText = '',
    onChange,
    isActive,
    isBlocked,
    blockingTooltipText,
  }) => {
    const title = tileTitleHyphens(rawTitle);
    const tileButton = (
      <button
        className={cx('reset-tile', 'button')}
        onClick={() => !isBlocked && onChange?.(soc, rawTitle, isActive)}
        type="button"
      >
        <OptionPickerIcon {...{ icon, activeIcon, isActive }} />
        <OptionPickerPrice {...{ price, isBlocked }} />
        <Text color="inherit" size="size7-r">
          {title}
        </Text>
      </button>
    );

    if (isBlocked) {
      return (
        <div className={cx('tile', { _active: isActive })}>
          <Tooltip message={blockingTooltipText} position="top">
            {tileButton}
          </Tooltip>
          <TOptionPickerTooltip tooltipText={tooltipText} />
        </div>
      );
    }

    return (
      <div className={cx('tile', { _active: isActive })}>
        {tileButton}
        <TOptionPickerTooltip tooltipText={tooltipText} />
      </div>
    );
  },
);
