import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ActionBarButtons: FC<React.PropsWithChildren> = ({ children }) => (
  <div className={cx('action-bar-buttons')}>{children}</div>
);
