import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { BillTotal } from './components/BillTotal';
import { BillButton } from './components/BillButton';
import { usePrepareBillProps } from './hooks/usePrepareBillProps';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Чек на странице тарифа */
export const Bill = memo(() => {
  const { totalProps, billBtnProps } = usePrepareBillProps();

  return (
    <section className={cx('wrapper')}>
      <BillTotal {...totalProps} />
      <BillButton {...billBtnProps} />
    </section>
  );
});
