import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import {
  Button,
  Heading,
  InputsRow,
  Link,
  SmartForm,
  SmartInput,
  Status,
} from '@beef/legacy-ui-kit';
import { findIndex } from 'ramda';

import Icon from 'pages-components/Icon';
import { translate } from 'utils/format-string';

import style from './styles.pcss';

const cx = classnames.bind(style);

const phoneMask = '+7 ___ ___ __ __';

class ComponentClass extends Component {
  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({
      isMounted: true,
    });
  }

  phoneValidator = () => {
    const { numbers, phone } = this.props;
    const number = phone.replace(/\D/gi, '');

    if (findIndex((element) => phone === element)(numbers) !== -1) {
      return { status: 'fail', msg: 'Такой номер уже есть в списке' };
    }

    if (number[0] === '7') {
      if (number.length === 11) {
        return { status: 'ok' };
      }

      if (phone === '+7') {
        return { status: 'ok' };
      }

      return { status: 'fail', msg: 'Неверный формат номера телефона' };
    }

    return { status: 'fail' };
  };

  onPhoneChange = (value) => {
    this.props.onPhoneChange(value);

    if (value.length === 16) {
      setTimeout(() => {
        this.phoneValidator().status === 'ok' && this.props.onPhoneAdd(value);
      });
    }
  };

  render() {
    const {
      numbers,
      maxNumbers,
      errorText,
      lastRemovedNumber,
      phone,
      isDisabled,
      showButton,
      onPhoneAdd,
      onPhoneRecover,
      onPhoneRemove,
      onSave,
    } = this.props;
    const { isMounted } = this.state;
    const currentNumbers = numbers.length;
    const textNumbers = translate('numbers', currentNumbers);
    const overflowAfter = maxNumbers - currentNumbers;
    const overflow = overflowAfter <= 0;

    return (
      <div>
        <Heading level={1}>Настройка черного списка</Heading>
        {!overflow && (
          <div className={cx('tip')}>Введите номер телефона, который хотите заблокировать</div>
        )}
        <SmartForm onSubmit={onPhoneAdd} validator={{ phone: this.phoneValidator }}>
          {overflow ?
            <Status
              className={['attention', cx('blackListStatus')]}
              emoji="statusFail"
              text="Список номеров заполнен. Удалите один из номеров, чтобы добавить другой"
            />
          : <div>
              <InputsRow>
                <SmartInput
                  className={cx('phoneInput')}
                  forceTouch={phone.length === 16}
                  inputId="phone"
                  keepInitialValue
                  mask={phoneMask}
                  onChange={this.onPhoneChange}
                  value={isMounted ? phone : '+7'}
                />
              </InputsRow>
            </div>
          }
          {lastRemovedNumber && (
            <div className={cx('recover')}>
              Номер {lastRemovedNumber} был удален из списка
              <br />
              <br />
              <Link onClick={onPhoneRecover}>Восстановить</Link>
            </div>
          )}
          {errorText && (
            <Status
              className={['error', cx('blackListStatus', 'beforeOverflow')]}
              emoji="statusFail"
              text={errorText}
            />
          )}
          {currentNumbers ?
            <div className={cx('numberList')}>
              <Heading className={cx('listHeader')} level={4}>
                {`${currentNumbers} ${textNumbers} в списке`}
              </Heading>
              <div className={cx('numbers')}>
                {numbers.map((num) => (
                  <div className={cx('phoneRow')} key={num}>
                    <span className={cx('phone')}>{num}</span>
                    <span onClick={() => onPhoneRemove(num)} role="button" tabIndex="0">
                      <Icon className={cx('removePhone')} name="crossThin" />
                    </span>
                  </div>
                ))}
              </div>
              {currentNumbers > 4 ?
                <div className={cx('fade')} />
              : null}
            </div>
          : null}
          {overflowAfter <= 3 && overflowAfter !== 0 && (
            <Status
              className={['attention', cx('blackListStatus', 'beforeOverflow')]}
              emoji="statusFail"
              text={`Вы можете добавить еще ${overflowAfter} ${translate('number', overflowAfter)}`}
            />
          )}
        </SmartForm>
        {showButton && (
          <div className={cx('submitWrapper')}>
            <Button className="big" disabled={isDisabled} onClick={onSave} type="submit">
              Сохранить
            </Button>
          </div>
        )}
      </div>
    );
  }
}

ComponentClass.defaultProps = {
  phone: '',
};

ComponentClass.propTypes = {
  numbers: PropTypes.array,
  maxNumbers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorText: PropTypes.string,
  lastRemovedNumber: PropTypes.string,
  phone: PropTypes.string,
  isDisabled: PropTypes.bool,
  showButton: PropTypes.bool,
  isAddDisabled: PropTypes.bool,
  onPhoneAdd: PropTypes.func,
  onPhoneChange: PropTypes.func,
  onPhoneRecover: PropTypes.func,
  onPhoneRemove: PropTypes.func,
  onSave: PropTypes.func,
};

export default ComponentClass;
