import React from 'react';
import classNames from 'classnames/bind';
import { Pagination } from '@carnica/ui-kit';

import { DEFAULT_INDEX } from 'constructorV1/constants';

import { useCardScroll } from '../../hooks/useCardScroll';
import type { TBenefitsWithPagination } from './types';
import { BenefitsShadow } from '../BenefitsShadow';
import { BenefitsCard } from '../BenefitsCard';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент ЕК BenefitsWithPagination состоит из карусели карточек с пагинацией */
export const BenefitsWithPagination = ({ benefits }: TBenefitsWithPagination) => {
  const { refCard, refCards, point, activeCard, handelClick, activeCardIndex } =
    useCardScroll(benefits);

  return (
    <div className={cx('wrapper')}>
      <div className={cx('wrapper__benefits')}>
        {/* Компонент для отображения тени.
        Если activeBenefitIndex РАВЕН индексу первого элемента benefits, скрываем тень */}
        <BenefitsShadow isHidden={activeCardIndex === DEFAULT_INDEX} />
        <ul className={cx('wrapper__cards')} ref={refCards}>
          {benefits.map((benefit, i) => (
            <li className={cx('wrapper__cards--item')} key={benefit.title} ref={refCard}>
              <BenefitsCard
                description={benefit.description}
                iconUrl={benefit.iconUrl}
                isActive={benefits[i] === activeCard}
                onClick={() => handelClick(i)}
                title={benefit.title}
              />
            </li>
          ))}
        </ul>
        {/* Компонент для отображения тени.
        Если activeBenefitIndex РАВЕН индексу последнего элемента benefits, скрываем тень */}
        <BenefitsShadow isHidden={activeCardIndex === benefits.length - 1} isRight />
      </div>
      <Pagination
        activePoint={point}
        className={cx('wrapper__pagination')}
        direction="row"
        onPointClick={(i) => handelClick(i - 1)}
        points={benefits.length}
      />
    </div>
  );
};
