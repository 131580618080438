/**
 @description - Фукция считывает utm-метки с URL услуги и возвращает URL с параметрами AppsFlyer
 @return {string|null}
 @param {string} url - Location.search, содержащий параметры URL
 @param {string} appStore - Идентификатор store приложения
 */
export const getAFAnalyticsURL = (url, appStore) => {
  const baseUrl = 'https://app.appsflyer.com/';
  const appId = {
    android: 'ru.beeline.services',
    ios: '569251594',
  };

  let href = null;
  const urlParams = new URLSearchParams(url);
  const utm = {
    utmSource: urlParams.get('utm_source') || undefined,
    utmCampaign: urlParams.get('utm_campaign') || undefined,
    utmMedium: urlParams.get('utm_medium') || undefined,
    utmTerm: urlParams.get('utm_term') || undefined,
    utmContent: urlParams.get('utm_content') || undefined,
  };

  if (
    utm.utmCampaign === 'Beeline_GigiZaSon_2019_OLV_HM' ||
    utm.utmCampaign === 'Beeline_GigiZaSon_2019_Reach_HM' ||
    utm.utmCampaign === 'Beeline_GigiZaSon_2019_Performance_HM'
  ) {
    const afParameters = new URLSearchParams();

    afParameters.append('pid', utm.utmSource);
    afParameters.append('c', utm.utmCampaign);
    afParameters.append('af_adset', utm.utmContent);
    afParameters.append('af_channel', utm.utmMedium);
    afParameters.append('af_keywords', utm.utmTerm);

    href = `${baseUrl}${appId[appStore]}?${afParameters.toString()}`;
  }

  return href;
};

/**
 @description - Фукция считывает GET-параметры с URL услуги и возвращает URL my.bee.page
 @return {string|null}
 @param {string} url - Location.search, содержащий параметры URL
 */
export const getMyBeePageURL = (url) => {
  const myBeePageUrl = {
    isSms: 'https://mybee.page.link/insurance-landing-sms',
    isPush: 'https://mybee.page.link/insurance-landing-dstk',
    fromMainPage: 'https://mybee.page.link/insurance-landing-mainpage-banner',
  };

  const urlParams = new URLSearchParams(url);
  const params = {
    utmSource: urlParams.get('utm_source'),
    utmCampaign: urlParams.get('utm_campaign'),
    utmMedium: urlParams.get('utm_medium'),
    fromMainPage: urlParams.get('fromMain'),
  };

  if (params.utmSource === 'beeline' && params.utmCampaign === 'zashhita-ekrana') {
    if (params.utmMedium === 'sms') {
      return myBeePageUrl.isSms;
    }

    if (params.utmMedium === 'push') {
      return myBeePageUrl.isPush;
    }
  } else if (params.fromMainPage) {
    return myBeePageUrl.fromMainPage;
  }

  return null;
};

/**
 @description - Фукция считывает GET-параметры с URL услуги и возвращает URL //mybeeline.onelink.me/
 @return {string|null}
 @param {string} url - Location.search, содержащий параметры URL
 */
export const getMyBeelineURL = (url) => {
  const myBeelineUrl = 'https://mybeeline.onelink.me/fAcl?pid=mybee&utm_campaign=\\';
  const actualCampaigns = [
    'Beeline_Gigi_za_mozgi_2019_SP_HM',
    'Beeline_Gigi_za_mozgi_2019_Reach_HM',
    'Beeline_Gigi_za_mozgi_2019_Performance_HM',
    'Beeline_Gigi_za_mozgi_2019_OLV_HM',
  ];

  const utmCampaign = new URLSearchParams(url).get('utm_campaign');

  for (let i = 0; utmCampaign && i < actualCampaigns.length; i++) {
    if (actualCampaigns[i] === utmCampaign) {
      return `${myBeelineUrl}${utmCampaign}`;
    }
  }

  return null;
};
