import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import { getFmcSum } from '../../../../utils/getFmcSum';
import { getFttbServiceAndEquipmentPrice } from '../utils';
import { useIsMyTariff } from '../../../../hooks/useIsMyTariff';
import { TabTypes } from '../../../constructorPage/Tabs/constants';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { useStepNavigation } from '../../../../hooks/useStepNavigation';
import { setConvergencePrice } from '../../../../store/slices/totalSlice';
import { EConstructorStep } from '../../../../store/slices/stepperSlice/types';
import { countConnectFttbPrice } from '../../../../utils/countConnectFttbPrice';
import { DEFAULT_TAB_ID } from '../../../constructorPage/FmcTabsContainer/constants';

/** Хук подсчет общей суммы ШПД */
export const useFmcCountPrice = () => {
  const dispatch = useAppDispatch();
  const isMyTariff = useIsMyTariff();
  const { currentStep } = useStepNavigation();

  /* Получаем выбранные inacId и тарифы ШПД */
  const {
    tariffs,
    previewTariffs,
    priceCategory,
    tariffInacId,
    currentTariffTab,
    servicesInacIdList,
    connectedFmcInacId,
  } = useAppSelector(
    (state) => ({
      connectedFmcInacId: state.common.userInfo.convergenceConnectedInfo?.inacId,
      tariffInacId: state.total.convergenceInfo?.tariffInacId ?? DEFAULT_TAB_ID,
      convergenceOldPrice: state.total.convergenceInfo?.convergenceOldPrice,
      servicesInacIdList: state.total.convergenceInfo?.servicesInacIdList,
      previewTariffs: state.fttbPresets.previewTariffs,
      currentTariffTab: state.total.currentTariffTab,
      priceCategory: state.total.priceCategory,
      tariffs: state.fttbPresets.tariffs,
    }),
    shallowEqual,
  );

  /** Флаг вернет true, если пользователь является конвергентом (имеется connectedFmcInacId),
   * и текущий таб способа подключения тарифа "ChangeTariffForm" (Изменить тариф) */
  const isDefaultConnectConvergence =
    connectedFmcInacId && currentTariffTab === TabTypes.ChangeTariffForm;

  /** Флаг определяет, нужно ли на текущем шаге настройки тарифа показывать полную стоимость ШПД в чеке.
   * Если текущий степ (currentStep) - это fttbSettings (настройка сервисов ШПД) или specialistCall (вызов специалиста), флаг вернет true */
  const isStepWithFullConvergencePrice = [
    EConstructorStep.fttbSettings,
    EConstructorStep.specialistCall,
  ].includes(currentStep);

  /** Определяем текущий ШПД-тариф */
  const currentTariff = tariffs?.find((item) => item.inacId === tariffInacId);

  /* НОВОЕ ПОДКЛЮЧЕНИЕ ШПД */

  /** Считаем стоимость (текущая, старая) НОВОГО выбранного пресета ШПД (ТОЛЬКО СТОИМОСТЬ ИНТЕРНЕТА) */
  const { fmcPrice = 0, fmcOldPrice = 0 } =
    getFmcSum(previewTariffs, tariffInacId, priceCategory) ?? {};

  /** Считаем стоимость (текущая, старая) НОВЫХ сервисов/услуг ШПД */
  const { price: fttbServicePrice, oldPrice: fttbServiceOldPrice } =
    getFttbServiceAndEquipmentPrice(currentTariff, servicesInacIdList) ?? {};

  /* ПОДКЛЮЧЕННЫЙ ШПД (если имеется) */

  /** Считаем стоимость (текущая, старая) подключенного ШПД (ИНТЕРНЕТ + УСЛУГИ) */
  const { connectConvergencePrice, connectConvergenceOldPrice } =
    countConnectFttbPrice({ currentTariff, servicesInacIdList }) ?? {};

  /** Определяем ОБЩУЮ стоимость (ИНТЕРНЕТ + УСЛУГИ) НОВОГО ШПД.
   * Если текущий степ fttbSettings (настройка сервисов ШПД) или specialistCall (вызов специалиста),
   * суммируем стоимость интернета (fmcPrice) и стоимость услуг (fttbServicePrice).
   * Иначе вернем ТОЛЬКО стоимость интернета */
  const newConnectionConvergencePrice =
    isStepWithFullConvergencePrice ? fmcPrice + fttbServicePrice : fmcPrice;

  const newConnectionConvergenceOldPrice =
    isStepWithFullConvergencePrice ? fmcOldPrice + fttbServiceOldPrice : fmcOldPrice;

  /* Общая стоимость ШПД (новая/старая цены) */
  const convergencePrice = Number(
    isDefaultConnectConvergence ? connectConvergencePrice : newConnectionConvergencePrice,
  );

  const convergenceOldPrice = Number(
    isDefaultConnectConvergence ? connectConvergenceOldPrice : newConnectionConvergenceOldPrice,
  );

  useEffect(() => {
    /* Если текущий шаг настройки тарифа fttbConnectedSettings (настройка ПОДКЛЮЧЕННОГО ШПД) и
     * меняются какой-то из сервисов (!isMyTariff),
     * перезаписываем стоимость ШПД в total */
    if (currentStep === EConstructorStep.fttbConnectedSettings && !isMyTariff)
      dispatch(setConvergencePrice({ price: convergencePrice, oldPrice: convergenceOldPrice }));
  }, [currentStep, isMyTariff, convergencePrice, convergenceOldPrice]);

  return { convergencePrice, convergenceOldPrice };
};
