import { MutableRefObject, useEffect, useRef } from 'react';
import { formatOrderData, handleSuccessRequest } from '@beef/layout-kit/utils';
import { setCartData } from '@carnica/smart-kit';

import { headerStore2024 } from 'store';
import { useAppDispatch } from 'constructorV1/store';
import { basketQuery } from 'constructorV1/store/totalSlice/api/basketQuery';
import { TBasketQueryRes } from 'constructorV1/api/fetchBasket';

import { useSelectBasketParams } from './useSelectBasketParams';

/** Возвращает событие запроса в корзину */
export const useConnectBasket = () => {
  /* Добавили any из-за проблем с типизацией dispatch */
  const fetchBasketPromise: MutableRefObject<any> = useRef();
  const dispatch = useAppDispatch();
  const { params } = useSelectBasketParams();

  /* Отмена запроса к корзине при выходе со страницы финалного чека  TotalBill */
  useEffect(
    () => () => {
      fetchBasketPromise.current?.abort();
    },
    [],
  );

  const handleBasketSubmit = async () => {
    /* Преобразует данные для формата корзины */
    const requestData = formatOrderData(params);
    fetchBasketPromise.current = dispatch(basketQuery(requestData));
    fetchBasketPromise.current
      ?.unwrap()
      .then((data: TBasketQueryRes) => {
        if (data.id) {
          handleSuccessRequest({
            cartId: data.id,
            items: data.items,
          });

          if (data) {
            headerStore2024().dispatch(setCartData(data));
          }
        }
      })
      .then(() => {
        if (typeof window !== 'undefined') {
          window.location.href = '/basket/';
        }
      })
      .catch(() => {});
  };
  return { handleBasketSubmit };
};
