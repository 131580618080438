/** Варианты layoutType тарифов */
export const TARIFF_LAYOUTS = {
  vip: 'vip',
  young: 'young',
  yandex: 'yandex',
  digital: 'digital',
  ztariff: 'ztariff',
  default: 'default',
  tariffLive: 'tariffLive',
  constructor: 'constructor',
  baseConstructor: 'baseConstructor',
  tariffConstructor: 'tariffConstructor',
  unitedConstructor: 'unitedConstructor',
} as const;

/** Варианты layoutType тарифов, которые должны открываться на новом конструкторе (ConstructorTariff2023) */
export const CONSTRUCTOR2023_LAYOUTS_TYPES: Array<keyof typeof TARIFF_LAYOUTS> = [
  TARIFF_LAYOUTS.tariffLive,
  TARIFF_LAYOUTS.baseConstructor,
  TARIFF_LAYOUTS.tariffConstructor,
];
