import React from 'react';
import classnames from 'classnames/bind';
import { Link, Text } from '@beef/ui-kit';

import styles from './styles.pcss';
import { LoginLinkFormPropsType } from './types';

const cn = classnames.bind(styles);

export const LoginLinkForm = ({
  description,
  loginButtonText,
  loginButtonUrl,
}: LoginLinkFormPropsType) => (
  <div className={cn('content')}>
    {description && <Text size="size6-r">{description}</Text>}
    {loginButtonText && (
      <Link buttonSize="m" buttonVariant="main" href={loginButtonUrl} variant="button">
        {loginButtonText}
      </Link>
    )}
  </div>
);
