import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const OpenArrowIcon = ({ isOpen, className }) => (
  <Icon className={cx('arrowIcon', { opened: isOpen }, className)} name="dropdownArrow" />
);

OpenArrowIcon.defaultProps = { isOpen: false };
OpenArrowIcon.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.any,
};

export default memo(OpenArrowIcon);
