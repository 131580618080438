import React from 'react';
import { connect } from 'react-redux';
import { isPlainObject } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { Tag } from '@beef/ui-kit';

import { SCREEN_ID_CONFIG } from 'pages/Services/ScopeZeroBalanceServices/constants';
import useGetServiceNotification from 'pages/Services/ZeroBalanceSupport/hooks/useGetServiceNotification';

const SERVICE_INFO_COLOR_CONFIG = {
  yellow: 'gold',
  red: 'warning',
};

const _ServiceInfo = ({ isAuthenticated }) => {
  const status = useGetServiceNotification(SCREEN_ID_CONFIG.services, isAuthenticated);

  return status ?
      <Tag size="m" textColor="black" variant={SERVICE_INFO_COLOR_CONFIG[status.color]}>
        {status.text}
      </Tag>
    : null;
};

_ServiceInfo.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: isPlainObject(state.external.user),
});

export const ServiceInfo = connect(mapStateToProps)(_ServiceInfo);
