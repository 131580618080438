import React, { ChangeEvent, FC } from 'react';
import classNames from 'classnames/bind';
// Components
import { Button, ChevronRightIcon, ChipsStateless, ReloadIcon, Text } from '@beef/ui-kit';

import { ymPushParams } from 'utils/ym';

// Types
import { ButtonsBlockProps } from './types';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ButtonsBlock: FC<ButtonsBlockProps> = ({
  content,
  presets,
  showTabs,
  handleClick,
  needForRetry,
  isCheckedConvergence,
  isShowAvailableSettings,
  hasRequestConvergenceError,
  isOldConvergence,
}) => {
  // TODO: разнести компонент во втором стейдже
  const btnText =
    (needForRetry && content?.retryBtnText) ||
    (isOldConvergence && content?.btnArchiveText) ||
    (hasRequestConvergenceError ? content?.retryBtnText : content?.btnText);
  const icon =
    hasRequestConvergenceError ? <ReloadIcon size={20} /> : <ChevronRightIcon size={20} />;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const activePreset = presets?.find((i) => i.value === value);

    if (activePreset?.hasEvent) {
      ymPushParams(activePreset?.title);
      handleClick(value);
    }
  };

  const handleButtonClick = () => {
    handleClick();
  };

  return (
    <>
      {showTabs && presets && (
        <ChipsStateless
          checkedValues="default"
          className={cx('chips-container')}
          isScrollable
          onChange={handleChange}
          options={presets}
          type="checkbox"
          variant="card"
        />
      )}
      {((!showTabs && isShowAvailableSettings && content?.btnText) || needForRetry) && (
        <div className={cx('container')}>
          <div
            className={cx('container__btn', {
              'container__btn--min': !isCheckedConvergence,
              'container__btn--reload': hasRequestConvergenceError,
            })}
          >
            <Button
              fullWidth
              isLoading={!isCheckedConvergence}
              onClick={handleButtonClick}
              rightIcon={icon}
              size="l"
              variant="plain"
            >
              {btnText}
            </Button>
          </div>
          {!isCheckedConvergence && (
            <Text size="size5-r-l" tagType="h2">
              {content?.loadingText}
            </Text>
          )}
        </div>
      )}
    </>
  );
};
