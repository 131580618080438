import React from 'react';
import { connect } from 'react-redux';

import { GenericInput } from './GenericInput/index';
import { getFilterPlaceholder } from '../../../store/selectors/content';
import { FILTER_FAVORITE } from '../../../constants';
import { FilterInputShape } from '../../../shapes';

const FavoriteInputLayout = ({ query, placeholder, onQueryChange }) => (
  <GenericInput
    mask={{
      mask: '',
      format: '#######',
    }}
    onChange={onQueryChange}
    placeholder={placeholder}
    value={query}
    withIcon
  />
);

FavoriteInputLayout.propTypes = FilterInputShape;

export const FavoriteInput = connect((state) => ({
  placeholder: getFilterPlaceholder(FILTER_FAVORITE, state),
}))(FavoriteInputLayout);
