import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

const positionStates = {
  none: 'none',
  onStopBlock: 'onStopBlock',
};

const getStopBlockPosition = (top, customCorrectionPosition) =>
  top + window.scrollY + customCorrectionPosition;
/**
 * @description: хук для нахождения точки пересечения между целевым DOM-объектом и определенной позицией на странице.
 * @param positionData, id целевого элемента, числовое значение stopPosition и число для коррекции отступа
 * @returns {object}
 */

export const useStopBlockPosition = ({ stopBlockId, customCorrectionPosition = 0 }) => {
  const [anchor, setAnchor] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(positionStates.none);
  const isMobile = useIsMobileDimension();

  let observer = null;
  let target = null;

  if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
    target = document.querySelector(`#${stopBlockId}`);

    observer = new IntersectionObserver(
      ([entry]) => {
        const {
          isIntersecting,
          boundingClientRect: { top },
        } = entry;

        if (!isIntersecting && currentPosition === positionStates.onStopBlock) {
          setCurrentPosition(positionStates.none);
          setAnchor(null);
        }

        if (isIntersecting && currentPosition !== positionStates.onStopBlock) {
          const stopBlockPosition = getStopBlockPosition(top, customCorrectionPosition);

          setCurrentPosition(positionStates.onStopBlock);
          setAnchor({ top: `${stopBlockPosition}px` });
        }
      },
      {
        root: null,
        threshold: 0.1,
      },
    );
  }

  useEffect(() => {
    if (!isMobile && target && observer) {
      observer.observe(target);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, target, isMobile]);

  return {
    anchor,
    currentPosition,
  };
};

useStopBlockPosition.propTypes = {
  stopBlockId: PropTypes.string,
  stopPosition: PropTypes.number,
  customCorrectionPosition: PropTypes.number,
};
