import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Card } from '@beef/smart-kit';
import { Link, Text } from '@beef/ui-kit';

// Styles
import { ymPushParams } from 'utils/ym';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const VoWiFiDisconnected = ({ title, description, linkText, linkUrl }) => {
  useEffect(() => {
    ymPushParams({ vowifi: { vowifi_not_available_v1: 'view_screen' } });
  }, []);

  const onLinkClick = useCallback((e) => {
    if (e.target.tagName === 'A') {
      ymPushParams({
        vowifi: { vowifi_not_available_v1: { link_click: e.target.href } },
      });
    }
  }, []);

  const onButtonCLick = useCallback(() => {
    ymPushParams({ vowifi: { vowifi_not_available_v1: 'more_info' } });
  }, []);
  return (
    <Card bordered={false} className={cn('status')}>
      <div className={cn('status__title')}>
        <Text size="size4-m" tagType="h3">
          {title}
        </Text>
      </div>
      <div className={cn('status__description')} onClick={onLinkClick}>
        <Text color="grey50" size="size6-r" tagType="p">
          {description}
        </Text>
      </div>
      <Link
        buttonSize="m"
        buttonVariant="main"
        href={linkUrl}
        onClick={onButtonCLick}
        variant="button"
      >
        {linkText}
      </Link>
    </Card>
  );
};

VoWiFiDisconnected.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
};

export { VoWiFiDisconnected };
