import { fetchYandexProducts } from './api';
import { TInput, selectDescriptionName } from './selectors';
import { TariffUpMetaData } from './store/TariffUpMetaData';
import { TariffUpModals } from './store/TariffUpModals';
import { EDescriptionName, EYandexPopupType } from './types';
import { onConfirm } from './YandexModal/ConnectedModal/handler';

export const handleYandex = async () => {
  const description = selectDescriptionName([TariffUpMetaData.Products] as unknown as TInput);

  switch (description) {
    case EDescriptionName.ConnectedWithTrialNotActivated:
    case EDescriptionName.ConnectedWithoutTrialNotActivated:
      TariffUpModals.YandexPopupType = EYandexPopupType.Activation;
      break;
    case EDescriptionName.ConnectedWithTrialActivated:
    case EDescriptionName.ConnectedWithoutTrialActivated:
      TariffUpModals.YandexPopupType = EYandexPopupType.Family;
      break;
    case EDescriptionName.DisconnectedButTrialInProgress:
    case EDescriptionName.DisconnectedButSubscriptionInProgress:
    case EDescriptionName.NotConnectedNotActivated:
      await onConfirm();
      break;
    default:
      console.error(`"${description}" doesn't have a corresponding handler`);
      break;
  }

  TariffUpModals.IsLoading = false;
  TariffUpModals.IsOpen = true;
};

export const tryGetYandexSubscriptions = async () => {
  try {
    const isAuthenticated = TariffUpMetaData.IsAuthenticated;
    const soc = TariffUpMetaData.Soc;

    if (isAuthenticated) {
      throw new Error('Not fetching because should only fetch in non-authenticated scenario');
    }

    if (!soc) {
      throw new Error('No soc was provided');
    }

    const productsResponse = await fetchYandexProducts(soc);
    const previousResponse = TariffUpMetaData.Products;

    TariffUpMetaData.Products = {
      ...previousResponse,
      productList: [
        ...(previousResponse.productList || []),
        ...(productsResponse.productList || []),
      ],
    };
  } catch (e) {
    console.error(e);
  }
};
