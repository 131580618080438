import { useEffect, useState } from 'react';

import { getQueryParamByKey } from 'utils/yandexTariffHelpers';

// NOTE: Для упрощения вытягивания параметров из УРЛ
const getRegExp = (param: string) => new RegExp(`${param}=([^&]+)`);
export const getValue = (param: string, str: string) => getRegExp(param).exec(str)?.[1];

type TResult = {
  accessToken: null | string;
  location?: {
    hash: string;
    href: string;
    search: null | string;
  };
  state?: string;
  url: null | string;
};

// NOTE: This is necessary to happen on mount
// since SSR on the back-end does not support DOM API...
export const useYandexUrlParams = () => {
  const [result, setResult] = useState<TResult>({
    url: null,
    accessToken: null,
  });

  useEffect(() => {
    /* собираем нужные для работы компоненты данные из url */
    const { location, decodeURIComponent } = window || {};
    const { search, hash, href } = location || {};
    const urlParams = new URLSearchParams(decodeURIComponent(search));
    const url = urlParams.get('redirectUrl');
    const accessToken = getQueryParamByKey(hash, 'access_token');

    // NOTE: Если на эту страницу перешли с Яндекс OAuth,
    // то чтобы вернуть пользователя на изначальный урл, с которого он уходил,
    // Яндекс OAuth API предоставляет поле state, где можно пробрасывать произвольные данные
    // https://yandex.ru/dev/id/doc/dg/oauth/reference/web-client.html#web-client__js
    const state = getValue('state', href);

    setResult({
      location,
      url,
      accessToken,
      state,
    });
  }, []);

  return result;
};
