import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@beef/ui-kit';

import { declension } from 'utils/format-string';

const CountDown = ({ countDownStart, onCountdownEnd, countdownText }) => {
  const [countDown, setCountDown] = useState(countDownStart);
  useEffect(() => {
    if (countDown <= 0) {
      onCountdownEnd();
      return;
    }
    const interval = setTimeout(() => {
      setCountDown(countDown - 1000);
    }, 1000);
    return () => clearTimeout(interval);
  }, [countDown]);

  if (countDown <= 0) {
    return null;
  }

  return (
    <Text size="size6-r">
      {countdownText} {countDown / 1000}{' '}
      {declension(['секунду', 'секунды', 'секунд'], countDown / 1000)}
    </Text>
  );
};

CountDown.propTypes = {
  countDownStart: PropTypes.number,
  onCountdownEnd: PropTypes.func,
  countdownText: PropTypes.string,
};

export default CountDown;
