import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const RefreshTile = ({ direction, label, text, iconName, onClick }) => (
  <div className={cx('ctn', `ctn-direction--${direction}`)}>
    {label && (
      <div className={cx('ctn__block')}>
        <button className={cx('action-button')} onClick={onClick} type="button">
          <div className={cx('action-button__icon')}>
            <Icon name={iconName} />
          </div>
          <div className={cx('action-button__label')}>{label}</div>
        </button>
      </div>
    )}
    {text && <div className={cx('ctn__block')}>{text}</div>}
  </div>
);

RefreshTile.defaultProps = {
  direction: 'column',
  iconName: 'arrowCircled',
  onClick: () => {
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  },
};

RefreshTile.propTypes = {
  direction: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  iconName: PropTypes.string,

  onClick: PropTypes.func,
};

export default RefreshTile;
