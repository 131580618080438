import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, CatalogBanner, Loader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';
import MobileAppBanner from 'pages-components/SmsServiceExtension/components/MobileAppBanner';

import styles from './Popup.pcss';

const cx = classNames.bind(styles);

const loaderStatuses = {
  ready: 'ready',
  pending: 'active',
  success: 'done',
  error: 'done',
};

const fixWindow = (fix) => {
  if (window.chrome && window.chrome.webstore) {
    return fix ? document.body.classList.add('fixed') : document.body.classList.remove('fixed');
  }
};

export default class Popup extends Component {
  constructor(props) {
    super(props);

    this.state = { movedUp: false };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.status === 'pending' && !prevState.movedUp) {
      setTimeout(() => this.setState({ movedUp: true }), 1000);
      fixWindow(true);
    }
  }

  handleCloseClick = () => {
    fixWindow(false);
    this.props.onClose();
    this.setState({ movedUp: false });
  };

  render() {
    const { status, ctn, errorMessage, advert, mobileAppBanner, currentCtn, sendMessageUrl } =
      this.props;
    const { movedUp } = this.state;
    let loaderText = '';

    if (status === 'pending') {
      loaderText = 'Отправляем SMS...';
    } else if (status === 'success') {
      loaderText = `SMS на <span class="noBreakCtn">${ctn}</span> отправлено`;
    } else if (status === 'error') {
      loaderText = errorMessage;
    }

    return (
      <PopupStateless
        noCross={status === 'pending'}
        onClose={this.handleCloseClick}
        opened={['pending', 'success', 'error'].indexOf(status) !== -1}
      >
        <div className={cx('loaderWrapper', { movedUp })}>
          <div className={cx('loader', { end: status === 'success' || status === 'error' })}>
            <Loader
              lastIcon={status === 'error' ? 'statusFail' : 'statusOk'}
              status={loaderStatuses[status] || 'ready'}
              timeout={500}
            />
          </div>
          <div className={cx('loaderText')} dangerouslySetInnerHTML={{ __html: loaderText }} />
        </div>
        {mobileAppBanner ?
          <div className={cx('mobileBanner', { movedUp })}>
            <MobileAppBanner
              {...mobileAppBanner}
              currentCtn={currentCtn}
              sendMessageUrl={sendMessageUrl}
            />
          </div>
        : <div className={cx('banner', { movedUp })}>
            <CatalogBanner {...advert} />
          </div>
        }
        <div className={cx('button', { shown: status === 'success' || status === 'error' })}>
          <Button className="big" onClick={this.handleCloseClick}>
            {status === 'success' ? 'Отправить еще SMS' : 'Закрыть окно'}
          </Button>
        </div>
      </PopupStateless>
    );
  }
}

Popup.propTypes = {
  status: PropTypes.string,
  ctn: PropTypes.string,
  advert: PropTypes.object,
  errorMessage: PropTypes.string,
  onClose: PropTypes.func,
  mobileAppBanner: PropTypes.object,
  currentCtn: PropTypes.number,
  sendMessageUrl: PropTypes.string,
};
