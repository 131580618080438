import React, { memo } from 'react';

// TODO: заменить импорт после выноса PresetPicker в пакет!
import { PresetPicker } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/PresetPicker';

import { useAppDispatch } from '../../../../../store';
import { useMinValueSelector } from './selectors/useMinSocSelector';
import { setMinutes } from '../../../../../store/slices/totalSlice';
import { WPContainer } from '../../../../commonComponents/WPContainer';
import { useMinPresetsSelector } from './selectors/useMinPresetsSelector';
import { sendPresetYm } from '../../tempYmUtils';

/** Гб/Мин для тарифа Up */
export const MinPresetContainer = memo(() => {
  const { minPresets } = useMinPresetsSelector();
  const { minValue } = useMinValueSelector();
  const dispatch = useAppDispatch();
  const onChange = (value: number) => {
    /* Отправка метрики */
    sendPresetYm(value, 'min');
    dispatch(setMinutes(value));
  };

  return (
    <WPContainer>
      <PresetPicker activeKey={minValue} {...{ options: minPresets, onChange }} />
    </WPContainer>
  );
});
