import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';
import { currentStateList } from 'pages/FTTB/constants';

import {
  RenderAbbreviatedPopup,
  RenderFail,
  RenderLoader,
  RenderPopup,
  RenderSuccess,
} from './components';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const RenderPopupFormsOld = memo(({ renderPopup, closePopup, isOpen, data }) => (
  <PopupStateless className={cx('popup')} onClose={closePopup} opened={isOpen}>
    {renderPopup === currentStateList.abbreviated && <RenderAbbreviatedPopup data={data} />}
    {renderPopup === currentStateList.none && <RenderPopup data={data} />}
    {renderPopup === currentStateList.success && <RenderSuccess texts={data.texts} />}
    {renderPopup === currentStateList.fail && <RenderFail texts={data.texts} />}
    {renderPopup === currentStateList.loading && <RenderLoader />}
  </PopupStateless>
));

RenderPopupFormsOld.propTypes = {
  data: PropTypes.object,
  renderPopup: PropTypes.string,
  isOpen: PropTypes.bool,
  closePopup: PropTypes.func,
};
