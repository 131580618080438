import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import axios from 'axios';
import {
  Button,
  Description,
  Descriptions,
  Emoji,
  Heading,
  Link,
  Preloader,
} from '@beef/legacy-ui-kit';

import TaxCalculator from 'pages-components/TaxCalculator';
import PopupStateless from 'pages-components/Popup';

import styles from './PopupTaxInfo.pcss';

const cx = classNames.bind(styles);

class PopupTaxInfo extends Component {
  state = {
    isOpenPopup: false,
    isLoading: true,
    error: false,
    data: {},
  };

  onRefresh = () => {
    this.setState({
      isLoading: true,
      error: false,
    });
    this.onLoadData();
  };

  onLoadData = () => {
    axios(this.props.taxUrl)
      .then(({ data }) => {
        this.setState({
          data,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          error: true,
          isLoading: false,
        });
      });
  };

  togglePopup = () => {
    this.onLoadData();
    this.setState(({ isOpenPopup }) => ({ isOpenPopup: !isOpenPopup }));
  };

  render() {
    const {
      isOpenPopup,
      isLoading,
      error,
      data: { items, title, button, calculator },
    } = this.state;
    return (
      <div className={cx('container')}>
        <img
          alt="Стопка монеток"
          className={cx('icon')}
          src="//static.beeline.ru/upload/images/b2c/icons/nalog.png"
        />
        <p className={cx('link')}>
          <Link onClick={this.togglePopup}>{this.props.taxNote}</Link>
        </p>
        <PopupStateless className={cx('popup')} onClose={this.togglePopup} opened={isOpenPopup}>
          {isLoading && <Preloader />}

          {!isLoading && !error && (
            <>
              <Heading className={cx('title')} level={1}>
                {title}
              </Heading>
              <TaxCalculator className={cx('calculator')} {...calculator} />
              <Descriptions className={cx('descriptions')}>
                {items &&
                  items.map(({ title: itemTitle, description }, i) => (
                    <Description key={i} title={itemTitle}>
                      {description}
                    </Description>
                  ))}
              </Descriptions>
              <Button onClick={this.togglePopup}>{button}</Button>
            </>
          )}

          {error && (
            <>
              <Emoji name="smile-dead" />
              <div>
                Что-то пошло не так.
                <br />
                Попробуйте <Link onClick={this.onRefresh}> обновить </Link>
                <br />
                через минуту
              </div>
            </>
          )}
        </PopupStateless>
      </div>
    );
  }
}

PopupTaxInfo.defaultProps = { taxNote: 'В стоимость включен НДС 20%' };

PopupTaxInfo.propTypes = {
  taxUrl: PropTypes.string,
  taxNote: PropTypes.string,
};

export default PopupTaxInfo;
