import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import style from './style.pcss';

const cx = classnames.bind(style);

class Offerta extends PureComponent {
  state = {
    showOfferta: false,
    showPdnAgreement: false,
  };

  switchOfferta = (type) => () => {
    if (type === 'offerta') {
      this.setState((prevState) => ({
        showOfferta: !prevState.showOfferta,
        showPdnAgreement: false,
      }));
    } else if (type === 'pdnAgreement') {
      this.setState((prevState) => ({
        showOfferta: false,
        showPdnAgreement: !prevState.showPdnAgreement,
      }));
    }
  };

  render() {
    const { offerta, pdnAgreement, conditionalsText } = this.props;
    const { preOffertaLink, offertaLink, prePdnAgreementLink, pdnAgreemnetLink } =
      conditionalsText || {};
    const { showOfferta, showPdnAgreement } = this.state;
    return offerta && pdnAgreement ?
        <div>
          <div className={cx('offerNotification')}>
            <span dangerouslySetInnerHTML={{ __html: preOffertaLink }} />
            <div
              className={cx('collapseLink', { active: showOfferta })}
              onClick={this.switchOfferta('offerta')}
              role="button"
              tabIndex="0"
            >
              <span
                className={cx('underscored')}
                dangerouslySetInnerHTML={{ __html: offertaLink }}
              />
              <Icon className={cx(showOfferta ? 'down' : 'up')} name="dropdownArrow" />
            </div>
            <span dangerouslySetInnerHTML={{ __html: prePdnAgreementLink }} />
            <div
              className={cx('collapseLink', { active: showPdnAgreement })}
              onClick={this.switchOfferta('pdnAgreement')}
              role="button"
              tabIndex="0"
            >
              <span
                className={cx('underscored')}
                dangerouslySetInnerHTML={{ __html: pdnAgreemnetLink }}
              />
              <Icon className={cx(showPdnAgreement ? 'down' : 'up')} name="dropdownArrow" />
            </div>
          </div>
          <div
            className={cx('collapsed', { show: showOfferta })}
            dangerouslySetInnerHTML={{ __html: offerta }}
          />
          <div
            className={cx('collapsed', { show: showPdnAgreement })}
            dangerouslySetInnerHTML={{ __html: pdnAgreement }}
          />
        </div>
      : null;
  }
}

Offerta.propTypes = {
  offerta: PropTypes.string,
  pdnAgreement: PropTypes.string,
  conditionalsText: PropTypes.object,
};

export default Offerta;
