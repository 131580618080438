import { handleSendAnalytics } from './main2021';

/** Отключение тарифа */
export const gaYandexTariffDisconnected = () => {
  handleSendAnalytics({
    event: 'GA_event',
    eventCategory: 'Online disconnection success',
    eventAction: 'Мобильная связь_Tariffs',
    eventLabel: 'Близкие люди с Яндекс Плюс',
  });
};

/** Успешная активация Яндекс Плюс */
export const gaYandexPlusConnected = () => {
  handleSendAnalytics({
    event: 'GA_event',
    eventCategory: 'Online connection success',
    eventAction: 'Мобильная связь_Services',
    eventLabel: 'Яндекс Плюс',
  });
};

/** Показ окна смены тарифа */
export const gaShowChangeTariffForm = (label) => {
  handleSendAnalytics({
    event: 'GA_event',
    eventCategory: 'Online connection',
    eventAction: 'Мобильная связь_Tariffs',
    eventLabel: label,
  });
};

/** Успешное отключение Яндекс Плюс */
export const gaYandexPlusDisconnected = () => {
  handleSendAnalytics({
    event: 'GA_event',
    eventCategory: 'Online disconnection success',
    eventAction: 'Мобильная связь_Services',
    eventLabel: 'Яндекс Плюс',
  });
};
