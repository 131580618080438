import React from 'react';
import PropTypes from 'prop-types';

import { quickOrderRequestUrl } from 'pages/FTTB/constants';

import PopupWithReCaptcha from '../PopupWithReCaptcha';

const QuickOrderPopup = (props) => {
  if (!props.texts || !props.featureEnabled) return null;

  const {
    texts,
    showCaptcha,
    onClose,
    isOpen,
    additionalData,
    onSuccess,

    gaEventLabel,
  } = props;

  return (
    <PopupWithReCaptcha
      additionalData={additionalData}
      gaEventLabel={gaEventLabel}
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={onSuccess}
      phoneFieldName="phoneNumber"
      showCaptcha={showCaptcha}
      submitUrl={quickOrderRequestUrl}
      texts={texts}
    />
  );
};

// QuickOrderPopup.defaultProps = {
//   texts: { ...stubData },
// };

QuickOrderPopup.propTypes = {
  texts: PropTypes.shape({}),
  showCaptcha: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  featureEnabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  additionalData: PropTypes.shape({}),

  gaEventLabel: PropTypes.string,
};

export default QuickOrderPopup;
