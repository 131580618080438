import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TPreviewTariff } from '../components/constructorPage/FmcTabsContainer/types';
import { apiGetPartnerFmc } from './constants';

/** Тип параметров запроса метода */
type TRequestParams = {
  alias: string;
};

/** Тип ответа метода */
export type TPartnerFmc = {
  fmcPartnerOnRegion: boolean;
  isSuccess?: boolean;
  tariffs?: TPreviewTariff[];
};

/** Запрос данных для настройки по выбранной скорости пресета ШПД на главной странице конструктора */
export const fetchPartnerFmcTariffs = createAsyncThunk<TPartnerFmc, TRequestParams>(
  'presetApi/fetchPartnerFmсPresets',
  async (params, { signal }) => {
    try {
      const response = (
        await axios.get(apiGetPartnerFmc, {
          params,
          signal,
        })
      ).data;

      if (!response?.isSuccess) {
        throw new Error();
      }

      if (!response?.fmcPartnerOnRegion || !response?.tariffs?.length) {
        throw new Error();
      }

      return response;
    } catch (e) {
      throw new Error();
    }
  },
);
