import { createReducer } from '@reduxjs/toolkit';

import { CATEGORY_ALIAS, RECALL_REQUEST_AFTER_CAPTCHA } from '../../constants';
import {
  loadFilteredChangeNumbersThunk,
  loadFilteredNumbersThunk,
  setCaptchaData,
  setCaptchaIsEnabled,
  setDefCodes,
  setError,
  setLoading,
  setNumbersInCart,
  setNumbersList,
  setRandomNumber,
  setReCallAfterCaptchaRequest,
} from '../actions/numbers';

export const numbers = createReducer(
  {
    lists: null,
    defCodes: null,
    randomNumber: null,
    numbersInCart: [],
    randomNumberCategory: CATEGORY_ALIAS.spetsialnyie,
    loading: true,
    error: null,
    isCaptchaEnabled: false,
    isCaptchaInvalid: false,
    isFetching: false,
  },
  {
    [setDefCodes.type]: (state, action) => {
      state.defCodes = action.payload;
    },
    [setNumbersList.type]: (state, action) => {
      state.lists = action.payload;
    },
    [setRandomNumber.type]: (state, action) => {
      state.randomNumber = action.payload;
    },
    [setLoading.type]: (state, action) => {
      state.loading = action.payload;
    },
    [setError.type]: (state, action) => {
      state.error = action.payload;
    },
    [setNumbersInCart.type]: (state, action) => {
      state.numbersInCart = action.payload;
    },
    [setCaptchaIsEnabled.type]: (state, action) => {
      state.isCaptchaEnabled = action.payload;
    },
    [setCaptchaData.type]: (state, action) => {
      state.captchaData = action.payload;
    },
    [setReCallAfterCaptchaRequest.type]: (state, action) => {
      state.reCallRequestName = action.payload;
    },
    [loadFilteredNumbersThunk.pending]: (state) => {
      state.loading = true;
      state.isFetching = true;
    },
    [loadFilteredNumbersThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = false;
      state.lists = action.payload;
      state.isCaptchaEnabled = false;
      state.isCaptchaInvalid = false;
      state.isFetching = false;
    },
    [loadFilteredNumbersThunk.rejected]: (state, action) => {
      state.error = true;
      state.loading = false;
      state.lists = {};
      // для Капчи
      state.isCaptchaEnabled = action?.payload?.request?.status === 429;
      state.isCaptchaInvalid = action?.payload?.request?.statusText.includes('bad_captcha');
      state.reCallRequestName = RECALL_REQUEST_AFTER_CAPTCHA.loadFilteredNumbersThunk;
      state.isFetching = false;
    },
    [loadFilteredChangeNumbersThunk.pending]: (state) => {
      state.loading = true;
    },
    [loadFilteredChangeNumbersThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = false;
      state.lists = action.payload;
    },
    [loadFilteredChangeNumbersThunk.rejected]: (state) => {
      state.error = true;
      state.loading = false;
      state.lists = {};
    },
  },
);
