import axios from 'axios';

import {
  SET_LOTTIE_ANIMATION,
  setDetailsForSpn,
  setLoadingModal,
  setStepModalSpn,
  setWaitingRequest,
} from 'pages/SPN/store/actions';
import { poll } from 'utils/timed-functions';
import store from 'store';

import { makeRequest } from './makeRequest';
import {
  CONNECTION_STATUS,
  DETAILS_STATUSES,
  DETAILS_STATUSES_TYPE,
  LOADER_CONTENT_STEPS,
  MODAL_STEPS_SPN,
} from '../store/constants';
import { openLoaderAndSetContent, showErrorTextAndValidation } from '../ModalsContainer/common';
import { selectIsModalOpen, selectLoaderData } from '../store/actions/selectors';

export const getDetailsForSpnAPI = async () =>
  makeRequest({
    url: '/customers/products/mobile/services/detailsforspn/',
    method: 'get',
    headers: {
      'content-type': 'application/json',
    },
  });

export const checkDetailsForSpnAPI = async () =>
  makeRequest({
    url: '/customers/products/mobile/services/checkconnectspnstatus/',
    method: 'get',
    headers: {
      'content-type': 'application/json',
    },
  });

const changeMobileSpnServiceAPI = (soc, spn = '') =>
  makeRequest({
    url: '/customers/products/mobile/services/changemobilespnservice/',
    method: 'post',
    headers: {
      'content-type': 'application/json',
    },
    params: { soc, spn },
  });

const connectMobileSpnServiceAPI = (soc, spn) =>
  makeRequest({
    url: '/customers/products/mobile/services/connectmobilespnservice/',
    method: 'post',
    headers: {
      'content-type': 'application/json',
    },
    params: { soc, spn },
  });

const deactivateMobileSpnServiceAPI = (soc, spn) =>
  makeRequest({
    url: '/customers/products/mobile/services/disconnectspnservice/',
    method: 'post',
    headers: {
      'content-type': 'application/json',
    },
    params: { soc, spn },
  });

// LottieAnimation
export const setLottieAnimation = (name) => (dispatch, getState) => {
  const { lottieData } = getState().external.spn;

  if (!lottieData?.[name]) {
    axios.get(`/customers/products/mobile/services/getanimation/${name}`).then(({ data }) => {
      dispatch({
        type: SET_LOTTIE_ANIMATION,
        payload: { [name]: data, name },
      });
    });
  }
};

// polling
const dataProcessingFunc = ({ data }) => {
  const { dispatch, getState } = store;
  const { status: mainStatus } = data;
  const { status, type } = mainStatus;
  const isModalOpen = selectIsModalOpen(getState());
  const isLoadingModal = getState()?.external?.spn?.isLoadingModal;

  // Если закрылась модалка, то остановить поллинг
  if (!isModalOpen) {
    return 'Stop polling';
  }

  // заканчивает лоадинг модалки
  if (isLoadingModal) {
    dispatch(setLoadingModal());
  }

  if (data && status === DETAILS_STATUSES.AVAILABLE) {
    /** если status(2) - inProgress и любой type(1-3) - activate, change or deactivate,
     * при успешном получении данных status(1) - available и не законченном поллинге,
     *  смотрим какой поп-ап с экраном показать
     * */
    if (type === DETAILS_STATUSES_TYPE.CONNECT) {
      dispatch(setStepModalSpn(MODAL_STEPS_SPN.SUCCESS_ACTIVATE));
    }

    if (type === DETAILS_STATUSES_TYPE.CHANGE) {
      dispatch(setStepModalSpn(MODAL_STEPS_SPN.SUCCESS_CHANGE));
    }

    if (type === DETAILS_STATUSES_TYPE.DISCONNECT) {
      dispatch(setStepModalSpn(MODAL_STEPS_SPN.SUCCESS_DEACTIVATE));
    }
    return DETAILS_STATUSES.AVAILABLE;
  }

  /** если status(2) - inProgress, выбираем нужные данные для лоадера */
  if (status === DETAILS_STATUSES.IN_PROGRESS) {
    if (type === DETAILS_STATUSES_TYPE.CONNECT) {
      dispatch(openLoaderAndSetContent(LOADER_CONTENT_STEPS.LOADER_ACTIVATE));
      return DETAILS_STATUSES.IN_PROGRESS;
    }

    if (type === DETAILS_STATUSES_TYPE.CHANGE) {
      dispatch(openLoaderAndSetContent(LOADER_CONTENT_STEPS.LOADER_CHANGENAME));
      return DETAILS_STATUSES.IN_PROGRESS;
    }

    if (type === DETAILS_STATUSES_TYPE.DISCONNECT) {
      dispatch(openLoaderAndSetContent(LOADER_CONTENT_STEPS.LOADER_DEACTIVATE));
      return DETAILS_STATUSES.IN_PROGRESS;
    }
  }

  if (status === DETAILS_STATUSES.ERROR) {
    dispatch(setStepModalSpn(MODAL_STEPS_SPN.TECH_ERROR));
    return DETAILS_STATUSES.ERROR;
  }

  return undefined;
};

export const getDetailsSpn = async () => {
  const { dispatch } = store;
  try {
    const { data } = await getDetailsForSpnAPI();
    dispatch(setLoadingModal());
    const { status: mainStatus } = data;
    const { status } = mainStatus;
    if (data && status === DETAILS_STATUSES.AVAILABLE) {
      dispatch(setDetailsForSpn(data));
    }
    if (status === DETAILS_STATUSES.ERROR) {
      dispatch(setStepModalSpn(MODAL_STEPS_SPN.TECH_ERROR));
    }
    if (status === DETAILS_STATUSES.IN_PROGRESS) {
      pollingCheckDetailsSpn();
    }
  } catch (e) {
    dispatch(setStepModalSpn(MODAL_STEPS_SPN.TECH_ERROR));
  }
};

export const pollingCheckDetailsSpn = () => {
  const { dispatch, getState } = store;
  const modalsLoader = selectLoaderData(getState());
  const { timeoutPollingTime, intervalPollingTime } = modalsLoader;
  dispatch(setWaitingRequest());

  const pollFn = poll(
    () => checkDetailsForSpnAPI(),
    (data) => dataProcessingFunc(data) === DETAILS_STATUSES.IN_PROGRESS,
    intervalPollingTime,
    timeoutPollingTime,
  );

  return pollFn
    .catch((error) => {
      console.error(`Error polling timeout: ${error}`);
      dispatch(setStepModalSpn(MODAL_STEPS_SPN.TIMEOUT_ERROR));
    })
    .finally(() => dispatch(setWaitingRequest()));
};

// ACTIVATE SPN
export const connectMobileSPNService = (soc, spn) => async (dispatch) => {
  dispatch(setWaitingRequest());
  try {
    const response = await connectMobileSpnServiceAPI(soc, spn);
    const { isSucceeded, view } = response.data;

    if (!isSucceeded) {
      if (view.connectionStatus === CONNECTION_STATUS.forbiddenname) {
        showErrorTextAndValidation();
        return;
      }

      dispatch(setStepModalSpn(MODAL_STEPS_SPN.TECH_ERROR));
      return;
    }

    if (isSucceeded) {
      if (view.connectionStatus === CONNECTION_STATUS.forbiddenname) {
        showErrorTextAndValidation();
        return;
      }

      if (view.connectionStatus === CONNECTION_STATUS.requestsent) {
        pollingCheckDetailsSpn();
        return;
      }

      if (view.connectionStatus === CONNECTION_STATUS.error) {
        dispatch(setStepModalSpn(MODAL_STEPS_SPN.TECH_ERROR));
        return;
      }

      await pollingCheckDetailsSpn();
    }
  } catch (error) {
    console.error(`Couldn't connect SPN: ${error}`);
  } finally {
    dispatch(setWaitingRequest());
  }
};

// CHANGE SPN
export const changeMobileSPNService = (soc, spn) => async (dispatch) => {
  dispatch(setWaitingRequest());
  try {
    const response = await changeMobileSpnServiceAPI(soc, spn);
    const { isSucceeded, view } = response.data;

    if (!isSucceeded) {
      if (view.connectionStatus === CONNECTION_STATUS.forbiddenname) {
        showErrorTextAndValidation();
        return;
      }

      dispatch(setStepModalSpn(MODAL_STEPS_SPN.TECH_ERROR));
      return;
    }

    if (isSucceeded) {
      if (view.connectionStatus === CONNECTION_STATUS.forbiddenname) {
        showErrorTextAndValidation();
        return;
      }

      if (view.connectionStatus === CONNECTION_STATUS.requestsent) {
        pollingCheckDetailsSpn();
        return;
      }

      await pollingCheckDetailsSpn();
    }
  } catch (error) {
    console.error(`Couldn't change SPN: ${error} `);
  } finally {
    dispatch(setWaitingRequest());
  }
};

// DEACTIVATE SPN
export const deactivateMobileSPNService = (soc, spn) => async (dispatch) => {
  dispatch(setWaitingRequest());
  try {
    const response = await deactivateMobileSpnServiceAPI(soc, spn);
    const { isSucceeded, view } = response.data;

    if (!isSucceeded) {
      dispatch(setStepModalSpn(MODAL_STEPS_SPN.TECH_ERROR));
      return;
    }

    if (isSucceeded) {
      if (view.connectionStatus === CONNECTION_STATUS.forbiddenname) {
        showErrorTextAndValidation();
        return;
      }

      if (view.connectionStatus === CONNECTION_STATUS.requestsent) {
        pollingCheckDetailsSpn();
        return;
      }

      await pollingCheckDetailsSpn();
    }
  } catch (error) {
    console.error(`Couldn't deactivate SPN: ${error}`);
  } finally {
    dispatch(setWaitingRequest());
  }
};

export const redirectToLogin = () => {
  const pathname = window?.location?.pathname;
  const origin = window?.location?.origin;
  if (pathname && origin) {
    window.open(`${origin}/login/?returnUrl=${encodeURI(pathname)}`, '_self');
    return undefined;
  }

  return undefined;
};
