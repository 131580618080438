import React, { memo, useState } from 'react';
import classNames from 'classnames/bind';
import { Switch } from '@carnica/ui-kit';

import { TMobileOptionsDO } from 'constructorV1/store/tariffSettingsSlice/types';

import { ModalContainer } from './components/ModalContainer';
import { PriceContainer } from './components/PriceContainer';
import { HeaderContainer } from './components/HeaderContainer';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Тип опций */
export type TOptionTile = {
  onChange: (serviceCategory: string) => void;
  pictures?: Array<{ image: string }>;
} & Omit<TMobileOptionsDO, 'pictures'>;

/** Компонент – плитка одной опции */
export const OptionTile = memo<TOptionTile>(
  ({
    rcRate,
    pictures,
    onChange,
    entityName,
    isTurnedOn,
    isUnbreakable = false,
    rcRatePeriod = ' ₽/мес',
    rcRateWithDiscount,
    serviceCategory = '',
    modalWindowInfo,
  }) => {
    /* Локальный state для модального окна опции */
    const [isOpen, setIsOpen] = useState(false);

    /** Проверка наличия модального окна опции */
    const isHasModal = typeof modalWindowInfo !== 'undefined';

    /** Обработчик модального окна (закрытие/закрытие) */
    const onClick = () => {
      if (!isHasModal) return;
      setIsOpen(!isOpen);
    };

    /** Обработчик кнопки модального окна (вкл/выкл опции и закрытие окна) */
    const handleClick = () => {
      onChange(serviceCategory);
      onClick();
    };

    return (
      <div className={cx('wrapper')}>
        <button
          className={cx('wrapper__button', 'wrapper--reset', {
            'wrapper--no-click': !isHasModal,
          })}
          onClick={onClick}
          type="button"
        >
          <HeaderContainer {...{ entityName, pictures }} />
          <PriceContainer {...{ rcRate, rcRatePeriod, rcRateWithDiscount }} />
        </button>
        <ModalContainer
          onChange={handleClick}
          {...{
            isOpen,
            isTurnedOn,
            pictures,
            entityName,
            onClick,
            isUnbreakable,
            ...modalWindowInfo,
          }}
        />
        {!isUnbreakable && (
          <div className={cx('wrapper__switch')}>
            <Switch checked={isTurnedOn} onChange={() => onChange(serviceCategory)} />
          </div>
        )}
      </div>
    );
  },
);
