import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'framer-motion';

import { useAppSelector } from 'constructorV1/store';
import { DEFAULT_INDEX } from 'constructorV1/constants';
import type { TBenefitDO } from 'constructorV1/store/commonSlice/types';
import { selectBenefitsSettings } from 'constructorV1/store/selectors/selectCommon';

export const useCardScroll = (cards: TBenefitDO[]) => {
  /** Текущие настройки автоматического пролистывания benefits */
  const { autoPlay, duration } = useAppSelector(selectBenefitsSettings) ?? {};
  /* Ссылка на контейнер с карточками */
  const refCards = useRef<HTMLUListElement | null>(null);
  /* Ссылка на карточку */
  const refCard = useRef<HTMLLIElement | null>(null);
  /* Флаг вернет true, если пользователь будет видеть элемент с карточками */
  const isInView = useInView(refCards);

  /* Локальный state для установления значения активной карточки, если значение isActive не задано, активной будет 1 карточка */
  const [activeCard, setActiveCard] = useState<TBenefitDO>(
    () => cards.find((benefit) => benefit.isActive) || cards[0],
  );

  /* Вычисляем значение индекса активной карточки  */
  const activeCardIndex = useMemo(() => cards.indexOf(activeCard), [cards, activeCard]);

  /* Осуществляем смещение контейнера с карточками на значение scrollOffset */
  const scroll = (index: number) => {
    refCards.current?.children[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  /* Осуществляем смещение контейнера с карточками по клику на карточку или пункт пагинации */
  const setNewActiveCard = (index: number) => {
    /* Осуществляем скролл к выбранной карточке */
    scroll(index);

    /* После скролла к новой активной карточки, устанавливаем значение activeCard */
    setActiveCard(cards[index]);
  };

  /* Устанавливаем первоначальный скролл до активной карточки */
  useEffect(() => {
    setNewActiveCard(activeCardIndex);
  }, []);

  useEffect(() => {
    /* Если autoPlay или duration равны false/0, выходим из функции */
    if (!autoPlay || !duration) {
      return () => {};
    }

    let timerId: NodeJS.Timeout | undefined;

    if (isInView) {
      /** Таймер автоматического пролистывания слайдера */
      timerId = setInterval(() => {
        const nextCardIndex = activeCardIndex + 1;

        /* Выставляем индекс активной карточки.
         * Если текущий индекс просматриваемой карточки (activeCardIndex) последний в массиве, возвращаемся к началу списка.
         * Иначе, переходим к следующей карточке */
        setNewActiveCard(activeCardIndex === cards.length - 1 ? DEFAULT_INDEX : nextCardIndex);
      }, duration);
    }

    return () => clearInterval(timerId);
  }, [cards, activeCardIndex, autoPlay, duration, isInView]);

  return {
    refCard,
    refCards,
    activeCard,
    activeCardIndex,
    point: activeCardIndex + 1,
    handelClick: setNewActiveCard,
  };
};
