import React, { RefObject } from 'react';
import classnames from 'classnames/bind';
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from '@beef/ui-kit';
import SwiperClass from 'swiper/types/swiper-class';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

type Props = {
  onClose?: () => void;
  slider: RefObject<SwiperClass | null>;
};

export const Controls = ({ onClose, slider }: Props) => {
  const isMobile = useIsMobileDimension();

  const handleBackwardClick = () => {
    slider.current?.slidePrev();
  };

  const handleForwardClick = () => {
    slider.current?.slideNext();
  };

  return (
    <>
      <button className={cx('closeButton')} onClick={onClose} type="button">
        <CloseIcon fill="#fff" size={24} />
      </button>
      <button className={cx('switcher', 'backward')} onClick={handleBackwardClick} type="button">
        <ChevronLeftIcon fill="#727281" size={isMobile ? 16 : 24} />
      </button>
      <button className={cx('switcher', 'forward')} onClick={handleForwardClick} type="button">
        <ChevronRightIcon fill="#727281" size={isMobile ? 16 : 24} />
      </button>
    </>
  );
};
