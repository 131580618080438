import React, { FC } from 'react';
import { AppBanner, PageFixer, AppBannerPropsType as TCommonAppBanner } from '@beef/smart-kit';

import { useAppBannerSpace } from './hooks/useAppBannerSpace';

/** Общий компонент-баннера для тарифов.
 * Содержит заголовок, описание, ссылки на приложения и QR.
 * Реализован на базе китового компонента AppBanner */
export const CommonAppBanner: FC<TCommonAppBanner> = ({ ...props }) => {
  const innerVerticalSpace = useAppBannerSpace();
  return (
    <PageFixer size="m" {...{ innerVerticalSpace }}>
      <AppBanner {...props} />
    </PageFixer>
  );
};
