import classNames from 'classnames/bind';
import React, { useContext } from 'react';

// Utils
import { sendAnimalsTabsYaAnalytics } from 'pages/ProductCard/utils';

// Components
import { Badge } from 'pages/ProductCard/Tariff/components/CharactersTabs/Badge';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

// Styles
import { ConstructorContext } from 'pages/ProductCard/Tariff/containers/ConstructorTariff/constants';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TTabProps = {
  activeColor: string;
  activeOptionSoc: string;
  discount: string;
  highSoc: string;
  imageSrc: string;
  isChangeTariff: boolean;
  lowSoc: string;
  name: string;
  onClick: (soc: string) => void;
  optionSoc: string;
};

const Tab: React.FC<Partial<TTabProps>> = ({
  name,
  optionSoc,
  imageSrc,
  activeColor,
  discount,
  isChangeTariff,
  activeOptionSoc,
  onClick,
  highSoc,
  lowSoc,
}) => {
  const isMobile = useIsMobileDimension();
  const imgSize = isMobile ? '84px' : '106px';
  const isActive = [highSoc, lowSoc].includes(activeOptionSoc);
  const backgroundColor = isActive ? activeColor : undefined;
  const { isHighPreset } = useContext(ConstructorContext);
  const currentSoc = isHighPreset ? highSoc : lowSoc;

  const handleClick = () => {
    if (onClick && optionSoc) {
      onClick(currentSoc as string);
    }
    sendAnimalsTabsYaAnalytics({ name });
  };

  return (
    <div
      className={cx('tab', { 'tab--active': isActive })}
      onClick={handleClick}
      style={{ ...(backgroundColor && { backgroundColor }) }}
    >
      <img
        alt="character-img"
        className={cx('tab__img')}
        height={imgSize}
        loading="lazy"
        src={imageSrc}
        width={imgSize}
      />
      {discount && isActive && isChangeTariff && (
        <Badge activeColor={activeColor} discount={discount} />
      )}
    </div>
  );
};

export { Tab };
