import { createReducer } from '@reduxjs/toolkit';

import { setLoading } from '../actions/cart';

export const cart = createReducer(
  {
    loadingId: '',
  },
  {
    [setLoading.type]: (state, action) => {
      state.loadingId = action.payload;
    },
  },
);
