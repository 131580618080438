/**
 * TODO: Удалить весь блок? Вероятно никто не использовал его с 2019
 */

import { getSubstateFromGetState } from '@beef/legacy-beeline-store/utils';
import { path, pathOr } from 'ramda';

import store from 'store';
import {
  SET_ALWAYS_IN_POSITIVE_VALUE,
  changeLimitConfirmation,
} from 'pages/ProductCard/actions/alwaysInPositive';
import {
  pushServiceDisconnectionErrorEvent,
  pushServiceDisconnectionSuccessEvent,
  pushShowServiceOffEvent,
  pushUpsellConfirmationEvent,
  pushUpsellShowPopupEvent,
} from 'utils/ga';

import { disconnectServiceRequest } from './serviceConnect';
import { resetPrice, tariffData, toggleService, updatePrice } from './servicesExtension';

export const ALWAYS_IN_POSITIVE_SERVICE_NAME = 'personalCreditLimit';

export const personalCreditLimit = store.createAction({
  path: ['tariff', 'data', 'personalCreditLimit'],
  defaultState: {},
  dispatches: ['update'],
  name: 'tariff_personal_credit_limit',
});

export const credit = store.createAction({
  path: ['tariff', 'data', 'personalCreditLimit', 'card', 'creditAmount'],
  defaultState: {},
  dispatches: ['update'],
  name: 'tariff_personal_credit',
});

export const deposit = store.createAction({
  path: ['tariff', 'data', 'personalCreditLimit', 'card', 'deposit'],
  defaultState: {},
  dispatches: ['update'],
  name: 'tariff_personal_deposit',
});

export const settingsPopup = store.createAction({
  path: ['tariff', 'data', 'popups', 'settings'],
  defaultState: { opened: false },
  dispatches: ['set', 'update'],
  name: 'tariff_settings_popup',
});

export const disconnectConfirmPopup = store.createAction({
  path: ['tariff', 'data', 'personalCreditLimit', 'disconnectConfirmationPopup'],
  defaultState: { opened: false },
  dispatches: ['set', 'update'],
  name: 'tariff_confirm_disconnect_popup',
});

export const toggleConnected = (value) => (dispatch, getState) => {
  const creditInfo = getSubstateFromGetState(getState, personalCreditLimit);
  const newValue = typeof value === 'undefined' ? !creditInfo.banner.isConnected : value;
  dispatch(
    personalCreditLimit.update({
      ...creditInfo,
      isConnecting: false,
      banner: {
        ...creditInfo.banner,
        isConnected: newValue,
      },
    }),
  );
  dispatch(toggleService(ALWAYS_IN_POSITIVE_SERVICE_NAME, 'CONNECTED'));
};

export const closeAllPopups = () => (dispatch) => {
  dispatch(settingsPopup.update({ opened: false }));
  dispatch(disconnectConfirmPopup.update({ opened: false }));
};

export const disconnectService = (disconnectDate, options) => (dispatch, getState) => {
  const tariff = getSubstateFromGetState(getState, tariffData);
  const pclData = getSubstateFromGetState(getState, personalCreditLimit);
  const { soc, serviceName, marketCode } = pclData.banner;

  dispatch(closeAllPopups());
  dispatch({
    type: SET_ALWAYS_IN_POSITIVE_VALUE,
    payload: {
      popupData: {
        step: 'pending',
        isSuccess: true,
      },
    },
  });
  disconnectServiceRequest({
    url: pathOr('', ['extData', 'disconnectServiceUrl'], tariff),
    soc,
    serviceName,
    disconnectDate: disconnectDate.toISOString(),
  })
    .then(({ data }) => {
      if (!data || !data.view) {
        return Promise.reject();
      }

      dispatch({
        type: SET_ALWAYS_IN_POSITIVE_VALUE,
        payload: {
          popupData: {
            ...data.view,
            step: data.isSucceeded ? 'success_disconnect' : 'error',
          },
        },
      });
      if (data.isSucceeded) {
        dispatch(toggleConnected(false));
        pushServiceDisconnectionSuccessEvent({ ...options, soc, serviceName, marketCode });
      }
      return Promise.resolve();
    })
    .catch(({ response }) => {
      const viewData =
        response && response.view ? response.view : pathOr({}, ['errorPopup'], pclData);

      dispatch({
        type: SET_ALWAYS_IN_POSITIVE_VALUE,
        payload: {
          popupData: {
            ...viewData,
            step: 'error',
          },
        },
      });
      pushServiceDisconnectionErrorEvent({
        error: viewData.content,
        ...options,
        soc,
        serviceName,
        marketCode,
      });
    });
};

export const changeLimit = (value) => (dispatch, getState) => {
  const creditData = getSubstateFromGetState(getState, credit);
  const pclData = getSubstateFromGetState(getState, personalCreditLimit);

  const { initValue } = creditData;
  dispatch(
    credit.update({
      initValue: typeof initValue === 'undefined' ? creditData.currentValue : creditData.initValue,
      currentValue: value,
    }),
  );
  dispatch(deposit.update({ amount: value }));
  dispatch(updatePrice(ALWAYS_IN_POSITIVE_SERVICE_NAME, `${value}${creditData.currency}`));
  dispatch({
    type: SET_ALWAYS_IN_POSITIVE_VALUE,
    payload: { amount: value },
  });
  if (initValue !== value) {
    dispatch(
      personalCreditLimit.update({
        isConnecting: true,
      }),
    );
  } else if (pclData.card.isConnectingInProgress || pclData.banner.isConnected) {
    dispatch(
      personalCreditLimit.update({
        isConnecting: null,
      }),
    );
  }
};

export const toggleConnecting = (value) => (dispatch, getState) => {
  const { isAuthenticated, extData } = getSubstateFromGetState(getState, tariffData);
  const { serviceName, soc, marketCode } = path(['personalCreditLimit', 'banner'], extData);

  if (isAuthenticated) {
    const creditInfo = getSubstateFromGetState(getState, personalCreditLimit);
    const creditData = getSubstateFromGetState(getState, credit);
    let newValue = false;
    if (typeof creditInfo.isConnecting === 'undefined') {
      newValue =
        typeof value === 'undefined' ?
          !creditInfo.banner.isConnected && !creditInfo.card.isConnectingInProgress
        : value;
    } else {
      newValue = typeof value === 'undefined' ? !creditInfo.isConnecting : value;
    }
    if (!newValue && (creditInfo.banner.isConnected || creditInfo.card.isConnectingInProgress)) {
      dispatch(closeAllPopups());
      dispatch(disconnectConfirmPopup.update({ opened: true }));
    } else {
      const isConnecting = newValue;
      dispatch(
        personalCreditLimit.update({
          ...creditInfo,
          isConnecting,
        }),
      );

      dispatch(
        toggleService(
          ALWAYS_IN_POSITIVE_SERVICE_NAME,
          newValue || isConnecting ? 'IN_PROGRESS' : 'NOT_CONNECTED',
        ),
      );

      if (newValue && !creditInfo.banner.isConnected) {
        dispatch(changeLimit(creditData.currentValue || creditInfo.banner.creditAmountValue));

        pushUpsellConfirmationEvent({ serviceName, soc, marketCode });
      }
      if (!newValue) {
        // Сбрасываем состояние до изначального
        dispatch(resetPrice(ALWAYS_IN_POSITIVE_SERVICE_NAME));
        dispatch(deposit.update({ amount: undefined }));
        dispatch(credit.update({ currentValue: creditData.initValue }));
      }
    }
  }
};

export const showConnectingPopup = () => (dispatch, getState) => {
  const { isAuthenticated, extData } = getSubstateFromGetState(getState, tariffData);
  const { serviceName, soc, marketCode } = path(['personalCreditLimit', 'banner'], extData);
  dispatch(settingsPopup.update({ opened: true }));
  pushUpsellShowPopupEvent({ isAuthenticated, serviceName, soc, marketCode });
};

export const toggleConnectingWithPopup = () => (dispatch, getState) => {
  const { isAuthenticated } = getSubstateFromGetState(getState, tariffData);

  if (isAuthenticated) {
    const creditInfo = getSubstateFromGetState(getState, personalCreditLimit);

    if (
      (creditInfo.banner.isConnected || creditInfo.card.isConnectingInProgress) &&
      !creditInfo.isConnecting
    ) {
      dispatch(disconnectConfirmPopup.update({ opened: true }));
      const { serviceName, soc, marketCode } = creditInfo.banner;
      pushShowServiceOffEvent({ serviceName, soc, marketCode });
    } else {
      const isActive =
        typeof creditInfo.isConnecting === 'undefined' ?
          creditInfo.banner.isConnected
        : creditInfo.isConnecting;

      dispatch(toggleConnecting());
      if (!isActive) {
        dispatch(settingsPopup.update({ opened: true }));
      }
    }
  } else {
    dispatch(showConnectingPopup());
  }
};

export const confirmLimit = () => (dispatch) => {
  dispatch(closeAllPopups());
  dispatch(changeLimitConfirmation());
};

export const initConfirmLimitPopup = (data) => (dispatch) => {
  dispatch({
    type: SET_ALWAYS_IN_POSITIVE_VALUE,
    payload: {
      amount: +pathOr(0, ['card', 'creditAmount', 'currentValue'], data),
      isReturn: path(['card', 'deposit', 'isReturn'], data),
      changeLimitUrl: pathOr('', ['card', 'creditAmount', 'changeLimitUrl'], data),
      confirmationPopupData: pathOr('', ['card', 'confirmationPopup'], data),
      errorPopupData: data.errorPopup,
      soc: data.banner.soc,
      serviceName: data.banner.serviceName,
      marketCode: data.banner.marketCode,
    },
  });
};
