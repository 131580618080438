import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { FontSizeType } from '@beef/ui-kit';

import { usePrepareTariffOptionsGroup } from './hooks/usePrepareTariffOptionsGroup';
import { OptionPickerContainer } from './components/OptionPickerContainer';
import { TitleContent } from '../../../../commonComponents/TitleContent';
import { TTariffOptionsGroup } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент группы опций */
export const TariffOptionsGroup: FC<TTariffOptionsGroup> = ({
  index,
  title = '',
  description = '',
  options,
  parentOptions,
  isBigFontSize = false,
}) => {
  /** Объект props для OptionPickerContainer.
   * Содержит текст для элемента подсказки и функцию-обработчик клика */
  const settings = usePrepareTariffOptionsGroup(index);

  /** Наличие родительских или дочерних опций, доступных пользователю */
  const hasOptions = !!parentOptions?.length || !!options?.length;

  /** Подготовка пропов для компонента TitleContent.
   * Если isBigFontSize = true, то увеличиваем размер title (используется в общем тарифе би) */
  const titleContentProps = {
    title,
    description,
    extraSize: 'size5-r-s' as FontSizeType,
    size: (isBigFontSize ? 'size2-m' : 'size4-m') as FontSizeType,
  };

  return hasOptions ?
      <div className={cx('wrapper')}>
        <TitleContent {...titleContentProps} />
        {/* Родительские опции */}
        <OptionPickerContainer {...settings} options={parentOptions} />
        {/* Дочерние опции */}
        <OptionPickerContainer {...settings} options={options} />
      </div>
    : null;
};
