import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';

type TDevicesTitle = {
  title?: string;
};

/** Компонент заголовка для модального окна описания рекомендуемых устройств */
export const DevicesTitle: FC<TDevicesTitle> = ({ title }) =>
  title ? <Text size="size2-m">{title}</Text> : null;
