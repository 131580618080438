import React from 'react';
import { connect } from 'react-redux';

import { GenericInput } from './GenericInput/index';
import { getFilterPlaceholder } from '../../../store/selectors/content';
import { FILTER_TAIL } from '../../../constants';
import { FilterInputShape } from '../../../shapes';

export const TailInputLayout = ({ query, onQueryChange, placeholder }) => (
  <GenericInput
    mask={{
      mask: '',
      format: '####',
    }}
    onChange={onQueryChange}
    placeholder={placeholder}
    value={query}
    withIcon
  />
);

TailInputLayout.propTypes = FilterInputShape;

export const TailInput = connect((state) => ({
  placeholder: getFilterPlaceholder(FILTER_TAIL, state),
}))(TailInputLayout);
