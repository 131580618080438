import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SuccessFlatConnection = ({
  textPrefix,
  textPostfix,
  linkText,
  className: wrapperClassName,
  onLinkClick,
}) => (
  <div className={cx('wrapper', wrapperClassName)}>
    <Icon className={cx('icon')} name="checkedThin" />
    <div className={cx('content')}>
      <span dangerouslySetInnerHTML={{ __html: textPrefix }} />
      <Link className={['hideVisitedLink', cx('link')]} onClick={onLinkClick}>
        <span dangerouslySetInnerHTML={{ __html: linkText }} />
      </Link>
      <span dangerouslySetInnerHTML={{ __html: textPostfix }} />
    </div>
  </div>
);

SuccessFlatConnection.propTypes = {
  textPrefix: PropTypes.string,
  textPostfix: PropTypes.string,
  linkText: PropTypes.string,
  className: PropTypes.string,
  onLinkClick: PropTypes.func,
};

export default SuccessFlatConnection;
