import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames/bind';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import { FullView } from './FullView';
import { PreviewSlide } from './PreviewSlide';
import type { Image } from './types';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

type Props = {
  images: Image[];
};

export const Gallery = ({ images }: Props) => {
  const [viewIndex, setViewIndex] = useState<null | number>(null);
  const isMobile = useIsMobileDimension();

  const handleSlideClick = (index: number) => {
    setViewIndex(index);
  };

  return (
    <>
      <Swiper
        centeredSlides
        className={cx('gallery')}
        draggable
        loop
        slidesPerView="auto"
        spaceBetween={isMobile ? 12 : 24}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <PreviewSlide
              alt={image.alt || ''}
              onClick={() => handleSlideClick(index)}
              src={image.preview || image.src}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <FullView
        images={images}
        initialSlide={viewIndex || 0}
        isOpen={viewIndex !== null}
        onClose={() => setViewIndex(null)}
      />
    </>
  );
};

export { Image };
