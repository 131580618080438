import { TInput, selectYandexSubscription } from '../selectors';
import { TariffUpMetaData } from '../store/TariffUpMetaData';
import { TariffUpModals } from '../store/TariffUpModals';
import { EYandexPopupType } from '../types';

export const onCancel = () => {
  TariffUpModals.IsOpen = false;
  TariffUpModals.YandexPopupType = EYandexPopupType.Error;
};

type TUpdateYandexSubscriptionArgs = {
  isConnected: boolean;
  isSubscriptionStatus: boolean;
  yandexAssigned: boolean;
};

/**
 * Апдейтит отображение карточки с Яндексом после активации
 * (С "активировать" на "подключите семью")
 */
export const updateYandexSubscrption = ({
  isConnected = false,
  isSubscriptionStatus = false,
  yandexAssigned = false,
}: TUpdateYandexSubscriptionArgs) => {
  const result = [TariffUpMetaData.Products] as unknown as TInput;
  const yandexSubscription = selectYandexSubscription(result);
  const subscriptions = result[0];

  const productList = subscriptions?.productList?.map((product) => {
    if (product?.id !== yandexSubscription?.id) {
      return product;
    }

    return {
      ...yandexSubscription,
      isConnected,
      isSubscriptionStatus,
      yandexAssigned,
    };
  });

  TariffUpMetaData.Products = { ...subscriptions, productList };
};
