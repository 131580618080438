import { createSelector } from '@reduxjs/toolkit';

import { selectContent } from '../selectors';

const selectStories = (state) => state.stories;

export const selectStoriesLoading = (state) => selectStories(state).loading;
export const selectCurrentRegionRating = (state) => selectStories(state).currentRegionRating;
export const selectCurrentRegionList = (state) => selectStories(state).currentRegionList;
export const selectStoriesList = (state) => selectStories(state).list;
export const selectCurrentStoriesList = createSelector(
  selectCurrentRegionList,
  selectStoriesList,
  (currentRegion, list) => list.find((item) => item.region === currentRegion)?.stories || [],
);
export const selectRegionsList = (state) =>
  selectStoriesList(state).map((item) => ({
    id: item.region,
    title: item.region,
  }));
export const selectCurrentStoriesRating = createSelector(
  selectCurrentRegionRating,
  selectStoriesList,
  selectContent,
  (currentRegion, regions, { rating }) => {
    const stories = regions.find((item) => item.region === currentRegion)?.stories || [];
    return [...stories]
      .sort((a, b) => b.votesPercentage - a.votesPercentage)
      .slice(0, rating.previewCount || 5);
  },
);
