import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Price } from 'pages/SearchResultsPage/components/Filter/Price';
import { PriceWithAnalytics } from 'pages/SearchResultsPage/components/Filter/Price/PriceWithAnalytics';
import { FiltersMapper } from 'pages/SearchResultsPage/components/Filter/FiltersMapper';
import { MobileCategories } from 'pages/SearchResultsPage/components/Filter/Categories/MobileCategories';
import { getOpenedFilterType } from 'pages/SearchResultsPage/store/selectors';

export const _Content = ({ openedFilterType }) =>
  openedFilterType ?
    <FiltersMapper name={openedFilterType} withoutWrapper />
  : <>
      <MobileCategories />
      <PriceWithAnalytics>
        <Price />
      </PriceWithAnalytics>
      <FiltersMapper />
    </>;

_Content.propTypes = {
  openedFilterType: PropTypes.string,
};

export const Content = connect((state) => ({
  openedFilterType: getOpenedFilterType(state),
}))(_Content);
