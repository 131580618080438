import React from 'react';
import classNames from 'classnames/bind';
import { Switch, Text } from '@beef/ui-kit';

import styles from './styles.pcss';
import { SwitchBlockType } from './types';

const cx = classNames.bind(styles);

const SwitchBlock = ({
  name,
  checkedOption,
  disabledOption,
  onChange,
  currencyDiscountSum,
  currencySum,
  showPriceWithoutDiscount,
}: SwitchBlockType) => (
  <div className={cx('container')}>
    <Switch
      checked={checkedOption}
      disabled={disabledOption}
      name={name}
      onChange={onChange}
      testName="Option_switch"
    />
    <div className={cx('container__price')}>
      <Text color="grey50" size="size5-r-s">
        {currencyDiscountSum}
      </Text>
    </div>
    <div>
      {showPriceWithoutDiscount && (
        <Text color="grey40" isCrossedOut size="size5-r-s">
          {currencySum}
        </Text>
      )}
    </div>
  </div>
);

export { SwitchBlock };
