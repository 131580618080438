import { cloneElement, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const CAPTCHA_STATUS = {
  success: 'success',
  fail: 'fail',
};

const CaptchaWithCallbackPopupHOC = ({ showCaptcha, children, currentCtn, data, ...rest }) => {
  const [phoneInputValue, setPhoneInputValue] = useState(currentCtn || '');
  const [captcha, setCaptcha] = useState(showCaptcha);
  const [captchaStatus, setCaptchaStatus] = useState(CAPTCHA_STATUS.success);
  const [captchaMsg, setCaptchaMsg] = useState('');
  const [refreshCaptcha, setRefreshCaptcha] = useState(0);
  const [captchaToken, setCaptchaToken] = useState('');
  const [captchaKey, setCaptchaKey] = useState('');

  const changePhone = (value) => {
    setPhoneInputValue(value);
  };

  const changeCaptchaState = (value) => {
    setCaptcha(value);
    setCaptchaToken('');
    setCaptchaMsg(data?.texts.captchaTexts.errorMsg);
  };

  const changeCaptchaToken = (value) => {
    setCaptchaToken(value);
  };

  return cloneElement(children, {
    reCaptchaModel: {
      onChange: changeCaptchaToken,
      refreshCaptcha,
      setCaptchaKey,
      setRefreshCaptcha,
    },
    captchaStatus,
    changeCaptchaStatus: setCaptchaStatus,
    captchaMsg,
    changeCaptchaMsg: setCaptchaMsg,
    captchaKey,
    showCaptcha: captcha,
    captchaToken,
    changeCaptchaToken: setCaptchaToken,
    changeCaptchaState,

    changePhone,
    phoneInputValue,
    data,

    ...rest,
  });
};

CaptchaWithCallbackPopupHOC.propTypes = {
  showCaptcha: PropTypes.bool,
  activeCtn: PropTypes.string,
};

const ConnectedReCaptchaAndPhoneHOC = connect(({ external }) => ({
  locale: external.locale || 'ru',
}))(CaptchaWithCallbackPopupHOC);

export { ConnectedReCaptchaAndPhoneHOC as CaptchaWithCallbackPopupHOC };
