import React from 'react';
import classNames from 'classnames/bind';

// PropTypes
import { subDescriptionShape } from '../../PropTypes';

// Components
import { BottomSideItem } from './BottomSideItem';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const BottomSide = ({ subDescription }) => (
  <ul className={cx('wrapper')}>
    {subDescription?.map(({ title, description }) => (
      <BottomSideItem key={title} {...{ title, description }} />
    ))}
  </ul>
);

BottomSide.propTypes = {
  subDescription: subDescriptionShape,
};
