import { createSelector } from '@reduxjs/toolkit';

import { selectMetaData } from '../selectors';

const selectStories = (state) => state.finalStories;

export const selectFinalStoriesList = (state) => selectStories(state).list;
export const selectFinalStoriesLoading = (state) => selectStories(state).loading;
export const selectCurrentFinalStoryId = (state) => selectStories(state).currentStory;
export const selectCurrentFinalVideoId = (state) => selectStories(state).currentVideo;
export const selectFinalVoteLoading = (state) => selectStories(state).isLoading;
export const selectFinalVoteSubmitted = (state) => selectStories(state).voteSubmitted;
export const selectIsVoteAvailable = createSelector(
  selectMetaData,
  selectFinalVoteSubmitted,
  ({ isUserVoteAvailable, isAuthorized }, isVoteSubmitted) =>
    !isAuthorized || (isUserVoteAvailable && !isVoteSubmitted),
);

const findStoryById = (list, id) => list.find((story) => story.id === id);

export const selectCurrentFinalStory = createSelector(
  selectFinalStoriesList,
  selectCurrentFinalStoryId,
  findStoryById,
);

export const selectCurrentFinalVideo = createSelector(
  selectFinalStoriesList,
  selectCurrentFinalVideoId,
  findStoryById,
);

export const selectFinalStoriesListSorted = createSelector(selectFinalStoriesList, (list) =>
  [...list].sort((a, b) => b.votesPercentage - a.votesPercentage),
);
