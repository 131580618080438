import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ContentLiner = React.forwardRef(({ children, className, containerRef }) => (
  <div className={cx('container', className)} ref={containerRef}>
    <div className={cx('content')}>{children}</div>
  </div>
));

ContentLiner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
