import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { ChevronUpIcon, Text } from '@beef/ui-kit';

import { windowResizeHandler } from 'pages/Maps/utils';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const BaseStationList = ({ points, title, onClick }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => windowResizeHandler(() => {}, '.react-container'), []);

  const handleToggle = () => {
    setOpen((currentState) => !currentState);
  };

  return (
    <div className={cx('wrap', { open })}>
      <button className={cx('title')} onClick={handleToggle} type="button">
        <Text size="size4-m">
          {points.length}
          {title}
        </Text>
        <ChevronUpIcon size={24} />
      </button>
      <div className={cx('list')}>
        {points.map((point, index) => (
          <button className={cx('point')} key={index} onClick={() => onClick(point)} type="button">
            <Text size="size4-r">{point.name}</Text>
          </button>
        ))}
      </div>
    </div>
  );
};
