import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { InfoCard } from '@beef/smart-kit';

import { ContentType } from 'pages/PromotionsPage/types';
import { withElementInView } from 'utils/hocs/withElementInView';
import { ANALYTICS } from 'pages/PromotionsPage/analytics';

import { CardLink } from './CardLink';
import { CardSection } from './CardSection';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

type CardType = ContentType & {
  alias: string;
  className?: string;
  defaultImg?: string;
  elementRef?: React.RefObject<HTMLAnchorElement>;
  isArchive?: boolean;
  isPage?: boolean;
};

export const _Card = ({
  elementRef,
  isPage,
  alias,
  tileImage,
  link,
  title,
  preview,
  intervalText,
  specialConditions,
  className,
  defaultImg,
  isArchive,
}: CardType) => (
  <CardLink
    className={cn(className || 'card')}
    elementRef={elementRef}
    href={link}
    onClick={() => ANALYTICS.PROMOTION_CLICK.sendParams({ block: alias, title, link, isPage })}
    withHover
  >
    {isArchive ?
      <InfoCard text={preview} title={title} />
    : <>
        <CardSection side="top">
          <img alt="" className={cn('card__image')} src={tileImage || defaultImg} />
        </CardSection>
        <CardSection side="bottom">
          <div className={cn('card__text')}>
            <Text className={cn('card__title')} size="size4-m">
              {title}
            </Text>
            <Text color="grey60" size="size5-r-l">
              {preview}
            </Text>
          </div>
          <Text color="grey60" size="size6-r">
            {intervalText}
          </Text>
          <Text color="grey60" size="size6-r">
            {specialConditions}
          </Text>
        </CardSection>
      </>
    }
  </CardLink>
);

export const Card = withElementInView(_Card)(({ alias: block, title, link, isPage }: CardType) => {
  ANALYTICS.PROMOTION_VIEW.sendParams({ block, title, link, isPage });
});
