import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { CollapsedContent, Link, Tab, TabContent, Tabs, TabsList } from '@beef/legacy-ui-kit';

import PopupStateless from 'pages-components/Popup';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class HowToWatchPopup extends Component {
  state = { currentTab: 'mobile' };

  handleTabClick = (tabAlias) => this.setState({ currentTab: tabAlias });

  renderMobileTab() {
    const { textData } = this.props;

    return (
      <div className={cx('tabWrapper')}>
        <div className={cx('content')}>
          <span dangerouslySetInnerHTML={{ __html: textData.mobileTabText }} />
        </div>
        <div className={cx('downloadAppWrapper')}>
          {textData.apps.map((app) => (
            <a
              className={cx('downloadAppLink')}
              href={app.url}
              key={app.alias}
              rel="noreferrer"
              target="_blank"
            >
              <img alt="" src={app.imageUrl} />
            </a>
          ))}
        </div>
      </div>
    );
  }

  renderSmatrTvTab() {
    const {
      textData: { smartTvTab },
    } = this.props;

    return (
      <div className={cx('tabWrapper')}>
        <div className={cx('content')}>
          {smartTvTab.smartTv.map((item) => (
            <CollapsedContent key={item.alias} title={item.title}>
              <span className={cx('smartTvText')} dangerouslySetInnerHTML={{ __html: item.text }} />
            </CollapsedContent>
          ))}
        </div>
      </div>
    );
  }

  renderTvConsoleTab() {
    const {
      textData: { tvConsoleTab },
    } = this.props;

    return (
      <div className={cx('tabWrapper')}>
        <div className={cx('content')}>
          <div className={cx('tvConsoleImageWrap')}>
            <img alt="" className={cx('tvConsoleImage')} src={tvConsoleTab.tvConsoleImageUrl} />
          </div>
          <ul className={cx('tvConsoleFeatureList')}>
            {tvConsoleTab.tvConsoleFeatures.map((feature, i) => (
              <li key={i}>
                <div
                  className={cx('tvConsoleFeatureCnt')}
                  dangerouslySetInnerHTML={{ __html: feature }}
                />
              </li>
            ))}
            <li className={cx('tvConsoleFeatureFullInfo')}>
              <Link href={tvConsoleTab.fullInfoUrl} target="_blank">
                <span dangerouslySetInnerHTML={{ __html: tvConsoleTab.fullInfoText }} />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const { opened, onClose, textData } = this.props;
    const { currentTab } = this.state;

    return (
      <PopupStateless className={cx('popup')} onClose={onClose} opened={opened}>
        <div className={cx('howToWatch')}>
          <div className={cx('howToWatchTitle')}>
            <span dangerouslySetInnerHTML={{ __html: textData.title }} />
          </div>

          <div className={cx('container')}>
            <Tabs>
              <TabsList
                activeTab={currentTab}
                className="tabList"
                handleTabClick={this.handleTabClick}
                type="calendar"
              >
                <Tab className={cx('tabItem')} id="mobile">
                  <span
                    className={cx('tabTitle')}
                    dangerouslySetInnerHTML={{ __html: textData.mobileTabTitle }}
                  />
                </Tab>
                <Tab className={cx('tabItem')} id="smart-tv">
                  <span
                    className={cx('tabTitle')}
                    dangerouslySetInnerHTML={{ __html: textData.smartTvTabTitle }}
                  />
                </Tab>
                <Tab className={cx('tabItem')} id="tv-console">
                  <span
                    className={cx('tabTitle')}
                    dangerouslySetInnerHTML={{ __html: textData.tvConsoleTabTitle }}
                  />
                </Tab>
              </TabsList>

              <TabContent id="mobile">{this.renderMobileTab()}</TabContent>
              <TabContent id="smart-tv">{this.renderSmatrTvTab()}</TabContent>
              <TabContent id="tv-console">{this.renderTvConsoleTab()}</TabContent>
            </Tabs>
          </div>
        </div>
      </PopupStateless>
    );
  }
}

HowToWatchPopup.defaultProps = { opened: false };

HowToWatchPopup.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  textData: PropTypes.object,
};

export default HowToWatchPopup;
