import { shallowEqual } from '@beef/utils';

import { useAppSelector } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

/** Получаем данные доступности и возможности подключения для страницы настройки ШПД */
export const useCheckAddressResults = () =>
  useAppSelector(
    (state) => ({
      isHasRequest: state.fttbAddress.isHasRequest,
      isNotAvailable: state.fttbAddress.isNotAvailable,
      isAlreadyConnected: state.fttbAddress.isAlreadyConnected,
      isAvailableToConnect: state.fttbAddress.isAvailableToConnect,
    }),
    shallowEqual,
  );
