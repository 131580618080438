import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button } from '@beef/ui-kit';
import { RootStateOrAny, connect } from 'react-redux';

import { isEmptyValue } from 'utils/isEmptyValue';
import { selectStateSpn, selectWaitingRequest } from 'pages/SPN/store/actions/selectors';
import { ActionBarCommonType } from 'pages/SPN/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const ActionBar: FC<ActionBarCommonType> = ({ data, handlersModal, isWaitingRequest }) => {
  if (isEmptyValue(data)) return null;

  const { main, additional } = handlersModal;

  const { mainButtonText, additionalButtonText } = data;

  return (
    <div className={cn('action-bar')}>
      {mainButtonText && (
        <Button isLoading={isWaitingRequest} onClick={main} testName="ABCommonMainBtn">
          {mainButtonText}
        </Button>
      )}

      {additionalButtonText && (
        <Button onClick={additional} testName="ABCommonAdditionalBtn" variant="tertiary">
          {additionalButtonText}
        </Button>
      )}
    </div>
  );
};

const ConnectedActionBar = connect((state: RootStateOrAny) => {
  const spnState = selectStateSpn(state);

  return {
    isWaitingRequest: selectWaitingRequest(spnState),
  };
})(ActionBar);

export { ConnectedActionBar as ActionBar };
