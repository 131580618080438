import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { isEmpty, startsWith } from 'ramda';

// Components
import { Heading, TextInput } from '@beef/legacy-ui-kit';

import LineComponent from './components/LineComponent';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MetroStations = ({ title, placeholder, errorMessage, lines }) => {
  const stationsList = useMemo(() => {
    const allStations = [];
    lines?.map(({ stations }) =>
      stations?.map((station) => allStations.push(station?.name.toLowerCase())),
    );

    return allStations;
  }, [lines]);

  const [selectedStation, selectStation] = useState('');
  const [inputActive, setInputActive] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (value) => {
    setInputActive(true);
    selectStation(value);
    // Проверяет, есть ли значение поиска в списке станций
    setError(!stationsList.some((station) => startsWith(value, station)));

    if (isEmpty(value)) {
      setInputActive(false);
      selectStation('');
    }
  };

  return (
    <div className={cx('container')}>
      <Heading className={cx('title')} isStyled={false} level={2}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Heading>

      <div className={cx('searchContainer')}>
        <TextInput
          onChange={(value) => handleChange(value.toLowerCase())}
          placeholder={placeholder}
        />
      </div>

      <div className={cx('stationsContainer')}>
        {lines?.map((line) => (
          <LineComponent
            inputActive={inputActive}
            key={line.name}
            onError={setError}
            selectedStation={selectedStation}
            {...line}
          />
        ))}
      </div>

      {error && (
        <span className={cx('errorMessage')} dangerouslySetInnerHTML={{ __html: errorMessage }} />
      )}
    </div>
  );
};

MetroStations.defaultProps = {
  lines: [],
};

MetroStations.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  lines: PropTypes.arrayOf(PropTypes.object),
};

export default MetroStations;
