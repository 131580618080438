import React from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@beef/ui-kit';

import style from './styles.pcss';

const cn = classNames.bind(style);

const SkeletonExtendedSteps = (): JSX.Element => (
  <div className={cn('wrapper')}>
    <div className={cn('left-block')}>
      <Skeleton className={cn('skeleton__title')} />

      <ul className={cn('skeleton__list')}>
        {[...Array(5).keys()].map((_, indx) => (
          <Skeleton
            className={cn('skeleton__item', {
              'skeleton__item--longXL': indx === 2,
              'skeleton__item--longL': indx === 3,
              'skeleton__item--longM': indx === 4,
            })}
            key={+indx}
          />
        ))}
      </ul>

      <ul className={cn('skeleton__list-description')}>
        {[...Array(4).keys()].map((_, indx) => (
          <Skeleton
            className={cn('skeleton__item-description', {
              'skeleton__item-description--wide': indx % 2 === 1,
              [`skeleton__item-description--mobile-wide-${indx}`]: indx,
            })}
            key={+indx}
          />
        ))}
      </ul>

      <Skeleton className={cn('skeleton__lb-button')} />
    </div>
    <div className={cn('right-block')}>
      <Skeleton className={cn('skeleton__img')} />
    </div>
  </div>
);

export { SkeletonExtendedSteps };
