import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { ScrollContainer } from '@beef/legacy-ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ProductFeature = ({ logo, title, text, img, galleries }) => (
  <div>
    <div className={cx('description')}>
      <div className={cx('description__content')}>
        {logo && (
          <div className={cx('logo')}>
            <img alt="" src={logo} />
          </div>
        )}
        {title && (
          <div className={cx('title')}>
            <Text size="size1-m" tagType="h2">
              <div dangerouslySetInnerHTML={{ __html: title }} />
            </Text>
          </div>
        )}
        {text && (
          <div className={cx('title')}>
            <Text size="size4-r" tagType="div">
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </Text>
          </div>
        )}
      </div>
      {img && (
        <div className={cx('description__img')}>
          <img alt="" src={img} />
        </div>
      )}
    </div>
    {galleries?.length && (
      <>
        {galleries.map((galleryItem, i) => (
          <div className={cx('card-gallery')} key={i}>
            {galleryItem.title && (
              <div className={cx('card-gallery__title')}>
                <Text size="size3-m" tagType="h3">
                  {galleryItem.title}
                </Text>
              </div>
            )}
            {galleryItem?.icons?.length > 0 && (
              <div className={cx('card-gallery__list-wrap')}>
                <ScrollContainer handleShouldPlayDemo={() => {}}>
                  <ul className={cx('card-gallery__list')}>
                    {galleryItem.icons.map((icon, i) => (
                      <li className={cx('card-gallery__list-item')} key={i}>
                        <img alt="" src={icon} />
                      </li>
                    ))}
                  </ul>
                </ScrollContainer>
              </div>
            )}
          </div>
        ))}
      </>
    )}
  </div>
);

ProductFeature.propTypes = {
  logo: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
  /* галлерея изображения */
  galleries: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icons: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

export default ProductFeature;
