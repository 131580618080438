import { useEffect, useState } from 'react';

import { TPopupContent } from 'pages/ProductCard/Tariff/containers/ConstructorTariff/types';
import { RecursivePartial } from 'utils/types';

/**
 * Функция управления модальным окном;
 */

export const useModalActions = (
  activeOption?: string,
  popups: RecursivePartial<Record<string, TPopupContent>> = {},
) => {
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState<string | undefined>();

  // NOTE: Looking for the correct popup in content
  const popupIndex = Object.entries(popups).findIndex(
    ([key]) => key.toLowerCase() === activeOption?.toLowerCase(),
  );
  const contentList: TPopupContent = Object.values(popups)[popupIndex] || [];
  const content: TPopupContent[number] | undefined = contentList.find((v) => v.id === id);

  useEffect(() => {
    if (showModal && !content) {
      // NOTE: Because the logic performs toggling,
      // we need to make sure that it's toggled back correctly
      // if popup is invisible
      setShowModal(false);
    }
  }, [showModal, content, setShowModal]);

  const isOpen = showModal && !!content;

  const handleModal = (_id?: string) => {
    setId(_id);
    setShowModal((prevShowModal) => !prevShowModal);
  };

  return { isOpen, handleModal, content };
};
