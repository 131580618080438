import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { templateParser } from 'utils/format-string';

import TvPackage from './components/TvPackage';
import FlatButton from '../FlatButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TvPackagesGroup = (props) => {
  const {
    onTvPackageActiveChange,
    onShowAllPackagesClick,

    tvPackagesGroup,
    activeTvPackages,
    maxTvPackagesCount,
    contentData,

    forceOpenTvPackageChannels,
    isWithoutUpsaleSwitch,
  } = props;

  const { showAllPackagesBtnTemplate } = contentData;

  const tvPackages = useMemo(
    () => tvPackagesGroup.tvPackages.slice(0, maxTvPackagesCount),
    [maxTvPackagesCount, tvPackagesGroup.tvPackages],
  );

  const onShowAllPackagesBtnClick = useCallback(
    () => onShowAllPackagesClick(tvPackagesGroup.alias),
    [onShowAllPackagesClick, tvPackagesGroup.alias],
  );

  return (
    <div>
      <div className={cx('title')} dangerouslySetInnerHTML={{ __html: tvPackagesGroup.name }} />
      {tvPackages.map((tvPackage) => (
        <TvPackage
          forceOpenChannels={forceOpenTvPackageChannels}
          isActive={activeTvPackages.includes(tvPackage.alias)}
          isWithoutUpsaleSwitch={isWithoutUpsaleSwitch}
          key={tvPackage.alias}
          onActiveChange={onTvPackageActiveChange}
          tvPackage={tvPackage}
        />
      ))}
      {maxTvPackagesCount < tvPackagesGroup.tvPackages.length && (
        <FlatButton className={cx('allPackagesBtn')} onClick={onShowAllPackagesBtnClick}>
          {templateParser(showAllPackagesBtnTemplate, {
            packagesCount: tvPackagesGroup.tvPackages.length - maxTvPackagesCount,
          })}
        </FlatButton>
      )}
    </div>
  );
};

TvPackagesGroup.defaultProps = {
  activeTvPackages: [],
  maxTvPackagesCount: 3,
};
TvPackagesGroup.propTypes = {
  tvPackagesGroup: PropTypes.shape({
    alias: PropTypes.string,
    name: PropTypes.string,
    tvPackages: PropTypes.arrayOf(PropTypes.shape({ alias: PropTypes.string })),
  }),
  onTvPackageActiveChange: PropTypes.func,
  onShowAllPackagesClick: PropTypes.func,
  contentData: PropTypes.shape({ showAllPackagesBtnTemplate: PropTypes.string }),
  forceOpenTvPackageChannels: PropTypes.bool,
  isWithoutUpsaleSwitch: PropTypes.bool,
  maxTvPackagesCount: PropTypes.number,
  activeTvPackages: PropTypes.arrayOf(PropTypes.string),
};

export default memo(TvPackagesGroup);
