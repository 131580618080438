import React, { FC } from 'react';

import { ServiceTiles } from '../ServiceTiles';
import { FmcTabsContainer } from '../FmcTabsContainer';
import { usePrepareConvergence } from './hooks/usePrepareConvergence';

/** Компонент-обертка получения данных из store для блока конвергенции и билайн-ТВ */
export const ConvergenceServices: FC = () => {
  const { convergenceProps } = usePrepareConvergence();
  return (
    <ServiceTiles {...convergenceProps}>
      {/* Блок доступных ШПД-пресетов (покажем, если в регионе доступно подключение ШПД) */}
      <FmcTabsContainer />
    </ServiceTiles>
  );
};
