import React, { FC, ReactNode } from 'react';
import { Modal, ModalPropsType } from '@beef/ui-kit';

import { ModalActionBar, TModalActionBar } from './components/ModalActionBar';

export type TModalContainer = {
  children?: ReactNode;
  modalActionBarProps: TModalActionBar;
  modalProps: ModalPropsType;
};

/** Контейнер модального окна для Passive */
export const ModalContainer: FC<TModalContainer> = ({
  children,
  modalProps,
  modalActionBarProps,
}) => (
  <Modal size="m" {...modalProps} actionBar={<ModalActionBar {...modalActionBarProps} />}>
    {children}
  </Modal>
);
