import React, { FC } from 'react';

import { PageHeader } from '../../../../commonComponents/PageHeader';
import { useSelectUserAddress } from './selectors/useSelectPageContent';

/** Обертка над заголовком и кнопокй "назад" (на страницу редактирования адреса подключения ШПД) */
export const AddressEditorHeader: FC = () => {
  const titleContentProps = useSelectUserAddress();
  return <PageHeader {...titleContentProps} />;
};
