import { useEffect, useState } from 'react';

import {
  ServiceContext,
  ServiceContexts,
  ZeroBalanceSocs,
} from 'pages/Services/ScopeZeroBalanceServices/types';

import { fetchServiceNotification, filterServiceInfoBySoc } from '../utils';

/**
 * @desc: Хук возвращает текст, статус (цвет карточки) для подключенной услуги
 * @desc: если в массиве больше 1-го элементов, то ответ фильтруется по сокам услуги,
 * @desc: приходящими в аргументе и возвращается статус только для нужной услуги
 */

export const useGetServiceNotification = (
  screenId: string,
  socs: ZeroBalanceSocs,
  isAuthorized: boolean,
) => {
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    if (isAuthorized) {
      fetchServiceNotification(screenId)
        .then((res: ServiceContexts) => filterServiceInfoBySoc(res, socs))
        .then((res: ServiceContext) => setServiceData(res));
    }
  }, []);

  return serviceData;
};
