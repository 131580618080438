import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';
import { useIsDesktopDimension } from 'utils/hooks/useGetDimensionGroup';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Header = ({ title, bannerInfo }) => {
  const isDesktop = useIsDesktopDimension();

  return (
    <div
      className={cx('header')}
      style={{ backgroundImage: isDesktop ? null : `url("${bannerInfo.image}")` }}
    >
      <div className={cx('header__wrapper')}>
        <div className={cx('header__wrapper__text')}>
          {title && (
            <div className={cx('title')}>
              <Text color="grey95" size="size0-b" tagType="h1">
                {title}
              </Text>
            </div>
          )}
          <ol className={cx('benefits')}>
            {bannerInfo?.benefits.map((benefit, idx) => (
              <li className={cx('benefit')} key={idx}>
                <div className={cx('benefit__number')}>
                  <Text color="beeline" size="size1-b" tagType="span">
                    {idx + 1}
                  </Text>
                </div>
                <Text color="grey50" size="size5-r-s" tagType="span">
                  {benefit}
                </Text>
              </li>
            ))}
          </ol>
        </div>
        <div className={cx('header__wrapper__img')}>
          <Picture className={cx('header__wrapper__img__picture')} src={bannerInfo.image} />
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  title: 'Персональный Конструктор',
};

Header.propTypes = {
  bannerInfo: PropTypes.shape({
    image: PropTypes.string,
    benefits: PropTypes.arrayOf(PropTypes.string),
  }),
  title: PropTypes.string,
};

export { Header };
