import React, { useState } from 'react';
import { ActionBar, Button, Modal } from '@beef/ui-kit';

import Captcha from 'pages-components/Captcha/Captcha';
import { CAPTCHA_STATUS } from 'pages/FTTB2021/hoc/CaptchaWithCallbackPopupHOC';

interface CaptchaPopupProps {
  data: {
    captchaMsg: string;
    captchaStatus: string;
    captchaToken: string;
    changeCaptchaMsg: (captchaMsg: string) => void;
    changeCaptchaStatus: (status: string) => void;
    changeCaptchaToken: (captchaToken: string) => void;
    onBackCallBtnClick: () => void;
    reCaptchaModel: Record<string, any>;
    texts: {
      captchaTexts: {
        button: string;
        errorMsg: string;
        reloadText: string;
        title: string;
      };
    };
  };
  isLoading: boolean;
  onClose: () => void;
}

export const CaptchaPopup: React.FC<CaptchaPopupProps> = ({ data, onClose, isLoading }) => {
  const {
    reCaptchaModel,
    onBackCallBtnClick,
    texts,
    captchaStatus,
    changeCaptchaStatus,
    captchaMsg,
    changeCaptchaMsg,
    captchaToken,
    changeCaptchaToken,
  } = data;

  const [newCaptcha, setNewCaptcha] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (captchaToken && captchaStatus === CAPTCHA_STATUS.success) {
      onBackCallBtnClick();
      setNewCaptcha(!newCaptcha);
    }
  };

  const inputOnBlur = () => {
    if (!captchaToken) {
      changeCaptchaStatus(CAPTCHA_STATUS.fail);
      changeCaptchaMsg(texts.captchaTexts.title);
    } else if (captchaToken.length < 5) {
      changeCaptchaStatus(CAPTCHA_STATUS.fail);
      changeCaptchaMsg(texts.captchaTexts.errorMsg);
    } else {
      changeCaptchaStatus(CAPTCHA_STATUS.success);
      changeCaptchaMsg('');
    }
  };

  const inputOnFocus = () => {
    changeCaptchaMsg('');
  };

  return (
    <Modal
      actionBar={
        <ActionBar isWithText size="s">
          <Button isLoading={isLoading} onClick={handleSubmit}>
            {texts.captchaTexts?.button}
          </Button>
        </ActionBar>
      }
      isOpen
      onClose={onClose}
      size="s"
    >
      <Captcha
        {...reCaptchaModel}
        changeCaptchaMsg={changeCaptchaMsg}
        changeCaptchaStatus={changeCaptchaStatus}
        changeCaptchaToken={changeCaptchaToken}
        changeNewCaptcha={setNewCaptcha}
        errorMsg={captchaMsg}
        msg={texts.captchaTexts}
        newCaptcha={newCaptcha}
        onBlur={inputOnBlur}
        onFocus={inputOnFocus}
        status={captchaStatus}
      />
    </Modal>
  );
};
