import { shallowEqual } from '@beef/utils';

import { useAppSelector } from '../../../../store';

/** Селектор выборки title и description для PageHeader */
export const useSelectTariffTitle = () => {
  const { pageTitle = '', pageDescription = '' } = useAppSelector(
    (state) => ({
      pageTitle: state.common.content?.pageTitle,
      pageDescription: state.common.content?.pageDescription,
    }),
    shallowEqual,
  );

  return { title: pageTitle, description: pageDescription };
};
