import { ChangeEvent } from 'react';
import { ChipsPropsType, FontSizeType } from '@beef/ui-kit';

import { useAppDispatch } from '../../../../../../../store';
import { sendFmcYm } from '../../../../../FmcTabsContainer/tempYmUtils';
import { setNetSpeed } from '../../../../../../../store/slices/totalSlice';
import { createServicesFmcTabs, selectServiceForActiveTab } from '../../utils';
import { useSelectFmcServiceContent } from '../../selectors/useSelectFmcServiceContent';
import { useSelectFmcServicesTariffs } from '../../selectors/useSelectFmcServicesTariffs';
import { useSelectActiveInacId } from '../../../../../FmcTabsContainer/selectors/useSelectActiveInacId';
import { changeFmcInacId } from '../../../../../../../store/slices/totalSlice/asyncThunks/changeFmcInacId';

export const usePrepareFmcServicesTabs = () => {
  const dispatch = useAppDispatch();

  /* Контент для страницы настройки ШПД */
  const settingsFmcPage = useSelectFmcServiceContent();
  const { internetSection } = settingsFmcPage ?? {};

  /* Доступные тарифы ШПД для настройки */
  const servicesTariffs = useSelectFmcServicesTariffs();

  /* Пропы для компонента TitleContent (информационное описание блока настройки) */
  const titleContentProps = {
    ...internetSection,
    size: 'size3-m' as FontSizeType,
    extraSize: 'size5-r-s' as FontSizeType,
  };

  /** Текущий активный inacId ШПД (на его основе отображается текущий активный таб) */
  const currentActiveInacId = useSelectActiveInacId();

  /** Вытаскиваем данные для активного ШПД-таба */
  const { priceInfo } = selectServiceForActiveTab(servicesTariffs, currentActiveInacId);

  /** Функция-обработчик клика по табу ШПД */
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    /* Текущий выбранный InacId пользователем */
    const activeInacId = e.target?.value;

    /* Выставляем текущий активный таб ШПД (определяется путем обновления tariffInacId в total) */
    await dispatch(changeFmcInacId(activeInacId));

    /* Вытаскиваем данные для текущего активного ШПД-таба */
    const { priceInfo: activePriceInfo } = selectServiceForActiveTab(servicesTariffs, activeInacId);

    /* Перезаписываем netSpeed для блока Домашний интернет и ТВ */
    dispatch(setNetSpeed(activePriceInfo?.speedText || ''));

    /* Отправка аналитики при клике по табам */
    sendFmcYm(activePriceInfo?.speedText, { fmc_step: 'custom' });
  };

  /*  Собираем данные для китовых (ChipsStateless) табов (title, description и value) */
  const options = createServicesFmcTabs(servicesTariffs);
  /* Пропы для китового компонента ChipsStateless */
  const chipsStatelessFmcProps: ChipsPropsType = {
    options,
    onChange,
    variant: 'card',
    checkedValues: currentActiveInacId,
  };

  return { titleContentProps, chipsStatelessFmcProps, priceInfo };
};
