import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Benefit = ({ image, title, text, isCounter, index }) => (
  <div className={cx('benefit', { 'benefit--counter': isCounter })}>
    {image && <img alt="Иконка бенефита" className={cx('image')} src={image} />}
    {isCounter && (
      <div className={cx('counter')}>
        <Text color="white" size="size4-m">
          {index}
        </Text>
      </div>
    )}
    {title && (
      <div className={cx('title')}>
        <Text size="size5-m" tagType="h2">
          {title}
        </Text>
      </div>
    )}
    {text && (
      <div className={cx('text', { 'text--counter': isCounter })}>
        <Text size="size5-r-s">{text}</Text>
      </div>
    )}
  </div>
);

Benefit.propType = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  isCounter: PropTypes.bool,
  index: PropTypes.number,
};

export default Benefit;
