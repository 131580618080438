import React from 'react';
import { RequestStatus } from '@beef/smart-kit';
import { Modal } from '@beef/ui-kit';

import { ModalActionBar } from 'pages/Services/ZeroBalanceSupport/Component/ModalActionBar';

import { TModalWrapperProps } from './types';

export const ModalWrapper: React.FC<TModalWrapperProps> = ({ onClose, content }) => (
  <Modal
    actionBar={<ModalActionBar {...content.actionBarProps} />}
    isCentered
    isOpen
    onClose={onClose}
    size="m"
  >
    <RequestStatus
      description={content?.modalData?.description}
      img={content?.modalData?.img}
      title={content?.modalData?.title}
    />
  </Modal>
);
