import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { Tabs, Text } from '@beef/ui-kit';

import { StoryCardLayout as StoryCard } from '../StoryCard';
import { StoryModalLayout as StoryModal } from '../StoryModal';
import { ScrollToEdgesWrap } from '../../../FancyNumber2022/components/ScrollToEdgesWrap/index';
import { CardPlaceholder } from '../CardPlaceholder';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const StoryExampleGroups = ({ title, groups, previewButtonText }) => {
  const [currentGroup, setCurrentGroup] = useState(groups[0].region);
  const [currentStory, setCurrentStory] = useState(null);
  const tabs = groups.map((group) => ({
    id: group.region,
    title: group.region,
  }));
  const stories = groups.find((group) => group.region === currentGroup)?.stories || [];

  return (
    <>
      <div className={cx('title')}>
        <Text size="size1-m" tagType="h2">
          {title}
        </Text>
      </div>
      <div className={cx('tabs')}>
        <ScrollToEdgesWrap>
          <Tabs
            isScrollable
            onChange={setCurrentGroup}
            selectedTab={currentGroup}
            tabs={tabs}
            variant="secondary"
          />
        </ScrollToEdgesWrap>
      </div>
      <div className={cx('grid', 'size-m')}>
        {stories.map((story, index) =>
          story.type === 'placeholder' ?
            <CardPlaceholder image={story.image} />
          : <StoryCard
              buttonText={previewButtonText || 'Читать'}
              image={story.image}
              key={+index}
              onClick={() => setCurrentStory(story)}
              text={story.name}
              title={story.author}
            />,
        )}
      </div>
      {currentStory && (
        <StoryModal isOpen onClose={() => setCurrentStory(null)} story={currentStory} />
      )}
    </>
  );
};
