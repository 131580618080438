import React, { FC } from 'react';
import { Link, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { ModalContainer } from 'pages/ProductCard/Tariff/components/CharactersTabs/TabModal';
import { useModalActions } from 'pages/ProductCard/Tariff/components/CharactersTabs/TabModal/hooks/useModalActions';

import styles from '../Option/styles.pcss';

const cx = classNames.bind(styles);

type TeteringPropsTypes = {
  buttonText: string;
  description: string;
  icon?: string;
  subDescription?: string;
  title: string;
};

type BenefitPropsTypes = {
  benefit: string;
  benefitLinkText?: string;
  popupTetering: TeteringPropsTypes;
  useBenefitPopup?: boolean;
};

export const Benefit: FC<BenefitPropsTypes> = ({
  benefit,
  popupTetering,
  benefitLinkText,
  useBenefitPopup,
}) => {
  const { isOpen, handleModal } = useModalActions();

  return (
    <div className={cx('option__description')}>
      {benefit && (
        <Text color="grey50" size="size5-r-s" tagType="div" testName="Option_description">
          {benefit}
        </Text>
      )}
      {useBenefitPopup && benefitLinkText && (
        <>
          <Link href="" isNoUnderline onClick={handleModal} size="size5-r-s">
            {benefitLinkText}
          </Link>
          <ModalContainer {...{ isOpen, handleModal, content: popupTetering }} />
        </>
      )}
    </div>
  );
};
