import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isError: false,
};

const voWifiServiceCardCommonSlice = createSlice({
  name: 'voWifiServiceCardCommon',
  initialState,
  reducers: {
    setErrorScreenStatus(state, action) {
      state.errorScreenStatus = action.payload;
    },
    setIsError(state, action) {
      state.isError = action.payload;
    },
  },
});

export const { setErrorScreenStatus, setIsError } = voWifiServiceCardCommonSlice.actions;
export default voWifiServiceCardCommonSlice.reducer;
