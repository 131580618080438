import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { ErrorScreensHookTypes } from 'pages/Services/VoWifiServiceCardLanding/types';

import { ERRORS_SCREEN_STATUSES } from '../../store/constants';

export const useErrorScreenContainerInit = ({ errorScreenStatus, data }): ErrorScreensHookTypes => {
  const isMobile = useIsMobileDimension();

  let handlers = {
    main: () => {},
    additional: () => {},
  };

  switch (errorScreenStatus) {
    case ERRORS_SCREEN_STATUSES.DEFAULT_ERROR:
      handlers = {
        main: () => {
          window.location.reload();
        },
        additional: () => {
          const url = data?.buttons?.additional?.href;

          return window.open(url);
        },
      };
      break;

    case ERRORS_SCREEN_STATUSES.SERVER_ERROR:
      handlers = {
        main: () => {
          window.location.reload();
        },
        additional: () => {},
      };
      break;

    default:
      return handlers;
  }

  const handleMainClick = () => handlers.main();

  const handleAdditionalClick = () => handlers.additional();

  return {
    isMobile,
    handleMainClick,
    handleAdditionalClick,
  };
};
