import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { Card } from '@beef/smart-kit';

import { useOnScreenSendAnalytics } from 'pages/MnpOrderNew/hooks';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const TransferConditions = ({ data: { title, sections } }) => {
  const nodeYMRef = useOnScreenSendAnalytics(title);

  return (
    <section className={cx('transfer-conditions')} ref={nodeYMRef}>
      <Text color="grey95" size="size2-m" tagType="h2">
        {title}
      </Text>
      <div className={cx('transfer-conditions__list')}>
        {sections.map((transferCondition) => (
          <div
            className={cx('transfer-condition')}
            key={transferCondition.id}
            style={{
              backgroundImage: `url("${transferCondition.img}")`,
            }}
          >
            <Card className={cx('card')}>
              <div className={cx('card__title')}>
                <Text color="grey95" size="size4-r" tagType="span" testName="someName">
                  {transferCondition.title}
                </Text>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </section>
  );
};

TransferConditions.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        img: PropTypes.string,
      }),
    ),
  }),
};
