import React, { memo } from 'react';

import { ButtonContent } from './components/ButtonContent';
import { usePrepareBackButtonProp } from './hooks/usePrepareBackButtonProp';

/** Компонент кнопки возврата на предыдущий шаг на основе react-router */
export const RouteBackButton = memo(() => {
  const onClick = usePrepareBackButtonProp();
  return <ButtonContent onClick={onClick} />;
});
