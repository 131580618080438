import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { Bubble } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

// Hoc's
import CardBubbleHoc from '../../hocs/CardBubbleHoc';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CardBubble = ({ icon, title, text, tail, className, place, tariffId, portal }) => {
  const [isBubbleHidden, useToggleBubble] = useState(false);

  // Если в sessionStorage есть id в стироке то мы не показываем бабл;
  useEffect(() => {
    const tariffIds = sessionStorage.getItem('tariffIds');
    const isCurrentTariffId = tariffIds && tariffIds.match(tariffId);
    const isHide = !!(isCurrentTariffId && isCurrentTariffId.input.match(place));

    useToggleBubble(isHide);
  }, []);

  // По клику на крестик мы скрываем бабл и записываем его в sessionStorage;
  const setHideBubble = () => {
    const tariffIdString = sessionStorage.getItem('tariffIds');
    const newTariffIdString =
      tariffIdString ? `${tariffIdString}, ${tariffId}_${place}` : `${tariffId}_${place}`;

    sessionStorage.setItem('tariffIds', newTariffIdString);
    useToggleBubble(true);
  };

  return (
    <CardBubbleHoc place={place} portal={portal}>
      <Bubble className={[tail, className, cx('cardBubble', isBubbleHidden && 'hide')]}>
        <button className={cx('closeBubble')} onClick={setHideBubble}>
          <Icon name="crossThin" />
        </button>
        <div className={cx('bubbleHeading')}>
          {icon && <Icon className={cx('icon')} name={icon} />}
          {title && <span className={cx('title')} dangerouslySetInnerHTML={{ __html: title }} />}
        </div>
        <div className={cx('text')} dangerouslySetInnerHTML={{ __html: text }} />
      </Bubble>
    </CardBubbleHoc>
  );
};

CardBubble.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  tail: PropTypes.string,
  className: PropTypes.string,
  place: PropTypes.string,
  portal: PropTypes.bool,
  tariffId: PropTypes.number,
};

export default CardBubble;
