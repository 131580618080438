const STEPS = {
  INITIAL: 'INITIAL',
  ESIA_REDIRECT: 'ESIA_REDIRECT',
  CONNECTED: 'CONNECTED',
};

const GET_REDIRECT_ERROR = {
  DEFAULT: 'DEFAULT',
  BLOCKED: 'BLOCKED',
  CONFLICT: 'CONFLICT',
};

const ESIA_REDIRECT_CODE = {
  OK: 'ok',
  ESNODATA: 'esnodata',
  NOT_TRUSTED_ESIA_ACCOUNT: 'not_trusted_esia_account',
  NOT_RUS: 'not_rus',
  NOT_MATCHED: 'not_matched',
  UCP_BAD_REQUEST: 'ucp_bad_request',
  ESIA_ACCESS_DENIED: 'esia_access_denied',
  ESIA_ERROR: 'esia_error',
};

/**
 * @desc: разбирает uri параметры после редиректа от Госуслуг
 *@returns {
 *  {[string]: { available: boolean, value: string | null }}
 * }
 */
const parseEsiaRedirectQuery = ({ paramKeys, queryString }) => {
  const keys = paramKeys || ['esia', 'info', 'status', 'code'];
  const urlParams = new URLSearchParams(queryString || '');
  return keys.reduce((acc, item) => {
    acc[item] = urlParams.has(item) ? urlParams.get(item).toLowerCase() : null;
    return acc;
  }, {});
};

/**
 * @desc: вычисляет инициальный шаг для услуги "Доступный интернет"
 * @returns {string}
 */
const getCurrentStep = ({ isConnected, isAuthenticated, esiaQueryParams }) => {
  if (!isAuthenticated) {
    return STEPS.INITIAL;
  }
  if (isConnected) {
    return STEPS.CONNECTED;
  }

  const { code } = esiaQueryParams;

  return code ? STEPS.ESIA_REDIRECT : STEPS.INITIAL;
};

export { ESIA_REDIRECT_CODE, GET_REDIRECT_ERROR, STEPS, getCurrentStep, parseEsiaRedirectQuery };
