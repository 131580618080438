import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {
  Button,
  Checkbox,
  CollapsedContent,
  InputGroup,
  InputInfo,
  InputsRow,
  Label,
  SmartForm,
  SmartInput,
  Status,
} from '@beef/legacy-ui-kit';

import { maskedPhone as maskedPhoneValidator } from 'utils/validators';
import { formatPhoneWithDefaultPrefix, unformatPhone } from 'utils/format-string';
import SmsLoginForm from 'widgets/SmsLoginForm';

import ResultPopup from '../ResultPopup';
import { postFriendFormData } from './actions';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

class InviteForm extends Component {
  constructor(props) {
    super(props);
    this.postFriendFormData = postFriendFormData.bind(this);
  }

  state = {
    isAuthorized: false,
    wrongFriendCtn: false,
    friendNumber: '+7 ',
    myNumber: '+7 ',
    name: '',
    popupStep: null,
    agreed: true,
  };

  componentDidMount() {
    const { currentCtn } = this.props;
    const friendNumber = localStorage.getItem('friendNumber');
    const name = localStorage.getItem('friendName');

    this.setState({
      friendNumber: friendNumber || '+7 ',
      name: name || '',
    });

    if (currentCtn) {
      this.setAutorization(currentCtn);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentCtn && this.props.currentCtn !== prevProps.currentCtn) {
      this.setAutorization(this.props.currentCtn);
    }
  }

  onResultPopupClose = () => {
    this.setState({ popupStep: null });
  };

  onFriendCtnChange = (friendNumber) => {
    this.setState({
      friendNumber,
      wrongFriendCtn: false,
    });
  };

  onMyNumberChange = (myNumber) => {
    this.setState({ myNumber });
  };

  onNameChange = (name) => {
    this.setState({ name });
  };

  onCheckboxToggle = () => {
    this.setState({ agreed: !this.state.agreed });
  };

  onFormSubmit = () => {
    const { friendNumber, name, isAuthorized } = this.state;

    if (!isAuthorized) {
      this.setState({ popupStep: 'smsConfirm' });
      return;
    }

    this.postFriendFormData({
      ctn: unformatPhone(friendNumber),
      name,
    });
  };

  onSmsLoginFormMount = () => {
    const { friendNumber, name } = this.state;
    localStorage.setItem('friendNumber', friendNumber);
    localStorage.setItem('friendName', name);
  };

  setAutorization = ({ isMobile, isConvergent, isXbr, accountText }) => {
    const isAuthorized = (isMobile || isConvergent) && !isXbr;

    this.setState({
      myNumber: isAuthorized ? formatPhoneWithDefaultPrefix(unformatPhone(accountText)) : '+7 ',
      isAuthorized,
    });
  };

  validateFriendCtn = (value) => {
    const result = maskedPhoneValidator(value);

    if (this.state.wrongFriendCtn) {
      result.status = 'fail';
      result.msg = '';
    }
    return result;
  };

  render() {
    const { texts, showNameInput } = this.props;
    const { popupStep, isAuthorized, wrongFriendCtn, friendNumber, myNumber, name, agreed } =
      this.state;

    const inputProps = {
      mask: '+7 ___ ___ __ __',
      keepInitialValue: true,
      autoComplete: 'off',
      size: 'big',
    };

    const showCheckbox = !!texts.agreeText;
    const showOffero = !!(texts.offeroTitle && texts.offeroText);

    return (
      <>
        <SmartForm
          autoComplete="off"
          disableIfInvalid
          inline
          onSubmit={this.onFormSubmit}
          validator={{
            friendNumber: this.validateFriendCtn,
            myNumber: maskedPhoneValidator,
          }}
        >
          <FormRow info={texts.whomInfo} label={texts.whomLabel}>
            <SmartInput
              {...inputProps}
              inputId="friendNumber"
              name="friendNumber"
              onChange={this.onFriendCtnChange}
              value={friendNumber}
            />
            {wrongFriendCtn && (
              <Status className={['error', 'compact', cx('status')]} emoji="smile-pont">
                {texts.wrongFriendCtn}
              </Status>
            )}
          </FormRow>

          <FormRow
            className={isAuthorized && 'hidden'}
            info={texts.fromInfo}
            label={texts.fromLabel}
          >
            <SmartInput
              {...inputProps}
              inputId="myNumber"
              name="myNumber"
              onChange={this.onMyNumberChange}
              value={myNumber}
            />
          </FormRow>
          {showNameInput && texts.nameTitle && texts.nameInfo && (
            <FormRow>
              <CollapsedContent indexed title={texts.nameTitle}>
                <Label>{texts.nameLabel}</Label>
                <SmartInput
                  {...inputProps}
                  inputId="name"
                  mask=""
                  maxLength="32"
                  name="name"
                  onChange={this.onNameChange}
                  value={name}
                />
              </CollapsedContent>
              <InputInfo>{texts.nameInfo}</InputInfo>
            </FormRow>
          )}
          <FormRow>
            {showCheckbox && (
              <Checkbox
                checked={agreed}
                className={cx('checkbox')}
                handleChange={this.onCheckboxToggle}
              >
                <span dangerouslySetInnerHTML={{ __html: texts.agreeText }} />
              </Checkbox>
            )}
            <Button className="big" disabled={showCheckbox && !agreed} wide>
              Отправить
            </Button>
            {showOffero && (
              <CollapsedContent title={texts.offeroTitle} titleClass={cx('offeroTitle')}>
                <div dangerouslySetInnerHTML={{ __html: texts.offeroText }} />
              </CollapsedContent>
            )}
          </FormRow>
        </SmartForm>

        {popupStep && (
          <ResultPopup
            friendNumber={friendNumber}
            myNumber={myNumber}
            onClose={this.onResultPopupClose}
            step={popupStep}
            texts={texts}
          >
            {popupStep === 'smsConfirm' && (
              <SmsLoginForm
                confirmButtonText={texts.sendSmsButton}
                friendNumber={friendNumber}
                myNumber={myNumber}
                name={name}
                onMount={this.onSmsLoginFormMount}
                sendSmsAgainText={texts.sendSmsAgain}
              />
            )}
          </ResultPopup>
        )}
      </>
    );
  }
}

InviteForm.propTypes = {
  title: PropTypes.objectOf(PropTypes.string),
  currentCtn: PropTypes.object,
  texts: PropTypes.shape({
    agreeText: PropTypes.string,
    offeroTitle: PropTypes.string,
    offeroText: PropTypes.string,
    whomLabel: PropTypes.string,
    whomInfo: PropTypes.string,
    wrongFriendCtn: PropTypes.string,
    fromLabel: PropTypes.string,
    fromInfo: PropTypes.string,
    nameTitle: PropTypes.string,
    nameLabel: PropTypes.string,
    nameInfo: PropTypes.string,
    sendSmsButton: PropTypes.string,
    sendSmsAgain: PropTypes.string,
  }),
  showNameInput: PropTypes.bool,
};

const FormRow = ({ label, info, children, className }) => (
  <InputsRow className={cx('inputGroup', className)}>
    <InputGroup>
      {label && <Label>{label}</Label>}
      {children}
      {info && <InputInfo>{info}</InputInfo>}
    </InputGroup>
  </InputsRow>
);

FormRow.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
};

export default InviteForm;
