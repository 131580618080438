import { ENTITY_TYPE } from 'pages/SearchResultsPage/utils/constants';

export const getPages = (current, total) => [
  /** render first item */
  ...(current > 2 ? [{ index: 1 }] : []),

  /** render dots on left side or number if dots only contain 1 item */
  ...(current === 4 ? [{ index: 2 }] : []),
  ...(current > 4 ? [{ type: ENTITY_TYPE.dots, index: 1 }] : []),

  /** render three central item */
  ...(current === total && total > 4 ? [{ index: current - 2 }] : []),
  ...(current > 1 ? [{ index: current - 1 }] : []),
  { index: current },
  ...(current < total ? [{ index: current + 1 }] : []),
  ...(current === 1 && total > 4 ? [{ index: current + 2 }] : []),

  /** render dots on right side or number if dots only contain 1 item */
  ...(current === total - 3 ? [{ index: total - 1 }] : []),
  ...(current < total - 3 ? [{ type: ENTITY_TYPE.dots, index: total }] : []),

  /** render last item */
  ...(current < total - 1 ? [{ index: total }] : []),
];
