const CARD_TYPES = {
  WIDE: 2,
  WIDE_R: 3,
  WIDE_L: 4,
};

export default (serviceOrTariff) => {
  const { cardType, image, mobileImage } = serviceOrTariff;
  const isLeft = cardType === CARD_TYPES.WIDE_L || cardType === CARD_TYPES.WIDE;
  const isRight = cardType === CARD_TYPES.WIDE_R;
  const hasBanner = isLeft || isRight;
  let alignment = null;
  if (isLeft) alignment = 'left';
  if (isRight) alignment = 'right';
  return {
    size: hasBanner || cardType === CARD_TYPES.WIDE ? 'full' : 'half',
    coverUrl: hasBanner ? image : null,
    mobileCoverUrl: hasBanner ? mobileImage : null,
    alignment,
  };
};
