import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@beef/legacy-ui-kit';
import { getSubstate } from '@beef/legacy-beeline-store/utils';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import qs from 'query-string';

import { templateParser } from 'utils/format-string';

import {
  ALWAYS_IN_POSITIVE_SERVICE_NAME,
  changeLimit,
  confirmLimit,
  disconnectConfirmPopup,
  disconnectService,
  initConfirmLimitPopup,
  personalCreditLimit,
  settingsPopup,
  toggleConnecting,
} from '../../../actions/alwaysInPositiveBanner';
import { tariffData } from '../../../actions/servicesExtension';
import ServiceConnectPopup from '../../ServiceConnectPopup/ServiceConnectPopup';
import AlwaysInPositivePopup from '../../AlwaysInPositivePopup/AlwaysInPositivePopup';
import SettingsPopup from './SettingsPopup';
import { ServiceDisconnectionPopup } from '../../ServiceDisconnectPopup/ServiceDisconnectPopup';
import { initConnectionPopup, toggleServiceConnectionPopup } from '../../../actions/serviceConnect';
// todo: где стили для AlwaysInPositiveSettings? линтер ругается на cx, стили указаны в разметке, но их нет вообще
export const AlwaysInPositiveSettings = (props) =>
  props.show ?
    <div>
      <div dangerouslySetInnerHTML={{ __html: props.title }} />
      <div>
        <Link onClick={props.onClick}>{props.linkText}</Link>
      </div>
    </div>
  : null;
AlwaysInPositiveSettings.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  linkText: PropTypes.string,
  onClick: PropTypes.func,
};

export const AlwaysInPositiveSettingsConnected = connect(
  (state) => {
    const data = getSubstate(state, personalCreditLimit);
    return {
      title: templateParser(data.banner.connectedText, { serviceName: data.banner.serviceName }),
      linkText: data.banner.changeLimitText,
      show: data.banner.isConnected || data.card.isConnectingInProgress,
    };
  },
  (dispatch) => ({
    onClick: () => dispatch(settingsPopup.update({ opened: true })),
  }),
)(AlwaysInPositiveSettings);

export class AlwaysInPositiveServiceContainer extends Component {
  constructor(props) {
    super(props);

    this.handleConnectClick = this.handleConnectClick.bind(this);
  }

  componentDidMount() {
    this.props.setData(this.props.authUrl, this.props.creditLimitData);
    if (
      this.props.isAuthenticated &&
      qs.parse(window.location.search).connect === ALWAYS_IN_POSITIVE_SERVICE_NAME
    ) {
      setTimeout(() => this.props.openSettings(), 500);
    }
  }

  handleConnectClick() {
    if (this.props.isAuthenticated) {
      this.props.onConfirm(this.props.creditLimitData);
    } else {
      this.props.onAuth();
    }
  }

  render() {
    const data = this.props.creditLimitData;

    const amount = pathOr(null, ['card', 'deposit', 'amount'], data);
    const serviceName = pathOr('', ['banner', 'serviceName'], data);
    const soc = pathOr('', ['banner', 'soc'], data);
    const initialDate = new Date();
    let isActive = false;
    if (typeof data.isConnecting === 'undefined' || data.isConnecting === null) {
      isActive = data.banner.isConnected || data.card.isConnectingInProgress;
    } else {
      isActive = data.isConnecting;
    }

    return (
      <div>
        <SettingsPopup
          active={isActive}
          amount={amount}
          data={data}
          isAuthenticated={this.props.isAuthenticated}
          isConnecting={data.isConnecting}
          onAuth={this.props.onAuth}
          onCancel={this.props.onSettingsPopupCancel}
          onClose={this.props.onSettingsPopupClose}
          onConnectClick={this.handleConnectClick}
          onConnectionChange={this.props.onConnectionChange}
          onLimitChange={this.props.onLimitChange}
          onTariffConnect={this.props.onTariffConnect}
          opened={this.props.settingsPopupOpened}
        />
        <AlwaysInPositivePopup />
        <ServiceConnectPopup />
        {this.props.disconnectConfirmPopup ?
          <ServiceDisconnectionPopup
            cancelButtonText={this.props.disconnectConfirmPopup.buttonResetText}
            confirmButtonText={this.props.disconnectConfirmPopup.buttonText}
            content={templateParser(this.props.disconnectConfirmPopup.content, { serviceName })}
            dateText={this.props.disconnectConfirmPopup.dateText}
            disconnectUrl={this.props.disconnectServiceUrl}
            initialDate={initialDate}
            onCancel={this.props.onDisconnectConfirmCancel}
            onClose={this.props.onDisconnectConfirmClose}
            onConfirm={this.props.onDisconnectConfirm}
            popupIsActive={!!this.props.disconnectConfirmPopup.opened}
            serviceName={serviceName}
            soc={soc}
            title={templateParser(this.props.disconnectConfirmPopup.title, { serviceName })}
            warningText={templateParser(this.props.disconnectConfirmPopup.description, {
              serviceName,
            })}
          />
        : null}
      </div>
    );
  }
}

AlwaysInPositiveServiceContainer.propTypes = {
  creditLimitData: PropTypes.object,
  settingsPopupOpened: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  authUrl: PropTypes.string,
  disconnectConfirmPopup: PropTypes.object,
  onConnectionChange: PropTypes.func,
  onSettingsPopupClose: PropTypes.func,
  onSettingsPopupCancel: PropTypes.func,
  onLimitChange: PropTypes.func,
  setData: PropTypes.func,
  onConfirm: PropTypes.func,
  onAuth: PropTypes.func,
  onTariffConnect: PropTypes.func,
  onDisconnectConfirm: PropTypes.func,
  onDisconnectConfirmClose: PropTypes.func,
  onDisconnectConfirmCancel: PropTypes.func,
  disconnectServiceUrl: PropTypes.string,
  openSettings: PropTypes.func,
};

const mapStateToProps = (state) => {
  const tariff = getSubstate(state, tariffData);
  return {
    creditLimitData: getSubstate(state, personalCreditLimit),
    settingsPopupOpened: getSubstate(state, settingsPopup).opened,
    disconnectConfirmPopup: getSubstate(state, disconnectConfirmPopup),
    authUrl: tariff.authPopupUrl,
    isTariffConnected: tariff.isConnected,
    disconnectServiceUrl: tariff.extData.disconnectServiceUrl,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSettingsPopupClose: () => dispatch(settingsPopup.update({ opened: false })),
  onSettingsPopupCancel: () => {
    dispatch(settingsPopup.update({ opened: false }));
    dispatch(toggleConnecting());
  },
  onConnectionChange: () => ownProps.isAuthenticated && dispatch(toggleConnecting()),
  onLimitChange: (value) => dispatch(changeLimit(value)),
  setData: (authUrl, data) => {
    dispatch(
      initConnectionPopup(authUrl, data.banner.alias, ALWAYS_IN_POSITIVE_SERVICE_NAME, data.soc),
    );
    dispatch(initConfirmLimitPopup(data));
  },
  openSettings: () => dispatch(settingsPopup.update({ opened: true })),
  onConfirm: (data) => dispatch(confirmLimit(data)),
  onAuth: () => {
    const { href } = window.location;
    window.history.replaceState(
      {},
      document.title,
      `${href.substr(0, href.lastIndexOf('/'))}/?connect=${ALWAYS_IN_POSITIVE_SERVICE_NAME}`,
    );
    dispatch(settingsPopup.update({ opened: false }));
    dispatch(toggleServiceConnectionPopup(true));
  },
  onTariffConnect: () => {
    const urlParams = qs.parse(window.location.search);
    urlParams.connect = 'true';
    const { protocol } = window.location;
    const { host } = window.location;
    const { pathname } = window.location;
    window.location.href = `${protocol}//${host}${pathname}?${qs.stringify(urlParams)}`;
  },
  onDisconnectConfirm: (disconnectDate) => {
    dispatch(
      disconnectService(
        new Date(
          Date.UTC(
            disconnectDate.getFullYear(),
            disconnectDate.getMonth(),
            disconnectDate.getDate(),
          ),
        ),
        { isUpsell: true },
      ),
    );
  },
  onDisconnectConfirmCancel: () => {
    dispatch(disconnectConfirmPopup.update({ opened: false }));
    dispatch(settingsPopup.update({ opened: true }));
  },
  onDisconnectConfirmClose: () => {
    dispatch(disconnectConfirmPopup.update({ opened: false }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AlwaysInPositiveServiceContainer);
