import { BreadCrumbItemType } from '@beef/ui-kit';

import { ResponseType } from 'pages/ActionCategoryPage/types';

type DataConverterType = {
  breadcrumbs: BreadCrumbItemType[];
  title: string;
} & ResponseType;

export const dataConverter = (content: DataConverterType) => ({
  content: {
    ...content,
  },
});
