import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiValidationUrl } from './constants';

/** Тип запроса (params) */
type TValidateMnpReq = {
  phone: string;
};

/** Тип ответа */
export type TValidateMnpRes = {
  Errors?: Record<string, any>;
  IsSucceeded: boolean;
  View?: {
    IsExistingCtn: boolean;
  };
  camelCase?: boolean;
};

export const validateMnp = createAsyncThunk<TValidateMnpRes, TValidateMnpReq>(
  'mnpApi/validateRequest',
  async (params, { signal, rejectWithValue }) => {
    try {
      return (await axios.post<TValidateMnpRes>(apiValidationUrl, params, { signal })).data;
    } catch (e) {
      return rejectWithValue({ IsSucceeded: false });
    }
  },
);
