import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Utils
import { Text } from '@beef/ui-kit';

import { hasShutDownService } from 'pages/ProductCard/utils';

// Components
import { OptionInfo } from '../OptionInfo';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const OptionsShutDown = ({ title, options, packages, shutdownOption, autoProlongTitle }) => {
  const isShutDownShowOption = hasShutDownService(options);
  const isShutDownHidePackages = hasShutDownService(packages);
  const showTitle = title && (isShutDownShowOption || isShutDownHidePackages);
  const showAutoProlongTitle = isShutDownHidePackages && autoProlongTitle;

  return (
    <div className={cx('wrapper')}>
      {showTitle && (
        <div className={cx('wrapper__title')}>
          <Text color="grey95" size="size4-m">
            {title}
          </Text>
        </div>
      )}

      {showAutoProlongTitle && (
        <div className={cx('wrapper__prolong')}>
          <Text color="grey95" size="size5-r-s">
            {autoProlongTitle}
          </Text>
        </div>
      )}

      {options?.map(
        (option) =>
          !option?.checked &&
          option?.defaultEnabled &&
          shutdownOption && (
            <OptionInfo
              hasConnectedOptions={!!option.isConnectedPeriod}
              isShutdown={shutdownOption}
              key={option.title}
              period={option.period}
              price={option.price}
              title={option.title}
              unit={option.unit}
              value={option.value}
            />
          ),
      )}
    </div>
  );
};

OptionsShutDown.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.number,
      value: PropTypes.number,
      period: PropTypes.string,
      isShutdown: PropTypes.bool,
      isConnectedPeriod: PropTypes.bool,
    }),
  ),
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      unit: PropTypes.string,
      price: PropTypes.number,
      value: PropTypes.number,
      period: PropTypes.string,
      unitPrice: PropTypes.string,
      remainingSize: PropTypes.number,
    }),
  ),
  shutdownOption: PropTypes.bool,
  autoProlongTitle: PropTypes.string,
};
