import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { ItemContentContainer } from '../ItemContentContainer';
import { fullOrderServicesShape } from '../../../../../../PropTypes';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const TariffContentContainer = ({
  isMobile,
  tariffInacId,
  fttbServiceType,
  fullOrderServices,
}) => (
  <ul className={cx('wrapper')}>
    {fullOrderServices?.map(({ toolTipText, descriptionFee, subscriptionFee, ...props }) => (
      <ItemContentContainer
        {...props}
        key={props.id}
        showToolTip={toolTipText && !isMobile}
        {...{ isMobile, tariffInacId, toolTipText }}
        fttbServiceType={fttbServiceType}
        subscription={descriptionFee?.replace('{0}', subscriptionFee)}
      />
    ))}
  </ul>
);

TariffContentContainer.propTypes = {
  isMobile: PropTypes.bool,
  fttbServiceType: PropTypes.number,
  tariffInacId: PropTypes.string,
  id: PropTypes.string,
  fullOrderServices: fullOrderServicesShape,
};
