export const eSim = 'eSIM';
export const eSimStep = 'replace_eSIM';

export const YM_ESIM_STEP_PARAMS = {
  GET_ESIM: 'card_get_eSIM',
  ENTER_ESIA: 'signin_ESIA',
  SIGNIN_SUCCESS_ESIA: 'signin_success_ESIA',
  SET_SIGNATURE: 'application_get_eSIM',
  SUCCESS_ESIM_QR: 'QR_success',
  ERROR_ESIM: 'error',
  GET_SIM_CARD: 'card_get_SIM',
  INSTALL_ESIM_PROFILE: 'set_eSIM_profile',
};

export const YM_ESIM_PARAMS = {
  GET_ESIM_START: { [eSim]: { [eSimStep]: YM_ESIM_STEP_PARAMS.GET_ESIM } },
  AUTH_BY_ESIA: { [eSim]: { [eSimStep]: YM_ESIM_STEP_PARAMS.ENTER_ESIA } },
  SUCCESS_BY_ESIA: { [eSim]: { [eSimStep]: YM_ESIM_STEP_PARAMS.SIGNIN_SUCCESS_ESIA } },
  CONFIRM_BY_SIGNATURE: { [eSim]: { [eSimStep]: YM_ESIM_STEP_PARAMS.SET_SIGNATURE } },
  QR_CODE_SUCCESS: { [eSim]: { [eSimStep]: YM_ESIM_STEP_PARAMS.SUCCESS_ESIM_QR } },
  GET_SIMPLE_SIM_START: { [eSim]: { [eSimStep]: YM_ESIM_STEP_PARAMS.GET_SIM_CARD } },
  WEBVIEW_INSTALL_ESIM: { [eSim]: { [eSimStep]: YM_ESIM_STEP_PARAMS.INSTALL_ESIM_PROFILE } },
};
