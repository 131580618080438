import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';
import OkStatus from 'pages-components/OkStatus/OkStatus';

import TextContainer from '../TextContainer/TextContainer';
import commonStyles from '../SharingService.pcss';
import styles from './DisconnectSuccessPopup.pcss';

const cx = classNames.bind({ ...styles, ...commonStyles });

class DisconnectWarningPopup extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.onButtonClick) {
      this.props.onButtonClick();
    } else {
      this.props.onClose();
    }
  }

  renderText = () => {
    const { type, number } = this.props;

    switch (type) {
      case 'all':
        return (
          <TextContainer
            className={cx('description', 'descriptionWrapper')}
            path={['texts', 'disconnectSuccessDescription']}
          />
        );
      case 'Cancel':
        return (
          <TextContainer
            className={cx('description', 'descriptionWrapper')}
            path={['texts', 'disconnectSuccessOneCancelled']}
            variables={{ number: number.number }}
          />
        );
      case 'Delete':
        return (
          <TextContainer
            className={cx('description', 'descriptionWrapper')}
            path={['texts', 'disconnectSuccessOneDeleted']}
            variables={{ number: number.number }}
          />
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <PopupStateless onClose={this.props.onClose} opened={this.props.opened}>
        <div className={cx('content')}>
          <div className={cx('titleWrapper')}>
            <OkStatus emoji="statusOk">
              <TextContainer className={cx('title')} path={['texts', 'disconnectSuccessTitle']} />
            </OkStatus>
          </div>
          {this.renderText()}
          <Button className="big" onClick={this.handleClick}>
            <TextContainer path={['texts', 'invitationLinkClose']} />
          </Button>
        </div>
      </PopupStateless>
    );
  }
}

DisconnectWarningPopup.propTypes = {
  opened: PropTypes.bool,
  type: PropTypes.string,
  number: PropTypes.object,
  onButtonClick: PropTypes.func,
  onClose: PropTypes.func,
};

export default DisconnectWarningPopup;
