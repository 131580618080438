import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LandingConstructor } from '@beef/landing-constructor';

import {
  faqContentShape,
  layoutContentShape,
} from 'pages/Services/ScopeZeroBalanceServices/propTypes/contentPropTypes';
import { ZeroBalanceSupportContext } from 'pages/Services/ZeroBalanceSupport/context';
import {
  SCREEN_ID_CONFIG,
  ZERO_BALANCE_SOCS,
} from 'pages/Services/ScopeZeroBalanceServices/constants';
import { useGetServiceNotification } from 'pages/Services/ScopeZeroBalanceServices/hooks/useGetServiceNotification';
import { FaqLayout } from 'pages/Services/ZeroBalanceSupport/Component/FaqLayout';

import { Banner } from '../Component/Banner';
import { ImageCardContainer } from '../Component/ImageCardContainer';
import { ServiceNotification } from '../Component/ServiceNotification';

export const ZeroBalanceSupportWithContext = ({ faq, layout }) => {
  const {
    data: { isConnected, isAuthenticated },
    requestStatus: { inactiveService },
  } = useContext(ZeroBalanceSupportContext);

  const status = useGetServiceNotification(
    SCREEN_ID_CONFIG.serviceDetail,
    ZERO_BALANCE_SOCS,
    isAuthenticated,
  );

  return (
    <>
      {isConnected && (
        <ServiceNotification
          isConnected={isConnected}
          requestStatus={inactiveService}
          status={status}
        />
      )}
      <Banner />
      <ImageCardContainer />
      <LandingConstructor {...layout} />
      <FaqLayout {...faq} />
    </>
  );
};

ZeroBalanceSupportWithContext.propTypes = {
  isConnected: PropTypes.bool,
  faq: faqContentShape,
  layout: layoutContentShape,
};
