import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';
import { handleChangeTotal } from 'pages/ProductCard/Tariff/containers/ConstructorTariff/actions';
import DispatchContext, {
  ConstructorContext,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff/constants';
import { SwitchBlock } from 'pages/ProductCard/Tariff/components/Options/SwitchBlock';

import Platforms from '../../Platforms';
import { Benefit } from '../Benefit';
import { getIsDisabledAsPassive, initPrice } from '../../../../utils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Option = ({
  id,
  unit,
  option,
  popupTetering,
  initialChecked,
  benefitLinkText,
  withoutDiscountUnit,
}) => {
  const _dispatch = useContext(DispatchContext);
  const { activeCharacter, activeOptionSoc } = useContext(ConstructorContext);
  const [checked, setChecked] = useState(initialChecked);
  const [price, setPrice] = useState(null);
  // TODO вынести логику после теттеринга
  const isEnable = option.isHideInCheck && activeCharacter?.type === 1;
  const showPriceWithoutDiscount = price?.discountSum !== price?.sum;
  const checkedOption = checked || isEnable;
  const { isDisabledSwitchAsPassive, isDisabledAllAsPassive } = getIsDisabledAsPassive(
    option,
    activeOptionSoc,
  );
  const isDisabledOption = !!option.persistentEnabled || isEnable || isDisabledSwitchAsPassive;
  const isDisabledPlatform = !!option.persistentEnabled || isEnable || isDisabledAllAsPassive;
  const currencyDiscountSum =
    (price?.discountSum ?? 0) === 0 ? 'Бесплатно' : `${price.discountSum} ${unit}`;
  const currencySum = (price?.sum ?? 0) === 0 ? 'Бесплатно' : `${price.sum} ${withoutDiscountUnit}`;

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  useEffect(() => {
    if (price) {
      _dispatch(handleChangeTotal({ id, price }));
    }
  }, [price]);

  useEffect(() => {
    const newPrice = initPrice(option, initialChecked, activeOptionSoc);
    setPrice(newPrice);
  }, [option, initialChecked]);

  const onChange = (e) => {
    setChecked(e.target.checked);
    _dispatch(
      handleChangeTotal({
        id,
        price: {
          ...price,
          checked: e.target.checked,
        },
      }),
    );
  };

  return (
    <div className={cx('option')} data-component="Option">
      <div className={cx('option__platform-top')}>
        <div className={cx('option__title')}>
          <Text color="grey95" size="size4-r" tagType="div" testName="Option_title">
            {option.title}
          </Text>
          {option?.benefit && (
            <Benefit
              benefit={option.benefit}
              benefitLinkText={benefitLinkText}
              popupTetering={popupTetering}
              useBenefitPopup={option.useBenefitPopup}
            />
          )}
        </div>
        {price && (
          <SwitchBlock
            checkedOption={checkedOption}
            currencyDiscountSum={currencyDiscountSum}
            currencySum={currencySum}
            disabledOption={isDisabledOption}
            name={option.title}
            onChange={onChange}
            showPriceWithoutDiscount={showPriceWithoutDiscount}
          />
        )}
      </div>
      {option.iconForConstructorOptions && (
        <div className={cx('option__sprite')} data-component="Option_icon">
          <Picture src={option.iconForConstructorOptions} />
        </div>
      )}
      {option.subOptions && option.subOptions.length ?
        <Platforms
          checked={checked || isEnable}
          disabled={isDisabledPlatform}
          id={id}
          isHideInCheck={option.isHideInCheck}
          optionEntityName={option.entityName}
          optionSoc={option.soc}
          platforms={option.subOptions}
          setChecked={setChecked}
          setPrice={setPrice}
          title={option.title}
        />
      : null}
    </div>
  );
};

Option.defaultProps = {};

Option.propTypes = {
  id: PropTypes.number,
  unit: PropTypes.string,
  initialChecked: PropTypes.bool,
  benefitLinkText: PropTypes.string,
  option: PropTypes.shape({}),
  popupTetering: PropTypes.shape({}),
};

export default Option;
