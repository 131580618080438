import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { CellAction, CellCollection } from '@beef/ui-kit';

import { withConnectCategories } from '../hocs/withConnectCategories';
import { MobileCategory } from './MobileCategory';
import styles from './styles.pcss';
import { CategoriesPropsType } from '../types';

const cx = classNames.bind(styles);

const _MobileCategories = ({ categories, resetFilter, selectedId }: CategoriesPropsType) => (
  <div className={cx('container')}>
    <CellCollection>
      <CellAction
        id="all"
        isActive={!selectedId}
        leftTitle="все категории"
        onClick={resetFilter}
        type="radio"
      />
      <div className={cx('categories')}>
        <MobileCategory categories={categories} />
      </div>
    </CellCollection>
  </div>
);

_MobileCategories.propTypes = {
  resetFilter: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  categories: PropTypes.array,
};

export const MobileCategories = withConnectCategories(_MobileCategories);
