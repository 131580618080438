import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames/bind';
import { Button, DropdownCustom, Link } from '@beef/legacy-ui-kit';
import qs from 'query-string';

import PopupStateless from 'pages-components/Popup';
import { email as emailValidator } from 'utils/validators';

import OrderForm, { AddressLink } from './OrderForm';
import Text from '../Text';
import styles from './index.pcss';

const cx = classNames.bind(styles);

const formItems = [
  {
    title: 'Your first name',
    name: 'firstName',
    type: 'text',
    required: true,
  },
  {
    title: 'Last name',
    name: 'lastName',
    type: 'text',
    required: true,
  },
  {
    title: 'Middle name',
    name: 'middleName',
    type: 'text',
  },
  {
    title: 'Document series',
    name: 'documentSeries',
    type: 'text',
  },
  {
    title: 'Document number',
    name: 'documentNumber',
    type: 'text',
    required: true,
  },
  {
    title: 'E-mail',
    name: 'email',
    type: 'text',
    validator: emailValidator,
    required: true,
  },
  {
    name: 'city',
    type: 'city',
  },
  {
    name: 'office',
    type: 'office',
  },
  {
    title: 'Insert symbols from picture',
    name: 'captcha',
    type: 'captcha',
  },
  {
    type: 'hidden',
    name: '_captcha_key',
    value: 'simOrderInternational',
  },
];

class GetSimCard extends Component {
  captchaUrl = '';

  constructor(props) {
    super(props);

    let region = 'moskva';
    const { offices } = props.data;

    if (offices && offices.regions.findIndex((item) => item.code === props.currentRegion) !== -1) {
      region = props.currentRegion;
    }

    this.state = {
      validationKey: '',
      step: 'ready',
      serverError: false,
      showCaptcha: false,
      office: null,
      region,
    };
  }

  getRegions = () =>
    this.props.data.offices.regions.map(({ code, title }) => ({ label: title, value: code }));

  getPoints = () =>
    this.props.data.offices.points.filter((item) => item.region === this.state.region);

  triggerForm = () => {
    if (this.state.step === 'ready') {
      window.dataLayer.push({
        event: 'GA_event',
        eventCategory: 'EngClick',
        eventAction: 'OrderDelivery',
        eventLable: window.location.href,
      });
    }
    this.setState({ step: this.state.step === 'ready' ? 'form' : 'ready' });
  };

  submitForm = (event) => {
    event.preventDefault();
    this.setState({ step: 'pending', serverError: false });

    const { regions } = this.props.data.offices;

    const payload = {
      regionId: (regions.find((item) => item.code === this.state.region) || {}).id,
    };
    const { elements } = event.target;

    // 0 - valid, 1 - invalid
    const validationKey = formItems.reduce((result, item) => {
      const value = elements[item.name] && elements[item.name].value;
      payload[item.name] = value;
      if (item.validator && item.validator(value).status !== 'ok') {
        return result += 1; // eslint-disable-line
      }
      return (result += (item.required && !value) ? 1 : 0); // eslint-disable-line
    }, '');

    if (validationKey.indexOf(1) > -1) {
      this.setState({ validationKey, step: 'form' });
      return;
    }

    const GAData = {
      event: 'event_newsim_order_',
      ordered_itemProduct: 'Мобильная связь',
      ordered_itemCat: 'Tariffs',
      ordered_itemMethod: 'Sim',
    };

    const handleError = () => {
      this.setState({ step: 'form', validationKey: '', serverError: true });
      GAData.event += 'error';
      window.dataLayer.push(GAData);
    };

    axios
      .post('./ReserveTariff', qs.stringify(payload))
      .then(({ data: { orderId, isSucceeded, isCaptchaRequired, isCaptchaError } }) => {
        if (isSucceeded) {
          this.setState({ step: 'success' });
          GAData.event += 'success';
          window.dataLayer.push({ ...GAData, orderId });
          return;
        }
        if (isCaptchaRequired || isCaptchaError) {
          this.captchaUrl = `/captcha.ashx?w=122&h=36&key=simOrderInternational&r=${Math.random()}`;
          this.setState({
            validationKey,
            showCaptcha: true,
            step: 'form',
          });
        } else {
          handleError();
        }
      })
      .catch(handleError);
  };

  chooseRegion = (region) => {
    this.setState({ region, office: null });
  };

  chooseOffice = (office) => {
    this.setState({ office });
  };

  renderOffices = () => {
    const { offices } = this.props.data;

    if (!offices) return null;

    return (
      <div className={cx('tile')}>
        <div className={cx('title')}>{offices.title}</div>
        <div className={cx('regionSelector')}>
          <DropdownCustom
            onChange={this.chooseRegion}
            options={this.getRegions()}
            placeholder="Choose your city"
            value={this.state.region}
          />
        </div>
        {this.getPoints().map(AddressLink)}
        {offices.moreText && (
          <div className={cx('moreOffices')}>
            {offices.moreText}{' '}
            <Link href={offices.moreLinkUrl} target="_blank">
              {offices.moreLinkText}
            </Link>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { region, office, step, validationKey, serverError, showCaptcha } = this.state;
    const { delivery } = this.props.data;

    return (
      <div className={cx('component')}>
        {delivery && (
          <div
            className={cx('tile', 'delivery')}
            id="deliveryBanner"
            onClick={this.triggerForm}
            role="button"
            tabIndex="0"
          >
            <div className={cx('title')}>{delivery.heading}</div>
            <img alt="" className={cx('deliveryIcon')} src={delivery.icon} />
            <div className={cx('subTitle')}>{delivery.title}</div>
            <Button transformer>{delivery.button}</Button>
          </div>
        )}
        <PopupStateless onClose={this.triggerForm} opened={step !== 'ready'}>
          {(step === 'form' || step === 'pending') && (
            <OrderForm
              agreementText={delivery.agreement}
              buttonText="Make order"
              captcha={showCaptcha && this.captchaUrl}
              chooseOffice={this.chooseOffice}
              chooseRegion={this.chooseRegion}
              formItems={formItems}
              formTitle="Order delivery"
              getPoints={this.getPoints}
              getRegions={this.getRegions}
              office={office}
              onSubmit={this.submitForm}
              region={region}
              serverError={serverError}
              step={step}
              validationKey={validationKey}
            />
          )}
          {step === 'success' && (
            // eslint-disable-next-line max-len
            <Text data="<h1>Done!</h1><h4>Your request is sent. Don't forget to check your e-mail — we'll contact you soon!</h4>">
              <Button
                className="big"
                onClick={() => {
                  this.setState({ step: 'ready' });
                }}
                transformer
              >
                OK
              </Button>
            </Text>
          )}
        </PopupStateless>
        {this.renderOffices()}
      </div>
    );
  }
}

GetSimCard.propTypes = {
  data: PropTypes.shape({
    offices: PropTypes.shape({
      regions: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
      points: PropTypes.arrayOf(
        PropTypes.shape({
          region: PropTypes.string,
        }),
      ),
    }),
    delivery: PropTypes.shape({
      title: PropTypes.string,
      heading: PropTypes.string,
      icon: PropTypes.string,
      button: PropTypes.string,
      agreement: PropTypes.string,
    }),
  }),
  currentRegion: PropTypes.string,
};

export default GetSimCard;
