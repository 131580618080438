import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

// Utils
import { ymPushParams } from 'utils/ym';
// Analytics
import { ymOrderTariffUpFormBidView } from 'pages/FTTB2021/components/OrderTariffFormPopup/analytics';

// Constants
import { DEFAULT_ACTIVE_TAB, NOT_AVAILABLE_SETTINGS } from './constants';
// Components
import { Heading } from './Heading';
import { InfoBlock } from './InfoBlock';
import { ButtonsBlock } from './ButtonsBlock';
// Types
import { ConvergenceBlockProps } from './types';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ConvergenceBlock: FC<ConvergenceBlockProps> = ({
  activePresetTab,
  content,
  presets,
  activeTab,
  archiveUrl,
  userNumber,
  userAddress,
  handleClick,
  description,
  needForRetry,
  isConvergence,
  isChangeTariff,
  isAuthenticated,
  isCheckedConvergence,
  homeInternetOptionsSum,
  isShowAvailableSettings,
  hasRequestConvergenceError,
  isAvailableConstructorConvergence,
  needVerificationInternetOptionsSum,
  discountDescription,
  isSimOrderAvailableFttb,
  userBlockDiscountText,
  isOldConvergence,
  fttbPresetConnectedDiscount,
}) => {
  const [tariffPrice, setTariffPrice] = useState<null | number>(null);

  const isSimOrderForm = activeTab === 'SimOrderForm';
  const isMNPForm = activeTab === 'MNPForm';

  const baseShowCondition = !isConvergence && !isMNPForm && isShowAvailableSettings;

  const showUserBlock = !!(userNumber && userAddress && baseShowCondition);
  const showTabs = !!(
    !userNumber &&
    !userAddress &&
    !!presets?.length &&
    baseShowCondition &&
    isCheckedConvergence &&
    !archiveUrl
  );

  const price = homeInternetOptionsSum + needVerificationInternetOptionsSum;
  const unit: string = content?.unit || '';
  const colorDescription = hasRequestConvergenceError ? 'scarlet' : 'grey60';
  const isUserDiscount = !isShowAvailableSettings && !isMNPForm && discountDescription;

  useEffect(() => {
    if ((price === 0 && fttbPresetConnectedDiscount) || price > 0) {
      setTariffPrice(price);
    }
  }, [price, fttbPresetConnectedDiscount]);

  useEffect(() => {
    ymPushParams({
      fmc_ev_: {
        fmc_form_view: showTabs ? presets[DEFAULT_ACTIVE_TAB]?.title : NOT_AVAILABLE_SETTINGS,
      },
    });
  }, []);

  useEffect(() => {
    if (showUserBlock) {
      ymPushParams({ fmc_ev_: { fmc_form_on: [price, unit].join(' ') } });
    }
  }, [showUserBlock]);

  useEffect(() => {
    if (!isAvailableConstructorConvergence) {
      ymOrderTariffUpFormBidView({ fmc_form_off: description });
    }
  }, [isAvailableConstructorConvergence, showUserBlock]);

  if (isSimOrderAvailableFttb && isSimOrderForm) {
    return null;
  }

  return (
    <div className={cx('container')}>
      <Heading
        price={tariffPrice}
        successIcon={content?.successIcon}
        title={content?.title}
        tvIcon={content?.tvIcon}
        unit={unit}
        {...{ isAuthenticated, isConvergence, isChangeTariff }}
      />
      <InfoBlock
        addressTitle={content?.addressTitle}
        discountDescription={!isMNPForm && showTabs && discountDescription}
        discountText={isUserDiscount && userBlockDiscountText}
        phoneTitle={content?.phoneTitle}
        {...{
          userNumber,
          userAddress,
          description,
          showUserBlock,
          colorDescription,
          isSimOrderAvailableFttb,
          isOldConvergence,
        }}
      />
      <ButtonsBlock
        {...{
          activePresetTab,
          showTabs,
          presets,
          content,
          archiveUrl,
          handleClick,
          needForRetry,
          isCheckedConvergence,
          isShowAvailableSettings,
          hasRequestConvergenceError,
          isOldConvergence,
        }}
      />
    </div>
  );
};
