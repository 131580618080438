import React from 'react';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PhoneIcon = ({ className }) => (
  <div className={cx('wrapper', className)}>
    <div className={cx('phoneIconWrapper')}>
      <Icon className={cx('phoneIcon')} name="phoneIconWithShadow" />
    </div>
  </div>
);

export default PhoneIcon;
