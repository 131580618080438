import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { Link, Text } from '@beef/ui-kit';

import { TrustPaymentModuleButton } from 'pages/Services/TrustPayment/components/TrustPaymentModuleButton';
import { ServiceStatus } from 'pages/Services/ScopeZeroBalanceServices/components/ServiceStatus';
import { URL_CONFIG } from 'pages/Services/ScopeZeroBalanceServices/constants';

import { TrustPaymentContext } from '../../context';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const BannerDetailsNode = () => {
  const {
    isConnected,
    content: {
      isAvailableToConnect,
      isPopupWidgetNewEnabled = true,
      banner: { connectedText, title, text },
    },
  } = useContext(TrustPaymentContext);

  return (
    <div className={cx('container')}>
      <ServiceStatus isConnected={isConnected} text={connectedText} />
      {title && (
        <Text color="grey95" size="size0-b" tagType="h1">
          {title}
        </Text>
      )}
      <div className={cx('text')}>
        <Text color="grey60" size="size4-r">
          {text}
        </Text>
      </div>
      <div>
        {isPopupWidgetNewEnabled ?
          <TrustPaymentModuleButton
            disabled={!isAvailableToConnect}
            size="m"
            text="Подключить"
            variant="main"
          />
        : <Link
            buttonSize="m"
            buttonVariant="main"
            disabled={!isAvailableToConnect}
            href={URL_CONFIG.redirectUrl}
            size="size5-m"
            variant="button"
          >
            Подключить
          </Link>
        }
      </div>
    </div>
  );
};
