import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import globalStore from 'store';
import { usePrevious } from 'utils/hooks/usePrevious';

import { TMnpValidateErrors } from '../../../store/slices/commonContentSlice/types';
import { useDisableNext } from '../../../hooks/disabledNextHooks/useDisableNext';
import { EDisableNextEvent } from '../../../store/slices/stepperSlice/types';
import { TValidateMnpRes, validateMnp } from '../../../api/validateMnp';
import { setUserPhoneNumber } from '../../../store/slices/totalSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { errorsType } from '../constants';
import {
  VALID_PHONE_LENGTH,
  getErrorStatus,
  getOnlyDigitsPhone,
  isFilledPhone,
  isNumberBought,
} from '../utils';

/** Хук для работы с данными страницы mnp.
 * Валидирует номер телефона и возвращает ошибки валидации.
 * Обрабатывает сохранение номера и дисейбла кнопки подключения в сторе */
export const useMmpContainerData = () => {
  const dispatch = useAppDispatch();
  const { disableNext, enableNext } = useDisableNext(EDisableNextEvent.mnpValidate);
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [helpMessage, setHelpMessage] = useState('');
  const { mnpValidateErrors, inputHelpMessage } =
    useAppSelector((state) => state.common?.content?.mnpPage) || {};
  const boughtNumbers = globalStore.getState().external.cartData?.currentCart?.numbers ?? [];
  const prevPhone = usePrevious(getOnlyDigitsPhone(phone));

  const handleError = useCallback((errors: keyof typeof errorsType) => {
    setErrorMessage(getErrorStatus(errors, mnpValidateErrors as TMnpValidateErrors));
  }, []);

  useEffect(() => {
    /* Устанавливаем дисейбл кнопки и сбрасываем mnp номер */
    disableNext();
    dispatch(setUserPhoneNumber(null));
    return () => {
      /* Сбрасываем mnp номер дисейбл кнопки */
      enableNext();
    };
  }, []);

  useEffect(() => {
    let promise: any = null;
    const validateRequest = () => {
      /* Запрос валидации номера */
      promise = dispatch(validateMnp({ phone: getOnlyDigitsPhone(phone) }));
      promise
        ?.unwrap()
        .then((data: TValidateMnpRes) => {
          /* Ошибка неуспешного запроса */
          if (!data.IsSucceeded) {
            handleError(errorsType.notSuccess);
            return;
          }
          /* Ошибка номер существует */
          if (data.View?.IsExistingCtn) {
            handleError(errorsType.isExistingCtn);
            return;
          }
          /* Записываем номер телефона в стор */
          dispatch(setUserPhoneNumber(getOnlyDigitsPhone(phone)));
          /* Разблокируем кнопку в этом случае */
          enableNext();
        })
        .catch((e: Error) => {
          if (e.message !== 'Aborted') {
            handleError(errorsType.failedRequest);
          }
        })
        .finally(() => setHelpMessage(''));
    };

    const prepareValidateRequest = () => {
      const phoneFilled = isFilledPhone(phone);
      setErrorMessage('');

      /* Используем если уже были введены 10 цифр номера и удалена хотя бы одна цифра */
      if (prevPhone?.length === VALID_PHONE_LENGTH) {
        disableNext();
        dispatch(setUserPhoneNumber(null));
      }

      /* Выход если номер заполнен не полностью */
      if (!phoneFilled) return;

      /* Ошибка, если номер уже в корзине */
      if (isNumberBought(boughtNumbers, getOnlyDigitsPhone(phone))) {
        handleError(errorsType.isCtnAlreadyInBasket);
        return;
      }

      /* Устанавливается сообщение о проверке номера */
      setHelpMessage(inputHelpMessage as string);
      validateRequest();
    };

    prepareValidateRequest();
    return () => {
      promise?.abort();
    };
  }, [phone]);

  const onChange = useCallback(
    (e: ChangeEvent) => setPhone((e.target as HTMLTextAreaElement).value),
    [],
  );

  return {
    phone,
    errorMessage,
    onChange,
    helpMessage,
  };
};
