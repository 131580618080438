import React, { useState } from 'react';
import { ImageCard } from '@beef/smart-kit';
import { Text } from '@beef/ui-kit';
import classnames from 'classnames/bind';

import { VideoModal } from './VideoModal';
import { CardPlaceholder } from '../CardPlaceholder';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const VideoCards = ({ title, videos }) => {
  const [currentVideo, setCurrentVideo] = useState(null);

  return (
    <>
      <Text size="size1-m" tagType="h2">
        {title}
      </Text>
      <div className={cx('grid')}>
        {videos.map((video, index) =>
          video.type === 'placeholder' ?
            <CardPlaceholder image={video.image} key={index} />
          : <div
              className={cx('card')}
              key={index}
              onClick={() => setCurrentVideo(index)}
              role="button"
              tabIndex="0"
            >
              <ImageCard bordered={false} img={video.img} size="s" title={video.title} />
            </div>,
        )}
      </div>
      {currentVideo !== null && (
        <VideoModal {...videos[currentVideo]} onClose={() => setCurrentVideo(null)} />
      )}
    </>
  );
};
