import { getActiveCharacter } from '../../../utils';
import { TCharacter } from './types';

// TODO: общая data конструктора не типизирована!
/** Функция получения дефолтного значения типа пресета */
export const getDefaultHeightPreset = (data: any) =>
  data?.layoutData?.defaultInfo?.isHighPreset || false;

/** Функция получения текущего текста пассивки в зависимости от типа пресета */
const chosePassiveText = (
  lowPassiveText: string,
  highPassiveText: string,
  isHighPreset: boolean,
) => (isHighPreset ? highPassiveText : lowPassiveText);

/** Функция-маппер для получения пассивок по выбранному персонажу */
// eslint-disable-next-line max-len
export const getActiveCharacterPassive = (
  characters: TCharacter[],
  activeOptionSoc: string,
  isHighPreset: boolean,
) => {
  /** Получаем текущего активного персонажа */
  const activeCharacter = getActiveCharacter(characters, activeOptionSoc);
  const { type, imageSrc, activeColor: backgroundColor, power, highPower } = activeCharacter || {};

  /**  Default-таб для компонента PassiveTabs. Основывается на типе (type) персонажа */
  const selectedType = String(type);

  /**  Теста для высшей и нисшей пассивки определяются как description способностей */
  const lowPassiveText = power?.description;
  const highPassiveText = highPower?.description;

  /** Текущий текст пресета */
  const passiveText = chosePassiveText(lowPassiveText, highPassiveText, isHighPreset);

  /** Общие пропы для компонента PassiveContent в Passive */
  const passiveContentProps = { imageSrc, passiveText };

  return { selectedType, backgroundColor, passiveContentProps };
};

/** Ф-я для проверки соответствия эдемента soc в массиве "socs" элементам в массиве "tariffOptions"
 * если соответствие найдено, возвращается true */
export const checkSocsInTariffOptions = (socs: string[], tariffOptions: any[]) => {
  if (!tariffOptions?.length || !socs?.length) {
    return false;
  }
  return socs.some((soc) => {
    return tariffOptions.some((item) => {
      if (!item.subOptions?.length) {
        return soc === item.soc;
      }
      return item.subOptions?.some((i: Record<string, any>) => soc === i.soc);
    });
  });
};

/** Репрайс 02.24 тариф Up аз BCP-18360. Новый костыль из-за введения нулевых пресетов
 *  Проверяет наличие выбранныех соков в множестве доступных к подключению */
export const checkChosenSocs = (socs: string[], tariffOptions: any[]) => {
  if (!tariffOptions?.length || !socs?.length) {
    return [];
  }

  return socs.reduce((accSocs: string[], soc: string) => {
    const availableSocs = tariffOptions.reduce((acc, item) => {
      if (!item.subOptions?.length && soc === item.soc) {
        return [...acc, soc];
      }
      const currentSoc = item.subOptions?.find((i: Record<string, any>) => soc === i.soc)?.soc;
      if (currentSoc) {
        return [...acc, currentSoc];
      }
      return acc;
    }, []);

    return [...availableSocs, ...accSocs];
  }, []);
};
