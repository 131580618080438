import { useCallback, useEffect, useState } from 'react';

// Constants
import { ymOrderTariffAddressView } from 'pages/FTTB2021/components/OrderTariffFormPopup/analytics';
import { TARIFF_TYPES } from 'pages/FTTB2022/CatalogPage/deprecated/store/blocks/constants';

import { REQUEST_STATUS } from '../constants';

/* eslint-disable max-len */
/**
 * useFetchRegion: обрабатывает полученные данные после запроса регионов через getCitySearchResults.
 * @param region
 * @param resetInputs
 * @param setChosenRegion
 * @param setAddressChecked
 * @param resetSettingsTariff
 * @returns {{onSelectRegionFromList: onSelectRegionFromList, handleChangeRegionInput: (function(*): void), regionValue: unknown}}
 */
/* eslint-enable max-len */
export const useFetchRegion = ({
  region,
  resetInputs,
  setChosenRegion,
  setAddressChecked,
  resetSettingsTariff,
}) => {
  const [regionValue, setRegionValue] = useState(region.label || '');

  /**
   * handleChangeRegionInput: управление изменением инпутом региона.
   * @param value
   */
  const handleChangeRegionInput = useCallback(
    (value) => {
      resetSettingsTariff();
      setRegionValue('');
      resetInputs();

      if (value) {
        setRegionValue(value);
        setChosenRegion(regionValue);
        ymOrderTariffAddressView({ fmc_address_fill_field: 'city' }, TARIFF_TYPES.presetUp);
      }
    },
    [region, regionValue],
  );

  /**
   * onSelectRegionFromList: управление данными из выпадающего списка поиска.
   * @param value
   */
  const onSelectRegionFromList = (value) => {
    resetInputs();

    if (!value?.slug) {
      setAddressChecked(REQUEST_STATUS.regionIsNotFound);
    }

    const id = +value.slug;
    const label = value?.label;

    setChosenRegion({ id, label });
  };

  useEffect(() => {
    if (!region.id) {
      setAddressChecked(REQUEST_STATUS.regionIsNotFound);
    }
  }, [regionValue]);

  return { regionValue, onSelectRegionFromList, handleChangeRegionInput };
};
