export const INFINITY_CONSTANT = 100500;
export const keyUpCodes = 'ArrowUp,ArrowDown,ArrowLeft,ArrowRight';

export const CONSTRUCTOR_TABS = {
  SimOrderForm: 'SimOrderForm',
  MNPForm: 'MNPForm',
  ChangeTariffForm: 'ChangeTariffForm',
};

export const POLLING_STATUS = {
  error: 'ERROR',
  progress: 'IN_PROGRESS',
  complete: 'COMPLETE',
};

export const roamingYmEvents = {
  prePayment: {
    type: 'prepayment',
    faqClick: 'pre_link_click',
  },
  postPayment: {
    type: 'postpayment',
    faqClick: 'post_faq_click',
    buttonClick: 'post_button_click',
    authFillForm: 'post_auth_fill_form',
    getPassword: 'post_auth_get_password',
    authSuccess: 'post_auth_success',
    requestFailed: 'post_request_failed',
    requestSuccess: 'post_success_page',
  },
  prolongSpeed: {
    type: 'speed_page',
    faqClick: 'speed_faq_click',
  },
  sms20: {
    type: '20sms_page',
    faqClick: '20sms_faq_click',
    buttonClick: '20sms_button_click',
    authFillForm: '20sms_auth_fill_form',
    getPassword: '20sms_auth_get_password',
    authSuccess: '20sms_auth_success',
    requestFailed: '20sms_request_failed',
    requestSuccess: '20sms_success_page',
    applyService: '20sms_apply_service',
  },
  notifications: {
    type: 'notifications_page',
    faqClick: 'notifications_faq_click',
    buttonClick: 'notifications_button_click',
    phoneClick: 'notifications_phone_click',
    authFillForm: 'notifications_auth_fill_form',
    getPassword: 'notifications_auth_get_password',
    authSuccess: 'notifications_auth_success',
    requestFailed: 'notifications_request_failed',
    requestSuccess: 'notifications_success_page',
    applyService: 'notifications_apply_service',
  },
  roaming: {
    type: 'notifications_page',
    faqClick: 'notifications_faq_click',
  },
  roamingCrimea: {
    type: 'crimea_page',
    faqClick: 'crimea_faq_click',
    buttonClick: 'crimea_button_click',
    toUp: 'crimea_to_up_tariff',
    calculationPhone: 'crimea_calculation_phone',
    calculationTariff: 'crimea_calculation_tariff',
  },
  package2GbCrimea: {
    type: 'crimea2gb_page',
    faqClick: 'crimea2gb_faq_click',
    buttonClick: 'crimea2gb_button_click',
    authFillForm: 'crimea2gb_auth_fill_form',
    getPassword: 'crimea2gb_auth_get_password',
    authSuccess: 'crimea2gb_auth_success',
    requestFailed: 'crimea2gb_request_failed',
    requestSuccess: 'crimea2gb_success_page',
    applyService: 'crimea2gb_apply_service',
  },
  package5GbCrimea: {
    type: 'crimea5gb_page',
    faqClick: 'crimea5gb_faq_click',
    buttonClick: 'crimea5gb_faq_click',
    authFillForm: 'crimea5gb_auth_fill_form',
    getPassword: 'crimea5gb_auth_get_password',
    authSuccess: 'crimea5gb_auth_success',
    requestFailed: 'crimea5gb_request_failed',
    requestSuccess: 'crimea5gb_success_page',
    applyService: 'crimea5gb_apply_service',
  },
};

export const TARIFF_WRONG_REGION_API = '/customers/products/mobile/tariffs/GetWrongRegionPopup/';
export const SERVICE_WRONG_REGION_API = '/customers/products/mobile/services/GetWrongRegionPopup/';
