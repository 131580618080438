import React from 'react';
import PropTypes from 'prop-types';
import { Button, SmartForm, SmartInput, Tab, Tabs, TabsList } from '@beef/legacy-ui-kit';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import { ECaptchaKeys, InvisibleCaptcha } from '@beef/captcha';

import PlainContainer from 'pages-components/PopupContainer/PlainContainer';
import SimpleParameter from 'widgets/FancyNumberOrderPopup/SimpleParameter';
import { generateCaptchaErrorText } from 'pages/Maps/components/OrderPopup/components/OrderSimForm/utils';
import Captcha from 'pages/Feedback/components/Captcha/Captcha';

import CollapseButton from '../CollapseButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const OrderSimForm = ({
  setActiveTariffTab,
  toggleTariffsTabs,
  showTariffs,
  tariffs,
  onInputChange,
  title,
  collapseButtonName,
  startBalanceUnitText,
  serverErrors,
  startBalancePriceText,
  startBalancePrice,
  onCaptchaChange,
  tariffDetails,
  buttonText,
  onFormSubmit,
  captchaStatus,
  onChangeCaptchaStatus,
  handleOldCaptchaChange,
  captchaUrl,
  captchaRequired,
  smartCaptchaEnabled,
}) => {
  const [contactNumber, changeContactNumber] = React.useState('+7');

  const captchaTextInformation = React.useMemo(() => {
    return generateCaptchaErrorText(serverErrors.captcha, captchaStatus);
  }, [serverErrors.captcha, captchaStatus]);

  return (
    <PlainContainer heading={title}>
      <div className={cx('collapseContent')}>
        <SimpleParameter
          title={<CollapseButton onClick={toggleTariffsTabs} text={collapseButtonName} />}
          titleReverse
          value={tariffDetails}
        />

        {showTariffs && (
          <Tabs onChange={setActiveTariffTab}>
            <TabsList className={cx('tabs')} type="radio">
              {tariffs.map((tariff) => (
                <Tab className={cx('tab')} id={tariff.id} key={tariff.id}>
                  <div>
                    <Text color="grey95" size="size6-r">
                      {tariff.name}
                    </Text>
                  </div>
                  <div>
                    <Text color="grey95" size="size6-r">
                      {tariff.description}
                    </Text>
                  </div>
                </Tab>
              ))}
            </TabsList>
          </Tabs>
        )}
      </div>

      <div className={cx('total')}>
        <Text color="grey95" size="size5-r-s">
          {startBalancePriceText}
        </Text>
        {startBalancePrice && (
          <div className={cx('price')}>
            <Text color="grey95" size="size6-r">
              {`${startBalancePrice} ₽`}
            </Text>
            {startBalanceUnitText && (
              <Text color="grey95" size="size6-r">
                {` ${startBalanceUnitText}`}
              </Text>
            )}
          </div>
        )}
      </div>

      <SmartForm
        onSubmit={onFormSubmit}
        validator={{
          phoneInput: (value) => ({
            status: value.length !== 16 ? 'fail' : 'ok',
          }),
        }}
      >
        <div className={cx('form', { withCaptcha: captchaRequired && !smartCaptchaEnabled })}>
          <SmartInput
            className={cx('input')}
            inputId="phoneInput"
            keepInitialValue
            mask="+7 ___ ___ __ __"
            onChange={(value) => {
              changeContactNumber(value);
              onInputChange(value);
            }}
            size="big"
            value={contactNumber}
          />

          {!smartCaptchaEnabled ?
            captchaRequired && (
              <div className={cx('captchaWrapper')}>
                <Captcha
                  captchaKey="fancynumber-order-form"
                  captchaUrl={captchaUrl}
                  forceTouch={serverErrors.captcha}
                  inputId="Captcha"
                  name="Captcha"
                  onChange={handleOldCaptchaChange}
                />
                <SmartInput
                  inputId="_captcha_key"
                  name="_captcha_key"
                  type="hidden"
                  value="fancynumber-order-form"
                />
              </div>
            )
          : <div className={cx('yandexCaptchaWrapper')}>
              <InvisibleCaptcha
                clientKey={ECaptchaKeys.B2CMaps}
                onChangeStatus={onChangeCaptchaStatus}
                refresh={serverErrors.captcha}
                setToken={onCaptchaChange}
              />
            </div>
          }

          <Button className={['big']} transformer>
            {buttonText}
          </Button>
        </div>
        {captchaTextInformation && <p className={cx('errorText')}>{captchaTextInformation}</p>}
      </SmartForm>
    </PlainContainer>
  );
};

OrderSimForm.propTypes = {
  setActiveTariffTab: PropTypes.func,
  toggleTariffsTabs: PropTypes.func,
  showTariffs: PropTypes.bool,
  tariffs: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  tariffDetails: PropTypes.string,
  collapseButtonName: PropTypes.string,
  buttonText: PropTypes.string,
  captchaStatus: PropTypes.oneOf(['javascript-error', 'success', 'declined', 'network-error']),
  startBalancePrice: PropTypes.number,
  startBalancePriceText: PropTypes.string,
  startBalanceUnitText: PropTypes.string,
  onInputChange: PropTypes.func,
  serverErrors: PropTypes.shape({
    captcha: PropTypes.bool,
  }),
  onCaptchaChange: PropTypes.func,
  onFormSubmit: PropTypes.func,
  onChangeCaptchaStatus: PropTypes.func,
  captchaRequired: PropTypes.bool,
  smartCaptchaEnabled: PropTypes.bool,
  captchaUrl: PropTypes.string,
  handleOldCaptchaChange: PropTypes.func,
};

export default OrderSimForm;
