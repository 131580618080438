import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FlatButton = ({ connected, buttonText, onClick, isLight, className }) => (
  <EmptyButton
    className={cx('bottomButton', 'clickable', className, { connected, isLight })}
    onClick={onClick}
  >
    {buttonText}
    <Icon className={cx('arrow')} name="arrowDown" />
  </EmptyButton>
);

FlatButton.propTypes = {
  className: PropTypes.string,
  connected: PropTypes.bool,
  isLight: PropTypes.bool,

  buttonText: PropTypes.node,
  onClick: PropTypes.func,
};

export default FlatButton;
