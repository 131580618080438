import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Стандартный параметр со значением и описанием/единицей измерения;
 * @param icon - Ссылка на иконку параметра;
 * @param iconName - Имя иконки;
 * @param value - Значение;
 * @param unit - единицей измерения;
 * @param parameterType - Тип параметра;
 * @param widgetType - Тип виджета;
 * @param bottomIcon - Иконка под параметрами;
 * @param bottomIconName - Имя иконки под параметрами;
 * @param extraParams - Дополнительные параметры с иконкой, выводятся в одной строке с параметром через знак +;
 * @param className - Дополнительные классы для обертки;
 * @param iconAbsoluteUrl - Выравнивание по центру иконки, считаем, что любая иконка с абсолютным url
 * должна выравниваться по центру;
 * @param paramsGridView - Для отображения в виде сетки в карточке тарифа;
 * @param containerClass - доп класс для контейнера;
 */
const Parameter = ({
  icon,
  iconName,
  value,
  unit,
  parameterType,
  widgetType,
  bottomIcon,
  bottomIconName,
  extraParams,
  className,
  iconAbsoluteUrl,
  containerClass,
  paramsGridView,
  isYandex,
}) => {
  const yandexType = widgetType === 'yandex-plus-subscription';
  return (
    <div className={cx('parameter', className, { parameter__yandex: yandexType })}>
      <div
        className={cx('container', parameterType || widgetType, containerClass, {
          [iconName]: !iconAbsoluteUrl && iconName,
          'with-image': extraParams,
          'absolute-url': iconAbsoluteUrl,
          'params-grid': paramsGridView,
          'params-center': paramsGridView && icon,
        })}
      >
        <div className={cx('box', { 'with-icon': icon, 'yandex-box': yandexType })}>
          {icon && (
            <Picture
              alt={iconName}
              className={cx('icon', {
                [iconName]: !iconAbsoluteUrl && iconName,
                yandex__icon: yandexType,
              })}
              src={icon}
            />
          )}
          {value && widgetType !== 'yandex-plus-subscription' && (
            <span className={cx('value')} dangerouslySetInnerHTML={{ __html: value }} />
          )}
        </div>
        {yandexType && value && (
          <div className={cx('yandex-price')}>
            <span className={cx('value')} dangerouslySetInnerHTML={{ __html: value }} />
          </div>
        )}
        {(unit || bottomIcon) && (
          <div>
            {unit && (
              <span
                className={cx('unit', {
                  'grid-icon': !!paramsGridView && icon,
                  'yandex-unit': yandexType,
                  'unit__yandex-indent': isYandex,
                })}
                dangerouslySetInnerHTML={{ __html: unit }}
              />
            )}
            {bottomIcon && (
              <Picture alt={bottomIconName} className={cx('bottom-icon')} src={bottomIcon} />
            )}
          </div>
        )}

        {extraParams && (
          <div className={cx('extra-params')}>
            <div className={cx('extra-icon')}>
              <Picture alt={extraParams.iconName} src={extraParams.icon} />
            </div>
            <div
              className={cx('iconUnitText')}
              dangerouslySetInnerHTML={{ __html: extraParams.iconUnit }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Parameter.defaultProps = {
  className: '',
  iconAbsoluteUrl: false,
  paramsGridView: false,
};

Parameter.propTypes = {
  icon: PropTypes.string,
  iconName: PropTypes.string,
  value: PropTypes.string,
  unit: PropTypes.string,
  parameterType: PropTypes.string,
  widgetType: PropTypes.string,
  bottomIcon: PropTypes.string,
  bottomIconName: PropTypes.string,
  className: PropTypes.string,
  containerClass: PropTypes.string,

  paramsGridView: PropTypes.bool,
  iconAbsoluteUrl: PropTypes.bool,

  extraParams: PropTypes.object,
};

export default Parameter;
