import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { Tab, Tabs, TabsList } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import Icon from 'pages-components/Icon';

import SimpleParameter from '../SimpleParameter';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CollapseButton = ({ text, onClick, rotated }) => (
  <EmptyButton className={cx('dropdownButton')} onClick={onClick}>
    {text}
    <Icon className={cx('dropdownArrow', { rotated })} name="dropdownArrow" />
  </EmptyButton>
);

CollapseButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  rotated: PropTypes.bool,
};

const FancyNumberWithTariff = ({
  tariffs,
  tariffIndex,
  price,
  isSoleTariff,
  numberToHtml,
  categoryName,
  tariffTitle,
  tariffPriceTitle,
  tariffPriceUnit,
  lookTariffText,
  changeTariffText,
  onChange,
  deliveryFormComponent,
}) => {
  const [showTariffs, toggleTariffsShown] = useState(false);
  const [showTariffDescription, toggleTariffDescriptionShown] = useState(false);
  const currentTariff = tariffs[tariffIndex];
  const collapseButtonProps =
    isSoleTariff ?
      {
        onClick: () => {
          toggleTariffDescriptionShown(!showTariffDescription);
        },
        rotated: showTariffDescription,
        text: lookTariffText,
      }
    : {
        onClick: () => {
          toggleTariffsShown(!showTariffs);
        },
        rotated: showTariffs,
        text: changeTariffText,
      };

  const tariffName = tariffTitle.replace('{{TARIFFNAME}}', currentTariff.name);
  const tariffDescription = `${tariffName}. ${currentTariff.description}`;

  return (
    <>
      <SimpleParameter price={parseInt(price, 10)} value={`${categoryName} ${numberToHtml}`} />

      {(tariffs.length > 1 || showTariffs) && (
        <div className={cx('collapsedContainer')}>
          {tariffs.length > 1 && (
            <SimpleParameter
              title={<CollapseButton {...collapseButtonProps} />}
              titleReverse
              value={tariffDescription}
            />
          )}

          {showTariffs && (
            <Tabs activeTab={currentTariff.id}>
              <TabsList className={cx('tabs')} type="radio">
                {tariffs.map((tariff, index) => (
                  <Tab
                    className={cx('tab')}
                    id={tariff.id}
                    key={tariff.id}
                    onClick={() => {
                      onChange(index);
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: tariff.name }} />
                    <div dangerouslySetInnerHTML={{ __html: tariff.description }} />
                  </Tab>
                ))}
              </TabsList>
            </Tabs>
          )}
        </div>
      )}

      <div className={cx('totalPrice')}>
        <span className={cx('title')} dangerouslySetInnerHTML={{ __html: tariffPriceTitle }} />
        <div className={cx('price')}>
          <span>{`${currentTariff.startBalance} ₽`}</span>
          {tariffPriceUnit && <span>{` ${tariffPriceUnit}`}</span>}
        </div>
      </div>

      {deliveryFormComponent && <div className={cx('deliveryForm')}>{deliveryFormComponent}</div>}
    </>
  );
};

FancyNumberWithTariff.defaultProps = { initialTariffIndex: 0 };

FancyNumberWithTariff.propTypes = {
  tariffs: PropTypes.array,
  price: PropTypes.number,
  isSoleTariff: PropTypes.bool,
  numberToHtml: PropTypes.string,
  categoryName: PropTypes.string,
  freeText: PropTypes.string,
  tariffTitle: PropTypes.string,
  priceText: PropTypes.string,
  tariffPriceTitle: PropTypes.string,
  tariffPriceUnit: PropTypes.string,
  changeTariffText: PropTypes.string,
  lookTariffText: PropTypes.string,
  onChange: PropTypes.func,
  initialTariffIndex: PropTypes.number,
  category: PropTypes.string,
  deliveryFormComponent: PropTypes.any,
  tariffIndex: PropTypes.number,
};

export default FancyNumberWithTariff;
