import { TeSIMModal } from './types';

/** Функция определения контентных данных для попапов authModal/eSIMModal.
 * Выбираем нужное в зависимости от текущего активного таба */
export const defineModalContent = (
  authModal: TeSIMModal | undefined,
  eSIMModal: TeSIMModal | undefined,
  isChangeTariffFormTab: boolean,
) => {
  const content = isChangeTariffFormTab ? authModal : eSIMModal;
  return { ...content };
};
