import React, { ComponentProps, FC } from 'react';
import classNames from 'classnames/bind';
import { Accordion } from '@carnica/ui-kit';

import { DIMENSION_GROUPS, useGetDimensionGroup } from 'utils/hooks/useGetDimensionGroup';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TFaqData = {
  summary: string;
  text: string;
};

type TFaq = {
  data: TFaqData[];
};

const sizeMap: Record<DIMENSION_GROUPS, ComponentProps<typeof Accordion>['size']> = {
  [DIMENSION_GROUPS.desktop]: 'l',
  /* @ts-expect-error по всей видимости ошибочно не допускается размер m */
  [DIMENSION_GROUPS.tablet]: 'm',
  [DIMENSION_GROUPS.mobile]: 's',
};

/** Компонент Faq */
export const Faq: FC<TFaq> = ({ data }) => {
  const dimensionGroup = useGetDimensionGroup() || 'desktop';
  return (
    <div className={cx('component')}>
      {data?.map(({ summary, text }) => (
        <Accordion key={summary} label={summary} size={sizeMap[dimensionGroup]}>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Accordion>
      ))}
    </div>
  );
};
