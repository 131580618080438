import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Utils
import { Link, Text } from '@beef/ui-kit';
import { Preloader } from '@beef/legacy-ui-kit';

import { getStatusIcon } from 'utils/screenProtectionService';

// Components
import Picture from 'pages-components/Picture';

// Styles

import styles from './styles.pcss';
import Icon from '../../../../../components/Icon';

const cx = classNames.bind(styles);

const ProductParameters = ({
  loading,
  productParameters,
  statusIcon,
  infoText,
  warningText,
  errorText,
}) => {
  if (loading) {
    return (
      <div className={cx('preloader-wrap')}>
        <Preloader className={cx('preloader')} size="40" />
      </div>
    );
  }
  return (
    <div className={cx('params')}>
      {productParameters.map((param) => (
        <div className={cx('params__item')} key={param.label}>
          <div className={cx('params__label')}>
            {param.link ?
              <Link
                href={param.link}
                rightIcon={<Icon name="link" />}
                size="size5-r-s"
                target="_blank"
              >
                {param.label}
              </Link>
            : <Text size="size5-r-s">{param.label}</Text>}
          </div>
          <span className={cx('price')}>
            <Text size="size5-r-s">
              {param.label === 'Страховой полис' && (
                <Picture
                  className={cx('status-icon')}
                  src={getStatusIcon(param.value, statusIcon)}
                />
              )}
              {param.value} {param.unit}
            </Text>
          </span>
        </div>
      ))}
      <span
        className={cx('text', {
          'text--info': infoText,
          'text--warning': warningText,
          'text--error': errorText,
        })}
        dangerouslySetInnerHTML={{ __html: infoText || warningText || errorText }}
      />
    </div>
  );
};

ProductParameters.propType = {
  infoText: PropTypes.string,
  warningText: PropTypes.string,
  errorText: PropTypes.string,
  productParameters: PropTypes.array,
  statusIcon: PropTypes.object,
};

export default ProductParameters;
