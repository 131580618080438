import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TTitle = {
  title: string;
};

/** Заголовок попапа */
export const Title: FC<TTitle> = ({ title }) => (
  <Text className={cx('component')} size="size2-m">
    {title}
  </Text>
);
