import React from 'react';
import { connect } from 'react-redux';

import { LoadingWrap } from 'pages-components/LoadingWrap';

import { selectStoriesLoading } from '../../store/stories';
import { RatingLayout } from './RatingLayout';
import { Heading } from './Heading';
import { Chart } from './Chart';

const RatingComponent = ({ isLoading }) => (
  <RatingLayout
    chartElement={
      <LoadingWrap isLoading={isLoading} minHeight="200px">
        {!isLoading && <Chart />}
      </LoadingWrap>
    }
    headingElement={<Heading />}
  />
);

const mapStateToProps = (state) => ({
  isLoading: selectStoriesLoading(state),
});

export const Rating = connect(mapStateToProps)(RatingComponent);
