import React from 'react';
import classNames from 'classnames/bind';

import { usePrepareEditorProps } from './hooks/usePrepareEditorProps';
import { TitleContent } from '../../commonComponents/TitleContent';
import { ConnectionAddressEditor } from '../../../tempComponents/ConnectionAddressEditor';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Обертка над компонентом редактирования адреса подключения ШПД */
export const ConnectionAddressEditorContainer = () => {
  const { titleContentProps, addressEditorProps } = usePrepareEditorProps();

  return (
    <section className={cx('wrapper')}>
      <TitleContent {...titleContentProps} />
      <ConnectionAddressEditor {...addressEditorProps} />
    </section>
  );
};
