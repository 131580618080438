import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { getMiscContent } from '../../../../store/selectors/content';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

const MessageLayout = ({ children }) => (
  <span className={cx('message')}>
    <Text color="grey40" size="size4-r">
      {children}
    </Text>
  </span>
);

MessageLayout.propTypes = {
  children: PropTypes.node,
};

export const BoughtMessage = connect((state) => ({
  children: getMiscContent(state).reserved,
}))(MessageLayout);

export const InCartMessage = connect((state) => ({
  children: getMiscContent(state).inCart,
}))(MessageLayout);
