import { useContext, useState } from 'react';

import { ConstructorContext } from '../../../containers/ConstructorTariff/constants';

/** Подготовка данных для модального окна Passive */
export const usePrepareModalContent = () => {
  const [isOpen, setIsOpen] = useState(false);

  /** Через контекст достаем название кнопки для попап с информацией по пассивкам */
  const { passivePopups } = useContext(ConstructorContext);
  const { btnText } = passivePopups || {};

  /** Функция открытия модального окна для компонента ModalActionBar */
  const openModal = () => setIsOpen(true);

  /** Функция закрытия модального окна */
  const onClose = () => setIsOpen(false);

  /** Пропы для китового компонента Modal */
  const modalProps = { isOpen, onClose };

  /** Пропы для компонента ModalActionBar */
  const modalActionBarProps = { onClick: onClose, btnText };

  /** Общий проп для кмпонента  ModalContainer */
  const modalContainerProps = { modalProps, modalActionBarProps };

  return { openModal, modalContainerProps };
};
