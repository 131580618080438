import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from '@beef/legacy-ui-kit';

import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth();
  return `${day > 9 ? '' : 0}${day}.${month > 8 ? '' : 0}${month + 1}.${date.getFullYear()}`;
};

const resolutions = {
  pdf: 'PDF',
  docx: 'D0C',
  doc: 'D0C',
  xlsx: 'XLS',
  xls: 'XLS',
};

const Tile = ({ title, description, link, actionDate, publishDate, fileSize }) => {
  const fileType = resolutions[link.split('.').pop()];

  return (
    <div className={cx('tile')}>
      <div className={cx('publishDate')}>{formatDate(publishDate)}</div>
      <div className={cx('title')} dangerouslySetInnerHTML={{ __html: title }} />
      <div className={cx('description')} dangerouslySetInnerHTML={{ __html: description }} />
      <span className={cx('actionDate')}>{formatDate(actionDate)}</span>

      <div className={cx('document')}>
        <Icon name="documents" />
        <div className={cx('resolution')}>{fileType}</div>
        <Link href={link} target="_blank">
          Подробная информация
        </Link>
        <div className={cx('fileInfo')}>
          {fileType}
          {fileSize ? `, ${fileSize} КБ` : ''}
        </div>
      </div>
    </div>
  );
};

Tile.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  actionDate: PropTypes.string,
  publishDate: PropTypes.string,
  fileSize: PropTypes.string,
};

export default Tile;
