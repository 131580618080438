export type TPrepareTariffCardAnalyticProps = {
  authStatus: 'az' | 'nz';
  data: Record<string, any>;
  type: string;
};

/** Функция подготовки общих данных для аналитики карточки тарифа в каталоге */
export const tariffCardAnalyticsProps = ({
  data,
  type,
  authStatus,
}: TPrepareTariffCardAnalyticProps) => {
  return {
    tariff_catalog_mobile: {
      auth_status: authStatus,
      tariff_card: type,
      tariff_name: data.alias,
      title: data.title,
    },
  };
};
