import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { usePrepareErrorContent } from './hooks/usePrepareErrorContent';
import { ErrorTextContent } from './components/ErrorTextContent';
import { ErrorPicture } from './components/ErrorPicture';
import { ErrorButton } from './components/ErrorButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент ошибки */
export const CommonErrorBanner: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { buttonProps, contentProps, pictureProps, handlePushYm } = usePrepareErrorContent();

  /* Клик по кнопке обновить + отправка метрики */
  const handleClick = () => {
    onClick();
    handlePushYm();
  };

  return (
    <section className={cx('wrapper')}>
      <ErrorPicture {...pictureProps} />
      <ErrorTextContent {...contentProps} />
      <ErrorButton onClick={handleClick} {...buttonProps} />
    </section>
  );
};
