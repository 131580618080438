import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { CarouselWithNavigation } from '@beef/smart-kit';

// Utils
import { PartnerCard } from '../PartnerCard';

// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const Carousel = ({ subscriptions }) => (
  <div className={cn('wrapper')}>
    <CarouselWithNavigation
      className={cn('swiper')}
      nextBtnClassname={cn('carousel-next-btn')}
      prevBtnClassname={cn('carousel-prev-btn')}
      settings={{
        slidesPerView: 'auto',
        spaceBetween: 20,
        pagination: {
          clickable: true,
        },
        simulateTouch: true,
      }}
      slideWidthAuto
    >
      {subscriptions.map((card) => (
        <PartnerCard
          checked={card.checked}
          description={card.description}
          id={card.id}
          imageUrl={card.imageUrl}
          isPartnerService={!!card?.partner}
          isSubscriptionStatus={card.isSubscriptionStatus}
          key={card.id}
          name={card.name}
          paymentPeriod={card.paymentPeriod}
          periodAlias={card.periodAlias}
          price={card.price}
          subscriptionStatusText={card.subscriptionStatusText}
        />
      ))}
    </CarouselWithNavigation>
  </div>
);
Carousel.propTypes = {
  subscriptions: PropTypes.arrayOf({
    id: PropTypes.number,
    name: PropTypes.string,
    categoryName: PropTypes.string,
    imageUrl: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    paymentPeriod: PropTypes.string,
    subscriptionStatusText: PropTypes.string,
    isSubscriptionStatus: PropTypes.bool,
  }),
};

export { Carousel };
