import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import { choseUserType } from '../utils/choseUserType';
import { useAppDispatch, useAppSelector } from '../store';
import { setUserStoryType } from '../store/slices/stepperSlice';

/** Хук с логикой определения типа пользователя.
 * По мере продвижения разработки нового конструктора необходимо
 * пополнять возможные пользовательские сценарии (EUserStoryType) и описывать методы
 * их определения в этом хуке */
export const useCheckUserType = () => {
  const dispatch = useAppDispatch();

  /* Объект параметров для определения текущего пользователя через функцию choseUserType */
  const choseUserTypeParams = useAppSelector(
    (state) => ({
      isArchived: state.common.userInfo.isArchived,
      currentTariffTab: state.total.currentTariffTab,
      isConnected: state.common.userInfo.isConnected,
      tariffInacId: state.total.convergenceInfo.tariffInacId,
      isAuthenticated: state.common.userInfo.isAuthenticated,
      isConvergentUser: state.common.userInfo.isConvergentUser,
      /* Признак для определения выбора партнёрского тарифа шпд */
      isPartnerFmcTariffSelected: state.total.convergenceInfo.isPartnerFmcTariffSelected,
    }),
    shallowEqual,
  );

  /* Определение текущего типа пользователя при первой загрузке конструктора */
  useEffect(() => {
    dispatch(setUserStoryType(choseUserType(choseUserTypeParams)));
  }, []);
};
