import { getAdditionalHeight, getIconStaticUrl } from './actions';

const mapAdditionalParams = (params) =>
  params.map((param) => {
    let iconObj = {};
    if (param.icon) {
      iconObj = getIconStaticUrl(param.icon);
    }

    return {
      ...param,
      unit: param.label ? `${param.unit} ${param.label}` : param.unit,
      ...iconObj,
    };
  });

export const getAdditionalParams = (tariffsGroup, additionalParameters) => {
  let paramsHeight = null;
  let parametersList = null;

  const tariffList =
    tariffsGroup &&
    tariffsGroup.reduce((arr, curr) => {
      arr.push(...curr.entries);
      return arr;
    }, []);

  if (additionalParameters && additionalParameters.length && tariffList) {
    // Вычисляем высоту блока с доп параметрами для соблюдения горизонта бордюра;
    paramsHeight = tariffList && getAdditionalHeight(tariffList);
    // Добавляем заголовок для additionalParameters как параметр;
    parametersList = mapAdditionalParams(additionalParameters);
  }

  return {
    additionalParamsHeight: paramsHeight,
    additionalParameters: parametersList,
  };
};
