import React from 'react';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const ChartItem = ({ author, count, percentage, index, onClick }) => (
  <button className={cx('chartItem')} onClick={onClick} type="button">
    <div className={cx('title')}>
      <Text size="size4-r">{author}</Text>
      <Text size="size4-r">{count}</Text>
    </div>
    <div className={cx('scale')}>
      <div
        className={cx('scale-value')}
        style={{
          width: `${percentage}%`,
          animationDuration: `${index * 300 + 800}ms`,
        }}
      />
    </div>
  </button>
);
