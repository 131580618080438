import React from 'react';
// eslint-disable-next-line import/order
import PropTypes from 'prop-types';

// Components
import { Preloader } from '@beef/legacy-ui-kit';

import Wizard, { Step } from 'pages-components/Wizard/Wizard';

import OrderSimForm from './components/OrderSimForm';
import ResultPopup from './components/ResultPopup';

const OrderPopup = ({
  toggleTariffsTabs,
  setActiveTariffTab,
  onClosePopup,
  showTariffs,
  tariffs,
  activeStep,
  title,
  collapseButtonName,
  buttonText,
  startBalancePrice,
  startBalancePriceText,
  startBalanceUnitText,
  errorPopupData,
  successPopupData,
  handleCaptchaChange,
  tariffDetails,
  serverErrors,
  onInputChange,
  makeOrder,
  captchaStatus,
  onChangeCaptchaStatus,
  captchaRequired,
  captchaUrl,
  handleOldCaptchaChange,
  smartCaptchaEnabled,
}) => (
  <Wizard activeStep={activeStep} onClose={onClosePopup} opened>
    <Step name="loading">
      <Preloader />
    </Step>
    <Step name="form">
      <OrderSimForm
        buttonText={buttonText}
        captchaRequired={captchaRequired}
        captchaStatus={captchaStatus}
        captchaUrl={captchaUrl}
        collapseButtonName={collapseButtonName}
        handleOldCaptchaChange={handleOldCaptchaChange}
        onCaptchaChange={handleCaptchaChange}
        onChangeCaptchaStatus={onChangeCaptchaStatus}
        onFormSubmit={makeOrder}
        onInputChange={onInputChange}
        serverErrors={serverErrors}
        setActiveTariffTab={setActiveTariffTab}
        showTariffs={showTariffs}
        smartCaptchaEnabled={smartCaptchaEnabled}
        startBalancePrice={startBalancePrice}
        startBalancePriceText={startBalancePriceText}
        startBalanceUnitText={startBalanceUnitText}
        tariffDetails={tariffDetails}
        tariffs={tariffs}
        title={title}
        toggleTariffsTabs={toggleTariffsTabs}
      />
    </Step>
    <Step name="error">
      <ResultPopup onClose={onClosePopup} popupData={errorPopupData} />
    </Step>
    <Step name="success">
      <ResultPopup onClose={onClosePopup} popupData={successPopupData} />
    </Step>
  </Wizard>
);

OrderPopup.propTypes = {
  setActiveTariffTab: PropTypes.func,
  toggleTariffsTabs: PropTypes.func,
  onClosePopup: PropTypes.func,
  tariffs: PropTypes.arrayOf(PropTypes.shape({})),
  showTariffs: PropTypes.bool,
  activeStep: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  collapseButtonName: PropTypes.string,
  tariffDetails: PropTypes.string,
  startBalancePrice: PropTypes.number,
  startBalancePriceText: PropTypes.string,
  startBalanceUnitText: PropTypes.string,
  errorPopupData: PropTypes.shape({}),
  successPopupData: PropTypes.shape({}),
  captchaStatus: PropTypes.oneOf(['javascript-error', 'success', 'declined', 'network-error']),
  handleCaptchaChange: PropTypes.func,
  onChangeCaptchaStatus: PropTypes.func,
  onInputChange: PropTypes.func,
  serverErrors: PropTypes.shape({}),
  makeOrder: PropTypes.func,
  captchaRequired: PropTypes.bool,
  smartCaptchaEnabled: PropTypes.bool,
  captchaUrl: PropTypes.string,
  handleOldCaptchaChange: PropTypes.func,
};

export default OrderPopup;
