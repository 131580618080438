import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@beef/legacy-ui-kit';
import { pathOr } from 'ramda';
import { Provider, connect } from 'react-redux';
import classNames from 'classnames/bind';
import qs from 'query-string';

import store from 'store';
import documentReady from 'utils/document-ready';
import { sendCvmAnalyticsEvent } from 'utils/analytics/cvmTariffAnalytics';
import {
  getRegionSearchParams,
  handleRoamingYm,
  roamingYmEventsTypes,
} from 'pages/ProductCard/utils';

import {
  initConnectionPopup,
  initDisconnectionPopup,
  initServiceStore,
  showServiceConnectionPopup,
  showServiceConnectionPopupWithOfferCode,
} from '../../actions/serviceConnect';
import styles from '../../ProductCard.pcss';
import serviceStyles from './ServiceConnectButton.pcss';
import ServiceDisconnectPopup from '../ServiceDisconnectPopup/ServiceDisconnectPopup';
import ServiceConnectPopup from '../ServiceConnectPopup/ServiceConnectPopup';
import { DefaultStatus } from '../ServiceConnectionStatus';
// eslint-disable-next-line import/no-named-as-default
import ServiceStatusPopup from '../ServiceStatusPopup/ServiceStatusPopup';

const cx = classNames.bind({ ...styles, ...serviceStyles });

export class ServiceConnectionButton extends PureComponent {
  state = {
    detailsforlk: false,
  };

  componentDidMount() {
    const { onInit, data } = this.props;

    const { availableForConnect, isConnectedLater, isConnected } = data;

    const { previousRegion, currentRegion } = getRegionSearchParams();
    const isWrongRegion = previousRegion && previousRegion !== currentRegion;

    const offerType = new URLSearchParams(window.location.search).get('offerType');

    if (onInit) onInit(data, offerType);

    if (
      availableForConnect &&
      !isConnected &&
      !isConnectedLater &&
      qs.parse(window.location.search).connect === 'true' &&
      !isWrongRegion
    ) {
      documentReady(() => this.handleConnectClick());
    }

    if (typeof window !== 'undefined') {
      const renderUrlMatch = window.location.href.match('detailsforlk');
      this.setState({ detailsforlk: !!renderUrlMatch });
    }
  }

  handleConnectClick = () => {
    const { detailsforlk } = this.state;

    const { onConnectWithOfferCode, onConnect, data } = this.props;

    const { isAuthenticated, marketCode, pageTitle, offerCode, campId, subgroupId, soc, ymType } =
      data;

    const params = {
      serviceName: pageTitle,
      isAuthenticated,
      marketCode,
      soc,
    };

    if (ymType) {
      handleRoamingYm({ type: ymType, actionType: roamingYmEventsTypes.buttonClick });
    }

    // CVM Offers Analytics For LK
    if (detailsforlk && campId && subgroupId) {
      sendCvmAnalyticsEvent([{ campId, subgroupId }], 'landApproved', 'offers');
    }
    // ----------------------------

    if (offerCode) {
      params.offerCode = offerCode;
      onConnectWithOfferCode(params);
    } else {
      const offerType = new URLSearchParams(window.location.search).get('offerType');
      onConnect(params, data, offerType);
    }
  };

  renderErrorPopup() {
    const {
      data: { errorPopup },
      errorData,
    } = this.props;

    return <ServiceStatusPopup errorContent={errorData || errorPopup} id="recentCalls" />;
  }

  renderButton() {
    const { availableForConnect, connectButton } = this.props.data;

    if (connectButton && availableForConnect) {
      return (
        <div className={cx('connectButtons')}>
          <Button className={['big', cx('button')]} onClick={this.handleConnectClick} wide>
            {connectButton}
          </Button>
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      serviceProcess,
      data,
      data: {
        extData: { content },
      },
    } = this.props;

    const {
      isConnectedLater,
      connectedText,
      canBeRemoved,
      warningText,
      isConnected,
      archiveText,
      isArchived,
      soc,
      ymType,
    } = data;

    if (content?.isCustomDisconnect) {
      return null;
    }

    const disconnect = isConnectedLater ? 'Отменить подключение' : 'Отключить';

    return (
      <div>
        <DefaultStatus
          archiveContent={archiveText}
          canBeRemoved={canBeRemoved}
          connectedContent={connectedText}
          data={this.props.data}
          disconnectContent={disconnect}
          isArchived={isArchived}
          isConnected={isConnected}
          isConnectedLater={isConnectedLater}
          soc={soc}
          warningContent={warningText}
        />

        {!serviceProcess && !isConnected && !isConnectedLater && !isArchived && this.renderButton()}

        <ServiceConnectPopup ymType={ymType} />
        <ServiceDisconnectPopup isCancel={isConnectedLater} />

        {this.renderErrorPopup()}
      </div>
    );
  }
}

ServiceConnectionButton.propTypes = {
  onConnect: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  serviceProcess: PropTypes.string,
  data: PropTypes.object,
  errorData: PropTypes.object,
  onConnectWithOfferCode: PropTypes.func,
  isCustomDisconnect: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const connectData = pathOr({}, ['external', 'serviceConnectionPopup'], state);
  const disconnectData = pathOr({}, ['external', 'serviceDisconnectionPopup'], state);
  let errorData = null;

  if (connectData.serviceProcess === 'error') {
    errorData = connectData.resultPopupData || disconnectData.resultPopupData;
  }

  return {
    serviceProcess: connectData.serviceProcess,
    errorData,
  };
};

const ConnectedComponent = connect(mapStateToProps, (dispatch) => ({
  onInit: (data, offerType) => {
    dispatch(initServiceStore(data));
    dispatch(initDisconnectionPopup(data.serviceDisconnectionPopup));
    dispatch(initConnectionPopup(data.connectPopupUrl, data.alias, 'true', data.soc, offerType));
  },
  onConnect: (params, data, offerType) => {
    dispatch(initConnectionPopup(data.connectPopupUrl, data.alias, 'true', data.soc, offerType));
    dispatch(showServiceConnectionPopup(params));
  },
  onConnectWithOfferCode: (params) => dispatch(showServiceConnectionPopupWithOfferCode(params)),
}))(ServiceConnectionButton);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
