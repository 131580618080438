import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { useAppSelector } from 'constructorV1/store';

import { selectPreparedSections } from './selectors/selectPreparedSections';
import { OptionTiles } from './components/OptionTiles';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент-обертка над опциями конструктора */
export const OptionContainer: FC = () => {
  const sections = useAppSelector(selectPreparedSections);

  return (
    <section className={cx('wrapper')}>
      {sections?.map((section, idx) => <OptionTiles key={idx} {...section} />)}
    </section>
  );
};
