import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, CollapsedContent, SmartForm, SmartInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { maskedPhone } from 'utils/validators';

import TextContainer from '../TextContainer/TextContainer';
import commonStyles from '../SharingService.pcss';

const cx = classNames.bind(commonStyles);

class AddNumberForm extends Component {
  state = {
    number: '+7 ',
    cleared: false,
  };

  handleNumberChange = (number) => {
    this.setState((prevState) => ({
      number,
      cleared: prevState.cleared && false,
    }));
  };

  handleFormSubmit = (data) => {
    if (this.state.cleared) return;

    this.props
      .onAddNumber(data)
      .then(() => this.setState({ number: '+7 ', cleared: true }))
      .catch((e) => console.error(e));
  };

  render() {
    return (
      <>
        <TextContainer
          className={cx('description')}
          path={['texts', 'addNumberDescriptionBefore']}
        />
        <SmartForm
          className={cx('form')}
          onSubmit={this.handleFormSubmit}
          validator={this.state.cleared ? {} : { phone: maskedPhone }}
        >
          <div className={cx('input')}>
            <TextContainer className={cx('inputTitle')} path={['texts', 'addNumberInputTitle']} />
            <SmartInput
              className={cx('phoneInput')}
              inputId="phone"
              mask="+7 ___ ___ __ __"
              onChange={this.handleNumberChange}
              textInputClass="bigSize"
              value={this.state.number}
            />
          </div>
          <div className={cx('submit')}>
            <Button className={['big']} type="submit" wide>
              <TextContainer path={['texts', 'addNumberInvitationButtonTitle']} />
            </Button>
          </div>
        </SmartForm>
        <TextContainer
          className={cx('description')}
          path={['texts', 'addNumberDescriptionAfter']}
        />
        <div className={cx('links')}>
          <CollapsedContent
            contentClass={cx(['description', 'collapsed-content'])}
            title={this.props.switchNumberInfoLink}
          >
            <TextContainer path={['texts', 'howFindNumber']} />
          </CollapsedContent>
        </div>
      </>
    );
  }
}

AddNumberForm.propTypes = {
  switchNumberInfoLink: PropTypes.string,
  onAddNumber: PropTypes.func,
};

export default AddNumberForm;
