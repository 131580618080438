import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import Offers from './Offers';
import Questionnaire from './Questionnaire';
import CallBack from './CallBack';
import { TariffUpOffers } from './TariffUpOffers';

const cx = classNames.bind(styles);

const componentsByTypes = {
  offers: Offers,
  tariffUpOffers: TariffUpOffers,
  question: Questionnaire,
  callBack: CallBack,
  callBackOk: CallBack,
};

const DownsellPopup = ({ data, onSubmit, setAnalytics }) => {
  if (!data) return null;
  const { type } = data;

  const DownsellComponent = componentsByTypes[type];

  const onOfferSubmit = (url, payload, canRetry = false) => {
    if (type === 'callBackOk') {
      window.location.href = url;
    } else {
      onSubmit({ url, tariffData: null, payload: payload || {}, canRetry });
    }
  };

  return (
    <div className={cx('component')}>
      {DownsellComponent && (
        <DownsellComponent data={data} onSubmit={onOfferSubmit} setAnalytics={setAnalytics} />
      )}
    </div>
  );
};

DownsellPopup.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  setAnalytics: PropTypes.func,
};

export default DownsellPopup;
