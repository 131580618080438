import { composeStoreInitializer } from '@beef/redux';
import { combineReducers } from '@reduxjs/toolkit';

import voWifiServiceCardCommonSlice from './slices';

export const initializeVoWifiServiceCardLanding = composeStoreInitializer(
  combineReducers({
    voWifiContent: (state = {}) => state,
    voWifiServiceCardCommonSlice,
  }),
);
