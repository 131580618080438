// Libs & Utils
import axios from 'axios';
import { pathOr } from 'ramda';

// Actions
import { errorConnectionEvent, tariffDownsellShowEvent } from './tariffConnectionAnalytics';
import { toggleConnectPopup } from './tariffConnectExtension';
import { recordPayload, showError } from '../actionsApi';
// Actions Types
import {
  SET_CONNECTION_RESULT_POPUP,
  SET_TABS_TO_DISPLAY,
  SET_TARIFF_CONNECTION_POPUP,
} from '../actionTypes';

export const tariffInfoUpdater = {};

export const toggleTariffConnectionPopup =
  (
    popupIsActive = false,
    offerCode = null,
    tabsToDisplay = null,
    isFloatingButtonClicked = false,
    activeTabName = null,
  ) =>
  async (dispatch, getState) => {
    const { external } = getState();
    const { tariffConnectionPopup, tariff } = external;
    const { connectPopupUrl, downSellPayload, offerCodeStore, data } = tariffConnectionPopup;

    dispatch(toggleConnectPopup(popupIsActive));
    dispatch(recordPayload(SET_TABS_TO_DISPLAY, tabsToDisplay));
    dispatch(recordPayload(SET_CONNECTION_RESULT_POPUP, null));
    dispatch(
      recordPayload(SET_TARIFF_CONNECTION_POPUP, {
        contactPhone: undefined,
        divorcing: false,
        isPending: true,
        isError: false,
        step: null,
      }),
    );

    if (!popupIsActive) {
      return;
    }

    // Костыль для подключения акции функционала downsellPopupNew;
    // Костыль предназначен для запрета передачи данных в теле запроса, так как
    // все параметры передаются через параметры url;
    const paramsObject =
      data && data.type === 'offers' ?
        null
      : downSellPayload || {
          connect: 'true&activeTab=submit',
          offerCode: offerCode || offerCodeStore,
        };

    if (
      tariff.data.isForYoungDetailEnabled &&
      paramsObject &&
      tariffConnectionPopup?.additionalOptions?.chosenSocs
    ) {
      paramsObject.additionalSocs = tariffConnectionPopup.additionalOptions.chosenSocs;
    }

    try {
      const {
        data: { isSucceeded, view },
      } =
        tariff?.data?.dataFetched ?
          { data: { isSucceeded: tariff?.data?.isSucceeded, view: tariff?.data } }
        : await axios.post(connectPopupUrl, paramsObject);

      if (isSucceeded) {
        let obj = {
          resultPopupData: null,
          isPending: false,
          divorcing: false,
          canRetry: false,
          data: view,
          isFloatingButtonClicked,
          activeTabName,
        };

        if (tariff.data?.esim?.popupUrl) {
          /**
           * подкладываем урл для запроса данных о есим в новой корзине
           * todo: прийти с бекендом к единому формату получения этих данных
           */
          obj.data = {
            ...obj.data,
            esimPopupUrl: `${tariff.data.esim.popupUrl}&tariffUpSoc=${tariff.data.tariffUpSoc}`,
          };
        }

        const isDownsellOffer = ['callBack', 'question', 'offers', 'tariffUpOffers'].includes(
          view.type,
        );
        if (isDownsellOffer) {
          // Параметр предназначен для указания на функционал downsell;
          // Применяется для отправки аналитики на бэк;
          obj = { ...obj, place: 'downsell' };
        }

        dispatch(recordPayload(SET_TARIFF_CONNECTION_POPUP, obj));
        if (view.isDownsell) {
          const tariffs = pathOr([{}, {}], ['tariffsData', 'tariffs'], view)[1];
          tariffDownsellShowEvent(tariffs);
        }
      } else {
        dispatch(showError(view));
        dispatch(
          recordPayload(SET_TARIFF_CONNECTION_POPUP, {
            isPending: false,
            isError: true,
          }),
        );
        errorConnectionEvent(view && view.content);
      }
    } catch (err) {
      let errorPopup = null;

      if (data) {
        switch (data.type) {
          case 'offers':
            errorPopup =
              downSellPayload?.errorPopup || data?.offers?.map((item) => item.errorPopup)[0];
            break;
          case 'callBack':
          case 'question':
            errorPopup = data.errorPopup;
            break;
        }
      }

      dispatch(showError(errorPopup));
      dispatch(
        recordPayload(SET_TARIFF_CONNECTION_POPUP, {
          isPending: false,
          isError: true,
        }),
      );
    }

    tariffInfoUpdater.update = () => {
      dispatch(
        toggleTariffConnectionPopup(
          popupIsActive,
          offerCode,
          tabsToDisplay,
          isFloatingButtonClicked,
        ),
      );
    };
  };
