import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

// Utils
import { replacePriceDot } from 'utils/format-string';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Компонент в виде перечеркнутой цены
 * @param priceColor - Флаг перекрашивает цену в белый цвет/черный цвет;
 * @param oldValue - Цена;
 * @param className - Дополнительный класс;
 */
const PriceOld = ({ priceColor, oldValue, className }) => (
  <div
    className={cx('priceOld', priceColor, className)}
    dangerouslySetInnerHTML={{ __html: replacePriceDot(oldValue) }}
  />
);

PriceOld.default = { priceColor: 'dark' };

PriceOld.propTypes = {
  oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priceColor: PropTypes.string,
};

/**
 * Желтая ленточка с текстом
 * @param label - Текст лейбла;
 * @param animated - Запускает анимацию отскока;
 * @param className - Дополнительный класс;
 * @param square - Скрывает треугольник делая компонент прямоугольным;
 * @param icon - Иконка в бэйдже;
 */
const Label = ({ label, animated, className, square, icon }) => (
  <span className={cx('label', { animated, square }, className)}>
    <span className={cx('labelBox')}>
      <span dangerouslySetInnerHTML={{ __html: label }} data-attr="label" />
      {icon && (
        <Icon className={cx('icon')} src={`//static.beeline.ru/upload/images/icons/${icon}.svg`} />
      )}
    </span>
  </span>
);

Label.propTypes = {
  label: PropTypes.string,
  animated: PropTypes.bool,
  className: PropTypes.string,
  square: PropTypes.bool,
  icon: PropTypes.string,
};

/**
 * Цена со скидкой, используется в карточке тарифа;
 * @param price - Объект с полями цены;
 * @param white - Цвет текста скидки;
 * @param className - Доп. классы;
 */
const Price = ({ price, white, className }) => (
  <div className={cx('price', className)}>
    {(price.oldValue || price.label) && (
      <div className={cx('discount')}>
        {price.oldValue && <PriceOld oldValue={price.oldValue} white={white} />}
        {price.label && <Label label={price.label} />}
      </div>
    )}

    <div className={cx('innerPrice')}>
      <span
        className={cx('priceValue')}
        dangerouslySetInnerHTML={{ __html: replacePriceDot(price.value) }}
      />
      <span className={cx('priceUnit')} dangerouslySetInnerHTML={{ __html: price.unit }} />
      {(price.condition || price.conditionLabel) && (
        <div className={cx('condition')}>
          {price.conditionLabel && (
            <Label className={cx('conditionLabel')} label={price.conditionLabel} />
          )}
          {price.condition && <span dangerouslySetInnerHTML={{ __html: price.condition }} />}
        </div>
      )}
    </div>
  </div>
);

Price.propTypes = {
  price: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.string,
    present: PropTypes.string,
    condition: PropTypes.string,
    oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    symbolLabel: PropTypes.string,
    conditionLabel: PropTypes.string,
  }),
  white: PropTypes.bool,
};

export default {
  Price,
  PriceOld,
  Label,
};
