import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { connect } from 'react-redux';
import { Button, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { translate } from 'utils/format-string';
import Icon from 'pages-components/Icon';
import EmptyButton from 'pages-components/EmptyButton';
import Ctx from 'pages-components/Ctx';

import * as actions from '../../store/actions';
import PointItem from './PointItem';
import PointInfo from './PointInfo';
import ControlsWrapper from '../Controls/Wrapper';
import { FiltersMapper } from './FiltersMapper';
import { hidePointOnMap, toggleInfo, togglePointsList } from '../../store/actions';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

class OfficesList extends Component {
  increaseCount = 10;

  initialCount = 5;

  state = {
    showCount: this.initialCount,
  };

  componentDidUpdate(prevProps) {
    if (prevProps?.points?.length !== this.props?.points?.length) {
      this.setState({
        showCount: this.initialCount,
      });
    }
  }

  onPointsClick = (id) => () => {
    const point = this.props.points.find((item) => item.id === id);
    this.props.onToggleInfo(point);
  };

  onShowMoreClick = () => {
    this.setState({
      showCount: (this.state.showCount += this.increaseCount),
    });
  };

  onFilterChange = (filter) => {
    this.props.onFiltersChange(filter);

    if (!this.props.points.find((item) => item.id === this.props.chosenPoint.id)) {
      this.props.hidePointOnMap();
    }
  };

  getEmptyText = () => {
    const { points, clusters } = this.props;

    if (points.length === 0 && clusters.length === 0) {
      return Ctx.maps.movingMapText;
    }
    if (clusters.length) {
      return Ctx.maps.zoomingInMapText;
    }

    return '';
  };

  getTotalPointsCount = () => {
    const { points, clusters } = this.props;
    return clusters.reduce((total, cluster) => total + cluster.count, points.length);
  };

  render() {
    const {
      sng,
      opened,
      points,
      isPopUp,
      pointsData,
      chosenPoint,
      onToggleInfo,
      isLandingPage,
      showOrderButton,
      holidaysText,
      holidayScheduleChangesText,
      onTogglePointsList,
      filtersOptions,
      appliedFilters,
    } = this.props;

    const { showCount } = this.state;

    if (!points) return null;

    const hiddenCount = points.length - showCount < 0 ? 0 : points.length - showCount;
    const showMoreCount =
      hiddenCount / this.increaseCount >= 1 ? this.increaseCount : hiddenCount % this.increaseCount;
    const emptyText = !showMoreCount && this.getEmptyText();
    const totalCount = this.getTotalPointsCount();

    return (
      <ControlsWrapper
        filtersOptions={filtersOptions}
        fixedHeight={isLandingPage}
        offsetValue="140px"
      >
        <div className={cx('heading-wrapper')}>
          <EmptyButton
            className={cx('toggle-button')}
            onClick={() => {
              onTogglePointsList(!opened);
            }}
            tabIndex={-1}
          >
            <Text size="size4-m" tagType="h4">
              {totalCount} {translate('office', totalCount)} {Ctx.maps.postfix}
              <Icon className={cx('arrow', { opened })} name="arrowDown" />
            </Text>
          </EmptyButton>

          {!sng && isEmpty(filtersOptions) === false && (
            <FiltersMapper
              appliedFilters={appliedFilters}
              filtersOptions={filtersOptions}
              onFilterChange={this.onFilterChange}
            />
          )}
        </div>
        <div className={cx('content-wrapper', chosenPoint.showInfo && 'show-info')}>
          <div className={cx('content', 'list')}>
            {points.slice(0, showCount).map((point, index) => (
              <PointItem
                {...point}
                customName={(pointsData[point.place.name] || {}).displayName}
                holidayScheduleChangesText={holidayScheduleChangesText}
                key={index}
                onClick={this.onPointsClick(point.id)}
              />
            ))}
            {showMoreCount ?
              <div className={cx('button')}>
                <Button onClick={this.onShowMoreClick} size="l" variant="main">
                  {Ctx.maps.showMore} {showMoreCount}
                </Button>
              </div>
            : null}
            {emptyText && (
              <div className={cx('warning')}>
                <Text color="grey95" size="size6-r">
                  {emptyText}
                </Text>
              </div>
            )}
          </div>
          <div
            className={cx('content', 'info', {
              'stretched-to-top': isLandingPage || sng,
            })}
          >
            {chosenPoint.showInfo && (
              <PointInfo
                extraInfo={pointsData[chosenPoint.place.name]}
                isPopUp={isPopUp}
                isSimOrder={chosenPoint.simOrder}
                onBackClick={() => {
                  onToggleInfo(null);
                }}
                setOpenPopup={this.props.setOpenPopup}
                showOrderButton={showOrderButton}
                {...chosenPoint}
                holidaysText={holidaysText}
              />
            )}
          </div>
        </div>
      </ControlsWrapper>
    );
  }
}

OfficesList.propTypes = {
  isLandingPage: PropTypes.bool,
  points: PropTypes.array,
  clusters: PropTypes.array,
  chosenPoint: PropTypes.object,
  opened: PropTypes.bool,
  pointsData: PropTypes.object,
  onToggleInfo: PropTypes.func,
  onFiltersChange: PropTypes.func,
  onTogglePointsList: PropTypes.func,
  hidePointOnMap: PropTypes.func,
  setOpenPopup: PropTypes.func,
  showOrderButton: PropTypes.bool,
  filtersOptions: PropTypes.array,
  appliedFilters: PropTypes.object,
  holidaysText: PropTypes.string,
  holidayScheduleChangesText: PropTypes.string,
};

const mapStateToProps = ({ external: { maps = {} } }) => ({
  opened: maps.listOpened,
  chosenPoint: maps.chosenPoint || {},
  points: maps.points,
  clusters: maps.clusters || [],
  appliedFilters: maps.appliedFilters || {},
});

const mapDispatchToProps = (dispatch) => ({
  onTogglePointsList: (data) => dispatch(togglePointsList(data)),
  onToggleInfo: (data) => dispatch(toggleInfo(data)),
  hidePointOnMap: () => dispatch(hidePointOnMap()),
  onFiltersChange: (filter) => dispatch(actions.setFilters(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfficesList);
