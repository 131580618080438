import { formatDate } from 'utils/format-string';

import { ETrustPaymentConstant, EZeroBalanceSupportConstant, TZeroBalanceContext } from './types';

export const selectData = (state: TZeroBalanceContext) => state.data || {};

export const selectConnectPopupUrl = (state: TZeroBalanceContext) =>
  selectData(state)?.connectPopupUrl || '';
export const selectConnectPollUrl = (state: TZeroBalanceContext) =>
  selectData(state)?.connectPollUrl ||
  '/customers/products/mobile/services/checkconnectrequeststatus/';
export const selectSoc = (state: TZeroBalanceContext) => selectData(state)?.soc || '';

export const selectIsAuthenticated = (state: TZeroBalanceContext) =>
  selectData(state).isAuthenticated ?? false;
export const selectIsConnected = (state: TZeroBalanceContext) =>
  selectData(state).isConnected ?? false;
export const selectIsAvailableToConnect = (state: TZeroBalanceContext) =>
  selectData(state).availableForConnect ?? false;
export const selectIsAvailableForNextConnection = (state: TZeroBalanceContext) =>
  selectData(state).isAvailableForNextConnection ?? false;

export const selectTrustPaymentData = (state: TZeroBalanceContext) => {
  const additionalDays =
    state.trustPaymentData?.expPeriod || ETrustPaymentConstant.DefaultExpirationPeriodInDays;
  const additionalDaysInMilliseconds = additionalDays * ETrustPaymentConstant.OneDayInMilliseconds;
  const dateInMilliseconds = new Date().valueOf();
  const newDate = new Date(dateInMilliseconds + additionalDaysInMilliseconds);

  return {
    ...state.trustPaymentData,
    total: [state.trustPaymentData?.amount, state.trustPaymentData?.chargeAmount]
      .filter(Boolean)
      .reduce((sum = 0, val = 0) => sum + val, 0),
    dueDate: formatDate(newDate, ''),
  };
};

export const selectSetTrustPaymentData = (state: TZeroBalanceContext) =>
  state.setTrustPaymentData ||
  (() => {
    console.error('No set trust payment context handler');
  });

export const selectContent = (state: TZeroBalanceContext) => state.content || {};
export const selectBanner = (state: TZeroBalanceContext) => selectContent(state).banner || {};

export const selectPolling = (state: TZeroBalanceContext) => selectContent(state)?.polling || {};
export const selectInterval = (state: TZeroBalanceContext) =>
  selectPolling(state)?.interval || EZeroBalanceSupportConstant.Interval;
export const selectTimeout = (state: TZeroBalanceContext) =>
  selectPolling(state)?.timeout || EZeroBalanceSupportConstant.Timeout;

export const selectRequestStatus = (state: TZeroBalanceContext) => state.requestStatus || {};
export const selectUnavailableService = (state: TZeroBalanceContext) =>
  selectRequestStatus(state).unavailableService || {};
export const selectBlockedService = (state: TZeroBalanceContext) =>
  selectRequestStatus(state).blockedService || {};
export const selectTrustPaymentSuccess = (state: TZeroBalanceContext) =>
  selectRequestStatus(state).trustPaymentSuccess || {};
export const selectError = (state: TZeroBalanceContext) => selectRequestStatus(state).error || {};

export const selectDisconnectBlockedService = (state: TZeroBalanceContext) =>
  selectRequestStatus(state).blockedServiceDisconnect || {};

export const selectConfirmConnect = (state: TZeroBalanceContext) =>
  selectRequestStatus(state).confirmConnect || {};
export const selectErrorConnectStep = (state: TZeroBalanceContext) =>
  selectRequestStatus(state).errorConnectStep || {};
export const selectSuccess = (state: TZeroBalanceContext) =>
  selectRequestStatus(state).success || {};

export const selectConfirmDisconnect = (state: TZeroBalanceContext) =>
  selectRequestStatus(state).confirmDisconnect || {};
export const selectConfirmDisconnectArchived = (state: TZeroBalanceContext) =>
  selectRequestStatus(state).confirmDisconnectArchived || {};
export const selectErrorDisconnectStep = (state: TZeroBalanceContext) =>
  selectRequestStatus(state).errorDisconnectStep || {};
export const selectDisconnectServiceSuccess = (state: TZeroBalanceContext) =>
  selectRequestStatus(state).disconnectServiceSuccess || {};

export const selectRequestErrorStep = (state: TZeroBalanceContext) =>
  selectRequestStatus(state).requestError || {};
