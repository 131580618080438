import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import styles from './styles.pcss';
import MainParam from './components/MainParam';

const cx = classNames.bind(styles);

const TariffParams = ({ isDark, paramsGroups }) => (
  <div className={cx({ isDark })}>
    {paramsGroups.map(({ aslias, mainParam, additionalParam }) => (
      <div className={cx('paramsGroupWrapper')} key={aslias}>
        <MainParam isDark={isDark} mainParam={mainParam} />
        <div
          className={cx('additionalParam', {
            disabledParam: !additionalParam.included,
          })}
        >
          <div className={cx('additionalParamIconWrapper')}>
            <Icon className={cx('additionalParamIcon')} name={additionalParam.iconName} />
          </div>
          <div
            className={cx('additionalParamName')}
            dangerouslySetInnerHTML={{ __html: additionalParam.name }}
          />
          <span
            className={cx('additionalParamDescription')}
            dangerouslySetInnerHTML={{ __html: additionalParam.description }}
          />
          {additionalParam.tooltipText && (
            <Tooltip className={cx('additionalParamTooltip')}>
              <span
                dangerouslySetInnerHTML={{
                  __html: additionalParam.tooltipText,
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>
    ))}
  </div>
);

TariffParams.propTypes = {
  isDark: PropTypes.bool,
  paramsGroups: PropTypes.arrayOf(
    PropTypes.shape({
      alias: PropTypes.string,
      mainParam: PropTypes.shape({
        alias: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        unit: PropTypes.string,
        iconName: PropTypes.string,
        description: PropTypes.string,
        onClick: PropTypes.func,
      }),
      additionalParam: PropTypes.shape({
        alias: PropTypes.string,
        name: PropTypes.string,
        included: PropTypes.bool,
        iconName: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  ),
};

export default memo(TariffParams);
