import { pushDataLayer } from 'utils/ga';
import { ymPushEvent } from 'utils/ym';

export const pushSmsFormEvent = (step) => {
  pushDataLayer({
    event: {
      submit: 'event_popupSub_interaction',
      success: 'event_popupSub_success',
      error: 'event_popupSub_fail',
      smsCodeSubmit: 'event_popupSMS_interaction',
    }[step],
  });
};

/**
 * Отправляем пуш в аналитику если в контенте либо в заголовке есть баблик;
 * ------------------------------------------------------------------------
 * @param tariff - Объект тарифа;
 */
export const pushRecommendTariffEvent = (tariff) =>
  pushDataLayer({
    event: 'Recommend_offer',
    itemTitleRecommend: tariff.pageTitle,
    itemSocRecommend: tariff.soc,
    itemMarketCodeRecommend: tariff.marketCode,
    itemCampaignID: tariff.campId,
    itemSubgroupID: tariff.subgroupId,
  });

export const ymMobTariffsToDetail = (tariffData) => {
  /* Временно "отключаем" функцию по согласованию с аналитикой!
   После проверки новой метрики будет принято решение по ymMobTariffsToDetail в целом */
  // ymPushEvent('MobTariffsToDetail', { URL6: document.location.href });
  ymPushEvent('podrobnee');
  if (tariffData.cardStyle === 'constructor') {
    ymPushEvent('nastriot_tariff');
  }
};

export const ymMobTariffsShowAll = () => {
  ymPushEvent('MobTariffsShowAll', { URL7: document.location.href });
};
