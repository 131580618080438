import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { ExtendedSteps as ESteps } from '@beef/landing-constructor';

import { ExtendedStepsTypes } from 'pages/SPN/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const ExtendedSteps: FC<ExtendedStepsTypes> = ({ title, text, content }) => (
  <div className={cn('wrapper')}>
    <ESteps content={content} text={text} title={title} />
  </div>
);
