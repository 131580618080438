import { shallowEqual } from '@beef/utils';

import { useAppSelector } from '../store';
import { checkArraysValuesEqual } from '../utils/checkArraysValuesEqual';
import { useIsConnectedTariff } from './tariffStateHooks/useIsConnectedTariff';
import { useSelectTariffTab } from '../components/constructorPage/Tabs/selectors/useSelectTariffTab';
import { TabTypes } from '../components/constructorPage/Tabs/constants';

/** Хук определяет, является ли тариф подключенным (сок персонажа, пресеты, опции ) */
export const useIsMyTariff = () => {
  const isConnectedTariff = useIsConnectedTariff();
  const { currentTariffTab } = useSelectTariffTab();

  const { baseTariffInfo, optionsSoc, servicesInacIdList } = useAppSelector(
    (state) => ({
      baseTariffInfo: [
        state.total.gbValue,
        state.total.minValue,
        state.total.characterSoc,
        state.total.convergenceInfo.tariffInacId,
      ],
      optionsSoc: state.total.optionsSoc,
      servicesInacIdList: state.total.convergenceInfo.servicesInacIdList,
    }),
    shallowEqual,
  );

  const {
    defaultBaseTariffInfo,
    defaultOptionsSoc,
    defaultServicesInacIdList = [],
  } = useAppSelector(
    (state) => ({
      defaultBaseTariffInfo: [
        state.common.defaultConstructor.defaultTariffData?.gbValue,
        state.common.defaultConstructor.defaultTariffData?.minValue,
        state.common.defaultConstructor.defaultTariffData?.characterSoc,
        state.common.defaultConstructor.defaultTariffData?.tariffInacId,
      ],
      defaultOptionsSoc: state.common.defaultConstructor.defaultTariffData?.optionsSoc,
      defaultServicesInacIdList:
        state.common.defaultConstructor.defaultTariffData?.servicesInacIdList,
    }),
    shallowEqual,
  );

  if (!isConnectedTariff || currentTariffTab !== TabTypes.ChangeTariffForm) return false;

  return [
    checkArraysValuesEqual(baseTariffInfo, defaultBaseTariffInfo),
    checkArraysValuesEqual(optionsSoc, defaultOptionsSoc),
    checkArraysValuesEqual(servicesInacIdList, defaultServicesInacIdList),
  ].every((checkRes) => checkRes);
};
