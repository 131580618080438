import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';

// Utils
import { gaYandexPlusConnected } from 'utils/analytics/yandexTariffGA';

// Components
import RequestStatus from 'pages-components/RequestStatus';
import PageLevel from 'pages/ProductCard/Tariff/components/PageLevel';
import { yandexPlusConnectedYMUpd } from 'utils/analytics/tariffsYandexMetrica';

import CountDown from './CountDown';
import StandaloneDisconnect from './StandAloneDisconnect';
import SubscriptionFetchStatus from './SubscriptionFetchStatus';
import { useYandexUrlParams } from './utils';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const STEPS_CONFIG = {
  initial: 'initial',
  state: 'state',
  error: 'error',
  standaloneSubscriptionConnectedPreview: 'standaloneSubscriptionConnectedPreview',
  standaloneSubscriptionConnected: 'standaloneSubscriptionConnected',
  standaloneSubscriptionDisconnectSuccess: 'standaloneSubscriptionDisconnectSuccess',
  yandexPlusSubscriptionConnected: 'yandexPlusSubscriptionConnected',
  standaloneYandexPlusAndVisitYandexPassport: 'standaloneYandexPlusAndVisitYandexPassport',
  yandexPlusConnectedVisitYandexPassport: 'yandexPlusConnectedVisitYandexPassport',
};

const CONNECTED_SERVICE = 'Яндекс Плюс';

const YandexPlusCallbackPage = ({ data: { content } }) => {
  const [step, setStep] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [token, setToken] = useState(null);
  const [standaloneData, setStandaloneData] = useState(null);
  const [errorMessage, setErrorMsg] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const { accessToken, location, state, url } = useYandexUrlParams();

  useEffect(() => {
    if (state) {
      setStep(STEPS_CONFIG.state);
    } else if (!url || !accessToken) {
      setStep(STEPS_CONFIG.error);
    } else {
      /* если их нет - сваливаемся в ошибку */
      setRedirectUrl(url);
      setToken(accessToken);
      setStep(STEPS_CONFIG.initial);
    }
  }, [state, url, accessToken]);

  useEffect(() => {
    if (step === STEPS_CONFIG.state) {
      location.pathname = decodeURIComponent?.(state);
    }
  }, [step]);

  // пуш отправлять когда пользователь возвращается на страницу после активации через аккаунт яндекса
  useEffect(() => {
    if (
      step === STEPS_CONFIG.yandexPlusSubscriptionConnected ||
      step === STEPS_CONFIG.standaloneSubscriptionConnectedPreview
    ) {
      gaYandexPlusConnected();
      yandexPlusConnectedYMUpd('partner', CONNECTED_SERVICE);
    }
  }, [step, redirectUrl]);

  if (!content.statuses[step] && !state) {
    return null;
  }

  const getCurrentStep = (conditions) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const condition of Object.keys(conditions)) {
      if (conditions[condition]) {
        return condition;
      }
    }
  };

  return (
    <div className={cx('wrap')}>
      <PageLevel contentSize="s" size="l">
        {state && <RequestStatus data={{}} fullSize pending />}

        {step === STEPS_CONFIG.initial && (
          <SubscriptionFetchStatus
            data={content.statuses[step]}
            onError={(message) => {
              setStep(STEPS_CONFIG.error);
              if (message) {
                setErrorMsg(message);
              }
            }}
            onSuccess={(hasStandaloneSub, hasForeignSub) => {
              setSubscriptions({
                hasStandaloneSub,
                hasForeignSub,
              });
              const stepValue = getCurrentStep({
                [STEPS_CONFIG.standaloneSubscriptionConnectedPreview]:
                  hasStandaloneSub || hasForeignSub,
                [STEPS_CONFIG.yandexPlusSubscriptionConnected]: !hasStandaloneSub && !hasForeignSub,
              });
              setStep(stepValue);
            }}
            setStandaloneData={setStandaloneData}
            token={token}
          />
        )}
        {(step === STEPS_CONFIG.standaloneSubscriptionConnected ||
          step === STEPS_CONFIG.standaloneYandexPlusAndVisitYandexPassport) && (
          <StandaloneDisconnect
            disconnectContent={content.statuses[step]}
            onError={() => {
              setStep(STEPS_CONFIG.error);
            }}
            onSuccess={() => {
              setStep(STEPS_CONFIG.standaloneSubscriptionDisconnectSuccess);
            }}
            pendingContent={content.statuses.standaloneSubscriptionDisconnectPending}
            redirectUrl={redirectUrl}
            standaloneData={standaloneData}
          />
        )}

        {(step === STEPS_CONFIG.error ||
          step === STEPS_CONFIG.standaloneSubscriptionDisconnectSuccess ||
          step === STEPS_CONFIG.yandexPlusConnectedVisitYandexPassport ||
          step === STEPS_CONFIG.yandexPlusSubscriptionConnected) && (
          <RequestStatus
            data={{
              ...content.statuses[step],
              buttonUrl: redirectUrl || '/',
              description:
                (step === STEPS_CONFIG.error && errorMessage) || content.statuses[step].description,
            }}
            fullSize
          />
        )}
        {step === STEPS_CONFIG.standaloneSubscriptionConnectedPreview && (
          <RequestStatus
            data={content.statuses[step]}
            fullSize
            onClick={() => {
              const { hasStandaloneSub, hasForeignSub } = subscriptions;
              const stepValue = getCurrentStep({
                [STEPS_CONFIG.standaloneYandexPlusAndVisitYandexPassport]:
                  hasStandaloneSub && hasForeignSub,
                [STEPS_CONFIG.standaloneSubscriptionConnected]: hasStandaloneSub && !hasForeignSub,
                [STEPS_CONFIG.yandexPlusConnectedVisitYandexPassport]: hasForeignSub,
              });
              setStep(stepValue);
            }}
          />
        )}
        {step === STEPS_CONFIG.standaloneSubscriptionDisconnectSuccess && (
          <div className={cx('countdown')}>
            <CountDown
              countDownStart={content.redirectTimeout}
              countdownText="Перейдем на страницу через"
              onCountdownEnd={() => {
                window.location.href = redirectUrl;
              }}
            />
          </div>
        )}
      </PageLevel>
    </div>
  );
};

export default YandexPlusCallbackPage;
