import React from 'react';
import { Faq, PageContainer, PageFixer } from '@beef/smart-kit';

import { faqContentShape } from 'pages/Services/ScopeZeroBalanceServices/propTypes/contentPropTypes';

export const FaqLayout = (props) => (
  <PageContainer size="l">
    <PageFixer size="m">
      <Faq {...props} />
    </PageFixer>
  </PageContainer>
);

FaqLayout.propTypes = {
  faq: faqContentShape,
};
