import guid from './guid-generator';

const getRemoveListenerById = (id) => () => {
  const iframe = document.getElementById(id);
  if (iframe) iframe.remove();
};

export const onElementHeightChange = (element, handler) => {
  element.style.position = 'relative';
  const iframe = document.createElement('iframe');
  const id = `elementHeightChange_${guid()}`;

  iframe.tabindex = '-1';
  iframe.id = id;
  iframe.style.position = 'absolute';
  iframe.style.top = 0;
  iframe.style.bottom = 0;
  iframe.style.left = 0;
  iframe.style.height = '100%';
  iframe.style.width = 0;
  iframe.style.border = 0;
  iframe.style.backgroundColor = 'transparent';
  iframe.style.opacity = 0;
  iframe.style.pointerEvents = 'none';
  iframe.addEventListener('load', () => {
    iframe.contentWindow.addEventListener('resize', handler);
  });
  element.appendChild(iframe);

  return getRemoveListenerById(id);
};
