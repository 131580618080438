import React, { memo } from 'react';
// Components
import { Button } from '@beef/ui-kit';

import { BaseActionBar, BaseContent, BasePopUp } from './components';

type ModalData = {
  confirmButton?: string;
  description?: string;
  imageSrc?: string;
  rejectButton?: string;
  title?: string;
};

type TariffUpChangedPopUpContentProps = {
  alt?: string;
  data?: ModalData;
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onReject?: () => void;
};

export const CommonPopup = memo(
  ({ onClose, onConfirm, data, onReject, alt, isOpen }: TariffUpChangedPopUpContentProps) => {
    const { title, description, imageSrc, confirmButton, rejectButton } = data || {};
    const img = imageSrc ? { src: imageSrc, alt: alt ?? '' } : undefined;
    return (
      <BasePopUp
        actionBar={
          <BaseActionBar>
            {confirmButton && (
              <Button onClick={onConfirm} size="m" variant="main">
                {confirmButton}
              </Button>
            )}
            {rejectButton && (
              <Button onClick={onReject} size="m" variant="tertiary">
                {rejectButton}
              </Button>
            )}
          </BaseActionBar>
        }
        isOpen={isOpen}
        onClose={onClose}
      >
        <BaseContent description={description} img={img} title={title} />
      </BasePopUp>
    );
  },
);
