import axios from 'axios';

import { ARCHIVE_API } from '../consts';
import { ResponseType } from '../types';

export const fetchPaginatedActions = async (
  year: number | string,
  page: number | string,
): Promise<ResponseType> =>
  axios.get(`${ARCHIVE_API}/${year}/`, { params: { page } }).then(({ data }) => data);
