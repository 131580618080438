import { replaceNullWithUndefined } from 'utils/types';

import { selectLegacyModalState } from '../selectors';
import { EYandexPopupType } from '../types';
import { TModalProps } from './types';

type TState = ReturnType<typeof selectLegacyModalState>;

export const selectYandexPopups = (state: TState) => state[0];
export const selectPopupType = (state: TState) => state[1];
export const selectProductPopups = (state: TState) => state[3];
export const selectEndpoints = (state: TState) => state[4]?.endpoint;
export const selectIsModalLoading = (state: TState) => state[5];
export const selectIsModalOpen = (state: TState) => state[6];

export const getSelectYandexPopupProps =
  (popupType: EYandexPopupType) =>
  (state: TState): TModalProps => {
    const yandexPopups = selectYandexPopups(state);
    const _popupType = selectPopupType(state);
    const isActive = _popupType === popupType;
    const _popupContent = yandexPopups?.[popupType as string];
    const popupContent = replaceNullWithUndefined(_popupContent || {});
    const isLoading = selectIsModalLoading(state);
    const isOpen = isActive && selectIsModalOpen(state);
    const shouldRender = isOpen; // TODO: Вероятно понадобится кастомизация

    return {
      isOpen,
      isLoading,
      popupContent: replaceNullWithUndefined(popupContent || {}),
      shouldRender,
    };
  };
