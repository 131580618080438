import { createReducer } from '@reduxjs/toolkit';

// Actions
import {
  resetUserContactFields,
  resetUserData,
  setFlat,
  setHouse,
  setIsHighSpeed,
  setPhoneNumber,
  setRegion,
  setStatusConnection,
  setStreet,
} from '../actions/orderTariffUserData';

// Constants
import { REQUEST_STATUS } from '../../constants';

export const orderTariffUserData = createReducer(
  {
    flat: '',
    userPhoneNumber: '',
    isHighSpeedTariff: false,
    house: { id: '', label: '' },
    street: { id: '', label: '' },
    selectedRegion: { id: '', label: '' },
    statusConnection: REQUEST_STATUS.isNone,
  },
  {
    [setRegion.type]: (state, { payload }) => {
      state.selectedRegion = payload;
    },
    [setStreet.type]: (state, { payload }) => {
      state.street = payload;
    },
    [setHouse.type]: (state, { payload }) => {
      state.house = payload;
    },
    [setFlat.type]: (state, { payload }) => {
      state.flat = payload;
    },
    [setPhoneNumber.type]: (state, { payload }) => {
      state.userPhoneNumber = payload;
    },
    [setStatusConnection.type]: (state, { payload }) => {
      state.statusConnection = payload;
    },
    [setIsHighSpeed.type]: (state, { payload }) => {
      state.isHighSpeedTariff = payload;
    },
    [resetUserContactFields.type]: (state) => {
      state.street = { id: '', label: '' };
      state.house = { id: '', label: '' };
      state.flat = '';
    },
    [resetUserData.type]: (state) => {
      state.selectedRegion = { id: '', label: '' };
      state.street = { id: '', label: '' };
      state.house = { id: '', label: '' };
      state.flat = '';
    },
  },
);
