import { useAppSelector } from 'constructorV1/store';
import { selectPresetsLoading } from 'constructorV1/components/constructorPage/PresetPickers/selectors/selectPresetsLoading';
import { selectSelectedAlerts } from 'constructorV1/components/tempComponents/AlertContainer/selectors/selectSelectesAlerts';

/** Хук подготовки данных подсказок ЕК */
export const usePrepareAlerts = () => {
  const isLoading = useAppSelector(selectPresetsLoading);
  const selectedAlerts = useAppSelector(selectSelectedAlerts);

  return { alerts: selectedAlerts, isLoading };
};
