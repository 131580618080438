import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from '@beef/ui-kit';
import { Heading } from '@beef/legacy-ui-kit';

import Picture from 'pages-components/Picture';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ServiceCard = ({ data }) => {
  const { imageUrl, title, metaDescription, fee, aboutLink } = data;

  return (
    <div className={cx('card')}>
      <header className={cx('card__header')}>
        <Picture
          alt="service type"
          className={cx('card__header-pic')}
          lazy
          mobileSrc={imageUrl}
          src={imageUrl}
        />
        <Heading className={cx('card__title')} level={4}>
          {title}
        </Heading>
      </header>
      <div className={cx('card__description')}>{metaDescription}</div>
      <div className={cx('card__price')}>{`${fee.fee === null ? 0 : fee.fee} ₽/ ${
        fee.feeType
      }`}</div>
      <div className={cx('card__link')}>
        <Link
          buttonSize="m"
          buttonVariant="main"
          href={aboutLink}
          isFullWidth
          target="_blank"
          variant="button"
        >
          Подробнее
        </Link>
      </div>
    </div>
  );
};

ServiceCard.propTypes = {
  data: PropTypes.shape({
    imageUrl: PropTypes.string,
    metaDescription: PropTypes.string,
    fee: PropTypes.shape({
      fee: PropTypes.number,
      feeType: PropTypes.string,
    }),
    title: PropTypes.string,
    aboutLink: PropTypes.string,
  }),
};
