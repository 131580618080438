import { shallowEqual } from '@beef/utils';

import { useAppSelector } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

/** Селектор выборки данных для заголовка страници настройки ШПД */
export const useSelectUserAddress = () => {
  const { title, description } =
    useAppSelector(
      (state) => ({
        title: state.common.content.pageTitle,
        description: state.common.content.pageDescription,
      }),
      shallowEqual,
    ) ?? {};

  return { title, description };
};
