import React, { FC, useEffect, useRef, useState } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import { useOnScreenEffect } from '@beef/utils';

import { TOptionPickerTile } from '../../types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TOptionPickerPrice = Pick<TOptionPickerTile, 'isBlocked' | 'price'> & { unit?: string };

/** Компонент цены опции */
export const OptionPickerPrice: FC<TOptionPickerPrice> = ({ price, unit = '₽' }) => {
  const lastPrice = useRef<null | number>(null);

  /* Подсветка изменения цены опции. При изменении цены – блок подсвечен пока пользователь его не увидит */
  const [highlighted, setHighlighted] = useState(false);
  const nodeRef = useOnScreenEffect(() => highlighted && setHighlighted(false));

  useEffect(() => {
    if (lastPrice.current !== null) {
      setHighlighted(true);
    }
    if (lastPrice.current !== price) {
      lastPrice.current = price;
    }
  }, [price]);

  const priceContent = `${price} ${unit}`;

  return (
    <div className={cx('price', { _highlighted: highlighted })} ref={nodeRef}>
      <Text size="size5-r-s">{priceContent}</Text>
    </div>
  );
};
