import axios from 'axios';
import { equals, path, pathOr } from 'ramda';

import store from 'store';
import { formatPhone, unformatPhone } from 'utils/format-string';
import generateGUID from 'utils/guid-generator';
import { getCookie, setCookie } from 'utils/cookie';
import { transformRequest } from 'utils/globalAjaxHandler';
import { getAuthErrorByCode } from 'widgets/actions/authorization';
import {
  pushServiceConnectionErrorEvent,
  pushServiceConnectionSuccessEvent,
  pushServiceDisconnectionErrorEvent,
  pushShowServiceCancelPopupEvent,
  pushShowServiceConnectionPopupEvent,
  pushShowServiceOffPopupEvent,
} from 'utils/ga';
import { pushSmsFormEvent } from 'utils/analytics/tariffCard';
import {
  sendCvmAnalyticsEvent,
  sendCvmTariffAnalyticsEvent,
} from 'utils/analytics/cvmTariffAnalytics';
import { gaYandexPlusDisconnected } from 'utils/analytics/yandexTariffGA';
import { yandexPlusDisconnectedSuccessYMUpd } from 'utils/analytics/tariffsYandexMetrica';
import { pushServiceConnected, pushServiceDisconnected } from 'utils/analytics/services';
import { ymSetParams } from 'utils/ym';
import { YM_FULL_PARAMS } from 'utils/analytics/offsetLanding';
import { AUTH_YM_ACTIONS, AUTH_YM_EVENTS, YMAuthEvent } from 'utils/analytics/ymCommonEvents';
import { SERVICE_WRONG_REGION_API } from 'pages/ProductCard/constants';
import { fetchWrongRegionPopup } from 'pages/ProductCard/services/fetchWrongRegionPopup';

import { createRequestInProgressMsg, handleRoamingYm, roamingYmEventsTypes } from '../utils';
import {
  INIT_SERVICE_DATA,
  RESET_SERVICE_POPUP,
  SERVICE_IS_DISCONNECT,
  SET_SERVICE_CONNECTION_POPUP,
  SET_SERVICE_DISCONNECTION_POPUP,
  SET_SERVICE_STATUS_REQUEST_COMPLATE,
  SET_SERVICE_STATUS_REQUEST_ERROR,
  SET_SERVICE_STATUS_REQUEST_IN_PROGRESS,
  SET_SERVICE_STATUS_REQUEST_START,
  SET_SERVICE_WAITING_DISCONNECT_POPUP,
  SET_SERVICE_WAITING_POPUP,
  SET_SRV_CONNECTION_RESULT_POPUP,
  SET_SRV_DISCONNECTION_RESULT_POPUP,
  TOGGLE_SERVICE_CONNECTION_POPUP,
  TOGGLE_SERVICE_DISCONNECTION_POPUP,
} from './actionTypes';
import { setConfirmPopup } from './confirmNumber';
import { mapConfirmNumberPopup } from './tariffConnect';

let isFetching = false;
let isTimerLeft = false;
let isComplate = false;

/*
 * CVM Analytics
 */
const sendAnalyticsEvent = (campId, subgroupId) => {
  if (typeof window !== 'undefined') {
    const renderUrlMatch = window.location.href.match('detailsforlk');
    if (renderUrlMatch && campId && subgroupId) {
      sendCvmAnalyticsEvent([{ campId, subgroupId }], 'landWantMore', 'offers');
    }
  }
};

const getServiceData = () => {
  const service = pathOr({}, ['external', 'service'], store.getState());

  return {
    serviceName: service.pageTitle,
    soc: service.soc,
    marketCode: service.marketCode,
  };
};

export const setConnectionResult = (view) => ({
  type: SET_SRV_CONNECTION_RESULT_POPUP,
  payload: view,
});

export const setDisconnectionResult = (view) => ({
  type: SET_SRV_DISCONNECTION_RESULT_POPUP,
  payload: view,
});

export const setServiceConnectionPopup = (data) => ({
  type: SET_SERVICE_CONNECTION_POPUP,
  payload: data,
});

export const disconnectServiceRequest = ({ url, soc, serviceName, disconnectDate }) =>
  axios({
    url,
    data: { soc, serviceName, disconnectDate },
    method: 'post',
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  });

/**
 * Show error pop-up.
 * @param error {object} Error data object.
 */
const showError =
  (error = {}) =>
  (dispatch) => {
    const defaultErrorPopup = path(['external', 'service', 'errorPopup'], store.getState());

    dispatch(
      setConnectionResult({
        ...defaultErrorPopup,
        ...error,
      }),
    );
    dispatch(setServiceConnectionPopup({ isSubmitting: false }));
  };

/**
 * Show error pop-up.
 * @param error {object} Error data object.
 */
const showDisconnectError =
  (error = {}) =>
  (dispatch) => {
    const defaultErrorPopup = path(['external', 'service', 'errorPopup'], store.getState());
    dispatch(
      setDisconnectionResult({
        ...defaultErrorPopup,
        ...error,
        isPending: false,
      }),
    );
    // dispatch(setDisconnectionResult({ isPending: false }));
  };

/**
 * Initialize service in Redux store.
 * @param data {object} Service data.
 */
export const initServiceStore = (data) => (dispatch) => {
  dispatch({
    type: INIT_SERVICE_DATA,
    payload: data,
  });
};

/**
 * Initialize disconnection pop-up.
 * @param data {object} Pop-up data.
 */
export const initDisconnectionPopup = (data) => (dispatch) => {
  dispatch({
    type: SET_SERVICE_DISCONNECTION_POPUP,
    payload: data,
  });
};

const isEqualsDate = (date) => {
  const dateObj = new Date(date);
  const currentDateTime = new Date().getDate();
  return !equals(currentDateTime, dateObj.getDate()) && dateObj;
};

const httpRequestApi = (url, dataObj, params) =>
  axios({
    url,
    method: 'post',
    data: dataObj,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    params,
  })
    .then((response) => response.data)
    .catch((err) => {
      pushServiceConnectionErrorEvent({
        error: err,
        ...getServiceData(),
      });
    });

/**
 * Show/hide service disconnection pop-up.
 * @param opened {boolean} True, if need open.
 */
export const toggleDisconnectPopup = (opened) => (dispatch) => {
  dispatch({
    type: TOGGLE_SERVICE_DISCONNECTION_POPUP,
    payload: { popupIsActive: opened },
  });
};

/**
 * Cancel service connection.
 */
export const cancelConnectService = () => (dispatch) => {
  const state = store.getState();
  const service = pathOr({}, ['external', 'service'], state);
  const { soc } = service;

  httpRequestApi(service.cancelConnectUrl, {
    Soc: soc,
  }).then((data) => {
    if (data && data.view) {
      if (data.isSucceeded) {
        dispatch(setDisconnectionResult(data.view));
      } else {
        dispatch(showError(data.view));
        const error = data.view && data.view.content;
        pushServiceDisconnectionErrorEvent({ error, ...getServiceData() });
      }
    } else {
      dispatch(showError());
      const error = data.view && data.view.content;
      pushServiceDisconnectionErrorEvent({ error, ...getServiceData() });
    }
  });
};
/**
 * Init service connection pop-up.
 * @param connectPopupUrl {string} URL for request pop-up data.
 * @param alias {string} Service alias.
 * @param connect {string} Connect parameter value.
 * @param soc {string} Service SOC.
 * @param offerType {string || null} Тип оффера, берется из get-параметра
 * @param returnUrl {string || null} URL, на который перейдет пользователь после авторизации
 * @param servicesSource {0 || 1} params for public or not public services
 */
export const initConnectionPopup = (
  connectPopupUrl = '/authorizationpopup/',
  alias = '',
  connect = 'true',
  soc = '',
  offerType = null,
  returnUrl = null,
  servicesSource = 0,
) =>
  setServiceConnectionPopup({
    connectPopupUrl,
    alias,
    connect,
    soc,
    offerType,
    returnUrl,
    servicesSource,
  });

/**
 * TODO: временная мера, выпилить после реализации
 * https://task.beeline.ru/browse/BCDEV-7450 (бек начнет возвращать нужные данные)
 */
export const initConvertToEsimServiceAuth = (connectPopupUrl, extraData) =>
  setServiceConnectionPopup({ connectPopupUrl, extraData });

export const initServiceConnectionPopup = ({
  connectPopupUrl = '/authorizationpopup/',
  alias = '',
  connect = 'true',
  soc = '',
  offerType = null,
  returnUrl = null,
  servicesSocs = null,
  ignoreConflictSocs = null,
}) =>
  setServiceConnectionPopup({
    connectPopupUrl,
    alias,
    connect,
    soc,
    offerType,
    returnUrl,
    servicesSocs,
    ignoreConflictSocs,
  });

export const resetPopupResult = () => (dispatch) => {
  dispatch({ type: RESET_SERVICE_POPUP });
  dispatch(initConnectionPopup());
};

/**
 * Reset SMS login form data.
 */
export const resetSmsForm = () => (dispatch) => {
  dispatch(setConfirmPopup({ service: false }));
  dispatch(setServiceConnectionPopup({ step: 'smsFormPhone', error: {} }));
};

export const resetToSmsForm = (dispatch) => {
  dispatch(setServiceConnectionPopup({ step: 'smsFormPhone', error: null }));
};

/**
 * Reset results pop-ups.
 */
export const resetResultPopup = () => (dispatch, getState) => {
  if (path(['external', 'serviceConnectionPopup', 'serviceProcess'], getState()) !== 'error') {
    dispatch(setConnectionResult(null));
    dispatch(setDisconnectionResult(null));
  }
};

export const serviceConnectionPopupResponseData = (data, dispatch) => {
  if (data.isSucceeded) {
    if (data.view && data.view.type === 'noMobileInternet') {
      dispatch(setConnectionResult(data.view));
    } else {
      dispatch(
        setServiceConnectionPopup({
          data: data.view || null,
          isPending: false,
        }),
      );

      // AB testing for service connection popup
      const getABtestCookie = getCookie('autoConnectServiceABtest');
      if (getABtestCookie && getABtestCookie === 'active') {
        dispatch(connectService(new Date(), null));
      }
    }
  } else {
    dispatch(showError(data.view));

    const error = data.view && data.view.content;
    pushServiceConnectionErrorEvent({ error, ...getServiceData() });
  }
};

export const serviceConnectionPopupErrorData = (dispatch) => {
  dispatch(setServiceConnectionPopup({ data: null, isPending: false }));
  dispatch(showError());
  pushServiceConnectionErrorEvent({ ...getServiceData() });
};

export const showServiceConnectionPopupWithOfferCode =
  ({ isAuthenticated, serviceName, soc, marketCode, offerCode }) =>
  (dispatch) => {
    dispatch({
      type: TOGGLE_SERVICE_CONNECTION_POPUP,
      payload: {
        popupIsActive: true,
      },
    });

    dispatch(setServiceConnectionPopup({ isPending: true, data: null, phone: '+7 ' }));

    const popupData = pathOr({}, ['external', 'serviceConnectionPopup'], store.getState());

    httpRequestApi(popupData.connectPopupUrl, {
      alias: popupData.alias,
      soc: popupData.soc,
      connect: popupData.connect,
      offerType: popupData.offerType,
      offerCode,
    })
      .then((data) => serviceConnectionPopupResponseData(data, dispatch))
      .catch(() => serviceConnectionPopupErrorData(dispatch));

    pushShowServiceConnectionPopupEvent({
      isAuthenticated,
      serviceName,
      soc,
      marketCode,
    });
  };

export const showWrongRegionPopup = (previousRegion, serviceAlias) => (dispatch) => {
  dispatch(setServiceConnectionPopup({ isPending: true, data: null }));
  dispatch({
    type: TOGGLE_SERVICE_CONNECTION_POPUP,
    payload: {
      popupIsActive: true,
    },
  });
  fetchWrongRegionPopup(SERVICE_WRONG_REGION_API, {
    serviceAlias,
    previousRegion,
  })
    .then(
      (data) =>
        data?.view &&
        dispatch(
          showError({
            ...data.view,
            type: 'serviceWrongRegion',
          }),
        ),
    )
    .catch((error) => console.error(error));
};

/**
 * Get initial data for connection pop-up.
 * @returns {AxiosPromise}
 */
const getServiceConnectionPopupData = () => {
  const popupData = pathOr({}, ['external', 'serviceConnectionPopup'], store.getState());

  return httpRequestApi(popupData.connectPopupUrl, {
    alias: popupData.alias,
    soc: popupData.soc,
    socs: popupData.servicesSocs,
    ignoreConflictSocs: popupData.ignoreConflictSocs,
    connect: popupData.connect,
    offerType: popupData.offerType,
    returnUrl: popupData.returnUrl,
    servicesSource: popupData.servicesSource,
  });
};

/**
 * Open/close connection pop-up.
 * @param popupIsActive {boolean} Pop-up state.
 */
export function toggleServiceConnectionPopup(popupIsActive = false) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_SERVICE_CONNECTION_POPUP,
      payload: { popupIsActive },
    });

    // AB testing for service connection popup
    // Start pending immediately
    const getABtestCookie = getCookie('autoConnectServiceABtest');
    if (getABtestCookie && getABtestCookie === 'active') {
      dispatch({
        type: SET_SERVICE_WAITING_POPUP,
        payload: { opened: true, step: 'pending' },
      });
    }

    if (popupIsActive) {
      dispatch(setServiceConnectionPopup({ isPending: true, data: null }));

      getServiceConnectionPopupData()
        .then((data) => serviceConnectionPopupResponseData(data, dispatch))
        .catch(() => serviceConnectionPopupErrorData(dispatch));
    }
  };
}

/**
 * Show/hide waiting pop-up.
 * @param opened {boolean} True, if need show.
 */
export const toggleWaitingPopup =
  (opened = false) =>
  (dispatch) => {
    dispatch({
      type: SET_SERVICE_WAITING_POPUP,
      payload: { opened },
    });
  };

export const checkServiceStateTimer = (callback, id, dispatch, timeout) => {
  let timeLeft = timeout || 150;

  const timerInterval = setInterval(() => {
    if (isFetching) {
      return;
    }

    const state = store.getState();
    const { serviceProcess } = pathOr({}, ['external', 'serviceConnectionPopup'], state);

    timeLeft -= 1;

    if (timeLeft === 0) {
      isTimerLeft = true;
      callback(id, dispatch);
      clearInterval(timerInterval);
      return;
    }

    if (serviceProcess === 'error' || serviceProcess === 'complete') {
      clearInterval(timerInterval);
      return;
    }

    if (!isComplate) {
      callback(id, dispatch);
    }
  }, 1000);
};

/**
 * Disconnect service.
 * @param disconnectDate {object} Date.
 * @param url {string} Disconnect service URL.
 * @param serviceSoc {string} Service SOC.
 * @param serviceName {string} Service name.
 * @param options {object} Additional params.
 */
// eslint-disable-next-line no-unused-vars
export const disconnectService =
  (disconnectDate, url, serviceSoc, serviceName, options) => (dispatch) => {
    const state = store.getState();
    const service = pathOr({}, ['external', 'service'], state);

    const { soc, pageTitle } = service;
    const serviceDate = isEqualsDate(disconnectDate);

    dispatch(setServiceConnectionPopup({ isPending: true }));

    dispatch({
      type: SET_SERVICE_WAITING_DISCONNECT_POPUP,
      payload: { opened: true, step: 'pending' },
    });

    dispatch({
      type: SET_SERVICE_STATUS_REQUEST_START,
      payload: {
        serviceProcess: 'progress',
        serviceType: 'disconnect',
        serviceDateTime: {
          date:
            serviceDate &&
            serviceDate.toLocaleString('ru', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            }),
        },
      },
    });

    httpRequestApi(service.disconnectServiceUrl, {
      soc,
      serviceName: pageTitle,
      disconnectDate: disconnectDate.toISOString(),
    }).then((data) => {
      if (data && data.isSucceeded) {
        const { view } = data;

        const { requestStatus, requestId } = data.view;

        let payloadData = {};

        if (requestId === -1) {
          dispatch({
            type: SET_SERVICE_STATUS_REQUEST_IN_PROGRESS,
            payload: {
              serviceProcess: 'inProgress',
              ...createRequestInProgressMsg(view, pageTitle, 'отключение услуги'),
            },
          });

          return;
        }

        if (requestStatus === 'OPEN' || requestStatus === 'IN_PROGRESS') {
          payloadData = {
            ...view,
            opened: true,
            step: 'main',
          };

          // eslint-disable-next-line no-use-before-define
          checkServiceStateTimer(
            checkDisconnectServiceRequestState,
            requestId,
            dispatch,
            view.timeout,
          );
        } else {
          // Успешное отключение Яндекс Плюс
          gaYandexPlusDisconnected();
          yandexPlusDisconnectedSuccessYMUpd(document.location.href);
          payloadData = {
            result: view,
            opened: true,
            step: 'result',
          };
        }

        dispatch({
          type: SET_SERVICE_WAITING_DISCONNECT_POPUP,
          payload: payloadData,
        });
      } else {
        dispatch({
          type: SET_SERVICE_STATUS_REQUEST_ERROR,
          payload: {
            serviceProcess: 'error',
            familyIsConnected: false,
          },
        });

        dispatch(showDisconnectError(data.view));
      }
    });
  };

const checkDisconnectServiceRequestState = (requestId, dispatch) => {
  const state = store.getState();
  const service = pathOr({}, ['external', 'service'], state);
  const serviceDisconnectionPopup = pathOr({}, ['external', 'serviceDisconnectionPopup'], state);

  const { soc } = service;
  const { checkDisconnectRequestStatusUrl } = serviceDisconnectionPopup;

  isFetching = true;

  httpRequestApi(checkDisconnectRequestStatusUrl, {
    requestId,
    Soc: soc,
  })
    .then((data) => {
      if (data && data.isSucceeded) {
        const { view } = data;
        const { requestStatus } = view;
        const { waitingPopup } = serviceDisconnectionPopup;

        if (requestStatus === 'IN_PROGRESS') {
          if (isTimerLeft) {
            dispatch({
              type: SET_SERVICE_STATUS_REQUEST_COMPLATE,
              payload: {
                serviceProcess: 'timeout',
                serviceType: 'disconnect',
              },
            });

            dispatch({
              type: SET_SERVICE_WAITING_POPUP,
              payload: {
                opened: true,
                step: 'timeout',
              },
            });
          }
        } else if (requestStatus === 'COMPLETE') {
          isComplate = true;

          dispatch({
            type: SET_SERVICE_STATUS_REQUEST_COMPLATE,
            payload: {
              serviceProcess: 'complete',
              familyIsConnected: false,
            },
          });

          dispatch(setDisconnectionResult(view));

          pushServiceConnectionSuccessEvent({
            isConnected: false,
            ...getServiceData(),
          });

          dispatch({
            type: SERVICE_IS_DISCONNECT,
            payload: {
              isDisconnected: true,
              statusText: 'Эта услуга отключена',
            },
          });

          dispatch({
            type: SET_SERVICE_WAITING_DISCONNECT_POPUP,
            payload: {
              ...waitingPopup,
              result: view,
              opened: true,
              step: 'result',
            },
          });
        } else {
          dispatch({
            type: SET_SERVICE_STATUS_REQUEST_ERROR,
            payload: {
              serviceProcess: 'error',
              familyIsConnected: false,
              view,
            },
          });

          pushServiceConnectionErrorEvent({
            error: data && view && view.content,
            ...getServiceData(),
          });

          dispatch(showDisconnectError());
        }
      } else {
        dispatch({
          type: SET_SERVICE_STATUS_REQUEST_ERROR,
          payload: {
            serviceProcess: 'error',
          },
        });

        dispatch(showDisconnectError());
      }

      isFetching = false;
    })
    .catch(() => {
      isFetching = false;
    });
};

/**
 * Make connect service request.
 * @param soc {string} Service SOC.
 * @param connectDate {Date} Connection date.
 * @param disconnectDate {Date} Disconnection date.
 */
export const connectService =
  ({ connectDate, disconnectDate }) =>
  (dispatch) => {
    const state = store.getState();
    const connectData = path(['external', 'serviceConnectionPopup', 'data'], state);
    const connectForm = connectData.serviceConnectForm;
    const { soc } = connectData;
    const url = path(['serviceConnectForm', 'connectServiceUrl'], connectData);
    const { basketSocs } = connectForm;
    const isMultiOfferVal = connectForm.isMultiOffer;
    const serviceDate = isEqualsDate(connectDate);
    const { service } = path(['external'], state);
    const { ymType } = service || {};

    // CVM offers code
    const tariffConnectData = path(['external', 'tariffConnectionPopup', 'data'], state);
    // ---------------

    dispatch(setServiceConnectionPopup({ isPending: true }));
    handleRoamingYm({
      type: ymType,
      actionType: roamingYmEventsTypes.applyService,
      value: serviceDate ? 'apply_other_date' : 'apply_now',
    });

    dispatch({
      type: SET_SERVICE_WAITING_POPUP,
      payload: { opened: true, step: 'pending' },
    });

    dispatch({
      type: SET_SERVICE_STATUS_REQUEST_START,
      payload: {
        serviceProcess: 'progress',
        serviceType: 'connect',
        serviceDateTime: {
          date:
            serviceDate &&
            serviceDate.toLocaleString('ru', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            }),
        },
      },
    });

    let params = {
      soc,
      connectDate,
      disconnectDate,
      featureParams: null,
      basketSocs,
    };

    if (connectForm && connectForm.campId && connectForm.subgroupId) {
      params.campId = connectForm.campId;
      params.subgroupId = connectForm.subgroupId;
    }

    if (isMultiOfferVal !== null) {
      params = {
        isMultiOffer: isMultiOfferVal,
        ...params,
      };
    }

    httpRequestApi(url, params).then((data) => {
      if (data && data.isSucceeded) {
        const { view } = data;
        const { requestId, requestStatus } = view;

        let payloadData = {};

        if (view && view.type === 'multiOfferOk') {
          payloadData = {
            result: view,
            opened: true,
            step: 'result',
          };
        }

        dispatch(setServiceConnectionPopup({ serviceRequestId: requestId }));

        if (requestStatus === 'OPEN' || requestStatus === 'IN_PROGRESS') {
          payloadData = {
            ...view,
            opened: true,
            step: 'main',
          };

          pushShowServiceConnectionPopupEvent({
            inProcess: true,
            ...getServiceData(),
          });

          checkServiceStateTimer(checkServiceRequestState, requestId, dispatch, view.timeout);
        } else if (requestStatus === 'COMPLETE' || requestStatus === 'REQUEST_SENT') {
          const serviceData = getServiceData();
          payloadData = {
            result: view,
            opened: true,
            step: 'result',
          };

          pushServiceConnectionSuccessEvent({
            isConnected: requestStatus === 'COMPLETE',
            ...serviceData,
          });

          handleRoamingYm({ type: ymType, actionType: roamingYmEventsTypes.requestSuccess });
        }

        dispatch({
          type: SET_SERVICE_WAITING_POPUP,
          payload: payloadData,
        });
      } else {
        let error = null;

        // Проверяет наличие сценария CVM offer, если есть
        // выводит CVM ошибку, иначе стандартный сценарий;
        if (tariffConnectData && tariffConnectData.type === 'offers') {
          error = tariffConnectData.offers[0].errorPopup;

          // Отправляем Downsell событие аналитики
          if (connectForm.campId && connectForm.subgroupId) {
            sendCvmTariffAnalyticsEvent([
              {
                place: 'downsale',
                state: 'landWantMore',
                subgroupId: connectForm.subgroupId,
                campId: connectForm.campId,
              },
            ]);
          }
        } else if (data && data.view) {
          error = data.view;
        }

        sendAnalyticsEvent(connectForm.campId, connectForm.subgroupId);
        handleRoamingYm({ type: ymType, actionType: roamingYmEventsTypes.requestFailed });

        dispatch({
          type: SET_SERVICE_STATUS_REQUEST_ERROR,
          payload: {
            serviceProcess: 'error',
            familyIsConnected: false,
          },
        });

        dispatch({
          type: SET_SERVICE_WAITING_POPUP,
          payload: {
            result: error,
            opened: true,
            step: 'result',
          },
        });

        dispatch(showError(error || {}));
      }

      setCookie({
        name: 'autoConnectServiceABtest',
        value: '',
        ttl: -1,
      });
    });
  };

/**
 * Check tariff connection status.
 * @param requestId {number} ID of tariff request.
 */
export const checkServiceRequestState = (requestId, dispatch) => {
  const state = store.getState();
  const { serviceConnectionPopup, service } = path(['external'], state);
  const { ymType } = service || {};

  const connectData = serviceConnectionPopup.data;
  const { soc } = connectData;
  // eslint-disable-next-line no-unused-vars
  const { checkRequestStateUrl, campId, subgroupId } = path(['serviceConnectForm'], connectData);

  isFetching = true;

  httpRequestApi(checkRequestStateUrl, {
    requestId,
    Soc: soc,
  })
    .then((data) => {
      if (data && data.isSucceeded) {
        const { view } = data;
        const { requestStatus } = view;
        const { waitingPopup } = serviceConnectionPopup;
        const completeActionData = {
          type: SET_SERVICE_STATUS_REQUEST_COMPLATE,
          payload: {
            serviceProcess: 'timeout',
            serviceType: 'connect',
            familyIsConnected: false,
          },
        };

        if (requestStatus === 'REQUEST_SENT') {
          dispatch(completeActionData);

          isFetching = true;
          return;
        }

        if (isTimerLeft && requestStatus === 'IN_PROGRESS') dispatch(completeActionData);

        if (requestStatus === 'COMPLETE') {
          dispatch({
            type: SET_SERVICE_STATUS_REQUEST_COMPLATE,
            payload: {
              serviceProcess: 'complete',
              familyIsConnected: true,
            },
          });

          pushServiceConnectionSuccessEvent({
            isConnected: requestStatus === 'COMPLETE',
            ...getServiceData(),
          });

          handleRoamingYm({ type: ymType, actionType: roamingYmEventsTypes.requestSuccess });

          dispatch({
            type: SET_SERVICE_WAITING_POPUP,
            payload: {
              ...waitingPopup,
              result: view,
              opened: true,
              step: 'result',
            },
          });
        }
      } else {
        dispatch({
          type: SET_SERVICE_STATUS_REQUEST_ERROR,
          payload: {
            serviceProcess: 'error',
            familyIsConnected: false,
          },
        });

        dispatch(showError(data.view));
        handleRoamingYm({
          type: ymType,
          actionType: roamingYmEventsTypes.requestFailed,
          value: 'error_blocked',
        });
      }

      isFetching = false;
    })
    .catch(() => {
      isFetching = false;
    });
};

/**
 * Request one-time password for auth.
 * @param phone {string} Formatted phone number.
 * @param captcha {string} Captcha code.
 * @param captchaKey {string} Captcha key.
 */
export const sendPhone =
  ({ phone, captcha, captchaKey }) =>
  async (dispatch) => {
    const state = store.getState();
    const data = path(['external', 'serviceConnectionPopup', 'data'], state);
    const url = path(['smsConfirmationForm', 'oneTimePasswordSmsUrl'], data);
    const checkRegionUrl = path(['smsConfirmationForm', 'checkRegionUrl'], data);

    const login = unformatPhone(phone);
    dispatch(setServiceConnectionPopup({ phone, isPending: true, error: {} }));

    pushSmsFormEvent('submit');
    ymSetParams(YM_FULL_PARAMS.YM_POPUP_AUTH_BY_SMS_SEND_PHONE);
    try {
      let sameRegion = true;
      let resp = { data: { view: {} } };

      if (checkRegionUrl) {
        resp = await axios.get(
          `${checkRegionUrl}${checkRegionUrl.indexOf('?') > -1 ? '&' : '?'}phone=${login}`,
          {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          },
        );

        sameRegion = resp.data.isSucceeded;
      }

      if (sameRegion) {
        try {
          await axios({
            url,
            method: 'get',
            params: { login, hash: generateGUID(), captcha, captchaKey },
            transformRequest,
          });
          dispatch(
            setConfirmPopup({
              ...mapConfirmNumberPopup(data.smsConfirmationForm),
              ...resp.data.view,
              phone,
              service: true,
            }),
          );
          dispatch(setServiceConnectionPopup({ isPending: false }));
          pushSmsFormEvent('success');
          ymSetParams(YM_FULL_PARAMS.YM_POPUP_AUTH_BY_SMS_INPUT_CODE);
        } catch (error) {
          const code = error.response && error.response.data && error.response.data.ErrorCode;
          dispatch(setServiceConnectionPopup({ ...getAuthErrorByCode(code), isPending: false }));
          pushSmsFormEvent('error');
        }
      } else {
        dispatch(showError(resp.data && resp.data.view));
        pushSmsFormEvent('error');
      }
    } catch ({ response }) {
      if (response && response.data && response.data.view) {
        dispatch(showError(response.data.view));
      } else {
        dispatch(showError());
      }
      pushSmsFormEvent('error');
    }
  };

export const sendLogin = (form, formCallback, ymType) => async (dispatch) => {
  const state = store.getState();

  const data = path(['external', 'serviceConnectionPopup', 'data'], state);
  const checkRegionUrl = path(['loginForm', 'checkRegionUrl'], data);
  const { login } = form;
  const phone = login[0] === '9' ? formatPhone(`7${login}`) : login;
  dispatch(
    setServiceConnectionPopup({
      contactPhone: phone,
      phone,
      isSubmitting: true,
    }),
  );

  try {
    let resp = { data: { view: {} } };
    let sameRegion = true;

    if (checkRegionUrl) {
      resp = await axios.get(
        `${checkRegionUrl}${checkRegionUrl.indexOf('?') > -1 ? '&' : '?'}login=${login}`,
        {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        },
      );
      sameRegion = resp.data.isSucceeded;
    }

    if (sameRegion) {
      formCallback();
      if (ymType) {
        handleRoamingYm({ type: ymType, actionType: roamingYmEventsTypes.authSuccess });
      }
    } else {
      let view = resp.data && resp.data.view;
      if (view.buttonUrl) {
        dispatch(
          setServiceConnectionPopup({
            data: {
              ...data,
              loginForm: {
                ...data.loginForm,
                returnUrl: view.buttonUrl,
                login: form.login,
                password: form.password,
              },
            },
            isSubmitting: true,
          }),
        );
        view = { ...view, buttonUrl: null, step: 'submitLogin', isWrongRegionError: true };
      }
      handleRoamingYm({
        type: ymType,
        actionType: roamingYmEventsTypes.requestFailed,
        value: 'other_region',
      });
      dispatch(showError(view));
      dispatch(setServiceConnectionPopup({ isSubmitting: false }));
      YMAuthEvent.visible(
        AUTH_YM_EVENTS.CONNECT_SERVICE_PASSWORD,
        AUTH_YM_ACTIONS.VISIBLE_WRONG_REGION,
      );
    }
  } catch ({ response, message }) {
    if (response && response.data && response.data.view) {
      dispatch(showError(response.data.view));
    } else {
      dispatch(showError());
    }
    YMAuthEvent.error(AUTH_YM_EVENTS.CONNECT_SERVICE_PASSWORD, {
      error: response?.data?.ErrorCode || message,
    });

    setCookie({
      name: 'autoConnectServiceABtest',
      value: '',
      ttl: -1,
    });
  }
};

export const showServiceConnectionPopup =
  ({ isAuthenticated, serviceName, soc, marketCode }) =>
  (dispatch) => {
    dispatch(toggleServiceConnectionPopup(true));
    pushShowServiceConnectionPopupEvent({
      isAuthenticated,
      serviceName,
      soc,
      marketCode,
    });
  };

export const showServiceDisconnectionPopup =
  ({ isConnectedLater, serviceName, soc, marketCode }) =>
  (dispatch) => {
    dispatch(toggleDisconnectPopup(true));
    if (isConnectedLater) {
      pushShowServiceCancelPopupEvent({
        serviceName,
        soc,
        marketCode,
      });
    } else {
      pushShowServiceOffPopupEvent({
        serviceName,
        soc,
        marketCode,
      });
    }
  };

export const onConnectResultShown = () => (_, getState) => {
  pushServiceConnected(path(['external', 'service'], getState()));
};

export const onDisconnectResultShown = () => (_, getState) => {
  pushServiceDisconnected(path(['external', 'service'], getState()));
};
export { httpRequestApi };
