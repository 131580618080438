import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
// Components
import { Text } from '@beef/ui-kit';

import { PackageInfo } from '../PackageInfo';
// Utils
import { hasPackageSize } from './utils';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Packages = ({ title, packages, hasZTariff }) => {
  const showMainTitle = hasPackageSize(packages);
  return (
    <div className={cx('wrapper')}>
      {title && showMainTitle && (
        <div className={cx('wrapper__title')}>
          <Text color="grey95" size="size4-m">
            {title}
          </Text>
        </div>
      )}
      <ul className={cx('wrapper__packages')}>
        {packages?.map(
          (currentPackage) =>
            (currentPackage?.value || !!currentPackage?.remainingSize) && (
              <PackageInfo
                hasZTariff={hasZTariff}
                initialPeriod={currentPackage.initialPeriod}
                key={currentPackage.unit}
                period={currentPackage.period}
                price={currentPackage.price}
                remainingSize={+currentPackage.remainingSize}
                unit={currentPackage.unit.toLowerCase() === 'штук' ? 'SMS' : currentPackage.unit}
                unitPrice={currentPackage.unitPrice}
                value={currentPackage.value}
              />
            ),
        )}
      </ul>
    </div>
  );
};

Packages.propTypes = {
  title: PropTypes.string,
  hasZTariff: PropTypes.bool,
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      unit: PropTypes.string,
      price: PropTypes.number,
      value: PropTypes.number,
      period: PropTypes.string,
      unitPrice: PropTypes.string,
      remainingSize: PropTypes.number,
    }),
  ),
};
