import React, { FC } from 'react';
import classNames from 'classnames/bind';

// TODO: Не забыть про импорт после выноса в отдельный пакет!
import {
  OptionPicker,
  TOptionPicker,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/OptionPicker';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TOptionPickerContainer = {
  blockingTooltipText: string;
  handleChange: (soc: string, optionTitle: string, isActive: boolean) => void;
  options?: TOptionPicker['options'];
};

/** Обертка над компонентом OptionPicker для опций */
export const OptionPickerContainer: FC<TOptionPickerContainer> = ({
  options = [],
  handleChange,
  blockingTooltipText,
}) =>
  options.length ?
    <div className={cx('wrapper')}>
      <OptionPicker
        blockingTooltipText={blockingTooltipText}
        onChange={handleChange}
        options={options}
      />
    </div>
  : null;
