import { Modal } from '@beef/ui-kit';
import React, { useCallback, useState } from 'react';

import { CommonActionBar } from '../CommonActionBar';
import { ModalContent } from '../../TabModal/ModalContent';
import { TModalProps } from '../types';

type TCommonModalContainerProps = {
  actionBar: React.ReactElement;
  content: React.ReactElement;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export const CommonModalContainer: React.FC<Partial<TCommonModalContainerProps>> = ({
  content,
  actionBar,
  isLoading = true,
  isOpen = false,
  onClose = () => {},
}) => (
  <Modal
    actionBar={!isLoading ? actionBar : undefined}
    isLoading={isLoading}
    isOpen={isOpen}
    onClose={onClose}
    size="m"
  >
    {content}
  </Modal>
);

export const CommonModal: React.FC<TModalProps> = ({
  isOpen,
  isLoading,
  popupContent = {},
  onConfirm,
  onCancel,
}) => (
  <CommonModalContainer
    actionBar={<CommonActionBar confirmText={popupContent?.btnText} onConfirm={onConfirm} />}
    content={<ModalContent {...popupContent} />}
    isLoading={isLoading}
    isOpen={isOpen}
    onClose={onCancel}
  />
);

export const CommonModalWithFocusedCancel: React.FC<TModalProps> = ({
  isOpen,
  isLoading,
  popupContent = {},
  onConfirm,
  onCancel,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const onClose = useCallback(() => {
    if (isFocused) {
      onCancel?.();
    }
  }, [isFocused]);

  return (
    <div
      onBlur={() => {
        setIsFocused(false);
      }}
      onFocusCapture={() => {
        setIsFocused(true);
      }}
    >
      <CommonModalContainer
        actionBar={<CommonActionBar confirmText={popupContent?.btnText} onConfirm={onConfirm} />}
        content={<ModalContent {...popupContent} />}
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
      />
    </div>
  );
};
