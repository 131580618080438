import React from 'react';
import { connect } from 'react-redux';

import { LoadingWrap } from 'pages-components/LoadingWrap';

import {
  selectFinalStoriesListSorted,
  selectFinalStoriesLoading,
  setCurrentStory,
} from '../../../../store/finalStories';
import { ChartItem } from '../../ChartItem';

const ChartLayout = ({ chart, isLoading, onItemClick }) => (
  <LoadingWrap isLoading={isLoading} minHeight="200px">
    {chart.map((item, index) => (
      <ChartItem
        author={item.author}
        count={item.votesCount}
        index={index}
        key={item.id}
        onClick={() => onItemClick(item.id)}
        percentage={item.votesPercentage}
      />
    ))}
  </LoadingWrap>
);

const mapStateToProps = (state) => ({
  chart: selectFinalStoriesListSorted(state),
  isLoading: selectFinalStoriesLoading(state),
});

const mapDispatchToProps = {
  onItemClick: setCurrentStory,
};

export const Chart = connect(mapStateToProps, mapDispatchToProps)(ChartLayout);
