import { pushDataLayer } from '../ga';

export const orderSimMapOpenPopup = ({ soc, tariffName }) => {
  pushDataLayer({
    event: 'event_popup_interaction',
    itemProduct: 'Мобильная связь',
    itemCat: 'Tariffs',
    itemCatNumber: 'Специальное предложение',
    itemPodCatNumber: 'Бесплатный номер',
    itemTitle: tariffName,
    itemSoc: soc,
  });
};

export const orderSimMapErrorPopup = ({ errorMessage, tariffName, soc }) => {
  pushDataLayer({
    event: 'event_newsim_order_error',
    connectionErrorType: errorMessage,
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Tariffs',
    ordered_itemTitle: tariffName,
    ordered_itemSoc: soc,
    ordered_itemMethod: 'SimNumber',
    ordered_itemCatNumber: 'Специальное предложение',
    ordered_itemPodCatNumber: 'Бесплатный номер',
  });
};

export const orderSimMapSuccessPopup = ({ orderId, tariffName, soc }) => {
  pushDataLayer({
    event: 'event_newsim_order_success',
    orderId,
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemCat: 'Tariffs',
    ordered_itemCatNumber: 'Специальное предложение',
    ordered_itemPodCatNumber: 'Бесплатный номер',
    ordered_itemTitle: tariffName,
    ordered_itemSoc: soc,
    ordered_itemMethod: 'SimNumber',
    ordered_itemShipping: 'Самовывоз',
  });
};
