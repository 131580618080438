import { TPartnerProductInfoResponse } from 'pages/ProductCard/Tariff/types';
import { calculatePaymentPeriod } from 'pages/ProductCard/Tariff/utils';

import { TariffUpMetaData } from '../../../CharactersTabs/store/TariffUpMetaData';
import { getTabsContent } from '../../utils';
import { getPartnerProductList } from '../api/getPartnerProductList';
import { CHANGE_SUBSCRIPTION_STATUS, SET_CHOSEN_TAB, SET_PRODUCT_DATA } from './constants';
import { dispatcher } from './provider';

const setProductData = (object) => ({
  type: SET_PRODUCT_DATA,
  payload: object,
});

const setChosenTab = (string) => ({
  type: SET_CHOSEN_TAB,
  payload: string,
});

const changeSubscriptionStatus = (number) => ({
  type: CHANGE_SUBSCRIPTION_STATUS,
  payload: number,
});

const getPartnerProductsInfo = async (soc?: string) => {
  const response = await getPartnerProductList({ soc });
  const { isSucceeded, ...unprocessedProducts } = response.data as TPartnerProductInfoResponse;

  if (isSucceeded && unprocessedProducts) {
    const products = {
      ...unprocessedProducts,
      productList: unprocessedProducts.productList?.map((subscription) => ({
        ...subscription,
        paymentPeriod: calculatePaymentPeriod(subscription || {}),
      })),
    };

    const {
      productList,
      content: { defaultTabLabel },
    } = products;

    const tabs = getTabsContent(productList, defaultTabLabel);

    if (products) {
      TariffUpMetaData.Products = products;
    }

    dispatcher(setProductData({ ...products, tabs }));
  }
};

export { changeSubscriptionStatus, getPartnerProductsInfo, setChosenTab, setProductData };
