import React, { useCallback } from 'react';
import { TariffTile as TariffTileOriginal } from '@beef/smart-kit';

export const TariffTile = (props) => {
  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      window.open(props.link, '_blank');
    },
    [props.link],
  );

  return <TariffTileOriginal {...props} onLinkClick={handleClick} />;
};
