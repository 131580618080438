import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AlignedGrid } from '../AlignedGrid';
import { GridContext } from './context';

export const ScrollableGridItem = ({ children, className }) => {
  const queryClass = useContext(GridContext);
  return React.Children.map(children, (child, rowIndex) => (
    <AlignedGrid.Item className={[queryClass, className].join(' ')} key={+rowIndex}>
      {child}
    </AlignedGrid.Item>
  ));
};

ScrollableGridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
