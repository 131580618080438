import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { AddressContent, TAddressContent } from './components/AddressContent';
import { EditButton, TEditButton } from './components/EditButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TConnectionAddressEditor = {
  addressContentProps: TAddressContent;
  editButtonProps: TEditButton;
};

/** Компонент редактирования адреса подключения на странице настройки ШПД */
export const ConnectionAddressEditor: FC<TConnectionAddressEditor> = ({
  editButtonProps,
  addressContentProps,
}) => (
  <section className={cx('wrapper')}>
    <AddressContent {...addressContentProps} />
    <EditButton {...editButtonProps} />
  </section>
);
