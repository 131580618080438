import React from 'react';

// Components
import { RegionContainer } from './RegionContainer';
import { InputsContainer } from './InputsContainer';

export const AddressRequestInputsSection = () => (
  <>
    <RegionContainer />
    <InputsContainer />
  </>
);
