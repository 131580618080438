import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
// Components
import { ReloadIcon, Text } from '@beef/ui-kit';

import SuccessIcon from 'pages/ProductCard/Tariff/components/Check/Header/SuccessIcon';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Header = ({
  title,
  hasNotification,
  refundDiscount,
  handleRefundDiscount,
  yourTariff,
  activeCharacter,
}) => {
  const fullTitle = yourTariff ? `${title} подключён ` : title;
  const color = '#13171b';
  return (
    <div className={cx('header')}>
      <div className={cx('header__title')}>
        <Text size="size3-m" testName="Check_header">
          {fullTitle}
        </Text>
        {yourTariff && <SuccessIcon color={color} />}
      </div>
      {hasNotification && (
        <div className={cx('header__refund')} onClick={handleRefundDiscount}>
          <ReloadIcon fill="#727281" size={20} />
          <Text color="grey60" size="size5-r-s">
            {refundDiscount}
          </Text>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  hasNotification: PropTypes.bool,
  refundDiscount: PropTypes.string,
  handleRefundDiscount: PropTypes.func,
};

export { Header };
