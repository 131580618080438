import { RootStateOrAny, connect } from 'react-redux';

import LottieAnimation from 'pages-components/LottieAnimation';
import { ThunkDispatchType } from 'pages/SPN/types';

import { setLottieAnimation } from '../../../api';

export const Loader = connect(
  (state: RootStateOrAny, ownProps) => {
    const { name } = ownProps;
    const { lottieData } = state?.external?.spn || {};

    // доп.проверка, если приходит не объект, а строка, и чтобы не падал LottieAnimation
    const animationData = typeof lottieData?.[name] === 'object' ? lottieData?.[name] : null;

    return {
      animationData,
    };
  },
  (dispatch: ThunkDispatchType) => ({
    callbackFunc: (name: string) => dispatch(setLottieAnimation(name)),
  }),
)(LottieAnimation);
