import { createReducer } from '@reduxjs/toolkit';

// Actions
import {
  resetCurrentUserTariff,
  setCurrentUserTariff,
  setIsFinishedSettings,
  setUserAddressConnect,
  setUserNumber,
} from '../actions/totalSettings';

export const totalSettings = createReducer(
  {
    tariff: {},
    address: '',
    userNumber: '',
    isArchive: false,
    isConnected: false,
    isFinishedSettings: false,
  },
  {
    [setUserNumber.type]: (state, { payload }) => {
      state.userNumber = payload;
    },
    [setCurrentUserTariff.type]: (state, { payload }) => {
      state.tariff = payload;
    },
    [setIsFinishedSettings.type]: (state, { payload }) => {
      state.isFinishedSettings = payload;
    },
    [setUserAddressConnect.type]: (state, { payload }) => {
      state.address = payload;
    },
    [resetCurrentUserTariff.type]: (state) => {
      state.tariff = {};
      state.address = '';
      state.userNumber = '';
      state.isFinishedSettings = false;
    },
  },
);
