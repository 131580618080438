import axios from 'axios';

import { templateParser } from 'utils/format-string';
import { poll } from 'utils/timed-functions';
import { RecursivePartial } from 'utils/types';

import {
  EPollingStatus,
  EStep,
  IInitializeResponse,
  IMetaDataResponse,
  IPollingResponse,
  IResponseWithView,
  TForm,
  TOptions,
} from './types';

/**
 * Класс, который содержит в себе логику, необходимую для общения
 * с сервисом подключения и отключения (изначально для услуги "поддержка при нуле")
 *
 * Этапы:
 *
 * 1. Проверка доступности сервиса (step GetMetdata)
 * 2. Обращение к сервису (step Initiate)
 * 3. Поллинг до ответа, ошибки или таймаута (step Polling)
 */
export class BaseServicePoller<PServiceName extends string, PServiceField extends string> {
  constructor(
    private serviceName: PServiceName,
    private serviceField: PServiceField,
    private serviceUrl: string,
    private soc: string,
    private interval: number,
    private timeout: number,
  ) {}

  /**
   * Метод, который возвращает сообщение об ошибке с общей необходимой информацией
   */
  protected getErrorMessage(vars: {
    errors?: Record<string, unknown>;
    isSucceeded: boolean;
    statusCode?: number;
    step: EStep;
    warning?: string;
  }) {
    const errorTemplate =
      'Cannot perform {{step}} step for the {{serviceName}}; IsSucceeded: {{isSucceeded}}; StatusCode: {{statusCode}}; Warning: {{warning}};';
    const _vars = { ...vars, serviceName: this.serviceName };

    return templateParser(errorTemplate, _vars);
  }

  /**
   * Если ответ от сервиса не соответствует контракту, кидаем ошибку
   */
  protected throwIfNeeded(
    step: EStep,
    data: RecursivePartial<IResponseWithView<{ [key: string]: TForm }>>,
  ) {
    if (data.isSucceeded === false || !data?.view) {
      throw new Error(
        this.getErrorMessage({
          step,
          isSucceeded: !!data?.isSucceeded,
          statusCode: data.view?.connectionStatus,
          warning: data.view?.[this.serviceName as string]?.warningText,
          errors: data.errors,
        }),
      );
    }
  }

  /**
   * Этап №1 соединения с сервисом.
   *
   * Получаем урл, по которому будем общаться дальше
   */
  protected async getServiceMetaData() {
    const { data } = await axios.post<RecursivePartial<IMetaDataResponse>>(this.serviceUrl, {
      soc: this.soc,
      featureParams: null,
    });

    this.throwIfNeeded(EStep.GetMetadata, data);

    // NOTE: Для правильной отработки инференции в TS
    if (!data.view) throw Error('No "view" field found');

    return data.view;
  }

  /**
   * Этап №2 соединения с сервисом.
   *
   * Получаем единичный ответ при обращении к урлу
   */
  protected async initiate(meta: RecursivePartial<IMetaDataResponse['view']>) {
    const url = meta?.[this.serviceName as string]?.[this.serviceField as string] as
      | string
      | undefined;

    if (!url) throw new Error(`No url for ${this.serviceName} initiation request`);

    const { data } = await axios.post<RecursivePartial<IInitializeResponse>>(url, {
      soc: this.soc,
      alias: null,
      featureParams: null,
    });

    this.throwIfNeeded(EStep.Initiate, data);

    // NOTE: Для правильной отработки инференции в TS
    if (!data.view) throw Error('No "view" field found');

    return data.view;
  }

  /**
   * Этап №3 соединения с сервисом.
   *
   * Поллим статус до успеха, ошибки или таймаута
   */
  protected async pollUntilCompletion(
    initiationResponse: RecursivePartial<IInitializeResponse['view']>,
    options?: TOptions,
  ) {
    const url =
      initiationResponse?.[this.serviceName as string]?.checkRequestStateUrl ||
      options?.fallbackPollingUrl;

    if (!url) throw new Error('No request status url for polling');

    const { requestId } = initiationResponse;

    const result = await poll(
      async () => {
        const { data } = await axios.post<RecursivePartial<IPollingResponse>>(url, {
          requestId,
          soc: this.soc,
        });

        return data;
      },
      (data) => {
        switch (data.view?.requestStatus) {
          case EPollingStatus.Complete:
            return false;

          case EPollingStatus.InProgress:
            return true;

          default:
            throw new Error(JSON.stringify(data));
        }
      },
      this.interval,
      this.timeout,
    );

    this.throwIfNeeded(EStep.Polling, result);

    // NOTE: Для правильной отработки инференции в TS
    if (!result.view) throw new Error('No "view" field found');

    if (result.view?.requestStatus !== EPollingStatus.Complete) {
      throw new Error('Polling unsuccessful');
    }

    return result.view;
  }
}
