import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import HouseConnection from './components/HouseConnection';
import TariffsList from './components/TariffsList';
import OrderBackCall from './components/OrderBackCall';
import ComparesTable from './components/ComparesTable';
import YouAreFavorites from './components/YouAreFavorites';
import DescriptionsWrapper from './components/DescriptionsWrapper';
import styles from './styles.pcss';
import withStoreProvider from '../hoc/withStoreProvider';

const cx = classNames.bind(styles);

const StreetCardPage = (props) => {
  const {
    currentRegion,
    otherConnectedStreets,
    streetPageBaseUrl,
    streetsUrl,
    street,
    tariffs,
    houses,
    showCaptcha,
    texts,
    callBackRequestData,
    orderFormData,
    availableScheduleData,
  } = props;

  const {
    quickOrderPopupTexts,
    houseConnection,
    tariffsListData,
    comparesTable,
    youAreFavorites,
    orderBackCall,
    descriptions,
  } = texts;

  const currentCity = {
    id: currentRegion.id,
    label: currentRegion.title,
  };

  return (
    <div className={cx('wrapper')}>
      <HouseConnection
        currentCity={currentCity}
        quickOrderPopupTexts={quickOrderPopupTexts}
        showCaptcha={showCaptcha}
        street={street}
        streetsUrl={streetsUrl}
        {...houseConnection}
      />
      <div className={cx('shouldBeLast')}>
        <DescriptionsWrapper
          {...descriptions}
          city={currentCity}
          houses={houses}
          otherConnectedStreets={otherConnectedStreets}
          street={street}
          streetPageBaseUrl={streetPageBaseUrl}
          tariffs={tariffs}
          tariffsTexts={tariffsListData}
        />
      </div>
      <TariffsList
        availableScheduleData={availableScheduleData}
        backCallPopupData={callBackRequestData}
        orderFormData={orderFormData}
        tariffs={tariffs}
        {...tariffsListData}
      />
      <OrderBackCall showCaptcha={showCaptcha} {...orderBackCall} />
      <ComparesTable {...comparesTable} />
      <YouAreFavorites
        quickOrderPopupTexts={quickOrderPopupTexts}
        showCaptcha={showCaptcha}
        {...youAreFavorites}
      />
    </div>
  );
};

StreetCardPage.defaultProps = {
  showCaptcha: false,
};

StreetCardPage.propTypes = {
  texts: PropTypes.shape({
    quickOrderPopupTexts: PropTypes.object,
    houseConnection: PropTypes.object,
    tariffsListData: PropTypes.object,
    comparesTable: PropTypes.object,
    youAreFavorites: PropTypes.object,
    orderBackCall: PropTypes.object,
    descriptions: PropTypes.object,
    feedback: PropTypes.object,
  }),
  callBackRequestData: PropTypes.object,
  orderFormData: PropTypes.object,
  availableScheduleData: PropTypes.object,
  tariffs: PropTypes.array,
  streetsUrl: PropTypes.string,
  street: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  }),
  showCaptcha: PropTypes.bool,
  houses: PropTypes.array,
  currentRegion: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  otherConnectedStreets: PropTypes.array,
  streetPageBaseUrl: PropTypes.string,
};

export default withStoreProvider(StreetCardPage);
