import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Switch, Text } from '@beef/ui-kit';

// Constants
import { FREE_TEXT } from '../../../../../../containers/ConstructorTariff/constants';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const RightSide = ({ unit, price, checked, unitOld, showDiscount, currentDiscountPrice }) => (
  <div className={cx('wrapper')}>
    <Switch checked={checked} disabled name={price} />
    {!price && (
      <Text color="grey60" size="size5-r-l">
        {FREE_TEXT}
      </Text>
    )}
    {!!price && (
      <div className={cx('wrapper__price')}>
        <Text color="grey60" size="size5-r-l">
          {`${showDiscount ? currentDiscountPrice : price} ${unit}`}
        </Text>
      </div>
    )}
    {showDiscount && (
      <div className={cx('wrapper__discount')}>
        <Text color="grey40" size="size5-r-l">
          {`${price} ${unitOld}`}
        </Text>
      </div>
    )}
  </div>
);

RightSide.propTypes = {
  unit: PropTypes.string,
  price: PropTypes.number,
  checked: PropTypes.bool,
  discount: PropTypes.shape({
    unit: PropTypes.string,
    oldPrice: PropTypes.number,
  }),
  unitOld: PropTypes.string,
  showDiscount: PropTypes.bool,
  currentDiscountPrice: PropTypes.number,
};

export { RightSide };
