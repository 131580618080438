import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';
import ButtonWithPreloader from 'pages-components/ButtonWithPreloader';

import TextContainer from '../TextContainer/TextContainer';
import commonStyles from '../SharingService.pcss';

const cx = classNames.bind(commonStyles);

class DisconnectWarningPopup extends Component {
  state = {};

  getContent() {
    if (this.props.texts) {
      return this.props.texts;
    }

    const { number, status } = this.props.number;
    const variables = { number };

    let text = null;

    if (status === 'invited') {
      text = (
        <TextContainer
          className={cx('description')}
          path={['texts', 'disconnectConfirmTextSent']}
          variables={variables}
        />
      );
    } else if (this.props.connectedNumber > 1) {
      text = (
        <TextContainer
          className={cx('description')}
          path={['texts', 'disconnectConfirmTextConnected']}
          variables={variables}
        />
      );
    } else {
      text = (
        <TextContainer
          className={cx('description')}
          path={['texts', 'disconnectIfOne']}
          variables={variables}
        />
      );
    }

    return {
      text,
      title: (
        <TextContainer
          className={cx('title')}
          path={[
            'texts',
            status === 'invited' ? 'disconnectConfirmTitleSent' : 'disconnectConfirmTitle',
          ]}
        />
      ),
      buttonText: (
        <TextContainer
          path={[
            'texts',
            status === 'invited' ?
              'disconnectConfirmButtonTextSent'
            : 'disconnectConfirmButtonText',
          ]}
        />
      ),
    };
  }

  render() {
    const { number, status } = this.props.number;
    const strings = this.getContent();

    return (
      <PopupStateless onClose={this.props.onClose} opened={this.props.opened}>
        <div className={cx('content')}>
          <Heading level={1}>{strings.title}</Heading>
          {strings.text}
          <div>
            <div className={cx('popupButton')}>
              <ButtonWithPreloader
                className="big"
                disabled={this.state.loading}
                isPreloader={this.state.loading}
                onClick={() => {
                  this.setState({ loading: true });
                  this.props.onRemoveNumber(number);
                }}
                wide
              >
                {strings.buttonText}
              </ButtonWithPreloader>
            </div>
          </div>
          {status === 'connected' && this.props.onSwitchNumber ?
            <div className={cx('links')}>
              <Link onClick={() => this.props.onSwitchNumber(number, status)}>
                <TextContainer path={['texts', 'disconnectWarningLinkText']} />
              </Link>
            </div>
          : null}
        </div>
      </PopupStateless>
    );
  }
}

DisconnectWarningPopup.propTypes = {
  opened: PropTypes.bool,
  number: PropTypes.object,
  onClose: PropTypes.func,
  onRemoveNumber: PropTypes.func,
  onSwitchNumber: PropTypes.func,
  connectedNumber: PropTypes.number,
  texts: PropTypes.object,
};

export default DisconnectWarningPopup;
