import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

// Components
import { StatefulSearchInput } from '@beef/ui-kit';

import { getCitySearchResults } from 'pages/Anniversary/api';

import { InputLayout } from '../InputsContainer/InputLayout';

// Actions
import { resetCurrentUserTariff } from '../../../../../store/actions/totalSettings';
import {
  resetUserData,
  setRegion,
  setStatusConnection,
} from '../../../../../store/actions/orderTariffUserData';

// Selectors
import { getCityLabel } from '../../../../../store/selectors/userData';
import { getUserSelectedRegion } from '../../../../../store/selectors/orderTariffUserData';

// Hooks
import { useFetchRegion } from '../../../../../Hooks/useFetchRegion';

// Utils

// Constants
import { DELAY_TIME } from '../../../../../constants';

// PropTypes
import { addressShape } from '../../../../../PropTypes';

// Styles
import styles from '../InputsContainer/styles.pcss';

const cx = classNames.bind(styles);

const RegionContainerLayout = ({ text, region, ...props }) => {
  const { regionValue, handleChangeRegionInput, onSelectRegionFromList } = useFetchRegion({
    region,
    ...props,
  });

  return (
    <div className={cx('wrapper')}>
      <InputLayout type="city" {...{ text }}>
        <StatefulSearchInput
          debounceInterval={DELAY_TIME}
          leftAside={null}
          onChange={handleChangeRegionInput}
          onSearch={getCitySearchResults}
          onSelect={onSelectRegionFromList}
          value={region.label || regionValue}
        />
      </InputLayout>
    </div>
  );
};

const mapDispatchToProps = {
  setChosenRegion: setRegion,
  resetInputs: resetUserData,
  setAddressChecked: setStatusConnection,
  resetSettingsTariff: resetCurrentUserTariff,
};

const mapStateToProps = (state) => ({
  text: getCityLabel(state),
  region: getUserSelectedRegion(state),
});

RegionContainerLayout.propTypes = {
  region: addressShape,
  text: PropTypes.string,
  resetInputs: PropTypes.func,
  setChosenRegion: PropTypes.func,
  setAddressChecked: PropTypes.func,
};

export const RegionContainer = connect(mapStateToProps, mapDispatchToProps)(RegionContainerLayout);
