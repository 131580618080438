import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from '@beef/ui-kit';

import { getOnlyNumbres } from 'utils/format-string';

import { GenericInput } from './GenericInput/index';
import { getDefCodes } from '../../../store/selectors/numbers';
import { getFilterPlaceholder } from '../../../store/selectors/content';
import { mapDropdownOptions } from './constatns';
import { FILTER_SIMILAR } from '../../../constants';
import { FilterInputShape } from '../../../shapes';

export const SimilarInputLayout = ({
  query,
  defCode,
  onQueryChange,
  onDefCodeChange,
  defCodes,
  placeholder,
}) => {
  const isEmpty = !defCodes || defCodes.length === 0;

  return (
    <GenericInput
      defCodeNode={
        <Dropdown
          disabled={isEmpty}
          emptyValueText="–––"
          name="defcode"
          onChange={onDefCodeChange}
          options={isEmpty ? [] : mapDropdownOptions(defCodes)}
          value={defCode}
        />
      }
      mask={{
        mask: '_',
        format: '###–##–##',
      }}
      onChange={(value) => onQueryChange(getOnlyNumbres(value))}
      placeholder={placeholder}
      value={query}
    />
  );
};

SimilarInputLayout.propTypes = FilterInputShape;

export const SimilarInput = connect((state) => ({
  defCodes: getDefCodes(state),
  placeholder: getFilterPlaceholder(FILTER_SIMILAR, state),
}))(SimilarInputLayout);
