import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Button, Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';

// Components
import Grid from 'pages-components/Grid';
import { ServiceRegular } from 'pages-components/Tiles';

import FavoriteNumberWizardModal from './components/FavoriteNumberWizardModal';

// Hocs
import FavoriteNumberServiceCardHoc from './hocs/FavoriteNumberServiceCardHoc';
import FavoriteNumberWizardModalHoc from './hocs/FavoriteNumberWizardModalHoc';

// Style
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FavoriteNumberButton = ({ onClick, buttonText }) => (
  <div className={cx('contentButton')}>
    <Button className={[cx('big'), cx('bigButtonText')]} onClick={() => onClick(true)} wide>
      {buttonText}
    </Button>
  </div>
);

FavoriteNumberButton.propTypes = {
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
};

const FavoriteNumberServiceCard = ({
  favoriteNumberContent,
  getFavoriteNumberData,
  favoriteNumber,
  productParameters,
  isPagePreloader,
  description,
  modalData,
  subTitle,
  toggleModal,
  opened,
  title,
}) => (
  <Grid.ProductCard className={cx('serviceCard')} subTitle={subTitle} title={title}>
    {description && (
      <div className={cx('description')} dangerouslySetInnerHTML={{ __html: description }} />
    )}
    <div className={cx('container')}>
      {isPagePreloader ?
        <div className={cx('preloader')}>
          <Preloader />
        </div>
      : <>
          {/** Отрисовываем блок с любимый номером если
             массив favoriteNumbers пришел не пустой */}
          {favoriteNumber && (
            <>
              {/** Параметр "любимый номер" */}
              <Grid.ProductParameters>
                <ServiceRegular
                  description={favoriteNumber.description}
                  value={favoriteNumber.value}
                />
              </Grid.ProductParameters>

              {/** Кнопка смены красивого номера. Данные для кнопки
                 приходят в запросе /favoritenumberapi/ */}
              <FavoriteNumberButton
                buttonText={favoriteNumberContent.changeButtonText}
                onClick={toggleModal}
              />
            </>
          )}

          {/** Список параметров услуги */}
          {productParameters && (
            <Grid.ProductParameters>
              {productParameters.map((item, index) => (
                <ServiceRegular key={index} {...item} />
              ))}
            </Grid.ProductParameters>
          )}

          {/** Если с бэка пришли данные favoriteNumbers пустые,
             выводим кнопку добавления номмера */}
          {!favoriteNumber && (
            <FavoriteNumberButton
              buttonText={favoriteNumberContent.addButtonText}
              onClick={toggleModal}
            />
          )}

          {/** Модальное окно дабавления/удаления любимого номера */}
          {modalData && (
            <FavoriteNumberWizardModalHoc
              closeModal={toggleModal}
              data={modalData}
              getFavoriteNumberData={getFavoriteNumberData}
              opened={opened}
            >
              <FavoriteNumberWizardModal />
            </FavoriteNumberWizardModalHoc>
          )}
        </>
      }
    </div>
  </Grid.ProductCard>
);

FavoriteNumberServiceCard.propTypes = {
  productParameters: PropTypes.array,
  favoriteNumber: PropTypes.shape({
    value: PropTypes.string,
    description: PropTypes.string,
    changeButtonText: PropTypes.string,
  }),
  getFavoriteNumberData: PropTypes.func,
  favoriteNumberContent: PropTypes.object,
  opened: PropTypes.bool,
  modalData: PropTypes.object,
  isPagePreloader: PropTypes.bool,
  description: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  toggleModal: PropTypes.func,
};

export default (props) => (
  <Provider store={store}>
    <FavoriteNumberServiceCardHoc store={store} {...props}>
      <FavoriteNumberServiceCard />
    </FavoriteNumberServiceCardHoc>
  </Provider>
);
