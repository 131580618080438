import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, CollapsedContent, Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FAQ = ({ articles, mobile }) => {
  const elements = articles.map(({ content, title }, index) =>
    mobile ?
      <div key={index} title={title}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    : <CollapsedContent
        contentClass={cx('content')}
        key={index}
        title={title}
        titleClass={cx('title')}
      >
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </CollapsedContent>,
  );

  return (
    <div className={cx('component')}>
      <Heading level={1}>Frequently asked questions</Heading>
      <div className={cx('inner')}>
        {mobile ?
          <Accordion mobile>{elements}</Accordion>
        : elements}
      </div>
    </div>
  );
};

FAQ.propTypes = {
  articles: PropTypes.array,
  mobile: PropTypes.bool,
};

export default FAQ;
