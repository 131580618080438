import { isEmpty, path, pathOr } from 'ramda';

import store from 'store';
import Ctx from 'pages-components/Ctx';

export const getTariffData = (getState) => path(['external', 'tariff', 'data'], getState());

export const tariffData = store.createAction({
  path: ['tariff', 'data'],
  defaultState: {},
  dispatches: ['set', 'update'],
  name: 'tariff_data',
});

export const servicesData = store.createAction({
  path: ['tariff', 'data'],
  defaultState: { services: [] },
  dispatches: ['update'],
  name: 'tariff_services',
});

export const toggleService = (serviceName, value) => (dispatch, getState) => {
  const tariffDataObj = getTariffData(getState);
  const texts = pathOr({}, ['sharing', 'texts'], tariffDataObj);
  const info = pathOr({}, ['sharing', 'info'], tariffDataObj);
  const invites = pathOr([], ['invites'], info);

  let stringValue;

  if (value === true) {
    stringValue = 'CONNECTED';
  } else if (value === false) {
    stringValue = 'NOT_CONNECTED';
  } else {
    stringValue = value;
  }

  dispatch(
    servicesData.update({
      services: [
        {
          title: texts.servicesShareTitle,
          icon: null,
          isConnected:
            Ctx.service.serviceNameConst === serviceName ? stringValue : !isEmpty(invites),
          price: `${info.price} ${info.unit}`,
        },
      ],
    }),
  );
};

export const updatePrice = (serviceName, value) => (dispatch, getState) => {
  const tariffDataObj = getTariffData(getState);
  const service = pathOr([], ['service'], tariffDataObj);
  const info = pathOr({}, ['sharing', 'info'], tariffDataObj);

  dispatch(
    servicesData.update({
      services: [
        {
          ...service,
          price: Ctx.service.serviceNameConst === serviceName ? value : info.price,
          initialPrice: value || info.price,
        },
      ],
    }),
  );
};

export const resetPrice = (serviceName) => (dispatch, getState) => {
  const tariffDataObj = getTariffData(getState);
  const service = pathOr([], ['service'], tariffDataObj);

  if (!isEmpty(service)) {
    dispatch(
      servicesData.update({
        services: [
          {
            ...service,
            price:
              Ctx.service.serviceNameConst === serviceName ?
                service[0].initialPrice
              : service[0].price,
          },
        ],
      }),
    );
  }
};
