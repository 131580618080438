import { MutableRefObject } from 'react';

import { getAdditionalYmPushPayload, getYmPushPayload, handleClick } from '../tempYmUtils';
import { useAppSelector } from '../../../../store';
import { useOnScreenAnalytics } from '../../../../tempAnalytics/hooks/useOnScreenAnalytics';

/** Селектор выборки контента для промо-баннера под чеком */
export const useSelectPromo = (bannerRef: MutableRefObject<HTMLDivElement | null>) => {
  const content = useAppSelector((state) => state.common?.content);
  const { promoBanner } = content || {};
  const { link, image, title, bgColor, description } = promoBanner || {};

  /* Флаг отвечающий за отображение промо-баннера */
  const isShowPromoBanner = Boolean(title && description);

  /* Проп для контентной части промо-баннера */
  const contentProps = { title, description };

  /* Отправка метрики по просмотру блока с разными параметрами на разные адреса */
  useOnScreenAnalytics({ ref: bannerRef, payload: getYmPushPayload(link, title) });
  useOnScreenAnalytics({
    ref: bannerRef,
    payload: getAdditionalYmPushPayload(link, title),
    isForce: true,
  });

  /* Отправка метрики  по клику на блок с разными параметрами на разные адреса */
  const onClick = () => {
    handleClick(link, title);
  };

  return { link, image, bgColor, contentProps, isShowPromoBanner, onClick };
};
