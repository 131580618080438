export const PARTNER_SERIVCE_MODAL_ACTION = 'partner-service/modal-action';
export const ON_MOUNT_ACTION = 'meta/on-mount-action';

export const TOGGLE_FAQ_STATE_ID = 'analytics/toggle-faq-state-id';
export const ANALYTICS_MODAL_BUTTON_CLICK = 'analytics/modal-button-click-action';
export const ANALYTICS_FAQ_CLICK = 'analytics/faq-click-action';
export const ANALYTICS_SUBSCRIPTION_CLICK = 'analytics/subscription-click-action';

export const SET_HAS_MOUNTED = 'meta/set-has-mounted';

export enum EAction {
  SetPartnerProduct = 'data/set-partner-product',
  SetIsConnected = 'data/set-is-connected',
}

export const CONNECTED_TEXT = 'Услуга подключена';
