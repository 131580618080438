import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Carousel } from '@beef/smart-kit';

import { useGetDimensionGroup, useIsDesktopDimension } from 'utils/hooks/useGetDimensionGroup';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const SLIDES_VIEW_MOBILE = 1;
const SLIDES_VIEW_TABLET = 2;
const SLIDES_VIEW_DESKTOP = 3;
const ENABLED_CAROUSEL = 2;

export const CarouselComponent = ({
  children,
  isPaginate,
  spaceBetween,
  hasCarousel,
  itemsCount,
}) => {
  const dimension = useGetDimensionGroup();
  const isDesktop = useIsDesktopDimension();

  const getCarouselSizeView = () => {
    if (dimension === 'mobile') {
      return SLIDES_VIEW_MOBILE;
    }
    if (dimension === 'desktop') {
      return SLIDES_VIEW_DESKTOP;
    }
    return SLIDES_VIEW_TABLET;
  };
  return !isDesktop && hasCarousel && itemsCount >= ENABLED_CAROUSEL ?
      <Carousel
        className={cn('carousel')}
        settings={{
          ...{
            pagination: {
              clickable: isPaginate,
            },
            spaceBetween,
          },
          slidesPerView: getCarouselSizeView(),
        }}
      >
        {children}
      </Carousel>
    : children;
};

CarouselComponent.propTypes = {
  children: PropTypes.node,
  isPaginate: PropTypes.bool,
  spaceBetween: PropTypes.number,
  itemsCount: PropTypes.number,
  hasCarousel: PropTypes.bool,
};
