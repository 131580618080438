import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TExplanation = {
  explanation?: string;
  subExplanation?: string;
};

/** Заголовок и саб-заголовок для описания условий пассивок */
export const Explanation: FC<TExplanation> = ({ explanation, subExplanation }) => (
  <div className={cx('wrapper')}>
    {explanation && (
      <Text color="grey60" size="size5-r-s">
        {explanation}
      </Text>
    )}
    {subExplanation && (
      <Text color="grey60" size="size5-r-s">
        {subExplanation}
      </Text>
    )}
  </div>
);
