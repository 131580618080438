import React, { useRef } from 'react';
import { Faq, PageContainer } from '@beef/smart-kit';
// @ts-expect-error ошибочно НЕ импортируется FaqPropsInterface из @beef/smart-kit
import type { FaqPropsInterface } from '@beef/smart-kit/components/FAQ/types';

import { useOnScreenAnalytics } from 'pages/TariffsCatalog2021/hooks/useOnScreenAnalytics';
import { useFaqAnalyticsProps } from 'pages/TariffsCatalog2021/hooks/useFaqAnalyticsProps';

type TFAQContainer = {
  authStatus: 'az' | 'nz';
  faq: FaqPropsInterface;
};

/* Компонент обертка над блоком FAQ.
 * Включает в себя аналитику */
export const FAQContainer = ({ faq, authStatus }: TFAQContainer) => {
  /* Устанавливаем presetsRef на обертку FAQ */
  const faqRef = useRef(null);

  /* Подготовка данных для аналитики в FAQ */
  const { content, onClick, payloadView } = useFaqAnalyticsProps({ faq, authStatus });

  /* Отправка аналитики если блок видим больше 2х секунд */
  useOnScreenAnalytics({
    ref: faqRef,
    threshold: 0.25,
    payload: payloadView,
  });

  return (
    <PageContainer size="m">
      {/* Использование forwardRef напрямую невозможно из-за особенностей китового компонента Faq */}
      <div ref={faqRef}>
        <Faq {...content} onClick={onClick} />
      </div>
    </PageContainer>
  );
};
