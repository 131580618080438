import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { equals, pathOr } from 'ramda';

import { DefaultStatus } from 'pages/ProductCard/components/ServiceConnectionStatus';
import { openConfirmation } from 'pages/ProductCard/components/ServiceStatusPopup/actions';
import { translate } from 'utils/format-string';

import ServiceConnection from '../../ServiceConnection';
import { connectBlackNumbers, openBlackList } from '../../../actions/blackList';
import { initDisconnectionPopup, initServiceStore } from '../../../actions/serviceConnect';
import ServiceDisconnectPopup from '../../ServiceDisconnectPopup/ServiceDisconnectPopup';
import ServiceStatusPopup from '../../ServiceStatusPopup/ServiceStatusPopup';
import { getNumbersDiff } from '../utils/numbers';

class BlackListConnection extends PureComponent {
  render() {
    const { data, numbers, onConfirm, openBlackListPopup, onConnect, notChanged } = this.props;

    const { isConnectedLater, errorPopup } = data || {};
    const { added, removed } = numbers;

    const type = removed !== 0 && added === 0 ? 'remove' : 'add';
    const total = type === 'add' ? added : removed;

    let actionText = type === 'add' ? 'Настроить' : 'Отключить';
    actionText = added > 0 ? 'Подключить' : actionText;

    const afterText = total > 0 ? `${total} ${translate('number', total)}` : '';

    return (
      <ServiceConnection
        connectionText={`${actionText} ${afterText}`}
        showConnect
        {...this.props}
        onConnect={notChanged ? openBlackListPopup : onConnect}
        onLoadConnect={openBlackListPopup}
        statusComponent={DefaultStatus}
      >
        <ServiceDisconnectPopup isCancel={isConnectedLater} />
        <ServiceStatusPopup
          confirmationSubmit={onConfirm}
          errorContent={errorPopup}
          id="blackListService"
          isServiceConnection
          serviceName="Черный список"
        />
      </ServiceConnection>
    );
  }
}

BlackListConnection.propTypes = {
  data: PropTypes.object,
  openBlackListPopup: PropTypes.func,
  notChanged: PropTypes.bool,
  touched: PropTypes.bool,
  numbers: PropTypes.number,
  onConnect: PropTypes.func,
  onConfirm: PropTypes.func,
};

const mapStateToProps = (state) => {
  const phoneList = pathOr([], ['external', 'blackList', 'blackNumbers'], state);
  const serverList = pathOr([], ['external', 'blackList', 'blackNumbersFromServer'], state);

  return {
    numbers: getNumbersDiff(state),
    notChanged: equals(phoneList, serverList),
    touched: pathOr({}, ['external', 'blackList', 'serviceStatus'], state).touched,
  };
};

const mapDispatchToProps = (dispatch, { data }) => {
  const { serviceDisconnectionPopup } = data || {};
  dispatch(initServiceStore(data));
  dispatch(initDisconnectionPopup(serviceDisconnectionPopup || {}));
  return {
    openBlackListPopup: () => dispatch(openBlackList()),
    onConfirm: () => dispatch(connectBlackNumbers()),
    onConnect: () => dispatch(openConfirmation({ key: 'blackListService' })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlackListConnection);
