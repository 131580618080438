import React, { memo } from 'react';
import PropTypes from 'prop-types';
// Components
import { FamilyPlusIcon, Text } from '@beef/ui-kit';
// Styles
import classNames from 'classnames/bind';

import { translate } from 'utils/format-string';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const AmountNumbersTag = memo(({ amount, className }) => (
  <div className={cx('tag', className)}>
    <div className={cx('tag__text')}>
      <Text color="black" size="size4-m">
        +{amount}
      </Text>
      <Text color="black" size="size5-r-s">
        {translate('numbers', amount)}
      </Text>
    </div>
    <div className={cx('tag__icon')}>
      <FamilyPlusIcon size="xs" />
    </div>
  </div>
));

AmountNumbersTag.propTypes = {
  amount: PropTypes.number,
  className: PropTypes.string,
};
