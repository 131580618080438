import { InternetTvOutlineIcon, PhoneIcon, RouterOutlineIcon, TabletIcon } from '@beef/ui-kit';

export enum EDeviceAlias {
  Cellphone = 'cellphone',
  Tablet = 'planshet',
  UsbModel = 'usb-modem',
  AllInOne = 'all-in-one',
  Convergence = 'convergation',
}

export const DefinedIcons: Record<string, React.FC> = {
  [EDeviceAlias.Cellphone]: PhoneIcon,
  [EDeviceAlias.Tablet]: TabletIcon,
  [EDeviceAlias.UsbModel]: RouterOutlineIcon,
  [EDeviceAlias.AllInOne]: InternetTvOutlineIcon,
  [EDeviceAlias.Convergence]: InternetTvOutlineIcon,
};
