import { useCallback } from 'react';

import { TUseMapStateToProps } from 'pages/Services/ZeroBalanceSupport/enhancers/withContext/types';
import { selectDisconnectBlockedService } from 'pages/Services/ZeroBalanceSupport/selectors';
import { TZeroBalanceContext } from 'pages/Services/ZeroBalanceSupport/types';

import { TModalWrapperProps } from '../../../ModalWrapper/types';

export const useMapStateToProps: TUseMapStateToProps<TZeroBalanceContext, TModalWrapperProps> = ({
  state,
  ownProps: { setCurrentModal },
}) => {
  const modalData = selectDisconnectBlockedService(state);

  const onClose = useCallback(() => setCurrentModal(null, true), [setCurrentModal]);

  return {
    onClose,
    content: {
      modalData,
      actionBarProps: {
        loading: false,
        confirmButton: {
          content: modalData?.button,
        },
        rejectButton: {
          content: modalData?.buttonReject,
        },
      },
    },
  };
};
