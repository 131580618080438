const getAttr = (str, attr) => {
  /* eslint-disable-next-line */
  const attrResult = new RegExp(`${attr }\s*=\s*([\"'])([^\"']+)\\1`).exec(str);
  return attrResult ? attrResult[2] : null;
};

// Код применяется для модальных окон которые приходят из DPC, функция помогает отрисовать кнопки app store
export const getAppStoreButtonsFromHtml = (contentString) => {
  const buttonsObj = {
    ios: null,
    android: null,
  };

  const dataButtonAppSrc = getAttr(contentString, 'data-button-app-src');
  const dataButtonAppImg = getAttr(contentString, 'data-button-app-img');

  const dataButtonGoogleSrc = getAttr(contentString, 'data-button-google-src');
  const dataButtonGoogleImg = getAttr(contentString, 'data-button-google-img');

  if (dataButtonAppSrc && dataButtonAppImg) {
    buttonsObj.ios = {
      link: dataButtonAppSrc,
      image: dataButtonAppImg,
    };
  }

  if (dataButtonGoogleSrc && dataButtonGoogleImg) {
    buttonsObj.android = {
      link: dataButtonGoogleSrc,
      image: dataButtonGoogleImg,
    };
  }

  return { appButtons: buttonsObj.ios && buttonsObj.android ? buttonsObj : null };
};
