import { DEFAULT_INDEX } from 'constructorV1/constants';
import { TSectionsDO } from 'constructorV1/store/tariffSettingsSlice/types';

/** Функция проверки наличия стоимости у активных опций */
export const checkOptionCosts = (sections: TSectionsDO[], optionsSocs: string[]) => {
  /* Перебираем каждую доступную секцию опций и ищем в ней активные опции с ценой */
  const result = sections?.map((section) => {
    return section.options?.findIndex(
      (option) => optionsSocs?.includes(option.serviceCategory || '') && option.rcRate,
    );
  });

  /* Возвращаем true, если есть хотя бы один index >= 0 */
  return Boolean(result?.some((index) => index >= DEFAULT_INDEX));
};
