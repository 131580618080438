import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Компонент, для работы с фокусом\блюром внутри какой-то области
 * Вызывает `onFocus`, когда любой элемент внутри попадает в фокус(input, textarea, div, span...)
 * Вызывает `onBlur`, когда фокус уходит за пределы `FocusManager`а
 */
const FocusManager = ({ initialFocus, disabled, onFocus, onBlur, children }) => {
  const [isFocused, setIsFocused] = useState(initialFocus);

  // Если указать элементу tabIndex, то он генерирует события onFocus/onBlur
  // https://stackoverflow.com/a/18504302
  const tabIndexValue = useMemo(() => {
    disabled ? '' : -1;
  }, [disabled]);

  const handleFocus = useCallback(
    (event) => {
      // Если фокус сработал на одном из вложенных элементов в первый раз
      if (event.currentTarget.contains(event.target) && !isFocused) {
        onFocus(event);
        setIsFocused(true);
      }
    },
    [isFocused, onFocus],
  );

  const handleTimer = useCallback((currentTarget, fakeEvent) => {
    // setTimeout т.к. сначала document.activeElement равен body и только потом равен элементу у которого фокус
    // https://gist.github.com/pstoica/4323d3e6e37e8a23dd59
    const timer = setTimeout(() => {
      // Если текущий активный элемент не содержится внутри FocusManager'а
      if (!currentTarget?.contains(document.activeElement) && isFocused) {
        onBlur(fakeEvent);
        setIsFocused(false);
      }
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  const handleBlur = useCallback((event) => {
    const { currentTarget } = event;
    const fakeEvent = { ...event };
    // setTimeout т.к. сначала document.activeElement равен body и только потом равен элементу у которого фокус
    // https://gist.github.com/pstoica/4323d3e6e37e8a23dd59
    handleTimer(currentTarget, fakeEvent);
  }, []);

  return (
    <div onBlur={handleBlur} onFocus={handleFocus} tabIndex={tabIndexValue}>
      {children}
    </div>
  );
};

FocusManager.defaultProps = {
  initialFocus: false,
  // Если disabled == true, то перестаёт генерировать события onFocus/onBlur
  disabled: false,
  onFocus: () => {},
  onBlur: () => {},
};
FocusManager.propTypes = {
  initialFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default memo(FocusManager);
