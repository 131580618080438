type OpenDeviceModalType = {
  deviceSlug: string;
  isShopModalEnabled: boolean;
  setSlug: (id: string) => void;
};

export const onOpenDeviceModal = ({
  deviceSlug,
  isShopModalEnabled,
  setSlug,
}: OpenDeviceModalType) => {
  if (isShopModalEnabled) {
    setSlug(deviceSlug);
  } else {
    (window as any).location = `/shop/details/${deviceSlug}/`;
  }
};
