import React, { FC } from 'react';
import { PhoneInput as KitPhoneInput } from '@beef/ui-kit';

import { useFmcCheckPhoneInput } from './hooks/useFmcCheckPhoneInput';
import type { TPhoneInput } from './types';

/** common компонент для обработки ввода номера телефона и сеттинга в стор */
export const PhoneInput: FC<TPhoneInput> = ({ label }) => {
  const inputProps = useFmcCheckPhoneInput();
  return (
    <KitPhoneInput
      label={label || ''}
      maskSettings={{
        mask: '_',
        format: '+7 ### ###-##-##',
      }}
      placeholder="+7 ___ ___-__-__"
      {...inputProps}
    />
  );
};
