import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { pathOr } from 'ramda';

import store from 'store';

// Actions
import { fetchNumbers, setChosenNumber, setNewPhoneIndex } from 'widgets/actions/funcyNumberOrder';

// Utils
import { maskPhone } from 'utils/format-string';
import { isNumberBought } from 'utils/cartHelpers';
import { pushEcommerceNumberView } from 'utils/analytics/simOrderAnalytics';

/**
 * HOC-Компонент для получения и отображения номера функционала "Красивый номер";
 * Компонент получает данные в виде списка, достает необходимый объект одного из "номеров на выбор",
 * выбирает номер по индексу, парсит номер и отдает номер вида +7 ХХХ ХХХ ХХ ХХ;
 * Так же компонент прокидывает функцио, которая по клику меняет индекс номера,
 * этим самым выбирая новый номер из списка и прокидывая его дальше в компонент;
 */
class FancyNumberWidgetHOC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryType: 'spetsialnyie',
      category: null,
      chosen: props.rootChosenIndex || 0,
    };
  }

  componentDidMount() {
    const { categoryType } = this.state;
    this.props.fetchNumbers();
    this.props.setNewPhoneIndex(categoryType);
  }

  componentDidUpdate(prevProps, prevState) {
    const category = this.filterCategoryType(this.props.numbers);
    const isExistNumber = !!(category && category.numbers && category.numbers[this.state.chosen]);

    if (category?.numbers?.length) {
      pushEcommerceNumberView();
    }

    if (prevState.category !== category && prevProps.numbers !== this.props.numbers) {
      this.setState({ category }, () => {
        if (this.props.onNumberChange && isExistNumber) {
          this.props.onNumberChange(category.numbers[this.state.chosen].value);
        }
      });
    }

    if (prevProps.rootChosenIndex !== this.props.rootChosenIndex) {
      this.handleRootIndexUpdate();
    }

    if (
      prevState.category?.numbers !== this.state.category?.numbers ||
      prevState.chosen !== this.state.chosen
    ) {
      const number = this.state.category && this.state.category.numbers[this.state.chosen];
      this.props.setChosenNumber(number?.value);
    }
  }

  handleRootIndexUpdate = () => {
    const { rootChosenIndex } = this.props;
    this.setState({ chosen: rootChosenIndex });
  };

  filterCategoryType = (numbersList) =>
    numbersList.filter((item) => item.alias === this.state.categoryType)[0];

  handleRefreshClick = () => {
    const { disabled, rootChosenIndex } = this.props;
    const { chosen, category } = this.state;

    if (disabled) {
      return false;
    }

    let chosenIndex = chosen >= category.numbers.length - 1 ? 0 : chosen + 1;
    /* синхронизация с другим виджетом */
    if (rootChosenIndex || rootChosenIndex === 0) {
      chosenIndex = rootChosenIndex >= category.numbers.length - 1 ? 0 : rootChosenIndex + 1;
    }
    const chosenNumberValue = category.numbers[chosenIndex].value;

    this.setState({ chosen: chosenIndex });

    if (this.props.onRootChosenIndexChange) {
      this.props.onRootChosenIndexChange(chosenIndex);
    }

    if (this.props.onNumberChange) {
      this.props.onNumberChange(chosenNumberValue);
    }

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'GA_event',
        eventCategory: 'commonClick',
        eventAction: 'Refresh_number | Случайный номер',
        eventLabel: window.location.href,
      });
    }
  };

  render() {
    const { children, hidden, tariffData, numberClassName, disabled } = this.props;
    const { chosen, category } = this.state;
    const number = category && category.numbers[chosen];
    const { fancyNumberWidget, discountValue, mnpWidget, pageTitle } = tariffData;
    const isBought = isNumberBought(this.props.boughtNumbers, number?.value);

    return (
      (!hidden &&
        category &&
        number &&
        cloneElement(children, {
          handleRefreshClick: this.handleRefreshClick,
          number: maskPhone(number.value),
          currentNumber: number,
          mnpWidget: mnpWidget || {},
          numberClassName,
          tariffName: pageTitle,
          ...fancyNumberWidget,
          singleNumber: true,
          disabled,
          discountValue,
          isBought,
        })) ||
      null
    );
  }
}

FancyNumberWidgetHOC.propTypes = {
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  /* заказанные номера */
  boughtNumbers: PropTypes.arrayOf(PropTypes.string),
  numberClassName: PropTypes.string,
  setNewPhoneIndex: PropTypes.func,
  fetchNumbers: PropTypes.func,
  /* проброс выбранного номера наверх для доступа к нему в заказе */
  setChosenNumber: PropTypes.func,
  /* индекс выбранного номера для синхронизации нескольких виджетов с красивыми номерами */
  rootChosenIndex: PropTypes.number,

  /* прокидывание выбранного номера наружу */
  onNumberChange: PropTypes.func,
  /* для синхронизации нескольких виджетов с красивыми номерами */
  onRootChosenIndexChange: PropTypes.func,

  tariffData: PropTypes.shape({
    pageTitle: PropTypes.string,
    fancyNumberWidget: PropTypes.object,
    discountValue: PropTypes.number,
    mnpWidget: PropTypes.object,
  }),
  children: PropTypes.any,
  numbers: PropTypes.array,
};

const mapStateToProps = ({ external }) => ({
  numbers: pathOr([], ['funcyNumberOrder', 'numbers'], external),
  tariffData: pathOr({}, ['tariff', 'data'], external),
  boughtNumbers: external?.cartData?.currentCart?.numbers ?? [],
});

const mapDispatchToProps = {
  setNewPhoneIndex,
  fetchNumbers,
  setChosenNumber,
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(FancyNumberWidgetHOC);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
