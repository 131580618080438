import { getEnvironment } from './env';
import { getHasBrowserWindowAPI } from './window';

/**
 * Позволяет подменять имплементацию запроса на мок через sessionStorage
 *
 * Когда запущенно на разрешенной среде, если есть урл для mockName, то будет отраатывать mockHandler
 */
export function getMockRequestHandlerWhenAllowed<POutput extends Promise<unknown>>({
  originalHandler,
  mockHandler,
  mockName,
  allowedEnvironments = ['dev', 'UAT'],
}: {
  allowedEnvironments?: string[];
  mockHandler: (url: string) => POutput;
  mockName: string;
  originalHandler: () => POutput;
}) {
  try {
    if (!getHasBrowserWindowAPI()) throw new Error('No browser API');

    const environment = getEnvironment();

    if (!environment) throw new Error('No environment');

    const isAllowed = allowedEnvironments.some((env) =>
      environment.toLowerCase().includes(env.toLowerCase()),
    );

    if (!isAllowed) throw new Error('Not allowed to use mock');

    const mockUrl = sessionStorage.getItem(mockName);

    if (!mockUrl) throw new Error('No mock url found');

    return mockHandler(mockUrl);
  } catch (e) {
    console.warn(e);
    return originalHandler();
  }
}
