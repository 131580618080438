import PropTypes from 'prop-types';

const buttonLinkShape = PropTypes.shape({
  text: PropTypes.string,
  href: PropTypes.string,
  variant: PropTypes.string,
  buttonVariant: PropTypes.string,
  size: PropTypes.string,
});

const buttonShape = PropTypes.shape({
  text: PropTypes.string,
  link: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
});

export const unavailableServiceShape = PropTypes.shape({
  description: PropTypes.string,
  title: PropTypes.string,
  button: buttonLinkShape,
  buttonReject: buttonLinkShape,
  img: {
    src: PropTypes.string,
    lazy: PropTypes.bool,
    alt: PropTypes.string,
  },
});

export const successStepShape = PropTypes.shape({
  description: PropTypes.string,
  title: PropTypes.string,
  button: {
    text: PropTypes.string,
  },
  img: {
    src: PropTypes.string,
    lazy: PropTypes.bool,
    alt: PropTypes.string,
  },
});

export const connectShape = PropTypes.shape({
  description: PropTypes.string,
  title: PropTypes.string,
  button: buttonShape,
  img: {
    src: PropTypes.string,
    lazy: PropTypes.bool,
    alt: PropTypes.string,
  },
});

export const disconnectServiceSuccess = PropTypes.shape({
  description: PropTypes.string,
  title: PropTypes.string,
  button: buttonShape,
  img: {
    src: PropTypes.string,
    lazy: PropTypes.bool,
    alt: PropTypes.string,
  },
});

export const serviceDataShape = PropTypes.shape({
  isConnected: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isAvailableForNextConnection: PropTypes.bool,
});

export const contentShape = PropTypes.shape({
  polling: PropTypes.shape({
    timeout: PropTypes.number,
    interval: PropTypes.number,
  }),
  imageCard: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.string,
  }),
});

export { buttonLinkShape, buttonShape };
