import { MouseEvent, useState } from 'react';
import { SizeTypes } from '@beef/ui-kit/Modal/types';

type TUsePrepareEquipmentBlock = {
  buttonText?: string;
  link?: string;
};

/** Хук для подготовки данных блока оборудования */
export const usePrepareEquipmentBlock = ({ buttonText, link }: TUsePrepareEquipmentBlock) => {
  /* Стейт для модального окна */
  const [isOpen, setIsOpen] = useState(false);

  /* Клик по кнопке открытия модального окна */
  const onClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  /* Функция закрытия модального окна */
  const onClose = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsOpen(false);
  };

  /* Общий проп для компонента ModalContainer */
  const modalContainerProps = {
    isOpen,
    size: 'm' as SizeTypes,
    // Изменил тип тк ModalPropsType из @beef ждет ()=> void
    onClose: onClose as () => void,
    modalActionBarProps: {
      link: link ?? '',
      buttonText,
      onClick: onClose,
    },
  };

  return { onClick, modalContainerProps };
};
