import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { Header } from './components/Header';
import { Picture } from './components/Picture';
import { ButtonTile } from './components/ButtonTile';
import { TAdvantagesTile } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент отображения плитки тарифа */
export const TariffInfoTile: FC<TAdvantagesTile> = ({ buttonProps, headerProps, pictureProps }) => (
  <div className={cx('wrapper')}>
    <Header {...headerProps} />
    <Picture {...pictureProps} />
    <ButtonTile {...buttonProps} />
  </div>
);
