import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import type { TBenefitsCardText } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент CardText состоит из текстового содержимого карточки преимущества */
export const BenefitsCardText = ({ title, description }: TBenefitsCardText) => {
  return (
    <div className={cx('container')}>
      <Text variant="headline-small">{title}</Text>
      <Text color="secondary" variant="body-small">
        {description}
      </Text>
    </div>
  );
};
