import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map, YMaps } from 'react-yandex-maps';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { debounce } from 'utils/timed-functions';

import { choosePoint, getPoints, toggleMode } from '../../store/actions';
import { getUrlParam, updateUrlState, windowResizeHandler } from '../../utils';
import PlacemarkCircle from './PlacemarkCircle';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const midValue = (values) => (values[0] + values[1]) / 2;

class MapWrapper extends Component {
  state = {
    mapReady: false,
  };

  componentDidUpdate(prevProps) {
    const { appliedFilters } = prevProps;

    const pointsVisibilityUpdated = prevProps.hidePoints !== this.props.hidePoints;
    const disabledFilterToggled =
      appliedFilters?.appliedDisabledFilter !== this.props.appliedFilters?.appliedDisabledFilter;
    const alfaBankCardFilterToggled =
      appliedFilters?.appliedAlfaBankCardFilter !==
      this.props.appliedFilters?.appliedAlfaBankCardFilter;
    const alfaBankCard1525FilterToggled =
      appliedFilters?.appliedAlfaBankCard1525Filter !==
      this.props.appliedFilters?.appliedAlfaBankCard1525Filter;
    const officesFilterToggled =
      appliedFilters?.appliedOfficesFilter !== this.props.appliedFilters?.appliedOfficesFilter;
    const ownerOfficesFilterToggled =
      appliedFilters?.appliedOwnerOfficesFilter !==
      this.props.appliedFilters?.appliedOwnerOfficesFilter;
    const equipmentReturnOnlyFilterToggled =
      appliedFilters?.appliedEquipmentReturnOnlyFilter !==
      this.props.appliedFilters?.appliedEquipmentReturnOnlyFilter;
    const equipmentReturnReplacementFilterToggled =
      appliedFilters?.appliedEquipmentReturnReplacementFilter !==
      this.props.appliedFilters?.appliedEquipmentReturnReplacementFilter;

    if (
      pointsVisibilityUpdated ||
      disabledFilterToggled ||
      alfaBankCardFilterToggled ||
      officesFilterToggled ||
      ownerOfficesFilterToggled ||
      alfaBankCard1525FilterToggled ||
      equipmentReturnOnlyFilterToggled ||
      equipmentReturnReplacementFilterToggled
    ) {
      this.getPoints();
    }

    if (prevProps.mapMode !== this.props.mapMode) {
      this.fitZoom(this.props.zoomLimit[this.props.mapMode]);
    }
  }

  componentWillUnmount() {
    if (this.removeResizeHandler) {
      this.removeResizeHandler();
    }
  }

  onWindowResize = debounce(() => {
    const type = window.innerWidth < 800 ? 'mobile' : 'desktop';
    this.context.mapController.map.container.fitToViewport();

    if (this.deviceType !== type) {
      this.deviceType = type;
      this.context.mapController.setMargins(type);
    }
  }, 300);

  onMapLoad = () => {
    this.context.mapController.init(() => {
      this.context.mapController.addEventListener('boundschange', this.onBoundsChange);

      // --------- Helps to detect when requests to points are unnecessary ---------
      const mapContainer = document.querySelector(`.${cx('map')}`);
      if (mapContainer) {
        ['touchstart', 'mousedown', 'pointerdown'].forEach((type) => {
          mapContainer.addEventListener(type, this.setRequestBlocked);
        });
      }
      // ---------------------------------------------------------------------------
      const { controlHeight, isPopUp, onMapLoad, onToggleMode } = this.props;

      if (controlHeight && !isPopUp) {
        this.removeResizeHandler = windowResizeHandler(this.onWindowResize, `.${cx('map')}`);
      }

      this.setState({ mapReady: true });
      this.getPoints();

      onToggleMode(getUrlParam('mapMode'));
      onMapLoad?.();
    });
  };

  onItemClick = (type, id) => () => {
    let array = [];

    switch (type) {
      case 'cluster':
        array = this.props.clusters;
        break;
      case 'point':
        array = this.props.points;
    }

    const itemData = array.find((item) => item.id === id);

    if (type === 'cluster') {
      this.context.mapController.setBounds(itemData.lats, itemData.lons);
    }
    if (type === 'point' && itemData) {
      this.props.choosePoint(itemData);
    }
  };

  onBoundsChange = () => {
    this.blockRequests = false;
    this.getPoints();
  };

  setRequestBlocked = () => {
    this.blockRequests = true;
  };

  getPoints = debounce(() => {
    if (this.blockRequests) {
      return;
    }

    const { mapController } = this.context;
    const { ignoreShopPoints, pointsType, sng, sngPoints } = this.props;

    if (mapController) {
      if (mapController.map) {
        const center = mapController.map.getCenter();

        const isPopUp = !!this.props.isPopUp;

        if (!isPopUp) {
          updateUrlState({
            lat: center[0],
            lon: center[1],
            zoom: mapController.map.getZoom(),
          });
        }
      }

      const bounds = mapController?.getBounds({ useMapMargin: true });

      if (bounds) {
        this.props.getPoints({ bounds, ignoreShopPoints, sng, sngPoints, types: pointsType });
      }
    }
  }, 500);

  fitZoom = ([min, max]) => {
    const { map } = this.context.mapController;
    const zoom = map.getZoom();

    if (zoom < min) {
      map.setZoom(min);
    } else if (zoom > max) {
      map.setZoom(max);
    }
  };

  deviceType = null;

  blockRequests = false;

  render() {
    const {
      chosenPoint,
      clusters,
      currentLocation,
      hidePoints,
      mapMode,
      params,
      points,
      pointsData,
      zoomLimit,
    } = this.props;
    const showItems = this.state.mapReady && !hidePoints;
    const [minZoom, maxZoom] = zoomLimit[mapMode];
    const chosenPointIcon = pointsData[chosenPoint?.place?.name];

    return (
      <YMaps>
        <Map
          className={cx('map')}
          defaultState={{
            center: [params.lat, params.lon],
            zoom: params.zoom || 12,
          }}
          instanceRef={this.context.mapController.setMapInstance}
          onLoad={this.onMapLoad}
          options={{
            avoidFractionalZoom: true,
            maxZoom,
            minZoom,
            suppressMapOpenBlock: true,
            yandexMapDisablePoiInteractivity: true,
          }}
        >
          {showItems &&
            points.map(({ id, lat, lon, place }) =>
              id === chosenPoint.id ?
                null
              : <PlacemarkCircle
                  iconLayout={this.context.mapController.pointLayout}
                  key={id}
                  onClick={this.onItemClick('point', id)}
                  position={[lat, lon]}
                  properties={{
                    iconName: pointsData[place.lowerCaseName]?.icon || 'default.svg',
                  }}
                  radius={18}
                  zIndex={100}
                />,
            )}
          {showItems &&
            clusters.map((cluster) => (
              <PlacemarkCircle
                centered
                iconLayout={this.context.mapController.clusterLayout}
                key={cluster.id}
                onClick={this.onItemClick('cluster', cluster.id)}
                position={[midValue(cluster.lats), midValue(cluster.lons)]}
                properties={{ iconContent: cluster.count }}
                radius={24}
                zIndex={100}
              />
            ))}
          {chosenPoint.id && chosenPoint.displayPoint && (
            <PlacemarkCircle
              iconLayout={this.context.mapController.pointLayout}
              onClick={this.onItemClick('point', chosenPoint.id)}
              position={[chosenPoint.lat, chosenPoint.lon]}
              properties={{
                iconName: (chosenPointIcon && chosenPointIcon.icon) || 'default.svg',
                styles: 'animation: jumpingPointer 2s infinite;',
              }}
              radius={18}
              zIndex={998}
            />
          )}
          {currentLocation && (
            <PlacemarkCircle
              iconLayout={this.context.mapController.myPointLayout}
              position={[currentLocation.lat, currentLocation.lon]}
              radius={18}
              zIndex={999}
            />
          )}
        </Map>
      </YMaps>
    );
  }
}

MapWrapper.defaultProps = {
  controlHeight: true,
  zoomLimit: {
    coverZone: [3, 13],
    offices: [4, 16],
  },
};

MapWrapper.propTypes = {
  appliedAlfaBankCard1525Filter: PropTypes.bool,
  appliedAlfaBankCardFilter: PropTypes.bool,
  appliedDisabledFilter: PropTypes.bool,
  appliedEquipmentReturnOnlyFilter: PropTypes.bool,
  appliedEquipmentReturnReplacementFilter: PropTypes.bool,
  appliedFilters: PropTypes.object,
  appliedOfficesFilter: PropTypes.bool,
  choosePoint: PropTypes.func,
  chosenPoint: PropTypes.object,
  clusters: PropTypes.array,
  controlHeight: PropTypes.bool,
  currentLocation: PropTypes.object,
  getPoints: PropTypes.func,
  hidePoints: PropTypes.bool,
  ignoreShopPoints: PropTypes.bool,
  isPopUp: PropTypes.bool,
  mapMode: PropTypes.string,
  onMapLoad: PropTypes.func,
  onToggleMode: PropTypes.func,
  params: PropTypes.object,
  points: PropTypes.array,
  pointsData: PropTypes.object,
  pointsType: PropTypes.string,
  zoomLimit: PropTypes.object,
};

MapWrapper.defaultProps = {
  appliedFilters: {},
};

MapWrapper.contextTypes = {
  mapController: PropTypes.object,
};

const mapStateToProps = ({ external: { maps = {} } }) => ({
  appliedFilters: maps.appliedFilters,
  chosenPoint: maps.chosenPoint || {},
  clusters: maps.clusters || [],
  currentLocation: maps.currentLocation,
  points: maps.points || [],
});

const mapDispatchToProps = (dispatch) => ({
  choosePoint: (data) => dispatch(choosePoint(data)),
  getPoints: (data) => dispatch(getPoints(data)),
  onToggleMode: (data) => dispatch(toggleMode(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapWrapper);
