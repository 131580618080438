import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Emoji } from '@beef/legacy-ui-kit';

import styles from './OkStatus.pcss';

const cx = classNames.bind(styles);

const OkStatus = ({ children, emoji }) => (
  <div className={cx('layout')}>
    <span className={cx('text')}>{children}</span>
    <Emoji className={cx('hand')} name={emoji} />
  </div>
);

OkStatus.defaultProps = {
  children: 'Супер',
  emoji: 'statusOk',
};

OkStatus.propTypes = {
  emoji: PropTypes.string,
  children: PropTypes.any,
};

export default OkStatus;
