import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const FlexItem = ({ children, flex, align, className }) => (
  <div className={cn(align && `valign--${align}`, className)} style={{ flex }}>
    {children}
  </div>
);

FlexItem.propTypes = {
  children: PropTypes.node,
  flex: PropTypes.string,
  align: PropTypes.oneOf(['top', 'bottom']),
  className: PropTypes.string,
};
