import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

// Components
import { PhoneInput, Text } from '@beef/ui-kit';

// Actions
import { usePhoneInputWithMask } from 'pages/FTTB2021/components/OrderTariffFormPopup/hooks/usePhoneInputWithMask';

import { setPhoneNumber } from '../../../../store/actions/orderTariffUserData';

// Selectors
import { getNumberLabel, getPhoneErrorLabel } from '../../../../store/selectors/userData';
import { getUserSelectedPhoneNumber } from '../../../../store/selectors/orderTariffUserData';

// Hooks

// Constants
import { autoComplete, mask, name, placeholder, type } from './constants';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PhoneInputSectionLayout = ({ ctn, label, setCtn, phoneErrorText }) => {
  const { inputError, validateCtn, inputCntWithMask, handleChangePhone } = usePhoneInputWithMask(
    ctn,
    phoneErrorText,
    setCtn,
  );

  return (
    <div className={cx('container')}>
      {label && (
        <Text color="grey60" size="size5-r-s">
          {label}
        </Text>
      )}
      <div className={cx('container__phone')}>
        <PhoneInput
          autoComplete={autoComplete}
          errorMessage={inputError}
          maskSettings={mask}
          name={name}
          onBlur={validateCtn}
          onChange={handleChangePhone}
          placeholder={placeholder}
          type={type}
          value={inputCntWithMask}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  label: getNumberLabel(state),
  ctn: getUserSelectedPhoneNumber(state),
  phoneErrorText: getPhoneErrorLabel(state),
});

const mapDispatchToProps = {
  setCtn: setPhoneNumber,
};

PhoneInputSectionLayout.propTypes = {
  ctn: PropTypes.string,
  setCtn: PropTypes.func,
  label: PropTypes.string,
  phoneErrorText: PropTypes.string,
};

export const PhoneInputSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhoneInputSectionLayout);
