import { AppBannerBlock } from './components/AppBanner';
import { CategoriesBlock } from './components/Categories';
import { CommandsBlock } from './components/Commands';
import { FAQBlock } from './components/FAQ';
import { HeaderBlock } from './components/Header';
import { StoriesBlock } from './components/Stories';

export const BLOCK_TYPES = {
  appBanner: 'appBanner',
  categories: 'categories',
  faq: 'faq',
  header: 'header',
  quickcommands: 'quickcommands',
  stories: 'stories',
};

export const BLOCK_COMPONENTS = {
  [BLOCK_TYPES.appBanner]: { component: AppBannerBlock },
  [BLOCK_TYPES.categories]: { component: CategoriesBlock },
  [BLOCK_TYPES.faq]: { component: FAQBlock },
  [BLOCK_TYPES.header]: { component: HeaderBlock, withoutWrapper: true },
  [BLOCK_TYPES.quickcommands]: { component: CommandsBlock },
  [BLOCK_TYPES.stories]: { component: StoriesBlock },
};
