import { filter, lastValueFrom, map, take } from 'rxjs';

import { sendPostRequestWithId } from 'pages/PartnerServiceCard2022/api';
import { TYandexFinishActivationResponse } from 'pages/PartnerServiceCard2022/types';
import { getToken } from 'pages/PartnerServiceCard2022/utils';
import { templateParser } from 'utils/format-string';
import { REDIRECT_URL_TEMPLATE, getRedirectUrl } from 'utils/url';

import { TInput, selectLegacyModalState, selectYandexSubscription } from '../../selectors';
import { TariffUpMetaData } from '../../store/TariffUpMetaData';
import { TariffUpModals } from '../../store/TariffUpModals';
import { TariffUpPowers } from '../../store/TariffUpPowers';
import { EYandexPopupType, EYandexResponseType } from '../../types';
import { getYandexOAuthUrlRequest } from '../api';
import { updateYandexSubscrption } from '../handler';
import { selectEndpoints } from '../selectors';

export { onCancel } from '../handler';

export const onConfirm = async () => {
  try {
    TariffUpModals.IsLoading = true;

    const state = selectLegacyModalState();
    const endpoints = selectEndpoints(state);
    const endpoint = endpoints?.activate;

    if (!endpoint) {
      throw new Error('No activation endpoint was found in content');
    }

    if (!window) {
      throw new Error('No window object in global context');
    }

    const href: string = window.location?.href || '';
    const redirectUrl = getRedirectUrl(href);
    const oAuthEndpoint = templateParser(REDIRECT_URL_TEMPLATE, { endpoint, redirectUrl });

    const yandexOAuthUrl = await getYandexOAuthUrlRequest(oAuthEndpoint);

    if (!yandexOAuthUrl) {
      throw new Error('No yandex oauth url');
    }

    // NOTE: Should come back based on redirects
    if (window.location?.href) {
      window.location.href = yandexOAuthUrl;
      // NOTE: Не закрываем попап пока ждем перехода
      await new Promise(() => {});
    }

    TariffUpModals.IsOpen = false;
  } catch (e) {
    console.error(e);
    TariffUpModals.YandexPopupType = EYandexResponseType.Error;
  } finally {
    TariffUpModals.IsLoading = false;
  }
};

export const onMount = async () => {
  try {
    const token = getToken();

    if (!token) {
      return;
    }

    console.info(`Received Yandex token: ${token}`);

    TariffUpModals.IsLoading = true;
    TariffUpModals.IsOpen = true;

    const endpoints = await lastValueFrom(
      TariffUpModals.ObservableState.pipe(
        map(selectLegacyModalState),
        map(selectEndpoints),
        filter((v) => !!v),
        take(1),
      ),
    );

    if (!endpoints) {
      throw new Error('No endpoints');
    }

    const yandexSubscription = await lastValueFrom(
      TariffUpPowers.ObservableState.pipe(
        map(() => [TariffUpMetaData.Products] as unknown as TInput),
        map(selectYandexSubscription),
        filter((v) => !!v),
        take(1),
      ),
    );

    if (!yandexSubscription || !yandexSubscription.id) {
      throw new Error('No yandex subscription');
    }

    const finishActivationEndpoint = endpoints?.finishActivation as string | undefined;

    if (!finishActivationEndpoint) {
      throw new Error('No finish activation endpoint');
    }

    const endpoint = `${finishActivationEndpoint}?token=${token}`;

    // TODO: Move to common utils
    await sendPostRequestWithId(`${yandexSubscription.id}`, endpoint, {
      yandexToken: token,
    }).then((res: TYandexFinishActivationResponse) => {
      if (!res || !res.isSucceeded || !res.data?.success) {
        throw new Error("Couldn't finish activation");
      }

      updateYandexSubscrption({
        isConnected: true,
        isSubscriptionStatus: true,
        yandexAssigned: true,
      });

      TariffUpModals.YandexPopupType = EYandexPopupType.Activated;
    });
  } catch (e) {
    console.error(e);

    TariffUpModals.YandexPopupType = EYandexPopupType.Error;
  } finally {
    TariffUpModals.IsLoading = false;
  }
};
