import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
// Components
import { Input, StatefulSearchInput } from '@beef/ui-kit';

import { getActiveTabServiceType } from 'pages/ProductCard/Tariff/components/ConvergenceLayout/store/selectors/orderTariffDetailsData';

import { InputLayout } from './InputLayout';
// Selectors
import { getRegionLoading } from '../../../../../store/selectors/modal';
import { getSettingsTariff } from '../../../../../store/selectors/totalSettings';
import {
  getFlatLabel,
  getHouseLabel,
  getStreetLabel,
} from '../../../../../store/selectors/userData';
import {
  getStatusConnection,
  getUserSelectedFlat,
  getUserSelectedHouse,
  getUserSelectedPhoneNumber,
  getUserSelectedRegionID,
  getUserSelectedRegionLabel,
  getUserSelectedStreet,
  getUserSelectedStreetID,
} from '../../../../../store/selectors/orderTariffUserData';
// Actions
import { setActiveTab } from '../../../../../store/actions/orderTariffDetailsData';
import { resetCurrentUserTariff } from '../../../../../store/actions/totalSettings';
import { setCurrentStep, setLoadingStatus } from '../../../../../store/actions/modal';
import {
  setFlat,
  setHouse,
  setIsHighSpeed,
  setStatusConnection,
  setStreet,
} from '../../../../../store/actions/orderTariffUserData';
// Hooks
import { usePrepareHandlers } from './Hooks/usePrepareHandlers';
import { useFetchHouse } from '../../../../../Hooks/useFetchHouse';
import { useFetchStreet } from '../../../../../Hooks/useFetchStreet';
import { useAddressVerification } from '../../../../../Hooks/useAddressVerification';
// Constants
import { DELAY_TIME, highSpeedVerification } from '../../../../../constants';
// PropTypes
import { addressShape, iDShape } from '../../../../../PropTypes';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const InputsContainerLayout = ({
  flat,
  house,
  street,
  streetID,
  flatLabel,
  houseLabel,
  streetLabel,
  regionLabel,
  regionFetchLoading,
  ...props
}) => {
  const { fetchStreets, onSelectStreetFromList } = useFetchStreet({ ...props });
  const { fetchHouses, onSelectHouseFromList } = useFetchHouse({ streetID, ...props });

  const {
    houseValue,
    streetValue,
    onClearFlat,
    incorrectRegion,
    onChangeFlat,
    handleChangeHouseInput,
    handleChangeStreetInput,
  } = usePrepareHandlers({ house, street, regionLabel, ...props });

  useAddressVerification({
    flat,
    house,
    street,
    regionLabel,
    checkAddressType: highSpeedVerification.withVerification,
    ...props,
  });

  return (
    <div className={cx('wrapper')}>
      <InputLayout text={streetLabel} type="street">
        <StatefulSearchInput
          debounceInterval={DELAY_TIME}
          disabled={regionFetchLoading || !regionLabel || incorrectRegion}
          leftAside={null}
          onChange={handleChangeStreetInput}
          onSearch={fetchStreets}
          onSelect={onSelectStreetFromList}
          value={street.label || streetValue}
        />
      </InputLayout>
      <InputLayout text={houseLabel} type="house">
        <StatefulSearchInput
          debounceInterval={DELAY_TIME}
          disabled={!street.isConnected}
          leftAside={null}
          onChange={handleChangeHouseInput}
          onSearch={fetchHouses}
          onSelect={onSelectHouseFromList}
          value={house.label || houseValue}
        />
      </InputLayout>
      <InputLayout text={flatLabel} type="flat">
        <Input
          debounceInterval={DELAY_TIME}
          disabled={!house.isConnected}
          onChange={onChangeFlat}
          onClear={onClearFlat}
          value={flat}
        />
      </InputLayout>
    </div>
  );
};

const mapDispatchToProps = {
  setChosenFlat: setFlat,
  setStep: setCurrentStep,
  setChosenHouse: setHouse,
  setChosenStreet: setStreet,
  setDefaultTab: setActiveTab,
  setHighSpeed: setIsHighSpeed,
  setIsLoading: setLoadingStatus,
  setAddressChecked: setStatusConnection,
  resetSettingsTariff: resetCurrentUserTariff,
};

const mapStateToProps = (state) => ({
  flatLabel: getFlatLabel(state),
  houseLabel: getHouseLabel(state),
  flat: getUserSelectedFlat(state),
  streetLabel: getStreetLabel(state),
  house: getUserSelectedHouse(state),
  street: getUserSelectedStreet(state),
  regionID: getUserSelectedRegionID(state),
  streetID: getUserSelectedStreetID(state),
  regionFetchLoading: getRegionLoading(state),
  userSettingsTariff: getSettingsTariff(state),
  userNumber: getUserSelectedPhoneNumber(state),
  regionLabel: getUserSelectedRegionLabel(state),
  currentStatusConnection: getStatusConnection(state),
  tariffType: getActiveTabServiceType(state),
});

InputsContainerLayout.propTypes = {
  regionID: iDShape,
  streetID: iDShape,
  house: addressShape,
  street: addressShape,
  flat: PropTypes.string,
  setStep: PropTypes.func,
  flatLabel: PropTypes.string,
  userNumber: PropTypes.string,
  houseLabel: PropTypes.string,
  setIsLoading: PropTypes.func,
  streetLabel: PropTypes.string,
  regionLabel: PropTypes.string,
  setChosenFlat: PropTypes.func,
  setChosenHouse: PropTypes.func,
  setChosenStreet: PropTypes.func,
  setAddressChecked: PropTypes.func,
  regionFetchLoading: PropTypes.bool,
  tariffType: PropTypes.string,
};

export const InputsContainer = connect(mapStateToProps, mapDispatchToProps)(InputsContainerLayout);
