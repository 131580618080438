import React from 'react';
import classnames from 'classnames/bind';
import { AppBanner } from '@beef/smart-kit';

import styles from './styles.module.pcss';

const cx = classnames.bind(styles);

export const AppBannerBlock = ({ content: { title, text, appButtons, qrCode, img } }) => (
  <div className={cx('app-banner')}>
    <AppBanner appButtons={appButtons} img={img} qrCode={qrCode} text={text} title={title} />
  </div>
);
