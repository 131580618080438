import { useAppSelector } from '../../../../store';

/** Селектор выборки контентных данных для компонента редактирования адреса подключения ШПД */
export const useSelectAddressEditor = () => {
  /* Получаем контентный объект settingsFmcPage для страницы настройки ШПД */
  const settingsFmcPage = useAppSelector(
    (state) => state.common?.content?.fmcSettings?.settingsFmcPage,
  );

  /* Данные для блока редактирования адреса */
  const { editIcon, title, description, connectionAddressTitle } = settingsFmcPage || {};

  return { editIcon, title, description, connectionAddressTitle };
};
