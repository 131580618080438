import React, { FC } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames/bind';

import { useMnpProps } from './hooks/useMnpProps';
import { Header } from './components/Header';
import { Form } from './components/Form';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Варианты анимации MNP контейнера с формой в чеке ЕК */
const variantsMNPContainer = {
  hidden: {
    height: 'auto',
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.02 },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.02 },
  },
};

/** Общий контейнер для MNP с вводом номера пользователя */
export const MNPContainer: FC = () => {
  /* Подготовка данных для компонента MNPContainer */
  const { formProps, headerProps } = useMnpProps();

  return (
    <motion.section
      animate="visible"
      className={cx('wrapper')}
      exit="exit"
      initial="hidden"
      layout
      variants={variantsMNPContainer}
    >
      <Header {...headerProps} />
      <Form {...formProps} />
    </motion.section>
  );
};
