import { TCharactersTabsProps } from '../types';
import { createObservableState } from './utils';

export type TTariffUpPowers = {
  [key: string]: unknown;
  CharactersTabsData: Partial<TCharactersTabsProps>;
};

/**
 * Объект с состоянием, нужным для способоностей апперов
 */
export const TariffUpPowers = createObservableState<TTariffUpPowers>({
  CharactersTabsData: {},
});
