import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Heading, Tab, TabContent, Tabs, TabsList } from '@beef/legacy-ui-kit';

import { RenderRequestFrom } from '../RenderRequestFrom';
import { RenderTechSupport } from '../RenderTechSupport';
import styles from '../../styles.pcss';

const cx = classNames.bind(styles);

export const RenderPopup = ({ data }) => {
  const { texts } = data;

  return (
    <>
      <Heading className={cx('popupHeading')} level={1}>
        <span dangerouslySetInnerHTML={{ __html: texts.title }} />
      </Heading>
      <Tabs activeTab="backCallForm">
        <TabsList inline type="radio">
          <Tab id="backCallForm">
            <div
              className={cx('tabTitle')}
              dangerouslySetInnerHTML={{ __html: texts.backCallTabTitle }}
            />
            <div
              className={cx('tabSubTitle')}
              dangerouslySetInnerHTML={{ __html: texts.backCallTabSubTitle }}
            />
          </Tab>
          <Tab id="techSupport">
            <div
              className={cx('tabTitle')}
              dangerouslySetInnerHTML={{ __html: texts.techSupportTabTitle }}
            />
            <div
              className={cx('tabSubTitle')}
              dangerouslySetInnerHTML={{ __html: texts.techSupportTabSubTitle }}
            />
          </Tab>
        </TabsList>
        <TabContent id="backCallForm">
          <RenderRequestFrom data={data} />
        </TabContent>
        <TabContent id="techSupport">
          <RenderTechSupport texts={texts} />
        </TabContent>
      </Tabs>
    </>
  );
};

RenderPopup.propTypes = {
  data: PropTypes.shape({
    texts: PropTypes.shape({
      title: PropTypes.string,
      backCallTabTitle: PropTypes.string,
      backCallTabSubTitle: PropTypes.string,
      techSupportTabTitle: PropTypes.string,
      techSupportTabSubTitle: PropTypes.string,
    }),
  }),
};
