import React from 'react';
import PropTypes from 'prop-types';

import { ScrollContainerV3 } from '../ScrollContainerV3';
import { GridMediaQueryBuilder, GridPresets } from './components/GridMediaQueryBuilder';
import { gridOptionsShape } from './components/GridMediaQueryBuilder/shapes';
import { AlignedGrid } from '../AlignedGrid';
import { GridContext } from './context';
import { ScrollableGridItem } from './ScrollableGridItem';

/**
 * Scrollable GRID DOES control the amount of children
 */
export const ScrollableGrid = ({
  children,
  count,
  options,
  levels,
  className,
  gridClassName,
  isNoGrow,
}) => (
  <ScrollContainerV3 className={className} isNoGrow={isNoGrow}>
    <GridMediaQueryBuilder
      count={count ?? React.Children.count(children)}
      name="ovfCol"
      options={options}
    >
      {(queryClass) => (
        <GridContext.Provider value={queryClass}>
          <AlignedGrid
            className={gridClassName}
            isCarouselEnabled={options.hasCarousel}
            itemMaxSize={options.maxSize}
            levels={levels}
          >
            {children}
          </AlignedGrid>
        </GridContext.Provider>
      )}
    </GridMediaQueryBuilder>
  </ScrollContainerV3>
);

ScrollableGrid.Item = ScrollableGridItem;

ScrollableGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.shape(gridOptionsShape).isRequired,
  count: PropTypes.number,
  levels: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  gridClassName: PropTypes.string,
  isNoGrow: PropTypes.bool,
};

ScrollableGrid.presets = GridPresets;
