import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import generateGUID from 'utils/guid-generator';

import Option from './Option';
import { initChecked } from '../../../utils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Options = memo(
  ({
    options,
    unit,
    title,
    popupTetering,
    benefitLinkText,
    withoutDiscountUnit,
    activeOptionSoc,
  }) => (
    <div data-component="Options">
      <div className={cx('option-heading')}>
        <Text size="size3-m">{title}</Text>
      </div>
      {options.map((option, idx) => {
        const key = generateGUID();
        return (
          <Option
            benefitLinkText={benefitLinkText}
            id={idx}
            initialChecked={initChecked(option, activeOptionSoc)}
            key={key}
            option={option}
            popupTetering={popupTetering}
            unit={unit}
            withoutDiscountUnit={withoutDiscountUnit}
          />
        );
      })}
    </div>
  ),
);

Options.propTypes = {
  unit: PropTypes.string,
  title: PropTypes.string,
  benefitLinkText: PropTypes.string,
  popupTetering: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  withoutDiscountUnit: PropTypes.string,
};

export default Options;
