import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import { cnTheme } from '@carnica/themes';

import { useGlobalKeys } from 'pages/CarnicaDSLandings/hooks';

import { PortalProvider, usePortalContext } from '../PortalProvider';
import { SidebarConsumer } from '../SidebarConsumer';
import { ISidebarProps, sidebarPropPositionDefault } from '../types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const SidebarContent: React.FC<ISidebarProps> = (props) => {
  const {
    children,
    className,
    hasOverlay,
    isOpen,
    onClickOutside,
    onEsc,
    position = sidebarPropPositionDefault,
    rootClassName,
    style,
    ...otherProps
  } = props;

  const { zIndex } = usePortalContext();

  useGlobalKeys({
    Escape: (e) => isOpen && onEsc && onEsc(e),
  });

  const wrapperRef = useRef<HTMLElement>(null);

  return (
    <PortalProvider zIndex={zIndex}>
      <section
        className={cx(
          cnTheme('light'),
          'sidebar',
          rootClassName,
          { hasOverlay },
          `position_${position}`,
        )}
        style={{
          zIndex: style?.zIndex ?? zIndex,
        }}
      >
        <div aria-label="overlay" className={cx('overlay')} />
        <aside
          className={cx('wrapper', `position_${position}`, className)}
          ref={wrapperRef}
          style={{
            ...style,
            zIndex: undefined,
          }}
          {...otherProps}
        >
          <SidebarConsumer ignoreClickInsideRefs={[wrapperRef]} onClickOutside={onClickOutside}>
            {children}
          </SidebarConsumer>
        </aside>
      </section>
    </PortalProvider>
  );
};
