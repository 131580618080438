import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const withCn = (WrappedComponent) => {
  const NormalizedComponent = ({ className, isInline, ...props }) => (
    <div className={cn({ inline: isInline }, className)}>
      <WrappedComponent {...props} />
    </div>
  );

  NormalizedComponent.propTypes = {
    className: PropTypes.string,
  };

  return NormalizedComponent;
};
