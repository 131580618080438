import React from 'react';
import { StatefulSearchInput } from '@beef/ui-kit';

import { useCheckAddressInputsTexts } from '../../selectors/useCheckAddressInputsTexts';
import { useRegionInput } from './hooks/useRegionInput';

export const FmcRegionInput = () => {
  const { regionTitle, regionPlaceholder } = useCheckAddressInputsTexts();
  const inputProps = useRegionInput();
  return (
    <StatefulSearchInput
      debounceInterval={500}
      label={regionTitle}
      leftAside={undefined}
      placeholder={regionPlaceholder}
      {...inputProps}
    />
  );
};
