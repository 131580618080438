import { ymMNP } from 'pages/MnpOrderNew/ym';

export const useYMForm = (title: string) => {
  const handleClickInput = () => {
    ymMNP({
      action: 'click',
      item_type: 'field',
      field_name: 'Номер для переноса',
      object_type: 'block',
      block_title: title,
    });
  };

  const handleSendYMSubmit = () => {
    ymMNP({
      action: 'click',
      item_type: 'button',
      button_text: 'Начать переход',
      object_type: 'block',
      block_title: title,
    });
  };

  return {
    handleClickInput,
    handleSendYMSubmit,
  };
};
