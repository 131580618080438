import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { omit, pathOr } from 'ramda';
import { Description, Descriptions } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';
import { parseUrlForNeedOpenOrderPaymentResultPopup } from 'widgets/actions/orderPaymentForm';
import { maskPhone } from 'utils/format-string';
import FancyNumberOrderPopup from 'widgets/FancyNumberOrderPopup/FancyNumberOrderPopup';
import OrderPaymentResultPopup from 'widgets/OrderPaymentForm/OrderPaymentResultPopup';

import Banner from './components/Banner';
import MainLayout from './components/MainLayout';
import {
  fetchNumbers,
  fetchTariffs,
  removeChosenNumber,
  setCategory,
  togglePopupStatus,
} from './actions';
import styles from './index.pcss';

const cx = classNames.bind(styles);

class FancyLanding extends Component {
  componentDidMount() {
    const {
      data: { category, widget, popupsContent },
    } = this.props;

    this.props.setCategory(category || 'B');
    this.props.fetchNumbers('all', '');
    this.props.fetchTariffs(widget.fetchTariffsUrl, widget.staticNumberParams.category);
    this.props.openPaymentResultPopup(popupsContent);
  }

  handleOrderFinish = (result) => {
    if (result === 'success') {
      this.props.removeNumber();
    }

    this.props.closePopup();
  };

  render() {
    const { data, isMobile, tariffsInfo, chosenNumber, popupIsActive, currentRegion } = this.props;
    const { banner, descriptions, popupsContent, widget } = data;

    return (
      <div className={cx('wrapper')}>
        <div className={cx('banner')}>
          <Banner isMobile={isMobile} {...banner} />
        </div>

        <div className={cx('content')}>
          <MainLayout texts={data} />
        </div>

        <div className={cx('descriptionWrapper')}>
          <div className={cx('descriptions')}>
            <Descriptions titleTag="h2">
              {descriptions.map(({ title, content }, key) => (
                <Description key={key} title={title}>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </Description>
              ))}
            </Descriptions>
          </div>
        </div>

        {popupIsActive && (
          <FancyNumberOrderPopup
            {...popupsContent}
            checkRegionUrl={widget.checkRegionUrl}
            currentRegion={currentRegion}
            number={{
              ...omit(['value'], chosenNumber),
              ...widget.staticNumberParams,
              number: maskPhone(chosenNumber.value),
            }}
            onOrderFinish={this.handleOrderFinish}
            place="Bronze"
            tariffsInfo={tariffsInfo}
          />
        )}
        {!popupIsActive && <OrderPaymentResultPopup />}
      </div>
    );
  }
}

// FancyLanding.defaultProps = {
//   data: stubData,
// };

FancyLanding.propTypes = {
  data: PropTypes.shape({}),
  isMobile: PropTypes.bool,
  fetchNumbers: PropTypes.func,
  fetchTariffs: PropTypes.func,
  closePopup: PropTypes.func,
  setCategory: PropTypes.func,
  tariffsInfo: PropTypes.shape({}),
  chosenNumber: PropTypes.shape({}),
  popupIsActive: PropTypes.bool,
  removeNumber: PropTypes.func,
  currentRegion: PropTypes.shape({}),
  openPaymentResultPopup: PropTypes.func,
};

const mapStateToProps = (state) => ({
  tariffsInfo: pathOr(null, ['external', 'fancyLanding', 'tariffsInfo'], state),
  chosenNumber: pathOr(null, ['external', 'fancyLanding', 'chosenNumber'], state),
  popupIsActive: pathOr(false, ['external', 'fancyLanding', 'popupIsActive'], state),
  currentRegion: pathOr(null, ['external', 'regions', 'currentRegion'], state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchNumbers: (path, query) => dispatch(fetchNumbers(path, query, true)),
  fetchTariffs: (url, alias) => dispatch(fetchTariffs(url, alias)),
  setCategory: (cat) => dispatch(setCategory(cat)),
  closePopup: () => dispatch(togglePopupStatus(false)),
  removeNumber: () => dispatch(removeChosenNumber()),
  openPaymentResultPopup: (data) => dispatch(parseUrlForNeedOpenOrderPaymentResultPopup(data)),
});

const ConnectedLanding = connect(mapStateToProps, mapDispatchToProps)(FancyLanding);

export default (props) => (
  <Provider store={store}>
    <ConnectedLanding {...props} />
  </Provider>
);
