import { ymPushEvent, ymPushParams } from 'utils/ym';
import { tariffClickYM } from 'utils/analytics/tariffsYandexMetrica';
import { TARIFF_TYPES } from 'pages/FTTB2022/CatalogPage/deprecated/store/blocks/constants';
import {
  REQUEST_STATUS,
  btnTexts,
  popupSteps,
} from 'pages/ProductCard/Tariff/components/ConvergenceLayout/constants';
import { getStatusConnection } from 'pages/ProductCard/Tariff/components/ConvergenceLayout/store/selectors/orderTariffUserData';
import { getConnectionStatusTexts } from 'pages/ProductCard/Tariff/components/ConvergenceLayout/store/selectors/userData';
import { getTargetTariffType } from 'pages/FTTB2021/components/OrderTariffFormPopup/store/selectors/common';

import {
  CONVERGENT_CONNECT_UP_FORM,
  CONVERGENT_TARIFF_CALLBACK_LONG_FORM,
  HOME_TARIFF_CALLBACK_LONG_FORM,
  HOME_TARIFF_CONNECT_LONG_FORM,
} from './constants';

export const ymOrderTariffIsOpen = (tariffData, showFullForm) => {
  const formType = tariffData.isPopular ? 'top_tariff' : 'tariff_cart';

  if (
    (tariffData.tariffType === TARIFF_TYPES.internet ||
      tariffData.tariffType === TARIFF_TYPES.kit) &&
    showFullForm
  ) {
    ymPushParams({ fttb_ev_: { full_form_request_sent: tariffData.tariffAlias } });
    return ymPushEvent(HOME_TARIFF_CONNECT_LONG_FORM, {
      URL: window.location.href,
      form_type: formType,
      tariff: tariffData.tariffAlias,
    });
  }

  if (tariffData.tariffType === TARIFF_TYPES.presetUp && showFullForm) {
    ymPushParams({ fmc_ev_: { fmc_request_start: tariffData.tariffAlias } });

    return ymPushEvent(CONVERGENT_CONNECT_UP_FORM, {
      URL: window.location.href,
      form_type: formType,
      tariff: tariffData.tariffAlias,
    });
  }

  return tariffClickYM();
};

export const ymOrderTariffAddressView = (payload, tariffType) => {
  if (tariffType === TARIFF_TYPES.presetUp) {
    ymPushParams({ fmc_ev_: { fmc_address_check: payload } });
  } else {
    ymPushParams({ fttb_ev_: { full_form_address: payload } });
  }
};
export const ymOrderTariffUpFormBidView = (payload) => {
  ymPushParams({ fmc_ev_: { fmc_form_bid: payload } });
};

export const ymOrderAlternativeTariffView = (payload, isConvergent) => {
  ymPushParams({ fttb_ev_: { full_form_tariff_details: payload } });
  const keyName =
    isConvergent ? CONVERGENT_TARIFF_CALLBACK_LONG_FORM : HOME_TARIFF_CALLBACK_LONG_FORM;
  ymPushEvent([keyName], { form_type: 'operator', URL: window.location.href });
};

export const ymOrderTariffDetailsView = (payload) => {
  ymPushParams({ fttb_ev_: { full_form_bid: payload } });
};

export const ymOrderTariffCalendarView = (payload) => {
  ymPushParams({ fttb_ev_: { full_form_visit: payload } });
};

export const ymOrderTariffSummaryPageView = (payload, tariffName) => {
  ymPushParams({ fttb_ev_: { full_form_request_success: payload } });
  ymPushEvent(HOME_TARIFF_CALLBACK_LONG_FORM, { URL: window.location.href, tariff: tariffName });
};

export const ymCheckConnectionTariffUp = (payload) => {
  ymPushParams({ fmc_ev_: { fmc_tariff_check: payload } });
};

const getIsUnavailableStatus = (status) =>
  [
    REQUEST_STATUS.isNotAvailable,
    REQUEST_STATUS.houseIsNotFound,
    REQUEST_STATUS.regionIsNotFound,
    REQUEST_STATUS.streetIsNotFound,
    REQUEST_STATUS.regionIsNotConnect,
  ].some((i) => i === status);

export const onClickConvergenceByStepAnalytics = (viewStep, { priceWithUnit }) =>
  ({
    [popupSteps.addressRequestForm]: () =>
      ymOrderTariffAddressView('fmc_address_continue', TARIFF_TYPES.presetUp),
    [popupSteps.requestExists]: () =>
      ymOrderTariffAddressView(
        { fmc_address_button: btnTexts.changeAddress },
        TARIFF_TYPES.presetUp,
      ),
    [popupSteps.callBackRequest]: () =>
      ymOrderTariffAddressView({ fmc_address_button: btnTexts.callBack }, TARIFF_TYPES.presetUp),
    [popupSteps.connectCompleted]: () =>
      ymOrderTariffAddressView(
        { fmc_address_button: btnTexts.checkDiffAddress },
        TARIFF_TYPES.presetUp,
      ),
    [popupSteps.confirmCancelConnect]: () => ymOrderTariffUpFormBidView('fmc_bid_refuse'),
    [popupSteps.tariffDetailsForm]: () =>
      ymOrderTariffUpFormBidView({ fmc_bid_continue: priceWithUnit }),
  })[viewStep];

const getIsAnalyticCoveredStatus = (status) =>
  [
    REQUEST_STATUS.isNotAvailable,
    REQUEST_STATUS.houseIsNotFound,
    REQUEST_STATUS.regionIsNotFound,
    REQUEST_STATUS.streetIsNotFound,
    REQUEST_STATUS.regionIsNotConnect,
    REQUEST_STATUS.alreadyInUse,
    REQUEST_STATUS.isConnected,
    REQUEST_STATUS.isAvailable,
  ].some((i) => i === status);

// eslint-disable-next-line consistent-return
export const onInitConnectionNotificationYmAnalytics =
  ({ house, street, currentCity }) =>
  (dispatch, getState) => {
    const state = getState();
    const status = getStatusConnection(state);
    const statusTexts = getConnectionStatusTexts(state);
    const fullAddress = `${currentCity}, ${street}, ${house}`;

    const { tariffType } = getTargetTariffType(state);

    if (getIsAnalyticCoveredStatus(status)) {
      if (status === REQUEST_STATUS.isAvailable) {
        return ymOrderTariffAddressView(
          { fmc_address_available: statusTexts?.[status] },
          tariffType,
        );
      }
      const fmcAddressError =
        getIsUnavailableStatus(status) ? 'Подключение недоступно' : statusTexts?.[status];
      return ymOrderTariffAddressView({ [fmcAddressError]: fullAddress }, tariffType);
    }
  };
