import { TPreviewTariff } from '../components/constructorPage/FmcTabsContainer/types';
import { getCurrentConvergenceInfo } from '../components/constructorPage/FmcTabsContainer/utils';

/** Функция получения текущей и старой цены для ШПД */
export const getFmcSum = (
  previewTariffs: TPreviewTariff[],
  tariffInacId: string,
  priceCategory: string,
) => {
  /* Получаем общий объект ШПД по текущему tariffInacId (содерижт inacId, priceList, convergenceBlock) */
  const currentConvergenceInfo = getCurrentConvergenceInfo(previewTariffs, tariffInacId);

  /* Достаем priceList с ценами по табу ШПД */
  const currentTabInfo = currentConvergenceInfo?.priceList?.find(
    (item) => item.priceCategory === priceCategory,
  );

  return {
    fmcPrice: currentTabInfo?.price as number,
    fmcOldPrice: currentTabInfo?.oldPrice as number,
  };
};
