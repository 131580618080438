import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MainParam = ({ mainParam, isDark }) => {
  if (!mainParam) {
    return null;
  }
  return (
    <div className={cx('mainParam')}>
      <div className={cx('mainParamIconWrapper')}>
        <Icon className={cx('mainParamIcon')} name={mainParam.iconName} />
      </div>

      <div className={cx('mainParamValue')} dangerouslySetInnerHTML={{ __html: mainParam.value }} />
      <br />
      {mainParam.onClick ?
        <Link className={['hideVisitedColor', isDark && 'light']} onClick={mainParam.onClick}>
          <div
            className={cx('mainParamUnit')}
            dangerouslySetInnerHTML={{ __html: mainParam.unit }}
          />
        </Link>
      : <div className={cx('mainParamUnit')} dangerouslySetInnerHTML={{ __html: mainParam.unit }} />
      }

      {typeof mainParam.description === 'string' && !!mainParam.description && (
        <div
          className={cx('mainParamDescription')}
          dangerouslySetInnerHTML={{ __html: mainParam.description }}
        />
      )}
    </div>
  );
};

MainParam.propTypes = {
  isDark: PropTypes.bool,
  mainParam: PropTypes.shape({
    alias: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.string,
    iconName: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

export default MainParam;
