import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import InfoPopup from 'pages-components/InfoPopup';

// Inner Components
import SingleRowButton from '../SingleRowButton';
import RowButton from '../RowButton';

/**
 * Wrapper для вызова модального окна;
 * -----------------------------------
 * @param type - Тип вызываемой кнопки;
 * @param fetchUrl - Url по которому вызывается модально окно;
 * @param children - Дочерний элемент;
 * @params ...props - Принимает все props, что RowButton и SingleRowButton;
 */

const RowButtonWithPopup = ({ type, fetchUrl, children, ...props }) => {
  const [showPopup, toggleShowPopup] = useState(false);
  const rowButtonType = {
    rowButton: RowButton,
    singleRowButton: SingleRowButton,
  };

  const ButtonComponent = rowButtonType[type];

  return (
    <>
      <ButtonComponent
        {...props}
        onClick={() => {
          toggleShowPopup(true);
        }}
      >
        {children}
      </ButtonComponent>
      {showPopup && (
        <InfoPopup
          fetchUrl={fetchUrl}
          onClose={() => {
            toggleShowPopup(false);
          }}
        />
      )}
    </>
  );
};

RowButtonWithPopup.defaultProps = { type: 'rowButton' };

RowButtonWithPopup.propTypes = {
  children: PropTypes.object,
  fetchUrl: PropTypes.string,
  type: PropTypes.string,
};

export default RowButtonWithPopup;
