import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Libs
import { pathOr } from 'ramda';

// Utils
import classNames from 'classnames/bind';

import { getKbyteString, getSecondsString } from '../../utils';

// Styles
import styles from './styles.pcss';

// Components
import Accumulator from '../Accumulator';
import Scale from '../Scale';

const cx = classNames.bind(styles);

export class Accumulators extends Component {
  state = {
    scaleSize: 100,
    packagesName:
      this.props.isLifeFamily ? ['onNetSeconds', 'offNetSeconds'] : ['kbyte', 'seconds', 'sms'],
  };

  componentDidMount() {
    this.setScaleSize();
  }

  componentDidUpdate() {
    this.setScaleSize();
  }

  setScaleSize = () => {
    const { packagesName } = this.state;
    const scalesSizes = packagesName.map((key) => this[key].values.size);
    const scaleSize = Math.max(...scalesSizes);

    // 120% безопасно
    if (this.state.scaleSize !== scaleSize) {
      this.setState({ scaleSize }); // eslint-disable-line
    }
  };

  getBasicRests = () => {
    const { basicRests } = this.props;
    const { packagesName } = this.state;
    const newRests = { ...basicRests };

    packagesName.forEach((key) => {
      if (basicRests[key].available > basicRests[key].size) {
        newRests[key] = {
          ...newRests[key],
          size: newRests[key].available,
        };
      }
    });

    return newRests;
  };

  /**
   * Возвращает объект вида:
   * {
   *  kbyte: Number,
   *  seconds: Number,
   *  sms: Number
   * }
   * или
   * {
   *   onNetSeconds: Number,
   *   offNetSeconds: Number
   * }
   * в зависимости от значения this.state.packagesName
   */
  getRests = (basicRests) => {
    const { values } = this.props;

    const { packagesName } = this.state;

    return packagesName.reduce(
      (restObj, packageKey) => ({
        ...restObj,
        [packageKey]: basicRests[packageKey].available + values[packageKey],
      }),
      {},
    );
  };

  getSize = (basicRests) => {
    const { values } = this.props;

    const { packagesName } = this.state;

    return packagesName.reduce(
      (restObj, packageKey) => ({
        ...restObj,
        [packageKey]: basicRests[packageKey].size + values[packageKey],
      }),
      {},
    );
  };

  renderScale = (name, defaultMaxSize, text) => {
    const { basicRests, values, fixZindex, flipped } = this.props;
    const { scaleSize } = this.state;
    const { available, size } = basicRests[name];

    return [
      <Scale
        available={available > size ? size : available}
        className={cx('scale')}
        decreased={values[name] < 0 ? -values[name] : 0}
        defaultMaxSize={defaultMaxSize}
        empty={values.dataTariff && (name === 'seconds' || name === 'sms')}
        fixZindex={fixZindex}
        increased={values[name] > 0 ? values[name] : 0}
        key="scale"
        ref={(e) => {
          this[name] = e;
        }}
        scaleSize={scaleSize}
        size={basicRests[name].size}
      />,
      !flipped && text && (
        <div
          className={cx('notEnoughText')}
          dangerouslySetInnerHTML={{ __html: text }}
          key="text"
        />
      ),
    ];
  };

  renderOnNetOffNetAccumulators() {
    const { texts, values, flipped, inline } = this.props;
    const basicRests = this.getBasicRests();
    const maxSeconds = Math.max(
      basicRests.onNetSeconds.size || 0,
      basicRests.offNetSeconds.size || 0,
    );
    const rest = this.getRests(basicRests);
    const size = this.getSize(basicRests);

    return (
      <>
        <Accumulator
          disabled={!flipped && basicRests.onNetSeconds.notEnough}
          emptyText={values.dataTariff ? '0 мин.' : ''}
          icon="phone"
          inline={inline}
          label="Минуты на билайн"
          value={`${getSecondsString(rest.onNetSeconds)} ${
            rest.onNetSeconds === size.onNetSeconds ?
              ''
            : ` из ${getSecondsString(size.onNetSeconds)}`
          }`}
        >
          {this.renderScale(
            'onNetSeconds',
            maxSeconds,
            basicRests.onNetSeconds.notEnough && texts.restEnoughSeconds,
          )}
        </Accumulator>
        <Accumulator
          disabled={!flipped && basicRests.offNetSeconds.notEnough}
          emptyText={values.dataTariff ? '0 мин.' : ''}
          icon="phone"
          inline={inline}
          label="Минуты на другие сети"
          value={`${getSecondsString(rest.offNetSeconds)} ${
            rest.offNetSeconds === size.offNetSeconds ?
              ''
            : ` из ${getSecondsString(size.offNetSeconds)}`
          }`}
        >
          {this.renderScale(
            'offNetSeconds',
            maxSeconds,
            basicRests.offNetSeconds.notEnough && texts.restEnoughSeconds,
          )}
        </Accumulator>
      </>
    );
  }

  renderMinsKbyteAccumulators() {
    const { texts, values, flipped, inline } = this.props;
    const basicRests = this.getBasicRests();
    const rest = this.getRests(basicRests);
    const size = this.getSize(basicRests);

    return (
      <>
        <Accumulator
          icon="internet"
          inline={inline}
          label="Интернет"
          value={`${getKbyteString(rest.kbyte)} ${
            rest.kbyte === size.kbyte ? '' : ` из ${getKbyteString(size.kbyte)}`
          }`}
        >
          {this.renderScale('kbyte', 500)}
        </Accumulator>

        <Accumulator
          disabled={!flipped && basicRests.seconds.notEnough}
          emptyText={values.dataTariff ? '0 мин.' : ''}
          icon="phone"
          inline={inline}
          label="Минуты"
          value={`${getSecondsString(rest.seconds)} ${
            rest.seconds === size.seconds ? '' : ` из ${getSecondsString(size.seconds)}`
          }`}
        >
          {this.renderScale(
            'seconds',
            30000,
            basicRests.seconds.notEnough && texts.restEnoughSeconds,
          )}
        </Accumulator>

        <Accumulator
          disabled={!flipped && basicRests.sms.notEnough}
          emptyText={values.dataTariff ? '0 SMS' : ''}
          icon="chat"
          inline={inline}
          label="SMS"
          value={`${rest.sms} SMS ${rest.sms === size.sms ? '' : ` из ${size.sms} SMS`}`}
        >
          {this.renderScale('sms', 500, basicRests.sms.notEnough && texts.restEnoughSms)}
        </Accumulator>
      </>
    );
  }

  render() {
    const { className, isLifeFamily } = this.props;

    return (
      <div className={cx('component', className)}>
        {isLifeFamily ? this.renderOnNetOffNetAccumulators() : this.renderMinsKbyteAccumulators()}
      </div>
    );
  }
}

Accumulators.defaultProps = {
  texts: {},
  fixZindex: false,
  flipped: false,
  inline: false,
  values: { seconds: 0, sms: 0, kbyte: 0 },
};

Accumulators.propTypes = {
  basicRests: PropTypes.object.isRequired,
  values: PropTypes.object,
  texts: PropTypes.object,
  fixZindex: PropTypes.bool,
  flipped: PropTypes.bool,
  inline: PropTypes.bool,
  isLifeFamily: PropTypes.bool,
};

export default connect((state) => {
  const tariffConstructor = pathOr({}, ['external', 'tariffConstructor'], state);
  const { isLifeFamily } = tariffConstructor;

  return {
    basicRests: tariffConstructor.data.accumulators,
    values: tariffConstructor.values,
    flipped: tariffConstructor.flipped,
    dataTariff: tariffConstructor.values.kbyte !== 0,
    isLifeFamily,
  };
})(Accumulators);
