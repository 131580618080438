import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import Picture from 'pages-components/Picture';
import SocialButtons from 'pages-components/SocialButtons/SocialButtons';
import useCreateQrCodeImg from 'utils/hooks/useCreateQrCodeImg';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MobileApps = ({ content }) => {
  const qrCode = useCreateQrCodeImg(content?.qrCodeUrl.url);

  const pictureContent = [
    {
      srcSet: content.imageMobile,
      media: 'max-width: 420px',
    },
    {
      srcSet: content.imageTablet,
      media: 'min-width: 421px',
    },
  ];

  return (
    <div className={cx('wrapper')}>
      <div className={cx('wrapper__left')}>
        <div className={cx('wrapper__title')}>
          <Text size="size1-b" tagType="h3">
            {content.title}
          </Text>
        </div>
        <div className={cx('wrapper__description')}>
          <Text size="size5-m" tagType="span">
            {content.description}
          </Text>
        </div>
        <div className={cx('wrapper__downloads')}>
          {content.appButtons && (
            <div className={cx('wrapper__buttons')}>
              <SocialButtons className={cx('mobile-buttons')} data={content.appButtons} />
            </div>
          )}
          {qrCode && (
            <figure className={cx('wrapper__qr-code')}>
              <div>
                <img alt="" className={cx('qr-code')} src={qrCode} />
              </div>
              {content?.qrCodeUrl.description && (
                <figcaption className={cx('caption')}>
                  <Text color="grey50" size="size7-r" tagType="span">
                    {content?.qrCodeUrl.description}
                  </Text>
                </figcaption>
              )}
            </figure>
          )}
        </div>
      </div>
      <div className={cx('wrapper__right')}>
        <Picture className={cx('image')} lazy source={pictureContent} />
      </div>
    </div>
  );
};

MobileApps.propTypes = {
  content: PropTypes.shape({
    qrCodeUrl: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    appButtons: PropTypes.object,
    downloadBtnText: PropTypes.string,
    downloadBtnUrl: PropTypes.string,
    imageMobile: PropTypes.string,
    imageTablet: PropTypes.string,
    showQrCodeWidth: PropTypes.number,
  }),
};

export default MobileApps;
