import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { getPromotionContentByPlace } from 'pages/FancyNumber2022/store/selectors/content';
import { PromotionTagWrapper } from 'pages/FancyNumber2022/components/PromotionTagWrapper';
import { PromotionShape } from 'pages/FancyNumber2022/shapes';
import { PROMOTION_PLACES } from 'pages/FancyNumber2022/constants';

import { getActiveCategories } from '../../../store/selectors/category';
import { getIsChangeNumber } from '../../../store/selectors/change';
import { toggleActiveCategory } from '../../../store/actions/category';
import { Select } from './Select/index';
import { Price } from '../../Price/index';
import { FilterButton } from '../FilterButton/index';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CategoriesSelectLayout = ({ activeCategories, promotion, onClick }) => (
  <Select>
    {({ alias, name, price, discount, isShowPrice }) => (
      <PromotionTagWrapper {...(promotion.category === alias && { promotionTag: promotion?.tag })}>
        <FilterButton
          active={activeCategories[alias]}
          className={cx('categoryButton')}
          key={alias}
          onClick={() => onClick(alias)}
        >
          {name} {isShowPrice && <Price discount={discount} price={price} />}
        </FilterButton>
      </PromotionTagWrapper>
    )}
  </Select>
);

CategoriesSelectLayout.propTypes = {
  activeCategories: PropTypes.objectOf(PropTypes.bool),
  promotion: PropTypes.shape(PromotionShape),
  onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  activeCategories: getActiveCategories(state),
  promotion:
    !getIsChangeNumber(state) && getPromotionContentByPlace(PROMOTION_PLACES.category, state),
});

const mapDispatchToProps = {
  onClick: toggleActiveCategory,
};

export const CategoriesSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoriesSelectLayout);
