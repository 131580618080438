import React, { memo } from 'react';
import classnames from 'classnames/bind';
import { Link } from '@beef/ui-kit';
import { Card } from '@beef/smart-kit';

import styles from './styles.module.pcss';

const cx = classnames.bind(styles);

export const GridCell = memo(({ title, href, image, color, links, className }) => (
  <Card className={cx('card', color && `card--${color}`, className)} withHover>
    <div className={cx('card-content')} style={{ backgroundImage: `url(${image})` }}>
      <Link href={href} isNoUnderline size="size3-m">
        {title}
      </Link>
      <div className={cx('card-links')}>
        {links.map((link, index) => (
          <div className={cx('link')} key={index}>
            <Link color="grey60" href={link.href} isNoUnderline size="size5-r-l">
              <span className={cx('link_text')}>{link.text}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  </Card>
));
