import { createSelector } from '@reduxjs/toolkit';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { RootState } from 'constructorV1/store';

/** Селектор выборки объекта user ЕК */
export const selectUser = (state: RootState) => state.user;

/** Селектор выборки состояния подключения тарифа ЕК */
export const selectIsConnectTariff = createSelector(selectUser, (user) => user.isConnected);

/** Селектор выборки состояния авторизации пользователя */
export const selectIsAuthenticated = createSelector(selectUser, (user) => user.isAuthenticated);

/** Селектор выборки объекта user ЕК */
export const selectConnectionType = createSelector(selectUser, (user) => user.connectionType);

export const selectIsFamilyConnected = createSelector(selectUser, (user) => user.isFamilyConnected);
