import { declension } from 'utils/format-string';

const infinityParamSymbol = '<span data-type="infinitySymbol">∞</span>';
const yellowInfinityIcon = 'yellow-infinity';

const getMinutePackage = (tariff) => {
  if (tariff.hasMinutePackage) {
    return tariff.minutePackage.numberValue || 0;
  }
  if (tariff.hasMinutePackageSplitting) {
    return tariff.totalSplittedMinutePackageValue || 0;
  }
};

const getTvChannelsText = (tariff, channelsTextArray) =>
  declension(channelsTextArray, tariff.channelCount);

const getPrice = ({ feeToShow, oldFee }, unit = '₽/мес') => ({
  absolutePrice: oldFee,
  label: 'Price',
  payment: false,
  value: String(feeToShow),
  widgetType: 'normal',
  unit,
});

/**
 * @returns {{
 *   absolutePrice: 0.0,
 *   payment: false,
 *   unit: null,
 *   value: null,
 *   widgetType: 'normal',
 * }}
 */
const getDefaultParamObj = () => ({
  absolutePrice: 0.0,
  payment: false,
  unit: null,
  value: null,
  widgetType: 'normal',
});

const getMobileParameters = (tariff, tariffsText) => {
  const params = [];
  const {
    smsPackage,
    smsPackageClosestPeople,
    messengersPackage,
    tetheringPackage,
    splitInternetDeviceCount,
    sharePackageText,
  } = tariff;
  const mobileInternet = tariff.mobileTraffic;
  const minutes = getMinutePackage(tariff);

  if (mobileInternet) {
    params.push({
      ...getDefaultParamObj(),
      unit: tariffsText.mobileTrafficUnit,
      value: mobileInternet.isUnlimited ? infinityParamSymbol : String(mobileInternet.numberValue),
    });
  }

  if (minutes) {
    params.push({
      ...getDefaultParamObj(),
      unit: tariffsText.minutesUnit,
      value: String(minutes),
    });
  }

  if (smsPackageClosestPeople) {
    params.push({
      ...getDefaultParamObj(),
      unit: smsPackageClosestPeople.titleMobile,
      value: infinityParamSymbol,
    });
  } else if (smsPackage && smsPackage.numberValue) {
    params.push({
      ...getDefaultParamObj(),
      unit: tariffsText.smsUnit,
      value: String(smsPackage.numberValue),
    });
  }

  if (messengersPackage) {
    const messengerParam = {
      ...getDefaultParamObj(),
      icon: yellowInfinityIcon,
      unit: messengersPackage.units,
    };

    if (messengersPackage.iconUrl) {
      messengerParam.widgetType = 'with-image';
      messengerParam.data = {
        image: messengersPackage.iconUrl,
      };
    }

    params.push(messengerParam);
  }

  if (tetheringPackage) {
    params.push({
      ...getDefaultParamObj(),
      icon: yellowInfinityIcon,
      unit: tetheringPackage.units,
    });
  }

  if (splitInternetDeviceCount > 0) {
    params.push({
      ...getDefaultParamObj(),
      icon: tariffsText.shareIconIconPrefix + splitInternetDeviceCount,
      unit: sharePackageText,
    });
  }

  return params;
};

const getAdditionalParameters = (tariff, tariffsText) => {
  const additionalParams = [];

  const speed = tariff.internetSpeed;
  if (speed) {
    additionalParams.push({
      ...getDefaultParamObj(),
      unit: tariffsText.internetSpeedUnit,
      value: String(speed),
    });
  }

  const { isWifiRouterFree } = tariff;
  if (isWifiRouterFree) {
    additionalParams.push({
      ...getDefaultParamObj(),
      unit: tariffsText.wifiRouterText,
      widgetType: 'with-plus',
    });
  }

  const hasTv = tariff.hasTvTariff && tariff.channelCount > 0;
  if (hasTv) {
    additionalParams.push({
      ...getDefaultParamObj(),
      unit: getTvChannelsText(tariff, tariffsText.channelsTextArray),
      value: String(tariff.channelCount),
    });
  }

  const hasTvDevice = hasTv && tariff.hasTVE && tariff.isTVEFree;
  if (hasTvDevice) {
    additionalParams.push({
      ...getDefaultParamObj(),
      unit: tariffsText.tvDeviceText,
      widgetType: 'with-plus',
    });
  }

  /* FIRST-1735 всегда добавлять 4в1 */

  if (tariffsText.fourInOneText && tariffsText.fourInOneIcon) {
    additionalParams.push({
      ...getDefaultParamObj(),
      unit: tariffsText.fourInOneText,
      icon: tariffsText.fourInOneIcon,
      widgetType: 'normal',
    });
  }

  return additionalParams;
};

const getDiscount = (tariff) => {
  if (!tariff.discountText) return null;

  return {
    label: tariff.discountText,
    percent: tariff.discountPercent,
    priceWithoutDiscount: tariff.oldFee,
  };
};

export default function mapTariffs(data, tariffsText = {}) {
  const closeAlias = 'blizkie-lyudi-3-plus-300-mbits';
  const result = {
    catalogDiscountText: data.catalogDiscountText,
    tariffs: data.list.map((tariff) => ({
      additionalParameters: getAdditionalParameters(tariff, tariffsText),
      parameters: getMobileParameters(tariff, tariffsText),
      price: getPrice(
        tariff.marketingProduct.alias === closeAlias ? { feeToShow: 495, oldfee: 945 } : tariff,
        tariffsText.priceUnit,
      ),

      title: tariff.marketingProduct.title,
      alias: tariff.marketingProduct.alias,

      discount: getDiscount(
        tariff.marketingProduct.alias === closeAlias ?
          { discountText: '- 50% на 3 месяца', discountPercent: 50, oldFee: 945 }
        : tariff,
      ),
      link: tariff.aboutLink,

      orderLink: tariff.buyLink,
      orderButtonText: tariffsText.connectionButtonText,
      aboutLinkText: tariffsText.aboutLinkText,

      newYearDiscountText: null,
      nameplateDiscount: null,
      priceDescription: null,
      cardStyle: null,
      availableForConnect: true,
      clickable: true,
      hideButton: false,
      isConnected: false,
      isArchived: false,
    })),
  };

  result.tariffs.forEach((tariff, i, selfArray) => {
    tariff.tariffsGroup = [{ entries: selfArray }];
  });

  return result;
}
