import { useEffect, useState } from 'react';

import { isEmptyValue } from 'utils/isEmptyValue';
import {
  setErrorText,
  setErrorValidation,
  setLoaderContentStep,
  setStepModalSpn,
  setToggleModal,
} from 'pages/SPN/store/actions';
import store from 'store';

// Analytics
import { spnAnalyticsParams } from '../ym';
import { MODAL_STEPS_SPN } from '../store/constants';
import {
  changeMobileSPNService,
  connectMobileSPNService,
  deactivateMobileSPNService,
} from '../api/index';
import { selectMainStepData, selectStateSpn } from '../store/actions/selectors';
import {
  checkChangedNetworkNameOrSoc,
  checkIsNotChangedSoc,
  isRefersToEditable,
  validationAndSetError,
} from './common';
import {
  HandlersControllerTypes,
  HandlersModalType,
  HeaderBarConfigType,
  ModalContainerHookTypes,
  ModalsMainTypes,
  OptionsActionBarType,
  OptionsDataForCommonModalType,
  OptionsDataForMainModalTypes,
} from '../types';

export const useInteractionModalSPN = ({
  modalsContent,
  childSocsEditable,
  childSocs,
  spnState,
  step,
  dispatch,
  loaderContentStep,
  hasErrorValidation,
  errorText,
  isLoadingModal,
  isWaitingRequest,
}: ModalContainerHookTypes) => {
  const [content, setContent] = useState<
    { [K in keyof ModalsMainTypes]: ModalsMainTypes[K] } | any
  >({});

  useEffect(() => {
    setContent(modalsContent[step]);
    spnAnalyticsParams('popup_view');

    return () => {
      dispatch(setErrorValidation(false));
      dispatch(setErrorText(''));
    };
  }, [step, isLoadingModal]);

  const haveChildSocs = !isEmptyValue(childSocs);

  const hasConnectedSoc = [childSocs, childSocsEditable].reduce((acc, soc) => {
    const finded = soc?.find((el) => el?.isConnected);

    if (finded) acc = finded.isConnected;

    return acc;
  }, false);

  const defaultSocEditable = childSocsEditable?.find((el) => el?.isDefault === true);
  const defaultSocChild =
    haveChildSocs ? childSocs?.find((el) => el?.isDefault === true) : undefined;

  const choosenSocEditable = defaultSocEditable ?? childSocsEditable?.[0];

  const isEditable = isRefersToEditable();

  const placeholder =
    choosenSocEditable?.placeholder || (defaultSocChild?.isDefault ? defaultSocChild?.title : '');
  const preselectedSoc = defaultSocEditable?.soc || defaultSocChild?.soc || '';

  const hasNeedToChangeSPN = checkChangedNetworkNameOrSoc(choosenSocEditable, preselectedSoc);
  const isDefaultSocChildNotChanged =
    defaultSocChild?.isConnected ? checkIsNotChangedSoc(defaultSocChild?.soc) : false;
  const dontNeedToChangeName = !hasNeedToChangeSPN && hasConnectedSoc;

  const headerBarConfig: HeaderBarConfigType = {
    headerText: spnState?.pageTitle ?? '',
  };

  // Options for MainStep component and ActionBarMain
  const optionsDataForMainModal: OptionsDataForMainModalTypes = {
    haveChildSocs,
    choosenSocEditable,
    defaultSocChild,
    defaultSocEditable,
    content,
    childSocsEditable,
    childSocs,
    hasErrorValidation,
    errorText,
    isWaitingRequest,
    hasConnectedSoc,
    dontNeedToChangeName,
    isDefaultSocChildNotChanged,
    isEditable,
    placeholder,
    preselectedSoc,
    step,
  };

  let optionsDataForCommonModal: OptionsDataForCommonModalType = {
    title: '',
    text: '',
    img: '',
  };

  let optionsActionBar: OptionsActionBarType = {
    mainButtonText: '',
    additionalButtonText: '',
  };

  // Handler Сontroller
  const handlersController: HandlersControllerTypes = {
    [MODAL_STEPS_SPN.MAIN]: {
      main: async () => {
        const state = store.getState();
        const { spn, soc } = selectMainStepData(state);

        if (isEditable) {
          const {
            childSocsEditable: { errors },
          } = content;
          const { errorStr } = validationAndSetError({ value: spn, errors, dispatch });

          if (errorStr) return;
        }

        if (hasConnectedSoc) {
          if (!dontNeedToChangeName && !hasErrorValidation) {
            await dispatch(changeMobileSPNService(soc, spn));

            // analytics
            spnAnalyticsParams('change_name');
            return;
          }
        }

        await dispatch(connectMobileSPNService(soc, spn));

        // analytics
        spnAnalyticsParams('activate');
      },
      additional: () => {
        dispatch(setStepModalSpn(MODAL_STEPS_SPN.CONFIRM_DEACTIVATE));
      },
    },
    [MODAL_STEPS_SPN.CONFIRM_DEACTIVATE]: {
      main: async () => {
        const state = store.getState();
        const { soc } = selectStateSpn(state);

        await dispatch(deactivateMobileSPNService(soc));

        // analytics
        spnAnalyticsParams('deactivate');
      },
      additional: () => {
        dispatch(setStepModalSpn(MODAL_STEPS_SPN.MAIN));
      },
    },
    COMMON: {
      main: () => {
        dispatch(setToggleModal());
        dispatch(setLoaderContentStep(''));
      },
      additional: () => null,
    },
  };

  let handlersModal: HandlersModalType = {
    main: () => null,
    additional: () => null,
  };

  const commonStep = 'COMMON';
  switch (step) {
    case MODAL_STEPS_SPN.MAIN:
      handlersModal = {
        main: () => handlersController[step].main(),
        additional: () => handlersController[step].additional(),
      };
      break;

    case MODAL_STEPS_SPN.CONFIRM_DEACTIVATE:
      handlersModal = {
        main: () => handlersController[step].main(),
        additional: () => handlersController[step].additional(),
      };

      optionsDataForCommonModal = {
        title: content?.title,
        text: content?.text,
        img: content?.img,
      };

      optionsActionBar = {
        mainButtonText: content.deactivateButtonText,
        additionalButtonText: content.cancelButtonText,
      };
      break;
    case MODAL_STEPS_SPN.SUCCESS_ACTIVATE:
    case MODAL_STEPS_SPN.SUCCESS_DEACTIVATE:
    case MODAL_STEPS_SPN.SUCCESS_CHANGE:
    case MODAL_STEPS_SPN.TECH_ERROR:
    case MODAL_STEPS_SPN.TIMEOUT_ERROR:
      handlersModal = {
        main: () => handlersController[commonStep].main(),
        additional: () => null,
      };

      // analytics
      if (step === MODAL_STEPS_SPN.TIMEOUT_ERROR || step === MODAL_STEPS_SPN.TECH_ERROR) {
        spnAnalyticsParams({ error: content?.title });
      } else {
        spnAnalyticsParams({ result_page: content?.title });
      }

      optionsDataForCommonModal = {
        title: content?.title,
        text: content?.text,
        img: content?.img,
      };

      optionsActionBar = {
        mainButtonText: content.buttonText,
        additionalButtonText: '',
      };
      break;
    case MODAL_STEPS_SPN.LOADER:
      optionsDataForCommonModal = {
        title: content?.[loaderContentStep]?.title,
        text: content?.[loaderContentStep]?.text,
        img: content?.img,
        animationName: content?.animationName,
      };

      handlersModal = {
        main: () => null,
        additional: () => null,
      };

      break;

    default:
      return null;
  }

  return {
    headerBarConfig,
    optionsDataForMainModal,
    handlersModal,
    optionsActionBar,
    optionsDataForCommonModal,
  };
};
