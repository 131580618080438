import { times } from '@beef/utils';

const MIN_SUPPORTED_RESOLUTION = 320;

export const makeGridQueries = (
  id,
  count,
  { minSize, edgeIndent, gutter, minVisible, maxVisible, ...option },
) => {
  /**
   * MinColumnSize layout:
   * | edgeSpace | minSize | gutter | minVisible * minSize |
   *
   * MaxColumnSize layout:
   * | edgeSpace | maxSize | gutter | maxVisible * minSize |
   *
   * Window width for MaxColumnSize equals MinColumnSize for same blocks + 1:
   * ------
   * | edgeSpace | minSize | gutter | minSize | gutter | minVisible * minSize |
   */
  const calcMinFitSize = (n) => edgeIndent + n * gutter + minSize * (n + minVisible);
  const calcMaxSize = (n) => (calcMinFitSize(n + 1) - edgeIndent - n * gutter) / (n + maxVisible);
  const getMediaInterval = (min, max) =>
    `${min !== 0 ? `(min-width: ${min}px) and ` : ''}(max-width: ${max}px)`;

  /**
   * Рассчитываем константы по формуле для minFitSize и maxFitSize
   * blockSize = k * width + b,
   *   где k - динамический коэффициент
   *   b - константа
   *   W - разрешение экрана
   */
  const getWidthFormula = (minWidth, maxWidth, minFitSize, maxFitSize) => {
    const k = (maxWidth - minWidth) / (maxFitSize - minFitSize);
    const b = minWidth - minFitSize * k;
    return `calc(${Math.round(100 * k)}vw + ${Math.round(b)}px)`;
  };

  return [
    `
      .${id} {
        min-width: ${minSize}px; 
        max-width: ${option.maxSize}px;
      }
    `,
    ...times(count - 1, (i) => i).map((n) => {
      const minFitSize = calcMinFitSize(n);
      const maxFitSize = calcMinFitSize(n + 1);
      const maxSize = calcMaxSize(n);
      if (maxFitSize < MIN_SUPPORTED_RESOLUTION) return undefined;

      return `
      @media ${getMediaInterval(minFitSize, maxFitSize)} {
        .${id} {
          min-width: ${getWidthFormula(minSize, maxSize, minFitSize, maxFitSize)}
        }
      }
    `;
    }),
  ].filter(Boolean);
};
