import React, { forwardRef } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const AppLayout = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode; className?: string; style: React.CSSProperties }
>(({ children, className = '', style }, ref) => (
  <div className={cx('app-layout', className)} ref={ref} style={style}>
    {children}
  </div>
));
