// Utils
import { getNotificationFill } from '../../../../../utils';

/**
 * Хук "useNotificationPrepareData" готовит данные для "ConnectionNotification".
 * @param text
 * @param status
 * @returns {{notificationText: *, fill: string}}
 */
export const useNotificationPrepareData = ({ status, statusTexts }) => {
  const fillColor = getNotificationFill(status);
  const notificationText = statusTexts?.[status];

  return { fill: fillColor, notificationText };
};
