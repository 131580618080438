import React from 'react';
import classNames from 'classnames/bind';
import { CheckIcon, Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IProps {
  isConnected: boolean;
  text: string;
}

export const ServiceStatus: React.FC<IProps> = ({ isConnected, text }): JSX.Element | null =>
  isConnected ?
    <div className={cx('service-status')}>
      <CheckIcon fill="rgb(10,206,115)" />
      <Text color="green" size="size5-m">
        {text}
      </Text>
    </div>
  : null;
