import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames/bind';
import { Button, Link, Preloader } from '@beef/legacy-ui-kit';

import PopupContainer from 'pages-components/PopupContainer/PlainContainer';
import PopupStateless from 'pages-components/Popup';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const options = {
  button: Button,
  link: Link,
};

class SmsReminder extends Component {
  state = {
    opened: false,
    step: 'pending',
  };

  onClosePopup = () => {
    this.setState({ opened: false });
  };

  makeReminder = () => {
    const { callback } = this.props;

    this.setState({ opened: true, step: 'pending' });

    axios.post(this.props.postUrl).then(({ data }) => {
      this.setState({ step: data.isSucceeded ? 'success' : 'error' });
    });

    if (callback) callback();
  };

  render() {
    const { texts, type, className, color, needTransformer } = this.props;
    const { opened, step } = this.state;
    const Comp = options[type];

    return (
      <>
        <Comp
          className={cx(type === 'link' && 'handler', className)}
          color={color}
          onClick={this.makeReminder}
          transformer={needTransformer}
        >
          <span className={cx('reminderText')}>{texts.linkText}</span>
        </Comp>
        <PopupStateless onClose={this.onClosePopup} opened={opened}>
          {step === 'pending' ?
            <Preloader />
          : <PopupContainer
              buttonText={texts[`${step}ButtonText`]}
              description={texts[`${step}Description`]}
              emoji={texts[`${step}Emoji`]}
              heading={texts[`${step}Heading`]}
              onButtonClick={step === 'success' ? this.onClosePopup : this.makeReminder}
            />
          }
        </PopupStateless>
      </>
    );
  }
}

SmsReminder.defaultProps = {
  type: 'link',
  color: 'light',
  needTransformer: true,
};

SmsReminder.propTypes = {
  postUrl: PropTypes.string,
  callback: PropTypes.func,
  className: PropTypes.any,
  texts: PropTypes.object,
  type: PropTypes.string,
  color: PropTypes.string,
  needTransformer: PropTypes.bool,
};

export default SmsReminder;
