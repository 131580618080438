import { useCallback, useEffect } from 'react';
import { shallowEqual } from '@beef/utils';

import { useStepNavigation } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/hooks/useStepNavigation';
import { EUserStoryType } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/stepperSlice/types';

import { DEFAULT_INDEX } from '../constants';
import { generateHash } from '../utils/generateHash';
import { useAppDispatch, useAppSelector } from '../store';
import { setRequestServicesHash } from '../store/slices/fttbSlice';
import { getFmcActiveServices } from '../utils/getFmcActiveServices';
import { TabTypes } from '../components/constructorPage/Tabs/constants';
import { setDefaultFmcServices } from '../store/slices/commonContentSlice';
import { setConnectedConvergenceAddress } from '../store/slices/totalSlice';
import { useIsConvergentUser } from './tariffStateHooks/useIsConvergentUser';
import { TFetchFmcServicesQueryRes, fetchFmcServices } from '../api/fmcServices';
import { changeFmcInacId } from '../store/slices/totalSlice/asyncThunks/changeFmcInacId';

/** Отправки запроса для получения данных по ШПД для последующей настройки.
 * Возвращает полный массив с объектами, включающий в себя пресеты/опции ШПД, оборудование и т.д. */
export const useGetAvailableFmcServices = () => {
  const dispatch = useAppDispatch();
  const { userType } = useStepNavigation();
  const isConvergenceUser = useIsConvergentUser();

  const {
    regionId,
    isConnected,
    generation,
    characterSoc,
    priceCategory,
    currentTariffTab,
    connectedAddress,
    connectedInacId,
    selectedNewInacId,
    highSpeedAvailable,
    currentRequestHash,
    connectedFmcInacId,
    tariffSoc,
  } = useAppSelector(
    (state) => ({
      generation: state.total.generation,
      characterSoc: state.total.characterSoc,
      priceCategory: state.total.priceCategory,
      currentTariffTab: state.total.currentTariffTab,
      isConnected: state.common.userInfo?.isConnected,
      regionId: state.common.userRegion.regionId,
      currentRequestHash: state.fttbPresets?.requestServicesHash,
      highSpeedAvailable: state.total.convergenceInfo.highSpeedAvailable,
      connectedFmcInacId: state.common.userInfo.convergenceConnectedInfo?.inacId || '',
      connectedAddress: state.common.userInfo.convergenceConnectedInfo?.connectedAddress || '',
      connectedInacId: state.common.defaultConstructor.defaultTariffData?.tariffInacId || '',
      selectedNewInacId: state.total.convergenceInfo.tariffInacId || '',
      tariffSoc: state.total.tariffSoc,
    }),
    shallowEqual,
  );

  /* Текущий активный InacId.
   * Если пользователь настраивает свой тариф (тип пользователя authConnectedSettingsFTTB),
   * используем для расчетов connectedInacId.
   * Если пользователь настраивает новый ШПД, используем выбранный InacId - selectedNewInacId */
  const activeInacId =
    userType === EUserStoryType.authConnectedSettingsFTTB ? connectedInacId : selectedNewInacId;

  /* Формируем хэш по текущим параметрам запроса, для последующего предотвращения запроса с аналогичными данными */
  const requestHash = generateHash(regionId, generation, characterSoc, priceCategory);

  /** Функция позволяет установить адрес подключения ШПД пользователя.
   * Логика вынесена в функцию, т.к. нужно задействовать ее в нескольких сценариях */
  const setConnectedAddress = useCallback(() => {
    /* Если текущий активный таб способа подключения конструктора ChangeTariffForm и
     в hydrate пришел адрес подключения ШПД, устанавливаем его (адрес подключения) по-умолчанию */
    if (connectedAddress && currentTariffTab === TabTypes.ChangeTariffForm) {
      dispatch(setConnectedConvergenceAddress(connectedAddress));
    }
  }, [connectedAddress]);

  /** Для единого конструктора на шпд берется сок тарифа тк нет сока персонажа */
  const soc = characterSoc || tariffSoc;

  /* Хук будет вызывать fetchFmcServices при изменении priceCategory для получения актуальных данных по ШПД-тарифам */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    /* Если текущий хэш запроса из стора (currentRequestHash) НЕ равен хэшу текущего запроса (requestHash),
     выполняем новый запрос */
    if (currentRequestHash !== requestHash) {
      /* Получаем данные по ШПД-пресетам для главной страницы конструктора и добавляем их в стор */
      const promise = dispatch(
        fetchFmcServices({
          regionId,
          generation,
          soc,
          priceCategory,
          isConnected,
          highSpeedAvailable: highSpeedAvailable as number,
        }),
      );

      /* Записываем хэш текущего запроса в стор */
      promise
        .unwrap()
        /* Выставляем активный таб ШПД и включаем активные по-умолчанию сервисы */
        .then((data: TFetchFmcServicesQueryRes) => {
          /* Если в пришедших тарифах НЕТ выбранного и отсутствует подключенный InacId (connectedFmcInacId)
           или текущий тип пользователя authNewSimNotConnectedWithFTTB (покупка новой sim в ШПД в АЗ),
           делаем активным первый тариф из массива */
          if (
            (!connectedFmcInacId || userType === EUserStoryType.authNewSimNotConnectedWithFTTB) &&
            data.tariffs?.findIndex((tariff) => tariff.inacId === activeInacId) === -1
          ) {
            return dispatch(changeFmcInacId(data?.tariffs?.[DEFAULT_INDEX].inacId));
          }

          /* Если пользователь с подключенным ШПД,
           устанавливаем в common дефолтные данные списка подключенных сервисов */
          if (isConvergenceUser) {
            const currentTariff =
              data.tariffs?.find((tariff) => tariff.inacId === activeInacId) ?? {};
            const currentTariffServices = Object.values(currentTariff);
            const defaultActiveServices = getFmcActiveServices(currentTariffServices);

            /* Если в hydrate пришел адрес подключения ШПД устанавливаем его по-умолчанию */
            setConnectedAddress();

            dispatch(setDefaultFmcServices(defaultActiveServices));
          }

          /* Определяем вариант inacId для записи в стор,
           в зависимости от типа таба подключения тарифа и наличия подключенного ШПД */
          const chosenInacId =
            connectedFmcInacId && currentTariffTab === TabTypes.ChangeTariffForm ?
              connectedFmcInacId
            : activeInacId;

          /* Если пользователь уже подключил ШПД (connectedFmcInacId) или в пришедших тарифах есть выбранный inacId,
             делаем его активным и включаем активные сервисы */
          return dispatch(changeFmcInacId(chosenInacId));
        })
        /* Выставляем хэш текущего запроса (используется для предотвращения повторных запросов) */
        .then(() => dispatch(setRequestServicesHash(requestHash)))
        .catch((e: Error) => {
          /* Если в hydrate пришел адрес подключения ШПД устанавливаем его по-умолчанию */
          setConnectedAddress();
          console.error(e);
        });

      /* Отменяем запрос, если пользователь перешел на предыдущую страницу */
      return () => {
        promise.abort();
      };
    }
  }, [priceCategory]);
};
