import classNames from 'classnames/bind';
import React, { ReactElement } from 'react';
// Components
import { CarouselWithNavigation, PageRow } from '@beef/smart-kit';
import { Text } from '@beef/ui-kit';

import { selectBenefitsData } from '../../store/selectors';
import { Benefit } from './components/Benefit';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type IConditionalCarouselProps = React.PropsWithChildren<{ hasCarousel: boolean }>;

const ConditionalCarousel: React.FC<IConditionalCarouselProps> = ({ children, hasCarousel }) =>
  hasCarousel ?
    <CarouselWithNavigation
      className={cx('swiper-container')}
      settings={{
        spaceBetween: 24,
        simulateTouch: true,
        pagination: {
          clickable: true,
        },
        slidesPerView: 3,
        observer: true,
        observeParents: true,
      }}
      slideWidthAuto
      withDefaultNavigation
    >
      {[children].flat()}
    </CarouselWithNavigation>
  : (children as ReactElement | null);

type TBenefitProps = {
  benefits: Partial<ReturnType<typeof selectBenefitsData>['benefits'][number]>[];
};

type TBenefitsProps = Omit<ReturnType<typeof selectBenefitsData>, 'benefits'> & TBenefitProps;

export const Benefits: React.FC<TBenefitsProps> = ({
  benefits = [],
  title,
  hasImage,
  layoutType = 'default',
}) => (
  <PageRow blockSpacersPreset="m" tagName="section">
    <span className={cx('title')}>
      <Text size="size1-m">{title}</Text>
    </span>

    <div className={cx('content', !hasImage && 'no-image', `content--${layoutType}`)}>
      <ConditionalCarousel hasCarousel={layoutType === 'alt'}>
        {benefits.map((benefit) => (
          <Benefit benefit={benefit} key={benefit.id} layoutType={layoutType} />
        ))}
      </ConditionalCarousel>
    </div>
  </PageRow>
);
