import React from 'react';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const Layout = ({ buttonNode, timerNode, rulerRef, progress, isPlaying, className }) => (
  <div className={cx('player', { isPlaying }, className)}>
    {buttonNode}
    <div className={cx('progress')}>
      <div className={cx('rulerWrap')} ref={rulerRef}>
        <div className={cx('ruler')}>
          <div className={cx('filler')} style={{ transform: `scaleX(${progress})` }} />
        </div>
        <div
          className={cx('thumb', {
            beginning: !progress,
            end: progress === 1,
          })}
          style={{ left: `${progress * 100}%` }}
        />
      </div>
      {timerNode}
    </div>
  </div>
);
