import React, { FC } from 'react';

import { usePrepareFmcServicesTabs } from '../hooks/usePrepareFmcServicesTabs';
import { TitleContent } from '../../../../../../commonComponents/TitleContent';
import { WPContainer } from '../../../../../../commonComponents/WPContainer';
// TODO: не забыть заменить импорт при выносе FmcTabs в отдельный пакет!
import { FmcTabs } from '../../../../../../../tempComponents/FmcTabs';

/** Компонент-обертка над доступными табами ШПД-пресетов на странице настройки ШПД */
export const FmcServicesTabsContainer: FC = () => {
  const { titleContentProps, chipsStatelessFmcProps } = usePrepareFmcServicesTabs();

  return (
    <>
      <TitleContent {...titleContentProps} />
      <WPContainer>
        <FmcTabs {...chipsStatelessFmcProps} />
      </WPContainer>
    </>
  );
};
