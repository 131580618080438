import { searchConnectedSoc } from '../../../../../../utils';

/**
 * Функция getPeriodTitle определяет период, который необходимо
 * отобразить пользователю, в зависимости от выбранных параметров
 * @returns {string}
 */
export const getPeriodTitle = (option, checked, activeTabPeriod) => {
  if (option?.defaultEnabled && option.tabs[activeTabPeriod]?.isConnected) {
    return `${option.title} ${option?.initialPeriod}`;
  }
  return checked ?
      `${option.title} ${option.tabs[activeTabPeriod]?.expirationPeriod}`
    : option.title;
};

/**
 * Функция checkedActiveTab определяет активный таб (период),
 * а также управляет вкл/выкл тогла опции
 * @returns {string}
 */
export const checkedActiveTab = ({
  idx,
  option,
  checked,
  infinite,
  setChecked,
  isConnected,
  activeTabPeriod,
  defaultPeriodTab,
  setActiveTabPeriod,
  hasConnectedPeriod,
}) => {
  if (idx === defaultPeriodTab && isConnected && !infinite && option.persistentEnabled) {
    return;
  }
  if (checked) {
    setActiveTabPeriod(idx);
    if (idx === activeTabPeriod) {
      setActiveTabPeriod(defaultPeriodTab);
      if (!hasConnectedPeriod) {
        setChecked(option.defaultEnabled);
      }
    }
  }
};

/**
 * Функция createOptionsParams создает объект с данными
 * по опции, исходя из выбранных условий
 * @returns {object}
 */
export const createOptionsParams = (checked, option, activeTabPeriod) => ({
  [option.title]: {
    ...option,
    checked,
    price: option.tabs[activeTabPeriod]?.price,
    period: option.tabs[activeTabPeriod]?.expirationPeriod,
    unit: option.tabs[activeTabPeriod]?.unit,
    isConnectedPeriod: option.tabs[activeTabPeriod]?.isConnected,
    soc: option.tabs[activeTabPeriod]?.soc,
    connectedSoc: searchConnectedSoc(option.tabs) || null,
  },
});

export const onChange =
  (setChecked, checked, setActiveTabPeriod, defaultPeriodTab, callback) => (e) => {
    setChecked(e.target.checked);

    if (checked) {
      setActiveTabPeriod(defaultPeriodTab);
    }
    callback();
  };
