import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button, Input } from '@carnica/ui-kit';
import { CloseIcon } from '@beef/ui-kit';

import { Metric } from 'pages/CarnicaDSLandings/MNPLanding/analytics';

import styles from './styles.pcss';
import { useInputForm } from './hooks/useInputForm';

const cx = classNames.bind(styles);

/**
 * Блок с вводом номера для его переноса
 */
export const InputForm: FC = () => {
  const {
    handleBlur,
    handleClear,
    handleFocus,
    handleChangeNumber,
    phoneNumber,
    buttonTitle,
    error,
    handleSubmit,
    isLoading,
    placeholder,
  } = useInputForm();

  return (
    <>
      <Metric events={{ yaMNPMetric: ['handleClickInput'] }} mode="click">
        <Input
          className={cx('input')}
          error={error}
          onBlur={handleBlur}
          onChange={handleChangeNumber}
          onFocus={handleFocus}
          placeholder={placeholder}
          rightSide={
            phoneNumber && (
              <div onClick={handleClear}>
                <CloseIcon fill="#28303F" size="s" />
              </div>
            )
          }
          status={error ? 'error' : 'default'}
          value={phoneNumber}
          wrapperProps={{ className: cx('input-wrapper') }}
        />
      </Metric>

      <Metric events={{ yaMNPMetric: ['handleClickStartTransferPhoneNumber'] }} mode="click">
        <Button
          className={cx('button')}
          disabled={Boolean(error) || isLoading}
          loading={isLoading}
          onClick={handleSubmit}
        >
          {buttonTitle}
        </Button>
      </Metric>
    </>
  );
};
