import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { path, pathOr } from 'ramda';
import { Button, Heading, Link, Status } from '@beef/legacy-ui-kit';

import { templateParser } from 'utils/format-string';
import OptionSelector from 'pages-components/OptionSelector/OptionSelector';
import PopupStateless from 'pages-components/Popup';

import styles from './SettingsPopup.pcss';
import CreditLimitTuner from './CreditLimitTuner';
import AuthHint from '../../AuthHint/AuthHint';

const cx = classNames.bind({ ...styles });

class ServiceInfoPopup extends Component {
  constructor(props) {
    super(props);

    this.textVariables = {
      serviceName: path(['banner', 'serviceName'], this.props.data),
      value: path(['banner', 'creditAmountValue'], this.props.data),
      tariffLink: null,
      authLink: null,
    };
  }

  getPrice() {
    const depositData = pathOr({}, ['card', 'deposit'], this.props.data);

    if ((!this.props.isConnecting && depositData.isReturn) || this.props.amount) {
      return '';
    }
    return templateParser(path(['creditAmountFormat'], this.props.data.banner), this.textVariables);
  }

  renderText(textPath, className) {
    return (
      <span
        className={className}
        dangerouslySetInnerHTML={{
          __html: templateParser(path(textPath, this.props.data.banner), this.textVariables),
        }}
      />
    );
  }

  renderHint() {
    const { data } = this.props;
    if (this.props.isAuthenticated) {
      if (!data.card.isConnectingInProgress) {
        return data.isConnecting && !data.banner.isConnected ?
            <div className={cx('connected')} ref={(c) => (this.connectContainer = c)}>
              {this.renderText(['goingToAddText'])}
            </div>
          : null;
      }

      return <div className={cx('connected')}>{data.card.connectingInProgressMessage}</div>;
    }

    return !data.isConnecting && !data.banner.isConnected ?
        <div className={cx('connected')} ref={(c) => (this.connectContainer = c)}>
          <AuthHint
            authLinkText={path(['banner', 'authLinkText'], data)}
            connectTariffText={path(['banner', 'connectTariffText'], data)}
            onAuth={this.props.onAuth}
            onTariffConnect={this.props.onTariffConnect}
            text={path(['banner', 'authToAddServiceText'], data)}
          />
        </div>
      : null;
  }

  render() {
    if (!this.props.opened) return null;

    const title = this.renderText(['serviceTitleFormat']);
    const url = pathOr('#', ['banner', 'serviceCardUrl'], this.props.data);
    const { card, banner } = this.props.data;
    const creditData = pathOr({}, ['card', 'creditAmount'], this.props.data);
    const depositData = pathOr({}, ['card', 'deposit'], this.props.data);

    return (
      <PopupStateless onClose={this.props.onClose} opened={this.props.opened}>
        <div>
          <Heading level={1}>{title}</Heading>
          <div className={cx('content')}>
            <div className={cx('description-wrapper')}>
              {this.renderText(['serviceConnectDescription'])}
            </div>
            <OptionSelector
              checked={this.props.active}
              inProgress={card.isConnectingInProgress}
              isAvailable={this.props.isAuthenticated}
              name="alwaysInPositive"
              onChangeValue={this.props.onConnectionChange}
              price={this.getPrice()}
              subTitle={this.renderHint()}
              title={title}
            >
              {this.props.active ?
                <div>
                  <CreditLimitTuner
                    amount={this.props.amount}
                    creditData={creditData}
                    depositData={depositData}
                    isConnected={this.props.data.banner.isConnected}
                    isConnecting={this.props.isConnecting || card.isConnectingInProgress}
                    onValueChange={this.props.onLimitChange}
                  />
                  {this.props.isConnecting && (card.isConnectingInProgress || banner.isConnected) ?
                    <div className={cx('message')}>
                      <Status
                        className={[cx('leftAlignedText'), 'attention']}
                        emoji="pointingHandUp"
                      >
                        {templateParser(creditData.changedMessage, { value: this.props.amount })}
                      </Status>
                    </div>
                  : null}
                  <div className={cx('buttons')}>
                    {!banner.isConnected || this.props.isConnecting ?
                      <div className={cx('button')}>
                        <Button
                          className="big"
                          disabled={!!card.isConnectingInProgress && !this.props.isConnecting}
                          onClick={this.props.onConnectClick}
                          wide
                        >
                          {!this.props.isConnecting && card.isConnectingInProgress ?
                            card.connectingInProgressMessage
                          : this.renderText(['addServiceButtonText'])}
                        </Button>
                      </div>
                    : null}
                  </div>
                  {!card.isConnectingInProgress && !banner.isConnected ?
                    <div className={cx('buttons')}>
                      <div className={cx('button')}>
                        <Link className={cx('resetLink')} onClick={this.props.onCancel}>
                          {this.renderText(['cancelAddingServiceButtonText'])}
                        </Link>
                      </div>
                    </div>
                  : null}
                </div>
              : null}
            </OptionSelector>
            <div className={cx(['link', 'details-link'])}>
              <Link href={url} target="_blank">
                {this.renderText(['serviceCardUrlText'])}
              </Link>
            </div>
          </div>
        </div>
      </PopupStateless>
    );
  }
}

ServiceInfoPopup.propTypes = {
  data: PropTypes.object,
  opened: PropTypes.bool,
  amount: PropTypes.string,
  onClose: PropTypes.func,
  onConnectionChange: PropTypes.func,
  onLimitChange: PropTypes.func,
  onConnectClick: PropTypes.func,
  active: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isConnecting: PropTypes.bool,
  onAuth: PropTypes.func,
  onTariffConnect: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ServiceInfoPopup;
