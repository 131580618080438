import { useRef } from 'react';
import { shallowEqual } from 'react-redux';

import { useAppSelector } from '../../../store';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { useOnScreenAnalytics } from '../../../tempAnalytics/hooks/useOnScreenAnalytics';
import { EConstructorStep } from '../../../store/slices/stepperSlice/types';

/** Отправка метрики при видимости блока с табами ШПД */
export const useSendFmcTabsYm = () => {
  /* Устанавливаем presetsRef на контейнер с табами ШПД для отправки аналитики при видимости этого блока */
  const presetsRef = useRef(null);

  /* Получаем установленное значение netSpeed */
  const { netSpeed } = useAppSelector(
    (state) => ({
      netSpeed: state.total.convergenceInfo.netSpeed,
    }),
    shallowEqual,
  );

  /* Получаем значение currentStep для отправки аналитики в зависимости от страницы */
  const { currentStep } = useStepNavigation();

  /* Отправка аналитики если блок с табами виден больше 2х секунд
      при условии отображения не менее 50% площади блока */
  useOnScreenAnalytics({
    ref: presetsRef,
    payload: {
      block_fmc: 'view',
      ...(currentStep === EConstructorStep.fttbSettings && {
        fmc_step: 'custom',
        net_speed: netSpeed,
      }),
    },
  });

  return presetsRef;
};
