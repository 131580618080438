import React from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import classNames from 'classnames/bind';
import { Description, Descriptions, Heading } from '@beef/legacy-ui-kit';

import store from 'store';

import InviteForm from './components/InviteForm';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const InviteFriendLanding = ({
  currentCtn,
  data: { sendInviteUrl, heading, subHeading, description, formTexts, showNameInput, descriptions },
}) => (
  <div className={cx('page')}>
    <div className={cx('headings')}>
      {heading && (
        <Heading className={cx('mainHeading')} isStyled={false} level={1}>
          {heading}
        </Heading>
      )}
      {subHeading && (
        <Heading className={cx('subHeading')} level={4}>
          {subHeading}
        </Heading>
      )}
      {description && <Heading level={4}>{description}</Heading>}
    </div>

    <InviteForm
      currentCtn={currentCtn}
      sendInviteUrl={sendInviteUrl}
      showNameInput={showNameInput}
      texts={formTexts}
    />

    {descriptions && (
      <div className={cx('descriptions')}>
        <Descriptions>
          {descriptions.map((item, index) => (
            <Description key={index} title={item.title}>
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
            </Description>
          ))}
        </Descriptions>
      </div>
    )}
  </div>
);

// InviteFriendLanding.defaultProps = {
//   data: stubData,
// };

InviteFriendLanding.propTypes = {
  currentCtn: PropTypes.shape({}),
  data: PropTypes.shape({
    sendInviteUrl: PropTypes.string,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    description: PropTypes.string,
    formTexts: PropTypes.shape({}),
    showNameInput: PropTypes.bool,
    descriptions: PropTypes.shape([]),
  }),
};

const ConnectedLanding = connect((state) => ({
  currentCtn: state.external.currentCtn,
}))(InviteFriendLanding);

export default (props) => (
  <Provider store={store}>
    <ConnectedLanding {...props} />
  </Provider>
);
