import { debounce } from 'utils/timed-functions';

import { getActiveFilter } from '../selectors/filter';
import { pushNumbersShowTable } from '../../analytics/numbers';

let queue = [];

const handlePush = (filter) => {
  pushNumbersShowTable(queue, filter);
  queue = [];
};

const handlePushDebounced = debounce(handlePush, 100);

const handleShown = (number, filter) => {
  queue.push(number);
  handlePushDebounced(filter);
};

export const sendNumberShownEvent = (number) => (dispatch, getState) => {
  const state = getState();
  const filter = getActiveFilter(state);
  handleShown(number, filter);
};
