import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import type { TLogo } from './types';

const cx = classNames.bind(styles);

const BEELINE_LOGO = '//static.beeline.ru/upload/images/b2c/bee-logo/single.png';

export const Logo: FC<TLogo> = ({
  className,
  imgClassName,
  title,
  logoSrc,
  link = '',
  onClick,
}) => {
  return (
    <a
      className={cx('wrapper', className, { 'wrapper--disabled': !link })}
      href={link}
      onClick={onClick}
    >
      <img alt="билайн" className={cx('logo-img', imgClassName)} src={logoSrc || BEELINE_LOGO} />
      {title && <h3 className={cx('title')}>{title}</h3>}
    </a>
  );
};
