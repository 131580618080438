import React, { ReactElement, memo } from 'react';
import { Modal } from '@beef/ui-kit';

type BasePopUpContentProps = {
  actionBar?: ReactElement;
  isOpen: boolean;
  onClose: () => void;
};

export const BasePopUp = memo(
  ({ children, actionBar, onClose, isOpen }: React.PropsWithChildren<BasePopUpContentProps>) => (
    <Modal actionBar={actionBar} isOpen={isOpen} onClose={onClose} size="m">
      {children}
    </Modal>
  ),
);
