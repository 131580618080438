import { TTariffInfo } from '../components/constructorPage/TariffInfoTiles/types';

/** Функция определения текущего активного сока персонажа.
 * В зависимости от типа пресета (высший/низший) возвращает сок низшего/высшего порядка */
export const defineCharacterSoc = (
  tariffs: TTariffInfo[],
  alias: string,
  isHighPreset: boolean,
) => {
  const { optionSoc = '', highOptionSoc = '' } =
    tariffs?.find((tariff) => tariff?.alias === alias) || {};
  return isHighPreset ? highOptionSoc : optionSoc;
};
