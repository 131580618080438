import { ActionBar, Button, Link } from '@beef/ui-kit';
import React, { memo } from 'react';

import { getButtonProps } from './selectors';
import { TModalActionBarProps } from './types';

const ModalActionBar: React.FC<TModalActionBarProps> = memo(
  ({ loading, confirmButton, rejectButton }) => {
    const confirmProps = getButtonProps(confirmButton);
    const ConfirmButton = confirmProps.href ? Link : Button;

    const rejectProps = getButtonProps(rejectButton);
    const RejectButton = rejectProps.href ? Link : Button;

    return (
      <ActionBar size="m">
        {confirmProps.children && <ConfirmButton {...confirmProps} isLoading={loading} />}
        {rejectProps.children && <RejectButton {...rejectProps} />}
      </ActionBar>
    );
  },
);

export { ModalActionBar };
