import React, { FC, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { createCommonPictures } from 'constructorV1/utils/createCommonPictures';

import { CBillIcons, TCBillIcons } from '../CBillIcons';
import { CCloseBillIcons } from '../CCloseBillIcons';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCOptions = {
  sections?: Array<{
    options: TCBillIcons[];
    title: string;
  }>;
};

/** Варианты анимации блока с иконками в результирующем чеке ЕК */
const variantsCOptionsGroup = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
};

/** Блок с выбранными опциями для чека */
export const COptionsGroup: FC<TCOptions> = ({ sections }) => {
  /* Локальный state состояния раскрытия компонента опций в чеке */
  const [isOpen, setIsOpen] = useState(false);

  /* Обработчик клика по блоку с опциями */
  const handleClick = () => setIsOpen((state) => !state);

  return (
    <motion.button className={cx('wrapper')} layout onClick={handleClick} type="button">
      {sections?.map(({ title, options }) => (
        <AnimatePresence initial={false} key={title}>
          <div className={cx('wrapper__item')}>
            {title && (
              <motion.div layout>
                <Text
                  align="left"
                  color="secondary-invert"
                  lineHeight={26}
                  size="s"
                  weight="regular"
                >
                  {title}
                </Text>
              </motion.div>
            )}
            <motion.div
              animate="visible"
              className={cx('wrapper__icons')}
              exit="exit"
              initial="hidden"
              layout
              variants={variantsCOptionsGroup}
            >
              {/* CBillIcons - "раскрытые" опции, CCloseBillIcons - компактные опции */}
              {isOpen ?
                options.map((option) => <CBillIcons key={option.entityName} {...option} />)
              : <CCloseBillIcons pictures={createCommonPictures(options)} />}
            </motion.div>
          </div>
        </AnimatePresence>
      ))}
    </motion.button>
  );
};
