import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Constants
import { Text } from '@beef/ui-kit';

import { FREE_TEXT } from '../../../containers/ConstructorTariff/constants';

// Styles

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FamilyInfo = ({ title, price, unit }) => (
  <div className={cx('wrapper')}>
    {title && (
      <Text size="size5-m" tagType="h3">
        {title}
      </Text>
    )}
    {price ?
      <Text size="size5-r-s">{`${price} ${unit}`}</Text>
    : <Text size="size5-r-s">{FREE_TEXT}</Text>}
  </div>
);

FamilyInfo.propTypes = {
  unit: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number,
};

export { FamilyInfo };
