import axios from 'axios';

import { transformRequest } from 'utils/globalAjaxHandler';

export const requestCaptcha = async (url: string) => {
  const captcha = { key: '', pic: '' };

  try {
    const { headers, data } = await axios.get(url, {
      transformRequest,
      withCredentials: true,
      responseType: 'blob',
    });

    if (headers['content-type'] === 'image/gif') {
      captcha.pic = URL.createObjectURL(data);
    }

    if (headers['content-type'] === 'application/json') {
      const { captchakey, captcha: pic }: Record<string, string> = JSON.parse(await data.text());

      captcha.key = captchakey;
      captcha.pic = `data:image/png;base64,${pic}`;
    }
  } catch (err) {
    console.error(err);
  }

  return captcha;
};
