import { useAppDispatch, useAppSelector } from '../../../../store';
import { useThrowAsyncError } from '../../../../hooks/useThrowAsyncError';
import { fetchSubmitCallbackRequest } from '../../../../api/fetchSubmitCallbackRequest';
import { nextStep } from '../../../../store/slices/stepperSlice';
import { CALLBACK_CASE_TILE_SALE } from '../../../../constants';

export const useSubmitCallbackRequest = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();
  const phoneNumber = useAppSelector((state) => state.total.userPhoneNumber) as string;
  const caseTile = CALLBACK_CASE_TILE_SALE;

  const onCallbackSent = () => {
    dispatch(fetchSubmitCallbackRequest({ phoneNumber, caseTile }))
      .unwrap()
      .then(() => dispatch(nextStep()))
      .catch((e: Error) => throwAsyncError(e));
  };

  return { onCallbackSent };
};
