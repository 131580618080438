import React, { useState } from 'react';
import classNames from 'classnames/bind';

import { forwardRefWithTag } from 'pages/CarnicaDSLandings/utils/forwardRefWithTag';
import { useIsMobile } from 'utils/hooks/useIsMobile';

import styles from './styles.pcss';
import { HoveredCardProps } from './types';

const cn = classNames.bind(styles);

export const HoveredCard = forwardRefWithTag<HoveredCardProps, 'div'>((props, ref) => {
  const {
    borderRadius = '32px',
    cardHeight = '420px',
    cardWidth = '420px',
    className,
    clipStyle = 'center-circle',
    firstLayer,
    secondLayer,
    style = {},
    tag = 'div',
    hasActiveCard,
    ...otherProps
  } = props;

  const Tag = tag as string;

  const isMobile = useIsMobile();

  const [isClosed, setIsClosed] = useState(false);

  const handleClickFirst = () => {
    setIsClosed(false);
  };

  const handleClickSecond = () => {
    setIsClosed(true);
  };

  return (
    <Tag
      className={cn(
        'card-container',
        clipStyle,
        className,
        isMobile && isClosed && 'closed',
        hasActiveCard && 'card-active',
      )}
      ref={ref}
      style={{
        ['--hovered-card-height' as string]: cardHeight,
        ['--hovered-card-width' as string]: cardWidth,
        ...style,
      }}
      {...otherProps}
    >
      <div
        className={cn('card', 'first')}
        onClick={handleClickFirst}
        style={{
          borderRadius,
        }}
      >
        {firstLayer}
      </div>
      <div
        className={cn('card', 'second')}
        onClick={handleClickSecond}
        style={{
          borderRadius,
        }}
      >
        {secondLayer}
      </div>
    </Tag>
  );
});
