import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Heading } from '@beef/legacy-ui-kit';
import { pathOr } from 'ramda';
import classNames from 'classnames/bind';

import styles from '../ServiceConnectPopup/ServiceConnectPopup.pcss';

const cx = classNames.bind(styles);

const ServiceTimeoutPopup = ({ type, timeoutTexts, onClose }) => {
  let description = '';

  if (type === 'connect') {
    description = timeoutTexts.connectTimeoutText;
  } else if (type === 'disconnect') {
    description = timeoutTexts.disconnectTimeoutText;
  }

  return (
    <div className={cx('content')}>
      <Heading level={1}>Заявка отправлена</Heading>
      <div className={cx('description')}>{description}</div>
      <div className={cx('buttons')}>
        <div className={cx('confirm-button')}>
          <Button className="big" onClick={() => onClose()}>
            Продолжить работу
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const timeoutTexts = pathOr({}, ['external', 'service', 'timeoutTexts'], state);
  return {
    timeoutTexts,
  };
};

ServiceTimeoutPopup.propTypes = {
  timeoutTexts: PropTypes.object,
  onClose: PropTypes.func,
  type: PropTypes.string,
};

export default connect(mapStateToProps, null)(ServiceTimeoutPopup);
