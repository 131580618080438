import React from 'react';
import PropTypes from 'prop-types';

// Styles
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TitleDiscount = ({ discount, unit }) => (
  <div className={cx('discount')}>
    <span className={cx('badge')} dangerouslySetInnerHTML={{ __html: `${discount} ${unit}` }} />
  </div>
);

TitleDiscount.propTypes = {
  discount: PropTypes.string,
  unit: PropTypes.string,
};

export default TitleDiscount;
