import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

// Components
import { TabsContainer } from '../TabsContainer';
import { TariffTotalContainer } from '../TariffTotalContainer';
import { TariffSettingsContainer } from '../TariffSettingsContainer';
// Actions
import { setTotalPrice } from '../../../../store/actions/orderTariffDetailsData';
// Selectors
import { getUserAuthenticated } from '../../../../store/selectors/userData';
import { getSettingsTariff } from '../../../../store/selectors/totalSettings';
import {
  getAccessTariff,
  getActiveTab,
  getHasSeamlessUrlParams,
  getSeamlessUrlParams,
} from '../../../../store/selectors/orderTariffDetailsData';
import {
  getIsConvergentUser,
  getUserTypeTariff,
} from '../../../../store/selectors/orderTariffUserData';
// Hooks
import { useFilterTariffs } from './Hooks/useFilterTariffs';
// PropTypes
import { tariffShape } from '../../../../PropTypes';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffDetailsLayout = ({ handleConvergencePopup, ...props }) => {
  const { currentTariffs } = useFilterTariffs({ ...props });
  return (
    <div className={cx('wrapper')}>
      <div className={cx('wrapper__setting')}>
        <TabsContainer />
        <TariffSettingsContainer {...{ currentTariffs, handleConvergencePopup }} />
      </div>
      <TariffTotalContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeTab: getActiveTab(state),
  tariffs: getAccessTariff(state),
  settingsTariff: getSettingsTariff(state),
  isConvergence: getIsConvergentUser(state),
  isAuthenticated: getUserAuthenticated(state),
  isHighSpeedAvailable: getUserTypeTariff(state),
  seamlessUrlParams: getSeamlessUrlParams(state),
  hasSeamlessUrlParams: getHasSeamlessUrlParams(state),
});

TariffDetailsLayout.propTypes = {
  setPrice: PropTypes.func,
  activeTab: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  isHighSpeedAvailable: PropTypes.bool,
  handleConvergencePopup: PropTypes.func,
  tariffs: PropTypes.arrayOf(tariffShape),
};

export const TariffDetailsContainer = connect(mapStateToProps, { setPrice: setTotalPrice })(
  TariffDetailsLayout,
);
