import { templateParser } from 'utils/format-string';

const setBlockAlias = (componentType, alias) => {
  if (process.env.NODE_ENV === 'development' && hasBlockAlias(componentType)) {
    throw new Error('Переназначение alias блока запрещено.');
  }
  componentType.blockAlias = alias;
};

export const getBlockAlias = (componentType) => {
  const { blockAlias } = componentType;
  if (process.env.NODE_ENV === 'development' && !blockAlias) {
    throw new Error('Компонент не помечен как блок, получение alias-а запрещено');
  }
  return blockAlias;
};

export const hasBlockAlias = (componentType) => !!componentType.blockAlias;

/**
 * Присваивает компоненту alias, не создает новую копию компонента
 * @param alias {string}
 */
export const defineBlock = (alias) => (WrappedComponent) => {
  setBlockAlias(WrappedComponent, alias);
  return WrappedComponent;
};

export const addOnClickForInfoCard = (cards, func) =>
  cards.map((card) => {
    if (card?.wrapperLink?.onClick) {
      return {
        ...card,
        wrapperLink: {
          onClick: () => func(card?.type, card?.title, card?.wrapperLink?.href),
        },
      };
    }
    return { ...card };
  });

export const cutTitleBlock = (flag, title) => (flag ? title.split(' ')[0] : title);

const generateFAQTabs = (tabs, vars) => {
  if (!tabs) return null;

  return tabs.map((tab) => {
    const title = templateParser(tab.title, vars);

    const content = tab.content.map((el) =>
      el.map((item) => {
        const text = templateParser(item.text, vars);

        return { ...item, text };
      }),
    );

    return { ...tab, title, content };
  });
};

export const getNewItems = ({ items, region, regionin }) => {
  const varsForTemplate = {
    region,
    regionin,
  };

  return items.map((item) => {
    const tabs = generateFAQTabs(item.tabs, varsForTemplate);

    return { ...item, tabs };
  });
};

export const getTopPosition = (refElement) => {
  if (refElement) {
    const { height } = refElement.getBoundingClientRect();
    return Math.floor(height / 2 - 40);
  }
  return 100;
};
