import React from 'react';
import { compose, withReduxStore } from '@beef/redux';
import { connect } from 'react-redux';
import { withDataConverter, withMountListener } from '@beef/react';
import { LandingConstructor } from '@beef/landing-constructor';

import { resultsLandingDataConverter } from '../store/dataConverters';
import { initializeStoreResultsLanding } from '../store';
import { selectContent } from '../store/selectors';
import { HeadingResults } from '../components/HeadingResults';
import { StoriesList } from '../components/StoriesList';
import { Rating } from '../components/Rating';
import { StoryModal } from '../components/StoryModal';
import { StatusModal } from '../components/StatusModal';
import { fetchStories } from '../store/stories';

const customConstructorComponents = {
  stories: StoriesList,
  rating: Rating,
};

const ResultsLandingLayout = ({ constructorBlocks }) => (
  <>
    <HeadingResults />
    <LandingConstructor blocks={constructorBlocks} customComponents={customConstructorComponents} />
    <StatusModal />
    <StoryModal />
  </>
);

const mapStateToProps = (state) => ({
  constructorBlocks: selectContent(state).blocks,
});

const mapDispatchToProps = {
  onMount: fetchStories,
};

export const ResultsLanding = compose(
  withDataConverter(resultsLandingDataConverter),
  withReduxStore(initializeStoreResultsLanding),
  connect(mapStateToProps, mapDispatchToProps),
  withMountListener,
)(ResultsLandingLayout);
