import { createSelector } from '@reduxjs/toolkit';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { RootState } from 'constructorV1/store';
import { getEnvBaseUrl } from 'constructorV1/router/utils/getEnvBaseUrl';

/** Селектор выборки common ЕК */
export const selectCommon = (state: RootState) => state.common;

/** Селектор выборки глобального контента ЕК */
export const selectContent = createSelector(selectCommon, (common) => common.content);

/** Селектор выборки контента малого чека ЕК */
export const selectBillContent = createSelector(selectContent, (content) => content.bill);

/** Селектор выборки контента общего (результирующего) чека ЕК */
export const selectCBillContent = createSelector(selectContent, (content) => content.commonBill);

/** Селектор выборки контента ошибок MNP формы */
export const selectMnpFormErrors = createSelector(
  selectBillContent,
  (bill) => bill.mnpContent?.mnpValidateErrors,
);

/** Селектор выборки главного заголовка конструктора и его настроек */
export const selectCommonTitle = createSelector(
  selectCommon,
  (common) => common.content.commonTitle,
);

/** Селектор выборки преимуществ тарифа ЕК */
export const selectBenefits = createSelector(selectCommon, (common) => common.benefits);

/** Селектор выборки настроек преимуществ тарифа ЕК */
export const selectBenefitsSettings = createSelector(
  selectContent,
  (content) => content.benefitsSettings,
);

/** Селектор серверного baseUrl c адаптером для локального окружения */
export const selectBaseUrl = createSelector(selectCommon, (common) =>
  getEnvBaseUrl(common.baseUrl),
);

/** Селектор выборки возможного контента запроса метода результирующего чека ЕК */
export const selectCBillResultContent = createSelector(
  selectContent,
  (content) => content.resultPage,
);

/** Селектор выборки контента ошибки запроса метода результирующего чека ЕК */
export const selectCBillErrorTypeContent = createSelector(
  selectCBillResultContent,
  (resultContent) => resultContent.requestErrors,
);

/** Селектор выборки контента успеха запроса метода результирующего чека ЕК */
export const selectCBillSuccessTypeContent = createSelector(
  selectCBillResultContent,
  (resultContent) => resultContent.requestSuccess,
);

/** Селектор выборки заголовка страницы результирующего чека ЕК */
export const selectCBillPageTitle = createSelector(
  selectCBillContent,
  (common) => common.pageTitle,
);

/** Селектор выборки контента для плавающего блока в мобильной версии */
export const selectCFloatingTotal = createSelector(
  selectContent,
  (content) => content.floatingTotal,
);
