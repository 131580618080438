import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ProductTile } from '@beef/legacy-ui-kit';

import { SERVICE_SOCS } from 'pages/Services/ScopeZeroBalanceServices/constants';

import { ServiceInfo } from '../ServiceInfo';
import wideCard from '../../utils/wideCard';

const ServiceTile = ({ service, className, onClick }) => {
  const addYandexToParams = useMemo(
    () => (params) =>
      params?.reduce((acc, curr) => {
        if (service?.soc === 'YANDEX_PD' && curr.label === 'Абонентская плата') {
          return [
            ...acc,
            {
              key: 'SubscriptionFee',
              label: 'Абонентская плата',
              legal: null,
              template: null,
              unit: '₽',
              value: '199',
              subscriptionFeeDiscount: '<br>первые 90 дней бесплатно',
            },
          ];
        }
        return [...acc, curr];
      }, []),
    [service?.parameters],
  );

  return (
    <ProductTile
      arrowLink
      availableForConnect={service.availableForConnect}
      buttonLink={`${service.link}?connect=true`}
      buttonText={service.connectionButton || 'Подробнее'}
      className={className}
      connectedText={service.connectedText || 'Подключено'}
      description={service.description}
      forArchiveTariff={service.forArchiveTariff}
      hideSubscribeButton={service.hideSubscribeButton}
      id={service.id}
      isConnected={service.isConnected}
      link={service.link}
      onClick={onClick}
      parameters={addYandexToParams(service?.parameters)}
      title={service.title}
      {...wideCard(service)}
      ServiceInfoComponent={SERVICE_SOCS.includes(service?.soc) ? ServiceInfo : null}
      serviceImage={service.serviceImage}
    />
  );
};

ServiceTile.propTypes = {
  service: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ServiceTile;
