import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { IFloat } from './types';

const cx = classNames.bind(styles);

export const Float: React.FC<IFloat> = ({ children }) => {
  return <div className={cx('fixed-button')}>{children}</div>;
};
