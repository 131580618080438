import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Popup from 'pages-components/Popup';
import { STEPS_CONFIG, getCurrentStep } from 'utils/ConvertToEsimServiceHelper';
import RequestStatus from 'pages-components/RequestStatus';

import GosAuth from '../steps/GosAuth';
import RequestSuccess from '../steps/RequestSuccess';
import { SimConvertStatementWrapped } from '../steps/SimConvertStatement';

const RequestSteps = ({ onClose, opened, extData, errorData, isWebView }) => {
  const [step, setStep] = useState(getCurrentStep(extData));
  const [email, setEmail] = useState('');

  return (
    <Popup onClose={onClose} opened={opened}>
      <div>
        {step === STEPS_CONFIG.initial && (
          <GosAuth
            onClose={onClose}
            texts={{
              success: extData?.gosAuthRequired,
              error: extData?.defaultError,
              blockedError: extData?.blockedError,
              freeBlockedError: extData?.freeBlockedError,
              refillBlockedError: extData?.refillBlockedError,
              officeBlockedError: extData?.officeBlockedError,
              cpBlockBlockedError: extData?.cpBlockBlockedError,
            }}
          />
        )}
        {step === STEPS_CONFIG.error && (
          <RequestStatus
            data={errorData}
            onClick={() => {
              if (errorData.buttonUrl) {
                window.location.href = errorData.buttonUrl;
              } else {
                onClose();
              }
            }}
          />
        )}
        {step === STEPS_CONFIG.requestForm && (
          <SimConvertStatementWrapped
            data={extData}
            onClose={onClose}
            setEmail={setEmail}
            setStep={setStep}
          />
        )}
        {step === STEPS_CONFIG.success && (
          <RequestSuccess
            eSimStatusPolling={extData.eSimStatusPolling}
            email={email}
            isWebView={isWebView}
            onClose={onClose}
            pollingNetworkErrors={extData.pollingNetworkErrors}
            texts={{
              loading: extData?.requestSuccessLoading,
              success: extData?.requestSuccessContent,
              error: extData?.defaultError,
            }}
          />
        )}
      </div>
    </Popup>
  );
};

RequestSteps.propTypes = {
  opened: PropTypes.bool,

  onClose: PropTypes.func,
  extData: PropTypes.object,
  errorData: PropTypes.object,
  isWebView: PropTypes.bool,
};

RequestSteps.defaultProps = {
  opened: false,

  extData: {},
  onClose: () => {},
};

export default RequestSteps;
