import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { IconPropsType } from './types';
import styles from './styles.pcss';
import { ICON_URLS } from './constants';

const cx = classNames.bind(styles);

export const Icon: FC<IconPropsType> = ({ isSuccess }) => {
  return (
    <img alt="" className={cx('icon')} src={isSuccess ? ICON_URLS.SUCCESS : ICON_URLS.ERROR} />
  );
};
