import React, { FC, useRef } from 'react';
import classNames from 'classnames/bind';
import { shallowEqual } from '@beef/utils';

import { useAppSelector } from '../../store';
import { useOnScreenAnalytics } from '../../tempAnalytics/hooks/useOnScreenAnalytics';
import { ModalHeader } from '../modalComponents/ModalHeader';
import { TitleContent } from '../commonComponents/TitleContent';
import { RouteBackButton } from '../commonComponents/RouteBackButton';
import { CallbackRequestPhoneInput } from './components/CallbackRequestPhoneInput';
import { UserAgreement } from './components/UserAgreement';
import { useDisableNextButton } from './hooks/useDisableNextButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Контейнер компонента обратного звонка ШПД */
export const CallbackRequestContainer: FC = () => {
  const { header, userAgreement, ...rest } =
    useAppSelector(
      (state) => ({
        header: state.common.content.callbackRequestPage?.header,
        title: state.common.content.callbackRequestPage?.title,
        img: state.common.content.callbackRequestPage?.img,
        description: state.common.content.callbackRequestPage?.description,
        userAgreement: state.common.content.callbackRequestPage?.userAgreement,
      }),
      shallowEqual,
    ) ?? {};

  useDisableNextButton();

  /* Устанавливаем presetsRef на контейнер для отправки аналитики при видимости этого блока */
  const presetsRef = useRef(null);

  /* Отправка аналитики при видимости блока */
  useOnScreenAnalytics({
    ref: presetsRef,
    payload: {
      page_url: window.location.href,
      page_path: window.location.pathname,
      page_title: rest.title,
      action: 'view',
      item_type: 'page',
    },
  });

  return (
    <div className={cx('wrapper')} ref={presetsRef}>
      <TitleContent title={header} />
      <div className={cx('container')}>
        <RouteBackButton />
        <ModalHeader {...rest} />
        <CallbackRequestPhoneInput />
        <UserAgreement text={userAgreement || ''} />
      </div>
    </div>
  );
};
