import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { isEmptyValue } from '../../../utils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ADDITIONAL_OFFSET_FOR_CHECKBOX = 30;
const DEFAULT_OFFSET = 50;

const Coverage = ({ opened, children, className, fixedHeight, offsetValue }) => (
  <div
    className={cx('component', { 'fixed-height': fixedHeight, opened }, className)}
    style={{ transform: `translateY(calc(100% - ${offsetValue}))` }}
  >
    {children}
  </div>
);

Coverage.defaultProps = {
  offsetValue: '50px',
};

Coverage.propTypes = {
  opened: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  fixedHeight: PropTypes.bool,
  offsetValue: PropTypes.string,
};

const mapStateToProps = ({ external: { maps = {} } }, ownProps) => {
  const { filtersOptions } = ownProps;

  const opened = ownProps.opened === false ? false : maps.listOpened;

  const offsetIndex =
    isEmptyValue(filtersOptions) ? 0 : (
      filtersOptions.reduce((acc, { filterSettings }) => acc + Number(filterSettings.showFilter), 0)
    );
  let offsetValue;
  if (ownProps.offsetValue) {
    offsetValue = ownProps.offsetValue;
  } else {
    offsetValue = `${offsetIndex * ADDITIONAL_OFFSET_FOR_CHECKBOX + DEFAULT_OFFSET}px`;
  }

  return { opened, offsetValue };
};

export default connect(mapStateToProps)(Coverage);
