import { breakdownPrice } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/utils/breakdownPrice';
import { useIsAuthenticated } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/hooks/tariffStateHooks/useIsAuthenticated';
import { useSelectActiveInacId } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/components/constructorPage/FmcTabsContainer/selectors/useSelectActiveInacId';

import { countHiddenServicesPrice } from '../utils/countHiddenServicesPrice';
import { useSelectFmcServiceContent } from '../../../selectors/useSelectFmcServiceContent';
import { useSelectFmcServicesTariffs } from '../../../selectors/useSelectFmcServicesTariffs';

/** Хук подготовки данных для компонента HiddenServices */
export const useHiddenServices = () => {
  const isAuthenticated = useIsAuthenticated();

  /** Контентные данные по странице настройки сервисов ШПД */
  const settingsFmcPage = useSelectFmcServiceContent();
  const { unit = '', title = '', description = '' } = settingsFmcPage?.hiddenServicesSection ?? {};

  /** Доступные ШПД-пресеты с настройками (fetchFmcServices) */
  const tariffs = useSelectFmcServicesTariffs();

  /** Текущий активный inacId тарифа */
  const currentInacId = useSelectActiveInacId();

  /** Общая цена скрытых сервисов ШПД (number type) */
  const hiddenServicesPrice = countHiddenServicesPrice(tariffs, currentInacId) ?? 0;

  /** Финальная общая цена скрытых сервисов ШПД (string type через ф-цию breakdownPrice для разрядности цены) */
  const price = breakdownPrice(hiddenServicesPrice);

  /** Пропы со стоимостью скрытых опций для компонента Price */
  const priceProps = { unit, price };

  /** Пропы с заголовком и описание для компонента Description */
  const descriptionProps = { title, description };

  return { priceProps, descriptionProps, isShowHiddenServices: isAuthenticated };
};
