/* eslint-disable react/prop-types */
import { ActionBar, Button, CodeInput, Modal, Notification, Preloader, Text } from '@beef/ui-kit';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';

import { formatNumber, formatTimer } from 'utils/format-string';

import {
  ErrorBalanceNotEnough,
  ErrorIncorrectSmsConfirmationCode,
  getSmsCode,
  resetError,
  sendSms,
  setAwaitingSmsCode,
  setCurrentNumber,
  setSuccessStatus,
} from '../../store/actions/change';
import styles from './styles.pcss';
import {
  getCurrentNumber,
  getError,
  getIsAwaitingSmsCode,
  getIsLoadingSms,
  getIsSuccess,
} from '../../store/selectors/change';
import { getPopup } from '../../store/selectors/content';
import { useUserNumber } from '../../hooks/useUserNumber';

const cx = classnames.bind(styles);

export const ChangeNumberModalLayout = ({
  number,
  sms,
  popup,
  success,
  cancelChanging,
  resetCodeError,
  isSuccess,
  requestSms,
  isAwaitingSmsCode,
  sendCode,
  isLoadingSms,
  error,
  isWrongCode,
  isNeedTopupBalance,
  codeLength,
}) => {
  const { value, price } = number;

  const [errorMessage, setErrorMessage] = useState('');
  const [code, setCode] = useState('');
  const [timer, setTimer] = useState(60);

  const userNumber = useUserNumber();

  useEffect(() => {
    if (!isAwaitingSmsCode) {
      return;
    }
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [isAwaitingSmsCode]);

  useEffect(() => {
    if (isWrongCode) {
      setErrorMessage(error.title);
    }
  }, [isWrongCode, error]);

  const handleChange = (codeValue) => {
    setCode(codeValue);
    if (codeValue.length > 0) {
      resetCodeError();
      setErrorMessage('');
    }
    if (codeValue.length === codeLength) {
      setCode('');
      sendCode(codeValue);
    }
  };

  const sendAgain = () => {
    setCode('');
    setErrorMessage('');
    setTimer(120);
  };

  if (error && !error.isCodeError) {
    return (
      <Modal
        actionBar={
          <ActionBar size="l">
            <Button
              onClick={() => {
                window.location.href = error.link;
              }}
              size="l"
            >
              {error.button}
            </Button>
            {isNeedTopupBalance && (
              <Button onClick={cancelChanging} size="l" variant="tertiary">
                {error.cancel}
              </Button>
            )}
          </ActionBar>
        }
        isOpen
        onClose={cancelChanging}
      >
        <div className={cx('wrapError')}>
          <img alt={error.title} className={cx('catImage')} src={error.img} />
          <Text className={cx('title')} size="size2-m">
            {error.title}
          </Text>
          <Text color="grey60" size="size4-r">
            {error.description.replace('%number%', formatNumber(value))}
          </Text>
        </div>
      </Modal>
    );
  }

  if (isSuccess) {
    return (
      <Modal
        actionBar={
          <ActionBar size="l">
            <Button onClick={cancelChanging} size="l">
              {success.button}
            </Button>
          </ActionBar>
        }
        isOpen
        onClose={cancelChanging}
      >
        <div className={cx('wrapSuccessful')}>
          <img alt={success.title} className={cx('catImage')} src={success.img} />
          <Text className={cx('title')} size="size2-m">
            {success.title}
          </Text>
          <Text color="grey60" size="size4-r">
            {success.description.replace('%number%', formatNumber(value))}
          </Text>
        </div>
      </Modal>
    );
  }

  if (isLoadingSms) {
    return (
      <Modal isOpen onClose={cancelChanging}>
        <div className={cx('wrapLoader')}>
          <Preloader color="contentPlaceholder" size="l" />
        </div>
      </Modal>
    );
  }

  const timerBlock =
    timer <= 0 ?
      <Text className={cx('sendAgain')} color="grey95" size="size5-r-s">
        <span onClick={sendAgain}>{sms.timertextrepeat}</span>
      </Text>
    : <Text className={cx('timer')} color="grey60" size="size5-r-s">
        {sms.timertext} {formatTimer(timer)}
      </Text>;

  if (isAwaitingSmsCode) {
    return (
      <Modal isOpen onClose={cancelChanging}>
        <div>
          <Text className={cx('title')} size="size2-m">
            {sms.title}
          </Text>
          <div>
            <Text className={cx('sent')} color="grey60" size="size5-r-s">
              {sms.text}{' '}
              <Text className={cx('phoneNumber')} color="grey60" size="size5-r-s">
                {userNumber && formatNumber(userNumber)}
              </Text>
            </Text>
            <CodeInput
              errorMessage={errorMessage}
              inputValue={code}
              length={codeLength}
              onChange={handleChange}
            />
            {timerBlock}
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      actionBar={
        <ActionBar size="l">
          <Button onClick={requestSms} size="l">
            {popup.link}
          </Button>
        </ActionBar>
      }
      isNoFixHeight
      isOpen
      onClose={cancelChanging}
    >
      <div>
        <Text className={cx('title')} size="size2-m">
          {popup.title}
        </Text>
        <Notification text={popup.description} variant="secondary" />
        <div className={cx('wrap')}>
          <div className={cx('number')}>
            <Text className={cx('picked')} color="grey60" size="size5-r-s">
              {popup.newnumber}
            </Text>
            <Text className={cx('phone')} size="size3-b">
              {formatNumber(value)}
            </Text>
          </div>
          <div className={cx('priceWrap')}>
            <Text className={cx('finalPrice')} size="size4-m">
              {price} ₽
            </Text>
          </div>
        </div>
        <Text className={cx('messageSend')} color="grey60" size="size6-r">
          {popup.currentnumber}{' '}
          <Text className={cx('phoneNumber')} color="grey60" size="size6-r">
            {userNumber && formatNumber(userNumber)}
          </Text>
        </Text>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  popup: getPopup(state).change,
  sms: getPopup(state).sms,
  success: getPopup(state).success,
  error: getPopup(state).errors[(getError(state) || '').toLowerCase()],
  codeLength: getPopup(state).codeLength,
  isWrongCode:
    (getError(state) || '').toLowerCase() === ErrorIncorrectSmsConfirmationCode.toLowerCase(),
  isNeedTopupBalance: (getError(state) || '').toLowerCase() === ErrorBalanceNotEnough.toLowerCase(),
  number: getCurrentNumber(state),
  isAwaitingSmsCode: getIsAwaitingSmsCode(state),
  isSuccess: getIsSuccess(state),
  isLoadingSms: getIsLoadingSms(state),
});

const mapDispatchToProps = (dispatch) => ({
  cancelChanging: () => {
    dispatch(setCurrentNumber(null));
    dispatch(setSuccessStatus(false));
    dispatch(setAwaitingSmsCode(false));
  },
  requestSms: () => {
    dispatch(getSmsCode());
  },
  sendCode: (code) => {
    dispatch(sendSms(code));
  },
  resetCodeError: () => {
    dispatch(resetError());
  },
});

export const ChangeNumberModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeNumberModalLayout);
