import React, { memo } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const OptionInfo = memo(
  ({ unit, price, title, period, isShutdown, hasConnectedOptions }) => (
    <div className={cx('wrapper')}>
      <div className={cx('wrapper__info')}>
        {title && (
          <Text color="grey95" size="size5-r-s">
            {`${title} ${!isShutdown ? period : ''}`}
          </Text>
        )}
      </div>
      {price && !hasConnectedOptions && !isShutdown && (
        <div className={cx('wrapper__price')}>
          <Text color="grey95" size="size5-r-s">
            {`${price} ${unit}`}
          </Text>
        </div>
      )}
    </div>
  ),
);

OptionInfo.propTypes = {
  unit: PropTypes.string,
  price: PropTypes.number,
  title: PropTypes.string,
  period: PropTypes.string,
  isShutdown: PropTypes.bool,
  hasConnectedOptions: PropTypes.bool,
};
