/** Тип компонента Separation по-умолчанию */
export const LEFT_TYPE_SIDE = 'left';

/** Цвет фона тега по-умолчанию */
export const DEFAULT_TAG_COLOR = 'violet50';

/** Цвет тега по-умолчанию */
export const DEFAULT_TEXT_COLOR = 'black';

/** Значение ключа главного попапа тарифа (по-умолчанию) */
export const MAIN_POPUP_KEY = 'main';

/** Значение ключа попапа способности персонажа */
export const POWER_POPUP_KEY = 'power';

/** Тип базового сока персонажа тарифа */
export const OPTION_SOC = 'optionSoc';

/** Тип высшего сока персонажа тарифа */
export const HIGH_OPTION_SOC = 'highOptionSoc';

/** Значение ключа контентной части попапа способности персонажа для пресета низшего порядка */
export const DETAILS_INFO = 'detailsInfo';

/** Значение ключа контентной части попапа способности персонажа для пресета высшего порядка */
export const HIGH_DETAILS_INFO = 'highDetailsInfo';

/** Дефолтный цвет для фона персонажа */
export const DEFAULT_BG_COLOR = '#fff';

/** Дефолтное значение индекса для табов, попапов и т.п. */
export const DEFAULT_INDEX = 0;

/** Дефолтный индекс шага в степпере.
 * Используется, например, в prevStep для перехода к предыдущему шагу настройки конструктора */
export const DEFAULT_INDEX_STEP_SKIP = 0;

/** Вариант мобильного внутреннего отступа для обертки PageFixer */
export const MOBILE_PADDING = 40;

/** Вариант десктопного внутреннего отступа для обертки PageFixer */
export const DESKTOP_PADDING = 80;

/** Минимальное количество дополнительных плиток тарифа.
 * Влияет на позиционирование изображения */
export const MIN_COUNT_EXTRA_TILES = 2;

/** Для Бази требуется иной отступ, т.к. она единственный аппер,
 * который не должен прижиматься к нижней части плитки */
export const IS_BEE = 'Базя';

/** query-параметр при наличии которого автоматически откроется попап выбора персонажа
 * при переходе из общей карточки тарифа UP */
export const SETTINGS_TARIFF = 'settingsTariff';

/** Default цвет текста (используется для окраски descriptions) */
export const DEFAULT_COLOR = '#7A7A89';

/** Дефолтный контент для общего компонента ErrorBoundary */
export const defaultErrorContent = {
  image: 'https://static.beeline.ru/upload/images/icons/error.svg',
  title: 'Что-то пошло не по плану',
  description: 'Иногда такое случается — обновите страницу',
  btnText: 'Обновить',
};

/** Базовый параметр при отправке запроса на калбек при покупке шпд */
export const CALLBACK_CASE_TILE_SALE = 'sale';

/** Базовый параметр при отправке запроса на калбек при апгрейде шпд */
export const CALLBACK_CASE_TILE_SUPPORT = 'support';

/** LayoutType нового тарифа "Up".
 * От него зависит, покажем мы компонент DevicesContainer (тариф Интернет Live) в пресетах,
 * или же PassiveContainer (тариф Up) */
export const TARIFF_UP_TYPE = 'tariffConstructor';

/** LayoutType ОБЩЕГО тарифа би */
export const BASE_CONSTRUCTOR_TYPE = 'baseConstructor';

/** Настройки для слайдера слотов монтажника даты и время */
export const SLIDER_SETTINGS_DATE_TIME = {
  navigation: false,
  simulateTouch: false,
  spaceBetween: 0,
  breakpoints: {
    1280: {
      spaceBetween: 24,
    },
    1024: {
      spaceBetween: 24,
    },
    767: {
      spaceBetween: 24,
    },
    320: {
      spaceBetween: 12,
    },
  },
};

export const DEFAULT_STEP_COUNT = 1;

/** Типы финальной страницы успеха (ThanksFTTBPage) */
export const enum ESuccessPageType {
  /** Пользователь покупает НОВУЮ SIM С ШПД (НЗ/АЗ) через заявку (без корзины) */
  buyNewTariff = 'buyNewTariff',
  /** Пользователь ДОКУПАЕТ к подключенному тарифу Up - ШПД (АЗ) */
  addFttbToTariff = 'addFttbToTariff',
  /** Пользователь изменяет ПОДКЛЮЧЕННЫЙ тариф Up */
  changeYourTariff = 'changeYourTariff',
  /** Пользователь переходит на тариф Up С ПОДКЛЮЧЕНИЕМ ШПД */
  goToTariffWithFttb = 'goToTariffWithFttb',
  /** Пользователь переходит на тариф Up БЕЗ ПОДКЛЮЧЕНИЯ ШПД */
  goToTariffWithoutFttb = 'goToTariffWithoutFttb',
  /** Пользователь покупает тариф с новой sim ЧЕРЕЗ КОРЗИНУ.
   * Объекта с таким ключом нет в контенте, но пользователь НЕ попадет на финальную страницу, т.к. отправится в корзину */
  buyNewTariffWithCart = 'buyNewTariffWithCart',
  /** Пользователь отправил заявку на обратный звонок */
  callBackRequest = 'callBackRequest',
}
