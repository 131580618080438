import { createReducer } from '@reduxjs/toolkit';

import { setAlias } from '../actions/content';

export const content = createReducer(
  { alias: '' },
  {
    [setAlias.type]: (state, action) => {
      state.alias = action.payload;
    },
  },
);
