import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Checkbox, Text } from '@beef/ui-kit';

import { EquipmentBlocks } from './EquipmentBlocks';
import { TEquipmentCheckbox } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент чекбокс. При включенном состоянии отображает блоки выбора оборудования */
export const EquipmentCheckbox: FC<TEquipmentCheckbox> = ({
  checkboxProps: { checked, label, ...restCheckboxProps },
  equipmentProps,
}) => (
  <article className={cx('wrapper')}>
    {/* Чекбокс с выбором нужно ли оборудование */}
    <div className={cx('checkbox', { checked })}>
      <Checkbox {...{ ...restCheckboxProps, checked }} />
      <Text size="size4-m">{label}</Text>
    </div>
    {/* Блоки выбора оборудования */}
    {checked && <EquipmentBlocks equipmentProps={equipmentProps} />}
  </article>
);
