import React from 'react';

import { TBannersContainer } from './types';
import { FamilyContainer } from './components';

export const BannersContainer = ({ familyBanner }: TBannersContainer) => {
  return (
    // сразу заготовка если понадобится еще 1 баннер как в новом конструкторе тарифа
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{familyBanner && <FamilyContainer {...familyBanner} />}</>
  );
};
