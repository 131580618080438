import React, { FC } from 'react';
import { InfoIcon, Tooltip } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { TOptionPickerTile } from '../../types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Иконка опциональной доп. информации об опции с тултипом */
export const TOptionPickerTooltip: FC<Pick<TOptionPickerTile, 'tooltipText'>> = ({
  tooltipText,
}) =>
  tooltipText ?
    <div className={cx('tooltip-container')}>
      <Tooltip message={tooltipText} position="top">
        <InfoIcon />
      </Tooltip>
    </div>
  : null;
