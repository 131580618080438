import React, { FC } from 'react';
import { Button } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TPreChangesButton = {
  btnText?: string;
  onClick?: () => void;
};

/** Блок с кнопкой для перехода к следующему шагу */
export const PreChangesButton: FC<TPreChangesButton> = ({ btnText, onClick }) =>
  btnText ?
    <div className={cx('wrapper')}>
      <Button fullWidth onClick={onClick}>
        {btnText}
      </Button>
    </div>
  : null;
