import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const OldFee = ({ value, unit, className, discountText, discountClassName }) => (
  <div className={cx('oldFeeWrap', className)}>
    <div className={cx('oldFee')}>
      <span dangerouslySetInnerHTML={{ __html: value }} />
      <span className={cx('unit')} dangerouslySetInnerHTML={{ __html: unit }} />
    </div>
    {discountText && (
      <div className={cx('discountWrap', discountClassName)}>
        <span className={cx('discountText')} dangerouslySetInnerHTML={{ __html: discountText }} />
      </div>
    )}
  </div>
);

OldFee.propTypes = {
  value: PropTypes.string,
  unit: PropTypes.string,
  className: PropTypes.string,
  discountText: PropTypes.string,
  discountClassName: PropTypes.string,
};

export default OldFee;
