import React, { memo } from 'react';

// TODO: заменить импорт после выноса PresetPicker в пакет!
import { PresetPicker } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/PresetPicker';

import { useAppDispatch } from '../../../../../store';
import { useGbValueSelector } from './selectors/useGbSocSelector';
import { setGbytes } from '../../../../../store/slices/totalSlice';
import { useGbPresetsSelector } from './selectors/useGbPresetsSelector';
import { WPContainer } from '../../../../commonComponents/WPContainer';
import { sendPresetYm } from '../../tempYmUtils';

/** Гб/Мин для тарифа Up */
export const GbPresetContainer = memo(() => {
  const { gbPresets } = useGbPresetsSelector();
  const { gbValue } = useGbValueSelector();
  const dispatch = useAppDispatch();
  const onChange = (value: number) => {
    /* Отправка метрики */
    sendPresetYm(value, 'gb');
    dispatch(setGbytes(value));
  };

  return (
    <WPContainer>
      <PresetPicker activeKey={gbValue} {...{ options: gbPresets, onChange }} />
    </WPContainer>
  );
});
