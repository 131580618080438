import {
  AvailableFiltersType,
  SelectedParametersType,
} from 'pages/SearchResultsPage/components/Filter/FilterWrapper/types';
import { fetchSearch } from 'pages/SearchResultsPage/services/fetchSearch';
import { validateSelectedFilters } from 'pages/SearchResultsPage/utils/helperFilters';
import { prepareSearchUrlParams } from 'pages/SearchResultsPage/utils/prepareFilters';

const fetchSearchWithParams = async (
  searchUrl: URLSearchParams,
  selectedParameters: SelectedParametersType = {},
) => {
  const searchURL = prepareSearchUrlParams(searchUrl, selectedParameters);
  return fetchSearch(searchURL);
};

export const fetchFilters = async (
  searchUrl: URLSearchParams,
  selectedParameters: SelectedParametersType = {},
  availableParameters: AvailableFiltersType = {},
) => {
  try {
    const search = await fetchSearchWithParams(searchUrl, selectedParameters);

    if (!search?.filters?.length) {
      const actuallyParameters = validateSelectedFilters(
        selectedParameters,
        availableParameters?.filters,
      );

      if (!Object.keys(actuallyParameters?.filters || {}).length) {
        return {
          parameters: selectedParameters,
        };
      }

      const reSearch = await fetchSearchWithParams(searchUrl, actuallyParameters);

      return {
        search: reSearch,
        parameters: actuallyParameters,
        isCleanFilters: true,
      };
    }

    return {
      search,
      parameters: selectedParameters,
    };
  } catch (e) {
    console.error(e);
    return {
      parameters: selectedParameters,
    };
  }
};
