import { ymPushParams } from 'utils/ym';

import { pushYm } from '../../../tempAnalytics/metricUtils';

/* Ф-я выбора, отправляет урл если ссылка или 'popup' если модальное окно */
const getUrl = (link?: string) => (!link || link === '#' ? 'popup' : link);

/** Блок баннера отправляет дважды параметры для метрики и на просмотр и на клик, поэтому 2 разные похожые функции /*
/** Ф-я для сбора параметров просмотра блока номер 1 */
export const getYmPushPayload = (link?: string, title?: string, isClick?: boolean) => {
  const url = getUrl(link);
  return {
    ad_banner: isClick ? 'click' : 'view',
    banner_title: title,
    banner_url: url,
  };
};

/** Ф-я для сбора параметров просмотра блока номер 2 */
export const getAdditionalYmPushPayload = (link?: string, title = '', isClick?: boolean) => {
  const url = getUrl(link);
  return {
    banners_ev_: {
      [isClick ? 'click' : 'view']: {
        tariff_pdp_zverinyi: {
          position: {
            ad_banner: {
              [title]: url,
            },
          },
        },
      },
    },
  };
};

/* Общие параметры для попапоа */
const popupParams = {
  popup_type: 'adv',
};

/** Ф-я отправки метрики на клик по банеру */
export const handleClick = (link?: string, title = '', isClick = true) => {
  /* 3 отправки метрики с разным наполнением на 1 клик согласно тз */
  pushYm(getYmPushPayload(link, title, isClick));
  ymPushParams(getAdditionalYmPushPayload(link, title, isClick));
  pushYm({ ...popupParams, popup_name: title, popup_action: 'view' });
};

/** Ф-я отправки метрики на клик по основной кнопке попапа банера */
export const popupYmClick = (title = '', btnText = '') => {
  pushYm({ ...popupParams, popup_name: title, popup_action: btnText });
};

/** Ф-я отправки метрики на клик по extra кнопке попапа банера */
export const popupYmClickExtra = (title = '', btnText = '') => {
  pushYm({ ...popupParams, popup_name: title, popup_action: btnText });
};
