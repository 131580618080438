import { useEffect } from 'react';

import { useIsDesktopDimension } from 'utils/hooks/useGetDimensionGroup';
import {
  setErrorScreenStatus,
  setIsError,
} from 'pages/Services/VoWifiServiceCardLanding/store/slices';

import { voWifiViewScreenYM } from '../analytics';
import { ERRORS_SCREEN_STATUSES, SUPPORT_STATUSES } from '../store/constants';
import { IRoot, MainDataTypes, SupportStatusType } from '../types';

export const useInitVoWifiServiceCard = ({
  content,
  supportStatus,
  errorScreensState,
  errorScreenStatus,
  isError,
  vowifiFAQ,
  vowifiInstructions,
  isConnected,
  dispatch,
}: Partial<IRoot>) => {
  useEffect(() => {
    // ym for VoWifi
    if (supportStatus) {
      voWifiViewScreenYM(supportStatus);
    }
  }, [supportStatus]);

  const isDesktop = useIsDesktopDimension();
  const statusesOfSupport = [
    SUPPORT_STATUSES.NOT_SUPPORTED_WITH_RESTRICTION,
    SUPPORT_STATUSES.NOT_SUPPORTED,
    SUPPORT_STATUSES.SUPPORTED,
  ];

  const hasSupportStatus = statusesOfSupport.includes(supportStatus);

  const { notification, teaserBanner, appBanner, ...rest } = content;

  let mainData: Partial<MainDataTypes> = {
    faq: vowifiFAQ?.content,
    extendedSteps: vowifiInstructions?.content,
    appBanner,
    ...rest,
  };
  const errorData = errorScreensState?.[errorScreenStatus];

  if (isConnected && supportStatus === SUPPORT_STATUSES.SUPPORTED) {
    const teaserBannerUpd = {
      ...teaserBanner,
      label: teaserBanner.label.connected,
      button: teaserBanner.button.settings,
    };

    const notificationUpd = notification?.[supportStatus?.toLowerCase() as SupportStatusType];

    mainData = {
      ...mainData,
      teaserBanner: { ...teaserBannerUpd },
      notification: { ...notificationUpd },
    };
  } else if (supportStatus === SUPPORT_STATUSES.NOT_SUPPORTED) {
    const teaserBannerUpd = {
      ...teaserBanner,
      label: teaserBanner.label.unavailable,
      button: teaserBanner.button.shop,
    };

    const notificationUpd = notification?.[supportStatus?.toLowerCase() as SupportStatusType];

    mainData = {
      ...mainData,
      teaserBanner: { ...teaserBannerUpd },
      notification: { ...notificationUpd },
    };
  } else if (supportStatus === SUPPORT_STATUSES.NOT_SUPPORTED_WITH_RESTRICTION) {
    const teaserBannerUpd = {
      ...teaserBanner,
      label: teaserBanner.label.unavailable,
      button: {},
    };

    const notificationUpd = notification?.[supportStatus?.toLowerCase() as SupportStatusType];

    mainData = {
      ...mainData,
      teaserBanner: { ...teaserBannerUpd },
      notification: { ...notificationUpd },
    };
  } else if (!hasSupportStatus) {
    dispatch(setIsError(true));
    dispatch(setErrorScreenStatus(ERRORS_SCREEN_STATUSES.DEFAULT_ERROR));
  } else if (!isConnected) {
    const teaserBannerUpd = {
      ...teaserBanner,
      label: teaserBanner.label.unavailable,
      button: {},
    };

    mainData = {
      ...mainData,
      teaserBanner: { ...teaserBannerUpd },
      notification: {},
    };
  }

  // для appBanner
  if (!isDesktop) {
    mainData.appBanner = {
      ...mainData?.appBanner,
      text: mainData?.appBanner?.mobileText,
    };
  }

  if (isError) {
    mainData = {};
  }

  return {
    mainData,
    errorData,
  };
};
