import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { FREE_SERVICE } from 'constructorV1/constants';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TPriceContainer = {
  rcRate?: number | string;
  rcRatePeriod?: string;
  rcRateWithDiscount?: number;
};

/** Компонент старой/новой цены для ЕК */
export const PriceContainer: FC<TPriceContainer> = ({
  rcRate,
  rcRatePeriod,
  rcRateWithDiscount,
}) => (
  <div className={cx('wrapper')}>
    {!!rcRateWithDiscount && rcRate !== rcRateWithDiscount && (
      <Text
        align="left"
        className={cx('wrapper__discount')}
        color="tertiary-invert"
        lineHeight={20}
        size="xs"
        weight="regular"
      >
        {rcRateWithDiscount}
      </Text>
    )}
    <Text align="left" lineHeight={20} size="xs" weight="regular">
      {rcRate ? `${rcRate} ${rcRatePeriod}` : FREE_SERVICE}
    </Text>
  </div>
);
