import axios from 'axios';

// Ations
import { toggleConnectPopup } from '../tariffConnect/tariffConnectExtension';
import { serviceConnectionPopupResponseData } from '../serviceConnect';
import { recordPayload } from '../actionsApi';

// Actions Types
import { SET_SRV_CONNECTION_RESULT_POPUP, TOGGLE_SERVICE_CONNECTION_POPUP } from '../actionTypes';

export const toggleDownsellServicePopup =
  (url, popupData, popupIsActive = false) =>
  (dispatch) => {
    dispatch(toggleConnectPopup(false));

    dispatch({
      type: TOGGLE_SERVICE_CONNECTION_POPUP,
      payload: { popupIsActive },
    });

    if (popupIsActive) {
      axios
        .post(url)
        .then((response) => serviceConnectionPopupResponseData(response.data, dispatch))
        .catch(() => {
          dispatch(
            recordPayload(SET_SRV_CONNECTION_RESULT_POPUP, {
              ...popupData.offer.errorPopup,
            }),
          );
        });
    }
  };
