import React, { Component, createRef } from 'react';
import { Accordion, Heading, Link, Tab, TabContent, Tabs, TabsList } from '@beef/legacy-ui-kit';
import { BreadCrumbs } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from 'pages-components/Icon';
import PopupStateless from 'pages-components/Popup';
import TaxCalculator from 'pages-components/TaxCalculator';
import { setFTTBCatalogData } from 'pages/FTTB/actions';
import OtherCatalogBanner from 'pages/FTTB/CatalogPage/components/OtherCatalogBanner';
import TariffsCatalog from 'pages/FTTB/CatalogPage/components/TariffsCatalog';
import withStoreProvider from 'pages/FTTB/hoc/withStoreProvider';
import { mapBreadCrumbs } from 'pages/Help/components/StaticPage/dataMapper';
import store from 'store';
import { ANALYTICS_LISTENERS } from 'utils/analytics/helpStaticPage';
import FeedbackForm from 'widgets/FeedbackForm';

import CheckNumberToOperator from '../CheckNumberToOperator';
import FancyNumberLinks from '../FancyNumberLinks';
import styles from './StaticPage.pcss';

const cx = classNames.bind(styles);

class StaticPage extends Component {
  constructor(props) {
    super(props);

    if (props?.data?.catalogData) {
      store.dispatch(setFTTBCatalogData(props?.data?.catalogData));
    }

    this.pageRef = createRef();

    this.state = {
      breadCrumbs: mapBreadCrumbs(props?.data?.breadScrumbs),
      headingExpanded: false,
      showFeedbackPopup: false,
    };
  }

  componentDidMount() {
    if (window && window.location && window.location.href.indexOf('#faq') > -1) {
      setTimeout(this.scrollPageToEnchor, 500);
    }
    // ym listeners
    this.handleYmEventListeners('add');
  }

  componentWillUnmount() {
    // ym listeners
    this.handleYmEventListeners('remove');
  }

  handleYmEventListeners = (action) => {
    Object.keys(ANALYTICS_LISTENERS).forEach((key) => {
      const node = this.pageRef.current.querySelectorAll(key);
      if (!node) return;
      Object.keys(ANALYTICS_LISTENERS[key]).forEach((event) => {
        node.forEach((element) =>
          action === 'add' ?
            element.addEventListener(event, ANALYTICS_LISTENERS[key][event])
          : element.removeEventListener(event, ANALYTICS_LISTENERS[key][event]),
        );
      });
    });
  };

  handleClick = (event) => {
    if (event.target.dataset.popup === 'feedback') {
      event.preventDefault();
      this.setState({ showFeedbackPopup: true });
    }
  };

  scrollPageToEnchor() {
    const id = window.location.hash.replace('#', '');
    const elem = document.getElementById(id);

    elem.scrollIntoView(
      {
        behavior: 'smooth',
        block: 'start',
      },
      100,
    );
  }

  renderNavigation(navigation, asMobileDropdown) {
    if (!navigation) return null;
    const { parent } = this.props.data;
    return (
      <div className={cx('navigation', { asMobileDropdown, mobile: asMobileDropdown })}>
        {parent && (
          <div className={cx('navigationUp', 'navigationLine', 'desktop')}>
            <Icon className={cx('arrowTop')} name="arrowTop" />
            <Link href={parent.url}>{parent.title}</Link>
          </div>
        )}
        {!asMobileDropdown && !!navigation.length && (
          <div className={cx('description', 'navigationLine')}>Другие статьи этого раздела:</div>
        )}
        {navigation?.map((entry, index) => (
          <div
            className={cx('navigationItem', 'navigationLine', {
              active: entry.active,
            })}
            key={index}
          >
            {entry.active ?
              <span className={cx('text')}>{entry.title}</span>
            : <Link href={entry.url}>{entry.title}</Link>}
          </div>
        ))}
      </div>
    );
  }

  renderDocuments(data) {
    if (!data) return null;

    return (
      <div className={cx('documents')}>
        {data.map((entry, index) => (
          <div className={cx('document')} key={index}>
            <div className={cx('documentIcon')}>
              <Icon name="documents" />
              <div className={cx('extension')}>{entry.format}</div>
            </div>
            <div className={cx('documentLink')}>
              <Link href={entry.href}>{entry.title}</Link>
              <div>
                {entry.format}, {entry.size}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderTabsContent(data, price) {
    return data.map((entry, index) => (
      <TabContent id={`tab-${index}`} key={index}>
        {this.renderHTML(entry.html, price)}
      </TabContent>
    ));
  }

  renderTabsList(data) {
    return (
      <TabsList className={cx('tabsList')}>
        {data.map((entry, index) => (
          <Tab className={cx('staticTab')} id={`tab-${index}`} key={index}>
            <div className={cx('menuTab')}>
              <img alt="" className={cx('icon')} src={entry.imgUrl} />
              {entry.title}
            </div>
          </Tab>
        ))}
      </TabsList>
    );
  }

  renderTabs(data, price) {
    if (!data) return null;

    return (
      <Tabs>
        {this.renderTabsList(data)}
        {this.renderTabsContent(data, price)}
      </Tabs>
    );
  }

  renderBottomMenu(children) {
    if (!children || !children.length) return null;
    return (
      <div className={cx('bottomMenu', 'desktop')}>
        {children.map((entry, index) => (
          <div className={cx('bottomMenuLink')} key={index}>
            <Link className="hideVisitedColor" href={entry.url}>
              {entry.title}
            </Link>
          </div>
        ))}
      </div>
    );
  }

  renderHTML(html) {
    if (!html) return null;

    if (html === '{{checkNumberToOperator}}') {
      return <CheckNumberToOperator />;
    }
    if (html === '{{fancyNumberNavigation}}') {
      return <FancyNumberLinks />;
    }

    const calculator = /{{calculator:(.*)}}/.exec(html);

    if (calculator) {
      const tax = calculator[1];
      const parts = html.split(`{{calculator:${tax}}}`);

      return (
        <div className={cx('markupContainer')}>
          <div dangerouslySetInnerHTML={{ __html: parts[0] }} />
          <TaxCalculator tax={tax} />
          <div dangerouslySetInnerHTML={{ __html: parts[1] }} />
        </div>
      );
    }

    return <div className={cx('markupContainer')} dangerouslySetInnerHTML={{ __html: html }} />;
  }

  renderBottomMenuMobile(children) {
    if (!children || !children.length) return null;

    return (
      <div className={cx('bottomMenu', 'mobile')}>
        <Accordion mobile>
          {[{ title: 'Подробнее' }].map((entry, index) => (
            <div key={index} title={entry.title}>
              {children.map((subEntry, subEntryIndex) => (
                <div className={cx('bottomMenuLink')} key={subEntryIndex}>
                  <Link href={subEntry.url}>{subEntry.title}</Link>
                </div>
              ))}
            </div>
          ))}
        </Accordion>
      </div>
    );
  }

  render() {
    const {
      banner,
      catalogData,
      children,
      documents,
      ext: { extDescription },
      html,
      navigation,
      tabs,
      title,
    } = this.props.data;
    const { breadCrumbs, headingExpanded, showFeedbackPopup } = this.state;
    return (
      <div
        className={cx('staticPage')}
        onClick={this.handleClick}
        ref={this.pageRef}
        role="button"
        tabIndex="0"
      >
        {!!breadCrumbs.length && <BreadCrumbs path={breadCrumbs} />}
        <Heading className={cx('headingWrapper')} level={1}>
          {title}
        </Heading>
        {extDescription && (
          <article className={cx('extDescriptionWrapper')}>
            <section
              className={cx('extDescription')}
              dangerouslySetInnerHTML={{ __html: extDescription }}
            />
          </article>
        )}
        {headingExpanded && this.renderNavigation(navigation, true)}
        <div className={cx('pageWrapper')}>
          <div className={cx('contentWrapper')}>
            {html === '' ? null : this.renderHTML(html)}
            {this.renderTabs(tabs)}
            {this.renderBottomMenu(children)}
          </div>
          {this.renderBottomMenuMobile(children)}
          {this.renderDocuments(documents)}
          {this.renderNavigation(navigation)}
        </div>
        {!!catalogData?.tariffs?.length && (
          <TariffsCatalog
            data={catalogData?.texts?.tariffsCatalogData}
            discountText={catalogData?.catalogDiscountText}
            forceShowBackCallPopup
          />
        )}
        {banner && <OtherCatalogBanner availableCatalogs={['convergent']} data={banner} />}
        <PopupStateless
          onClose={() => this.setState({ showFeedbackPopup: false })}
          opened={showFeedbackPopup}
        >
          <FeedbackForm />
        </PopupStateless>
      </div>
    );
  }
}

StaticPage.propTypes = {
  data: PropTypes.shape({
    banner: PropTypes.object,
    catalogData: PropTypes.shape({
      tariffs: PropTypes.array,
    }),
    children: PropTypes.array,
    ext: PropTypes.shape({
      extDescription: PropTypes.string,
    }),
    html: PropTypes.string,
    navigation: PropTypes.array,
    parent: PropTypes.object,
    tabs: PropTypes.object,
    title: PropTypes.string,
  }),
};

export default withStoreProvider(StaticPage);
