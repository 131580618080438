import React from 'react';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const ScrollToEdgesWrap = ({ children, className }) => (
  <div className={cx('wrap', className)}>{children}</div>
);
