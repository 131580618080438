import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CollapsedContent,
  Emoji,
  Heading,
  Link,
  SmartForm,
  SmartInput,
  Status,
  Tab,
  TabContent,
  Tabs,
  TabsList,
} from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import SimOrderBenefits from 'pages-components/SimOrderBenefits/SimOrderBenefits';
import { unformatPhone, validateFancyNumber } from 'utils/format-string';
import { numberToHtml } from 'utils/fancyNumberBeautifier';

import Captcha from '../../pages/Feedback/components/Captcha/Captcha';
import FancyNumberWithTariff from './FancyNumberWithTariff';
import FancyNumberWithoutTariff from './FancyNumberWithoutTariff';
import AddressForm from './AddressForm';
import styles from './FancyNumberOrderPopup.pcss';

const cx = classNames.bind(styles);

const captchaValidator = (value) => ({
  status: value.length === 5 ? 'ok' : 'fail',
  msg: 'Неверные символы',
});

class OrderForm extends Component {
  state = {
    showTabs: false,
    currentTabs: [],
    activeTab: null,
    isWithoutTariffForm: !this.props.tariffs,
    showFTTBForm: false,
    FTTBData: {},
    isOpenAddressForm: false,
  };

  componentDidMount() {
    const {
      category,
      tabs,
      price,
      tariffs,
      formattedValue,
      number,
      currentRegion,
      showAddressForm,
    } = this.props;

    // В рамках задачи BCDEV-6116 попросили скрыть табы, но сам фанк оставить
    // const showTabs = category === 'bronzovie' && tabs && tabs.length > 0 && tariffs && tariffs.length > 0;
    const showTabs = false;
    const showFTTBForm =
      category === 'bronzovie' &&
      tariffs &&
      tariffs.length > 0 &&
      currentRegion.isFTTB &&
      showAddressForm;

    this.setState({
      NumberToHtml:
        formattedValue ?
          numberToHtml({
            number: { formattedValue, value: unformatPhone(number) },
          })
        : number,
      showFTTBForm,
      primaryShowFTTBFormStateValue: showFTTBForm,
    });

    if (showTabs) {
      const newTabs = tabs.map((tab) => {
        const newTitle = tab.title
          .replace('{{TARIFFNAME}}', tariffs[0].name)
          .replace('{{PRICE}}', price);

        return {
          title: newTitle,
          id: tab.id,
        };
      });

      this.setState({
        currentTabs: newTabs,
        activeTab: tabs[0].id,
        showTabs,
      });
    }
  }

  handleFormButtonClick = () => {
    // if (this.state.activeTab === 'without-tariff') {
    //   return this.props.onButtonClick(-1);
    // }
  };

  renderForm = () => {
    if (this.state.showTabs) {
      return this.renderFormWithTabs();
    } else if (this.state.isWithoutTariffForm) {
      return this.renderFancyNumberWithoutTariff();
    } else {
      return this.renderFancyNumberWithTariff();
    }
  };

  renderFancyNumberWithTariff = () => {
    const {
      fancyNumberWithChoiceTariff,
      totalPrice,
      tariffs,
      tariffIndex,
      category,
      categoryName,
      price,
      deliveryFormComponent,
    } = this.props;

    return (
      <FancyNumberWithTariff
        {...fancyNumberWithChoiceTariff}
        category={category}
        categoryName={categoryName}
        deliveryFormComponent={deliveryFormComponent}
        isSoleTariff={tariffs.length === 1}
        numberToHtml={this.state.NumberToHtml}
        onChange={this.props.onTariffChange}
        price={price}
        tariffIndex={tariffIndex}
        tariffs={tariffs}
        totalPrice={totalPrice}
      />
    );
  };

  renderFancyNumberWithoutTariff = () => (
    <FancyNumberWithoutTariff
      bronzeDiscountText={this.props.bronzeDiscountText}
      categoryName={this.props.categoryName}
      deliveryFormComponent={this.props.deliveryFormComponent}
      discountValue={this.props.discountValue}
      isBronze={this.props.category === 'bronzovie'}
      numberToHtml={this.state.NumberToHtml}
      oldPrice={this.props.oldPrice}
      price={this.props.price}
    />
  );

  renderFormWithTabs = () => (
    <Tabs
      activeTab={this.state.activeTab}
      onChange={(activeTab) => {
        this.setState({
          activeTab,
          showFTTBForm: this.state.primaryShowFTTBFormStateValue && activeTab === 'with-tariff',
        });
      }}
    >
      <TabsList type="calendar">
        {this.state.currentTabs.map((tab) => (
          <Tab id={tab.id} key={tab.id}>
            <span dangerouslySetInnerHTML={{ __html: tab.title }} />
          </Tab>
        ))}
      </TabsList>

      <TabContent id="with-tariff">{this.renderFancyNumberWithTariff()}</TabContent>

      <TabContent id="without-tariff">{this.renderFancyNumberWithoutTariff()}</TabContent>
    </Tabs>
  );

  render() {
    const {
      number,
      onFormSubmit,
      onFeedbackNumberChange,
      currentFeedbackNumber,
      onCaptchaChange,
      captchaRequired,
      serverErrors,
      title,
      titleIcon,
      description,
      buttonText,
      warningText,
      warningLink,
      warningLinkText,
      onValidate,
      onValidateFailed,
      fttbFormContent,
      currentRegion,
      invalidFTTBData,
      deliveryFormComponent,
      totalPrice,
      hideForm,
    } = this.props;
    const { FTTBData, showFTTBForm } = this.state;

    const orderButtonText = totalPrice ? `Заказать за ${totalPrice} ₽` : `${buttonText}`;

    return (
      <div>
        <Heading className={cx('header')} isStyled={false} level={1}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
          {titleIcon && <Emoji className="inHeading" path={titleIcon} />}
        </Heading>

        {this.renderForm()}

        {showFTTBForm && (
          <CollapsedContent
            className={cx('collapsedContentWrapper')}
            contentClass={cx('collapsedContent')}
            title={fttbFormContent.title}
          >
            <p
              className={cx('description')}
              dangerouslySetInnerHTML={{ __html: fttbFormContent.description }}
            />
            <AddressForm
              city={{
                name: currentRegion.title,
                id: currentRegion.id,
              }}
              invalid={invalidFTTBData}
              onChange={(data, isOpenAddressForm) =>
                this.setState({ FTTBData: data, isOpenAddressForm })
              }
            />
            {FTTBData.street && FTTBData.house && FTTBData.flat && (
              <Status className={cx('fttbStatus')} text={fttbFormContent.statusText} />
            )}
          </CollapsedContent>
        )}

        {warningText && (
          <div className={cx('warning')}>
            <Status className="attention" emoji="statusFail">
              <div className={cx('warningContent')}>
                <div
                  className={cx('warningText')}
                  dangerouslySetInnerHTML={{ __html: warningText }}
                />
                {warningLink && (
                  <Link className={cx('warningLink')} href={warningLink}>
                    {warningLinkText}
                  </Link>
                )}
              </div>
            </Status>
          </div>
        )}

        {!hideForm && (
          <>
            <SmartForm
              inline
              onSubmit={onFormSubmit}
              onSubmitFailed={onValidateFailed}
              onValidate={onValidate}
              validator={{
                number: validateFancyNumber(number),
                Captcha: captchaValidator,
                test: true,
              }}
            >
              <div className={cx('form', { withCaptcha: captchaRequired })}>
                <SmartInput
                  className={cx('input')}
                  inputId="number"
                  keepInitialValue
                  mask="+7 ___ ___ __ __"
                  onChange={onFeedbackNumberChange}
                  size="big"
                  value={currentFeedbackNumber || '+7 '}
                />
                {captchaRequired && (
                  <div className={cx('captchaWrapper')}>
                    <Captcha
                      captchaKey="fancynumber-order-form"
                      forceTouch={serverErrors.captcha}
                      inputId="Captcha"
                      name="Captcha"
                      onChange={onCaptchaChange}
                    />
                    <SmartInput
                      inputId="_captcha_key"
                      name="_captcha_key"
                      type="hidden"
                      value="fancynumber-order-form"
                    />
                  </div>
                )}
                <Button className={['big']} onClick={this.handleFormButtonClick} transformer>
                  {orderButtonText}
                </Button>
              </div>
            </SmartForm>
            <p className={cx('description')} dangerouslySetInnerHTML={{ __html: description }} />
          </>
        )}

        <SimOrderBenefits
          benefits={deliveryFormComponent ? [] : ['delivery']}
          className={cx('orderFormText')}
          showAgreement
        />
      </div>
    );
  }
}

OrderForm.propTypes = {
  fancyNumberWithChoiceTariff: PropTypes.object,
  formattedValue: PropTypes.string,
  tariffs: PropTypes.array,
  category: PropTypes.string,
  categoryName: PropTypes.string,
  tabs: PropTypes.array,
  number: PropTypes.string,
  price: PropTypes.number,
  onFormSubmit: PropTypes.func,
  onButtonClick: PropTypes.func,
  onFeedbackNumberChange: PropTypes.func,
  onCaptchaChange: PropTypes.func,
  captchaRequired: PropTypes.bool,
  serverErrors: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  titleIcon: PropTypes.string,
  warningText: PropTypes.string,
  warningLink: PropTypes.string,
  warningLinkText: PropTypes.string,
  onValidate: PropTypes.func,
  currentRegion: PropTypes.object,
  showAddressForm: PropTypes.bool,
  fttbFormContent: PropTypes.object,
  invalidFTTBData: PropTypes.bool,
  bronzeDiscountText: PropTypes.string,
  discountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  deliveryFormComponent: PropTypes.any,
  oldPrice: PropTypes.string,
  totalPrice: PropTypes.number,
  tariffIndex: PropTypes.number,
  onTariffChange: PropTypes.func,
  currentFeedbackNumber: PropTypes.string,
  onValidateFailed: PropTypes.func,
  hideForm: PropTypes.bool,
};

export default OrderForm;
