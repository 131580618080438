import { Text } from '@beef/ui-kit';
import classnames from 'classnames/bind';
import React, { useState } from 'react';

import { pushAnniversaryEvent } from '../../utils/analytics';
import { StoryCardLayout as StoryCard } from '../StoryCard';
import { StoryModalLayout as StoryModal } from '../StoryModal';
import { CardPlaceholder } from '../CardPlaceholder';
import { STORIES_EXAMPLE_ALIAS } from '../../constants';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const StoryExamples = ({
  title,
  previewButtonText,
  storyButton = {},
  stories,
  size = 'm',
}) => {
  const [currentStory, setCurrentStory] = useState(null);

  return (
    <>
      <div className={cx('title')}>
        <Text size="size1-m" tagType="h2">
          {title}
        </Text>
      </div>
      <div className={cx('anchor')} id={STORIES_EXAMPLE_ALIAS} />
      <div className={cx('grid', `size-${size}`)}>
        {stories.map((story, index) =>
          story.type === 'placeholder' ?
            <CardPlaceholder image={story.image} />
          : <StoryCard
              buttonText={previewButtonText}
              image={story.image}
              key={+index}
              onClick={() => {
                pushAnniversaryEvent({
                  read_story: {
                    name: story.name,
                    storyID: story.id,
                  },
                });
                setCurrentStory(story);
              }}
              text={story.name}
              title={story.author}
            />,
        )}
      </div>
      {currentStory && (
        <StoryModal
          isActionAvailable={!!storyButton.link}
          isOpen
          onClose={() => setCurrentStory(null)}
          onSubmit={() => {
            window.location.href = currentStory.link || storyButton.link;
          }}
          story={currentStory}
          submitText={storyButton.text}
        />
      )}
    </>
  );
};
