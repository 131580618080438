import React, { useMemo } from 'react';
import classNames from 'classnames/bind';

import { MobileFilter } from 'pages/SearchResultsPage/components/Settings/MobileFilter';
import { Sort } from 'pages/SearchResultsPage/components/Settings/Sort';
import { TotalHits } from 'pages/SearchResultsPage/components/Settings/TotalHits';
import { SortWithAnalytics } from 'pages/SearchResultsPage/components/Settings/Sort/SortWithAnalytics';
import { useGetDimensionGroup } from 'utils/hooks/useGetDimensionGroup';
import { MobileContext } from 'pages/SearchResultsPage/components/Settings/mobileContext';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Settings = () => {
  const dimension = useGetDimensionGroup();
  const isAdaptive = useMemo(() => dimension && dimension !== 'desktop', [dimension]);

  return (
    <div className={cx('container')}>
      <TotalHits />
      <div className={cx('settings')}>
        <MobileContext.Provider value={isAdaptive}>
          <MobileFilter />
        </MobileContext.Provider>
        <SortWithAnalytics>
          <Sort />
        </SortWithAnalytics>
      </div>
    </div>
  );
};
