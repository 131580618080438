import { pathOr } from 'ramda';

import { getLabelSymbol } from './actions';

export const getPrice = (price, discount, priceDescription) => {
  if (!price) return null;

  const priceData = pathOr(null, ['data'], price);
  const text = pathOr(null, ['text'], priceDescription);
  const showDiscount = pathOr(null, ['showDiscount'], priceDescription);
  const priceWithoutDiscount = pathOr(null, ['priceWithoutDiscount'], discount);
  const discountLabel = pathOr(null, ['label'], discount);

  // Формирование старой цены(перечеркнутой цены);
  const oldValue = priceWithoutDiscount ? `${priceWithoutDiscount}&nbsp;₽` : null;
  // Иконка в шильдике;
  const priceLabelWithSymbol = priceData && getLabelSymbol(priceData);

  let priceObj = {
    value: price.value,
    unit: price.unit,
    oldValue,
    label: priceLabelWithSymbol || discountLabel,
    condition: text,
  };

  // Если есть поле showDiscount и поле равное true, выводим скидку под ценой;
  if (showDiscount) {
    priceObj = {
      ...priceObj,
      oldValue: null,
      label: null,
      conditionLabel: discountLabel,
    };
  }

  return priceObj;
};
