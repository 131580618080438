import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetPresetsUrl } from '../constants';
import { TFetchTariffUpPresetQueryRes, TFetchTariffUpPresetReq } from './types';

export const fetchTariffUpPreset = createAsyncThunk<
  TFetchTariffUpPresetQueryRes,
  TFetchTariffUpPresetReq
>('presetApi/fetchTariffUpPresets', async (params, { signal }) => {
  try {
    return (
      await axios.get<TFetchTariffUpPresetQueryRes>(apiGetPresetsUrl, {
        params,
        signal,
      })
    ).data;
  } catch (e) {
    throw new Error();
  }
});
