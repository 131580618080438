import { createContext } from 'react';

const SET_LOADING = 'SET_LOADING';
const SET_SOCS_ON = 'SET_SOCS_ON';
const SET_SOCS_OFF = 'SET_SOCS_OFF';
const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE';
const SET_TARIFF_INFO = 'SET_TARIFF_INFO';
const SET_PACKAGE_PARAM = 'SET_PACKAGE_PARAM';
const SET_CHOSEN_OPTIONS = 'SET_CHOSEN_OPTIONS';

export {
  SET_ACTIVE_TAB,
  SET_CHOSEN_OPTIONS,
  SET_LOADING,
  SET_PACKAGE_PARAM,
  SET_SOCS_OFF,
  SET_SOCS_ON,
  SET_TARIFF_INFO,
  SET_TOTAL_PRICE,
};

/** Пустая функция передается для логики ADS */
const DispatchContext = createContext(() => {});

export default DispatchContext;
