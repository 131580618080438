import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import TariffsList from '../../components/TariffsList';
import SwipedContainer from '../SwipedContainer';
import TariffsGroupTitle from './components/TariffsGroupTitle';
import TariffsGroupIcon from './components/TariffsGroupIcon';
import TariffCatalogTitle from './components/TariffCatalogTitle';
import ShowMoreButton from './components/ShowMoreButton';

// Hoc's
import MainCatalogMobileHoc from '../../hocs/MainCatalogMobileHoc';
import MainCatalogDesktopHoc from '../../hocs/MainCatalogDesktopHoc';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CATALOG_HOCS = {
  mobile: MainCatalogMobileHoc,
  desktop: MainCatalogDesktopHoc,
};

const TariffsCatalog = ({
  isTariffsListCenterClass,
  groupWithGradientClassName,
  triggerResizeEvent,
  tariffsCount,
  setShowButton,
  isShowButton,
  catalogTitle,
  subTitle,
  tariffs,
  device,
}) => {
  // По клику на кнопку обновляем каталог иначе события в каталоге работать не будут;
  triggerResizeEvent && useEffect(triggerResizeEvent, [isShowButton]);

  return (
    <div className={cx('digitalTariffsContainer')}>
      <TariffCatalogTitle catalogTitle={catalogTitle} subTitle={subTitle} />

      <SwipedContainer device={device}>
        <div className={cx('tariffs-list', groupWithGradientClassName, isTariffsListCenterClass)}>
          {tariffs.map((tariffGroup, groupIndex) => (
            <div
              className={cx('block', {
                'group-gradient': tariffGroup.isHot,
                'yandex-gradient': tariffGroup.isYandex,
              })}
              id={`block_${groupIndex}`}
              key={groupIndex}
            >
              <div className={cx('block__group-wrapper')}>
                {tariffGroup.icon && <TariffsGroupIcon icon={tariffGroup.icon} />}
                <TariffsGroupTitle
                  className={cx('group-title')}
                  title={tariffGroup.title}
                  titleId="groupTitle"
                />
              </div>
              <TariffsList
                className={cx('list')}
                isYandex={tariffGroup.isYandex}
                paramsGridView
                tariffList={tariffGroup.entries}
                tariffsGroup={tariffs}
              />
            </div>
          ))}
          {isShowButton && <ShowMoreButton number={tariffsCount} onClick={setShowButton} />}
        </div>
      </SwipedContainer>
    </div>
  );
};

TariffsCatalog.propTypes = {
  groupWithGradientClassName: PropTypes.string,
  isTariffsListCenterClass: PropTypes.string,
  catalogTitle: PropTypes.string,
  device: PropTypes.string,

  tariffsCount: PropTypes.number,

  isShowButton: PropTypes.bool,

  subTitle: PropTypes.object,
  tariffs: PropTypes.array,

  setShowButton: PropTypes.func,
  triggerResizeEvent: PropTypes.func,
};

const DigitalTariffsCatalog = (props) => {
  const DigitalTariffsCatalogHoc = CATALOG_HOCS[props.device];
  return (
    <DigitalTariffsCatalogHoc {...props}>
      <TariffsCatalog />
    </DigitalTariffsCatalogHoc>
  );
};

DigitalTariffsCatalog.propTypes = {
  device: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};

export default DigitalTariffsCatalog;
