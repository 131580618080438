import { createSlice } from '@reduxjs/toolkit';

import { TTariffInfo } from '../../../components/constructorPage/TariffInfoTiles/types';

const initialState: TTariffInfo[] = [];

/** Slice для основных плиток тарифа (персонаж) */
export const tariffInfoSlice = createSlice<TTariffInfo[]>({
  name: 'tariffsInfo',
  initialState,
  reducers: {},
});

export const tariffsInfoReducer = tariffInfoSlice.reducer;
