import React from 'react';

import { Separation } from '../components/commonComponents/Separation';
import { TariffContainer } from '../components/commonComponents/TariffContainer';
import { MnpContainer } from '../components/MnpContainer';
import { BillContainer } from '../components/commonComponents/BillContainer';

/** Страница настройки перехода со своим номером */
export const MnpPage = () => (
  <TariffContainer>
    <Separation>
      <MnpContainer />
    </Separation>
    <Separation sideType="right">
      {/* Чек тарифа и промо баннер */}
      <BillContainer />
    </Separation>
  </TariffContainer>
);
