// TODO: Файл использует расширение .tsx по причине того,что для китового компонента
//  "ChipsStateless" требуется пробрасывать JSX-элемент для перечеркивания старой цены (скидка)!
import React from 'react';

import { FmcDescription } from '../../../FmcTabsContainer/components/FmcDescription';
import { TServicesTariff } from '../../../../../store/slices/fttbSlice/types';

/** Функция подготовки структуры данных для табов ШПД-сервисов */
export const createServicesFmcTabs = (servicesTariffs: TServicesTariff[]) =>
  servicesTariffs?.map((tariff) => ({
    value: tariff.inacId,
    title: tariff.priceInfo?.speedText,
    description: (
      <FmcDescription oldPrice={tariff.priceInfo?.oldPrice} price={tariff.priceInfo?.price} />
    ),
  }));

/** Функция выборки сервисов ШПД по текущему активному табу */
export const selectServiceForActiveTab = (tariffs: TServicesTariff[], inacId: string) =>
  tariffs.find((tariff) => tariff.inacId === inacId) ?? {};
