import classNames from 'classnames/bind';
import React, { useContext, useEffect, useMemo } from 'react';
import { Text } from '@beef/ui-kit';

import { getPartnerProductsInfo } from '../../packages/context/actions';
import { ProductsContext } from '../../packages/context/context';
import { filterPartnerSubscriptions } from '../../utils';
import { Carousel } from '../Carusel/index';
import { ContentWrapper } from '../ContentWrapper/index';
import { PartnerTabs } from '../Tabs/index';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IPartnerSubscriptionsProps {
  onPartnershipClick?: (subscriptions: Record<string, unknown>[]) => void;
  soc?: string;
}

const PartnerSubscriptions: React.FC<IPartnerSubscriptionsProps> = ({
  soc,
  onPartnershipClick,
}) => {
  const state = useContext(ProductsContext);

  const {
    isVisiblePanel,
    subscriptions,
    tabsContent,
    chosenTab,
    defaultTabLabel,
    partnerSubscriptionsTitle,
  } = state;

  useEffect(() => {
    // NOTE: В случае ошибки, пользватель не увидит партнерских
    // подписок в тарифе, что некретично, но вероятно есть
    // более подходящий способ
    getPartnerProductsInfo(soc).catch(console.error);
  }, []);

  useEffect(() => {
    const checkedSubscriptions =
      subscriptions?.filter((subscription) => subscription.checked) || [];
    onPartnershipClick?.(checkedSubscriptions);
  }, [subscriptions]);

  const selectedSubscriptions = useMemo(
    () => filterPartnerSubscriptions(subscriptions, defaultTabLabel, chosenTab),
    [chosenTab],
  );

  return (
    <ContentWrapper isVisiblePanel={isVisiblePanel}>
      <div className={cx('wrapper')}>
        {partnerSubscriptionsTitle && (
          <Text color="grey95" size="size3-m">
            {partnerSubscriptionsTitle}
          </Text>
        )}
        {!!tabsContent?.length && <PartnerTabs tabsContent={tabsContent} />}
        {!!selectedSubscriptions?.length && <Carousel subscriptions={selectedSubscriptions} />}
      </div>
    </ContentWrapper>
  );
};

export { PartnerSubscriptions };
