import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { connectionDataPropTypes } from 'pages/FTTB/constants';
import { getConnectionData } from 'pages/FTTB/selectors/connectionState';
import PopupRequestHouse from 'pages/AllInOneForm/components/PopupRequestHouse/PopupRequestHouse';
import FullFormPopup from 'pages/FTTB/components/FullFormPopup';
import BackCallPopup from 'pages/FTTB/components/BackCallPopup';

class OrderTariffPopupsGroup extends PureComponent {
  state = {
    fullFormPopupIsOpen: true,
    connectHousePopupIsOpen: false,
    backCallPopupIsOpen: false,
  };

  componentDidMount() {
    if (this.props.forceShowBackCallPopup) {
      this.onOpenBackCallPopup();
    }
  }

  onOpenPopupRequestHouse = () =>
    this.setState({ connectHousePopupIsOpen: true, fullFormPopupIsOpen: false });

  onClosePopupRequestHouse = () => {
    this.setState({ connectHousePopupIsOpen: false }, this.checkAllPopupClosed);
  };

  onOpenBackCallPopup = () =>
    this.setState({ backCallPopupIsOpen: true, fullFormPopupIsOpen: false });

  onCloseBackCallPopup = () => {
    this.setState({ backCallPopupIsOpen: false }, this.checkAllPopupClosed);
  };

  onCloseFullFormPopup = () => {
    this.setState({ fullFormPopupIsOpen: false }, this.checkAllPopupClosed);
  };

  checkAllPopupClosed = () => {
    const { fullFormPopupIsOpen, connectHousePopupIsOpen, backCallPopupIsOpen } = this.state;

    if (fullFormPopupIsOpen || connectHousePopupIsOpen || backCallPopupIsOpen) return;

    this.props.onCloseAllPopups();
  };

  render() {
    const { fullFormPopupIsOpen, connectHousePopupIsOpen, backCallPopupIsOpen } = this.state;

    const {
      connectingRequestUrl,
      backCallPopupData,
      shouldFetchSpecTariff,
      promoTariff,
      isColorTariff,
      topTariffPromoModel,
      getAllSelectedServicesForTariff,
      getAllExcludedServicesForTariff,
      orderFormData,
      availableScheduleData,
      isFttbWithTv,

      // from connect()
      connectionData,
    } = this.props;

    const { street, house } = connectionData || {};

    // Для цветного тарифа модель берётся из store,
    // а в promoTariffModel кладётся модель для "дефолтного" тарифа, на случай изменения адреса
    const promoTariffModel = isColorTariff ? topTariffPromoModel : promoTariff;

    return (
      <div>
        {fullFormPopupIsOpen && (
          <FullFormPopup
            availableScheduleData={availableScheduleData}
            checkSpecTariff={isColorTariff}
            connectingRequestUrl={connectingRequestUrl}
            connectionData={connectionData}
            data={orderFormData}
            getAllExcludedServicesForTariff={getAllExcludedServicesForTariff}
            getAllSelectedServicesForTariff={getAllSelectedServicesForTariff}
            isFttbWithTv={isFttbWithTv}
            isOpen
            onClose={this.onCloseFullFormPopup}
            onConnectHouse={this.onOpenPopupRequestHouse}
            onOpenBackCallPopup={this.onOpenBackCallPopup}
            promoTariff={promoTariffModel}
            shouldFetchSpecTariff={shouldFetchSpecTariff}
          />
        )}
        {connectHousePopupIsOpen && (
          <PopupRequestHouse
            house={house}
            onClose={this.onClosePopupRequestHouse}
            popupIsOpen
            street={street}
          />
        )}
        {backCallPopupIsOpen && (
          <BackCallPopup
            analyticTariffDetails={this.props.backCallAnalyticsData}
            data={backCallPopupData}
            forceShowBackCallPopup={this.props.forceShowBackCallPopup}
            isOpen
            onClose={this.onCloseBackCallPopup}
            showCaptcha={backCallPopupData.showCaptcha}
          />
        )}
      </div>
    );
  }
}

OrderTariffPopupsGroup.propTypes = {
  forceShowBackCallPopup: PropTypes.bool,
  backCallAnalyticsData: PropTypes.object,
  connectionData: PropTypes.shape(connectionDataPropTypes),
  connectingRequestUrl: PropTypes.string,
  backCallPopupData: PropTypes.object,
  promoTariff: PropTypes.object,
  orderFormData: PropTypes.object,
  topTariffPromoModel: PropTypes.object,
  isColorTariff: PropTypes.bool,
  isFttbWithTv: PropTypes.bool,
  availableScheduleData: PropTypes.object,
  shouldFetchSpecTariff: PropTypes.bool,
  onCloseAllPopups: PropTypes.func,
  getAllSelectedServicesForTariff: PropTypes.func,
  getAllExcludedServicesForTariff: PropTypes.func,
};

const mapStateToProps = (state) => ({
  connectionData: getConnectionData(state),
});

const ConnectedOrderTariffPopupsGroup = connect(mapStateToProps)(OrderTariffPopupsGroup);

export default ConnectedOrderTariffPopupsGroup;
