import React, { PropsWithChildren, useState } from 'react';
import { Cell } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { useIsDesktopDimension } from 'utils/hooks/useGetDimensionGroup';
import { Props } from 'pages/SearchResultsPage/components/Filter/FilterWrapper/types';
import { Chevron } from 'pages/SearchResultsPage/components/Filter/FilterWrapper/components/Chevron';
import { CATEGORY_TITLE } from 'pages/SearchResultsPage/components/Filter/constants';
import { Chips } from 'pages/SearchResultsPage/components/Filter/FilterWrapper/components/Chips';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const FilterWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
  title,
  className,
  isOpened = true,
  list,
  filterHandler,
  selected,
  chipsCanBeExtended,
  isShowChevron,
}) => {
  const [isOpen, setIsOpen] = useState(isOpened);
  const isDesktop = useIsDesktopDimension();

  const handleClick = () => {
    if (isDesktop) {
      setIsOpen((prev) => !prev);
    } else if (chipsCanBeExtended) {
      onClick(title);
    }
  };

  const chipsVisible = list && !isDesktop && title.toLowerCase() !== CATEGORY_TITLE;
  const showChevron = (list || isShowChevron) && (chipsCanBeExtended || isDesktop);

  return (
    <div className={cx(className, 'container')}>
      <Cell
        actionIcon={showChevron ? <Chevron isDesktop={isDesktop} isOpen={isOpen} /> : undefined}
        buttonConfig={{
          id: 'id',
          onClick: handleClick,
        }}
        className={cx('title')}
        leftTitle={title?.toLowerCase()}
      />
      {chipsVisible ?
        <Chips checkedValues={selected} onChange={filterHandler} options={list} />
      : null}
      {isDesktop && <div className={cx('children', !isOpen && 'children--closed')}>{children}</div>}
    </div>
  );
};
