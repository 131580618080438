import React from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const OptionsList = ({ options }) => (
  <div className={cx('description')}>
    <Text color="grey60" size="size5-r-s">
      {options}
    </Text>
  </div>
);

export { OptionsList };
