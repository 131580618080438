import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

// TODO: Не забыть про импорт при выносе в отдельный пакет!
import { TRecommendations } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/PresetPicker/types';

import { RecommendationsList } from '../RecommendationsList';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TDevicesInfo = {
  description?: string;
  recommendations: TRecommendations[];
};

/** Базовая информация по рекомендуемым устройствам */
export const DevicesInfo: FC<TDevicesInfo> = ({ description, recommendations }) => (
  <div className={cx('wrapper')}>
    <RecommendationsList {...{ recommendations }} />
    {description && (
      <Text color="grey60" size="size5-r-l">
        {description}
      </Text>
    )}
  </div>
);
