import React, { FC } from 'react';
import { AvatarGroup, Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type THeaderContainer = {
  entityName?: string;
  pictures?: Array<{ image: string }>;
};

/** Верхняя часть плитки опции.
 * Содержит в себе заголовок опции и иконки сервисов */
export const HeaderContainer: FC<THeaderContainer> = ({ entityName, pictures }) => (
  <div className={cx('wrapper')}>
    {entityName && (
      <Text align="left" size="xs" weight="medium">
        {entityName}
      </Text>
    )}
    {pictures?.length && (
      <AvatarGroup className={cx('wrapper__icons')} items={pictures} size="s" wrap />
    )}
  </div>
);
