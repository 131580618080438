import classNames from 'classnames/bind';
import React, { PropsWithChildren } from 'react';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ContentInfoWrapper: React.FC<PropsWithChildren<{ isSticky: boolean }>> = ({
  isSticky,
  children,
}) => (
  <div
    className={cx('container', {
      'container--sticky': isSticky,
    })}
  >
    <div
      className={cx('content', {
        'content--sticky': isSticky,
      })}
    >
      {children}
    </div>
  </div>
);
