import { TRequestParams as TRequestParamsBase } from '../types';
import { useAppDispatch } from '../../../../store';
import { useThrowAsyncError } from '../../../../hooks/useThrowAsyncError';
import { nextStep } from '../../../../store/slices/stepperSlice';
import { changeTariff } from '../../../../api/changeTariff';

type TRequestParams = {
  requestParams: TRequestParamsBase | undefined;
};

/** Возвращает событие запроса на смену тарифа */
export const useChangeTariff = ({ requestParams }: TRequestParams) => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();
  const onChangeTariff = async () => {
    if (!requestParams) {
      throw new Error();
    }

    dispatch(changeTariff(requestParams))
      .unwrap()
      .then(() => dispatch(nextStep()))
      .catch((e: Error) => throwAsyncError(e));
  };

  return { onChangeTariff };
};
