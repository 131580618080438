import React, { memo, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';
import SlidingDown from 'pages-components/SlidingDown';
import EmptyButton from 'pages-components/EmptyButton';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ChannelsList = ({ channels }) => {
  const [activeChannelId, setActiveChannelId] = useState(null);
  const [showDescription, setShowDescription] = useState(false);

  const wrapperRef = useRef(null);
  const activeChannelRef = useRef(null);
  const slidingWrapRef = useRef(null);
  const decriptionRef = useRef(null);
  const decriptionWrapRef = useRef(null);
  const decriptionArrowRef = useRef(null);

  const isActiveChannel = (id) => activeChannelId === id;
  const ifIsActive = (id, returnValue) => (isActiveChannel(id) ? returnValue : undefined);

  const onChannelClick = (id) => {
    if (activeChannelId === id) {
      setShowDescription(false);
      setTimeout(() => setActiveChannelId(null), 230);
      return;
    }

    setActiveChannelId(id);
  };
  const activeChannelIndex = channels.findIndex((channel) => channel.id === activeChannelId);

  useLayoutEffect(() => {
    if (typeof activeChannelId !== 'number') return;
    if (activeChannelIndex === -1) return;

    const activeChannelEl = activeChannelRef.current;
    const slidingWrapEl = slidingWrapRef.current;
    const decriptionEl = decriptionRef.current;
    const decriptionWrapEl = decriptionWrapRef.current;
    const decriptionArrowEl = decriptionArrowRef.current;

    const { offsetLeft, offsetWidth: channelWidth } = activeChannelEl;
    const { offsetTop } = decriptionWrapEl;
    const width = wrapperRef.current.offsetWidth;

    slidingWrapEl.style.width = `${width}px`;
    slidingWrapEl.style.marginLeft = `-${offsetLeft}px`;

    const { offsetHeight } = decriptionEl;
    decriptionEl.style.top = `${offsetTop}px`;
    decriptionArrowEl.style.left = `${offsetLeft + channelWidth / 2}px`;
    decriptionWrapEl.style.height = `${offsetHeight}px`;

    setShowDescription(true);
  }, [activeChannelId, activeChannelIndex]);

  return (
    <div className={cx('wrapper')} ref={wrapperRef}>
      {channels.map((channel) => (
        <div
          className={cx('channelWrap')}
          key={channel.id}
          ref={ifIsActive(channel.id, activeChannelRef)}
        >
          <EmptyButton
            className={cx('channel', { activeChannel: isActiveChannel(channel.id) })}
            onClick={() => onChannelClick(channel.id)}
          >
            <div className={cx('channelImg')}>
              <Picture lazy src={channel.image} />
            </div>
          </EmptyButton>
          <div className={cx('slidingWrap')} ref={ifIsActive(channel.id, slidingWrapRef)}>
            <SlidingDown disableOnMobile show={showDescription && isActiveChannel(channel.id)}>
              {isActiveChannel(channel.id) && (
                <div className={cx('descriptionWrap')} ref={decriptionWrapRef}>
                  <div className={cx('descriptionArrow')} ref={decriptionArrowRef} />
                  <div
                    className={cx('descriptionContent')}
                    dangerouslySetInnerHTML={{ __html: channel.description }}
                    ref={decriptionRef}
                  />
                </div>
              )}
            </SlidingDown>
          </div>
        </div>
      ))}
    </div>
  );
};

ChannelsList.propTypes = {
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};

export default memo(ChannelsList);
