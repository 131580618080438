import { cloneElement } from 'react';
import { connect } from 'react-redux';

import { pushFilterReset } from 'pages/SearchResultsPage/utils/analytics';
import { getPlaceForAnalytics } from 'pages/SearchResultsPage/store/selectors';

const _ResetButtonWithAnalytics = ({ children, location }) => {
  const handleClick = (e) => {
    children.props.onClick?.(e);
    pushFilterReset(location);
  };
  return cloneElement(children, { onClick: handleClick });
};

export const ResetButtonWithAnalytics = connect((state) => ({
  location: getPlaceForAnalytics(state),
}))(_ResetButtonWithAnalytics);
