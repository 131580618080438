import { composeStoreInitializer } from '@beef/redux';
import { combineReducers } from '@reduxjs/toolkit';

import anketa from './anketa';
import modal from './modal';
import status from './status';
import stories from './stories';
import story from './story';
import timeCapsule from './time-capsule';
import votes from './votes';
import finalStories from './finalStories';

export const initializeStoreResultsLanding = composeStoreInitializer(
  combineReducers({
    content: (state = {}) => state,
    stories,
    status,
    story,
    votes,
  }),
);

export const initializeStoreTimeCapsuleLanding = composeStoreInitializer(
  combineReducers({
    form: timeCapsule,
    modal,
  }),
);

export const initializeStoreAnketaLanding = composeStoreInitializer(
  combineReducers({
    form: anketa,
    modal,
  }),
);

export const initializePimpMyBeepLanding = composeStoreInitializer(
  combineReducers({
    content: (state = {}) => state,
    status,
  }),
);

export const initializeEmployeesLanding = composeStoreInitializer(
  combineReducers({
    content: (state = {}) => state,
  }),
);

export const initializeFinalLanding = composeStoreInitializer(
  combineReducers({
    content: (state = {}) => state,
    finalStories,
    status,
  }),
);

export const initializeFinalResultsLanding = initializeEmployeesLanding;
