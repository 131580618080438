import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { ChevronRightIcon } from '@beef/ui-kit';

import { PassiveContent } from './components/PassiveContent';
import { ModalContainer } from './components/ModalContainer';
import { ModalContent } from './components/ModalContent';
import { TPassive } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

// TODO: Выпилить "Passive" из старого конструктора и добавить его туда импортом!
/** Блок пассивки для пресетов (Гб).
 * Включает в себя контентную часть и модальное общее окно */
export const Passive = memo<TPassive>(
  ({
    handleClick,
    selectedType,
    passivePopups,
    backgroundColor,
    passiveContentProps,
    modalContainerProps,
  }) => (
    <>
      <button
        className={cx('button', 'wrapper')}
        onClick={handleClick}
        style={{ backgroundColor }}
        type="button"
      >
        <PassiveContent {...passiveContentProps} />
        <ChevronRightIcon />
      </button>
      <ModalContainer {...modalContainerProps}>
        <ModalContent {...{ selectedType, passivePopups }} />
      </ModalContainer>
    </>
  ),
);
