import { useEffect } from 'react';
import { shallowEqual } from '@beef/utils';

import {
  getActiveFmcTabId,
  getCurrentConvergenceInfo,
} from '../components/constructorPage/FmcTabsContainer/utils';
import { fetchFmcPresets } from '../api/fmсPresets';
import { DEFAULT_TAB_ID } from '../components/constructorPage/FmcTabsContainer/constants';
import { useSelectErrorFmcPresets } from '../components/constructorPage/FmcServicesSettings/selectors/useSelectErrorFmcPresets';
import { useThrowAsyncError } from './useThrowAsyncError';
import { useAppDispatch, useAppSelector } from '../store';
import { setRequestPresetsHash } from '../store/slices/fttbSlice';
import { setConvergenceBlock } from '../store/slices/tariffServicesSlice';
import { generateHash } from '../utils/generateHash';
import { useIsConvergentUser } from './tariffStateHooks/useIsConvergentUser';
import { TabTypes } from '../components/constructorPage/Tabs/constants';
import { setIsPartnerFmcTariffSelected, setNetSpeed } from '../store/slices/totalSlice';
import { changeFmcInacId } from '../store/slices/totalSlice/asyncThunks/changeFmcInacId';
import { useSelectTariffTab } from '../components/constructorPage/Tabs/selectors/useSelectTariffTab';
import { fetchPartnerFmcTariffs } from '../api/fetchPartnerFmcTariffs';
import { useIsArchivedTariff } from './tariffStateHooks/useIsArchivedTariff';

/** Отправки запроса для получения данных по доступным пресетам ШПД */
export const useGetAvailableFmcPresets = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();
  const isArchived = useIsArchivedTariff();
  const isConvergenceUser = useIsConvergentUser();

  /* Получаем текущие данные из общего объекта total и common.
   * alias используется для запроса партнёрского fmc */
  const {
    alias,
    regionId,
    generation,
    characterSoc,
    tariffInacId = '',
    availableFmc,
    isPartnerFmcAvailable,
    tariffSoc,
  } = useAppSelector(
    (state) => ({
      alias: state.total.alias,
      generation: state.total.generation,
      characterSoc: state.total.characterSoc,
      regionId: state.common.userRegion.regionId,
      availableFmc: state.common.userRegion.availableFmc,
      tariffInacId: state.total.convergenceInfo.tariffInacId,
      isPartnerFmcAvailable: state.common.userRegion.isPartnerFmcAvailable,
      tariffSoc: state.total.tariffSoc,
    }),
    shallowEqual,
  ) ?? {};

  /** Для единого конструктора на шпд берется сок тарифа тк нет сока персонажа */
  const soc = characterSoc ?? tariffSoc;

  /* Текущий вариант (таб) способа подключения тарифа */
  const { currentTariffTab } = useSelectTariffTab();

  /* Наличие ошибки при отправке запроса на получение ШПД-пресетов (главная страница) */
  const isErrorFmcRequest = useSelectErrorFmcPresets();

  /* Получаем текущий хэш (requestPresetsHash) запроса пресетов из стейта или кеша */
  const currentRequestHash = useAppSelector((state) => state.fttbPresets?.requestPresetsHash);

  /* Формируем хэш по текущим параметрам запроса, для последующего предотвращения запроса с аналогичными данными */
  const requestHash = generateHash(regionId, generation, characterSoc);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    /** Если пользователь УЖЕ ЯВЛЯЕТСЯ конвергентом и текущий выбранный таб способа подключения тарифа ChangeTariffForm,
     * или пользователь имеет архивный тариф UP,
     * не выполняем запрос на получение FMC-пресетов (табов ШПД) */
    if ((isConvergenceUser && currentTariffTab === TabTypes.ChangeTariffForm) || isArchived) {
      return;
    }

    /* Если в регионе доступно ШПД (availableFmc) и закончена проверка данных из кеша,
     переходим к следующему условию */
    /* Если хэш запроса ШПД-пресетов (requestPresetsHash) НЕ РАВЕН текущему хэшу запроса (requestHash),
       либо произошла ошибка запроса - отправляем запрос на получение ШПД-пресетов */
    if (availableFmc && (currentRequestHash !== requestHash || isErrorFmcRequest)) {
      /* Получаем расширенные данные по ШПД-сервисам для настройки и добавляем их в стор */
      dispatch(fetchFmcPresets({ regionId, generation, soc }))
        .unwrap()
        /* Ставим актуальную информацию по блоку конвергенции (заголовок, описание, иконка и т.п),
         * если пользователь конвергент (С ШПД) */
        .then(({ tariffs = [] }) => {
          if (isConvergenceUser) {
            /** Флаг определяет наличие, в доступных для подключения тарифах ШПД, текущего подключенного inacId тарифа (/fmcpresets/) */
            const hasConnectedInacIdFmcTariff = tariffs.some(
              (tariff) => tariff.inacId === tariffInacId,
            );

            /* Если среди доступных табов ШПД не пришла скорость с inacId, как у текущего подключенного ШПД,
             записываем default inacId ШПД (Без интернета) в total */
            if (!hasConnectedInacIdFmcTariff) {
              dispatch(changeFmcInacId(DEFAULT_TAB_ID)).catch((error: Error) =>
                console.error(error),
              );
            }

            /** Записываем информацию по convergenceBlock в total */
            dispatch(
              setConvergenceBlock(
                getCurrentConvergenceInfo(tariffs, tariffInacId)?.convergenceBlock || {},
              ),
            );
          }
        })
        /* Записываем хэш текущего запроса в стор */
        .then(() => dispatch(setRequestPresetsHash(requestHash)))
        .catch((e: Error) => throwAsyncError(e));
    }

    /* Если в регионе НЕ доступно ШПД (availableFmc), закончена проверка данных из кеша
     и доступен ПК, запрашиваем данные тарифа от партнёров */
    if (
      !availableFmc &&
      isPartnerFmcAvailable &&
      (currentRequestHash !== requestHash || isErrorFmcRequest)
    ) {
      dispatch(setRequestPresetsHash(requestHash));
      dispatch(fetchPartnerFmcTariffs({ alias }))
        .unwrap()
        .then(({ tariffs = [] }) => {
          /* Проверяем, есть ли признак "активности" */
          const activeTabId = getActiveFmcTabId(tariffs);

          /* Если есть тариф с признаком isSelectedTab (activeTabId) - сеттим признак партнёрского тарифа,
             id для активации таба и его convergenceBlock */
          if (activeTabId) {
            dispatch(setIsPartnerFmcTariffSelected(true));
            // eslint-disable-next-line no-void
            void dispatch(changeFmcInacId(activeTabId));

            /* Вытаскиваем данные для активного таба блока "Домашний интернет и ТВ" */
            const { convergenceBlock = {}, priceList } = getCurrentConvergenceInfo(
              tariffs,
              activeTabId,
            );

            /* Устанавливаем convergenceBlock для блока "Домашний интернет и ТВ" */
            dispatch(setConvergenceBlock(convergenceBlock));

            /* Устанавливаем netSpeed для блока Домашний интернет и ТВ */
            dispatch(setNetSpeed(priceList?.[0].speedText || ''));
          }
        })
        .catch((e: Error) => console.error(e));
    }
  }, [requestHash, currentTariffTab]);
};
