import React, { cloneElement, useContext } from 'react';

// Context
import PropTypes from 'prop-types';

import { ProductsContext } from '../../packages/context/context';

const DetailsPopupWrapper = ({ children, id }) => {
  const state = useContext(ProductsContext);
  const { subscriptions, detailCardBtnText, rateText } = state;

  const chosenProduct = subscriptions.find((subscription) => subscription.id === id) || {};
  const { price, name, paymentPeriod, partnerDetails = {} } = chosenProduct;
  const { extDescription, logoPath, partnerCardLink } = partnerDetails;

  const detailsRatePrice = `${price} ${paymentPeriod}`;

  return cloneElement(children, {
    logoPath,
    name,
    rateText,
    price: detailsRatePrice,
    extDescription,
    partnerCardLink,
    detailCardBtnText,
  });
};

DetailsPopupWrapper.propTypes = {
  children: PropTypes.elementType,
  id: PropTypes.number,
};

export { DetailsPopupWrapper };
