import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Notification } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const NotificationContent = ({ text, handleNotification }) => (
  <div className={cx('wrapper')}>
    <Notification isWithCloseButton onClick={handleNotification} text={text} variant="primary" />
  </div>
);

NotificationContent.propTypes = {
  text: PropTypes.string,
  handleNotification: PropTypes.func,
};

export { NotificationContent };
