import { isInPage } from 'utils/isInPage';

const scrollToBlock = (elementId, scrollParams = { block: 'start', behavior: 'smooth' }) => {
  if (window !== undefined && isInPage(document.getElementById(elementId))) {
    document.getElementById(elementId).scrollIntoView(scrollParams);
  }
};

export default scrollToBlock;

export const windowScrollToBlock = (elementId, scrollParams = { behavior: 'smooth' }) => {
  if (window !== undefined && isInPage(document.getElementById(elementId))) {
    const headerHeight = document.querySelector('header')?.offsetHeight || 0;
    const elementOffsetTop = document.getElementById(elementId).offsetTop || 0;
    const baseOffset = 20;

    const scrollValue = elementOffsetTop - headerHeight - baseOffset;

    window.scrollTo({
      top: scrollValue,
      ...scrollParams,
    });
  }
};
