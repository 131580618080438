import { createSelector } from '@reduxjs/toolkit';

const getChangeState = (state) => state.change;

export const getIsChangeNumber = (state) => getChangeState(state)?.isChangeNumber;
export const getCurrentNumber = createSelector(getChangeState, (state) => state.currentNumber);
export const getIsAwaitingSmsCode = createSelector(
  getChangeState,
  (state) => state.isAwaitingSmsCode,
);
export const getIsSuccess = createSelector(getChangeState, (state) => state.isSuccess);
export const getIsLoadingSms = createSelector(getChangeState, (state) => state.isLoadingSms);
export const getError = createSelector(getChangeState, (state) => state.error);
