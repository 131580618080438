import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

import { TTitleContent, TitleContent } from '../../commonComponents/TitleContent';
import { TServiceTile } from '../../../tempComponents/ServiceTile/types';
import { ServicesContainer } from './components/ServicesContainer';
import { ServiceContainer } from './components/ServiceContainer';
import { TServiceType } from './tempYmUtils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TServiceTiles = {
  children?: ReactNode;
  services: TServiceTile[];
  showServices?: boolean;
  titleContentProps: Partial<TTitleContent>;
  type?: TServiceType;
};

/** Компонент обертка для вывода плиток доп. услуг ServiceTile */
export const ServiceTiles: FC<TServiceTiles> = ({
  type,
  services,
  children,
  showServices = true,
  titleContentProps,
}) => (
  <section className={cx('wrapper')}>
    <TitleContent {...titleContentProps} {...{ type }} />
    {children}
    {services?.length && showServices ?
      <ServicesContainer>
        {services?.map((service) => (
          <ServiceContainer key={service.title} {...{ service, type }} />
        ))}
      </ServicesContainer>
    : null}
  </section>
);
