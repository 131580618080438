import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { MONTH_UNIT, PRICE_UNIT } from '../../constants';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TFmcDescription = {
  oldPrice?: number;
  price?: number;
};

/** Описание пресета ШПД.
 * Компонент приходится использовать из-за очень негибкой настройки ChipsStateless,
 * который не имеет возможности добавлять к тексту перечеркивание */
export const FmcDescription: FC<TFmcDescription> = ({ price = 0, oldPrice }) => (
  <div className={cx('wrapper', { wrapper__discount: oldPrice })}>
    {String(price) && `${price} ${PRICE_UNIT}`}
    {!!oldPrice && (
      <>
        <span className={cx('wrapper--crossed')}>{`${oldPrice} ${PRICE_UNIT}`}</span>
        {`${MONTH_UNIT}`}
      </>
    )}
  </div>
);
