import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Button, Link } from '@beef/legacy-ui-kit';

import { numberToHtml } from 'utils/fancyNumberBeautifier';
import InfoPopup from 'pages-components/InfoPopup';
import EmptyButton from 'pages-components/EmptyButton';
import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FancyNumberPopup = ({ number, fetchUrl, onClose, onRefreshClick, onClickMNP }) => {
  const [rotate, increaseRotate] = useState(0);

  return (
    <InfoPopup
      fetchUrl={fetchUrl || '/customers/products/mobile/tariffs/info-popup/'}
      onClose={onClose}
    >
      <div className={cx('number')}>
        <span dangerouslySetInnerHTML={{ __html: numberToHtml({ number }) }} />
        <EmptyButton
          className={cx('button')}
          onClick={() => {
            onRefreshClick();
            increaseRotate(rotate + 1);
          }}
          style={{ transform: `rotate(${360 * rotate}deg)` }}
        >
          <Icon name="arrowCircled" />
        </EmptyButton>
      </div>
      <Button className="big" onClick={onClose} transformer>
        Сохранить выбор
      </Button>
      <div className={cx('links')}>
        <Link href="/customers/products/mobile/services/details/mnp/">
          Посмотреть другие номера
        </Link>
        <Link
          onClick={() => {
            onClose();
            onClickMNP();
          }}
        >
          Перейти в билайн со своим номером
        </Link>
      </div>
    </InfoPopup>
  );
};

FancyNumberPopup.propTypes = {
  number: PropTypes.number,
  fetchUrl: PropTypes.string,
  onClose: PropTypes.func,
  onRefreshClick: PropTypes.func,
  onClickMNP: PropTypes.func,
};

export default FancyNumberPopup;
