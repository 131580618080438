import React, { Children, cloneElement, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import PopupStateless from 'pages-components/Popup';

// Styles
import Regular from '../Regular';
import styles from '../styles.pcss';

const cx = classNames.bind(styles);

const GroupRowButton = ({ paramsList, isButton, children }) => {
  const [showPopup, toggleShowPopup] = useState(false);
  const closePopup = () => {
    toggleShowPopup(false);
  };

  return (
    <>
      <div className={cx('rowButtonParamsGroup')}>
        {isButton && (
          <>
            <EmptyButton
              className={cx('outerButton', 'clickable')}
              onClick={() => {
                toggleShowPopup(true);
              }}
            />
            <div className={cx('row')} />
          </>
        )}
        {paramsList.map((item, key) => (
          <Regular className={cx('groupItem')} key={key} {...item} />
        ))}
      </div>
      <PopupStateless onClose={closePopup} opened={showPopup}>
        {showPopup && Children.map(children, (child) => cloneElement(child, { closePopup }))}
      </PopupStateless>
    </>
  );
};

GroupRowButton.propTypes = {
  isButton: PropTypes.bool,
  children: PropTypes.object,
  paramsList: PropTypes.array.isRequired,
};

export default GroupRowButton;
