export function getHostName(url) {
  const hostName = new URL(url).hostname;
  const parts = hostName.split('.');
  return parts[parts.length - 2];
}

export function capitalizeFirstLetter(string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
}

export const getStoreName = (link) => capitalizeFirstLetter(getHostName(link));
