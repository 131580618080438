import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TMnpNumber = {
  description?: string;
  onChange?: () => void;
  phone?: string;
};

/** Блок с выбранным номером MNP */
export const MnpNumber: FC<TMnpNumber> = ({ phone, onChange, description }) => (
  <button className={cx('wrapper')} onClick={onChange} type="button">
    {phone && (
      <Text align="left" color="primary" lineHeight={24} size="xs" weight="regular">
        {phone}
      </Text>
    )}
    {description && (
      <Text align="left" color="secondary" lineHeight={24} size="xs" weight="regular">
        {description}
      </Text>
    )}
  </button>
);
