import { useAppDispatch } from '../../store';
import { EDisableNextEvent } from '../../store/slices/stepperSlice/types';
import {
  disableNext as disableNextAction,
  enableNext as enableNextAction,
} from '../../store/slices/stepperSlice';
import { useDisabledNextList } from './useDisabledNextList';

/** Хук получения данных степпера и методами управления переходов */
export const useDisableNext = (event: EDisableNextEvent) => {
  const dispatch = useAppDispatch();

  const disabledNextList = useDisabledNextList();
  const isDisabledNextEvent = disabledNextList.includes(event);

  const disableNext = () => dispatch(disableNextAction(event));
  const enableNext = () => dispatch(enableNextAction(event));
  const toggleNext = () =>
    isDisabledNextEvent ? dispatch(enableNextAction(event)) : dispatch(disableNextAction(event));

  return {
    isDisabledNextEvent,
    disableNext,
    enableNext,
    toggleNext,
  };
};
