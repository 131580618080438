import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { pushApplePayAction } from 'utils/ga';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const postJSON = (url, body) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.onload = function () {
      try {
        resolve(JSON.parse(xhr.response));
      } catch (error) {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send(JSON.stringify(body));
  });

class ApplePayButton extends Component {
  state = { isVisible: false };

  componentDidMount() {
    const { ApplePaySession } = window;

    try {
      if (ApplePaySession && ApplePaySession.canMakePayments()) {
        setTimeout(() => this.setState({ isVisible: true }));
        this.props.onShown();
        pushApplePayAction('show');
      }
    } catch (error) {
      console.warn(error.message);
    }
  }

  handleClick = async () => {
    const { ApplePaySession } = window;
    const {
      label,
      sum,
      paymentData,
      paymentType,
      onSuccess,
      onError,
      onReset,
      onClick,
      processPaymentUrl,
      mapProcessPaymentReq,
      mapProcessPaymentRes,
    } = this.props;
    const number = paymentData.ctn || paymentData.homeLogin;

    const session = new ApplePaySession(1, {
      countryCode: 'RU',
      currencyCode: 'RUB',
      merchantCapabilities: ['supports3DS'],
      requiredShippingContactFields: ['email'],
      supportedNetworks: ['masterCard', 'visa'],
      total: {
        label,
        amount: sum,
      },
    });

    try {
      await onClick('Apple Pay');
    } catch (err) {
      onReset();
      return;
    }

    pushApplePayAction('click');

    session.onvalidatemerchant = (event) => {
      postJSON('/applepay/getsession', {
        url: event.validationURL,
        paymentType,
      })
        .then((response) => {
          session.completeMerchantValidation(response);
        })
        .catch(() => {
          session.completeMerchantValidation({});
          onError({ isServerError: true });
        });
    };

    session.onpaymentauthorized = (event) => {
      postJSON(
        processPaymentUrl,
        mapProcessPaymentReq({
          number,
          sum,
          paymentType,
          applePayPaymentObject: event.payment,
        }),
      )
        .then((response) => {
          const resp = mapProcessPaymentRes(response);
          session.completePayment(ApplePaySession.STATUS_SUCCESS);
          pushApplePayAction('success', sum);

          if (resp.isSuccess) {
            onSuccess();
          } else {
            onError({ isServerError: false });
          }
        })
        .catch(() => {
          session.completePayment(ApplePaySession.STATUS_FAILURE);
          onError({ isServerError: true });
        });
    };

    session.begin();
  };

  render() {
    const { isVisible } = this.state;
    const { className, disabled, hidden } = this.props;

    if (!isVisible) return null;

    return (
      <button
        className={cx('applePayButton', className, { hidden })}
        disabled={disabled}
        onClick={this.handleClick}
        type="button"
      />
    );
  }
}

ApplePayButton.propTypes = {
  className: PropTypes.any,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  sum: PropTypes.number,
  paymentData: PropTypes.object,
  onShown: PropTypes.func,
  onClick: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onReset: PropTypes.func,
  paymentType: PropTypes.string,
  processPaymentUrl: PropTypes.string,
  mapProcessPaymentReq: PropTypes.func,
  mapProcessPaymentRes: PropTypes.func,
  hidden: PropTypes.bool,
};

ApplePayButton.defaultProps = {
  processPaymentUrl: '/applepay/processpayment',
  mapProcessPaymentReq: (e) => e,
  mapProcessPaymentRes: (e) => e,
};

export default ApplePayButton;
