import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Dropdown } from '@beef/ui-kit';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';

import { setParametersAction } from 'pages/SearchResultsPage/store/actions';
import { dropdownSortOptions } from 'pages/SearchResultsPage/utils/constants';

import styles from './styles.pcss';
import { getSort, getTotalHits } from '../../../store/selectors';

const cx = classNames.bind(styles);

const _Sort = ({ sort, onChangeSort, onChange }) => (
  <div className={cx('container')}>
    <Dropdown
      onChange={(val) => {
        onChangeSort(val);
        onChange?.(val);
      }}
      options={dropdownSortOptions}
      value={sort}
    />
  </div>
);

_Sort.propTypes = {
  sort: PropTypes.string,
  onChange: PropTypes.func,
  onChangeSort: PropTypes.func,
};

const mapStateToProps = (state) => ({
  sort: getSort(state),
  shouldRender: !!getTotalHits(state),
});

const mapDispatchToProps = (dispatch) => ({
  onChangeSort: (params) => dispatch(setParametersAction('sort', params)),
});

export const Sort = compose(connect(mapStateToProps, mapDispatchToProps), withRenderFlag)(_Sort);
