import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';

// Style
import styles from '../styles.pcss';

const cx = classNames.bind(styles);

const ResultStep = ({ onClose, data: { title, contentText, buttonText, icon } }) => (
  <div>
    {icon && <Picture alt={title} className={cx('icon')} src={icon} />}
    <Heading isStyled={false} level={1}>
      <span dangerouslySetInnerHTML={{ __html: title }} />
    </Heading>
    <div className={cx('content', 'center')}>
      <p dangerouslySetInnerHTML={{ __html: contentText }} />
      <Button className={[cx('button'), cx('big')]} onClick={onClose} transformer>
        {buttonText}
      </Button>
    </div>
  </div>
);

ResultStep.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.shape({
    title: PropTypes.string,
    contentText: PropTypes.string,
    buttonText: PropTypes.string,
    icon: PropTypes.string,
  }),
};

export default ResultStep;
