import React, { forwardRef } from 'react';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { formatIntoHundredths } from 'utils/format-string';

import { TPresetPickerTile } from './types';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

/** Компонент-кнопка пресета ЕК. Содержит визуальную информацию по пресету (Гм, Мин и т.п.) */
export const PresetPickerTile = forwardRef<HTMLButtonElement, TPresetPickerTile>(
  ({ isActive, isOffer, size, icon, onChange }, ref) => {
    return (
      <button
        className={cx('button', { 'button--active': isActive })}
        key={size}
        onClick={() => onChange(size)}
        ref={isActive ? ref : null}
        type="button"
      >
        <Text className={cx('text', { 'text--active': isActive })} size="size3-m">
          {formatIntoHundredths(size)}
        </Text>
        {isOffer && (
          <div className={cx('gift', { 'gift--active': isActive })}>
            <img alt="" src={icon} />
          </div>
        )}
      </button>
    );
  },
);
