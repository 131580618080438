import { Benefits } from './Benefits';
import { TransferConditions } from './TransferConditions';
import { TransferSteps } from './TransferSteps';

export { FaqWrapper as Faq } from './Faq';
export { Form } from './Form';
export { Header } from './Header';

export const blockComponents = {
  benefits: Benefits,
  transferSteps: TransferSteps,
  transferConditions: TransferConditions,
};
