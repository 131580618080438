import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openAuthModal } from '@beef/layout-kit/utils';

import { selectMetaData } from 'pages/Anniversary/store/selectors';

import { resetStory, selectCurrentStory } from '../story';
import { setStatus } from '../status';
import { postVote } from '../../api';
import { STATUS } from '../../constants';
import { pushStoryVoteError, pushStoryVoteSuccess } from '../../utils/analytics';

export const sendVote = createAsyncThunk('story/sendVote', async (_, { dispatch, getState }) => {
  const story = selectCurrentStory(getState());
  try {
    await postVote(story.id);
    dispatch(updateVoteAvailable(story.region));
    dispatch(setStatus(STATUS.voteSubmitted));
    pushStoryVoteSuccess(story);
  } catch (err) {
    dispatch(setStatus(STATUS.commonError));
    pushStoryVoteError();
  } finally {
    dispatch(resetStory());
  }
});

export const submitVote = () => (dispatch, getState) => {
  const { isAuthorized } = selectMetaData(getState());
  if (!isAuthorized) {
    dispatch(resetStory());
    openAuthModal();
    return;
  }
  dispatch(sendVote());
};

const initialState = {
  available: {},
  isLoading: false,
};

const votesSlice = createSlice({
  name: 'votes',
  initialState,
  reducers: {
    updateVoteAvailable(state, action) {
      state.available[action.payload] = false;
    },
  },
  extraReducers: {
    [sendVote.pending]: (state) => {
      state.isLoading = true;
    },
    [sendVote.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [sendVote.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { updateVoteAvailable } = votesSlice.actions;
export default votesSlice.reducer;
export * from './selectors';
