import { formatNumber } from 'utils/fancyNumberBeautifier';
import { getRandomInt } from 'utils/fn';

const mapNumberItem = (number, category, isBought, niceClass) => ({
  value: +number.value,
  formattedValue: number.formattedValue || formatNumber(number.value),
  featureCode: number.featureCode,
  category,
  isBought,
  niceClass,
});

export const mapNumbersResponse = (data, emulateBought) => {
  const numbersByCategories = {};
  data.numbers.forEach(({ alias, numbers, niceClass }) => {
    let boughtIndex = -1;
    if (emulateBought && numbers.length > 2) {
      boughtIndex = getRandomInt(0, numbers.length);
    }

    numbersByCategories[alias] = numbers.map((number, index) =>
      mapNumberItem(number, alias, boughtIndex === index, niceClass),
    );
  });

  return numbersByCategories;
};

const NUMBERS_CATEGORIES = {
  0: 'spetsialnyie',
  1: 'bronzovie',
  2: 'serebryanie',
  3: 'zolotie',
};

export const mapChangeNumbersResponse = (data) => {
  const numbersByCategories = {};
  const { isSucceeded, message } = data;

  if (!isSucceeded || !message?.numbers?.length) {
    return {};
  }

  message?.numbers?.forEach((number) => {
    const category = NUMBERS_CATEGORIES[number.category];

    const mappedNumber = mapNumberItem(
      {
        ...number,
        value: `${number.number}`,
      },
      category,
    );
    if (numbersByCategories[category]) {
      numbersByCategories[category].push(mappedNumber);
    } else {
      numbersByCategories[category] = [mappedNumber];
    }
  });

  return numbersByCategories;
};
