export default function documentReady(callback) {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
      try {
        callback();
      } catch (error) {
        console.error(error);
      }
    });
  } else {
    try {
      callback();
    } catch (error) {
      console.error(error);
    }
  }
}

export const onReady = (cb) => {
  if (typeof window === 'undefined') return;
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    cb();
  } else {
    document.addEventListener('DOMContentLoaded', cb);
  }
};
