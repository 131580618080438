import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ParametersList = ({ parameters, itemWide, itemFixedWidth }) => (
  <ul className={cx('parameters-list')}>
    {parameters.map((param, i) => (
      <li
        className={cx('parameters-list__item', {
          'parameters-list__item--wide': !!itemWide,
          'parameters-list__item--fixed-width': !!itemFixedWidth,
        })}
        key={i}
      >
        {param.value && (
          <div className={cx('param__value')}>
            <Text size="size1-m" tagType="h6">
              <span dangerouslySetInnerHTML={{ __html: param.value }} />
            </Text>
          </div>
        )}
        {param.icon && (
          <div className={cx('param__icon')}>
            <img alt="" src={param.icon} />
          </div>
        )}
        {param.unit && <Text size="size5-r-s">{param.unit}</Text>}
        {param.text && <Text size="size7-r">{param.text}</Text>}
      </li>
    ))}
  </ul>
);

ParametersList.propTypes = {
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      icon: PropTypes.string,
      unit: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  itemWide: PropTypes.bool,
  itemFixedWidth: PropTypes.bool,
};

export default ParametersList;
