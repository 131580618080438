import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { PromotionTag } from './components/PromotionTag';
import { PromotionTagWrapperPropsType } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const PromotionTagWrapper: FC<PromotionTagWrapperPropsType> = ({
  promotionTag,
  tagClassName,
  children,
}) => (
  <div className={cx('wrapper')}>
    {children}
    {promotionTag?.tagText && (
      <PromotionTag {...promotionTag} className={cx('tag', tagClassName)} />
    )}
  </div>
);
