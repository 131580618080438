import React from 'react';
import classNames from 'classnames/bind';

import { Passive } from '../Passive';
import { usePreparePassive } from './hooks/usePreparePassive';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Обертка над компонентом Passive для старого конструктора */
export const PassiveContainer = () => {
  const { passiveProps } = usePreparePassive();
  return (
    <div className={cx('wrapper')}>
      <Passive {...passiveProps} />
    </div>
  );
};
