import { compose, withReduxStore } from '@beef/redux';
import React from 'react';
import { EHeaderTheme } from '@carnica/smart-kit';

import { ChatWidget } from 'widgets/Chat';
import { useHeaderTheme } from 'pages-components/HeaderV2/hooks';

import { initializeStore } from './store';
import { MNPLayout } from './layout/MNPLayout';
import { useMainContent } from './hooks/useMainContent';
import { FAQSection } from './sections/FAQSection';
import { StartSection } from './sections/StartSection';
import { InfoAboutServiceSection } from './sections/InfoAboutServiceSection';
import { MNPForm } from './sections/MNPForm';
import { MobileStickyForm } from './sections/MobileStickyForm';
import { LayoutForHideFixedButton } from './layout/LayoutForHideFixedButton';
import { IFullPageContent } from './types';
import { withMetricContainer } from './analytics';

/**
 * Лендинг редизайна переноса номера в билайн (MNP)
 * ТЗ: https://bwiki.beeline.ru/pages/viewpage.action?pageId=187086957
 * Задача, в которой велась разработка: https://btask.beeline.ru/browse/PEB-5204
 * Дизайн: https://www.figma.com/design/PRIeorhXfGTAjyTlN6lAHD/MNP-landing?node-id=1367-4564&t=rpN3WbQRny89argA-0
 */
const MNPLandingPage = () => {
  const { chatContent, isMobile } = useMainContent();

  /** Устанавливаем primary тему для шапки */
  useHeaderTheme(EHeaderTheme.primary);

  return (
    <MNPLayout>
      {/* Стартовый экран */}
      <StartSection />

      {/* Лайаут, под который прячется зафиксированная кнопка на мобильном разрешении */}
      <LayoutForHideFixedButton>
        {/* Форма МНП */}
        <MNPForm />

        {/* Информация об услуге */}
        <InfoAboutServiceSection />
      </LayoutForHideFixedButton>

      {/* Зафиксированная кнопка */}
      {isMobile && <MobileStickyForm />}

      {/* Блок FAQ */}
      <FAQSection />

      {/* Чат */}
      {!chatContent?.disableChatWidget && <ChatWidget config={chatContent?.config} />}
    </MNPLayout>
  );
};

export const MNPLanding = compose(
  withReduxStore(initializeStore, {
    passThroughProps: ({ data }: IFullPageContent) => {
      return data;
    },
    mapPropsToState: ({ data }: IFullPageContent) => {
      return {
        common: data.extData.targetRedesignData,
        additionalData: {
          id: data.id,
          isMnpService: data.isMnpService,
          isAuth: data.isAuthenticated,
        },
      };
    },
  }),
  withMetricContainer,
)(MNPLandingPage);
