import { Button, Text } from '@beef/ui-kit';
import React from 'react';

import { ModalsControllerProps } from './ModalsController/types';
import { ActionBarButtons, ResponseLayout } from './components';

type DeactivateModalDataProps = Pick<ModalsControllerProps, 'onClose' | 'onDeactivateLimit'> & {
  data: ModalsControllerProps['data']['deactivate'];
  isLoading: boolean;
};

export const useDeactivateModalData = ({
  onDeactivateLimit,
  onClose,
  data,
  isLoading,
}: DeactivateModalDataProps) => {
  const { title, description, buttons } = data || {};
  const { deactivate, cancel } = buttons || {};
  return {
    content: (
      <ResponseLayout
        description={
          <Text color="grey60" size="size5-r-l">
            {description}
          </Text>
        }
        title={<Text size="size3-m">{title}</Text>}
      />
    ),
    actionBar: (
      <ActionBarButtons>
        <Button isLoading={isLoading} onClick={onDeactivateLimit}>
          {deactivate?.title}
        </Button>
        <Button onClick={onClose} variant="tertiary">
          {cancel?.title}
        </Button>
      </ActionBarButtons>
    ),
  };
};
