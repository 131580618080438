import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { Button } from '@beef/ui-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { ymSimMNPAddToCart } from 'utils/analytics/ymCommonEvents';

import { Price } from '../../Price/index';
import { orderRandomNumber } from '../../../store/actions/cart';
import { getCurrentRandomNumber } from '../../../store/selectors/numbers';
import { getMiscContent } from '../../../store/selectors/content';
import { NumberShape } from '../../../shapes';
import { getCartLoading } from '../../../store/selectors/cart';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

const BuyButtonLayout = ({ onClick, number, buyText, disabled }) => {
  const isMobile = useIsMobileDimension();
  const handleClick = () => {
    onClick();
    ymSimMNPAddToCart();
  };

  return (
    <div className={cx('buy-button')}>
      <Button disabled={disabled} fullWidth onClick={handleClick} size={isMobile ? 's' : 'l'}>
        {buyText}
        <span className={cx('buy-button_price', { disabled })}>
          <Price
            color={disabled ? null : 'sienna'}
            discount={number.discount}
            price={number.price}
          />
        </span>
      </Button>
    </div>
  );
};

BuyButtonLayout.propTypes = {
  onClick: PropTypes.func,
  buyText: PropTypes.string,
  number: PropTypes.shape(NumberShape),
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  number: getCurrentRandomNumber(state),
  buyText: getMiscContent(state).buy,
  disabled: !getCurrentRandomNumber(state) || getCartLoading(state),
});

const mapDispatchToProps = {
  onClick: orderRandomNumber,
};

export const BuyButton = connect(mapStateToProps, mapDispatchToProps)(BuyButtonLayout);
