import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Input } from '@beef/ui-kit';
import { compose } from '@beef/redux';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';

import { debounce } from 'utils/timed-functions';
import {
  getIsSelectServiceCategory,
  getPriceCategories,
  getPriceInputValue,
} from 'pages/SearchResultsPage/store/selectors';
import { preparePriceInput } from 'pages/SearchResultsPage/utils/getPriceValues';
import { useIsDesktopDimension } from 'utils/hooks/useGetDimensionGroup';
import { FilterWrapper } from 'pages/SearchResultsPage/components/Filter/FilterWrapper';
import { setInputPriceValue, setPriceAction } from 'pages/SearchResultsPage/store/actions/products';

import { TITLE_PRICE } from '../constants';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _Price = ({ price, setFilter, onClick, currentPrice, onChange, setPrice }) => {
  const isDesktop = useIsDesktopDimension();
  const Wrapper = isDesktop ? FilterWrapper : 'div';
  const timeout = isDesktop ? 2500 : 500;
  const setInput = useCallback(
    debounce(({ min, max }) => {
      const correctPrice = preparePriceInput({ min, max, price });
      setPrice(correctPrice);
    }, timeout),
    [price, timeout],
  );

  const setFilterPrice = useCallback(
    debounce((value) => {
      setFilter(value);
      onChange?.(value);
    }, timeout),
    [price, timeout],
  );

  const onChangeValue = ({ target: { id, value } }, isPreparePrice) => {
    const result = value.replace(/\D/g, '');
    if (currentPrice[id] !== result || !isDesktop) {
      const newValue = { ...currentPrice, [id]: result };
      setPrice(newValue);
      if (isDesktop || isPreparePrice) {
        setInput(newValue);
        setFilterPrice(newValue);
      }
    }
  };

  const onBlur = (e) => {
    onChangeValue(e, true);
  };

  return (
    <Wrapper
      className={cx('container')}
      isShowChevron
      onClick={onClick}
      title={isDesktop ? TITLE_PRICE : undefined}
    >
      {!isDesktop && <div className={cx('title')}>{TITLE_PRICE}</div>}
      <div className={cx('price')}>
        {Object.keys(price).map((key) => (
          <div className={cx('input')} key={key}>
            <Input
              id={key}
              onBlur={isDesktop ? undefined : onBlur}
              onChange={onChangeValue}
              placeholder={price[key]}
              value={currentPrice[key]}
            />
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

_Price.propTypes = {
  price: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  setFilter: PropTypes.func,
  currentPrice: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  onClick: PropTypes.func,
  setPrice: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  setFilter: (val) => dispatch(setPriceAction(val)),
  setPrice: (val) => dispatch(setInputPriceValue(val)),
});

const mapStateToProps = (state) => ({
  price: getPriceCategories(state),
  currentPrice: getPriceInputValue(state),
  shouldRender: !getIsSelectServiceCategory(state),
});

export const Price = compose(connect(mapStateToProps, mapDispatchToProps), withRenderFlag)(_Price);
