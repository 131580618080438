import React from 'react';
import { createPortal } from 'react-dom';
import { Bullets as KITBullets } from '@carnica/smart-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { BulletsType } from './types';

const cn = classNames.bind(styles);

export const Bullets: React.FC<BulletsType> = ({ containerRef, ...otherProps }) => {
  if (window?.sliderBulletsRef?.current) {
    return createPortal(
      <div className={cn('buttons-wrap')}>
        <KITBullets
          {...otherProps}
          activeBulletClassName={cn('active-bullet')}
          bulletClassName={cn('bullet')}
          bulletsClassName={cn('bullets')}
        />
      </div>,
      window?.sliderBulletsRef?.current,
    );
  }
  return null;
};
