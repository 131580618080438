import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@carnica/ui-kit';

import { IconChevronLeft } from 'constructorV1/components/tempComponents/IconChevronLeft';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type THeader = {
  onClick?: () => void;
  title?: string;
};

/** Компонент с заголовком MNP формы и кнопкой возврата */
export const Header: FC<THeader> = ({ title, onClick }) => (
  <div className={cx('wrapper')}>
    <Button iconLeft={IconChevronLeft} onClick={onClick} onlyIcon view="secondary-invert" />
    {title && (
      <Text align="left" color="primary" lineHeight={24} size="m" weight="regular">
        {title}
      </Text>
    )}
  </div>
);
