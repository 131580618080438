import { createSelector } from '@reduxjs/toolkit';

import { selectBillContent } from 'constructorV1/store/selectors/selectCommon';

/** Селектор формирования данных в кнопке чека */
export const selectButtonProps = createSelector(selectBillContent, (billContent) => {
  /* Контентная часть из common */
  const { btnText: totalBtnText } = billContent.totalAmountContent;

  return {
    /* Основной текст кнопки */
    btnText: totalBtnText,
  };
});
