import axios from 'axios';

import { createMnpOrderUrl } from 'pages/MnpOrderNew/constants';

export const createMnpOrder = (phone: string, region: string) =>
  axios.post(createMnpOrderUrl, {
    leadName: 'PodborSim',
    phone,
    region,
  });
