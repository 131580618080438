import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { getHasErrorInHash, getToken } from 'pages/PartnerServiceCard2022/utils';

import { sendPostRequestWithId } from '../../api';
import { EModalType, IPartnerServiceCardState, TYandexFinishActivationResponse } from '../../types';
import { ON_MOUNT_ACTION, SET_HAS_MOUNTED } from '../constants';
import { selectFinishActivationEndpoint, selectHasMounted } from '../selectors';
import { modalSlice } from '../slices';

export const setHasMounted = createAction(SET_HAS_MOUNTED);

export const onMountThunk = createAsyncThunk<void, void, { state: IPartnerServiceCardState }>(
  ON_MOUNT_ACTION,
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const { dispatch } = thunkApi;

    try {
      const hasMounted = selectHasMounted(state);

      if (hasMounted) return;
      dispatch(setHasMounted());

      const { data } = state;
      const token = getToken();
      const endpoint = selectFinishActivationEndpoint(data, token);
      const hasError = getHasErrorInHash();

      if (hasError) {
        throw new Error('There is error field in the URL search parameters');
      }

      if (!token || !data.id) return;

      const res = (await sendPostRequestWithId(data.id, endpoint, {
        yandexToken: token,
      })) as TYandexFinishActivationResponse;

      if (!res || res.isSucceeded === false || res.data?.success === false) {
        throw new Error('Unsuccessful finish activation response');
      }

      dispatch(modalSlice.actions.openModal(EModalType.ActivationSuccess));
    } catch (e) {
      console.error(e);
      dispatch(modalSlice.actions.openModal(EModalType.DefaultError));
    }
  },
);
