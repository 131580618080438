import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ActionBar, Link } from '@beef/ui-kit';

export const ModalActionBar = memo(({ size, buttons }) => (
  <ActionBar size={size}>
    {buttons[0] && (
      <Link buttonVariant="tertiary" href={buttons[0]?.url} variant="button">
        {buttons[0]?.title}
      </Link>
    )}
    {buttons[1] && (
      <Link buttonVariant="tertiary" href={buttons[1]?.url} variant="button">
        {buttons[1]?.title}
      </Link>
    )}
  </ActionBar>
));

ModalActionBar.propTypes = {
  size: PropTypes.string,
  buttons: PropTypes.arrayOf({
    url: PropTypes.string,
    title: PropTypes.string,
  }),
};
