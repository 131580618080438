import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { path } from 'ramda';
import { TextInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { debounce } from 'utils/timed-functions';
import store from 'store';

import { fetchHouses, fetchRegions, fetchStreets } from '../../actions/actions';
import { SET_ALLINONE_VALUE } from '../../actions/actionTypes';
import styles from './Dropdown.pcss';

const cx = classNames.bind(styles);

class Dropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputRef: null,
      focus: false,
      value: '',
      activeEntry: null,
    };

    const delay = props.type === 'house' ? 0 : 200;

    this.handleUpdateInput = debounce(this.handleUpdateInput.bind(this), delay);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOuterClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOuterClick);
  }

  handleOuterClick = (event) => {
    const { setValue, entities, resetValue } = this.props;
    let { value } = this.state;

    if (event.target !== this.state.inputRef && this.state.focus) {
      if (value) {
        if (entities && entities.length) {
          value = entities[0].label;
          setValue(entities[0]);
        } else {
          resetValue(value);
        }
      } else {
        resetValue();
      }
      this.setState({
        focus: false,
        value,
      });
    }
  };

  handleGetRef = (inputRef) => {
    this.setState({
      inputRef,
    });
  };

  handleFocus = () => {
    this.setState({
      focus: true,
    });
  };

  handleKeyDown = (event) => {
    const { activeEntry } = this.state;
    const { entities } = this.props;

    if (['ArrowDown', 'ArrowUp', 'Enter'].indexOf(event.key) === -1) {
      return;
    }

    event.preventDefault();

    if (event.key === 'ArrowDown') {
      let newActiveEntry = activeEntry + 1;
      if (entities && newActiveEntry > entities.length) {
        newActiveEntry = entities.length;
      }
      this.setState({ activeEntry: newActiveEntry });
    }

    if (event.key === 'ArrowUp') {
      const newActiveEntry = activeEntry - 1 >= 0 ? activeEntry - 1 : 0;
      this.setState({ activeEntry: newActiveEntry });
    }

    if (event.key === 'Enter' && activeEntry) {
      this.handleSelect(entities[activeEntry - 1]);
    }
  };

  handleSelect = (entity) => {
    const { setValue, onChange } = this.props;
    onChange(entity, true);
    this.setState(
      {
        focus: false,
        value: entity.label,
        activeEntry: null,
      },
      () => {
        setValue(entity);
      },
    );
  };

  handleUpdateInput(value = '') {
    const { type, region, street, onChange } = this.props;

    this.setState({ value });

    if (type === 'region') {
      store.dispatch(fetchRegions(value));
    }

    if (type === 'street') {
      store.dispatch(fetchStreets(region.id, value));
    }

    if (type === 'house') {
      store.dispatch(fetchHouses(street.id, value));
    }

    if (onChange) {
      onChange(value);
    }
  }

  render() {
    const { type, disabled, entities, value, status, size } = this.props;
    const { activeEntry } = this.state;
    return (
      <div className={cx('layout')}>
        <TextInput
          autoComplete="off"
          clearable
          disabled={disabled}
          getRef={this.handleGetRef}
          name={type}
          onChange={this.handleUpdateInput}
          onClear={this.handleUpdateInput}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          ref={this.props.inputRef}
          size={size}
          status={status}
          value={typeof value === 'undefined' ? this.state.value : value}
        />
        {entities.length ?
          <ul className={cx('dropdown', { focus: this.state.focus })}>
            {entities.map((entity, index) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                className={cx('dropdownItem', { activeEntry: index === activeEntry - 1 })}
                key={index}
                onClick={() => {
                  this.handleSelect(entity);
                }}
              >
                {entity.label}
              </li>
            ))}
          </ul>
        : null}
      </div>
    );
  }
}

Dropdown.defaultProps = {
  region: {},
  street: {},
  entities: [],
  disabled: false,
  status: 'ok',
  size: 'default',
};

Dropdown.propTypes = {
  type: PropTypes.oneOf(['region', 'street', 'house']).isRequired,
  size: PropTypes.oneOf(['default', 'big']),
  setValue: PropTypes.func.isRequired,
  resetValue: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  entities: PropTypes.array,
  region: PropTypes.object,
  street: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  inputRef: PropTypes.func,
  status: PropTypes.string,
};

const mapStateToProps = ({ external }, props) => {
  // переменная опреляет невозможность использования dropdown
  // dropdown бывает трех типов region, street, house.
  // Соответственно для редактирования разных частей адреса
  // Условие говорит, что dropdown типа street недоступен, если не выбран регион
  // или dropdown типа house недоступен, если не выбрана улица
  const disabled =
    (props.type === 'street' &&
      (!path(['allInOne', 'region', 'id'], external) ||
        path(['allInOne', 'tariffNotFoundInRegion'], external))) ||
    (props.type === 'house' && !path(['allInOne', 'street', 'id'], external));

  return {
    ...external.allInOne,
    disabled,
    entities: path(['allInOne', `${props.type}s`], external),
  };
};

const REGION_TO_PAYLOAD_NAME = {
  region: 'regions',
  street: 'streets',
  house: 'houses',
};

const mapDispatchToProps = (dispatch, props) => ({
  setValue: (entity) => {
    const payload = {};
    if (props.type in REGION_TO_PAYLOAD_NAME) {
      const element = REGION_TO_PAYLOAD_NAME[props.type];
      payload[element] = [entity];
    } else {
      throw new Error(`There is no implementation for type ${props.type}`);
    }

    dispatch({
      type: SET_ALLINONE_VALUE,
      resetError: true,
      payload: {
        [props.type]: entity,
        [`${props.type}IsNotFound`]: false,
        ...payload,
      },
    });
  },
  resetValue: (value = '') => {
    const streetPayload = {
      street: {},
      streets: [],
    };

    const regionPayload = {
      region: {},
      regions: [],
    };

    let payload = {
      [`${props.type}IsNotFound`]: !!value,
      house: {},
      houses: [],
      flat: '',
    };

    switch (props.type) {
      case 'region':
        payload = { ...payload, ...regionPayload, ...streetPayload };
        break;
      case 'street':
        payload = { ...payload, ...streetPayload };
        break;
      case 'house':
        break;
      default:
        throw new Error(`There is no implementation for type ${props.type}`);
    }
    store.dispatch({
      type: SET_ALLINONE_VALUE,
      payload,
    });
  },
});

const ConnectedDropdown = connect(mapStateToProps, mapDispatchToProps)(Dropdown);

export default (props) => (
  <Provider store={store}>
    <ConnectedDropdown {...props} />
  </Provider>
);
