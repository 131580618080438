import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Tabs } from '@beef/ui-kit';
import { fetchLoginModel } from '@beef/layout-kit/actions';

import SMSInitialForm from './SMSInitialForm';
import SMSCodeForm from './SMSCodeForm';
import HiddenInputs from '../HiddenFields/HiddenFields';
import PasswordForm from './PasswordForm';
import { MobileIdInitialForm } from './MobileIdInitialForm';
import { SET_AUTH_CTN, SET_AUTH_STATE } from '../../actions/actionTypes';
import { AUTH_STEPS, ID_CONFIG, MESSAGES } from '../../actions/constants';
import {
  onCheckMobileIdAvailable,
  onResetPassword,
  onSetLoading,
  onStepChange,
  onSubmitMobileId,
} from '../../actions/authorization';
import styles from './styles.pcss';
import { YMAuthEvent } from '../../utils/ym';

const cx = classNames.bind(styles);

class InitialForm extends Component {
  state = {
    needValidation: false,
    focused: false,
    error: '',
    serviceError: '',
    tabValue: this.props.withMobileId ? 'mobileIdInitial' : 'sms',
    actualTabsList: this.props.data.initialForm.tabsList,
  };

  componentDidMount() {
    this.props.fetchLoginReactModel();
    if (!this.props.withMobileId) {
      const newTabsListResult = this.props.data.initialForm.tabsList.filter(
        (item) => item.id !== 'mobileIdInitial',
      );
      this.setState({ actualTabsList: newTabsListResult });
    }
    if (this.state.tabValue === 'sms') {
      this.props.handleStepChange(AUTH_STEPS.smsInitial);
      this.setState({ tabValue: 'sms' });
    }
    if (this.state.tabValue === 'mobileIdInitial') {
      this.props.handleStepChange(AUTH_STEPS.mobileIdInitial);
      this.setState({ tabValue: 'mobileIdInitial' });
    }
    if (this.props.step === AUTH_STEPS.password) {
      this.props.handleStepChange(AUTH_STEPS.password);
      this.setState({ tabValue: 'password' });
    }
    if (this.props.step === AUTH_STEPS.smsCode) {
      this.props.handleStepChange(AUTH_STEPS.smsCode);
      this.setState({ tabValue: 'sms' });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.serviceError && prevState.tabValue !== this.state.tabValue) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        serviceError: '',
      });
    }
    if (this.state.serviceError && prevProps.ctn !== this.props.ctn) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        serviceError: '',
      });
    }
  }

  setErrors = ({ error = '', serviceError = '' }) => {
    if (error || serviceError) {
      YMAuthEvent.error('mob_id', error || serviceError);
    }
    this.setState({
      error,
      serviceError,
    });
  };

  handleChangeTab = (id) => {
    this.setState({ tabValue: id });
    if (id === 'password') {
      this.props.handleStepChange(AUTH_STEPS.password);
    }
    if (id === 'mobileIdInitial') {
      this.props.handleStepChange(AUTH_STEPS.mobileIdInitial);
    }
    if (id === 'sms') {
      this.props.handleStepChange(AUTH_STEPS.smsInitial);
    }
    if (id !== 'sms') {
      this.props.handleChangeSmsStep('');
    }
  };

  handleMobileIdAuth = async () => {
    const { checkMobileIdAvailable, ctn, setLoading, setMobileIdToken, checkIsBeeline } =
      this.props;

    this.setErrors({});
    setMobileIdToken();

    if (!ctn.trim().length) {
      this.setErrors({ error: 'Введите номер телефона' });
      return;
    }

    if (!ctn.trim().match(/^[\d ()+-]+$/)) {
      this.setErrors({ error: MESSAGES.AUTH_MOBILE_ID_INVALID_CTN });
      return;
    }

    const { requiredSendSms, isBeelineCtn } = await checkMobileIdAvailable(ctn);
    if (!isBeelineCtn) {
      YMAuthEvent.mobileIdNotBeeline();
    }
    const onSuccess = (data) => {
      if (data.validationPassed && data.mobileidtoken) {
        setMobileIdToken(data.mobileidtoken);
      } else {
        this.setErrors({ serviceError: MESSAGES.AUTH_MOBILE_ID_INVALID_CTN });
      }
    };

    const onError = () => {
      this.setErrors({ serviceError: MESSAGES.AUTH_MOBILE_ID_DEFAULT_ERROR });
    };

    if (
      (requiredSendSms && checkIsBeeline && isBeelineCtn) ||
      (requiredSendSms && !checkIsBeeline)
    ) {
      setLoading(true);
      await this.props.handleSubmitMobileId(onSuccess, onError);
      setLoading(false);
    } else {
      this.setErrors({
        serviceError:
          !isBeelineCtn ? MESSAGES.MOBILE_ID_NOT_BEELINE : MESSAGES.AUTH_MOBILE_ID_INVALID_CTN,
      });
    }
  };

  render() {
    const {
      ctn,
      login,
      password,
      submitCTN,
      links,
      withMobileId,
      loading,
      autofocus,
      requestSMS,
      head,
      step,
      action,
      fetchingSMS,
      authError,
      errorMessage,
      smsBlocked,
      firstTimeLogin,
      fields,
      handleChangeCTN,
      handleChangeTab,
      fetchLoginReactModel,
      setAuthCtn,
      setAuthLogin,
      setAuthPassword,
      handleInput,
      handleChangeSmsStep,
      resetPassword,
      checkIsBeeline,
    } = this.props;

    const { tabValue, serviceError, actualTabsList } = this.state;
    const data = this.props.data || {};
    const initialForm = data.initialForm || {};
    const { sms, mobileIdInitial } = initialForm;

    return (
      <div className={cx('initial-form')}>
        <div
          className={cx('initial-form__tabs', {
            'initial-form__tabs--dark': tabValue !== 'mobileIdInitial',
          })}
        >
          <Tabs
            isScrollable
            noWrap
            onChange={this.handleChangeTab}
            selectedTab={tabValue}
            tabs={actualTabsList}
            variant="secondary"
          />
        </div>
        <div className={cx('initial-form__tab-content')}>
          {tabValue === 'mobileIdInitial' &&
            step === AUTH_STEPS.mobileIdInitial &&
            withMobileId && (
              <MobileIdInitialForm
                autofocus={autofocus}
                clickMobileIdAuth={this.handleMobileIdAuth}
                ctn={ctn}
                data={mobileIdInitial}
                formId={ID_CONFIG.mobileIdInitial}
                handleChangeCTN={handleChangeCTN}
                handleInput={handleInput}
                loading={loading}
                serviceError={serviceError}
                setAuthCtn={setAuthCtn}
                submitCTN={submitCTN}
              />
            )}
          {tabValue === 'sms' && step === AUTH_STEPS.smsInitial && (
            <SMSInitialForm
              autofocus={autofocus}
              checkIsBeeline={checkIsBeeline}
              ctn={ctn}
              data={sms}
              handleChangeCTN={handleChangeCTN}
              handleChangeTab={handleChangeTab}
              handleInput={handleInput}
              serviceError={errorMessage}
              setAuthCtn={setAuthCtn}
              submitCTN={submitCTN}
            />
          )}
          {step === AUTH_STEPS.smsCode && (
            <SMSCodeForm
              action={action}
              authError={authError}
              ctn={ctn}
              data={sms}
              errorMessage={errorMessage}
              fetchingSMS={fetchingSMS}
              handleChangeSmsStep={handleChangeSmsStep}
              handleInput={handleInput}
              head={head}
              links={links}
              requestSMS={requestSMS}
              smsBlocked={smsBlocked}
            >
              <HiddenInputs fields={fields} firstTimeLogin={firstTimeLogin} isMobileClient />
            </SMSCodeForm>
          )}
          {(tabValue === 'password' || step === AUTH_STEPS.password) && (
            <PasswordForm
              action={action}
              autofocus={autofocus}
              data={data}
              formId={ID_CONFIG.password}
              handleInput={handleInput}
              links={links}
              login={login}
              onResetPassword={resetPassword}
              password={password}
              setAuthLogin={setAuthLogin}
              setAuthPassword={setAuthPassword}
            >
              <HiddenInputs fields={fields} />
            </PasswordForm>
          )}
        </div>
      </div>
    );
  }
}

InitialForm.defaultProps = {
  setMobileIdToken: () => {},
};

InitialForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,

  links: PropTypes.element,

  ctn: PropTypes.string,
  login: PropTypes.string,
  password: PropTypes.string,
  step: PropTypes.string,

  autofocus: PropTypes.bool,
  loading: PropTypes.bool,
  withMobileId: PropTypes.bool,

  submitCTN: PropTypes.func,
  handleChangeCTN: PropTypes.func,
  checkMobileIdAvailable: PropTypes.func,
  setLoading: PropTypes.func,
  setMobileIdToken: PropTypes.func,
  handleStepChange: PropTypes.func,
  setAuthPassword: PropTypes.func,
  setAuthLogin: PropTypes.func,
  handleSubmitMobileId: PropTypes.func,
  handleChangeSmsStep: PropTypes.func,
  handleInput: PropTypes.func,
  resetPassword: PropTypes.func,
  action: PropTypes.string,
  requestSMS: PropTypes.func,
  setAuthCtn: PropTypes.func,
  authError: PropTypes.bool,
  fetchLoginReactModel: PropTypes.func,
};

export default connect(null, {
  handleChangeCTN: (ctn) => (dispatch) => dispatch({ type: SET_AUTH_CTN, payload: ctn }),
  handleChangeTab: () => (dispatch) =>
    dispatch({
      type: SET_AUTH_STATE,
      payload: {
        authError: false,
        errorMessage: '',
      },
    }),
  fetchLoginReactModel: () => (dispatch) => dispatch(fetchLoginModel()),
  checkMobileIdAvailable: onCheckMobileIdAvailable,
  setLoading: onSetLoading,
  handleStepChange: onStepChange,
  handleSubmitMobileId: onSubmitMobileId,
  resetPassword: onResetPassword,
})(InitialForm);
