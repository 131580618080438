export const mapStoriesResponse = ({ result }) =>
  result.map((region) => ({
    region: region.regionName,
    stories: region.stories.map((story) => ({
      ...mapStory(story),
      votesPercentage: (story.votesCount / region.votesCount) * 100,
    })),
  }));

export const mapStoriesFinalResponse = ({ result }) => {
  const totalVotes = result.reduce((total, story) => total + story.votesCount, 0);
  return result.map((story) => ({
    ...mapStory(story),
    votesPercentage: (story.votesCount / totalVotes) * 100,
  }));
};

const mapStory = (story) => ({
  id: story.id,
  image: `https://static.beeline.ru/upload/images/anniversary30/stories/${story.id}.png`,
  city: story.city,
  region: story.region,
  name: story.name || story.author,
  text: story.text,
  video: story.video,
  author: story.author,
  votesCount: story.votesCount,
});
