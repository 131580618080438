import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Text } from '@beef/ui-kit';

import { OptionInfo } from '../OptionInfo';

// Utils
import { getCheckedOption } from './utils';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Options = ({ title, options }) => {
  const hasCheckedOption = getCheckedOption(options);
  return (
    <div className={cx('wrapper')}>
      {title && hasCheckedOption && (
        <div className={cx('wrapper__title')}>
          <Text color="grey95" size="size4-m">
            {title}
          </Text>
        </div>
      )}

      {options?.map(
        (option) =>
          option?.checked && (
            <OptionInfo
              hasConnectedOptions={!!option.isConnectedPeriod}
              isShutdown={false}
              key={option.title}
              period={option.isConnectedPeriod ? option.initialPeriod : option.period}
              price={option.price}
              title={option.title}
              unit={option.unit}
              value={option.value}
            />
          ),
      )}
    </div>
  );
};

Options.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.number,
      value: PropTypes.number,
      period: PropTypes.string,
      isShutdown: PropTypes.bool,
      isConnectedPeriod: PropTypes.bool,
    }),
  ),
};
