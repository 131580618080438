import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from '../TariffContentContainer/styles.pcss';

const cx = classNames.bind(styles);

export const ItemBottomBlock = ({ description, subscription }) => (
  <div className={cx('wrapper__bottom', { 'wrapper__bottom--between': description })}>
    {description && (
      <div className={cx('wrapper__description')}>
        <Text color="grey60" size="size5-r-s">
          {description}
        </Text>
      </div>
    )}
    {subscription && (
      <div className={cx('wrapper__price')}>
        <Text color="grey60" size="size5-r-s">
          {subscription}
        </Text>
      </div>
    )}
  </div>
);

ItemBottomBlock.propTypes = {
  description: PropTypes.string,
  subscription: PropTypes.string,
};
