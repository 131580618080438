import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';

import { createArray } from 'constructorV1/utils/createArray';
import { getRandomIntInclusive } from 'constructorV1/utils/getRandomIntInclusive';
import { MAX_SKELETON_TILES_LENGTH, MIN_SKELETON_TILES_LENGTH } from 'constructorV1/constants';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Кастомный скелетон карточки опции */
export const SkeletonOptionTile: FC = memo(() => (
  <div className={cx('wrapper')}>
    <div className={cx('wrapper__button')}>
      <div className={cx('wrapper__top')}>
        <div className={cx('wrapper__header', 'skeleton')} />
        <div className={cx('wrapper__icons')}>
          {/* Генерация массива иконок случайно длины */}
          {createArray(
            getRandomIntInclusive(MIN_SKELETON_TILES_LENGTH, MAX_SKELETON_TILES_LENGTH),
          ).map((i) => (
            <div className={cx('wrapper__avatar')} key={i}>
              <div className={cx('wrapper__avatar--item', 'skeleton')} />
            </div>
          ))}
        </div>
      </div>
      <div className={cx('wrapper__bottom')}>
        <div className={cx('wrapper__price', 'skeleton')} />
        <div className={cx('wrapper__switch', 'skeleton')} />
      </div>
    </div>
  </div>
));
