import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { TTitleContent, TitleContent } from '../../commonComponents/TitleContent';
import { FmcContainer, TFmcContainer } from './components/FmcContainer';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TConnectedFmcTile = {
  titleContentProps: Partial<TTitleContent>;
} & TFmcContainer;

/** Компонент-обертка плитки подключенного ШПД */
export const ConnectedFmcTile: FC<TConnectedFmcTile> = ({
  service = {},
  withoutButton,
  titleContentProps,
}) => (
  <section className={cx('wrapper')}>
    <TitleContent {...titleContentProps} />
    <FmcContainer {...{ service, withoutButton }} />
  </section>
);
