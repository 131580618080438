import { createAction } from '@reduxjs/toolkit';
import { getIsWebview } from '@beef/layout-kit';

import { pushQuery } from 'pages/SearchResultsPage/utils/pushQuery';
import { defaultParameters } from 'pages/SearchResultsPage/utils/constants';
import {
  prepareAutocompleteUrlParams,
  prepareAvailableFilters,
  prepareFiltersParams,
  setFiltersInUrl,
  setParamsInUrl,
  setQueryInUrl,
} from 'pages/SearchResultsPage/utils/prepareFilters';
import {
  getCurrentFilters,
  getPriceCategories,
  getSelectedFiltersCount,
  getTemporaryFilter,
  getTypeFilter,
} from 'pages/SearchResultsPage/store/selectors';
import { fetchAutocomplete } from 'pages/SearchResultsPage/services/fetchSearch';
import {
  getCurrentPriceValues,
  getInitialObjPrice,
  preparePriceInput,
} from 'pages/SearchResultsPage/utils/getPriceValues';
import { getIsNeedNewReq } from 'pages/SearchResultsPage/utils/getIsNeedNewReq';
import { validateSelectedFilters } from 'pages/SearchResultsPage/utils/helperFilters';
import { FILTER_NAMES, RESET_LIST, TEXT_BRAND } from 'pages/SearchResultsPage/utils/constsFilters';

import { fetchFilters } from './filtersAsync';

export const setData = createAction('set data');
export const setIsResetAvailableParameters = createAction('set is reset available parameters');
export const setArticles = createAction('set articles');
export const setInputPriceValue = createAction('set input price value');
export const setIsLoading = createAction('set is loading');
export const setIsInitialLoading = createAction('set is initial loading');
export const setCurrentUrl = createAction('set current url');
export const setParameters = createAction('set parameters');
export const setFilters = createAction('set filters');
export const setInitialParameters = createAction('set initial parameters');
export const setTerm = createAction('set term');
export const setIsArticles = createAction('set is articles');
export const setSearchQuery = createAction('set search query');
export const setCorrection = createAction('set correction');
export const setOpenFilterType = createAction('set open fitler type');
export const resetParametersFilters = createAction('reset available parameters');
export const setTemporaryFilter = createAction('set temporary filters');

const SearchParamsWithoutFilters = ['categories', 'price', 'sort', 'term', 'offset'];
const AllSearchParams = [...SearchParamsWithoutFilters, 'filters'];

const updatePrice = (selectedParametersPrice, searchPrice, inputPrice) => (dispatch) => {
  const selectedPrice = getInitialObjPrice(selectedParametersPrice);
  const { min, max } = preparePriceInput({
    min: searchPrice?.min,
    max: searchPrice?.max,
    price: {
      min: selectedPrice?.min,
      max: selectedPrice?.max,
    },
  });
  // Выходим если нет выбранного диапазона цены
  if (!selectedPrice?.min && !selectedPrice?.max) {
    return;
  }
  // Устанавливаем если в оба поля диапазона цены записаны значения
  if (inputPrice?.min && inputPrice?.max) {
    dispatch(setInputPriceValue({ min, max }));
  }
  setQueryInUrl(FILTER_NAMES.price, `${min};${max}`);
};

export const setProductsAction = () => async (dispatch, getState) => {
  const { searchUrl, selectedParameters, availableParameters, inputPrice } = getState().products;
  const { search, parameters, isCleanFilters } = await fetchFilters(
    searchUrl,
    selectedParameters,
    availableParameters,
  );
  if (search) {
    const price =
      search.price.min === 0 && search.price.max === 0 ? availableParameters.price : search.price;

    const filters = prepareAvailableFilters(search.filters);
    dispatch(setData({ ...search, filters, price }));

    const actuallyParameters = validateSelectedFilters(parameters, filters);
    setFiltersInUrl(actuallyParameters.filters);

    if (isCleanFilters) {
      dispatch(setFilters(actuallyParameters.filters));
    }

    // Обновление состояния price параметров Store + url
    dispatch(updatePrice(selectedParameters.price, price, inputPrice));

    /**  Устанавливаем глобальную переменную digiCustomZeroQueries для AnyQuery
     *  только при наличии параметра/cookie - webview */
    const isWebview = getIsWebview();
    if (!isWebview && search.zeroQueries !== 'undefined') {
      window.digiCustomZeroQueries = search.zeroQueries;
    }
  } else {
    dispatch(resetParametersFilters());
    setFiltersInUrl(parameters.filters);
  }
};

export const setAutocompleteAction = () => async (dispatch, getState) => {
  const { autocompleteUrl, selectedParameters } = getState().products;
  const autocompleteURL = prepareAutocompleteUrlParams(autocompleteUrl, selectedParameters.term);
  try {
    const { articles, taps } = await fetchAutocomplete(autocompleteURL);
    if (articles || taps) {
      dispatch(setArticles({ articles, taps }));
    }
  } catch (e) {
    console.error(e);
  }
};

const updateData =
  (withAutocomplete = true) =>
  async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    if (withAutocomplete) {
      await dispatch(setAutocompleteAction());
    }
    await dispatch(setProductsAction());
    const { productList, articles, isArticles, availableParameters, selectedParameters } =
      getState().products;
    /* делаем еще запрос, если есть только одна категория и нет фильтров */
    const isNeedNewReq = getIsNeedNewReq(availableParameters.categories, selectedParameters);
    if (isNeedNewReq) {
      dispatch(setParametersAction(FILTER_NAMES.categories, availableParameters.categories[0].id));
    }
    const countSelectedFilters = getSelectedFiltersCount(getState());
    if (!productList?.length && articles.length && !isArticles && !countSelectedFilters) {
      setQueryInUrl(FILTER_NAMES.categories, FILTER_NAMES.articles);
      dispatch(setIsArticles(true));
    }
    dispatch(setIsLoading(false));
    if (!isNeedNewReq) {
      dispatch(setIsInitialLoading(false));
    }
  };

const resetFilter = (array) => (dispatch) => {
  const filterQuery = new URLSearchParams(window.location.search);
  const parameters = {};
  array.forEach((key) => {
    filterQuery.delete(key);
    parameters[key] = defaultParameters[key];
    if (key === FILTER_NAMES.price) dispatch(setInputPriceValue({ min: '', max: '' }));
  });
  dispatch(setParameters(parameters));
  return filterQuery;
};

export const initialSearchParams = (path, isCloseModalFilters) => (dispatch, getState) => {
  dispatch(setIsInitialLoading(true));
  const isWebview = getIsWebview();
  dispatch(setCurrentUrl(isWebview));

  const parameters = {};
  parameters.size = new URL(getState().products.searchUrl).searchParams.get('size');
  const searchUrlParams = new URLSearchParams(path || window.location.search);
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of searchUrlParams.entries()) {
    if (AllSearchParams.includes(key)) {
      parameters[key] =
        key === 'filters' ? { ...parameters.filters, ...prepareFiltersParams(value) } : value;
    }
  }

  dispatch(setInitialParameters(parameters));
  dispatch(setInputPriceValue(getInitialObjPrice(parameters.price)));

  if (isCloseModalFilters) {
    setParamsInUrl(searchUrlParams, {
      [FILTER_NAMES.categories]: parameters.categories,
      [FILTER_NAMES.price]: parameters.price,
    });
  }
  dispatch(updateData());
};

export const setParametersAction = (key, value) => (dispatch) => {
  let url;
  let isFilter = false;
  if (key === 'term') {
    dispatch(setIsResetAvailableParameters(true));
    url = dispatch(resetFilter(AllSearchParams));
  }
  if (key === 'categories') {
    dispatch(setIsResetAvailableParameters(true));
    url = dispatch(resetFilter(RESET_LIST));
  }
  if (key === FILTER_NAMES.sort || key === FILTER_NAMES.price) {
    url = dispatch(resetFilter([FILTER_NAMES.offset]));
  }
  if (!SearchParamsWithoutFilters.includes(key)) {
    isFilter = true;
    url = dispatch(resetFilter([FILTER_NAMES.offset]));
    dispatch(setFilters({ [key]: value }));
  }
  if (!isFilter) {
    dispatch(setParameters({ [key]: value }));
  }
  setQueryInUrl(key, value, url, isFilter);
  dispatch(updateData());
};

export const setPriceAction = (value) => (dispatch, getState) => {
  const price = getPriceCategories(getState());
  const { min, max } = getCurrentPriceValues({
    valueFrom: +value.min,
    valueTo: +value.max,
    ...price,
  });

  if (min === price.min && max === price.max) {
    dispatch(setParametersAction(FILTER_NAMES.price, ''));
  } else {
    dispatch(setParametersAction(FILTER_NAMES.price, `${min};${max}`));
  }
};

export const clearFilter =
  ({ isMobile }) =>
  (dispatch) => {
    const resetParams = ['offset', 'price', 'filters'];
    if (isMobile) {
      resetParams.push('categories');
    }
    const filterQuery = dispatch(resetFilter(resetParams));
    pushQuery(filterQuery);
    dispatch(setInputPriceValue({ min: '', max: '' }));
    dispatch(updateData());
  };

export const applyMobileFilters = () => (dispatch, getState) => {
  const state = getState();
  const temporaryFilter = getTemporaryFilter(state);
  const type = getTypeFilter(state);

  dispatch(setFilters({ [type]: temporaryFilter.join(';') }));
  dispatch(setOpenFilterType(null));
  dispatch(updateData());
};

export const setOpenAdditionalFiltersModal = (name) => (dispatch, getState) => {
  const type = name === TEXT_BRAND ? FILTER_NAMES.brands : name;
  const filters = getCurrentFilters(getState(), type);

  dispatch(setOpenFilterType(name));
  dispatch(setTemporaryFilter(filters));
};
