import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const RightColumnWrapper: FC = ({ children }) => (
  <div className={cx('wrapper')}>{children}</div>
);
