import React, { FC } from 'react';

import { TariffHeader } from '../components/constructorPage/TariffHeader';
import { TariffTitle } from '../components/constructorPage/TariffTitle';
import { TariffContainer } from '../components/commonComponents/TariffContainer';
import { Separation } from '../components/commonComponents/Separation';
import { Tabs } from '../components/constructorPage/Tabs';
import { PresetsPickers } from '../components/constructorPage/PresetsPickers';
import { TariffOptions } from '../components/constructorPage/TariffOptions';
import { ConvergenceServices } from '../components/constructorPage/ConvergenceServices';
import { ExtraServices } from '../components/constructorPage/ExtraServices';
import { BillContainer } from '../components/commonComponents/BillContainer';
import { FAQContainer } from '../components/commonComponents/FAQContainer';
import { usePushDataLayer } from '../tempAnalytics/hooks/usePushDataLayer';
import { TariffInfoTilesContainer } from '../components/constructorPage/TariffInfoTilesContainer';

/** Главная страница конструктора */
export const ConstructorBuilderPage: FC = () => {
  /* Отправляем DataLayer при показе главной страницы */
  usePushDataLayer();

  return (
    <>
      <TariffHeader>
        {/* Общий заголовок и описание тарифа */}
        <TariffTitle />
        {/* Основные плитки тарифа */}
        <TariffInfoTilesContainer />
      </TariffHeader>
      {/* Табы, пресеты, опции, сервисы, чек тарифа */}
      <TariffContainer>
        <Separation sideType="right">
          {/* Чек тарифа и промо баннер */}
          <BillContainer />
        </Separation>
        <Separation>
          {/* Табы способов подключения тарифа */}
          <Tabs />
          {/* Блок выбора пресетов тарифа (интернет/минуты) */}
          <PresetsPickers />
          {/* Блок Опции */}
          <TariffOptions />
          {/* Блок ШПД (конвергенция) и ТВ */}
          <ConvergenceServices />
          {/* Блок семьи и доп. услуг */}
          <ExtraServices />
        </Separation>
      </TariffContainer>
      {/* FAQ тарифа */}
      <FAQContainer />
    </>
  );
};
