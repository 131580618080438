import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../..';

/**
 * Стейт с контентом страницы
 */
export const selectCommonState = (state: RootState) => state.common;

/**
 * Контент для блока FAQ
 */
export const selectFAQContent = createSelector(selectCommonState, (state) => state.faq);

/**
 * Контент для футера
 */
export const selectFooterContent = createSelector(selectCommonState, (state) => state.footer);

/**
 * Контент для главного экрана
 */
export const selectMainContent = createSelector(selectCommonState, (state) => ({
  anchorId: state.main.anchorId,
  title: state.main.title,
  description: state.main.description,
  buttonTitle: state.main.buttons.titleButon,
  mobileTitle: state.main.mobileTitle,
  errors: state.main.errors,
  menu: state.main.menu,
}));

/**
 * Контент для хедера и сайдбара
 */
export const selectMenuContent = createSelector(selectMainContent, (state) => state.menu);

/**
 * Контент для чата
 */
export const selectChatContent = createSelector(selectCommonState, (state) => state.chatWidget);

/**
 * Контент для блока "как работает перенос"
 */
export const selectHowItWorksContent = createSelector(
  selectCommonState,
  (state) => state.instruction,
);

/**
 * Контент для блока "в билайне можно все"
 */
export const selectBenefitsContent = createSelector(selectCommonState, (state) => state.benefits);

/**
 * Контент для блока с дополнительной информацией
 */
export const selectAdditionalInfoContent = createSelector(
  selectCommonState,
  (state) => state.other,
);

/**
 * Контент формы переноса номера
 */
export const selectMNPFormContent = createSelector(selectCommonState, (state) => state.formBlocks);

/**
 * Контент для блока ввода номера
 */
export const selectInputBlockContent = createSelector(
  selectMNPFormContent,
  (state) => state.inputBlock,
);

/**
 * Контент для блока с условиями переноса номера
 */
export const selectListBlockContent = createSelector(
  selectMNPFormContent,
  (state) => state.listBlock,
);

/**
 * Контент для блока "если ты уже в билайне"
 */
export const selectQRBlockContent = createSelector(selectMNPFormContent, (state) => state.qrBlock);
