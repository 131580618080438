import { shallowEqual } from '@beef/utils';

import { useAppSelector } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

export const useCheckAddressInputsTexts = () =>
  useAppSelector(
    (state) => ({
      regionTitle: state.common.content.fmcSettings?.checkAddressPage.regionTitle,
      regionPlaceholder: state.common.content.fmcSettings?.checkAddressPage.regionPlaceholder,
      streetTitle: state.common.content.fmcSettings?.checkAddressPage.streetTitle,
      streetPlaceholder: state.common.content.fmcSettings?.checkAddressPage.streetPlaceholder,
      homeTitle: state.common.content.fmcSettings?.checkAddressPage.homeTitle,
      homePlaceholder: state.common.content.fmcSettings?.checkAddressPage.homePlaceholder,
      flatTitle: state.common.content.fmcSettings?.checkAddressPage.flatTitle,
      flatPlaceholder: state.common.content.fmcSettings?.checkAddressPage.flatPlaceholder,
      contactPhoneTitle: state.common.content.fmcSettings?.checkAddressPage.contactPhoneTitle,
    }),
    shallowEqual,
  );
