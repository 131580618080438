import { useEffect } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

import { changeStreetInputState, setRegion } from '../../../../store/slices/fttbAddressSlice/index';

/** Хук проверяет установленный в fttbAddressSlice поле regionId
 * и при необходимости устанавливает его дефолтным значением из hydrate */
export const useSetDefaultRegionFttbAddress = () => {
  const dispatch = useAppDispatch();
  const defaultRegion = useAppSelector((state) => ({
    regionId: state.common.userRegion.regionId,
    region: state.common.userRegion.regionName,
  }));
  const currentRegion = useAppSelector((state) => ({
    regionId: state.fttbAddress.checkConnectionRes.regionId,
    region: state.fttbAddress.checkConnectionRes.region,
  }));

  /** Устанавливаем при монтировании дефолтный регион из hydrate, если еще не был задан */
  useEffect(() => {
    if (
      defaultRegion.regionId &&
      defaultRegion &&
      (!currentRegion.regionId || !currentRegion.region)
    ) {
      dispatch(setRegion({ ...defaultRegion }));
      dispatch(changeStreetInputState(false));
    }
  }, []);
};
