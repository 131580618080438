import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Link, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';
// import { Button } from '@beef/legacy-ui-kit';
import Icon from 'pages-components/Icon';

import Description from '../Description';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PointInfo = ({
  lat,
  lon,
  onBackClick,
  place,
  extraInfo,
  setOpenPopup,
  isSimOrder,
  showOrderButton,
  holidaysText,
}) => (
  <>
    <div className={cx('link-wrapper')}>
      <Icon className={cx('backArrow')} name="dropdownArrow" />
      <Link onClick={onBackClick} size="size6-r" variant="dark">
        К списку офисов
      </Link>
    </div>

    <Description text={place.address} />
    {place.metro && <Description fill={place.line} icon="metro" text={place.metro} />}
    {extraInfo && extraInfo.displayName && <Description text={extraInfo.displayName} />}
    {place.addressNote && <Description small text={place.addressNote} />}
    <Description icon="clocks" text="График работы" />
    <Description text={place.openHours} />
    {place.openHoursHoliday && (
      <>
        <Description text={holidaysText} />
        <Description text={place.openHoursHoliday} />
      </>
    )}
    <Description text={place.openHoursB2b} />
    {place.openHoursHolidayB2b && (
      <>
        <Description text={holidaysText} />
        <Description text={place.openHoursHolidayB2b} />
      </>
    )}
    <Description small>
      <Link
        href={`https://yandex.ru/maps/?mode=routes&rtext=~${lat}%2C${lon}`}
        size="size6-r"
        target={{
          summary: '_blank',
        }}
        variant="dark"
      >
        Построить маршрут?
      </Link>
    </Description>
    {extraInfo && (
      <div className={cx('extraInfo')}>
        {extraInfo.image && <img alt="" src={extraInfo.image} />}
        <Text color="grey95" size="size6-r">
          {extraInfo.text}
        </Text>
      </div>
    )}

    {isSimOrder && showOrderButton && (
      <div className={cx('getSimCard')}>
        <div className={cx('wrapper')}>
          <Picture src="//static.beeline.ru/shop/media/blocks/45e58cae-e5d7-4ab0-a4c1-dd957d33e793.png" />
          <Text color="grey95" size="size4-r">
            Получить SIM
          </Text>
        </div>
        <Button fullWidth onClick={setOpenPopup} size="l" type="button" variant="main">
          Забрать здесь
        </Button>
      </div>
    )}
  </>
);

PointInfo.propTypes = {
  lat: PropTypes.number,
  lon: PropTypes.number,
  onBackClick: PropTypes.func,
  setOpenPopup: PropTypes.func,
  isSimOrder: PropTypes.bool,
  place: PropTypes.shape({
    address: PropTypes.string,
    metro: PropTypes.string,
    line: PropTypes.string,
    addressNote: PropTypes.string,
    openHours: PropTypes.string,
    openHoursB2b: PropTypes.string,
  }),
  extraInfo: PropTypes.shape({
    displayName: PropTypes.string,
    image: PropTypes.string,
    text: PropTypes.string,
  }),
  showOrderButton: PropTypes.bool,
  holidaysText: PropTypes.string,
};

export default PointInfo;
