import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TErrorButton = {
  btnText?: string;
  onClick: () => void;
};

/** Обертка над кнопкой для блока ошибки total чека */
export const ErrorButton: FC<TErrorButton> = ({ btnText, onClick }) => (
  <div className={cx('wrapper')}>
    <Button fullWidth onClick={onClick}>
      {btnText}
    </Button>
  </div>
);
