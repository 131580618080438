import { shallowEqual } from 'react-redux';

import { nextStep } from '../../../../store/slices/stepperSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { useThrowAsyncError } from '../../../../hooks/useThrowAsyncError';
import { prepareServicesInacIdList } from '../../../../utils/prepareServicesInacIdList';
import { fetchTariffWithConvergence } from '../../../../api/connectTariffWithConvergence';

/** Возвращает событие запроса без корзины,
 *  а через метод подключения тарифа с ШПД в НЗ */
export const useConnectWithConvergence = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();

  /* Получаем данные для формирования параметров запроса на подключение тарифа с ШПД */
  const {
    flat = '',
    houseId = 0,
    timeSlot = {},
    contactPhone = '',
    tariffInacId = '',
    servicesInacIdList = [],
  } = useAppSelector(
    (state) => ({
      flat: state.total.convergenceInfo.flat,
      contactPhone: state.total.userPhoneNumber,
      houseId: state.total.convergenceInfo.houseId,
      tariffInacId: state.total.convergenceInfo.tariffInacId,
      timeSlot: state.total.convergenceInfo.totalDateTimeSlot,
      servicesInacIdList: state.total.convergenceInfo.servicesInacIdList,
    }),
    shallowEqual,
  );

  /* Параметры запроса на подключение тарифа с ШПД */
  const requestParams = {
    flat,
    houseId,
    contactPhone,
    servicesInacIdList: prepareServicesInacIdList(tariffInacId, servicesInacIdList),
    ...timeSlot,
  };

  /** Функция-обработчик отправки запроса с ШПД на подключение тарифа */
  const onFttbWithoutBasket = async () =>
    dispatch(fetchTariffWithConvergence(requestParams))
      .unwrap()
      .then(() => dispatch(nextStep()))
      .catch((e: Error) => throwAsyncError(e));

  return { onFttbWithoutBasket };
};
