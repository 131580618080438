import React from 'react';

import { ModalHeader } from '../../../../../../../modalComponents/ModalHeader';
import { ModalContainer } from '../../../../../../../modalComponents/ModalContainer';
import { EquipmentCheckbox } from '../../../../../../../../tempComponents/EquipmentCheckbox';
import { usePrepareInternetEquipment } from '../../hooks/usePrepareInternetEquipment';
import { ECheckboxType, EEquipmentType } from '../types';

/** Компонент-обертка настройки подключения ТВ оборудования */
export const TvEquipmentContainer = () => {
  const { checkboxProps, equipmentProps, modalProps } = usePrepareInternetEquipment({
    equipmentType: EEquipmentType.tvEquipment,
    checkboxType: ECheckboxType.tvEquipmentCheckbox,
  });

  if (!equipmentProps || !checkboxProps) return null;

  return (
    <section>
      <EquipmentCheckbox checkboxProps={checkboxProps} equipmentProps={equipmentProps} />
      <ModalContainer {...modalProps.modalContainerProps}>
        <ModalHeader {...modalProps.modalHeaderProps} />
      </ModalContainer>
    </section>
  );
};
