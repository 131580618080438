import * as CONSTANTS from './constants';
import { currentTariffOptions } from '../../../utils';

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case CONSTANTS.SET_PRESET_PARAM:
      return {
        ...state,
        total: { ...state.total, ...action.payload },
      };
    case CONSTANTS.SET_TARIFF_INFO:
      // eslint-disable-next-line no-case-declarations
      const { isHighPreset, tariffOptions } = action.payload;
      // eslint-disable-next-line no-case-declarations
      const { options } = state.total;
      // eslint-disable-next-line no-case-declarations
      const selectedOptions = Object.values(options)?.reduce(
        (entityNames: any, option: any) =>
          option.checked ? [...entityNames, ...option.entityName] : entityNames,
        [],
      );
      return {
        ...state,
        isHighPreset,
        info: {
          ...action.payload,
          tariffOptions: currentTariffOptions(tariffOptions, selectedOptions),
        },
      };
    case CONSTANTS.SET_DEFAULT_SLIDES_AND_OPTIONS:
      return {
        ...state,
        info: {
          ...state.defaultInfo,
          defaultInfo: true,
        },
        sliders: state.slides,
        isHighPreset: state.defaultInfo.isHighPreset,
      };
    case CONSTANTS.GET_PRESET_OPTIONS:
      return {
        ...state,
        presetOptions: action.payload,
      };
    case CONSTANTS.SET_PRESET_OPTIONS:
      return {
        ...state,
        info: {
          ...state.info,
          tariffOptions: action.presetOptions,
        },
        total: {
          ...state.total,
          options: action.options,
          // initOptions: action.initOptions,
        },
      };
    case CONSTANTS.TOGGLE_UPSELL_OFFER:
      return {
        ...state,
        isUpsellSwitch: !state.isUpsellSwitch,
      };
    case CONSTANTS.HANDLE_CHANGE_TOTAL:
      return {
        ...state,
        total: {
          ...state.total,
          options: {
            ...state.total.options,
            [action.id]: action.price,
          },
        },
      };
    case CONSTANTS.DISABLE_SPECIAL_OFFER:
      return {
        ...state,
        isUpsellSwitch: false,
      };
    case CONSTANTS.SET_SPECIAL_OFFER_INFO:
      // eslint-disable-next-line no-case-declarations
      const specialOptionSlides = () =>
        state.slides.map((slide: any) => ({
          ...slide,
          defaultIndex: slide.specialOfferIndex,
        }));
      return {
        ...state,
        info: action.payload,
        sliders: specialOptionSlides(),
      };
    case CONSTANTS.RESET_OPTIONS:
      return {
        ...state,
        total: {
          ...state.total,
          options: {},
        },
      };
    case CONSTANTS.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case CONSTANTS.CHANGE_PARTNER_SUBSCRIPTION:
      return {
        ...state,
        total: {
          ...state.total,
          subscriptions: action.payload,
        },
      };
    case CONSTANTS.SET_CURRENT_GB_SLIDE_INDEX:
      return {
        ...state,
        currentGBSlideIndex: action.payload,
      };
    case CONSTANTS.SET_CONVERGENCE_SETTINGS:
      return {
        ...state,
        convergence: action.payload,
      };
    case CONSTANTS.SET_AVAILABLE_PRESETS:
      return {
        ...state,
        presets: action.payload,
      };
    case CONSTANTS.SET_CONNECTED_AND_AVAILABLE_CONVERGENCE_TARIFFS:
      return {
        ...state,
        connectConvergenceTariffs: action.payload,
      };
    case CONSTANTS.SET_ACTIVE_PRESET_TAB:
      return {
        ...state,
        activePresetTab: action.payload,
      };
    case CONSTANTS.SET_FTTB_DISCOUNT_FROM_CONNECTED_PRESET:
      return {
        ...state,
        fttbDiscountOnPreset: action.payload,
      };
    default:
      return state;
  }
};
