import { shallowEqual } from '@beef/utils';

import { DEFAULT_TAB_ID } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/components/constructorPage/FmcTabsContainer/constants';

import { changeFmcInacId } from '../../../../store/slices/totalSlice/asyncThunks/changeFmcInacId';
import { EConstructorStep } from '../../../../store/slices/stepperSlice/types';
import { useIsGoToFirstStep } from '../../../../hooks/useIsGoToFirstStep';
import { useStepNavigation } from '../../../../hooks/useStepNavigation';
import { useAppDispatch, useAppSelector } from '../../../../store';

/** Хук подготовки данных для кнопки Назад */
export const usePrepareBackButtonProp = () => {
  const dispatch = useAppDispatch();
  const isGoToFirstStep = useIsGoToFirstStep();
  const { prevStep, currentStep, goToFirstStep } = useStepNavigation();

  const { tariffInacId, availableSchedule, convergenceConnectedInacId } = useAppSelector(
    (state) => ({
      tariffInacId: state.total.convergenceInfo.tariffInacId,
      availableSchedule: state.specialistVisit.availableSchedule,
      convergenceConnectedInacId: state.common.userInfo.convergenceConnectedInfo?.inacId,
    }),
    shallowEqual,
  );

  /** Ф-я на клик назад при уже подключенном шпд.
   *  Сбрасывает serviceInacIdList при переходе на шаг конструктора к начальному */
  const handleGoToFirstStep = () => {
    if (convergenceConnectedInacId) {
      dispatch(changeFmcInacId(convergenceConnectedInacId)).catch((e: Error) => console.error(e));
    }
    goToFirstStep();
  };

  /* Обработчик клика на кнопку "Назад"  */
  return () => {
    /* Если текущий степ finalBill (total чек), выбран ШПД пресет (inacId не равен default-значению) и в ответе вернулся пустой массив periods,
      то пропускаем экран с выбором слотов монтажника */
    if (
      currentStep === EConstructorStep.finalBill &&
      tariffInacId !== DEFAULT_TAB_ID &&
      !availableSchedule
    ) {
      prevStep(1);
      return;
    }
    /* При уже подключенном ШПД нелинейный шаг на стартовый конструктор */
    if (isGoToFirstStep) {
      handleGoToFirstStep();
      return;
    }
    /* Во всех остальных случаях стандартная логика (prevStep) */
    prevStep();
  };
};
