import { pushQuery } from 'pages/SearchResultsPage/utils/pushQuery';

import {
  AVAILABLE_TYPE,
  FILTER_NAMES,
  FILTER_TYPE,
  KEY_FILTER,
  KEY_GROUP_FILTERS,
  TYPE_QUERIES,
} from './constsFilters';
import { getUrlSearchParams } from './helperFilters';

export const prepareAvailableFilters = (availableFilterList = []) => {
  const currentParameters = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const available of availableFilterList) {
    if (available.dataType === 'BOOLEAN') {
      currentParameters[available.name] = [{ ...available.values[0], name: 'да' }];
    } else if (
      available.name !== FILTER_NAMES.categories &&
      available.name !== FILTER_NAMES.price &&
      AVAILABLE_TYPE.includes(available.dataType)
    ) {
      currentParameters[available.name] = available.values ?? [];
    }
  }
  return currentParameters;
};

export const prepareSearchUrlParams = (searchUrl, parameters) => {
  const searchURL = new URL(searchUrl);
  Object.entries(TYPE_QUERIES).forEach(([key, value]) =>
    searchURL.searchParams.set(key, parameters[value]),
  );
  FILTER_TYPE.forEach((key) => {
    if (parameters[key]) searchURL.searchParams.append(KEY_FILTER, `${key}:${parameters[key]}`);
  });
  const filtersKey = Object.keys(parameters?.filters || {});
  if (filtersKey.length) {
    filtersKey.map((filter) =>
      searchURL.searchParams.append(KEY_FILTER, `${filter}:${parameters.filters?.[filter]}`),
    );
  }
  return searchURL;
};

export const setQueryInUrl = (key, value, url, isFilter) => {
  const filterQuery = url || getUrlSearchParams();
  if (!filterQuery) return;
  if (!isFilter) {
    filterQuery.set(key, value);
  }
  pushQuery(filterQuery);
};

export const setFiltersInUrl = (currentFilters = {}) => {
  const filterQuery = getUrlSearchParams();
  if (!filterQuery) return;

  // Для корректного обновления фильтров очищаем круппу фильтров filters - не удалять строку для корректной работы
  filterQuery.delete(KEY_GROUP_FILTERS);

  const keyFilters = Object.keys(currentFilters);
  keyFilters.filter(
    (key) =>
      currentFilters[key] && filterQuery.append(KEY_GROUP_FILTERS, `${key}:${currentFilters[key]}`),
  );
  pushQuery(filterQuery);
};

export const prepareFiltersParams = (filters) => {
  const [name, value] = filters.split(':');
  return name && value ? { [name]: value } : {};
};

export const prepareAutocompleteUrlParams = (autocompleteUrl, term) => {
  const autocompleteURL = new URL(autocompleteUrl);
  autocompleteURL.searchParams.set('st', term);
  return autocompleteURL;
};

export const setParamsInUrl = (url, params) => {
  const paramList = Object.entries(params);
  paramList.forEach(([key, value]) => (value ? url.set(key, value) : url.delete(key)));
  pushQuery(url);
};
