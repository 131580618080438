import React from 'react';
import classNames from 'classnames/bind';

import { useAppSelector } from 'constructorV1/store';
import { selectFaq } from 'constructorV1/components/commonComponents/CustomFaq/selectors/selectFaq';

import { Faq } from './components/Faq';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CustomFaq = () => {
  const { title, data } = useAppSelector(selectFaq);

  if (!(title || data)) {
    return null;
  }

  /** Контейнер для faq с заголовком */
  return (
    <section className={cx('section')}>
      <div className={cx('title')}>{title}</div>
      {data && <Faq data={data} />}
    </section>
  );
};
