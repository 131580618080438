/** Вспомогательная функция, позволяющая собрать все заблокированные соки
 * в родительских и дочерних опциях персонажа (опции с флагом isBlocked) */
export const collectCutOutOptionsSocs = (options, rawCharacterSoc) =>
  options.reduce((acc, { optionsList = [], parentOptionsList = [] }) => {
    /** Обработка/проверка родительских опций */

    /** Массив заблокированных пассивных опций (опции с флагом isBlocked) из parentOptionsList (родительские опции) */
    const blockedPassiveParentOptions = parentOptionsList?.filter(
      (item) => item.characterTypes?.[rawCharacterSoc]?.isBlocked,
    );

    /** Массив заблокированных пассивных РОДИТЕЛЬСКИХ соков если такие будут добавлены) */
    const blockedPassiveParentSocs = blockedPassiveParentOptions?.map((item) => item.soc);

    /** Обработка/проверка дочерних опций */

    /** Массив заблокированных пассивных опций (опции с флагом isBlocked) из optionsList (дочерние опции) */
    const blockedPassiveOptions = optionsList?.filter(
      (item) => item.characterTypes?.[rawCharacterSoc]?.isBlocked,
    );

    /** Массив заблокированных пассивных ДОЧЕРНИХ соков */
    const blockedPassiveSocs = blockedPassiveOptions?.map(
      (blockedPassiveOption) => blockedPassiveOption.soc,
    );

    if (blockedPassiveParentSocs?.length) {
      acc.push(...blockedPassiveParentSocs);
    }

    if (blockedPassiveSocs?.length) {
      acc.push(...blockedPassiveSocs);
    }

    return acc;
  }, []);
