import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import TariffCard from 'pages-components/TariffCard';

// Hocs
import TariffCardParamsHoc from 'pages-components/TariffCard/hocs/TariffCardParamsHoc';

const TariffList = memo(
  ({ tariffList, tariffsButtonGroup, className, tariffsGroup, paramsGridView, isYandex }) => {
    const handleButtonClick = useCallback((alias) => {
      window.location.href = `/customers/products/mobile/tariffs/details/${alias}`;
    }, []);

    return (
      <ul className={className}>
        {tariffList.map((tariff, index) => (
          <li key={index}>
            <TariffCardParamsHoc
              {...tariff}
              buttonGroup={
                tariffsButtonGroup && {
                  ...tariffsButtonGroup.texts,
                  onButtonClick: () => {
                    tariffsButtonGroup.onButtonClick('bottomButton', index);
                  },
                  onLinkClick: () => {
                    tariffsButtonGroup.onButtonClick('bottomLink', index);
                  },
                }
              }
              hideButton={false}
              isYandex={isYandex}
              onButtonClick={() => handleButtonClick(tariff.alias)}
              paramsGridView={paramsGridView}
              portal
              tariffList={tariffList}
              tariffsGroup={tariffsGroup}
            >
              <TariffCard />
            </TariffCardParamsHoc>
          </li>
        ))}
      </ul>
    );
  },
);

TariffList.displayName = 'TariffList';

TariffList.propTypes = {
  className: PropTypes.string,
  paramsGridView: PropTypes.bool,

  tariffsButtonGroup: PropTypes.object,
  tariffList: PropTypes.array,
  tariffsGroup: PropTypes.array,
  isYandex: PropTypes.bool,
  handleLoadModal: PropTypes.func,
};

export default TariffList;
