const pushEvent = (key, payload) => {
  if (typeof window === 'undefined') return;
  window.flocktory = window.flocktory || [];
  window.flocktory.push([key, payload]);
};

export const flocktoryAddToCart = (product) => {
  const { brand, id, price } = product;
  pushEvent('addToCart', { item: { brand, id, price } });
};
