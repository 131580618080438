import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { DevicesItem } from './componetns/DevicesItem';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TRecommendationsItem = {
  devices: string[];
  title?: string;
};

/** Заголовок и список рекомендуемых девайсов */
export const RecommendationsItem: FC<TRecommendationsItem> = ({ title, devices }) => (
  <li className={cx('wrapper')}>
    {title && <Text size="size4-r">{title}</Text>}
    <ul className={cx('wrapper__item')}>
      {devices?.map((name) => <DevicesItem key={name} name={name} />)}
    </ul>
  </li>
);
