import { MouseEvent, cloneElement } from 'react';
import { connect } from 'react-redux';

import {
  CategoriesAnalyticsPusher,
  SpecialCategoriesAnalyticsPusher,
} from 'pages/SearchResultsPage/utils/analytics';
import { getPlaceForAnalytics, getTerm } from 'pages/SearchResultsPage/store/selectors';
import { CATEGORIES } from 'pages/SearchResultsPage/utils/constants';

type CategoriesWithAnalyticsType = {
  children: JSX.Element;
  isSpecial?: boolean;
  location: keyof typeof CATEGORIES;
  name?: string;
  term: string;
};

const _CategoriesWithAnalytics = ({
  children,
  location,
  isSpecial,
  term,
  name,
}: CategoriesWithAnalyticsType) => {
  const AnalyticsPusher = isSpecial ? SpecialCategoriesAnalyticsPusher : CategoriesAnalyticsPusher;
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    children.props.onClick?.(e);
    AnalyticsPusher.location = location;
    AnalyticsPusher.pushClickEvent({ [name || children.props.name]: term });
  };
  return cloneElement(children, { onClick: handleClick });
};

export const CategoriesWithAnalytics = connect((state) => ({
  location: getPlaceForAnalytics(state) as keyof typeof CATEGORIES,
  term: getTerm(state),
}))(_CategoriesWithAnalytics);
