import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const LeftSide = ({ children }) => <div className={cx('left__side')}>{children}</div>;

LeftSide.propTypes = {
  children: PropTypes.node,
};

export { LeftSide };
