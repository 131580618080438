import React, { memo } from 'react';

export const RefreshIcon = memo(() => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M5.61383 8.98394L11.9524 10.1451L12.2588 8.47296L7.88627 7.67194L9.37882 3.48475L7.77751 2.91395L5.61383 8.98394Z"
      fill="#191C22"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4.15039 12.3221L4.15039 13.1721C4.15039 17.5076 7.66495 21.0221 12.0004 21.0221C16.3358 21.0221 19.8504 17.5076 19.8504 13.1721C19.8504 8.83671 16.3358 5.32214 12.0004 5.32214C10.2862 5.32214 8.69812 5.87255 7.40646 6.80611L6.71756 7.30402L7.71338 8.68183L8.40228 8.18392C9.41383 7.45281 10.6556 7.02214 12.0004 7.02214C15.3969 7.02214 18.1504 9.77559 18.1504 13.1721C18.1504 16.5687 15.3969 19.3221 12.0004 19.3221C8.60384 19.3221 5.85039 16.5687 5.85039 13.1721L5.85039 12.3221L4.15039 12.3221Z"
      fill="#191C22"
      fillRule="evenodd"
    />
  </svg>
));
