import { DEFAULT_TAB_ID } from '../components/constructorPage/FmcTabsContainer/constants';

/** Подготовка servicesInacIdList. По контракту с беком договорились,
 * что tariffInacId (inacId таба ШПД) всегда будет лежать первым элементом массива/строки */
export const prepareServicesInacIdList = (tariffInacId: string, servicesInacIdList: string[]) => {
  if (tariffInacId === DEFAULT_TAB_ID) {
    return undefined;
  }
  /* На всякий случай удаляем из массива servicesInacIdList пустые значения (null),
   чтобы избежать некорректной работы метода /get-changes/ */
  const prepareServicesInacIdListItems = servicesInacIdList.filter((inacId) => inacId != null);
  return [tariffInacId, ...prepareServicesInacIdListItems].join();
};
