import React from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { pathOr } from 'ramda';

// Components
import classNames from 'classnames/bind';

import store from 'store';

import { ConnectTariffButton } from '../../../components/ConnectButtonsGroup';
import FloatingConnectWrapper from '../FloatingConnectWrapper';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FloatingConnectButton = (props) => {
  const { includeNumber, children, isButtonsGroup, tariffYoung } = props;

  return (
    <FloatingConnectWrapper
      greyAreaBottomSpace={isButtonsGroup ? 90 : 0}
      hideIfNotAvailableForConnect
      render={({ tariffData }) => {
        const { connectButton } = tariffData;

        return (
          <div
            className={cx('wrapper', tariffYoung && 'youngTariffFloatingButton', {
              buttonsGroup: isButtonsGroup,
            })}
          >
            <ConnectTariffButton
              buttonText={connectButton}
              includeNumber={includeNumber}
              isFloatingButton
              newNumber=""
              popupData={tariffData}
            />
            {children}
          </div>
        );
      }}
    />
  );
};

FloatingConnectButton.propTypes = {
  includeNumber: PropTypes.bool,
  isButtonsGroup: PropTypes.bool,
  tariffYoung: PropTypes.bool,
  tariffData: PropTypes.shape({
    isConnected: PropTypes.bool,
    availableForConnect: PropTypes.bool,
    isArchived: PropTypes.bool,
    hideSubscribeButton: PropTypes.bool,
    connectButton: PropTypes.string,
  }),
};

const mapStateToProps = ({ external }) => ({
  tariffData: pathOr({}, ['tariff', 'data'], external),
});

const ConnectedComponent = connect(mapStateToProps)(FloatingConnectButton);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
