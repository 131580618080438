import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames/bind';

import styles from './SidebarContent/styles.pcss';
import { SidebarPortal } from './SidebarPortal/SidebarPortal';
import { ISidebarProps } from './types';

const cx = classNames.bind(styles);

export const Sidebar: React.FC<ISidebarProps> = ({ isOpen, ...otherProps }) => (
  <CSSTransition
    classNames={{
      enter: cx('animate_enter'),
      enterActive: cx('animate_entering'),
      enterDone: cx('animate_entered'),
      exit: cx('animate_exit'),
      exitActive: cx('animate_exiting'),
      exitDone: cx('animate_exited'),
    }}
    in={isOpen}
    timeout={300}
    unmountOnExit
  >
    <SidebarPortal isOpen={isOpen} {...otherProps} />
  </CSSTransition>
);
