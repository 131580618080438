import { useAppSelector } from '../../../../store';
import { EConstructorStep } from '../../../../store/slices/stepperSlice/types';

/** Хук отвечает за определение того,
 *  требуется ли на текущей странице отрисовывать кнопку "Назад" (возврат к предыдущиму шагу).
 *  Если текущий шаг настройки не равен "constructor" (0), показываем кнопку */
export const useShowRoutBackButton = () => {
  const currentStep = useAppSelector((state) => state.stepper.currentStep);
  return currentStep !== EConstructorStep.constructor;
};
