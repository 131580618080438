import { productType } from 'pages/FTTB/constants';
import { templateParser } from 'utils/format-string';

import { getStreetPageUrl } from '../utils/streetsHelper';

export const getTariffsTitle = (type, texts) => {
  const { kitTariffTitle, internetTariffTitle, tvTariffTitle } = texts;
  switch (type) {
    case productType.internet:
      return internetTariffTitle;
    case productType.kit:
      return kitTariffTitle;
    case productType.tv:
      return tvTariffTitle;

    default:
      return '';
  }
};

const getOtherConnectedStreetLinks = (otherConnectedStreets, streetPageBaseUrl) => {
  if (!otherConnectedStreets || !otherConnectedStreets.length) return null;

  return otherConnectedStreets
    .map(
      (otherStreet) => `<a class="link"
                              href="${getStreetPageUrl(streetPageBaseUrl, otherStreet)}">
                                ${otherStreet.label}
                            </a>`,
    )
    .join(', ');
};

const getTariffsWithMinPrice = (tariffs, tariffsTexts) => {
  if (!tariffs) return null;

  return tariffs
    .map((tariff) => {
      const { tariffsCardText } = tariffsTexts;
      const title = getTariffsTitle(tariff.type, tariffsCardText);

      return `"${title}" ${tariffsCardText.pricePrefix} ${tariff.price} ${tariffsCardText.pricePerMounth}`;
    })
    .join(', ');
};

const generateTabs = (tabs, vars) => {
  if (!tabs) return null;

  return tabs.map((tab) => {
    const title = templateParser(tab.title, vars);

    const content = tab.content.map((el) =>
      el.map((item) => {
        const text = templateParser(item.text, vars);

        return { ...item, text };
      }),
    );

    return { ...tab, title, content };
  });
};

export const getNewItems = (props) => {
  const {
    fullAddress,
    items,
    city,

    street,
    streetPageBaseUrl,

    otherConnectedStreetsTitle,
    otherConnectedStreets,

    houseItemTitle: houseItemTitleTemplate,
    houses,

    tariffs,
    tariffsTexts,
  } = props;

  if (!street && !city) return items;

  const otherConnectedStreetLinks = getOtherConnectedStreetLinks(
    otherConnectedStreets,
    streetPageBaseUrl,
  );

  const tariffsWithMinPrice = getTariffsWithMinPrice(tariffs, tariffsTexts);

  const houseItemTitle = houses && houseItemTitleTemplate;

  const varsForTemplate = {
    fullAddress: fullAddress && fullAddress,
    streetName: street && street.label,
    cityName: city && city.label,
    tariffsWithMinPrice,

    houseItemTitle,
    houses: houses && houses.map((house) => house.label).join(', '),

    otherConnectedStreetsTitle,
    otherConnectedStreetLinks,
  };

  return items.map((item) => {
    const tabs = generateTabs(item.tabs, varsForTemplate);

    return { ...item, tabs };
  });
};
