import { emptyObj } from '@beef/utils';
import { createSelector } from '@reduxjs/toolkit';

import { createMergedBlockSelector } from '../../selectors';
import type { RootState as State, TBlock } from '../../types';
import { getCheckAddressBlock } from '../selectors';
import { scaleFromOrigin } from './helpers';
import type { CheckAddressDataReturnType, TStatus } from './types';

export const getCheckAddressData = createMergedBlockSelector<TBlock, CheckAddressDataReturnType>(
  getCheckAddressBlock,
);

/** Common texts */
export const getCheckTitle = (state: State) => getCheckAddressData(state).title;
export const getCheckTitleAltTemplate = (state: State) =>
  getCheckAddressData(state).titleAltTemplate;
export const getCheckTitleMobile = (state: State) => getCheckAddressData(state).titleMobile;
export const getCheckTitleAltMobile = (state: State) => getCheckAddressData(state).titleAltMobile;
export const getCheckPhone = (state: State) => getCheckAddressData(state).phone;
export const getCheckStreetTemplate = (state: State) => getCheckAddressData(state).streetTemplate;
export const getCheckStreetLabelMobile = (state: State) =>
  getCheckAddressData(state).streetLabelMobile;
export const getIconImageHrefPin = (state: State) => getCheckAddressData(state).iconImageHrefPin;
export const getCheckHouseLabel = (state: State) => getCheckAddressData(state).houseLabel;
export const getCheckFlatLabel = (state: State) => getCheckAddressData(state).flatLabel;
export const getCityLabel = (state: State) => getCheckAddressData(state).cityLabel;

export const getCheckButton = (state: State) => getCheckAddressData(state).checkButton;
export const getCheckConnectButton = (state: State) => getCheckAddressData(state).connectButton;

export const getPopularTariffAlias = (state: State) => getCheckAddressData(state).tariffAlias;

const getBannerData = (state: State) => getCheckAddressData(state).banner || emptyObj;
export const getPopularTariffHeading = (state: State) => getBannerData(state).tariffTitle;
export const getFallbackBannerHeading = (state: State) => getBannerData(state).title;
export const getFallbackBannerDescription = (state: State) => getBannerData(state).description;

export const getFallbackBannerCard = (state: State) => getBannerData(state).tariffLike;

/** Map */
const getMapData = (state: State) => getCheckAddressData(state).map;

export const getCurrentCity = (state: State) => getCheckAddressData(state).city;
export const getCurrentCityId = (state: State) => getCheckAddressData(state).cityId;

export const getMapApikey = (state: State) =>
  getMapData(state).apikey || 'e53d529e-29c6-4143-ac2d-536d88a7b678';
export const getCurrentCityGeo = (state: State) => getMapData(state).center;

const getCurrentCityBoundsRaw = (state: State) => getMapData(state).boundedBy;
export const getCurrentCityBounds = createSelector(
  getCurrentCityBoundsRaw,
  // Сжимаем масштаб по вертикали, т.к. карта сильно сплюснута
  // @ts-expect-error lint fix
  ([[lat1, long1], [lat2, long2]]) => [
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    [scaleFromOrigin(lat1, (lat2 + lat1) / 2, 0.3), long1],
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    [scaleFromOrigin(lat2, (lat2 + lat1) / 2, 0.3), long2],
  ],
);

/** Placemark tooltips */
export const getAvailableStatusData = (state: State) =>
  getCheckAddressData(state).availableStatus || (emptyObj as TStatus);
export const getAvailableStatusText = (state: State) => getAvailableStatusData(state).text;
export const getAvailableStatusActionText = (state: State) =>
  getAvailableStatusData(state).actionText;
export const getAvailableStatusActionHref = (state: State) =>
  getAvailableStatusData(state).actionHref;

export const getHSAvailableStatusData = (state: State) =>
  getCheckAddressData(state).highSpeedAvailableStatus || (emptyObj as TStatus);
export const getHSAvailableStatusText = (state: State) => getHSAvailableStatusData(state).text;
export const getHSAvailableStatusActionText = (state: State) =>
  getHSAvailableStatusData(state).actionText;
export const getHSAvailableStatusActionHref = (state: State) =>
  getHSAvailableStatusData(state).actionHref;

export const getNotAvailableStatusData = (state: State) =>
  getCheckAddressData(state).notAvailableStatus || (emptyObj as TStatus);
export const getNotAvailableStatusText = (state: State) => getNotAvailableStatusData(state).text;
export const getNotAvailableStatusTextMobile = (state: State) =>
  getNotAvailableStatusData(state).textMobile;
export const getNotAvailableStatusActionText = (state: State) =>
  getNotAvailableStatusData(state).actionText;
export const getNotAvailableStatusActionHref = (state: State) =>
  getNotAvailableStatusData(state).actionHref;
