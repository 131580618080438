import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { Provider, connect } from 'react-redux';

import { setSearchQuery } from 'pages/SearchResultsPage/store/actions';
import store from 'store';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const _Tap = ({ relatedSearch, tap, onClick, nodeRef, setQuery }) => {
  const handleClick = () => {
    setQuery(relatedSearch);
    onClick?.(relatedSearch);
  };

  return (
    <button className={cx('tap')} onClick={handleClick} ref={nodeRef} type="submit">
      <Text color="grey60" size="size5-r-s">
        {tap}
      </Text>
    </button>
  );
};

_Tap.propTypes = {
  relatedSearch: PropTypes.string,
  tap: PropTypes.string,
  onClick: PropTypes.func,
};

export const ConnectedComponent = connect(null, { setQuery: setSearchQuery })(_Tap);

export const Tap = (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
