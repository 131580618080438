import React from 'react';
import classNames from 'classnames/bind';
import { Notification } from '@beef/ui-kit';

import { ModalHeader } from '../../modalComponents/ModalHeader';
import { ModalContainer } from '../../modalComponents/ModalContainer';
import { usePrepareArchivedWithFttbProps } from './hoocks/usePrepareArchivedWithFttbProps';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**  Нотификация на странице настроек fttb о возможности обновить тариф */
export const ArchivedWithFttbBlock = () => {
  const { modalProps, notificationProps } = usePrepareArchivedWithFttbProps();
  return (
    <div className={cx('component')}>
      <Notification {...{ ...notificationProps, variant: 'primary' }} />
      <ModalContainer {...modalProps.modalContainerProps}>
        <ModalHeader {...modalProps.modalHeaderProps} />
      </ModalContainer>
    </div>
  );
};
