import { useAppSelector } from '../../../../store';

/** Селектор выборки контентных данных для доступных ШПД-пресетов */
export const useSelectFmcProps = () => {
  /* Выбираем текущую priceCategory из мобильных пресетов */
  const priceCategory = useAppSelector((state) => state.total?.priceCategory);

  /* Выбираем default-convergenceBlock (приходит в hydrate) для дальнейшего использования в табе "Без интернета" */
  const defaultConvergenceBlock = useAppSelector(
    (state) => state.common.defaultConstructor?.tariffServices?.convergenceBlock,
  );

  /* Выбираем данные по доступным ШПД-пресетам и статусу загрузки из стора */
  const { previewTariffs, isLoadingFmcRequest } =
    useAppSelector((state) => state?.fttbPresets) || {};

  return { previewTariffs, priceCategory, isLoadingFmcRequest, defaultConvergenceBlock };
};
