import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import styles from './NumberList.pcss';

const cx = classNames.bind(styles);

class NumberList extends Component {
  static renderIcon(icon) {
    return icon ? <img role="presentation" src={icon} /> : null;
  }

  static renderNumber(number) {
    return number;
  }

  static renderAlias(alias) {
    return alias || null;
  }

  static renderStatus(status) {
    switch (status) {
      case 'myNumber':
        return <span className={cx('statusContent', 'myNumber')}>Мой номер</span>;
      case 'invited':
        return <span className={cx('statusContent')}>Ожидает подключения</span>;
      case 'removing':
        return <span className={cx('statusContent')}>Отключается</span>;
      case 'connected':
        return (
          <span className={cx('statusContent', 'connected')}>
            <Icon className={cx('tick')} name="tariffCheck" />
            Подключен
          </span>
        );
    }
    return null;
  }

  renderControl(number, status) {
    if (['removed', 'myNumber'].includes(status)) return null;

    if (status === 'removing') {
      return <Preloader className={cx('preloader')} />;
    }

    return (
      <span onClick={() => this.props.onRemoveNumber(number, status)} role="button" tabIndex="0">
        <Icon className={cx('controlContent')} name="crossThin" />
      </span>
    );
  }

  renderCell = (name, child) => <td className={cx('col', name)}>{child}</td>;

  render() {
    return (
      <table className={cx('numberList')}>
        <tbody className={cx('numberListContainer')}>
          {this.props.numbers.map(({ icon, number, name, status }, index) => (
            <tr className={cx('row')} key={index}>
              {this.renderCell('icon', NumberList.renderIcon(icon))}
              {this.renderCell('number', NumberList.renderNumber(number))}
              {this.renderCell('alias', NumberList.renderAlias(name))}
              {this.renderCell('number', NumberList.renderStatus(status))}
              {this.props.controls !== false && (
                <td className={cx('col', 'control')}>{this.renderControl(number, status)}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

NumberList.propTypes = {
  numbers: PropTypes.array,
  onRemoveNumber: PropTypes.func,
  controls: PropTypes.bool,
};

export default NumberList;
