import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Radio } from '@beef/ui-kit';

import { MainInfoType } from 'pages/SPN/types';

import { isRefersToEditable, textForLabel } from '../../common';
import { useInitializeMainInfoSPN } from './hook';
import { FieldInput } from './components/FieldInput/index';
import { ChildSocsBlock } from './components/ChildSocsBlock/index';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const MainInfo: FC<MainInfoType> = ({ data }) => {
  const outputData = useInitializeMainInfoSPN({ data });

  if (!outputData) return null;

  const {
    handleRadioChange,
    handleBlurInput,
    handleChangeInput,
    handleFocusInput,
    haveChildSocs,
    choosenSocEditable,
    childSocs,
    infoText,
    choosenOption,
    defaultPriceInfo,
    errorText,
    inputPlaceholder,
    savedTempEditableSpn,
  } = outputData;

  const checkedRadio = choosenOption?.soc === choosenSocEditable?.soc;
  const helpMessage = errorText ? '' : infoText;
  const valueInputOnlyForEditableSocs =
    isRefersToEditable() ? savedTempEditableSpn || choosenOption?.spn : savedTempEditableSpn;

  return (
    <>
      {haveChildSocs && (
        <section className={cn('editable-section')}>
          {choosenSocEditable && (
            <>
              <div className={cn('variant')}>
                <Radio
                  checked={checkedRadio}
                  label={textForLabel({
                    socTitle: choosenSocEditable?.title,
                    priceInfo: defaultPriceInfo,
                  })}
                  name="mainOption"
                  onChange={(e) =>
                    handleRadioChange(
                      { soc: e.target.value, spn: valueInputOnlyForEditableSocs },
                      true,
                    )
                  }
                  radioVariant="default"
                  testName="mainOption"
                  value={choosenSocEditable?.soc}
                />
              </div>

              <FieldInput
                errorMessage={errorText}
                helpMessage={helpMessage}
                onBlur={handleBlurInput}
                onChange={handleChangeInput}
                onFocus={handleFocusInput}
                placeholder={inputPlaceholder}
                soc={choosenOption?.soc}
                value={valueInputOnlyForEditableSocs}
              />
            </>
          )}

          <ChildSocsBlock
            childSocs={childSocs}
            choosenOption={choosenOption}
            isEditableSocSpn={false}
            onRadioChange={handleRadioChange}
          />
        </section>
      )}

      {!haveChildSocs && (
        <>
          <div className={cn('label')}>
            {textForLabel({ socTitle: choosenSocEditable?.title, priceInfo: defaultPriceInfo })}
          </div>

          <FieldInput
            errorMessage={errorText}
            helpMessage={helpMessage}
            onBlur={handleBlurInput}
            onChange={handleChangeInput}
            onFocus={handleFocusInput}
            placeholder={inputPlaceholder}
            soc={choosenOption?.soc}
            value={choosenOption?.spn}
          />
        </>
      )}
    </>
  );
};
