import React from 'react';
import classNames from 'classnames/bind';
import { PageFixer } from '@beef/smart-kit';
import { connect } from 'react-redux';

import { ErrorScreen } from 'pages-components/ErrorScreen';
// Styles

import style from './styles.pcss';
import { useErrorScreenContainerInit } from './hook';
import { selectErrorScreenStatus } from '../../store/selectors';
import { RootState } from '../../types';

const cn = classNames.bind(style);

const _ErrorScreenContainer = ({ data, errorScreenStatus }): JSX.Element => {
  const { isMobile, handleMainClick, handleAdditionalClick } = useErrorScreenContainerInit({
    data,
    errorScreenStatus,
  });

  return (
    <PageFixer size="l">
      <ErrorScreen
        {...data}
        classNameImg={cn('bg-image')}
        fullWidth={isMobile}
        onAdditionalClick={handleAdditionalClick}
        onClick={handleMainClick}
      />
    </PageFixer>
  );
};

const mapStateToProps = (state: RootState) => ({
  errorScreenStatus: selectErrorScreenStatus(state),
});

const ErrorScreenContainer = connect(mapStateToProps)(_ErrorScreenContainer);

export { ErrorScreenContainer };
