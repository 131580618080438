import React, { useCallback } from 'react';
import PropTypes, { number } from 'prop-types';
import { Device } from '@beef/smart-kit';
import { handleSuccessRequest } from '@beef/layout-kit/utils';
import { Provider, connect } from 'react-redux';
import { setCartData } from '@carnica/smart-kit';

import {
  devicePurchaseButtonDataHelper,
  getPurchaseButtonType,
} from 'utils/devicePurchaseButtonDataHelpers';
import { handleDevicePurchase } from 'utils/purchaseDeviceHelper';
import store, { headerStore2024 } from 'store';
import { pushAddToCart } from 'pages/SearchResultsPage/utils/analytics';
import { getBasketIdExpiration } from 'pages/SearchResultsPage/utils/getBasketIdExpiration';

export const _SmartDevice = ({
  setLoading,
  deviceData,
  isLoading,
  cartApi,
  purchasedItemsIds,
  handleSetCartData,
  onClick,
  nodeRef,
  basketIdExpiration,
}) => {
  const { isTariff, isServices, isPreorder, isElk, ...device } = deviceData;
  const inBasketItemsSet = new Set(purchasedItemsIds);
  const isInBasket = inBasketItemsSet.has(+device.id);

  const onPurchase = async (id) => {
    const { brand, price, name } = device;
    const expiration = getBasketIdExpiration(basketIdExpiration);

    if (id) {
      setLoading(id);
      try {
        await handleDevicePurchase({
          cartApi,
          id,
          simOrderPlace: 'searchPage',
          handleSetCartData,
          basketIdExpiration: expiration,
        });
        pushAddToCart({ id, brand, price, name });
      } catch (e) {
        console.error(e);
      }
    }
    setLoading('');
  };
  const redirectToCart = useCallback(() => handleSuccessRequest({ redirectToCart: true }), []);

  const type = getPurchaseButtonType({
    isInBasket,
    isPreorder,
    isService: isServices || isTariff,
    isElk,
  });

  const getOnClickFunction = () => {
    if (isInBasket) {
      return redirectToCart;
    }
    if (isTariff || isServices || isElk) {
      return onClick;
    }
    return onPurchase;
  };

  const purchaseData = devicePurchaseButtonDataHelper({
    type,
    isLoading,
    onClick: getOnClickFunction(),
  });

  return (
    <Device
      device={device}
      extraText={isTariff ? '/мес' : ''}
      isWithModal
      onOpenModal={onClick}
      purchaseData={purchaseData}
      ref={nodeRef}
    />
  );
};

_SmartDevice.propTypes = {
  deviceData: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  setLoading: PropTypes.func,
  cartApi: PropTypes.func,
  purchasedItemsIds: PropTypes.arrayOf(number),
  handleSetCartData: PropTypes.func,
  onClick: PropTypes.func,
  basketIdExpiration: PropTypes.string,
};

const mapStateToProps = ({ external }) => ({
  cartApi: external?.cartData?.cartApi,
  purchasedItemsIds: external?.cartData?.currentCart?.devices ?? [],
});

export const ConnectedComponent = connect(mapStateToProps, () => ({
  handleSetCartData: (data) => {
    headerStore2024().dispatch(setCartData(data));
  },
}))(_SmartDevice);

export const SmartDevice = (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
