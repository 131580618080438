import { MouseEvent, useCallback, useState } from 'react';
import { ChipsPropsType } from '@beef/ui-kit';

import { TModalActionBar } from '../../../modalComponents/ModalContainer/components/ModalActionBar';
import { TabTypes } from '../constants';
import { defineModalContent } from '../utils';
import { useSelectTabs } from '../selectors/useSelectTabs';
import { useSelectBaseUrl } from '../selectors/useSelectBaseUrl';
import { useSelectTariffTab } from '../selectors/useSelectTariffTab';
import { useGetOnChange } from './useGetOnChange';
import { useIsElkSettings } from '../../../../hooks/useIsElkSettings';
import { TModalHeader } from '../../../modalComponents/ModalHeader';
import { TModalContainer } from '../../../modalComponents/ModalContainer';
import { TTabTypesYm, sendPopupYm } from '../tempYmUtils';

/** Хук подготовки данных и обработчиков для компонента табов. */
export const usePrepareTabs = () => {
  const [isOpen, setIsOpen] = useState(false);

  /* Переменная для определения скрытия табов */
  const isHideTabs = useIsElkSettings();

  /* Базовый url тарифа */
  const baseUrl = useSelectBaseUrl();

  /* Текущий вариант (таб) способа подключения тарифа */
  const { currentTariffTab, currentViewedTariffTab } = useSelectTariffTab();

  /* Определяем, является ли текущий просматриваемый таб - ChangeTariffForm */
  const isChangeTariffFormTab = currentViewedTariffTab === TabTypes.ChangeTariffForm;

  /* Контентные данные для табов способа подключения тарифа */
  const { authModal, eSIMModal, options, connectionMethodTitle } = useSelectTabs();

  /* Контентные данные для попапов authModal/eSIMModal */
  const {
    link = '',
    title = '',
    extraLink,
    buttonText,
    description = '',
    buttonTextExtra,
  } = defineModalContent(authModal, eSIMModal, isChangeTariffFormTab) || {};

  /* Функция закрытия модального окна */
  const onClose = useCallback(() => setIsOpen(false), [isOpen]);

  /* Функция переключения состояния модального окна.
   * Т.к. в качестве "кнопки" используется компонент Link, требуется "оборвать" переход по href */
  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e?.preventDefault();
      setIsOpen((state) => !state);
      if (isChangeTariffFormTab) {
        handleExtraPushYm?.();
      }
    },
    [isOpen],
  );

  /* Функция обработки клика на таб тарифа (вариант подключения) */
  const onChange = useGetOnChange({ setIsOpen, currentTariffTab });

  /* Список попапов для метрики кнопок  */
  const tabsWithYm = [TabTypes.eSIMForm, TabTypes.ChangeTariffForm];

  /* Функция отправки аналитики при клике на основную кнопку модального окна */
  const handlePushYm =
    tabsWithYm.includes(currentViewedTariffTab) ?
      () => {
        sendPopupYm(buttonText || 'confirm', currentViewedTariffTab as TTabTypesYm);
      }
    : undefined;

  /* Функция отправки аналитики при клике на extra кнопку модального окна */
  const handleExtraPushYm =
    tabsWithYm.includes(currentViewedTariffTab) ?
      () => {
        sendPopupYm(buttonTextExtra || 'details', currentViewedTariffTab as TTabTypesYm);
      }
    : undefined;

  /* Пропы для китового компонента ChipsStateless */
  const chipsStatelessProps: ChipsPropsType = {
    options,
    onChange,
    variant: 'card',
    checkedValues: currentTariffTab as unknown as string,
  };

  /* Вариант размера модального окна в зависимости от того, является ли текущий просматриваемый таб ChangeTariffForm */
  const sizeType = isChangeTariffFormTab ? 's' : 'm';

  /* Вариант ссылки для главной кнопки модального окна. Если текущий просматриваемый таб
   * ChangeTariffForm (смена тарифа), то главная кнопка будет вести на страницу авторизации c returnUrl=${baseUrl} */
  const linkType = isChangeTariffFormTab ? `/login/?returnUrl=${baseUrl}` : link;

  /* Тип открытия ссылки в модальном окне.
   * Если текущий просматриваемый таб ChangeTariffForm, то ссылка открывается в текущем окне */
  const targetType = isChangeTariffFormTab ? '_parent' : '_blank';

  /* Обработчик главной кнопки в модальном окне.
   * Если в контенте НЕТ link (!link) и текущий просматриваемый таб НЕ ChangeTariffForm, запускаем handleClick */
  const onClickType = !link && !isChangeTariffFormTab ? handleClick : handlePushYm;

  /* Обработчик вспомогательной кнопки в модальном окне. Если в контенте НЕТ extraLink (!extraLink)
   * или текущий просматриваемый таб ChangeTariffForm, на доп. кнопке запускаем handleClick */
  const onClickExtraType = !extraLink || isChangeTariffFormTab ? handleClick : handleExtraPushYm;

  /* Пропы modalActionBarProps для обертки ModalContainer. Содержит нейминг кнопок, ссылки и т.д. */
  const modalActionBarProps: TModalActionBar = {
    link: linkType,
    extraLink,
    buttonText,
    buttonTextExtra,
    target: targetType,
    onClick: onClickType,
    onClickExtra: onClickExtraType,
  };

  /** Пропы для компонента шапки ModalHeader в ModalContainer. */
  const modalHeaderProps: TModalHeader = { title, description };

  /** Пропы для внешней обертки ModalContainer. */
  const modalContainerProps: Omit<TModalContainer, 'children'> = {
    isOpen,
    onClose,
    size: sizeType,
    modalActionBarProps,
  };

  return {
    title: connectionMethodTitle,
    modalHeaderProps,
    chipsStatelessProps,
    modalContainerProps,
    isHideTabs,
  };
};
