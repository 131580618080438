import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';

import OrderTariffForm from '../OrderTariffForm';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/* eslint-disable max-len */
const defaultData = {
  pageTitle: 'Заявка на подключение',
  houseConnectionStatusTexts: 'По указанному адресу недоступно подключение выбранных услуг',
  hasRequestOnAddressStatusText:
    'Мы уже обрабатываем заявку на эту квартиру. Наш оператор позвонит вам в течении 15 минут в рабочее время. Если вы не сможете принять звонок, мы перезвоним в течение часа. Обработка заявок производится с {{startTime}} до {{endTime}}',
  emptyListLabel: 'Улица не найдена',
  emptyListBackCall: 'Заказать обратный звонок',
  houseConnectionBtnTexts: 'Подключить дом',
  successFlatConnectionTexts: {
    textPrefix: 'По адресу ',
    textPostfixTemplate: ' подключение доступно',
  },
  specTariffSuccessFlatConnectionTexts: {
    withSpecTariff: {
      textPrefix: 'Поздравляем! По адресу ',
      textPostfixTemplate: ' доступен спецтариф «{{tariffName}}»',
    },
    withoutSpecTariff: {
      textPrefix: 'Поздравляем! По адресу ',
      textPostfixTemplate: ' уже представлен самый оптимальный тариф «{{tariffName}}»',
    },
  },
  supportSection: {
    supportText: 'Для оперативного решения вопроса позвоните по бесплатному телефону',
    supportPhone: '8 800 700 8000',
  },
  successFlatConnectionTemplate: '{{street}}, дом {{house}}, квартира {{flat}}',
  orderBackCallTexts: {
    requestTitle: 'Закажите обратный звонок',
    requestDescription: 'Мы вам всё подробно расскажем',
    requestOrderBackCallBtn: 'Позвоните мне',
    successTitle: 'Заявка принята',
    successDescription: 'Мы свяжемся с Вами в ближайшее возможное время.',
    successOrderBackCallBtn: 'Заказать ещё звонок',
    failTitle: 'Что-то пошло не&nbsp;так',
    failDescription: 'К сожалению, что-то пошло не так. Попробуйте позже',
    failOrderBackCallBtn: 'Попробовать ещё',
    wrongNumber: 'Неправильный формат номера телефона',
  },
  сontactsFormTexts: {
    contactsTitle: '',
    phoneInputLabel: 'Номер телефона',
    nameInputLabel: 'Как к вам обращаться',
  },
  flatConnectionFail:
    'Ваша квартира уже подключена.<br />Если вы хотите сделать второе подключение по<br />данному адресу, позвоните<br />нам по данному телефону 8 800 700 8378.',
  flatPartnerConnectedTemplate:
    'По адресу {{street}}, дом {{house}}, {{flat}} нет возможности подключения домашнего интернета',
  haveQuestionText: 'Остались вопросы? ',
  notFoundStreet: 'Не нашли улицу?',
  notFoundHouse: 'Не нашли дом?',
  notFoundAddressBtn: 'Закажите обратный звонок',
  haveQuestionLinkText: 'Закажите обратный звонок',
  userAgreementText:
    'Нажимая на кнопку «Оформить заявку» или «Закажите обратный звонок», вы предоставляете ПАО «ВымпелКом» согласие на обработку персональных данных',
  termsOfUse:
    'ПАО «ВымпелКом» (127083, г. Москва, ул. Восьмого марта, дом 10, строение 14) обрабатывает указанные в форме персональные данные в течение 1 месяца с целью получения консультации путем сбора, записи, систематизации, накопления, хранения, уточнения (обновления, изменения), извлечения, использования, передачи (предоставления, доступа), обезличивания, блокирования, удаления, уничтожения, как с использованием, так и без использования средств автоматизации в соответствии с Политикой «Обработка персональных данных». Отзыв согласия на обработку персональных данных направляется по форме, размещенной на сайте в сети интернет (www.beeline.ru).',
  specTariff: {
    pageTitle: 'Проверка адреса',
    checkAddressBtnText: 'Проверить адрес',
  },
  priceSection: {
    tariffTitleText: 'Тариф «{{tariffName}}»',
    bundleTitleText: 'Пакет «{{tariffName}}»',
    wifiIncluded: 'Wi-Fi роутер в рассрочку',
    selectedWifiRouter: 'Wi-Fi роутер',
    oldFeeUnit: '₽',
    wifiNotIncluded: 'Wi-Fi роутер не входит',
    tvDeviceIncluded: 'ТВ-приставка в аренду',
    tvDeviceNotIncluded: 'ТВ-приставка не входит',
    orderBtnText: 'Оформить заявку',
    phoneInputLabel: 'Номер телефона',
    additionalTvPackageText: 'Дополнительные ТВ-пакеты',
    additionalServicesText: 'Дополнительные услуги',
    addedAntivirusText: 'Антивирусы',
  },
};

/* eslint-enable max-len */

const FullFormPopup = (props) => {
  const {
    isFttbWithTv,
    isOpen,
    buyTVEData,
    promoTariff,
    onClose,
    onConnectHouse,
    onOpenBackCallPopup,
    getAllSelectedServicesForTariff,
    getAllExcludedServicesForTariff,
    connectingRequestUrl,
    tariffRequestData,
    checkSpecTariff,
    shouldFetchSpecTariff,
    checkFlatOnInitial,
    availableScheduleData,
    data,
    connectionData,
  } = props;

  return (
    <PopupStateless className={cx('popup')} onClose={onClose} opened={isOpen}>
      <OrderTariffForm
        availableScheduleData={availableScheduleData}
        buyTVEData={buyTVEData}
        checkFlatOnInitial={checkFlatOnInitial}
        checkSpecTariff={checkSpecTariff}
        connectingRequestUrl={connectingRequestUrl}
        connectionData={connectionData}
        data={data}
        getAllExcludedServicesForTariff={getAllExcludedServicesForTariff}
        getAllSelectedServicesForTariff={getAllSelectedServicesForTariff}
        isFttbWithTv={isFttbWithTv}
        onCityChange={onClose}
        onConnectHouse={onConnectHouse}
        onOpenBackCallPopup={onOpenBackCallPopup}
        promoTariff={promoTariff}
        shouldFetchSpecTariff={shouldFetchSpecTariff}
        tariffRequestData={tariffRequestData}
      />
    </PopupStateless>
  );
};

FullFormPopup.defaultProps = {
  data: defaultData,
};
FullFormPopup.propTypes = {
  promoTariff: PropTypes.object.isRequired,
  data: PropTypes.object,
  buyTVEData: PropTypes.object,
  tariffRequestData: PropTypes.object,
  availableScheduleData: PropTypes.object,
  connectionData: PropTypes.object,

  connectingRequestUrl: PropTypes.string.isRequired,

  onClose: PropTypes.func.isRequired,
  onConnectHouse: PropTypes.func.isRequired,
  onOpenBackCallPopup: PropTypes.func.isRequired,

  getAllSelectedServicesForTariff: PropTypes.func,
  getAllExcludedServicesForTariff: PropTypes.func,

  isOpen: PropTypes.bool.isRequired,
  isFttbWithTv: PropTypes.bool,
  checkSpecTariff: PropTypes.bool,
  shouldFetchSpecTariff: PropTypes.bool,
  checkFlatOnInitial: PropTypes.bool,
};

export default FullFormPopup;
