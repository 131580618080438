import { times } from '@beef/utils';

import { DEFAULT_DEF_CODE, FILTER_TAIL } from '../../constants';

export const mapActiveCategoriesToArray = (categories) =>
  Object.keys(categories).filter((category) => !!categories[category]);

const getNumberTailPattern = (value) =>
  `${times(7 - value.length, () => DEFAULT_DEF_CODE[0]).join('')}${value}`;
const getNumberCommonPattern = (value) =>
  times(7, (index) => value[index] || DEFAULT_DEF_CODE[0]).join('');

export const getFancyNumberPattern = (filter, value) =>
  filter === FILTER_TAIL ? getNumberTailPattern(value) : getNumberCommonPattern(value);
