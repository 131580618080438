import { DEFAULT_INDEX } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/constants';

import { useHandleSelectingData } from './useHandleSelectingData';

/** Хук для выбора даты слотов монтажника и передачи выбранной
 * даты и периода времени для этой даты */
export const useSelectedDateSlots = () => {
  const { dateLabel, onChange, options, selectedDate } = useHandleSelectingData();

  return {
    isDateSlot: true,
    label: dateLabel,
    onChange,
    options,
    value: selectedDate || options?.[DEFAULT_INDEX]?.id,
  };
};
