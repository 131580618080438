import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { Heading, ScrollContainer } from '@beef/legacy-ui-kit';

import { formatDate, formatPhone } from 'utils/format-string';

import styles from './BlackListRecentCalls.pcss';

const cx = classNames.bind(styles);

class BlackListRecentCalls extends Component {
  renderCalls() {
    return (
      <ScrollContainer>
        <table className={cx('table')}>
          <thead>
            <tr>
              <th>Номер телефона</th>
              <th>Время последнего звонка</th>
              <th>Количество звонков</th>
            </tr>
          </thead>
          <tbody>
            {this.props.calls.map((call) => (
              <tr>
                <td>{call.ctn}</td>
                <td dangerouslySetInnerHTML={{ __html: formatDate(new Date(call.time)) }} />
                <td>{call.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ScrollContainer>
    );
  }

  render() {
    return (
      <div>
        <div className={cx('calls')}>
          <Heading level={1}>Звонки с запрещённых номеров за последние сутки</Heading>
          {this.props.calls.length ?
            this.renderCalls()
          : <div>Звонков с номеров из черного списка за последние сутки не было</div>}
        </div>
      </div>
    );
  }
}

BlackListRecentCalls.defaultProps = {
  calls: [],
};

BlackListRecentCalls.propTypes = {
  calls: PropTypes.array,
};

export default connect((state) => {
  const calls = pathOr(
    [],
    ['external', 'serviceStatus', 'data', 'recentCalls', 'res', 'data', 'data'],
    state,
  ).map(({ ctn, time, count }) => ({ ctn: formatPhone(ctn), time, count }));
  return {
    calls,
  };
})(BlackListRecentCalls);
