import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Heading } from '@beef/legacy-ui-kit';

import ServiceParameter from '../ServiceParameter';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ParametersBlock = ({ heading, serviceError, parameters, type, ...props }) => {
  if (serviceError) {
    return null;
  }
  return (
    <div className={cx('parametersContainer')}>
      <Heading className={cx('subHeading')} isStyled={false} level={4}>
        {heading}
      </Heading>
      {parameters?.map?.((parameter) => (
        <div className={cx('parameter')} key={parameter.key || parameter.label}>
          <ServiceParameter
            configOptions={{ ...props }}
            parametersOptions={{ ...parameter }}
            type={type}
          />
        </div>
      ))}
    </div>
  );
};

ParametersBlock.propTypes = {
  heading: PropTypes.string,
  parameters: PropTypes.array,
  type: PropTypes.string,
  serviceError: PropTypes.bool,
};

export default ParametersBlock;
