import { cloneElement } from 'react';
import { connect } from 'react-redux';

import { getPlaceForAnalytics, getTerm } from 'pages/SearchResultsPage/store/selectors';
import { PriceAnalyticsPusher } from 'pages/SearchResultsPage/utils/analytics';

const _PriceWithAnalytics = ({ children, location, term }) => {
  const handleChange = (e) => {
    children.props.onChange?.(e);
    PriceAnalyticsPusher.location = location;
    PriceAnalyticsPusher.pushClickEvent({ [`${e.min}-${e.max}`]: term });
  };
  return cloneElement(children, { onChange: handleChange });
};

export const PriceWithAnalytics = connect((state) => ({
  location: getPlaceForAnalytics(state),
  term: getTerm(state),
}))(_PriceWithAnalytics);
