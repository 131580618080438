export const resultsLandingDataConverter = ({ data: content, votesAvailable, ...restData }) => ({
  content: {
    ...content,
    meta: restData,
  },
  votes: {
    available: votesAvailable.reduce(
      (acc, region) => ({
        ...acc,
        [region.regionName]: region.voteAvailable,
      }),
      {},
    ),
  },
});

export const pimpMyBeepDataConverter = ({ data: content, ...restData }) => ({
  content: {
    ...content,
    meta: restData,
  },
});

export const employeesDataConverter = pimpMyBeepDataConverter;
export const finalDataConverter = pimpMyBeepDataConverter;
export const finalResultsDataConverter = pimpMyBeepDataConverter;
