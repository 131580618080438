import React, { FC, SVGAttributes } from 'react';

/** Компонент иконки-заглушки для плиток ServiceTile */
export const DefaultIcon: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    fill="none"
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.166 30.4001L42.57 31.8421C42.7183 31.9309 42.841 32.0566 42.9263
          32.207C43.0115 32.3574 43.0563 32.5273 43.0563 32.7001C43.0563 32.873 43.0115
          33.0429 42.9263 33.1933C42.841 33.3436 42.7183 33.4694 42.57 33.5581L25.03
          44.0821C24.7189 44.269 24.3628 44.3678 24 44.3678C23.6371 44.3678 23.281
          44.269 22.97 44.0821L5.42995 33.5581C5.28165 33.4694 5.15888 33.3436
          5.07365 33.1933C4.98841 33.0429 4.9436 32.873 4.9436 32.7001C4.9436
          32.5273 4.98841 32.3574 5.07365 32.207C5.15888 32.0566 5.28165 31.9309
          5.42995 31.8421L7.83395 30.4001L24 40.1001L40.166 30.4001ZM40.166 21.0001L42.57
          22.4421C42.7183 22.5309 42.841 22.6566 42.9263 22.807C43.0115 22.9574 43.0563
          23.1273 43.0563 23.3001C43.0563 23.473 43.0115 23.6429 42.9263 23.7933C42.841
          23.9436 42.7183 24.0694 42.57 24.1581L24 35.3001L5.42995 24.1581C5.28165 24.0694
          5.15888 23.9436 5.07365 23.7933C4.98841 23.6429 4.9436 23.473 4.9436 23.3001C4.9436
          23.1273 4.98841 22.9574 5.07365 22.807C5.15888 22.6566 5.28165 22.5309 5.42995
          22.4421L7.83395 21.0001L24 30.7001L40.166 21.0001ZM25.028 2.61814L42.57 13.1421C42.7183
          13.2309 42.841 13.3566 42.9263 13.507C43.0115 13.6574 43.0563 13.8273 43.0563 14.0001C43.0563
          14.173 43.0115 14.3429 42.9263 14.4933C42.841 14.6436 42.7183 14.7694 42.57 14.8581L24
          26.0001L5.42995 14.8581C5.28165 14.7694 5.15888 14.6436 5.07365 14.4933C4.98841 14.3429
          4.9436 14.173 4.9436 14.0001C4.9436 13.8273 4.98841 13.6574 5.07365 13.507C5.15888 13.3566
          5.28165 13.2309 5.42995 13.1421L22.97 2.61814C23.281 2.43125 23.6371 2.33252 24 2.33252C24.3628
          2.33252 24.7189 2.43125 25.03 2.61814H25.028ZM24 6.66414L11.774 14.0001L24 21.3361L36.226
          14.0001L24 6.66414Z"
      fill="white"
    />
  </svg>
);
