import React from 'react';
import { LandingConstructor } from '@beef/landing-constructor';
import { PageRow, TeaserBanner } from '@beef/smart-kit';
import classNames from 'classnames/bind';

import style from './styles.module.pcss';
import { DetailsTeaserApp } from './components/DetailsTeaserApp';

const cn = classNames.bind(style);

type MobileAppLanding2023Type = {
  data: {
    blocks: any[];
  };
};

export const MobileAppLanding2023 = ({ data }: MobileAppLanding2023Type) => {
  const teaserAppData = data.blocks.find((block) => block.type === 'teaserApp');
  return (
    <>
      {teaserAppData?.data && (
        <PageRow blockSpacersPreset="m" tagName="section">
          <div className={cn('teaser')}>
            <TeaserBanner
              {...teaserAppData.data}
              detailsNode={<DetailsTeaserApp {...teaserAppData.data} />}
            />
          </div>
        </PageRow>
      )}
      <LandingConstructor {...data} />
    </>
  );
};
