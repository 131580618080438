// Utils
import { useIsDesktopDimension, useIsTabletDimension } from 'utils/hooks/useGetDimensionGroup';

/**
 *@description хук для определения значения innerVerticalSpace у PageRow;
 *@param {{ mobile: number, tablet: number, desktop: number } | undefined}
 * { mobile: number, tablet: number, desktop: number } данные для отступов могут быть разные в макете
 *@returns { number }
 */

const usePageRow = (options = { mobile: 40, tablet: 64, desktop: 80 }) => {
  const { mobile, tablet, desktop } = options;

  let value = mobile;
  const isTablet = useIsTabletDimension();
  const isDesktop = useIsDesktopDimension();

  if (isTablet) value = tablet;
  if (isDesktop) value = desktop;

  return value;
};

export { usePageRow };
