import { FontSizeType } from '@beef/ui-kit';

import { TabTypes } from '../../Tabs/constants';
import { useSelectTariffTab } from '../../Tabs/selectors/useSelectTariffTab';
import { useSelectServices } from '../../ServiceTiles/selectors/useSelectServices';
import { useIsConvergentUser } from '../../../../hooks/tariffStateHooks/useIsConvergentUser';
import { useSelectActiveInacId } from '../../FmcTabsContainer/selectors/useSelectActiveInacId';

/** Хук подготовки данных для бока конвергенции и ТВ */
export const usePrepareConvergence = () => {
  const isConvergentUser = useIsConvergentUser();
  const { convergenceBlock } = useSelectServices();
  const { currentTariffTab } = useSelectTariffTab();
  const { convergenceInfo, convergenceServices } = convergenceBlock || {};
  const { title = '', infoBlock } = convergenceInfo || {};
  const activeInacId = useSelectActiveInacId();

  /** Если пользователь НЕ является конвергентом (БЕЗ ШПД)
   * или текущий таб способа подключения тарифа НЕ ChangeTariffForm,
   * покажем доп. сервисы (кино и тв, например) */
  const isUserAvailable = !isConvergentUser || currentTariffTab !== TabTypes.ChangeTariffForm;
  /** При доработке ЕК BCP-19435 убираем показ сервисов на табе без интернета */
  const isServiceAvailable = !convergenceServices && activeInacId !== 'withoutInternet';
  const showServices = isUserAvailable && isServiceAvailable;

  const convergenceProps = {
    showServices,
    services: convergenceServices || [],
    type: 'tv',
    /** Объект пропов для компонента TitleContent (title/description/icon) */
    titleContentProps: {
      size: 'size2-m' as FontSizeType,
      extraSize: 'size5-r-s' as FontSizeType,
      title,
      icon: infoBlock?.icon || '',
      textColor: infoBlock?.textColor || '',
      description: infoBlock?.description || '',
    },
  };

  return { convergenceProps };
};
