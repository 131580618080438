import React from 'react';
import { Provider, connect } from 'react-redux';

import {
  initConnectionPopup,
  toggleServiceConnectionPopup,
} from 'pages/ProductCard/actions/serviceConnect';
import store from 'store';
import { IklPage } from 'pages/PclLandingPage/IklPage';

const mapStateToProps = (state) => ({
  currentCtn: state.external.currentCtn || state.external.user,
  authenticated:
    (state.external.currentCtn && !state.external.currentCtn.isXbr) ||
    (state.external.user && !state.external.user.isXbr),
  userData: {
    ctn: state.external.user?.ctn,
    fttb: state.external.user?.fttbNumber,
    type:
      (
        !state.external.user?.hasOwnProperty('login') ||
        state.external.user?.isConvergent ||
        state.external.user?.isMobile
      ) ?
        0
      : 1,
  },
});

const mapDispatchToProps = (dispatch) => ({
  setConnectPopupData: () => {
    dispatch(initConnectionPopup());
  },
  openConnectPopup: () => {
    dispatch(toggleServiceConnectionPopup(true));
  },
});

const IKLConnected = connect(mapStateToProps, mapDispatchToProps)(IklPage);

export const PclLandingPage = (props) => {
  if (!props?.data?.extData?.pclApiEnabled) {
    return null;
  }
  return (
    <Provider store={store}>
      <IKLConnected {...props} />
    </Provider>
  );
};
