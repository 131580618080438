import React from 'react';
import { Text } from '@beef/ui-kit';
import classnames from 'classnames/bind';

import styles from './styles.pcss';
import type { TDateTimeChip } from './types';

const cx = classnames.bind(styles);

/** Компонент-кнопка чипс. */
export const DateTimeChip = ({
  dayOfWeek,
  isActive,
  isDateSlot,
  label,
  onClick,
}: TDateTimeChip) => (
  <button
    className={cx('chip', { _date: isDateSlot }, { _active: isActive })}
    onClick={onClick}
    type="button"
  >
    {label && (
      <Text className={cx('value')} size="size5-m">
        {label}
      </Text>
    )}
    {dayOfWeek && (
      <Text className={cx('time_value')} size="size6-r">
        {dayOfWeek}
      </Text>
    )}
  </button>
);
