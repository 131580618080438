import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

import { Description } from './components/Description';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const DescriptionLayout = ({ description, notification, discountText, subDescription }) => (
  <div className={cx('wrapper')}>
    {notification ?
      <Text color="grey60" size="size4-r">
        {notification}
      </Text>
    : <Description
        description={description}
        discountText={discountText}
        subDescription={subDescription}
      />
    }
  </div>
);

DescriptionLayout.propTypes = {
  description: PropTypes.string,
  discountText: PropTypes.string,
  notification: PropTypes.string,
  subDescription: PropTypes.string,
};

export { DescriptionLayout };
