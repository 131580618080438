import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Emoji, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { getConnectionData } from 'pages/FTTB/selectors/connectionState';
import { declension, templateParser } from 'utils/format-string';
import { getTariffsTitle } from 'pages/FTTB/StreetCardPage/utils';
import FullFormPopup from 'pages/FTTB/components/FullFormPopup';
import BackCallPopup from 'pages/FTTB/components/BackCallPopup';
import PopupRequestHouse from 'pages/AllInOneForm/components/PopupRequestHouse/PopupRequestHouse';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class TariffCard extends PureComponent {
  state = {
    showFullFormPopup: false,
    showPopupRequestHouse: false,
    showBackCallPopup: false,
  };

  getParamsData(alias, value) {
    let parametersText;
    let emojiName;
    let label;
    const {
      speedParamTemplate,
      channelsDeclension,
      minutsDeclension,
      speedParamLabel,
      tvParamLabel,
      mobileParamLabel,
    } = this.props.texts;
    switch (alias) {
      case 'speed':
        parametersText = templateParser(speedParamTemplate, { speed: value });
        emojiName = 'rocket';
        label = speedParamLabel;
        break;
      case 'tv':
        parametersText = `${value} ${declension(channelsDeclension)}`;
        emojiName = 'popcorn';
        label = tvParamLabel;
        break;
      case 'mobile':
        parametersText = `${value} ${declension(minutsDeclension)}`;
        emojiName = 'smartphone';
        label = mobileParamLabel;
        break;
      default:
        break;
    }

    return {
      parametersText,
      emojiName,
      label,
    };
  }

  openFullFormPopup = () => this.setState({ showFullFormPopup: true });

  closeFullFormPopup = () => this.setState({ showFullFormPopup: false });

  showPopupRequestHouse = () => {
    this.closeFullFormPopup();
    this.setState({ showPopupRequestHouse: true });
  };

  closePopupRequestHouse = () => this.setState({ showPopupRequestHouse: false });

  openBackCallPopup = () => {
    this.closeFullFormPopup();
    this.setState({ showBackCallPopup: true });
  };

  closeBackCallPopup = () => {
    this.setState({ showBackCallPopup: false });
  };

  handleButtonClick = () => {
    if (this.props.forceShowBackCallPopup) {
      this.openBackCallPopup();
    } else {
      this.openFullFormPopup();
    }
  };

  render() {
    const {
      className,
      tariff,
      texts,
      connectionData,
      backCallPopupData,
      orderFormData,
      availableScheduleData,
    } = this.props;
    const { showFullFormPopup, showPopupRequestHouse, showBackCallPopup } = this.state;
    const { type, url, requestUrl, params, price, promoTariff } = tariff;
    const { pricePerMounth, connectTariffBtn, pricePrefix } = texts;

    const { street, house } = connectionData || {};

    const title = getTariffsTitle(type, texts);

    return (
      <div className={cx('tariffCard', className)}>
        <div className={cx('tariffCardContainer')}>
          <div className={cx('tariffTitle')}>
            <Link className="hideVisitedColor" href={url}>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Link>
          </div>

          <div className={cx('paramsList')}>
            {params.map(({ alias, value }) => {
              const { parametersText, emojiName, label } = this.getParamsData(alias, value);

              return (
                <div className={cx('param')} key={alias}>
                  <div className={cx('paramLabel')} dangerouslySetInnerHTML={{ __html: label }} />
                  <div className={cx('paramValue')}>
                    <span dangerouslySetInnerHTML={{ __html: parametersText }} />
                    <Emoji className={cx('paramEmoji')} name={emojiName} />
                  </div>
                </div>
              );
            })}
          </div>

          <div className={cx('priceSection')}>
            <span className={cx('pricePrefix')} dangerouslySetInnerHTML={{ __html: pricePrefix }} />
            <span className={cx('price')}>{price}</span>
            <span
              className={cx('pricePerMounth')}
              dangerouslySetInnerHTML={{ __html: ` ${pricePerMounth}` }}
            />
          </div>

          <Button onClick={this.handleButtonClick} wide>
            {connectTariffBtn}
          </Button>
        </div>

        <div>
          <div className={cx('fullFormWrapper')}>
            <FullFormPopup
              availableScheduleData={availableScheduleData}
              connectingRequestUrl={requestUrl}
              connectionData={connectionData}
              data={orderFormData}
              isOpen={showFullFormPopup}
              onClose={this.closeFullFormPopup}
              onConnectHouse={this.showPopupRequestHouse}
              onOpenBackCallPopup={this.openBackCallPopup}
              promoTariff={promoTariff}
            />
          </div>

          <PopupRequestHouse
            house={house}
            onClose={this.closePopupRequestHouse}
            popupIsOpen={showPopupRequestHouse}
            street={street}
          />

          {showBackCallPopup && (
            <BackCallPopup
              analyticTariffDetails={{
                ordered_itemTitle: promoTariff.title,
                ordered_itemPrice: tariff.price,
                ordered_itemAlias: promoTariff?.alias,
                ordered_itemDiscount: null,
              }}
              data={backCallPopupData}
              forceShowBackCallPopup={this.props.forceShowBackCallPopup}
              isOpen={showBackCallPopup}
              onClose={this.closeBackCallPopup}
              showCaptcha={backCallPopupData.showCaptcha}
            />
          )}
        </div>
      </div>
    );
  }
}

TariffCard.propTypes = {
  className: PropTypes.string,
  forceShowBackCallPopup: PropTypes.bool,
  connectionData: PropTypes.object,
  backCallPopupData: PropTypes.object,
  orderFormData: PropTypes.object,
  availableScheduleData: PropTypes.object,
  tariff: PropTypes.shape({
    type: PropTypes.number,
    url: PropTypes.string,
    requestUrl: PropTypes.string,
    promoTariff: PropTypes.object,
    params: PropTypes.arrayOf(
      PropTypes.shape({
        alias: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    price: PropTypes.number,
  }),
  texts: PropTypes.shape({
    pricePerMounth: PropTypes.string,
    speedParamTemplate: PropTypes.string,
    channelsDeclension: PropTypes.arrayOf(PropTypes.string),
    minutsDeclension: PropTypes.arrayOf(PropTypes.string),
    connectTariffBtn: PropTypes.string,
    moreInfo: PropTypes.string,
    kitTariffTitle: PropTypes.string,
    internetTariffTitle: PropTypes.string,
    tvTariffTitle: PropTypes.string,
    speedParamLabel: PropTypes.string,
    pricePrefix: PropTypes.string,
    tvParamLabel: PropTypes.string,
    mobileParamLabel: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  connectionData: getConnectionData(state),
});

export default connect(mapStateToProps)(TariffCard);
