import { FontSizeType } from '@beef/ui-kit';

import { useSelectServices } from '../../ServiceTiles/selectors/useSelectServices';
import { useSelectContent } from '../../ServiceTiles/selectors/useSelectContent';

/** Хук подготовки данных для бока доп. сервисов (семья) */
export const usePrepareExtraServices = () => {
  const { extraServicesTitle } = useSelectContent();
  const { extraServices } = useSelectServices();

  const extraServicesProps = {
    services: extraServices || [],
    type: 'service',
    /** Объект пропов для компонента TitleContent (title/description/icon) */
    titleContentProps: {
      size: 'size2-m' as FontSizeType,
      extraSize: 'size5-r-s' as FontSizeType,
      title: extraServicesTitle || '',
      icon: '',
      textColor: '',
      description: '',
    },
  };

  return { extraServicesProps };
};
