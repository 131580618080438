import React from 'react';

import FormProvider from './FormProvider';
import BroadbandLinks from '../Links/BroadbandLinks';
import StandardLinks from '../Links/StandardLinks';

interface IProps extends Record<string, any> {
  haveBroadband: boolean;
}

const ConfiguredFormProvider: React.FC<IProps> = (props) => (
  // @ts-expect-error legacina
  <FormProvider {...props} links={props.haveBroadband ? BroadbandLinks : StandardLinks} />
);

export default ConfiguredFormProvider;
