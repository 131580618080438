import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Modal } from '@beef/ui-kit';

import { ModalActionBar } from '../ModalActionBar';
import { ModalContent } from '../ModalContent';

const ModalContainer = ({ icon, link, title, isOpen, buttonText, description, handleModal }) => (
  <Modal
    actionBar={<ModalActionBar buttonText={buttonText} handleModal={handleModal} />}
    isOpen={isOpen}
    onClose={handleModal}
    size="m"
  >
    <ModalContent description={description} icon={icon} link={link} title={title} />
  </Modal>
);

ModalContainer.propTypes = {
  icon: PropTypes.node,
  isOpen: PropTypes.bool,
  link: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  handleModal: PropTypes.func,
};

export { ModalContainer };
