import React from 'react';

import ServicesExtension from 'pages/ProductCard/components/ServicesExtension/ServicesExtension';
import { ProvidedSharingServiceExtension } from 'pages/ProductCard/components/ServicesExtension/SharingService/SharingService';

import TariffCard from '../ProductCard/Tariff';

export const CommonTariffCard = (props) => (
  <TariffCard {...props} ServicesExtension={ServicesExtension} />
);
export const SharingServiceExtension = ProvidedSharingServiceExtension;
