import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

// Components
import { Tooltip } from '@beef/legacy-ui-kit';

// Styles
import classNames from 'classnames/bind';

import Ctx from 'pages-components/Ctx';

import styles from './SimOrderBenefits.pcss';

const cx = classNames.bind(styles);

class SimOrderBenefits extends Component {
  renderBenefit = (benefit, className) => {
    const iconAlias = typeof benefit === 'object' ? benefit.icon : benefit;
    const textAlias = typeof benefit === 'object' ? benefit.textField : benefit;
    const text = this.props.benefitText || Ctx.simOrderBenefits[textAlias];
    const benefitIcon =
      this.props.benefitIcon ||
      `https://static.beeline.ru/upload/images/b2c/icons/${iconAlias}.svg`;

    return (
      <div className={cx('column', className)}>
        <div className={cx('icon')}>
          <img alt="" src={benefitIcon} />
        </div>
        <div className={cx('text')} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    );
  };

  render() {
    const { benefits, showAgreement, className, agreementClassName } = this.props;
    const benefitsToRender = typeof benefits === 'string' ? [benefits] : benefits;

    return (
      <div className={cx('wrapper', className)}>
        <div className={cx('benefitsWrapper')}>
          {benefitsToRender.length === 1 ?
            this.renderBenefit(benefitsToRender[0], 'singleBenefit')
          : benefitsToRender.map((alias, i) => (
              <Fragment key={i}>{this.renderBenefit(alias)}</Fragment>
            ))
          }
        </div>
        {showAgreement && (
          <div className={cx('agreement', agreementClassName)}>
            Оставляя контактный номер, вы подтверждаете, что ввели свой номер самостоятельно и
            соглашаетесь с передачей ваших персональных данных.
            <Tooltip>
              Мы зарезервируем для вас SIM в одном из офисов или, по вашему желанию, бесплатно
              доставим SIM вам в руки. Для доставки мы передадим ваши персональные данные (ФИО,
              адрес доставки и номер телефона) партнёрам – курьерским компаниям: ООО «Курьер-Регион
              Столица» (119270, г. Москва, Лужнецкая наб., д. 2/4, стр. 59, этаж 3, офис 308), ООО
              «Портал» (143026, г. Москва, территория инновационного центра «Сколково», ул. Нобеля,
              д. 5), ООО «АЙ ЭМ ЭЛ» (125130, г. Москва, Выборгская улица, д.22, стр.1, этаж 1, пом.
              I, ком. 31.
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}

SimOrderBenefits.defaultProps = { benefits: ['help', 'mnp'] };

SimOrderBenefits.propTypes = {
  benefits: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      icon: PropTypes.string,
      textField: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  benefitText: PropTypes.string,
  benefitIcon: PropTypes.string,
  showAgreement: PropTypes.bool,
  className: PropTypes.any,
  agreementClassName: PropTypes.any,
};

export default SimOrderBenefits;
