import React, { memo } from 'react';
import classnames from 'classnames/bind';

import { GridCell } from '../GridCell';
import styles from './styles.module.pcss';

const cx = classnames.bind(styles);

export const CategoriesGrid = memo(({ list }) => (
  <div className={cx('categories-grid')}>
    {list?.map((item, index) => (
      <GridCell
        className={cx('categories-grid__cell')}
        color={item.color}
        href={item.href}
        image={item.image}
        key={index}
        links={item.links}
        title={item.title}
      />
    ))}
  </div>
));
