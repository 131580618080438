import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import TariffCardDetails from 'pages/ProductCard/Tariff/components/TariffCardDetails';
import TariffControlCards from 'pages/ProductCard/Tariff/components/TariffControlCards';
import PageLevel from 'pages/ProductCard/Tariff/components/PageLevel';
import ProductFeatures from 'pages/ProductCard/components/ProductFeatures';
import MobileApps from 'pages/ProductCard/components/MobileApps';
import ConnectionSteps from 'pages/ProductCard/components/ConnectionSteps';
import TariffConnectPopup from 'pages/ProductCard/components/TariffConnectPopup/TariffConnectPopup';
import { CONTROL_TYPES } from 'utils/partnershipTariffHelper';
import {
  benefitsShape,
  packageItemShape,
  priceItemShape,
  priceShape,
  subscriptionShape,
} from 'pages/ProductCard/Tariff/containers/tariffPropTypes';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);
const STICKY_CHANGE_STATE_GAP = 40;

const PartnerShipTariff = ({
  pageTitle,
  price,
  benefits,
  prices,
  setESimOpenModal,
  content,
  packages,
  subscription,
  isConnected,
  availableCards,
  isAuthenticated,
  availableInShop,
  showDiscountParams,
}) => {
  const calcEndBlockRef = useRef(null);
  const [detailsBlockStick, setDetailsBlockStick] = useState(false);
  const [cardControlOptions, setCardControlOptions] = useState([]);

  useEffect(() => {
    let res = [];

    if (availableCards.mnp) {
      res = [...res, CONTROL_TYPES.mnp];
    }

    if (availableCards.esim) {
      res = [...res, CONTROL_TYPES.esim];
    }
    setCardControlOptions(res);
  }, [availableCards, setCardControlOptions]);

  useEffect(() => {
    if (isConnected) {
      return;
    }
    const onScroll = () => {
      if (calcEndBlockRef?.current) {
        const windowScroll = window.scrollY;
        const elOffsetTop = calcEndBlockRef.current.getBoundingClientRect().top + window.scrollY;
        setDetailsBlockStick(windowScroll - elOffsetTop - STICKY_CHANGE_STATE_GAP > 0);
      }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, [setDetailsBlockStick, calcEndBlockRef]);
  return (
    <div>
      <div className={cx('visual')}>
        <div className={cx('visual__content')}>
          {!!content.pageSubtitle && (
            <div className={cx('page-subtitle')}>
              <Text size="size5-r-s">{content.pageSubtitle}</Text>
            </div>
          )}
          {pageTitle && (
            <Text size="size1-m" tagType="h1">
              <span className={cx('page-title')}>{pageTitle}</span>
            </Text>
          )}
        </div>
      </div>
      <PageLevel contentSize="s">
        <TariffCardDetails
          availableInShop={availableInShop}
          benefits={benefits}
          isAuthenticated={isAuthenticated}
          isConnected={isConnected}
          packages={packages}
          packagesTitle={content.packagesTitle}
          pageTitle={pageTitle}
          price={price}
          prices={prices}
          showDiscountParams={showDiscountParams}
          sticky={detailsBlockStick}
          subscription={subscription}
          summaryContent={content.summary}
          usefulLinks={content.usefulLinks}
        />
      </PageLevel>
      <div ref={calcEndBlockRef} />
      {content.controlCardsContent && cardControlOptions?.length > 0 && (
        <PageLevel color="grey-30" contentSize="s" size="m">
          <TariffControlCards
            availableControls={cardControlOptions}
            controlCardsContent={content.controlCardsContent}
            setESimOpenModal={setESimOpenModal}
          />
        </PageLevel>
      )}
      {content.productFeatures && (
        <PageLevel color="grey-5" contentSize="m" size="l">
          <ProductFeatures featuresList={content.productFeatures} />
        </PageLevel>
      )}
      {content.connectionSteps && (
        <PageLevel contentSize="s" size="s">
          <ConnectionSteps
            availableInShop={availableInShop}
            steps={content.connectionSteps.steps}
            title={content.connectionSteps.title}
          />
        </PageLevel>
      )}
      {content.mobileApps && (
        <PageLevel color="white" contentSize="m" size="m">
          <MobileApps content={content.mobileApps} />
        </PageLevel>
      )}
      <TariffConnectPopup
        cartAnalyticsData={{
          mnpAnalyticsPlace: 'Tariff_card',
          mnpAnalyticsCategory: 'tariffs/details',
          cartAnalyticsCategory: 'tariffs/details',
          cartAnalyticsList: 'Tariff_card',
        }}
        includeNumber={false}
      />
    </div>
  );
};

PartnerShipTariff.propTypes = {
  /* заголовок (название тарифа) */
  pageTitle: PropTypes.string,

  /* абонентская плата */
  price: priceShape,
  /* доп плюшки тарифа */
  benefits: PropTypes.arrayOf(benefitsShape),
  /* детальная стоимость тарифа */
  prices: PropTypes.arrayOf(priceItemShape),
  /* контент для страницы */
  content: PropTypes.object,
  /* доступные пакеты тарифа */
  packages: PropTypes.arrayOf(packageItemShape),
  /* данные для блока с подпиской */
  subscription: subscriptionShape,
  /* опции для карточек управления тарифа */
  availableCards: PropTypes.shape({
    esim: PropTypes.bool,
    mnp: PropTypes.bool,
  }),

  /* открытие окна заказа есим */
  setESimOpenModal: PropTypes.func,

  /* доступен ли тариф для подключения */
  availableInShop: PropTypes.bool,
  /* подключен ли тариф */
  isConnected: PropTypes.bool,
  /* авторизация */
  isAuthenticated: PropTypes.bool,
  /* показ скидки на тарифе в АЗ и НЗ */
  showDiscountParams: PropTypes.object,
};

export default PartnerShipTariff;
