import { shallowEqual } from '@beef/utils';

import { useAppSelector } from '../../../../store';

/**  Хук подготовки данных компонента об устаревшем поколении ап и показ ссылки на обновленный ап */
export const usePrepareArchivedWithoutFttbProps = () => {
  /* Получение общих данных */
  const { description, url, text } = useAppSelector(
    (state) => ({
      description: state.common.content.archivedWithoutFttb?.description,
      text: state.common.content.archivedWithoutFttb?.text,
      url: state.common.latestTariffUpLink,
    }),
    shallowEqual,
  );
  return { description, url, text };
};
