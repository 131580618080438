import React from 'react';
import classNames from 'classnames/bind';

import { TempSkeleton } from 'constructorV1/components/commonComponents/TempSkeleton';
import { createArray } from 'constructorV1/utils/createArray';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/* заглушка для скелетона, колоичество полей возможно вынести в контент? */
const SKELETON_TILES_LENGTH = 8;
const skeletonStub = createArray(SKELETON_TILES_LENGTH);

/** Компонент ЕК PresetPicker отображает набор плиток с пресетами, которые можно перетаскивать горизонтально. */
export const SkeletonPresetPicker = () => {
  return (
    <div className={cx('component')}>
      <div className={cx('container')}>
        <TempSkeleton className={cx('unit')} />
        {skeletonStub.map((i) => (
          <TempSkeleton className={cx('tile')} key={i} />
        ))}
      </div>
    </div>
  );
};
