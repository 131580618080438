import React from 'react';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

type Props = {
  alt?: string;
  src: string;
};

export const ImageSlide = ({ src, alt }: Props) => (
  <div className={cx('slide')}>
    <img alt={alt || ''} src={src} />
  </div>
);
