import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from '@beef/ui-kit';

import { ChartItem } from '../ChartItem';
import {
  selectCurrentRegionRating,
  selectCurrentStoriesRating,
  selectRegionsList,
  setCurrentRegionRating,
} from '../../../store/stories';
import { openStoryFromChart } from '../../../store/story';
import { pushStoryAction } from '../../../utils/analytics';

const ChartLayout = ({ chart, groups, currentGroup, onSetGroup, onItemClick }) => (
  <>
    <Tabs
      isScrollable
      onChange={onSetGroup}
      selectedTab={currentGroup}
      tabs={groups}
      variant="secondary"
    />
    {chart.map((item, index) => (
      <ChartItem
        author={item.author}
        count={item.votesCount}
        index={index}
        key={item.id}
        onClick={() => onItemClick(item)}
        percentage={item.votesPercentage}
      />
    ))}
  </>
);

const mapStateToProps = (state) => ({
  chart: selectCurrentStoriesRating(state),
  groups: selectRegionsList(state),
  currentGroup: selectCurrentRegionRating(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSetGroup: (id) => dispatch(setCurrentRegionRating(id)),
  onItemClick: (story) => {
    dispatch(openStoryFromChart(story.id));
    pushStoryAction('read_story', story);
  },
});

export const Chart = connect(mapStateToProps, mapDispatchToProps)(ChartLayout);
