import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

// Components
import { Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import Picture from 'pages-components/Picture';
import Icon from 'pages-components/Icon';

// Styles
import styles from '../styles.pcss';

const cx = classNames.bind(styles);

/**
 * @param icon - Иконку для основного текста;
 * @param iconImg - картинка для основного текста;
 * @param text - Основной текст;
 * @param onClick - Callback вызывается по клику на блок;
 * @param link - link для перехода;
 * @param children - Дочерний элемент, представлена как опциональная возможность добавлять элементы;
 * @param className - Дополнительный класс;
 * @param connected - Статус подключения.
 * @param textTooltip - Тултип для текста;
 * @param description - Описание, текст распологается в правой части блока;
 * @param descriptionTooltip - Тултип для описания;
 * @param subDescription - Дополнительное описание в правой части блока,
 * под текстом описания. Выводится желтым цветом;
 * @param reversed - Меняет местами текст и описание.
 * @param descriptionClassName - Дополнительный класс для блока с описанием.
 * @param image - Изображение в виджете.
 * @param widgetType - Тип виджета.
 */

const RowButton = ({
  icon,
  link,
  text,
  onClick,
  children,
  className,
  connected,
  textTooltip,
  description,
  subDescription,
  descriptionTooltip,
  reversed,
  descriptionClassName,
  image,
  iconImg,
  widgetType,
}) => (
  <div
    className={cx('tiles', 'rowButton', { reversed }, (onClick || link) && 'clickable', className)}
  >
    {onClick && (
      <EmptyButton className={cx('outerButton', 'clickable')} onClick={onClick}>
        {text || description}
      </EmptyButton>
    )}
    {link && (
      <a
        className={cx('outerButton', 'clickable')}
        href={link}
        rel="nofollow noopener noreferrer"
        target="_blank"
      >
        {text || description}
      </a>
    )}
    <div className={cx('rightBox')}>
      <div className={cx('about')}>
        {children}
        {text && (
          <>
            <span className={cx('text')}>
              {text}
              {icon && (
                <span className={cx('icon')}>
                  <Icon name={icon} />
                </span>
              )}
              {iconImg && (
                <span className={cx('icon')}>
                  <img alt="" src={iconImg} />
                </span>
              )}
              {textTooltip && (
                <Tooltip>
                  <span dangerouslySetInnerHTML={{ __html: textTooltip }} />
                </Tooltip>
              )}
            </span>
            {widgetType === 'amediateka' && (
              <span className={cx('outerIcon')}>
                <Picture
                  alt="amediateka"
                  src="https://static.beeline.ru/upload/images/amedia-tarif-icon.svg"
                />
              </span>
            )}
          </>
        )}
      </div>
      {connected && !isEmpty(connected) && (
        <div className={cx('status', connected.state ? 'success' : 'warning')}>
          <span>{connected.text}</span>
        </div>
      )}
    </div>
    {description && (
      <div className={cx('leftBox')}>
        <div className={cx('description', descriptionClassName)}>
          <span
            className={cx('descriptionText')}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {descriptionTooltip && (
            <Tooltip className={cx('descriptionTooltip')}>
              <span dangerouslySetInnerHTML={{ __html: descriptionTooltip }} />
            </Tooltip>
          )}
          {subDescription && (
            <div
              className={cx('subDescription')}
              dangerouslySetInnerHTML={{ __html: subDescription }}
            />
          )}
        </div>
        {image && (
          <div className={cx('image')}>
            <img alt={description} src={image} />
          </div>
        )}
      </div>
    )}
    <div className={cx('row')} />
    {widgetType === 'sharing' && (
      <>
        <div className={cx('additionalSimImg')}>
          <img alt="" src="https://static.beeline.ru/upload/images/with-family-mobile.svg" />
        </div>
        <div
          className={cx('additionalSimImgDesktop')}
          style={{
            'background-image': 'url(https://static.beeline.ru/upload/images/with-family.svg)',
          }}
        />
      </>
    )}
  </div>
);

RowButton.defaultProps = { connected: {} };

RowButton.propTypes = {
  icon: PropTypes.string,
  iconImg: PropTypes.string,
  image: PropTypes.string,
  widgetType: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  descriptionClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.oneOfType([PropTypes.object]),
  onClick: PropTypes.func,
  reversed: PropTypes.bool,
  description: PropTypes.string,
  descriptionTooltip: PropTypes.string,
  subDescription: PropTypes.string,
  textTooltip: PropTypes.string,
  text: PropTypes.string,
  connected: PropTypes.object,
};

export default RowButton;
