import PropTypes from 'prop-types';

export const gridOptionsShape = {
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  gutter: PropTypes.number,
  edgeIndent: PropTypes.number,
  minVisible: PropTypes.number,
  maxVisible: PropTypes.number,
};
