import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import styles from './styles.pcss';
import { DownloadButtons } from '../../blocks/DownloadButtons';
import { ITeaser } from '../../types';
import { useGetDimentions } from './hooks/useGetDimentions';
import { TextLayout } from '../../layout';

const cx = classNames.bind(styles);

export const Teaser = memo(
  ({
    imageUrl,
    title,
    mobileImageUrl,
    subTitle,
    fixedButton,
    titleMobile,
    subTitleMobile,
    handleClickModal,
    handleCloseModal,
  }: ITeaser) => {
    const isMobile = useIsMobileDimension();
    const { isMiniMobile, isLargeTablet, isMiniDesktop } = useGetDimentions();

    return (
      <div className={cx('teaser')}>
        <TextLayout className={cx('text')}>
          <Text className={cx('title')} size={isMobile ? 'l' : '2xl'}>
            {isMobile ? titleMobile : title}
          </Text>
          <Text className={cx('sub-title')} color="secondary" size={isMobile ? 'xs' : 'm'}>
            {isMobile ? subTitleMobile : subTitle}
          </Text>
          <div className={cx('buttons')}>
            <DownloadButtons
              {...fixedButton}
              className={cx('button')}
              handleClickModal={handleClickModal}
              handleCloseModal={handleCloseModal}
              screenTitle={title}
            />
            {isLargeTablet && (
              <img alt="" className={cx('img')} src={isMiniMobile ? mobileImageUrl : imageUrl} />
            )}
          </div>
        </TextLayout>
        {isMiniDesktop && (
          <img alt="" className={cx('img-bottom')} src={isMobile ? mobileImageUrl : imageUrl} />
        )}
      </div>
    );
  },
);
