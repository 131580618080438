import { ymPushParams } from 'utils/ym';

import { curry } from '../../pages/src/pages/ProductCard/utils';

const EVENT_ACTION_LIST = {
  view: 'fttb_main_view',
  speedConnection: 'fttb_main_speed_connection',
  addressStreet: 'fftb_main_address_street',
  addressBuilding: 'fftb_main_address_building',
  addressFlat: 'fftb_main_address_flat',
  requestSubmit: 'fftb_main_request_submit',
  enterCtn: 'fftb_main_enter_ctn',
  requestSuccess: 'fftb_main_request_success',
  aboutTariff: 'fttb_main_about_tariff',
  catalogTariffs: 'fttb_main_catalog_tariffs',
  backToTariff: 'fttb_main_back_to_tariff',
};

const handleSendAnalytics = ({
  event,
  eventCategory,
  eventAction,
  eventLabel,
  additionalFields,
}) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    let res = {};

    if (event) {
      res.event = event;
    }
    if (eventCategory) {
      res.eventCategory = eventCategory;
    }
    if (eventAction) {
      res.eventAction = eventAction;
    }

    if (eventLabel) {
      res.eventLabel = eventLabel;
    }

    if (additionalFields) {
      res = {
        ...res,
        ...additionalFields,
      };
    }

    window.dataLayer.push({
      ...res,
    });
  }
};

const pushAnalyticsFTTBForm = (eventAction, step, additionalData) => {
  if (typeof window === 'undefined' || !window.dataLayer) return null;

  let analyticData = {
    event: EVENT_ACTION_LIST[eventAction],
  };

  if (step) {
    analyticData.step = step;
  }

  if (additionalData) {
    analyticData = {
      ...analyticData,
      ...additionalData,
    };
  }

  window.dataLayer.push(analyticData);
};
const ymPushAnalyticsFTTBForm = (city, additionalAnalytic) => {
  ymPushParams({
    main: {
      block_fttb: {
        [city]: additionalAnalytic,
      },
    },
  });
};
const handleSendYandexMetrika = (id, name, alias, params) => {
  if (typeof window === 'undefined' || !window.ym) return null;
  window.ym(id, name, alias, params);
};

const curriedHandleSendYandexMetrika = curry(handleSendYandexMetrika);

const sendYandexMetrika = curriedHandleSendYandexMetrika(26001372, 'reachGoal');

const getPackagesString = (packages) => packages?.map((option) => option?.value ?? '--')?.join('|');

export {
  getPackagesString,
  handleSendAnalytics,
  pushAnalyticsFTTBForm,
  sendYandexMetrika,
  ymPushAnalyticsFTTBForm,
};
