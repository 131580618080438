import React, { Component } from 'react';
import PropTypes from 'prop-types';

// utils
import addDays from 'date-fns/add_days';
import { Button, DatePicker, Emoji, Heading, Link, Status } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { getMobileOS } from 'utils/detect-mobile';

import styles from './ServiceDisconnectPopup.pcss';

const cx = classNames.bind({ ...styles });

class ServiceDisconnectPopupContent extends Component {
  constructor(props) {
    super(props);

    const { initialDate } = this.props;
    const year = initialDate.getFullYear();
    const month = initialDate.getMonth();
    const day = initialDate.getDate();

    this.initialDateString = `${day}.${month + 1}.${year}`;
    this.state = {
      disconnectDate: initialDate,
      hideNativeView: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.setState({ hideNativeView: getMobileOS() === 'ios' });
  }

  handleClick() {
    if (this.props.onConfirm) {
      this.props.onConfirm(this.state.disconnectDate);
    }
  }

  renderFormattedText(text) {
    return text ? <span dangerouslySetInnerHTML={{ __html: text }} /> : null;
  }

  render() {
    const { title, content, confirmButtonText, cancelButtonText, titleIcon, dateText, isCancel } =
      this.props;

    const maxDisconnectionDate = addDays(new Date().getTime(), 30); // 30 дней

    return (
      <div className={cx('content')}>
        <Heading level={1}>
          {this.renderFormattedText(title)}
          {titleIcon && <Emoji className="inHeading" path={titleIcon} />}
        </Heading>
        <div className={cx('description')}>{this.renderFormattedText(content)}</div>
        {!isCancel && dateText ?
          <div className={cx('settings')}>
            <div className={cx('dateLabel')}>{dateText}</div>
            <div className={cx('dateInput')}>
              <DatePicker
                className={cx('wide')}
                hideNativeView={this.state.hideNativeView}
                max={maxDisconnectionDate}
                min={this.props.initialDate || null}
                onChange={(disconnectDate) => this.setState({ disconnectDate })}
                onFocus={(disconnectDate) => this.setState({ disconnectDate })}
                value={this.state.disconnectDate || null}
              />
            </div>
          </div>
        : null}
        {this.props.warningText ?
          <div className={cx('warning')}>
            <Status className="attention" emoji="pointingHandUp">
              {this.renderFormattedText(this.props.warningText)}
            </Status>
          </div>
        : null}
        <div className={cx('buttons')}>
          <div className={cx('confirm-button')}>
            <Button className="big" onClick={this.handleClick}>
              {confirmButtonText}
            </Button>
          </div>
          {cancelButtonText && (
            <div className={cx('cancel-button')}>
              <Link onClick={this.props.onCancel}>{cancelButtonText}</Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ServiceDisconnectPopupContent.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  content: PropTypes.string,
  warningText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  initialDate: PropTypes.object,
  dateText: PropTypes.string,
  isCancel: PropTypes.bool,
};

export default ServiceDisconnectPopupContent;
