import React from 'react';
import classNames from 'classnames/bind';
import { Preloader } from '@beef/ui-kit';

import styles from './styles.pcss';
import { TZoomControls } from './types';
import LocationIcon from './assets/Location.svg';
import PlusIcon from './assets/Plus.svg';
import MinusIcon from './assets/Minus.svg';

const cn = classNames.bind(styles);

export const ZoomControls = ({
  loading,
  onClick,
  onGetLocationCLick,
  className,
}: TZoomControls) => (
  <div className={cn('zoom-controls', className)}>
    {[1, -1].map((value) => (
      <button
        className={cn('button')}
        key={value}
        onClick={() => {
          onClick(value);
        }}
        type="button"
      >
        {value > 0 ?
          <PlusIcon />
        : <MinusIcon />}
      </button>
    ))}
    <button className={cn('button')} onClick={onGetLocationCLick} type="button">
      {loading ?
        <Preloader size="m" spaced />
      : <LocationIcon />}
    </button>
  </div>
);
