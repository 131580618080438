import { ReactElement, ReactNode } from 'react';
import { SizeTypes } from '@beef/ui-kit/Modal/types';

import { PaymentProps, userDataProps } from 'pages/PclLandingPage/IklPage/types';

export const ModalsKeys = {
  activateLimitModal: 'activateLimitModal', // подключение услуги
  successActivateModal: 'successActivateModal',
  errorActivateModal: 'errorActivateModal',
  deactivateLimitModal: 'deactivateLimitModal', // отключение услуги
  successDeactivateModal: 'successDeactivateModal',
  errorDeactivateModal: 'errorDeactivateModal',
  changeLimitModal: 'changeLimitModal', // обновление услуги
  successChangeLimitModal: 'successChangeLimitModal',
  errorChangeLimitModal: 'errorChangeLimitModal',
  deactivateDebtModal: 'deactivateDebtModal',
  topUpBalanceModal: 'topUpBalanceModal',
};

type ValueOf<T> = T[keyof T];
export type ModalsValues = ValueOf<typeof ModalsKeys>;

export type ModalsDataKeys =
  | 'deactivate'
  | 'deactivateDebt'
  | 'deactivateError'
  | 'deactivateSuccessful'
  | 'error'
  | 'limit'
  | 'successful'
  | 'updateError'
  | 'updateSuccessful';

type ModalDataType = {
  agreement?: { link: string; text: string; url: string };
  buttons?: { [key: string]: { title: string } };
  description: string;
  image?: string;
  title: string;
};

export type BarValuesType = {
  gap: number;
  maxBarValue?: number;
  maxChange: number;
  minBarValue?: number;
  minChange: number;
};

export type ModalsControllerProps = {
  barValues: BarValuesType;
  data: {
    [key in ModalsDataKeys]: ModalDataType;
  };
  isLoading: boolean;
  isOpen: boolean;
  limit: number;
  modal: ModalsValues;
  onActivateLimit: (value: number) => void;
  onChangeLimit: (value: number) => void;
  onClose: () => void;
  onDeactivateLimit: () => void;
  onIncreaseBalanceClick: () => void;
  payment: PaymentProps;
  userData: userDataProps;
};

export type ModalContentType = {
  actionBar: ReactElement;
  content: ReactNode;
  modalSize?: SizeTypes;
  title?: string;
};
