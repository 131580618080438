import { useAppSelector } from '../store';

/** Общий хук статуса загрузки данных по запросам (пресеты, смена персонажа и т.п.).
 * В будщем можно расширять новыми условиями, по которым будет отображаться скелетон во время загрузки данных */
export const useIsLoadingPresetsPrice = () => {
  /* Получение статуса загрузки пресетов (при запросе) */
  const isLoadingPresets = useAppSelector((state) => state.presets.isLoadingPresets);

  /* Получение статуса загрузки при смене персонажа (при запросе) */
  const isLoading = useAppSelector((state) => state.total.isLoading);

  return isLoading || isLoadingPresets;
};
