import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import axios from 'axios';
import { Provider, connect } from 'react-redux';
import classNames from 'classnames/bind';
import { Button, Heading } from '@beef/legacy-ui-kit';

import { getWebView } from 'utils/detect-mobile';
import { ESIM_POLLING_STATUS } from 'utils/ConvertToEsimServiceHelper';
import RequestStatus from 'pages-components/RequestStatus';
import { ymPushParams } from 'utils/ym';
import { YM_ESIM_PARAMS } from 'utils/analytics/zamenaNaEsim';
import { poll } from 'utils/timed-functions';

import Loading from '../../Loading';
import styles from './styles.pcss';
import store from '../../../../../../store/store';

const cx = classNames.bind(styles);

const RequestSuccessContent = ({
  texts: { loading, success, error },
  email,
  currentCtn,
  onClose,
  isWebView,
  eSimStatusPolling,
  pollingNetworkErrors,
}) => {
  const [status, setStatus] = useState('pending');
  const [qrCode, setQrCode] = useState(null);
  const [qrCodeLink, setQrCodeLink] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  // временное отображение для webView, выпилить
  const [msgWebView, setMsgWebView] = useState(null);

  useEffect(() => {
    setIsMobile(getWebView());
  }, []);

  useEffect(() => {
    poll(
      () =>
        axios
          .get('/convert-to-esim/status/')
          .then(({ data }) => {
            if (data?.isSucceeded && data?.url) {
              setStatus('success');
              setQrCode(data?.url);
              return ESIM_POLLING_STATUS.success;
            }
            if (data?.errorType === ESIM_POLLING_STATUS.progress) {
              // IN_PROGRESS - единственный код, для которого считаем, что нужно продолжать поллинг
              return ESIM_POLLING_STATUS.progress;
            }
            setStatus('error');
            return ESIM_POLLING_STATUS.error;
          })
          .catch((axiosError) => {
            /**
             * В iOS при сворачивании вебвью происходит разрыв соединения,
             * поэтому, если в qp хранится код ошибки, продолжаем поллинг
             */
            if (pollingNetworkErrors?.some((el) => el === axiosError.code)) {
              return ESIM_POLLING_STATUS.progress;
            }
            setStatus('error');
            return ESIM_POLLING_STATUS.error;
          }),
      (eSimPollingStatus) => eSimPollingStatus === ESIM_POLLING_STATUS.progress,
      eSimStatusPolling.interval,
      eSimStatusPolling.timeout,
    ).catch(() => setStatus('error'));
  }, [eSimStatusPolling.interval, eSimStatusPolling.timeout, pollingNetworkErrors]);

  useEffect(() => {
    // на телефоне - по ссылке открываем мобильное приложение
    if (!qrCode) {
      return;
    }

    if (isMobile) {
      setQrCodeLink(qrCode);
    } else {
      ymPushParams(YM_ESIM_PARAMS.QR_CODE_SUCCESS);
      QRCode.toDataURL(qrCode).then((url) => {
        setQrCodeLink(url);
      });
    }
    const AndroidOrWebkit = window?.Android || window?.webkit || ' not detected';
    setMsgWebView(`Loaded: ${AndroidOrWebkit}`);
  }, [qrCode, isMobile]);

  const handleMobileClick = () => {
    const event = 'eSIM';
    const data = {
      link: qrCodeLink,
      ctn: currentCtn,
    };
    ymPushParams(YM_ESIM_PARAMS.WEBVIEW_INSTALL_ESIM);

    if (window?.Android?.eSIM) {
      setMsgWebView(`Android: ${window.Android}`);
      window.Android.eSIM(JSON.stringify(data));
      return;
    }

    if (window?.webkit) {
      setMsgWebView(`IOs: ${window.webkit}`);
      window.webkit.messageHandlers.callbackHandler.postMessage({
        event,
        ...data,
      });
      return;
    }
    setMsgWebView(`href: ${window.location}, to: ${data.link}`);
    window.location.href = data.link;
  };

  if (status === 'pending') {
    return <Loading description={loading?.description} header={loading?.header} />;
  }

  if (status === 'error') {
    return <RequestStatus data={error} onClick={onClose} />;
  }

  if (status === 'success') {
    return (
      <div className={cx('component')}>
        <Heading className={cx('title')} level={1}>
          <span dangerouslySetInnerHTML={{ __html: success?.title }} />
        </Heading>
        {!isMobile && qrCodeLink && (
          <>
            <div
              className={cx('description')}
              dangerouslySetInnerHTML={{ __html: success?.description }}
            />
            <div className={cx('qrCodeWrapper')}>
              <img
                alt="qr-код для установки профиля esim"
                className={cx('qrCode')}
                src={qrCodeLink}
              />
            </div>
          </>
        )}

        {isMobile && qrCodeLink && (
          <>
            <div
              className={cx('description')}
              dangerouslySetInnerHTML={{ __html: success?.mobileText }}
            />
            <div className={cx('buttonWrap')}>
              <Button className="big" onClick={handleMobileClick} transformer>
                {success.buttonText}
              </Button>
            </div>
          </>
        )}
        {!isMobile && (
          <div
            className={cx('description', 'descriptionMail')}
            dangerouslySetInnerHTML={{
              __html: `${success?.emailText} ${email}`,
            }}
          />
        )}
        {isWebView && (
          <div>
            <p>{msgWebView}</p>
            <p>{getWebView()}</p>
          </div>
        )}
      </div>
    );
  }

  return null;
};

RequestSuccessContent.propTypes = {
  email: PropTypes.string,
  currentCtn: PropTypes.string,

  texts: PropTypes.object,

  onClose: PropTypes.func,
  isWebView: PropTypes.bool,
  eSimStatusPolling: PropTypes.shape({
    timeout: PropTypes.number,
    interval: PropTypes.number,
  }),
  pollingNetworkErrors: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  currentCtn: state.external?.currentCtn?.ctn || state.external?.user?.ctn,
});

const ConnectedComponent = connect(mapStateToProps, null)(RequestSuccessContent);

const RequestSuccess = (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);

export default RequestSuccess;
