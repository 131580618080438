import { replaceNullWithUndefined } from 'utils/types';

import { TariffUpModals } from '../../store/TariffUpModals';
import { EYandexPopupType, EYandexResponseType } from '../../types';
import {
  TState,
  selectIsModalLoading,
  selectIsModalOpen,
  selectPopupType,
  selectProductPopups,
  selectYandexPopups,
} from '../selectors';
import { TModalProps } from '../types';

// NOTE: Selector needs to only depend on TState
// NOTE: второй параметр нужен исключительно для запуска теста
// (../__tests__/selectors.test.ts),
// иначе всегда будет отрабатывать первый if
export const selectModalProps = (state: TState, isTesting?: boolean): TModalProps => {
  if (!TariffUpModals.IsOpen && !isTesting) return {};

  const popupType = selectPopupType(state);
  const isOpen = selectIsModalOpen(state);

  const productPopups = selectProductPopups(state);
  const yandexPopups = selectYandexPopups(state);

  const yandexContent = yandexPopups[popupType];
  const productContent = productPopups[popupType];

  const _popupContent = popupType && (productContent || yandexContent);

  // NOTE: Need to think of a better way of making sure
  // no conflicting popups could be displayed
  const isActive =
    !!Object.values(EYandexResponseType).includes(popupType) ||
    popupType === EYandexPopupType.Activated;
  const popupContent = replaceNullWithUndefined(_popupContent || {});
  const isLoading = selectIsModalLoading(state);
  const shouldRender = isActive && isOpen;

  // NOTE: Content has incompattible field names
  // Therefore, needs mapping
  const popupContentWithIcon = popupContent && {
    ...popupContent,
    icon: productContent?.imgUrl || yandexContent?.image,
  };

  return { isOpen, isLoading, popupContent: popupContentWithIcon, shouldRender };
};
