import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { AnyAction } from '@reduxjs/toolkit';

import { useAppDispatch } from 'constructorV1/store';
import { getCommonBillData } from 'constructorV1/store/commonBillSlice';
import { useThrowAsyncError } from 'constructorV1/hooks/useThrowAsyncError';
import { useConstructorLink } from 'constructorV1/router/hooks/useConstructorLink';

/** Хук старта запроса на получение результирующих данных для общего чека */
export const useStartTotalRequest = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();
  const navigate = useNavigate();

  /** Ссылка на страницу результата конструктора */
  const resultLink = useConstructorLink('result');

  /** Функция перехода на страницу результата (отработает в случае возникновения терминальной ошибки) */
  const terminalError = () => navigate(resultLink);

  useLayoutEffect(() => {
    const promise = dispatch(getCommonBillData(terminalError) as unknown as AnyAction);
    promise.unwrap().catch((e: Error) => {
      throwAsyncError(e);
    });

    return () => {
      promise.abort();
    };
  }, []);
};
