import { useEffect, useState } from 'react';

import {
  ContentQp,
  LoadingType,
  useInitRedirectServiceCardLandingProps,
} from 'pages/Services/RedirectServiceCardLanding/types';
import { getContentQp } from 'pages/Services/RedirectServiceCardLanding/api';

/**
 *  Хук для получения qpContent и получения feature toggle
 */
export const useChoseVersionRedirectServiceCardLanding = () => {
  const [loading, setLoading] = useState<LoadingType>('idle');
  const [contentQp, setContentQp] = useState<ContentQp>();

  const getData = async () => {
    setLoading('pending');
    try {
      const data = await getContentQp();
      setContentQp(data);
      setLoading('succeeded');
    } catch {
      setLoading('failed');
    }
  };

  useEffect(() => {
    getData().catch(() => {});
  }, []);

  return {
    loading,
    contentQp,
  };
};

export const useInitRedirectServiceCardLanding = (
  props: useInitRedirectServiceCardLandingProps,
) => {
  const { getOptions, contentQp, content } = props;

  const { pageTitle, titleColor, pageSubtitle, textColor, requestId, isAuthenticated } = content;
  const teaserBanner = {
    title: pageTitle,
    titleColor,
    text: pageSubtitle,
    textColor,
    img: contentQp?.pereadresatsiya?.img,
  };

  useEffect(() => {
    getOptions({ requestId, isAuthenticated });
  }, []);

  const isError = false;
  const errorData = undefined;
  const mainData = {
    faq: contentQp?.faq,
    teaserBanner,
  };
  return {
    mainData,
    errorData,
    isError,
  };
};

/**
 * Хук для генерирации внешний submit
 * для формы c заданным id
 * согласно официальному примеру
 * библиотеки react-final-from
 */
export const useExternalFormSubmit = (id: string) => {
  const onClick = () => {
    const form = document.getElementById(id) as HTMLFormElement | null;
    if (!form) return;
    if (typeof form?.requestSubmit === 'function') {
      form?.requestSubmit();
    } else {
      form?.dispatchEvent(new Event('submit', { cancelable: true }));
    }
  };

  return {
    onClick,
  };
};
