import React from 'react';
import { Text } from '@beef/ui-kit';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

type ContentType = {
  text: string;
};

const cn = classNames.bind(styles);

const _Content = ({ text }: ContentType) => (
  <Text className={cn('content')} color="grey60" size="size4-r">
    {text}
  </Text>
);

// @ts-expect-error state not typed in ts yet
export const Content = connect(({ content }): ContentType => ({ text: content.text }))(_Content);
