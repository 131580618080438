import React from 'react';

import { useArchivedCases } from '../hooks/tariffStateHooks/useArchivedCases';
import { useGetAvailableFmcServices } from '../hooks/useGetAvailableFmcServices';
import { Separation } from '../components/commonComponents/Separation';
import { BillContainer } from '../components/commonComponents/BillContainer';
import { TariffContainer } from '../components/commonComponents/TariffContainer';
import { ConnectionAddressEditorContainer } from '../components/constructorPage/ConnectionAddressEditorContainer';
import { AddressEditorHeader } from '../components/constructorPage/ConnectionAddressEditorContainer/components/AddressEditorTitle';
import { FmcServicesSettingsContainer } from '../components/constructorPage/FmcServicesSettings';
import { FmcSettingsPageContainer } from '../components/constructorPage/FmcSettingsPageContainer';
import { ArchivedWithFttbBlock } from '../components/constructorPage/ArchivedWithFttbBlock';

/** Страница настройки ШПД и ТВ */
export const SettingsFttbAndTvPage = () => {
  useGetAvailableFmcServices();
  const { isArchivedTariffUpWithFttb } = useArchivedCases();

  return (
    <>
      {/* Заголовок и описание страницы */}
      <AddressEditorHeader />
      <TariffContainer isShortPadding>
        <Separation>
          {/* Обертка над общим контейнером настроек сервисов */}
          <FmcSettingsPageContainer>
            {/* Блок редактирования адреса подключения */}
            <ConnectionAddressEditorContainer />
            {/* Блок аутентификации архивного ШПД */}
            {isArchivedTariffUpWithFttb && <ArchivedWithFttbBlock />}
            {/* Блок настройки ШПД-сервисов */}
            <FmcServicesSettingsContainer />
          </FmcSettingsPageContainer>
        </Separation>
        <Separation sideType="right">
          {/* Чек тарифа и промо баннер */}
          <BillContainer />
        </Separation>
      </TariffContainer>
    </>
  );
};
