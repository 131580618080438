import { withDataConverter, withMountListener, withRenderFlag } from '@beef/react';
import { compose } from '@beef/utils';
import React from 'react';

import { withObservableState } from 'utils/hocs/withObservableState';

import { selectModalProps } from '../../selectors';
import { TTariffUpModals, TariffUpModals } from '../../store/TariffUpModals';
import { EYandexPopupType } from '../../types';
import { TModalProps } from '../types';
import { onMount } from './handler';

const FamilyModal: React.FC<TModalProps> = () => null;

const converter = (): TModalProps => {
  const modalProps = selectModalProps(EYandexPopupType.Family);

  return {
    ...modalProps,
    onMount,
  };
};

export const ConnectedFamilyModal = compose<typeof FamilyModal>(
  withObservableState<TTariffUpModals>(TariffUpModals.ObservableState),
  withDataConverter(converter),
  withRenderFlag,
  withMountListener,
)(FamilyModal);
