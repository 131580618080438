import React, { FC, useRef } from 'react';
import classNames from 'classnames/bind';

import { GroupsPreload } from './components/GroupsPreload';
import { GroupContainer } from './components/GroupContainer';
import { BillGroup } from './components/BillGroup';
import { TotalInfo } from './components/TotalInfo';
import { usePrepareTotalBill } from './hooks/usePrepareTotalBill';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Total чек (финальный чек на отдельной странице) */
export const TotalBill: FC = () => {
  /* ref для отправки аналитики */
  const totalBillRef = useRef(null);
  const { isLoading, billGroups, totalInfoProps } = usePrepareTotalBill(totalBillRef);
  return (
    <section className={cx('wrapper')} ref={totalBillRef}>
      <GroupContainer>
        {isLoading ?
          <GroupsPreload />
        : billGroups?.map((group) => <BillGroup key={group.title} {...group} />)}
      </GroupContainer>
      <GroupContainer>
        <TotalInfo {...totalInfoProps} />
      </GroupContainer>
    </section>
  );
};
