import React from 'react';

// TODO: Не забыть про импорт после выноса в отдельный пакет!
import { ResultConnect } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/ResultConnect';

import { usePrepareFTTBPageProps } from '../hooks/usePrepareFTTBPageProps';

/** Страница благодарности ШПД.
 * Отображается в случае успешной отправки callback на обратный звонок */
export const ThanksFTTBPage = () => {
  const resultConnectProps = usePrepareFTTBPageProps();
  return <ResultConnect {...resultConnectProps} />;
};
