import qs from 'query-string';
import { pathOr } from 'ramda';
import { axiosBaseRequest } from '@beef/utils';
import { fetchLoginModel } from '@beef/layout-kit/actions';

import { SET_LOGIN_MODEL } from 'pages-components/Header2021/actionTypes';

import { parseCtn } from '../utils/ctn-string';
import { getPasswordLocation } from '../utils/location';
import {
  SET_AUTH_CTN,
  SET_AUTH_LOGIN,
  SET_AUTH_PASSWORD,
  SET_AUTH_STATE,
  SET_AUTH_STEP,
  SET_CTN_READ_FROM_URL,
  SET_CTN_TYPE,
  SET_MOBILE_ID_CAPTCHA,
  TOGGLE_PENDING_CAPTCHA,
} from './actionTypes';
import { AUTH_STEPS, LOGIN_TYPES, MESSAGES } from './constants';
import { YMAuthEvent } from '../utils/ym';

export const onRequestSMS = (captcha) => (dispatch, getState) => {
  // чтобы ошибка сразу скрывалась после возможно неправильно введенного номера
  dispatch({
    type: SET_AUTH_STATE,
    payload: {
      authError: false,
      errorMessage: null,
      fetchingSMS: true,
      smsBlocked: false,
    },
  });

  dispatch({ type: TOGGLE_PENDING_CAPTCHA });

  const step = pathOr('', ['authorization', 'authStep'], getState().external);
  const ctn = pathOr('', ['external', 'authorization', 'authCtn'], getState());
  const bisUrl = pathOr('', ['external', 'loginModel', 'bisUrl'], getState());
  let url = `${bisUrl}/identity/generateonetimepassword?login=${ctn}`;
  const withCaptcha = captcha !== undefined;
  if (withCaptcha) {
    url = `${url}&captcha=${captcha.captcha}&captchaKey=${captcha.captchaKey}`;
  }

  return axiosBaseRequest({
    url,
    transformRequest: [
      (payload, headers) => {
        delete headers.common['X-Requested-With'];
        delete headers['X-Requested-With'];
        delete headers['X-Request-Id'];
        delete headers['X-Request-Sign'];
        delete headers['X-Request-Timestamp'];
        return payload;
      },
    ],
  })
    .then((data) => {
      if (data && data.Status === 'OK') {
        dispatch({ type: SET_AUTH_STATE, payload: { fetchingSMS: false } });
        dispatch({ type: SET_AUTH_STEP, payload: AUTH_STEPS.smsCode });
        if (step === AUTH_STEPS.smsCaptcha) {
          YMAuthEvent.smsCaptchaNext();
        }
        YMAuthEvent.smsSend();
        YMAuthEvent.smsCodeShow();
      } else {
        dispatch({ type: SET_AUTH_STATE, payload: { authError: true } });
      }
    })
    .catch((error) => {
      const errorCode = pathOr(null, ['response', 'data', 'ErrorCode'], error);

      if (errorCode === 'AUTH_MAX_TOKENS_BY_PERIOD') {
        dispatch({
          type: SET_AUTH_STATE,
          payload: {
            fetchingSMS: false,
            smsBlocked: true,
          },
        });
        dispatch({ type: SET_AUTH_STEP, payload: AUTH_STEPS.smsCode });
      } else if (errorCode === 'NEED_CAPTCH' || errorCode === 'ERROR_CAPTCH') {
        if (step === AUTH_STEPS.smsCaptcha) {
          YMAuthEvent.smsCaptchaError();
        }
        dispatch({ type: SET_AUTH_STEP, payload: null });
        dispatch({ type: SET_AUTH_STEP, payload: AUTH_STEPS.smsCaptcha });
      } else {
        YMAuthEvent.error('sms', MESSAGES[errorCode] || MESSAGES.DEFAULT);
        dispatch({
          type: SET_AUTH_STATE,
          payload: {
            authError: true,
            errorMessage: MESSAGES[errorCode] || MESSAGES.DEFAULT,
          },
        });
      }
    })
    .finally(() => {
      dispatch({ type: TOGGLE_PENDING_CAPTCHA });
    });
};

export const onSetMobileIdCaptcha = (captcha) => (dispatch) => {
  dispatch({
    type: SET_MOBILE_ID_CAPTCHA,
    payload: { mobileIdCaptcha: { key: null, captcha: null } },
  });
  if (captcha) {
    dispatch({
      type: SET_MOBILE_ID_CAPTCHA,
      payload: {
        mobileIdCaptcha: { key: captcha.captchaKey, captcha: captcha.captcha },
      },
    });
  }
};

export const onSubmitCtn = (ctn, checkIsBeeline) => (dispatch) => {
  const parsedCtn = parseCtn(ctn);
  const premiseCtn = (parsedCtn.ctnType === LOGIN_TYPES.mobile ? parsedCtn.ctn : ctn).trim();

  dispatch(fetchLoginModel(premiseCtn))
    .then((loginModel) => {
      dispatch({ type: SET_AUTH_CTN, payload: premiseCtn });
      const outerLoginType = pathOr(LOGIN_TYPES.unknown, ['loginType'], loginModel);
      const requiredSendSms = pathOr(false, ['requiredSendSms'], loginModel);
      const nextStep = AUTH_STEPS.smsCode;
      const { isBeelineCtn } = loginModel;
      let loginType;
      // Check for SMS sending
      if (outerLoginType === LOGIN_TYPES.mobile) {
        if (requiredSendSms) {
          loginType = outerLoginType;
        } else {
          loginType = LOGIN_TYPES.login;
        }
      } else {
        loginType = outerLoginType;
      }
      if ((checkIsBeeline && isBeelineCtn) || !checkIsBeeline) {
        dispatch({ type: SET_CTN_TYPE, payload: loginType });
        dispatch(onRequestSMS());
        dispatch({ type: SET_AUTH_STEP, payload: nextStep });
      } else {
        YMAuthEvent.smsNotBeeline();
        dispatch({
          type: SET_AUTH_STATE,
          payload: { authError: true, errorMessage: MESSAGES.SMS_NOT_BEELINE },
        });
      }
    })
    .catch(() => {
      dispatch({ type: SET_AUTH_STATE, payload: { authError: true } });
    });
};

export const onCheckMobileIdAvailable = (ctn) => (dispatch) => {
  const parsedCtn = parseCtn(ctn);
  const premiseCtn = (parsedCtn.ctnType === LOGIN_TYPES.mobile ? parsedCtn.ctn : ctn).trim();
  return dispatch(fetchLoginModel(premiseCtn))
    .then(({ requiredSendSms, isBeelineCtn }) => ({
      requiredSendSms,
      isBeelineCtn,
    }))
    .catch(() => false);
};

export const onSetLoading = (loading) => (dispatch) => {
  dispatch({ type: SET_LOGIN_MODEL, payload: { loading } });
};

export const onResetForm = (step) => (dispatch) => {
  dispatch({
    type: SET_AUTH_STEP,
    payload: step === AUTH_STEPS.smsCode ? AUTH_STEPS.smsInitial : AUTH_STEPS.mobileIdInitial,
  });
  dispatch({
    type: SET_MOBILE_ID_CAPTCHA,
    payload: { mobileIdCaptcha: { key: null, captcha: null } },
  });
  dispatch({ type: SET_AUTH_CTN, payload: '' });
  dispatch({ type: SET_CTN_TYPE, payload: LOGIN_TYPES.unknown });
};

export const onSetAuthCtn = (ctn) => (dispatch) => {
  dispatch({ type: SET_AUTH_CTN, payload: ctn });
};

export const onSetAuthLogin = (login) => (dispatch) => {
  dispatch({ type: SET_AUTH_LOGIN, payload: login });
};

export const onSetAuthPassword = (password) => (dispatch) => {
  dispatch({ type: SET_AUTH_PASSWORD, payload: password });
};

export const onSwitchForm = (step, needLoginModel) => (dispatch, getState) => {
  if (needLoginModel) {
    dispatch(fetchLoginModel());
  }
  const ctn = pathOr('', ['external', 'authorization', 'authCtn'], getState());
  dispatch({ type: SET_AUTH_CTN, payload: ctn.trim() });
  dispatch({ type: SET_AUTH_STEP, payload: step });
};

export const onSetLoginType =
  (type = LOGIN_TYPES.unknown) =>
  (dispatch) => {
    dispatch({ type: SET_CTN_TYPE, payload: type });
  };

export const onStepChange = (step) => (dispatch, getState) => {
  if (
    // eslint-disable-next-line sonarjs/no-identical-expressions
    pathOr(null, ['authorization', 'mobileIdCaptcha', 'key'], getState().external) ||
    pathOr(null, ['authorization', 'mobileIdCaptcha', 'key'], getState().external)
  ) {
    dispatch({
      type: SET_MOBILE_ID_CAPTCHA,
      payload: { mobileIdCaptcha: { key: null, captcha: null } },
    });
  }

  dispatch({ type: SET_AUTH_STEP, payload: step });
};

export const onSubmitMobileId = (onSuccess, onError) => async (dispatch, getState) => {
  let additionalParams = '';
  const step = pathOr('', ['authorization', 'authStep'], getState().external);
  const mobileIdCaptcha = getState().external.authorization?.mobileIdCaptcha;
  const mobileIdData = getState().external?.mobileIdData;
  const ctn = getState().external.authorization?.authCtn;

  dispatch({ type: TOGGLE_PENDING_CAPTCHA });

  if ((mobileIdCaptcha?.key || mobileIdCaptcha?.key === '') && mobileIdCaptcha?.captcha) {
    additionalParams = `?captcha=${mobileIdCaptcha.captcha}&captchaKey=${mobileIdCaptcha.key}`;
  }

  try {
    const data = await axiosBaseRequest({
      method: 'post',
      url: `${mobileIdData.bisUrl}/identity/mobileid${additionalParams}`,
      data: { ctn: parseCtn(ctn).ctn },
      withCredentials: !!mobileIdData.bisIncludeCredentials,
      transformRequest: [
        (payload, headers) => {
          delete headers.common['X-Requested-With'];
          delete headers['X-Requested-With'];
          delete headers['X-Request-Id'];
          delete headers['X-Request-Sign'];
          delete headers['X-Request-Timestamp'];
          return payload;
        },
      ],
    });

    if (onSuccess) {
      onSuccess(data);
    }
    if (data.validationPassed && data.mobileidtoken) {
      dispatch(onSwitchForm(AUTH_STEPS.mobileId));
      dispatch(onSetLoginType(LOGIN_TYPES.mobile));
      YMAuthEvent.mobileIdSendPush();
    }
  } catch (e) {
    if (step === AUTH_STEPS.mobileIdCaptcha) {
      YMAuthEvent.mobileIdCaptchaError();
    }
    if (
      e?.response?.data?.ErrorCode === 'NEED_CAPTCH' ||
      e?.response?.data?.ErrorCode === 'ERROR_CAPTCH'
    ) {
      dispatch(onStepChange(null));
      dispatch(onStepChange(AUTH_STEPS.mobileIdCaptcha));
    } else if (onError) {
      onError();
    }
  } finally {
    dispatch({ type: TOGGLE_PENDING_CAPTCHA });
  }
};

export const onGetCtnFromUrl = (dispatch, getState) => {
  let ctn = qs.parse(window.location.search).loginAutofillCtn;

  try {
    ctn = atob(ctn);
  } catch (er) {
    ctn = '';
  }

  if (!ctn || pathOr(false, ['authorization', 'ctnReadFromUrl'], getState().external)) {
    return;
  }

  dispatch({ type: SET_CTN_READ_FROM_URL });
  dispatch({ type: SET_AUTH_CTN, payload: ctn });
};

export const onResetPassword = () => (dispatch, getState) => {
  YMAuthEvent.passwordRestoreClick();
  const { authStep, loginType } = getState().external.authorization;
  const loginModel = getState().external?.loginModel;

  if (!loginModel) {
    dispatch(fetchLoginModel()).then(({ recoverPasswordUrlString }) => {
      getPasswordLocation({
        recoverPasswordUrl: recoverPasswordUrlString,
        step: authStep,
        loginType,
      });
    });

    return undefined;
  }

  return getPasswordLocation({
    recoverPasswordUrl: loginModel.recoverPasswordUrlString,
    step: authStep,
    loginType,
  });
};
