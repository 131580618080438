import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { Button, ButtonVariantType } from '@beef/ui-kit';
import { InfoBanner } from '@beef/smart-kit';

import { Story } from 'pages/Anniversary/types';

import { StoryModalLayout as StoryModal } from '../StoryModal';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

type Props = {
  button: {
    text: string;
    variant: ButtonVariantType;
  };
  story: Story;
  title: string;
};

export const WinnerBanner = ({ story, button, ...bannerProps }: Props) => {
  const [showStory, setShowStory] = useState(false);

  return (
    <>
      <div className={cx('wrap')}>
        <InfoBanner {...bannerProps}>
          <Button onClick={() => setShowStory(true)} variant={button.variant}>
            {button.text}
          </Button>
        </InfoBanner>
      </div>
      {showStory && <StoryModal isOpen onClose={() => setShowStory(false)} story={story} />}
    </>
  );
};
