import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Card } from '@beef/smart-kit';
import { Tag } from '@beef/ui-kit';

import { ImageWrapper } from './components/ImageWrapper/ImageWrapper';
import { DetailsCard } from './components/DetailsCard/DetailsCard';

// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const CardComponent = ({
  imageUrl,
  subscriptionStatusText,
  showProductDetails,
  name,
  description,
  price,
  paymentPeriod,
  isSubscriptionStatus,
  handleChecked,
  isChecked,
  isLoading,
}) => (
  <Card className={cn('card')}>
    <button className={cn('card__wrapper-btn')} onClick={showProductDetails} type="button">
      <div className={cn('card__banner')}>
        <ImageWrapper imageUrl={imageUrl} />

        {subscriptionStatusText && (
          <div className={cn('card__tag')}>
            <Tag textColor="black" variant="shapeStatic">
              {subscriptionStatusText}
            </Tag>
          </div>
        )}
      </div>
      <DetailsCard
        checked={isChecked}
        description={description}
        disabled={isLoading}
        isLoading={isLoading}
        isSubscriptionStatus={isSubscriptionStatus}
        name={name}
        onChange={handleChecked}
        paymentPeriod={paymentPeriod}
        price={price}
      />
    </button>
  </Card>
);

CardComponent.propTypes = {
  subscriptionStatusText: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  paymentPeriod: PropTypes.string,
  isSubscriptionStatus: PropTypes.bool,
  isChecked: PropTypes.bool,
  showProductDetails: PropTypes.func,
  handleChecked: PropTypes.func,
  isLoading: PropTypes.bool,
};

export { CardComponent };
