import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';
import EmptyButton from 'pages-components/EmptyButton';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PickupPoint = ({
  name,
  address,
  opening,
  subwayName,
  subwayColor,
  availableDate,
  active,
  onClick,
}) => (
  <EmptyButton className={cx('component', active && 'active')} onClick={onClick}>
    <div className={cx('name')} dangerouslySetInnerHTML={{ __html: name }} />
    <div className={cx('wrapper')}>
      <div className={cx('text', 'address')} dangerouslySetInnerHTML={{ __html: address }} />
      <div className={cx('text', 'opening')} dangerouslySetInnerHTML={{ __html: opening }} />
      <div className={cx('text', 'metro')}>
        {subwayColor && <Icon fill={subwayColor} name="metro" />}
        {subwayName && subwayName}
      </div>
    </div>
    <div className={cx('text', 'date')} dangerouslySetInnerHTML={{ __html: availableDate }} />
  </EmptyButton>
);

PickupPoint.propTypes = {
  name: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  address: PropTypes.string,
  opening: PropTypes.string,
  subwayName: PropTypes.string,
  subwayColor: PropTypes.string,
  availableDate: PropTypes.string,
};

export default PickupPoint;
