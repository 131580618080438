import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { motion } from 'framer-motion';

import { ESkeletonBillTypes } from 'constructorV1/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TSkeletonBill = {
  type: ESkeletonBillTypes;
};

/** Кастомный скелетон чека ЕК */
export const SkeletonBill = memo<TSkeletonBill>(({ type }) => (
  <motion.div className={cx('wrapper', `wrapper__${type}`)} layout />
));
