import { Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import OtherCatalogBanner from 'pages/FTTB/CatalogPage/components/OtherCatalogBanner';
import ComparesTable from 'pages/FTTB/StreetCardPage/components/ComparesTable';
import DescriptionsWrapper from 'pages/FTTB/StreetCardPage/components/DescriptionsWrapper';
import OrderBackCall from 'pages/FTTB/StreetCardPage/components/OrderBackCall';
import TariffsList from 'pages/FTTB/StreetCardPage/components/TariffsList';
import YouAreFavorites from 'pages/FTTB/StreetCardPage/components/YouAreFavorites';
import { ZonesList } from 'pages/FTTB/StreetsMainPage/components/ZonesList';

import withStoreProvider from '../hoc/withStoreProvider';
import StreetConnection from './components/StreetConnection';
import StreetsList from './components/StreetsList';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const StreetsMainPage = (props) => {
  const {
    faq,
    fullAddress,
    tariffs,
    streetBaseUrl,
    streetAggregatorZonesByGroups,
    callBackRequestData,
    orderFormData,
    pagination,
    streetsCount,
    streets,
    currentRegion,
    texts,
    availableScheduleData,
    forceShowBackCallPopup,
    header,
    showCaptcha,
  } = props;
  const {
    descriptions,
    orderBackCall,
    tariffsListData,
    streetConnection,
    streetsList,
    quickOrderPopupTexts,
    otherCatalogBanner,
    catalogsAvailableInRegion,
    comparesTable,
  } = texts;

  const currentCity = {
    id: currentRegion.id,
    label: currentRegion.title,
  };

  const description = descriptions || faq;
  const combinedActiveZonesData = streetAggregatorZonesByGroups.map((zone) => ({
    activePage: zone.activePage,
    zoneType: zone.streetAggregatorZoneGroup,
  }));
  return (
    <div className={cx('wrapper')}>
      {header && (
        <Heading className={cx('title')} level={1}>
          <span dangerouslySetInnerHTML={{ __html: header }} />
        </Heading>
      )}
      <StreetConnection
        currentCity={currentCity}
        streetBaseUrl={streetBaseUrl}
        withHeaderOnMainPage={!!header}
        {...streetConnection}
      />
      <div className={cx('shouldBeLast')}>
        <DescriptionsWrapper city={currentCity} fullAddress={fullAddress} {...description} />
      </div>
      <StreetsList
        activePages={combinedActiveZonesData}
        currentCity={currentCity}
        pagination={pagination}
        streetBaseUrl={streetBaseUrl}
        streets={streets}
        streetsCount={streetsCount}
        {...streetsList}
      />
      {streetAggregatorZonesByGroups?.map((coverageZone) => (
        <div key={coverageZone.streetAggregatorZoneGroup}>
          <ZonesList
            activePages={combinedActiveZonesData}
            pagination={pagination}
            zoneList={coverageZone}
            {...streetsList}
          />
        </div>
      ))}
      <TariffsList
        availableScheduleData={availableScheduleData}
        backCallPopupData={callBackRequestData}
        forceShowBackCallPopup={forceShowBackCallPopup}
        orderFormData={orderFormData}
        tariffs={tariffs}
        {...tariffsListData}
      />
      {otherCatalogBanner && catalogsAvailableInRegion && (
        <OtherCatalogBanner
          availableCatalogs={catalogsAvailableInRegion}
          data={otherCatalogBanner}
        />
      )}
      <OrderBackCall {...orderBackCall} />
      {comparesTable && <ComparesTable {...comparesTable} />}
      {quickOrderPopupTexts && (
        <YouAreFavorites
          quickOrderPopupTexts={quickOrderPopupTexts}
          showCaptcha={showCaptcha}
          {...texts.youAreFavorites}
        />
      )}
    </div>
  );
};

StreetsMainPage.propTypes = {
  forceShowBackCallPopup: PropTypes.bool,
  header: PropTypes.string,
  faq: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }),
    ),
  }),
  streetBaseUrl: PropTypes.string,
  streetsCount: PropTypes.number,
  streets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.number,
      isConnected: PropTypes.bool,
      alias: PropTypes.string,
    }),
  ),
  tariffs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number,
      url: PropTypes.string,
      price: PropTypes.number,
      params: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number,
          alias: PropTypes.string,
        }),
      ),
      promoTariff: PropTypes.shape({
        alias: PropTypes.string,
        productType: PropTypes.number,
        channelCount: PropTypes.number,
        channelsLabel: PropTypes.string,
        discountText: PropTypes.string,
        sortOrder: PropTypes.number,
        params: PropTypes.arrayOf(
          PropTypes.shape({
            sortOrder: PropTypes.number,
            value: PropTypes.string,
            paramValue: PropTypes.string,
            unit: PropTypes.string,
          }),
        ),
        fee: PropTypes.number,
        oldFee: PropTypes.shape({
          isEmpty: PropTypes.bool,
          textValue: PropTypes.string,
          value: PropTypes.string,
          unit: PropTypes.string,
          legal: PropTypes.string,
          title: PropTypes.string,
        }),
        feeUnit: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
        requestUrl: PropTypes.string,
        wifiRouterIncluded: PropTypes.bool,
        tvDeviceIncluded: PropTypes.bool,
        mobileTariffIncluded: PropTypes.bool,
        mobileTariffTitle: PropTypes.string,
      }),
    }),
  ),
  pagination: PropTypes.shape({
    activePage: PropTypes.number,
    totalPageCount: PropTypes.number,
    url: PropTypes.string,
  }),
  showCaptcha: PropTypes.bool,
  requestUrl: PropTypes.string,
  texts: PropTypes.shape({
    streetConnection: PropTypes.shape({
      requestFormTitle: PropTypes.string,
      streetInputLabel: PropTypes.string,
      failTitlePrefix: PropTypes.string,
      failTitlePostfix: PropTypes.string,
      failDescription: PropTypes.string,
      failButtonText: PropTypes.string,
    }),
    streetsList: PropTypes.shape({
      titleTemplate: PropTypes.string,
      streetDeclension: PropTypes.arrayOf(PropTypes.string),
      showMoreBtnText: PropTypes.string,
    }),
    tariffsListData: PropTypes.shape({
      title: PropTypes.string,
      tariffsCardText: PropTypes.shape({
        pricePerMounth: PropTypes.string,
        speedParamTemplate: PropTypes.string,
        channelsDeclension: PropTypes.arrayOf(PropTypes.string),
        minutsDeclension: PropTypes.arrayOf(PropTypes.string),
        connectTariffBtn: PropTypes.string,
        pricePrefix: PropTypes.string,
        kitTariffTitle: PropTypes.string,
        internetTariffTitle: PropTypes.string,
        tvTariffTitle: PropTypes.string,
        speedParamLabel: PropTypes.string,
        tvParamLabel: PropTypes.string,
        mobileParamLabel: PropTypes.string,
      }),
    }),
    orderBackCall: PropTypes.shape({
      requestTitle: PropTypes.string,
      requestDescription: PropTypes.string,
      requestOrderBackCallBtn: PropTypes.string,
      successTitle: PropTypes.string,
      successDescription: PropTypes.string,
      successOrderBackCallBtn: PropTypes.string,
      failTitle: PropTypes.string,
      failDescription: PropTypes.string,
      failOrderBackCallBtn: PropTypes.string,
      wrongNumber: PropTypes.string,
    }),
    comparesTable: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      headColumns: PropTypes.arrayOf(PropTypes.string),
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          columns: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
    }),
    youAreFavorites: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      buttonText: PropTypes.string,
    }),
    tariffs: PropTypes.shape({
      kitTariffTitle: PropTypes.string,
      internetTariffTitle: PropTypes.string,
      tvTariffTitle: PropTypes.string,
    }),
    catalogsAvailableInRegion: PropTypes.arrayOf(PropTypes.string),
    otherCatalogBanner: PropTypes.shape({
      title: PropTypes.string,
      internetLink: PropTypes.shape({
        title: PropTypes.string,
        icons: PropTypes.arrayOf(PropTypes.string),
        url: PropTypes.string,
      }),
      internetAndTVLink: PropTypes.shape({
        title: PropTypes.string,
        icons: PropTypes.arrayOf(PropTypes.string),
        url: PropTypes.string,
      }),
      convLink: PropTypes.shape({
        title: PropTypes.string,
        icons: PropTypes.arrayOf(PropTypes.string),
        url: PropTypes.string,
      }),
    }),
    quickOrderPopupTexts: PropTypes.shape({
      title: PropTypes.string,
      messageText: PropTypes.string,
      buttonText: PropTypes.string,
      successTitle: PropTypes.string,
      successText: PropTypes.string,
      successButtonText: PropTypes.string,
      errorTitle: PropTypes.string,
      errorText: PropTypes.string,
      errorButtonText: PropTypes.string,
      userAgreementText: PropTypes.string,
      termsOfUse: PropTypes.string,
      termsOfUseLinkText: PropTypes.string,
    }),
  }),
  orderFormData: PropTypes.shape({
    pageTitle: PropTypes.string,
    houseConnectionStatusTexts: PropTypes.string,
    hasRequestOnAddressStatusText: PropTypes.string,
    emptyListLabel: PropTypes.string,
    emptyListBackCall: PropTypes.string,
    houseConnectionBtnTexts: PropTypes.string,
    successFlatConnectionTexts: PropTypes.shape({
      textPrefix: PropTypes.string,
      textPostfixTemplate: PropTypes.string,
    }),
    specTariffSuccessFlatConnectionTexts: PropTypes.shape({
      withSpecTariff: PropTypes.shape({
        textPrefix: PropTypes.string,
        textPostfixTemplate: PropTypes.string,
      }),
      withoutSpecTariff: PropTypes.shape({
        textPrefix: PropTypes.string,
        textPostfixTemplate: PropTypes.string,
      }),
    }),
    supportSection: PropTypes.shape({
      supportText: PropTypes.string,
      supportPhone: PropTypes.string,
    }),
    flatPartnerConnectedTemplate: PropTypes.string,
    chooseDateTimeCheckboxText: PropTypes.string,
    chooseDateTime: PropTypes.shape({
      dateLabel: PropTypes.string,
      datePlaceholder: PropTypes.string,
      timeLabel: PropTypes.string,
      entranceLabel: PropTypes.string,
      intercomLabel: PropTypes.string,
      emptyTimePeriodForDayError: PropTypes.string,
      requestErrorTitle: PropTypes.string,
      requestErrorBtn: PropTypes.string,
      emptyDaysStatusText: PropTypes.string,
      houseRequiredWarning: PropTypes.string,
      timePlaceholder: PropTypes.string,
    }),
    successFlatConnectionTemplate: PropTypes.string,
    orderBackCallTexts: PropTypes.shape({
      requestTitle: PropTypes.string,
      requestDescription: PropTypes.string,
      requestOrderBackCallBtn: PropTypes.string,
      successTitle: PropTypes.string,
      successDescription: PropTypes.string,
      successOrderBackCallBtn: PropTypes.string,
      failTitle: PropTypes.string,
      failDescription: PropTypes.string,
      failOrderBackCallBtn: PropTypes.string,
      wrongNumber: PropTypes.string,
    }),
    сontactsFormTexts: PropTypes.shape({
      contactsTitle: PropTypes.string,
      phoneInputLabel: PropTypes.string,
      nameInputLabel: PropTypes.string,
    }),
    flatConnectionFail: PropTypes.string,
    haveQuestionText: PropTypes.string,
    notFoundStreet: PropTypes.string,
    notFoundHouse: PropTypes.string,
    notFoundAddressBtn: PropTypes.string,
    haveQuestionLinkText: PropTypes.string,
    userAgreementText: PropTypes.string,
    termsOfUse: PropTypes.string,
    specTariff: PropTypes.shape({
      pageTitle: PropTypes.string,
      checkAddressBtnText: PropTypes.string,
    }),
    buyTVEData: PropTypes.shape({
      tveShopImage: PropTypes.string,
      tveShopText: PropTypes.string,
      tveShopBtnText: PropTypes.string,
      tveShopHref: PropTypes.string,
      tveMobileText: PropTypes.string,
      mobileApps: PropTypes.shape({
        android: PropTypes.shape({
          appLink: PropTypes.string,
          appImage: PropTypes.string,
        }),
        ios: PropTypes.shape({
          appLink: PropTypes.string,
          appImage: PropTypes.string,
        }),
      }),
    }),
    priceSection: PropTypes.shape({
      tariffTitleText: PropTypes.string,
      bundleTitleText: PropTypes.string,
      wifiIncluded: PropTypes.string,
      oldFeeUnit: PropTypes.string,
      wifiNotIncluded: PropTypes.string,
      tvDeviceIncluded: PropTypes.string,
      tvDeviceNotIncluded: PropTypes.string,
      orderBtnText: PropTypes.string,
      phoneInputLabel: PropTypes.string,
      tvUnitPostfix: PropTypes.string,
      mobileTariffInfoText: PropTypes.string,
    }),
  }),
  currentRegion: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    title: PropTypes.string,
    titleTat: PropTypes.string,
    titleEng: PropTypes.string,
    titlePreposition: PropTypes.string,
    titlePrepositionTat: PropTypes.string,
    titlePrepositionEng: PropTypes.string,
  }),
  callBackRequestData: PropTypes.shape({
    isFeatureEnabled: PropTypes.bool,
    showCaptcha: PropTypes.bool,
    texts: PropTypes.shape({
      title: PropTypes.string,
      backCallTabTitle: PropTypes.string,
      backCallTabSubTitle: PropTypes.string,
      techSupportTabTitle: PropTypes.string,
      techSupportTabSubTitle: PropTypes.string,
      phoneLabel: PropTypes.string,
      wrongPhone: PropTypes.string,
      phoneInputDescription: PropTypes.string,
      backCallBtnText: PropTypes.string,
      userAgreementText: PropTypes.string,
      termsOfUse: PropTypes.string,
      techSupportText: PropTypes.string,
      techSupportPhone: PropTypes.string,
      techSupportPhoneHref: PropTypes.string,
      successTitle: PropTypes.string,
      sucessContent: PropTypes.string,
      errorTitle: PropTypes.string,
      errorContent: PropTypes.string,
      floatingBackCall: PropTypes.shape({
        title: PropTypes.string,
        btnText: PropTypes.string,
        phone: PropTypes.string,
      }),
      abbreviatedPopup: PropTypes.shape({
        abbreviatedPopupTitle: PropTypes.string,
        abbreviatedPopupText: PropTypes.string,
      }),
      fullFormPopupTexts: PropTypes.shape({
        addressRequestTexts: PropTypes.shape({
          title: PropTypes.string,
          subtitle: PropTypes.string,
          statusConnectionTexts: PropTypes.shape({
            isAvailable: PropTypes.string,
            isExists: PropTypes.string,
            isNotAvailable: PropTypes.string,
            alreadyInUse: PropTypes.string,
            houseIsNotFound: PropTypes.string,
            streetIsNotFound: PropTypes.string,
          }),
        }),
        tariffDetailsTexts: PropTypes.shape({
          title: PropTypes.string,
          subtitle: PropTypes.string,
          notificationText: PropTypes.string,
          tariffTitle: PropTypes.string,
          summaryTitle: PropTypes.string,
        }),
        calendarTexts: PropTypes.shape({
          title: PropTypes.string,
          backToAddressStepText: PropTypes.string,
          subtitle: PropTypes.string,
          description: PropTypes.string,
          arrivalOfSpecialist: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
          }),
        }),
        placeholderAddressTexts: PropTypes.shape({
          city: PropTypes.string,
          street: PropTypes.string,
          house: PropTypes.string,
          flat: PropTypes.string,
          phone: PropTypes.string,
          onPhoneError: PropTypes.string,
        }),
        alternativeTariffTexts: PropTypes.shape({
          title: PropTypes.string,
          backToAddressStepText: PropTypes.string,
          description: PropTypes.string,
        }),
        summaryPageTexts: PropTypes.shape({
          headerTexts: PropTypes.shape({
            title: PropTypes.string,
            img: PropTypes.string,
          }),
          callbackNotification: PropTypes.string,
          timeCreationText: PropTypes.string,
          creationNumberText: PropTypes.string,
          serviceSectionHeader: PropTypes.string,
          tariffTitle: PropTypes.string,
          summaryText: PropTypes.string,
          routerTitle: PropTypes.string,
          tvConsoleTitle: PropTypes.string,
          connectionHeader: PropTypes.string,
          addressText: PropTypes.string,
          phoneNumberTitle: PropTypes.string,
          specialistAppointedText: PropTypes.string,
          specialistAppointedNotificationText: PropTypes.string,
          techSupportText: PropTypes.string,
        }),
        headerBarTitle: PropTypes.string,
        activeButtonTexts: PropTypes.shape({
          addressRequestForm: PropTypes.string,
          tariffDetailsForm: PropTypes.string,
          calendarForm: PropTypes.string,
          alternativeTariff: PropTypes.string,
          summaryPage: PropTypes.string,
          shortSuccess: PropTypes.string,
          fail: PropTypes.string,
        }),
        userAgreement: PropTypes.shape({
          userAgreementText: PropTypes.string,
          termsOfUse: PropTypes.string,
        }),
      }),
      redesignPopupTexts: PropTypes.shape({
        title: PropTypes.string,
        backCallTabTitle: PropTypes.string,
        backCallTabSubTitle: PropTypes.string,
        techSupportTabTitle: PropTypes.string,
        techSupportTabSubTitle: PropTypes.string,
        phoneLabel: PropTypes.string,
        wrongPhone: PropTypes.string,
        phoneInputDescription: PropTypes.string,
        backCallBtnText: PropTypes.string,
        userAgreementText: PropTypes.string,
        termsOfUse: PropTypes.string,
        techSupportText: PropTypes.string,
        techSupportPhone: PropTypes.string,
        techSupportImgUrl: PropTypes.string,
        techSupportPhoneHref: PropTypes.string,
        successPopup: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          img: PropTypes.shape({
            src: PropTypes.string,
          }),
          button: PropTypes.shape({
            text: PropTypes.string,
          }),
        }),
        errorPopup: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          img: PropTypes.shape({
            src: PropTypes.string,
          }),
          button: PropTypes.shape({
            text: PropTypes.string,
          }),
        }),
        abbreviatedPopup: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }),
      }),
    }),
  }),
  availableScheduleData: PropTypes.shape({
    chooseDateTimeEnabled: PropTypes.bool,
    startDateOffsetInDays: PropTypes.number,
    endDateOffsetInDays: PropTypes.number,
  }),
};

export default withStoreProvider(StreetsMainPage);
