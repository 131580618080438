import React, { useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import RequestStatus from 'pages-components/RequestStatus';
import {
  API_ENDPOINTS,
  SUBSCRIPTION_STATUSES,
  manageSubscriptionStatus,
} from 'utils/partnershipTariffHelper';

const SubscriptionFetchStatus = ({ data, token, setStandaloneData, onSuccess, onError }) => {
  useEffect(() => {
    const handleCheckStatusRequest = async () => {
      try {
        const res = await axios.get(API_ENDPOINTS.GET_SUBSCRIPTION_DATA, {
          params: {
            redirectUrl: window.location.pathname,
          },
        });
        setStandaloneData(res?.data?.standalone);
      } catch (e) {
        console.error(e);
      }
    };

    const handleActivationRequest = async () => {
      try {
        const activationRequest = await axios.post(API_ENDPOINTS.FINISH_ACTIVATION, {
          yandexToken: token,
        });

        if (activationRequest.data.isSucceeded) {
          handleCheckStatusRequest();
          const { hasStandaloneSub, hasForeignSub } = activationRequest.data;
          onSuccess(hasStandaloneSub, hasForeignSub);
          // Показ лоадера подключения услуги в ЛК и карточке тарифа
          manageSubscriptionStatus(SUBSCRIPTION_STATUSES.pending);
        } else {
          onError(activationRequest?.data?.errorMessage);
        }
      } catch (e) {
        onError();
      }
    };
    handleActivationRequest();
  }, [token]);

  return <RequestStatus data={data} pending />;
};

SubscriptionFetchStatus.propTypes = {
  token: PropTypes.string,
  data: PropTypes.object,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  setStandaloneData: PropTypes.func,
};

export default SubscriptionFetchStatus;
