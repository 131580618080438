/*
 * Ключи для обращения к localStorage в пределах приложения
 */

export const FTTB_SELECTED_PRODUCT = 'homeCatalogSelectedProuct';

export const getFromLocalStorage = (localStorageKey) => {
  try {
    return localStorage.getItem(localStorageKey);
  } catch (err) {
    console.error(err);
  }

  return null;
};

export const getAndParseFromLocalStorage = (localStorageKey) => {
  try {
    return JSON.parse(getFromLocalStorage(localStorageKey));
  } catch (err) {
    console.error(err);
  }

  return null;
};

export const removeFromLocalStorage = (localStorageKey) => {
  try {
    localStorage.removeItem(localStorageKey);
    return true;
  } catch (err) {
    console.error(err);
  }

  return false;
};
