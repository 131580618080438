import PropTypes from 'prop-types';

export const addressShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
});

export const textsShape = PropTypes.shape({
  city: PropTypes.string,
  flat: PropTypes.string,
  phone: PropTypes.string,
  house: PropTypes.string,
  street: PropTypes.string,
  phoneErrorText: PropTypes.string,
});

export const iDShape = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const fullOrderServicesShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    checked: PropTypes.bool,
    alias: PropTypes.string,
    inacId: PropTypes.string,
    description: PropTypes.string,
    enabledInTariff: PropTypes.bool,
    descriptionFee: PropTypes.string,
    subscriptionFee: PropTypes.number,
  }),
);

export const fullOrderGroupServicesShape = PropTypes.arrayOf(
  PropTypes.shape({
    guid: PropTypes.string,
    serviceTitle: PropTypes.string,
    groupSelection: PropTypes.number,
    fttbServiceType: PropTypes.number,
    serviceDescription: PropTypes.string,
    fullOrderGroupServices: fullOrderServicesShape,
  }),
);

export const feeShape = PropTypes.shape({
  unit: PropTypes.string,
  isEmpty: PropTypes.bool,
  legal: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  textValue: PropTypes.string,
});

export const fullOrderInternalTariffsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    alias: PropTypes.string,
    inacId: PropTypes.string,
    productType: PropTypes.number,
  }),
);

export const tariffShape = PropTypes.shape({
  fee: feeShape,
  oldFee: feeShape,
  name: PropTypes.string,
  speed: PropTypes.string,
  alias: PropTypes.string,
  inacId: PropTypes.string,
  isSuccess: PropTypes.bool,
  detailsUrl: PropTypes.string,
  productType: PropTypes.number,
  isHighSpeedTariff: PropTypes.bool,
  fullOrderGroupServices: fullOrderGroupServicesShape,
  fullOrderInternalTariffs: fullOrderInternalTariffsShape,
});
