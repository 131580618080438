import { MutableRefObject, useEffect, useRef } from 'react';

import { ymPushParams } from 'utils/ym';
import { useInView } from 'pages/FTTB2022/TariffDetailsPage/hooks/useInView';

type TUseOnScreenAnalytics = {
  payload: Record<string, any> | null;
  ref?: MutableRefObject<HTMLDivElement | null>;
  threshold?: number;
  timeout?: number;
};

/** Хук для разовой отправки аналитики при видимости блока или части блока более 2-х секунд
 * @param ref - ref блока
 * @param payload - значение для отправки аналитики
 * @param timeout - время задержки перед отправкой аналитики
 * @param threshold - допустимый процент пересечения target и root */
export const useOnScreenAnalytics = ({
  ref,
  payload,
  timeout = 2000,
  threshold = 0.5,
}: TUseOnScreenAnalytics) => {
  /** Переменная определяет, видит ли пользователь отслеживаемый элемент на значение threshold на своем экране.
   * p.s. Хук useOnScreen из @beef/utils НЕ ПОДХОДИТ, т.к. через него нельзя управлять threshold */
  const isVisible = useInView(ref, { threshold });
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (ref?.current && isVisible) {
      timer.current = setTimeout(() => {
        ymPushParams(payload || {});
        ref.current = null;
      }, timeout);
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [isVisible]);

  return null;
};
