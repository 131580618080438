import React, { FC, ReactElement } from 'react';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';

interface RouterProps {
  baseUrl?: string;
  children: React.ReactNode;
}

// TODO: для локального запуска отключаем, т.к. создает роутер в роутере.
// Нужно добавить прокидывание флага через контекст, если withReactRouter уже оборачивал,
// то пропускать обертку. И надо обернуть Root именно этим роутером
const Router: FC<RouterProps> = ({ children, baseUrl }): ReactElement => {
  if (process.env.NODE_ENV === 'development') {
    return children as ReactElement;
  }

  return typeof window === 'undefined' ?
      <MemoryRouter initialEntries={baseUrl ? [`${baseUrl}`] : undefined}>{children}</MemoryRouter>
    : <BrowserRouter>{children}</BrowserRouter>;
};

export interface WithReactRouterProps {
  baseUrl?: string;
}

export function withReactRouter<T extends WithReactRouterProps>(
  WrappedComponent: React.ComponentType<T>,
): FC<T> {
  return (props: T): ReactElement => (
    <Router baseUrl={props.baseUrl}>
      <WrappedComponent {...props} />
    </Router>
  );
}
