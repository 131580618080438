import { useSelectErrorContent } from '../selectors/useSelectErrorContent';
import { useAppSelector } from '../../../../store';
import { useSelectErrorFmcPresets } from '../../../constructorPage/FmcServicesSettings/selectors/useSelectErrorFmcPresets';
import { sendYm } from '../tempYmUtils';
import { usePrepareTotalYm } from '../../../../tempAnalytics/hooks/usePrepareTotalYm';

/** Подготовка данных для компонента CommonErrorBanner */
export const usePrepareErrorContent = () => {
  /* Получение контентных данных */
  const errorTotalBill = useSelectErrorContent();
  const { image, title, btnText, description } = errorTotalBill;

  /* Пропы компонента ErrorPicture (содержит картинку) */
  const pictureProps = { image };

  /* Пропы компонента ErrorTextContent (содержит заголовок и описание) */
  const contentProps = { title, description };

  /* Пропы компонента ErrorButton (содержит текст кнопки и обработчик) */
  const buttonProps = { btnText };

  /* Текущий таб (способ подключения тарифа)  */
  const { currentTariffTab } = useAppSelector((state) => state.total) || {};

  /* Получаем статус ошибки загрузки сервисов ШПД на странице settingsFttbAndTvPage.
Под капотом используется общий флаг ошибки пресетов и сервисов - isErrorFmcRequest(стор)) */
  const isErrorFmcServices = useSelectErrorFmcPresets();

  /* Отправка метрики */
  const preparedAnalytics = {
    ...(isErrorFmcServices ?
      {
        errorType: 'fmc',
      }
    : {
        currentTab: currentTariffTab,
      }),
    title,
    description,
  };

  sendYm(preparedAnalytics);

  /* Ф-я отправки метрики при клике на обновить */
  const handlePushYm = usePrepareTotalYm({ type: 'error' });

  return { buttonProps, contentProps, pictureProps, handlePushYm };
};
