import { createSelector } from '@reduxjs/toolkit';
import { emptyObj } from '@beef/utils';

// Selectors
import { getAddressOrderData } from './common';

/**
 * Данные, введенные пользователем в поля AddressRequest-формы:
 *
 * getUserContent: общий объект с данными addressRequest-формы
 * getUserSelectedFlat: выбранный номер квартиры.
 * getUserSelectedHouse: выбранный дом (полный объект).
 * getUserSelectedStreet: выбранная улица (полный объект).
 * getIsConvergenceUser: флаг определяет, является ли юзер конвергентом.
 * getUserTypeTariff: тип тарифов (высокоскоростные/обычные).
 * getIsConvergentUser: является ли юзер подключенным конвергентом.
 * getUserSelectedStreetID: ID выбранной улицы.
 * getUserSelectedHouseLabel: номер выбранного дома.
 * getUserSelectedStreetLabel: название выбранной улицы.
 * getStatusConnection: статус возмоности подключения услуги.
 * getUserSelectedRegion: выбранный регион.
 * getUserSelectedRegionID:  ID выбранного региона.
 * getUserSelectedRegionLabel: лейбл выбранного региона.
 * getUserSelectedPhoneNumber: введенный номер.
 * getUserSelectedHouseID: ID выбранного дома.
 */

export const getUserContent = createSelector(getAddressOrderData, (state) => state || emptyObj);
export const getUserSelectedFlat = (state) => getUserContent(state).flat;
export const getUserSelectedHouse = (state) => getUserContent(state).house;
export const getUserSelectedStreet = (state) => getUserContent(state).street;
export const getUserTypeTariff = (state) => getUserContent(state).isHighSpeedTariff;
export const getIsConvergentUser = (state) => getUserContent(state).isConvergent;
export const getUserSelectedStreetID = (state) => getUserSelectedStreet(state).id;
export const getUserSelectedHouseLabel = (state) => getUserSelectedHouse(state).label;
export const getUserSelectedStreetLabel = (state) => getUserSelectedStreet(state).label;
export const getStatusConnection = (state) => getUserContent(state).statusConnection;
export const getUserSelectedRegion = (state) => getUserContent(state).selectedRegion;
export const getUserSelectedRegionID = (state) => getUserSelectedRegion(state).id;
export const getUserSelectedRegionLabel = (state) => getUserSelectedRegion(state).label;
export const getUserSelectedPhoneNumber = (state) => getUserContent(state).userPhoneNumber;
export const getUserSelectedHouseID = (state) => getUserSelectedHouse(state).id;
