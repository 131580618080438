import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import SmsForm from './SmsForm';
import SmsFormPhone from './SmsFormPhone';
import SmsFormWithCaptcha from './SmsFormWithCaptcha';
import styles from './SmsLoginForm.pcss';

const cx = classNames.bind(styles);

const SmsLoginForm = (props) => {
  const {
    step,
    ussdCommandForm,
    phone,
    postUrl,
    clientId,
    returnUrl,
    state,
    nonce,
    redirectUrl,
    smsWasSentText,
    sendSmsAgain,
    sendSmsAgainText,
    confirmButtonText,
    error,
    submitSmsFormPhone,
    sendSmsButtonText,
    description,
    needCaptcha,
    bisUrl,
    sendPhoneButtonText,
    paymentNotice,
  } = props;

  return (
    <div>
      {step === 'smsForm' && (
        <SmsForm
          clientId={clientId}
          confirmButtonText={confirmButtonText}
          nonce={nonce}
          notice={paymentNotice}
          phone={phone}
          postUrl={postUrl}
          redirectUrl={redirectUrl}
          returnUrl={returnUrl}
          sendSmsAgain={sendSmsAgain}
          sendSmsAgainText={sendSmsAgainText}
          smsWasSentText={smsWasSentText}
          state={state}
        />
      )}
      {(step === 'smsFormPhone' || (step === 'smsForm' && !phone)) && (
        <SmsFormPhone
          description={description}
          error={error}
          phone={phone}
          sendSmsButtonText={sendSmsButtonText}
          submitSmsFormPhone={submitSmsFormPhone}
        />
      )}
      {step === 'smsFormWithCaptcha' && (
        <SmsFormWithCaptcha
          bisUrl={bisUrl}
          description={description}
          needCaptcha={needCaptcha}
          phone={phone}
          postUrl={postUrl}
          sendPhoneButtonText={sendPhoneButtonText}
          submitSmsFormPhone={submitSmsFormPhone}
        />
      )}

      {ussdCommandForm && ussdCommandForm.ussdText ?
        <div className={cx('ussdWrapper')}>
          <span dangerouslySetInnerHTML={{ __html: ussdCommandForm.ussdText }} />
          <span className={cx('ussd')}>
            {ussdCommandForm.ussd}
            <Icon className={cx('phoneIcon')} name="phoneHandset" />
          </span>
        </div>
      : null}
    </div>
  );
};

SmsLoginForm.propTypes = {
  step: PropTypes.string,
  ussdCommandForm: PropTypes.object,
  phone: PropTypes.string,
  postUrl: PropTypes.string,
  clientId: PropTypes.string,
  returnUrl: PropTypes.string,
  state: PropTypes.string,
  nonce: PropTypes.string,
  redirectUrl: PropTypes.string,
  smsWasSentText: PropTypes.string,
  sendSmsAgain: PropTypes.func,
  sendSmsAgainText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  error: PropTypes.object,
  submitSmsFormPhone: PropTypes.func,
  sendSmsButtonText: PropTypes.string,
  description: PropTypes.string,
  needCaptcha: PropTypes.string,
  bisUrl: PropTypes.string,
  sendPhoneButtonText: PropTypes.string,
  paymentNotice: PropTypes.string,
};

export default SmsLoginForm;
