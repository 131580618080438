import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getQueryValue } from '../store/selectors/filter';
import { pushStatusShown } from '../analytics/filter';

export const withStatusEvent = (status) => (WrappedComponent) => {
  const WithStatusEvent = ({ query, ...props }) => {
    useEffect(() => {
      pushStatusShown(status, query);
    }, []);

    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = (state) => ({
    query: getQueryValue(state),
  });

  return connect(mapStateToProps)(WithStatusEvent);
};
