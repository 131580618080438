import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ColumnsLayout = forwardRef(({ leftColumn, rightColumn }, ref) => (
  <div className={cx('container')} ref={ref}>
    <aside className={cx('columns_left')}>{leftColumn}</aside>
    <div className={cx('columns_right')}>{rightColumn}</div>
  </div>
));

ColumnsLayout.propTypes = {
  leftColumn: PropTypes.node,
  rightColumn: PropTypes.node,
};
