import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import format from 'date-fns/format';
import { Preloader } from '@beef/legacy-ui-kit';

import RequestStatus from 'pages-components/RequestStatus';
import { ymPushParams } from 'utils/ym';
import { YM_ESIM_PARAMS } from 'utils/analytics/zamenaNaEsim';

class GosAuth extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      status: 'pending',
      data: props.texts?.error,
      freeBlockViewed: false,
      timeout: null,
    };
  }

  componentDidMount() {
    this.handleEsimRequest();
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeout);
  }

  getErrorDetails = (reason) => {
    const { texts } = this.props;

    return texts[`${reason}BlockedError`] || texts?.blockedError;
  };

  handleEsimRequest = () => {
    const { texts } = this.props;

    axios
      .get('/convert-to-esim/')
      .then((res) => {
        if (res?.data.isSucceeded && res?.data.esiaUrl) {
          this.setState({
            data: {
              ...texts?.success,
              url: res?.data.esiaUrl,
            },
            status: 'success',
          });
        } else {
          this.setState({
            status: 'error',
          });
          if (res?.data.errorType === 'BLOCKED') {
            if (res.data.reason === 'free' && this.state.freeBlockViewed) {
              this.handleUnblock();
            } else {
              this.setState({
                data: this.getErrorDetails(res.data.reason),
              });
            }
          }
        }
      })
      .catch(() => {
        this.setState({
          status: 'error',
        });
      });
  };

  handleUnblock = async () => {
    const { texts } = this.props;
    this.setState({
      status: 'pending',
    });

    if (this.state.freeBlockViewed) {
      const timeout = setTimeout(() => {
        this.handleEsimRequest();
      }, 2000);
      this.setState({
        timeout,
      });
      return;
    }

    try {
      const res = await axios.post('/api/profile/userinfo/unblockCtn/', {
        dateStr: format(new Date(), 'YYYY-MM-DD'),
      });

      if (res?.data?.isSucceeded) {
        const timeout = setTimeout(() => {
          this.setState({
            freeBlockViewed: true,
            timeout,
          });

          this.handleEsimRequest();
        }, 2000);
      } else {
        this.setState({
          status: 'error',
          data: {
            ...texts?.error,
            description: res.data.message,
          },
        });
      }
    } catch (e) {
      this.setState({
        status: 'error',
        data: texts?.error,
      });
    }
  };

  handleClick = () => {
    ymPushParams(YM_ESIM_PARAMS.AUTH_BY_ESIA);
    const { onClose } = this.props;
    const { data, status } = this.state;

    if (data.buttonUrl) {
      return;
    }

    if (data.buttonAction && data.buttonAction === 'unblock') {
      this.handleUnblock();
    } else if (data.url) {
      if (typeof window !== 'undefined') {
        window.location.href = data.url;
      }
    } else if (status === 'error') {
      onClose();
    }
  };

  render() {
    const { data, status } = this.state;

    if (status === 'pending') {
      return (
        <div style={{ padding: '50px 0' }}>
          <Preloader />
        </div>
      );
    }

    return <RequestStatus data={data} onClick={this.handleClick} />;
  }
}

GosAuth.propTypes = {
  texts: PropTypes.object,
  onClose: PropTypes.func,
};

export default GosAuth;
