import React from 'react';

// TODO: Не забыть про импорт после выноса в отдельный пакет!
import { ConstructorSwiper } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/ConstructorSwiper';

import { ServiceContainer } from '../../../../../ServiceTiles/components/ServiceContainer';
import { usePrepareFmcServiceSettings } from '../hooks/usePrepareFmcServiceSettings';

/** Обертка над компонентом редактирования адреса подключения ШПД */
export const InternetServicesContainer = () => {
  const internetServices = usePrepareFmcServiceSettings();

  return (
    <ConstructorSwiper>
      {internetServices?.map((service) => (
        <ServiceContainer key={service.title} {...{ service }} />
      ))}
    </ConstructorSwiper>
  );
};
