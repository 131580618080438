import { pushDataLayer } from 'utils/ga';
import { callOnce } from 'utils/fn';

import { CATEGORY, FILTER } from './filter';

export const fancyNumberVkEventGoal = 'sim_numbers_basket';

const DEFAULT_TARIFF = {
  id: 'tariff not defined',
  name: 'tariff not defined',
  price: 0.0,
  brand: 'Sim - Tariffs',
  variant: '',
};

const getProducts = (numbers) => [
  ...numbers.map((number) => ({
    id: `Номер - ${CATEGORY[number.category]}`,
    name: `Номер - ${CATEGORY[number.category]}`,
    price: number.price,
    brand: 'Sim - Numbers',
    category: `Номер/${FILTER[number.filter]}`,
    variant: CATEGORY[number.category],
  })),
  {
    ...DEFAULT_TARIFF,
    category: `Номер/${FILTER[numbers[0]?.filter] || ''}`,
  },
];

const mapNumber = (number, filter) => ({
  price: number.price - (number.discount || 0) * number.price,
  category: number.category,
  filter,
});

const pushNumberShowEcommerce = (numbers) => {
  pushDataLayer({
    ecommerce: {
      currencyCode: 'RUB',
      detail: {
        products: getProducts(numbers),
      },
    },
  });
};

export const pushNumbersShowTable = (numbers, filter) => {
  pushNumberShowEcommerce(numbers.map((number) => mapNumber(number, filter)));
};

export const pushRandomNumberShow = callOnce((number) => {
  pushNumberShowEcommerce([mapNumber(number, 'random')]);
});

export const pushAddToCart = (number, filter) => {
  pushDataLayer({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'RUB',
      add: {
        products: getProducts([mapNumber(number, filter)]),
      },
    },
  });
};
