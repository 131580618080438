import { useDispatch } from 'react-redux';
import { shallowEqual } from '@beef/utils';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
// TODO: после выноса ServiceTile в пакет, не забыть поменять импорт!
import { TServiceTile } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/ServiceTile/types';
import { useAppSelector } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

import { replacePrice } from '../utils/replacePrice';
import { prepareServiceTile } from '../../../../../../utils/prepareServiceTile';
import { goToFttbConnectedSettingsStep } from '../../../../../../store/slices/stepperSlice';

/** Хук готовит данные для плитки подключенного ШПД (FmcContainer) */
export const usePrepareServiceTile = (service: TServiceTile) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobileDimension();

  /* Получаем стоимость подключенного ШПД от бека в hydrate */
  const { convergencePrice, settingsFmcButton } = useAppSelector(
    (state) => ({
      settingsFmcButton: state.common.content.settingsFmcButton,
      convergencePrice: state.total.convergenceInfo.convergencePrice,
    }),
    shallowEqual,
  );

  /* Извлекаем контентные данные */
  const { info, variant } = service || {};
  const { infoPrice } = info || {};
  const currentVariant = isMobile ? 'vertical' : variant;

  const serviceTile = prepareServiceTile(service);

  /** Функция перехода к странице настройки подключенного ШПД */
  const handleClick = () => dispatch(goToFttbConnectedSettingsStep());

  /* Пропы для компонента плитки подключенного ШПД (FmcContainer) */
  const serviceTileProps = { ...serviceTile, variant: currentVariant };

  /* Заменяем шаблон {{price}} на реальную стоимость подключенного ШПД (convergencePrice) */
  const currentInfoPrice = replacePrice(infoPrice?.price, convergencePrice);

  /* Результирующий объект infoPrice с текущей ценой за ШПД */
  const prepareInfoPrice = { ...infoPrice, price: currentInfoPrice };

  /* Пропы для компонента InfoPrice */
  const infoPriceProps = {
    infoPrice: prepareInfoPrice,
    /* Если оставлять просто "horizontal", то компонент, который принимает пропы, ругается на тип string */
    variant: 'horizontal' as TServiceTile['variant'],
  };

  /* Пропы для кнопки перехода к настройке подключенного ШПД */
  const settingsBtnProps = {
    onClick: handleClick,
    btnText: settingsFmcButton,
  };

  return { serviceTileProps, infoPriceProps, settingsBtnProps };
};
