import React from 'react';
import { Text } from '@beef/ui-kit';

import { usePrepareArchivedWithoutFttbProps } from './hoocks/usePrepareArchivedWithoutFttbProps';

/**  Информация об устаревшем поколении ап и показ ссылки на обновленный ап */
export const ArchivedWithoutFttbBlock = () => {
  const { description, url, text } = usePrepareArchivedWithoutFttbProps();
  return (
    <div>
      <Text color="grey60" size="size5-r-l">
        {description}
        <a href={url}>{text}</a>
      </Text>
    </div>
  );
};
