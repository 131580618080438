import React, { FC } from 'react';

import { useAppSelector } from '../../../store';
import { ConnectedFmcTile } from '../ConnectedFmcTile';
import { ConnectedTileSkeleton } from '../ConnectedTileSkeleton';
import { useGetAvailableFmcServices } from '../../../hooks/useGetAvailableFmcServices';
import { usePrepareConvergenceConnected } from './hooks/usePrepareConvergenceConnected';

type TConvergenceConnected = {
  withoutButton?: boolean;
};

/** Компонент-обертка получения данных из store для блока подключенной конвергенции (ШПД) */
export const ConvergenceConnected: FC<TConvergenceConnected> = ({ withoutButton = false }) => {
  /** Подготовленные пропы для компонента ConnectedFmcTile */
  const convergenceConnectedProps = usePrepareConvergenceConnected();

  /** Флаг загрузки */
  const isLoadingServicesRequest = useAppSelector((state) => state.fttbPresets.isLoadingFmcRequest);

  /** Вызов метода fmcServices для получения данных по ШПД (подключенные сервисы/услуги) */
  useGetAvailableFmcServices();
  return isLoadingServicesRequest ?
      <ConnectedTileSkeleton />
    : <ConnectedFmcTile withoutButton={withoutButton} {...convergenceConnectedProps} />;
};
