import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { useSelectLoadingStatus } from '../../../TotalBill/components/TotalInfo/components/ButtonContainer/selectors/useSelectLoadingStatus';
import { useBillReloadButton } from './hooks/useBillReloadButton';
import { RouteBackButton } from '../../../../commonComponents/RouteBackButton';
import { CommonErrorBanner } from '../../../../commonComponents/CommonErrorBanner';
import { TotalBill } from '../../../TotalBill';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Обертка над кнопкой возврата, total чеком и компонентом ошибки */
export const TotalBillInfo: FC = () => {
  const onClick = useBillReloadButton();
  const { hasLoadingError } = useSelectLoadingStatus();

  return (
    <div className={cx('wrapper')}>
      <RouteBackButton />
      {hasLoadingError ?
        <CommonErrorBanner onClick={onClick} />
      : <TotalBill />}
    </div>
  );
};
