import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { apiValidationUrl } from 'constructorV1/api/constants';

/** Тип ответа */
export type TValidateMnpRes = {
  Errors?: Record<string, any>;
  IsSucceeded: boolean;
  View?: {
    IsExistingCtn: boolean;
  };
  camelCase?: boolean;
};

/** Запрос на валидацию введенного Mnp номера пользователем */
export const fetchValidateMnp = async ({
  params,
  ...config
}: AxiosRequestConfig): Promise<AxiosResponse<TValidateMnpRes>> =>
  axios.post(apiValidationUrl, params, config);
