import { useEffect, useRef, useState } from 'react';

const releaseEventKeys = ['pointerup', 'pointercancel', 'pointerleave'];

export const useProgress = ({ duration, progress, setProgress }) => {
  const progressNodeRef = useRef(null);
  const dragProgressRef = useRef(null);
  const [dragProgress, setDragProgress] = useState(null);

  useEffect(() => {
    if (!progressNodeRef.current) return null;

    const handlePointerDown = (event) => {
      event.preventDefault();

      const handleDrag = ({ clientX }) => {
        const { left, width } = progressNodeRef.current.getBoundingClientRect();
        let position = (clientX - left) / width;
        if (position < 0) position = 0;
        if (position > 1) position = 1;
        const currentProgress = Math.floor(position * duration);

        setDragProgress(currentProgress);
        dragProgressRef.current = currentProgress;
      };

      const unsubscribe = () => {
        setProgress(dragProgressRef.current);
        setDragProgress(null);
        document.removeEventListener('pointermove', handleDrag);
        releaseEventKeys.forEach((key) => document.removeEventListener(key, unsubscribe));
      };

      document.addEventListener('pointermove', handleDrag);
      releaseEventKeys.forEach((key) => document.addEventListener(key, unsubscribe));

      handleDrag(event);
    };

    progressNodeRef.current.addEventListener('pointerdown', handlePointerDown);

    return () => progressNodeRef.current.removeEventListener('pointerdown', handlePointerDown);
  }, [duration]);

  const currentProgress = (dragProgress === null ? progress : dragProgress) / duration;

  return {
    currentProgress,
    progressNodeRef,
  };
};
