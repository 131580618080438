import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from '@beef/legacy-ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const scrollIntoView = (anchor) => {
  anchor.scrollIntoView(
    {
      behavior: 'smooth',
      block: 'start',
    },
    100,
  );
};

const AnchorLink = ({ children, anchor }) => (
  <div className={cx('component')}>
    <Link
      onClick={() => {
        scrollIntoView(anchor());
      }}
    >
      {children}
    </Link>
  </div>
);

AnchorLink.propTypes = {
  anchor: PropTypes.func,
};

export default AnchorLink;
