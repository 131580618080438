import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames/bind';

import { getRandomInt } from 'utils/fn';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IDotProps {
  backgroundColor: string;
  left: number;
  top: number;
}

/**
 * Компонент одной точки на стартовом экране
 */
export const Dot: FC<IDotProps> = ({ backgroundColor, left, top }) => {
  return (
    <div
      className={cx('dot')}
      style={
        {
          // цвет фона точки
          '--dot-background-color': backgroundColor,
          // смещение точки по оси Х
          '--dot-left': `${left || getRandomInt(0, 100)}%`,
          // смещение точки по оси У
          '--dot-top': `${top || getRandomInt(0, 100)}%`,
        } as CSSProperties
      }
    />
  );
};
