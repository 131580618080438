import React, { MouseEvent } from 'react';
import { Button } from '@beef/ui-kit';

import { ModalsKeys } from 'pages/PclLandingPage/components/modals/ModalsController/types';
import { useIsDesktopDimension, useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

type ButtonsProps = {
  changeBtn: string;
  connectBtn: string;
  disconnectBtn: string;
  handleClick: () => void;
  onOpenModalClick: (event: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  viewChangeButton: boolean;
  viewConnectButton: boolean;
  viewDisconnectButton: boolean;
};

export const Buttons = ({
  viewConnectButton,
  handleClick,
  connectBtn,
  viewChangeButton,
  onOpenModalClick,
  changeBtn,
  viewDisconnectButton,
  disconnectBtn,
}: ButtonsProps) => {
  const isPhone = useIsMobileDimension();
  const isDesktop = useIsDesktopDimension();
  const buttonSize = isDesktop ? 'l' : 'm';
  const fullWidth = isPhone;
  return (
    <>
      {viewConnectButton && (
        <Button fullWidth={fullWidth} onClick={handleClick} size={buttonSize}>
          {connectBtn}
        </Button>
      )}
      {viewChangeButton && (
        <Button
          fullWidth={fullWidth}
          onClick={onOpenModalClick(ModalsKeys.changeLimitModal)}
          size={buttonSize}
        >
          {changeBtn}
        </Button>
      )}
      {viewDisconnectButton && (
        <Button
          fullWidth={fullWidth}
          onClick={onOpenModalClick(ModalsKeys.deactivateLimitModal)}
          size={buttonSize}
          variant="tertiary"
        >
          {disconnectBtn}
        </Button>
      )}
    </>
  );
};
