import { pathOr } from 'ramda';

import { ymPushParams } from 'utils/ym';
import store from 'store';
import { getAccountType } from 'utils/analytics/auth';

type PayloadYM = Record<string, unknown>;
export const keyYMForMNP = 'mnp';

export const getMNPCommonProps = () => {
  const user = pathOr(null, ['external', 'user'], store?.getState?.());
  return {
    page_url: typeof window !== 'undefined' ? window.origin : undefined,
    page_path: typeof window !== 'undefined' ? window.location.pathname : undefined,
    page_title: typeof window !== 'undefined' ? document.title : undefined,
    account_type: getAccountType(user),
  };
};

export const ymMNP = (payload: PayloadYM) => {
  const commonProps = getMNPCommonProps();
  ymPushParams({ [keyYMForMNP]: { ...payload, ...commonProps } });
};
