import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { declension, templateParser } from 'utils/format-string';
import { getStreetPageUrl } from 'pages/FTTB/utils/streetsHelper';
import ColumnsList from 'pages/FTTB/components/ColumnsList';
import { ZONE_NUMBERS } from 'pages/FTTB/constants';

import Pagination from '../Pagination';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

class StreetsList extends PureComponent {
  state = {
    showMoreStreets: this.props.pagination.activePage !== 1,
  };

  onShowMoreStreetsClick = () => this.setState({ showMoreStreets: true });

  renderStreetLink = (street) => {
    const { streetBaseUrl } = this.props;

    return (
      <div className={cx('street')} key={street.id}>
        <Link className="hideVisitedColor" href={getStreetPageUrl(streetBaseUrl, street)}>
          <span dangerouslySetInnerHTML={{ __html: street.label }} />
        </Link>
      </div>
    );
  };

  render() {
    const {
      titleTemplate,
      streets,
      showMoreBtnText,
      streetsCount,
      streetDeclension,
      pagination,
      currentCity,
      activePages,
    } = this.props;
    const { showMoreStreets } = this.state;
    const { activePage, totalPageCount } = pagination;

    const firstStreestList = streets.slice(0, 12);
    const secondStreestList = streets.slice(12);

    const title = templateParser(titleTemplate, {
      streetsCount,
      cityName: currentCity.label,
      streetDeclension: declension(streetDeclension, streetsCount),
    });

    return (
      <div className={cx('wrapper')}>
        <Heading className={cx('title')} level={2}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Heading>

        <div>
          <ColumnsList>{firstStreestList.map(this.renderStreetLink)}</ColumnsList>
          <div className={cx({ hidden: !showMoreStreets })}>
            <ColumnsList>{secondStreestList.map(this.renderStreetLink)}</ColumnsList>
          </div>
        </div>
        {!showMoreStreets && (
          <div className={cx('showMoreBtn')}>
            <Button className="big" color="light" onClick={this.onShowMoreStreetsClick} wide>
              <span dangerouslySetInnerHTML={{ __html: showMoreBtnText }} />
            </Button>
          </div>
        )}

        <div className={cx('paginationWrapper', { hidden: !showMoreStreets })}>
          <Pagination
            activePage={activePage}
            activePages={activePages}
            pagination={pagination}
            totalPageCount={totalPageCount}
            zoneType={ZONE_NUMBERS.streetActivePage}
          />
        </div>
      </div>
    );
  }
}

StreetsList.propTypes = {
  showMoreBtnText: PropTypes.string,
  titleTemplate: PropTypes.string,
  streetBaseUrl: PropTypes.string,
  pagination: PropTypes.shape({
    activePage: PropTypes.number,
    totalPageCount: PropTypes.number,
    url: PropTypes.string,
  }),
  streetsCount: PropTypes.number,
  streetDeclension: PropTypes.arrayOf(PropTypes.string),
  streets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.number,
      alias: PropTypes.string,
    }),
  ),
  currentCity: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number,
  }),
};

export default StreetsList;
