import { createSelector } from '@reduxjs/toolkit';
import { emptyObj } from '@beef/utils';

// Selectors
import { getIsConnectedTariff } from './totalSettings';
import { getCommonModalData, getCommonStoreContent, getCommonStoreData } from './common';
import {
  getArchiveNotificationPopupDescription,
  getArchiveNotificationPopupTitle,
} from './userData';
import {
  getStatusConnection,
  getUserSelectedFlat,
  getUserSelectedHouseLabel,
  getUserSelectedPhoneNumber,
  getUserSelectedRegionLabel,
  getUserSelectedStreetLabel,
} from './orderTariffUserData';

// Constants
import { NUMBER_PHONE_LENGTH, REQUEST_STATUS, popupSteps } from '../../constants';

/**
 * getCurrentStep: текущий шаг подключения в общем попапе.
 * getCurrentButtonText: текст главной кнопки.
 * getCurrentSecondButtonText: текст вспомогательной кнопки.
 */
export const getCurrentStep = createSelector(getCommonModalData, (state) => state.currentStep);
export const getCurrentButtonText = createSelector(getCommonModalData, (state) => state.buttonText);
export const getCurrentSecondButtonText = createSelector(
  getCommonModalData,
  (state) => state.secondButtonText,
);

/**
 * getButtonDisabledStatus: управление disabled-состоянием кнопки в Request-попапе.
 * @param state
 * @returns {boolean}
 */
export const getButtonDisabledStatus = (state) => {
  const currentStep = getCurrentStep(state);
  const statusConnection = getStatusConnection(state);
  const selectedPhone = getUserSelectedPhoneNumber(state);

  const { isExists, isAvailable, isHighSpeedAvailable } = REQUEST_STATUS;
  const correctPhoneNumber = selectedPhone.length === NUMBER_PHONE_LENGTH;
  const correctStatusConnection = [isExists, isAvailable, isHighSpeedAvailable]?.includes(
    statusConnection,
  );

  const {
    connectFail,
    requestExists,
    callBackRequest,
    connectCompleted,
    tariffDetailsForm,
    confirmCancelConnect,
    upTariffSpeedFromArchive,
  } = popupSteps;
  const isUnlockButton = [
    connectFail,
    requestExists,
    callBackRequest,
    connectCompleted,
    tariffDetailsForm,
    confirmCancelConnect,
    upTariffSpeedFromArchive,
  ]?.includes(currentStep);

  if (isUnlockButton) {
    return false;
  }

  return !(correctStatusConnection && correctPhoneNumber);
};

/**
 * Получение данных для попапа requestExists - "Заявка на подключение уже оформлена".
 *
 * getRequestExistsContent: общий объект с данными попапа requestExists.
 * getRequestExistsTitle: заголовок requestExists.
 * getRequestExistsSubTitle: sub-заголовок requestExists.
 * getRequestExistsDescription: описание блока requestExists.
 */
export const getRequestExistsContent = createSelector(
  getCommonStoreContent,
  (state) => state?.content?.requestExists || emptyObj,
);
export const getRequestExistsTitle = (state) => getRequestExistsContent(state).title;
export const getRequestExistsSubTitle = (state) => getRequestExistsContent(state).subTitle;
export const getRequestExistsDescription = (state) => getRequestExistsContent(state).description;

/**
 * Получение данных для попапа connectCompleted - "Услуга уже подключена".
 *
 * getConnectCompletedContent: общий объект с данными попапа connectCompleted.
 * getRequestCompletedTitle: заголовок connectCompleted.
 * getRequestCompletedSubTitle: sub-заголовок connectCompleted.
 * getRequestCompletedDescription: описание connectCompleted.
 */
export const getConnectCompletedContent = createSelector(
  getCommonStoreContent,
  (state) => state?.content?.connectCompleted || emptyObj,
);
export const getRequestCompletedTitle = (state) => getConnectCompletedContent(state).title;
export const getRequestCompletedSubTitle = (state) => getConnectCompletedContent(state).subTitle;
export const getRequestCompletedDescription = (state) =>
  getConnectCompletedContent(state).description;

/**
 * Получение данных для попапа confirmCancelConnect - "Отказаться от услуги?".
 *
 * getCancelConfirmTitle: заголовок confirmCancelConnect.
 * getCancelConfirmSubTitle: sub-заголовок confirmCancelConnect.
 * getCancelConfirmDescription: описание confirmCancelConnect.
 */
export const getCancelConfirmContent = createSelector(
  getCommonStoreContent,
  (state) => state?.content?.confirmCancelConnect || emptyObj,
);
export const getCancelConfirmIcon = (state) => getCancelConfirmContent(state).icon;
export const getCancelConfirmTitle = (state) => getCancelConfirmContent(state).title;
export const getCancelConfirmDescription = (state) => getCancelConfirmContent(state).description;

/**
 * getWarningTitle: определение текущего title в WarningAnswers-попап.
 */
export const getWarningTitle = createSelector(getCommonStoreData, (state) => {
  const currentStep = state?.modal?.currentStep;

  if (currentStep === popupSteps.requestExists) {
    return getRequestExistsTitle(state);
  }

  if (currentStep === popupSteps.upTariffSpeedFromArchive) {
    return getArchiveNotificationPopupTitle(state);
  }

  return getRequestCompletedTitle(state);
});

/**
 * getRequestCompletedSubTitle: определение текущего subTitle в WarningAnswers-попап.
 */
export const getWarningSubTitle = createSelector(getCommonStoreData, (state) => {
  if (state?.modal?.currentStep === popupSteps.requestExists) {
    return getRequestExistsSubTitle(state);
  }
  return getRequestCompletedSubTitle(state);
});

/**
 * getWarningDescription: определение текущего description в WarningAnswers-попап.
 */
export const getWarningDescription = createSelector(getCommonStoreData, (state) => {
  const currentStep = state?.modal?.currentStep;

  if (currentStep === popupSteps.requestExists) {
    return getRequestExistsDescription(state);
  }

  if (currentStep === popupSteps.upTariffSpeedFromArchive) {
    return getArchiveNotificationPopupDescription(state);
  }

  return getRequestCompletedDescription(state);
});

/**
 * getAddressText: формирование общего адреса пользователя в WarningAnswers-попап.
 */
export const getAddressText = createSelector(
  getCommonStoreData,
  (state) =>
    `г. ${getUserSelectedRegionLabel(state)}, ${getUserSelectedStreetLabel(
      state,
    )}, дом ${getUserSelectedHouseLabel(state)}, квартира ${getUserSelectedFlat(state)}`,
);

/**
 * Получение данных для попапа checkConnectionFail - "Ошибка проверки адреса подключения".
 *
 * getCheckConnectionFailIcon: иконка checkConnectionFail-попапа.
 * getCheckConnectionFailTitle: заголовок checkConnectionFail-попапа.
 * getCheckConnectionFailDescription: описание checkConnectionFail-попапа.
 */
export const getCheckConnectionFailContent = createSelector(
  getCommonStoreContent,
  (state) => state?.content?.checkConnectionFail || emptyObj,
);
export const getCheckConnectionFailIcon = (state) => getCheckConnectionFailContent(state).icon;
export const getCheckConnectionFailTitle = (state) => getCheckConnectionFailContent(state).title;
export const getCheckConnectionFailDescription = (state) =>
  getCheckConnectionFailContent(state).description;

/**
 * getCallBackRequestStatus: статус отправки запроса заказа обратного звонка.
 * getCallBackPopupContent: контент для CallBackPopup.
 * getCallBackIcon: иконка CallBackPopup-попапа.
 * getCallBackTitle: заголовок CallBackPopup-попапа.
 * getCallBackDescription: описание CallBackPopup-попапа.
 */
export const getCallBackRequestStatus = createSelector(
  getCommonModalData,
  (state) => state?.callBackRequestStatus,
);
export const getCallBackPopupContent = createSelector(
  getCommonStoreContent,
  (state) => state?.content?.callBackRequest,
);

export const getCallBackIcon = createSelector(getCommonStoreData, (state) => {
  const step = state?.modal?.currentStep;
  if (step === popupSteps.confirmCancelConnect) {
    return getCancelConfirmIcon(state);
  }
  if (step === popupSteps.connectFail) {
    return getCheckConnectionFailIcon(state);
  }
  return getCallBackPopupContent(state)[getCallBackRequestStatus(state)].icon;
});

export const getCallBackTitle = createSelector(getCommonStoreData, (state) => {
  const step = state?.modal?.currentStep;
  if (step === popupSteps.confirmCancelConnect) {
    return getCancelConfirmTitle(state);
  }
  if (step === popupSteps.connectFail) {
    return getCheckConnectionFailTitle(state);
  }
  return getCallBackPopupContent(state)[getCallBackRequestStatus(state)].title;
});

export const getCallBackDescription = createSelector(getCommonStoreData, (state) => {
  const step = state?.modal?.currentStep;
  if (step === popupSteps.confirmCancelConnect) {
    return getCancelConfirmDescription(state);
  }
  if (step === popupSteps.connectFail) {
    return getCheckConnectionFailDescription(state);
  }
  return getCallBackPopupContent(state)[getCallBackRequestStatus(state)].description;
});

/**
 * getIsSettled: указатель пользовательского закрытия попапа.
 * getLoadingStatus: статус получения асинхронных данных в попап.
 * getCallBackStatus: статус отправки данных для обратного звонка.
 * getRegionLoading: статус провери региона.
 */
export const getIsSettled = createSelector(getCommonModalData, (state) => state?.isSettled);
export const getLoadingStatus = createSelector(getCommonModalData, (state) => state?.isLoading);
export const getRegionLoading = createSelector(getCommonModalData, (state) => state?.isFetchRegion);
export const getCallBackStatus = createSelector(
  getCommonModalData,
  (state) => state?.isCallbackLoading,
);

/**
 * getFlagIsHeaderConfigRender: проверка наличия кнопки "Назад" в модалке.
 * @param state
 * @returns {boolean}
 */
export const getFlagIsHeaderConfigRender = (state) => {
  const currentStep = getCurrentStep(state);
  const isConnectTariff = getIsConnectedTariff(state);
  return (
    !isConnectTariff &&
    (currentStep === popupSteps.tariffDetailsForm || currentStep === popupSteps.calendarForm)
  );
};

/**
 * getTariffDetailsContent: общий контент для orderTariffDetailsData
 * getTariffDetailsTotalText: "итого" в orderTariffDetailsData
 * getTariffDetailsSubTitle: sub-title в orderTariffDetailsData
 * getTariffDetailUnitDate: дата в формате - "/ мес" в orderTariffDetailsData
 * getTariffDetailsTitle: title попапа orderTariffDetailsData
 * getTariffDetailUnit: unit попапа orderTariffDetailsData
 */
export const getTariffDetailsContent = createSelector(
  getCommonStoreContent,
  (state) => state?.content?.tariffDetailsTexts || emptyObj,
);
export const getTariffDetailsTotalText = (state) => getTariffDetailsContent(state).totalText;
export const getTariffDetailsSubTitle = (state) => getTariffDetailsContent(state).subTitle;
export const getTariffDetailUnitDate = (state) => getTariffDetailsContent(state).unitDate;
export const getTariffDetailsTitle = (state) => getTariffDetailsContent(state).title;
export const getTariffDetailUnit = (state) => getTariffDetailsContent(state).unit;
