import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  InputGroup,
  InputInfo,
  InputsRow,
  Label,
  Link,
  Preloader,
  SmartForm,
} from '@beef/legacy-ui-kit';
import { Input } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { notEmpty } from 'utils/validators';
import {
  YMAuthEvent,
  ymAuthBySMSorPassForm,
  ymEnterLkSuccess,
} from 'utils/analytics/ymCommonEvents';
import { onShowPasswordClick } from 'pages/Login/Authorization2021/utils/common';
import { handleRoamingYm, roamingYmEventsTypes } from 'pages/ProductCard/utils';

import HiddenInput from '../HiddenInput/HiddenInput';
import styles from './LoginForm.pcss';

const cx = classNames.bind(styles);

class LoginForm extends PureComponent {
  constructor(props) {
    super(props);
    this.container = null;
    this.observer = null;
    this.handleIntersection = this.handleIntersection.bind(this);
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(this.handleIntersection, { threshold: 0.1 });
    this.observer.observe(this.container);

    this.form = this.container.getElementsByTagName('form')[0];
  }

  componentWillUnmount() {
    if (this.props.eventType) {
      YMAuthEvent.close(this.props.eventType);
    }
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  handleIntersection(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (this.props.eventType) {
          YMAuthEvent.visible(this.props.eventType);
        }
      }
    });
  }

  handleSubmit = () => {
    const { login, password } = this.state;

    const { clientId, returnUrl, state, nonce, redirectUrl, eventType } = this.props;

    const formData = {
      login,
      password,
      clientId,
      returnUrl,
      state,
      nonce,
      redirectUrl,
    };

    ymEnterLkSuccess();
    ymAuthBySMSorPassForm();
    YMAuthEvent.authSuccess(eventType);
    this.props.onSubmit(formData, () => this.form.submit());
  };

  handleInputChange =
    (type) =>
    ({ target: { value } }) => {
      const { eventType } = this.props;
      if (type === 'login') {
        if (eventType) {
          YMAuthEvent.fillLoginInput(eventType);
        }
        this.setState({ login: value });
      }
      if (type === 'password') {
        if (eventType) {
          YMAuthEvent.fillPasswordInput(eventType);
        }
        this.setState({ password: value });
      }
    };

  handleClick = () => {
    const { ymType } = this.props;
    handleRoamingYm({ type: ymType, actionType: roamingYmEventsTypes?.getPassword });
    eventType && YMAuthEvent.clickGetPassword(eventType);
  };

  render() {
    const {
      postUrl,
      loginPrompt,
      clientId,
      returnUrl,
      state,
      nonce,
      redirectUrl,
      loginLabel,
      passwordLabel,
      recoverPasswordUrl,
      recoverPasswordText,
      loginButtonText,
      onSubmit,
      isSubmitting,
      eventType,
    } = this.props;

    return (
      <div>
        <div
          className={cx({ hidden: isSubmitting })}
          ref={(container) => {
            this.container = container;
          }}
        >
          <SmartForm
            action={postUrl}
            disableIfInvalid
            inline
            method="post"
            onSubmit={onSubmit && this.handleSubmit}
            validator={{
              login: notEmpty,
              password: notEmpty,
            }}
          >
            <div className={cx('description')} dangerouslySetInnerHTML={{ __html: loginPrompt }} />
            <HiddenInput
              clientId={clientId}
              nonce={nonce}
              redirectUrl={redirectUrl}
              returnUrl={returnUrl}
              state={state}
            />
            <InputsRow groups={3}>
              <InputGroup>
                <Label>{loginLabel}</Label>
                <Input
                  id="login"
                  name="login"
                  onChange={this.handleInputChange('login')}
                  onClick={() => YMAuthEvent.clickLoginInput(eventType)}
                />
              </InputGroup>
              <InputGroup>
                <Label>{passwordLabel}</Label>
                <Input
                  id="password"
                  name="password"
                  onChange={this.handleInputChange('password')}
                  onClick={(event, isTypePassword) =>
                    eventType &&
                    onShowPasswordClick(
                      event,
                      isTypePassword,
                      () => YMAuthEvent.showPasswordInput(eventType),
                      () => YMAuthEvent.hidePasswordInput(eventType),
                    )
                  }
                  onFocus={() => eventType && YMAuthEvent.clickPasswordInput(eventType)}
                  type="password"
                />
                <InputInfo>
                  <Link href={recoverPasswordUrl} onClick={this.handleClick}>
                    {recoverPasswordText}
                  </Link>
                </InputInfo>
              </InputGroup>
              <InputGroup className={cx('buttonWrapper')}>
                <Label>&nbsp;</Label>
                <Button
                  className="big"
                  onClick={() => eventType && YMAuthEvent.clickAuthButton(eventType)}
                  wide
                >
                  {loginButtonText}
                </Button>
              </InputGroup>
            </InputsRow>
          </SmartForm>
        </div>
        <div className={cx({ hidden: !isSubmitting }, 'loaderWrapper')}>
          <Preloader className="center" />
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  postUrl: PropTypes.string,
  loginPrompt: PropTypes.string,
  clientId: PropTypes.string,
  returnUrl: PropTypes.string,
  state: PropTypes.string,
  nonce: PropTypes.string,
  redirectUrl: PropTypes.string,
  loginLabel: PropTypes.string,
  passwordLabel: PropTypes.string,
  recoverPasswordUrl: PropTypes.string,
  recoverPasswordText: PropTypes.string,
  loginButtonText: PropTypes.string,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  eventType: PropTypes.string,
};

export default LoginForm;
