import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { TransitionGroup } from 'react-transition-group';

import { FadeSlide } from 'pages-components/Animations';
import AdaptiveWrapper from 'pages-components/AdaptiveWrapper';
import BackCallPopup from 'pages/FTTB/components/BackCallPopup';
import { tariffClickYM } from 'utils/analytics/tariffsYandexMetrica';
import { pushBackCallAction } from 'utils/ga';

import PhoneIcon from './components/PhoneIcon';
import FloatingBackCallSection from './components/FloatingBackCallSection';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const positionStates = {
  none: 'none',
  onStopBlock: 'onStopBlock',
};

class FloatingPhoneWidget extends PureComponent {
  state = {
    showBackCallPopup: false,
    showFloatingBackCall: false,
    currentPositionState: positionStates.none,
  };

  componentDidMount() {
    if (window.innerWidth < 768) return;

    const { stopBlockId } = this.props;
    if (!stopBlockId) return;

    if (!document.getElementById(stopBlockId)) return;

    document.addEventListener('scroll', this.onScroll, { passive: true });
  }

  componentWillUnmount() {
    if (!this.props.stopBlockId) return;
    document.removeEventListener('scroll', this.onScroll);
  }

  onOpenPopup = () => {
    this.setState({ showBackCallPopup: true });
    pushBackCallAction('buttonClick', this.getGaType());
    tariffClickYM();
  };

  onClosePopup = () => {
    this.setState({ showBackCallPopup: false });
  };

  onShowFloatingBackCall = () => {
    this.setState({ showFloatingBackCall: true });
  };

  onCloseFloatingBackCall = () => {
    this.setState({ showFloatingBackCall: false });
  };

  onFloatingBackCallPhoneClick = () => {
    this.onCloseFloatingBackCall();
    this.onOpenPopup();
  };

  onScroll = () => {
    const stopBlock = document.getElementById(this.props.stopBlockId);
    if (!stopBlock) return;

    const { currentPositionState } = this.state;
    const { stopPosition } = this.props;
    const pos = this.getPosition(stopBlock);

    if (pos < stopPosition && currentPositionState !== positionStates.onStopBlock) {
      const { top, height } = stopBlock.getBoundingClientRect();
      const scrollY = window.scrollY || window.pageYOffset;

      const stopBlockPosition = top + height / 2 + scrollY;

      this.setState({
        currentPositionState: positionStates.onStopBlock,
      });

      this.container.style.top = `${stopBlockPosition}px`;
      return;
    }

    if (pos > stopPosition && currentPositionState === positionStates.onStopBlock) {
      this.setState({ currentPositionState: positionStates.none });

      this.container.style.top = '';
    }
  };

  getPosition = (el) => {
    const { top, height } = el.getBoundingClientRect();
    return (top + height) / (window.innerHeight + height);
  };

  getGaType = () => {
    const { isFMC, isFttbTv } = this.props;
    if (isFMC) return 'fmc';
    if (isFttbTv) return 'fttbTv';

    return 'fttb';
  };

  render() {
    const {
      showCaptcha,
      backCallPopupData,
      withoutAdaptive,
      isFMC,
      isFttbTv,
      marketingProductName,
    } = this.props;

    const { showBackCallPopup, showFloatingBackCall, currentPositionState } = this.state;

    return (
      <div>
        <AdaptiveWrapper keepBoth>
          <div className={cx('container', { smoothHide: showFloatingBackCall })}>
            <button
              className={cx('openPopupBtn')}
              onClick={this.onShowFloatingBackCall}
              type="button"
            >
              <PhoneIcon className={cx('phoneIcon')} />
            </button>
          </div>

          <div
            className={cx('container', {
              withoutAdaptive,
              onStopFixed: currentPositionState === positionStates.onStopBlock,
            })}
            ref={(el) => {
              this.container = el;
            }}
          >
            <button className={cx('openPopupBtn')} onClick={this.onOpenPopup} type="button">
              <PhoneIcon className={cx('phoneIcon')} />
            </button>
          </div>
        </AdaptiveWrapper>

        <TransitionGroup>
          {showFloatingBackCall && (
            <FadeSlide appear in={showFloatingBackCall} mountOnEnter timeout={250} unmountOnExit>
              <FloatingBackCallSection
                data={backCallPopupData.texts.floatingBackCall}
                onClose={this.onCloseFloatingBackCall}
                onPhoneClick={this.onFloatingBackCallPhoneClick}
              />
            </FadeSlide>
          )}
        </TransitionGroup>

        {showBackCallPopup && (
          <BackCallPopup
            data={backCallPopupData}
            forceShowBackCallPopup={this.props.forceShowBackCallPopup}
            isFMC={isFMC}
            isFttbTv={isFttbTv}
            isOpen={showBackCallPopup}
            marketingProductName={marketingProductName}
            onClose={this.onClosePopup}
            redesign
            showCaptcha={showCaptcha}
          />
        )}
      </div>
    );
  }
}

FloatingPhoneWidget.defaultProps = {
  withoutAdaptive: false,
  isFMC: false,
};
FloatingPhoneWidget.propTypes = {
  showCaptcha: PropTypes.bool,
  forceShowBackCallPopup: PropTypes.bool,
  isFMC: PropTypes.bool,
  stopBlockId: PropTypes.string,
  stopPosition: PropTypes.number,
  backCallPopupData: PropTypes.shape({
    texts: PropTypes.shape({
      floatingBackCall: PropTypes.shape({
        title: PropTypes.string,
        btnText: PropTypes.string,
        phone: PropTypes.string,
      }),
    }),
  }),
  withoutAdaptive: PropTypes.bool,
  isFttbTv: PropTypes.bool,
  marketingProductName: PropTypes.string,
};

export default FloatingPhoneWidget;
