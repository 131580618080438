import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import OldFee from 'pages/FTTB/components/OldFee';
import { tariffClickYM } from 'utils/analytics/tariffsYandexMetrica';

import TariffParams from '../TariffParams';
import TariffBonus from '../TariffBonus';
import TariffParamsHoc from '../TariffParams/hoc';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const getBenefitForCurrentCatalog = (tariff, showTVInfo) => {
  const { promoBenefit, promoBenefitFTTB, promoBenefitDP } = tariff;

  if (!tariff.hasAnyPromoBenefit) return null;

  if (!showTVInfo && promoBenefitFTTB) return promoBenefitFTTB;
  if (showTVInfo && promoBenefitDP) return promoBenefitDP;

  return promoBenefit;
};

const getColorTariffBenefit = (texts, isPseudoColorTariff) => {
  const { colorTariffBenefit } = texts;
  if (isPseudoColorTariff) {
    return {
      icon: colorTariffBenefit.icon,
      textValue: colorTariffBenefit.textForPromo,
    };
  }

  return {
    icon: colorTariffBenefit.icon,
    textValue: colorTariffBenefit.textForColor,
  };
};

const TariffCard = (props) => {
  const {
    showTVInfo,
    showTvClickable, // AB-test
    paramsData,
    tariff,
    isPromo,
    isColorTariff: isColorTariffOrigin,
    isPseudoColorTariff,
    texts,
    onConnectTariffClick,
    onTvChannelsClick,
  } = props;

  const { fee, oldFee, name, discountText } = tariff;

  const onConnectBtnClick = useCallback(() => {
    tariffClickYM();
    onConnectTariffClick(tariff.id);
  }, [tariff.id, onConnectTariffClick]);

  const isColorTariff = isColorTariffOrigin || isPseudoColorTariff;
  const currentPromoBenefit =
    isColorTariff ?
      getColorTariffBenefit(texts, isPseudoColorTariff)
    : getBenefitForCurrentCatalog(tariff, showTVInfo);

  return (
    <div className={cx('wrapper', { isPromo, isColorTariff })}>
      {currentPromoBenefit && (
        <div className={cx('tariffBonus')}>
          <TariffBonus benefit={currentPromoBenefit} />
        </div>
      )}
      <div className={cx('container')}>
        <Link className={['hideVisitedColor', isPromo && 'light']} href={tariff.detailsUrl}>
          <span
            className={cx('tariffTitle')}
            dangerouslySetInnerHTML={{ __html: `${name}&nbsp;›` }}
          />
        </Link>
        <div
          className={cx('description')}
          dangerouslySetInnerHTML={{ __html: tariff.shortDescription }}
        />

        <div className={cx('tariffParamsWrapper')}>
          <TariffParamsHoc
            data={paramsData}
            isPromo={isPromo}
            onTvChannelsClick={onTvChannelsClick}
            showTVParams={showTVInfo}
            showTvClickable={showTvClickable}
            tariff={tariff}
          >
            <TariffParams />
          </TariffParamsHoc>
        </div>

        <div className={cx('priceWrap')}>
          {oldFee && !oldFee.isEmpty && (
            <div className={cx('oldPriceWrap')}>
              <OldFee
                discountClassName={cx('discountLabel')}
                discountText={discountText}
                unit={` ${texts.oldFeeUnit}`}
                value={oldFee.value}
              />
            </div>
          )}
          <div className={cx('price')}>
            <span dangerouslySetInnerHTML={{ __html: fee.value }} />
            <span
              className={cx('priceUnit')}
              dangerouslySetInnerHTML={{ __html: ` ${fee.unit}` }}
            />
          </div>
        </div>

        <div className={cx('connectBtnWrapper')}>
          <Button className={cx('connectBtn')} onClick={onConnectBtnClick} wide>
            <span dangerouslySetInnerHTML={{ __html: texts.toConnectBtn }} />
          </Button>
        </div>
      </div>
    </div>
  );
};

TariffCard.propTypes = {
  paramsData: PropTypes.object,
  isColorTariff: PropTypes.bool,
  isPseudoColorTariff: PropTypes.bool,
  onConnectTariffClick: PropTypes.func,
  onTvChannelsClick: PropTypes.func,
  showTVInfo: PropTypes.bool,
  showTvClickable: PropTypes.bool,
  isPromo: PropTypes.bool,
  tariff: PropTypes.object,
  texts: PropTypes.object,
};

export default TariffCard;
