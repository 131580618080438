import { connect } from 'react-redux';
import { Notifications } from '@beef/ui-kit';

import { getNotifications } from '../../store/selectors/filter';
import { removeNotification } from '../../store/actions/filter';

const mapStateToProps = (state) => ({
  notifications: getNotifications(state),
  duration: 6000,
});

const mapDispatchToProps = {
  onClick: removeNotification,
};

export const NotificationsWrap = connect(mapStateToProps, mapDispatchToProps)(Notifications);
