import { isFilledPhone } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/utils/isFilledPhone';

export const getValidatorsRedirectAlways = () => ({
  phoneAlwaysRedirect: (value: string) =>
    !isFilledPhone(value || '') ? 'Введите номер для переадресации' : undefined,
});

export const phoneMaskSettings = {
  mask: '_',
  format: '+7 ###-###-##-##',
};
