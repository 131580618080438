import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { templateParser } from 'utils/format-string';

import BasicPopup from './Basic';
import Accumulators from '../Accumulators';

export default class ConnectedPopup extends PureComponent {
  render() {
    const { className, texts, resultValues } = this.props;
    const { offNetSeconds, onNetSeconds, ...otherValues } = resultValues;

    const newValuesForTemplateParser = {
      ...otherValues,
      onNet: onNetSeconds,
      offNet: offNetSeconds,
    };

    return (
      <BasicPopup
        buttonText={texts.connectedButton}
        className={className}
        description={templateParser(texts.connectedDescription, newValuesForTemplateParser)}
        heading={texts.connectedHeader}
        onClick={() => window.location.reload()}
      >
        <Accumulators inline />
      </BasicPopup>
    );
  }
}

ConnectedPopup.defaultProps = {
  texts: {},
  resultValues: {},
};

ConnectedPopup.propTypes = {
  texts: PropTypes.object,
  resultValues: PropTypes.object,
};
