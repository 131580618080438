import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { path, pathOr } from 'ramda';
import classNames from 'classnames/bind';
import { Button, Emoji, Heading, Link, Loader } from '@beef/legacy-ui-kit';

import { templateParser } from 'utils/format-string';
import store from 'store';
import PopupStateless from 'pages-components/Popup';

import styles from './AlwaysInPositivePopup.pcss';
import { changeLimit, resetPopupData } from '../../actions/alwaysInPositive';

const cx = classNames.bind(styles);

class AlwaysInPositivePopup extends PureComponent {
  renderPopup() {
    const { data, closePopup, confirmationPopup, amount } = this.props;

    if (!data) {
      return null;
    }

    if (data.step === 'pending') {
      return (
        <div className={cx('center')}>
          <Loader status="active" />
        </div>
      );
    }

    return (
      <div className={cx('wrapper')}>
        <Heading level={1}>
          <span dangerouslySetInnerHTML={{ __html: data.title }} />
          <Emoji className="inHeading" path={data.titleIcon} />
        </Heading>
        <div
          className={cx('text')}
          dangerouslySetInnerHTML={{ __html: templateParser(data.content, { amount }) }}
        />
        <Button
          className="big"
          onClick={data.step === 'confirmation' ? confirmationPopup : closePopup}
        >
          {data.buttonText}
        </Button>
        {data.buttonResetText ?
          <Link className={cx('resetLink')} onClick={closePopup}>
            {data.buttonResetText}
          </Link>
        : null}
      </div>
    );
  }

  render() {
    const { closePopup } = this.props;
    const element = this.renderPopup();

    if (!element) {
      return null;
    }

    return (
      <PopupStateless onClose={closePopup} opened>
        {element}
      </PopupStateless>
    );
  }
}

AlwaysInPositivePopup.defaultProps = {
  data: null,
  amount: 0,
};

AlwaysInPositivePopup.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.object,
  closePopup: PropTypes.func.isRequired,
  confirmationPopup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: pathOr(null, ['external', 'alwaysInPositive', 'popupData'], state),
  amount: path(['external', 'alwaysInPositive', 'amount'], state),
});

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(resetPopupData()),
  confirmationPopup: () => dispatch(changeLimit()),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(AlwaysInPositivePopup);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
