import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Ctx from 'pages-components/Ctx';
import Icon from 'pages-components/Icon';
import PopupStateless from 'pages-components/Popup';

import TextContainer from '../TextContainer/TextContainer';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Компонент содержит контент модального окна ошибки при отключении номера из семьи.
 * Отрисовывается в случае ошибки при отключении номера.
 * @param opened <bool> - Флаг управления открытия модального окна;
 * @param onClose <func> - Функция закрытия модального окна;
 */
const DisconnectNumberWarningPopup = ({ opened, onClose }) => (
  <PopupStateless onClose={onClose} opened={opened}>
    <div className={cx('container')}>
      <Heading level={1}>
        <TextContainer path={['texts', 'disconnectNumberWarningTitle']} />
        <Icon className={cx('smile')} name="smile-nervous" />
      </Heading>

      <div className={cx('text')}>
        <TextContainer path={['texts', 'disconnectNumberWarningText']} />
      </div>

      <Button className={cx('big')} onClick={onClose}>
        {Ctx.tariff.buttonText.proceed}
      </Button>
    </div>
  </PopupStateless>
);

DisconnectNumberWarningPopup.propTypes = {
  onClose: PropTypes.func,
  opened: PropTypes.bool,
};

export default DisconnectNumberWarningPopup;
