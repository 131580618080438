import { PageFixer } from '@beef/smart-kit';
import React from 'react';
import { Provider, RootStateOrAny, connect } from 'react-redux';

import store from 'store';
import { isEmptyValue } from 'utils/isEmptyValue';

import { Benefits } from './components/Benefits';
import { ExtendedSteps } from './components/ExtendedSteps';
import { TeaserBanner } from './components/TeaserBanner';
import { useInitializeSPN } from './hook';
import { Features } from './components/Features';
import { About } from './components/About';
import { AppBannerWrapper } from './components/AppBannerWrapper/index';
import { FAQWrapper } from './components/FAQWrapper/index';
import ModalsContainer from './ModalsContainer/index';
import { selectIsModalOpen } from './store/actions/selectors';

const SPN = ({ isModalOpen, data }: { data: any; isModalOpen: boolean }) => {
  const { appBanner, benefits, extendedSteps, features, teaserBanner, about, faq } =
    useInitializeSPN(data);

  return (
    <>
      {isModalOpen && <ModalsContainer />}

      {!isEmptyValue(teaserBanner) && <TeaserBanner data={teaserBanner} />}

      <PageFixer size="m">
        {!isEmptyValue(benefits) && <Benefits data={benefits} />}
        {!isEmptyValue(extendedSteps) && <ExtendedSteps {...extendedSteps} />}
        {!isEmptyValue(features) && <Features {...features} />}
        {!isEmptyValue(about) && <About {...about} />}
        {!isEmptyValue(appBanner) && <AppBannerWrapper {...appBanner} />}
        {!isEmptyValue(faq) && <FAQWrapper data={faq} />}
      </PageFixer>
    </>
  );
};

const mapStateToProps = (state: RootStateOrAny) => ({
  isModalOpen: selectIsModalOpen(state),
});

const SPNConnected = connect(mapStateToProps)(SPN);

const SPNWithStore = (props: any) => (
  <Provider store={store}>
    <SPNConnected {...props} />
  </Provider>
);

export { SPNWithStore as SPN };
