import { vkPushEvent } from 'utils/analytics/vkEvents';

const tariffsWithVkAnalytics = [
  'up5',
  'up4',
  'up3',
  'up2',
  'up1',
  'na-maksimum',
  'vse-v-plyuse',
  'plan-b',
];

export const enum ETariffsVkGoals {
  SimTariffOpenCart = 'sim_tarif_open_cart',
  SimTariffOpenForm = 'sim_tarif_open_form',
  SimTariffBasket = 'sim_tarif_basket',
}

export const vkPushSimTariffEvent = (tariffAlias, goal) => {
  if (tariffsWithVkAnalytics.includes(tariffAlias)) vkPushEvent(goal);
};
