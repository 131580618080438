import { createAsyncThunk } from '@reduxjs/toolkit';

import { redirectToLoginPage } from 'utils/auth';

import {
  EAnalyticsActionType,
  EModalType,
  ESubscriptionStatus,
  IPartnerServiceCardState,
  TModal,
} from '../../../types';
import { pushPartnerServiceParams } from '../../../utils';
import {
  ANALYTICS_FAQ_CLICK,
  ANALYTICS_MODAL_BUTTON_CLICK,
  ANALYTICS_SUBSCRIPTION_CLICK,
} from '../../constants';
import {
  selectAlias,
  selectFaqClickState,
  selectIsAuthenticated,
  selectPrice,
  selectSubscriptionStatus,
} from '../../selectors';
import { modalSlice } from '../../slices';
import { toggleFaqClickState } from './actions';

export const onSubscriptionButtonClickThunk = createAsyncThunk<
  void,
  void,
  { state: IPartnerServiceCardState }
>(ANALYTICS_MODAL_BUTTON_CLICK, async (_, thunkApi) => {
  const state = thunkApi.getState();
  const { dispatch } = thunkApi;

  try {
    const alias = selectAlias(state.data);
    const price = selectPrice(state.data);
    const isAuthenticated = selectIsAuthenticated(state.data);

    if (!isAuthenticated) {
      redirectToLoginPage();
      return;
    }

    const status = selectSubscriptionStatus(state.data);

    const [modalType, actionType] = [
      status === ESubscriptionStatus.Disconnect && [
        EModalType.Predisconnect,
        EAnalyticsActionType.Disconnect,
      ],
      status === ESubscriptionStatus.Connect && [
        EModalType.Preconnect,
        EAnalyticsActionType.Connect,
      ],
      status === ESubscriptionStatus.Activate && [
        EModalType.Preactivate,
        EAnalyticsActionType.Activate,
      ],
      [EModalType.DefaultError, EAnalyticsActionType.Unknown],
    ].find((v) => !!v) as [TModal, string];

    dispatch(modalSlice.actions.openModal(modalType));

    pushPartnerServiceParams({
      [actionType]: {
        partner: {
          [alias]: price,
        },
      },
    });
  } catch (e) {
    dispatch(modalSlice.actions.openModal('defaultError'));
    console.error(e);
  }
});

export const onFAQClickAnalyticsThunk = createAsyncThunk<
  void,
  string,
  { state: IPartnerServiceCardState }
>(ANALYTICS_FAQ_CLICK, async (id, thunkApi) => {
  const state = thunkApi.getState();
  const { dispatch } = thunkApi;

  const alias = selectAlias(state.data);

  dispatch(
    toggleFaqClickState({
      id,
    }),
  );
  // NOTE: Отсылаем аналитику только при открытии аккордиона.
  // При закрытии игнорим.
  const isOpen = !!selectFaqClickState(state)?.[id];
  if (isOpen) {
    pushPartnerServiceParams({
      faq: {
        information: alias,
      },
    });
  }
});

export const onSubscriptionCardClickThunk = createAsyncThunk<
  void,
  void,
  { state: IPartnerServiceCardState }
>(ANALYTICS_SUBSCRIPTION_CLICK, async (_, thunkApi) => {
  const state = thunkApi.getState();

  const price = selectPrice(state.data);
  const alias = selectAlias(state.data);

  pushPartnerServiceParams({
    new_card: {
      partner: {
        [alias]: price,
      },
    },
  });
});
