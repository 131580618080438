import React from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@beef/ui-kit';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const SkeletonDevice = () => (
  <div className={cn('device')}>
    <div className={cn('device__img')}>
      <Skeleton className={cn('banner')} />
    </div>
    <div className={cn('device__title')}>
      <Skeleton className={cn('banner-mini')} />
    </div>
    <div className={cn('device__description')}>
      <Skeleton className={cn('banner-mini')} />
    </div>
  </div>
);
