import { TOptionsListItem, TOptionsSlice } from '../store/slices/optionsSlice/types';

/** Функция сбора всех доступных опций тарифа в единый массив.
 *  По-умолчанию разделены на два массива - "Неограниченный трафик" и "Сервисы" */
const getAllOptions = (options: TOptionsSlice) =>
  options?.reduce((acc: TOptionsListItem[], option) => {
    acc = [...acc, ...option.optionsList, ...(option.parentOptionsList ?? [])];
    return acc;
  }, []);

/** Функция выборки активных (выбранных пользователем) опций */
const getChosenOptions = (allOptions: TOptionsListItem[], optionsSoc: string[]) =>
  allOptions?.filter((option) => optionsSoc.includes(option?.soc));

/** Функция расчета абонентской платы по выбранным опциям тарифа.
 * Считает текущую и старую цену (при наличии) */
export const getOptionsCountSum = (options: TOptionsSlice, optionsSoc: string[]) => {
  /** Собираем все доступные опции в единый массив для удобства работы */
  const allOptions = getAllOptions(options);

  /** Собираем активные опции */
  const chosenOptions = getChosenOptions(allOptions, optionsSoc);

  /* Производим подстчет общей новой и старой цены, разделяя их на поля optionsPrice и optionsOldPrice */
  return chosenOptions?.reduce(
    (acc, chosenOption) => {
      acc.optionsPrice += chosenOption.price;
      acc.optionsOldPrice += chosenOption.oldPrice;

      return acc;
    },
    { optionsPrice: 0, optionsOldPrice: 0 },
  );
};
