import React from 'react';
import { FontColorType, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import { AppBannerPropsType } from '@beef/smart-kit';

import { QRCode } from 'pages/MobileAppLanding2023/components/QRCode/QRCode';

import style from './styles.module.pcss';
import { AppButtons } from '../AppButtons/AppButtons';

const cn = classNames.bind(style);

type DetailsTeaserAppType = AppBannerPropsType & {
  textColor?: FontColorType;
  titleColor?: FontColorType;
  withDivider?: boolean;
};

export const DetailsTeaserApp = ({
  title,
  text,
  qrCode,
  appButtons,
  titleColor,
  textColor,
  withDivider,
}: DetailsTeaserAppType) => (
  <div
    className={cn('details', {
      'details--divider': withDivider,
    })}
  >
    {title && (
      <Text color={titleColor} size="size0-b" tagType="h1">
        {title}
      </Text>
    )}
    {text && (
      <Text className={cn('details__text')} color={textColor} size="size4-r">
        {text}
      </Text>
    )}
    <div className={cn('download-actions')}>
      {appButtons && <AppButtons appButtons={appButtons} />}
      {qrCode?.img && <QRCode img={qrCode?.img} text={qrCode.text} />}
    </div>
  </div>
);
