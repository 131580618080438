import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TExtraAmountText = {
  /* Компонент используется в разных сценариях,
   где нужно/ненужно отображать блок с ценой и описанием */
  isVisible?: boolean;
  price?: number;
  title?: string;
  unit?: string;
};

/** Компонент доп. стоимости по тарифу платежей чека ЕК */
export const ExtraAmountText: FC<TExtraAmountText> = ({ unit = '₽', price, title, isVisible }) => {
  return isVisible ?
      <div className={cx('wrapper')}>
        <Text align="left" color="primary" lineHeight={24} size="xs" weight="regular">
          {title}
        </Text>
        {price && (
          <Text align="left" color="primary" lineHeight={24} size="xs" weight="regular">
            {price} {unit}
          </Text>
        )}
      </div>
    : null;
};
