import axios from 'axios';

import { objectToFormData } from 'utils/objectToFormData';

function createOrder(url, requestData) {
  const formData = objectToFormData(requestData);

  const requestConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'X-Requested-With': 'XMLHttpRequest',
    },
  };

  return axios.post(url, formData, requestConfig);
}

export default createOrder;
