import { shallowEqual } from '@beef/utils';

import { useAppSelector } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

/** Селектор выборки данных (заголовок, опичание)
 *  страници просмотра предварительных изменений по тарифу */
export const useSelectPreChangeProps = () => {
  const { title, description } =
    useAppSelector(
      (state) => ({
        title: state.common.content.pagePreChangeTitle,
        description: state.common.content.pagePreChangeDescription,
      }),
      shallowEqual,
    ) ?? {};

  return { title, description };
};
