import React, { FC } from 'react';

import { PassiveContainer } from '../../../PassiveContainer';
import { DevicesContainer } from '../../../DevicesContainer';

type TInfoBannerContainer = {
  isDisabled?: boolean;
  showUpPassives: boolean;
};

/** Компонент-обертка, позволяющая по флагу showUpPassives выводить либо баннер с пассивками (для апперов),
 * либо общий вариант баннера (для НЕ Up тарифов) на основе конструктора.
 * Наличие isDisabled в true отключает компонент */
export const InfoBannerContainer: FC<TInfoBannerContainer> = ({
  isDisabled = false,
  showUpPassives,
}) => {
  /* Флаг "отключения" компонента InfoBannerContainer */
  if (isDisabled) return null;

  /* Если флаг showUpPassives = true, отобразим компонент PassiveContainer с попапом для апперов.
   * Иначе отображаем общий информационный баннер для тарифов на базе конструктора (DevicesContainer) */
  return showUpPassives ? <PassiveContainer /> : <DevicesContainer />;
};
