import React, { FC } from 'react';
import { Button } from '@carnica/ui-kit';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { formatAlertText } from 'constructorV1/components/tempComponents/AlertContainer/components/AlertTile/utils/formatAlertText';
import { TAlertPopupData } from 'constructorV1/store/commonSlice/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ModalContent: FC<
  TAlertPopupData & { discountPercent: null | number; onClose: () => void }
> = ({ onClose, discountPercent, buttonText, items, title, description }) => {
  const formattedTitle = formatAlertText(title, discountPercent);
  return (
    <div className={cx('container')}>
      <Text className={cx('title')} size="size2-m">
        {formattedTitle}
      </Text>
      {items?.map(({ description: itemDescription, price, unit }) => {
        const fullPrice = price && unit ? `${price} ${unit}` : null;
        return fullPrice ?
            <div className={cx('info')}>
              <Text className={cx('text')} size="size5-r-l">
                {itemDescription}
              </Text>
              <Text className={cx('text')} size="size5-r-l">
                {fullPrice}
              </Text>
            </div>
          : <Text className={cx('subtitle')} size="size3-m">
              {itemDescription}
            </Text>;
      })}
      {description && (
        <Text className={cx('description')} size="size3-m">
          {description}
        </Text>
      )}
      <Button className={cx('button')} onClick={onClose}>
        {buttonText}
      </Button>
    </div>
  );
};
