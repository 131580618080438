export const ZERO_BALANCE_SOCS = ['SOSPM_VIP', 'SOSPM_REG', 'SOSP_VIP', 'SOSP_REG', 'SOSP'];

export const TRUST_PAYMENT_SOCS = ['TP2', 'TP2_2'];

export const SERVICE_SOCS = [...ZERO_BALANCE_SOCS, ...TRUST_PAYMENT_SOCS];

export const SCREEN_ID_CONFIG = {
  main: 'Main',
  services: 'Services',
  serviceDetail: 'ServiceDetail',
};

export const URL_CONFIG = {
  trustPaymentService:
    '/customers/products/mobile/services/details/doveritelnyy-platezh/promisedpayment/mobile/',
  topUpAccount: '/customers/finansy-oplata/popolnenie-scheta/',
  redirectUrl:
    '/customers/finansy-oplata/popolnenie-scheta/promisedpayment/fttb/#/PromisedPayment/Mobile',
  topUpAccountUrl: '/customers/finansy-oplata/popolnenie-scheta/',
};
