import { errorsType } from './constants';
import { getOnlyDigitsPhone } from '../../utils/getOnlyDigitsPhone';
import { VALID_PHONE_LENGTH, isFilledPhone } from '../../utils/isFilledPhone';
import { TMnpValidateErrors } from '../../store/slices/commonContentSlice/types';

/** Устанавливает ошибку валидации по ключу */
const getErrorStatus = (error: keyof typeof errorsType, mnpValidateErrors: TMnpValidateErrors) => {
  if (error in errorsType) {
    return mnpValidateErrors?.[error];
  }

  return mnpValidateErrors?.failedRequest;
};

/**
 * @desc: проверяет, есть ли номер у пользователя в корзине
 * @param boughtNumbers { string[] } номера в корзине
 * @param currentNumber { string } номер для проверки
 * @returns {boolean}
 */
const isNumberBought = (boughtNumbers: string[], currentNumber: string): boolean => {
  if (!boughtNumbers?.length || !currentNumber) {
    return false;
  }

  return !!boughtNumbers.find((number) => number === currentNumber);
};

export { VALID_PHONE_LENGTH, getErrorStatus, getOnlyDigitsPhone, isFilledPhone, isNumberBought };
