import React from 'react';
import PropTypes from 'prop-types';

const HiddenInputs = ({ fields, firstTimeLogin, isMobileClient }) => (
  <div>
    {Object.keys(fields).map((value, key) => (
      <input key={key} name={value} type="hidden" value={fields[value]} />
    ))}
    {!firstTimeLogin && isMobileClient ?
      <input name="one_time_password" type="hidden" value="true" />
    : null}
  </div>
);

HiddenInputs.propTypes = {
  fields: PropTypes.object,
  firstTimeLogin: PropTypes.bool,
  isMobileClient: PropTypes.bool,
};

export default HiddenInputs;
