import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import classNames from 'classnames/bind';

import store from 'store';
import { setAdditionalSimCount } from 'pages/ProductCard/actions/tariffConnect';
import { useIsMobile } from 'utils/hooks/useIsMobile';

// Styles

import rootStyles from '../styles.pcss';
import styles from './styles.pcss';

const cx = classNames.bind({ ...styles, ...rootStyles });

const AdditionalSimComponent = ({
  className,
  data: { maxSimCount, defaultSimCount },
  label,
  value,
  onSetAdditionalSimCount,
}) => {
  const [items, setItems] = useState([]);
  const [simCount, setSimCount] = useState(defaultSimCount);
  const isMobile = useIsMobile();
  const [hoverItem, setHoverItem] = useState(isMobile ? 0 : defaultSimCount);

  useEffect(() => {
    onSetAdditionalSimCount(simCount);
  }, [simCount]);
  useEffect(() => {
    if (maxSimCount && maxSimCount !== items.length) {
      setItems(Array.from(Array(maxSimCount).keys()));
    }
  }, [maxSimCount]);

  return (
    <div className={cx('tiles', className)}>
      <div className={cx('rightBox')}>
        <div className={cx('about')}>{value}</div>
      </div>
      <div className={cx('leftBox')}>
        {maxSimCount > 0 && (
          <div className={cx('AdditionalSimCount')}>
            <div className={cx('AdditionalSimControls')}>
              {items.map((item) => (
                <label
                  className={cx('AdditionalSimControlsItem', {
                    additionalSimControlsItemFakerBlur:
                      item === 0 && simCount === 1 && hoverItem === 1,
                  })}
                  key={item}
                  onMouseEnter={() => {
                    if (!isMobile) {
                      setHoverItem(item + 1);
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isMobile) {
                      setHoverItem(0);
                    }
                  }}
                >
                  <input
                    checked={item + 1 === simCount}
                    className={cx('AdditionalSimControlsRadio')}
                    name="AdditionalSimCount"
                    onChange={() => {
                      setSimCount(item + 1);
                    }}
                    onClick={() => {
                      if (item + 1 === simCount) {
                        setSimCount(0);
                      }
                    }}
                    type="radio"
                  />
                  <div
                    className={cx('AdditionalSimCountFaker', {
                      additionalSimCountFakerActive:
                        item + 1 <= simCount || (!isMobile && item + 1 <= hoverItem),
                    })}
                  />
                </label>
              ))}
            </div>
            <div className={cx('AdditionalSimCountNum')}>
              {simCount} из {maxSimCount}
            </div>
          </div>
        )}
        <div className={cx('description')}>
          <div className={cx('descriptionText')}>{label}</div>
        </div>
      </div>
    </div>
  );
};

AdditionalSimComponent.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,

  data: PropTypes.shape({
    defaultSimCount: PropTypes.number,
    maxSimCount: PropTypes.number,
  }),

  onSetAdditionalSimCount: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onSetAdditionalSimCount: (num) => dispatch(setAdditionalSimCount(num)),
});

const ConnectedComponent = connect(null, mapDispatchToProps)(AdditionalSimComponent);

const AdditionalSim = (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);

export default AdditionalSim;
