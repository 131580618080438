import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, CollapsedContent, Heading, Link, SmartInput } from '@beef/legacy-ui-kit';

// Style
import classNames from 'classnames/bind';

import styles from '../styles.pcss';

const cx = classNames.bind(styles);

const ConnectStep = ({
  openDeleteNumber,
  openConfirm,
  onChange,
  data: {
    title,
    buttonText,
    beforeContent,
    deleteText,
    collapsedButtonText,
    collapsedContent,
    afterContent,
    disabled,
    msg,
  },
}) => (
  <div>
    {title && (
      <Heading isStyled={false} level={1}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Heading>
    )}
    <div className={cx('content')}>
      {beforeContent && <p dangerouslySetInnerHTML={{ __html: beforeContent }} />}

      {/** Форма добавления/отправки номера */}
      <div className={cx('form')}>
        <SmartInput
          className={cx('formInput')}
          mask="+7 ___ ___ __ __"
          msg={msg}
          onChange={onChange}
          size="big"
          status={msg && 'fail'}
          value="+7 "
        />
        <Button className={cx('big')} disabled={!disabled} onClick={openConfirm} transformer>
          {buttonText}
        </Button>
      </div>

      {deleteText && (
        <div className={cx('delNumber')}>
          {deleteText[0]}
          <Link onClick={openDeleteNumber}>удалить</Link>
          {deleteText[1]}
        </div>
      )}

      {/** Скрытый кондент */}
      {collapsedContent && (
        <CollapsedContent title={collapsedButtonText}>
          <div dangerouslySetInnerHTML={{ __html: collapsedContent }} />
        </CollapsedContent>
      )}

      {afterContent && <p dangerouslySetInnerHTML={{ __html: afterContent }} />}
    </div>
  </div>
);

ConnectStep.propTypes = {
  openDeleteNumber: PropTypes.func,
  openConfirm: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.shape({
    title: PropTypes.string,
    beforeContent: PropTypes.string,
    buttonText: PropTypes.string,
    deleteText: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    collapsedButtonText: PropTypes.string,
    collapsedContent: PropTypes.string,
    afterContent: PropTypes.string,
    disabled: PropTypes.bool,
    msg: PropTypes.string,
  }),
};

export default ConnectStep;
