import { TVariables } from '../types';
import { useIsHighPreset } from '../../../../hooks/useIsHighPreset';
import {
  DETAILS_INFO,
  HIGH_DETAILS_INFO,
  IS_BEE,
  MAIN_POPUP_KEY,
  MIN_COUNT_EXTRA_TILES,
  POWER_POPUP_KEY,
} from '../../../../constants';

/** Подготовка общих переменных, используемых в плитке тарифа */
export const usePrepareVariables = ({
  isOpen,
  okBtnText,
  chosenCharacter,
  defaultCharacter,
  preViewCharacter,
}: TVariables) => {
  /* Определение того, является ли текущий пресет высшим */
  const isHighPreset = useIsHighPreset();

  /* Контентные данные по текущему выбранному персонажу */
  const { name, imageSrc, chooseBtn, advantages, description } =
    chosenCharacter || defaultCharacter;

  /* Идентификатор "Бази" (используется для уникального отображения картинки) */
  const isBeeIndent = name === IS_BEE;

  /* Определение того, является ли текущий открытый попап главным (выбор персонажа) */
  const isMainKey = isOpen === MAIN_POPUP_KEY;

  /* Определение того, является ли текущая плитка вариантом Power */
  const isPowerKey = isOpen === POWER_POPUP_KEY;

  /* Массив доп. плиток (power, partner, superPower) */
  const extraTiles = Object?.values(advantages);

  /* Массив ключей доп. плиток (power, partner, superPower) */
  const extraTilesKeys = Object.keys(advantages) as unknown as Array<keyof typeof advantages>;

  /* Тип контента для информационного попапа в зависимости от того,
   *  является ли текущая просматриваемая плитка Power-вариантом, а также является ли текущий пресет высшим */
  const defineTypeInfo = isPowerKey && isHighPreset ? HIGH_DETAILS_INFO : DETAILS_INFO;

  /* Контент для попапа плитки Power */
  const detailsInfo = advantages[isOpen as keyof typeof advantages]?.[defineTypeInfo];

  /* Сброс отступа для изображения плитки */
  const withoutIndent = !!extraTiles?.length && extraTiles?.length > MIN_COUNT_EXTRA_TILES;

  /* Вариант текста в кнопке, в зависимости от выбранного попапа.
   * Текст относится к главной (mainBtn) кнопке */
  const currentButtonText = isMainKey ? preViewCharacter?.chooseBtn || chooseBtn : okBtnText;

  return {
    name,
    imageSrc,
    isMainKey,
    advantages,
    isBeeIndent,
    detailsInfo,
    description,
    withoutIndent,
    extraTilesKeys,
    currentButtonText,
  };
};
