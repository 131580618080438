import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import { SignCanvas } from 'pages/EReplacementSimLanding/components/SigningComponent/SignCanvas';
import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class SigningComponent extends PureComponent {
  state = {
    showCrossButton: false,
  };

  onSignStart = () => {
    this.setState({ showCrossButton: true });
  };

  getImage = () => this.signCanvas.getImage();

  signErase = () => {
    this.signCanvas.erase();
    this.setState({ showCrossButton: false });
  };

  render() {
    return (
      <div className={cx('signFieldWrapper')}>
        {this.state.showCrossButton ?
          <EmptyButton className={cx('crossButton')} onClick={this.signErase}>
            <Icon name="cross" />
          </EmptyButton>
        : <span className={cx('previewText')}>{this.props.descriptionSigning}</span>}

        <SignCanvas
          onSignStart={this.onSignStart}
          ref={(e) => {
            this.signCanvas = e;
          }}
        />
      </div>
    );
  }
}

SigningComponent.propTypes = {
  descriptionSigning: PropTypes.string,
};

export default SigningComponent;
