import React from 'react';

import { useCheckAddressInputsTexts } from '../../selectors/useCheckAddressInputsTexts';
import { PhoneInput } from '../../../../../../commonComponents/PhoneInput';

export const FmcCheckPhoneInput = () => {
  const { contactPhoneTitle } = useCheckAddressInputsTexts();

  return <PhoneInput label={contactPhoneTitle} />;
};
