import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Switch } from '@beef/ui-kit';

import { ItemTitle } from './ItemTitle';
import styles from '../TariffContentContainer/styles.pcss';

const cx = classNames.bind(styles);

export const ItemTopBlock = ({ name, text, checked, disabled, onChange, showToolTip }) => (
  <div className={cx('wrapper__top')}>
    <ItemTitle {...{ name, text, showToolTip }} />
    <Switch {...{ name, checked, disabled, onChange }} />
  </div>
);

ItemTopBlock.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  showToolTip: PropTypes.bool,
};
