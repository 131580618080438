import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { PassiveTitle } from './components/PassiveTitle';
import { PassiveTabs } from './components/PassiveTabs';
import { PassiveInfo } from './components/PassiveInfo';
import { PassiveYandex } from './components/PassiveYandex';
import { usePrepareModalContent } from './hooks/usePrepareModalContent';
import { TPassivePopups } from '../../types';
import styles from './styles.pcss';
import { PassiveFamily } from '../../../PassiveFamily';

const cx = classNames.bind(styles);

type TModalContent = {
  passivePopups: TPassivePopups;
  selectedType: string;
};

/** Компонент контентной части попапа пассивок */
export const ModalContent: FC<TModalContent> = ({ passivePopups, selectedType }) => {
  const {
    title,
    passiveTabsProps,
    passiveInfoProps,
    passiveYandexInfoProps,
    passiveFamilyInfoProps,
  } = usePrepareModalContent(passivePopups, selectedType);
  return (
    <section className={cx('wrapper')}>
      <PassiveTitle title={title} />
      <PassiveTabs {...passiveTabsProps} />
      <PassiveInfo {...passiveInfoProps} />
      <PassiveFamily {...passiveFamilyInfoProps} />
      <PassiveYandex {...passiveYandexInfoProps} />
    </section>
  );
};
