import React, { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Carousel } from '@carnica/smart-kit';
import { Swiper } from 'swiper/types';

import styles from './styles.pcss';
import { InfoPure } from '../Info';
import { Analytics } from './components/Analytics';
import { DownloadButtons } from '../../blocks/DownloadButtons';
import { ISlides } from '../../types';
import { Bullets } from './components/Bullets';

const cn = classNames.bind(styles);

export const Slides: React.FC<ISlides> = memo(({ slides, fixedButton, isActive }) => {
  const [activeTitle, setActiveTitle] = useState<string | undefined>(slides[0]?.title);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleSlideChange = (swiper: Swiper) => {
    setActiveTitle(slides[swiper?.realIndex]?.title);
  };

  // Пробрасываем булеты в карусель и ренедрим в портале, чтобы в мобильном виде при появлении
  // нижнего бара с адресом булеты не заходили под него
  useEffect(() => {
    window.sliderBulletsRef = ref;
  }, [ref]);

  return (
    <div className={cn('wrap')}>
      <Carousel
        CustomBullets={Bullets}
        bulletsClassName={cn('bullets')}
        onSlideChange={handleSlideChange}
      >
        {slides?.map((slide) => (
          <InfoPure key={slide?.imageUrl} {...slide} hideGradient isBottomImg={slide.isBottomImg} />
        ))}
      </Carousel>
      {isActive && <Analytics activeTitle={activeTitle} slides={slides} />}
      <div className={cn('buttons-wrap')} ref={ref}>
        <DownloadButtons className={cn('buttons')} {...fixedButton} screenTitle={activeTitle} />
      </div>
    </div>
  );
});
