import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Link, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import {
  CART_URL,
  formatOrderData,
  getCartRedirect,
  handleSuccessRequest,
} from '@beef/layout-kit/utils';
import { setCartData } from '@carnica/smart-kit';

import { maskPhone } from 'utils/format-string';
import { isNumberBought } from 'utils/cartHelpers';
import Icon from 'pages-components/Icon';
import ErrorPopup from 'pages-components/ErrorPopup/ErrorPopup';
import { headerStore2024 } from 'store';

import PriceWrapper from '../PriceWrapper';
import styles from './index.pcss';

const cx = classNames.bind(styles);

class HotNumbers extends Component {
  // eslint-disable-next-line consistent-return
  static getDerivedStateFromProps({ hotNumbers }, state) {
    if (state.chosenIndex >= hotNumbers?.length) {
      return { chosenIndex: 0 };
    }
  }

  state = {
    chosenIndex: 0,
    pending: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { chosenIndex, pending } = this.state;
    if (pending && chosenIndex !== prevState.chosenIndex) {
      this.setState({
        pending: false,
      });
    }
  }

  handleNextClick = (event) => {
    event.preventDefault();

    const index = this.state.chosenIndex + 1;
    this.setState({
      chosenIndex: index >= this.props.hotNumbers.length ? 0 : index,
    });
  };

  checkIsBought = (value) => isNumberBought(this.props.boughtNumbers, value);

  handleChoseClick = () => {
    const chosenNumber = this.props.hotNumbers[this.state.chosenIndex].value;

    const request = formatOrderData({
      ctn: chosenNumber,
      simOrderPlace: 'Bronze',
    });
    this.setState({
      pending: true,
    });

    this.props.cartApi
      .post(`/${request.apiEndpoint}`, request.body)
      .then(({ data }) => {
        handleSuccessRequest({
          cartId: data.id,
          items: data.items,
          redirectToCart: getCartRedirect(),
        });
        this.props.handleSetCartData(data);
      })
      .catch(() => {
        this.setState({ errorPopupOpened: true });
      })
      .finally(() => {
        this.setState({
          pending: false,
        });
      });
  };

  closePopup = () => {
    this.setState({ errorPopupOpened: false });
  };

  render() {
    const { param, button, showMore } = this.props.texts;
    const { hotNumbers, onMoreClick } = this.props;
    const { chosenIndex, pending } = this.state;
    const chosenNumber = hotNumbers[chosenIndex];
    const numberBought = this.checkIsBought(chosenNumber?.value);

    if (!chosenNumber) {
      return null;
    }

    return (
      <div className={cx('wrapper')}>
        <div className={cx('carousel')}>
          <div
            className={cx('number', {
              'number--bought': numberBought,
            })}
          >
            {maskPhone(chosenNumber.value, '+7 ___ ___ __ __')}
          </div>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button className={cx('refreshButton')} onClick={this.handleNextClick} type="button">
            <Icon name="arrowCircled" />
          </button>
        </div>

        <div className={cx('parameter')}>
          <div>
            <Text size="size5-r-s">{param.label}</Text>
          </div>
          <PriceWrapper {...param} />
        </div>

        <div className={cx('connect')}>
          {numberBought ?
            <Link
              buttonSize="l"
              buttonVariant="secondary"
              href={CART_URL}
              isFullWidth
              variant="button"
            >
              Перейти в корзину
            </Link>
          : <Button disabled={pending} fullWidth onClick={this.handleChoseClick}>
              {button}
            </Button>
          }
          <div className={cx('other')}>
            <Link onClick={onMoreClick} size="size5-r-s">
              {showMore}
            </Link>
          </div>
        </div>
        {this.state.errorPopupOpened && (
          <ErrorPopup
            buttonText="Выбрать другой номер"
            description="Кто-то уже купил этот номер.<br>
            Пожалуйста, выберите другой и оформите заказ"
            onClose={this.closePopup}
            opened={this.state.errorPopupOpened}
            title="Что-то пошло не так..."
          />
        )}
      </div>
    );
  }
}

HotNumbers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  texts: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  hotNumbers: PropTypes.array.isRequired,
  onMoreClick: PropTypes.func.isRequired,
  boughtNumbers: PropTypes.arrayOf(PropTypes.string),
  handleSetCartData: PropTypes.func,
  cartApi: PropTypes.func,
};

const mapStateToProps = (state) => ({
  hotNumbers: state.external.fancyLanding?.hotNumbers || [],
  cartApi: state.external?.cartData?.cartApi,
  boughtNumbers: state.external?.cartData?.currentCart?.numbers ?? [],
});

const mapDispatchToProps = () => ({
  handleSetCartData: (data) => headerStore2024().dispatch(setCartData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HotNumbers);
