import React from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { TBroadBandInternetPopupProps } from 'pages/Services/TrustPayment/types';
import { RecursivePartial } from 'utils/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const ModalSection: React.FC<{
  section: RecursivePartial<TBroadBandInternetPopupProps['sections'][number]>;
}> = ({ section }) => {
  return (
    <div className={cn('section-item')}>
      <div className={cn('left')}>
        {section.icon && <img alt="icon" className={cn('icon')} src={section.icon} />}
      </div>
      <div className={cn('right')}>
        <Text size="size5-m">{section.title}</Text>
        <Text color="grey60" size="size6-r">
          {section.description}
        </Text>
      </div>
    </div>
  );
};
