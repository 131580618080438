import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import addDays from 'date-fns/add_days';
import format from 'date-fns/format';
import startOfDay from 'date-fns/start_of_day';
import { Button, DatePicker, Dropdown, Preloader, Status, TextInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { getAvailableSchedule as getAvailableScheduleAction } from 'pages/FTTB/actions';
import {
  getAvailableScheduleList as getAvailableScheduleListSelector,
  getAvailableScheduleObj as getAvailableScheduleObjSelector,
  isAvailableScheduleError as isAvailableScheduleErrorSelector,
  isAvailableScheduleLoading as isAvailableScheduleLoadingSelector,
} from 'pages/FTTB/selectors/availableSchedule';
import {
  mapTimePeriodToOptionValue,
  parseTimePeriodFromOptionValue,
} from 'pages/FTTB/utils/timePeriodsHelper';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class ChooseDateTime extends PureComponent {
  state = {
    minDate: new Date(),
    maxDate: new Date(),
    choosedDate: null,
    entrance: '',
    intercom: '',
  };

  componentDidMount() {
    const { getAvailableSchedule, houseId, startDateOffsetInDays, endDateOffsetInDays } =
      this.props;
    if (houseId) getAvailableSchedule(houseId, startDateOffsetInDays, endDateOffsetInDays);

    const today = new Date();
    const minDate = addDays(today, startDateOffsetInDays);
    const maxDate = addDays(today, endDateOffsetInDays);

    this.setState({ minDate, maxDate });
  }

  componentDidUpdate(prevProps) {
    const { houseId, getAvailableSchedule } = this.props;
    const { houseId: prevHouseId } = prevProps;

    if (prevHouseId !== houseId) {
      this.resetDateTime();
      if (houseId) getAvailableSchedule(houseId);
    }
  }

  onDateChange = (date) => {
    this.setState({ choosedDate: date });
    this.props.onDateTimeChange({ date, timePeriod: null });
  };

  onEntranceChange = (val) => {
    this.setState({ entrance: val }, () => this.props.onEntranceChange(val));
  };

  onIntercomChange = (val) => {
    this.setState({ intercom: val }, () => this.props.onIntercomChange(val));
  };

  onTimeChange = (value) => {
    const { choosedDate: date } = this.state;
    const [startDate, endDate] = parseTimePeriodFromOptionValue(value);

    this.props.onDateTimeChange({ date, timePeriod: { startDate, endDate } });
  };

  onErrorBtnClick = () => {
    const { getAvailableSchedule, houseId, startDateOffsetInDays, endDateOffsetInDays } =
      this.props;
    if (houseId) getAvailableSchedule(houseId, startDateOffsetInDays, endDateOffsetInDays);
  };

  getTimePeriods = () => {
    const { availableSchedule } = this.props;
    const { choosedDate } = this.state;

    if (!choosedDate) return [];

    const startOfChoosedDay = startOfDay(choosedDate);
    const day = availableSchedule[startOfChoosedDay.getTime()];
    return day ? day.timePeriods : [];
  };

  getTimePeriodsOptions = () => {
    const timePeriods = this.getTimePeriods();

    return timePeriods.map(({ start, end }) => ({
      value: mapTimePeriodToOptionValue(start, end),
      label: `${format(start, 'HH:mm')}-${format(end, 'HH:mm')}`,
    }));
  };

  getDateInputErrorMsg = () => {
    const { choosedDate } = this.state;
    const { contentData } = this.props;

    const emptyTimePeriodForDay = choosedDate && this.getTimePeriods().length === 0;

    if (emptyTimePeriodForDay) return contentData.emptyTimePeriodForDayError;
    return null;
  };

  get timeInputDisabled() {
    return this.getTimePeriods().length === 0;
  }

  resetDateTime = () => {
    if (this.datePicker) this.datePicker.handleChange(null);
  };

  renderForm() {
    const { choosedDate, minDate, maxDate } = this.state;
    const { contentData, dateInputStatus, timeInputStatus, houseId } = this.props;

    const dateInputErrorMsg = this.getDateInputErrorMsg();
    return (
      <div className={cx('wrapper')}>
        <div className={cx('dateTimeWrapper')}>
          <div className={cx('dateInputWrapper')}>
            <div
              className={cx('label')}
              dangerouslySetInnerHTML={{ __html: contentData.dateLabel }}
            />
            <DatePicker
              className={cx('calendar', {
                fail: dateInputStatus === 'fail' || dateInputErrorMsg,
              })}
              disabled={!houseId}
              hideTodayButton
              max={maxDate}
              min={minDate}
              onChange={this.onDateChange}
              placeholder={contentData.datePlaceholder}
              ref={(e) => {
                this.datePicker = e;
              }}
              size="big"
              status={dateInputStatus}
              value={choosedDate}
            />
            {dateInputErrorMsg && (
              <div className={cx('textFail')}>
                <span dangerouslySetInnerHTML={{ __html: dateInputErrorMsg }} />
              </div>
            )}
          </div>
          <div className={cx('timeInputWrapper')}>
            <div
              className={cx('label')}
              dangerouslySetInnerHTML={{ __html: contentData.timeLabel }}
            />
            <Dropdown
              className={cx('dropdown')}
              disabled={this.timeInputDisabled}
              onChange={this.onTimeChange}
              options={this.getTimePeriodsOptions()}
              placeholder={contentData.timePlaceholder}
              size="big"
              status={timeInputStatus}
              wide
            />
          </div>
        </div>
        <div className={cx('houseInfoWrap')}>
          <div className={cx('entranceWrap')}>
            <div
              className={cx('label')}
              dangerouslySetInnerHTML={{ __html: contentData.entranceLabel }}
            />
            <TextInput
              className={cx('entranceInput')}
              clearable
              maxLength={10}
              onChange={this.onEntranceChange}
              size="big"
            />
          </div>
          <div className={cx('intercomWrap')}>
            <div
              className={cx('label')}
              dangerouslySetInnerHTML={{ __html: contentData.intercomLabel }}
            />
            <TextInput
              className={cx('intercomInput')}
              clearable
              maxLength={10}
              onChange={this.onIntercomChange}
              size="big"
            />
          </div>
        </div>
      </div>
    );
  }

  renderError() {
    const { contentData } = this.props;

    return (
      <div className={cx('wrapper', 'errorWrapper')}>
        <div
          className={cx('errorTitle')}
          dangerouslySetInnerHTML={{ __html: contentData.requestErrorTitle }}
        />
        <Button className={cx('errorBtn')} color="light" onClick={this.onErrorBtnClick}>
          <span dangerouslySetInnerHTML={{ __html: contentData.requestErrorBtn }} />
        </Button>
      </div>
    );
  }

  renderEmptyAvailableDaysStatus() {
    const { contentData } = this.props;

    return (
      <div className={cx('wrapper', 'emptyDaysWrapper')}>
        <Status className={['wide', 'attention', cx('emptyDaysStatus')]} emoji="statusFail">
          <span dangerouslySetInnerHTML={{ __html: contentData.emptyDaysStatusText }} />
        </Status>
      </div>
    );
  }

  renderWarning() {
    const { contentData } = this.props;

    return (
      <div className={cx('wrapper', 'warningWrapper')}>
        <Status className={['wide', 'attention', cx('warningStatus')]} emoji="statusFail">
          <span dangerouslySetInnerHTML={{ __html: contentData.houseRequiredWarning }} />
        </Status>
      </div>
    );
  }

  renderLoader() {
    return (
      <div className={cx('wrapper', 'loaderWrapper')}>
        <Preloader />
      </div>
    );
  }

  render() {
    const {
      isAvailableScheduleError,
      isAvailableScheduleLoading,
      availableScheduleDaysCount,
      houseId,
    } = this.props;

    if (isAvailableScheduleLoading) return this.renderLoader();
    if (isAvailableScheduleError) return this.renderError();
    if (houseId && availableScheduleDaysCount === 0) return this.renderEmptyAvailableDaysStatus();
    if (!houseId) return this.renderWarning();

    return this.renderForm();
  }
}

ChooseDateTime.defaultProps = {
  onDateTimeChange: () => {},
  onEntranceChange: () => {},
  onIntercomChange: () => {},
};
ChooseDateTime.propTypes = {
  houseId: PropTypes.number,
  startDateOffsetInDays: PropTypes.number,
  endDateOffsetInDays: PropTypes.number,
  dateInputStatus: PropTypes.oneOf(['ok', 'fail']),
  timeInputStatus: PropTypes.oneOf(['ok', 'fail']),
  contentData: PropTypes.shape({
    chooseHouseError: PropTypes.string,
    emptyTimePeriodForDayError: PropTypes.string,
    dateLabel: PropTypes.string,
    datePlaceholder: PropTypes.string,
    timeLabel: PropTypes.string,
    timePlaceholder: PropTypes.string,
    requestErrorBtn: PropTypes.string,
    emptyDaysStatusText: PropTypes.string,
    entranceLabel: PropTypes.string,
    intercomLabel: PropTypes.string,
    requestErrorTitle: PropTypes.string,
    houseRequiredWarning: PropTypes.string,
  }),

  onDateTimeChange: PropTypes.func,
  onEntranceChange: PropTypes.func,
  onIntercomChange: PropTypes.func,
  getAvailableSchedule: PropTypes.func,
  availableSchedule: PropTypes.objectOf(
    PropTypes.shape({
      day: PropTypes.instanceOf(Date),
      timePeriods: PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.instanceOf(Date),
          end: PropTypes.instanceOf(Date),
        }),
      ),
    }),
  ),
  isAvailableScheduleError: PropTypes.bool,
  isAvailableScheduleLoading: PropTypes.bool,
  availableScheduleDaysCount: PropTypes.number,
};

const mapStateToProps = (state) => ({
  availableSchedule: getAvailableScheduleObjSelector(state),
  isAvailableScheduleError: isAvailableScheduleErrorSelector(state),
  isAvailableScheduleLoading: isAvailableScheduleLoadingSelector(state),
  availableScheduleDaysCount: getAvailableScheduleListSelector(state).length,
});

const mapDispatchToProps = {
  getAvailableSchedule: getAvailableScheduleAction,
};

const ConnectedChooseDateTime = connect(mapStateToProps, mapDispatchToProps)(ChooseDateTime);

export default ConnectedChooseDateTime;
