/* eslint-disable */
// todo: исправить ошибки линтера
import React, { useEffect, useState, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';
import { setActiveTab } from 'pages/ProductCard/Tariff/containers/ZTariff/store/actions';
import DispatchContext from 'pages/ProductCard/Tariff/containers/ZTariff/store/constants';
import { Tab } from './Tab';

// Styles
import styles from './styles.pcss';

// Utils
import { getTabsFromContentArray } from '../../../../../utils';

const cx = classNames.bind(styles);

export const Tabs = memo(({
  data: {
    title,
    content,
  },
  isAuthenticated,
  isTariffAvailableForConnect,
  isTariffConnected,
}) => {
  const _dispatch = useContext(DispatchContext);
  const defaultTab = isAuthenticated && (isTariffAvailableForConnect || isTariffConnected) ? 1 : 0;
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [filteredTabs, setFilteredTabs] = useState(content);

  useEffect(() => {
    if (!isAuthenticated && (filteredTabs[selectedTab].id === 'ChangeTariffForm')) {
      _dispatch(setActiveTab('LoginForm'));
      return;
    }
    _dispatch(setActiveTab(filteredTabs[selectedTab].id));
  }, [selectedTab]);

  useEffect(() => {
    const tabs = getTabsFromContentArray({
      tabs: content,
      isAuth: isAuthenticated,
      isConnected: isTariffConnected,
      isAvailable: isTariffAvailableForConnect,
    });
    setFilteredTabs(tabs);
  }, []);

  return (
    <>
      {title && (
        <Text tagType="h2" size="size3-m">
          {title}
        </Text>
      )}
      <div className={cx('wrapper-tabs')}>
        {filteredTabs?.map((tab, idx) => (
          <Tab
            id={idx}
            key={tab?.text}
            title={tab?.text}
            fancyNumber={tab?.fancyNumber}
            selected={selectedTab}
            onClick={() => setSelectedTab(idx)}
          />
        ))}
      </div>
    </>
  );
});

Tabs.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.array,
  }),
  isAuthenticated: PropTypes.bool,
  isTariffAvailableForConnect: PropTypes.bool,
  isTariffConnected: PropTypes.bool,
};
