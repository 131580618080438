import { useCallback, useMemo, useState } from 'react';

import { TUseMapStateToProps } from 'pages/Services/ZeroBalanceSupport/enhancers/withContext/types';
import {
  selectConfirmConnect,
  selectConnectPollUrl,
  selectConnectPopupUrl,
  selectInterval,
  selectSoc,
  selectTimeout,
} from 'pages/Services/ZeroBalanceSupport/selectors';
import { TZeroBalanceContext } from 'pages/Services/ZeroBalanceSupport/types';

import { TModalWrapperProps } from '../../../ModalWrapper/types';
import { ServicePoller } from '../../api/index';
import { EModal, EServiceField, EServiceName } from '../types';

export const useMapStateToProps: TUseMapStateToProps<TZeroBalanceContext, TModalWrapperProps> = ({
  state,
  ownProps: { setCurrentModal },
}) => {
  const connectPopupUrl = selectConnectPopupUrl(state);
  const connectPollUrl = selectConnectPollUrl(state);
  const soc = selectSoc(state);
  const modal = selectConfirmConnect(state);
  const interval = selectInterval(state);
  const timeout = selectTimeout(state);

  const servicePoller = useMemo(
    () =>
      new ServicePoller<EServiceName, EServiceField>(
        EServiceName.ServiceConnectForm,
        EServiceField.ConnectServiceUrl,
        connectPopupUrl,
        soc,
        interval,
        timeout,
      ),
    [connectPopupUrl, soc, interval, timeout],
  );

  const [loading, setLoading] = useState(false);

  const onClose = useCallback(() => setCurrentModal(null, false), [setCurrentModal]);

  const handleConfirm = useCallback(async () => {
    setLoading(true);
    try {
      await servicePoller.execute({
        onSuccess: () => {
          setCurrentModal(EModal.ConnectionSuccess, true);
        },
        onError: (e) => {
          console.error(e);
          setCurrentModal(EModal.RequestErrorStep, true);
        },
        options: {
          fallbackPollingUrl: connectPollUrl,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, [servicePoller, setCurrentModal]);

  return {
    setCurrentModal,
    onClose,
    content: {
      modalData: modal,
      actionBarProps: {
        loading,
        confirmButton: {
          content: modal?.button || {},
          onClick: handleConfirm,
        },
        rejectButton: {
          content: modal?.buttonReject || {},
          onClick: onClose,
        },
      },
    },
  };
};
