import { getHasBrowserWindowAPI } from './window';

/**
 * Метод, позволяющий перейти на страницу авторизации
 * В основоном, нужен для сценариев, где нужно
 * авторизовать пользователя, который находится в
 * неавторизованой зоне
 */
export const redirectToLoginPage = (customRedirectPath?: string) => {
  try {
    if (getHasBrowserWindowAPI()) {
      const redirectPath = [customRedirectPath, window.location.pathname].find((v) => !!v);

      window.location.href = [
        '/login',
        redirectPath && `?returnUrl=${encodeURIComponent(redirectPath)}`,
      ].join('');
    }
  } catch (e) {
    console.error('Could not perform redirect to the login page', e);
  }
};
