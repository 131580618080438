// CVM аналитика;
// Cсылка на документацию: http://arch.vimpelcom.ru/pages/viewpage.action?pageId=27112056;

import axios from 'axios';

const CVM_SAVE_RESPONSES_URL = '/opsoffer/saveresponses/';

let landViewedTimer = null;

/**
 * Функция spreadData используется для создания одного массива из вложеных массивов и обычных обьектов.
 * Если объект типа family мы ищем массив entries в объекте;
 * Если объект типа tariff мы кладем его целиком в массив;
 * @param { array } previousValue - последний результат вызова функции, он же «промежуточный результат».
 * @param { object } currentItem - текущий элемент массива, элементы перебираются по очереди слева-направо.
 */
const spreadData = (previousValue, currentItem) => {
  switch (currentItem.type) {
    case 'family':
      previousValue.push(...currentItem.entries);
      break;
    case 'tariff':
      previousValue.push(currentItem);
      break;
  }
  return previousValue;
};

/**
 * Функция formationObject формирует объект с данными необходимыми для передачи данных на бэк;
 * ------------------------------------------------------------------------------------------
 * @param { string }state - Тип/Имя состояние запроса;
 * @param { string } place - Указатель для функционала аналитики;
 */
export const formationObject =
  (state, place = null) =>
  (previousValue, currentItem) => {
    if (currentItem.campId && currentItem.subgroupId) {
      let obj = {
        subgroupId: currentItem.subgroupId,
        campId: currentItem.campId,
        state,
      };

      if (place) {
        obj = { ...obj, place };
      }

      previousValue.push(obj);
    }
    return previousValue;
  };

/**
 * Функция чейнит в себе функции разбора масива объектов и функцию
 * создания нового объекта для отправки на бэк;
 * ---------------------------------------------------------------
 * @param {Object[]} array - Массив объектов;
 * @param state { string } - Тип/Имя состояние запроса;
 * @param place { string } - Указатель для функционала аналитики;
 */
export const getEventArray = (array, state, place) =>
  array.reduce(formationObject(state, place), []);

/**
 * Отправляет запрос на бэк, возвращает промисс;
 * -------------------------
 * @param {Object[]} offersArray - Массив объектов для аналитики;
 */
export const sendCvmTariffAnalyticsEvent = (offersArray) =>
  axios.post(CVM_SAVE_RESPONSES_URL, offersArray).catch((err) => {
    console.error('CVM Offers Analytics Error: ', err);
  });

/**
 * Функция отправки аналитики без отчистки таймера, c редьюсером создания единого массива из: family, tariff;
 * ------------------------------------------------
 * @param {Object[]} tariffsArray - Массив объектов;
 * @param {string } state - Тип/Имя состояние запроса;
 * @param {string} place - флаг для разделения функционала;
 */
export const sendTariffsArrayAnalyticsEvent = (tariffsArray, state, place = null) => {
  const spreadArray = tariffsArray.reduce(spreadData, []);
  const eventArray = getEventArray(spreadArray, state, place);

  if (eventArray.length) {
    sendCvmTariffAnalyticsEvent(eventArray);
  }
};

/**
 * a. Абоненту прилетел КО place: downsale state: openChannel;
 * b. Абонент просматривает предложение контр-оффера более 5 секунд place: downsale state: landViewed;
 * c. Абонент нажал «неинтересно» place: downsale state: refuse;
 * d. Абонент согласился с рекомендованным предложением (нажал принять предложение) place: downsale state: landApproved;
 * e. Только для тарифа/тарифа со скидкой – абонент открыл поп-ап с подробным описанием тарифа
 * place: downsale state: open;
 * f. Абонент согласился с конфликтами на этапе подключения (для типа Акция такое состояние отправляется сразу
 * из окна downsell
 *    предложения) - place: downsale state: activation;
 * g. При попытке активации сервиса через метод корзины пришел ответ отличный от 200
 * ОК place: downsale state: landWantMore
 * --------------------------------------------------------------------------------------------------------------------
 * @param {Object[]} tariffsArray - Массив объектов;
 * @param { string } state - Тип/Имя состояние запроса;
 * @param { string } place - Интерфейс где сработала аналитика;
 */
// Реализация функционала: а, c, d, e, f;
export const sendCvmAnalyticsEvent = (tariffsArray = [], state, place) => {
  if (!tariffsArray.length) return;

  const eventArray = getEventArray(tariffsArray, state, place);

  if (eventArray.length) {
    sendCvmTariffAnalyticsEvent(eventArray);
    resetCvmAnalyticsTimer();
  }
};

// Реализация функционала: b;
export const timerCvmAnalyticsEvent = (tariffsArray = [], state, place = null) => {
  if (!tariffsArray.length) return;

  landViewedTimer = setTimeout(() => {
    sendCvmAnalyticsEvent(tariffsArray, state, place);
  }, 5000);
};

/**
 * Замыкание позволяет сделать один запрос при переключении табов
 */
const sendSingleEvent = () => {
  let num = 0;
  return (tariffsArray = [], state) => {
    if (num < 1 && tariffsArray.length) {
      const eventArray = getEventArray(tariffsArray, state, 'downsale');
      sendCvmTariffAnalyticsEvent(eventArray);
      num += 1;
    }
  };
};

/**
 * Функция нужна для сброса таймера при переходи на другой таб в модальном окне;
 */
export const resetCvmAnalyticsTimer = () => {
  if (process.env.RUN_ENV !== 'server') clearTimeout(landViewedTimer);
};

/**
 * Функция отправляет аналитику с праметром place, используется для upsale и cvm offers;
 * @param { array } arrayIds
 * @param { string } state
 * @param { string } place
export const sendCvmAnalyticsEvent = (arrayIds, state, place) => {
  const eventArray = getEventArray(arrayIds, state, place);
  sendCvmTariffAnalyticsEvent(eventArray);
};
 */

export const sendSingleDownsellAnalyticsEvent = sendSingleEvent();
