import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { DropdownCustom, Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';
import { CompareFilledIcon } from '@beef/ui-kit';

import Icon from 'pages-components/Icon';

import styles from './styles.pcss';
import { getKbyteString, getSecondsString } from '../../utils';
import { resetValues, setValues, toggleFlippedState } from '../../../../actions/tariffConstructor';

const cx = classNames.bind(styles);

class Switcher extends Component {
  state = {
    touched: false,
  };

  /**
   * Возвращает опции для Dropdown'а с выбором минут
   * @returns {array} Массив опций для Dropdown
   */
  getSecondsOptions = () => {
    const { flipped } = this.props;
    const quantums = {};

    this.props.constructors.forEach(({ seconds }) => {
      const moreThenZero = seconds.quantum > 0;
      const lessThenZero = seconds.quantum < 0;
      if (flipped ? moreThenZero : lessThenZero) {
        quantums[seconds.quantum] = 0;
      }
    });

    return this.addResetOption(
      'seconds',
      Object.keys(quantums).map((value) => ({
        label: `${flipped ? 'на ' : ''}${getSecondsString(Math.abs(value), true)}`,
        value: +value,
      })),
    );
  };

  /**
   * Возвращает опции для Dropdown'а с выбором onNet минут
   * @returns {array} Массив опций для Dropdown
   */
  getOnNetSecondsOptions = () => {
    const { flipped, constructors } = this.props;
    const quantums = {};

    constructors.forEach(({ onNetSeconds }) => {
      const moreThenZero = onNetSeconds.quantum > 0;
      const lessThenZero = onNetSeconds.quantum < 0;
      if (flipped ? moreThenZero : lessThenZero) {
        quantums[onNetSeconds.quantum] = 0;
      }
    });

    return this.addResetOption(
      'onNetSeconds',
      Object.keys(quantums).map((value) => ({
        label: `${flipped ? 'на ' : ''}${getSecondsString(Math.abs(value), true)}`,
        value: +value,
      })),
    );
  };

  /**
   * Возвращает опции для Dropdown'а с выбором offNet минут
   * @returns {array} Массив опций для Dropdown
   */
  getOffNetSecondsOptions = () => {
    const { flipped, constructors } = this.props;
    const quantums = {};

    constructors.forEach(({ offNetSeconds }) => {
      const moreThenZero = offNetSeconds.quantum > 0;
      const lessThenZero = offNetSeconds.quantum < 0;
      if (flipped ? lessThenZero : moreThenZero) {
        quantums[offNetSeconds.quantum] = 0;
      }
    });

    return this.addResetOption(
      'offNetSeconds',
      Object.keys(quantums).map((value) => ({
        label: `${!flipped ? 'на ' : ''}${getSecondsString(Math.abs(value), true)}`,
        value: +value,
      })),
    );
  };

  getSMSOptions = () => {
    const { flipped } = this.props;
    const quantums = {};

    this.props.constructors.forEach(({ sms }) => {
      if (flipped ? sms.quantum > 0 : sms.quantum < 0) {
        quantums[sms.quantum] = 0;
      }
    });

    return this.addResetOption(
      'sms',
      Object.keys(quantums).map((value) => ({
        label: `${Math.abs(value)} SMS`,
        value: +value,
      })),
    );
  };

  getKbytesOptions = () => {
    const { flipped } = this.props;
    const quantums = {};

    this.props.constructors.forEach(({ kbyte }) => {
      if (flipped ? kbyte.quantum < 0 : kbyte.quantum > 0) {
        quantums[kbyte.quantum] = 0;
      }
    });

    return this.addResetOption(
      'kbyte',
      Object.keys(quantums).map((value) => ({
        label: `${flipped ? '' : 'на '}${getKbyteString(Math.abs(value), true)}`,
        value: +value,
      })),
    );
  };

  addResetOption = (key, array) => {
    if (array.length > 0 && this.props.values[key] !== 0) {
      array.unshift({ label: 'Не менять', value: 0 });
    }
    return array;
  };

  switchSomething = (key) => (value) => {
    if (value === 'reset') {
      this.props.resetValues();
      return;
    }
    this.props.setValues(value, key);
  };

  splitDropdowns = () => {
    if (!this.state.touched) {
      this.setState({ touched: true });
    }
  };

  renderFlipButton = (className) =>
    this.props.unflippable ?
      <div className={cx('switchArrow', className)}>
        <Icon name="arrowLeft" />
      </div>
    : <button
        className={cx('switchButton', className)}
        onClick={this.props.toggleFlippedState}
        type="button"
      >
        <CompareFilledIcon />
      </button>;

  renderMinsAndSmsDropdown() {
    const { flipped, unflippable, values, texts } = this.props;
    const secondsOptions = this.getSecondsOptions();
    const smsOptions = this.getSMSOptions();
    const kbyteOptions = this.getKbytesOptions();
    let collapse = false;

    if (secondsOptions.length === 0) {
      collapse = 'seconds';
    } else if (smsOptions.length === 0 || !this.state.touched) {
      collapse = 'sms';
    }

    return (
      <div className={cx('dropdownsWrapper')}>
        <div
          className={cx('dropdowns', 'dropdownsFirst', { flipped }, `${collapse}Collapse`)}
          onClick={this.splitDropdowns}
          role="button"
          tabIndex="0"
        >
          <DropdownCustom
            collapsed={!collapse}
            disabled={!secondsOptions.length}
            onChange={this.switchSomething('seconds')}
            options={secondsOptions}
            placeholder={`${flipped ? 'на ' : ''}минуты${
              collapse && smsOptions.length > 0 ? ' и SMS' : ''
            }`}
            value={values.seconds || null}
          />
          <DropdownCustom
            collapsed={!collapse}
            disabled={!smsOptions.length}
            onChange={this.switchSomething('sms')}
            options={smsOptions}
            placeholder={`${collapse === 'seconds' && flipped ? 'на ' : ''}${
              collapse ? '' : 'и '
            }SMS`}
            value={values.sms || null}
          />
        </div>
        {this.renderFlipButton(['desktop', flipped && 'flipped'])}
        <div
          className={cx('dropdowns', 'dropdownsSecond', { flipped })}
          onClick={this.splitDropdowns}
          role="button"
          tabIndex="0"
        >
          <DropdownCustom
            disabled={!kbyteOptions.length}
            onChange={this.switchSomething('kbyte')}
            options={kbyteOptions}
            placeholder={`${flipped ? '' : 'на '}гигабайты`}
            value={values.kbyte || null}
          />
        </div>
        {unflippable && (
          <div className={cx('tooltip')}>
            <Tooltip>{flipped ? texts.oneSideInfoSeconds : texts.oneSideInfoKbyte}</Tooltip>
          </div>
        )}
      </div>
    );
  }

  renderInnetOffnetDropdown() {
    const { flipped, values } = this.props;
    const onNetSeconds = this.getOnNetSecondsOptions();
    const offNetSeconds = this.getOffNetSecondsOptions();

    return (
      <div className={cx('dropdownsWrapper')}>
        <div className={cx('dropdowns', 'dropdownsFirst', { flipped })}>
          <DropdownCustom
            disabled={!onNetSeconds.length}
            onChange={this.switchSomething('onNetSeconds')}
            options={onNetSeconds}
            placeholder="минуты на билайн"
            value={values.onNetSeconds || null}
          />
        </div>
        {this.renderFlipButton(['desktop', flipped && 'flipped'])}
        <div className={cx('dropdowns', 'dropdownsSecond', { flipped })}>
          <DropdownCustom
            disabled={!offNetSeconds.length}
            onChange={this.switchSomething('offNetSeconds')}
            options={offNetSeconds}
            placeholder="минуты на другие сети"
            value={values.offNetSeconds || null}
          />
        </div>
      </div>
    );
  }

  render() {
    const { isLifeFamily } = this.props;

    return (
      <div className={cx('component')}>
        <div className={cx('title')}>
          <span>Обменять</span>
          {this.renderFlipButton('mobile')}
        </div>
        {isLifeFamily ? this.renderInnetOffnetDropdown() : this.renderMinsAndSmsDropdown()}
      </div>
    );
  }
}

Switcher.defaultProps = {
  constructors: [],
  values: {},
  texts: {},
  flipped: false,
  unflippable: false,
};

Switcher.propTypes = {
  constructors: PropTypes.arrayOf(PropTypes.shape({})),
  values: PropTypes.shape({}),
  texts: PropTypes.shape({}),
  flipped: PropTypes.bool,
  unflippable: PropTypes.bool,
  isLifeFamily: PropTypes.bool,
  resetValues: PropTypes.func.isRequired,
  toggleFlippedState: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    const tariffConstructor = pathOr({}, ['external', 'tariffConstructor'], state);
    const { isLifeFamily } = tariffConstructor;

    return {
      flipped: tariffConstructor.flipped,
      unflippable: tariffConstructor.unflippable,
      constructors: tariffConstructor.data.constructors,
      accumulators: tariffConstructor.data.accumulators,
      values: tariffConstructor.values,
      isLifeFamily,
    };
  },
  (dispatch) => ({
    setValues: (value, key) => dispatch(setValues(value, key)),
    toggleFlippedState: () => dispatch(toggleFlippedState),
    resetValues: () => dispatch(resetValues),
  }),
)(Switcher);
