import React from 'react';
import { PageFixer } from '@beef/smart-kit';
import classNames from 'classnames/bind';

import type { PageFixerType } from './smartKitTypes';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export interface IWidthFixerProps extends PageFixerType {
  withBorder?: boolean;
}

export const WidthFixer: React.FC<IWidthFixerProps> = ({
  backgroundColor = 'main',
  children,
  withBorder,
  ...pageFixerProps
}) => (
  <div
    className={cx('component', withBorder && 'with-border', `background-${backgroundColor}`)}
    style={{ backgroundColor: pageFixerProps.backgroundCustomColor }}
  >
    <PageFixer backgroundColor={backgroundColor} size="m" {...pageFixerProps}>
      {children}
    </PageFixer>
  </div>
);
