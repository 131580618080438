import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'querystring';

import globalStore from 'store';

import { FILTER_FAVORITE } from '../../constants';
import {
  fetchAllNumbers,
  fetchChangeNumbers,
  fetchFavoriteNumbers,
  fetchSettings,
  fetchSimilarNumbers,
} from '../api/index';
import {
  getActiveFilter,
  getDefCodeValue,
  getIsQueryValid,
  getQueryValue,
} from '../selectors/filter';
import {
  getNextRandomNumber,
  selectCaptchaData,
  selectIsCaptchaEnabled,
} from '../selectors/numbers';
import { pushNextRandomClick } from '../../analytics/filter';
import { getRandomContent } from '../selectors/content';
import { isChangeNumberLocation } from '../../utils';

export const setDefCodes = createAction('set def codes');
export const setNumbersList = createAction('set numbers list');
export const setRandomNumber = createAction('set random number');
export const setNumbersInCart = createAction('set numbers in cart');
export const setLoading = createAction('set numbers loading');
export const setError = createAction('set numbers error');
export const setCaptchaIsEnabled = createAction('set captcha is enabled');
export const setCaptchaData = createAction('set captcha data');
export const setReCallAfterCaptchaRequest = createAction('set recall after captcha request');

export const loadFilteredNumbersThunk = createAsyncThunk(
  'fetch filtered numbers',
  // eslint-disable-next-line no-unused-vars
  async ({ filter, query, defCode }, { _, getState, rejectWithValue }) => {
    const state = getState();

    const captchaDataFromStore = selectCaptchaData(state);
    const captchaIsEnabled = selectIsCaptchaEnabled(state);

    const options = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify({
        captcha: captchaDataFromStore?.captchaToken,
        _captcha_key: captchaDataFromStore?.key,
      }),
      method: 'POST',
    };

    const captchaOptions = captchaIsEnabled ? options : {};

    try {
      if (filter === FILTER_FAVORITE) {
        return await fetchFavoriteNumbers(query, captchaOptions);
      }

      return await fetchSimilarNumbers({ filter, number: query, defCode }, captchaOptions);
    } catch (error) {
      console.error('Error in loadFilteredNumbersThunk:', error.response);
      return rejectWithValue(error);
    }
  },
);

export const loadFilteredChangeNumbersThunk = createAsyncThunk(
  'fetch change numbers',
  async ({ query, filter, defCode }) => fetchChangeNumbers({ query, filter, defCode }),
);

export const loadDefCodes = () => async (dispatch) => {
  try {
    const defCodes = await fetchSettings();
    dispatch(setDefCodes(defCodes));
  } catch (err) {
    // do nothing
  }
};

const selectNextRandomNumber = (dispatch, getState) => {
  const nextNumber = getNextRandomNumber(getState());
  dispatch(setRandomNumber(nextNumber));
};

export const setNextRandomNumber = () => (dispatch, getState) => {
  dispatch(selectNextRandomNumber);
  pushNextRandomClick(getRandomContent(getState()).category);
};

export const loadInitialNumbers = () => async (dispatch, getState) => {
  if (getIsQueryValid(getState())) {
    await dispatch(loadFilteredNumbers);
  } else {
    await dispatch(loadAllNumbers());
  }
  dispatch(selectNextRandomNumber);
};

export const loadAllNumbers = () => async (dispatch, getState) => {
  const isChangeNumber = isChangeNumberLocation();
  const state = getState();

  const query = getQueryValue(state);

  if (isChangeNumber) {
    dispatch(loadFilteredChangeNumbersThunk({ query }));
  } else {
    try {
      const numbers = await fetchAllNumbers();
      dispatch(setNumbersList(numbers));
      dispatch(setCaptchaIsEnabled(false));
    } catch (err) {
      dispatch(setError(true));
      dispatch(setNumbersList({}));
    } finally {
      dispatch(setLoading(false));
    }
  }
};

export const loadFilteredNumbers = async (dispatch, getState) => {
  const isChangeNumber = isChangeNumberLocation();

  const query = getQueryValue(getState());
  if (query.length < 2) return;
  const filter = getActiveFilter(getState());
  const defCode = getDefCodeValue(getState());

  if (isChangeNumber) {
    dispatch(loadFilteredChangeNumbersThunk({ query, filter, defCode }));
  } else {
    await dispatch(loadFilteredNumbersThunk({ filter, query, defCode }));
  }
};

export const loadNumbersInCart = () => (dispatch) => {
  try {
    const numbers = globalStore.getState().external.cartData.currentCart.numbers || [];
    dispatch(setNumbersInCart(numbers.map((number) => +number)));
  } catch (err) {
    // no action needed
  }
};

export const loadFancyNumberData = () => (dispatch) => {
  dispatch(loadDefCodes());
  dispatch(loadInitialNumbers());
  dispatch(loadNumbersInCart());
};
