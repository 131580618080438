import { useAppSelector } from 'constructorV1/store';
import { selectBasketProps } from 'constructorV1/components/constructorPage/BillContainer/selectors/selectBasketProps';
import { selectConnectionType } from 'constructorV1/store/selectors/selectUser';
import { EConnectionType } from 'constructorV1/store/userSlice/types';
import { prepareMnpNumber } from 'constructorV1/utils/prepareMnpNumber';

/** Хук выборки параметров для отправки запроса к basket.
 * Содержит id тарифа, id конструктора. соки опций, данные по мнп */
export const useSelectBasketParams = () => {
  const { constructorId, optionsSocs, id, userPhoneNumber } = useAppSelector(selectBasketProps);
  const connectionType = useAppSelector(selectConnectionType);

  const isMnpUser = connectionType === EConnectionType.mnp;

  const constructorTariffData = {
    tariffConstructorId: constructorId,
    optionSocs: optionsSocs?.join(','),
    partnersId: '',
  };

  /** Базовые параметры для дальнейшего преобразования к формату корзины.
   * Используются для покупки новой sim */
  const baseParams = {
    tariffId: id,
    constructorTariffData,
    additionalCtnOptions: {},
  };

  /** Дополнительные параметры для покупки mnp
   * расширяют baseParams в случае перехода со своим номером */
  const mnpParams = {
    simOrderPlace: 'mnp',
    mnp: prepareMnpNumber(userPhoneNumber || ''),
  };

  const params = {
    ...baseParams,
    ...(isMnpUser ? mnpParams : {}),
  };
  return { params };
};
