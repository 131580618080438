import React, { PureComponent } from 'react';
import { Provider, connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'query-string';
import {
  Button,
  Emoji,
  Heading,
  Link,
  Preloader,
  SmartForm,
  SmartInput,
} from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';
import { maskedPhone } from 'utils/validators';
import { currentStateList as currentPopupList } from 'pages/FTTB/constants';
import { pushBackCallAction } from 'utils/ga';
import PopupStateless from 'pages-components/Popup';
import Icon from 'pages-components/Icon';
import { CaptchaWithCallbackPopupHOC } from 'pages/FTTB2021/hoc/CaptchaWithCallbackPopupHOC';
import Captcha from 'pages/Feedback/components/Captcha/Captcha';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const initialPhoneValue = '+7 ';

const CAPTCHA_KEY = 'fttb_quick_order_popup';

class PopupWithReCaptcha extends PureComponent {
  state = {
    showCaptcha: this.props.showCaptcha,
    currentPopup: currentPopupList.requestForm,
    openedTermOfUse: false,
    phoneInputValue: initialPhoneValue,
  };

  componentDidMount() {
    const { currentCtn } = this.props;

    if (currentCtn && (currentCtn.isMobile || currentCtn.isConvergent)) {
      this.changePhoneInput(currentCtn.accountText.replace(/-/g, ' '));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentPopup } = this.state;
    const { currentPopup: prevPopup } = prevState;

    const { currentCtn, isOpen, pushGA } = this.props;
    const { currentCtn: prevCurrentCtn, isOpen: prevIsOpen } = prevProps;

    if (currentPopup === currentPopupList.success && currentPopup !== prevPopup) {
      this.props.onSuccess(this.resetPhoneInput);
    }

    const prevCtn = prevCurrentCtn ? prevCurrentCtn.ctn : '';
    if (
      currentCtn &&
      (currentCtn.isMobile || currentCtn.isConvergent) &&
      currentCtn.ctn !== prevCtn
    ) {
      this.changePhoneInput(currentCtn.accountText.replace(/-/g, ' '));
    }

    if (isOpen && !prevIsOpen) pushGA('shown');
  }

  onSubmit = (values) => {
    const { pushGA } = this.props;
    pushGA('click');

    if (this.props.gaEventLabel) {
      pushBackCallAction('send', this.props.gaEventLabel);
    }

    const { phoneInput } = values;
    const { submitUrl, phoneFieldName, additionalData } = this.props;
    this.setState({ currentPopup: currentPopupList.loading, phoneInputValue: phoneInput });

    axios({
      method: 'POST',
      url: submitUrl,
      data: qs.stringify({
        [phoneFieldName]: phoneInput.replace(/ /g, ''),
        captcha: this.props.captchaToken,
        _captcha_key: CAPTCHA_KEY,
        ...additionalData,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then(({ data: { isSucceeded, isCaptchaRequired, isCaptchaError } }) => {
        const { showCaptcha } = this.state;
        if (isSucceeded) {
          this.setState({ currentPopup: currentPopupList.success, showCaptcha: isCaptchaRequired });
          if (isCaptchaRequired && !showCaptcha) pushGA('captcha');

          pushGA('send');
        } else if (isCaptchaError) {
          if (isCaptchaRequired && !showCaptcha) {
            pushGA('captcha');
            this.setState({ showCaptcha: true });
          }

          this.setState({ currentPopup: currentPopupList.requestForm });
        } else {
          pushGA('sendError');
          this.setState({ currentPopup: currentPopupList.fail });
        }
      })
      .catch((err) => {
        console.error(err);
        pushGA('sendError');
        this.setState({ currentPopup: currentPopupList.fail });
      });
  };

  onClose = () => {
    this.setState({
      currentPopup: currentPopupList.requestForm,
      phoneInputValue: initialPhoneValue,
    });
    this.props.onClose();
  };

  changePhoneInput = (phoneInputValue) => this.setState({ phoneInputValue });

  resetPhoneInput = () => this.setState({ phoneInputValue: '+7 ' });

  toggleTermOfUse = () => {
    const { openedTermOfUse } = this.state;
    this.setState({ openedTermOfUse: !openedTermOfUse });
  };

  renderRequestFrom() {
    const { openedTermOfUse, phoneInputValue, showCaptcha } = this.state;
    const { reCaptchaModel } = this.props;
    const { messageText, title, buttonText, termsOfUse, userAgreementText, termsOfUseLinkText } =
      this.props.texts;

    return (
      <div className={cx('wrapper')}>
        <Heading className={cx('title')} level={1} tagName="div">
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Heading>
        <p className={cx('description')} dangerouslySetInnerHTML={{ __html: messageText }} />
        <SmartForm
          disableIfInvalid
          onSubmit={this.onSubmit}
          validator={{
            phoneInput: maskedPhone,
            captchaInput: (value) => ({
              status: value.length < 5 ? 'fail' : 'ok',
              msg: value.length < 5 ? 'Пройдите капчу' : '',
            }),
          }}
        >
          <div className={cx('inputsWrapper')}>
            <SmartInput
              className={cx('phoneInput')}
              inputId="phoneInput"
              keepInitialValue
              mask="+7 ___ ___ __ __"
              name="phoneInput"
              placeholder="+7 ___ ___ __ __"
              size="big"
              value={phoneInputValue}
            />
            <div className={cx('captchaWrapper')}>
              {showCaptcha && (
                <Captcha captchaKey={CAPTCHA_KEY} inputId="captchaInput" {...reCaptchaModel} />
              )}
            </div>

            <Button className={cx('button', 'submitButton')} type="submit" wide>
              <span dangerouslySetInnerHTML={{ __html: buttonText }} />
            </Button>
          </div>
        </SmartForm>
        {userAgreementText && (
          <div className={cx('termOfUse')}>
            <span dangerouslySetInnerHTML={{ __html: userAgreementText }} />

            {termsOfUseLinkText && termsOfUseLinkText.length && (
              <Link className={cx('termOfUseLink')} onClick={this.toggleTermOfUse}>
                <span dangerouslySetInnerHTML={{ __html: termsOfUseLinkText }} />

                <Icon
                  className={cx({ termOfUseCollapsedTitleArrow: true, opened: openedTermOfUse })}
                  name="dropdownArrow"
                />
              </Link>
            )}
            {openedTermOfUse && (
              <div
                className={cx('termOfUseContent')}
                dangerouslySetInnerHTML={{ __html: termsOfUse }}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  renderSuccess() {
    const { title, successTitle, successText, successButtonText } = this.props.texts;

    return (
      <div className={cx('wrapper')}>
        <Heading className={cx('titleWrapper')} level={1} tagName="div">
          <span
            className={cx('title')}
            dangerouslySetInnerHTML={{ __html: successTitle || title }}
          />
          <Emoji className={cx('emoji')} name="smile-happy" />
        </Heading>
        <p className={cx('description')} dangerouslySetInnerHTML={{ __html: successText }} />

        <Button className={cx('button')} onClick={this.onClose}>
          <span dangerouslySetInnerHTML={{ __html: successButtonText }} />
        </Button>
      </div>
    );
  }

  renderFail() {
    const { title, errorTitle, errorText, errorButtonText } = this.props.texts;
    return (
      <div className={cx('wrapper')}>
        <Heading className={cx('titleWrapper')} level={1} tagName="div">
          <span className={cx('title')} dangerouslySetInnerHTML={{ __html: errorTitle || title }} />
          <Emoji className={cx('emoji')} name="smile-sad" />
        </Heading>
        <div className={cx('description')} dangerouslySetInnerHTML={{ __html: errorText }} />

        <Button className={cx('button')} onClick={this.onClose}>
          <span dangerouslySetInnerHTML={{ __html: errorButtonText }} />
        </Button>
      </div>
    );
  }

  renderLoader() {
    return (
      <div className={cx('preloadWrapper')}>
        <Preloader size="100" />
      </div>
    );
  }

  render() {
    if (!this.props.texts) return null;

    const { currentPopup } = this.state;
    const { isOpen } = this.props;
    return (
      <PopupStateless onClose={this.onClose} opened={isOpen}>
        {currentPopup === currentPopupList.requestForm && this.renderRequestFrom()}
        {currentPopup === currentPopupList.success && this.renderSuccess()}
        {currentPopup === currentPopupList.fail && this.renderFail()}
        {currentPopup === currentPopupList.loading && this.renderLoader()}
      </PopupStateless>
    );
  }
}

PopupWithReCaptcha.propTypes = {
  texts: PropTypes.object.isRequired,
  showCaptcha: PropTypes.bool.isRequired,
  locale: PropTypes.string,
  currentCtn: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  additionalData: PropTypes.object,
  pushGA: PropTypes.func,
  submitUrl: PropTypes.string.isRequired,
  phoneFieldName: PropTypes.string.isRequired,
  reCaptchaModel: PropTypes.object,
  captchaToken: PropTypes.string,
  gaEventLabel: PropTypes.string,
};

PopupWithReCaptcha.defaultProps = {
  pushGA: () => {},
  onSuccess: () => {},
};

const ConnectedPopupWithReCaptcha = connect(({ external }) => ({
  locale: external.locale || '',
  currentCtn: external.currentCtn,
}))(PopupWithReCaptcha);

export default (props) => (
  <Provider store={store}>
    <CaptchaWithCallbackPopupHOC {...props}>
      <ConnectedPopupWithReCaptcha />
    </CaptchaWithCallbackPopupHOC>
  </Provider>
);
