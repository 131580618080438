import React, { FC } from 'react';
import { motion } from 'framer-motion';

interface AnimatedWrapperProps {
  children: React.ReactNode;
}

/** Компонент добавляет анимацию к своим дочерним элементам.
 *  Для работы требуется обернуть родителя в <AnimatePresence /> */
export const AnimatedWrapper: FC<AnimatedWrapperProps> = ({ children }) => {
  return (
    <motion.div
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      initial={{ height: 0, opacity: 0 }}
      transition={{ duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 }}
    >
      {children}
    </motion.div>
  );
};
