import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { useIsMobileDimension } from '@beef/ui-kit';

import { Anchor } from 'pages/CarnicaDSLandings/ui';

import { Metric } from '../../../../analytics';
import styles from './styles.pcss';
import { useHowItWorksBlock } from './hooks/useHowItWorksBlock';
import { Step } from './components/Step';

const cx = classNames.bind(styles);

/**
 * Блок "как работает перенос"
 */
export const HowItWorksBlock: FC = () => {
  const { anchorId, items, title } = useHowItWorksBlock();
  const isMobile = useIsMobileDimension();

  return (
    <Metric data={title} events={{ yaMNPMetric: ['handleViewHowItWorksBlock'] }} mode="viewport">
      <Anchor anchorId={anchorId} />

      <div className={cx('wrapper')}>
        <Text className={cx('title')} variant={isMobile ? 'display-small' : 'display-medium'}>
          {title}
        </Text>

        {items.map((step) => (
          <Step key={step.id} {...step} />
        ))}
      </div>
    </Metric>
  );
};
