import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getMobileOS } from 'utils/detect-mobile';
import { ymClickStore } from 'utils/analytics/ymCommonEvents';

import styles from './SocialButtons.pcss';

const cx = classNames.bind(styles);

class SocialButtons extends PureComponent {
  state = {
    device: null,
    rendered: false,
  };

  componentDidMount() {
    const device = this.props.device || getMobileOS();

    this.props.onMobileDetect(device);
    this.setState({
      device,
      rendered: true,
    });
  }

  onClick = (store) => {
    window.dataLayer.push({
      event: 'storeClick',
      store,
    });
    ymClickStore();

    if (this.props.callback) this.props.callback();
  };

  render() {
    if (!this.props.data) return null;

    const { device, rendered } = this.state;
    const { data, className, changeUrl } = this.props;
    const { huawei } = this.props.data;

    const href = (name) =>
      rendered && changeUrl ? changeUrl(name, data[name].link) : data[name].link;

    if (device) {
      return (
        <>
          <a
            className={cx('appButton', className)}
            href={href(device)}
            onClick={() => this.onClick(device)}
            rel="nofollow noopener noreferrer"
            style={{ backgroundImage: `url(${data[device].image})` }}
            target="_blank"
          />
          {device === 'android' && huawei && (
            <a
              className={cx('appButton', className)}
              href={huawei.link}
              onClick={() => this.onClick(device)}
              rel="nofollow noopener noreferrer"
              style={{ backgroundImage: `url(${huawei.image})` }}
              target="_blank"
            />
          )}
          {data.smartTv && (
            <a
              className={cx('appButton', className)}
              href={data.smartTv.link}
              onClick={() => this.onClick(device)}
              rel="nofollow noopener noreferrer"
              style={{ backgroundImage: `url(${data.smartTv.image})` }}
              target="_blank"
            />
          )}
        </>
      );
    }

    return Object.keys(data).map((key, i) => (
      <a
        className={cx('appButton', className)}
        href={href(key)}
        key={i}
        onClick={() => this.onClick(key)}
        rel="nofollow noopener noreferrer"
        style={{ backgroundImage: `url(${data[key].image})` }}
        target="_blank"
      />
    ));
  }
}

SocialButtons.defaultProps = {
  deviceType: ['android', 'ios'],
  onMobileDetect: () => {},
};

SocialButtons.propTypes = {
  callback: PropTypes.func,
  changeUrl: PropTypes.func,
  onMobileDetect: PropTypes.func,
  device: PropTypes.string,
  data: PropTypes.object,
  className: PropTypes.any,
};

export default SocialButtons;
