import { useCallback, useState } from 'react';
import { DropdownOptionInterface } from '@beef/ui-kit';

import { throttle } from 'utils/timed-functions';
import {
  useAppDispatch,
  useAppSelector,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';
import { getHouseSearchResults } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/api/fmcAddressCheck/getHouseSearchResults';
import {
  changeFlatInputState,
  setHouse,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/fttbAddressSlice';

/* Хук подготовки пропов input выбора региона */
export const useCheckHouse = () => {
  const dispatch = useAppDispatch();

  const streetId = useAppSelector((state) => state.fttbAddress.checkConnectionRes.streetId);
  const errorTextHelper = useAppSelector(
    (state) => state.common.content?.fmcSettings?.checkAddressPage.errorTextHelper,
  );

  /* Текст ошибки */
  const [errorMessage, setErrorMessage] = useState<string>();

  /* Значение input региона из стора fttbAddress */
  const value = useAppSelector((state) => state.fttbAddress.checkConnectionRes.house) || '';

  /* Обработка выбора региона из подсказки */
  const onSelect = useCallback(
    (house: DropdownOptionInterface) => {
      dispatch(setHouse({ house: house.label, houseId: Number(house.id) }));
      dispatch(changeFlatInputState(!house.isConnected));
      setErrorMessage(!house.isConnected ? errorTextHelper : undefined);
    },
    [value],
  );

  /* Очистка дома в сторе, если input редактируется пользователем */
  const onChange = useCallback(
    throttle(
      () => {
        dispatch(setHouse({ house: undefined, houseId: undefined }));
      },
      1000,
      {},
    ),
    [value],
  );

  const onSearch = useCallback((term: string) => getHouseSearchResults(streetId, term), [streetId]);

  const disabled = useAppSelector((state) => state.fttbAddress.isDisabledHouse);

  return { value, onSelect, onChange, onSearch, disabled, errorMessage };
};
