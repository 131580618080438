import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Selectors
import { getAddressText, getWarningSubTitle } from '../../../../store/selectors/modal';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const WarningInfoLayout = ({ subTitle, addressText }) => (
  <div className={cx('wrapper')}>
    {subTitle && <Text size="size4-m">{subTitle}</Text>}
    {addressText && <Text size="size5-r-s">{addressText}</Text>}
  </div>
);

const mapStateToProps = (state) => ({
  addressText: getAddressText(state),
  subTitle: getWarningSubTitle(state),
});

WarningInfoLayout.propTypes = {
  subTitle: PropTypes.string,
  addressText: PropTypes.string,
};

export const WarningInfo = connect(mapStateToProps)(WarningInfoLayout);
