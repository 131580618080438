import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { Bill } from '../Bill';
import { PromoContainer } from '../PromoContainer';
import styles from './styles.pcss';
import { FamilyContainer } from '../FamilyContainer';

const cx = classNames.bind(styles);

/** Обертка над чеком и промо-баннером */
export const BillContainer = memo(() => (
  <section className={cx('wrapper')}>
    <Bill />
    <FamilyContainer />
    <PromoContainer />
  </section>
));
