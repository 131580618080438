import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { TTotalSum, TotalSum } from './components/TotalSum';
import { LoadingTotalSum } from './components/LoadingTotalSum';
import { TotalDescription } from './components/TotalDescription';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TBillTotal = {
  description?: string;
  isLoadingPresets: boolean;
  isMobile: boolean;
  title?: string;
  totalSumProps: TTotalSum;
};

/** Итоговая информация по стоимости подключаемого тарифа */
export const BillTotal = memo<TBillTotal>(
  ({ title, isMobile, description, totalSumProps, isLoadingPresets }) => (
    <div className={cx('wrapper', { wrapper__loading: isLoadingPresets })}>
      {title && <Text size="size3-m">{title}</Text>}
      {!isLoadingPresets ?
        <TotalSum {...totalSumProps} />
      : <LoadingTotalSum />}
      {!isMobile && <TotalDescription {...{ description }} />}
    </div>
  ),
);
