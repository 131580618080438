import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { AppBanner } from '@beef/smart-kit';

import { AppBannerWrapperType } from 'pages/SPN/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const AppBannerWrapper: FC<AppBannerWrapperType> = ({
  ageCategory,
  qrCode,
  img,
  appButtons,
  text,
  title,
}) => (
  <div className={cn('wrapper')} data-t-id="AppBanner">
    <AppBanner
      ageCategory={ageCategory}
      appButtons={appButtons}
      img={img}
      qrCode={qrCode}
      text={text}
      title={title}
    />
  </div>
);
