import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, Heading, SmartCheckbox, SmartForm, SmartInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { emailForEsim as validateEmail } from 'utils/validators';
import { poll } from 'utils/timed-functions';
import SigningComponent from 'pages/Services/ConvertToEsimService/component/SigningComponent';
import Loading from 'pages/Services/ConvertToEsimService/component/Loading';
import { POLLING_STATUS, STEPS_CONFIG } from 'utils/ConvertToEsimServiceHelper';
import RequestStatus from 'pages-components/RequestStatus';
import { ymPushParams } from 'utils/ym';
import { YM_ESIM_PARAMS } from 'utils/analytics/zamenaNaEsim';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const validator = {
  email: validateEmail,
  checkboxWarning: (value) => ({ status: value ? 'ok' : 'typo' }),
};

const FIELD_ALIAS_CONFIG = {
  docIssueDate: 'docIssueDate',
  docIssuer: 'docIssuer',
  docNo: 'docNo',
  fullName: 'fullName',
};

class SimConvertStatement extends PureComponent {
  state = {
    email: '',
    emptySignatureField: true,
    error: false,
    errorMobileId: false,
    pending: true,
    pendingMobileId: false,
    polling: this.props.data.polling,
    signature: '',
    statementData: { ...this.props.data.simConvertStatement },
  };

  componentDidMount() {
    const { statementData } = this.state;

    axios
      .get('/convert-to-esim/form')
      .then((response) => {
        if (response?.data.isSucceeded && response.data) {
          this.setState({
            statementData: {
              ...statementData,
              ...response.data.personalData,
              formName: statementData.formName.map((item) => ({
                ...item,
                value: response?.data.personalData[FIELD_ALIAS_CONFIG[item.alias]],
              })),
            },
          });
          ymPushParams(YM_ESIM_PARAMS.SUCCESS_BY_ESIA);
          this.setState({ pending: false });
        } else {
          this.setState({ error: true });
        }
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  clientId = `UWEB;${window.navigator.userAgent}`;

  handleInput = (val, name) => {
    this.setState({
      [name]: val,
    });
  };

  identityStatusPolling = ({ signature, email, clientId, requestId }) => {
    poll(
      () =>
        axios
          .post('/convert-to-esim/', {
            signature,
            email,
            clientId,
            requestId,
          })
          .then(({ data }) => {
            if (
              data.mobileIdRequestStatus === POLLING_STATUS.error &&
              !data.isEsimRegistrationSucceeded
            ) {
              this.setState({ error: true });
              throw new Error('wrong mobileIdRequestStatus');
            }

            if (data.mobileIdRequestStatus === POLLING_STATUS.success && data.token !== '') {
              this.props.setEmail(this.state.email);
              this.props.setStep(STEPS_CONFIG.success);
              return POLLING_STATUS.success;
            }
            return data.token !== '' ? data.mobileIdRequestStatus : POLLING_STATUS.progress;
          })
          .catch((error) => {
            /**
             * В iOS при сворачивании вебвью происходит разрыв соединения,
             * поэтому, если в qp хранится код ошибки, продолжаем поллинг
             */
            if (this.props.data?.pollingNetworkErrors?.some((el) => el === error.code)) {
              return POLLING_STATUS.progress;
            }
            this.setState({ error: true });
            return POLLING_STATUS.error;
          }),
      (mobileIdRequestStatus) => mobileIdRequestStatus === POLLING_STATUS.progress,
      this.state.polling.interval,
      this.state.polling.timeout,
    )
      .catch(() => this.setState({ error: true }))
      .finally(() => this.setState({ pendingMobileId: false }));
  };

  onSubmit = () => {
    if (!this.signingComponent.state.showCrossButton) {
      this.setState({ emptySignatureField: false });
      setTimeout(() => this.setState({ emptySignatureField: true }), 3000);
      return;
    }

    this.setState({
      pendingMobileId: true,
    });

    this.setState({
      signature: this.signingComponent.getImage(),
    });

    ymPushParams(YM_ESIM_PARAMS.CONFIRM_BY_SIGNATURE);

    axios
      .post('/convert-to-esim/authMobileId/', {
        clientId: this.clientId,
      })
      .then(({ data }) => {
        if (data.isSucceeded) {
          this.setState({ errorMobileId: false });
          this.identityStatusPolling({
            signature: this.state.signature,
            clientId: this.clientId,
            email: this.state.email,
            requestId: data.requestId,
          });
        } else {
          this.setState({ errorMobileId: true });
        }
      })
      .catch((err) => {
        console.error('authMobileId: ', err);
      });
  };

  render() {
    const {
      title,
      formTitle,
      dataTitle,
      buttonText,
      formMailText,
      checkboxText,
      formName,
      warningText,
      signatureTitle,
      descriptionSigning,
      singingDesc,
    } = this.state.statementData;

    const accountText = this.props.currentCtn?.accountText || '';

    const loadingMobileIdText = {
      ...this.props.data.loadingMobileId,
      description: this.props.data.loadingMobileId.description.replace(
        // eslint-disable-next-line no-template-curly-in-string
        '${accountText}',
        accountText,
      ),
    };

    return !(this.state.error || this.state.errorMobileId) ?
        <>
          {this.state.pending || this.state.pendingMobileId ?
            <Loading
              {...(this.state.pendingMobileId ?
                { ...loadingMobileIdText }
              : { description: this.props.data.loading.description })}
            />
          : <div className={cx('component')}>
              <Heading className={cx('title')} level={1}>
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Heading>

              <Heading className={cx('subTitle')} level={2}>
                <span dangerouslySetInnerHTML={{ __html: dataTitle }} />
              </Heading>

              <table>
                <tbody className={cx('userData')}>
                  {formName.map((elem) => (
                    <tr className={cx('columnWrap')} key={elem.alias}>
                      <td className={cx('column', 'columnLeft', 'description')}>{elem.field}</td>
                      <td className={cx('column', 'columnRight')}>{elem.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Heading className={cx('subTitle')} level={2}>
                <span dangerouslySetInnerHTML={{ __html: formTitle }} />
              </Heading>

              <SmartForm
                autoComplete="off"
                className={cx('contactsForm')}
                disableIfInvalid
                inline
                onSubmit={this.onSubmit}
                validator={validator}
              >
                <div className={cx('input', 'contactEmail')}>
                  <span dangerouslySetInnerHTML={{ __html: formMailText }} />
                  <SmartInput
                    inputId="email"
                    name="email"
                    onChange={(val) => {
                      this.handleInput(val, 'email');
                    }}
                    size="big"
                    value={this.state.email}
                  />
                </div>
                <div className={cx('checkbox')}>
                  <SmartCheckbox checked inputId="checkboxWarning" name="warning">
                    {checkboxText}
                    <div
                      className={cx('description', 'warningText')}
                      dangerouslySetInnerHTML={{ __html: warningText }}
                    />
                  </SmartCheckbox>
                </div>

                <div className={cx('signature')}>
                  <Heading className={cx('subTitle')} level={2}>
                    <span dangerouslySetInnerHTML={{ __html: signatureTitle }} />
                  </Heading>
                  <span
                    className={cx('description', 'singingDesc')}
                    dangerouslySetInnerHTML={{ __html: singingDesc }}
                  />
                  <SigningComponent
                    descriptionSigning={descriptionSigning}
                    ref={(e) => {
                      this.signingComponent = e;
                    }}
                  />
                  {this.state.emptySignatureField ?
                    ''
                  : <span className={cx('signatureError')}>Заполните это поле</span>}
                </div>

                <div className={cx('button')}>
                  <Button className="big" onClick={this.props.onClick} transformer>
                    {buttonText}
                  </Button>
                </div>
              </SmartForm>
            </div>
          }
        </>
      : <RequestStatus
          data={
            this.state.errorMobileId ? this.props.data.mobileIdError : this.props.data.defaultError
          }
          onClick={this.props.onClose}
        />;
  }
}

SimConvertStatement.defaultProps = {
  onClose: () => {},
  onClick: () => {},
};

SimConvertStatement.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  setEmail: PropTypes.func,
  setStep: PropTypes.func,
  currentCtn: PropTypes.shape({
    login: PropTypes.string,
    isMobile: PropTypes.bool,
    ctn: PropTypes.string,
    isConvergent: PropTypes.bool,
    isXbr: PropTypes.bool,
    xbrProfile: PropTypes.string,
    fttbNumber: PropTypes.string,
    accountText: PropTypes.string,
  }),

  RequestSuccessContent: PropTypes.shape({
    descriptionSigning: PropTypes.string,
    qrCode: PropTypes.string,
    title: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    emailText: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  currentCtn: state.external?.currentCtn || state.external?.user,
});

export const SimConvertStatementWrapped = connect(mapStateToProps)(SimConvertStatement);
