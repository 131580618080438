import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { useAppSelector } from 'constructorV1/store';
import { BillCommon } from 'constructorV1/components/commonComponents/BillCommon';
import { selectCBillPageTitle } from 'constructorV1/store/selectors/selectCommon';
import { useStartTotalRequest } from 'constructorV1/components/commonComponents/BillCommon/hooks/useStartTotalRequest';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Контейнер для компонентов результирующего чека ЕК */
export const BillCommonContainer: FC = () => {
  /* Заголовок страницы */
  const pageTitle = useAppSelector(selectCBillPageTitle);

  /* Старт запроса на формирование результирующего чека */
  useStartTotalRequest();

  return (
    <section className={cx('wrapper')}>
      {pageTitle && (
        <Text align="center" color="primary" lineHeight={52} size="xl" weight="regular">
          {pageTitle}
        </Text>
      )}
      <BillCommon />
    </section>
  );
};
