import React from 'react';
import { FontColorType, FontSizeType, Text } from '@beef/ui-kit';

type TDescriptionProps = {
  color: FontColorType;
  isBoldSection?: boolean;
  size: FontSizeType;
  value: string;
};

export const Description: React.FC<TDescriptionProps> = ({ isBoldSection, size, color, value }) => {
  const props = {
    size,
    color,
    ...(isBoldSection && { size: 'size4-m' as FontSizeType, color: 'grey95' as FontColorType }),
  };

  return (
    <Text color={props.color} size={props.size}>
      {value}
    </Text>
  );
};
