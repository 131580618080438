import { ActionBar, Modal } from '@beef/ui-kit';
import React from 'react';

import { selectPopupProps } from '../../store/selectors';
import { IPartnerServiceCardState } from '../../types';
import { ModalButtons } from './components/ModalButtons';
import { PartnerDetails } from './components/PartnerDetails';

export const PartnerPopup: React.FC<
  ReturnType<typeof selectPopupProps> &
    Partial<IPartnerServiceCardState['modal']> & {
      onCancel: () => void;
      onConfirm: () => void;
    }
> = (props) => {
  const { content, isModalOpen = false, onCancel, onConfirm, isLoading = false } = props;

  return (
    <Modal
      actionBar={
        <ActionBar isNoPadding size="s">
          <ModalButtons
            isLoading={isLoading}
            mainBtnText={content.button?.confirm}
            onCancel={onCancel}
            onConfirm={onConfirm}
            secondBtnText={content.button?.cancel || undefined}
          />
        </ActionBar>
      }
      isOpen={isModalOpen}
      onClose={() => onCancel()}
      size="m"
    >
      <PartnerDetails
        modalDescription={content.description}
        modalImg={content.imageSrc}
        modalTitle={content.title}
      />
    </Modal>
  );
};
