import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Tab, TabsList } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Табы для переключения на другие тарифы из семейства, являются ссылками;
 * -----------------------------------------------------------------------
 * @param familyNavigation - Список табов cемейства тарифов;
 * @param className - Дополнительный класс;
 */
const FamilyNavigation = ({ familyNavigation, className }) => (
  <div className={className}>
    <TabsList autoScroll className={cx('familyTabList')}>
      {familyNavigation.map((item, key) => (
        <Tab active={item.active} className={cx('familyTab')} href={item.href} key={key}>
          <div>
            {item.isConnected && <Icon className={cx('icon')} name="tariffCheck" />}
            <span className={cx('label')} dangerouslySetInnerHTML={{ __html: item.label }} />
          </div>
        </Tab>
      ))}
    </TabsList>
  </div>
);

FamilyNavigation.defaultProps = {
  familyNavigation: [],
};

FamilyNavigation.propTypes = {
  familyNavigation: PropTypes.array,
  className: PropTypes.string,
};

export default FamilyNavigation;
