import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { ChevronLeftIcon, Text } from '@beef/ui-kit';
import PropTypes from 'prop-types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CategoryLink = ({
  name,
  value,
  onClick,
  isOpen,
  withChildren,
  isSelectedCategory,
  withIcon,
}) => {
  const textSize = useMemo(() => (isOpen ? 'size5-m' : 'size6-r'), [isOpen]);

  return (
    <button
      className={cx(
        'button',
        withChildren && 'button_children',
        isSelectedCategory && 'button_open',
        withIcon && 'with_icon',
      )}
      onClick={onClick}
      type="submit"
    >
      {withIcon && <ChevronLeftIcon size={20} />}
      <Text color="inherit" size={textSize}>
        {name}
      </Text>
      {value && (
        <Text color="grey60" size="size6-r">
          {value}
        </Text>
      )}
    </button>
  );
};

CategoryLink.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  withChildren: PropTypes.bool,
  isSelectedCategory: PropTypes.bool,
  withIcon: PropTypes.bool,
};
