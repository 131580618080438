import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { Button } from '@beef/legacy-ui-kit';

import {
  initConvertToEsimServiceAuth,
  toggleServiceConnectionPopup,
} from 'pages/ProductCard/actions/serviceConnect';
import { STEPS_CONFIG, getCurrentStep, getErrorDetails } from 'utils/ConvertToEsimServiceHelper';
import RequestSteps from 'pages/Services/ConvertToEsimService/component/RequestSteps';
import { getWebView } from 'utils/detect-mobile';
import { ymPushParams } from 'utils/ym';
import { YM_ESIM_PARAMS } from 'utils/analytics/zamenaNaEsim';
import { redirectToLogin } from 'utils/redirectToLogin';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ConvertToEsimService = ({ data, openAuthPopup }) => {
  const { content } = data?.extData;
  const { ftRedirectLoginEnabled } = content;
  const { isAuthenticated } = data;
  const [popupOpen, setPopupOpen] = useState(false);
  const [errorData, setErrorData] = useState(content?.defaultError || {});
  const [textWebview, setTextWebView] = useState(null);

  const onButtonClick = () => {
    ymPushParams(YM_ESIM_PARAMS.GET_ESIM_START);

    // переход на новую форму логина /login
    if (!isAuthenticated && ftRedirectLoginEnabled) {
      return redirectToLogin({ connect: true });
    }

    if (isAuthenticated) {
      setPopupOpen(true);
    } else {
      openAuthPopup(content?.restoreAccessForm);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const step = getCurrentStep(content);

      setTextWebView(getWebView());

      const urlParams = new URLSearchParams(window.location.search);
      const redirectedFromAuth = urlParams.get('connect');
      setPopupOpen(
        isAuthenticated &&
          (step === STEPS_CONFIG.error ||
            step === STEPS_CONFIG.requestForm ||
            redirectedFromAuth === 'true'),
      );

      if (step === STEPS_CONFIG.error) {
        setErrorData(content?.[getErrorDetails(content)]);
      }
    }
  }, []);

  return (
    <div className={cx('wrap')}>
      {content && (
        <>
          {content?.preview?.text && (
            <div className={cx('contentRow')}>
              <div className={cx('text')}>{content?.preview?.text}</div>
            </div>
          )}
          {content?.preview?.buttonText && (
            <div className={cx('contentRow')}>
              <Button className="big" onClick={onButtonClick} wide>
                {content.preview.buttonText}
              </Button>
            </div>
          )}
          {content?.preview?.note && <div className={cx('note')}>{content?.preview?.note}</div>}
        </>
      )}
      {isAuthenticated && (
        <RequestSteps
          errorData={errorData}
          extData={content}
          isWebView={content?.isWebView}
          onClose={() => setPopupOpen(false)}
          opened={popupOpen}
        />
      )}
      {content?.isWebView && (
        <p>
          webView is:
          {textWebview}
        </p>
      )}
    </div>
  );
};

ConvertToEsimService.propTypes = {
  data: PropTypes.object,
  openAuthPopup: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  openAuthPopup: (extraData) => {
    dispatch(initConvertToEsimServiceAuth('/authorizationpopup/', extraData));
    dispatch(toggleServiceConnectionPopup(true));
  },
});

export default connect(null, mapDispatchToProps)(ConvertToEsimService);
