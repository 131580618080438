import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { pathOr } from 'ramda';
import qs from 'query-string';
import { Button, Link, Status } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';
import documentReady from 'utils/document-ready';
import { changeLimitConfirmation } from 'pages/ProductCard/actions/alwaysInPositive';
import { pushShowServiceConnectionPopupEvent } from 'utils/ga';
import { templateParser } from 'utils/format-string';
import { getRegionSearchParams } from 'pages/ProductCard/utils';

import ServiceDisconnectPopup from '../ServiceDisconnectPopup/ServiceDisconnectPopup';
import ServiceConnectPopup from '../ServiceConnectPopup/ServiceConnectPopup';
import {
  disconnectService,
  initDisconnectionPopup,
  initServiceStore,
  showServiceConnectionPopup,
  showServiceDisconnectionPopup,
} from '../../actions/serviceConnect';
import styles from './AlwaysInPositiveButton.pcss';

const cx = classNames.bind(styles);

class AlwaysInPositiveButton extends Component {
  componentDidMount() {
    const { availableForConnect, isConnected, isConnectedLater } = this.props.data;
    const { previousRegion, currentRegion } = getRegionSearchParams();
    const isWrongRegion = previousRegion && previousRegion !== currentRegion;

    if (this.props.onInit) {
      this.props.onInit(this.props.data);
    }

    if (
      availableForConnect &&
      !isConnected &&
      !isConnectedLater &&
      qs.parse(window.location.search).connect === 'true' &&
      !isWrongRegion
    ) {
      documentReady(() => this.handleConnectClick());
    }
  }

  handleConnectClick = () => {
    const { isAuthenticated, pageTitle, soc, marketCode } = this.props.data;

    this.props.onClick({
      isAuthenticated,
      serviceName: pageTitle,
      soc,
      marketCode,
    });
  };

  handleDisconnectClick = () => {
    const { isConnectedLater, pageTitle, soc, marketCode } = this.props.data;

    this.props.onDisconnect({
      isConnectedLater,
      serviceName: pageTitle,
      soc,
      marketCode,
    });
  };

  render() {
    const {
      connectingInProgress,
      isLimitChanged,
      changedMessage,
      returnMessage,
      connectButton,
      amount,
      data: {
        availableForConnect,
        isArchived,
        isConnected,
        isConnectedLater,
        extData,
        warningText,
        canBeRemoved,
        connectedText,
        archiveText,
      },
    } = this.props;

    const connectingMessage = pathOr(
      '',
      ['personalCreditLimit', 'connectingInProgressMessage'],
      extData,
    );

    return (
      <div>
        {warningText ?
          <div className={cx('connectButtons')}>
            <Status className={['attention']} emoji="statusFail" text={warningText} />
          </div>
        : null}

        {isConnected || isConnectedLater ?
          <div className={cx('connectButtons')}>
            {canBeRemoved ?
              <div className={cx('button')}>
                <Status className={['success']}>
                  <span className={cx('connectionStatusContent')}>
                    <span className={cx('connectionStatusText')}>{connectedText}</span>
                    <span className={cx('connectionStatusText')}>
                      <Link className="light" onClick={this.props.onDisconnect}>
                        {isConnected && !isConnectedLater ? 'Отключить' : null}
                        {isConnectedLater ? 'Отменить подключение' : null}
                      </Link>
                    </span>
                  </span>
                </Status>
              </div>
            : <div className={cx('button')}>
                <Status className={['success']} text={connectedText} />
              </div>
            }
          </div>
        : null}

        {connectingInProgress && (
          <div className={cx('connectButtons')}>
            <div className={cx('button')}>
              <Status className={cx('inProcess')}>
                <span className={cx('connectionStatusContent')}>
                  <span className={cx('connectionStatusText')}>{connectingMessage}</span>
                  <span className={cx('connectionStatusText')}>
                    <Link className="light" onClick={this.props.onDisconnect}>
                      Отключить
                    </Link>
                  </span>
                </span>
              </Status>
            </div>
          </div>
        )}

        {connectButton && availableForConnect && !isConnectedLater && (!isArchived || isConnected) ?
          <div>
            {isLimitChanged && changedMessage && (isConnected || connectingInProgress) ?
              <div className={cx('connectButtons')}>
                <div className={cx('button')}>
                  <Status className="attention" emoji="pointingHandUp">
                    <span className={cx('message')}>
                      {templateParser(changedMessage, { value: amount })}
                    </span>
                  </Status>
                </div>
              </div>
            : null}

            {!isLimitChanged && returnMessage ?
              <div className={cx('connectButtons')}>
                <div className={cx('button')}>
                  <Status className="attention" emoji="pointingHandUp">
                    <span className={cx('message')}>{returnMessage}</span>
                  </Status>
                </div>
              </div>
            : null}

            {(isLimitChanged || (!isConnected && !connectingInProgress)) && (
              <div className={cx('connectButtons')}>
                <div className={cx('button')}>
                  <Button className="big" onClick={this.handleConnectClick} wide>
                    {connectButton}
                  </Button>
                </div>
              </div>
            )}
          </div>
        : null}
        {isArchived ?
          <div className={cx('connectButtons')}>
            <Status className={['centeredText']} text={archiveText} />
          </div>
        : null}
        <ServiceConnectPopup />
        <ServiceDisconnectPopup onConfirm={this.props.onDisconnectConfirm} />
      </div>
    );
  }
}

AlwaysInPositiveButton.propTypes = {
  onInit: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object,
  connectingInProgress: PropTypes.bool,
  isLimitChanged: PropTypes.bool,
  changedMessage: PropTypes.string,
  returnMessage: PropTypes.string,
  connectButton: PropTypes.string,
  onDisconnect: PropTypes.func,
  onDisconnectConfirm: PropTypes.func,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state, props) => {
  const { data } = props;
  const connectingInProgress = pathOr(
    false,
    ['extData', 'personalCreditLimit', 'isConnectingInProgress'],
    data,
  );
  const isLimitChanged = pathOr(false, ['external', 'alwaysInPositive', 'isChanged'], state);
  const amount = pathOr(0, ['external', 'alwaysInPositive', 'amount'], state);
  const changedMessage = pathOr(
    '',
    ['extData', 'personalCreditLimit', 'creditAmount', 'changedMessage'],
    data,
  );
  const returnMessage = pathOr(
    '',
    ['extData', 'personalCreditLimit', 'deposit', 'returnMessage'],
    data,
  );
  const connectButton =
    !props.data.isAuthenticated || !isLimitChanged ?
      data.connectButton
    : pathOr(
        data.connectButton,
        ['extData', 'personalCreditLimit', 'creditAmount', 'saveButtonText'],
        data,
      );

  return {
    connectingInProgress,
    isLimitChanged,
    changedMessage,
    connectButton,
    returnMessage,
    amount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClick: (params) => {
    if (!params.isAuthenticated) {
      dispatch(showServiceConnectionPopup(params));
    } else {
      dispatch(changeLimitConfirmation());
    }

    pushShowServiceConnectionPopupEvent(params);
  },
  onInit: (data) => {
    dispatch(initServiceStore(data));
    dispatch(initDisconnectionPopup(data.serviceDisconnectionPopup));
  },
  onDisconnect: (params) => dispatch(showServiceDisconnectionPopup(params)),
  onDisconnectConfirm: (disconnectDate, url, soc, serviceName) =>
    dispatch(
      disconnectService(
        new Date(
          Date.UTC(
            disconnectDate.getFullYear(),
            disconnectDate.getMonth(),
            disconnectDate.getDate(),
          ),
        ),
        url,
        soc,
        serviceName,
        { isUpsell: true },
      ),
    ),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(AlwaysInPositiveButton);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
