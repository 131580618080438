import React, { useContext } from 'react';

import { TUseMapStateToProps } from './types';

export function withContext<
  T extends Record<string, unknown>,
  P extends React.PropsWithChildren<Record<string, unknown>>,
>({
  context,
  useMapStateToProps,
}: {
  context: React.Context<T>;
  useMapStateToProps: TUseMapStateToProps<T, P>;
}) {
  return (WrappedComponent: React.FC<P>) => (props: P) => {
    const state = useContext<T>(context);
    const mappedProps: P = useMapStateToProps({ state, ownProps: props });

    return <WrappedComponent {...mappedProps} />;
  };
}
