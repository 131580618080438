import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Emoji, Heading, Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { templateParser } from 'utils/format-string';
import { ymAuthBySMSorPassForm, ymChangeTariffSuccess } from 'utils/analytics/ymCommonEvents';
import SmsForm from 'widgets/Authorization/SmsLoginForm/SmsForm';

import styles from './ConfirmNumberPopup.pcss';

const cx = classNames.bind(styles);

export class ConfirmationNumberPopup extends PureComponent {
  state = {
    isAutoServiceConnectABtest: false,
  };

  componentDidMount() {
    const { service } = this.props;

    if (service && service.autoServiceConnectABtest) {
      this.setState({
        isAutoServiceConnectABtest: service.autoServiceConnectABtest,
      });
    }
  }

  handleSubmit() {
    ymChangeTariffSuccess();
    ymAuthBySMSorPassForm();
  }

  render() {
    const {
      isPending,
      title,
      content,
      phone,
      postUrl,
      clientId,
      returnUrl,
      state,
      nonce,
      redirectUrl,
      confirmButtonText,
      titleIcon,
      sendSmsAgain,
      sendSmsAgainText,
    } = this.props;

    return (
      <div>
        {isPending ?
          <div className={cx('loader')}>
            <Preloader />
          </div>
        : <div className={cx('wrapper')}>
            <Heading level={1}>
              <span dangerouslySetInnerHTML={{ __html: title }} />
              <Emoji className="inHeading" path={titleIcon} />
            </Heading>
            <div
              className={cx('description')}
              dangerouslySetInnerHTML={{ __html: templateParser(content, { number: phone }) }}
            />
            <SmsForm
              autoServiceConnectABtest={this.state.isAutoServiceConnectABtest}
              clientId={clientId}
              confirmButtonText={confirmButtonText}
              hidePhone
              nonce={nonce}
              onSubmit={this.handleSubmit}
              phone={phone}
              postUrl={postUrl}
              redirectUrl={redirectUrl}
              returnUrl={returnUrl}
              sendSmsAgain={sendSmsAgain}
              sendSmsAgainText={sendSmsAgainText}
              state={state}
            />
          </div>
        }
      </div>
    );
  }
}

ConfirmationNumberPopup.defaultProps = {
  isPending: false,
};

ConfirmationNumberPopup.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  phone: PropTypes.string,
  postUrl: PropTypes.string,
  clientId: PropTypes.string,
  returnUrl: PropTypes.string,
  state: PropTypes.string,
  nonce: PropTypes.string,
  redirectUrl: PropTypes.string,
  confirmButtonText: PropTypes.string,
  isPending: PropTypes.bool,
  titleIcon: PropTypes.string,
  sendSmsAgain: PropTypes.string,
  sendSmsAgainText: PropTypes.string,
  service: PropTypes.object,
};

const mapStateToProps = ({ external }) => ({
  ...external.confirmNumberPopup,
  service: external.service,
});

export default connect(mapStateToProps)(ConfirmationNumberPopup);
