import React, { FC } from 'react';
import { TeaserBanner } from '@beef/smart-kit';
import classNames from 'classnames/bind';
import { Notifications } from '@beef/ui-kit';
import { connect } from 'react-redux';

import { BannerProps } from 'pages/Services/RedirectServiceCardLanding/components/Banner/types';
import { ModalCancel } from 'pages/Services/RedirectServiceCardLanding/components/ModalCancel';
import { ModalSettings } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings';
import { useBanner } from 'pages/Services/RedirectServiceCardLanding/components/Banner/hook';
import { RedirectStore } from 'pages/Services/RedirectServiceCardLanding/store';
import {
  selectAuthenticated,
  selectLoadingInitialData,
  selectRedirectQPContent,
} from 'pages/Services/RedirectServiceCardLanding/store/redirect/selectors';

import { Detail } from './Detail';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const _Banner: FC<BannerProps> = (props) => {
  const { loadingInitialData, contentNotification, isAuthenticated } = props;
  const {
    isVisibleModal,
    isVisibleCancelModal,
    onCloseCancelModal,
    onOpenCancelModal,
    onOpenModal,
    onCloseModal,
    onCloseNotifications,
    notifications,
  } = useBanner({
    loadingInitialData,
    contentNotification,
    isAuthenticated,
  });

  return (
    <div className={cx('wrapper')}>
      {notifications && notifications?.length > 0 && (
        <Notifications
          duration={(contentNotification?.duration || 6) * 1000}
          isWithCloseButton={contentNotification?.isWithCloseButton || true}
          notifications={notifications}
          onClick={onCloseNotifications}
          variant={contentNotification?.variant || 'primary'}
        />
      )}
      <TeaserBanner
        {...props}
        detailsNode={
          <Detail {...props} onOpenCancelModal={onOpenCancelModal} onOpenModal={onOpenModal} />
        }
      />
      {isVisibleModal && <ModalSettings onCloseModal={onCloseModal} />}
      {isVisibleCancelModal && <ModalCancel onCloseModal={onCloseCancelModal} />}
    </div>
  );
};

const mapStateToProps = (state: RedirectStore) => ({
  loadingInitialData: selectLoadingInitialData(state),
  isAuthenticated: selectAuthenticated(state),
  contentNotification: selectRedirectQPContent(state)?.pereadresatsiya?.notification,
});

export const Banner = connect(mapStateToProps)(_Banner);
