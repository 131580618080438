import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Provider, connect } from 'react-redux';
import { prepareLoginStore } from '@beef/layout-kit/store';
import classNames from 'classnames/bind';

import store from 'store';
import { LoginPropsType } from 'pages/Login/types';

import { AUTH_STEPS } from './Authorization2021/actions/constants';
import { onSwitchForm } from './Authorization2021/actions/authorization';
import mock from './Authorization2021/data/mock';
import { HeaderLoginForms } from './Authorization2021';
import styles from './styles.pcss';
import { CSS_HEIGHT_VAR, getButtonText, getFormId } from './utils';
import { BackLink, Error, FormContainer, FormHeader, SubmitButtons } from './components';
import { useMobileAvailableHeight } from './hooks/useMobileAvailableHeight';

const cx = classNames.bind(styles);

const LoginComponent = ({
  withMobileId,
  loginModel,
  checkIsBeeline,
  loading,
  cookieDomain,
  step,
  isFocusedInput,
  ...props
}: LoginPropsType) => {
  const [stepSmsPassword, setStepSmsPassword] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const wrapRef = useRef(null);

  useMobileAvailableHeight(wrapRef, CSS_HEIGHT_VAR);

  useEffect(() => {
    prepareLoginStore(props.data.loginModel);
  }, []);

  const shouldRenderButton = useMemo(
    () =>
      (step !== AUTH_STEPS.mobileId && stepSmsPassword !== 'error') ||
      (stepSmsPassword !== 'error' && step === AUTH_STEPS.smsCode),
    [step, stepSmsPassword],
  );
  const buttonText = getButtonText(step, mock.texts, stepSmsPassword);
  const formId = getFormId(step, stepSmsPassword);

  return (
    <div className={cx('wrap')} ref={wrapRef}>
      <BackLink step={step} />
      <div className={cx('form-wrap')}>
        <FormContainer className={cx('form')}>
          {loginModel.loginFailed ?
            <Error step={step} />
          : <>
              <FormHeader />
              <div className={cx('form-content')}>
                <HeaderLoginForms
                  checkIsBeeline={checkIsBeeline}
                  cookieDomain={cookieDomain}
                  handleChangeSmsStep={setStepSmsPassword}
                  handleInput={setInputValue}
                  loginModel={loginModel}
                  mobile={false}
                  withMobileId={props.data.withMobileId}
                />
                {shouldRenderButton && (
                  <div className={cx('form-submit')}>
                    <SubmitButtons
                      buttonText={buttonText}
                      disabled={step === AUTH_STEPS.smsCode && stepSmsPassword === 'loading'}
                      formId={formId}
                      isActiveButton={[AUTH_STEPS.smsCaptcha, AUTH_STEPS.mobileIdCaptcha].includes(
                        step,
                      )}
                      loading={loading}
                      step={step}
                      value={inputValue}
                    />
                  </div>
                )}
              </div>
            </>
          }
        </FormContainer>
      </div>
    </div>
  );
};

const mapStateToProps = ({ external }: { external: Record<string, any> }) => {
  const pendingCaptcha = external?.authorization?.pendingCaptcha ?? false;
  const globalPending = external?.loginModel?.loading ?? true;

  return {
    step: external?.authorization?.authStep,
    loading: globalPending || pendingCaptcha,
    loginModel: external?.loginModel ?? {},
    mobileIdData: external?.mobileIdData?.content ?? null,
  };
};

const mapDispatchToProps = {
  switchForm: onSwitchForm,
};

const LoginComponentConnected = connect(mapStateToProps, mapDispatchToProps)(LoginComponent);

const Login = (props: LoginPropsType) => (
  <Provider store={store}>
    <LoginComponentConnected {...props} />
  </Provider>
);

export default Login;
