import { useEffect, useState } from 'react';

import store from 'store';
import { IContent } from 'pages/SPN/types';
import { setLottieAnimation } from 'pages/SPN/api';

import { setInitDataSpn } from './store/actions';
import { selectContent, selectLoaderAnimationName } from './store/actions/selectors';

export const useInitializeSPN = (data: any): IContent => {
  const [mainContent, setContent] = useState<IContent>({});
  const { dispatch, getState } = store;

  useEffect(() => {
    dispatch(setInitDataSpn(data));

    const animationName = selectLoaderAnimationName(getState());
    const content = selectContent(data) || {};

    setContent(content);
    dispatch(setLottieAnimation(animationName));
  }, []);

  const { appBanner, benefits, extendedSteps, features, teaserBanner, about, faq } = mainContent;

  return { appBanner, benefits, extendedSteps, features, teaserBanner, about, faq };
};
