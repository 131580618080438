import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Emoji, Heading } from '@beef/legacy-ui-kit';

// Styles
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Компонент выводит контент в модальное окно Результата пулинга КонтрОфера;
 * ------------------------------------------------------------------------
 * @param closePopup - callback, закрывает модальное окно;
 * @param isSucceeded - Статус окончания операции;
 * @param success - Объект содержит данные успешного завершения операции;
 * @param error - Объект содержит данные провала операции;
 */
const CounterOfferResultPopup = ({ closePopup, isSucceeded, success, error }) => {
  const objectType = isSucceeded ? success : error;

  return (
    <div className={cx('counteroffer')}>
      <Heading level={1}>
        <span dangerouslySetInnerHTML={{ __html: objectType.title }} />
        {objectType.emoji && <Emoji className={cx('statusOkEmoji')} name="statusOk" />}
      </Heading>
      {objectType.description && <p dangerouslySetInnerHTML={{ __html: objectType.description }} />}
      {objectType.buttonText && (
        <Button className={cx('big')} onClick={closePopup}>
          {objectType.buttonText}
        </Button>
      )}
    </div>
  );
};

CounterOfferResultPopup.propTypes = {
  closePopup: PropTypes.func,
  isSucceeded: PropTypes.bool,
  success: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    emoji: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  error: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    emoji: PropTypes.string,
    buttonText: PropTypes.string,
  }),
};

export default CounterOfferResultPopup;
