import axios from 'axios';
import qs from 'query-string';

import { deleteCookie, getCookie } from 'utils/cookie';

const cookies = ['googlePayTransactionId', 'googlePayToken'];

export const googlePayTransactionCheck = () =>
  axios
    .post('/googlepay/gettransactioninfo', {
      id: getCookie(cookies[0]),
      token: getCookie(cookies[1]),
    })
    .then(({ data }) => {
      if (!data.isSuccess) {
        return Promise.reject(data);
      }

      cookies.map((item) => deleteCookie(item));

      const params = qs.parse(window.location.search);
      delete params.params;
      window.history.replaceState(
        {},
        document.title,
        `${window.location.origin}${window.location.pathname}?${qs.stringify(params)}`,
      );
    });
