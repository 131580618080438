import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { CarouselWithNavigation } from '@beef/smart-kit';

import { useConstructorSwiperSettings } from './hooks/useConstructorSwiperSettings';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TCarousel = {
  children: ReactNode;
};

/** Контейнер с настройками свайпера для карточек сервисов */
export const ConstructorSwiper: FC<TCarousel> = ({ children }) => {
  /** Готовим объект settings (настройки свайпера) для работы CarouselWithNavigation */
  const settings = useConstructorSwiperSettings(cx('swiper__bullet--active'));
  return (
    <section className={cx('wrapper')}>
      <CarouselWithNavigation
        className={cx('swiper')}
        nextBtnClassname={cx('swiper__btn', 'swiper__btn--next')}
        prevBtnClassname={cx('swiper__btn', 'swiper__btn--prev')}
        settings={settings}
      >
        {children}
      </CarouselWithNavigation>
    </section>
  );
};
