import React, { memo } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
// Components
import { Text } from '@beef/ui-kit';

import { SubscriptionPrice } from './SubcriptionPrice';
// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const Subscriptions = memo(({ subscriptions }) => (
  <div className={cn('block')}>
    <div className={cn('block__title')}>
      <Text size="size4-m">Подписки</Text>
    </div>
    <div className={cn('block__subscriptions')}>
      {subscriptions.map((subscription) => (
        <div className={cn('block__subscription')} key={subscription.id}>
          <Text size="size5-r-s">{subscription.name}</Text>
          <SubscriptionPrice
            hasTrialPeriod={subscription.hasTrial}
            paymentPeriod={subscription.paymentPeriod}
            price={subscription.price}
            statusText={subscription.subscriptionStatusText}
          />
        </div>
      ))}
    </div>
  </div>
));

Subscriptions.propTypes = {
  subscriptions: PropTypes.arrayOf({
    name: PropTypes.string,
    price: PropTypes.string,
    paymentPeriod: PropTypes.string,
    id: PropTypes.string,
    periodAlias: PropTypes.string,
    subscriptionStatusText: PropTypes.string,
    hasTrial: PropTypes.bool,
  }),
};

export { Subscriptions };
