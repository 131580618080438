import { useCallback, useEffect, useState } from 'react';

import { EStatus } from 'pages/MainPage2021/pageBlocks/PersonalInfoBlock/types';
import { redirectToLoginPage } from 'utils/auth';

import { TUseMapStateToProps } from '../../enhancers/withContext/types';
import useBannerButton from '../../hooks/useBannerButton';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import {
  selectBanner,
  selectIsAuthenticated,
  selectIsAvailableForNextConnection,
  selectIsAvailableToConnect,
  selectIsConnected,
  selectSetTrustPaymentData,
} from '../../selectors';
import { TZeroBalanceContext } from '../../types';
import { EModal } from '../ModalContainer/modals/types';
import { TBannerProps } from './types';
import { getModal, persistDisabled, retrieveIsDisabled } from './utils';

export const useMapStateToProps: TUseMapStateToProps<TZeroBalanceContext, TBannerProps> = ({
  state,
}) => {
  const isAuthenticated = selectIsAuthenticated(state);
  const setTrustPaymentData = selectSetTrustPaymentData(state);
  const banner = selectBanner(state);

  const [currentModal, setCurrentModal] = useState<EModal | null>(null);
  const [isTrustPaymentOpen, setIsTrustPaymentOpen] = useState(false);

  const userInfo = useGetUserInfo();
  const status = userInfo?.status?.status;
  const isConnected = selectIsConnected(state);
  const isAvailableToConnect = selectIsAvailableToConnect(state);
  const isAvailableForNextConnection = selectIsAvailableForNextConnection(state);
  const hasInsufficientFunds = status === EStatus.NotEnoughMoney;

  const { buttonType } = useBannerButton();
  const _isDisabled = (isAuthenticated && !userInfo) || retrieveIsDisabled();
  const [isDisabled, setIsDisabled] = useState(_isDisabled);

  const hasTooltip = isDisabled && hasInsufficientFunds;

  const modal = getModal({
    isConnected,
    isAvailableToConnect,
    isAvailableForNextConnection,
    status,
  });

  useEffect(() => {
    if (_isDisabled !== isDisabled) {
      setIsDisabled(_isDisabled);
    }
  }, [_isDisabled, isDisabled]);

  const onClick = useCallback(() => {
    if (!isAuthenticated) {
      redirectToLoginPage();
      return;
    }

    setCurrentModal(modal);
  }, [isAuthenticated, modal]);

  const _setCurrentModal = (_modal: EModal | null, shouldBeDisabled?: boolean) => {
    if (_modal === EModal.TrustPayment) {
      setIsDisabled(true);
      setCurrentModal(null);
      setIsTrustPaymentOpen(true);
      return;
    }

    setIsDisabled(!!shouldBeDisabled);
    setCurrentModal(_modal);

    if (hasInsufficientFunds && shouldBeDisabled) {
      persistDisabled(!!shouldBeDisabled);
    }
  };

  const trustPayment = {
    isOpen: isTrustPaymentOpen,
    handlers: {
      onSuccess: () => {
        setIsTrustPaymentOpen(false);
        setCurrentModal(EModal.TrustPaymentSuccess);
      },
      onClose: () => {
        setIsTrustPaymentOpen(false);
      },
      onStep: (_: unknown, data?: Record<string, unknown>) => {
        if (data) {
          setTrustPaymentData(data);
        }
      },
      onError: () => {
        setIsTrustPaymentOpen(false);
        setCurrentModal(EModal.Error);
      },
    },
  };

  return {
    trustPayment,
    content: {
      banner,
      currentModal,
      setCurrentModal: _setCurrentModal,
      button: {
        disabled: isDisabled,
        hasTooltip,
        buttonType,
        onClick,
      },
    },
  };
};
