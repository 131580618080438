import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TWPContainer = {
  children: ReactNode;
};

/** Without Padding Container - обертка, позволяющая убрать padding */
export const WPContainer: FC<TWPContainer> = ({ children }) => (
  <section className={cx('wrapper')}>{children}</section>
);
