import { FontColorType, FontSizeType } from '@beef/ui-kit';

type TInput = { linkText: string; smallSize?: boolean };
type TOutput = { color: FontColorType; size: FontSizeType; text?: string };

export const processTextSettings = ({ smallSize, linkText }: TInput): TOutput => {
  const color = 'grey60';
  const size = smallSize ? 'size5-r-s' : 'size4-r';
  const text = smallSize ? linkText : undefined;

  return { text, size, color };
};
