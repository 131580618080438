import React, { useState } from 'react';
import {
  CharacterBeeOutlineIcon,
  CharacterCatOutlineIcon,
  CharacterDragonOutlineIcon,
  CharacterPandaOutlineIcon,
  CharacterRobotOutlineIcon,
  TabType,
} from '@beef/ui-kit';

import { TPassivePopups } from '../../../types';

/** Подготовка контента для компонента ModalContent. Включает в себя табы и данные по пассивкам апперов */
export const usePrepareModalContent = (
  passivePopups: Partial<TPassivePopups>,
  selectedType: string,
) => {
  const [selectedTab, setSelectedTab] = useState(selectedType);

  /* Контентная часть для блока в попапе пассивок */
  const { tabs = [], title, passiveInfo = {} as Record<string, any> } = passivePopups || {};

  /* Обработчик клика по табу  */
  const onChange = (idx: string) => setSelectedTab(idx);

  /** Функция получения китовой иконки для насыщения массива объектов Tabs */
  const choseIcon = (id: string, fill = '#727281', size = 20) => {
    switch (id) {
      case '0':
        return <CharacterBeeOutlineIcon {...{ fill, size }} />;
      case '1':
        return <CharacterDragonOutlineIcon {...{ fill, size }} />;
      case '2':
        return <CharacterCatOutlineIcon {...{ fill, size }} />;
      case '3':
        return <CharacterPandaOutlineIcon {...{ fill, size }} />;
      case '4':
        return <CharacterRobotOutlineIcon {...{ fill, size }} />;
      default:
        return <CharacterBeeOutlineIcon {...{ fill, size }} />;
    }
  };

  /** Функция добавления китовой иконки к объекту таба */
  const prepareTabsWithIcon = (rawTabs: TabType[]) =>
    rawTabs?.map((tabProps) => ({ ...tabProps, icon: choseIcon(tabProps.id) }));

  /* Пропы для компонента ModalTabs */
  const passiveTabsProps = { tabs: prepareTabsWithIcon(tabs), onChange, selectedTab };

  /* Пропы для компонента PassiveInfo */
  const passiveInfoProps = { ...passiveInfo?.[selectedTab] };

  /* Пропы для компонента PassiveYandex */
  const passiveYandexInfoProps = { ...passiveInfoProps?.yandexInfo };

  /* Пропы для компонента PassiveFamily */
  const passiveFamilyInfoProps = { ...passiveInfoProps?.familyInfo };

  return {
    title,
    passiveTabsProps,
    passiveInfoProps,
    passiveYandexInfoProps,
    passiveFamilyInfoProps,
  };
};
