import React from 'react';

import { useClickOutside } from 'pages/CarnicaDSLandings/VirtualAssistant/hooks/useClickOutside';

import { usePortalContext } from '../PortalProvider';
import { ISidebarConsumerProps } from './types';

export const SidebarConsumer: React.FC<ISidebarConsumerProps> = (props) => {
  const { children, ignoreClickInsideRefs = [], onClickOutside } = props;

  const { refs } = usePortalContext();

  useClickOutside({
    handler: onClickOutside,
    ignoreClicksInsideRefs: [...ignoreClickInsideRefs, ...refs],
    isActive: !!onClickOutside,
  });

  return children as React.ReactElement;
};
