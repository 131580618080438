import { createReducer } from '@reduxjs/toolkit';

import { setLoading } from '../actions/cart';

export const cart = createReducer(
  {
    loading: false,
  },
  {
    [setLoading.type]: (state, action) => {
      state.loading = action.payload;
    },
  },
);
