// адаптер для поддержки и форматирования контента под разные версии компонента Stories
export const storiesListAdapter = ({ data: { stories } }) => ({
  stories: stories.map((story) => {
    const {
      detail: { img, legend: { link, text, title } = {}, viewTimeout } = {},
      img: image,
      slides,
      ...props
    } = story;
    if (slides?.length > 0) return story;
    return {
      ...props,
      image,
      slides: [
        {
          image: img?.src,
          link,
          text,
          title,
        },
      ],
      viewTimeout,
    };
  }),
});
