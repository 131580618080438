import { createSlice } from '@reduxjs/toolkit';

import { TFTTBSpecialistVisitReducer, TFTTBSpecialistVisitSlice } from './types';

const initialState: TFTTBSpecialistVisitSlice = {
  selectedTimePeriods: undefined,
  availableSchedule: undefined,
  selectedDate: undefined,
  selectedTime: { label: '', id: '', value: '', startTimeStamp: '', endTimeStamp: '' },
};

const fttbSpecialistVisitSlice = createSlice<
  TFTTBSpecialistVisitSlice,
  TFTTBSpecialistVisitReducer
>({
  name: 'fttbSpecialistVisit',
  initialState,
  reducers: {
    setInitialState: () => initialState,
    // установка полченных слотов монтажника после проверки адреса
    setAvailableSchedule: (state, { payload }) => {
      state.availableSchedule = payload;
    },
    // установка выбранной даты из слотов дат
    setSelectingDate: (state, { payload }) => {
      state.selectedDate = payload;
    },
    // установка временного периода после выбора даты
    setSelectedTimePeriods: (state, { payload }) => {
      state.selectedTimePeriods = payload;
    },
    // установка выбранного слота времени
    setSelectedTime: (state, { payload }) => {
      state.selectedTime = payload;
    },
  },
});

export const { setAvailableSchedule, setSelectedTime, setSelectingDate, setSelectedTimePeriods } =
  fttbSpecialistVisitSlice.actions;

export const fttbSpecialistVisitReducer = fttbSpecialistVisitSlice.reducer;
