import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TPassiveFamily = {
  description?: string;
  title?: string;
};

/**
 * информация об акциях семьи
 */
export const PassiveFamily: FC<TPassiveFamily> = ({ title, description }) => {
  if (!title && !description) {
    return null;
  }

  return (
    <div className={cx('wrapper')}>
      <Text size="size4-m">{title}</Text>
      <Text color="grey60" size="size5-r-s">
        {description}
      </Text>
    </div>
  );
};
