import { TServicesTariff } from '../store/slices/fttbSlice/types';
import { getFttbServiceAndEquipmentPrice } from '../components/commonComponents/Bill/utils';

type TCountConnectFttbPrice = {
  currentTariff?: TServicesTariff;
  servicesInacIdList?: string[];
};

/** Функция подсчета стоимости табов/сервисов ПОДКЛЮЧЕННОГО ШПД */
export const countConnectFttbPrice = ({
  currentTariff,
  servicesInacIdList,
}: TCountConnectFttbPrice) => {
  /** Стоимость пресета интернета подключенного ШПД */
  const { price: fmcTabPrice = 0, oldPrice: fmcTabOldPrice = 0 } = currentTariff?.priceInfo ?? {};

  /** Стоимость подключенных сервисов ШПД */
  const { price: fttbServicePrice, oldPrice: fttbServiceOldPrice } =
    getFttbServiceAndEquipmentPrice(currentTariff, servicesInacIdList);

  /** Общая текущая стоимость подключенного ШПД */
  const connectConvergencePrice = Number(fmcTabPrice) + Number(fttbServicePrice);

  /** Общая старая стоимость подключенного ШПД */
  const connectConvergenceOldPrice = Number(fmcTabOldPrice) + Number(fttbServiceOldPrice);

  return { connectConvergencePrice, connectConvergenceOldPrice };
};
