import { useEffect } from 'react';
import { FontSizeType } from '@beef/ui-kit';

import { useDisableNext } from '../../../../hooks/disabledNextHooks/useDisableNext';
import { EDisableNextEvent } from '../../../../store/slices/stepperSlice/types';
import { useAppSelector } from '../../../../store';
import { useCheckAddressResults } from '../selectors/useCheckAddressResults';
import { useCheckAddressPageContent } from '../selectors/useCheckAddressPageContent';

/** Хук подготовки пропов для компонента редактирования адреса подключения ШПД */
export const usePrepareCheckAddress = () => {
  /* Функция возврата к предыдущему шагу настройки */
  const { disableNext, enableNext } = useDisableNext(EDisableNextEvent.checkAddress);
  const { isHasRequest, isNotAvailable, isAlreadyConnected } = useCheckAddressResults();
  const isAvailableToConnect = useAppSelector((state) => state.fttbAddress.isAvailableToConnect);

  /* Флаг наличия проблем в возможности подключения по выбранному адресу */
  const hasError = isHasRequest || isNotAvailable || isAlreadyConnected;

  /* Делаем выборку контентных данных */
  const { title, description } = useCheckAddressPageContent() || {};

  /* Пропы для компонента TitleContent (информационное описание блока настройки)
   * Если isAvailableToConnect равен false, то не выводим заголовок и описание */
  const titleContentProps = {
    title: isAvailableToConnect ? title : undefined,
    description: isAvailableToConnect ? description : undefined,
    size: 'size2-m' as FontSizeType,
    extraSize: 'size5-r-s' as FontSizeType,
  };

  useEffect(() => {
    (isAvailableToConnect ? enableNext : disableNext)();
  }, [isAvailableToConnect]);

  /* Разблокировка "Далее" при уходе со страницы проверки адреса */
  useEffect(
    () => () => {
      enableNext();
    },
    [],
  );

  return { titleContentProps, hasError };
};
