import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Tag, Text } from '@beef/ui-kit';

import { Icons } from '../../../Icons';
import { THeader as TTopContainer } from '../../types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** title || icons верхней части Header, а также тэг */
export const TopContainer: FC<TTopContainer> = ({ tag, title, icons }) => (
  <div className={cx('wrapper')}>
    {title && <Text size="size1-m">{title}</Text>}
    {!title && !!icons?.length && <Icons {...{ icons }} />}
    {tag?.text && (
      <Tag size="m" {...tag}>
        {tag.text}
      </Tag>
    )}
  </div>
);
