const CONTROL_TYPES = {
  mnp: 'mnp',
  esim: 'esim',
};

const API_ENDPOINTS = {
  GET_SUBSCRIPTION_DATA: '/api/yandex-tariff-subscription/',
  FINISH_ACTIVATION: '/api/yandex-tariff-subscription/finish-activation/',
  STANDALONE_DISCONNECT: '/api/profile/userinfo/disableSubscription/',
};

const SUBSCRIPTION_STATUSES = {
  available: 'Available',
  notAvailable: 'NotAvailable',
  connected: 'Connected',
  suspended: 'Suspended',
  pending: 'Pending',
};

// Обновление статуса в uAPI после подключения услуги запаздывает на 3-5 минут.
// Показ актуального статуса
const getLocalStorageValue = (storageKey) => (status) => {
  if (status) {
    localStorage.setItem(storageKey, status);
  }

  return localStorage.getItem(storageKey);
};

const manageSubscriptionStatus = getLocalStorageValue('yaPlusSbscriptionStatus');

export { API_ENDPOINTS, CONTROL_TYPES, SUBSCRIPTION_STATUSES, manageSubscriptionStatus };
