import React, { FC } from 'react';
import classNames from 'classnames/bind';
// Components
import { Text } from '@beef/ui-kit';

import { DescriptionBlockProps } from './types';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const DescriptionBlock: FC<DescriptionBlockProps> = ({
  description,
  colorDescription,
  discountText,
}) => (
  <div className={cx('description')}>
    {/* @ts-expect-error color */}
    <Text color={colorDescription} size="size5-r-s">
      {description}
    </Text>
    {discountText && (
      <Text color="grey60" size="size5-r-s">
        {discountText}
      </Text>
    )}
  </div>
);

export { DescriptionBlock };
