export const selectAnketa = (state) => state.form;

export const getIsAuthorized = (state) => state?.meta?.isAuthorized || state?.content?.isAuthorized;

export const selectContent = (state) => state.content;
export const selectData = (state) => state.data;
export const selectTitle = (state) => selectContent(state).title;

export const selectAreCheckboxesChecked = (state) =>
  selectData(state)?.rulesAgreement?.value &&
  selectData(state)?.personalDataAgreement?.value &&
  selectData(state)?.employeeAgreement.value;

export const selectFormValues = (state) => ({
  firstName: selectData(state)?.firstName?.value,
  lastName: selectData(state)?.lastName?.value,
  phone: selectData(state)?.phone?.value,
  city: selectData(state)?.cityId?.value,
  storyTitle: selectData(state)?.storyTitle?.value,
  story: selectData(state)?.story?.value,
});

export const selectButtonContent = (state) => selectContent(state).button;
