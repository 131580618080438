import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Components
import classNames from 'classnames/bind';

import RightBox from 'pages/Services/VoLTEService/RightBox';
import { poll } from 'utils/timed-functions';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);
const POLL_TIMEOUT = 2000;

const ServiceParameter = ({
  type,
  parametersOptions: { value, unit, label, description, optionConnected },
  configOptions: { feature, onSwitchClick, openErrorPopup, pollIntervalTime },
}) => {
  const [loading, setLoading] = useState(false);

  const handleRequest = async (isConnected) => {
    const { soc, feature: configFeature, paramName } = feature;
    const featureParameters = {
      soc,
      feature: configFeature,
      paramName,
      paramValue: isConnected ? 'Y' : 'N',
    };

    const fetchRequestId = async () => {
      const response = await axios.post('/volteapi/change-service/', featureParameters);
      if (response?.data.requestId) {
        return response.data.requestId;
      } else {
        throw new Error('no success');
      }
    };

    const requestId = await fetchRequestId();
    if (requestId) {
      await poll(
        async () => {
          const response = await axios.get('/volteapi/change-service-status', {
            params: {
              requestId,
            },
          });
          if (response?.data.requestStatus) {
            const bigRequestStatus = response.data.requestStatus.toUpperCase();
            if (
              bigRequestStatus === 'ERROR' ||
              bigRequestStatus === 'REJECTED' ||
              bigRequestStatus === 'REQUESTSENT'
            ) {
              throw new Error('wrong requestStatus');
            }
            return bigRequestStatus;
          } else {
            return null;
          }
        },
        (bigRequestStatus) => bigRequestStatus !== 'COMPLETE' && bigRequestStatus === 'INPROGRESS',
        pollIntervalTime,
        POLL_TIMEOUT,
      );
    }
  };

  const handleSwitchClick = () => {
    setLoading(true);

    handleRequest(!optionConnected)
      .then(() => {
        onSwitchClick(!optionConnected);
      })
      .catch(() => {
        openErrorPopup();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={cx('parameter')}>
      <div className={cx('leftBox')}>
        <div className={cx('label')}>{label}</div>
        <div className={cx('description')}>{description}</div>
      </div>
      <div className={cx('rightBox')}>
        <RightBox
          handleSwitchClick={handleSwitchClick}
          loading={loading}
          optionConnected={optionConnected}
          type={type}
          unit={unit}
          value={value}
        />
      </div>
    </div>
  );
};

ServiceParameter.propTypes = {
  type: PropTypes.string,
  parametersOptions: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.string,
    optionConnected: PropTypes.bool,
  }),
  configOptions: PropTypes.shape({
    feature: PropTypes.shape({
      params: PropTypes.array,
      soc: PropTypes.string,
      paramName: PropTypes.string,
      feature: PropTypes.string,
    }),
    onSwitchClick: PropTypes.func,
    openErrorPopup: PropTypes.func,
    pollIntervalTime: PropTypes.number,
  }),
};

export default ServiceParameter;
