import { getHasBrowserWindowAPI } from 'utils/window';

// TODO: Remove once TP-1529 merged
export const redirectToLoginPage = ({
  shouldRedirectHere,
  customRedirectPath,
}: {
  customRedirectPath?: string;
  shouldRedirectHere?: boolean;
}) => {
  if (getHasBrowserWindowAPI()) {
    const redirectPath = [shouldRedirectHere && window.location.pathname, customRedirectPath].find(
      (v) => !!v,
    ) as string | undefined;

    window.location.href = [
      '/login',
      redirectPath && `?returnUrl=${encodeURIComponent(redirectPath)}`,
    ].join('');
  }
};
