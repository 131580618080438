import React from 'react';
import PropTypes from 'prop-types';
import { Emoji, Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Discount from 'pages-components/Discount';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SimpleParameter = ({
  title,
  value,
  valueIcon,
  price,
  oldPrice,
  priceTooltip,
  discountText,
  titleReverse,
}) => (
  <div className={cx('parameter')}>
    <div className={cx({ titleReverse })}>
      {title &&
        (typeof title === 'string' ?
          <div className={cx('title')} dangerouslySetInnerHTML={{ __html: title }} />
        : <div className={cx('title')}>{title}</div>)}
      {value && (
        <div className={cx(!title && 'small')}>
          {valueIcon && <Emoji className={cx('valueIcon')} name={valueIcon} />}
          <span className={cx('value')} dangerouslySetInnerHTML={{ __html: value }} />
        </div>
      )}
    </div>
    {(typeof price === 'number' || discountText) && (
      <div className={cx('price', discountText && 'discount', oldPrice && 'withLabel')}>
        {discountText && (
          <>
            {oldPrice ?
              <div className={cx('discountText')}>
                <span className={cx('crossedOut')}>{oldPrice}</span>
                <Discount.Label animated label={discountText} />
              </div>
            : <div
                className={cx('discountText')}
                dangerouslySetInnerHTML={{ __html: discountText }}
              />
            }
          </>
        )}
        <span className={cx(!oldPrice && 'crossedOut')}>
          {price === 0 || Number.isNaN(Number(price)) ? 'Бесплатно' : `${price} ₽`}
        </span>
        {priceTooltip && <Tooltip className={cx('tooltip')}>{priceTooltip}</Tooltip>}
      </div>
    )}
  </div>
);

SimpleParameter.propTypes = {
  title: PropTypes.any,
  value: PropTypes.string,
  valueIcon: PropTypes.string,
  price: PropTypes.number,
  oldPrice: PropTypes.string,
  priceTooltip: PropTypes.string,
  discountText: PropTypes.string,
  titleReverse: PropTypes.bool,
};

export default SimpleParameter;
