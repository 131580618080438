import { ActionBar, Button, Modal, Text } from '@beef/ui-kit';
import { Picture } from '@beef/smart-kit';
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { closeModal, confirmModal } from '../../store/modal';
import {
  selectIsModalOpen,
  selectModalContent,
  selectModalType,
} from '../../store/modal/selectors';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const ModalLayout = ({ isOpen, closeModal, confirmModal, modalType, content }) => {
  const { title, text, confirm, cancel, image } = content;
  return (
    <Modal
      actionBar={
        <ActionBar size="s">
          <Button
            onClick={() => {
              confirmModal(modalType);
            }}
          >
            {confirm}
          </Button>
          {cancel && (
            <Button onClick={closeModal} variant="tertiary">
              {cancel}
            </Button>
          )}
        </ActionBar>
      }
      isLoading={false}
      isOpen={isOpen}
      onClose={closeModal}
      size="s"
    >
      <div className={cn('container')}>
        <Picture alt={image?.alt} className={cn('image')} src={image?.src} />
        <span className={cn('title')}>
          <Text size="size2-m" tagType="h2">
            {title}
          </Text>
        </span>
        <span className={cn('text')}>
          <Text color="grey60" size="size4-r">
            {text}
          </Text>
        </span>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const modal = state;
  const modalType = selectModalType(modal);
  const content = ownProps.content[modalType] || selectModalContent(modal) || {};
  const isOpen = selectIsModalOpen(modal);

  return {
    isOpen,
    modalType,
    content,
  };
};

const mapDispatchToProps = (dispatch, state) => ({
  closeModal: () => dispatch(closeModal()),
  confirmModal:
    state?.confirmModal ||
    (() => {
      dispatch(confirmModal());
    }),
});

export const LandingModal = connect(mapStateToProps, mapDispatchToProps)(ModalLayout);
