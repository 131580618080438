import React, { FC } from 'react';

import { ServiceTiles } from '../ServiceTiles';
import { usePrepareExtraServices } from './hooks/usePrepareExtraServices';

/** Компонент-обертка получения данных из store для блока доп. услуг (семья) */
export const ExtraServices: FC = () => {
  const { extraServicesProps } = usePrepareExtraServices();
  return extraServicesProps.services?.length ? <ServiceTiles {...extraServicesProps} /> : null;
};
