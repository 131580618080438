import React, { memo } from 'react';
import { RangeSlider, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { generateSteps } from './utils';
import styles from './styles.pcss';
import { BarValuesType } from '../../ModalsController/types';

const cx = classNames.bind(styles);

export type LimitModalContentProps = {
  barValues: BarValuesType;
  defaultValue?: number;
  description: string;
  onChange: (value: number) => void;
};

/**
 * Компонент для отображения контента в окнах запроса лимитов
 */
export const LimitModalContent = memo<LimitModalContentProps>(
  ({ description, barValues, onChange, defaultValue = 0 }) => {
    const { gap, minChange, maxChange } = barValues || {};
    const steps = generateSteps(minChange, maxChange, gap);
    return (
      <div className={cx('content-wrapper')}>
        <Text color="grey60" size="size4-r">
          {description}
        </Text>
        <RangeSlider
          defaultIndex={defaultValue}
          lastIndex={steps.length - 1}
          onChange={onChange}
          size="m"
          steps={steps}
          title="Лимит расходов"
          unit="₽"
        />
      </div>
    );
  },
);
