import { createSlice } from '@reduxjs/toolkit';

export const getValidateField =
  (state) =>
  (fieldName, validators = []) =>
    validators.reduce((errorMessage, [errorName, validator]) => {
      if (errorMessage) return errorMessage;

      const value = state?.data?.[fieldName]?.value;
      const errorContent = state?.content?.[fieldName]?.error?.[errorName];

      const isInvalid = validator(value);
      if (isInvalid) return errorContent;
      return errorMessage;
    }, '');

export const getFormSlice = (initialState) =>
  createSlice({
    name: 'form',
    initialState,
    reducers: {
      setValue: ({ data: state }, action) => {
        const { fieldName, value } = action.payload;
        state[fieldName].value = value;
        if (!value) return;
        state[fieldName].error = '';
      },
      setError: ({ data: state }, action) => {
        const { fieldName, value } = action.payload;
        state[fieldName].error = value;
      },
      setHasSubmitted: ({ data: state }, action) => {
        state.hasSubmitted = action.payload;
      },
      setMetaFlag: (state, action) => {
        const { key, value } = action.payload;
        state.meta[key] = value;
      },
    },
    extraReducers: {
      'anketa/validate/pending': (state) => {
        state.data.button.value = 'pending';
      },
      'anketa/validate/fulfilled': (state) => {
        state.data.button.value = 'idle';
      },
      'anketa/validate/rejected': (state) => {
        state.data.button.value = 'idle';
      },
      'anketa/submit/pending': (state) => {
        state.data.button.value = 'pending';
      },
      'anketa/submit/fulfilled': (state) => {
        state.data.button.value = 'idle';
      },
      'anketa/submit/rejected': (state) => {
        state.data.button.value = 'idle';
      },
    },
  });
