import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, Text } from '@beef/ui-kit';
import { PhoneInput, Status, Tooltip } from '@beef/legacy-ui-kit';
import { formatOrderData, handleSuccessRequest } from '@beef/layout-kit/utils';
import { setCartData } from '@carnica/smart-kit';

import Ctx from 'pages-components/Ctx';
import { headerStore2024 } from 'store';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const VALID_PHONE_LENGTH = 10;

/**
 * Форма перехода со своим номером через корзину (mnp)
 */
const MnpCartFormContent = ({
  id,
  cartApi,
  userAgreement,
  note,
  description,
  validationUrl,
  errors,
  onError,
  handleSetCartData,
  basketSource,
  constructorTariffData,
  sendAnalytics,
}) => {
  const [phone, setPhone] = useState('');
  const [pending, setPending] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [statusError, setStatusError] = useState('');

  const handleError = () => {
    if (onError) {
      onError();
    } else {
      setPending(false);
      setStatusError(Ctx.defaultError.content);
    }
  };

  const handleSubmit = useCallback(
    // eslint-disable-next-line consistent-return
    async (e) => {
      e.preventDefault();

      if (phone.length !== VALID_PHONE_LENGTH) {
        setErrorText('Введите номер телефона');
      }

      if (!allowSubmit) {
        return false;
      }

      setPending(true);
      setErrorText('');
      setStatusError('');

      try {
        let requestDataDetails = {
          simOrderPlace: 'mnp',
        };

        if (basketSource) {
          requestDataDetails = {
            basketSource,
          };
        }

        const requestData = formatOrderData({
          ...requestDataDetails,
          tariffId: id,
          mnp: phone,
          constructorTariffData,
        });

        const { data } = await cartApi.post(requestData.apiEndpoint, requestData.body);

        if (data.id) {
          handleSuccessRequest({
            cartId: data.id,
            redirectToCart: true,
            items: data.items,
          });

          if (data) {
            sendAnalytics({ isConnect: true });
            handleSetCartData(data);
          }
        } else {
          handleError();
        }
      } catch (err) {
        console.error(err);
        handleError();
      }
    },
    [phone, allowSubmit],
  );

  const handlePhoneChange = (val) => {
    setPhone(val);
  };

  useEffect(() => {
    const phoneFilled = phone.length === VALID_PHONE_LENGTH;
    setErrorText('');
    setStatusError('');

    if (!validationUrl) {
      setAllowSubmit(phoneFilled);
    }

    if (!phoneFilled) {
      setAllowSubmit(false);
    } else {
      setPending(true);
      axios
        .post(validationUrl, {
          phone,
          contactPhone: phone,
          onlyValidation: true,
        })
        .then(({ data }) => {
          const isExistingCtn = data.View?.IsExistingCtn;
          setAllowSubmit(data.IsSucceeded && !isExistingCtn);
          if (!data.IsSucceeded) {
            setErrorText(data.ErrorMessage || Ctx.defaultError.content);
          }
          if (isExistingCtn) {
            setErrorText(errors.isBeelineCtn || Ctx.defaultError.content);
          }
        })
        .finally(() => {
          setPending(false);
        });
    }
  }, [phone, validationUrl]);

  return (
    <form onSubmit={handleSubmit}>
      {description && (
        <div className={cx('description')}>
          <Text color="grey80" size="size6-r">
            {description}
          </Text>
        </div>
      )}
      <div className={cx('input')}>
        <PhoneInput
          disabled={pending}
          error={errorText}
          keepErrorIndent={false}
          onChange={handlePhoneChange}
          value={phone}
        />
      </div>
      {statusError && (
        <div className={cx('status-error')}>
          <Status className="error">{statusError}</Status>
        </div>
      )}
      {note && (
        <div className={cx('note')}>
          <Text color="grey95" size="size5-r-s">
            {note}
          </Text>
        </div>
      )}
      <div className={cx('btn-wrap')}>
        <Button disabled={pending} fullWidth size="m" type="submit">
          Перейти в билайн
        </Button>
      </div>
      {userAgreement.length > 0 && (
        <div className={cx('user-agreement')}>
          {userAgreement.map((item) => (
            <div key={item.text}>
              <Text color="grey50" size="size6-r">
                {item.text}
                {item.tooltip && (
                  <Tooltip contentPosition="top">
                    <span dangerouslySetInnerHTML={{ __html: item.tooltip }} />
                  </Tooltip>
                )}
              </Text>
            </div>
          ))}
        </div>
      )}
    </form>
  );
};

const mapDispatchToProps = () => ({
  handleSetCartData: (data) => {
    headerStore2024().dispatch(setCartData(data));
  },
});

const mapStateToProps = (state) => ({
  cartApi: state.external?.cartData?.cartApi,
});

MnpCartFormContent.defaultProps = {
  userAgreement: [],
};

MnpCartFormContent.propTypes = {
  description: PropTypes.string,
  note: PropTypes.string,
  /* url для запроса валидации данных о возможности перехода  */
  validationUrl: PropTypes.string,

  userAgreement: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      tooltip: PropTypes.string,
    }),
  ),

  errors: PropTypes.shape({
    isBeelineCtn: PropTypes.string,
  }),
  /* страница заказа (только клоны mnp) */
  basketSource: PropTypes.string,
  /* сущность axios для взаимодействия с корзиной */
  cartApi: PropTypes.func,

  /* внешняя обработка ошибки запроса в АПИ шопа */
  onError: PropTypes.func,

  handleSetCartData: PropTypes.func,
};

const MnpCartForm = connect(mapStateToProps, mapDispatchToProps)(MnpCartFormContent);

export default MnpCartForm;
