import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getStoryById } from 'pages/Anniversary/api';
import { setStatus } from 'pages/Anniversary/store/status';
import { STATUS } from 'pages/Anniversary/constants';
import {
  selectCurrentRegionList,
  selectCurrentRegionRating,
} from 'pages/Anniversary/store/stories';

const fetchStoryById = createAsyncThunk('story/fetchById', async (payload, { dispatch }) => {
  try {
    return await getStoryById(payload);
  } catch (err) {
    dispatch(setStatus(STATUS.commonError));
    throw err;
  }
});

export const openStoryFromChart = (id) => (dispatch, getState) => {
  const region = selectCurrentRegionRating(getState());
  dispatch(fetchStoryById({ id, region }));
};

export const openStoryFromList = (id) => (dispatch, getState) => {
  const region = selectCurrentRegionList(getState());
  dispatch(fetchStoryById({ id, region }));
};

const initialState = {
  isLoading: false,
  currentStory: null,
};

const storySlice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    resetStory(state) {
      state.currentStory = null;
    },
  },
  extraReducers: {
    [fetchStoryById.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchStoryById.fulfilled]: (state, action) => {
      state.currentStory = action.payload;
      state.isLoading = false;
    },
    [fetchStoryById.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { resetStory } = storySlice.actions;
export default storySlice.reducer;
export * from './selectors';
