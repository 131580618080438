import { Input, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const Textarea = ({ value = '', onChange, maxLength, extraAttributes, ...props }) => {
  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <div className={cn('textarea')}>
      <Input
        isTextAreaResizable
        {...props}
        bottomLayoutNode={
          !props.errorMessage && typeof maxLength === 'number' ?
            <span className={cn('limit')}>
              <Text color="grey60" size="size6-r">
                {value.length}/{maxLength}
              </Text>
            </span>
          : null
        }
        extraAttributes={{
          ...extraAttributes,
          maxLength,
        }}
        isTextArea
        onChange={handleChange}
        value={value}
      />
    </div>
  );
};
