import { useEffect, useState } from 'react';
import axios from 'axios';

import { ResponseProps } from 'pages/PclLandingPage/IklPage/types';

type usePrepareIklDataProps = {
  isAuthorized: boolean;
  soc: string;
};

export const usePrepareIklData = ({ soc, isAuthorized }: usePrepareIklDataProps) => {
  const [data, setData] = useState<ResponseProps | null>(null);

  useEffect(() => {
    axios
      .get(`/pclServiceApi/pcl-info?soc=${soc}`)
      .then(({ data: response }) => {
        if (response) {
          setData(response);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const { isError, pclApiInfo, qpData, dpcData } = data || {};

  const { limits, contexts, hasConnectedPcl } = pclApiInfo || {};
  const { pclServiceDescription, content } = qpData || {};
  const {
    errorData,
    breadcrumbs,
    description,
    serviceControlsCommands,
    faq,
    limitsModals,
    buttons,
    payment,
  } = content || {};
  const { bgImage, marketingProductName, benefits } = dpcData || {};
  const viewConnectButton = !isAuthorized || (isAuthorized && !hasConnectedPcl);
  const viewChangeButton = isAuthorized && hasConnectedPcl;
  const viewDisconnectButton = isAuthorized && hasConnectedPcl;
  const { minChange, maxChange, maxValue, gap, chosenValue } = limits || {};
  const { connectBtn, changeBtn, disconnectBtn } = buttons || {};

  return {
    isError,
    errorData,
    pclApiInfo,
    minChange,
    maxChange,
    gap,
    chosenValue,
    limitsModals,
    maxValue,
    payment,
    contexts,
    dpcData,
    marketingProductName,
    bgImage,
    pclServiceDescription,
    breadcrumbs,
    viewConnectButton,
    connectBtn,
    viewChangeButton,
    changeBtn,
    viewDisconnectButton,
    disconnectBtn,
    benefits,
    description,
    serviceControlsCommands,
    faq,
  };
};
