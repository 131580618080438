import React, { FC, Fragment, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { TabType, Tabs } from '@beef/ui-kit';
import { PageRow } from '@beef/smart-kit';
import { withRenderFlag } from '@beef/react';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';

import { Slider } from 'pages/PromotionsPage/components/Slider';
import { DataType } from 'pages/PromotionsPage/types';
import { ANALYTICS } from 'pages/PromotionsPage/analytics';

import { Card } from './components/Card';
import styles from './styles.pcss';
import { Title } from './components/Title';

const cn = classNames.bind(styles);

type MapStateToPropsType = {
  shouldRender: boolean;
} & DiscountsBlockType;

type DiscountsBlockType = {
  data: DataType[];
  defaultImg?: string;
  tabs: TabType[];
};

const _DiscountsBlock: FC<DiscountsBlockType> = ({ data, tabs, defaultImg }) => {
  const [activeTab, setActiveTab] = useState<string>('all');
  const currentData = useMemo(
    () => (activeTab === 'all' ? data : data.filter((item) => activeTab === item.alias)),
    [activeTab, data],
  );

  const handleChangeTab = (id: string) => {
    setActiveTab(id);

    const [tabTitle] = tabs.filter((item) => item.id === id).map(({ title }) => title);
    if (tabTitle) ANALYTICS.TAB_CLICK.sendParams(tabTitle);
  };

  return (
    <PageRow blockSpacersPreset="m" tagName="section">
      <div className={cn('wrapper')}>
        <div className={cn('tabs')}>
          <Tabs
            noWrap
            onChange={handleChangeTab}
            selectedTab={activeTab}
            tabs={tabs}
            variant="tertiary"
          />
        </div>
        {currentData?.map((section) => (
          <Fragment key={section.alias}>
            <Title
              onClick={() => ANALYTICS.VIEW_ALL_CLICK.sendParams(section.alias ?? 'archive')}
              showMoreLink={section.showMoreLink}
              title={section.title}
            />
            <Slider
              className={cn('slider')}
              desktopSize={408}
              gridClassName={cn('slider--grid')}
              isNoGrow
              mobileSize={206}
              tabletSize={336}
            >
              {section?.content?.map((item) => (
                <Card
                  defaultImg={defaultImg}
                  key={item.id}
                  {...item}
                  alias={section.alias ?? 'archive'}
                  className={cn(!section.alias && 'archive')}
                  isArchive={!section.alias}
                />
              ))}
            </Slider>
          </Fragment>
        ))}
      </div>
    </PageRow>
  );
};

// @ts-expect-error state not typed in ts yet
export const DiscountsBlock = compose(
  connect(
    ({ content }): MapStateToPropsType => ({
      ...content.actionBlock,
      shouldRender: content.actionBlock,
    }),
  ),
  withRenderFlag,
)(_DiscountsBlock);
