import React from 'react';
import PropTypes from 'prop-types';

import { pushOpenTariffConnectPopup } from 'utils/ga';
import { ConnectBtnsRow } from 'pages/ProductCard/components/ConnectButtonsGroup';

import FloatingConnectWrapper from '../FloatingConnectWrapper';

const FloatingBtnsRow = ({ connectBtnText }) => (
  <FloatingConnectWrapper
    render={({ tariffData, isAvailableForConnect }) => {
      const onConnectClick = () => {
        pushOpenTariffConnectPopup({
          isMnp: false,
          tariffName: tariffData.pageTitle,
          marketCode: tariffData.marketCode,
          soc: tariffData.soc,
          method: tariffData.twoOrderBtnsData?.gaEventBuySImMethod || 'Sim',
        });
      };

      return (
        <ConnectBtnsRow
          connectBtnText={connectBtnText}
          isShowChangeTariff={isAvailableForConnect}
          onConnectClick={onConnectClick}
          tabsToDisplay={tariffData.twoOrderBtnsData?.buySimTabsToDisplay}
          tariffData={tariffData}
        />
      );
    }}
  />
);

FloatingBtnsRow.propTypes = {
  connectBtnText: PropTypes.string,
};

export default FloatingBtnsRow;
