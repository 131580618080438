import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Preloader, Status } from '@beef/legacy-ui-kit';

// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);
const ERROR_TEXT = 'Что-то пошло не так.\nПопробуйте перезагрузить страницу';

const ContentWrapper = ({ children, status }) => {
  if (status === 'loading') {
    return (
      <div className={cn('loader')}>
        <Preloader size="l" />
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className={cn('error')}>
        <Status className={['error', cn('error__status')]} text={ERROR_TEXT} />
      </div>
    );
  }

  return <div className={cn('content')}>{children}</div>;
};

ContentWrapper.propTypes = {
  status: PropTypes.bool,
  children: PropTypes.node,
};

export { ContentWrapper };
