import { useCallback, useState } from 'react';
import { shallowEqual } from '@beef/utils';
import { DropdownOptionInterface } from '@beef/ui-kit';

import { throttle } from 'utils/timed-functions';
import {
  useAppDispatch,
  useAppSelector,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';
import {
  changeHouseInputState,
  setStreet,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/fttbAddressSlice';
import { getStreetSearchResults } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/api/fmcAddressCheck/getStreetSearchResults';

/* Хук подготовки пропов input выбора региона */
export const useStreetInput = () => {
  const dispatch = useAppDispatch();

  const {
    value = '',
    regionId,
    errorTextHelper,
  } = useAppSelector(
    (state) => ({
      /* Значение input региона из стора fttbAddress */
      value: state.fttbAddress.checkConnectionRes.street,
      regionId: state.fttbAddress.checkConnectionRes.regionId,
      errorTextHelper: state.common.content?.fmcSettings?.checkAddressPage.errorTextHelper,
    }),
    shallowEqual,
  );

  /* Текст ошибки */
  const [errorMessage, setErrorMessage] = useState<string>();

  /* Обработка выбора улицы из подсказки */
  const onSelect = useCallback(
    (street: DropdownOptionInterface) => {
      dispatch(setStreet({ street: street.label, streetId: Number(street.id) }));
      dispatch(changeHouseInputState(!street.isConnected));
      setErrorMessage(!street.isConnected ? errorTextHelper : undefined);
    },
    [errorTextHelper],
  );

  /* Очистка улицы в сторе, если input редактируется пользователем */
  const onChange = useCallback(
    throttle(
      () => {
        dispatch(setStreet({ street: undefined, streetId: undefined }));
      },
      1000,
      {},
    ),
    [value],
  );

  const onSearch = useCallback(
    (term: string) => getStreetSearchResults(regionId, term),
    [regionId],
  );

  const disabled = useAppSelector((state) => state.fttbAddress.isDisabledStreet);

  return { value, onSelect, onChange, onSearch, disabled, errorMessage };
};
