import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ModalLayout = ({ children }) => <div className={cx('container')}>{children}</div>;

ModalLayout.propTypes = {
  children: PropTypes.node,
};
