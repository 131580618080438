import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import InfoPopup from 'pages-components/InfoPopup';
import EmptyButton from 'pages-components/EmptyButton';

import Info from '../Info';
import styles from '../Info/styles.pcss';

const cx = classNames.bind(styles);

/**
 * Wrapper для вызова модального окна;
 * -----------------------------------
 * @param fetchUrl - Url по которому вызывается модально окно;
 * @param value - Текст внутри блока;
 * @param popupClassName - Класс для попапа;
 * @params ...props - Принимает все props
 */

const InfoWithPopup = ({ fetchUrl, value, popupClassName, isLayoutYoung, ...props }) => {
  const [showPopup, toggleShowPopup] = useState(false);

  return (
    <>
      <EmptyButton
        className={cx('infoButton', 'clickable', isLayoutYoung && 'layoutYoung')}
        {...props}
        onClick={() => {
          toggleShowPopup(true);
        }}
      >
        <Info isLayoutYoung={isLayoutYoung} value={value} />
        <div className={cx('arr')} />
      </EmptyButton>
      {showPopup && (
        <InfoPopup
          className={popupClassName}
          fetchUrl={fetchUrl}
          isLayoutYoung={isLayoutYoung}
          onClose={() => {
            toggleShowPopup(false);
          }}
        />
      )}
    </>
  );
};

InfoWithPopup.propTypes = {
  fetchUrl: PropTypes.string,
  value: PropTypes.string,
  popupClassName: PropTypes.string,
  isLayoutYoung: PropTypes.bool,

  children: PropTypes.object,
};

export default InfoWithPopup;
