import React from 'react';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { Card } from '@beef/smart-kit';

import styles from './styles.module.pcss';

const cx = classnames.bind(styles);

export const CommandCard = ({ title, text, className }) => (
  <Card className={className} withHover>
    <a className={cx('card-content')} href={`tel:${title}`}>
      <div className={cx('card-title')}>
        <Text size="size4-m">{title}</Text>
      </div>
      <Text color="grey60" size="size5-r-l">
        {text}
      </Text>
    </a>
  </Card>
);
