import { RefObject, useEffect, useState } from 'react';
import { useIsMobileDimension } from '@beef/ui-kit/hooks';

type TWindowHeight = {
  height: number;
  visualViewportHeight?: number;
};
/**
 * @desc: Хук для получения и установки в виде переменной CSS реальной доступной высоты (100vh)
 */
export const useMobileAvailableHeight = (
  elementRef: RefObject<HTMLElement>,
  cssVarName: string,
) => {
  const isMobile = useIsMobileDimension();
  const [windowHeight, setWindowHeight] = useState<TWindowHeight>({
    height: 0,
    visualViewportHeight: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight({
        height: window.innerHeight,
        visualViewportHeight: window.visualViewport?.height,
      });
    };

    window.addEventListener('resize', handleResize);
    window.visualViewport?.addEventListener('resize', handleResize);

    handleResize();

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('resize', handleResize);
      window.visualViewport?.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (elementRef.current && isMobile && cssVarName) {
      elementRef.current.style.setProperty(
        cssVarName,
        `${windowHeight.visualViewportHeight ?? windowHeight.height}px`,
      );
    }
  }, [elementRef, isMobile, windowHeight]);
};
