import { useStepNavigation } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/hooks/useStepNavigation';

import { EUserStoryType } from '../store/slices/stepperSlice/types';

/** Хук определяет, является ли подключение покупкой новой сим (весь нз и аз купить новую сим) */
export const useIsNewSimUserType = () => {
  const { userType } = useStepNavigation();

  const newSimUserList = [
    /* Нз случаи */
    EUserStoryType.notAuthWithoutFTTB,
    EUserStoryType.notAuthWithFTTB,
    /* АЗ случаи */
    EUserStoryType.authNewSimNotConnectedWithoutFTTB,
    EUserStoryType.authNewSimNotConnectedWithFTTB,
    /* Партнерский шпд */
    EUserStoryType.tariffUpWithPartnerFTTB,
  ];

  return newSimUserList.includes(userType);
};
