import axios from 'axios';

const headers = { headers: { 'X-Requested-With': 'XMLHttpRequest' } };

export const postActivateLimit = (requestData: any) =>
  axios.post('/pclServiceApi/activate', { limit: requestData }, headers);

export const postDeactivateLimit = () => axios.post('/pclServiceApi/deactivate', {}, headers);

export const postChangeLimit = (requestData: any) =>
  axios.post('/pclServiceApi/change-limit', { limit: requestData }, headers);
