const getPriceCategory = (facets = []) => {
  const price = facets?.find((item) => item.name === 'price');
  const priceObject = { min: 0, max: 0 };
  price?.values?.forEach((value) => {
    priceObject[value.id] = value.value;
  });
  return priceObject;
};

export const fromAPISearch = (data) => ({
  filters: data.facets,
  totalHits: data.totalHits,
  categories: data.facets.find((item) => item.name === 'categories')?.values || [],
  price: getPriceCategory(data.facets),
  selectedCategories: data.selectedFacets?.find((item) => item.name === 'categories')?.values || [],
  productList: data.products || [],
  correction: data.correction,
  zeroQueries: data.zeroQueries,
});

export const fromAPIAutocomplete = (data) => ({
  taps: data.taps,
  articles: data.contents?.map((article) => ({
    link: article.link_url,
    title: article.name,
    annotation: article.annotation,
  })),
});
