import { useState } from 'react';

/**
 * Функция управления блоком нотификации (предупреждения);
 */
export const useNotification = (isCorrectShareSize) => {
  const [showNotification, setShowNotification] = useState(isCorrectShareSize);

  const handleNotification = () => setShowNotification(false);

  return { showNotification, handleNotification };
};
