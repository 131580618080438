/**
 * Перенаправляет пользователя на страницу входа с дополнительными параметрами поиска.
 * По умолчанию он добавляет текущий путь в качестве параметра returnUrl.
 *
 * @function
 * @param {Object} [searchParams={}] - Объект, представляющий дополнительные параметры поиска.
 * @example
 * // Redirects to '/login/?returnUrl=/currentPath'
 * redirectToLogin();
 *
 * @example
 * // Redirects to '/login/?returnUrl=/currentPath&lang=en&theme=dark'
 * redirectToLogin({ lang: 'en', theme: 'dark' });
 */
export const redirectToLogin = (searchParams = {}) => {
  const { pathname, origin, search: existingSearch } = window?.location || {};

  if (!pathname || !origin) return;

  const existingParams = new URLSearchParams(existingSearch);

  Object.keys(searchParams).forEach((key) => {
    existingParams.set(key, encodeURI(searchParams[key]));
  });

  const returnUrl = `${pathname}${
    existingParams.toString() ? `?${existingParams.toString()}` : ''
  }`;
  const newUrl = `${origin}/login/?returnUrl=${encodeURI(returnUrl)}`;

  window.open(newUrl, '_self');
};
