export const dataConverter = (data) => ({
  content: data,
  products: {
    maxCountToShowInFilter: data.maxCountToShowInFilter || 7,
    maxCountToOpenFilter: data.maxCountToOpenFilter || 3,
    searchUrl: data.searchUrl || '',
    autocompleteUrl: data.autocompleteUrl || '',
    webViewAutocompleteUrl: data.webViewAutocompleteUrl || '',
    webViewSearchUrl: data.webViewSearchUrl || '',
    basketIdExpiration: data?.cookieSettings?.basketIdExpiration || '',
    defaultImg: data.categories || {},
    isShopModalEnabled: data.isShopModalEnabled,
  },
});
