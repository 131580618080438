import React from 'react';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

type Props = {
  alt?: string;
  onClick: () => void;
  src: string;
};

export const PreviewSlide = ({ src, alt, onClick }: Props) => (
  <button className={cx('slide')} onClick={onClick} type="button">
    <img alt={alt} src={src} />
  </button>
);
