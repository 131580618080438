import React from 'react';
import classNames from 'classnames/bind';
import { motion } from 'framer-motion';

import { usePreparePresetPicker } from './hooks/usePreparePresetPicker';
import { useScrollOnDrag } from './hooks/useScrollOnDrag';
import { PresetPickerUnit } from './components/PresetPickerUnit';
import { PresetPickerShadow } from './components/PresetPickerShadow';
import { PresetPickerTile } from './components/PresetPickerTile';
import { TPresetPicker } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент ЕК PresetPicker отображает набор плиток с пресетами, которые можно перетаскивать горизонтально. */
export const PresetPicker = ({ activeValue, steps, unit, onChange, icon }: TPresetPicker) => {
  const { activeRef, containerRef } = usePreparePresetPicker();

  const {
    isDragListener,
    isDragging,
    isScrolledToEnd,
    handleDrag,
    handleDragStart,
    handleDragEnd,
  } = useScrollOnDrag(containerRef);

  return (
    <div className={cx('component')}>
      {/* Компонент для отображения единицы измерения */}
      <PresetPickerUnit unit={unit} />
      {/* Отображаем плитки с пресетами */}
      <motion.div
        className={cx('container', { 'container--dragging': isDragging })}
        drag="x"
        dragConstraints={containerRef}
        dragElastic={0}
        dragListener={isDragListener}
        layoutScroll
        onDrag={(event, info) => handleDrag(info)}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        ref={containerRef}
      >
        {steps.map(({ size, isOffer }) => {
          const isActive = activeValue === size;
          return (
            <PresetPickerTile
              key={size}
              ref={activeRef}
              {...{ size, isOffer, onChange, isActive, icon }}
            />
          );
        })}
      </motion.div>
      {/* Компонент для отображения тени */}
      <PresetPickerShadow isScrolledToEnd={isScrolledToEnd} />
    </div>
  );
};
