import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@carnica/ui-kit';

import { useAppSelector } from 'constructorV1/store';
import { onReloadWithCrash } from 'constructorV1/utils/onReloadWithCrash';
import { MainContainer } from 'constructorV1/components/commonComponents/MainContainer';

import { selectErrorBoundaryContent } from './selectors/selectErrorBoundaryContent';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент общей ошибки */
export const ErrorBoundaryFallback: FC = () => {
  /* Общие контентные данные */
  const { title, description, image, btnText } = useAppSelector(selectErrorBoundaryContent);

  return (
    <MainContainer>
      <section className={cx('wrapper')}>
        {image && <img alt="" className={cx('wrapper__image')} src={image} />}
        <div className={cx('wrapper__content')}>
          {title && (
            <Text color="primary" size="l">
              {title}
            </Text>
          )}
          {description && (
            <Text color="secondary" size="s">
              {description}
            </Text>
          )}
        </div>
        {btnText && (
          <div className={cx('wrapper__button')}>
            <Button onClick={onReloadWithCrash} width="full">
              {btnText}
            </Button>
          </div>
        )}
      </section>
    </MainContainer>
  );
};
