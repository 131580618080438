import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { ItemTopBlock } from '../ItemTopBlock';
import { ItemBottomBlock } from '../ItemBottomBlock';
import { setSelectedTariffServices } from '../../../../../../store/actions/orderTariffDetailsData';
import styles from '../TariffContentContainer/styles.pcss';

const cx = classNames.bind(styles);

const ItemContentLayout = ({
  name,
  inacId,
  checked,
  isMobile,
  description,
  subscription,
  tariffInacId,
  selectService,
  fttbServiceType,
  toolTipText: text,
  enabledInTariff: disabled,
}) => (
  <li className={cx('wrapper__item')}>
    <ItemTopBlock
      {...{ text, name, checked, disabled }}
      onChange={() => selectService({ inacId, tariffInacId, name, fttbServiceType })}
      showToolTip={text && !isMobile}
    />
    <ItemBottomBlock {...{ description, subscription }} />
  </li>
);

ItemContentLayout.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  isMobile: PropTypes.bool,
  inacId: PropTypes.string,
  toolTipText: PropTypes.string,
  description: PropTypes.string,
  selectService: PropTypes.func,
  tariffInacId: PropTypes.string,
  subscription: PropTypes.string,
  enabledInTariff: PropTypes.bool,
  fttbServiceType: PropTypes.number,
};

export const ItemContentContainer = connect(null, { selectService: setSelectedTariffServices })(
  ItemContentLayout,
);
