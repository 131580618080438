import { connect } from 'react-redux';
import React, { FC } from 'react';

import { BaseStatus } from 'pages/Services/RedirectServiceCardLanding/components/Status/BaseStatus';
import { RedirectStore } from 'pages/Services/RedirectServiceCardLanding/store';
import { selectRedirectQPContent } from 'pages/Services/RedirectServiceCardLanding/store/redirect/selectors';
import { SuccessActiveProps } from 'pages/Services/RedirectServiceCardLanding/components/Status/types';

export const _SuccessCancel: FC<SuccessActiveProps> = ({ content, onCloseModal, hideButtons }) => (
  <BaseStatus {...content} hideButtons={hideButtons} onClickMain={onCloseModal} />
);

const mapStateToProps = (state: RedirectStore) => ({
  content: selectRedirectQPContent(state)?.cancelActive,
});

export const SuccessCancel = connect(mapStateToProps)(_SuccessCancel);
