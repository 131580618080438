import React from 'react';
import PropTypes from 'prop-types';

// Components
import Wizard, { Step } from 'pages-components/Wizard/Wizard';

import { ConfirmStep, ConnectStep, NotAllowedStep, PendingStep, ResultStep } from '../Steps';

const FavoriteNumberWizardModal = ({
  connectModal,
  confirmModal,
  closeModal,
  successModal,
  openDeleteNumber,
  changeFreeModal,
  notAllowedModal,
  delSuccessModal,
  deleteModal,
  openConfirm,
  errorModal,
  changeModal,
  reConnectModal,
  onChange,
  onDelete,
  onConnect,
  opened,
  step,
}) => (
  <Wizard activeStep={step} onClose={closeModal} opened={opened}>
    <Step name="connect">
      <ConnectStep data={connectModal} onChange={onChange} openConfirm={openConfirm} />
    </Step>
    <Step name="reConnect">
      <ConnectStep data={reConnectModal} onChange={onChange} openConfirm={openConfirm} />
    </Step>
    <Step name="change">
      <ConnectStep
        data={changeModal}
        onChange={onChange}
        openConfirm={openConfirm}
        openDeleteNumber={openDeleteNumber}
      />
    </Step>
    <Step name="changeFree">
      <ConnectStep
        data={changeFreeModal}
        onChange={onChange}
        openConfirm={openConfirm}
        openDeleteNumber={openDeleteNumber}
      />
    </Step>
    <Step name="notAllowed">
      <NotAllowedStep data={notAllowedModal} onClose={closeModal} />
    </Step>
    <Step name="confirm">
      <ConfirmStep data={confirmModal} onClick={onConnect} onClose={closeModal} />
    </Step>
    <Step name="delete">
      <ConfirmStep data={deleteModal} onClick={onDelete} onClose={closeModal} />
    </Step>
    <Step name="pending">
      <PendingStep />
    </Step>
    <Step name="success">
      <ResultStep data={successModal} onClose={closeModal} />
    </Step>
    <Step name="delSuccess">
      <ResultStep data={delSuccessModal} onClose={closeModal} />
    </Step>
    <Step name="error">
      <ResultStep data={errorModal} onClose={closeModal} />
    </Step>
  </Wizard>
);

FavoriteNumberWizardModal.propTypes = {
  connectModal: PropTypes.object,
  confirmModal: PropTypes.object,
  deleteModal: PropTypes.object,
  notAllowedModal: PropTypes.object,
  changeFreeModal: PropTypes.object,
  delSuccessModal: PropTypes.object,
  successModal: PropTypes.object,
  errorModal: PropTypes.object,
  changeModal: PropTypes.object,
  reConnectModal: PropTypes.object,
  closeModal: PropTypes.func,
  opened: PropTypes.bool,
  openConfirm: PropTypes.func,
  onConnect: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  openDeleteNumber: PropTypes.func,
  step: PropTypes.string,
};

export default FavoriteNumberWizardModal;
