import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { StatusPropsType } from './types';
import styles from './styles.pcss';
import { Buttons } from './components/Buttons';
import { Content } from './components/Content';

const cx = classNames.bind(styles);

export const Status: FC<StatusPropsType> = ({
  title,
  subTitle,
  acceptText,
  declineText,
  onAccept,
  onDecline,
  isSuccess,
}) => {
  return (
    <div className={cx('wrap')}>
      <Content isSuccess={isSuccess} subTitle={subTitle} title={title} />
      <Buttons
        acceptText={acceptText}
        declineText={declineText}
        onAccept={onAccept}
        onDecline={onDecline}
      />
    </div>
  );
};
