import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { pushOpenTariffConnectPopup } from 'utils/ga';
import {
  ChangeTariffBtn,
  ConnectTariffButton,
} from 'pages/ProductCard/components/ConnectButtonsGroup';

import FloatingConnectWrapper from '../FloatingConnectWrapper';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FloatingBtnsGroup = ({ connectBtnText }) => (
  <FloatingConnectWrapper
    greyAreaBottomSpace={70}
    render={({ tariffData, isAvailableForConnect }) => {
      const onConnectClick = () => {
        pushOpenTariffConnectPopup({
          isMnp: false,
          tariffName: tariffData.pageTitle,
          marketCode: tariffData.marketCode,
          soc: tariffData.soc,
          method: tariffData.twoOrderBtnsData?.gaEventBuySImMethod || 'Sim',
        });
      };

      return (
        <div className={cx('wrapper')}>
          <ConnectTariffButton
            buttonText={connectBtnText}
            className={cx('connectBtn')}
            isFloatingButton
            newNumber=""
            onClick={onConnectClick}
            popupData={tariffData}
            tabsToDisplay={tariffData.twoOrderBtnsData?.buySimTabsToDisplay}
          />
          {isAvailableForConnect && (
            <div className={cx('changeTariffBtnWrap')}>
              <ChangeTariffBtn data={tariffData} />
            </div>
          )}
        </div>
      );
    }}
  />
);

FloatingBtnsGroup.propTypes = {
  connectBtnText: PropTypes.string,
};

export default FloatingBtnsGroup;
