import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from '@beef/legacy-ui-kit';

import styles from './styles.pcss';
import HowToWatchPopup from '../HowToWatchPopup';

const cx = classNames.bind(styles);

class HowToWatchLink extends Component {
  state = {
    howToWatchPopup: false,
  };

  onHowToWatchClick = () => this.setState({ howToWatchPopup: true });

  closeHowToWatchPopup = () => this.setState({ howToWatchPopup: false });

  render() {
    const { linkText, popupTexts } = this.props;

    if (!linkText || !popupTexts) return null;

    const { howToWatchPopup } = this.state;

    return (
      <div>
        <div className={cx('howToWatch')}>
          <Link onClick={this.onHowToWatchClick}>
            <span dangerouslySetInnerHTML={{ __html: linkText }} />
          </Link>
        </div>

        <HowToWatchPopup
          onClose={this.closeHowToWatchPopup}
          opened={howToWatchPopup}
          textData={popupTexts}
        />
      </div>
    );
  }
}

HowToWatchLink.propTypes = {
  linkText: PropTypes.string,
  popupTexts: PropTypes.object,
};

export default HowToWatchLink;
