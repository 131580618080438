import React from 'react';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const Timer = ({ duration, progress }) => (
  <div className={cx('timer')}>
    <Text color="grey60" size="size6-r">
      {formatTime(progress * duration)}
    </Text>
    <Text color="grey60" size="size6-r">
      {formatTime(duration)}
    </Text>
  </div>
);

const formatTime = (seconds) => {
  const date = new Date(0);
  date.setSeconds(seconds || 0);
  return date.toISOString().substring(15, 19);
};
