import axios from 'axios';

import { setTariffConnectionPopup } from '../tariffConnect/tariffConnectExtension';

let count = 0;

/**
 * Посылает запросы на бэк;
 */
const polling = (downsellData, requestId, dispatch, timeout) => {
  const url = downsellData.pollingUrl;
  const performanceTimer = performance.now();

  axios.post(url, { requestId }).then((request) => {
    const {
      data: { isSucceeded },
    } = request;

    // Если общее время запросов больше timeout, выводим
    // модально окно ошибки и выходим из функции;
    if (count >= timeout) {
      dispatch(
        setTariffConnectionPopup({
          isDownsellPending: false,
          data: { ...downsellData.texts, isSucceeded: false },
        }),
      );

      return null;
    }

    // if
    //   Если ответ пришел null то продолжаем делать
    //   запросы, увеличиваем счетчик на виличину запроса;
    // else
    //   Если пришел результат true или false завершаем пулинг
    //   и вывордим соответсвующее модальное окно, выходим из фыункции;
    if (isSucceeded === null) {
      const millisec = performance.now() - performanceTimer;
      const seconds = millisec / 1000;

      count += seconds;
      polling(downsellData, requestId, dispatch, timeout);
    } else {
      dispatch(
        setTariffConnectionPopup({
          isDownsellPending: false,
          data: { ...downsellData.texts, isSucceeded },
        }),
      );

      return null;
    }
  });
};

/**
 * Функция рабортает в связке с replaceDowsellTariff;
 */
export const pollingDownsellTariff = (downsellData, requestId, dispatch, timeout) => {
  // Запускаем прелоудер, задаем шаг модального окна Wizard
  dispatch(
    setTariffConnectionPopup({
      isDownsellPending: true,
      downsellStep: 'counteroffer',
      data: null,
    }),
  );

  try {
    polling(downsellData, requestId, dispatch, timeout);
  } catch (err) {
    console.error(err);
  }
};
