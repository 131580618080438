import { TAnimalTab } from '../components/AnimalTab';
import { DEFAULT_BG_COLOR } from '../../../../constants';
import { useIsHighPreset } from '../../../../hooks/useIsHighPreset';

export const usePrepareAnimalTabSettings = ({
  onClick,
  optionSoc,
  activeColor,
  highOptionSoc,
  activeCharacter,
}: Omit<TAnimalTab, 'imageSrc' | 'name'>) => {
  /* Тип текущего пресета */
  const isHighPreset = useIsHighPreset();

  /* Текущий активный сок персонажа тарифа */
  const currentCharacterSoc = (isHighPreset ? highOptionSoc : optionSoc) || '';

  /* Обработчик клика по табу персонажа */
  const handleClick = () => onClick(currentCharacterSoc);

  /* Флаг определения активного персонажа. Подсвечивает таб персонажа в нужный цвет */
  const isActive = activeCharacter === currentCharacterSoc;

  /* Текущий цвет таба персонажа */
  const backgroundColor = isActive ? activeColor : DEFAULT_BG_COLOR;

  return { isActive, handleClick, backgroundColor };
};
