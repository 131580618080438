import { ChangeEvent, MouseEvent, useState } from 'react';
import { shallowEqual } from '@beef/utils';
// @ts-expect-error ошибочно НЕ импортируется SizeTypes из @beef/ui-kit
import { SizeTypes } from '@beef/ui-kit/Modal/types';

import { DEFAULT_INDEX } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/constants';
import { setToggleService } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/totalSlice';
import { TCommonEquipment } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/fttbSlice/types';
import {
  useAppDispatch,
  useAppSelector,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

import { useSelectFmcServiceContent } from '../../selectors/useSelectFmcServiceContent';
import { useSelectActiveInacId } from '../../../../../FmcTabsContainer/selectors/useSelectActiveInacId';
import { ECheckboxType, EEquipmentType, TSizeType } from '../Equipment/types';

type TUsePrepareInternetEquipment = {
  checkboxType: ECheckboxType;
  equipmentType: EEquipmentType;
};

/** Хук для подготовки данных чекбокса оборудования EquipmentCheckbox и его вложенных блоков.
 * Используется как для интернет-оборудования, так и для ТВ-оборудования.
 * Идет получение всех доступных тарифов и выборка по-активному currentInacId объекта equipmentType
 * Тк компонент EquipmentCheckbox глупый, подготовка всех пропсов происходит здесь */
const usePrepareInternetEquipment = ({
  equipmentType,
  checkboxType,
}: TUsePrepareInternetEquipment) => {
  const dispatch = useAppDispatch();
  const { modalContent, actionBar } = useAppSelector(
    (state) => ({
      modalContent: state.common.content.removeEquipmentPopup?.modalContent,
      actionBar: state.common.content.removeEquipmentPopup?.actionBar,
    }),
    shallowEqual,
  );

  /** Текущий активный inacId тарифа */
  const currentInacId = useSelectActiveInacId();

  /** Определяем текущий шпд тариф */
  const tariffs = useAppSelector((state) => state.fttbPresets.tariffs);
  const equipment = tariffs?.find((tariff) => tariff.inacId === currentInacId)?.[
    equipmentType
  ] as TCommonEquipment[];

  /** Находим текущий блок оборудования. Устанавлваем initialChecked для чекбокса если есть активный по дефолту */
  const activeEquipment = equipment?.find((item) => item.isActive);
  const initialChecked = !!activeEquipment;

  /** Устанавливаем дефолтное значение выбранного блока */
  const defaultValue = activeEquipment?.inacId || equipment?.[DEFAULT_INDEX]?.inacId || '';

  /** Стейт блока оборудования checkedInacId хранит inacId выбранного блока */
  const [checkedInacId, setCheckedInacId] = useState(defaultValue);

  /** Контент для чекбокса настройки оборудования */
  const settingsFmcPage = useSelectFmcServiceContent();
  const label = settingsFmcPage?.[checkboxType] || '';

  /** Стейт основного чекбокса */
  const [isChecked, setIsChecked] = useState(initialChecked);
  /** Стейт модального окна подтверждения */
  const [isOpenModal, setIsOpenModal] = useState(false);

  /** Выходим из хука с null если оборудования нет.
   *  По-хорошему можно выйти выше, но ругается eslint на правлило хук послу ретурна  */
  if (!equipment) {
    return {
      checkboxProps: null,
      equipmentProps: null,
    };
  }

  /** Ф-я обработчик клика по блокам. Записывает inacId выбранного блока оборудования в стейт и стор */
  const onChangeRadio = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === checkedInacId) return;
    setCheckedInacId(e.target.value);
    /* Удаляем старое значение */
    dispatch(setToggleService(checkedInacId));
    /* Добавляем новое значение */
    dispatch(setToggleService(e.target.value));
  };

  /** Ф-я определения активного блока оборудования для последующей стилизации */
  const getIsActive = (item: TCommonEquipment) => item.inacId === checkedInacId;

  /** Размер чекбокса */
  const size: TSizeType = 'big';

  const closeModal = () => setIsOpenModal(false);
  const openModal = () => setIsOpenModal(true);

  const setCheckedInaqId = () => dispatch(setToggleService(checkedInacId));
  const onClose = (e: MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault();
    closeModal();
  };

  const onConfirm = (e: MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault();
    setCheckedInaqId();
    setIsChecked(false);
    closeModal();
  };

  /** Ф-я обработчик клика по чекбоксу. Записывает стейт состояние isChecked, обновляет стор */
  const onChangeCheckbox = () => {
    if (isChecked) {
      openModal();
      return;
    }
    setIsChecked(true);
    /* Удаляет значение из стор если отжимаем чекбокс. Добавлят eсли нажимаем */
    setCheckedInaqId();
  };

  /** Пропы для основного чекбокса */
  const checkboxProps = {
    label,
    size,
    name: checkboxType,
    checked: isChecked,
    onChange: onChangeCheckbox,
  };

  /** Пропы для блоков оборудования */
  const equipmentProps = {
    onChange: onChangeRadio,
    getIsActive,
    equipmentData: equipment,
  };

  /** Пропы для модального окна */
  const modalContainerProps = {
    isOpen: isOpenModal,
    size: 'm' as SizeTypes,
    onClose: closeModal,
    modalActionBarProps: {
      ...actionBar,
      link: actionBar?.link ?? '',
      onClick: onClose,
      onClickExtra: onConfirm,
    },
  };

  const modalProps = {
    modalContainerProps,
    modalHeaderProps: modalContent,
  };

  return {
    checkboxProps,
    equipmentProps,
    modalContainerProps,
    modalProps,
  };
};

export { usePrepareInternetEquipment };
