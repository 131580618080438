import store from 'store';

import AlwaysInPositiveService, {
  AlwaysInPositiveSettingsConnected,
} from './AlwaysInPositiveService/AlwaysInPositiveService';
import {
  ALWAYS_IN_POSITIVE_SERVICE_NAME,
  showConnectingPopup,
  toggleConnectingWithPopup,
} from '../../actions/alwaysInPositiveBanner';

export default function (serviceName) {
  switch (serviceName) {
    case ALWAYS_IN_POSITIVE_SERVICE_NAME:
      return {
        component: AlwaysInPositiveService,
        settingsContent: AlwaysInPositiveSettingsConnected,
        onClick: () => {
          console.warn('button click');
          store.dispatch(toggleConnectingWithPopup());
        },
        onBodyClick: () => {
          console.warn('body click');
          store.dispatch(showConnectingPopup());
        },
      };
    default:
      return {};
  }
}
