import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import classNames from 'classnames/bind';

// Utils
import { Preloader } from '@beef/legacy-ui-kit';

import { templateParser } from 'utils/format-string';

// Components
import ErrorPopup from 'pages-components/ErrorPopup/ErrorPopup';

import ParametersBlock from './ParametersBlock';
import styles from './styles.pcss';

const cx = classNames.bind(styles);
const DEFAULT_POLL_INTERVAL = 50;

const CONNECT_STATUS_CONFIG = {
  connect: 'подключении',
  disConnect: 'отключении',
};
const SERVICE_NAME = 'VOLTE3';

const VoLTEService = ({
  data: {
    productParameters,
    extData: { priceHeading, optionsHeading, errorPopupContent },
  },
  ctn,
}) => {
  const [loading, setLoading] = useState(true);
  const [serviceError, setServiceError] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feature, setFeature] = useState(null);
  const [optionConnected, setOptionConnection] = useState(false);
  const [stateLabel, setStateLabel] = useState(null);
  const [desc, setDesc] = useState(null);
  const [pollIntervalTime, setPollIntervalTime] = useState(null);

  const VoWifiOptionTexts = {
    label: stateLabel,
    description: desc,
  };

  useEffect(() => {
    axios
      .get('/volteapi/', {
        params: {
          ctn,
          soc: SERVICE_NAME,
        },
      })
      .then(({ data }) => {
        if (data) {
          setFeature({ ...data, soc: SERVICE_NAME });
          setStateLabel(data.label);
          setDesc(data.description);
          setOptionConnection(data.connectionStatus === 'Y');
          setPollIntervalTime(data.pollIntervalTime || DEFAULT_POLL_INTERVAL);
        }
        setLoading(false);
        setServiceError(false);
      })
      .catch(() => {
        setLoading(false);
        setServiceError(true);
      });
  }, []);

  if (loading) {
    return <Preloader className={cx('preloader')} size="40" />;
  }

  return (
    <>
      <div className={cx('serviceContainer')}>
        <ParametersBlock
          feature={feature}
          heading={optionsHeading}
          onSwitchClick={setOptionConnection}
          openErrorPopup={() => setErrorPopup(true)}
          parameters={[{ ...VoWifiOptionTexts, optionConnected }]}
          pollIntervalTime={pollIntervalTime}
          serviceError={serviceError}
          type="optionParameters"
        />
        <ParametersBlock
          heading={priceHeading}
          parameters={productParameters}
          type="productParameters"
        />
      </div>

      {errorPopup && errorPopupContent && (
        <ErrorPopup
          buttonText={errorPopupContent.buttonText}
          description={templateParser(errorPopupContent.description, {
            type:
              optionConnected ? CONNECT_STATUS_CONFIG.disConnect : CONNECT_STATUS_CONFIG.connect,
            serviceName: VoWifiOptionTexts?.label,
          })}
          errorEmoji={errorPopupContent.errorEmoji}
          onClose={() => setErrorPopup(false)}
          opened={errorPopup}
          title={errorPopupContent.title}
        />
      )}
    </>
  );
};

VoLTEService.propTypes = {
  data: PropTypes.shape({
    productParameters: PropTypes.arrayOf(PropTypes.object),
    extData: PropTypes.shape({
      options: PropTypes.array,
      priceHeading: PropTypes.string,
      optionsHeading: PropTypes.string,
      errorPopupContent: PropTypes.shape({
        errorEmoji: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        buttonText: PropTypes.string,
      }),
    }),
  }),
  ctn: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { currentCtn } = pathOr({}, ['external'], state);

  return {
    ctn: currentCtn?.ctn,
  };
};

export default connect(mapStateToProps)(VoLTEService);
