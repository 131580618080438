import { createSlice } from '@reduxjs/toolkit';

import { TFttbAddressSlice, TFttbAddressSliceReducer } from './types';
import {
  EConnectionCheckResult,
  getCheckConnection,
} from '../../../api/fmcAddressCheck/getCheckConnection';

const initialState: TFttbAddressSlice = {
  checkConnectionRes: {
    region: undefined,
    regionId: undefined,
    street: undefined,
    streetId: undefined,
    house: undefined,
    houseId: undefined,
    /* Может быть string или number */
    flat: undefined,
    isConnected: true,
    isConvergentRequest: true,
    checkAddressType: 0,
  },

  isDisabledStreet: true,
  isDisabledHouse: true,
  isDisabledFlat: true,

  isHasRequest: false,
  isNotAvailable: false,
  isAlreadyConnected: false,
  isAvailableToConnect: false,
};

const fttbAddressSlice = createSlice<TFttbAddressSlice, TFttbAddressSliceReducer>({
  name: 'fttbAddress',
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setRegion: (state, action) => {
      state.checkConnectionRes.region = action.payload.region;
      state.checkConnectionRes.regionId = action.payload.regionId;
      // Сброс зависимых значений при обнулении инпута
      if (!action.payload.region) {
        state.checkConnectionRes.street = undefined;
        state.checkConnectionRes.streetId = undefined;
        state.checkConnectionRes.house = undefined;
        state.checkConnectionRes.houseId = undefined;
        state.checkConnectionRes.flat = undefined;
        state.isDisabledStreet = true;
        state.isDisabledHouse = true;
        state.isDisabledFlat = true;
        state.isAvailableToConnect = false;
      }
    },
    setStreet: (state, action) => {
      state.checkConnectionRes.street = action.payload.street;
      state.checkConnectionRes.streetId = action.payload.streetId;
      // Сброс зависимых значений при обнулении инпута
      if (!action.payload.street || !action.payload.streetId) {
        state.checkConnectionRes.house = undefined;
        state.checkConnectionRes.houseId = undefined;
        state.checkConnectionRes.flat = undefined;
        state.isDisabledHouse = true;
        state.isDisabledFlat = true;
        state.isAvailableToConnect = false;
      }
    },
    setHouse: (state, action) => {
      state.checkConnectionRes.house = action.payload.house;
      state.checkConnectionRes.houseId = action.payload.houseId;
      // Сброс зависимых значений при обнулении инпута
      if (!action.payload.house || !action.payload.houseId) {
        state.checkConnectionRes.flat = undefined;
        state.isDisabledFlat = true;
        state.isAvailableToConnect = false;
      }
    },
    setFlat: (state, action) => {
      state.checkConnectionRes.flat = action.payload.flat;
      if (!action.payload.flat) {
        state.isAvailableToConnect = false;
      }
    },
    changeStreetInputState: (state, action) => {
      state.isDisabledStreet = action.payload;
    },
    changeHouseInputState: (state, action) => {
      state.isDisabledHouse = action.payload;
    },
    changeFlatInputState: (state, action) => {
      state.isDisabledFlat = action.payload;
    },
    cancelIsAvailableToConnect: (state) => {
      state.isAvailableToConnect = false;
    },
    /* Установка данных в fttbAddressSlice из кеша (persisted) */
    setPersistedFttbAddressSlice: (_, action) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(getCheckConnection.fulfilled, (state, action) => {
      const isHasRequest =
        action.payload.View.HasRequestOnAddress || action.payload.View.IsHasRequestConvergent;

      state.isHasRequest = isHasRequest;
      state.isAvailableToConnect =
        action.payload.View.ConnectionState === EConnectionCheckResult.AVAILABLE_TO_CONNECT &&
        !isHasRequest;
      state.isAlreadyConnected =
        action.payload.View.ConnectionState === EConnectionCheckResult.ALREADY_CONNECTED;
      state.isNotAvailable =
        action.payload.View.ConnectionState !== EConnectionCheckResult.AVAILABLE_TO_CONNECT &&
        action.payload.View.ConnectionState !== EConnectionCheckResult.ALREADY_CONNECTED;
    });
  },
});

export const {
  setInitialState,
  setRegion,
  setStreet,
  setHouse,
  setFlat,
  changeHouseInputState,
  changeStreetInputState,
  changeFlatInputState,
  cancelIsAvailableToConnect,
  setPersistedFttbAddressSlice,
} = fttbAddressSlice.actions;

export const fttbAddressReducer = fttbAddressSlice.reducer;
