// since you never know what fields will be missing
export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[] ?
    RecursivePartial<U>[] // NOTE: when value is array
  : T[P] extends object ?
    T[P] extends (...args: any[]) => any ?
      T[P]
    : RecursivePartial<T[P]>
  : T[P]; // NOTE: otherwise
};

export function replaceNullWithUndefined<T extends Record<string, any>>(input: T) {
  return Object.entries(input).reduce(
    (acc, entry) => ({
      ...acc,
      [entry[0]]: entry[1] === null ? undefined : entry[1],
    }),
    {} as { [P in keyof T]: NonNullable<T[P]> },
  );
}

export function getValuesOfEnum<PEnum extends Record<string, number | string>>(enumType: PEnum) {
  return Object.keys(enumType)
    .filter((key) => Number.isNaN(Number(key)))
    .map((key) => enumType[key]);
}
