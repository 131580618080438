import { emptyObj } from '@beef/utils';

/**
 * getCommonModalData: получение общего оббекта модального окна.
 */
export const getCommonModalData = (state) => state.modal;

/**
 * getCommonStoreContent: получение common-объекта
 * со статическими данными для полей RequestForm.
 */
export const getCommonStoreContent = (state) => state.common || emptyObj;

/**
 * getAddressOrderData: получение информации из полей в форме RequestForm.
 */
export const getAddressOrderData = (state) => state.orderTariffUserData;

/**
 * getTariffDetailsData: получение данных по пресетам.
 */
export const getTariffDetailsData = (state) => state.orderTariffDetailsData;

/**
 * getCommonStoreData: общий стейт.
 * @param state
 */
export const getCommonStoreData = (state) => state;
