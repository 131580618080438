import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Subscription from 'pages/ProductCard/Tariff/components/Subscription';
import TariffSummary from 'pages/ProductCard/Tariff/components/TariffSummary';
import {
  benefitsShape,
  packageItemShape,
  priceItemShape,
  priceShape,
  subscriptionShape,
  usefulLinkShape,
} from 'pages/ProductCard/Tariff/containers/tariffPropTypes';

import AvailablePackages from '../AvailablePackages';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffCardDetails = ({
  packages,
  subscription,
  usefulLinks,
  packagesTitle,
  benefits,
  prices,
  pageTitle,
  price,
  summaryContent,
  isConnected,
  sticky,
  isAuthenticated,
  availableInShop,
  showDiscountParams,
}) => (
  <div className={cx('wrap')}>
    <div className={cx('details')}>
      {packages?.length > 0 && (
        <div className={cx('availablePackages')}>
          <AvailablePackages packages={packages} title={packagesTitle} />
        </div>
      )}
      {subscription && (
        <div className={cx('subscription')}>
          <Subscription
            benefits={benefits}
            isAuthenticated={isAuthenticated}
            isConnected={isConnected}
            subscription={subscription}
          />
        </div>
      )}
    </div>
    <div className={cx('summary')}>
      <TariffSummary
        availableInShop={availableInShop}
        isConnected={isConnected}
        pageTitle={pageTitle}
        price={price}
        prices={prices}
        showDiscountParams={showDiscountParams}
        sticky={sticky}
        subscriptionContent={subscription.content}
        summaryContent={summaryContent}
        usefulLinks={usefulLinks}
      />
    </div>
  </div>
);

TariffCardDetails.propTypes = {
  /* заголовок блока с пакетами */
  packagesTitle: PropTypes.string,
  /* заголовок страницы */
  pageTitle: PropTypes.string,

  /* подключен ли тариф */
  isConnected: PropTypes.bool,
  /* прилипание к верху страницы */
  sticky: PropTypes.bool,
  /* доступен для подключения */
  availableInShop: PropTypes.bool,
  /* авторизация */
  isAuthenticated: PropTypes.bool,

  /* доступные пакеты */
  packages: PropTypes.arrayOf(packageItemShape),
  /* контент для подписок */
  subscription: subscriptionShape,
  /* полезные ссылки */
  usefulLinks: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.arrayOf(usefulLinkShape),
  }),
  /* доп плюшки тарифа */
  benefits: PropTypes.arrayOf(benefitsShape),
  /* блок с ценами */
  prices: PropTypes.arrayOf(priceItemShape),
  /* абонентская плата */
  price: priceShape,
  /* контент для блока с данными по тарифу */
  summaryContent: PropTypes.object,
  /* показ скидки на тарифе */
  showDiscountParams: PropTypes.object,
};

export default TariffCardDetails;
