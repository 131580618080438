import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import { Tooltip } from '@beef/legacy-ui-kit';
import QRCode from 'qrcode';

// Hooks
import classNames from 'classnames/bind';

import { useIsMobile } from 'utils/hooks/useIsMobile';

// Styles
import SocialButtons from 'pages-components/SocialButtons/SocialButtons';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ESim = ({
  appStoreButtons,
  texts: { description, descriptionTooltip },
  esim: { qrCodeUrl, appButtons },
}) => {
  const isMobile = useIsMobile();
  const qrImageEl = useRef(null);

  useEffect(() => {
    if (!isMobile && qrCodeUrl) {
      QRCode.toDataURL(qrCodeUrl).then((url) => {
        qrImageEl.current.src = url;
      });
    }
  });

  return (
    <div className={cx('component')}>
      <div className={cx('description')}>
        <span dangerouslySetInnerHTML={{ __html: description }} />
        <Tooltip>
          <div dangerouslySetInnerHTML={{ __html: descriptionTooltip }} />
        </Tooltip>
      </div>

      {qrCodeUrl && (
        <div className={cx('qrCode')}>
          <img alt="" ref={qrImageEl} src="" />
        </div>
      )}

      {/* Кнопки приложений и предложения */}
      {appButtons && (
        <div className={cx('buttonsGroup')}>
          <SocialButtons data={appStoreButtons} />
        </div>
      )}
    </div>
  );
};

ESim.propTypes = {
  appStoreButtons: PropTypes.object,
  esim: PropTypes.shape({
    qrCodeUrl: PropTypes.string,
    appButtons: PropTypes.object,
  }),
  texts: PropTypes.shape({
    description: PropTypes.string,
    descriptionTooltip: PropTypes.string,
  }),
};

export default ESim;
