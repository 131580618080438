import axios from 'axios';

import { apiBasket } from './constants';

/** Более точного интерфейса по ответу от корзины не нашлось */
export interface IBasketResponse {
  actionStats: {
    quantity: number;
  };
  client: {
    clientType: unknown;
  };
  id: string;
  items: any[];
  promoCodeInfo: unknown;
  regionId: number;
  regionPath: string;
}

/**
 * Получение содержимого корзины
 */
export const fetchBasket = async (basketId: string) => {
  try {
    const { data } = await axios.get<IBasketResponse>(`${apiBasket}/${basketId}`);

    return data;
  } catch (error) {
    return String(error);
  }
};
