import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Status } from '@beef/legacy-ui-kit';
import qs from 'query-string';
import classNames from 'classnames/bind';

import SimOrderPopup from 'pages-components/SimOrderPopup/SimOrderPopup';
import documentReady from 'utils/document-ready';

import styles from '../../ProductCard.pcss';

const cx = classNames.bind(styles);

/**
 * Default connect buttons with old popups.
 */
class TariffConnectButton extends PureComponent {
  componentDidMount() {
    const { isPostpaid, isConnected } = this.props.data;

    if (!isPostpaid && !isConnected && qs.parse(window.location.search).connect === 'true') {
      documentReady(() => this.showConnectionPopup());
    }
  }

  showConnectionPopup() {
    window.QA.Beeline.Pages.MobileTariff.Bill.connect();
  }

  render() {
    const {
      isConnected,
      availableForConnect,
      isArchived,
      warningText,
      isAuthenticated,
      availableInShop,
      connectButtonLink,
      connectButton,
    } = this.props.data;

    return (
      <div>
        {isConnected ?
          <div className={cx('connectButtons')}>
            <Status className={['success', 'centeredText']}>Мой тариф</Status>
          </div>
        : null}

        {!isConnected && !availableForConnect && !isArchived ?
          <div className={cx('connectButtons')}>
            <Status className={['attention']} emoji="statusFail">
              Тариф доступен для подключения в офисе
            </Status>
          </div>
        : null}

        {warningText ?
          <div className={cx('connectButtons')}>
            <Status className={['attention']} emoji="statusFail" text={warningText} />
          </div>
        : null}

        {!isAuthenticated ?
          <div className={cx('connectButtons')}>
            {availableInShop && !isArchived ?
              <Button className="big" onClick={() => this.simPopup.triggerPopup(true)} wide>
                Купить SIM в один клик
              </Button>
            : null}
            {availableInShop && availableForConnect && !isConnected ?
              <div className={cx('secondaryButton')}>
                <Button
                  className="default"
                  href={connectButtonLink}
                  onClick={connectButtonLink ? null : this.showConnectionPopup}
                  wide
                >
                  {connectButton}
                </Button>
              </div>
            : null}
            {!availableInShop && availableForConnect && !isConnected ?
              <Button
                className="big"
                href={connectButtonLink}
                onClick={connectButtonLink ? null : this.showConnectionPopup}
                wide
              >
                {connectButton}
              </Button>
            : null}
          </div>
        : null}

        {isAuthenticated ?
          <div className={cx('connectButtons')}>
            {availableForConnect && !isConnected ?
              <Button
                className="big"
                href={connectButtonLink}
                onClick={connectButtonLink ? null : this.showConnectionPopup}
                wide
              >
                {connectButton}
              </Button>
            : null}
          </div>
        : null}

        {isArchived ?
          <div className={cx('connectButtons')}>
            <Status className={['centeredText']}>Тариф в архиве</Status>
          </div>
        : null}

        <SimOrderPopup
          includeNumber={this.props.includeNumber}
          number={this.props.newNumber}
          ref={(e) => (this.simPopup = e)}
          tariffAlias={this.props.data.alias}
          tariffId={this.props.data.id}
          tariffTitle={this.props.data.pageTitle}
          texts={{ title: `Быстрый заказ тарифа «${this.props.data.pageTitle}»` }}
        />
      </div>
    );
  }
}

TariffConnectButton.propTypes = {
  data: PropTypes.object.isRequired,
  includeNumber: PropTypes.bool,
  newNumber: PropTypes.string,
};

export default TariffConnectButton;
