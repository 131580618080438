import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { isIE } from 'utils/device';
import AdaptiveWrapper from 'pages-components/AdaptiveWrapper';

import BackgroundVideo from './BackgroundVideo';
import styles from './Background.pcss';

const cx = classNames.bind(styles);

class Background extends PureComponent {
  state = {
    isIE: false,
  };

  componentDidMount() {
    if (isIE()) {
      this.setState({ isIE: true });
    }
  }

  render() {
    const { videoSrc, imageDesktop, imageMobile, loop, stretchVideo, overlay } = this.props;

    return (
      <div
        className={cx('component', 'absolute', 'stretch', { stretchVideo })}
        style={this.state.isIE ? { backgroundImage: `url(${imageDesktop})` } : {}}
      >
        <picture>
          <source media="(min-width: 768px)" srcSet={imageDesktop} />
          <img
            alt=""
            className={cx('absolute', 'stretch', 'fitted')}
            loading="lazy"
            src={imageMobile}
          />
        </picture>

        {videoSrc && /(ogg|webm|mp4)/.test(videoSrc) && (
          <AdaptiveWrapper>
            <></>
            <BackgroundVideo
              className={cx('absolute', 'stretch', { stretchVideo })}
              coverImage={imageDesktop}
              loop={loop}
              ratio={this.props.ratio}
              src={videoSrc}
              stretch={stretchVideo}
            />
          </AdaptiveWrapper>
        )}

        {overlay && (
          <div className={cx('absolute', 'stretch', 'overlay')} style={{ background: overlay }} />
        )}
      </div>
    );
  }
}

Background.propTypes = {
  imageDesktop: PropTypes.string,
  imageMobile: PropTypes.string,
  videoSrc: PropTypes.string,
  loop: PropTypes.bool,
  overlay: PropTypes.string,
  ratio: PropTypes.number,
  stretchVideo: PropTypes.bool,
};

export default Background;
