import { combineReducers } from '@reduxjs/toolkit';
import { composeStoreInitializer } from '@beef/redux';

import { cart, products } from './reducers';

const rootReducer = combineReducers({
  content: (state = {}) => state,
  cart,
  products,
});

export const initializeStore = composeStoreInitializer(rootReducer);
