import React, { ReactNode, memo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TGroupContainer = {
  children: ReactNode;
};

/** Обертка над логической секцией блока (BillGroups, TotalInfo и т.п.) */
export const GroupContainer = memo<TGroupContainer>(({ children }) => (
  <div className={cx('wrapper')}>{children}</div>
));
