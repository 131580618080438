import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TAddressContent = {
  description?: string;
  title?: string;
};

/** Контентная часть, включающая адресс пользователя, на странице настройки ШПД */
export const AddressContent: FC<TAddressContent> = ({ title, description }) => (
  <div className={cx('wrapper')}>
    {title && <Text size="size5-m">{title}</Text>}
    {description && <Text size="size5-r-s">{description}</Text>}
  </div>
);
