import { useEffect, useRef } from 'react';

import { useOnScreen } from 'utils/hooks/useOnScreen';

import { pushRandomNumberShow } from '../analytics/numbers';

export const useRandomNumberShowEvent = (number) => {
  const containerRef = useRef(null);
  const isVisible = useOnScreen(containerRef);

  useEffect(() => {
    if (isVisible && number) {
      pushRandomNumberShow(number);
    }
  }, [isVisible, number]);

  return containerRef;
};
