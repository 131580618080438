/** Получение пакетов тарифа (Гб, Мин) */
export const apiGetPacketsUrl = '/api/v1/constructors/packets/';

/** Изменение пакета тарифа (Гб или Мин) */
export const apiChangePacketUrl = '/api/v1/constructors/change-packet/';

/** Получение мобильных опций тарифа */
export const apiGetOptionsUrl = '/api/v1/constructors/options/';

/** Базовый URL для запроса валидации номера mnp */
export const apiValidationUrl = '/customers/products/mobile/services/validatemnporder/';

/** Базовый URL для запроса результирующего чека ЕК (total bill) */
export const apiGetTotalBillUrl = '/api/v1/constructors/get-total-bill/';

/** Базовый URL для подключения/обновления тарифа в АЗ */
export const apiConnectTariffUrl = '/api/v1/constructors/connect-tariff/';
