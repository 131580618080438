import { useEffect } from 'react';

import { throwAsyncError } from 'utils/errors';

import { useHandleAvailableSchedule } from '../components/hooks/useHandleAvailableSchedule';
import { useAppSelector } from '../../../../store';

/** Хук подготовки пропов для компонента выбора слотов монтажника ШПД */
export const usePrepareSpecialistVisitContent = () => {
  /* Если пользовательский тип равен 1 ("НЗ c ШПД" то после страницы
   настроек тарифа будет вызываться метод для получения слотов монтажника (onSchedule) */
  const { onSchedule } = useHandleAvailableSchedule();
  useEffect(() => {
    onSchedule().catch((e: Error) => throwAsyncError(e));
  }, []);

  const contentProps = useAppSelector(
    (state) => state.common.content.fmcSettings?.calendarVisitPage,
  );
  const availableSchedule = useAppSelector((state) => state.specialistVisit.availableSchedule);

  return {
    contentProps,
    availableSchedule,
  };
};
