import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { Tooltip } from '@beef/legacy-ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FeeNote = ({ text, tooltip }) => (
  <div className={cx('wrap')}>
    <Text color="grey90" key={text} size="size6-r">
      <span dangerouslySetInnerHTML={{ __html: text }} />
      {tooltip && (
        <Tooltip className={cx('tooltip')}>
          <span dangerouslySetInnerHTML={{ __html: tooltip }} />
        </Tooltip>
      )}
    </Text>
  </div>
);

FeeNote.propTypes = {
  text: PropTypes.string,
  tooltip: PropTypes.string,
};

export default FeeNote;
