import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { LayoutGroup, motion } from 'framer-motion';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { CWriteOff, TCWriteOff } from '../CWriteOff';
import { CAccordion, TCAccordion } from '../CAccordion';
import { CDisableContainer, TCDisableContainer } from '../CDisableContainer';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCMoreDetails = {
  conditions?: TCAccordion;
  disabling?: TCDisableContainer;
  isBlockDetailsHidden?: boolean;
  writeOff?: TCWriteOff;
};

/** Блок подробной информации в чеке ЕК */
export const CMoreDetails: FC<TCMoreDetails> = ({
  writeOff,
  disabling,
  conditions,
  isBlockDetailsHidden,
}) =>
  !isBlockDetailsHidden ?
    <LayoutGroup>
      <motion.section className={cx('wrapper')} layout>
        {/* Блок с текущим списанием и периодом */}
        <CWriteOff {...writeOff} />
        {/* Блок с детализацией (аккордеон) */}
        <CAccordion {...conditions} />
        {/* Блок с отключаемыми опциями */}
        <CDisableContainer {...disabling} />
      </motion.section>
    </LayoutGroup>
  : null;
