import React from 'react';
import { Button } from '@beef/legacy-ui-kit';

import ServiceCard from '../ProductCard/Service';

const autopayUrl = '/customers/how-to-pay/popolnenie-scheta/#/AutoPayment/AddAutoPayment';

const AutoPay = ({ ...props }) => {
  const connectButtonExtension = () => (
    <div style={{ textAlign: 'center', margin: '0 20px' }}>
      <Button
        className="big"
        href={
          props.data?.isAuthenticated ?
            autopayUrl
          : `/login?returnUrl=${encodeURIComponent(autopayUrl)}`
        }
        transformer
      >
        Подключить
      </Button>
    </div>
  );

  return <ServiceCard {...props} connectButtonExtension={connectButtonExtension} />;
};

AutoPay.defaultProps = {
  data: {},
};

export default AutoPay;
