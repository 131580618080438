import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useHighlyFlamableUnsafeHackyIdCrutch } from './helpers';

/**
 * FIXME: Нужно заменить useId из react-18
 * Очень грязный хак, чтобы id, полученный при SSR не перезатирался локальным при гидрации
 * See: https://github.com/reactjs/rfcs/pull/46#issuecomment-385182716
 */
const useServerChildren = (renderChildren) => {
  const isClientSideRendering = typeof window !== 'undefined';
  const shouldUseServerChildren = useRef(isClientSideRendering);
  useEffect(() => {
    shouldUseServerChildren.current = false;
  }, []);

  return shouldUseServerChildren.current ?
      {
        dangerouslySetInnerHTML: { __html: '' },
        suppressHydrationWarning: true,
      }
    : { children: renderChildren() };
};

export const InjectCSS = ({ name, children, renderCSS }) => {
  const id = useHighlyFlamableUnsafeHackyIdCrutch(name);
  return (
    <>
      <style type="text/css" {...useServerChildren(() => renderCSS(id))} />
      {children(id)}
    </>
  );
};

InjectCSS.propTypes = {
  name: PropTypes.string,
  children: PropTypes.func,
  renderCSS: PropTypes.func,
};
