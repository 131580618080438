import { TPersistData } from '../store/persistor';

/** Функция подготовки данных из кеша (Persist) */
export const preparePersistCache = (regionId: number, constructorPersistState: TPersistData) => {
  /* Получение сохраненных данных при их наличии */
  const persistedTotal = constructorPersistState?.total ?? undefined;
  const persistedStepper = constructorPersistState?.stepper ?? undefined;
  const persistedOptions = constructorPersistState?.options ?? undefined;
  const persistedFttbPresets = constructorPersistState?.fttbPresets ?? undefined;
  const persistedFttbAddress = constructorPersistState?.fttbAddress ?? undefined;

  return {
    persistedTotal,
    persistedStepper,
    persistedOptions,
    persistedFttbPresets,
    persistedFttbAddress,
  };
};
