import React, { memo } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { INFINITY_CONSTANT } from 'pages/ProductCard/constants';
import Icon from 'pages-components/Icon';
import { totalUnitPropTypesShape } from 'pages/ProductCard/Tariff/components/Check/constants';

import styles from '../styles.pcss';

const cx = classNames.bind(styles);

const Presets = memo(({ total }) => (
  <div className={cx('check-row__main')}>
    <Text color="grey60" size="size5-r-s" testName="Check_gbytes">
      {total?.gbytes?.value === INFINITY_CONSTANT ?
        <Icon className={cx('icon')} name="infinityConstructor" />
      : total?.gbytes?.value}
    </Text>
    <div className={cx('check-row__text', 'check-row__text--unit')}>
      <Text color="grey60" size="size5-r-s">
        {total?.gbytes?.unit}
      </Text>
    </div>
    <div className={cx('check-row__text', 'check-row__text--plus')}>
      <Text color="grey60" size="size5-r-s">
        &nbsp;&#43;&nbsp;
      </Text>
    </div>
    <Text color="grey60" size="size5-r-s" testName="Check_minutes">
      {total?.minutes?.value === INFINITY_CONSTANT ?
        <Icon className={cx('icon')} name="infinityConstructor" />
      : total?.minutes?.value}
    </Text>
    <div className={cx('check-row__text', 'check-row__text--unit')}>
      <Text color="grey60" size="size5-r-s">
        {total?.minutes?.unit}
      </Text>
    </div>
  </div>
));

Presets.propTypes = {
  total: PropTypes.shape({
    gbytes: PropTypes.shape(totalUnitPropTypesShape),
    minutes: PropTypes.shape(totalUnitPropTypesShape),
  }),
};

export default Presets;
