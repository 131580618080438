import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Benefit, Benefits } from '@beef/legacy-ui-kit';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const BenefitsWrapper = ({ benefits }) => (
  <Benefits>
    {benefits.map(({ image, text }, index) => (
      <Benefit emoji={image} key={index}>
        <div className={cx('text-wrap')}>
          <Text size="size7-r">{text}</Text>
        </div>
      </Benefit>
    ))}
  </Benefits>
);

BenefitsWrapper.propTypes = {
  benefits: PropTypes.array.isRequired,
};

export default BenefitsWrapper;
