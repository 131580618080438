import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchValidateMnp } from 'constructorV1/api/fetchValidateMnp';

/** Тип запроса (params) */
type TValidateMnpReq = {
  phone: string;
};

/** Тип ответа */
export type TValidateMnpRes = {
  Errors?: Record<string, any>;
  IsSucceeded: boolean;
  View?: {
    IsExistingCtn: boolean;
  };
  camelCase?: boolean;
};

/** Запрос на валидацию введенного Mnp номера пользователем */
export const validateMnp = createAsyncThunk<TValidateMnpRes, TValidateMnpReq>(
  'mnp/validateRequest',
  async (params, { signal, rejectWithValue }) => {
    try {
      return (await fetchValidateMnp({ params, signal })).data;
    } catch (e) {
      return rejectWithValue({ IsSucceeded: false });
    }
  },
);
