import React, { FC } from 'react';
import { Skeleton } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент-скелетон для блока подключенного ШПД блока */
export const ConnectedTileSkeleton: FC = () => <Skeleton className={cx('wrapper')} />;
