import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Modal } from '@beef/ui-kit';

import { PartnerDetails } from '../PartnerDetails';

// Constants
import { POPUP_STEPS } from '../../constants';

// Utils
import { DetailsPopupWrapper } from '../DetailsPopupWrapper';
import { ErrorPopupWrapper } from '../ErrorPopupWrapper';

const PartnerPopup = ({ onPopupSwitch, errorCodeValue, id, step }) => (
  <Modal isCentered isOpen onClose={onPopupSwitch} size="m">
    {step === POPUP_STEPS.details && (
      <DetailsPopupWrapper id={id}>
        <PartnerDetails />
      </DetailsPopupWrapper>
    )}
    {step === POPUP_STEPS.error && (
      <ErrorPopupWrapper errorCodeValue={errorCodeValue} id={id} onPopupSwitch={onPopupSwitch} />
    )}
  </Modal>
);

PartnerPopup.propTypes = {
  onPopupSwitch: PropTypes.func,
  errorCodeValue: PropTypes.string,
  id: PropTypes.number,
  step: PropTypes.string,
};

export { PartnerPopup };
