import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Heading, Status } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Grid from 'pages-components/Grid';

// Style
import styles from '../styles.pcss';

const cx = classNames.bind(styles);

const NotAllowedStep = ({
  onClose,
  data: { title, params, statusText, buttonConfirmText, buttonCancelText, paymentUrl },
}) => (
  <div>
    <Heading isStyled={false} level={1}>
      <span dangerouslySetInnerHTML={{ __html: title }} />
    </Heading>

    <div className={cx('content')}>
      <div className={cx('status')}>
        <Status className={['error']}>
          <p dangerouslySetInnerHTML={{ __html: statusText }} />
        </Status>
      </div>

      {params && (
        <Grid.ProductParameters className={cx('paramsGroup')}>
          {params.map((item, key) => (
            <div className={cx('params')} key={key}>
              <div className={cx('description')}>{item.description}</div>
              <div className={cx('value')}>{item.value}</div>
            </div>
          ))}
        </Grid.ProductParameters>
      )}

      <div className={cx('buttonsGroup')}>
        <Button className={cx('big')} color="light" onClick={onClose} wide>
          {buttonCancelText}
        </Button>
        <Button className={cx('big')} href={paymentUrl} wide>
          {buttonConfirmText}
        </Button>
      </div>
    </div>
  </div>
);

NotAllowedStep.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.shape({
    params: PropTypes.array,
    title: PropTypes.string,
    statusText: PropTypes.string,
    buttonCancelText: PropTypes.string,
    buttonConfirmText: PropTypes.string,
    paymentUrl: PropTypes.string,
  }),
};

export default NotAllowedStep;
