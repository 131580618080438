import React from 'react';

import { PartnerSubscriptions } from './components/PartnerSubscriptions/index';
import { ProductsProvider } from './packages/context/provider';

interface IPartnershipProductsProps {
  onPartnershipClick: () => void;
  // NOTE: SOC (service order code) - serves as tariff id
  soc?: string;
}

const PartnershipProducts: React.FC<IPartnershipProductsProps> = ({ onPartnershipClick, soc }) => (
  <ProductsProvider onPartnershipClick={onPartnershipClick} soc={soc}>
    <PartnerSubscriptions />
  </ProductsProvider>
);

export { PartnershipProducts };
