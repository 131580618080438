import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';

const STICKY_SPEED = 220;
const STICKY_PADDING = 10;
const GROUP_WITH_GRADIENT_CLASSNAME = 'groupWithGradient';

class MainCatalogMobileHoc extends Component {
  componentDidMount() {
    // Контейнеры с тарифами + обертка-контейнер ScrollContainer;
    this.scrollContainer = document.querySelector('.scrollBlock'); // Компонент ScrollContainer;
    this.groupTitleArray = document.querySelectorAll('#groupTitle');

    if (this.scrollContainer) {
      this.scrollContainer.addEventListener('scroll', this.onScroll);
    }
  }

  /**
   * Вспомогательная функция для нахождения
   * размера и позиции элемента.
   * */
  getElemBoundingRect = (elem) => {
    const { width, right, left } = elem.getBoundingClientRect();
    return { width, right, left };
  };

  /**
   * Функция скрывает Arrow в зависимости
   * от расположений контента.
   * */
  onScroll = () => {
    requestAnimationFrame(this.moveHeading);
  };

  /** Функция отвечает за прокрутку заголовка в блоке Digital Тарифов */
  moveHeading = () => {
    for (let index = 0; index < this.groupTitleArray.length; index++) {
      this.getStickyCoords(index);
    }
  };

  /** Высчитывает координады sticky заголовка; */
  getStickyCoords = (nodeIndex) => {
    const { left } = this.getElemBoundingRect(this.groupTitleArray[nodeIndex]);
    const coords = (left * -1) / STICKY_SPEED;
    const padding = coords < 1 ? STICKY_PADDING : 0;

    this.setAnimation(coords, nodeIndex, padding);
  };

  /** Устанавливаем стили для анимации заголовка; */
  setAnimation = (coords, nodeItem, padding) => {
    this.groupTitleArray[nodeItem].style.left = `${coords}px`;
    this.groupTitleArray[nodeItem].style.position = 'sticky';
    this.groupTitleArray[nodeItem].style.paddingLeft = `${padding}px`;
    this.groupTitleArray[nodeItem].style.position = '-webkit-sticky';
  };

  filterGroupWithGradient = (tariffsGroups) => tariffsGroups.filter((item) => item.isHot);

  render() {
    const { children, tariffs } = this.props;

    // Если есть поле isHot, отрисовываем блок с градиентом;
    const isHotGroup = this.filterGroupWithGradient(tariffs.groups);

    return cloneElement(children, {
      ...this.props,
      groupWithGradientClassName: isHotGroup && GROUP_WITH_GRADIENT_CLASSNAME,
      tariffs: tariffs.groups,
    });
  }
}

MainCatalogMobileHoc.propTypes = {
  tariffs: PropTypes.array,
};

export default MainCatalogMobileHoc;
