import { INFINITY } from './constants';

/**
 * Функция sampleSteps делает выборку значений размера (size) левой части
 * для стандартного компонента RangeSlider в виде массива.
 * @returns {array}
 */
export const sampleSteps = (steps) => steps?.map((step) => step.size);

/**
 * Функция rightSampleSteps делает выборку значений цены (price)
 * для стандартного компонента RangeSlider в виде массива.
 * @returns {array}
 */
export const rightSampleSteps = (steps, activeTab) =>
  steps?.map((step) => step?.stepPeriods[activeTab]?.price);

/**
 * Функция chooseBenefit определяет текущий текст бенефита
 * для компонента AutoProlong
 * @returns {string}
 */
export const chooseBenefit = (
  hasZTariff,
  autoProlong,
  currentIndex,
  isChangeTariff,
  currentTabIsInfinite,
) => {
  if (hasZTariff) {
    if (isChangeTariff && currentIndex === 0) {
      return currentTabIsInfinite ?
          autoProlong?.textWithoutTermHasTariff
        : autoProlong?.textWithTermHasTariff;
    }
    return currentTabIsInfinite ?
        autoProlong?.textWithoutExpirationTariffOff
      : autoProlong?.textWithExpirationTariffOff;
  } else {
    return currentTabIsInfinite ?
        autoProlong?.textWithoutExpirationTariffOff
      : autoProlong?.textWithExpirationTariffOff;
  }
};

/**
 * Функция currentRightText определяет текущий текст
 * для правой части RangeSlider
 * @returns {string}
 */
export const currentRightText = (slide, currentIndex, activeTabPeriod) => {
  if (!slide?.remainingSize && currentIndex === 0) {
    return;
  }
  const unit = (slide?.unit).toLowerCase() === 'штук' ? 'SMS' : slide?.unit;
  return slide?.remainingSize && slide?.initialPeriod && currentIndex === 0 ?
      `${slide?.remainingSize} ${unit} ${slide?.initialPeriod}`
    : slide?.tabs[activeTabPeriod]?.expirationPeriod;
};

/**
 * Функция createPackageParams создает объект с данными
 * по пакетам, исходя из выбранных условий в RangeSlider, для дальнейшей
 * отправки их в результирующий объект
 * @returns {object}
 */
export const createPackageParams = (slide, currentIndex, enabled, activeTabPeriod) => ({
  [slide.type.toLowerCase()]: {
    value: slide.steps[currentIndex]?.size,
    initialPeriod: slide.initialPeriod,
    unit: slide.unit,
    checked: enabled,
    remainingSize: slide.remainingSize,
    initialSize: slide.initialSize,
    period: slide?.tabs[activeTabPeriod]?.expirationPeriod,
    soc: slide?.steps[currentIndex]?.stepPeriods[activeTabPeriod]?.soc,
    price: slide?.steps[currentIndex]?.stepPeriods[activeTabPeriod]?.price,
    unitPrice: slide?.steps[currentIndex]?.stepPeriods[activeTabPeriod]?.unit,
    defaultEnabled: slide?.steps[currentIndex]?.stepPeriods[activeTabPeriod]?.defaultEnabled,
    socAutoProlong: slide?.steps[currentIndex]?.stepPeriods[activeTabPeriod]?.autoProlongSoc,
  },
});

export const setChangeSlide =
  (slide, currentIndex, setCurrentIndex, defaultPeriodTab, setActiveTabPeriod) => (value) => {
    const slideSizeValues = slide?.steps?.map((slideSteps) => slideSteps.size);
    const index = slideSizeValues.indexOf(value);
    if (index === 0) {
      setActiveTabPeriod(defaultPeriodTab);
    }
    if (currentIndex !== index) {
      setCurrentIndex(index);
    }
  };

export const getSliderTemplate = ({
  slide,
  hasZTariff,
  autoProlong,
  currentIndex,
  isChangeTariff,
  activeTabPeriod,
  setCurrentIndex,
  defaultPeriodTab,
  setActiveTabPeriod,
  persistentEnabledAutoProlong,
}) => {
  const steps = sampleSteps(slide.steps);
  const lastIndex = slide.steps.length - 1;
  const isInfinity = slide.steps[currentIndex]?.size === INFINITY;
  const rightSteps = rightSampleSteps(slide.steps, activeTabPeriod);
  const isPersistentEnabledAutoProlong =
    !persistentEnabledAutoProlong || (persistentEnabledAutoProlong && !!currentIndex);
  const defaultEnabled = slide?.steps[0]?.stepPeriods[0]?.defaultEnabled;
  const rightText = currentRightText(slide, currentIndex, activeTabPeriod);
  const rightUnit = slide?.steps[currentIndex]?.stepPeriods[activeTabPeriod]?.unit;
  const isDisableAutoProlong = defaultEnabled && !isChangeTariff && currentIndex === 0;
  const hasAutoProlong = slide?.steps[currentIndex]?.stepPeriods[activeTabPeriod]?.defaultEnabled;
  const currentTabIsInfinite = slide?.steps[currentIndex]?.stepPeriods[activeTabPeriod]?.isInfinite;
  const textBeforeValue =
    hasZTariff && slide.initialSize && isChangeTariff && !!currentIndex ? '+' : null;
  const handleChange = setChangeSlide(
    slide,
    currentIndex,
    setCurrentIndex,
    defaultPeriodTab,
    setActiveTabPeriod,
  );
  const benefit = chooseBenefit(
    hasZTariff,
    autoProlong,
    currentIndex,
    isChangeTariff,
    currentTabIsInfinite,
  );
  const value =
    slide.initialSize && currentIndex === 0 ? slide.initialSize : slide.steps[currentIndex]?.size;

  return {
    value,
    steps,
    benefit,
    lastIndex,
    rightUnit,
    rightText,
    isInfinity,
    rightSteps,
    handleChange,
    defaultEnabled,
    hasAutoProlong,
    textBeforeValue,
    isDisableAutoProlong,
    currentTabIsInfinite,
    isPersistentEnabledAutoProlong,
  };
};
