export const REDIRECT_URL_TEMPLATE = '{{endpoint}}?redirectUrl={{redirectUrl}}';

/**
 * Функция, которая вынимает полный домен из урла
 * (Например "https://translate.yandex.ru" станет "translate.yandex.ru")
 */
export const getFullDomain = (url: string): string => {
  const processedUrl = url.replace(/^(\w+:\/\/)/, '');
  if (!processedUrl) return '';
  return processedUrl.split('/')[0];
};

/**
 * Функция, которая превращает домен в список, упорядоченный по уровню
 * (Например "https://translate.yandex.ru" станет ["ru", "yandex", "translate"])
 */
export const getDomainLevels = (domain: string): string[] => domain.split('.').reverse();

/**
 * Функция, которая убирает домены выше второго уровня
 * (Например "https://translate.yandex.ru" станет "https://yandex.ru")
 *
 * TODO: Переназвать и сделать поддержку доменов больше 3 уровня
 */
export const getRedirectUrl = (url: string) => {
  const fullDomain = getFullDomain(url);
  const thirdLevelDomain = getDomainLevels(fullDomain)[2];
  return url.replace(`${thirdLevelDomain}.`, '');
};

/**
 * Функция, которая убирает параметры и хэши из УРЛа
 */
export const getUrlWithoutParams = (url: string) => {
  const _url = new URL(url);

  _url.hash = '';
  _url.search = '';

  return _url.toString();
};
