import React, { FC, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { CellAction } from '@beef/ui-kit';

import { setParametersAction } from 'pages/SearchResultsPage/store/actions';
import { getCurrentCategory, isSelectedCategory } from 'pages/SearchResultsPage/store/selectors';

import { CategoriesWithAnalytics } from '../CategoriesWithAnalytics';
import { CategoryPropsType } from '../types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _Categories: FC<CategoryPropsType> = ({
  categories,
  setFilter,
  selectedCategoryId,
  showAllCategoriesLink,
}) => (
  <>
    {categories.map((category) => (
      <Fragment key={category.id}>
        <CategoriesWithAnalytics>
          <CellAction
            id={category.id}
            isActive={selectedCategoryId === category.id}
            leftTitle={category.name?.toLowerCase()}
            onClick={() => setFilter(category.id)}
            type="radio"
          />
        </CategoriesWithAnalytics>
        {showAllCategoriesLink && category.children?.length > 0 && (
          <div className={cx('children')}>
            <MobileCategory categories={category.children} />
          </div>
        )}
      </Fragment>
    ))}
  </>
);

const mapDispatchToProps = {
  setFilter: (value: string) => setParametersAction('categories', value || ''),
};
// @ts-expect-error state not typed in ts yet
const mapStateToProps = (state) => ({
  selectedCategoryId: getCurrentCategory(state),
  showAllCategoriesLink: isSelectedCategory(state),
});

export const MobileCategory = connect(mapStateToProps, mapDispatchToProps)(_Categories);
