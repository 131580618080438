import React from 'react';
import PropTypes from 'prop-types';

// Styles
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffsGroupTitle = ({ titleId, title, className }) => (
  <div className={cx('sub-title', className)} id={titleId}>
    {title && <span>{title}</span>}
  </div>
);

TariffsGroupTitle.propTypes = {
  className: PropTypes.string,
  titleId: PropTypes.string,
  title: PropTypes.string,
};

export default TariffsGroupTitle;
