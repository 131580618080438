import axios from 'axios';

import { redirectServiceUrl, statusNot200 } from 'pages/Services/RedirectServiceCardLanding/const';
import { ContentQp } from 'pages/Services/RedirectServiceCardLanding/types';

export const getContentQp = async () => {
  const url = redirectServiceUrl.qpContent;
  try {
    const { data, status } = await axios.get(url);
    if (status === 200) {
      return data as ContentQp;
    }
  } catch (e) {
    throw new Error(e);
  }
  throw new Error(statusNot200);
};
