import { ymPushWithUrl } from 'utils/ym';

import { NUMBERS_CATEGORIES } from '../fancyNumberChoiceHelper';

export const ymSimFavoriteNumbers = () => ymPushWithUrl('SimFavoriteNumbers');
export const ymSimStartMnpProcess = () => ymPushWithUrl('SimStartMnpProcess');

const CATEGORY_KEYS = {
  [NUMBERS_CATEGORIES.spetsialnyie]: 'SimOrderFreeNumber',
  [NUMBERS_CATEGORIES.bronzovie]: 'SimOrderBronzeNumber',
  [NUMBERS_CATEGORIES.serebryanie]: 'SimOrderSilverNumber',
  [NUMBERS_CATEGORIES.zolotie]: 'SimOrderGoldNumber',
};

export const ymSimOrderNumber = (category) => {
  ymPushWithUrl(CATEGORY_KEYS[category]);
};
