import { TARIFF_LAYOUTS } from 'pages/ProductCard/Tariff/tariffTypes';

/** Массив типов layoutType, в которых нужно показывать информационный баннер вместо тела конструктора */
const exceptionLayoutTypes: Array<keyof typeof TARIFF_LAYOUTS> = ['constructor'];

/** Тип параметров для функции defineAuthInternetLive */
export type TExceptionTariff = {
  alias: string;
  isAuthenticated: boolean;
  layoutType: keyof typeof TARIFF_LAYOUTS;
};

/** Список алиасов на исключение */
const excAliasList = [
  'internet-live',
  'internet-live-smart-device',
  'internet-live-tablet',
  'internet-live-router',
  'internet-live-auto',
];

/** Функция позволяет определить, требуется ли вместо основного тела конструктора показывать информационный баннер с QR */
export const exceptionTariff = ({
  isAuthenticated,
  layoutType,
  alias,
}: TExceptionTariff): boolean => {
  /* Если пользователь авторизован (isAuthenticated) и
   * массив исключений (exceptionLayoutTypes) содержит текущий layoutType, переходим к проверке alias.
   *
   * p.s. На момент написания функции, новая АЗ еще НЕ заехала в прод, поэтому layoutType у тарифа Internet Live такой же,
   * как и у тарифа Up - constructor */
  if (isAuthenticated && exceptionLayoutTypes.includes(layoutType)) {
    /* Если текущий alias включен в excAliasList ("internet-live-*"), вернем true, что позволит в дальнейшем отрендерить компонент информационного баннера,
     * вместо основного тела конструктора */
    return excAliasList.includes(alias);
  }

  return false;
};
