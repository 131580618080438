/* eslint-disable max-len */
import React from 'react';

export default {
  texts: {
    initialForm: {
      header: {
        head: 'Вход в личный кабинет',
      },
      tabsList: [
        {
          id: 'mobileIdInitial',
          title: 'Мобильный ID',
          icon: (
            <svg
              fill="none"
              height="20"
              viewBox="0 0 20 20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M7.39019 5L9.48961 11.0116L11.5907 5H15.833V15.8333H13.1475V8.49416L10.6633 15.8333H8.71843L6.19075 8.43226V15.8333H3.33301V5H7.39019Z"
                fill="white"
                fillRule="evenodd"
              />
              <path
                clipRule="evenodd"
                d="M9.58288 0C12.1571 0 14.1112 1.34004 14.3623 3.33333H11.6737C11.3901 2.57379 10.6163 2.05064 9.57434 2.05064C8.5307 2.05064 7.76883 2.57379 7.4887 3.33333H4.80348C5.0563 1.34004 7.0241 0 9.58288 0Z"
                fill="white"
                fillRule="evenodd"
              />
            </svg>
          ),
        },
        {
          id: 'sms',
          title: 'По SMS',
        },
        {
          id: 'password',
          title: 'С постоянным паролем',
        },
      ],
      mobileIdInitial: {
        placeholder: '+7 000 000-00-00',
        label: 'Введите номер телефона',
        button: 'Продолжить',
      },
      sms: {
        initial: {
          placeholder: '+7 000 000-00-00',
          label: 'Введите номер телефона',
          button: 'Отправить SMS',
        },
        code: {
          header: {
            sending: 'Отправляем SMS...',
            success: 'Пароль отправлен вам в SMS',
            repeat: 'Отправить SMS повторно',
            blocked:
              'Лимит отправки одноразового пароля превышен. Отправить пароль повторно можно будет через 24 часа',
          },
          placeholder: 'Пароль из SMS',
          invalidFormat: 'Введите 5 цифр из SMS',
          button: 'Войти',
          label: 'Пароль из SMS',
        },
      },
      password: {
        inputs: [
          {
            name: 'login',
            label: 'Введите логин',
            placeholder: 'Логин',
            tooltip: 'Номер лицевого счёта, домашнего интернета или ТВ, или ваш собственный логин',
          },
          {
            name: 'password',
            label: 'Введите пароль',
            placeholder: 'Пароль',
          },
        ],
        button: 'Войти',
      },
    },
    smsCaptcha: {
      captchaDesc: 'Укажите символы с картинки',
      placeholder: 'Символы с картинки',
      button: 'Далее',
      cancel: 'Отмена',
    },
    links: {
      howToGetLogin: 'Как получить логин и пароль?',
      enterBySMS: 'Войти с помощью SMS',
      enterByPassword: 'Войти с постоянным паролем',
      enterByOTPassword: 'Войти с одноразовым паролем',
      getOneTimePassword: 'Получить пароль в SMS',
      getPassword: 'Я не помню пароль',
    },
  },
};
