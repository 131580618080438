import { useMemo } from 'react';

import { useAppSelector } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

import { sendPassiveYm } from '../../PresetsPickers/tempYmUtils';
import { usePrepareModalContent } from './usePrepareModalContent';
import { useIsHighPreset } from '../../../../hooks/useIsHighPreset';
import { getActiveCharacterPassive } from '../../../../utils/getActiveCharacterPassive';

/** Подготовка данных для PassiveContainer */
export const usePreparePassive = () => {
  const isHighPreset = useIsHighPreset();

  /* Массив доступных для подключения персонажей */
  const tariffs = useAppSelector((state) => state?.tariffsInfo) ?? [];

  /* Сок текущего активного персонажа */
  const characterSoc = useAppSelector((state) => state.total?.characterSoc) ?? '';

  /* Контент попапа пассивок */
  const passivePopups = useAppSelector((state) => state.common?.content?.passivePopups);

  /* Пропы для компонента Modal в Passive */
  const { openModal, modalContainerProps } = usePrepareModalContent();

  /* Пропы для блока Passive (плашка под пресетом Гб).
   * Включает в себя общий попап passivePopups (описание пассивок),
   * passiveContentProps (иконка и текст плашки) и backgroundColor (фон плашки) */
  const passiveContainerProps = {
    passivePopups,
    ...getActiveCharacterPassive(tariffs, characterSoc, isHighPreset),
  };

  /* Имя активного персонажа */
  const activeCharacterName =
    useMemo(
      () =>
        tariffs.find(
          (tariff) => tariff?.[isHighPreset ? 'highOptionSoc' : 'optionSoc'] === characterSoc,
        )?.name,
      [characterSoc, isHighPreset],
    ) ?? '';

  /* Способность активного персонажа */
  const activeCharacterPower = passiveContainerProps.passiveContentProps.passiveText ?? '';

  const handleClick = () => {
    openModal();
    /* Отправка метрики по клику по блоку пассивок */
    sendPassiveYm(activeCharacterName, activeCharacterPower);
  };

  /* Общие пропы для компонента Passive */
  return { modalContainerProps, handleClick, ...passiveContainerProps };
};
