export const selectTimeCapsule = (state) => state.form;

export const selectIsAuthorized = (state) =>
  state?.meta?.isAuthorized || state?.content?.isAuthorized;
export const selectIsPhoneUser = (state) => !(state?.meta?.isFttb || state?.content?.isFttb);
export const selectIsSmsSent = (state) => state?.meta?.isSmsSent || state?.content?.isSmsSent;

export const selectContent = (state) => state.content;
export const selectTitle = (state) => selectContent(state).title;
export const selectInfo = (state) => selectContent(state).info;

export const selectSmsContent = (state) => selectContent(state).sms;

export const selectButtonContent = (state) => selectContent(state).button;

export const selectData = (state) => state.data;

export const selectPhoneData = (state) => selectData(state)?.phone;
export const selectSmsData = (state) => selectData(state)?.sms;
