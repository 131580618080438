import { MouseEvent, useState } from 'react';
import { shallowEqual } from '@beef/utils';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { SizeTypes } from '@beef/ui-kit/Modal/types';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { fetchSubmitCallbackRequest } from '../../../../api/fetchSubmitCallbackRequest';
import { goToFinalPageStep } from '../../../../store/slices/stepperSlice';
import { useThrowAsyncError } from '../../../../hooks/useThrowAsyncError';
import { CALLBACK_CASE_TILE_SUPPORT } from '../../../../constants';

/**  Хук подготовки данных компонента об устаревшем поколении ап и показ ссылки на обновленный ап */
export const usePrepareArchivedWithFttbProps = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();
  /* Номер телефона абонента */
  const phoneNumber = useAppSelector((state) => state.common.userInfo?.activeCTN) ?? '';
  const caseTile = CALLBACK_CASE_TILE_SUPPORT;

  /* Получение общих данных */
  const {
    buttonText = '',
    buttonTextExtra = '',
    description = '',
    link = '',
    title = '',
    notificationButtonText = '',
    notificationText = '',
  } = useAppSelector(
    (state) => ({
      buttonText: state.common.content.archivedWithoutFttbModal?.buttonText,
      buttonTextExtra: state.common.content.archivedWithoutFttbModal?.buttonTextExtra,
      description: state.common.content.archivedWithoutFttbModal?.description,
      link: state.common.content.archivedWithoutFttbModal?.link,
      title: state.common.content.archivedWithoutFttbModal?.title,
      notificationButtonText: state.common.content.archivedWithoutFttbNotification?.buttonText,
      notificationText: state.common.content.archivedWithoutFttbNotification?.text,
    }),
    shallowEqual,
  );

  /** Стейт модального окна подтверждения */
  const [isOpenModal, setIsOpenModal] = useState(false);
  const closeModal = () => setIsOpenModal(false);
  const openModal = () => setIsOpenModal(true);

  const onClose = (e: MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault();
    closeModal();
  };

  const onCallbackSent = (e: MouseEvent<HTMLAnchorElement>) => {
    onClose(e);
    dispatch(fetchSubmitCallbackRequest({ phoneNumber, caseTile }))
      .unwrap()
      .then(() => dispatch(goToFinalPageStep()))
      .catch((err: Error) => throwAsyncError(err));
  };

  /** Пропы для модального окна */
  const modalContainerProps = {
    isOpen: isOpenModal,
    size: 'm' as SizeTypes,
    onClose: closeModal,
    modalActionBarProps: {
      link,
      buttonText,
      buttonTextExtra,
      onClick: onCallbackSent,
      onClickExtra: onClose,
    },
  };

  const modalProps = {
    modalContainerProps,
    modalHeaderProps: {
      title,
      description,
    },
  };

  /** Пропы для плашки нотивикации */
  const notificationProps = {
    buttonText: notificationButtonText,
    text: notificationText,
    onClick: openModal,
  };

  return { modalProps, notificationProps };
};
