import { MediaBlock } from '@beef/smart-kit';
import React from 'react';
import classNames from 'classnames/bind';

import { selectMediaBlockData } from '../../store/selectors';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const ContentBlockWithMedia: React.FC<ReturnType<typeof selectMediaBlockData>[number]> = ({
  title = '',
  description = '',
  content,
  logo,
  background,
}) => (
  <div className={cn('container')}>
    <MediaBlock
      background={background}
      content={content}
      description={description}
      logo={logo}
      title={title}
    />
  </div>
);
