import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import Icon from 'pages-components/Icon';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SubscriptionStatusText = ({ connected, suspended, account, bonus, subscriptionTexts }) => {
  if (account || bonus) {
    return (
      <>
        {account && (
          <div className={cx('user-email')}>
            <Text size="size6-r">{account}</Text>
          </div>
        )}
        {(bonus || bonus === 0) && (
          <div
            className={cx('bonus-count', {
              'bonus-count--disabled': suspended,
            })}
          >
            <div className={cx('bonus-count__icon')}>
              <Icon name="yandexPlusWhite" />
            </div>
            <Text color="white" size="size6-r">
              {bonus}
            </Text>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {(connected || suspended) && (
        <div className={cx('header__aside-icon')}>
          {connected && <Icon name="yandexPlusGradient" />}
          {suspended && <Icon name="yandexPlusGrey" />}
        </div>
      )}
      {subscriptionTexts && (
        <Text size="size5-r-s">
          {connected && <>{subscriptionTexts.subscriptionActive}</>}
          {suspended && <>{subscriptionTexts.subscriptionSuspend}</>}
        </Text>
      )}
    </>
  );
};

SubscriptionStatusText.propTypes = {
  /* подписка подключена */
  connected: PropTypes.bool,
  /* подписка подключена, но заблокирована */
  suspended: PropTypes.bool,
  /* почта из яндекса */
  account: PropTypes.string,
  /* кол-во баллов */
  bonus: PropTypes.number,
  /* тексты статусов */
  subscriptionTexts: PropTypes.object,
};

export default SubscriptionStatusText;
