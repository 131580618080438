import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { AnimatedWrapper } from 'constructorV1/components/tempComponents/AnimatedWrapper';
import { usePrepareAlertTile } from 'constructorV1/components/tempComponents/AlertContainer/components/AlertTile/hooks/usePrepareAlertTile';
import { ModalContent } from 'constructorV1/components/tempComponents/AlertContainer/components/ModalContent';
import { TAlertTile } from 'constructorV1/store/commonSlice/types';

import { ModalForm } from '../ModalForm';
import { renderTextWithLinks } from './utils/renderTextWithLink';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

/** Плитка напоминания под пресетами ЕК */
export const AlertTile: FC<TAlertTile> = ({ text, popupData }) => {
  const { isOpen, handleOpenPopup, onClose, discountPercent } = usePrepareAlertTile(popupData);

  return (
    <AnimatedWrapper>
      <Text className={cx('text')} size="size4-r">
        {renderTextWithLinks(handleOpenPopup, discountPercent, text)}
      </Text>
      {popupData && (
        <ModalForm isOpen={isOpen} onClose={onClose}>
          <ModalContent {...{ ...popupData, onClose, discountPercent }} />
        </ModalForm>
      )}
    </AnimatedWrapper>
  );
};
