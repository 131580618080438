import React from 'react';
import { compose } from '@beef/redux';
import { connect } from 'react-redux';
import { withMountListener } from '@beef/react';
import { ImageCard } from '@beef/smart-kit';

import {
  fetchFinalStories,
  selectFinalStoriesList,
  selectFinalStoriesLoading,
  selectIsVoteAvailable,
  setCurrentStory,
  setCurrentVideo,
} from '../../store/finalStories';
import { StoriesListLayoutComponent as StoriesListLayout } from '../StoriesList/StoriesListLayout';
import { StoryCardLayout } from '../StoryCard';

const FinalStoriesListLayout = ({
  title,
  buttonText,
  isLoading,
  list,
  placeholder,
  onStorySelect,
  onVideoSelect,
}) => (
  <StoriesListLayout isLoading={isLoading} title={title}>
    {list.map((story) => (
      <StoryCardLayout
        buttonText={buttonText}
        image={story.image}
        key={story.id}
        onButtonClick={() => onStorySelect(story.id)}
        onImageClick={() => onVideoSelect(story.id)}
        title={story.name}
      />
    ))}
    {placeholder && (
      <ImageCard
        bordered={false}
        button={{
          text: placeholder.buttonText,
          link: placeholder.link,
          variant: 'tertiary',
          target: '_blank',
        }}
        img={{ src: placeholder.image }}
        size="s"
        title={placeholder.title}
      />
    )}
  </StoriesListLayout>
);

const mapStateToProps = (state, props) => ({
  buttonText: selectIsVoteAvailable(state) ? props.buttonText : props.buttonReadText,
  list: selectFinalStoriesList(state),
  isLoading: selectFinalStoriesLoading(state),
});

const mapDispatchToProps = {
  onMount: fetchFinalStories,
  onStorySelect: setCurrentStory,
  onVideoSelect: setCurrentVideo,
};

export const FinalStoriesList = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withMountListener,
)(FinalStoriesListLayout);
