import { TInput, selectLegacyModalState, selectYandexSubscription } from '../../selectors';
import { TariffUpMetaData } from '../../store/TariffUpMetaData';
import { TariffUpModals } from '../../store/TariffUpModals';
import { EYandexResponseType } from '../../types';
import { onConfirm as activateSubscription } from '../ActivateModal/handler';
import { postConnectYandexSubscriptionRequest } from '../api';
import { selectEndpoints } from '../selectors';

export { onCancel } from '../handler';

export const onConfirm = async () => {
  try {
    const tariffUpValue = [TariffUpMetaData.Products] as unknown as TInput;
    const yandexSubscription = selectYandexSubscription(tariffUpValue);

    // TODO: Move error names into enum
    if (!yandexSubscription) throw new Error('No yandex subscription found');

    const { id } = yandexSubscription;

    // NOTE: Loading (Error is needed as just a valid type)
    TariffUpModals.YandexPopupType = EYandexResponseType.Error;
    TariffUpModals.IsLoading = true;
    TariffUpModals.IsOpen = true;

    const state = selectLegacyModalState();
    const endpoints = selectEndpoints(state);

    if (!endpoints || !endpoints.connect) {
      throw new Error('No endpoints in the content');
    }

    const url = endpoints.connect;

    const data = await postConnectYandexSubscriptionRequest({ url, id });

    if (!data.isSucceeded || !data.code) {
      // TODO: Move error message to enum
      throw new Error('Unsuccessful response');
    }

    await activateSubscription();
  } catch (e) {
    console.error(e);
    TariffUpModals.YandexPopupType = EYandexResponseType.Error;
  } finally {
    TariffUpModals.IsLoading = false;
  }
};
