import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { pathOr } from 'ramda';

import store from 'store';
// Actions
import { sendCvmAnalyticsEvent, timerCvmAnalyticsEvent } from 'utils/analytics/cvmTariffAnalytics';

import { toggleDownsellServicePopup } from '../../actions/downsellService/toggleDownsellServicePopup';
import { replaceDownsellTariff } from '../../actions/downsellTariff/replaceDownsellTariff';

// Utils

// События должны запускаться при первом просмотре downsell;
const ANALYTICS_STATE = ['openChannel', 'landViewed'];

class DownsellNewHOC extends Component {
  componentDidMount() {
    const { data } = this.props;

    // CVM Analytics;
    const isDownsellOffer = ['callBack', 'question', 'offers'].includes(data.type);
    if (isDownsellOffer) {
      ANALYTICS_STATE.map(this.setAnalytics);
    }
    // -------------
  }

  /** CVM Analytics */
  setAnalytics = (state) => {
    const { data, place } = this.props;

    if (!place && place !== 'downsell') return;

    // Если это тип оффера callBack или Question мы создаем массив вручную;
    // Иначе мы бурем массив из поля offers;

    const isOffersObject = ['callBack', 'question'].includes(data.type);
    const cvmOffersParams =
      isOffersObject && data.subgroupId && data.campId ?
        [
          {
            subgroupId: data.subgroupId,
            campId: data.campId,
          },
        ]
      : data.offers;

    switch (state) {
      case 'open':
      case 'refuse':
      case 'activation':
      case 'openChannel':
      case 'landApproved':
        this.props.downsellTariffEvent(cvmOffersParams, state, 'downsale');
        break;
      case 'landViewed':
        this.props.timerDownsellTariffEvent(cvmOffersParams, state, 'downsale');
        break;
    }
  };

  /**
   * Функция определяет тип предложений от USSS и вызывает соответствующий метод;
   * Если есть один из offerType мы открываем Услуги, а иначе открывает тарифы и акции;
   * ------------------------------------
   * Если это Мультиофферинг, функция смотрит на параметр connectResponseType: tariff/service
   * от которого зависит вызов подключения. Это параметр гинерит Бэк;
   * ------------------------------------
   * @param paramsObject - Объект с параметрами переданный из карточки;
   */
  handlerOnDownsellConfirm = (paramsObject) => {
    const { payload, url, canRetry, tariffData } = paramsObject;
    const offerType = payload && payload.offer && payload.offer.offerType;
    const isServiceResponseType =
      payload && payload.offer && payload.offer.connectResponseType === 'service';
    const isOfferTypeTariff = offerType === 'tariff';
    const isServiceOfferType = offerType && ['service', 'discount'].includes(offerType);

    // Вызывает CVM аналитику;
    if (payload.state) this.setAnalytics(payload.state);
    // ----------------------

    if (isServiceOfferType || isServiceResponseType) {
      this.props.toggleDownsellServicePopup(url, payload, true);
    } else if (isOfferTypeTariff) {
      // создает индивидуальный step для ADS тарифа конструктор
      const step =
        paramsObject?.payload?.constructorId ?
          'constructorAdsTariffCardModal'
        : 'downsellTariffCardModal';
    } else {
      this.props.onDownsellConfirm(url, tariffData, payload, canRetry);
    }
  };

  render() {
    const { children, data } = this.props;

    return cloneElement(children, {
      onSubmit: this.handlerOnDownsellConfirm,
      setAnalytics: this.setAnalytics,
      data,
    });
  }
}

DownsellNewHOC.propTypes = {
  toggleDownsellServicePopup: PropTypes.func,
  onDownsellConfirm: PropTypes.func,
  downsellTariffEvent: PropTypes.func,
  timerDownsellTariffEvent: PropTypes.func,
  data: PropTypes.shape({}),
  place: PropTypes.string,
};

const mapStateToProps = ({ external }) => ({
  ...pathOr({}, ['tariffConnectionPopup'], external),
});

const ConnectedComponent = connect(mapStateToProps, (dispatch) => ({
  downsellTariffEvent: (arr, state, place) => sendCvmAnalyticsEvent(arr, state, place),
  timerDownsellTariffEvent: (arr, state, place) => timerCvmAnalyticsEvent(arr, state, place),
  toggleDownsellServicePopup: (url, serviceData, popupIsActive) =>
    dispatch(toggleDownsellServicePopup(url, serviceData, popupIsActive)),
  onDownsellConfirm: (url, tariffData, payload, canRetry) =>
    dispatch(replaceDownsellTariff(url, tariffData, payload, canRetry)),
}))(DownsellNewHOC);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
