import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { PricePreload } from '../../../PricePreload';
import { useSelectLoadingStatus } from '../ButtonContainer/selectors/useSelectLoadingStatus';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TPrice = {
  toPay?: string;
  totalPrice?: string;
};

/** Блок с ценой */
export const Price = memo<TPrice>(({ toPay, totalPrice }) => {
  const { isLoading } = useSelectLoadingStatus();
  return (
    <div className={cx('wrapper')}>
      {toPay && <Text size="size3-m">{toPay}</Text>}
      {isLoading ?
        <PricePreload />
      : <Text size="size1-m">{totalPrice}</Text>}
    </div>
  );
});
