import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SortButton = ({ direction, children, className, ...props }) => (
  <EmptyButton className={cx('component', direction && 'active', direction, className)} {...props}>
    {direction && <Icon className={cx('icon')} name="sort" />}
    {children}
  </EmptyButton>
);

SortButton.propTypes = { direction: PropTypes.string };

export default SortButton;
