import React from 'react';

// Components
import classNames from 'classnames/bind';

import TariffCard from 'pages-components/TariffCard';

// Styles
import styles from './styles.pcss';

// Animation Data Map
import animationMap from './animationMap';

const cx = classNames.bind(styles);

/**
 * Компонент-обертка с анимацией для карточки тарифа, используется в модальном окне КО.
 * Содержит в себе TariffCard;
 */
const TariffCardAnimation = (props) => (
  <div className={cx('tariffCardAnimation')}>
    <div className={cx('animation')}>
      {animationMap.map((item, key) => {
        const array = new Array(item.length).fill(null).map((x, i) => i);
        return (
          <div className={cx(item.type)} key={key}>
            {array.map((_, index) => (
              <>
                {item.type === 'dot' ?
                  <div className={cx(item.weight)} key={index} />
                : <img alt={item.type} key={index} src={item.image} />}
              </>
            ))}
          </div>
        );
      })}
    </div>
    <TariffCard {...props} />
  </div>
);

export default TariffCardAnimation;
