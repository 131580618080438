import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Style
import classNames from 'classnames/bind';
import { Button, Checkbox, Heading, RadioButton } from '@beef/legacy-ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Компонент отвечает за отрисовку блока опросника в модальном окне;
 * @param data - Модель с данными полученными с бэка;
 * @param onSubmit - Callback подтверждения ответов для отправки на бэк;
 * @param setAnalytics - Callback аналитика CVM передается из HOC компонента;
 */
const Questionnaire = ({ data, onSubmit, setAnalytics }) => {
  const [checked, setChecked] = useState({});

  const multiple = data.multipleAnswers;
  const buttonActive = Object.keys(checked).find((key) => checked[key]);
  const submit = (event) => {
    event.preventDefault();

    const answerIds = Object.keys(checked).filter((item) => checked[item]);

    onSubmit(
      data.answerUrl,
      {
        questionId: data.questionId,
        answerIds,
      },
      true,
    );
    setChecked({});
    setAnalytics('activation');
  };

  const onSelect = (event) => {
    const id = event.target.name;

    setChecked({
      ...(multiple ? checked : {}),
      [id]: !checked[id],
    });
  };

  const CheckComponent = multiple ? Checkbox : RadioButton;

  return (
    <div>
      <Heading level={1}>
        <span dangerouslySetInnerHTML={{ __html: data.title }} />
      </Heading>
      <Heading level={3}>
        <span dangerouslySetInnerHTML={{ __html: data.description }} />
      </Heading>

      <form className={cx('answers')} onChange={onSelect} onSubmit={submit}>
        {data.answers.map((answer, key) => (
          <CheckComponent checked={checked[answer.id]} key={key} name={answer.id}>
            {answer.text}
          </CheckComponent>
        ))}
        <div className={cx('button')}>
          <Button className="big" disabled={!buttonActive} transformer>
            {data.buttonText}
          </Button>
        </div>
      </form>
    </div>
  );
};

Questionnaire.propTypes = {
  data: PropTypes.array,
  onSubmit: PropTypes.func,
  setAnalytics: PropTypes.func,
};

export default Questionnaire;
