import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Input, Link, QuestionIcon, Tooltip } from '@beef/ui-kit';

import { debounce } from 'utils/timed-functions';
import { onShowPasswordClick } from 'pages/Login/Authorization2021/utils/common';

import styles from './styles.pcss';
import { YMAuthEvent } from '../../utils/ym';

const cx = classNames.bind(styles);

class PasswordForm extends Component {
  state = {
    login: this.props.login,
    password: this.props.password,
  };

  componentDidMount() {
    if (this.state.login && !this.state.password) {
      this.props.handleInput(this.state.login);
    }
    if (!this.state.login && this.state.password) {
      this.props.handleInput(this.state.password);
    }
    if (this.state.login && this.state.password) {
      this.props.handleInput(this.state.login + this.state.password);
    }
    if (!this.state.login && !this.state.password) {
      this.props.handleInput('');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /* бросаем наверх вэлью чтобы в экшнбаре модалки кнопка могла дизэйблиться когда инпуты не заполнены */
    if (prevState.login !== this.state.login || prevState.password !== this.state.password) {
      this.props.handleInput(this.state.login + this.state.password);
    }
  }

  componentWillUnmount() {
    this.props.handleInput('');
    this.handleInputFillDebounced.cancel();
  }

  handleInputFillDebounced = debounce((event) => {
    if (event.target.name === 'login') {
      YMAuthEvent.passwordLoginFill();
    }
    if (event.target.name === 'password') {
      YMAuthEvent.passwordPasswordFill();
    }
  }, 2000);

  handleChange = (event) => {
    if (event.target.name === 'login') {
      this.setState({ login: event.target.value });
      this.props.setAuthLogin(event.target.value);
    }
    if (event.target.name === 'password') {
      this.setState({ password: event.target.value });
      this.props.setAuthPassword(event.target.value);
    }
    this.handleInputFillDebounced(event);
  };

  handleFocus = (event) => {
    if (event.target.name === 'login') {
      YMAuthEvent.passwordLoginClick();
    }
    if (event.target.name === 'password') {
      YMAuthEvent.passwordPasswordClick();
    }
  };

  handleResetForm = () => {
    this.props.onResetPassword();
  };

  render() {
    const { login, password } = this.state;
    const { action, children, autofocus, formId } = this.props;
    const data = this.props.data || {};
    const passwordForm = data.initialForm.password.inputs || {};
    const linkText = data.links || {};

    return (
      <div className={cx('password-form')}>
        <form action={action} id={formId} method="POST">
          {passwordForm.map((input, index) => (
            <div className={cx('password-form__input')} key={input.name}>
              <Input
                extraAttributes={
                  autofocus && index === 0 ?
                    {
                      autoFocus: true,
                    }
                  : null
                }
                label={input.label}
                name={input.name}
                onChange={this.handleChange}
                onClick={(event, isTypePassword) => {
                  onShowPasswordClick(
                    event,
                    isTypePassword,
                    () => YMAuthEvent.passwordPasswordShow(),
                    () => YMAuthEvent.passwordPasswordHide(),
                  );
                }}
                onFocus={this.handleFocus}
                placeholder={input.placeholder}
                rightAside={
                  input?.tooltip && (
                    <Tooltip message={input.tooltip} position="left" variant="secondary">
                      <QuestionIcon size={20} />
                    </Tooltip>
                  )
                }
                /* type='' костыль для легаси страниц  */
                type={index === 0 ? '' : 'password'}
                value={index === 0 ? login : password}
              />
            </div>
          ))}
          <div className={cx('password-form__link')}>
            <Link href="#" isNoUnderline onClick={this.handleResetForm} size="size5-b">
              {linkText.getPassword}
            </Link>
          </div>
          {children}
        </form>
      </div>
    );
  }
}

PasswordForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  action: PropTypes.string,
  formId: PropTypes.string,
  handleInput: PropTypes.func,
  login: PropTypes.string,
  password: PropTypes.string,
  onResetPassword: PropTypes.func,
  setAuthLogin: PropTypes.func,
  setAuthPassword: PropTypes.func,
  autofocus: PropTypes.bool,
};

export default PasswordForm;
