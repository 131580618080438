import React from 'react';
import PropTypes from 'prop-types';
import { Emoji, Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from '../../styles.pcss';

const cx = classNames.bind(styles);

export const RenderSuccess = ({ texts }) => (
  <>
    <div className={cx('successEmoji')}>
      <Emoji className={cx('emoji')} name="statusOk" />
    </div>
    <Heading className={cx('popupHeading', 'success')} level={1}>
      <span dangerouslySetInnerHTML={{ __html: texts.successTitle }} />
    </Heading>
    <div
      className={cx('successContent')}
      dangerouslySetInnerHTML={{ __html: texts.sucessContent }}
    />
  </>
);

RenderSuccess.propTypes = {
  texts: PropTypes.shape({
    successTitle: PropTypes.string,
    sucessContent: PropTypes.string,
  }),
};
