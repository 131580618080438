/**
 * To be moved to beef
 */
import React from 'react';

const templateProcessor = (template, fn) =>
  template
    .split(/{{(\w+)}}/gi)
    // every element with odd index is literal
    .map((value, index) => (index % 2 === 1 ? fn(value, index) : value));

/**
 * add key to existing child, unless element is primitive and does not require a key
 */
const cloneWithKey = (child, key) =>
  typeof child !== 'string' ? React.cloneElement(child, { key }) : child;

/**
 * Meant to be backward compatible with old parser, allow react elements to be used
 * @param template - string template
 * @param vars - object of variables
 * @param keepArray - if all variables are strings, parser joins it in a single string,
 *   but with this option it keeps an array
 * @param fallback - how to handle variable that isn't provided in vars
 */
export const templateParser = (template, vars, { keepArray = false, fallback = () => '' } = {}) => {
  const literalsArr = templateProcessor(template || '', (key, index) =>
    cloneWithKey(vars[key] || fallback(key, vars), `${key}-${index}`),
  );
  if (keepArray || literalsArr.find((item) => typeof item !== 'string')) {
    return literalsArr;
  }
  return literalsArr.join('');
};

const isNumeric = (value) => typeof value === 'number' || /^-?\d+$/.test(value);

export const defaultsToPx = (value) => `${value}${isNumeric(value) ? 'px' : ''}`;

export const toTelHref = (phone = '') => `tel://${phone.replace(/\D/g, '')}`;

export const getPageTypeByUrl = () => {
  const pathname = window.location.href;
  const parts = pathname.split('/').filter((e) => e !== '');
  return parts[parts.length - 1];
};
