import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { LayoutGroup, motion } from 'framer-motion';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { useAppSelector } from 'constructorV1/store';
import { selectCBillContent } from 'constructorV1/store/selectors/selectCommon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCWriteOff = {
  price?: string;
  subTitle?: string;
};

/** Блок подробной информации (списанием) в общем чеке ЕК.
 * Содержит подпись (text), цену с юнитом (price) и период списания (subText) */
export const CWriteOff: FC<TCWriteOff> = ({ price, subTitle }) => {
  /* Контентный заголовок для блока списания */
  const { writeOffTitle: title } = useAppSelector(selectCBillContent);

  /* Если цена не указана, не показываем блок */
  if (!price) {
    return null;
  }

  return (
    <LayoutGroup>
      <motion.section className={cx('wrapper')} layout>
        <motion.div className={cx('wrapper__title')} layout>
          <Text align="left" color="primary" lineHeight={20} size="m" weight="regular">
            {title}
          </Text>
          <Text align="left" color="primary" lineHeight={20} size="m" weight="bold">
            {price}
          </Text>
        </motion.div>
        {subTitle && (
          <motion.div layout>
            <Text align="left" color="secondary" lineHeight={20} size="xs" weight="regular">
              {subTitle}
            </Text>
          </motion.div>
        )}
      </motion.section>
    </LayoutGroup>
  );
};
