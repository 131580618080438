import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { pushCheckAddressAction } from 'utils/ga';
import { getConnectionData } from 'pages/FTTB/selectors/connectionState';
import FullFormPopup from 'pages/FTTB/components/FullFormPopup';
import BackCallPopup from 'pages/FTTB/components/BackCallPopup';
import PopupRequestHouse from 'pages/AllInOneForm/components/PopupRequestHouse/PopupRequestHouse';
import AdaptiveWrapper from 'pages-components/AdaptiveWrapper';
import { connectionDataPropTypes } from 'pages/FTTB/constants';
import { getConnectionStateInitial } from 'pages/FTTB/actions';
import { tariffClickYM } from 'utils/analytics/tariffsYandexMetrica';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class CheckAddress extends Component {
  state = {
    fullFormPopupOpen: false,
    connectHousePopupIsOpen: false,
    backCallPopupOpen: false,
  };

  componentDidMount() {
    this.props.getConnectionData();
  }

  showBackCallPopup = () => {
    this.fullFormPopupClose();
    this.setState({ backCallPopupOpen: true });
  };

  closeBackCallPopup = () => {
    this.setState({ backCallPopupOpen: false });
  };

  fullFormPopupOpen = () => {
    this.setState({ fullFormPopupOpen: true });
    pushCheckAddressAction(this.props.data.fullFormBtnText);
  };

  fullFormPopupClose = () => this.setState({ fullFormPopupOpen: false });

  showPopupRequestHouse = () => {
    this.fullFormPopupClose();
    this.setState({ connectHousePopupIsOpen: true });
  };

  closePopupRequestHouse = () => this.setState({ connectHousePopupIsOpen: false });

  handleButtonClick = () => {
    tariffClickYM();
    if (this.props.forceShowBackCallPopup) {
      this.showBackCallPopup();
    } else {
      this.fullFormPopupOpen();
    }
  };

  renderForm() {
    const { title, subTitle, fullFormBtnText } = this.props.data;

    return (
      <div className={cx('checkAddressInner')}>
        <div className={cx('title')} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={cx('subTitle')} dangerouslySetInnerHTML={{ __html: subTitle }} />

        <div className={cx('checkAddressButtonWrapper')}>
          <AdaptiveWrapper>
            <Button className={cx('fullFormOpenBtn')} onClick={this.handleButtonClick}>
              <span dangerouslySetInnerHTML={{ __html: fullFormBtnText }} />
            </Button>
            <Button className={['big', cx('fullFormOpenBtn')]} onClick={this.handleButtonClick}>
              <span dangerouslySetInnerHTML={{ __html: fullFormBtnText }} />
            </Button>
          </AdaptiveWrapper>
        </div>
      </div>
    );
  }

  render() {
    const { fullFormPopupOpen, connectHousePopupIsOpen, backCallPopupOpen } = this.state;
    const {
      connectionData,
      data,
      orderFormData,

      promoTariff,
      connectingRequestUrl,

      isRedesign,

      backCallPopupData,
      shouldFetchSpecTariff,
      availableScheduleData,
      isFttbTv,
    } = this.props;

    const { street, house } = connectionData || {};
    const { backgroundImage, mobileBackgroundImage } = data;

    return (
      <div className={cx('checkAddress')}>
        <div
          className={cx('formWrapper', 'desktop')}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          {this.renderForm()}
        </div>
        <div
          className={cx('formWrapper', 'mobile')}
          style={{ backgroundImage: `url(${mobileBackgroundImage})` }}
        >
          {this.renderForm()}
        </div>

        {fullFormPopupOpen && (
          <div>
            <FullFormPopup
              availableScheduleData={availableScheduleData}
              connectingRequestUrl={connectingRequestUrl}
              connectionData={connectionData}
              data={orderFormData}
              isOpen={fullFormPopupOpen}
              onClose={this.fullFormPopupClose}
              onConnectHouse={this.showPopupRequestHouse}
              onOpenBackCallPopup={this.showBackCallPopup}
              promoTariff={promoTariff}
              shouldFetchSpecTariff={shouldFetchSpecTariff}
            />
          </div>
        )}
        <PopupRequestHouse
          house={house}
          onClose={this.closePopupRequestHouse}
          popupIsOpen={connectHousePopupIsOpen}
          street={street}
        />

        {backCallPopupOpen && (
          <BackCallPopup
            data={backCallPopupData}
            forceShowBackCallPopup={this.props.forceShowBackCallPopup}
            isFttbTv={isFttbTv}
            isOpen={backCallPopupOpen}
            onClose={this.closeBackCallPopup}
            redesign={isRedesign}
            showCaptcha={backCallPopupData.showCaptcha}
          />
        )}
      </div>
    );
  }
}

CheckAddress.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    fullFormBtnText: PropTypes.string,
    backgroundImage: PropTypes.string,
    mobileBackgroundImage: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  orderFormData: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  availableScheduleData: PropTypes.object,
  getConnectionData: PropTypes.func,
  connectionData: PropTypes.shape(connectionDataPropTypes),
  // eslint-disable-next-line react/forbid-prop-types
  promoTariff: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  backCallPopupData: PropTypes.object,
  connectingRequestUrl: PropTypes.string,

  isRedesign: PropTypes.bool,

  shouldFetchSpecTariff: PropTypes.bool,
  isFttbTv: PropTypes.bool,
  /* флаг для а/б тесте, показывать попап с обратной связью */
  forceShowBackCallPopup: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  connectionData: getConnectionData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getConnectionData: () => dispatch(getConnectionStateInitial),
});

const ConnectedCheckAddress = connect(mapStateToProps, mapDispatchToProps)(CheckAddress);

export default ConnectedCheckAddress;
