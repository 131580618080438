import {
  CHANGE_PARTNER_SUBSCRIPTION,
  DISABLE_SPECIAL_OFFER,
  GET_PRESET_OPTIONS,
  HANDLE_CHANGE_TOTAL,
  RESET_OPTIONS,
  SET_ACTIVE_PRESET_TAB,
  SET_ACTIVE_TAB,
  SET_AVAILABLE_PRESETS,
  SET_CONNECTED_AND_AVAILABLE_CONVERGENCE_TARIFFS,
  SET_CONVERGENCE_SETTINGS,
  SET_CURRENT_GB_SLIDE_INDEX,
  SET_DEFAULT_SLIDES_AND_OPTIONS,
  SET_FTTB_DISCOUNT_FROM_CONNECTED_PRESET,
  SET_PRESET_OPTIONS,
  SET_PRESET_PARAM,
  SET_SPECIAL_OFFER_INFO,
  SET_TARIFF_INFO,
  TOGGLE_UPSELL_OFFER,
} from './constants';

const setPresetParams = (payload: any) => ({
  type: SET_PRESET_PARAM,
  payload,
});

const setTariffInfo = (payload: any) => ({
  type: SET_TARIFF_INFO,
  payload,
});

const setDefaultInfoAndSlides = () => ({
  type: SET_DEFAULT_SLIDES_AND_OPTIONS,
});

const toggleUpsellOffer = () => ({
  type: TOGGLE_UPSELL_OFFER,
});

const handleChangeTotal = ({ id, price }: any) => ({
  type: HANDLE_CHANGE_TOTAL,
  id,
  price,
});

const disableSpecialOffer = () => ({
  type: DISABLE_SPECIAL_OFFER,
});

const setSpecialOfferInfo = (payload: any) => ({
  type: SET_SPECIAL_OFFER_INFO,
  payload,
});

const resetTotalOptions = () => ({
  type: RESET_OPTIONS,
});

const setActiveTab = (payload: any) => ({
  type: SET_ACTIVE_TAB,
  payload,
});

const changePartnerSubscription = (payload: any) => ({
  type: CHANGE_PARTNER_SUBSCRIPTION,
  payload,
});

const setCurrentGbSlideIndex = (payload: any) => ({
  type: SET_CURRENT_GB_SLIDE_INDEX,
  payload,
});

const setConvergence = (payload: any) => ({
  type: SET_CONVERGENCE_SETTINGS,
  payload,
});

const setFttbDiscountFromConnectedPreset = (payload: string | undefined) => ({
  type: SET_FTTB_DISCOUNT_FROM_CONNECTED_PRESET,
  payload,
});

const setAvailablePresets = (payload: any) => ({
  type: SET_AVAILABLE_PRESETS,
  payload,
});

const setConnectedConvergenceTariff = (payload: any) => ({
  type: SET_CONNECTED_AND_AVAILABLE_CONVERGENCE_TARIFFS,
  payload,
});

const setActivePresetOnTab = (payload: string) => ({
  type: SET_ACTIVE_PRESET_TAB,
  payload,
});

const getPresetOptions = (payload: any[]) => ({
  type: GET_PRESET_OPTIONS,
  payload,
});

const setPresetOptions = ({ presetOptions, options }: any) => ({
  type: SET_PRESET_OPTIONS,
  presetOptions,
  options,
});

export {
  changePartnerSubscription,
  disableSpecialOffer,
  getPresetOptions,
  handleChangeTotal,
  resetTotalOptions,
  setActivePresetOnTab,
  setActiveTab,
  setAvailablePresets,
  setConnectedConvergenceTariff,
  setConvergence,
  setCurrentGbSlideIndex,
  setDefaultInfoAndSlides,
  setFttbDiscountFromConnectedPreset,
  setPresetOptions,
  setPresetParams,
  setSpecialOfferInfo,
  setTariffInfo,
  toggleUpsellOffer,
};
