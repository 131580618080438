import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

type TitleType = {
  title?: string;
};

export const Title = ({ title = 'Условия' }: TitleType) => (
  <Text className={cn('title')} size="size1-m" tagType="h2">
    {title}
  </Text>
);
