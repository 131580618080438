import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

/**
 * Исправляет схлопывание последнего padding'а внутри overflow:scroll flex--контейнера
 */
export const ScrollPaddingFix = () => <div className={cn('fix')} />;
