import { useCallback } from 'react';

// Services
import getHousesForStreet from 'pages/FTTB/services/getHousesForStreet';

// constants
import { REQUEST_STATUS } from '../constants';

/**
 * useFetchHouse:запрос к API через getHousesForStreet
 * для получения дома подключения.
 * @param setChosenHouse
 * @param street
 * @param setStatusConnection
 * @returns {{fetchHouses: (function(*): Promise<AxiosResponse<any>>), onSelectHouseFromList: onSelectHouseFromList}}
 */
export const useFetchHouse = ({ streetID, setChosenHouse, setAddressChecked }) => {
  const fetchHouses = useCallback(
    (value) => {
      if (value) {
        return getHousesForStreet(streetID, value).then(({ data }) => {
          if (data?.length) {
            return data;
          }
          setAddressChecked(REQUEST_STATUS.houseIsNotFound);
          return [];
        });
      }

      return setAddressChecked(REQUEST_STATUS.isNone);
    },
    [streetID],
  );

  const onSelectHouseFromList = (value) => {
    if (!value.isConnected) {
      setAddressChecked(REQUEST_STATUS.isNotAvailable);
    }
    setChosenHouse(value);
  };

  return {
    fetchHouses,
    onSelectHouseFromList,
  };
};
