import React, { memo } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Text } from '@beef/ui-kit';

import { convertSum } from 'pages/ProductCard/utils';

import styles from '../styles.pcss';

const cx = classNames.bind(styles);

const MainPackagePrice = memo(({ discount, gbAndMinutesLongRate, info }) => {
  if (!discount) {
    return (
      <div className={cx('check-row__right')}>
        <div className={cx('check-row__text', 'check-row__text--right')}>
          <Text size="size5-r-s">
            {gbAndMinutesLongRate}
            &nbsp;
            {info.longRcRateUnit}
          </Text>
        </div>
      </div>
    );
  }
  return (
    <div className={cx('check-row__right')}>
      <div className={cx('check-row__text', 'check-row__text--right')}>
        <div className={cx('check-row__right-margin')}>
          <Text color="grey50" crossOutColor="grey50" isCrossedOut size="size6-r">
            {gbAndMinutesLongRate}
            &nbsp;
            {info.longRcRateUnit.replace(/\/мес/, '')}
          </Text>
        </div>
        &nbsp;
        <Text size="size5-r-s">
          {convertSum(discount)}
          &nbsp;
          {info.longRcRateUnit}
        </Text>
      </div>
    </div>
  );
});

MainPackagePrice.propTypes = {
  info: PropTypes.shape({
    longRcRateUnit: PropTypes.string,
  }),
  gbAndMinutesLongRate: PropTypes.number,
  discount: PropTypes.number,
};

export default MainPackagePrice;
