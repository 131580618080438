import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Categories = ({ categories, onCategoryClick, activeIndex }) => (
  <div className={cx('wrapper')}>
    {categories.map(({ name }, i) => (
      <EmptyButton
        className={cx('channelsCategory', { activeCategory: activeIndex === i })}
        key={i}
        onClick={() => onCategoryClick(i)}
      >
        {name}
      </EmptyButton>
    ))}
  </div>
);

Categories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  onCategoryClick: PropTypes.func,
  activeIndex: PropTypes.number,
};

export default memo(Categories);
