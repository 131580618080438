import { TOptionsSlice } from '../store/slices/optionsSlice/types';

/** Функция определения дефолтных активных опций */
export const getOptionsSocs = (options: TOptionsSlice) => {
  const socs: string[] = [];
  /* Проходимся по массиву опций и и щем активные */
  options?.forEach((optionGroup) => {
    /* Забираем соки активных родительских опций */
    const activeParentSocs = optionGroup.parentOptionsList
      ?.filter((opt) => opt?.isActive)
      .map((opt) => opt?.soc);
    if (activeParentSocs) {
      socs.push(...activeParentSocs);
    }

    /* Проходимся по дочерним опциям */
    optionGroup?.optionsList?.forEach((opt) => {
      /* Если опция активна и родительской опции нет или родительская опция не была добавлена, то
       пушим soc опции в результирующий массив */
      if (opt?.isActive && (!opt?.parentSoc || !socs.includes(opt?.parentSoc))) {
        socs.push(opt?.soc);
      }
    });
  });
  return socs;
};
