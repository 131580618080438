/** Path страницы единого конструктора в dev режиме. Переписывает серверный baseUrl */
export const CONSTRUCTOR_BASE_URL = '/constructor-v1/';

/** Тип компонента Separation по-умолчанию */
export const LEFT_TYPE_SIDE = 'left';

/** Дефолтное значение индекса для табов, попапов и т.п. */
export const DEFAULT_INDEX = 0;

/** Дефолтное значение индекса для массива доп. опций.
 * Под данным индексом на фронт приходят дополнительные опции, которые необходимо отображать особым образом в чеке */
export const DEFAULT_EXTRA_OPTIONS_INDEX = 1;

/** Максимальный размер доп. опций без включения wrap.
 * Используется в функции defineSizeIconsBlock. Значение "2" определено дизайнерами */
export const MAX_EXTRA_OPTIONS_LENGTH = 2;

/** Default текст для бесплатных опций/пресетов */
export const FREE_SERVICE = 'бесплатно';

/** Длина телефонного номера с маской */
export const PHONE_MASK_LENGTH = 10;

/* Заглушка для опций скелетона. Минимальная длина массива опций */
export const OPTIONS_TILE_LENGTH = 5;

/* Заглушка для опций скелетона. Минимальная длина массива иконок */
export const MIN_SKELETON_TILES_LENGTH = 1;

/* Заглушка для опций скелетона. Максимальная длина массива иконок */
export const MAX_SKELETON_TILES_LENGTH = 6;

/* Иконка стрелки "влево" для кнопки "назад".
 В контент не выносили, т.к. картинка уже загружена на статику  */
export const ICON_CHEVRON_LEFT =
  'https://static.beeline.ru/upload/images/carnica/icons/IconChevronLeft.svg';
