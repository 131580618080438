import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { ContentContainer, TContentContainer } from './components/ContentContainer';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TWithoutConvergenceBlock = {
  content: TContentContainer;
  image: string;
};

/** Компонент НЕдоступного домашнего интернета */
export const WithoutConvergenceBlock: FC<TWithoutConvergenceBlock> = ({ image, content }) => (
  <section className={cx('wrapper')}>
    <ContentContainer {...content} />
    {image && (
      <div className={cx('wrapper__image')}>
        <img alt="" loading="lazy" src={image} />
      </div>
    )}
  </section>
);
