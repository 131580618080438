const formatMonth = (number) => {
  switch (number) {
    case 1:
      return 'января';
    case 2:
      return 'февраля';
    case 3:
      return 'марта';
    case 4:
      return 'апреля';
    case 5:
      return 'мая';
    case 6:
      return 'июня';
    case 7:
      return 'июля';
    case 8:
      return 'августа';
    case 9:
      return 'сентября';
    case 10:
      return 'октября';
    case 11:
      return 'ноября';
    case 12:
      return 'декабря';
    default:
      return '/';
  }
};

const formatStrMonth = (numberString) => formatMonth(parseInt(numberString, 10));

export const convertISODateToString = (ISODate, withYear = true) => {
  const d = new Date(ISODate);
  return `${d.getDate()} ${formatStrMonth(d.getMonth() + 1)} ${withYear ? d.getFullYear() : ''}`;
};
