import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Heading, Status } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Ctx from 'pages-components/Ctx';
import { TARIFF_LAYOUTS } from 'pages/ProductCard/Tariff/tariffTypes';

import TariffCardBody from '../../components/TariffCardBody';
import TariffRowBenefit from '../../components/TariffRowBenefit';

// Hoc's
import TariffBodyHOC from '../../../hoc/TariffBodyHOC';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const YouthTariff = ({
  setESimOpenModal,
  pageTitle,
  guide,
  pageSubtitle,
  isConnected,
  layoutType,
}) => (
  <div className={cx('YouthTariff')}>
    <div className={cx('heading')}>
      <div className={cx('titleGroup')}>
        <div className={cx('titleSuccess')}>
          {isConnected && (
            <Status className={`success ${cx('success')}`}>{Ctx.tariff.statusText.myTariff}</Status>
          )}
        </div>
        <Heading className={cx('title')} isStyled={false} level={1}>
          {pageTitle}
        </Heading>
        {!!pageSubtitle && (
          <div className={cx('infoText')} dangerouslySetInnerHTML={{ __html: pageSubtitle }} />
        )}
      </div>

      {!isConnected && guide && guide.length > 0 && (
        <ul className={cx('benefits')}>
          {guide.map((guideItem, i) => (
            <li className={cx('benefitItem')} key={i}>
              <TariffRowBenefit
                {...guideItem}
                benefitClass={cx('benefitBlock')}
                benefitText={`${i + 1}`}
                textClass={cx('benefitText')}
              />
            </li>
          ))}
        </ul>
      )}

      {isConnected && layoutType === TARIFF_LAYOUTS.young && (
        <div className={cx('configureBtnWrap')} id="topConnectButton">
          <Button
            className={['big', cx('btn')]}
            href="/customers/products/mobile/profile/#/home"
            wide
          >
            {Ctx.tariff.configureBtnText}
          </Button>
        </div>
      )}
    </div>
    <TariffBodyHOC setESimOpenModal={setESimOpenModal}>
      <TariffCardBody />
    </TariffBodyHOC>
  </div>
);

YouthTariff.propTypes = {
  pageSubtitle: PropTypes.string,
  pageTitle: PropTypes.string,
  priceDiscount: PropTypes.string,
  layoutType: PropTypes.string,

  isConnected: PropTypes.bool,

  guide: PropTypes.array,

  setESimOpenModal: PropTypes.func,

  esim: PropTypes.object,
  price: PropTypes.shape({
    value: PropTypes.string,
    unit: PropTypes.string,
  }),
};

export default YouthTariff;
