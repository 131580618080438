import { TeaserBanner } from '@beef/smart-kit';
import classNames from 'classnames/bind';
import React from 'react';
import TrustPayment from '@beep/trustpayment';

import ConnectPopup from 'pages/ProductCard/components/ServiceConnectPopup/ServiceConnectPopup';
import { BannerDetailsNode } from 'pages/Services/ZeroBalanceSupport/Component/BannerDetailsNode';
import { ZeroBalanceSupportContext } from 'pages/Services/ZeroBalanceSupport/context';

import { withContext } from '../../enhancers/withContext/index';
import { TZeroBalanceContext } from '../../types';
import { ModalContainer } from '../ModalContainer/index';
import { useMapStateToProps } from './store';
import styles from './styles.pcss';
import { TBannerProps } from './types';

const cx = classNames.bind(styles);

const _Banner: React.FC<TBannerProps> = ({
  trustPayment: { isOpen, handlers },
  content: {
    banner,
    currentModal,
    setCurrentModal,
    button: { buttonType, hasTooltip, disabled, onClick },
  },
}) => (
  <div className={cx('container')}>
    <TeaserBanner
      {...banner}
      detailsNode={
        <BannerDetailsNode
          button={{
            ...buttonType,
            hasTooltip,
            onClick,
            disabled,
          }}
        />
      }
    />
    <ConnectPopup />
    <TrustPayment handlers={handlers} isAuth isOpen={isOpen} />
    <ModalContainer currentModal={currentModal} setCurrentModal={setCurrentModal} />
  </div>
);

export const Banner = withContext<TZeroBalanceContext, React.ComponentProps<typeof _Banner>>({
  context: ZeroBalanceSupportContext,
  useMapStateToProps,
})(_Banner);
