import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { CONTROL_TYPES } from 'utils/partnershipTariffHelper';
import { controlCardContentShape } from 'pages/ProductCard/Tariff/containers/tariffPropTypes';

import Box from '../Box';
import ControlCard from './ControlCard';
import CustomControlButton from './CustomControlButton';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * @desc: Список карточек управления тарифа
 */
const TariffControlCards = ({ availableControls, controlCardsContent, setESimOpenModal }) => {
  const handleClick = useCallback(
    (type) => {
      if (type === CONTROL_TYPES.esim) {
        setESimOpenModal();
      }
    },
    [setESimOpenModal],
  );

  return (
    <ul className={cx('cards-list')}>
      {availableControls.map((control) => {
        if (!controlCardsContent?.[control]?.type) {
          return null;
        }

        let customButton = null;

        if (controlCardsContent[control].type === CONTROL_TYPES.mnp) {
          customButton = <CustomControlButton type={controlCardsContent[control].type} />;
        }

        return (
          <li className={cx('card-list__item')} key={control}>
            <Box className={cx('card-box')}>
              <ControlCard
                buttonText={controlCardsContent[control].buttonText}
                customButton={customButton}
                href={controlCardsContent[control].href}
                icon={controlCardsContent[control].icon}
                linkText={controlCardsContent[control].linkText}
                onClick={() => handleClick(controlCardsContent[control].type)}
                text={controlCardsContent[control].text}
                title={controlCardsContent[control].title}
              />
            </Box>
          </li>
        );
      })}
    </ul>
  );
};

TariffControlCards.propTypes = {
  /* открытие окна есим */
  setESimOpenModal: PropTypes.func,
  /* контент для карточек */
  controlCardsContent: controlCardContentShape,
  /* список доступных карточек */
  availableControls: PropTypes.arrayOf(PropTypes.string),
};

export default TariffControlCards;
