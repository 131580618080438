/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import { Dropdown, Heading, Status } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import FamilyNavigation from 'pages/ProductCard/components/FamilyNavigation/FamilyNavigation';
import Picture from 'pages-components/Picture';

// Styles

import { ymPushParams } from 'utils/ym';
import { YM_ESIM_PARAMS } from 'utils/analytics/zamenaNaEsim';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

function removeDuplicatesBy(keyFn, array) {
  if (!array) return null;
  const mySet = new Set();
  const filteredArray = array.filter((x) => x);
  return filteredArray.filter((x) => {
    const key = keyFn(x);
    const isNew = !mySet.has(key);
    if (isNew) mySet.add(key);
    return isNew;
  });
}

const feeTypesDictionary = {
  DailyFirstSevenDays: 'Неделя бесплатно',
  Daily: 'Посуточная оплата',
  Mounthly: 'Ежемесячная оплата',
};

export const ServiceContentWrapper = ({ data, isCustomStyles, dimension, hasBackground }) => {
  const pageHeaderRef = useRef(null);

  const {
    productTypeTitle,
    familyNavigation,
    bgImageDesktop,
    bgImageMobile,
    pageSubtitle,
    pageTitle,
    titleColor,
    alias,
    customServiceParameters,
    extData,
    serviceImage,
  } = data;

  useLayoutEffect(() => {
    pageHeaderRef.current.style.setProperty('color', titleColor || '#000', 'important');
  }, [titleColor]);

  const handleYMSend = (event) => {
    event.preventDefault();
    event.stopPropagation();
    ymPushParams(YM_ESIM_PARAMS.GET_SIMPLE_SIM_START);
    window.location.href = event.target;
  };

  useEffect(() => {
    const elementYMSend = document.getElementById('esimYmSend')?.getElementsByTagName('a')[0];
    if (elementYMSend) {
      elementYMSend.addEventListener('click', handleYMSend, false);
    }

    return () => {
      if (elementYMSend) {
        elementYMSend.removeEventListener('click', handleYMSend, false);
      }
    };
  });

  const currentFeeType =
    familyNavigation && typeof familyNavigation.find === 'function' && familyNavigation.length > 1 ?
      familyNavigation.find((s) => s.alias === alias)
    : null;

  const otherFeeTypes =
    currentFeeType ?
      familyNavigation
        .filter((s) => s.alias !== alias)
        .filter((s) => s.label === currentFeeType.label)
    : null;

  const filteredFamilyNavigation = useMemo(
    () =>
      removeDuplicatesBy(
        (d) => d.label,
        currentFeeType ?
          familyNavigation.filter(
            (n) => !(n.label === currentFeeType.label && n.feeType !== currentFeeType.feeType),
          )
        : familyNavigation,
      ),
    [currentFeeType, familyNavigation],
  );

  const showNavigation = filteredFamilyNavigation && filteredFamilyNavigation.length > 1;
  const isMobileBg = dimension === 'mobile';

  return (
    <div
      className={cx('contentWrapper', {
        withNavigation: showNavigation,
        'contentWrapper--hasBackground': hasBackground,
      })}
    >
      {(bgImageMobile || bgImageDesktop) && (
        <div className={cx('wrapper__bgImage')}>
          <Picture
            alt=""
            className={cx('bgImage')}
            lazy
            src={isMobileBg ? bgImageMobile : bgImageDesktop}
          />
        </div>
      )}
      <div
        className={cx('pageHeader', {
          'pageHeader--hasBackground': hasBackground,
        })}
        ref={pageHeaderRef}
      >
        {showNavigation && (
          <FamilyNavigation
            alias={alias}
            familyNav={filteredFamilyNavigation}
            isLightTheme={customServiceParameters && !!customServiceParameters.length}
          />
        )}
        {serviceImage && (
          <Picture
            alt=""
            className={cx('icon')}
            source={[
              {
                srcSet: serviceImage,
                media: 'min-width: 768px',
              },
            ]}
            src={serviceImage}
          />
        )}
        <div className={cx('pageHeading')}>
          <Heading level={1}>
            {!serviceImage && (
              <span
                className={cx('product-type')}
                dangerouslySetInnerHTML={{
                  __html: productTypeTitle || 'Услуга',
                }}
              />
            )}
            <div dangerouslySetInnerHTML={{ __html: pageTitle }} />
          </Heading>
        </div>

        {otherFeeTypes && otherFeeTypes.length ?
          <div className={cx('paymentType')}>
            <Dropdown
              onChange={(value) => {
                if (value !== 'current') window.location.pathname = value;
              }}
              options={[
                {
                  value: 'current',
                  label: feeTypesDictionary[currentFeeType.feeType || 'DailyFirstSevenDays'],
                },
              ].concat(
                otherFeeTypes.map((type) => ({
                  value: type.link,
                  label: feeTypesDictionary[type.feeType || 'DailyFirstSevenDays'],
                })),
              )}
              value="current"
            />
          </div>
        : null}

        <div
          className={cx('subHeader', isCustomStyles && 'subHeader--custom')}
          dangerouslySetInnerHTML={{ __html: pageSubtitle }}
        />
        {extData?.esimUnavailable && extData?.unavailableContent?.text && (
          <div className={cx('unavailableСontent')}>
            <Status className="error">
              <span
                className={cx('error')}
                dangerouslySetInnerHTML={{
                  __html: extData.unavailableContent.text,
                }}
                id="esimYmSend"
              />
            </Status>
          </div>
        )}
        {extData?.content?.wifiNeedText && !extData?.esimUnavailable && (
          <div className={cx('wifiNeedTextСontent')}>
            <Status className="error">
              <span
                className={cx('error')}
                dangerouslySetInnerHTML={{
                  __html: extData?.content?.wifiNeedText,
                }}
                id="wifiNeedText"
              />
            </Status>
          </div>
        )}
      </div>
    </div>
  );
};

ServiceContentWrapper.propTypes = {
  data: PropTypes.object,
  isCustomStyles: PropTypes.bool,
  dimension: PropTypes.string,
  hasBackground: PropTypes.bool,
};
