import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import styles from './DataBlocks.pcss';

const cx = classNames.bind(styles);

const Container = ({ children }) => <div className={cx('container')}>{children}</div>;

Container.propTypes = {
  children: PropTypes.any,
};

class Block extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHidden: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isHidden: false });
    });
  }

  render() {
    const { isHidden } = this.state;
    const { children, className, title, subtitle, info, icon } = this.props;

    if (!title && !children) return null;

    return (
      <div className={cx('block', { hidden: isHidden, withIcon: icon }, className)}>
        {icon && <Icon className={cx('blockIcon')} name={icon} />}
        {title && (
          <div className={cx('blockHeading')}>
            <div className={cx('blockHeadingTitle')}>{title}</div>
            {info && <div className={cx('blockHeadingInfo')}>{info}</div>}
            {subtitle && <div className={cx('blockHeadingSubtitle')}>{subtitle}</div>}
          </div>
        )}
        {children && <div className={cx('blockContent')}>{children}</div>}
      </div>
    );
  }
}

Block.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  info: PropTypes.any,
  icon: PropTypes.string,
};

const ContentGroup = ({ children, title, info, description }) => (
  <div className={cx('contentGroup')}>
    <div className={cx('blockHeading')}>
      <div className={cx('blockHeadingTitle')}>{title}</div>
      {info && <div className={cx('blockHeadingInfo')}>{info}</div>}
    </div>
    {description && <div className={cx('blockDescription')}>{description}</div>}
    {children && <div className={cx('blockContent')}>{children}</div>}
  </div>
);

ContentGroup.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  info: PropTypes.any,
  description: PropTypes.any,
};

const Table = ({ className, children }) => (
  <table className={cx('table', className)}>
    <tbody>{children}</tbody>
  </table>
);

Table.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
};

const TableRow = ({ className, children }) => (
  <tr className={cx('tableRow', className)}>{children}</tr>
);

TableRow.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
};

const TableCell = ({ className, children, ...props }) => (
  <td {...props} className={cx('tableCell', className)}>
    {children}
  </td>
);

TableCell.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
};

export default {
  Container,
  Block,
  ContentGroup,
  Table,
  TableRow,
  TableCell,
};
