import React, { FC } from 'react';
import classNames from 'classnames/bind';

// TODO: Не забыть про импорт при выносе в отдельный пакет!
import { ServiceTile } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/ServiceTile';
import { TServiceTile } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/ServiceTile/types';

import { SettingsButton } from '../SettingsButton';
import { InfoPrice } from '../../../ServiceTiles/components/InfoPrice';
import { usePrepareServiceTile } from './hooks/usePrepareServiceTile';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TFmcContainer = {
  service: Partial<TServiceTile>;
  withoutButton?: boolean;
};

/** Компонент-обертка для вывода плитки подключенного ШПД */
export const FmcContainer: FC<TFmcContainer> = ({ service = {}, withoutButton = false }) => {
  const { serviceTileProps, infoPriceProps, settingsBtnProps } = usePrepareServiceTile(
    service as TServiceTile,
  );
  return (
    <div className={cx('wrapper', { wrapper__unclickable: withoutButton })}>
      <ServiceTile {...serviceTileProps}>
        <div className={cx('wrapper__settings')}>
          <InfoPrice {...infoPriceProps} />
          {!withoutButton && <SettingsButton {...settingsBtnProps} />}
        </div>
      </ServiceTile>
    </div>
  );
};
