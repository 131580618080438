type TArray = (number | string | undefined)[];

const arrayToString = (list: TArray) =>
  list.map((item) => (typeof item === 'number' ? item.toString() : item));

/** Ф-я сравнивает переменные в объекте таким образом,
 *  что ['a','b'] === ['b','a'] равно true */
export const checkArraysValuesEqual = (arr1?: TArray, arr2?: TArray) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
    return false;
  }

  /* При нативной сотировке sort() происходит путаница при наличии в массиве '0' и 0, поэтому привел эл-ты к строке */
  const sortedArr1 = arrayToString(arr1).sort();
  const sortedArr2 = arrayToString(arr2).sort();
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};
