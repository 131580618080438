import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TTotalOldPrice = {
  oldPrice?: string;
  period?: string;
  unit: string;
};

/** Блок старой цены и юнита оплаты за период */
export const TotalOldPrice = memo<TTotalOldPrice>(({ unit, period, oldPrice }) => (
  <div className={cx('wrapper')}>
    {!!oldPrice && (
      <div className={cx('wrapper__old-price')}>
        <Text color="grey40" isCrossedOut size="size5-r-s">
          {`${oldPrice} ${unit}`}
        </Text>
      </div>
    )}
    {period && (
      <Text color="grey60" size="size5-r-s">
        {period}
      </Text>
    )}
  </div>
));
