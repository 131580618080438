/**
 * ERROR_COLOR, SUCCESS_COLOR: цвета текста нотификации на этапе проверки адреса.
 * @type {string}
 */
export const ERROR_COLOR = '#F45151';
export const SUCCESS_COLOR = '#1790FF';

/**
 * DELAY_TIME: delay для функции useDebounce.
 * @type {number}
 */
export const DELAY_TIME = 500;

/**
 * NUMBER_PHONE_LENGTH: стандартная длина номера.
 * @type {number}
 */
export const NUMBER_PHONE_LENGTH = 10;

/**
 * DEFAULT_TAB_VALUE: активный таб пресетов по-умолчанию.
 * @type {string}
 */
export const DEFAULT_TAB_VALUE = '0';

/**
 * REQUEST_STATUS: статусы подключения услуги.
 */
export const REQUEST_STATUS = {
  isNone: 'isNone',
  isExists: 'isExists',
  isAvailable: 'isAvailable',
  isConnected: 'isConnected',
  alreadyInUse: 'alreadyInUse',
  isNotAvailable: 'isNotAvailable',
  houseIsNotFound: 'houseIsNotFound',
  streetIsNotFound: 'streetIsNotFound',
  regionIsNotFound: 'regionIsNotFound',
  regionIsNotConnect: 'regionIsNotConnect',
  isHighSpeedAvailable: 'isHighSpeedAvailable',
};

/**
 * popupSteps: шаги в общем попапе.
 */
export const popupSteps = {
  addressRequestForm: 0,
  tariffDetailsForm: 1,
  calendarForm: 2,
  requestExists: 3,
  connectCompleted: 4,
  callBackRequest: 5,
  confirmCancelConnect: 6,
  connectFail: 7,
  fail: 8,
  upTariffSpeedFromArchive: 9,
};

/**
 * highSpeedVerification: возможность скоростного подключения.
 */
export const highSpeedVerification = {
  withVerification: 0,
  withoutVerification: 1,
};

/**
 * btnTexts: кнопки попапа.
 */
export const btnTexts = {
  next: 'Далее',
  close: 'Понятно',
  save: 'Сохранить',
  backButton: 'Назад',
  cancelConfirm: 'Отказаться',
  callBack: 'Заказать звонок',
  archiveCancel: 'Отказаться',
  changeAddress: 'Изменить адрес',
  revertCancelConfirm: 'Оставить',
  addToTariff: 'Добавить к тарифу',
  archiveTariffUpSpeed: 'Отправить заявку',
  checkDiffAddress: 'Проверить другой адрес',
  cansel: 'Отказаться от домашнего интернета',
};

/**
 * connectionSpeed: типы возможного скоростного подключения.
 */
export const connectionSpeed = {
  lowSpeed: 0,
  middleSpeed: 1,
  highSpeed: 2,
};

export const ZERO_CONSTANT = 0;
export const ONE_CONSTANT = 1;
export const TWO_CONSTANT = 2;
