import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import {
  Button,
  Dropdown,
  Heading,
  InputGroup,
  InputInfo,
  InputsRow,
  Label,
  Link,
  SmartForm,
  SmartInput,
  Status,
} from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';
import { maskedPhone, shpdLogin } from 'utils/validators';
import { pushGAeeBeeAllInOneFormAction } from 'utils/ga';
import LegalWithTooltip from 'pages/FTTB/components/LegalWithTooltip';
import Ctx from 'pages-components/Ctx';

import { makeFormData } from '../../selectors/form';
import { SET_ALLINONE_VALUE } from '../../actions/actionTypes';
import { sendRequiest } from '../../actions/actions';
import PopupRequestHouse from '../PopupRequestHouse/PopupRequestHouse';
import FormAddress from '../FormAddress/FormAddress';
import styles from './FormWrapper.pcss';

const cx = classNames.bind(styles);

class FormWrapper extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPhonePushed: false,
      isNamePushed: false,
      selectedAddress: 'default',
      regionStatus: 'ok',
      streetStatus: 'ok',
      houseStatus: 'ok',
      flatStatus: 'ok',
      orderBtnClicked: false,
    };
  }

  onOrderClick = () => {
    this.setState({ orderBtnClicked: true });
  };

  getSelectedAddressType = (withoutInternet, address, selectedAddress) => {
    if (withoutInternet) return 'New';
    if (typeof address === 'object' && address !== null && selectedAddress === 'default')
      return 'ExistingAuth';
    return 'Existing';
  };

  getHeader = (address) => {
    if (address) {
      return 'Выберите адрес подключения';
    }
    return 'Введите ваш логин — номер лицевого счета, указанный в договоре';
  };

  pushEventToDataLayer = (event, type = 'convForm', additional = {}) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      const eventContent =
        typeof event === 'object' ? event : (
          {
            event: 'GA_event',
            eventAction: event,
            eventCategory: 'КонвергенцияForm',
            eventLabel: 'changeField',
            ...additional,
          }
        );

      if (type === 'convForm') {
        window.dataLayer.push(eventContent);
      } else {
        window.dataLayer.push({
          ...eventContent,
          ordered_Type: this.props.withoutInternet ? 'New order' : 'Home User',
        });
      }
    }
  };

  handlePhoneChange = () => {
    if (!this.state.isPhonePushed) {
      this.setState({ isPhonePushed: true }, () => {
        this.pushEventToDataLayer('Phone');
      });
    }
  };

  handleNameChange = () => {
    if (!this.state.isNamePushed) {
      this.setState({ isNamePushed: true }, () => {
        this.pushEventToDataLayer('Name');
      });
    }
  };

  handleDropDownChange = (value) => {
    if (this.state.selectedAddress !== value) {
      this.setState({
        selectedAddress: value,
      });
    }
  };

  handleLoginBlur = () => {
    this.pushEventToDataLayer('Account_no');
  };

  handlerOnSubmit = (data) => {
    pushGAeeBeeAllInOneFormAction(this.props);
    this.props.onSubmit(data);
  };

  validateAllFields = () => {
    if (!this.state.orderBtnClicked) return;
    const { region, street, house, flat } = this.props;

    this.setState({
      regionStatus: region && typeof region.id === 'number' ? 'ok' : 'fail',
      streetStatus: street && typeof street.id === 'number' ? 'ok' : 'fail',
      houseStatus: house && typeof house.id === 'number' ? 'ok' : 'fail',
      flatStatus: flat && flat.length > 0 ? 'ok' : 'fail',
    });
  };

  renderHeading = (address) => (
    <Heading className={cx('heading')} level={4}>
      {this.getHeader(address)}
    </Heading>
  );

  renderAddressDropdown = (withoutInternet, address) => {
    if (withoutInternet || !address) return null;
    return (
      <Dropdown
        className={cx({ hidden: this.props.withoutInternet })}
        onChange={this.handleDropDownChange}
        options={[
          { value: 'default', label: address.description },
          { value: 'other', label: 'Другой адрес' },
        ]}
        value="default"
      />
    );
  };

  renderLoginInput = (showForm, showHeader) => {
    if (!showForm) return null;

    return (
      <div>
        {showHeader ?
          <Heading className={cx('heading')} level={4}>
            Введите ваш логин
          </Heading>
        : null}
        <SmartInput
          autoComplete="off"
          className={cx('inputNumber', { hidden: this.props.withoutInternet })}
          inputId="FttbAnyName"
          mask="__________"
          name="FttbAnyName"
          onBlur={this.handleLoginBlur}
          size="big"
        />
        <InputsRow className={cx({ hidden: this.props.withoutInternet })}>
          <Label />
          <InputInfo>Например 08ХХХХХХХХ</InputInfo>
        </InputsRow>
      </div>
    );
  };

  render() {
    const {
      address,
      alias,
      withoutInternet,
      headerRegionId,
      region,
      street,
      house,
      flat,
      flatAlreadyUsed,
      isFlatPartnerConnected,
      tariffName,
      tariffDescription,
      tariffValue,
      isAddressCorrect,
      requestData,
      mobileTariffUrl,
      openRequestHousePopup,
      hasDiscount,
    } = this.props;

    // Хардкод
    const updatedTariffValue = alias === 'blizkie-lyudi-3-plus-300-mbits' ? 495 : null;

    const { regionStatus, streetStatus, houseStatus, flatStatus } = this.state;

    const selectedAdressType = this.getSelectedAddressType(
      withoutInternet,
      address,
      this.state.selectedAddress,
    );
    let validator;

    const selectedRegionId = selectedAdressType === 'New' ? region.id : headerRegionId;

    switch (selectedAdressType) {
      case 'New':
        validator = {
          Address: (value) => {
            this.validateAllFields();
            return {
              status: value && value.length > 0 ? 'ok' : 'fail',
              msg: 'Некорректно указан адрес',
            };
          },
        };
        break;
      case 'Existing':
        validator = { FttbAnyName: shpdLogin };
        break;
      case 'ExistingAuth':
        if (this.state.selectedAddress !== 'default') validator = { FttbAnyName: shpdLogin };
        break;
      default:
    }

    const showHeader = this.state.selectedAddress !== 'default' && !withoutInternet;
    const showForm = (!address && !withoutInternet) || showHeader;
    const houseIsNotAvailable = house.id && !house.isConnected;
    return (
      <div className={cx('form')}>
        <SmartForm
          autoComplete="off"
          inline
          onSubmit={this.handlerOnSubmit}
          validator={{
            ContactNumber: maskedPhone,
            ...validator,
          }}
        >
          <SmartInput
            inputId="SelectedAddressType"
            name="SelectedAddressType"
            type="hidden"
            value={selectedAdressType}
          />
          <SmartInput inputId="RegionId" name="RegionId" type="hidden" value={selectedRegionId} />
          <SmartInput inputId="NewStreetId" name="NewStreetId" type="hidden" value={street.id} />
          <SmartInput inputId="NewHouseId" name="NewHouseId" type="hidden" value={house.id} />
          <SmartInput inputId="NewFlat" name="NewFlat" type="hidden" value={flat} />
          <SmartInput inputId="TariffName" name="TariffName" type="hidden" value={tariffName} />
          <SmartInput
            className={cx('hidden')}
            inputId="Address"
            type="hidden"
            value={!withoutInternet || isAddressCorrect ? 'Address' : ''}
          />

          {!withoutInternet && this.renderHeading(address)}

          <FormAddress
            className={cx({ hidden: !withoutInternet })}
            flatStatus={flatStatus}
            houseStatus={houseStatus}
            pushToAnalytics={this.pushEventToDataLayer}
            regionStatus={regionStatus}
            streetStatus={streetStatus}
          />

          {this.renderAddressDropdown(withoutInternet, address)}

          {this.renderLoginInput(showForm, showHeader)}
          {(!houseIsNotAvailable || !withoutInternet) && (
            <InputsRow groups={2}>
              <InputGroup className={cx('inputGroup')}>
                <Label>{Ctx.allInOne.requestForm.phoneLabel}</Label>
                <SmartInput
                  autoComplete="off"
                  inputId="ContactNumber"
                  keepInitialValue
                  mask="+7 ___ ___ __ __"
                  name="ContactNumber"
                  onChange={this.handlePhoneChange}
                  size="big"
                  value="+7 "
                />
              </InputGroup>

              <InputGroup className={cx('inputGroup')}>
                <Label>{Ctx.allInOne.requestForm.nameLabel}</Label>
                <SmartInput
                  autoComplete="off"
                  inputId="FirstName"
                  name="FirstName"
                  onChange={this.handleNameChange}
                  size="big"
                />
              </InputGroup>
            </InputsRow>
          )}

          <div className={cx('tariffInfoWrap')}>
            <div className={cx('tariffInfo')}>
              {hasDiscount ?
                <div>
                  <span className={cx('tariffDiscountLabel')}>
                    {Ctx.allInOne.requestForm.tariffDiscountLabel}
                  </span>
                  <span
                    className={cx('tariffName')}
                    dangerouslySetInnerHTML={{ __html: ` «${tariffName}»` }}
                  />
                </div>
              : <div
                  className={cx('tariffName')}
                  dangerouslySetInnerHTML={{ __html: `Тариф «${tariffName}»` }}
                />
              }
              {tariffDescription && (
                <div
                  className={cx('tariffAdditional')}
                  dangerouslySetInnerHTML={{ __html: tariffDescription }}
                />
              )}
            </div>
            <div>
              {`${updatedTariffValue || tariffValue} ${Ctx.allInOne.requestForm.tariffUnit}`}
            </div>
          </div>

          <div className={cx('buttonWrapper')}>
            {houseIsNotAvailable && withoutInternet ?
              <Button
                className="big"
                href={`${mobileTariffUrl}?connect=true&ignoreDivorcing=true`}
                type="button"
                wide
              >
                {Ctx.allInOne.requestForm.btnWithoutHome}
              </Button>
            : <Button
                className="big"
                disabled={flatAlreadyUsed || isFlatPartnerConnected}
                onClick={this.onOrderClick}
                type="submit"
                wide
              >
                {Ctx.allInOne.requestForm.buttonText}
              </Button>
            }
          </div>

          {houseIsNotAvailable && withoutInternet && (
            <div className={cx('requestHouseLinkWrap')}>
              <Link onClick={openRequestHousePopup}>
                {Ctx.allInOne.requestForm.connectHouseLink}
              </Link>
            </div>
          )}
        </SmartForm>

        {!requestData.IsSucceeded && requestData.ErrorMessage ?
          <Status className={['wide', 'attention']} emoji="statusFail">
            {requestData.ErrorMessage}
          </Status>
        : null}

        <LegalWithTooltip
          className={cx('legal')}
          termsOfUse={Ctx.allInOne.requestForm.termsOfUse}
          userAgreementText={Ctx.allInOne.requestForm.userAgreementText}
        />

        <PopupRequestHouse pushToAnalytics={this.pushEventToDataLayer} />
      </div>
    );
  }
}

FormWrapper.defaultProps = {
  withoutInternet: false,
  isAddressCorrect: true,
  flatAlreadyUsed: false,
  isFlatPartnerConnected: false,
  region: {},
  street: {},
  house: {},
  flat: '',
  tariff: {},
  tariffName: '',
  tariffValue: '',
  tariffUnit: '',
  requestData: {},
  services: [],
};

FormWrapper.propTypes = {
  address: PropTypes.object,
  tariff: PropTypes.object,
  withoutInternet: PropTypes.bool,
  isAddressCorrect: PropTypes.bool,
  flatAlreadyUsed: PropTypes.bool,
  isFlatPartnerConnected: PropTypes.bool,
  hasDiscount: PropTypes.bool,
  headerRegionId: PropTypes.number,
  region: PropTypes.object,
  street: PropTypes.object,
  house: PropTypes.object,
  flat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tariffName: PropTypes.string,
  tariffDescription: PropTypes.string,
  tariffValue: PropTypes.string,
  tariffUnit: PropTypes.string,
  services: PropTypes.array,
  mobileTariffUrl: PropTypes.string,
  requestData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  openRequestHousePopup: PropTypes.func,
  alias: PropTypes.string,
};

const makeMapStateToProps = () => {
  return (state, props) => makeFormData(state, props);
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (data) => dispatch(sendRequiest(data)),
  openRequestHousePopup: () =>
    dispatch({
      type: SET_ALLINONE_VALUE,
      payload: {
        popupIsOpen: true,
        requestHouse: '',
      },
    }),
});

const ConnectedFormWrapper = connect(makeMapStateToProps, mapDispatchToProps)(FormWrapper);

export default (props) => (
  <Provider store={store}>
    <ConnectedFormWrapper {...props} />
  </Provider>
);
