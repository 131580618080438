import React, { FC } from 'react';
import { compose, withReduxStore } from '@beef/redux';
import { connect } from 'react-redux';
import { withDataConverter } from '@beef/react';

import { useInitRedirectServiceCardLanding } from 'pages/Services/RedirectServiceCardLanding/hook';
import {
  RedirectStore,
  initializeRedirectServiceCardLanding,
} from 'pages/Services/RedirectServiceCardLanding/store';
import {
  ContentDpc,
  RedirectServiceCardLandingProps,
} from 'pages/Services/RedirectServiceCardLanding/types';
import { getRedirectData } from 'pages/Services/RedirectServiceCardLanding/store/redirect/asyncActions';
import {
  selectDpcContent,
  selectLoadingInitialData,
  selectRedirectQPContent,
} from 'pages/Services/RedirectServiceCardLanding/store/redirect/selectors';
import { MainError } from 'pages/Services/RedirectServiceCardLanding/components/Status/MainError';

import { RedirectServiceCardContainer } from './components/RedirectServiceCardContainer';

const _RedirectServiceCard: FC<RedirectServiceCardLandingProps> = (props) => {
  const { loadingInitialData, contentQp } = props;
  const { mainData } = useInitRedirectServiceCardLanding({ ...props });

  if (loadingInitialData === 'failed') {
    return <MainError {...contentQp?.mainError} />;
  }

  return <RedirectServiceCardContainer {...mainData} />;
};

const mapStateToProps = (state: RedirectStore) => ({
  state,
  loadingInitialData: selectLoadingInitialData(state),
  content: selectDpcContent(state),
  contentQp: selectRedirectQPContent(state),
});

const mapDispatchToProps = {
  getOptions: getRedirectData,
};

export const RedirectServiceCard = compose(
  withDataConverter((props: { content: ContentDpc }) => ({ content: props.content })),
  withReduxStore(initializeRedirectServiceCardLanding),
  connect(mapStateToProps, mapDispatchToProps),
)(_RedirectServiceCard);
