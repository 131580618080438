import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { WPContainer } from '../../commonComponents/WPContainer';
import { UppersTabs } from './components/UppersTabs';
import { TariffFeatures } from '../TariffFeatures';
import { TUppersTabsContainer } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонет-обертка табов апперов для попапа смены персонажа */
export const UppersTabsContainer: FC<TUppersTabsContainer> = ({
  tabs,
  onClick,
  activeColor,
  tariffFeatures,
  activeCharacter,
}) => (
  <div className={cx('wrapper')}>
    <WPContainer>
      <UppersTabs {...{ tabs, onClick, activeColor, activeCharacter }} />
    </WPContainer>
    <TariffFeatures {...{ activeColor, tariffFeatures }} />
  </div>
);
