import { withDataConverter, withMountListener } from '@beef/react';
import { compose, withReduxStore } from '@beef/redux';
import { Picture } from '@beef/smart-kit';
import { Card, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import { LandingConstructor } from '@beef/landing-constructor';
import React, { memo, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { AnketaForm } from '../components/Form/Anketa/AnketaForm';
import { HeaderScrollDown } from '../components/HeaderScrollDown';
import styles from '../components/Form/Anketa/styles.pcss';
import { StoryExamples } from '../components/StoryExamples';
import { initializeStoreAnketaLanding } from '../store';
import { getAnketaFieldNames, setValue } from '../store/anketa';
import { initializeForm, submitAnketa, validateAnketa } from '../store/anketa/actions';
import { confirmModal, openModal } from '../store/modal';
import { selectHasErrors } from '../store/selectors';
import { StorageHelper, processLandingConstructorData } from '../utils';
import { pushAnniversaryEvent } from '../utils/analytics';
import { FORM_ALIAS } from '../constants';

const cn = classNames.bind(styles);

const AnketaFormSection = ({ content, form, data, modal, strip, ...rest }) => {
  const meta = rest?.meta;
  const hasSubmitted = data?.hasSubmitted || meta?.isStorySent;
  const hasErrors = selectHasErrors({ data });
  const ref = useRef();
  const formRef = useRef();
  const shouldScrollIntoView = data.shouldScrollIntoView?.value;

  useEffect(() => {
    if (hasErrors || hasSubmitted || shouldScrollIntoView) {
      const _ref = hasErrors ? formRef : ref;
      const top = _ref.current.getBoundingClientRect().top + window.scrollY - 150;
      window.scroll({
        top,
        behavior: 'smooth',
      });
    }
  }, [hasSubmitted, hasErrors, shouldScrollIntoView]);

  return (
    <div ref={ref}>
      <div className={cn('anchor')} id={FORM_ALIAS} />
      <Card className={cn('card')}>
        <div className={cn('content')}>
          <div className={cn('details__img')}>
            <Picture alt={content?.side?.image?.description} src={content?.side?.image?.src} />
            {!hasSubmitted && (
              <div className={cn('text')}>
                <div className={cn('title')}>
                  <Text color="grey95" size="size1-m" tagType="h2">
                    {content?.side?.title || ''}
                  </Text>
                </div>
                <div className={cn('description')}>
                  <Text color="grey95" size="size4-r">
                    {content?.side?.description || ''}
                  </Text>
                </div>
              </div>
            )}
          </div>
          <div className={cn('details')} ref={formRef}>
            <AnketaForm
              content={content}
              {...form}
              data={data}
              modal={modal}
              strip={strip}
              {...rest}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = ({ form, modal }) => {
  const strip = form?.content?.story?.strip;
  return {
    ...form,
    modal,
    strip,
  };
};

const mapDispatchToProps = (dispatch) => ({
  form: {
    setValue: (fieldName, value) => dispatch(setValue({ value, fieldName })),
    handleValidate: (e) => {
      e.preventDefault();
      pushAnniversaryEvent('story_send_form');
      dispatch(validateAnketa());
    },
    confirmModal: (modalType) => {
      dispatch(confirmModal());
      if (modalType === 'confirmation') {
        pushAnniversaryEvent('story_confirm_sending');
        dispatch(submitAnketa());
      }
    },
    openModal: (modalType) => {
      dispatch(openModal(modalType));
    },
  },
  onMount: () => {
    dispatch(initializeForm('anketa'));

    const _hasValue = getAnketaFieldNames().reduce((hasValue, fieldName) => {
      const value = StorageHelper[fieldName];
      dispatch(setValue({ value, fieldName }));
      const isAgreementField = fieldName.toLowerCase().includes('agreement');
      return hasValue || (!isAgreementField && !!value);
    }, false);

    dispatch(setValue({ value: _hasValue, fieldName: 'shouldScrollIntoView' }));
  },
});

const ConnectedFormSection = compose(
  withDataConverter((data) => ({
    form: data,
    modal: data,
  })),
  withReduxStore(initializeStoreAnketaLanding),
  connect(mapStateToProps, mapDispatchToProps),
  withMountListener,
)(AnketaFormSection);

export const AnketaLanding = ({ data, ...meta }) => (
  <>
    <HeaderScrollDown {...data.heading} scrollToId={FORM_ALIAS} />
    <LandingConstructor
      {...processLandingConstructorData(data, {
        custom: (b) => (meta?.isStoryAvailable ? b : null),
      })}
      customComponents={{
        AnketaFormSection: memo((props) => <ConnectedFormSection {...props} meta={meta} />),
        StoryExamples,
      }}
    />
  </>
);
