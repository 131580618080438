import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { path, pathOr } from 'ramda';
import { Button, Heading, Preloader, Tab, TabContent, Tabs, TabsList } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';
import UssdForm from 'pages-components/UssdForm/UssdForm';
import PopupStateless from 'pages-components/Popup';

import LoginForm from './LoginForm/LoginForm';
import SmsLoginForm from './SmsLoginForm/SmsLoginForm';
import { resetSmsForm, sendPhone, toggleAuthorizationPopup } from '../actions/authorization';
import styles from './Authorization.pcss';

const cx = classNames.bind(styles);

class Authorization extends Component {
  renderPopup() {
    const {
      activeTab,
      data,
      errorPopupData,
      isPending,
      closePopup,
      tabs,
      form,
      smsForm,
      step,
      phone,
      submitSmsFormPhone,
      sendSmsAgain,
      error,
      ussdForm,
    } = this.props;

    if (isPending) {
      return <Preloader className="center" />;
    }

    if (!data) {
      if (!errorPopupData) {
        return null;
      }

      return (
        <div className={cx('wrapper')}>
          <Heading level={1}>{errorPopupData.title}</Heading>
          <p>{errorPopupData.content}</p>
          <Button onClick={closePopup}>{errorPopupData.buttonText}</Button>
        </div>
      );
    }

    return (
      <div className={cx('wrapper')}>
        <Heading className={cx('header')} level={1}>
          {data.addServiceHeader}
          <br />
          {`«${data.serviceName}»`}
        </Heading>
        <Tabs activeTab={activeTab}>
          <TabsList inline type="radio">
            {tabs.map((tab) => (
              <Tab className={cx('tab')} id={tab.type} key={tab.type}>
                <span className={cx('tabText')} dangerouslySetInnerHTML={{ __html: tab.title }} />
              </Tab>
            ))}
          </TabsList>
          <TabContent className={cx('form')} id="LoginForm">
            <LoginForm {...form} />
          </TabContent>
          <TabContent className={cx('form')} id="SmsConfirmationForm">
            <SmsLoginForm
              error={error}
              phone={phone}
              sendSmsAgain={sendSmsAgain}
              step={step}
              submitSmsFormPhone={submitSmsFormPhone}
              {...form}
              {...smsForm}
            />
          </TabContent>
          <TabContent className={cx('form')} id="UssdCommandForm">
            {ussdForm && <UssdForm description={ussdForm.ussdText} ussd={ussdForm.ussd} />}
          </TabContent>
        </Tabs>
      </div>
    );
  }

  render() {
    const { closePopup, popupIsActive } = this.props;
    const element = this.renderPopup();

    if (!element) {
      return null;
    }

    return (
      <PopupStateless onClose={closePopup} opened={popupIsActive}>
        {element}
      </PopupStateless>
    );
  }
}

const mapStateToProps = (state) => {
  const authorizationPopup = pathOr({}, ['external', 'authorizationPopup'], state);
  const tabs = path(['data', 'activationTypes'], authorizationPopup);
  const activeTab = tabs && tabs.find((tab) => tab.IsActive);

  return {
    ...authorizationPopup,
    activeTab: pathOr(activeTab && activeTab.type, ['activeTab'], authorizationPopup),
    form: path(['data', 'loginForm'], authorizationPopup),
    smsForm: path(['data', 'smsConfirmationForm'], authorizationPopup),
    ussdForm: path(['data', 'ussdCommandForm'], authorizationPopup),
    tabs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(toggleAuthorizationPopup(false)),
  sendSmsAgain: () => dispatch(resetSmsForm()),
  submitSmsFormPhone: (data) => dispatch(sendPhone(data)),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(Authorization);

Authorization.propTypes = {
  activeTab: PropTypes.string,
  closePopup: PropTypes.func.isRequired,
  data: PropTypes.object,
  error: PropTypes.object,
  errorPopupData: PropTypes.object,
  form: PropTypes.object,
  isPending: PropTypes.bool,
  phone: PropTypes.string,
  popupIsActive: PropTypes.bool,
  tabs: PropTypes.array,
  sendSmsAgain: PropTypes.func.isRequired,
  smsForm: PropTypes.object,
  step: PropTypes.string,
  submitSmsFormPhone: PropTypes.func.isRequired,
  ussdForm: PropTypes.object,
  showSmsForm: PropTypes.bool,
};

Authorization.defaultProps = {
  activeTab: null,
  data: null,
  error: {},
  errorPopupData: null,
  form: null,
  isPending: false,
  phone: '',
  popupIsActive: false,
  tabs: [],
  smsForm: null,
  showSmsForm: false,
  step: 'smsFormPhone',
};

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);

/* +7 965 443-00-47 */
