import React from 'react';
import { Text } from '@beef/ui-kit';

import { usePrepareArchivedNoTariffUpWithtFttbProps } from './hoocks/usePrepareArchivedNoTariffUpWithtFttbProps';

/** Показываем при тарифе не ап с ШПД информация о том, что
 *  шпд может быть настроено только после отправки заявки */
export const ArchivedNoTariffUpWithFttbBlock = () => {
  const text = usePrepareArchivedNoTariffUpWithtFttbProps();
  return (
    <div>
      <Text color="grey60" size="size5-r-l">
        {text}
      </Text>
    </div>
  );
};
