import React, { FC } from 'react';
import { LayoutGroup, motion } from 'framer-motion';
import classNames from 'classnames/bind';

import { useAppSelector } from 'constructorV1/store';
import {
  selectCBillData,
  selectCBillIsLoading,
} from 'constructorV1/store/selectors/selectCommonBill';
import { ESkeletonCBillTypes } from 'constructorV1/types';
import { SkeletonCommonBill } from 'constructorV1/components/tempComponents/SkeletonCommonBill';

import { CBackButton } from './components/CBackButton';
import { CBillButton } from './components/CBillButton';
import { CMoreDetails } from './components/CMoreDetails';
import { CPlanSettings } from './components/CPlanSettings';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Результирующий чек конструктора ЕК для отдельной страницы */
export const BillCommon: FC = () => {
  /* Флаг загрузки запроса на формирование результирующего чека */
  const isLoading = useAppSelector(selectCBillIsLoading);

  /** Обработанные данные для компонентов результирующего чека */
  const { cSettingsProps, cDetailsProps, cBillButtonProps, isBlockDetailsHidden } =
    useAppSelector(selectCBillData) ?? {};

  return (
    <LayoutGroup>
      <motion.section className={cx('wrapper')} layout>
        <motion.div
          className={cx('wrapper__content', { 'wrapper__content--hidden': isBlockDetailsHidden })}
          layout
        >
          {/* Блок выбранных настроек (черный блок) */}
          {isLoading ?
            <SkeletonCommonBill type={ESkeletonCBillTypes.settings} />
          : <CPlanSettings {...cSettingsProps} />}
          {/* Блок с подробной информацией */}
          {isLoading ?
            <SkeletonCommonBill type={ESkeletonCBillTypes.details} />
          : <CMoreDetails {...cDetailsProps} />}
          {/* Кнопка "Назад" */}
          <CBackButton />
        </motion.div>
      </motion.section>
      {/* Кнопка перехода к оплате */}
      {isLoading ?
        <SkeletonCommonBill type={ESkeletonCBillTypes.button} />
      : <CBillButton {...cBillButtonProps} />}
    </LayoutGroup>
  );
};
