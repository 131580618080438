import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import QuickOrderPopup from 'pages/FTTB/components/QuickOrderPopup';
import { gaEventLabel } from 'pages/FTTB/constants';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class YouAreFavorites extends PureComponent {
  state = {
    showQuickOrderPopup: false,
  };

  closeQuickOrderPopup = () => this.setState({ showQuickOrderPopup: false });

  showQuickOrderPopup = () => this.setState({ showQuickOrderPopup: true });

  render() {
    const { title, description, buttonText, showCaptcha, quickOrderPopupTexts } = this.props;
    const { showQuickOrderPopup } = this.state;

    return (
      <div className={cx('wrapper')}>
        <Heading className={cx('title')} level={2}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Heading>

        <p className={cx('description')} dangerouslySetInnerHTML={{ __html: description }} />

        <div className={cx('quickOrderBtnWrapper')}>
          <Button
            className={['big', cx('openQuickOrderBtn')]}
            onClick={this.showQuickOrderPopup}
            transformer
          >
            {buttonText}
          </Button>
        </div>

        <QuickOrderPopup
          featureEnabled
          gaEventLabel={gaEventLabel.chosen}
          isOpen={showQuickOrderPopup}
          onClose={this.closeQuickOrderPopup}
          showCaptcha={showCaptcha}
          texts={quickOrderPopupTexts}
        />
      </div>
    );
  }
}

YouAreFavorites.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  showCaptcha: PropTypes.bool,
  quickOrderPopupTexts: PropTypes.object,
};

export default YouAreFavorites;
