import React, { useEffect, useRef } from 'react';

import { useOnScreen } from 'utils/hooks/useOnScreen';

const HALF_ELEMENT = 0.5;

export const withElementInView =
  (WrappedComponent, options = { threshold: HALF_ELEMENT }) =>
  (onView) =>
  (props) => {
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, options);

    useEffect(() => {
      if (isVisible) {
        onView(props);
      }
    }, [isVisible, props]);

    return <WrappedComponent {...props} elementRef={ref} isVisible={isVisible} />;
  };
