/**
 * usePrepareConvergenceData генерирует данные для расчетов конвергенса
 * @param convergenceContent
 * @param isShowAvailableSettings
 * @param activeTab
 * @param isAuthenticated
 * @param isChangeTariff
 * @param convergence
 * @param isConvergence
 */
export const usePrepareConvergenceData = ({
  convergenceContent,
  isShowAvailableSettings,
  activeTab,
  isAuthenticated,
  isChangeTariff,
  convergence,
  isConvergence,
}) => {
  const homeInternetServiceTypes = convergenceContent?.homeInternetServiceTypes;

  const disableInAuthZone = ['WIFI_RENT', 'IPTV_CONSOLE_RENT', 'WiFiRouter', 'IptvRent'];

  const convergenceOnlyOptions =
    (isShowAvailableSettings &&
      activeTab !== 'MNPForm' &&
      (!isAuthenticated || isChangeTariff) &&
      convergence?.tariff?.fullOrderGroupServices?.reduce(
        (acc, i) => [...acc, ...i.fullOrderServices],
        [],
      )) ||
    [];

  const getHomeInternet = (i) => {
    if (!i.checked) {
      return false;
    }
    if (!isAuthenticated || !isChangeTariff) {
      return true;
    }
    if (isAuthenticated && !isConvergence && isChangeTariff) {
      return false;
    }
    if (isConvergence) {
      return i.isConnected ? true : homeInternetServiceTypes.includes(i.serviceType);
    }
    return homeInternetServiceTypes.includes(i.serviceType);
  };

  const getNeedVerification = (i) => {
    if (!i.checked || !(isAuthenticated && isChangeTariff)) {
      return false;
    }
    if (isAuthenticated && !isConvergence && isChangeTariff) {
      return true;
    }
    if (isConvergence) {
      return i.isConnected ? false : !homeInternetServiceTypes.includes(i.serviceType);
    }
    return !homeInternetServiceTypes.includes(i.serviceType);
  };

  /**
   * hiddenConvergenceOptions: объект скрытых доп.опций.
   * @type {*}
   */
  const hiddenConvergenceOptions = convergence?.tariff?.fullOrderGroupServices?.find(
    (option) => option.isHidden,
  );

  const convergenceOptions = convergenceOnlyOptions?.reduce(
    (acc, i) => {
      const isHomeInternet = getHomeInternet(i);
      const isNeedVerification = getNeedVerification(i);
      const isDisabled = !i.checked && i.isConnected && isConvergence && isChangeTariff;
      const isChecked = i.checked;
      const isChanged = i.checked !== i.isConnected;
      const isHiddenOption = hiddenConvergenceOptions?.fullOrderServices?.includes(i) && isChecked;

      const callBackCondition =
        disableInAuthZone.includes(i.serviceType) && isConvergence && isChangeTariff;
      const isNeedCallBack = callBackCondition && !i.checked && i.isConnected;
      const isNeedCallBackEquipment = callBackCondition && i.checked && !i.isConnected;

      return {
        ...acc,
        homeInternetOptions:
          isHomeInternet ? [...acc.homeInternetOptions, i] : acc.homeInternetOptions,
        needVerificationInternetOptions:
          isNeedVerification ?
            [...acc.needVerificationInternetOptions, i]
          : acc.needVerificationInternetOptions,
        disabledOptions: isDisabled ? [...acc.disabledOptions, i] : acc.disabledOptions,
        allCheckedConvergenceOptions:
          isChecked || isHiddenOption ?
            [...acc.allCheckedConvergenceOptions, i]
          : acc.allCheckedConvergenceOptions,
        changedConvergenceOptions:
          isChanged ? [...acc.changedConvergenceOptions, i] : acc.changedConvergenceOptions,
        needCallBackOptions:
          isNeedCallBack ? [...acc.needCallBackOptions, i] : acc.needCallBackOptions,
        removalEquipment:
          isNeedCallBackEquipment ? [...acc.removalEquipment, i] : acc.removalEquipment,
      };
    },
    {
      homeInternetOptions: [],
      needVerificationInternetOptions: [],
      disabledOptions: [],
      allCheckedConvergenceOptions: [],
      changedConvergenceOptions: [],
      needCallBackOptions: [],
      removalEquipment: [],
    },
  );

  const {
    homeInternetOptions,
    needVerificationInternetOptions,
    disabledOptions,
    allCheckedConvergenceOptions,
    changedConvergenceOptions,
    needCallBackOptions,
    removalEquipment,
  } = convergenceOptions;

  const isNeedConnectedConvergence = !isConvergence && !!allCheckedConvergenceOptions.length;
  const hasChangedConvergenceOptions = !!changedConvergenceOptions.length;
  const hasChangedConnectedConvergenceOptions = isConvergence && !!changedConvergenceOptions.length;
  const isNeedCallBackOptions =
    !!needCallBackOptions.length || (isConvergence && !convergence?.tariff?.isConnected);

  const currentCBSource = isConvergence ? changedConvergenceOptions : allCheckedConvergenceOptions;
  const isNeedDefaultCallBackOptions =
    !!currentCBSource.length &&
    currentCBSource.every((i) => disableInAuthZone.includes(i.serviceType));

  const isNeedCallBackAndRemoveEquipment =
    !!removalEquipment.length &&
    changedConvergenceOptions.length > removalEquipment.length &&
    removalEquipment.every((i) => disableInAuthZone.includes(i.serviceType));

  const getOptionsNames = (list) =>
    list
      ?.map(({ name, serviceType, discountName }) =>
        serviceType === 'InternetTariff' && discountName ?
          `<br><nobr>${discountName}</nobr>`
        : name,
      )
      .join(', ');

  const getOptionsPrice = (list) => list?.reduce((acc, i) => acc + i.subscriptionFee, 0);

  const homeInternetOptionsSum = getOptionsPrice(homeInternetOptions);
  const needVerificationInternetOptionsSum = getOptionsPrice(needVerificationInternetOptions);

  return {
    homeInternetOptions,
    needVerificationInternetOptions,
    disabledOptions,
    allCheckedConvergenceOptions,
    isNeedConnectedConvergence,
    hasChangedConvergenceOptions,
    getOptionsNames,
    getOptionsPrice,
    homeInternetOptionsSum,
    needVerificationInternetOptionsSum,
    isNeedCallBackOptions,
    isNeedDefaultCallBackOptions,
    isNeedCallBackAndRemoveEquipment,
    removalEquipment,
    hasChangedConnectedConvergenceOptions,
  };
};
