import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import Presets from '../../Check/Presets';
import MainPackagePrice from '../../Check/MainPackagePrice';
import Options from '../../Check/Options';
import { totalUnitPropTypesShape } from '../../Check/constants';

const cx = classNames.bind(styles);

const Check = memo(
  ({ info, total, content, totalSum, gbAndMinutesLongRate, gbAndMinutesDiscountRate }) => {
    const totalOptionsSum = useMemo(
      () =>
        Object.values(total?.options).reduce(
          (sum, current) => (current.checked ? sum + current.sumDay : sum),
          0,
        ),
      [total],
    );

    return (
      <div className={cx('check')}>
        <div className={cx('check__header')}>
          <Text color="grey95" size="size3-m">
            {content.title}
          </Text>
        </div>
        <div className={cx('check-unit')}>
          <div className={cx('check-row')}>
            <div className={cx('check-row__left')}>
              <Text size="size4-m" tagType="h3">
                Основной пакет
              </Text>
            </div>
            <div className={cx('check-row__right')}>
              <div className={cx('check-row__text', 'check-row__text--right')}>
                <MainPackagePrice
                  discount={gbAndMinutesDiscountRate}
                  gbAndMinutesLongRate={gbAndMinutesLongRate}
                  info={info}
                />
              </div>
            </div>
          </div>
        </div>
        <Presets total={total} />

        {total?.options && (
          <Options content={content} info={info} total={total} totalOptionsSum={totalOptionsSum} />
        )}

        <div className={cx('total')}>
          <div className={cx('total__text')}>
            <Text color="grey95" size="size3-b">
              {content.totalPriceText}
            </Text>
          </div>
          <div className={cx('total__price')}>
            <Text color="grey95" size="size1-b">
              {totalSum.discountSum || totalSum.sum}
            </Text>
          </div>
          &nbsp;
          <div className={cx('total__unit')}>
            <Text color="grey95" size="size5-m">
              {info.longRcRateUnit}
            </Text>
          </div>
          {!!totalSum.discountSum && (
            <div className={cx('total__discounted')}>
              <Text color="grey50" crossOutColor="magenta" isCrossedOut size="size5-r-s">
                {totalSum.sum}
              </Text>
            </div>
          )}
        </div>
      </div>
    );
  },
);

Check.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    titleOptions: PropTypes.string,
    totalPriceText: PropTypes.string,
  }),
  total: PropTypes.shape({
    gbytes: PropTypes.shape(totalUnitPropTypesShape),
    minutes: PropTypes.shape(totalUnitPropTypesShape),
    options: PropTypes.object,
  }),
  info: PropTypes.shape({
    longRcRateUnit: PropTypes.string,
    shortRcRateUnit: PropTypes.string,
    tariffOptions: PropTypes.array,
  }),
  totalSum: PropTypes.shape({
    sum: PropTypes.string,
    discountSum: PropTypes.string,
  }),
  gbAndMinutesLongRate: PropTypes.number,
  gbAndMinutesDiscountRate: PropTypes.number,
};

export { Check };
