import React from 'react';
import { Text } from '@beef/ui-kit';

import { cyrillicWordsRegex, digitsRegex, latinWordsRegex, symbolsRegex } from 'utils/validators';
import { isEmptyValue } from 'utils/isEmptyValue';
import {
  setErrorText,
  setErrorValidation,
  setLoaderContentStep,
  setStepModalSpn,
} from 'pages/SPN/store/actions';
import store from 'store';
import {
  selectErrorTextInModalsContent,
  selectMainStepData,
  selectModalsContent,
  selectModalsDetails,
  selectStateSpn,
} from 'pages/SPN/store/actions/selectors';

import { CHILD_SOC_PRICE_KEY_VARIANTS, MODAL_STEPS_SPN } from '../store/constants';
import { spnAnalyticsParams } from '../ym';

export const textForLabel = ({ socTitle, priceInfo }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <>
    <Text size="size5-r-s">{`${socTitle}: &nbsp;`}</Text>
    <Text size="size5-m">{priceInfo}</Text>
  </>
);

export const defineWordsAndQuantity = (value) => {
  let hasLatin = false;
  let hasCyrillic = false;
  let hasDigits = false;
  let hasValidationErrorName = false;
  let hasSymbols = false;

  if (latinWordsRegex(value)) {
    hasLatin = true;
  }

  if (cyrillicWordsRegex(value)) {
    hasCyrillic = true;
  }

  if (digitsRegex(value)) {
    hasDigits = true;
  }

  if (symbolsRegex(value)) {
    hasSymbols = true;
  }

  if (hasLatin && hasCyrillic) {
    hasValidationErrorName = true;
    return { hasValidationErrorName, maxQuantityOfSymbols: 11 };
  }

  const maxQuantityOfSymbols =
    (
      hasLatin ||
      (hasLatin && hasDigits) ||
      (hasSymbols && hasLatin) ||
      (hasDigits && hasSymbols && !hasCyrillic) ||
      (hasSymbols && hasLatin && hasDigits && !hasCyrillic) ||
      (!hasSymbols && !hasLatin && hasDigits && !hasCyrillic) ||
      (hasSymbols && !hasLatin && !hasDigits && !hasCyrillic)
    ) ?
      11
    : 8;

  return {
    maxQuantityOfSymbols,
  };
};

export const openLoaderAndSetContent = (stepContent) => (dispatch) => {
  dispatch(setStepModalSpn(MODAL_STEPS_SPN.LOADER));
  dispatch(setLoaderContentStep(stepContent));
};

export const validateSPNInputValue = (value, errors) => {
  const { hasValidationErrorName, maxQuantityOfSymbols } = defineWordsAndQuantity(value);

  const { emptyFieldText, validationFailedText, forbiddenNameText } = errors;

  if (isEmptyValue(value)) {
    return { errorStr: emptyFieldText, maxQuantityOfSymbols };
  }

  if (!hasValidationErrorName) {
    return { errorStr: '', maxQuantityOfSymbols };
  }

  if (hasValidationErrorName) {
    // analytics
    spnAnalyticsParams({ error: validationFailedText });

    return { errorStr: validationFailedText, maxQuantityOfSymbols };
  }

  // analytics
  spnAnalyticsParams({ error: forbiddenNameText });

  return { errorStr: forbiddenNameText, maxQuantityOfSymbols };
};

export const validationAndSetError = ({ value, errors, dispatch }) => {
  const { errorStr, maxQuantityOfSymbols } = validateSPNInputValue(value, errors);

  if (errorStr) {
    dispatch(setErrorValidation(true));
  } else {
    dispatch(setErrorValidation(false));
  }

  dispatch(setErrorText(errorStr));
  return { maxQuantityOfSymbols, errorStr };
};

export const isRefersToEditable = () => {
  const state = store.getState();
  const spnState = selectStateSpn(state);
  const modalsExtData = selectModalsDetails(spnState);
  const childSocsEditable = modalsExtData?.childSocsEditable;
  const { soc } = selectMainStepData(state);

  return Boolean(childSocsEditable?.find((el) => el.soc === soc));
};

export const showErrorTextAndValidation = () => {
  const { dispatch, getState } = store;
  const spnState = selectStateSpn(getState());
  const modalsContentState = selectModalsContent(spnState);
  const { forbiddenNameText } = selectErrorTextInModalsContent(modalsContentState);

  dispatch(setErrorValidation(true));
  dispatch(setErrorText(forbiddenNameText));
};

export const checkChangedNetworkNameOrSoc = (choosenSocEditable, preselectedSoc) => {
  if (!choosenSocEditable) return false;

  const state = store.getState();
  const { soc, spn } = selectMainStepData(state);

  return Boolean(choosenSocEditable?.placeholder !== spn || preselectedSoc !== soc);
};

export const checkIsNotChangedSoc = (preselectedSoc) => {
  const state = store.getState();
  const { soc } = selectMainStepData(state);
  return soc === preselectedSoc;
};

export const getPriceInfo = (socObj) => {
  if (!socObj) return null;

  const findedKey = socObj?.price?.find(
    (el) => el.key === CHILD_SOC_PRICE_KEY_VARIANTS.SUBSCRIPTION_FEE,
  );

  if (findedKey) {
    return `${findedKey?.value ?? 0} ${findedKey?.unit}`;
  }

  return '0 ₽';
};
