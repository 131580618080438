import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Regular = ({ icon, label, value }) => (
  <div className={cx('regular')}>
    <Picture className={cx('valueIcon')} src={icon} />
    <div className={cx('params')}>
      {label && (
        <div
          className={cx('label', !value && 'withoutValue')}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}

      {value && <div className={cx('description')} dangerouslySetInnerHTML={{ __html: value }} />}
    </div>
  </div>
);

Regular.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
};

const List = ({ list, icon }) => (
  <div className={cx('listParams')}>
    <Picture alt="list-icon" className={cx('valueIcon')} src={icon} />

    {list && (
      <div className={cx('multiList')}>
        {list.map((item, index) => (
          <div className={cx('valueItem', item.widgetType)} key={index}>
            <span className={cx('value')} dangerouslySetInnerHTML={{ __html: item.value }} />
            {item.unit && (
              <span className={cx('unit')} dangerouslySetInnerHTML={{ __html: item.unit }} />
            )}
          </div>
        ))}
      </div>
    )}
  </div>
);

List.propTypes = {
  icon: PropTypes.string,
  list: PropTypes.array,
};

export default {
  Regular,
  List,
};
