import React from 'react';
import { PageFixer, PageRow } from '@beef/smart-kit';
import { compose, withReduxStore } from '@beef/redux';
import { withDataConverter } from '@beef/react';

import { dataConverter } from './store/dataConverter';
import { Content, Teaser, Title } from './components';
import { initializeStore } from './store';

const _ActionPage = () => (
  <>
    <Teaser />
    <PageFixer>
      <PageRow blockSpacersPreset="l" tagName="section">
        <Title />
        <Content />
      </PageRow>
    </PageFixer>
  </>
);

export const ActionPage = compose(
  withDataConverter(dataConverter),
  withReduxStore(initializeStore),
)(_ActionPage);
