import React from 'react';

import { Separation } from '../components/commonComponents/Separation';
import { BillContainer } from '../components/commonComponents/BillContainer';
import { TariffContainer } from '../components/commonComponents/TariffContainer';
import { CallbackRequestContainer } from '../components/CallbackRequestContainer';

/** Страница запроса номера телефона для обратной связи */
export const FTTBCallbackRequestPage = () => (
  <TariffContainer isShortPadding>
    <Separation>
      <CallbackRequestContainer />
    </Separation>
    <Separation sideType="right">
      <BillContainer />
    </Separation>
  </TariffContainer>
);
