import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isEmpty, pathOr } from 'ramda';
import { Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import PlainContainer from 'pages-components/PopupContainer/PlainContainer';
import SimOrderBenefits from 'pages-components/SimOrderBenefits/SimOrderBenefits';
import EmailSubscriptionForm from 'widgets/EmailSubscriptionForm';
import Discount from 'pages-components/Discount';
import GradientText from 'pages-components/GradientText';
import Ctx from 'pages-components/Ctx';
import * as endpoints from 'constants/Endpoints';
import { pushOrderEvent } from 'utils/analytics/simOrderAnalytics';
import { maskPhone, templateParser, unformatPhone } from 'utils/format-string';
import { trackCPAEvent } from 'utils/track';

import Form from './components/Form';
import styles from './SimOrderForm.pcss';
import store from '../../store/store';

const cx = classNames.bind(styles);

class SimOrderForm extends Component {
  state = {
    step: 'form',
    phone: '+7 ',
    contactPhone: '',
    isExistingCtn: false,
    errorStatus: null,
  };

  componentDidMount() {
    if (this.props.phone) {
      this.setState({
        phone: this.props.phone.replace('+7', '').replace(/ /g, ''),
      });
    }
  }

  onChange = (value) => {
    const { errorStatus } = this.state;

    if (value && value.length === 16) {
      this.sendRequest(
        {
          onlyValidation: true,
          contactPhone: value,
          mnpPhone: value,
        },
        'change',
      );
    } else if (!isEmpty(errorStatus)) {
      this.setState({ errorStatus: null, isExistingCtn: false });
    }
  };

  handleSubmit = (inputData) => {
    this.props.onStepChange('loading');

    this.setState({
      step: 'loading',
      phone: unformatPhone(inputData.contactPhone),
    });

    this.sendRequest(inputData, 'submit');
  };

  pushCPAEvent = (orderId, price) =>
    trackCPAEvent({
      soc: this.props.tariffAlias,
      type: 'Number',
      orderId,
      price,
    });

  sendRequest = (inputData, actionType) => {
    const { tariffId, optionSocs, constructorId } = this.props;

    this.setState({ step: 'loading' });

    const regionMeta = document.querySelector('meta[name="REGION_ID"]');
    const phone = unformatPhone(inputData.mnpPhone);
    const contactPhone = unformatPhone(inputData.contactPhone);

    let requestData = {
      ...this.props.query,
      region: regionMeta ? regionMeta.content : 98140,
      phone,
      tariffId,
      contactPhone,
      optionSocs,
      constructorId,
    };

    // Если есть onlyValidation значит мы
    // идем по сценарию проверки региона номера
    if (inputData.onlyValidation) {
      requestData = {
        ...requestData,
        onlyValidation: inputData.onlyValidation,
      };
    }

    if (this.props.reservePlace) {
      requestData = {
        ...requestData,
        reservePlace: this.props.reservePlace,
      };
    }

    axios
      .post(this.props.endpoint, requestData)
      .then(({ data }) => {
        const { View, IsSucceeded, number } = data;
        const {
          IsLimitExcess,
          IsExistingCtn,
          IsDifferentRegion,
          WarningText,
          ButtonText,
          ButtonUrl,
          Content,
          OrderId,
          TariffId,
        } = View;

        if (IsSucceeded === false) {
          throw 'error';
        }

        if (IsSucceeded && (IsDifferentRegion || WarningText) && !IsExistingCtn) {
          const stateObj = {
            step: 'form',
            errorStatus: null,
          };

          // Новая ошибка: идите в свой регион. (Вернется только если передан tariffId);
          if (IsDifferentRegion) {
            stateObj.errorStatus = {
              status: 'error',
              emoji: 'statusFail',
              buttonText: ButtonText,
              buttonUrl: ButtonUrl,
              content: Content,
            };
          }

          // Новая ошибка: Будет изменен регион. (Вернется только если не передан
          // tariffId и передан параметр OnlyValidation);
          if (WarningText) {
            stateObj.errorStatus = {
              status: 'attention',
              emoji: 'pointingHandUp',
              content: WarningText,
            };
          }

          this.setState(stateObj);
          return;
        }

        if (IsLimitExcess) {
          throw 'limitedExcess';
        }

        this.setState({ isExistingCtn: IsExistingCtn });

        if (IsExistingCtn) {
          this.setState({ step: 'form' });
          return;
        }

        this.orderNumber = OrderId;
        this.tariffId = TariffId;

        // Если запрос ушел через поле ввода, оставляем пользователя на форме;
        // Если Запро уше по клику на кнопку, выводим окно успеха;
        if (IsSucceeded && actionType === 'change') {
          this.setState({ step: 'form' });
        } else if (IsSucceeded && actionType === 'submit') {
          const orderId = data.OrderId || number || (View && OrderId);
          pushOrderEvent(orderId, true);
          this.pushCPAEvent(orderId);

          this.props.onStepChange('success');
          this.setState({ step: 'success' });
        }
      })
      .catch((error) => {
        pushOrderEvent();
        this.props.onStepChange('error');

        this.setState({ step: typeof error === 'string' ? error : 'error' });
      });
  };

  resetState = () => {
    const { onReset } = this.props;

    this.setState({ step: 'form', phone: '' });

    if (typeof onReset === 'function') onReset();
  };

  render() {
    const {
      hideBenefit,
      content,
      formData,
      discountValue,
      isSimDiscountDeliveryOnly,
      productTypeTitle,
      emailSubscriptionTexts,
      tariffCardStyle,
    } = this.props;

    const { step, phone, errorStatus, isExistingCtn } = this.state;

    const showSubscriptionFeeDiscount = !isSimDiscountDeliveryOnly && !!discountValue;
    const showDeliveryDiscount = isSimDiscountDeliveryOnly && productTypeTitle?.discount;
    const { simOrderForm } = pathOr(
      {},
      ['external', 'tariffConnectionPopup', 'data'],
      store.getState(),
    );
    const texts = content[step] || {};
    const showEmailForm = emailSubscriptionTexts ? step === 'success' : false;
    const description = templateParser(texts.description, { contactPhone: maskPhone(phone) });
    const buttonText = !['loading', 'form'].includes(step) && 'Вернуться на сайт';
    const isShowForm = step === 'form' || step === 'loading';

    return (
      <div className={cx({ tariffCardStyle })}>
        {showSubscriptionFeeDiscount && (
          <Heading className={cx('heading')} level="3">
            {Ctx.tariff.title.discountedSim}
            <GradientText className={cx('discountValue')} text={`${discountValue}%`} />
          </Heading>
        )}

        {showDeliveryDiscount && (
          <div className={cx('discount')}>
            <Discount.Label className={cx('discountLabel')} label={productTypeTitle?.discount} />
            <span className={cx('text')}>{Ctx.deliveryForm.deliveryDiscountLabel}</span>
          </div>
        )}

        {showEmailForm && (
          <EmailSubscriptionForm
            contactNumber={maskPhone(phone)}
            orderNumber={this.orderNumber}
            tariffId={this.tariffId}
            texts={emailSubscriptionTexts}
          />
        )}
        {!showEmailForm && (
          <PlainContainer
            buttonText={buttonText}
            className={cx('container')}
            description={description}
            emoji={texts.emoji}
            heading={texts.heading}
            onButtonClick={this.resetState}
          >
            {isShowForm && (
              <>
                <Form
                  data={formData}
                  discountValue={discountValue}
                  errorStatus={errorStatus}
                  isExistingCtn={isExistingCtn}
                  onChange={this.onChange}
                  onSubmit={this.handleSubmit}
                  phone={phone}
                  step={step}
                />

                {simOrderForm?.additionalDescription && (
                  <div
                    className={cx('text')}
                    dangerouslySetInnerHTML={{ __html: simOrderForm.additionalDescription }}
                  />
                )}

                {!hideBenefit && (
                  <SimOrderBenefits benefits={[]} className={cx('benefits')} showAgreement />
                )}
              </>
            )}
          </PlainContainer>
        )}
      </div>
    );
  }
}

SimOrderForm.defaultProps = {
  endpoint: endpoints.PROMO_ORDER_LEAD,
  content: {
    success: {
      emoji: 'smile-happy',
      heading: 'Супер',
      description:
        'Наш специалист свяжется с вами по номеру<br />{{contactPhone}} для уточнения деталей',
    },
    error: {
      heading: 'Упс, что-то пошло не так',
      description:
        'При отправке запроса произошла ошибка,<br />наши специалисты уже работают над этим!',
    },
    limitedExcess: {
      heading: 'Ваш заказ уже в обработке',
      description: `Мы перезваниваем в течение часа после оформления заказа по будням в дневное время.
                    Пожалуйста ожидайте.`,
    },
  },
  query: {},
  phone: '+7 ',
  onStepChange: () => {},
};

SimOrderForm.propTypes = {
  tariffId: PropTypes.string,
  emailSubscriptionTexts: PropTypes.string,
  tariffCardStyle: PropTypes.string,
  reservePlace: PropTypes.string,
  tariffAlias: PropTypes.string,
  endpoint: PropTypes.string,
  phone: PropTypes.string,

  hideBenefit: PropTypes.bool,
  isSimDiscountDeliveryOnly: PropTypes.bool,

  discountValue: PropTypes.number,

  onReset: PropTypes.func,

  query: PropTypes.object,
  formData: PropTypes.object,
  content: PropTypes.object,
  onStepChange: PropTypes.func,

  productTypeTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  optionSocs: PropTypes.string,
  constructorId: PropTypes.string,
};

export default SimOrderForm;
