import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import Icon from 'pages-components/Icon';
import Ctx from 'pages-components/Ctx';

// Styles
import baseStyles from '../styles.pcss';
import selfStyles from './styles.pcss';

const cx = classNames.bind({
  ...baseStyles,
  ...selfStyles,
});

/**
 * @param icon - Иконка слева от текста;
 * @param text  - Текст кнопки;
 * @param onClick - Callback вызывается по клику на блок;
 * @param className - Дополнительный класс;
 * @param hideRow - Указывает на возможность скрывать стрелку;
 */
const SingleRowButton = ({ icon, text, onClick, className, hideRow }) => (
  <div className={cx('tiles', 'borderButton', 'component', className)}>
    {onClick && (
      <EmptyButton aria-label={text} className={cx('outerButton', 'clickable')} onClick={onClick} />
    )}
    <div className={cx('about')}>
      {icon && (
        <span className={cx('icon')}>
          <Icon src={icon} />
        </span>
      )}
      <span className={cx('text')} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
    {!hideRow && <div className={cx('row')} />}
  </div>
);

SingleRowButton.defaultProps = {
  icon: Ctx.tiles.constructorIcon,
  hideRow: false,
};

SingleRowButton.propTypes = {
  hideRow: PropTypes.bool,
  icon: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default SingleRowButton;
