/**
 * useFilterTariffs: хук фильтрует все тарифы региона по скорости и суммирует цену пресетов по табам.
 * @param tariffs
 * @param setPrice
 * @param activeTab
 * @param isConvergence
 * @param settingsTariff
 * @param isAuthenticated
 * @param seamlessUrlParams
 * @param hasSeamlessUrlParams
 * @param isHighSpeedAvailable
 * @param isHighSpeedAvailableFromUrl
 * @returns {{currentTariffs}}
 * hasSettingsTariff: проверка наличия настроенного пользователем тарифа.
 * isConnectedOrSeamlessConvergence: является ли текущий пользователь подключенным конвергентом
 * или же имеет query-параметры в URL.
 * showHighSpeedTariff: определение доступности скоростных тарифов.
 * currentTariffs: текущие тарифы.
 * tariffTab: табы текущих тарифов.
 * isHighSpeedAvailableFromUrl: высокоскоростные тарифы из query-параметры в URL.
 */
import { sumTotalServicesFee } from '../../../../../utils';

export const useFilterTariffs = ({
  tariffs,
  setPrice,
  activeTab,
  isConvergence,
  settingsTariff,
  isAuthenticated,
  seamlessUrlParams,
  hasSeamlessUrlParams,
  isHighSpeedAvailable,
}) => {
  let isHighSpeedAvailableFromUrl = false;
  const hasSettingsTariff = !!Object?.keys(settingsTariff).length;

  try {
    isHighSpeedAvailableFromUrl = JSON.parse(seamlessUrlParams?.isHighSpeedAvailable);
  } catch (err) {
    console.error(err);
  }

  const isConnectedOrSeamlessConvergence =
    (isAuthenticated && isConvergence && hasSettingsTariff) ||
    (!isAuthenticated && hasSeamlessUrlParams && isHighSpeedAvailableFromUrl);
  const showHighSpeedTariff =
    hasSeamlessUrlParams ? isHighSpeedAvailableFromUrl : isHighSpeedAvailable;
  const currentTariffs =
    isConnectedOrSeamlessConvergence ? tariffs : (
      tariffs?.filter((tariff) => (showHighSpeedTariff ? tariff : !tariff.isHighSpeedTariff))
    );
  const tariffTab =
    currentTariffs?.find(({ inacId }) => inacId === activeTab) || currentTariffs?.[0];

  setPrice(sumTotalServicesFee({ tariffTab }));

  return { currentTariffs };
};
