import { emptyArr } from '@beef/utils';
import { FaqPropsInterface, InfoBannerPropsType } from '@beef/smart-kit';

import { DataType } from 'pages/PromotionsPage/types';

const defaultTab = { title: 'Все акции', id: 'all' };

export type DataConverterType = {
  actionBlock: DataType[];
  defaultImg?: string;
  faq: FaqPropsInterface;
  infoBanner: InfoBannerPropsType;
  mainAction: {
    link: string;
  };
  mainActionBackgroundColor?: string;
  mainActionBackgroundPosition?: string;
  mainActionBackgroundSize?: string;
  preview: string;
  title: string;
};

export const dataConverter = (data: DataConverterType) => ({
  content: {
    title: data.title || 'Акции',
    mainAction: data.mainAction ? data.mainAction : null,
    infoBanner: Object.keys(data.infoBanner || {})?.length > 0 ? data.infoBanner : null,
    actionBlock:
      data.actionBlock ?
        {
          data: data.actionBlock,
          defaultImg: data.defaultImg,
          tabs: [
            defaultTab,
            ...data.actionBlock.map((item) => ({ title: item.title, id: item.alias })),
          ],
        }
      : emptyArr,
    faq: Object.keys(data.faq || {})?.length > 0 ? data.faq : null,
  },
});
