import PropTypes from 'prop-types';

export const totalUnitPropTypesShape = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
};

export const accumulatorUnitPropTypesShape = {
  soc: PropTypes.arrayOf(PropTypes.string),
  longRcRate: PropTypes.number,
  shortRcRate: PropTypes.number,
};

export const bannerPropTypesShape = {
  familyBanner: PropTypes.any,
};

export const REPLACED_RATES = {
  shortRate: 'SHORTRATE',
  longRate: 'LONGRATE',
  optionsRate: 'OPTIONSRATE',
  chargeAmount: 'CHARGEAMOUNT',
  partners: 'PARTNERS',
  partnerTrial: 'TRIALPERIOD',
  partnerSumAfterTrial: 'SUMAFTER',
  chargeTariffAmount: 'CHARGETARIFFAMOUNT',
  chargePresetAmount: 'CHARGEPRESETAMOUNT',
  connectedFamilyPriceTariff: 'CONNECTEDFAMILYPRICETARIFF',
};

export const FAMILY_PRICING_PERIOD = {
  DAILY: '₽/сут',
  MONTHLY: '₽/мес',
};

export const IS_DRAGON_TYPE = 'POWER3';
