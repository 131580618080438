import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@beef/legacy-ui-kit';

import EmptyButton from 'pages-components/EmptyButton';

import OpenArrowIcon from './components/OpenArrowIcon';

const onLinkCLick = (e) => {
  e.preventDefault();
};

const LinkWithArrow = ({ onClick, isOpen, children, className, linkClassName, ...props }) => (
  <EmptyButton className={className} onClick={onClick}>
    <Link className={linkClassName} onClick={onLinkCLick} {...props}>
      {children}
    </Link>
    &nbsp;
    <OpenArrowIcon isOpen={isOpen} />
  </EmptyButton>
);

LinkWithArrow.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.any,
  linkClassName: PropTypes.any,
};

export default LinkWithArrow;
