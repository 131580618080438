/**
 * @desc: проверяет, есть ли номер у пользователя в корзине
 * @param boughtNumbers { Array } номера в корзине
 * @param currentNumber { String } номер для проверки
 * @returns {boolean}
 */
const isNumberBought = (boughtNumbers, currentNumber) => {
  if (!boughtNumbers?.length || !currentNumber) {
    return false;
  }

  return !!boughtNumbers.find((number) => number === currentNumber);
};

export { isNumberBought };
