import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bubble, Emoji, RangeSlider, Status, Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { templateParser } from 'utils/format-string';

import styles from './CreditLimitTuner.pcss';

const cx = classNames.bind(styles);

class CreditLimitTuner extends Component {
  getGarantPayment() {
    const { depositData, creditData } = this.props;

    if (!this.props.isConnecting && depositData.isReturn) {
      return depositData.amountText;
    } else if (this.props.amount) {
      return this.props.amount + creditData.currency;
    }
    return '';
  }

  render() {
    const { options, currentValue, currentValueLabel, recalculatedTip, disabled, initValue } =
      this.props.creditData;
    const slider = options.find((option) => +option.value === +currentValue);
    const initialPosition = slider && +slider.position;
    const { depositData } = this.props;

    return (
      <div className={cx('limit-tuner')}>
        {currentValueLabel ?
          <div className={cx('info')}>
            <div className={cx('description')}>{currentValueLabel}</div>
            <div className={cx('value')}>
              {currentValue && `-${currentValue}`}
              {this.props.creditData.currency}
            </div>
          </div>
        : null}
        {typeof initialPosition !== 'undefined' ?
          <div className={cx('slider')}>
            {recalculatedTip && !disabled && (
              <Bubble className="bottomLeft">
                <div className={cx('bubble')}>
                  <Emoji name="statusOk" />
                  {recalculatedTip}
                </div>
              </Bubble>
            )}
            <RangeSlider
              disabled={disabled}
              initialPosition={+initialPosition}
              markSelectedLabel={this.props.isConnected}
              markedValue={initValue || currentValue}
              onValueChange={this.props.onValueChange}
              reverse
              sticky
              values={options}
            />
          </div>
        : null}
        {depositData.label ?
          <div className={cx('info', 'garant')}>
            <div className={cx('description')}>
              {depositData.label}
              {depositData.tip ?
                <Tooltip>{depositData.tip}</Tooltip>
              : null}
            </div>
            <div className={cx('value')}>{this.getGarantPayment()}</div>
          </div>
        : null}
        {!this.props.isConnecting && this.props.depositData.returnMessage ?
          <div className={cx('return-message')}>
            <Status className={[cx('leftAlignedText'), 'attention']} emoji="pointingHandUp">
              {templateParser(this.props.depositData.returnMessage, { value: currentValue })}
            </Status>
          </div>
        : null}
      </div>
    );
  }
}

CreditLimitTuner.defaultProps = {
  creditData: {},
  depositData: {},
};

CreditLimitTuner.propTypes = {
  creditData: PropTypes.object,
  depositData: PropTypes.object,
  onValueChange: PropTypes.func,
  isConnected: PropTypes.bool,
  isConnecting: PropTypes.bool,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CreditLimitTuner;
