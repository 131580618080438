/* eslint-disable */
// todo: исправить ошибки линтера
import React, { memo } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Utils
import { currentTitle } from 'pages/ProductCard/utils';

// Components
import { Text } from '@beef/ui-kit';
import { TarificationInfo } from './components/TarificationInfo';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const DefaultCheck = memo(({ content, defaultInfo: { tariffInfo } }) => (
  <ul className={cx('wrapper')}>
    {tariffInfo?.map(({ type, items }) => (
      <li key={type} className={cx('wrapper__item')}>
        <div className={cx('wrapper__item--title')}>
          <Text tagType="h3" size="size4-m">
            {currentTitle(content?.packageNames, type)}
          </Text>
        </div>
        {items && (
          <TarificationInfo
            items={items}
            extraDescription={content.titlePackageNotAvailable}
          />
        )}
      </li>
    ))}
  </ul>
));

DefaultCheck.propTypes = {
  content: PropTypes.shape({
    packageNames: PropTypes.array,
    titlePackageNotAvailable: PropTypes.string,
  }),
  defaultInfo: PropTypes.shape({
    tariffInfo: PropTypes.arrayOf(PropTypes.shape({
      items: PropTypes.array,
      type: PropTypes.string,
    })),
  }),
};
