import React, { useCallback, useContext } from 'react';
import { ActionBar, Link, Modal, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { TBroadBandInternetPopupProps } from 'pages/Services/TrustPayment/types';
import { RecursivePartial } from 'utils/types';
import { TrustPaymentContext } from 'pages/Services/TrustPayment/context';

import { ModalSections } from '../ModalSections/index';
import styles from './styles.pcss';
import { redirectToLoginPage } from './utils';

const cn = classNames.bind(styles);

export const BroadBandInternetModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  popup: RecursivePartial<TBroadBandInternetPopupProps>;
}> = ({ isOpen, onClose, popup }) => {
  const { isAuthenticated } = useContext(TrustPaymentContext);

  const { title, description, sections, button, footnotes } = popup;

  const onClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (!isAuthenticated) {
      e.preventDefault();
      redirectToLoginPage({ customRedirectPath: button?.href });
    }
  }, []);

  return (
    <Modal
      actionBar={
        <ActionBar size="m">
          {button && (
            <Link
              buttonVariant={button?.buttonVariant}
              href={button?.href || ''}
              onClick={onClick}
              variant={button?.variant}
            >
              {button.text || ''}
            </Link>
          )}
        </ActionBar>
      }
      isOpen={isOpen}
      onClose={onClose}
      size="m"
    >
      <div className={cn('modal-wrapper')}>
        {title && <Text size="size2-m">{title}</Text>}
        {description && (
          <Text color="grey60" size="size4-r">
            {description}
          </Text>
        )}
        {sections && <ModalSections sections={sections} />}
        {footnotes && <Text size="size5-r-l">{footnotes}</Text>}
      </div>
    </Modal>
  );
};
