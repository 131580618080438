import { useRef } from 'react';
import { useIsDesktopDimension, useIsMobileDimension } from '@beef/ui-kit';

import { IInfo } from '../types';
import { SCREENS_COUNT_WITHOUT_SLIDES, WINDOW_HEIGHT } from './constants';
import { useSlideScroll } from './useSlideScroll';

export type IUsePrepareSlides = {
  isOpenModal: boolean;
  pagesToScrollDesktop: number;
  pagesToScrollMobile: number;
  slides: IInfo[];
};

export const usePrepareSlides = ({
  slides,
  isOpenModal,
  pagesToScrollDesktop = 1,
  pagesToScrollMobile = 1,
}: IUsePrepareSlides) => {
  const isMobile = useIsMobileDimension();
  const isDesktop = useIsDesktopDimension();
  // Максимальное количество экранов - SCREENS_COUNT_WITHOUT_SLIDES базовых, если есть слайдер,
  // то в десктопе он добавляется, так же отнимается 1, т.к, добавлен дополнительный слайд внизу,
  // чтобы при скролле обратно многновенно не включался предыдущий
  let maxScreens = SCREENS_COUNT_WITHOUT_SLIDES + (slides?.length || 0) - 1;

  const pagesToScroll = isDesktop ? pagesToScrollDesktop : pagesToScrollMobile;

  if (isMobile) {
    maxScreens = SCREENS_COUNT_WITHOUT_SLIDES + (slides?.length ? 1 : 0) - 1;
  }

  const sliderRef = useRef<HTMLDivElement>(null);

  // Общая высота лендинга - высота одного экрана (100vh), умноженная на параметр, определяющий, насколько много
  // скроллить для переключения страницы, умноженный на количество экранов (которые должны включать
  // вертикальные слайды на десктопе)
  let maxLandingHeight =
    WINDOW_HEIGHT * pagesToScroll * (SCREENS_COUNT_WITHOUT_SLIDES + (slides?.length || 0) - 1);

  // в мобильном виде количество слайдов не учитываются, т.к. они горизонтальные, учитвается только,
  // есть ли хотя бы один или нет
  if (isMobile) {
    maxLandingHeight =
      WINDOW_HEIGHT * pagesToScroll * (SCREENS_COUNT_WITHOUT_SLIDES + (slides?.length ? 1 : 0) - 1);
  }

  let firstNumberOfScreensWithSlider = 0;

  // Количество первых экранов, включая слайдер, используется как базовое смещения для номеров
  // экранов, расположенных после слайдера. В мобильном виде включает первые 2 экрана + 1 или 0 в зависимости от того,
  // есть ли горизонтальный слайдер, а в дестопе включает все вертикальные слайды
  if (isMobile) {
    firstNumberOfScreensWithSlider = (slides?.length ? 1 : 0) + 2;
  } else {
    firstNumberOfScreensWithSlider = (slides?.length || 0) + 2;
  }

  const { activeScreen } = useSlideScroll({ maxScreens, isOpenModal, slides, sliderRef });

  return { activeScreen, maxLandingHeight, sliderRef, firstNumberOfScreensWithSlider };
};
