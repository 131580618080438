import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Button, PhoneInput } from '@carnica/ui-kit';

import { usePhone } from './hooks/usePhone';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TForm = {
  btnText?: string;
  phone?: string;
};

/** Компонент формы с кнопкой и инпутом для ввода номера пользователя */
export const Form = memo<TForm>(({ phone = '', btnText }) => {
  const { onChange, onClick, isDisabled, isLoading, errorMessage } = usePhone();

  return (
    <div className={cx('wrapper')}>
      <PhoneInput
        autoFocus
        error={errorMessage}
        label="номер телефона"
        onChange={onChange}
        placeholder="+7"
        value={phone}
      />
      <Button
        disabled={isDisabled}
        loading={isLoading}
        mode="default"
        onClick={onClick}
        view="secondary-invert"
        width="full"
      >
        {btnText}
      </Button>
    </div>
  );
});
