import { useState } from 'react';
import { FaqPropsInterface } from '@beef/smart-kit';

import { getTitle } from 'pages/MnpOrderNew/components/Faq/utils';
import { ymMNP } from 'pages/MnpOrderNew/ym';
import { useOnScreenSendAnalytics } from 'pages/MnpOrderNew/hooks';

export const useYMFaq = (data: FaqPropsInterface) => {
  const rootRef = useOnScreenSendAnalytics(data?.title || '');
  const [openPanels, setOpenPanels] = useState<Array<string>>([]);

  const handleClick = ({ panelId }: { panelId: string }) => {
    if (!panelId) {
      return;
    }
    const text = getTitle(data, panelId);
    if (text) {
      const commonYMProps = {
        action: 'click',
        item_type: 'spoiler',
        spoiler_title: text,
        object_type: 'block',
        block_title: data?.title,
      };
      if (openPanels.includes(panelId)) {
        ymMNP({
          ...commonYMProps,
          spoiler_status: 'hide',
        });
        setOpenPanels(openPanels.filter((panel) => panel !== panelId));
      } else {
        ymMNP({
          ...commonYMProps,
          spoiler_status: 'show',
        });
        setOpenPanels([...openPanels, panelId]);
      }
    }
  };
  return {
    handleClick,
    rootRef,
  };
};
