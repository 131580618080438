import React, { memo } from 'react';

import { PresetPicker } from '../PresetPicker';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectStepsInternet } from './selectors/selectStepsInternet';
import { setGbValue } from '../../../store/totalSlice';
import { selectGbValue } from './selectors/selectGbValue';
import { selectGiftIcon } from '../selectors/selectGiftIcon';

/** Контейнер для Гб ЕК */
export const GbPresetContainer = memo(() => {
  const dispatch = useAppDispatch();
  const stepsInternet = useAppSelector(selectStepsInternet);
  const activeGbValue = useAppSelector(selectGbValue);
  const icon = useAppSelector(selectGiftIcon);

  const onChange = (value: number) => {
    dispatch(setGbValue(value));
  };

  return stepsInternet ?
      <PresetPicker {...{ ...stepsInternet, icon, activeValue: activeGbValue, onChange }} />
    : null;
});
