import { createSelector } from '@reduxjs/toolkit';

import { selectConnectionType } from 'constructorV1/store/selectors/selectUser';
import { selectButtonProps } from 'constructorV1/components/constructorPage/BillContainer/selectors/selectButtonProps';
import { selectButtonLoading } from 'constructorV1/components/constructorPage/BillContainer/selectors/selectButtonLoading';
import { selectSettingsProps } from 'constructorV1/components/constructorPage/BillContainer/selectors/selectSettingsProps';
import { selectTotalAmountProps } from 'constructorV1/components/constructorPage/BillContainer/selectors/selectTotalAmountProps';

/** Селектор формирования данных чека ЕК (пресеты, опции, бонусы, табы подключения, цена, кнопка) */
export const selectBillProps = createSelector(
  selectSettingsProps,
  selectTotalAmountProps,
  selectButtonProps,
  selectButtonLoading,
  selectConnectionType,
  (settingsProps, totalAmountProps, buttonProps, isLoading, connectionType) => {
    return {
      /** Результирующий объект для компонента Settings ("черный блок") */
      settingsProps,
      /* Общий объект стоимости выбранных опций/пресетов и доп. платежей */
      totalAmountProps,
      /* Объект для кнопки в чеке */
      buttonProps: { ...buttonProps, isLoading },
      connectionType,
    };
  },
);
