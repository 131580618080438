import React, { FC } from 'react';
import classnames from 'classnames/bind';

import Shadow from '../assets/shadow.svg';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

type TBenefitsShadow = { isHidden?: boolean; isRight?: boolean };

/** Компонент - накладывает тень на блок преимуществ */
export const BenefitsShadow: FC<TBenefitsShadow> = ({ isRight = false, isHidden = false }) =>
  !isHidden ?
    <div className={cx('component', { 'component--right': isRight, 'component--left': !isRight })}>
      {/* в хроме не работает через <img /> (прозрачную картинку возвращает) поэтому затянул svg в проект */}
      <Shadow />
    </div>
  : null;
