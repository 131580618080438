import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';

import TextContainer from '../TextContainer/TextContainer';
import commonStyles from '../SharingService.pcss';

const cx = classNames.bind(commonStyles);

const DisconnectWarningPopup = ({
  opened,
  number: { number, status },
  onClose,
  onDisconnect,
  onSwitchNumber,
}) => (
  <PopupStateless onClose={onClose} opened={opened}>
    <div className={cx('content')}>
      <Heading level={1}>
        <TextContainer className={cx('title')} path={['texts', 'disconnectWarningTitle']} />
      </Heading>
      <TextContainer
        className={cx('description')}
        path={['texts', 'disconnectWarningDescription']}
        variables={{ number }}
      />
      <div>
        <div className={cx('popupButton')}>
          <Button className="big" onClick={() => onDisconnect(number, true, status)} wide>
            <TextContainer path={['texts', 'disconnectWarningButtonText']} />
          </Button>
        </div>
      </div>
      {status === 'connected' ?
        <div className={cx('links')}>
          <Link onClick={onSwitchNumber}>
            <TextContainer path={['texts', 'disconnectWarningLinkText']} />
          </Link>
        </div>
      : null}
    </div>
  </PopupStateless>
);

DisconnectWarningPopup.defaultProps = {
  number: {},
};

DisconnectWarningPopup.propTypes = {
  opened: PropTypes.bool,
  number: PropTypes.object,
  onClose: PropTypes.func,
  onDisconnect: PropTypes.func,
  onSwitchNumber: PropTypes.func,
};

export default DisconnectWarningPopup;
