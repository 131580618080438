import { YMMetricHelper, createMetric } from '@beef/metric';

import store from 'store';
import { getAccountType } from 'utils/analytics/auth';

const user = store.getState()?.external?.user;

/* https://bwiki.beeline.ru/pages/viewpage.action?pageId=655707032 */
export class MobilaApp2024Metric extends YMMetricHelper {
  protected readonly MOBILE_LANDING_PARAMS_KEY = 'LP_/mobile/';

  protected setMobilaApp2024 = (params: Record<string, any>) => {
    if (typeof window === 'undefined')
      return {
        [this.MOBILE_LANDING_PARAMS_KEY]: {
          ...params,
        },
      };
    return {
      [this.MOBILE_LANDING_PARAMS_KEY]: {
        page_title: document.title,
        page_url: window.location.href,
        page_path: window.location.pathname,
        ...params,
      },
    };
  };

  /* п.1 загрузка страницы */
  handlePageLoad() {
    this.sendParamsEvent(
      this.setMobilaApp2024({
        account_type: getAccountType(user),
        action: 'view',
        item_type: 'page',
      }),
    );
  }

  /* п.1.1 просмотр блока   */
  public handleViewBlock(data: string) {
    this.sendParamsEvent(
      this.setMobilaApp2024({
        account_type: getAccountType(user),
        action: 'view',
        item_type: 'block',
        block_title: data,
      }),
    );
  }

  /* п.2 Клик по кнопкам сторов   */
  handleAppButtonClick(data: string) {
    const parsedData = JSON.parse(data);

    this.sendParamsEvent(
      this.setMobilaApp2024({
        account_type: getAccountType(user),
        action: 'click',
        item_type: 'icon',
        icon_name: parsedData?.iconName,
        object_type: 'block',
        block_title: parsedData?.title,
      }),
    );
  }

  /* п.3 Клик по кнопке "скачать"   */
  handleModalButtonClick(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setMobilaApp2024({
        account_type: getAccountType(user),
        action: 'click',
        item_type: 'button',
        button_text: parsedData?.buttonText,
        object_type: 'block',
        block_title: parsedData?.title,
      }),
    );
  }

  /* п.4 открытие модального окна   */
  handleModalOpen(data: string) {
    this.sendParamsEvent(
      this.setMobilaApp2024({
        account_type: getAccountType(user),
        action: 'view',
        item_type: 'popup',
        popup_title: data,
      }),
    );
  }

  /* п.5 закрытие модального окна   */
  handleModalClose() {
    this.sendParamsEvent(
      this.setMobilaApp2024({
        account_type: getAccountType(user),
        action: 'click',
        item_type: 'icon',
        icon_type: 'close',
        object_type: 'popup',
        popup_title: 'сканируй, чтобы скачать приложение',
      }),
    );
  }
}

export const mobileApp2024Metric = new MobilaApp2024Metric({
  counterId: 26001372,
  isEcommerce: false,
  debug: true,
});

export const { Metric, decorateHandler, withMetric, withMetricContainer, withMetricListeners } =
  createMetric(
    {
      mobileApp2024Metric,
    },
    'debug',
  );
