import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, pathOr } from 'ramda';
import { SmartForm } from '@beef/legacy-ui-kit';

import { ResultPopupContent } from 'pages-components/ResultPopup/ResultPopup';
import { HiddenInput } from 'widgets/Authorization/index';
import EmailSubscriptionForm from 'widgets/EmailSubscriptionForm';
import { AUTH_YM_PLACE, YMAuthEvent } from 'utils/analytics/ymCommonEvents';

import ConnectOfferYandexPlusActivation from '../ConnectOfferYandexPlusActivation';

class ConnectionResultPopup extends Component {
  componentDidMount() {
    const { data, sendAnalytics } = this.props;

    if (data.requestStatus === 'COMPLETE' && sendAnalytics) {
      sendAnalytics({ isConnect: true });
    }
  }

  handleClick = () => {
    if (this.props.data && this.props.data.step === 'submitLogin') {
      const form = this.formContainer && this.formContainer.getElementsByTagName('form')[0];
      if (form) form.submit();
    } else {
      this.props.closePopup(this.props.data.needToBackMainStep);
    }
  };

  sendAnalyticsOnButtonClick = () => {
    const isWrongRegion =
      this.props.data?.step === AUTH_YM_PLACE.step.submitLogin &&
      this.props.data?.isWrongRegionError;
    if (isWrongRegion && this.props.eventType) {
      YMAuthEvent.clickWrongRegionButton(this.props.eventType);
    }
  };

  render() {
    const { data, loginForm, retryButton, textVariables, emailSubscriptionTexts } = this.props;
    if (data?.customScenario === 'yandex' && data.customScenarioData) {
      return (
        <ConnectOfferYandexPlusActivation
          content={data.customScenarioData.content}
          pollConfig={data.customScenarioData.pollConfig}
          tariffTitle={data.title}
        />
      );
    }

    if (data && data.emailSubscription && emailSubscriptionTexts) {
      return (
        <EmailSubscriptionForm
          contactNumber={textVariables?.number || ''}
          orderNumber={data.orderId}
          tariffId={data.tariffId}
          texts={emailSubscriptionTexts}
        />
      );
    }

    return (
      <ResultPopupContent
        onButtonClick={this.handleClick}
        retryButton={retryButton}
        sendAnalyticsOnButtonClick={this.sendAnalyticsOnButtonClick}
        {...this.props}
      >
        {data && data.step === 'submitLogin' && (
          <div
            ref={(node) => {
              this.formContainer = node;
            }}
          >
            <SmartForm action={loginForm.postUrl} method="post">
              <HiddenInput {...loginForm} />
              <input name="login" type="hidden" value={loginForm.login} />
              <input name="password" type="hidden" value={loginForm.password} />
            </SmartForm>
          </div>
        )}
      </ResultPopupContent>
    );
  }
}

ConnectionResultPopup.propTypes = {
  data: PropTypes.shape({}),
  loginForm: PropTypes.shape({}),
  customScenarioData: PropTypes.shape({}),
  officeMapUrl: PropTypes.string,
  customScenario: PropTypes.string,
  closePopup: PropTypes.func,
  retryButton: PropTypes.bool,
  textVariables: PropTypes.shape({
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  emailSubscriptionTexts: PropTypes.shape({}),
};

const mapStateToProps = (state) => {
  const tariffData = pathOr({}, ['external', 'tariffConnectionPopup'], state);
  const downsellPayload = pathOr({}, ['downSellPayload', 'offer'], tariffData);
  const downsellPayloadItems = pathOr([], ['items'], downsellPayload);
  const downsellTitle = downsellPayloadItems.length && downsellPayloadItems[0].title;
  const officeMapUrl = pathOr(null, ['data', 'postpaidForm', 'officeMapUrl'], tariffData);
  const retryButton = tariffData.canRetry;

  const offerType = pathOr(null, ['type'], tariffData);
  const offerTypeData = pathOr(null, ['data', 'type'], tariffData);

  const currentTariffData = tariffData.resultPopupData || tariffData.data;
  let tariffDataTitle = currentTariffData.title;

  if (
    !isEmpty(downsellPayload) &&
    tariffData.data &&
    tariffData.data.title &&
    offerTypeData !== 'question' &&
    offerTypeData !== 'callBack'
  ) {
    tariffDataTitle = tariffData.data.title.replace('{0}', `${downsellTitle}`);
  }

  if (
    offerType &&
    (offerType !== 'question' || offerType !== 'callBack') &&
    tariffData.resultPopupData &&
    offerTypeData !== 'callBack'
  ) {
    tariffDataTitle = tariffData.resultPopupData && tariffData.resultPopupData.title;
  }

  return {
    data: {
      ...currentTariffData,
      title: tariffDataTitle,
    },
    loginForm: pathOr({}, ['data', 'loginForm'], tariffData),
    downsellPayload,
    officeMapUrl,
    retryButton,
    textVariables: {
      number: tariffData.orderedFancyNumber,
      contactNumber: tariffData.phone,
    },
  };
};

export default connect(mapStateToProps)(ConnectionResultPopup);
