import { createSelector } from '@reduxjs/toolkit';

import { selectTariffSettings } from 'constructorV1/store/selectors/selectTariffSettings';
import { selectGiftBillIcon } from 'constructorV1/components/constructorPage/BillContainer/selectors/selectGiftBillIcon';

/** Селектор выборки подарков для чека */
export const selectBonuses = createSelector(
  selectTariffSettings,
  selectGiftBillIcon,
  (tariffSettings, icon) => tariffSettings?.bonuses?.map((item) => ({ ...item, icon })),
);
