import React, { Children, PureComponent } from 'react';
import PropTypes from 'prop-types';

import PopupWithBack from 'pages-components/PopupWithBack/PopupWithBack';
import { FadeSlide } from 'pages-components/Animations/index';

import styles from './Wizard.pcss';

export function Step(children) {
  return <div>{children}</div>;
}

Step.displayName = 'Step';

class Wizard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      step: this.props.activeStep,
      nextStep: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activeStep !== nextProps.activeStep) {
      this.setState({
        step: null,
        nextStep: nextProps.activeStep,
      });
    }

    if (this.props.opened !== nextProps.opened || this.state.step === null) {
      setTimeout(() => {
        this.setState({ step: this.props.activeStep, nextStep: this.props.activeStep });
      }, 100);
    }
  }

  handleExited = () => {
    this.setState({
      step: this.state.nextStep,
      nextStep: null,
    });
  };

  render() {
    const { children, className } = this.props;
    const params = {};
    Children.forEach(children, (el) => {
      params[el.props.name] = {
        onBack: el.props.onBackClick,
        backLink: el.props.backLinkText,
        onClose: el.props.onClose,
      };
    });

    const step = params[this.props.activeStep] || {};
    const backLinkText = step.backLink || this.props.backLinkText;

    return (
      <PopupWithBack
        {...this.props}
        backLinkText={backLinkText}
        onBackClick={step.onBack}
        onClose={step.onClose || this.props.onClose}
        showBackButton={step.onBack && step.backLink}
      >
        <div className={`${styles.wrapper} ${className}`}>
          {Children.map(children, (element) =>
            element.type.displayName === Step.displayName ?
              <FadeSlide
                appear
                in={this.state.step === element.props.name}
                mountOnEnter
                onExited={() => this.handleExited(params[this.state.nextStep])}
                timeout={{ enter: 300, exit: 100 }}
                unmountOnExit
              >
                <div>{element.props.children}</div>
              </FadeSlide>
            : null,
          )}
        </div>
      </PopupWithBack>
    );
  }
}

Wizard.propTypes = {
  activeStep: PropTypes.string.isRequired,
  opened: PropTypes.bool,
  backLinkText: PropTypes.string,
  onClose: PropTypes.func,
};

export default Wizard;
