import { BlockInfoProps } from 'pages/Services/RedirectServiceCardLanding/components/BlockInfo/types';
import { getListForBlockInfo } from 'pages/Services/RedirectServiceCardLanding/components/BlockInfo/utils';

export const useBlockInfo = ({ options, settings, contentDpc }: BlockInfoProps) => {
  const list = getListForBlockInfo({
    options,
    settings,
  });

  const priceInfo = contentDpc?.productParameters?.[0];

  const title = settings?.activatedTitle;

  return {
    list,
    title,
    priceInfo,
  };
};
