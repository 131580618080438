import React, { FC } from 'react';
import { Faq, PageFixer, PageRow } from '@beef/smart-kit';

import { isEmptyValue } from 'utils/isEmptyValue';
import { usePageRow } from 'utils/hooks/usePageRow';
import { RedirectServiceCardContainerProps } from 'pages/Services/RedirectServiceCardLanding/components/RedirectServiceCardContainer/types';
import { BlockInfo } from 'pages/Services/RedirectServiceCardLanding/components/BlockInfo';

import { Banner } from '../Banner';

export const RedirectServiceCardContainer: FC<RedirectServiceCardContainerProps> = ({
  faq,
  teaserBanner,
}) => {
  const verticalSpaceValue = usePageRow();

  return (
    <div>
      <PageRow>{!isEmptyValue(teaserBanner) && <Banner {...teaserBanner} />}</PageRow>
      <PageFixer size="l">
        <PageRow space={verticalSpaceValue}>
          <BlockInfo />
        </PageRow>
        {!isEmptyValue(faq) && (
          <PageRow space={verticalSpaceValue}>
            <Faq data={faq.sections} title={faq.title} />
          </PageRow>
        )}
      </PageFixer>
    </div>
  );
};
