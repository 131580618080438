import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { Loader } from '@beef/legacy-ui-kit';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import ServiceTimeoutPopup from 'pages/ProductCard/components/ServiceTimeoutPopup/ServiceTimeoutPopup';
import { ResultPopupContent } from 'pages-components/ResultPopup/ResultPopup';
import Wizard, { Step } from 'pages-components/Wizard/Wizard';

import {
  cancelConnectService,
  disconnectService,
  onDisconnectResultShown,
  toggleDisconnectPopup,
} from '../../actions/serviceConnect';
import WaitingPopup from '../WaitingPopup/WaitingPopup';
import ServiceDisconnectPopupContent from './ServiceDisconnectPopupContent';
import styles from './ServiceDisconnectPopup.pcss';

const cx = classNames.bind({ ...styles });

export class ServiceDisconnectionPopup extends Component {
  getCurrentStep = () => {
    const { isPending, resultPopupData, waitingPopup, serviceProcess } = this.props;

    let step = 'pending';
    if (!isPending) {
      step = 'main';
    }

    if (resultPopupData && Object.keys(resultPopupData).length > 0) {
      step = 'result';
    } else if (waitingPopup) {
      if (waitingPopup.opened || waitingPopup.data?.step === 'pending') {
        step = 'waiting';
      }
    }

    if (serviceProcess === 'timeout') {
      step = 'timeout';
    }

    return step;
  };

  handleConfirmClick = (disconnectDate) => {
    if (this.props.isCancel) {
      this.props.onCancelConnection();
    } else {
      this.props.onConfirm(
        disconnectDate,
        this.props.disconnectUrl,
        this.props.soc,
        this.props.serviceName,
      );
    }
  };

  render() {
    const {
      title,
      content,
      warningText,
      confirmButtonText,
      cancelButtonText,
      serviceType,
      titleIcon,
      initialDate,
      dateText,
      resultPopupData,
      isCancel,
      onClose,
      onCancel,
      onResultShown,
      popupIsActive,
      serviceName,
      waitingPopup,
    } = this.props;

    const disconnectText =
      serviceName ? `Услуга &laquo;${serviceName}&raquo; отключается...` : 'Услуга отключается...';

    return (
      <div>
        <Wizard activeStep={this.getCurrentStep()} onClose={onClose} opened={popupIsActive}>
          <Step name="pending">
            <Loader status="active" />
            <div className={cx('text')} dangerouslySetInnerHTML={{ __html: disconnectText }} />
          </Step>
          <Step name="main">
            <ServiceDisconnectPopupContent
              cancelButtonText={cancelButtonText}
              confirmButtonText={confirmButtonText}
              content={content}
              dateText={dateText}
              initialDate={initialDate}
              isCancel={isCancel}
              onCancel={onCancel}
              onConfirm={this.handleConfirmClick}
              title={title}
              titleIcon={titleIcon}
              warningText={warningText}
            />
          </Step>
          <Step name="result" onClose={onClose}>
            <ResultPopupContent
              closePopup={onClose}
              data={resultPopupData}
              onMount={onResultShown}
              type="service"
            />
          </Step>
          <Step name="timeout" onClose={onClose}>
            <ServiceTimeoutPopup onClose={onClose} type={serviceType} />
          </Step>
          {waitingPopup ?
            <Step name="waiting" onClose={onClose}>
              <WaitingPopup
                data={waitingPopup}
                interval={waitingPopup.interval}
                opened={waitingPopup.opened}
                serviceType={serviceType}
                timeout={waitingPopup.timeout}
              />
            </Step>
          : <div />}
        </Wizard>
      </div>
    );
  }
}

ServiceDisconnectionPopup.defaultProps = {
  initialDate: new Date(),
};

ServiceDisconnectionPopup.propTypes = {
  isCancel: PropTypes.bool,
  popupIsActive: PropTypes.bool,
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  content: PropTypes.string,
  warningText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  isPending: PropTypes.bool,
  initialDate: PropTypes.object,
  dateText: PropTypes.string,
  onCancelConnection: PropTypes.func,
  resultPopupData: PropTypes.object,
  disconnectUrl: PropTypes.string,
  soc: PropTypes.string,
  serviceName: PropTypes.string,
  serviceProcess: PropTypes.string,
  serviceType: PropTypes.string,
  waitingPopup: PropTypes.object,
};

const mapStateToProps = (state) => {
  const popup = pathOr({}, ['external', 'serviceDisconnectionPopup'], state);
  const { serviceType } = pathOr({}, ['external', 'serviceConnectionPopup'], state);

  return {
    ...popup,
    confirmButtonText: popup.buttonText,
    cancelButtonText: popup.buttonResetText,
    waitingPopup: pathOr(null, ['waitingPopup'], popup),
    serviceType,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onConfirm:
    ownProps.onConfirm ||
    ((disconnectDate, url, soc, serviceName) =>
      dispatch(
        disconnectService(
          new Date(
            Date.UTC(
              disconnectDate.getFullYear(),
              disconnectDate.getMonth(),
              disconnectDate.getDate(),
            ),
          ),
          url,
          soc,
          serviceName,
        ),
      )),
  onCancelConnection: () => dispatch(cancelConnectService()),
  onClose: () => dispatch(toggleDisconnectPopup(false)),
  onCancel: () => dispatch(toggleDisconnectPopup(false)),
  onResultShown: () => dispatch(onDisconnectResultShown()),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(ServiceDisconnectionPopup);

export default ConnectedComponent;
