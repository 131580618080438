import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { RadioGroup } from '@beef/ui-kit';

import { getFilterContent } from '../../../store/selectors/content';
import { getActiveFilter } from '../../../store/selectors/filter';
import { resetPageUrl, setActiveFilter } from '../../../store/actions/filter';
import { SelectItemShape } from '../../../shapes';
import { ScrollToEdgesWrap } from '../../ScrollToEdgesWrap/index';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

const SelectLayout = ({ value, items, onChange, onClick }) => (
  <ScrollToEdgesWrap className={cx('filters')}>
    <RadioGroup
      items={items}
      name="filter"
      onChange={(event) => onChange(event.target.value)}
      onClick={(event) => onClick(event.target.value)}
      position="horizontal"
      size="small"
      value={value}
    />
  </ScrollToEdgesWrap>
);

SelectLayout.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape(SelectItemShape)),
};

const mapStateToProps = (state) => ({
  value: getActiveFilter(state),
  items: getFilterContent(state).filters.map((filter) => ({
    itemValue: filter.alias,
    label: filter.label,
  })),
});

const mapDispatchToProps = {
  onChange: setActiveFilter,
  onClick: resetPageUrl,
};

export const FilterSelect = connect(mapStateToProps, mapDispatchToProps)(SelectLayout);
