import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import BackCallPopup from 'pages/FTTB/components/BackCallPopup';
import { throttle } from 'utils/timed-functions';
import { tariffClickYM } from 'utils/analytics/tariffsYandexMetrica';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const SHOW_BLOCK_POSITION = 0.83;
// Позиция при которой надо скрывать блок при скроле вверх
const HIDE_BLOCK_POSITION = 0.79;

class BackCallSection extends PureComponent {
  state = {
    backCallPopupOpened: false,
    isVisible: false,
  };

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll, { passive: true });
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  prevPosition = null;

  onScroll = throttle(() => {
    const pos = this.getPosition();

    if (typeof pos !== 'number') return;

    const { isVisible } = this.state;

    if (pos < SHOW_BLOCK_POSITION && !isVisible) {
      this.setState({ isVisible: true });

      return;
    }

    if (pos > HIDE_BLOCK_POSITION && this.prevPosition < pos && isVisible) {
      this.setState({ isVisible: false });
    }

    this.prevPosition = pos;
  }, 50);

  getPosition = () => {
    if (!this.container) return null;

    const { top, height } = this.container.getBoundingClientRect();
    return (top + height) / (window.innerHeight + height);
  };

  showBackCallPopup = () => {
    tariffClickYM();
    this.setState({ backCallPopupOpened: true });
  };

  closeBackCallPopup = () => {
    this.setState({ backCallPopupOpened: false });
  };

  render() {
    const { backCallPopupData, showCaptcha, data, isFttbTv, isRedesign } = this.props;
    const { text, backCallLinkText, supportPhone, betweenNumber } = data;
    const { backCallPopupOpened, isVisible } = this.state;

    return (
      <div
        className={cx('wrapper', { showBlock: isVisible })}
        id="back-call-section"
        ref={(el) => {
          this.container = el;
        }}
      >
        <div className={cx('container')}>
          <img
            alt=""
            className={cx('bubbleTailImg')}
            src="//static.beeline.ru/upload/images/icons/bubble-tail.svg"
          />
          <div className={cx('textWrap')}>
            <div className={cx('title')} dangerouslySetInnerHTML={{ __html: text }} />

            <div className={cx('content')}>
              <span className={cx('phone')} dangerouslySetInnerHTML={{ __html: supportPhone }} />
              <div
                className={cx('betweenNumber')}
                dangerouslySetInnerHTML={{ __html: betweenNumber }}
              />

              <Button className={cx('backCallPopupBtn')} onClick={this.showBackCallPopup}>
                <span dangerouslySetInnerHTML={{ __html: backCallLinkText }} />
              </Button>
            </div>
          </div>
        </div>

        <BackCallPopup
          data={backCallPopupData}
          isFttbTv={isFttbTv}
          isOpen={backCallPopupOpened}
          onClose={this.closeBackCallPopup}
          redesign={isRedesign}
          showCaptcha={showCaptcha}
        />
      </div>
    );
  }
}

BackCallSection.propTypes = {
  data: PropTypes.shape({
    iconUrl: PropTypes.string,
    text: PropTypes.string,
    backCallLinkText: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  backCallPopupData: PropTypes.object,
  showCaptcha: PropTypes.bool,
  isFttbTv: PropTypes.bool,
  isRedesign: PropTypes.bool,
};

export default BackCallSection;
