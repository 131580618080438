import { createSlice } from '@reduxjs/toolkit';

import { TCommon } from './types';

const initialState: TCommon = {
  /* Статический контент тарифа */
  content: {},
  /* Объект контента по ограничениям и попапам нулевых пресетов */
  alertsData: null,
  /* Массив УТП для слайдера */
  benefits: [],
  /* Базовый url ЕК */
  baseUrl: '',
};

/** Общий контент тарифа Up */
const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
});

export const commonReducer = commonSlice.reducer;
