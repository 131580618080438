import React, { FC } from 'react';
import { TeaserBanner } from '@beef/smart-kit';
import classNames from 'classnames/bind';

// Hook
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
// Types
import { ITeaserBanner } from 'pages/Services/VoWifiServiceCardLanding/types';

// Components
import { DetailsBlock } from './components/DetailsBlock';
// Style
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const TeaserBannerContainer: FC<Partial<ITeaserBanner>> = ({
  img,
  text,
  title,
  label,
  button,
  isBackgroundImage,
}) => {
  const isMobile = useIsMobileDimension();

  return (
    <div className={cx('wrapper')}>
      <TeaserBanner
        detailsNode={
          <DetailsBlock
            button={{
              ...button,
            }}
            isMobile={isMobile}
            label={label}
            text={text}
            title={title}
          />
        }
        img={img}
        isBackgroundImage={isBackgroundImage}
        withDivider={false}
      />
    </div>
  );
};
