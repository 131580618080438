export const reduceExtendedData = (data) => {
  return data.items.reduce((acc, group) => {
    group.items.forEach((tariff, idx) => {
      const newItem = { tariff };
      if (idx === 0) {
        newItem.title = group.title;
      }
      if (acc.length > 2) {
        newItem.isHidden = true;
      }
      acc.push(newItem);
    });
    return acc;
  }, []);
};
