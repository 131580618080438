import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { getNextStep } from '../../../utils/defineStepper';
import {
  EConstructorStep,
  EDisableNextEvent,
  EUserStoryType,
  TStepperReducer,
  TStepperSlice,
} from './types';
import { fetchPartnerFmcTariffs } from '../../../api/fetchPartnerFmcTariffs';
import { fetchAvailableSchedule } from '../../../api/fetchAvailableSchedule';
import { getCheckConnection } from '../../../api/fmcAddressCheck/getCheckConnection';
import { fetchTariffUpPreset } from '../../../api/tariffupPreset/fetchTariffUpPreset';
import { fetchFmcServices } from '../../../api/fmcServices';
import { fetchBasketQuery } from '../../../api/basketQuery';
import { getUpperPrice } from '../../../api/getUpperPrice';
import { fetchFmcPresets } from '../../../api/fmсPresets';
import { userSteps } from '../../../router/userSteps';
import { deleteItem } from '../../../utils/deleteItem';

export const initialState: TStepperSlice = {
  userType: EUserStoryType.notAuthWithoutFTTB,
  currentStep: EConstructorStep.constructor,
  isLoadingNext: false,
  disabledNextList: [],
};

/** Слайс с информацией о текущем шаге конструктора и важной инфой о пользователе (тип) */
export const stepperSlice = createSlice<TStepperSlice, TStepperReducer>({
  name: 'stepper',
  initialState,
  reducers: {
    /* Текущий тип пользователя */
    setUserStoryType: (state, action: PayloadAction<EUserStoryType>) => {
      state.userType = action.payload;
    },
    /* Блокировка перехода на следующий шаг */
    disableNext: (state, action) => {
      if (!state.disabledNextList.includes(action.payload)) {
        state.disabledNextList = [...state.disabledNextList, action.payload];
      }
    },
    /* Разблокировка перехода на следующий шаг */
    enableNext: (state, action) => {
      if (state.disabledNextList.includes(action.payload)) {
        state.disabledNextList = state.disabledNextList.filter((item) => item !== action.payload);
      }
    },
    /* Следующий шаг настройки конструктора */
    nextStep: (state, action: PayloadAction<number | undefined>) => {
      // предотвращаем софтовый переход на следующий шаг по вызову экшена nextStep при блокировке
      if (state.disabledNextList.length > 0) {
        return;
      }
      const currentStepIndex = userSteps[state.userType].indexOf(state.currentStep);

      const step = getNextStep(action?.payload);
      const nextStep = userSteps[state.userType][currentStepIndex + step] ?? undefined;

      if (nextStep !== undefined) {
        state.currentStep = nextStep;
      } else {
        throw new Error('Is last constructor step!');
      }
    },
    /* Предыдущий шаг настройки конструктора */
    prevStep: (state, action: PayloadAction<number | undefined>) => {
      const currentStepIndex = userSteps[state.userType].indexOf(state.currentStep);
      const step = getNextStep(action?.payload);
      const prevStep = userSteps[state.userType][currentStepIndex - step] ?? undefined;

      if (prevStep !== undefined) {
        state.currentStep = prevStep;
      } else {
        throw new Error('Is first constructor step!');
      }
    },
    /* Переход на шаг настройки шпд */
    goToFttbConnectedSettingsStep: (state) => {
      state.currentStep = EConstructorStep.fttbConnectedSettings;
    },
    /* Переход на финальную страницу */
    goToFinalPageStep: (state) => {
      state.currentStep = EConstructorStep.finalPage;
    },
    /* Переход на первый шаг */
    goToFirstStep: (state) => {
      state.currentStep = userSteps[state.userType][0] ?? undefined;
    },
    /* Установка данных в stepperSlice из кеша (persisted) */
    setPersistedStepper: (_, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: (builder) => {
    builder
      /* getCheckConnection - вызывается после того как полностью заполнена форма адреса подключения
       * устанавливает лоадер на кнопку в момент сетевого запроса */
      .addCase(getCheckConnection.pending, (state) => {
        state.isLoadingNext = true;
      })
      .addCase(getCheckConnection.fulfilled, (state) => {
        state.isLoadingNext = false;
      })
      .addCase(getCheckConnection.rejected, (state) => {
        state.isLoadingNext = false;
      })
      /* fetchAvailableSchedule - вызывается после того как  заполнена форма адреса подключения и нажимается кнопка Далее
       * устанавливает лоадер на кнопку в момент сетевого запроса */
      .addCase(fetchAvailableSchedule.pending, (state) => {
        state.isLoadingNext = true;
      })
      .addCase(fetchAvailableSchedule.fulfilled, (state) => {
        state.isLoadingNext = false;
      })
      .addCase(fetchAvailableSchedule.rejected, (state) => {
        state.isLoadingNext = false;
      })
      /* fetchBasketQuery - метод отправки данных в корзину,
      на этапе перехода в которую требуется показать процесс загрузки в кнопке мини-чека */
      .addCase(fetchBasketQuery.pending, (state) => {
        state.isLoadingNext = true;
      })

      /* getUpperPrice - получение актуальной стоимости (presetsPrice) присета по апперу.
       * fetchFmcPresets - получение доступных ШПД персетов на главной странице (табы ШПД).
       * Disable кнопки "Далее" в мини-чеке в зависимости от статуса запроса по вышеуказанным методам */
      .addMatcher(
        isAnyOf(
          getUpperPrice.pending,
          fetchFmcPresets.pending,
          fetchFmcServices.pending,
          fetchPartnerFmcTariffs.pending,
        ),
        (state) => {
          state.disabledNextList.push(EDisableNextEvent.fetchFmcPresets);
        },
      )
      .addMatcher(
        isAnyOf(
          getUpperPrice.fulfilled,
          fetchFmcPresets.fulfilled,
          fetchFmcServices.fulfilled,
          fetchPartnerFmcTariffs.fulfilled,
        ),
        (state) => {
          state.disabledNextList = deleteItem(
            state.disabledNextList,
            EDisableNextEvent.fetchFmcPresets,
          );
        },
      )
      .addMatcher(
        isAnyOf(
          getUpperPrice.rejected,
          fetchFmcPresets.rejected,
          fetchFmcServices.rejected,
          fetchPartnerFmcTariffs.rejected,
        ),
        (state) => {
          state.disabledNextList = deleteItem(
            state.disabledNextList,
            EDisableNextEvent.fetchFmcPresets,
          );
        },
      )

      /* Начало запроса на получение пресетов тарифа НЗ АЗ (Гб, Мин)  */
      .addMatcher(isAnyOf(fetchTariffUpPreset.pending), (state) => {
        state.disabledNextList.push(EDisableNextEvent.fetchTariffupPreset);
      })
      .addMatcher(isAnyOf(fetchTariffUpPreset.fulfilled), (state) => {
        state.disabledNextList = deleteItem(
          state.disabledNextList,
          EDisableNextEvent.fetchTariffupPreset,
        );
      })
      .addMatcher(isAnyOf(fetchTariffUpPreset.rejected), (state) => {
        state.disabledNextList = deleteItem(
          state.disabledNextList,
          EDisableNextEvent.fetchTariffupPreset,
        );
      });
  },
});

export const {
  setUserStoryType,
  nextStep,
  prevStep,
  disableNext,
  enableNext,
  setPersistedStepper,
  goToFttbConnectedSettingsStep,
  goToFirstStep,
  goToFinalPageStep,
} = stepperSlice.actions;

export const stepperReducer = stepperSlice.reducer;
