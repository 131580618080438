import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { TariffTile } from '@beef/smart-kit';

import { slConstructorClick } from 'utils/analytics/sl';
import { ymMobTariffsToDetail } from 'utils/analytics/tariffCard';
import { useOnScreenAnalytics } from 'pages/TariffsCatalog2021/hooks/useOnScreenAnalytics';
import { tariffCardAnalyticsProps } from 'pages/TariffsCatalog2021/utils/tariffCardAnalyticsProps';
import { ymAnalyticTariffCardClick } from 'pages/TariffsCatalog2021/utils/ymAnalyticTariffCardClick';
import { ETariffsVkGoals, vkPushSimTariffEvent } from 'utils/analytics/simTariffVkEvents';

/** Компонент-обертка карточки тарифа в каталоге */
export const Tariff = ({ data: { type, ...data } = {}, authStatus }) => {
  /* Устанавливаем presetsRef на обертку карточки тарифа */
  const presetsRef = useRef(null);

  /* Отправка аналитики при видимости блока карточки тарифа */
  useOnScreenAnalytics({
    ref: presetsRef,
    threshold: 0.5,
    payload: tariffCardAnalyticsProps({ data, type: 'view', authStatus }),
  });

  return (
    <div ref={presetsRef}>
      <TariffTile
        {...data}
        onLinkClick={(event) => {
          event.stopPropagation();
          slConstructorClick(data);
          ymMobTariffsToDetail(data);
          ymAnalyticTariffCardClick({
            data,
            authStatus,
            type: 'go_to_detail',
          });
          vkPushSimTariffEvent(data?.alias, ETariffsVkGoals.SimTariffOpenCart);
        }}
      />
    </div>
  );
};

Tariff.propTypes = {
  authStatus: PropTypes.string,
  data: PropTypes.object,
};
