import { path, pathOr } from 'ramda';
import { connect } from 'react-redux';

import FormProvider from './components/FormProvider';
import {
  onRequestSMS,
  onResetForm,
  onResetPassword,
  onSetAuthCtn,
  onSetAuthLogin,
  onSetAuthPassword,
  onSetMobileIdCaptcha,
  onStepChange,
  onSubmitCtn,
  onSubmitMobileId,
  onSwitchForm,
} from './actions/authorization';
import { LOGIN_TYPES } from './actions/constants';
import mock from './data/mock';

const mapStateToProps = ({ external }) => ({
  step: path(['authorization', 'authStep'], external),
  ctn: pathOr('', ['authorization', 'authCtn'], external),
  mobileIdCaptcha: pathOr('', ['authorization', 'mobileIdCaptcha'], external),
  login: pathOr('', ['authorization', 'authLogin'], external),
  password: pathOr('', ['authorization', 'authPassword'], external),
  loginType: pathOr(LOGIN_TYPES.unknown, ['authorization', 'loginType'], external),
  fetchingSMS: pathOr(false, ['authorization', 'fetchingSMS'], external),
  firstTimeLogin: pathOr(false, ['loginModel', 'firstTimeLogin'], external),
  hiddenFields: pathOr({}, ['hiddenFields'], external),
  action: pathOr('#', ['loginModel', 'postUrl'], external),
  isAudioCaptchaEnabled: pathOr(false, ['loginModel', 'isAudioCaptchaEnabled'], external),
  recoverPasswordUrl: pathOr('#', ['loginModel', 'recoverPasswordUrlString'], external),
  bisUrl: pathOr('/identity', ['loginModel', 'bisUrl'], external),
  b2bLoginUrl: pathOr('https://my.beeline.ru/login.xhtml', ['loginModel', 'b2bLoginUrl'], external),
  loading: pathOr(true, ['loginModel', 'loading'], external),
  haveBroadband: pathOr(false, ['regions', 'currentRegion', 'isFTTB'], external),
  authError: pathOr(false, ['authorization', 'authError'], external),
  smsBlocked: pathOr(false, ['authorization', 'smsBlocked'], external),
  errorMessage: pathOr('', ['authorization', 'errorMessage'], external),
  mobileIdData: pathOr(null, ['mobileIdData'], external),
  data: pathOr({}, ['texts'], mock),
});

const mapDispatchToProps = {
  submitCtn: (value, checkIsBeeline) => onSubmitCtn(value, checkIsBeeline),
  switchForm: onSwitchForm,
  resetForm: onResetForm,
  requestSMS: onRequestSMS,
  resetPassword: onResetPassword,
  setAuthCtn: onSetAuthCtn,
  setAuthPassword: onSetAuthPassword,
  setAuthLogin: onSetAuthLogin,
  handleSetCaptcha: onSetMobileIdCaptcha,
  handleStepChange: onStepChange,
  handleSubmitMobileId: onSubmitMobileId,
};

export const HeaderLoginForms = connect(mapStateToProps, mapDispatchToProps)(FormProvider);
