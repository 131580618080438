import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { TabType, Tabs, Text } from '@beef/ui-kit';

import { BlockWrap } from '../BlockWrap';
import { ScrollToEdgesWrap } from '../ScrollToEdgesWrap';
import { getHeaderByAlias } from '../../store/selectors/content';
import { getIsChangeNumber } from '../../store/selectors/change';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

type Props = {
  isChangeNumber: boolean;
  tabs: TabType[];
  title: string;
};

const HeaderLayout = ({ title, tabs, isChangeNumber }: Props) => (
  <BlockWrap className={cx('header')}>
    <Text size="size0-b" tagType="h1">
      {title}
    </Text>
    <ScrollToEdgesWrap className={cx('header_tabs')}>
      <Tabs
        onChange={() => {}}
        outerSpaceValue={20}
        selectedTab={isChangeNumber ? 'change' : 'new'}
        tabs={tabs}
        variant="tertiary"
      />
    </ScrollToEdgesWrap>
  </BlockWrap>
);

const mapStateToProps = (state) => ({
  title: getHeaderByAlias(state).title,
  tabs: getHeaderByAlias(state).tabs,
  isChangeNumber: getIsChangeNumber(state),
});

export const Header = connect(mapStateToProps)(HeaderLayout);
