import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { Price } from './components/Price';
import { ButtonContainer } from './components/ButtonContainer';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TTotalInfo = {
  onClick: () => void;
  payButton?: string;
  toPay?: string;
  totalPrice?: string;
  totalPriceText?: string;
};

/** Итоговая стоимость, кнопка и доп. описание */
export const TotalInfo = memo<TTotalInfo>(
  ({ toPay, onClick, payButton, totalPrice, totalPriceText }) => (
    <div className={cx('wrapper')}>
      <Price {...{ toPay, totalPrice }} />
      <ButtonContainer {...{ onClick, payButton, totalPriceText }} />
    </div>
  ),
);
