import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
// Components
import { InfoIcon, Link, Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const LeftSide = ({
  icon,
  link,
  title,
  linkText,
  description,
  openFamilyModal,
  showNotification,
  isDisableFamilyButton,
}) => (
  <div className={cx('wrapper')}>
    {title && (
      <div className={cx('wrapper__title')}>
        <Text color="grey95" size="size4-r" tagType="h3">
          {title}
        </Text>
      </div>
    )}
    {description && (
      <div className={cx('wrapper__description')}>
        {showNotification && isDisableFamilyButton && <InfoIcon fill="#ff0000" size="m" />}
        <Text color="grey60" size="size5-r-l">
          {description}
        </Text>
      </div>
    )}
    {link && (
      <div className={cx('wrapper__link')}>
        <Link
          href={link}
          isNoUnderline
          onClick={(e) => openFamilyModal(e)}
          rightIcon={icon}
          size="size5-r-l"
        >
          {linkText}
        </Link>
      </div>
    )}
  </div>
);

LeftSide.propTypes = {
  icon: PropTypes.node,
  link: PropTypes.string,
  title: PropTypes.string,
  linkText: PropTypes.string,
  description: PropTypes.string,
  openFamilyModal: PropTypes.func,
  showNotification: PropTypes.string,
  isDisableFamilyButton: PropTypes.bool,
};

export { LeftSide };
