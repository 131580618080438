import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import AccessibleInternetContext from 'pages/Services/AссessibleInternet/AccessibleInternetContext';
import RequestStatusWithLoader from 'pages/Services/AссessibleInternet/component/RequestStatusWithLoader';
import {
  GET_REDIRECT_ERROR,
  STEPS,
} from 'pages/Services/AссessibleInternet/accessibleInternetServiceHelper';
import { ymPushParams } from 'utils/ym';

const GosAuth = ({ texts, onClose, opened }) => {
  const { soc } = useContext(AccessibleInternetContext);

  const [status, setStatus] = useState('loading');
  const [url, setUrl] = useState('');

  const handleEsiaRequest = useCallback(() => {
    axios
      .get('/available-internet/', { params: { soc } })
      .then((res) => {
        if (res?.data.isSucceeded && res?.data.esiaUrl) {
          setStatus('success');
          setUrl(res.data.esiaUrl);
        } else {
          const errorCodesMap = {
            [GET_REDIRECT_ERROR.BLOCKED]: 'blockedError',
            [GET_REDIRECT_ERROR.DEFAULT]: 'defaultError',
            [GET_REDIRECT_ERROR.CONFLICT]: 'conflictError',
          };
          setStatus(errorCodesMap[res?.data.errorType]);
        }
      })
      .catch(() => {
        setStatus('defaultError');
      });
  }, [soc]);

  const handleButtonClick = useCallback(() => {
    if (status === 'success') {
      setStatus('loading');
    }
    onClose(STEPS.INITIAL);
  }, [status, texts]);

  useEffect(() => {
    if (opened) {
      handleEsiaRequest();
    }
  }, [opened]);

  useCallback(() => {
    if (status === 'success') {
      ymPushParams({
        page_category: {
          personal_account: { free_internet: 'auth_required_popup_view' },
        },
      });
    }
  }, [status]);

  const currentData = useMemo(() => {
    if (status === 'loading') {
      return texts[status] || {};
    }
    return texts[status] ? { ...texts[status], buttonUrl: url } : texts.defaultError;
  }, [texts, status, url]);

  return (
    <RequestStatusWithLoader
      data={currentData}
      isLoading={status === 'loading'}
      isOpen={opened}
      onClose={handleButtonClick}
      onConfirm={handleButtonClick}
    />
  );
};

GosAuth.propTypes = {
  texts: PropTypes.shape({
    success: PropTypes.object,
    blockedError: PropTypes.object,
    defaultError: PropTypes.object,
    conflictError: PropTypes.object,
  }),
  onClose: PropTypes.func,
  opened: PropTypes.bool,
};

export default GosAuth;
