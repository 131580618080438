import { TeaserBanner } from '@beef/smart-kit';
import { useIsMobileDimension } from '@beef/ui-kit';
import { useOnScreen } from '@beef/utils';
import classNames from 'classnames/bind';
import React, { PropsWithChildren, useRef } from 'react';

import { selectHeadBlockData } from 'pages/PartnerServiceCard2022/store/selectors';

import { TeaserBannerContent } from '../components/TeaserBannerContent/index';
import styles from './styles.pcss';
import { EColor } from '../types';

const cx = classNames.bind(styles);

/**
 * Хок, который стилизует тизер баннер в соответствии с дизайнами
 */
export function withTeaserBannerOverrides<
  T extends PropsWithChildren<ReturnType<typeof selectHeadBlockData>>,
>(WrappedComponent: React.FC<React.ComponentProps<typeof TeaserBanner>>) {
  const ResultingComponent: React.FC<T> = (props) => {
    const { isConnected, content, isSticky, isYandex } = props;

    const isMobile = useIsMobileDimension();
    const tag = content.subscription.text;
    const ref = useRef<HTMLDivElement>(null);
    const isOnScreen = useOnScreen(ref);

    const titleColor = isYandex || isMobile ? EColor.Grey95 : EColor.White;

    const backgroundSettings = {
      hasGradient: !isYandex,
      tagSettings:
        !isConnected && tag ?
          {
            variant: 'shapeStatic',
            textColor: EColor.Black,
            size: isMobile ? 8 : 14,
            children: tag,
          }
        : undefined,
      statusSettings:
        isConnected && tag ?
          {
            text: tag,
          }
        : undefined,
    };

    return (
      <div
        className={cx('сontainer', {
          'container--mobile': isMobile,
          'container--sticky': isSticky && !isOnScreen,
        })}
        ref={ref}
      >
        <WrappedComponent
          backgroundSettings={backgroundSettings}
          img={content.banner}
          isBackgroundImage={!isYandex}
          text={
            <TeaserBannerContent
              flags={{
                isYandex,
                isConnected,
                isOnScreen,
                isSticky,
              }}
              info={content.info}
            />
          }
          title={<span className={cx('title')}>{content.title ?? ''}</span>}
          titleColor={titleColor}
        />
      </div>
    );
  };

  return ResultingComponent;
}
