import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { SmartInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { pushUnlimPopupShown } from 'utils/ga';
import { maskPhone } from 'utils/format-string';
import { maskedPhone as validatePhone } from 'utils/validators';
import Captcha from 'pages/Feedback/components/Captcha/Captcha';
import ButtonWithPreloader from 'pages-components/ButtonWithPreloader';
import PopupStateless from 'pages-components/Popup';
import PlainContainer from 'pages-components/PopupContainer/PlainContainer';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SendSmsForm = ({ onSubmit, popupContent, sendSmsForm, ctn }) => {
  // Hooks
  const [step, setStep] = useState('ready');
  const [phone, changePhone] = useState('+7');
  const [phoneValidate, changePhoneValidate] = useState({});
  const [showCaptcha, toggleShowCaptcha] = useState(false);
  const [captcha, setCaptchaValue] = useState('');
  const [showCaptchaError, toggleShowCaptchaError] = useState(false);

  useEffect(() => {
    if (ctn) {
      const formatCtn = maskPhone(ctn);

      changePhone(formatCtn);
      changePhoneValidate(validatePhone(formatCtn));
    }
  }, [ctn]);

  const showPopup = step !== 'pending' && step !== 'ready';
  const isButtonDisabled = showCaptcha && (phone.length !== 16 || captcha.length !== 5);
  const submitForm = async () => {
    if (phoneValidate.status === 'ok') {
      setStep('pending');
      const {
        nextStep = 'ready',
        isCaptchaError,
        isCaptchaRequired,
      } = await onSubmit(sendSmsForm.requestUrl, phone, captcha, sendSmsForm.templateName);
      const analyticsMessage = isCaptchaError || nextStep === 'errorPopup' ? 'error' : nextStep;

      setStep(nextStep);
      toggleShowCaptchaError(isCaptchaError);
      toggleShowCaptcha(isCaptchaRequired);

      pushUnlimPopupShown(analyticsMessage);
      if (!showCaptchaError) setCaptchaValue('');
    }
  };

  return (
    <>
      {showPopup && (
        <PopupStateless onClose={() => setStep('ready')} opened>
          <PlainContainer
            {...popupContent[step]}
            description={popupContent[step].description.replace('{{PHONE}}', phone)}
            onButtonClick={() => setStep('ready')}
          />
        </PopupStateless>
      )}

      <div className={cx('formWrap')}>
        <SmartInput
          className={cx('phoneInput')}
          keepInitialValue
          mask="+7 ___ ___ __ __"
          name="phone"
          onBlur={() => changePhoneValidate(validatePhone(phone))}
          onChange={(value) => changePhone(value)}
          onFocus={() => changePhoneValidate({ status: 'ok' })}
          size="big"
          status={phoneValidate.status}
          value={phone}
        />
        <div className={cx('submitContainer', showCaptcha && 'withCaptcha')}>
          {showCaptcha && (
            <div className={cx('captchaWrapper')}>
              <Captcha
                captchaKey="mobile-app-form"
                disabled={step === 'pending'}
                height={51}
                inputId="captcha"
                msg="Введите символы с картинки"
                name="captcha"
                onChange={(value) => setCaptchaValue(value)}
                onFocus={() => toggleShowCaptchaError(false)}
                refreshCaptcha
                size="big"
                status={showCaptchaError ? 'fail' : 'ok'}
              />
            </div>
          )}
          <ButtonWithPreloader
            buttonText={sendSmsForm.buttonText}
            className="big"
            disabled={isButtonDisabled}
            isPreloader={step === 'pending'}
            onClick={submitForm}
            preloaderClassName={cx('buttonPreloader')}
            type="button"
          />
        </div>
      </div>
    </>
  );
};

SendSmsForm.propTypes = {
  onSubmit: PropTypes.func,
  popupContent: PropTypes.object,
  sendSmsForm: PropTypes.object,
  ctn: PropTypes.string,
};

const mapStateToProps = (state) => ({
  ctn: pathOr(null, ['external', 'currentCtn', 'ctn'], state),
});

export default connect(mapStateToProps)(SendSmsForm);
