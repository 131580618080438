import { useAppSelector } from 'constructorV1/store';
import { selectIsConnectTariff } from 'constructorV1/store/selectors/selectUser';
import { selectInitialData } from 'constructorV1/store/selectors/selectTariffSettings';
import {
  selectGbValue,
  selectMinValue,
  selectTotalOptionsSocs,
} from 'constructorV1/store/selectors/selectTotal';
import { checkArraysValuesEqual } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/utils/checkArraysValuesEqual';

/** Хук определяет, является ли тариф подключенным */
export const useIsMyTariff = () => {
  const isConnected = useAppSelector(selectIsConnectTariff);
  const initialData = useAppSelector(selectInitialData);
  const gbValue = useAppSelector(selectGbValue);
  const minValue = useAppSelector(selectMinValue);
  const optionsSocs = useAppSelector(selectTotalOptionsSocs);

  return (
    isConnected &&
    initialData?.gbValue === gbValue &&
    initialData?.minValue === minValue &&
    checkArraysValuesEqual(initialData?.optionsSocs, optionsSocs)
  );
};
