import { createSlice } from '@reduxjs/toolkit';

import { EBillStepper } from 'constructorV1/components/constructorPage/BillStepper/types';
import { basketQuery } from 'constructorV1/store/totalSlice/api/basketQuery';

import { TTotalSlice } from './types';

const initialState: TTotalSlice = {
  /* Значение пресетов Гб */
  gbValue: 0,
  /* Значение пресетов Миг */
  minValue: 0,
  /* Массив соков активных (включенных опций) */
  optionsSocs: [],
  /* Номер телефона юзера при выборе Mnp подключения */
  userPhoneNumber: '',
  /* Текущий шаг в малом чеке ЕК (чек/mnp-форма) */
  currentBillStep: EBillStepper.bill,
  /* Сок тарифа */
  soc: '',
  /* id конструктора */
  constructorId: null,
  /* Текущая цена пресетов */
  price: 0,
  /* Старая цена пресетов */
  priceWithDiscount: 0,
  /* id тарифа */
  id: null,
  isLoadingBasketQuery: false,
};

/** Slice общих подсчетов (total) */
export const totalSlice = createSlice({
  name: 'total',
  initialState,
  reducers: {
    /* Текущий шаг настройки ЕК */
    setCurrentBillStep: (state, action) => {
      state.currentBillStep = action.payload;
    },
    /* Обновление массива активных опций optionsSocs */
    setOptionsSocs: (state, action) => {
      state.optionsSocs = action.payload;
    },
    /* Обновление выбранного пользователем номера */
    setUserPhoneNumber: (state, action) => {
      state.userPhoneNumber = action.payload;
    },
    /* Обновление gbValue */
    setGbValue: (state, action) => {
      state.gbValue = action.payload;
    },
    /* Обновление gbValue */
    setMinValue: (state, action) => {
      state.minValue = action.payload;
    },
    setDataFromPackets: (state, action) => {
      state.constructorId = action.payload.constructorId;
      state.priceWithDiscount = action.payload.priceWithDiscount;
      state.price = action.payload.price;
      state.gbValue = action.payload.gbValue;
      state.minValue = action.payload.minValue;
    },
    setDataFromChangePacket: (state, action) => {
      state.constructorId = action.payload.constructorId;
      state.priceWithDiscount = action.payload.priceWithDiscount;
      state.price = action.payload.price;
    },
  },
  extraReducers: (builder) => {
    builder
      /* Запрос к корзине */
      .addCase(basketQuery.pending, (state) => {
        state.isLoadingBasketQuery = true;
      })
      .addCase(basketQuery.rejected, (state) => {
        state.isLoadingBasketQuery = false;
      })
      .addCase(basketQuery.fulfilled, (state) => {
        state.isLoadingBasketQuery = false;
      });
  },
});

export const {
  setGbValue,
  setMinValue,
  setCurrentBillStep,
  setDataFromPackets,
  setOptionsSocs,
  setUserPhoneNumber,
  setDataFromChangePacket,
} = totalSlice.actions;

export const totalReducer = totalSlice.reducer;
