import { EUserStoryType } from '../store/slices/stepperSlice/types';
import { ESuccessPageType } from '../constants';

/** Объект вариантов названий для финальной страницы на основе enum ESuccessPageType.
 * Используется в ф-ции chooseFinalPageVariant для определения конечного объекта с контентом на основе userType */
const variants: Record<EUserStoryType, ESuccessPageType> = {
  /* АЗ */
  [EUserStoryType.authConnectedSettingsFTTB]: ESuccessPageType.changeYourTariff,
  [EUserStoryType.authConnectedWithFTTB]: ESuccessPageType.addFttbToTariff,
  [EUserStoryType.authConnectedWithoutFTTB]: ESuccessPageType.changeYourTariff,
  [EUserStoryType.authNewSimNotConnectedWithFTTB]: ESuccessPageType.buyNewTariff,
  /* Сценарий authNewSimNotConnectedWithoutFTTB уведет пользователя в корзину */
  [EUserStoryType.authNewSimNotConnectedWithoutFTTB]: ESuccessPageType.buyNewTariffWithCart,
  [EUserStoryType.authNotConnectedWithFTTB]: ESuccessPageType.goToTariffWithFttb,
  [EUserStoryType.authNotConnectedWithoutFTTB]: ESuccessPageType.goToTariffWithoutFttb,

  /* НЗ */
  [EUserStoryType.notAuthWithFTTB]: ESuccessPageType.buyNewTariff,
  /* Сценарий notAuthWithoutFTTB уведет пользователя в корзину */
  [EUserStoryType.notAuthWithoutFTTB]: ESuccessPageType.buyNewTariffWithCart,

  /* Общие сценарии */
  [EUserStoryType.tariffUpArchiveWithoutFTTB]: ESuccessPageType.goToTariffWithoutFttb,
  // TODO: Ждем инфы от диза, возможно для этого сценария поменяется финалка!
  [EUserStoryType.tariffUpTransitionFromArchiveTariff]: ESuccessPageType.callBackRequest,
  /* Сценарий mnp уведет пользователя в корзину */
  [EUserStoryType.mnp]: ESuccessPageType.buyNewTariffWithCart,
  /* Сценарий tariffUpWithPartnerFTTB уведет пользователя на callBack */
  [EUserStoryType.tariffUpWithPartnerFTTB]: ESuccessPageType.buyNewTariff,
};
/** Функция позволяет получить название объекта,
 * который требуется использовать для формирования финальной страницы.
 * Название определяется в зависимости от варианта userType */
export const chooseFinalPageVariant = (
  userType: EUserStoryType,
  isCallbackRequest: boolean,
): ESuccessPageType => {
  /** Возвращаем тип callBackRequest, если isCallbackRequest - true */
  if (isCallbackRequest) {
    return ESuccessPageType.callBackRequest;
  }

  /** Определяем вариант контентного объекта на основе userType */
  const successPageType = variants[userType];

  if (successPageType) {
    return successPageType;
  }

  /* Если !successPageType (в объекте variants отсутствует значение по userType),
   выкидываем warning и возвращаем дефолтное значение */
  console.warn('Неизвестный тип пользователя userType');
  return ESuccessPageType.buyNewTariff;
};
