import { createReducer } from '@reduxjs/toolkit';

// Actions
import {
  setAccessibleTariffs,
  setActiveSettingsTab,
  setActiveTab,
  setCurrentAnimalSoc,
  setSelectedServices,
  setTotalPrice,
} from '../actions/orderTariffDetailsData';
// Constants
import { DEFAULT_TAB_VALUE } from '../../constants';

export const orderTariffDetailsData = createReducer(
  {
    animalSoc: '',
    totalPrice: 0,
    tariffs: [],
    activeTab: DEFAULT_TAB_VALUE,
    activeSettingsTab: DEFAULT_TAB_VALUE,
  },
  {
    [setActiveTab.type]: (state, { payload }) => {
      state.activeTab = payload;
    },
    [setActiveSettingsTab.type]: (state, { payload }) => {
      state.activeSettingsTab = payload;
    },
    [setTotalPrice.type]: (state, { payload }) => {
      state.totalPrice = payload;
    },
    [setCurrentAnimalSoc.type]: (state, { payload }) => {
      state.animalSoc = payload;
    },
    [setAccessibleTariffs.type]: (state, { payload }) => {
      state.tariffs = payload;
    },
    [setSelectedServices.type]: (state, { payload }) => {
      state.tariffs = payload;
    },
  },
);
