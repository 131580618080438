import { combineReducers } from '@reduxjs/toolkit';
import { composeStoreInitializer } from '@beef/redux';

const rootReducer = combineReducers({
  data: (state = {}) => state,
  renderUrl: (state = '/') => state,
  baseUrl: (state = '/') => state,
});

export const initializeStore = composeStoreInitializer(rootReducer);
