import React, { FC, useEffect } from 'react';
import { Modal } from '@beef/ui-kit';
import { compose } from '@beef/redux';
import { connect } from 'react-redux';

import { ModalSettingsProps } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/types';
import { RedirectStore } from 'pages/Services/RedirectServiceCardLanding/store';
import {
  selectLoadingAction,
  selectRedirectOptions,
  selectRedirectQPContent,
} from 'pages/Services/RedirectServiceCardLanding/store/redirect/selectors';
import { useModalSettings } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/hook';
import { setRedirectData } from 'pages/Services/RedirectServiceCardLanding/store/redirect/asyncActions';
import { resetLoadingAction } from 'pages/Services/RedirectServiceCardLanding/store/redirect';
import { ActionBarSettings } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/ActionBarSettings';
import { ModalSettingsContent } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/ModalSettingsContent';

const _ModalSettings: FC<ModalSettingsProps> = ({
  options: optionsStore,
  saveData,
  loading,
  onCloseModal,
  resetLoading,
  content,
}) => {
  const { activeTab, options, onChangeTab, headerText, formProps } = useModalSettings({
    optionsStore,
    loading,
    content,
    saveData,
  });

  useEffect(
    () => () => {
      resetLoading();
    },
    [],
  );

  return (
    <Modal
      actionBar={
        <ActionBarSettings
          activeTab={activeTab}
          content={content}
          onCloseModal={onCloseModal}
          options={options}
        />
      }
      headerBarConfig={{
        headerText,
      }}
      isOpen
      onClose={onCloseModal}
      size="m"
    >
      <ModalSettingsContent
        activeTab={activeTab}
        content={content}
        formProps={formProps}
        loading={loading}
        onChangeTab={onChangeTab}
        onCloseModal={onCloseModal}
        options={options}
      />
    </Modal>
  );
};

const mapStateToProps = (state: RedirectStore) => ({
  options: selectRedirectOptions(state),
  loading: selectLoadingAction(state),
  content: selectRedirectQPContent(state)?.settings,
});

const mapDispatchToProps = {
  resetLoading: resetLoadingAction,
  saveData: setRedirectData,
};

export const ModalSettings = compose(connect(mapStateToProps, mapDispatchToProps))(_ModalSettings);
