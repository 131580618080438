import { FontSizeType } from '@beef/ui-kit';

import { useSelectActiveInacId } from '../../../../../FmcTabsContainer/selectors/useSelectActiveInacId';
import { useSelectFmcServiceContent } from '../../selectors/useSelectFmcServiceContent';
import { useSelectFmcServicesTariffs } from '../../selectors/useSelectFmcServicesTariffs';
import { selectServiceForActiveTab } from '../../utils';

/** Хук подготовки пропов для блока TvServicesContainer */
export const usePrepareTvServices = () => {
  /** Текущий активный inacId ШПД */
  const currentActiveInacId = useSelectActiveInacId() ?? '';

  /* Доступные тарифы ШПД для настройки */
  const servicesTariffs = useSelectFmcServicesTariffs() ?? [];

  /* Доступные TV-сервисы по текущему активному табу (currentActiveInacId) */
  const service = selectServiceForActiveTab(servicesTariffs, currentActiveInacId)?.tvServices ?? {
    title: '',
    description: '',
  };

  /* Контент для страницы настройки ШПД */
  const settingsFmcPage = useSelectFmcServiceContent();
  const { tvSection } = settingsFmcPage ?? {};

  /* Пропы для кмопонента TitleContent (информационное описание блока TV-сервисов) */
  const titleContentProps = {
    ...tvSection,
    size: 'size3-m' as FontSizeType,
    extraSize: 'size5-r-s' as FontSizeType,
  };

  return { service, titleContentProps };
};
