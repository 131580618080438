import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TInfoPicture = {
  image?: string;
};

/** Блок с картинкой информационного компонента */
export const InfoPicture: FC<TInfoPicture> = ({ image }) =>
  image ?
    <div className={cx('wrapper')}>
      <img alt="" loading="lazy" src={image} />
    </div>
  : null;
