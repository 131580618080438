import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import HotNumbers from '../HotNumbers';
import Benefits from '../Benefits';
import Filter from '../Filter';
import NumbersTable from '../NumbersTable';
import styles from './index.pcss';

const cx = classNames.bind(styles);

class MainLayout extends PureComponent {
  moreClick = () => {
    this.anchor.scrollIntoView(
      {
        behavior: 'smooth',
        block: 'start',
      },
      100,
    );
  };

  render() {
    const { heading, description, hotNumbers, benefits, filters, numbersTable, widget } =
      this.props.texts;

    return (
      <>
        <div className={cx('heading')}>
          <Text size="size0-b">{heading}</Text>
        </div>

        <div className={cx('description')}>
          <Text size="size4-r">{description}</Text>
        </div>

        <HotNumbers
          onMoreClick={this.moreClick}
          texts={{
            ...hotNumbers,
            param: {
              ...widget.staticNumberParams,
            },
          }}
        />

        <Benefits benefits={benefits} />

        <span
          ref={(e) => {
            this.anchor = e;
          }}
        />

        <Filter texts={filters} />

        <NumbersTable numbersTable={numbersTable} />
      </>
    );
  }
}

MainLayout.propTypes = {
  texts: PropTypes.object.isRequired,
};

export default connect(null, null)(MainLayout);
