import { cloneElement } from 'react';
import PropTypes from 'prop-types';

/**
 * HOC компонент, подготавлявает список для табов семейного тарифа;
 * ---------------------------------------------------------------
 * @param children - Дочерний компонент;
 * @param familyNavigation - Базовый список объекта-таба семейства;
 * @param alias - Alias страницы;
 */
const FamilyNavigationHOC = ({ children, familyNavigation, alias }) => {
  const modifyFamilyNav = familyNavigation.map((item) => ({
    ...item,
    href: item.alias === alias ? null : item.link,
    active: item.alias === alias || `${item.alias}-promo` === alias,
  }));

  return cloneElement(children, {
    familyNavigation: modifyFamilyNav,
  });
};

FamilyNavigationHOC.defaultProps = {
  familyNavigation: [],
};

FamilyNavigationHOC.propTypes = {
  familyNavigation: PropTypes.array,
  alias: PropTypes.string,
  children: PropTypes.object,
};

export default FamilyNavigationHOC;
