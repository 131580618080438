import { connect } from 'react-redux';

import TariffCard from 'pages/FTTB/CatalogPage/components/TariffCard';
import { catalogPageTypeList } from 'pages/FTTB/constants';

const mapStateToProps = ({ external: { fttbCatalogData } }) => ({
  texts: fttbCatalogData.texts.tariffsTexts,
  paramsData: fttbCatalogData.texts.paramsData,
  showTVInfo: fttbCatalogData.pageType === catalogPageTypeList.kit,
  showTvClickable: fttbCatalogData.showTvClickable,
});

export default connect(mapStateToProps)(TariffCard);
