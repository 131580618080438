import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * @desc: "Этаж" на странице
 * @constructor
 */
const PageLevel = ({ color, size, children, contentSize }) => (
  <div className={cx('wrap', `wrap--color-${color}`, `wrap--size-${size}`)}>
    <div className={cx('content', `content--size-${contentSize}`)}>{children}</div>
  </div>
);

PageLevel.defaultProps = {
  color: 'white',
  size: 'm',
  contentSize: 'm',
};

PageLevel.propTypes = {
  /* цвет фона */
  color: PropTypes.oneOf(['white', 'grey-5', 'grey-30', 'commonUnderlay']),
  /* размеры внутренних отступов обертки */
  size: PropTypes.oneOf(['l', 'm', 's']),
  /* ширина контента */
  contentSize: PropTypes.oneOf(['l', 'm', 's']),
};

export default PageLevel;
