import React, { useCallback } from 'react';
import classNames from 'classnames/bind';
import { Link, Text } from '@beef/ui-kit';
import { Card, PageContainer, PageFixer, PageRow } from '@beef/smart-kit';

import errorImage from './error.png';
import styles from './styles.module.pcss';

const cx = classNames.bind(styles);

export const Error = () => {
  const onClick = useCallback((e) => {
    e.preventDefault();
    window.location.reload();
  }, []);

  return (
    <PageContainer size="s">
      <PageFixer backgroundColor="main" size="m">
        <PageRow>
          <Card className={cx('error')}>
            <div className={cx('error__img')}>
              <img alt="" src={errorImage} />
            </div>
            <div className={cx('error__text')}>
              <Text size="size4-m">Что-то пошло не так.</Text>
              <Link href="/" isNoUnderline onClick={onClick} size="size4-m">
                Перезагрузите страницу
              </Link>
            </div>
          </Card>
        </PageRow>
      </PageFixer>
    </PageContainer>
  );
};
