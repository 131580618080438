import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { sendNumberShownEvent } from '../store/actions/analytics';
import { getActiveFilter } from '../store/selectors/filter';

export const withNumberRowAnalytics = (WrappedComponent) => {
  const WithNumberRowAnalytics = ({ onViewNumber, filter, ...props }) => {
    useEffect(() => {
      onViewNumber(props.number);
    }, [filter]);

    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = (state) => ({
    filter: getActiveFilter(state),
  });

  const mapDispatchToProps = {
    onViewNumber: sendNumberShownEvent,
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithNumberRowAnalytics);
};
