import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';

// Components
import { Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import OptionSelector from 'pages-components/OptionSelector/OptionSelector';
import Picture from 'pages-components/Picture';
import PopupStateless from 'pages-components/Popup';

import TextContainer from '../TextContainer/TextContainer';
import ConnectedNumbersInfo from '../ConnectedNumbersInfo/ConnectedNumbersInfo';
import AuthHint from '../../../AuthHint/AuthHint';

// Actions
import {
  changeNumbers,
  serviceSettingsPopup,
  triggerPopupShareService,
} from '../../../../actions/sharing';
import { toggleTariffConnectionPopup } from '../../../../actions/tariffConnect/toggleTariffConnectionPopup';

// Styles
import commonStyles from '../SharingService.pcss';
import styles from './ServiceSettingsPopup.pcss';

const cx = classNames.bind({ ...styles, ...commonStyles });

class ServiceInfoPopup extends Component {
  renderHint = () => {
    const {
      authText,
      connectTariffText,
      authLinkText,
      onTariffConnect,
      onAuth,
      active,
      isAuthenticated,
      count,
    } = this.props;

    if (!isAuthenticated || count === 0) {
      return (
        <div className={cx('connected')}>
          <AuthHint
            authLinkText={authLinkText}
            connectTariffText={connectTariffText}
            onAuth={onAuth}
            onTariffConnect={onTariffConnect}
            text={authText}
          />
        </div>
      );
    }

    if (active) {
      return (
        <div className={cx('connected')}>
          <ConnectedNumbersInfo
            isConnected={this.props.isConnected}
            isPopup
            maxNumbers={this.props.count}
            numbers={this.props.numbers}
            onConnectedChange={this.props.onConnectedChange}
            ref={(c) => {
              this.connectContainer = c;
            }}
          />
        </div>
      );
    }

    return null;
  };

  renderDescription = () => {
    const { info } = this.props;
    const { price, dataSize, count, unit, addPacketSize } = info;

    if (dataSize) {
      const text =
        price === '0' || price === '0,0' ? 'dataShareSizeTextWithoutPrice' : 'dataShareSizeText';

      return (
        <TextContainer
          declension={{
            text: 'номеров',
            word: 'numbersGenitive',
            count,
          }}
          path={['texts', text]}
          variables={{
            dataSize,
            numbersCount: count,
            price: `${price} ${unit}`,
          }}
        />
      );
    }

    return (
      <TextContainer
        path={['texts', 'servicesShareDescription']}
        variables={{
          traffic: addPacketSize,
          maxNumbers: count,
          priceUnit: unit,
          price,
        }}
      />
    );
  };

  render() {
    if (!this.props.opened) return null;

    const {
      opened,
      onClose,
      title,
      detailsLink,
      detailsLinkText,
      price,
      isAuthenticated,
      familyModalPicture,
      onChangeValue,
      active,
      optionTitle,
      count,
    } = this.props;

    return (
      <div>
        <PopupStateless onClose={onClose} opened={opened}>
          <div className={cx('content')}>
            {familyModalPicture && (
              <div className={cx('familyPicture')}>
                <Picture alt="family" src={familyModalPicture} />
              </div>
            )}

            <Heading level={1}>{title}</Heading>
            <div className={cx('serviceDescription')}>{this.renderDescription()}</div>
            <OptionSelector
              checked={active}
              isAvailable={isAuthenticated && count > 0}
              name="sharedService"
              onChangeValue={onChangeValue}
              price={price}
              subTitle={this.renderHint()}
              title={optionTitle}
            />

            {detailsLinkText && (
              <div className={cx(['link', 'details-link'])}>
                <Link href={detailsLink} target="_blank">
                  {detailsLinkText}
                </Link>
              </div>
            )}
          </div>
        </PopupStateless>
      </div>
    );
  }
}

ServiceInfoPopup.defaultProps = {
  opened: false,
  detailsLink: null,
  detailsLinkText: null,
  onAuth: () => null,
};

ServiceInfoPopup.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  detailsLink: PropTypes.string,
  detailsLinkText: PropTypes.string,
  price: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  numbers: PropTypes.number.isRequired,
  onConnectedChange: PropTypes.func.isRequired,
  authText: PropTypes.string.isRequired,
  connectTariffText: PropTypes.string.isRequired,
  authLinkText: PropTypes.string,
  onTariffConnect: PropTypes.func.isRequired,
  onAuth: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  optionTitle: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  isConnected: PropTypes.bool.isRequired,
  familyModalPicture: PropTypes.string,
  info: PropTypes.object,
};

const mapStateToProps = (state) => {
  const tariff = pathOr({}, ['external', 'tariff', 'data'], state);
  const sharing = pathOr({}, ['sharing'], tariff);

  const { texts, info, links } = sharing;
  const { count, price, unit, authPopupUrl } = info;

  const parsedNumber = Number(price);
  const priceString = parsedNumber && `${parsedNumber} ${unit}`;

  return {
    opened: pathOr(false, ['serviceSettingsPopup', 'opened'], sharing),
    title: texts.servicesShareTitle,
    familyModalPicture: links.familyModalPicture,
    optionTitle: texts.servicesShareSwitchText,
    price: priceString,
    authText: texts.noAuthText,
    connectTariffText: texts.connectTariffText,
    authLinkText: texts.authLinkText,
    isAuthenticated: tariff.isAuthenticated,
    connectPopupUrl: authPopupUrl,
    isConnected: tariff.isConnected,
    count,
    info,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(serviceSettingsPopup.update({ opened: false })),
  onChangeValue: () => dispatch(triggerPopupShareService()),
  onConnectedChange: () => dispatch(changeNumbers()),
  onTariffConnect: () => {
    dispatch(serviceSettingsPopup.update({ opened: false }));
    dispatch(toggleTariffConnectionPopup(true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceInfoPopup);
