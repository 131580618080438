import { useIsConnectedTariff } from './useIsConnectedTariff';
import { useIsConvergentUser } from './useIsConvergentUser';
import { useIsArchivedTariff } from './useIsArchivedTariff';

/** Хук возвращает вариации архивных тарифов АП */
export const useArchivedCases = () => {
  const isArchivedTariff = useIsArchivedTariff();
  const isConnectedTariff = useIsConnectedTariff();
  const isConvergenceUser = useIsConvergentUser();

  const isArchivedTariffUp = isArchivedTariff && isConnectedTariff;

  /* Подключен старый ап + Подключен ШПД */
  const isArchivedTariffUpWithFttb = isArchivedTariffUp && isConvergenceUser;
  /* Подключен старый ап + Не подключен ШПД */
  const isArchivedTariffUpWithoutFttb = isArchivedTariffUp && !isConvergenceUser;
  /* Подключен не ап + Подключен ШПД */
  const isArchivedNoTariffUpWithFttb = !isConnectedTariff && isConvergenceUser;

  return {
    isArchivedTariffUpWithFttb,
    isArchivedTariffUpWithoutFttb,
    isArchivedNoTariffUpWithFttb,
  };
};
