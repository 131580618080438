import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import { ChipsStateless } from '@beef/ui-kit';

import { usePrepareTabs } from './hooks/usePrepareTabs';
import { ModalHeader } from '../../modalComponents/ModalHeader';
import { WPContainer } from '../../commonComponents/WPContainer';
import { TitleContent } from '../../commonComponents/TitleContent';
import { ModalContainer } from '../../modalComponents/ModalContainer';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Основные табы тарифа. Отвечают за выбор варианта оформления тарифного плана */
export const Tabs: FC = memo(() => {
  const {
    title = '',
    modalHeaderProps,
    chipsStatelessProps,
    modalContainerProps,
    isHideTabs,
  } = usePrepareTabs();

  if (isHideTabs) return null;

  return (
    <section className={cx('wrapper')}>
      <TitleContent size="size2-m" title={title} />
      <WPContainer>
        <ChipsStateless className={cx('wrapper__tabs')} {...chipsStatelessProps} />
      </WPContainer>
      <ModalContainer {...modalContainerProps}>
        <ModalHeader {...modalHeaderProps} />
      </ModalContainer>
    </section>
  );
});
