import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { Button, Heading, Link } from '@beef/legacy-ui-kit';

import styles from './styles.pcss';
import Tile from './Tile';

const cx = classNames.bind(styles);

class Archive extends Component {
  constructor(props) {
    super(props);

    const years = props.data.actualization.map((item) => item.year);

    this.state = {
      years,
      activeYear: years[0],
      append: true,
    };
  }

  timeoutId = null;

  setYear = (activeYear) => () => {
    clearTimeout(this.timeoutId);
    this.setState({ append: false });

    this.timeoutId = setTimeout(() => {
      this.setState({ activeYear, append: true });
    }, 300);
  };

  render() {
    const { years, activeYear, append } = this.state;
    const { actualization, heading, catalogLink, archiveLink } = this.props.data;
    const { items } = actualization.find((item) => item.year === activeYear) || {};

    return (
      <div className={cx('component')}>
        <Heading level={1}>{heading}</Heading>
        <div className={cx('tilesWrapper')}>
          <div className={cx('navigation', { append })}>
            {years.map((year) => (
              <div
                className={cx('navItem', { active: activeYear === year })}
                key={year}
                onClick={this.setYear(year)}
              >
                {year}
              </div>
            ))}
          </div>
          <div className={cx('tiles', { append })}>
            {items && items.length ?
              items.map(Tile)
            : <div className={cx('empty')}>Нет подходящих записей</div>}
          </div>
        </div>
        <div className={cx('catalogButton')}>
          <Button big className={['default', 'big']} href={catalogLink}>
            Действующие тарифы
          </Button>
        </div>
        <div className={cx('catalogButton')}>
          <Link href={archiveLink}>Архив тарифов</Link>
        </div>
      </div>
    );
  }
}

export default Archive;
