import React from 'react';
import classNames from 'classnames/bind';
import { BreadCrumbItemType, BreadCrumbs, Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

type DetailPropsType = {
  breadcrumbs: BreadCrumbItemType[];
  text: string;
  textColor?: string;
  title: string;
  titleColor?: string;
};

export const Detail = ({ breadcrumbs, title, text, titleColor, textColor }: DetailPropsType) => (
  <div className={cn('details')}>
    {breadcrumbs && (
      <div className={cn('breadcrumbs')}>
        <BreadCrumbs path={breadcrumbs} />
      </div>
    )}
    {title && (
      <Text color={titleColor} size="size0-b" tagType="h1">
        {title}
      </Text>
    )}
    {text && (
      <div className={cn('details__text')}>
        <Text color={textColor} size="size4-r">
          {text}
        </Text>
      </div>
    )}
  </div>
);
