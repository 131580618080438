import { useEffect } from 'react';

import { usePrepareDataLayer } from './usePrepareDataLayer';

/** Хук отправки DataLayer при показе страницы */
export const usePushDataLayer = () => {
  const pushDataLayer = usePrepareDataLayer();
  useEffect(() => {
    pushDataLayer('common');
  }, []);
};
