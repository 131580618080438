import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

// Components
import { DatePicker, Dropdown, Label, Link, TextInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Discount from 'pages-components/Discount';
import Ctx from 'pages-components/Ctx';

// Utils
import { pushAddressInputFocus } from 'utils/analytics/delivery';
import { getFineDate } from 'widgets/actions/deliveryForm';
import { debounce } from 'utils/timed-functions';
import { getMobileOS } from 'utils/detect-mobile';

// Styles

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class AddressForm extends Component {
  constructor(props) {
    super();
    const { firstDeliveryDate, fastDeliveryDisabled, deliveryDays, isSelectedFastDelivery } = props;

    this.state = {
      hideNativeView: false,
      firstDeliveryDate:
        fastDeliveryDisabled && isSelectedFastDelivery ?
          deliveryDays?.[1]?.date
        : firstDeliveryDate,
      showDateInput: true,
    };
  }

  componentDidMount() {
    const { fastDeliveryDisabled } = this.props;
    const { firstDeliveryDate } = this.state;

    this.setState({ hideNativeView: getMobileOS() === 'ios' });
    if (fastDeliveryDisabled) {
      this.onDayChange(firstDeliveryDate);
      // небольшой костыль, чтобы перерендерить интпут с датой
      this.setState(
        {
          showDateInput: false,
        },
        () => {
          this.setState({
            showDateInput: true,
          });
        },
      );
    }
  }

  onRegionChange = () => {
    this.props.onCancel();
    document.querySelector('#toggleButton-regions-desktop').click();
  };

  onDayChange = (value) => {
    let newValues = {
      currentDeliveryDate: null,
      currentDeliveryDateIndex: 0,
      isSelectedFastDelivery: false,
      isSelectedEmployeeDelivery: false,
    };

    if (value) {
      const selectedDeliveryDate = format(value, 'YYYY-MM-DD');
      const selectedDeliveryDateIndex = this.props.deliveryDays.findIndex(
        (el) => el.date === selectedDeliveryDate,
      );

      if (selectedDeliveryDateIndex !== -1) {
        const typeOfSelectedDeliveryDate = this.props.deliveryDays[selectedDeliveryDateIndex].type;
        newValues = {
          currentDeliveryDate: selectedDeliveryDate,
          currentDeliveryDateIndex: selectedDeliveryDateIndex,
          isSelectedFastDelivery:
            this.props.isFastDeliveryAvailable && typeOfSelectedDeliveryDate === 'FAST',
          isSelectedEmployeeDelivery:
            this.props.isEmployeeDeliveryAvailable && typeOfSelectedDeliveryDate === 'EMPLOYEE',
        };
      }
    }

    this.props.setInputValue({
      ...newValues,
      currentInterval: 1,
    });
  };

  onAddressChange = debounce((value) => {
    this.props.setInputValue({ address: value });
  }, 1000);

  onIntervalChange = (value) => {
    this.props.setInputValue({ currentInterval: value });
  };

  onAddressFocus = debounce(pushAddressInputFocus, 100);

  getDeliveryIntervals = () => {
    const {
      texts,
      currentDeliveryDate,
      deliveryDays,
      currentDeliveryDateIndex,
      fastDeliveryDisabled,
    } = this.props;

    let intervals =
      currentDeliveryDate ?
        deliveryDays[currentDeliveryDateIndex].intervals || []
      : texts.notAvailablePeriod;

    if (fastDeliveryDisabled) {
      intervals = intervals.filter(({ type }) => type !== 'fastDelivery');
    }

    return intervals;
  };

  render() {
    const {
      currentCity,
      texts,
      submitted,
      address,
      currentDeliveryDate,
      currentInterval,
      lastDeliveryDate,
      isSelectedFastDelivery,
      discountLabel,
    } = this.props;
    const { firstDeliveryDate, showDateInput } = this.state;

    return (
      <div className={cx('component')}>
        {discountLabel ?
          <div className={cx('discount')}>
            <Discount.Label className={cx('discountLabel')} label={discountLabel} />
            <span className={cx('text')}>{Ctx.deliveryForm.deliveryDiscountLabel}</span>
          </div>
        : <div
            className={cx('deliveryInterval')}
            dangerouslySetInnerHTML={{ __html: texts.deliveryDescription }}
          />
        }

        <div className={cx('dateInputs')}>
          <div className={cx('dateInput')}>
            <Label htmlFor="deliveryDate">{Ctx.deliveryForm.dateLabel}</Label>
            {showDateInput && (
              <DatePicker
                hideNativeView={this.state.hideNativeView}
                max={getFineDate(lastDeliveryDate)}
                min={getFineDate(firstDeliveryDate)}
                name="deliveryDate"
                onChange={this.onDayChange}
                size="big"
                status={submitted && !currentDeliveryDate ? 'fail' : ''}
                value={getFineDate(currentDeliveryDate)}
              />
            )}
          </div>
          <div className={cx('dateInput')}>
            <Label htmlFor="deliveryTime">{Ctx.deliveryForm.intervalLabel}</Label>
            <Dropdown
              name="deliveryTime"
              onChange={this.onIntervalChange}
              options={this.getDeliveryIntervals().map((interval, index) => ({
                value: index + 1,
                label: interval.label,
              }))}
              placeholder="Интервал"
              size="big"
              value={currentInterval}
            />
          </div>
        </div>

        <Label className={cx('label')}>
          {Ctx.deliveryForm.addressLabel}
          <Link className={cx('cityLink')} onClick={this.onRegionChange}>
            {currentCity}
          </Link>
        </Label>
        <TextInput
          id="deliveryAddress"
          name="deliveryAddress"
          onChange={this.onAddressChange}
          onFocus={this.onAddressFocus}
          size="big"
          status={submitted && !address ? 'fail' : ''}
          value={address}
        />

        <div className={cx('tip')} dangerouslySetInnerHTML={{ __html: texts.addressTip }} />

        {isSelectedFastDelivery && (
          <div
            className={cx('deliveryInterval')}
            dangerouslySetInnerHTML={{ __html: texts.fastDeliveryDescription }}
          />
        )}
      </div>
    );
  }
}

AddressForm.propTypes = {
  discountLabel: PropTypes.string,
  currentCity: PropTypes.string,
  currentDeliveryDate: PropTypes.string,
  lastDeliveryDate: PropTypes.string,
  firstDeliveryDate: PropTypes.string,
  address: PropTypes.string,

  currentDeliveryDateIndex: PropTypes.number,
  currentInterval: PropTypes.number,

  isFastDeliveryAvailable: PropTypes.bool,
  isSelectedFastDelivery: PropTypes.bool,
  isEmployeeDeliveryAvailable: PropTypes.bool,
  fastDeliveryDisabled: PropTypes.bool,
  submitted: PropTypes.bool,

  deliveryDays: PropTypes.array,
  texts: PropTypes.object,

  onCancel: PropTypes.func,
  setInputValue: PropTypes.func,
};

export default AddressForm;
