import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TPopupImage = {
  image: string;
};

/** Картинка попапа */
export const PopupImage: FC<TPopupImage> = ({ image }) => (
  <img alt="" className={cx('component')} height="190" src={image} width="190" />
);
