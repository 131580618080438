import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import TariffCard from 'pages-components/TariffCard';

// Hoc's
import SimpleTariffCardHoc from 'pages-components/TariffCard/hocs/SimpleTariffCardHoc';

// Styles
import MyBeelineBanner from '../../components/MyBeelineBanner';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffsGrid = ({
  title,
  tariffs,
  appBanner,
  isShowButton,
  archiveLink,
  archiveLinkName,
  allTariffVisible,
  allTariffsButtonName,
  handlerShowAllTariffs,
  paramsGridView,
  handleLoadModal,
}) => {
  return (
    <div className={cx('tariffsGrid')}>
      <Heading className={cx('title')} level={2}>
        {title}
      </Heading>

      {tariffs && (
        <div className={cx('grid')}>
          <ul>
            {tariffs.map((item, index) => (
              <li className={cx(item.hideUnderButton && allTariffVisible)} key={index}>
                <SimpleTariffCardHoc {...item} tariffsGroup={tariffs}>
                  <TariffCard paramsGridView={paramsGridView} />
                </SimpleTariffCardHoc>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className={cx('buttonsGroup')}>
        {isShowButton && (
          <Button className={['big', 'light']} onClick={handlerShowAllTariffs} wide>
            {allTariffsButtonName}
          </Button>
        )}

        <Link className={[cx('archiveLink'), 'hideVisitedColor']} href={archiveLink}>
          {archiveLinkName}
        </Link>
      </div>

      {appBanner && (
        <div className={cx('banner')}>
          <MyBeelineBanner {...appBanner} />
        </div>
      )}
    </div>
  );
};

TariffsGrid.propTypes = {
  title: PropTypes.string,
  archiveLinkName: PropTypes.string,
  archiveLink: PropTypes.string,
  allTariffVisible: PropTypes.string,
  allTariffsButtonName: PropTypes.string,

  isShowButton: PropTypes.bool,
  paramsGridView: PropTypes.bool,

  tariffs: PropTypes.array,

  appBanner: PropTypes.object,

  handlerShowAllTariffs: PropTypes.func,
  handleLoadModal: PropTypes.func,
};

export default TariffsGrid;
