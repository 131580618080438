import React from 'react';

// Components
import { VoWiFiDisconnected } from '../VoWiFiDisconnected';
import { VoWiFiConnected } from '../VoWiFiConnected';
import { ContentWrapper } from '../ContentWrapper';

// Hooks
import { useFetchRequest } from '../../hooks/useFetchRequest';

const VoWiFiServiceCardButton = () => {
  const { isConnected, connectionData = {}, disconnectionData = {}, status } = useFetchRequest();

  const { statusText, descriptionTitle, descriptionText } = connectionData;
  const { title, description, redirectLinkText, redirectLinkUrl } = disconnectionData;

  return (
    <ContentWrapper status={status}>
      {isConnected ?
        <VoWiFiConnected
          descriptionBodyText={descriptionText}
          descriptionTitle={descriptionTitle}
          statusText={statusText}
        />
      : <VoWiFiDisconnected
          description={description}
          linkText={redirectLinkText}
          linkUrl={redirectLinkUrl}
          title={title}
        />
      }
    </ContentWrapper>
  );
};

export { VoWiFiServiceCardButton };
