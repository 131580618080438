import { useAppDispatch } from '../../../../../../store';
import { setIsLoading, setLoadingError } from '../../../../../../store/slices/totalSlice';

/** Обработчик для кнопки "Обновить".
Убираем ошибку загрузки и повторно выставляем статус загрузки данных */
export const useBillReloadButton = () => {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(setLoadingError(false));
    dispatch(setIsLoading(true));
  };
};
