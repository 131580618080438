import React, { FC } from 'react';

import { CommonInfoBanner, TCommonInfoBanner } from '../../../../commonComponents/CommonInfoBanner';

type TPreChangesConflicts = {
  preChangesConflictProps: TCommonInfoBanner;
};

/** Блок с конфликтами при изменении тарифа */
export const PreChangesConflicts: FC<TPreChangesConflicts> = ({ preChangesConflictProps }) => {
  const { buttonProps, contentProps, pictureProps } = preChangesConflictProps;

  return (
    <CommonInfoBanner
      buttonProps={buttonProps}
      contentProps={contentProps}
      pictureProps={pictureProps}
    />
  );
};
