import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@beef/ui-kit';
import { PageFixer, PageRow } from '@beef/smart-kit';

import { usePageRow } from 'utils/hooks/usePageRow';

import style from './styles.pcss';

const cn = classNames.bind(style);

export const SkeletonLayout: FC = () => {
  const verticalSpaceValue = usePageRow();
  return (
    <div>
      <Skeleton className={cn('skeleton__teaser-banner')} />

      <PageFixer size="l">
        <div className={cn('skeleton__content')}>
          <PageRow space={verticalSpaceValue}>
            <Skeleton className={cn('skeleton__faq')} />
          </PageRow>
        </div>
      </PageFixer>
    </div>
  );
};
