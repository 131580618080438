import React, { useMemo, useState } from 'react';

import { ZeroBalanceSupportContext } from '../context';

export const ZeroBalanceSupportProvider: React.FC<
  React.PropsWithChildren<{
    data: {
      content: Record<string, unknown>;
      data: Record<string, unknown>;
      requestStatus: Record<string, unknown>;
    };
  }>
> = ({ children, data }) => {
  const [trustPaymentData, setTrustPaymentData] = useState<Record<string, unknown>>();

  const value = useMemo(
    () => ({
      ...data,
      trustPaymentData,
      setTrustPaymentData,
    }),
    [data, trustPaymentData],
  );

  return (
    <ZeroBalanceSupportContext.Provider value={value}>
      {children}
    </ZeroBalanceSupportContext.Provider>
  );
};
