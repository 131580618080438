import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { DescriptionCell, TDescriptionCell } from '../DescriptionCell';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TDescription = {
  info: TDescriptionCell[];
};

/** Описание пассивки и саб-заголовок для описания условий пассивок */
export const Description: FC<TDescription> = ({ info }) => (
  <ul className={cx('wrapper')}>
    {info?.map((item) => <DescriptionCell key={item.title} {...item} />)}
  </ul>
);
