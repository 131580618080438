import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';

const GROUP_WITH_GRADIENT_CLASSNAME = 'groupWithGradient';

class MainCatalogDesktopHoc extends Component {
  state = {
    isShowButton: true,
  };

  setShowButton = () =>
    this.setState({
      isShowButton: false,
    });

  /** Обновляем собятия каталога после нажатия на кнопку "еще n тарифов" */
  triggerResizeEvent = () => {
    const resizeWindow = window.document.createEvent('UIEvent');
    resizeWindow.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeWindow);
  };

  /** Если у тарифа поле isHidden false, склыдываем в отдельный массив
   * отфильтрованный от тарифов с полем isHidden true */
  sliceTariffs = (tariffs) =>
    tariffs.reduce((arr, curr) => {
      const tariffsEntries = curr.entries.filter((item) => !item.isHidden);
      if (tariffsEntries.length) {
        arr.push({ ...curr, entries: tariffsEntries });
      }
      return arr;
    }, []);

  /** Проверяем на наличие тарифов с полем isHidden true и складываем их в
   * отдельный массив для вывода кол-ва скрытых тирифов в кнопку "еще n тарифов" */
  checkTariffsEntries = (tariffs) =>
    tariffs.reduce((arr, curr) => {
      const filteredEntries = curr.entries.filter((item) => item.isHidden);
      if (filteredEntries.length) {
        arr.push(...filteredEntries);
      }
      return arr;
    }, []);

  // Находим группу с градиентом
  filterGroupWithGradient = (tariffsGroups) => tariffsGroups.filter((item) => item.isHot);

  render() {
    const { isShowButton } = this.state;
    const { tariffs, isAuthenticated } = this.props;

    let tariffsGroup = tariffs.groups;
    let isTariffsCenter = null;
    let checkTariffsEntriesLength = null;

    // Если пользовать находится в АЗ иначе идем по сценарию НЗ;
    if (isAuthenticated) {
      // Проверяем на необходимость скрытия карточек тарифа под кнопку;
      checkTariffsEntriesLength = this.checkTariffsEntries(tariffs.groups);

      // Скрываем тарифы;
      tariffsGroup =
        checkTariffsEntriesLength.length && isShowButton ?
          this.sliceTariffs(tariffs.groups)
        : tariffs.groups;

      // Из за кнопки, необходимо центрировать тарифы, так как кнопка дает +190px справа
      isTariffsCenter = isShowButton && checkTariffsEntriesLength?.length ? 'center' : null;
    }

    // Если есть поле isHot, отрисовываем блок с градиентом;
    const isHotGroup = this.filterGroupWithGradient(tariffs.groups);

    return cloneElement(this.props.children, {
      ...this.props,
      isTariffsListCenterClass: isTariffsCenter,
      triggerResizeEvent: this.triggerResizeEvent,
      setShowButton: this.setShowButton,
      groupWithGradientClassName: isHotGroup && GROUP_WITH_GRADIENT_CLASSNAME,
      isShowButton: !!(checkTariffsEntriesLength?.length && isShowButton),
      tariffs: tariffsGroup,
      tariffsCount: checkTariffsEntriesLength?.length,
    });
  }
}

MainCatalogDesktopHoc.propTypes = {
  children: PropTypes.object,
  tariffs: PropTypes.object,
  isAuthenticated: PropTypes.bool,
};

export default MainCatalogDesktopHoc;
