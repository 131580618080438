import React from 'react';
import classNames from 'classnames/bind';

// Components
import { VoWiFiParameter } from '../VoWiFiParameter';

// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const VoWiFiServiceParameters = ({ data }) => {
  const { voWiFiParameters } = data?.extData;

  return (
    <div className={cn('parameters')}>
      {voWiFiParameters?.map((parameter, idx) => (
        <VoWiFiParameter
          description={parameter?.description}
          key={idx}
          label={parameter?.label}
          unit={parameter?.unit}
          value={parameter?.value}
        />
      ))}
    </div>
  );
};

export { VoWiFiServiceParameters };
