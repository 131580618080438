import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

/**
 * В каждой колонке сетки может быть несколько уровней (строк), все их надо обернуть в item
 */
export const AlignedGridItem = ({ children, className }) =>
  React.Children.map(children, (child, rowIndex) => (
    <div className={cn('item', className)} key={+rowIndex}>
      {child}
    </div>
  ));

AlignedGridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
