import React, { FC, MouseEvent } from 'react';
import { ActionBar, Link } from '@beef/ui-kit';
// @ts-expect-error ошибочно НЕ импортируется TargetType из @beef/ui-kit
import { TargetType } from '@beef/ui-kit/Link/types';

export type TModalActionBar = {
  buttonText?: string;
  buttonTextExtra?: string;
  extraLink?: string;
  link: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  onClickExtra?: (e: MouseEvent<HTMLAnchorElement>) => void;
  target?: TargetType;
};

/** Контейнер для кнопок общей модальной обертки.
 * При отсутсвии href отрабатывает onClick */
export const ModalActionBar: FC<TModalActionBar> = ({
  link,
  target,
  onClick,
  extraLink,
  buttonText,
  onClickExtra,
  buttonTextExtra,
}) => (
  <ActionBar size="m">
    {buttonText && (
      <Link buttonVariant="main" href={link} onClick={onClick} target={target} variant="button">
        {buttonText}
      </Link>
    )}
    {(extraLink || onClickExtra) && buttonTextExtra && (
      <Link
        buttonVariant="tertiary"
        href={extraLink}
        onClick={onClickExtra}
        target="_blank"
        variant="button"
      >
        {buttonTextExtra}
      </Link>
    )}
  </ActionBar>
);
