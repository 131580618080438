import React from 'react';

import { ConnectedActivateModal } from './ActivateModal';
import { ConnectedConnectedModal } from './ConnectedModal';
import { ConnectedErrorModal } from './ErrorModal';
import { ConnectedFamilyModal } from './FamilyModal';
import { ConnectedYandexResponseModal } from './YandexResponseModal';

export const YandexSubscriptionModals: React.FC = () => (
  <>
    <ConnectedActivateModal />
    <ConnectedConnectedModal />
    <ConnectedErrorModal />
    <ConnectedYandexResponseModal />
    <ConnectedFamilyModal />
  </>
);
