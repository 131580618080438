import React, { memo, useState } from 'react';
import { Faq } from '@beef/smart-kit';
import { BreadCrumbs, Link, Text } from '@beef/ui-kit';
import { Preloader } from '@beef/legacy-ui-kit';

import { ModalsController } from 'pages/PclLandingPage/components/modals/ModalsController';
import { ModalsKeys } from 'pages/PclLandingPage/components/modals/ModalsController/types';
import { useModalsController } from 'pages/PclLandingPage/hooks/useModalsController';
import { usePrepareIklData } from 'pages/PclLandingPage/hooks/usePrepareIklData';
import { ErrorComponent } from 'pages/PclLandingPage/components/ErrorComponent';
import { useAuthPopup } from 'pages/EjapLanding/hooks/useAuthPopup';
import { Buttons } from 'pages/PclLandingPage/components/Buttons';
import ConnectPopup from 'pages/ProductCard/components/ServiceConnectPopup/ServiceConnectPopup';
import { IKLProps } from 'pages/PclLandingPage/IklPage/types';

import {
  Benefits,
  ContentBlock,
  Description,
  IklBanner,
  Notification,
  ServiceControlsCommands,
} from '../components';

const IklPage = memo<IKLProps>((props) => {
  const {
    data: { soc, isAuthenticated: isAuthorized },
    authenticated,
    currentCtn,
    openConnectPopup,
    setConnectPopupData,
    userData,
  } = props;

  const {
    isError,
    errorData,
    pclApiInfo,
    minChange,
    maxChange,
    gap,
    chosenValue,
    limitsModals,
    maxValue,
    payment,
    contexts,
    dpcData,
    marketingProductName,
    bgImage,
    pclServiceDescription,
    breadcrumbs,
    viewConnectButton,
    connectBtn,
    viewChangeButton,
    changeBtn,
    viewDisconnectButton,
    disconnectBtn,
    benefits,
    description,
    serviceControlsCommands,
    faq,
  } = usePrepareIklData({ soc, isAuthorized });

  const {
    isModalOpen,
    modal,
    isLoading,
    onActivateLimit,
    onDeactivateLimit,
    onChangeLimit,
    onCloseModal,
    onOpenModalClick,
    onIncreaseBalanceClick,
  } = useModalsController();

  const { pending } = useAuthPopup({
    currentCtn,
    authenticated,
    setConnectPopupData,
    openConnectPopup,
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleClick =
    isAuthorized ?
      onOpenModalClick(ModalsKeys.activateLimitModal)
    : () => {
        setIsOpen(true);
        openConnectPopup();
      };

  if (isError) {
    return <ErrorComponent {...errorData} />;
  }

  if (pending) {
    return <Preloader size="40" />;
  }

  return (
    <>
      {pclApiInfo && (
        <ModalsController
          barValues={{ minChange, maxChange, gap, chosenValue }}
          data={limitsModals}
          isLoading={isLoading}
          isOpen={isModalOpen}
          limit={maxValue}
          modal={modal}
          onActivateLimit={onActivateLimit}
          onChangeLimit={onChangeLimit}
          onClose={onCloseModal}
          onDeactivateLimit={onDeactivateLimit}
          onIncreaseBalanceClick={onIncreaseBalanceClick}
          payment={payment}
          userData={userData}
        />
      )}

      {contexts?.map((notification) => (
        <Notification
          criticalType={notification.criticalType}
          description={`${notification.header} ${notification.feePeriod}`}
        >
          <Link onClick={onOpenModalClick(ModalsKeys.topUpBalanceModal)}>
            <Text
              color={notification.criticalType === 'Critical' ? 'white' : 'grey95'}
              size="size5-r-s"
            >
              {notification.feeLink.text}
            </Text>
          </Link>
        </Notification>
      ))}

      {dpcData && (
        <IklBanner
          actions={
            <Buttons
              {...{
                viewConnectButton,
                handleClick,
                connectBtn,
                viewChangeButton,
                onOpenModalClick,
                changeBtn,
                viewDisconnectButton,
                disconnectBtn,
              }}
            />
          }
          breadcrumbs={<BreadCrumbs path={breadcrumbs} />}
          description={pclServiceDescription}
          image={bgImage}
          title={marketingProductName}
        />
      )}

      <ContentBlock>
        {benefits && <Benefits data={benefits} />}
        {description && <Description data={description} />}
        {serviceControlsCommands && <ServiceControlsCommands data={serviceControlsCommands} />}
        {faq?.data?.length > 0 && <Faq {...faq} />}
      </ContentBlock>

      {isOpen && <ConnectPopup />}
    </>
  );
});

export { IklPage };
