import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AutocompleteInput, TextInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const valuesFilter = (value, items) => {
  const filteredItems = items.filter(
    (item) => item && item.label.toLowerCase().indexOf(value.toLowerCase()) > -1,
  );

  return filteredItems.length !== 0 ? filteredItems : items;
};

class AddressForm extends Component {
  state = {
    streets: [],
    street: null,
    houses: [],
    house: null,
    flat: '',
  };

  componentDidMount() {
    this.resetDefaultState(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      const { street, house, flat } = this.state;

      this.props.onChange(
        {
          street: street ? street.label : null,
          house: house ? house.label : null,
          flat: flat || null,
          FTTBConnected: house ? house.isConnected : null,
        },
        true,
      );
    }
  }

  componentWillUnmount() {
    this.resetDefaultState(false);
  }

  fetchStreets = (term) => {
    this.setState({ street: null, house: null, flat: '' });
    this.houseInput.handleClear();

    if (term && term.length < 3) {
      return;
    }

    axios({
      url: '/checkconnection/GetStreets/',
      method: 'post',
      data: {
        cityId: this.props.city.id,
        term,
      },
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    }).then(({ data }) => this.setState({ streets: data }));
  };

  changeStreet = (street) => {
    this.setState({ street, house: null, flat: '' });
    this.houseInput.handleClear();
  };

  fetchHouses = (term) => {
    this.setState({ house: null, flat: '' });

    if (!this.state.street) {
      return;
    }

    axios({
      url: '/checkconnection/GetHouses/',
      method: 'post',
      data: {
        streetId: this.state.street.id,
        term,
      },
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    }).then(({ data }) => this.setState({ houses: data }));
  };

  changeHouse = (house) => {
    this.setState({ house, flat: '' });
  };

  changeFlat = (flat) => {
    this.setState({ flat });
  };

  resetDefaultState(isShowed) {
    this.props.onChange({}, isShowed);
  }

  render() {
    const { streets, street, houses, house, flat } = this.state;
    const { invalid } = this.props;

    return (
      <div className={cx('wrapper')}>
        <div className={cx('input')}>
          <AutocompleteInput
            filter={valuesFilter}
            itemCaptionExtractor={(item) => item.label}
            items={streets}
            onChange={this.changeStreet}
            onClearRequested={() => this.changeStreet(null)}
            onFetchRequested={this.fetchStreets}
            placeholder={`Улица в городе ${this.props.city.name}`}
            showPending
            status={invalid && !street ? 'fail' : 'ok'}
          />
        </div>

        <div className={cx('input')}>
          <AutocompleteInput
            disabled={!street}
            filter={valuesFilter}
            itemCaptionExtractor={(item) => item.label}
            items={houses}
            onChange={this.changeHouse}
            onClearRequested={() => this.changeHouse(null)}
            onFetchRequested={this.fetchHouses}
            placeholder="Дом"
            ref={(e) => {
              this.houseInput = e;
            }}
            showPending
            status={invalid && !house ? 'fail' : 'ok'}
          />
        </div>
        <TextInput
          className={cx('input')}
          disabled={!house}
          mask="____"
          onChange={this.changeFlat}
          placeholder="Квартира"
          status={invalid && !flat ? 'fail' : 'ok'}
          value={flat}
        />
      </div>
    );
  }
}

AddressForm.propTypes = {
  city: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
};

export default AddressForm;
