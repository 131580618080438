import { DEFAULT_TAG_COLOR, DEFAULT_TEXT_COLOR } from '../constants';
import { TServiceTile } from '../tempComponents/ServiceTile/types';
import { TTag } from '../tempComponents/TariffInfoTile/types';

/** Функция подготовки пропов для плитки ServiceTile */
export const prepareServiceTile = (service: TServiceTile) => {
  /* Готвоим данные по текущему сервису (variant карточки, textColor и tag) */
  const variant = service?.tagColor || DEFAULT_TAG_COLOR;
  const textColor = service?.textColor || DEFAULT_TEXT_COLOR;
  const tag: TTag = { variant, textColor, text: service?.tagText || '' };
  return { ...service, tag };
};
