import { useEffect, useState } from 'react';

import { fetchServiceNotification, filterServiceInfoBySoc } from '../utils';

/**
 * @desc: Хук возвращает текст, статус (цвет карточки) для подключенной услуги
 * @desc: если в массиве больше 1-го элементов, то ответ фильтруется по сокам услуги,
 * @desc: приходящими в аргументе и возвращается статус только для нужной услуги
 * @param {string} screenId
 * @param {boolean} isAuthorized
 * @param {Array<String>} socs
 * @returns {{ additionalText: string, color: string, name: string, text: string }}
 */

const useGetServiceNotification = (screenId, socs, isAuthorized) => {
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    if (isAuthorized) {
      fetchServiceNotification(screenId)
        .then((res) => filterServiceInfoBySoc(res, socs))
        .then((res) => {
          setServiceData(res);
        });
    }
  }, []);

  return serviceData;
};

export default useGetServiceNotification;
