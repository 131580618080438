import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Switch } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const RightBox = ({ type, value, unit, optionConnected, handleSwitchClick, loading }) => (
  <>
    {type === 'productParameters' && value && (
      <div className={cx('value')}>
        <span className={cx('valueNumber', { withUnit: unit })}>{value}</span>
        {unit && <span className={cx('valueUnit')} dangerouslySetInnerHTML={{ __html: unit }} />}
      </div>
    )}

    {type === 'optionParameters' && (
      <Switch
        checked={optionConnected}
        disabled={loading}
        isLoading={loading}
        onChange={handleSwitchClick}
      />
    )}
  </>
);

RightBox.propTypes = {
  optionConnected: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  handleSwitchClick: PropTypes.func,
};

export default RightBox;
