import React, { ComponentProps, FC } from 'react';
import classNames from 'classnames/bind';

import { Description } from './components/Description';
import { Price } from './components/Price';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type THiddenServices = {
  descriptionProps: ComponentProps<typeof Description>;
  isShowHiddenServices: boolean;
  priceProps: ComponentProps<typeof Price>;
};

/** Информационный компонент скрытых сервисов (hiddenServices) */
export const HiddenServices: FC<THiddenServices> = ({
  priceProps,
  descriptionProps,
  isShowHiddenServices,
}) =>
  isShowHiddenServices ?
    <section className={cx('wrapper')}>
      <Description {...descriptionProps} />
      <Price {...priceProps} />
    </section>
  : null;
