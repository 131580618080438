import classNames from 'classnames/bind';
import React from 'react';

import { TBasePower } from '../CharactersTabs/types';
import { PowerDescription } from './PowerDescription';
import { PowerName } from './PowerName';
import styles from './styles.pcss';
import { processTextSettings } from './utils/useTextSettings';

const cx = classNames.bind(styles);

type TPowersProps = Partial<
  TBasePower & {
    color?: string;
    handleModal: (e: React.MouseEvent<HTMLElement>) => void;
    isCard?: boolean;
    isTextOnly?: boolean;
    linkText: string;
    smallSize: boolean;
  }
>;

export const Powers: React.FC<TPowersProps> = ({
  icon,
  title,
  linkText = 'Подробнее',
  smallSize,
  handleModal,
  description,
  isCard,
  color: backgroundColor,
  abilityIcon,
  isTextOnly,
}) => {
  const { text, size, color } = processTextSettings({ smallSize, linkText });

  return (
    <div
      className={cx('wrapper', { 'wrapper--card': isCard })}
      style={{ ...(backgroundColor && { backgroundColor }) }}
    >
      {title && <PowerName icon={icon} title={title} />}
      {description && (
        <PowerDescription
          abilityIcon={abilityIcon}
          color={color}
          description={description}
          handleModal={handleModal}
          isTextOnly={isTextOnly}
          size={size}
          text={text}
        />
      )}
    </div>
  );
};
