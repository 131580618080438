import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { TOptionPickerTile } from './components/OptionPickerTile/types';
import { OptionPickerTile } from './components/OptionPickerTile';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TOptionPicker = {
  blockingTooltipText: string;
  onChange: (soc: string, title: string, isActive: boolean) => void;
  options: Omit<TOptionPickerTile, 'blockingTooltipText'>[];
};

/** Компонент выбора опций тарифа */
export const OptionPicker: FC<TOptionPicker> = ({ options, onChange, blockingTooltipText }) => (
  <div className={cx('container')}>
    {options.map((option) => (
      <OptionPickerTile
        blockingTooltipText={blockingTooltipText}
        key={option.title}
        onChange={onChange}
        {...option}
      />
    ))}
  </div>
);
