import { FontSizeType } from '@beef/ui-kit';

import { EConstructorStep } from '../../../../store/slices/stepperSlice/types';
import { useSelectAddressEditor } from '../selectors/useSelectAddressEditor';
import { useSelectUserAddress } from '../selectors/useSelectUserAddress';
import { useStepNavigation } from '../../../../hooks/useStepNavigation';
import { DEFAULT_INDEX_STEP_SKIP } from '../../../../constants';

/** Хук подготовки пропов для компонента редактирования адреса подключения ШПД */
export const usePrepareEditorProps = () => {
  /* Функция возврата к предыдущему шагу настройки */
  const { prevStep } = useStepNavigation();

  /* Текущий шаг подключения тарифа */
  const { currentStep } = useStepNavigation();

  /* Получаем адрес подключения пользователя */
  const connectionAddress = useSelectUserAddress();

  /* Делаем выборку контентных данных */
  const { editIcon, title, description, connectionAddressTitle } = useSelectAddressEditor();

  // TODO: Временное решение ДО внедрения нового функционала возможности переподключения ШПД!
  /* Объект с иконкой для блока редактирования и функцией возврата на предыдущий шаг (проверка адреса).
   * Если пользователь настраивает подключенный ШПД (currentStep = fttbConnectedSettings),
   * убираем возможность редактирования адреса подключения! */
  const editButtonProps =
    currentStep === EConstructorStep.fttbConnectedSettings ?
      {}
    : {
        icon: editIcon,
        onClick: () => prevStep(DEFAULT_INDEX_STEP_SKIP),
      };

  /* Пропы для компонента TitleContent (информационное описание блока настройки) */
  const titleContentProps = {
    title,
    description,
    size: 'size2-m' as FontSizeType,
    extraSize: 'size5-r-s' as FontSizeType,
  };

  /* Пропы для компонента ConnectionAddressEditor (блока с адресом пользователя и кнопкой редактирования) */
  const addressEditorProps = {
    /* Объект с заголовком блока (title: "Адрес подключения" и адресом пользователя в description) */
    addressContentProps: {
      title: connectionAddressTitle,
      description: connectionAddress,
    },
    editButtonProps,
  };

  return { titleContentProps, addressEditorProps };
};
