import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

/** Типы пользователей. Сначала добавлять сюда при масштабировании */
export enum EUserStoryType {
  /** Все сценарии в АЗ */
  /** Тариф ПОДКЛЮЧЕН с УЖЕ АКТИВНЫМ ШПД (конвергент) */
  authConnectedSettingsFTTB = 'authConnectedSettingsFTTB',
  /** Тариф ПОДКЛЮЧЕН, С добавлением ШПД */
  authConnectedWithFTTB = 'authConnectedWithFTTB',
  /** Тариф ПОДКЛЮЧЕН, БЕЗ добавления ШПД */
  authConnectedWithoutFTTB = 'authConnectedWithoutFTTB',
  /** Пользователь покупает новую SIM (SimOrderForm) в АЗ, С добавлением ШПД */
  authNewSimNotConnectedWithFTTB = 'authNewSimNotConnectedWithFTTB',
  /** Пользователь покупает новую SIM (SimOrderForm) в АЗ, БЕЗ добавления ШПД */
  authNewSimNotConnectedWithoutFTTB = 'authNewSimNotConnectedWithoutFTTB',
  /** Тариф НЕ подключен, С добавлением ШПД */
  authNotConnectedWithFTTB = 'authNotConnectedWithFTTB',
  /** Тариф НЕ подключен, БЕЗ добавления ШПД */
  authNotConnectedWithoutFTTB = 'authNotConnectedWithoutFTTB',

  /** Все сценарии в НЗ */
  /** С ШПД */
  notAuthWithFTTB = 'notAuthWithFTTB',
  /** Без ШПД */
  notAuthWithoutFTTB = 'notAuthWithoutFTTB',

  /** Все сценарии частного случая */
  /** Старый тариф ап, где недоступно ШПД */
  tariffUpArchiveWithoutFTTB = 'tariffUpArchiveWithoutFTTB',
  /** Переход из более ранних архивных версий конвергента (Близкие люди+, Для тебя и дома и т.п.) в новый UP */
  tariffUpTransitionFromArchiveTariff = 'tariffUpTransitionFromArchiveTariff',

  /** Общие сценарии на тарифе */
  /** Переход со своим номером */
  mnp = 'mnp',

  /** ШПД от партнёров */
  tariffUpWithPartnerFTTB = 'tariffUpWithPartnerFTTB',
}

/** Все шаги конструктора. Используются для формирования последовательности шагов разных типов пользователей */
export enum EConstructorStep {
  /** Главный конструктор пресета, опций и шпд */
  constructor = 'constructor',
  /** Финальный мегачек */
  finalBill = 'finalBill',
  /** Финальная страничка (например, страница благодарности) */
  finalPage = 'finalPage',
  /** Проверка адреса подключения шпд */
  fttbAddress = 'fttbAddress',
  /** страница запроса номера телефона */
  fttbCallbackRequest = 'fttbCallbackRequest',
  /** Изменение шпд в уже подключенном конвергенте */
  fttbConnectedSettings = 'fttbConnectedSettings',
  /** Настройка шпд и тв */
  fttbSettings = 'fttbSettings',
  /** Настройка перехода со своим номером */
  mnp = 'mnp',
  /** Настройка вызова специалиста */
  specialistCall = 'specialistCall',
  /** Изменение подключенного тарифа в АЗ */
  tariffConnectChange = 'tariffConnectChange',
}

/** События, которые могут использоваться как блокировка кнопки далее */
export enum EDisableNextEvent {
  /** Используется в CallbackRequestContainer */
  callbackRequest = 'callbackRequest',
  /** Проверка пдреса подключения шпд */
  checkAddress = 'checkAddress',
  /** Запрос на получение Fmc */
  fetchFmcPresets = 'fetchFmcPresets',
  /** Запрос на получение пресетов */
  fetchTariffupPreset = 'fetchTariffupPreset',
  /** Мой тариф */
  isMyTariff = 'isMyTariff',
  /** Проверка валидности номера mnp */
  mnpValidate = 'mnpValidate',
}

export type EDisableNextEvents = EDisableNextEvent[];

/** Тип последовательности шагов констуктора */
export type TConstructorSteps = EConstructorStep[];

/** Тип слайса степпера-роутера */
export type TStepperSlice = {
  currentStep: EConstructorStep;
  disabledNextList: EDisableNextEvents;
  isLoadingNext: boolean;
  userType: EUserStoryType;
};

/** Тип редьюсера слайса */
export type TStepperReducer = {
  disableNext: CaseReducer<TStepperSlice, PayloadAction<EDisableNextEvent>>;
  enableNext: CaseReducer<TStepperSlice, PayloadAction<EDisableNextEvent>>;
  goToFinalPageStep: CaseReducer<TStepperSlice>;
  goToFirstStep: CaseReducer<TStepperSlice>;
  goToFttbConnectedSettingsStep: CaseReducer<TStepperSlice>;
  nextStep: CaseReducer<TStepperSlice, PayloadAction<number | undefined>>;
  prevStep: CaseReducer<TStepperSlice, PayloadAction<number | undefined>>;
  setPersistedStepper: CaseReducer<TStepperSlice, PayloadAction<TStepperSlice | undefined>>;
  setUserStoryType: CaseReducer<TStepperSlice, PayloadAction<EUserStoryType>>;
};
