import { emptyArr, emptyObj, shallowEqual } from '@beef/utils';

import { DEFAULT_INDEX } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/constants';

import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { setSelectedTime } from '../../../../../../store/slices/fttbSpecialistVisitSlice';
import { setTotalDateTimeSlot } from '../../../../../../store/slices/totalSlice';
import { ITimePeriod } from '../../../types';

/** Хук для получения метода при клике на слот время монтажника */
export const useHandleSelectingTime = () => {
  const dispatch = useAppDispatch();

  const periods = useAppSelector((state) => state.specialistVisit.selectedTimePeriods);
  const availableSchedule = useAppSelector((state) => state.specialistVisit.availableSchedule);

  const { selectedTimeSlot, timeLabel } = useAppSelector(
    (state) => ({
      selectedTimeSlot: state.specialistVisit.selectedTime,
      timeLabel: state.common.content.fmcSettings?.calendarVisitPage.timeLabel,
    }),
    shallowEqual,
  );

  const [{ timePeriods: defaultTimePeriod = emptyArr }] = availableSchedule || emptyArr;

  /** метод выбора времени из периода выбранной даты слотов */
  const onChange = (value: string) => {
    if (periods?.length) {
      const selectedTime = periods?.find(({ id }) => id === value) || (emptyObj as ITimePeriod);
      dispatch(setSelectedTime(selectedTime));
      dispatch(
        setTotalDateTimeSlot({
          endDate: selectedTime.endTimeStamp,
          startDate: selectedTime.startTimeStamp,
        }),
      );
    }
  };

  return {
    isDateSlot: false,
    label: timeLabel,
    onChange,
    options: periods,
    value: selectedTimeSlot?.id || defaultTimePeriod[DEFAULT_INDEX]?.id,
  };
};
