import { useCallback, useEffect, useMemo, useState } from 'react';

import { TARIFF_WRONG_REGION_API } from 'pages/ProductCard/constants';
import { isEmptyValue } from 'pages/Maps/utils';
import { fetchWrongRegionPopup } from 'pages/ProductCard/services/fetchWrongRegionPopup';
import { getRegionSearchParams } from 'pages/ProductCard/utils';

export const useWrongRegionPopup = () => {
  const [wrongRegionPopupData, setWrongRegionPopupData] = useState({});

  useEffect(() => {
    const { previousRegion, currentRegion, missingTariff, needOpenPopup } = getRegionSearchParams();
    if (previousRegion && previousRegion !== currentRegion && needOpenPopup) {
      fetchWrongRegionPopup(TARIFF_WRONG_REGION_API, {
        missingTariff,
        previousRegion,
      })
        .then((data) => data?.view && setWrongRegionPopupData(data.view))
        .catch((error) => console.error(error));
    }
  }, []);

  const closeWrongRegionPopup = useCallback(() => {
    setWrongRegionPopupData({});
  }, []);

  return useMemo(
    () => ({
      isWrongRegion: !isEmptyValue(wrongRegionPopupData),
      wrongRegionPopupData,
      closeWrongRegionPopup,
    }),
    [wrongRegionPopupData, closeWrongRegionPopup],
  );
};
