import React from 'react';
import { MobileAppButton } from '@beef/ui-kit';
import { useMobileOS } from '@beef/react';
import classNames from 'classnames/bind';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import style from './styles.module.pcss';

const cn = classNames.bind(style);

const STORE_TYPE: Record<string, 'appGallery' | 'appStore' | 'googlePlay'> = {
  ios: 'appStore',
  android: 'googlePlay',
  huawei: 'appGallery',
};

type ButtonsType = {
  android?: string;
  huawei?: string;
  ios?: string;
};
type AppButtonsType = {
  appButtons: ButtonsType;
};

export const AppButtons = ({ appButtons }: AppButtonsType) => {
  const appTypes = Object.keys(appButtons);
  const isMobile = useIsMobileDimension();
  const mobileOS = useMobileOS();
  return (
    <div className={cn('container')}>
      {appTypes.map((type) => {
        if (!appButtons[type as keyof ButtonsType]) {
          return null;
        }
        return (
          <div
            className={cn('button', {
              'button--visible':
                !mobileOS || mobileOS === type || (mobileOS === 'android' && type === 'huawei'),
            })}
            key={type}
          >
            <MobileAppButton
              href={appButtons[type as keyof ButtonsType] as string}
              isFullWidth={isMobile}
              key={type}
              variant={STORE_TYPE[type as keyof ButtonsType]}
            />
          </div>
        );
      })}
    </div>
  );
};
