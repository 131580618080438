import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Button } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const FilterButton = ({ onClick, active, children, className }) => (
  <Button className={cx(className)} onClick={onClick} variant="plain">
    <span className={cx('button', { active })}>{children}</span>
  </Button>
);

FilterButton.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};
