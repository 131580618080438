import classNames from 'classnames/bind';
import { useLayoutEffect } from 'react';

import styles from '../styles.pcss';

const cx = classNames.bind(styles);

/**
 * Хук для скрытия скроллбара на лендинге MNP
 *
 * Костыль, чтобы не ломались стили скролла на других страницах
 * из-за особенностей страницы на бэкенде
 */
export const useHideScrollbar = () => {
  useLayoutEffect(() => {
    const htmlElement = document.querySelector('html');
    const pageContainer = document.querySelector('#main-mnp-redesign-layout');

    if (pageContainer && htmlElement) {
      htmlElement.classList.add(cx('html-mnp-redesign-layout'));
      pageContainer.classList.add(cx('page-container-mnp-redesign'));
    }
  }, []);
};
