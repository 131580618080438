/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Description,
  Descriptions,
  Heading,
  ProductParameter,
  ProductParameters,
  Status,
  Tooltip,
} from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';
import { noop } from '@beef/utils';

import store from 'store';
// Utils / Analytics
import { formatDate } from 'utils/format-string';
import { sendCvmAnalyticsEvent } from 'utils/analytics/cvmTariffAnalytics';
// Components
import ProductListParameterHOC from 'pages/ProductCard/components/ProductListParameter/hoc';
import ProductListParameter from 'pages/ProductCard/components/ProductListParameter/ProductListParameter';
import BenefitsWithPopups from 'pages-components/BenefitsWithPopup';
import SmsReminder from 'pages-components/SmsReminder';
import MetroStations from 'pages/ProductCard/components/MetroStations';
import { useGetDimensionGroup } from 'utils/hooks/useGetDimensionGroup';
import { handleRoamingYm, roamingYmEventsTypes } from 'pages/ProductCard/utils';

import HeadBanner from './components/HeadBanner/HeadBanner';
import ServiceConnectButton from './components/ServiceConnectButton/ServiceConnectButton';
import { ServiceContentWrapper } from './components/ServiceContentWrapper';
// Styles
import styles from './ProductCard.pcss';

const cx = classNames.bind(styles);

const ServiceCard = ({
  data,
  isCustomStyles,
  isCustomDivider,
  parametersExtension,
  detailsExtension,
  connectButtonExtension,
  divider,
  onFaqSectionClick,
}) => {
  const dimension = useGetDimensionGroup();
  const infoAnchorRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const renderUrlMatch = window.location.href.match('detailsforlk');

      if (renderUrlMatch) {
        const { campId, subgroupId } = data;
        sendCvmAnalyticsEvent([{ campId, subgroupId }], 'open', 'offers');
      }
    }
  }, []);

  const showConnectionPopup = () => {
    const { authVersion } = data;
    if (authVersion !== '2') {
      window.QA.Beeline.Pages.MobileService.Bill.connect();
    }
  };

  const handleInfoClick = () => {
    infoAnchorRef.current.scrollIntoView(
      {
        behavior: 'smooth',
        block: 'start',
      },
      100,
    );
  };

  const {
    alias,
    archiveTariffServiceInfo,
    connectButtonVersion,
    connectButton,
    availableForConnect,
    productParameters,
    productTypeTitle,
    mainDescription,
    pageTitleBubble,
    bgImageDesktop,
    bgImageMobile,
    descriptions,
    smsRemind,
    pageTitle,
    benefits,
    customServiceParameters,
    customServiceParametersHeader,
    expDate,
    extData,
    ymType,
  } = data;

  const ParametersExtension = parametersExtension;
  const DetailsExtension = detailsExtension;
  const ConnectButtonExtension = connectButtonExtension;
  const isCustomService = customServiceParameters && !!customServiceParameters.length;
  const expDateObject = expDate && new Date(expDate);
  const hasBackground = !!(bgImageMobile || bgImageDesktop);

  const onDescriptionClick = (val = 0) => {
    if (!descriptions?.length || !ymType) return;
    const { title: value } = descriptions[val];
    handleRoamingYm({ type: ymType, actionType: roamingYmEventsTypes.faqClick, value });
  };

  return (
    <Provider store={store}>
      <div>
        {isCustomService && (
          <div className={cx({ hasBackground })}>
            <ServiceContentWrapper
              data={data}
              dimension={dimension}
              hasBackground={hasBackground}
              isCustomStyles={isCustomStyles}
            />

            {benefits && !!benefits.length && (
              <>
                <BenefitsWithPopups
                  benefitLayout="column"
                  benefits={benefits}
                  className={cx('benefits', 'isCustomService')}
                  iconType="src"
                  showTitles
                />
                <hr className={cx('line')} />
              </>
            )}

            {!!productParameters.length && (
              <>
                <div className={cx('parameters')}>
                  <ProductParameters className={['withBorders', cx('parametersTable')]}>
                    {productParameters.map((parameter, index) => (
                      <ProductParameter
                        key={index}
                        label={parameter.label}
                        legal={parameter.legal}
                        subscriptionFeeDiscount={parameter.subscriptionFeeDiscount}
                        unit={parameter.unit}
                        value={parameter.value}
                      />
                    ))}
                  </ProductParameters>
                </div>

                {connectButton && availableForConnect && (
                  <ConnectButtonExtension connectButtonVersion={connectButtonVersion} data={data} />
                )}

                <hr className={cx('line')} />
              </>
            )}

            {expDateObject && (
              <Status
                className={['success', cx('connectedStatus')]}
                text={`Акция подключена до ${formatDate(
                  expDateObject,
                  false,
                )} ${expDateObject.getFullYear()}`}
              />
            )}
            {customServiceParametersHeader && (
              <div className={cx('param__heading')}>
                <Heading level={2}>{customServiceParametersHeader}</Heading>
              </div>
            )}
            <ProductListParameterHOC alias={alias}>
              <ProductListParameter content={customServiceParameters} />
            </ProductListParameterHOC>
          </div>
        )}

        {!isCustomService && (
          <div className={cx('pageMain', { hasBackground: !!(bgImageMobile || bgImageDesktop) })}>
            {pageTitleBubble ?
              <HeadBanner
                image={bgImageDesktop}
                mobileImage={bgImageMobile}
                pageTitleBubble={pageTitleBubble}
                productTypeTitle={productTypeTitle}
                title={pageTitle}
              />
            : <ServiceContentWrapper
                data={data}
                dimension={dimension}
                hasBackground={hasBackground}
                isCustomStyles={isCustomStyles}
              />
            }
            {benefits && benefits.length ?
              <BenefitsWithPopups
                benefitLayout="column"
                benefits={benefits}
                className={cx('benefits', isCustomStyles && 'benefits--custom', {
                  benefitsNoBorderBottom: extData?.esimUnavailable,
                })}
                iconType="src"
                showTitles
              />
            : divider && (
                <div className={cx('divider', { 'divider--without-space': isCustomDivider })} />
              )
            }

            {!extData?.esimUnavailable && (
              <ParametersExtension
                className={cx('parameters')}
                data={data}
                onConnect={showConnectionPopup}
                onScrollToInfo={handleInfoClick}
              >
                {!!productParameters?.length && (
                  <ProductParameters className={['withBorders', cx('parametersTable')]}>
                    {productParameters.map((parameter, index) => (
                      <ProductParameter
                        key={index}
                        label={parameter.label}
                        legal={parameter.legal}
                        subscriptionFeeDiscount={parameter.subscriptionFeeDiscount}
                        unit={parameter.unit}
                        value={parameter.value}
                      />
                    ))}
                  </ProductParameters>
                )}
              </ParametersExtension>
            )}

            {data?.extData?.metro && <MetroStations {...data.extData.metro} />}

            {mainDescription ?
              <div
                className={cx('mainDescription')}
                dangerouslySetInnerHTML={{ __html: mainDescription }}
              />
            : null}

            {archiveTariffServiceInfo && (
              <div className={cx('archiveInfo')}>
                <Status className={cx('status')}>
                  <span dangerouslySetInnerHTML={{ __html: archiveTariffServiceInfo.text }} />
                  {archiveTariffServiceInfo.tooltip && (
                    <Tooltip text={archiveTariffServiceInfo.tooltip} />
                  )}
                </Status>
              </div>
            )}

            <ConnectButtonExtension connectButtonVersion={connectButtonVersion} data={data} />

            {smsRemind && <SmsReminder {...smsRemind} />}
          </div>
        )}
        <DetailsExtension />
        <div ref={infoAnchorRef} />
        {!!descriptions?.length && (
          <div className={cx('pageDescriptions')}>
            <Descriptions
              className={cx('descriptions')}
              onChangeIndex={(index) => onFaqSectionClick(index, descriptions, data)}
              onClick={onDescriptionClick}
              titleTag="h2"
            >
              {descriptions
                .filter((d) => d.content)
                .map((description, dIndex) => (
                  <Description key={dIndex} title={description.title}>
                    <ProductParameters className={cx('parametersTable')}>
                      {description.content.map((parameter, pIndex) => (
                        <ProductParameter
                          key={`${dIndex}-${pIndex}`}
                          label={parameter.label}
                          legal={parameter.legal}
                          subscriptionFeeDiscount={parameter.subscriptionFeeDiscount}
                          unit={parameter.unit}
                          value={parameter.value}
                        />
                      ))}
                    </ProductParameters>
                  </Description>
                ))}
            </Descriptions>
          </div>
        )}
      </div>
    </Provider>
  );
};

ServiceCard.defaultProps = {
  parametersExtension: (props) => <div className={cx('parameters')}>{props.children}</div>,
  detailsExtension: () => <div />,
  connectButtonExtension: ({ data }) => <ServiceConnectButton data={data} />,
  divider: true,
  onFaqSectionClick: noop,
};

ServiceCard.propTypes = {
  connectButtonExtension: PropTypes.func,
  parametersExtension: PropTypes.func,
  detailsExtension: PropTypes.func,
  data: PropTypes.object,
  isCustomDivider: PropTypes.bool,
  metro: PropTypes.object,
  divider: PropTypes.bool,
  styleType: PropTypes.string,
  isCustomStyles: PropTypes.bool,
  onFaqSectionClick: PropTypes.func,
};

export default ServiceCard;
