import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import {
  COMPONENT_STATE_FADEIN,
  COMPONENT_STATE_FADEOUT,
  COMPONENT_STATE_LOADING,
  COMPONENT_STATE_STATIC,
} from './constants';

const cx = classNames.bind(styles);

/* Украдено из шопа */

/**
 * this function is required to fix issues with sidebar,
 * caused by untracked layout changes
 */
export const simulateResize = () => {
  const resizeEvent = window.document.createEvent('UIEvents');
  resizeEvent.initUIEvent('resize', true, false, window, 0);
  window.dispatchEvent(resizeEvent);
};

/**
 * SmoothContentSwitcher changes old children for new children smoothly,
 * if component either receives new `children`, or gets `isLoading` flag, fadeOut stage begins
 * once fadeOut is done, if status is not `isLoading`, we show new content, otherwise we show preloader
 */
export class SmoothContentSwitcher extends React.Component {
  constructor(props, context) {
    super(props, context);
    const { isLoading } = props;

    this.state = {
      state: isLoading ? COMPONENT_STATE_LOADING : COMPONENT_STATE_STATIC,
    };
  }

  static getDerivedStateFromProps({ isLoading }, { state }) {
    const date = Date.now();
    let currentDate;
    do {
      currentDate = Date.now();
    } while (currentDate - date < 100);
    if (state === COMPONENT_STATE_STATIC) {
      if (isLoading) {
        return {
          state: COMPONENT_STATE_FADEOUT,
        };
      }
    } else if (state === COMPONENT_STATE_LOADING) {
      if (!isLoading) {
        return {
          state: COMPONENT_STATE_FADEIN,
        };
      }
    }
    return null;
  }

  /**
   * When fadeOut starts, we set timer for next state change (loading or fadeIn)
   * When fadeIn starts, we set timer for next state change (static)
   */
  componentDidUpdate(prevProps, prevState) {
    const { state } = this.state;
    if (state === COMPONENT_STATE_FADEOUT && prevState.state !== COMPONENT_STATE_FADEOUT) {
      setTimeout(this.finishFadeOut, 400);
    } else if (state === COMPONENT_STATE_FADEIN && prevState.state !== COMPONENT_STATE_FADEIN) {
      setTimeout(() => {
        this.setState({
          state: COMPONENT_STATE_STATIC,
        });
      }, 400);
    }
    requestAnimationFrame(simulateResize);
  }

  /**
   * When fadeOut is complete, if data is:
   * available - we enter fadeIn state
   * unavailable - we enter loading state
   */
  finishFadeOut = () => {
    const { onReadyToSwitch } = this.props;
    this.setState(
      {
        state: this.props.isLoading ? COMPONENT_STATE_LOADING : COMPONENT_STATE_FADEIN,
      },
      onReadyToSwitch,
    );
  };

  render() {
    const { children, className } = this.props;
    const { state } = this.state;
    return (
      <div className={cx('container', state, className)}>
        <div className={cx('content')}>{children}</div>
      </div>
    );
  }
}

SmoothContentSwitcher.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool, // if isLoading is true, we show preloader after hiding content
  onReadyToSwitch: PropTypes.func,
  className: PropTypes.string,
};
