import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import { LayoutGroup, motion } from 'framer-motion';

import { SkeletonBill } from 'constructorV1/components/tempComponents/SkeletonBill';
import { ESkeletonBillTypes } from 'constructorV1/types';

import { PlanSettings } from './components/PlanSettings';
import { TotalAmount } from './components/TotalAmount';
import { TariffTabs } from './components/TariffTabs';
import { BillButton } from './components/BillButton';
import { useBillProps } from './hooks/useBillProps';
import { MnpForm } from './components/MnpForm';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Варианты анимации общей нижней части чека ЕК */
const variantsBillBottom = {
  hidden: {
    height: 'auto',
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.2 },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.2 },
  },
};

/** Варианты анимации блока с ценой и кнопкой (total и button).
 * Вынесено отдельным объектом для удобства последующих корректировок анимации данной части */
const variantsTotalAmount = {
  hidden: {
    height: 'auto',
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: { duration: 0.3, ease: 'linear', type: 'tween', delay: 0.2 },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: { duration: 0.3, ease: 'linear', type: 'tween', delay: 0.2 },
  },
};

/** Компонент чека ЕК */
export const Bill: FC = memo(() => {
  /* Подготовка данных для компонента Bill */
  const { isLoading, settingsProps, buttonProps, tariffTabsProps, mnpFormProps, totalAmountProps } =
    useBillProps();

  return (
    <motion.section
      animate="visible"
      className={cx('wrapper')}
      exit="exit"
      initial="hidden"
      layout
      variants={variantsBillBottom}
    >
      <LayoutGroup>
        {/* Блок настроек тарифа (чёрный блок) */}
        {isLoading ?
          <SkeletonBill type={ESkeletonBillTypes.settings} />
        : <PlanSettings {...settingsProps} />}
        {/* Блок с выбранным номером для переноса (MNP) */}
        <MnpForm {...mnpFormProps} />
        {/* Табы выбора варианта номера (новый перенос) */}
        {isLoading ?
          <SkeletonBill type={ESkeletonBillTypes.tabs} />
        : <TariffTabs {...tariffTabsProps} />}
        <div className={cx('wrapper__bottom')} id="constructor-bill">
          {isLoading ?
            <SkeletonBill type={ESkeletonBillTypes.bottom} />
          : <motion.div
              animate="visible"
              className={cx('wrapper__content')}
              exit="exit"
              initial="hidden"
              layout
              variants={variantsTotalAmount}
            >
              <LayoutGroup>
                {/* Блок с общей ценой, скидками и доплатами */}
                <TotalAmount {...totalAmountProps} />
                {/* Кнопка в чеке */}
                <BillButton {...buttonProps} />
              </LayoutGroup>
            </motion.div>
          }
        </div>
      </LayoutGroup>
    </motion.section>
  );
});
