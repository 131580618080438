import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TDevicesItem = {
  name?: string;
};

/** Название рекомендуемого девайса */
export const DevicesItem: FC<TDevicesItem> = ({ name }) =>
  name ?
    <li className={cx('wrapper')}>
      <Text color="grey60" size="size5-r-l">
        {name}
      </Text>
    </li>
  : null;
