import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { DefaultIcon } from '../../../../../Icons/DefaultIcon';
import styles from '../../styles.pcss';

const cx = classNames.bind(styles);

export type TPicture = {
  image?: string;
  mobImage?: string;
};

/** Компонент картинки/иконки для плитки ServiceTile.
 * Принимает моб. и десктопную картинки. При их отсутствии ставит иконку-заглушку */
export const Picture: FC<TPicture> = ({ image, mobImage }) => (
  <div className={cx({ 'wrapper--default': !image })}>
    {image ?
      <picture>
        <source media="(max-width: 767px)" srcSet={mobImage} />
        <img alt="" className={cx('wrapper__image')} loading="lazy" src={image} />
      </picture>
    : <DefaultIcon className={cx('wrapper__icon')} />}
  </div>
);
