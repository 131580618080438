import React, { memo } from 'react';
import PropTypes from 'prop-types';

// Components
import { ActionBar, Button } from '@beef/ui-kit';

const ModalActionBar = memo(({ handleModal, buttonText }) => (
  <ActionBar size="m">
    <Button onClick={handleModal}>{buttonText}</Button>
  </ActionBar>
));

ModalActionBar.propTypes = {
  handleModal: PropTypes.func,
  buttonText: PropTypes.string,
};

export { ModalActionBar };
