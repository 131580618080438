import React, { ReactNode, memo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TContainer = {
  children: ReactNode;
};

/** Компонент-обертка над главной частью конструктора (Layout-контейнеры) */
export const Container = memo<TContainer>(({ children }) => (
  <section className={cx('wrapper')}>{children}</section>
));
