export const getHref = (): string => {
  const href = window?.location?.href;

  if (!href) throw new Error('href is empty');

  return href;
};

export const setHref = (href: string): void => {
  if (window?.location) {
    window.location.href = href;
  } else {
    throw new Error('No location object found');
  }
};
