import React, { FC } from 'react';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type BadgeProps = {
  activeColor: string;
  discount: string;
};

const Badge: FC<BadgeProps> = ({ discount, activeColor }) => (
  <div className={cx('badge')} style={{ backgroundColor: activeColor }}>
    <Text size="size6-r">{discount}</Text>
  </div>
);

export { Badge };
