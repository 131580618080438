import { useCallback, useEffect, useRef } from 'react';

// Utils
import { getRegionTariffs, replaceTariff } from '../utils';

// Constants
import { REQUEST_STATUS } from '../constants';

/**
 * useGetRegionTariff: проверка на доступность к подключению конвергенции/получшение тарифов для региона.
 * Хук кеширует данные для каждого выбранного юзером региона и предоствращает отправку бессмысленных запросов.
 * @param soc
 * @param isOpen
 * @param regionId
 * @param generation
 * @param setAnimalSoc
 * @param setAccessTariffs
 * @param setRegionLoading
 * @param setAddressChecked
 * @param currentConnectionStatus
 */
export const useGetRegionTariff = ({
  soc,
  isOpen,
  regionId,
  hasInacId,
  generation,
  setAnimalSoc,
  defaultRegion,
  setUrlQueryFlat,
  allAccessTariffs,
  setAccessTariffs,
  setRegionLoading,
  setUrlQueryPhone,
  setUrlQueryHouse,
  seamlessUrlParams,
  setUrlQueryStreet,
  setAddressChecked,
  userSettingsTariff,
  hasSeamlessUrlParams,
}) => {
  const cachedRequests = useRef({});
  const cachedKey = `${regionId}-${soc}`;
  const hasSettingsTariff = !!Object.keys(userSettingsTariff).length;

  if (hasSettingsTariff) {
    cachedRequests.current[cachedKey] = [...allAccessTariffs];
  }

  /**
   * sendSeamlessUrlParams: функция сборки и отправки данных пользователя
   * для сценария "бесшовного" перехода BCP-6900
   * @type {(function(): void)|*}
   */
  const sendSeamlessUrlParams = useCallback(() => {
    const { street, streetId, house, houseId, flat, contactPhone } = seamlessUrlParams || {};

    setUrlQueryStreet({ label: street, id: +streetId, isConnected: true });
    setUrlQueryHouse({ label: house, id: +houseId, isConnected: true });
    setUrlQueryPhone(contactPhone);
    setUrlQueryFlat(flat);
  }, [seamlessUrlParams, hasSeamlessUrlParams]);

  useEffect(() => {
    if (hasSeamlessUrlParams && hasInacId) {
      sendSeamlessUrlParams();
      return;
    }

    if (isOpen && regionId) {
      if (cachedRequests.current[cachedKey]) {
        const cachedInfo = cachedRequests.current[cachedKey];
        const settings = { allTariffs: cachedInfo, userTariff: userSettingsTariff };

        if (hasSettingsTariff) {
          setAccessTariffs(replaceTariff(settings));
          return;
        }

        if (!cachedInfo?.length) {
          setAddressChecked(REQUEST_STATUS.regionIsNotConnect);
        }

        setAccessTariffs(cachedInfo);
      } else {
        setAnimalSoc(soc);
        setRegionLoading(true);

        if (allAccessTariffs?.length && regionId === defaultRegion.id) {
          setRegionLoading(false);
          return;
        }

        getRegionTariffs({ soc, regionId, generation })
          .then(({ data }) => {
            setRegionLoading(false);
            const { tariffs } = data;

            if (!tariffs?.length) {
              setAddressChecked(REQUEST_STATUS.regionIsNotConnect);
            }

            setAccessTariffs(tariffs);
            cachedRequests.current[cachedKey] = tariffs;
          })
          .catch((error) => {
            console.error(error);
            setAddressChecked(REQUEST_STATUS.regionIsNotFound);
          });
      }
    }
  }, [soc, isOpen, regionId]);
};
