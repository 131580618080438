import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Heading, ScrollContainer } from '@beef/legacy-ui-kit';

import { ServiceCard } from './components/ServiceCard';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ExtraServices = ({ data }) => (
  <section className={cx('extra-service-section')}>
    <Heading level={2}>
      <span>Дополнительные услуги</span>
    </Heading>
    <ScrollContainer handleShouldPlayDemo={() => false}>
      <ul className={cx('service-list')}>
        {data.map((service) => (
          <li className={cx('service-list__item')} key={service.aboutLink}>
            <ServiceCard data={service} />
          </li>
        ))}
      </ul>
    </ScrollContainer>
  </section>
);

ExtraServices.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      aboutLink: PropTypes.string,
    }),
  ),
};
