import axios from 'axios';

export const makeRequest = ({ url = '/', method = 'get', headers = {}, params = {}, data = {} }) =>
  axios({
    url,
    method,
    headers,
    params,
    data,
  });
