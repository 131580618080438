import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TLinkContainer = {
  children: ReactNode;
  link: string;
};

/** Компонент-обертка над кликабельным промо-баннером со ссылкой */
export const LinkContainer: FC<TLinkContainer> = ({ link, children }) => (
  <a className={cx('wrapper')} href={link} rel="noopener noreferrer" target="_blank">
    {children}
  </a>
);
