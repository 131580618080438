import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { onMnpCartLoadDataLayer } from 'utils/analytics/cart';

import MnpCartForm from './components/MnpCartForm';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

// как понимаю компонент больше нигде не используется (в роутах уже тоже старье). toDo: удалить?
const MNPOrder = ({ data }) => {
  useEffect(() => {
    onMnpCartLoadDataLayer();
  }, []);

  const { extData } = data;

  return (
    <div className={cx('cart-form-wrap')}>
      <MnpCartForm
        basketSource={extData.basketSource}
        errors={{
          isBeelineCtn: extData.formData?.isBeelineCtnErrorText,
        }}
        note={extData.note}
        userAgreement={[
          {
            text: extData?.legal?.userAgreementText,
            tooltip: extData?.legal?.termsOfUse,
          },
        ]}
        validationUrl={extData.endpoint}
      />
    </div>
  );
};

MNPOrder.propTypes = {
  data: PropTypes.shape({
    extData: PropTypes.object,
  }),
};

export default MNPOrder;
