import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { ActionBar, Button, Text } from '@beef/ui-kit';

// styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ModalActionBar = ({
  text,
  onClick,
  disabled,
  isLoading,
  buttonText,
  secondButtonText,
  showAgreementText,
  isCallBackLoading,
  sentCallBackRequest,
}) => (
  <ActionBar isWithText={showAgreementText} size="m">
    {buttonText && <Button {...{ onClick, disabled, isLoading }}>{buttonText}</Button>}
    {secondButtonText && (
      <Button isLoading={isCallBackLoading} onClick={sentCallBackRequest} variant="tertiary">
        {secondButtonText}
      </Button>
    )}
    {showAgreementText && (
      <div className={cx('wrapper')}>
        <Text color="grey50" size="size6-r">
          {text}
        </Text>
      </div>
    )}
  </ActionBar>
);

ModalActionBar.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string,
  isCallBackLoading: PropTypes.bool,
  showAgreementText: PropTypes.bool,
  secondButtonText: PropTypes.string,
  sentCallBackRequest: PropTypes.func,
};
