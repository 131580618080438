import { ymPushParams } from 'utils/ym';
import { debounce } from 'utils/timed-functions';
import { pushDataLayer } from 'utils/ga';

import { CATEGORIES, TARGET_CLICK, TARGET_VIEW } from './constants';

type EventType = {
  data: any;
  location: keyof typeof CATEGORIES;
  target: keyof typeof TARGET_CLICK;
};

type EventTypeView = {
  data: any;
  location: keyof typeof CATEGORIES;
  target: keyof typeof TARGET_VIEW;
};

type ProductType = {
  brand: string;
  id: string;
  name: string;
  price: string;
};

const mapperArticles = (articles: string[]) =>
  articles.map((article) => ({ article_title: article }));

export const pushClick = ({ location, target, data }: EventType) =>
  ymPushParams({ search_ev_: { [CATEGORIES[location]]: { [TARGET_CLICK[target]]: data } } });

export const pushView = ({ location, target, data }: EventTypeView) => {
  ymPushParams({ search_ev_: { [CATEGORIES[location]]: { [TARGET_VIEW[target]]: data } } });
};

type ItemType = {
  [key: string]: string;
};

type YMPusherConstructor = {
  location?: keyof typeof CATEGORIES;
  mapper?: (obj: ItemType[] | string[]) => ItemType[];
  target: keyof typeof TARGET_CLICK | keyof typeof TARGET_VIEW;
};

class YMPusher {
  #items: ItemType[] | string[] = [];

  target;

  location;

  mapper;

  constructor({ location = 'main', target, mapper }: YMPusherConstructor) {
    this.location = location;
    this.target = target;
    this.mapper = mapper;
  }

  #pushViewEventWithDebounce = debounce(() => {
    if (this.mapper) {
      this.#items = this.mapper(this.#items);
    }
    pushView({
      location: this.location,
      target: this.target,
      data: this.#items,
    });
    this.#items = [];
  }, 1000);

  pushViewEvent = (item: ItemType & string) => {
    if (typeof item === 'object') {
      const key = Object.keys(item)?.[0];
      const indexValue = this.#items.findIndex((value) => value[key] === item[key]);
      if (indexValue === -1) {
        this.#items.push(item);
        this.#pushViewEventWithDebounce();
      }
    } else if (!this.#items.includes(item)) {
      this.#items.push(item);
      this.#pushViewEventWithDebounce();
    }
  };

  pushClickEvent = (item: any) => {
    pushClick({
      location: this.location,
      target: this.target,
      data: item,
    });
  };
}

export const ArticlesAnalyticsPusher = new YMPusher({
  location: 'articles',
  target: 'article',
  mapper: mapperArticles,
});
export const TapsAnalyticsPusher = new YMPusher({ target: 'tap' });
export const BrandsAnalyticsPusher = new YMPusher({ target: 'brand' });
export const CategoriesAnalyticsPusher = new YMPusher({ target: 'category' });
export const SpecialCategoriesAnalyticsPusher = new YMPusher({ target: 'main_category' });
export const DeviceAnalyticsPusher = new YMPusher({ target: 'products' });
export const PriceAnalyticsPusher = new YMPusher({ target: 'price' });

export const pushViewNotFound = (query: string) =>
  ymPushParams({ search_ev_: { no_result_page: { result_not_found: query } } });

export const pushFilterReset = (location: keyof typeof CATEGORIES) =>
  ymPushParams({ search_ev_: { [CATEGORIES[location]]: 'filter_reset' } });

export const backToSearchResults = () =>
  ymPushParams({
    search_ev_: { [CATEGORIES.articles]: 'back_to_search_results' },
  });

export const pushAddToCart = (product: ProductType) => {
  pushDataLayer({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'RUB',
      add: {
        products: [
          {
            id: product.id,
            name: product.name,
            price: product.price,
            brand: product.brand,
          },
        ],
      },
    },
  });
};
