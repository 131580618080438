import React from 'react';
import { LandingConstructor } from '@beef/landing-constructor';
import { withDataConverter } from '@beef/react';
import { Faq, PageContainer } from '@beef/smart-kit';
import { Preloader } from '@beef/ui-kit';

import { Banner, ImageCardsContainer, ServiceNotificationWrapper } from './components/index';
import { TrustPaymentContext } from './context';
import { dataConverter } from './dataConverter';
import { TTrustPaymentProps } from './types';
import { CustomBanner } from './components/BroadBandInternetBanner/index';

const TrustPaymentWrapper: React.FC<TTrustPaymentProps> = ({
  isConnected,
  isAuthenticated,
  content,
}) => {
  const contextValue = React.useMemo(
    () => ({
      content,
      isConnected,
      isAuthenticated,
    }),
    [isConnected, isAuthenticated, content],
  );

  if (!content) {
    return (
      <PageContainer size="s">
        <div style={{ margin: '0 auto', position: 'relative', width: 100, height: 100 }}>
          <Preloader size="l" spaced />
        </div>
      </PageContainer>
    );
  }

  return (
    <>
      <TrustPaymentContext.Provider value={contextValue}>
        <ServiceNotificationWrapper />
        <Banner />
        <ImageCardsContainer />

        {content?.layout && (
          <LandingConstructor
            {...content.layout}
            customComponents={{
              CustomBanner,
            }}
          />
        )}
      </TrustPaymentContext.Provider>

      {content?.faq && <Faq {...content.faq} />}
    </>
  );
};

export default withDataConverter(dataConverter)(TrustPaymentWrapper);
