import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@beef/ui-kit';

import { DescriptionSkeleton } from '../DescriptionSkeleton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Заглушка для блока с иконкой и описанием */
export const SectionsSkeleton: FC = () => (
  <div className={cx('wrapper')}>
    <Skeleton className={cx('wrapper__icon')} />
    <DescriptionSkeleton />
  </div>
);
