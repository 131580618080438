export const ORDER_TARIFF_ENDPOINTS = {
  get_tariff: '/customers/products/home/request/GetFullOrderData/',
  create_order: '/customers/products/home/request/createFullOrder/',
  get_init_data: '/hometariffsapi/GetSubmitCallbackRequestData/',
  get_available_schedule: '/hometariffsapi/getavailableschedule/',
  get_presetUp_setup_data: '/hometariffsapi/GetPresetUpSetupData/',
  get_mobile_tariffs: '/customers/products/mobile/tariffs/forland2021/',
};

export const REQUEST_STATUS = {
  isNone: 'isNone',
  isHighSpeedAvailable: 'isHighSpeedAvailable',
  isHighSpeedAvailableWithoutCalendarStep: 'isHighSpeedAvailableWithoutCalendarStep',
  isAvailable: 'isAvailable',
  isExists: 'isExists',
  shortSuccess: 'shortSuccess',
  isNotAvailable: 'isNotAvailable',
  houseIsNotFound: 'houseIsNotFound',
  streetIsNotFound: 'streetIsNotFound',
  alreadyInUse: 'alreadyInUse',
};

export const commonAvailableRequestStatuses = [
  REQUEST_STATUS.isExists,
  REQUEST_STATUS.isAvailable,
  REQUEST_STATUS.alreadyInUse,
  REQUEST_STATUS.isHighSpeedAvailable,
  REQUEST_STATUS.isHighSpeedAvailableWithoutCalendarStep,
];

export const orderTariffPopupNames = {
  addressRequestForm: 'addressRequestForm',
  tariffDetailsForm: 'tariffDetailsForm',
  calendarForm: 'calendarForm',
  alternativeTariff: 'alternativeTariff',
  summaryPage: 'summaryPage',
  shortSuccess: 'shortSuccess',
  fail: 'fail',
  addressNotAvailableModal: 'addressNotAvailableModal',
  convergenceTariffs: 'convergenceTariffs',
  mobileTariffs: 'mobileTariffs',
};

export const popupSteps = {
  addressRequestForm: 0,
  tariffDetailsForm: 1,
  calendarForm: 2,
  alternativeTariff: 3,
  summaryPage: 4,
  shortSuccess: 5,
  fail: 6,
  convergenceTariffs: 7,
  mobileTariffs: 8,
  addressNotAvailableModal: 9,
};

export const inputSelectionType = {
  radio: 1,
  checkbox: 0,
};

export const orderTariffPopupSteps = [
  orderTariffPopupNames.addressRequestForm,
  orderTariffPopupNames.tariffDetailsForm,
  orderTariffPopupNames.calendarForm,
  orderTariffPopupNames.alternativeTariff,
  orderTariffPopupNames.summaryPage,
  orderTariffPopupNames.shortSuccess,
  orderTariffPopupNames.fail,
  orderTariffPopupNames.convergenceTariffs,
  orderTariffPopupNames.mobileTariffs,
  orderTariffPopupNames.addressNotAvailableModal,
];

export const tariffServiceType = {
  wifiRouter: 13,
  tvConsole: 12,
  staticIp: 7,
};

export const highSpeedVerification = {
  withVerification: false,
  withoutVerification: true,
};

export const BACK_CALL_POPUP_IS_SHOWN = 'backCallPopupIsShown';

export const CONNECTION_TEXT = {
  restricted: 'ограничение по скорости',
  available: 'доступно',
  unavailable: 'недоступно',
  failed: 'что-то пошло не так',
};

/** Маска для поля ввода квартиры.
 * Согласно ТЗ пользователь должен иметь возможность вводить не более 4-х символов */
export const flatMask = {
  mask: '',
  format: '####',
};
