import React from 'react';
import classNames from 'classnames/bind';
import { PhoneInput, Text } from '@beef/ui-kit';

import { useAppSelector } from '../../store';
import { TitleContent } from '../commonComponents/TitleContent';
import { RouteBackButton } from '../commonComponents/RouteBackButton';
import { useMmpContainerData } from './hooks/useMmpContainerData';
import styles from './styles.pcss';
import { TermsBlock } from './TermsBlock';

const cx = classNames.bind(styles);

/** Блок страницы mnp с информацией и валидацией номера */
const MnpContainer = () => {
  const mnpPage = useAppSelector((state) => state.common?.content?.mnpPage);
  const { title, description, subTitle, infoText, phoneInputLabel } = mnpPage || {};
  const { phone, errorMessage, helpMessage, onChange } = useMmpContainerData();

  return (
    <div>
      <TitleContent title={title} />
      <Text className={cx('description')} color="grey60" size="size4-r">
        {description}
      </Text>
      <RouteBackButton />
      <Text className={cx('title')} size="size2-m">
        {subTitle}
      </Text>
      <Text className={cx('text')} color="grey60" size="size5-r-s">
        {infoText}
      </Text>
      <PhoneInput
        className={cx('input')}
        errorMessage={errorMessage}
        helpMessage={helpMessage}
        label={phoneInputLabel}
        maskSettings={{
          mask: '_',
          format: '+7 ### ###-##-##',
        }}
        onChange={onChange}
        placeholder="+7 ___ ___-__-__"
        value={phone}
      />
      <TermsBlock />
    </div>
  );
};

export { MnpContainer };
