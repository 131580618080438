import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames/bind';
import {
  Button,
  InputGroup,
  Label,
  Preloader,
  SmartCheckbox,
  SmartForm,
  SmartInput,
  Tooltip,
} from '@beef/legacy-ui-kit';

import { templateParser, unformatPhone } from 'utils/format-string';
import PlainContainer from 'pages-components/PopupContainer/PlainContainer';
import { email as validateEmail } from 'utils/validators';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class EmailSubscriptionForm extends Component {
  email = '';

  state = {
    step: 'form',
    phone: '+7 ',
    pending: false,
  };

  componentDidMount() {
    if (this.props.contactNumber) {
      this.onPhoneChange(this.props.contactNumber);
    }
  }

  onButtonClick = () => {
    const { step } = this.state;

    if (step === 'error') {
      this.setState({ step: 'form' });
    }
  };

  onPhoneChange = (phone) => {
    this.setState({ phone });
  };

  onSubmit = ({ email, phone }) => {
    const { orderNumber, tariffId } = this.props;
    const formatPhone = unformatPhone(phone);

    this.setState({ pending: true });

    axios
      .post('/Feedback/CollectEmail/', {
        phone: formatPhone === '7' ? null : formatPhone,
        orderNumber,
        tariffId,
        email,
      })
      .then(({ data }) => {
        this.email = email;

        this.setState({ step: data.isSucceeded ? 'success' : 'error' });
      })
      .catch(() => {
        this.setState({ step: 'error' });
      })
      .finally(() => {
        this.setState({ pending: false });
      });
  };

  render() {
    const { contactNumber } = this.props;
    const { step, phone, pending } = this.state;
    const texts = this.props.texts[step];

    return (
      <PlainContainer
        {...texts}
        className={cx('form')}
        description={templateParser(texts.description, {
          contactNumber,
          email: this.email,
        })}
        onButtonClick={step === 'form' ? null : this.onButtonClick}
      >
        {step === 'form' && (
          <SmartForm
            inline
            onSubmit={this.onSubmit}
            validator={{
              email: (value) => ({
                status: validateEmail(value).status === 'fail' ? 'typo' : 'ok',
              }),
              phone: (value) => ({
                status: value.length === 16 || value === '+7 ' ? 'ok' : 'typo',
              }),
              agreement: (value) => ({ status: value ? 'ok' : 'typo' }),
            }}
          >
            <InputGroup>
              <Label>Email</Label>
              <SmartInput className={cx('input')} inputId="email" name="email" size="big" />
              <Label>Номер телефона</Label>
              <SmartInput
                className={cx('input')}
                inputId="phone"
                keepInitialValue
                mask="+7 ___ ___ __ __"
                name="phone"
                size="big"
                value={phone}
              />
              <div className={cx('checkbox')}>
                <SmartCheckbox inputId="agreement" name="agreement">
                  {texts.agreement.text}
                  <Tooltip className={cx('tooltip')}>{texts.agreement.tooltip}</Tooltip>
                </SmartCheckbox>
              </div>
              {pending ?
                <Preloader className={cx('preloader')} />
              : <Button className={['big', cx('button')]} transformer>
                  {texts.buttonText}
                </Button>
              }
            </InputGroup>
          </SmartForm>
        )}
      </PlainContainer>
    );
  }
}

EmailSubscriptionForm.propTypes = {
  contactNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orderNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tariffId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  texts: PropTypes.shape({
    description: PropTypes.string,
    agreement: PropTypes.shape({
      tooltip: PropTypes.string,
      text: PropTypes.string,
    }),
    buttonText: PropTypes.string,
  }),
};

export default EmailSubscriptionForm;
