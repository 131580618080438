import { useContext, useEffect, useState } from 'react';

import { TGetUserStatusResponse } from 'pages/MainPage2021/pageBlocks/PersonalInfoBlock/types';
import { getDataUserInfo } from 'pages/MainPage2021/pageBlocks/PersonalInfoBlock/utils';
import { ZeroBalanceSupportContext } from 'pages/Services/ZeroBalanceSupport/context';

import { selectIsAuthenticated } from '../selectors';

/**
 * @desc: Возвращает статус авторизованного пользователя
 * @returns {boolean}
 */
export const useGetUserInfo = () => {
  const context = useContext(ZeroBalanceSupportContext);
  const [userInfo, setUserInfo] = useState<TGetUserStatusResponse | undefined>();

  const isAuthenticated = selectIsAuthenticated(context);

  useEffect(() => {
    if (!isAuthenticated) return;

    getDataUserInfo()
      .then((data) => {
        setUserInfo(data);
      })
      .catch(console.error);
  }, [isAuthenticated]);

  return userInfo;
};
