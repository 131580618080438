import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Button, Text } from '@beef/ui-kit';

import styles from './BeautyCtn.pcss';
import { ctnBeautifier } from '../../utils/ctn-string';
import { LOGIN_TYPES } from '../../actions/constants';

const cx = classNames.bind(styles);

const BeautyCtn = ({ ctn, resetForm }) => {
  if (ctn) {
    return (
      <div className={cx('beauty-ctn')}>
        <Text size="size5-m">{ctnBeautifier(ctn)}</Text>
        <Button onClick={resetForm} variant="plain">
          Изменить номер
        </Button>
      </div>
    );
  }
  return null;
};

BeautyCtn.propTypes = {
  ctn: PropTypes.string,
  loginType: PropTypes.oneOf(Object.keys(LOGIN_TYPES).map((key) => LOGIN_TYPES[key])),
  resetForm: PropTypes.func,
};

export default BeautyCtn;
