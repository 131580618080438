import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';

import { getTaps } from 'pages/SearchResultsPage/store/selectors';
import { Tap } from 'pages/SearchResultsPage/components/Taps/Tap';
import { TapWithAnalytics } from 'pages/SearchResultsPage/components/Taps/Tap/TapWithAnalytics';
import { setParametersAction } from 'pages/SearchResultsPage/store/actions';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _Taps = ({ taps, onClick }) => (
  <div className={cx('container')}>
    <div className={cx('text')}>
      <Text color="grey60" size="size5-r-s">
        уточнить:
      </Text>
    </div>
    <div className={cx('taps')}>
      {taps.map((tap) => (
        <TapWithAnalytics key={tap.tap}>
          <Tap onClick={onClick} relatedSearch={tap.relatedSearch} tap={tap.tap} />
        </TapWithAnalytics>
      ))}
    </div>
  </div>
);

_Taps.propTypes = {
  taps: PropTypes.arrayOf(
    PropTypes.shape({
      relatedSearch: PropTypes.string,
      tap: PropTypes.string,
    }),
  ),
};

const mapStateToProps = (state) => ({
  taps: getTaps(state),
  shouldRender: !!getTaps(state).length,
});
const mapDispatchToProps = (dispatch) => ({
  onClick: (val) => dispatch(setParametersAction('term', val)),
});

export const Taps = compose(connect(mapStateToProps, mapDispatchToProps), withRenderFlag)(_Taps);
