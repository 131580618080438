import { BubbleTiles, Regular, StrikedOutValue } from 'pages-components/Tiles';

/**
 * Карта для набора компонентов Tiles;
 * Карта состоит из объекта который содержит в себе:
 *  1) ключи (ключи равны значению поля widgetType);
 *  2) componentName - Название компонента который будет отрисован;
 *  3) tileType - Навание типа по которому будет склыдваться объект с параметрами из mapper'a;
 */
export default {
  'with-popup': {
    componentName: Regular,
    tileType: 'withPopup',
  },
  'constructor-minutes': {
    componentName: Regular,
    tileType: 'regularButton',
  },
  normal: {
    componentName: Regular,
    tileType: 'regularButton',
  },
  crossing: {
    componentName: StrikedOutValue,
    tileType: 'strikedOut',
  },
  bubble: {
    componentName: BubbleTiles,
    tileType: 'bubble',
  },
};
