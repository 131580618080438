import React from 'react';
import classNames from 'classnames/bind';
import { cnTheme } from '@carnica/themes';

import styles from './styles.pcss';
import rootStyles from '../../../ui/root.pcss';
import { useHideScrollbar } from './hooks/useHideScrollbar';

const cx = classNames.bind({ ...rootStyles, ...styles });

export const MNPLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  /** Скрываем скроллбар на лендинге */
  useHideScrollbar();

  return (
    <main className={cx(cnTheme('light'))} id="main-mnp-redesign-layout">
      {children}
    </main>
  );
};
