import React, { FC } from 'react';
import { Avatar } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TBillIcons = {
  activeIcons: string[];
};

/** Варианты анимации иконок в чеке ЕК */
const variantsIcons = {
  hidden: {
    opacity: 0,
  },
  visible: (custom: number) => ({
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: custom * 0.04 },
  }),
  exit: (custom: number) => ({
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: custom * 0.04 },
  }),
};

/** Компонент иконок опций в чеке ЕК */
export const BillIcons: FC<TBillIcons> = ({ activeIcons = [] }) => {
  return (
    <motion.div className={cx('wrapper')} layout>
      <AnimatePresence initial={false}>
        {activeIcons?.map((activeIcon, index) => (
          <motion.div
            animate="visible"
            custom={index}
            exit="exit"
            initial="hidden"
            key={activeIcon}
            layout
            variants={variantsIcons}
          >
            <Avatar className={cx('wrapper__icon')} image={activeIcon} size="s" />
          </motion.div>
        ))}
      </AnimatePresence>
    </motion.div>
  );
};
