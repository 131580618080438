import axios from 'axios';

import { FANCY_NUMBER_PATH as endpoint } from 'constants/Endpoints';
import { unformatPhone } from 'utils/format-string';

export async function postFriendFormData(payload) {
  const { sendInviteUrl } = this.props;

  try {
    const {
      data: { homeRegion: isBeeline },
    } = await axios(`${endpoint}/isPhoneBelongToCurrentRegion?phone=${unformatPhone(payload.ctn)}`);

    if (isBeeline) {
      throw 'wrongFriendCtn';
    }

    const { data } = await axios.post(sendInviteUrl, payload);

    if (!data.isSucceeded) {
      throw 'error';
    }

    localStorage.removeItem('friendNumber');
    localStorage.removeItem('friendName');

    this.setState({
      popupStep: 'success',
    });
  } catch (err) {
    if (err === 'wrongFriendCtn') {
      this.setState({ wrongFriendCtn: true });
    } else {
      this.setState({ popupStep: 'error' });
    }
  }
}
