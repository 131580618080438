import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// YM
import {
  yandexPlusConnectedYMUpd,
  yandexPlusDisconnectedSuccessYMUpd,
} from 'utils/analytics/tariffsYandexMetrica';

// Components
import { PartnerPopup } from '../PartnerPopup';
import { CardComponent } from '../CardComponent';

// Actions
import { connectPartnerSubscription } from '../../packages/api/connectPartnerSubscription';

// Context
import { dispatcher } from '../../packages/context/provider';

// Action
import { changeSubscriptionStatus } from '../../packages/context/actions';

// Constants
import { ERROR_POPUP_ALIASES, POPUP_STEPS } from '../../constants';

const EMPTY_ERROR_CODE = '';

const PartnerCard = ({
  id,
  subscriptionStatusText,
  isSubscriptionStatus,
  imageUrl,
  name,
  description,
  price,
  paymentPeriod,
  checked,
  isPartnerService,
}) => {
  const [isChecked, setChecked] = useState(checked);
  const [isLoading, setLoadingStatus] = useState(false);
  const [isPopupOpened, setPopupStatus] = useState(false);
  const [errorCodeValue, setErrorCodeValue] = useState(EMPTY_ERROR_CODE);
  const [step, setStep] = useState(null);

  const openErrorPopup = (code) => {
    setPopupStatus(true);
    setStep(POPUP_STEPS.error);
    setErrorCodeValue(code);
  };

  const changeSubscriptionListItem = (checkValue, productId) => {
    setChecked(checkValue);
    dispatcher(changeSubscriptionStatus(productId));
  };

  const handleChecked = useCallback(async () => {
    setErrorCodeValue(EMPTY_ERROR_CODE);
    if (!isChecked) {
      try {
        setLoadingStatus(true);
        const response = await connectPartnerSubscription(id);
        const { isSucceeded, code } = response.data;

        if (isSucceeded) {
          yandexPlusConnectedYMUpd(isPartnerService ? 'partner' : 'beeline', name);
          changeSubscriptionListItem(true, id);
        } else {
          openErrorPopup(code);
        }
      } catch (error) {
        openErrorPopup(ERROR_POPUP_ALIASES.SOMETHING_GOING_WRONG);
      } finally {
        setLoadingStatus(false);
      }
    } else {
      yandexPlusDisconnectedSuccessYMUpd(isPartnerService ? 'partner' : 'beeline', name);
      changeSubscriptionListItem(false, id);
    }
  }, [isChecked]);

  const handlePopupSwitch = useCallback(() => setPopupStatus((prevStatus) => !prevStatus), []);
  const showProductDetails = useCallback(() => {
    handlePopupSwitch();
    setStep(POPUP_STEPS.details);
  }, []);

  return (
    <>
      <CardComponent
        description={description}
        handleChecked={handleChecked}
        imageUrl={imageUrl}
        isChecked={isChecked}
        isLoading={isLoading}
        isSubscriptionStatus={isSubscriptionStatus}
        name={name}
        paymentPeriod={paymentPeriod}
        price={price}
        showProductDetails={showProductDetails}
        subscriptionStatusText={subscriptionStatusText}
      />
      {isPopupOpened && (
        <PartnerPopup
          errorCodeValue={errorCodeValue}
          id={id}
          onPopupSwitch={handlePopupSwitch}
          step={step}
        />
      )}
    </>
  );
};

PartnerCard.propTypes = {
  subscriptionStatusText: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  paymentPeriod: PropTypes.string,
  isSubscriptionStatus: PropTypes.bool,
  checked: PropTypes.bool,
  isPartnerService: PropTypes.bool,
};

export { PartnerCard };
