import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type CardLinkTypes = {
  children: React.ReactNode;
  className?: string;
  elementRef?: React.RefObject<HTMLAnchorElement>;
  href: string;
  onClick?: () => void;
  withHover?: boolean;
};

export const CardLink = ({
  elementRef,
  children,
  href,
  className,
  withHover,
  onClick,
}: CardLinkTypes) => (
  <a
    className={cx('component', className, withHover && 'component--with-hover')}
    href={href}
    onClick={onClick}
    ref={elementRef}
  >
    {children}
  </a>
);
