import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from '@beef/legacy-ui-kit';

import Icon from 'pages-components/Icon';
import { getNumberText, templateParser, translate } from 'utils/format-string';

import commonStyles from '../legacy-styles.pcss';
import styles from './BlackListParameters.pcss';

const cx = classNames.bind(styles);

const BlackListAddedNumbers = ({
  addedNumbers,
  restoreNumbers,
  parameter: { value, unit, key, template = '' },
  restore,
  onClean,
  onRestore,
}) => {
  if (addedNumbers === 0 && !restore) {
    return null;
  }

  const textCount = getNumberText(addedNumbers, 'genitive');
  let count = textCount && textCount.length ? textCount : addedNumbers;
  if (restore) {
    count = restoreNumbers;
  }

  return (
    <tr className={cx('row')} key={key}>
      <td className={cx('title', restore && 'restore')}>
        <div className={cx('label')}>
          {templateParser(template, {
            number: translate('numbersGenitive', addedNumbers),
            count,
          })}
        </div>
      </td>
      <td className={cx('addCost', 'value')}>
        {restore ?
          <Link onClick={onRestore}>Вернуть</Link>
        : <>
            <span className={cx('valueNumber', 'withUnit')}>{`${value * addedNumbers} `}</span>
            <span>{unit}</span>
            <span className={cx('cleanAdded', 'pointer')} onClick={onClean}>
              <Icon name="cross" />
            </span>
          </>
        }
      </td>
    </tr>
  );
};

BlackListAddedNumbers.propTypes = {
  parameter: PropTypes.object,
  addedNumbers: PropTypes.number,
  restoreNumbers: PropTypes.number,
  restore: PropTypes.bool,
  onClean: PropTypes.func,
  onRestore: PropTypes.func,
};

export default BlackListAddedNumbers;
