import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';

import { setParametersAction } from 'pages/SearchResultsPage/store/actions';
import {
  getCurrentPage,
  getLimit,
  getOffset,
  getTotal,
} from 'pages/SearchResultsPage/store/selectors';
import { getPages } from 'pages/SearchResultsPage/utils/getPages';
import { ENTITY_TYPE } from 'pages/SearchResultsPage/utils/constants';

import styles from './styles.pcss';
import { LinkBlock } from './LinkBlock';

const cx = classNames.bind(styles);

/* TODO украдено из шопа */

const _Pagination = ({ total, current, setOffset, limit, deviceListRef }) => {
  const renderItem = ({ type = ENTITY_TYPE.item, index }) => {
    const isDisabled = current === index || index < 1 || index > total;
    return type === ENTITY_TYPE.dots ?
        <span className={cx(ENTITY_TYPE.dots)} key={`${type}-${index}`}>
          ...
        </span>
      : <LinkBlock
          className={cx(type, { disabled: isDisabled, itemActive: current === index })}
          key={`${type}-${index}`}
          type={type}
          {...(!isDisabled ?
            {
              onClick: () => {
                if (deviceListRef?.current) {
                  deviceListRef.current.scrollIntoView({
                    behavior: 'smooth',
                  });
                }
                setOffset(index * limit - limit);
              },
            }
          : {})}
        >
          {type === ENTITY_TYPE.item && <span className={cx('itemIn')}>{index}</span>}
        </LinkBlock>;
  };
  return (
    <div className={cx('wrapper')}>
      {current > 1 && renderItem({ type: ENTITY_TYPE.prev, index: current - 1 })}
      {getPages(current, total).map(renderItem)}
      {current !== total && renderItem({ type: ENTITY_TYPE.next, index: current + 1 })}
    </div>
  );
};

_Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  setOffset: PropTypes.func.isRequired,
  limit: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setOffset: (val) => dispatch(setParametersAction('offset', val)),
});

const mapStateToProps = (state) => ({
  total: getTotal(state),
  limit: getLimit(state),
  offset: getOffset(state),
  current: getCurrentPage(state),
  shouldRender: getTotal(state) > 1,
});

export const Pagination = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRenderFlag,
)(_Pagination);
