import classNames from 'classnames/bind';
import React, { PropsWithChildren } from 'react';
// Components
import { Text } from '@beef/ui-kit';

import Picture from '../../../../../../components/Picture';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const PartnerDetails: React.FC<
  PropsWithChildren<{
    modalDescription: string;
    modalImg: string;
    modalTitle: string;
  }>
> = ({ modalTitle, modalDescription, modalImg }) => (
  <div className={cx('container')}>
    <Picture className={cx('bg-image')} src={modalImg} />

    <div className={cx('info-block')}>
      <Text size="size2-m">{modalTitle}</Text>

      <Text color="grey60" size="size4-r">
        {modalDescription}
      </Text>
    </div>
  </div>
);
