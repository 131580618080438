import React, { ReactElement } from 'react';
import { PageFixer } from '@beef/smart-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type ContentBlockProps = {
  children: ReactElement;
};

const ContentBlock = ({ children }: ContentBlockProps) => (
  <PageFixer backgroundCustomColor="transparent" size="m">
    <div className={cx('section')}>{children}</div>
  </PageFixer>
);

export { ContentBlock };
