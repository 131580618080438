import PropTypes from 'prop-types';

export const faqItemShape = PropTypes.shape({
  categoryId: PropTypes.string,
  tabs: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }),
});

export const faqContentShape = PropTypes.shape({
  data: PropTypes.arrayOf(faqItemShape),
  categories: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  title: PropTypes.string,
});

export const layoutItemShape = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.shape({
    src: PropTypes.string,
    title: PropTypes.string,
    source: PropTypes.arrayOf(
      PropTypes.shape({
        srcset: PropTypes.string,
        media: PropTypes.string,
      }),
    ),
  }),
});

export const layoutContentShape = PropTypes.shape({
  blocks: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.shape({
      text: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.shape({
        tabId: PropTypes.string,
        isClickableSteps: PropTypes.bool,
        steps: PropTypes.arrayOf(layoutItemShape),
      }),
    }),
  }),
});
