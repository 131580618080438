import React, { memo, useRef } from 'react';
import classNames from 'classnames/bind';

import { TitleContent } from '../../commonComponents/TitleContent';
import { GbPresetContainer } from './components/GbPresetContainer';
import { MinPresetContainer } from './components/MinPresetContainer';
import { InfoBannerContainer } from './components/InfoBannerContainer';
import { useHandlePresetChanges } from './hooks/useHandlePresetChanges';
import { usePreparePresetsPickers } from './hooks/usePreparePresetsPickers';
import { useOnScreenAnalytics } from '../../../tempAnalytics/hooks/useOnScreenAnalytics';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Секция пресетов интернета и минут */
export const PresetsPickers = memo(() => {
  /* Готовим контентные данные и определяем показ пассивок/рекомендаций */
  const { isDisabled, showUpPassives, titleContentProps } = usePreparePresetsPickers();

  /* Отправка запроса для получения данных по пресету */
  useHandlePresetChanges();

  /* Отправка аналитики если блок видим больше 2х секунд */
  const presetsRef = useRef(null);
  useOnScreenAnalytics({ ref: presetsRef, payload: { block_mobile: 'view' } });

  return (
    <section className={cx('wrapper')} ref={presetsRef}>
      <TitleContent {...titleContentProps} />
      <div className={cx('wrapper__pickers')}>
        <GbPresetContainer />
        <InfoBannerContainer {...{ isDisabled, showUpPassives }} />
        <MinPresetContainer />
      </div>
    </section>
  );
});
