// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { TData } from 'constructorV1/api/fetchTotalBill';

/** Локальная функция для формирования props компонента BillCommon */
export const createCommonBillProps = (btnCText: string, data?: TData) => {
  /* Извлекаем данные из data для формирования props */
  const { details, settings, zeroPresetsConditions, requestParams } = data ?? {};

  /** Флаг от бека на скрытие блока с подробной информацией */
  const isBlockDetailsHidden = details?.isBlockDetailsHidden ?? false;

  /** Если массив zeroPresetsConditions не пустой, значит выбраны пресеты без оплаты */
  const isWithoutPay = !!zeroPresetsConditions?.length;

  /** Текст в кнопке результирующего чека.
   * Содержит стандартный текст btnCText (контент) с ценой из объекта writeOff (от бека).
   * Если writeOff отсутствует (null), проверяем наличие массива zeroPresetsConditions (флаг isWithoutPay).
   * При false вернем 0 */
  const btnText = `${btnCText} ${details?.writeOff?.price ?? (isWithoutPay ? '' : 0)}`;

  return {
    /** Props для компонента CPlanSettings */
    cSettingsProps: { ...settings, isWithoutPay, zeroPresetsConditions },
    /** Props для компонента CMoreDetails */
    cDetailsProps: details,
    /** Props для компонента CBillButton (общая кнопка) */
    cBillButtonProps: {
      btnText,
    },
    /** Параметры запроса на смену тарифа */
    requestParams,
    isBlockDetailsHidden,
  };
};
