import React from 'react';
import Proptypes from 'prop-types';
import { Button, Emoji } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AccordionContent = ({ emoji, text, button, link }) => (
  <div className={cx('accordionWrapper')}>
    <div className={cx('accordionContent')}>
      <Emoji name={emoji} />
      <div className={cx('contentContainer')}>
        <span className={cx('accordionText')} dangerouslySetInnerHTML={{ __html: text }} />
        {button && (
          <Button
            className={[cx('accordionButton'), 'big']}
            href={button.url}
            transformer
            type="button"
          >
            {button.text}
          </Button>
        )}

        {link && <a href={link.url}>{link.text}</a>}
      </div>
    </div>
  </div>
);

AccordionContent.propTypes = {
  emoji: Proptypes.string,
  text: Proptypes.string,
  button: Proptypes.shape({
    url: Proptypes.string,
    text: Proptypes.string,
  }),
  link: Proptypes.shape({
    url: Proptypes.string,
    text: Proptypes.string,
  }),
};

export default AccordionContent;
