import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';

import { TCAccordionItem } from '../CAccordionItem';
import { COptionsGroup } from '../COptionsGroup';
import { CTotalPrice } from '../CTotalPrice';
import { TCBillIcons } from '../CBillIcons';
// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { CAccordion } from '../CAccordion';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCPlanSettings = {
  isWithoutPay?: boolean;
  presetsText?: string;
  sections?: Array<{
    options: TCBillIcons[];
    title: string;
  }>;
  subTitle?: string;
  title?: string;
  zeroPresetsConditions?: TCAccordionItem[];
};

/** Варианты анимации "черного" (black block) блока с настройками в результирующем чеке ЕК */
const variantsCSettings = {
  hidden: {
    height: 0,
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
};

/** Блок с выбранными пресетами/опциями/бонусами для общего чека (black block) */
export const CPlanSettings: FC<TCPlanSettings> = ({
  isWithoutPay,
  presetsText,
  title,
  subTitle,
  sections,
  zeroPresetsConditions,
}) => {
  return (
    <LayoutGroup>
      {/* Блок со значениями подключаемых пресетов */}
      <motion.section className={cx('wrapper')} layout style={{ borderRadius: '24px' }}>
        {presetsText && (
          <motion.div layout>
            <Text align="left" color="primary-invert" lineHeight={40} size="xl" weight="regular">
              {presetsText}
            </Text>
          </motion.div>
        )}
        {!!sections?.length && (
          <AnimatePresence initial={false}>
            <motion.div
              animate="visible"
              className={cx('wrapper__options')}
              exit="exit"
              initial="hidden"
              layout
              variants={variantsCSettings}
            >
              <COptionsGroup {...{ sections }} />
            </motion.div>
          </AnimatePresence>
        )}
        {/* Блок с ценой и периодом оплаты подключения/нового условия */}
        <CTotalPrice {...{ title, subTitle }} />
        {/* Раскрывающийся список с подробным описанием по оплате */}
        <CAccordion info={zeroPresetsConditions} isWithoutPay={isWithoutPay} />
      </motion.section>
    </LayoutGroup>
  );
};
