import { MutableRefObject, useEffect, useRef } from 'react';

import { ymPushEvent, ymPushParams } from 'utils/ym';
import { useOnScreen } from 'utils/hooks/useOnScreen';

import { pushYm } from '../metricUtils';

type TUseOnScreenAnalytics = {
  event?: string;
  isForce?: boolean;
  payload: Record<string, any> | null;
  ref?: MutableRefObject<HTMLDivElement | null>;
};

/** Хук для разовой отправки аналитики при видимости блока или части блока более 2-х секунд
 * @param ref - ref блока
 * @param payload - значение для отправки аналитики
 * @param threshold - допустимый процент пересечения target и root
 * @param isForce - если true то отправляет данные в метрику напрямую без модификаций */
export const useOnScreenAnalytics = ({ ref, payload, event, isForce }: TUseOnScreenAnalytics) => {
  const isVisible = useOnScreen(ref);
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (ref?.current && isVisible) {
      timer.current = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isForce ? ymPushParams(payload || {}) : pushYm(payload);
        if (event) ymPushEvent(event);
        ref.current = null;
      }, 2000);
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [isVisible]);

  return null;
};
