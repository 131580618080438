import React, { FunctionComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { DownloadButtons } from '../blocks/DownloadButtons';
import { IFixedButton, IInfo } from '../types';

const cx = classNames.bind(styles);

type DownloadButtonProps = {
  fixedButton: IFixedButton;
  handleClickModal?: () => void;
  isBottomImg?: boolean;
  title?: string;
};

export function withDownloadButton<TProps extends DownloadButtonProps & IInfo>(
  isLightColor: boolean,
) {
  return (WrappedComponent: FunctionComponent<TProps>) => {
    return (props: TProps) => {
      return (
        <div className={cx('wrap')}>
          <WrappedComponent {...props} />
          <div className={cx('buttons-wrap')}>
            <DownloadButtons
              className={cx('buttons')}
              {...props.fixedButton}
              handleClickModal={props?.handleClickModal}
              handleCloseModal={props?.handleCloseModal}
              isLightColor={isLightColor}
              screenTitle={props?.title}
            />
          </div>
        </div>
      );
    };
  };
}
