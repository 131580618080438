import React from 'react';

import CheckShieldIcon from './assets/icons/check-shield.svg';
import CompasIcon from './assets/icons/compas.svg';
import DotsMessageIcon from './assets/icons/dots-message.svg';
import FlagIcon from './assets/icons/flag.svg';
import GiftIcon from './assets/icons/gift.svg';
import GlobeIcon from './assets/icons/globe.svg';
import LableIcon from './assets/icons/lable.svg';
import PlayIcon from './assets/icons/play.svg';
import ShieldIcon from './assets/icons/shield.svg';
import SoundIcon from './assets/icons/sound.svg';
import EsimIcon from './assets/icons/esim.svg';
import IPhoneIcon from './assets/icons/phone.svg';
import SimIcon from './assets/icons/sim.svg';
import { IAnimatedIcons, IHoveredIcons } from './store/slices/commonSlice/types';

/**
 * Иконки для карточек в блоке AdditionalInfo
 */
export const hoveredIconsCardArray = [
  {
    RightDownIcon: () => <CheckShieldIcon />,
    BottomIcon: () => <ShieldIcon />,
    RightTopIcon: () => <CheckShieldIcon />,
    LeftTopIcon: () => <CheckShieldIcon />,
  },
  {
    RightDownIcon: () => <CompasIcon />,
    BottomIcon: () => <GlobeIcon />,
    RightTopIcon: () => <FlagIcon />,
    LeftTopIcon: () => <CompasIcon />,
  },
  {
    RightDownIcon: () => <DotsMessageIcon />,
    BottomIcon: () => <SoundIcon />,
    RightTopIcon: () => <PlayIcon />,
    LeftTopIcon: () => <DotsMessageIcon />,
  },
  {
    RightDownIcon: () => <GiftIcon />,
    BottomIcon: () => <LableIcon />,
    RightTopIcon: () => <LableIcon />,
    LeftTopIcon: () => <GiftIcon />,
  },
] satisfies IHoveredIcons[];

/**
 * Иконки для анимации на стартовом экране лендинга
 */
export const animatedIconsArray = [
  { id: 1, Icon: () => <IPhoneIcon />, duration: '2.5s' },
  { id: 2, Icon: () => <SimIcon />, duration: '1s' },
  { id: 3, Icon: () => <EsimIcon />, duration: '1.5s' },
] satisfies IAnimatedIcons[];

/** !!! Перенесено из старого MNP */

/* PEB-4891: Значение захардкожено для корректного формирования запроса корзины в formatOrderData.
Значение "1" соответствует дефолтному тарифу. Согласовано с аналитиком */
export const DEFAULT_CONSTRUCTOR_ID = 1;
