import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

// Components
import { Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import Picture from 'pages-components/Picture';
import Icon from 'pages-components/Icon';
import Logo from 'pages-components/Logo/Logo';
import Ctx from 'pages-components/Ctx';

// Styles
import styles from '../styles.pcss';

const cx = classNames.bind(styles);

/**
 * @param className - Дополнительный класс;
 * @param subText - Элемент в виде желтого текста под основным текстом в левой части блока;
 * @param children - Дочерний элемент, представлена как опциональная возможность добавлять элементы;
 * @param onClick - Callback. Дополнительная кнопка в виде желтого текста под в левой части блока;
 * @param buttonText - Текст дополнительной кнопки;
 * @param valueIcon - Обьект с именем и ссылкой на иконку;
 * @param text - Текст в левой части блока;
 * @param textTooltip - Тултип для текста;
 * @param description - Описание, текст распологается в правой части блока;
 * @param descriptionTooltip - Тултип для описания;
 * @param connected - Статус подключения.
 * @param beeLogo - Флаг для отрисовки логотипа в тексте параметра.
 * @param bottomIcon - Иконка отображается в нижней части параметров.
 * @param reversed - Меняет местами текст и описание.
 * @param descriptionClassName - Дополнительный класс для блока с описанием.
 * @param outerIcon - Иконка отображается в незоны блока tiles в десктопной версии и в параметре в мобиле.
 */
const Regular = ({
  icon,
  text,
  beeLogo,
  subText,
  onClick,
  valueIcon,
  children,
  className,
  connected,
  buttonText,
  textTooltip,
  description,
  bottomIcon,
  outerIcon,
  unlimIcon,
  descriptionTooltip,
  reversed,
  descriptionClassName,
}) => (
  <div className={cx('tiles', { reversed }, className)}>
    <div className={cx('rightBox')}>
      <div className={cx('about', { beeLogo }, { unlimIcon }, outerIcon && 'outerIconParam')}>
        {children}
        {(text || beeLogo) && (
          <>
            {valueIcon && (
              <Picture alt={valueIcon.iconName} className={cx('valueIcon')} src={valueIcon.icon} />
            )}
            <span className={cx('text')} dangerouslySetInnerHTML={{ __html: text }} />
            {outerIcon && (
              <span className={cx('outerIcon')}>
                <Picture alt={outerIcon.iconName} src={outerIcon.icon} />
              </span>
            )}
            {unlimIcon && (
              <span className={cx('unlimIcon')}>
                <Picture src={unlimIcon.icon} />
              </span>
            )}
            {beeLogo && (
              <span className={cx('beeLogoIcon')}>
                <Logo type="single" />
              </span>
            )}
          </>
        )}
        {textTooltip && (
          <Tooltip>
            <span dangerouslySetInnerHTML={{ __html: textTooltip }} />
          </Tooltip>
        )}
      </div>
      {onClick && (
        <EmptyButton aria-label={buttonText} className={cx('button')} onClick={onClick}>
          {buttonText}
        </EmptyButton>
      )}
      {subText && (
        <div className={cx('subDescription')}>
          {subText}
          <span className={cx('icon')}>
            <Icon name={icon} />
          </span>
        </div>
      )}
      {connected && !isEmpty(connected) && (
        <div className={cx('status', connected.state ? 'success' : 'warning')}>
          <span>{connected.text}</span>
        </div>
      )}
    </div>
    {description && (
      <div className={cx('leftBox')}>
        <div className={cx('description', descriptionClassName)}>
          <span
            className={cx('descriptionText')}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {descriptionTooltip && (
            <Tooltip className={cx('descriptionTooltip')}>
              <span dangerouslySetInnerHTML={{ __html: descriptionTooltip }} />
            </Tooltip>
          )}
        </div>
        {bottomIcon && (
          <div className={cx('bottomIcon')}>
            <Picture
              alt={bottomIcon.name}
              className={cx('icon', bottomIcon.name)}
              src={bottomIcon.src}
            />
          </div>
        )}
      </div>
    )}
  </div>
);

Regular.defaultProps = {
  icon: Ctx.tiles.constructorIcon,
  connected: {},
};

Regular.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  descriptionClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  bottomIcon: PropTypes.string,
  beeLogo: PropTypes.bool,
  reversed: PropTypes.bool,
  icon: PropTypes.string,
  subText: PropTypes.string,
  children: PropTypes.object,
  textTooltip: PropTypes.string,
  descriptionTooltip: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  connected: PropTypes.object,
  outerIcon: PropTypes.shape({
    icon: PropTypes.string,
    iconName: PropTypes.string,
  }),
  valueIcon: PropTypes.shape({
    iconName: PropTypes.string,
    icon: PropTypes.string,
  }),
};

export default Regular;
