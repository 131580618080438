import { ActionBar, Button, ButtonVariantType, Link } from '@beef/ui-kit';
import React, { memo } from 'react';

type ModalActionBarProps = {
  button: {
    text: string;
    url: string;
    variant: ButtonVariantType;
  };
  isLoading: boolean;
  isNoFixHeight?: boolean;
  onConfirm?: () => void;
  size: 'l' | 'm' | 's';
};

const ModalActionBar = memo<ModalActionBarProps>(
  ({ button: { text, url: buttonUrl, variant }, onConfirm, isLoading, size }) => (
    <ActionBar isLoading={isLoading} isNoFixHeight={!isLoading} size={size}>
      {buttonUrl ?
        <Link buttonSize="m" buttonVariant={variant ?? 'main'} href={buttonUrl} variant="button">
          {text}
        </Link>
      : <Button onClick={onConfirm} variant={variant}>
          {text}
        </Button>
      }
    </ActionBar>
  ),
);

export { ModalActionBar };
