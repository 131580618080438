import { createSelector } from '@reduxjs/toolkit';
import { generateReactKey } from '@beef/utils';

import { convertNumberToInt, getRedirectUrl } from 'pages/PartnerServiceCard2022/utils';
import { templateParser as replaceVariablesInText } from 'utils/format-string';

import { EResponseCode, MODAL_CODE_MAP } from '../../constants';
import {
  EModalType,
  IPartnerServiceCardState,
  TLayoutType,
  TModal,
  TNullable,
  TPartnerServiceCardDataSubstate,
} from '../../types';
import { ESubscriptionStatus } from './types';

export const selectId = (data: TPartnerServiceCardDataSubstate) => data?.id;
export const selectIsAuthenticated = (data: TPartnerServiceCardDataSubstate) =>
  data?.isAuthenticated;
export const selectAlias = (data: TPartnerServiceCardDataSubstate) => data?.alias ?? '';
export const selectPartnerTechName = (data: TPartnerServiceCardDataSubstate) =>
  data?.partnerTechName?.toLowerCase() || '';
export const selectIsYandex = (data: TPartnerServiceCardDataSubstate) =>
  data?.partnerTechName?.toLowerCase() === 'yandex';
export const selectIsYandexMulti = (data: TPartnerServiceCardDataSubstate) =>
  data?.techName?.toLowerCase().includes('yandexmult');

export const selectPartnerProduct = (data: TPartnerServiceCardDataSubstate) => data?.partnerProduct;

export const selectExtData = (data: TPartnerServiceCardDataSubstate) => data?.extData;
export const selectPartnerContent = createSelector(selectExtData, (data) => data?.partnerContent);
export const selectPartnerContentHeadingButtonContent = (data: TPartnerServiceCardDataSubstate) =>
  selectPartnerContent(data)?.heading?.button;

// TODO: Discuss with the BE devs why there are two sources of information
export const selectIsConnected = (data: TPartnerServiceCardDataSubstate) =>
  data?.isConnected || !!selectPartnerProduct(data)?.isSubscriptionStatus;

export const selectSubscriptionStatus = (
  data: TPartnerServiceCardDataSubstate,
): ESubscriptionStatus => {
  const isConnected = !!selectIsConnected(data);
  const isYandexAssigned = !!selectPartnerProduct(data)?.yandexAssigned;
  const isYandex = selectIsYandex(data);

  const result: (ESubscriptionStatus | boolean)[] = [
    !isConnected && ESubscriptionStatus.Connect,
    isYandex && !isYandexAssigned && ESubscriptionStatus.Activate,
    ESubscriptionStatus.Disconnect,
  ];

  return result.find((v) => !!v) as ESubscriptionStatus;
};

export const selectSubscriptionButtonData = (
  data: TPartnerServiceCardDataSubstate,
  onClick: () => void,
) => {
  const buttonContent = selectPartnerContentHeadingButtonContent(data);
  const subscriptionStatus = selectSubscriptionStatus(data);
  const isConnected = !!data?.isConnected;

  return {
    onClick,
    settings: selectProductParameters(data),
    text: buttonContent?.[subscriptionStatus] || '',
    isConnected,
  };
};

export const selectBenefitsData = (data: TPartnerServiceCardDataSubstate) => {
  const hasMissingImages = data?.benefits?.some(({ icon }) => !icon);
  return {
    title: selectPartnerContent(data)?.benefits?.title,
    benefits:
      data?.benefits?.map((benefit, i) => ({
        key: generateReactKey(benefit),
        name: benefit.title,
        description: benefit.text,
        logo: !hasMissingImages ? benefit.icon : '',
        id: `${i}`,
      })) || [],
    hasImage: !hasMissingImages,
    layoutType: (selectIsYandex(data) ? 'alt' : 'default') as TLayoutType,
  };
};

export const selectYandexBenefits = (data: TPartnerServiceCardDataSubstate) =>
  selectPartnerContent(data)?.yandexMultiBenefits;
export const selectYandexBenefitsData = createSelector(
  selectYandexBenefits,
  (benefits: TPartnerServiceCardDataSubstate['yandexMultiBenefits']) => ({
    title: benefits?.title || '',
    benefits:
      benefits?.items?.map((benefit) => ({
        key: generateReactKey(benefit),
        name: benefit.title || '',
        description: benefit.description || '',
      })) || [],
    hasImage: false,
    layoutType: 'multi' as TLayoutType,
  }),
);

export const selectName = (data: TPartnerServiceCardDataSubstate) =>
  data.partnerTechName || data.techName || data.alias;
export const selectMediaContent = createSelector(selectPartnerContent, (data) => data?.media);
export const selectMediaForAlias = createSelector(
  selectMediaContent,
  selectName,
  (media, name = '') =>
    Object.entries(media).find(([key]) => key.toLowerCase() === name?.toLowerCase())?.[1],
);

export const selectStepsToConnect = (data: TPartnerServiceCardDataSubstate) =>
  selectPartnerContent(data)?.stepsToConnect;
export const selectStepsToConnectTitle = createSelector(selectStepsToConnect, ({ title }) => title);

export const selectHasTrial = createSelector(
  selectPartnerProduct,
  (partnerProduct) => partnerProduct?.hasTrial,
);

export const selectIntegerPriceValue = createSelector(selectPartnerProduct, (product) =>
  convertNumberToInt(product?.price || 0),
);

export const selectIntegerTrialPriceValue = createSelector(selectPartnerProduct, (product) =>
  convertNumberToInt(product?.trialPrice || 0),
);

export const selectPaymentPeriod = createSelector(
  selectHasTrial,
  selectPartnerProduct,
  (hasTrial, product) =>
    (hasTrial ? product?.paymentPeriodTrial : product?.paymentPeriod) || product?.paymentPeriod,
);

export const selectPrice = createSelector(
  selectPaymentPeriod,
  selectIntegerPriceValue,
  (paymentPeriod, price) => (price && paymentPeriod ? `${price} ${paymentPeriod}` : ''),
);

export const selectTrialPrice = createSelector(
  selectPaymentPeriod,
  selectIntegerTrialPriceValue,
  (paymentPeriod, trialPrice) =>
    trialPrice && paymentPeriod ? `${trialPrice} ${paymentPeriod}` : '',
);

export const selectTrialPeriod = createSelector(
  selectPartnerProduct,
  (product) => product?.trialPeriodWithUnit,
);

export const selectVariables = (
  data: TPartnerServiceCardDataSubstate,
  modal?: IPartnerServiceCardState['modal'],
) => ({
  ...data,
  serviceName: data.alias,
  price: selectPrice(data),
  trialPrice: selectTrialPrice(data),
  trialPeriod: selectTrialPeriod(data),
  partnerUrl: selectPartnerUrl(modal),
});

export const selectStepItems = createSelector(
  selectStepsToConnect,
  selectVariables,
  (content, variables) =>
    content?.items.map((item) =>
      Object.entries(item).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: replaceVariablesInText(value, variables),
        }),
        item,
      ),
    ),
);

export const selectExtendedBlockData = (
  data: TPartnerServiceCardDataSubstate,
  onClick: () => void,
) => ({
  title: selectStepsToConnectTitle(data),
  content: [
    {
      tabId: '1',
      isClickableSteps: false,
      steps: selectStepItems(data),
    },
  ],
  button: selectSubscriptionButtonData(data, onClick),
});

export const selectSubscriptionsData = (
  data: TPartnerServiceCardDataSubstate,
  onClick?: (partnerName: TNullable<string>, price: TNullable<string>) => void,
) => ({
  partnerName: data.alias,
  prefixTitle: selectPartnerContent(data)?.subscriptions?.title,
  subscriptions: (data.otherPartnerSubscriptions || [])
    .map((subscription) => {
      const image = subscription.bgImageDesktop || subscription.bgImageMobile || data.fallbackImage;

      return {
        key: generateReactKey(subscription),
        price: {
          value: Number(subscription.feeParams?.[0]?.value) || 0,
        },
        button: {
          link: subscription.link,
          text: selectPartnerContent(data)?.subscriptions?.buttonText,
          target: '_blank',
          variant: 'tertiary',
        },
        text: subscription.description as string,
        onClick: () => onClick?.(subscription.partnerName, subscription.feeParams?.[0]?.value),
        tag: subscription.subscriptionStatusText && {
          children: subscription.subscriptionStatusText,
          textColor: 'black',
          variant: 'gold',
        },
        title: subscription.partnerName || '',
        img: {
          src: image,
          source: [
            {
              srcset: subscription.bgImageMobile,
              media: 'max-width: 787px',
            },
          ],
        },
      };
    })
    .filter(({ img }) => !!img.src),
});

export const selectFAQContainerData = (
  data: TPartnerServiceCardDataSubstate,
  onClick?: (tabId: string) => Promise<unknown>,
) => ({
  data: {
    title: data?.descriptions?.[0]?.title || 'Частые вопросы',
    categories: [],
    data: [
      {
        categoryId: '1',
        tabs: data?.descriptions?.map((item, i) => ({
          id: `${i}`,
          title: item?.title,
          content: item.content?.map(({ label, unit, value }) => [
            {
              text: `${label || ''} ${value || ''} ${unit || ''}`.trim(),
            },
          ]),
        })),
      },
    ],
  },
  hasData: data.descriptions?.length > 0,
  onClick,
});

export const selectDisplayPrice = createSelector(
  selectHasTrial,
  selectPrice,
  selectTrialPrice,
  (hasTrial, price, trialPrice) => (hasTrial ? trialPrice : price),
);

export const selectPreviousPrice = createSelector(selectHasTrial, selectPrice, (hasTrial, price) =>
  hasTrial ? price : '',
);

export const selectProductParameters = createSelector(
  selectDisplayPrice,
  selectPreviousPrice,
  (value = '', previous = '') => ({
    value,
    previous,
  }),
);

export const selectPopupContent = createSelector(
  selectExtData,
  (_: TPartnerServiceCardDataSubstate, modal: IPartnerServiceCardState['modal']) =>
    modal?.modalType,
  (data, step) => data?.content?.[step as TModal],
);

export const selectPopupProps = createSelector(
  [selectPopupContent, selectHasTrial, selectVariables],
  (content, hasTrial, variables) => ({
    content: {
      description: replaceVariablesInText(
        hasTrial ? content?.descriptionTrial : content?.description,
        variables,
      ),
      title: replaceVariablesInText(content?.title, variables),
      imageSrc: content?.icon || '',
      button: {
        confirm: replaceVariablesInText(content?.buttonText, variables),
        cancel: replaceVariablesInText(content?.cancelButtonText, variables),
      },
    },
  }),
);

export const selectEndpoint = createSelector(selectPartnerContent, (data) => data?.endpoint);
export const selectPartnerUrl = (modal?: IPartnerServiceCardState['modal']) => modal?.url;

export const selectHeadBlockData = (
  data: TPartnerServiceCardDataSubstate,
  onClick: () => void,
) => ({
  isConnected: selectIsConnected(data),
  isYandex: selectIsYandex(data),
  content: {
    banner: {
      src: data?.bgImageDesktop,
      source: [
        {
          srcset: data?.bgImageMobile,
          media: 'max-width: 767px',
        },
      ],
    },
    title: data?.pageTitle,
    subscription: {
      text: data?.subscriptionStatusText,
    },
    info: {
      button: selectSubscriptionButtonData(data, onClick),
      page: {
        subtitle: data?.pageSubtitle,
        title: data?.pageTitle,
      },
    },
  },
  isSticky: selectIsYandex(data),
});

export const selectMediaBlockData = createSelector(
  selectMediaForAlias,
  selectIsYandex,
  (data, isYandex) => {
    const media = [data].flat();
    return media
      .filter((m) => m)
      .map((m) => ({
        title: m?.title,
        description: m?.subtitle,
        content:
          m?.items?.map((item) => ({
            id: item.id,
            src: item.icons[0],
          })) || [],
        layoutType: (isYandex ? 'alt' : 'default') as TLayoutType,
        logo: m?.logo,
        background: m?.background,
      }));
  },
);

export const selectConnectEndpoint = createSelector(
  selectEndpoint,
  (endpoint) => endpoint?.connect,
);

export const selectYandexConnectEndpoint = createSelector(
  selectEndpoint,
  (endpoint) => endpoint?.connectYandex || endpoint?.connect,
);

export const selectActivateEndpoint = createSelector(
  selectEndpoint,
  (_: TPartnerServiceCardDataSubstate, href: string) => href,
  (endpoint: Record<string, string>, href: string): string =>
    `${endpoint?.activate}?redirectUrl=${getRedirectUrl(href)}`,
);

export const selectFinishActivationEndpoint = createSelector(
  selectEndpoint,
  (state: TPartnerServiceCardDataSubstate, token: string) => token,
  (endpoint: Record<string, string>, token: string) =>
    `${endpoint?.finishActivation}?token=${token}`,
);

export const selectPaymentEndpoint = createSelector(
  selectEndpoint,
  (endpoint) => endpoint?.payment,
);

export const selectAccountEndpoint = createSelector(
  selectEndpoint,
  (endpoint) => endpoint?.account,
);

export const selectYandexPlusEndpoint = createSelector(
  selectEndpoint,
  (endpoint) => endpoint?.yandexPlus,
);
export const getModalBasedOnCode = (
  code:
    | (typeof EResponseCode)[keyof typeof EResponseCode]
    | EModalType.Preconnect
    | EModalType.Predisconnect,
) => MODAL_CODE_MAP[code];
export const selectModalTypeBasedOnCode = createSelector(
  selectPartnerTechName,
  (_: string, code: EResponseCode) => code,
  (name: string, code: EResponseCode) => {
    const isYandex = name.includes('yandex');
    const isVK = name === 'vk';
    if (isYandex && code === EResponseCode.Success) {
      return EModalType.Preactivate;
    }
    if (isVK && code === EResponseCode.UserNotFound) {
      return EModalType.UserNotFoundError;
    }
    return getModalBasedOnCode(code);
  },
);
