import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import { Button, Emoji, Link } from '@beef/legacy-ui-kit';
import { path, pathOr } from 'ramda';

import store from 'store';
import Icon from 'pages-components/Icon';
import { getNumberCountText, translate } from 'utils/format-string';

import * as actions from '../../../../actions/sharing';
import TextContainer from '../TextContainer/TextContainer';
import DataBlocks from './components/DataBlocks/DataBlocks';
import styles from './ProfileTable.pcss';

const cx = classNames.bind(styles);

class ProfileTable extends Component {
  renderInfo(data) {
    const units =
      data.currency && data.pricePeriod ? `${data.currency}${data.pricePeriod}` : `${data.unit}`;
    const info = `${data.price} ${units} за ${data.count} ${translate('number', data.count)}`;
    return <span>{info}</span>;
  }

  renderText(textPath) {
    const { count, price, unit } = this.props.data;
    const numbersUnit = translate('number', count);

    return (
      <TextContainer
        path={textPath}
        variables={{
          maxNumbers: count,
          priceUnit: unit,
          numbersUnit,
          price,
        }}
      />
    );
  }

  render() {
    if (!this.props.data) return null;

    const { isSeb, count, invites, nearestTariff } = this.props.data;
    const { addNumber, disconnectNumber } = this.props.actions;

    if (!isSeb) return null;

    return (
      <DataBlocks.Block
        info={
          this.props.data ?
            this.renderInfo(this.props.data)
          : this.renderText(['texts', 'profileBlockInfo'])
        }
        title={
          <span>
            Подключенные к тарифу номера
            <Emoji className={cx('sharingIcon')} name="sharing" />
          </span>
        }
      >
        {invites.length !== 0 ?
          <DataBlocks.Table className="fixedTable">
            {invites.map((invite, key) => (
              <DataBlocks.TableRow
                className={cx('blockTableRow')}
                key={`invite-ctn-${invite.number}`}
              >
                <DataBlocks.TableCell>
                  {invite.name && invite.status === 'connected' ? invite.name : `Номер ${key + 1}`}
                </DataBlocks.TableCell>
                <DataBlocks.TableCell>{invite.number}</DataBlocks.TableCell>
                <DataBlocks.TableCell
                  className={cx('blockTableCell', {
                    successCell: invite.status === 'connected',
                  })}
                >
                  {invite.status === 'connected' ?
                    <span>
                      <span className={cx('tick')}>
                        <Icon className={cx('scaled')} name="checked" />
                      </span>
                      <span>Подключен</span>
                    </span>
                  : <span>Ожидает подключения</span>}
                </DataBlocks.TableCell>
                <DataBlocks.TableCell className={cx('iconsCell')}>
                  <button
                    className={cx('iconButton')}
                    onClick={() => disconnectNumber(invite)}
                    type="button"
                  >
                    <Icon className={cx('crossIcon')} name="crossThin" />
                  </button>
                </DataBlocks.TableCell>
              </DataBlocks.TableRow>
            ))}
            <DataBlocks.TableRow>
              <DataBlocks.TableCell colSpan="4">
                {invites.length < count ?
                  <Button className="default" onClick={addNumber}>
                    Добавить номер
                  </Button>
                : <div className={cx('information')}>
                    {nearestTariff ?
                      <div className={cx('attention')}>
                        <div className={cx('infoText')}>
                          {this.renderText(['texts', 'changeTariffHint'])}
                        </div>
                        <div className={cx('newTariff')}>
                          <Emoji className={cx('newTariffIcon')} name="pointingHandUp" />
                          <Link className={cx('newTariffLink')} href={nearestTariff.link}>
                            {`${nearestTariff.label} с ${getNumberCountText(
                              +nearestTariff.phonesAmount,
                              'instrumental',
                            )}`}
                          </Link>
                        </div>
                      </div>
                    : null}
                    <span className={cx('maxNumbersText')}>
                      {this.renderText(['texts', 'connectedMaxNumbersText'])}
                    </span>
                  </div>
                }
              </DataBlocks.TableCell>
            </DataBlocks.TableRow>
          </DataBlocks.Table>
        : <DataBlocks.Table>
            <DataBlocks.TableRow>
              <DataBlocks.TableCell>
                <div className={cx('tableText')}>
                  {this.renderText(['texts', 'emptyProfileMessage'])}
                </div>
              </DataBlocks.TableCell>
            </DataBlocks.TableRow>
            <DataBlocks.TableRow>
              <DataBlocks.TableCell>
                <Button className="default" onClick={addNumber}>
                  Подключить номер
                </Button>
              </DataBlocks.TableCell>
            </DataBlocks.TableRow>
          </DataBlocks.Table>
        }
      </DataBlocks.Block>
    );
  }
}

function findNearestFamilyTariff(tariffData) {
  const invites = path(['data', 'sharing', 'connectedNumbers'], tariffData);
  const family = path(['data', 'familyNavigation'], tariffData);

  if (family && Array.isArray(family)) {
    const sortedFamily = [...family].sort((a, b) => +a.phonesAmount - +b.phonesAmount);
    return sortedFamily.find((item) => item.phonesAmount > invites.length);
  }

  return null;
}

ProfileTable.propTypes = {
  data: PropTypes.object,
  actions: PropTypes.object,
  nearestTariff: PropTypes.object,
};

function mapStateToProps(state) {
  const { tariff } = state.external;
  const sharing = pathOr({}, ['data', 'sharing', 'info'], tariff);
  const invites = pathOr([], ['data', 'sharing', 'connectedNumbers'], tariff);
  const nearestTariff =
    sharing && invites.length === +sharing.count ? findNearestFamilyTariff(tariff) : null;

  return {
    data: { ...sharing, invites, nearestTariff },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addNumber: () => actions.addNumberPopup.update({ opened: true }),
        switchNumber: (i) => actions.switchNumber(i.number, i.status),
        disconnectNumber: (i) => actions.disconnectNumber(i.number, i.status),
      },
      dispatch,
    ),
  };
}

const ProfileTableConnected = connect(mapStateToProps, mapDispatchToProps)(ProfileTable);

export default () => (
  <Provider store={store}>
    <ProfileTableConnected />
  </Provider>
);
