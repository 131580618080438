import { ymPushParams } from 'utils/ym';

const LEVEL_1_VALUE = 'tariff_pdp_zverinyi';
// TODO: отменить после реализации аз
const DEFAULT_AUTH_ZONE = 'nz';

/* Справочник методов подключения */
export const connectMethodTypes = {
  SimOrderForm: 'Купить SIM-карту',
  ChangeTariffForm: 'Сменить тариф',
  MNPForm: 'Перейти в билайн',
  eSIMForm: 'Купить eSIM',
};

/** Ф-я отправки метрики на сервер */
export const pushYm = (value: Record<string, any> | null) => {
  if (!value) return;
  const extValue = { ...value, auth_status: DEFAULT_AUTH_ZONE };
  const params = { [LEVEL_1_VALUE]: extValue };

  /* Отправляем метрику */
  ymPushParams(params);
};
