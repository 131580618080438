import { createAsyncThunk } from '@reduxjs/toolkit';

import { getHref, setHref } from 'utils/dom';

import { sendPreconnectAnalytics } from '../../analytics';
import { getOAuthEndpoint, getPartnerUrl, getResponseCode, sendPostRequestWithId } from '../../api';
import {
  EModalType,
  EResponseCode,
  EThunkError,
  IPartnerServiceCardState,
  TModalHandler,
} from '../../types';
import { openChat } from '../../utils';
import { PARTNER_SERIVCE_MODAL_ACTION } from '../constants';
import {
  getModalBasedOnCode,
  selectAccountEndpoint,
  selectConnectEndpoint,
  selectEndpoint,
  selectIsYandex,
  selectPaymentEndpoint,
} from '../selectors';
import {
  selectId,
  selectModalTypeBasedOnCode,
  selectPartnerUrl,
  selectYandexConnectEndpoint,
  selectYandexPlusEndpoint,
} from '../selectors/selectors';
import { modalSlice } from '../slices';
import { setIsConnected, setPartnerProduct } from './data';

// Success Modal Handler
const successModalHandler = async (state: IPartnerServiceCardState) => {
  const url = selectPartnerUrl(state.modal);

  if (!url) throw new Error(EThunkError.NoPartnerUrl);

  setHref(url);
};

// Preconnect Handler
const preconnectModalHandler: TModalHandler = async (state, dispatch) => {
  const isYandex = selectIsYandex(state.data);
  const id = selectId(state.data);
  const endpoint =
    isYandex ? selectYandexConnectEndpoint(state.data) : selectConnectEndpoint(state.data);

  if (!id) throw new Error(EThunkError.NoId);

  const data = await sendPostRequestWithId(id, endpoint);

  const code = getResponseCode(data);
  const partnerUrl = getPartnerUrl(data) || '';

  const modalType = selectModalTypeBasedOnCode(state.data, code);

  if (code === EResponseCode.Success) {
    dispatch(
      setPartnerProduct({
        yandexAssigned: false,
        isSubscriptionStatus: true,
      }),
    );

    dispatch(setIsConnected(true));
  }

  if (!modalType) throw new Error(EThunkError.NoModalType);

  dispatch(modalSlice.actions.openModal(modalType));
  dispatch(modalSlice.actions.setUrl(partnerUrl));

  sendPreconnectAnalytics(code, state);
};

// Disconnect Modal
const predisconnectModalHandler: TModalHandler = async (state, dispatch) => {
  const endpoint = selectEndpoint(state.data);
  const id = selectId(state.data);

  if (!id) throw new Error(EThunkError.NoId);

  const data = await sendPostRequestWithId(id, endpoint?.disconnect);
  const code = getResponseCode(data);

  const isSuccess = code === EResponseCode.Success;
  const modalType = isSuccess ? EModalType.RequestSuccessDisconnect : getModalBasedOnCode(code);

  if (isSuccess) {
    dispatch(
      setPartnerProduct({
        isSubscriptionStatus: false,
      }),
    );
  }

  if (!modalType) throw new Error(EThunkError.NoModalType);

  dispatch(modalSlice.actions.openModal(modalType));

  sendPreconnectAnalytics(code, state);
};

// Preactive Modal
const preactivateModalHandler: TModalHandler = async (state, dispatch) => {
  const href: string = getHref();
  const oAuthEndpoint = await getOAuthEndpoint(href, state);

  if (!oAuthEndpoint) {
    // TODO: Ideally, there should be a specific popup stating that the subscription is not available
    throw new Error(EThunkError.NoOAuthEndpoint);
  }

  dispatch(modalSlice.actions.setIsLoading(true));
  // NOTE: Should come back based on redirects
  setHref(oAuthEndpoint);
  // NOTE: Don't step out of this function while we're waiting for the redirect
  await new Promise(() => {});
};

const activationSuccessModalHandler: TModalHandler = async (state, dispatch) => {
  const yandexPlusEndpoint = selectYandexPlusEndpoint(state.data);
  if (yandexPlusEndpoint) {
    dispatch(modalSlice.actions.setIsLoading(true));
    setHref(yandexPlusEndpoint);
    // NOTE: Don't step out of this function while we're waiting for the redirect
    await new Promise(() => {});
  }
};

// ERROR MODALS
const defaultErrorTypeHandler: TModalHandler = async (_, dispatch) => {
  dispatch(modalSlice.actions.closeModal());
};

// Activation Error Modal
const activationErrorModalHandler: TModalHandler = async (_, dispatch) => {
  openChat();
  dispatch(modalSlice.actions.closeModal());
};

// Low Balance Modal
const getNotBalanceAccountErrorModalHandler: TModalHandler = async (state) => {
  const endpoint = selectPaymentEndpoint(state.data) || '';
  setHref(endpoint);
};

// Account Error Modal
const getAccountErrorModalHandler: TModalHandler = async (state) => {
  const endpoint = selectAccountEndpoint(state.data) || '';
  setHref(endpoint);
};

// TODO: Name each function separately and assign to the object at the end of the file
export const ModalHandlerMap: Record<string, TModalHandler> = {
  [EModalType.Success]: successModalHandler,
  [EModalType.Preconnect]: preconnectModalHandler,
  [EModalType.Predisconnect]: predisconnectModalHandler,
  [EModalType.Preactivate]: preactivateModalHandler,
  [EModalType.ActivationSuccessExtra]: activationSuccessModalHandler, // TODO: Adjust

  // Errors
  [EModalType.DefaultError]: defaultErrorTypeHandler,
  [EModalType.ActivationError]: activationErrorModalHandler,
  [EModalType.NoBalanceAccountError]: getNotBalanceAccountErrorModalHandler,
  [EModalType.AccountError]: getAccountErrorModalHandler,
};

// Thunks
// NOTE: Действие, которе происходит при нажатии кнопки подтверждения в модалке
export const modalActionThunk = createAsyncThunk<void, void, { state: IPartnerServiceCardState }>(
  PARTNER_SERIVCE_MODAL_ACTION,
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const { dispatch } = thunkApi;

    try {
      const {
        modal: { modalType },
      } = state;

      dispatch(modalSlice.actions.setIsLoading(true));

      const modalHandler = ModalHandlerMap[modalType];

      if (!modalHandler) {
        dispatch(modalSlice.actions.closeModal());
        return;
      }

      await modalHandler(state, dispatch);
    } catch (e) {
      console.error(e);
      dispatch(modalSlice.actions.openModal(EModalType.DefaultError));
    } finally {
      dispatch(modalSlice.actions.setIsLoading(false));
    }
  },
);
