import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const DigitalBenefit = ({
  showBenefitPicture,
  icon,
  title,
  text,
  className,
  onClick,
  picture,
  showTitleMobile,
}) => (
  <div className={cx('digitalBenefit', className)}>
    <a className={cx('coverLink')} onClick={onClick} />

    {showBenefitPicture && (
      <div className={cx('pictureContainer')}>
        {picture && <Picture alt={title} src={picture} />}
      </div>
    )}

    <div
      className={cx('wrapper', {
        'wrapper--full-height': !showBenefitPicture,
      })}
    >
      {icon && (
        <div className={cx('icon')}>
          <Picture alt={title} src={icon} />
        </div>
      )}

      <div>
        <div
          className={cx('title', {
            'title--mobile-visible': showTitleMobile,
          })}
        >
          {title}
        </div>
        <div className={cx('text')} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  </div>
);

DigitalBenefit.defaultProps = { showBenefitPicture: true };

DigitalBenefit.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  picture: PropTypes.string,

  showBenefitPicture: PropTypes.bool,
  showTitleMobile: PropTypes.bool,

  onClick: PropTypes.func,
};

export default DigitalBenefit;
