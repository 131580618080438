import { useIsHighPreset } from '../../../../hooks/useIsHighPreset';
import { DEFAULT_INDEX } from '../../../../constants';
import { TPrepareTabs } from '../types';

/** Формирует данные по featured-блокам. Блоки выводятся в попапе выбора персонажа */
export const usePrepareTabs = ({
  preViewCharacter,
  availableUppersTabs,
  defaultCharacterSoc,
}: TPrepareTabs) => {
  /* Определение того, является ли текущий пресет высшим */
  const isHighPreset = useIsHighPreset();

  /* Содержит массив плашек tariffFeatures, soc, optionSoc, activeColor, name и т.п. */
  const featuredContent =
    availableUppersTabs.find((upperTab) => upperTab.optionSoc === defaultCharacterSoc) ??
    availableUppersTabs[DEFAULT_INDEX];

  /* Массив Feature-плашек (отображение доп. информации в блоках попапа выбора персонажа) */
  const tariffFeatures =
    isHighPreset ? featuredContent?.highTariffFeatures : featuredContent?.tariffFeatures;

  /* Текущий активный цвет персонажа */
  const activeColor =
    preViewCharacter ? preViewCharacter.activeColor : featuredContent?.activeColor;

  return { activeColor, tariffFeatures };
};
