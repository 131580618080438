import { Button } from '@beef/ui-kit';
import React from 'react';

import { ModalsControllerProps } from './ModalsController/types';
import { ActionBarButtons, ResponseLayout } from './components';

type DeactivateDebtModalDataProps = Pick<
  ModalsControllerProps,
  'onClose' | 'onIncreaseBalanceClick'
> & {
  data: ModalsControllerProps['data']['deactivateDebt'];
};

export const useDeactivateDebtModalData = ({
  onIncreaseBalanceClick,
  onClose,
  data,
}: DeactivateDebtModalDataProps) => {
  const { title, image, description, buttons } = data || {};
  const { balance, cancel } = buttons || {};

  return {
    modalSize: 'm',
    content: <ResponseLayout description={description} image={image} isSmImg title={title} />,
    actionBar: (
      <ActionBarButtons>
        <Button onClick={onIncreaseBalanceClick}>{balance?.title}</Button>
        <Button onClick={onClose} variant="tertiary">
          {cancel?.title}
        </Button>
      </ActionBarButtons>
    ),
  };
};
