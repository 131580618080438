import React from 'react';

export interface IPaddingProps {
  Tag?: keyof JSX.IntrinsicElements;
  bottom?: number;
  children: React.ReactNode;
  componentRef?: React.RefObject<HTMLElement>;
  horizontal?: number;
  left?: number;
  right?: number;
  top?: number;
  vertical?: number;
}

export const Padding: React.FC<IPaddingProps> = ({
  Tag = 'div',
  bottom,
  children,
  horizontal,
  left,
  right,
  top,
  vertical,
  componentRef,
}) => (
  <Tag
    ref={componentRef}
    style={{
      paddingBottom: vertical || bottom,
      paddingLeft: horizontal || left,
      paddingRight: horizontal || right,
      paddingTop: vertical || top,
    }}
  >
    {children}
  </Tag>
);
