import React, { memo, useCallback, useState } from 'react';
import { RootStateOrAny, connect, useDispatch } from 'react-redux';

import { CaptchaPopup } from 'pages/FancyNumber2022/components/CaptchaWrapper/components/CaptchaPopup';
import {
  loadFilteredNumbersThunk,
  setCaptchaData,
  setCaptchaIsEnabled,
} from 'pages/FancyNumber2022/store/actions/numbers';
import {
  selectIsCaptchaInvalid,
  selectIsFetching,
  selectReCallAfterCaptchaRequest,
} from 'pages/FancyNumber2022/store/selectors/numbers';
import { RECALL_REQUEST_AFTER_CAPTCHA } from 'pages/FancyNumber2022/constants';
import { CaptchaWrapperType } from 'pages/FancyNumber2022/components/CaptchaWrapper/types';
import {
  getActiveFilter,
  getDefCodeValue,
  getQueryValue,
} from 'pages/FancyNumber2022/store/selectors/filter';
import { getCaptchaTexts } from 'pages/FancyNumber2022/store/selectors/content';

const _CaptchaWrapper = memo<CaptchaWrapperType>(
  ({ reCallRequestName, isCaptchaInvalid, query, filter, defCode, isFetching, captchaTexts }) => {
    const dispatch = useDispatch();

    const [captchaToken, changeCaptchaToken] = useState('');
    const [captchaMsg, changeCaptchaMsg] = useState('');
    const [captchaStatus, changeCaptchaStatus] = useState('');
    const [captchaKey, setCaptchaKey] = useState('');

    const handleOnClose = useCallback(() => {
      dispatch(setCaptchaIsEnabled(false));
    }, []);

    const onBackCallBtnClick = useCallback(() => {
      const captchaData = {
        captchaToken,
        key: captchaKey,
      };

      dispatch(setCaptchaData(captchaData));

      if (reCallRequestName === RECALL_REQUEST_AFTER_CAPTCHA.loadFilteredNumbersThunk) {
        dispatch(loadFilteredNumbersThunk({ query, filter, defCode }));
      }

      if (isCaptchaInvalid) {
        changeCaptchaMsg(captchaTexts?.errorMsg);
      }
    }, [
      captchaKey,
      captchaTexts?.errorMsg,
      captchaToken,
      defCode,
      dispatch,
      filter,
      isCaptchaInvalid,
      query,
      reCallRequestName,
    ]);

    const reCaptchaModel = {
      width: 280,
      height: 70,
      name: 'captcha',
      setCaptchaKey,
      onChange: changeCaptchaToken,
    };

    const data = {
      texts: { captchaTexts },
      reCaptchaModel,
      captchaMsg,
      captchaStatus,
      captchaToken,
      captchaKey,
      changeCaptchaMsg,
      changeCaptchaStatus,
      changeCaptchaToken,
      onBackCallBtnClick,
      isFetching,
    };

    return <CaptchaPopup data={data} isLoading={isFetching} onClose={handleOnClose} />;
  },
);

const mapStateToProps = (state: RootStateOrAny) => ({
  reCallRequestName: selectReCallAfterCaptchaRequest(state),
  isCaptchaInvalid: selectIsCaptchaInvalid(state),
  query: getQueryValue(state),
  filter: getActiveFilter(state),
  defCode: getDefCodeValue(state),
  isFetching: selectIsFetching(state),
  captchaTexts: getCaptchaTexts(state),
});

export const CaptchaWrapper = connect(mapStateToProps)(_CaptchaWrapper);
