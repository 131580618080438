import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@beef/ui-kit';

const Luck = ({ onSubmit }) => (
  <Button onClick={onSubmit} size="l" variant="main">
    Мне повезёт
  </Button>
);

Luck.propTypes = {
  onSubmit: PropTypes.func,
};

export default Luck;
