import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TModalIcon = {
  icon?: string;
  testId?: number;
};

/** Компонент иконки сервиса модального окна опции */
export const ModalIcon: FC<TModalIcon> = ({ icon, testId }) => (
  <li className={cx('wrapper')}>
    <img alt="" className={cx('wrapper__icon')} data-t-id={testId} loading="lazy" src={icon} />
  </li>
);
