import React, { FC } from 'react';

import { TitleContent } from '../../commonComponents/TitleContent';
import { useSelectTariffTitle } from './selectors/useSelectTariffTitle';

/** Компонент главного заголовка и описания total чека */
export const TotalBillTitle: FC = () => {
  const titleContentProps = useSelectTariffTitle();
  return <TitleContent {...titleContentProps} />;
};
