import { MutableRefObject } from 'react';

import { vkPushEvent } from 'utils/analytics/vkEvents';
import { VkGoalEvent } from 'pages/FTTB2022/CatalogPage/deprecated/blocks/constants';

import { useSelectLoadingStatus } from '../components/TotalInfo/components/ButtonContainer/selectors/useSelectLoadingStatus';
import { EUserStoryType } from '../../../../store/slices/stepperSlice/types';
import { useStepNavigation } from '../../../../hooks/useStepNavigation';
import { useConnectWithConvergence } from './useConnectWithConvergence';
import { useSubmitCallbackRequest } from './useSubmitCallbackRequest';
import { useSelectContent } from '../selectors/useSelectContent';
import { useConnectBasket } from './useConnectBasket';
import { useGetTotalBill } from './useGetTotalBill';
import { usePrepareTotalYm } from '../../../../tempAnalytics/hooks/usePrepareTotalYm';
import { useChangeTariff } from './useChangeTariff';

/** Подготовка данных для total чека */
export const usePrepareTotalBill = (ref: MutableRefObject<HTMLDivElement | null>) => {
  /* Получение контентных данных */
  const { toPay, payButton: defaultButtonText } = useSelectContent();

  /* Статус запроса данных от бека */
  const { isLoading } = useSelectLoadingStatus();

  /* Тип текущего пользователя */
  const { userType } = useStepNavigation();

  /* Получение асинхронных данных (подключаемые/отключаемые опции, возвраты, итогова стоимость и т.п) */
  const { billGroups, totalPrice, totalPriceText, buttonText, requestParams } =
    useGetTotalBill() || {};

  /* Функция обработчик для кнопки "Оплатить" БЕЗ ШПД настроек */
  const { onBasket } = useConnectBasket();

  /* Функция обработчик для кнопки "Оплатить" С ШПД настройками */
  const { onFttbWithoutBasket } = useConnectWithConvergence();

  /* Функция обработчик для кнопки "Оплатить" на регионе с доступным партнёрским регионом (и выбранным тарифом) */
  const { onCallbackSent } = useSubmitCallbackRequest();
  const { onChangeTariff } = useChangeTariff({ requestParams });

  /* Результирующий вариант функции-обработчика на кнопку "Оплатить" в total чеке.
   * Зависит от типа пользовательского сценария */
  const handleClick =
    {
      [EUserStoryType.mnp]: onBasket,
      [EUserStoryType.notAuthWithoutFTTB]: onBasket,
      [EUserStoryType.authNewSimNotConnectedWithoutFTTB]: onBasket,
      [EUserStoryType.notAuthWithFTTB]: onFttbWithoutBasket,
      [EUserStoryType.authNewSimNotConnectedWithFTTB]: onFttbWithoutBasket,
      [EUserStoryType.tariffUpWithPartnerFTTB]: onCallbackSent,
    }[userType] || onChangeTariff;

  /* Подготовка данных и отправка метрики */
  const handlePushYm = usePrepareTotalYm({ type: 'totalBill', ref });

  /* Функции-обработчик + метрика на кнопку "Оплатить" */
  const onClick = async () => {
    handleClick();
    handlePushYm();
    vkPushEvent(VkGoalEvent.shpdTvIntPurchase);
  };

  /* Текст кнопки берется из от бека из getTotal, если там пусто. то дефолтный текст из контента */
  const payButton = buttonText || defaultButtonText;

  /* Props для компонента TotalInfo (контентная часть, обработчик, итоговая стоимость и доп. описание) */
  const totalInfoProps = { toPay, onClick, payButton, totalPrice, totalPriceText };

  return { isLoading, billGroups, totalInfoProps };
};
