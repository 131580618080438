import axios from 'axios';

import {
  CHANGE_STEP,
  CHOOSE_CONSTRUCTOR,
  GET_TARIFF_CONSTRUCTORS,
  RESET_VALUES,
  SET_VALUES,
  TOGGLE_FLIP_STATE,
  TOGGLE_TARIFF_CONSTRUCTOR,
} from './tariffConsctructorActionsType';
import { toggleTariffConnectionPopup } from './tariffConnect/toggleTariffConnectionPopup';
import { initConnectionPopup } from './tariffConnect/tariffConnectExtension';
import { pushError, pushInitial } from '../components/TariffConstructor/utils/ga';

export const setConstructorStep = (step) => (dispatch, getState) => {
  if (['balanceNotEnough', 'restNotEnough', 'blocked', 'error'].indexOf(step) !== -1) {
    pushError(step, getState().external);
  }

  dispatch({ type: CHANGE_STEP, payload: step });
};

export const getConstructors = (url) => (dispatch) => {
  axios
    .get(url)
    .then(({ data }) => {
      dispatch({ type: GET_TARIFF_CONSTRUCTORS, payload: data });

      let step = 'main';
      if (data.restNotEnough || data.balanceNotEnough || data.blocked) {
        if (data.restNotEnough) {
          step = 'restNotEnough';
        } else if (data.balanceNotEnough) {
          step = 'balanceNotEnough';
        } else if (data.blocked) {
          step = 'blocked';
        }
      }
      dispatch(setConstructorStep(step));
    })
    .catch(() => {
      dispatch(setConstructorStep('error'));
    });
};

export const resetValues = (dispatch) => dispatch({ type: RESET_VALUES });

export const toggleConstructor = (dispatch, getState) => {
  const tariff = getState().external.tariff.data;
  const constructor = getState().external.tariffConstructor;
  const { offerCode } = getState().external.tariff.data;

  if (constructor.step === 'connected' && constructor.opened) {
    return window.location.reload();
  }
  if (!constructor.values.dataTariff && constructor.opened) {
    dispatch(
      initConnectionPopup(tariff.connectPopupUrl, tariff.alias, tariff.soc, 'true', offerCode),
    );
  }
  dispatch({ type: TOGGLE_TARIFF_CONSTRUCTOR });
};

export const toggleFlippedState = (dispatch) => dispatch({ type: TOGGLE_FLIP_STATE });

export const setValues = (value, key) => (dispatch) => {
  dispatch({ type: SET_VALUES, payload: { value, key } });
  // if (getState().external.tariffConstructor.values.dataTariff) {
  //   pushDataTariff(getState().external);
  // }
};

export const chooseConstructor = (dispatch) => dispatch({ type: CHOOSE_CONSTRUCTOR });

const statuses = ['error', 'pending', 'rejected', 'done'];

const fetchCallback =
  (dispatch, checkStatusUrl, requestId) =>
  ({ data, data: { requestStatus } }) => {
    if (!requestStatus || statuses[requestStatus] === 'pending') {
      dispatch(checkStatus(checkStatusUrl, requestId || data.requestId));
    } else if (statuses[requestStatus] === 'done') {
      dispatch(setConstructorStep('connected'));
    } else {
      return Promise.reject(data);
    }
  };

const checkStatus = (checkStatusUrl, requestId) => (dispatch) => {
  setTimeout(() => {
    axios
      .get(`${checkStatusUrl}?requestId=${requestId}`)
      .then(fetchCallback(dispatch, checkStatusUrl, requestId))
      .catch(() => {
        dispatch(setConstructorStep('error'));
      });
  }, 2000);
};

export const connectConstructor = (connectUrl, checkStatusUrl) => (dispatch, getState) => {
  const { values } = getState().external.tariffConstructor;
  const tariff = getState().external.tariff.data;

  if (!tariff.isAuthenticated) {
    // Подключаем текущий тариф
    if (values.constructorId) {
      localStorage.setItem('tariffConstructorState', 'touched');
    }
    dispatch(toggleConstructor);
    dispatch(toggleTariffConnectionPopup(true));
  } else if (values.dataTariff) {
    // Подключаем data-тариф: инициализируем popup подключения с soc и alias data-тарифа
    dispatch(
      initConnectionPopup(tariff.connectPopupUrl, values.dataTariff.alias, values.dataTariff.soc),
    );
    dispatch(toggleConstructor);
    dispatch(toggleTariffConnectionPopup(true));
  } else if (!tariff.isConnected) {
    const { offerCode } = getState().external.tariff.data;
    // Подключение нового тарифа с конструктором. Передаем soc тарифа и id конструктора.
    // В инициализации popup-а подключения подмешиваем id конструктора, результирующий popup меняется
    dispatch(chooseConstructor);
    dispatch(toggleConstructor);
    dispatch(toggleTariffConnectionPopup(true, offerCode));
  } else {
    dispatch(setConstructorStep('connecting'));
    // Применение конструктора к подключенному тарифу
    axios({
      url: connectUrl,
      method: 'post',
      data: { constructorId: values.constructorId },
    })
      .then(fetchCallback(dispatch, checkStatusUrl))
      .catch(() => {
        dispatch(setConstructorStep('error'));
      });
  }
};

export const pushGA = (dispatch, getState) => {
  pushInitial(getState().external);
};
