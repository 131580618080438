export const ORIGINS = {
  beelinePage: 'beeline-page',
  landingPage: 'landing-page',
};

export const CHECKBOX_TYPE = {
  officeType: '3',
  businessType: '2',
};

export const FILTERS = 'filters';

export const FILTERS_FOR_BACKEND = {
  appliedOfficesFilter: 'bt',
  appliedOwnerOfficesFilter: 'beelineOwnOffices',
  appliedDisabledFilter: 'Disabled',
  appliedAlfaBankCardFilter: 'AlfaBankCard',
  appliedAlfaBankCard1525Filter: 'AlfaBankCard1525',
  appliedEquipmentReturnOnlyFilter: 'EquipmentReturnOnly',
  appliedEquipmentReturnReplacementFilter: 'EquipmentReturnReplacement',
};

export const DISABLED_FILTERS_FOR_BACKEND = {
  appliedOfficesFilter: 'appliedOfficesFilter',
  appliedOwnerOfficesFilter: 'appliedOwnerOfficesFilter',
};
