import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { Button, Text } from '@beef/ui-kit';

import {
  initConvertToEsimServiceAuth,
  initDisconnectionPopup,
  initServiceStore,
  toggleServiceConnectionPopup,
} from 'pages/ProductCard/actions/serviceConnect';
import { DefaultStatus } from 'pages/ProductCard/components/ServiceConnectionStatus';
import ServiceDisconnectPopup from 'pages/ProductCard/components/ServiceDisconnectPopup/ServiceDisconnectPopup';
import AccessibleInternetContext from 'pages/Services/AссessibleInternet/AccessibleInternetContext';
import ServiceConnectPopup from 'pages/ProductCard/components/ServiceConnectPopup/ServiceConnectPopup';
import { ymPushParams } from 'utils/ym';
import { redirectToLogin } from 'utils/redirectToLogin';

import RequestSteps from '../RequestSteps';
import {
  STEPS,
  getCurrentStep,
  parseEsiaRedirectQuery,
} from '../../accessibleInternetServiceHelper';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AccessibleInternetButton = ({ data, openAuthPopup, onInit }) => {
  const { content } = data?.extData;
  const {
    isAuthenticated,
    isConnectedLater,
    connectedText,
    canBeRemoved,
    warningText,
    isConnected,
    archiveText,
    isArchived,
    soc,
    alias,
  } = data;
  const [esiaQueryParams, setEsiaQueryParams] = useState({});
  const [step, setStep] = useState(STEPS.INITIAL);
  const [opened, setOpened] = useState(false);
  const computedConnected = isConnected || step === STEPS.CONNECTED;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isAuthenticated && onInit) {
        onInit(data);
      }

      const currentEsiaQueryParams = parseEsiaRedirectQuery({
        queryString: window?.location?.search,
      });
      setEsiaQueryParams(currentEsiaQueryParams);
      const currentStep = getCurrentStep({
        isConnected,
        isAuthenticated,
        esiaQueryParams: currentEsiaQueryParams,
      });
      setStep(currentStep);
      setOpened(currentStep === STEPS.ESIA_REDIRECT);
    }
  }, []);

  const isButtonVisible = useMemo(
    () => !computedConnected && !isConnectedLater && !isArchived && !warningText,
    [computedConnected],
  );

  const onButtonClick = () => {
    ymPushParams({
      page_category: { personal_account: { free_internet: 'go_to_connect' } },
    });

    const { ftRedirectLoginEnabled } = content;

    // переход на новую форму логина /login
    if (!isAuthenticated && ftRedirectLoginEnabled) {
      return redirectToLogin({ connect: true });
    }

    if (isAuthenticated) {
      setOpened(true);
    } else {
      openAuthPopup(content?.restoreAccessForm);
    }
  };

  const handleModalClose = (newStep) => {
    setOpened(false);
    if (newStep) {
      setStep(newStep);
    }
  };

  const disconnect = isConnectedLater ? 'Отменить подключение' : 'Отключить';

  return (
    <div className={cx('wrap')}>
      {!isButtonVisible && (
        <DefaultStatus
          archiveContent={archiveText}
          canBeRemoved={canBeRemoved}
          connectedContent={connectedText}
          data={data}
          disconnectContent={disconnect}
          isArchived={isArchived}
          isConnected={computedConnected}
          isConnectedLater={isConnectedLater}
          soc={soc}
          warningContent={warningText}
        />
      )}

      {isButtonVisible && (
        <div className={cx('button')}>
          <Button disabled={opened} onClick={onButtonClick}>
            {content.preview.buttonText}
          </Button>
        </div>
      )}

      <div className={cx('button-description')}>
        <Text color="grey80" size="size5-r-s" tagtype="p">
          Для подключения услуги потребуется авторизация в Госуслугах
        </Text>
      </div>

      <AccessibleInternetContext.Provider value={{ soc, esiaQueryParams, alias }}>
        <RequestSteps extData={content} onClose={handleModalClose} opened={opened} step={step} />
      </AccessibleInternetContext.Provider>

      <ServiceConnectPopup />
      <ServiceDisconnectPopup isCancel={isConnectedLater} />
    </div>
  );
};

AccessibleInternetButton.propTypes = {
  openAuthPopup: PropTypes.func,
  onInit: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onInit: (data) => {
    dispatch(initServiceStore(data));
    dispatch(initDisconnectionPopup(data.serviceDisconnectionPopup));
  },
  openAuthPopup: (extraData) => {
    dispatch(initConvertToEsimServiceAuth('/authorizationpopup/', extraData));
    dispatch(toggleServiceConnectionPopup(true));
  },
});

export default connect(null, mapDispatchToProps)(AccessibleInternetButton);
