import React, { useContext, useLayoutEffect } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Button, Text } from '@beef/ui-kit';

import { hasShutDownService } from 'pages/ProductCard/utils';

import { SummaryInfo } from './components/SummaryInfo';

// Utils
import { connectSocs, disconnectSocs, getAdditionalInfo } from './utils';

// Actions
import { setSocsOff, setSocsOn, setTotalPrice } from '../../../../../../store/actions';

// Constants
import DispatchContext from '../../../../../../store/constants';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Summary = ({
  unit,
  title,
  options,
  btnText,
  packages,
  handleOrderModal,
  showMinTotalInfo,
  commonTotalPrice,
  isTariffCompleted,
  additionalCommonInfo,
  initialBalancePayment,
  isChangeOptionsTariff,
  isChangePackagesTariff,
  additionalCommonInfoMinTotal,
}) => {
  const _dispatch = useContext(DispatchContext);
  const hasDisableOption = hasShutDownService(options);
  const currentAdditionalInfo = getAdditionalInfo({
    commonTotalPrice,
    showMinTotalInfo,
    additionalCommonInfo,
    initialBalancePayment,
    additionalCommonInfoMinTotal,
  });

  // todo: логику нужно будет вынести в редакс, избавившись от подобных конструкций
  useLayoutEffect(() => {
    _dispatch(setTotalPrice(commonTotalPrice));
    _dispatch(setSocsOff([...disconnectSocs(packages, options)]));
    _dispatch(setSocsOn([...connectSocs(packages, options)]));
  }, [hasDisableOption, commonTotalPrice, isChangeOptionsTariff, isChangePackagesTariff, options]);

  return (
    <>
      {!!commonTotalPrice && (
        <>
          {title && <SummaryInfo commonTotalPrice={commonTotalPrice} title={title} unit={unit} />}

          {(additionalCommonInfo || additionalCommonInfoMinTotal) && (
            <div className={cx('additional')}>
              <Text color="grey60" size="size6-m">
                {currentAdditionalInfo}
              </Text>
            </div>
          )}
        </>
      )}

      {btnText && (
        <div className={cx('button', { 'button--save': !commonTotalPrice })}>
          <Button
            disabled={isTariffCompleted}
            fullWidth
            onClick={handleOrderModal}
            size="l"
            type="button"
            variant={isTariffCompleted ? 'tertiary' : 'main'}
          >
            {btnText}
          </Button>
        </div>
      )}
    </>
  );
};

Summary.propTypes = {
  unit: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.number,
      value: PropTypes.number,
      period: PropTypes.string,
      isShutdown: PropTypes.bool,
      isConnectedPeriod: PropTypes.bool,
    }),
  ),
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      unit: PropTypes.string,
      price: PropTypes.number,
      value: PropTypes.number,
      period: PropTypes.string,
      unitPrice: PropTypes.string,
      remainingSize: PropTypes.number,
    }),
  ),
  btnText: PropTypes.string,
  showMinTotalInfo: PropTypes.bool,
  handleOrderModal: PropTypes.func,
  isTariffCompleted: PropTypes.bool,
  commonTotalPrice: PropTypes.number,
  isChangeOptionsTariff: PropTypes.bool,
  isChangePackagesTariff: PropTypes.bool,
  additionalCommonInfo: PropTypes.string,
  initialBalancePayment: PropTypes.number,
  additionalCommonInfoMinTotal: PropTypes.string,
};
