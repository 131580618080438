import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

import { DescriptionLayout } from './components/DescriptionLayout';
import { PopupButton } from './components/Button';

// Hooks
import { usePrepareContent } from './hooks/usePrepareContent';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Popup = memo(({ onClick, ...content }) => {
  const {
    title,
    btnText,
    priceBtn,
    description,
    discountText,
    notification,
    subDescription,
    showDescriptionLayout,
  } = usePrepareContent(content);

  return (
    <div className={cx('wrapper')}>
      {title && (
        <Text size="size3-b" tagType="h1">
          {title}
        </Text>
      )}
      {showDescriptionLayout && (
        <DescriptionLayout
          description={description}
          discountText={discountText}
          notification={notification}
          priceBtn={priceBtn}
          subDescription={subDescription}
        />
      )}
      {btnText && <PopupButton btnText={btnText} onClick={onClick} price={priceBtn} />}
    </div>
  );
});

Popup.propTypes = {
  onClick: PropTypes.func,
  tariffName: PropTypes.string,
  refundsSum: PropTypes.number,
  totalAmountUnit: PropTypes.string,
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    btnText: PropTypes.string,
    btnSave: PropTypes.string,
    price: PropTypes.number,
  }),
};

export default Popup;
