import React from 'react';
import { Picture } from '@beef/smart-kit';
import { FontColorType, FontSizeType, Link } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { Description } from './Description';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TPowerDescriptionProps = {
  abilityIcon?: string;
  color: FontColorType;
  description: string;
  handleModal: (e: React.MouseEvent<HTMLElement>) => void;
  isTextOnly?: boolean;
  size: FontSizeType;
  text: string;
};

export const PowerDescription: React.FC<Partial<TPowerDescriptionProps>> = ({
  size = 'size5-r-s',
  text,
  color = 'grey60',
  handleModal,
  description = '',
  isTextOnly = false,
  abilityIcon,
}) => (
  <div className={cx('wrapper')}>
    <div className={cx('description')}>
      {!isTextOnly && abilityIcon && <Picture className={cx('image')} src={abilityIcon} />}
      <Description color={color} size={size} value={description} />
    </div>
    {text && !isTextOnly && (
      <Link isNoUnderline onClick={(e) => handleModal?.(e)} size={size}>
        {text}
      </Link>
    )}
  </div>
);
