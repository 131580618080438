import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Button, Text } from '@beef/ui-kit';
import { CarouselWithNavigation } from '@beef/smart-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import { TariffTile } from './TariffTile';
import styles from './styles.pcss';
import { useOffersDataMapper } from './utils';

const cx = classnames.bind(styles);

export const TariffUpOffers = ({ data, onSubmit }) => {
  const { title, tariffs, buttonResetText, onOffersReject } = useOffersDataMapper(data, onSubmit);
  const isMobile = useIsMobileDimension();

  return (
    <div className={cx('component')}>
      <Text className={cx('title')} size="size2-m" tagType="h2">
        {title}
      </Text>
      <CarouselWithNavigation
        className={cx('carousel')}
        paginatePadding={false}
        settings={{
          pagination: {
            clickable: true,
          },
          spaceBetween: 16,
          simulateTouch: true,
          slidesPerView: 'auto',
          observer: true,
          observeParents: true,
        }}
        slideWidthAuto={!isMobile}
      >
        {tariffs.map((tariff) => (
          <div className={cx('tariff-tile')} key={tariff.id}>
            <TariffTile {...tariff} />
          </div>
        ))}
      </CarouselWithNavigation>
      <div className={cx('button')}>
        <Button fullWidth={isMobile} onClick={onOffersReject} size="m" variant="tertiary">
          {buttonResetText}
        </Button>
      </div>
    </div>
  );
};

TariffUpOffers.propTypes = {
  onSubmit: PropTypes.func,
  data: PropTypes.shape({}),
};
