import { useEffect, useState } from 'react';

const pageSectionTransition = { ease: 'linear', duration: 0 };

/* Расчет пропов для motion-обертки, компенсирующей высоту хедера */
export const useHeaderHeightCompensation = () => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const headerElement: HTMLElement | null = document.querySelector(
      '[data-component="site-header-container"]',
    );

    const observer = new ResizeObserver(([entry]: any) => {
      setHeight(entry.contentRect.height);
    });

    if (headerElement) {
      observer.observe(headerElement);
    }

    return () => {
      if (headerElement) {
        observer.unobserve(headerElement);
      }
    };
  }, []);

  return { transition: pageSectionTransition, animate: { paddingTop: height } };
};
