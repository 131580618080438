import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@beef/ui-kit';
import { AppButtons } from '@beef/smart-kit';
import classNames from 'classnames/bind';

import { appBannerAnalyticsProps } from 'pages/TariffsCatalog2021/utils/appBannerAnalyticsProps';
import { useOnScreenAnalytics } from 'pages/TariffsCatalog2021/hooks/useOnScreenAnalytics';
import { useGetDimensionGroup } from 'utils/hooks/useGetDimensionGroup';
import { ymClickStore } from 'utils/analytics/ymCommonEvents';

import styles from './styles.module.pcss';

const cx = classNames.bind(styles);

const Banner = ({ data, authStatus }) => {
  /* Устанавливаем presetsRef на обертку баннера */
  const presetsRef = useRef(null);
  const dimensionGroup = useGetDimensionGroup();
  const backgroundImage = useMemo(
    () =>
      dimensionGroup === 'tablet' ?
        `url(${data.content.backgroundBannerTablet})`
      : `url(${data.content.backgroundBanner})`,
    [dimensionGroup],
  );
  /* Адаптер под новый компонент AppButtons */
  const appButtons = useMemo(
    () =>
      Object.entries(data.content.appButtons).reduce((acc, [store, { link }]) => {
        acc[store] = link;
        return acc;
      }, {}),
    [data.content.appButtons],
  );

  /* Отправка аналитики при видимости блока app баннера */
  useOnScreenAnalytics({
    ref: presetsRef,
    threshold: 0.5,
    payload: appBannerAnalyticsProps({ authStatus }),
  });

  return (
    <div className={cx('banner')} ref={presetsRef} style={{ backgroundImage }}>
      <Text color="grey95" size="size1-m" tagType="span">
        {data.content.title}
      </Text>
      <div className={cx('banner__text')}>
        <Text color="grey95" size="size5-r-s" tagType="div">
          {data.content.text}
        </Text>
      </div>
      <div className={cx('banner__buttons')}>
        {appButtons && (
          <div className={cx('banner__social')}>
            <div className={cx('social-button')}>
              <AppButtons
                appButtons={appButtons}
                onClick={(store) => {
                  window.dataLayer.push({
                    event: 'storeClick',
                    store,
                  });
                  ymClickStore();
                }}
              />
            </div>
          </div>
        )}
        {data.content.qrCodeAppLinkImage && dimensionGroup === 'desktop' && (
          <div className={cx('banner__qr-code-link')}>
            <img alt="qr-code" src={data.content.qrCodeAppLinkImage} />
          </div>
        )}
      </div>
    </div>
  );
};

Banner.propTypes = {
  authStatus: PropTypes.string,
  data: PropTypes.shape({
    content: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      qrCodeAppLinkImage: PropTypes.string,
      backgroundBannerTablet: PropTypes.string,
      backgroundBanner: PropTypes.string,
      appButtons: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export { Banner };
