export const YM_STEPS = {
  START: 'step1_start',
  INPUT_PHONE: 'step2_input_phone',
  INPUT_CODE: 'step3_input_code',
  BLOCK_IN_VIEW: 'offers_block_view',
  BLOCK_ON_CLICK: 'offers_block_click',
  NOT_FOUND: 'offers_not_found',
};

export const YM_PARAMS_TYPE = {
  AUTH: 'auth_type_ev_',
  CARD: 'lp_gifts_ev_',
  BANNER: 'banners_ev_',
};

export const YM_PARAMS = {
  AUTH_BY_SMS_START: { sms: YM_STEPS.START },
  AUTH_BY_SMS_PHONE: { sms: YM_STEPS.INPUT_PHONE },
  AUTH_BY_SMS_CODE: { sms: YM_STEPS.INPUT_CODE },
  AUTH_BY_PASS_START: { password: YM_STEPS.START },
};

export const YM_FULL_PARAMS = {
  YM_POPUP_AUTH_BY_SMS: { [YM_PARAMS_TYPE.AUTH]: YM_PARAMS.AUTH_BY_SMS_START },
  YM_POPUP_AUTH_BY_SMS_SEND_PHONE: { [YM_PARAMS_TYPE.AUTH]: YM_PARAMS.AUTH_BY_SMS_PHONE },
  YM_POPUP_AUTH_BY_SMS_INPUT_CODE: { [YM_PARAMS_TYPE.AUTH]: YM_PARAMS.AUTH_BY_SMS_CODE },
  YM_POPUP_AUTH_BY_PASS: { [YM_PARAMS_TYPE.AUTH]: YM_PARAMS.AUTH_BY_PASS_START },
  YM_NOT_OFFERS: { [YM_PARAMS_TYPE.CARD]: YM_STEPS.NOT_FOUND },
};
