import { path } from 'ramda';

const getTariffData = (state) => {
  const allInOne = path(['external', 'allInOne'], state);
  const tariffName = path(['serverData', 'mobileTariffName'], allInOne);
  const tariffDescription = path(['serverData', 'mobileTariffDescription'], allInOne);
  const soc = path(['serverData', 'soc'], allInOne);
  const tariffValue = path(['serverData', 'feePerMonth'], allInOne);
  const tariffUnit = path(['serverData', 'feePerMonthText'], allInOne);
  const mobileTariffUrl = path(['serverData', 'mobileTariffUrl'], allInOne);
  const hasDiscount = path(['serverData', 'hasDiscount'], allInOne);

  return {
    tariffName,
    tariffValue,
    tariffDescription,
    tariffUnit,
    soc,
    mobileTariffUrl,
    hasDiscount,
  };
};

const getFormData = (state) => {
  const allInOne = path(['external', 'allInOne'], state);
  const address = path(['serverData', 'address'], allInOne);
  const alias = path(['serverData', 'preset'], allInOne);
  const street = path(['street'], allInOne);
  const house = path(['house'], allInOne);
  const flat = path(['flat'], allInOne);
  const flatAlreadyUsed = path(['flatAlreadyUsed'], allInOne);
  const isFlatPartnerConnected = path(['isFlatPartnerConnected'], allInOne);
  const requestData = path(['requestData'], allInOne);
  const region = path(['region'], allInOne);

  return {
    address,
    alias,
    region,
    street,
    house,
    flat,
    flatAlreadyUsed,
    isFlatPartnerConnected,
    requestData,
    isAddressCorrect: !!path(['id'], street) && !!path(['isConnected'], house) && !!flat,
    services: path(['serverData', 'services'], allInOne),
    withoutInternet: path(['activeTabIndex'], allInOne) === 0,
    headerRegionId: path(['headerRegionServerData', 'globalRegionId'], allInOne),
  };
};

export const makeFormData = (state) => ({
  ...getFormData(state),
  ...getTariffData(state),
});
