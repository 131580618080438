import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SmartForm, SmartInput } from '@beef/legacy-ui-kit';
import { Button, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import BISCaptcha from 'pages-components/Captcha/BISCaptcha';
import {
  AUTH_YM_ACTIONS,
  AUTH_YM_EVENTS,
  CAPTCHA_LENGTH,
  YMAuthEvent,
} from 'utils/analytics/ymCommonEvents';

import styles from './SmsLoginForm.pcss';

const cx = classNames.bind(styles);

class SmsFormWithCaptcha extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      captcha: null,
      captchaKey: null,
    };
  }

  setCaptcha = ({ captcha, captchaKey }) => {
    if (captcha.length === CAPTCHA_LENGTH) {
      YMAuthEvent.fillCaptchaInput(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS);
    }
    this.setState({
      captcha,
      captchaKey,
    });
  };

  render() {
    const {
      phone,
      submitSmsFormPhone,
      postUrl,
      description,
      needCaptcha,
      bisUrl,
      sendPhoneButtonText,
    } = this.props;

    if (!phone || !postUrl) {
      return null;
    }

    return (
      <SmartForm
        autoComplete="off"
        className={cx('smsForm')}
        disableIfInvalid
        onSubmit={submitSmsFormPhone}
        validator={{
          Captcha: (v) => ({
            status: v && v.length >= 4 ? 'ok' : 'fail',
            msg: 'Введите символы',
          }),
        }}
      >
        {description && (
          <div className={cx('description')}>
            <Text size="size6-r">{description}</Text>
          </div>
        )}
        <div className={cx('phone')}>{phone}</div>
        <div className={cx('captcha')}>{needCaptcha}</div>
        <div className={cx('sms')}>
          <div className={cx('hasCaptchaItem')}>
            <SmartInput inputId="phone" name="phone" type="hidden" value={phone} />
            <SmartInput inputId="captcha" name="captcha" type="hidden" value={this.state.captcha} />
            <SmartInput
              inputId="captchaKey"
              name="captchaKey"
              type="hidden"
              value={this.state.captchaKey}
            />
            <BISCaptcha
              host={bisUrl}
              inputId="Captcha"
              name="Captcha"
              onChange={this.setCaptcha}
              onClick={() => YMAuthEvent.clickCaptchaInput(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS)}
              onReloadClick={() => YMAuthEvent.reloadCaptcha(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS)}
            />
          </div>
          <div>
            <Button
              fullWidth
              onClick={() => {
                YMAuthEvent.clickAuthButton(
                  AUTH_YM_EVENTS.CHANGE_TARIFF_SMS,
                  AUTH_YM_ACTIONS.NEXT_CAPTCHA,
                );
              }}
              size="l"
              type="submit"
            >
              {sendPhoneButtonText || 'Отправить'}
            </Button>
          </div>
        </div>
      </SmartForm>
    );
  }
}

SmsFormWithCaptcha.propTypes = {
  phone: PropTypes.string,
  submitSmsFormPhone: PropTypes.func,
  postUrl: PropTypes.string,
  description: PropTypes.string,
  needCaptcha: PropTypes.string,
  bisUrl: PropTypes.string,
  sendPhoneButtonText: PropTypes.string,
};

export default SmsFormWithCaptcha;
