import { useState } from 'react';

import { prepareTabs } from '../utils/prepareTabs';
import { prepareTabsWithIcon } from '../utils/prepareTabsWithIcon';
import { findActiveTabContent } from '../utils/findActiveTabContent';
import { useSelectGbValue } from '../../../../DevicesContainer/selectors/useSelectGbValue';
import { useSelectDevicesPopups } from '../../../../DevicesContainer/selectors/useSelectDevicesPopups';
import { useSelectAvailableGbPresets } from '../../../../DevicesContainer/selectors/useSelectAvailableGbPresets';

/** Подготовка контента для компонена ModalContent. Включает в себя табы и данные по пассивкам апперов */
export const usePrepareModalContent = () => {
  /* Текущий активный таб Гб */
  const gbValue = useSelectGbValue();

  /* Конетнтная часть для блока в попапе пассивок */
  const { title } = useSelectDevicesPopups();

  /* Доступные Гб пресеты */
  const gbPresets = useSelectAvailableGbPresets();

  const [selectedTab, setSelectedTab] = useState(gbValue);

  /* Обработчик клика по табу  */
  const onChange = (idx: string) => setSelectedTab(idx);

  /* Пропы для компонента ModalTabs */
  const devicesTabsProps = {
    tabs: prepareTabsWithIcon(prepareTabs(gbPresets)),
    onChange,
    selectedTab,
  };

  /* Находим контент текущего активного таба */
  const activeTabContentProps = findActiveTabContent(selectedTab, gbPresets);

  return { title, devicesTabsProps, activeTabContentProps };
};
