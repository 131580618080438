import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { InfoIcon, Text, Tooltip } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { convertSum } from 'pages/ProductCard/utils';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Title = memo(
  ({ tooltip, title, discount, longRcRateUnit, isNotEnoughMoney, gbAndMinutesLongRate }) => (
    <div className={cx('title')}>
      <Text size="size3-m" tagType="h2">
        {title}
        {tooltip && (
          <span className={cx('tooltip')}>
            <Tooltip message={tooltip}>
              <InfoIcon fill={isNotEnoughMoney ? '#F45151' : '#727281'} size="s" />
            </Tooltip>
          </span>
        )}
      </Text>
      {gbAndMinutesLongRate && longRcRateUnit && (
        <div>
          {!!discount && (
            <Text className={cx('discount')} color="grey40" isCrossedOut size="size5-r-s">
              {gbAndMinutesLongRate}
              &nbsp;
              {longRcRateUnit.replace(/\/мес/, '')}
            </Text>
          )}
          <Text color="grey60" size="size4-r">
            {discount ? convertSum(discount) : gbAndMinutesLongRate}
            &nbsp;
            {longRcRateUnit}
          </Text>
        </div>
      )}
    </div>
  ),
);

Title.propTypes = {
  tooltip: PropTypes.string,
  isNotEnoughMoney: PropTypes.bool,
  title: PropTypes.string,
  discount: PropTypes.number,
  longRcRateUnit: PropTypes.string,
  gbAndMinutesLongRate: PropTypes.number,
};
