import axios from 'axios';
import { formatOrderData } from '@beef/layout-kit/utils';

import { IBasketResponse } from './fetchBasket';

const BASKET_URL = '/basketapi/api/v1/';

/**
 * Создание корзины
 */
export const createBasket = async (payload: ReturnType<typeof formatOrderData>) => {
  try {
    const { data } = await axios.post<IBasketResponse>(
      `${BASKET_URL}${payload.apiEndpoint}`,
      payload.body,
    );

    return data;
  } catch (error) {
    return String(error);
  }
};
