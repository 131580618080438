import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';
import { getPageUrl } from 'pages/FTTB/utils/streetsHelper';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Pagination = ({
  totalPageCount,
  activePage,
  pagination,
  zoneType,
  fixOn,
  displayedPagesRange,
  activePages,
}) => {
  const isLastPage = totalPageCount === activePage;

  const renderPages = () => {
    const pagesList = [];

    const startFrom = activePage <= fixOn ? 1 : activePage - (fixOn - 1);
    let endOn =
      activePage <= fixOn ? displayedPagesRange : activePage + (displayedPagesRange - fixOn);

    if (endOn > totalPageCount) {
      endOn = totalPageCount;
    }

    for (let page = startFrom; page <= endOn; page += 1) {
      if (page === activePage) {
        pagesList.push(
          <span className={cx('paginationPage', 'activePage')} key={page}>
            {page}
          </span>,
        );
      } else {
        pagesList.push(
          <a
            className={cx('paginationPage')}
            href={getPageUrl(page, zoneType, pagination, activePages)}
            key={page}
          >
            {page}
          </a>,
        );
      }
    }

    return pagesList;
  };

  return (
    <div className={cx('pagination')}>
      {activePage > 1 && (
        <a className={cx('prevPage')} href={getPageUrl(activePage - 1, zoneType, pagination)}>
          <Icon className={cx('prevPageIcon')} name="dropdownArrow" />
        </a>
      )}
      {renderPages()}
      {!isLastPage && (
        <a className={cx('nextPage')} href={getPageUrl(activePage + 1, zoneType, pagination)}>
          <Icon className={cx('nextPageIcon')} name="dropdownArrow" />
        </a>
      )}
    </div>
  );
};

Pagination.defaultProps = {
  displayedPagesRange: 8,
  fixOn: 4,
};

Pagination.propTypes = {
  activePage: PropTypes.number,
  totalPageCount: PropTypes.number,
  pagination: PropTypes.shape({
    activePage: PropTypes.number,
    totalPageCount: PropTypes.number,
    url: PropTypes.string,
  }),
  zoneType: PropTypes.number,
  /**
   * Страница после которой начнут меняться отображаемые страницы
   */
  fixOn: PropTypes.number,
  /**
   * Количество отображаеммых страниц
   */
  displayedPagesRange: PropTypes.number,
};

export default Pagination;
