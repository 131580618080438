import React from 'react';

export interface IFlexFactorProps {
  Tag?: keyof JSX.IntrinsicElements;
  basis?: React.CSSProperties['flexBasis'];
  children: React.ReactNode;
  className?: string;
  grow?: React.CSSProperties['flexGrow'];
  shrink?: React.CSSProperties['flexShrink'];
}

export const FlexFactor: React.FC<IFlexFactorProps> = ({
  Tag = 'div',
  basis = 'auto',
  children,
  className,
  grow,
  shrink,
}) => (
  <Tag
    className={className}
    style={{
      flexBasis: basis,
      flexGrow: grow,
      flexShrink: shrink,
    }}
  >
    {children}
  </Tag>
);
