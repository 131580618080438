import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import classNames from 'classnames/bind';
import { Preloader } from '@beef/legacy-ui-kit';

import styles from './styles.pcss';
import MainPopup from '../Popups/Constructor';
import BasicPopup from '../Popups/Basic';
import ConnectingPopup from '../Popups/Connecting';
import ConnectedPopup from '../Popups/Connected';
import RestNotEnoughPopup from '../Popups/RestEnough';
import { getKbyteString, getSecondsString } from '../../utils';
import { toggleConstructor } from '../../../../actions/tariffConstructor';

const cx = classNames.bind(styles);

class TariffConstructor extends Component {
  componentDidMount() {
    if (!this.props.constructorId) {
      this.props.getConstructors();
    }
  }

  getResultValues = () => {
    const { values, accumulators, isLifeFamily } = this.props;

    if (isLifeFamily) {
      return {
        onNetSeconds: getSecondsString(values.onNetSeconds + accumulators.onNetSeconds.size, true),
        offNetSeconds: getSecondsString(
          values.offNetSeconds + accumulators.offNetSeconds.size,
          true,
        ),
      };
    }

    return {
      kbyte: getKbyteString(values.kbyte + accumulators.kbyte.size, true),
      seconds: getSecondsString(values.seconds + accumulators.seconds.size, true),
      sms: `${values.sms + accumulators.sms.size} SMS`,
    };
  };

  render() {
    const {
      texts,
      step,
      authenticated,
      tariffConnected,
      tariffName,
      onClose,
      accumulators,
      connectConstructor,
      onConnectTariffClick,
    } = this.props;

    let component = null;

    if (step === 'pending') {
      component = (
        <div className={cx('loader')}>
          <Preloader />
        </div>
      );
    }

    if (step === 'connecting') {
      component = <ConnectingPopup className={cx('component', 'loader')} />;
    }

    if (step === 'connected') {
      component = (
        <ConnectedPopup
          className={cx('component')}
          resultValues={this.getResultValues()}
          texts={texts}
        />
      );
    }

    if (step === 'error') {
      component = (
        <BasicPopup
          buttonText={texts.errorButton || 'Продолжить работу'}
          className={cx('component')}
          description={texts.errorDescription || 'Произошла ошибка :('}
          heading={texts.errorHeading || 'Упс...'}
          onClick={onClose}
        />
      );
    }

    if (step === 'main') {
      component = (
        <MainPopup
          authenticated={authenticated}
          className={cx('component')}
          connectConstructor={connectConstructor}
          onConnectTariffClick={onConnectTariffClick}
          resultValues={this.getResultValues()}
          tariffConnected={tariffConnected}
          tariffName={tariffName}
          texts={texts}
        />
      );
    }

    if (step === 'balanceNotEnough') {
      component = (
        <BasicPopup
          buttonText={texts.balanceNotEnoughButton}
          className={cx('component')}
          description={texts.balanceNotEnoughDescription}
          heading={texts.balanceNotEnoughHeading}
          onClick={() => {
            window.location.href = texts.balanceNotEnoughLink;
          }}
        />
      );
    }

    if (step === 'blocked') {
      component = (
        <BasicPopup
          buttonText={texts.blockedButton}
          className={cx('component')}
          description={texts.blockedDescription}
          heading={texts.blockedHeading}
          onClick={() => {
            window.location.href = texts.blockedLink;
          }}
        />
      );
    }

    if (step === 'restNotEnough') {
      component = (
        <RestNotEnoughPopup
          className={cx('component')}
          onClick={this.props.toggleConstructor}
          resetDate={accumulators.resetDate}
          texts={texts}
        />
      );
    }

    return component;
  }
}

TariffConstructor.defaultProps = {
  texts: {},
  accumulators: {},
  values: {},
  constructorId: '',
};

TariffConstructor.propTypes = {
  texts: PropTypes.object,
  accumulators: PropTypes.object,
  values: PropTypes.object,
  step: PropTypes.string.isRequired,
  authenticated: PropTypes.bool.isRequired,
  tariffConnected: PropTypes.bool.isRequired,
  constructorId: PropTypes.any,
  getConstructors: PropTypes.func.isRequired,
  toggleConstructor: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  connectConstructor: PropTypes.func.isRequired,
  tariffName: PropTypes.string,
  resetDate: PropTypes.string,
  onConnectTariffClick: PropTypes.func,
  isLifeFamily: PropTypes.bool,
};

export default connect(
  (state) => {
    const tariffConstructor = pathOr({}, ['external', 'tariffConstructor'], state);
    const { values } = tariffConstructor;
    const accumulators = pathOr({}, ['data', 'accumulators'], tariffConstructor);
    const { isLifeFamily } = tariffConstructor;

    return {
      constructorId: tariffConstructor.constructorId,
      accumulators,
      values,
      isLifeFamily,
    };
  },
  (dispatch) => ({
    toggleConstructor: () => dispatch(toggleConstructor),
  }),
)(TariffConstructor);
