import { setCookie } from 'utils/cookie';

const ttl = 60 * 60 * 1000;

const getPagePathname = () =>
  typeof window !== 'undefined' ? window.location.pathname.split('?')[0] : '';

export const savePagePathname = (cookieDomain) => {
  setCookie({
    name: 'pageCategory',
    value: getPagePathname(),
    domain: cookieDomain,
    ttl,
    path: '/',
  });
};
