import React from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@beef/ui-kit';
import { PageFixer, PageRow } from '@beef/smart-kit';

// Hooks
import { usePageRow } from 'utils/hooks/usePageRow';

import { SkeletonExtendedSteps } from './components/SkeletonExtendedSteps';
// Styles
import style from './styles.pcss';

const cn = classNames.bind(style);

const SkeletonLayout = (): JSX.Element => {
  const verticalSpaceValue = usePageRow();
  return (
    <div>
      <Skeleton className={cn('skeleton__teaser-banner')} />

      <PageFixer size="l">
        <div className={cn('skeleton__content-wrapper')}>
          <PageRow space={verticalSpaceValue}>
            <Skeleton className={cn('skeleton__app-banner')} />
          </PageRow>
          <PageRow space={verticalSpaceValue}>
            <SkeletonExtendedSteps />
          </PageRow>
          <PageRow space={verticalSpaceValue}>
            <Skeleton className={cn('skeleton__faq')} />
          </PageRow>
        </div>
      </PageFixer>
    </div>
  );
};

export { SkeletonLayout };
