import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { Logo } from '../../Logo';
import { Button } from '../../Button';
import { ButtonPropColor } from '../../Button/types';
import { BurgerMenu } from '../../BurgerMenu';
import UserSVG from '../icons/user.svg';
import styles from './styles.pcss';
import type { THeaderContent } from './types';

const cx = classNames.bind(styles);

enum EButtonType {
  SUPPORT = 'support',
  AUTHORIZATION = 'authorization',
}

type ButtonData = { color: ButtonPropColor; href: string; label: string; type: EButtonType };

const items: ButtonData[] = [
  {
    color: 'white',
    href: '/customers/pomosh/',
    label: 'Помощь',
    type: EButtonType.SUPPORT,
  },
  {
    color: 'yellow',
    href: '/customers/products/elk/',
    label: 'Войти',
    type: EButtonType.AUTHORIZATION,
  },
];

export const HeaderContent: FC<THeaderContent> = ({
  isDarkTheme,
  isForSideBar,
  isMobile,
  isSideBarOpen,
  switchSideBar,
  ym,
  analyticsEvents,
  isAuth,
}) => {
  const handleClick = (item: ButtonData) => () => {
    window.location.href = item.href;
    ym(item.label, isForSideBar);
    analyticsEvents?.onTopButtonClick?.(item.label, isForSideBar, isMobile);
  };

  return (
    <header className={cx('header')}>
      <Button
        color={isDarkTheme ? 'grey' : 'white'}
        onClick={() => {
          switchSideBar?.();
          if (isSideBarOpen) {
            analyticsEvents?.onSidebarClose?.();
          } else {
            analyticsEvents?.onSidebarOpen?.();
          }
        }}
        size="s"
      >
        <BurgerMenu
          className={cx({
            'burger-menu--open': isSideBarOpen,
          })}
          color={isDarkTheme ? 'grey' : 'white'}
        />
      </Button>
      {(isDarkTheme && isSideBarOpen) || (
        <Logo
          className={cx('logo')}
          link="/customers/products/"
          onClick={analyticsEvents?.onLogoClick}
        />
      )}
      {isMobile ?
        <Button color={isDarkTheme ? 'grey' : 'white'} onClick={handleClick(items[1])} size="s">
          <UserSVG className={cx({ 'user--light': isDarkTheme })} />
        </Button>
      : <div className={cx('button-group')}>
          {items.map((el) => {
            if (el.type === EButtonType.AUTHORIZATION && isAuth) {
              return (
                <Button
                  color={isDarkTheme ? 'grey' : 'white'}
                  key={el.label}
                  onClick={handleClick(el)}
                  size="s"
                >
                  <UserSVG className={cx({ 'user--light': isDarkTheme })} />
                </Button>
              );
            }

            return (
              <Button
                color={isDarkTheme && el.color === 'white' ? 'grey' : el.color}
                key={el.label}
                onClick={handleClick(el)}
                size="m"
              >
                {el.label?.toLowerCase()}
              </Button>
            );
          })}
        </div>
      }
    </header>
  );
};
