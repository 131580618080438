import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
// Components
import { Text } from '@beef/ui-kit';

// Selectors
import { getTotalPrice } from '../../../../../store/selectors/orderTariffDetailsData';
import { getTariffDetailUnit, getTariffDetailUnitDate } from '../../../../../store/selectors/modal';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TotalUnitLayout = ({ unit, unitDate, price }) => (
  <div className={cx('wrapper')}>
    <Text size="size1-m">{price}</Text>
    {unit && (
      <div className={cx('wrapper__unit')}>
        <Text size="size1-m">{unit}</Text>
        <Text color="grey60" size="size5-r-s">
          {unitDate}
        </Text>
      </div>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  price: getTotalPrice(state),
  unit: getTariffDetailUnit(state),
  unitDate: getTariffDetailUnitDate(state),
});

TotalUnitLayout.propTypes = {
  unit: PropTypes.string,
  price: PropTypes.number,
  unitDate: PropTypes.string,
};

export const TotalUnitContainer = connect(mapStateToProps)(TotalUnitLayout);
