import { createSelector } from '@reduxjs/toolkit';

import { selectCurrentStory } from '../story';

const selectVotes = (state) => state.votes;

export const selectIsVoteLoading = (state) => selectVotes(state).isLoading;
export const selectVotesAvailable = (state) => selectVotes(state).available;
export const selectIsVoteAvailableForStory = createSelector(
  selectVotesAvailable,
  selectCurrentStory,
  (votesAvailable, currentStory) => votesAvailable[currentStory?.region] || false,
);
