const REQUEST_TIMEOUT = 30;

const POLL_INTERVAL = 4 * 1000;

const PROGRESS_COLOR_CONFIG = {
  ok: '#80b980',
  warning: '#fac12b',
  danger: '#dc0000',
};

const POLL_STATUSES = {
  InProgress: 'InProgress',
  Succeeded: 'Succeeded',
  Failed: 'Failed',
  NotStarted: 'NotStarted',
};

const getProgressColor = (time) => {
  const percentLeft = (100 * time) / REQUEST_TIMEOUT;

  if (percentLeft < 20) {
    return PROGRESS_COLOR_CONFIG.danger;
  } else if (percentLeft < 50) {
    return PROGRESS_COLOR_CONFIG.warning;
  }
  return PROGRESS_COLOR_CONFIG.ok;
};

export { POLL_INTERVAL, POLL_STATUSES, PROGRESS_COLOR_CONFIG, REQUEST_TIMEOUT, getProgressColor };
