import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link, Text } from '@beef/ui-kit';

import Icon from 'pages-components/Icon';

import { Tariff } from '../Tariff';
import styles from './styles.module.pcss';

const cx = classNames.bind(styles);

const Group = ({ data, showAllTariffs, isLastBlock, archiveLink, authStatus }) => (
  <div className={cx('group')}>
    <div className={cx('group__title')}>
      <Text color="grey95" size="size2-m" tagType="h2">
        {data.title}
      </Text>

      {isLastBlock && (
        <Link
          href={archiveLink}
          isNoUnderline
          rightIcon={<Icon name="chevronRight" />}
          size="size5-m"
        >
          Показать архивные тарифы
        </Link>
      )}
    </div>
    <div
      className={cx('group__items', { 'group__items--half': data?.items[0]?.cardType === 'half' })}
    >
      {data.items.map((tariff) => {
        if (!showAllTariffs && tariff.hideUnderButton) {
          return null;
        }

        return (
          <div className={cx('tariff')} key={tariff.id}>
            <Tariff authStatus={authStatus} data={tariff} />
          </div>
        );
      })}
    </div>
  </div>
);

Group.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.array,
  }),
  showAllTariffs: PropTypes.bool,
  isLastBlock: PropTypes.bool,
  archiveLink: PropTypes.string,
};

export { Group };
