import { useEffect } from 'react';

import { useIsMyTariff } from './useIsMyTariff';
import { useDisableNext } from './disabledNextHooks/useDisableNext';
import { EDisableNextEvent } from '../store/slices/stepperSlice/types';

/** Хук блокирует кнопку далее если это мой тариф */
export const useCheckIsMyTariff = () => {
  const isMyTariff = useIsMyTariff();
  const { disableNext, enableNext } = useDisableNext(EDisableNextEvent.isMyTariff);

  useEffect(() => {
    if (isMyTariff) {
      disableNext();
    }

    if (!isMyTariff) {
      enableNext();
    }
  }, [isMyTariff]);
};
