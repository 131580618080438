import { Button, Text, useIsMobileDimension } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import React from 'react';

import { selectHeadBlockData } from '../../../../store/selectors';
import { getButtonVariant } from '../../../../utils';
import { Price } from '../../../Price';
import { EColor } from '../../types';
import { ContentInfoWrapper } from './ContentInfoWrapper';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type ContentInfoProps = {
  content: ReturnType<typeof selectHeadBlockData>['content']['info'];
  isConnected: boolean;
  isSticky?: boolean;
  isYandex?: boolean;
};

export const ContentInfo: React.FC<ContentInfoProps> = (props) => {
  const {
    isSticky = false,
    isYandex = false,
    isConnected,
    content: {
      page: { subtitle, title },
      button: { onClick, settings, text },
    },
  } = props;

  const fontSizeName = isSticky ? 'size4-m' : 'size0-b';
  const isMobile = useIsMobileDimension();
  const isButtonDark = isSticky || isYandex;
  const buttonVariant = getButtonVariant(isButtonDark, isMobile, isConnected);

  return (
    <ContentInfoWrapper isSticky={isSticky}>
      {isSticky && (
        <div className={cx('content__name')}>
          <Text color={isMobile ? EColor.Grey95 : EColor.White} size={fontSizeName}>
            {title}
          </Text>
        </div>
      )}
      <div className={cx('content__description')}>
        <Text color={isMobile ? EColor.Grey60 : EColor.Grey40} size="size4-r">
          {subtitle}
        </Text>
      </div>
      <div
        className={cx('content__button', {
          'content__button--connectedService': isConnected,
        })}
      >
        <Button
          fullWidth={!!isMobile}
          onClick={() => {
            onClick?.();
          }}
          rightSideNode={!isConnected && settings ? <Price buttonSettings={settings} /> : null}
          size="l"
          type="button"
          variant={buttonVariant}
        >
          {text || ''}
        </Button>
      </div>
    </ContentInfoWrapper>
  );
};
