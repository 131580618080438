// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { TCPlanSettings } from 'constructorV1/components/commonComponents/BillCommon/components/CPlanSettings';
import { TCMoreDetails } from 'constructorV1/components/commonComponents/BillCommon/components/CMoreDetails';
// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { TCBillButton } from 'constructorV1/components/commonComponents/BillCommon/components/CBillButton';

/** Типы данных запроса подключения */
export type TRequestParams = {
  constructorId: number;
  optionsCategories: string[];
  soc: string;
};

/** Справочник типов ошибок метода запроса результирующего чека */
export enum ECBillRequestErrors {
  balance_error = 'balance_error',
  number_is_blocked = 'number_is_blocked',
  something_error = 'something_error',
  changed_error = 'changed_error',
  personal_data_error = 'personal_data_error',
}

/** Типы подготовленного объекта data */
export type TCommonBillData = {
  cBillButtonProps: TCBillButton;
  cDetailsProps: TCMoreDetails;
  cSettingsProps: TCPlanSettings;
  isBlockDetailsHidden?: boolean;
  requestParams: TRequestParams;
};

/** Типы слайса общего чека */
export type TCommonBillSlice = {
  data: TCommonBillData | null;
  errorRequestType: ECBillRequestErrors | null;
  isChangeTariffLoading: boolean;
  isLoading: boolean;
};
