import React from 'react';
import { compose, withReduxStore } from '@beef/redux';
import { connect } from 'react-redux';
import { LandingConstructor } from '@beef/landing-constructor';
import { withDataConverter } from '@beef/react';

import { employeesDataConverter } from '../store/dataConverters';
import { initializeEmployeesLanding } from '../store';
import { selectContent } from '../store/selectors';
import { StoryExamples } from '../components/StoryExamples';
import { EmployeesBanner } from '../components/EmployeesBanner';

const customComponents = { StoryExamples };

const EmployeesLandingLayout = ({ constructorBlocks }) => (
  <>
    <EmployeesBanner />
    <LandingConstructor blocks={constructorBlocks} customComponents={customComponents} />
  </>
);

const mapStateToProps = (state) => ({
  constructorBlocks: selectContent(state).blocks,
});

export const EmployeesLanding = compose(
  withDataConverter(employeesDataConverter),
  withReduxStore(initializeEmployeesLanding),
  connect(mapStateToProps),
)(EmployeesLandingLayout);
