import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import SimpleParameter from '../SimpleParameter';

const cx = classNames.bind(styles);

const FancyNumberWithoutTariff = ({
  price,
  oldPrice,
  isBronze,
  discountValue,
  bronzeDiscountText,
  categoryName,
  numberToHtml,
  deliveryFormComponent,
}) => (
  <>
    <SimpleParameter
      discountText={isBronze && discountValue && bronzeDiscountText}
      oldPrice={!!oldPrice && isBronze && oldPrice}
      price={price}
      title={categoryName}
      value={numberToHtml}
    />
    {deliveryFormComponent && <div className={cx('deliveryForm')}>{deliveryFormComponent}</div>}
  </>
);

FancyNumberWithoutTariff.propTypes = {
  price: PropTypes.number,
  oldPrice: PropTypes.string,
  categoryName: PropTypes.string,
  numberToHtml: PropTypes.string,
  bronzeDiscountText: PropTypes.string,
  discountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  deliveryFormComponent: PropTypes.any,
  isBronze: PropTypes.bool,
};

export default FancyNumberWithoutTariff;
