import React, { FC, useRef } from 'react';
import classNames from 'classnames/bind';

import { ChildrenContainer } from '../ChildrenContainer';
import { ModalHeader } from '../../../../modalComponents/ModalHeader';
import { ServiceTile } from '../../../../../tempComponents/ServiceTile';
import { usePrepareServiceTails } from './hooks/usePrepareServiceTails';
import { ModalContainer } from '../../../../modalComponents/ModalContainer';
import { TServiceTile } from '../../../../../tempComponents/ServiceTile/types';
import { TServiceType } from '../../tempYmUtils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TServiceContainer = {
  service: TServiceTile;
  type?: TServiceType;
};

/** Компонент обертка для вывода плиток доп. услуг ServiceTile.
 * Включает в себя плитку сервиса, ChildrenContainer и модальное окно */
export const ServiceContainer: FC<TServiceContainer> = ({ service, type }) => {
  const serviceRef = useRef(null);

  const { serviceTileProps, modalHeaderProps, modalContainerProps, childrenContainerProps } =
    usePrepareServiceTails(service, serviceRef, type);

  return (
    <div className={cx('wrapper')} ref={serviceRef}>
      <ServiceTile {...serviceTileProps}>
        <ChildrenContainer {...childrenContainerProps} />
      </ServiceTile>
      <ModalContainer {...modalContainerProps}>
        <ModalHeader {...modalHeaderProps} />
      </ModalContainer>
    </div>
  );
};
