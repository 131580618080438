import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import axios from 'axios';
import { Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';

import PopupStateless from 'pages-components/Popup';
import PopupWithBack from 'pages-components/PopupWithBack/PopupWithBack';
import PlainContainer from 'pages-components/PopupContainer/PlainContainer';
import Ctx from 'pages-components/Ctx';
import EmailSubscriptionForm from 'widgets/EmailSubscriptionForm';
import DeliveryForm from 'widgets/DeliveryForm';
import OrderPaymentForm from 'widgets/OrderPaymentForm';
import OrderPaymentResultPopup from 'widgets/OrderPaymentForm/OrderPaymentResultPopup';
import { getGAClientId, pushOrderEvent, shippingTypes } from 'utils/ga';
import { trackCPAEvent } from 'utils/track';
import { templateParser, unformatPhone } from 'utils/format-string';
import { toggleOrderPaymentForm } from 'widgets/actions/orderPaymentForm';
import { openOrderPaymentForm } from 'widgets/actions/deliveryForm';

import styles from './FancyNumberOrderPopup.pcss';
import OrderForm from './OrderForm';

const cx = classNames.bind(styles);

class FancyNumberOrderPopup extends Component {
  state = {
    step: 'form',
    regionFetching: false,
    errorTexts: {},
    feedbackNumber: '+7 ',
    currentFeedbackNumber: '',
    captchaRequired: false,
    captcha: '',
    serverErrors: {},
    warning: {},
    invalidFTTBData: false,
    deliveryFormError: false,
    tariffIndex: 0,
  };

  componentDidMount() {
    const tariffs = this.props.tariffsInfo.tariffs || [];
    const initialTariffIndex = tariffs.findIndex((tariff) => tariff.isBase);
    this.setState({ tariffIndex: initialTariffIndex === -1 ? 0 : initialTariffIndex });
  }

  getDeliveryFormData = () =>
    this.deliveryFormRef && this.deliveryFormRef.getWrappedInstance().onSubmit();

  onDeliveryFormError = () => {
    this.setState({ deliveryFormError: true });
  };

  handleFormSubmit = (needPayment) => async (values) => {
    const { place, orderplace } = this.props;
    const { id, name, soc } = this.selectedTariff;
    const { FTTBData, isOpenAddressForm } = this.orderForm.state;
    let deliveryData = {};

    if (isOpenAddressForm && !(FTTBData.street && FTTBData.house && FTTBData.flat)) {
      this.setState({ invalidFTTBData: true });
      return;
    }

    if (needPayment) {
      this.setState({
        step: 'pending',
        currentFeedbackNumber: this.state.feedbackNumber,
      });

      const isServerError = await this.props.openOrderPaymentForm(
        this.props.orderPaymentFormContent,
        this.props.number,
        id,
        unformatPhone(values.number),
        FTTBData,
      );

      this.setState({ step: isServerError ? 'error' : 'form' });

      return null;
    }

    deliveryData = this.getDeliveryFormData();
    if (this.deliveryFormRef && !deliveryData) return;

    this.setState({
      step: 'pending',
      invalidFTTBData: false,
      currentFeedbackNumber: this.state.feedbackNumber,
    });

    axios
      .post(
        '/fancynumber/makeOrder',
        qs.stringify({
          contactNumber: unformatPhone(values.number),
          number: unformatPhone(this.props.number.number),
          Captcha: values.Captcha,
      _captcha_key: values._captcha_key, // eslint-disable-line
          gaClientId: getGAClientId(),
          orderPlace: orderplace || place,
          dpcId: id,
          ...FTTBData,
          ...deliveryData,
        }),
      )
      .then((resp) => {
        if (resp.data.IsCaptchaRequired && !this.state.captchaRequired) {
          return this.setState({ step: 'form', captchaRequired: true });
        }
        if (resp.data.IsCaptchaError) {
          return this.setState({
            step: 'form',
            serverErrors: { captcha: true },
          });
        }
        if (!resp.data.IsSuccess) {
          return this.setState({
            step: 'error',
            errorTexts: {
              title: resp.data.HeaderMessage,
              description: resp.data.Message,
            },
          });
        }

        pushOrderEvent({
          orderId: resp.data.OrderId,
          price: resp.data.Price,
          ordered_itemCat: 'Tariffs',
          ordered_itemCatNumber: this.props.filterName || 'Случайный номер',
          ordered_itemPodCatNumber:
            this.props.number.categoryName === 'Специальные предложения' ?
              'Бесплатный номер'
            : this.props.number.categoryName,
          ordered_itemMethod: 'Number',
          ordered_itemTitle: name,
          ordered_itemSoc: soc,
          ordered_itemConvergenceAddress: isOpenAddressForm && !this.state.invalidFTTBData,
          ordered_itemConvergenceHome: FTTBData?.FTTBConnected || false,
          ordered_itemShipping:
            shippingTypes[deliveryData && deliveryData['delivery.type']] || 'Не указан',
        });

        trackCPAEvent({
          orderId: resp.data.OrderId,
          price: resp.data.Price,
          type: 'Number',
          soc: '',
        });

        return this.setState({ step: 'success' });
      })
      .catch(() => {
        this.setState({ step: 'error' });
      });
  };

  handleTariffChange = (tariffIndex) => {
    this.setState({ tariffIndex });
  };

  handleFeedbackNumberChange = (feedbackNumber) => {
    this.setState({ feedbackNumber });
  };

  handleCaptchaChange = (captcha) => {
    this.setState({ captcha, serverErrors: {} });
  };

  handleValidate = (values) => {
    const { number } = values;

    if (number.status !== 'ok' || this.state.regionFetching) {
      return;
    }

    const {
      checkRegionUrl,
      orderPopupTexts: { warnings, fancyNumberUrl },
    } = this.props;

    if (checkRegionUrl && number.value !== this.prevNumber) {
      this.setState({ warning: {}, regionFetching: true });
      axios(`${checkRegionUrl}?phone=${unformatPhone(number.value)}`)
        .then(({ data }) => {
          if (!data.result && data.homeRegion) {
            const warning = warnings.otherRegion;
            this.setState({
              regionFetching: false,
              warning: {
                text: templateParser(warning.text, {
                  number: number.value,
                  currentRegion: data.currentRegion && data.currentRegion.titlePreposition,
                  homeRegion: data.homeRegion.titlePreposition,
                }),
                linkText: warning.linkText,
                link: `${data.homeRegion.url && data.homeRegion.url.replace(/\/$/, '')}${
                  fancyNumberUrl[0] === '/' ? '' : '/'
                }${fancyNumberUrl}`,
              },
            });
          } else {
            this.setState({ regionFetching: false });
          }
        })
        .catch(() => {
          this.setState({ step: 'error', regionFetching: false });
        });
      this.prevNumber = number.value;
    }
  };

  onPopupClose = (action) => () => {
    this.props.onOrderFinish(action);
    this.props.toggleOrderPaymentForm(false);
  };

  get texts() {
    const {
      successPopupTexts,
      failPopupTexts,
      number: { number },
    } = this.props;
    const { step, feedbackNumber, errorTexts } = this.state;

    if (step === 'success') {
      return {
        heading: successPopupTexts.title,
        emoji: successPopupTexts.titleIcon,
        description: templateParser(successPopupTexts.description, { feedbackNumber }),
        buttonText: successPopupTexts.buttonText,
        onButtonClick: this.onPopupClose('success'),
      };
    }
    if (step === 'error') {
      return {
        heading: errorTexts.title || Ctx.fancyNumber.errorText,
        emoji: failPopupTexts.titleIcon,
        description: templateParser(errorTexts.description || Ctx.fancyNumber.failText, { number }),
        buttonText: errorTexts.title ? failPopupTexts.buttonText : 'Закрыть',
        onButtonClick: this.onPopupClose(
          errorTexts.description && errorTexts.title ? 'late' : 'fail',
        ),
      };
    }

    return {};
  }

  get selectedTariff() {
    const tariffs = this.props.tariffsInfo.tariffs || [];
    const defaultTariffId = this.props.tariffsInfo.deliveryTariffId;

    return tariffs[this.state.tariffIndex] || { id: defaultTariffId };
  }

  render() {
    const {
      number,
      place,
      orderplace,
      orderPopupTexts,
      emailSubscriptionTexts,
      deliveryForm,
      tariffsInfo,
      currentRegion,
      deliveryActiveTab,
      showOrderPaymentForm,
      showOrderPaymentResultPopup,
      isSelectedEmployeeDelivery,
      isSelectedFastDelivery,
      toPaymentButtonText,
      paymentAvailable,
    } = this.props;
    const {
      step,
      tariffIndex,
      captchaRequired,
      serverErrors,
      warning,
      currentFeedbackNumber,
      invalidFTTBData,
      deliveryFormError,
    } = this.state;
    const showEmailForm = emailSubscriptionTexts ? step === 'success' : false;
    const totalPrice = +number.price + (this.selectedTariff.startBalance || 0);
    const needPayment =
      deliveryActiveTab === 'delivery' &&
      paymentAvailable &&
      (isSelectedEmployeeDelivery || isSelectedFastDelivery);
    const orderFormButtonText = needPayment ? toPaymentButtonText : orderPopupTexts.buttonText;
    const discountLabel =
      tariffsInfo && tariffsInfo.tariffs && tariffsInfo.tariffs[tariffIndex].discountDeliveryOnly ?
        tariffsInfo.tariffs[tariffIndex].discountLabel
      : null;

    if (showOrderPaymentForm) {
      return (
        <PopupWithBack
          backLinkText="Назад"
          onBackClick={() => this.props.toggleOrderPaymentForm(false)}
          onClose={this.onPopupClose('cancel')}
          opened
          showBackButton
        >
          <OrderPaymentForm
            numberCategory={this.props.number.categoryName}
            orderPlace={orderplace || place}
            soc={this.selectedTariff.soc}
            tariffName={this.selectedTariff.name}
          />
        </PopupWithBack>
      );
    }

    if (showOrderPaymentResultPopup) {
      return (
        <PopupStateless onClose={this.onPopupClose('cancel')} opened>
          <OrderPaymentResultPopup />
        </PopupStateless>
      );
    }

    return (
      <PopupStateless onClose={this.onPopupClose('cancel')} opened>
        {showEmailForm && (
          <EmailSubscriptionForm
            contactNumber={currentFeedbackNumber}
            texts={emailSubscriptionTexts}
          />
        )}
        {!showEmailForm && (
          <PlainContainer className={cx('content')} {...this.texts}>
            {step === 'form' && (
              <OrderForm
                {...number}
                captchaRequired={captchaRequired}
                currentFeedbackNumber={currentFeedbackNumber}
                onCaptchaChange={this.handleCaptchaChange}
                onFeedbackNumberChange={this.handleFeedbackNumberChange}
                onFormSubmit={this.handleFormSubmit(needPayment)}
                onTariffChange={this.handleTariffChange}
                onValidate={this.handleValidate}
                onValidateFailed={this.getDeliveryFormData}
                ref={(e) => {
                  this.orderForm = e;
                }}
                serverErrors={serverErrors}
                tariffIndex={tariffIndex}
                tariffs={tariffsInfo && tariffsInfo.tariffs}
                totalPrice={totalPrice}
                {...orderPopupTexts}
                buttonText={orderFormButtonText}
                currentRegion={currentRegion}
                deliveryFormComponent={
                  deliveryForm &&
                  !deliveryFormError && (
                    <DeliveryForm
                      {...deliveryForm}
                      chosenNumber={number}
                      discountLabel={discountLabel}
                      esim
                      onCancel={this.onPopupClose('cancel')}
                      onError={this.onDeliveryFormError}
                      ref={(e) => {
                        this.deliveryFormRef = e;
                      }}
                      tariffId={this.selectedTariff.id}
                    />
                  )
                }
                hideForm={deliveryActiveTab === 'esim'}
                invalidFTTBData={invalidFTTBData}
                warningLink={warning.link}
                warningLinkText={warning.linkText}
                warningText={warning.text}
              />
            )}
            {step === 'pending' && <Preloader />}
          </PlainContainer>
        )}
      </PopupStateless>
    );
  }
}

FancyNumberOrderPopup.defaultProps = {
  orderPopupTexts: {},
  successPopupTexts: {},
  failPopupTexts: {},
  toPaymentButtonText: 'К оплате',
};

FancyNumberOrderPopup.propTypes = {
  number: PropTypes.object,
  orderPopupTexts: PropTypes.object,
  successPopupTexts: PropTypes.object,
  failPopupTexts: PropTypes.object,
  onOrderFinish: PropTypes.func,
  filterName: PropTypes.string,
  orderplace: PropTypes.string,
  checkRegionUrl: PropTypes.string,
  place: PropTypes.string,
  tariffsInfo: PropTypes.object,
  emailSubscriptionTexts: PropTypes.object,
  currentRegion: PropTypes.object.isRequired,
  deliveryActiveTab: PropTypes.string,
  isSelectedEmployeeDelivery: PropTypes.bool,
  isSelectedFastDelivery: PropTypes.bool,
  showOrderPaymentForm: PropTypes.bool,
  showOrderPaymentResultPopup: PropTypes.bool,
  toPaymentButtonText: PropTypes.string,
  openOrderPaymentForm: PropTypes.func,
  toggleOrderPaymentForm: PropTypes.func,
  orderPaymentFormContent: PropTypes.object,
  paymentAvailable: PropTypes.bool,
  deliveryForm: PropTypes.object,
};

const ConnectedFancyNumber = connect(
  (state) => ({
    deliveryActiveTab: pathOr(null, ['deliveryForm', 'deliveryData', 'activeTab'], state.external),
    paymentAvailable: pathOr(
      true,
      ['deliveryForm', 'baseDeliveryDaysData', 'paymentAvailable'],
      state.external,
    ),
    isSelectedEmployeeDelivery: pathOr(
      false,
      ['deliveryForm', 'inputValues', 'isSelectedEmployeeDelivery'],
      state.external,
    ),
    isSelectedFastDelivery: pathOr(
      false,
      ['deliveryForm', 'inputValues', 'isSelectedFastDelivery'],
      state.external,
    ),
    showOrderPaymentForm: pathOr(false, ['orderPaymentForm', 'show'], state.external),
    showOrderPaymentResultPopup: pathOr(
      false,
      ['orderPaymentForm', 'showResultPopup'],
      state.external,
    ),
    orderPaymentFormContent: pathOr(null, ['orderPaymentForm', 'texts'], state.external),
  }),
  {
    openOrderPaymentForm,
    toggleOrderPaymentForm,
  },
)(FancyNumberOrderPopup);

export default ConnectedFancyNumber;
