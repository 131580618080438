/* eslint-disable object-curly-spacing */

import { merge, mergeWith, path, propEq } from 'ramda';
import { getSubstateFromGetState } from '@beef/legacy-beeline-store/utils';
import axios from 'axios';

import { templateParser, unformatPhone } from 'utils/format-string';
import store from 'store';
import { pushTariffSharingConnectEvent } from 'utils/ga';

import { toggleService } from './servicesExtension';

export {
  connectService,
  initServiceConnectionPopup,
  resetPopupResult as resetServicePopupResult,
  showServiceConnectionPopup,
} from './serviceConnect';

export const SHARING_SERVICE_NAME = 'sharedEverythingBundle';

export const sharingData = store.createAction({
  path: ['tariff', 'data', 'sharing', 'info'],
  defaultState: {},
  dispatches: ['set'],
  name: 'all_tariff_sharing',
});

export const textContent = store.createAction({
  path: ['tariff', 'data', 'sharing', 'texts'],
  defaultState: {},
  dispatches: ['set', 'update'],
  name: 'all_tariff_texts',
});

export const serviceSettingsPopup = store.createAction({
  path: ['tariff', 'data', 'sharing', 'serviceSettingsPopup'],
  defaultState: { opened: false },
  dispatches: ['update'],
  name: 'all_tariff_info_popup',
});

export const addNumberPopup = store.createAction({
  path: ['tariff', 'data', 'sharing', 'addNumberPopup'],
  defaultState: { opened: false, profileCtn: null },
  dispatches: ['update'],
  name: 'all_tariff_addNumber_popup',
});

export const upsalePopupContent = store.createAction({
  path: ['tariff', 'data', 'sharing', 'upsalePopupContent'],
  defaultState: {},
  dispatches: ['update'],
  name: 'fetch_upsale_popup_content',
});

export const upsalePopup = store.createAction({
  path: ['tariff', 'data', 'sharing', 'upsalePopup'],
  defaultState: { opened: false },
  dispatches: ['update'],
  name: 'all_tariff_upsale_popup',
});

export const isUpsalePopupIntoTariffCard = store.createAction({
  path: ['tariff', 'data', 'sharing', 'isUpsalePopupIntoTariffCard'],
  defaultState: false,
  dispatches: ['update'],
  name: 'all_tariff_upsale_popup_into_tariff_card',
});

export const invitationPopup = store.createAction({
  path: ['tariff', 'data', 'sharing', 'invitationPopup'],
  defaultState: { opened: false },
  dispatches: ['update'],
  name: 'all_tariff_invitation_popup',
});

export const settingsPopup = store.createAction({
  path: ['tariff', 'data', 'sharing', 'settingsPopup'],
  defaultState: { opened: false },
  dispatches: ['update'],
  name: 'all_tariff_settings_popup',
});

export const switchNumberPopup = store.createAction({
  path: ['tariff', 'data', 'sharing', 'switchNumberPopup'],
  defaultState: { opened: false },
  dispatches: ['update'],
  name: 'all_tariff_switchNumberPopup',
});

export const disconnectWrarningPopup = store.createAction({
  path: ['tariff', 'data', 'sharing', 'disconnectWarningPopup'],
  defaultState: { opened: false },
  dispatches: ['update'],
  name: 'all_tariff_disconnect_warning',
});

export const disconnectPopup = store.createAction({
  path: ['tariff', 'data', 'sharing', 'disconnectPopup'],
  defaultState: { opened: false },
  dispatches: ['update'],
  name: 'all_tariff_disconnect_popup',
});

export const disconnectConfirmPopup = store.createAction({
  path: ['tariff', 'data', 'sharing', 'disconnectConfirmPopup'],
  defaultState: { opened: false, number: {} },
  dispatches: ['update'],
  name: 'all_tariff_disconnect_confirm_popup',
});

export const disconnectSuccessPopup = store.createAction({
  path: ['tariff', 'data', 'sharing', 'disconnectSuccessPopup'],
  defaultState: { opened: false, type: '' },
  dispatches: ['update'],
  name: 'all_tariff_disconnect_success_popup',
});

export const connectedNumbers = store.createAction({
  path: ['tariff', 'data', 'sharing', 'connectedNumbers'],
  defaultState: [],
  dispatches: ['set', 'update'],
  name: 'all_tariff_connected_numbers',
});

export const serviceStatus = store.createAction({
  path: ['tariff', 'data', 'sharing', 'status'],
  defaultState: {
    authenticated: false,
    connected: false,
    servicesShared: false,
  },
  dispatches: ['update'],
  name: 'all_tariff_status',
});

export const links = store.createAction({
  path: ['tariff', 'data', 'sharing', 'links'],
  defaultState: {},
  dispatches: ['update'],
  name: 'all_tariff_links',
});

export const loadingStatus = store.createAction({
  path: ['tariff', 'data', 'sharing', 'loadingStatus'],
  defaultState: {},
  dispatches: ['update'],
  name: 'all_tariff_loading_status',
});

export const errors = store.createAction({
  path: ['tariff', 'data', 'sharing', 'errors'],
  defaultState: { type: null },
  dispatches: ['set', 'reset', 'update'],
  name: 'all_tariff_errors',
});

export const changedNumber = store.createAction({
  path: ['tariff', 'data', 'sharing', 'changedNumber'],
  defaultState: {},
  dispatches: ['update'],
  name: 'all_tariff_changed_number',
});

export const disconnectNumberWarning = store.createAction({
  path: ['tariff', 'data', 'sharing', 'disconnectNumberWarningPopup'],
  defaultState: { opened: false },
  dispatches: ['update'],
  name: 'all_tariff_disconnect_number_warning_popup',
});

export const authPopup = store.createAction({
  path: ['tariff', 'data', 'sharing', 'authPopup'],
  defaultState: { confirmAuthOpened: false, opened: false },
  dispatches: ['update'],
  name: 'all_tariff_auth_popup',
});

export const closeAllPopups = () => (dispatch) => {
  dispatch(invitationPopup.update({ opened: false }));
  dispatch(settingsPopup.update({ opened: false }));
  dispatch(serviceSettingsPopup.update({ opened: false }));
  dispatch(disconnectConfirmPopup.update({ opened: false }));
  dispatch(disconnectWrarningPopup.update({ opened: false }));
  dispatch(disconnectPopup.update({ opened: false }));
  dispatch(addNumberPopup.update({ opened: false }));
  dispatch(switchNumberPopup.update({ opened: false }));
  dispatch(disconnectSuccessPopup.update({ opened: false }));
  dispatch(errors.update({ type: null }));
};

const getErrorData = (type, data = {}, dispatch, getState) => {
  let errorData = { ...data };
  if (type === 'remove-any') {
    const texts = getSubstateFromGetState(getState, textContent);
    const number = getSubstateFromGetState(getState, changedNumber);

    errorData = {
      title: texts.disconnectConfirmTitleSent,
      description: null,
      errorContent: templateParser(texts.disconnectConfirmErrorText, { number: number.number }),
      buttonText: texts.tryAgain,
      onButtonClick: () => dispatch(removeNumber(number.number)),
    };
  }
  return { type, ...errorData };
};

const showErrorPopup = (type, data) => (dispatch, getState) => {
  dispatch(closeAllPopups());
  dispatch(errors.update(getErrorData(type, data, dispatch, getState)));
};

export const showFamilyExpansionError = () => (dispatch, getState) => {
  dispatch(
    showErrorPopup(
      'familyExpansion',
      path(['external', 'tariff', 'data', 'sharing', 'texts', 'upsaleError'], getState()),
    ),
  );
};

// key is a key of endpoint in links
export const serverRequest = (options) /* key is required */ => (dispatch, getState) => {
  const loadingState = getSubstateFromGetState(getState, loadingStatus);
  if (loadingState[options.key]) {
    return Promise.reject('wait before fetch ends');
  }

  if (!options.key) {
    const message = `no key in options for server request ${JSON.stringify(options)}`;
    dispatch(
      errors.update({
        genericError: message,
      }),
    );
    return Promise.reject(message);
  }

  const additions = {
    url: getSubstateFromGetState(getState, links)[options.key],
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  };
  const data = mergeWith(merge, additions, options);
  dispatch(
    loadingStatus.update({
      [options.key]: true,
    }),
  );

  return axios
    .request(data)
    .then((resp) => {
      dispatch(
        loadingStatus.update({
          [options.key]: false,
        }),
      );

      const respData = resp.data;
      if (
        respData.isSucceeded === false ||
        (respData.result && respData.result.isSucceeded === false)
      ) {
        return Promise.reject(respData);
      }

      return resp;
    })
    .catch((error) => {
      dispatch(
        loadingStatus.update({
          [options.key]: false,
        }),
      );

      if (error.error.message === 'checks.comverse.status') {
        dispatch(closeAllPopups());
        dispatch(disconnectNumberWarning.update({ opened: true }));
      } else {
        dispatch(showErrorPopup(options.key, error?.errorPopup || {}));
      }

      return Promise.reject(error);
    });
};

export const triggerShareService = () => (dispatch, getState) => {
  const state = getState();
  const isAuthenticated = path(['external', 'tariff', 'data', 'isAuthenticated'], state);
  const info = getSubstateFromGetState(getState, sharingData);

  if (isAuthenticated && info.count > 0) {
    const numbers = getSubstateFromGetState(getState, connectedNumbers) || [];
    const isConnected =
      numbers.findIndex((item) => item.status === 'connected' || item.status === 'invited') !== -1;

    if (isConnected) {
      if (numbers.length === 1) {
        dispatch(changedNumber.update(numbers[0]));
      }
      dispatch(disconnectPopup.update({ opened: true }));
    } else if (numbers.length !== 0) {
      dispatch(settingsPopup.update({ opened: true }));
    } else {
      dispatch(addNumberPopup.update({ opened: true }));
    }
    dispatch(toggleService(SHARING_SERVICE_NAME, isConnected ? 'CONNECTED' : 'NOT_CONNECTED'));
  } else {
    dispatch(serviceSettingsPopup.update({ opened: true }));
  }
};

export const triggerPopupShareService = (number, status) => (dispatch, getState) => {
  dispatch(closeAllPopups());
  return triggerShareService(number, status)(dispatch, getState);
};

export const sendInvitation = (number) => (dispatch, getState) => {
  const currentNumbers = getSubstateFromGetState(getState, connectedNumbers) || [];
  const { soc, pageTitle, marketCode } = path(['external', 'tariff', 'data'], store.getState());

  if (currentNumbers.some(propEq('number', number))) {
    dispatch(
      addNumberPopup.update({
        info: getSubstateFromGetState(getState, textContent).invitationAlreadyInvited,
      }),
    );

    return Promise.resolve();
  }

  return dispatch(
    serverRequest({
      key: 'invitation',
      method: 'post',
      data: { ctn: unformatPhone(number) },
    }),
  ).then((data) => {
    dispatch(addNumberPopup.update({ opened: false }));
    dispatch(invitationPopup.update({ opened: true }));
    dispatch(connectedNumbers.set(currentNumbers.concat([{ number, status: 'invited' }])));
    dispatch(toggleService(SHARING_SERVICE_NAME, true));
    pushTariffSharingConnectEvent({ soc, tariffName: pageTitle, marketCode });

    return Promise.resolve(data);
  });
};

export const addNumberFromAnotherPopup = () => async (dispatch, getState) => {
  const sharing = path(['external', 'tariff', 'data', 'sharing'], getState());
  const isFull = sharing.info.invites && sharing.info.invites.length >= sharing.info.count;

  if (!isFull) {
    dispatch(closeAllPopups());
    dispatch(addNumberPopup.update({ opened: true }));
    return;
  }

  try {
    const { data } = await axios.get(sharing.links.upsaleUrl);

    if (!data) throw new Error('no upsale');

    dispatch(closeAllPopups());
    dispatch(upsalePopupContent.update(data));
    dispatch(upsalePopup.update({ opened: true }));
    dispatch(isUpsalePopupIntoTariffCard.update(true));
  } catch (er) {
    dispatch(showFamilyExpansionError());
  }
};

export const switchNumber = (number, status) => (dispatch) => {
  if (number && status) {
    dispatch(changedNumber.update({ number, status }));
  }

  dispatch(closeAllPopups());
  dispatch(switchNumberPopup.update({ opened: true }));
};

export const showDisconnectSuccessPopup = (type) => (dispatch) => {
  dispatch(closeAllPopups());
  dispatch(disconnectSuccessPopup.update({ opened: true, type }));
};

const showLastNumberDisconnectWarning = () => (dispatch) => {
  dispatch(closeAllPopups());
  dispatch(disconnectWrarningPopup.update({ opened: true }));
};

export const removeNumberRequest = (dispatch, getState, number, force = false) => {
  const numbers = getSubstateFromGetState(getState, connectedNumbers) || [];
  const data = {
    ctn: unformatPhone(number),
    force: true,
  };

  return new Promise((resolve, reject) => {
    dispatch(
      serverRequest({
        key: 'remove-any',
        method: 'post',
        data,
      }),
    ).then((resp) => {
      const respData = resp.data;
      if (respData && respData.error && respData.error.message === 'LastCtnRemovalWarning') {
        reject();
        return;
      }

      const numberType = respData?.result?.type || 'Delete';
      const confirmedNumbers = numbers.filter((item) => item.status === 'connected');
      const isLastConfirmed =
        confirmedNumbers.length === 1 && confirmedNumbers[0].number === number;

      const popupType = isLastConfirmed || (numberType === 'Delete' && force) ? 'all' : numberType;
      const filtered = numbers.filter(
        (item) => !(item.number === number || (force && item.status === 'connected')),
      );

      dispatch(connectedNumbers.set(filtered));
      dispatch(
        toggleService(
          SHARING_SERVICE_NAME,
          filtered.some((num) => num.status === 'connected'),
        ),
      );
      resolve(popupType);
    });
  });
};

export const removeNumber =
  (number, force = false) =>
  (dispatch, getState) => {
    removeNumberRequest(dispatch, getState, number, force)
      .then((popupType) => dispatch(showDisconnectSuccessPopup(popupType)))
      .catch(() => dispatch(showLastNumberDisconnectWarning()));
  };

export const disconnectAll = () => (dispatch) =>
  dispatch(
    serverRequest({
      key: 'disconnect',
      method: 'post',
    }),
  ).then(() => {
    dispatch(connectedNumbers.set([]));
    dispatch(closeAllPopups());
    dispatch(showDisconnectSuccessPopup('all'));
    dispatch(toggleService(SHARING_SERVICE_NAME, false));
  });

export const changeNumbers = () => (dispatch) => {
  dispatch(closeAllPopups());
  dispatch(settingsPopup.update({ opened: true }));
};

export const disconnectNumber = (number, status) => (dispatch) => {
  dispatch(closeAllPopups());
  dispatch(changedNumber.update({ number, status }));
  dispatch(disconnectConfirmPopup.update({ opened: true }));
};

export const switchNumberSubmit = (oldNumber, newNumber) => (dispatch, getState) =>
  dispatch(
    serverRequest({
      key: 'replaceSubscriber',
      method: 'post',
      data: {
        ctnOld: unformatPhone(oldNumber),
        ctnNew: unformatPhone(newNumber),
      },
    }),
  ).then((resp) => {
    if (resp.data && resp.data.isSucceeded) {
      const numbers = getSubstateFromGetState(getState, connectedNumbers) || [];
      const index = numbers.findIndex((item) => item.number === oldNumber);
      numbers[index] = {
        status: 'invited',
        number: newNumber,
      };

      dispatch(switchNumberPopup.update({ opened: false }));
      dispatch(invitationPopup.update({ opened: true }));
      dispatch(connectedNumbers.set(numbers));
      dispatch(
        toggleService(
          SHARING_SERVICE_NAME,
          numbers.some((number) => number.status === 'connected'),
        ),
      );
    }
  });
