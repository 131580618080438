import React from 'react';

import { PageHeader } from '../components/commonComponents/PageHeader';
import { Separation } from '../components/commonComponents/Separation';
import { BillContainer } from '../components/commonComponents/BillContainer';
import { TariffContainer } from '../components/commonComponents/TariffContainer';
import { SpecialistVisit } from '../components/constructorPage/SpecialistVisitContainer';
import { useSelectTariffTitle } from '../components/constructorPage/TariffTitle/selectors/useSelectTariffTitle';

/** Страница настройки вызова специалиста */
export const SettingsSpecialistVisitPage = () => {
  const titleContentProps = useSelectTariffTitle();
  return (
    <>
      <PageHeader {...titleContentProps} />
      <TariffContainer isShortPadding>
        <Separation>
          <SpecialistVisit />
        </Separation>
        <Separation sideType="right">
          {/* Чек тарифа и промо баннер */}
          <BillContainer />
        </Separation>
      </TariffContainer>
    </>
  );
};
