import React, { FC } from 'react';
import { PageFixer } from '@beef/smart-kit';
import { ErrorBoundary } from '@beef/react';
import { withReduxStore } from '@beef/redux';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import DevelopmentClearCache from './components/commonComponents/DevelopmentClearCacheButton';
import { initializeStore } from './store';
import { useCheckUserType } from './hooks/useCheckUserType';
import { useAutoScrollTop } from './hooks/useAutoScrollTop';
import { onReloadWithCrash } from './utils/onReloadWithCrash';
import { ConstructorRouter } from './router';
import { dataConverter } from './dataConvertor';
import { usePersistor } from './store/persistor';
import { CommonErrorBanner } from './components/commonComponents/CommonErrorBanner';
import { DESKTOP_PADDING, MOBILE_PADDING } from './constants';
import { useSetPersistedInfo } from './hooks/useSetPersistedInfo';
import { useCheckIsMyTariff } from './hooks/useCheckIsMyTariff';

/** Основная задача: BCP-10158
 * Главный компонент конструктора 2023.
 * Постепенно будем выносить логику в redux и избавляться от партянки ниже */
const ConstructorTariff2023Layout: FC = () => {
  useCheckUserType();
  usePersistor();
  useAutoScrollTop();
  useCheckIsMyTariff();
  const isMobile = useIsMobileDimension();
  const isCompleteOfCheckCache = useSetPersistedInfo();
  const innerVerticalSpace = isMobile ? MOBILE_PADDING : DESKTOP_PADDING;

  /* Для предотвращения лишних запросов, дожидаемся установки данных из sessionStorage (constructor-state) */
  if (!isCompleteOfCheckCache) return null;

  return (
    <PageFixer size="m" {...{ innerVerticalSpace }}>
      {/* @ts-expect-error Неверная типизация ErrorBoundary */}
      <ErrorBoundary fallback={<CommonErrorBanner onClick={onReloadWithCrash} />}>
        <ConstructorRouter />
      </ErrorBoundary>
      {/* Кнопка быстрой очистки кэша. РЕНДЕРИТСЯ ТОЛЬКО В DEV */}
      <DevelopmentClearCache />
    </PageFixer>
  );
};

// TODO: не забыть типизировать data!
export const ConstructorTariff2023 = withReduxStore(initializeStore, {
  passThroughProps: ({ data, layoutType }: any) => ({ data, layoutType }),
  mapPropsToState: dataConverter,
})(ConstructorTariff2023Layout);
