import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { connect } from 'react-redux';

import { PROMOTION_PLACES } from 'pages/FancyNumber2022/constants';

import { PromotionTagWrapper } from '../../PromotionTagWrapper';
import { useRandomNumberShowEvent } from '../../../hooks/useRandomNumberShowEvent';
import { StyledNumber } from '../../StyledNumber/index';
import { getCurrentRandomNumber } from '../../../store/selectors/numbers';
import { getPromotionContentByPlace, getRandomContent } from '../../../store/selectors/content';
import { getIsChangeNumber } from '../../../store/selectors/change';
import { NumberShape, PromotionShape } from '../../../shapes';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

const NumberBoxLayout = ({ number, image, promotion }) => {
  const containerRef = useRandomNumberShowEvent(number);
  return (
    <div className={cx('number-box')} ref={containerRef}>
      <PromotionTagWrapper
        {...(promotion.category === number.category && { promotionTag: promotion.tag })}
        tagClassName={cx('number-box_tag')}
        tagSize="m"
      >
        <img alt="" aria-hidden="true" className={cx('number-box_image')} src={image} />
      </PromotionTagWrapper>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div className={cx('number-box_value')} tabIndex="0">
        <StyledNumber color="white" highlightColor={null} number={number} size="size3-m" />
      </div>
    </div>
  );
};

NumberBoxLayout.propTypes = {
  number: PropTypes.shape(NumberShape),
  image: PropTypes.string,
  promotion: PropTypes.shape(PromotionShape),
};

const mapStateToProps = (state) => ({
  number: getCurrentRandomNumber(state),
  image: getRandomContent(state).image,
  promotion:
    !getIsChangeNumber(state) &&
    getPromotionContentByPlace(PROMOTION_PLACES.randomPhoneNumber, state),
});

export const NumberBox = connect(mapStateToProps)(NumberBoxLayout);
