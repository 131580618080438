import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { TPicture } from './types';
import styles from '../../styles.pcss';

const cx = classNames.bind(styles);

/** Компонент основной картинки для плитки тарифа */
export const Picture: FC<TPicture> = ({ imageSrc, isBeeIndent, withoutIndent }) =>
  imageSrc ?
    <img
      alt=""
      className={cx(
        'wrapper__image',
        { 'wrapper__image--bee': isBeeIndent },
        { 'wrapper__image--without': withoutIndent },
      )}
      loading="lazy"
      src={imageSrc}
    />
  : null;
