import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Bubble, Checkbox, Emoji, Textarea } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import styles from './TextInput.pcss';

const cx = classNames.bind(styles);

const alphabet = { 'а':'a', 'б':'b', 'в':'v', 'г':'g', 'д':'d', 'е':'e', 'ё':'yo', 'ж':'zh', 'з':'z', 'и':'i', 'й':'i', // eslint-disable-line
  'к':'k', 'л':'l', 'м':'m', 'н':'n', 'о':'o', 'п':'p', 'р':'r', 'с':'s', 'т':'t', 'у':'u', 'ф':'f', 'х':'h', 'ц':'ts', // eslint-disable-line
  'ч':'ch', 'ш':'sh', 'щ':'sch', 'ъ':"'", 'ы':'i', 'ь':"'", 'э':'e', 'ю':'yu', 'я':'ya', 'Ё':'Yo', '№':'#', '«': '"', '»': '"' }; // eslint-disable-line

const getChar = (char) => {
  const charCode = char.charCodeAt();

  if (charCode > 1039 && charCode < 1072) {
    const transChar = alphabet[char.toLowerCase()];
    return transChar[0].toUpperCase() + transChar.slice(1);
  }

  return alphabet[char] || char;
};

export function hasBadSymbols(value) {
  return (
    value
      .toLowerCase()
      .split('')
      .findIndex((char) => char.charCodeAt() > 126) !== -1
  );
}

export default class TextInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      transText: this.translateText(props.text),
      bubbleShown: false,
      remainChars: props.maxLengths[1],
      focused: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.text === '' && this.props.text !== prevProps.text) {
      this.setState({
        transText: '',
        remainChars: prevProps.maxLengths[1],
      });
    }
  }

  setFocus = () => this.textarea.inputElement.focus();

  handleCheckboxChange = () => {
    const translate = !this.props.translate;

    if (!this.props.translate || (translate && this.state.focused)) {
      const transText = this.translateText(this.state.transText);
      this.props.onChange('text', transText);
      this.setState({
        transText,
        bubbleShown: true,
        remainChars: this.props.maxLengths[hasBadSymbols(transText) ? 0 : 1] - transText.length,
      });
    }

    this.props.onChange('translate', translate);
  };

  handleTextChange = (value) => {
    const { translate, maxLengths } = this.props;
    let transText = value.slice(0, 1000 + this.props.maxLengths[0]);

    if (translate && ' ,.!?\n'.indexOf(value[value.length - 1]) !== -1) {
      transText = this.translateText(transText);
    }

    const remainChars = maxLengths[hasBadSymbols(transText) ? 0 : 1] - transText.length;

    if (!this.state.bubbleShown) {
      const prevRemainChars = this.state.remainChars;

      if (prevRemainChars < 0 && remainChars >= 0) {
        this.setState({ bubbleShown: true });
      }
    }

    this.props.onChange('text', transText);
    this.setState({ transText, remainChars });
  };

  handleTextAreaFocus = () => this.setState({ focused: true });

  handleTextAreaBlur = () => {
    setTimeout(() => {
      const value = this.state.transText;
      const transText = this.props.translate ? this.translateText(value) : value;
      this.props.onChange('text', transText);
      this.setState({
        transText,
        remainChars: this.props.maxLengths[hasBadSymbols(transText) ? 0 : 1] - transText.length,
        focused: false,
      });
    }, 200);
  };

  handleCrossClick = () => this.setState({ bubbleShown: true });

  translateText = (value) => value.split('').map(getChar).join('');

  render() {
    const { translate } = this.props;
    const { transText, bubbleShown, remainChars } = this.state;

    return (
      <div className={cx('input', { warning: remainChars < 0 })}>
        <div className={cx('labels')}>
          <div className={cx('label')}>Текст SMS</div>
          <Bubble
            className={cx('bubbleWrapper', {
              shown: !bubbleShown && !translate && remainChars < 0 && hasBadSymbols(transText),
            })}
            tail="bottomLeft"
          >
            <div className={cx('bubble')}>
              <div className={cx('bubbleCross')} onClick={this.handleCrossClick}>
                <Icon name="cross" />
              </div>
              <Emoji className={cx('bubbleImage')} name="pointingHand" />
              <div className={cx('bubbleText')}>Включите, чтобы увеличить длину сообщения</div>
            </div>
          </Bubble>
          <Checkbox checked={translate} handleChange={this.handleCheckboxChange} name="translate">
            Преобразовать в транслит
          </Checkbox>
        </div>
        <Textarea
          onBlur={this.handleTextAreaBlur}
          onChange={this.handleTextChange}
          onFocus={this.handleTextAreaFocus}
          ref={(e) => (this.textarea = e)}
          value={transText}
        />
        <div className={cx('remain', { hidden: remainChars > 9, warning: remainChars < 0 })}>
          {remainChars}
        </div>
      </div>
    );
  }
}

TextInput.propTypes = {
  translate: PropTypes.bool,
  text: PropTypes.string,
  maxLengths: PropTypes.array,
  onChange: PropTypes.func,
};
