import React, { FC, ReactNode } from 'react';
import { Modal } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IModalForm {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalForm: FC<IModalForm> = ({ isOpen, onClose, children }) => (
  <Modal className={cx('modal')} isOpen={isOpen} onClose={onClose} portalClassName={cx('portal')}>
    {children}
  </Modal>
);
