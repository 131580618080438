import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Preloader, Status } from '@beef/legacy-ui-kit';
import { Button } from '@beef/ui-kit';
import { formatOrderData, getCartRedirect, handleSuccessRequest } from '@beef/layout-kit/utils';
import classNames from 'classnames/bind';
import { setCartData } from '@carnica/smart-kit';

import Ctx from 'pages-components/Ctx';
import Icon from 'pages-components/Icon';
import ErrorPopup from 'pages-components/ErrorPopup/ErrorPopup';
import { numberToHtml } from 'utils/fancyNumberBeautifier';
import { isNumberBought } from 'utils/cartHelpers';
import { headerStore2024 } from 'store';

import { incrementLimit } from '../../actions';
import styles from './index.pcss';

const cx = classNames.bind(styles);

const NumbersTable = ({
  numbers,
  limit,
  increment,
  fetchingError,
  isMobile,
  boughtNumbers,
  cartApi,
  handleSetCartData,
}) => {
  const [errorPopupOpened, setErrorPopupOpened] = useState(false);
  const [pending, setPending] = useState(false);

  const isBought = (number) => isNumberBought(boughtNumbers, number.value);

  const numberClick = (number) => {
    if (isBought(number) || pending) {
      return;
    }
    const request = formatOrderData({
      ctn: number.value,
      simOrderPlace: 'Bronze',
    });
    setPending(true);

    cartApi
      .post(`/${request.apiEndpoint}`, request.body)
      .then(({ data }) => {
        handleSuccessRequest({
          cartId: data.id,
          items: data.items,
          redirectToCart: getCartRedirect(),
        });
        handleSetCartData(data);
      })
      .catch(() => {
        setErrorPopupOpened(true);
      })
      .finally(() => {
        setPending(false);
      });
  };

  const renderNumber = (number, index) => (
    <div
      className={cx('number', {
        'number--bought': isBought(number),
      })}
      key={index}
    >
      <Link
        className={cx({
          disabled: pending,
        })}
        onClick={() => numberClick(number)}
      >
        <span dangerouslySetInnerHTML={{ __html: numberToHtml({ number }) }} />
      </Link>
      {!isBought(number) && (
        <div className={cx('button-wrapper')}>
          <Button onClick={() => numberClick(number)} variant="plain">
            <Icon className={cx('button-wrapper__trolley')} name="trolley" />
          </Button>
        </div>
      )}
    </div>
  );

  if (!numbers.length) {
    return (
      <div className={cx('empty')}>
        {fetchingError ?
          <Status className={['error', 'wide']}>{Ctx.fancyNumber.errorText}</Status>
        : <Preloader className={cx('preloader')} />}
      </div>
    );
  }

  return (
    <div className={cx('wrapper')}>
      <div className={cx('numbers')}>{numbers.slice(0, limit).map(renderNumber)}</div>
      <div className={cx('button')}>
        {!(limit >= numbers.length) && (
          <Button
            disabled={limit >= numbers.length}
            fullWidth={isMobile}
            onClick={increment}
            size="s"
            variant="secondary"
          >
            Показать больше
          </Button>
        )}
      </div>
      {errorPopupOpened && (
        <ErrorPopup
          buttonText="Выбрать другой номер"
          description="Кто-то уже купил этот номер.<br>
            Пожалуйста, выберите другой и оформите заказ"
          onClose={() => setErrorPopupOpened(false)}
          opened={errorPopupOpened}
          title="Что-то пошло не так..."
        />
      )}
    </div>
  );
};

NumbersTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  numbers: PropTypes.array.isRequired,
  limit: PropTypes.number.isRequired,
  increment: PropTypes.func,
  fetchingError: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  boughtNumbers: PropTypes.arrayOf(PropTypes.string),
  cartApi: PropTypes.func,
  handleSetCartData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  numbers: state.external.fancyLanding?.numbers || [],
  limit: state.external.fancyLanding?.limit || 6,
  fetchingError: state.external.fancyLanding?.fetchingError || false,
  isMobile: state.external.deviceType === 'mobile',
  boughtNumbers: state.external?.cartData?.currentCart?.numbers ?? [],
  cartApi: state.external?.cartData?.cartApi,
});

const mapDispatchToProps = (dispatch) => ({
  increment: () => dispatch(incrementLimit()),
  handleSetCartData: (data) => headerStore2024().dispatch(setCartData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NumbersTable);
