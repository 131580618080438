import React from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { pathOr } from 'ramda';

import store from 'store';

// Components
import PopupTaxInfo from 'widgets/PopupTaxInfo/PopupTaxInfo';
import SmsReminder from 'pages-components/SmsReminder';

/**
 * Модальные окна страницы Тарифа;
 */
const ModalsGroup = ({ tariffData: { subscriptionFree, smsRemind, taxNote, taxUrl } }) => (
  <>
    {smsRemind && <SmsReminder postUrl={smsRemind.postUrl} texts={smsRemind.texts} type="button" />}

    {taxNote && <PopupTaxInfo price={subscriptionFree} taxNote={taxNote} taxUrl={taxUrl} />}
  </>
);

ModalsGroup.propTypes = {
  tariffData: PropTypes.shape({
    smsRemind: PropTypes.shape({
      postUrl: PropTypes.string,
      texts: PropTypes.object,
    }),
    subscriptionFree: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    taxNote: PropTypes.string,
    taxUrl: PropTypes.string,
  }),
};

const mapStateToProps = ({ external }) => ({
  tariffData: pathOr({}, ['tariff', 'data'], external),
});

const ConnectedComponent = connect(mapStateToProps, null)(ModalsGroup);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
