import React from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { pathOr } from 'ramda';
import classNames from 'classnames/bind';

import store from 'store';
import { isMobileDevice } from 'utils/device';
import Banner from 'pages-components/BannerDeprecated/InfoBanner';

import styles from './index.pcss';
import GetSimCard from './components/GetSimCard';
import Text from './components/Text';
import Tariff from './components/Tariff';
import FAQ from './components/FAQ';
import Descriptions from './components/Descriptions';

const cx = classNames.bind(styles);

const Row = ({ maxWidth, grey, children }) => (
  <div className={cx('rowWrapper', { grey })}>
    <div className={cx('row')} style={{ maxWidth }}>
      {children}
    </div>
  </div>
);

Row.propTypes = {
  maxWidth: PropTypes.string,
  grey: PropTypes.bool,
};

class English extends React.Component {
  state = {
    mounted: false,
  };

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    const { currentRegion } = this.props;
    const { blocks } = this.props.data;
    // hydrate discrepancy fix
    const isMobile = this.state.mounted ? this.props.mobile : true;

    return (
      <div className={cx('wrapper')}>
        {blocks &&
          blocks.map(({ type, ...data }, key) => {
            if (type === 'banner') {
              return <Banner data={data} key={key} mobile={isMobile} />;
            }
            if (type === 'offices') {
              return (
                <Row key={key} maxWidth="1000px">
                  <GetSimCard currentRegion={currentRegion} data={data} />
                </Row>
              );
            }
            if (type === 'text') {
              return (
                <Row key={key}>
                  <Text data={data.text} />
                </Row>
              );
            }
            if (type === 'tariff') {
              return (
                <Row key={key}>
                  <Tariff {...data} />
                </Row>
              );
            }
            if (type === 'faq') {
              return <FAQ key={key} {...data} mobile={isMobile} />;
            }
            if (type === 'descriptions') {
              return (
                <Row grey key={key}>
                  <Descriptions {...data} />
                </Row>
              );
            }

            return null;
          })}
      </div>
    );
  }
}

// English.defaultProps = {
//   data: stubData,
// };

English.propTypes = {
  currentRegion: PropTypes.string,
  mobile: PropTypes.bool,
  data: PropTypes.shape({
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        // And any object fields
        // Your block, Your props
      }),
    ),
  }),
};

const EnglishConnected = connect((state) => ({
  mobile: isMobileDevice(state),
  currentRegion: pathOr('moscow', ['external', 'regions', 'currentRegion', 'code'], state),
}))(English);

export default (props) => (
  <Provider store={store}>
    <EnglishConnected {...props} />
  </Provider>
);
