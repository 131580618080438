import React from 'react';

// Components
import { Preloader } from '@beef/legacy-ui-kit';

// Style
import classNames from 'classnames/bind';

import styles from '../styles.pcss';

const cx = classNames.bind(styles);

const PendingStep = () => (
  <div className={cx('pending')}>
    <div className={cx('preloader')}>
      <Preloader />
    </div>
    <p dangerouslySetInnerHTML={{ __html: 'Одну минуточку...' }} />
  </div>
);

export default PendingStep;
