import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import type { TBurgerMenu } from './types';

const cx = classNames.bind(styles);

export const BurgerMenu: FC<TBurgerMenu> = ({ className, color }) => {
  const lines = new Array(3).fill(null).map((_, index) => (
    <li
      className={cx(
        'line',
        {
          [`line--${color}`]: Boolean(color),
        },
        className,
      )}
      key={index}
    />
  ));

  return (
    <div className={cx('wrapper')}>
      <ul className={cx('burger-menu')}>{lines}</ul>
    </div>
  );
};
