import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { ListBlock } from './components/ListBlock';
import { QRBlock } from './components/QRBlock';
import { InputBlock } from './components/InputBlock';
import { useMNPForm } from './hooks/useMNPForm';

const cx = classNames.bind(styles);

/**
 * Форма МНП
 */
export const MNPForm: FC = () => {
  const { heightReferencePoint, isDesktop, lowerLimitMovingArea } = useMNPForm();

  return (
    <div
      className={cx('movement-area')}
      style={
        {
          // Высота области, по которой может перемещаться форма
          '--height-for-movement-form': `${lowerLimitMovingArea}px`,
          // Значение высоты начальной точки показа формы
          '--height-reference-point': `${heightReferencePoint}px`,
        } as CSSProperties
      }
    >
      <div className={cx('sticky-container')} id="form">
        {/* Блок с инпутом */}
        <InputBlock />

        {isDesktop ?
          <>
            {/* Блок со списком условий */}
            <ListBlock key={1} />
            {/* Блок с переходом в мобильное приложение */}
            <QRBlock key={2} />
          </>
        : <>
            <QRBlock key={2} />
            <ListBlock key={1} />
          </>
        }
      </div>
    </div>
  );
};
