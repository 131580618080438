import { useState } from 'react';

import { useSelectDevicesPopups } from '../selectors/useSelectDevicesPopups';

/** Подготовка данных для модального окна Devices */
export const usePrepareModalContent = () => {
  const [isOpen, setIsOpen] = useState(false);

  /* Контент попапа (забираем название кнопки) */
  const { btnText } = useSelectDevicesPopups();

  /* Функция открытия модального окна для компонента ModalActionBar */
  const openModal = () => setIsOpen(true);

  /* Функция закрытия модального окна */
  const onClose = () => setIsOpen(false);

  /* Пропы для китового компонента Modal */
  const modalProps = { isOpen, onClose };

  /* Пропы для компонента ModalActionBar */
  const modalActionBarProps = { onClick: onClose, btnText };

  /* Общий проп для кмпонента  ModalContainer */
  const modalContainerProps = { modalProps, modalActionBarProps };

  return { openModal, modalContainerProps };
};
