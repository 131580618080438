import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { pathOr } from 'ramda';
import classNames from 'classnames/bind';

import store from 'store';

// Utils
import { onElementHeightChange } from 'utils/height-change-listener';
import { isInPage } from 'utils/isInPage';

// Styles

import { TARIFF_LAYOUTS } from 'pages/ProductCard/Tariff/tariffTypes';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class FloatingConnectWrapper extends Component {
  state = {
    showFloatingButton: true,
  };

  componentDidMount() {
    const showTariffConnectButtons = this.getShowTariffConnectButtons();

    if (showTariffConnectButtons && isInPage(document.querySelector('footer'))) {
      const { greyAreaBottomSpace } = this.props;
      this.grayArea = document.getElementById('pageDescriptions');
      this.topConnectButton = document.querySelector('#topConnectButton');
      this.bottomConnectButton = document.querySelector('#bottomConnectButton');
      this.footer = document.querySelector('footer').parentNode;

      window.addEventListener('scroll', this.scrollEventListener);
      this.scrollEventListener();
      this.removeGrayAreaHeightEventListener = onElementHeightChange(
        this.grayArea,
        this.footerViewportEventListener,
      );

      if (greyAreaBottomSpace) {
        this.grayArea.style.paddingBottom = `${greyAreaBottomSpace}px`;
      }
    }
  }

  componentWillUnmount() {
    const showTariffConnectButtons = this.getShowTariffConnectButtons();

    if (showTariffConnectButtons) {
      window.removeEventListener('scroll', this.scrollEventListener);
      this.removeGrayAreaHeightEventListener();
    }
  }

  updateShowFloatingButtonState = () => {
    this.setState((prevState) => ({
      showFloatingButton: !prevState.showFloatingButton,
    }));
  };

  scrollEventListener = () => {
    const { showFloatingButton } = this.state;
    // Верхняя граница нижней кнопки + высота кнопки;
    const { top: bottomButtonPosition, height: bottomButtonHeight } =
      this.bottomConnectButton.getBoundingClientRect();

    const isFloatingButtonShow =
      bottomButtonPosition + bottomButtonHeight < 0 || bottomButtonPosition > window.innerHeight;
    let isButtonCanShow = isFloatingButtonShow;
    if (this.topConnectButton) {
      // Нижняя граница верхней кнопки;
      const { bottom: topButtonPosition } = this.topConnectButton.getBoundingClientRect();
      isButtonCanShow = isFloatingButtonShow && topButtonPosition < 0;
    }

    if (isButtonCanShow) {
      // ConnectButton НЕ во viewport, Показываем плавающую кнопку
      if (!showFloatingButton) {
        this.updateShowFloatingButtonState();
      }
    } else if (showFloatingButton) {
      // ConnectButton ВО viewport, Скрываем плавающую кнопку
      this.updateShowFloatingButtonState();
    }

    this.footerViewportEventListener();
  };

  footerViewportEventListener = () => {
    const footerPosition = this.footer.getBoundingClientRect().top;
    const floatingButtonHeight = this.floatingButton.getBoundingClientRect().height;
    const indentFloatingButton = window.innerWidth > 767 ? 30 : 10;

    if (footerPosition < window.innerHeight) {
      // Footer ВО viewport, Фиксируем плавающую кнопку
      this.floatingButton.classList.add(cx('absoluteButton'));
      this.floatingButton.style.top = `${
        window.pageYOffset + footerPosition - floatingButtonHeight - indentFloatingButton
      }px`;
    } else if (this.floatingButton.classList.contains(cx('absoluteButton'))) {
      // Footer НЕ во viewport. Если кнопка была зафиксирована - отлепляем
      this.floatingButton.style.top = '';
      this.floatingButton.classList.remove(cx('absoluteButton'));
    }
  };

  checkAvailableForConnect = () => {
    const { isConnected, availableForConnect, isArchived } = this.props.tariffData;
    return !isConnected && availableForConnect && !isArchived;
  };

  getShowTariffConnectButtons = () => {
    const { tariffData, hideIfNotAvailableForConnect } = this.props;

    if (tariffData.hideSubscribeButton) return false;

    return !(hideIfNotAvailableForConnect && !this.checkAvailableForConnect());
  };

  render() {
    const { tariffData, className, render } = this.props;

    return this.getShowTariffConnectButtons() ?
        <div
          className={cx(
            'floatingButton',
            className,
            tariffData?.layoutType === TARIFF_LAYOUTS.young && 'youngFont',
          )}
          ref={(e) => {
            this.floatingButton = e;
          }}
          style={{ visibility: this.state.showFloatingButton ? 'visible' : 'hidden' }}
        >
          {render({
            isAvailableForConnect: this.checkAvailableForConnect(),
            tariffData,
          })}
        </div>
      : null;
  }
}

FloatingConnectWrapper.propTypes = {
  hideIfNotAvailableForConnect: PropTypes.bool,
  greyAreaBottomSpace: PropTypes.number,
  render: PropTypes.func,
  tariffData: PropTypes.shape({
    isConnected: PropTypes.bool,
    availableForConnect: PropTypes.bool,
    isArchived: PropTypes.bool,
    hideSubscribeButton: PropTypes.bool,
    connectButton: PropTypes.string,
    layoutType: PropTypes.string,
  }),
};

const mapStateToProps = ({ external }) => ({
  tariffData: pathOr({}, ['tariff', 'data'], external),
});

const ConnectedComponent = connect(mapStateToProps)(FloatingConnectWrapper);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
