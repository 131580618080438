import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const HaveQuestions = ({ text, linkText, onOpenBackCallPopup }) => (
  <div className={cx('haveQuestions')}>
    <div className={cx('haveQuestionsText')} dangerouslySetInnerHTML={{ __html: text }} />
    <Link onClick={onOpenBackCallPopup}>
      <span dangerouslySetInnerHTML={{ __html: linkText }} />
    </Link>
  </div>
);

HaveQuestions.propTypes = {
  text: PropTypes.string,
  linkText: PropTypes.string,
  onOpenBackCallPopup: PropTypes.func,
};

export default HaveQuestions;
