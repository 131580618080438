import axios from 'axios';

import { tariffData } from 'pages/ProductCard/actions/servicesExtension';
import { SET_TARIFF_CONNECTION_POPUP } from 'pages/ProductCard/actions/actionTypes';
import { toggleTariffConnectionPopup } from 'pages/ProductCard/actions/tariffConnect/toggleTariffConnectionPopup';
import formatConnectPopupUrlResponse from 'utils/formatConnectPopupUrlResponse';
import { mobTariffDetailConnectYM } from 'utils/analytics/tariffsYandexMetrica';

/**
 * используется в едином каталоге и на странице услуги
 */
const setTariffConnectPopupInitialData =
  ({ popupData, data }) =>
  (dispatch) => {
    dispatch({
      type: SET_TARIFF_CONNECTION_POPUP,
      payload: popupData,
    });
    dispatch(tariffData.update(data));
  };

const openTariffConnectPopup = () => (dispatch) => {
  dispatch(toggleTariffConnectionPopup(true, null, null, true));
};

const determineOrderModal =
  ({
    hasChangedConvergenceOptions,
    requestConvergenceParams,
    hasConnectedConvergence,
    isConnectUpConvergence,
    isNeedCallBackOptions,
    connectingConstructor,
    updateConnectedTariff,
    setOrderModalPending,
    chosenSubscriptions,
    isChangeMobile,
    isChangeOnlyMobileOptions,
    pricePlaneName,
    updateTariffUp,
    fttbServices,
    tariffUpSoc,
    chosenSocs,
    activeTab,
    socsOff,
    socsOn,
    info,
    data,
  }) =>
  (dispatch) => {
    const params = { constructorId: info?.constructorId };

    if (hasConnectedConvergence) {
      if (!isChangeOnlyMobileOptions || (isChangeMobile && hasChangedConvergenceOptions)) {
        params.fttbServices = fttbServices;
      }
      params.pricePlaneName = pricePlaneName;
      params.isChangeMobile = isChangeMobile;
      params.isConvergenceValidate = hasConnectedConvergence;
    }

    if (chosenSocs?.length) {
      params.additionalSocs = chosenSocs?.join(',');
    }
    if (activeTab) {
      params.activeTab = activeTab;
    }
    if (socsOff) {
      params.disconnectedSocs = socsOff?.join(',');
    }
    if (socsOn) {
      params.additionalSocs = socsOn?.join(',');
    }
    if (connectingConstructor) {
      params.connectingConstructor = connectingConstructor;
    }

    if (tariffUpSoc) {
      params.tariffUpSoc = tariffUpSoc;
    }

    if (chosenSubscriptions && chosenSubscriptions.length > 0) {
      params.partnersId = chosenSubscriptions.join(',');
    }

    dispatch({
      type: SET_TARIFF_CONNECTION_POPUP,
      payload: {
        contactPhone: undefined,
        divorcing: false,
        isPending: true,
        isError: false,
        step: null,
        popupIsActive: true,
      },
    });

    dispatch(
      tariffData.update({
        chosenSubscriptions: chosenSubscriptions?.join(','),
        tariffConstructorId: info?.constructorId,
        optionSocs: chosenSocs?.join(','),
        socsOff: socsOff?.join(','),
        socsOn: socsOn?.join(','),
        updateConnectedTariff,
        connectingConstructor,
        updateTariffUp,
        fttbServices,
        requestConvergenceParams,
        isConnectUpConvergence,
        isNeedCallBackOptions,
        isChangeMobile,
        isChangeOnlyMobileOptions,
        tariffUpSoc,
        hasChangedConvergenceOptions,
        requestConstructorParams: params,
      }),
    );

    /** Клик на Подключить, Хочу этот тариф Yandex Metrika */
    mobTariffDetailConnectYM(document.location.href);

    axios
      .post(data.connectPopupUrl, params)
      .then(({ data: { isSucceeded, view } }) => {
        if (isSucceeded && view) {
          const tariffInformation = formatConnectPopupUrlResponse({
            data: {
              ...view,
              isSucceeded,
              isForYoungDetailEnabled: true,
              isForYoung: true,
            },
            simOrderPlace: 'TariffZLand',
          });
          dispatch({
            type: SET_TARIFF_CONNECTION_POPUP,
            payload: {
              alias: data.alias,
              dataFetched: true,
            },
          });
          dispatch(tariffData.update(tariffInformation));
        } else {
          dispatch({
            type: SET_TARIFF_CONNECTION_POPUP,
            payload: { dataFetched: true },
          });
          dispatch(
            tariffData.update({
              isSucceeded,
              dataFetched: true,
              ...view,
            }),
          );
        }
      })
      .finally(() => {
        setOrderModalPending(false);
        dispatch(toggleTariffConnectionPopup(true, null, null, true));
      });
  };

export { determineOrderModal, openTariffConnectPopup, setTariffConnectPopupInitialData };
