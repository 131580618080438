import React, { useEffect, useRef } from 'react';
import type { AnimationSegment } from 'lottie-web';

import { getContainerCursorPosition, getContainerVisibility } from './helper';
import { UseInitInteractivity } from './types';

export const useInitInteractivity: UseInitInteractivity = ({
  actions,
  animationContainerRef,
  animationItem,
  mode,
  wrapperRef,
}) => {
  const modeRef = useRef(mode);
  const animationItemRef = useRef(animationItem);
  const assignedSegmentRef: React.MutableRefObject<null | number[]> = useRef(null);

  const handleCursor = (_x: number, _y: number) => {
    if (!animationContainerRef.current || !animationItemRef.current || !actions.length) {
      return;
    }

    let x = _x;
    let y = _y;

    if (x !== -1 && y !== -1) {
      const pos = getContainerCursorPosition(animationContainerRef.current, x, y);
      x = pos.x;
      y = pos.y;
    }

    const action = actions.find(({ position }) => {
      if (!position) {
        return false;
      }

      if (Array.isArray(position.x) && Array.isArray(position.y)) {
        return x >= position.x[0] && x <= position.x[1] && y >= position.y[0] && y <= position.y[1];
      }

      if (!Number.isNaN(position.x) && !Number.isNaN(position.y)) {
        return x === position.x && y === position.y;
      }

      return false;
    });

    if (!action) {
      return;
    }

    switch (action.type) {
      case 'seek': {
        if (
          action.position &&
          Array.isArray(action.position.x) &&
          Array.isArray(action.position.y) &&
          action.frames.length === 2
        ) {
          const xPercent =
            (x - action.position.x[0]) / (action.position.x[1] - action.position.x[0]);
          const yPercent =
            (y - action.position.y[0]) / (action.position.y[1] - action.position.y[0]);

          animationItemRef.current.playSegments(action.frames as AnimationSegment, true);
          animationItemRef.current.goToAndStop(
            Math.ceil(((xPercent + yPercent) / 2) * (action.frames[1] - action.frames[0])),
            true,
          );
        }
        break;
      }
      case 'loop': {
        animationItemRef.current.playSegments(action.frames as AnimationSegment, true);
        break;
      }
      case 'play': {
        if (animationItemRef.current.isPaused) {
          animationItemRef.current.resetSegments(false);
        }
        animationItemRef.current.playSegments(action.frames as AnimationSegment);
        break;
      }
      case 'stop': {
        animationItemRef.current.goToAndStop(action.frames[0], true);
        break;
      }
      default:
        break;
    }
  };

  const mouseMoveHandler = (ev: Event) => {
    const e = ev as MouseEvent;
    handleCursor(e.clientX, e.clientY);
  };

  const mouseOutHandler = () => {
    handleCursor(-1, -1);
  };

  const scrollHandler = () => {
    if (!animationContainerRef.current || !animationItemRef.current || !actions.length) {
      return;
    }

    const currentPercent = getContainerVisibility(
      animationContainerRef.current,
      wrapperRef?.current ?? window,
    );

    const action = actions.find(
      ({ visibility }) =>
        visibility && currentPercent >= visibility[0] && currentPercent <= visibility[1],
    );

    if (!action) {
      return;
    }

    switch (action.type) {
      case 'seek': {
        if (action.visibility && action.frames.length === 2) {
          const frameToGo =
            action.frames[0] +
            Math.ceil(
              ((currentPercent - action.visibility[0]) /
                (action.visibility[1] - action.visibility[0])) *
                action.frames[1],
            );
          animationItemRef.current.goToAndStop(
            frameToGo - animationItemRef.current.firstFrame - 1,
            true,
          );
        }
        break;
      }
      case 'loop': {
        animationItemRef.current.playSegments(action.frames as AnimationSegment, true);
        assignedSegmentRef.current = action.frames;
        break;
      }
      case 'play': {
        animationItemRef.current.resetSegments(true);
        animationItemRef.current.play();
        break;
      }
      case 'stop': {
        animationItemRef.current.goToAndStop(
          action.frames[0] - animationItemRef.current.firstFrame - 1,
          true,
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  const listenerUpdate = (type: 'add' | 'remove', workMode: 'cursor' | 'scroll') => {
    const operation = type === 'add' ? 'addEventListener' : 'removeEventListener';
    if (workMode === 'scroll') {
      document[operation]('scroll', scrollHandler);
    } else {
      animationContainerRef.current?.[operation]('mousemove', mouseMoveHandler);
      animationContainerRef.current?.[operation]('mouseout', mouseOutHandler);
    }
  };

  useEffect(() => {
    if (!animationContainerRef.current || !animationItemRef.current || !actions.length) {
      return;
    }

    listenerUpdate('add', mode);

    // eslint-disable-next-line consistent-return
    return () => {
      listenerUpdate('remove', mode);
    };
  }, [mode, animationItem]);

  useEffect(() => {
    modeRef.current = mode;
  }, [mode]);

  useEffect(() => {
    animationItemRef.current = animationItem;
  }, [animationItem]);
};
