import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Tabs } from '@beef/ui-kit';

// Actions
import { setChosenTab } from '../../packages/context/actions';
import { dispatcher } from '../../packages/context/provider';

// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const DEFAULT_TAB = '1';

const PartnerTabs = ({ tabsContent }) => {
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);

  const handleChange = useCallback(
    (id) => {
      if (id !== activeTab) {
        setActiveTab(id);
        const selectedTabName = (tabsContent.find((tab) => tab.id === id) || {}).title;
        dispatcher(setChosenTab(selectedTabName));
      }
    },
    [activeTab],
  );

  return (
    <div className={cn('tabs-wrapper')}>
      <Tabs
        noWrap
        onChange={handleChange}
        selectedTab={activeTab}
        tabs={tabsContent}
        variant="tertiary"
      />
    </div>
  );
};

PartnerTabs.propTypes = {
  tabsContent: PropTypes.arrayOf({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
};

export { PartnerTabs };
