import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button } from '@carnica/ui-kit';

import { ButtonsType } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Buttons: FC<ButtonsType> = ({ acceptText, declineText, onAccept, onDecline }) => {
  return (
    <div className={cx('button')}>
      {acceptText && (
        <Button onClick={onAccept} size="l" type="button" view="primary" width="full">
          {acceptText}
        </Button>
      )}

      {declineText && (
        <Button onClick={onDecline} size="l" type="button" view="secondary" width="full">
          {declineText}
        </Button>
      )}
    </div>
  );
};
