import { FontSizeType } from '@beef/ui-kit';

import { useConnectedConvergenceInfo } from '../../../../hooks/tariffStateHooks/useConnectedConvergenceInfo';

/** Хук подготовки данных для бока подключенной конвергенции (ШПД) */
export const usePrepareConvergenceConnected = () => {
  const connectConvergenceInfo = useConnectedConvergenceInfo();
  return {
    service: connectConvergenceInfo || {},
    /** Объект пропов для компонента TitleContent (title/description/icon) */
    titleContentProps: {
      size: 'size2-m' as FontSizeType,
      extraSize: 'size5-r-s' as FontSizeType,
    },
  };
};
