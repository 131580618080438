import { createReducer } from '@reduxjs/toolkit';

import { IPartnerServiceCardState, TPartnerServiceCardDataSubstate } from '../types';
import { setFaqClickState } from './actions/analytics';
import { setIsConnected, setPartnerProduct } from './actions/data';
import { setHasMounted } from './actions/meta';
import { CONNECTED_TEXT } from './constants';

export const analyticsReducer = createReducer<Partial<IPartnerServiceCardState['analytics']>>(
  {
    faqClickState: {},
  },
  {
    [setFaqClickState.type]: (state, action) => {
      const { payload } = action;
      if (state?.faqClickState) {
        state.faqClickState[payload.id] = payload.isOpen;
      }
    },
    [setFaqClickState.type]: (state, action) => {
      const { payload } = action;
      if (state?.faqClickState) {
        state.faqClickState[payload.id] = !state.faqClickState[payload.id];
      }
    },
  },
);

export const metaReducer = createReducer(
  {
    hasMounted: false,
  },
  {
    [setHasMounted.type]: (state) => {
      state.hasMounted = true;
    },
  },
);

export const dataReducer = createReducer<Partial<TPartnerServiceCardDataSubstate>>(
  {},
  {
    [setPartnerProduct.type]: (state, action) => {
      if (state?.partnerProduct && action?.payload) {
        state.partnerProduct = {
          ...state.partnerProduct,
          ...action.payload,
        };
      }
    },
    [setIsConnected.type]: (state, action) => {
      state.isConnected = action.payload;
      state.subscriptionStatusText = state.connectedText || CONNECTED_TEXT;
    },
  },
);
