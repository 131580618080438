import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { PageFixer } from '@beef/smart-kit';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type NotificationProps = {
  criticalType: string;
  description: string;
};

export const Notification = memo<React.PropsWithChildren<NotificationProps>>(
  ({ description, criticalType, children }) => (
    <div className={cx('wrapper', criticalType.toLowerCase())}>
      <PageFixer backgroundCustomColor="transparent">
        <div className={cx('grid')}>
          <div className={cx('content-area')}>
            <Text color="inherit" size="size5-r-s">
              {description}
            </Text>
          </div>
          <div className={cx('buttons-area')}>
            <div className={cx('delimiter')} />
            {children}
          </div>
        </div>
      </PageFixer>
    </div>
  ),
);
