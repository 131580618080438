import { ymPushEvent, ymPushParams } from 'utils/ym';
import {
  TPrepareTariffCardAnalyticProps,
  tariffCardAnalyticsProps,
} from 'pages/TariffsCatalog2021/utils/tariffCardAnalyticsProps';

/** Функция отправки аналитики при клике по карточке тарифа в каталоге тарифов */
export const ymAnalyticTariffCardClick = ({ ...props }: TPrepareTariffCardAnalyticProps) => {
  /** Убираем из текущего URL все get-параметры для корректной работы аналитики */
  const url = document.location.href.split('?')[0];

  ymPushParams(tariffCardAnalyticsProps({ ...props }));
  ymPushEvent('MobTariffsToDetail', { URL6: url });
};
