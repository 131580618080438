/* eslint-disable no-restricted-syntax */
import { getLocalCurrencyStr } from 'utils/getLocalCurrencyStr';

import { convertISODateToString } from './utils';

class DeviceMapper {
  getColors(device) {
    if (device?.colors) {
      return device.colors.map((color) => ({
        value: color.productSlug,
        label: color.color,
        isActive: color.isActive,
      }));
    }
    return undefined;
  }

  getTypes(types) {
    return types?.map((type) => ({
      title: type.title,
      list: (type.list || []).map(({ productSlug, title, isActive }) => ({
        id: productSlug,
        title,
        isActive,
      })),
    }));
  }

  getImgOptions(device) {
    return device?.images.map((image) => ({
      src: image.imageUrl,
      alt: image.alt,
    }));
  }

  getPromoStartSales(startDate, startTemplate) {
    const template = startTemplate || 'Старт продаж {start} года';

    if (startDate) {
      const date = convertISODateToString(startDate);

      return template.replace('{start}', date);
    }
    return undefined;
  }

  getBadges(badges) {
    return badges.reduce((acc, cur) => {
      let badge = {
        children: cur.title,
        help: cur.content,
      };

      if (cur.variant) {
        badge = {
          ...badge,
          variant: cur.variant,
        };
      }

      if (cur.textColor) {
        badge = {
          ...badge,
          textColor: cur.textColor,
        };
      }

      acc.push(badge);
      return acc;
    }, []);
  }

  getPromoCreditLabel(creditDetails) {
    return creditDetails?.referenceTemplate
      .replace('<AnchorLink>', '')
      .replace('</AnchorLink>', '')
      .replace('₽', '')
      .replace('{{minPayment}}', `${getLocalCurrencyStr(creditDetails.minPayment)}`);
  }

  getMappedDevices(productsFromApi) {
    return productsFromApi.map((product) => {
      const id = product?.id;
      const promotionId = product?.productPromotion?.id;

      const isPromo = product?.productPromotion?.isOnlyWithPromo;
      const price = isPromo ? product.productPromotion.bundlePrice : product?.price?.value;

      const oldPrice =
        isPromo ? product.productPromotion.priceWithoutPromotion : product?.price?.oldValue;

      const discount =
        isPromo ? product.productPromotion.discountPercent : product?.price?.discountPercent;

      const imgOptions = product?.images.map((image) => ({ src: image })).slice(0, 6);
      const promoBadges = this.getBadges(product.badges);

      const variant =
        product?.parameters?.length ? product.parameters.map(({ value }) => value).join('_') : '';

      return {
        id,
        promotionId,
        slug: product.urlSlug,
        name: product.name,
        brand: product.brandName,
        oldPrice,
        price,
        link: `/shop/details/${product.urlSlug}`,
        discount,
        promoBadges,
        imgOptions,
        isPreorder: product.isPreorder,
        category: product.mainCollectionName,
        variant,
      };
    });
  }

  getEnrichedDevice(device) {
    const id = device?.productId;
    const promotionId = device?.promotion?.available?.id;

    const isPromo = device?.promotion?.available?.isOnlyWithPromo;
    const price = isPromo ? device.promotion.available.bundlePrice : device?.price?.value;

    const oldPrice =
      isPromo ? device.promotion.available.priceWithoutPromotion : device?.price?.oldValue;

    const discount =
      isPromo ? device.promotion.available.discountPercent : device?.price?.discountPercent;

    const startDate = device?.preorderDetails?.startDate;
    const startTemplate = device?.preorderDetails?.startTemplate;
    const contentPromotion = device?.contentPromotion;

    const color = this.getColors(device);
    const types = this.getTypes(device?.types);
    const imgOptions = this.getImgOptions(device);
    const promoBadges = this.getBadges(device?.badges);

    const reviews = {
      average: device?.feedback?.average,
      count: device?.feedback?.count,
      link: `${device?.baseUrl}otzyvy/`,
    };

    let promoCredit;
    let promoBundle;
    let promoCode;
    let promoStartSales;

    if (device?.creditDetails) {
      promoCredit = {
        label: this.getPromoCreditLabel(device?.creditDetails),
        link: `${device?.baseUrl}payment/`,
      };
    }

    if (device?.promotion?.available?.name) {
      promoBundle = {
        label: device.promotion.available.name,
        link: `${device?.baseUrl}promo/`,
      };
    }

    if (contentPromotion) {
      promoCode = {
        label: contentPromotion,
        link: `${device?.baseUrl}promo`,
      };
    }

    if (device.isPreorder) {
      promoStartSales = this.getPromoStartSales(startDate, startTemplate);
    }

    return {
      promoCode,
      discount,
      types,
      color,
      imgOptions,
      reviews,
      promoCredit,
      promoBundle,
      promoBadges,
      id,
      promotionId,
      price,
      oldPrice,
      promoStartSales,
      name: device.title,
      characteristics: device?.characteristics?.brief,
      isPreorder: device.isPreorder,
      link: device.absoluteBaseUrl,
      slug: device?.productSlug,
      brand: device.brandName,
      impression: device?.impression,
    };
  }
}

export const deviceMapper = new DeviceMapper();
