import React, { ReactNode, memo } from 'react';
import classNames from 'classnames/bind';

import { LEFT_TYPE_SIDE } from 'constructorV1/constants';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TLayout = {
  children: ReactNode;
  sideType?: 'left' | 'right';
};

/** Разделяет главный компонент на левую и правую часть, в зависимости от sideType */
export const Layout = memo<TLayout>(({ sideType = 'left', children }) =>
  sideType === LEFT_TYPE_SIDE ?
    <main className={cx('wrapper', 'wrapper--left')}>{children}</main>
  : <aside className={cx('wrapper', 'wrapper--right')}>{children}</aside>,
);
