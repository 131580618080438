import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {
  Button,
  DropdownCustom,
  Form,
  Heading,
  InputGroup,
  Label,
  Link,
  Preloader,
  TextInput,
} from '@beef/legacy-ui-kit';

import Icon from 'pages-components/Icon';
import { getMobileOS } from 'utils/detect-mobile';

import styles from './index.pcss';

const cx = classNames.bind(styles);

const inputs = {
  text: TextInput,
  dropdown: DropdownCustom,
};

const protocol = getMobileOS() === 'ios' ? 'maps' : 'https';

export const AddressLink = ({ address, location, inForm }, index) => (
  <div className={cx('addressLink', { inForm })} key={index}>
    <Icon name="mapMarker" />
    <Link
      className="hideVisitedColor"
      href={`${protocol}://maps.google.com/maps?daddr=${location}&amp;ll=`}
      target="_blank"
    >
      {address}
    </Link>
  </div>
);

AddressLink.propTypes = {
  address: PropTypes.string,
  location: PropTypes.string,
  inForm: PropTypes.any,
};

class OrderForm extends Component {
  state = {};

  inputTypeFinished = (name) => (value) =>
    value &&
    typeof value === 'string' &&
    window.dataLayer.push({
      event: 'GA_event',
      eventCategory: 'EngPopup',
      eventAction: name,
      eventLabel: window.location.href,
    });

  dropdownChange = (name) => (value) => {
    this.inputTypeFinished(name)(value);
    if (name === 'office') {
      this.props.chooseOffice(value);
    } else {
      this.props.chooseRegion(value);
    }
  };

  formDataChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  renderRequiredLabel = () => <span style={{ color: 'red' }}>*</span>;

  renderCitySelector = (name) => (
    <InputGroup key={name}>
      <Label>City of destination {this.renderRequiredLabel()}</Label>
      <DropdownCustom
        disabled={this.props.step === 'pending'}
        name={name}
        onChange={this.dropdownChange(name)}
        options={this.props.getRegions()}
        placeholder="Choose your city"
        value={this.props.region}
      />
    </InputGroup>
  );

  renderOfficeSelector = (name) => (
    <InputGroup key={name}>
      <Label>Office to pick up the SIM {this.renderRequiredLabel()}</Label>
      <DropdownCustom
        disabled={this.props.step === 'pending'}
        name={name}
        onChange={this.dropdownChange(name)}
        options={this.props.getPoints().map(({ address }) => ({ label: address, value: address }))}
        placeholder="Choose office"
        status={this.props.validationKey && !this.props.office && 'fail'}
        value={this.props.office}
      />
    </InputGroup>
  );

  render() {
    const {
      formTitle,
      buttonText,
      formItems,
      step,
      validationKey,
      captcha,
      office,
      region,
      serverError,
      agreementText,
      onSubmit,
    } = this.props;
    const values = this.state;

    const formDisabled =
      formItems.findIndex(({ required, name }) => (required ? !values[name] : false)) !== -1 ||
      !(office && region);

    return (
      <Form onChange={this.formDataChange} onSubmit={onSubmit}>
        <Heading level={1}>{formTitle}</Heading>
        {formItems.map(({ title, name, value, type, required, options, placeholder }, index) => {
          const Input = inputs[type];
          let inputComponent = null;

          if (type === 'captcha') {
            inputComponent = captcha && (
              <TextInput
                captcha={captcha}
                disabled={step === 'pending'}
                key={index}
                name={name}
                status="fail"
              />
            );
          }

          if (type === 'hidden') {
            return (
              <div className={cx('hidden')} key={index}>
                <input name={name} type="hidden" value={value} />
              </div>
            );
          }

          if (type === 'city') {
            return this.renderCitySelector(name);
          }

          if (type === 'office') {
            return this.renderOfficeSelector(name);
          }

          return inputComponent || Input ?
              <InputGroup key={index}>
                <Label>
                  {title} {required && this.renderRequiredLabel()}
                </Label>
                {inputComponent || (
                  <Input
                    disabled={step === 'pending'}
                    name={name}
                    onBlur={this.inputTypeFinished(name)}
                    options={options}
                    placeholder={placeholder}
                    status={validationKey[index] === '1' ? 'fail' : ''}
                  />
                )}
              </InputGroup>
            : null;
        })}
        <InputGroup>
          {serverError && (
            <div
              className={cx('errorMessage')}
              dangerouslySetInnerHTML={{ __html: 'An error has been accured. Try again later.' }}
            />
          )}
          {step === 'pending' ?
            <Preloader />
          : <Button disabled={formDisabled}>{buttonText}</Button>}
        </InputGroup>
        <div className={cx('agreement')} dangerouslySetInnerHTML={{ __html: agreementText }} />
      </Form>
    );
  }
}

OrderForm.propTypes = {
  formTitle: PropTypes.string,
  buttonText: PropTypes.string,
  formItems: PropTypes.array,
  step: PropTypes.string,
  validationKey: PropTypes.array,
  region: PropTypes.string,
  agreementText: PropTypes.string,
  onSubmit: PropTypes.func,
  chooseOffice: PropTypes.func,
  chooseRegion: PropTypes.func,
  getRegions: PropTypes.func,
  getPoints: PropTypes.func,
  office: PropTypes.string,
  captcha: PropTypes.bool,
  serverError: PropTypes.bool,
};

export default OrderForm;
