import { EStatus } from 'pages/MainPage2021/pageBlocks/PersonalInfoBlock/types';

import { useGetUserInfo } from './useGetUserInfo';

/**
 * @desc: Возвращает статус авторизованного пользователя
 * @returns {boolean}
 */
export const useUserBlockedInfo = () => {
  const userInfo = useGetUserInfo();

  return userInfo?.status?.status !== EStatus.Active;
};
