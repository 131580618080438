import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { TeaserBanner as Banner } from '@beef/smart-kit';

import { TeaserBannerType } from 'pages/SPN/types';

import { Details } from './components/Details';
// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const TeaserBanner: FC<TeaserBannerType> = ({ data }) => (
  <div className={cn('wrapper')}>
    <Banner
      detailsNode={<Details data={data} />}
      img={data?.img}
      isBackgroundImage={data?.isBackgroundImage}
    />
  </div>
);
