import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';

import { Bonus, TBonus } from '../Bonus';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TBonusMapper = {
  bonuses?: TBonus[];
};

/** Общий компонент бонусов, получаемых из пресетов */
export const BonusMapper: FC<TBonusMapper> = ({ bonuses = [] }) =>
  bonuses?.length ?
    <motion.ul className={cx('wrapper')} layout>
      <AnimatePresence>
        {bonuses.map((bonus) => (
          <LayoutGroup key={bonus.subTitle}>
            <Bonus {...bonus} key={bonus.subTitle} />
          </LayoutGroup>
        ))}
      </AnimatePresence>
    </motion.ul>
  : null;
