import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import DiscountTiles from 'pages-components/Discount';

// Styles
import baseStyles from '../styles.pcss';
import selfStyles from './styles.pcss';

const cx = classNames.bind({
  ...baseStyles,
  ...selfStyles,
});

const Discount = ({ icon, text, textTooltip, price, discount, description, tilesStyle }) => (
  <div className={cx('tiles', 'discount', tilesStyle)}>
    {icon && (
      <div className={cx('discountIcon')}>
        <div className={cx('icon')}>
          <img alt="discount" src={icon} />
        </div>
      </div>
    )}
    <div className={cx('rightBox')}>
      <div className={cx('about')}>
        <span className={cx('text')} dangerouslySetInnerHTML={{ __html: text }} />
      </div>

      {price && (
        <div className={cx('aboutDiscount')}>
          <DiscountTiles.PriceOld
            oldValue={`${price} &#8381;`}
            priceColor={tilesStyle === 'dark' ? 'white' : 'dark'}
          />
          {discount && <DiscountTiles.Label animated label={discount} />}
        </div>
      )}
    </div>

    <div className={cx('leftBox', 'discountLeftBox')}>
      <div className={cx('description')}>
        <span dangerouslySetInnerHTML={{ __html: description }} />
        {textTooltip && (
          <Tooltip className={cx('tooltip')}>
            <span dangerouslySetInnerHTML={{ __html: textTooltip }} />
          </Tooltip>
        )}
      </div>
    </div>
  </div>
);

Discount.defaultProps = {
  icon: '//static.beeline.ru/upload/images/b2c/icons/percentage.svg',
  tilesStyle: 'dark',
};

Discount.propTypes = {
  tilesStyle: PropTypes.string,
  textTooltip: PropTypes.string,
  description: PropTypes.string,
  discount: PropTypes.number,
  price: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
};

export default Discount;
