import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { pathOr } from 'ramda';
import qs from 'query-string';

// Components
import { getSubstate } from '@beef/legacy-beeline-store/utils';

import store from 'store';
import { maskPhone } from 'utils/format-string';
import ErrorPopup from 'pages-components/ErrorPopup/ErrorPopup';

import AddNumberPopup from './AddNumberPopup';
import UpsalePopup from './UpsalePopup';
import ServiceSettingsPopup from './ServiceSettingsPopup/ServiceSettingsPopup';
import InvitationPopup from './InvitationPopup/InvitationPopup';
import SettingsPopup from './SettingsPopup/SettingsPopup';
import DisconnectWarningPopup from './DisconnectWarningPopup/DisconnectWarningPopup';
import SwitchNumberPopup from './SwitchNumberPopup/SwitchNumberPopup';
import DisconnectPopup from './DisconnectPopup/DisconnectPopup';
import DisconnectConfirmPopup from './DisconnectConfirmPopup/DisconnectConfirmPopup';
import DisconnectSuccessPopup from './DisconnectSuccessPopup/DisconnectSuccessPopup';
import DisconnectNumberWarningPopup from './DisconnectNumberWarningPopup';

// Actions
import {
  SHARING_SERVICE_NAME,
  addNumberFromAnotherPopup,
  addNumberPopup,
  changeNumbers,
  changedNumber,
  connectedNumbers,
  disconnectAll,
  disconnectConfirmPopup,
  disconnectNumber,
  disconnectNumberWarning,
  disconnectPopup,
  disconnectSuccessPopup,
  disconnectWrarningPopup,
  errors,
  invitationPopup,
  isUpsalePopupIntoTariffCard,
  removeNumber,
  sendInvitation,
  serviceSettingsPopup,
  settingsPopup,
  sharingData,
  showFamilyExpansionError,
  switchNumber,
  switchNumberPopup,
  switchNumberSubmit,
  textContent,
  triggerPopupShareService,
  triggerShareService,
  upsalePopup,
  upsalePopupContent,
} from '../../../actions/sharing';
import { tariffData } from '../../../actions/servicesExtension';

// Utils

export class SharingServiceExtension extends Component {
  componentDidMount() {
    const { data } = this.props;

    if (this.props.onInit && data) this.props.onInit(data);
    if (
      this.props.isAuthenticated &&
      qs.parse(window.location.search).connect === SHARING_SERVICE_NAME
    ) {
      setTimeout(() => this.props.onServiceClick(), 500);
    }
  }

  render() {
    const { data, texts, isUpsalePopupIntoTariffCard, openSebPopup, openAddNumberPopup } =
      this.props;

    if (typeof data === 'undefined') return null;

    const connectedNumber =
      this.props.numbers && this.props.numbers.length ?
        this.props.numbers.filter((number) => number.status === 'connected').length
      : 0;
    const showUpsalePopup = !!(this.props.upsalePopupOpened && this.props.upsalePopupContent);
    const disconnectTitle = texts && texts.disconnectSuccessInfoTitle;
    const familyFilled = data.invites && data.invites.length >= data.count;

    let upsaleResetButtonAction = isUpsalePopupIntoTariffCard ? openSebPopup : openAddNumberPopup;
    if (familyFilled) {
      upsaleResetButtonAction = this.props.onExpansionError;
    }

    return (
      <div>
        <ServiceSettingsPopup
          active={!!connectedNumber}
          maxNumbers={data.count}
          numbers={connectedNumber}
        />

        {showUpsalePopup && (
          <UpsalePopup
            close={this.props.closeUpsalePopup}
            content={this.props.upsalePopupContent}
            familyFilled={familyFilled}
            open={this.props.upsalePopupOpened}
            resetButtonAction={upsaleResetButtonAction}
          />
        )}

        {this.props.addNumberOpened && (
          <AddNumberPopup
            onAddNumber={this.props.onAddNumber}
            onClose={this.props.onAddNumberClose}
            opened={this.props.addNumberOpened}
            profileCtn={this.props.addedCtn}
          />
        )}

        {this.props.invitationOpened && (
          <InvitationPopup
            maxNumbers={data.count}
            numbers={this.props.numbers}
            onAddNumber={this.props.onAddNumberFromAnotherPopup}
            onClose={this.props.onInvitationClose}
            opened={this.props.invitationOpened}
            price={data.price}
            switchHowItWorks={texts.invitationHowItWorksLink}
            switchInvitationHelp={texts.invitationHelpLink}
            unit={data.unit}
          />
        )}

        {this.props.settingsOpened && (
          <SettingsPopup
            maxNumbers={data.count}
            numbers={this.props.numbers}
            onAddNumber={this.props.onAddNumberFromAnotherPopup}
            onClose={this.props.onSettingsClose}
            onDisconnectNumber={this.props.onDisconnectNumber}
            onSwitchNumber={this.props.onSwitchNumber}
            opened={this.props.settingsOpened}
            switchHowItWorks={texts.invitationHowItWorksLink}
            switchInvitationHelp={texts.invitationHelpLink}
          />
        )}

        {this.props.numbers.length ?
          <DisconnectWarningPopup
            number={this.props.currentDeletedNumber}
            onClose={this.props.onDisconnectWarningClose}
            onDisconnect={this.props.onRemoveNumber}
            onSwitchNumber={this.props.onSwitchNumber}
            opened={this.props.disconnectWarningOpened}
          />
        : null}

        {this.props.disconnectNumberWarningPopup && (
          <DisconnectNumberWarningPopup
            onClose={this.props.onDisconnectNumberWarningClose}
            opened={this.props.disconnectNumberWarningPopup}
          />
        )}

        {this.props.numbers.length ?
          <SwitchNumberPopup
            maxNumbers={data.count}
            number={this.props.currentDeletedNumber}
            numbers={this.props.numbers}
            onClose={this.props.onSwitchNumberClose}
            onSwitchNumberSubmit={this.props.onSwitchNumberSubmit}
            opened={this.props.switchNumberOpened}
            price={data.price}
            switchNumberInfoLink={texts.switchNumberInfoLink}
            switchNumberSimOrder={texts.switchNumberSimOrder}
            unit={data.unit}
          />
        : null}

        {this.props.disconnectOpened && (
          <DisconnectPopup
            addPacket={data.addPacket}
            addPacketSize={+data.addPacketSize}
            maxNumbers={data.count}
            number={this.props.currentDeletedNumber}
            numbers={this.props.numbers}
            onChangeNumbers={this.props.onSwitchNumber}
            onClose={this.props.onDisconnectClose}
            onDisconnect={this.props.onDisconnectAll}
            opened={this.props.disconnectOpened}
            price={data.price}
            unit={data.unit}
          />
        )}
        {this.props.disconnectConfirmPopup && (
          <DisconnectConfirmPopup
            connectedNumber={connectedNumber}
            number={this.props.currentDeletedNumber}
            onClose={this.props.onDisconnectConfirmClose}
            onRemoveNumber={this.props.onRemoveNumber}
            onSwitchNumber={this.props.onSwitchNumber}
            opened={this.props.disconnectConfirmPopup}
          />
        )}

        {this.props.disconnectSuccessPopup && (
          <DisconnectSuccessPopup
            infoTitle={disconnectTitle}
            number={this.props.currentDeletedNumber}
            onClose={this.props.onDisconnectSuccessClose}
            opened={this.props.disconnectSuccessPopup}
            type={this.props.disconnectSuccessType}
          />
        )}

        <ErrorPopup
          buttonText={this.props.error.buttonText}
          description={this.props.error.description}
          errorContent={this.props.error.errorContent}
          onButtonClick={this.props.error.onButtonClick}
          onClose={this.props.onErrorClose}
          opened={!!this.props.error.type}
          title={this.props.error.title}
        />
      </div>
    );
  }
}

SharingServiceExtension.propTypes = {
  data: PropTypes.object,
  texts: PropTypes.object,
  onDisconnectNumber: PropTypes.func,
  onSwitchNumberSubmit: PropTypes.func,
  disconnectConfirmPopup: PropTypes.bool,
  onDisconnectConfirmClose: PropTypes.func,
  addNumberOpened: PropTypes.bool,
  onServiceClick: PropTypes.func,
  invitationOpened: PropTypes.bool,
  onAddNumber: PropTypes.func,
  onAddNumberClose: PropTypes.func,
  onInvitationClose: PropTypes.func,
  onAddNumberFromAnotherPopup: PropTypes.func,
  numbers: PropTypes.array,
  settingsOpened: PropTypes.bool,
  onSettingsClose: PropTypes.func,
  onRemoveNumber: PropTypes.func,
  disconnectWarningOpened: PropTypes.bool,
  onDisconnectWarningClose: PropTypes.func,
  switchNumberOpened: PropTypes.bool,
  onSwitchNumberClose: PropTypes.func,
  onSwitchNumber: PropTypes.func,
  disconnectOpened: PropTypes.bool,
  onDisconnectClose: PropTypes.func,
  onDisconnectAll: PropTypes.func,
  currentDeletedNumber: PropTypes.object,
  error: PropTypes.object,
  disconnectSuccessPopup: PropTypes.bool,
  onDisconnectSuccessClose: PropTypes.func,
  disconnectSuccessType: PropTypes.string,
  onErrorClose: PropTypes.func,
  onInit: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  upsalePopupContent: PropTypes.object,
  closeUpsalePopup: PropTypes.func,
  openAddNumberPopup: PropTypes.func,
  upsalePopupOpened: PropTypes.bool,
  openSebPopup: PropTypes.func,
  isUpsalePopupIntoTariffCard: PropTypes.bool,
  disconnectNumberWarningPopup: PropTypes.bool,
  onDisconnectNumberWarningClose: PropTypes.func,
  onExpansionError: PropTypes.func,
  addedCtn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state) => ({
  addNumberOpened: pathOr(false, ['opened'], getSubstate(state, addNumberPopup)),
  addedCtn: pathOr(null, ['profileCtn'], getSubstate(state, addNumberPopup)),
  upsalePopupOpened: pathOr(false, ['opened'], getSubstate(state, upsalePopup)),
  upsalePopupContent: getSubstate(state, upsalePopupContent),
  invitationOpened: pathOr(false, ['opened'], getSubstate(state, invitationPopup)),
  settingsOpened: pathOr(false, ['opened'], getSubstate(state, settingsPopup)),
  disconnectWarningOpened: pathOr(false, ['opened'], getSubstate(state, disconnectWrarningPopup)),
  switchNumberOpened: pathOr(false, ['opened'], getSubstate(state, switchNumberPopup)),
  disconnectNumberWarningPopup: pathOr(
    false,
    ['opened'],
    getSubstate(state, disconnectNumberWarning),
  ),
  data: getSubstate(state, sharingData),
  texts: getSubstate(state, textContent),
  numbers: getSubstate(state, connectedNumbers) || [],
  disconnectOpened: pathOr(false, ['opened'], getSubstate(state, disconnectPopup)),
  disconnectConfirmPopup: pathOr(false, ['opened'], getSubstate(state, disconnectConfirmPopup)),
  disconnectSuccessPopup: pathOr(false, ['opened'], getSubstate(state, disconnectSuccessPopup)),
  disconnectSuccessType: pathOr(null, ['type'], getSubstate(state, disconnectSuccessPopup)),
  currentDeletedNumber: getSubstate(state, changedNumber),
  error: getSubstate(state, errors) || {},
  isUpsalePopupIntoTariffCard: getSubstate(state, isUpsalePopupIntoTariffCard),
});

const mapDispatchToProps = {
  onServiceClick: triggerShareService,
  onPopupServiceClick: triggerPopupShareService,
  openAddNumberPopup: () => addNumberPopup.update({ opened: true }),
  onAddNumberClose: () => addNumberPopup.update({ opened: false }),
  closeUpsalePopup: () => upsalePopup.update({ opened: false }),
  onAddNumber: (data) => sendInvitation(data.phone),
  onServiceSettingsClose: () => serviceSettingsPopup.update({ opened: false }),
  onInvitationClose: () => invitationPopup.update({ opened: false }),
  onAddNumberFromAnotherPopup: addNumberFromAnotherPopup,
  onConnectedChange: changeNumbers,
  onSettingsClose: () => settingsPopup.update({ opened: false }),
  onDisconnectWarningClose: () => disconnectWrarningPopup.update({ opened: false }),
  onRemoveNumber: removeNumber,
  onSwitchNumberClose: () => switchNumberPopup.update({ opened: false }),
  onSwitchNumber: switchNumber,
  onDisconnectClose: () => disconnectPopup.update({ opened: false }),
  onDisconnectConfirmClose: () => disconnectConfirmPopup.update({ opened: false }),
  onDisconnectSuccessClose: () => disconnectSuccessPopup.update({ opened: false }),
  onDisconnectAll: disconnectAll,
  onDisconnectNumber: disconnectNumber,
  onBodyClick: () => serviceSettingsPopup.update({ opened: true }),
  onSwitchNumberSubmit: switchNumberSubmit,
  onExpansionError: showFamilyExpansionError,
  onErrorClose: () => errors.update({ type: null }),
  onDisconnectNumberWarningClose: () => disconnectNumberWarning.update({ opened: false }),
  onInit: (data) =>
    connectedNumbers.set(
      data.invites ?
        data.invites.map((e) => ({
          number: maskPhone(e.ctn),
          status: e.status.toLowerCase() === 'confirmed' ? 'connected' : 'invited',
          name: e.name,
          icon: e.icon,
        }))
      : [],
    ),
};

const ConnectedSharingService = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SharingServiceExtension);

export default ConnectedSharingService;

export class ProvidedSharingServiceExtension extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newStore: store.dispatch(tariffData.update(this.props.data)),
    };
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedSharingService />
      </Provider>
    );
  }
}

ProvidedSharingServiceExtension.propTypes = {
  data: PropTypes.object,
};
