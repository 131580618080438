import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Tooltip } from '@beef/legacy-ui-kit';
import { Text } from '@beef/ui-kit';

import TariffCost from './TariffCost';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffCostWithDiscount = ({
  price,
  subtitle,
  discountedPrice,
  hasDpcSubscriptionFeeOnly,
  isAuthenticated,
  rcRate,
  rcRateWithoutDiscount,
  tooltipText,
}) => {
  const getPriceObject = (priceValue, discountedPriceVAlue) => ({
    defaultCost: priceValue,
    discountedCost: discountedPriceVAlue,
    unit: price.unit,
  });

  const currentPrice = useMemo(() => {
    const azPriceFromDPC = isAuthenticated && discountedPrice?.value;
    const azPriceFromPricePlanAPI =
      isAuthenticated && !hasDpcSubscriptionFeeOnly && rcRateWithoutDiscount && rcRate;
    const nzPriceFromDPC = !isAuthenticated && discountedPrice?.value;
    if (azPriceFromPricePlanAPI) {
      return getPriceObject(rcRateWithoutDiscount, rcRate);
    }
    if (azPriceFromDPC) {
      return getPriceObject(price.value, discountedPrice.value);
    }
    if (nzPriceFromDPC) {
      return getPriceObject(price.value, discountedPrice.value);
    }
    return null;
  }, [isAuthenticated, hasDpcSubscriptionFeeOnly, discountedPrice, price]);

  return (
    <>
      {currentPrice && (
        <div className={cx('cost')}>
          <div className={cx('cost__price')}>
            <Text size="size2-m">{currentPrice.discountedCost}</Text>
          </div>
          <div className={cx('cost__price-description')}>
            <Text size="size4-r">{currentPrice.unit}</Text>
          </div>
          <Text color="grey50" crossOutColor="scarlet" isCrossedOut size="size4-r">
            <div className={cx('discount-container')}>
              <div className={cx('cost__discount')}>{currentPrice.defaultCost}</div>
              <div className={cx('cost__discount-description')}>{currentPrice.unit}</div>
            </div>
          </Text>
        </div>
      )}
      {!currentPrice && <TariffCost price={price} />}
      <div className={cx('row', 'row--flex')}>
        {subtitle && (
          <Text size="size4-m" tagType="span">
            {subtitle}
          </Text>
        )}
        {tooltipText && currentPrice && (
          <Tooltip>
            <div dangerouslySetInnerHTML={{ __html: tooltipText }} />
          </Tooltip>
        )}
      </div>
    </>
  );
};

TariffCostWithDiscount.propTypes = {
  price: PropTypes.shape({
    value: PropTypes.string,
    unit: PropTypes.string,
  }),
  discountedPrice: PropTypes.shape({
    value: PropTypes.string,
    unit: PropTypes.string,
  }),
  subtitle: PropTypes.string,
  showDiscountParams: PropTypes.shape({
    discountValue: PropTypes.number,
    discountParam: PropTypes.number,
  }),
  tooltipText: PropTypes.string,
  hasDpcSubscriptionFeeOnly: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  rcRate: PropTypes.number,
  rcRateWithoutDiscount: PropTypes.number,
};

export default TariffCostWithDiscount;
