import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { composeStoreInitializer } from '@beef/redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { commonReducer } from './slices/commonSlice';
import { additionalDataReducer } from './slices/additionalDataSlice';

const rootReducer = combineReducers({
  common: commonReducer,
  additionalData: additionalDataReducer,
});

export const initializeStore = composeStoreInitializer(
  rootReducer,
) as unknown as typeof configureStore<typeof rootReducer>;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ReturnType<typeof initializeStore>['dispatch'];

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
