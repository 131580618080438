import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../store';
import { changeIsCompleteOfCheckCache } from '../store/slices/commonContentSlice/asyncThunks/changeIsCompleteOfCheckCache';

/** Хук установки данных по конструктору из constructor-state (браузерная вкладка Application).
 * Ставим кешированные данные в стор через текущий хук, для устранения ошибки согласования с состоянием при SSR */
export const useSetPersistedInfo = () => {
  const dispatch = useAppDispatch();

  /* Получаем текущие данные из объекта common */
  const isCompleteOfCheckCache =
    useAppSelector((state) => state.common.isCompleteOfCheckCache) ?? false;

  useEffect(() => {
    dispatch(changeIsCompleteOfCheckCache()).catch((e) => {
      console.error(e);
    });
  }, []);

  return isCompleteOfCheckCache;
};
