type TAppBannerAnalyticsProps = {
  authStatus: string;
};

/** Функция подготовки данных app-баннера в каталоге тарифов */
export const appBannerAnalyticsProps = ({ authStatus }: TAppBannerAnalyticsProps) => {
  return {
    tariff_catalog_mobile: {
      auth_status: authStatus,
      block_app: 'view',
    },
  };
};
