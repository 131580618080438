import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { path } from 'ramda';
import { InputGroup, InputsRow, Label, Link, Status, TextInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';
import { checkFlatConnection, flatConnectionStates } from 'pages/FTTB/utils/checkAddressHelper';
import { debounce } from 'utils/timed-functions';
import { templateParser } from 'utils/format-string';
import Ctx from 'pages-components/Ctx';
import { pushFloatingCheckAction } from 'utils/ga';

import styles from './FormAddress.pcss';
import Dropdown from '../Dropdown/Dropdown';
import { SET_ALLINONE_VALUE } from '../../actions/actionTypes';
import { getTariffData } from '../../actions/actions';

const cx = classNames.bind(styles);

const createGAPush = (event, serverData, eventData) => {
  const eventObj = {
    event,
    clientType: 'B2C',
    ordered_itemProduct: 'Convorder',
    ordered_itemSoc: serverData.soc,
    ordered_itemTitle: serverData.mobileTariffName,
    ordered_itemPrice: serverData.feePerMonth,
    ...eventData,
  };

  if (serverData.connectedInacFromParams) {
    eventObj.ordered_addServices = serverData.connectedInacFromParams.replace(/;/g, '|');
  } else {
    eventObj.ordered_addServices = '';
  }

  return eventObj;
};

class FormAddress extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      flat: props.flat,
      house: '',
      street: '',
      region: '',
      notAvailablePushed: false,
      newStreetPushed: false,
      newHousePushed: false,
      newFlatPushed: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { flatIsDisabled, streetId, houseId, isNotAvailable, currentRegion } = this.props;

    if (flatIsDisabled) {
      this.setState({ flat: '' });
    }

    if (currentRegion?.id) {
      if (currentRegion?.id !== prevProps.currentRegion?.id) {
        setTimeout(() => this.streetInput.setFocus(), 20);
      }

      if (currentRegion?.label) {
        this.setState({ region: currentRegion.label });
      }
    }

    if (!!streetId && streetId !== prevProps.streetId) {
      setTimeout(() => this.houseInput.setFocus(), 20);
    }

    if (!!houseId && prevProps.houseId !== this.props.houseId) {
      setTimeout(() => this.flatInput.setFocus(), 20);
      pushFloatingCheckAction('address', 'houseFound');
    }

    if (isNotAvailable && !this.state.notAvailablePushed) {
      this.setState({ notAvailablePushed: true }, () => {
        const event = createGAPush('event_item_order_sent_fail', this.props.serverData);
        event.error_type = 'not_connected_house';
        this.props.pushToAnalytics(event, 'sentFail');
        pushFloatingCheckAction('address', 'houseNotFound');
      });
    }
  }

  checkFlatConnection = debounce((flat) => {
    const {
      currentRegion,
      streetId,
      houseId,
      isNotAvailable,
      setFlatUsedState,
      setFlatPartnerConnected,
      serverData,
    } = this.props;
    const { street, house } = this.state;

    if (!flat) return;

    checkFlatConnection({
      city: {
        id: currentRegion.id,
        label: currentRegion.label,
      },
      street: {
        id: streetId,
        label: street,
      },
      house: {
        id: houseId,
        label: house,
        isConnected: !isNotAvailable,
      },
      flat,
    }).then(({ connectionState, hasRequestOnAddress }) => {
      const { none, alreadyInUse, partnerConnected } = flatConnectionStates;
      const flatAlreadyUsed =
        hasRequestOnAddress ? connectionState === none : connectionState === alreadyInUse;
      setFlatUsedState(flatAlreadyUsed);
      setFlatPartnerConnected(connectionState === partnerConnected);

      if (flatAlreadyUsed) {
        const event = createGAPush('event_item_order_sent_double', serverData);
        this.props.pushToAnalytics(event, 'doubleOrder');
        pushFloatingCheckAction('address', 'flatNotAvailable');
      } else {
        pushFloatingCheckAction('address', 'addressFound');
      }
    });
  }, 500);

  resetNotAvailableHomeStatus = () => {
    this.houseInput.clear();
  };

  handleChangeFlat = (flat) => {
    if (!this.state.newFlatPushed) {
      this.setState({ newFlatPushed: true }, () => this.props.pushToAnalytics('NewFlat'));
    }
    this.setState({ flat });
    this.props.setFlat(flat);
    this.props.setFlatPartnerConnected(false);

    this.checkFlatConnection(flat);
  };

  handleRegionChange = (payload) => {
    this.setState({ region: payload.label, street: '', house: '', flat: '' });
    this.props.setFlatPartnerConnected(false);

    if (payload.id) {
      store.dispatch(getTariffData(payload.id));
    }
  };

  handleStreetChange = (payload) => {
    if (!this.state.newStreetPushed) {
      this.setState({ newStreetPushed: true }, () => this.props.pushToAnalytics('NewStreet'));
    }
    this.setState({ street: payload.label, house: '', flat: '' });
    this.props.setFlatPartnerConnected(false);
  };

  handleHouseChange = (payload, selected) => {
    if (!this.state.newHousePushed) {
      this.setState({ newHousePushed: true }, () => this.props.pushToAnalytics('NewHouse'));
    }
    if (selected) {
      this.props.pushToAnalytics('house', 'convForm', {
        eventLabel: `Дом ${payload.isConnected ? '' : 'не '}подключен`,
      });
    }
    this.setState({ house: payload.label });
    this.props.setFlatPartnerConnected(false);
  };

  render() {
    const {
      flatIsDisabled,
      flatAlreadyUsed,
      isFlatPartnerConnected,
      isNotAvailable,
      houseIsNotFound,
      streetIsNotFound,
      regionIsNotFound,
      className,
      regionStatus,
      streetStatus,
      houseStatus,
      flatStatus,
      street,
      houseData,
      texts,
      tariffNotFoundInRegion,
      tariffNotFoundInRegionMessage,
    } = this.props;
    const { street: streetName, flat } = this.state;

    return (
      <div className={cx('address', className)}>
        <InputsRow className={cx({ hidden: isNotAvailable })} groups={3}>
          <InputGroup className={cx('inputGroup')}>
            <Label>{Ctx.allInOne.requestForm.regionLabel}</Label>
            <Dropdown
              name="NewRegion"
              onChange={this.handleRegionChange}
              size="default"
              status={regionStatus}
              type="region"
              value={this.state.region}
            />
          </InputGroup>
          <InputGroup className={cx('inputGroup')}>
            <Label>{Ctx.allInOne.requestForm.streetLabel}</Label>
            <Dropdown
              inputRef={(e) => {
                this.streetInput = e;
              }}
              name="NewStreet"
              onChange={this.handleStreetChange}
              size="default"
              status={streetStatus}
              type="street"
              value={this.state.street}
            />
          </InputGroup>

          <InputGroup className={cx('inputGroup')}>
            <InputsRow className={['nested', 'small']} groups={2}>
              <InputGroup className={cx('number')}>
                <Label>{Ctx.allInOne.requestForm.homeLabel}</Label>
                <Dropdown
                  inputRef={(e) => {
                    this.houseInput = e;
                  }}
                  name="NewHouse"
                  onChange={this.handleHouseChange}
                  size="default"
                  status={houseStatus}
                  type="house"
                  value={this.state.house}
                />
              </InputGroup>
              <InputGroup className={cx('number')}>
                <Label>{Ctx.allInOne.requestForm.flatLabel}</Label>
                <TextInput
                  autoComplete="off"
                  disabled={flatIsDisabled || isNotAvailable}
                  mask="_____"
                  name="NewFlat"
                  onChange={this.handleChangeFlat}
                  ref={(e) => {
                    this.flatInput = e;
                  }}
                  size="default"
                  status={flatStatus}
                  value={this.state.flat}
                />
              </InputGroup>
            </InputsRow>
          </InputGroup>
        </InputsRow>

        {isNotAvailable && (
          <InputsRow>
            <Status
              className={['wide', 'attention', cx('notAvailableHomeStatus')]}
              emoji="statusFail"
            >
              <p>
                {Ctx.allInOne.requestForm.notAvailableHousePrefix}
                <Link onClick={this.resetNotAvailableHomeStatus}>
                  {street.label}, дом
                  {houseData.label}
                </Link>
                {Ctx.allInOne.requestForm.notAvailableHousePostfix}
              </p>
            </Status>
          </InputsRow>
        )}
        {tariffNotFoundInRegion && (
          <InputsRow>
            <Status
              className={['wide', 'attention']}
              emoji="statusFail"
              text={tariffNotFoundInRegionMessage}
            />
          </InputsRow>
        )}
        {regionIsNotFound && (
          <InputsRow>
            <Status className={['wide', 'attention']} emoji="statusFail">
              {Ctx.allInOne.requestForm.regionIsNotFound}
            </Status>
          </InputsRow>
        )}
        {!regionIsNotFound && streetIsNotFound && (
          <InputsRow>
            <Status className={['wide', 'attention']} emoji="statusFail">
              {Ctx.allInOne.requestForm.streetIsNotFound}
            </Status>
          </InputsRow>
        )}
        {!streetIsNotFound && houseIsNotFound && (
          <InputsRow>
            <Status className={['wide', 'attention']} emoji="statusFail">
              {Ctx.allInOne.requestForm.houseIsNotFound}
            </Status>
          </InputsRow>
        )}

        {flatAlreadyUsed && (
          <InputsRow>
            <Status className={['wide', 'attention']} emoji="statusFail">
              {texts.flatAlreadyUsed}
            </Status>
          </InputsRow>
        )}
        {isFlatPartnerConnected && (
          <InputsRow>
            <Status
              className={['wide', 'attention', cx('flatPartnerConnected')]}
              emoji="statusFail"
            >
              {templateParser(texts.flatPartnerConnected, {
                street: streetName,
                house: houseData && houseData.label,
                flat,
              })}
            </Status>
          </InputsRow>
        )}
      </div>
    );
  }
}

FormAddress.defaultProps = {
  isNotAvailable: false,
  houseIsNotFound: false,
  streetIsNotFound: false,
  regionIsNotFound: false,
  flatAlreadyUsed: false,
  isFlatPartnerConnected: false,
  className: '',
  flat: '',
};

FormAddress.propTypes = {
  setFlat: PropTypes.func.isRequired,
  flatIsDisabled: PropTypes.bool.isRequired,
  pushToAnalytics: PropTypes.func.isRequired,
  isNotAvailable: PropTypes.bool,
  houseIsNotFound: PropTypes.bool,
  streetIsNotFound: PropTypes.bool,
  regionIsNotFound: PropTypes.bool,
  serverData: PropTypes.shape({
    soc: PropTypes.string,
    mobileTariffName: PropTypes.string,
    feePerMonth: PropTypes.string,
    connectedInacFromParams: PropTypes.string,
  }),
  className: PropTypes.string,
  flat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentRegion: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number,
  }),
  texts: PropTypes.shape({
    flatAlreadyUsed: PropTypes.string,
    flatPartnerConnected: PropTypes.string,
  }),
  streetId: PropTypes.number,
  houseId: PropTypes.number,
  setFlatUsedState: PropTypes.func,
  setFlatPartnerConnected: PropTypes.func,
  isFlatPartnerConnected: PropTypes.bool,
  flatAlreadyUsed: PropTypes.bool,
  regionStatus: PropTypes.oneOf(['default', 'ok', 'fail']),
  streetStatus: PropTypes.oneOf(['default', 'ok', 'fail']),
  houseStatus: PropTypes.oneOf(['default', 'ok', 'fail']),
  flatStatus: PropTypes.oneOf(['default', 'ok', 'fail']),
  street: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number,
  }),
  houseData: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number,
  }),
  tariffNotFoundInRegion: PropTypes.bool,
  tariffNotFoundInRegionMessage: PropTypes.string,
};

const mapStateToProps = ({ external }) => {
  // reSelect
  const { allInOne = {} } = external;
  const street = path(['street'], allInOne) || {};
  const house = path(['house'], allInOne) || {};

  return {
    texts: path(['serverData', 'texts'], allInOne),
    flat: allInOne.flat,
    activeIndex: allInOne.activeIndex,
    isNotAvailable: house.id && !house.isConnected,
    houseIsNotFound: allInOne.houseIsNotFound,
    serverData: allInOne.serverData,
    flatIsDisabled: !path(['street', 'id'], allInOne) || !house.id,
    streetId: path(['street', 'id'], allInOne),
    houseId: path(['house', 'id'], allInOne),
    flatAlreadyUsed: allInOne.flatAlreadyUsed,
    isFlatPartnerConnected: allInOne.isFlatPartnerConnected,
    currentRegion: path(['region'], allInOne),
    houseData: house,
    street,
    tariffNotFoundInRegion: path(['tariffNotFoundInRegion'], allInOne) || false,
    tariffNotFoundInRegionMessage: path(['tariffNotFoundInRegionMessage'], allInOne) || '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFlat: (flat) =>
    dispatch({
      type: SET_ALLINONE_VALUE,
      resetError: true,
      payload: {
        flat,
      },
    }),
  setFlatUsedState: (flatAlreadyUsed) =>
    dispatch({
      type: SET_ALLINONE_VALUE,
      payload: { flatAlreadyUsed },
    }),
  setFlatPartnerConnected: (isFlatPartnerConnected) =>
    dispatch({
      type: SET_ALLINONE_VALUE,
      payload: { isFlatPartnerConnected },
    }),
});

const ConnectedFormAddress = connect(mapStateToProps, mapDispatchToProps)(FormAddress);

export default (props) => (
  <Provider store={store}>
    <ConnectedFormAddress {...props} />
  </Provider>
);
