import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Скелетон на время запроса пресетов для кмпонента TotalSum */
export const LoadingTotalSum: FC = () => <Skeleton className={cx('wrapper')} />;
