import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';
import {
  setDefaultInfoAndSlides,
  toggleUpsellOffer,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff/actions';
import DispatchContext from 'pages/ProductCard/Tariff/containers/ConstructorTariff/constants';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const UpSell = ({ content, isUpsellSwitch, getUpsellOfferInfo }) => {
  const _dispatch = useContext(DispatchContext);
  const onChange = () => {
    _dispatch(toggleUpsellOffer());
    /** Возвращает defaultInfo при отключении на toggle */
    if (isUpsellSwitch) {
      _dispatch(setDefaultInfoAndSlides());
    }
  };

  useEffect(() => {
    if (isUpsellSwitch) {
      getUpsellOfferInfo();
    }
  }, [isUpsellSwitch]);

  return (
    <div className={cx('upsell')}>
      <div className={cx('upsell__left')}>
        <div className={cx('upsell__title')}>
          <Icon className={cx('upsell__icon')} src={content?.icon} />
          <Text size="size3-m" tagType="h2">
            {content?.title}
          </Text>
        </div>
        <Text color="grey50" size="size5-r-s" tagType="div">
          {content?.description}
        </Text>
      </div>
      <div className={cx('upsell__right')}>
        <Switch checked={isUpsellSwitch} onChange={onChange} />
      </div>
    </div>
  );
};

UpSell.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  isUpsellSwitch: PropTypes.bool,
  getUpsellOfferInfo: PropTypes.func,
};
