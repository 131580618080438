import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { FmcRegionInput } from './components/FmcRegionInput';
import { FmcStreetInput } from './components/FmcStreetInput';
import { FmcHouseInput } from './components/FmcHouseInput';
import { FmcFlatInput } from './components/FmcFlatInput';
import { FmcCheckPhoneInput } from './components/FmcCheckPhoneInput';

const cx = classNames.bind(styles);

export const FmcAddressForm = () => (
  <div className={cx('wrapper')}>
    <FmcRegionInput />
    <FmcStreetInput />
    <div className={cx('columns')}>
      <FmcHouseInput />
      <FmcFlatInput />
    </div>
    <FmcCheckPhoneInput />
  </div>
);
