export const buttonRole = {
  link: 'link',
  popup: 'popup',
};
export const validationUrl = '/customers/products/mobile/services/validatemnporder/';
export const createMnpOrderUrl = '/customers/products/mobile/services/createmnporder/';
export const errorTextContent = {
  notSuccess: 'Упс, что-то пошло не так',
  failedRequest: 'При отправке запроса произошла ошибка, наши специалисты уже работают над этим',
  notFilledCtn: 'Введите номер телефона',
  isExistingCtn: 'Этот номер уже в билайн. Введите другой',
  isCtnAlreadyInBasket: 'Упс! что-то пошло не так, ваш номер уже в корзине',
};
export const statuses = {
  error: 'error',
  pending: 'pending',
  notAllowSubmit: 'notAllowSubmit',
  success: 'success',
};
export const mnpVkEventGoal = 'sim_mnp_basket';

/* PEB-4891: Значение захардкожено для корректного формирования запроса корзины в formatOrderData.
   Значение "1" соответствует дефолтному тарифу. Согласовано с аналитиком */
export const DEFAULT_CONSTRUCTOR_ID = 1;
