import axios from 'axios';

import { apiCreateMnpOrder } from './constants';

export interface ICreateMNPOrderResponse {
  Errors: Record<string, unknown>;
  IsSucceeded: boolean;
  View: IViewCreateMNPOrder;
  camelCase: boolean;
}

export interface IViewCreateMNPOrder {
  Options: string[];
  OrderId?: string;
  OrderPlace: number;
  Price?: number;
  ProductId?: number;
  ShopSlug: string;
  StartBalance?: number;
  TariffId: number;
}

/**
 * Создание заказа на перенос номера
 */
export const createMNPOrder = async (phone: string, region: number) => {
  try {
    const { data } = await axios.post<ICreateMNPOrderResponse>(apiCreateMnpOrder, {
      leadName: 'PodborSim',
      phone,
      region,
    });

    return data;
  } catch (error) {
    return String(error);
  }
};
