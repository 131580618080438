import { useEffect, useState } from 'react';

import { useBannerProps } from 'pages/Services/RedirectServiceCardLanding/components/Banner/types';

// кастомный хук для баннера
export const useBanner = ({
  loadingInitialData,
  isAuthenticated,
  contentNotification,
}: useBannerProps) => {
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [isVisibleCancelModal, setVisibleCancelModal] = useState(false);

  const [notifications, setNotifications] = useState<
    {
      id: number;
      text: string;
    }[]
  >();

  const onCloseModal = () => {
    setVisibleModal(false);
  };

  const onOpenModal = () => {
    setVisibleModal(true);
  };

  const onOpenCancelModal = () => {
    setVisibleCancelModal(true);
  };

  const onCloseCancelModal = () => {
    setVisibleCancelModal(false);
  };

  const onCloseNotifications = () => {
    setNotifications([]);
  };

  // при первичной отрисовке показываем нотификацию о загрузку настроек редиректа
  useEffect(() => {
    if (contentNotification?.text && isAuthenticated) {
      setNotifications([
        {
          id: 1,
          text: contentNotification?.text,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    // Если есть нотификация и данные настроек получилили, то закрываем нотификацию
    if (notifications && notifications?.length > 0 && loadingInitialData === 'succeeded') {
      onCloseNotifications();
    }
  }, [loadingInitialData, notifications]);

  return {
    isVisibleModal,
    isVisibleCancelModal,
    onCloseCancelModal,
    onOpenCancelModal,
    onOpenModal,
    onCloseModal,
    notifications,
    onCloseNotifications,
  };
};
