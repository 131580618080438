import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { useIsDesktopDimension } from '@beef/ui-kit';

import { Metric } from 'pages/CarnicaDSLandings/MNPLanding/analytics';

import { IHoveredIcons, IOtherItem } from '../../../../../store/slices/commonSlice/types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IHoveredCardProps extends IOtherItem {
  bottomIconClassName?: string;
  icons: Partial<IHoveredIcons>;
  rightDownIconClassName?: string;
  rightTopIconClassName?: string;
  topLeftIconClassName?: string;
}

/**
 * Компонент карточки с ховер-эффектом
 */
export const HoveredCard: FC<IHoveredCardProps> = ({
  icons: { BottomIcon, LeftTopIcon, RightDownIcon, RightTopIcon },
  topLeftIconClassName,
  bottomIconClassName,
  rightTopIconClassName,
  rightDownIconClassName,
  description,
  title,
}) => {
  const isDesktop = useIsDesktopDimension();
  return (
    <Metric data={title} events={{ yaMNPMetric: ['handleViewHoveredCard'] }} mode="viewport">
      <div className={cx('container')}>
        <Metric data={title} events={{ yaMNPMetric: ['handleClickHoveredCard'] }} mode="click">
          <div className={cx('card')}>
            {LeftTopIcon && isDesktop && (
              <div className={cx('icon', topLeftIconClassName)}>
                <LeftTopIcon />
              </div>
            )}

            <Text className={cx('title')} variant="body-medium">
              {title}
            </Text>

            <Text color="secondary" variant="body-small">
              {description}
            </Text>

            {RightDownIcon && (
              <div className={cx('right-down-icon', rightDownIconClassName)}>
                <RightDownIcon />
              </div>
            )}

            {BottomIcon && (
              <div className={cx('bottom-icon', bottomIconClassName)}>
                <BottomIcon />
              </div>
            )}

            {RightTopIcon && (
              <div className={cx('right-top-icon', rightTopIconClassName)}>
                <RightTopIcon />
              </div>
            )}
          </div>
        </Metric>
      </div>
    </Metric>
  );
};
