import PropTypes from 'prop-types';

/* абонентская плата */
const priceShape = PropTypes.shape({
  unit: PropTypes.string,
  value: PropTypes.string,
});

/* бенефит тарифа */
const benefitsShape = PropTypes.shape({
  icon: PropTypes.string,
  text: PropTypes.string,
});

/* элемент блока цены */
const priceItemShape = PropTypes.shape({
  label: PropTypes.string,
  /* тултип */
  legal: PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string,
  }),
  value: PropTypes.number,
});

/* пакет тарифа */
const packageItemShape = PropTypes.shape({
  unit: PropTypes.string,
  value: PropTypes.string,
});

/* данные для подписки */
const subscriptionShape = PropTypes.shape({
  content: PropTypes.object,
  data: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    unit: PropTypes.string,
    value: PropTypes.string,
  }),
});

/* полезные ссылки */
const usefulLinkShape = PropTypes.shape({
  text: PropTypes.string,
  href: PropTypes.string,
  pageLink: PropTypes.bool,
  note: PropTypes.string,
});

/* карточка управления тарифа */
const controlCardContentShape = PropTypes.shape({
  icon: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  href: PropTypes.string,
  linkText: PropTypes.string,
});

export {
  benefitsShape,
  controlCardContentShape,
  packageItemShape,
  priceItemShape,
  priceShape,
  subscriptionShape,
  usefulLinkShape,
};
