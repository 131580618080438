import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Discount from 'pages-components/Discount';
import TextWithRegionContainer from 'pages/FTTB/CatalogPage/containers/TextWithRegionContainer';
import TariffsListContainer from 'pages/FTTB/CatalogPage/containers/TariffsListContainer';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffsCatalog = ({ theme, data, discountText, headingId, forceShowBackCallPopup }) => {
  const { title, description, discountDescription } = data;

  return (
    <div className={cx('wrapper', theme)}>
      <Heading className={cx('title')} level={1} tagName="div">
        <TextWithRegionContainer id={headingId} text={title} />
      </Heading>

      {(description || (discountText && discountDescription)) && (
        <div className={cx('description')}>
          {discountText && <Discount.Label className={cx('discount')} label={discountText} />}
          <span
            dangerouslySetInnerHTML={{ __html: discountText ? discountDescription : description }}
          />
        </div>
      )}

      <TariffsListContainer forceShowBackCallPopup={forceShowBackCallPopup} />
    </div>
  );
};

TariffsCatalog.defaultProps = {
  theme: 'light',
};
TariffsCatalog.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  discountText: PropTypes.string,
  headingId: PropTypes.string,

  forceShowBackCallPopup: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    discountDescription: PropTypes.string,
    benefits: PropTypes.arrayOf(
      PropTypes.shape({
        alias: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }),
};

export default TariffsCatalog;
