import { useAppSelector } from '../../../../store';

/** Селектор выборки контента для плиток доп. сервисов (семья) */
export const useSelectContent = () => {
  const extraServicesTitle = useAppSelector(
    (state) => state.common?.content.extraServicesTitle || '',
  );

  return { extraServicesTitle };
};
