import { createAsyncThunk } from '@reduxjs/toolkit';

import { getFmcActiveServices } from '../../../../utils/getFmcActiveServices';
import { countConnectFttbPrice } from '../../../../utils/countConnectFttbPrice';
import { setActiveInacId, setConvergencePrice, setDefaultActiveServices } from '../index';
import { DEFAULT_TAB_ID } from '../../../../components/constructorPage/FmcTabsContainer/constants';

/** Вспомогательный экшен для одновременного изменения активного таба ШПД (зависит от inacId)
 * и сбора inacId default-активных сервисов */
export const changeFmcInacId = createAsyncThunk<string, string>(
  'total/setFmcInacIdAndServicesList',
  (inacId, { dispatch, getState }) => {
    /* Устанавливаем активный таб сервиса по inacId */
    dispatch(setActiveInacId(inacId));

    /* Если пользователь переключили таб ШДП на главной странице конструктора в вариант "Без интернета",
     сбрасываем все настроенные inacId */
    if (inacId === DEFAULT_TAB_ID) {
      dispatch(setDefaultActiveServices([]));
    }

    /* Определяем текущий просматриваемый вариант сервисов по активному inacId */
    /* @ts-expect-error ошибка от getState из-за некорректной типизации стора (смотреть комменты у создания стора) */
    const currentTariff = getState().fttbPresets.tariffs.find((tariff) => tariff.inacId === inacId);

    /* Определяем наличие подключенного inacId ШПД */
    /* @ts-expect-error ошибка от getState из-за некорректной типизации стора (смотреть комменты у создания стора) */
    const currentConnectInacId = getState().common.userInfo.convergenceConnectedInfo?.inacId || '';

    /* Если currentTariff присутствует, продолжаем проверку */
    if (currentTariff) {
      /* Собираем все значения объекта currentTariff в массив для дальнейшего маппинга */
      const currentTariffServices = Object.values(currentTariff);

      /* Выбираем inacId всех активных сервисов. Сервисы могут быть всех возможных типов */
      const defaultActiveServices = getFmcActiveServices(currentTariffServices);

      /* Если у пользователя есть подключенный ШПД (currentConnectInacId),
       высчитываем стоимость ШПД и записываем в total */
      if (currentConnectInacId) {
        /* Считаем стоимость ШПД (интернет + сервисы) */
        const { connectConvergencePrice, connectConvergenceOldPrice } = countConnectFttbPrice({
          currentTariff,
          servicesInacIdList: defaultActiveServices,
        });

        /* Записываем стоимость ШПД в total */
        dispatch(
          setConvergencePrice({
            price: connectConvergencePrice,
            oldPrice: connectConvergenceOldPrice,
          }),
        );
      }

      /* Записываем все активные inacId в массив servicesInacIdList */
      dispatch(setDefaultActiveServices(defaultActiveServices));
    }

    return inacId;
  },
);
