import React from 'react';
import PropTypes from 'prop-types';
import { Button, Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Statuses from './components/Statuses';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ButtonWithPreloader = ({
  children,
  isPreloader,
  status,
  statusText,
  buttonText,
  preloaderClassName,
  ...buttonProps
}) => (
  <>
    {isPreloader && (
      <div className={cx('preloader', preloaderClassName)}>
        <Preloader size="40" />
      </div>
    )}

    {!isPreloader && (
      <>
        {status ?
          <Statuses modifier={status} text={statusText} />
        : <Button {...buttonProps}>
            <span className={cx('buttonText')}>{children || buttonText}</span>
          </Button>
        }
      </>
    )}
  </>
);

ButtonWithPreloader.propTypes = {
  children: PropTypes.any,
  isPreloader: PropTypes.bool,
  status: PropTypes.any,
  statusText: PropTypes.string,
  buttonText: PropTypes.string,
  preloaderClassName: PropTypes.any,
  buttonProps: PropTypes.object,
};

export default ButtonWithPreloader;
