import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Components
import { pathOr } from 'ramda';

import Ctx from 'pages-components/Ctx';

// Mapper
import { getFavoriteNumberState, getTilesParams } from './mapper';

const FAVORITE_NUMBER_API_URL = '/favoritenumberapi/';

class FavoriteNumberServiceCardHoc extends Component {
  state = {
    isPagePreloader: true,
    favoriteNumber: null,
    isModalOpened: false,
    productParameters: null,
    modalData: null,
  };

  componentDidMount() {
    this.getFavoriteNumberData();
  }

  getFavoriteNumberData = () => {
    const state = this.props.store.getState();
    const ctn = pathOr('', ['external', 'authorization', 'authCtn'], state);

    // Запрос на получение любимого номера;
    // Если любимый номер пришел, отрисовываем его
    // в верхней части контента страницы;
    axios
      .get(FAVORITE_NUMBER_API_URL)
      .then(({ data }) =>
        this.setState({
          isPagePreloader: false,
          ...getFavoriteNumberState(data),
          productParameters: getTilesParams(this.props.data, data),
          ctn,
        }),
      )
      .catch((err) => {
        this.setState({ isPagePreloader: false });
        console.error('Ошибка в get запросе получении favoriteNumber: ', err);
      });
  };

  /** Управление отображением модального окна */
  toggleModal = () =>
    this.setState((prevState) => ({
      isModalOpened: !prevState.isModalOpened,
    }));

  render() {
    const { children, data } = this.props;
    const {
      isPagePreloader,
      favoriteNumber,
      isModalOpened,
      modalData,
      favoriteNumberContent,
      productParameters,
      pageSubTitle,
      ctn,
    } = this.state;

    return cloneElement(children, {
      favoriteNumber,
      favoriteNumberContent,
      isPagePreloader,
      productParameters,
      subTitle: Ctx.service.defaultSubTitle,
      description: pageSubTitle,
      toggleModal: this.toggleModal,
      getFavoriteNumberData: this.getFavoriteNumberData,
      title: data.pageTitle,
      opened: isModalOpened,
      modalData: { ...modalData, ctn },
    });
  }
}

FavoriteNumberServiceCardHoc.propTypes = {
  data: PropTypes.shape({
    productParameters: PropTypes.array,
    pageSubtitle: PropTypes.string,
    pageTitle: PropTypes.string,
  }),
};

export default FavoriteNumberServiceCardHoc;
