import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

// Components
import { TabsItemContainer } from './TabsItemContainer';
import { DiscountBlock } from '../../../../../ConvergenceBlock/DiscountBlock';
// Selectors
import {
  getAccessTariff,
  selectDiscountDescription,
  selectIsConnectedUserFlag,
  selectTariffTabsTitle,
} from '../../../../store/selectors/orderTariffDetailsData';
// PropTypes
import { tariffShape } from '../../../../PropTypes';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TabsContainerLayout = ({ title, discountDescription, tariffs, isConnectedUser }) => (
  <div className={cx('container')}>
    <Text size="size3-m" tagType="h3">
      {title}
    </Text>
    <TabsItemContainer data={tariffs} />
    {discountDescription && !isConnectedUser && (
      <DiscountBlock discountDescription={discountDescription} />
    )}
  </div>
);

const mapStateToProps = (state) => ({
  tariffs: getAccessTariff(state),
  title: selectTariffTabsTitle(state),
  discountDescription: selectDiscountDescription(state),
  isConnectedUser: selectIsConnectedUserFlag(state),
});

TabsContainerLayout.propTypes = {
  tariffs: PropTypes.arrayOf(tariffShape),
  title: PropTypes.string,
  discountDescription: PropTypes.string,
  isConnectedUser: PropTypes.bool,
};

export const TabsContainer = connect(mapStateToProps, null)(TabsContainerLayout);
