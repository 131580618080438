import React, { useRef } from 'react';
import classnames from 'classnames/bind';
import { Modal } from '@beef/ui-kit';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import { Controls } from './Controls';
import { ImageSlide } from '../ImageSlide';
import { Image } from '../types';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

type Props = {
  images: Image[];
  initialSlide?: number;
  isOpen: boolean;
  onClose: () => void;
};

export const FullView = ({ isOpen, images, initialSlide = 0, onClose }: Props) => {
  const slider = useRef<SwiperClass | null>(null);

  return (
    <Modal isCloseButtonVisible={false} isNoPadding isOpen={isOpen} onClose={onClose} theme="dark">
      <div className={cx('wrap')}>
        <Swiper
          centeredSlides
          className={cx('gallery')}
          draggable
          initialSlide={initialSlide}
          loop
          observeParents
          // Have to pass these two properties to fix Swiper initialization inside fixed container
          observer
          onInit={(swiperInstance) => {
            slider.current = swiperInstance;
          }}
          slidesPerView="auto"
          spaceBetween={24}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <ImageSlide alt={image.alt} src={image.src} />
            </SwiperSlide>
          ))}
        </Swiper>
        <Controls onClose={onClose} slider={slider} />
      </div>
    </Modal>
  );
};
