import React, { memo } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ChangeTariffBlock = memo(({ title, chargeAmount, chargeAmountUnit }) => (
  <div className={cx('wrapper')}>
    {title && (
      <Text color="grey95" size="size4-m">
        {title}
      </Text>
    )}
    {chargeAmount && chargeAmountUnit && (
      <Text color="grey95" size="size5-r-s">
        {`${chargeAmount} ${chargeAmountUnit}`}
      </Text>
    )}
  </div>
));

ChangeTariffBlock.propTypes = {
  title: PropTypes.string,
  chargeAmount: PropTypes.number,
  chargeAmountUnit: PropTypes.string,
};
