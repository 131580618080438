import React, { useState } from 'react';
import { InfoBanner } from '@beef/smart-kit';
import { Button } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { RecursivePartial } from 'utils/types';

import { TBroadBandInternetPopupProps } from '../../types';
import { BroadBandInternetModal } from './components/Modal/index';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const CustomBanner: React.FC<
  Record<string, unknown> & {
    button: {
      children: string;
    };
    popup: RecursivePartial<TBroadBandInternetPopupProps>;
  }
> = ({ popup, button, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={cn('wrapper')}>
        <InfoBanner {...props}>
          <Button
            {...button}
            onClick={() => {
              setIsOpen(true);
            }}
          />
        </InfoBanner>
      </div>
      <BroadBandInternetModal isOpen={isOpen} onClose={() => setIsOpen(false)} popup={popup} />
    </>
  );
};
