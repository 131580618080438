import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AutocompleteInput, Button, Emoji, Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { currentStateList, gaEventLabel } from 'pages/FTTB/constants';
import { GET_FTTB_CONNECTION_STATE_END, setAndSaveConnectionState } from 'pages/FTTB/actions';
import { getConnectionData } from 'pages/FTTB/selectors/connectionState';
import getHousesForStreet from 'pages/FTTB/services/getHousesForStreet';
import getConnectionState from 'pages/FTTB/services/getConnectionState';
import QuickOrderPopup from 'pages/FTTB/components/QuickOrderPopup';
import PopupRequestHouse from 'pages/AllInOneForm/components/PopupRequestHouse/PopupRequestHouse';
import Picture from 'pages-components/Picture';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const itemCaptionExtractor = (item) => item.label;
const valuesFilter = (value, items) => items;

class HouseConnection extends Component {
  state = {
    currentState: currentStateList.requestForm,
    houses: [],
    showQuickOrderPopup: false,
    showPopupRequestHouse: false,
    quickOrderUsed: false,
  };

  componentDidMount() {
    const { currentCity, street } = this.props;

    this.props.setFTTBConnectionData({ city: currentCity, street });
    getConnectionState().then((connectionData) => {
      if ((connectionData.street.id || null) === street.id) {
        this.props.setFTTBConnectionData(connectionData);
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { connectionData } = this.props;
    const { connectionData: prevConnectionData } = prevProps;

    if (connectionData !== prevConnectionData) {
      this.onConnectionDataChange();
    }
  }

  onConnectionDataChange() {
    const { connectionData } = this.props;

    if (connectionData.house) {
      this.setState({
        currentState:
          connectionData.house.isConnected ? currentStateList.success : currentStateList.fail,
      });
    }
  }

  onChangeAddressClick = () => this.setState({ currentState: currentStateList.requestForm });

  onHouseChange = (house) => {
    const { currentCity, street } = this.props;

    this.props.setAndSaveConnectionState({ city: currentCity, street, house });
  };

  fetchHouses = (term) => {
    getHousesForStreet(this.props.street.id, term).then(({ data }) => {
      this.setState({ houses: data });
    });
  };

  closeQuickOrderPopup = () => this.setState({ showQuickOrderPopup: false });

  showQuickOrderPopup = () => this.setState({ showQuickOrderPopup: true });

  showPopupRequestHouse = () => this.setState({ showPopupRequestHouse: true });

  closePopupRequestHouse = () => this.setState({ showPopupRequestHouse: false });

  quickOrderPopupSuccess = (resetPhoneInput) => {
    this.setState({ quickOrderUsed: true });
    resetPhoneInput();
  };

  renderForm() {
    const { houses } = this.state;
    const {
      currentCity,
      formTitlePrefix,
      formTitlePostfix,
      toMainStreetPageLinkText,
      street,
      houseInputText,
      defaultImage,
      streetsUrl,
    } = this.props;

    return (
      <div className={cx('formWrapper')}>
        <Heading className={cx('title')} level={1}>
          <span dangerouslySetInnerHTML={{ __html: formTitlePrefix }} />
          {`${currentCity.label}, `}
          <span dangerouslySetInnerHTML={{ __html: street.label }} />
          <span dangerouslySetInnerHTML={{ __html: formTitlePostfix }} />
        </Heading>

        <div className={cx('houseInputWrapper')}>
          <div
            className={cx('houseInputText')}
            dangerouslySetInnerHTML={{ __html: houseInputText }}
          />

          <AutocompleteInput
            className={cx('houseInput')}
            filter={valuesFilter}
            itemCaptionExtractor={itemCaptionExtractor}
            items={houses}
            onChange={this.onHouseChange}
            onFetchRequested={this.fetchHouses}
            placeholder="Дом"
          />
        </div>

        <div className={cx('imageWrapper')}>
          <Picture className={cx('image')} src={defaultImage} />
        </div>

        <div className={cx('mainStreetsPageLink')}>
          <Link className="hideVisitedColor" href={streetsUrl}>
            <span dangerouslySetInnerHTML={{ __html: toMainStreetPageLinkText }} />
          </Link>
        </div>
      </div>
    );
  }

  renderSuccess() {
    const {
      successTitlePrefix,
      successTitlePostfix,
      street,
      connectBtnText,
      connectionUsedLinkText,
      successImage,
      connectionData,
    } = this.props;
    const { quickOrderUsed } = this.state;

    const { house } = connectionData || {};

    return (
      <div className={cx('successWrapper')}>
        <Heading className={cx('title')} level={1}>
          <span dangerouslySetInnerHTML={{ __html: successTitlePrefix }} />
          <Link onClick={this.onChangeAddressClick}>{`${street.label}, ${house.label}`}</Link>
          <span dangerouslySetInnerHTML={{ __html: successTitlePostfix }} />
          <Emoji className={cx('emoji')} name="smile-happy" />
        </Heading>

        <div className={cx('imageWrapper')}>
          <Picture className={cx('image')} src={successImage} />
        </div>

        <div className={cx('connectBtn')}>
          {quickOrderUsed ?
            <Link className={cx('connectionUsedLink')} onClick={this.showQuickOrderPopup}>
              <span dangerouslySetInnerHTML={{ __html: connectionUsedLinkText }} />
            </Link>
          : <Button adaptive className="big" onClick={this.showQuickOrderPopup}>
              <span dangerouslySetInnerHTML={{ __html: connectBtnText }} />
            </Button>
          }
        </div>
      </div>
    );
  }

  renderFail() {
    const {
      failTitle,
      failDescriptionPrefix,
      failDescriptionPostfix,
      failButtonText,
      street,
      connectionData,
      failImage,
    } = this.props;

    const { house } = connectionData || {};

    return (
      <div className={cx('failWrapper')}>
        <Heading className={cx('title')} level={1}>
          <span dangerouslySetInnerHTML={{ __html: failTitle }} />
          <Emoji className={cx('emoji')} name="smile-sad" />
        </Heading>

        <div className={cx('failDescription')}>
          <span dangerouslySetInnerHTML={{ __html: failDescriptionPrefix }} />
          <Link onClick={this.onChangeAddressClick}>{`${street.label}, ${house.label}`}</Link>
          <span dangerouslySetInnerHTML={{ __html: failDescriptionPostfix }} />
        </div>

        <div className={cx('imageWrapper')}>
          <Picture className={cx('image')} src={failImage} />
        </div>

        <div className={cx('connectHouseBtn')}>
          <Button adaptive className="big" onClick={this.showPopupRequestHouse}>
            <span dangerouslySetInnerHTML={{ __html: failButtonText }} />
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { quickOrderPopupTexts, street, showCaptcha, connectionData } = this.props;
    const { currentState, showQuickOrderPopup, showPopupRequestHouse } = this.state;

    const { house } = connectionData || {};

    const additionalData =
      currentState === currentStateList.success ?
        {
          House: house.label,
          HouseId: house.id,
          IsConnected: house.isConnected,
          Street: street.label,
          StreetId: street.id,
        }
      : {};

    return (
      <div className={cx('wrapper')}>
        {currentState === currentStateList.requestForm && this.renderForm()}
        {currentState === currentStateList.success && this.renderSuccess()}
        {currentState === currentStateList.fail && this.renderFail()}

        <QuickOrderPopup
          additionalData={additionalData}
          featureEnabled
          gaEventLabel={gaEventLabel.getBeeline}
          isOpen={showQuickOrderPopup}
          onClose={this.closeQuickOrderPopup}
          onSuccess={this.quickOrderPopupSuccess}
          showCaptcha={showCaptcha}
          texts={quickOrderPopupTexts}
        />

        <PopupRequestHouse
          house={house}
          onClose={this.closePopupRequestHouse}
          popupIsOpen={showPopupRequestHouse}
          street={street}
        />
      </div>
    );
  }
}

HouseConnection.propTypes = {
  formTitlePrefix: PropTypes.string,
  formTitlePostfix: PropTypes.string,
  successTitlePrefix: PropTypes.string,
  successTitlePostfix: PropTypes.string,
  failTitle: PropTypes.string,
  failButtonText: PropTypes.string,
  failDescriptionPrefix: PropTypes.string,
  failDescriptionPostfix: PropTypes.string,
  connectionUsedLinkText: PropTypes.string,
  houseInputText: PropTypes.string,
  connectBtnText: PropTypes.string,
  connectionData: PropTypes.object,
  defaultImage: PropTypes.string,
  successImage: PropTypes.string,
  failImage: PropTypes.string,
  toMainStreetPageLinkText: PropTypes.string,

  showCaptcha: PropTypes.bool,
  setFTTBConnectionData: PropTypes.func,
  setAndSaveConnectionState: PropTypes.func,
  quickOrderPopupTexts: PropTypes.object,
  currentCity: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  }),
  streetsUrl: PropTypes.string,
  street: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  connectionData: getConnectionData(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFTTBConnectionData: (data) =>
    dispatch({ type: GET_FTTB_CONNECTION_STATE_END, payload: { connectionData: data } }),
  setAndSaveConnectionState: (data) => dispatch(setAndSaveConnectionState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HouseConnection);
