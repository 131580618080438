import React from 'react';
import PropTypes from 'prop-types';
import { Status, Tooltip } from '@beef/legacy-ui-kit';

// Styles
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import tileStyle from '../styles.pcss';

const cx = classNames.bind(styles);
const tileStyleCx = classNames.bind(tileStyle);

/**
 * @desc: Информационные виджет с текстом на всю ширину блока;
 * @param className - Дополнительный класс;
 * @param value - Текст внутри блока;
 * @param icon - Иконка рядом с текстом;
 * @param absolutePrice - Цена;
 * @param unit - Единица измерения;
 * @param toolTip - Текст тултипа;
 * @param connected - Подключен;
 */
const Info = ({
  className,
  value,
  icon,
  connected,
  absolutePrice,
  unit,
  toolTip,
  isLayoutYoung,
}) => (
  <div className={tileStyleCx('tiles', className)}>
    <div className={cx('content')}>
      {value && (
        <div
          className={cx('text', isLayoutYoung && 'layoutYoung')}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      )}
      {icon && (
        <div className={cx('iconsWrap')}>
          <div className={cx('icon')}>
            <img alt="" src={icon} />
          </div>
        </div>
      )}
      {(absolutePrice || unit) && (
        <div className={cx('cost')}>
          <span>
            {absolutePrice} {unit}
          </span>
          {toolTip && (
            <Tooltip className={cx('tooltip')}>
              <span dangerouslySetInnerHTML={{ __html: toolTip }} />
            </Tooltip>
          )}
        </div>
      )}
      {connected && (
        <div className={cx('successWrap')}>
          <Status className={`success ${cx('success')}`}>Подключено</Status>
        </div>
      )}
    </div>
  </div>
);

Info.propTypes = {
  value: PropTypes.string,
  icon: PropTypes.string,
  absolutePrice: PropTypes.string,
  unit: PropTypes.string,
  toolTip: PropTypes.string,

  connected: PropTypes.bool,
  isLayoutYoung: PropTypes.bool,
};

export default Info;
