import { createReducer } from '@reduxjs/toolkit';

// Actions
import {
  callBackRequestLoading,
  setBackToPreviousStep,
  setButtonText,
  setCallBackStatus,
  setCurrentStep,
  setIsFetchRegion,
  setIsSettled,
  setLoadingStatus,
  setSecondButtonText,
} from '../actions/modal';

// Constants
import { btnTexts, popupSteps } from '../../constants';

export const modal = createReducer(
  {
    isSettled: true,
    isLoading: false,
    isCallbackLoading: false,
    callBackRequestStatus: 'fail',
    isFetchRegion: false,
    currentStep: popupSteps.addressRequestForm,
    buttonText: btnTexts.next,
    secondButtonText: '',
  },
  {
    [setIsSettled.type]: (state, { payload }) => {
      state.isSettled = payload;
    },
    [setIsFetchRegion.type]: (state, { payload }) => {
      state.isFetchRegion = payload;
    },
    [callBackRequestLoading.type]: (state, { payload }) => {
      state.callBackRequestStatus = payload;
    },
    [setCallBackStatus.type]: (state, { payload }) => {
      state.isCallbackLoading = payload;
    },
    [setLoadingStatus.type]: (state, { payload }) => {
      state.isLoading = payload;
    },
    [setBackToPreviousStep.type]: (state) => ({
      ...state,
      currentStep: state.currentStep - 1,
    }),
    [setCurrentStep.type]: (state, { payload }) => {
      state.currentStep = payload;
    },
    [setButtonText.type]: (state, { payload }) => {
      state.buttonText = payload;
    },
    [setSecondButtonText.type]: (state, { payload }) => {
      state.secondButtonText = payload;
    },
  },
);
