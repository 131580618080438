import { ymPushParams } from 'utils/ym';

export const CATEGORY = {
  spetsialnyie: 'Бесплатный',
  bronzovie: 'Бронзовый',
  serebryanie: 'Серебряный',
  zolotie: 'Золотой',
  'zolotie-vip': 'Золотой-VIP',
  'zolotie-premium': 'Золотой-Premium',
  platinovie: 'Платиновый',
};

export const FILTER = {
  'lyubimoe-chislo': 'Любимое число',
  'krasiviy-hvost': 'Красивый хвост',
  'pohozhiy-nomer': 'Похожий номер',
  random: 'Случайный',
};

export const pushSearchChange = (filter) => {
  ymPushParams({ sim_krasivie_nomera_ev_: { search: { apply: FILTER[filter] } } });
};

export const pushDefCodeChange = () => {
  ymPushParams({ sim_krasivie_nomera_ev_: { search: 'select_code' } });
};

export const pushStatusShown = (status, query) => {
  ymPushParams({ sim_krasivie_nomera_ev_: { search: { [status]: query } } });
};

export const pushNextRandomClick = (category) => {
  ymPushParams({ sim_krasivie_nomera_ev_: { random_number: CATEGORY[category] } });
};

export const pushShowMore = () => {
  ymPushParams({ sim_krasivie_nomera_ev_: 'show_more' });
};
