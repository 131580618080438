import axios, { AxiosRequestConfig } from 'axios';
import qs from 'query-string';
import { createAsyncThunk } from '@reduxjs/toolkit';

export type TCheckConnectionReq = {
  checkAddressType: number;
  city: string;
  cityId: number;
  flat: string;
  house: string;
  houseId: number;
  isConnected: boolean;
  isConvergentRequest: boolean;
  street: string;
  streetId: number;
};

/* Коды ConnectionState
 * 0 – не прошла проверка по дому
 * 1 – доступно подключение
 * 2 - уже подключено
 * 3 – нет возможности
 * 4 – ошибка */
export enum EConnectionCheckResult {
  ALREADY_CONNECTED = 2,
  AVAILABLE_TO_CONNECT = 1,
  CHECK_ERROR = 4,
  HOME_FAILED_VERIFICATION = 0,
  NOT_AVAILABLE_TO_CONNECT = 3,
}

export type TCheckConnectionRes = {
  Errors: Record<any, any>;
  IsSucceeded: boolean;
  View: {
    CallCenterWorkEndTime: any;
    CallCenterWorkStartTime: any;
    ConnectionState: EConnectionCheckResult;
    HasRequestOnAddress: boolean;
    HighSpeedAvailable: number;
    IsHasRequestConvergent: boolean;
  };
  camelCase: boolean;
};

/** Функция проверки доступности подключения по адресу пользователя (регион, улица, дом, квартира) */
export const getCheckConnection = createAsyncThunk<TCheckConnectionRes, TCheckConnectionReq>(
  'constructorApi/checkConnection',
  async (params, { signal, rejectWithValue }) => {
    try {
      const requestData = qs.stringify(params);
      const response = (
        await axios.post<TCheckConnectionRes>(
          '/customers/products/home/request/checkconnection/',
          requestData,
          {
            'X-Requested-With': 'XMLHttpRequest',
            signal,
          } as AxiosRequestConfig,
        )
      ).data;

      if (!response.IsSucceeded) {
        throw new Error();
      }

      return response;
    } catch (e) {
      return rejectWithValue({ IsSucceeded: false });
    }
  },
);
