import mapTariffs from './tariff-card';

export default function mapData(data) {
  const result = { ...data };

  if (data.tariffs && data.tariffs.list && data.tariffs.list.length) {
    result.tariffs = mapTariffs(data.tariffs, data.tariffsText);
  } else {
    result.tariffs = {};
  }

  return result;
}
