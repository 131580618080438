import React, { memo } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const SummaryInfo = memo(({ title, commonTotalPrice, unit }) => (
  <div className={cx('total')}>
    {title && (
      <div className={cx('total__title')}>
        <Text color="grey95" size="size4-m">
          {title}
        </Text>
      </div>
    )}
    <div className={cx('total__price')}>
      <Text color="grey95" size="size1-m">
        {commonTotalPrice}
      </Text>
      {unit && (
        <div className={cx('total__unit')}>
          <Text color="grey95" size="size5-r-s">
            {unit}
          </Text>
        </div>
      )}
    </div>
  </div>
));

SummaryInfo.propTypes = {
  unit: PropTypes.string,
  title: PropTypes.string,
  commonTotalPrice: PropTypes.number,
};
