import { convertListToObjectBy, emptyArr } from '@beef/utils';
import { createSelector } from '@reduxjs/toolkit';

import {
  getAddressData,
  getRegion,
} from 'pages/FTTB2021/components/OrderTariffFormPopup/store/selectors/userData';
import {
  PAGE_TYPE_EVENT_NAMES,
  PAGE_TYPES,
} from 'pages/FTTB2022/CatalogPage/deprecated/store/blocks/constants';
import { getPageTypeByUrl } from 'pages/FTTB2022/CatalogPage/deprecated/utils';

import { FttbBlocks } from '../../blocks/constants';
import { getContentData } from '../selectors';
import { getActiveCtn, getPageType, getUserType } from '../shared/selectors';
import type { RootState as State } from '../types';
import type { TAddress, TGetFullAddress } from './types';

const getBlocksListUnordered = (state: State) => getContentData(state)?.blocks || emptyArr;

export const getBlocksList = createSelector(getBlocksListUnordered, (blocks) =>
  blocks.slice().sort((a, b) => a.positionOrder - b.positionOrder),
);

const getBlocksDict = createSelector(getBlocksList, convertListToObjectBy('alias'));
export const getBlocksAliasList = createSelector(getBlocksList, (list) =>
  list.map((item) => item.alias),
);

/**
 * Separate blocks
 */
export const getHeadingBlock = (state: State) => getBlocksDict(state)[FttbBlocks.HEADING];
export const getInfoCardsBlock = (state: State) => getBlocksDict(state)[FttbBlocks.INFO_CARDS];
export const getAdvantagesBlock = (state: State) =>
  getBlocksDict(state)[FttbBlocks.ADVANTAGES_CARDS];
export const getAboutConnectionBlock = (state: State) =>
  getBlocksDict(state)[FttbBlocks.CONNECTION_CARDS];
export const getCheckAddressBlock = (state: State) =>
  getBlocksDict(state)[FttbBlocks.CHECK_ADDRESS];
export const getTariffsBlock = (state: State) => getBlocksDict(state)[FttbBlocks.TARIFFS];
export const getAdditionalServicesBlock = (state: State) =>
  getBlocksDict(state)[FttbBlocks.ADDITIONAL_SERVICES];
export const getAdditionalInfoBlock = (state: State) =>
  getBlocksDict(state)[FttbBlocks.ADDITIONAL_INFO];
export const getDevicesBlock = (state: State) => getBlocksDict(state)[FttbBlocks.DEVICES];
export const getLeavePhoneBlock = (state: State) => getBlocksDict(state)[FttbBlocks.LEAVE_PHONE];
export const getFAQBlock = (state: State) => getBlocksDict(state)[FttbBlocks.FAQ];
export const getBannerBlock = (state: State) => getBlocksDict(state)[FttbBlocks.BANNER];
export const getHomeTariffBannerBlock = (state: State) =>
  getBlocksDict(state)[FttbBlocks.HOME_TARIFFS_BANNER];
export const getNewsBlock = (state: State) => getBlocksDict(state)[FttbBlocks.NEWS];

export const activeCtn = (state: State) => getActiveCtn(state);
export const userType = (state: State) => getUserType(state);

/**
 * Selectors for analytics
 */
export const getAnalyticEventType = createSelector(
  [getPageType],
  (pageType) =>
    PAGE_TYPE_EVENT_NAMES[pageType as keyof typeof PAGE_TYPE_EVENT_NAMES] || getPageTypeByUrl,
);

export const isPageTypeFmc = createSelector(
  [getPageType],
  (pageType) => pageType === PAGE_TYPES.fmc,
);

export const getFullAddress = createSelector(
  [getRegion, getAddressData],
  (region: TAddress, { house, street }: TGetFullAddress) =>
    `${region?.label}, ${street.label}, ${house.label}`,
);
