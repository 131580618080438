import { Button, Link } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, { Component } from 'react';

import Ctx from 'pages-components/Ctx';
import InfoPopup from 'pages-components/InfoPopup';
import store from 'store';
import { onCartTariffCardLoadDataLayer } from 'utils/analytics/cart';
import { sendCvmAnalyticsEvent, timerCvmAnalyticsEvent } from 'utils/analytics/cvmTariffAnalytics';
import { pushEcommerceTariffView } from 'utils/analytics/simOrderAnalytics';
import { pushDataLayer, pushViewedTariffCard } from 'utils/ga';
import Authorization from 'widgets/Authorization/Authorization';
import { CommonAppBanner } from 'pages-components/CommonAppBanner';
import { exceptionTariff } from 'pages/ProductCard/Tariff/utils/exceptionTariff';
import { ConstructorV1 } from 'constructorV1/index';

// eslint-disable-next-line import/order
import { tariffData } from '../actions/servicesExtension';

// Containers
import ConstructorTariff from './containers/ConstructorTariff';
import DigitalTariff from './containers/DigitalTariff';
import PartnerShipTariff from './containers/PartnershipTariff';
import RegularTariff from './containers/RegularTariff';
import YouthTariff from './containers/YouthTariff';
import { ConstructorTariff2023 } from './containers/ConstructorTariff2023';
// eslint-disable-next-line import/order
import ZTariff from './containers/ZTariff';

// Components
import AnchorLink from './components/AnchorLink';
import Banners from './components/Banners';
import FeeNote from './components/FeeNote';
import FloatingBtnsGroup from './components/FloatingBtnsGroup';
import FloatingBtnsRow from './components/FloatingBtnsRow';
import FloatingConnectButton from './components/FloatingConnectButton';
import FloatingConnectWrapper from './components/FloatingConnectWrapper';
import ModalsGroup from './components/ModalsGroup';
import PageDescriptions from './components/PageDescriptions';
// eslint-disable-next-line import/order
import TariffConnect from './components/TariffConnect';

// HOC's
// eslint-disable-next-line import/order
import TariffConnectHOC from '../hoc/TariffConnectHOC';

// Utils
import { CONSTRUCTOR2023_LAYOUTS_TYPES, TARIFF_LAYOUTS } from './tariffTypes';
import { isRecommendTariff } from './mapper';
import { TariffUpMetaData } from './components/CharactersTabs/store/TariffUpMetaData';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Cтраница тарифа, получает data и прокидывает ее в store; После чего, все дочернии
 * компонент ведут в store; Компонент не содержит стилей, складывается
 * из отдельных компонентов страницы, которые, по необходимости,
 * можно всегда отключить не сломав страницу;
 */
class Tariff extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    store.dispatch(SERVER_ENV ? tariffData.set(data) : tariffData.update(data));

    this.state = {
      eSimModalOpen: false,
      isLayoutYoung: false,
    };
  }

  componentDidMount() {
    const { pageTitle, soc, marketCode, isAuthenticated, isConnected, layoutType, id } =
      this.props.data || {};

    TariffUpMetaData.IsAuthenticated = isAuthenticated;
    TariffUpMetaData.Soc = soc;

    if (id) {
      pushDataLayer({
        tariffId: id,
      });
    }

    isRecommendTariff(this.props.data);

    const GAParams = {
      itemTitle: pageTitle,
      itemSoc: soc,
      itemMarketCode: marketCode,
    };

    if (isAuthenticated) {
      GAParams.myTariff = `${+isConnected}`;
    }

    this.sendAnalyticsEvent();
    pushViewedTariffCard(GAParams);
    pushEcommerceTariffView(this.props.data);

    if (typeof window !== 'undefined') {
      onCartTariffCardLoadDataLayer({
        id,
        name: pageTitle,
        price: this.props.data?.price?.value,
        alias: this.props.data?.alias,
        discount: this.props.data?.discountValue || null,
      });
    }

    this.setState({
      isLayoutYoung: layoutType === TARIFF_LAYOUTS.young,
    });
  }

  setESimOpenModal = () =>
    this.setState((prevState) => ({
      eSimModalOpen: !prevState.eSimModalOpen,
    }));

  // eslint-disable-next-line consistent-return
  getFloatingBtns() {
    const { isLayoutYoung } = this.state;

    const {
      isOrderBtnsRow,
      twoOrderBtnsData,
      connectButton,
      esim,
      isConnected,

      availableInShop,
      isArchived,
    } = this.props.data || {};

    const showOneMoreButton = availableInShop && isConnected && !isArchived;

    if (isOrderBtnsRow && twoOrderBtnsData) {
      return <FloatingBtnsRow connectBtnText={twoOrderBtnsData.connectBtnText || connectButton} />;
    }

    if (!isOrderBtnsRow && twoOrderBtnsData) {
      return (
        <FloatingBtnsGroup connectBtnText={twoOrderBtnsData.connectBtnText || connectButton} />
      );
    }

    if (showOneMoreButton && isLayoutYoung) {
      return (
        <FloatingConnectWrapper
          render={() => (
            <TariffConnectHOC>
              <TariffConnect renderYoungConnected />
            </TariffConnectHOC>
          )}
        />
      );
    }

    if (!twoOrderBtnsData) {
      return (
        <FloatingConnectButton isButtonsGroup={!!esim} tariffYoung={isLayoutYoung}>
          {esim && (
            <div>
              <Button fullWidth onClick={this.setESimOpenModal} variant="secondary">
                {esim.text}
              </Button>
            </div>
          )}

          {isConnected && isLayoutYoung && (
            <div className={cx('block')}>
              <Link
                buttonSize="l"
                buttonVariant="main"
                href="/customers/products/mobile/profile/#/home"
                isFullWidth
                variant="button"
              >
                {Ctx.tariff.configureBtnText}
              </Link>
            </div>
          )}
        </FloatingConnectButton>
      );
    }
  }

  // CVM аналитика;
  sendAnalyticsEvent = () => {
    const { subgroupId, campId, offerType } = this.props.data || {};
    let renderUrlMatch = null;

    // Проверяем, абонент пришел из ЛК или нет
    if (typeof window !== 'undefined') {
      renderUrlMatch = window.location.href.match('detailsforlk');
    }

    if (subgroupId && campId) {
      const arrayIds = [
        {
          campId,
          subgroupId,
        },
      ];

      // Абонент пришел из ЛК нажав на CVM оффер
      if (renderUrlMatch) {
        sendCvmAnalyticsEvent(arrayIds, 'open', 'offers');
        return;
      }

      // Если нет offerType, мы считаем что абонент прищел из каталога по upsale тарифу;
      const place = !offerType ? 'catalog' : 'downsale';

      if (!offerType) {
        sendCvmAnalyticsEvent(arrayIds, 'open', 'catalog');
      }

      timerCvmAnalyticsEvent(arrayIds, 'landViewed', place);
    }
  };

  renderTariffBody = () => {
    const {
      simDiscount,
      familyNavigation,
      productTypeTitle,
      pageTitleIcon,
      discountValue,
      pageTitle,
      isConnected,
      benefits,
      guide,
      layoutType,
      price,
      esim,
      alias,
      pageSubtitle,
      availablePackages,
    } = this.props.data || {};

    // Проверяем наличие навигации семейства, скрываем если ее нет либо есть один;
    const familyNavigationList =
      familyNavigation && !isEmpty(familyNavigation) && familyNavigation.length > 1 ?
        familyNavigation
      : null;

    // Проверяем наличие бенефитов;
    const benefitsList = benefits && !isEmpty(benefits) ? benefits : null;

    // Устанавливаем подзаголовок страницы;
    let subTitleText = discountValue ? Ctx.tariff.subTitle.discount : Ctx.tariff.subTitle.regular;

    // Если есть бэйдж скидки;
    let discountText = null;
    const priceDiscount = simDiscount?.deliveryOnly ? (productTypeTitle || {}).discount : '';

    // Если это тариф со скидкой + бэйдж;
    if (productTypeTitle) {
      subTitleText = productTypeTitle.text;
      discountText = productTypeTitle.discount;
    }

    // Подсвечивать заголовок ораньжевым если есть скидка или бэйджи тарифа со скидкой;
    const isBurnSubTitle = !!(discountValue || discountText);

    if (layoutType === TARIFF_LAYOUTS.young) {
      return (
        <YouthTariff
          alias={alias}
          availablePackages={availablePackages}
          esim={esim}
          familyNavigation={familyNavigationList}
          guide={guide}
          isConnected={isConnected}
          layoutType={layoutType}
          pageSubtitle={pageSubtitle}
          pageTitle={pageTitle}
          price={price}
          priceDiscount={priceDiscount}
          setESimOpenModal={this.setESimOpenModal}
          subTitleText={subTitleText}
        />
      );
    }

    if (layoutType === TARIFF_LAYOUTS.digital || layoutType === TARIFF_LAYOUTS.vip) {
      return (
        <DigitalTariff
          alias={alias}
          benefits={benefitsList}
          esim={esim}
          familyNavigation={familyNavigationList}
          layoutType={layoutType}
          pageTitle={pageTitle}
          price={price}
          priceDiscount={priceDiscount}
          setESimOpenModal={this.setESimOpenModal}
          subTitleText={subTitleText}
        />
      );
    }

    return (
      <RegularTariff
        alias={alias}
        benefits={benefitsList}
        discountText={discountText}
        familyNavigation={familyNavigationList}
        isBurnSubTitle={isBurnSubTitle}
        pageTitle={pageTitle}
        pageTitleIcon={pageTitleIcon}
        setESimOpenModal={this.setESimOpenModal}
        subTitleText={subTitleText}
      />
    );
  };

  renderCommonParts = () => {
    const { eSimModalOpen, isLayoutYoung } = this.state;

    const { fullDescriptionLink, descriptions, banners, esim, feeNote, layoutType } =
      this.props.data || {};

    // Проверка наличия данных для баннера;
    const isBanner = banners && !isEmpty(banners);
    return (
      <>
        <ModalsGroup />

        {feeNote && <FeeNote text={feeNote.text} tooltip={feeNote.toolTip} />}

        {eSimModalOpen && <InfoPopup fetchUrl={esim.popupUrl} onClose={this.setESimOpenModal} />}

        {layoutType !== TARIFF_LAYOUTS.yandex && (
          <AnchorLink anchor={() => this.descriptionsAnchor}>
            <span className={cx(isLayoutYoung && 'youngFont')}>
              {Ctx.tariff.descriptionsAnchor}
            </span>
          </AnchorLink>
        )}

        {isBanner && <Banners banners={banners} />}

        <div
          ref={(e) => {
            this.descriptionsAnchor = e;
          }}
        />
        <PageDescriptions
          className={cx(isLayoutYoung && 'youngFont')}
          descriptions={descriptions}
          fullDescriptionLink={fullDescriptionLink}
        />

        <Authorization />
      </>
    );
  };

  render() {
    const {
      layoutType,
      pageTitle,
      benefits,
      layoutData,
      isConnected,
      price,
      availableForConnect,
      availableInShop,
      isAuthenticated,
      mnpWidget,
      esim,
      rcRate,
      rcRateWithoutDiscount,
      discountedPrice,
      alias,
    } = this.props.data || {};
    /** Реализация задачи BCP-17900 (баннер для "Интернет LIVE" в АЗ).
     * Если ф-ция exceptionTariff вернет true, вместо основного тела конструктора показываем информационный баннер с QR-кодом */
    if (exceptionTariff({ isAuthenticated, layoutType, alias })) {
      return <CommonAppBanner {...layoutData.content.commonAppBanner} />;
    }

    if (layoutType === TARIFF_LAYOUTS.yandex) {
      return (
        <>
          <PartnerShipTariff
            availableCards={{
              esim: !!esim,
              mnp: !isAuthenticated && availableInShop && !!mnpWidget,
            }}
            availableForConnect={availableForConnect}
            availableInShop={availableInShop}
            benefits={benefits}
            content={layoutData.content}
            isAuthenticated={isAuthenticated}
            isConnected={isConnected}
            packages={layoutData.packages}
            pageTitle={pageTitle}
            price={price}
            prices={layoutData.prices}
            setESimOpenModal={this.setESimOpenModal}
            showDiscountParams={{
              isAuthenticated,
              hasDpcSubscriptionFeeOnly: layoutData?.hasDpcSubscriptionFeeOnly,
              rcRate,
              rcRateWithoutDiscount,
              discountedPrice,
            }}
            subscription={{
              data: layoutData?.subscription,
              content: layoutData.content?.subscription,
            }}
          />
          {this.renderCommonParts()}
        </>
      );
    }

    /** Конструктор 2.0 */
    if (CONSTRUCTOR2023_LAYOUTS_TYPES.includes(layoutType)) {
      const { data } = this.props;

      return (
        <>
          <ConstructorTariff2023 {...{ data, layoutType }} />
          {this.renderCommonParts()}
        </>
      );
    }

    /** Единый конструктор 2024 */
    if (layoutType === TARIFF_LAYOUTS.unitedConstructor) {
      const { data } = this.props;

      return <ConstructorV1 {...{ data }} />;
    }

    /** Конструктор 1.0 */
    if (layoutType === TARIFF_LAYOUTS.constructor) {
      const { data } = this.props;

      return (
        <>
          <ConstructorTariff
            bannerInfo={data.layoutData.bannerInfo}
            charactersData={data.layoutData.charactersData}
            checkData={data.layoutData?.checkData}
            content={data.layoutData.content}
            convergenceContent={data.layoutData.convergenceContent}
            data={data}
            defaultInfo={data.layoutData.defaultInfo}
            familyData={data.layoutData.familyData}
            hasSpecialOffer={data.layoutData?.hasSpecialOffer}
            hasTariffConstructor={data.layoutData.hasTariffConstructor}
            homeInternet={data.layoutData.homeInternet}
            isAnimalsTariffLayout={data.layoutData.isAnimalsTariffLayout}
            isConnectedCharacter={data.layoutData.isConnectedCharacter}
            isDataTariffLayout={data.layoutData.isDataTariffLayout}
            isTariffAvailableForConnect={data.layoutData.isAvailable}
            presetDiscounts={data.layoutData.presetDiscounts}
            slides={data.layoutData.slides}
            socBaseConnected={data.layoutData.socBaseConnected}
            specialOfferInfo={data.layoutData?.specialOfferInfo}
          />
          {this.renderCommonParts()}
        </>
      );
    }

    if (layoutType === TARIFF_LAYOUTS.ztariff) {
      const { data } = this.props;

      return (
        <>
          <ZTariff
            bannerInfo={data.layoutData.bannerInfo}
            checkInfo={data.layoutData.checkInfo}
            content={data.layoutData.content}
            data={data}
            hasZTariff={data.layoutData.hasZTariff}
            isAvailable={data.layoutData.isAvailable}
            isTariffAvailableForConnect={data.layoutData.isAvailable}
            slides={data.layoutData.slides}
            tariffOptions={data.layoutData.tariffOptions}
          />
          {this.renderCommonParts()}
        </>
      );
    }

    return (
      <>
        {this.renderTariffBody()}
        {this.getFloatingBtns()}
        {this.renderCommonParts()}
      </>
    );
  }
}

/* eslint-disable react/forbid-prop-types */
Tariff.propTypes = {
  data: PropTypes.shape({
    subgroupId: PropTypes.number,
    discountValue: PropTypes.number,
    campId: PropTypes.number,

    fullDescriptionLink: PropTypes.object,
    price: PropTypes.object,
    esim: PropTypes.object,
    twoOrderBtnsData: PropTypes.object,

    banners: PropTypes.arrayOf(PropTypes.object),
    descriptions: PropTypes.array,
    familyNavigation: PropTypes.array,
    parameterGroups: PropTypes.array,
    benefits: PropTypes.array,

    isAuthenticated: PropTypes.bool,
    isConnected: PropTypes.bool,

    pageTitleIcon: PropTypes.string,
    marketCode: PropTypes.string,
    pageTitle: PropTypes.string,
    offerType: PropTypes.string,
    alias: PropTypes.string,
    soc: PropTypes.string,
    layoutType: PropTypes.string,

    simDiscount: PropTypes.shape({
      label: PropTypes.string,
      percent: PropTypes.number,
      deliveryOnly: PropTypes.bool,
    }),
    productTypeTitle: PropTypes.shape({
      text: PropTypes.string,
      discount: PropTypes.string,
    }),
    extData: PropTypes.shape({
      service: PropTypes.array,
    }),
    layoutData: PropTypes.shape({
      IsUnavailableForBuyingNewSIM: PropTypes.bool,
      IsAvailable: PropTypes.bool,
      IsConnect: PropTypes.bool,
      hasDpcSubscriptionFeeOnly: PropTypes.bool,
    }),
  }),
};

export default Tariff;
