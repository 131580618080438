import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Radio } from '@beef/ui-kit';

import { ChildSocsBlockType } from 'pages/SPN/types';

import { getPriceInfo, textForLabel } from '../../../../common';
// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const ChildSocsBlock: FC<ChildSocsBlockType> = ({
  childSocs,
  onRadioChange,
  choosenOption,
  isEditableSocSpn,
}) => (
  <ul className={cn('child-socs-block')}>
    {childSocs?.map((el, idx) => {
      const childPriceInfo = getPriceInfo(el);

      return (
        <li className={cn('child-soc')} key={el.soc}>
          <Radio
            checked={choosenOption?.soc === el.soc}
            label={textForLabel({ socTitle: el.title, priceInfo: childPriceInfo })}
            name="childOption"
            onChange={(e) =>
              onRadioChange({ soc: e.target.value, spn: el?.title || '' }, isEditableSocSpn)
            }
            radioVariant="default"
            value={el.soc}
          />
        </li>
      );
    })}
  </ul>
);
