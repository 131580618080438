import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TItemDetailsInfo = {
  name: string;
  value: string;
};

/** Item-элемент для списка DetailsInfo */
export const ItemDetailsInfo: FC<TItemDetailsInfo> = ({ name, value }) => (
  <li className={cx('wrapper')}>
    <Text size="size5-r-s">{name}</Text>
    <Text size="size5-r-s">{value}</Text>
  </li>
);
