import React from 'react';
import PropTypes from 'prop-types';
import { useCheckMounted } from '@beef/react';

// Components
import { Button } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import DigitalBenefitsList from 'pages-components/Benefits/components/DigitalBenefitsList';
import Discount from 'pages-components/Discount';
import { useMobileOs } from 'utils/hooks/useIsMobile';
import { TARIFF_LAYOUTS } from 'pages/ProductCard/Tariff/tariffTypes';

import FamilyNavigation from '../../components/FamilyNavigation';
import TariffCardBody from '../../components/TariffCardBody';
import TariffConnect from '../../components/TariffConnect';

// Hoc's
import FamilyNavigationHOC from '../../../hoc/FamilyNavigationHOC';
import DigitalBenefitsHoc from '../../../hoc/DigitalBenefitsHoc';
import TariffBodyHOC from '../../../hoc/TariffBodyHOC';
import TariffConnectHOC from '../../../hoc/TariffConnectHOC';

// Utils hocs

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const DigitalTariff = ({
  familyNavigation,
  alias,
  subTitleText,
  layoutType,
  setESimOpenModal,
  pageTitle,
  benefits,
  priceDiscount,
  price,
  esim,
}) => {
  const isMounted = useCheckMounted();
  const device = useMobileOs() ? 'mobile' : 'desktop';
  const isShowBenefitPicture = device === 'desktop';
  const eSimButtonSize = device === 'desktop' && 'big';

  return (
    <div className={cx('digitalTariff')}>
      <div className={cx('heading', { [`layout--${layoutType}`]: !!layoutType })}>
        {/* Табы навигации */}
        {familyNavigation && (
          <FamilyNavigationHOC alias={alias} familyNavigation={familyNavigation}>
            <FamilyNavigation className={cx('digitalNavigation')} />
          </FamilyNavigationHOC>
        )}

        {/*  Контейнер для заголовка и подзаголовка */}
        <div className={cx('titleGroup')}>
          <div className={cx('subTitle')}>{subTitleText}</div>
          <div className={cx('pageTitle')}>{pageTitle}</div>
        </div>

        {/*  Контейнер для бенефитов */}
        {benefits && (
          <div className={cx('benefits', !isShowBenefitPicture && 'withoutPicture')}>
            <DigitalBenefitsHoc benefits={benefits}>
              <DigitalBenefitsList
                fade
                showBenefitPicture={isShowBenefitPicture}
                showTitleMobile={layoutType === TARIFF_LAYOUTS.vip}
              />
            </DigitalBenefitsHoc>
          </div>
        )}

        {/*  Цена тарифа */}
        <div className={cx('cost')}>
          {price && <div className={cx('price')}>{`Всё это за ${price.value} ${price.unit}`}</div>}

          {priceDiscount && (
            <Discount.Label className={cx('discountLabel')} label={priceDiscount} />
          )}
        </div>

        <div className={cx(esim ? 'buttonsGroup' : 'singleButton')}>
          {/* Кнопки подключения тарифа */}
          {isMounted && (
            <>
              <div className={cx('connectButton')} id="topConnectButton">
                <TariffConnectHOC>
                  <TariffConnect floatingButtonBehavior onlyConnectButton />
                </TariffConnectHOC>
              </div>

              {esim && (
                <div className={cx('eSimButton')}>
                  <Button className={eSimButtonSize} color="light" onClick={setESimOpenModal} wide>
                    {esim.text}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <TariffBodyHOC setESimOpenModal={setESimOpenModal}>
        <TariffCardBody />
      </TariffBodyHOC>
    </div>
  );
};

DigitalTariff.propTypes = {
  alias: PropTypes.string,
  layoutType: PropTypes.string,
  subTitleText: PropTypes.string,
  pageTitle: PropTypes.string,
  priceDiscount: PropTypes.string,

  familyNavigation: PropTypes.array,
  benefits: PropTypes.array,

  setESimOpenModal: PropTypes.func,

  esim: PropTypes.object,
  price: PropTypes.shape({
    value: PropTypes.string,
    unit: PropTypes.string,
  }),
};

export default DigitalTariff;
