import { useEffect } from 'react';

export const useInitialRequest = (onSetInitialParams) => {
  const handleInitialParams = (e) => {
    onSetInitialParams(e?.currentTarget?.location?.search);
  };

  useEffect(() => {
    onSetInitialParams();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', handleInitialParams);
    }
    return () => window.removeEventListener('popstate', handleInitialParams);
  }, []);
};
