/**
 * Утилита для получения данных из корзины (на основе из бифа)
 *
 * Используется пока в шапке не появится функционал
 * по работе с корзиной
 */
export const getPurchasedItems = (items: any[]) => {
  const purchasedItems: { devices: any[]; numbers: any[] } = { devices: [], numbers: [] };
  const getItemData = (item: any) => {
    if (!item.isExcluded) {
      if (item.itemType === 'product' && item.id) {
        purchasedItems.devices = [...purchasedItems.devices, parseInt(item.id, 10)];
      } else if (item.itemType === 'ctn' && item?.details?.ctn) {
        purchasedItems.numbers = [...purchasedItems.numbers, item.details.ctn];
      }
    }

    if (item?.children?.length) {
      item.children.forEach((childItem: any) => {
        getItemData(childItem);
      });
    }
  };

  if (items?.length) {
    items.forEach((item) => {
      getItemData(item);
    });
  }

  return purchasedItems;
};
