import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { equals, path, symmetricDifference, without } from 'ramda';

import PopupStateless from 'pages-components/Popup';

import { closeBlackList, saveBlackList, setBlackList } from '../../../actions/blackList';
import styles from './styles.pcss';
import SettingsContent from './SettingsContent';

const cx = classNames.bind(styles);

class BlackListPopup extends Component {
  constructor(props) {
    super(props);
    const { phoneList } = props;
    this.state = {
      phone: '+7',
      isAddDisabled: true,
      localList: phoneList || [],
      lastRemovedNumber: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { phoneList } = prevProps;
    const nextList = this.props.phoneList;
    if (nextList && !equals(phoneList, nextList)) {
      this.setState({
        localList: nextList,
      });
    }
  }

  handlePhoneChange = (value) => {
    const number = value.replace(/\D/gi, '');
    this.setState({
      phone: value,
      isAddDisabled: !(number.length === 11),
    });
  };

  handlePhoneAdd = (payload) => {
    const { phone } = this.state;
    this.setState((prevState) => ({
      phone: '+7',
      isAddDisabled: true,
      localList: [...prevState.localList, payload || phone],
    }));
  };

  handlePhoneRemove = (phone) => {
    this.setState((prevState) => ({
      localList: without([phone], prevState.localList),
      lastRemovedNumber: phone,
    }));
  };

  handlePhoneRecover = () => {
    this.setState((prevState) => ({
      localList: [...prevState.localList, prevState.lastRemovedNumber],
      lastRemovedNumber: '',
    }));
  };

  handleClose = () => {
    const { phoneList, onClose } = this.props;
    this.setState(
      {
        phone: '+7',
        isAddDisabled: true,
        localList: phoneList || [],
        lastRemovedNumber: '',
      },
      onClose,
    );
  };

  handleSave = () => {
    const { localList } = this.state;
    this.props.onSave(localList);
  };

  render() {
    const { opened, maxNumbers, phoneList, errorText } = this.props;
    const { isAddDisabled, localList, lastRemovedNumber, phone } = this.state;
    const showButton = localList.length || !equals(phoneList, localList);
    const isDisabled = !symmetricDifference(localList, phoneList).length;
    return (
      <div className={cx('blackListPopup')}>
        <PopupStateless onClose={this.handleClose} opened={opened}>
          <SettingsContent
            errorText={errorText}
            isAddDisabled={isAddDisabled}
            isDisabled={isDisabled}
            lastRemovedNumber={lastRemovedNumber}
            maxNumbers={maxNumbers}
            numbers={localList}
            onPhoneAdd={this.handlePhoneAdd}
            onPhoneChange={this.handlePhoneChange}
            onPhoneRecover={this.handlePhoneRecover}
            onPhoneRemove={this.handlePhoneRemove}
            onSave={this.handleSave}
            phone={phone}
            showButton={showButton}
          />
        </PopupStateless>
      </div>
    );
  }
}

BlackListPopup.propTypes = {
  phoneList: PropTypes.array,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  errorText: PropTypes.string,
  maxNumbers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state) => {
  const phoneList = path(['external', 'blackList', 'blackNumbers'], state);
  return {
    opened: path(['external', 'blackList', 'blackListPopup', 'opened'], state),
    phoneList: [...phoneList],
    errorText: path(['external', 'blackList', 'blackListPopup', 'error', 'text'], state),
    maxNumbers: path(['external', 'blackList', 'options', 'maxNumbers'], state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(closeBlackList()),
  onSave: (list) => {
    dispatch(setBlackList(list));
    dispatch(saveBlackList());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BlackListPopup);
