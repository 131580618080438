import { dataLayerParams } from './constants';

/* Тип для выбора отправки данных dataLayer */
export type TDataLayer = keyof typeof dataLayerParams;

/* Финальная подготовка и отправка DataLayer */
export const pushDataLayer = (type: TDataLayer, commonDataLayerParams: Record<string, any>) => {
  /* Выбирает common || mnp из dataLayerParams */
  const currentParams = dataLayerParams[type];

  /* Расширенные параметры */
  const extCurrentParams = {
    ecommerce: {
      currencyCode: 'RUB',
      detail: {
        products: [{ ...currentParams[0], ...commonDataLayerParams }, currentParams[1]],
      },
    },
  };

  if (window && window?.dataLayer) {
    window.dataLayer.push(extCurrentParams);
  }
};
