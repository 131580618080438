import React from 'react';
import { StatefulSearchInput } from '@beef/ui-kit';

import { useCheckHouse } from './hooks/useCheckHouse';
import { useCheckAddressInputsTexts } from '../../selectors/useCheckAddressInputsTexts';

export const FmcHouseInput = () => {
  const { homeTitle, homePlaceholder } = useCheckAddressInputsTexts();
  const inputProps = useCheckHouse();
  const placeholder = !inputProps.disabled ? homePlaceholder : undefined;
  return (
    <StatefulSearchInput
      debounceInterval={500}
      label={homeTitle}
      leftAside={undefined}
      placeholder={placeholder}
      {...inputProps}
    />
  );
};
