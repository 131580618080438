import React from 'react';
import classNames from 'classnames/bind';

import styles from '../styles.pcss';
import RefreshTile from './index';

const cx = classNames.bind(styles);

const RefreshTileCardBody = (props) => (
  <div className={cx('tiles')}>
    <RefreshTile {...props} />
  </div>
);

export default RefreshTileCardBody;
