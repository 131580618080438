import React from 'react';
import ReactDOM from 'react-dom';

import { SidebarContent } from '../SidebarContent';
import { ISidebarProps } from '../types';

export const SidebarPortal: React.FC<ISidebarProps> = (props) => {
  const { container = window?.document?.body, ...otherProps } = props;

  if (!container) {
    return null;
  }

  return ReactDOM.createPortal(<SidebarContent {...otherProps} />, container) as React.ReactElement;
};
