import { tail } from 'ramda';

import { maskPhone } from 'utils/format-string';

import { LOGIN_TYPES } from '../actions/constants';

export const ctnBeautifier = (ctn) => {
  if (/^08\d{8}$/.test(ctn)) {
    return maskPhone(ctn, ' ___ ___-__-__');
  }
  if (/^9\d{9}$/.test(ctn)) {
    return maskPhone(ctn, '+7 ___ ___-__-__');
  }
  return ctn;
};

export const parseCtn = (ctn) => {
  const cleanCtn = ctn.replace(/[^\w\d]/gi, '');
  let parsedCtn = ctn;
  let ctnType = LOGIN_TYPES.login;
  if (/^9\d{9}$/.test(cleanCtn)) {
    ctnType = LOGIN_TYPES.mobile;
    parsedCtn = cleanCtn;
  } else if (/^[7,8]9\d{9}$/.test(cleanCtn)) {
    ctnType = LOGIN_TYPES.mobile;
    parsedCtn = tail(cleanCtn);
  }
  return {
    ctn: parsedCtn,
    ctnType,
  };
};

export const checkIsValid = (valueNumber) => {
  const onlyDigits = valueNumber.replace(/\+7|\D/g, '');
  return onlyDigits.length === 10;
};
