import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Button,
  Checkbox,
  Heading,
  InputGroup,
  Label,
  Link,
  Preloader,
  SmartForm,
  SmartInput,
  Status,
} from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import SlidingDown from 'pages-components/SlidingDown';

// Utils
import { maskPhone } from 'utils/format-string';
import { validatePhone } from 'utils/validators';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Form = (props) => {
  const { phone: phoneOrigin, isExistingCtn, errorStatus, onSubmit, onChange, data, step } = props;

  const [showContactPhone, setShowContactPhone] = useState(false);

  const onCheckboxToggle = () => setShowContactPhone(!showContactPhone);

  const onFormSubmit = useCallback(
    (values) => {
      if (showContactPhone) {
        onSubmit({ contactPhone: values.contactPhone, mnpPhone: values.phone });
        return;
      }

      onSubmit({ contactPhone: values.phone, mnpPhone: values.phone });
    },
    [showContactPhone, onSubmit],
  );

  const phone = phoneOrigin ? maskPhone(phoneOrigin) : '+7 ';

  const serverErrors =
    isExistingCtn ?
      {
        phone: {
          status: 'fail',
          msg: data.isBeelineCtnErrorText,
          touched: true,
        },
      }
    : {};

  const isShowButton =
    step !== 'loading' && (errorStatus?.status !== 'error' || errorStatus?.isShowBtn);

  return (
    <div>
      {data.heading && (
        <Heading className={cx('title')} isStyled={false} level={1}>
          <span dangerouslySetInnerHTML={{ __html: data.heading }} />
        </Heading>
      )}

      <div className={cx('textContainer')} dangerouslySetInnerHTML={{ __html: data.description }} />

      <SmartForm
        className={cx('form')}
        onSubmit={onFormSubmit}
        serverErrors={serverErrors}
        validator={{
          phone: (val) => {
            if (isExistingCtn && phone === val) {
              return {
                status: 'fail',
                msg: data.isBeelineCtnErrorText,
              };
            }

            return validatePhone(val);
          },
          contactPhone: (val) => {
            if (!showContactPhone) {
              return { status: 'ok' };
            }

            return validatePhone(val);
          },
        }}
      >
        <InputGroup className={cx('inputGroup')}>
          <div className={cx('label')}>
            <Label htmlFor="simOrderPhone">
              <span dangerouslySetInnerHTML={{ __html: data.labels.mnp }} />
            </Label>
          </div>
          <SmartInput
            className={cx('input')}
            id="simOrderPhone"
            inputId="phone"
            mask="+7 ___ ___ __ __"
            onChange={onChange}
            prefix="+7 "
            size="big"
            value={phone}
          />
        </InputGroup>
        <Checkbox
          checked={!showContactPhone}
          className={cx('checkbox')}
          handleChange={onCheckboxToggle}
        >
          <span dangerouslySetInnerHTML={{ __html: data.checkboxText }} />
        </Checkbox>
        <SlidingDown className={cx('inputGroup')} show={showContactPhone}>
          <InputGroup>
            <div className={cx('label')}>
              <Label htmlFor="simOrderContactPhone">
                <span dangerouslySetInnerHTML={{ __html: data.labels.contact }} />
              </Label>
            </div>
            <SmartInput
              className={cx('input', 'contactPhone')}
              id="simOrderContactPhone"
              inputId="contactPhone"
              mask="+7 ___ ___ __ __"
              prefix="+7 "
              size="big"
              value="+7 "
            />
          </InputGroup>
        </SlidingDown>

        {errorStatus && (
          <Status className={[cx('statusBlock'), errorStatus.status]} emoji={errorStatus.emoji}>
            <span dangerouslySetInnerHTML={{ __html: errorStatus.content }} />
            {errorStatus.buttonUrl && errorStatus.buttonText && (
              <a className={cx('statusLink')} href={errorStatus.buttonUrl}>
                {errorStatus.buttonText}
              </a>
            )}
          </Status>
        )}

        {step === 'loading' && (
          <div className={cx('preloader')}>
            <Preloader />
          </div>
        )}

        {isShowButton && (
          <div className={cx('buttonWrapper')}>
            <Button className="big" wide>
              <span dangerouslySetInnerHTML={{ __html: data.orderBtn }} />
            </Button>
          </div>
        )}

        {data.otherLinkText && (
          <Link className={['hideVisitedColor', cx('otherLink')]} href={data.otherLinkUrl}>
            {data.otherLinkText}
          </Link>
        )}
      </SmartForm>
    </div>
  );
};

Form.propTypes = {
  isExistingCtn: PropTypes.bool,
  phone: PropTypes.string,
  onSubmit: PropTypes.func,
  data: PropTypes.object,

  errorStatus: PropTypes.object,
  step: PropTypes.string,
  onChange: PropTypes.func,
};

export default Form;
