import React, { FC } from 'react';
import { Button, Skeleton } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { ActionsProps } from 'pages/Services/RedirectServiceCardLanding/components/Banner/types';
import { redirectToLogin } from 'pages/SPN/api';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const Actions: FC<ActionsProps> = ({
  loadingInitialData,
  onOpenModal,
  isAuthenticated,
  options,
  buttons,
  onOpenCancelModal,
}) => {
  const mainButtonText = options?.doNotRedirect === false ? buttons.change : buttons.active;
  const isVisibleCancelButton = options?.doNotRedirect === false;
  const cancelButtonText = buttons.cancel;

  const onClickMainButton = () => {
    if (isAuthenticated) {
      onOpenModal();
    } else {
      redirectToLogin();
    }
  };

  if (loadingInitialData === 'pending') {
    return <Skeleton className={cn('skeleton')} />;
  }

  return (
    <div className={cn('details__action')}>
      <Button onClick={onClickMainButton}>{mainButtonText}</Button>
      {isVisibleCancelButton && (
        <Button onClick={onOpenCancelModal} variant="tertiary">
          {cancelButtonText}
        </Button>
      )}
    </div>
  );
};
