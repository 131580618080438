import { ymPushEvent } from 'utils/ym';

import { connectMethodTypes, pushYm } from '../../../tempAnalytics/metricUtils';
import { TabTypes } from './constants';

/* Справочник событий для ymPushEvent  */
const eventTypes = {
  SimOrderForm: 'poluchit_novy_nomer',
  ChangeTariffForm: 'izmenit_tariff',
  MNPForm: 'pereiti_s_sohraneniem_nomera',
  eSIMForm: 'kupit_esim',
};

/* Параметры для метрики при показе модального окна апера */
const modalParams = {
  eSIMForm: {
    popup_type: 'esim',
    popup_name: 'Купить eSIM',
  },
  ChangeTariffForm: {
    popup_type: 'auth',
    popup_name: 'Сначала авторизуйтесь',
  },
};

/** Ф-я отправки параметров для кликов по табам в яндекс метрику */
export const sendClickYm = (currentTab: TabTypes) => {
  if (!currentTab) return;

  if (currentTab in connectMethodTypes && currentTab in eventTypes) {
    pushYm({ click_method_connect: connectMethodTypes[currentTab] });
    ymPushEvent(eventTypes[currentTab]);
  }

  /* Дополнительно отправлять при показе модального окна есим */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  if (currentTab === TabTypes.eSIMForm) {
    pushYm({ ...modalParams.eSIMForm, popup_action: 'view' });
  }
  if (currentTab === TabTypes.ChangeTariffForm) {
    pushYm({ ...modalParams.ChangeTariffForm, popup_action: 'view' });
  }
};

/** Тип табов для отправки метрики на клик по экшен бару модального окна esim или попапа авторизации для купить нов сим */
export type TTabTypesYm = TabTypes.ChangeTariffForm | TabTypes.eSIMForm;

/** Отправка метрики на клик по экшен бару модального окна esim или попапа авторизации для купить нов сим */
export const sendPopupYm = (type: string, currentTab: TTabTypesYm) => {
  pushYm({
    ...modalParams[currentTab],
    popup_action: type,
  });
};
