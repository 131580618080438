import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { apiGetUpperPriceUrl } from './constants';

/** Тип запроса (params) */
type TFetchUpperPriceReq = {
  gbValue: number;
  minValue: number;
  tariffSoc: string;
};

/** Тип ответа */
export type TFetchUpperPriceRes = {
  commonBill?: {
    totalPrice: number;
    totalPriceText: string;
  } | null;
  isSuccess?: boolean;
};

/** Запрос данных по total чеку
 * @deprecated вместо этого запроса теперь пользуемся только not-auth-tariffup-preset при изменении пресетов и аппера */
export const getUpperPrice = createAsyncThunk<TFetchUpperPriceRes, TFetchUpperPriceReq>(
  'upperApi/getUpperPrice',
  async (params, { signal }) => {
    try {
      const response = (
        await axios.get<TFetchUpperPriceRes>(apiGetUpperPriceUrl, {
          params,
          signal,
        })
      ).data;

      if (!response.isSuccess) {
        throw new Error();
      }
      return response;
    } catch (e) {
      throw new Error();
    }
  },
);
