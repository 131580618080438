import { Cell, Skeleton, Text } from '@beef/ui-kit';
import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { BlockInfoSettingsProps } from 'pages/Services/RedirectServiceCardLanding/components/BlockInfo/types';
import { maskPhone } from 'utils/format-string';

import styles from './styles.pcss';

const cx = classNames.bind(styles);
export const BlockInfoSettings: FC<BlockInfoSettingsProps> = ({
  title,
  list,
  isMobile,
  loadingInitialData,
}) => {
  if (loadingInitialData === 'pending') {
    return <Skeleton className={cx('skeleton')} />;
  }

  if (list.length === 0) {
    return null;
  }

  return (
    <div className={cx('card')}>
      <Text className={cx('card__title')} size={isMobile ? 'size4-m' : 'size2-m'}>
        {title}
      </Text>
      {list?.map((item) => (
        <Cell
          key={item.title}
          leftSubtitle={item.subtitle}
          leftTitle={maskPhone(item.title, '+7 ___ ___-__-__') || ''}
        />
      ))}
    </div>
  );
};
