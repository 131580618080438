import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import Ctx from 'pages-components/Ctx';

import Accumulator from '../Accumulator';
import Scale from '../Scale';

// Utils
import { getKbyteString, getSecondsString } from '../../utils';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

class AccumulatorsList extends Component {
  state = {
    scaleSize: 100,
  };

  setTextValue = (accumulator) => {
    let text = '';

    if (!accumulator.isUnlimited) {
      const rest = accumulator.available;
      const { size } = accumulator;

      switch (accumulator.unit) {
        case 'KBYTE':
          text = `${getKbyteString(rest)} ${rest === size ? '' : ` из ${getKbyteString(size)}`}`;
          break;
        case 'SECONDS':
          text = `${getSecondsString(rest)} ${
            rest === size ? '' : ` из ${getSecondsString(size)}`
          }`;
          break;
        case 'SMS':
          text = `${rest} SMS ${rest === size ? '' : ` из ${size} SMS`}`;
          break;
      }
    }

    return text;
  };

  renderScale = (accumulator) => {
    const { available, size } = accumulator;
    const { scaleSize } = this.state;

    const availableSize = available > size ? size : available;

    return (
      <Scale available={availableSize} className={cx('scale')} scaleSize={scaleSize} size={size} />
    );
  };

  render() {
    const { accumulators } = this.props;

    return (
      <>
        {accumulators.map((item, key) => (
          <Accumulator
            icon={Ctx.accumulators[item.unit.toLowerCase()].icon}
            inline
            key={key}
            label={Ctx.accumulators[item.unit.toLowerCase()].label}
            tooltip={item.description}
            value={this.setTextValue(item)}
          >
            {item.isUnlimited ?
              <span className={cx('unlimited')}>{Ctx.accumulators.unlimText}</span>
            : this.renderScale(item)}
          </Accumulator>
        ))}
      </>
    );
  }
}

AccumulatorsList.defaultProps = {
  accumulators: [],
};

AccumulatorsList.propTypes = {
  accumulators: PropTypes.array,
};

export default AccumulatorsList;
