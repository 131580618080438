import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Card } from '@beef/smart-kit';
import { Text } from '@beef/ui-kit';

import { useOnScreenSendAnalytics } from 'pages/MnpOrderNew/hooks';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Benefits = ({ data: { sections } }) => {
  const nodeYMRef = useOnScreenSendAnalytics('Преимущества', true);

  return (
    <section className={cx('benefits')} ref={nodeYMRef}>
      {sections.map((benefit) => (
        <Card className={cx('benefit')} key={benefit.id}>
          <Text color="grey95" size="size3-m">
            {benefit.title}
          </Text>
          <div className={cx('description')}>
            <Text color="grey50" size="size5-r-s">
              {benefit.description}
            </Text>
          </div>
        </Card>
      ))}
    </section>
  );
};

Benefits.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
        popupContent: PropTypes.shape({}),
        button: PropTypes.shape({
          link: PropTypes.string,
          text: PropTypes.string,
        }),
      }),
    ),
  }),
};
