import { ChangeEvent, useCallback } from 'react';
import { shallowEqual } from '@beef/utils';

import { TGetOnChange } from '../types';
import { TabTypes } from '../constants';
import { sendClickYm } from '../tempYmUtils';
import { DEFAULT_TAB_ID } from '../../FmcTabsContainer/constants';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getCurrentConvergenceInfo } from '../../FmcTabsContainer/utils';
import { setConvergenceBlock } from '../../../../store/slices/tariffServicesSlice';
import { usePrepareDataLayer } from '../../../../tempAnalytics/hooks/usePrepareDataLayer';
import { useIsAuthenticated } from '../../../../hooks/tariffStateHooks/useIsAuthenticated';
import { useSelectActiveInacId } from '../../FmcTabsContainer/selectors/useSelectActiveInacId';
import { changeFmcInacId } from '../../../../store/slices/totalSlice/asyncThunks/changeFmcInacId';
import { changeCurrentUserType } from '../../../../store/slices/stepperSlice/asyncThunks/changeCurrentUserType';
import {
  setConnectedConvergenceAddress,
  setTariffTab,
  setViewedTariffTab,
} from '../../../../store/slices/totalSlice';

/** Хук переключения активного таба и обработки сценария подключения */
export const useGetOnChange = ({ setIsOpen, currentTariffTab }: TGetOnChange) => {
  const dispatch = useAppDispatch();
  const pushDataLayer = usePrepareDataLayer();
  const tariffInacId = useSelectActiveInacId();
  const isAuthenticated = useIsAuthenticated();

  const { tariffs, connectedAddress, connectedFmcInacId } = useAppSelector(
    (state) => ({
      connectedAddress: state.common.userInfo.convergenceConnectedInfo?.connectedAddress || '',
      connectedFmcInacId: state.common.userInfo.convergenceConnectedInfo?.inacId || '',
      tariffs: state.fttbPresets.previewTariffs,
    }),
    shallowEqual,
  );

  const defaultConvergenceBlock =
    useAppSelector(
      (state) => state.common.defaultConstructor.defaultTariffData?.convergenceBlock,
    ) || {};

  return useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const currentTab = e?.target?.value as TabTypes;

      /* Массив табов, которые могут быть активными */
      const switchableTabs = [TabTypes.MNPForm, TabTypes.SimOrderForm];

      /* Если мы находимся в АЗ, к активным табам добавляется таб ChangeTariffForm */
      if (isAuthenticated) {
        switchableTabs.push(TabTypes.ChangeTariffForm);
      }

      /* Флаг отвечает за возможность сделать таб выбора способа подключения активным.
       * В НЗ активными табами могут быть только MNPForm и SimOrderForm.
       * В АЗ к ним добавляется ChangeTariffForm */
      const isSwitchable = switchableTabs.includes(currentTab);

      /* Отправка яндекс метрики */
      sendClickYm(currentTab);

      /* Если mnp, то отправляем dataLayer */
      if (currentTab === TabTypes.MNPForm) {
        pushDataLayer('mnp');
      }

      /* Если пользователь имеет подключенный ШПД (присутствует connectedFmcInacId), в зависимости от currentTab,
      либо ставим default-информацию по ШПД для корректного отображения блока подключенного ШПД,
      либо выставляем актуальную информацию по блоку convergenceBlock */
      if (connectedFmcInacId) {
        if (currentTab === TabTypes.ChangeTariffForm) {
          /* Записываем адрес подключенного ШПД в total */
          dispatch(setConnectedConvergenceAddress(connectedAddress));

          /* Записываем default информацию по блоку конвергенции (заголовок, описание, иконка и т.п) */
          dispatch(setConvergenceBlock(defaultConvergenceBlock));

          /* Записываем inacId подключенного ШПД в total */
          dispatch(changeFmcInacId(connectedFmcInacId)).catch((error: Error) =>
            console.error(error),
          );
        } else {
          /** Флаг определяет наличие, в доступных для подключения тарифах ШПД, текущего подключенного inacId тарифа (/fmcpresets/) */
          const hasConnectedInacIdFmcTariff = tariffs.some(
            (tariff) => tariff.inacId === tariffInacId,
          );

          /* Если тарифы ШПД доступны и среди доступных табов ШПД не пришла скорость с инаком, как у текущего подключенного ШПД,
           записываем default inacId ШПД в total */
          if (tariffs.length) {
            dispatch(
              changeFmcInacId(hasConnectedInacIdFmcTariff ? connectedFmcInacId : DEFAULT_TAB_ID),
            ).catch((error: Error) => console.error(error));
          }
          /* Ставим актуальную информацию по блоку конвергенции (заголовок, описание, иконка и т.п) */
          dispatch(
            setConvergenceBlock(
              getCurrentConvergenceInfo(tariffs, tariffInacId)?.convergenceBlock || {},
            ),
          );
        }
      }

      /* Если текущий таб не является "заблокированным" (isSwitchable) для активации (выделение таба активным цветом),
       * диспатчим его значение в store для дальнейшего использования.
       * Вариант eSIMForm покажет информативное окно с возможностью перехода в приложение.
       * Вариант ChangeTariffForm в НЗ предложит залогиниться с последующим переходом в АЗ старого конструктора */
      if (isSwitchable) {
        dispatch(setTariffTab(currentTab));
      }

      /* Устанавливаем текущий просматриваемый пользователем таб способа подключения тарифа.
       * На базе этой информации определяем показ попапа с информацией/авторизацией, либо продолжаем настройку тарифа */
      dispatch(setViewedTariffTab(currentTab));

      /* Определение текущего типа пользователя через общий AsyncThunk */
      dispatch(changeCurrentUserType({ handleModalOpen: () => setIsOpen(true) })).catch(
        (error: Error) => console.error(error),
      );
    },
    [tariffInacId, currentTariffTab],
  );
};
