import { createAsyncThunk } from '@reduxjs/toolkit';

import { TFetchPacketsRes, fetchPackets } from '../../../api/fetchPackets';

/** Тип параметров запроса fetchPackets пакетов ConstructorV1 */
type TFetchPacketsReq = {
  soc: string;
};

/** Обертка запроса мобильных пакетов ConstructorV1 */
export const getPackets = createAsyncThunk<Partial<TFetchPacketsRes>, TFetchPacketsReq>(
  '/api/v1/constructors/getPackets',
  async (params, { signal }) => {
    try {
      return (await fetchPackets({ params, signal })).data;
    } catch (error) {
      throw new Error();
    }
  },
);
