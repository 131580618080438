import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from '@beef/legacy-ui-kit';

import Icon from 'pages-components/Icon';
import Ctx from 'pages-components/Ctx';
import SimOrderPopup from 'widgets/SimOrderPopup/SimOrderPopup';

import styles from './FancyNumberWidget.pcss';
import FancyNumberPopup from '../FancyNumberPopup';

const cx = classNames.bind(styles);

/**
 * Компонент отрисосывает строку таблицы "Красивого номера";
 * @param mnpWidget - Виджет MNP для модального окна;
 * @param number - Красивый номер;
 * @param currentNumber - Объект выбранного красивого номера
 * @param discountValue - ???;
 * @param tariffName - Название тарифа;
 * @param handleRefreshClick - Callback для смены номера;
 * @param infoPopupFetchUrl - URL адрес, по которому будут получены данные для FancyNumberPopup;
 */
const FancyNumberWidget = ({
  mnpWidget,
  number,
  currentNumber,
  discountValue,
  tariffName,
  singleNumber,
  handleRefreshClick,
  infoPopupFetchUrl,
  numberClassName,
  isBought,
  customClassName,
  refreshNumberClick,
}) => {
  const simPopup = useRef(null);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const triggerSimOrderPopup = () => {
    simPopup.current.triggerPopup(true);
  };

  if (singleNumber) {
    return (
      <div
        className={cx('number', customClassName, numberClassName && numberClassName, {
          'number--bought': isBought,
        })}
      >
        <span
          className={cx({ 'number-refresh': refreshNumberClick })}
          onClick={handleRefreshClick}
          role="button"
          tabIndex="0"
        >
          {number}
          <Icon className={cx('refreshIcon')} name="arrowCircled" />
        </span>
      </div>
    );
  }

  return (
    <div className={cx('fancyNumberTilesWidget')}>
      <Link className={cx('fancyNumberButton')} onClick={() => setShowInfoPopup(true)} />
      <div
        className={cx('number', {
          'number--bought': isBought,
        })}
      >
        {number}
        <span onClick={handleRefreshClick} role="button" tabIndex="0">
          <Icon className={cx('refreshIcon')} name="arrowCircled" />
        </span>
      </div>

      <SimOrderPopup
        benefits={[]}
        query={{ leadName: 'PodborSim' }}
        ref={simPopup}
        textFor="mnp"
        texts={{
          title:
            discountValue ?
              Ctx.fancyNumber.cardDiscountText.replace('{{TARIFF_NAME}}', tariffName)
            : Ctx.fancyNumber.cardRegularText.replace('{{TARIFF_NAME}}', tariffName),
        }}
        {...mnpWidget}
      />
      {showInfoPopup && (
        <FancyNumberPopup
          fetchUrl={infoPopupFetchUrl}
          number={currentNumber}
          onClickMNP={triggerSimOrderPopup}
          onClose={() => setShowInfoPopup(false)}
          onRefreshClick={handleRefreshClick}
        />
      )}
    </div>
  );
};

FancyNumberWidget.defaultProps = {
  showAllText: 'Посмотреть все номера',
  singleNumber: false,
};

FancyNumberWidget.propTypes = {
  singleNumber: PropTypes.bool,
  isBought: PropTypes.bool,
  tariffName: PropTypes.string,
  category: PropTypes.string,
  showAllText: PropTypes.string,
  showAllLink: PropTypes.string,
  numberClassName: PropTypes.string,
  infoPopupFetchUrl: PropTypes.string,
  number: PropTypes.string,

  currentNumber: PropTypes.object,
  mnpWidget: PropTypes.object,
  discountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  handleRefreshClick: PropTypes.func,
};

export default FancyNumberWidget;
