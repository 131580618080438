import { useEffect, useRef, useState } from 'react';

import {
  DEFAULT_SLIDE_VIEW,
  MAX_SLIDE_GROUP,
  MAX_SLIDE_VIEW,
  MIN_SLIDE_GROUP,
  MIN_SLIDE_VIEW,
  SPACE_BETWEEN,
} from '../constants';

/** Хук определения настроек свайперва ConstructorSwiper
 * в зависимости от текущего разрешения экрана пользователя */
export const useConstructorSwiperSettings = (className: string) => {
  const prevCarouselBtn = useRef(null);
  const nextCarouselBtn = useRef(null);
  const [slidesPerValue, setSlidesPer] = useState({ view: MIN_SLIDE_VIEW, group: MIN_SLIDE_GROUP });

  /** Функция-обработчик меняющая настройки свайпера при запуске resize (применяется в addEventListener) */
  const handleResize = () => setSlidesPer(viewGroupSettings());

  /** Функция "slidesPer" определяет "view" и "group" для настроек компонента ConstructorSwiper */
  function viewGroupSettings() {
    if (typeof window !== 'undefined') {
      /* Экраны с разрешением от 1260px и более */
      if (window.matchMedia('(min-width: 1260px)').matches) {
        return { view: MAX_SLIDE_VIEW, group: MAX_SLIDE_GROUP };
      }

      /* Экраны с разрешением от 1024px и более */
      if (window.matchMedia('(min-width: 1024px)').matches) {
        return { view: MIN_SLIDE_VIEW, group: MIN_SLIDE_GROUP };
      }

      /* Экраны с разрешением от 480px до 768px */
      if (window.matchMedia('(min-width: 480px) and (max-width: 768px)').matches) {
        return { view: MAX_SLIDE_VIEW, group: MIN_SLIDE_GROUP };
      }

      /* Экраны с разрешением от 375px до 479px */
      if (window.matchMedia('(min-width: 375px) and (max-width: 479px)').matches) {
        return { view: DEFAULT_SLIDE_VIEW, group: MIN_SLIDE_GROUP };
      }
    }

    /* Дефолтное значение настроек просматриваемого элемента и группы.
     Применяется к экранам до 375px */
    return {
      view: MIN_SLIDE_VIEW,
      group: MIN_SLIDE_GROUP,
    };
  }

  /** Функция "paginationSettings" добавляет буллеты в пагинацию */
  const paginationSettings = () => {
    if (slidesPerValue.view < MAX_SLIDE_VIEW) {
      return {
        clickable: true,
        bulletActiveClass: className,
      };
    }

    return false;
  };

  /** Итоговые настройки для ConstructorSwiper на текущем разрешении пользователя */
  const settings = {
    slidesPerView: slidesPerValue.view,
    slidesPerGroup: slidesPerValue.group,
    navigation: {
      prevEl: prevCarouselBtn.current,
      nextEl: nextCarouselBtn.current,
    },
    spaceBetween: SPACE_BETWEEN,
    pagination: paginationSettings(),
    simulateTouch: true,
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return settings;
};
