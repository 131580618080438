import React, { memo } from 'react';

import { PresetPicker } from '../PresetPicker';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setMinValue } from '../../../store/totalSlice';
import { selectStepsMinutes } from './selectors/selectStepsMinutes';
import { selectMinValue } from './selectors/selectMinValue';
import { selectGiftIcon } from '../selectors/selectGiftIcon';

/** Контейнер для мннут ЕК */
export const MinPresetContainer = memo(() => {
  const dispatch = useAppDispatch();
  const stepsMinutes = useAppSelector(selectStepsMinutes);
  const activeMinValue = useAppSelector(selectMinValue);
  const icon = useAppSelector(selectGiftIcon);

  const onChange = (value: number) => {
    dispatch(setMinValue(value));
  };

  return stepsMinutes ?
      <PresetPicker {...{ ...stepsMinutes, icon, activeValue: activeMinValue, onChange }} />
    : null;
});
