import { Button } from '@beef/ui-kit';
import React from 'react';

import { ModalsControllerProps } from './ModalsController/types';
import { ActionBar, ResponseLayout } from './components';

type SuccessModalDataProps = Pick<ModalsControllerProps, 'limit' | 'onClose'> & {
  data: ModalsControllerProps['data']['deactivateSuccessful'];
};

export const useSuccessModalData = ({ onClose, data, limit }: SuccessModalDataProps) => {
  const { title, image, description, buttons } = data || {};
  const { confirm } = buttons || {};
  const desc = description.replace('{{limit}}', limit.toString());

  return {
    modalSize: 'm',
    content: <ResponseLayout description={desc} image={image} title={title} />,
    actionBar: (
      <ActionBar>
        <Button onClick={onClose}>{confirm?.title}</Button>
      </ActionBar>
    ),
  };
};
