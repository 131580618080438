import { axiosBeelineRequest } from '@beef/utils';

import { SERVICE_CONTEXTS_ENDPOINT } from 'constants/Endpoints';
import { SERVICE_SOCS } from 'pages/Services/ScopeZeroBalanceServices/constants';

import { ServiceContexts, ZeroBalanceSocs } from './types';

export const filterServiceInfoBySoc = (serviceInfo: ServiceContexts, soc: ZeroBalanceSocs) =>
  serviceInfo?.find((item: { name: string }) => soc.includes(item.name));

export const fetchServiceNotification = (screenId: string) =>
  axiosBeelineRequest({ url: SERVICE_CONTEXTS_ENDPOINT, params: { screenId } })
    .then(
      ({ contexts }: ServiceContexts) =>
        contexts?.filter((serviceInfoElem) => SERVICE_SOCS.includes(serviceInfoElem?.name)),
    )
    .catch((e: Error) => {
      console.error(e);
      return [];
    });
