import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './index.pcss';

const cx = classNames.bind(styles);

const Banner = ({ imageMobile, imageDesktop, gradient, isMobile }) => {
  if (!isMobile && !imageDesktop) return null;

  return (
    <div style={{ backgroundImage: `url(${gradient})` }}>
      <img alt="" className={cx('image')} src={isMobile ? imageMobile : imageDesktop} />
    </div>
  );
};

Banner.defaultProps = {
  gradient: '',
};

Banner.propTypes = {
  isMobile: PropTypes.bool,
  imageDesktop: PropTypes.string,
  imageMobile: PropTypes.string,
  gradient: PropTypes.string,
};

export default Banner;
