import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Tabs, TabsPropsType } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Обертка над табами попапа описания пассивок */
export const PassiveTabs: FC<Omit<TabsPropsType, 'variant'>> = ({
  tabs,
  selectedTab,
  onChange,
}) => (
  <div className={cx('wrapper')}>
    <Tabs noWrap variant="secondary" {...{ tabs, onChange, selectedTab }} />
  </div>
);
