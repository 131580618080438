import React from 'react';
import { compose, withReduxStore } from '@beef/redux';
import { connect } from 'react-redux';
import { LandingConstructor } from '@beef/landing-constructor';
import { withDataConverter } from '@beef/react';

import { finalResultsDataConverter } from '../store/dataConverters';
import { initializeFinalResultsLanding } from '../store';
import { selectContent } from '../store/selectors';
import { StoryExamples } from '../components/StoryExamples';
import { PhotosBanner } from '../components/PhotosBanner';
import { WinnerBanner } from '../components/WinnerBanner';

const customComponents = { StoryExamples, WinnerBanner, PhotosBanner };

const FinalResultsLandingLayout = ({ constructorBlocks }) => (
  <LandingConstructor blocks={constructorBlocks} customComponents={customComponents} />
);

const mapStateToProps = (state) => ({
  constructorBlocks: selectContent(state).blocks,
});

export const FinalResultsLanding = compose(
  withDataConverter(finalResultsDataConverter),
  withReduxStore(initializeFinalResultsLanding),
  connect(mapStateToProps),
)(FinalResultsLandingLayout);
