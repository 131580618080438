/**
 *  @param{object} - данные из content.filter
 *  @return {Object[]} - выбор категорий из filter в зависимости от feature toggle
 *  */
export const selectCategoriesDependsOnFT = (content) => {
  let selectedCategories;

  if (content?.newCategoriesOfBuyNumbersEnabled) {
    selectedCategories = content?.categoriesBuyNumber;
  } else {
    selectedCategories = content?.categories;
  }

  return selectedCategories;
};

export const selectPriceOptions = (content, category, prices) => {
  let price;

  if (content?.newCategoriesOfBuyNumbersEnabled) {
    price = '';
  } else {
    price = prices[category?.alias]?.price;
  }

  return price;
};
