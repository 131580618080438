import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { Faq } from '@beef/smart-kit';

import { voWifiFaqQuestionYM } from 'pages/Services/VoWifiServiceCardLanding/analytics';
import { selectSupportStatus } from 'pages/Services/VoWifiServiceCardLanding/store/selectors';
import { SupportStatusType, VowifiFAQ } from 'pages/Services/VoWifiServiceCardLanding/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const _FaqContainer: FC<{ data: VowifiFAQ; supportStatus: SupportStatusType }> = ({
  data,
  supportStatus,
}) => {
  const [savedTabId, setSavedTabId] = useState('1');

  const { sections, ...rest } = data;
  const handleOnClick = useCallback(
    (info: { panelId: string; tabId: string }) => {
      const { tabId, panelId } = info;

      if (tabId) {
        setSavedTabId(tabId);
      }

      const categories = sections.find(
        ({ categoryId }: { categoryId: string }) => categoryId === savedTabId,
      );
      const questionTitle =
        categories?.tabs?.find(({ id }: { id: string }) => id === panelId)?.title ?? '';
      // ym
      voWifiFaqQuestionYM(questionTitle, supportStatus);
    },
    [savedTabId],
  );

  return (
    <div className={cn('wrapper')}>
      <Faq data={sections} {...rest} onClick={handleOnClick} />
    </div>
  );
};

export const FaqContainer = connect((state) => ({
  supportStatus: selectSupportStatus(state),
}))(_FaqContainer);
