import React, { memo, useMemo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { ExtendedSteps } from '@beef/landing-constructor';

import Picture from 'pages-components/Picture';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type ServiceControlsCommandsProps = {
  data: {
    commands: { title: string }[];
    image: string;
    tabletImage: string;
    title: string;
  };
};

export const ServiceControlsCommands = memo<ServiceControlsCommandsProps>(
  ({ data: { title, image, commands, tabletImage } }) => {
    const commandsList = useMemo(
      () => commands.map(({ title }, id) => ({ title, id: id.toString() })),
      [commands],
    );
    const isPhone = useIsMobileDimension();

    return (
      <section className={cx('wrapper')}>
        <Text size="size1-m">{title}</Text>
        <div className={cx('grid')}>
          <div className={cx('command-area')}>
            <ExtendedSteps content={[{ steps: commandsList }]} title="" />
          </div>
          <div className={cx('image-area')}>
            <Picture className={cx('image')} src={isPhone ? tabletImage : image} />
          </div>
        </div>
      </section>
    );
  },
);
