import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

// Utils
import { Heading, Status } from '@beef/legacy-ui-kit';
import { Button } from '@beef/ui-kit';
import { getSubstate } from '@beef/legacy-beeline-store/utils';

import { gaShowChangeTariffForm } from 'utils/analytics/yandexTariffGA';
import { showChangeTariffFormYM } from 'utils/analytics/tariffsYandexMetrica';

// Components

import BubbleMessage from 'pages-components/BubbleMessage';
import DisconnectionWarning from 'pages-components/DisconnectionWarning';
import { maskPhone, templateParser } from 'utils/format-string';

import NumbersList from '../../ServicesExtension/SharingService/NumberList/NumberList';

// Actions
import { connectedNumbers } from '../../../actions/sharing';
import { disconnectNumber } from '../../../actions/sharingTariffConnect';

// Styles
import styles from '../TariffConnectPopup.pcss';

const cx = classNames.bind({ ...styles });

const ButtonRender = ({ onClick, text, disabled }) => (
  <Button disabled={disabled} onClick={onClick}>
    {text}
  </Button>
);

const LinkRender = ({ onClick, text, disabled }) => {
  if (!text) {
    return null;
  }

  return (
    <>
      {text && (
        <Button disabled={disabled} onClick={onClick} variant="plain">
          {text}
        </Button>
      )}
    </>
  );
};

class SebConnectForm extends PureComponent {
  statuses = {
    confirmed: 'connected',
    connected: 'connected',
    removing: 'removing',
  };

  componentDidMount() {
    const { currentTariffName, tariffName } = this.props;
    /** GA Показ окна смена тарифа  */
    // TODO: Вынести в хук или хок при рефакторе попапа!!!
    if (currentTariffName && tariffName) {
      gaShowChangeTariffForm(`${currentTariffName} - ${tariffName}`);
      showChangeTariffFormYM(currentTariffName, tariffName, document.location.href);
    }
  }

  handleRemoveClick = (number, status) => {
    if (this.props.onRemoveButtonClick) {
      this.props.onRemoveButtonClick();
    }
    this.props.onDisconnectNumber(number, status);
  };

  formatNumbers(numbers) {
    return numbers.map((e) => ({
      number: maskPhone(e.ctn),
      status: this.statuses[e.status.toLowerCase()] || 'invited',
      name: e.name,
      icon: e.icon,
    }));
  }

  renderFormattedText = (text) => <div dangerouslySetInnerHTML={{ __html: text }} />;

  renderServicesText() {
    const { serviceDescription, serviceList } = this.props;

    return serviceList ?
        templateParser(serviceDescription, { serviceList: `<p>${serviceList}</p>` })
      : '';
  }

  render() {
    const {
      description,
      serviceList,
      confirmButtonText,
      cancelButtonText,
      additionalNumbersText,
      sebOverflowWarning,
      numbers,
      newTariffDescription,
      allowNumberDeleting,
      maxNumbers,
      archiveWarning,
      changeTariffForm,
      customWarning,
      flipButtons,
      yandexPlusWarning,
    } = this.props;

    // Проверяем наличие текста для отображения статуса (Желтая плашка с пальцем вверх);
    const isShowStatus = [customWarning, serviceList, archiveWarning].some((item) => item);
    // Флаг: пользователю нужно удалить [numbers.length - maxNumbers] номеров для продолжения подключения
    const numbersDiff = numbers && numbers.length - maxNumbers;
    const sebOverflow = allowNumberDeleting === true && numbersDiff;

    return (
      <div className={cx('content')}>
        <div className={cx('description')}>
          {this.renderFormattedText(description)}
          {newTariffDescription && <p>{this.renderFormattedText(newTariffDescription)}</p>}

          {changeTariffForm.middleDescription && (
            <p className={cx('middleDescription')}>
              {this.renderFormattedText(changeTariffForm.middleDescription)}
            </p>
          )}
        </div>
        {numbers && numbers.length > 0 && (
          <div className={cx('numbersList')}>
            <Heading className={cx('numbersListHeader')} level={4}>
              {additionalNumbersText}
            </Heading>
            <NumbersList
              controls={allowNumberDeleting}
              numbers={this.formatNumbers(numbers)}
              onRemoveNumber={this.handleRemoveClick}
            />
          </div>
        )}
        {sebOverflow > 0 && (
          <div className={cx('warning')}>
            <Status className="error" emoji="pointingHandUp">
              {this.renderFormattedText(
                templateParser(sebOverflowWarning, {
                  newSebCount: maxNumbers,
                  currentSebCount: numbers.length,
                }),
              )}
            </Status>
          </div>
        )}
        {isShowStatus && (
          <div className={cx('warning')}>
            <Status className="attention" emoji="pointingHandUp">
              {customWarning && (
                <p className={cx('extraText')}>{this.renderFormattedText(customWarning)}</p>
              )}
              {this.renderFormattedText(this.renderServicesText())}
              {serviceList && archiveWarning && this.renderFormattedText('<br/>')}
              {archiveWarning}
            </Status>
          </div>
        )}

        {changeTariffForm.bubble && (
          <div className={cx('bubble')}>
            <BubbleMessage
              content={changeTariffForm.bubble.content}
              icon={changeTariffForm.bubble.icon}
            />
          </div>
        )}

        {yandexPlusWarning && (
          <DisconnectionWarning src={yandexPlusWarning.imageUrl} text={yandexPlusWarning.text} />
        )}

        <div className={cx('buttons')}>
          <div className={cx('confirm-button', 'tc')}>
            {flipButtons ?
              <ButtonRender onClick={this.props.onCancelClick} text={cancelButtonText} />
            : <ButtonRender
                disabled={sebOverflow > 0}
                onClick={this.props.onClick}
                text={confirmButtonText}
              />
            }
          </div>

          {confirmButtonText && (
            <div className={cx('confirm-button', 'tc')}>
              {flipButtons ?
                <LinkRender
                  disabled={allowNumberDeleting && numbersDiff > 0}
                  onClick={this.props.onClick}
                  text={confirmButtonText}
                />
              : <LinkRender
                  disabled={sebOverflow > 0}
                  onClick={this.props.onCancelClick}
                  text={cancelButtonText}
                />
              }
            </div>
          )}
        </div>
      </div>
    );
  }
}

SebConnectForm.propTypes = {
  description: PropTypes.string,
  newTariffDescription: PropTypes.string,
  serviceDescription: PropTypes.string,
  serviceList: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  additionalNumbersText: PropTypes.string,
  allowNumberDeleting: PropTypes.bool,
  numbers: PropTypes.array,
  maxNumbers: PropTypes.number,
  onRemoveButtonClick: PropTypes.func,
  archiveWarning: PropTypes.string,
  changeTariffForm: PropTypes.shape({
    middleDescription: PropTypes.string,
    bubble: PropTypes.shape({
      icon: PropTypes.string,
      content: PropTypes.string,
    }),
  }),
  onDisconnectNumber: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  customWarning: PropTypes.string,
  flipButtons: PropTypes.bool,
  sebOverflowWarning: PropTypes.string,
  yandexPlusWarning: PropTypes.shape({
    text: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
  tariffName: PropTypes.string,
  currentTariffName: PropTypes.string,
};

LinkRender.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,

  onClick: PropTypes.func,
};

ButtonRender.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,

  onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  sharedNumbers: getSubstate(state, connectedNumbers) || [],
  currentTariffName: state?.external?.tariff?.data?.currentTariffName || '',
  tariffName: state?.external?.tariffConnectionPopup?.data?.tariffName || '',
});

const mapDispatchToProps = {
  onDisconnectNumber: disconnectNumber,
};

export const ConnectedSebConnectForm = connect(mapStateToProps, mapDispatchToProps)(SebConnectForm);
