import qs from 'query-string';

import { TARIFF_LAYOUTS } from 'pages/ProductCard/Tariff/tariffTypes';

let firebaseAppId = null;

export const pushOrderEvent = (orderId, success) => {
  window.dataLayer.push({
    event: success ? 'event_newsim_order_success' : 'event_popupMNP_fail',
    ordered_itemCat: 'Tariffs',
    ordered_itemProduct: 'Мобильная связь',
    ordered_itemMethod: 'MNP',
    ordered_itemShipping: 'Не указан',
    orderId,
  });
};

export const setFirebaseAppId = () => {
  firebaseAppId = qs.parse(window.location.search).firebaseAppId || firebaseAppId;
};

const eSimActions = {
  open: 'screen view - esim first screen',
  changeNumber: 'tap - choose another number',
  changeTariff: 'tap - choose another price plan',
  pay: 'tap - first screen pay button',
  payError: 'error - payment process error',
  numberEmpty: 'error - phone list fail',
  tariffsEmpty: 'error - price plane list fail',
};

export const pushESimEvent = (action, payload = {}) => {
  window.dataLayer.push({
    event: 'event_esim_purchase_process',
    eventCategory: 'esim purchase process',
    webviewPurpose: 'esim_purchase_process',
    webviewPlatform: 'mobile',
    eventAction: eSimActions[action],
    firebaseAppId,
    ...payload,
  });
};

// -- Ecommerce events --

const mapNumber = (category, filter, price) => ({
  id: `Номер - ${category}`,
  name: `Номер - ${category}`,
  variant: category,
  price: +price || 0,
  brand: 'Sim - Numbers',
  category: filter,
});

const getVariantTariff = ({
  id,
  soc,
  layoutType,
  subscriptionFee,
  trafficPackage,
  minutesPackage,
  gbytes,
  minutes,
}) => {
  if (layoutType === TARIFF_LAYOUTS.constructor) {
    return `${id}+${soc}`;
  }

  return (
    `${id}+${soc} | ${subscriptionFee} - ${subscriptionFee} ` +
    `| ${gbytes?.value || trafficPackage || 0}_${minutes?.value ?? minutesPackage ?? 0}`
  );
};

const mapTariff = (
  { id, pageTitle, subscriptionFee, soc, trafficPackage, minutesPackage, layoutType },
  { gbytes, minutes },
) => ({
  id,
  name: `Тариф - ${pageTitle}`,
  price: subscriptionFee,
  brand: 'Sim - Tariffs',
  category: 'Тарифы/Тарифы для смартфонов',
  variant: getVariantTariff({
    id,
    soc,
    layoutType,
    subscriptionFee,
    trafficPackage,
    minutesPackage,
    gbytes,
    minutes,
  }),
});

const mnpDefault = {
  id: 'mnp',
  name: 'mnp',
  price: 1000.0,
  discount: 0.0,
  brand: 'Sim - MNP',
  category: 'MNP',
  variant: 'mnp',
  quantity: 1,
  list: null,
  position: 1,
};

const mnpActionFieldDefault = {
  id: '20106-46333831',
  goal_id: ['231552286', '232077791'],
  affiliation: 'B2C - SHOP',
  revenue: 500,
  tax: '',
  shipping: 0,
  coupon: '',
};

const ecommersEvent = (payload) => ({ ecommerce: { currencyCode: 'RUB', ...payload } });
const detailEvent = (products) => ({ detail: { products } });
const addEvent = (products) => ({ add: { products } });
const purchaseEvent = (actionField, products) => ({ purchase: { actionField, products } });

export const pushEcommerceTariffView = (tariffData) => {
  window.dataLayer.push(ecommersEvent(detailEvent([mapTariff(tariffData, {})])));
};

let viewNumberPushed = false;
export const pushEcommerceNumberView = () => {
  if (!viewNumberPushed) {
    window.dataLayer.push(
      ecommersEvent(detailEvent([mapNumber('Бесплатный', 'Тарифы/Тарифы для смартфонов', 0)])),
    );
    viewNumberPushed = true;
  }
};

const ecommerceSimData = (tariffData, constructorParams) => [
  mapTariff(tariffData, constructorParams || {}),
  mapNumber('Бесплатный', 'Тарифы/Тарифы для смартфонов', 0),
];

export const pushEcommerceBuySim = (tariffData, constructorParams) => {
  window.dataLayer.push({
    event: 'addToCart',
    ...ecommersEvent(addEvent(ecommerceSimData(tariffData, constructorParams))),
  });
};

export const pushEcommerceViewSim = (tariffData, constructorParams) => {
  window.dataLayer.push({
    ...ecommersEvent(detailEvent(ecommerceSimData(tariffData, constructorParams))),
  });
};

const getExtraEcommerceMnpDetails = (id) => ({
  id,
  name: 'Тариф - Близкие люди 2',
  price: 0.0,
  brand: 'Sim - MNP',
  category: 'MNP',
  variant: 'mnp',
  quantity: 1,
  list: null,
  position: 1,
});

export const pushEcommerceShowMnp = (id) => {
  window.dataLayer.push(ecommersEvent(detailEvent([mnpDefault, getExtraEcommerceMnpDetails(id)])));
};

export const pushEcommerceBuyMnp = (id) => {
  window.dataLayer.push(ecommersEvent(addEvent([mnpDefault, getExtraEcommerceMnpDetails(id)])));
};

export const pushEcommerceSuccessBuyMnp = (id) => {
  window.dataLayer.push(
    ecommersEvent(
      purchaseEvent(mnpActionFieldDefault, [mnpDefault, getExtraEcommerceMnpDetails(id)]),
    ),
  );
};
