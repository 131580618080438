import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { LeftSide } from './components/LeftSide';
import { RightSide } from './components/RightSide';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Family = ({
  icon,
  link,
  unit,
  title,
  price,
  unitOld,
  checked,
  linkText,
  description,
  openFamilyModal,
  showDiscount,
  currentDiscountPrice,
  showNotification,
  isDisableFamilyButton,
}) => (
  <div className={cx('wrapper')}>
    <LeftSide
      description={description}
      icon={icon}
      isDisableFamilyButton={isDisableFamilyButton}
      link={link}
      linkText={linkText}
      openFamilyModal={openFamilyModal}
      showNotification={showNotification}
      title={title}
    />
    <RightSide
      checked={checked}
      currentDiscountPrice={currentDiscountPrice}
      price={price}
      showDiscount={showDiscount}
      unit={unit}
      unitOld={unitOld}
    />
  </div>
);

Family.propTypes = {
  icon: PropTypes.node,
  link: PropTypes.string,
  unit: PropTypes.string,
  checked: PropTypes.bool,
  title: PropTypes.string,
  price: PropTypes.number,
  unitOld: PropTypes.string,
  linkText: PropTypes.string,
  discount: PropTypes.shape({
    unit: PropTypes.string,
    oldPrice: PropTypes.number,
  }),
  description: PropTypes.string,
  openFamilyModal: PropTypes.func,
  showDiscount: PropTypes.bool,
  currentDiscountPrice: PropTypes.number,
  showNotification: PropTypes.string,
  isDisableFamilyButton: PropTypes.bool,
};

export { Family };
