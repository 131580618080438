import React, { FC } from 'react';
import classNames from 'classnames/bind';
// Components
import { Text } from '@beef/ui-kit';

import { HeadingProps } from './types';
// Styles
import styles from './styles.pcss';
// utils
import { isNumber } from '../utils';

const cx = classNames.bind(styles);

const Heading: FC<HeadingProps> = ({
  price,
  unit,
  title,
  isConvergence,
  successIcon,
  isChangeTariff,
  isAuthenticated,
}) => (
  <div className={cx('container')}>
    <div className={cx('title')}>
      {title && <Text size="size4-r">{title}</Text>}
      {isConvergence && isChangeTariff && successIcon && (
        <img alt="successIcon" src={successIcon} />
      )}
    </div>
    <div className={cx('container__cost')}>
      {isNumber(price) && (!isAuthenticated || (isAuthenticated && isChangeTariff)) && (
        <div className={cx('price')}>
          <Text color="grey60" size="size4-r">
            {price} {unit}
          </Text>
        </div>
      )}
    </div>
  </div>
);

export { Heading };
