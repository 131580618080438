import axios from 'axios';

const mapOffers = (offers) => {
  const result = {
    tariffs: [],
    rejectUrls: [],
  };

  offers.forEach((offer) => {
    if (offer.items) {
      result.tariffs.push(...offer.items);
    }
    if (offer.buttonResetUrl) {
      result.rejectUrls.push(offer.buttonResetUrl);
    }
    result.title = result.title || offer.title;
    result.buttonResetText = result.buttonResetText || offer.buttonResetText;
    result.basicTariffUrl = result.buttonUrl || offer.buttonUrl;
  });

  return result;
};

export const useOffersDataMapper = (data, onSubmit) => {
  const { rejectUrls, basicTariffUrl, connectPopupUrlTest, ...restOffersData } = mapOffers(
    data.offers,
  );
  const onOffersReject = () => {
    rejectUrls.forEach((rejectUrl) => axios.post(rejectUrl));
    onSubmit(data.defaultPopupUrl, { ...data.requestConstructorParams });
  };

  return {
    ...restOffersData,
    onOffersReject,
  };
};
