import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Button } from '@beef/legacy-ui-kit';

import Icon from 'pages-components/Icon';

import styles from '../../styles.pcss';

const cx = classNames.bind(styles);

const picSrc = '//static.beeline.ru/upload/images/new-shpd-catalog/voprosi/trubka.svg';

export const RenderTechSupport = ({ texts }) => (
  <div className={cx('wrapper', 'techSupport')}>
    <div className={cx('desktopOnly')}>
      <div
        className={cx('techSupportText')}
        dangerouslySetInnerHTML={{ __html: texts.techSupportText }}
      />
      <div className={cx('techSupportPhoneWrapper')}>
        <img alt="" className={cx('techSupportIcon')} src={picSrc} />
        <div
          className={cx('techSupportPhone')}
          dangerouslySetInnerHTML={{ __html: texts.techSupportPhone }}
        />
      </div>
    </div>
    <div className={cx('mobileOnly')}>
      <Button className={['big', cx('phoneBtn')]} color="light" href={texts.techSupportPhoneHref}>
        <span dangerouslySetInnerHTML={{ __html: texts.techSupportPhone }} />
        <Icon className={cx('phoneBtnIcon')} name="phone-material" />
      </Button>
      <div
        className={cx('techSupportText')}
        dangerouslySetInnerHTML={{ __html: texts.techSupportText }}
      />
    </div>
  </div>
);

RenderTechSupport.propTypes = {
  texts: PropTypes.shape({
    techSupportText: PropTypes.string,
    techSupportPhone: PropTypes.string,
    techSupportPhoneHref: PropTypes.string,
  }),
};
