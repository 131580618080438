export const SUPPORT_STATUSES = {
  NOT_SUPPORTED: 'NOT_SUPPORTED',
  NOT_SUPPORTED_WITH_RESTRICTION: 'NOT_SUPPORTED_WITH_RESTRICTION',
  SUPPORTED: 'SUPPORTED',
};

export const ERRORS_SCREEN_STATUSES = {
  DEFAULT_ERROR: 'defaultError',
  SERVER_ERROR: 'serverError',
};
