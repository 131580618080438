import { useState } from 'react';

/**
 * Функция управления модальным окном;
 */
export const useModal = ({ isConnected, isChangeTariff, isAuthenticated }) => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = () => setShowModal(!showModal);

  const openFamilyModal = (e) => {
    if (!isConnected || !isAuthenticated || !isChangeTariff) {
      e.preventDefault();
      handleModal();
    }
  };

  return { showModal, handleModal, openFamilyModal };
};
