import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TDescription = {
  description?: string;
  title?: string;
};

/** Компонент описания для блока скрытых сервисов */
export const Description: FC<TDescription> = ({ title, description }) => (
  <div className={cx('wrapper')}>
    {title && <Text size="size3-m">{title}</Text>}
    {description && <Text size="size4-r">{description}</Text>}
  </div>
);
