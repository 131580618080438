import { useEffect } from 'react';
import type { AnyAction } from '@reduxjs/toolkit';
import { useWillMount } from '@beef/react';
import { EHeaderTheme, setHeaderTheme } from '@carnica/smart-kit';

import { useAppDispatch } from 'constructorV1/store';
import { getInitialData } from 'constructorV1/store/tariffSettingsSlice';
import { useThrowAsyncError } from 'constructorV1/hooks/useThrowAsyncError';
import { headerStore2024 } from 'store';

/** Хук старта страницы ЕК */
export const useStartConstructor = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();

  /** TODO: Хук инициализации темы для HeaderV2. Заменить на useInitialHeaderTheme после обновления до 24.20  */
  useWillMount(() => {
    try {
      headerStore2024().dispatch(setHeaderTheme(EHeaderTheme.primary));
    } catch (e) {
      console.error(e);
    }
  });

  useEffect(() => {
    dispatch(getInitialData() as unknown as AnyAction)
      .unwrap()
      .catch((e: Error) => throwAsyncError(e));
  }, []);
};
