import { useCallback } from 'react';

import { yaMNPMetric } from '../../../analytics';
import { useAppSelector } from '../../../store';
import { selectFAQContent } from '../../../store/slices/commonSlice/selectors';

/**
 * Хук для данных блока faq
 */
export const useFAQSection = () => {
  const faqContent = useAppSelector(selectFAQContent);

  /**
   * Хендлер для отправки аналитики по клику на элемент блока FAQ
   * Используем нативный вызов метода класса аналитики, поскольку
   * нет возможности вызвать метод с передачей нескольких параметров
   */
  const handleSendFAQAnalitic = useCallback((flag: boolean, titleBlock: string) => {
    if (!flag) {
      yaMNPMetric.handleClickShowFAQItem(titleBlock);
    } else {
      yaMNPMetric.handleClickHideFAQItem(titleBlock);
    }
  }, []);

  return {
    ...faqContent,
    handleSendFAQAnalitic,
  };
};
