import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Card } from '@beef/smart-kit';
import { Text } from '@beef/ui-kit';

import Picture from 'pages-components/Picture';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type Benefit = {
  description: string;
  icon: string;
  position: number;
  title: string;
};

type BenefitsProps = {
  data: Benefit[];
};

export const Benefits = memo<BenefitsProps>(({ data }) => (
  <section className={cx('benefits')}>
    {data.map((benefit) => {
      const { title, icon, description } = benefit;
      return (
        <Card className={cx('benefit')} key={benefit.position}>
          <Picture className={cx('icon')} src={icon} />
          <Text color="grey95" size="size4-m">
            {title}
          </Text>
          <div className={cx('description')}>
            <Text color="grey50" size="size5-r-l">
              {description}
            </Text>
          </div>
        </Card>
      );
    })}
  </section>
));
