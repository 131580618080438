import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { getCurrentNumber } from '../selectors/change';

export const setNumber = createAction('set change number');
export const setAwaitingSmsCode = createAction('set change sms code awaiting');
export const setSmsLoadingStatus = createAction('set change sms loading status');
export const setSuccessStatus = createAction('set change success status');
export const setError = createAction('set change error');

export const ErrorGeneralFault = 'generalfault';
export const ErrorBalanceNotEnough = 'balancenotenough';
export const ErrorIncorrectSmsConfirmationCode = 'incorrectsmsconfirmationcode';

const ENDPOINT = {
  changenumber: '/changenumber/change/',
};

export const setCurrentNumber = (payload) => (dispatch) => {
  dispatch(setNumber(payload));
};

export const resetError = () => (dispatch) => {
  dispatch(setError(null));
};

export const getSmsCode = () => async (dispatch, getState) => {
  const currentNumber = getCurrentNumber(getState());

  if (!currentNumber) {
    return;
  }

  const { data } = await axios.put(ENDPOINT.changenumber, {
    changetonumber: currentNumber.value,
    featurecode: currentNumber.featureCode,
    cost: currentNumber.price,
    findnumberrule: 9,
    numbercategory: currentNumber.category,
  });

  if (data && data.isSucceeded) {
    dispatch(setAwaitingSmsCode(true));
  } else if (data && data.code) {
    dispatch(setError(data.code));
  } else {
    dispatch(setError(ErrorGeneralFault));
  }
};

export const sendSms = (code) => async (dispatch, getState) => {
  dispatch(setSmsLoadingStatus(true));

  const currentNumber = getCurrentNumber(getState());

  if (!currentNumber) {
    return;
  }

  try {
    const { data } = await axios.put(ENDPOINT.changenumber, {
      changetonumber: currentNumber.value,
      featurecode: currentNumber.featureCode,
      cost: currentNumber.price,
      findnumberrule: 9,
      numbercategory: currentNumber.category,
      confirmationcode: code,
    });

    if (data && data.isSucceeded) {
      dispatch(setSuccessStatus(true));
    } else if (data && data.code) {
      dispatch(setError(data.code));
    } else {
      dispatch(setError(ErrorGeneralFault));
    }
  } catch (e) {
    dispatch(setSmsLoadingStatus(false));
  }
};
