import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import { Button } from '@beef/legacy-ui-kit';

import documentReady from 'utils/document-ready';

import { initConnectionPopup, showServiceConnectionPopup } from '../../actions/serviceConnect';
import serviceStyles from '../../ProductCard.pcss';
import ServiceConnectPopup from '../ServiceConnectPopup/ServiceConnectPopup';

class ServiceConnection extends Component {
  componentDidMount() {
    const { onInit, data } = this.props;
    const { isAuthenticated } = data;
    if (typeof onInit === 'function') onInit(data);

    if (isAuthenticated && qs.parse(window.location.search).connect === 'true') {
      documentReady(() => this.handleSubmit('query'));
    }
  }

  handleSubmit = (type) => {
    const { data, showConnectionPopup, submitConnect, onLoadConnect } = this.props;
    const { connectButtonVersion, isAuthenticated } = data || {};
    if (isAuthenticated) {
      if (type === 'query') {
        onLoadConnect();
      } else {
        submitConnect();
      }
    } else if (connectButtonVersion && connectButtonVersion > 1) {
      showConnectionPopup();
    } else {
      window.QA.Beeline.Pages.MobileService.Bill.connect();
    }
  };

  render() {
    const { children, showConnect, connectionText, onConnect, isDisabled, data, statusComponent } =
      this.props;
    const {
      isAuthenticated,
      isConnected,
      connectedText,
      warningText,
      archiveText,
      isConnectedLater,
      isArchived,
      canBeRemoved,
    } = data || {};

    const ServiceStatus = statusComponent;

    return (
      <div>
        {showConnect ?
          <div className={serviceStyles.connectButtons}>
            <Button
              className={['big', serviceStyles.buttons]}
              disabled={isDisabled}
              onClick={isAuthenticated ? onConnect : this.handleSubmit}
              wide
            >
              {connectionText}
            </Button>
          </div>
        : null}
        <ServiceStatus
          archiveContent={archiveText}
          canBeRemoved={canBeRemoved}
          connectedContent={connectedText}
          data={this.props.data}
          disconnectContent="Отключить"
          isArchived={isArchived}
          isConnected={isConnected}
          isConnectedLater={isConnectedLater}
          warningContent={warningText}
        />
        {children}
        <ServiceConnectPopup />
      </div>
    );
  }
}

ServiceConnection.defaultProps = {
  onInit: () => {},
  submitConnect: () => {},
  showConnectionPopup: () => {},
  onConnect: () => {},
  onLoadConnect: () => {},
};

ServiceConnection.propTypes = {
  onInit: PropTypes.func,
  submitConnect: PropTypes.func,
  showConnectionPopup: PropTypes.func,
  onConnect: PropTypes.func,
  connectionText: PropTypes.string,
  data: PropTypes.object,
  showConnect: PropTypes.bool,
  isDisabled: PropTypes.bool,
  statusComponent: PropTypes.func,
  onLoadConnect: PropTypes.func,
};

const mapDispatchToProps = (dispatch, { isAuthenticated, data }) => ({
  onInit: () => dispatch(initConnectionPopup(data.connectPopupUrl, data.alias, 'true', data.soc)),
  showConnectionPopup: () => dispatch(showServiceConnectionPopup({ isAuthenticated })),
});

export default connect(null, mapDispatchToProps)(ServiceConnection);
