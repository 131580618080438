import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { ImageCard, PageFixer, PageRow } from '@beef/smart-kit';
import { Text } from '@beef/ui-kit';

import { ZeroBalanceSupportContext } from 'pages/Services/ZeroBalanceSupport/context';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ImageCardContainer = () => {
  const {
    content: {
      imageCards: { content, title },
    },
  } = useContext(ZeroBalanceSupportContext);

  return (
    <PageRow space="64">
      <PageFixer>
        {title && (
          <div className={cx('title-wrapper')}>
            <Text level={2} size="size1-m" tagType="h2">
              {title}
            </Text>
          </div>
        )}
        <div className={cx('component')}>
          {content.map((card) => (
            <div className={cx('item')} key={card.title}>
              <ImageCard
                bordered={card.bordered}
                button={card.button}
                img={card.img}
                size="m"
                text={card.text}
                title={card.title}
              />
            </div>
          ))}
        </div>
      </PageFixer>
    </PageRow>
  );
};
