import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from '@beef/ui-kit';
import { TariffTileLayout } from '@beef/smart-kit';
import classNames from 'classnames/bind';

import { Tariff } from '../Tariff';
import { reduceExtendedData } from '../../utils';
import styles from './styles.module.pcss';

const cx = classNames.bind(styles);

const defaultLengthVisibilityIndex = 3;

const Extended = ({ data, authStatus }) => {
  const [reducedData, setReducedData] = useState([]);
  const [visibilityIndex, setVisibilityIndex] = useState(defaultLengthVisibilityIndex);

  const changeVisible = useCallback(() => {
    setVisibilityIndex((prevState) => prevState + defaultLengthVisibilityIndex);
  }, [reducedData]);

  useEffect(() => {
    setReducedData(reduceExtendedData(data));
  }, [data]);

  return (
    reducedData.length > 0 && (
      <div className={cx('extended')}>
        <div className={cx('extended__tariffs')}>
          {reducedData.map((item, idx) =>
            idx < visibilityIndex ?
              <div
                className={cx('extended-item', !item.title && 'extended-item--without-title')}
                key={item.tariff.id}
              >
                <div className={cx('extended-item__wrapper')}>
                  {item.title && (
                    <div className={cx('extended-item__title')}>
                      <Text color="grey95" size="size3-m" tagType="h2">
                        {item.title}
                      </Text>
                    </div>
                  )}

                  <TariffTileLayout className={cx('tariff-tile-layout')}>
                    <Tariff authStatus={authStatus} data={item.tariff} />
                  </TariffTileLayout>
                </div>
              </div>
            : null,
          )}
        </div>

        {visibilityIndex < reducedData.length && (
          <div className={cx('extended__show-tariffs-button')}>
            <Button fullWidth onClick={changeVisible} size="l" type="button" variant="tertiary">
              {data.moreButtonText}
            </Button>
          </div>
        )}
      </div>
    )
  );
};

Extended.propTypes = {
  authStatus: PropTypes.string,
  data: PropTypes.shape({
    type: PropTypes.string,
    moreButtonText: PropTypes.string,
    items: PropTypes.array,
  }),
};

export { Extended };
