import { pathOr } from 'ramda';

import {
  pushMobileTariffConnectEvent,
  pushTariffConnectionErrorEvent,
  pushTariffDownsellShowEvent,
} from 'utils/ga';

export const errorConnectionEvent = (content) => (dispatch, getState) => {
  const state = getState();

  const { currentTariffName, currentTariffSoc, marketCode, tariffName, soc } = pathOr(
    {},
    ['external', 'tariff', 'data'],
    state,
  );

  const { ctn } = pathOr('', ['external', 'currentCtn'], state);

  pushTariffConnectionErrorEvent({
    currentTariffName,
    currentTariffSoc,
    error: content,
    tariffName,
    marketCode,
    soc,
    ctn,
  });
};

export const tariffDownsellShowEvent = (tariffs) => {
  pushTariffDownsellShowEvent({
    downsellTariffName: tariffs.title,
    downsellSoc: tariffs.soc,
    downsellMarketCode: tariffs.marketCode,
  });
};

export const mobileConnectEvent = (data) => (dispatch, getState) => {
  const { external } = getState();
  const { tariff } = external;
  const { ctn, orderId, requestStatus } = data;
  const { currentTariffName, currentTariffSoc, tariffName, marketCode, soc } = tariff.data;

  pushMobileTariffConnectEvent({
    isOrder: requestStatus === 'IN_PROGRESS',
    currentTariffName,
    currentTariffSoc,
    tariffName,
    marketCode,
    orderId,
    ctn,
    soc,
  });
};
