import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../..';

/**
 * Стейт с дополнительными данными
 */
export const selectAdditionalDataState = (state: RootState) => state.additionalData;

/**
 * Id тарифа
 */
export const selectTarrifId = createSelector(selectAdditionalDataState, (state) => state.id);

/**
 * Флаг того, что это mnpService
 */
export const selectIsMNPService = createSelector(
  selectAdditionalDataState,
  (state) => state.isMnpService,
);

/**
 * Флаг авторизации пользователя
 */
export const selectIsAuth = createSelector(selectAdditionalDataState, (state) => state.isAuth);
