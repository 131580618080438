import React, { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { PropsWithClassName } from '@beef/ui-kit/types';

import { Heading } from '../../Heading';
import style from './styles.pcss';
import { CardsWrapperPropsType } from '../types';

const cn = classNames.bind(style);

export const CustomCardsWrapper = ({
  children,
  title,
  description,
  link,
  size = 's',
  type,
  className,
}: PropsWithChildren<PropsWithClassName<CardsWrapperPropsType>>) => (
  <div className={className}>
    {title && <Heading description={description} link={link} title={title} />}
    <div className={cn('container', `container--${size}`)}>
      {React.Children.map(children, (child) => React.cloneElement(child, { type, size }))}
    </div>
  </div>
);
