import React from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@beef/ui-kit';

import { SkeletonDevice } from 'pages/SearchResultsPage/components/Preload/SkeletonDevice';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const Preload = () => (
  <div className={cn('container')}>
    <Skeleton className={cn('title', 'banner-mini')} />
    <div className={cn('category')}>
      <Skeleton className={cn('banner')} />
      <Skeleton className={cn('banner')} />
      <Skeleton className={cn('banner')} />
      <Skeleton className={cn('banner')} />
    </div>
    <div className={cn('layout')}>
      <aside className={cn('aside')}>
        <div className={cn('aside__left')}>
          <Skeleton className={cn('banner-mini')} />
          <Skeleton className={cn('banner-mini')} />
          <Skeleton className={cn('banner-mini')} />
          <Skeleton className={cn('banner-mini')} />
          <Skeleton className={cn('banner-mini')} />
          <Skeleton className={cn('banner-mini')} />
        </div>
        <div className={cn('aside__right')}>
          <Skeleton className={cn('banner-mini')} />
          <Skeleton className={cn('banner-mini')} />
          <Skeleton className={cn('banner-mini')} />
          <Skeleton className={cn('banner-mini')} />
          <Skeleton className={cn('banner-mini')} />
          <Skeleton className={cn('banner-mini')} />
        </div>
      </aside>
      <div className={cn('columns_right')}>
        <div className={cn('body')}>
          <div className={cn('body__filters')}>
            <Skeleton className={cn('banner-mini')} />
            <Skeleton className={cn('banner-mini')} />
          </div>
          <Skeleton className={cn('banner-mini', 'body__title')} />
          <div className={cn('body__devices')}>
            <SkeletonDevice />
            <SkeletonDevice />
            <SkeletonDevice />
            <SkeletonDevice />
            <SkeletonDevice />
            <SkeletonDevice />
            <SkeletonDevice />
            <SkeletonDevice />
          </div>
        </div>
      </div>
    </div>
  </div>
);
