export const FttbBlocks = {
  ADDITIONAL_INFO: 'additionalInfo',
  ADDITIONAL_SERVICES: 'additionalServices',
  ADVANTAGES_CARDS: 'advantagesWidget',
  BANNER: 'mainBanner',
  CHECK_ADDRESS: 'checkAddress',
  CONNECTION_CARDS: 'aboutConnectionWidget',
  DEVICES: 'equipment',
  FAQ: 'faq',
  HEADING: 'header',
  HOME_TARIFFS_BANNER: 'homeTariffsBanner',
  INFO_CARDS: 'mainWidget',
  LEAVE_PHONE: 'connectionFeedback',
  NEWS: 'news',
  QUIZ: 'game',
  TARIFFS: 'tariffs2022',
};

export const DEVICE_PAYMENT_TYPE = {
  available: 'available',
  preEnabled: 'preEnabled',
};

export const VkGoalEvent = {
  shpdInitiateAddress: 'shpd_initiate_address',
  shpdInitiateCallback: 'shpd_initiate_callback',
  shpdInitiateNotAddress: 'shpd_initiate_notaddress',
  shpdLead: 'shpd_lead',
  shpdOpenForm: 'shpd_open_form',
  shpdOrderedACall: 'shpd_ordered_a_call',
  shpdOrderedCallback: 'shpd_ordered_callback',
  shpdTvIntInitiateCheckout: 'shpd_tv_int_initiate_checkout',
  shpdTvIntOpenForm: 'shpd_tv_int_open_form',
  shpdTvIntPurchase: 'shpd_tv_int_purchase',
  shpdTvLead: 'shpd_tv_lead',
  shpdTvOpenForm: 'shpd_tv_open_form',
  shpdYandexLead: 'shpd_yandex_lead',
  shpdYandexPodkluchit: 'shpd_yandex_podkluchit',
};
