/**
 * Функция генерирует массив чисел от {min} до {max} с шагом {gap}
 */
export const generateSteps = (min: number, max: number, gap: number): number[] => {
  const steps = [];
  for (let j = min; j <= max; j += gap) {
    steps.push(j);
  }
  return steps;
};

export const getChosenStep = (
  min: number,
  max: number,
  gap: number,
  chosenValue: null | number,
): number => {
  let defaultStep = 0;
  for (let j = min; j <= max; j += gap) {
    if (j === chosenValue) return defaultStep;
    defaultStep += 1;
  }
  return defaultStep;
};
