import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@beef/ui-kit';

import { SectionsSkeleton } from './components/SectionsSkeleton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Заглушка для загрузки предварительных изменений по подключенному тарифу Up */
export const PreChangesSkeleton: FC = () => (
  <div className={cx('wrapper')}>
    <div className={cx('wrapper__description')}>
      <SectionsSkeleton />
      <SectionsSkeleton />
    </div>
    <Skeleton className={cx('wrapper__button')} />
  </div>
);
