import { useIsDesktopDimension } from '@beef/ui-kit';
import { useEffect, useState } from 'react';
import { throttle } from '@beef/utils';

/**
 * Хук для определения размеров контейнера,
 * по которому будет перемещаться форма для
 * переноса номера
 */
export const useMNPForm = () => {
  /** Нижняя граница зоны перемещения,
   * является высотой всего блока
   * для перемещения формы
   */
  const [lowerLimitMovingArea, setLowerLimitMovingArea] = useState(0);

  /**
   * Значение отступа от верхнего края экрана;
   * начальная точка позиционирования формы
   */
  const [heightReferencePoint, setHeightReferencePoint] = useState(0);

  const isDesktop = useIsDesktopDimension();

  useEffect(() => {
    /**
     * Последний блок, до которого должна
     * перемещаться форма
     */
    const lowerBlock = document.querySelector('#cards');

    /**
     * Блок, от которого рассчитываем начальную точку
     */
    const contentStartBlock = document.querySelector('#info-block');

    if (!lowerBlock || !contentStartBlock) {
      return undefined;
    }

    /** Батчинг значений блоков */
    const blocksRectValues = () => {
      return {
        startBlockTop: contentStartBlock.getBoundingClientRect().top,
        lowerBlockBottom: lowerBlock.getBoundingClientRect().bottom,
        scrollY: window.scrollY,
      };
    };

    /**
     * Изменения стейтов делаем через ресайз, чтобы в случае
     * изменения размеров окна мы получали правильные значения
     */
    const onResize = throttle({ delay: 100, leading: true, trailing: true }, () => {
      const { lowerBlockBottom, scrollY, startBlockTop } = blocksRectValues();

      /**
       * Начальная точка находится напротив тектового блока стратового экрана,
       * за вычетом высоты хедера, также добавляем к начальной точке величину скролла,
       * поскольку обновить страницу можно в любой момент, в связи с чем и начальная точка
       * должна сместиться на вееличину скролла
       */
      setHeightReferencePoint(startBlockTop - 76 + scrollY);

      /**
       * Общая высота контейнера определяется как нижняя точка последнего нужного блока
       * за вычетом той высоты, которую мы установили как изначальную для формы относительно блока контента,
       * плюс высота хедера (величина скролла не нужна, поскольку область - не перемещается,
       * а лишь меняет размеры)
       */
      setLowerLimitMovingArea(lowerBlockBottom - startBlockTop + 76);
    });

    /** Первичный вызов установки размеров */
    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return {
    lowerLimitMovingArea,
    heightReferencePoint,
    isDesktop,
  };
};
