import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { Checkbox, Heading, Status, Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import ButtonWithPreloader from 'pages-components/ButtonWithPreloader';
import PaymentButtons from 'widgets/PaymentForm/PaymentButtons';
import {
  submitOrderPaymentForm,
  toggleOrderPaymentForm,
  toggleOrderPaymentResultPopup,
} from 'widgets/actions/orderPaymentForm';
import { getDeliveryFormData } from 'widgets/actions/deliveryForm';
import { toggleConnectPopup } from 'pages/ProductCard/actions/tariffConnect/tariffConnectExtension';
import Captcha from 'pages/Feedback/components/Captcha/Captcha';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class OrderPaymentForm extends Component {
  state = {
    agreementChecked: false,
    showCheckboxWarning: false,
    showError: false,
    errorMessage: '',
    pending: false,
    captcha: '',
    captchaRequired: false,
    returnUrl: '',
  };

  onAgreementChecked = () => {
    this.setState({ agreementChecked: !this.state.agreementChecked });
  };

  checkValidCheckbox = () => {
    if (!this.props.texts.agreementCheckbox) {
      return true;
    } else if (!this.state.agreementChecked) {
      this.setState({ showCheckboxWarning: true });
      return null;
    }
    this.setState({ showCheckboxWarning: false });
    return true;
  };

  onSubmit = async () => {
    const { captchaRequired: needCaptcha, captcha } = this.state;

    if ((needCaptcha && captcha.length !== 5) || !this.checkValidCheckbox()) {
      return;
    }

    this.setState({ pending: true, showError: false, errorMessage: '' });

    const { showErrorPopup, showError, captchaRequired, errorMessage } =
      await this.props.submitOrderPaymentForm({
        orderPlace: this.props.orderPlace,
        captchaKey: this.props.captchaKey,
        tariffName: this.props.tariffName,
        numberCategory: this.props.numberCategory,
        soc: this.props.soc,
        captcha,
        getDeliveryData: this.props.getDeliveryData,
      });

    if (showErrorPopup) {
      this.setState({ pending: false });
      this.props.closeOrderPaymentForm();
      this.props.openOrderPaymentFailPopup();
    }

    if (showError) {
      this.setState({
        pending: false,
        showError: true,
        captchaRequired,
        errorMessage,
      });
    }
  };

  onCaptchaChange = (captcha) => {
    this.setState({ captcha });
  };

  onPayButtonClick = async () => {
    this.setState({ pending: true, showError: false, errorMessage: '' });

    const { showError, errorMessage, captchaRequired, returnUrl } =
      await this.props.submitOrderPaymentForm({
        orderPlace: this.props.orderPlace,
        captchaKey: this.props.captchaKey,
        tariffName: this.props.tariffName,
        numberCategory: this.props.numberCategory,
        soc: this.props.soc,
        captcha: this.state.captcha,
        payButton: true,
      });

    this.setState({
      pending: false,
      showError: !!showError,
      captchaRequired,
      errorMessage,
      returnUrl,
    });

    if (showError) {
      throw 'esim unavailable';
    }
  };

  onPayButtonSuccess = () => {
    window.location.href = this.state.returnUrl;
  };

  onPaymentButtonError = () => {
    this.setState({ pending: false, showError: true });
  };

  render() {
    const {
      texts,
      totalPrice,
      applePayEnabled,
      googlePayEnabled,
      captchaKey,
      googlePayProcessPaymentUrl,
      applePayProcessPaymentUrl,
    } = this.props;
    const { pending, captchaRequired, captcha, returnUrl } = this.state;
    const showPaymentButtons = applePayEnabled || applePayEnabled;
    const typo = captcha.length !== 5;

    return (
      <div className={cx('component')}>
        <Heading isStyled={false} level={1}>
          <span dangerouslySetInnerHTML={{ __html: texts.heading }} />
        </Heading>
        <Status className={['attention', cx('warning')]} emoji="pointingHandUp">
          <span dangerouslySetInnerHTML={{ __html: texts.warning }} />
          {texts.warningTooltip && <Tooltip>{texts.warningTooltip}</Tooltip>}
        </Status>
        {texts.params.map((param, index) => (
          <div className={cx('param')} key={index}>
            <div className={cx('paramValue')} dangerouslySetInnerHTML={{ __html: param.value }} />
            <div className={cx('paramTitle')}>
              <span dangerouslySetInnerHTML={{ __html: param.title }} />
              {param.tooltip && <Tooltip text={param.tooltip} />}
            </div>
          </div>
        ))}
        <div className={cx('total')} dangerouslySetInnerHTML={{ __html: texts.total }} />
        {texts.agreementCheckbox && (
          <Checkbox
            checked={this.state.agreementChecked}
            className={cx('agreement')}
            handleChange={this.onAgreementChecked}
            name="agreement"
          >
            <span dangerouslySetInnerHTML={{ __html: texts.agreementCheckbox }} />
            {this.state.showCheckboxWarning && (
              <p
                className={cx('error')}
                dangerouslySetInnerHTML={{ __html: texts.checkboxWarning }}
              />
            )}
          </Checkbox>
        )}
        {captchaRequired && !pending && (
          <div className={cx('captcha', { typo })}>
            <Captcha captchaKey={captchaKey} forceTouch={typo} onChange={this.onCaptchaChange} />
          </div>
        )}
        {showPaymentButtons && (
          <PaymentButtons
            applePayEnabled={applePayEnabled}
            applePayProcessPaymentUrl={applePayProcessPaymentUrl}
            className={cx('payButtons', pending && 'hidden')}
            disabled={captchaRequired && typo}
            googlePayEnabled={googlePayEnabled}
            googlePayProcessPaymentUrl={googlePayProcessPaymentUrl}
            label="eSIM"
            large
            mapProcessPaymentReq={(payObject) => ({
              payObject,
              orderNumber: window.esimOrderNumber,
            })}
            mapProcessPaymentRes={(res) => res.transactionInfo}
            onClick={this.onPayButtonClick}
            onError={this.onPaymentButtonError}
            onSuccess={this.onPayButtonSuccess}
            returnUrl={returnUrl && `${this.state.returnUrl}&params=googlePayConfirm`}
            sum={totalPrice}
          />
        )}
        <ButtonWithPreloader
          buttonText={texts.button}
          className="big"
          isPreloader={pending}
          onClick={this.onSubmit}
          wide
        />
        {this.state.showError && (
          <p
            className={cx('error')}
            dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}
          />
        )}
      </div>
    );
  }
}

const ConnectedOrderPaymentForm = connect(
  (state) => ({ ...pathOr({}, ['orderPaymentForm'], state.external) }),
  (dispatch) => ({
    submitOrderPaymentForm: (payload) => dispatch(submitOrderPaymentForm(payload)),
    getDeliveryData: () => dispatch(getDeliveryFormData()),
    closeOrderPaymentForm: () => {
      dispatch(toggleConnectPopup(false));
      dispatch(toggleOrderPaymentForm(false));
    },
    openOrderPaymentFailPopup: () => dispatch(toggleOrderPaymentResultPopup(true)),
  }),
)(OrderPaymentForm);

OrderPaymentForm.defaultProps = { captchaKey: 'sim-order-form' };

OrderPaymentForm.propTypes = {
  texts: PropTypes.shape({
    heading: PropTypes.string,
    warning: PropTypes.string,
    warningTooltip: PropTypes.string,
    agreementCheckbox: PropTypes.string,
    params: PropTypes.array,
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    checkboxWarning: PropTypes.string,
    button: PropTypes.string,
  }),
  orderPlace: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  totalPrice: PropTypes.number,
  applePayEnabled: PropTypes.bool,
  googlePayEnabled: PropTypes.bool,
  captchaKey: PropTypes.string,
  googlePayProcessPaymentUrl: PropTypes.string,
  applePayProcessPaymentUrl: PropTypes.string,
  soc: PropTypes.string,
  tariffName: PropTypes.string,
  numberCategory: PropTypes.string,
  getDeliveryData: PropTypes.func,
  submitOrderPaymentForm: PropTypes.func,
  closeOrderPaymentForm: PropTypes.func,
  openOrderPaymentFailPopup: PropTypes.func,
};

export default ConnectedOrderPaymentForm;
