import { isInPage } from 'utils/isInPage';

enum EScrollPosition {
  start = 'start',
  center = 'center',
  end = 'end',
}

const getOffsetByPosition = (position, hiddenElements) => {
  const baseOffset = hiddenElements ? 0 : 150;
  switch (position) {
    case EScrollPosition.start:
      return -baseOffset;
    case EScrollPosition.center:
      return -window.innerHeight / 2;
    case EScrollPosition.end:
      return -window.innerHeight + baseOffset;
    default:
      return 0;
  }
};

export const scrollToId = (selector: string, position: ScrollLogicalPosition = 'start') => {
  const element = document?.querySelector<HTMLElement>(selector);
  if (window !== undefined && isInPage(element)) {
    const hiddenElementsHeight = [...document.querySelectorAll('[data-hidden-to-scroll]')].reduce(
      (acc, elementNode) => acc + (elementNode.offsetHeight || 0),
      0,
    );
    const elementOffsetTop = element.getBoundingClientRect().top || 0;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const scrollToPosition =
      elementOffsetTop -
      hiddenElementsHeight +
      scrollTop +
      getOffsetByPosition(position, hiddenElementsHeight);

    setTimeout(() => {
      window.isSeenSettingsBlock = false;
      window.isSideBarOpen = false;
      window.isScrolling = false;
    }, 1000);

    window.isSideBarOpen = true;
    window.isScrolling = true;

    window.scrollTo({
      top: scrollToPosition,
      behavior: 'smooth',
    });
  }
};
