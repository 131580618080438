import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const LegalWithTooltip = ({ userAgreementText, termsOfUse, className }) => (
  <div className={cx('termOfUse', className)}>
    <span dangerouslySetInnerHTML={{ __html: userAgreementText }} />
    <Tooltip contentPosition="top">
      <span dangerouslySetInnerHTML={{ __html: termsOfUse }} />
    </Tooltip>
  </div>
);

LegalWithTooltip.propTypes = {
  userAgreementText: PropTypes.string,
  termsOfUse: PropTypes.string,
  className: PropTypes.any,
};

export default LegalWithTooltip;
