import { createSelector } from '@reduxjs/toolkit';
import { emptyObj } from '@beef/utils';

// Selectors
import { getCommonStoreData } from './common';

/**
 * getTotalSettingsContent: общий контент объекта пользовательских настроек ШПД - TotalSettings.
 *
 * getIsFinishedSettings: флаг окончания пользовательской настройки тарифа ШПД
 * для предотвращения постоянного "дергания" стора на каждое изминение.
 * getIsConnectedTariff: является ли текущий тариф уже подключеным.
 * getIsArchiveTariff: является ли текущий тариф архивным.
 * getSettingsTariff: текущий настраевымый тариф.
 */
export const getTotalSettingsContent = createSelector(
  getCommonStoreData,
  (state) => state?.totalSettings || emptyObj,
);
export const getIsFinishedSettings = (state) => getTotalSettingsContent(state).isFinishedSettings;
export const getIsConnectedTariff = (state) => getTotalSettingsContent(state).isConnected;
export const getIsArchiveTariff = (state) => getTotalSettingsContent(state).isArchive;
export const getSettingsTariff = (state) => getTotalSettingsContent(state).tariff;
