import React, { FC, ReactNode } from 'react';
import { cnTheme } from '@carnica/themes';
import classNames from 'classnames/bind';
import { motion } from 'framer-motion';

import styles from './styles.pcss';
import { useHeaderHeightCompensation } from './hooks/useHeaderHeightCompensation';

const cx = classNames.bind(styles);

type TPageContainer = {
  children: ReactNode;
};

/** Кастомная обертка для ЕК.
 * Китовый PageFixer не подходит на текущий момент времени */
export const PageContainer: FC<TPageContainer> = ({ children }) => {
  const { animate, transition } = useHeaderHeightCompensation();
  return (
    <motion.section animate={animate} className={cx('wrapper', cnTheme())} transition={transition}>
      <div className={cx('wrapper__children')}>{children}</div>
    </motion.section>
  );
};
