import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { useIsDesktopDimension, useIsMobileDimension } from '@beef/ui-kit';
import { Text } from '@carnica/ui-kit';

import { Anchor, Button } from 'pages/CarnicaDSLandings/ui';

import styles from './styles.pcss';
import { useStartSectionContent } from './hooks/useStartSectionContent';
import { Dots } from './components/Dots';
import { AnimatedIcons } from './components/AnimatedIcons';
import { Metric } from '../../analytics';

const cx = classNames.bind(styles);

/**
 * Стартовый блок лендинга MNP
 */
export const StartSection = memo(() => {
  const {
    anchorId,
    title,
    description,
    mobileTitle,
    buttonTitle,
    handleScrollToInfoAboutServiceSection,
  } = useStartSectionContent();

  const isMobile = useIsMobileDimension();
  const isDesktop = useIsDesktopDimension();

  return (
    <section className={cx('section-wrapper')}>
      {isDesktop && <Dots />}

      <Anchor anchorId={anchorId} />

      {isDesktop && <AnimatedIcons />}

      <div className={cx('wrapper')}>
        <Metric asChild events={{ yaMNPMetric: ['handleViewStartBlock'] }} mode="viewport">
          <div className={cx('info')} id="info-block">
            <Text
              align={!isDesktop ? 'center' : 'left'}
              variant={!isDesktop ? 'display-small' : 'display-large'}
            >
              {isMobile ? mobileTitle : title}
            </Text>

            <Text align={!isDesktop ? 'center' : 'left'} color="secondary" variant="body-medium">
              {description}
            </Text>
          </div>
        </Metric>

        {isDesktop && (
          <Metric events={{ yaMNPMetric: ['handleClickButtonMeet'] }} mode="click">
            <Button
              className={cx('btn')}
              color="white"
              onClick={handleScrollToInfoAboutServiceSection}
              size="l"
            >
              {buttonTitle}
            </Button>
          </Metric>
        )}
      </div>
    </section>
  );
});
