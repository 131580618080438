import {
  Button,
  Checkbox,
  InfoIcon,
  Input,
  Link,
  PhoneInput,
  StatefulSearchInput,
  Text,
  Tooltip,
} from '@beef/ui-kit';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import { getCitySearchResults } from '../../../api';
import {
  selectAreCheckboxesChecked,
  selectButtonContent,
  selectData,
  selectTitle,
} from '../../../store/anketa/selectors';
import { selectContent, selectHasErrors } from '../../../store/selectors';
import { processTextWithCustomRegex, trimName } from '../../../utils';
import { LandingModal } from '../../LandingModal/LandingModal';
import { Textarea } from '../Textarea';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const getCheckboxLabel = (state, fieldName) => {
  const link = (
    <span className={cn('link-wrapper')}>
      <Link href={selectContent(state)?.[fieldName]?.link?.url} target="_blank">
        {selectContent(state)?.[fieldName]?.link?.text}
      </Link>
    </span>
  );
  const label = selectContent(state)?.[fieldName]?.label || '';
  const linkText = selectContent(state)?.[fieldName]?.link?.text;
  return (
    <span className={cn('checkbox-label')}>
      {label
        .split(linkText)
        .map((s, i) => [s, [link][i]])
        .flat()
        .filter((v) => !!v)}
    </span>
  );
};

export const AnketaForm = (props) => {
  const state = props;

  const { handleValidate, setValue, confirmModal, openModal, strip } = state;
  const searchInputRef = useRef();

  const isMobileDimension = useIsMobileDimension();
  const tooltipPosition = isMobileDimension ? 'top' : 'right';

  const hasErrors = selectHasErrors(state);
  const hasSubmitted = state?.meta?.isStorySent || selectData(state)?.hasSubmitted;

  const shouldShowPreloader = selectData(state)?.button?.value === 'pending';

  return (
    <>
      {hasSubmitted && (
        <>
          <div className={cn('title')}>
            <Text color="grey95" size="size1-m" tagType="h2">
              {selectContent(state)?.accepted?.title || ''}
            </Text>
          </div>
          <div className={cn('description')}>
            <Text color="grey95" size="size4-r">
              {selectContent(state)?.accepted?.description || ''}
            </Text>
          </div>
        </>
      )}
      {!hasSubmitted && (
        <form className={cn('form')}>
          <div className={cn('title')}>
            <Text color="grey95" size="size1-m" tagType="h2">
              {selectTitle(state) || ''}
            </Text>
          </div>
          <div className={cn('field-group')}>
            <div className={cn('input')}>
              <Input
                disabled={false}
                errorMessage={selectData(state)?.firstName?.error || ''}
                extraAttributes={{
                  autoFocus: !!selectData(state)?.firstName?.error,
                }}
                label={selectContent(state)?.firstName?.label || ''}
                onBlur={(e) => setValue('firstName', trimName(e.target.value))}
                onChange={(e) => setValue('firstName', e.target.value)}
                placeholder={selectContent(state)?.firstName?.placeholder}
                required
                type="text"
                value={selectData(state)?.firstName?.value}
              />
            </div>
            <div className={cn('input')}>
              <Input
                disabled={false}
                errorMessage={selectData(state)?.lastName?.error || ''}
                label={selectContent(state)?.lastName?.label || ''}
                onBlur={(e) => setValue('lastName', trimName(e.target.value))}
                onChange={(e) => setValue('lastName', e.target.value)}
                placeholder={selectContent(state)?.lastName?.placeholder}
                required
                type="text"
                value={selectData(state)?.lastName?.value}
              />
            </div>
          </div>
          <div className={cn('field-group')}>
            <div className={cn('input')}>
              <PhoneInput
                disabled={false}
                errorMessage={selectData(state)?.phone?.error || ''}
                label={
                  <span className={cn('tooltip')}>
                    {selectContent(state)?.phone?.label || ''}
                    <Tooltip
                      maxWidth="225"
                      message={selectContent(state)?.phone?.tooltip}
                      position={tooltipPosition}
                      variant="secondary"
                    >
                      <InfoIcon size="s" />
                    </Tooltip>
                  </span>
                }
                maskSettings={{
                  mask: '_',
                  format: '+7 ### ###-##-##',
                }}
                onChange={(e) => setValue('phone', e.target.value)}
                placeholder={selectContent(state)?.phone?.placeholder || ''}
                required
                type="tel"
                value={selectData(state)?.phone?.value}
              />
            </div>
            <div className={cn('input')}>
              <StatefulSearchInput
                debounceInterval={300}
                errorMessage={selectData(state)?.city?.error || ''}
                id="city-search"
                inputRef={searchInputRef}
                label={
                  <span className={cn('tooltip')}>
                    {selectContent(state)?.city?.label || ''}
                    <Tooltip
                      maxWidth="225"
                      message={selectContent(state)?.city?.tooltip}
                      position={tooltipPosition}
                      variant="secondary"
                    >
                      <InfoIcon size="s" />
                    </Tooltip>
                  </span>
                }
                name="city-search"
                onSearch={getCitySearchResults}
                onSelect={(option) => {
                  setValue('city', option?.label);
                  setValue('cityId', option?.slug);
                }}
                placeholder={selectContent(state)?.city?.placeholder}
                testName="city-search"
              />
            </div>
          </div>
          <div className={cn('input')}>
            <Input
              disabled={false}
              errorMessage={selectData(state)?.storyTitle?.error || ''}
              label={selectContent(state)?.storyTitle?.label || ''}
              onChange={(e) => setValue('storyTitle', e.target.value)}
              placeholder={selectContent(state)?.storyTitle?.placeholder}
              required
              type="text"
              value={selectData(state)?.storyTitle?.value}
            />
          </div>
          <div className={cn('textarea')}>
            <Textarea
              disabled={false}
              errorMessage={selectData(state)?.story?.error || ''}
              extraAttributes={{
                style: {
                  minHeight: '186px',
                },
              }}
              fullWidth
              isTextArea
              label={
                <span className={cn('tooltip')}>
                  {selectContent(state)?.story?.label || ''}
                  <Tooltip
                    maxWidth="225"
                    message={selectContent(state)?.story?.tooltip || ''}
                    position={tooltipPosition}
                    variant="secondary"
                  >
                    <InfoIcon size="s" />
                  </Tooltip>
                </span>
              }
              maxLength={1500}
              onChange={(e) =>
                setValue(
                  'story',
                  processTextWithCustomRegex(
                    e.target.value,
                    strip?.preprocess ? strip?.value : '',
                    strip?.placeholder,
                  ),
                )
              }
              placeholder={selectContent(state)?.story?.placeholder}
              required
              type="text"
              value={selectData(state)?.story?.value}
            />
          </div>
          <div className={cn('checkbox-container')}>
            <Checkbox
              checked={selectData(state)?.personalDataAgreement?.value}
              label={getCheckboxLabel(state, 'personalDataAgreement')}
              onChange={(e) =>
                setValue('personalDataAgreement', !selectData(state)?.personalDataAgreement?.value)
              }
            />
            <Checkbox
              checked={selectData(state)?.rulesAgreement?.value}
              label={getCheckboxLabel(state, 'rulesAgreement')}
              onChange={(e) =>
                setValue('rulesAgreement', !selectData(state)?.rulesAgreement?.value)
              }
            />
            <Checkbox
              checked={selectData(state)?.employeeAgreement?.value}
              label={getCheckboxLabel(state, 'employeeAgreement')}
              onChange={(e) => {
                const value = selectData(state)?.employeeAgreement?.value;
                if (value) openModal('employee');
                setValue('employeeAgreement', !value);
              }}
            />
          </div>
          <div className={cn('button-container')}>
            <Button
              disabled={!selectAreCheckboxesChecked(state) || hasErrors}
              isLoading={shouldShowPreloader}
              onClick={handleValidate}
              size="l"
              type="submit"
              variant="main"
            >
              {selectButtonContent(state)?.text || ''}
            </Button>
          </div>
        </form>
      )}
      <LandingModal confirmModal={confirmModal} content={selectContent(state)} />
    </>
  );
};

AnketaForm.propTypes = {
  formContent: PropTypes.shape({
    title: PropTypes.string,
    phone: PropTypes.shape({
      label: PropTypes.string,
    }),
    sms: PropTypes.shape({
      label: PropTypes.string,
    }),
    button: PropTypes.shape({
      text: PropTypes.string,
    }),
  }),
};
