import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import Grid from 'pages-components/Grid';
import { BenefitsSlider } from 'pages-components/Benefits';

import FamilyNavigation from '../../components/FamilyNavigation';
import TariffCardBody from '../../components/TariffCardBody';

// Hocs
import FamilyNavigationHOC from '../../../hoc/FamilyNavigationHOC';
import TariffBodyHOC from '../../../hoc/TariffBodyHOC';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const RegularTariff = ({
  familyNavigation,
  setESimOpenModal,
  isBurnSubTitle,
  discountText,
  subTitleText,
  pageTitleIcon,
  pageTitle,
  benefits,
  alias,
}) => (
  <>
    {familyNavigation && (
      <FamilyNavigationHOC alias={alias} familyNavigation={familyNavigation}>
        <FamilyNavigation />
      </FamilyNavigationHOC>
    )}

    <Grid.ProductCard
      burnSubTitle={isBurnSubTitle}
      discount={discountText}
      icon={pageTitleIcon}
      subTitle={subTitleText}
      title={pageTitle}
    >
      {benefits && <BenefitsSlider benefits={benefits} className={cx('tariffCardBenefits')} />}

      <TariffBodyHOC setESimOpenModal={setESimOpenModal}>
        <TariffCardBody />
      </TariffBodyHOC>
    </Grid.ProductCard>
  </>
);

RegularTariff.propTypes = {
  alias: PropTypes.string,
  discountText: PropTypes.string,
  subTitleText: PropTypes.string,
  pageTitleIcon: PropTypes.string,
  pageTitle: PropTypes.string,

  isBurnSubTitle: PropTypes.bool,

  setESimOpenModal: PropTypes.func,

  benefits: PropTypes.array,
  familyNavigation: PropTypes.array,
};

export default RegularTariff;
