import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import RedirectFromEsia from 'pages/Services/AссessibleInternet/component/steps/RedirectFromEsia';

import { ESIA_REDIRECT_CODE, STEPS } from '../../accessibleInternetServiceHelper';
import GosAuth from '../steps/GosAuth';

const RequestSteps = ({ onClose, opened, extData, step }) => {
  const gosAuthTextsMap = useMemo(
    () => ({
      success: extData?.gosAuthRequired,
      defaultError: extData?.defaultError,
      blockedError: extData?.blockedError,
      conflictError: extData?.conflictError,
      loading: extData?.loading,
    }),
    [extData],
  );

  const redirectTextsMap = useMemo(
    () => ({
      defaultError: extData.defaultError,
      [ESIA_REDIRECT_CODE.OK]: extData?.requestSuccessContent,
      [ESIA_REDIRECT_CODE.NOT_TRUSTED_ESIA_ACCOUNT]: extData?.notTrustedEsiaAccountError,
      [ESIA_REDIRECT_CODE.NOT_RUS]: extData?.notRussianCitizenError,
      [ESIA_REDIRECT_CODE.NOT_MATCHED]: extData?.esiaNotMatchedDataError,
      [ESIA_REDIRECT_CODE.ESIA_ACCESS_DENIED]: extData?.esiaAccessDeniedError,
      [ESIA_REDIRECT_CODE.ESIA_ERROR]: extData?.esiaNotAnswerError,
    }),
    [extData],
  );
  return (
    <>
      {step === STEPS.INITIAL && (
        <GosAuth onClose={onClose} opened={opened} texts={gosAuthTextsMap} />
      )}
      {step === STEPS.ESIA_REDIRECT && (
        <RedirectFromEsia onClose={onClose} opened={opened} texts={redirectTextsMap} />
      )}
    </>
  );
};

RequestSteps.propTypes = {
  opened: PropTypes.bool,
  step: PropTypes.oneOf(Object.values(STEPS)),

  onClose: PropTypes.func,
  extData: PropTypes.object,
};

RequestSteps.defaultProps = {
  opened: false,

  extData: {},
  onClose: () => {},
};

export default RequestSteps;
