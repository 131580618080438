import { withRenderFlag } from '@beef/react';
import { compose } from '@beef/redux';
import { BreadCrumbItemType, BreadCrumbs } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import React, { FC } from 'react';
import { connect } from 'react-redux';

import { RootPageStore } from '../../types';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

interface BreadcrumbsProps {
  breadcrumbs: BreadCrumbItemType[];
  shouldRender: boolean;
}

const _Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs }) => (
  <div className={cn('breadcrumbs')}>
    <BreadCrumbs path={breadcrumbs} />
  </div>
);

const mapStateToProps = ({ content: { breadcrumbs } }: RootPageStore): BreadcrumbsProps => ({
  breadcrumbs,
  shouldRender: Boolean(breadcrumbs?.length),
});

export const Breadcrumbs = compose(connect(mapStateToProps), withRenderFlag)(_Breadcrumbs);
