import { memo } from 'react';

import { ZeroBalanceSupportContext } from 'pages/Services/ZeroBalanceSupport/context';
import { withContext } from 'pages/Services/ZeroBalanceSupport/enhancers/withContext/index';

import { ModalWrapper } from '../../../ModalWrapper/index';
import { ModalMap } from '../map';
import { EModal } from '../types';
import { useMapStateToProps } from './store';

ModalMap[EModal.UnavailableServiceStep] = {
  component: withContext({
    context: ZeroBalanceSupportContext,
    useMapStateToProps,
  })(memo(ModalWrapper)),
};
