import { shallowEqual } from '@beef/utils';

import { useAppSelector } from '../../../../store';

/** Селектор выборки контентных данных для попапа рекомендуемых устройств */
export const useSelectDevicesPopups = () =>
  useAppSelector(
    (state) => ({
      icon: state.common?.content?.internetLiveContent?.icon,
      btnText: state.common?.content?.internetLiveContent?.btnText,
      title: state.common?.content?.internetLiveContent?.popupTitle,
      description: state.common?.content?.internetLiveContent?.description,
    }),
    shallowEqual,
  );
