import { ymPushEvent } from '../ym';

export const pushServiceConnected = (service) => {
  ymPushEvent('ServiceConnectSuccess', {
    service_ev_: {
      service_connect: {
        beeline: service?.pageTitle,
      },
    },
  });
};

export const pushServiceDisconnected = (service) => {
  ymPushEvent('ServiceDisconnectSuccess', {
    service_ev_: {
      service_disconnect: {
        beeline: service?.pageTitle,
      },
    },
  });
};
