import { Card } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import { LandingConstructor } from '@beef/landing-constructor';
import React from 'react';

import styles from '../components/Form/TimeCapsule/styles.pcss';
import { TimeCapsuleForm } from '../components/Form/TimeCapsule/TimeCapsuleForm';
import { HeaderScrollDown } from '../components/HeaderScrollDown';
import { FORM_ALIAS } from '../constants';
import { processLandingConstructorData } from '../utils';

const cn = classNames.bind(styles);

const TimeCapsuleFormSection = ({ content, ...rest }) => (
  <>
    <div className={cn('anchor')} id={FORM_ALIAS} />
    <Card className={cn('card')}>
      <div className={cn('content')}>
        <div className={cn('details')}>
          <TimeCapsuleForm content={content} {...rest} />
        </div>
        <div
          className={cn('details__img')}
          style={{ backgroundImage: `url(${content.image.src})` }}
        />
      </div>
    </Card>
  </>
);

export const TimeCapsuleLanding = ({ data, ...meta }) => (
  <>
    <HeaderScrollDown {...data.heading} scrollToId={FORM_ALIAS} />
    <LandingConstructor
      {...processLandingConstructorData(data, {
        custom: (b) => (meta?.isSmsAvailable ? b : null),
      })}
      customComponents={{
        TimeCapsuleFormSection: (props) => <TimeCapsuleFormSection {...props} meta={meta} />,
      }}
    />
  </>
);
