import React from 'react';
import { PageFixer, PageRow } from '@beef/smart-kit';
import { compose, withReduxStore } from '@beef/redux';
import { withDataConverter } from '@beef/react';

import { dataConverter } from './store/dataConverter';
import { Actions, Title } from './components';
import { initializeStore } from './store';

const _ActionCategoryPage = () => (
  <PageFixer>
    <Title />
    <PageRow blockSpacersPreset="l" tagName="section">
      <Actions />
    </PageRow>
  </PageFixer>
);

export const ActionCategoryPage = compose(
  withDataConverter(dataConverter),
  withReduxStore(initializeStore),
)(_ActionCategoryPage);
