import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@beef/ui-kit';

import { isEmptyValue } from 'utils/isEmptyValue';
import { ActionBarMainType } from 'pages/SPN/types';

// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const ActionBarMain: FC<ActionBarMainType> = ({ data, handlersModal }) => {
  if (isEmptyValue(data)) return null;

  const { main, additional } = handlersModal;

  const {
    content: { activateButtonText, changeButtonText, deactivateButtonText, offerText },
    hasErrorValidation,
    isWaitingRequest,
    dontNeedToChangeName,
    hasConnectedSoc,
    isDefaultSocChildNotChanged,
  } = data;

  const hasDisabled = isDefaultSocChildNotChanged || dontNeedToChangeName || hasErrorValidation;
  const mainBtnText = hasConnectedSoc ? changeButtonText : activateButtonText;

  return (
    <div className={cn('wrapper')}>
      <div className={cn('action-bar')}>
        {mainBtnText && (
          <Button
            disabled={hasDisabled}
            isLoading={isWaitingRequest}
            onClick={main}
            testName="ABMainBtn"
            type="submit"
          >
            {mainBtnText}
          </Button>
        )}

        {hasConnectedSoc && deactivateButtonText && (
          <Button onClick={additional} testName="ABAdditionalBtn" variant="tertiary">
            {deactivateButtonText}
          </Button>
        )}
      </div>

      {offerText && (
        <Text color="grey60" size="size5-r-s" tagType="div">
          {offerText}
        </Text>
      )}
    </div>
  );
};
