import qs from 'query-string';
import erdMaker from 'element-resize-detector';

import { isObject } from 'utils/timed-functions';

import { DISABLED_FILTERS_FOR_BACKEND, FILTERS, FILTERS_FOR_BACKEND } from '../constants';

export const updateUrlState = (params) => {
  const newParams = {
    ...qs.parse(window.location.search),
    ...params,
  };
  window.history.replaceState(null, '', `${window.location.pathname}?${qs.stringify(newParams)}`);
};

export const deleteParameterUrlState = (key) => {
  const params = { ...qs.parse(window.location.search) };
  delete params[key];
  window.history.replaceState(null, '', `${window.location.pathname}?${qs.stringify(params)}`);
  return params;
};

export const getUrlParam = (key) => qs.parse(window.location.search)[key];

/**
 * @description Получение активных фильтров из списка чекбоксов и форматирование под отправку запроса на бэк
 * @example {appliedDisabledFilter: true} возвращает строку Disabled
 * @param appliedFilters
 * @returns {string}
 */
export const getFilterList = (appliedFilters) => {
  const reg = /applied|Filter/gi;
  return Object.keys(appliedFilters)
    .filter((key) => !DISABLED_FILTERS_FOR_BACKEND[key] && appliedFilters[key])
    .map((v) => v.replace(reg, ''))
    .reduce((acc, el, index) => ({ ...acc, [`filters[${index}]`]: el }), {});
};

export const getFilterParams = (appliedFilters) => {
  const { appliedDisabledFilter, appliedAlfaBankCardFilter, appliedAlfaBankCard1525Filter } =
    appliedFilters;

  return {
    forDisabled: appliedDisabledFilter || false,
    has100DaysAlfaCard: appliedAlfaBankCardFilter || false,
    has1525AlfaCard: appliedAlfaBankCard1525Filter || false,
  };
};

export const windowResizeHandler = (callback, pageElementSelecor) => {
  const wrapperElement = document.querySelector('.react-adaptive-wrapper');
  const pageElement = document.querySelector(pageElementSelecor);

  if (!pageElement || !wrapperElement) {
    return () => {};
  }

  const handler = () => {
    const height = document.documentElement.clientHeight;
    wrapperElement.style.height = `${height}px`;
    callback();
  };

  const erdInstance = erdMaker({ strategy: 'object' });
  erdInstance.listenTo(pageElement, handler);

  return () => {
    erdInstance.removeListener(pageElement, handler);
  };
};

export const formatPointsData = (points = {}) => {
  if (!isObject(points)) {
    return {};
  }
  return Object.keys(points).reduce(
    (acc, curr) => ({
      ...acc,
      [curr.toLowerCase()]: points[curr],
    }),
    {},
  );
};

export const isEmptyValue = (value) =>
  ['', null, undefined, '{}'].includes(value) === true ||
  (Array.isArray(value) === true && value.length === 0) ||
  (typeof value === 'object' && Object.keys(value).length === 0);

const getFiltersByCondition = ({ isFilterOut, filters, selectFilter }) => {
  if (isFilterOut) {
    return filters?.filter((param) => param !== selectFilter).join(',');
  }
  return [...filters, selectFilter].join(',');
};

// Удаление одиночных фильтров(НЕ указанные в параметре filters)
const deleteSingleFilterFromUrl = (key, value) => {
  const activeSingleFilters = getUrlParam(FILTERS_FOR_BACKEND[key]);
  if (activeSingleFilters && !value) {
    deleteParameterUrlState(FILTERS_FOR_BACKEND[key]);
  }
};

export const setFiltersToUrlState = (filter) => {
  const currentFilter = Object.entries(filter);

  if (!currentFilter.length || currentFilter.length > 1) {
    return null;
  }

  const [key, value] = currentFilter[0];
  deleteSingleFilterFromUrl(key, value);

  // Обработка набора фильтров указанных в параметре filters
  const activeAllFilters = getUrlParam(FILTERS)?.split(',') || [];
  if (!activeAllFilters?.length && !value) {
    return null;
  }

  const filtersResult = getFiltersByCondition({
    isFilterOut: activeAllFilters?.length && !value,
    filters: activeAllFilters,
    selectFilter: FILTERS_FOR_BACKEND[key],
  });

  if (!filtersResult?.length) {
    return deleteParameterUrlState(FILTERS);
  }
  return updateUrlState({ [FILTERS]: filtersResult });
};

export const getUrlParamsByFilters = (filters) => {
  const activeFilters = getUrlParam(FILTERS)?.split(',');

  return Object.entries(filters).reduce((acc, [filterKey, filterValue]) => {
    switch (filterValue) {
      case 'bt':
        return {
          ...acc,
          ...{
            [filterKey]: activeFilters?.includes(filterValue) || getUrlParam('bt') === '2' || false,
          },
        };
      case 'beelineOwnOffices':
        return {
          ...acc,
          ...{
            [filterKey]:
              activeFilters?.includes(filterValue) ||
              getUrlParam('beelineOwnOffices') === '3' ||
              false,
          },
        };
      case 'AlfaBankCard':
        return {
          ...acc,
          ...{
            [filterKey]:
              activeFilters?.includes(filterValue) || getUrlParam('has100DaysAlfaCard') || false,
          },
        };
      case 'AlfaBankCard1525':
        return {
          ...acc,
          ...{
            [filterKey]:
              activeFilters?.includes(filterValue) || getUrlParam('has1525AlfaCard') || false,
          },
        };
      case 'Disabled':
        return {
          ...acc,
          ...{
            [filterKey]:
              activeFilters?.includes(filterValue) || getUrlParam('forDisabled') || false,
          },
        };
      case 'EquipmentReturnOnly':
      case 'EquipmentReturnReplacement':
        return {
          ...acc,
          ...{ [filterKey]: activeFilters?.includes(filterValue) || false },
        };
      default:
        break;
    }

    return acc;
  }, {});
};
