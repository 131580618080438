import React from 'react';
import PropTypes from 'prop-types';

// Components
import Params from './components/Params';

const DigitalParameter = ({ widgetType, icon, value, label, valueList }) => (
  <>
    {widgetType === 'list' && <Params.List icon={icon} list={valueList} />}

    {widgetType === 'normal' && <Params.Regular icon={icon} label={label} value={value} />}
  </>
);

DigitalParameter.propTypes = {
  value: PropTypes.string,
  widgetType: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  valueList: PropTypes.array,
};

export default DigitalParameter;
