import React, { ReactNode, memo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TMainContainer = {
  children: ReactNode;
};

/** Компонент-обертка над общим блоком с настройками конструктора */
export const MainContainer = memo<TMainContainer>(({ children }) => (
  <section className={cx('wrapper')}>{children}</section>
));
