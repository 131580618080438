import React, { FC } from 'react';

import { Screen } from 'constructorV1/components/tempComponents/Screen';
import { Status } from 'constructorV1/components/tempComponents/Status';

import { useResultContent } from './hooks/useResultContent';

/** Контейнер страницы результата ЕК */
export const ResultContainer: FC = () => {
  /* Подготовка данных для страницы конфликтов */
  const results = useResultContent();

  return (
    <Screen>
      <Status {...results} />
    </Screen>
  );
};
