import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { connect } from 'react-redux';

import styles from './styles.pcss';
import { RootPageStore } from '../../types';

const cn = classNames.bind(styles);

interface TitleProps {
  title?: string;
}

const _Title = ({ title = 'Архив Акций' }: TitleProps) => (
  <Text className={cn('title')} size="size0-b" tagType="h1">
    {title}
  </Text>
);

export const Title = connect(({ content: { title } }: RootPageStore): TitleProps => ({ title }))(
  _Title,
);
