import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import store from 'store';
import { getGAClientId, pushBackCallAction } from 'utils/ga';
import { currentStateList } from 'pages/FTTB/constants';
import submitCallbackRequest from 'pages/FTTB/services/submitCallbackRequest';
import { callbackFormClickYM } from 'utils/analytics/tariffsYandexMetrica';
import {
  successfullFTTBFormSubmissionMT,
  successfullFTTBFormSubmissionVK,
} from 'utils/analytics/pixelAnalytics';
import {
  CAPTCHA_STATUS,
  CaptchaWithCallbackPopupHOC,
} from 'pages/FTTB2021/hoc/CaptchaWithCallbackPopupHOC';
import { getCookie } from 'utils/cookie';
import { vkPushEvent } from 'utils/analytics/vkEvents';
import { VkGoalEvent } from 'pages/FTTB2022/CatalogPage/deprecated/blocks/constants';

import { RenderPopupFormsNewDesign, RenderPopupFormsOld } from './components';

const BackCallPopup = ({
  onClose,
  isOpen,
  phoneInputValue,
  changePhone,
  showAbbreviatedPopup,
  data,
  redesign,
  isFMC,
  isFttbTv,
  showCaptcha,
  captchaToken,
  changeCaptchaToken,
  changeCaptchaState,
  reCaptchaModel,
  captchaStatus,
  changeCaptchaStatus,
  captchaMsg,
  changeCaptchaMsg,
  forceShowBackCallPopup,
  analyticTariffDetails,
  marketingProductName,
  isIcon,
  isConvergent,
  caseTitle,
  renderRequestStatusPopup,
  captchaKey,
}) => {
  const [phoneInputStatus, setPhoneInputStatus] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(
    showAbbreviatedPopup ? currentStateList.abbreviated : currentStateList.none,
  );

  const getGaType = () => {
    if (isFMC) return 'fmc';
    if (isFttbTv) return 'fttbTv';

    return 'fttb';
  };

  useEffect(() => {
    if (!data || !data.texts) return;

    if (isOpen) {
      sessionStorage.setItem('backCallPopupIsShown', '1');
    }

    if (showCaptcha) {
      pushBackCallAction('captcha', getGaType());
    }
    if (renderRequestStatusPopup) {
      setCurrentState(renderRequestStatusPopup);
    }
  }, []);

  const handleClosePopup = useCallback(() => {
    setCurrentState(currentStateList.none);
    onClose();
  }, []);

  const onPhoneFocus = () => {
    setPhoneInputStatus(null);
  };

  const sendSuccessSubmitAnalytics = () => {
    let additionalData = {
      clientType: 'B2C',
      ordered_itemTitle: 'Общий',
      ordered_itemProduct: getGaType(),
    };

    const clientCTN = getCookie('USSS_gaUID');

    if (clientCTN) {
      additionalData = {
        ...additionalData,
        clientCTN,
      };
    }
    /**
      инфа о тарифе, может перезаписывать дефолтные значения
    */
    if (analyticTariffDetails) {
      additionalData = {
        ...additionalData,
        ...analyticTariffDetails,
      };
    }
    pushBackCallAction('send', 'url', additionalData);
  };

  const onBackCallBtnClick = () => {
    if (phoneInputValue.length !== 10) {
      setPhoneInputStatus('fail');
      return;
    }

    let clientId = null;

    if (forceShowBackCallPopup) {
      clientId = getGAClientId();
    }

    setPhoneInputStatus(null);

    if (redesign) {
      setLoading(true);
    }

    if (!redesign) {
      setCurrentState(currentStateList.loading);
    }

    const captchaData = {
      captchaToken,
      key: captchaKey,
    };

    submitCallbackRequest({
      phoneNumber: `+7${phoneInputValue}`,
      captchaData,
      isFMC,
      clientId,
      marketingProductName,
      caseTitle,
    })
      .then((result) => {
        const { shouldShowCaptcha, isCaptchaError, isSucceeded } = result;
        changeCaptchaState(false);

        if (redesign) {
          setLoading(false);
        }

        if (isSucceeded) {
          setCurrentState(currentStateList.success);
          changeCaptchaState(shouldShowCaptcha);
          sendSuccessSubmitAnalytics();
          callbackFormClickYM(isIcon, isConvergent);
          successfullFTTBFormSubmissionVK();
          successfullFTTBFormSubmissionMT();
          vkPushEvent(VkGoalEvent.shpdOrderedCallback);
        } else if (isCaptchaError) {
          changeCaptchaStatus(shouldShowCaptcha ? CAPTCHA_STATUS.fail : CAPTCHA_STATUS.success);
          changeCaptchaState(shouldShowCaptcha);
          setCurrentState(currentStateList.captcha);
        } else {
          setCurrentState(currentStateList.fail);
          pushBackCallAction('sendError', getGaType());
        }
      })
      .catch((err) => {
        console.error(err);
        pushBackCallAction('sendError', getGaType());
        setCurrentState(currentStateList.fail);
      });
  };

  const renderFormsData = useMemo(
    () => ({
      texts: data.texts,
      phoneInputValue,
      changePhone,
      showCaptcha,
      captchaToken,
      changeCaptchaToken,
      reCaptchaModel,
      captchaStatus,
      changeCaptchaStatus,
      phoneInputStatus,
      onPhoneFocus,
      onBackCallBtnClick,
      captchaMsg,
      changeCaptchaMsg,
    }),
    [phoneInputValue, changePhone, showCaptcha, captchaToken, changeCaptchaState, captchaMsg],
  );

  return redesign ?
      <RenderPopupFormsNewDesign
        closePopup={handleClosePopup}
        data={renderFormsData}
        isLoading={isLoading}
        isOpen={isOpen}
        renderPopup={currentState}
      />
    : <RenderPopupFormsOld
        closePopup={handleClosePopup}
        data={renderFormsData}
        isOpen={isOpen}
        renderPopup={currentState}
      />;
};

BackCallPopup.propTypes = {
  data: PropTypes.shape({
    texts: PropTypes.shape({}),
  }),
  isOpen: PropTypes.bool,
  isFMC: PropTypes.bool,
  isFttbTv: PropTypes.bool,
  forceShowBackCallPopup: PropTypes.bool,
  onClose: PropTypes.func,
  redesign: PropTypes.bool,

  /* from CaptchaWithCallbackPopupHOC */
  reCaptchaModel: PropTypes.shape({
    onChange: PropTypes.func,
    refreshCaptcha: PropTypes.number,
    setCaptchaKey: PropTypes.func,
  }),
  captchaStatus: PropTypes.string,
  changeCaptchaStatus: PropTypes.func,
  captchaMsg: PropTypes.string,
  changeCaptchaMsg: PropTypes.func,
  captchaKey: PropTypes.string,
  showCaptcha: PropTypes.bool,
  captchaToken: PropTypes.string,
  changeCaptchaToken: PropTypes.func,
  changeCaptchaState: PropTypes.func,
  changePhone: PropTypes.func,
  phoneInputValue: PropTypes.string,
  marketingProductName: PropTypes.string,
  showAbbreviatedPopup: PropTypes.bool,
  isConvergent: PropTypes.bool,
  caseTitle: PropTypes.string,
  analyticTariffDetails: PropTypes.shape({}),
};

export default (props) => (
  <Provider store={store}>
    <CaptchaWithCallbackPopupHOC {...props}>
      <BackCallPopup />
    </CaptchaWithCallbackPopupHOC>
  </Provider>
);
