import React from 'react';
import classNames from 'classnames/bind';
import { withRenderFlag } from '@beef/react';

import { useSetCurrAnchorOnScroll } from './useSetCurrAnchorOnScroll';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type AnchorTypes = {
  additionalClass?: string;
  anchorId: string;
};

const __Anchor: React.FC<AnchorTypes> = ({ anchorId, additionalClass }) => {
  const ref = useSetCurrAnchorOnScroll(anchorId);

  return <span className={cx('anchor', additionalClass)} id={anchorId} ref={ref} />;
};

const _Anchor: React.FC<Partial<AnchorTypes>> = withRenderFlag({
  shouldRenderFn: (props: Partial<AnchorTypes>) => Boolean(props?.anchorId),
})(__Anchor);

export const Anchor = React.memo(_Anchor);
