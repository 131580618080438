export function getContainerVisibility(el: HTMLElement, container: HTMLElement | Window): number {
  const { height, top } = el.getBoundingClientRect();

  const current = window.innerHeight - top;
  const max = window.innerHeight + height;
  const localScroll = Math.max(
    window.scrollY - (container instanceof Window ? 0 : container.offsetTop),
    0,
  );

  return container instanceof Window ? current / max : localScroll / container.offsetHeight;
}

export function getContainerCursorPosition(
  container: Element,
  cursorX: number,
  cursorY: number,
): { x: number; y: number } {
  const { height, left, top, width } = container.getBoundingClientRect();

  const x = (cursorX - left) / width;
  const y = (cursorY - top) / height;

  return { x, y };
}
