import React, { MouseEvent } from 'react';

import { formatAlertText } from 'constructorV1/components/tempComponents/AlertContainer/components/AlertTile/utils/formatAlertText';

/** Функция для рендеринга текста с возможностью замены ссылки на обработчик */
export const renderTextWithLinks = (
  handleLinkClick: (e: MouseEvent<HTMLAnchorElement>) => void,
  discountPercent: null | number,
  text = '',
) => {
  const formattedText = formatAlertText(text, discountPercent);
  /* Разбиваем текст на части, где есть ссылки */
  const parts = formattedText.split(/(<a[^>]*>.*?<\/a>)/g);
  return parts.map((part, index) => {
    /* Проверяем, является ли часть ссылкой с пустым href */
    if (part.startsWith('<a')) {
      /* Извлекаем значения */
      const hrefMatch = part.match(/href="([^"]*)"/) ?? '';
      const href = hrefMatch ? hrefMatch[1] : '';
      if (!href) {
        /* Убираем теги <a> */
        const linkText = part.replace(/<\/?a[^>]*>/g, '');
        return (
          <a href="" key={index} onClick={handleLinkClick}>
            {linkText}
          </a>
        );
      }
      return <span dangerouslySetInnerHTML={{ __html: part }} />;
    }
    return part;
  });
};
