const timePeriodSeparator = '$$timeSeparator$$';

export const mapTimePeriodToOptionValue = (start, end) =>
  start.toJSON() + timePeriodSeparator + end.toJSON();
export const parseTimePeriodFromOptionValue = (value) => {
  const [startTimeStr, endTimeStr] = value.split(timePeriodSeparator);

  const startTime = new Date(startTimeStr);
  const endTime = new Date(endTimeStr);

  return [startTime, endTime];
};
