import axios from 'axios';

import { apiValidation } from './constants';

export interface IResponseValidateMNPOrder {
  Errors: Record<string, unknown>;
  IsSucceeded: boolean;
  View: {
    IsExistingCtn: boolean;
  };
  camelCase: boolean;
}

/**
 * Валидация введенного номера
 */
export const validateMNPOrder = async (phoneNumber: string) => {
  try {
    const { data } = await axios.post<IResponseValidateMNPOrder>(apiValidation, {
      phone: phoneNumber,
    });

    return data;
  } catch (error) {
    return String(error);
  }
};
