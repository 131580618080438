import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link, SmartInput } from '@beef/legacy-ui-kit';

import { DEFAULT_CAPTCHA_URL } from 'pages/Feedback/components/Captcha/constants';
import { fetchCaptcha } from 'pages/Feedback/components/Captcha/services';

import styles from './Captcha.pcss';

const cx = classNames.bind(styles);

class Captcha extends Component {
  constructor(props) {
    super(props);
    this.state = { pic: null, captcha: '', captchaKey: '' };
    this.updateCaptcha = this.updateCaptcha.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  componentDidMount() {
    const { onMount } = this.props;
    this.updateCaptcha();
    if (onMount) onMount(this);
  }

  componentDidUpdate(prevProps) {
    const { refreshCaptcha } = this.props;

    if (refreshCaptcha && refreshCaptcha !== prevProps.refreshCaptcha) {
      this.updateCaptcha();
      this.setValue('');
    }
  }

  getValue() {
    return this.state.captcha;
  }

  setValue(captcha) {
    this.setState({ captcha });
  }

  handleChange = (value) => {
    const { onChange } = this.props;
    const { captchaKey } = this.state;
    this.setState({ captcha: value });
    if (onChange) onChange(captchaKey ? { captcha: value, captchaKey } : value);
  };

  async updateCaptcha() {
    const { captchaUrl, captchaKey, width, height } = this.props;
    const captchaRequestUrl = `/${captchaUrl}?w=${width}&h=${height}&key=${captchaKey}&r=${Math.random()}`;
    const newCaptcha = await fetchCaptcha(captchaRequestUrl);
    this.setState(newCaptcha);
  }

  render() {
    const { name, onBlur, onFocus, status, msg, inputId, size, forceTouch, disabled, inputClass } =
      this.props;
    const { pic, captcha } = this.state;

    return (
      <div className={cx('captcha')}>
        <SmartInput
          captcha={pic}
          disabled={disabled}
          forceTouch={forceTouch}
          inputId={inputId}
          mask="_____"
          msg={msg}
          name={name}
          onBlur={onBlur}
          onChange={this.handleChange}
          onFocus={onFocus}
          ref={(e) => (this.inputElement = e)}
          size={size}
          status={status}
          textInputClass={inputClass}
          value={captcha}
        />
        <div className={cx('reset')}>
          <Link onClick={this.updateCaptcha}>Обновить символы</Link>
        </div>
      </div>
    );
  }
}

Captcha.defaultProps = {
  width: 123,
  height: 39,
  refreshCaptcha: false,
  captchaUrl: DEFAULT_CAPTCHA_URL,
};

Captcha.propTypes = {
  name: PropTypes.string,
  captchaKey: PropTypes.string,
  inputClass: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  status: PropTypes.string,
  msg: PropTypes.string,
  onMount: PropTypes.func,
  inputId: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  forceTouch: PropTypes.bool,
  disabled: PropTypes.bool,
  refreshCaptcha: PropTypes.bool,
  captchaUrl: PropTypes.string,
};

export default Captcha;
