import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TPrice = {
  price?: string;
  unit?: string;
};

/** Компонент с общей ценой за скрытые опции */
export const Price: FC<TPrice> = ({ price, unit = '₽/мес' }) =>
  price ?
    <div className={cx('wrapper')}>
      <Text size="size3-m">{`${price} ${unit}`}</Text>
    </div>
  : null;
