import { createSlice } from '@reduxjs/toolkit';

import { IInitialState } from './types';

const initialState: IInitialState = {
  benefits: {
    anchorId: '',
    description: '',
    items: [],
    title: '',
  },
  faq: {
    tabs: [],
    title: '',
  },
  formBlocks: {
    inputBlock: {
      buttonTitle: '',
      title: '',
      placeholder: '',
    },
    listBlock: {
      list: [],
      title: '',
      icon: '',
    },
    qrBlock: {
      description: '',
      imgQR: '',
      title: '',
      buttonTitle: '',
      deeplink: '',
    },
  },
  footer: {
    consentText: {
      clickableText: '',
      textAfter: '',
      textBefore: '',
    },
    copyright: '',
    countryInfo: {
      icon: '',
      text: '',
    },
    popupContent: {
      buttonText: '',
      text: '',
      title: '',
    },
    siteMap: {
      link: '',
      text: '',
    },
    socialsLinks: [],
  },
  instruction: {
    anchorId: '',
    items: [],
    title: '',
  },
  main: {
    mobileTitle: '',
    anchorId: '',
    buttons: {
      authButton: '',
      chat: '',
      imageBall: '',
      imageMenu: '',
      titleButon: '',
    },
    description: '',
    errors: {
      failedRequest: '',
      isCtnAlreadyInBasket: '',
      isExistingCtn: '',
      notFilledCtn: '',
    },
    menu: {
      anchors: [],
      links: [],
      logoSrc: '',
    },
    title: '',
  },
  other: {
    items: [],
    title: '',
  },
};

const commonSlice = createSlice({
  name: 'MNP_LANDING/COMMON',
  initialState,
  reducers: {},
});

export const commonReducer = commonSlice.reducer;
