import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type ArticlePropsType = {
  annotation?: string;
  link: string;
  nodeRef?: React.RefObject<HTMLLIElement>;
  onClick?: () => void;
  title: string;
};

export const Article = ({ link, title, annotation, onClick, nodeRef }: ArticlePropsType) => (
  <li ref={nodeRef}>
    <a className={cx('link')} href={link} onClick={onClick}>
      <Text size="size4-r">{title}</Text>
      {annotation && (
        <Text color="grey60" size="size5-r-l">
          {annotation}
        </Text>
      )}
    </a>
  </li>
);
