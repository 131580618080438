import { DEFAULT_INDEX } from './constants';
import { initialState as stepperInitialState } from './store/slices/stepperSlice';
import { prepareOptionsForPassiveAbility } from './utils/prepareOptionsForPassiveAbility';
import { getDefaultPresets } from './utils/getDefaultPresets';
import { getActivePresets } from './utils/getActivePresets';
import { getOptionsSocs } from './utils/getOptionsSocs';
import { getDefaultTariffTab } from './utils/getDefaultTariffTab';
import { DEFAULT_TAB_ID } from './components/constructorPage/FmcTabsContainer/constants';

/** Позволяет подготовить данные для инициализации стейта начальными свойствами */
// TODO: типизировать data!
export const dataConverter = ({ data, layoutType }: Record<string, any>) => {
  const { id } = data;
  const {
    baseUrl,
    content,
    presets,
    options: rawOptions,
    tariffInfo,
    userRegion,
    extraServices,
    convergenceBlock,
    isElkSettings,
    userInfo,
    latestTariffUpLink,
  } = data.layoutData;

  /** Активный персонаж по-умолчанию */
  const activeCharacter = getDefaultPresets(tariffInfo);
  const { soc, optionSoc, highOptionSoc, generation, alias } =
    activeCharacter || tariffInfo[DEFAULT_INDEX];

  /**  Данные по-дефолтному constructorId, старой и текущей цене пресета */
  const {
    price: presetsPrice,
    oldPrice: presetsOldPrice,
    constructorId,
    isHighPreset,
    priceCategory,
  } = presets;

  /** Активный сок персонажа по-умолчанию. Меняется в зависимости от типа пресета (isHighPreset) */
  const activeCharacterSoc = isHighPreset ? highOptionSoc : optionSoc;

  /** Активный пресет с гигабайтами по-умолчанию */
  const activeGbPreset = getActivePresets(presets?.gbytes);
  const { value: gbValue } = activeGbPreset || presets.gbytes[DEFAULT_INDEX];

  /** Активный пресет с минутами по-умолчанию */
  const activeMinPreset = getActivePresets(presets?.minutes);
  const { value: minValue } = activeMinPreset || presets.minutes[DEFAULT_INDEX];

  /** Подготовленные опции с измененными полями цен, активности и блокировки
   * с учетом текущего типа пресета и наличия пассивных способностей */
  const preparedOptions = prepareOptionsForPassiveAbility(rawOptions, activeCharacterSoc);

  /** Активные опции тарифа по-умолчанию */
  const optionsSoc = getOptionsSocs(preparedOptions);

  /** Дефолтные доп.сервисы и конвергенция (конвергенция, семья, ТВ)   */
  const tariffServices = { extraServices, convergenceBlock };

  /** Дефолтное значения таба выбора тарифа */
  const defaultTariffTab = getDefaultTariffTab(userInfo.isAuthenticated);

  /** Дефолтное значения сок персонажа, пресет гб/мин и опций */
  const defaultTariffData = {
    gbValue,
    minValue,
    optionsSoc,
    convergenceBlock,
    characterSoc: activeCharacterSoc,
    tariffInacId: userInfo.convergenceConnectedInfo?.inacId ?? DEFAULT_TAB_ID,
  };

  /** Дефолтный конструктор (бекап) */
  const defaultConstructor = {
    presets,
    options: rawOptions,
    tariffInfo,
    tariffServices,
    defaultTariffData,
  };

  /** Статический контент и бекап тарифа */
  const common = {
    baseUrl,
    content,
    /* Включает в себя isAuthenticated, isConnected,
     isConvergentUser, isArchived, activeCTN, convergenceConnectedInfo */
    userInfo,
    userRegion,
    isAuthenticated: userInfo.isAuthenticated,
    layoutType,
    isElkSettings,
    latestTariffUpLink,
    defaultConstructor,
  };

  /* Итоговый набор socs тарифа и общей суммы (total) */
  const total = {
    id,
    alias,
    gbValue,
    minValue,
    generation,
    optionsSoc,
    isHighPreset,
    presetsPrice,
    constructorId,
    priceCategory,
    tariffSoc: soc,
    presetsOldPrice,
    characterSoc: activeCharacterSoc,
    currentTariffTab: defaultTariffTab,
  };

  /* Общие начальные данные по тарифу для store */
  return {
    total,
    common,
    presets,
    options: preparedOptions,
    tariffServices,
    tariffsInfo: tariffInfo,
    stepper: stepperInitialState,
  };
};
