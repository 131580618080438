import React from 'react';

// TODO: Не забыть про импорт при выносе в отдельный пакет EquipmentCheckbox (tempComponents)!
import { EquipmentCheckbox } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/EquipmentCheckbox';
import { ModalHeader } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/components/modalComponents/ModalHeader';
import { ModalContainer } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/components/modalComponents/ModalContainer';

import { ECheckboxType, EEquipmentType } from '../types';
import { usePrepareInternetEquipment } from '../../hooks/usePrepareInternetEquipment';

/** Компонент-обертка настройки подключения интернет оборудования */
export const InternetEqContainer = () => {
  const { checkboxProps, equipmentProps, modalProps } = usePrepareInternetEquipment({
    equipmentType: EEquipmentType.internetEquipment,
    checkboxType: ECheckboxType.internetEquipmentCheckbox,
  });

  if (!equipmentProps || !checkboxProps) return null;

  return (
    <section>
      <EquipmentCheckbox checkboxProps={checkboxProps} equipmentProps={equipmentProps} />
      <ModalContainer {...modalProps.modalContainerProps}>
        <ModalHeader {...modalProps.modalHeaderProps} />
      </ModalContainer>
    </section>
  );
};
