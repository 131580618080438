import { getFieldNames } from '../../utils';
import { getFormSlice, getValidateField } from '../reducers';

const initialState = {
  data: {
    shouldScrollIntoView: {
      value: false,
    },
    sms: {
      value: '',
      error: '',
      touched: false,
      dirty: false,
    },
    smsCode: {
      value: '',
      error: '',
    },
    captcha: {
      value: '',
      error: '',
    },
    button: {
      value: 'idle',
      error: '',
    },
  },
};

export const getTimeCapsuleFieldNames = () => getFieldNames(initialState);

export const getValidateAndSetError = (dispatch, state) => (fieldName, validators) => {
  const validateField = getValidateField(state);
  const value = validateField(fieldName, validators);

  dispatch(setError({ fieldName, value }));
  return value;
};

const timeCapsuleSlice = getFormSlice(initialState);

export const { setValue, setError, setMetaFlag } = timeCapsuleSlice.actions;
export default timeCapsuleSlice.reducer;
