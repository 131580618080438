import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@beef/ui-kit';

// Components
import Picture from 'pages-components/Picture/index';

// Types
import { IErrorScreen } from './types';
// Styles
import style from './styles.pcss';

const cn = classNames.bind(style);

const ErrorScreen: FC<IErrorScreen> = ({
  img,
  title,
  description,
  onClick,
  onAdditionalClick,
  buttons,
  source,
  classNameImg,
  alt = '',
  titleSize = 'size4-m',
  descriptionSize = 'size6-r',
  fullWidth,
  colorDescription = 'grey60',
  colorTitle = 'grey95',
}) => {
  const { main, additional } = buttons || {};

  return (
    <div className={cn('wrapper')}>
      <div className={cn('block__img')}>
        <Picture alt={alt} className={classNameImg} source={source} src={img} />
      </div>

      {title && (
        <div className={cn('block__title')}>
          <Text color={colorTitle} size={titleSize}>
            {title}
          </Text>
        </div>
      )}

      {description && (
        <div className={cn('block__description')}>
          <Text color={colorDescription} size={descriptionSize}>
            {description}
          </Text>
        </div>
      )}

      <div className={cn('block__handlers')}>
        {main?.text && (
          <Button fullWidth={fullWidth} onClick={onClick} size="m">
            {main?.text}
          </Button>
        )}
        {additional?.text && (
          <Button fullWidth={fullWidth} onClick={onAdditionalClick} size="m" variant="tertiary">
            {additional?.text}
          </Button>
        )}
      </div>
    </div>
  );
};

export { ErrorScreen };
