import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Preloader } from '@beef/legacy-ui-kit';
import { Button, Link, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import PopupWithBack from 'pages-components/PopupWithBack/PopupWithBack';
import BubbleMessage from 'pages-components/BubbleMessage';
import { templateParser } from 'utils/format-string';

import ResultHeader from './ResultHeader';
import styles from './ResultPopup.pcss';

const cx = classNames.bind(styles);

export class ResultPopupContent extends PureComponent {
  state = { constructorState: null };

  componentDidMount() {
    if (this.props.shown) {
      this.getConstructorState();
    }
    this.props.onMount?.();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.shown && newProps.shown !== this.props.shown) {
      this.getConstructorState();
    }
  }

  getConstructorState = () => {
    const constructorState =
      localStorage.getItem('tariffConstructorState') || this.state.constructorState;

    this.setState({ constructorState });
    localStorage.removeItem('tariffConstructorState');
  };

  handleClick = () => {
    this.props.sendAnalyticsOnButtonClick?.();
    const { data } = this.props;
    if (data.buttonUrl) {
      window.location.href = data.buttonUrl;
    } else if (this.state.constructorState && this.props.onTariffConstructorOpen) {
      this.props.closePopup();
      this.props.onTariffConstructorOpen();
    } else if (data.step === 'confirmation') {
      this.props.confirmationPopup();
    } else if (this.props.onButtonClick) {
      this.props.onButtonClick();
    } else if (data.step === 'callback') {
      this.props.callback();
    } else {
      this.props.closePopup();
    }
  };

  handleSecondaryClick = () => {
    if (this.state.constructorState) {
      this.props.closePopup();
    } else if (this.props.data.buttonResetUrl) {
      window.location.href = this.props.data.buttonResetUrl;
    } else if (this.props.onSecondaryButtonClick) {
      this.props.onSecondaryButtonClick();
    } else {
      this.props.closePopup();
    }
  };

  renderPopup() {
    const { data, textVariables, type, retryButton } = this.props;

    if (!data || Object.keys(data).length === 0) {
      return null;
    }

    if (data.step === 'pending') {
      return <Preloader className="center" />;
    }

    const { constructorTexts } = data;
    const resultPopupDescription = data && (data.content || data.description);

    if (type === 'service') {
      return (
        <div className={cx('wrapper')}>
          {data.titleIcon && <img alt="" className={cx('icon')} src={data.titleIcon} />}
          <div className={cx('heading')}>
            <Text size="size1-m" tagType="h1">
              {data.title}
            </Text>
          </div>

          {resultPopupDescription && (
            <div className={cx('text')}>
              <Text size="size6-r">{resultPopupDescription}</Text>
            </div>
          )}
          {data.buttonText ?
            <div className={cx('btn-wrap')}>
              <Button onClick={this.handleClick} size="l">
                <span dangerouslySetInnerHTML={{ __html: data.buttonText }} />
              </Button>
            </div>
          : null}
          {data.buttonResetText && (
            <div className={cx('resetLink')}>
              <Link onClick={this.handleSecondaryClick}>{data.buttonResetText}</Link>
            </div>
          )}
        </div>
      );
    }

    const buttonText = retryButton ? data.buttonRetryText : data.buttonText;

    return (
      <div className={cx('wrapper')}>
        <ResultHeader icon={data.titleIcon} title={data.title} />
        <div className={cx('resultDescription')}>
          <Text size="size6-r">
            <span
              dangerouslySetInnerHTML={{
                __html:
                  constructorTexts ?
                    constructorTexts[this.state.constructorState || 'approved']
                  : templateParser(resultPopupDescription, textVariables),
              }}
            />
          </Text>
        </div>
        {data.bubble && (
          <div className={cx('bubble')}>
            <BubbleMessage content={data.bubble.content} icon={data.bubble.icon} />
          </div>
        )}
        {buttonText && (
          <div className={cx('btn-wrap')}>
            <Button onClick={this.handleClick} size="l">
              {buttonText}
            </Button>
          </div>
        )}
        {data.buttonResetText ?
          <div className={cx('resetLink')}>
            <Link onClick={this.handleSecondaryClick}>{data.buttonResetText}</Link>
          </div>
        : null}
      </div>
    );
  }

  render() {
    const { children } = this.props;
    const element = this.renderPopup();

    if (!element) {
      return null;
    }

    return (
      <div>
        {element}
        {children}
      </div>
    );
  }
}

ResultPopupContent.defaultProps = {
  data: null,
  textVariables: {},
};

ResultPopupContent.propTypes = {
  onSecondaryButtonClick: PropTypes.func,
  onTariffConstructorOpen: PropTypes.func,
  confirmationPopup: PropTypes.func,
  textVariables: PropTypes.shape({}),
  onButtonClick: PropTypes.func,
  retryButton: PropTypes.bool,
  closePopup: PropTypes.func,
  callback: PropTypes.func,
  onMount: PropTypes.func,
  type: PropTypes.string,
  data: PropTypes.shape({}),
  shown: PropTypes.bool,
  sendAnalyticsOnButtonClick: PropTypes.func,
};

const ResultPopup = ({ closePopup, data, onBackButtonClick, ...props }) => (
  <PopupWithBack
    backLinkText={data && data.backButtonText}
    onBackClick={onBackButtonClick}
    onClose={closePopup}
    opened={data && Object.keys(data).length > 0}
    showBackButton={data && data.backButtonText}
  >
    <ResultPopupContent {...props} />
  </PopupWithBack>
);

ResultPopup.propTypes = {
  data: PropTypes.shape({}),
  backButtonText: PropTypes.string,
  closePopup: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func,
};

export default ResultPopup;
