import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@carnica/ui-kit';

import { SkeletonFloatingTotal } from 'constructorV1/components/tempComponents/SkeletonFloatingTotal';
import { ESkeletonFloatingTotalTypes } from 'constructorV1/types';

import { useFloatingTotal } from './hooks/useFloatingTotal';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент плавающего блока с ценой и кнопкой ЕК (для мобильной версии) */
export const FloatingTotal: FC = () => {
  /** Подготовленный контент блока.
   * Строка с пробелом - костыль бага компонента Button из carnica */
  const {
    title,
    btnText = ' ',
    priceText,
    isLoading,
    isMyTariff,
    handleClick,
    isHiddenOnSection,
  } = useFloatingTotal();

  /* Если текущий тариф не подключен и есть контент - выводим плавающую кнопку */
  return !isMyTariff && btnText ?
      <section className={cx('wrapper', { 'wrapper--hidden': isHiddenOnSection })}>
        <div className={cx('wrapper__content')}>
          {title && (
            <Text align="left" color="secondary" lineHeight={24} size="xs" weight="regular">
              {title}
            </Text>
          )}
          {isLoading ?
            <SkeletonFloatingTotal type={ESkeletonFloatingTotalTypes.price} />
          : <Text align="left" color="primary" lineHeight={24} size="m" weight="medium">
              {priceText}
            </Text>
          }
        </div>
        {isLoading ?
          <SkeletonFloatingTotal type={ESkeletonFloatingTotalTypes.button} />
        : <Button onClick={handleClick} view="primary" width="full">
            {btnText}
          </Button>
        }
      </section>
    : null;
};
