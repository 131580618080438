import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Heading,
  // @ts-expect-error legacy-ui-kit не типизировн
} from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import ColumnsList from 'pages/FTTB/components/ColumnsList';
import { ZoneLink } from 'pages/FTTB/StreetsMainPage/components/ZonesList/ZoneLink';
import { ZonesListType } from 'pages/FTTB/StreetsMainPage/components/ZonesList/types';
import scrollToBlock from 'utils/scrollToBlock';
import { COUNTS_OF_ZONE_LIST } from 'pages/FTTB/constants';

import Pagination from '../Pagination';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ZonesList: FC<ZonesListType> = ({
  zoneList,
  showMoreBtnText,
  pagination,
  activePages,
}) => {
  const [showMoreZones, setShowMoreZones] = useState(zoneList.activePage !== 1);
  const firstZonesList = zoneList.zones?.slice(0, 12);
  const secondZonesList = zoneList.zones?.slice(12);
  const zoneListLength = zoneList.zones?.length || 0;

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const id = window.location.hash.replace('#', '');
    scrollToBlock(id, { block: 'start', behavior: 'smooth' });
  }, []);
  return (
    <div className={cx('wrapper')} id={`ref_${zoneList.streetAggregatorZoneGroup}`}>
      <Heading className={cx('title')} level={2}>
        <span dangerouslySetInnerHTML={{ __html: zoneList.title }} />
      </Heading>

      <div>
        <ColumnsList>
          {firstZonesList?.map((zoneItem) => (
            <div className={cx('street')} key={zoneItem.url}>
              <ZoneLink zone={zoneItem} />
            </div>
          ))}
        </ColumnsList>
        <div className={cx({ hidden: !showMoreZones })}>
          <ColumnsList>
            {secondZonesList?.map((zoneItem) => (
              <div className={cx('street')} key={zoneItem.url}>
                <ZoneLink zone={zoneItem} />
              </div>
            ))}
          </ColumnsList>
        </div>
      </div>
      {!showMoreZones && zoneListLength > COUNTS_OF_ZONE_LIST && (
        <div className={cx('showMoreBtn')}>
          <Button className="big" color="light" onClick={() => setShowMoreZones(true)} wide>
            <span dangerouslySetInnerHTML={{ __html: showMoreBtnText }} />
          </Button>
        </div>
      )}

      <div className={cx('paginationWrapper', { hidden: !showMoreZones })}>
        {zoneList.pageCount > 1 && (
          <Pagination
            activePage={zoneList.activePage}
            activePages={activePages}
            pagination={pagination}
            totalPageCount={zoneList.pageCount}
            zoneType={zoneList.streetAggregatorZoneGroup}
          />
        )}
      </div>
    </div>
  );
};
