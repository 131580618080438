import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import styles from './styles.pcss';
import { useAdditionalBlock } from './hooks/useAdditionalInfo';
import { HoveredCard } from './HoveredCard';
import { hoveredIconsCardArray } from '../../../../constants';

const cx = classNames.bind(styles);

/**
 * Блок с карточками дополнительной информации
 */
export const AdditionalInfoBlock: FC = () => {
  const { items, title } = useAdditionalBlock();
  return (
    <div className={cx('wrapper')}>
      <Text className={cx('title')} color="secondary" variant="body-medium">
        {title}
      </Text>

      <div className={cx('cards')} id="cards">
        {items.map((card) => (
          <HoveredCard
            {...card}
            icons={hoveredIconsCardArray[card.id]}
            key={card.id}
            rightTopIconClassName={`${card.id === 3 ? cx('rotate-icon') : undefined}`}
          />
        ))}
      </div>
    </div>
  );
};
