import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TOptionsItem = {
  name: string;
  oldPrice: string;
  price: string;
};

/** Item списка подключаемых/отключаемых опций и присетов тарифа */
export const OptionsItem = memo<TOptionsItem>(({ name, price, oldPrice }) => (
  <li className={cx('wrapper')}>
    {name && (
      <Text className={cx('wrapper__text')} color="grey60" size="size5-r-s">
        {name}
      </Text>
    )}
    <div className={cx('wrapper__price')}>
      {price && <Text size="size5-r-s">{price}</Text>}
      {oldPrice && (
        <Text color="grey60" isCrossedOut size="size6-r">
          {oldPrice}
        </Text>
      )}
    </div>
  </li>
));
