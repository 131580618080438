import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TIcons = {
  icons?: string[];
};

/** Компонент отображает иконки в плитке тарифа */
export const Icons: FC<TIcons> = ({ icons }) => (
  <div className={cx('wrapper')}>
    {icons?.map((icon) => (
      <img alt="" className={cx('wrapper__icon')} key={icon} loading="lazy" src={icon} />
    ))}
  </div>
);
