import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';

import TextContainer from '../TextContainer/TextContainer';
import NumberList from '../NumberList/NumberList';
import commonStyles from '../SharingService.pcss';
import styles from './DisconnectPopup.pcss';

const cx = classNames.bind({ ...styles, ...commonStyles });

class SettingsPopup extends Component {
  renderDisconnectedNumbers() {
    return (
      <div className={cx('numberList')}>
        <Heading level={4}>
          <TextContainer
            className={cx('numberListTitle')}
            path={['texts', 'disconnectNumberListTitle']}
          />
        </Heading>
        <div className={cx('tableWrapper')}>
          <NumberList noControls numbers={this.props.numbers} />
        </div>
      </div>
    );
  }

  renderTextForDisconnectAll = () => (
    <div>
      <TextContainer className={cx('description')} path={['texts', 'connectedPacket']} />
      {!this.props.addPacket ?
        <TextContainer className={cx('description')} path={['texts', 'connectedPacketRepeatPay']} />
      : null}
      {!this.props.addPacket ?
        <TextContainer
          className={cx('description')}
          path={['texts', 'connectedPacketRepeatTraffic']}
          variables={{ traffic: this.props.addPacketSize }}
        />
      : null}
    </div>
  );

  renderTextForLastNumber = () => {
    const { addPacketSize, addPacket } = this.props;
    const isAddPacketText = !addPacket && !!addPacketSize;

    return (
      <div>
        <TextContainer
          className={cx('description')}
          path={['texts', 'disconnectWarningDescription']}
          variables={{ number: this.props.number.number }}
        />
        {isAddPacketText && (
          <>
            <TextContainer
              className={cx('description')}
              path={['texts', 'connectedPacketRepeatPay']}
            />
            <TextContainer
              className={cx('description')}
              path={['texts', 'connectedPacketRepeatTraffic']}
            />
          </>
        )}
      </div>
    );
  };

  render() {
    const { numbers } = this.props;
    return (
      <PopupStateless onClose={this.props.onClose} opened={this.props.opened}>
        <div className={cx('content')}>
          <Heading level={1}>
            <TextContainer className={cx('title')} path={['texts', 'disconnectTitle']} />
          </Heading>
          {numbers.length > 1 ? this.renderTextForDisconnectAll() : this.renderTextForLastNumber()}
          {numbers.length > 1 && this.renderDisconnectedNumbers()}
          <div>
            <div className={cx('popupButton')}>
              <Button className="big" onClick={() => this.props.onDisconnect()} wide>
                <TextContainer path={['texts', 'disconnectButtonText']} />
              </Button>
            </div>
          </div>
          {numbers.length === 1 && numbers[0].status === 'connected' && (
            <div className={cx('links')}>
              <Link
                onClick={() => this.props.onChangeNumbers(numbers[0].number, numbers[0].status)}
              >
                <TextContainer
                  className={cx('simOfferLink')}
                  path={['texts', 'disconnectChangeNumbersLink']}
                />
              </Link>
            </div>
          )}
        </div>
      </PopupStateless>
    );
  }
}

SettingsPopup.defaultProps = {
  number: {},
};

SettingsPopup.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  numbers: PropTypes.array,
  number: PropTypes.object,
  addPacket: PropTypes.bool,
  addPacketSize: PropTypes.number,
  onChangeNumbers: PropTypes.func,
  onDisconnect: PropTypes.func,
};

export default SettingsPopup;
