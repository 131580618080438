import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Actions from '../Actions';
import Param from './components/Param/Param';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Stats = ({
  title,
  className,
  bodyClass,
  parameters,
  gift,
  isForYoungDetailEnabled,
  selectedSoc,
  handlePackageSelect,
}) => {
  const handleClick = useCallback(
    (soc, price) => {
      handlePackageSelect(soc, price);
    },
    [handlePackageSelect],
  );
  return (
    <div className={cx('wrap', className)}>
      <div className={cx('body', bodyClass)}>
        {title && <div className={cx('label', { labelNoBorder: !!gift })}>{title}</div>}
        {gift && (
          <div className={cx('gift')}>
            <Actions className="stats" isLayoutYoung={isForYoungDetailEnabled} text={gift} />
          </div>
        )}
      </div>
      {parameters.length > 0 && (
        <div className={cx('scrollWrap')}>
          <ul className={cx('stats')}>
            {parameters.map((param, i) => (
              <Param
                key={i}
                onClick={() => {
                  if (isForYoungDetailEnabled && !param.isGift) {
                    const price = param.priceValue && param.soc ? param.priceValue : 0;
                    handleClick(param.soc, price);
                  }
                }}
                param={param}
                previousSelected={selectedSoc === parameters[i - 1]?.soc}
                selectable={isForYoungDetailEnabled}
                selected={selectedSoc === param.soc || param.isGift}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

Stats.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  bodyClass: PropTypes.string,
  gift: PropTypes.string,
  isForYoungDetailEnabled: PropTypes.bool,
  selectedSoc: PropTypes.string,

  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      unit: PropTypes.string,
      price: PropTypes.string,
      soc: PropTypes.string,
    }),
  ),

  handlePackageSelect: PropTypes.func,
};

Stats.defaultProps = {
  isForYoungDetailEnabled: false,
};

export default Stats;
