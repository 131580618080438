import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { getLocalCurrencyStr } from '@beef/utils';
import { Text } from '@beef/ui-kit';

import { TInfoPrice } from '../../../store/slices/fttbSlice/types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Блок отображения цены с периодом и перечеркнутой скидки */
export const PriceBlock: FC<TInfoPrice> = ({ price, oldPrice, extraUnit }) => (
  <div className={cx('component', { baseline: !oldPrice })}>
    {/* Цена */}
    <Text size="size2-m">{getLocalCurrencyStr(price)}</Text>
    <div className={cx('block')}>
      {/* Скидка */}
      {oldPrice && (
        <Text className={cx('discount')} color="grey40" isCrossedOut size="size5-r-s">
          {getLocalCurrencyStr(oldPrice)}
        </Text>
      )}
      {/* Экстра юнит например /мес */}
      <Text color="grey60" size="size5-r-s">
        {extraUnit}
      </Text>
    </div>
  </div>
);
