import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, pathOr, propEq } from 'ramda';

import ServiceConnection from 'pages/ProductCard/components/ServiceConnection';
import { RedirectServiceStatus } from 'pages/ProductCard/components/ServiceConnectionStatus';
import { optionsPopup } from 'pages/ProductCard/actions/redirect';
import { openConfirmation } from 'pages/ProductCard/components/ServiceStatusPopup/actions';

const RedirectConnection = ({
  data,
  isDisabled,
  isNoneActive,
  loaded,
  onConnect,
  onOptionsOpen,
  ...props
}) => {
  const { connectButton, isConnectedLater, isConnected, isAuthenticated } = data || {};
  const showConnect =
    (!isConnectedLater && !isConnected) || !isDisabled || (loaded && isNoneActive);

  return (
    <ServiceConnection
      connectionText={isNoneActive && isAuthenticated ? 'Настроить' : connectButton}
      isDisabled={isNoneActive ? false : isDisabled}
      showConnect={showConnect}
      {...props}
      data={data}
      onConnect={isNoneActive ? onOptionsOpen : onConnect}
      statusComponent={RedirectServiceStatus}
    />
  );
};

RedirectConnection.propTypes = {
  onConnect: PropTypes.func,
  onOptionsOpen: PropTypes.func,
  isNoneActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  loaded: PropTypes.bool,
  data: PropTypes.object,
};

const mapStateToProps = (state, { data: { isAuthenticated } }) => {
  const status = pathOr([], ['external', 'redirect', 'serviceStatus'], state);
  const { loaded, loading, saved, options } = status || {};
  const { name } = find(propEq('active', true))(options);
  const isNoneActive = name === 'none';

  return {
    isDisabled: isAuthenticated && saved,
    loaded: !loading && loaded,
    isNoneActive,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onConnect: () => dispatch(openConfirmation({ key: 'redirectService' })),
  onOptionsOpen: () => dispatch(optionsPopup({ opened: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RedirectConnection);
