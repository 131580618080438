import { RedirectOptions } from 'pages/Services/RedirectServiceCardLanding/store/redirect/types';
import { RedirectByConditionsTypes } from 'pages/Services/RedirectServiceCardLanding/types';

export const defaultOptions: RedirectOptions = {
  doNotRedirect: true,
  redirectAlways: false,
  redirectByConditions: false,
  phoneAlwaysRedirect: null,
  phoneIfUnavailable: null,
  phoneIfBusy: null,
  phoneIfNoAnswer: null,
};

export const optionsListForByConditions: Array<RedirectByConditionsTypes> = [
  'phoneIfNoAnswer',
  'phoneIfUnavailable',
  'phoneIfBusy',
];
export const redirectServiceUrl = {
  qpContent: '/customers/products/mobile/services/getredirectdatacontent/',
  getRedirectData: '/customers/products/mobile/services/getredirectdata/',
  checkConnectServiceStatus: '/customers/products/mobile/services/checkconnectservicestatus/',
};

export const statusNot200 = 'Получен статус не 200';

export const idFormRedirectSettings = 'idFormRedirectSettings';
