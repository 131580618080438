import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';
import { Status } from '@beef/legacy-ui-kit';

import Discount from 'pages-components/Discount';
import Nameplate from 'pages-components/Nameplate';
import Picture from 'pages-components/Picture';
import RefreshTile from 'pages-components/Tiles/Refresh';

import Parameter from './components/Parameter';
import DigitalParameter from './components/DigitalParameter';
import SimpleParameter from './components/SimpleParameter';
import FlatButton from './components/FlatButton';
import GroupButton from './components/GroupButton';
import CardBubble from './components/CardBubble';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffCard = ({
  actionLabel,
  additionalParameters,
  inlineDiscountText,
  additionalParamsHeight,
  nameplateDiscount,
  bottomButtonType,
  buttonGroup,
  nameplateIcon,
  onButtonClick,
  bottomBubble,
  topBubble,
  parameters,
  buttonText,
  hideButton,
  clickable,
  className,
  isCardsHasUpperBar,
  upperBar,
  description,
  title,
  price,
  cardStyle,
  images,
  alias,
  link,
  fullLink,
  withLevitate,
  priceNotLoaded,
  paramsGridView,
  howToConnect,
  onHowToConnect,
  forceOnButtonClick,
  orderButton,
  isYandex,
  offerType,
}) => {
  const onMainButtonClick = (e) => {
    if (e.shiftKey || e.altKey || e.ctrlKey || e.metaKey) return;
    e.preventDefault();
    if ((onButtonClick && alias) || forceOnButtonClick) {
      onButtonClick(e, 'mainButton', alias);
    } else {
      window.location.href = link;
    }
  };
  const isGridView = paramsGridView && cardStyle !== 'young';

  const onBottomButtonClick = (e) => {
    if (onButtonClick && alias) {
      onButtonClick(e, 'bottomButton', alias);
    }
    if (clickable && link && !onButtonClick) {
      window.location.href = link;
    }
    if (!alias && link) {
      window.location.href = link;
    }
  };

  return (
    <div
      className={cx(
        'card',
        cardStyle,
        className,
        !clickable && 'disabled',
        images && 'cardWithImage',
        isCardsHasUpperBar && 'withUpperBar',
        withLevitate && 'withLevitate',
        !upperBar && 'upperBarEmpty',
        !hideButton && 'withFooterButton',
      )}
    >
      {/* Баблик для скидки, отрисовывается над карточкой */}
      {topBubble && <CardBubble {...topBubble} />}

      {/* Баблик для предложений, отрисовывается под карточкой */}
      {bottomBubble && <CardBubble {...bottomBubble} className={cx('cardBubble')} />}

      {upperBar && (
        <div className={cx('upperBar')}>
          <Nameplate.UpperBar {...upperBar} />
        </div>
      )}

      <div className={cx('cardInner', cardStyle)}>
        {/* Угловой шильдик с текстом или иконкой в правом углу карточки */}
        {(nameplateIcon || nameplateDiscount) && (
          <Nameplate.Badge icon={nameplateIcon} label={nameplateDiscount} type="tawny" />
        )}

        {images && (
          <div className={cx('cardImage')}>
            <Picture
              alt=""
              pictureClassName={cx('cardPicture')}
              source={[
                {
                  srcSet: images.mobile,
                  media: 'max-width: 580px',
                },
              ]}
              src={images.desktop}
            />
          </div>
        )}

        <div className={cx('cardContainerWrap')}>
          {fullLink && <a className={cx('fullLink')} href={fullLink} />}

          {/* Ссылка срабатывает по клику на область карточки */}
          {!fullLink && (
            <a
              aria-label="Перейти"
              className={cx('mainButton')}
              href={link}
              onClick={onMainButtonClick}
            />
          )}

          <div className={cx('cardContainer')}>
            <div className={cx('parameters')}>
              <a className={cx('title')} dangerouslySetInnerHTML={{ __html: title }} href={link} />

              {description && (
                <div
                  className={cx('description')}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}

              {/* Линейный шильдик с текстом пересекающей карточку */}
              {inlineDiscountText && <Nameplate.Inline label={inlineDiscountText} />}

              {/* Параметры тарифа */}
              {parameters &&
                parameters.map((param, index) => (
                  <div
                    className={cx({
                      paramsGridView: isGridView,
                    })}
                    key={index}
                  >
                    {param.parameterType === 'digitalTariff' && <DigitalParameter {...param} />}

                    {(param.parameterType === 'tariff' || param.parameterType === 'promo') && (
                      <Parameter
                        {...param}
                        iconAbsoluteUrl={
                          param.iconName &&
                          (param.iconName.includes('.') || param.iconName.includes('/'))
                        }
                        isYandex={isYandex}
                        paramsGridView={paramsGridView}
                      />
                    )}
                    {param.parameterType === 'simpleTariff' && (
                      <SimpleParameter
                        {...param}
                        cardStyle={cardStyle === 'welcome' && cardStyle}
                      />
                    )}
                  </div>
                ))}
            </div>

            {/* Параметры которые выводятся под бардюром */}
            {additionalParameters && (
              <div
                className={cx('additionalParams', {
                  additionalParamsGridView: isGridView,
                })}
                style={{ height: isGridView ? 'auto' : `${additionalParamsHeight}px` }}
              >
                {additionalParameters.map((param, index) => (
                  <div
                    className={cx({
                      paramsGridView: isGridView,
                    })}
                    key={index}
                  >
                    <Parameter
                      {...param}
                      className={cardStyle}
                      iconAbsoluteUrl={
                        param.iconName &&
                        (param.iconName.includes('.') || param.iconName.includes('/'))
                      }
                      paramsGridView={paramsGridView}
                    />
                  </div>
                ))}
              </div>
            )}

            {/* Цена + Скидка, Бирка с акцией тут же, для выравнивания бирки */}
            {(price || actionLabel) && !priceNotLoaded && (
              <div
                className={cx('discountTariffCard')}
                style={{ height: price?.priceHeight ? `${price.priceHeight}px` : 'auto' }}
              >
                {/* Бирка с акцией */}
                {actionLabel && (
                  <div className={cx('actionLabelWrap', price?.label && 'actionLabelWrapSeparate')}>
                    <div className={cx('actionLabel')}>
                      <Discount.Label label={actionLabel} />
                    </div>
                  </div>
                )}
                {price && <Discount.Price price={price} />}
              </div>
            )}
          </div>
        </div>
        {priceNotLoaded && (
          <div className={cx('refresh-parameter')}>
            <RefreshTile
              direction="column"
              label="Показать абонентскую плату"
              text="Значение не было получено в отведенное время"
            />
          </div>
        )}

        {/* Желтая кнопка в нижней части карточки */}
        {!hideButton && offerType !== 'discount' && (
          <>
            {bottomButtonType === 'flat' && (
              <>
                {orderButton && (
                  <FlatButton
                    buttonText={orderButton.text}
                    className={cx({
                      noRadius: [howToConnect && bottomButtonType === 'flat'],
                    })}
                    onClick={orderButton.onClick}
                  />
                )}
                <FlatButton
                  buttonText={buttonText}
                  className={cx({
                    noRadius: [howToConnect && bottomButtonType === 'flat'],
                  })}
                  isLight={!!orderButton}
                  onClick={onBottomButtonClick}
                />
              </>
            )}
            {bottomButtonType === 'group' && <GroupButton {...buttonGroup} />}
          </>
        )}

        {howToConnect && bottomButtonType === 'flat' && offerType !== 'discount' && (
          <FlatButton
            buttonText={howToConnect?.text}
            isLight
            onClick={() => {
              if (onHowToConnect) {
                onHowToConnect();
                return;
              }
              window.location.href = howToConnect?.url;
            }}
          />
        )}
      </div>
      {offerType === 'discount' && (
        <Status className={['success', 'compact']}>
          <span>Мой тариф</span>
        </Status>
      )}
    </div>
  );
};

TariffCard.defaultProps = {
  withLevitate: true,
  paramsGridView: false,
};

TariffCard.propTypes = {
  actionLabel: PropTypes.string,
  title: PropTypes.string,
  inlineDiscountText: PropTypes.string,
  cardStyle: PropTypes.string,
  alias: PropTypes.string,
  link: PropTypes.string,
  fullLink: PropTypes.string,
  buttonText: PropTypes.string,
  nameplateDiscount: PropTypes.string,
  nameplateIcon: PropTypes.string,
  description: PropTypes.string,
  offerType: PropTypes.string,

  paramsGridView: PropTypes.bool,
  hideButton: PropTypes.bool,
  withLevitate: PropTypes.bool,
  clickable: PropTypes.bool,
  isCardsHasUpperBar: PropTypes.bool,
  forceOnButtonClick: PropTypes.bool,
  priceNotLoaded: PropTypes.bool,
  isYandex: PropTypes.bool,

  additionalParamsHeight: PropTypes.number,

  price: PropTypes.object,
  buttonGroup: PropTypes.object,
  upperBar: PropTypes.object,
  bottomBubble: PropTypes.object,
  topBubble: PropTypes.object,
  images: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
    }),
  ]),

  howToConnect: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),

  orderButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),

  parameters: PropTypes.array,
  additionalParameters: PropTypes.array,
  bottomButtonType: PropTypes.oneOf(['flat', 'group']),

  onClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  onHowToConnect: PropTypes.func,
};

export default TariffCard;
