import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Notification } from '@beef/ui-kit';

import { PassiveContainer } from '../PassiveContainer';
import { GB_TYPES } from './constants';
import Slider from './Slider';
import styles from './styles.pcss';
import { Title } from './Title';

const cx = classNames.bind(styles);

const Slides = memo(
  ({
    tooltip,
    content,
    slides,
    discount,
    handleChangeTotal,
    getTariffInfo,
    setUpsellSwitch,
    isNotDefaultPreset,
    longRcRateUnit,
    gbAndMinutesLongRate,
    isNotEnoughMoney,
    suitableGenerationForPassive,
  }) => {
    const [isShowNotification, setIsShowNotification] = useState(true);
    const handleClose = () => setIsShowNotification(false);

    return (
      <div className={cx('slides__wrapper')} data-component="Slides">
        {content?.title && (
          <Title
            discount={discount}
            gbAndMinutesLongRate={gbAndMinutesLongRate}
            isNotEnoughMoney={isNotEnoughMoney}
            longRcRateUnit={longRcRateUnit}
            title={content.title}
            tooltip={tooltip}
          />
        )}
        <div className={cx('slides')}>
          {slides.map((slide) => (
            <div className={cx('slide')}>
              <Slider
                getTariffInfo={getTariffInfo}
                handleChangeTotal={handleChangeTotal}
                isNotDefaultPreset={isNotDefaultPreset}
                key={slide.type}
                setUpsellSwitch={setUpsellSwitch}
                slide={slide}
                testName="Slider"
              />
              {/* Показываем пассивку после слайдера с типом GB_TYPES,
             если поколение тарифа подходит для этого (suitableGenerationForPassive) */}
              {suitableGenerationForPassive && slide.type === GB_TYPES && <PassiveContainer />}
            </div>
          ))}
        </div>
        {isShowNotification && (
          <Notification
            isWithCloseButton
            onClick={handleClose}
            text={content?.notificationText}
            variant="secondary"
          />
        )}
      </div>
    );
  },
);

Slides.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    notificationText: PropTypes.string,
  }),
  tooltip: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleChangeTotal: PropTypes.func,
  getTariffInfo: PropTypes.func,
  setUpsellSwitch: PropTypes.func,
};

export default Slides;
