import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { Tab, TabContent, Tabs, TabsList } from '@beef/legacy-ui-kit';
import { Text } from '@beef/ui-kit';

import Luck from './Luck';
import styles from './index.pcss';
import { fetchLuck, fetchNumbers, setFilter } from '../../actions';
import FavoriteNumber from './FavoriteNumber';

const cx = classNames.bind(styles);

const filters = [
  {
    id: 'mne-povezet',
    name: 'Мне повезёт',
    icon: '//static.beeline.ru/upload/images/emoji/dice.svg',
    component: Luck,
  },
  {
    id: 'lyubimoe-chislo',
    name: 'Любимое число',
    icon: '//static.beeline.ru/upload/images/emoji/star.svg',
    component: FavoriteNumber,
    extData: { method: 'favourite', minDigits: 2, maxDigits: 7 },
  },
  {
    id: 'perevertyish',
    name: 'Перевёртыш',
    icon: 'https://static.beeline.ru/upload/images/emoji/flip-flop.svg',
    component: FavoriteNumber,
    extData: { method: 'palindrome', minDigits: 2, maxDigits: 3 },
  },
];

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabId: 'mne-povezet',
    };
  }

  changeTab = (tabId) => () => {
    const filter = filters.find((item) => item.id === tabId);

    if (this.state.tabId !== 'mne-povezet' && tabId === 'mne-povezet') {
      this.fetchNumbers();
    }
    this.setState({ tabId }, () => {
      this.props.setFilter({ id: filter.id, name: filter.name });
    });
  };

  shuffleNumbers = () => {
    this.props.fetchLuck();
  };

  fetchNumbers = (path = 'all', query = '') => {
    this.props.fetchNumbers(path, query);
  };

  renderTab = (item) => (
    <Tab id={item.id} key={item.id} onClick={this.changeTab(item.id)}>
      <div className={cx('filterTab')}>
        <img alt="" className={cx('filterImage')} src={item.icon} />
        {item.name}
      </div>
    </Tab>
  );

  renderTabContent = (item) => (
    <TabContent id={item.id} key={item.id}>
      <div className={cx('description')}>
        <div className={cx('text')}>
          <Text size="size5-r-s">{this.props.texts.descriptions[item.id]}</Text>
        </div>
        <div className={cx('tab-action')}>
          <item.component
            data={{ description: this.props.texts.descriptions[item.id] }}
            extData={item.extData || {}}
            onSubmit={item.id === 'mne-povezet' ? this.shuffleNumbers : this.fetchNumbers}
          />
        </div>
      </div>
    </TabContent>
  );

  render() {
    return (
      <div className={cx('wrapper')}>
        <div className={cx('heading')}>
          <Text size="size1-m">{this.props.texts.heading}</Text>
        </div>
        <Tabs activeTab={this.state.filterId || 'mne-povezet'}>
          <TabsList className={cx('tabsList')}>{filters.map(this.renderTab)}</TabsList>
          {filters.map(this.renderTabContent)}
        </Tabs>
      </div>
    );
  }
}

Filter.propTypes = {
  texts: PropTypes.object.isRequired,
  fetchLuck: PropTypes.func.isRequired,
  fetchNumbers: PropTypes.func.isRequired,
  setFilter: PropTypes.func,
};

export default connect(
  () => ({}),
  (dispatch) => ({
    fetchNumbers: (path, query) => dispatch(fetchNumbers(path, query)),
    fetchLuck: () => dispatch(fetchLuck()),
    setFilter: (filter) => dispatch(setFilter(filter)),
  }),
)(Filter);
