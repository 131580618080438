import { setConnectionType } from 'constructorV1/store/userSlice';
import { useAppDispatch, useAppSelector } from 'constructorV1/store';
import { EConnectionType } from 'constructorV1/store/userSlice/types';
import { breakdownNumber } from 'constructorV1/utils/breakdownNumber';
import { selectIsAuthenticated } from 'constructorV1/store/selectors/selectUser';
import { setCurrentBillStep, setUserPhoneNumber } from 'constructorV1/store/totalSlice';
import { EBillStepper } from 'constructorV1/components/constructorPage/BillStepper/types';
import { selectMnpProps } from 'constructorV1/components/constructorPage/BillContainer/selectors/selectMnpProps';
import { selectBillProps } from 'constructorV1/components/constructorPage/BillContainer/selectors/selectBillProps';
import { selectPresetsLoading } from 'constructorV1/components/constructorPage/PresetPickers/selectors/selectPresetsLoading';

/** Хук подготовки данных в малом чеке ЕК */
export const useBillProps = () => {
  const dispatch = useAppDispatch();

  /** Флаг загрузки пресетов ЕК */
  const isLoading = useAppSelector(selectPresetsLoading);

  /** Флаг авторизации пользователя */
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  /** Выборка подготовленных данных для чека без mnpFormProps и tariffTabsProps */
  const { connectionType, ...billProps } = useAppSelector(selectBillProps);

  /* Общие контентные данные и выбранный номер телефона пользователя */
  const {
    billContent: { mnpContent, tariffTabs },
    userPhoneNumber = '',
  } = useAppSelector(selectMnpProps);

  /* Табы способа подключения тарифа (simOrderForm, Mnp) */
  const tariffTabsProps = {
    /* Если тариф НЕ подключен и mnp номер НЕ выбран, показываем блок табов */
    isVisible: !isAuthenticated && connectionType !== EConnectionType.mnp,
    /* Текущий активный таб способа подключения тарифа (simOrderForm, Mnp) */
    activeTab: connectionType,
    onChange: (id: EConnectionType) => {
      /* Устанавливаем текущий тип подключения connectionType */
      dispatch(setConnectionType(id));
      /* Если текущий id === mnp, открываем форму ввода mnp-номера */
      if (id === EConnectionType.mnp) {
        dispatch(setCurrentBillStep(EBillStepper.mnpForm));
      }
    },
    ...tariffTabs,
  };

  /* Результирующий объект для компонента mnpForm (выбранный номер MNP) */
  const mnpFormProps = {
    /* Если mnp номер выбран, показываем блок */
    isVisible: !!userPhoneNumber,
    /* Введенный номер пользователя */
    phone: breakdownNumber(`7${userPhoneNumber}`),
    /* Переход к блоку MNP */
    onChange: () => {
      dispatch(setCurrentBillStep(EBillStepper.mnpForm));
    },
    /* Сброс MNP */
    onClick: () => {
      /* Обнуляем введенный номер MNP */
      dispatch(setUserPhoneNumber(''));
      /* Меняем connectionType на newSim, что переключит таб способа подключения тарифа */
      dispatch(setConnectionType(EConnectionType.newSim));
      /* Переходим на главный чек ЕК */
      dispatch(setCurrentBillStep(EBillStepper.bill));
    },
    ...mnpContent,
  };

  return { isLoading, mnpFormProps, tariffTabsProps, ...billProps };
};
