import { Banner, Benefits, Extended, Group, Tariff } from './components';

/** Варианты компонентов страницы каталога  */
export const BLOCKS_COMPONENT = {
  tariff: Tariff,
  benefits: Benefits,
  group: Group,
  appBanner: Banner,
  extended: Extended,
};

/** Default индекс для вкладок FAQ */
export const DEFAULT_INDEX = 0;
