import { useAppSelector } from '../../../../store';

/** Селектор выборки title и description для total чека */
export const useSelectTariffTitle = () => {
  const content = useAppSelector((state) => state.common?.content);
  const { finalStepTitle, finalStepDescription } = content || {};

  return {
    title: finalStepTitle,
    description: finalStepDescription,
  };
};
