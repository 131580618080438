import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { AdvertisementBanner } from '@beef/smart-kit';
import classNames from 'classnames/bind';

import { BenefitPopup } from 'pages-components/Benefits';
import InfoPopup from 'pages-components/InfoPopup';

import styles from './styles.module.pcss';

const cx = classNames.bind(styles);

export const Benefits = ({ data }) => {
  const [benefitIndex, setBenefitIndex] = useState(null);

  const onBenefitClick = useCallback((e, idx) => {
    e.preventDefault();
    setBenefitIndex(idx);
  }, []);

  return (
    <>
      <div className={cx('benefits-container')}>
        <ul className={cx('benefits')}>
          {data.items.map((benefit, idx) => (
            <li className={cx('benefits__item')} key={idx}>
              <AdvertisementBanner
                backgroundColor={`#${benefit?.backgroundColor}`}
                backgroundImage={benefit?.bgImage}
                caption={benefit.text}
                captionTag="p"
                contentPosition="center"
                label={benefit.buttonText}
                link={benefit.popupContent || benefit.popupUrl ? '#' : null}
                onClick={(e) => onBenefitClick(e, idx)}
                size="s"
                theme="light"
                withHover
              />
            </li>
          ))}
        </ul>
      </div>

      {benefitIndex !== null && (
        <>
          {data.items[benefitIndex].popupContent && (
            <BenefitPopup
              onPopupClose={() => setBenefitIndex(null)}
              popupContent={data.items[benefitIndex].popupContent}
            />
          )}

          {data.items[benefitIndex].popupUrl && (
            <InfoPopup
              fetchUrl={data.items[benefitIndex].popupUrl}
              onClose={() => setBenefitIndex(null)}
            />
          )}
        </>
      )}
    </>
  );
};

Benefits.propTypes = {
  data: PropTypes.object,
};
