import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { getMobileOS } from 'utils/detect-mobile';
import { pushTVEUpsaleBlockAction } from 'utils/ga';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const onShopClick = () => pushTVEUpsaleBlockAction('eShopClick');
const onIOSClick = () => pushTVEUpsaleBlockAction('iosClick');
const onAndroidClick = () => pushTVEUpsaleBlockAction('androidClick');

const BuyTVEUpsale = (props) => {
  const { tveShopImage, tveShopText, tveShopBtnText, tveMobileText, tveShopHref, mobileApps } =
    props;
  const mobileOS = getMobileOS();
  const mobileApp = mobileApps[mobileOS];
  const onMobileClick = mobileOS === 'ios' ? onIOSClick : onAndroidClick;

  return (
    <div className={cx('wrapper')}>
      <div className={cx('bordered', 'tveShop')}>
        <img alt="билайн ТВ" src={tveShopImage} />
        <div
          className={cx('tveText', 'tveShopText')}
          dangerouslySetInnerHTML={{ __html: tveShopText }}
        />
        <div className={cx('buyInShopWrapper')}>
          <Button className={cx('buyInShop')} href={tveShopHref} onClick={onShopClick}>
            <span dangerouslySetInnerHTML={{ __html: tveShopBtnText }} />
          </Button>
        </div>
      </div>

      {mobileApp && (
        <div className={cx('bordered', 'tveMobile')}>
          <div className={cx('tveText')} dangerouslySetInnerHTML={{ __html: tveMobileText }} />
          <div className={cx('appBtn')}>
            <a
              href={mobileApp.appLink}
              onClick={onMobileClick}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="Приложение билайн ТВ" className={cx('appImage')} src={mobileApp.appImage} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

BuyTVEUpsale.defaultProps = {
  tveShopImage: 'https://static.beeline.ru/upload/images/fttb/beeline-tv-everywhere.jpg',
  tveShopText: 'С нашей ТВ приставкой смотрите билайн ТВ на интернете от любого провайдера',
  tveShopBtnText: 'В интернет-магазин',
  tveShopHref: '/shop/details/tv-pristavka-bilain-grb485-black/',

  tveMobileText: 'Смотрите билайн ТВ на вашем смартфоне!',
  mobileApps: {
    android: {
      appLink: 'https://app.appsflyer.com/ru.beeline.tve.android?af_prt=vivaki.ru',
      appImage: 'https://static.beeline.ru/upload/images/tve/appMarketLogos/google-play-logo.svg',
    },
    ios: {
      appLink: 'https://app.appsflyer.com/id979837827?af_prt=vivaki.ru',
      appImage: 'https://static.beeline.ru/upload/images/b2c/apps/appstore.svg',
    },
  },
};
BuyTVEUpsale.propTypes = {
  tveShopImage: PropTypes.string,
  tveShopText: PropTypes.string,
  tveShopBtnText: PropTypes.string,
  tveShopHref: PropTypes.string,

  tveMobileText: PropTypes.string,
  mobileApps: PropTypes.shape({
    android: PropTypes.shape({
      appLink: PropTypes.string,
      appImage: PropTypes.string,
    }),
    ios: PropTypes.shape({
      appLink: PropTypes.string,
      appImage: PropTypes.string,
    }),
  }),
};

export default BuyTVEUpsale;
