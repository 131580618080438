import { createSelector } from '@reduxjs/toolkit';

import { selectBillContent } from 'constructorV1/store/selectors/selectCommon';
import { selectUserPhoneNumber } from 'constructorV1/store/selectors/selectTotal';

/** Селектор выборки данных Mnp ЕК */
export const selectMnpProps = createSelector(
  selectBillContent,
  selectUserPhoneNumber,
  (billContent, userPhoneNumber) => {
    return {
      billContent,
      userPhoneNumber,
    };
  },
);
