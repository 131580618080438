import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { TSegmentedControl } from './types';
import style from './styles.pcss';

const cn = classNames.bind(style);

export const SegmentedCoverage = memo(
  ({ coverageTypes, mapController, analyticsEvents }: TSegmentedControl) => {
    const [activeCoverage, setActiveCoverage] = useState(
      () => coverageTypes.find((item) => item.active).alias,
    );

    useEffect(() => {
      const baseActiveCoverage = coverageTypes.find((item) => item.active).alias;
      if (baseActiveCoverage) mapController.addCoverageLayer(baseActiveCoverage);
    }, []);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      analyticsEvents?.onSegmentedControlClick?.(value);
      setActiveCoverage(value);
      coverageTypes.forEach(({ alias }) =>
        alias === value ?
          mapController.addCoverageLayer(alias)
        : mapController.removeCoverageLayer(alias),
      );
    };

    return (
      <ul className={cn('segmented-coverage')}>
        {coverageTypes.map(({ alias, name }, index) => (
          <li className={cn('item')} key={index}>
            <label
              className={cn('label', {
                'label--active': alias === activeCoverage,
              })}
              htmlFor={alias}
            >
              {name.toLowerCase()}
            </label>
            <input
              checked={alias === activeCoverage}
              className={cn('input')}
              id={alias}
              name="coverage"
              onChange={handleChange}
              type="radio"
              value={alias}
            />
          </li>
        ))}
      </ul>
    );
  },
);
