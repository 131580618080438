import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Utils / Mappers
import { unformatPhone } from 'utils/format-string';

import { getFavoriteNumberState } from './mapper';

// Добавление или смена любимого номера
const FAVORITE_NUMBER_ADD_OR_UPDATE_API_URL = '/favoritenumberapi/addorupdate/';
// Удаление любимого номера;
const FAVORITE_NUMBER_REMOVE_API_URL = '/favoritenumberapi/remove';
// Урл по которому делаем пулинг на бэк для определения статуса подключения номера;
const POLLING_URL = '/sebtariff/requeststatus';
// Регулярка для парсинга строки и поиска телефона в троке;
// eslint-disable no-useless-escape
const REG_EXP_TELEPHONE_NUMBER = /[\+\d][\. 0-9-\(\)]{7,16}\d/gim;

class FavoriteNumberWizardModalHoc extends Component {
  state = {
    userNumber: null,
    step: null,
    texts: {
      delete: null,
      delSuccess: null,
      confirm: null,
      success: null,
    },
  };

  count = 0;

  componentDidMount() {
    const { data } = this.props;

    this.setState({
      modalData: { ...getFavoriteNumberState(data) },
      step: data.step,
    });
  }

  // Записываем шаг и вызываем callback для подстановки номера в текст;
  setStep = (step) => {
    this.setState({ step }, this.getBeautyConfirmText);
  };

  /** Записывает введенный номер в state; */
  setUserNumber = (userNumber) => {
    const { step, content } = this.props.data;
    const { changeFreeModal, connectModal, changeModal } = content;

    const messageObj = {
      changeFree: changeFreeModal,
      connect: connectModal,
      change: changeModal,
    };

    // Проверяем номер на валидность;
    const isValidNumber =
      userNumber.length === 16 &&
      !userNumber
        .slice(3, userNumber.length)
        .replace(/\s/g, '')
        .match(/^9\d{9}/);

    this.setState({
      userNumber,
      msg: isValidNumber && messageObj[step].validationText,
    });
  };

  /** Устанавливает новый шаг для открытия окна подтверждения */
  openConfirm = () => this.setStep('confirm');

  /** Устанавливает новый шаг для открытия окна удаления номера */
  openDeleteNumber = () => this.setStep('delete');

  /** Пулинг запроса на бэк для подключения/отключения/смены */
  polling = (requestId, requestType, timeout) => {
    const performanceTimer = performance.now();
    axios(POLLING_URL, {
      method: 'post',
      data: { requestId },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((request) => {
        const {
          data: { isSucceeded, result },
        } = request;

        if (this.count >= timeout) {
          this.setStep('error');
          return;
        }

        if (result === null) {
          const millisec = performance.now() - performanceTimer;
          const seconds = millisec / 1000;

          this.count += seconds;

          this.polling(requestId, requestType, timeout);
        } else {
          let step = null;
          if (isSucceeded && result) {
            step = requestType === 'connect' ? 'success' : 'delSuccess';
            // Обновляем контент
            this.props.getFavoriteNumberData();
          } else {
            step = 'error';
          }

          this.setStep(step);
        }
      })
      .catch((err) => {
        this.setState({ step: 'error' });
        console.error('Ощшибка в post запросе при подключении favoriteNumber: ', err);
      });
  };

  /** Отправыляет запрос на бэк основываясь на типе запроса */
  sendRequest = (type) => {
    this.setState({ step: 'pending' });

    let request = null;
    switch (type) {
      case 'delete':
        request = axios(FAVORITE_NUMBER_REMOVE_API_URL, {
          method: 'post',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
        break;
      case 'connect':
        request = axios(FAVORITE_NUMBER_ADD_OR_UPDATE_API_URL, {
          method: 'post',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
          data: {
            favoriteNumber: unformatPhone(this.state.userNumber),
          },
        });
        break;
    }

    request
      .then(({ data }) => {
        if (data.requestId) {
          this.polling(data.requestId, type, 30);
        } else {
          this.setStep('error');
        }
      })
      .catch((err) => {
        this.setStep('error');
        console.error('Ощшибка в post запросе при подключении favoriteNumber: ', err);
      });
  };

  /** Подключение номера */
  onConnect = () => this.sendRequest('connect');

  /** Удаление номера */
  onDelete = () => this.sendRequest('delete');

  onCloseModal = () => {
    this.setStep(this.props.data.step);
    this.props.closeModal();
  };

  /** Ищем в тексте номер телефона и подменяем его введенным номером в input.
   * Так себе решение, но пока другова не придумал */
  getBeautyConfirmText = () => {
    const { userNumber, modalData, step } = this.state;
    const { favoriteNumber, deleteModal, delSuccessModal, confirmModal, successModal } = modalData;

    // Объект с текстом в которых возможена подмена номера в тексте;
    const texts = {
      delete: deleteModal.contentText,
      delSuccess: delSuccessModal.contentText,
      confirm: confirmModal.contentText,
      success: successModal.contentText,
    };

    // Находим совпадения в тексте
    const regExp = texts[step] && texts[step].match(REG_EXP_TELEPHONE_NUMBER);
    let replaceContentText = null;

    // if { Если совпадения номера в тексте есть меняем его на дугой номер; }
    // else { Если совпадений нет, меняем номер по переменной {{TEXT}} }
    if (regExp && regExp.length) {
      const number =
        regExp[0] === favoriteNumber && step !== 'delete' ? userNumber : favoriteNumber;
      replaceContentText = texts[step].replace(regExp[0], number);
    } else if (userNumber || favoriteNumber) {
      const number = step !== 'delSuccess' ? userNumber : favoriteNumber;
      replaceContentText = texts[step] && texts[step].replace('{{TEXT}}', number);
    }

    this.setState({
      ...this.state,
      texts: {
        ...this.state.texts,
        [step]: replaceContentText,
      },
    });
  };

  render() {
    const { children, opened } = this.props;
    const { step, userNumber, modalData, msg, texts } = this.state;

    const isActiveButton = userNumber && userNumber.length === 16 && !msg;

    return modalData ?
        cloneElement(children, {
          openConfirm: this.openConfirm,
          onChange: this.setUserNumber,
          closeModal: this.onCloseModal,
          onConnect: this.onConnect,
          onDelete: this.onDelete,
          openDeleteNumber: this.openDeleteNumber,
          reConnectModal: {
            ...modalData.reConnectModal,
            disabled: isActiveButton,
            msg,
          },
          changeModal: {
            ...modalData.changeModal,
            disabled: isActiveButton,
            msg,
          },
          errorModal: modalData.errorModal,
          deleteModal: {
            ...modalData.deleteModal,
            contentText: texts.delete,
          },
          notAllowedModal: modalData.notAllowedModal,
          changeFreeModal: {
            ...modalData.changeFreeModal,
            disabled: isActiveButton,
            msg,
          },
          connectModal: {
            ...modalData.connectModal,
            disabled: isActiveButton,
            msg,
          },
          delSuccessModal: {
            ...modalData.delSuccessModal,
            contentText: texts.delSuccess,
          },
          confirmModal: {
            ...modalData.confirmModal,
            contentText: texts.confirm,
          },
          successModal: {
            ...modalData.successModal,
            contentText: texts.success,
          },
          opened,
          step,
        })
      : null;
  }
}

FavoriteNumberWizardModalHoc.propTypes = {
  getFavoriteNumberData: PropTypes.func,
  data: PropTypes.object,
  children: PropTypes.object,
  closeModal: PropTypes.func,
  opened: PropTypes.bool,
  step: PropTypes.string,
};

export default FavoriteNumberWizardModalHoc;
