import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './BlackListParameters.pcss';

const cx = classNames.bind(styles);

const BlackListLastCalls = ({ showRecentCalls }) => (
  <tr className={cx('pointer', 'row')} onClick={showRecentCalls}>
    <td className={cx('label')}>Звонки с запрещенных номеров за сутки</td>
    <td className={cx('phoneList')}>
      <span className={cx('total')}>посмотреть</span>
    </td>
  </tr>
);

BlackListLastCalls.propTypes = {
  showRecentCalls: PropTypes.func.isRequired,
};

export default BlackListLastCalls;
