import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames/bind';
import { Switch } from '@beef/legacy-ui-kit';

import styles from './Option.pcss';

const cx = classNames.bind(styles);

class Option extends Component {
  onClick = (event) => {
    const switchNode = findDOMNode(this.switch);
    if (switchNode.contains(event.target) || event.target === switchNode) {
      return;
    }
    if (!this.props.active) {
      this.props.onClick();
    }
  };

  onBodyClick = (event) => {
    const switchNode = findDOMNode(this.switch);
    if (switchNode.contains(event.target) || event.target === switchNode) {
      return;
    }

    this.props.onBodyClick(this.props.active);
  };

  getStyle() {
    return this.props.bgUrl ?
        { background: `url(${this.props.bgUrl}) center center/cover no-repeat` }
      : {};
  }

  renderDescription() {
    if (!this.props.description) return null;
    return <div className={cx('description')}>{this.props.description}</div>;
  }

  renderSettingsContent() {
    if (!this.props.settingsContent) return null;
    const SettingsContentComponent = this.props.settingsContent;
    return (
      <div className={cx('settings-content')}>
        <SettingsContentComponent />
      </div>
    );
  }

  render() {
    return (
      <div
        className={cx('option', { even: this.props.even, dark: this.props.dark })}
        onClick={this.onBodyClick}
        ref={(e) => {
          this.root = e;
        }}
        role="button"
        style={this.getStyle()}
        tabIndex="0"
      >
        <div className={cx('container')}>
          <div className={cx('switch')}>
            <Switch
              checked={this.props.active}
              isFuture={this.props.inProgress}
              onChange={this.props.onClick}
              ref={(e) => {
                this.switch = e;
              }}
            />
          </div>
          <div className={cx('info')}>
            <div className={cx('title')}>
              <span className={cx('titleText')}>{this.props.title}</span>
            </div>
            {this.renderSettingsContent()}
            {this.renderDescription()}
            {this.props.price ?
              <div className={cx('price', 'price-mobile')}> {this.props.price} </div>
            : null}
          </div>
          {this.props.price ?
            <div className={cx('price', 'price-desktop')}> {this.props.price} </div>
          : null}
          <div className={cx('arrow')} />
        </div>
      </div>
    );
  }
}

Option.propTypes = {
  title: PropTypes.string,
  even: PropTypes.bool,
  description: PropTypes.string,
  active: PropTypes.bool,
  bgUrl: PropTypes.string,
  price: PropTypes.string,
  onClick: PropTypes.func,
  dark: PropTypes.bool,
  onBodyClick: PropTypes.func,
  settingsContent: PropTypes.func,
  inProgress: PropTypes.bool,
};

export default Option;
