import { cloneElement } from 'react';
import { connect } from 'react-redux';

import { BrandsAnalyticsPusher } from 'pages/SearchResultsPage/utils/analytics';
import { getPlaceForAnalytics, getTerm } from 'pages/SearchResultsPage/store/selectors';

const _FilterBlockWithAnalytics = ({ children, location, term, name }) => {
  const handleChange = (e) => {
    children.props.onChange?.(e);
    if (name === 'brands') {
      BrandsAnalyticsPusher.location = location;
      BrandsAnalyticsPusher.pushClickEvent({ [children.props.name]: term });
    }
  };
  return cloneElement(children, { onChange: handleChange });
};

export const FilterBlockWithAnalytics = connect((state) => ({
  location: getPlaceForAnalytics(state),
  term: getTerm(state),
}))(_FilterBlockWithAnalytics);
