import { useEffect, useState } from 'react';

// Actions
import {
  setAvailablePresets,
  setConnectedConvergenceTariff,
  setConvergence,
  setFttbDiscountFromConnectedPreset,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff/actions';
// Utils
import {
  getAvailablePresets,
  getRegionTariffs,
} from 'pages/ProductCard/Tariff/components/ConvergenceLayout/utils';

// Constants
import {
  DEFAULT_REQUEST_VALUE,
  ERROR_REQUEST_CONVERGENCE_TEXT,
  MAX_REQUEST_VALUE,
  REQUEST_COUNTER_STEP,
  defaultPreset,
} from '../constants';

/**
 * useConvergenceCheckConnection: хук проверки наличия подключенных/доступных тарифов.
 */
export const useConvergenceCheckConnection = ({
  _dispatch,
  hasInacId,
  homeInternet,
  activeOptionSoc,
  isOldConvergence,
  seamlessUrlParams,
  convergenceContent,
  hasSeamlessUrlParams,
  isAnimalsTariffLayout,
  currentTariffsGeneration,
}) => {
  const [isCheckedConvergence, setIsCheckedConvergence] = useState(false);
  const [requestCounter, setRequestCounter] = useState(DEFAULT_REQUEST_VALUE);
  const [hasRequestConvergenceError, setHasRequestConvergenceError] = useState(false);
  const hasHomeInternet = !!(homeInternet && Object?.keys(homeInternet)?.length);

  useEffect(() => {
    if (!isAnimalsTariffLayout) return;
    if (!hasHomeInternet) {
      setIsCheckedConvergence(true);
      return;
    }
    const { id } = convergenceContent.currentRegion;
    const { isAuthorized } = convergenceContent.userInfo;

    const tariffsRequestOptions = {
      soc: activeOptionSoc,
      regionId: id,
      generation: currentTariffsGeneration,
    };

    if (!isAuthorized || isAuthorized || (hasSeamlessUrlParams && hasInacId)) {
      if (requestCounter > MAX_REQUEST_VALUE) {
        setIsCheckedConvergence(true);
        setHasRequestConvergenceError(true);
        return;
      }

      getRegionTariffs(tariffsRequestOptions)
        .then(({ data: { tariffs, isSuccess } }) => {
          setRequestCounter(requestCounter + REQUEST_COUNTER_STEP);
          if (!isSuccess) {
            throw new Error(ERROR_REQUEST_CONVERGENCE_TEXT);
          }

          if (hasRequestConvergenceError) {
            setHasRequestConvergenceError(false);
          }

          const connectedTariff = tariffs?.find(
            (tariff) => tariff.isConnected || tariff.inacId === seamlessUrlParams.inacId,
          );

          const presets = tariffs?.length ? getAvailablePresets({ tariffs }) : [defaultPreset];

          if (
            isSuccess &&
            (connectedTariff ||
              isOldConvergence ||
              (hasSeamlessUrlParams && hasInacId) ||
              isAuthorized ||
              !isAuthorized)
          ) {
            _dispatch(setAvailablePresets(presets));
            _dispatch(setConnectedConvergenceTariff(tariffs));
            _dispatch(setConvergence({ tariff: connectedTariff }));

            if (connectedTariff?.discountName) {
              _dispatch(setFttbDiscountFromConnectedPreset(connectedTariff?.discountName));
            }

            setIsCheckedConvergence(true);
          }
        })
        .catch((error) => {
          if (requestCounter) {
            setIsCheckedConvergence(true);
            setHasRequestConvergenceError(true);
            return;
          }

          setHasRequestConvergenceError(true);
          console.error(error);
        });
    } else {
      setIsCheckedConvergence(true);
    }
  }, [hasRequestConvergenceError]);
  return {
    requestCounter,
    setRequestCounter,
    isCheckedConvergence,
    setIsCheckedConvergence,
    hasRequestConvergenceError,
    setHasRequestConvergenceError,
  };
};
