import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { IPortalContextType, IPortalProviderProps } from './types';

const PortalContext = createContext<IPortalContextType>({
  refs: [],
});

export const usePortalContext = () => useContext(PortalContext);

export const PortalProvider: React.FC<IPortalProviderProps> = ({ children, zIndex }) => {
  const [refs, setRefs] = useState<IPortalContextType['refs']>([]);

  const { addRefs: addParentRefs } = usePortalContext();

  const addRefs: IPortalContextType['addRefs'] = (refList) => {
    if (refList.length > 0) {
      const newRefs = [
        // Удаляем мертвые и повторяющиеся рефки
        ...refs.filter((ref) => ref.current),
        ...refList.filter((ref) => {
          if (!ref.current) {
            return false;
          }
          return !refs.find((item) => item.current === ref.current);
        }),
      ];

      setRefs(newRefs);
    }
  };

  useEffect(() => {
    addParentRefs?.(refs);
  }, [refs]);

  useEffect(() => () => setRefs([]), []);

  const value = useMemo(
    () => ({
      addRefs,
      refs,
      zIndex,
    }),
    [refs, zIndex],
  );

  return <PortalContext.Provider value={value}>{children}</PortalContext.Provider>;
};
