/** Расстояние между карточками свайпера */
export const SPACE_BETWEEN = 24;

/** Типы разрешений устройств */
export const DIMENSION_GROUPS = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
};

/** Варианты переменной "view" в "slidesPer" */
export const MIN_SLIDE_VIEW = 1;
export const MAX_SLIDE_VIEW = 2;
export const DEFAULT_SLIDE_VIEW = 1.2;

/** Варианты переменной "group" в "slidesPer" */
export const MIN_SLIDE_GROUP = 1;
export const MAX_SLIDE_GROUP = 2;
