import { EModalType, EResponseCode } from './types';

export { EResponseCode } from './types';

export const MODAL_CODE_MAP: Partial<
  Record<(typeof EResponseCode)[keyof typeof EResponseCode] | EModalType, EModalType>
> = {
  [EResponseCode.Success]: EModalType.Success,
  [EResponseCode.BalanceError]: EModalType.NoBalanceAccountError,
  [EResponseCode.Region]: EModalType.NoUserRegionError,
  [EResponseCode.Restriction]: EModalType.ForbiddenError,
  [EResponseCode.Account]: EModalType.AccountError,
  [EResponseCode.Duplicate]: EModalType.DuplicateAccountError,
  [EResponseCode.Default]: EModalType.DefaultError,
  [EModalType.Preconnect]: EModalType.Preconnect,
  [EModalType.Predisconnect]: EModalType.Predisconnect,
};
