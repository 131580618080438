import { pushYm } from '../../../tempAnalytics/metricUtils';

export type TServiceType = 'service' | 'tv' | undefined;

const serviceTypeAction: { [key: string]: string } = {
  tv: 'tv_element',
  service: 'add_service_element',
};

const serviceTypeName: { [key: string]: string } = {
  tv: 'tv_element_name',
  service: 'add_service_element_name',
};

/* Параметры для метрики при показе модального окна апера */
const modalParams = {
  popup_type: 'service',
};

/** Ф-я подотовки параметров для события просмотра сервисов */
export const getYmPayload = (name: string, type: TServiceType) => {
  if (!(type && type in serviceTypeAction)) return null;
  return {
    [serviceTypeAction[type]]: 'view',
    [serviceTypeName[type]]: name,
  };
};

/** Ф-я отправки параметров для клика по блоку сервисов и tv */
export const sendYm = (name: string, type?: string) => {
  if (!(type && type in serviceTypeAction && type in serviceTypeName)) return;
  pushYm({
    [serviceTypeAction[type]]: 'click',
    [serviceTypeName[type]]: name,
  });
  pushYm({
    ...modalParams,
    popup_action: 'view',
    popup_name: name,
  });
};

const popupActionParams = {
  confirm: 'confirm',
  details: 'detail',
} as const;

type TPopupActionType = keyof typeof popupActionParams;

/* Отправка метрики на клик по кнопке "Понятно" и "Подробнее" в модальом окне сервисов */
export const sendPopupYm = (name: string, actionType: TPopupActionType, type?: string) => {
  if (!(type && type in serviceTypeAction)) return;
  pushYm({
    ...modalParams,
    popup_name: name,
    popup_action: popupActionParams[actionType],
  });
};
