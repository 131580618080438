import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { LayoutGroup, motion } from 'framer-motion';

import { BillIcons } from '../BillIcons';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TOptionsMapper = {
  activeIcons: string[];
  /** Флаг задает максимальный размер блока (используется, например, для блока с максимальным трафиком) */
  isMaximal?: boolean;
  title?: string;
};

/** Варианты анимации маппера иконок в чеке ЕК */
const variantsOptionsMapper = {
  hidden: {
    height: 0,
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
};

/** Блок маппинга иконок выбранных опций */
export const OptionsMapper: FC<TOptionsMapper> = ({ title, isMaximal = false, activeIcons }) =>
  activeIcons?.length ?
    <motion.div
      animate="visible"
      className={cx('wrapper')}
      exit="exit"
      initial="hidden"
      layout
      variants={variantsOptionsMapper}
    >
      <LayoutGroup>
        {title && (
          <motion.div className={cx('wrapper__title')} layout>
            <Text align="left" color="secondary-invert" lineHeight={20} size="xs" weight="regular">
              {title}
            </Text>
          </motion.div>
        )}
        {Array.isArray(activeIcons) && !!activeIcons.length && (
          <motion.div
            className={cx('wrapper__icons', { 'wrapper__icons--maximal': isMaximal })}
            layout
          >
            <BillIcons activeIcons={activeIcons} />
          </motion.div>
        )}
      </LayoutGroup>
    </motion.div>
  : null;
