import { makeRequest } from './makeRequest';

export const connectPartnerSubscription = (id) =>
  makeRequest({
    url: '/api/partner-subscriptions/connect-partner-subscription/',
    method: 'post',
    headers: {
      'content-type': 'application/json',
    },
    params: { id, checkOnly: true },
  });
