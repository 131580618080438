import React, { ReactNode, memo } from 'react';
import classNames from 'classnames/bind';
import { PageFixer } from '@beef/smart-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { Heading } from 'pages/PclLandingPage/components/IklBanner/components/Heading';
import { Banner } from 'pages/PclLandingPage/components/IklBanner/components/Banner';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type IklBannerProps = {
  actions: ReactNode;
  breadcrumbs: ReactNode;
  description: string;
  image: string;
  title: string;
};

export const IklBanner = memo<IklBannerProps>(
  ({ breadcrumbs, image, actions, title, description }: IklBannerProps) => {
    const isPhone = useIsMobileDimension();
    const isTablet = !isPhone;
    const PageFixerComponent = isTablet ? PageFixer : React.Fragment;

    return (
      <div className={cx('wrapper')}>
        <PageFixerComponent backgroundCustomColor="transparent">
          <div className={cx('grid')}>
            <Heading {...{ breadcrumbs, actions, title, description }} />
            <Banner image={image} />
          </div>
        </PageFixerComponent>
      </div>
    );
  },
);
