import axios from 'axios';
import qs from 'query-string';

import { getCookie } from 'utils/cookie';

export const trackCPAEvent = ({ type, orderId, price, soc }) => {
  const uid = getCookie('admitad_uid');
  const url = 'https://ad.admitad.com/r';

  if (uid) {
    const params = {
      uid: true, // (const)
      order_id: orderId,
      position_id: 1, // (const)
      // (с абонентской - 1, без абонента - 2, для платного номера - 1, для бесплатного - 2)
      tariff_code: price > 0 ? 1 : 2,
      action_code: (type === 'Number' && 2) || (type === 'Tariff' && 3), // (2 - покупка номера, 3 - заказ ТП)
      position_count: 1, // (const)
      price: price || 0, // (месячную стоимость АП, либо стоимость номера, иначе -0)
      quantity: 1, // (const)
      product_id: soc, // (alias ТП или alias категории номера)
      postback_key: '3dc36612E7B8fD8c7aC85dEc4C722FD5', // (const)
      campaign_code: 'e28564c442', // (const)
      currency_code: 'RUB', // (const)
      payment_type: 'sale', // (const)
      postback: 1, // (const)
    };

    axios.get(`${url}?${qs.stringify(params)}`);
  }
};

// Оставил для пример - Как логать фронт в kibana
/* export const eSIMLoggingFunction = (message, place, line = 0, col = 0) => {
  if (typeof window?.onerror === 'function') {
    window.onerror(`[eSIM FrontLog][order=${getCookie('esimOrderId')}] ${message}`, `esimorder/${place}`, line, col);
  }
}; */
