import { Faq, FaqPropsInterface } from '@beef/smart-kit';
import classNames from 'classnames/bind';
import React from 'react';

import { PartnerServiceCardMergeState } from '../../store';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const FAQContainer: React.FC<PartnerServiceCardMergeState['faqBlockData']> = ({
  data,
  onClick,
}) => {
  const { title, categories, data: faqData } = data;

  return (
    <div className={cx('container')}>
      <Faq
        categories={categories}
        data={faqData as FaqPropsInterface['data']}
        onClick={(info) => onClick?.(info?.tabId || '')}
        title={title}
      />
    </div>
  );
};
