import { useCallback, useState } from 'react';
import { DropdownOptionInterface } from '@beef/ui-kit';
import { shallowEqual } from '@beef/utils';

import {
  useAppDispatch,
  useAppSelector,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';
import {
  changeStreetInputState,
  setRegion,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/fttbAddressSlice';

import { getRegionTariffs } from '../../../../../../../../api/fmcAddressCheck/getRegionsTariffs';
import { useThrowAsyncError } from '../../../../../../../../hooks/useThrowAsyncError';
import { getCitySearchResults } from '../../../../../../../../api/fmcAddressCheck/getCitySearchResults';

/* Хук подготовки пропов input выбора региона */
export const useRegionInput = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();

  const { loadingTextHelper, errorTextHelper } = useAppSelector(
    (state) => ({
      errorTextHelper: state.common.content?.fmcSettings?.checkAddressPage.errorTextHelper,
      loadingTextHelper: state.common.content?.fmcSettings?.checkAddressPage.loadingTextHelper,
    }),
    shallowEqual,
  );

  /* Сообщение-лоадер */
  const [helpMessage, setHelpMessage] = useState<string>();
  /* Текст ошибки */
  const [errorMessage, setErrorMessage] = useState<string>();

  /* Значение input региона из стора fttbAddress */
  const value = useAppSelector((state) => state.fttbAddress.checkConnectionRes.region);

  const { soc, generation, defaultRegionId } = useAppSelector(
    (state) => ({
      soc: state.total.characterSoc,
      generation: state.total.generation,
      defaultRegionId: state.common.userRegion.regionId,
    }),
    shallowEqual,
  );

  /* Обработка выбора региона из подсказки */
  const onSelect = useCallback(
    (region: DropdownOptionInterface) => {
      const regionId = region.slug;
      dispatch(setRegion({ region: region.label, regionId: Number(regionId) }));
      if (defaultRegionId !== Number(regionId)) {
        setErrorMessage(errorTextHelper);
        setHelpMessage(loadingTextHelper);
        getRegionTariffs({ soc, regionId: regionId || '', generation })
          .then(({ tariffs }) => {
            const hasntAvailableTarrifs = tariffs?.length === 0;
            dispatch(changeStreetInputState(hasntAvailableTarrifs));
            if (hasntAvailableTarrifs) {
              setErrorMessage(errorTextHelper);
            }
          })
          .catch((e) => throwAsyncError(e))
          .finally(() => setHelpMessage(undefined));
      } else {
        dispatch(changeStreetInputState(false));
        setErrorMessage(undefined);
      }
    },
    [soc, generation],
  );

  /* Очистка региона в сторе, если input редактируется пользователем */
  const onChange = useCallback(() => {
    dispatch(setRegion({ region: undefined, regionId: undefined }));
  }, []);

  return {
    value,
    onSelect,
    onChange,
    helpMessage,
    errorMessage,
    onSearch: getCitySearchResults,
  };
};
