import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@beef/legacy-ui-kit';

const LegalTooltip = ({ legal }) => {
  const legalTypeString = typeof legal === 'string';

  return (
    <Tooltip
      icon={legal.type === 'present' && 'present'}
      type={legalTypeString ? 'common' : legal?.type}
    >
      <div dangerouslySetInnerHTML={{ __html: legalTypeString ? legal : legal?.text }} />
    </Tooltip>
  );
};

LegalTooltip.propTypes = {
  legal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string,
    }),
  ]),
};

export default LegalTooltip;
