import { connect } from 'react-redux';

import { getTvChannelsData, isTvChannelsIsLoaded } from 'pages/FTTB/selectors/tvChannels';
import ChannelsPopup from 'pages-components/ChannelsPopup';

const mapStateToProps = (state) => {
  const isLoaded = isTvChannelsIsLoaded(state);
  const tvChannels = getTvChannelsData(state);

  return {
    contentData: state.external.fttbCatalogData.texts.channelsPopupContent,
    channelsData: isLoaded ? tvChannels : undefined,
  };
};

export default connect(mapStateToProps)(ChannelsPopup);
