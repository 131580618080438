import { useEffect, useState } from 'react';
import QRCode from 'qrcode';

/**
 * @desc: Возвращает qr-код для переданной ссылки
 * @author: Andrey Sukhov
 * @param qrCodeUrl { String }
 * @returns { String }
 */
const useCreateQrCodeImg = (qrCodeUrl) => {
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    if (qrCodeUrl) {
      QRCode.toDataURL(qrCodeUrl).then((url) => {
        setQrCode(url);
      });
    }
  }, [qrCodeUrl]);

  return qrCode;
};

export default useCreateQrCodeImg;
