import { createAsyncThunk } from '@reduxjs/toolkit';

import { setTotalOptionsSoc } from '../../totalSlice';
import { toggleOptionActivity } from '../index';

/** Вспомогательный экшен для одновременного изменения слайса опций и слайса total для выборочного
 * добавления в total соков опций, исключающего дочерние соки, при выбранных родительских */
export const changeOptionSoc = createAsyncThunk<string, string>(
  'options/changeOptionSoc',
  (soc, { dispatch, getState }) => {
    dispatch(toggleOptionActivity(soc));
    /* @ts-expect-error ошибка от getState из-за некорректной типизации стора (смотреть комменты у создания стора) */
    const options = getState().options as unknown as TOptionsSlice;
    dispatch(setTotalOptionsSoc(options));
    return soc;
  },
);
