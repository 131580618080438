import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { Fade } from 'pages-components/Animations';
import PopupStateless from 'pages-components/Popup';

import Icon from '../Icon';
import styles from './PopupWithBack.pcss';

const cx = classNames.bind(styles);

const PopupWithBack = ({
  opened,
  onClose,
  showBackButton,
  onBackClick,
  backLinkText,
  children,
  ...props
}) => (
  <PopupStateless onClose={onClose} opened={opened} {...props}>
    <Fade in={showBackButton} mountOnEnter timeout={{ enter: 150, exit: 100 }} unmountOnExit>
      <div className={cx('popupBack')}>
        <Icon name="arrowLeft" />
        &nbsp;&nbsp;
        <Link onClick={onBackClick}>{backLinkText}</Link>
      </div>
    </Fade>
    {children}
  </PopupStateless>
);

PopupWithBack.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool,
  onBackClick: PropTypes.func,
  backLinkText: PropTypes.string,
};

PopupWithBack.defaultProps = {
  opened: false,
  showBackButton: false,
  onBackClick: () => null,
  backLinkText: 'Назад',
};

export default PopupWithBack;
