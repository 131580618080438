import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openAuthModal } from '@beef/layout-kit/utils';

import { getStoriesFinal, postVoteFinal } from '../../api';
import { selectMetaData } from '../selectors';
import { setStatus } from '../status';
import { selectCurrentFinalStoryId } from './selectors';
import { STATUS } from '../../constants';

const initialState = {
  list: [],
  loading: true,
  currentStory: null,
  currentVideo: null,
  voteAvailable: false,
  voteSubmitted: false,
  isLoading: false,
};

export const fetchFinalStories = createAsyncThunk('finalStories/fetchAll', getStoriesFinal);

const sendFinalVote = createAsyncThunk('story/sendFinalVote', async (_, { dispatch, getState }) => {
  const storyId = selectCurrentFinalStoryId(getState());
  try {
    await postVoteFinal(storyId);
    dispatch(setStatus(STATUS.voteSubmitted));
  } catch (err) {
    dispatch(setStatus(STATUS.commonError));
  } finally {
    dispatch(resetCurrentStory());
  }
});

export const submitVote = () => (dispatch, getState) => {
  const { isAuthorized } = selectMetaData(getState());
  if (!isAuthorized) {
    dispatch(resetCurrentStory());
    openAuthModal();
    return;
  }
  dispatch(sendFinalVote());
};

const finalStoriesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    setCurrentStory(state, action) {
      state.currentStory = action.payload;
    },
    resetCurrentStory(state) {
      state.currentStory = null;
    },
    setCurrentVideo(state, action) {
      state.currentVideo = action.payload;
    },
    resetCurrentVideo(state) {
      state.currentVideo = null;
    },
  },
  extraReducers: {
    [fetchFinalStories.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    },
    [sendFinalVote.pending]: (state) => {
      state.isLoading = true;
    },
    [sendFinalVote.fulfilled]: (state) => {
      state.isLoading = false;
      state.voteSubmitted = true;
    },
    [sendFinalVote.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setCurrentStory, resetCurrentStory, setCurrentVideo, resetCurrentVideo } =
  finalStoriesSlice.actions;
export default finalStoriesSlice.reducer;
export * from './selectors';
