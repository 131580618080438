import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import Icon from 'pages-components/Icon';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CollapseButton = ({ text, onClick, rotated }) => (
  <EmptyButton className={cx('dropdownButton')} onClick={onClick}>
    {text}
    <Icon className={cx('dropdownArrow', { rotated })} name="dropdownArrow" />
  </EmptyButton>
);

CollapseButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  rotated: PropTypes.bool,
};

export default CollapseButton;
