import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Preloader } from '@beef/legacy-ui-kit';

import { debounce } from 'utils/timed-functions';
import { isIE } from 'utils/device';

import styles from './BackgroundVideo.pcss';

const cx = classNames.bind(styles);

class Video extends Component {
  state = {
    playing: false,
    rendered: false,
  };

  componentDidMount() {
    this.setState({ rendered: true }, () => {
      if (isIE()) {
        this.v.onplay = () => {
          const calculatedRatio =
            this.v.videoHeight ? this.v.videoWidth / this.v.videoHeight : 2.65;
          this.ratio = this.props.ratio || calculatedRatio;
          this.resizeVideo(this.ratio);

          window.addEventListener('resize', this.resizeVideo);
        };
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeVideo);
  }

  handlePlay = () => {
    this.setState({ playing: true });
  };

  resizeVideo = debounce(() => {
    const { width, height } = this.wrapper.getBoundingClientRect();
    const { ratio } = this;

    if (width / ratio <= height) {
      this.v.style.width = `${height * ratio}px`;
      this.v.style.height = '100%';
    } else {
      this.v.style.height = `${width / ratio}px`;
      this.v.style.width = '100%';
    }
  }, 500);

  render() {
    const { className, src, coverImage, loop, stretch } = this.props;
    const { playing, rendered } = this.state;

    return (
      <div
        className={cx('wrapper')}
        ref={(w) => {
          this.wrapper = w;
        }}
      >
        {!playing ?
          <Preloader className="center" />
        : null}
        {rendered && (
          <video
            autoPlay
            className={cx(className, 'video', { playing, stretch })}
            loop={loop}
            muted
            onCanPlay={this.handlePlay}
            poster={coverImage}
            ref={(v) => {
              this.v = v;
            }}
            src={src}
          />
        )}
      </div>
    );
  }
}

Video.defaultProps = {
  loop: false,
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  loop: PropTypes.bool,
  coverImage: PropTypes.string,
  ratio: PropTypes.number,
  stretch: PropTypes.bool,
};

export default Video;
