import React from 'react';
import classnames from 'classnames/bind';
import { PageRow } from '@beef/smart-kit';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const BannerFixer = ({ children }) => (
  <PageRow blockSpacersPreset="m" tagName="section">
    <div className={cx('fixer')}>{children}</div>
  </PageRow>
);
