import { useContext } from 'react';

// Actions
import { setConvergence } from 'pages/ProductCard/Tariff/containers/ConstructorTariff/actions';
// Constanta
import DispatchContext from 'pages/ProductCard/Tariff/containers/ConstructorTariff/constants';
// Utils
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

/**
 * useCheckedServices: готовит массив tariffs с доступными для указанного адреса тарифами, а также
 * добавляет в объект totalSettings настроенный активный тариф.
 * @param flat
 * @param setCTN
 * @param userCTN
 * @param houseId
 * @param isSettled
 * @param activeTab
 * @param setAddress
 * @param addressText
 * @param resetIsFinish
 * @param currentTariffs
 * @param setActiveTariff
 * @param isAuthenticated
 * @param isFinishSetSetting
 * @param userSelectedNumber
 * @param handleConvergencePopup
 * @returns {{isMobile: boolean, tariffInacId, tariffs}}
 */
export const useCheckedServices = ({
  flat,
  setCTN,
  userCTN,
  houseId,
  isSettled,
  activeTab,
  setAddress,
  addressText,
  resetIsFinish,
  currentTariffs,
  setActiveTariff,
  isAuthenticated,
  isFinishSetSetting,
  userSelectedNumber,
  handleConvergencePopup,
}) => {
  const isMobile = useIsMobileDimension();
  const _dispatch = useContext(DispatchContext);
  const selectTariff =
    currentTariffs?.find(({ inacId }) => inacId === activeTab) || currentTariffs[0];

  const tariffInacId = selectTariff?.inacId;
  const tariffs = selectTariff?.fullOrderGroupServices;
  const userNumber = isAuthenticated && !userSelectedNumber ? userCTN : userSelectedNumber;
  const result = { flat, houseId, userNumber, userAddress: addressText, tariff: selectTariff };

  if (isFinishSetSetting && selectTariff) {
    setCTN(userNumber);
    setAddress(addressText);
    setActiveTariff(selectTariff);
    _dispatch(setConvergence(result));
    resetIsFinish(false);

    if (isSettled) {
      handleConvergencePopup();
    }
  }

  return { tariffs, isMobile, tariffInacId, isAuthenticated };
};
