import React, { FC } from 'react';

import { LoadingServices } from './components/LoadingServices';
import { FmcServiceSettings } from './components/FmcServiceSettings';
import { CommonErrorBanner } from '../../commonComponents/CommonErrorBanner';
import { usePrepareFmcServicesSettings } from './hooks/usePrepareFmcServicesSettings';

/** Контейнер-обертка над блоком настройки ШПД.
 * 1. Если загрузка пресетов в процессе (isLoadingFmcRequest), показываем пользователю скелетон.
 * 2. Если в процессе загрузки пресетов получили ошибку (hasErrorFmcPresets),
 *  отрисовываем компонент повторной отправки запроса.
 * 3. Если загрузка прошла без ошибок, отрисовываем основной компонент ШПД-сервисов (FmcServiceSettings) */
export const FmcServicesSettingsContainer: FC = () => {
  const { handleClick, isErrorFmcServices, isLoadingFmcRequest } = usePrepareFmcServicesSettings();

  if (isLoadingFmcRequest) {
    return <LoadingServices />;
  }

  if (isErrorFmcServices) {
    return <CommonErrorBanner onClick={handleClick} />;
  }

  return <FmcServiceSettings />;
};
