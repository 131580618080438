import React, { memo } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TDeviceContent = {
  description?: string;
  icon?: string;
};

/** Блок с картинкой и текстом рекомендуемых устройств */
export const DeviceContent = memo<TDeviceContent>(({ icon, description }) => (
  <div className={cx('wrapper')}>
    {icon && (
      <div className={cx('wrapper__image')}>
        <img alt="" loading="lazy" src={icon} />
      </div>
    )}
    {description && <Text size="size5-r-s">{description}</Text>}
  </div>
));
