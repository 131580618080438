import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { ServiceContainer } from '../../../../../ServiceTiles/components/ServiceContainer';
import { TitleContent } from '../../../../../../commonComponents/TitleContent';
import { usePrepareTvServices } from '../hooks/usePrepareTvServices';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент TV-сервисов для ШПД */
export const TvServicesContainer: FC = () => {
  const { service, titleContentProps } = usePrepareTvServices();

  return (
    <section className={cx('wrapper')}>
      <TitleContent {...titleContentProps} />
      <ServiceContainer service={service} />
    </section>
  );
};
