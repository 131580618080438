import { useState } from 'react';

/** Вспомогательный хук для триггера ErrorBoundary */
export const useThrowAsyncError = () => {
  const [_, setState] = useState(false);

  return (error: Error) => {
    // Отсекаем ошибки от отмененных запросов
    if (error.message === 'Aborted') {
      return;
    }
    setState(() => {
      throw new Error(error.message);
    });
  };
};
