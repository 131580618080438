import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import styles from './styles.pcss';
import { useInputBlock } from './hooks/useInputBlock';
import { CardForm } from '../../../../components/CardForm';
import { InputForm } from './components/InputForm';

const cx = classNames.bind(styles);

/**
 * Блок с вводом номера для его переноса
 */
export const InputBlock: FC = () => {
  const { title } = useInputBlock();

  return (
    <CardForm wrapperClassName={cx('wrapper')}>
      <Text className={cx('title')} variant="headline-small">
        {title}
      </Text>

      <InputForm />
    </CardForm>
  );
};
