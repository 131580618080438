import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, CollapsedContent, Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';
import { translate } from 'utils/format-string';

import NumberList from '../NumberList/NumberList';
import TextContainer from '../TextContainer/TextContainer';
import styles from './SettingsPopup.pcss';
import commonStyles from '../SharingService.pcss';

const cx = classNames.bind({ ...styles, ...commonStyles });

class SettingsPopup extends Component {
  renderText(path, className) {
    const availableNumbersCount = this.props.maxNumbers - this.props.numbers.length;
    const numbersUnit = translate('number', availableNumbersCount);

    return (
      <TextContainer
        className={className}
        path={path}
        variables={{ availableNumbersCount, numbersUnit }}
      />
    );
  }

  render() {
    const { numbers } = this.props;
    const availableNumbers = this.props.maxNumbers - numbers.length;

    return (
      <PopupStateless onClose={this.props.onClose} opened={this.props.opened}>
        <div className={cx('content')}>
          <Heading level={1}>{this.renderText(['texts', 'settingsTitle'], cx('title'))}</Heading>
          <div>
            {availableNumbers === 0 ? null : (
              this.renderText(['texts', 'settingsDescription'], cx('description'))
            )}
          </div>
          {numbers.length > 0 ?
            <div className={cx('numberList')}>
              <Heading className={cx('settingsHeading')} level={4}>
                {this.renderText(['texts', 'numberListTitle'], cx('numberListTitle'))}
              </Heading>
              <div className={cx('tableWrapper')}>
                <NumberList numbers={numbers} onRemoveNumber={this.props.onDisconnectNumber} />
              </div>
            </div>
          : null}
          <div className={cx('addNumberButton')}>
            <Button className="big" onClick={this.props.onAddNumber} wide>
              {this.renderText(['texts', 'settingButtonTitle'])}
            </Button>
          </div>
          {numbers.length === 1 && numbers[0].status === 'connected' ?
            <div className={cx('links', 'switchLink')}>
              <Link onClick={() => this.props.onSwitchNumber(numbers[0].number, numbers[0].status)}>
                {this.renderText(['texts', 'disconnectWarningLinkText'])}
              </Link>
            </div>
          : null}
          <div className={cx(['links'])}>
            <CollapsedContent
              contentClass={cx(['description', 'collapsed-content'])}
              title={this.props.switchInvitationHelp}
            >
              <TextContainer path={['texts', 'invitationHelp']} />
            </CollapsedContent>
            <CollapsedContent
              contentClass={cx(['description', 'collapsed-content'])}
              title={this.props.switchHowItWorks}
            >
              <TextContainer path={['texts', 'invitationHowItWorks']} />
            </CollapsedContent>
          </div>
        </div>
      </PopupStateless>
    );
  }
}

SettingsPopup.defaultProps = {
  numbers: [],
};

SettingsPopup.propTypes = {
  opened: PropTypes.bool,
  maxNumbers: PropTypes.number,
  onAddNumber: PropTypes.func,
  onClose: PropTypes.func,
  numbers: PropTypes.array,
  onDisconnectNumber: PropTypes.func,
  onSwitchNumber: PropTypes.func,
  switchInvitationHelp: PropTypes.string,
  switchHowItWorks: PropTypes.string,
};

export default SettingsPopup;
