type SelectedParametersType = {
  categories: string;
  filters: Record<string, string>;
  price: string;
};

export const getIsNeedNewReq = (
  availableCategories: Record<string, string>[],
  { categories, filters, price }: SelectedParametersType,
) =>
  [
    availableCategories?.length === 1,
    availableCategories?.[0]?.id !== 'articles',
    !filters,
    !price,
    !categories,
  ].every(Boolean);
