import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Selectors
import {
  getCallBackDescription,
  getCallBackIcon,
  getCallBackTitle,
} from '../../../store/selectors/modal';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CallBackRequestLayout = ({ icon, title, description }) => (
  <div className={cx('wrapper')}>
    {icon && <img alt="icon" className={cx('wrapper__icon')} src={icon} />}
    {title && <Text size="size2-m">{title}</Text>}
    {description && (
      <Text color="grey50" size="size4-r">
        {description}
      </Text>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  icon: getCallBackIcon(state),
  title: getCallBackTitle(state),
  description: getCallBackDescription(state),
});

CallBackRequestLayout.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export const CallBackRequest = connect(mapStateToProps)(CallBackRequestLayout);
