import React, { PureComponent } from 'react';
import { Provider, connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import store from 'store';
import { FTTB_SELECTED_PRODUCT, getAndParseFromLocalStorage } from 'utils/localStorage';
import { getConnectionData } from 'pages/FTTB/selectors/connectionState';
import { connectionDataPropTypes } from 'pages/FTTB/constants';
import PopupRequestHouse from 'pages/AllInOneForm/components/PopupRequestHouse/PopupRequestHouse';
import OrderTariffForm from 'pages/FTTB/components/OrderTariffForm';
import BackCallPopup from 'pages/FTTB/components/BackCallPopup';

import { mapSelectedProductToPromo } from '../utils/mapToPromoTariff';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

class RequestPage extends PureComponent {
  state = {
    tariffData: {
      selectedProduct: null,
      promoTariff: null,
    },
    connectHousePopupIsOpen: false,
    backCallPopupIsOpen: false,
  };

  componentDidMount() {
    this.setTarfffData();
  }

  setTarfffData = () => {
    const selectedProduct = getAndParseFromLocalStorage(FTTB_SELECTED_PRODUCT);

    if (!selectedProduct) return;

    const { requestUrl } = this.props.data;

    const promoTariff = mapSelectedProductToPromo(selectedProduct, requestUrl);

    this.setState({
      tariffData: { selectedProduct, promoTariff },
    });
  };

  showConnectHousePopup = () => this.setState({ connectHousePopupIsOpen: true });

  closeConnectHousePopup = () => this.setState({ connectHousePopupIsOpen: false });

  showBackCallPopup = () => this.setState({ backCallPopupIsOpen: true });

  closeBackCallPopup = () => this.setState({ backCallPopupIsOpen: false });

  render() {
    const { data, connectionData, urlCreateOrder, callBackRequestData, availableScheduleData } =
      this.props;
    const { tariffData, connectHousePopupIsOpen, backCallPopupIsOpen } = this.state;

    const { street, house } = connectionData || {};

    return (
      <div className={cx('wrapper')}>
        <div className={cx('formWrapper')}>
          <OrderTariffForm
            availableScheduleData={availableScheduleData}
            buyTVEData={data.buyTVEData}
            connectingRequestUrl={urlCreateOrder}
            data={data}
            onConnectHouse={this.showConnectHousePopup}
            onOpenBackCallPopup={this.showBackCallPopup}
            promoTariff={tariffData.promoTariff}
            tariffRequestData={tariffData.selectedProduct}
          />
        </div>

        {connectHousePopupIsOpen && (
          <PopupRequestHouse
            house={house}
            onClose={this.closeConnectHousePopup}
            popupIsOpen
            street={street}
          />
        )}

        {backCallPopupIsOpen && (
          <BackCallPopup
            data={callBackRequestData}
            isOpen
            onClose={this.closeBackCallPopup}
            showCaptcha={callBackRequestData.showCaptcha}
          />
        )}
      </div>
    );
  }
}

RequestPage.propTypes = {
  data: PropTypes.object,
  callBackRequestData: PropTypes.shape({
    showCaptcha: PropTypes.bool,
    texts: PropTypes.object,
  }),
  urlCreateOrder: PropTypes.string,
  requestUrl: PropTypes.string,
  availableScheduleData: PropTypes.object,
  connectionData: PropTypes.shape(connectionDataPropTypes),
};

const mapStateToProps = (state) => ({
  connectionData: getConnectionData(state),
});

const ConnectedRequestPage = connect(mapStateToProps)(RequestPage);

export default (props) => (
  <Provider store={store}>
    <ConnectedRequestPage {...props} />
  </Provider>
);
