import { useCallback, useState } from 'react';

import { IInfo } from '../types';
import { decorateHandler } from '../metric';

export type IUsePrepareSlides = {
  slides: IInfo[];
};

export const useModal = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [scrollPosition, setScollPosition] = useState(0);

  const [isHideModal] = useState(false);
  const handleClickModal = () => {
    setIsOpenModal(true);
    setScollPosition(window?.scrollY || 0);
  };

  const handleCloseModal = decorateHandler(
    useCallback(() => {
      if (typeof window !== 'undefined') {
        window?.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'instant',
        });
      }
      setIsOpenModal(false);
    }, [isOpenModal, setIsOpenModal, scrollPosition]),
    {
      mobileApp2024Metric: ['handleModalClose'],
    },
  );

  return {
    isHideModal,
    isOpenModal,
    handleClickModal,
    handleCloseModal,
  };
};
