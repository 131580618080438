import React from 'react';
import PropTypes from 'prop-types';
import { PageFixer } from '@beef/smart-kit';

export const SectionWrapper = ({ children, className }) => (
  <PageFixer size="m" space="24">
    <div className={className}>{children}</div>
  </PageFixer>
);

SectionWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
