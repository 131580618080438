import { AxiosRequestConfig, AxiosResponse } from 'axios';

import globalStore from 'store';

export type TBasketQueryRes = Record<string, any>;
type TBody = {
  items: Record<string, any>[];
  regionPath: any;
};

/** Запрос на корзину ConstructorV1 */
export const fetchBasket = async (
  apiEndpoint: string,
  body: TBody,
  config: AxiosRequestConfig,
): Promise<AxiosResponse<TBasketQueryRes>> => {
  const { cartApi } = globalStore.getState().external.cartData;

  return cartApi.post(apiEndpoint, body, config);
};
