/* eslint-disable no-case-declarations */
import { CHANGE_SUBSCRIPTION_STATUS, SET_CHOSEN_TAB, SET_PRODUCT_DATA } from './constants';
import { changeSubscriptionStatus } from '../../utils';

export const initialState = {
  isVisiblePanel: false,
  popupContent: [],
  howToPayRedirectUrl: '#',
  subscriptions: [],
  tabsContent: [],
  chosenTab: null,
  rateText: 'Абонентская плата',
  detailCardBtnText: 'Подробнее об услуге',
  partnerSubscriptionsTitle: 'Подписки',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_DATA:
      const { productList, tabs, popupContent, howToPayRedirectUrl, content = {} } = action.payload;
      const { defaultTabLabel, ...otherContent } = content;
      return {
        ...state,
        subscriptions: productList,
        chosenTab: defaultTabLabel,
        tabsContent: tabs,
        isVisiblePanel: !!productList?.length,
        howToPayRedirectUrl,
        popupContent,
        defaultTabLabel,
        ...otherContent,
      };
    case SET_CHOSEN_TAB:
      return { ...state, chosenTab: action.payload };
    case CHANGE_SUBSCRIPTION_STATUS:
      const changedSubscriptions = changeSubscriptionStatus(state.subscriptions, action.payload);
      return { ...state, subscriptions: changedSubscriptions };
    default:
      return state;
  }
};
