import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'constructorV1/store';
import { setOptionsSocs } from 'constructorV1/store/totalSlice';
import { setToggleSections } from 'constructorV1/store/tariffSettingsSlice';
import { selectTotalOptionsSocs } from 'constructorV1/store/selectors/selectTotal';
import { selectOptionsSections } from 'constructorV1/store/selectors/selectTariffSettings';

/** Вспомогательный экшен для одновременного изменения options, total и выборочного
 * добавления в total соков опций.
 * Вкл/выкл (toggle) опции и изменение массива активных опций optionsSocs */
export const toggleOptions = createAsyncThunk<
  Promise<void>,
  string,
  {
    dispatch: Dispatch;
    state: RootState;
  }
>('options/toggleOptions', async (serviceCategory, { dispatch, getState }) => {
  const sections = selectOptionsSections(getState());
  const optionsSocs = selectTotalOptionsSocs(getState());

  /* Формируем массив активных (включенных) соков опций.
   * Если текущий массив optionsSocs включает в себя проверяемую опцию (serviceCategory),
   * убираем ее из массива для последующего отключения. Если сока нет, добавляем его в массив для включения */
  const newOptionsSocs =
    optionsSocs.includes(serviceCategory) ?
      optionsSocs.filter((soc) => soc !== serviceCategory)
    : [...optionsSocs, serviceCategory];

  /* Добавляем в store обновленный массив optionsSocs */
  dispatch(setOptionsSocs(newOptionsSocs));

  /* Формируем новый объект sections, на основе включенных опций массива (optionsSocs) */
  const newSections = sections?.map((section) => ({
    ...section,
    options: section.options?.map((option) => {
      return {
        ...option,
        isTurnedOn:
          option.serviceCategory === serviceCategory ? !option.isTurnedOn : option.isTurnedOn,
      };
    }),
  }));

  /* Добавляем в store обновленный объект sections */
  dispatch(setToggleSections(newSections));
});
