import React, { FC, MutableRefObject, useEffect, useRef } from 'react';
import { AppBanner } from '@beef/smart-kit';

import { IAppBanner } from 'pages/Services/VoWifiServiceCardLanding/types';
import { getStoreName } from 'pages/Services/VoWifiServiceCardLanding/utils';
import {
  voWifiGoToAppYM,
  voWifiGoToStoreYM,
} from 'pages/Services/VoWifiServiceCardLanding/analytics';

export const AppBannerContainer: FC<{ data: IAppBanner }> = ({ data }) => {
  const bannerRef: MutableRefObject<any> = useRef();

  useEffect(() => {
    // ym
    const clickListener = (event: React.MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLElement;

      if (target.tagName === 'A') {
        event.preventDefault();
        const href = target.getAttribute('href');

        voWifiGoToAppYM(href);
      }
    };

    if (bannerRef.current) {
      bannerRef.current.addEventListener('click', clickListener);
      return () => {
        if (bannerRef.current) {
          bannerRef.current.removeEventListener('click', clickListener);
        }
      };
    }
  }, []);

  const handleOnClick = (link: string) => {
    voWifiGoToStoreYM(getStoreName(link));
  };

  return (
    <div ref={bannerRef}>
      <AppBanner {...data} onClick={handleOnClick} />
    </div>
  );
};
