import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Tab } from './components/Tab';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const SliderTabs = memo(({ slide, activeTabPeriod, setActiveTabPeriod }) => (
  <div className={cx('wrapper')}>
    {slide?.tabs?.map((tab, idx) => (
      <Tab
        id={idx}
        key={tab.text}
        onClick={() => setActiveTabPeriod(idx)}
        selected={activeTabPeriod}
        title={tab.text}
      />
    ))}
  </div>
));

SliderTabs.propTypes = {
  slide: PropTypes.object,
  activeTabPeriod: PropTypes.number,
  setActiveTabPeriod: PropTypes.func,
};
