import React from 'react';
import { Provider } from 'react-redux';

import store from 'store';

export default function withStoreProvider(WrappedComp) {
  return (props) => (
    <Provider store={store}>
      <WrappedComp {...props} />
    </Provider>
  );
}
