import { shallowEqual } from '@beef/utils';

import { throwAsyncError } from 'utils/errors';

import { useSelectLoadingFmcPresets } from '../selectors/useSelectLoadingFmcPresets';
import { useSelectErrorFmcPresets } from '../selectors/useSelectErrorFmcPresets';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { fetchFmcServices } from '../../../../api/fmcServices';

/** Хук подготовки данных для компонента FmcServicesSettingsContainer */
export const usePrepareFmcServicesSettings = () => {
  const dispatch = useAppDispatch();
  /* Получаем статус загрузки сервисов ШПД.
  Под капотом используется общий флаг для запросов пресетов и сервисов - isLoadingFmcRequest(стор)) */
  const isLoadingFmcRequest = useSelectLoadingFmcPresets();

  /* Доступность высокоскоростных пресетов ШПД */
  const highSpeedAvailable = useAppSelector(
    (state) => state.total.convergenceInfo?.highSpeedAvailable,
  ) as number;

  /* Получаем статус ошибки загрузки сервисов ШПД на странице settingsFttbAndTvPage.
  Под капотом используется общий флаг ошибки пресетов и сервисов - isErrorFmcRequest(стор)) */
  const isErrorFmcServices = useSelectErrorFmcPresets();

  /* Получаем текущие данные по тарифу */
  const {
    regionId,
    isConnected,
    generation,
    characterSoc,
    priceCategory = '',
    tariffSoc,
  } = useAppSelector(
    (state) => ({
      regionId: state.common?.userRegion?.regionId,
      characterSoc: state.total.characterSoc,
      generation: state.total.generation,
      priceCategory: state.total.priceCategory,
      isConnected: state.common.userInfo?.isConnected,
      tariffSoc: state.total.tariffSoc,
    }),
    shallowEqual,
  );

  /** Для единого конструктора на шпд берется сок тарифа тк нет сока персонажа */
  const soc = characterSoc || tariffSoc;

  /** Обработчик повторного запроса ШПД-сервисов */
  const handleClick = () => {
    /* Отправляем запрос для получения ШПД-сервисов */
    dispatch(
      fetchFmcServices({
        regionId,
        generation,
        isConnected,
        soc,
        priceCategory,
        highSpeedAvailable,
      }),
    ).catch((e: Error) => throwAsyncError(e));
  };

  return {
    handleClick,
    isErrorFmcServices,
    isLoadingFmcRequest,
  };
};
