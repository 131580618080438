import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { translate } from 'utils/format-string';

import commonStyles from '../legacy-styles.pcss';
import styles from './BlackListParameters.pcss';

const cx = classNames.bind(styles);

const BlackListedNumbers = ({ phoneList, openBlackList }) => {
  const total = phoneList.length;
  return (
    <tr className={cx('phoneRow')}>
      <td className={cx('title')}>
        <div className={cx('label')}>Черный список</div>
      </td>
      <td className={cx('phoneList', 'pointer')} onClick={openBlackList}>
        <span className={cx('total')}>
          {total === 0 ? 'Нет номеров' : `${total} ${translate('number', total)}`}
        </span>
        {total !== 0 ?
          phoneList.reduce((result, value, index) => {
            if (index < 2) {
              result.push(
                <div className={cx('phoneCell')} key={value}>
                  {value}
                </div>,
              );
            } else if (index === 2) {
              const remainingNumbers = phoneList.length - 2;
              result.push(
                <div className={cx('phoneCell', 'showDetails')} key={remainingNumbers}>
                  и еще {remainingNumbers} {translate('number', remainingNumbers)}
                </div>,
              );
            }
            return result;
          }, [])
        : null}
      </td>
    </tr>
  );
};

BlackListedNumbers.propTypes = {
  phoneList: PropTypes.array,
  openBlackList: PropTypes.func,
};

export default BlackListedNumbers;
