import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { InputBlock } from '../MNPForm/components/InputBlock';

const cx = classNames.bind(styles);

/**
 * Блок с "липким" позиционированием
 * инпута переноса номера
 */
export const MobileStickyForm: FC = memo(() => {
  return (
    <div className={cx('wrapper')}>
      <InputBlock />
    </div>
  );
});
