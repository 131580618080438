import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { StatefulSearchInput } from '@beef/ui-kit';
import { PageRow, TeaserBanner } from '@beef/smart-kit';

import { getOptionHref, getSearchResults } from './utils';
import styles from './styles.module.pcss';

const cx = classnames.bind(styles);

const INPUT_NAME = 'help-search';

export const HeaderBlock = ({ content: { banner = {}, search = {} } }) => {
  const [query, setQuery] = useState('');

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (query) {
      window.location.href = getOptionHref(query);
    }
  };

  return (
    <PageRow blockSpacersPreset="m">
      <div className={cx('header')}>
        <TeaserBanner {...banner}>
          <form className={cx('form')} onSubmit={handleSearchSubmit}>
            <StatefulSearchInput
              className={cx('input-wrapper')}
              name={INPUT_NAME}
              onChange={setQuery}
              onSearch={getSearchResults}
              placeholder={search.placeholder}
            />
          </form>
        </TeaserBanner>
      </div>
    </PageRow>
  );
};
