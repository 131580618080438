import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CaptchaInput extends PureComponent {
  constructor(props) {
    super(props);

    const secsPast = Math.floor((+new Date() - +new Date(props.lastSMS)) / 1000);
    const timer = props.timeout - secsPast;

    this.state = {
      timer: timer > 0 ? timer : null,
      intervalId: null,
    };
  }

  componentDidMount() {
    if (this.state.timer) {
      const intervalId = setInterval(() => {
        if (this.state.timer <= 1) {
          clearInterval(this.state.intervalId);
          this.props.onTimeOut();
        } else {
          this.setState({ timer: this.state.timer - 1 });
        }
      }, 1000);

      this.setState({ intervalId });
    } else {
      this.props.onTimeOut();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    return (
      <div className={this.props.className}>
        {`Вы сможете отправить SMS через ${this.state.timer} секунд`}
      </div>
    );
  }
}

CaptchaInput.propTypes = {
  timeout: PropTypes.number,
  lastSMS: PropTypes.number,
  onTimeOut: PropTypes.func,
};

export default CaptchaInput;
