import { TPartnerProductInfoResponse } from '../types';
import { createObservableState } from './utils';

type TTariffUpMetaData = {
  IsAuthenticated: boolean;
  Products: Partial<TPartnerProductInfoResponse>;
  Soc: string;
};

/**
 * Объект с дополнительным состоянием, необходимым для работы супер способностей
 * в тарифе UP
 */
export const TariffUpMetaData = createObservableState<TTariffUpMetaData>({
  IsAuthenticated: false,
  Soc: '',
  Products: {},
});
