import { useAppSelector } from '../../../../store';

/** Селектор выборки контента для баннера под чеком */
export const useSelectFamily = () => {
  const content = useAppSelector((state) => state.common?.content);
  const { familyBanner } = content ?? {};
  const { bgColor, description, image, title } = familyBanner ?? {};

  const isShowBanner = Boolean(title && description);
  const contentProps = { description, title };

  return { bgColor, contentProps, image, isShowBanner };
};
