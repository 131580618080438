import { EYandexPopupType, TPopupType } from '../types';
import { TTariffUpPowers, TariffUpPowers } from './TariffUpPowers';
import { createObservableState } from './utils';

export type TTariffUpModals = {
  IsLoading: boolean;
  IsOpen: boolean;
  YandexPopupType: TPopupType;
};

/**
 * Объект с состоянием, нужным для модалок суперспособности с Яндексом
 */
export const TariffUpModals = createObservableState<TTariffUpModals, TTariffUpPowers>(
  {
    YandexPopupType: EYandexPopupType.Error,
    IsLoading: false,
    IsOpen: false,
  },
  TariffUpPowers.ObservableState,
);
