import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';
import BackCallPopup from 'pages/FTTB/components/BackCallPopup';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { tariffClickYM } from 'utils/analytics/tariffsYandexMetrica';

import { useStopBlockPosition } from './hooks/useStopBlockPosition';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const positionStates = {
  none: 'none',
  onStopBlock: 'onStopBlock',
};

export const FloatingPhoneWidget = memo(
  ({ data, isFMC, isFttbTv, redesign = true, ...positionData }) => {
    const isMobile = useIsMobileDimension();
    const { anchor, currentPosition } = useStopBlockPosition(positionData);

    const [showPopup, setShowPopup] = useState(false);

    const handleClick = () => {
      tariffClickYM();
      setShowPopup(!showPopup);
    };

    return (
      <>
        <button
          className={cx('phone-widget', {
            'phone-widget--stop': currentPosition === positionStates.onStopBlock,
          })}
          onClick={handleClick}
          style={anchor || null}
          type="button"
        >
          <Icon
            className={cx({
              'phone-widget--mobile': isMobile,
            })}
            name="FTTBHandset"
          />
        </button>
        {showPopup && (
          <BackCallPopup
            data={data}
            isFMC={isFMC}
            isFttbTv={isFttbTv}
            isOpen={showPopup}
            onClose={setShowPopup}
            redesign={redesign}
            showCaptcha={data.showCaptcha}
          />
        )}
      </>
    );
  },
);

FloatingPhoneWidget.propTypes = {
  data: PropTypes.shape({
    showCaptcha: PropTypes.bool,
  }),
  stopBlockId: PropTypes.string,
  stopPosition: PropTypes.number,
  customCorrectionPosition: PropTypes.number,
  redesign: PropTypes.bool,
  isFttbTv: PropTypes.bool,
  isFMC: PropTypes.bool,
};
