import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import Discount from 'pages-components/Discount';

import styles from './index.pcss';

const cx = classNames.bind(styles);

const PriceWrapper = ({ price, oldPrice, discountValue, discountText }) => (
  <div className={cx('price')}>
    <Text size="size5-r-l">{price === 0 || Number.isNaN(price) ? 'Бесплатно' : `${price} ₽`}</Text>
    {oldPrice && (
      <div>
        <Text crossOutColor="scarlet" isCrossedOut size="size5-r-l">
          {oldPrice}
          {!!discountValue && discountText && (
            <Discount.Label animated className={cx('discountLabel')} label={discountText} />
          )}
        </Text>
      </div>
    )}
  </div>
);

PriceWrapper.propTypes = {
  price: PropTypes.number,
  oldPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  discountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  discountText: PropTypes.string,
};

export default PriceWrapper;
