import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/* isZIndent задает отступы контейнеру для Z */

const MainContent = ({ children, isZIndent }) => (
  <div className={cx('main-content', { 'main-content--z': isZIndent })}>{children}</div>
);

MainContent.propTypes = {
  children: PropTypes.node,
  isZIndent: PropTypes.bool,
};

export { MainContent };
