import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

type TPresetPickerUnit = { unit: string };

/** Компонент для отображения единицы измерения */
export const PresetPickerUnit: FC<TPresetPickerUnit> = ({ unit }) => (
  <div className={cx('component')}>
    <Text color="white" size="size5-r-s">
      {unit.toLowerCase()}
    </Text>
  </div>
);
