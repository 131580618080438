import { withDotSeparatedProps } from '@beef/landing-constructor/library-utils';
import PropTypes from 'prop-types';

import {
  CustomCard as _CustomCard,
  CustomCardsWrapper as _CustomCardsWrapper,
} from './components/CardsLayout';

export const CustomCardsWrapper = withDotSeparatedProps(_CustomCardsWrapper, {
  type: PropTypes.oneOf(['imageCard', 'infoCard']),
  title: PropTypes.string,
  description: PropTypes.string,
  'link.text': PropTypes.string,
  'link.href': PropTypes.string,
  'link.withIcon': PropTypes.bool,
  'link.withMobileText': PropTypes.string,
  size: PropTypes.oneOf(['l', 'm', 's']),
  children: PropTypes.node,
  className: PropTypes.string,
});

export const CustomCard = withDotSeparatedProps(_CustomCard, {
  title: PropTypes.string,
  text: PropTypes.string,
  bordered: PropTypes.bool,
  'img.src': PropTypes.string,
  'img.title': PropTypes.string,
  'button.text': PropTypes.string,
  'button.link': PropTypes.string,
  'button.variant': PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
});
