import React from 'react';
import PropTypes from 'prop-types';

// Styles
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Statuses = ({ modifier, text }) => <div className={cx('status', modifier)}>{text}</div>;

Statuses.propTypes = {
  modifier: PropTypes.string,
  text: PropTypes.string,
};

export default Statuses;
