import { BreadCrumbItemType } from '@beef/ui-kit';
import { TeaserBannerPropsType } from '@beef/smart-kit';

type DataConverterType = {
  action: {
    actionBanner: TeaserBannerPropsType;
    backgroundColor: string;
    image: string;
    isArchive: boolean;
    preview: string;
    text: string;
    title: string;
  };
  breadcrumbs: BreadCrumbItemType[];
};
export const dataConverter = ({ action, breadcrumbs }: DataConverterType) => ({
  content: {
    title: action.title,
    actionBanner: {
      ...action.actionBanner,
    },
    isArchive: action.isArchive,
    breadcrumbs,
    text: action.text,
  },
});
