import { createSelector } from '@reduxjs/toolkit';

export const selectModal = (state) => state.modal;
export const selectContent = (state) => selectModal(state)?.content;
export const selectModalType = (state) => selectModal(state)?.modalType || '';
export const selectIsModalOpen = (state) => !!selectModal(state)?.isModalOpen;
export const selectModalContent = createSelector(
  selectModalType,
  selectContent,
  (type, content) => content[type],
);
