import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { monthGenitive, templateParser } from 'utils/format-string';

import BasicPopup from './Basic';
import Accumulators from '../Accumulators';

export default class ConnectedPopup extends PureComponent {
  getDescription = () => {
    const date = new Date(this.props.resetDate);
    return templateParser(this.props.texts.restEnoughDescription, {
      date: `${date.getDate()} ${monthGenitive[date.getMonth()]}`,
    });
  };

  render() {
    const { className, texts, onClick } = this.props;

    return (
      <BasicPopup
        buttonText={texts.restEnoughButton}
        className={className}
        description={this.getDescription()}
        heading={texts.restEnoughHeader}
        onClick={onClick}
      >
        <div style={{ opacity: '.5' }}>
          <Accumulators inline />
        </div>
      </BasicPopup>
    );
  }
}

ConnectedPopup.propTypes = {
  texts: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  resetDate: PropTypes.func.isRequired,
};

ConnectedPopup.defaultProps = {
  texts: {},
};
