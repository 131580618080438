import axios from 'axios';

import { ALLINONE_GET_REGION } from '../../../constants/Endpoints';
import { mapStoriesFinalResponse, mapStoriesResponse } from '../mappers/stories';
import { preprocessPhone } from '../utils';
import { pushAnniversaryEvent } from '../utils/analytics';

export const getStoriesRequest = async () => {
  const { data } = await axios('/anniversary/storycontest/');
  if (!data.isSucceeded) {
    throw new Error('storyContest error');
  }
  return mapStoriesResponse(data);
};

export const getStoryById = async ({ id }) => {
  const { data } = await axios('/anniversary/story/', { params: { id } });
  if (!data.isSucceeded) {
    throw new Error('storyById error');
  }
  return data.result;
};

export const postVote = async (id) => {
  const { data } = await axios.post('/anniversary/vote/', { id });
  if (!data.isSucceeded) {
    throw new Error('vote error');
  }
};

export const postBeepConnect = async () => {
  const { data } = await axios.post('/anniversary/beep-connect/');
  if (!data.isSucceeded) {
    throw new Error('beep connect error');
  }
};

export const getSmsCode = async (phone, bisUrl) => {
  const phoneNumber = preprocessPhone(phone);
  const { data } = await axios.post(`${bisUrl}/SendConfirmationCode`, {
    ctn: phoneNumber,
  });
  if (data.errorReason > 0) {
    const errorMessage = "Couldn't send sms code";
    pushAnniversaryEvent({
      error: {
        code: data.errorReason,
        text: `${errorMessage}; ${data.message}`,
      },
    });
    throw new Error(errorMessage);
  }
};

export const checkSmsCode = async (code, phone, bisUrl) => {
  const phoneNumber = preprocessPhone(phone);
  const { data } = await axios.post(`${bisUrl}/CheckConfirmationCode`, {
    ctn: phoneNumber,
    code,
  });
  return data;
};
export const postTimeCapsule = async (smsText) => {
  const { data } = await axios.post('/anniversary/capsuleTime', {
    smsText,
    dateTimeAdd: new Date().toISOString(),
  });
  if (!data.isSucceeded) {
    const errorMessage = "Couldn't post time capsule";
    pushAnniversaryEvent({
      error: {
        code: data.errorReason,
        text: `${errorMessage}; ${data.message}`,
      },
    });
    throw new Error(errorMessage);
  }
};

export const postAnketa = async (titleName, firstName, secondName, city, userStory, phone) => {
  const contactPhone = preprocessPhone(phone);
  const { data } = await axios.post('/anniversary/storyContest', {
    titleName,
    firstName,
    secondName,
    city,
    userStory,
    contactPhone,
  });
  if (!data.isSucceeded) {
    const errorMessage = "Couldn't post anketa";
    pushAnniversaryEvent({
      error: {
        code: data.errorReason,
        text: `${errorMessage}; ${data.message}`,
      },
    });
    throw new Error(errorMessage);
  }
};

export const getCitySearchResults = async (query) => {
  let options = [];
  try {
    const { data } = await axios({
      url: ALLINONE_GET_REGION,
      params: {
        boldFont: false,
        startsWith: query,
        'ui-culture': 'ru-ru',
      },
    });
    options = data;
  } catch (err) {
    return options;
  }

  return options.View.map((option) => ({
    label: option.Title?.replaceAll(/<[^>]*>/g, '') || '',
    slug: `${option.MarketingRegionId || ''}`,
  }));
};

export const getStoriesFinal = async () => {
  const { data } = await axios('/anniversary/storyContestFinal');
  if (!data.isSucceeded) {
    throw new Error('storyContestFinal error');
  }
  return mapStoriesFinalResponse(data);
};

export const postVoteFinal = async (id) => {
  const { data } = await axios.post('/anniversary/voteFinal/', { id });
  if (!data.isSucceeded) {
    throw new Error('voteFinal error');
  }
};
