import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PROP_TYPES = {
  unit: PropTypes.string,
  value: PropTypes.string,
  widgetType: PropTypes.string,
  iconName: PropTypes.string,
  icon: PropTypes.string,
  parameterType: PropTypes.string,
  extraParams: PropTypes.object,
  cardStyle: PropTypes.string,
};

const WithImage = ({ icon, iconName, unit, widgetType, cardStyle }) => (
  <div className={cx('withImage', widgetType, cardStyle)}>
    {icon && <Picture alt={iconName} className={cx('icon', iconName)} src={icon} />}
    {unit && <span className={cx('unit')} dangerouslySetInnerHTML={{ __html: unit }} />}
  </div>
);

const Normal = ({ value, unit, widgetType, cardStyle }) => (
  <div className={cx(widgetType, cardStyle)}>
    {value && <span className={cx('value')} dangerouslySetInnerHTML={{ __html: value }} />}
    {unit && <span className={cx('unit')} dangerouslySetInnerHTML={{ __html: unit }} />}
  </div>
);

const SimpleParameter = ({
  parameterType,
  value,
  unit,
  widgetType,
  iconName,
  extraParams,
  icon,
  cardStyle,
}) => (
  <div className={cx('container', parameterType)}>
    {widgetType === 'normal' && (
      <>
        {icon ?
          <WithImage cardStyle={cardStyle} icon={icon} iconName={iconName} unit={unit} />
        : <Normal cardStyle={cardStyle} unit={unit} value={value} widgetType={widgetType} />}
      </>
    )}
    {extraParams && (
      <div className={cx('extraPramsContainer')}>
        <Normal unit={unit} value={value} widgetType={widgetType} />
        <div className={cx('extraParams')}>
          <div className={cx('extraIcon')}>
            <Picture alt={extraParams.iconName} src={extraParams.icon} />
          </div>
          <div
            className={cx('iconUnitText')}
            dangerouslySetInnerHTML={{ __html: extraParams.iconUnit }}
          />
        </div>
      </div>
    )}
  </div>
);

WithImage.propTypes = {
  unit: PropTypes.string,
  widgetType: PropTypes.string,
  iconName: PropTypes.string,
  icon: PropTypes.string,
  cardStyle: PropTypes.string,
};

Normal.propTypes = {
  unit: PropTypes.string,
  value: PropTypes.string,
  widgetType: PropTypes.string,
  cardStyle: PropTypes.string,
};

SimpleParameter.propTypes = PROP_TYPES;

export default SimpleParameter;
