export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const callOnce = (cb) => {
  let shouldCall = true;
  return (...args) => {
    if (shouldCall) {
      cb(...args);
      shouldCall = false;
    }
  };
};
