import React from 'react';
import { Button, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import { Provider, connect } from 'react-redux';
import { useOpenSearch } from '@carnica/smart-kit';

import { getQuerySelector } from 'pages/SearchResultsPage/store/selectors/search';
import store from 'store';
import { templateParser } from 'utils/format-string';
import { INFO_TEXT_NOT_FOUND } from 'pages/SearchResultsPage/utils/constsFilters';
import { prepareQuerySearch } from 'pages/SearchResultsPage/utils/helperFilters';

import styles from './styles.pcss';
import NotFoundIcon from './not-found.svg';

const cx = classNames.bind(styles);

export const _NotFound = ({ text, nodeRef, withFilters, resetFilter, query }) => {
  const preparedQuerySearch = prepareQuerySearch(query);
  const title = templateParser(INFO_TEXT_NOT_FOUND, { query: preparedQuerySearch });
  const onOpenSearch = useOpenSearch();

  return (
    <div className={cx('container')} ref={nodeRef}>
      <NotFoundIcon />
      <Text className={cx('title', !text && 'title--without-text')} size="size2-m">
        {title}
      </Text>
      {text && (
        <Text className={cx('text')} color="grey60" size="size4-r">
          {text}
        </Text>
      )}
      {withFilters ?
        <Button onClick={resetFilter}>Сбросить фильтры</Button>
      : <Button onClick={onOpenSearch}>Изменить запрос</Button>}
    </div>
  );
};

export const ConnectedComponent = connect((state) => ({
  query: getQuerySelector(state),
}))(_NotFound);

export const NotFound = (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
