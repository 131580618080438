import { difference, path } from 'ramda';

export const getNumbersDiff = (state) => {
  const phoneList = path(['external', 'blackList', 'blackNumbers'], state);
  const serverPhoneList = path(['external', 'blackList', 'blackNumbersFromServer'], state);
  let added = 0;
  let removed = 0;
  if (phoneList && serverPhoneList) {
    added = difference(phoneList, serverPhoneList).length;
    removed = difference(serverPhoneList, phoneList).length;
  }

  return {
    added,
    removed,
  };
};
