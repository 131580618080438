import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Avatar, Text } from '@carnica/ui-kit';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCBillIcons = {
  entityName: string;
  pictures: string[];
};

/** Варианты анимации названий опций в результирующем чеке ЕК */
const variantsCTitle = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.2 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.2 },
  },
};

/** Варианты анимации иконок в результирующем чеке ЕК в развернутом состоянии */
const variantsCIcons = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
};

/** Компонент иконок опций в результирующем чеке ЕК (развернутый) */
export const CBillIcons: FC<TCBillIcons> = ({ entityName, pictures = [] }) => (
  <motion.div className={cx('wrapper')} layout>
    {entityName && (
      <motion.div
        animate="visible"
        className={cx('wrapper__title')}
        exit="exit"
        initial="hidden"
        layout
        variants={variantsCTitle}
      >
        <Text align="left" color="primary-invert" lineHeight={20} size="xs" weight="bold">
          {entityName}
        </Text>
      </motion.div>
    )}
    <AnimatePresence initial={false}>
      <div className={cx('wrapper__block')}>
        {pictures?.map((picture) => (
          <motion.div
            animate="visible"
            className={cx('wrapper__picture')}
            exit="exit"
            initial="hidden"
            key={picture}
            layout
            layoutId={picture}
            variants={variantsCIcons}
          >
            <Avatar className={cx('wrapper__icon')} image={picture} size="s" />
          </motion.div>
        ))}
      </div>
    </AnimatePresence>
  </motion.div>
);
