import React from 'react';
import PropTypes from 'prop-types';
import { Status, Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const WarningStatus = ({ warningData }) => (
  <Status className={['attention', cx('status')]} emoji="pointingHandUp">
    <span dangerouslySetInnerHTML={{ __html: warningData.text }} />
    {warningData.tooltip && (
      <Tooltip>
        <span dangerouslySetInnerHTML={{ __html: warningData.tooltip }} />
      </Tooltip>
    )}
  </Status>
);

WarningStatus.propTypes = {
  warningData: PropTypes.shape({
    text: PropTypes.string,
    tooltip: PropTypes.string,
  }),
};

export default WarningStatus;
