import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Constants
import { CollapsedContent } from '@beef/legacy-ui-kit';
import { Button } from '@beef/ui-kit';

import {
  convertPercentToCoefficient,
  convertedSum,
  getDiscountPrice,
  initOptions,
  initSlidesObject,
  initializeOptions,
} from 'pages/ProductCard/utils';

// Components
import { BenefitsSlider } from 'pages-components/Benefits';
import Grid from 'pages-components/Grid';
import Ctx from 'pages-components/Ctx';

import PageDescriptions from '../PageDescriptions';
import Slides from '../Slides';
import Options from '../Options';
import { Check } from './Check';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ContructorCardBodyPopup = ({ dataModal, connectDownsellTariff, cancelDownsellTariff }) => {
  const {
    benefits,
    pageTitle,
    descriptions,
    pageSubtitle,
    pageTitleIcon,
    connectButton,
    resetAdsButton,
    fullDescriptionLink,
    layoutData,
  } = dataModal;

  const { slides, content, defaultInfo, presetDiscounts } = layoutData;

  const { simOrderForm } = presetDiscounts;

  const total = useMemo(
    () => ({
      ...initSlidesObject(slides),
      options: initOptions(defaultInfo.tariffOptions),
      initOptions: Object.values(initializeOptions(defaultInfo.tariffOptions)).filter(
        (item) => item.checked,
      ),
    }),
    [slides, defaultInfo],
  );

  const gbAndMinutesLongRate = defaultInfo?.accumulators?.gbytesAndMinutes?.longRcRate || 0;
  const chargeTariffAmount = defaultInfo?.chargeAmount || 0;

  const totalSum = useMemo(() => {
    let sum = gbAndMinutesLongRate + chargeTariffAmount;
    let discountSum = getDiscountPrice(simOrderForm, sum);

    if (total?.options) {
      Object.values(total.options)
        .filter((item) => item.checked)
        .forEach((option) => {
          sum += option.sum;
          if (discountSum) {
            discountSum += option.sum;
          }
        });
    }

    return {
      sum: convertedSum(sum),
      discountSum: discountSum ? convertedSum(discountSum) : '',
    };
  }, [total, defaultInfo]);

  // Проверяем наличие бенефитов;
  const isBenefits = benefits && benefits.length;
  // Текст кнопки подключения
  const connectButtonText = connectButton || Ctx.tariff.buttonText.connect;
  // Сумма со скидкой без учета стоимости опций
  const gbAndMinutesDiscountRate = getDiscountPrice(simOrderForm, gbAndMinutesLongRate);

  return (
    <div className={cx('modal-tariff-card')}>
      <Grid.ProductCard
        className={cx('reset-heading')}
        icon={pageTitleIcon}
        subTitle={pageSubtitle}
        title={pageTitle}
      >
        {!!isBenefits && <BenefitsSlider benefits={benefits} />}

        <div className={cx('tariff-card-body')}>
          <Slides content={content.slides} isUpsellSwitch={false} slides={slides} />
          {defaultInfo?.tariffOptions?.length > 0 && (
            <Options
              options={defaultInfo.tariffOptions}
              title={content.options.title}
              unit={defaultInfo.longRcRateUnit}
            />
          )}
          <Check
            content={content.check.content}
            gbAndMinutesDiscountRate={gbAndMinutesDiscountRate}
            gbAndMinutesLongRate={gbAndMinutesLongRate}
            info={defaultInfo}
            total={total}
            totalSum={totalSum}
          />

          <div className={cx('buttons-group')}>
            <div className={cx('button-accept')}>
              <Button
                fullWidth
                onClick={connectDownsellTariff}
                size="l"
                type="button"
                variant="main"
              >
                {connectButtonText}
              </Button>
            </div>

            <Button
              fullWidth
              onClick={cancelDownsellTariff}
              size="l"
              type="button"
              variant="secondary"
            >
              {resetAdsButton}
            </Button>
          </div>
        </div>
      </Grid.ProductCard>

      <CollapsedContent
        contentClass={cx('question')}
        title="Дополнительная информация о тарифе"
        titleClass={cx('collapsedTitle')}
      >
        <div className={cx('modal-description')}>
          <PageDescriptions descriptions={descriptions} fullDescriptionLink={fullDescriptionLink} />
        </div>
      </CollapsedContent>
    </div>
  );
};

ContructorCardBodyPopup.propTypes = {
  connectDownsellTariff: PropTypes.func,
  cancelDownsellTariff: PropTypes.func,
  dataModal: PropTypes.shape({
    pageTitleIcon: PropTypes.string,
    fullDescriptionLink: PropTypes.object,
    connectButton: PropTypes.string,
    descriptions: PropTypes.array,
    parameterGroups: PropTypes.array,
    resetAdsButton: PropTypes.string,
    pageSubtitle: PropTypes.string,
    pageTitle: PropTypes.string,
    benefits: PropTypes.array,
    discountParams: PropTypes.object,
    layoutData: PropTypes.shape({
      slides: PropTypes.array,
      content: PropTypes.object,
      defaultInfo: PropTypes.object,
      hasTariffConstructor: PropTypes.bool,
      presetDiscounts: PropTypes.objectOf({
        simOrderForm: PropTypes.number,
      }),
    }),
  }),
};

export default ContructorCardBodyPopup;
