import React from 'react';
import { connect } from 'react-redux';
import { ActionBar, Button, Modal, Text } from '@beef/ui-kit';

import { pushStoryAction } from 'pages/Anniversary/utils/analytics';

import { selectContent, selectMetaData } from '../../store/selectors';
import {
  resetStory,
  selectCurrentStory,
  selectShowStory,
  selectStoryLoading,
} from '../../store/story';
import { selectIsVoteAvailableForStory, selectIsVoteLoading, submitVote } from '../../store/votes';

export const StoryModalLayout = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  isActionInProgress,
  isActionAvailable,
  story,
  submitText,
}) => (
  <Modal
    actionBar={
      isActionAvailable ?
        <ActionBar isLoading={isLoading} isWithText size="s">
          <Button isLoading={isActionInProgress} onClick={() => onSubmit(story)}>
            {submitText}
          </Button>
        </ActionBar>
      : null
    }
    headerBarConfig={{ headerText: story.author }}
    isLoading={isLoading}
    isOpen={isOpen}
    onClose={onClose}
    size="m"
  >
    <Text color="grey60" size="size4-r">
      {story.text}
    </Text>
  </Modal>
);

const mapStateToProps = (state) => {
  const story = selectCurrentStory(state) || {};
  const { isVoteAvailable, isAuthorized } = selectMetaData(state);
  const isVoteAvailableForStory = selectIsVoteAvailableForStory(state);

  return {
    isOpen: selectShowStory(state),
    isLoading: selectStoryLoading(state),
    isActionInProgress: selectIsVoteLoading(state),
    isActionAvailable: isVoteAvailable && (isVoteAvailableForStory || !isAuthorized),
    submitText: selectContent(state).storyModal.submitText,
    story,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(resetStory()),
  onSubmit: (story) => {
    dispatch(submitVote());
    pushStoryAction('vote_story', story);
  },
});

export const StoryModal = connect(mapStateToProps, mapDispatchToProps)(StoryModalLayout);
