import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {
  Button,
  Heading,
  Link,
  SmartForm,
  SmartInput,
  Status,
  Switch,
  Tab,
  TabContent,
  Tabs,
  TabsList,
} from '@beef/legacy-ui-kit';

import { maskedPhone as phoneValidator } from 'utils/validators';

import style from './styles.pcss';

const cx = classNames.bind(style);

const phoneMask = '+7 ___ ___ __ __';

class SettingsContent extends Component {
  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({
      isMounted: true,
    });
  }

  render() {
    const {
      activeTab,
      oneNumber,
      multipleOptions,
      disabled,
      onSave,
      onActiveTabChange,
      onOneNumberPhoneChange,
      onSwitchChange,
      onMultipleOptionsPhoneChange,
    } = this.props;
    const { isMounted } = this.state;
    return (
      <div>
        <Heading level={2}>Настройки переадресации</Heading>
        <div className={cx('tabs')}>
          <Tabs activeTab={activeTab} onChange={onActiveTabChange}>
            <TabsList inline type="radio">
              <Tab className={cx('tabHead')} id="oneNumber">
                Все вызовы
              </Tab>
              <Tab className={cx('tabHead')} id="multipleOptions">
                Точная настройка
              </Tab>
            </TabsList>

            <TabContent id="oneNumber">
              <div className={cx('section')}>
                <div className={cx('tip')}>Все номера будут переадресованы на этот номер</div>
                <SmartForm validator={{ phone1: phoneValidator }}>
                  <SmartInput
                    className={cx('phoneInput')}
                    inputId="phone1"
                    keepInitialValue
                    mask={phoneMask}
                    onChange={onOneNumberPhoneChange}
                    value={isMounted ? oneNumber.phone : '+7'}
                  />
                </SmartForm>
              </div>
            </TabContent>

            <TabContent id="multipleOptions">
              <SmartForm
                className={cx('multipleOptionsForm')}
                validator={{
                  phone2: phoneValidator,
                  phone3: phoneValidator,
                  phone4: phoneValidator,
                }}
              >
                <div className={cx('section', 'multipleOptions')}>
                  <span className={cx('tip')}>Переадресовывать вызовы, если мой номер</span>
                  <div className={cx('multipleRow')}>
                    <div className={cx('inlineSwitch')}>
                      <Switch
                        checked={multipleOptions.noAnswer.active}
                        onChange={onSwitchChange('noAnswer')}
                      />
                    </div>
                    <span className={cx('multipleText', 'statusText')}>
                      {multipleOptions.noAnswer.text}, на номер
                    </span>
                    <SmartInput
                      className={[cx('phoneInput', 'inlineInput'), 'absTooltip']}
                      disabled={!multipleOptions.noAnswer.active}
                      inputId="phone2"
                      keepInitialValue
                      mask={phoneMask}
                      onChange={onMultipleOptionsPhoneChange('noAnswer')}
                      value={isMounted ? multipleOptions.noAnswer.phone : '+7'}
                    />
                  </div>
                  <div className={cx('divider')} />
                  <div className={cx('multipleRow')}>
                    <div className={cx('inlineSwitch')}>
                      <Switch
                        checked={multipleOptions.notAvailable.active}
                        onChange={onSwitchChange('notAvailable')}
                      />
                    </div>
                    <span className={cx('multipleText', 'statusText')}>
                      {multipleOptions.notAvailable.text}, на номер
                    </span>
                    <SmartInput
                      className={[cx('phoneInput', 'inlineInput'), 'absTooltip']}
                      disabled={!multipleOptions.notAvailable.active}
                      inputId="phone3"
                      keepInitialValue
                      mask={phoneMask}
                      onChange={onMultipleOptionsPhoneChange('notAvailable')}
                      value={isMounted ? multipleOptions.notAvailable.phone : '+7'}
                    />
                  </div>
                  {multipleOptions.notAvailable.active ?
                    <Status className="attention">
                      После включения этой опции, переадресация, настроенная в услуге{' '}
                      <Link
                        href="/customers/products/mobile/services/details/avtootvetchik/"
                        target="_blank"
                      >
                        «Автоответчик»
                      </Link>
                      , перестанет работать
                    </Status>
                  : null}
                  <div className={cx('divider')} />
                  <div className={cx('multipleRow')}>
                    <div className={cx('inlineSwitch')}>
                      <Switch
                        checked={multipleOptions.busy.active}
                        onChange={onSwitchChange('busy')}
                      />
                    </div>
                    <span className={cx('multipleText', 'statusText')}>
                      {multipleOptions.busy.text}, на номер
                    </span>
                    <SmartInput
                      className={[cx('phoneInput', 'inlineInput'), 'absTooltip']}
                      disabled={!multipleOptions.busy.active}
                      inputId="phone4"
                      keepInitialValue
                      mask={phoneMask}
                      onChange={onMultipleOptionsPhoneChange('busy')}
                      value={isMounted ? multipleOptions.busy.phone : '+7'}
                    />
                  </div>
                </div>
              </SmartForm>
            </TabContent>
          </Tabs>
          <div className={cx('submitWrapper')}>
            <Button className="big" disabled={disabled} onClick={onSave} type="submit">
              Сохранить
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

SettingsContent.propTypes = {
  activeTab: PropTypes.string,
  oneNumber: PropTypes.object,
  multipleOptions: PropTypes.object,
  disabled: PropTypes.bool,
  onSave: PropTypes.func,
  onActiveTabChange: PropTypes.func,
  onOneNumberPhoneChange: PropTypes.func,
  onSwitchChange: PropTypes.func,
  onMultipleOptionsPhoneChange: PropTypes.func,
};

export default SettingsContent;
