import { useLayoutEffect, useState } from 'react';

export const useGetDimentions = () => {
  const [isMiniMobile, setIsMiniMobile] = useState(false);
  const [isLargeTablet, setIsLargeTablet] = useState(false);
  const [isMiniDesktop, setIsMiniDesktop] = useState(false);

  useLayoutEffect(() => {
    if ((window?.screen?.height || 0) <= 1080) {
      setIsLargeTablet(true);
      setIsMiniDesktop(false);
    }
    if ((window?.screen?.width || 0) <= 440) {
      setIsMiniMobile(true);
    } else {
      setIsMiniMobile(false);
    }
    if ((window?.screen?.height || 0) > 1080) {
      setIsLargeTablet(false);
      setIsMiniDesktop(true);
    }
  }, []);

  return {
    isMiniMobile,
    isLargeTablet,
    isMiniDesktop,
  };
};
