import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { FlexItem } from './FlexItem';

const cn = classNames.bind(styles);

export const Flex = ({ children, align, justifyContent, alignItems, gap, wrap, className }) => (
  <div
    className={cn(
      'container',
      align && `align--${align}`,
      gap && `gap--${gap}`,
      wrap && `wrap--${wrap}`,
      alignItems && `alignItems--${alignItems}`,
      className,
    )}
    style={{ justifyContent }}
  >
    {React.Children.map(children, (child, index) =>
      !child || child.type === Flex.Item ?
        child
      : <Flex.Item align={child.props?.align} flex={child.props?.flex} key={+index}>
          {typeof child === 'object' ? React.cloneElement(child, { flex: undefined }) : child}
        </Flex.Item>,
    )}
  </div>
);

Flex.Item = FlexItem;

Flex.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(['left', 'right']),
  wrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
  alignItems: PropTypes.oneOf(['center', 'start', 'end', 'baseline', 'stretch']),
  gap: PropTypes.oneOf([
    '5xl',
    '4xl',
    '3xl',
    '2xl',
    'xl',
    'l',
    'm',
    's',
    'xs',
    '2xs',
    '3xs',
    '4xs',
  ]),
  justifyContent: PropTypes.string,
  className: PropTypes.string,
};
