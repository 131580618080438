import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Basic = ({ className, children, heading, description, buttonText, onClick }) => (
  <div className={className}>
    <Heading level={1}>{heading}</Heading>
    <Heading level={4}>
      <span dangerouslySetInnerHTML={{ __html: description }} />
    </Heading>
    <div className={cx('content')}>{children}</div>
    <Button className="big" onClick={onClick} transformer>
      {buttonText}
    </Button>
  </div>
);

Basic.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

Basic.defaultProps = {
  heading: '',
  description: '',
  buttonText: '',
};

export default Basic;
