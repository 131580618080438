import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { TeaserBanner } from '@beef/smart-kit';
import { Button } from '@beef/ui-kit';

import scrollToBlock from 'utils/scrollToBlock';

import { BannerFixer } from '../BannerFixer';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const HeaderScrollDown = ({ button, scrollToId, ...bannerProps }) => {
  const onClick = useCallback(() => {
    scrollToBlock(scrollToId);
  }, [scrollToId]);

  return (
    <BannerFixer>
      <TeaserBanner {...bannerProps}>
        {button && (
          <div className={cx('button')}>
            <Button size="l" {...button} fullWidth onClick={onClick}>
              {button.text}
            </Button>
          </div>
        )}
      </TeaserBanner>
    </BannerFixer>
  );
};

HeaderScrollDown.propTypes = {
  button: PropTypes.shape({
    text: PropTypes.string,
  }),
  scrollToId: PropTypes.string,
};
