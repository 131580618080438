export const declOfNum = (count) => {
  count %= 100;
  if (count > 10 && count < 20) {
    return 2;
  }
  count %= 10;
  if (count > 1 && count < 5) {
    return 1;
  }
  if (count === 1) {
    return 0;
  }
  return 2;
};
