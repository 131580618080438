import { getQueryParams } from 'pages/FTTB2021/components/OrderTariffFormPopup/utils';
import { ZONE_NUMBERS } from 'pages/FTTB/constants';
/**
 * Возращает url на страницу улицы в агрегаторе
 * @param {string} streetBaseUrl
 * @param {{ id: number, alias: string }} street
 */
export const getStreetPageUrl = (streetBaseUrl, street) =>
  `${streetBaseUrl}${street.id}-${street.alias.toLowerCase()}/`;
export const getPageUrl = (page, zone, pagination, activePages) => {
  const { url, activePage } = pagination;
  const params = {};
  activePages?.forEach((item) => {
    params[Object.keys(ZONE_NUMBERS)[ZONE_NUMBERS.streetActivePage]] =
      zone === ZONE_NUMBERS.streetActivePage ? page : activePage;
    params[Object.keys(ZONE_NUMBERS)[item.zoneType]] =
      zone === item.zoneType ? page : item.activePage;
  });
  const urlParams = getQueryParams(params);
  return `${url}?${urlParams}#ref_${zone}`;
};
