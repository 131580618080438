import { createContext } from 'react';

const SET_PRESET_PARAM = 'SET_PRESET_PARAM';
const SET_TARIFF_INFO = 'SET_TARIFF_INFO';
const SET_DEFAULT_SLIDES_AND_OPTIONS = 'SET_DEFAULT_SLIDES_AND_OPTIONS';
const TOGGLE_UPSELL_OFFER = 'TOGGLE_UPSELL_OFFER';
const HANDLE_CHANGE_TOTAL = 'HANDLE_CHANGE_TOTAL0';
const DISABLE_SPECIAL_OFFER = 'DISABLE_SPECIAL_OFFER';
const SET_SPECIAL_OFFER_INFO = 'SET_SPECIAL_OFFER_INFO';
const RESET_OPTIONS = 'RESET_OPTIONS';
const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
const CHANGE_PARTNER_SUBSCRIPTION = 'CHANGE_PARTNER_SUBSCRIPTION';
const SET_CURRENT_GB_SLIDE_INDEX = 'SET_CURRENT_GB_SLIDE_INDEX';
const PANDA_TYPE_NUMBER = 3;
const SET_CONVERGENCE_SETTINGS = 'SET_CONVERGENCE_SETTINGS';
const SET_AVAILABLE_PRESETS = 'SET_AVAILABLE_PRESETS';
const SET_ACTIVE_PRESET_TAB = 'SET_ACTIVE_PRESET_TAB';
const SET_CONNECTED_AND_AVAILABLE_CONVERGENCE_TARIFFS =
  'SET_CONNECTED_AND_AVAILABLE_CONVERGENCE_TARIFFS';
const SPECIAL_NUMBERS = 'spetsialnyie';
const DEFAULT_FANCY_NUMBER_INDEX = 0;
const SET_FTTB_DISCOUNT_FROM_CONNECTED_PRESET = 'SET_FTTB_DISCOUNT_FROM_CONNECTED_PRESET';

/** Записывает дефолтное состояние опции на текущем пресете */
const GET_PRESET_OPTIONS = 'GET_PRESET_OPTIONS';
/** Устанавливает дефолтное состояние опции на текущем пресете используется для смены персонажа */
const SET_PRESET_OPTIONS = 'SET_PRESET_OPTIONS';

/** Константы для аналитики InternetLive */
const MIN = 0;
const MAX = 10000;
const COOKIE_LIFE_TIME = 2592000;
const TEST_NAME = 'AAtest_internet_live';

/** Константы для конвергенции (ШПД) InternetLive */
const MAX_REQUEST_VALUE = 2;
const REQUEST_COUNTER_STEP = 1;
const DEFAULT_REQUEST_VALUE = 0;
const ERROR_REQUEST_CONVERGENCE_TEXT = 'Can not to get data array of tariffs';

const FREE_TEXT = 'Бесплатно';

const CONNECTED_FTTB_KEY = 'isConnectedFttb--true';

export {
  CHANGE_PARTNER_SUBSCRIPTION,
  CONNECTED_FTTB_KEY,
  COOKIE_LIFE_TIME,
  DEFAULT_FANCY_NUMBER_INDEX,
  DEFAULT_REQUEST_VALUE,
  DISABLE_SPECIAL_OFFER,
  ERROR_REQUEST_CONVERGENCE_TEXT,
  FREE_TEXT,
  GET_PRESET_OPTIONS,
  HANDLE_CHANGE_TOTAL,
  MAX,
  MAX_REQUEST_VALUE,
  MIN,
  PANDA_TYPE_NUMBER,
  REQUEST_COUNTER_STEP,
  RESET_OPTIONS,
  SET_ACTIVE_PRESET_TAB,
  SET_ACTIVE_TAB,
  SET_AVAILABLE_PRESETS,
  SET_CONNECTED_AND_AVAILABLE_CONVERGENCE_TARIFFS,
  SET_CONVERGENCE_SETTINGS,
  SET_CURRENT_GB_SLIDE_INDEX,
  SET_DEFAULT_SLIDES_AND_OPTIONS,
  SET_FTTB_DISCOUNT_FROM_CONNECTED_PRESET,
  SET_PRESET_OPTIONS,
  SET_PRESET_PARAM,
  SET_SPECIAL_OFFER_INFO,
  SET_TARIFF_INFO,
  SPECIAL_NUMBERS,
  TEST_NAME,
  TOGGLE_UPSELL_OFFER,
};

/**
 * defaultPreset - таб для блока конвергенции по-умолчанию.
 * @type {{hasEvent: boolean, description: string, id: string, title: string}}
 */
export const defaultPreset = {
  value: 'default',
  title: 'Без интернета',
  description: '',
  hasEvent: false,
};

/** Пустая функция передается для логики ADS */
const DispatchContext = createContext(() => {});

export default DispatchContext;

export const ConstructorContext = createContext({
  activeCharacter: null,
  isHighPreset: false,
  activeOptionSoc: '',
  passivePopups: {} as Record<string, any>,
  characters: [],
});
