import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { throwAsyncError } from 'utils/errors';
import Picture from 'pages-components/Picture';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const getDataForLinks = (alias, data) => {
  switch (alias) {
    case 'internet':
      return data.internetLink;

    case 'kit':
      return data.internetAndTVLink;

    case 'convergent':
      return data.convLink;

    default:
      break;
  }

  return null;
};

const OtherCatalogBanner = (props) => {
  const { data, availableCatalogs } = props;

  const { title } = data;

  if (!availableCatalogs || !availableCatalogs.length) return null;

  return (
    <div className={cx('wrapper')}>
      <Heading className={cx('title')} level={3} tagName="div">
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Heading>

      <div className={cx('linksWrapper')}>
        {availableCatalogs.map((availableCatalogAlias) => {
          const linkData = getDataForLinks(availableCatalogAlias, data);

          if (!linkData) {
            throwAsyncError(
              `Не найденно соответствующих данных для availableCatalogAlias: ${availableCatalogAlias}`,
            );
            return null;
          }

          return (
            <div className={cx('linkSection')} key={availableCatalogAlias}>
              <a className={cx('linkIconsWrapper')} href={linkData.url}>
                {linkData.icons.map((icon, index) => (
                  <Fragment key={index}>
                    {index !== 0 && <span className={cx('linkIconPlus')}>+</span>}
                    <Picture className={cx('linkIcon')} src={icon} />
                  </Fragment>
                ))}
              </a>

              <Link className={['hideVisitedColor', cx('linkTitle')]} href={linkData.url}>
                <span dangerouslySetInnerHTML={{ __html: linkData.title }} />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

OtherCatalogBanner.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    internetLink: PropTypes.shape({
      icons: PropTypes.arrayOf(PropTypes.string),
      url: PropTypes.string,
      title: PropTypes.string,
    }),
    internetAndTVLink: PropTypes.shape({
      icons: PropTypes.arrayOf(PropTypes.string),
      url: PropTypes.string,
      title: PropTypes.string,
    }),
    convLink: PropTypes.shape({
      icons: PropTypes.arrayOf(PropTypes.string),
      url: PropTypes.string,
      title: PropTypes.string,
    }),
  }),
  availableCatalogs: PropTypes.arrayOf(PropTypes.oneOf(['internet', 'kit', 'convergent'])),
};

export default OtherCatalogBanner;
