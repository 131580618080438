import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Heading } from '@beef/legacy-ui-kit';

import Benefit from './Benefit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Benefits = ({ benefits, className, isCounter, title }) => (
  <div className={cx('component')}>
    <Heading className={cx('title')} level="2">
      {title}
    </Heading>
    <ul className={cx('wrapper')}>
      <div className={cx('benefit', className)}>
        {benefits?.map(({ text, title, image }, index) => (
          <li className={cx('benefit__block')} key={text}>
            <Benefit
              benefitTitle={title}
              image={image}
              index={index + 1}
              isCounter={isCounter}
              text={text}
              title={title}
            />
          </li>
        ))}
      </div>
    </ul>
  </div>
);

Benefits.propType = {
  benefits: PropTypes.array,
  className: PropTypes.string,
  isCounter: PropTypes.bool,
  title: PropTypes.string,
};

export default Benefits;
