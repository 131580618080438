import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Switch } from '@beef/legacy-ui-kit';

import styles from './OptionSelector.pcss';

const cx = classNames.bind({ ...styles });

const OptionSelector = ({
  name,
  className,
  title,
  subTitle,
  price,
  priceHint,
  isAvailable,
  checked,
  onChangeValue,
  inProgress,
  children,
}) => (
  <div className={cx('wrapper', className)}>
    <div className={cx('switch-wrapper')}>
      {isAvailable && (
        <div className={cx('switch')}>
          <Switch checked={checked} isFuture={inProgress} name={name} onChange={onChangeValue} />
        </div>
      )}
      <div className={cx('title')}>
        <div className={cx('main-title')}>{title}</div>
        <div className={cx('sub-title')}>{subTitle}</div>
      </div>
      {price ?
        <div className={cx('price')}>
          {price}
          {priceHint ?
            <div className={cx('price-hint')}>{priceHint}</div>
          : null}
        </div>
      : null}
    </div>
    {children}
  </div>
);

OptionSelector.defaultProps = { isAvailable: true };

OptionSelector.propTypes = {
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  price: PropTypes.string,
  priceHint: PropTypes.string,
  onChangeValue: PropTypes.func,
  className: PropTypes.string,
  checked: PropTypes.bool,
  isAvailable: PropTypes.bool,
  inProgress: PropTypes.bool,
};

export default OptionSelector;
