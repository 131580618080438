import { createSlice } from '@reduxjs/toolkit';

import { IInitialState } from './types';

const initialState: IInitialState = {
  id: 0,
  isMnpService: false,
  isAuth: false,
};

const additionalDataSlice = createSlice({
  name: 'MNP_LANDING/ADDITIONAL_DATA',
  initialState,
  reducers: {},
});

export const additionalDataReducer = additionalDataSlice.reducer;
