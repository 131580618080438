import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Заглушка для загрузки ШПД-табов */
export const FmcSkeleton: FC = () => (
  <section className={cx('wrapper')}>
    <Skeleton className={cx('wrapper__tab')} />
    <Skeleton className={cx('wrapper__tab')} />
    <Skeleton className={cx('wrapper__tab')} />
    <Skeleton className={cx('wrapper__tab')} />
  </section>
);
