import { useState } from 'react';

import { TModalContent } from '../../../modalComponents/ModalContent/types';
import { TUppersTabsContainer } from '../../UppersTabsContainer/types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { TModalContainer } from '../../../modalComponents/ModalContainer';
import { useSelectContent } from '../selectors/useSelectContent';
import { useSelectInfoTile } from '../selectors/useSelectInfoTile';
import { usePrepareVariables } from './usePrepareVariables';
import { usePrepareTabs } from './usePrepareTabs';
import { useOpenTariffPopup } from './useOpenTariffPopup';
import { getExtraTilesProps } from '../utils';
import { setCharacterSoc, setTariffAlias, setTariffSoc } from '../../../../store/slices/totalSlice';
import { MAIN_POPUP_KEY } from '../../../../constants';
import { useIsHighPreset } from '../../../../hooks/useIsHighPreset';
import { sendClickYm, sendExtraPopupYm, sendPopupYm } from '../tempYmUtils';

/** Хук подготовки данных для плиток тарифа.
 * После подключения стора, избавимся от текущей реализации */
export const usePrepareTiles = () => {
  const dispatch = useAppDispatch();
  const isHighPreset = useIsHighPreset();

  /* Флаг имеет значение null (close), либо key (open) попапа, который требуется показать */
  const [isOpen, setIsOpen] = useState<null | string>(null);

  /* Soc просматриваемого персонажа из кеша, если таковой имеется */
  const defaultPreViewCharacterSoc = useAppSelector((state) => state.total?.characterSoc) ?? '';

  /* Soc просматриваемого персонажа */
  const [preViewCharacterSoc, setPreViewCharacterSoc] = useState<string>(
    defaultPreViewCharacterSoc,
  );

  /* Контентная часть */
  const { okBtnText, changeUpperTitle, changeUpperDescription, buttonTextChangeTariff } =
    useSelectContent();

  /* Доступные звериные тарифы и данные по ним */
  const {
    defaultCharacter,
    chosenCharacter,
    preViewCharacter,
    availableUppersTabs,
    defaultCharacterSoc,
  } = useSelectInfoTile(preViewCharacterSoc);

  /* Данные по featured-блокам в попапе выбора персонажа */
  const { activeColor, tariffFeatures } = usePrepareTabs({
    preViewCharacter,
    availableUppersTabs,
    defaultCharacterSoc,
  });

  /* Подготовка общих переменных для плитки тарифа */
  const props = usePrepareVariables({
    isOpen,
    okBtnText,
    chosenCharacter,
    defaultCharacter,
    preViewCharacter,
  });

  /* Общие переменные для плитки тарифа */
  const {
    name,
    imageSrc,
    isMainKey,
    advantages,
    isBeeIndent,
    detailsInfo,
    description,
    withoutIndent,
    extraTilesKeys,
    currentButtonText,
  } = props;

  /* Функция открыия попапа по ключу */
  const handleClick = (key: null | string) => {
    setIsOpen(key);
    sendClickYm(key, chosenCharacter.name, chosenCharacter, defaultCharacter, isHighPreset);
  };

  /* Функция закрытия попапа */
  const onClose = () => handleClick(null);

  /* Объект пропов для главной карточки тарифа (персонаж) */
  const mainTilesProps = {
    headerProps: { title: name, description },
    pictureProps: { imageSrc, isBeeIndent, withoutIndent },
    buttonProps: {
      onClick: () => handleClick(MAIN_POPUP_KEY),
      buttonText: buttonTextChangeTariff,
    },
  };

  /* Создает объект пропов на основе key для EXTRA-карточек тарифа (партнерка, способность, суперспособность) */
  const extraTilesProps = (key: keyof typeof advantages) =>
    getExtraTilesProps({
      isBeeIndent,
      isHighPreset,
      withoutIndent,
      tileInfo: advantages[key],
      isPowerType: key === 'power',
      onClick: () => handleClick(key),
    });

  /* Ф-я закрытия для EXTRA-карточек тарифа (партнерка, способность, суперспособность) */
  const handleExtraPopupClose = () => {
    onClose();
    /* Отправка метрики по кнопке "Понятно" в модальном окне */
    sendExtraPopupYm(isOpen ?? '', detailsInfo?.title);
  };

  /* Объек пропов для "нижней части" попапа (buttons, size, open/close) */
  const modalContainerProps: Omit<TModalContainer, 'children'> = {
    isOpen: !!isOpen,
    onClose: () => {
      setPreViewCharacterSoc(
        (isHighPreset ? chosenCharacter.highOptionSoc : chosenCharacter.optionSoc) ?? '',
      );
      onClose();
    },
    modalActionBarProps: {
      link: '',
      extraLink: '',
      onClick: async (e) => {
        e.preventDefault();
        if (isMainKey) {
          dispatch(setTariffSoc(preViewCharacter.soc));
          dispatch(setCharacterSoc(preViewCharacterSoc));
          /** диспатч выбранного алиаса в стор требуется для корректного переключения аппера */
          dispatch(setTariffAlias(preViewCharacter.alias));
          /* Отправка метрики */
          sendPopupYm('confirm', preViewCharacter.name);
          onClose();
        } else {
          handleExtraPopupClose();
        }
      },
      buttonText: currentButtonText,
    },
    size: isMainKey ? 'm' : 's',
  };

  /* Объект Features (плашки описания способностей/суперсопосбностей для главного попапа выбора персонажа).
   * Берем либо из просматриваемого персонажа (preViewCharacter),
   * либо из текущего выбранного персонажа (tariffFeatures) */
  const defineTariffFeatures =
    (isHighPreset ? preViewCharacter?.highTariffFeatures : preViewCharacter?.tariffFeatures) ||
    tariffFeatures;

  /* клик по выбору персонажа */
  const onClick = (optionSoc: string) => {
    setPreViewCharacterSoc(optionSoc);

    /* Отправка метрики */
    sendPopupYm(
      'choose',
      availableUppersTabs?.find(
        (item) => item.optionSoc === optionSoc || item.highOptionSoc === optionSoc,
      )?.name,
    );
  };
  /* Объект пропов для главного поапа тарифа (выбор персонажа) */
  const uppersTabsProps: TUppersTabsContainer = {
    activeColor,
    tabs: availableUppersTabs,
    tariffFeatures: defineTariffFeatures,
    activeCharacter:
      (isHighPreset ? preViewCharacter.highOptionSoc : preViewCharacter.optionSoc) ||
      defaultCharacterSoc ||
      '',
    onClick,
  };

  /* Объект пропов контентной части попапов (используется и для главного и для extra-попапов) */
  const modalHeaderProps = {
    title: isMainKey ? changeUpperTitle : detailsInfo?.title,
    description: isMainKey ? changeUpperDescription : detailsInfo?.text,
  };

  /* Итоговый объект пропов для компонента ModalContent (контентная часть в главном/extra попапах) */
  const modalContentProps: TModalContent = {
    isMainKey,
    uppersTabsProps,
    modalHeaderProps,
  };

  /* Автоматическое открытие модального окна с выбором персонажа, если в url страницы есть query-параметр.
   * Отрабатывает единожды */
  useOpenTariffPopup({ isOpen, isMainKey, handleClick });

  return {
    extraTilesKeys,
    mainTilesProps,
    extraTilesProps,
    modalContentProps,
    modalContainerProps,
  };
};
