import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { DoneOutlineIcon, InfoIcon, Link, Text } from '@beef/ui-kit';

// Types
import { IDetailsBlock, IIcons } from 'pages/Services/VoWifiServiceCardLanding/types';
// Styles
import { selectSupportStatus } from 'pages/Services/VoWifiServiceCardLanding/store/selectors';
import { SUPPORT_STATUSES } from 'pages/Services/VoWifiServiceCardLanding/store/constants';
import {
  voWifiClickedSettingsButtonInTeaserYM,
  voWifiGoToShopYM,
} from 'pages/Services/VoWifiServiceCardLanding/analytics';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const Icon: FC<IIcons> = ({ icon, colorIcon, size }) => {
  const icons = {
    DoneOutlineIcon,
    InfoIcon,
  };

  const IconComponent = icon !== undefined && icon in icons ? icons?.[icon] : null;

  return IconComponent ? <IconComponent fill={colorIcon} size={size} /> : null;
};

export const _DetailsBlock: FC<Partial<IDetailsBlock>> = ({
  label,
  title,
  text,
  button,
  isMobile,
  supportStatus,
}) => {
  const { icon, text: labelText, colorIcon, textColor } = label || {};

  const testName = supportStatus ? `label_${supportStatus?.toLowerCase()}` : 'label_not_supported';

  const handleLinkOnClick = (event) => {
    // ym
    if (supportStatus === SUPPORT_STATUSES.SUPPORTED) {
      event.preventDefault();
      voWifiClickedSettingsButtonInTeaserYM();
      window.location.href = button?.href ?? '';
      return;
    }

    if (supportStatus === SUPPORT_STATUSES.NOT_SUPPORTED) {
      voWifiGoToShopYM();
      window.location.href = button?.href ?? '';
    }
  };

  return (
    <div className={cn('wrapper')}>
      <div className={cn('label')} data-t-id={testName}>
        {icon && <Icon colorIcon={colorIcon} icon={icon} size="m" />}
        <Text color={textColor} size="size5-m">
          {labelText}
        </Text>
      </div>

      <div className={cn('details')}>
        {title && (
          <Text size="size0-b" tagType="h1">
            {title}
          </Text>
        )}

        {text && (
          <div className={cn('details__text')}>
            <Text color="grey60" size="size4-r">
              {text}
            </Text>
          </div>
        )}

        {button?.text && (
          <div className={cn('action_button')}>
            <Link {...button} isFullWidth={isMobile} onClick={handleLinkOnClick}>
              {button.text}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export const DetailsBlock = connect((state) => ({
  supportStatus: selectSupportStatus(state),
}))(_DetailsBlock);
