import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { TariffContentContainer } from './TariffContentContainer';
import { TariffDescriptionContainer } from './TariffDescriptionContainer';
import { fullOrderServicesShape } from '../../../../../PropTypes';
import { ItemHiddenFees } from './ItemHiddenFees';
import styles from '../styles.pcss';

const cx = classNames.bind(styles);

export const TariffSettingsItemContainer = ({
  isHidden,
  isMobile,
  tariffInacId,
  fttbServiceType,
  serviceTitle,
  fullOrderServices,
  serviceDescription,
  tooltip,
}) => {
  const visibleServices = fullOrderServices?.filter(({ isHidden: h }) => !h);
  return (
    <li className={cx('wrapper__item')}>
      {isHidden ?
        <ItemHiddenFees
          {...{ serviceTitle, fullOrderServices, tooltip: !isMobile ? tooltip : '' }}
        />
      : <>
          <TariffDescriptionContainer {...{ serviceTitle, serviceDescription }} />
          <TariffContentContainer
            {...{ tariffInacId, isMobile, fttbServiceType, fullOrderServices: visibleServices }}
          />
        </>
      }
    </li>
  );
};

TariffSettingsItemContainer.propTypes = {
  guid: PropTypes.string,
  fttbServiceType: PropTypes.number,
  isHidden: PropTypes.bool,
  isMobile: PropTypes.bool,
  tariffInacId: PropTypes.string,
  serviceTitle: PropTypes.string,
  tooltip: PropTypes.string,
  serviceDescription: PropTypes.string,
  fullOrderServices: fullOrderServicesShape,
};
