import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TPassiveYandex = {
  description?: string;
  icon?: string;
};

/** Базовая информация по "Яндекс плюс" в пассивке */
export const PassiveYandex: FC<TPassiveYandex> = ({ icon, description }) =>
  description ?
    <div className={cx('wrapper')}>
      {icon && (
        <div className={cx('wrapper__icon')}>
          <img alt="" className={cx('wrapper__icon--small')} loading="lazy" src={icon} />
        </div>
      )}
      <Text color="grey60" size="size5-r-s">
        {description}
      </Text>
    </div>
  : null;
