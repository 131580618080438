import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { ChevronRightIcon } from '@beef/ui-kit';

import { DeviceContent } from './components/DeviceContent';
import { ModalContainer } from './components/ModalContainer';
import { ModalContent } from './components/ModalContent';
import { DEFAULT_BG_COLOR } from './constants';
import { TDevices } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Блок рекомендованных устройств для пресетов (Гб).
 * Включает в себя контентную часть и модальное общее окно */
export const Devices = memo<TDevices>(
  ({
    handleClick,
    deviceContentProps,
    modalContainerProps,
    backgroundColor = DEFAULT_BG_COLOR,
  }) => (
    <>
      <button
        className={cx('button', 'wrapper')}
        onClick={handleClick}
        style={{ backgroundColor }}
        type="button"
      >
        <DeviceContent {...deviceContentProps} />
        <ChevronRightIcon />
      </button>
      <ModalContainer {...modalContainerProps}>
        <ModalContent />
      </ModalContainer>
    </>
  ),
);
