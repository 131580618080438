import { createSelector } from '@reduxjs/toolkit';

import { selectIsMaximal } from 'constructorV1/components/constructorPage/BillContainer/selectors/selectIsMaximal';
import { selectOptionsSections } from 'constructorV1/store/selectors/selectTariffSettings';
import { selectTotalOptionsSocs } from 'constructorV1/store/selectors/selectTotal';

/** Селектор подготовки объекта activeSections для отображения активных иконок опций */
export const selectMapOptions = createSelector(
  selectOptionsSections,
  selectTotalOptionsSocs,
  selectIsMaximal,
  (sections, optionsSocs, isMaximal) => {
    /* Перебираем каждую доступную секцию опций */
    return sections?.map((section) => {
      /* Выборка активных опций по секции */
      const sectionActiveIcons = section.options
        ?.reduce((acc: any, option) => {
          if (optionsSocs?.includes(option.serviceCategory || '')) {
            return [...acc, option.pictures];
          }
          return acc;
        }, [])
        .flat();

      return { isMaximal, title: section.title, activeIcons: sectionActiveIcons };
    });
  },
);
