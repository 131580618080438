export const FANCY_NUMBER_URL =
  '/customers/products/mobile/services/details/nomer-na-vybor/krasivie-nomera/';
export const FANCY_NUMBER_ALIAS = 'krasivie-nomera';
export const FANCY_NUMBER_TITLE = 'Красивые номера';

export const CATEGORY_ALIAS = {
  spetsialnyie: 'spetsialnyie',
  bronzovie: 'bronzovie',
  serebryanie: 'serebryanie',
  zolotie: 'zolotie',
  'zolotie-vip': 'zolotie-vip',
  'zolotie-premium': 'zolotie-premium',
  platinovie: 'platinovie',
};

export const FILTER_FAVORITE = 'lyubimoe-chislo';
export const FILTER_TAIL = 'krasiviy-hvost';
export const FILTER_SIMILAR = 'pohozhiy-nomer';

export const FILTER_ALIAS_ARRAY = [FILTER_FAVORITE, FILTER_TAIL, FILTER_SIMILAR];
export const CATEGORY_ALIAS_ARRAY = [
  'spetsialnyie',
  'bronzovie',
  'serebryanie',
  'zolotie',
  'zolotie-vip',
  'zolotie-premium',
  'platinovie',
];

export const DEFAULT_DEF_CODE = 'XXX';

export const ENDPOINT = {
  favorite: '/fancynumber/favourite/',
  similar: '/fancynumber/similar/',
  all: '/fancynumber/all/',
  change: '/changenumber/availablenumbers/',
  settings: '/fancynumber/settings/',
};

export const PROMOTION_PLACES = {
  category: 'category',
  phoneNumber: 'phoneNumber',
  randomPhoneNumber: 'randomPhoneNumber',
};

export const RECALL_REQUEST_AFTER_CAPTCHA = {
  loadAllNumbers: 'loadAllNumbers',
  loadFilteredNumbersThunk: 'loadFilteredNumbersThunk',
};
