// @ts-expect-error неверная типизация в ui-kit
import { TabType } from '@beef/ui-kit/Tabs/types';

// TODO: Не забыть про импорт при выносе в пакет!
import { TPresetPickerOptions } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/PresetPicker/types';

/** Функция подготовки структуры таба для китового компоннета Tabs */
export const prepareTabs = (tabs: TPresetPickerOptions[]): TabType[] =>
  tabs?.map((tab) => ({
    id: tab.value as unknown as string,
    title: `${tab.value} ${tab.unit}`,
  }));
