import React, { PureComponent } from 'react';
import axios from 'axios';
import qs from 'query-string';
import classNames from 'classnames/bind';
import { Button, TextInput } from '@beef/legacy-ui-kit';

import Captcha from 'pages-components/SmsServiceExtension/components/Captcha/Captcha';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CAPTCHA_KEY = 'ConfirmationForm';

class CheckNumberToOperator extends PureComponent {
  state = {
    phone: '+7 ',
    captcha: '',
    captchaError: null,
    result: null,
    pending: false,
  };

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { phone, captcha } = this.state;

    this.setState({
      result: null,
      captchaError: null,
      pending: true,
    });

    const payload = {
      PhoneCode: phone.slice(3, 6),
      Phone: phone.slice(-9),
      _captcha_key: CAPTCHA_KEY,
      Captcha: captcha,
    };

    // it suppose query parameters are required, but not used on backend 🤷‍
    axios
      .post(
        '/checkmobileoperatorwidget/getrequest/?ui-page=1095185&ui-part=1095531&ui-culture=ru-ru&ui-region=nizhniy-novgorod',
        qs.stringify(payload),
      )
      .then(({ data }) => {
        this.captcha.fetchCaptcha();
        if (data.Errors && data.Errors.Captcha) {
          this.setState({
            captchaError: 'Неверный код с изображения',
            captcha: '',
            pending: false,
          });
          return;
        }
        this.setState({
          result: data,
          pending: false,
        });
      })
      .catch(() => {
        this.setState({
          result: {},
          pending: false,
        });
      });
  };

  render() {
    const { phone, captcha, captchaError, result, pending } = this.state;
    const resultHTML = result?.View || 'Ой, что-то пошло не так :(';

    return (
      <>
        <div className={cx('info')}>
          Просто введите номер мобильного телефона и символы с картинки, чтобы проверить оператора и
          регион владельца номера.
        </div>
        <form className={cx('form')} onSubmit={this.onSubmit}>
          <TextInput
            keepInitialValue
            mask="+7 ___ ___-__-__"
            name="ctn"
            onChange={(value) => {
              this.onChange('phone', value);
            }}
            value={phone}
          />
          <Captcha
            captchaKey={CAPTCHA_KEY}
            error={captchaError}
            onChange={this.onChange}
            ref={(e) => {
              this.captcha = e;
            }}
          />
          <Button disabled={phone.length !== 16 || captcha.length !== 5 || pending} wide>
            Проверить
          </Button>
        </form>
        {result && <div className={cx('info')} dangerouslySetInnerHTML={{ __html: resultHTML }} />}
      </>
    );
  }
}

export default CheckNumberToOperator;
