import { useIsDesktopDimension, useOnClickOutside } from '@beef/ui-kit/hooks';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import { ModalProps } from './types';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const useModal = ({ isOpen, onClose }: ModalProps) => {
  const isDesktop = useIsDesktopDimension();
  const container = useRef<HTMLElement | null>(null);

  const [isIos, setIsIos] = useState(false);
  const commonModalRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const [browserNavbarHeight, setBrowserNavbarHeight] = useState(0);
  const [bodyScrollHeight, setbodyScrollHeight] = useState(0);

  useEffect(() => {
    container.current = window?.document?.body;
  }, []);

  useOnClickOutside({
    ref: commonModalRef,
    handler: onClose,
    isOpen,
    overlayRef,
  });

  useEffect(() => {
    setIsIos(window?.navigator?.userAgent?.includes('Mac') && 'ontouchend' in document);
  }, []);

  useEffect(() => {
    const keyHandler = ({ code }: KeyboardEvent) => {
      if (code === 'Escape' && isOpen) {
        onClose();
      }
    };
    window?.addEventListener('keyup', keyHandler);

    return () => {
      window?.removeEventListener('keyup', keyHandler);
    };
  }, [onClose, isOpen]);

  useEffect(() => {
    /** расчеты для того, чтобы при открытии и закрытии модалки
     * контент не прыгал вверх страницы и учет навбара сафари (особенности иоса) */
    if (isIos) {
      const handleMove = () => {
        /** вычисляем высоту панели навигации сафари, чтобы учитывать ее при отработке промотки
         * для фиксирования основного контента под модалкой в прежнем положении */
        const navbarHeight = window.innerHeight - document.documentElement.clientHeight || 0;
        setBrowserNavbarHeight((prevState) => {
          if (prevState < navbarHeight) {
            return navbarHeight;
          }
          return prevState;
        });
        const scrollingElement = document?.scrollingElement || document?.documentElement;
        const bodyScrollTop = scrollingElement?.scrollTop;
        /** вычисляем на какую высоту был прокручен контент, чтобы корректно фиксировать
         * основной контент под модалкой в прежнем положении */
        setbodyScrollHeight(bodyScrollTop);
      };

      document?.addEventListener('touchmove', handleMove);

      return () => {
        document?.removeEventListener('touchmove', handleMove);
      };
    }
    return undefined;
  }, [isIos]);

  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth || 0;
    let lastTouchY: any;
    let lastTouchX: any;
    let bodyChildren: any;
    const scrollbarHeight = window.innerHeight - document.documentElement.clientHeight || 0;

    if (isIos) {
      /**
       * должен быть использован querySelector `body > *`,
       * так как event.preventDefault на body или html element ничего не делает
       */
      bodyChildren = Array.from(document?.querySelectorAll('body > *'));
    }
    const preventRubberBand = (event: any) => {
      let allowScroll = false;
      if (event?.type === 'touchstart' || event?.cancelable === false) {
        lastTouchY = event?.touches[0]?.clientY;
        lastTouchX = event?.touches[0]?.clientX;
      } else {
        let closestScrollable = event?.target;

        const currentY = event?.touches[0]?.clientY;
        const currentX = event?.touches[0]?.clientX;

        const deltaY = lastTouchY - currentY;
        const deltaX = lastTouchX - currentX;

        /**
         * запуск цикла по родителям элемента от event.target до document.body
         */
        while (closestScrollable && closestScrollable?.tagName !== 'BODY') {
          const { scrollTop, clientHeight, scrollHeight } = closestScrollable;

          lastTouchY = currentY;

          /**
           * если направление скроллинга идет вверх и скроллбар нашего основго элемента не вверху
           * или направление скроллинга идет вниз и скроллбар нашего основго элемента не внизу
           */
          if (
            (deltaY < 0 && scrollTop > 0) ||
            (deltaY > 0 && scrollHeight - clientHeight > scrollTop) ||
            (deltaX !== 0 &&
              /**
               * когда сафари показывает верхний и нижний навбар высота элемента обрезается на высоту
               * навбара без добавления скролла, поэтому мы игнорируем элемент с тем же scrollHeight как у body
               */
              scrollHeight !== document?.body?.clientHeight)
          ) {
            /**
             * нашли скролящийся элемент, все в порядке, скролл разрешен
             */
            allowScroll = true;
            break;
          }
          closestScrollable = closestScrollable.parentNode;
        }

        /**
         * если все предки таргета проверены на всем пути до body и ни один из них не имеет доступного скролла,
         * то резиновое поведение скроллинга иоса сафари надо остановить (rubber band scroll)
         */
        if (!allowScroll) {
          event?.preventDefault();
        }
      }
    };

    if (isOpen) {
      if (!isIos) {
        document?.documentElement?.classList?.add(cn('scroll-hidden'));
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.style.overflowX = 'visible';
        document.body.style.overflow = 'hidden';
      }

      if (isIos) {
        document?.documentElement?.classList?.add(cn('fix-ios-scroll'));
        /** когда навбар виден, разница составаляет 0, поэтому
         * чтобы при открытом навбаре контент под модалкой не сдвинулся,
         * надо заранее знать высоту этого навбара и сдвигать контент с ее учетом */
        if (scrollbarHeight === 0) {
          document.body.scrollTop = bodyScrollHeight - browserNavbarHeight;
        } else {
          document.body.scrollTop = bodyScrollHeight;
        }
        document.body.style.overflow = 'hidden';
        /** чтобы не скролился контент под модалкой, но при
         * этом контент в ней самой оставался бы прокручиваемым (особенности работы body в safari ios) */
        document?.addEventListener('touchstart', preventRubberBand);
        bodyChildren?.forEach(
          (item: any) => item?.addEventListener('touchmove', preventRubberBand),
        );
      }
    }

    return () => {
      document?.documentElement?.classList?.remove(cn('scroll-hidden'));
      document.body.style.overflow = 'auto';
      document.body.style.overflowX = 'hidden';
      document?.documentElement?.classList?.remove(cn('fix-ios-scroll'));

      if (isIos) {
        document?.removeEventListener('touchstart', preventRubberBand);
        bodyChildren?.forEach(
          (item: any) => item?.removeEventListener('touchmove', preventRubberBand),
        );
      }

      if (!isIos) {
        document.body.style.paddingRight = '0';
      }
    };
  }, [isOpen, browserNavbarHeight, bodyScrollHeight, isIos]);

  return {
    commonModalRef,
    overlayRef,
    isDesktop,
    isOpen,
    onClose,
    container: container.current,
  };
};
