import { createSelector } from '@reduxjs/toolkit';
import { emptyObj } from '@beef/utils';

// Selectors
import { getTariffDetailUnit } from 'pages/ProductCard/Tariff/components/ConvergenceLayout/store/selectors/modal';

import { getCommonStoreContent, getTariffDetailsData } from './common';

/**
 * getTariffDetailsContent: общие данные по блоку доступных тарифов в orderTariffDetailsData.
 * getAccessTariff: массив тарифов.
 * getHasInacId: наличие обязательного параметра inacId в параметрах URL (бесшовный ШПД).
 * getActiveTab: активный таб.
 * getTotalPrice: общая сумма по активным пресетам таба.
 * getSeamlessUrlParams: объект query-параметров из URL.
 * getSettingsActiveTab: активный таб подключенного пакета.
 * getHasSeamlessUrlParams: наличие свойств в объекте с query-параметрами.
 * getIsActivateDisconnectConvergent: feature toggle для активации возможности вкл/выкл функции отказа от конвергенции.
 * selectDiscountDescription: получает текст описания скидки
 * selectTariffTabsTitle: получение заголовка для табов тарифов
 */
export const getTariffDetailsContent = createSelector(
  getTariffDetailsData,
  (state) => state || emptyObj,
);
export const selectCommonContent = createSelector(
  getCommonStoreContent,
  (common) => common.content || emptyObj,
);

export const getAccessTariff = (state) => getTariffDetailsContent(state).tariffs;
export const getHasInacId = (state) => getTariffDetailsContent(state).hasInacId;
export const getActiveTab = (state) => getTariffDetailsContent(state).activeTab;
export const getTotalPrice = (state) => getTariffDetailsContent(state).totalPrice;
export const getSeamlessUrlParams = (state) => getTariffDetailsContent(state).seamlessUrlParams;
export const getSettingsActiveTab = (state) => getTariffDetailsContent(state).activeSettingsTab;
export const getHasSeamlessUrlParams = (state) =>
  getTariffDetailsContent(state).hasSeamlessUrlParams;
export const getIsActivateDisconnectConvergent = (state) =>
  getTariffDetailsContent(state).activateDisconnectConvergent;
export const selectDiscountDescription = (state) =>
  getTariffDetailsContent(state).discountDescription;
export const selectTariffTabsTitle = (state) => selectCommonContent(state).presetUpOrderTexts;
export const selectIsConnectedUserFlag = (state) =>
  getAccessTariff(state)?.some(({ isConnected }) => isConnected);

export const getActiveTabSpeedName = createSelector(
  getAccessTariff,
  getActiveTab,
  (tariffs, currentTab) =>
    tariffs.find(({ inacId }) => inacId === currentTab)?.speed || tariffs[0]?.speed,
);

export const getActiveTabServiceType = createSelector(
  getAccessTariff,
  getActiveTab,
  (tariffs, currentTab) =>
    tariffs.find(({ inacId }) => inacId === currentTab)?.productType || tariffs[0]?.productType,
);
export const getTotalPriceWithUnit = createSelector(
  getTotalPrice,
  getTariffDetailUnit,
  (sum, unit) => [sum, unit].join('&nbsp;'),
);
