import React from 'react';
import { connect } from 'react-redux';

import { getActiveFilter, getDefCodeValue, getQueryValue } from '../../../store/selectors/filter';
import { setFilterDefCodeValue, setFilterQueryValue } from '../../../store/actions/filter';
import { getFilterComponent } from './utils';
import { FilterInputShape } from '../../../shapes';

const FilterInputLayout = ({ activeFilter, query, defCode, onQueryChange, onDefCodeChange }) => {
  const InputComponent = getFilterComponent(activeFilter);

  return (
    <InputComponent
      defCode={defCode}
      onDefCodeChange={onDefCodeChange}
      onQueryChange={onQueryChange}
      query={query}
    />
  );
};

FilterInputLayout.propTypes = FilterInputShape;

const mapStateToProps = (state) => ({
  activeFilter: getActiveFilter(state),
  query: getQueryValue(state),
  defCode: getDefCodeValue(state),
});

const mapDispatchToProps = {
  onQueryChange: setFilterQueryValue,
  onDefCodeChange: setFilterDefCodeValue,
};

export const FilterInput = connect(mapStateToProps, mapDispatchToProps)(FilterInputLayout);
