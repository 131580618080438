import React from 'react';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TBadgeProps = { activeColor?: string; discount: string };

const Badge: React.FC<TBadgeProps> = ({ discount }) => (
  <div className={cx('badge')}>
    <Text size="size5-r-s">{discount}</Text>
  </div>
);

export { Badge };
