import React, { FC, memo } from 'react';

import { useDots } from './hooks/useDots';
import { Dot } from './components/Dot';

/**
 * Компонент точек на стартовом блоке
 */
export const Dots: FC = memo(() => {
  const dots = useDots();

  return (
    <>
      {dots.map((dot) => (
        <Dot key={dot.id} {...dot} />
      ))}
    </>
  );
});
