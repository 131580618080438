import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ScrollContainerV2 } from '@beef/legacy-ui-kit';

import { ScrollPaddingFix } from './extra/ScrollPaddingFix';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

/**
 * TODO: Might consider refactoring ScrollContainerV2
 */
export const ScrollContainerV3 = ({ children, className, isNoGrow }) => (
  <ScrollContainerV2 className={cn('container', className)}>
    <div className={cn('component', { 'component--no-grow': isNoGrow })}>{children}</div>
    <ScrollPaddingFix />
  </ScrollContainerV2>
);

ScrollContainerV3.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isNoGrow: PropTypes.bool,
};
