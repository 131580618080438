import { Children, PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';

import {
  SET_FTTB_CONNECTION_DATA,
  getConnectionStateInitial,
  setAndSaveConnectionState as setAndSaveConnectionStateAction,
} from 'pages/FTTB/actions';
import { connectionDataPropTypes } from 'pages/FTTB/constants';
import * as checkAddressHelper from 'pages/FTTB/utils/checkAddressHelper';
import getStreetsForCity from 'pages/FTTB/services/getStreetsForCity';
import getHousesForStreet from 'pages/FTTB/services/getHousesForStreet';
import { getConnectionData, isConnectionDataLoading } from 'pages/FTTB/selectors/connectionState';
import { getCurrentRegion } from 'pages/FTTB/selectors/regions';

const noop = () => {};

class CheckAddressHOC extends PureComponent {
  state = {
    streets: [],
    street: null,
    streetTerm: '',
    houses: [],
    house: null,
    houseTerm: '',
    flat: '',
    connectionDataChanged: false,
  };

  componentDidMount() {
    const { connectionData } = this.props;

    this.props.getConnectionData();
    if (connectionData) this.setNewConnectionData(connectionData);
  }

  componentDidUpdate(prevProps) {
    const { connectionData } = this.props;
    const { connectionData: prevConnectionData } = prevProps;

    // Если в `store` обновились данные о адресе, перезатираем локальный стейт, новыми данными
    if (!isEqual(connectionData, prevConnectionData)) this.setNewConnectionData(connectionData);
  }

  /**
   * Принимает адрес, записывает его в состояние и по умолчанию в `store`
   * @param {object} data - объект с адресом
   * @param {bool} [setInStore=true] - флаг для записи адреса в `store`
   */
  setConnectionData = (data, setInStore = true) => {
    const { street, house, flat } = data;
    this.setState({ street, house, flat });

    if (setInStore) this.props.setConnectionData(data);
  };

  setNewConnectionData = (connectionData) => {
    const { currentCity } = this.props;
    const newConnectionData = {
      city: currentCity,
      street: null,
      house: null,
      flat: '',
    };

    if (connectionData.city && connectionData.city.id) {
      newConnectionData.city = connectionData.city;
    }
    if (connectionData.street && connectionData.street.id) {
      newConnectionData.street = connectionData.street;
    }
    if (connectionData.house && connectionData.house.id) {
      newConnectionData.house = connectionData.house;
    }
    if (connectionData.flat) {
      newConnectionData.flat = connectionData.flat;
    }

    this.setState({ connectionDataChanged: true });
    this.setConnectionData(newConnectionData, false);
  };

  changeCity = () => {
    const { onCityChange } = this.props;
    checkAddressHelper.changeCity();
    if (onCityChange) onCityChange();
  };

  /**
   * Устанавливает переданую улицу в `state`
   * @param {object} street объект выбранной улицы
   * @param {func} [callback] функция вызываемая после `setState`
   */
  changeStreet = (street, callback = noop) => {
    const { onStreetChange } = this.props;
    this.setState({ street, houses: [], house: null }, () => {
      callback();
      if (onStreetChange) onStreetChange();
    });
  };

  changeHouse = (house, callback = noop) => {
    const { currentCity, onHouseChange } = this.props;
    const { street } = this.state;

    this.props.setAndSaveConnectionState({
      city: currentCity,
      street,
      house,
    });

    this.setState({ house }, () => {
      callback();
      if (onHouseChange) onHouseChange();
    });
  };

  changeFlat = (value, callback = noop) => {
    const { street, house } = this.state;
    const { currentCity, onFlatChange } = this.props;

    this.setState({ flat: value }, () => {
      if (onFlatChange) onFlatChange(value);
      callback();
    });

    this.setConnectionData({ city: currentCity, street, house, flat: value });
  };

  fetchStreets = (term) => {
    getStreetsForCity(this.props.currentCity.id, term).then(({ data }) => {
      this.setState({ streets: data, streetTerm: term });
    });
  };

  fetchHouses = (term) => {
    const { street } = this.state;
    if (!street) return;

    getHousesForStreet(street.id, term).then(({ data }) => {
      this.setState({ houses: data, houseTerm: term });
    });
  };

  clearStreet = (callback) => {
    this.setState({ street: null, house: null, flat: '' }, callback);
  };

  clearHouse = (callback = noop) => {
    const { onClearHouse } = this.props;
    this.setState({ house: null, flat: '' }, () => {
      if (onClearHouse) onClearHouse();
      callback();
    });
  };

  render() {
    const { streets, street, streetTerm, houses, house, houseTerm, flat, connectionDataChanged } =
      this.state;
    const {
      currentCity,

      setAndSaveConnectionState,
      loadingConnectionData,

      children,
    } = this.props;

    return Children.map(children, (child) =>
      // like a <Element {...props} checkAddressModel={...} />
      cloneElement(child, {
        checkAddressModel: {
          // change handlers
          changeCity: this.changeCity,
          changeStreet: this.changeStreet,
          changeHouse: this.changeHouse,
          changeFlat: this.changeFlat,

          // fetch handlers
          fetchStreets: this.fetchStreets,
          fetchHouses: this.fetchHouses,

          // clear handlers
          clearStreet: this.clearStreet,
          clearHouse: this.clearHouse,

          // city info
          currentCity,

          // streets info
          streets,
          street,
          streetTerm,

          // houses info
          houses,
          house,
          houseTerm,

          // flat info
          flat,

          // Принимает объект с адресом, записывает его в `store` и сохраняет в сессию
          setAndSaveConnectionState,
          // Принимает объект с адресом, записывает его в `state` и `store`(по умолчанию), но не сохраняет в сессию
          setConnectionData: this.setConnectionData,

          // Флаг означающий, что данные о проверенном адресе ещё не загруженны
          loadingConnectionData,

          // Равен `true`, если данные о адресе были изменены из `store`
          connectionDataChanged,
        },
      }),
    );
  }
}

CheckAddressHOC.propTypes = {
  connectionData: PropTypes.shape(connectionDataPropTypes),
  currentCity: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  }),
  loadingConnectionData: PropTypes.bool,

  setAndSaveConnectionState: PropTypes.func,
  setConnectionData: PropTypes.func,
  getConnectionData: PropTypes.func,

  onCityChange: PropTypes.func,
  onHouseChange: PropTypes.func,
  onStreetChange: PropTypes.func,
  onFlatChange: PropTypes.func,
  onClearHouse: PropTypes.func,
};

const mapStateToProps = (state) => ({
  connectionData: getConnectionData(state),
  currentCity: getCurrentRegion(state),
  loadingConnectionData: isConnectionDataLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  setConnectionData: (data) =>
    dispatch({ type: SET_FTTB_CONNECTION_DATA, payload: { connectionData: data } }),
  getConnectionData: () => dispatch(getConnectionStateInitial),
  setAndSaveConnectionState: (data) => dispatch(setAndSaveConnectionStateAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckAddressHOC);
