import React, { cloneElement, useReducer } from 'react';

// Context
import { ProductsContext } from './context';

// Reducer
import { initialState, reducer } from './reducer';

// eslint-disable-next-line import/no-mutable-exports
export let dispatcher = null;

const ProductsProvider = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  dispatcher = dispatch;

  return (
    <ProductsContext.Provider value={state}>
      {cloneElement(children, props)}
    </ProductsContext.Provider>
  );
};

export { ProductsProvider };
