import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames/bind';
import qs from 'query-string';
import { Button, Preloader, SmartForm, SmartInput } from '@beef/legacy-ui-kit';

import { unformatPhone } from 'utils/format-string';
import Captcha from 'pages/Feedback/components/Captcha/Captcha';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const pushGA = (popupType) => {
  window.dataLayer.push({
    event: 'app_popup',
    popupType,
  });
};

class SmsForm extends Component {
  state = {
    number: '+7 ',
    pending: false,
    error: null,
    captchaRequired: false,
    captcha: '',
    captchaError: false,
  };

  componentDidMount() {
    if (this.props.currentCtn) {
      this.setCurrentCtn(this.props.currentCtn);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentCtn && this.props.currentCtn && this.state.number.length < 4) {
      this.setCurrentCtn(this.props.currentCtn);
    }
  }

  onSubmit = (value) => {
    const { onStepChange } = this.props;
    const { number } = this.state;

    if (number.length !== 16) {
      return;
    }

    this.setState({ pending: true, error: null });

    axios(`/fancynumber/isphonebelongtocurrentregion/?phone=${unformatPhone(number)}`)
      .then(({ data }) => {
        if (!data.result && !data.homeRegion) {
          throw new Error('notBeeline');
        }

        return axios
          .post(
            this.props.sendMessageUrl,
            qs.stringify({
              ctn: unformatPhone(number),
              captcha: value.captcha,
          _captcha_key: value._captcha_key, // eslint-disable-line
            }),
          )
          .then((resp) => {
            const newState = { pending: false };

            if (resp.data.isCaptchaRequired && !this.state.captchaRequired) {
              newState.captchaRequired = true;
              return this.setState(newState);
            }

            if (resp.data.isCaptchaError) {
              newState.captchaError = true;
              return this.setState(newState);
            }

            if (!resp.data.isSuccess) {
              pushGA('error');
              onStepChange('finished', 'common');
              return;
            }

            pushGA('success');
            onStepChange('finished');
          });
      })
      .catch((error) => {
        if (error === 'notBeeline') {
          this.setState({ pending: false, error });
        } else {
          onStepChange('finished', error);
        }
      });
  };

  onNumberChange = (number) => {
    this.setState({ number, error: null });
  };

  setCurrentCtn = (currentCtn) => {
    this.setState({ number: currentCtn.accountText.replace(/-/g, ' ') });
  };

  handleCaptchaChange = (captcha) => {
    this.setState({ captcha, captchaError: false });
  };

  render() {
    const { texts, className } = this.props;
    const { number, captcha, error, captchaError, captchaRequired, pending } = this.state;

    return (
      <SmartForm
        className={cx('form', className)}
        onSubmit={this.onSubmit}
        validator={{
          captcha: (value) => ({
            status: value && value.length === 5 && !captchaError ? 'ok' : 'fail',
            msg: 'Неверные символы',
          }),
        }}
      >
        <div className={cx('input', error === 'notBeeline' && 'error')}>
          <SmartInput
            disabled={pending}
            inputId="number"
            keepInitialValue
            mask="+7 ___ ___ __ __"
            name="number"
            onChange={this.onNumberChange}
            size="big"
            value={number}
          />
          <div className={cx('tip')}>
            {error === 'notBeeline' ? texts.tipBeelineOnly : texts.tip}
          </div>

          {captchaRequired && (
            <div className={cx('captchaWrapper')}>
              <Captcha
                captchaKey="mobile-app-form"
                disabled={pending}
                forceTouch={captchaError}
                inputId="captcha"
                msg="Неверные символы"
                name="captcha"
                onChange={this.handleCaptchaChange}
              />
              <SmartInput
                inputId="_captcha_key"
                name="_captcha_key"
                type="hidden"
                value="mobile-app-form"
              />
            </div>
          )}
        </div>
        <div className={cx('button-wrapper')}>
          {pending ?
            <Preloader />
          : <Button
              className={cx('big')}
              disabled={
                number.length !== 16 ||
                !!error ||
                captchaError ||
                (captchaRequired && captcha.length !== 5)
              }
            >
              {texts.buttonText}
            </Button>
          }
        </div>
      </SmartForm>
    );
  }
}

SmsForm.defaultProps = { onStepChange: () => {} };

SmsForm.propTypes = {
  currentCtn: PropTypes.object,
  onStepChange: PropTypes.func,
  texts: PropTypes.shape({
    tipBeelineOnly: PropTypes.string,
    tip: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  className: PropTypes.any,
  sendMessageUrl: PropTypes.string,
};

export default SmsForm;
