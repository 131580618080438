import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@beef/ui-kit';

import { numberToHtml } from 'utils/fancyNumberBeautifier';

import { NumberShape } from '../../shapes';

/**
 * Renders fancy number with highlighting of combinations and with appropriate indents
 */
export const StyledNumber = ({ number, size = 'size5-r-s', highlightColor = '#FFE069', color }) => (
  <Text color={color} size={size}>
    {numberToHtml({
      number: {
        formattedValue: number.formattedValue,
        value: number.value,
        isBought: false,
      },
      color: highlightColor,
    })}
  </Text>
);

StyledNumber.propTypes = {
  number: PropTypes.shape(NumberShape),
  size: PropTypes.string,
  highlightColor: PropTypes.string,
  color: PropTypes.string,
};
