import { useCallback, useEffect, useRef, useState } from 'react';

export const usePlayAudio = ({ onPlay = () => {} }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!audioRef.current) return;

    audioRef.current.addEventListener('timeupdate', () => {
      setProgress(Math.floor(audioRef.current.currentTime));
    });

    audioRef.current.addEventListener('loadedmetadata', () => {
      setDuration(Math.floor(audioRef.current.duration));
    });

    audioRef.current.addEventListener('ended', () => {
      setProgress(duration);
      setIsPlaying(false);
    });
  }, [duration]);

  const handleTogglePlay = useCallback(() => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
      onPlay();
    }
  }, [isPlaying, onPlay]);

  const handleSetProgress = useCallback(
    (value) => {
      audioRef.current.currentTime = value;
      audioRef.current.play();
      setProgress(value);
      setIsPlaying(true);
      onPlay();
    },
    [onPlay],
  );

  return {
    togglePlay: handleTogglePlay,
    setProgress: handleSetProgress,
    isPlaying,
    progress,
    duration,
    audioRef,
  };
};
