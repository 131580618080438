import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { useIsMobile } from 'utils/hooks/useIsMobile';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SlidingDown = ({ show, children, disableOnMobile, className }) => {
  const showBool = !!show;
  const wrapperRef = useRef(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    const el = wrapperRef.current;

    if (disableOnMobile && isMobile) {
      if (showBool) {
        el.style.height = 'auto';
      } else {
        el.style.height = 0;
      }

      return;
    }

    if (showBool) {
      el.style.height = `${el.scrollHeight}px`;
    } else {
      el.style.height = `${el.offsetHeight}px`;
      el.offsetHeight; // trigger reflow
      el.style.height = 0;
    }
  }, [showBool, wrapperRef, disableOnMobile]);

  const onTransitionEnd = useCallback(() => {
    if (showBool) wrapperRef.current.style.height = 'auto';
  }, [showBool, wrapperRef]);

  return (
    <div className={cx('wrapper', className)} onTransitionEnd={onTransitionEnd} ref={wrapperRef}>
      {children}
    </div>
  );
};

SlidingDown.defaultProps = {
  show: false,
  disableOnMobile: false,
};
SlidingDown.propTypes = {
  show: PropTypes.bool,
  disableOnMobile: PropTypes.bool,
};

export default SlidingDown;
