import { useState } from 'react';
import { filter, map } from '@beef/utils';

import { RedirectOptions } from 'pages/Services/RedirectServiceCardLanding/store/redirect/types';
import {
  RedirectFormItem,
  RedirectFormOptions,
  TabType,
} from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/types';
import {
  getDefaultRedirectFormOptions,
  getPhoneWithoutFormat,
} from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/utils';
import { isFilledPhone } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/utils/isFilledPhone';
import {
  defaultOptions,
  optionsListForByConditions,
} from 'pages/Services/RedirectServiceCardLanding/const';
import {
  LoadingType,
  RedirectSettingsContent,
} from 'pages/Services/RedirectServiceCardLanding/types';

export const useModalSettings = ({
  optionsStore,
  loading,
  content,
  saveData,
}: {
  content: RedirectSettingsContent;
  loading: LoadingType;
  optionsStore: RedirectOptions;
  saveData: (v: {
    dataForStartRequest: Partial<RedirectOptions>;
    options: RedirectOptions;
  }) => unknown;
}) => {
  const initialTab = optionsStore?.redirectByConditions ? 'redirectByConditions' : 'redirectAlways';
  const [activeTab, setActiveTab] = useState<TabType>(initialTab);
  const defaultRedirectFormOptions = getDefaultRedirectFormOptions(optionsStore);
  const [options, setOptions] = useState<RedirectFormOptions>(defaultRedirectFormOptions);
  const headerText: string | undefined =
    !['succeeded', 'failed'].includes(loading) ? content?.title : undefined;

  const onChangeTab = (tab: string | string[]) => {
    setActiveTab(tab as TabType);
  };

  const onChangePhone = (value: RedirectFormItem) => {
    setOptions((o) => ({
      ...o,
      [activeTab]: value,
    }));
  };

  const onChangeActive = (name: string) => {
    setOptions((o) => ({
      ...o,
      [activeTab]: {
        ...o[activeTab],
        [name]: o[activeTab][name] === null ? '' : null,
      },
    }));
  };

  const onClear = (name: string) => {
    setOptions((o) => ({
      ...o,
      [name]: undefined,
    }));
  };

  const onSubmitHandle = ({
    dataForStartRequest,
    options: opt,
  }: {
    dataForStartRequest: Partial<RedirectOptions>;
    options: RedirectOptions;
  }) => {
    saveData({ dataForStartRequest, options: opt });
  };

  const formProps = {
    loading,
    content,
    onClear,
    onChangePhone,
    onSubmitHandle,
    onChangeActive,
  };

  return {
    activeTab,
    options,
    onChangeTab,
    headerText,
    formProps,
  };
};

export const useFormRedirectByConditions = ({
  options,
  onChangePhone,
  onSubmitHandle,
}: {
  onChangePhone: (v: RedirectFormItem) => void;
  onSubmitHandle: (v: {
    dataForStartRequest: Partial<RedirectOptions>;
    options: RedirectOptions;
  }) => void;
  options: RedirectFormItem;
}) => {
  const getValidators = () => {
    const activeOptions = filter(options, (v) => v !== null);
    return map(
      activeOptions,
      () => (value: string) =>
        !isFilledPhone(value || '') ? 'Введите номер для переадресации' : undefined,
    );
  };

  const onSubmit = () => {
    const newOptionsByConditions = {
      ...map(options, getPhoneWithoutFormat),
      redirectByConditions: true,
    };
    const dataForStartRequest = newOptionsByConditions;
    const newOptions = { ...defaultOptions, doNotRedirect: false, ...newOptionsByConditions };
    onSubmitHandle({
      dataForStartRequest,
      options: newOptions,
    });
  };

  const isDisabledSubmitButton = !(
    options.phoneIfNoAnswer !== null ||
    options.phoneIfUnavailable !== null ||
    options.phoneIfBusy !== null
  );

  const onChangeForm = (value: object | undefined) => {
    onChangePhone(value as RedirectFormItem);
  };

  return {
    getValidators,
    onSubmit,
    isDisabledSubmitButton,
    optionsType: optionsListForByConditions,
    onChangeForm,
  };
};
