import React, { FC } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames/bind';
import { Accordion } from '@carnica/ui-kit';

import { CAccordionItem, TCAccordionItem } from '../CAccordionItem';
// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { useCAccordion } from './hooks/useCAccordion';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCAccordion = {
  info?: TCAccordionItem[];
  isWithoutPay?: boolean;
};

/** Компонент с заголовком и аккордеоном с подробной информацией по списаниям */
export const CAccordion: FC<TCAccordion> = ({ info, isWithoutPay }) => {
  /* Подготовленные контентные данные для аккордеона */
  const { color, title } = useCAccordion(isWithoutPay);

  return info?.length ?
      <motion.div layout>
        <Accordion
          className={cx('wrapper', { 'wrapper--white': isWithoutPay })}
          label={title}
          size="s"
        >
          <div className={cx('wrapper__item')}>
            {info?.map(({ entityName, price }) => (
              <CAccordionItem key={entityName} {...{ color, entityName, price }} />
            ))}
          </div>
        </Accordion>
      </motion.div>
    : null;
};
