import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'query-string';
import { Button, InputsRow, Label, SmartForm, SmartInput, Status } from '@beef/legacy-ui-kit';

import { unformatPhone } from 'utils/format-string';
import { FIELDS_CONFIG } from 'utils/ConvertToEsimServiceHelper';
import { validators } from 'utils/simReplacement';
import Captcha from 'pages/Feedback/components/Captcha/Captcha';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const InputRender = (props) => {
  if (props.type === 'info') {
    return <div>{props.infoText}</div>;
  }

  return <SmartInput {...props} />;
};

const RestoreAccessForm = ({ onResult, data }) => {
  const [formData, setFormData] = useState({});
  const [status, setStatus] = useState('initial');
  const [error, setError] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [showCaptchaError, setShowCaptchaError] = useState(false);
  const [restorePhoneError, setRestorePhoneError] = useState('');
  const [captchaParameters] = useState({
    templateName: 'zamenaNaEsim',
    _captcha_key: 'zamena-na-esim',
  });

  useEffect(() => {
    const formConfig = FIELDS_CONFIG.reduce((acc, curr) => {
      if (curr.group) {
        const groupConfig = curr.group.reduce((groupAcc, groupCurr) => {
          if (groupCurr.type === 'info') {
            return { ...groupAcc };
          }
          return { ...groupAcc, [groupCurr.name]: '' };
        }, {});
        return { ...acc, ...groupConfig };
      }

      return { ...acc, [curr.name]: '' };
    }, {});

    setFormData(formConfig);
  }, []);

  const checkIsBeeline = useCallback((val) => {
    setRestorePhoneError('');
    if (unformatPhone(val).length !== 10) {
      return;
    }
    axios.post(data.checkNumberUrl, { ctn: unformatPhone(val) }).then((res) => {
      if (res?.data?.isBeelineNumber === false) {
        setRestorePhoneError(data.checkNumberFailedMessage);
      }
    });
  }, []);

  const handleInput = (name, val) => {
    if (name === 'ctn') {
      checkIsBeeline(val);
    }

    setFormData({
      ...formData,
      [name]: val,
    });
  };

  const handleSubmit = () => {
    setStatus('pending');
    const phoneNumbers = {
      ctn: unformatPhone(formData.ctn),
      contactPhone: unformatPhone(formData.contactPhone),
    };

    const formattedData = {
      ...formData,
      ...phoneNumbers,
      _captcha_key: captchaParameters._captcha_key,
    };

    axios
      .post(data.postUrl, qs.stringify(formattedData))
      .then((res) => {
        if (res?.data?.isSucceeded) {
          setStatus('success');
          onResult({
            title: 'Заявка принята',
            buttonText: 'Закрыть',
            titleIcon: 'https://static.beeline.ru/upload/images/emoji/statusOk.svg',
            description: 'В ближайшее время с вами свяжется наш сотрудник',
          });
        } else {
          setStatus('initial');
          setError(data.resultStatusTexts.attention);

          if (!showCaptcha && res?.data?.isCaptchaRequired) {
            setShowCaptcha(true);
          }

          if (res?.data?.isCaptchaError) {
            setShowCaptchaError(true);
          }
        }
      })
      .catch(() => {
        setStatus('initial');
        setError(data.resultStatusTexts.attention);
      });
  };

  return (
    <div className={cx('wrap')}>
      <SmartForm autoComplete="off" disableIfInvalid onSubmit={handleSubmit} validator={validators}>
        {FIELDS_CONFIG.map((field) => (
          <InputsRow groups={2} key={field.label}>
            <div className={cx('group')}>
              <div className={cx('label')}>
                <Label>{field.label}</Label>
              </div>
              <div className={cx('body')}>
                {field.group?.length ?
                  <div className={cx('groupRow')}>
                    {field.group.map((groupItem) => (
                      <div
                        className={cx('groupRowItem')}
                        key={groupItem.name}
                        style={{ flex: `${groupItem.width} 1 40px` }}
                      >
                        <InputRender
                          {...groupItem}
                          disabled={status === 'pending'}
                          id={groupItem.name}
                          inputId={groupItem.name}
                          onChange={(val) => {
                            handleInput(groupItem.name, val);
                          }}
                          value={formData[groupItem.name]}
                        />
                      </div>
                    ))}
                  </div>
                : <InputRender
                    {...field}
                    disabled={status === 'pending'}
                    id={field.name}
                    inputId={field.name}
                    onChange={(val) => {
                      handleInput(field.name, val);
                    }}
                    value={formData[field.name]}
                  />
                }
              </div>
            </div>
            {field.name === 'ctn' && restorePhoneError && (
              <div className={cx('restorePhoneError')}>
                <Status className={['error', cx('checkNumberStatus')]} emoji="statusFail">
                  {data.checkNumberFailedMessage}
                </Status>
              </div>
            )}
          </InputsRow>
        ))}
        {showCaptcha && (
          <div className={cx('group')}>
            <div className={cx('label')} />
            <div className={cx('body')}>
              <Captcha
                captchaKey={captchaParameters._captcha_key}
                disabled={status === 'pending'}
                height={51}
                inputClass={cx('captchaWrap')}
                inputId="captcha"
                name="captcha"
                onChange={(val) => {
                  handleInput('captcha', val);
                }}
                onFocus={() => {
                  if (showCaptchaError) {
                    setShowCaptchaError(false);
                  }
                }}
                refreshCaptcha
                size="big"
                status={showCaptchaError ? 'fail' : 'ok'}
                width={150}
              />
            </div>
          </div>
        )}
        <div className={cx('submit')}>
          <Button className="big" disabled={status === 'pending' || !!restorePhoneError}>
            {data.btnText}
          </Button>
        </div>
      </SmartForm>
      {error && <Status className="error">{error}</Status>}
    </div>
  );
};

InputRender.propTypes = {
  type: PropTypes.string,
  infoText: PropTypes.string,
};

RestoreAccessForm.propTypes = {
  data: PropTypes.shape({
    alias: PropTypes.string,
    btnText: PropTypes.string,
    checkNumberFailedMessage: PropTypes.string,
    checkNumberUrl: PropTypes.string,
    postUrl: PropTypes.string,
    resultStatusTexts: PropTypes.shape({
      attention: PropTypes.string,
      error: PropTypes.string,
      success: PropTypes.string,
    }),
    successTexts: PropTypes.shape({
      buttonText: PropTypes.string,
      buttonUrl: PropTypes.any,
      description: PropTypes.string,
      title: PropTypes.string,
    }),
    tabName: PropTypes.string,
  }),
  onResult: PropTypes.func,
};

export default RestoreAccessForm;
