import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { connect } from 'react-redux';

import { translate } from 'utils/format-string';

export class Text extends Component {
  replace = () => {
    const string = path(this.props.path, this.props.data);
    const { variables } = this.props;

    if (!string) return '';
    if (!variables) return string;

    return string.replace(/{{(\w+)}}/gi, (full, match) =>
      typeof variables[match] !== 'undefined' ? variables[match] : '',
    );
  };

  render() {
    const { declension } = this.props;

    let text = this.replace();
    if (declension) {
      const word = translate(declension.word, declension.count);
      text = text.replace(declension.text, word);
    }

    return <span className={this.props.className} dangerouslySetInnerHTML={{ __html: text }} />;
  }
}

Text.defaultProps = {
  className: 'textContainer',
};

Text.propTypes = {
  path: PropTypes.array,
  data: PropTypes.object,
  variables: PropTypes.object,
  declension: PropTypes.shape({
    word: PropTypes.string,
    count: PropTypes.number,
    text: PropTypes.string,
  }),
  className: PropTypes.any,
};

export default connect((state) => {
  const textPath = path(['meta', 'textPath'], state) || ['external', 'tariff', 'data', 'sharing'];
  return {
    data: path(textPath, state),
  };
})(Text);
