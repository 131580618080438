import { composeStoreInitializer } from '@beef/redux';
import { combineReducers } from '@reduxjs/toolkit';

import { redirectSlice } from 'pages/Services/RedirectServiceCardLanding/store/redirect';

export const initializeRedirectServiceCardLanding = composeStoreInitializer(
  combineReducers({
    content: (state = {}) => state,
    redirect: redirectSlice.reducer,
  }),
);

const store = initializeRedirectServiceCardLanding();
export type RedirectStore = ReturnType<typeof store.getState>;
