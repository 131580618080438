import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

import styles from './styles.pcss';

const LottieAnimation = ({ loop = true, name, animationData, callbackFunc, ...props }) => {
  const options = {
    loop,
    animationData,
    autoplay: true,
    rendererSettings: {
      className: styles.lottieAnimation,
    },
  };

  useEffect(() => {
    callbackFunc(name);
  }, [name]);

  return animationData ? <Lottie options={options} /> : null;
};

LottieAnimation.propTypes = {
  loop: PropTypes.bool,
  name: PropTypes.string,
  callbackFunc: PropTypes.func,
  animationData: PropTypes.object,
};

export default LottieAnimation;
