import { AnyAction, Dispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { RootState } from 'constructorV1/store';
import { selectTariffSoc } from 'constructorV1/store/selectors/selectTotal';
import { selectGbValue } from 'constructorV1/components/tempComponents/GbPresetContainer/selectors/selectGbValue';
import { selectMinValue } from 'constructorV1/components/tempComponents/MinPresetContainer/selectors/selectMinValue';
import {
  setDataFromChangePacket,
  setDataFromPackets,
  setOptionsSocs,
} from 'constructorV1/store/totalSlice';
import { prepareActiveOptionsSocs } from 'constructorV1/utils/prepareActiveOptionsSocs';
import { getPackets } from 'constructorV1/store/tariffSettingsSlice/api/getPackets';
import { getOptions } from 'constructorV1/store/tariffSettingsSlice/api/getOptions';
import { changePacket } from 'constructorV1/store/tariffSettingsSlice/api/changePacket';
import { selectIsFamilyConnected } from 'constructorV1/store/selectors/selectUser';
import { selectAlertsData } from 'constructorV1/components/tempComponents/AlertContainer/selectors/selectAlertsData';
import { prepareSelectedAlerts } from 'constructorV1/store/tariffSettingsSlice/utils/prepareSelectedAlerts';

import { TTariffSettingsSlice } from './types';

const initialState: TTariffSettingsSlice = {
  isOptionsLoading: true,
  isPacketsLoading: true,
  /* Объект с настройками пресета Гб */
  stepsInternet: null,
  /* Объект с настройками пресета Мин */
  stepsMinutes: null,
  /* Объект с доступными секциями опций (трафик, допы) */
  sections: null,
  /* Массив бонусов тарифа */
  bonuses: undefined,
  /* Скидка тарифа */
  discountPercent: null,
  /* Массив подсказок (ограничения нулевых пресетов, отключение, обновление тарифа и т.п.) */
  selectedAlerts: null,
  /* Базовый конструктор */
  initialData: null,
};

/** Вспомогательный экшен для обработки начальных данных */
export const getInitialData = createAsyncThunk<
  Promise<void>,
  void,
  {
    dispatch: Dispatch;
    state: RootState;
  }
>('getInitialData', async (_, { dispatch, getState }) => {
  const soc = selectTariffSoc(getState());
  const isFamilyConnected = selectIsFamilyConnected(getState());
  const alerts = selectAlertsData(getState());

  try {
    const { data, success } = await dispatch(getPackets({ soc }) as unknown as AnyAction).unwrap();

    if (!data || !success) {
      throw new Error();
    }

    const {
      stepsInternet,
      stepsMinutes,
      constructorId,
      priceWithDiscount,
      price,
      bonuses,
      discountPercent,
    } = data;
    const gbValue = stepsInternet.initialSize;
    const minValue = stepsMinutes.initialSize;

    /* Устанавливаем данные по пакетам в tariffSettingSlice */
    dispatch(setPackets({ stepsInternet, stepsMinutes }));
    const selectedAlerts = prepareSelectedAlerts({
      gbValue,
      minValue,
      isFamilyConnected,
      discountPercent,
      alerts,
    });
    /* Устанавливаем бонусы в tariffSettingSlice */
    dispatch(setBonusesAndDiscountPercent({ bonuses, discountPercent, selectedAlerts }));
    /* Устанавливаем данные из пакетов в totalSlice */
    dispatch(setDataFromPackets({ constructorId, priceWithDiscount, price, gbValue, minValue }));

    const { data: optionsData, success: optionsSuccess } = await dispatch(
      getOptions({ soc, gbValue, minValue }) as unknown as AnyAction,
    ).unwrap();
    if (!optionsData || !optionsSuccess) {
      throw new Error();
    }

    const { sections } = optionsData;

    /* Установили опции в tariffSettingsSlice */
    dispatch(setToggleSections(sections));

    const optionsSocs = prepareActiveOptionsSocs({ sections });
    /* Устанавливаем активные опции в total */
    dispatch(setOptionsSocs(optionsSocs));
    /* Сохраняем данные для дальнейшей проверки "мой тариф" */
    dispatch(
      setInitialData({
        gbValue,
        minValue,
        optionsSocs,
      }),
    );
  } catch (e) {
    throw new Error();
  }
});

/** Вспомогательный экшен для обработки смены пресета и запроса опций */
export const changePacketAndOptionsData = createAsyncThunk<
  Promise<void>,
  void,
  {
    dispatch: Dispatch;
    state: RootState;
  }
>('changePacketAndOptionsData', async (_, { dispatch, getState, signal }) => {
  const soc = selectTariffSoc(getState());
  const gbValue = selectGbValue(getState());
  const minValue = selectMinValue(getState());
  const isFamilyConnected = selectIsFamilyConnected(getState());
  const alerts = selectAlertsData(getState());

  try {
    /* @ts-expect-error ошибка типизации store */
    const promise = await dispatch(changePacket({ soc, gbValue, minValue, signal })).unwrap();

    const { data, success } = promise;

    if (!data || !success) {
      throw new Error();
    }

    const { constructorId, priceWithDiscount, price, bonuses, discountPercent } = data;

    /* Устанавливаем данные из пакетов в totalSlice */
    dispatch(setDataFromChangePacket({ constructorId, priceWithDiscount, price }));

    const selectedAlerts = prepareSelectedAlerts({
      gbValue,
      minValue,
      isFamilyConnected,
      discountPercent,
      alerts,
    });

    /* Устанавливаем бонусы в tariffSettingSlice */
    dispatch(setBonusesAndDiscountPercent({ discountPercent, bonuses, selectedAlerts }));

    const { data: optionsData, success: optionsSuccess } = await dispatch(
      getOptions({ soc, gbValue, minValue, signal }) as unknown as AnyAction,
    ).unwrap();
    if (!optionsData || !optionsSuccess) {
      throw new Error();
    }

    const { sections } = optionsData;

    /* Установили опции в tariffSettingsSlice */
    dispatch(setToggleSections(sections));

    const optionsSocs = prepareActiveOptionsSocs({ sections });
    /* Устанавливаем активные опции в total */
    dispatch(setOptionsSocs(optionsSocs));
  } catch (e) {
    throw new Error();
  }
});

/** Slice мобильных опций тарифа */
export const tariffSettingsSlice = createSlice({
  name: 'tariffSettings',
  initialState,
  reducers: {
    /* Изменение состояний опций */
    setToggleSections: (state, action) => {
      state.sections = action.payload;
    },
    setPackets: (state, action) => {
      state.stepsInternet = action.payload.stepsInternet;
      state.stepsMinutes = action.payload.stepsMinutes;
    },
    setBonusesAndDiscountPercent: (state, action) => {
      state.bonuses = action.payload.bonuses;
      state.discountPercent = action.payload.discountPercent;
      state.selectedAlerts = action.payload.selectedAlerts;
    },
    setInitialData: (state, action) => {
      state.initialData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /* Запрос на получение пресетов и опций */
      .addCase(getInitialData.rejected, (state) => {
        state.isOptionsLoading = false;
        state.isPacketsLoading = false;
      })
      .addCase(getInitialData.pending, (state) => {
        state.isOptionsLoading = true;
        state.isPacketsLoading = true;
      })
      .addCase(getInitialData.fulfilled, (state) => {
        state.isOptionsLoading = false;
        state.isPacketsLoading = false;
      })
      /* Запрос на смену пресетов и получение опций */
      .addCase(changePacketAndOptionsData.rejected, (state, action) => {
        state.isOptionsLoading = action.error.message === 'Aborted';
      })
      .addCase(changePacketAndOptionsData.pending, (state) => {
        state.isOptionsLoading = true;
      })
      .addCase(changePacketAndOptionsData.fulfilled, (state) => {
        state.isOptionsLoading = false;
      });
  },
});

export const { setToggleSections, setPackets, setBonusesAndDiscountPercent, setInitialData } =
  tariffSettingsSlice.actions;

export const tariffSettingsReducer = tariffSettingsSlice.reducer;
