import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TDescriptionCell = {
  description?: string;
  title?: string;
};

/** "Ячейка" с названием м описанием пассивки */
export const DescriptionCell: FC<TDescriptionCell> = ({ title, description }) => (
  <li className={cx('wrapper')}>
    {title && <Text size="size5-r-s">{title}</Text>}
    {description && (
      <div className={cx('wrapper__description')}>
        <Text size="size5-r-s">{description}</Text>
      </div>
    )}
  </li>
);
