import { sendYandexMetrika } from './main2021';

/** Клик на Подключить, Хочу этот тариф */
const mobTariffDetailConnectYM = () =>
  sendYandexMetrika('MobTariffDetailConnect', { URL9: document.location.href });

/** Клик на Далее на странице конструктора */
const convergentToConnectUpFormYM = () =>
  sendYandexMetrika('ConvergentToConnectUpForm', { URL9: document.location.href });

/** Показ страницы "Заявка отправлена" */
const convergentCallbackUpForm = () => sendYandexMetrika('ConvergentCallbackUpForm', {});

/** Добавление Сим карты в корзину Клик на Купить */
const addToCartSimCardYM = (url) => sendYandexMetrika('AddToCartSimCard', { URL10: url });

/** Перенести номер в билайне Клики на Начать переход */
const addToCartMNPSimCardYM = (url) => sendYandexMetrika('AddToCartMNPSimCard', { URL11: url });

/** Изменить свой тариф Клик на Отправить */
const mobTariffDetailSMSConnectYM = (url) =>
  sendYandexMetrika('MobTariffDetailSMSConnect', { URL12: url });

/** Успешная активация Яндекс Плюс after 27.04 */
const yandexPlusConnectedYMUpd = (service, connectedService) => {
  sendYandexMetrika('ServiceConnectSuccess', {
    service_ev_: {
      service_connect: {
        [service]: connectedService,
      },
    },
  });
};

/** Успешное отключение Яндекс Плюс after 27.04 */
const yandexPlusDisconnectedSuccessYMUpd = (service, connectedService) => {
  sendYandexMetrika('ServiceDisconnectSuccess', {
    service_ev_: {
      service_connect: {
        [service]: connectedService,
      },
    },
  });
};

/** Отключение тарифа */
const yandexTariffDisconnectedYM = (url, tariffName) => {
  sendYandexMetrika('TariffDisconnectSuccess', {
    TariffDisconnect: {
      [tariffName]: url,
    },
  });
};

/** Показ окна смены Семейного тарифа */
const showChangeTariffFormYM = (currentTariffName, newTariffName, url) => {
  sendYandexMetrika('TariffChangeSuccess', {
    TariffChange: {
      [currentTariffName]: {
        [newTariffName]: url,
      },
    },
  });
};

/** Успех подключения тарифа */
const yandexTariffConnectedSuccessYM = (url, tariffName) => {
  sendYandexMetrika('TariffConnectSuccess', {
    TariffConnect: { [tariffName]: url },
  });
};

const TARIFF_TYPE = {
  fttb: 'fttb',
  conv: 'conv',
};

const tariffTypeYM = {
  _type: TARIFF_TYPE.fttb,
  set(type) {
    this._type = type;
  },
  get value() {
    return this._type;
  },
};

/** Все клики на страницах ШПД */
const tariffClickYM = () =>
  sendYandexMetrika(
    tariffTypeYM.value === TARIFF_TYPE.fttb ? 'HomeTariffConnect' : 'ConvergentTariffConnect',
    {
      URL: document.location.href,
    },
  );

/** Клики на форму обратного звонка на ШПД */
const callbackFormClickYM = (isIcon, isConvergent) =>
  sendYandexMetrika(isConvergent ? 'ConvergentTariffCallbackShortForm' : 'HomeTariffCallbackForm', {
    URL: document.location.href,
    form_type: isIcon ? 'icon' : 'popup',
  });

/** Клики на техническую поддержку ШПД */
const technicalSupportClickYM = (isConvergent) =>
  sendYandexMetrika(isConvergent ? 'ConvergentTariffTechQuestion' : 'HomeTariffTechQuestion', {
    URL: document.location.href,
  });

const longFormSubmitNewYM = (orderId) =>
  sendYandexMetrika(
    'ConvergentTariffCallbackLongFormNew',
    // eslint-disable-next-line camelcase
    { tariff_convergent_request_dm_: orderId },
  );

const longFormSubmitExistYM = (orderId) =>
  sendYandexMetrika(
    'ConvergentTariffCallbackLongFormExist',
    // eslint-disable-next-line camelcase
    { tariff_convergent_request_dm_: orderId },
  );

export {
  TARIFF_TYPE,
  addToCartMNPSimCardYM,
  addToCartSimCardYM,
  callbackFormClickYM,
  convergentCallbackUpForm,
  convergentToConnectUpFormYM,
  longFormSubmitExistYM,
  longFormSubmitNewYM,
  mobTariffDetailConnectYM,
  mobTariffDetailSMSConnectYM,
  showChangeTariffFormYM,
  tariffClickYM,
  tariffTypeYM,
  technicalSupportClickYM,
  yandexPlusConnectedYMUpd,
  yandexPlusDisconnectedSuccessYMUpd,
  yandexTariffConnectedSuccessYM,
  yandexTariffDisconnectedYM,
};
