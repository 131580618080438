import React, { memo } from 'react';
import classnames from 'classnames/bind';

import Twix from './twix.svg';
import Pizza from './pizza.svg';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const Button = memo(({ onClick, type = 'play', className }) => (
  <button className={cx('button', className)} onClick={onClick} type="button">
    {type === 'play' ?
      <Pizza className={cx('pizza')} />
    : <Twix className={cx('twix')} />}
  </button>
));
