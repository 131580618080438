import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import styles from './styles.pcss';
import { withDownloadButton } from '../../enhancers/withDownoadButton';
import { IInfo } from '../../types';
import { BottomLayout, CenterLayout } from './components';
import { TextLayout } from '../../layout';

const cn = classNames.bind(styles);

export const InfoPure: React.FC<IInfo> = ({
  title,
  subTitle,
  imageUrl,
  isBottomImg,
  mobileImageUrl,
  titleMobile,
  subTitleMobile,
  hideGradient,
}) => {
  const isMobile = useIsMobileDimension();

  return (
    <div className={cn('info')}>
      <TextLayout>
        <Text className={cn('title')} size={isMobile ? 'l' : '2xl'}>
          {isMobile ? titleMobile : title}
        </Text>
        <Text className={cn('sub-title')} color="secondary" size={isMobile ? 'xs' : 'm'}>
          {isMobile ? subTitleMobile : subTitle}
        </Text>
      </TextLayout>
      <BottomLayout className={cn('fake-img')} shouldRender={isBottomImg}>
        <img alt="" className={cn('img', 'bottom-img', isMobile && 'mobile')} src={imageUrl} />
      </BottomLayout>
      <CenterLayout shouldRender={!isBottomImg}>
        <img
          alt=""
          className={cn('img', isMobile && 'mobile')}
          src={(isMobile && mobileImageUrl) || imageUrl}
        />
      </CenterLayout>

      {isBottomImg && !hideGradient && <div className={cn('gradient')} />}
    </div>
  );
};

export const Info = withDownloadButton(false)(InfoPure);
