import { TServiceTile } from '../../../tempComponents/ServiceTile/types';
import {
  TCommonEquipment,
  TCommonInternetServices,
  THiddenServices,
  TPriceInfo,
  TServicesTariff,
} from '../../../store/slices/fttbSlice/types';

type TValues =
  | TCommonEquipment[]
  | TCommonInternetServices[]
  | THiddenServices[]
  | TPriceInfo
  | TServiceTile
  | string;

/* Ключи не требующие обхода для суммирования */
const EXCEPTIONS = ['inacId', 'priceInfo', 'hiddenServices'];

/** Вспомогательная ф-я для суммирования цен функции getFttbServiceAndEquipmentPrice */
const getSumPrices = (accPrice: number, accOldPrice: number, price = 0, oldPrice = 0) => ({
  price: Number(accPrice) + Number(price),
  oldPrice: accOldPrice + oldPrice,
});

/** Вспомогательная ф-я для getFttbServiceAndEquipmentPrice выбирает поля, которые требуются для суммирования */
const getItemPrice = (
  acc: Record<'oldPrice' | 'price', number>,
  item: Record<string, any>,
  servicesInacIdList?: string[],
) => {
  /* Выходим, если нет inacId в списке активных */
  if (!servicesInacIdList?.includes(item.inacId)) {
    return { price: acc.price, oldPrice: acc.oldPrice };
  }

  /* Переменная описывают путь к возможным полям с ценами */
  const infoPrice = item.info?.infoPrice ?? item.infoPrice ?? item;

  return getSumPrices(acc.price, acc.oldPrice, infoPrice.price, infoPrice.oldPrice);
};

/** Ф-я обходит массив сервисов и оборудования и возвращает акумулированную цену,
 *  вспомогательная для getFttbServiceAndEquipmentPrice   */
const getItemPrices = (value: any[], servicesInacIdList: string[]) =>
  value.reduce(
    (acc: Record<'oldPrice' | 'price', number>, item: Record<string, any>) =>
      getItemPrice(acc, item, servicesInacIdList),
    { price: 0, oldPrice: 0 },
  );

/** Функция предварительного расчета ШПД сервисов */
export const getFttbServiceAndEquipmentPrice = (
  currentTariff?: TServicesTariff,
  servicesInacIdList?: string[],
) => {
  /* Выходим с нулевыми ценами если нет информации по тарифу или нет активных inacId */
  if (!(currentTariff && Object.entries(currentTariff).length && servicesInacIdList?.length)) {
    return { price: 0, oldPrice: 0 };
  }

  return Object.entries(currentTariff).reduce(
    (acc: Record<'oldPrice' | 'price', number>, [key, value]: [string, TValues]) => {
      /* Выходим, если поля не требуют подсчета */
      if (!EXCEPTIONS.includes(key) && typeof value !== 'string' && typeof value !== null) {
        /* Если value - массив, то аккумулируем суммы в редьюсере используя getItemPrices. */
        /* Если нет, то накапливаем цены напрямую через getItemPrice */
        const itemPrices =
          Array.isArray(value) ?
            getItemPrices(value, servicesInacIdList)
          : getItemPrice({ price: 0, oldPrice: 0 }, value, servicesInacIdList);

        return getSumPrices(acc.price, acc.oldPrice, itemPrices.price, itemPrices.oldPrice);
      }
      return acc;
    },
    { price: 0, oldPrice: 0 },
  );
};
