import { createSelector } from '@reduxjs/toolkit';

import { FILTER_VALIDATORS } from '../../validators';

const getFilterState = (state) => state.filter;

export const getActiveFilter = createSelector(getFilterState, (state) => state.active);
export const getQueryValue = createSelector(getFilterState, (state) => state.query);
export const getDefCodeValue = createSelector(getFilterState, (state) => state.defCode);
export const getIsQueryValid = createSelector(
  getActiveFilter,
  getQueryValue,
  (filter, query) => FILTER_VALIDATORS[filter]?.(query) || false,
);
export const getNotifications = (state) => getFilterState(state).notifications;
