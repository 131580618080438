import { connect } from 'react-redux';

import { selectContent } from '../../store/selectors';
import { STORIES_EXAMPLE_ALIAS } from '../../constants';
import { HeaderScrollDown } from '../HeaderScrollDown';

const mapStateToProps = (state) => ({
  ...selectContent(state).employeesBanner,
  scrollToId: STORIES_EXAMPLE_ALIAS,
});

export const EmployeesBanner = connect(mapStateToProps)(HeaderScrollDown);
