import { useEffect, useState } from 'react';

import { TUseGetTotalBill } from '../types';
import { useAppDispatch } from '../../../../store';
import { useSelectParams } from '../selectors/useSelectParams';
import { TFetchTotalBillQueryRes, getTotalBill } from '../../../../api/getTotalBill';

/** Получение данных по total чеку. Полный подсчет и формирование данных происходит на беке */
export const useGetTotalBill = () => {
  const dispatch = useAppDispatch();
  const params = useSelectParams();
  const [result, setResult] = useState<TUseGetTotalBill>(null);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return undefined;
    }

    /* Запрос total чека */
    const promise = dispatch(getTotalBill(params as Parameters<typeof getTotalBill>[0]));
    promise
      .unwrap()
      .then((data: TFetchTotalBillQueryRes) =>
        setResult(
          data?.commonBill ? { ...data.commonBill, requestParams: data.requestParams } : null,
        ),
      )
      .catch(() => setResult(null));

    return () => {
      promise.abort();
    };
  }, []);

  return result;
};
