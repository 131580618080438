import { RedirectFormOptions } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/types';
import { RedirectOptions } from 'pages/Services/RedirectServiceCardLanding/store/redirect/types';

export const getDefaultRedirectFormOptions = (options: RedirectOptions): RedirectFormOptions => ({
  redirectAlways: {
    phoneAlwaysRedirect: options.phoneAlwaysRedirect || '',
  },
  redirectByConditions: {
    phoneIfBusy: options.phoneIfBusy || '',
    phoneIfNoAnswer: options.phoneIfNoAnswer || '',
    phoneIfUnavailable: options.phoneIfUnavailable || '',
  },
});

export const getPhoneWithoutFormat = (phone: null | string) =>
  phone?.replace(/\+7|\D/g, '') || null;
