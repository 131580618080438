import React, { useLayoutEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useIsMobileDimension } from '@beef/ui-kit';
import { Skeleton as UIKitSkeleton } from '@carnica/ui-kit';

import { IComponentStoreLinks, Stores } from './types';
import styles from './styles.pcss';
import { Android, IOS } from '../../../CarnicaDSLandings/utils/PlatformContainers';
import { IMG_URLS } from './constants';
import { Metric } from '../../metric';

const cx = classNames.bind(styles);

export const StoresIcons: React.FC<IComponentStoreLinks> = ({
  storeLinks,
  isLightColor,
  screenTitle,
}) => {
  const isMobile = useIsMobileDimension();

  const [canRender, setCanRender] = useState(false);
  const [isShow, setIsShow] = useState(false);

  useLayoutEffect(() => {
    setCanRender(true);
    setTimeout(() => setIsShow(true), 200);
  }, []);

  if (!canRender) {
    return <UIKitSkeleton.Chips className={cx('item')} />;
  }

  return (
    <div className={cx('stores', isShow && 'isShow')}>
      <IOS>
        {(isMobile && storeLinks[Stores.IOS] && (
          <Metric
            asChild
            data={JSON.stringify({
              title: screenTitle,
              iconName: 'App Store',
            })}
            events={{ mobileApp2024Metric: ['handleAppButtonClick'] }}
            mode="click"
          >
            <a
              aria-label={Stores.IOS}
              className={cx(
                'stores__icon-full-width',
                isLightColor && 'stores__icon-full-width-light',
              )}
              href={storeLinks[Stores.IOS]}
            >
              <img
                alt="App Store"
                className={cx('')}
                src={isLightColor ? IMG_URLS.IOS_FULL_DARK : IMG_URLS.IOS_FULL_LIGHT}
              />
            </a>
          </Metric>
        )) ||
          null}
      </IOS>
      <Android>
        {(storeLinks[Stores.RuStore] && (
          <Metric
            asChild
            data={JSON.stringify({
              title: screenTitle,
              iconName: 'RuStore',
            })}
            events={{ mobileApp2024Metric: ['handleAppButtonClick'] }}
            mode="click"
          >
            <a
              aria-label={Stores.RuStore}
              className={cx('stores__icon', 'stores__ru-store')}
              href={storeLinks[Stores.RuStore]}
            >
              <img
                alt="RuStore"
                className={cx('stores__icon-img')}
                height="56px"
                src={IMG_URLS.RU_STORE}
                width="56px"
              />
            </a>
          </Metric>
        )) ||
          null}
      </Android>
      <Android>
        {(storeLinks[Stores.Android] && (
          <Metric
            asChild
            data={JSON.stringify({
              title: screenTitle,
              iconName: 'Google Play',
            })}
            events={{ mobileApp2024Metric: ['handleAppButtonClick'] }}
            mode="click"
          >
            <a
              aria-label={Stores.Android}
              className={cx(
                'stores__icon',
                'stores__android',
                isLightColor && 'stores__android-light',
              )}
              href={storeLinks[Stores.Android]}
            >
              <img
                alt="Google Play"
                className={cx('stores__icon-img-small')}
                height="56px"
                src={IMG_URLS.ANDROID}
                width="56px"
              />
            </a>
          </Metric>
        )) ||
          null}
      </Android>

      <Android>
        {(storeLinks[Stores.Huawei] && (
          <Metric
            asChild
            data={JSON.stringify({
              title: screenTitle,
              iconName: 'App Gallery',
            })}
            events={{ mobileApp2024Metric: ['handleAppButtonClick'] }}
            mode="click"
          >
            <a
              aria-label={Stores.Huawei}
              className={cx('stores__icon', 'stores__huawei')}
              href={storeLinks[Stores.Huawei]}
            >
              <img
                alt="App Gallery"
                className={cx('stores__icon-img')}
                height="56px"
                src={IMG_URLS.HUAWEI}
                width="56px"
              />
            </a>
          </Metric>
        )) ||
          null}
      </Android>
    </div>
  );
};
