import { useContext } from 'react';

import { useUserBlockedInfo } from 'pages/Services/ZeroBalanceSupport/hooks/useUserBlockedInfo';
import { ZeroBalanceSupportContext } from 'pages/Services/ZeroBalanceSupport/context';

/**
 * @desc: Возвращает булевый параметр для дизейбла кнопки отключения/подключения
 * @returns {{buttonType: *, disabled: boolean}}
 */

const useBannerButton = () => {
  const {
    data: { isConnected },
    content: { banner },
  } = useContext(ZeroBalanceSupportContext);
  const blocked = useUserBlockedInfo();
  const buttonType = isConnected ? banner.disconnectButton : banner.connectButton;

  const disabledToConnect = false;
  const disabledToDisconnect = isConnected && blocked;
  const disabled = disabledToConnect || disabledToDisconnect;

  return { disabled, buttonType };
};

export default useBannerButton;
