import { useEffect, useRef } from 'react';
import type { AnyAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'constructorV1/store';
import { useThrowAsyncError } from 'constructorV1/hooks/useThrowAsyncError';
import { changePacketAndOptionsData } from 'constructorV1/store/tariffSettingsSlice';
import { selectInitialData } from 'constructorV1/store/selectors/selectTariffSettings';
import { selectGbValue } from 'constructorV1/components/tempComponents/GbPresetContainer/selectors/selectGbValue';
import { selectMinValue } from 'constructorV1/components/tempComponents/MinPresetContainer/selectors/selectMinValue';

/** Отправки запроса для получения данных по пресету */
export const useHandlePresetChanges = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();
  const gbValue = useAppSelector(selectGbValue);
  const minValue = useAppSelector(selectMinValue);

  const initialData = useAppSelector(selectInitialData);
  const firstMount = useRef(true);

  useEffect(() => {
    if (!initialData || firstMount.current) {
      firstMount.current = false;
      return undefined;
    }

    const promise = dispatch(changePacketAndOptionsData() as unknown as AnyAction);
    promise.unwrap().catch((e: Error) => throwAsyncError(e));
    return () => {
      promise.abort();
    };
  }, [gbValue, minValue]);
};
