import { TServicesTariff } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/fttbSlice/types';

/** Функция подсчета стоимости скрытых сервисов (hiddenServices) */
export const countHiddenServicesPrice = (tariffs: TServicesTariff[], currentInacId: string) => {
  /** Скрытые сервисы (hiddenServices) по текущему активному InacId (currentInacId) */
  const currentHiddenServices = tariffs?.find((tariff) => tariff.inacId === currentInacId)
    ?.hiddenServices;

  /** Высчитываем стоимость активных скрытых опций */
  return currentHiddenServices?.reduce((acc, item) => {
    if (item.isActive) {
      acc += item.price;
    }
    return acc;
  }, 0);
};
