import { connect } from 'react-redux';

import { fetchTvChannelsData } from 'pages/FTTB/actions';
import TariffsList from 'pages/FTTB/CatalogPage/components/TariffsList';
import { getConnectionDataHouse } from 'pages/FTTB/selectors/connectionState';
import {
  getSpecialTariffFullModel,
  getSpecialTariffHouseId,
  isShowSpecTariffIfExist,
} from 'pages/FTTB/selectors/specialTariff';

const mapStateToProps = (state) => {
  const currHouseId = getConnectionDataHouse(state)?.id;
  const specTariffHouseId = getSpecialTariffHouseId(state);

  return {
    isSpecTariffCheckForCurrentHouse: specTariffHouseId && specTariffHouseId === currHouseId,

    showSpecTariffIfExist: isShowSpecTariffIfExist(state),
    specTariffModel: getSpecialTariffFullModel(state),
    tariffs: state.external.fttbCatalogData.tariffs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTvChannelsData: (fetchUrl, tariffAlias) =>
    dispatch(fetchTvChannelsData(fetchUrl, tariffAlias)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffsList);
