import React, { FC } from 'react';

// TODO: Не забыть про импорт после выноса tempComponents в отдельный пакет!
import { HiddenServices } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/HiddenServices';

import { useHiddenServices } from './hooks/useHiddenServices';

/** Обертка над компонентом скрытых опций (HiddenServices) на странице настройки ШПД-сервисов */
export const HiddenServicesContainer: FC = () => {
  const { priceProps, descriptionProps, isShowHiddenServices } = useHiddenServices();
  return <HiddenServices {...{ priceProps, descriptionProps, isShowHiddenServices }} />;
};
