import React from 'react';
import { PageFixer } from '@beef/smart-kit';
import classNames from 'classnames/bind';
import { BreadCrumbItemType, BreadCrumbs, Tag, Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

type ArchiveBannerPropsType = {
  breadcrumbs: BreadCrumbItemType[];
  title: string;
};

export const ArchiveBanner = ({ title, breadcrumbs }: ArchiveBannerPropsType) => (
  <PageFixer>
    {breadcrumbs && (
      <div className={cn('archive__breadcrumbs')}>
        <BreadCrumbs path={breadcrumbs} />
      </div>
    )}
    <Tag textColor="black" variant="transparent">
      Архивная акция
    </Tag>
    <Text className={cn('archive__title')} color="grey95" size="size0-b" tagType="h1">
      {title}
    </Text>
  </PageFixer>
);
