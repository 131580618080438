import React from 'react';

import { Passive } from '../Passive';
import { usePreparePassive } from './hooks/usePreparePassive';

/** Обертка над компонентом Passive для старого конструктора */
export const PassiveContainer = () => {
  const passiveProps = usePreparePassive();
  return <Passive {...passiveProps} />;
};
