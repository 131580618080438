import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { InfoCard } from '@beef/smart-kit';

import { TrustPaymentContext } from 'pages/Services/TrustPayment/context';

import { SectionWrapper } from '../SectionWrapper';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ImageCardsContainer = () => {
  const {
    content: { infoCard },
  } = useContext(TrustPaymentContext);

  return (
    <SectionWrapper className={cx('component')}>
      {infoCard?.map((cards) =>
        cards.content.map((card, index) => (
          <div className={cx('item')} key={`${cards.id}_${index}`}>
            <InfoCard {...card} size="s" />
          </div>
        )),
      )}
    </SectionWrapper>
  );
};
