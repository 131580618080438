import React from 'react';
import PropTypes from 'prop-types';

import TariffConnectButton from 'pages/ProductCard/Tariff/components/TariffSummary/TariffConnectButton';
import { CONTROL_TYPES } from 'utils/partnershipTariffHelper';

const CustomControlButton = ({ type }) => {
  if (type === CONTROL_TYPES.mnp) {
    return (
      <TariffConnectButton
        activeTabName="MNPForm"
        buttonProps={{
          size: 'm',
        }}
        buttonText="Перенести номер в билайн"
      />
    );
  }

  return null;
};

CustomControlButton.propTypes = {
  type: PropTypes.string,
};

export default CustomControlButton;
