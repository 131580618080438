import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Platform = ({
  soc,
  style,
  isAll,
  checked,
  setChecked,
  entityName,
  activeIcon,
  inactiveIcon,
  platformsLength,
  activePlatforms,
  setActivePlatforms,
  handleActivateAllPlatforms,
  persistentEnabled,
  disabled,
}) => {
  // Добавление "all" по которому происходит проверка,
  // если для опции пришел параметр isAll
  const isActive = useMemo(() => {
    if (isAll) {
      return activePlatforms.has('all');
    }

    return activePlatforms.has(soc);
  }, [soc, activePlatforms]);

  const platformStyles = useMemo(() => {
    const pStyles = { ...style };
    if (activeIcon && inactiveIcon) {
      pStyles.backgroundImage = isActive ? `url("${activeIcon}")` : `url("${inactiveIcon}")`;
    }

    return pStyles;
  }, [activeIcon, inactiveIcon, isActive, style]);

  // Передача нажатой опции, при нажатии isAll
  // все опции активируются, при отжатии хотя-бы
  // одной из них, опция isAll деактивируется.
  const onClick = useCallback(() => {
    if (isAll) {
      handleActivateAllPlatforms();
    } else {
      setActivePlatforms((prevSet) => {
        if (!prevSet.has(soc)) {
          prevSet.add(soc);
        } else {
          if (prevSet.has('all')) {
            prevSet.delete('all');
          }
          prevSet.delete(soc);
        }

        if (!prevSet.size) {
          setChecked(false);
        } else {
          setChecked(true);
        }

        // Кейс когда все платформы активированы, также активируется и родительская опция
        if (prevSet.size === platformsLength - 1) {
          handleActivateAllPlatforms();
          return prevSet;
        }

        return new Set(prevSet);
      });
    }
  }, [soc, isAll, checked, platformsLength, setActivePlatforms, handleActivateAllPlatforms]);

  return (
    <button
      className={cx(
        'platform',
        { 'platform--non-icon': !activeIcon && !inactiveIcon },
        { 'platform--active': !activeIcon && !inactiveIcon && isActive },
      )}
      data-component="Platform"
      disabled={persistentEnabled || disabled}
      onClick={onClick}
      style={platformStyles}
      type="button"
    >
      {!activeIcon && !inactiveIcon ? entityName : null}
    </button>
  );
};

Platform.propTypes = {
  soc: PropTypes.string,
  style: PropTypes.object,
  isAll: PropTypes.bool,
  checked: PropTypes.bool,
  entityName: PropTypes.string,
  activeIcon: PropTypes.string,
  inactiveIcon: PropTypes.string,
  platformsLength: PropTypes.number,
  activePlatforms: PropTypes.instanceOf(Set),
  setChecked: PropTypes.func,
  setActivePlatforms: PropTypes.func,
  handleActivateAllPlatforms: PropTypes.func,
  persistentEnabled: PropTypes.bool,
};

export default Platform;
