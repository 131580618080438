import { pushDataLayer } from 'utils/ga';

// Результат оплаты через GooglePay или ApplePay (успех или ошибка)
export const pushResult = (type, result) => {
  pushDataLayer({
    event: 'event_az_applForm',
    eventAction: `pay_${result}_${type}Pay`,
    formName: 'Пополнение счёта',
  });
};

// Клик на кнопку GooglePay / ApplePay
export const pushClickPayButton = (buttonType) => {
  pushDataLayer({
    event: 'autotracking',
    action: 'buttonClick',
    eventText: buttonType,
  });
};
