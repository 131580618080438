export const OFFERS_TYPES = {
  service: 1,
  rate: 2,
  rateDiscount: 12,
  promo: 3,
};

export const CONNECTION_STATUS = {
  available: 0,
  notavailable: 1,
  notenoughmoney: 2,
  ctnisblocked: 3,
  ctnisblockedbyfinance: 4,
  conflictwithtariff: 5,
  conflictwithservices: 6,
  replaceservices: 7,
  timeout: 8,
  hasnotfamilynumbers: 9,
  requestsent: 10,
  error: 11,
};

export const POLLING_STATUS = {
  error: 'ERROR',
  progress: 'IN_PROGRESS',
  complete: 'COMPLETE',
};
