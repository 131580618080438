import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ExtendedSteps } from '@beef/landing-constructor';
import { PageRow } from '@beef/smart-kit';

import { useIsTabletDimension } from 'utils/hooks/useGetDimensionGroup';
import { useOnScreenSendAnalytics } from 'pages/MnpOrderNew/hooks';

import { Form } from '../Form';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const TransferSteps = ({
  id,
  isMnpService,
  data: { title, image, tabletImage, items, meta },
}) => {
  const nodeYMRef = useOnScreenSendAnalytics(title);
  const isTablet = useIsTabletDimension();

  const memoItems = useMemo(() => {
    const data = [...items];
    data[0].customDescription = (
      <div className={cx('form')}>
        <Form isMnpService={isMnpService} isVertical={isTablet} tariffId={id} title={title} />
      </div>
    );
    data[0].img = {
      src: image,
      title: 'steps-image',
      source: [
        {
          srcset: image,
          media: 'max-width: 768px',
        },
        {
          srcset: tabletImage,
          media: 'max-width: 1006px',
        },
        {
          srcset: image,
          media: 'min-width: 1006px',
        },
      ],
    };
    return data;
  }, [items, isTablet, id]);

  return (
    <div ref={nodeYMRef}>
      <PageRow blockSpacersPreset="m" tagName="section">
        <ExtendedSteps content={[{ steps: memoItems }]} meta={meta} title={title} />
      </PageRow>
    </div>
  );
};

TransferSteps.propTypes = {
  id: PropTypes.number,
  isMnpService: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    tabletImage: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }),
};
