import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link, Text } from '@beef/ui-kit';
import { Tooltip } from '@beef/legacy-ui-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import scrollToBlock from 'utils/scrollToBlock';
import { getLocalCurrencyStr } from 'utils/getLocalCurrencyStr';
import {
  priceItemShape,
  priceShape,
  usefulLinkShape,
} from 'pages/ProductCard/Tariff/containers/tariffPropTypes';

import Box from '../Box';
import TariffCostWithDiscount from './TariffCostWithDiscount';
import TariffConnectButton from './TariffConnectButton';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffSummary = ({
  usefulLinks,
  prices,
  price,
  summaryContent,
  isConnected,
  sticky,
  pageTitle,
  availableInShop,
  showDiscountParams,
}) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [formattedUsefulLinks, setFormattedUsefulLinks] = useState([]);
  const [formattedPrices, setFormattedPrices] = useState([]);
  const isMobile = useIsMobileDimension();

  useEffect(() => {
    if (usefulLinks?.list?.length > 0) {
      setFormattedUsefulLinks({
        ...usefulLinks,
        list: usefulLinks.list.map((usefulLink) => {
          const targetProps = {};
          if (!usefulLink.pageLink) {
            targetProps.target = '_blank';
            targetProps.extraAttributes = {
              rel: 'nofollow noopener',
            };
          }
          return {
            ...usefulLink,
            ...targetProps,
          };
        }),
      });
    }
  }, [usefulLinks]);

  useEffect(() => {
    setFormattedPrices(
      prices.map((priceItem) => {
        if (priceItem.value === 0) {
          return {
            ...priceItem,
            value: 'бесплатно',
          };
        }
        return priceItem;
      }),
    );
  }, [prices]);

  useEffect(() => {
    const newPrice = prices.reduce((acc, curr) => {
      if (curr.value && !Number.isNaN(curr.value)) {
        return acc + curr.value;
      }
      return acc;
    }, 0);
    setTotalPrice(newPrice);
  }, [prices]);

  if (sticky && availableInShop) {
    if (isMobile) {
      return (
        <div className={cx('fixed-wrap')}>
          <div className={cx('fixed-wrap__content')}>
            <div className={cx('fixed-wrap__details')}>
              <TariffConnectButton />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={cx('fixed-wrap')}>
        <div className={cx('fixed-wrap__content')}>
          <div className={cx('fixed-wrap__logo')}>
            <Text size="size4-m" tagType="h2">
              {pageTitle}
            </Text>
          </div>
          <div className={cx('fixed-wrap__details')}>
            <TariffCostWithDiscount
              price={price}
              subtitle={summaryContent?.subtitle}
              tooltipText={summaryContent?.tooltipText}
              {...showDiscountParams}
            />
            {availableInShop && (
              <div className={cx('fixed-wrap__order')}>
                <TariffConnectButton />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <TariffCostWithDiscount
        price={price}
        subtitle={summaryContent?.subtitle}
        tooltipText={summaryContent?.tooltipText}
        {...showDiscountParams}
      />
      {!isConnected && prices?.length > 0 && (
        <>
          <div className={cx('summary-separator')} />
          <ul className={cx('summary-list')}>
            <li className={cx('summary-row')}>
              <Text size="size4-m">К оплате</Text>
            </li>
            {formattedPrices.map((item) => (
              <li className={cx('summary-row')} key={item.label}>
                <div className={cx('summary-row__description')}>
                  <Text size="size6-r">
                    <span className={cx('summary-row__text')}>
                      {item.label}
                      {item?.legal?.text && (
                        <Tooltip className={cx('summary-tooltip')}>
                          <span dangerouslySetInnerHTML={{ __html: item.legal.text }} />
                        </Tooltip>
                      )}
                    </span>
                  </Text>
                  <div className={cx('summary-row__dots')} />
                </div>
                {item.value && (
                  <div className={cx('summary-row__value')}>
                    <Text size="size6-r">
                      {!Number.isNaN(item.value) && item.value > 0 ?
                        <>{getLocalCurrencyStr(item.value)}</>
                      : 'бесплатно'}
                    </Text>
                  </div>
                )}
              </li>
            ))}
            {prices?.length > 1 && (
              <li className={cx('summary-row')}>
                <div className={cx('summary-row__description')}>
                  <Text color="grey80" size="size4-m">
                    <span className={cx('summary-row__text')}>Итого к оплате</span>
                  </Text>
                  <div className={cx('summary-row__dots')} />
                </div>
                <div className={cx('summary-row__value')}>
                  <Text size="size4-m">
                    {totalPrice > 0 && <>{getLocalCurrencyStr(totalPrice)}</>}
                  </Text>
                </div>
              </li>
            )}
          </ul>
        </>
      )}

      {!isConnected && availableInShop && (
        <div>
          <TariffConnectButton />
        </div>
      )}

      {isConnected && (
        <div className={cx('row')}>
          <div className={cx('service-connected')}>
            <Text color="white" size="size4-m">
              Ваш тариф
            </Text>
          </div>
        </div>
      )}

      {isConnected && formattedUsefulLinks?.list?.length > 0 && (
        <div className={cx('row')}>
          <Box color="grey-5" size="s">
            <div className={cx('row')}>
              <Text size="size4-r" tagType="h6">
                {usefulLinks.title}
              </Text>
            </div>
            <ul className={cx('useful-links')}>
              {formattedUsefulLinks?.list.map((usefulLink) => (
                <li className={cx('useful-links__item')} key={usefulLink.href}>
                  <div className={cx('useful-link__wrap')}>
                    <Link
                      href={usefulLink.href}
                      onClick={(e) => {
                        if (usefulLink.pageLink) {
                          e.preventDefault();
                          scrollToBlock(usefulLink.href.replace('#', ''));
                        }
                      }}
                      size="size7-r"
                      {...usefulLink.targetProps}
                    >
                      {usefulLink.text}
                    </Link>
                  </div>
                  <div className={cx('useful-link__text')}>
                    <Text size="size7-r">{usefulLink.note}</Text>
                  </div>
                </li>
              ))}
            </ul>
          </Box>
        </div>
      )}
    </div>
  );
};

TariffSummary.propTypes = {
  pageTitle: PropTypes.string,

  /* подключен ли тариф */
  isConnected: PropTypes.bool,
  /* прилипание к верху страницы */
  sticky: PropTypes.bool,
  /* доступен ли тариф */
  availableInShop: PropTypes.bool,

  /* вспомогательные ссылки */
  usefulLinks: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.arrayOf(usefulLinkShape),
  }),
  /* блок с ценами */
  prices: PropTypes.arrayOf(priceItemShape),
  /* абонентская плата */
  price: priceShape,
  /* контент для компонента */
  summaryContent: PropTypes.object,
  /* показ скидки на тарифе */
  showDiscountParams: PropTypes.object,
};

export default TariffSummary;
