import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { CloseIcon, Counter, Link, Text } from '@beef/ui-kit';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';

import {
  getFiltersIsEnabled,
  getSelectedFiltersCount,
} from 'pages/SearchResultsPage/store/selectors';
import { clearFilter } from 'pages/SearchResultsPage/store/actions';
import { ResetButtonWithAnalytics } from 'pages/SearchResultsPage/components/Filter/FilterLabel/ResetButtonWithAnalytics';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const _FilterLabel = ({ count, resetFilter }) => (
  <div className={cx('container')}>
    <div className={cx('title')}>
      <Text color="grey60" size="size5-m">
        фильтры
      </Text>
      {!!count && (
        <div className={cx('count')}>
          <Counter count={count} />
        </div>
      )}
    </div>
    {!!count && (
      <ResetButtonWithAnalytics>
        <Link
          isNoUnderline
          onClick={resetFilter}
          rightIcon={<CloseIcon size={20} />}
          size="size6-m"
        >
          сбросить фильтры
        </Link>
      </ResetButtonWithAnalytics>
    )}
  </div>
);

_FilterLabel.propTypes = {
  count: PropTypes.number,
  resetFilter: PropTypes.func,
};

const mapStateToProps = (state) => ({
  count: getSelectedFiltersCount(state),
  shouldRender: getFiltersIsEnabled(state),
});

export const FilterLabel = compose(
  connect(mapStateToProps, { resetFilter: clearFilter }),
  withRenderFlag,
)(_FilterLabel);
