import { useEffect, useState } from 'react';

import { useAppDispatch } from '../../../../store';
import { useThrowAsyncError } from '../../../../hooks/useThrowAsyncError';
import { TFetchTariffChangesQueryRes, fetchTariffChanges } from '../../../../api/getTariffChanges';
import { useSelectParams } from '../selectors/useSelectParams';

/** Хук получение данных по изменениям подключенного тарифа */
export const useFetchPreChangesTariff = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();

  const params = useSelectParams();
  const [result, setResult] = useState<TFetchTariffChangesQueryRes | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return undefined;
    }

    /* Запрос изменений по тарифу */
    const promise = dispatch(fetchTariffChanges(params));
    promise
      .unwrap()
      .then((response: TFetchTariffChangesQueryRes) => {
        setResult(response);
      })
      .catch((e: Error) => throwAsyncError(e));

    return () => {
      promise.abort();
    };
  }, []);

  return result;
};
