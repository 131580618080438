import { axiosBaseRequest } from '@beef/utils';

import { transformRequest } from 'utils/globalAjaxHandler';

export enum EContentType {
  img = 'image/gif',
  json = 'application/json',
}

export type CaptchaResponseType = {
  captcha: string;
  captchakey: string;
};

export const fetchCaptcha = async (url: string) => {
  try {
    const { headers, data } = await axiosBaseRequest<Blob>({
      url,
      transformRequest,
      originalResponse: true,
      responseType: 'blob',
    });

    if (headers['content-type'] === EContentType.img) {
      return {
        pic: URL.createObjectURL(data),
      };
    }
    if (headers['content-type'] === EContentType.json) {
      const { captcha, captchakey }: CaptchaResponseType = JSON.parse(await data.text());
      return {
        pic: `data:image/png;base64,${captcha}`,
        captchaKey: captchakey,
      };
    }
  } catch (err) {
    console.error(err);
  }
  return {};
};
