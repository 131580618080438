import axios, { AxiosRequestConfig } from 'axios';

import { ALLINONE_GET_STREET } from 'constants/Endpoints';

type TStreetRest = {
  id: number;
  isConnected: boolean;
  label: string;
};

/* Функция проверки доступности подключения выбранной улицы */
export async function getStreetSearchResults(cityId: number | undefined, term: string) {
  if (!cityId) {
    return [];
  }
  return (
    await axios.post(ALLINONE_GET_STREET, { cityId, term }, {
      'X-Requested-With': 'XMLHttpRequest',
    } as AxiosRequestConfig)
  ).data.map(({ alias, ...rest }: { alias: string; rest: TStreetRest }) => ({
    slug: alias,
    alias,
    ...rest,
  }));
}
