import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

import { Slider } from './Slider';
import { Attention } from '../Attention';

// Utils
import { currentTitle } from '../../../../../utils';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Slides = ({
  activeTab,
  saveDataTab,
  hasZTariff,
  slidesInfo,
  isChangeTariff,
  hasRemainingSize,
  isChangePackagesTariff,
  persistentEnabledAutoProlong,
  content: { slides, autoProlong, slidesAttentionBlockText },
}) => (
  <div className={cx('wrapper')}>
    {slides?.title && (
      <div className={cx('wrapper__title')}>
        <Text size="size3-m" tagType="h2">
          {slides.title}
        </Text>
      </div>
    )}

    {hasZTariff && slidesAttentionBlockText && hasRemainingSize && (
      <Attention
        hasChange={!isChangePackagesTariff && isChangeTariff}
        text={slidesAttentionBlockText}
      />
    )}

    {slidesInfo?.map((slide) => (
      <Slider
        activeTab={activeTab}
        autoProlong={autoProlong}
        hasZTariff={hasZTariff}
        isChangeTariff={isChangeTariff}
        key={slide.type}
        persistentEnabledAutoProlong={persistentEnabledAutoProlong}
        saveDataTab={saveDataTab}
        slide={slide}
        titleContent={currentTitle(slides.types, slide.type)}
      />
    ))}
  </div>
);

Slides.propTypes = {
  hasZTariff: PropTypes.bool,
  activeTab: PropTypes.string,
  saveDataTab: PropTypes.bool,
  isChangeTariff: PropTypes.bool,
  hasRemainingSize: PropTypes.bool,
  isChangePackagesTariff: PropTypes.bool,
  content: PropTypes.shape({
    slides: PropTypes.shape({
      title: PropTypes.string,
      types: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
    autoProlong: PropTypes.shape({
      title: PropTypes.string,
      benefit: PropTypes.string,
    }),
    slidesAttentionBlockText: PropTypes.string,
  }),
  persistentEnabledAutoProlong: PropTypes.bool,
  slidesInfo: PropTypes.arrayOf(
    PropTypes.shape({
      initialPeriod: PropTypes.string,
      initialSize: PropTypes.number,
      lastIndex: PropTypes.number,
      remainingSize: PropTypes.number,
      steps: PropTypes.array,
      tabs: PropTypes.array,
      type: PropTypes.string,
      unit: PropTypes.string,
    }),
  ),
};
