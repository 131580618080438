import React, { FC, MouseEvent } from 'react';
import { Button } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TInfoButton = {
  btnText?: string;
  extraBtnText?: string;
  extraOnClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

/** Обертка над кнопками для информационного блока */
export const InfoButton: FC<TInfoButton> = ({ btnText, onClick, extraBtnText, extraOnClick }) => (
  <div className={cx('wrapper')}>
    {btnText && (
      <Button fullWidth onClick={onClick} variant="main">
        {btnText}
      </Button>
    )}
    {extraBtnText && (
      <Button fullWidth onClick={extraOnClick} variant="tertiary">
        {extraBtnText}
      </Button>
    )}
  </div>
);
