import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
// Components
import { MapPinOutlineIcon, Text } from '@beef/ui-kit';
// Selectors
import { withRenderFlag } from '@beef/react';

import { onInitConnectionNotificationYmAnalytics } from 'pages/FTTB2021/components/OrderTariffFormPopup/analytics';
import { REQUEST_STATUS } from 'pages/ProductCard/Tariff/components/ConvergenceLayout/constants';
import { getCurrentCity } from 'pages/FTTB2022/CatalogPage/deprecated/store/blocks/checkAddress/selectors';

import { getConnectionStatusTexts } from '../../../../store/selectors/userData';
import {
  getStatusConnection,
  getUserSelectedHouse,
  getUserSelectedStreet,
} from '../../../../store/selectors/orderTariffUserData';
// Hooks
import { useNotificationPrepareData } from './Hooks/useNotificationPrepareData';
// PropTypes
import { textsShape } from '../../../../PropTypes';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ConnectionNotificationLayout = ({
  status,
  statusTexts,
  onInit,
  house,
  street,
  city,
}) => {
  const { fill, notificationText } = useNotificationPrepareData({ status, statusTexts });
  useEffect(() => {
    onInit?.(house, street, city);
  }, [onInit]);
  return (
    <div className={cx('wrapper', { 'wrapper--hide': !fill })}>
      <MapPinOutlineIcon size={fill ? 'm' : null} {...{ fill }} />
      {notificationText && <Text size="size6-r">{notificationText}</Text>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  status: getStatusConnection(state),
  statusTexts: getConnectionStatusTexts(state),
  shouldRender: getStatusConnection(state) !== REQUEST_STATUS.isNone,
  house: getUserSelectedHouse(state),
  street: getUserSelectedStreet(state),
  city: getCurrentCity(state),
});

ConnectionNotificationLayout.propTypes = {
  statusTexts: textsShape,
  status: PropTypes.string,
  onInit: PropTypes.func,
  house: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
};

export const ConnectionNotification = connect(mapStateToProps, {
  onInit: onInitConnectionNotificationYmAnalytics,
})(withRenderFlag(ConnectionNotificationLayout));
