import React from 'react';
import PropTypes from 'prop-types';

// Styles
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const GradientText = ({ text, direction, from, to, className }) => (
  <span
    className={cx(className, 'component')}
    dangerouslySetInnerHTML={{ __html: text }}
    style={{ background: `linear-gradient(${direction},${from},${to})` }}
  />
);

GradientText.defaultProps = {
  direction: 'to left',
  from: '#fad82c',
  to: '#fdaf2a',
};

GradientText.propTypes = {
  className: PropTypes.any,
  text: PropTypes.string,
  direction: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
};

export default GradientText;
