import React, { PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { has, path, pathOr } from 'ramda';
import { Loader } from '@beef/legacy-ui-kit';

import {
  disconnectRedirection,
  setRedirectData,
  setServiceStatus,
  startRedirectConnectionRequest,
} from 'pages/ProductCard/actions/redirect';

import styles from './styles.pcss';
import ServiceStatusPopup from '../ServiceStatusPopup/ServiceStatusPopup';
import RedirectCustomParameter from './components/RedirectCustomParameter';
import RedirectSettingsPopup from './components/RedirectSettingsPopup';

const cx = classNames.bind(styles);

class RedirectService extends PureComponent {
  componentDidMount() {
    const { data, getOptions } = this.props;
    const { isAuthenticated, requestId } = data;
    if (isAuthenticated) getOptions(isAuthenticated, requestId);
  }

  renderLoader() {
    return [
      <div className={cx('wrapper')} key="loader">
        <Loader status="active" />
      </div>,
      <div className={cx('loading')} key="desc">
        Проверяем настройки услуги. Это займет около минуты, пожалуйста, подождите
      </div>,
    ];
  }

  render() {
    const { children, data, isLoading, onDisconnect, onConnect, isDisconnection } = this.props;
    const { isAuthenticated, requestId, errorPopup } = data || {};

    return (
      <div className={cx('redirect')}>
        {!isAuthenticated ? children : null}
        {!isLoading && isAuthenticated && children ?
          cloneElement(
            children,
            children.props,
            <RedirectCustomParameter isAuthenticated={isAuthenticated} requestId={requestId} />,
            ...children.props.children,
          )
        : null}
        {isLoading && isAuthenticated ? this.renderLoader() : null}
        <RedirectSettingsPopup />
        <ServiceStatusPopup
          confirmationContent={
            isDisconnection ?
              {
                heading: 'Отключение всех переадресаций',
                description: null,
                button: 'Отключить все переадресации',
                hasServiceName: false,
              }
            : null
          }
          confirmationSubmit={isDisconnection ? onDisconnect : onConnect}
          errorContent={errorPopup}
          id="redirectService"
          isDisconnection={isDisconnection}
          isServiceConnection
          serviceName="Переадресация"
        />
      </div>
    );
  }
}

RedirectService.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  getOptions: PropTypes.func,
  onDisconnect: PropTypes.func,
  onConnect: PropTypes.func,
  isDisconnection: PropTypes.bool,
};

const mapStateToProps = ({ external }) => ({
  isLoading: !pathOr(false, ['redirect', 'serviceStatus', 'loaded'], external),
  isDisconnection: has('disconnect', path(['redirect', 'serviceStatus'], external)),
});

const mapDispatchToProps = (dispatch) => ({
  getOptions: (isAuthenticated, requestId) => dispatch(setRedirectData(isAuthenticated, requestId)),
  onConnect: () => dispatch(startRedirectConnectionRequest()),
  onDisconnect: () => {
    dispatch(disconnectRedirection());
    dispatch(setServiceStatus({ disconnect: false }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RedirectService);

export { default as RedirectConnection } from './components/RedirectConnection';
