import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент прелоада total чека */
export const GroupsPreload: FC = () => (
  <div className={cx('wrapper')}>
    <Skeleton className={cx('wrapper__title')} />
    <Skeleton className={cx('wrapper__group')} />
    <Skeleton className={cx('wrapper__group')} />
  </div>
);
