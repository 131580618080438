import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const HeaderDescription = ({ subTitle, description, isAuthenticated }) => (
  <div className={cx('wrapper')}>
    {subTitle && (
      <Text size="size4-m" tagType="h3">
        {subTitle}
      </Text>
    )}
    {isAuthenticated && description && (
      <Text color="grey60" size="size6-r" tagType="h3">
        {description}
      </Text>
    )}
  </div>
);

HeaderDescription.propTypes = {
  subTitle: PropTypes.string,
  description: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};
