import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { ItemDetailsInfo, TItemDetailsInfo } from './components/ItemDetailsInfo';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TDetailsInfo = {
  detailsInfo?: TItemDetailsInfo[];
};

/** Компонент-список для ModalHeader.
 * Предназначен для выведения названия услуги и ее стоимости. */
export const DetailsInfo: FC<TDetailsInfo> = ({ detailsInfo }) =>
  detailsInfo?.length ?
    <ul className={cx('wrapper')}>
      {detailsInfo?.map((detail, idx) => <ItemDetailsInfo key={idx} {...detail} />)}
    </ul>
  : null;
