import { PATH_FOR_YM_PARAMS } from 'utils/locationsYmParams';
import { debounce } from 'utils/timed-functions';

import { ymPushEvent, ymPushParams } from '../ym';

export const ymClickStore = () => ymPushEvent('click_store');
export const ymEnterLkSuccess = () => ymPushEvent('enter_lk_success');
export const ymChangeTariff = () => ymPushEvent('izmenit_tariff_form');
export const ymChangeTariffSuccess = () => ymPushEvent('podlkyuchenie_success');
export const ymSimMNPAddToCart = () => ymPushEvent('SimMNPAddToCart');
export const ymAuthBySMSorPassForm = () => {
  const path = window.location.pathname;
  if (Object.values(PATH_FOR_YM_PARAMS).includes(path)) {
    ymPushEvent('Auth_Success', { auth_status_dm_: true, auth_type_ev_: { sms: 'auth_success' } });
  }
};
export const ymInitMnp = () => {
  // todo: ask analytics about duplicate events
  ymPushEvent('nachat_perehod');
  ymPushEvent('nachat_perehod_s_sohraneniem_nomera');
};

/** Auth YM events */
export const SMS_CODE_LENGTH = 5;
export const CAPTCHA_LENGTH = 6;

export const AUTH_YM_EVENTS = {
  CHANGE_TARIFF_PASSWORD: 'password_change_tariff_custom',
  CHANGE_TARIFF_SMS: 'sms_change_tariff',
  CONNECT_SERVICE_PASSWORD: 'password_service_connect',
};

export const AUTH_YM_ACTIONS = {
  VISIBLE_WRONG_REGION: 'wrong_region',
  CLOSE_WRONG_REGION: 'wrong_region_close',
  STEP_CAPTCHA: 'step_capcha',
  CLOSE_CAPTCHA: 'capcha_close',
  ERROR_CAPTCHA: 'capcha_error',
  NEXT_CAPTCHA: 'capcha_next',
  SEND_SMS: 'send_sms',
  STEP_INPUT_CODE: 'step_input_code',
  STEP_INPUT_CODE_CLOSE: 'step_input_code_close',
  STEP2_INPUT_PHONE: 'step2_input_phone',
};

export const AUTH_YM_PLACE = {
  form: {
    smsFormWithCaptcha: 'smsFormWithCaptcha',
    smsConfirmationForm: 'SmsConfirmationForm',
  },
  step: {
    main: 'main',
    submitLogin: 'submitLogin',
  },
};

const wrapAuthEvent = (payload) => ({
  auth_type_ev_: payload,
});

export const YMAuthEvent = {
  /** auth common */
  visible: (type, action) => ymPushParams(wrapAuthEvent({ [type]: action ?? 'step1_start' })),
  close: (type, action) => ymPushParams(wrapAuthEvent({ [type]: action ?? 'step1_close' })),
  capchaClose: (type, action) => ymPushParams(wrapAuthEvent({ [type]: action ?? 'capcha_close' })),
  changeNumber: (type, action) =>
    ymPushParams(wrapAuthEvent({ [type]: action ?? 'change_number' })),
  error: (type, error) => ymPushParams(wrapAuthEvent({ [type]: error })),
  clickLoginInput: (type) => ymPushParams(wrapAuthEvent({ [type]: 'input_login_click' })),
  fillLoginInput: debounce(
    (type) => ymPushParams(wrapAuthEvent({ [type]: 'input_login_fill_in' })),
    2000,
  ),
  clickPasswordInput: (type) => ymPushParams(wrapAuthEvent({ [type]: 'input_password_click' })),
  fillPasswordInput: debounce(
    (type) => ymPushParams(wrapAuthEvent({ [type]: 'input_password_fill_in' })),
    2000,
  ),
  clickGetPassword: (type) => ymPushParams(wrapAuthEvent({ [type]: 'get_password' })),
  showPasswordInput: (type) => ymPushParams(wrapAuthEvent({ [type]: 'input_password_show' })),
  hidePasswordInput: (type) => ymPushParams(wrapAuthEvent({ [type]: 'input_password_hide' })),
  clickAuthButton: (type, action) =>
    ymPushParams(wrapAuthEvent({ [type]: action ?? 'auth_enter' })),
  changeNumberWrongRegion: (type) =>
    ymPushParams(wrapAuthEvent({ [type]: 'wrong_region_change_number' })),
  clickWrongRegionButton: (type) =>
    ymPushParams(wrapAuthEvent({ [type]: 'wrong_region_go_to_local' })),
  authSuccess: (type) =>
    ymPushEvent('Auth_Success', {
      auth_status_dm_: true,
      auth_type_ev_: { [type]: 'auth_success' },
    }),
  clickCaptchaInput: (type) => ymPushParams(wrapAuthEvent({ [type]: 'input_capcha_click' })),
  fillCaptchaInput: (type) => ymPushParams(wrapAuthEvent({ [type]: 'input_capcha_fill_in' })),
  reloadCaptcha: (type) => ymPushParams(wrapAuthEvent({ [type]: 'capcha_reload' })),
  /** auth sms */
  fillSmsInput: (type) => ymPushParams(wrapAuthEvent({ [type]: 'input_password_fill_in' })),
  sendSms: (type) => ymPushParams(wrapAuthEvent({ [type]: 'send_sms' })),
  repeatSms: (type) => ymPushParams(wrapAuthEvent({ [type]: 'repeat_sms' })),
};
