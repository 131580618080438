import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const TariffDescriptionContainer = ({ serviceTitle, serviceDescription }) => (
  <div className={cx('wrapper')}>
    {serviceTitle && <Text size="size3-m">{serviceTitle}</Text>}
    {serviceDescription && <Text size="size5-r-s">{serviceDescription}</Text>}
  </div>
);

TariffDescriptionContainer.propTypes = {
  serviceTitle: PropTypes.string,
  serviceDescription: PropTypes.string,
};
