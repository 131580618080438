import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Tag, Text } from '@beef/ui-kit';

import { Picture } from './components/Picture';
import { TPictureContainer } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент картинки с тегом для плитки ServiceTile.
 * Включает в себя картинку и иконку-заглушку (по-умолчанию) */
export const PictureContainer: FC<TPictureContainer> = ({ tag, image, mobImage }) => (
  <div className={cx('wrapper')}>
    {tag?.text && (
      <div className={cx('wrapper__tag')}>
        <Tag size="m" {...tag}>
          {/* @ts-expect-error Временное решение для отображения символа рубля */}
          <Text color="inherit" size="size7-r">
            {tag.text}
          </Text>
        </Tag>
      </div>
    )}
    <Picture {...{ image, mobImage }} />
  </div>
);
