import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { Heading, Tab, TabContent, Tabs, TabsList } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { simBuyAction } from 'pages/ProductCard/actions/simBuy';
import PopupStateless from 'pages-components/Popup';
import SimOrderForm from 'widgets/SimOrderForm/SimOrderForm';

import SimPurchaseForm from './SimPurchaseForm';
import AddNumberForm from './AddNumberForm';
import TextContainer from '../TextContainer/TextContainer';
import commonStyles from '../SharingService.pcss';

const cx = classNames.bind(commonStyles);

class AddNumberPopup extends Component {
  state = {
    activeTab: null,
    showTabs: true,
  };

  componentDidMount() {
    this.setState({
      activeTab: this.props.content.tabs[0].type,
    });
  }

  toggleShowTabs = (value = false) => this.setState({ showTabs: value });

  render() {
    const { opened, onClose, onAddNumber, profileCtn, ctn } = this.props;
    const { showTabs, activeTab } = this.state;

    return (
      <PopupStateless onClose={onClose} opened={opened}>
        <section className={cx('content')}>
          {showTabs && (
            <Heading level={1}>
              <TextContainer className={cx('title')} path={['texts', 'addNumberTitle']} />
            </Heading>
          )}

          <Tabs activeTab={activeTab} onChange={(tabName) => this.setState({ activeTab: tabName })}>
            <TabsList
              className={cx('tabsList', !showTabs && 'hidden')}
              inline
              stretched
              type="radio"
            >
              {this.props.content.tabs.map((tab) => (
                <Tab className={cx('tab')} id={tab.type} key={tab.type}>
                  <span dangerouslySetInnerHTML={{ __html: tab.title }} />
                </Tab>
              ))}
            </TabsList>

            <TabContent id="sim-order">
              <SimPurchaseForm
                callback={this.toggleShowTabs}
                onClose={onClose}
                onSubmit={simBuyAction}
                phone={profileCtn}
                {...this.content.simPurchaseForm}
              />
            </TabContent>

            <TabContent id="add-number">
              <AddNumberForm
                onAddNumber={onAddNumber}
                switchNumberInfoLink={this.props.content.switchNumberInfoLink}
              />
            </TabContent>

            <TabContent id="mnp">
              <SimOrderForm
                {...this.props.content.mnp}
                onReset={() => this.toggleShowTabs(true)}
                onStepChange={() => this.toggleShowTabs(false)}
                phone={ctn}
                reservePlace="Family"
              />
            </TabContent>
          </Tabs>
        </section>
      </PopupStateless>
    );
  }
}

AddNumberPopup.propTypes = {
  onAddNumber: PropTypes.func,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  profileCtn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default connect((state) => ({
  ctn: path(['external', 'currentCtn'], state),
  content: state.external.tariff.data.sharing.texts,
}))(AddNumberPopup);
