import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

type ITextLayout = {
  children: React.ReactNode;
  className?: string;
};

export const TextLayout: React.FC<ITextLayout> = ({ children, className }) => {
  return <div className={cn('text', className)}>{children}</div>;
};
