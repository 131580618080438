import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { TEquipmentProps } from './types';
import { EquipmentBlock } from '../EquipmentBlock';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const EquipmentBlocks: FC<TEquipmentProps> = ({
  equipmentProps: { equipmentData, getIsActive, onChange },
}) => (
  <form className={cx('component')}>
    {equipmentData.map((item) => (
      <label htmlFor={item.inacId} key={item.inacId}>
        <EquipmentBlock equipment={item} isActive={getIsActive(item)} />
        <input
          className={cx('input')}
          id={item.inacId}
          name="equipment"
          onChange={onChange}
          type="checkbox"
          value={item.inacId}
        />
      </label>
    ))}
  </form>
);
