/* eslint-disable */
// todo: исправить ошибки линтера
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { determineOrderModal } from 'pages/ProductCard/actions/tariffConnect/handleTariffConnectionPopup';
import TariffConnectPopup from 'pages/ProductCard/components/TariffConnectPopup/TariffConnectPopup';
import withStoreProvider from 'pages/FTTB/hoc/withStoreProvider';
import { TeaserBanner } from '@beef/smart-kit';
import { Preloader } from '@beef/ui-kit';
import { MainWrapper, MainContent, LeftSide, RightSide } from '../../components/Grid';
import { Tabs } from './components/Tabs';
import { Slides } from './components/Slides';
import { Options } from './components/Options';
import { CheckZ } from './components/CheckZ';

// Reducer
import { reducer } from './store/reducer';

// Actions
import { setTariffInfo, setLoading } from './store/actions';

// Constants
import DispatchContext from './store/constants';

// Utils
import { checkChangeSettings, checkRemainingSize, checkConnectedOptions, handleOrderModal } from '../../../utils';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ZTariff = ({
  data,
  content,
  actions,
  hasZTariff,
  isAvailable,
  requestStatus,
  isTariffAvailableForConnect,
}) => {
  const [state, _dispatch] = useReducer(reducer, {
    hasZTariff,
    socsOn: [],
    socsOff: [],
    isAvailable,
    totalPrice: 0,
    pending: true,
    activeTab: null,
    saveDataTab: false,
  });

  const [orderModalPending, setOrderModalPending] = useState(false);
  const personalPlanData = useMemo(() => state?.socsOn?.join(), [state.socsOn]);
  const isChangeTariff = useMemo(() => {
    return state.activeTab !== 'SimOrderForm' && state.activeTab !== 'MNPForm';
  }, [state.activeTab]);
  const type = (hasZTariff && isChangeTariff) ? 'currentNumber' : 'newNumber';
  const hasRemainingSize = checkRemainingSize(state[type]?.initPackages);
  const hasConnectedOptions = !!checkConnectedOptions(state[type]?.initOptions);
  const isChangeOptionsTariff = checkChangeSettings(state[type]?.initOptions, state.resultOptions);
  const isChangePackagesTariff = checkChangeSettings(state[type]?.initPackages, state.resultPackages);
  const total = useMemo(() => (
    { packages: { ...state.resultPackages }, options: { ...state.resultOptions } }
  ), [state.resultPackages, state.resultOptions]);
  const updateConnectedTariff = hasZTariff && isChangeTariff;

  const handleOrderOpenModal = useCallback(() => handleOrderModal({
    data,
    state,
    orderModalPending,
    setOrderModalPending,
    updateConnectedTariff,
    determineOrderModal: actions.determineOrderModal,
  }), [orderModalPending, state.socsOn, state.socsOff]);

  useEffect(() => {
    _dispatch(setLoading(true));
    axios.get('/tariff-z/info', { params: { soc: data.soc } })
      .then((response) => {
        _dispatch(setTariffInfo(response.data));
      })
      .catch((error) => console.error(error))
      .finally(() => _dispatch(setLoading(false)));
  }, []);

  return (
    <MainWrapper>
      {content?.bannerInfo && (
        <TeaserBanner
          withDivider
          img={content.bannerInfo.img}
          text={content.bannerInfo.text}
          title={content.bannerInfo.title}
          backgroundColor={content.bannerInfo?.backgroundColor}
        />
      )}
      <DispatchContext.Provider value={_dispatch}>
        <MainContent>
          <LeftSide>
            {content?.tabs && (
              <Tabs
                data={content.tabs}
                isTariffConnected={hasZTariff}
                isAuthenticated={data.isAuthenticated}
                isTariffAvailableForConnect={isTariffAvailableForConnect}
                activeTab={state.activeTab}
              />
            )}
            {state?.pending && (
              <div className={cx('preloader')}>
                <Preloader size="l" />
              </div>
            )}
            {state?.slides && (
              <Slides
                content={content}
                hasZTariff={hasZTariff}
                slidesInfo={state.slides}
                activeTab={state.activeTab}
                isTariffConnected={hasZTariff}
                isChangeTariff={isChangeTariff}
                saveDataTab={state.saveDataTab}
                hasRemainingSize={hasRemainingSize}
                persistentEnabledAutoProlong={hasZTariff}
                isChangePackagesTariff={isChangePackagesTariff}
              />
            )}
            {!!state?.tariffOptions?.length && (
              <Options
                hasZTariff={hasZTariff}
                activeTab={state.activeTab}
                title={content.options.title}
                options={state.tariffOptions}
                saveDataTab={state.saveDataTab}
                isChangeTariff={isChangeTariff}
                hasConnectedOptions={hasConnectedOptions}
                description={content.options.description}
                isChangeOptionsTariff={isChangeOptionsTariff}
                optionsAttentionBlockText={content.optionsAttentionBlockText}
              />
            )}
          </LeftSide>
          <RightSide>
            {state?.checkInfo && (
              <CheckZ
                total={total}
                hasZTariff={hasZTariff}
                requestStatus={requestStatus}
                defaultCheck={state.checkInfo}
                content={content.check.content}
                isChangeTariff={isChangeTariff}
                hasRemainingSize={hasRemainingSize}
                handleOrderModal={handleOrderOpenModal}
                hasConnectedOptions={hasConnectedOptions}
                isChangeOptionsTariff={isChangeOptionsTariff}
                isChangePackagesTariff={isChangePackagesTariff}
              />
            )}
          </RightSide>
        </MainContent>
      </DispatchContext.Provider>
      <TariffConnectPopup
        includeNumber={false}
        activeTab={state.activeTab}
        personalPlanData={personalPlanData}
        constructorPopupContent={{
          popupContent: {
            popupDefaultConstructor: content?.popup,
            popupCashBackConstructor: content?.popupCashBack,
          },
          price: state.totalPrice,
        }}
      />
    </MainWrapper>
  );
};

ZTariff.propTypes = {
  data: PropTypes.object,
  content: PropTypes.shape({
    popup: PropTypes.shape({
      title: PropTypes.string,
      btnText: PropTypes.string,
      description: PropTypes.string,
    }),
    tabs: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
        isActive: PropTypes.bool,
        fancyNumber: PropTypes.bool,
      })),
      title: PropTypes.string,
    }),
    bannerInfo: PropTypes.shape({
      img: PropTypes.shape({
        src: PropTypes.string,
        title: PropTypes.string,
        source: PropTypes.arrayOf(PropTypes.shape({
          media: PropTypes.string,
          srcset: PropTypes.string,
        })),
      }),
      text: PropTypes.string,
      title: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
    check: PropTypes.shape({
      content: PropTypes.object,
    }),
    options: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    optionsAttentionBlockText: PropTypes.string,
  }),
  actions: PropTypes.object,
  hasZTariff: PropTypes.bool,
  isAvailable: PropTypes.bool,
  requestStatus: PropTypes.string,
  isTariffAvailableForConnect: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ determineOrderModal }, dispatch),
});

const ConnectionZTariff = withStoreProvider(connect((state) => ({
  requestStatus: state?.external?.tariffConnectionPopup?.resultPopupData?.requestStatus,
}), mapDispatchToProps)(ZTariff));

export default ConnectionZTariff;
