import { DataTabsInterface } from '@beef/smart-kit/components/FAQ/types';
import type { FaqPropsInterface } from '@beef/smart-kit';

export const getTitle = (faq: FaqPropsInterface, panelId: string) => {
  const { data } = faq;
  let result = '';
  data.forEach(({ tabs }: DataTabsInterface) => {
    tabs.forEach((tab: { id: string; title: string }) => {
      if (tab.id === panelId) {
        result = tab.title;
      }
    });
  });
  return result;
};
