import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FlatButton = ({ children, isCloseButton, className, onClick }) => (
  <EmptyButton
    className={cx('wrapper', { closeState: isCloseButton }, className)}
    onClick={onClick}
  >
    <div className={cx('iconWrap')}>
      <Icon className={cx('icon')} name="cross" />
    </div>
    {typeof children === 'string' ?
      <span className={cx('btnText')} dangerouslySetInnerHTML={{ __html: children }} />
    : <div className={cx('btnText')}>{children}</div>}
  </EmptyButton>
);

FlatButton.propTypes = {
  children: PropTypes.node,
  isCloseButton: PropTypes.bool,
  className: PropTypes.any,
  onClick: PropTypes.func,
};

export default FlatButton;
