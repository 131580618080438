import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { setSearchQueryModule } from '@carnica/smart-kit';

import store from 'store';
import { setSearchQuery } from 'pages/SearchResultsPage/store/actions';
import { pushQuery } from 'pages/SearchResultsPage/utils/pushQuery';

export const _ListenerCorrection = ({ correction, setQuery }) => {
  useEffect(() => {
    if (correction && correction !== 'undefined') {
      setQuery(correction);
      setSearchQueryModule(correction);

      const url = new URLSearchParams(window.location.search);
      url.set('term', correction);
      pushQuery(url);
    }
  }, [correction]);

  return <div />;
};

_ListenerCorrection.propTypes = {
  setQuery: PropTypes.func,
  correction: PropTypes.string,
};

export const ConnectedComponent = connect(null, { setQuery: setSearchQuery })(_ListenerCorrection);

export const ListenerCorrection = (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
