import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Tooltip } from '@beef/legacy-ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Tariff = ({ options, description }) => (
  <div className={cx('component')}>
    {options &&
      options.map(({ label, value, unit, tooltip }, index) => (
        <div className={cx('option')} key={index}>
          <div dangerouslySetInnerHTML={{ __html: label }} />
          <div>
            <span className={cx('value')} dangerouslySetInnerHTML={{ __html: value }} />
            <span dangerouslySetInnerHTML={{ __html: unit }} />
            {tooltip && <Tooltip>{tooltip}</Tooltip>}
          </div>
        </div>
      ))}
    <div className={cx('description')}>{description}</div>
  </div>
);

Tariff.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      unit: PropTypes.string,
      tooltip: PropTypes.string,
    }),
  ),
  description: PropTypes.string,
};

export default Tariff;
