/* @ts-expect-error ошибка импорта типа TitleProps */
import { TitleProps } from '@carnica/ui-kit';
import { useIsDesktopDimension } from '@carnica/utils/hooks';

import { selectPresetsLoading } from '../selectors/selectPresetsLoading';
import { selectPresetsTitle } from '../selectors/selectPresetsTitle';
import { useAppSelector } from '../../../../store';

/** Хук подготовки данных для компонента PresetsPickers ЕК */
export const usePreparePresetsPickers = () => {
  const title = useAppSelector(selectPresetsTitle);
  const isLoading = useAppSelector(selectPresetsLoading);
  const isDesktop = useIsDesktopDimension();
  const titleContentProps = {
    title,
    subTitle: '',
    size: isDesktop ? 'm' : 's',
    align: 'left',
    titleTagName: 'h2',
  } as TitleProps;

  return { titleContentProps, isLoading };
};
