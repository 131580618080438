import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AdditionalCost = memo(({ title, description, price, unit }) => (
  <div className={cx('wrapper')}>
    <div>
      {title && (
        <Text size="size5-m" tagType="h3">
          {title}
        </Text>
      )}
      {description && (
        <div className={cx('description')}>
          <Text color="grey60" size="size5-r-s">
            {description}
          </Text>
        </div>
      )}
    </div>
    {price && (
      <Text color="grey95" size="size5-r-s">
        {`${price} ${unit}`}
      </Text>
    )}
  </div>
));

AdditionalCost.propTypes = {
  title: PropTypes.string,
  price: PropTypes.number,
  unit: PropTypes.string,
  description: PropTypes.string,
};

export default AdditionalCost;
