import React, { FC, useRef } from 'react';
import classNames from 'classnames/bind';
import { FontSizeType, Text } from '@beef/ui-kit';

import { extractHref } from 'pages/ProductCard/Tariff/utils';

import { pushYm } from '../../../../tempAnalytics/metricUtils';
import { useOnScreenAnalytics } from '../../../../tempAnalytics/hooks/useOnScreenAnalytics';
import { DEFAULT_COLOR } from '../../../../constants';
import { TServiceType } from '../../../constructorPage/ServiceTiles/tempYmUtils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TDescription = {
  description?: string;
  extraSize: FontSizeType;
  icon?: string;
  /** Вариант контентного цвета для description.
   * Таких цветов нет в FontColorType, поэтому задаем inline цвет для родительской обертки
   * и через color="inherit" подтягиваем его) */
  textColor?: string;
  /** Передаем тип основного блока для навешивания аналитики */
  type?: TServiceType;
};

/** Компонент доп. текста */
export const Description: FC<TDescription> = ({
  icon,
  extraSize,
  textColor = DEFAULT_COLOR,
  description,
  type,
}) => {
  const presetsRef = useRef(null);

  /** Отправка аналитики если блок промо информации при партнёрском конвергенте виден больше 2х секунд */
  if (type === 'tv') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useOnScreenAnalytics({
      ref: presetsRef,
      payload: {
        block_fmc: 'notification',
        notification_text: description,
      },
    });
  }

  /** Функция при клике на описание в блоке "Домашний интернет и ТВ" */
  const addAnalyticsOnClick = () => {
    if (type === 'tv') {
      /* Отправка аналитики */
      pushYm({
        block_fmc: 'link_click',
        link_text: description,
        link_url: extractHref(description!),
      });
    }
  };

  return (
    <div
      className={cx('wrapper')}
      onClick={addAnalyticsOnClick}
      ref={presetsRef}
      style={{ color: textColor }}
    >
      {icon && <img alt="icon" className={cx('wrapper__icon')} loading="lazy" src={icon} />}
      {description && (
        <Text color="inherit" size={extraSize}>
          {description}
        </Text>
      )}
    </div>
  );
};
