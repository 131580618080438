import { FILTER_FAVORITE, FILTER_SIMILAR, FILTER_TAIL } from './constants';

const minMax =
  (min: number, max: number) =>
  (value = ''): boolean =>
    value.length >= min && value.length <= max;

export const FILTER_VALIDATORS = {
  [FILTER_FAVORITE]: minMax(2, 7),
  [FILTER_TAIL]: minMax(2, 4),
  [FILTER_SIMILAR]: minMax(2, 7),
};
