import { useCallback } from 'react';

import { TUseMapStateToProps } from 'pages/Services/ZeroBalanceSupport/enhancers/withContext/types';
import {
  selectTrustPaymentData,
  selectTrustPaymentSuccess,
} from 'pages/Services/ZeroBalanceSupport/selectors';
import { TZeroBalanceContext } from 'pages/Services/ZeroBalanceSupport/types';
import { templateParser } from 'utils/format-string';

import { TModalWrapperProps } from '../../../ModalWrapper/types';
import { EModal } from '../types';

export const useMapStateToProps: TUseMapStateToProps<TZeroBalanceContext, TModalWrapperProps> = ({
  state,
  ownProps: { setCurrentModal },
}) => {
  const modalData = selectTrustPaymentSuccess(state);
  const trustPaymentData = selectTrustPaymentData(state);
  const title = modalData.title && templateParser(modalData.title, trustPaymentData);
  const description =
    modalData.description && templateParser(modalData.description, trustPaymentData);

  const onClick = useCallback(() => {
    setCurrentModal(EModal.ConnectConfirmStep);
  }, [setCurrentModal]);

  const onClose = useCallback(() => {
    setCurrentModal(null);
  }, [setCurrentModal]);

  return {
    onClose,
    setCurrentModal,
    content: {
      modalData: {
        ...modalData,
        title,
        description,
      },
      actionBarProps: {
        loading: false,
        confirmButton: {
          content: modalData?.button || {},
          onClick,
        },
        rejectButton: {
          content: modalData?.buttonReject,
          onClick: onClose,
        },
      },
    },
  };
};
