import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import styles from './UssdForm.pcss';

const cx = classNames.bind(styles);

const UssdForm = ({ ussd, description, onlyMobileUssd, mobileClassName }) => {
  const showCallButton = !ussd.match(/[а-я]/gi);
  const clearedUssd = ussd.replace(/\s/g, '');

  return (
    <>
      {description && (
        <div className={cx('description')} dangerouslySetInnerHTML={{ __html: description }} />
      )}

      {!onlyMobileUssd && (
        <div className={cx(showCallButton ? 'ussdShowOnlyOnDesktop' : 'ussdShowOnMobileToo')}>
          <a className={cx('desktopUssd')} href={`tel:${clearedUssd}`}>
            <span dangerouslySetInnerHTML={{ __html: ussd }} />
            <Icon className={cx('phoneIcon')} name="phoneHandset" />
          </a>
        </div>
      )}

      {showCallButton && (
        <a className={cx('mobileUssd', mobileClassName)} href={`tel:${clearedUssd}`}>
          <Button className={['big', 'light']} type="submit" wide>
            <div className={cx('phoneButton')}>
              <span dangerouslySetInnerHTML={{ __html: ussd }} />
              <Icon className={cx('buttonIcon')} name="phoneHandset" />
            </div>
          </Button>
        </a>
      )}
    </>
  );
};

UssdForm.defaultProps = { onlyMobileUssd: false };

UssdForm.propTypes = {
  ussd: PropTypes.string,
  description: PropTypes.string,
  onlyMobileUssd: PropTypes.bool,
  mobileClassName: PropTypes.any,
};

export default UssdForm;
