// проверяем клик по кнопку для отображения пароля PasswordInput
export const onShowPasswordClick = (event, isTypePassword, onShow, onHide) => {
  if (event.target.tagName !== 'INPUT') {
    if (isTypePassword) {
      onShow?.();
    } else {
      onHide?.();
    }
  }
};
