import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { InfoCard } from '@beef/smart-kit';

import { BenefitsType } from 'pages/SPN/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const Benefits: FC<BenefitsType> = ({ data }) => (
  <div className={cn('wrapper')} data-t-id="Benefits">
    {data?.map((el, idx) => (
      <div className={cn('card')} key={+idx}>
        <InfoCard icon={el.icon} text={el.description} title={el.title} />
      </div>
    ))}
  </div>
);
