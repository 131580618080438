import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, redirect } from 'react-router-dom';

import ServicesCatalog from './components/ServicesCatalog';
import { withReactRouter } from '../../enhancers/withReactRouter';

const serverEnv = typeof SERVER_ENV !== 'undefined' && SERVER_ENV;

const getDeviceFromPathname = (defaultAlias) => {
  if (typeof window === 'undefined') return defaultAlias;

  const parts = window.location.pathname.split('/');
  const deviceIndex = parts.findIndex((part) => part === 'services') + 1;
  return parts[deviceIndex] || defaultAlias;
};

const ServicesRouted = ({ renderUrl, baseUrl, data, ...props }) => {
  const defaultDevice = data.devices.find((d) => d.isActive === true);
  const { alias } = defaultDevice || {};

  const activeDevice = getDeviceFromPathname(alias);

  useEffect(() => {
    const pageUrl = serverEnv ? renderUrl : baseUrl;
    redirect(`${pageUrl}${alias}`);
  }, [alias, baseUrl, renderUrl]);

  return (
    <Routes>
      <Route
        element={
          <ServicesCatalog {...props} activeDevice={activeDevice} baseUrl={baseUrl} data={data} />
        }
        path={`${baseUrl}*`}
      />
    </Routes>
  );
};

ServicesRouted.defaultProps = {
  baseUrl: '/customers/products/mobile/services/',
  renderUrl: '/customers/products/mobile/services/',
};

ServicesRouted.propTypes = {
  baseUrl: PropTypes.string,
  renderUrl: PropTypes.string,
  data: PropTypes.shape({
    devices: PropTypes.arrayOf(
      PropTypes.shape({
        isActive: PropTypes.bool.isRequired,
        alias: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default withReactRouter(ServicesRouted);
