import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const PackageInfo = ({
  unit,
  value,
  price,
  period,
  unitPrice,
  hasZTariff,
  initialPeriod,
  remainingSize,
}) => (
  <li className={cx('wrapper')}>
    <div className={cx('wrapper__info')}>
      {!!remainingSize && hasZTariff && (
        <div className={cx('wrapper__remainder')}>
          <Text color="grey60" size="size5-r-s">
            {`${remainingSize} ${unit} ${value ? '+' : initialPeriod}`}
          </Text>
        </div>
      )}
      {!!value && (
        <Text color="grey95" size="size5-r-s">
          {`${value} ${unit} ${period}`}
        </Text>
      )}
    </div>
    {!!value && (
      <Text color="grey95" size="size5-r-s">
        {`${price} ${unitPrice}`}
      </Text>
    )}
  </li>
);

PackageInfo.propTypes = {
  unit: PropTypes.string,
  value: PropTypes.number,
  price: PropTypes.number,
  period: PropTypes.string,
  hasZTariff: PropTypes.bool,
  unitPrice: PropTypes.string,
  remainingSize: PropTypes.number,
};
