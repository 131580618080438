import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Emoji, Heading, Link, Status } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import styles from './ChangeTariffResultPopup.pcss';

const cx = classNames.bind(styles);

class ChangeTariffResultPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  handleClick() {
    if (this.props.buttonUrl) {
      window.location.href = this.props.buttonUrl;
    } else if (this.props.onButtonClick) {
      this.props.onButtonClick();
    }
  }

  handleCancelClick() {
    if (this.props.buttonResetUrl) {
      window.location.href = this.props.buttonResetUrl;
    } else if (this.props.onCancelButtonClick) {
      this.props.onCancelButtonClick();
    } else {
      this.props.closePopup();
    }
  }

  renderFormattedText(text) {
    return text ? <span dangerouslySetInnerHTML={{ __html: text }} /> : null;
  }

  render() {
    if (!this.props.opened) return null;

    const { title, content, buttonText, buttonResetText, titleIcon, serviceOfferUssd } = this.props;

    return (
      <div className={cx('content')}>
        <Heading level={1}>
          {this.renderFormattedText(title)}
          {titleIcon && <Emoji className="inHeading" path={titleIcon} />}
        </Heading>
        <div className={cx('description')}>{this.renderFormattedText(content)}</div>
        {this.props.warningText ?
          <div className={cx('warning')}>
            <Status className="attention" emoji="pointingHandUp">
              <div className={cx('warningContent')}>
                {this.renderFormattedText(this.props.warningText)}
              </div>
            </Status>
          </div>
        : null}

        {this.props.serviceOffer ?
          <div className={cx('offer')}>
            {this.props.serviceOfferImage && (
              <div className={cx('offerImage')}>
                <img alt="" src={this.props.serviceOfferImage} />
              </div>
            )}
            <div className={cx('offerContent')}>{this.props.serviceOffer}</div>
            {serviceOfferUssd && (
              <div className={cx('ussd')}>
                {serviceOfferUssd}
                <a href={`tel:${serviceOfferUssd}`}>
                  <Icon className={cx('phoneIcon')} name="phoneHandset" />
                </a>
              </div>
            )}
            {this.props.serviceOfferDescription && (
              <div className={cx('offerDescription')}>{this.props.serviceOfferDescription}</div>
            )}
          </div>
        : null}

        <div className={cx('buttons')}>
          {buttonText && (
            <div className={cx('confirm-button')}>
              <Button className="big" onClick={this.handleClick}>
                {buttonText}
              </Button>
            </div>
          )}
          {buttonResetText && (
            <div className={cx('cancel-button')}>
              <Link onClick={this.handleCancelClick}>{buttonResetText}</Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ChangeTariffResultPopup.propTypes = {
  opened: PropTypes.bool,
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  content: PropTypes.string,
  warningText: PropTypes.string,
  serviceOffer: PropTypes.string,
  serviceOfferDescription: PropTypes.string,
  serviceOfferImage: PropTypes.string,
  buttonUrl: PropTypes.string,
  serviceOfferUssd: PropTypes.string,
  buttonText: PropTypes.string,
  buttonResetText: PropTypes.string,
  buttonResetUrl: PropTypes.string,
  onButtonClick: PropTypes.func,
  onCancelButtonClick: PropTypes.func,
  closePopup: PropTypes.func,
};

export default ChangeTariffResultPopup;
