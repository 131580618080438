import React from 'react';

import { useSelectedDateSlots } from './hooks/useSelectedDateSlots';
import { CarouselSlots } from '../CarouselSlots';

export const DateSlots = () => {
  const props = useSelectedDateSlots();

  return <CarouselSlots {...props} />;
};
