import cn from 'classnames/bind';

import { CSSAnimatedComponent } from 'pages-components/Animations';

import styles from './styles.pcss';

const cx = cn.bind(styles);

const zoomIn = () => ({
  enter: cx('zoomInEnter'),
  enterActive: cx('zoomInEnterActive'),
  exit: cx('zoomInExit'),
  exitActive: cx('zoomInExitActive'),
});

const slideDown = () => ({
  enter: cx('slideDownEnter'),
  enterActive: cx('slideDownEnterActive'),
  exit: cx('slideDownExit'),
  exitActive: cx('slideDownExitActive'),
});

const slideDownAndFade = () => ({
  enter: cx('slideDownAndFadeEnter'),
  enterActive: cx('slideDownAndFadeEnterActive'),
  exit: cx('slideDownAndFadeExit'),
  exitActive: cx('slideDownAndFadeExitActive'),
});

const defaultProps = {
  mountOnEnter: true,
  unmountOnExit: true,
  appear: true,
};

export const ZoomIn = CSSAnimatedComponent(zoomIn());
ZoomIn.defaultProps = defaultProps;

export const SlideDown = CSSAnimatedComponent(slideDown());
SlideDown.defaultProps = defaultProps;

export const SlideDownAndFade = CSSAnimatedComponent(slideDownAndFade());
SlideDownAndFade.defaultProps = defaultProps;
