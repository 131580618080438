/**
 * @desc: готовим данные для попапа заказа сим в нужном формате
 * @param data
 * @param simOrderPlace
 * @returns {{
 * dataFetched: boolean, deliveryForm: *, mnpWidget: *, simDiscount: *, simOrderPlace: *, id: *, esimPopupUrl: *}}
 */
const formatConnectPopupUrlResponse = ({ data, simOrderPlace }) => {
  const { tariffDetails, ...restData } = data || {};
  return {
    ...restData,
    id: tariffDetails?.id,
    mnpWidget: tariffDetails?.mnpWidget,
    deliveryForm: tariffDetails?.deliveryForm,
    simDiscount: tariffDetails?.simDiscount,
    esimPopupUrl: tariffDetails?.esim?.popupUrl,
    dataFetched: true,
    simOrderPlace,
  };
};

export default formatConnectPopupUrlResponse;
