import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Input, Preloader, Text } from '@beef/ui-kit';

import { onShowPasswordClick } from 'pages/Login/Authorization2021/utils/common';

import styles from './styles.pcss';
import { RefreshIcon } from '../../icons';
import { ID_CONFIG } from '../../actions/constants';
import { YMAuthEvent } from '../../utils/ym';

const cx = classNames.bind(styles);

class SMSCodeForm extends Component {
  state = {
    needValidation: false,
    codeInputRender: false,
    value: '',
    error: '',
    step: '',
  };

  componentDidMount() {
    if (!this.props.authError && this.props.fetchingSMS) {
      this.props.handleChangeSmsStep('loading');
    }
    if (this.testRef && this.testRef.current) {
      this.props.handleChangeSmsStep('success');
      this.testRef.current = false;
    }
  }

  componentDidUpdate(prevProps) {
    const { authError, smsBlocked, fetchingSMS, handleChangeSmsStep, errorMessage } = this.props;
    if (authError) {
      /* бросаем наверх какой сейчас у нас идет этап чтобы корректно отразить экшнбар в модалке */
      handleChangeSmsStep('error');
    }
    if (!authError && fetchingSMS && !this.testRef.current) {
      handleChangeSmsStep('loading');
    }
    if (!authError && prevProps.fetchingSMS !== fetchingSMS) {
      handleChangeSmsStep('success');
    }
    if (
      !authError &&
      !smsBlocked &&
      (!fetchingSMS || this.testRef.current) &&
      !this.state.codeInputRender
    ) {
      this.setState({
        codeInputRender: true,
      });
    }
    if (errorMessage !== prevProps.errorMessage) {
      YMAuthEvent.smsError(errorMessage);
    }
  }

  testRef = createRef();

  handleChange = (event) => {
    this.setState({ value: event.target.value });
    if (event.target.value.length === 5) {
      YMAuthEvent.smsPasswordFill();
    }
    /* бросаем наверх вэлью чтобы в экшнбаре модалки кнопка могла дизэйблиться когда инпут не заполнен */
    this.props.handleInput(event.target.value);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (/^\d{5}$/.test(this.state.value)) {
      this.setState({ error: '' });
      event.target.submit();
    } else {
      YMAuthEvent.error('sms', this.props.data.code.invalidFormat);
      this.setState({ error: this.props.data.code.invalidFormat });
    }
  };

  handleFocus = () => {
    this.setState({ error: '' });
    YMAuthEvent.smsPasswordClick();
  };

  handleClick = () => {
    this.props.requestSMS();
    if (this.testRef && this.testRef.current) {
      this.testRef.current = true;
    }
    YMAuthEvent.smsRepeat();
    this.props.handleInput('');
  };

  render() {
    const { value, error } = this.state;
    const { ctn, fetchingSMS, head, authError, action, children, errorMessage, smsBlocked } =
      this.props;
    const smsCodeForm = this.props.data?.code || {};
    const header = smsCodeForm.header || {};
    const renderPreloader = !authError && !smsBlocked && fetchingSMS && !this.testRef.current;
    const renderForm = !authError && !smsBlocked && (!fetchingSMS || this.testRef.current);

    return (
      <div className={cx('sms-code-form')}>
        {authError && !smsBlocked && (
          <div>
            <div className={cx('sms-code-form__status')}>
              <div className={cx('sms-code-form__status-text')}>
                <Text size="size5-r-l">{errorMessage}</Text>
              </div>
            </div>
            <button
              className={cx(
                'sms-code-form__refresh-button',
                'sms-code-form__refresh-button--error',
              )}
              onClick={this.handleClick}
              type="button"
            >
              {header.repeat}
              <div className={cx('sms-code-form__refresh-icon')}>
                <RefreshIcon />
              </div>
            </button>
          </div>
        )}
        {smsBlocked && (
          <>
            {head}
            <div className={cx('sms-code-form__form')}>
              <Text size="size5-r-s">{header.blocked}</Text>
            </div>
          </>
        )}
        {renderPreloader && (
          <div className={cx('sms-code-form__loading')}>
            <div className={cx('sms-code-form__loader')}>
              <Preloader />
            </div>
            <Text size="size5-m">{header.sending}</Text>
          </div>
        )}
        {renderForm && (
          <>
            <div className={cx('sms-code-form__number')} ref={this.testRef}>
              {head}
              <div className={cx('sms-code-form__sending')}>
                <span className={cx('sms-code-form__text')}>{header.success}</span>
                <button
                  className={cx('sms-code-form__refresh-button')}
                  onClick={this.handleClick}
                  type="button"
                >
                  {header.repeat}
                  <div className={cx('sms-code-form__refresh-icon')}>
                    <RefreshIcon />
                  </div>
                </button>
              </div>
            </div>
            <div className={cx('sms-code-form__form')}>
              <form
                action={action}
                id={ID_CONFIG.smsCode}
                method="POST"
                onSubmit={this.handleSubmit}
              >
                <Input
                  className={cx('sms-code-form__input-wrapper')}
                  errorMessage={error}
                  extraAttributes={{
                    maxLength: 5,
                  }}
                  label={smsCodeForm.label}
                  name="password"
                  onChange={this.handleChange}
                  onClick={(event, isTypePassword) => {
                    onShowPasswordClick(event, isTypePassword, () => YMAuthEvent.smsPasswordShow());
                  }}
                  onFocus={this.handleFocus}
                  placeholder={smsCodeForm.placeholder}
                  type="password"
                  value={value}
                />
                <input hidden id="login" name="login" readOnly value={ctn} />
                {children}
              </form>
            </div>
          </>
        )}
      </div>
    );
  }
}

SMSCodeForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  ctn: PropTypes.string,
  action: PropTypes.string,
  requestSMS: PropTypes.func,
  head: PropTypes.element,
  fetchingSMS: PropTypes.bool,
  authError: PropTypes.bool,
  smsBlocked: PropTypes.bool,
  children: PropTypes.node,
  handleInput: PropTypes.func,
  handleChangeSmsStep: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default SMSCodeForm;
