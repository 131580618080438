import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setCartData } from '@carnica/smart-kit';
import {
  CART_URL,
  formatAdditionalOptions,
  formatOrderData,
  handleSuccessRequest,
} from '@beef/layout-kit/utils';
import { Button, Link, Radio, Text } from '@beef/ui-kit';

import { sendEsimPurchaseEvent, showErrorPopup } from 'pages/ProductCard/actions/tariffConnect';
import FancyNumberWidget from 'pages/ProductCard/components/FancyNumberWidget/FancyNumberWidget';
import FancyNumberWidgetHOC from 'pages/ProductCard/hoc/FancyNumberWidgetHOC';
import Ctx from 'pages-components/Ctx';
import { isNumberBought } from 'utils/cartHelpers';
import { maskPhone } from 'utils/format-string';
import { addToCartSimCardYM } from 'utils/analytics/tariffsYandexMetrica';
import { pushEcommerceBuySim } from 'utils/analytics/simOrderAnalytics';
import { ymSimMNPAddToCart } from 'utils/analytics/ymCommonEvents';
import { ETariffsVkGoals, vkPushSimTariffEvent } from 'utils/analytics/simTariffVkEvents';
import { headerStore2024 } from 'store';

import EsimDescription from '../EsimDescription';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const STATUSES = {
  sim: 'sim',
  esim: 'esim',
};

const getDefaultOption = ({ esimAvailable, availableInShop }) => {
  if (availableInShop) {
    return STATUSES.sim;
  }
  if (esimAvailable) {
    return STATUSES.esim;
  }
  return null;
};

/**
 * Форма добавления сим в корзину (через api корзины)
 */
const SimCartFormContent = ({
  alias,
  note,
  availableInShop,
  chosenNumber,
  onStoreButtonClick,
  onNumberChange,
  onRequestError,
  id,
  soc,
  trafficPackage,
  cartApi,
  rootCtnChosenIndex,
  onRootCtnChosenIndexChange,
  additionalOptions,
  handleSetCartData,
  openFancyNumberModal,
  fancyNumberModalChoice,
  esimData,
  boughtNumbers,
  simOrderPlace,
  analyticsData,
  constructorTariffData,
  constructorParams,
  personalPlanData,
  sendAnalytics,
}) => {
  const [currentOption, setCurrentOption] = useState(
    getDefaultOption({ esim: !!esimData, availableInShop }),
  );
  const [submitStatus, setSubmitStatus] = useState('initial');
  const [choiceAvailable, setChoiceAvailable] = useState(!!esimData && availableInShop);

  useEffect(() => {
    setChoiceAvailable(!!esimData && availableInShop);
  }, [esimData, availableInShop]);

  // eslint-disable-next-line consistent-return
  const handleChangeNumber = useMemo(() => {
    setSubmitStatus('initial');
    if (onNumberChange) {
      return onNumberChange;
    }
  }, [onNumberChange]);

  const isBought = useMemo(
    () => isNumberBought(boughtNumbers, chosenNumber),
    [boughtNumbers, chosenNumber],
  );

  const simPurchaseChosen = currentOption === STATUSES.sim;
  const formDisabled = submitStatus === 'pending';
  /* доп опции для тарифа */
  const additionalSimOptions = formatAdditionalOptions(additionalOptions);

  const handleBuySim = async () => {
    /** Добавление Сим карты в корзину Клик на Купить */
    addToCartSimCardYM(document.location.href);
    ymSimMNPAddToCart();
    vkPushSimTariffEvent(alias, ETariffsVkGoals.SimTariffBasket);
    setSubmitStatus('pending');
    const requestData = formatOrderData({
      tariffId: id,
      ctn: chosenNumber,
      additionalSimOptions,
      simOrderPlace,
      constructorTariffData,
      personalPlanData,
    });

    pushEcommerceBuySim(
      {
        id,
        soc,
        trafficPackage,
        pageTitle: analyticsData.name,
        subscriptionFee: analyticsData.price,
      },
      constructorParams,
    );

    try {
      const { data } = await cartApi.post(requestData.apiEndpoint, requestData.body);
      if (data.id) {
        handleSuccessRequest({
          cartId: data.id,
          items: data.items,
        });

        if (data) {
          handleSetCartData(data);
        }
        if (sendAnalytics) {
          sendAnalytics({ isConnect: true });
        }
        setSubmitStatus('success');
      } else {
        onRequestError(Ctx.defaultError);
      }
    } catch (e) {
      onRequestError(Ctx.defaultError);
    }
  };

  return (
    <div className={cx('wrap')}>
      <div
        className={cx({
          'radio-list': !!choiceAvailable,
        })}
      >
        {choiceAvailable && (
          <div className={cx('radio-list-item')}>
            <Radio
              checked={currentOption === STATUSES.sim}
              disabled={formDisabled}
              label="Купить обычную SIM"
              name="sim-type"
              onChange={(e) => {
                setCurrentOption(e.target.value);
              }}
              radioVariant="default"
              value={STATUSES.sim}
            />
          </div>
        )}
        {simPurchaseChosen && (
          <div className={cx('number-widget-wrap')}>
            {fancyNumberModalChoice ?
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {chosenNumber && (
                  <>
                    <div
                      className={cx('number-view', {
                        'number-view--success': isBought,
                      })}
                    >
                      {maskPhone(chosenNumber)}
                    </div>
                    <div
                      className={cx('number-choice-btn', {
                        'number-choice-btn--to-left': choiceAvailable,
                      })}
                    >
                      <Button onClick={openFancyNumberModal} size="m" variant="plain">
                        Выбрать другой номер
                      </Button>
                    </div>
                  </>
                )}
              </>
            : <FancyNumberWidgetHOC
                boughtNumbers={boughtNumbers}
                chosenNumber={chosenNumber}
                disabled={formDisabled}
                number={chosenNumber}
                onNumberChange={handleChangeNumber}
                onRootChosenIndexChange={onRootCtnChosenIndexChange}
                rootChosenIndex={rootCtnChosenIndex}
              >
                <FancyNumberWidget />
              </FancyNumberWidgetHOC>
            }
          </div>
        )}
        {choiceAvailable && (
          <div className={cx('radio-list-item')}>
            <Radio
              checked={currentOption === STATUSES.esim}
              disabled={formDisabled}
              label="eSIM онлайн"
              name="sim-type"
              onChange={(e) => {
                setCurrentOption(e.target.value);
              }}
              radioVariant="default"
              value={STATUSES.esim}
            />
          </div>
        )}
      </div>
      {currentOption === STATUSES.esim && esimData && (
        <div className={cx('esim-description')}>
          <EsimDescription
            appButtons={esimData.appButtons}
            descriptionList={esimData.descriptionList}
            onStoreButtonClick={onStoreButtonClick}
            qrCodeUrl={esimData.qrCodeUrl}
          />
        </div>
      )}
      {note && (
        <div className={cx('note')}>
          <Text size="size6-r">{note}</Text>
        </div>
      )}
      {simPurchaseChosen && (
        <div className={cx('submit')}>
          {isBought ?
            <Link
              buttonSize="l"
              buttonVariant="secondary"
              fullWidth
              href={CART_URL}
              variant="button"
            >
              Перейти в корзину
            </Link>
          : <Button disabled={formDisabled} fullWidth onClick={handleBuySim} size="l">
              Купить
            </Button>
          }
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onRequestError: (params) => {
    dispatch(showErrorPopup(params));
  },
  handleSetCartData: (data) => headerStore2024().dispatch(setCartData(data)),
  onStoreButtonClick: () => dispatch(sendEsimPurchaseEvent),
});

const mapStateToProps = (state) => ({
  additionalOptions: state.external?.tariffConnectionPopup?.additionalOptions,
  cartApi: state.external?.cartData?.cartApi,
  boughtNumbers: state?.external?.cartData?.currentCart?.numbers ?? [],
});

SimCartFormContent.propTypes = {
  /* текст - сноска */
  note: PropTypes.string,
  /* выбранный номер телефона */
  chosenNumber: PropTypes.string,
  /* заказанные номера */
  boughtNumbers: PropTypes.arrayOf(PropTypes.string),
  /* Данные для блока есим */
  esimData: PropTypes.shape({
    descriptionList: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        tooltip: PropTypes.string,
      }),
    ),
    qrCodeUrl: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    appButtons: PropTypes.object,
  }),

  /* данные для аналитики */
  // eslint-disable-next-line react/forbid-prop-types
  analyticsData: PropTypes.object,

  /* id тарифа */
  id: PropTypes.number,
  alias: PropTypes.string,
  soc: PropTypes.string,
  trafficPackage: PropTypes.string,

  /* флаг для отображения кнопки для перехода в попап выбора красивых номеров */
  fancyNumberModalChoice: PropTypes.bool,

  /* индекс выбраного номера, для синхронизаии нескольких виджетов выбора номера */
  rootCtnChosenIndex: PropTypes.number,

  /* флаг доступности товара в магазине */
  availableInShop: PropTypes.bool,

  /* доп опции для заказа сим */
  // eslint-disable-next-line react/forbid-prop-types
  additionalOptions: PropTypes.object,

  /* сущность axios для взаимодействия с корзиной */
  cartApi: PropTypes.func,
  /* место, откуда был сделан заказ, нужно для апикеяНеАпикея в заказе новой корзины */
  simOrderPlace: PropTypes.string,
  onNumberChange: PropTypes.func,
  /* обновление индекса выбранного номера для виджета красивого номера */
  onRootCtnChosenIndexChange: PropTypes.func,

  /* открыть модальное окно с выбором номеров */
  openFancyNumberModal: PropTypes.func,

  /* отображение попапа с ошибкой */
  onRequestError: PropTypes.func,

  handleSetCartData: PropTypes.func,
};

const SimCartForm = connect(mapStateToProps, mapDispatchToProps)(SimCartFormContent);

export default SimCartForm;
