/** Базовый URL запроса данных по total чеку */
export const apiGetTotalUrl = '/constructor-api/get-total/';

/** Получение актуальной цены по персонажу ( */
export const apiGetUpperPriceUrl = '/constructor-api/get-upper-price/';

/** Получение пресетов тарифа (Гб, Мин) в НЗ/АЗ */
export const apiGetPresetsUrl = '/constructor-api/change-preset/';

/** Получение доступных ШПД-пресетов тарифа в НЗ (табы ШПД на главной странице конструктора) */
export const apiGetFmcPresets = '/customers/products/home/request/fmcpresets/';

/** Метод подключения тарифа в НЗ с настроенным ШПД */
export const apiConnectTariffWithConvergence = '/api/convergence/connect-convergence-tariff/';

/** Получение данных для настройки ШПД в НЗ (доступные сервисы, пресеты, оборудование и т.п. ) */
export const apiGetFmcServices = '/customers/products/home/request/fmcservices/';

/** Базовый URL для запроса валидации номера mnp */
export const apiValidationUrl = '/customers/products/mobile/services/validatemnporder/';

/** Получение доступных партнерских ШПД-тарифов */
export const apiGetPartnerFmc = '/hometariffsapi/FmcPartnerNotAuth';

/** Эндпоинт метода отправки заявки на обратный звонок */
export const apiSubmitCallbackRequest = '/hometariffsapi/SubmitCallbackRequest';

/** Базовый URL запроса данных по total чеку */
export const apiGetTariffChangesUrl = '/constructor-api/get-changes/';

/** эндпоинт метода получения слотов монтажника */
export const apiAvailableSchedule = '/hometariffsapi/getavailableschedule/';
