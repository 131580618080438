import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TDiscount = {
  discountText?: string;
};

/** Блок скидки в total-чеке */
export const Discount = memo<TDiscount>(({ discountText }) =>
  discountText ?
    <div className={cx('wrapper')}>
      <Text color="inherit" size="size5-m">
        {discountText}
      </Text>
    </div>
  : null,
);
