import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Emoji } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import InfoPopup from 'pages-components/InfoPopup';
import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Banners = ({ banners }) => (
  <div className={cx('banners')}>
    {banners.map((banner, index) => (
      <Banner key={index} {...banner} />
    ))}
  </div>
);

Banners.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.object),
};

export default Banners;

const Banner = ({
  title,
  description,
  mobileBackground,
  desktopBackground,
  dataUrl,
  icon,
  price: { value, trialPeriod, dailyPeriod, showPresent, secondValue },
}) => {
  const [showPopup, toggleShowPopup] = useState(false);
  const period = dailyPeriod ? '&#8381;/сутки' : '&#8381;/мес';
  const unit = trialPeriod ? '&#8381; ' : period;
  const valueToString = value && value.toString().replace('.', ',');
  const secondValueToString = secondValue && secondValue.toString().replace('.', ',');
  const isMobileBg =
    (typeof window !== 'undefined' && window?.matchMedia('(max-width: 768px)').matches) || false;

  return (
    <>
      <div
        className={cx('banner')}
        onClick={() => toggleShowPopup(true)}
        role="button"
        style={{
          backgroundImage: `url(${isMobileBg ? mobileBackground : desktopBackground})`,
        }}
        tabIndex="0"
      >
        <div className={cx('bannerContent')}>
          <div className={cx('info')}>
            <span className={cx('title', 'boldText')} dangerouslySetInnerHTML={{ __html: title }} />
            {icon && <Icon className={cx('icon')} src={icon} />}
            <p
              className={cx('description', 'simpleText')}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>

          <div className={cx('parameters')}>
            <span
              className={cx('value', 'boldText')}
              dangerouslySetInnerHTML={{ __html: `${valueToString} ${unit}` }}
            />

            {trialPeriod && (
              <span
                className={cx('period', 'simpleText')}
                dangerouslySetInnerHTML={{ __html: trialPeriod }}
              />
            )}

            {showPresent && <Emoji className={cx('emoji')} name="present" />}

            {secondValue && (
              <p
                className={cx('secondValue', 'simpleText')}
                dangerouslySetInnerHTML={{ __html: `далее ${secondValueToString} ${period}` }}
              />
            )}
          </div>
        </div>

        <div className={cx('arrowWrap')}>
          <Icon className={cx('arrow')} name="dropdownArrow" />
        </div>
      </div>

      {showPopup && <InfoPopup fetchUrl={dataUrl} onClose={() => toggleShowPopup(false)} />}
    </>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  mobileBackground: PropTypes.string,
  desktopBackground: PropTypes.string,
  dataUrl: PropTypes.string,
  icon: PropTypes.string,
  price: PropTypes.shape({
    value: PropTypes.number,
    trialPeriod: PropTypes.string,
    dailyPeriod: PropTypes.bool.isRequired,
    showPresent: PropTypes.bool,
    secondValue: PropTypes.number,
  }),
};
