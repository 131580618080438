import React from 'react';
import classNames from 'classnames/bind';
import { Button } from '@carnica/ui-kit';
import { withDataConverter } from '@beef/react';
import { compose } from '@beef/redux';

import type { IFixedButton } from '../../types';
import styles from './styles.pcss';
import { StoresIcons } from '../StoresIcons';
import { Metric } from '../../metric';

const cx = classNames.bind(styles);

const DownloadButtons_: React.FC<IFixedButton> = ({
  storeLinks,
  title = '',
  isLightColor,
  screenTitle,
  className,
  handleClickModal,
}) => {
  const links = {
    googlePlay: storeLinks?.googlePlayLink,
    appGallery: storeLinks?.huaweiLink,
    appStore: storeLinks?.appleLink,
    ruStore: storeLinks?.ruStoreLink,
  };

  return (
    <>
      <Metric
        asChild
        data={JSON.stringify({
          title: screenTitle,
          buttonText: title,
        })}
        events={{ mobileApp2024Metric: ['handleModalButtonClick'] }}
        mode="click"
      >
        <Button
          className={cx('btn', className)}
          onClick={handleClickModal}
          size="l"
          view={isLightColor ? 'secondary' : 'tertiary'}
        >
          {title}
        </Button>
      </Metric>
      <div className={cx('icons')}>
        <StoresIcons isLightColor={isLightColor} screenTitle={screenTitle} storeLinks={links} />
      </div>
    </>
  );
};

export const dataConverter = (content: IFixedButton) => ({
  ...content,
  links: {
    googlePlay: content?.storeLinks?.googlePlayLink,
    appGallery: content?.storeLinks?.huaweiLink,
    appStore: content?.storeLinks?.appleLink,
    ruStore: content?.storeLinks?.ruStoreLink,
  },
});

export const DownloadButtons = compose(withDataConverter(dataConverter))(DownloadButtons_);
