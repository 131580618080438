import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { __, difference, path, pathOr, pipe, without } from 'ramda';
import { ProductParameter, ProductParameters } from '@beef/legacy-ui-kit';

import { SET_BLACK_NUMBERS, openBlackList } from '../../../actions/blackList';
import { startRequest } from '../../ServiceStatusPopup/actions';
import parentStyles from '../../../ProductCard.pcss';
import { responseHandler } from '../utils/responseHandler';
import { getNumbersDiff } from '../utils/numbers';
import BlackListAddedNumbers from './BlackListAddedNumbers';
import BlackListLastCalls from './BlackListLastCalls';
import BlackListedNumbers from './BlackListedNumbers';

const getPhoneList = path(['external', 'blackList', 'blackNumbers']);
const getServerList = pathOr([], ['external', 'blackList', 'blackNumbersFromServer']);

class BlackListPhonePicker extends PureComponent {
  phoneListForRestore = [];

  handleAddedClean = () => {
    const { phoneList = [], serverList = [], setBlackListNumbers } = this.props;
    this.phoneListForRestore = phoneList;

    setBlackListNumbers(pipe(difference, without(__, phoneList))(phoneList, serverList));
  };

  handleRestore = () => {
    this.props.setBlackListNumbers(this.phoneListForRestore);
    this.phoneListForRestore = [];
  };

  render() {
    const { addedNumbers, data, serverList, phoneList, showRecentCalls, openBlackListPopup } =
      this.props;
    const { isAuthenticated, isConnected, productParameters = [] } = data;

    return (
      <ProductParameters className={['withBorders', parentStyles.parametersTable]}>
        {isAuthenticated ?
          <BlackListedNumbers openBlackList={openBlackListPopup} phoneList={phoneList} />
        : null}
        {isAuthenticated && (isConnected || serverList.length) ?
          <BlackListLastCalls showRecentCalls={showRecentCalls} />
        : null}
        {isAuthenticated ?
          <ProductParameter
            key="<h3>К оплате</h3>"
            label="<h3>К оплате</h3>"
            unit={null}
            value={null}
          />
        : null}
        {productParameters.map((parameter) => {
          const { value, unit, key, label } = parameter;
          switch (key) {
            case 'OneNumberInBlackListCost':
              return (
                <BlackListAddedNumbers
                  addedNumbers={addedNumbers}
                  key={label}
                  onClean={this.handleAddedClean}
                  onRestore={this.handleRestore}
                  parameter={parameter}
                  restore={this.phoneListForRestore.length > 0 && addedNumbers === 0}
                  restoreNumbers={this.phoneListForRestore.length - serverList.length}
                />
              );
            default:
              return <ProductParameter key={label} label={label} unit={unit} value={value} />;
          }
        })}
      </ProductParameters>
    );
  }
}

BlackListPhonePicker.defaultProps = {
  phoneList: [],
  data: {},
};

BlackListPhonePicker.propTypes = {
  phoneList: PropTypes.array,
  serverList: PropTypes.array,
  openBlackListPopup: PropTypes.func,
  showRecentCalls: PropTypes.func,
  setBlackListNumbers: PropTypes.func,
  data: PropTypes.object,
  addedNumbers: PropTypes.number,
};

export default connect(
  (state) => {
    const { added } = getNumbersDiff(state);
    return {
      phoneList: getPhoneList(state),
      serverList: getServerList(state),
      addedNumbers: added,
    };
  },
  (dispatch) => ({
    openBlackListPopup: () => dispatch(openBlackList()),
    showRecentCalls: () =>
      dispatch(
        startRequest({
          key: 'recentCalls',
          startUrl: '/CommonApi/LastCallsToBlackNumbers',
          pollUrl: '/CommonApi/LastCallsToBlackNumbers',
          responseHandler,
        }),
      ),
    setBlackListNumbers: (numbers) => dispatch({ type: SET_BLACK_NUMBERS, payload: numbers }),
  }),
)(BlackListPhonePicker);
