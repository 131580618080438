import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {
  Bubble,
  Button,
  Emoji,
  Heading,
  Link,
  SaleRow,
  Status,
  Tooltip,
} from '@beef/legacy-ui-kit';

import { translate } from 'utils/format-string';
import Icon from 'pages-components/Icon';
import BenefitsWithPopups from 'pages-components/BenefitsWithPopup';

import styles from './ProductsFamilyTile.pcss';

const cx = classNames.bind(styles);

function doScrolling(elementY, duration) {
  if (typeof window === 'undefined') {
    return;
  }

  const startingY = window.pageYOffset;
  const diff = elementY - startingY;
  let start;

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    const time = timestamp - start;
    const percent = Math.min(time / duration, 1);
    window.scrollTo(0, startingY + diff * percent);
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
}

class ProductsFamilyTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hiddenGroups: 0,
      hiddenItems: 0,
      colSpan: 1,
      benefits: null,
    };
  }

  componentDidMount() {
    this.processHiddenGroups();
    this.processBenefits();
  }

  componentDidUpdate(prevProps) {
    const { data } = prevProps;
    if (data !== this.props.data) {
      this.processHiddenGroups();
    }
  }

  handleClick = (link, id) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(link, id);
    }
    window.location.href = link;
  };

  showHiddenGroups = () => {
    doScrolling(this.container.offsetTop, 750);
    this.setState({ hiddenGroups: 0 });
  };

  processHiddenGroups() {
    let hiddenGroups = 0;
    let hiddenItems = 0;
    let colSpan = 0;
    const groupKey = this.props.itemType === 'tariff' ? 'groups' : 'services';
    if (this.props.data && this.props.data[groupKey]) {
      this.props.data[groupKey].forEach((item) => {
        if (item.isHidden) {
          ++hiddenGroups;
          const items = item.tariffs || [1]; // <-- fix for services (items.length == 1 :);
          hiddenItems += items.length;
        }
        if (
          !colSpan &&
          typeof item.tariffs === 'object' &&
          item.tariffs[0] &&
          item.tariffs[0].parameters &&
          typeof item.tariffs[0].parameters === 'object'
        ) {
          colSpan = Object.keys(item.tariffs[0].parameters).length;
        }
      });
    }

    this.setState({ hiddenGroups, hiddenItems, colSpan });
  }

  processBenefits() {
    const { showBenefits, services } = this.props.data;
    if (!showBenefits && services) {
      return;
    }

    const benefits = [];
    const existedBenefits = {};

    services.forEach((service) => {
      if (service.benefits && service.benefits.length) {
        service.benefits.forEach((benefit) => {
          if (!existedBenefits[benefit.icon]) {
            existedBenefits[benefit.icon] = 1;
            benefits.push(benefit);
          }
        });
      }
    });

    if (benefits.length > 0) {
      this.setState({ benefits: benefits.slice(0, 4) });
    }
  }

  renderShowMore() {
    if (!this.state.hiddenGroups) {
      return null;
    }

    return (
      <button className={cx('showMore')} onClick={this.showHiddenGroups}>
        <span className={cx('showMoreText')}>
          {this.state.hiddenGroups ?
            `Ещё ${this.state.hiddenItems} ${translate(
              this.props.itemType,
              this.state.hiddenGroups,
            )} в семействе`
          : 'Свернуть'}
        </span>
        <Icon className={cx('showMoreArrow')} name="dropdownArrow" />
      </button>
    );
  }

  renderTariffGroups() {
    const {
      connectedText,
      data: { showBenefits, groups },
    } = this.props;

    if (!groups) return null;

    const elements = [];
    const colSpan = this.state.colSpan + (showBenefits ? 3 : 2);

    groups.forEach((group, index) => {
      const els = [];
      els.push(
        <tr className={cx('tariffsRow', 'tariffsRowTablet')} key={index}>
          <td className={cx('tariffsTitle')} colSpan={colSpan}>
            <Heading className={cx('heading')} level={4}>
              {group.groupTitle}
            </Heading>
          </td>
        </tr>,
      );

      if (group.sale) {
        els.push(
          <tr className={cx('tariffsRow', 'tariffsRowTablet')} key={`sale${index}`}>
            <td className={cx('saleGroup')} colSpan={colSpan}>
              <SaleRow className={['transformer']}>
                <span dangerouslySetInnerHTML={{ __html: group.sale }} />
              </SaleRow>
            </td>
          </tr>,
        );
      }

      if (group.tariffs) {
        group.tariffs.forEach((tariff, index1) => {
          const el = [];

          if (tariff.sale) {
            el.push(
              <tr className={cx('tariffsRow', 'tariffsRowTablet')} key={`ts${index1}`}>
                <td className={cx('saleTariff')} colSpan={colSpan}>
                  <SaleRow className={['transformer', 'borderTop']}>
                    <span dangerouslySetInnerHTML={{ __html: tariff.sale }} />
                  </SaleRow>
                </td>
              </tr>,
            );
          }

          el.push(
            <TariffRow
              connectedText={connectedText}
              key={`t${index1}`}
              saleGroup={group.sale}
              showBenefits={showBenefits}
              tariff={tariff}
              title={group.groupTitle}
            />,
          );

          els.push(el);
        });

        elements.push(
          <tbody
            className={cx({
              tariffsBody: true,
              isHidden: group.isHidden && this.state.hiddenGroups,
            })}
            key={index}
          >
            {els}
          </tbody>,
        );
      }
    });

    return elements;
  }

  renderServices() {
    const {
      data: { showBenefits, services },
      connectedText,
    } = this.props;

    if (!services) return null;

    return services.map((service, key) => (
      <tbody
        className={cx({
          servicesBody: true,
          isHidden: service.isHidden && this.state.hiddenGroups,
        })}
        key={key}
      >
        <ServiceRow
          allBenefits={this.state.benefits}
          connectedText={connectedText}
          key={key}
          onClick={this.handleClick}
          service={service}
          showBenefits={showBenefits}
        />
      </tbody>
    ));
  }

  render() {
    const { benefits } = this.state;
    const {
      className,
      data: { familyTitle, description, titles, type },
    } = this.props;

    if (type !== 'family') {
      return null;
    }

    return (
      <div
        className={cx('container', className)}
        ref={(c) => {
          this.container = c;
        }}
      >
        <div className={cx('header')}>
          <Heading>
            <span dangerouslySetInnerHTML={{ __html: familyTitle }} />
          </Heading>
          {description ?
            <div className={cx('description')} dangerouslySetInnerHTML={{ __html: description }} />
          : null}

          {benefits ?
            <BenefitsWithPopups
              benefits={benefits}
              className={cx('headerBenefits')}
              iconType="src"
              showTitles
            />
          : null}
        </div>

        <div
          className={cx({
            containerTariffs: this.props.data.groups,
            containerServices: this.props.data.services,
          })}
        >
          <table className={cx('tariffs', { hasHiddenGroup: this.state.hiddenGroups })}>
            {titles ?
              <thead>
                <tr className={cx('tariffsHead')}>
                  {titles.map((title, index) => (
                    <td className={cx('tariffsTd')} key={index}>
                      {title}
                    </td>
                  ))}
                </tr>
              </thead>
            : null}

            {this.renderTariffGroups()}
            {this.renderServices()}
          </table>
        </div>

        {this.renderShowMore()}
      </div>
    );
  }
}

export default ProductsFamilyTile;

function TariffRow(props) {
  if (!props.tariff) {
    return null;
  }
  const {
    title,
    showBenefits,
    connectedText,
    saleGroup,
    tariff: {
      parameters,
      link,
      legal,
      sale,
      isPostpaid,
      isConnected,
      availableForConnect,
      isArchived,
      connectionButton,
    },
  } = props;
  const benefits = ['sim', 'sim-delivery', 'economy'];

  return (
    <tr className={cx('tariffsRow', { isSale: !!sale })}>
      <td className={cx('tariffsTd', '')}>
        {title ?
          <Heading className={cx('heading', 'tariffsTitleMobile')} level={4}>
            <Link href={link}>{title}</Link>
          </Heading>
        : null}
        <div className={cx('tariffTitle')}>
          <Link className={cx('payLink')} href={link}>
            {isPostpaid ? 'Постоплата' : 'Предоплата'}
          </Link>
          <span className={cx('payLinkMobile')}>{isPostpaid ? 'Постоплата' : 'Предоплата'}</span>
          {legal ?
            <Tooltip>{legal}</Tooltip>
          : null}
        </div>
        {sale || saleGroup ?
          <div className={cx('saleRowMobile')}>
            <SaleRow>
              <span dangerouslySetInnerHTML={{ __html: sale || saleGroup }} />
            </SaleRow>
          </div>
        : null}
      </td>

      {typeof parameters === 'object' ?
        Object.keys(parameters).map((id) => {
          if (id === 'discount') {
            return null;
          }
          if (id === 'price' && parameters.discount) {
            return <TariffParam discount={parameters.discount} key={id} param={parameters[id]} />;
          }
          return <TariffParam key={id} param={parameters[id]} />;
        })
      : null}

      {/* Hardcode */}
      {showBenefits ?
        <TariffParamBenefits benefits={benefits} isPostpaid={isPostpaid} />
      : null}

      <td className={cx('tariffsTd', 'tariffsTdButton')}>
        {isConnected ?
          <div className={cx('buttonWrapper')}>
            <Status className={['success', 'compact', 'wide', 'centeredText']}>
              <Link className={['light', 'hideVisitedColor']} href={link}>
                {connectedText}
              </Link>
            </Status>
          </div>
        : <div className={cx('buttonWrapper')}>
            {availableForConnect ?
              <Button href={`${link}?connect=true`} wide>
                {connectionButton}
              </Button>
            : <Status className={['compact', 'wide', 'centeredText']}>Недоступен</Status>}
          </div>
        }

        {isArchived ?
          <div className={cx('buttonWrapper')}>
            <Status className={['compact', 'wide', 'centeredText']}>В архиве</Status>
          </div>
        : null}
      </td>
    </tr>
  );
}

function TariffParamBenefits(props) {
  if (!props.benefits) {
    return false;
  }

  return (
    <td className={cx('tariffsTd', 'tariffsTdBenefits')}>
      {props.benefits.map((benefit, index) => (
        <div
          className={cx('tarrifsBenefit', { hidden: index === 0 && props.isPostpaid })}
          key={index}
        >
          <img alt="" className={cx('tarrifsBenefitImg')} src={benefit.icon} />
        </div>
      ))}
    </td>
  );
}

function TariffParam(props) {
  if (!props.param) {
    return <td className={cx('tariffsTd')} />;
  }
  const {
    discount,
    param: { value, unit, sale },
  } = props;

  return (
    <td className={cx('tariffsTd')}>
      <span dangerouslySetInnerHTML={{ __html: value }} />{' '}
      <span className={cx('unit')} dangerouslySetInnerHTML={{ __html: unit }} />
      {discount ?
        <div className={cx('downsale')}>
          <span className={cx('oldPrice')}>{`${discount.absolutePrice} ${unit}`}</span>
          <Bubble className={['bottomRight', cx('bubble')]}>
            <div className={cx('bubbleEmojis')}>
              <Emoji className={cx('bubbleEmoji')} name="statusOk" />
              <Emoji className={cx('bubbleEmoji')} name="rubble" />
            </div>
            {`Любите скидки?
              Только для вас действует скидка
              ${Math.round(discount.value)}%`}
            {discount.unit > 0 && ` в течение ${discount.unit} месяцев`}
          </Bubble>
        </div>
      : null}
      {sale ?
        <div className={cx('saleCell')}>
          <SaleRow className={['injection', 'inherit']}>
            <span dangerouslySetInnerHTML={{ __html: sale }} />
          </SaleRow>
        </div>
      : null}
    </td>
  );
}

function ServiceRow(props) {
  if (!props.service) {
    return null;
  }

  const {
    connectedText,
    allBenefits,
    onClick,
    service: {
      title,
      id,
      benefits,
      parameters,
      link,
      legal,
      isConnected,
      availableForConnect,
      connectionButton,
    },
  } = props;

  const [first, ...restTail] = parameters || [];
  const { value, unit } = first || {};

  return (
    <tr className={cx('tariffsRow')}>
      <td className={cx('tariffsTd')}>
        {title ?
          <div className={cx('serviceTitle')}>
            <Heading className={cx('heading')} level={4}>
              <Link notPseudo onClick={() => onClick(link, id)}>{`${value || ''} ${
                unit || ''
              }`}</Link>
            </Heading>
            {legal ?
              <Tooltip>{legal}</Tooltip>
            : null}
          </div>
        : null}
      </td>

      {benefits ?
        <td className={cx('tariffsTd', 'servicesTdBenefits')}>
          {allBenefits &&
            allBenefits.map((allBenefitsItem) => (
              <div className={cx('tarrifsBenefit')}>
                {benefits.map(
                  (benefit) =>
                    allBenefitsItem.icon === benefit.icon && (
                      <img alt="" className={cx('tarrifsBenefitImg')} src={benefit.icon} />
                    ),
                )}
              </div>
            ))}
        </td>
      : null}

      {restTail instanceof Array ?
        restTail.map((parameter, index) => {
          if (
            parameter !== null &&
            (parameter.label === 'Стоимость подключения' || parameter.label === 'Подключение')
          )
            return null;

          return <TariffParam key={`family-service-param-${id}-${index}`} param={parameter} />;
        })
      : null}

      <td className={cx('tariffsTd', 'tariffsTdButton')}>
        {isConnected ?
          <div className={cx('buttonWrapper')}>
            <Status className={['success', 'compact', 'wide', 'centeredText']}>
              <Link className={['light', 'hideVisitedColor']} href={link}>
                {connectedText}
              </Link>
            </Status>
          </div>
        : <div className={cx('buttonWrapper')}>
            {availableForConnect ?
              <Button onClick={() => onClick(`${link}?connect=true`)} wide>
                {connectionButton}
              </Button>
            : <Status className={['compact', 'wide', 'centeredText']}>Недоступен</Status>}
          </div>
        }
      </td>
    </tr>
  );
}

ProductsFamilyTile.defaultProps = {
  connectedText: 'Подключено',
  itemType: 'tariff',
};

ProductsFamilyTile.propTypes = {
  data: PropTypes.object.isRequired,
  connectedText: PropTypes.string,
  itemType: PropTypes.string,
  onClick: PropTypes.func,
};

TariffRow.propTypes = {
  tariff: PropTypes.object,
  title: PropTypes.string,
  showBenefits: PropTypes.bool,
  connectedText: PropTypes.string,
  saleGroup: PropTypes.string,
};

TariffParam.propTypes = {
  param: PropTypes.object,
  discount: PropTypes.object,
};

TariffParamBenefits.propTypes = {
  benefits: PropTypes.array,
  isPostpaid: PropTypes.bool,
};

ServiceRow.propTypes = {
  connectedText: PropTypes.string,
  service: PropTypes.object,
  allBenefits: PropTypes.array,
  onClick: PropTypes.func,
};
