import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames/bind';

import { ModalHeader } from '../ModalHeader';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TModalContent = {
  modalHeaderProps: {
    description?: string;
    image?: string;
    title?: string;
  };
};

export const ModalContent = ({ modalHeaderProps, children }: PropsWithChildren<TModalContent>) => (
  <div className={cx('wrapper')}>
    <ModalHeader {...modalHeaderProps} />
    {children}
  </div>
);
