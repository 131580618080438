import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PULLED_DOWN_IMG = 'pulled_down';

const TariffRowBenefit = ({ text, icon, benefitText, className, benefitClass, textClass }) => {
  // костыль для отображения изображений, притянутых вниз
  const isPulledDown = icon && icon.includes(PULLED_DOWN_IMG);

  return (
    <div className={cx('wrap', className)}>
      <div className={cx('benefit', benefitClass)}>{benefitText}</div>
      <div className={cx('text', textClass)}>{text}</div>
      {!!icon && (
        <div className={cx('icon', isPulledDown && 'isPulledDown')}>
          <img alt="" src={icon} />
        </div>
      )}
    </div>
  );
};

TariffRowBenefit.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  benefitText: PropTypes.string,

  className: PropTypes.string,
  benefitClass: PropTypes.string,
  textClass: PropTypes.string,
};

export default TariffRowBenefit;
