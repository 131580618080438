import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SmartForm, SmartInput, Status } from '@beef/legacy-ui-kit';
import { Button, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import { useOnScreenEffect } from '@beef/utils';

import { validateMaskedPhone } from 'utils/validators';
import { ymSetParams } from 'utils/ym';
import { YM_FULL_PARAMS } from 'utils/analytics/offsetLanding';
import { AUTH_YM_ACTIONS, AUTH_YM_EVENTS, YMAuthEvent } from 'utils/analytics/ymCommonEvents';
import { handleRoamingYm, roamingYmEventsTypes } from 'pages/ProductCard/utils';

import styles from './SmsLoginForm.pcss';

const cx = classNames.bind(styles);

const SmsFormPhone = ({
  error,
  phone = '+7 ',
  submitSmsFormPhone,
  sendSmsButtonText,
  description,
  ymType,
}) => {
  const elementRef = useOnScreenEffect(() => {
    ymSetParams(YM_FULL_PARAMS.YM_POPUP_AUTH_BY_SMS);
    YMAuthEvent.visible(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS);
  }, []);

  const handleChange = (value) => {
    if (value) {
      YMAuthEvent.fillLoginInput(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS);
    }
  };

  const onClick = () => {
    YMAuthEvent.clickAuthButton(
      AUTH_YM_EVENTS.CHANGE_TARIFF_SMS,
      AUTH_YM_ACTIONS.STEP2_INPUT_PHONE,
    );
    if (ymType) {
      handleRoamingYm({
        type: ymType,
        actionType: roamingYmEventsTypes?.calculationPhone,
        value: 'calculate',
      });
    }
  };

  return (
    <SmartForm
      autoComplete="off"
      className={cx('smsForm')}
      onSubmit={submitSmsFormPhone}
      serverErrors={error}
      validator={{ phone: (value) => ({ status: validateMaskedPhone(value) ? 'ok' : 'typo' }) }}
    >
      {description && (
        <div className={cx('description')}>
          <Text size="size6-r">
            {/* TODO убрать span dangerouslySetInnerHTML */}
            {/* В контексте SmartForm <Text/> не обрабатывает переносы строки <br> */}
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </Text>
        </div>
      )}

      <div className={cx('sms')} ref={elementRef}>
        <div className={cx('smsItem')}>
          <SmartInput
            forceTouch={error && error.phone}
            inputId="phone"
            mask="+7 ___ ___ __ __"
            msg={error && error.phone && error.phone.msg}
            name="phone"
            onChange={handleChange}
            onClick={() => YMAuthEvent.clickLoginInput(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS)}
            size="big"
            status={error && error.phone && 'fail'}
            value={phone}
          />
        </div>

        <div className={cx('smsItem')}>
          <Button fullWidth onClick={onClick} size="l" type="submit" variant="main">
            <span dangerouslySetInnerHTML={{ __html: sendSmsButtonText }} />
          </Button>
        </div>
      </div>

      {error && error.type ?
        <Status className={['error', cx('error')]} emoji="statusFail">
          {error.msg}
        </Status>
      : null}
    </SmartForm>
  );
};

SmsFormPhone.propTypes = {
  error: PropTypes.shape({}),
  phone: PropTypes.string,
  submitSmsFormPhone: PropTypes.func,
  sendSmsButtonText: PropTypes.string,
  description: PropTypes.string,
};

export default SmsFormPhone;
