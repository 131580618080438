/* eslint-disable */
// todo: исправить ошибки линтера
import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Text } from '@beef/ui-kit';
import { TotalCheck } from './components/TotalCheck';
import { DefaultCheck } from './components/DefaultCheck';

// Utils
import { countCommonPrice } from '../../../../../utils';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const CheckZ = ({
  total,
  content,
  hasZTariff,
  defaultCheck,
  requestStatus,
  isChangeTariff,
  handleOrderModal,
  hasRemainingSize,
  hasConnectedOptions,
  isChangeOptionsTariff,
  isChangePackagesTariff,
}) => {
  const commonTotalPrice = useMemo(() => {
    const price = countCommonPrice(total.packages, 'packages')
    + countCommonPrice(total.options, 'options');

    if (defaultCheck?.chargeAmount && !hasZTariff && isChangeTariff) {
      return price + defaultCheck?.chargeAmount;
    }
    return price;
  }, [total]);

  const showTotalCheck = (hasZTariff && (hasRemainingSize || hasConnectedOptions))
      || !isChangePackagesTariff || !isChangeOptionsTariff;
  const showDescription = content?.description && isChangePackagesTariff
      && isChangeOptionsTariff && !hasRemainingSize && !hasConnectedOptions;

  const options = useMemo(() => Object?.values(total.options), [total.options]);
  const packages = useMemo(() => Object?.values(total.packages), [total.packages]);

  return (
    <div className={cx('wrapper')}>
      {content?.title && (
        <div className={cx('header')}>
          <div className={cx('header__title')}>
            <Text
              color="grey95"
              size="size3-m"
            >
              {content.title}
            </Text>
          </div>
          {showDescription && (
            <Text
              color="grey50"
              size="size5-r-s"
            >
              {content.description}
            </Text>
          )}
        </div>
      )}
      {showTotalCheck
        ? (
          <TotalCheck
            total={total}
            options={options}
            content={content}
            packages={packages}
            hasZTariff={hasZTariff}
            requestStatus={requestStatus}
            isChangeTariff={isChangeTariff}
            handleOrderModal={handleOrderModal}
            commonTotalPrice={commonTotalPrice}
            hasRemainingSize={hasRemainingSize}
            hasConnectedOptions={hasConnectedOptions}
            chargeAmount={defaultCheck?.chargeAmount}
            isChangeOptionsTariff={!isChangeOptionsTariff}
            isChangePackagesTariff={!isChangePackagesTariff}
            chargeAmountUnit={defaultCheck?.chargeAmountUnit}
            initialBalancePayment={defaultCheck?.initialBalancePayment}
          />
        )
        : <DefaultCheck content={content} defaultInfo={defaultCheck} />}
    </div>
  );
};

CheckZ.propTypes = {
  total: PropTypes.shape({
    options: PropTypes.shape(PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.number,
      value: PropTypes.number,
      period: PropTypes.string,
      isShutdown: PropTypes.bool,
      isConnectedPeriod: PropTypes.bool,
    })),
    packages: PropTypes.arrayOf(PropTypes.shape({
      unit: PropTypes.string,
      price: PropTypes.number,
      value: PropTypes.number,
      period: PropTypes.string,
      unitPrice: PropTypes.string,
      remainingSize: PropTypes.number,
    })),
  }),
  content: PropTypes.object,
  hasZTariff: PropTypes.bool,
  defaultCheck: PropTypes.object,
  isChangeTariff: PropTypes.bool,
  requestStatus: PropTypes.string,
  hasRemainingSize: PropTypes.bool,
  handleOrderModal: PropTypes.func,
  hasConnectedOptions: PropTypes.bool,
  isChangeOptionsTariff: PropTypes.bool,
  isChangePackagesTariff: PropTypes.bool,
};
