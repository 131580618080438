import axios from 'axios';
import qs from 'query-string';

import { unformatPhone } from 'utils/format-string';
import { getGAClientId } from 'utils/ga';

export const simBuyAction = async (
  url,
  uglyPhone,
  captcha,
  captchaKey,
  reservePlace = 'ServiceCard',
) => {
  const contactNumber = unformatPhone(uglyPhone);

  try {
    const { data } = await axios.post(
      url,
      qs.stringify({
        contactNumber,
        captcha,
        _captcha_key: captchaKey,
        gaClientId: getGAClientId(),
        reservePlace: reservePlace || undefined,
      }),
    );

    if (data.isSucceeded) {
      return {
        nextStep: 'successPopup',
        isCaptchaRequired: false,
        isCaptchaError: false,
        orderId: data.orderId,
        price: data.price,
      };
    }

    if (data.isSucceeded === false) {
      throw new Error('Call Order Error');
    }

    if (data.IsCaptchaError || !data.IsSuccess) {
      return {
        isCaptchaError: data.IsCaptchaError,
        isCaptchaRequired: data.IsCaptchaRequired,
      };
    }
  } catch (e) {
    return {
      nextStep: 'errorPopup',
    };
  }
};
