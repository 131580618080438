import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';

type TPassiveTitle = {
  title?: string;
};

/** Компонент заголовка для модального окна описания пассивок */
export const PassiveTitle: FC<TPassiveTitle> = ({ title }) =>
  title ? <Text size="size2-m">{title}</Text> : null;
