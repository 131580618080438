import React, { FC, ReactNode } from 'react';
import { Modal, ModalPropsType } from '@beef/ui-kit';

import { ModalActionBar, TModalActionBar } from './components/ModalActionBar';

export type TModalContainer = {
  children: ReactNode;
  isOpen: boolean;
  modalActionBarProps: TModalActionBar;
} & ModalPropsType;

/** Контейнер общей модалки для всего конструктора.
 * ModalActionBar компонент, отвечающий за buttons модалки */
export const ModalContainer: FC<TModalContainer> = ({
  size,
  isOpen,
  onClose,
  children,
  modalActionBarProps,
}) => (
  <Modal {...{ size, isOpen, onClose }} actionBar={<ModalActionBar {...modalActionBarProps} />}>
    {children}
  </Modal>
);
