import { useAppDispatch } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

import { useSubmitCallbackRequest } from './useSubmitCallbackRequest';
import { setInitialState } from '../../../../../../store/slices/fttbAddressSlice';
import { useCheckAddressResults } from '../../../selectors/useCheckAddressResults';
import { TCommonInfoBanner } from '../../../../../commonComponents/CommonInfoBanner';
import { useCheckAddressPageContent } from '../../../selectors/useCheckAddressPageContent';

/** Хук подготовки данных для компонента CommonInfoBanner */
export const usePrepareCheckAddressInfo = () => {
  const dispatch = useAppDispatch();
  const submitCallbackRequest = useSubmitCallbackRequest();
  const content = useCheckAddressPageContent();
  const { isHasRequest, isNotAvailable, isAlreadyConnected } = useCheckAddressResults();

  /* Выбираем контент в зависимости от ошибки */
  const contentBanner =
    (isHasRequest && content?.isHasRequestConvergent) ||
    (isNotAvailable && content?.errorCheckAddress) ||
    (isAlreadyConnected && content?.alreadyConnected);

  const { title, description, image, btnText, extraBtnText } = contentBanner || {};

  /** Обработчик главной кнопки */
  const handleClick = () => submitCallbackRequest();

  /** Обработчик дополнительной кнопки */
  const extraHandleClick = () => dispatch(setInitialState());

  const commonInfoBannerProps: TCommonInfoBanner = {
    buttonProps: {
      btnText,
      extraBtnText,
      onClick: handleClick,
      extraOnClick: extraHandleClick,
    },
    pictureProps: {
      image,
    },
    contentProps: {
      title,
      description,
    },
  };
  return commonInfoBannerProps;
};
