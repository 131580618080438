import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { useIsMobile } from 'utils/hooks/useIsMobile';

import ConnectTariffButton from '../ConnectTariffButton';
import ChangeTariffBtn from '../ChangeTariffBtn';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ConnectBtnsRow = ({
  isShowChangeTariff,
  tariffData,
  className,
  connectBtnText,
  tabsToDisplay,
  onConnectClick,
}) => {
  const isMobile = useIsMobile(false);

  const modifier = isMobile ? '' : 'big';

  return (
    <div className={cx('wrapper', className)}>
      <div className={cx('col', { allRow: !isShowChangeTariff })}>
        <ConnectTariffButton
          buttonText={connectBtnText}
          className={cx('connectBtn')}
          isFloatingButton
          newNumber=""
          onClick={onConnectClick}
          popupData={tariffData}
          tabsToDisplay={tabsToDisplay}
        />
      </div>
      {isShowChangeTariff && (
        <div className={cx('col')}>
          <ChangeTariffBtn
            className={cx('changeTariffBtn')}
            data={tariffData}
            modifier={modifier}
          />
        </div>
      )}
    </div>
  );
};

ConnectBtnsRow.propTypes = {
  isShowChangeTariff: PropTypes.bool,
  tariffData: PropTypes.object,
  connectBtnText: PropTypes.string,
  tabsToDisplay: PropTypes.array,
  onConnectClick: PropTypes.func,
};

export default ConnectBtnsRow;
