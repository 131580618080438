import React, { ComponentProps, FC } from 'react';
import classNames from 'classnames/bind';

import { TitleContent } from '../TitleContent';
import { RouteBackButton } from '../RouteBackButton';
import { useShowRoutBackButton } from './hooks/useShowRoutBackButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Обертка над заголовком и кнопокй "назад" для страниц конструктора */
export const PageHeader: FC<ComponentProps<typeof TitleContent>> = (props) => {
  const isShowBackButton = useShowRoutBackButton();
  return (
    <div className={cx('wrapper')}>
      <TitleContent {...props} />
      {isShowBackButton && <RouteBackButton />}
    </div>
  );
};
