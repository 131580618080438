import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

// Components
import { Heading, Preloader } from '@beef/legacy-ui-kit';

import Grid from 'pages-components/Grid';
import ErrorPopup from 'pages-components/ErrorPopup/ErrorPopup';
import Ctx from 'pages-components/Ctx';

// Utils
import { templateParser } from 'utils/format-string';

// Actions
import { showServiceDisconnectionPopup } from 'pages/ProductCard/actions/serviceConnect';

import ServiceParameters from './components/ServiceParameters';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

class SecretaryService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isErrorPopupOpened: false,
      error: {},
      optionName: '',
      parametersContent: {},
      setServiceDisconnection: false,
    };
  }

  componentDidMount() {
    if (this.props?.data?.isConnected) {
      this.fetchData();
    } else {
      this.setState({ parametersContent: this.props?.data?.extData });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { setServiceDisconnection } = this.state;

    if (prevState.setServiceDisconnection !== setServiceDisconnection && setServiceDisconnection) {
      this.handleDisconnectClick();
      this.setState({ setServiceDisconnection: false });
    }
  }

  checkIfLastConnectedService = (secretaryServiceList) => {
    const connectedServices = [];
    secretaryServiceList.map(({ isConnected }) => connectedServices.push(isConnected));
    return connectedServices.filter((value) => value === true).length <= 1;
  };

  fetchData = () => {
    axios
      .get('/beelinesekretarapi/')
      .then((response) => {
        this.setState({ parametersContent: response?.data });
      })
      .catch((err) => console.error(err));
  };

  onSwitchClick = (id) => {
    const newServiceList = [];
    const { secretaryServiceList } = this.state.parametersContent;
    secretaryServiceList.forEach((service) => {
      if (service.id === id) {
        newServiceList.push({
          ...service,
          isConnected: !secretaryServiceList[id - 1].isConnected,
        });
      } else {
        newServiceList.push({
          ...service,
        });
      }
    });
    this.setState((prevState) => ({
      parametersContent: {
        ...prevState.parametersContent,
        secretaryServiceList: newServiceList,
      },
    }));
  };

  openPopup = (popupProps, optionName) => {
    this.setState({ isErrorPopupOpened: true, error: popupProps, optionName });
  };

  handleDisconnectClick = () => {
    const { isConnectedLater, marketCode, serviceName: pageTitle, soc } = this.props.data;
    const { onDisconnect } = this.props;

    onDisconnect({
      isConnectedLater,
      marketCode,
      pageTitle,
      soc,
    });
  };

  handleLastConnectedService = () => {
    this.setState({ setServiceDisconnection: true });
  };

  defineShownValue = (param) => {
    const { isAuthenticated, isConnected: isServiceConnected } = this.props.data;
    const { parametersContent } = this.state;
    if (isAuthenticated) {
      if (isServiceConnected && param?.key === 'SubscriptionFee') {
        return Math.ceil(parametersContent?.dailyFee?.amount / 100);
      } else if (!isServiceConnected && param?.key === 'SubscriptionFee') {
        return parametersContent?.subscriptionFee;
      }
    }
    return param?.value;
  };

  render() {
    const {
      productParameters,
      isAuthenticated,
      isConnected: isServiceConnected,
      pageTitle: serviceName,
    } = this.props.data;
    const { parametersContent, error, optionName } = this.state;
    const { secretaryServiceList } = parametersContent;
    const { text } = this.props.data?.extData;

    if (secretaryServiceList?.length) {
      return (
        <div className={cx('container')}>
          <Heading
            className={cx('secretaryServiceHeading')}
            isStyled={false}
            level={2}
            tagName="div"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: Ctx?.service?.secretaryService?.compositionHeading,
              }}
            />
          </Heading>

          <Grid.ProductParameters className={secretaryServiceList && cx('secretaryService')}>
            {secretaryServiceList.map((parameter) => (
              <ServiceParameters
                areOptionsVisible={isServiceConnected}
                checkIfLastConnectedService={() =>
                  this.checkIfLastConnectedService(secretaryServiceList)
                }
                disconnectPopupOpen={this.props?.disconnectPopupOpen}
                handleLastConnectedService={this.handleLastConnectedService}
                isConnected={(e) => secretaryServiceList[e].isConnected}
                isSecretaryServiceDisconnected={this.props?.isDisconnected}
                key={parameter.id}
                onSwitchClick={this.onSwitchClick}
                openPopup={this.openPopup}
                popupContent={parametersContent?.popupContent ?? {}}
                {...parameter}
              />
            ))}
          </Grid.ProductParameters>

          <Heading className={cx('secretaryServiceHeading')} level={2} tagName="div">
            <span
              dangerouslySetInnerHTML={{ __html: Ctx?.service?.secretaryService?.priceHeading }}
            />
          </Heading>

          {!!secretaryServiceList?.length && (
            <Grid.ProductParameters className={secretaryServiceList && cx('secretaryService')}>
              {productParameters.map((param) => {
                let paramUnit = param?.unit;

                // Хардкод валюты в АЗ и НЗ, предыстория - BCDEV-7496 и BCDEV-7697
                if (param?.key === 'SubscriptionFee') {
                  paramUnit = isAuthenticated ? '₽/сутки' : `${param?.unit}`;
                }
                return (
                  <ServiceParameters
                    description={param?.key === 'SubscriptionFee' ? text : null}
                    key={param?.key}
                    legal={param?.legal}
                    name={param?.label}
                    unit={paramUnit}
                    value={this.defineShownValue(param)}
                  />
                );
              })}
            </Grid.ProductParameters>
          )}

          <ErrorPopup
            buttonText={error?.buttonText}
            description={templateParser(error?.description, { serviceName, optionName })}
            errorEmoji={error?.errorEmoji}
            onClose={() => this.setState({ isErrorPopupOpened: false })}
            opened={this.state.isErrorPopupOpened}
            title={error?.title}
          />
        </div>
      );
    }

    return (
      <div className={cx('preloader')}>
        <Preloader />
      </div>
    );
  }
}

SecretaryService.defaultProps = {
  productParameters: [],
};

SecretaryService.propTypes = {
  alias: PropTypes.string,
  productParameters: PropTypes.array,
  isServiceConnected: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  popupContent: PropTypes.object,
  serviceName: PropTypes.string,
  isDisconnected: PropTypes.bool,
  onDisconnect: PropTypes.func,
  isConnectedLater: PropTypes.bool,
  marketCode: PropTypes.string,
  pageTitle: PropTypes.string,
  soc: PropTypes.string,
  disconnectUrl: PropTypes.string,
  disconnectPopupOpen: PropTypes.bool,
  serviceType: PropTypes.string,
  serviceProcess: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { resultPopupData, serviceDateTime, serviceProcess, serviceType, view } = pathOr(
    {},
    ['external', 'serviceConnectionPopup'],
    state,
  );

  const { serviceDisconnectionPopup } = pathOr({}, ['external'], state);

  return {
    serviceDisconnectionPopup,
    disconnectPopupOpen: serviceDisconnectionPopup?.popupIsActive,
    isDisconnected: serviceDisconnectionPopup?.isDisconnected,
    resultPopupData,
    serviceDateTime,
    serviceProcess,
    serviceType,
    view,
  };
};

export default connect(mapStateToProps, (dispatch) => ({
  onDisconnect: (params) => dispatch(showServiceDisconnectionPopup(params)),
}))(SecretaryService);
