/** ID дефолтного таба "Без интернета" */
export const DEFAULT_TAB_ID = 'withoutInternet';

/** Текст default-таба "Без интернета" */
export const DEFAULT_TAB_TEXT = 'Без интернета';

/** Доп.юнит для шаблона цены */
export const MONTH_UNIT = '/мес';

/** Цена для default-таба ШПД */
export const FREE_PRICE = 0;

/** Юнит для default-таба ШПД */
export const PRICE_UNIT = '₽';
