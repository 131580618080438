import { createAction } from '@reduxjs/toolkit';

// Tariff Actions
import { setActiveTab } from './orderTariffDetailsData';

// Modal Actions
import { setButtonText, setCurrentStep, setSecondButtonText } from './modal';

// User Actions
import { resetUserContactFields, setStatusConnection } from './orderTariffUserData';

// Constants
import { REQUEST_STATUS, btnTexts, popupSteps } from '../../constants';

// Common Actions
export const setLoading = createAction('SET_LOADING');
export const setUserRegion = createAction('SET_USER_REGION');
export const setAuthenticated = createAction('SET_AUTHENTICATED');
export const setGlobalContent = createAction('SET_GLOBAL_CONTENT');

/**
 * setCloseModal: Закрытие модального окна со сбросом заполненных пользователем полей.
 */
export const setCloseModal = (step, activeSettingTab, hasSettingsTariff) => (dispatch) => {
  if (hasSettingsTariff) {
    if (step !== popupSteps.tariffDetailsForm) {
      dispatch(setCurrentStep(popupSteps.tariffDetailsForm));
    }
    dispatch(setActiveTab(activeSettingTab));
    return;
  }

  dispatch(setSecondButtonText(''));
  dispatch(resetUserContactFields());
  dispatch(setButtonText(btnTexts.next));
  dispatch(setStatusConnection(REQUEST_STATUS.isNone));
  dispatch(setCurrentStep(popupSteps.addressRequestForm));
};

/**
 * archiveNotificationHandler: установка шага "повышения скорости для архивного тарфиа" в попапе настройки ШПД.
 */
export const archiveNotificationHandler = () => (dispatch) => {
  dispatch(setCurrentStep(popupSteps.upTariffSpeedFromArchive));
};
