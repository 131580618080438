import { DEFAULT_CAPTCHA_URL } from 'pages/Feedback/components/Captcha/constants';

export const getModalData = (data) => ({
  title: data.texts.title,
  simOrderUrl: data.simOrderForm.simOrderUrl,
  captchaUrl: data.simOrderForm.captchaUrl ?? DEFAULT_CAPTCHA_URL,
  description: data.texts.description,
  descriptionIcon: data.texts.descriptionIcon,
  collapseButtonName: data.texts.collapseButtonName,
  buttonText: data.texts.buttonText,
  startBalancePriceText: data.texts.priceText,
  startBalanceUnitText: data.texts.priceUnitText,
  tariffs: data.tariffs,
  smartCaptchaEnabled: data.simOrderForm.smartCaptchaEnabled,
});
