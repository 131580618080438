import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { Button, Checkbox, Emoji, Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { templateParser } from 'utils/format-string';
import { pushShowTariffConnectionPopupEvent } from 'utils/ga';

import styles from './Constructor.pcss';
import Switchers from '../Switchers';
import Accumulators from '../Accumulators';
import { pushGA } from '../../../../actions/tariffConstructor';

const cx = classNames.bind(styles);

class TariffConstructor extends Component {
  state = { agreement: false };

  componentDidMount() {
    this.props.pushGA();
  }

  onButtonClick = () => {
    const { soc, marketCode, tariffName, authenticated, onConnectTariffClick, connectConstructor } =
      this.props;

    const callback =
      authenticated ? connectConstructor : onConnectTariffClick || connectConstructor;

    pushShowTariffConnectionPopupEvent({
      pageUrl: window.location.href,
      tariffName,
      authenticated,
      marketCode,
      soc,
    });

    callback();
  };

  handleCheckbox = () => this.setState({ agreement: !this.state.agreement });

  renderWarning = () => {
    const { texts, tariffName, authenticated, newPocket } = this.props;

    return (
      <div className={cx('warning')}>
        <div className={cx('text')}>
          <Emoji className={cx('hand')} name="pointingHandUp" />
          <div
            dangerouslySetInnerHTML={{
              __html: templateParser(texts.connectNewWarning, { tariff: tariffName }),
            }}
          />
        </div>
        <Button
          className={['big', cx('button')]}
          disabled={authenticated && !newPocket}
          onClick={this.onButtonClick}
          transformer
        >
          {texts.buttonUnauth}
        </Button>
      </div>
    );
  };

  renderResultButton = () => {
    const {
      texts,
      newPocket,
      tariffConnected,
      values: { dataTariff },
    } = this.props;

    return [
      <Button
        className={['big', cx('button')]}
        disabled={(!newPocket && tariffConnected) || (dataTariff && !this.state.agreement)}
        key="button"
        onClick={this.onButtonClick}
        transformer
      >
        {tariffConnected ? texts.connectConstructorButton : texts.connectTariffButton}
      </Button>,
    ];
  };

  render() {
    const { className, texts, values, resultValues, newPocket, authenticated } = this.props;

    const { dataTariff, price, offNetSeconds, onNetSeconds } = values;
    const showResultForOnNetOffNetConstructor =
      (offNetSeconds > 0 || offNetSeconds < 0) && (onNetSeconds > 0 || onNetSeconds < 0);
    const showResultForConstructor =
      !showResultForOnNetOffNetConstructor && newPocket && (!authenticated || !dataTariff);

    return (
      <div className={cx(className, 'component')}>
        <Heading level={1}>{texts.constructorHeading}</Heading>
        <div className={cx('description')}>{texts.constructorDescription}</div>

        <Switchers newPocket={newPocket} texts={texts} />
        <div className={cx('subHeading')}>{newPocket ? texts.newPockets : texts.oldPockets}</div>
        <Accumulators className={cx('accumulators')} fixZindex texts={texts} />
        {showResultForOnNetOffNetConstructor && (
          <div
            className={cx('onNetOffNetResult')}
            dangerouslySetInnerHTML={{
              __html: templateParser(texts.connectFullWarning, {
                onNet: resultValues.onNetSeconds,
                offNet: resultValues.offNetSeconds,
              }),
            }}
          />
        )}
        {showResultForConstructor && (
          <div
            className={cx('resultItem')}
            dangerouslySetInnerHTML={{
              __html: templateParser(texts.connectFullWarning, resultValues),
            }}
            key="warning"
          />
        )}
        {newPocket &&
          authenticated &&
          dataTariff && [
            <div
              className={cx('resultItem')}
              dangerouslySetInnerHTML={{
                __html: templateParser(texts.dataTariffInfo, { ...resultValues, andSMS: 'и SMS' }),
              }}
              key="info"
            />,
            dataTariff &&
              dataTariff.deviceTypes &&
              dataTariff.deviceTypes.indexOf('planshet') !== -1 && (
                <div
                  className={cx('resultItem')}
                  dangerouslySetInnerHTML={{ __html: texts.dataTariffPlanshetInfo }}
                  key="planshet"
                />
              ),
            <div className={cx('resultItem')} key="checkbox">
              <Checkbox
                checked={this.state.agreement}
                className={cx('checkbox')}
                handleChange={this.handleCheckbox}
              />
              {texts.dataTariffConfirm}
            </div>,
          ]}
        {newPocket && authenticated && !dataTariff && (
          <div
            className={cx('resultItem')}
            dangerouslySetInnerHTML={{
              __html: templateParser(price > 0 ? texts.connectPrice : texts.connectPriceFree, {
                price: `${price} ₽`,
              }),
            }}
          />
        )}
        {authenticated ? this.renderResultButton() : this.renderWarning()}
      </div>
    );
  }
}

TariffConstructor.defaultProps = {
  texts: {},
  values: {},
  resultValues: {},
};

TariffConstructor.propTypes = {
  texts: PropTypes.object,
  values: PropTypes.object,
  resultValues: PropTypes.object,
  newPocket: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool.isRequired,
  tariffConnected: PropTypes.bool.isRequired,
  connectConstructor: PropTypes.func.isRequired,
  pushGA: PropTypes.func.isRequired,
  tariffName: PropTypes.string,
  onConnectTariffClick: PropTypes.func,
  marketCode: PropTypes.string,
  soc: PropTypes.string,
};

export default connect(
  (state, { tariffName }) => {
    const values = pathOr({}, ['external', 'tariffConstructor', 'values'], state);
    const newTariffName =
      tariffName || pathOr('', ['external', 'tariff', 'data', 'pageTitle'], state);

    return {
      newPocket: Object.keys(values).reduce((result, key) => values[key] + result, 0) !== 0,
      tariffName: newTariffName,
      soc: state.external.tariff.data.soc,
      marketCode: state.external.tariff.data.marketCode,
      values,
    };
  },
  (dispatch) => ({
    pushGA: () => dispatch(pushGA),
  }),
)(TariffConstructor);
