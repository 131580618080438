import { useState } from 'react';
import PropTypes from 'prop-types';

import { ymOrderTariffAddressView } from 'pages/FTTB2021/components/OrderTariffFormPopup/analytics';
import { TARIFF_TYPES } from 'pages/FTTB2022/CatalogPage/deprecated/store/blocks/constants';

export const usePhoneInputWithMask = (ctn, errorText, setCtn, tariffType) => {
  const [inputCntWithMask, setInputCntWithMask] = useState(ctn || '');
  const [inputError, setInputError] = useState('');

  const validateCtn = () => {
    if (ctn.length !== 10) {
      setInputError(errorText);
    }
  };

  const handleChangePhone = ({ target: { value } }) => {
    /** PhoneInput api позволяет вводить номер с +0, для корректной валидации "+0" и "+7" убирается регуляркой */
    const parsedPhone = value.replace(/\+[07]|\D/g, '');
    setInputError('');
    if (parsedPhone?.length === 10 && inputCntWithMask !== parsedPhone) {
      const addressFillText =
        tariffType === TARIFF_TYPES.presetUp ? 'fmc_address_fill_field' : 'address_fill_field';
      ymOrderTariffAddressView({ [addressFillText]: 'phone' }, tariffType);
    }
    setInputCntWithMask(parsedPhone);
    setCtn(parsedPhone);
  };

  return {
    inputCntWithMask,
    inputError,
    handleChangePhone,
    validateCtn,
  };
};

usePhoneInputWithMask.propTypes = {
  phoneInputValue: PropTypes.string,
  errorText: PropTypes.string,
  changePhone: PropTypes.func,
};
