import format from 'date-fns/format';

import { REQUEST_STATUS } from './constants';

export const getNotificationFill = (status) => {
  if (
    status === REQUEST_STATUS.alreadyInUse ||
    status === REQUEST_STATUS.isNotAvailable ||
    status === REQUEST_STATUS.houseIsNotFound ||
    status === REQUEST_STATUS.streetIsNotFound
  ) {
    return '#F45151';
  }

  if (
    status === REQUEST_STATUS.isAvailable ||
    status === REQUEST_STATUS.isHighSpeedAvailable ||
    status === REQUEST_STATUS.isHighSpeedAvailableWithoutCalendarStep ||
    status === REQUEST_STATUS.isExists
  ) {
    return '#1790FF';
  }

  return null;
};

export const getConnectionStatus = ({
  ConnectionState,
  HasRequestOnAddress,
  HighSpeedAvailable,
  IsHasRequestConvergent,
}) => {
  if ((ConnectionState === 0 && HasRequestOnAddress) || IsHasRequestConvergent) {
    return REQUEST_STATUS.isExists;
  }

  if (ConnectionState === 1 && (HighSpeedAvailable === 1 || HighSpeedAvailable === 2)) {
    return REQUEST_STATUS.isHighSpeedAvailable;
  }

  if (ConnectionState === 2) {
    return REQUEST_STATUS.alreadyInUse;
  }

  if (ConnectionState === 1) {
    return REQUEST_STATUS.isAvailable;
  }

  return REQUEST_STATUS.isNotAvailable;
};

export const composeTimeSlotData = (data) =>
  data.map(({ start, end, ...rest }) => ({
    ...rest,
    id: `${format(start, 'HH:mm')}`,
    label: `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`,
    start,
    end,
  }));

export const composeConnectionData = ({
  region,
  street,
  house,
  flat,
  checkAddressType,
  isConvergentRequest,
}) => ({
  city: region.label,
  citiId: region.id,
  street: street.label,
  streetId: street.id,
  house: house.label,
  houseId: house.id,
  isConnected: house.isConnected,
  flat,
  checkAddressType,
  isConvergentRequest,
});

export const getQueryParams = (urlParams) => new URLSearchParams(urlParams).toString();

export const getInitialDate = (data = {}) => {
  const [nextAvailableDay] = Object.entries(data).sort((a, b) => a[0] - b[0]);

  return nextAvailableDay ? nextAvailableDay[1]?.day : new Date();
};
