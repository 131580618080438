import React, { FC } from 'react';
import classNames from 'classnames/bind';
// Components
import { InfoIcon, Text, Tooltip } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type InfoBlockProps = {
  hideTooltipInShortCycle?: boolean;
  isNotEnoughMoney?: boolean;
  mainPackageTitle: string;
  tooltipText?: string;
};

const InfoBlock: FC<InfoBlockProps> = ({
  mainPackageTitle,
  tooltipText,
  isNotEnoughMoney,
  hideTooltipInShortCycle,
}) => (
  <div className={cx('component')}>
    <Text size="size5-m">{mainPackageTitle}</Text>
    {tooltipText && !hideTooltipInShortCycle && (
      <Tooltip message={tooltipText}>
        <InfoIcon fill={isNotEnoughMoney ? '#FF0000' : '#727281'} size="s" />
      </Tooltip>
    )}
  </div>
);

export { InfoBlock };
