import { useCallback } from 'react';

// Services
import getStreetsForCity from 'pages/FTTB/services/getStreetsForCity';

// Constants
import { REQUEST_STATUS } from '../constants';

/**
 * useFetchStreet: запрос к API через getStreetsForCity
 * для получения улицы подключения.
 * @param regionID
 * @param setChosenStreet
 * @param setAddressChecked
 * @returns {{onSelectStreetFromList: onSelectStreetFromList, fetchStreets: (function(*): (Promise<unknown>))}}
 */
export const useFetchStreet = ({ regionID, setChosenStreet, setAddressChecked }) => {
  const onSelectStreetFromList = (value) => {
    if (!value?.isConnected) {
      setAddressChecked(REQUEST_STATUS.isNotAvailable);
    }

    setChosenStreet(value);
  };

  const fetchStreets = useCallback(
    (value) => {
      if (value) {
        return getStreetsForCity(regionID, value).then(({ data }) => {
          if (data?.length === 0) {
            setAddressChecked(REQUEST_STATUS.streetIsNotFound);
            return [];
          }
          return data;
        });
      }
      return setAddressChecked(REQUEST_STATUS.isNone);
    },
    [regionID],
  );

  return { fetchStreets, onSelectStreetFromList };
};
