import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Preloader } from '@beef/legacy-ui-kit';
import { Button, Link, Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const RequestStatus = ({
  onClick,
  pending,
  isLeftCentered,
  fullSize,
  data: { icon, title, description, buttonText, buttonUrl },
}) => (
  <div className={cx('component', { 'component--left': isLeftCentered })}>
    {icon && <img alt="" className={cx('icon')} src={icon} />}
    {!pending && (
      <div
        className={cx('title', {
          'title--left': isLeftCentered,
          'title--full-size': fullSize,
        })}
      >
        <Text size="size1-m" tagType="h1">
          {title}
        </Text>
      </div>
    )}
    {pending && (
      <div className={cx('preloader-wrap')}>
        <Preloader size="76" />
      </div>
    )}
    <div
      className={cx('description__wrap', {
        'description__wrap--left': isLeftCentered,
      })}
    >
      <div className={cx('description')}>
        <Text size="size6-r">{description}</Text>
      </div>
    </div>
    {!pending && (onClick || buttonUrl) && buttonText && (
      <div className={cx('btn-wrap')}>
        {onClick && !buttonUrl && (
          <Button fullWidth onClick={onClick} size="m" variant="main">
            {buttonText}
          </Button>
        )}
        {buttonUrl && (
          <Link buttonSize="m" buttonVariant="main" href={buttonUrl} isFullWidth variant="button">
            {buttonText}
          </Link>
        )}
      </div>
    )}
  </div>
);

RequestStatus.defaultProps = {
  pending: false,
  fullSize: false,
};

RequestStatus.propTypes = {
  onClick: PropTypes.func,
  isLeftCentered: PropTypes.bool,
  pending: PropTypes.bool,
  fullSize: PropTypes.bool,

  data: PropTypes.shape({
    icon: PropTypes.string,
    titleIcon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
  }),
};

export default RequestStatus;
