/**
 * Функция getAdditionalInfo определяет
 * текст описания оплаты в чеке
 * @returns {string}
 */
export const getAdditionalInfo = ({
  commonTotalPrice,
  showMinTotalInfo,
  additionalCommonInfo,
  initialBalancePayment,
  additionalCommonInfoMinTotal,
}) =>
  showMinTotalInfo ?
    additionalCommonInfoMinTotal.replace('{{totalPrice}}', initialBalancePayment)
  : additionalCommonInfo.replace('{{totalPrice}}', commonTotalPrice);

/**
 * Функция getDisconnectSocs возвращает массив
 * отключаемых соков пакетов/опций
 * @returns {array}
 */
const getDisconnectSocs = (data, socType) => {
  if (data) {
    return Object?.values(data)?.reduce((acc, curr) => {
      return (
          (curr.defaultEnabled && !curr.checked && socType !== 'connectedSoc' && !curr.value) ||
            (curr.checked &&
              !curr?.isConnectedPeriod &&
              socType === 'connectedSoc' &&
              curr?.connectedSoc)
        ) ?
          [...acc, curr[socType]]
        : acc;
    }, []);
  } else return [];
};

/**
 * Функция disconnectSocs формирует общий массив
 * отключаемых соков пакетов/опций
 * @returns {array}
 */
export const disconnectSocs = (packagesData, optionsData) => {
  const disconnectAutoProlongSoc = getDisconnectSocs(packagesData, 'socAutoProlong');
  const disconnectOptionsSocs = getDisconnectSocs(optionsData, 'soc');
  const changePeriodSoc = getDisconnectSocs(optionsData, 'connectedSoc');
  return [...disconnectAutoProlongSoc, ...disconnectOptionsSocs, ...changePeriodSoc];
};

/**
 * Функция getConnectSocs возвращает массив
 * подключаемых соков пакетов/опций
 * @returns {array}
 */
const getConnectSocs = (data, socType) => {
  if (data) {
    return Object?.values(data)?.reduce((acc, curr) => {
      if (socType === 'packages') {
        if (curr?.value) {
          return curr.socAutoProlong ? [...acc, curr.soc, curr.socAutoProlong] : [...acc, curr.soc];
        } else return acc;
      }

      if (socType === 'options') {
        if (curr.isConnectedPeriod && curr.checked) {
          disconnectSocs(null, curr);
        }
        return (!curr.defaultEnabled && curr.checked) || (curr.checked && !curr.isConnectedPeriod) ?
            [...acc, curr.soc]
          : acc;
      }
      return acc;
    }, []);
  } else return [];
};

/**
 * Функция connectSocs формирует общий массив
 * подключаемых соков пакетов/опций
 * @returns {array}
 */
export const connectSocs = (packagesData, optionsData) => {
  const connectPackagesSocs = getConnectSocs(packagesData, 'packages');
  const connectOptionsSocs = getConnectSocs(optionsData, 'options');
  return [...connectPackagesSocs, ...connectOptionsSocs];
};
