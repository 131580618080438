import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getStoriesRequest } from '../../api';

export const fetchStories = createAsyncThunk('stories/fetchAll', getStoriesRequest);

const initialState = {
  list: [],
  loading: true,
  currentRegionRating: null,
  currentRegionList: null,
  currentStory: null,
};

const storiesSlice = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    setCurrentRegionRating(state, action) {
      state.currentRegionRating = action.payload;
    },
    setCurrentRegionList(state, action) {
      state.currentRegionList = action.payload;
    },
  },
  extraReducers: {
    [fetchStories.fulfilled]: (state, action) => {
      const currentRegion = action.payload[0]?.region;
      state.currentRegionRating = currentRegion;
      state.currentRegionList = currentRegion;
      state.list = action.payload;
      state.loading = false;
    },
  },
});

export const { setCurrentRegionRating, setCurrentRegionList } = storiesSlice.actions;
export default storiesSlice.reducer;
export * from './selectors';
