import { RefObject, useEffect, useState } from 'react';
import { PanInfo, useMotionValueEvent, useScroll } from 'framer-motion';

import { useIsMobile } from 'utils/hooks/useIsMobile';

/** Подготавливает горизонтальный горизонтальный скрол и драг */
export const useScrollOnDrag = (containerRef: RefObject<HTMLDivElement>) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const isMobileOs = useIsMobile();
  /* Получаем прогресс горизонтального скролла */
  const { scrollXProgress } = useScroll({ container: containerRef });

  /* Функция для вычисления смещения активного элемента */
  const handleDrag = (info: PanInfo) => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= info.delta.x;
    }
  };

  /* Отслеживаем изменение прогресса горизонтального скролла */
  useMotionValueEvent(scrollXProgress, 'change', (value) => {
    const isAtTheEnd = value > 0.99;
    if (isAtTheEnd !== isScrolledToEnd) {
      setIsScrolledToEnd(isAtTheEnd);
    }

    return value;
  });

  /* Определяем, должен ли быть включен слушатель перетаскивания */
  const isDragListener = !isMobileOs && hasOverflow;

  /* Функции для начала и окончания перетаскивания */
  const handleDragStart = () => {
    document.body.style.cursor = 'grab';
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    document.body.style.cursor = '';
    setIsDragging(false);
  };

  useEffect(() => {
    /* Проверяем наличие переполнения в контейнере */
    if (containerRef.current) {
      const isOverflow = containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setHasOverflow(isOverflow);
    }
  }, []);

  /* Возвращаем необходимые значения и функции */
  return {
    isDragListener,
    isDragging,
    isScrolledToEnd,
    handleDrag,
    handleDragStart,
    handleDragEnd,
  };
};
