import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { TextPropsType } from '@beef/ui-kit';

import { Description, TDescription } from './components/Description';
import { Price, TPrice } from './components/Price';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TInfoPrice = {
  infoPrice?: Partial<TPrice & TDescription>;
  /** Свойство отвечает за вариант визуализации плитки ServiceTile. Горизонтальный вид используется по-умолчанию. */
  variant?: 'horizontal' | 'vertical';
} & Omit<TextPropsType, 'size'>;

/** Цена (цена со скидкой) сервиса для ServiceTile.
 * InfoPrice - один из вариантов отображения контента */
export const InfoPrice: FC<Omit<TInfoPrice, 'children'>> = ({
  color,
  variant = 'horizontal',
  infoPrice,
}) =>
  infoPrice?.price ?
    <div className={cx('wrapper', `wrapper--${variant}`)}>
      <Description {...{ color, description: infoPrice?.description }} />
      <Price {...infoPrice} />
    </div>
  : null;
