export const MODAL_STEPS_SPN = {
  MAIN: 'main',
  SUCCESS_ACTIVATE: 'successActivate',
  SUCCESS_CHANGE: 'successChange',
  CONFIRM_DEACTIVATE: 'confirmDeactivate',
  SUCCESS_DEACTIVATE: 'successDeactivate',
  TECH_ERROR: 'techError',
  LOADER: 'loader',
  TIMEOUT_ERROR: 'timeoutError',
};

export const LOADER_CONTENT_STEPS = {
  LOADER_ACTIVATE: 'activate',
  LOADER_DEACTIVATE: 'diactivate',
  LOADER_CHANGENAME: 'changeName',
};

export const CONNECTION_STATUS = {
  available: 0,
  notavailable: 1,
  notenoughmoney: 2,
  ctnisblocked: 3,
  ctnisblockedbyfinance: 4,
  conflictwithtariff: 5,
  conflictwithservices: 6,
  replaceservices: 7,
  timeout: 8,
  hasnotfamilynumbers: 9,
  requestsent: 10,
  error: 11,
  forbiddenname: 12,
};

export const POLLING_STATUS = {
  error: 'ERROR',
  progress: 'IN_PROGRESS',
  complete: 'COMPLETE',
};

export const DETAILS_STATUSES = {
  AVAILABLE: 1,
  IN_PROGRESS: 2,
  ERROR: 3,
};

export const DETAILS_STATUSES_TYPE = {
  CONNECT: 1,
  CHANGE: 2,
  DISCONNECT: 3,
  UNKNOWN: 0,
};

export const CHILD_SOC_PRICE_KEY_VARIANTS = {
  SUBSCRIPTION_FEE: 'SubscriptionFee',
  PRICE: 'Price',
};
