import { createAsyncThunk } from '@reduxjs/toolkit';

import { TChangeTariffRes, fetchChangeTariff } from 'constructorV1/api/fetchChangeTariff';
// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { TRequestParams } from 'constructorV1/store/commonBillSlice/types';

/** Тип запроса (params) */
type TChangeTariffReq = NonNullable<TRequestParams>;

/** Запрос данных на смену тарифа */
export const changeTariff = createAsyncThunk<TChangeTariffRes, TChangeTariffReq>(
  'changeTariffApi',
  async (params, { signal, rejectWithValue }) => {
    try {
      const response = (await fetchChangeTariff({ params, signal })).data;

      if (!response?.isSucceeded) {
        throw new Error();
      }
      return response;
    } catch (e) {
      return rejectWithValue({ isSucceeded: false });
    }
  },
);
