import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { ItemInfoList, TItemInfoList } from './components/ItemInfoList';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TInfoList = {
  infoList?: TItemInfoList[];
  variant: 'horizontal' | 'vertical';
};

/** Список сервисов в виде иконки и описания для ServiceTile.
 * InfoList - один из вариантов отображения контента */
export const InfoList: FC<TInfoList> = ({ infoList, variant }) =>
  infoList?.length ?
    <ul className={cx('wrapper', `wrapper--${variant}`)}>
      {infoList?.map(({ icon, text }) => <ItemInfoList key={text} {...{ icon, text }} />)}
    </ul>
  : null;
