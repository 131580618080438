import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { Explanation } from './components/Explanation';
import { Description } from './components/Description';
import { TDescriptionCell } from './components/DescriptionCell';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TPassiveInfo = {
  explanation?: string;
  info: TDescriptionCell[];
  subExplanation?: string;
  title?: string;
};

/** Базовая информация по пассивке */
export const PassiveInfo: FC<TPassiveInfo> = ({ info, title, explanation, subExplanation }) => (
  <div className={cx('wrapper')}>
    {title && <Text size="size4-m">{title}</Text>}
    <div className={cx('wrapper__content')}>
      <Explanation {...{ explanation, subExplanation }} />
      <Description info={info} />
    </div>
  </div>
);
