import { createSlice } from '@reduxjs/toolkit';

import { EConnectionType, ESimCardType, TUserSlice } from './types';

export const initialState: TUserSlice = {
  /* Вариант подключения тарифа (новый номер, mnp) */
  connectionType: EConnectionType.newSim,
  /* Вариант sim-карты (пластик, eSim) */
  simCardType: ESimCardType.sim,
  isAuthenticated: false,
  /* Номер телефона юзера при авторизации */
  activeCTN: '',
  isArchived: false,
  isConnected: true,
  isFamilyConnected: false,
};

/** Слайс с информацией о пользователе */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    /* Установка таба смены способа подключения тарифа (mnp, simOrderForm) */
    setConnectionType: (state, action) => {
      state.connectionType = action.payload;
    },
    /* Установка таба варианта sim-карты (пластик, eSim) */
    setSimCardType: (state, action) => {
      state.simCardType = action.payload;
    },
  },
});

export const { setConnectionType, setSimCardType } = userSlice.actions;

export const userReducer = userSlice.reducer;
