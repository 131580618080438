import React, { memo, useMemo } from 'react';
import { Text } from '@beef/ui-kit';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from '../styles.pcss';

const cx = classNames.bind(styles);

const Summary = memo(({ content, totalSum, info }) => {
  const totalPriceText = useMemo(
    () => (
      <div className={cx('right-indent')}>
        <Text color="grey95" size="size3-b" tagType="span">
          {content.totalPriceText}
        </Text>
      </div>
    ),
    [content.totalPriceText],
  );

  const longRateUnit = useMemo(
    () => (
      <Text color="grey95" size="size5-m" tagType="span">
        {info.longRcRateUnit}
      </Text>
    ),
    [info.longRcRateUnit],
  );

  return (
    <>
      {!totalSum.discountSum && (
        <div className={cx('total')}>
          {totalPriceText}
          <Text color="grey95" size="size1-m" tagType="span" testName="Check_total">
            {totalSum.sum}
          </Text>
          &nbsp;
          {longRateUnit}
        </div>
      )}

      {totalSum.discountSum && (
        <div className={cx('discount')}>
          {totalPriceText}
          <Text color="grey95" size="size1-m" tagType="span" testName="Check_total_discount">
            {`${totalSum.discountSum} ${info.chargeAmountUnit}`}
          </Text>
          &nbsp;
          <div className={cx('discount__price')}>
            <Text
              color="grey50"
              crossOutColor="grey60"
              isCrossedOut
              size="size4-b"
              tagType="span"
              testName="Check_total_discount_crossed"
            >
              {totalSum.sum}
            </Text>
            <Text color="grey60" size="size5-m" tagType="span">
              {info.longRcRateUnit}
            </Text>
          </div>
        </div>
      )}
    </>
  );
});

Summary.propTypes = {
  content: PropTypes.shape({
    totalPriceText: PropTypes.string,
  }),
  info: PropTypes.shape({
    longRcRateUnit: PropTypes.string,
    longPeriod: PropTypes.string,
  }),
  totalSum: PropTypes.shape({
    sum: PropTypes.string,
    discountSum: PropTypes.string,
  }),
};

export default Summary;
