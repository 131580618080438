import { Button } from '@beef/ui-kit';
import React, { useState } from 'react';

import { ModalsControllerProps } from './ModalsController/types';
import { ActionBar, LimitModalContent } from './components';

type ChangeLimitModalDataProps = Pick<
  ModalsControllerProps,
  'barValues' | 'limit' | 'onChangeLimit'
> & {
  data: ModalsControllerProps['data']['limit'];
  defaultValue?: number;
  isLoading: boolean;
  setConnectedLimit: (val: number) => void;
};

export const useChangeLimitModalData = ({
  onChangeLimit,
  data,
  limit,
  barValues,
  setConnectedLimit,
  isLoading,
  defaultValue = 0,
}: ChangeLimitModalDataProps) => {
  const { title, description, buttons } = data || {};
  const { change } = buttons || {};
  const desc = description.replace('{{limit}}', limit.toString());

  const [limitValue, setLimitValue] = useState(defaultValue);

  const onConfirmButtonClick = () => {
    onChangeLimit(limitValue);
    setConnectedLimit(limitValue);
  };

  return {
    title,
    content: (
      <LimitModalContent
        barValues={barValues}
        defaultValue={defaultValue}
        description={desc}
        onChange={(value) => setLimitValue(value)}
      />
    ),
    actionBar: (
      <ActionBar>
        <Button isLoading={isLoading} onClick={onConfirmButtonClick}>
          {change.title}
        </Button>
      </ActionBar>
    ),
  };
};
