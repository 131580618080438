import React, { FC } from 'react';
import { Button } from '@beef/ui-kit';
import { RootStateOrAny, connect } from 'react-redux';

import { setLoadingModal, setStepModalSpn, setToggleModal } from 'pages/SPN/store/actions';
import { getDetailsSpn, redirectToLogin } from 'pages/SPN/api';
import {
  selectIsAuthenticated,
  selectIsLoadingModal,
  selectStateSpn,
} from 'pages/SPN/store/actions/selectors';
import { ActionButtonTeaserType, ThunkDispatchType } from 'pages/SPN/types';
import { MODAL_STEPS_SPN } from 'pages/SPN/store/constants';

const ActionButton: FC<ActionButtonTeaserType> = ({
  button,
  fullWidth,
  onOpenModal,
  onSetLoadingModal,
  onSetStepOfModal,
  isLoadingModal,
  isAuthenticated,
}) => {
  const handleOnClick = async () => {
    if (!isAuthenticated) {
      redirectToLogin();
      return;
    }

    if (!isLoadingModal) {
      onSetLoadingModal();
    }

    onSetStepOfModal(MODAL_STEPS_SPN.MAIN);
    onOpenModal();
    await getDetailsSpn();
  };

  return (
    <Button fullWidth={fullWidth} onClick={handleOnClick} size="l" testName="TeaserActionBtn">
      {button?.text}
    </Button>
  );
};

const ActionButtonConnected = connect(
  (state: RootStateOrAny) => {
    const spnState = selectStateSpn(state);

    return {
      isLoadingModal: selectIsLoadingModal(spnState),
      isAuthenticated: selectIsAuthenticated(state),
    };
  },
  (dispatch: ThunkDispatchType) => ({
    onOpenModal: () => dispatch(setToggleModal()),
    onSetLoadingModal: () => dispatch(setLoadingModal()),
    onSetStepOfModal: (step: string) => dispatch(setStepModalSpn(step)),
    dispatch,
  }),
)(ActionButton);

export { ActionButtonConnected as ActionButton };
