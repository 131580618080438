import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { toggleTariffConnectionPopup as toggleTariffConnectionPopupAction } from 'pages/ProductCard/actions/tariffConnect/toggleTariffConnectionPopup';
import { pushOpenTariffConnectPopup } from 'utils/ga';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ChangeTariffBtn = ({ data, toggleTariffConnectionPopup, className, modifier }) => {
  const { twoOrderBtnsData, offerCode } = data;
  const onClick = () => {
    pushOpenTariffConnectPopup({
      isMnp: false,
      tariffName: data.pageTitle,
      marketCode: data.marketCode,
      method: twoOrderBtnsData.gaChangeTariffEventMethod || 'Number',
      soc: data.soc,
    });
    toggleTariffConnectionPopup(true, offerCode, twoOrderBtnsData.changeTariffTabs);
  };

  return (
    <Button
      className={[cx('changeTariffBtn', className), modifier]}
      color="light"
      onClick={onClick}
      wide
    >
      {twoOrderBtnsData.changeTariffText}
    </Button>
  );
};

ChangeTariffBtn.propTypes = {
  data: PropTypes.shape({
    twoOrderBtnsData: PropTypes.shape({
      changeTariffText: PropTypes.string,
      changeTariffTabs: PropTypes.arrayOf(PropTypes.string),
    }),
    offerCode: PropTypes.string,
  }),
  modifier: PropTypes.string,
  toggleTariffConnectionPopup: PropTypes.func,
};

const mapDispatchToProps = {
  toggleTariffConnectionPopup: toggleTariffConnectionPopupAction,
};

export default connect(null, mapDispatchToProps)(ChangeTariffBtn);
