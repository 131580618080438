import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { ECBillRequestErrors } from 'constructorV1/store/commonBillSlice/types';
import { TCMoreDetails } from 'constructorV1/components/commonComponents/BillCommon/components/CMoreDetails';
import { TCPlanSettings } from 'constructorV1/components/commonComponents/BillCommon/components/CPlanSettings';
import { TCAccordionItem } from 'constructorV1/components/commonComponents/BillCommon/components/CAccordionItem';

import { apiGetTotalBillUrl } from './constants';

/** Тип объекта data запроса fetchTotalBill результирующего чека (common bill) ConstructorV1 */
export type TData = {
  details: TCMoreDetails;
  requestParams: {
    constructorId: number;
    optionsCategories: string[];
    soc: string;
  };
  settings: TCPlanSettings;
  zeroPresetsConditions: TCAccordionItem[];
};

/** Тип ответа запроса fetchTotalBill результирующего чека (common bill) ConstructorV1 */
export type TFetchTotalBillRes = {
  data?: TData;
  error: ECBillRequestErrors;
  status: number;
  success: boolean;
};

/** Запрос на получение результирующего чека (common bill) ConstructorV1 */
export const fetchTotalBill = async (
  config: AxiosRequestConfig,
): Promise<AxiosResponse<TFetchTotalBillRes>> => axios.get(apiGetTotalBillUrl, config);
