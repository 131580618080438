import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { Link } from '@beef/ui-kit';

import { Button } from 'pages/CarnicaDSLandings/ui';
import { Metric } from 'pages/CarnicaDSLandings/MNPLanding/analytics';

import styles from './styles.pcss';
import { CardForm } from '../../../../components/CardForm';
import { useQRBlock } from './hooks/useQRBlock';

const cx = classNames.bind(styles);

/**
 * Блок с переходом в мобильное приложение
 */
export const QRBlock: FC = () => {
  const { buttonTitle, description, imgQR, title, deeplink, isDesktop } = useQRBlock();

  return (
    <Metric data={title} events={{ yaMNPMetric: ['handleViewQRBlock'] }} mode="viewport">
      <CardForm
        style={isDesktop ? { backgroundImage: `url(${imgQR})` } : {}}
        wrapperClassName={cx('wrapper')}
      >
        <div className={cx('info-block')}>
          <Text color="primary-invert" variant="headline-small">
            {title}
          </Text>

          <Text color="secondary-invert" variant="body-small">
            {description}
          </Text>
        </div>

        {!isDesktop && (
          <Link className={cx('link')} href={deeplink} target="_blank">
            <Button color="white" size="l">
              <Text align="center" variant="body-accent-small">
                {buttonTitle}
              </Text>
            </Button>
          </Link>
        )}
      </CardForm>
    </Metric>
  );
};
