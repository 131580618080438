import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Actions
import { Switch } from '@beef/ui-kit';

import { setOptionParams } from 'pages/ProductCard/Tariff/containers/ZTariff/store/actions';

// Constants
import DispatchContext from 'pages/ProductCard/Tariff/containers/ZTariff/store/constants';

// Components
import Picture from 'pages-components/Picture';

import { Tab } from '../../Slides/Slider/components/SlidesTabs/components/Tab';
import { OptionInfo } from './components/OptionInfo';

// Utils
import { initActivePeriodTab } from '../../../../../../utils';
import { checkedActiveTab, createOptionsParams, getPeriodTitle, onChange } from './utils';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Option = ({
  option,
  activeTab,
  hasZTariff,
  saveDataTab,
  isChangeTariff,
  initialChecked,
}) => {
  const _dispatch = useContext(DispatchContext);
  const [checked, setChecked] = useState(initialChecked);
  const defaultPeriodTab = initActivePeriodTab(option?.tabs);
  const [activeTabPeriod, setActiveTabPeriod] = useState(defaultPeriodTab);
  const [price, setPrice] = useState(option?.tabs[defaultPeriodTab]?.price);
  const hasConnectedPeriod = useMemo(
    () => !!option?.tabs.find((tab) => tab?.isConnected),
    [option],
  );
  const isSelectedTab =
    !checked ||
    (option.tabs[activeTabPeriod]?.isConnected && !option.tabs[activeTabPeriod]?.isInfinite);
  const title = getPeriodTitle(option, checked, activeTabPeriod);

  const getActiveTab = useCallback(
    (idx, tab) =>
      checkedActiveTab({
        idx,
        option,
        checked,
        setChecked,
        activeTabPeriod,
        defaultPeriodTab,
        setActiveTabPeriod,
        hasConnectedPeriod,
        isInfinite: tab?.isInfinite,
        isConnected: tab?.isConnected,
      }),
    [option, checked, activeTabPeriod],
  );

  const sendOptions = () => {
    _dispatch(setOptionParams(createOptionsParams(checked, option, activeTabPeriod)));
  };
  const handleChange = onChange(
    setChecked,
    checked,
    setActiveTabPeriod,
    defaultPeriodTab,
    sendOptions,
  );

  useLayoutEffect(() => {
    if ((activeTab === 'MNPForm' || activeTab === 'SimOrderForm' || !hasZTariff) && saveDataTab) {
      setActiveTabPeriod(activeTabPeriod);
      setChecked(checked);
    } else {
      setActiveTabPeriod(defaultPeriodTab);
      setChecked(option.defaultEnabled);
    }
  }, [saveDataTab, activeTab]);

  useEffect(() => {
    setPrice(option.tabs[activeTabPeriod]?.price);
  }, [checked, activeTabPeriod, price]);

  useEffect(() => {
    _dispatch(setOptionParams(createOptionsParams(checked, option, activeTabPeriod)));
  }, [
    isChangeTariff,
    option,
    hasConnectedPeriod,
    checked,
    activeTabPeriod,
    price,
    activeTab,
    initialChecked,
  ]);

  return (
    <div className={cx('option')}>
      <div className={cx('option__platform-top')}>
        {option?.title && <OptionInfo benefit={option?.benefit} title={title} />}
        {price && (
          <Switch
            checked={checked}
            disabled={option.persistentEnabled && hasZTariff}
            name={option.title}
            onChange={handleChange}
          />
        )}
      </div>
      {option?.iconForZTariffOptions && (
        <div className={cx('option__sprite', { 'option__sprite--shadow': !checked })}>
          <Picture src={option.iconForZTariffOptions} />
        </div>
      )}
      {option?.tabs && (
        <div className={cx('option__tabs')}>
          {option?.tabs?.map((tab, idx) => (
            <Tab
              disabled={!checked || (tab?.isConnected && !tab?.isInfinite)}
              id={idx}
              isConnectedPeriod={tab?.isConnected}
              isInfinite={tab?.isInfinite}
              key={tab?.text}
              onClick={() => getActiveTab(idx, tab)}
              selected={isSelectedTab ? '' : activeTabPeriod}
              title={tab?.text}
            />
          ))}
        </div>
      )}
    </div>
  );
};

Option.propTypes = {
  option: PropTypes.shape({
    title: PropTypes.string,
    benefit: PropTypes.string,
    defaultEnabled: PropTypes.bool,
    persistentEnabled: PropTypes.bool,
    iconForZTariffOptions: PropTypes.string,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        soc: PropTypes.string,
        unit: PropTypes.string,
        text: PropTypes.string,
        price: PropTypes.number,
        isActive: PropTypes.bool,
        isInfinite: PropTypes.bool,
        isConnected: PropTypes.bool,
        expirationPeriod: PropTypes.string,
      }),
    ),
  }),
  hasZTariff: PropTypes.bool,
  activeTab: PropTypes.string,
  saveDataTab: PropTypes.bool,
  isChangeTariff: PropTypes.bool,
  initialChecked: PropTypes.bool,
};
