import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Link, TextInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Ctx from 'pages-components/Ctx';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const LoginForm = ({ authorized, title, description, titleLink }) => {
  const [ctn, ctnChange] = useState('');
  const submit = () => {
    window.location.href = `/login/?loginAutofillCtn=${btoa(ctn)}`;
  };

  return (
    <div className={cx('component')}>
      <Link
        className={[cx('link'), 'hideVisitedColor']}
        href={titleLink || Ctx.loginForm.titleLink}
      >
        <span dangerouslySetInnerHTML={{ __html: title || Ctx.loginForm.title }} />
      </Link>

      <div dangerouslySetInnerHTML={{ __html: description || Ctx.loginForm.description }} />

      {!authorized && (
        <div className={cx('form')}>
          <TextInput onChange={ctnChange} placeholder="Телефон или Логин" value={ctn} />
          <Button disabled={!ctn} onClick={submit}>
            {Ctx.loginForm.buttonText}
          </Button>
          <Link
            className="hideVisitedColor"
            href="https://identity.beeline.ru/identity/recoverypassword"
          >
            {Ctx.loginForm.infoLinkText}
          </Link>
        </div>
      )}
    </div>
  );
};

LoginForm.propTypes = {
  authorized: PropTypes.bool,
  title: PropTypes.string,
  titleLink: PropTypes.string,
  description: PropTypes.string,
};

export default LoginForm;
