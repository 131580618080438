import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Bubble } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Tiles - Который отрисовывает Bubble;
 * @param label - Текст бабла;
 * @param iconName - Название иконки;
 * @param icon - ссылка на иконку;
 * @param className - Название класса для Bubble;
 */
const BubbleTiles = ({ label, iconName, icon, className }) => (
  <Bubble className={['topLeft', cx('bubble'), className]}>
    {icon ?
      <img alt="" className={cx('icon')} src={icon} />
    : <Icon className={cx('icon')} name={iconName} />}
    <div className={cx('text')} dangerouslySetInnerHTML={{ __html: label }} />
  </Bubble>
);

BubbleTiles.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
};

export default BubbleTiles;
