import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStatus: null,
};

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setStatus(state, action) {
      state.currentStatus = action.payload;
    },
    resetStatus(state) {
      state.currentStatus = null;
    },
  },
});

export const { setStatus, resetStatus } = statusSlice.actions;
export default statusSlice.reducer;
export * from './selectors';
