import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { SmartForm, SmartInput } from '@beef/legacy-ui-kit';
import { CloseIcon, Text } from '@beef/ui-kit';

import BISCaptcha from 'pages-components/Captcha/BISCaptcha';
import { debounce } from 'utils/timed-functions';

import { CAPTCHA_LENGTH } from '../../utils/ym';
import styles from './Forms.pcss';

const cx = classNames.bind(styles);

const AuthCaptchaForm = ({
  onCaptchaSubmit,
  bisUrl,
  className,
  isAudioCaptchaEnabled,
  resetForm,
  data: { smsCaptcha },
  formId,
  onFormReset,
  onClick,
  onReset,
  onFill,
}) => {
  const [captchaKeyValue, setCaptchaKeyValue] = useState('');

  const onCaptchaChangeDebounced = debounce(onFill, 3000);
  useEffect(() => () => onCaptchaChangeDebounced.cancel(), []);

  const onCaptchaChange = useCallback(({ captcha, captchaKey }) => {
    setCaptchaKeyValue(captchaKey);
    if (captcha.length === CAPTCHA_LENGTH) {
      onFill();
      onCaptchaChangeDebounced.cancel();
      return;
    }
    onCaptchaChangeDebounced();
  }, []);

  const handleResetForm = () => {
    onFormReset();
    resetForm();
  };

  return (
    <div className={cx('captcha', className)}>
      <div className={cx('authForm')}>
        <div className={cx('captchaDescription')}>
          <Text size="size5-r-s">{smsCaptcha.captchaDesc}</Text>
        </div>
        <button
          aria-label="Отменить ввод капчи"
          className={cx('cancelCaptcha')}
          onClick={handleResetForm}
          type="button"
        >
          <CloseIcon size="m" />
        </button>
        <div>
          <SmartForm id={formId} onSubmit={onCaptchaSubmit}>
            <SmartInput
              inputId="captchaKey"
              name="captchaKey"
              type="hidden"
              value={captchaKeyValue}
            />
            <BISCaptcha
              className={cx('captcha-wrap')}
              host={bisUrl}
              inputId="captcha"
              isAudioCaptchaEnabled={isAudioCaptchaEnabled}
              onChange={onCaptchaChange}
              onClick={onClick}
              onReset={onReset}
              placeholder={smsCaptcha?.placeholder}
            />
          </SmartForm>
        </div>
      </div>
    </div>
  );
};

AuthCaptchaForm.propTypes = {
  data: PropTypes.shape({
    smsCaptcha: PropTypes.shape({
      captchaDesc: PropTypes.string,
      placeholder: PropTypes.string,
    }),
  }),
  onCaptchaSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  bisUrl: PropTypes.string,
  isAudioCaptchaEnabled: PropTypes.bool,
  className: PropTypes.string,
  formId: PropTypes.string,
  onFormReset: PropTypes.func,
  onClick: PropTypes.func,
  onReset: PropTypes.func,
  onFill: PropTypes.func,
};

export default AuthCaptchaForm;
