import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { InfoTextContent, TInfoTextContent } from './components/InfoTextContent';
import { InfoPicture, TInfoPicture } from './components/InfoPicture';
import { InfoButton, TInfoButton } from './components/InfoButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCommonInfoBanner = {
  buttonProps: TInfoButton;
  contentProps: TInfoTextContent;
  pictureProps: TInfoPicture;
};

/** Информационный компонент с иконкой, текстом и кнопками */
export const CommonInfoBanner: FC<TCommonInfoBanner> = ({
  pictureProps,
  contentProps,
  buttonProps,
}) => (
  <section className={cx('wrapper')}>
    <InfoPicture {...pictureProps} />
    <InfoTextContent {...contentProps} />
    <InfoButton {...buttonProps} />
  </section>
);
