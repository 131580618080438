import { pushAnniversaryEvent } from './utils/analytics';

export const processLandingConstructorData = (data, blockProcessors = {}) => ({
  ...data,
  blocks: data.blocks
    ?.map((block, i) => {
      const identityFn = (val) => val;
      const fn = blockProcessors[block.type] || blockProcessors[block.data?.type] || identityFn;
      return fn(block, i);
    })
    .filter((block) => !!block),
});

export const processTextWithCustomRegex = (text, regex, placeholder) => {
  if (!regex) return text;
  return text.replaceAll(new RegExp(regex, 'g'), placeholder || '');
};

export const StorageHelper = new Proxy(
  {},
  {
    get(_, name) {
      const value = sessionStorage.getItem(name);
      // NOTE: Так как умысел в том, чтобы дать возможность сохранить на время
      // пока пройдет авторизация, нужно сразу после удалить это поле.
      // Иначе для пользователя, при повторной перезагрузке окна, будет не понятно
      // почему изменения не сохраняются кроме тех, что были до авторизации.
      if (value) sessionStorage.removeItem(name);
      return value || '';
    },
    set(_, name, value) {
      sessionStorage.setItem(name, value);
      return true;
    },
  },
);

export const getFieldNames = (initialState) =>
  Object.entries(initialState.data)
    .filter(([_, value]) => value.value !== undefined)
    .map(([key]) => key);

export const preprocessPhone = (phone) => phone?.replace(/^\+\d/, '').replaceAll(/\D/g, '');

const wait = async (duration = 1000) =>
  new Promise((res) => {
    setTimeout(res, duration);
  });

export const retry = async (request, attempts = 3, duration = 1000) => {
  try {
    await request();
  } catch (e) {
    if (attempts <= 1) throw new Error(e);
    await wait(duration);
    await retry(request, attempts - 1);
  }
};

export const getHasErrors = (fieldNameValidators, validateAndSetError) => {
  const errors = Object.entries(fieldNameValidators).map(([fieldName, validators]) =>
    validateAndSetError(fieldName, validators),
  );
  const hasError = errors.some((v) => !!v);

  if (hasError) {
    const text = Object.keys(fieldNameValidators)
      .map((fieldName, i) => `${fieldName}: ${errors[i]}`)
      .filter((v, i) => errors[i])
      .join(', ');

    pushAnniversaryEvent({
      error: {
        code: undefined,
        text,
      },
    });
  }

  return hasError;
};

export const trimName = (name = '') => name.trim().replaceAll(/\s/g, '-');
