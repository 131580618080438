import { cloneElement, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

/**
 * Возвращает список сгрупированных  параметров
 * @param {object} tariff объект тарифа
 * @param {boolean} withTVParams признак указывающий на необходимость отображать ТВ параметры
 * @param {object} data объект с текстами и тд
 * @param {object} lightIcons объект с именами иконок
 * @param {Function} onTvChannelsClick функция вызываемая при клике на параметр каналов
 * @param {boolean} showTvClickable флаг указывающий, отображать кликабельность тв-параметра или нет
 * @returns {Array.<{
 *  alias: string,
 *  mainParam: {
 *    alias: string,
 *    value: string,
 *    unit: string,
 *    iconName: string,
 *    description?: string,
 *    onClick?: Function,
 *  },
 *  additionalParam: {
 *    alias: string,
 *    name: string,
 *    included: boolean,
 *    iconName: string,
 *    description: string,
 *  }
 * }>}
 */
const getParamsGroups = (
  tariff,
  withTVParams,
  data,
  lightIcons,
  onTvChannelsClick,
  showTvClickable,
) => {
  const {
    iconNames,
    paramIncluded,
    paramNotIncluded,
    wifiName,
    tvConsoleName,
    tvDescriptionPrefix,
    tvUnitPostfix,
    tvDescriptionEmpty,
  } = data;

  const speed = tariff?.internetConnectionSpeed;
  const routerIncluded = tariff?.routerInfo?.isIncluded;

  const icons = lightIcons ? iconNames.light : iconNames.default;

  const paramsGroups = [
    {
      alias: 'internet',
      mainParam: {
        alias: 'speed',
        value: speed.value,
        unit: speed.unit,
        iconName: icons.speed,
      },
      additionalParam: {
        alias: 'wi-fi',
        name: wifiName,
        description: routerIncluded ? paramIncluded : paramNotIncluded,
        included: routerIncluded,
        iconName: routerIncluded ? icons.wifi : icons.wifiOff,
        tooltipText: tariff?.routerInfo?.tooltipText,
      },
    },
  ];

  if (withTVParams) {
    const { tvInfo } = tariff;
    const tvDeviceIncluded = tariff.tvDeviceInfo.isIncluded;
    paramsGroups.push({
      alias: 'tv-info',
      mainParam: {
        alias: 'tv',
        value: tvInfo.count,
        unit: `${tvInfo.channelsLabel}${tvUnitPostfix}`,
        description:
          tvInfo.tvDescription ?
            `${tvDescriptionPrefix}${tvInfo.tvDescription}`
          : tvDescriptionEmpty,
        iconName: icons.tv,
        onClick: showTvClickable ? onTvChannelsClick : undefined,
      },
      additionalParam: {
        alias: 'tv-console',
        name: tvConsoleName,
        description: tvDeviceIncluded ? paramIncluded : paramNotIncluded,
        included: tvDeviceIncluded,
        iconName: tvDeviceIncluded ? icons.tvConsole : icons.tvConsoleOff,
      },
    });
  }

  const simDescription = tariff?.mobileTariffInfo?.simDescription;

  if (simDescription) {
    paramsGroups.push({
      alias: 'mobile-info',
      mainParam: null,
      additionalParam: {
        alias: 'sim',
        name: '',
        description: simDescription,
        included: true,
        iconName: icons.sim,
      },
    });
  }

  return paramsGroups;
};

const TariffParamsHoc = ({ children, showTvClickable, ...props }) => {
  const { tariff, showTVParams, data, isPromo, onTvChannelsClick: onTvChannelsClickOrigin } = props;
  const onTvChannelsClick = useCallback(
    () => onTvChannelsClickOrigin(tariff),
    [onTvChannelsClickOrigin, tariff],
  );

  const paramsGroups = useMemo(
    () => getParamsGroups(tariff, showTVParams, data, isPromo, onTvChannelsClick, showTvClickable),
    [tariff, showTVParams, data, isPromo, onTvChannelsClick, showTvClickable],
  );

  const componentProps = useMemo(
    () => ({
      paramsGroups,
      isDark: isPromo,
    }),
    [paramsGroups, isPromo],
  );

  return cloneElement(children, componentProps);
};

TariffParamsHoc.propTypes = {
  tariff: PropTypes.object,
  showTVParams: PropTypes.bool,
  showTvClickable: PropTypes.bool,
  data: PropTypes.object,
  isPromo: PropTypes.bool,
};

export default TariffParamsHoc;
