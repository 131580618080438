import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Popup from 'pages-components/Popup';

// Utils
import { STEPS_CONFIG } from 'utils/ConvertToEsimServiceHelper';

// Components
import RequestStatus from 'pages-components/RequestStatus';

import FormStatementStep from '../steps/FormStatementStep';
import AdditionalInfoStep from '../steps/AdditionalInfoStep';

const RequestSteps = ({
  onClose,
  opened,
  extData,
  errorData,
  successData,
  data,
  step,
  setStep,
}) => {
  const [userFormData, setUserFormData] = useState(null);

  return (
    <Popup onClose={onClose} opened={opened}>
      <div>
        {step === STEPS_CONFIG.initial && (
          <FormStatementStep
            checkOwnerUrl={data.checkOwnerUrl}
            checkUrl={data.checkUrl}
            data={extData}
            onClose={onClose}
            sendDataUrl={data.sendDataUrl}
            setStep={setStep}
            setUserFormData={setUserFormData}
            {...extData.formContent}
          />
        )}
        {step === STEPS_CONFIG.addInfo && (
          <AdditionalInfoStep
            additionalDataUrl={data.additionalDataUrl}
            data={extData}
            onClose={onClose}
            setStep={setStep}
            userFormData={userFormData}
          />
        )}
        {step === STEPS_CONFIG.error && (
          <RequestStatus data={errorData} onClick={() => onClose()} />
        )}
        {step === STEPS_CONFIG.success && (
          <RequestStatus data={successData} onClick={() => onClose()} />
        )}
      </div>
    </Popup>
  );
};

RequestSteps.propTypes = {
  opened: PropTypes.bool,

  onClose: PropTypes.func,
  extData: PropTypes.object,
  errorData: PropTypes.object,
  successData: PropTypes.object,
  step: PropTypes.string,
  setStep: PropTypes.func,
};

RequestSteps.defaultProps = {
  opened: false,

  extData: {},
  onClose: () => {},
};

export default RequestSteps;
