import { useEffect, useState } from 'react';
import { useIsMobileDimension } from '@beef/ui-kit';
import { EHeaderTheme, setHeaderTheme } from '@carnica/smart-kit';
import { useWillMount } from '@beef/react';

import { headerStore2024 } from 'store';

import { IInfo } from '../types';
import { SCREENS_COUNT_WITHOUT_SLIDES } from './constants';

export type IUseSlideScroll = {
  isOpenModal: boolean;
  maxScreens: number;
  sliderRef: React.RefObject<HTMLDivElement>;
  slides: IInfo[];
};

export const useSlideScroll = ({ maxScreens, isOpenModal, slides, sliderRef }: IUseSlideScroll) => {
  const isMobile = useIsMobileDimension();
  const [activeScreen, setActiveScreen] = useState(0);

  useWillMount(() => {
    headerStore2024()?.dispatch(setHeaderTheme(EHeaderTheme?.primary));
  });

  const handleScroll = () => {
    const scrollPosition = window?.scrollY || 0;

    let slidesNumber = SCREENS_COUNT_WITHOUT_SLIDES + (slides?.length || 0);

    if (isMobile) {
      slidesNumber = SCREENS_COUNT_WITHOUT_SLIDES + (slides?.length ? 1 : 0);
    }

    const sliderHeight =
      ((sliderRef?.current?.scrollHeight || 1) - (window?.innerHeight || 1)) / slidesNumber;
    let screenNumber = Math.floor(scrollPosition / sliderHeight);

    if (screenNumber > maxScreens) {
      screenNumber = maxScreens;
    }

    try {
      if (screenNumber !== activeScreen && screenNumber === maxScreens) {
        headerStore2024()?.dispatch(setHeaderTheme(EHeaderTheme?.dark));
      } else if (screenNumber !== activeScreen) {
        headerStore2024()?.dispatch(setHeaderTheme(EHeaderTheme?.primary));
      }
    } catch (e) {
      console.error(e);
    }

    if (!isOpenModal) {
      setActiveScreen(screenNumber);
    }
  };

  useEffect(() => {
    window?.addEventListener('scroll', handleScroll, { passive: true });
    return () => window?.removeEventListener('scroll', handleScroll);
  }, [isOpenModal, slides, sliderRef, maxScreens, isMobile]);

  return { activeScreen };
};
