import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

// Components
import { TariffSettingsItemContainer } from './TariffSettingsItemContainer';
// Actions
import {
  setCurrentUserTariff,
  setIsFinishedSettings,
  setUserAddressConnect,
  setUserNumber,
} from '../../../../store/actions/totalSettings';
// Selectors
import { getAddressText, getIsSettled } from '../../../../store/selectors/modal';
import {
  getActiveTab,
  getHasSeamlessUrlParams,
  getSeamlessUrlParams,
} from '../../../../store/selectors/orderTariffDetailsData';
import { getIsFinishedSettings } from '../../../../store/selectors/totalSettings';
import { getUserAuthenticated, getUserCTN } from '../../../../store/selectors/userData';
import {
  getUserSelectedFlat,
  getUserSelectedHouseID,
  getUserSelectedPhoneNumber,
} from '../../../../store/selectors/orderTariffUserData';
// Hooks
import { useCheckedServices } from './TariffSettingsItemContainer/Hooks/useCheckedServices';
// PropTypes
import { tariffShape } from '../../../../PropTypes';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const TariffSettingsLayout = ({ ...props }) => {
  const { tariffs, isMobile, tariffInacId, isAuthenticated } = useCheckedServices({ ...props });
  return (
    <ul className={cx('wrapper')}>
      {tariffs?.map((service) => {
        if (!isAuthenticated && service.isHidden) {
          return null;
        }
        return (
          <TariffSettingsItemContainer
            {...service}
            isMobile={isMobile}
            key={service.guid}
            tariffInacId={tariffInacId}
          />
        );
      })}
    </ul>
  );
};

const mapDispatchToProps = {
  setCTN: setUserNumber,
  setAddress: setUserAddressConnect,
  resetIsFinish: setIsFinishedSettings,
  setActiveTariff: setCurrentUserTariff,
};

const mapStateToProps = (state) => ({
  userCTN: getUserCTN(state),
  isSettled: getIsSettled(state),
  activeTab: getActiveTab(state),
  flat: getUserSelectedFlat(state),
  addressText: getAddressText(state),
  houseId: getUserSelectedHouseID(state),
  isAuthenticated: getUserAuthenticated(state),
  seamlessUrlParams: getSeamlessUrlParams(state),
  isFinishSetSetting: getIsFinishedSettings(state),
  hasSeamlessUrlParams: getHasSeamlessUrlParams(state),
  userSelectedNumber: getUserSelectedPhoneNumber(state),
});

TariffSettingsLayout.propTypes = {
  activeTab: PropTypes.string,
  setActiveTariff: PropTypes.func,
  isFinishSetSetting: PropTypes.bool,
  currentTariffs: PropTypes.arrayOf(tariffShape),
};

export const TariffSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TariffSettingsLayout);
