import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { polling } from '@beef/utils';

import { RedirectOptions } from 'pages/Services/RedirectServiceCardLanding/store/redirect/types';
import {
  defaultOptions,
  redirectServiceUrl,
  statusNot200,
} from 'pages/Services/RedirectServiceCardLanding/const';
import { RedirectStore } from 'pages/Services/RedirectServiceCardLanding/store';

const saveSession = async (url: string, body: unknown, params?: AxiosRequestConfig) => {
  try {
    const { data, status } = await axios.post(url, body, params);
    if (status === 200) {
      return data;
    }
  } catch (e) {
    throw new Error(e);
  }
  throw new Error(statusNot200);
};

const fetchRedirectData = async (url: string, requestId: number) => {
  try {
    const { data, status } = await axios.post<RedirectOptions>(url, { requestId, clearJson: true });
    if (status === 200) {
      return {
        isSuccess: true,
        isError: false,
        data,
      };
    }

    return {
      isSuccess: false,
      isError: true,
    };
  } catch (e) {
    throw new Error(e);
  }
};

const handlerFetchRedirectData = (v: { data: unknown }) => {
  const { data } = v;
  if (data === 'not ready' || data?.notReady === true) {
    return {
      isDone: false,
      isFailed: false,
    };
  }
  if (data?.IsSucceeded === false || data?.createCallForwardFallen === true) {
    return {
      isDone: true,
      isFailed: true,
    };
  }
  return {
    isDone: true,
    isFailed: false,
  };
};

export const getStatus = async ({
  url,
  requestId,
  timeout = 70,
  interval = 10,
}: {
  interval?: number;
  requestId: number;
  timeout?: number;
  url: string;
}) => {
  const poll = polling(fetchRedirectData, handlerFetchRedirectData, {
    initialTimeout: 0,
    timeout: timeout * 1000,
    interval: interval * 1000,
  });
  try {
    return await poll(url, requestId);
  } catch (e) {
    throw new Error(e);
  }
};

export const getRedirectData = createAsyncThunk<
  RedirectOptions,
  { isAuthenticated: boolean; requestId: number },
  {
    state: RedirectStore;
  }
>('getRedirectData', async (payload, { getState }) => {
  const poll = getState()?.content?.contentQp?.polling;
  const { requestId, isAuthenticated } = payload;

  try {
    if (isAuthenticated) {
      return await getStatus({
        url: redirectServiceUrl.getRedirectData,
        requestId,
        timeout: poll?.SERVICE_FORWARDING_DETAIL_CONNECT_STATE_MAX_LOADING_TIME,
        interval: poll?.SERVICE_FORWARDING_DETAIL_CONNECT_STATE_INTERVALS,
      });
    }
    return defaultOptions;
  } catch (e) {
    throw new Error(e);
  }
});

export const setRedirectData = createAsyncThunk<
  RedirectOptions,
  { dataForStartRequest: Partial<RedirectOptions>; options: RedirectOptions },
  {
    state: RedirectStore;
  }
>('setRedirectData', async (payload, { getState }) => {
  const { dataForStartRequest = {}, options } = payload || {};
  const poll = getState()?.content?.contentQp?.polling;
  try {
    const { requestId } = await saveSession(
      '/customers/products/mobile/services/saveredirectdata/',
      { ...dataForStartRequest, clearJson: true },
      { headers: { 'X-Requested-With': 'XMLHttpRequest' } },
    );
    if (requestId === 0) {
      throw new Error('Получен невалидный requestId');
    }
    await getStatus({
      url: redirectServiceUrl.checkConnectServiceStatus,
      requestId,
      timeout: poll?.SERVICE_FORWARDING_CONNECT_STATE_MAX_LOADING_TIME,
      interval: poll?.SERVICE_FORWARDING_CONNECT_STATE_INTERVALS,
    });
    return options;
  } catch (e) {
    throw new Error(e);
  }
});
