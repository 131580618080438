import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { TDescriptionBlock } from './types';
import { SHOW_MORE } from './constants';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент описания оборудования, содержит кнопку открытия модального окна */
export const DescriptionBlock: FC<TDescriptionBlock> = ({
  description,
  hasContentPopup,
  showMoreButton,
  onClick,
}) => (
  <div className={cx('component')}>
    <Text color="grey60" size="size5-r-s">
      {description}
    </Text>
    {hasContentPopup && (
      <button className={cx('button')} onClick={onClick} type="button">
        <Text color="contentAccent1" size="size5-r-s">
          {showMoreButton || SHOW_MORE}
        </Text>
      </button>
    )}
  </div>
);
