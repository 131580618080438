import React, { Component } from 'react';
import { Tooltip } from '@beef/legacy-ui-kit';
import { Checkbox } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { getMobileOS } from 'utils/detect-mobile';

import ControlsWrapper from '../Wrapper';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

class Coverage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: getMobileOS(),
    };

    this.props.coverageTypes.forEach((type) => {
      if (type.active) {
        this.state[type.alias] = true;
      }
    });
  }

  state = {};

  componentDidMount() {
    this.toggleAllLayers('add');
  }

  componentWillUnmount() {
    this.toggleAllLayers('remove');
  }

  onChange = (event) => {
    const { checked, name: type } = event.currentTarget;
    const shown = this.state[type];
    this.props.analyticsEvents?.onCheckboxClick?.(type, checked);

    if (shown) {
      this.context.mapController.removeCoverageLayer(type);
    } else {
      this.context.mapController.addCoverageLayer(type);
    }

    this.setState({ [type]: !shown });
  };

  toggleAllLayers = (action) => {
    this.props.coverageTypes.forEach(({ active, alias }) => {
      if (action === 'add') {
        if (active) {
          this.context.mapController.addCoverageLayer(alias);
        }
      } else {
        this.context.mapController.removeCoverageLayer(alias);
      }
    });
  };

  render() {
    const { className, coverageTypes } = this.props;

    return (
      <ControlsWrapper className={cx('coverage', className)} opened>
        <form className={cx('form')}>
          {coverageTypes.map(({ alias, color, name, tooltip }) => (
            <div className={cx('checkbox')} key={alias}>
              <Checkbox
                checked={this.state[alias] || false}
                label={name}
                name={alias}
                onChange={this.onChange}
                size="small"
              />
              <span className={cx('dot')} style={{ backgroundColor: color }} />

              {tooltip && (
                <Tooltip
                  className={cx('tooltip')}
                  contentPosition={this.state.isMobile && 'top'}
                  position={this.state.isMobile ? 'right' : 'center'}
                >
                  {tooltip}
                </Tooltip>
              )}
            </div>
          ))}
        </form>
      </ControlsWrapper>
    );
  }
}

Coverage.contextTypes = {
  mapController: PropTypes.shape({}),
};

Coverage.propTypes = {
  analyticsEvents: PropTypes.shape({
    onSegmentedControlClick: PropTypes.func,
    onCheckboxClick: PropTypes.func,
    onChoosePoint: PropTypes.func,
    onGeolocationButtonClick: PropTypes.func,
    onSearchInputFill: PropTypes.func,
    onSearchInputClick: PropTypes.func,
    onZoomButtonClick: PropTypes.func,
  }),
  className: PropTypes.string,
  coverageTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Coverage;
