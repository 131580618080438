import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Button, Link, Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ControlCard = ({ icon, title, text, buttonText, linkText, href, customButton, onClick }) => (
  <div className={cx('wrap')}>
    {icon && (
      <div className={cx('icon')}>
        <img alt="" src={icon} />
      </div>
    )}
    {title && (
      <div className={cx('title')}>
        <Text size="size4-r" tagType="h3">
          {title}
        </Text>
      </div>
    )}
    {text && (
      <div className={cx('text')}>
        <Text size="size6-r">{text}</Text>
      </div>
    )}
    {!customButton && buttonText && onClick && (
      <div className={cx('btn-wrap')}>
        <Button fullWidth onClick={onClick} size="m">
          {buttonText}
        </Button>
      </div>
    )}
    {customButton && <div className={cx('btn-wrap')}>{customButton}</div>}
    {linkText && href && (
      <div className={cx('btn-wrap')}>
        <Link buttonSize="m" buttonVariant="main" href={href} isFullWidth variant="button">
          {linkText}
        </Link>
      </div>
    )}
  </div>
);

ControlCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  href: PropTypes.string,
  linkText: PropTypes.string,

  /* отображение кастомной кнопки */
  customButton: PropTypes.func,

  onClick: PropTypes.func,
};

export default ControlCard;
