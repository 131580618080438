import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import TariffCard from 'pages-components/TariffCard';
import TariffCardAnimation from 'pages/ProductsCatalog/Tariffs/components/TariffCardAnimation';

// Hoc
import TariffCardParamsHoc from 'pages-components/TariffCard/hocs/TariffCardParamsHoc';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const OFFER_TYPES = ['service', 'discount', 'action', 'multi'];

class Offers extends Component {
  state = {
    activeOffer: 0,
    animate: false,
  };

  setAnimate = (isAnim) => this.setState({ animate: isAnim });

  setActiveOffer = (index) => this.setState({ activeOffer: index });

  getConstructorId = (offers) => (offers.items.find((o) => !!o.constructorId) || {}).constructorId;

  getActiveOffer = () => {
    const { activeOffer } = this.state;
    const {
      data: { offers },
    } = this.props;

    return offers[activeOffer];
  };

  isOneOfOfferType = (offerType) => OFFER_TYPES.some((item) => item === offerType);

  /** ВСЕ НАЧИНАЕТСЯ ИЗ ЭТОЙ ФУНКЦИИ */
  submitOffer = () => {
    const offer = this.getActiveOffer();
    const constructorId = this.getConstructorId(offer);

    // Alias необходим для кеширования модальных окон с карточкой тарифов;
    const alias = offer.items && offer.items[0].alias;

    this.props.onSubmit(offer.buttonUrl, {
      constructorId,
      offer,
      alias,
    });
  };

  rejectOffer = () => {
    const { buttonResetUrl } = this.getActiveOffer();

    this.props.onSubmit(buttonResetUrl, {
      state: 'refuse',
    });
  };

  /** Функция от крывает модальное окно с карточкой тарифа
   * по клику на плитку тарифа в модальном окне мультиоффера */
  openTariffCardPopup = (tariffCardObj) => {
    const offer = {
      offerType: 'tariff',
      buttonUrl: tariffCardObj.link,
    };

    this.props.onSubmit(null, {
      alias: tariffCardObj.alias,
      state: 'open',
      offer,
    });
  };

  submitServiceOffer = () => {
    const offer = this.getActiveOffer();

    // Необходимо проверить, мне пришли все акции или нет; Сценарий, когда все акции
    // предпологает моментальное подключение без модального окна конфликтов;
    const isActionTypeInMultiOffer = offer.items.every((item) => item.type === 'action');
    // ---------------------------------------------------------------------------------

    const constructorId = this.getConstructorId(offer);
    const canRetry = offer.offerType === 'action';
    const state =
      offer.offerType === 'action' || isActionTypeInMultiOffer ? 'activation' : 'landApproved';

    this.props.onSubmit(
      offer.buttonUrl,
      {
        constructorId,
        state,
        offer,
      },
      canRetry,
    );
  };

  renderTariffCard = (tariff) => {
    const offers = this.getActiveOffer();
    const TariffComponent = tariff.type !== 'tariff' ? TariffCard : TariffCardAnimation;

    if (offers.offerType === 'multi') {
      return (
        <TariffCardParamsHoc
          {...tariff}
          hideButton
          onButtonClick={() => this.openTariffCardPopup(tariff)}
          showBottomBubble={false}
          showTopBubble={false}
        >
          <TariffCard className={cx('tariffCard')} />
        </TariffCardParamsHoc>
      );
    }

    return (
      <TariffCardParamsHoc
        {...tariff}
        cardStyle={false}
        clickable
        offerType={offers.offerType}
        onButtonClick={this.submitServiceOffer}
        showBottomBubble={false}
        showTopBubble={false}
      >
        <TariffComponent className={cx('tariffCard')} />
      </TariffCardParamsHoc>
    );
  };

  renderButtonsGroup = () => {
    const {
      data: { offers },
    } = this.props;
    const { activeOffer } = this.state;

    const offer = this.getActiveOffer();
    const isShowOfferButton = this.isOneOfOfferType(offer.offerType);

    const buttonsGroupArray = [];

    // Если пришел offerType: 'service', 'discount', 'action', 'multi';
    if (isShowOfferButton) {
      buttonsGroupArray.push(
        <Button className="big" onClick={this.submitServiceOffer} transformer>
          {offer.buttonText}
        </Button>,
      );
    }

    if (offers.length > 1) {
      buttonsGroupArray.push(
        <Button
          className={['default', 'big']}
          onClick={() => {
            this.setAnimate(true);
            setTimeout(() => {
              this.setActiveOffer(activeOffer >= offers.length - 1 ? 0 : activeOffer + 1);
              this.setAnimate(false);
            }, 300);
          }}
          transformer
        >
          {offer.buttonMoreText}
        </Button>,
      );
    }

    return buttonsGroupArray.length ?
        <div className={cx('buttonsGroup')}>
          {buttonsGroupArray.map((item, key) => (
            <Fragment key={key}>{item}</Fragment>
          ))}
        </div>
      : null;
  };

  render() {
    const { animate } = this.state;
    const offer = this.getActiveOffer();

    return (
      <div className={cx('component', { animate })}>
        <Heading className={cx('heading')} level={1}>
          <span dangerouslySetInnerHTML={{ __html: offer.title }} />
        </Heading>
        {offer.description && <div dangerouslySetInnerHTML={{ __html: offer.description }} />}

        <div className={cx('tariffs')}>
          {offer.items.map((tariff, index) => (
            <Fragment key={index}>{this.renderTariffCard(tariff)}</Fragment>
          ))}
        </div>

        {this.renderButtonsGroup()}

        <Link className={cx('decline')} onClick={this.rejectOffer}>
          {offer.buttonResetText}
        </Link>
      </div>
    );
  }
}

Offers.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default Offers;
