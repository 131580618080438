import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { path } from 'ramda';
import qs from 'query-string';
import { Heading, Link, Tab, TabsList } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { pushGAeeBeeAllInOneForm } from 'utils/ga';
import Icon from 'pages-components/Icon';
import store from 'store';
import {
  successfullFTTBFormSubmissionMT,
  successfullFTTBFormSubmissionVK,
} from 'utils/analytics/pixelAnalytics';

import FormWrapper from './components/FormWrapper/FormWrapper';
import { SET_ALLINONE_VALUE } from './actions/actionTypes';
import styles from './AllInOneForm.pcss';
import StatusBlock from './components/StatusBlock/StatusBlock';

const cx = classNames.bind(styles);

class AllInOneForm extends PureComponent {
  state = {
    returnBackUrl: '',
  };

  componentDidMount() {
    const { activeTabIndex, setTabIndex, setData, data, setRegion } = this.props;

    if (data) {
      pushGAeeBeeAllInOneForm(data);
    }

    window.dataLayer.push({
      event: 'viewBasket',
      tariff: data.mobileTariffName,
      price: data.feePerMonth,
    });

    const params = qs.parse(window.location.search);
    if (params.returnUrl) {
      this.setState({ returnBackUrl: `${decodeURIComponent(params.returnUrl)}?connect=true` });
    }

    setRegion(data.globalRegionId, data.globalRegionTitle);
    setData(data);
    setTabIndex(activeTabIndex);
  }

  renderStatus = (requestData) => {
    const { texts } = this.props;
    if (requestData.IsSucceeded && requestData.View) {
      successfullFTTBFormSubmissionVK();
      successfullFTTBFormSubmissionMT();

      return (
        <StatusBlock
          content={requestData.View.Content}
          status="statusOk"
          title={requestData.View.Title}
        />
      );
    }

    return <StatusBlock content={texts.failStatusContent} status="statusOk" title="Супер" />;
  };

  render() {
    const { activeTabIndex, setTabIndex, requestData } = this.props;
    const { returnBackUrl } = this.state;

    if (path(['Errors', 'CreateRequestError'], requestData) || path(['IsSucceeded'], requestData)) {
      return this.renderStatus(requestData);
    }

    return (
      <div className={cx('layout')}>
        <Heading className={cx('h1')} level={1}>
          Заявка на подключение интернета
        </Heading>
        <div className={cx('mainBlock')}>
          {returnBackUrl && (
            <div className={cx('backLink')}>
              <Icon name="arrowLeft" />
              &nbsp;&nbsp;
              <Link className="hideVisitedColor" href={returnBackUrl}>
                Назад
              </Link>
            </div>
          )}
          <div className={cx('content')}>
            <TabsList className={cx('tabsList')} inline type="radio">
              {AllInOneForm.tabs.map((tab, index) => (
                <Tab
                  active={activeTabIndex === index}
                  className={cx('tab')}
                  key={index}
                  onClick={() => setTabIndex(index)}
                >
                  {tab.title}
                  <p
                    className={cx('minorText')}
                    dangerouslySetInnerHTML={{ __html: tab.describe }}
                  />
                </Tab>
              ))}
            </TabsList>
            <FormWrapper />
          </div>
        </div>
      </div>
    );
  }
}

AllInOneForm.tabs = [
  {
    title: 'У меня нет домашнего интернета от билайн',
    describe: 'Проверить адрес и оформить новое подключение',
  },
  {
    title: 'У меня есть домашний интернет от билайн',
    describe: 'Оформить подключение<br/>на существующий счет',
  },
];

AllInOneForm.defaultProps = {
  activeTabIndex: 0,
};

AllInOneForm.propTypes = {
  activeTabIndex: PropTypes.oneOf([0, 1]),
  setTabIndex: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  setRegion: PropTypes.func.isRequired,
  data: PropTypes.object,
  requestData: PropTypes.object,
  texts: PropTypes.object,
};

const mapStateToProps = ({ external }) => ({
  activeTabIndex: path(['allInOne', 'activeTabIndex'], external),
  requestData: path(['allInOne', 'requestData'], external),
  texts: path(['allInOne', 'serverData', 'texts'], external),
});

const mapDispatchToProps = (dispatch) => ({
  setTabIndex: (activeTabIndex) => {
    const tabServerData =
      activeTabIndex === 0 ?
        store.getState()?.external?.allInOne?.formRegionServerData
      : store.getState()?.external?.allInOne?.headerRegionServerData;

    dispatch({
      type: SET_ALLINONE_VALUE,
      payload: {
        activeTabIndex,
        requestData: {},
        serverData: tabServerData,
      },
    });
  },
  setData: (serverData) =>
    dispatch({
      type: SET_ALLINONE_VALUE,
      payload: {
        headerRegionServerData: serverData,
        formRegionServerData: serverData,
      },
    }),
  setRegion: (regionId, regionTitle) =>
    dispatch({
      type: SET_ALLINONE_VALUE,
      payload: {
        region: { id: regionId, label: regionTitle },
      },
    }),
});

const ConnectedAllInOneForm = connect(mapStateToProps, mapDispatchToProps)(AllInOneForm);

export default (props) => (
  <Provider store={store}>
    <ConnectedAllInOneForm {...props} />
  </Provider>
);
