import { debounce } from '@beef/utils';

import { pushYm } from '../../../tempAnalytics/metricUtils';

type TUnitType = 'gb' | 'min';

const presetTypes = {
  gb: 'net_package',
  min: 'call_package',
};

const blockMobileTypes = {
  gb: 'choose_net_package',
  min: 'choose_call_package',
};

/** Ф-я отправки параметров для кликов по табам Гб и Минут в яндекс метрику (c задержкой 3 сек) */
export const sendPresetYm = (value: number, type: TUnitType) => {
  const debounceSend = debounce(3000, () => {
    pushYm({
      [presetTypes[type]]: value,
      block_mobile: blockMobileTypes[type],
    });
  });
  debounceSend();
};

/** Ф-я отправки параметров для клика по блоку поссивок */
export const sendPassiveYm = (name: string, passive: string) => {
  pushYm({
    block_mobile: 'ability_click',
    ability_type: passive,
    upper_name: name,
  });
};
