import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Button, Emoji, Heading } from '@beef/legacy-ui-kit';
import { Provider, connect } from 'react-redux';
import { path } from 'ramda';

import PopupStateless from 'pages-components/Popup';

// -----------------------------
import { Loader } from 'pages-components/Loader/Loader';
// -----------------------------

import store from 'store';

import { closePopup, toggleErrorPopup } from './actions';
import styles from './ServiceStatusPopup.pcss';

const cx = classNames.bind(styles);

export class ServiceStatusPopup extends Component {
  renderConfirmation() {
    const { confirmationElement, confirmationSubmit, confirmationContent, serviceName } =
      this.props;
    const { heading, description, button, hasServiceName } = confirmationContent || {
      heading: 'Подключение услуги',
      description: `С баланса вашего номера будет сразу списана абонентская плата и плата за
                    подключение в соответствии с условиями тарифа или услуги.`,
      button: 'Подключить услугу',
      hasServiceName: true,
    };
    return (
      <div className={cx('content')}>
        {typeof confirmationElement !== 'undefined' ?
          confirmationElement
        : <div className={cx('contentWrapper')}>
            <Heading className={cx('header')} level={1}>
              {heading}
              <br />
              {hasServiceName ? `«${serviceName}»` : null}
            </Heading>
            <div className={cx('connectDescription')}>{description}</div>
            <div className={cx('buttons')}>
              <Button className="big" onClick={confirmationSubmit}>
                {button}
              </Button>
            </div>
          </div>
        }
      </div>
    );
  }

  renderSuccess() {
    const { successElement, serviceName, successSubmit, isDisconnection } = this.props;
    const reload = () => window?.location && window?.location.reload();

    return (
      <div className={cx('content')}>
        {typeof successElement !== 'undefined' ?
          successElement
        : <div className={cx('contentWrapper')}>
            <Emoji
              className={cx('headingSmile')}
              path="//static.beeline.ru/upload/images/emoji/smile-pont.svg"
            />
            <Heading level={1}>
              <span className={cx('headingText')}>Супер</span>
            </Heading>
            <div className={cx('text')}>
              {`Услуга «${serviceName}» ${isDisconnection ? 'отключена' : 'подключена'}.`}
            </div>
            <div className={cx('buttons')}>
              <Button className="big" onClick={successSubmit || reload}>
                Продолжить работу
              </Button>
            </div>
          </div>
        }
      </div>
    );
  }

  renderRequestSent() {
    const { sentElement, serviceName, successSubmit, isDisconnection } = this.props;
    const reload = () => window?.location && window?.location.reload();
    return (
      <div className={cx('content')}>
        {typeof sentElement !== 'undefined' ?
          sentElement
        : <div className={cx('contentWrapper')}>
            <Heading level={1}>
              <span className={cx('headingText')}>
                {`Заявка на ${
                  isDisconnection ? 'отключение' : 'подключение'
                } услуги «${serviceName}» отправлена.`}
              </span>
            </Heading>
            <div className={cx('buttons')}>
              <Button className="big" onClick={successSubmit || reload}>
                Продолжить работу
              </Button>
            </div>
          </div>
        }
      </div>
    );
  }

  renderError() {
    const { errorElement, onCloseError, errorContent, errorSubmit, errorPopup, onClose } =
      this.props;

    const { buttonText, titleIcon, content, title } = errorContent || {};

    const onClosePopup = errorPopup ? onCloseError : onClose;

    return (
      <div className={cx('content')}>
        {typeof errorElement !== 'undefined' ?
          errorElement
        : <div className={cx('contentWrapper')}>
            <Heading className={cx('header')} level={1}>
              <span className={cx('headingText')}>{title}</span>
              <Emoji className={cx('headingSmile')} path={titleIcon} />
            </Heading>
            <div className={cx('text')} dangerouslySetInnerHTML={{ __html: content }} />
            {buttonText && (
              <div className={cx('buttons')}>
                <Button className="big" onClick={errorSubmit || onClosePopup}>
                  {buttonText}
                </Button>
              </div>
            )}
          </div>
        }
      </div>
    );
  }

  render() {
    const {
      isServiceConnection,
      loaderClassName,
      onCloseError,
      errorStatus,
      errorPopup,
      onClose,
      status,
      opened,
    } = this.props;

    const isOpened = errorPopup ? errorPopup.opened : opened;
    const onClosePopup = errorPopup ? onCloseError : onClose;

    return (
      <PopupStateless onClose={onClosePopup} opened={isOpened}>
        <div className={cx('wrapper')}>
          <Loader
            isServiceConnection={isServiceConnection}
            loading={status === 'loading'}
            loadingClassName={loaderClassName}
          >
            <div className={cx({ loading: status === 'loading' })}>
              {status === 'confirmation' ? this.renderConfirmation() : null}
              {status === 'sent' ? this.renderRequestSent() : null}
              {status === 'success' ? this.renderSuccess() : null}
              {status === 'error' ? this.renderError() : null}
              {errorStatus && this.renderError()}
            </div>
          </Loader>
        </div>
      </PopupStateless>
    );
  }
}

ServiceStatusPopup.propTypes = {
  status: PropTypes.oneOf(['loading', 'success', 'error', 'confirmation']),
  confirmationElement: PropTypes.element,
  isServiceConnection: PropTypes.bool,
  confirmationSubmit: PropTypes.func,
  confirmationContent: PropTypes.object,
  loaderClassName: PropTypes.string,
  successElement: PropTypes.element,
  errorElement: PropTypes.element,
  isDisconnection: PropTypes.bool,
  errorContent: PropTypes.object,
  sentElement: PropTypes.element,
  serviceName: PropTypes.string,
  errorStatus: PropTypes.string,
  successSubmit: PropTypes.func,
  errorPopup: PropTypes.object,
  onCloseError: PropTypes.func,
  errorSubmit: PropTypes.func,
  onClose: PropTypes.func,
  opened: PropTypes.bool,
};

const mapStateToProps = ({ external }, ownProps) => {
  const field = 'serviceStatus';
  const status = path([field, 'data', ownProps.id, 'status'], external);
  const opened = path([field, 'popup', ownProps.id, 'opened'], external);
  const error = path([field, 'data', ownProps.id, 'error'], external);

  const errorPopup = path([field, 'popup', 'opened'], external);
  const errorStatus = path([field, 'popup', 'status'], external);

  return { opened, status, error, errorPopup, errorStatus };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose: () => dispatch(closePopup(ownProps.id)),
  onCloseError: () => dispatch(toggleErrorPopup(false, {})),
});

const ConnectedPopup = connect(mapStateToProps, mapDispatchToProps)(ServiceStatusPopup);

export default (props) => (
  <Provider store={store}>
    <ConnectedPopup {...props} />
  </Provider>
);
