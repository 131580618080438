import React from 'react';
import PropTypes from 'prop-types';

import { InjectCSS } from '../../../InjectCSS';
import { makeGridQueries } from './helpers';
import { gridOptionsShape } from './shapes';

export const GridPresets = {
  small: {
    minSize: 140,
    maxSize: 310,
    gutter: 16,
    edgeIndent: 20,
    minVisible: 0.1,
    maxVisible: 0.7,
  },
  medium: {
    minSize: 350,
    maxSize: 420,
    gutter: 16,
    edgeIndent: 20,
    minVisible: 0.04,
    maxVisible: 0.95,
  },
};
/**
 * Будет перемещено в ui-kit после завершения отладочных работ
 */
export const GridMediaQueryBuilder = ({ children, options, count, name = 'custom' }) => (
  <InjectCSS name={name} renderCSS={(id) => makeGridQueries(id, count, options).join('\n')}>
    {(id) => children(id)}
  </InjectCSS>
);

GridMediaQueryBuilder.propTypes = {
  name: PropTypes.string,
  children: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  options: PropTypes.shape(gridOptionsShape).isRequired,
};
