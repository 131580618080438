import React, { ReactNode, memo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TTariffContainer = {
  children: ReactNode;
  isShortPadding?: boolean;
};

/** Компонент-обертка над главной частью конструктора (Separation-контейнеры) */
export const TariffContainer = memo<TTariffContainer>(({ isShortPadding, children }) => (
  <section className={cx('wrapper', { 'wrapper--short-padding': isShortPadding })}>
    {children}
  </section>
));
