import { hasPartialOverlap } from 'utils/array';
import { nestedTemplateParser, templateParser } from 'utils/format-string';

import { TariffUpMetaData } from './store/TariffUpMetaData';
import { TariffUpModals } from './store/TariffUpModals';
import { TariffUpPowers } from './store/TariffUpPowers';
import {
  EDescriptionName,
  EPowerType,
  EYandexPopupType,
  EYandexResponseType,
  TBasePower,
  TCharactersTabsProps,
  TPartnerProductInfoResponse,
} from './types';
import { TState, getSelectYandexPopupProps } from './YandexModal/selectors';
import { TModalProps } from './YandexModal/types';

export type TInput = [
  Partial<TPartnerProductInfoResponse>,
  Partial<TCharactersTabsProps>,
  boolean | undefined,
];
type TOutput = Partial<{ activeColor: string; isYandex: boolean; powers: TBasePower[] }>;

const YANDEX_PARTIAL = 'яндекс';

export const selectYandexSubscription = (value: TInput) => {
  const [subscriptions] = value;

  // NOTE: Find Yandex subscription
  return subscriptions?.productList?.find(
    (v) => v.name?.toLowerCase?.().includes(YANDEX_PARTIAL) && (v.relatedTariffs || []).length > 0,
  );
};

export const selectDescriptionName = (value: TInput) => {
  // NOTE: Find Yandex subscription
  const yandexSubscription = selectYandexSubscription(value);

  if (!yandexSubscription) return '';

  const { isConnected, isSubscriptionStatus, hasTrial, yandexAssigned } = yandexSubscription;

  // NOTE: Depending on subscription parameters
  // we need to display correct popup
  return (
    [
      !isSubscriptionStatus && !isConnected && EDescriptionName.NotConnectedNotActivated,

      isSubscriptionStatus &&
        isConnected &&
        !yandexAssigned &&
        hasTrial &&
        EDescriptionName.ConnectedWithTrialNotActivated,

      isSubscriptionStatus &&
        isConnected &&
        !yandexAssigned &&
        !hasTrial &&
        EDescriptionName.ConnectedWithoutTrialNotActivated,

      isSubscriptionStatus &&
        !isConnected &&
        hasTrial &&
        EDescriptionName.DisconnectedButTrialInProgress,

      isSubscriptionStatus &&
        isConnected &&
        yandexAssigned &&
        !hasTrial &&
        EDescriptionName.ConnectedWithoutTrialActivated,

      isSubscriptionStatus &&
        isConnected &&
        yandexAssigned &&
        hasTrial &&
        EDescriptionName.ConnectedWithTrialActivated,

      isSubscriptionStatus &&
        !isConnected &&
        !hasTrial &&
        EDescriptionName.DisconnectedButSubscriptionInProgress,
    ].find(Boolean) || ''
  );
};

type TSelectYandexContentOutput = Exclude<Partial<TCharactersTabsProps['content']>, undefined>;
export const selectYandexContent = (value: TInput): TSelectYandexContentOutput => {
  const [_, props] = value;

  return props.content || {};
};

type TSelectProductPopupContent = Exclude<
  Partial<
    Record<
      EYandexResponseType,
      Exclude<TPartnerProductInfoResponse['popupContent'], undefined>[number]
    >
  >,
  undefined
>;
export const selectProductPopupContent = (value: TInput): TSelectProductPopupContent => {
  const [productInfo] = value;

  return (
    productInfo.popupContent?.reduce(
      (acc, item) => ({
        ...acc,
        [item.code as EYandexResponseType]: item,
      }),
      {} as TSelectProductPopupContent,
    ) || {}
  );
};

type TSelectYandexPopupsOutput = Exclude<
  Partial<TCharactersTabsProps['content']['yandexPopups']>,
  undefined
>;
export const selectYandexPopups = (value: TInput): TSelectYandexPopupsOutput =>
  selectYandexContent(value)?.yandexPopups || {};

type TSelectYandexDescriptionOutput = Exclude<
  Partial<TCharactersTabsProps['content']['yandexDescription']>,
  undefined
>;
export const selectYandexDescription = (value: TInput): TSelectYandexDescriptionOutput =>
  selectYandexContent(value)?.yandexDescription || {};

// NOTE: Если есть такая строчка, то неправильно пришел триал и нужно ее скрыть
const emptyTrialPartial = /(\s+)?за(\s+)?₽(\s+)?/g;

export const selectPowersProps = (value: TInput): TOutput => {
  const [, props, isAuthenticated] = value;

  const hasTariffConstructor = !!props.hasTariffConstructor;

  // NOTE: Find Yandex subscription
  const yandexSubscription = selectYandexSubscription(value);

  // NOTE: Depending on subscription parameters
  // we need to display correct popup
  const descriptionName = selectDescriptionName(value) as string;

  // NOTE: Prepare Yandex card content
  const content = props.content?.yandexDescription;
  const abilityIcon = content?.icon || undefined;
  const text = content?.[descriptionName]?.text || '';
  const description = nestedTemplateParser(text, yandexSubscription || {}).replaceAll(
    emptyTrialPartial,
    '',
  );
  const linkText =
    ([
      hasTariffConstructor &&
        isAuthenticated &&
        descriptionName === EDescriptionName.NotConnectedNotActivated &&
        content?.[EDescriptionName.DisconnectedButSubscriptionInProgress]?.btnText,
      content?.[descriptionName]?.btnText,
    ].find(Boolean) as string) || '';

  const transformedYandexSubscription: Partial<TBasePower> = {
    description,
    abilityIcon,
    linkText,
  };

  const { tabs, activeOptionSoc, isHighPreset } = props;

  const activeTabData = tabs?.find(
    (tab) =>
      tab.power?.optionSoc === activeOptionSoc || tab.highPower?.optionSoc === activeOptionSoc,
  );

  // NOTE: Determine if Yandex should be displayed
  const hasYandex = hasPartialOverlap(
    activeTabData?.relatedTariffs || [],
    yandexSubscription?.relatedTariffs || [],
  );

  const superPowerTemplate = props.content?.honeyTemplate || '{{text}}';

  const powerType = isHighPreset ? 'highPower' : 'power';

  // NOTE: Create a list of cards with powers
  const powers = [
    activeTabData?.[powerType] &&
      activeTabData[powerType].description && {
        ...activeTabData[powerType],
        id: EPowerType.Power,
      },
    hasYandex &&
      activeTabData &&
      transformedYandexSubscription.description && {
        ...activeTabData.power,
        ...transformedYandexSubscription,
        id: EPowerType.YandexPower,
      },
    activeTabData?.superPower &&
      activeTabData.superPower.description && {
        ...activeTabData.superPower,
        description: templateParser(superPowerTemplate, {
          text: activeTabData.superPower.description,
        }),
        id: EPowerType.SuperPower,
      },
  ].filter((v) => !!v) as TBasePower[];

  return {
    activeColor: activeTabData?.activeColor,
    powers,
    isYandex: [
      EDescriptionName.DisconnectedButTrialInProgress,
      EDescriptionName.ConnectedWithTrialNotActivated,
      EDescriptionName.ConnectedWithoutTrialNotActivated,
      EDescriptionName.ConnectedWithoutTrialActivated,
      EDescriptionName.ConnectedWithTrialActivated,
      EDescriptionName.DisconnectedButSubscriptionInProgress,
      isAuthenticated && hasTariffConstructor && EDescriptionName.NotConnectedNotActivated,
    ]
      .filter(Boolean)
      .includes(descriptionName as EDescriptionName),
  };
};

export const selectModalState = () => {
  const products = TariffUpMetaData.Products;
  const isAuthenticated = TariffUpMetaData.IsAuthenticated;
  const charactersTabsData = TariffUpPowers.CharactersTabsData;

  const _state = [products, charactersTabsData, isAuthenticated] as [
    typeof products,
    typeof charactersTabsData,
    boolean,
  ];

  const isLoading = TariffUpModals.IsLoading;
  const isOpen = TariffUpModals.IsOpen;

  const yandexPopups = selectYandexPopups(_state);
  const currentPopupType = TariffUpModals.YandexPopupType;
  const productPopupContent = selectProductPopupContent(_state);
  const yandexDescription = selectYandexDescription(_state);

  return {
    yandexPopups,
    currentPopupType,
    productPopupContent,
    endpoints: yandexDescription?.endpoint,
    isLoading,
    isOpen,
  };
};

export const selectLegacyModalState = () => {
  const { yandexPopups, currentPopupType, productPopupContent, endpoints, isLoading, isOpen } =
    selectModalState();

  return [
    yandexPopups,
    currentPopupType,
    {},
    productPopupContent,
    { endpoint: endpoints },
    isLoading,
    isOpen,
  ] as const;
};

export const selectModalProps = (popupType: EYandexPopupType): TModalProps => {
  if (!TariffUpModals.IsOpen) return {};

  const { yandexPopups, currentPopupType, productPopupContent, endpoints, isLoading, isOpen } =
    selectModalState();

  return getSelectYandexPopupProps(popupType)([
    yandexPopups,
    currentPopupType,
    {},
    productPopupContent,
    endpoints,
    isLoading,
    isOpen,
  ]);
};
