import React, { CSSProperties, FC, memo } from 'react';
import classNames from 'classnames/bind';

import { animatedIconsArray } from '../../../../constants';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Анимированные иконки на стартовом экране
 */
export const AnimatedIcons: FC = memo(() => {
  return (
    <>
      {animatedIconsArray.map(({ Icon, id, duration }) => (
        <div
          className={cx('icon')}
          key={id}
          // множитель для смещения иконки и длительность анимации
          style={{ '--offset-index': id, '--duration': duration } as CSSProperties}
        >
          <Icon />
        </div>
      ))}
    </>
  );
});
