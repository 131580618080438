import { FavoriteInput } from './FavoriteInput';
import { TailInput } from './TailInput';
import { SimilarInput } from './SimilarInput';
import { FILTER_SIMILAR, FILTER_TAIL } from '../../../constants';

export const getFilterComponent = (filter) => {
  switch (filter) {
    case FILTER_TAIL:
      return TailInput;
    case FILTER_SIMILAR:
      return SimilarInput;
    default:
      return FavoriteInput;
  }
};
