import React, { ReactNode, memo } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';

import style from './styles.pcss';

const cn = classNames.bind(style);

type BaseContentProps = {
  description?: ReactNode | string;
  img?: { alt: string; src: string };
  title?: ReactNode | string;
};

export const BaseContent = memo(({ img, title, description }: BaseContentProps) => (
  <>
    {img && (
      <div className={cn('img')}>
        <Picture {...img} />
      </div>
    )}
    {title && (
      <div className={cn('title')}>
        {React.isValidElement(title) ?
          title
        : <Text size="size2-m" tagType="h3">
            {title}
          </Text>
        }
      </div>
    )}

    {description && (
      <div className={cn('description')}>
        {React.isValidElement(description) ?
          description
        : <Text color="grey60" size="size4-r">
            {description}
          </Text>
        }
      </div>
    )}
  </>
));
