import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';
import { Preloader } from '@beef/legacy-ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SubscriptionStatus = ({ statusText, size }) => (
  <div className={cx('subscription-status')}>
    <Preloader className={cx('subscription-status__preloader')} isStyled={false} size={size} />
    {statusText && (
      <Text color="grey50" size="size5-r-s">
        {statusText}
      </Text>
    )}
  </div>
);

SubscriptionStatus.defaultProps = {
  size: '48',
};

SubscriptionStatus.propTypes = {
  statusText: PropTypes.string,
  size: PropTypes.string,
};

export { SubscriptionStatus };
