import React from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { Button } from '@beef/ui-kit';

import { Card } from 'pages/PromotionsPage/components/DiscountsBlock/components/Card';
import { useGetActions } from 'pages/ActionCategoryPage/hooks/useGetActions';
import { ResponseType, RootPageStore } from 'pages/ActionCategoryPage/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const _Actions = ({ data, pageInfo, alias, defaultImg }: ResponseType) => {
  const { actions, getMoreActions, isLast, isLoading } = useGetActions({ data, pageInfo, alias });
  return (
    <>
      <div className={cn('container')}>
        {actions.map((item) => (
          <Card {...item} alias={alias} className={cn('card')} defaultImg={defaultImg} isPage />
        ))}
      </div>
      {!isLast && (
        <div className={cn('button')}>
          <Button isLoading={isLoading} onClick={getMoreActions} size="l" variant="tertiary">
            Показать еще
          </Button>
        </div>
      )}
    </>
  );
};

export const Actions = connect(
  ({ content }: RootPageStore): ResponseType => ({
    data: content.data,
    alias: content.alias,
    defaultImg: content.defaultImg,
    pageInfo: content.pageInfo,
  }),
)(_Actions);
