import React, { memo, useLayoutEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Packages } from './components/Packages';
import { Options } from './components/Options';
import { OptionsShutDown } from './components/OptionsShutDown';
import { ChangeTariffBlock } from './components/ChangeTariffBlock';
import { Summary } from './components/Summary';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const TotalCheck = memo(
  ({
    content,
    options,
    packages,
    hasZTariff,
    chargeAmount,
    requestStatus,
    isChangeTariff,
    chargeAmountUnit,
    hasRemainingSize,
    commonTotalPrice,
    handleOrderModal,
    hasConnectedOptions,
    initialBalancePayment,
    isChangeOptionsTariff,
    isChangePackagesTariff,
  }) => {
    const [optionsShutDownShow, setOptionsShutDownShow] = useState(false);

    const isTariffCompleted = useMemo(() => requestStatus === 'COMPLETE', [requestStatus]);
    const btnText = useMemo(() => {
      if (isTariffCompleted) {
        return content.btnTextComplete;
      }
      return hasZTariff && isChangeTariff ? content.btnTextChange : content.btnText;
    }, [isChangeTariff, isTariffCompleted]);

    const showChangeTariffBlock = !!chargeAmount && !hasZTariff && isChangeTariff;
    const showPackages = isChangePackagesTariff || (hasZTariff && hasRemainingSize);
    const showOptions =
      isChangeOptionsTariff || (hasZTariff && hasConnectedOptions && !optionsShutDownShow);
    const showSummary =
      (isChangeOptionsTariff && commonTotalPrice) || isChangePackagesTariff || optionsShutDownShow;
    const showMinTotalInfo =
      (!!chargeAmount || initialBalancePayment) &&
      !isChangeTariff &&
      (isChangeOptionsTariff || isChangePackagesTariff) &&
      commonTotalPrice < initialBalancePayment;
    const showOptionsShutDown =
      ((isChangeOptionsTariff && optionsShutDownShow) || isChangePackagesTariff) &&
      hasZTariff &&
      isChangeTariff;

    useLayoutEffect(() => {
      setOptionsShutDownShow(isChangeOptionsTariff);
    }, [isChangePackagesTariff, isChangeOptionsTariff]);

    return (
      <div className={cx('wrapper')}>
        {showPackages && (
          <Packages hasZTariff={hasZTariff} packages={packages} title={content.titleMainPackage} />
        )}

        {showOptions && <Options options={options} title={content.titleOptions} />}

        {showOptionsShutDown && (
          <OptionsShutDown
            autoProlongTitle={content.titleAutoProlongInternet}
            options={options}
            optionsShutDownShow={optionsShutDownShow}
            packages={packages}
            setOptionsShutDownShow={setOptionsShutDownShow}
            shutdownOption
            title={content.titleDisabledOptions}
          />
        )}

        {showChangeTariffBlock && (
          <ChangeTariffBlock
            chargeAmount={chargeAmount}
            chargeAmountUnit={chargeAmountUnit}
            title={content?.titleChangeTariff}
          />
        )}

        {showSummary && (
          <Summary
            additionalCommonInfo={content?.additionalCommonInfo}
            additionalCommonInfoMinTotal={content?.additionalCommonInfoMinTotal}
            btnText={btnText}
            commonTotalPrice={commonTotalPrice}
            handleOrderModal={handleOrderModal}
            initialBalancePayment={initialBalancePayment}
            isChangeOptionsTariff={isChangeOptionsTariff}
            isChangePackagesTariff={isChangePackagesTariff}
            isTariffCompleted={isTariffCompleted}
            options={options}
            optionsShutDownShow={optionsShutDownShow}
            packages={packages}
            showMinTotalInfo={showMinTotalInfo}
            showOptionsShutDown={showOptionsShutDown}
            title={content.totalPriceText}
            unit={content.commonUnit}
          />
        )}
      </div>
    );
  },
);

TotalCheck.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.number,
      value: PropTypes.number,
      period: PropTypes.string,
      isShutdown: PropTypes.bool,
      isConnectedPeriod: PropTypes.bool,
    }),
  ),
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      unit: PropTypes.string,
      price: PropTypes.number,
      value: PropTypes.number,
      period: PropTypes.string,
      unitPrice: PropTypes.string,
      remainingSize: PropTypes.number,
    }),
  ),
  isChangeTariff: PropTypes.bool,
  chargeAmount: PropTypes.number,
  requestStatus: PropTypes.string,
  hasRemainingSize: PropTypes.bool,
  handleOrderModal: PropTypes.func,
  chargeAmountUnit: PropTypes.string,
  commonTotalPrice: PropTypes.number,
  hasConnectedOptions: PropTypes.bool,
  initialBalancePayment: PropTypes.number,
  isChangeOptionsTariff: PropTypes.bool,
  isChangePackagesTariff: PropTypes.bool,
  content: PropTypes.shape({
    btnText: PropTypes.string,
    commonUnit: PropTypes.string,
    titleOptions: PropTypes.string,
    btnTextChange: PropTypes.string,
    totalPriceText: PropTypes.string,
    btnTextComplete: PropTypes.string,
    titleMainPackage: PropTypes.string,
    titleChangeTariff: PropTypes.string,
    additionalCommonInfo: PropTypes.string,
    titleDisabledOptions: PropTypes.string,
    titleAutoProlongInternet: PropTypes.string,
    additionalCommonInfoMinTotal: PropTypes.string,
  }),
  hasZTariff: PropTypes.bool,
};
