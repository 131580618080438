// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const REQUEST_URL = '/VoWifiApi?soc=VOLTE3';

const useFetchRequest = () => {
  const [serviceData, setServiceData] = useState({});
  const [status, setStatus] = useState('loading');

  const {
    content = {},
    isConnected,
    redesignVoWiFiPageEnabled,
    vowifiFAQ,
    vowifiInstructions,
    support,
  } = serviceData;
  const { disconnectionData, connectionData } = content;

  useEffect(() => {
    setStatus('loading');
    axios
      .get(REQUEST_URL)
      .then((response) => {
        const { data } = response;
        setServiceData(data);
        setStatus('idle');
      })
      .catch(() => setStatus('error'));
  }, []);

  return {
    disconnectionData,
    connectionData,
    status,
    redesignVoWiFiPageEnabled,
    isConnected,
    landingData: {
      content,
      vowifiFAQ,
      vowifiInstructions,
      support,
      isConnected,
    },
  };
};

export { useFetchRequest };
