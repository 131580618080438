// TODO: заменить импорт после выноса PresetPicker в пакет!
// @ts-expect-error ошибочно НЕ импортируется ColorType из @beef/ui-kit
import { FontSizeType } from '@beef/ui-kit/types';

import { useAppSelector } from '../../../../store';
import { BASE_CONSTRUCTOR_TYPE, TARIFF_UP_TYPE } from '../../../../constants';
import { usePresetContentSelector } from '../selectors/usePresetContentSelector';

/** Хук подготовки данных для компонента PresetsPickers */
export const usePreparePresetsPickers = () => {
  const layoutType = useAppSelector((state) => state.common.layoutType);

  /* Получаем контентные данные для компонента TitleContent (заголовок блока) */
  const titleContent = usePresetContentSelector();

  /* Формируем общий объект пропов для компонента TitleContent (заголовок блока) */
  const titleContentProps = {
    ...titleContent,
    size: 'size2-m' as FontSizeType,
    extraSize: 'size5-r-s' as FontSizeType,
  };

  /* "showUpPassives" позволяет определить то, какой компонент в конструкторе нужно отобразить.
   * Если layoutType === TARIFF_UP_TYPE, показываем компонент PassiveContainer (новый тариф Up).
   * Если layoutType !== TARIFF_UP_TYPE, показываем компонент DevicesContainer (Интернет Live). */
  const showUpPassives = layoutType === TARIFF_UP_TYPE;

  /* Флаг отключает блок с PassiveContainer/DevicesContainer компонентами полностью.
   * На текущий момент времени, блок требуется скрывать для ОБЩЕГО тарифа би */
  const isDisabled = layoutType === BASE_CONSTRUCTOR_TYPE;

  return { titleContentProps, showUpPassives, isDisabled };
};
