import { useIsDesktopDimension } from '@carnica/utils/hooks';

import { useAppSelector } from '../../../../../store';
import { selectQRBlockContent } from '../../../../../store/slices/commonSlice/selectors';

/**
 * Хук для контента блока с переходом
 * в мобильное приложение
 */
export const useQRBlock = () => {
  const content = useAppSelector(selectQRBlockContent);
  const isDesktop = useIsDesktopDimension();

  return {
    ...content,
    isDesktop,
  };
};
