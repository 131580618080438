import React from 'react';
import classNames from 'classnames/bind';

import PresetPickerTile from './components/PresetPickerTile';
import { TPresetPicker } from './types';
import { usePreparePresetPicker } from './hooks/usePreparePresetPicker';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент пресета (Гб, Мин) */
export const PresetPicker = ({ activeKey, options, onChange }: TPresetPicker) => {
  const { ref, refContainer } = usePreparePresetPicker();

  return (
    <div className={cx('container')} ref={refContainer}>
      {options?.map((item) => {
        const isActive = item.value === activeKey;
        return (
          <PresetPickerTile
            {...item}
            isActive={isActive}
            key={item.soc}
            onClick={onChange}
            ref={isActive ? ref : null}
          />
        );
      })}
    </div>
  );
};
