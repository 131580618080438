import { useEffect, useState } from 'react';
import { shallowEqual } from '@beef/utils';

import { pushYm } from '../../../../tempAnalytics/metricUtils';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setIsPartnerFmcModalWasShown } from '../../../../store/slices/fttbSlice';
import type { TPartnerFmcPopup } from '../../../../store/slices/commonContentSlice/types';

/** Хук для выбора и активации таба с fmc-тарифом при его наличии,
 *  а также для показа модального окна */
export const useFmcTabPopupActivation = () => {
  const dispatch = useAppDispatch();
  /* Признак, определяющий, была ли модалка показана */
  const isPartnerFmcModalWasShown = useAppSelector(
    (state) => state.fttbPresets.isPartnerFmcModalWasShown,
  );

  /* Признак для определения выбора партнёрского тарифа шпд */
  const isPartnerFmcTariffSelected = useAppSelector(
    (state) => state.total.convergenceInfo.isPartnerFmcTariffSelected,
  );

  /* Контент для модалки партнёрского конвергента */
  const fmcModalData = useAppSelector(
    (state) => ({
      image: state.common.content.fmcSettings?.partnerFmcPopup?.image,
      title: state.common.content.fmcSettings?.partnerFmcPopup?.title,
      description: state.common.content.fmcSettings?.partnerFmcPopup?.description,
      buttonText: state.common.content.fmcSettings?.partnerFmcPopup?.buttonText,
    }),
    shallowEqual,
  ) as TPartnerFmcPopup;

  /* Состояние для показа модального окна партнёрского шпд */
  const [isOpen, setIsOpen] = useState(false);

  const handleShowPopup = () => {
    setIsOpen(!isOpen);

    if (isOpen) {
      /* Отправка аналитики в модальном окне "Тариф подрос" при клике "Понятно" */
      pushYm({
        popup_action: fmcModalData.buttonText,
        popup_type: 'fmc',
        popup_name: fmcModalData.title,
      });
    }
  };

  useEffect(() => {
    if (isPartnerFmcTariffSelected && !isPartnerFmcModalWasShown) {
      handleShowPopup();

      /* Отправка аналитики в модальном окне "Тариф подрос" */
      pushYm({
        popup_action: 'view',
        popup_type: 'fmc',
        popup_name: fmcModalData.title,
      });

      dispatch(setIsPartnerFmcModalWasShown(true));
    }
  }, [isPartnerFmcTariffSelected, isPartnerFmcModalWasShown]);

  return { isOpen, fmcModalData, handleShowPopup };
};
