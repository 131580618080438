import React, { FC } from 'react';

import { useStepNavigation } from '../hooks/useStepNavigation';
import { EConstructorStep } from '../store/slices/stepperSlice/types';
import {
  ConstructorBuilderPage,
  FTTBCallbackRequestPage,
  MnpPage,
  SettingsFttbAddressPage,
  SettingsFttbAndTvPage,
  SettingsSpecialistVisitPage,
  TariffChangesSettingsPage,
  ThanksFTTBPage,
  TotalBillPage,
} from '../pages';

/** Внутренний степпер-роутер конструктора */
export const ConstructorRouter: FC = () => {
  const { currentStep } = useStepNavigation();
  switch (currentStep) {
    case EConstructorStep.constructor:
      return <ConstructorBuilderPage />;
    case EConstructorStep.fttbAddress:
      return <SettingsFttbAddressPage />;
    case EConstructorStep.fttbSettings:
    case EConstructorStep.fttbConnectedSettings:
      return <SettingsFttbAndTvPage />;
    case EConstructorStep.specialistCall:
      return <SettingsSpecialistVisitPage />;
    case EConstructorStep.mnp:
      return <MnpPage />;
    case EConstructorStep.tariffConnectChange:
      return <TariffChangesSettingsPage />;
    case EConstructorStep.finalBill:
      return <TotalBillPage />;
    case EConstructorStep.finalPage:
      return <ThanksFTTBPage />;
    case EConstructorStep.fttbCallbackRequest:
      return <FTTBCallbackRequestPage />;
    default:
      return <ConstructorBuilderPage />;
  }
};
