import React from 'react';
import { PageFixer } from '@beef/smart-kit';
import { compose, withReduxStore } from '@beef/redux';
import { withDataConverter } from '@beef/react';

import { dataConverter } from './store/dataConverter';
import { DiscountsBlock, FAQ, InfoBanner, MainBanner, Title } from './components';
import { initializeStore } from './store';

const _PromotionsPage = () => (
  <PageFixer>
    <Title />
    <MainBanner />
    <DiscountsBlock />
    <InfoBanner />
    <FAQ />
  </PageFixer>
);

export const PromotionsPage = compose(
  withDataConverter(dataConverter),
  withReduxStore(initializeStore),
)(_PromotionsPage);
