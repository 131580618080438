import { formatOrderData, getCartRedirect, handleSuccessRequest } from '@beef/layout-kit/utils';

export const handleDevicePurchase = async ({
  cartApi,
  id,
  handleSetCartData,
  details,
  simOrderPlace = 'siteMain',
  basketIdExpiration,
  basketSource,
}) => {
  const data = {
    simOrderPlace,
    basketSource,
    devices: [
      {
        id,
        details,
        itemType: 'product',
      },
    ],
  };

  const requestData = formatOrderData(data);

  try {
    const res = await cartApi.post(`/${requestData.apiEndpoint}`, requestData.body);

    if (handleSetCartData) {
      handleSetCartData(res?.data);
    }

    handleSuccessRequest({
      cartId: res.data.id,
      items: res.data.items,
      redirectToCart: getCartRedirect(),
      basketIdExpiration,
    });
  } catch (error) {
    console.error(error);
  }
};
