import React, { FC } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { usePrepareTariffOptions } from './hooks/usePrepareTariffOptions';
import { TariffOptionsGroup } from './components/TariffOptionsGroup';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент выбора опций тарифа с несколькими группами опций */
export const TariffOptions: FC = () => {
  /* Подготовка данных для компонента TariffOptions */
  const { title, options, optionsRef } = usePrepareTariffOptions();
  /* Если заголовок блока (title) отсутствует, делаем заголовок группы в TariffOptionsGroup - большего размера.
   * Используется для общего конструктора */
  const isBigFontSize = !title ?? false;
  return (
    <section ref={optionsRef}>
      {title && <Text size="size2-m">{title}</Text>}
      <div className={cx('groups')}>
        {options.map((group, index) => (
          <TariffOptionsGroup
            description={group.description}
            index={index}
            isBigFontSize={isBigFontSize}
            key={index}
            options={group.optionsList}
            parentOptions={group.parentOptionsList}
            title={group.title}
          />
        ))}
      </div>
    </section>
  );
};
