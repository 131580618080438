import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { TOptionPickerTile } from '../../types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TOptionPickerIcon = Pick<TOptionPickerTile, 'activeIcon' | 'icon'> & { isActive?: boolean };

/** Компонент рендера иконки опции */
export const OptionPickerIcon: FC<TOptionPickerIcon> = ({ icon, activeIcon, isActive }) => (
  <div className={cx('wrapper')}>
    <img alt="" src={icon} />
    {/* Рендерим одновременно две иконки для того, чтобы не было мерцания при смене состояния (браузер загрузит
        альтернативную версию иконки сразу же) */}
    <img alt="" className={cx('active-icon', { _active: isActive })} src={activeIcon} />
  </div>
);
