import React, { useCallback, useEffect, useState } from 'react';
import { Provider, connect } from 'react-redux';
import { formatOrderData, handleSuccessRequest } from '@beef/layout-kit/utils';
import { Button, Input, Text, Tooltip } from '@beef/ui-kit';
import axios from 'axios';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from 'pages-components/Icon';
import { createMnpOrder } from 'pages/MnpOrderNew/api';
import store from 'store';
import { ymSimStartMnpProcess } from 'utils/analytics/simOrder';
import { pushEcommerceBuyMnp, pushEcommerceSuccessBuyMnp } from 'utils/analytics/simOrderAnalytics';
import { addToCartMNPSimCardYM } from 'utils/analytics/tariffsYandexMetrica';
import { vkPushEvent } from 'utils/analytics/vkEvents';
import { ymInitMnp, ymSimMNPAddToCart } from 'utils/analytics/ymCommonEvents';
import { isNumberBought } from 'utils/cartHelpers';
import { getOnlyDigitsPhone, isFilledPhone } from 'utils/format-string';

import { useYMForm } from './hook';
import {
  DEFAULT_CONSTRUCTOR_ID,
  errorTextContent,
  mnpVkEventGoal,
  statuses,
  validationUrl,
} from '../../constants';
import { getErrorStatus } from '../../utils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FormWithStore = ({
  analyticsData,
  boughtNumbers,
  buttonText,
  buttonVariant,
  cartApi,
  constructorTariffData,
  currentRegion,
  description,
  isMnpService,
  isVertical,
  personalPlanData,
  sendAnalytics,
  tariffId,
  userAgreement,
  title,
}) => {
  const [phone, setPhone] = useState('');
  const [isSentYM, setSentYM] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [status, setStatus] = useState('initial');
  const { handleClickInput, handleSendYMSubmit } = useYMForm(title);

  const onChange = useCallback(
    (e) => {
      if (isFilledPhone(e.target.value) && !isSentYM) {
        setSentYM(true);
      }

      setPhone(e.target.value);
    },
    [isSentYM],
  );

  const handleError = useCallback((errors) => {
    setErrorMessage(getErrorStatus(errors));
    setStatus(statuses.error);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      /** Перенести номер в билайне Клики на Начать переход */
      if (isFilledPhone(phone)) {
        ymInitMnp();
        addToCartMNPSimCardYM(document.location.href);
        handleSendYMSubmit();
      }

      pushEcommerceBuyMnp(tariffId);
      vkPushEvent(mnpVkEventGoal);

      if (!isFilledPhone(phone)) {
        setErrorMessage(errorTextContent.notFilledCtn);
      }

      if (status === statuses.notAllowSubmit) {
        return;
      }

      setStatus(statuses.pending);
      setErrorMessage('');
      ymSimStartMnpProcess();

      try {
        const onlyDigits = getOnlyDigitsPhone(phone);
        let id = tariffId;

        // Дефолтный объект с данными для корзины, на случай пустого пропа constructorTariffData
        const defaultConstructorTariffData = {
          // Значение захардкожено, так как соответствует дефолтному тарифу. Согласовано с аналитиком
          tariffConstructorId: DEFAULT_CONSTRUCTOR_ID,
          optionSocs: [],
        };

        if (isNumberBought(boughtNumbers, onlyDigits)) {
          handleError({ isCtnAlreadyInBasket: true });
          return;
        }

        if (!id || isMnpService) {
          const { data: createMnpData } = await createMnpOrder(onlyDigits, currentRegion);
          if (!createMnpData.IsSucceeded) {
            handleError();
            return;
          }

          if (createMnpData?.View?.IsExistingCtn) {
            handleError({ IsExistingCtn: true });
            return;
          }

          id = createMnpData?.View?.TariffId;

          // PEB-4891: в ответе createMnpOrder теперь присутствуют соки тарифа (и опций) для корректной работы корзины
          if (Array.isArray(createMnpData?.View?.Options)) {
            defaultConstructorTariffData.optionSocs = createMnpData?.View?.Options.join(',');
          }
        }

        const request = formatOrderData({
          constructorTariffData: constructorTariffData ?? defaultConstructorTariffData,
          mnp: onlyDigits,
          personalPlanData,
          simOrderPlace: 'mnp',
          tariffId: `${id}`,
        });

        const { data } = await cartApi.post(`/${request.apiEndpoint}`, request.body);

        if (data.id) {
          pushEcommerceSuccessBuyMnp(tariffId);
          ymSimMNPAddToCart();
          if (sendAnalytics) {
            sendAnalytics({ isConnect: true });
          }
          handleSuccessRequest({
            cartId: data.id,
            items: data.items,
            redirectToCart: true,
          });
        } else {
          handleError();
        }
      } catch (err) {
        handleError({ failedRequest: true });
      }
    },
    [phone, status, analyticsData, currentRegion, tariffId],
  );

  useEffect(() => {
    (async () => {
      const phoneFilled = isFilledPhone(phone);
      setErrorMessage('');

      if (!phoneFilled) {
        setStatus(statuses.notAllowSubmit);
        return;
      }

      try {
        setStatus(statuses.pending);
        const { data } = await axios.post(validationUrl, { phone: getOnlyDigitsPhone(phone) });

        const isExistingCtn = data.View?.IsExistingCtn;
        if (data.IsSucceeded && !isExistingCtn) {
          setStatus(statuses.notAllowSubmit);
        }
        if (!data.IsSucceeded) {
          handleError();
        }
        if (isExistingCtn && data.IsSucceeded) {
          handleError({ isExistingCtn: true });
          setStatus(statuses.notAllowSubmit);
          return;
        }
        setStatus(statuses.success);
      } catch {
        handleError({ failedRequest: true });
      }
    })();
  }, [phone]);

  return (
    <form className={cx('form')} onSubmit={handleSubmit}>
      {description && (
        <div className={cx('description')}>
          <Text color="grey80" size="size6-r">
            {description}
          </Text>
        </div>
      )}
      <div className={cx('form__handlers', { 'form__handlers--vertical': isVertical })}>
        <div className={cx('form__input')}>
          <Input
            errorMessage={errorMessage}
            maskSettings={{
              format: '+7 (###) ###-##-##',
              mask: '_',
            }}
            onChange={onChange}
            onClick={handleClickInput}
            placeholder="Номер для переноса"
            value={phone}
          />
        </div>
        <div className={cx('form__btn')}>
          <Button
            fullWidth
            isLoading={status === 'pending'}
            size="m"
            type="submit"
            variant={buttonVariant || 'main'}
          >
            {buttonText || 'Начать переход'}
          </Button>
        </div>
      </div>
      {userAgreement?.length > 0 && (
        <div>
          {userAgreement.map((item) => (
            <div className={cx('user-agreement')} key={item.text}>
              <Text color="grey50" size="size6-r">
                {item.text}
              </Text>
              <div className={cx('user-agreement__tooltip')}>
                {item.tooltip && (
                  <Tooltip message={item.tooltip} position="top">
                    <Icon name="info" />
                  </Tooltip>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </form>
  );
};

const ConnectedForm = connect(({ external }) => ({
  boughtNumbers: external?.cartData?.currentCart?.numbers ?? [],
  cartApi: external?.cartData?.cartApi,
  currentRegion: external?.regions?.currentRegion?.id,
}))(FormWithStore);

const Form = (props) => (
  <Provider store={store}>
    <ConnectedForm {...props} />
  </Provider>
);

FormWithStore.propTypes = {
  boughtNumbers: PropTypes.arrayOf(PropTypes.string),
  buttonText: PropTypes.string,
  buttonVariant: PropTypes.string,
  cartApi: PropTypes.func,
  currentRegion: PropTypes.string,
  data: PropTypes.shape({
    image: PropTypes.string,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.number,
        title: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
  }),
  isMnpService: PropTypes.bool,
  constructorTariffData: PropTypes.shape({
    optionSocs: PropTypes.string,
    partnersId: PropTypes.string,
    tariffConstructorId: PropTypes.number,
  }),
  tariffId: PropTypes.number,
  title: PropTypes.string,
};

export { Form };
