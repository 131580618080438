import { createSlice } from '@reduxjs/toolkit';

import { changeIsCompleteOfCheckCache } from './asyncThunks/changeIsCompleteOfCheckCache';
import { TCommonContent } from './types';

const initialState: TCommonContent = {
  baseUrl: '',
  content: {},
  layoutType: '',
  isElkSettings: false,
  userInfo: {
    activeCTN: '',
    isArchived: false,
    isConnected: false,
    isConvergentUser: false,
    isAuthenticated: false,
    convergenceConnectedInfo: {
      inacId: '',
      connectedAddress: '',
    },
  },
  userRegion: {
    regionId: 0,
    regionName: '',
    availableFmc: false,
    isPartnerFmcAvailable: false,
  },
  isCompleteOfCheckCache: false,
  latestTariffUpLink: {
    url: '',
    text: '',
  },
  defaultConstructor: {},
};

/** Общий контент тарифа Up */
const commonContentSlice = createSlice<TCommonContent>({
  name: 'fttbAddress',
  initialState,
  reducers: {
    setDefaultInacId: (state, action) => {
      state.defaultConstructor.defaultTariffData = {
        ...state.defaultConstructor.defaultTariffData,
        tariffInacId: action.payload,
      };
    },
    setDefaultFmcServices: (state, action) => {
      state.defaultConstructor.defaultTariffData = {
        ...state.defaultConstructor.defaultTariffData,
        servicesInacIdList: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeIsCompleteOfCheckCache.fulfilled, (state) => {
      state.isCompleteOfCheckCache = true;
    });
  },
});

export const { setDefaultInacId, setDefaultFmcServices } = commonContentSlice.actions;

export const commonContentReducer = commonContentSlice.reducer;
