import { Faq } from '@beef/smart-kit';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import DescriptionsHOC from 'pages/FTTB/StreetCardPage/hoc/DescriptionsHOC';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const DescriptionsWrapper = ({ categories, items }) => (
  <div className={cx('faq')} id="faq">
    <div className={cx('faq-wrapper')}>
      <Faq categories={categories} data={items} />
    </div>
  </div>
);

DescriptionsWrapper.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
          }),
        ),
      ),
    }),
  ),
};

export default (props) => (
  <DescriptionsHOC {...props}>
    <DescriptionsWrapper />
  </DescriptionsHOC>
);
