import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffsGroupIcon = ({ icon }) => (
  <Picture className={cx('icon')} mobileSrc={icon} src={icon} />
);

TariffsGroupIcon.propTypes = {
  icon: PropTypes.string,
};

export default TariffsGroupIcon;
