import { ReactElement, cloneElement } from 'react';

import { ArticlesAnalyticsPusher } from 'pages/SearchResultsPage/utils/analytics';
import { useOnScreenEffect } from 'utils/hooks/useOnScreenEffect';

type ArticleWithAnalyticsPropsType = {
  children: ReactElement;
};

export const ArticleWithAnalytics = ({ children }: ArticleWithAnalyticsPropsType) => {
  const nodeRef = useOnScreenEffect(() => {
    ArticlesAnalyticsPusher.pushViewEvent(children.props.title);
  }, []);

  const handleClick = (e: any) => {
    children.props.onClick?.(e);
    ArticlesAnalyticsPusher.pushClickEvent(children.props.title);
  };

  return cloneElement(children, { onClick: handleClick, nodeRef });
};
