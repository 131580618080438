import React, { FC } from 'react';
import classNames from 'classnames/bind';

// TODO: Не забыть про импорт после выноса в отдельный пакет!
import { ConstructorSwiper } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/ConstructorSwiper';

import { ServiceContainer } from '../../../../../ServiceTiles/components/ServiceContainer';
import { TitleContent } from '../../../../../../commonComponents/TitleContent';
import { usePrepareTvSubscriptions } from '../hooks/usePrepareTvSubscriptions';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент настройки TV для ШПД */
export const TvSubscriptionsContainer: FC = () => {
  const { titleContentProps, tvSubscriptionsServices } = usePrepareTvSubscriptions();
  return (
    <section className={cx('wrapper')}>
      <TitleContent {...titleContentProps} />
      <ConstructorSwiper>
        {tvSubscriptionsServices?.map((service) => (
          <ServiceContainer key={service.title} {...{ service }} />
        ))}
      </ConstructorSwiper>
    </section>
  );
};
