import { axiosBeelineRequest } from '@beef/utils';
import axios from 'axios';

import { getMockRequestHandlerWhenAllowed } from 'utils/mock';

import { EYandexResponseType } from '../types';

export const getYandexOAuthUrlRequest = async (oAuthEndpoint: string) => {
  const { yandexOAuthUrl } = await axiosBeelineRequest({
    originalResponse: false,
    url: oAuthEndpoint,
  });

  return yandexOAuthUrl as string;
};

const mockName = 'mock:partner-connect';

type TResponse = Promise<{ code?: EYandexResponseType; isSucceeded?: boolean }>;

export const postConnectYandexSubscriptionRequest = async ({
  url,
  id,
}: {
  id?: number;
  url: string;
}) => {
  const originalHandler = () =>
    axiosBeelineRequest<{ code?: EYandexResponseType }>({
      originalResponse: false,
      url,
      method: 'post',
      data: { id },
    }) as TResponse;

  const mockHandler = (_url: string) => axios({ url: _url }).then(({ data }) => data) as TResponse;

  return getMockRequestHandlerWhenAllowed<TResponse>({
    originalHandler,
    mockHandler,
    mockName,
  });
};
