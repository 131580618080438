import React, { ChangeEvent, FC } from 'react';
import classNames from 'classnames/bind';
import { Input } from '@beef/ui-kit';

import { FieldInputType } from 'pages/SPN/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const FieldInput: FC<FieldInputType> = ({
  onChange,
  onBlur,
  onFocus,
  value,
  errorMessage,
  helpMessage,
  soc,
  placeholder,
}) => (
  <div className={cn('input')}>
    <Input
      errorMessage={errorMessage}
      helpMessage={helpMessage}
      name="networkName"
      onBlur={onBlur}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value, soc)}
      onFocus={onFocus}
      placeholder={placeholder}
      testName="Input_own_networkName"
      type="text"
      value={value}
    />
  </div>
);
