import { Tooltip } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import React, { PropsWithChildren, useContext } from 'react';

import { ZeroBalanceSupportContext } from 'pages/Services/ZeroBalanceSupport/context';

import styles from './styles.pcss';
import { selectContent, selectData } from '../../selectors';

const cx = classNames.bind(styles);

export const ConnectButtonContainer: React.FC<PropsWithChildren<{ hasTooltip: boolean }>> = ({
  children,
  hasTooltip,
}) => {
  const context = useContext(ZeroBalanceSupportContext);
  const { banner } = selectContent(context);
  const { isConnected } = selectData(context);
  const message = isConnected ? banner?.tooltip?.disconnect : banner?.tooltip?.connect;

  const ComponentCompose = hasTooltip ? Tooltip : 'div';

  return (
    <div className={cx('container')}>
      <ComponentCompose
        labelPosition="center"
        maxWidth="225"
        message={message || ''}
        position="bottom"
      >
        {children}
      </ComponentCompose>
    </div>
  );
};
