import { useCountBillSum } from '../../components/commonComponents/Bill/hooks/useCountBillSum';
import { TDataLayer, pushDataLayer } from '../dataLayerUtils';
import { useAppSelector } from '../../store';

/** Подготавливает данные для отправки dataLayer (паект минут гб, totalPrice, soc)
 *  и отправляет метрику через pushDataLayer объеденяя с общими данными  */
export const usePrepareDataLayer = () => {
  const { gbValue, minValue, tariffSoc } = useAppSelector((state) => state.total) || {};

  /* Цена из чека */
  const { price } = useCountBillSum();

  /* Цена без пробелов типа 1 000 */
  const formattedPrice = price.replace(/\s/g, '');

  /* Ф-я собирает общие данные из стор для dataLayer */
  const commonDataLayerParams = {
    price: formattedPrice,
    id: tariffSoc,
    variant: `{${gbValue} Gb}_{${minValue} min}`,
  };

  /* Подготовка и отправка DataLayer */
  return (dataLayerType: TDataLayer) => {
    pushDataLayer(dataLayerType, commonDataLayerParams);
  };
};
