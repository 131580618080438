import classNames from 'classnames/bind';
import React from 'react';
import { ImageCard, InfoCard } from '@beef/smart-kit';

import { selectBenefitsData } from '../../../../store/selectors';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IBenefitProps {
  benefit: Partial<ReturnType<typeof selectBenefitsData>['benefits'][number]>;
  layoutType: ReturnType<typeof selectBenefitsData>['layoutType'];
}

// NOTE: Переписать как троичный оператор?
export const Benefit: React.FC<IBenefitProps> = ({ benefit, layoutType = 'default' }) => (
  <div className={cx('benefit', `benefit--${layoutType}`)}>
    {layoutType === 'default' && (
      <ImageCard
        bordered
        borderedSizes={['s']}
        hasFixedAspectRatio
        img={{
          src: benefit.logo,
          title: benefit.description || '',
        }}
        size="s"
        text={benefit.description || ''}
        title={<span className={cx('title')}>{benefit.name}</span>}
      />
    )}
    {['alt', 'multi'].includes(layoutType) && (
      <InfoCard
        bordered
        icon={benefit.logo || ''}
        text={benefit.description || ''}
        title={<span className={cx('title')}>{benefit.name}</span>}
      />
    )}
  </div>
);
