import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TTextsContainer = {
  description?: string;
  title?: string;
};

/** Компонент заголовка и описания для ModalHeader */
export const TextsContainer: FC<TTextsContainer> = ({ title, description }) => (
  <div className={cx('wrapper')}>
    {title && <Text size="size2-m">{title}</Text>}
    {description && (
      <Text color="grey60" size="size4-r">
        {description}
      </Text>
    )}
  </div>
);
