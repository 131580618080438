import React, { useContext } from 'react';
import { Link, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { useGetServiceNotification } from 'pages/Services/ScopeZeroBalanceServices/hooks/useGetServiceNotification';
import {
  SCREEN_ID_CONFIG,
  TRUST_PAYMENT_SOCS,
  URL_CONFIG,
} from 'pages/Services/ScopeZeroBalanceServices/constants';
import { TrustPaymentContext } from 'pages/Services/TrustPayment/context';
import { notificationYM } from 'utils/analytics/trustPaymentAnalytics';

import { TrustPaymentModuleButton } from '../TrustPaymentModuleButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ServiceNotificationWrapper = () => {
  const {
    content: { isAvailableToConnect },
    isAuthenticated,
  } = useContext(TrustPaymentContext);
  const status = useGetServiceNotification(
    SCREEN_ID_CONFIG.serviceDetail,
    TRUST_PAYMENT_SOCS,
    isAuthenticated,
  );
  const variant = status?.color === 'black' ? 'primary' : 'secondary';
  const textColor = status?.color === 'black' ? 'white' : 'grey95';

  React.useEffect(() => {
    if (status?.text) {
      const textButton = isAvailableToConnect ? 'Доверительный платёж' : 'Пополнить баланс';
      notificationYM(status.text, textButton, 'notification_view', URL_CONFIG.topUpAccountUrl);
    }
  }, [isAvailableToConnect, status?.text]);

  return status ?
      <div className={cx('notification-item-wrapper')}>
        <div className={cx('notification-item', { [`notification-item--${variant}`]: !!variant })}>
          <Text color={variant === 'secondary' ? 'grey95' : 'white'} size="size5-r-s">
            {status.text}
          </Text>
        </div>
        <div
          className={cx('notification-item__button', {
            [`notification-item__button--${variant}`]: !!variant,
          })}
        >
          {isAvailableToConnect ?
            <TrustPaymentModuleButton
              color={textColor}
              size="m"
              text="Доверительный платёж"
              variant="plain"
            />
          : <Link
              href={URL_CONFIG.topUpAccountUrl}
              isNoUnderline
              onClick={() =>
                notificationYM(
                  status.text,
                  'Пополнить баланс',
                  'notification_click',
                  URL_CONFIG.topUpAccountUrl,
                )
              }
            >
              <Text color={textColor}>Пополнить баланс</Text>
            </Link>
          }
        </div>
      </div>
    : null;
};
