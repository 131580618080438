import React from 'react';
import { ActionBar, DIMENSION_GROUPS, Modal, Text, useGetDimensionGroup } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { ISocialLinks, SocialLinks } from './SocialLinks';
import { Container } from '../../layout';
import { Button } from '../Button';
import { ClickableText, IClickableTextProps } from './ClickableText';
import styles from './styles.pcss';
import { usePopupState } from './usePopupState';

const cx = classNames.bind(styles);

export interface IFooterProps {
  analyticsEvents?: IAnalyticsEvents;
  className?: string;
  consentText: IClickableTextProps;
  copyright: string;
  popupContent: {
    buttonText: string;
    text: string;
    title: string;
  };
  socialsLinks: ISocialLinks['socialsLinks'];
}

export interface IAnalyticsEvents {
  onSocialLinkClick?: (name: string | undefined) => void;
}
export const Footer: React.FC<IFooterProps & IAnalyticsEvents> = ({
  consentText,
  copyright,
  popupContent,
  socialsLinks,
  analyticsEvents,
  className,
}) => {
  const isMobile = useGetDimensionGroup() === DIMENSION_GROUPS.mobile;
  const [open, togglePopupState] = usePopupState();

  return (
    <>
      <Modal
        actionBar={
          <ActionBar size="m">
            <Button onClick={togglePopupState}>{popupContent.buttonText}</Button>
          </ActionBar>
        }
        headerBarConfig={{
          headerText: popupContent.title,
        }}
        isOpen={open}
        onClose={togglePopupState}
        size="m"
      >
        <div className={cx('modal')}>
          <div className={cx('___modal__text')}>
            <Text size="size5-r-s">{popupContent.text}</Text>
          </div>
        </div>
      </Modal>

      <Container.Padding bottom={isMobile ? 16 : 28} horizontal={isMobile ? 20 : 24} top={0}>
        <footer className={cx('footer', className)} data-testid="carnica-footer">
          <Container.ColumnFlex gap={24}>
            <SocialLinks analyticsEvents={analyticsEvents} socialsLinks={socialsLinks} />
            <Container.FlexFactor>
              <ClickableText {...consentText} onClick={togglePopupState} />
            </Container.FlexFactor>
          </Container.ColumnFlex>

          <Container.ColumnFlex
            className={cx('flex-container')}
            // @ts-expect-error disable default prop value
            direction={null}
            gap={isMobile ? 0 : 24}
          >
            <Container.FlexFactor className={cx('ru-block')}>
              <img
                alt=""
                className={cx('flag-icon')}
                loading="lazy"
                src="//static.beeline.ru/upload/images/b2c/flags/5310_ru.png"
              />
              <Text size="size5-m">RU</Text>
            </Container.FlexFactor>
            <Container.Padding>
              <Text size="size7-r">
                <a className={cx('link')} href="/customers/site-map/">
                  Карта сайта
                </a>
              </Text>
              <Text size="size7-r">{copyright}</Text>
            </Container.Padding>
          </Container.ColumnFlex>
        </footer>
      </Container.Padding>
    </>
  );
};
