import React from 'react';

import { TitleContent } from '../../commonComponents/TitleContent';
import { FmcAddressInfo } from './components/FmcAddressInfo';
import { FmcAddressForm } from './components/FmcAddressForm';
import { useCheckConnection } from './hooks/useCheckConnection';
import { usePrepareCheckAddress } from './hooks/usePrepareCheckAddress';
import { useSetDefaultRegionFttbAddress } from './hooks/useSetDefaultRegionFttbAddress';

/** Контейнер формы проверки адреса подключения ШПД */
export const FmcContainer = () => {
  const { titleContentProps, hasError } = usePrepareCheckAddress();
  useSetDefaultRegionFttbAddress();
  useCheckConnection();

  return (
    <div>
      <TitleContent {...titleContentProps} />
      {hasError ?
        <FmcAddressInfo />
      : <FmcAddressForm />}
    </div>
  );
};
