import React, { ComponentProps, FC } from 'react';
import classNames from 'classnames/bind';

import { PreChangesButton } from '../PreChangesButton';
import { PreChangesInfoItem } from './components/PreChangesInfoItem';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TPreChangesInfo = {
  btnProps?: ComponentProps<typeof PreChangesButton>;
  changes?: ComponentProps<typeof PreChangesInfoItem>[];
};

/** Блок с информацией по изменениям тарифа */
export const PreChangesInfo: FC<TPreChangesInfo> = ({ changes, btnProps }) => (
  <div className={cx('wrapper')}>
    <ul className={cx('wrapper__list')}>
      {changes?.map((change, index) => <PreChangesInfoItem key={index} {...change} />)}
    </ul>
    <PreChangesButton {...btnProps} />
  </div>
);
