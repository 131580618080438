import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Description, Descriptions } from '@beef/legacy-ui-kit';

import styles from './styles.pcss';
import DocumentLink from '../../../ProductCard/components/DocumentLink';

const cx = classNames.bind(styles);

const Component = ({ data, additional }) => (
  <Descriptions
    additional={
      additional ?
        <div className={cx('documentLink')}>
          <DocumentLink {...additional} />
        </div>
      : null
    }
  >
    {data.map(({ title, content }, key) => (
      <Description key={key} title={title}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Description>
    ))}
  </Descriptions>
);

Component.propTypes = {
  additional: PropTypes.object,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.any,
    }),
  ),
};

export default Component;
