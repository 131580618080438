import React from 'react';
import PropTypes from 'prop-types';

// Components
import DefaultTariffConnectButton from 'pages/ProductCard/components/TariffConnectButton/DefaultTariffConnectButton';
import TariffConnectButton from 'pages/ProductCard/components/TariffConnectButton/TariffConnectButton';

/**
 * Кнопка подключения тарифа. Версия кнопки приходит с бэка;
 * ------------------------------------------------------------
 * @param connectButtonVersion - Версия кнопки для подключения;
 * @param connectButton - Объект кнопки подключения;
 * @param mnpPopupData - Обьект MNP модального окна;
 * @param isPreloader - Отображать прелоудер или нет;
 * @param newNumber - Красивый номер;
 * @param data - Данные карточки тарифа;
 * @param onlyConnectButton - Отвечает за отображение только кнопки подключения тарифа;
 * @param floatingButtonBehavior - Придает кнопке функционал! плавающей кнопки подключения тарифа
 * @param renderYoungConnected - Отображение кнопки настройки и покупки esim для подключенного тарифа
 */
const TariffConnect = ({
  connectButtonVersion,
  onlyConnectButton,
  connectButton,
  mnpPopupData,
  isPreloader,
  newNumber,
  data,
  warningData,
  warningDataCard,
  floatingButtonBehavior,
  renderYoungConnected,
}) => {
  const ButtonComponent =
    connectButtonVersion && connectButtonVersion > 1 ?
      TariffConnectButton
    : DefaultTariffConnectButton;

  return (
    <ButtonComponent
      connectButtonText={connectButton}
      data={data}
      floatingButtonBehavior={floatingButtonBehavior}
      includeNumber={!!newNumber}
      isPreloader={isPreloader}
      mnpPopupData={mnpPopupData}
      newNumber={newNumber}
      onlyConnectButton={onlyConnectButton}
      renderYoungConnected={renderYoungConnected}
      warningData={warningData}
      warningDataCard={warningDataCard}
    />
  );
};

TariffConnect.defaultProps = {
  onlyConnectButton: false,
  floatingButtonBehavior: false,
  renderYoungConnected: false,
};

TariffConnect.propTypes = {
  onlyConnectButton: PropTypes.bool,
  connectButtonVersion: PropTypes.string,
  connectButton: PropTypes.string,
  mnpPopupData: PropTypes.object,
  newNumber: PropTypes.string,
  isPreloader: PropTypes.bool,
  data: PropTypes.object,
  warningData: PropTypes.object,
  warningDataCard: PropTypes.object,
  floatingButtonBehavior: PropTypes.bool,
  renderYoungConnected: PropTypes.bool,
};

export default TariffConnect;
