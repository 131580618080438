import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import EmptyButton from 'pages-components/EmptyButton';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FoundPoint = ({ name, description, selected, onClick }) => (
  <EmptyButton className={cx('component', { selected })} onClick={onClick}>
    <div>
      <Text color="grey95" size="size5-r-s">
        {name}
      </Text>
    </div>
    <div>
      <Text color="grey50" size="size6-r">
        {description}
      </Text>
    </div>
  </EmptyButton>
);

FoundPoint.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FoundPoint;
