export const PROMOTIONS_PAGE = 'action_page_';

export const PROMOTIONS_PAGE_YM_STEP = {
  MAIN_BANNER_CLICK: 'main_banner_click',
  BOTTOM_BANNER_VIEW: 'bottom_banner_view',
  BOTTOM_BANNER_CLICK: 'bottom_banner_click',
  TAB_CLICK: 'tab_click',
  FAQ_VIEW: 'faq_view',
  FAQ_CLICK: 'faq_click',
};

export type YmPayloadType = {
  [key: string]: string;
};

export type YmCardsType = {
  block: string;
  isPage?: boolean;
  link: string;
  title: string;
};

export const PROMOTIONS_PAGE_YM_PARAMS = {
  MAIN_BANNER_CLICK: (payload: YmPayloadType) => ({
    [PROMOTIONS_PAGE]: {
      [PROMOTIONS_PAGE_YM_STEP.MAIN_BANNER_CLICK]: {
        [payload.title]: payload.link,
      },
    },
  }),
  BOTTOM_BANNER_VIEW: (payload: YmPayloadType) => ({
    [PROMOTIONS_PAGE]: {
      [PROMOTIONS_PAGE_YM_STEP.BOTTOM_BANNER_VIEW]: {
        [payload.title]: payload.link,
      },
    },
  }),
  BOTTOM_BANNER_CLICK: (payload: YmPayloadType) => ({
    [PROMOTIONS_PAGE]: {
      [PROMOTIONS_PAGE_YM_STEP.BOTTOM_BANNER_CLICK]: {
        [payload.title]: payload.link,
      },
    },
  }),
  TAB_CLICK: (payload: string) => ({
    [PROMOTIONS_PAGE]: {
      [PROMOTIONS_PAGE_YM_STEP.TAB_CLICK]: payload,
    },
  }),
  FAQ_VIEW: { [PROMOTIONS_PAGE]: PROMOTIONS_PAGE_YM_STEP.FAQ_VIEW },
  FAQ_CLICK: (payload: string) => ({
    [PROMOTIONS_PAGE]: {
      [PROMOTIONS_PAGE_YM_STEP.FAQ_CLICK]: payload,
    },
  }),
  PROMOTION_CLICK: (payload: YmCardsType) => ({
    [PROMOTIONS_PAGE]: {
      [`${payload.isPage ? 'page' : 'block'}_${payload.block}`]: {
        click: {
          [payload.title]: payload.link,
        },
      },
    },
  }),
  VIEW_ALL_CLICK: (payload: string) => ({
    [PROMOTIONS_PAGE]: {
      [`block_${payload}`]: 'view_all',
    },
  }),
};

export const wrapPromotionCard = ({ block, type, cards, isPage }: YmPayloadType) => ({
  [PROMOTIONS_PAGE]: { [`${isPage ? 'page' : 'block'}_${block}`]: { [type]: cards } },
});

export const mapPromotionCard = (card: any) => ({
  [card.title]: card.link,
});
