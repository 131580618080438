import React from 'react';
import { Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from '../../styles.pcss';

const cx = classNames.bind(styles);

export const RenderLoader = () => (
  <div className={cx('preloadWrapper')}>
    <Preloader size="100" />
  </div>
);
