import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Tooltip } from '@beef/legacy-ui-kit';

// Styles
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ServiceRegular = ({ value, description, valueTooltip, className }) => (
  <div className={cx('tiles', className)}>
    <div className={cx('box', !value && 'fullSize')}>
      <span className={cx('descriptionText')} dangerouslySetInnerHTML={{ __html: description }} />
    </div>
    {value && (
      <div className={cx('box')}>
        <div className={cx('value')}>
          <span className={cx('valueText')} dangerouslySetInnerHTML={{ __html: value }} />
          {valueTooltip && (
            <Tooltip className={cx('tooltip')}>
              <span dangerouslySetInnerHTML={{ __html: valueTooltip }} />
            </Tooltip>
          )}
        </div>
      </div>
    )}
  </div>
);

ServiceRegular.propTypes = {
  value: PropTypes.string,
  description: PropTypes.string,
  valueTooltip: PropTypes.string,
  className: PropTypes.string,
};

export default ServiceRegular;
