import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { ScrollContainer } from '@beef/legacy-ui-kit';

// helpers
import classNames from 'classnames/bind';

import { throttle } from 'utils/timed-functions';

// Styles
import SliderArrows from './components/SliderArrows';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MARGIN_LEFT = 25;
const UNIQUE_CLASS_NAME = 'scrollBlock';

class SwipedContainer extends Component {
  state = {};

  timestamp = null;

  componentDidMount() {
    const { uniqueName } = this.props;

    this.scrollContainer = document.querySelector(`.${uniqueName || UNIQUE_CLASS_NAME}`);

    if (this.scrollContainer) {
      this.scrollContainer.addEventListener('scroll', this.onScroll);
    }

    window.addEventListener('resize', this.resizeWindow);
    this.resizeWindow();
  }

  getElemBoundingRect = (elem) => {
    const { width, right, left } = elem.getBoundingClientRect();
    return { width, right, left };
  };

  /** Отрисовывает стрелки в зависимости от резмера экрана,
   * центрирует контент если он полностью вошел в экран */
  resizeWindow = () => {
    const { width, left } = this.getElemBoundingRect(this.swipeContainer);
    const currentInnerWindow =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    this.setState({
      leftArrow: left < 0,
      rightArrow: width > currentInnerWindow,
      isBoxInside: width < currentInnerWindow,
    });
  };

  /**
   * По клику на arrow, в зависимости от переданного type двигает слайдер;
   * @param buttonType - Кнопка-стрелка направления слайдера;
   */
  onArrowButtonClick = (buttonType) => {
    const { right: startRight } = this.getElemBoundingRect(this.swipeContainer);
    const bias = buttonType === 'rightArrow' ? startRight : MARGIN_LEFT;

    this.setState({
      leftArrow: buttonType !== 'leftArrow',
      rightArrow: buttonType !== 'rightArrow',
      arrowClick: true,
    });

    this.scrollContainer.scrollTo({
      left: bias,
      behavior: 'smooth',
    });

    const timer = setTimeout(() => {
      this.setState({ arrowClick: false });
      clearTimeout(timer);
    }, 500);
  };

  /** Функция скрывает Arrow в зависимости от расположений контента */
  onScroll = throttle((ev) => {
    if (this.timestamp !== ev.timestamp && !this.state.arrowClick) {
      const { right } = this.getElemBoundingRect(this.swipeContainer);
      const windowWidth = window.innerWidth;
      const leftArrow = right < windowWidth;
      const rightArrow = right > windowWidth;

      this.setState({ rightArrow, leftArrow });
    }
  }, 1000);

  render() {
    const { rightArrow, leftArrow, isBoxInside } = this.state;
    const { device, className, uniqueName, children } = this.props;

    return (
      <div className={cx('swipeCatalog')} id="swipeCatalog">
        <ScrollContainer
          className={cx(uniqueName || UNIQUE_CLASS_NAME)}
          handleShouldPlayDemo={() => false}
        >
          <div
            className={cx(className, 'swipeContainer', isBoxInside && 'center')}
            id="swipeContainer"
            ref={(swipeContainer) => {
              this.swipeContainer = swipeContainer;
            }}
          >
            {children}
          </div>
        </ScrollContainer>

        {device === 'desktop' && (
          <SliderArrows activeArrow={{ rightArrow, leftArrow }} onClick={this.onArrowButtonClick} />
        )}
      </div>
    );
  }
}

/**
 * @param tariffList - Список тарифов;
 * @param device - Тип устройства;
 * @param uniqueName - Уникальный класс;
 */
SwipedContainer.propTypes = {
  device: PropTypes.string,
  uniqueName: PropTypes.string,
  className: PropTypes.string,
};

export default SwipedContainer;
