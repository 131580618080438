import { useSelectActiveInacId } from '../../../../../FmcTabsContainer/selectors/useSelectActiveInacId';
import { useSelectFmcServicesTariffs } from '../../selectors/useSelectFmcServicesTariffs';
import { selectServiceForActiveTab } from '../../utils';

/** Хук подготовки пропов для компонента настройки ШПД-сервисов (FmcServiceSettings) */
export const usePrepareFmcServiceSettings = () => {
  /** Текущий активный inacId ШПД */
  const currentActiveInacId = useSelectActiveInacId() ?? '';

  /* Доступные тарифы ШПД для настройки */
  const servicesTariffs = useSelectFmcServicesTariffs();

  /* Доступные сервисы интернета по текущему активному табу (currentActiveInacId) */
  return selectServiceForActiveTab(servicesTariffs, currentActiveInacId)?.internetServices || [];
};
