import { createAsyncThunk } from '@reduxjs/toolkit';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { TFetchTotalBillRes, fetchTotalBill } from 'constructorV1/api/fetchTotalBill';

/** Тип параметров запроса fetchTotalBill результирующего чека ConstructorV1 */
type TFetchTotalBillReq = {
  constructorId: null | number;
  optionsSocs: string;
  signal: AbortSignal;
  soc: string;
};

/** Обертка запроса данных для результирующего чека ConstructorV1 */
export const getCommonBill = createAsyncThunk<Partial<TFetchTotalBillRes>, TFetchTotalBillReq>(
  'commonBillSlice/commonBill',
  async ({ signal, ...params }) => {
    try {
      return (await fetchTotalBill({ signal, params })).data;
      /* @ts-expect-error ошибка типизации error */
    } catch (error: Error) {
      throw new Error(error);
    }
  },
);
