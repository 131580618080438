import React, { FC } from 'react';
import classnames from 'classnames/bind';

import Shadow from '../assets/shadow.svg';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

type TPresetPickerShadow = { isScrolledToEnd: boolean };

/** Компонент - накладывает тень на пресеты гб или мин если есть скрол */
export const PresetPickerShadow: FC<TPresetPickerShadow> = ({ isScrolledToEnd }) => (
  <div className={cx('component', { 'component--scrolled': isScrolledToEnd })}>
    {/* в хроме не работает через <img /> (прозрачную картинку возвращает) поэтому затянул svg в проект */}
    <Shadow />
  </div>
);
