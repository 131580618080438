import React from 'react';
import { ChevronRightIcon, ChevronUpIcon } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type ChevronPropsType = {
  isDesktop?: boolean;
  isOpen?: boolean;
};

export const Chevron: React.FC<ChevronPropsType> = ({ isDesktop = false, isOpen }) => (
  <div className={cx('icon', !isOpen && isDesktop && 'icon--closed')}>
    {isDesktop ?
      <ChevronUpIcon size={20} />
    : <ChevronRightIcon size={20} />}
  </div>
);
