import { shallowEqual } from '@beef/utils';

import { useAppDispatch, useAppSelector } from '../../../../../../store';
import {
  setSelectedTime,
  setSelectedTimePeriods,
  setSelectingDate,
} from '../../../../../../store/slices/fttbSpecialistVisitSlice';

/** Хук для получения метода при клике на слот даты монтажника */
export const useHandleSelectingData = () => {
  const dispatch = useAppDispatch();

  const options = useAppSelector((state) => state.specialistVisit.availableSchedule);

  /** выбранная дата слота */
  const { dateLabel, selectedDate } = useAppSelector(
    (state) => ({
      dateLabel: state.common.content.fmcSettings?.calendarVisitPage.dateLabel,
      selectedDate: state.specialistVisit.selectedDate,
    }),
    shallowEqual,
  );

  /* метод клика по слоту даты */
  const onChange = (value?: string) => {
    const selectedPeriods = options?.find(({ id }) => id === value);
    // при выборе слота даты передаем в стор дату, временной период этой даты,
    // автоматически выбирается первый слот времени

    if (selectedPeriods) {
      dispatch(setSelectingDate(value));
      dispatch(setSelectedTimePeriods(selectedPeriods?.timePeriods));
      dispatch(setSelectedTime(selectedPeriods?.timePeriods[0]));
    }
  };

  return {
    dateLabel,
    onChange,
    options,
    selectedDate,
  };
};
