import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { templateParser } from 'utils/format-string';
import { getFeeWithAdditionalPackages } from 'pages/FTTB/utils/createOrderRequestHelper';
import { serviceTypes } from 'pages/FTTB/constants';
import Icon from 'pages-components/Icon';
import OldFee from 'pages/FTTB/components/OldFee';
import { getRoutersState, routersStates } from 'pages/FTTB/utils/wifiRoutersHelper';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const getTitleTextTemplate = (productType, data) =>
  productType === 2 ? data.bundleTitleText : data.tariffTitleText;

const getWifiRouterText = (isIncluded, selectedServices, excludedServices, data) => {
  const selectedRouters = selectedServices.filter((s) => s.type === serviceTypes.wifiRouter);
  const excludedRouters = excludedServices.filter((s) => s.type === serviceTypes.wifiRouter);
  const routersState = getRoutersState({ excludedRouters, selectedRouters, isIncluded });

  switch (routersState) {
    case routersStates.added:
      return data.selectedWifiRouter;
    case routersStates.excluded:
      return null;
    case routersStates.included:
      return data.wifiIncluded;
    default:
      return data.wifiNotIncluded;
  }
};

const renderAdditionalInfo = (tariff, data, additionalServices = [], excludedServices = []) => {
  const {
    params,
    channelCount,
    channelsLabel,
    wifiRouterIncluded,
    tvDeviceIncluded,
    mobileTariffIncluded,
    mobileTariffTitle,
  } = tariff;

  const {
    additionalTvPackageText,
    additionalServicesText,
    addedAntivirusText,
    tvUnitPostfix,
    mobileTariffInfoText,
  } = data;

  const hasTV = channelCount > 0;
  const hasAdditionalTvPackage = additionalServices.find(
    (service) => service.type === serviceTypes.tvPackage,
  );
  const hasAdditionalServices = additionalServices.find(
    (service) => service.type === serviceTypes.additionalService,
  );
  const hasAddedAntiviruses = additionalServices.find(
    (service) => service.type === serviceTypes.antivirus,
  );
  const speedParam = params[0];

  const speed = `${speedParam.paramValue} ${speedParam.unit}`;
  const tv = hasTV && `${channelCount} ${channelsLabel}${tvUnitPostfix}`;
  const wifi = getWifiRouterText(wifiRouterIncluded, additionalServices, excludedServices, data);
  const tvConsole = tvDeviceIncluded ? data.tvDeviceIncluded : data.tvDeviceNotIncluded;
  // eslint-disable-next-line max-len
  const mobileTariffAddedText =
    mobileTariffIncluded ?
      mobileTariffInfoText.replace('{{mobileTariffName}}', mobileTariffTitle)
    : '';

  return (
    <div className={cx('additionalInfo')}>
      <div>{speed}</div>
      <div>{tv}</div>
      {!!wifi && <div>{wifi}</div>}
      {hasTV && <div>{tvConsole}</div>}
      {hasAddedAntiviruses && <div>{addedAntivirusText}</div>}
      {hasAdditionalTvPackage && <div>{additionalTvPackageText}</div>}
      {hasAdditionalServices && <div>{additionalServicesText}</div>}
      {mobileTariffIncluded && <div>{mobileTariffAddedText}</div>}
    </div>
  );
};

const TariffInfo = (props) => {
  const [additionalInfoOpen, setAdditionalInfoState] = useState(false);
  const { tariffData, data, additionalServices, excludedServices } = props;

  const productType = !isEmpty(tariffData.productType) ? tariffData.ProductType : 0;
  const titleTextTemplate = getTitleTextTemplate(productType, data);

  const tariffTitle = templateParser(titleTextTemplate, { tariffName: tariffData.title });

  const toggleAdditionalInfoState = () => setAdditionalInfoState(!additionalInfoOpen);

  const tariffNameEl =
    tariffData.params.length ?
      <>
        <Link className={cx('tariffNameWrapper')} onClick={toggleAdditionalInfoState}>
          <span dangerouslySetInnerHTML={{ __html: tariffTitle }} />
        </Link>
        &nbsp;
        <Icon className={cx('arrowIcon', { opened: additionalInfoOpen })} name="dropdownArrow" />
      </>
    : <div className={cx('tariffNameWrapper')}>
        <span dangerouslySetInnerHTML={{ __html: tariffTitle }} />
      </div>;

  return (
    <div className={cx('wrapper')}>
      <div className={cx('inTotalText')}>
        {tariffNameEl}
        {additionalInfoOpen &&
          renderAdditionalInfo(tariffData, data, additionalServices, excludedServices)}
      </div>
      <div className={cx('inTotalPrice')}>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: getFeeWithAdditionalPackages(tariffData.fee, additionalServices),
            }}
          />
          <span dangerouslySetInnerHTML={{ __html: ` ${tariffData.feeUnit}` }} />
        </div>
        {tariffData.oldFee && !tariffData.oldFee.isEmpty && (
          <OldFee
            className={cx('oldFee')}
            discountClassName={cx('discount')}
            discountText={tariffData.discountText}
            unit={` ${data.oldFeeUnit}`}
            value={getFeeWithAdditionalPackages(tariffData.oldFee.value, additionalServices)}
          />
        )}
      </div>
    </div>
  );
};

TariffInfo.defaultProps = {
  additionalServices: [],
  excludedServices: [],
};
TariffInfo.propTypes = {
  tariffData: PropTypes.object,
  additionalServices: PropTypes.arrayOf(
    PropTypes.shape({
      fee: PropTypes.number,
      type: PropTypes.string,
    }),
  ),
  excludedServices: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ),
  data: PropTypes.shape({
    wifiIncluded: PropTypes.string,
    wifiNotIncluded: PropTypes.string,
    tvDeviceIncluded: PropTypes.string,
    tvDeviceNotIncluded: PropTypes.string,
    oldFeeUnit: PropTypes.string,
    bundleTitleText: PropTypes.string,
    tariffTitleText: PropTypes.string,
  }),
};

export default TariffInfo;
