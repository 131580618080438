import { EUserStoryType } from '../store/slices/stepperSlice/types';
import { TabTypes } from '../components/constructorPage/Tabs/constants';
import { DEFAULT_TAB_ID } from '../components/constructorPage/FmcTabsContainer/constants';

type TChoseUserType = {
  currentTariffTab?: string;
  isArchived?: boolean;
  isAuthenticated?: boolean;
  isConnected?: boolean;
  isConvergentUser?: boolean;
  isPartnerFmcTariffSelected?: boolean;
  tariffInacId?: string;
};

/** Функция позволяет определить текущий тип пользователя, в зависимости от входящих параметров.
 * Внимание! Функция НЕ возвращает тип общего пользователя (mnp) для упрощения логики.
 * При необходимости просто добавляйте условие dispatch(setUserStoryType(EUserStoryType.mnp)) */
export const choseUserType = ({
  isConnected,
  tariffInacId,
  isAuthenticated,
  currentTariffTab,
  isConvergentUser,
  isArchived = false,
  isPartnerFmcTariffSelected = false,
}: TChoseUserType) => {
  // TODO: Требуется добавить логику после уточнения ТЗ для сценариев:
  /** tariffUpRejectionOfEquipment - Отказ от оборудования ШПД.
   * tariffUpTransitionFromArchiveTariff - Переход из более ранних,
   * архивных версий конвергента (Близкие люди+) в новый UP.
   * tariffUpArchiveWithoutFTTB - Старый тариф ап, где недоступно ШПД */

  /* Если пользователь АВТОРИЗОВАН (АЗ), активируем сценарий изменением тарифа */
  if (isAuthenticated) {
    /* Если пользователь выбрал таб покупки НОВОЙ SIM (SimOrderForm) в АЗ,
     * запускаем отдельные сценарии для такого случая */
    if (currentTariffTab === TabTypes.SimOrderForm) {
      /* Если пользователь на архивном тарифе, запускаем архивный сценарий БЕЗ ШПД,
       т.к. подключить новые пресеты юзер не сможет */
      if (isArchived) {
        return EUserStoryType.tariffUpArchiveWithoutFTTB;
      }
      /* authNewSimNotConnectedWithFTTB - С добавлением ШПД или authNewSimNotConnectedWithoutFTTB - БЕЗ добавления ШПД) */
      return tariffInacId !== DEFAULT_TAB_ID ?
          EUserStoryType.authNewSimNotConnectedWithFTTB
        : EUserStoryType.authNewSimNotConnectedWithoutFTTB;
    }

    /* Если пользователь НЕ ПОДКЛЮЧЕН к текущему тарифу */
    if (!isConnected) {
      /* Если пользователь не подключен к тарифу "Близкие люди+" и т.п. и конвергент,
       * запускаем сценарий tariffUpTransitionFromArchiveTariff */
      if (isConvergentUser) {
        return EUserStoryType.tariffUpTransitionFromArchiveTariff;
      }
      /* Если пользователь выбрал доступный ШПД-пресет, запускаем сценарий authNotConnectedWithFTTB.
       * Если пользователь НЕ выбирал ШПД-пресет, запускаем сценарий authNotConnectedWithoutFTTB */
      return tariffInacId !== DEFAULT_TAB_ID ?
          EUserStoryType.authNotConnectedWithFTTB
        : EUserStoryType.authNotConnectedWithoutFTTB;
    }

    /* Если пользователь ПОДКЛЮЧЕН к текущему тарифу */
    if (isConnected) {
      /* Если пользователь конвергент (С ПОДКЛЮЧЕННЫМ ШПД) */
      if (isConvergentUser) {
        return EUserStoryType.authConnectedSettingsFTTB;
      }
      /* Если пользователь выбрал доступный ШПД-пресет, запускаем сценарий authConnectedWithFTTB.
       * Если пользователь НЕ выбирал ШПД-пресет, запускаем сценарий authConnectedWithoutFTTB */
      return tariffInacId !== DEFAULT_TAB_ID ?
          EUserStoryType.authConnectedWithFTTB
        : EUserStoryType.authConnectedWithoutFTTB;
    }
  }

  // TODO: Возможно потребуется добавить логику для АЗ, если партнерка будет как-то иначе работать!
  /* Если пользователю доступен партнерский конвергент (АЗ/НЗ) */
  if (isPartnerFmcTariffSelected) {
    return EUserStoryType.tariffUpWithPartnerFTTB;
  }

  /* Если пользователь НЕ авторизован (НЗ) */
  return tariffInacId !== DEFAULT_TAB_ID ?
      EUserStoryType.notAuthWithFTTB
    : EUserStoryType.notAuthWithoutFTTB;
};
