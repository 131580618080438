import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import axios from 'axios';
import { findIndex, pathOr, propEq } from 'ramda';
import qs from 'query-string';
import { axiosBeelineRequest } from '@beef/utils';

import { isObject } from 'utils/timed-functions';
import store from 'store';
import {
  orderSimMapErrorPopup,
  orderSimMapOpenPopup,
  orderSimMapSuccessPopup,
} from 'utils/analytics/orderSimMap';
import { formatPhone, unformatPhone } from 'utils/format-string';
import { fetchNumbers } from 'widgets/actions/funcyNumberOrder';
import Ctx from 'pages-components/Ctx';
import { slPurchase } from 'utils/analytics/sl';
import { ymSimMNPAddToCart } from 'utils/analytics/ymCommonEvents';

import { getModalData } from './mapper';

const randomInt = (min, max) => min + Math.floor((max - min) * Math.random());

class OrderPopupHoc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 'loading',
      showTariffs: false,
      currentIndex: 0,
      captcha: '',
      captchaKey: '',
      userNumber: null,
      captchaRequired: false,
      serverErrors: {},
      successPopupData: null,
      captchaStatus: null,
      tariffData: null,
    };
  }

  componentDidMount() {
    this.getOrderPopupData();
    this.props.fetchNumbers();
  }

  getOrderPopupData = () => {
    axios
      .get('/fancynumber/popup-for-map/', {
        params: {
          alias: this.props.alias,
        },
      })
      .then(({ data }) => {
        const modalData = getModalData(data);

        this.setState({
          tariffData: getModalData(data),
          step: 'form',
        });

        // Аналитика
        if (!modalData.tariffs[0]) {
          orderSimMapOpenPopup({
            soc: modalData.tariffs[0].soc,
            tariffName: modalData.tariffs[0].name,
          });
        }
      })
      .catch((error) => {
        this.setState({ step: 'error' });
        console.error('Get order popup data Error : ', error);
      });
  };

  makeOrder = () => {
    const { funcyOrderNumber } = this.props;
    const { userNumber, tariffData, currentIndex, captcha, captchaKey } = this.state;

    if (userNumber.length !== 16) return;

    const currentTariff = tariffData.tariffs[currentIndex];

    this.setState({ step: 'loading', serverErrors: { captcha: false } });

    const sendObject = {
      number: unformatPhone(funcyOrderNumber),
      contactNumber: unformatPhone(userNumber),
      tariffId: currentTariff.id,
      _captcha_key: captchaKey || 'fancynumber-order-form', // eslint-disable-line
      isMnp: false,
      orderPlace: 'mapsPageOrder',
      Captcha: captcha || undefined,
    };

    const headers = {
      ...(captcha && this.state.tariffData?.smartCaptchaEnabled ?
        { 'X-SmartCaptcha-Token': captcha }
      : {}),
    };

    axiosBeelineRequest({
      url: this.state.tariffData.simOrderUrl,
      withCredentials: true,
      headers,
      method: 'post',
      data: qs.stringify(sendObject),
    })
      .then((data) => {
        if (
          data?.IsCaptchaRequired &&
          !this.state.captchaRequired &&
          !this.state.tariffData?.smartCaptchaEnabled
        ) {
          return this.setState({ step: 'form', captchaRequired: true });
        }

        if (data?.IsCaptchaError) {
          return this.setState({
            step: 'form',
            serverErrors: { captcha: true },
            captcha: '',
          });
        }

        if (!data.isSucceeded) {
          return this.setState({ step: 'error' });
        }

        const { view } = data;

        const successPopupData = {
          title: view.title,
          icon: view.titleIcon,
          content: view.content.replace('{{contactNumber}}', userNumber),
          buttonText: view.buttonText,
        };

        // Аналитика
        orderSimMapSuccessPopup({
          orderId: view.orderId,
          tariffName: currentTariff.name,
          soc: currentTariff.soc,
        });
        slPurchase({
          orderId: view.orderId,
          price: view.startBalance,
          productId: view.tariffId,
        });
        ymSimMNPAddToCart();

        return this.setState({
          step: 'success',
          successPopupData,
        });
      })
      .catch((error) => {
        this.setState({ step: 'error' });
        console.error('Make order error : ', error);

        // Аналитика
        orderSimMapErrorPopup({
          errorMessage: error.message,
          tariffName: currentTariff.name,
          soc: currentTariff.soc,
        });
      });
  };

  setActiveTariffTab = (index) => {
    const { tariffs } = this.state.tariffData;
    const currentIndex = findIndex(propEq('id', index))(tariffs);

    this.setState({ currentIndex });
  };

  onChange = (value) => {
    this.setState({
      userNumber: value,
    });
  };

  onChangeCaptchaStatus = (value) => {
    this.setState({
      captchaStatus: value,
    });
  };

  toggleTariffsTabs = () =>
    this.setState((prevState) => ({
      showTariffs: !prevState.showTariffs,
    }));

  handleCaptchaChange = (captchaValue) => {
    this.setState({ captcha: captchaValue });
  };

  handleOldCaptchaChange = (captcha) => {
    const newCaptcha = isObject(captcha) ? captcha : { captcha };
    this.setState({ ...newCaptcha, serverErrors: {} });
  };

  render() {
    const {
      step,
      tariffData,
      showTariffs,
      captchaRequired,
      currentIndex,
      serverErrors,
      successPopupData,
      captchaStatus,
    } = this.state;

    const startBalancePrice =
      (tariffData || {}).tariffs && tariffData.tariffs[currentIndex].startBalance;
    const currentTariff = (tariffData || {}).tariffs && tariffData.tariffs[currentIndex];
    const tariffDetails =
      currentTariff && `Тариф: &laquo;${currentTariff.name}&raquo;. ${currentTariff.description}`;

    const captchaUrl = tariffData?.captchaUrl;
    const smartCaptchaEnabled = tariffData?.smartCaptchaEnabled;

    return cloneElement(this.props.children, {
      setCurrentTariffIndex: this.setCurrentTariffIndex,
      toggleTariffsTabs: this.toggleTariffsTabs,
      setActiveTariffTab: this.setActiveTariffTab,
      handleCaptchaChange: this.handleCaptchaChange,
      onInputChange: this.onChange,
      makeOrder: this.makeOrder,
      onChangeCaptchaStatus: this.onChangeCaptchaStatus,
      handleOldCaptchaChange: this.handleOldCaptchaChange,
      tariffDetails,
      ...tariffData,
      startBalancePrice,
      captchaStatus,
      smartCaptchaEnabled,
      serverErrors,
      captchaRequired,
      captchaUrl,
      tariffs: (tariffData || {}).tariffs,
      showTariffs,
      activeStep: step,
      errorPopupData: {
        icon: Ctx.defaultError.icon,
        title: Ctx.defaultError.title,
        content: Ctx.defaultError.content,
        buttonText: Ctx.defaultError.buttonText,
      },
      successPopupData,
    });
  }
}

OrderPopupHoc.propTypes = {
  children: PropTypes.shape({}),
  funcyOrderNumber: PropTypes.string,
  fetchNumbers: PropTypes.func,
};

const mapStateToProps = ({ external }) => {
  const funcyNumbers = pathOr([], ['funcyNumberOrder', 'numbers'], external);
  let orderNumber = null;

  if (funcyNumbers.length) {
    const spetsialnyie = funcyNumbers.filter((item) => item.alias === 'spetsialnyie')[0];
    const randomNumber = randomInt(0, spetsialnyie.numbers.length - 1);
    orderNumber = spetsialnyie.numbers[randomNumber];
  }

  return {
    funcyOrderNumber: orderNumber && formatPhone(orderNumber.value),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNumbers: () => dispatch(fetchNumbers()),
});

const PopupOrderConnected = connect(mapStateToProps, mapDispatchToProps)(OrderPopupHoc);

export default (props) => (
  <Provider store={store}>
    <PopupOrderConnected {...props} />
  </Provider>
);
