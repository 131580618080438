/**
 * @desc: считает разницу между старой ценой и новой ценой
 * @param oldPrice
 * @param priceWithDiscount
 * @returns {null|number}
 */
const getTariffDiscountValue = (oldPrice, priceWithDiscount) => {
  if (!oldPrice || !priceWithDiscount) {
    return null;
  }

  return parseInt(oldPrice, 10) - parseInt(priceWithDiscount, 10);
};

export default getTariffDiscountValue;
