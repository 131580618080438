import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { TotalBillTitle } from '../TotalBillTitle';
import { TotalBillInfo } from './components/TotalBillInfo';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Содержит компонент заголовка с описанием и total чек */
export const TotalBillContainer: FC = () => (
  <section className={cx('wrapper')}>
    <TotalBillTitle />
    <TotalBillInfo />
  </section>
);
