import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { TopContainer } from './components/TopContainer';
import { THeader } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Отрисовка шапки в плитке TariffInfoTile */
export const Header: FC<THeader> = ({ description, ...restProps }) => (
  <div className={cx('wrapper')}>
    <TopContainer {...restProps} />
    {description && (
      <Text color="grey60" size="size5-r-s">
        {description}
      </Text>
    )}
  </div>
);
