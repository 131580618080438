import { useEffect } from 'react';

import { getUrlParam } from '../../../../utils/getUrlParam';
import { MAIN_POPUP_KEY, SETTINGS_TARIFF } from '../../../../constants';
import { useSelectBaseUrl } from '../../Tabs/selectors/useSelectBaseUrl';
import { EConstructorStep } from '../../../../store/slices/stepperSlice/types';
import { useSelectCurrentStep } from '../../Tabs/selectors/useSelectCurrentStep';

type TUseOpenTariffPopup = {
  handleClick: (key: string) => void;
  /* Является ли текущий открытый попап главным попапом (попап выбора персонажа тарифа) */
  isMainKey: boolean;
  /* В зависимости от значения key (string), покажется тот или иной вариант попапа. При null попап скрыт */
  isOpen: null | string;
};

/** Автоматическое открытие попапа выбора персонажа */
export const useOpenTariffPopup = ({ isOpen, isMainKey, handleClick }: TUseOpenTariffPopup) => {
  /* Базовый url тарифа */
  const baseUrl = useSelectBaseUrl();

  /* Текущий шаг настройки тарифа */
  const currentStep = useSelectCurrentStep();

  useEffect(() => {
    /* Проверяем, является ли текущий шаг настройкой конструктора */
    const isConstructorSettingsStep = currentStep === EConstructorStep.constructor;
    /* Проверяем, имеется ли в query-параметре SETTINGS_TARIFF */
    const isShowSettingsPopup = JSON.parse(getUrlParam(SETTINGS_TARIFF) || false);

    /* Если в url присутствует (isShowSettingsPopup) query-параметр (SETTINGS_TARIFF), то
     * поднимаем главный попап тарифа с выбором персонажа */
    if (isShowSettingsPopup && isConstructorSettingsStep) {
      handleClick(MAIN_POPUP_KEY);
    }

    /* Удаляем query-параметр при закрытии попапа, если это главный попап (isMainKey)
     * и query-параметр содержал (SETTINGS_TARIFF) */
    return () => {
      if (isMainKey && isShowSettingsPopup) {
        window.history.replaceState({}, '', baseUrl);
      }
    };
  }, [isOpen]);
};
