import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ProductTile } from '@beef/legacy-ui-kit';

import { withBeelineStore } from 'store-enhancers/withBeelineStore';
import ProductsFamilyTile from 'pages-components/ProductsFamilyTile/ProductsFamilyTile';
import { ymPushParams } from 'utils/ym';

import ServiceTile from './ServiceTile';

const _ServicesGrid = ({ services, className, id, onClick }) => (
  <div className={className} id={id}>
    {services.map((service, index) => (
      <Fragment key={index}>
        {service.type === 'family' && (
          <>
            {service?.showBenefits ?
              <ProductsFamilyTile
                data={service}
                itemType="service"
                key={`familyTile-${index}`}
                onClick={(link, serviceId) => onClick && onClick(link, serviceId)}
              />
            : service.services.length > 1 ?
              <ProductTile
                description={service.description}
                family={service.services}
                forArchiveTariff={service.forArchiveTariff}
                itemType="service"
                key={`productTile-${index}`}
                onClick={(link, serviceId) => onClick && onClick(link, serviceId)}
                title={service.familyTitle}
              />
            : <ServiceTile
                key={`serviceTile-${index}`}
                onClick={(link, serviceId) => onClick && onClick(link, serviceId)}
                service={{
                  ...service.services[0],
                  hideSubscribeButton: service.hideSubscribeButton,
                }}
              />
            }
          </>
        )}
        {service.type === 'service' && (
          <ServiceTile
            key={`serviceTile-F${index}`}
            onClick={(link, serviceId) => {
              if (service.alias === 'vowifi') {
                ymPushParams({ vowifi: 'more_info' });
              }
              if (onClick) {
                onClick(link, serviceId);
              }
            }}
            service={service}
          />
        )}
      </Fragment>
    ))}
  </div>
);

_ServicesGrid.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string,
  onClick: PropTypes.func,
};

export default withBeelineStore(_ServicesGrid);
