import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Status } from '@beef/legacy-ui-kit';
import { Button, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';
import PopupStateless from 'pages-components/Popup';
import commonStyles from 'pages/ProductCard/components/ServicesExtension/SharingService/SharingService.pcss';

const cx = classNames.bind(commonStyles);

class ErrorPopup extends Component {
  handleClick = () => {
    const { onClose, onButtonClick } = this.props;

    if (onButtonClick) {
      onButtonClick();
    } else {
      onClose();
    }
  };

  render() {
    const { opened, onClose, errorEmoji, title, description, errorContent, buttonText } =
      this.props;

    return (
      <PopupStateless onClose={onClose} opened={opened}>
        <div className={cx('content')}>
          {errorEmoji && <Icon className={cx('errorEmoji')} name={errorEmoji} />}
          <Text size="size1-m" tagType="h1">
            {title}
          </Text>
          <div className={cx('description')}>
            <Text color="grey90" size="size5-r-s">
              {description}
            </Text>
            {errorContent && (
              <Status className={cx('errorContent')} emoji="statusFail">
                <div dangerouslySetInnerHTML={{ __html: errorContent }} />
              </Status>
            )}
          </div>
          <div className={cx('buttonBottom')}>
            <Button fullWidth onClick={this.handleClick} size="m" variant="main">
              {buttonText}
            </Button>
          </div>
        </div>
      </PopupStateless>
    );
  }
}

ErrorPopup.defaultProps = {
  title: 'Упс, что-то пошло не так',
  description:
    'При отправке запроса на сервер произошла ошибка,<br />наши специалисты уже работают над этим!',
  buttonText: 'Вернуться на сайт',
};

ErrorPopup.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  errorContent: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  errorEmoji: PropTypes.string,
};

export default ErrorPopup;
