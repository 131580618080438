import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const OptionInfo = memo(({ title, benefit }) => (
  <div className={cx('wrapper')}>
    {title && (
      <Text color="grey95" size="size4-r" tagType="div">
        {title}
      </Text>
    )}
    {benefit && (
      <div className={cx('wrapper__description')}>
        <Text color="grey50" size="size5-r-s" tagType="div">
          {benefit}
        </Text>
      </div>
    )}
  </div>
));

OptionInfo.propTypes = {
  title: PropTypes.string,
  benefit: PropTypes.string,
};
