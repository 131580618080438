import React from 'react';
import { connect } from 'react-redux';
import { ActionBar, Button, Modal, Text } from '@beef/ui-kit';

import { selectContent } from '../../../store/selectors';
import {
  resetCurrentStory,
  selectCurrentFinalStory,
  selectFinalVoteLoading,
  selectIsVoteAvailable,
  submitVote,
} from '../../../store/finalStories';

const FinalStoryModalLayout = ({ title, text, submitText, onSubmit, onClose, isLoading }) => (
  <Modal
    actionBar={
      submitText && (
        <ActionBar isWithText size="s">
          <Button isLoading={isLoading} onClick={onSubmit}>
            {submitText}
          </Button>
        </ActionBar>
      )
    }
    headerBarConfig={{ headerText: title }}
    isOpen
    onClose={onClose}
    size="m"
  >
    <Text color="grey60" size="size4-r">
      {text}
    </Text>
  </Modal>
);

const mapStateToProps = (state) => {
  const story = selectCurrentFinalStory(state);
  const { storyModal } = selectContent(state);
  return {
    title: story.name,
    text: story.text,
    submitText: selectIsVoteAvailable(state) ? storyModal.buttonText : null,
    isLoading: selectFinalVoteLoading(state),
  };
};

const mapDispatchToProps = {
  onClose: resetCurrentStory,
  onSubmit: submitVote,
};

export const FinalStoryModal = connect(mapStateToProps, mapDispatchToProps)(FinalStoryModalLayout);
