import { useEffect, useRef } from 'react';
import { shallowEqual } from '@beef/utils';

import { getDefaultCharacter } from '../../TariffInfoTiles/utils';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setOptions } from '../../../../store/slices/optionsSlice';
import { setCharacterSoc } from '../../../../store/slices/totalSlice';
import { defineCharacterSoc } from '../../../../utils/defineCharacterSoc';
import { useThrowAsyncError } from '../../../../hooks/useThrowAsyncError';
import { setExtraServices } from '../../../../store/slices/tariffServicesSlice';
import { TFetchTariffUpPresetQueryRes } from '../../../../api/tariffupPreset/types';
import { fetchTariffUpPreset } from '../../../../api/tariffupPreset/fetchTariffUpPreset';
import { prepareOptionsForPassiveAbility } from '../../../../utils/prepareOptionsForPassiveAbility';

/** Отправки запроса для получения данных по пресету */
export const useHandlePresetChanges = () => {
  const isInitialMount = useRef(true);
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();

  /* Получаем текущие данные из общего объекта total */
  const { gbValue, minValue, tariffSoc, alias, isConnected } = useAppSelector(
    (state) => ({
      alias: state.total.alias,
      gbValue: state.total.gbValue,
      minValue: state.total.minValue,
      tariffSoc: state.total.tariffSoc,
      characterSoc: state.total.characterSoc,
      isConnected: state.common.userInfo.isConnected,
    }),
    shallowEqual,
  );

  /** Массив доступных персонажей. Включает в себя объекты с soc, optionSoc, generation и т.п. */
  const tariffs = useAppSelector((state) => state.tariffsInfo);

  /** Массив зависимостей для useEffect с вызовом метода fetchTariffUpPreset на получение пресета */
  const fetchPresetDependencies = [gbValue, minValue];

  /* Если текущий тариф НЕ является подключенным, добавляем в зависимости tariffSoc.
   * По условию на 02.05.2024 (сообщили тестировщики), мы НЕ ДОЛЖНЫ делать запрос на пресеты, при смене аппера, на подключенном тарифе! */
  if (!isConnected) {
    fetchPresetDependencies.push(tariffSoc);
  }

  /* Дефолтный сок тарифа */
  const defaultTariffSoc = getDefaultCharacter(tariffs)?.soc;

  /* Сок для запроса дефолтный если тариф подключен */
  const soc = isConnected ? defaultTariffSoc : tariffSoc;

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return () => {};
    }

    const promise = dispatch(fetchTariffUpPreset({ gbValue, minValue, soc }));
    promise
      .unwrap()
      .then((res: TFetchTariffUpPresetQueryRes) => {
        const isHighPreset = res?.presets?.isHighPreset ?? false;
        /** Текущий активный сок персонажа. Меняется в зависимости от типа пресета (isHighPreset)
         * upd: замена tariffSoc на alias в рамках задачи (FIRST-7288), т.к. для ряда тарифов приходят
         * апперы с одинаковыми soc и на данный момент alias единственный уникальный параметр у тарифа */
        const currentCharacterSoc = defineCharacterSoc(tariffs, alias, isHighPreset);

        /* Ставим текущий активный сок персонажа в total */
        dispatch(setCharacterSoc(currentCharacterSoc));

        /* Ставим текущие активные доп. сервисы (например, "Семья") по пресету в tariffServices  */
        dispatch(setExtraServices(res?.extraServices));

        /* Диспатчим подготовленные опции в слайс с опциями */
        dispatch(
          setOptions(prepareOptionsForPassiveAbility(res.options || [], currentCharacterSoc)),
        );
      })

      .catch((e: Error) => throwAsyncError(e));

    return () => {
      promise.abort();
    };
  }, [...fetchPresetDependencies]);
};
