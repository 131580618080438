import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';

import { useAppSelector } from 'constructorV1/store';
import { selectPresetsLoading } from 'constructorV1/components/constructorPage/PresetPickers/selectors/selectPresetsLoading';

import { selectGifts } from './selectors/selectGifts';
import { GiftTile } from './components/GiftTile';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Контейнер для минут ЕК */
export const GiftContainer = memo(() => {
  const gifts = useAppSelector(selectGifts);
  const isLoading = useAppSelector(selectPresetsLoading);

  return !isLoading ?
      <motion.div className={cx('container')} layout>
        <AnimatePresence>
          {gifts?.map(({ title, subTitle, icon }, index) => (
            /* Возникает проблема анимации с ключами на основе title/subTitle */
            <LayoutGroup key={index}>
              <GiftTile icon={icon} subTitle={subTitle} title={title} />
            </LayoutGroup>
          ))}
        </AnimatePresence>
      </motion.div>
    : null;
});
