import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const SubscriptionPrice = ({ statusText, price, paymentPeriod, hasTrialPeriod }) => {
  const statusInLowerCase = statusText.toLowerCase();
  if (statusText && hasTrialPeriod) {
    return (
      <div className={cn('trial-price')}>
        <Text color="grey50" size="size5-r-s">
          {statusInLowerCase}
        </Text>
        <Text color="grey50" size="size5-r-s">
          {/* TODO: Move to content and use template parser */}
          {`затем ${price} ${paymentPeriod}`}
        </Text>
      </div>
    );
  }

  return (
    <Text color="grey50" size="size5-r-s">
      {price}
      &nbsp;
      {paymentPeriod}
    </Text>
  );
};

SubscriptionPrice.propTypes = {
  statusText: PropTypes.string,
  price: PropTypes.string,
  paymentPeriod: PropTypes.string,
  hasTrialPeriod: PropTypes.bool,
};

export { SubscriptionPrice };
