import React from 'react';
import PropTypes from 'prop-types';
import { PopupStateless } from '@beef/legacy-ui-kit';

import { pushTariffOption } from 'utils/ga';

const Popup = ({ children, onClose, ...props }) => (
  <PopupStateless
    onClose={() => {
      pushTariffOption('Закрыть popup');
      onClose();
    }}
    {...props}
  >
    {children}
  </PopupStateless>
);

Popup.propTypes = { onClose: PropTypes.func };

export default Popup;
