import React, { memo } from 'react';
import { Button, Text, Tooltip } from '@beef/ui-kit';
import classnames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const ActionButton = memo(
  ({ buttonText, agreementText, tooltipText, isLoading, formId, isIcon }) => (
    <>
      <div className={cx('text')}>
        <Text color="grey50" size="size6-r">
          {agreementText}
        </Text>{' '}
        {isIcon && (
          <Tooltip maxWidth="280" message={tooltipText} position="top" variant="secondary">
            <Icon name="FTTBInfo" />
          </Tooltip>
        )}
      </div>
      <Button extraAttributes={{ form: formId }} isLoading={isLoading} type="submit">
        {buttonText}
      </Button>
    </>
  ),
);
