import { BehaviorSubject } from 'rxjs';

import { TWithObservableState } from './types';

/**
 * Обертка над объектом, которая позволяет подписываться
 * на изменения в нем через поле ObservableState
 */
export function createObservableState<
  T extends Record<string, unknown>,
  S extends Record<string, unknown> = Record<string, unknown>,
>(initialState: T, state?: BehaviorSubject<S>) {
  // Копируем изначальный объект таким образом,
  // чтобы поля превратились из { field: value } -> { _field: value }
  // это нужно, так как мы перехватываем set для field, а записываем в _field
  // т.е. когда мы делаем obj.field = value, на самом деле мы назначаем obj._field = value;
  const obj = Object.entries(initialState).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`_${key}`]: value,
    }),
    {},
  );

  return new Proxy<TWithObservableState<T>>(
    {
      ...obj,
      ObservableState: state || new BehaviorSubject<T>(initialState),
    } as TWithObservableState<T>,
    {
      set(target: TWithObservableState<Record<string, unknown>>, prop: string, value: unknown) {
        target[`_${prop}`] = value;
        target.ObservableState.next({ ...target });

        return true;
      },

      get(target, prop: string) {
        if (prop === 'ObservableState') return target.ObservableState;
        return target[`_${prop}`];
      },
    },
  );
}
