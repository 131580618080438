import { connectMethodTypes, pushYm } from '../../../tempAnalytics/metricUtils';
import { TabTypes } from '../../constructorPage/Tabs/constants';

type TSendYm = {
  currentTab?: TabTypes;
  description?: string;
  errorType?: string;
  title?: string;
};
/** Ф-я отправки параметров при показе страницы ошибки */
export const sendYm = ({ currentTab, errorType, title = '', description = '' }: TSendYm) => {
  pushYm({
    error:
      currentTab && currentTab in connectMethodTypes ? connectMethodTypes[currentTab] : errorType,
    error_title: title,
    error_text: description,
  });
};
