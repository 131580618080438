import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { pushOpenTariffConnectPopup } from 'utils/ga';
import { templateParser } from 'utils/format-string';

// Components
import DescriptionsWrapper from 'pages-components/Descriptions';
import Ctx from 'pages-components/Ctx';

// Styles

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const onButtonClick = (dataForGA, callback) => () => {
  pushOpenTariffConnectPopup({ ...dataForGA });

  return callback();
};

const DivorcingModal = ({
  toggleTariffConnectionPopup,
  dataForGA,
  tariffName,
  convergentOrderPopup: {
    connectConvergenceButtonText,
    convergentOrderUrl,
    connectButtonText,
    descriptions,
    description,
    heading,
  },
}) => {
  const [showMore, updateShowMore] = useState(false);

  const headingText = templateParser(heading, { tariffName });

  return (
    <div>
      <Heading level={1}>{headingText}</Heading>
      <p>{description}</p>

      <div className={cx('buttonsGroup')}>
        <Button
          className={['big', cx('orderUrl')]}
          href={`${convergentOrderUrl}&returnUrl=${encodeURIComponent(window.location.pathname)}`}
          transformer
        >
          {connectConvergenceButtonText}
        </Button>
        <Button
          className={['big', 'light']}
          onClick={onButtonClick(dataForGA, toggleTariffConnectionPopup)}
          transformer
        >
          {connectButtonText}
        </Button>
      </div>

      {descriptions && (
        <div className={cx('moreButton', { showMore })}>
          <Link
            className={cx('moreButtonLink')}
            onClick={() => {
              updateShowMore(!showMore);
            }}
          >
            {Ctx.tariff.divorcingModal.buttons.moreInfo}
          </Link>
        </div>
      )}
      {showMore && <DescriptionsWrapper className={cx('description')} items={descriptions} />}
    </div>
  );
};

DivorcingModal.propTypes = {
  toggleTariffConnectionPopup: PropTypes.func,
  dataForGA: PropTypes.object,
  tariffName: PropTypes.string,
  convergentOrderPopup: PropTypes.shape({
    connectConvergenceButtonText: PropTypes.string,
    connectButtonText: PropTypes.string,
    convergentOrderUrl: PropTypes.string,
    descriptions: PropTypes.array,
    description: PropTypes.string,
    heading: PropTypes.string,
  }),
};

export default DivorcingModal;
