import React, { FC } from 'react';
import { Modal } from '@beef/ui-kit';
import { RootStateOrAny, connect } from 'react-redux';

import {
  selectErrorText,
  selectHasErrorValidation,
  selectIsLoadingModal,
  selectLoaderAnimationName,
  selectModalsContent,
  selectModalsDetails,
  selectStateSpn,
  selectWaitingRequest,
} from 'pages/SPN/store/actions/selectors';

import { setLoaderContentStep, setStepModalSpn, setToggleModal } from '../store/actions';
import { useInteractionModalSPN } from './hook';
import { MainInfo } from './components/MainInfo';
import { ActionBarMain } from './components/MainInfo/components/ActionBarMain';
import { CommonModal } from './components/CommonModal/index';
import { ActionBar } from './components/ActionBar/index';
import { MODAL_STEPS_SPN } from '../store/constants';
import {
  ChoosenContentType,
  ModalContainerHookTypes,
  ModalsContainerTypes,
  OutputDataHookType,
  ThunkDispatchType,
} from '../types';

const ModalsContainer: FC<ModalsContainerTypes> = ({
  modalsContent,
  childSocsEditable,
  childSocs,
  spnState,
  isOpen,
  onCloseModal,
  step,
  dispatch,
  loaderContentStep,
  hasErrorValidation,
  errorText,
  isLoadingModal,
  isWaitingRequest,
  animationName,
}) => {
  const interactionModalSpn: OutputDataHookType | null = useInteractionModalSPN({
    modalsContent,
    childSocsEditable,
    childSocs,
    spnState,
    step,
    dispatch,
    loaderContentStep,
    hasErrorValidation,
    errorText,
    isLoadingModal,
    isWaitingRequest,
  } as ModalContainerHookTypes);

  if (!interactionModalSpn) return null;

  const {
    headerBarConfig,
    optionsDataForMainModal,
    optionsDataForCommonModal,
    handlersModal,
    optionsActionBar,
  } = interactionModalSpn;

  const DEFAULT_STEP = 'common';
  const exhaustiveStep = step === MODAL_STEPS_SPN.MAIN ? MODAL_STEPS_SPN.MAIN : DEFAULT_STEP;
  const isShowHeaderOfModal = exhaustiveStep === MODAL_STEPS_SPN.MAIN ? headerBarConfig : {};

  const choosenContent: ChoosenContentType = {
    main: {
      details: <MainInfo data={optionsDataForMainModal} />,
      actions: <ActionBarMain data={optionsDataForMainModal} handlersModal={handlersModal} />,
    },
    common: {
      details: <CommonModal animationName={animationName} data={optionsDataForCommonModal} />,
      actions: <ActionBar data={optionsActionBar} handlersModal={handlersModal} />,
    },
  };

  return (
    <Modal
      actionBar={isLoadingModal ? undefined : choosenContent[exhaustiveStep].actions}
      headerBarConfig={isShowHeaderOfModal}
      isLoading={isLoadingModal}
      isOpen={isOpen}
      onClose={onCloseModal}
      size="s"
    >
      {choosenContent[exhaustiveStep].details}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatchType) => ({
  onCloseModal: () => {
    dispatch(setToggleModal());
    dispatch(setStepModalSpn('main'));
    dispatch(setLoaderContentStep(''));
  },
  dispatch,
});

export default connect((state: RootStateOrAny) => {
  const spnState = selectStateSpn(state);
  const modalsExtData = selectModalsDetails(spnState);

  return {
    modalsContent: selectModalsContent(spnState),
    childSocsEditable: modalsExtData?.childSocsEditable,
    childSocs: modalsExtData?.childSocs,
    isOpen: spnState?.isModalOpen,
    spnState,
    step: spnState?.modalStep,
    loaderContentStep: spnState?.loaderContentStep,
    isWaitingRequest: selectWaitingRequest(spnState),
    isLoadingModal: selectIsLoadingModal(spnState),
    hasErrorValidation: selectHasErrorValidation(spnState),
    errorText: selectErrorText(spnState),
    animationName: selectLoaderAnimationName(state),
  };
}, mapDispatchToProps)(ModalsContainer);
