import axios from 'axios';

import { declension, dictionary, maskPhone } from 'utils/format-string';

import { EStatus, EStatusTranslated, IPersonalInfoTexts, TGetUserStatusResponse } from './types';

export const USERINFO_ERRORS = {
  NOT_ALLOWED_FOR_POSTPAID: 'NOT_ALLOWED_FOR_POSTPAID',
};

export const getDataUserInfo = async () =>
  axios.get<TGetUserStatusResponse>('/customers/products/userinfo/').then(({ data }) => data);

function getStatusForNonFTTB(userStatus: EStatus, positiveBalance: boolean): string {
  if (!positiveBalance) {
    return EStatusTranslated.NegativeBalance;
  }

  const statusTranslations: { [key in EStatus]?: string } = {
    [EStatus.Active]: EStatusTranslated.Active,
    [EStatus.Blocked]: EStatusTranslated.Blocked,
  };

  return statusTranslations[userStatus] || EStatusTranslated.NegativeBalance;
}

function getStatusForFTTB(balance: number | undefined, userStatus: EStatus) {
  if (!balance || !userStatus) return '';

  if (balance >= 0) {
    switch (userStatus) {
      case EStatus.Active:
        return EStatusTranslated.Active;
      case EStatus.Activating:
      case EStatus.Unknow:
        return '';
      case EStatus.Blocked:
      case EStatus.BlockedByFinance:
      case EStatus.NotEnoughMoney:
      case EStatus.BlockedByWish:
      case EStatus.Corporative:
        return EStatusTranslated.Blocked;
      default:
        return '';
    }
  } else {
    // Баланс < 0
    switch (userStatus) {
      case EStatus.Active:
      case EStatus.NotEnoughMoney:
      case EStatus.Activating:
      case EStatus.Unknow:
        return EStatusTranslated.NegativeBalance;
      case EStatus.Blocked:
      case EStatus.BlockedByWish:
      case EStatus.Corporative:
        return EStatusTranslated.Blocked;
      default:
        return '';
    }
  }
}

export function composeUserStatus(
  personalInfo: TGetUserStatusResponse | undefined,
  isFTTB: boolean,
  texts: IPersonalInfoTexts,
) {
  const userStatus = personalInfo?.status.status || texts.defaultStatus;

  const balance = personalInfo?.balance.balance;

  if (!isFTTB) {
    const isPositiveBalance = balance ? balance >= 0 : true;
    return getStatusForNonFTTB(userStatus, isPositiveBalance);
  }

  return getStatusForFTTB(balance, userStatus);
}

export function extractPersonalInfoData(
  personalInfo: TGetUserStatusResponse | undefined,
  texts: IPersonalInfoTexts,
  positiveBalance: boolean,
) {
  // Вычисление displayedCtn
  const displayedCtn =
    personalInfo?.profileSummary?.ctn[0] === '0' ?
      maskPhone(personalInfo?.profileSummary?.ctn, '0__ ___ ___ __ __')?.substring(1)
    : maskPhone(personalInfo?.profileSummary?.ctn || texts.defaultCtn);

  // Составление информации о биллинге
  const { nextBillingDate, rcRate, longRcRate } = personalInfo?.profileSummary || {};
  const rate = rcRate || longRcRate;
  const billingInfo = nextBillingDate ? `${nextBillingDate} ${texts.nextBilling} ${rate} ₽` : '';

  // Составление текста об услугах
  const count = personalInfo?.services?.connectedServicesCount;
  const servicesText = count ? `${count} ${declension(dictionary.service, count)}` : texts.update;

  // Вычисление статуса активности тарифа
  const isActiveTariff =
    personalInfo?.balance && personalInfo?.status ?
      positiveBalance && personalInfo.status.status === EStatus.Active
    : true;

  return { displayedCtn, billingInfo, servicesText, isActiveTariff };
}
