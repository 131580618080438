import { TTariffInfo } from '../components/constructorPage/TariffInfoTiles/types';

/** Функция-маппер для получения пассивок по выбранному персонажу */
export const getActiveCharacterPassive = (
  tariffs: Partial<TTariffInfo[]>,
  characterSoc: string,
  isHighPreset: boolean,
) => {
  /** Получаем объект с данными текущего выбранного зверя в зависимости от типа пресета */
  const chosenTariff = tariffs?.find((tariff) => {
    const soc = isHighPreset ? tariff?.highOptionSoc : tariff?.optionSoc;
    return soc === characterSoc;
  });

  /* Получаем данные текущего активного персонажа */
  const { imageSrc, advantages, activeColor: backgroundColor } = chosenTariff || {};

  /* Теста для высшей и нисшей пассивки определяются как description/highDescription способностей */
  const { description, highDescription } = advantages?.power || {};

  /** Просматриваемый таб для компонента PassiveTabs.
   * Основывается на индексе (массив персонажей) текущего выбранного персонажа */
  const selectedType = String(tariffs?.indexOf(chosenTariff));

  /** Текущий текст плашки пассивки (под пресетом Гб) */
  const passiveText = isHighPreset ? highDescription : description;

  /** Общие пропы для компонента PassiveContent в Passive */
  const passiveContentProps = { imageSrc, passiveText };

  return { selectedType, backgroundColor, passiveContentProps };
};
