import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import DocumentLink from '../../../components/DocumentLink';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Скачать PDF;
 */
const Additional = ({ fullDescriptionLink: { href, text, format, size }, className }) => (
  <div className={cx('additional', className)}>
    <DocumentLink format={format} href={href} size={size} text={text} />
  </div>
);

Additional.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fullDescriptionLink: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
    format: PropTypes.string,
    size: PropTypes.string,
  }),
};

export default Additional;
