import React from 'react';
import { useIsMobileDimension } from '@carnica/utils/hooks';

import { useAppSelector } from 'constructorV1/store';
import { selectBenefits } from 'constructorV1/store/selectors/selectCommon';
import { selectIsAuthenticated } from 'constructorV1/store/selectors/selectUser';

import { BenefitsWithPagination, BenefitsWithSwiper } from './components';

/** Компонент ЕК Benefits состоит из карусели карточек с описанием преимуществ. */
export const Benefits = () => {
  const isMobile = useIsMobileDimension();
  /** Контентная часть УТП */
  const benefits = useAppSelector(selectBenefits);
  /** Флаг авторизации пользователя */
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  /* Если пользователь авторизован^ НЕ показываем блок УТП */
  if (isAuthenticated) {
    return null;
  }

  return benefits?.length ?
      <section>
        {isMobile ?
          <BenefitsWithSwiper benefits={benefits} />
        : <BenefitsWithPagination benefits={benefits} />}
      </section>
    : null;
};
