import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Faq, FaqPropsInterface } from '@beef/smart-kit';

import { isEmptyValue } from 'utils/isEmptyValue';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const FAQWrapper: FC<FaqPropsInterface> = ({ data }) => {
  if (isEmptyValue(data)) return null;

  const { title, categories, data: faqData } = data;

  return (
    <div className={cn('wrapper')}>
      <Faq categories={categories} data={faqData} title={title} />
    </div>
  );
};
