import { SET_CONFIRM_NUMBER_POPUP, TOGGLE_CONFIRM_NUMBER_POPUP } from './actionTypes';

export const setConfirmPopup = (data) => ({
  type: SET_CONFIRM_NUMBER_POPUP,
  payload: data,
});

export const toggleConfirmPopup = (type, isOpened) => ({
  type: TOGGLE_CONFIRM_NUMBER_POPUP,
  payload: { [type]: isOpened },
});
