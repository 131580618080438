import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const EquipmentFeatures = ({ routers, alias }) => {
  const { features } = routers[alias] || {};

  if (!features) return null;
  return (
    <div className={cx('wrapper')}>
      {features.map((feature, i) => (
        <div className={cx('routerFeature')} key={i}>
          <div className={cx('routerFeaturesIcon')}>
            <img alt="" src={feature.iconUrl} />
          </div>
          <div className={cx('routerFeaturesContent')}>
            <div className={cx('routerFeaturesTitleWrapper')}>
              <span
                className={cx('routerFeaturesTitle')}
                dangerouslySetInnerHTML={{ __html: feature.title }}
              />
              <span
                className={cx('routerFeaturesSubTitle')}
                dangerouslySetInnerHTML={{ __html: feature.subTitle }}
              />
            </div>
            <div
              className={cx('routerFeaturesListCaption')}
              dangerouslySetInnerHTML={{ __html: feature.listCaption }}
            />
            <ul className={cx('routerFeaturesList')}>
              {feature.listItems.map((listItem, key) => (
                <li className={cx('routerFeaturesListItem')} key={key}>
                  <span dangerouslySetInnerHTML={{ __html: listItem }} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

EquipmentFeatures.propTypes = {
  routers: PropTypes.arrayOf(
    PropTypes.shape({
      features: PropTypes.arrayOf(
        PropTypes.shape({
          iconUrl: PropTypes.string,
          title: PropTypes.string,
          subTitle: PropTypes.string,
          listCaption: PropTypes.string,
          listItems: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
    }),
  ),
  alias: PropTypes.string,
};

export default EquipmentFeatures;
