import React from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import style from './styles.module.pcss';

const cn = classNames.bind(style);

type QRCodeType = {
  img: string;
  text?: string;
};

export const QRCode = ({ img, text }: QRCodeType) => (
  <div className={cn('container')}>
    <div className={cn('qr')}>
      <img alt="qrCode" className={cn('qr__img')} src={img} />
    </div>
    {text && (
      <Text className={cn('qr__text')} color="grey50" size="size7-r">
        {text}
      </Text>
    )}
  </div>
);
