import React, { CSSProperties, FC, ReactNode, memo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface ICardFormProps {
  children?: ReactNode;
  style?: CSSProperties;
  wrapperClassName?: string;
}

/**
 * Компонент карточки в форме МНП
 */
export const CardForm: FC<ICardFormProps> = memo(({ children, wrapperClassName, style }) => {
  return (
    <div className={cx('card-form', wrapperClassName)} style={style}>
      {children}
    </div>
  );
});
