import { getHasBrowserWindowAPI, openUrlInNewTab } from 'utils/window';

import { selectLegacyModalState } from '../../selectors';
import { TariffUpModals } from '../../store/TariffUpModals';
import { selectEndpoints } from '../selectors';

export { onCancel } from '../handler';

export const onMount = () => {
  try {
    TariffUpModals.IsLoading = true;

    const state = selectLegacyModalState();

    const endpoints = selectEndpoints(state);
    const familyEndpoint = endpoints?.family;

    if (!familyEndpoint) {
      throw new Error('No family ya.cc/plus-family endpoint in content');
    }

    if (!getHasBrowserWindowAPI()) {
      throw new Error('No window object. Possibly you are running it on server side.');
    }

    openUrlInNewTab(familyEndpoint);
  } catch (e) {
    console.error(e);
  } finally {
    TariffUpModals.IsLoading = false;
    TariffUpModals.IsOpen = false;
  }
};
