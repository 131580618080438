import React, { FC } from 'react';
import { Preloader } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент-лоадер. Обрисовывается в момент запроса данных по ШПД-сервисам */
export const LoadingServices: FC = () => (
  <section className={cx('wrapper')}>
    <Preloader size="m" />
  </section>
);
