import React from 'react';
import classNames from 'classnames/bind';

import { TitleContent } from '../../commonComponents/TitleContent';
import { LoadingServices } from '../FmcServicesSettings/components/LoadingServices';
import { DateSlots } from './components/DateSlots';
import { TimeSlots } from './components/TimeSlots';
import { usePrepareSpecialistVisitContent } from './hooks/usePrepareSpecialistVisitContent';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Контейнер формы выбора слотов монтажника ШПД */
export const SpecialistVisit = () => {
  const { availableSchedule, contentProps } = usePrepareSpecialistVisitContent();
  if (!availableSchedule) {
    return <LoadingServices />;
  }
  return (
    <div className={cx('wrapper')}>
      <TitleContent {...contentProps} size="size2-m" />
      <div className={cx('container')}>
        <DateSlots />
        <TimeSlots />
      </div>
    </div>
  );
};
