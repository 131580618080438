import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import PropTypes from 'prop-types';
import { Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { deliveryPayment, toggleOrderPaymentResultPopup } from 'widgets/actions/orderPaymentForm';
import { deleteCookie } from 'utils/cookie';
import PopupStateless from 'pages-components/Popup';
import PlainContainer from 'pages-components/PopupContainer/PlainContainer';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const OrderPaymentResultPopup = ({ closePopup, opened, content, deliveryPaymentData }) => {
  if (!content) return null;

  const [isPreloader, togglePreloader] = useState(false);
  const [description, changeDescription] = useState(content.description);

  useEffect(() => {
    if (content.status === 'needSubmit') {
      const baseUrl = `${window.location.origin}${window.location.pathname}`;
      const newDescription = content.description.replace(
        '<a>',
        `<a href="${baseUrl}?onlycourier=1&connect=true">`,
      );
      changeDescription(newDescription);
    }

    return () => deleteCookie('_6182_waiting');
  }, [opened]);

  const onSubmit = async () => {
    togglePreloader(true);
    const { showErrorPopup } = await deliveryPayment(deliveryPaymentData);
    if (showErrorPopup) togglePreloader(false);
  };
  const onButtonClick = content.status === 'needSubmit' ? onSubmit : closePopup;

  return (
    <PopupStateless onClose={closePopup} opened={opened}>
      {isPreloader ?
        <Preloader />
      : <PlainContainer
          {...content}
          className={cx('popupContainer')}
          description={description}
          onButtonClick={onButtonClick}
        />
      }
    </PopupStateless>
  );
};

OrderPaymentResultPopup.propTypes = {
  opened: PropTypes.bool,
  closePopup: PropTypes.func,
  content: PropTypes.object,
  deliveryPaymentData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  opened: pathOr(false, ['orderPaymentForm', 'showResultPopup'], state.external),
  content: pathOr(null, ['orderPaymentForm', 'resultPopupData'], state.external),
  deliveryPaymentData: pathOr({}, ['orderPaymentForm', 'deliveryPaymentData'], state.external),
});

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => dispatch(toggleOrderPaymentResultPopup(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderPaymentResultPopup);
