import { createAction } from '@reduxjs/toolkit';

// User Data Actions
export const setFlat = createAction('SET_FLAT');
export const setHouse = createAction('SET_HOUSE');
export const setStreet = createAction('SET_STREET');
export const setRegion = createAction('SET_SELECTED_REGION');
export const resetUserData = createAction('RESET_USER_DATA');
export const setPhoneNumber = createAction('SET_PHONE_NUMBER');
export const setIsHighSpeed = createAction('SET_IS_HIGH_SPEED');
export const setStatusConnection = createAction('SET_STATUS_CONNECTION');
export const resetUserContactFields = createAction('RESET_USER_CONTACT_FIELDS');
