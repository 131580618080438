import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  ESIA_REDIRECT_CODE,
  STEPS,
} from 'pages/Services/AссessibleInternet/accessibleInternetServiceHelper';
import AccessibleInternetContext from 'pages/Services/AссessibleInternet/AccessibleInternetContext';
import RequestStatusWithLoader from 'pages/Services/AссessibleInternet/component/RequestStatusWithLoader';
import { ymPushParams } from 'utils/ym';

const RedirectFromEsia = ({ texts, onClose, opened }) => {
  const { esiaQueryParams } = useContext(AccessibleInternetContext);
  const { code } = esiaQueryParams;
  const { OK, ESIA_ERROR, ESIA_ACCESS_DENIED } = ESIA_REDIRECT_CODE;

  const handleClose = useCallback(() => {
    const newStep = code === OK ? STEPS.CONNECTED : STEPS.INITIAL;
    onClose(newStep);
  }, [code]);

  const currentData = texts[code] || texts.defaultError;

  useCallback(() => {
    if (code) {
      ymPushParams({
        page_category: {
          personal_account: { free_internet: 'auth_start_gosuslugi' },
        },
      });
      if (code !== ESIA_ERROR && code !== ESIA_ACCESS_DENIED) {
        ymPushParams({
          page_category: {
            personal_account: { free_internet: 'auth_success_gosuslugi' },
          },
        });
        if (code === OK) {
          ymPushParams({
            services_ev_: {
              service_connect: { beeline: 'Доступный интернет' },
            },
          });
        }
      }
    }
  }, [ESIA_ACCESS_DENIED, ESIA_ERROR, OK, code]);

  return (
    <RequestStatusWithLoader
      data={currentData}
      isOpen={opened}
      onClose={handleClose}
      onConfirm={handleClose}
    />
  );
};

RedirectFromEsia.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  texts: PropTypes.shape({
    defaultError: PropTypes.object,
    [ESIA_REDIRECT_CODE.OK]: PropTypes.object,
    [ESIA_REDIRECT_CODE.NOT_TRUSTED_ESIA_ACCOUNT]: PropTypes.object,
    [ESIA_REDIRECT_CODE.NOT_RUS]: PropTypes.object,
    [ESIA_REDIRECT_CODE.NOT_MATCHED]: PropTypes.object,
    [ESIA_REDIRECT_CODE.ESIA_ACCESS_DENIED]: PropTypes.object,
    [ESIA_REDIRECT_CODE.ESIA_ERROR]: PropTypes.object,
  }),
};

export default RedirectFromEsia;
