import { sanitize } from '@beef/utils';

import { MAX_CHIPS_VALUE } from '../components/Filter/constants';
import {
  FilterType,
  SelectedFiltersType,
  SelectedParametersType,
} from '../components/Filter/FilterWrapper/types';

export const getUrlSearchParams = (): URLSearchParams | undefined => {
  if (typeof window === 'undefined') return undefined;
  return new URLSearchParams(window.location.search);
};

export const prepareFilterListChips = (list: FilterType[] = []) =>
  list?.slice(0, MAX_CHIPS_VALUE)?.map((filterItem) => ({
    title: filterItem.name?.toLowerCase(),
    value: filterItem.id,
  })) || [];

const validateCurrentParameters = (
  currentFilter: string[] = [],
  availableFilters: SelectedFiltersType = {},
) => {
  const [key, value] = currentFilter;
  const filters = value?.split(';') || [];

  return filters
    ?.filter(
      (param: string) => availableFilters?.[key]?.find((filter: any) => filter?.id === param),
    )
    ?.join(';');
};

export const validateSelectedFilters = (
  selectedParameters: SelectedParametersType = {},
  availableParameters: SelectedFiltersType = {},
): SelectedParametersType => {
  const selectedParametersFilters = selectedParameters?.filters || {};
  const selectedFilters = Object.entries(selectedParametersFilters) as Array<Array<string>>;

  if (!Object.keys(availableParameters)?.length) {
    return selectedParameters;
  }

  const filters = selectedFilters.reduce((acc, curr) => {
    acc = {
      ...acc,
      [curr[0]]: validateCurrentParameters(curr, availableParameters),
    };
    return acc;
  }, {});

  return {
    ...selectedParameters,
    filters,
  };
};

export const prepareCurrentFilters = (value: string, currentFilters: string[] = []) => {
  const newValueIndex = currentFilters.indexOf(value);
  if (newValueIndex !== -1) {
    currentFilters.splice(newValueIndex, 1);
  } else {
    currentFilters.push(value);
  }
  return currentFilters;
};

export const updateProductsWithCache = (
  cacheParameters: URLSearchParams | undefined,
  actionClose: (url?: string, flag?: boolean) => void,
) => {
  const params = getUrlSearchParams()?.toString();
  const paramsCache = cacheParameters?.toString();

  if (params !== paramsCache) {
    actionClose(paramsCache, true);
  }
};

export const prepareQuerySearch = (query: string = '') => {
  if (query || typeof window === 'undefined') {
    return query;
  }
  const url = new URL(window.location.href);
  const termInfo = url.searchParams.get('term') || '';
  return termInfo && decodeURIComponent(sanitize(termInfo));
};
