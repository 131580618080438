import { FontSizeType } from '@beef/ui-kit';

import { useSelectActiveInacId } from '../../../../../FmcTabsContainer/selectors/useSelectActiveInacId';
import { useSelectFmcServiceContent } from '../../selectors/useSelectFmcServiceContent';
import { useSelectFmcServicesTariffs } from '../../selectors/useSelectFmcServicesTariffs';
import { selectServiceForActiveTab } from '../../utils';

/** Хук подготовки пропов для блока TvServicesContainer */
export const usePrepareTvSubscriptions = () => {
  /** Текущий активный inacId ШПД */
  const currentActiveInacId = useSelectActiveInacId() ?? '';

  /* Доступные тарифы ШПД для настройки */
  const servicesTariffs = useSelectFmcServicesTariffs() ?? [];

  /* Доступные TV-подписки по текущему активному табу (currentActiveInacId) */
  const tvSubscriptionsServices =
    selectServiceForActiveTab(servicesTariffs, currentActiveInacId)?.tvSubscriptionsServices ?? [];

  /* Контент для страницы настройки ШПД */
  const settingsFmcPage = useSelectFmcServiceContent();
  const { tvSubscriptionSection } = settingsFmcPage ?? {};

  /* Пропы для компонента TitleContent (информационное описание блока TV) */
  const titleContentProps = {
    ...tvSubscriptionSection,
    size: 'size3-m' as FontSizeType,
    extraSize: 'size5-r-s' as FontSizeType,
  };

  return { titleContentProps, tvSubscriptionsServices };
};
