/** лучше использовать для коротких текстов */
export const parseTextIntoReactElement = (text) => {
  const components = [];
  let currentText = '';
  let i = 0;

  while (i < text.length) {
    if (text[i] === '<') {
      // Сохранить текущий текстовый сегмент
      if (currentText) {
        components.push(currentText);
        currentText = '';
      }

      // Извлечение имени тега
      const endOfOpeningTag = text.indexOf('>', i);
      const tagName = text.slice(i + 1, endOfOpeningTag);

      const closingTag = `</${tagName}>`;
      const closingTagIndex = text.indexOf(closingTag, i);

      // Извлечение содержимого между тегами
      const content = text.slice(endOfOpeningTag + 1, closingTagIndex);

      components.push(React.createElement(tagName, null, content));

      i = closingTagIndex + closingTag.length;
    } else {
      currentText += text[i];
      i++;
    }
  }

  // Сохранить текущий текстовый сегмент
  if (currentText) {
    components.push(currentText);
  }

  return components;
};
