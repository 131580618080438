import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Switch } from '@beef/ui-kit';

import { PictureContainer } from './components/PictureContainer';
import { InfoContainer } from './components/InfoContainer';
import { TServiceTile } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент плитки ServiceTile */
export const ServiceTile: FC<TServiceTile> = ({
  tag,
  title,
  image,
  mobImage,
  description,
  variant = 'horizontal',
  children,
  isActive,
  showSwitch,
  handleClick,
  handleSwitchClick,
}) => (
  <>
    {/* Карточка сервиса */}
    <button className={cx('wrapper', `wrapper--${variant}`)} onClick={handleClick} type="button">
      <PictureContainer {...{ tag, image, mobImage }} />
      <InfoContainer {...{ title, description, variant }}>{children}</InfoContainer>
    </button>
    {/* Переключение сервиса в статус активен/не активен */}
    {showSwitch && (
      <div className={cx('wrapper__switch')}>
        <Switch checked={isActive} name={title} onChange={handleSwitchClick} />
      </div>
    )}
  </>
);
