import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

import { Option } from './Option';
import { Attention } from '../Attention';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Options = ({
  title,
  options,
  activeTab,
  hasZTariff,
  saveDataTab,
  description,
  isChangeTariff,
  hasConnectedOptions,
  isChangeOptionsTariff,
  optionsAttentionBlockText,
}) => (
  <div className={cx('wrapper')}>
    <div className={cx('wrapper__content')}>
      {title && (
        <Text color="grey95" size="size3-m">
          {title}
        </Text>
      )}
      {description && (
        <div className={cx('wrapper__description')}>
          <Text color="grey50" size="size5-r-s" tagType="div">
            {description}
          </Text>
        </div>
      )}
    </div>
    {hasZTariff && optionsAttentionBlockText && hasConnectedOptions && (
      <Attention
        hasChange={!isChangeOptionsTariff && isChangeTariff}
        text={optionsAttentionBlockText}
      />
    )}
    {options?.map((option, idx) => (
      <Option
        activeTab={activeTab}
        hasZTariff={hasZTariff}
        id={idx}
        initialChecked={option.defaultEnabled}
        isChangeTariff={isChangeTariff}
        key={option.title}
        option={option}
        saveDataTab={saveDataTab}
      />
    ))}
  </div>
);

Options.propTypes = {
  title: PropTypes.string,
  hasZTariff: PropTypes.bool,
  saveDataTab: PropTypes.bool,
  activeTab: PropTypes.string,
  description: PropTypes.string,
  isChangeTariff: PropTypes.bool,
  hasConnectedOptions: PropTypes.bool,
  isChangeOptionsTariff: PropTypes.bool,
  optionsAttentionBlockText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.number,
      value: PropTypes.number,
      period: PropTypes.string,
      isShutdown: PropTypes.bool,
      isConnectedPeriod: PropTypes.bool,
    }),
  ),
};
