import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteInput, Link, TextInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { checkAddressModelPropTypes } from 'pages/FTTB/constants';
import { pushFTTBAction } from 'utils/ga';
import { debounce } from 'utils/timed-functions';
import FocusManager from 'pages-components/FocusManager';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const valuesFilter = (value, items) => items;

const inputChooseStateList = {
  none: 'none',
  focused: 'focused',
  choosed: 'choosed',
};

class CheckAddressForm extends Component {
  state = {
    streetInputStatus: 'ok',
    houseInputStatus: 'ok',
    flatInputStatus: 'ok',
  };

  componentDidMount() {
    const { getValidateFn } = this.props;

    if (getValidateFn) {
      getValidateFn(this.validateStreet, this.validateHouse, this.validateFlat);
    }
  }

  streetInputChooseState = inputChooseStateList.none;

  houseInputChooseState = inputChooseStateList.none;

  onStreetChange = (street) => {
    const { pushGA, checkAddressModel } = this.props;

    checkAddressModel.changeStreet(street, () => {
      setTimeout(() => {
        const input = document.querySelector(`.${cx('houseInputWrapper')} input`);
        if (input) {
          input.focus();
          input.click();
        }
      }, 100);

      this.validateStreet(true);
    });
    checkAddressModel.clearHouse(() => this.houseInput && this.houseInput.handleClear());

    this.streetInputChooseState = inputChooseStateList.choosed;

    pushGA('address', 'street', 'FTTBForm');
  };

  onStreetFocus = () => {
    this.streetInputChooseState = inputChooseStateList.focused;
  };

  onStreetBlur = () => {
    const { streets } = this.props.checkAddressModel;
    const streetNotChoosen = this.streetInputChooseState !== inputChooseStateList.choosed;

    // Если массив улиц содержит элементы и улица ещё не выбрана
    if (streets && streets.length && streetNotChoosen) this.onStreetChange(streets[0]);
    this.props.onStreetBlur();
  };

  onHouseChange = (house) => {
    const { pushGA, checkAddressModel } = this.props;

    checkAddressModel.changeHouse(house, () => {
      setTimeout(() => {
        if (this.flatInput) {
          this.flatInput.focus();
          this.flatInput.click();
        }
      }, 0);
      this.validateHouse(true);
    });
    this.houseInputChooseState = inputChooseStateList.choosed;

    // пуши в аналитику
    pushGA('address', 'house', 'FTTBForm');
    if (house.isConnected) {
      pushGA('address', 'houseFound');
    } else {
      pushGA('address', 'houseNotFound');
    }
  };

  onHouseFocus = () => {
    this.houseInputChooseState = inputChooseStateList.focused;
  };

  onHouseBlur = () => {
    const { houses, houseTerm, clearHouse } = this.props.checkAddressModel;
    const houseNotChoosen = this.houseInputChooseState !== inputChooseStateList.choosed;

    // Если массив домов содержит элементы и дом ещё не выбран
    if (houses && houses.length && houseNotChoosen) {
      const house = houses.find((houseItem) => houseItem.label === houseTerm);

      if (house) this.onHouseChange(house);
      else clearHouse();
    }
    this.props.onHouseBlur();
  };

  onFlatChange = debounce((val) => {
    this.props.checkAddressModel.changeFlat(val);
    this.setState({ flatInputStatus: 'ok' });
  }, 150);

  onFlatInputBlur = (val) => {
    const { pushGA, onFlatInputBlur } = this.props;
    onFlatInputBlur(val);

    if (typeof val === 'string') pushGA('', 'flat', 'FTTBForm');
  };

  // Public api for validation
  validateStreet = (changeStatus) => {
    const { street } = this.props.checkAddressModel;
    const status = street && street.id ? 'ok' : 'fail';
    if (changeStatus) this.setState({ streetInputStatus: status });

    return status;
  };

  validateHouse = (changeStatus) => {
    const { house } = this.props.checkAddressModel;
    const status = house && house.id ? 'ok' : 'fail';
    if (changeStatus) this.setState({ houseInputStatus: status });

    return status;
  };

  validateFlat = (changeStatus) => {
    const { flat } = this.props.checkAddressModel;
    const status = flat ? 'ok' : 'fail';
    if (changeStatus) this.setState({ flatInputStatus: status });

    return status;
  };

  changeCity = () => {
    const { pushGA, checkAddressModel } = this.props;
    checkAddressModel.changeCity();

    pushGA('address', 'city');
  };

  clearStreetInput = () => {
    this.props.checkAddressModel.clearStreet(() => {
      if (this.houseInput) this.houseInput.handleClear();
    });
  };

  render() {
    const {
      className,
      cityLabelText,
      onFlatInputFocus,
      type,
      streetInputPlaceholder,
      houseInputPlaceholder,
      flatInputPlaceholder,
      StreetEmptyListComponent,
      withFlatInput,
      checkAddressModel,
      fmcLanding,
      isGiftLanding,
    } = this.props;

    const {
      currentCity,
      streets,
      street,
      houses,
      house,
      flat,
      fetchStreets,
      fetchHouses,
      clearHouse,
    } = checkAddressModel;
    const { streetInputStatus, houseInputStatus, flatInputStatus } = this.state;

    const inputsSize = type === 'bigInputs' ? 'big' : 'default';

    return (
      <div
        className={cx(
          'checkAddress',
          { bigInputs: type === 'bigInputs', 'form--custom': isGiftLanding, fmcLanding },
          className,
        )}
      >
        <div className={cx('streetInputWrapper')}>
          <div className={cx('streetInputLabel')}>
            {cityLabelText}
            <Link className={cx('cityChange', 'cityTitle')} onClick={this.changeCity}>
              {currentCity.label}
            </Link>
          </div>

          <FocusManager onBlur={this.onStreetBlur} onFocus={this.onStreetFocus}>
            <AutocompleteInput
              EmptyListComponent={StreetEmptyListComponent}
              className={cx('streetInput')}
              filter={valuesFilter}
              itemCaptionExtractor={(item) => item.label}
              items={streets}
              onChange={this.onStreetChange}
              onClearRequested={this.clearStreetInput}
              onFetchRequested={fetchStreets}
              placeholder={streetInputPlaceholder}
              showEmptyList
              showPending
              size={inputsSize}
              status={streetInputStatus}
              value={street}
            />
          </FocusManager>
        </div>
        <div className={cx('houseInputWrapper')}>
          <div className={cx('houseInputLabel')}>Дом</div>
          <FocusManager disabled={!street} onBlur={this.onHouseBlur} onFocus={this.onHouseFocus}>
            <AutocompleteInput
              className={cx('houseInput')}
              disabled={!street}
              filter={valuesFilter}
              itemCaptionExtractor={(item) => item.label}
              items={houses}
              onChange={this.onHouseChange}
              onClearRequested={clearHouse}
              onFetchRequested={fetchHouses}
              placeholder={houseInputPlaceholder}
              ref={(e) => {
                this.houseInput = e;
              }}
              size={inputsSize}
              status={houseInputStatus}
              value={house}
            />
          </FocusManager>
        </div>

        {withFlatInput && (
          <div className={cx('flatInputWrapper')}>
            <div className={cx('flatInputLabel')}>Квартира</div>
            <TextInput
              className={cx('flatInput')}
              disabled={!house || !house.isConnected}
              getRef={(e) => {
                this.flatInput = e;
              }}
              onBlur={this.onFlatInputBlur}
              onChange={this.onFlatChange}
              onFocus={onFlatInputFocus}
              placeholder={flatInputPlaceholder}
              size={inputsSize}
              status={flatInputStatus}
              value={flat}
            />
          </div>
        )}
      </div>
    );
  }
}

CheckAddressForm.defaultProps = {
  pushGA: pushFTTBAction,
  withFlatInput: true,
  cityLabelText: 'Улица в городе ',
  fmcLanding: false,
  onStreetBlur: () => {},
  onHouseBlur: () => {},
  onFlatInputFocus: () => {},
  onFlatInputBlur: () => {},
  type: 'default',
};

CheckAddressForm.propTypes = {
  pushGA: PropTypes.func,
  getValidateFn: PropTypes.func,
  withFlatInput: PropTypes.bool,
  onStreetBlur: PropTypes.func,
  onHouseBlur: PropTypes.func,
  onFlatInputFocus: PropTypes.func,
  onFlatInputBlur: PropTypes.func,
  StreetEmptyListComponent: PropTypes.func,
  type: PropTypes.oneOf(['default', 'bigInputs']),
  streetInputPlaceholder: PropTypes.string,
  houseInputPlaceholder: PropTypes.string,
  flatInputPlaceholder: PropTypes.string,
  checkAddressModel: PropTypes.shape(checkAddressModelPropTypes),
  className: PropTypes.any,
  cityLabelText: PropTypes.string,
  fmcLanding: PropTypes.bool,
};

export default CheckAddressForm;
