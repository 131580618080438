import { shallowEqual } from '@beef/utils';

import { useAppSelector } from '../../../../store';
import { useIsMnpUserType } from '../../../../hooks/useIsMnpUserType';
import { cutPassiveOptionsSocs } from '../utils/cutPassiveOptionsSocs';

/** Селектор выборки параметров для отправки запроса к basket.
 * Содержит id тарифа, id конструктора. соки опций, сок аппера */
// TODO: потребуется получать данные о партнерских подписках
export const useSelectBasketParams = () => {
  const { id, constructorId, characterSoc, optionsSoc, userPhoneNumber, options } = useAppSelector(
    (state) => ({
      id: state.total.id,
      options: state.options,
      optionsSoc: state.total.optionsSoc,
      characterSoc: state.total.characterSoc,
      constructorId: state.total.constructorId,
      userPhoneNumber: state.total.userPhoneNumber,
    }),
    shallowEqual,
  );

  const isMnpUser = useIsMnpUserType();

  // TODO: Выпилить после доработки корзиной своей логики!
  /** Временный костыль для корректной работы корзины.
   * Вырезает бесплатные опции-пассивки (isBlocked) из общего массива подключаемых опций.
   * После доработки корзиной своей части логики, вместо prepareOptionsSocs использовать optionSocs! */
  const prepareOptionsSocs = cutPassiveOptionsSocs(options, optionsSoc, characterSoc);

  /* Объединяем characterSoc и prepareOptionsSocs, т.к. для запроса сок персонажа является обыкновенной опцией */
  const optionSocs = [...prepareOptionsSocs, characterSoc];

  const constructorTariffData = {
    tariffConstructorId: constructorId,
    optionSocs: optionSocs?.join(','),
    partnersId: '',
  };

  /** Базовые параметры для дальнейшего преобразования к формату корзины.
   * Используются для покупки новой sim */
  const baseParams = {
    tariffId: id,
    constructorTariffData,
  };

  /** Дополнительные параметры для покупки mnp
   * расширяют baseParams в случае перехода со своим номером */
  const mnpParams = {
    simOrderPlace: 'mnp',
    mnp: userPhoneNumber,
  };

  const params = {
    ...baseParams,
    ...(isMnpUser ? mnpParams : {}),
  };
  return { params };
};
