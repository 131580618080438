import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TErrorPicture = {
  image?: string;
};

/** Блок с картинкой компонента ошибки для total чека */
export const ErrorPicture: FC<TErrorPicture> = ({ image }) => (
  <div className={cx('wrapper')}>
    <img alt="" loading="lazy" src={image} />
  </div>
);
