import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { useIsMobileDimension } from '@beef/ui-kit';

import { Anchor } from 'pages/CarnicaDSLandings/ui';

import { Metric } from '../../../../analytics';
import styles from './styles.pcss';
import { useBenefitsBlock } from './hooks/useBenefitsBlock';
import { Benefit } from './components/Benefit';

const cx = classNames.bind(styles);

/**
 * Блок "в билайне можно все"
 */
export const BenefitsBlock: FC = () => {
  const { anchorId, description, items, title } = useBenefitsBlock();
  const isMobile = useIsMobileDimension();
  return (
    <Metric events={{ yaMNPMetric: ['handleViewBenefitsBlock'] }} mode="viewport">
      <div className={cx('wrapper')}>
        <Anchor anchorId={anchorId} />

        <div className={cx('title__block')}>
          <Text
            align={isMobile ? 'center' : 'left'}
            variant={isMobile ? 'display-small' : 'display-medium'}
          >
            {title}
          </Text>

          <Text align={isMobile ? 'center' : 'left'} color="secondary" variant="body-medium">
            {description}
          </Text>
        </div>

        {items.map((benefit) => (
          <Benefit key={benefit.id} {...benefit} />
        ))}
      </div>
    </Metric>
  );
};
