import React, { memo } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { EConnectionType } from 'constructorV1/store/userSlice/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TTariffTab = {
  id: EConnectionType;
  isActive: boolean;
  onChange: (id: EConnectionType) => void;
  title?: string;
};

/** Таб способа подключения тарифа (simOrderForm, Mnp) */
export const TariffTab = memo<TTariffTab>(({ id, title, isActive, onChange }) => (
  <button
    className={cx('wrapper', { 'wrapper--active': isActive })}
    onClick={() => onChange(id)}
    type="button"
  >
    {title && (
      <Text
        align="left"
        color={isActive ? 'primary-invert' : 'primary'}
        lineHeight={20}
        size="xs"
        weight="regular"
      >
        {title}
      </Text>
    )}
  </button>
));
