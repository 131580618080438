import { useAppSelector } from 'constructorV1/store';
import { useIsMyTariff } from 'constructorV1/hooks/useIsMyTariff';
import { selectTotalPrice } from 'constructorV1/store/selectors/selectTotal';
import { selectCFloatingTotal } from 'constructorV1/store/selectors/selectCommon';
import { useBillButton } from 'constructorV1/components/commonComponents/Bill/components/BillButton/hooks/useBillButton';
import { selectPresetsLoading } from 'constructorV1/components/constructorPage/PresetPickers/selectors/selectPresetsLoading';

import { useHideOnScroll } from './useHideOnScroll';

/** Хук подготовки данных для компонента FloatingTotal */
export const useFloatingTotal = () => {
  /** Контентная часть блока */
  const { title, unit, btnText } = useAppSelector(selectCFloatingTotal);

  /** Текущая цена без скидки */
  const { totalPrice } = useAppSelector(selectTotalPrice);

  /** Флаг загрузки пресетов ЕК */
  const isLoading = useAppSelector(selectPresetsLoading);

  /** Подготовленный текст с ценой и юнитом */
  const priceText = `${totalPrice} ${unit}`;

  /** Результирующая функция-обработчик клика по кнопке чека ЕК */
  const handleClick = useBillButton();

  /** Флаг определяет, является ли текущий тарифный план "моим тарифом" */
  const isMyTariff = useIsMyTariff();

  /** Флаг скрытия компонента. Отрабатывает при достижении плавающим блоком секции bill (малый чек) */
  const isHiddenOnSection = useHideOnScroll(['constructor-bill']);

  return { title, btnText, priceText, isMyTariff, isLoading, handleClick, isHiddenOnSection };
};
