import PropTypes from 'prop-types';

export const NumberShape = {
  label: PropTypes.string,
  id: PropTypes.number,
};

export const FilterInputShape = {
  query: PropTypes.string,
  placeholder: PropTypes.string,
  onQueryChange: PropTypes.func,
  onDefCodeChange: PropTypes.func,
  defCodes: PropTypes.arrayOf(PropTypes.string),
};

export const SelectItemShape = {
  itemValue: PropTypes.string,
  label: PropTypes.string,
};

export const PromotionShape = {
  category: PropTypes.string,
  tag: PropTypes.shape({
    tagText: PropTypes.string,
    tooltip: PropTypes.shape({
      message: PropTypes.string,
      buttonConfig: PropTypes.shape({
        text: PropTypes.string,
        href: PropTypes.string,
      }),
    }),
  }),
};
