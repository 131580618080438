import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { assocPath, compose, equals, find, findIndex, path, prop, propEq } from 'ramda';
import { Status } from '@beef/legacy-ui-kit';

import PopupStateless from 'pages-components/Popup';
import { defaultOptions, optionsPopup, setServiceStatus } from 'pages/ProductCard/actions/redirect';
import { validateMaskedPhone } from 'utils/validators';

import SettingsContent from './SettingsContent';

class RedirectSettingsPopup extends PureComponent {
  state = {
    activeTab: 'oneNumber',
    options: this.props.options,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { options, saved } = nextProps;
    let nextState = {};
    const activeOption = compose(prop('name'), find(propEq('active', true)))(options);
    if (activeOption !== 'none' && activeOption !== this.state.activeTab) {
      nextState = { ...nextState, activeTab: activeOption };
    }
    if (!equals(options, this.state.options)) {
      nextState = { ...nextState, options };
    }
    if (!equals(nextState, {}) && saved) this.setState({ ...nextState });
  }

  handleClose = () => this.props.optionsPopup({ opened: false });

  handleActiveTabChange = (tab) => this.setState({ activeTab: tab });

  handleSwitchChange = (option) => () => {
    this.setState(({ options }) => {
      const foundIndex = findIndex(propEq('name', 'multipleOptions'), options);
      const foundOption = options[foundIndex];
      return {
        options: assocPath([foundIndex, option, 'active'], !foundOption[option].active, options),
      };
    });
  };

  handleMultipleOptionsPhoneChange = (option) => (value) => {
    this.setState(({ options }) => {
      const foundIndex = findIndex(propEq('name', 'multipleOptions'), options);
      return {
        options: assocPath([foundIndex, option, 'phone'], value, options),
      };
    });
  };

  handleOneNumberPhoneChange = (value) => {
    this.setState(({ options }) => {
      const foundIndex = findIndex(propEq('name', 'oneNumber'), options);
      return {
        options: assocPath([foundIndex, 'phone'], value, options),
      };
    });
  };

  isDisabled = () => {
    const settingsNotChanged = equals(this.state.options, this.props.options);
    const optionsIsValid = this.optionsIsValid();
    return !optionsIsValid || settingsNotChanged;
  };

  handleSave = () => {
    const { activeTab, options } = this.state;
    this.props.setServiceStatus({
      saved: false,
      options: options.map((option, index) => {
        const isActive = option.name === activeTab;
        const properties = isActive ? option : defaultOptions[index];
        return { ...properties, active: isActive };
      }),
    });
    this.handleClose();
  };

  optionsIsValid() {
    const { options, activeTab } = this.state;
    const [, oneNumber, multipleOptions] = options;

    if (activeTab === 'oneNumber' && validateMaskedPhone(oneNumber.phone)) {
      return true;
    }
    if (activeTab === 'multipleOptions') {
      let activeCount = 0;
      const isValid = ['busy', 'noAnswer', 'notAvailable'].reduce((validationResult, value) => {
        if (multipleOptions[value].active) {
          activeCount++;
          return validationResult && validateMaskedPhone(multipleOptions[value].phone);
        }
        return validationResult;
      }, true);

      return isValid && !!activeCount;
    }

    return false;
  }

  render() {
    const { optionsOpened, error = {} } = this.props;
    const { activeTab, options } = this.state;
    const [, oneNumber, multipleOptions] = options;

    return (
      <PopupStateless onClose={this.handleClose} opened={optionsOpened}>
        {error.active ?
          <Status className="error">{error.text}</Status>
        : <SettingsContent
            activeTab={activeTab}
            disabled={this.isDisabled()}
            multipleOptions={multipleOptions}
            onActiveTabChange={this.handleActiveTabChange}
            onMultipleOptionsPhoneChange={this.handleMultipleOptionsPhoneChange}
            onOneNumberPhoneChange={this.handleOneNumberPhoneChange}
            onSave={this.handleSave}
            onSwitchChange={this.handleSwitchChange}
            oneNumber={oneNumber}
          />
        }
      </PopupStateless>
    );
  }
}

RedirectSettingsPopup.propTypes = {
  error: PropTypes.object,
  options: PropTypes.array,
  optionsPopup: PropTypes.func,
  setServiceStatus: PropTypes.func,
  saved: PropTypes.bool,
  optionsOpened: PropTypes.bool,
};

const mapStateToProps = ({ external }) => {
  const optionsOpened = path(['redirect', 'optionsPopup', 'opened'], external);
  const serviceStatus = path(['redirect', 'serviceStatus'], external);
  const { error, options, saved } = serviceStatus || {};
  return { optionsOpened, error, options, saved };
};

const mapDispatchToProps = {
  optionsPopup,
  setServiceStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectSettingsPopup);
