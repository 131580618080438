import React, { FC } from 'react';
import classNames from 'classnames/bind';
// Components
import { Notification } from '@beef/ui-kit';
import { PageFixer, PageRow } from '@beef/smart-kit';

// Hook
import { usePageRow } from 'utils/hooks/usePageRow';
import { isEmptyValue } from 'utils/isEmptyValue';

import { TeaserBannerContainer } from './components/TeaserBannerContainer';
import { ExtendedStepsContainer } from './components/ExtendedStepsContainer';
import { FaqContainer } from './components/FaqContainer';
// Styles
import styles from './styles.pcss';
// Types
import { MainDataTypes } from '../../types';
import { AppBannerContainer } from './components/AppBannerContainer/index';

const cx = classNames.bind(styles);

export const VoWifiContainer: FC<{ data: MainDataTypes }> = ({ data }) => {
  const { notification, faq, extendedSteps, teaserBanner, appBanner } = data;

  const verticalSpaceValue = usePageRow();

  return (
    <>
      <PageRow>{!isEmptyValue(notification) && <Notification {...notification} />}</PageRow>

      <PageRow>
        {!isEmptyValue(teaserBanner) && <TeaserBannerContainer {...teaserBanner} />}
      </PageRow>

      <PageFixer size="l">
        <div className={cx('content-wrapper')}>
          {!isEmptyValue(appBanner) && (
            <PageRow space={verticalSpaceValue}>
              <AppBannerContainer data={appBanner} />
            </PageRow>
          )}

          {!isEmptyValue(extendedSteps) && (
            <PageRow space={verticalSpaceValue}>
              <ExtendedStepsContainer data={extendedSteps} />
            </PageRow>
          )}

          {!isEmptyValue(faq) && (
            <PageRow space={verticalSpaceValue}>
              <FaqContainer data={faq} />
            </PageRow>
          )}
        </div>
      </PageFixer>
    </>
  );
};
