import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames/bind';
import { SmartInput } from '@beef/legacy-ui-kit';
import { ReloadIcon } from '@beef/ui-kit';

import { transformRequest } from 'utils/globalAjaxHandler';

import styles from './Captcha.pcss';

const cx = classNames.bind(styles);

class BISCaptcha extends Component {
  inputId = {};

  constructor(props) {
    super(props);
    this.state = {
      pic: null,
      captcha: '',
      captchaKey: '',
    };
    this.fetchCaptcha = this.fetchCaptcha.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  componentDidMount() {
    const { onMount } = this.props;
    this.fetchCaptcha();
    if (onMount) onMount(this);
  }

  getValue() {
    const { captcha } = this.state;
    return captcha;
  }

  setValue(captcha) {
    this.setState({ captcha });
  }

  handleChange = (captcha) => {
    const { onChange } = this.props;
    this.setState({ captcha });
    if (onChange) {
      onChange({ captcha, captchaKey: this.state.captchaKey });
    }
  };

  fetchCaptcha() {
    if (this.props.captchaKey) {
      const { captchaKey, width, height } = this.props;
      const captchaSrc = `/captcha.ashx?w=${width}&h=${height}&key=${captchaKey}&r=${Math.random()}`;
      this.setState({ pic: captchaSrc });
    } else {
      const url = `${this.props.host}/captcha.ashx?w=140&type=base64&h=30${new Date().getTime()}`;
      axios.get(url, { transformRequest, withCredentials: true }).then((response) => {
        if (response.data && response.data.key && response.data.captcha) {
          this.setState({
            captchaKey: response.data.key,
            pic: response.data.captcha,
          });
        }
      });
    }
  }

  handleReset = () => {
    this.props.onReloadClick?.();
    this.fetchCaptcha();
    if (this.props.onReset) {
      this.props.onReset();
    }
  };

  render() {
    const { name, onBlur, onFocus, status, msg, inputId, className, onClick } = this.props;
    const { captcha } = this.state;

    return (
      <div className={cx('bis', className)}>
        <img
          alt=""
          className={cx('imgBis', className)}
          src={`data:image/png;base64,${this.state.pic}`}
        />
        <input
          id="captchaKey"
          name="captchaKey"
          ref={(e) => (this.inputId.captchaKey = e)}
          type="hidden"
          value={this.state.captchaKey}
        />
        <button
          aria-label="Обновить капчу"
          className={cx('resetBis')}
          onClick={this.handleReset}
          type="button"
        >
          <ReloadIcon size="m" />
        </button>
        <SmartInput
          className={['absolute', cx('inputBis')]}
          inputId={inputId}
          msg={msg}
          name={name}
          onBlur={onBlur}
          onChange={this.handleChange}
          onClick={onClick}
          onFocus={onFocus}
          placeholder={this.props.placeholder}
          ref={(e) => (this.inputId.captcha = e)}
          status={status}
          value={captcha}
        />
      </div>
    );
  }
}

BISCaptcha.defaultProps = {
  width: 148,
  height: 39,
};

BISCaptcha.propTypes = {
  host: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  onReloadClick: PropTypes.func,
  status: PropTypes.string,
  msg: PropTypes.string,
  onMount: PropTypes.func,
  onReset: PropTypes.func,
  inputId: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  captchaKey: PropTypes.string,
};

export default BISCaptcha;
