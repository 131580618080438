import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import style from './DeviceItemLink.pcss';

const cx = classNames.bind(style);

interface DeviceItemLinkProps {
  children?: ReactNode;
  href?: string;
  onChangeLink: () => void;
}

const DeviceItemLink: React.FC<DeviceItemLinkProps> = ({ children, href = '#', onChangeLink }) => {
  const navigate = useNavigate();

  const redirect = () => {
    onChangeLink();
    navigate(href);
  };

  return (
    <div aria-label="link" className={cx('link')} onClick={redirect} role="link" tabIndex={0}>
      {children}
    </div>
  );
};

export default DeviceItemLink;
