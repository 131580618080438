// TODO: заменить импорт при выносе пакета!
import { TAdvantagesTile } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/TariffInfoTile/types';

import { TExtraTilesProps, TTariffInfo } from './types';
import { DEFAULT_INDEX, HIGH_OPTION_SOC, OPTION_SOC } from '../../../constants';

/** Функция определения подключенного/дефолтного аппера */
export const getDefaultCharacter = (tariffsInfo: TTariffInfo[]) => {
  /* Крайне важен порядок! tariff.isActive всегда в приоритете над tariff.isDefault */
  return (
    tariffsInfo.find((tariff) => tariff.isActive) ||
    tariffsInfo.find((tariff) => tariff.isDefault) ||
    tariffsInfo[DEFAULT_INDEX]
  );
};

/** Функция выборки данных для отрисовки доступных табов апперов. Из общего объекта выбирает нужные данные */
export const getAnimalsTabs = (tariffInfo: TTariffInfo[]) =>
  tariffInfo?.map(
    ({
      soc = '',
      name = '',
      imageSrc = '',
      optionSoc = '',
      chooseBtn = '',
      activeColor = '',
      highOptionSoc = '',
      tariffFeatures = [],
      highTariffFeatures = [],
    }) => ({
      soc,
      name,
      imageSrc,
      optionSoc,
      chooseBtn,
      activeColor,
      highOptionSoc,
      tariffFeatures,
      highTariffFeatures,
    }),
  );

/** Функция определения текущего выбранного аппера */
export const getChosenCharacter = (
  tariffInfo: TTariffInfo[],
  activeCharacter: string,
  isHighPreset = false,
) => {
  /** Определяем тип сока для поиска нужного варианта тарифа, для отображения выбранного юзером персонажа.
   * По-умолчанию вернется первый аппер из списка (Базя) */
  const typeSoc = isHighPreset ? HIGH_OPTION_SOC : OPTION_SOC;
  return (
    tariffInfo.find((tariff) => tariff[typeSoc] === activeCharacter) ?? tariffInfo[DEFAULT_INDEX]
  );
};

/** Маппер данных для доп. плиток TariffInfoTiles (партнерка, способность, суперспособность) */
export const getExtraTilesProps = ({
  onClick,
  tileInfo,
  isPowerType,
  isBeeIndent,
  isHighPreset,
  withoutIndent,
}: TExtraTilesProps): TAdvantagesTile => {
  /** Тип присета (isHighPreset) и флаг определения,
   *  является ли текущий тип плитки (isPowerType) Power-вариантом */
  const isHighPresetAndPowerTile = isPowerType && isHighPreset;

  /** Контентные данные для плитки ExtraTiles */
  const {
    tag,
    highTag,
    icons,
    highIcons,
    title,
    highTitle,
    buttonText,
    description,
    highDescription,
  } = tileInfo;

  /** Определяем текущий title для ExtraTiles,
   *  в зависимости от типа присета (isHighPreset) и текущей плитки (isPowerType) */
  const defineTitle = isHighPresetAndPowerTile ? highTitle : title;

  /** Определяем текущий description для ExtraTiles,
   *  в зависимости от типа присета (isHighPreset) и текущей плитки (isPowerType) */
  const defineDescription = isHighPresetAndPowerTile ? highDescription : description;

  /** Определяем текущий массив icons для ExtraTiles,
   *  в зависимости от типа присета (isHighPreset) и текущей плитки (isPowerType) */
  const defineIcons = isHighPresetAndPowerTile ? highIcons : icons;

  /** Определяем текущий tag для ExtraTiles,
   *  в зависимости от типа присета (isHighPreset) и текущей плитки (isPowerType) */
  const defineTag = isHighPresetAndPowerTile ? highTag : tag;

  /** Пропы для контентной части блока карточки тарифа */
  const headerProps = {
    tag: defineTag,
    icons: defineIcons,
    title: defineTitle,
    description: defineDescription,
  };

  /** Изображение блока карточки тарифа */
  const pictureProps = { isBeeIndent, withoutIndent };

  /** Кнопка блока карточки тарифа */
  const buttonProps = { onClick, buttonText };

  return { headerProps, pictureProps, buttonProps };
};
