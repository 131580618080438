import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// Utils
import { getMobileOS } from 'utils/detect-mobile';

/**
 * Портал, добавляет баблики для карточек. Находит их координаты,
 * решает куда добавить баблик по типу, добавляет span в слайдер и прокидывает
 * туду баблики с координатами;
 */
class CardBubbleHoc extends Component {
  constructor(props) {
    super(props);
    this.newSpan = document.createElement('span');
    this.state = { left: 0 };
  }

  componentDidMount() {
    this.scrollContainer = document.querySelector('.scrollBlock');

    if (this.scrollContainer) {
      this.scrollContainer.addEventListener('scroll', this.handleResize);
    }

    if (this.props.portal) {
      this.swipeCatalog = document.querySelector('#swipeCatalog');
      if (this.swipeCatalog) {
        this.swipeCatalog.appendChild(this.newSpan);
      }
    }

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentDidUpdate() {
    const { place, portal } = this.props;
    const renderDom = portal ? this.newSpan : this.nodeElement;
    const device = getMobileOS();

    const styleObj = {
      pointerEvents: 'all',
      position: 'absolute',
      left: this.state.left,
      zIndex: 4,
    };

    if (portal) {
      switch (place) {
        case 'top':
          styleObj.top = '105px';
          styleObj.transform = 'translate3d(0%, -100%, 0px)';
          break;
        case 'bottom':
          styleObj.bottom = device ? '60px' : '90px';
          styleObj.transform = 'translate3d(0%, 100%, 0px)';
          break;
      }
    } else {
      styleObj.left = '100%';

      switch (place) {
        case 'top':
          styleObj.top = '25px';
          styleObj.transform = 'translate3d(-100%, -100%, 0)';
          break;

        case 'bottom':
          styleObj.bottom = '20px';
          styleObj.transform = 'translate3d(-100%, 100%, 0)';
          break;
      }
    }

    ReactDOM.render(<div style={styleObj}>{this.props.children}</div>, renderDom);
  }

  handleResize = () => {
    const timer = setTimeout(() => {
      if (this.nodeElement) {
        const rect = this.nodeElement.getBoundingClientRect();
        const { left } = rect;
        this.setState({ left });
      }

      clearTimeout(timer);
    }, 0);
  };

  render() {
    return (
      <span
        ref={(node) => {
          this.nodeElement = node;
        }}
      />
    );
  }
}

CardBubbleHoc.defaultProps = {
  portal: false,
  place: 'top',
};

CardBubbleHoc.propTypes = {
  portal: PropTypes.bool,
  place: PropTypes.string,
};

export default CardBubbleHoc;
