// @ts-expect-error ошибочно НЕ импортируется FaqPropsInterface из @beef/smart-kit
import type { FaqPropsInterface } from '@beef/smart-kit/components/FAQ/types';

import { ymPushParams } from 'utils/ym';
import { DEFAULT_INDEX } from 'pages/TariffsCatalog2021/constants';

export type TOnClick = {
  panelId: string;
};

type TUseSelectFAQContent = {
  authStatus: 'az' | 'nz';
  faq: FaqPropsInterface;
};

/** Хук подготовки данных FAQ в каталоге тарифов с аналитикой.
 * ВНИМАНИЕ! Хук позволяет корректно работать со стандартным FAQ БЕЗ дополнительных вкладок (согласовано с бизнесом для упрощения кода).
 * Если в будущем потребуется расширить функционал, можно взять логику из useSelectFAQContent */
export const useFaqAnalyticsProps = ({ faq, authStatus }: TUseSelectFAQContent) => {
  /* Функция выборки заголовка (title) активного блока в FAQ */
  const getTitle = (panelId: string) => {
    const { tabs } = faq.data?.[DEFAULT_INDEX] || {};
    return tabs?.find((item: Record<string, any>) => item.id === panelId)?.title;
  };

  /** Функция-обработчик кликов на пункты FAQ из @beef.
   * При клике по пункту FAQ, возвращает panelId, на основании которого производится выборка заголовка через ф-цию getTitle и
   * последующая отправка данных в метрику (только на разворачивание списка)  */
  const onClick = ({ panelId }: TOnClick) => {
    /* Получаем title активного пункта FAQ */
    const title = getTitle(panelId);

    /* Оправка метрики по клику */
    ymPushParams({
      tariff_catalog_mobile: {
        auth_status: authStatus,
        block_faq: 'click',
        faq_title: title,
      },
    });
  };

  /* Параметры для хука useOnScreenAnalytics в компоненте FAQContainer */
  const payloadView = {
    tariff_catalog_mobil: {
      auth_status: authStatus,
      block_faq: 'view',
    },
  };

  return { content: faq, onClick, payloadView };
};
