import { IPartnerServiceCardState } from '../../types';

export const selectAnalytics = (state: IPartnerServiceCardState) => state.analytics;
export const selectFaqClickState = (state: IPartnerServiceCardState) =>
  selectAnalytics(state).faqClickState;

export const selectMeta = (state: IPartnerServiceCardState) => state.meta;
export const selectHasMounted = (state: IPartnerServiceCardState) => selectMeta(state).hasMounted;

export * from './selectors';
