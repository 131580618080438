import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Bubble, Button, Emoji, Heading, Link } from '@beef/legacy-ui-kit';

import { pushBannerViewEvent, pushInfoBannerEvent } from 'utils/ga';

import styles from './index.pcss';
import BannerDeprecated from '../index';

const cx = classNames.bind(styles);

class InfoBanner extends Component {
  componentDidMount() {
    const { data, index } = this.props;
    const eventKey = `banner_${data.id}`;

    if (localStorage && localStorage.getItem(eventKey) !== 'pushed') {
      pushBannerViewEvent({ index, ...data });
      localStorage.setItem(eventKey, 'pushed');
    }
  }

  handleBannerClick = (event) => {
    const { clickable } = this.props.data;

    if (clickable) {
      event.stopPropagation();
      this.handleClick();
    }
  };

  handleClick = () => {
    const { title, link } = this.props.data;
    pushInfoBannerEvent('click', title, link);

    if (link) {
      window.location.href = link;
    }
  };

  renderButton = () => {
    const { link, buttonText, buttonStyle, buttonLink } = this.props.data;

    if (!(buttonLink || link) || !buttonText) return null;

    return (
      <div className={cx('button')}>
        <Button
          className={[cx('button'), buttonStyle]}
          href={buttonLink || link}
          onClick={this.handleClick}
          transformer
        >
          {buttonText}
        </Button>
      </div>
    );
  };

  renderBubble = () => {
    const { bubble, blockPosition } = this.props.data;

    if (!bubble) return null;

    return (
      <Bubble tail={blockPosition === 'left' ? 'leftBottom' : 'rightBottom'}>
        <div className={cx('bubbleContent')}>
          {bubble.icon ?
            <div className={cx('bubbleIcon')}>
              <Emoji name={bubble.icon} />
            </div>
          : null}
          <div className={cx('bubbleText')} dangerouslySetInnerHTML={{ __html: bubble.text }} />
        </div>
      </Bubble>
    );
  };

  render() {
    const { className, children } = this.props;
    const {
      link,
      title,
      description,
      clickable,
      verticalAlign,
      blockPosition,
      textStyle,
      bubble,
      stretchVideo,
      descriptionClass,
      cssClass,
    } = this.props.data;

    return (
      <BannerDeprecated data={this.props.data}>
        <div
          className={cx(className, 'wrapper', { clickable, stretchVideo })}
          onClickCapture={this.handleBannerClick}
        >
          <div className={cx('container', verticalAlign, blockPosition, !!cssClass && cssClass)}>
            <div className={cx('information', textStyle, !link && 'eventless')}>
              {this.renderBubble()}
              <Heading level={2} tagName="h4">
                <Link
                  className={[cx('title', bubble && 'hasBubble'), textStyle, 'hideVisitedColor']}
                  href={link}
                  notPseudo
                  onClick={this.handleClick}
                >
                  <span className={cx(textStyle)} dangerouslySetInnerHTML={{ __html: title }} />
                </Link>
              </Heading>
              {descriptionClass && (
                <div
                  className={cx('description', descriptionClass)}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </div>
            {children}
            {this.renderButton()}
          </div>
        </div>
      </BannerDeprecated>
    );
  }
}

InfoBanner.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  index: PropTypes.number,
};

export default InfoBanner;
