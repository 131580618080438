import { shallowEqual } from '@beef/utils';

import { onReloadWithCrash } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/utils/onReloadWithCrash';

import { EUserStoryType } from '../../../../store/slices/stepperSlice/types';
import { useThrowAsyncError } from '../../../../hooks/useThrowAsyncError';
import { CALLBACK_CASE_TILE_SUPPORT } from '../../../../constants';
import { fetchSubmitCallbackRequest } from '../../../../api/fetchSubmitCallbackRequest';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { useFetchPreChangesTariff } from './useFetchPreChangesTariff';
import { useStepNavigation } from '../../../../hooks/useStepNavigation';
import { useIsLoadingPresetsPrice } from '../../../../hooks/useIsLoadingPresetsPrice';
import { goToFinalPageStep } from '../../../../store/slices/stepperSlice';

/** Хук подготовки пропов для компонента PreChangesTariffContainer */
export const usePreparePreChangeTariff = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();
  /* Номер телефона абонента */
  const phoneNumber = useAppSelector((state) => state.common.userInfo?.activeCTN) ?? '';
  const caseTile = CALLBACK_CASE_TILE_SUPPORT;
  /* Получаем функцию перехода к следующему шагу через хук useStepNavigation */
  const { nextStep, userType } = useStepNavigation();

  /* Все изменения по текущему тарифу */
  const data = useFetchPreChangesTariff();
  const { data: info, isPreChangeCallback, errors } = data || {};

  /* Объединяем changes и informationConflicts в один массив (с бека получать уже приведенную структуру отказались) */
  const changes = info ? Object.values(info)?.flat(1) : [];

  /* Получение статуса загрузки изменений по тарифу через общий хук useIsLoadingPresetsPrice */
  const showSkeleton = useIsLoadingPresetsPrice();

  /* Текст кнопки компонента PreChangesButton */
  const { pagePreChangeButton = '', pagePreChangeCallbackButton = '' } = useAppSelector(
    (state) => ({
      pagePreChangeButton: state.common.content?.pagePreChangeButton,
      pagePreChangeCallbackButton: state.common.content?.pagePreChangeCallbackButton,
    }),
    shallowEqual,
  );

  /* Подключен тариф не ап с конвергенцией */
  const isArchivedNoTariffUpWithtFttb =
    userType === EUserStoryType.tariffUpTransitionFromArchiveTariff;

  /* Условия отправки калбека на пречеке */
  const isSendPreChangeCallback = isPreChangeCallback || isArchivedNoTariffUpWithtFttb;

  const btnText = isSendPreChangeCallback ? pagePreChangeCallbackButton : pagePreChangeButton;

  /* Обработчик для кнопки PreChanges (отправка калбека, переход на финальную страницу "Заявка отправлена") */
  const onCallbackSent = () => {
    dispatch(fetchSubmitCallbackRequest({ phoneNumber, caseTile }))
      .unwrap()
      .then(() => dispatch(goToFinalPageStep()))
      .catch((e: Error) => throwAsyncError(e));
  };
  /* Обработчик для кнопки PreChanges (переход к следующему этапу) */
  const handleClick = () => nextStep();

  const onClick = isSendPreChangeCallback ? onCallbackSent : handleClick;

  /* Общее пропы для компонента PreChangesButton */
  const btnProps = { btnText, onClick };

  /* Пропы для компонента PreChangesInfo */
  const preChangesInfoProps = { changes, btnProps };

  /* Подготовка пропов для компонента PreChangesConflicts */
  const { description, title, icon, button, buttonExtra } = errors?.terminalConflict || {};
  const { link, text } = button || {};
  const { link: extraLink, text: extraText } = buttonExtra || {};
  const contentProps = { description, title };
  const pictureProps = { image: icon };

  // eslint-disable-next-line no-return-assign
  const getOnclick = (v?: string) => (v ? () => (window.location.href = v) : onReloadWithCrash);

  const buttonProps = {
    btnText: text,
    onClick: getOnclick(link),
    extraBtnText: extraText,
    extraOnClick: getOnclick(extraLink),
  };

  /* Пропы для компонента PreChangesConflicts */
  const preChangesConflictProps =
    errors?.terminalConflict ? { contentProps, pictureProps, buttonProps } : null;

  return { showSkeleton, preChangesInfoProps, preChangesConflictProps };
};
