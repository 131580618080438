import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { HowToWatch } from 'pages/FTTB';
import Picture from 'pages-components/Picture';

import styles from './Sections.pcss';

const cx = classNames.bind(styles);

const Section = ({
  section: {
    imageUrl,
    title,
    alias,
    options,
    description,
    isOptionsColumn,
    howToWatchLink,
    howToWatchPopupTexts,
  },
}) => {
  const isTvDevice = alias === 'tvDevice';
  return (
    <div className={cx('sectionWrapper')} key={alias}>
      <div className={cx('section', alias)}>
        {isTvDevice && (
          <div
            className={cx('title', 'tvDeviceTitle', 'mobileOnly')}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <img alt="" className={cx('image')} src={imageUrl} />
        <div className={cx('info')}>
          <div className={cx('title', 'headerTitle', { desktopOnly: isTvDevice })}>{title}</div>
          <div className={cx('options', { optionsColumn: isOptionsColumn })}>
            {options.map((option, index) => (
              <div className={cx('option')} key={index}>
                {option.imgUrl && (
                  <div className={cx('optionIconWrap')}>
                    <Picture className={cx('optionIcon')} src={option.imgUrl} />
                  </div>
                )}
                {option.title && (
                  <div
                    className={cx('title', 'optionTitle')}
                    dangerouslySetInnerHTML={{ __html: option.title }}
                  />
                )}
                {option.description && (
                  <div
                    className={cx('description')}
                    dangerouslySetInnerHTML={{ __html: option.description }}
                  />
                )}
              </div>
            ))}
          </div>
          <div className={cx('description', 'sectionDesription')}>
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          {alias === 'tv' && (
            <HowToWatch linkText={howToWatchLink} popupTexts={howToWatchPopupTexts} />
          )}
        </div>
      </div>
    </div>
  );
};

export const Sections = ({ mobile, sections }) => (
  <div className={cx('sectionsWrapper', { isMobile: mobile })}>
    {sections.map((section, index) => (
      <Section key={index} section={section} />
    ))}
  </div>
);

Sections.propTypes = {
  mobile: PropTypes.bool,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
};
