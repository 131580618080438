import React, { FC } from 'react';

// Components
import { UserBlock } from '../UserBlock';
import { DescriptionBlock } from '../DescriptionBlock';
import { DiscountBlock } from '../DiscountBlock';
// Types
import { InfoBlockProps } from './types';

export const InfoBlock: FC<InfoBlockProps> = ({
  userNumber,
  userAddress,
  phoneTitle,
  description,
  addressTitle,
  showUserBlock,
  colorDescription,
  discountDescription,
  discountText,
}) => (
  <div>
    {discountDescription && !showUserBlock && (
      <DiscountBlock discountDescription={discountDescription} />
    )}
    {showUserBlock && <UserBlock {...{ userNumber, userAddress, phoneTitle, addressTitle }} />}
    {!showUserBlock && (
      <DescriptionBlock
        colorDescription={colorDescription}
        description={description}
        discountText={discountText}
      />
    )}
  </div>
);
