import React, { FC } from 'react';
import { ActionBar, Button } from '@beef/ui-kit';

export type TModalActionBar = {
  btnText: string;
  onClick: () => void;
};
/** Китовый компонент с кнопкой ("Понятно") для Modal */
export const ModalActionBar: FC<TModalActionBar> = ({ onClick, btnText }) => (
  <ActionBar size="m">
    <Button onClick={onClick} size="m">
      {btnText}
    </Button>
  </ActionBar>
);
