import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { Button } from '@beef/ui-kit';

import { getTotalHits } from 'pages/SearchResultsPage/store/selectors';
import { declOfNum } from 'pages/SearchResultsPage/utils/declOfNum';
import { textFormsProducts } from 'pages/SearchResultsPage/utils/constants';
import { TEMPLATE_BTN_FILTERS, TEXT_BTN_FILTERS } from 'pages/SearchResultsPage/store/constants';
import { templateParser } from 'utils/format-string';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const formatProductLabel = (count) => {
  const index = declOfNum(count);

  return templateParser(TEMPLATE_BTN_FILTERS, {
    action: TEXT_BTN_FILTERS.show,
    count,
    textProducts: textFormsProducts[index],
  });
};

export const _SidebarFooterComponent = ({ totalCount, onClick, isMainFiltersStep }) => (
  <div className={cx('component')}>
    <Button fullWidth onClick={onClick} role="button" size="l" tabIndex={0} variant="main">
      {isMainFiltersStep ? formatProductLabel(totalCount) : TEXT_BTN_FILTERS.applyFilters}
    </Button>
  </div>
);

_SidebarFooterComponent.propTypes = {
  totalCount: PropTypes.number,
  onClick: PropTypes.func,
  isMainFiltersStep: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  totalCount: getTotalHits(state),
});

export const SidebarFooter = connect(mapStateToProps)(_SidebarFooterComponent);
