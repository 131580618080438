import { YM_ESIM_STEP_PARAMS, eSim, eSimStep } from 'utils/analytics/zamenaNaEsim';

import { ymPushParams } from './ym';

const STEPS_CONFIG = {
  initial: 'initial',
  error: 'error',
  addInfo: 'addInfo',
  requestForm: 'requestForm',
  success: 'success',
};

const POLLING_STATUS = {
  error: 'Error',
  progress: 'InProcess',
  success: 'Success',
};

const ESIM_POLLING_STATUS = {
  error: 'ERROR',
  progress: 'IN_PROGRESS',
  success: 'SUCCESS',
};

const defineErrorsCode = (errorsCodeFromQP, code) => {
  if (errorsCodeFromQP) {
    return !!errorsCodeFromQP.find((errorCode) => errorCode === code);
  }

  return false;
};

/**
 * @desc: вычисляет текущий шаг для услуги замены сим на есим
 * * и замены сим-карты
 * @returns {string}
 */
const getCurrentStep = (data) => {
  const defaultStep = STEPS_CONFIG.initial;
  const errorsCode = data.errorsCode ?? data?.content?.errorsCode;

  if (typeof window !== 'undefined' && window?.location?.search) {
    const urlParams = new URLSearchParams(window.location.search);
    const esiaParam = urlParams.get('esia');
    const addInfoParam = urlParams.get('info');
    const statusParam = urlParams.get('status');
    const codeParam = urlParams.get('code');

    if (esiaParam === 'ok') {
      return STEPS_CONFIG.requestForm;
    }

    if (addInfoParam === 'ok') {
      return STEPS_CONFIG.addInfo;
    }

    const isCodeParamError = statusParam === 'error';

    if (errorsCode && (isCodeParamError || esiaParam === 'error')) {
      const combinedErrorsCode = Object.keys(errorsCode).reduce(
        (acc, key) => (Array.isArray(errorsCode[key]) ? acc.concat(errorsCode[key]) : acc),
        [],
      );

      if (
        esiaParam === 'error' ||
        (isCodeParamError && defineErrorsCode(combinedErrorsCode, codeParam))
      ) {
        return STEPS_CONFIG.error;
      }
    }
  }

  return defaultStep;
};

const getErrorDetails = ({ errorsCode }) => {
  let res = 'defaultError';

  if (!errorsCode) {
    res = 'defaultError';
  }

  if (typeof window !== 'undefined' && window?.location?.search) {
    const urlParams = new URLSearchParams(window.location.search);
    const statusParam = urlParams.get('status');
    const codeParam = urlParams.get('code');
    const isCodeParamError = statusParam === 'error';

    ymPushParams({
      [eSim]: { [eSimStep]: { [YM_ESIM_STEP_PARAMS.ERROR_ESIM]: codeParam } },
    });

    if (errorsCode) {
      const { passportCodes, noEnoughInfoCodes, unconfirmedCodes } = errorsCode;

      if (isCodeParamError && defineErrorsCode(passportCodes, codeParam)) {
        res = 'passportEsiaError';
      }

      if (isCodeParamError && defineErrorsCode(unconfirmedCodes, codeParam)) {
        res = 'profileNotConfirmedEsiaError';
      }

      if (isCodeParamError && defineErrorsCode(noEnoughInfoCodes, codeParam)) {
        res = 'noInfoEsiaError';
      }
    }
  }

  return res;
};

const FIELDS_CONFIG = [
  {
    name: 'ctn',
    label: 'Восстанавливаемый номер',
    mask: '+7 ___ ___ __ __',
  },
  {
    name: 'lastName',
    label: 'Фамилия',
  },
  {
    name: 'firstName',
    label: 'Имя',
  },
  {
    name: 'secondName',
    label: 'Отчество',
  },
  {
    label: 'Документ',
    infoText: 'Паспорт РФ',
    type: 'info',
  },
  {
    label: 'Серия и номер',
    group: [
      { name: 'documentSeries', type: 'number', width: 1, mask: '__ __' },
      { name: 'documentNumber', type: 'number', width: 3, mask: '______' },
    ],
  },
  {
    name: 'contactPhone',
    label: 'Контактный телефон',
    mask: '+7 ___ ___ __ __',
  },
];

export {
  ESIM_POLLING_STATUS,
  FIELDS_CONFIG,
  POLLING_STATUS,
  STEPS_CONFIG,
  getCurrentStep,
  getErrorDetails,
};
