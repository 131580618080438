import React from 'react';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { connect } from 'react-redux';
import { CollapseContainer } from '@beef/smart-kit';

import { LoadingWrap } from 'pages-components/LoadingWrap';

import { selectStoriesLoading } from '../../../store/stories';
import { selectContent } from '../../../store/selectors';
import { STORIES_LIST_ALIAS } from '../../../constants';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const StoriesListLayoutComponent = ({
  title,
  tabsNode,
  isLoading,
  limits = { mobile: 4, tablet: 6, desktop: 8 },
  children,
}) => (
  <>
    <div className={cx('anchor')} id={STORIES_LIST_ALIAS} />
    <Text size="size1-m">{title}</Text>
    {!isLoading && <div className={cx('tabs')}>{tabsNode}</div>}
    <LoadingWrap isLoading={isLoading} minHeight="200px">
      <CollapseContainer className={cx('grid')} limits={limits}>
        {children}
      </CollapseContainer>
    </LoadingWrap>
  </>
);

const mapStateToProps = (state) => ({
  title: selectContent(state).storiesList.title,
  isLoading: selectStoriesLoading(state),
});

export const StoriesListLayout = connect(mapStateToProps)(StoriesListLayoutComponent);
