import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';

// Utils
import { InputGroup, Label, SmartForm, SmartInput, Status } from '@beef/legacy-ui-kit';
import { Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { formatPhoneWithDefaultPrefix, unformatPhone } from 'utils/format-string';
import {
  checkBeelineNumber,
  getDefaultState,
  getUniqueProps,
  sendOwnerData,
  simReplaceValidators,
} from 'utils/simReplacement';

// Components
import ButtonWithPreloader from 'pages-components/ButtonWithPreloader';
import Captcha from 'pages/Feedback/components/Captcha/Captcha';

// Styles

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CAPTCHA_CONFIG = {
  templateName: 'simReplacementRequest',
  _captcha_key: 'sim-replacement-request',
};

class FormStatementStep extends PureComponent {
  state = {
    data: {
      ...getDefaultState(this.props.fields),
      captcha: '',
    },
    needCheckNumber: false,
    checkNumberFailed: false,
    pending: false,
    showCaptchaError: false,
    showCaptcha: false,
  };

  componentDidMount() {
    this.setCurrentCtn();
  }

  componentDidUpdate(prevProps, prevState) {
    const { needCheckNumber } = this.state;

    if (prevProps.currentCtn !== this.props.currentCtn) {
      this.setCurrentCtn();
    }

    if (needCheckNumber && prevState.needCheckNumber !== needCheckNumber) {
      this.checkNumber('ctn');
    }
  }

  inputHandler = (val, name) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: val,
      },
      needCheckNumber: name === 'ctn' ? false : this.state.needCheckNumber,
    });
  };

  blurHandler = (val, name) => {
    if (name === 'ctn' && this.form.state.inputs[name].status === 'ok') {
      this.setState({
        needCheckNumber: true,
      });
    }
  };

  setCurrentCtn = () => {
    const { currentCtn } = this.props;

    if (currentCtn) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          ctn: formatPhoneWithDefaultPrefix(currentCtn),
        },
      }));
    }
  };

  checkNumber = async (fieldName) => {
    const result = await checkBeelineNumber('/newsim/check/', this.state.data[fieldName]);

    this.setState({
      checkNumberFailed: !result,
    });
  };

  submitHandler = async () => {
    this.setState({ pending: true });

    const phoneNumbers = {
      ctn: unformatPhone(this.state.data.ctn),
      contactPhone: unformatPhone(this.state.data.contactPhone),
    };

    const formattedData = {
      ...this.state.data,
      ...phoneNumbers,
      ...CAPTCHA_CONFIG,
    };
    this.props.setUserFormData(formattedData);

    try {
      const result = await sendOwnerData('/sim-replace/check', formattedData);
      this.setState({
        pending: false,
        ...result,
      });

      if (!this.state.showCaptcha) {
        if (result.isOwner === false) {
          this.setState({
            pending: false,
            ...result,
          });

          result.isSucceeded ? this.props.setStep('addInfo') : this.props.setStep('error');
        } else {
          this.setState({
            pending: false,
            ...result,
          });

          result.isSucceeded ? this.props.setStep('success') : this.props.setStep('error');
        }
      }
    } catch (er) {
      this.props.setStep('error');
    }
  };

  render() {
    const { fields, buttonText, currentCtn, title, checkNumberFailedMessage } = this.props;

    return (
      <SmartForm
        name="claimForm"
        onSubmit={this.submitHandler}
        ref={(e) => {
          this.form = e;
        }}
        style={{ width: '100%', maxWidth: '550px' }}
        validator={simReplaceValidators}
      >
        <Heading className={cx('heading')} level={2}>
          {title}
        </Heading>
        <InputGroup>
          {fields?.map((formEl) => {
            const { initialValue, needDisabledOnCondition, ...inputProps } = formEl;
            return (
              <div className={cx('form-wrapper')} key={formEl.name}>
                <Label htmlFor={formEl.name}>
                  <span dangerouslySetInnerHTML={{ __html: formEl.label }} />
                </Label>
                <SmartInput
                  {...inputProps}
                  {...getUniqueProps(formEl.name)}
                  disabled={needDisabledOnCondition && !!currentCtn}
                  id={formEl.name}
                  inputId={formEl.name}
                  onBlur={(val) => {
                    this.blurHandler(val, formEl.name);
                  }}
                  onChange={(val) => {
                    this.inputHandler(val, formEl.name);
                  }}
                  size="big"
                  value={this.state.data[formEl.name]}
                />
                {this.state.checkNumberFailed && formEl.name === 'ctn' && (
                  <Status className={['error', cx('form-check')]} emoji="statusFail">
                    {checkNumberFailedMessage}
                  </Status>
                )}
              </div>
            );
          })}
        </InputGroup>

        <>
          {this.state.showCaptcha && (
            <section className={cx('wrap-captcha')}>
              <Captcha
                captchaKey={CAPTCHA_CONFIG._captcha_key}
                disabled={this.state.pending}
                height={51}
                inputId="captcha"
                name="captcha"
                onChange={(val) => {
                  this.inputHandler(val, 'captcha');
                }}
                onFocus={() => this.setState({ showCaptchaError: false })}
                refreshCaptcha
                size="big"
                status={this.state.showCaptchaError ? 'fail' : 'ok'}
                width={150}
              />
            </section>
          )}
        </>

        <ButtonWithPreloader
          className="big"
          disabled={this.state.checkNumberFailed}
          isPreloader={this.state.pending}
          type="submit"
          wide
        >
          {buttonText}
        </ButtonWithPreloader>
      </SmartForm>
    );
  }
}

const mapStateToProps = (state) => {
  const currentCtn = pathOr('', ['external', 'currentCtn', 'ctn'], state);

  return {
    currentCtn: currentCtn[0] === '9' ? currentCtn : null,
  };
};

FormStatementStep.propTypes = {
  fields: PropTypes.array,
  buttonText: PropTypes.string,
  currentCtn: PropTypes.string,
  checkUrl: PropTypes.string,
  sendDataUrl: PropTypes.string,
  setStep: PropTypes.func,
  checkOwnerUrl: PropTypes.string,
  title: PropTypes.string,
  checkNumberFailedMessage: PropTypes.string,
  setUserFormData: PropTypes.func,
};

export default connect(mapStateToProps)(FormStatementStep);
