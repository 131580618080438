import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CollapsedContent, Description, Descriptions } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './Questions.pcss';

const cx = classNames.bind(styles);

const renderComplexQuestion = (questions) => (
  <div className={cx('questionsWrapper')}>
    {questions.map((question, index) => (
      <CollapsedContent
        contentClass={cx('question')}
        indexed
        key={index}
        title={question.title}
        titleClass={cx('title')}
      >
        <span dangerouslySetInnerHTML={{ __html: question.text }} />
      </CollapsedContent>
    ))}
  </div>
);

export class Questions extends Component {
  renderDescriptions = () => {
    const { questionsPages } = this.props;

    return questionsPages.map((page, index) => {
      let pageComponent = null;

      if (page.type === 'complex') {
        pageComponent = renderComplexQuestion(page.questions);
      } else if (page.type === 'simple') {
        pageComponent = (
          <div className={cx('simpleQuestion')}>
            <span dangerouslySetInnerHTML={{ __html: page.text }} />
          </div>
        );
      }

      return (
        <Description key={index} title={page.title}>
          {pageComponent}
        </Description>
      );
    });
  };

  render() {
    const { className } = this.props;

    return <Descriptions className={className}>{this.renderDescriptions()}</Descriptions>;
  }
}

Questions.propTypes = {
  questionsPages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          text: PropTypes.string,
        }),
      ),
    }),
  ),
};
