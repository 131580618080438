import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import axios from 'axios';
import { Button, Link, Text } from '@beef/ui-kit';
import { ScrollContainer, Status } from '@beef/legacy-ui-kit';

import {
  benefitsShape,
  subscriptionShape,
} from 'pages/ProductCard/Tariff/containers/tariffPropTypes';
import {
  API_ENDPOINTS,
  SUBSCRIPTION_STATUSES,
  manageSubscriptionStatus,
} from 'utils/partnershipTariffHelper';
import { removeFromLocalStorage } from 'utils/localStorage';

import SubscriptionStatusText from './SubscriptionStatusText';
import { SubscriptionStatus } from './SubscriptionStatus';
import ParametersList from '../ParametersList';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Subscription = ({
  subscription: { content, data },
  benefits,
  isAuthenticated,
  isConnected,
}) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [pending, setPending] = useState(true);
  const [subscriptionDataStatus, setSubscriptionDataStatus] = useState(null);
  const hasSubscriptionPending = useRef(false);

  useEffect(() => {
    setPending(true);
    if (!isAuthenticated || !isConnected) {
      setPending(false);
      return;
    }
    const fetchSubscriptionData = async () => {
      try {
        const res = await axios.get(API_ENDPOINTS.GET_SUBSCRIPTION_DATA, {
          params: {
            redirectUrl: window.location.pathname,
          },
        });
        if (res?.data?.status) {
          // Показ лоадера подключения услуги в ЛК и карточке тарифа
          const isSubscriptionConnecting =
            SUBSCRIPTION_STATUSES.pending === manageSubscriptionStatus();
          const isAvaliable = SUBSCRIPTION_STATUSES.available === res?.data?.status;
          hasSubscriptionPending.current = isSubscriptionConnecting && isAvaliable;
          if (isSubscriptionConnecting && !isAvaliable) {
            removeFromLocalStorage('yaPlusSbscriptionStatus');
          }

          setSubscriptionData(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchSubscriptionData();
    setPending(false);
  }, [setSubscriptionData, isAuthenticated, isConnected]);

  useEffect(() => {
    if (subscriptionData?.status) {
      setSubscriptionDataStatus({ [subscriptionData?.status]: true });
    }
  }, [subscriptionData, setSubscriptionDataStatus]);

  if (
    pending ||
    (isAuthenticated && isConnected && (!subscriptionData || !subscriptionDataStatus))
  ) {
    return null;
  }

  const subscriptionNotAvailable = subscriptionDataStatus?.[SUBSCRIPTION_STATUSES.notAvailable];
  const subscriptionNotConnected =
    subscriptionDataStatus?.[SUBSCRIPTION_STATUSES.available] || subscriptionNotAvailable;
  const subscriptionConnected = subscriptionDataStatus?.[SUBSCRIPTION_STATUSES.connected];
  const subscriptionSuspended = subscriptionDataStatus?.[SUBSCRIPTION_STATUSES.suspended];
  const showActivationBtn =
    (subscriptionNotConnected && subscriptionData.activationRedirectUrl) ||
    subscriptionNotAvailable;
  const showSynchrionizationMessage =
    isAuthenticated &&
    subscriptionData?.statusSynchronizationText &&
    !subscriptionData?.isSubscriptionDisabled;

  return (
    <div>
      <div className={cx('row', 'header')}>
        <div className={cx('header__logo')}>
          <img alt="" src={data?.icon} />
        </div>
        {(!isAuthenticated || subscriptionNotConnected || (isAuthenticated && !isConnected)) && (
          <div className={cx('header__price')}>
            <Text size="size3-m">{data?.value}</Text>
          </div>
        )}
        {(subscriptionConnected || subscriptionSuspended) && (
          <div className={cx('header__aside')}>
            <SubscriptionStatusText
              account={subscriptionData.account}
              bonus={subscriptionData.bonus}
              connected={subscriptionConnected}
              subscriptionTexts={content?.subscriptionTexts}
              suspended={subscriptionSuspended}
            />
          </div>
        )}
      </div>

      {subscriptionSuspended && content?.subscriptionTexts?.suspendMessage && (
        <div className={cx('row')}>
          <Status className="error">
            <div className={cx('status-content')}>
              <Text color="red10" size="size6-r">
                {content?.subscriptionTexts?.suspendMessage}
              </Text>
            </div>
          </Status>
        </div>
      )}

      {subscriptionNotConnected && (
        <>
          {content?.subscriptionTexts?.remindActivationMessage && (
            <div className={cx('row')}>
              <Status>
                <div className={cx('status-content')}>
                  <Text size="size6-r">{content?.subscriptionTexts?.remindActivationMessage}</Text>
                </div>
              </Status>
            </div>
          )}
        </>
      )}
      {subscriptionDataStatus?.[SUBSCRIPTION_STATUSES.notAvailable] &&
        content?.subscriptionTexts?.notAvailableMessage && (
          <div className={cx('row')}>
            <Status className="attention">
              <div className={cx('status-content')}>
                <Text size="size6-r">{content.subscriptionTexts.notAvailableMessage}</Text>
              </div>
            </Status>
          </div>
        )}

      {showActivationBtn && (
        <div className={cx('row')}>
          {hasSubscriptionPending.current && (
            <SubscriptionStatus statusText={content?.subscriptionTexts?.preloaderText} />
          )}
          {!hasSubscriptionPending.current && subscriptionNotAvailable && (
            <Button disabled size="l" variant="main">
              {content?.subscriptionTexts?.buttonText}
            </Button>
          )}
          {!hasSubscriptionPending.current && !subscriptionNotAvailable && (
            <div
              className={cx({
                'subscribe-btn': !subscriptionDataStatus?.[SUBSCRIPTION_STATUSES.notAvailable],
              })}
            >
              <Link
                buttonSize="l"
                buttonVariant="main"
                href={subscriptionData.activationRedirectUrl}
                variant="button"
              >
                {content?.subscriptionTexts?.buttonText}
              </Link>
            </div>
          )}
        </div>
      )}
      {showSynchrionizationMessage && (
        <div className={cx('synchronization-status-container')}>
          <SubscriptionStatus size="38" statusText={subscriptionData.statusSynchronizationText} />
        </div>
      )}
      {subscriptionData?.isSubscriptionDisabled && content?.isSubscriptionDisabled && (
        <Status className={['error', cx('subscription-disabled')]}>
          {content.isSubscriptionDisabled.text}
          &nbsp;
          <a
            className={cx('subscription-disabled__link')}
            href={content.isSubscriptionDisabled.redirectLink}
          >
            {content.isSubscriptionDisabled.redirectText}
          </a>
        </Status>
      )}
      {(!isAuthenticated ||
        subscriptionConnected ||
        subscriptionSuspended ||
        (isAuthenticated && !isConnected)) && (
        <>
          {benefits?.length > 0 && (
            <div className={cx('row')}>
              <ScrollContainer handleShouldPlayDemo={() => {}}>
                <ParametersList itemFixedWidth itemWide parameters={benefits} />
              </ScrollContainer>
            </div>
          )}

          {content?.note && (
            <div className={cx('note')}>
              <div className={cx('note__icon')}>
                <img alt="" src={content.note.icon} />
              </div>
              <div className={cx('note__text')}>
                <Text size="size5-r-s">{content.note.text}</Text>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

Subscription.propTypes = {
  /* авторизован ли пользователь */
  isAuthenticated: PropTypes.bool,
  /* подключен ли тариф ли пользователь */
  isConnected: PropTypes.bool,
  /* список доп плюшек тарифа */
  benefits: PropTypes.arrayOf(benefitsShape),
  /* данные о подписке */
  subscription: subscriptionShape,
};

export default Subscription;
