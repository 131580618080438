import { ymPushParams } from '../../../../../utils/ym';

export const pushAnniversaryEvent = (payload) => ymPushParams({ '30years': payload });

const createSimpleEvent = (key) => () => pushAnniversaryEvent(key);

export const pushAudioPlay = createSimpleEvent('ringtone_preview_play');
export const pushAudioConnect = createSimpleEvent('ringtone_connect');
export const pushAudioConnectSuccess = createSimpleEvent('ringtone_request_accepted');
export const pushAudioConnectError = createSimpleEvent({
  error: {
    code: '',
    text: 'При поключении услуги произошла ошибка',
  },
});
export const pushStoryAction = (action, story) => {
  pushAnniversaryEvent({
    [action]: {
      name: story.name,
      storyID: story.id,
    },
  });
};
export const pushStoryVoteSuccess = (story) => {
  pushAnniversaryEvent({
    vote_accepted: {
      name: story.name,
      storyID: story.id,
    },
  });
};
export const pushStoryVoteError = createSimpleEvent({
  error: {
    code: '',
    text: 'При отправке голоса произошла ошибка',
  },
});
