/**
 * @desc: Функция проверяет, есть ли номер у пользователя в корзине.
 * @param boughtNumbers { string[] } номера в корзине.
 * @param currentNumber { string } номер для проверки.
 * @returns {boolean} */
export const isNumberBought = (boughtNumbers: string[], currentNumber: string): boolean => {
  if (!boughtNumbers?.length || !currentNumber) {
    return false;
  }

  return !!boughtNumbers.find((number) => number === currentNumber);
};
