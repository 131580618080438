import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { pathOr } from 'ramda';

import store from 'store';

const TariffConnectHOC = ({
  children,
  chosenNumber,
  onNumberChange,
  tariffData,
  isDownsellPending,
  tariffData: { connectButtonVersion, connectButton, mnpWidget, warningData },
}) =>
  cloneElement(children, {
    mnpPopupData: mnpWidget || {},
    newNumber: chosenNumber,
    onNumberChange,
    isPreloader: isDownsellPending,
    connectButtonVersion,
    connectButton,
    warningData: warningData && warningData.showInPopup && warningData,
    warningDataCard: warningData && warningData.showInCard && warningData,
    data: tariffData,
  });

TariffConnectHOC.propTypes = {
  chosenNumber: PropTypes.string,
  tariffData: PropTypes.shape({
    connectButtonVersion: PropTypes.string,
    connectButton: PropTypes.string,
    discountValue: PropTypes.number,
    mnpWidget: PropTypes.object,
  }),
  isDownsellPending: PropTypes.bool,
};

const mapStateToProps = ({ external }) => ({
  tariffData: pathOr({}, ['tariff', 'data'], external),
  isDownsellPending: pathOr(false, ['tariffConnectionPopup', 'isDownsellPending'], external),
});

const ConnectedComponent = connect(mapStateToProps, null)(TariffConnectHOC);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
