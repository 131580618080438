import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import Discount from 'pages-components/Discount';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Отрисовывает перечеркнутую цену, приепняется в коллаборации с BubbleTiles;
 * Появляется на downsell тарифах;
 * @param value - Значение в виде цены;
 * @param unit - Юнит цены;
 */
const StrikedOutValue = ({ value, unit, className, entityDesc }) => (
  <div className={cx('strikedOut', className)}>
    <div className={cx('wrap')}>
      <span
        className={cx('value')}
        dangerouslySetInnerHTML={{ __html: `${value} ${unit || ''}` }}
      />
      {entityDesc && <Discount.Label className={cx('discountLabel')} label={entityDesc} />}
    </div>
  </div>
);

StrikedOutValue.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  unit: PropTypes.string,
  entityDesc: PropTypes.string,
};

export default StrikedOutValue;
