import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RangeSlider } from '@beef/ui-kit';
import PropTypes from 'prop-types';

import { keyUpCodes } from 'pages/ProductCard/constants';
import {
  disableSpecialOffer,
  setCurrentGbSlideIndex,
  setPresetParams,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff/actions';
import DispatchContext from 'pages/ProductCard/Tariff/containers/ConstructorTariff/constants';

const INFINITY = 100500;
const COMMON_INT_VALUE = 1;

const Slider = ({ slide, getTariffInfo, isNotDefaultPreset }) => {
  const _dispatch = useContext(DispatchContext);
  const [currentIndex, setCurrentIndex] = useState(slide.defaultIndex);

  useEffect(() => {
    if (!isNotDefaultPreset) {
      if (slide.type === 'Gbytes') {
        _dispatch(setCurrentGbSlideIndex(slide.defaultIndex));
      }
      setCurrentIndex(slide.defaultIndex);
    }
  }, [isNotDefaultPreset]);

  useEffect(() => {
    /** Обработка сценария с одним элементом в Steps */
    const index = slide.steps.length === COMMON_INT_VALUE ? COMMON_INT_VALUE : slide.defaultIndex;
    setCurrentIndex(index);
  }, [slide]);

  const onMouseUp = useCallback(
    (e) => {
      if (currentIndex !== +e.target.value) {
        setCurrentIndex(+e.target.value);
        _dispatch(disableSpecialOffer());
        if (slide.type === 'Gbytes') {
          _dispatch(setCurrentGbSlideIndex(+e.target.value));
        }
        return getTariffInfo({
          [slide.type.toLowerCase()]: {
            value: slide.steps[e.target.value].value,
            unit: slide.unit,
          },
        });
      }
    },
    [slide, getTariffInfo],
  );

  const onChange = useCallback(
    (value) => {
      _dispatch(
        setPresetParams({
          [slide.type.toLowerCase()]: {
            value,
            unit: slide.unit,
          },
        }),
      );
    },
    [slide],
  );

  const onKeyUpSlideAvailable = useCallback(
    (e) => {
      if (keyUpCodes.includes(e.key)) {
        return onMouseUp(e);
      }
      return null;
    },
    [onMouseUp],
  );

  const steps = slide.steps.map((item) => item?.value);

  return (
    <RangeSlider
      extraAttributes={{
        onMouseUp,
        onTouchEnd: onMouseUp,
        onKeyUp: onKeyUpSlideAvailable,
      }}
      infinity={slide.steps[currentIndex] === INFINITY}
      onChange={onChange}
      {...{ ...slide, steps }}
      defaultIndex={currentIndex}
    />
  );
};

Slider.defaultProps = {
  getTariffInfo: () => {},
};

Slider.propTypes = {
  slide: PropTypes.shape({
    type: PropTypes.string.isRequired,
    unit: PropTypes.string,
    steps: PropTypes.array,
    defaultIndex: PropTypes.number,
  }),
  getTariffInfo: PropTypes.func,
  isNotDefaultPreset: PropTypes.bool,
};

export default Slider;
