import React, { FC } from 'react';
import classNames from 'classnames/bind';
// Components
import { Preloader, Text } from '@beef/ui-kit';

import { TemporaryDiscount } from 'pages/ProductCard/Tariff/components/Check/SubscriptionFeeBlock/TemporaryDiscount';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type SubscriptionFeeBlockProps = {
  activeColor: string;
  fttbPresetConnectedDiscount?: string;
  isChangeTariff: boolean;
  isCheckedConvergence: boolean;
  monthlyPeriod: string;
  price: number;
  priceWithDiscount: number;
  temporaryDiscount?: {
    discountPercents: string;
    text: string;
  };
  title: string;
  unit: string;
};

const SubscriptionFeeBlock: FC<SubscriptionFeeBlockProps> = ({
  title,
  price,
  priceWithDiscount,
  unit,
  monthlyPeriod,
  activeColor,
  temporaryDiscount,
  isChangeTariff,
  isCheckedConvergence,
  fttbPresetConnectedDiscount,
}: SubscriptionFeeBlockProps) => {
  // TODO: разнести компонент на части
  const showAuthDiscount = temporaryDiscount && isChangeTariff;
  const headingPrice =
    (temporaryDiscount && isChangeTariff) || priceWithDiscount ? priceWithDiscount || 0 : price;

  return (
    <div className={cx('container')}>
      <div className={cx('base')}>
        <div className={cx('base__title')}>
          <Text size="size4-m">{title}</Text>
        </div>
        {isCheckedConvergence ?
          <div className={cx('price')}>
            <div className={cx('count')}>
              <Text size="size1-m">{`${headingPrice} ${unit}`}</Text>
            </div>
            <div className={cx('discount')}>
              {(showAuthDiscount || priceWithDiscount) && (
                <Text color="grey40" isCrossedOut size="size5-r-s">
                  {`${price} ${unit}`}
                </Text>
              )}
              <Text color="grey60" size="size5-r-s">
                {monthlyPeriod}
              </Text>
            </div>
          </div>
        : <div className={cx('loader')}>
            <Preloader color="dark" size="m" />
          </div>
        }
      </div>
      {fttbPresetConnectedDiscount && (
        <div className={cx('fttb-discount')}>
          <Text size="size5-r-s">{fttbPresetConnectedDiscount}</Text>
        </div>
      )}
      {showAuthDiscount && (
        <TemporaryDiscount
          activeColor={activeColor}
          discountPercents={temporaryDiscount?.discountPercents}
          text={temporaryDiscount?.text}
        />
      )}
    </div>
  );
};

export { SubscriptionFeeBlock };
