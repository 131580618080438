const formatAvailablePackages = (packages) => {
  if (!packages) {
    return null;
  }

  return {
    ...packages,
    groups: packages.groups.map((group) => ({
      ...group,
      parameters: group.parameters.map((param) => {
        if (param.priceValue && !Number.isNaN(param.priceValue)) {
          return {
            ...param,
            priceValue: Number(param.priceValue.toString().replace(/,/g, '.')),
          };
        }
        return param;
      }),
    })),
  };
};

export { formatAvailablePackages };
