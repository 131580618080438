import React, { useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { Map, YMaps } from 'react-yandex-maps';
import { Modal, Text } from '@beef/ui-kit';

import styles from './styles.pcss';
import PlacemarkCircle from '../Map/PlacemarkCircle';
import { createLayout } from './utils';

const cx = classnames.bind(styles);

export const MapBaseStations = ({ mapController, points, params }) => {
  const [mapReady, setMapReady] = useState(false);
  const [activePoint, setActivePoint] = useState(null);
  const pointLayoutRef = useRef(null);

  const handleMapLoad = () => {
    mapController.init(() => {});
    pointLayoutRef.current = createLayout();
    setMapReady(true);
  };

  return (
    <>
      {typeof activePoint === 'number' && (
        <Modal
          headerBarConfig={{ headerText: points[activePoint].name }}
          isOpen
          onClose={() => setActivePoint(null)}
          size="m"
        >
          <Text size="size4-r">{points[activePoint].text}</Text>
        </Modal>
      )}
      <YMaps>
        <Map
          className={cx('map')}
          defaultState={{
            center: [params.lat, params.lon],
            zoom: params.zoom || 12,
          }}
          instanceRef={mapController.setMapInstance}
          onLoad={handleMapLoad}
          options={{
            yandexMapDisablePoiInteractivity: true,
            suppressMapOpenBlock: true,
            avoidFractionalZoom: true,
            minZoom: 4,
            maxZoom: 14,
          }}
        >
          {mapReady &&
            points.map((point, index) => (
              <PlacemarkCircle
                iconLayout={pointLayoutRef.current}
                key={index}
                onClick={() => setActivePoint(index)}
                position={[point.lat, point.lon]}
                radius={18}
                zIndex={100}
              />
            ))}
        </Map>
      </YMaps>
    </>
  );
};
