import React, { FC } from 'react';
import { Button } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { AnimatePresence, motion } from 'framer-motion';

import { useIsMyTariff } from 'constructorV1/hooks/useIsMyTariff';

import { useBillButton } from './hooks/useBillButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TBillButton = {
  btnText?: string;
  isLoading: boolean;
};

/** Варианты анимации для компонента BillButton */
const variantsBillButton = {
  hidden: {
    height: 0,
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
};

/** Компонент кнопки для малого чека ЕК.
 * Пустой пробел используется для фикса краша компонента Button (carnica), при отсутствии children */
export const BillButton: FC<TBillButton> = ({ btnText = ' ', isLoading }) => {
  /** Результирующая функция-обработчик клика по кнопке чека ЕК */
  const handleClick = useBillButton();
  /** Флаг определения "мой тариф" */
  const isMyTariff = useIsMyTariff();

  return (
    <AnimatePresence initial={false}>
      {btnText && !isMyTariff && (
        <motion.div
          animate="visible"
          className={cx('wrapper')}
          exit="exit"
          initial="hidden"
          variants={variantsBillButton}
        >
          <Button
            loading={isLoading}
            onClick={handleClick}
            size="l"
            type="button"
            view="primary"
            width="full"
          >
            {btnText}
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
