import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { InfoIcon, Text, Tooltip } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type THeader = {
  title?: string;
  tooltipText?: string;
};

/** Заголовок группы опций в total-чеке. Содержит title и tooltip */
export const Header = memo<THeader>(({ title, tooltipText }) => (
  <div className={cx('wrapper')}>
    {title && <Text size="size5-m">{title}</Text>}
    {tooltipText && (
      <Tooltip message={tooltipText} position="bottom">
        <InfoIcon />
      </Tooltip>
    )}
  </div>
));
