import React, { Component } from 'react';
import PropTypes from 'prop-types';

const getMousePosition = (event) => ({
  x: event.clientX,
  y: event.clientY,
});

const getTouchPosition = (event) => ({
  x: event.targetTouches[0].clientX,
  y: event.targetTouches[0].clientY,
});

class SignCanvas extends Component {
  inAction = false;

  prevX = 0;

  currX = 0;

  prevY = 0;

  currY = 0;

  listeners = {
    mousedown: (event) => {
      this.startDrawing(getMousePosition(event));
    },
    mousemove: (event) => {
      this.moveHandler(getMousePosition(event));
    },
    mouseup: () => this.endDrawing(),
    mouseout: () => this.endDrawing(),
    touchstart: (event) => {
      event.preventDefault();
      this.startDrawing(getTouchPosition(event));
    },
    touchmove: (event) => {
      this.moveHandler(getTouchPosition(event));
    },
    touchend: () => this.endDrawing(),
    touchcancel: () => this.endDrawing(),
  };

  componentDidMount() {
    this.fitCanvasElement();
    this.ctx = this.canvas.getContext('2d');

    Object.keys(this.listeners).forEach((key) => {
      this.canvas.addEventListener(key, this.listeners[key]);
    });
  }

  componentWillUnmount() {
    Object.keys(this.listeners).forEach((key) => {
      this.canvas.removeEventListener(key, this.listeners[key]);
    });
  }

  endDrawing = () => {
    this.inAction = false;
  };

  fitCanvasElement = () => {
    this.canvas.style.width = '100%';
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetWidth * 0.9;
  };

  draw = () => {
    const { ctx } = this;
    ctx.beginPath();
    ctx.moveTo(this.prevX, this.prevY);
    ctx.lineTo(this.currX, this.currY);
    ctx.strokeStyle = '#000';
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.closePath();
  };

  calculatePosition = (pointer) => {
    const { left, top } = this.canvas.getBoundingClientRect();

    this.prevX = this.currX;
    this.prevY = this.currY;
    this.currX = pointer.x - left;
    this.currY = pointer.y - top;
  };

  startDrawing = (pointer) => {
    this.props.onSignStart();

    this.inAction = true;
    this.calculatePosition(pointer);

    this.ctx.beginPath();
    this.ctx.fillRect(this.currX, this.currY, 2, 2);
    this.ctx.closePath();
  };

  moveHandler = (pointer) => {
    if (this.inAction) {
      this.calculatePosition(pointer);
      this.draw();
    }
  };

  erase = () => {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  };

  getImage = () => this.canvas.toDataURL('image/png').replace('data:image/png;base64,', '');

  render() {
    return (
      <canvas
        height="200"
        ref={(e) => {
          this.canvas = e;
        }}
        width="550"
      />
    );
  }
}

SignCanvas.propTypes = {
  onSignStart: PropTypes.func,
};

export { SignCanvas };
