import { getIconStaticUrl, getInfinitySymbol } from './actions';

export const getTariffParams = (cardStyle) => (params) => {
  if (!params) return;

  if (params.widgetType === 'with-image') {
    return {
      ...getIconStaticUrl(params.icon),
      value: params.value,
      unit: params.unit,
      widgetType: params.widgetType,
      bottomIcon: params.data.image,
      bottomIconName: 'messages',
    };
  }

  // Маленький но вынужденный костыль для совместимости со старым(горизонтальным) каталогом тарифов в АЗ;
  let { value } = params;
  let icon = null;
  const iconIsUrl = params.icon?.includes('//');

  if (cardStyle === 'dark' && params.icon && !iconIsUrl) {
    icon = `${params.icon}-light`;
  }

  if (params.icon) {
    icon = getIconStaticUrl(icon || params.icon);
  }

  const infinitySymbol = getInfinitySymbol(params.value, cardStyle);
  if (infinitySymbol) {
    icon = getIconStaticUrl(infinitySymbol);
    value = null;
  }

  const iconParameterType = icon && icon.iconName ? icon.iconName : null;

  if (params.widgetType === 'plus-group') {
    // Мы знаем что в виджете plus-group приходить параметры в массиве;
    const paramItems = params.data.items;
    let paramValue = paramItems[0].value;
    const groupInfinitySymbol = getInfinitySymbol(paramValue, cardStyle);

    if (groupInfinitySymbol) {
      icon = getIconStaticUrl(groupInfinitySymbol);
      paramValue = null;
    }

    return {
      value: paramValue,
      unit: paramItems[0].unit,
      ...icon,
      extraParams: {
        ...getIconStaticUrl(paramItems[1].icon),
        iconUnit: paramItems[1].unit,
      },
      parameterType: infinitySymbol ? 'plus-group-infinity' : params.widgetType,
    };
  }

  return {
    parameterType: iconParameterType || (infinitySymbol && 'infinity'),
    ...params,
    ...icon,
    unit: params.label ? `${params.unit} ${params.label}` : params.unit,
    value,
  };
};
