import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { path } from 'ramda';
import {
  Button,
  Emoji,
  Heading,
  InputGroup,
  InputsRow,
  Label,
  SmartForm,
  SmartInput,
} from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';
import { emailMayBeEmpty, maskedPhone, notEmpty } from 'utils/validators';
import PopupStateless from 'pages-components/Popup';

import { SET_ALLINONE_VALUE } from '../../actions/actionTypes';
import { sendRequiestHouse } from '../../actions/actions';
import styles from './PopupRequestHouse.pcss';

const cx = classNames.bind(styles);

class PopupRequestHouse extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { informingRequestPushed: false };
  }

  renderForm() {
    const { flat, street, house, regionTitle, onSubmit, pushToAnalytics } = this.props;

    const handleSubmit = (data) => {
      if (!this.state.informingRequestPushed) {
        this.setState({ informingRequestPushed: true }, () => {
          pushToAnalytics({
            event: 'GA_event',
            eventAction: 'Заявка на информирование',
            eventCategory: 'Конвергенция',
            eventLabel: window.location.href,
          });
        });
      }
      onSubmit(data);
    };

    const houseInputDisabled = typeof house.label !== 'undefined';

    return (
      <div className={cx('layout')}>
        <div className={cx('head')}>
          <Heading className={cx('heading')} level={2}>
            Заявка на подключение
          </Heading>
          <p className={cx('description')}>
            Когда ваш дом подключат, мы сразу же сообщим вам об этом.
          </p>
        </div>
        <SmartForm
          autoComplete="off"
          disableIfInvalid
          onSubmit={handleSubmit}
          validator={{
            City: notEmpty,
            Building: notEmpty,
            Flat: notEmpty,
            LastName: notEmpty,
            FirstName: notEmpty,
            PatronymicName: notEmpty,
            PhoneNumber: maskedPhone,
            Email: emailMayBeEmpty,
          }}
        >
          <SmartInput inputId="City" name="City" type="hidden" value={regionTitle} />

          <InputsRow>
            <InputGroup>
              <Label>Адрес подключения</Label>
              <SmartInput disabled inputId="Street" name="Street" readOnly value={street.label} />
            </InputGroup>
          </InputsRow>

          <InputsRow>
            <Label>Дом и квартира</Label>
            <InputGroup>
              <SmartInput
                className={cx('number')}
                disabled={houseInputDisabled}
                inputId="Building"
                name="Building"
                readonly={houseInputDisabled}
                value={house.label}
              />
              <SmartInput
                autoComplete="off"
                className={cx('number')}
                inputId="Flat"
                mask="_____"
                name="Flat"
                value={flat}
              />
            </InputGroup>
          </InputsRow>

          <InputGroup>
            <Label>Фамилия</Label>
            <SmartInput inputId="LastName" name="LastName" />
          </InputGroup>

          <InputGroup>
            <Label>Имя</Label>
            <SmartInput inputId="FirstName" name="FirstName" />
          </InputGroup>

          <InputGroup>
            <Label>Отчество</Label>
            <SmartInput inputId="PatronymicName" name="PatronymicName" />
          </InputGroup>

          <InputGroup>
            <Label>Эл. почта</Label>
            <SmartInput inputId="Email" name="Email" />
          </InputGroup>

          <InputGroup>
            <Label>Мобильный телефон</Label>
            <SmartInput
              inputId="PhoneNumber"
              keepInitialValue
              mask="+7 ___ ___ __ __"
              name="PhoneNumber"
              value="+7 "
            />
          </InputGroup>

          <div className={cx('buttonWrapper')}>
            <Button type="submit">Отправить заявку</Button>
          </div>
        </SmartForm>
      </div>
    );
  }

  renderFailAlert() {
    return (
      <div className={cx('status')}>
        <Emoji className={cx('emoji')} name="statusFail" />
        <Heading level={2}>Что-то пошло не так.</Heading>
      </div>
    );
  }

  renderSuccessAlert() {
    return (
      <div className={cx('status')}>
        <Emoji className={cx('emoji')} name="statusOk" />
        <Heading level={2}>Заявка отправлена</Heading>
      </div>
    );
  }

  renderContent() {
    const { requestHouse } = this.props;

    switch (requestHouse) {
      case 'success': {
        return this.renderSuccessAlert();
      }
      case 'fail': {
        return this.renderFailAlert();
      }
      default: {
        return this.renderForm();
      }
    }
  }

  render() {
    const { popupIsOpen, onClose } = this.props;

    return (
      <PopupStateless onClose={onClose} opened={popupIsOpen}>
        {this.renderContent()}
      </PopupStateless>
    );
  }
}

PopupRequestHouse.defaultProps = {
  popupIsOpen: false,
  requestHouse: '',
  flat: '',
  street: {},
  house: {},
  pushToAnalytics: () => {},
};

PopupRequestHouse.propTypes = {
  popupIsOpen: PropTypes.bool,
  requestHouse: PropTypes.string,
  flat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  street: PropTypes.shape({
    label: PropTypes.string,
  }),
  house: PropTypes.shape({
    label: PropTypes.string,
  }),
  regionTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pushToAnalytics: PropTypes.func,
};

const mapStateToProps = ({ external: { regions, allInOne = {} } = {} }, { popupIsOpen }) => ({
  regionTitle: path(['currentRegion', 'title'], regions),
  ...allInOne,
  popupIsOpen: typeof popupIsOpen !== 'undefined' ? popupIsOpen : allInOne.popupIsOpen,
});

const mapDispatchToProps = (dispatch, { onClose }) => ({
  onClose: () => {
    dispatch({ type: SET_ALLINONE_VALUE, payload: { popupIsOpen: false } });
    if (onClose) onClose();
  },
  onSubmit: (data) => dispatch(sendRequiestHouse(data)),
});

const ConnectedPopup = connect(mapStateToProps, mapDispatchToProps)(PopupRequestHouse);

export default (props) => (
  <Provider store={store}>
    <ConnectedPopup {...props} />
  </Provider>
);
