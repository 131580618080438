import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isModalOpen: false,
  isModalConfirmed: false,
  modalType: '',
};

const modal = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.isModalOpen = true;
      state.modalType = action.payload;
      state.isModalConfirmed = false;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
    confirmModal: (state) => {
      state.isModalConfirmed = true;
      state.isModalOpen = false;
    },
  },
});

export const { openModal, closeModal, confirmModal } = modal.actions;
export default modal.reducer;
