import React from 'react';

import { BottomSide } from './BottomSide';
import { TopSide } from './TopSide';

export type TModalContentProps = {
  description: string;
  icon: string;
  subDescription?: Record<string, string>[];
  title: string;
};

export const ModalContent: React.FC<Partial<TModalContentProps>> = ({
  icon,
  title,
  description,
  subDescription,
}) => (
  <>
    <TopSide description={description} icon={icon} title={title} />
    {subDescription && subDescription.length > 0 && <BottomSide subDescription={subDescription} />}
  </>
);
