import { createSelector } from '@reduxjs/toolkit';
import { emptyObj } from '@beef/utils';

// Selectors
import { getCommonStoreContent } from './common';

/**
 * Получение общих данных для формы AddressRequest:
 */
export const getAddressRequestContent = createSelector(
  getCommonStoreContent,
  (state) => state?.content || emptyObj,
);

/**
 * getAddressRequestTexts: общий объект Texts.
 * getPlaceholderAddressTexts: общий объект Placeholders.
 * getArchiveNotification: объект архивной нотификации.
 */
export const getAddressRequestTexts = (state) =>
  getAddressRequestContent(state).addressRequestTexts;
export const getPlaceholderAddressTexts = (state) =>
  getAddressRequestContent(state).placeholderAddressTexts;
export const getArchiveNotification = (state) =>
  getAddressRequestContent(state).archiveNotification;

/**
 * getArchiveNotificationText: текст архивной нотификации.
 * getArchiveNotificationPopup: объект попапа архивной нотификации.
 * getArchiveNotificationButtonText: текст кнопки архивной нотификации.
 * getArchiveNotificationPopupTitle: заголовок попапа архивной нотификации.
 * getArchiveNotificationPopupDescription: описание попапа архивной нотификации.
 */
export const getArchiveNotificationText = (state) => getArchiveNotification(state)?.text;
export const getArchiveNotificationPopup = (state) => getArchiveNotification(state)?.archivePopup;
export const getArchiveNotificationButtonText = (state) =>
  getArchiveNotification(state)?.buttonText;
export const getArchiveNotificationPopupTitle = (state) =>
  getArchiveNotificationPopup(state)?.title;
export const getArchiveNotificationPopupDescription = (state) =>
  getArchiveNotificationPopup(state)?.description;

/**
 * Контент для Request-попапа:
 *
 * getAddressRequestTitle: title Request-попапа.
 * getAddressRequestSubTitle: Sub-title Request-попапа.
 * getUserAuthenticated: статус авторизации пользователя.
 * getUserCTN: авторизационный номер пользователя.
 * getAgreementText: текст пользовательского соглашения Request-попапа.
 * getAddressRequestDescription: description Request-попапа.
 * getConnectionStatusTexts: объект с возможными статусами подключения.
 */
export const getAddressRequestTitle = (state) => getAddressRequestTexts(state).title;
export const getAddressRequestSubTitle = (state) => getAddressRequestTexts(state).subTitle;
export const getUserAuthenticated = (state) => getCommonStoreContent(state).isAuthenticated;
export const getUserCTN = (state) => getCommonStoreContent(state)?.content.userInfo.activeCTN;
export const getAgreementText = (state) => getCommonStoreContent(state)?.content.userAgreementText;
export const getAddressRequestDescription = (state) => getAddressRequestTexts(state).description;
export const getConnectionStatusTexts = (state) =>
  getAddressRequestTexts(state).statusConnectionTexts;

/**
 * Лейблы для полей Request-попапа:
 *
 * getFlatLabel: лейбл поля квартиры.
 * getCityLabel: лейбл поля города.
 * getHouseLabel: лейбл поля дома.
 * getNumberLabel: лейбл поля контактного номера.
 * getStreetLabel: лейбл поля улицы.
 * getPhoneErrorLabel: текст ошибки поля ввода номера телефона.
 */
export const getFlatLabel = (state) => getPlaceholderAddressTexts(state).flat;
export const getCityLabel = (state) => getPlaceholderAddressTexts(state).city;
export const getHouseLabel = (state) => getPlaceholderAddressTexts(state).house;
export const getNumberLabel = (state) => getPlaceholderAddressTexts(state).phone;
export const getStreetLabel = (state) => getPlaceholderAddressTexts(state).street;
export const getPhoneErrorLabel = (state) => getPlaceholderAddressTexts(state).phoneErrorText;

/**
 * getRegion: текущий полный объект региона пользователя, определенный при входе на страницы.
 */
export const getRegion = createSelector(getCommonStoreContent, (state) => state.region);
