import { ECaptchaStatus } from '@beef/captcha';

const ERROR_MESSAGES = {
  JAVASCRIPT_ERROR: 'Ошибка выполнения операции. Перезагрузите страницу или попробуйте позднее',
  BAD_VALIDATION: 'Ошибка валидации операции',
  NETWORK_ERROR: 'Ошибка соединения. Проверьте подключение к Интернету',
  DECLINED: 'Ошибка прохождения капчи. Нажмите "Оформить заказ" для повторного прохождения',
  DEFAULT: '',
};

export const generateCaptchaErrorText = (invalid: boolean, status: ECaptchaStatus) => {
  if (invalid) {
    return ERROR_MESSAGES.BAD_VALIDATION;
  }

  if (status === ECaptchaStatus.JavascriptError) {
    return ERROR_MESSAGES.JAVASCRIPT_ERROR;
  }

  if (status === ECaptchaStatus.NetworkError) {
    return ERROR_MESSAGES.NETWORK_ERROR;
  }

  if (status === ECaptchaStatus.Declined) {
    return ERROR_MESSAGES.DECLINED;
  }

  return ERROR_MESSAGES.DEFAULT;
};
