import { path } from 'ramda';

import { AUTH_STEPS, LOGIN_TYPES } from '../actions/constants';

function getCustomPusher(defaults) {
  return (data) => {
    const eventData = {
      ...defaults,
      ...data,
    };
    if (typeof window !== 'undefined' && window.dataLayer !== undefined) {
      window.dataLayer.push(eventData);
    }
  };
}

const pushLoginEvent = getCustomPusher({
  event: 'GA_event',
  eventCategory: 'loginSteps',
});

const eventPush = (eventAction) => {
  pushLoginEvent({
    eventAction,
    eventLabel: path(['location', 'href'], window),
  });
};

export const pushProps = (next, current) => {
  if (next.step !== current.step) {
    // on submit ctn
    if (current.step === AUTH_STEPS.initial) {
      let eventAction;
      switch (next.loginType) {
        case LOGIN_TYPES.mobile:
          eventAction = 'step2_mobile';
          break;
        case LOGIN_TYPES.mobileConvergence:
          eventAction = 'step2_convergence';
          break;
        case LOGIN_TYPES.login:
          eventAction = 'step3_login_password';
          break;
        case LOGIN_TYPES.unknown:
          eventAction = 'step3_unknown_password';
          break;
      }
      if (eventAction) {
        eventPush(eventAction);
      }
    } else if (next.step === AUTH_STEPS.initial) {
      // on cancel or reset
      if (current.step === AUTH_STEPS.smsCaptcha) {
        eventPush('try_const_after_captha');
      } else {
        eventPush('step3_undo');
      }
    } else if (next.step === AUTH_STEPS.smsCode) {
      // on request sms
      // if captcha
      if (current.step === AUTH_STEPS.smsCaptcha) {
        eventPush('try_tmp_after_captha');
        // if first request
      } else if (current.loginType === LOGIN_TYPES.mobile) {
        eventPush('step3_get_sms');
      } else if (current.loginType === LOGIN_TYPES.mobileConvergence) {
        eventPush('step3_convergence_get_tmp_password');
      }
    } else {
      // on switch form
      if (current.loginType === LOGIN_TYPES.mobile) {
        if (next.step === AUTH_STEPS.password) eventPush('step3_use_password');
        if (next.step === AUTH_STEPS.smsInitial) eventPush('step2_mobile');
        if (next.step === AUTH_STEPS.smsCode) eventPush('step4_mobile_use_password');
      }
      if (current.loginType === LOGIN_TYPES.mobileConvergence) {
        if (next.step === AUTH_STEPS.smsInitial) {
          eventPush('step3_use_convergence_tmp_password');
          eventPush('step3_convergence_tmp_password');
        }
        if (next.step === AUTH_STEPS.smsCode) eventPush('step4_convergence_use_password');
      }
    }
  } else if (next.fetchingSMS !== current.fetchingSMS && current.step === next.step) {
    eventPush('step_4begin');
  } else if (next.opened !== current.opened && next.opened === true) {
    eventPush('showLoginForm');
  }
};

export const pushGetPassword = ({ step, loginType }) => {
  let actionType;
  switch (loginType) {
    case LOGIN_TYPES.mobile:
      if (step === AUTH_STEPS.smsInitial) {
        actionType = 'step3_get_password';
      }
      break;
    case LOGIN_TYPES.mobileConvergence:
      if (step === AUTH_STEPS.password) {
        actionType = 'step3_convergence_get_password';
      }
      break;
    case LOGIN_TYPES.login:
      eventPush('step3_login_get_password');
      actionType = 'step3_login_howto';
      break;
    case LOGIN_TYPES.unknown:
      if (step === AUTH_STEPS.initial) {
        actionType = 'step1_howto';
      } else {
        eventPush('step3_unknown_get_password');
        actionType = 'step3_unknown_howto';
      }
  }
  eventPush(actionType);
};

export const sessionEventPush = (eventAction) => {
  if (!sessionStorage.getItem(eventAction)) {
    eventPush(eventAction);
    sessionStorage.setItem(eventAction, true);
  }
};
