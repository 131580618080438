import { useEffect } from 'react';

import { useStepNavigation } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/hooks/useStepNavigation';

/** Хук для автоматической отправки скролла при монтировании страницы */
export const useAutoScrollTop = () => {
  const { currentStep } = useStepNavigation();
  useEffect(() => {
    document.body.scrollTop = 0; // для safari
    document.documentElement.scrollTop = 0; // для нормальных браузеров
  }, [currentStep]);
};
