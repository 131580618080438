import axios from 'axios';
import qs from 'query-string';

/**
 * Отправляет запрос на обратный звонок
 * @param {Object} params - The parameters for the callback request.
 * @param {string} params.phoneNumber - The phone number for the callback request.
 * @param {Object|null} [params.captchaData] - The captcha data for the callback request. Default is null.
 * @param {boolean} [params.isFMC=false] - Indicates if the callback request is for FMC. Default is false.
 * @param {string|null} [params.clientId] - The client ID for the callback request. Default is null.
 * @param {string|null} [params.marketingProductName] - Default is null.
 * @param {string} [params.caseTitle='sale'] - The case title for the callback request. Default is 'sale'.
 * @returns {Promise<{
 *         shouldShowCaptcha: boolean,
 *         isCaptchaError: boolean,
 *         isSucceeded: boolean,
 *       }>} - A promise that resolves to the response data.
 */

const submitCallbackRequest = ({
  phoneNumber,
  captchaData = null,
  isFMC = false,
  clientId = null,
  marketingProductName = null,
  caseTitle = 'sale',
}) => {
  const requestObj = {
    phoneNumber: phoneNumber.replace(/ /g, ''),
    captcha: captchaData?.captchaToken,
    _captcha_key: captchaData?.key,
    isFMC,
    caseTitle,
  };

  if (clientId) {
    requestObj.clientId = clientId;
  }

  if (marketingProductName) {
    requestObj.marketingProductName = marketingProductName;
  }

  const requestData = qs.stringify(requestObj);

  const requestConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'X-Requested-With': 'XMLHttpRequest',
    },
  };

  return axios
    .post('/hometariffsapi/SubmitCallbackRequest', requestData, requestConfig)
    .then(({ data }) => {
      const { isSucceeded, isCaptchaRequired, isCaptchaError } = data;

      return {
        shouldShowCaptcha: isCaptchaRequired,
        isCaptchaError,
        isSucceeded,
        data,
      };
    });
};

export default submitCallbackRequest;
