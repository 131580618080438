import { getRandomInt } from 'utils/fn';

/** Количество точек на стартовом */
const COUNT_DOTS = 10;

/**  используем цвета не из кита, потому что необходимо добавить альфа-канал к цветам */
const AVAILABLE_COLORS_DOT = ['#28303f40', '#f84a0040'];

/**
 * Хук для точек на стартовом экране
 */
export const useDots = () => {
  return new Array(COUNT_DOTS).fill(null).map((_, index) => ({
    left: getRandomInt(1, 100),
    top: getRandomInt(1, 100),
    backgroundColor: AVAILABLE_COLORS_DOT[getRandomInt(0, 1)],
    id: index,
  }));
};
