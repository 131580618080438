import { useEffect, useRef } from 'react';

import { pushEcommerceShowMnp } from 'utils/analytics/simOrderAnalytics';
import { useOnScreenAnalytics } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempAnalytics/hooks/useOnScreenAnalytics';
import { getMNPCommonProps, keyYMForMNP } from 'pages/MnpOrderNew/ym';

export const usePushEcommerceShowMnp = (id: string) => {
  useEffect(() => {
    pushEcommerceShowMnp(id);
  }, [id]);
};

// Хук для отправки аналитики при первичном отображении
export const useOnScreenSendAnalytics = (title: string, blockName?: boolean) => {
  const rootRef = useRef(null);

  useOnScreenAnalytics({
    ref: rootRef,
    isForce: true,
    payload: {
      [keyYMForMNP]: {
        ...getMNPCommonProps(),
        action: 'view',
        item_type: 'block',
        [blockName ? 'block_name' : 'block_title']: title,
      },
    },
  });

  return rootRef;
};
