import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PopupStateless from 'pages-components/Popup';

import SimOrderForm from '../SimOrderForm/SimOrderForm';

class SimOrderPopup extends Component {
  state = { opened: false };

  triggerPopup = (opened) => this.setState({ opened });

  render() {
    const { opened } = this.state;

    return (
      <PopupStateless
        onClose={this.props.closePopup || (() => this.triggerPopup(false))}
        opened={opened}
      >
        <SimOrderForm onReset={() => this.triggerPopup(false)} {...this.props} />
      </PopupStateless>
    );
  }
}

SimOrderPopup.propTypes = {
  open: PropTypes.bool,
  textFor: PropTypes.string,
  phone: PropTypes.string,
  closePopup: PropTypes.func,
  endpoint: PropTypes.string,
};

export default SimOrderPopup;
