import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './style.pcss';

const cx = classNames.bind(styles);

const Progress = ({ text, percent, bg, className }) => (
  <div className={cx('wrap', className)}>
    <div className={cx('text')}>{text}</div>
    <div className={cx('progressCtn')}>
      <div
        className={cx('progress')}
        style={{
          width: `${parseInt(percent, 10)}%`,
          backgroundColor: bg,
        }}
      />
    </div>
  </div>
);

Progress.defaultProps = {
  text: '',
  percent: 0,
  bg: 'transparent',
  className: '',
};

Progress.propTypes = {
  text: PropTypes.string,
  percent: PropTypes.number,
  bg: PropTypes.string,
  className: PropTypes.string,
};

export default Progress;
