import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { templateParser } from 'utils/format-string';
import PopupStateless from 'pages-components/Popup';
import PlainContainer from 'pages-components/PopupContainer/PlainContainer';

class ResultPopup extends Component {
  onButtonClick = () => {
    const { step, onClose } = this.props;

    if (step === 'error' || step === 'success') {
      onClose();
    }
  };

  getDescription = (text = '') =>
    templateParser(text, {
      friendNumber: this.props.friendNumber,
      myNumber: this.props.myNumber,
    });

  render() {
    const { step, onClose, children } = this.props;
    const texts = this.props.texts[step] || {};

    return (
      <PopupStateless onClose={onClose} opened>
        <PlainContainer
          {...texts}
          description={this.getDescription(texts.description)}
          onButtonClick={this.onButtonClick}
        >
          {children}
        </PlainContainer>
      </PopupStateless>
    );
  }
}

ResultPopup.propTypes = {
  step: PropTypes.string,
  onClose: PropTypes.func,
  friendNumber: PropTypes.string,
  myNumber: PropTypes.string,
  texts: PropTypes.object,
};

export default ResultPopup;
