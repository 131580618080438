import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { selectContent } from '../../../store/selectors';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

const HeadingLayout = ({ image, title, subtitle }) => (
  <>
    <img alt="" className={cx('image')} src={image} />
    <div className={cx('title')}>
      <Text size="size1-m">{title}</Text>
    </div>
    <Text size="size4-r">{subtitle}</Text>
  </>
);

const mapStateToProps = (state) => {
  const {
    rating: {
      heading: { image, title, subtitle },
    },
  } = selectContent(state);
  return { image, title, subtitle };
};

export const Heading = connect(mapStateToProps)(HeadingLayout);
