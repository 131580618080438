import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';

// Components
import Ctx from 'pages-components/Ctx';

import styles from '../styles.pcss';
import { toggleTariffConnectionPopup } from '../../../actions/tariffConnect/toggleTariffConnectionPopup';

const cx = classNames.bind(styles);

/**
 * Кнопка + модальное окно заказа SIM-карты
 */
class SimOrderButton extends Component {
  triggerPopup = () => {
    this.props.onClick();
    store.dispatch(toggleTariffConnectionPopup(true, null, ['SimOrderForm']));
  };

  render() {
    const { buttonText, className, modifier, wide, color } = this.props;

    return (
      <div className={className}>
        <Button className={modifier} color={color} onClick={this.triggerPopup} wide={wide}>
          <span
            className={cx(modifier && modifier === 'big' && 'bigButtonText')}
            dangerouslySetInnerHTML={{ __html: buttonText }}
          />
        </Button>
      </div>
    );
  }
}

SimOrderButton.defaultProps = {
  buttonText: Ctx.tariff.buttonText.simOrder,
  onClick: () => {},
  wide: true,
  color: '',
};

SimOrderButton.propTypes = {
  buttonText: PropTypes.string,
  modifier: PropTypes.string,
  wide: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default SimOrderButton;
