import { formatOrderData, handleSuccessRequest } from '@beef/layout-kit/utils';
import { createAction } from '@reduxjs/toolkit';

import globalStore from 'store';
import { vkPushEvent } from 'utils/analytics/vkEvents';

import { getCurrentRandomNumber } from '../selectors/numbers';
import { addNotification } from './filter';
import { getFTnewCategoriesOfBuyNumbers, getOrderErrorContent } from '../selectors/content';
import { getCartLoading } from '../selectors/cart';
import { getActiveFilter } from '../selectors/filter';
import { fancyNumberVkEventGoal, pushAddToCart } from '../../analytics/numbers';

export const setLoading = createAction('set cart loading');

export const orderNumber = (number, isRandom) => async (dispatch, getState) => {
  const additionalCtnOptions = {};

  // возможные фича-тогглы
  const enabledFT = {
    newCategoriesOfBuyNumbersEnabled: getFTnewCategoriesOfBuyNumbers(getState()),
  };

  if (enabledFT?.newCategoriesOfBuyNumbersEnabled && number?.niceClass) {
    additionalCtnOptions.niceClass = number?.niceClass;
  }

  if (getCartLoading(getState())) return;
  if (number.isInCart) {
    handleSuccessRequest({ redirectToCart: true });
    return;
  }

  dispatch(setLoading(true));

  try {
    const { cartApi } = globalStore.getState().external.cartData;
    const request = formatOrderData({
      ctn: number.value,
      simOrderPlace: 'fancyNumberPage',
      additionalCtnOptions,
    });

    const { data } = await cartApi.post(`/${request.apiEndpoint}`, request.body);

    pushAddToCart(number, isRandom ? 'random' : getActiveFilter(getState()));
    vkPushEvent(fancyNumberVkEventGoal);

    handleSuccessRequest({
      cartId: data.id,
      redirectToCart: true,
      items: data.items,
    });
  } catch (err) {
    const errorContent = getOrderErrorContent(getState());
    dispatch(
      addNotification({
        id: Math.random().toString(),
        text: errorContent.title,
        buttonText: errorContent.closeButton,
      }),
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const orderRandomNumber = () => (dispatch, getState) => {
  dispatch(orderNumber(getCurrentRandomNumber(getState()), true));
};
