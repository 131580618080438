import React from 'react';
import PropTypes from 'prop-types';

const HiddenInput = ({ isSms, clientId, returnUrl, state, nonce, redirectUrl }) => (
  <div>
    <input name="client_id" type="hidden" value={clientId} />
    <input name="redirect_uri" type="hidden" value={returnUrl} />
    <input name="state" type="hidden" value={state} />
    <input name="nonce" type="hidden" value={nonce} />
    <input name="redirect_uri" type="hidden" value={redirectUrl} />
    <input name="response_type" type="hidden" value="id_token" />
    <input name="response_mode" type="hidden" value="form_post" />
    <input name="scope" type="hidden" value="openid selfservice_identity usss_token profile" />
    <input name="remember_me" type="hidden" value="false" />
    {isSms ?
      <input name="one_time_password" type="hidden" value="true" />
    : null}
  </div>
);

HiddenInput.propTypes = {
  isSms: PropTypes.bool,
  clientId: PropTypes.string,
  returnUrl: PropTypes.string,
  state: PropTypes.string,
  nonce: PropTypes.string,
  redirectUrl: PropTypes.string,
};

export default HiddenInput;
