import React from 'react';
import { Provider } from 'react-redux';
import type { ReactProps } from '@beef/react/types';

import store from '../store';

export function withBeelineStore<TProps extends ReactProps>(
  WrappedComponent: React.ComponentType<TProps>,
) {
  return (props: TProps) => (
    <Provider store={store}>
      <WrappedComponent {...props} />
    </Provider>
  );
}
