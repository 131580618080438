import { useCallback, useMemo } from 'react';

import { TUseMapStateToProps } from 'pages/Services/ZeroBalanceSupport/enhancers/withContext/types';
import { selectDisconnectServiceSuccess } from 'pages/Services/ZeroBalanceSupport/selectors';
import { TZeroBalanceContext } from 'pages/Services/ZeroBalanceSupport/types';

import { TModalWrapperProps } from '../../../ModalWrapper/types';

export const useMapStateToProps: TUseMapStateToProps<TZeroBalanceContext, TModalWrapperProps> = ({
  state,
  ownProps: { setCurrentModal },
}) => {
  const modalData = selectDisconnectServiceSuccess(state);

  const onClose = useCallback(() => setCurrentModal(null, true), [setCurrentModal]);

  const actionBarProps = useMemo(
    () => ({
      loading: false,
      confirmButton: {
        content: modalData?.button,
        onClick: onClose,
      },
      rejectButton: {
        content: modalData?.buttonReject,
      },
    }),
    [modalData, onClose],
  );

  return {
    onClose,
    content: {
      modalData,
      actionBarProps,
    },
  };
};
