import React, { FC } from 'react';
import { connect } from 'react-redux';

import { RedirectStore } from 'pages/Services/RedirectServiceCardLanding/store';
import {
  selectDpcContent,
  selectLoadingInitialData,
  selectRedirectOptions,
  selectRedirectQPContent,
} from 'pages/Services/RedirectServiceCardLanding/store/redirect/selectors';
import { BlockInfoProps } from 'pages/Services/RedirectServiceCardLanding/components/BlockInfo/types';
import { useBlockInfo } from 'pages/Services/RedirectServiceCardLanding/components/BlockInfo/hook';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { BlockInfoSettings } from 'pages/Services/RedirectServiceCardLanding/components/BlockInfo/BlockInfoSettings';
import { BlockInfoPrice } from 'pages/Services/RedirectServiceCardLanding/components/BlockInfo/BlockInfoPrice';

const _BlockInfo: FC<BlockInfoProps> = ({ options, settings, contentDpc, loadingInitialData }) => {
  const isMobile = useIsMobileDimension();

  const { title, list, priceInfo } = useBlockInfo({
    loadingInitialData,
    options,
    settings,
    contentDpc,
  });

  return (
    <div>
      <BlockInfoPrice priceInfo={priceInfo} />
      <BlockInfoSettings
        isMobile={isMobile}
        list={list}
        loadingInitialData={loadingInitialData}
        title={title}
      />
    </div>
  );
};
const mapStateToProps = (state: RedirectStore) => ({
  loadingInitialData: selectLoadingInitialData(state),
  options: selectRedirectOptions(state),
  contentDpc: selectDpcContent(state),
  settings: selectRedirectQPContent(state)?.settings,
});

export const BlockInfo = connect(mapStateToProps)(_BlockInfo);
