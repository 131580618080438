import { createSelector } from '@reduxjs/toolkit';

import { createImageItems } from 'constructorV1/utils/createImageItems';
import { selectOptionsSections } from 'constructorV1/store/selectors/selectTariffSettings';

/** Селектор преобразования массива секций под работу с компонентами кита.
 * Данный селектор меняет структуру pictures под компонент AvatarGroup */
export const selectPreparedSections = createSelector(selectOptionsSections, (sections) => {
  return sections?.map((section) => ({
    ...section,
    options: section.options?.map((option) => ({
      ...option,
      pictures: createImageItems(option.pictures),
    })),
  }));
});
