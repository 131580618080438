import React from 'react';
import classNames from 'classnames/bind';
import { Button, Heading } from '@beef/legacy-ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Link = ({ alias, href, onClick, heading, description, className }) => (
  <a className={cx('widget', alias, className)} href={href} onClick={onClick}>
    <Heading level={3} tagName="div">
      <span dangerouslySetInnerHTML={{ __html: heading }} />
    </Heading>
    <div className={cx('description')} dangerouslySetInnerHTML={{ __html: description }} />
    <div className={cx('widgetButton')}>
      <Button>Подобрать номер</Button>
    </div>
  </a>
);

export default function FancyNumberLinks() {
  return (
    <div className={cx('component')}>
      <Link
        alias="new"
        description="Подберите себе уникальный красивый номер телефона. Найдите номер, подходящий для вас."
        heading="Я хочу получить новую SIM-карту с красивым номером"
        href="/customers/products/mobile/services/details/nomer-na-vybor/krasivie-nomera/"
      />
      <Link
        alias="old"
        description="У меня уже есть номер «билайн», но я хочу поменять его на новый красивый номер"
        heading="Я хочу сменить свой старый номер «билайн» на новый красивый"
        href="/customers/products/mobile/services/details/nomer-na-vybor/dlya-abonentov/"
      />
    </div>
  );
}
