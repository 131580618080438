import { usePrepareModalContent } from './usePrepareModalContent';
import { useSelectDevicesPopups } from '../selectors/useSelectDevicesPopups';

/** Подготовка данных для DevicesContainer */
export const usePrepareDevices = () => {
  /* Контент плашки рекомендуемых устройств (кнопка, иконка, заголовок и т.д.) */
  const deviceContentProps = useSelectDevicesPopups();

  /* Пропы для компонента Modal в Devices */
  const { openModal, modalContainerProps } = usePrepareModalContent();

  /* Функция-обработчик для клика по плашке рекомендуемых устройств */
  const handleClick = () => openModal();

  /* Общие пропы для компонента Devices */
  return { handleClick, deviceContentProps, modalContainerProps };
};
