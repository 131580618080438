import React from 'react';
import { Faq } from '@beef/smart-kit';
import classNames from 'classnames/bind';

import { IWithAnalyticsFAQ, withAnalyticsFAQ } from './withAnalyticsFAQ';
import { Anchor } from '../Anchor';
import { Container } from '../../layout';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const WrappedFaq = withAnalyticsFAQ(Faq);

export type TFaqBlock = IWithAnalyticsFAQ & {
  anchorId: string;
};

export const FaqBlock: React.FC<TFaqBlock> = ({ anchorId, ...props }) => {
  return (
    <Container.FlexFactor className={cx('faq')}>
      <Anchor anchorId={anchorId} />
      <WrappedFaq {...props} />
    </Container.FlexFactor>
  );
};
