import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { IBenefitItem } from '../../../../../../store/slices/commonSlice/types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IBenefitProps extends IBenefitItem {}

/**
 * Компонент одного бенефита от билайна
 */
export const Benefit: FC<IBenefitProps> = ({ id, img, title }) => {
  return (
    <div
      className={cx('benefit')}
      style={
        {
          '--offset-index': id,
        } as CSSProperties
      }
    >
      <img alt="" src={img} />

      <Text
        className={cx('benefit__info', { 'benefit__info--with-translate': id < 2 })}
        variant="body-small"
      >
        {title}
      </Text>
    </div>
  );
};
