import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import SocialButtons from 'pages-components/SocialButtons/SocialButtons';
import TariffConnectButton from 'pages/ProductCard/Tariff/components/TariffSummary/TariffConnectButton';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ConnectionStep = ({ stepNumber, text, simOrder, appButtons }) => (
  <div className={cx('wrap')}>
    {stepNumber && (
      <div className={cx('step-number')}>
        <Text size="size2-m">{stepNumber}</Text>
      </div>
    )}
    {text && (
      <div className={cx('row')}>
        <Text size="size5-r-s">{text}</Text>
      </div>
    )}
    {simOrder && (
      <div className={cx('row')}>
        <div className={cx('button-wrap')}>
          <TariffConnectButton buttonText="Заказать SIM" />
        </div>
      </div>
    )}
    {appButtons && (
      <div className={cx('row')}>
        <SocialButtons className={cx('row__buttons-wrapper')} data={appButtons} />
      </div>
    )}
  </div>
);

ConnectionStep.propTypes = {
  /* номер шага */
  stepNumber: PropTypes.number,
  /* текстовое описание */
  text: PropTypes.string,
  /* кнопка заказа сим */
  simOrder: PropTypes.bool,
  /* кнопки приложений */
  appButtons: PropTypes.object,
};

export default ConnectionStep;
