import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { AmountText, TAmountText } from '../AmountText';
import { ExtraAmountText, TExtraAmountText } from '../ExtraAmountText';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TTotalAmount = {
  amountProps?: TAmountText;
  btnText?: string;
  extraAmountProps?: TExtraAmountText;
  handleClick?: () => void;
};

/** Общий компонент стоимости выбранных пресетов/опций и доп. платежей чека ЕК */
export const TotalAmount = memo<TTotalAmount>(({ amountProps, extraAmountProps }) => (
  <div className={cx('wrapper')}>
    <AmountText {...amountProps} />
    <ExtraAmountText {...extraAmountProps} />
  </div>
));
