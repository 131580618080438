// Utils
import { ctnMaskBeautifier } from 'utils/format-string';

const replaceText = (text, value) =>
  value || value === 0 ? text.replace('{{TEXT}}', value) : text;
const splitText = (text) => text.split('{{DELETE}}');

const PAYMENT_DESKTOP_URL = '/customers/how-to-pay/popolnenie-scheta/#/ImmediatePayment/Mobile/';
const PAYMENT_MOBILE_URL = 'https://oplata.beeline.ru/mob/';

const getChangeModalBeforeText = (data) => {
  const {
    content: { reConnectModal },
    freeChangeLimit,
    changePrice,
  } = data;

  const pageSubTitle = reConnectModal.beforeContent.replace(
    '{{FREE_CHANGE_LIMIT}}',
    freeChangeLimit,
  );

  return pageSubTitle.replace('{{CHANGE_PRICE_AMOUNT}}', changePrice);
};

const getNotAllowedParams = (data) => {
  const { freeAvailableChangeCount, favoriteNumbers, changePrice, wantage, content } = data;

  if (freeAvailableChangeCount && !favoriteNumbers) return;

  const { favoriteNumberText, changeNumberText, termChangeText, wantageText } =
    content.notAllowedModal.paramsTexts;

  // Получаем любимый номер из массива в красивом формате
  const favoriteNumberValue =
    favoriteNumbers && favoriteNumbers.length && ctnMaskBeautifier(favoriteNumbers[0]);

  // Находим кол-во дней осталось до конца месяца;
  const date = new Date();
  const dayInMonth = new Date(date.getFullYear(), date.getDate(), 0).getDate();
  const dayLeft = dayInMonth - date.getDate();

  const paramsArray = [
    {
      value: favoriteNumberValue,
      description: favoriteNumberText,
    },
    {
      value: `${changePrice} руб.`,
      description: changeNumberText,
    },
    {
      value: `${dayLeft} дней`,
      description: termChangeText,
    },
  ];

  if (wantage) {
    paramsArray.push({
      value: `${wantage} руб.`,
      description: wantageText,
    });
  }

  return paramsArray;
};

export const getFavoriteNumberState = (data) => {
  const { content, favoriteNumbers, changePrice, wantage, ctn } = data;
  const { deleteModal, confirmModal, successModal, changeModal, changeFreeModal, notAllowedModal } =
    content;

  const favoriteNumberValue =
    favoriteNumbers && favoriteNumbers.length ? ctnMaskBeautifier(favoriteNumbers[0]) : null;

  const beautyDeleteText = replaceText(deleteModal.contentText, favoriteNumberValue);
  const beautyConfirmText = replaceText(confirmModal.contentText, favoriteNumberValue);
  const beautySuccessText = replaceText(successModal.contentText, favoriteNumberValue);
  const beautyChangeButtonText =
    changePrice ? replaceText(changeModal.buttonText, changePrice) : 'Сменить';
  const beautyBeforeText = getChangeModalBeforeText(data);
  const splitDeleteText = splitText(changeModal.deleteText);
  const splitChangeDeleteText = favoriteNumberValue ? splitText(changeFreeModal.deleteText) : null;

  // Собираем ссылку для пополнения счета при нехватки денег на балансе;
  const paymentUrl = /mobile/i.test(navigator.userAgent) ? PAYMENT_MOBILE_URL : PAYMENT_DESKTOP_URL;
  let fullPaymentUrl = `${paymentUrl}?ctn=${ctn}`;
  if (wantage) {
    fullPaymentUrl = `${fullPaymentUrl}&sum=${wantage}`;
  }

  return {
    ...content,
    favoriteNumber: favoriteNumberValue,
    reConnectModal: {
      ...content.reConnectModal,
      beforeContent: beautyBeforeText,
    },
    notAllowedModal: {
      ...notAllowedModal,
      params: getNotAllowedParams(data),
      paymentUrl: fullPaymentUrl,
    },
    deleteModal: {
      ...deleteModal,
      contentText: beautyDeleteText,
    },
    confirmModal: {
      ...confirmModal,
      contentText: beautyConfirmText,
    },
    changeModal: {
      ...changeModal,
      buttonText: beautyChangeButtonText,
      deleteText: splitDeleteText,
    },
    changeFreeModal: {
      ...changeFreeModal,
      deleteText: splitChangeDeleteText,
    },
    successModal: {
      ...successModal,
      contentText: beautySuccessText,
    },
  };
};
