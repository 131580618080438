import type { AnyAction } from '@reduxjs/toolkit';
import React, { FC, useCallback } from 'react';
import classNames from 'classnames/bind';
import { useIsDesktopDimension } from '@carnica/utils/hooks';

import { OPTIONS_TILE_LENGTH } from 'constructorV1/constants';
import { createArray } from 'constructorV1/utils/createArray';
import { useAppDispatch, useAppSelector } from 'constructorV1/store';
import { OptionTile } from 'constructorV1/components/tempComponents/OptionTile';
import { TMobileOptionsDO } from 'constructorV1/store/tariffSettingsSlice/types';
import { Title } from 'constructorV1/components/commonComponents/ConstructorTitle';
import { SkeletonOptionTile } from 'constructorV1/components/tempComponents/SkeletonOptionTile';
import { toggleOptions } from 'constructorV1/store/tariffSettingsSlice/asyncThunks/changeOptionSoc';
import { selectOptionsLoading } from 'constructorV1/components/constructorPage/OptionContainer/selectors/selectOptionsLoading';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Общий тип мобильных опции без pictures */
export type TOptionTiles = {
  options: Array<Omit<TMobileOptionsDO, 'pictures'>>;
  subTitle: string;
  title: string;
};

/** Компонент-обертка над опциями конструктора */
export const OptionTiles: FC<TOptionTiles> = ({ options, title, subTitle }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectOptionsLoading);
  const isDesktop = useIsDesktopDimension();

  /** Функция-обработчик клика по чекрыжу */
  const onChange = useCallback(
    (serviceCategory: string) =>
      dispatch(toggleOptions(serviceCategory) as unknown as AnyAction).catch((e: Error) =>
        console.error(e),
      ),
    [options],
  );

  return (
    <div className={cx('wrapper')}>
      <Title {...{ title, subTitle }} align="left" size={isDesktop ? 'm' : 's'} titleTagName="h2" />
      <div className={cx('wrapper__options')}>
        {isLoading ?
          createArray(OPTIONS_TILE_LENGTH).map((i) => <SkeletonOptionTile key={i} />)
        : options?.map((option) => (
            <OptionTile key={option.entityName} {...{ ...option, onChange }} />
          ))
        }
      </div>
    </div>
  );
};
