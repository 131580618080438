import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TBillButton = {
  disabled?: boolean;
  extraText?: string;
  isLoading?: boolean;
  isMobile: boolean;
  nextButton?: string;
  onClick: () => void;
};

/** Кнопка чека на странице тарифа с доп. информацией */
export const BillButton = memo<TBillButton>(
  ({ disabled, isLoading, onClick, isMobile, extraText, nextButton }) =>
    nextButton ?
      <div className={cx('wrapper')}>
        <Button fullWidth size="m" variant="main" {...{ onClick, disabled, isLoading }}>
          {nextButton}
        </Button>

        {!isMobile && extraText && (
          <Text color="grey60" size="size6-r">
            {extraText}
          </Text>
        )}
      </div>
    : null,
);
