import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Heading, SmartForm, SmartInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import submitCallbackRequest from 'pages/FTTB/services/submitCallbackRequest';
import { maskedPhone, validateMaskedPhone } from 'utils/validators';
import { pushBackCallAction } from 'utils/ga';
import { currentStateList } from 'pages/FTTB/constants';
import Captcha from 'pages/Feedback/components/Captcha/Captcha';
import { CaptchaWithCallbackPopupHOC } from 'pages/FTTB2021/hoc/CaptchaWithCallbackPopupHOC';
import store from 'store';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const phoneMask = '+7 ___ ___ __ __';
const CAPTCHA_KEY = 'fttb_order_backCall';

class OrderBackCall extends PureComponent {
  state = {
    currentState: currentStateList.requestForm,
    phoneInputValue: '+7 ',
    phoneInputStatus: 'ok',
  };

  componentDidMount() {
    pushBackCallAction('shown');
  }

  onPhoneInputChange = (val) => {
    this.setState({ phoneInputValue: val, phoneInputStatus: 'ok' });
  };

  onSubmit = (values) => {
    const { captchaInput, phoneInput } = values;
    const { changeCaptchaState, showCaptcha } = this.props;

    pushBackCallAction('clickInFrom');

    if (!validateMaskedPhone(phoneInput)) {
      this.setState({ phoneInputStatus: 'fail' });
      return;
    }

    if (showCaptcha && captchaInput.length < 5) return;

    const captchaData = {
      captchaToken: captchaInput,
      key: CAPTCHA_KEY,
    };

    submitCallbackRequest({
      phoneNumber: phoneInput,
      captchaData,
      caseTitle: 'sale',
    })
      .then(({ isSucceeded, shouldShowCaptcha, isCaptchaError }) => {
        changeCaptchaState(false);
        pushBackCallAction('send');

        if (isCaptchaError && shouldShowCaptcha) {
          this.setState({ currentState: currentStateList.fail });
          changeCaptchaState(shouldShowCaptcha);
          pushBackCallAction('captcha');
        }
        if (isSucceeded) {
          this.setState({ currentState: currentStateList.success });
          changeCaptchaState(false);
        } else {
          this.setState({ currentState: currentStateList.fail });
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ currentState: currentStateList.fail });
        pushBackCallAction('sendError');
      });
  };

  getTextFn = (postfix) => () => {
    const request = this.props[`request${postfix}`];
    const success = this.props[`success${postfix}`];
    const fail = this.props[`fail${postfix}`];

    switch (this.state.currentState) {
      case currentStateList.requestForm:
        return request;
      case currentStateList.success:
        return success;
      case currentStateList.fail:
        return fail;
      default:
        return request;
    }
  };

  /**
   * В зависимости от состояния возвращает содержимое одного из пропсов:
   * requestTitle, successTitle, failTitle
   */
  getTitle = this.getTextFn('Title');

  /**
   * В зависимости от состояния возвращает содержимое одного из пропсов:
   * requestDescription, successDescription, failDescription
   */
  getDescription = this.getTextFn('Description');

  /**
   * В зависимости от состояния возвращает содержимое одного из пропсов:
   * requestOrderBackCallBtn, successOrderBackCallBtn, failOrderBackCallBtn
   */
  getButtonText = this.getTextFn('OrderBackCallBtn');

  render() {
    const {
      wrongNumber,
      className: wrapperClassName,
      titleClassName,
      descriptionClassName,
      reCaptchaModel,
      showCaptcha,
    } = this.props;
    const { phoneInputValue, phoneInputStatus } = this.state;

    const title = this.getTitle();
    const description = this.getDescription();
    const orderBackCallBtn = this.getButtonText();
    const showSmartForm = this.state.currentState === currentStateList.requestForm || showCaptcha;
    return (
      <div className={cx('wrapper', wrapperClassName)}>
        <Heading className={cx('title', titleClassName)} level={2}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Heading>

        <div
          className={cx('description', descriptionClassName)}
          dangerouslySetInnerHTML={{ __html: description }}
        />

        {showSmartForm && (
          <SmartForm
            className={cx('form')}
            onSubmit={this.onSubmit}
            validator={{
              phoneInput: maskedPhone,
              captchaInput: (value) => ({
                status: value.length < 5 ? 'fail' : 'ok',
                msg: value.length < 5 ? 'Пройдите капчу' : '',
              }),
            }}
          >
            {showCaptcha && (
              <div className={cx('reCaptcha')}>
                <Captcha captchaKey={CAPTCHA_KEY} inputId="captchaInput" {...reCaptchaModel} />
              </div>
            )}
            <div className={cx('phoneInput')}>
              <SmartInput
                inputId="phoneInput"
                keepInitialValue
                mask={phoneMask}
                onChange={this.onPhoneInputChange}
                placeholder={phoneMask}
                status={phoneInputStatus}
                value={phoneInputValue}
              />
              {phoneInputStatus === 'fail' && (
                <div className={cx('textFail')} dangerouslySetInnerHTML={{ __html: wrongNumber }} />
              )}
            </div>
            <div className={cx('orderBackCallBtn')}>
              <Button className="big" transformer type="submit">
                {orderBackCallBtn}
              </Button>
            </div>
          </SmartForm>
        )}
      </div>
    );
  }
}

OrderBackCall.propTypes = {
  requestTitle: PropTypes.string,
  requestDescription: PropTypes.string,
  requestOrderBackCallBtn: PropTypes.string,
  successTitle: PropTypes.string,
  successDescription: PropTypes.string,
  successOrderBackCallBtn: PropTypes.string,
  failTitle: PropTypes.string,
  failDescription: PropTypes.string,
  failOrderBackCallBtn: PropTypes.string,
  wrongNumber: PropTypes.string,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  showCaptcha: PropTypes.bool,
  changeCaptchaState: PropTypes.func,
  reCaptchaModel: PropTypes.shape({}),
};

export default (props) => (
  <Provider store={store}>
    <CaptchaWithCallbackPopupHOC {...props}>
      <OrderBackCall />
    </CaptchaWithCallbackPopupHOC>
  </Provider>
);
