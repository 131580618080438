import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { Button, Link, Status } from '@beef/legacy-ui-kit';
import { getSubstate } from '@beef/legacy-beeline-store/utils';

import BubbleMessage from 'pages-components/BubbleMessage';
import DisconnectionWarning from 'pages-components/DisconnectionWarning';
import { templateParser } from 'utils/format-string';

import { connectedNumbers } from '../../../actions/sharing';

// Styles
import styles from '../TariffConnectPopup.pcss';

const cx = classNames.bind({ ...styles });

// TODO: ПЕРЕПИСАТЬ КОМПОНЕНТ ChangeTariffForm + SebConnectForm, Т.К. КОМПОНЕНТЫ ПОХОЖИ!!!
class ChangeTariffForm extends PureComponent {
  renderFormattedText = (text) => <span dangerouslySetInnerHTML={{ __html: text }} />;

  renderStatus = () => {
    const { customWarning, serviceList, archiveWarning, serviceDescription } = this.props;
    const servicesWarning =
      serviceList ?
        templateParser(serviceDescription, { serviceList: `<br/>${serviceList}` })
      : null;

    return (
      <Status className="attention" emoji="pointingHandUp">
        {customWarning && (
          <p className={cx('extraText')}>{this.renderFormattedText(customWarning)}</p>
        )}
        {servicesWarning && this.renderFormattedText(servicesWarning)}
        {servicesWarning && archiveWarning && this.renderFormattedText('<br/><br/>')}
        {archiveWarning && this.renderFormattedText(archiveWarning)}
      </Status>
    );
  };

  render() {
    const {
      description,
      serviceList,
      changeTariffButtonText,
      cancelButtonText,
      price,
      serviceDescription,
      archiveWarning,
      customWarning,
      bubble,
      yandexPlusWarning,
    } = this.props;

    const servicesWarning =
      serviceList ?
        templateParser(serviceDescription, { serviceList: `<br/>${serviceList}` })
      : null;

    // Проверяем наличие текста для отображения статуса (Желтая плашка с пальцем вверх);
    const isShowStatus = [customWarning, servicesWarning, archiveWarning].some((item) => item);

    return (
      <div>
        <div className={cx('description')}>
          {this.renderFormattedText(templateParser(description, { price }))}
          {isShowStatus && <div className={cx('warning')}>{this.renderStatus()}</div>}
        </div>
        {bubble && (
          <div className={cx('bubble')}>
            <BubbleMessage content={bubble.content} icon={bubble.icon} />
          </div>
        )}
        {yandexPlusWarning && (
          <DisconnectionWarning src={yandexPlusWarning.imageUrl} text={yandexPlusWarning.text} />
        )}
        <div className={cx('buttons')}>
          <div className={cx('confirm-button')}>
            <Button className="big" onClick={this.props.onClick}>
              {changeTariffButtonText}
            </Button>
          </div>
          {cancelButtonText && (
            <div className={cx('confirm-button')}>
              <Link className={cx('resetLink')} onClick={this.props.onCancelClick}>
                {cancelButtonText}
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ChangeTariffForm.propTypes = {
  description: PropTypes.string,
  serviceDescription: PropTypes.string,
  serviceList: PropTypes.string,
  changeTariffButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  price: PropTypes.string,
  archiveWarning: PropTypes.string,
  onClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  customWarning: PropTypes.string,
  bubble: PropTypes.shape({
    content: PropTypes.string,
    icon: PropTypes.string,
  }),
  yandexPlusWarning: PropTypes.shape({
    text: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
  tariffName: PropTypes.string,
  currentTariffName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  sharedNumbers: getSubstate(state, connectedNumbers) || [],
  currentTariffName: state?.external?.tariff?.data?.currentTariffName || '',
  tariffName: state?.external?.tariffConnectionPopup?.data?.tariffName || '',
});

const ConnectedChangeTariffForm = connect(mapStateToProps, null)(ChangeTariffForm);

export default ConnectedChangeTariffForm;
