import { createAsyncThunk } from '@reduxjs/toolkit';

import { TFetchOptionsRes, fetchOptions } from '../../../api/fetchOptions';

/** Тип параметров запроса fetchOptions опций ConstructorV1 */
type TFetchOptionsReq = {
  gbValue: number;
  minValue: number;
  signal?: AbortSignal;
  soc: string;
};

/** Обертка запроса мобильных опций ConstructorV1 */
export const getOptions = createAsyncThunk<Partial<TFetchOptionsRes>, TFetchOptionsReq>(
  '/api/v1/constructors/fetchOptions',
  async ({ signal, ...params }) => {
    try {
      return (await fetchOptions({ signal, params })).data;
    } catch (e) {
      throw new Error();
    }
  },
);
