import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'query-string';
import { pathOr } from 'ramda';
import { connect } from 'react-redux';
import { Button, Form, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { initSmsService, updateLastSmsTime, updateSmsHistory } from './actions';
import styles from './SmsServiceExtension.pcss';
import CtnInput from './components/CtnInput/CtnInput';
import TextInput, { hasBadSymbols } from './components/TextInput/TextInput';
import Captcha from './components/Captcha/Captcha';
import Popup from './components/Popup/Popup';
import Timer from './components/Timer/Timer';
import MobileAppBanner from './components/MobileAppBanner';

const cx = classNames.bind(styles);

const linksTexts = {
  ctn: 'номер телефона',
  text: 'текст SMS',
  captcha: 'код с картинки',
};

class SmsServiceExtension extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ctn: '+7 ',
      translate: false,
      text: '',
      captcha: '',
      maxLengths: [
        this.props.data.extData.smsService.maxLengthNoEng,
        this.props.data.extData.smsService.maxLengthEng,
      ],
      status: 'ready',
      errorMessage: 'Ошибка. Не удалось отправить SMS. Попробуйте позже.',
      checkStatus: 'ready',
      checkErrors: {},
    };
  }

  componentDidMount() {
    this.props.initSmsServiceStore();
  }

  handleSubmitForm = (event) => {
    event.preventDefault();
    this.setState({ checkStatus: 'pending', checkErrors: {} });
    this.props.putCtnToHistory(this.state.ctn);

    const newState = { checkStatus: 'done', status: 'pending', captcha: '', checkErrors: {} };

    this.postMessage()
      .then((resp) => {
        const { data } = resp;

        if (data.data) {
          this.setState(newState);
          this.props.updateLastSmsTime(+new Date());
          setTimeout(() => this.setState({ status: 'success' }), 6000);
          return;
        }

        const errors = data.errors || {};

        if (Object.keys(errors).length === 0 || errors.other) {
          newState.errorMessage = errors.other || 'Не удалось отправить SMS. Попробуйте позже.';
          this.setState(newState);
          setTimeout(() => this.setState({ status: 'error' }), 6000);
          return;
        }

        newState.checkStatus = 'ready';
        newState.status = 'ready';

        if (errors.ctn) {
          newState.ctn = '+7 ';
          newState.checkErrors.ctn = errors.ctn;
        }
        if (errors.captcha) {
          newState.checkErrors.captcha = errors.captcha;
        }
        if (errors.message) {
          newState.checkErrors.message = errors.message;
        }

        this.captcha.fetchCaptcha();
        this.setState(newState);
      })
      .catch(() => {
        newState.errorMessage = 'Не удалось отправить SMS. Попробуйте позже.';
        delete newState.captcha;
        this.captcha.fetchCaptcha();
        this.setState(newState);
        setTimeout(() => this.setState({ status: 'error' }), 6000);
      });
  };

  postMessage = () => {
    const { ctn, captcha, translate, text } = this.state;
    const formattedCtn = ctn.replace(/\+7|\D/g, '');
    const body = {
      PhoneCode: formattedCtn.slice(0, 3),
      Phone: formattedCtn.slice(3),
      _captcha_key: this.props.data.extData.smsService.captchaKey,
      Captcha: captcha,
      CheckboxTransliterate: translate,
      Body: text,
      WidgetId: this.props.data.extData.smsService.captchaKey.replace('_ContentContaiter', ''),
      clearJson: true,
    };

    return axios({
      method: 'post',
      url: '/smssend/SendSms/?ui-part=442090',
      withCredentials: true,
      data: qs.stringify(body),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
    });
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
    if (value.length === 16) {
      this.text.setFocus();
    }
  };

  validateValue = (key, value = this.state[key]) => {
    if (key === 'ctn') {
      return value.replace(/\+7|\D/g, '').length === 10;
    } else if (key === 'text') {
      return (
        value.length > 0 && value.length <= this.state.maxLengths[hasBadSymbols(value) ? 0 : 1]
      );
    } else if (key === 'captcha') {
      return value.length === 5;
    }
    return false;
  };

  handleLinkClick = (key) => this[key].setFocus();

  handlePopupClose = () => {
    const newState = { captcha: '', status: 'ready', checkStatus: 'ready' };

    if (this.state.status === 'success') {
      newState.text = '';
      newState.ctn = '+7 ';
    }

    this.setState(newState);
    this.captcha.fetchCaptcha();
  };

  handleTimeOut = () => this.props.updateLastSmsTime(null);

  render() {
    const { ctnList, smsHistory, lastSMS, data, currentCtn, className } = this.props;
    const { ctn, translate, text, maxLengths, status, errorMessage, checkStatus, checkErrors } =
      this.state;
    const smsServiceData = data.extData.smsService;
    const mobileAppBanner = smsServiceData.banners.mobileApp || {};

    const links = Object.keys(linksTexts)
      .map((key) =>
        this.validateValue(key) ? null : (
          <Link className={cx('link')} key={key} onClick={() => this.handleLinkClick(key)}>
            {linksTexts[key]}
          </Link>
        ),
      )
      .filter((item) => item !== null);

    return (
      <div className={cx('smsService', className)}>
        <Form className={cx('form')} onSubmit={this.handleSubmitForm}>
          <CtnInput
            ctnList={ctnList}
            error={checkErrors.ctn}
            onChange={(value) => this.handleChange('ctn', value)}
            ref={(e) => {
              this.ctn = e;
            }}
            smsHistory={smsHistory ? smsHistory.ctns || {} : {}}
            value={ctn}
          />
          <TextInput
            isAuth
            maxLengths={maxLengths}
            onChange={this.handleChange}
            ref={(e) => {
              this.text = e;
            }}
            text={text}
            translate={translate}
          />
          <Captcha
            captchaKey={smsServiceData.captchaKey}
            error={checkErrors.captcha}
            label="Введите код с картинки"
            onChange={this.handleChange}
            ref={(e) => {
              this.captcha = e;
            }}
          />
          <Button
            className="big"
            disabled={links.length > 0 || checkStatus !== 'ready' || lastSMS !== null}
            wide
          >
            Отправить SMS
          </Button>
          <div className={cx('links')}>
            {links.length > 0 ? 'Осталось заполнить: ' : ''}
            {links}
          </div>
          {lastSMS && (
            <Timer
              className={cx('timer')}
              lastSMS={lastSMS}
              onTimeOut={this.handleTimeOut}
              timeout={smsServiceData.sendInterval}
            />
          )}
          {mobileAppBanner.showOnPage && (
            <MobileAppBanner
              {...smsServiceData.banners.mobileApp}
              className={cx('mobileBanner')}
              currentCtn={currentCtn}
              sendMessageUrl={smsServiceData.mobileAppLinkOverSmsUrl}
            />
          )}
          <Popup
            advert={smsServiceData.banners.advert || {}}
            ctn={ctn}
            currentCtn={currentCtn}
            errorMessage={errorMessage}
            mobileAppBanner={mobileAppBanner.showInPopup && mobileAppBanner}
            onClose={this.handlePopupClose}
            sendMessageUrl={smsServiceData.mobileAppLinkOverSmsUrl}
            status={status}
          />
        </Form>
      </div>
    );
  }
}

SmsServiceExtension.propTypes = {
  initSmsServiceStore: PropTypes.func,
  putCtnToHistory: PropTypes.func,
  updateLastSmsTime: PropTypes.func,
  data: PropTypes.object,
  ctnList: PropTypes.any,
  smsHistory: PropTypes.object,
  lastSMS: PropTypes.number,
  currentCtn: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => ({
  putCtnToHistory: (ctn) => dispatch(updateSmsHistory(ctn)),
  initSmsServiceStore: () => dispatch(initSmsService()),
  updateLastSmsTime: (time) => dispatch(updateLastSmsTime(time)),
});

export default connect(
  (state) => ({
    ctnList: state.external.ctnList,
    smsHistory: state.external.smsHistory,
    lastSMS: state.external.smsHistory ? state.external.smsHistory.lastSmsTime || null : null,
    currentCtn: pathOr(null, ['external', 'currentCtn'], state),
  }),
  mapDispatchToProps,
)(SmsServiceExtension);
