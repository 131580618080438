import React from 'react';
import classNames from 'classnames/bind';
import { TradeInIcon } from '@beef/ui-kit';

import { clearState } from '../../../store/persistor';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** ТОЛЬКО В DEV РЕЖИМЕ! Компонент кнопки быстрой очистки кэша из SessionStorage */
const DevelopmentClearCache = () => {
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }
  return (
    <button
      aria-label="ONLY DEV: Сброс кэша и перезагрузка"
      className={cx('button-reset', 'button-reload')}
      onClick={() => {
        clearState();
        window.location.reload();
      }}
      title="ONLY DEV: Сброс кэша и перезагрузка"
      type="button"
    >
      <TradeInIcon />
    </button>
  );
};

export default DevelopmentClearCache;
