import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';

import {
  getCategories,
  getCurrentCategory,
  getShowReturnLink,
} from 'pages/SearchResultsPage/store/selectors';
import { setParametersAction } from 'pages/SearchResultsPage/store/actions';

const mapDispatchToProps = (dispatch) => ({
  resetFilter: () => {
    dispatch(setParametersAction('categories', ''));
  },
});

const mapStateToProps = (state) => ({
  categories: getCategories(state),
  selectedId: getCurrentCategory(state),
  shouldRender: !!getCategories(state).length,
  showAllCategoriesLink: !!getCategories(state).length,
  isShowReturnLink: !!getShowReturnLink(state),
});

export const withConnectCategories = (WrapperComponent) =>
  compose(connect(mapStateToProps, mapDispatchToProps), withRenderFlag)(WrapperComponent);
