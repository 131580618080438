import { isEmpty } from 'ramda';

import { ymPushEvent, ymPushParams } from 'utils/ym';
import { isEmptyValue } from 'utils/isEmptyValue';

import { CONSTRUCTOR_TABS, roamingYmEvents } from './constants';
import { MAX_REQUEST_VALUE, TEST_NAME } from './Tariff/containers/ConstructorTariff/constants';
import { FAMILY_PRICING_PERIOD, IS_DRAGON_TYPE } from './Tariff/components/Check/constants';

/**
 * @desc вспомогательная фsункиц для формирования сообщения об ошибке,
 * в случае если сервер вернул requestId === -1 при запросе на отключение тарифа/услуги
 * @param data {object} данные об услуге
 * @param serviceName {string} название услуги
 * @param serviceName {requestDescription} запрос на какое действие происходит
 * @returns {{title: string, content: string}|{}}
 */
export const createRequestInProgressMsg = (data, serviceName, requestDescription) => {
  if (!data) {
    return {};
  }

  const name = serviceName ? `"${serviceName}"` : '';

  return {
    ...data,
    title: `Запрос на ${requestDescription} ${name} успешно отправлен. За статусом
             запроса следите в <a target="_blank" rel="noopener nofollow"
             href="/customers/products/mobile/profile/#/messages">истории запросов</a>`,
    content: '*При наличии включенных уведомлений, вам поступит оповещение о подключении',
  };
};

export const makeUrl = (base, params, mapValue = (f) => f) =>
  `${base}?${Object.entries(params)
    .filter(([_k, v]) => !(typeof v === 'undefined' || v === null))
    .map(([key, value]) => `${key}=${encodeURIComponent(mapValue(value))}`)
    .join('&')}`;

export const convertedSum = (sum) => Number(sum).toFixed(2).replace(/\.00$/, '');

// Типы пассивных опций как запрет на удаление, добавлениеи простая пассивка с возможной измененной ценой
export const PASSIVE_OPTION_TYPES = {
  restrictedForAdd: 'restrictedForAdd',
  restrictedForRemove: 'restrictedForRemove',
  passive: 'passive',
};

/** Т.к. на фронт из-за сторонней бибилиотеки бэка летят ключи объекта characterTypes в виде "poweR10",
 * Необходимо преобразовывать сок активного персонажа activeOptionSoc к такому же виду,
 * чтобы успешно доставать данные из объекта characterTypes */
export const changeActiveOptionSoc = (activeOptionSoc) => {
  if (!activeOptionSoc) return null;
  const firstSocPart = activeOptionSoc?.split('')?.slice(0, 4)?.join('')?.toLowerCase();
  const secondSocPart = activeOptionSoc?.split('')?.slice(4)?.join('');
  return `${firstSocPart}${secondSocPart}`;
};

// Возвращает тип пасссивки
export const getPassiveOptionType = (platform, activeOptionSoc) => {
  if (!activeOptionSoc || !platform) return null;
  const formattedActiveOptionSoc = changeActiveOptionSoc(activeOptionSoc);
  if (
    platform.characterTypes &&
    Object.keys(platform.characterTypes).includes(formattedActiveOptionSoc)
  ) {
    const currentPassive = platform.characterTypes[formattedActiveOptionSoc];
    if (currentPassive?.isRestrictedForRemove) {
      return PASSIVE_OPTION_TYPES.restrictedForRemove;
    }
    if (currentPassive?.isRestrictedForAdd) {
      return PASSIVE_OPTION_TYPES.restrictedForAdd;
    }
    return PASSIVE_OPTION_TYPES.passive;
  }
  return null;
};

// Не знаю как лучше сделать чтобы ничего не зацепить
// Возвращает дизейбл на свитч или на субопции
export const getIsDisabledAsPassive = (option, activeOptionSoc) => {
  if (!activeOptionSoc) return { isDisabledSwitchAsPassive: false, isDisabledAllAsPassive: false };
  if (option.subOptions?.length) {
    const mainSubOption = option.subOptions.find((item) => item.isAll);
    if (
      getPassiveOptionType(mainSubOption, activeOptionSoc) ===
      PASSIVE_OPTION_TYPES.restrictedForRemove
    ) {
      return { isDisabledSwitchAsPassive: true, isDisabledAllAsPassive: true };
    }
    const passiveOption = option.subOptions.find((item) => item.characterTypes);
    if (
      passiveOption &&
      getPassiveOptionType(passiveOption, activeOptionSoc) ===
        PASSIVE_OPTION_TYPES.restrictedForRemove
    ) {
      return { isDisabledSwitchAsPassive: true, isDisabledAllAsPassive: false };
    }
    return { isDisabledSwitchAsPassive: false, isDisabledAllAsPassive: false };
  }
  if (getPassiveOptionType(option, activeOptionSoc) === PASSIVE_OPTION_TYPES.restrictedForRemove) {
    return { isDisabledSwitchAsPassive: true, isDisabledAllAsPassive: true };
  }
  return { isDisabledSwitchAsPassive: false, isDisabledAllAsPassive: false };
};

const optionSum = (option, rate, activeOptionSoc) => {
  if (!option[rate]) {
    // Рачсет стоимости all опций по пассивкам
    if (activeOptionSoc && getPassiveOptionType(option?.subOptions?.[0], activeOptionSoc)) {
      return option.subOptions[0]?.characterTypes?.[activeOptionSoc]?.[rate] || 0;
    }

    if (option?.subOptions[0]?.defaultEnabled) {
      return option.subOptions[0][rate];
    }

    if (
      activeOptionSoc &&
      option?.subOptions?.find(
        (item) => item.characterTypes && Object.keys(item.characterTypes).includes(activeOptionSoc),
      )
    ) {
      return option.subOptions.reduce(
        (acc, subOption) =>
          subOption?.characterTypes?.[activeOptionSoc]?.[rate] ?
            // eslint-disable-next-line no-unsafe-optional-chaining
            acc + subOption?.characterTypes?.[activeOptionSoc]?.[rate]
          : acc,
        0,
      );
    }

    return option.subOptions.reduce(
      (acc, subOption) => (subOption.defaultEnabled ? acc + subOption[rate] : acc),
      0,
    );
  }

  // Рачсет стоимости опций по пассивкам
  if (activeOptionSoc && getPassiveOptionType(option, activeOptionSoc)) {
    return option?.characterTypes?.[activeOptionSoc]?.[rate] || 0;
  }

  return option[rate];
};

const optionSoc = (option, activeOptionSoc) => {
  if (option?.subOptions?.length) {
    const mainSubOptions = [];
    const includesSubOptions = [];

    option.subOptions.forEach((subOption) => {
      if (
        subOption.defaultEnabled ||
        (activeOptionSoc &&
          subOption.characterTypes &&
          Object.keys(subOption.characterTypes).includes(activeOptionSoc))
      ) {
        // eslint-disable-next-line no-unused-expressions
        subOption.isAll ?
          mainSubOptions.push(subOption.soc)
        : includesSubOptions.push(subOption.soc);
      }
    });
    return mainSubOptions.length ? mainSubOptions : includesSubOptions;
  }
  return [option.soc];
};

export const roamingYmEventsTypes = {
  type: 'type',
  faqClick: 'faqClick',
  faqLink: 'faqLink',
  buttonClick: 'buttonClick',
  loginClick: 'loginClick',
  getPassword: 'getPassword',
  authFillForm: 'authFillForm',
  authSuccess: 'authSuccess',
  requestFailed: 'requestFailed',
  requestSuccess: 'requestSuccess',
  phoneClick: 'phoneClick',
  toUp: 'toUp',
  calculationPhone: 'calculationPhone',
  calculationTariff: 'calculationTariff',
  applyService: 'applyService',
};

const pushRoamingYm = (value) => {
  ymPushParams({ roaming_ev: value });
};

export const handleRoamingYm = ({ type, actionType, value }) => {
  if (!type || !actionType) return;
  if (
    !roamingYmEvents[type] ||
    !roamingYmEvents[type]?.[actionType] ||
    !roamingYmEvents[type]?.type
  )
    return;
  if (value) {
    pushRoamingYm({
      [roamingYmEvents[type]?.type]: { [roamingYmEvents[type]?.[actionType]]: value },
    });
    return;
  }
  pushRoamingYm({ [roamingYmEvents[type]?.type]: roamingYmEvents[type]?.[actionType] });
};

const optionEntityName = (option, activeOptionSoc) => {
  if (option?.subOptions?.length) {
    const mainSubOptions = [];
    const includesSubOptions = [];

    option.subOptions.forEach((subOption) => {
      if (
        subOption.defaultEnabled ||
        option.isHideInCheck ||
        (activeOptionSoc &&
          subOption.characterTypes &&
          Object.keys(subOption.characterTypes).includes(activeOptionSoc))
      ) {
        // eslint-disable-next-line no-unused-expressions
        subOption.isAll ?
          mainSubOptions.push(subOption.entityName)
        : includesSubOptions.push(subOption.entityName);
      }
    });
    return mainSubOptions.length ? mainSubOptions : includesSubOptions;
  }
  return [option.entityName];
};

/**
 * На текущий момент в ф-ции initPrice важен порядок полей в возвращаемом объекте!
 * Будем фиксить в рамках редизайна.
 * @param option
 * @param initChecked
 */
// Используется в опциях разово и в утилитах в initializeOptions и initOptions
export const initPrice = (option, initChecked, activeOptionSoc) => {
  const formattedActiveOptionSoc = activeOptionSoc ? changeActiveOptionSoc(activeOptionSoc) : null;
  return {
    sum: optionSum(option, 'longRcRate', formattedActiveOptionSoc),
    sumDay: optionSum(option, 'shortRcRate', formattedActiveOptionSoc),
    discountSum: optionSum(option, 'longRcRateWithDiscount', formattedActiveOptionSoc),
    discountSumDay: optionSum(option, 'shortRcRateWithDiscount', formattedActiveOptionSoc),
    title: option.title,
    checked: initChecked,
    soc: optionSoc(option, formattedActiveOptionSoc),
    entityName: optionEntityName(option, formattedActiveOptionSoc),
    isHideInCheck: option.isHideInCheck,
  };
};

export const initChecked = (option, activeOptionSoc) => {
  const formattedActiveOptionSoc = activeOptionSoc ? changeActiveOptionSoc(activeOptionSoc) : null;
  if (option.subOptions.find((subOption) => subOption.defaultEnabled)) {
    return true;
  }

  if (
    option.subOptions.find(
      (subOption) =>
        getPassiveOptionType(subOption, activeOptionSoc) ===
        PASSIVE_OPTION_TYPES.restrictedForRemove,
    )
  ) {
    return true;
  }

  if (
    activeOptionSoc &&
    option.characterTypes &&
    Object.keys(option.characterTypes).includes(formattedActiveOptionSoc)
  ) {
    return true;
  }

  return option.defaultEnabled;
};

// Используется только в initializeOptions
export const initializeOptionsChecked = (options, activeOptionSoc) =>
  Object.fromEntries(
    options.map((option, i) => {
      const formattedActiveOptionSoc =
        activeOptionSoc ? changeActiveOptionSoc(activeOptionSoc) : null;

      if (option.subOptions.find((subOption) => subOption.defaultEnabled)) {
        return [i, true];
      }

      if (
        activeOptionSoc &&
        option.subOptions.find(
          (subOption) =>
            subOption.characterTypes &&
            Object.keys(subOption.characterTypes).includes(formattedActiveOptionSoc),
        )
      ) {
        return [i, true];
      }

      if (
        activeOptionSoc &&
        option.characterTypes &&
        Object.keys(option.characterTypes).includes(formattedActiveOptionSoc)
      ) {
        return [i, true];
      }

      return [i, option.defaultEnabled];
    }),
  );

// используется в index конструктора для инициализации и в ContructorCardBodyPopup
// ContructorCardBodyPopup не знаю что такое
export const initializeOptions = (options, activeAnimalSoc) => {
  const checked = initializeOptionsChecked(options, activeAnimalSoc);
  return Object.fromEntries(
    options.map((option, i) => [
      i,
      initPrice(
        option,
        checked[i],
        activeAnimalSoc || (option.isHideInCheck && activeAnimalSoc === IS_DRAGON_TYPE),
      ),
    ]),
  );
};

/**
 * Функция hasRemainingSize проверяет
 * наличие остатков по пакетам
 */
export const handleOrderModal = ({
  data,
  state,
  orderModalPending,
  determineOrderModal,
  setOrderModalPending,
  updateConnectedTariff,
}) => {
  if (!data.connectPopupUrl || orderModalPending) {
    return undefined;
  }
  setOrderModalPending(true);

  return determineOrderModal({
    data,
    setOrderModalPending,
    socsOn: state.socsOn,
    updateConnectedTariff,
    socsOff: state.socsOff,
    activeTab: state.activeTab,
  });
};

/**
 * Функция checkRemainingSize проверяет
 * наличие остатков по пакетам
 * @returns {boolean}
 */
export const checkRemainingSize = (service) =>
  service?.gbytes?.remainingSize > 0 ||
  service?.minutes?.remainingSize > 0 ||
  service?.sms?.remainingSize > 0;

/**
 * Функция checkConnectedOptions проверяет
 * наличие подключенных опций
 * @returns {boolean}
 */
export const checkConnectedOptions = (options) => {
  if (!options) return undefined;
  return Object?.values(options)?.find((option) => !!option?.isConnectedPeriod);
};

/**
 * Функция searchConnectedSoc определяет
 * подключенный soc опции
 * @returns {string}
 */
export const searchConnectedSoc = (tabs) =>
  tabs?.map((tab) => (tab.isConnected ? tab.soc : null))?.find((el) => el);

/**
 * Функция initActivePeriodTab определяет
 * наличие в массиве объектов (у объекта) флага isActive и возвращает индекс,
 * используемый для указания дефолтного таба.
 * @returns {number}
 */
export const initActivePeriodTab = (tabs) => tabs?.findIndex((tab) => tab?.isActive);

/**
 * Функция currentTitle определяет текущий title для рейнджа,
 * сравнивая тип текущего рейнджа с типом из контентного объекта.
 * @returns {string}
 */
export const currentTitle = (content, type) => content?.find((el) => el.type === type)?.title;

/**
 * Функция countCommonPrice определяет тип перебираемого объекта
 * (packages или options) и суммирует общий прайс для чека
 * @returns {number}
 */
export const countCommonPrice = (data, type) =>
  Object.values(data).reduce((sum, current) => {
    if (type === 'packages') {
      return current.price ? sum + current.price : sum;
    }
    if (type === 'options' && current.checked) {
      if (current.isConnectedPeriod) {
        return sum;
      }
      return current.price ? sum + current.price : sum;
    }

    return sum;
  }, 0);

/**
 * Функция initOptionsZ инициализирует начальный объект опций
 * @returns {object}
 */
export const initOptionsZ = (options) =>
  options?.reduce((acc, option) => {
    const selectedPeriod = initActivePeriodTab(option?.tabs);
    acc[option.title] = {
      ...option,
      checked: option.checked,
      price: option.tabs[selectedPeriod]?.price,
      period: option.tabs[selectedPeriod]?.expirationPeriod,
      unit: option.tabs[selectedPeriod]?.unit,
      isConnectedPeriod: option.tabs[selectedPeriod]?.isConnected,
      soc: option.tabs[selectedPeriod]?.soc,
      connectedSoc: searchConnectedSoc(option.tabs) || null,
    };
    return acc;
  }, {});

/**
 * Функция initSlidesObjectZ инициализирует начальный объект пакетов
 * @returns {object}
 */
export const initSlidesObjectZ = (slides) =>
  slides?.reduce((acc, slide) => {
    const selectedPeriod = initActivePeriodTab(slide?.tabs);

    acc[slide.type.toLowerCase()] = {
      value: slide.steps[0].size,
      initialPeriod: slide.initialPeriod,
      unit: slide.unit,
      checked: slide?.steps[0]?.stepPeriods[selectedPeriod]?.defaultEnabled,
      remainingSize: slide.remainingSize,
      initialSize: slide.initialSize,
      period: slide?.tabs[selectedPeriod]?.expirationPeriod,
      soc: slide?.steps[0]?.stepPeriods[selectedPeriod]?.soc,
      price: slide?.steps[0]?.stepPeriods[selectedPeriod]?.price,
      unitPrice: slide?.steps[0]?.stepPeriods[selectedPeriod]?.unit,
      defaultEnabled: slide?.steps[0]?.stepPeriods[selectedPeriod]?.defaultEnabled,
      socAutoProlong: slide?.steps[0]?.stepPeriods[selectedPeriod]?.autoProlongSoc,
    };
    return acc;
  }, {});

/**
 * Функция checkChangeSettings сравнивает начальный и результирующий объект
 * @returns {boolean}
 */
export const checkChangeSettings = (init, result) =>
  JSON.stringify(init) === JSON.stringify(result);

export const formatTemplate = (template, { value, unit }) =>
  template.replace('{{value}}', value).replace('{{unit}}', unit);

export const initSlidesObject = (slides) =>
  slides.reduce((acc, slide) => {
    acc[slide.type.toLowerCase()] = {
      value: slide.steps[slide.defaultIndex].value,
      unit: slide.unit,
    };
    return acc;
  }, {});

export const initOptions = (options, activeOptionSoc) =>
  options.reduce((acc, option, idx) => {
    const formattedActiveOptionSoc =
      activeOptionSoc ? changeActiveOptionSoc(activeOptionSoc) : null;

    acc[idx] = initPrice(option, initChecked(option, formattedActiveOptionSoc));
    return acc;
  }, {});

export const convertSum = (sum) => Number(sum).toFixed(2).replace(/\.00$/, '');

/**
 * Функция getInitData формирует дефолтное состояние для каждого таба
 * @returns {object}
 */
export const getInitData = (data, name) => ({
  initPackages: initSlidesObjectZ(data[name].slides),
  initOptions: initOptionsZ(data[name].tariffOptions),
  ...data[name],
});

/**
 * Функция getInitData сохраняет данные между MNPForm и SimOrderForm табами,
 * а так же управляет подгрузкой дефолтных данных
 * @returns {object}
 */
export const changeCurrentZInfo = (store, action) => {
  let { slides, checkInfo, saveDataTab, tariffOptions, resultOptions, resultPackages } = store;

  if (store.activeTab === 'ChangeTariffForm') {
    slides = store.newNumber?.slides;
    tariffOptions = store.newNumber?.tariffOptions;
    checkInfo = store.newNumber?.checkInfo;
    resultPackages = store.newNumber?.initPackages;
    resultOptions = store.newNumber?.initOptions;
  } else if (
    ((store.activeTab === 'MNPForm' || store.activeTab === 'SimOrderForm') &&
      (action === 'MNPForm' || action === 'SimOrderForm')) ||
    !store.hasZTariff
  ) {
    saveDataTab = true;
  } else {
    slides = store.currentNumber?.slides;
    tariffOptions = store.currentNumber?.tariffOptions;
    checkInfo = store.currentNumber?.checkInfo;
    resultPackages = store.currentNumber?.initPackages;
    resultOptions = store.currentNumber?.initOptions;
  }

  if (action === 'ChangeTariffForm' && store.hasZTariff) {
    saveDataTab = false;
  }

  return {
    resultPackages,
    resultOptions,
    slides,
    tariffOptions,
    checkInfo,
    saveDataTab,
  };
};

/**
 * Функция hasShutDownService вернет true,
 * если какая-либо из подключенных услуг отключается
 * @returns {boolean}
 */
export const hasShutDownService = (serviceData) =>
  !!serviceData?.find((service) => service.defaultEnabled && !service.checked);

/**
 * Filter tabs on isConnected, isAvailable, isUnavailable
 * @param {Object[]} tabs - Array of tabs.
 * @param {boolean} isAuth - Is user authenticated.
 * @param {boolean} isConnected - Is tariff connected.
 * @param {boolean} isAvailable - Is tariff available to connect.
 * @return {Object[]} tabs - Array of tabs.
 */
export const getTabsFromContentArray = ({ tabs, isAuth, isConnected, isAvailable }) => {
  if (isAuth && !isConnected && !isAvailable) {
    return tabs.filter(
      (tab) => tab.id === CONSTRUCTOR_TABS.SimOrderForm || tab.id === CONSTRUCTOR_TABS.MNPForm,
    );
  }

  return tabs;
};

const convertPercentToCoefficient = (percent) => 1 - percent / 100;

export const getDiscountPrice = (percents, price) => {
  if (percents) {
    return +convertedSum(convertPercentToCoefficient(percents) * price);
  }
  return 0;
};

const calculateDiscountAmount = (
  activeTab,
  isChangeTariff,
  discounts,
  characterDiscounts,
  gbAndMinutesRate,
) => {
  const chosenTabs = {
    simOrderForm: activeTab === CONSTRUCTOR_TABS.SimOrderForm,
    mnpForm: activeTab === CONSTRUCTOR_TABS.MNPForm,
    changeTariffForm: isChangeTariff,
  };

  let gbAndMinutesDiscountRate = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const tab of Object.keys(chosenTabs)) {
    if (chosenTabs[tab] && discounts[tab] && characterDiscounts && characterDiscounts[tab]) {
      gbAndMinutesDiscountRate =
        gbAndMinutesRate * convertPercentToCoefficient(discounts[tab] + characterDiscounts[tab]);
      break;
    }
    if (chosenTabs[tab] && characterDiscounts && characterDiscounts[tab]) {
      gbAndMinutesDiscountRate =
        gbAndMinutesRate * convertPercentToCoefficient(characterDiscounts[tab]);
      break;
    }
    if (chosenTabs[tab] && discounts[tab]) {
      gbAndMinutesDiscountRate = gbAndMinutesRate * convertPercentToCoefficient(discounts[tab]);
      break;
    }
  }
  return gbAndMinutesDiscountRate;
};

export const curry = (func) => {
  const curried = (...args) => {
    if (args.length >= func.length) {
      return func(...args);
    }
    return (...args2) => curried(...args.concat(args2));
  };

  return curried;
};

export const curriedCalculateDiscount = curry(calculateDiscountAmount);

export const getDifference = (firstArr, secondArr) => {
  const result = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const elem of firstArr) {
    if (!secondArr.includes(elem)) {
      result.push(elem);
    }
  }
  return result;
};

export const getCurrentStringParts = (stringParts) =>
  Object.keys(stringParts).filter((part) => stringParts[part]);

export const getStringFromParts = (parts, strings) =>
  parts.reduce((totalString, part) => totalString + strings[part], '');

export const totalPartnersPrice = (subscriptions) =>
  subscriptions.reduce((sum, { hasTrial, trialPrice, price }) => {
    if (hasTrial && trialPrice) {
      return sum + trialPrice;
    }
    if (!hasTrial) {
      return sum + price;
    }
    return sum;
  }, 0);

export const isSingleSubscriptionHasTrial = (subscriptions) => {
  if (subscriptions.length === 1 && subscriptions[0].hasTrial) {
    return subscriptions[0].hasTrial;
  }
  return false;
};

const getCheckedOptionsInPreset = (tariffOptions, selectedOptions) =>
  tariffOptions?.map((tariffOption) => {
    if (tariffOption?.subOptions?.length) {
      const newSubOptions = tariffOption?.subOptions?.map((subOption) => {
        if (selectedOptions.some((entityName) => entityName === subOption.entityName)) {
          if (subOption.entityName === tariffOption.entityName) {
            return { ...subOption, isAll: true, defaultEnabled: true };
          }
          return { ...subOption, defaultEnabled: true };
        }
        return subOption;
      });

      return { ...tariffOption, subOptions: newSubOptions };
    }
    if (selectedOptions.some((entityName) => entityName === tariffOption.entityName)) {
      return { ...tariffOption, defaultEnabled: true };
    }

    return tariffOption;
  });

const checkedOptions = (option) =>
  option.subOptions.map((subOption) => {
    if (subOption.isAll) {
      return { ...subOption, defaultEnabled: true };
    }
    return { ...subOption, defaultEnabled: subOption.isAll };
  });

const getCurrentOptions = (options) =>
  options.map((option) => {
    const childOptionsOnly = option.subOptions.filter((subOption) => !subOption.isAll);
    const isAllSelected =
      childOptionsOnly.length ?
        childOptionsOnly.every((subOption) => subOption.defaultEnabled)
      : false;
    if (isAllSelected) {
      const newSubOptions = checkedOptions(option);

      return { ...option, subOptions: newSubOptions };
    }
    return option;
  });

// Выбранные опции сохраются при смене пресета
export const currentTariffOptions = (tariffOptions, selectedOptions) => {
  const checkedSelectedOption = getCheckedOptionsInPreset(tariffOptions, selectedOptions) || [];
  return getCurrentOptions(checkedSelectedOption);
};

export const getDefaultCharacter = (characters) =>
  characters?.length > 0 &&
  (characters.find((tab) => tab.isActive) ||
    characters.find((tab) => tab.isDefault) ||
    characters[0]);

export const getActiveCharacter = (characters, activeOptionSoc) =>
  characters?.length > 0 &&
  characters.find(
    (character) =>
      character.optionSoc === activeOptionSoc ||
      character.power?.optionSoc === activeOptionSoc ||
      character.highPower?.optionSoc === activeOptionSoc,
  );

/**
 * Функция определения дизейбла кнопки в чеке для TariffUp при наличии блока семьи;
 */
export const useDisableButtonForFamily = ({ isChangeTariff, hasAttentionText }) => {
  let isDisableFamilyButton = false;
  if (isChangeTariff && hasAttentionText) {
    isDisableFamilyButton = true;
  }
  return { isDisableFamilyButton };
};

/**
 * Функция определения цены в блоке семьи для TariffUp;
 * При наличии свкидки вернет флаг на ее отображение и сумму  учетом скидки.
 */
export const useCurrentPrice = ({
  price,
  tabs,
  activeOptionSoc,
  familyNumberedPriceToggleEnabled,
}) => {
  let showDiscount = !!activeTabDiscount && !!price;
  const currentTab = tabs?.find((tab) => tab.optionSoc === activeOptionSoc);
  const activeTabDiscount = currentTab?.familyDiscount;
  let currentDiscountPrice = price;
  const currentPricePeriod = FAMILY_PRICING_PERIOD?.[currentTab?.pricePeriodForFamily];
  const tabPrice = currentTab?.priceForFamily;

  if (familyNumberedPriceToggleEnabled) {
    if (activeTabDiscount) {
      // если в priceForFamily приходит цена
      if (!isEmptyValue(tabPrice)) {
        currentDiscountPrice = tabPrice;
      } else {
        // если priceForFamily приходит null or undefined не отображать ценник на блоке Пономерной Семьи
        currentDiscountPrice = null;
        showDiscount = null;
      }
    } else {
      currentDiscountPrice = tabPrice;
    }
  }

  if (activeTabDiscount && !familyNumberedPriceToggleEnabled) {
    currentDiscountPrice = Math.ceil(price * activeTabDiscount);
  }

  return { showDiscount, currentDiscountPrice, currentPricePeriod, tabPrice };
};

/**
 * Функция подготовки и отправки данных в метрику для TariffUp;
 * @return {Object}
 */
export const usePrepareAnalytics = ({
  gbytes,
  unitGb,
  minutes,
  unitMin,
  content,
  options,
  activeTab,
}) => {
  const opt = Object.values(options)?.reduce((acc, option) => {
    if (option?.checked) {
      acc = [...acc, option?.title];
    }
    return acc;
  }, []);

  const prepareToSendYaAnimalAnalytics = ({ isConnect }) => {
    const propertyName = isConnect ? 'connect' : 'go_to_connect';
    const activeTabName = content?.find((tab) => tab.id === activeTab)?.text;
    const mainPack = `${gbytes} ${unitGb} + ${minutes} ${unitMin}`;
    const plugOptions = opt.length > 0 ? opt : null;

    const analyticsData = {
      tariff_pdp_zverinyi: {
        [propertyName]: activeTabName,
        main_pack: mainPack,
        options: plugOptions,
      },
    };

    ymPushParams(analyticsData);
  };

  return { prepareToSendYaAnimalAnalytics };
};

/**
 * Функция подготовки и отправки данных
 * по активному табу персонажей (зверей) для TariffUp;
 */
export const sendAnimalsTabsYaAnalytics = ({ name }) => {
  const analyticsData = {
    tariff_pdp_zverinyi: {
      select_character: name,
    },
  };

  ymPushParams(analyticsData);
};

/**
 * Функция подготовки и отправки данных
 * по активному основному табу зверей для TariffUp;
 */
export const sendMainTabsYaAnalytics = ({ id }) => {
  const ymKeyByTabId = {
    SimOrderForm: 'Получить новый номер',
    ChangeTariffForm: 'Изменить тариф',
    MNPForm: 'Перейти с сохранением номера',
  };

  const analyticsData = {
    tariff_pdp_zverinyi: {
      click_method_connect: ymKeyByTabId[id],
    },
  };

  ymPushParams(analyticsData);
};

/**
 * Функция подготовки и отправки данных в метрику для InternetLive;
 */
export const sendInternetLiveYaAnalytics = ({ groupType, value }) => {
  const analyticsData = {
    [TEST_NAME]: {
      [groupType]: value,
    },
  };

  ymPushParams(analyticsData);
};

export const getConvergenceDescription = ({
  homeInternet,
  isShowAvailableSettings,
  activeTab,
  requestCounter,
  isConvergence,
  isAuthenticated,
  isCheckedConvergence,
  hasRequestConvergenceError,
  isSimOrderAvailableFttb,
  isOldConvergence,
}) => {
  if (hasRequestConvergenceError) {
    if (requestCounter < MAX_REQUEST_VALUE && !isCheckedConvergence) {
      return homeInternet?.content?.requestPreError;
    }
    return homeInternet?.content?.requestError;
  }
  if (activeTab === 'MNPForm') {
    return homeInternet?.content?.mnpText;
  }
  // TODO: на первом стейдже скрываем кнопку настройки ШПД в АЗ на новом номере.
  if (activeTab === 'SimOrderForm' && (isConvergence || isAuthenticated) && !isOldConvergence) {
    return homeInternet?.content?.newSimText;
  }
  if (isSimOrderAvailableFttb) {
    return homeInternet?.content?.simOrderAvailableFttbText;
  }
  if (homeInternet?.needForRetry) {
    return homeInternet?.content?.needForRetryDescription;
  }
  if (isConvergence && isShowAvailableSettings && !isOldConvergence) {
    return homeInternet?.content?.convergenceDescription;
  }
  if (isShowAvailableSettings && !homeInternet?.archiveUrl) {
    return homeInternet?.content?.availablePresetDescription;
  }
  if (isOldConvergence) {
    return homeInternet?.content?.availableArchiveDescription;
  }
  return homeInternet?.content?.notAvailablePresetDescription;
};

export const getRequestV2Discount = ({
  needLongRcRateWithDiscount,
  gbAndMinutesLongRateWithDiscount,
  characterDiscountType,
  activeCharacterType,
  longRateWithDiscount,
}) => {
  if (
    needLongRcRateWithDiscount &&
    typeof gbAndMinutesLongRateWithDiscount === 'number' &&
    typeof characterDiscountType === 'number'
  ) {
    return characterDiscountType === activeCharacterType ? gbAndMinutesLongRateWithDiscount : 0;
  }
  if (needLongRcRateWithDiscount && typeof gbAndMinutesLongRateWithDiscount === 'number') {
    return gbAndMinutesLongRateWithDiscount;
  }
  if (needLongRcRateWithDiscount) {
    return 0;
  }
  return longRateWithDiscount;
};

export const sendYmConvergentRequest = ({
  sum,
  content: { unit, monthlyPeriod },
  total,
  joinedOptions: additional,
  fttbOptions: options,
}) => {
  const isTotalExist = !isEmpty(total?.gbytes) && !isEmpty(total?.minutes);
  const sumWithUnit = `${sum.sum} ${unit}`;
  const monthlyWithPeriod = `${sumWithUnit} ${monthlyPeriod}`;
  const mobileNetwork =
    isTotalExist &&
    `${total.gbytes.value} ${total.gbytes.unit} + ${total.minutes.value} ${total.minutes.unit}`;

  ymPushEvent('ConvergentCallbackUpForm', {
    URL: document.location.href,
    fmc_ev_: {
      fmc_request_success: [
        { monthly_cost: monthlyWithPeriod },
        { total: sumWithUnit },
        ...(isTotalExist ? [{ mobile_network: mobileNetwork }] : []),
        ...(additional ? [{ additional }] : []),
        ...(options ? [{ options }] : []),
      ],
    },
  });
};

export const getRegionSearchParams = () => {
  const { hostname, search } = window.location;
  const searchParams = new URLSearchParams(search);
  const [currentRegion] = hostname.split('.');
  return {
    currentRegion,
    needOpenPopup: searchParams.get('connect') === 'true',
    previousRegion: searchParams.get('previousRegion'),
    missingTariff: searchParams.get('missingTariff'),
    missingService: searchParams.get('missingService'),
  };
};
