import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AUTH_STEPS, ID_CONFIG, LOGIN_TYPES } from '../../actions/constants';
import InitialForm from '../Forms/InitialForm';
import MobileIdForm from '../Forms/MobileIdForm';
import BeautyCtn from '../BeautyCtn/BeautyCtn';
import AuthCaptchaForm from '../Forms/AuthCaptchaForm';
import { YMAuthEvent, pushYMEvent } from '../../utils/ym';
import { savePagePathname } from '../../utils/getPageCategory';

const FormProvider = ({
  withMobileId,
  step,
  resetForm,
  links,
  data,
  loginType,
  switchForm,
  resetPassword,
  requestSMS,
  onInitFormSubmit,
  submitCtn,
  handleSetCaptcha,
  handleSubmitMobileId,
  bisUrl,
  fetchingSMS,
  loading,
  hiddenFields,
  action,
  firstTimeLogin,
  haveBroadband,
  authError,
  errorMessage,
  smsBlocked,
  className,
  mobileIdData,
  setAuthCtn,
  setAuthLogin,
  setAuthPassword,
  login,
  password,
  mobile,
  handleInput,
  handleChangeSmsStep,
  checkIsBeeline,
  cookieDomain,
  ctn,
  isAudioCaptchaEnabled,
}) => {
  const [mobileIdToken, setMobileIdTokenState] = useState(null);
  useEffect(() => {
    savePagePathname(cookieDomain);
  }, []);

  useEffect(() => {
    pushYMEvent(step);
  }, [step]);

  useEffect(
    () => () => {
      if (withMobileId && step === AUTH_STEPS.mobileId) {
        resetForm();
        setMobileIdTokenState(null);
      }
    },
    [],
  );

  const onSubmitCtn = (...args) => {
    if (onInitFormSubmit) onInitFormSubmit();
    if (submitCtn) submitCtn(...args);
  };

  /* дальше по коду использование линкс местами пока закомменчено до 2 стейджа и выяснения нюансов */
  const renderLinks = () => {
    const Links = links;
    return <Links {...{ data, step, loginType, switchForm, resetPassword, requestSMS }} />;
  };

  const renderBeautyCtn = () => (
    <BeautyCtn
      ctn={ctn}
      resetForm={() => {
        resetForm(step);
        if (step === AUTH_STEPS.smsCode) {
          YMAuthEvent.smsChangeNumber();
        }
        setMobileIdTokenState(null);
      }}
    />
  );

  const setMobileIdCaptcha = (captcha) => {
    if (!captcha.captcha) {
      return;
    }
    handleSetCaptcha(captcha);

    const onSuccess = ({ mobileidtoken, validationPassed }) => {
      if (validationPassed && mobileidtoken) {
        setMobileIdTokenState(mobileidtoken);
      }
    };
    handleSubmitMobileId(onSuccess);
  };

  const autofocus = !mobile;

  switch (step) {
    case AUTH_STEPS.initial:
    case AUTH_STEPS.smsInitial:
    case AUTH_STEPS.password:
    case AUTH_STEPS.smsCode:
    case AUTH_STEPS.mobileIdInitial:
      return (
        <InitialForm
          action={action}
          authError={authError}
          autofocus={autofocus}
          captchaData={data}
          checkIsBeeline={checkIsBeeline}
          ctn={ctn}
          data={data}
          errorMessage={errorMessage}
          fetchingSMS={fetchingSMS}
          fields={hiddenFields}
          firstTimeLogin={firstTimeLogin}
          handleChangeSmsStep={handleChangeSmsStep}
          handleInput={handleInput}
          haveBroadband={haveBroadband}
          head={renderBeautyCtn()}
          isMobileClient
          links={renderLinks()}
          loading={loading}
          login={login}
          loginType={loginType}
          password={password}
          requestSMS={requestSMS}
          setAuthCtn={setAuthCtn}
          setAuthLogin={setAuthLogin}
          setAuthPassword={setAuthPassword}
          setMobileIdToken={setMobileIdTokenState}
          smsBlocked={smsBlocked}
          step={step}
          submitCTN={onSubmitCtn}
          withMobileId={withMobileId}
        />
      );
    case AUTH_STEPS.mobileId:
      return (
        <MobileIdForm
          bisIncludeCredentials={withMobileId && mobileIdData?.bisIncludeCredentials}
          bisUrl={mobileIdData?.bisUrl}
          ctn={ctn}
          data={data}
          links={renderLinks()}
          loginType={loginType}
          mobileIdData={mobileIdData}
          mobileIdToken={mobileIdToken}
          setMobileIdToken={setMobileIdTokenState}
          submitCTN={onSubmitCtn}
        />
      );
    case AUTH_STEPS.mobileIdCaptcha:
      return (
        <AuthCaptchaForm
          bisUrl={bisUrl}
          data={data}
          formId={ID_CONFIG.mobileIdCaptcha}
          isAudioCaptchaEnabled={isAudioCaptchaEnabled}
          onCaptchaSubmit={setMobileIdCaptcha}
          onClick={() => YMAuthEvent.mobileIdCaptchaInputClick()}
          onFill={() => YMAuthEvent.mobileIdFillCaptchaInput()}
          onFormReset={() => YMAuthEvent.mobileIdCaptchaCancel()}
          onReset={() => YMAuthEvent.mobileIdCaptchaReload()}
          resetForm={resetForm}
        />
      );
    case AUTH_STEPS.smsCaptcha:
      return (
        <AuthCaptchaForm
          bisUrl={bisUrl}
          className={className}
          data={data}
          formId={ID_CONFIG.smsCaptcha}
          isAudioCaptchaEnabled={isAudioCaptchaEnabled}
          onCaptchaSubmit={requestSMS}
          onClick={() => YMAuthEvent.smsCaptchaInputClick()}
          onFill={() => YMAuthEvent.smsFillCaptchaInput()}
          onFormReset={() => YMAuthEvent.smsCaptchaCancel()}
          onReset={() => YMAuthEvent.smsCaptchaReload()}
          resetForm={resetForm}
        />
      );
    default:
      return null;
  }
};

FormProvider.defaultProps = {
  propsAnalytics: () => {},
  mobileIdData: {},
};

FormProvider.propTypes = {
  hiddenFields: PropTypes.shape({}),
  mobileIdData: PropTypes.shape({}),
  action: PropTypes.string,
  data: PropTypes.shape({}),
  bisUrl: PropTypes.string,
  ctn: PropTypes.string,
  firstTimeLogin: PropTypes.bool,
  fetchingSMS: PropTypes.bool,
  loading: PropTypes.bool,
  handleSubmitMobileId: PropTypes.func,
  submitCtn: PropTypes.func,
  handleSetCaptcha: PropTypes.func,
  onInitFormSubmit: PropTypes.func,
  switchForm: PropTypes.func,
  requestSMS: PropTypes.func,
  resetForm: PropTypes.func,
  setAuthCtn: PropTypes.func,
  links: PropTypes.func,
  resetPassword: PropTypes.func,
  haveBroadband: PropTypes.bool,
  errorMessage: PropTypes.string,
  authError: PropTypes.bool,
  step: PropTypes.oneOf(Object.keys(AUTH_STEPS).map((key) => AUTH_STEPS[key])),
  loginType: PropTypes.oneOf(Object.keys(LOGIN_TYPES).map((key) => LOGIN_TYPES[key])),
  propsAnalytics: PropTypes.func,
  withMobileId: PropTypes.bool,
  login: PropTypes.string,
  password: PropTypes.string,
  setAuthPassword: PropTypes.func,
  setAuthLogin: PropTypes.func,
  mobile: PropTypes.bool,
  handleInput: PropTypes.func,
  handleChangeSmsStep: PropTypes.func,
  cookieDomain: PropTypes.string,
  isAudioCaptchaEnabled: PropTypes.bool,
};

export default FormProvider;
