import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import Box from 'pages/ProductCard/Tariff/components/Box';

import ProductFeature from './ProductFeature';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ProductFeatures = ({ featuresList }) => (
  <div>
    {featuresList.map((feature) => (
      <div className={cx('feature-group')} key={feature.title}>
        {feature.title && (
          <div className={cx('feature-group__title')}>
            <Text size="size0-b" tagType="h1">
              {feature.title}
            </Text>
          </div>
        )}
        {feature?.features?.length > 0 && (
          <ul className={cx('features-list')}>
            {feature.features.map((item, i) => (
              <li className={cx('features-list__item')} key={item.title || i}>
                <Box borderRadius={20} size="l">
                  <ProductFeature
                    galleries={item.galleries}
                    img={item.img}
                    logo={item.logo}
                    text={item.text}
                    title={item.title}
                  />
                </Box>
              </li>
            ))}
          </ul>
        )}
      </div>
    ))}
  </div>
);

ProductFeatures.propTypes = {
  featuresList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          logo: PropTypes.string,
          title: PropTypes.string,
          text: PropTypes.string,
          img: PropTypes.string,
          /* галлерея изображения */
          galleries: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              icons: PropTypes.arrayOf(PropTypes.string),
            }),
          ),
        }),
      ),
    }),
  ),
};

export default ProductFeatures;
