import React, { memo } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const TarificationInfo = memo(({ items, extraDescription }) => (
  <ul className={cx('wrapper')}>
    {items?.map((item) => (
      <li className={cx('wrapper__item')} key={item.description}>
        {(item?.description || extraDescription) && (
          <div className={cx('wrapper__description')}>
            <Text color="grey95" size="size5-r-s">
              {item.description ?? extraDescription}
            </Text>
          </div>
        )}
        {item?.price && (
          <div className={cx('wrapper__price')}>
            <div className={cx('wrapper__price--space')}>
              <Text color="grey60" size="size5-r-s">
                {item.price}
              </Text>
            </div>
            <Text color="grey60" size="size5-r-s">
              {item.unit}
            </Text>
          </div>
        )}
      </li>
    ))}
  </ul>
));

TarificationInfo.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.number,
      unit: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  extraDescription: PropTypes.string,
};
