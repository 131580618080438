import React, { memo, useEffect, useState } from 'react';
import { ActionBar, Button, Modal } from '@beef/ui-kit';

import Captcha from 'pages-components/Captcha/Captcha';
import { CAPTCHA_STATUS } from 'pages/FTTB2021/hoc/CaptchaWithCallbackPopupHOC';
import { CaptchaPopupProps } from 'pages/FancyNumber2022/components/CaptchaWrapper/components/CaptchaPopup/types';

export const CaptchaPopup = memo<CaptchaPopupProps>(({ data, onClose, isLoading }) => {
  const {
    reCaptchaModel,
    onBackCallBtnClick,
    texts,
    captchaStatus,
    changeCaptchaStatus,
    captchaMsg,
    changeCaptchaMsg,
    captchaToken,
    changeCaptchaToken,
    isFetching,
  } = data;

  const [newCaptcha, setNewCaptcha] = useState(false);
  const [isFetchingCompleted, setIsFetchingCompleted] = useState(false);

  useEffect(() => {
    if (isFetchingCompleted && !isFetching) {
      setNewCaptcha(!newCaptcha);
      setIsFetchingCompleted(false);
    }
  }, [isFetching, isFetchingCompleted, newCaptcha]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (captchaToken && captchaStatus === CAPTCHA_STATUS.success) {
      onBackCallBtnClick();
      setIsFetchingCompleted(true);
    }
  };

  const inputOnBlur = () => {
    if (!captchaToken) {
      changeCaptchaStatus(CAPTCHA_STATUS.fail);
      changeCaptchaMsg(texts.captchaTexts.title);
    } else if (captchaToken.length < 5) {
      changeCaptchaStatus(CAPTCHA_STATUS.fail);
      changeCaptchaMsg(texts.captchaTexts.errorMsg);
    } else {
      changeCaptchaStatus(CAPTCHA_STATUS.success);
      changeCaptchaMsg('');
    }
  };

  const inputOnFocus = () => {
    changeCaptchaMsg('');
  };

  return (
    <Modal
      actionBar={
        <ActionBar isWithText size="s">
          <Button
            disabled={captchaStatus === CAPTCHA_STATUS.fail}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            {texts.captchaTexts?.button}
          </Button>
        </ActionBar>
      }
      isOpen
      onClose={onClose}
      size="s"
    >
      <Captcha
        {...reCaptchaModel}
        changeCaptchaMsg={changeCaptchaMsg}
        changeCaptchaStatus={changeCaptchaStatus}
        changeCaptchaToken={changeCaptchaToken}
        changeNewCaptcha={setNewCaptcha}
        errorMsg={captchaMsg}
        msg={texts.captchaTexts}
        newCaptcha={newCaptcha}
        onBlur={inputOnBlur}
        onFocus={inputOnFocus}
        status={captchaStatus}
      />
    </Modal>
  );
});
