import React, { memo } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TPassiveContent = {
  imageSrc?: string;
  passiveText?: string;
};

/** Блок с картинкой и текстом пассивки */
export const PassiveContent = memo<TPassiveContent>(({ imageSrc, passiveText }) => (
  <div className={cx('wrapper')}>
    {imageSrc && (
      <div className={cx('wrapper__image')}>
        <img alt="" loading="lazy" src={imageSrc} />
      </div>
    )}
    {passiveText && <Text size="size5-r-s">{passiveText}</Text>}
  </div>
));
