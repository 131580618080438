import React from 'react';
import PropTypes from 'prop-types';
import { Emoji, Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from '../../styles.pcss';

const cx = classNames.bind(styles);

export const RenderFail = ({ texts }) => (
  <>
    <div className={cx('failEmoji')}>
      <Emoji className={cx('emoji')} name="smile-sad" />
    </div>
    <Heading className={cx('popupHeading', 'fail')} level={1}>
      <span dangerouslySetInnerHTML={{ __html: texts.errorTitle }} />
    </Heading>
    <div className={cx('failContent')} dangerouslySetInnerHTML={{ __html: texts.errorContent }} />
  </>
);

RenderFail.propTypes = {
  texts: PropTypes.shape({
    errorTitle: PropTypes.string,
    errorContent: PropTypes.string,
  }),
};
