import React from 'react';
import classNames from 'classnames/bind';
import { ChevronLeftIcon, Text } from '@beef/ui-kit';

import { YMAuthEvent } from 'pages/Login/Authorization2021/utils/ym';
import { LoginPropsType } from 'pages/Login/types';

import { INDEX_URL, LOGIN_TEXTS } from '../../utils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const BackLink = ({ step }: Pick<LoginPropsType, 'step'>) => (
  <div className={cx('wrap')}>
    <a className={cx('logo')} href={INDEX_URL}>
      <img alt="билайн" src="//static.beeline.ru/upload/images/b2c/bee-logo/single.png" />
    </a>
    <Text className={cx('link')} size="size6-r">
      <span className={cx('link-icon')}>
        <ChevronLeftIcon fill="#fff" size={24} />
      </span>
      <a href={INDEX_URL} onClick={() => YMAuthEvent.backLinkClick(step)}>
        <Text className={cx('link-text')} color="grey60" size="size5-r-s">
          {LOGIN_TEXTS.BACK_LINK_HEADING}
        </Text>
      </a>
    </Text>
  </div>
);
