import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { InputGroup, Label, SmartForm, SmartInput } from '@beef/legacy-ui-kit';
import { Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import ButtonWithPreloader from 'pages-components/ButtonWithPreloader';

// Utils
import {
  getDefaultState,
  getUniqueProps,
  sendAdditionalInfo,
  simReplaceValidators,
} from 'utils/simReplacement';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AdditionalInfoStep = ({
  setStep,
  userFormData,
  data: {
    addInfo,
    addInfo: { title, fields, buttonText },
  },
}) => {
  const [data, setData] = useState({
    ...getDefaultState(addInfo.fields),
  });
  const [pending, setPending] = useState(false);

  const onFieldChange = (value, name) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const submitHandler = async () => {
    setPending(true);
    const formattedData = {
      ...data,
      ...userFormData,
    };

    const result = await sendAdditionalInfo('/sim-replace/replace', formattedData);
    setPending(false);
    return result.isSucceeded ? setStep('success') : setStep('error');
  };

  return (
    <div className={cx('component')}>
      <SmartForm
        name="claimForm"
        onSubmit={submitHandler}
        style={{ width: '100%', maxWidth: '550px' }}
        validator={simReplaceValidators}
      >
        <Heading className={cx('heading')} level={2}>
          {title}
        </Heading>
        <InputGroup>
          {fields?.map((formEl) => {
            const { fieldsName, needDisabledOnCondition, label, ...inputProps } = formEl;
            return (
              <div className={cx('form-wrap')} key={formEl.name}>
                <div className={cx('title')}>{fieldsName}</div>
                <Label htmlFor={formEl.name}>{label}</Label>
                <SmartInput
                  {...inputProps}
                  {...getUniqueProps(formEl.name)}
                  className={cx('input', { 'input-balance': formEl.name === 'balance' })}
                  disabled={needDisabledOnCondition}
                  id={formEl.name}
                  inputId={formEl.name}
                  onChange={(val) => onFieldChange(val, formEl.name)}
                  size="big"
                  value={data[formEl.name]}
                />
              </div>
            );
          })}
        </InputGroup>
        <ButtonWithPreloader className="big" isPreloader={pending} type="submit" wide>
          {buttonText}
        </ButtonWithPreloader>
      </SmartForm>
    </div>
  );
};

AdditionalInfoStep.propTypes = {
  buttonText: PropTypes.string,
  title: PropTypes.string,
  fields: PropTypes.array,
  setStep: PropTypes.func,
  userFormData: PropTypes.object,
};

export default AdditionalInfoStep;
