import axios from 'axios';

// Constants
import { requestConfig } from 'pages/FTTB/constants';
import { defaultPreset } from 'pages/ProductCard/Tariff/containers/ConstructorTariff/constants';

import {
  ERROR_COLOR,
  ONE_CONSTANT,
  REQUEST_STATUS,
  SUCCESS_COLOR,
  TWO_CONSTANT,
  ZERO_CONSTANT,
  connectionSpeed,
} from './constants';

/**
 * getConnectionStatus: текущий статус возможности подключения.
 * @param ConnectionState
 * @param HasRequestOnAddress
 * @param HighSpeedAvailable
 * @param IsHasRequestConvergent
 * @param setHighSpeed
 * @returns {string}
 */
export const getConnectionStatus = ({
  ConnectionState,
  HasRequestOnAddress,
  HighSpeedAvailable,
  IsHasRequestConvergent,
  setHighSpeed,
}) => {
  if (ConnectionState === TWO_CONSTANT && !HasRequestOnAddress) {
    return REQUEST_STATUS.isConnected;
  }

  if (
    IsHasRequestConvergent ||
    ((ConnectionState === ZERO_CONSTANT || ConnectionState === TWO_CONSTANT) && HasRequestOnAddress)
  ) {
    return REQUEST_STATUS.isExists;
  }

  if (HighSpeedAvailable === -1) {
    setHighSpeed(true);
    return REQUEST_STATUS.isAvailable;
  }

  if (
    ConnectionState === ONE_CONSTANT &&
    (HighSpeedAvailable === connectionSpeed.lowSpeed ||
      HighSpeedAvailable === connectionSpeed.middleSpeed ||
      HighSpeedAvailable === connectionSpeed.highSpeed)
  ) {
    const isHighSpeed =
      HighSpeedAvailable === connectionSpeed.middleSpeed ||
      HighSpeedAvailable === connectionSpeed.highSpeed;
    setHighSpeed(isHighSpeed);
    return REQUEST_STATUS.isAvailable;
  }

  return REQUEST_STATUS.isNotAvailable;
};

/**
 * getNotificationFill: определение цвета натификации в AddressRequestForm.
 * @param status
 * @returns {string|null}
 */
export const getNotificationFill = (status) => {
  const {
    isExists,
    isAvailable,
    isNotAvailable,
    houseIsNotFound,
    regionIsNotFound,
    streetIsNotFound,
    regionIsNotConnect,
    isHighSpeedAvailable,
  } = REQUEST_STATUS;
  const successType = [isExists, isAvailable, isHighSpeedAvailable]?.includes(status);
  const errorType = [
    isNotAvailable,
    houseIsNotFound,
    regionIsNotFound,
    streetIsNotFound,
    regionIsNotConnect,
  ]?.includes(status);

  if (errorType) {
    return ERROR_COLOR;
  }

  if (successType) {
    return SUCCESS_COLOR;
  }

  return null;
};

/**
 * getRegionTariffs: получение тарифов в регионе.
 * @param regionId
 * @param soc
 * @param generation
 * @returns {AxiosPromise<any>}
 */
export const getRegionTariffs = ({ soc, regionId, generation }) =>
  axios.get(
    `/customers/products/home/request/getpresetsup/?regionId=${regionId}&soc=${soc}&generation=${generation}`,
    requestConfig,
  );

/**
 * fullOrderServicesMapper: маппер  для fullOrderServices, входящий в fullOrderGroupServices массива allTariffs.
 * @param data
 * @param inacId
 * @returns {*}
 */
const fullOrderServicesMapper = ({ data, inacId }) => {
  let groupName = '';
  let isParentChecked = false;

  return data?.map((service) => {
    const isServiceGroup = service.inacId === service.groupName;
    const isGroupElement = service.groupName === groupName;
    const isServiceInacId = service.inacId === inacId;

    if (isServiceGroup && isServiceInacId) {
      isParentChecked = service.checked;
      groupName = service.inacId;
    }

    // Текущий не групповой "чекрыжик"
    if (isServiceInacId) {
      return { ...service, checked: !service.checked };
    }

    // Текущая группа "чекрыжей", которая работает в связке
    if (isGroupElement && !isServiceInacId) {
      const isChecked = isParentChecked ? false : !service.checked;
      const isEnabled = !!(service.checked || isParentChecked) || service.defaultEnabled;

      return { ...service, checked: isChecked, enabledInTariff: isEnabled };
    }

    return service;
  });
};

/**
 * fullOrderGroupServicesMapper: маппер для fullOrderGroupServices, который входит в состав массива allTariffs.
 * @param data
 * @param inacId
 * @returns {*}
 */
const fullOrderGroupServicesMapper = ({ data, inacId }) =>
  data?.map((services) => ({
    ...services,
    fullOrderServices: fullOrderServicesMapper({ data: services.fullOrderServices, inacId }),
  }));

/**
 * tariffsMapper: маппер для массива allTariffs,
 * который содержит все доступные пресеты по указанному пользователем адресу.
 * @param inacId
 * @param allTariffs
 * @param tariffInacId
 * @returns {*}
 */
export const tariffsMapper = ({ inacId, allTariffs, tariffInacId }) =>
  allTariffs?.map((tariff) =>
    tariff.inacId === tariffInacId ?
      {
        ...tariff,
        fullOrderGroupServices: fullOrderGroupServicesMapper({
          inacId,
          data: tariff.fullOrderGroupServices,
        }),
      }
    : tariff,
  );

/**
 * sumTotalServicesFee: общая сумма пресетов по активному табу, включая скрытые пресеты.
 * @param tariffTab
 * @returns {*}
 */
export const sumTotalServicesFee = ({ tariffTab }) =>
  tariffTab?.fullOrderGroupServices
    ?.map((service) => service?.fullOrderServices)
    .flat()
    .filter((element) => element.checked)
    .reduce((acc, { subscriptionFee }) => acc + subscriptionFee, 0);

/**
 * replaceTariff: функция встраивает настроенный пользователем тариф в общий массив доступных тарифов.
 * @param allTariffs
 * @param userTariff
 * @returns {*}
 */
export const replaceTariff = ({ allTariffs, userTariff }) =>
  allTariffs?.map((tariff) => {
    if (tariff.inacId === userTariff.inacId) {
      return userTariff;
    }
    return tariff;
  });

/** Сумма всех выбранных подписок сервиса
 * @return {number}
 * */
export const countAllSubscriptionsFee = (arr) =>
  arr
    .filter(({ checked }) => checked)
    .map(({ subscriptionFee }) => subscriptionFee)
    .reduce((sum, nextFee) => sum + nextFee, 0);

/**
 * Функция возвращает массив отобранных ШПД-пресетов, доступных в регионе пользователя.
 * @param tariffs
 * @returns {*}
 */
export const getAvailablePresets = ({ tariffs = [] }) => {
  const availablePresets = tariffs
    .map(({ speed, name, inacId }) => ({
      hasEvent: true,
      title: speed,
      description: name,
      value: inacId,
    }))
    .sort((a, b) => a.speedValue - b.speedValue);

  return [defaultPreset, ...availablePresets];
};
