import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { FmcServicesTabsContainer } from './components/FmcServicesTabsContainer';
import { InternetServicesContainer } from './components/InternetServicesContainer';
import { InternetEqContainer } from './components/Equipment/InternetEqContainer';
import { TvEquipmentContainer } from './components/Equipment/TvEquipmentContainer';
import { TvServicesContainer } from './components/TvServicesContainer';
import { TvSubscriptionsContainer } from './components/TvSubscriptionsContainer';
import { HiddenServicesContainer } from './components/HiddenServicesContainer';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент настройки ШПД-сервисов */
export const FmcServiceSettings: FC = () => (
  <section className={cx('wrapper')}>
    {/* Табы доступных скоростей ШПД */}
    <FmcServicesTabsContainer />
    {/* Интернет-сервисы (Статический IP-адрес, Dr. Web Премиум и т.п.) */}
    <InternetServicesContainer />
    {/* Интернет оборудование */}
    <InternetEqContainer />
    <div className={cx('wrapper__tv')}>
      {/* ТВ-серисы ШПД */}
      <TvServicesContainer />
      {/* ТВ-подписки ШПД (Кинопоиск, IVI и т.п.) */}
      <TvSubscriptionsContainer />
    </div>
    {/* ТВ оборудование */}
    <TvEquipmentContainer />
    {/* Скрытые сервисы */}
    <HiddenServicesContainer />
  </section>
);
