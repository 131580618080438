import React, { FC } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames/bind';
import { Button, Text } from '@carnica/ui-kit';

import { IconChevronLeft } from 'constructorV1/components/tempComponents/IconChevronLeft';

import { MnpNumber } from '../MnpNumber';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TMnpForm = {
  description?: string;
  /* Флаг позволяет определить показ элемента, без необходимости реализации такой логики в родителе по условию */
  isVisible?: boolean;
  onChange?: () => void;
  onClick?: () => void;
  phone?: string;
  title?: string;
};

/** Компонент формы MNP с выбранным номером */
export const MnpForm: FC<TMnpForm> = ({
  phone,
  title,
  isVisible = false,
  description,
  onClick,
  onChange,
}) => {
  return isVisible ?
      <motion.section className={cx('wrapper')} layout>
        {title && (
          <Text align="left" color="secondary" lineHeight={20} size="xs" weight="regular">
            {title}
          </Text>
        )}
        <div className={cx('wrapper__information')}>
          <Button iconLeft={IconChevronLeft} onClick={onClick} onlyIcon view="secondary-invert" />
          <MnpNumber {...{ phone, onChange, description }} />
        </div>
      </motion.section>
    : null;
};
