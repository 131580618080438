import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { SmartForm } from '@beef/legacy-ui-kit';

import { ResultPopupContent } from 'pages-components/ResultPopup/ResultPopup';
import { HiddenInput } from 'widgets/Authorization/index';
import { AUTH_YM_EVENTS, YMAuthEvent } from 'utils/analytics/ymCommonEvents';

class ConnectionResultPopup extends Component {
  handleClick = () => {
    if (this.props.data && this.props.data.step === 'submitLogin') {
      if (this.props.data.isWrongRegionError) {
        YMAuthEvent.clickWrongRegionButton(AUTH_YM_EVENTS.CONNECT_SERVICE_PASSWORD);
      }
      const form = this.formContainer && this.formContainer.getElementsByTagName('form')[0];
      if (form) form.submit();
    } else if (this.props.onButtonClick) {
      this.props.onButtonClick();
    } else {
      this.props.closePopup();
    }
  };

  render() {
    const { data, loginForm } = this.props;

    return (
      <ResultPopupContent {...this.props} onButtonClick={this.handleClick} type="service">
        {data && data.step === 'submitLogin' ?
          <div
            ref={(node) => {
              this.formContainer = node;
            }}
            style={{ display: 'none' }}
          >
            <SmartForm action={loginForm.postUrl} method="post">
              <HiddenInput {...loginForm} />
              <input name="login" type="hidden" value={loginForm.login} />
              <input name="password" type="hidden" value={loginForm.password} />
            </SmartForm>
          </div>
        : null}
      </ResultPopupContent>
    );
  }
}

ConnectionResultPopup.propTypes = {
  data: PropTypes.object,
  loginForm: PropTypes.object,
  officeMapUrl: PropTypes.string,
  closePopup: PropTypes.func,
  onButtonClick: PropTypes.func,
};

const mapStateToProps = (state) => {
  const number = pathOr(null, ['external', 'serviceConnectionPopup', 'phone'], state);

  return {
    data: pathOr(null, ['external', 'serviceConnectionPopup', 'resultPopupData'], state),
    loginForm: pathOr({}, ['external', 'serviceConnectionPopup', 'data', 'loginForm'], state),
    textVariables: { number },
  };
};

export default connect(mapStateToProps)(ConnectionResultPopup);
