import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { BasePopupStateless, Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import ChannelsForm from './components/ChannelsForm';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ChannelsPopup = ({
  onClose,
  contentData,
  title,
  channelsData,
  onTvPackageActiveChange,
  initialSelectedTvPackageAliases,
  isWithoutUpsaleSwitch,
  hideChannels,
}) => (
  <BasePopupStateless className={cx('popup')} hasScrollOverlay isOpen onClose={onClose}>
    <div className={cx('popupContent')}>
      {channelsData ?
        <div className={cx('channelsWrap')}>
          <ChannelsForm
            contentData={contentData}
            data={channelsData}
            hideChannels={hideChannels}
            initialSelectedTvPackageAliases={initialSelectedTvPackageAliases}
            isWithoutUpsaleSwitch={isWithoutUpsaleSwitch}
            onTvPackageActiveChange={onTvPackageActiveChange}
            title={title}
          />
        </div>
      : <div className={cx('preloaderWrap')}>
          <Preloader className={cx('preloader')} />
        </div>
      }
    </div>
  </BasePopupStateless>
);

ChannelsPopup.defaultProps = { isWithoutUpsaleSwitch: false };

ChannelsPopup.propTypes = {
  onClose: PropTypes.func,
  contentData: PropTypes.object,
  channelsData: PropTypes.object,
  initialSelectedTvPackageAliases: PropTypes.array,
  isWithoutUpsaleSwitch: PropTypes.bool,
  title: PropTypes.string,
  hideChannels: PropTypes.bool,
  onTvPackageActiveChange: PropTypes.func,
};

export default memo(ChannelsPopup);
