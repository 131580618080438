import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@beef/legacy-ui-kit';

class AuthHint extends PureComponent {
  replaceLinks(texts, pattern, link) {
    if (typeof texts === 'string') {
      const result = [];
      const textParts = texts.split(pattern);
      for (let i = 0; i < textParts.length - 1; i++) {
        result.push(textParts[i]);
        result.push(link);
      }
      result.push(textParts[textParts.length - 1]);

      return result;
    } else if (Array.isArray(texts)) {
      let result = [];
      for (let i = 0; i < texts.length; i++) {
        if (typeof texts[i] === 'string') {
          result = [...result, ...this.replaceLinks(texts[i], pattern, link)];
        } else {
          result = [...result, texts[i]];
        }
      }

      return result;
    }

    return [];
  }

  render() {
    const { text, connectTariffText, authLinkText, onTariffConnect, onAuth } = this.props;
    let textParts = this.replaceLinks(
      text,
      '{{tariffLink}}',
      <Link onClick={onTariffConnect}>{connectTariffText}</Link>,
    );
    textParts = this.replaceLinks(
      textParts,
      '{{authLink}}',
      <Link onClick={onAuth}>{authLinkText}</Link>,
    );

    return (
      <span>
        {textParts.map((e, i) => (
          <span key={i}>{e}</span>
        ))}
      </span>
    );
  }
}

AuthHint.propTypes = {
  text: PropTypes.string.isRequired,
  connectTariffText: PropTypes.string.isRequired,
  authLinkText: PropTypes.string,
  onTariffConnect: PropTypes.func.isRequired,
  onAuth: PropTypes.func.isRequired,
};

export default AuthHint;
