import { TTariffInfo } from './types';
import {
  DETAILS_INFO,
  HIGH_DETAILS_INFO,
  MAIN_POPUP_KEY,
  POWER_POPUP_KEY,
} from '../../../constants';
import { pushYm } from '../../../tempAnalytics/metricUtils';

// TODO: вынести в qp?
/* Справочник типов событий */
const abilityTypes: { [index: string]: string } = {
  power: 'Способность',
  superPower: 'Суперспособность',
  partner: 'Партнерская программа',
};

/* Параметры для метрики при клике по кнопке открытия попапа персонажа */
const characterClickParams = {
  block_ability: 'upper_change',
};

/* Параметры для метрики при клике по кнопке открытия попапа остальных табов кроме персонажа */
const otherParams = {
  block_ability: 'ability_click',
};

/* Параметры для метрики при показе модального окна аппера */
const modalCharacterParams = {
  popup_type: 'uppers',
  popup_name: 'Выберите аппера',
};

/* Параметры для метрики при показе модального окна способности */
const modalPowerParams = {
  popup_type: 'ability',
};

/** Ф-я отправки параметров для кликов по табам в яндекс метрику */
export const sendClickYm = (
  key: null | string,
  upperName: string,
  chosenCharacter: TTariffInfo | undefined,
  defaultCharacter: TTariffInfo,
  isHighPreset: boolean,
) => {
  const { advantages } = chosenCharacter || defaultCharacter;
  if (!key || !advantages) return;

  /* Определение того, является ли текущая плитка вариантом Power */
  const isPowerKey = key === POWER_POPUP_KEY;
  const defineTypeInfo = isPowerKey && isHighPreset ? HIGH_DETAILS_INFO : DETAILS_INFO;

  const detailsInfo = advantages[key as keyof typeof advantages]?.[defineTypeInfo];

  const baseParams = { upper_name: upperName };
  /* Клик по персонажу */
  if (key === MAIN_POPUP_KEY) {
    pushYm({ ...baseParams, ...characterClickParams });
    pushYm({ ...{ ...modalCharacterParams, popup_action: 'view' } });
    return;
  }

  /* Клик по остальным табам */
  if (key in abilityTypes) {
    pushYm({ ...baseParams, ...otherParams, ability_type: abilityTypes[key] });
    pushYm({ ...modalPowerParams, popup_action: 'view', popup_name: detailsInfo?.title });
  }
};

type TPopupType = 'choose' | 'confirm';

const modalActionParams = {
  choose: 'choose_upper',
  confirm: 'confirm',
};

/* Отправка метрики на клик по апперу в модальном окне "Выберите аппера" при type = choose
 * по клику по кнопке "Выбрать..." в модальном окне "Выбрать аппера" при type = confirm */
export const sendPopupYm = (type: TPopupType, name = '') => {
  pushYm({
    ...modalCharacterParams,
    upper_name: name,
    popup_action: modalActionParams[type],
  });
};

/* Отправка метрики на клик по кнопке "Понятно" остальных в модальных окнах (способность, суперспособность и тд) */
export const sendExtraPopupYm = (name: string, title?: string) => {
  pushYm({
    ...modalPowerParams,
    popup_name: title,
    popup_action: 'confirm',
  });
};
