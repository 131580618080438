import { emptyObj } from '@beef/utils';

const getFttbAvailableScheduleData = (state) => state.external?.fttbAvailableSchedule || emptyObj;

export const getAvailableScheduleObj = (state) =>
  getFttbAvailableScheduleData(state).availableSchedule;
export const getAvailableScheduleList = (state) =>
  Object.values(getAvailableScheduleObj(state) || emptyObj);

const getCurrentState = (state) => getFttbAvailableScheduleData(state).state;
export const isAvailableScheduleLoading = (state) => getCurrentState(state) === 'loading';
export const isAvailableScheduleError = (state) => getCurrentState(state) === 'error';
