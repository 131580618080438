import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { Link, TextInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import Icon from 'pages-components/Icon';

import PickupPoint from './PickupPoint';
import SortButton from './SortButton';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const sortNextValues = {
  false: 'ascending',
  ascending: 'descending',
  descending: false,
};

class PickupForm extends Component {
  state = {
    showPoints: false,
    count: 5,
    filterQuery: '',
    sortaddress: false,
    sortsubway: false,
  };

  onSortClick = (alias) => () => {
    const key = `sort${alias}`;
    this.setState({
      sortaddress: false,
      sortsubway: false,
      [key]: sortNextValues[this.state[key]],
    });
  };

  togglePoints = () => {
    this.setState({
      showPoints: !this.state.showPoints,
      count: 5,
    });
  };

  showMore = () => {
    this.setState({ count: this.state.count + 25 });
  };

  onSearchChange = (filterQuery) => {
    this.setState({
      filterQuery,
      count: 5,
    });
  };

  onPointClick = (pointId) => () => {
    this.togglePoints();
    this.props.setInputValue({ pointId });
  };

  getPoints = () => {
    const { sortaddress, sortsubway, filterQuery } = this.state;
    let { points } = this.props;

    if (filterQuery) {
      points = points.filter(({ address, subwayName }) => {
        const query = filterQuery.toLowerCase();
        return subwayName.toLowerCase().includes(query) || address.toLowerCase().includes(query);
      });
    }

    if (!sortaddress && !sortsubway) return points;

    const fieldName = sortaddress ? 'address' : 'subwayName';
    const direction = sortaddress || sortsubway;

    return points.sort((a, b) => {
      // Спускаем точки без метро или адреса вниз
      if (!a[fieldName]) return 1;
      if (!b[fieldName]) return -1;
      // А дальше обычная сортировка
      if (direction === 'ascending') {
        return a[fieldName].localeCompare(b[fieldName]);
      }
      return b[fieldName].localeCompare(a[fieldName]);
    });
  };

  render() {
    const { texts, pointId } = this.props;
    const { filterQuery, showPoints, count, sortaddress, sortsubway } = this.state;
    const points = this.getPoints();
    const hiddenCount = points.length - count;
    const pointsToShow = hiddenCount > 0 ? points.slice(0, count) : points;
    const activePointData = pointId && this.props.points.find((point) => point.id === pointId);

    return (
      <div className={cx('component')}>
        <div
          className={cx('description')}
          dangerouslySetInnerHTML={{ __html: texts.description }}
        />
        {!activePointData && (
          <div className={cx('toggleContainer')}>
            <EmptyButton className={cx('toggleButton')} onClick={this.togglePoints}>
              {texts.showText}
              <Icon className={cx('arrow', showPoints && 'active')} name="dropdownArrow" />
            </EmptyButton>
          </div>
        )}
        {showPoints && (
          <div className={cx('filters')}>
            <div className={cx('searchInput')}>
              <TextInput onChange={this.onSearchChange} placeholder="Адрес или станции метро" />
              {!filterQuery && <Icon className={cx('searchIcon')} name="search" />}
            </div>
            <div className={cx('sortButtons')}>
              Сортировать по
              <SortButton
                className={cx('sortButton')}
                direction={sortaddress}
                onClick={this.onSortClick('address')}
              >
                Адресу {sortaddress === 'descending' ? 'Я-А' : 'А-Я'}
              </SortButton>
              <SortButton direction={sortsubway} onClick={this.onSortClick('subway')}>
                Метро {sortsubway === 'descending' ? 'Я-А' : 'А-Я'}
              </SortButton>
            </div>
          </div>
        )}
        {showPoints && (
          <div className={cx('points')}>
            {pointsToShow.map((office) => (
              <PickupPoint
                {...office}
                active={office.id === pointId}
                key={office.id}
                onClick={this.onPointClick(office.id)}
              />
            ))}
          </div>
        )}
        {showPoints && hiddenCount > 0 && (
          <Link className={cx('toggleLink')} onClick={this.showMore}>
            Показать ещё {hiddenCount < 25 ? hiddenCount : 25}
          </Link>
        )}
        {activePointData && (
          <div className={cx('activePoint')}>
            <Icon name="map-market" />
            <Link onClick={this.togglePoints}>
              <span
                dangerouslySetInnerHTML={{
                  __html: `${activePointData.name}. ${activePointData.address}`,
                }}
              />
            </Link>
          </div>
        )}
      </div>
    );
  }
}

PickupForm.propTypes = {
  texts: PropTypes.object,
  points: PropTypes.array,
  setInputValue: PropTypes.func,
  pointId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PickupForm;
