import React, { ReactNode, memo } from 'react';
import { Picture } from '@beef/smart-kit';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type ResponseLayoutProps = {
  description?: ReactNode;
  image?: string;
  isSmImg?: boolean;
  title?: ReactNode;
};

/**
 * Компонент для отображения контента в модальных окнах результатов api запросов
 */
export const ResponseLayout = memo<ResponseLayoutProps>(
  ({ title: titleProp, image, description: descProp, isSmImg }) => {
    const title =
      typeof titleProp === 'string' ? <Text size="size2-m">{titleProp}</Text> : titleProp;
    const description =
      typeof descProp === 'string' ?
        <Text color="grey60" size="size4-r">
          {descProp}
        </Text>
      : descProp;

    return (
      <div className={cx('response-layout')}>
        {image && <Picture className={cx('image', { isSmImg })} src={image} />}
        {title}
        {description}
      </div>
    );
  },
);
