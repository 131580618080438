import React, { FC, useMemo } from 'react';
import { Button } from '@beef/ui-kit';
import { useIsMobileDimension } from '@beef/ui-kit/hooks';

import { saveLastStep, sendSubmitAnalytics } from '../../Authorization2021/utils/ym';
import { SubmitButtonsProps } from './types';

export const SubmitButtons: FC<SubmitButtonsProps> = ({
  step,
  buttonText,
  value,
  loading,
  formId,
  disabled,
  isActiveButton,
}) => {
  const isMobile = useIsMobileDimension();
  const handleClick = () => {
    saveLastStep(step);
    sendSubmitAnalytics(step);
  };

  const isDisabled = useMemo(() => {
    if (isActiveButton) {
      return false;
    }
    return !value || disabled;
  }, [value, disabled, isActiveButton]);
  return (
    <Button
      disabled={isDisabled}
      extraAttributes={{ form: formId }}
      fullWidth={isMobile}
      isLoading={loading}
      onClick={handleClick}
      size="m"
      type="submit"
    >
      {buttonText}
    </Button>
  );
};
