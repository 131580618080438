import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { TextsContainer } from './components/TextsContainer';
import { DetailsInfo } from './components/DetailsInfo';
import { TItemDetailsInfo } from './components/DetailsInfo/components/ItemDetailsInfo';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TModalHeader = {
  description?: string;
  detailsInfo?: TItemDetailsInfo[];
  image?: string;
  title?: string;
};

/** Компонент extra-контента для попапов.
 * В тарифе UP это power, superPower и partner */
export const ModalHeader: FC<TModalHeader> = ({ image, title, description, detailsInfo }) => (
  <div className={cx('wrapper')}>
    {image && <img alt="" className={cx('wrapper__image')} loading="lazy" src={image} />}
    <TextsContainer {...{ title, description }} />
    <DetailsInfo {...{ detailsInfo }} />
  </div>
);
