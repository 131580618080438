import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TDescription = {
  description: string;
};

/** Описание попапа */
export const Description: FC<TDescription> = ({ description }) => (
  <Text className={cx('component')} color="grey60" size="size5-r-l">
    {description}
  </Text>
);
