import { createReducer } from '@reduxjs/toolkit';

import {
  setAwaitingSmsCode,
  setError,
  setNumber,
  setSmsLoadingStatus,
  setSuccessStatus,
} from '../actions/change';

export const change = createReducer(
  {
    isChangeNumber: false,
    currentNumber: null,
    isAwaitingSmsCode: false,
    isLoadingSms: false,
    isSuccess: false,
    error: null,
  },
  {
    [setNumber.type]: (state, action) => {
      state.currentNumber = action.payload;
    },
    [setAwaitingSmsCode.type]: (state, action) => {
      state.isAwaitingSmsCode = action.payload;
    },
    [setSmsLoadingStatus.type]: (state, action) => {
      state.isLoadingSms = action.payload;
    },
    [setSuccessStatus.type]: (state, action) => {
      state.isSuccess = action.payload;
    },
    [setError.type]: (state, action) => {
      state.error = action.payload;
      state.isLoadingSms = false;
    },
  },
);
