import { useState } from 'react';

import { popupYmClick, popupYmClickExtra } from '../tempYmUtils';
import { useAppSelector } from '../../../../store';
import { TModalHeader } from '../../../modalComponents/ModalHeader';
import { TModalContainer } from '../../../modalComponents/ModalContainer';

/** Хук для подготовки данных модального окна информационного баннера под предварительным чеком */
export const usePromoPopup = () => {
  const contentPopup = useAppSelector((state) => state.common?.content.promoBanner?.contentPopup);

  const { link, image, title, description, extraLink, buttonText, extraButtonText } =
    contentPopup || {};

  /* Состояние модального окна баннера  */
  const [isOpen, setIsOpen] = useState(false);

  /* Функция открытия попапа  */
  const onOpen = () => setIsOpen(true);

  /* Функция закрытия попапа  */
  const onClose = () => setIsOpen(false);

  /* На данном этапе только отправляет метрику по клику на основную кнопку */
  const onClick = () => popupYmClick(title, buttonText);

  /* На данном этапе только отправляет метрику по клику на extra кнопку */
  const onClickExtra = () => popupYmClickExtra(title, extraButtonText);

  /* Проп для ModalContainer.
   Содержит текущее состояние модалки, размер и т.п. */
  const modalContainerProps: Omit<TModalContainer, 'children'> | null =
    contentPopup ?
      {
        isOpen,
        size: 'm',
        onClose,
        modalActionBarProps: {
          link: link ?? '#',
          extraLink,
          buttonText,
          onClick,
          buttonTextExtra: extraButtonText,
          onClickExtra,
        },
      }
    : null;

  /* Проп для контентной части модалки. Содержит картинку, заголовок и описание.
   * Приведение типа используется из-за опционального наличия попапа. Деструктуризация добавляет
   * неправильный undefined в случае существования contentPopup */
  const modalHeaderProps = contentPopup ? ({ image, title, description } as TModalHeader) : null;

  return {
    onOpen,
    modalHeaderProps,
    modalContainerProps,
  };
};
