import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { TScreenPropsType } from './types';

const cx = classNames.bind(styles);

export const Screen: FC<TScreenPropsType> = ({ children }) => {
  return (
    <div className={cx('wrap')}>
      <div className={cx('content')}>{children}</div>
    </div>
  );
};
