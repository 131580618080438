import { YMMetricHelper, createMetric } from '@beef/metric';

import { RootState } from '../store';
import { selectIsAuth } from '../store/slices/additionalDataSlice/selectors';

/** ТЗ на разметку аналитики: https://bwiki.beeline.ru/pages/viewpage.action?pageId=609903077 */
export class YaMNPMetric extends YMMetricHelper<RootState, ReturnType<typeof selectIsAuth>> {
  protected readonly MNP_PARAMS_KEY = 'mnp';

  protected setYaMNPPageInfo = (params: Record<string, any>) => {
    if (typeof window === 'undefined')
      return {
        [this.MNP_PARAMS_KEY]: {
          ...params,
        },
      };

    return {
      [this.MNP_PARAMS_KEY]: {
        page_title: document.title,
        page_url: window.location.href,
        page_path: window.location.pathname,
        account_type: this.getData() ? 'az' : 'nz',
        ...params,
      },
    };
  };

  /** п.1 Просмотр блока “К нам можно со своим - переходи в билайн” */
  handleViewStartBlock() {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'view',
        item_type: 'block',
        block_name: 'к нам можно со своим - переходи в билайн',
      }),
    );
  }

  /** п.2 Клик по кнопке “Познакомимся поближе” */
  handleClickButtonMeet() {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'click',
        item_type: 'button',
        button_text: 'познакомиться поближе',
        object_type: 'block',
        block_title: 'к нам можно со своим - переходи в билайн',
      }),
    );
  }

  /** п.3 Ввод номера в поле  “Номер для переноса” */
  handleClickInput() {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'fill_in',
        item_type: 'field',
        object_type: 'block',
        block_title: 'номер для переноса',
      }),
    );
  }

  /** п.4 Клик по кнопке “Начать перенос” */
  handleClickStartTransferPhoneNumber() {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'click',
        item_type: 'button',
        button_text: 'Начать перенос',
        object_type: 'block',
        block_title: 'номер для переноса',
      }),
    );

    this.sendReachGoalEvent('AddToCartMNPSimCard', { URL9: document?.location?.href });
    this.sendReachGoalEvent('SimStartMnpProcess', { URL9: document?.location?.href });
    this.sendReachGoalEvent('SimMNPAddToCart', { URL9: document?.location?.href });
  }

  /** п.5 Просмотр блока “Чтобы было быстро” */
  handleViewListBlock(blockName: string) {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'view',
        item_type: 'block',
        block_name: blockName,
      }),
    );
  }

  /** п.6 Просмотр блока “Если ты уже есть в Билайн” */
  handleViewQRBlock(title: string) {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'view',
        item_type: 'block',
        block_name: title,
      }),
    );
  }

  /** п.9 Показ блока “Как работает перенос в билайн” */
  handleViewHowItWorksBlock(titleBlock: string) {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'view',
        item_type: 'block',
        block_name: titleBlock,
      }),
    );
  }

  /** п.10 Показ блока “В билайне можно все” */
  handleViewBenefitsBlock() {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'view',
        item_type: 'block',
        block_name: 'в билайне можно все',
      }),
    );
  }

  /** п.11 Показ элементов (карточек) в "а еще билайн - это” */
  handleViewHoveredCard(cardTitle: string) {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'view',
        item_type: 'element',
        element_title: cardTitle,
        object_type: 'block',
        block_title: 'а еще билайн - это',
      }),
    );
  }

  /** п.12 Клик по элементам блока "а еще билайн - это" */
  handleClickHoveredCard(cardTitle: string) {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'click',
        item_type: 'element',
        element_title: cardTitle,
        object_type: 'block',
        block_title: 'а еще билайн - это',
      }),
    );
  }

  /** п.13 Показ блока "Часто задаваемые вопросы" */
  handleViewFAQBlock() {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'view',
        item_type: 'block',
        block_name: 'часто задаваемые вопросы',
      }),
    );
  }

  /** п.14 Клик каждому элементу (вопросу) в блоке “Часто задаваемые вопросы”  (2 обработчика) */
  handleClickShowFAQItem(itemTitle: string) {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'click',
        item_type: 'spoiler',
        spoiler_title: itemTitle,
        spoiler_status: 'show',
        object_type: 'block',
        block_title: 'часто задаваемые вопросы',
      }),
    );
  }

  handleClickHideFAQItem(itemTitle: string) {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'click',
        item_type: 'spoiler',
        spoiler_title: itemTitle,
        spoiler_status: 'hide',
        object_type: 'block',
        block_title: 'часто задаваемые вопросы',
      }),
    );
  }

  /** п.16 Клик по элементу Меню */
  handleClickOpenSidebar() {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'click',
        item_type: 'icon',
        icon_name: 'menu',
        object_type: 'floating',
      }),
    );
  }

  /** п.17 Клик по закрытию «Меню» */
  handleClickCloseSidebar() {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'click',
        item_type: 'icon',
        icon_type: 'close',
        object_type: 'main_menu',
      }),
    );
  }

  /** п.18 Клик на кнопку «Войти»/ иконка "профиля" */
  handleClickLogin() {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'click',
        item_type: 'icon',
        icon_name: 'profile',
        object_type: 'main_menu',
      }),
    );
  }

  /** п.19 Клик по каждому элементу в списке меню */
  handleClickSidebarItem(linkText: string) {
    this.sendParamsEvent(
      this.setYaMNPPageInfo({
        action: 'click',
        item_type: 'link',
        link_text: linkText,
        object_type: 'main_menu',
      }),
    );
  }
}

export const yaMNPMetric = new YaMNPMetric({
  counterId: 26001372,
  isEcommerce: false,
  debug: true,
  selector: (state) => state?.additionalData?.isAuth || false,
});

export const { Metric, decorateHandler, withMetric, withMetricContainer } = createMetric(
  {
    yaMNPMetric,
  },
  'debug',
);
