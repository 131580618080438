import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';
import PlainContainer from 'pages-components/PopupContainer/PlainContainer';
import SocialButtons from 'pages-components/SocialButtons/SocialButtons';

// Style
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Модальное окно бенефита;
 * @param onPopupClose - Callback для закрытия модального окна;
 * @param popupContent - Данные модального окна;
 * @param appButtons - Кнопки сторов;
 */
const BenefitPopup = ({ onPopupClose, popupContent, appButtons }) => (
  <PopupStateless onClose={onPopupClose} opened>
    <PlainContainer
      {...(typeof popupContent === 'string' ?
        { description: popupContent }
      : {
          ...popupContent,
          onButtonClick: popupContent.buttonUrl ? undefined : onPopupClose,
        })}
    />

    {/* Кнопки приложений и предложения */}
    {appButtons && (
      <div className={cx('buttonsGroup')}>{appButtons && <SocialButtons data={appButtons} />}</div>
    )}
  </PopupStateless>
);

BenefitPopup.defaultProps = { onPopupClose: () => null };

BenefitPopup.propTypes = {
  onPopupClose: PropTypes.func,
  appButtons: PropTypes.object,
  benefit: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  popupContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default BenefitPopup;
