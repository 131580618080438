import { cloneElement } from 'react';
import PropTypes from 'prop-types';

import { getNewItems } from 'pages/FTTB/StreetCardPage/utils';

const DescriptionsHOC = (props) => {
  const { children, categories } = props;

  return cloneElement(children, {
    items: getNewItems(props),
    categories,
  });
};

DescriptionsHOC.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType(
      PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }),
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.arrayOf(
          PropTypes.arrayOf(
            PropTypes.shape({
              text: PropTypes.string,
            }),
          ),
        ),
      }),
    ),
  ),
};

export default DescriptionsHOC;
