import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { TeaserBanner } from '@beef/smart-kit';

import { selectContent } from '../../store/selectors';
import { AudioPlayer } from '../AudioPlayer';
import styles from './styles.pcss';
import { BannerFixer } from '../BannerFixer';
import { pushAudioPlay } from '../../utils/analytics';

const cx = classnames.bind(styles);

const BeepBannerLayout = ({ audioSrc, ...bannerProps }) => (
  <BannerFixer>
    <TeaserBanner {...bannerProps}>
      {audioSrc && <AudioPlayer className={cx('player')} onPlay={pushAudioPlay} src={audioSrc} />}
    </TeaserBanner>
  </BannerFixer>
);

const mapStateToProps = (state) => selectContent(state).beepBanner;

export const BeepBanner = connect(mapStateToProps)(BeepBannerLayout);
