import React from 'react';

export const usePopupState = (initialValue = false): [boolean, () => void] => {
  const [open, setState] = React.useState<boolean>(initialValue);

  const toggleState = React.useCallback(() => {
    setState((v) => !v);
  }, []);

  return [open, toggleState];
};
