import React, { FC } from 'react';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type UserBlockProps = {
  addressTitle: string;
  phoneTitle: string;
  userAddress?: string;
  userNumber?: string;
};

const UserBlock: FC<UserBlockProps> = ({ userNumber, userAddress, phoneTitle, addressTitle }) => {
  const formattedPhone = userNumber?.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 $1 $2-$3-$4');
  return (
    <div>
      <div className={cx('address')}>
        <Text color="grey60" size="size5-r-s">
          {addressTitle}
        </Text>
        {userAddress && <Text size="size5-r-s">{userAddress}</Text>}
      </div>
      <div className={cx('number')}>
        <Text color="grey60" size="size5-r-s">
          {phoneTitle}
        </Text>
        <Text size="size5-r-s">{formattedPhone}</Text>
      </div>
    </div>
  );
};

export { UserBlock };
