export const getDigitalTariffsParams = (params) => {
  if (params.widgetType === 'list') {
    return {
      valueList: params.data.items.filter((item) => item),
      icon: params.icon,
      widgetType: params.widgetType,
    };
  }

  return params;
};

export const getDigitalCardParams = (parameters) =>
  parameters.map((item) => ({
    ...getDigitalTariffsParams(item),
    value: item.value,
    parameterType: item.widgetType === 'empty' ? 'empty' : 'digitalTariff',
  }));
