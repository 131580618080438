import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

// Components
import { RequestStatus } from '@beef/smart-kit';

// Utils
import { templateParser } from 'utils/format-string';

import { addBtnUrlPropToErrorObj } from '../../utils';

// Constants
import { ERROR_POPUP_ALIASES } from '../../constants';

// Context
import { ProductsContext } from '../../packages/context/context';

const ErrorPopupWrapper = ({ errorCodeValue, id, onPopupSwitch }) => {
  const state = useContext(ProductsContext);
  const { subscriptions, popupContent, howToPayRedirectUrl } = state;

  const chosenProduct = useMemo(
    () => subscriptions.find((subscription) => subscription.id === id) || {},
    [subscriptions, id],
  );

  const { name, partnerDetails = {} } = chosenProduct;

  const currentErrorObject = useMemo(
    () =>
      popupContent.find((content) => content.code === errorCodeValue) ||
      popupContent.find((content) => content.code === ERROR_POPUP_ALIASES.SOMETHING_GOING_WRONG) ||
      {},
    [popupContent, errorCodeValue],
  );

  const codeErrorObject = {
    [ERROR_POPUP_ALIASES.RULE_CODE_DUPLICATE]: partnerDetails?.disconnectUrl,
    [ERROR_POPUP_ALIASES.RULE_CODE_BALANCE]: howToPayRedirectUrl,
  };

  const errorObject = addBtnUrlPropToErrorObj(currentErrorObject, codeErrorObject);

  const { imgUrl, title, description, btnText, btnUrl } = errorObject;

  const replacedTitle = templateParser(title, { SERVICENAME: name });
  const replacedDescription = templateParser(description, { SERVICENAME: name });

  return (
    <RequestStatus
      button={{
        text: btnText,
        url: btnUrl,
        variant: 'main',
      }}
      description={replacedDescription}
      img={{
        src: imgUrl,
      }}
      onConfirm={btnUrl || onPopupSwitch}
      title={replacedTitle}
    />
  );
};

ErrorPopupWrapper.propTypes = {
  onPopupSwitch: PropTypes.func,
  errorCodeValue: PropTypes.string,
  id: PropTypes.number,
};

export { ErrorPopupWrapper };
