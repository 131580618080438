import axios from 'axios';

// Actions Types
import { pathOr } from 'ramda';

import store from 'store';
import { unformatPhone } from 'utils/format-string';

import {
  SET_DISCONNECT_NUMBER_POPUP,
  SET_DISCONNECT_SUCCESS_POPUP,
  SET_DISCONNECT_WARNING_POPUP,
  SET_TARIFF_CONNECTION_POPUP,
  UPDATE_CONNECTED_NUMBERS,
} from './actionTypes';
import { serverRequest } from './sharing';
import { tariffInfoUpdater } from './tariffConnect/toggleTariffConnectionPopup';

/**
 * Show/hide disconnect number for sharing pop-up.
 * @param opened {boolean} True, if pop-up opened.
 */
export const toggleDisconnectNumberPopup = (opened = false) => ({
  type: SET_DISCONNECT_NUMBER_POPUP,
  payload: {
    opened,
  },
});

/**
 * Show/hide successful disconnect number pop-up.
 * @param popupType {string} Pop-up type.
 * @return {function} Toggle function.
 */
export const toggleDisconnectSuccessPopup =
  (popupType) =>
  (opened = false) => ({
    type: SET_DISCONNECT_SUCCESS_POPUP,
    payload: {
      opened,
      type: popupType,
    },
  });

/**
 * Show/hide pop-up with disconnect last number warning.
 * @param opened {boolean} True, if open.
 */
export const toggleDisconnectWarningPopup = (opened = false) => ({
  type: SET_DISCONNECT_WARNING_POPUP,
  payload: {
    opened,
  },
});

/**
 * Returns new list of numbers with replaced status of item with {ctn}
 * @param ctn {string}
 * @param status {string}
 */
const getUpdatedNumbersList = (numbers, ctn, status) =>
  numbers.map((item) =>
    item.ctn === unformatPhone(ctn) ?
      {
        ...item,
        status,
      }
    : item,
  );

/**
 * Simple action
 * @param payload {array} of numbers
 */
const updateConnectedNumbers = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_CONNECTED_NUMBERS,
    payload,
  });
};

const removeNumberWithPolling = (number, originalStatus) => (dispatch, getState) => {
  let requestId = null;
  let timeout = 500;
  let timeoutId = null;

  const getNumbers = () =>
    pathOr(
      [],
      ['external', 'tariffConnectionPopup', 'data', 'sebConnectForm', 'numbers'],
      getState(),
    );
  const removeFromList = () => {
    dispatch(updateConnectedNumbers(getNumbers().filter((item) => item.ctn !== number)));
    if (tariffInfoUpdater.update) {
      tariffInfoUpdater.update();
    }
  };
  const returnToList = () => {
    dispatch(updateConnectedNumbers(getUpdatedNumbersList(getNumbers(), number, originalStatus)));
  };

  dispatch(updateConnectedNumbers(getUpdatedNumbersList(getNumbers(), number, 'removing')));

  const pollRemoveNumber = () => {
    if (!pathOr(false, ['external', 'tariffConnectionPopup', 'popupIsActive'], getState())) {
      return clearTimeout(timeoutId);
    }

    axios
      .post('/sebTariff/requestStatus/', { requestId })
      .then(({ data }) => {
        const { result } = data;

        if (result === true) {
          return removeFromList();
        }
        if (result === false) {
          return returnToList();
        }

        timeoutId = setTimeout(pollRemoveNumber, (timeout += timeout));
      })
      .catch(returnToList);
  };

  axios
    .post('/sebTariff/deleteOrCancel/', {
      ctn: number,
      force: true,
    })
    .then(({ data }) => {
      const result = data.result || {};

      if (!result.isSucceeded) {
        return Promise.reject();
      }

      if (!result.requestId) {
        return removeFromList();
      }

      requestId = result.requestId;
      pollRemoveNumber();
    })
    .catch(() => {
      returnToList();
    });
};

/**
 * Disconnect number for sharing tariff.
 * @param number {string} Unformatted number.
 * @param status {string}
 */
export const disconnectNumber = (number, status) => (dispatch) => {
  dispatch(removeNumberWithPolling(unformatPhone(number), status));
};

/**
 * Show disconnect number pop-up and close others.
 * @param popupType {string} Pop-up type.
 */
const showDisconnectSuccessPopup = (popupType) => (dispatch) => {
  dispatch(toggleDisconnectNumberPopup(false));
  dispatch(toggleDisconnectSuccessPopup(popupType)(true));
};

const showLastNumberDisconnectWarning = () => (dispatch) => {
  dispatch(toggleDisconnectSuccessPopup()(true));
  dispatch(toggleDisconnectWarningPopup(true));
};

const removeSdbNumberRequest = (dispatch, number) => {
  const data = pathOr({}, ['external', 'tariffConnectionPopup', 'data'], store.getState());
  const numbers = pathOr([], ['sebConnectForm', 'numbers'], data);

  return new Promise((resolve, reject) => {
    dispatch(
      serverRequest({
        key: 'remove-any',
        method: 'post',
        data: {
          ctn: unformatPhone(number),
          force: true,
        },
      }),
    ).then((resp) => {
      if (resp.data && resp.data.error && resp.data.error.message === 'LastCtnRemovalWarning') {
        reject();
        return;
      }

      const numberType = resp.data?.type || 'deleted';
      const confirmedNumbers = numbers.filter(
        (item) => item.status === 'connected' || item.status.toLowerCase() === 'confirmed',
      );
      const isLastConfirmed =
        confirmedNumbers.length === 1 && confirmedNumbers[0].number === number;
      const popupType = isLastConfirmed ? 'all' : numberType;

      const filtered = numbers.filter((item) => !(item.ctn === unformatPhone(number)));

      dispatch({
        type: SET_TARIFF_CONNECTION_POPUP,
        payload: {
          data: {
            ...data,
            sebConnectForm: {
              ...data.sebConnectForm,
              numbers: filtered,
            },
          },
        },
      });
      resolve(popupType);
    });
  });
};

export const removeSdbNumber =
  (number, force = false) =>
  (dispatch) => {
    removeSdbNumberRequest(dispatch, number, force)
      .then(({ data }) => {
        const result = data.result || {};

        if (!result.isSucceeded) {
          return Promise.reject();
        } else {
          dispatch(showDisconnectSuccessPopup(data));
        }
      })
      .catch(() => dispatch(showLastNumberDisconnectWarning()));
  };

export const removeNumber =
  (number, force = false) =>
  (dispatch) => {
    dispatch(removeSdbNumber(number, force));
  };
