import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * @desc: Обертка в виде карточки
 */
const Box = ({ color, size, borderRadius, className, children }) => (
  <div
    className={cx('wrap', `wrap--${color}`, `wrap--${size}`, className && className)}
    style={{ borderRadius: `${borderRadius}px` }}
  >
    {children}
  </div>
);

Box.defaultProps = {
  color: 'white',
  size: 'm',
  borderRadius: 4,
};

Box.propTypes = {
  /* цвет фона */
  color: PropTypes.oneOf(['white', 'grey-5']),
  /* размеры внутренних отступов обертки */
  size: PropTypes.oneOf(['l', 'm', 's']),
  borderRadius: PropTypes.number,
};

export default Box;
