import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { Button } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { useCBillButton } from './hooks/useCBillButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCBillButton = {
  btnText?: string;
};

/** Основная кнопка чека ЕК.
 * Пустой пробел используется для фикса краша компонента Button (carnica), при отсутствии children */
export const CBillButton: FC<TCBillButton> = ({ btnText = ' ' }) => {
  /** Функция-обработчик клика для главной кнопки результирующего чека */
  const { handleClick, isLoading } = useCBillButton();

  return (
    <motion.section className={cx('wrapper')} layout>
      <Button loading={isLoading} mode="default" onClick={handleClick} view="primary" width="full">
        {btnText}
      </Button>
    </motion.section>
  );
};
