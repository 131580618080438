import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Emoji, Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';
import Ctx from 'pages-components/Ctx';
import TariffCard from 'pages-components/TariffCard';
import {
  sendCvmTariffAnalyticsEvent,
  timerCvmAnalyticsEvent,
} from 'utils/analytics/cvmTariffAnalytics';
import TariffCardParamsHoc from 'pages-components/TariffCard/hocs/TariffCardParamsHoc';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class UpsalePopup extends Component {
  tariffCVMParameters = {};

  timerId = null;

  componentDidMount() {
    const { content } = this.props;

    if (content.tariff && content.tariff.subgroupId && content.tariff.campId) {
      this.tariffCVMParameters = {
        subgroupId: content.tariff.subgroupId,
        campId: content.tariff.campId,
      };

      this.sendCVMAnalyticsWithoutDelay('openChannel');
      this.timerId = timerCvmAnalyticsEvent(
        [
          {
            ...this.tariffCVMParameters,
          },
        ],
        'landViewed',
      );
    }
  }

  componentWillUnmount() {
    return clearTimeout(this.timerId);
  }

  sendCVMAnalyticsWithoutDelay = (state) => {
    sendCvmTariffAnalyticsEvent([
      {
        ...this.tariffCVMParameters,
        state,
      },
    ]);
  };

  handlerClosePopup = (callback) => {
    this.sendCVMAnalyticsWithoutDelay('close');
    this.props.close();

    if (callback) callback();
  };

  getTexts = () => {
    const { familyFilled, content } = this.props;
    const hasDiscount = !!(content.tariff && content.tariff.parameters[3]);
    let key;

    if (familyFilled) {
      key = hasDiscount ? 'filledWithDiscount' : 'filledWithoutDiscount';
    } else {
      key = hasDiscount ? 'notFilledWithDiscount' : 'notFilledWithoutDiscount';
    }

    return content[key] || {};
  };

  render() {
    const { content, resetButtonAction, open } = this.props;
    const texts = this.getTexts();

    return (
      <PopupStateless className={cx('upsalePopup')} onClose={this.handlerClosePopup} opened={open}>
        <Heading className={cx('heading')} level={1}>
          <span dangerouslySetInnerHTML={{ __html: texts.heading }} />
          {texts.icon && <Emoji name={texts.icon} />}
        </Heading>

        <div
          className={cx('description')}
          dangerouslySetInnerHTML={{ __html: texts.description }}
        />

        <div className={cx('tariffContainer')}>
          <TariffCardParamsHoc
            {...content.tariff}
            cardStyle={false}
            clickable={false}
            hideButton
            showBottomBubble={false}
            showTopBubble={false}
          >
            <TariffCard className={cx('tariffCard')} />
          </TariffCardParamsHoc>

          <Button
            className={cx('big')}
            href={content.buttonLink}
            onClick={() => this.sendCVMAnalyticsWithoutDelay('open')}
            transformer
          >
            {Ctx.upsalePopup.buttonText}
          </Button>
        </div>

        <Link
          className={cx('resetButton')}
          onClick={() => this.handlerClosePopup(resetButtonAction)}
        >
          {Ctx.upsalePopup.resetButtonText}
        </Link>
      </PopupStateless>
    );
  }
}

UpsalePopup.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  resetButtonAction: PropTypes.func,
  content: PropTypes.object,
  familyFilled: PropTypes.bool,
};

export default UpsalePopup;
