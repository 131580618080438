import { Button } from '@beef/ui-kit';
import React from 'react';

import { ActionBar, ResponseLayout } from './components';
import { ModalsControllerProps } from './ModalsController/types';

type ErrorModalDataProps = Pick<ModalsControllerProps, 'onClose'> & {
  data: ModalsControllerProps['data']['deactivateError'];
};

export const useErrorModalData = ({ onClose, data }: ErrorModalDataProps) => {
  const { title, description, image, buttons } = data;
  const { confirm } = buttons || {};
  return {
    modalSize: 'm',
    content: <ResponseLayout description={description} image={image} title={title} />,
    actionBar: (
      <ActionBar>
        <Button onClick={onClose}>{confirm?.title}</Button>
      </ActionBar>
    ),
  };
};
