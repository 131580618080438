import { useSelectFmcProps } from '../selectors/useSelectFmcProps';
import { selectSuitableFmcTabs } from '../utils';

/** Хук подготовки ШПД-табов (общих данных) в зависимости от доступных скоростей и priceCategory */
export const usePrepareFmcTabs = () => {
  /* Получаем данные из стора по ШПД-пресетам.
   (массив ШПД-пресетов, текущую мобильную priceCategory, статус загрузки isLoadingFmcRequest
   и defaultConvergenceBlock для таба "Без интернета") */
  const {
    previewTariffs,
    priceCategory = '',
    isLoadingFmcRequest,
    defaultConvergenceBlock = {},
  } = useSelectFmcProps();

  /* Выбираем актуальные объекты ШПД-пресетов (по priceCategory)
   из общего списка доступных скоростей в данном регионе */
  const suitableFmcTabs = selectSuitableFmcTabs(
    previewTariffs,
    priceCategory,
    defaultConvergenceBlock,
  );

  return { preparedFmcInfo: suitableFmcTabs, isLoadingFmcRequest };
};
