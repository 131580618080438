import classNames from 'classnames/bind';
import React, { FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Tabs } from '@beef/ui-kit';

import { Card } from 'pages/PromotionsPage/components/DiscountsBlock/components/Card';

import styles from './styles.pcss';
import { useGetActions } from '../../hooks/useGetActions';
import { ConvertedStateType, RootPageStore } from '../../types';
import { changeYearInURL } from '../../utils';

const cn = classNames.bind(styles);

type ActionArchiveProps = Omit<ConvertedStateType, 'breadcrumbs' | 'title'>;

const _ActionArchive: FC<ActionArchiveProps> = ({ data, years, activeYear, pageInfo }) => {
  const [activeTab, setActiveTab] = useState<string>(activeYear);
  const { actions, fetchMore, refetch, isLast, isLoading, isFetchMoreLoading } = useGetActions({
    data,
    pageInfo,
    activeYear: activeTab,
  });

  const handleTabChange = useCallback((id: string) => {
    setActiveTab(id);
    changeYearInURL(id);
    refetch(id).catch((err) => console.error(err));
  }, []);

  return (
    <div>
      <div className={cn('tabs')}>
        <Tabs
          noWrap
          onChange={handleTabChange}
          selectedTab={activeTab}
          tabs={years}
          variant="tertiary"
        />
      </div>
      <div className={cn('container')}>
        {actions.map((item) => (
          <Card
            {...item}
            alias="archive"
            className={cn('archive')}
            isArchive
            isPage
            key={item.id}
          />
        ))}
      </div>
      {!isLast && !isLoading && (
        <div className={cn('button')}>
          <Button isLoading={isFetchMoreLoading} onClick={fetchMore} size="l" variant="tertiary">
            Показать еще
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({
  content: { data, pageInfo, activeYear, years },
}: RootPageStore): ActionArchiveProps => ({
  data,
  pageInfo,
  activeYear,
  years,
});

export const ActionArchive = connect(mapStateToProps)(_ActionArchive);
