import { getHasBrowserWindowAPI } from './window';

/**
 * Возвращает тип окружения (fUAT, ProdLike и т.д)
 */
export const getEnvironment = () => {
  if (!getHasBrowserWindowAPI()) return;

  if (window?.location?.hostname?.includes('localhost')) return 'dev';

  const match = document.body.innerHTML.match(/"env"\s*:\s*"(\w+)",/);

  if (!match || !match[1]) return;

  return match[1];
};
