import React from 'react';
import { Card, Text } from '@beef/ui-kit';
import classnames from 'classnames/bind';

import { Gallery, Image } from '../Gallery';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

type Props = {
  images: Image[];
  title: string;
};

export const PhotosBanner = ({ title, images }: Props) => (
  <Card className={cx('wrap')}>
    <div className={cx('heading')}>
      <Text size="size1-m">{title}</Text>
    </div>
    <div className={cx('slider')}>
      <Gallery images={images} />
    </div>
  </Card>
);
