export const getStatusIcon = (status, statusIcon) => {
  if (!statusIcon) {
    return;
  }
  switch (status) {
    case 'Заявление' || 'Оформленный':
      return statusIcon.statement;
    case 'Ожидание оплаты':
      return statusIcon.waiting;
    case 'Оплачен':
      return statusIcon.success;
    case 'Аннулирован':
      return statusIcon.cancel;
    default:
      return statusIcon.statement;
  }
};
