const selectStateVoWifi = (state) => state?.voWifiContent;
const selectVoWifiServiceCardState = (state) => state?.voWifiServiceCardCommonSlice;

export const selectContent = (state) => selectStateVoWifi(state)?.content?.data?.content;
export const selectVowifiFAQ = (state) => selectStateVoWifi(state)?.vowifiFAQ;
export const selectVowifiInstructions = (state) => selectStateVoWifi(state)?.vowifiInstructions;
export const selectSupportStatus = (state) => selectStateVoWifi(state)?.support;
export const selectErrorScreenStatus = (state) =>
  selectVoWifiServiceCardState(state)?.errorScreenStatus;
export const selectErrorScreensState = (state) => selectContent(state)?.errorScreens;

export const selectIsError = (state) => selectVoWifiServiceCardState(state)?.isError;
export const selectIsConnected = (state) => selectStateVoWifi(state)?.isConnected;
