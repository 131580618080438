import React, { useEffect } from 'react';
// Components
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ymOrderTariffAddressView } from 'pages/FTTB2021/components/OrderTariffFormPopup/analytics';
import { TARIFF_TYPES } from 'pages/FTTB2022/CatalogPage/deprecated/store/blocks/constants';

import { ModalLayout } from '../../Common/ModalLayout';
import { PhoneInputSection } from './PhoneInputSection';
import { HeaderContainer } from '../../Common/HeaderContainer';
import { ConnectionNotification } from './ConnectionNotification';
import { AddressRequestInputsSection } from './AddressRequestInputsSection';

const AddressRequestFormLayout = ({ onInit }) => {
  useEffect(() => {
    onInit?.();
  }, []);
  return (
    <ModalLayout>
      <HeaderContainer />
      <div>
        <AddressRequestInputsSection />
        <PhoneInputSection />
        <ConnectionNotification />
      </div>
    </ModalLayout>
  );
};

AddressRequestFormLayout.propTypes = {
  onInit: PropTypes.func,
};

export const AddressRequestForm = connect(null, () => ({
  onInit: () => ymOrderTariffAddressView('fmc_address_view', TARIFF_TYPES.presetUp),
}))(AddressRequestFormLayout);
