import React, { FC } from 'react';

import { TNonRegistrationPopup } from './types';
import { ActionBar, Description, PopupImage, Title, Wrapper } from './components';

/** Попап невозможности подключения из-за отсутствия регистрационных данных */
export const NonRegistrationPopup: FC<TNonRegistrationPopup> = ({
  title,
  description,
  image,
  button,
  extraButton,
  onClose,
}) => (
  <Wrapper>
    {image && <PopupImage image={image} />}
    {title && <Title title={title} />}
    {description && <Description description={description} />}
    {(button || extraButton) && (
      <ActionBar button={button} extraButton={extraButton} onClose={onClose} />
    )}
  </Wrapper>
);
