export enum EUserType {
  Mobile = 'Mobile',
  Other = 'Other',
}

export enum EStatus {
  Active = 'Active',
  Blocked = 'Blocked',
  BlockedByFinance = 'BlockedByFinance',
  NotEnoughMoney = 'NotEnoughMoney',
  Other = 'Other',
  Corporative = 'Corporative',
  Activating = 'Activating',
  BlockedByWish = 'BlockedByWish',
  Unknow = 'Unknow',
}

export enum EStatusTranslated {
  Active = 'Активный',
  NegativeBalance = 'Отрицательный баланс',
  Blocked = 'Заблокирован',
}

export enum EPaymentType {
  Prepaid = 'Prepaid',
  Other = 'Other',
}

export type TGetUserStatusResponse = {
  balance: {
    balance: number;
    currency: string;
    paymentType: EPaymentType | undefined;
    sumToPay: number;
  };
  profileSummary: {
    ctn: string;
    longRcRate: string;
    nextBillingDate: string;
    rcRate: string;
    shortRcRate: string;
    tariffName: string;
    tariffUrl: string;
    userType: EUserType | undefined;
  };
  services: {
    connectedServicesCount: number;
  };
  status: {
    canChangeStatus: false;
    dateBlock: null;
    status: EStatus;
  };
};

export interface IPersonalInfoProps {
  getData?: () => void;
  initialFetching?: boolean;
  isFTTBUser: boolean;
  isLoading?: boolean;
  personalInfo?: TGetUserStatusResponse;
  profileUrl: string;
  texts: IPersonalInfoTexts;
}
export interface IPersonalInfoTexts {
  balance: string;
  connectedServices: string;
  defaultCtn: string;
  defaultStatus: EStatus;
  error: string;
  link: string;
  nextBilling: string;
  number: string;
  tariff: string;
  update: string;
}
