import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { ConstructorContext } from 'pages/ProductCard/Tariff/containers/ConstructorTariff/constants';
import {
  PASSIVE_OPTION_TYPES,
  changeActiveOptionSoc,
  getPassiveOptionType,
} from 'pages/ProductCard/utils';

import Platform from './Platform';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Platforms = ({
  id,
  title,
  checked,
  setPrice,
  optionSoc,
  platforms,
  setChecked,
  optionEntityName,
  isHideInCheck,
  disabled,
}) => {
  const [activePlatforms, setActivePlatforms] = useState(new Set());
  const { activeCharacter, activeOptionSoc } = useContext(ConstructorContext);
  const formattedActiveOptionSoc = changeActiveOptionSoc(activeOptionSoc);

  const isFree = isHideInCheck && activeCharacter?.type === 1;
  const getSum = (val) => (isFree ? 0 : val);
  // При нажатии на кнопку с параметром isAll, активируются все опции
  // Если отжать хотя-бы одну опцию, чекед с кнопки isAll снимается
  // И происходит перерасчет стоимости. Опция isAll - дешевле чем
  // Все активный опции сложенные вместе.
  const handleActivateAllPlatforms = useCallback(
    (needClear = true) => {
      setActivePlatforms((prevSet) => {
        const characterTypePlatform = platforms.find((item) => item?.characterTypes);
        if (prevSet.size === platforms.length && needClear) {
          if (
            characterTypePlatform &&
            getPassiveOptionType(characterTypePlatform, activeOptionSoc) ===
              PASSIVE_OPTION_TYPES.restrictedForRemove
          ) {
            setChecked(true);
            return new Set([characterTypePlatform?.soc]);
          }
          setChecked(false);
          return new Set();
        }

        return new Set(
          platforms.map((platform) => {
            if (!platform.isAll) {
              setChecked(true);
              return platform.soc;
            }
            return 'all';
          }),
        );
      });
    },
    [platforms, setActivePlatforms],
  );

  useEffect(() => {
    platforms.forEach((platform) => {
      if (
        platform.isAll &&
        (platform.defaultEnabled ||
          getPassiveOptionType(platform, activeOptionSoc) ===
            PASSIVE_OPTION_TYPES.restrictedForRemove)
      ) {
        handleActivateAllPlatforms(false);
      } else if (
        platform.defaultEnabled ||
        getPassiveOptionType(platform, activeOptionSoc) === PASSIVE_OPTION_TYPES.restrictedForRemove
      ) {
        setActivePlatforms((prevSet) => {
          prevSet.add(platform.soc);
          return new Set(prevSet);
        });
      }
    });
  }, [platforms, handleActivateAllPlatforms]);

  useEffect(() => {
    if (checked && !activePlatforms.size) {
      return handleActivateAllPlatforms(false);
    }
    if (!checked) {
      return setActivePlatforms(new Set());
    }
  }, [checked]);

  // Расчет итоговой суммы, сложением всех цен платформ, либо вывод
  // суммы для опции isAll

  useEffect(() => {
    if (activePlatforms.size) {
      // Проверяем на актив isAll после чего складываем,
      // Либо выводим сумму isAll
      if (activePlatforms.has('all')) {
        const { soc, entityName } = platforms.filter((platform) => platform.isAll)[0];

        const allPlatform = platforms.filter((platform) => platform.isAll)[0];

        const isPassive = getPassiveOptionType(allPlatform, activeOptionSoc);
        const sum =
          isPassive ?
            allPlatform.characterTypes?.[formattedActiveOptionSoc]?.longRcRate
          : allPlatform.longRcRate;
        const sumDay =
          isPassive ?
            allPlatform.characterTypes?.[formattedActiveOptionSoc]?.shortRcRate
          : allPlatform.shortRcRate;
        const discountSum =
          isPassive ?
            allPlatform.characterTypes?.[formattedActiveOptionSoc]?.longRcRateWithDiscount
          : allPlatform.longRcRateWithDiscount;
        const discountSumDay =
          isPassive ?
            allPlatform.characterTypes?.[formattedActiveOptionSoc]?.shortRcRateWithDiscount
          : allPlatform.shortRcRateWithDiscount;

        setPrice({
          sum: getSum(sum),
          sumDay: getSum(sumDay),
          discountSum: getSum(discountSum),
          discountSumDay: getSum(discountSumDay),
          title,
          checked,
          soc: isFree ? [] : [soc],
          entityName: [entityName],
          isHideInCheck,
        });
      } else {
        // Забираем socs и цены
        const sumPrice = Object.fromEntries(
          platforms.map((item) => {
            const isPassive = getPassiveOptionType(item, activeOptionSoc);
            return [
              item.soc,
              {
                month:
                  isPassive ?
                    item.characterTypes?.[formattedActiveOptionSoc]?.longRcRate
                  : item.longRcRate,
                day:
                  isPassive ?
                    item.characterTypes?.[formattedActiveOptionSoc]?.shortRcRate
                  : item.shortRcRate,
                discMonth:
                  isPassive ?
                    item.characterTypes?.[formattedActiveOptionSoc]?.longRcRateWithDiscount
                  : item.longRcRateWithDiscount,
                discDay:
                  isPassive ?
                    item.characterTypes?.[formattedActiveOptionSoc]?.shortRcRateWithDiscount
                  : item.shortRcRateWithDiscount,
              },
            ];
          }),
        );
        let sumMonth = 0;
        let sumDay = 0;
        let sumDiscMonth = 0;
        let sumDiscDay = 0;

        if (!isFree) {
          activePlatforms.forEach((activePlatform) => {
            sumMonth += sumPrice[activePlatform]?.month;
            sumDay += sumPrice[activePlatform]?.day;
            sumDiscMonth += sumPrice[activePlatform]?.discMonth;
            sumDiscDay += sumPrice[activePlatform]?.discDay;
          });
        }

        const entityNamesArray = platforms
          .filter((platform) =>
            Array.from(activePlatforms).some((activeSoc) => platform.soc === activeSoc),
          )
          .map((platform) => platform.entityName);

        setPrice({
          sum: sumMonth,
          sumDay,
          discountSum: sumDiscMonth,
          discountSumDay: sumDiscDay,
          title,
          checked,
          soc: Array.from(activePlatforms),
          entityName: entityNamesArray,
          isHideInCheck,
        });
      }
    } else {
      const isPassive = getPassiveOptionType(platforms[0], activeOptionSoc);
      setPrice({
        title,
        checked,
        soc: [optionSoc],
        sum: getSum(
          isPassive ?
            platforms[0].characterTypes?.[formattedActiveOptionSoc]?.longRcRate
          : platforms[0].longRcRate,
        ),
        sumDay: getSum(
          isPassive ?
            platforms[0].characterTypes?.[formattedActiveOptionSoc]?.shortRcRate
          : platforms[0].shortRcRate,
        ),
        discountSum: getSum(
          isPassive ?
            platforms[0].characterTypes?.[formattedActiveOptionSoc]?.longRcRateWithDiscount
          : platforms[0].longRcRateWithDiscount,
        ),
        discountSumDay: getSum(
          isPassive ?
            platforms[0].characterTypes?.[formattedActiveOptionSoc]?.shortRcRateWithDiscount
          : platforms[0].shortRcRateWithDiscount,
        ),
        entityName: [optionEntityName],
        isHideInCheck,
      });
    }
  }, [activePlatforms, platforms, activeCharacter?.type, checked]);

  return (
    <div className={cx('platforms')} data-component="Platforms">
      {platforms.map((item, idx) => (
        <Platform
          activePlatforms={activePlatforms}
          checked={checked}
          disabled={
            disabled ||
            getPassiveOptionType(item, activeOptionSoc) === PASSIVE_OPTION_TYPES.restrictedForRemove
          }
          handleActivateAllPlatforms={handleActivateAllPlatforms}
          id={id}
          key={idx}
          platformsLength={platforms.length}
          price={item.rcRate}
          setActivePlatforms={setActivePlatforms}
          setChecked={setChecked}
          {...item}
        />
      ))}
    </div>
  );
};

Platforms.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  checked: PropTypes.bool,
  setPrice: PropTypes.func,
  optionSoc: PropTypes.string,
  platforms: PropTypes.arrayOf(PropTypes.object),
  handlerSwitcher: PropTypes.func,
  setChecked: PropTypes.func,
  optionEntityName: PropTypes.string,
  isHideInCheck: PropTypes.bool,
};

export default Platforms;
