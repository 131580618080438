import React, { memo } from 'react';
import classNames from 'classnames/bind';

import style from './styles.pcss';

const cn = classNames.bind(style);

export const BaseActionBar = memo(({ children }: React.PropsWithChildren) => (
  <div className={cn('action')}>{children}</div>
));
