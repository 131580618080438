import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import TariffCard from '../TariffCard';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffsList = (props) => {
  const {
    title,
    tariffs,
    tariffsCardText,
    backCallPopupData,
    orderFormData,
    availableScheduleData,
    forceShowBackCallPopup,
  } = props;
  return (
    <div className={cx('wrapper')}>
      <Heading className={cx('title')} level={2}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Heading>

      <div className={cx('tariffsList')}>
        {tariffs.map((tariff, i) => (
          <TariffCard
            availableScheduleData={availableScheduleData}
            backCallPopupData={backCallPopupData}
            className={cx('tariffCard')}
            forceShowBackCallPopup={forceShowBackCallPopup}
            key={i}
            orderFormData={orderFormData}
            tariff={tariff}
            texts={tariffsCardText}
          />
        ))}
      </div>
    </div>
  );
};

TariffsList.propTypes = {
  title: PropTypes.string,
  forceShowBackCallPopup: PropTypes.bool,
  tariffs: PropTypes.array,
  tariffsCardText: PropTypes.object,
  backCallPopupData: PropTypes.object,
  orderFormData: PropTypes.object,
  availableScheduleData: PropTypes.object,
};

export default TariffsList;
