import React from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './styles.pcss';

const fade = () => ({
  enter: styles.fadeEnter,
  enterActive: styles.fadeEnterActive,
  exit: styles.fadeExit,
  exitActive: styles.fadeExitActive,
});

const fadeSlide = () => ({
  enter: styles.fadeSlideEnter,
  enterActive: styles.fadeSlideEnterActive,
  exit: styles.fadeSlideExit,
  exitActive: styles.fadeSlideExitActive,
});

const CSSAnimatedComponent = (classNames) => (props) => (
  <CSSTransition {...props} classNames={classNames}>
    {props.children}
  </CSSTransition>
);

const Fade = CSSAnimatedComponent(fade());
const FadeSlide = CSSAnimatedComponent(fadeSlide());

export { CSSAnimatedComponent, Fade, FadeSlide };
