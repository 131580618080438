import React, { ReactNode, memo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TTariffHeader = {
  children: ReactNode;
};

/** Компонент-обертка включающий в себя заголовок, описание и плитки выбора аппера */
export const TariffHeader = memo<TTariffHeader>(({ children }) => (
  <section className={cx('wrapper')}>{children}</section>
));
