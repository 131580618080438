// Объект для баблика со скидкой
export const getBottomBubble = (params) =>
  params.bubble ?
    {
      ...params.bubble,
      portal: params.portal,
      tail: 'topRight',
      place: 'bottom',
      tariffId: `${params.id}`,
    }
  : null;

// Объект для баблика с рекомендациями
export const getTopBubble = (params) =>
  params.bubble ?
    {
      ...params.bubble,
      portal: params.portal,
      tail: 'bottomRight',
      place: 'top',
      tariffId: `${params.id}`,
    }
  : null;
