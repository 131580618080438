import React, { forwardRef } from 'react';
import classnames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { TPresetPickerTile } from './types';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

const Icon = ({ src }: { src?: null | string }) => {
  if (!src) {
    return null;
  }
  return <img alt="" className={cx('icon')} src={src} />;
};

/** Компонент-кнопка. Содержит визуальную информацию по пресету (Гм, Мин и т.п.) */
const PresetPickerTile = forwardRef<HTMLButtonElement | null, TPresetPickerTile>(
  ({ isActive, borderColor, onClick, ...props }, ref) => {
    /* Отдельная переменная для пропа, чтобы в dom не добавлялись пустые style */
    const styleProp = borderColor ? { style: { borderColor } } : {};
    return (
      <button
        className={cx('tile', { _active: isActive })}
        onClick={() => onClick(props.value)}
        type="button"
        {...styleProp}
        {...props}
        ref={ref}
      >
        <Text className={cx('value')} size="size5-m">
          {props.value}
        </Text>
        <Text className={cx('unit')} size="size6-r">
          {props.unit}
        </Text>
        <Icon src={isActive ? props.activeIcon : props.icon} />
      </button>
    );
  },
);

export default PresetPickerTile;
