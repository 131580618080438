import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ResultPopup = ({ popupData, onClose }) => (
  <div className={cx('errorPopup')}>
    <div className={cx('wrapper')}>
      {popupData.icon && (
        <div className={cx('headingIcon')}>
          <Picture src={popupData.icon} />
        </div>
      )}

      <Heading isStyled={false} level={1}>
        <span dangerouslySetInnerHTML={{ __html: popupData.title }} />
      </Heading>

      <p className={cx('description')} dangerouslySetInnerHTML={{ __html: popupData.content }} />
      <Button className={cx('big')} onClick={onClose} transformer>
        {popupData.buttonText}
      </Button>
    </div>
  </div>
);

ResultPopup.defaultProps = {
  onClose: () => {},
};

ResultPopup.propTypes = {
  onClose: PropTypes.func,
  popupData: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    buttonText: PropTypes.string,
  }),
};

export default ResultPopup;
