import React from 'react';
import { Button } from '@beef/ui-kit';
import PropTypes from 'prop-types';

import { withCn } from '../../enhancers/withCn';

export const _ButtonLink = ({ href, onClick, ...restProps }) => {
  const handleClick = (event) => {
    onClick?.(event);
    if (!event.defaultPrevented) {
      window.location.href = href;
    }
  };
  return <Button onClick={handleClick} {...restProps} />;
};

_ButtonLink.propTypes = {
  ...Button.propTypes,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

export const ButtonLink = withCn(_ButtonLink);
