import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { Title } from 'constructorV1/components/commonComponents/ConstructorTitle';

import { ModalIcons } from '../ModalIcons';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TModalContent = {
  entityName: string;
  modalWindowDescription?: string;
  pictures?: Array<{ image: string }>;
};

/** Контент модального окна опции */
export const ModalContent: FC<TModalContent> = ({
  entityName,
  modalWindowDescription,
  pictures,
}) => (
  <div className={cx('wrapper')}>
    <Title subTitle={modalWindowDescription} title={entityName} withoutExtraPaddings />
    <ModalIcons pictures={pictures} />
  </div>
);
