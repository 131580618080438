import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { FontSizeType, Text, TextPropsType } from '@beef/ui-kit';

import { Description } from './Description';
import { TServiceType } from '../../constructorPage/ServiceTiles/tempYmUtils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TTitleContent = {
  description: string;
  extraSize: FontSizeType;
  icon: string;
  textColor?: string;
  title: string;
  type?: TServiceType;
} & TextPropsType;

/** Компонент позволяет задать icon, title, description для блока */
export const TitleContent: FC<Partial<TTitleContent>> = ({
  icon,
  title,
  description,
  size = 'size0-b',
  extraSize = 'size4-r',
  type,
  ...restProps
}) =>
  title ?
    <section className={cx('wrapper')}>
      <Text size={size} {...restProps}>
        {title}
      </Text>
      {(icon || description) && (
        <Description {...{ icon, extraSize, description, type }} {...restProps} />
      )}
    </section>
  : null;
