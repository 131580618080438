import { createSelector } from '@reduxjs/toolkit';
import { emptyArr, times } from '@beef/utils';

import { getRandomInt } from 'utils/fn';

import { getActiveCategoriesArray } from './category';
import { getPrices, getPricesForChangeNumber } from './content';

const getNumbersState = (state) => state.numbers;

export const getDefCodes = createSelector(getNumbersState, (state) => state.defCodes);
export const getCurrentRandomNumber = createSelector(
  getNumbersState,
  (state) => state.randomNumber,
);
export const getRandomNumberCategory = createSelector(
  getNumbersState,
  (state) => state.randomNumberCategory,
);
export const getNumbersInCart = createSelector(getNumbersState, (state) => state.numbersInCart);
export const getError = (state) => getNumbersState(state).error;
export const selectIsCaptchaEnabled = createSelector(
  getNumbersState,
  (state) => state.isCaptchaEnabled,
);
export const selectIsFetching = createSelector(getNumbersState, (state) => state.isFetching);

export const selectIsCaptchaInvalid = createSelector(
  getNumbersState,
  (state) => state.isCaptchaInvalid,
);
export const selectCaptchaData = createSelector(getNumbersState, (state) => state.captchaData);
export const selectReCallAfterCaptchaRequest = createSelector(
  getNumbersState,
  (state) => state?.reCallRequestName,
);

const mapListsByPrice = (state, prices, numbersInCart) => {
  if (!state.lists) return null;
  return Object.keys(state.lists).reduce(
    (acc, category) => ({
      ...acc,
      [category]: state.lists[category].map((number) => ({
        ...number,
        price: prices[category]?.price,
        discount: prices[category]?.discount,
        isInCart: numbersInCart.includes(number.value),
        featureCode: number.featureCode,
      })),
    }),
    {},
  );
};

export const getNumbers = createSelector(
  getNumbersState,
  getPrices,
  getNumbersInCart,
  mapListsByPrice,
);

export const getChangeNumbers = createSelector(
  getNumbersState,
  getPricesForChangeNumber,
  getNumbersInCart,
  mapListsByPrice,
);

const mapCategoryToNumberList = (lists, activeCategories) => {
  if (!lists) return emptyArr;

  const categories = activeCategories.length ? activeCategories : Object.keys(lists);
  const maxListLength = Math.max(...Object.values(lists).map((group) => group.length));
  const resultList = [];
  times(maxListLength, (index) => {
    categories.forEach((category) => {
      if (lists[category] && lists[category][index]) {
        resultList.push(lists[category][index]);
      }
    });
  });
  return resultList;
};

/**
 * Returns shuffled by category array of numbers. Returns null if there is no numbers yet
 */
export const getNumbersList = createSelector(
  getNumbers,
  getActiveCategoriesArray,
  mapCategoryToNumberList,
);

export const getChangeNumberList = createSelector(
  getChangeNumbers,
  getActiveCategoriesArray,
  mapCategoryToNumberList,
);

export const getNextRandomNumber = createSelector(
  getNumbers,
  getCurrentRandomNumber,
  getRandomNumberCategory,
  (lists, currentNumber, category) => {
    if (!lists?.[category]?.length) return null;

    const numbers = lists[category];
    const indices = times(numbers.length, (i) => i);
    if (currentNumber) {
      const currentIndex = numbers.findIndex((number) => number.value === currentNumber.value);
      if (currentIndex !== -1) {
        indices.splice(currentIndex, 1);
      }
    }
    if (indices.length === 0) return currentNumber;

    const randomIndex = getRandomInt(0, indices.length - 1);
    return numbers[indices[randomIndex]];
  },
);

export const getIsLoading = createSelector(getNumbersState, (state) => state.loading);
export const getShowEmptyList = createSelector(
  getNumbers,
  getNumbersList,
  getError,
  (numbers, resultList, error) => !!numbers && resultList.length === 0 && !error,
);
