import {
  AdaptiveMenu,
  Breadcrumbs,
  Button,
  Dropdown,
  Emoji,
  Heading,
  IconsMenu,
  IconsMenuItem,
  Link,
  TextInput,
} from '@beef/legacy-ui-kit';
import { PhoneIcon, Preloader } from '@beef/ui-kit';
import axios from 'axios';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { cond, contains, filter, find, map, pipe, propEq, reduce } from 'ramda';
import React, { Component } from 'react';
import { redirect } from 'react-router-dom';

import MyBeelineBanner from 'pages/ProductsCatalog/Tariffs/components/MyBeelineBanner';
import { debounce } from 'utils/timed-functions';
import { ResultPopupContent } from 'pages-components/ResultPopup/ResultPopup';
import { SERVICE_WRONG_REGION_API } from 'pages/ProductCard/constants';
import PopupWithBack from 'pages-components/PopupWithBack/PopupWithBack';
import { isEmptyValue } from 'utils/isEmptyValue';
import { getRegionSearchParams } from 'pages/ProductCard/utils';
import { fetchWrongRegionPopup } from 'pages/ProductCard/services/fetchWrongRegionPopup';

import DeviceItemLink from '../DeviceItemLink/DeviceItemLink';
import styles from '../ProductsCatalog.pcss';
import { DefinedIcons } from './constants';
import ServicesGrid from './ServicesGrid';

const cx = classNames.bind(styles);

const badTitles = [
  'самый выгодный роуминг',
  'оплата в app store и itunes со счета «билайн',
  'мобильный билет',
  'переход в «билайн» со своим номером (mnp)',
  'номер на выбор',
  'оплата в google play™ со счета «билайн»',
  'оплата в windows store cо счета «билайн»',
  'оплата в windows phone store со счета «билайн»',
];

function doScrolling(elementY, duration) {
  if (typeof window === 'undefined') return;

  const startingY = window.pageYOffset;
  const diff = elementY - startingY;

  let start;

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    const time = timestamp - start;
    const percent = Math.min(time / duration, 1);
    window.scrollTo(0, startingY + diff * percent);
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
}

class ServicesCatalog extends Component {
  constructor(props) {
    super(props);

    const { data, activeCategory } = this.props;
    const { categories, userPayType } = data;
    const initialCategory =
      activeCategory ?
        find(propEq('id', Number(activeCategory)))(categories.list)
      : categories.list[0];

    this.state = {
      data,
      amount: data.entities.length,
      activeCategory: initialCategory,
      showPostpaid: userPayType === 'postpaid',
      searchString: '',
      isLoading: false,
      isFullLoaded: false,
      showAll: false,
      wrongRegionPopup: {},
    };
    this.throttledLoader = debounce(this.fullUpdateDevice, 2000);
  }

  componentDidMount() {
    const { previousRegion, currentRegion, missingService, needOpenPopup } =
      getRegionSearchParams();
    if (previousRegion && previousRegion !== currentRegion && needOpenPopup) {
      fetchWrongRegionPopup(SERVICE_WRONG_REGION_API, {
        missingService,
        previousRegion,
      })
        .then((data) => {
          if (data?.view) {
            this.setState({
              wrongRegionPopup: data.view,
            });
          }
        })
        .catch((error) => console.error(error));
    }

    if (!this.state.isFullLoaded) {
      this.fullUpdateDevice();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      isFullLoaded,
      activeCategory,
      data: { categories },
      searchString,
      showAll,
    } = this.state;
    const justLoaded = !isFullLoaded && nextState.isFullLoaded;
    const isSearching = searchString.length >= 3;
    const isMainCategory = activeCategory && activeCategory.title === categories.list[0].title;
    return !(justLoaded && !showAll && isMainCategory && !isSearching);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeDevice !== this.props.activeDevice) {
      this.updateDevice(this.props.activeDevice);
    }
  }

  closeWrongRegionPopup = () => {
    this.setState({ wrongRegionPopup: {} });
  };

  changeUrl = () => {
    if (typeof window === 'undefined') return;

    const parts = window.location.pathname.split('/');
    const deviceIndex = parts.findIndex((part) => part === 'services') + 1;
    const device = parts[deviceIndex];

    if (device) this.updateDevice(device);
  };

  animateContainer = (payload, timeStart = 300, cb = () => {}) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState(payload);
      setTimeout(() => {
        if (typeof cb !== 'function') {
          this.setState({ isLoading: false });
        } else {
          this.setState({ isLoading: false }, cb);
        }
      }, 120);
    }, timeStart);
  };

  updateDevice = (device) => {
    this.setState({
      isLoading: true,
      isFullLoaded: false,
      lastRequested: device,
    });
    const startTime = new Date();

    if (typeof document !== 'undefined') {
      const { headTitles } = this.props.data || {};
      const titles = headTitles || {};
      const key = device.replace('-', '');
      if (titles[key]) document.title = titles[key];
    }

    axios
      .post(`${this.props.indexURL}/${device}`, {
        mapped: true,
        id: device,
      })
      .then((response) => {
        const { lastRequested } = this.state;
        const { data } = response;
        const loadTime = new Date() - startTime;
        const restTime = loadTime < 300 ? 300 - loadTime : 0;
        const newActiveDevice = data.devices.find((d) => d.isActive);

        if (lastRequested === newActiveDevice.alias) {
          this.animateContainer(
            {
              data,
              prevCategory: this.state.activeCategory,
              activeCategory: data.categories.list[0],
              searchString: '',
              showAll: false,
            },
            restTime,
            this.throttledLoader,
          );
        }
      });
  };

  fullUpdateDevice = () => {
    const { activeDevice } = this.props;
    return axios
      .post(`${this.props.indexURL}/${activeDevice}/?categories=0`, {
        mapped: true,
        id: activeDevice,
      })
      .then(({ data: { entities } }) => {
        this.setState({ amount: entities.length }); // because of shouldComponentUpdate
        this.setState((prevState) => ({
          amount: entities.length,
          data: {
            ...prevState.data,
            entities,
          },
          isFullLoaded: true,
        }));
      });
  };

  updateCategory = (category) => {
    if (this.state.activeCategory !== null && this.state.activeCategory.id === category.id) {
      return;
    }
    this.animateContainer({ activeCategory: category, search: '', showAll: false });
    const { baseUrl, activeDevice } = this.props;
    redirect(`${baseUrl}${activeDevice}/${category.id}/`);
  };

  updateShowPostpaid = (value) => {
    this.animateContainer({ showPostpaid: value });
  };

  updateSearch = (value) => {
    this.setState({ searchString: value.toLowerCase() });
  };

  showAll = () => {
    this.setState((prevState) => ({
      prevCategory: prevState.activeCategory,
      activeCategory: null,
      showAll: true,
      popularId: prevState.activeCategory.id,
    }));
  };

  mapServices() {
    const { activeCategory, prevCategory, showPostpaid, showAll, popularId } = this.state;
    const { id } = activeCategory || {};
    const prevId = (prevCategory || {}).id;
    const searchString = this.state.searchString.toLowerCase();
    const servicesToMap = this.state.data.entities;
    const isSearching = searchString.length >= 3;
    const isShortSearch = searchString.length && searchString.length < 3;
    const chainIfNotFalse = (callback) => (falseOrService) =>
      falseOrService === false ? false : callback(falseOrService);

    const result = pipe(
      // Hide all if short search string
      (all) => (!isShortSearch ? all : []),
      // Filter services
      filter(
        pipe(
          // Post/Pre-paid filter
          (serviceOrFamily) => {
            const { services, type } = serviceOrFamily;
            let service;
            if (type === 'service') {
              service = serviceOrFamily;
            } else if (type === 'family') {
              const [first] = services || [];
              service = first || {};
            }
            const { availableForPrepaid, availableForPostpaid } = service;
            const show =
              availableForPrepaid === !showPostpaid || availableForPostpaid === showPostpaid;
            if (show !== false) return serviceOrFamily;
            return show;
          },
          // Filter only active service
          (service) => {
            const { hideSubscribeButton, availableForConnect, isConnected } = service;
            if (!hideSubscribeButton && !availableForConnect && !isConnected) return false;
            return service;
          },
          // Search filter
          chainIfNotFalse((serviceOrFamily) => {
            if (isSearching) {
              return cond([
                [
                  ({ type }) => type === 'service',
                  (service) => {
                    const { title = '', description = '' } = service;
                    return (
                      (!!title && contains(searchString, title.toLowerCase())) ||
                      (!!description && contains(searchString, description.toLowerCase()))
                    );
                  },
                ],
                [
                  ({ type }) => type === 'family',
                  (family) => {
                    const { familyTitle = '', description = '', services = [] } = family;
                    return (
                      (!!familyTitle && contains(searchString, familyTitle.toLowerCase())) ||
                      (!!description && contains(searchString, description.toLowerCase())) ||
                      !!services.find(
                        ({ title = '' }) => title && contains(searchString, title.toLowerCase()),
                      )
                    );
                  },
                ],
              ])(serviceOrFamily);
            }
            return serviceOrFamily;
          }),
          // Category filter
          chainIfNotFalse((serviceOrFamily) => {
            if (isSearching) return true;
            const { type, services } = serviceOrFamily;
            let idArray;
            let service;
            if (type === 'service') {
              service = serviceOrFamily;
              const { categories } = service || {};
              idArray = categories;
            } else if (type === 'family') {
              idArray = reduce((acc, { categories }) => [...acc, ...categories], [], services);
            } else {
              return false;
            }
            return id !== undefined ? contains(id, idArray) : true;
          }),
        ),
      ),
      // Map filtered services
      map(
        cond([
          // Update service
          [
            ({ type }) => type === 'service',
            (service) => {
              const { title } = service;
              return title && contains(title, badTitles) ?
                  { ...service, connectionButton: 'Узнать больше' }
                : service;
            },
          ],
          // Update family
          [
            ({ type }) => type === 'family',
            pipe(
              (family) => {
                const { services, familyTitle } = family;
                const [first, ...rest] = services;
                const hasBadTitleInFamily = familyTitle && contains(familyTitle, badTitles);
                return hasBadTitleInFamily ?
                    {
                      ...family,
                      services: [
                        {
                          ...first,
                          connectionButton: 'Узнать больше',
                        },
                        ...rest,
                      ],
                    }
                  : family;
              },
              (family) => {
                const { services } = family;
                const hasConnected = services.some(({ isConnected }) => isConnected === true);
                const { title } = activeCategory || {};
                const isConnectedCategory = title === 'Подключенные';
                return {
                  ...family,
                  services: map((familyService) => {
                    const { categories, isConnected } = familyService;
                    let isHidden;
                    if (isSearching) {
                      isHidden = false;
                    } else if (isConnectedCategory) {
                      isHidden = hasConnected && !(isConnected === true);
                    } else {
                      isHidden = !contains(id || prevId, categories);
                    }
                    return {
                      ...familyService,
                      isHidden,
                    };
                  }, services),
                };
              },
            ),
          ],
        ]),
      ),
    )(servicesToMap);

    if (showAll) {
      result.forEach((item, index) => {
        item.sortOrder = item.categories.indexOf(popularId) > -1 ? index : result.length + index; // eslint-disable-line
      });
      result.sort((itemA, itemB) => itemA.sortOrder - itemB.sortOrder);
    }

    return result;
  }

  render() {
    const { baseUrl } = this.props;
    const { devices, categories, breadcrumbs, archiveLink, appBanner } = this.state.data;
    const services = this.mapServices();

    const {
      amount,
      showPostpaid,
      activeCategory,
      searchString,
      isLoading,
      isFullLoaded,
      showAll,
      wrongRegionPopup,
    } = this.state;

    const { activeDevice } = this.props;
    const isSearching = searchString && searchString.length >= 3;
    const isNotMainCategory = activeCategory && activeCategory.title !== categories.list[0].title;
    const isPreloadServiceCard = !isFullLoaded && (isSearching || isNotMainCategory);
    const isWrongRegion = !isEmptyValue(wrongRegionPopup);

    return (
      <div className={cx('pageWrapper')}>
        {breadcrumbs ?
          <Breadcrumbs
            entries={[
              { title: 'Частным лицам', url: '/' },
              { title: 'Продукты', url: '/' },
              { title: 'Мобильная связь', url: '/' },
              { title: 'Услуги' },
            ]}
          />
        : null}
        <div className={cx('contentWrapper', { hasBreadcrumbs: !!breadcrumbs })}>
          <div className={cx('pageHeader')}>
            <Heading className={cx('pageHeading')} level={1}>
              Услуги
            </Heading>
            <div className={cx('devices', { isLoading })}>
              {devices ?
                <IconsMenu activeId={activeDevice}>
                  {devices.map(({ alias, title }) => (
                    <DeviceItemLink
                      href={`${baseUrl}${alias}/`}
                      key={alias}
                      onChangeLink={this.changeUrl}
                    >
                      {/*
                        FIXME: Надо переезжать, а то легаси кит ломается
                        В данном случае, компонент иконки создавался за счет привязки к имени тэга иконки
                        Если в глобальном скоупе нету этого тэга, все ломается
                      */}
                      <span className={cx('icon-menu-item')}>
                        <IconsMenuItem
                          Icon={DefinedIcons[alias] ?? PhoneIcon}
                          className={cx('icon-menu-item')}
                          text={title}
                        />
                      </span>
                    </DeviceItemLink>
                  ))}
                </IconsMenu>
              : null}
            </div>
          </div>
        </div>

        <div className={cx('pageHeaderDivider')} />

        <div className={cx('contentWrapper')}>
          <div className={cx('servicesFilters')}>
            <div className={cx('servicesFiltersList')}>
              <div className={cx('loadingContainer', { isLoading })}>
                {!searchString && categories.list && categories.list.length ?
                  <AdaptiveMenu
                    activeEntry={
                      activeCategory ?
                        categories.list.findIndex((i) => i.id === activeCategory.id)
                      : 0
                    }
                    entries={categories.list}
                    key={`servicesFiltersList${activeDevice}`}
                    onChange={(category) => this.updateCategory(category)}
                    tickId={0}
                  />
                : null}
              </div>
            </div>

            <div className={cx('servicesFiltersDropdown')}>
              <Dropdown
                className={['mimicry', cx('payTypeDropdown')]}
                onChange={(value) => {
                  this.updateShowPostpaid(value === 'post');
                }}
                options={[
                  { value: 'pred', label: 'Предоплата' },
                  { value: 'post', label: 'Постоплата' },
                ]}
                value={showPostpaid ? 'post' : 'pred'}
              />
            </div>

            <div className={cx('servicesFiltersSearch')}>
              <TextInput
                onButtonInputClick={() => this.updateSearch('')}
                onChange={this.updateSearch}
                placeholder="Название услуги"
                type="search"
                value={searchString}
              />
            </div>
          </div>

          <div className={cx('loadingContainer', { isLoading })}>
            {isPreloadServiceCard ?
              // todo: Для быстрого локального отображения меняем на ServicesGrid.
              <div className={cx('loader')}>
                <Preloader color="contentPlaceholder" size="l" spaced />
              </div>
            : <ServicesGrid className={cx('grid')} id="catalog-grid" services={services} />}
            <div className={cx('searchMessages', { active: searchString })}>
              {isSearching && isFullLoaded && !services.length ?
                [
                  <span className={cx('notFoundText')} key="head">
                    Ничего не нашлось
                  </span>,
                  <Emoji className={cx('notFoundEmoji')} key="emoji" name="smile-sad" />,
                  <span className={cx('notFoundText')} key="tail">
                    уточните, пожалуйста, запрос или попробуйте поискать ниже:
                  </span>,
                ]
              : null}
              {searchString && searchString.length < 3 ?
                <span>Введите не меньше трех букв, чтобы начать поиск</span>
              : null}
            </div>
            <div className={cx('linkWrapper')}>
              {(
                !showAll &&
                activeCategory.title === categories.list[0].title &&
                !searchString &&
                services.length
              ) ?
                <Button className={['big', 'light']} onClick={this.showAll} transformer>
                  Показать все услуги
                </Button>
              : null}

              {showAll && !isFullLoaded ?
                <Preloader className="big" />
              : null}
            </div>

            {amount && <div className={cx('servicesInfo')}>Всего {amount} услуг в категориях</div>}

            <div className={cx('categoriesList')}>
              {categories.list.map((item) => (
                <span className={cx('categoriesLink')} key={`category-link-${item.id}`}>
                  <Link
                    onClick={() => {
                      this.updateCategory(item);
                      doScrolling(0, 1000);
                    }}
                  >
                    {item.title}
                  </Link>
                </span>
              ))}
            </div>

            <div className={cx('linkWrapper')}>
              <Link className="hideVisitedColor" href={archiveLink}>
                Архив услуг
              </Link>
            </div>
          </div>
        </div>
        {appBanner && <MyBeelineBanner {...appBanner} />}
        {isWrongRegion && (
          <PopupWithBack onClose={this.closeWrongRegionPopup} opened={isWrongRegion}>
            <ResultPopupContent data={wrongRegionPopup} />
          </PopupWithBack>
        )}
      </div>
    );
  }
}

ServicesCatalog.defaultProps = {
  indexURL: '/customers/products/mobile/services/indexajax',
};

ServicesCatalog.propTypes = {
  data: PropTypes.shape({}),
  indexURL: PropTypes.string,
  breadcrumbs: PropTypes.bool,
  baseUrl: PropTypes.string,
  activeDevice: PropTypes.string,
  activeCategory: PropTypes.string,
  userPayType: PropTypes.string,
};

export default ServicesCatalog;
