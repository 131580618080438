import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Param = ({ param, selectable, selected, previousSelected, onClick }) => {
  const ContentTag = selectable ? 'button' : 'div';
  return (
    <li
      className={cx(selectable ? 'selectableStatsItem' : 'statsItem', {
        selected,
        previousSelected,
      })}
    >
      <ContentTag
        className={cx('content')}
        onClick={() => {
          if (selectable) {
            onClick();
          }
        }}
        {...(selectable && { type: 'button' })}
      >
        <div className={cx('units')}>
          <span className={cx('unit')} dangerouslySetInnerHTML={{ __html: param.value }} />
          <span dangerouslySetInnerHTML={{ __html: param.unit }} />
        </div>
        {param.priceValue && (
          <div className={cx('cost')}>
            <span dangerouslySetInnerHTML={{ __html: param.priceValue }} />{' '}
            <span dangerouslySetInnerHTML={{ __html: param.priceUnit }} />
          </div>
        )}
      </ContentTag>
    </li>
  );
};

Param.propTypes = {
  param: PropTypes.shape({
    value: PropTypes.string,
    unit: PropTypes.string,
    priceValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    priceUnit: PropTypes.string,
    soc: PropTypes.string,
    isGift: PropTypes.bool,
  }),
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  previousSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Param;
