import { useCallback } from 'react';
import { useEventListener } from '@beef/react';

import { useMutableRef } from '../useMutableRef';
import { UseClickOutsideProps } from './types';

export const useClickOutside = ({
  handler,
  ignoreClicksInsideRefs,
  isActive,
}: UseClickOutsideProps) => {
  const handlerRef = useMutableRef(handler);
  const ignoreClicksInsideRefsRef = useMutableRef(ignoreClicksInsideRefs);
  const isActiveRef = useMutableRef(isActive);

  const handleClick = useCallback((event: Event) => {
    if (!isActiveRef.current) {
      return;
    }

    const target = event.target as Node;

    const shouldCallHandler = ignoreClicksInsideRefsRef.current?.every(
      (ref) => !ref.current?.contains(target),
    );

    if (shouldCallHandler) {
      handlerRef.current?.(event as MouseEvent);
    }
  }, []);

  useEventListener('mousedown', handleClick, document);
};
