import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { BreadCrumbs, Tag, Text } from '@beef/ui-kit';

import { DIMENSION_GROUPS, useGetDimensionGroup } from 'utils/hooks/useGetDimensionGroup';
import { DetailsTeaserBannerType } from 'pages/SPN/types';

import { ActionButton } from '../ActionButton';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const Details: FC<DetailsTeaserBannerType> = ({ data }) => {
  const { button, label, description, title, breadcrumbs } = data;

  const dimension = useGetDimensionGroup();
  const isDesktop =
    dimension !== null && typeof dimension === 'string' && dimension === DIMENSION_GROUPS.desktop;
  const isMobile =
    dimension !== null && typeof dimension === 'string' && dimension === DIMENSION_GROUPS.mobile;

  return (
    <div className={cn('details')}>
      {isDesktop && breadcrumbs?.path && (
        <div className={cn('details__breadcrumbs')}>
          <BreadCrumbs path={breadcrumbs?.path} />
        </div>
      )}

      {label?.text && (
        <div className={cn('details__tag')}>
          <Tag size="m" textColor="white" variant="aquamarine">
            {label?.text}
          </Tag>
        </div>
      )}

      <div className={cn('details__title')}>
        <Text size="size0-b" tagType="h1">
          {title}
        </Text>
      </div>

      <div className={cn('details__description')}>
        <Text color="grey60" size="size4-r">
          {description}
        </Text>
      </div>

      {button?.text && <ActionButton button={button} fullWidth={isMobile} />}
    </div>
  );
};
