import getMetaValueByName from '../getMetaValueByName';

/**
 * @desc: аналитика по новой корзине, форматирование данных для пуша по каталогу тарифов
 * @param tariffCatalogData
 */
const formatCartTariffCatalogData = (tariffCatalogData) => {
  const flatTariffs = [];
  tariffCatalogData.forEach((tariffItem) => {
    if (tariffItem?.groups?.length > 0) {
      tariffItem.groups.forEach(({ entries }) => {
        entries.forEach((entryItem) => flatTariffs.push(entryItem));
      });
    } else {
      flatTariffs.push(tariffItem);
    }
  });
  return flatTariffs.map((item, i) => ({
    brand: 'Sims', // Бренд (хардкод)
    category: 'tariffs', // Категория симок (хардкод)
    id: item.id, // Идентификатор тарифа
    name: item.title, // Название тарифа
    list: 'Tariffs', // место, (хардкод)
    position: i + 1, // индекс в общем списке
    itemCatSoc: item.soc,
    price: item?.price?.value || null,
    metric14: item?.discount?.percent || null, // скидка
  }));
};

/**
 * @desc: аналитика по новой корзине, загрузка каталога тарифов
 * @param tariffList
 */
const onTariffCatalogLoadDataLayer = (tariffList) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'catalog_EC',
      regionName: getMetaValueByName('REGION_TITLE'),
      federalRegionName: getMetaValueByName('FEDERAL_DISTRICT'),
      ecommerce: {
        currencyCode: 'RUB',
        impressions: tariffList,
      },
    });
  }
};

const getNumberCategoryTitle = (title) => {
  if (title) {
    // попросили сделать костыль
    if (title.toLowerCase() === 'специальное предложение') {
      return 'Бесплатный номер';
    }
    return title;
  }
  return 'Случайный номер';
};

/**
 * @desc: аналитика по новой корзине, загрузка каталога номеров/виджета, форматирование номеров
 * @param itemCatNumber { string }
 * @param numbers
 * @param list { string } тип фильтра
 * @param categoriesData { object } данные о категориях
 */
const formatCartFancyNumbersData = ({ numbers, list, categoriesData = {}, itemCatNumber }) =>
  numbers.map((numberGroup, i) => ({
    brand: 'Sims', // Бренд (хардкод)
    category: 'numbers', // Категория симок (хардкод)
    id: 7028344, // Идентификатор тарифа (хардкод)
    name: 'Близкие люди 2', // Название тарифа (хардкод)
    list,
    position: i + 1,
    itemCatNumber, // Категория выбранного номера
    itemPodCatNumber: getNumberCategoryTitle(numberGroup.categorySingularTitle),
    itemPodCatMethod: 'Number', // Передает метод создания заявки
    price: numberGroup.price,
    metric14:
      categoriesData?.[numberGroup.alias]?.discountValue ?
        parseInt(categoriesData[numberGroup.alias].discountValue, 10)
      : null,
  }));

/**
 * @desc: аналитика по новой корзине, загрузка каталога номеров/виджета с красивыми номерами
 * @param numbersList
 */
const onCartFancyNumbersLoadDataLayer = (numbersList) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'catalog_EC',
      regionName: getMetaValueByName('REGION_TITLE'),
      federalRegionName: getMetaValueByName('FEDERAL_DISTRICT'),
      ecommerce: {
        currencyCode: 'RUB',
        impressions: numbersList,
      },
    });
  }
};

/**
 * @desc: аналитика по новой корзине, покупка номера
 * @param price
 * @param numberType
 * @param list { string } страница
 * @param categoryNumber { string } фильтр подбора номера
 */
const onCartFancyNumbersPurchaseDataLayer = ({ price, numberType, list, categoryNumber }) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'addToCart',
      regionName: getMetaValueByName('REGION_TITLE'),
      federalRegionName: getMetaValueByName('FEDERAL_DISTRICT'),
      ecommerce: {
        currencyCode: 'RUB',
        add: {
          actionField: { list },
          products: [
            {
              brand: 'Sims', // бренд товара (хардкод)
              category: 'numbers', // идентификатор категории
              id: 213659, // идентификатор тарифа Близкие люди 2 (хардкод)
              name: 'Близкие люди 2', // Название тарифа (хардкод)
              price,
              quantity: 1,
              itemCatNumber: categoryNumber,
              itemPodCatNumber: numberType,
              itemPodCatMethod: 'Number', // Передает метод создания заявки
            },
          ],
        },
      },
    });
  }
};

/**
 * @desc: аналитика по новой корзине, загрузка карточки тарифа
 * @param data
 */
const onCartTariffCardLoadDataLayer = (data = {}) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'detail_EC',
      regionName: getMetaValueByName('REGION_TITLE'),
      federalRegionName: getMetaValueByName('FEDERAL_DISTRICT'),
      ecommerce: {
        brand: 'Sims', // бренд товара, хардкод
        category: 'tariffs/card', // идентификатор категории, хардкод
        id: data.id,
        name: data.name,
        price: data.price,
        itemCatSoc: data.soc,
        metric14: data.discount,
      },
    });
  }
};

/**
 * @desc: аналитика по новой корзине, загрузка страницы mnp
 */
const onMnpCartLoadDataLayer = () => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'detail_EC',
      regionName: getMetaValueByName('REGION_TITLE'),
      federalRegionName: getMetaValueByName('FEDERAL_DISTRICT'),
      ecommerce: {
        detail: {
          products: [
            {
              brand: 'Sims', // бренд товара (хардкод)
              category: 'services/mnp', // идентификатор категории (хардкод)
              id: 7028344, // идентификатор тарифа Близкие люди 2 (хардкод)
              name: 'Близкие люди 2', // Название тарифа по умолчанию (хардкод)
            },
          ],
        },
      },
    });
  }
};

export {
  formatCartFancyNumbersData,
  formatCartTariffCatalogData,
  onCartFancyNumbersLoadDataLayer,
  onCartFancyNumbersPurchaseDataLayer,
  onCartTariffCardLoadDataLayer,
  onMnpCartLoadDataLayer,
  onTariffCatalogLoadDataLayer,
};
