import React from 'react';

import { AddressEditorHeader } from '../components/constructorPage/ConnectionAddressEditorContainer/components/AddressEditorTitle';
import { FmcContainer } from '../components/constructorPage/FmcContainer';
import { Separation } from '../components/commonComponents/Separation';
import { BillContainer } from '../components/commonComponents/BillContainer';
import { TariffContainer } from '../components/commonComponents/TariffContainer';

/** Страница настройки адреса подключения ШПД */
export const SettingsFttbAddressPage = () => (
  <>
    {/* Заголовок и описание страницы */}
    <AddressEditorHeader />
    <TariffContainer isShortPadding>
      <Separation>
        <FmcContainer />
      </Separation>
      <Separation sideType="right">
        {/* Чек тарифа и промо баннер */}
        <BillContainer />
      </Separation>
    </TariffContainer>
  </>
);
