import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { usePrepareTiles } from './hooks/usePrepareTiles';
import { ModalContent } from '../../modalComponents/ModalContent';
import { TariffInfoTile } from '../../../tempComponents/TariffInfoTile';
import { ModalContainer } from '../../modalComponents/ModalContainer';
import { WPContainer } from '../../commonComponents/WPContainer';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Обертка, отвечающая за отображение плиток тарифа.
 * Включает в себя модальное окно */
export const TariffInfoTiles: FC = () => {
  const {
    extraTilesKeys,
    mainTilesProps,
    extraTilesProps,
    modalContentProps,
    modalContainerProps,
  } = usePrepareTiles();
  return (
    <WPContainer>
      <section className={cx('wrapper')}>
        <TariffInfoTile key="main-tile" {...mainTilesProps} />
        {extraTilesKeys?.map((key) => <TariffInfoTile {...{ key, ...extraTilesProps(key) }} />)}
        <ModalContainer {...modalContainerProps}>
          <ModalContent {...modalContentProps} />
        </ModalContainer>
      </section>
    </WPContainer>
  );
};
