import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

import { useIsDesktopDimension } from 'utils/hooks/useGetDimensionGroup';

import { WPContainer } from '../../../../commonComponents/WPContainer';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TServicesContainer = {
  children: ReactNode;
};

/**
 * Обертка добавляющая в моб. версии контейнер с отступами (WPContainer) и scroll.
 */
export const ServicesContainer: FC<TServicesContainer> = ({ children }) => {
  const isDesktop = useIsDesktopDimension();
  return isDesktop ?
      <div className={cx('wrapper')}>{children}</div>
    : <WPContainer>
        <div className={cx('wrapper', 'wrapper--vertical')}>{children}</div>
      </WPContainer>;
};
