import { createAsyncThunk } from '@reduxjs/toolkit';

import { TVerificationData, checkValidPersistState, loadPersistState } from '../../../persistor';
import { preparePersistCache } from '../../../../utils/preparePersistCache';
import { setPersistedFttbAddressSlice } from '../../fttbAddressSlice';
import { setPersistedFttbSlice } from '../../fttbSlice';
import { setPersistedStepper } from '../../stepperSlice';
import { setPersistedOptions } from '../../optionsSlice';
import { setPersistedTotal } from '../../totalSlice';

/** Вспомогательный экшен для проверки окончания установки кеша из persist (constructor-state) */
export const changeIsCompleteOfCheckCache = createAsyncThunk<void, void, { state: any }>(
  'common/setIsCompleteOfCheckCache',
  (_, { dispatch, getState }) => {
    /** Получаем контентные данные */
    const {
      baseUrl,
      isElkSettings,
      userRegion: { regionId },
      userInfo: { activeCTN, isAuthenticated },
    } = getState().common;

    /** Пытаемся получить сохраненное состояние конструктора */
    const constructorPersistState = loadPersistState();

    /** Объект параметров необходимых для проверки кеширования */
    const verificationData: TVerificationData = {
      baseUrl,
      regionId,
      isElkSettings,
    };

    if (isAuthenticated) {
      verificationData.activeCTN = activeCTN;
    }

    /** Проверка на валидность сохраненных данных для текущей страницы */
    const hasValidPersistState = checkValidPersistState(verificationData, constructorPersistState);

    /* Если текущие данные в кеше валидны (hasValidPersistState),
    проверяем наличие каждой составляющей через функцию Boolean
    и в случае true конкретного объекта устанавливаем его в соответствующий slice */
    if (hasValidPersistState) {
      const {
        persistedTotal,
        persistedStepper,
        persistedOptions,
        persistedFttbPresets,
        persistedFttbAddress,
      } = preparePersistCache(regionId, constructorPersistState);

      dispatch(setPersistedTotal(persistedTotal));
      dispatch(setPersistedStepper(persistedStepper));
      dispatch(setPersistedOptions(persistedOptions));
      dispatch(setPersistedFttbSlice(persistedFttbPresets));
      dispatch(setPersistedFttbAddressSlice(persistedFttbAddress));
    }
  },
);
