import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { cnTheme } from '@carnica/themes';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import styles from './styles.pcss';
import { withDownloadButton } from '../../enhancers/withDownoadButton';
import { IDarkApp } from '../../types';

const cn = classNames.bind(styles);

export const DarkApp_: React.FC<IDarkApp> = ({ title, titleMobile, imageUrl, mobileImageUrl }) => {
  const isMobile = useIsMobileDimension();

  return (
    <div className={cn('info', cnTheme('dark'))}>
      <div className={cn('content')}>
        <Text className={cn('title')} size="2xl">
          {isMobile ? titleMobile : title}
        </Text>
        <picture className={cn('img')}>
          <source media="(max-width: 767px)" srcSet={mobileImageUrl} />
          <source media="(min-width: 768px)" srcSet={imageUrl} />
          <img
            alt=""
            className={cn('img')}
            src={isMobile ? mobileImageUrl : imageUrl}
            width="720"
          />
        </picture>
      </div>
    </div>
  );
};

export const DarkApp = withDownloadButton(true)(DarkApp_);
