import React from 'react';
import PropTypes from 'prop-types';
import { Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Loading = ({ header, description }) => (
  <div className={cx('component')}>
    <div className={cx('loaderWrap')}>
      <Preloader className={cx('loader')} size="80" />
    </div>
    {header && <div className={cx('header')}>{header}</div>}
    <div className={cx('description')}>{description}</div>
  </div>
);

Loading.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
};

export default Loading;
