import React, { FC } from 'react';
import { RootStateOrAny, connect } from 'react-redux';
import { compose, withReduxStore } from '@beef/redux';
import { PageContainer, PageFixer } from '@beef/smart-kit';
import { withDataConverter, withMountListener } from '@beef/react';

import { ClientRenderOnly } from 'pages-components/ClientRenderOnly';
import { PromotionBanner } from 'pages/FancyNumber2022/components/PromotionBanner';
import { RightColumnWrapper } from 'pages/FancyNumber2022/components/RightColumnWrapper';
import { selectIsCaptchaEnabled } from 'pages/FancyNumber2022/store/selectors/numbers';
import { CaptchaWrapper } from 'pages/FancyNumber2022/components/CaptchaWrapper';
import { FancyNumber2022PropsTypes } from 'pages/FancyNumber2022/types';

import { initializeStore } from './store/index';
import { loadFancyNumberData } from './store/actions/numbers';
import { dataConverter } from './store/dataConverter';
import { BlockWrap } from './components/BlockWrap/index';
import {
  CategoriesSelect,
  ColumnsLayout,
  FAQ,
  FilterInput,
  FilterSelect,
  Header,
  Notifications,
  NumberRow,
  NumbersList,
  RandomNumberBox,
  RandomNumberBuyButton,
  RandomNumberHeading,
  RandomNumberWrap,
} from './components/index';
import { AuthModal } from './components/AuthModal/index';
import { getCurrentNumber, getIsChangeNumber } from './store/selectors/change';
import { ChangeNumberModal } from './components/ChangeNumberModal/index';
import { useIsAuthenticated } from './hooks/useIsAuthenticated';

const FancyNumberLayout: FC<FancyNumber2022PropsTypes> = ({
  isChangeNumber,
  currentNumber,
  isCaptchaEnabled,
}) => {
  const isAuthenticated = useIsAuthenticated();

  if (isCaptchaEnabled) {
    return (
      <PageContainer size="s">
        <PageFixer size="m">
          <Header />
          <CaptchaWrapper />
        </PageFixer>
      </PageContainer>
    );
  }

  if (isChangeNumber && !isAuthenticated) {
    return (
      <PageContainer size="s">
        <PageFixer size="m">
          <Header />
          <AuthModal />
        </PageFixer>
      </PageContainer>
    );
  }

  return (
    <PageContainer size="s">
      <PageFixer size="m">
        <Header />
        {currentNumber && <ChangeNumberModal />}
        <ColumnsLayout
          leftColumn={
            <>
              <FilterInput />
              <FilterSelect />
              <CategoriesSelect />
              <BlockWrap>
                <NumbersList>
                  {(number) => (
                    <NumberRow isChangeNumber={isChangeNumber} key={number.value} number={number} />
                  )}
                </NumbersList>
              </BlockWrap>
            </>
          }
          rightColumn={
            !isChangeNumber && (
              <RightColumnWrapper>
                <RandomNumberWrap>
                  <RandomNumberHeading />
                  <RandomNumberBox />
                  <RandomNumberBuyButton />
                </RandomNumberWrap>
                <PromotionBanner />
              </RightColumnWrapper>
            )
          }
        />
        <FAQ />
        <ClientRenderOnly>
          <Notifications />
        </ClientRenderOnly>
      </PageFixer>
    </PageContainer>
  );
};

const mapStateToProps = (state: RootStateOrAny) => ({
  isChangeNumber: getIsChangeNumber(state),
  currentNumber: getCurrentNumber(state),
  isCaptchaEnabled: selectIsCaptchaEnabled(state),
});

export const FancyNumber2022 = compose(
  withDataConverter(dataConverter),
  withReduxStore(initializeStore),
  connect(mapStateToProps, { onMount: loadFancyNumberData }),
  withMountListener,
)(FancyNumberLayout);
