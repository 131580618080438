import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';

import { TRequestParams } from '../components/totalBillPageComponents/TotalBill/types';

/** Тип запроса (params) */
type TChangeTariffReq = NonNullable<TRequestParams>;

/** Тип ответа */
export type TChangeTariffRes = {
  isSucceeded: boolean;
};

/** Запрос данных на смену тарифа */
export const changeTariff = createAsyncThunk<TChangeTariffRes, TChangeTariffReq>(
  'changeTariffApi',
  async (params, { signal, rejectWithValue }) => {
    try {
      const response = (
        await axios.post<TChangeTariffRes>(params.endPoint, params.payload, {
          'X-Requested-With': 'XMLHttpRequest',
          signal,
        } as AxiosRequestConfig)
      ).data;
      /* Если ответ от бека содержит !isSucceeded,
       выкидываем ошибку (отрисуется блок ошибки) */
      if (!response?.isSucceeded) {
        throw new Error();
      }
      return response;
    } catch (e) {
      return rejectWithValue({ isSucceeded: false });
    }
  },
);
