import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Component
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const VoWiFiParameter = ({ label, value, unit, description }) => (
  <div className={cn('parameter')}>
    <div className={cn('parameter__details')}>
      <div className={cn('parameter__label')}>
        <Text size="size5-m">{label}</Text>
      </div>
      <Text size="size5-m">
        {value}
        &nbsp;
        {unit}
      </Text>
    </div>
    {description && (
      <div className={cn('parameter__description')}>
        <Text color="grey50" size="size6-r">
          {description}
        </Text>
      </div>
    )}
  </div>
);

VoWiFiParameter.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  unit: PropTypes.string,
  description: PropTypes.string,
};

export { VoWiFiParameter };
