import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';

import { mapAvailableSchedule } from 'pages/FTTB/utils/mapAvailableSchedule';
import {
  IPeriods,
  IPreparedPeriodsData,
  ITimePeriod,
} from 'pages/FTTB2022/TariffDetailsPage/store/types/modules/orderTariffPopupData';

/** маппинг данных полученных с api для вывода в компонент слот  */
export const composeAvailableSchedule = (periods: IPeriods[]) => {
  const data = [
    ...Object.values(mapAvailableSchedule(periods)),
  ] as unknown as IPreparedPeriodsData[];

  return data.map(({ day, timePeriods }) => {
    const label = format(day, 'D MMMM', { locale: ru });
    const dayOfWeek = format(day, 'dddd', { locale: ru });

    return {
      day,
      dayOfWeek,
      id: label,
      label,
      timePeriods: composeAvailableTimeSlots(timePeriods),
    };
  });
};

/** маппинг периода времени выбранной даты в компонент слот  */
export const composeAvailableTimeSlots = (timeSlots: ITimePeriod[]) =>
  timeSlots.map(({ end, start, ...rest }) => ({
    end,
    start,
    ...rest,
    id: `${format(start, 'HH:mm')}`,
    label: `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`,
  }));
