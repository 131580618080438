import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import { Emoji, Loader } from '@beef/legacy-ui-kit';

// Styles
import classNames from 'classnames/bind';

import Ctx from 'pages-components/Ctx';

import styles from './WaitingPopup.pcss';

const cx = classNames.bind(styles);

export default class WaitingPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      timeLeft: this.props.timeout,
      requestStatus: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleSecondaryClick = this.handleSecondaryClick.bind(this);
    this.tick = this.tick.bind(this);
  }

  componentDidMount() {
    const step = this.props.data && this.props.data.step;
    if (step !== 'pending' && this.state.timeLeft) {
      this.tick();

      if (this.props.requestStatus) {
        this.setState({ requestStatus: this.props.requestStatus });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const step = this.props.data && this.props.data.step;
    const nextStep = nextProps.data && nextProps.data.step;

    if (nextProps.timeout !== this.props.timeout) {
      this.setState({ timeLeft: nextProps.timeout });
    }
    if (!nextProps.opened && this.checkTimer) {
      clearTimeout(this.checkTimer);
    }
    if (nextProps.interval !== this.props.interval && step !== 'pending' && this.checkTimer) {
      clearTimeout(this.checkTimer);
      clearTimeout(this.timer);
    }

    if (nextStep === 'result' && this.props.onResult && nextProps.data.result) {
      if (nextProps.data.result.requestStatus !== 'IN_PROGRESS') {
        setTimeout(() => this.props.onResult(this.props.data.result), 1000);
      }
    }
  }

  componentDidUpdate() {
    const step = this.props.data && this.props.data.step;
    if (this.state.timeLeft && !this.timer && step !== 'pending') {
      this.tick();
    }
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer);
    if (this.checkTimer) clearTimeout(this.checkTimer);
  }

  getEmojiName = (url) => {
    if (url && url.startsWith('http')) {
      return url.match(/([^/]+)(?=\.svg$)/)[0];
    }

    return url;
  };

  handleClose = () => {
    if (this.timer) clearTimeout(this.timer);
    if (this.checkTimer) clearTimeout(this.checkTimer);

    this.props.closePopup();
  };

  check() {
    if (this.props.onCheck) {
      this.checkTimer = setTimeout(() => {
        this.props.onCheck();
        if (
          this.props.opened &&
          this.state.timeLeft > 1.5 * this.props.interval &&
          this.state.requestStatus &&
          this.state.requestStatus !== 'COMPLETE'
        ) {
          this.check();
        } else {
          clearTimeout(this.checkTimer);
        }
      }, this.props.interval * 1000);
    }
  }

  tick() {
    const { timeLeft } = this.state;
    if (this.props.opened) {
      if (timeLeft > 0) {
        if (!this.checkTimer) this.check();

        this.setState({ timeLeft: timeLeft - 1 });
        this.timer = setTimeout(this.tick, 1000);
      } else {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        if (this.props.onTimeout) {
          this.props.onTimeout();
        }
      }
    }
  }

  handleClick() {
    if (this.props.data.buttonUrl) {
      window.location.href = this.props.data.buttonUrl;
    } else if (this.props.onButtonClick) {
      this.props.onButtonClick();
    } else {
      this.props.closePopup();
    }
  }

  handleSecondaryClick() {
    if (this.props.data.buttonResetUrl) {
      window.location.href = this.props.data.buttonResetUrl;
    } else if (this.props.onSecondaryButtonClick) {
      this.props.onSecondaryButtonClick();
    } else {
      this.props.closePopup();
    }
  }

  renderLoader() {
    const { data } = this.props;
    const { result, step } = data;

    const resultIcon = data && result && result.titleIcon;
    const icons = ['chat', 'clock', 'infinitCalls', 'simOrder', 'infinitInternet'];

    return (
      <Loader
        lastIcon={this.getEmojiName(resultIcon)}
        size="small"
        status={step !== 'result' ? 'active' : 'ready'}
      >
        {icons.map((name) => (
          <Emoji key={name} name={name} />
        ))}
      </Loader>
    );
  }

  decideLoaderText() {
    const { type, serviceType } = this.props;
    const connectText =
      type === 'tariff' ?
        Ctx.tariff.connectTariffPreloaderText
      : Ctx.service.connectTariffPreloaderText;
    const disconnectText =
      type === 'tariff' ?
        Ctx.tariff.disconnectTariffPreloaderText
      : Ctx.service.disconnectTariffPreloaderText;

    if (serviceType === 'connect') {
      return connectText;
    }

    if (serviceType === 'disconnect') {
      return disconnectText;
    }
  }

  render() {
    const loaderText = this.decideLoaderText();

    return (
      <div className={cx('wrapper')}>
        <div className={cx('loaderContainer')}>{this.renderLoader()}</div>
        <p className={cx('loaderText')}>{loaderText}</p>
      </div>
    );
  }
}

WaitingPopup.defaultProps = {
  data: null,
  textVariables: {},
  timeout: 60,
  interval: 10,
};

WaitingPopup.propTypes = {
  data: PropTypes.object,

  type: PropTypes.string,
  serviceType: PropTypes.string,

  opened: PropTypes.bool,

  timeout: PropTypes.number,
  interval: PropTypes.number,

  onButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  closePopup: PropTypes.func,
  onCheck: PropTypes.func,
  onTimeout: PropTypes.func,
  onResult: PropTypes.func,
};
