import { useAppDispatch } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';
import { changeOptionSoc } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/optionsSlice/asyncThunks/changeOptionSoc';

import { useTariffOptionsGroupContent } from '../selectors/useTariffOptionsGroupContent';
import { sendYm } from '../../../tempYmUtils';

/** Хук подготовки данных для компонента TariffOptionsGroup.
 * Возвращает текст для элемента подсказки и функцию-обработчик */
export const usePrepareTariffOptionsGroup = (index: number) => {
  const dispatch = useAppDispatch();

  /** Текст для элемента подсказки */
  const blockingTooltipText = useTariffOptionsGroupContent();

  /** Функция-обработчик клика на опцию с отправкой аналитики */
  const handleChange = async (soc: string, optionTitle: string, isActive: boolean) => {
    /* Отправка яндекс метрики */
    sendYm(optionTitle, isActive, index ? 'service' : 'option');

    await dispatch(changeOptionSoc(soc));
  };

  return { handleChange, blockingTooltipText };
};
