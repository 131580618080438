import React from 'react';
import classNames from 'classnames/bind';
import { withRenderFlag } from '@beef/react';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

type ICenterLayout = {
  children: React.ReactNode;
  className?: string;
};

const _CenterLayout: React.FC<ICenterLayout> = ({ className, children }) => {
  return <div className={cn('wrap', className)}>{children}</div>;
};

export const CenterLayout = withRenderFlag(_CenterLayout);
