import { ResponseType } from '../types';

export const dataConverter = (content: ResponseType) => ({
  content: {
    ...content,
    years: content.years.map((year) => ({
      id: String(year),
      title: year,
    })),
    activeYear: String(content.activeYear),
    pageInfo: {
      ...content.pageInfo,
      pageNumber: 1,
    },
  },
});
