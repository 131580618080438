import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { BreadCrumbs, Text } from '@beef/ui-kit';
import { connect } from 'react-redux';

import { RedirectStore } from 'pages/Services/RedirectServiceCardLanding/store';
import {
  selectAuthenticated,
  selectLoadingInitialData,
  selectRedirectOptions,
  selectRedirectQPContent,
} from 'pages/Services/RedirectServiceCardLanding/store/redirect/selectors';
import { DetailProps } from 'pages/Services/RedirectServiceCardLanding/components/Banner/types';

import { Actions } from './Actions';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

const _Detail: FC<DetailProps> = ({
  breadcrumbs,
  title,
  text,
  titleColor,
  textColor,
  onOpenModal,
  onOpenCancelModal,
  options,
  isAuthenticated,
  loadingInitialData,
  buttons = {
    active: 'Подключить и настроить',
    change: 'Изменить настройки',
    cancel: 'Отключить все переадресации',
  },
}) => (
  <div className={cn('details')}>
    {breadcrumbs && (
      <div className={cn('breadcrumbs')}>
        <BreadCrumbs path={breadcrumbs} />
      </div>
    )}
    {title && (
      <Text color={titleColor} size="size0-b" tagType="h1">
        {title}
      </Text>
    )}
    {text && (
      <div className={cn('details__text')}>
        <Text color={textColor} size="size4-r">
          {text}
        </Text>
      </div>
    )}
    <Actions
      buttons={buttons}
      isAuthenticated={isAuthenticated}
      loadingInitialData={loadingInitialData}
      onOpenCancelModal={onOpenCancelModal}
      onOpenModal={onOpenModal}
      options={options}
    />
  </div>
);

const mapStateToProps = (state: RedirectStore) => ({
  loadingInitialData: selectLoadingInitialData(state),
  options: selectRedirectOptions(state),
  isAuthenticated: selectAuthenticated(state),
  buttons: selectRedirectQPContent(state)?.pereadresatsiya?.buttons,
});

export const Detail = connect(mapStateToProps)(_Detail);
