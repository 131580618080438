import React from 'react';
import PropTypes from 'prop-types';
import { and, map, pipe, prop, reduce, values } from 'ramda';
import { Loader as ConnectionLoader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import styles from './Loader.pcss';

const cx = classNames.bind(styles);

export const Loader = ({
  loading,
  children,
  className,
  loadingClassName,
  loadingChildrenClassName,
  noImage,
  isServiceConnection,
}) => (
  <div className={cx('loader', { loading, [loadingClassName]: loading }, className)}>
    {loading && !noImage ?
      <div className={cx('loaderContainer')}>
        {isServiceConnection ?
          <div className={cx('stretch')}>
            <ConnectionLoader status={loading ? 'active' : 'ready'} />
          </div>
        : <PlainLoader className={cx('loadingAnimation')} />}
      </div>
    : null}
    <div className={cx('children', { hidden: loading, [loadingChildrenClassName]: loading })}>
      {children}
    </div>
  </div>
);

Loader.propTypes = {
  loadingClassName: PropTypes.string,
  noImage: PropTypes.bool,
  loading: PropTypes.bool,
  loadingChildrenClassName: PropTypes.string,
  isServiceConnection: PropTypes.bool,
};

export default connect(({ external: { loading } }) => {
  const loadingInProgress = pipe(values, map(prop('loading'), reduce(and, true)))(loading);
  return { loading: loadingInProgress };
})(Loader);

export const PlainLoader = ({ className }) => (
  <img
    alt=""
    className={cx('plain', className)}
    role="presentation"
    src="https://static.beeline.ru/upload/images/b2c/icons/preloader.svg"
  />
);
