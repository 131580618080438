import React, { FC } from 'react';
import { ChipsStatefulSingleChoice, ConfigureOutlineIcon, ListOutlineIcon } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { SuccessActive } from 'pages/Services/RedirectServiceCardLanding/components/Status/SuccessActive';
import { ErrorStatus } from 'pages/Services/RedirectServiceCardLanding/components/Status/ErrorStatus';
import { FormRedirectAlways } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/FormRedirectAlways';
import { FormRedirectByConditions } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/FormRedirectByConditions';
import { ModalSettingsContentProps } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const ModalSettingsContent: FC<ModalSettingsContentProps> = ({
  activeTab,
  loading,
  content,
  options,
  formProps,
  onCloseModal,
  onChangeTab,
}) => {
  if (loading === 'succeeded') {
    return <SuccessActive hideButtons onCloseModal={onCloseModal} />;
  }

  if (loading === 'failed') {
    return <ErrorStatus hideButtons />;
  }

  return (
    <>
      <ChipsStatefulSingleChoice
        className={cx('tab')}
        defaultValue={activeTab}
        onChange={onChangeTab}
        options={[
          {
            icon: <ListOutlineIcon />,
            value: 'redirectAlways',
            title: content.redirectAlways?.tab,
          },
          {
            icon: <ConfigureOutlineIcon />,
            value: 'redirectByConditions',
            title: content.redirectByConditions?.tab,
          },
        ]}
        variant="card"
      />
      {activeTab === 'redirectAlways' && (
        <FormRedirectAlways {...formProps} options={options.redirectAlways} />
      )}
      {activeTab === 'redirectByConditions' && (
        <FormRedirectByConditions {...formProps} options={options.redirectByConditions} />
      )}
    </>
  );
};
