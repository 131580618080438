/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import { ActionBar, Button, Modal, Text } from '@beef/ui-kit';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';

import { redirectToNewSimPage } from 'pages/FancyNumber2022/utils';

import styles from './styles.pcss';
import { getPopup } from '../../store/selectors/content';

const cx = classnames.bind(styles);

const AuthModalLayout = ({ auth }) => {
  const switchToNewSimTab = useCallback(() => redirectToNewSimPage(), []);

  const openLoginPage = () => {
    window.location.href = `/login?returnUrl=${encodeURIComponent(window.location.href)}`;
  };

  return (
    <Modal
      actionBar={
        <ActionBar size="l">
          <Button onClick={openLoginPage} size="s">
            {auth.link}
          </Button>
        </ActionBar>
      }
      isOpen
      onClose={switchToNewSimTab}
      size="s"
    >
      <img alt={auth.title} className={cx('catImage')} src={auth.img} />
      <Text className={cx('title')} size="size2-m">
        {auth.title}
      </Text>
      <Text className={cx('description')} color="grey60" size="size4-r">
        {auth.description}
      </Text>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  auth: getPopup(state).auth,
  shouldRender: typeof window !== 'undefined',
});

export const AuthModal = compose(connect(mapStateToProps), withRenderFlag)(AuthModalLayout);
