import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

/**
 * Generic block wrapper for better indents handling
 */
export const BlockWrap = ({ className, children }) => (
  <div className={cx('wrap', className)}>{children}</div>
);

BlockWrap.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
