import { createSelector } from '@reduxjs/toolkit';

import { mapActiveCategoriesToArray } from '../mappers/filters';

const getCategoryState = (state) => state.category;

export const getActiveCategories = createSelector(getCategoryState, (state) => state.active);
export const getActiveCategoriesArray = createSelector(
  getActiveCategories,
  mapActiveCategoriesToArray,
);
