export enum EModal {
  ConnectConfirmStep = 'ConnectConfirmStep',
  DisconnectStep = 'DisconnectStep',
  UnavailableServiceStep = 'UnavailableServiceStep',
  BlockedServiceStep = 'BlockedServiceStep',
  TrustPayment = 'TrustPayment',
  BlockedDisconnectServiceStep = 'BlockedDisconnectServiceStep',
  ConfirmDisconnectStep = 'ConfirmDisconnectStep',
  DisconnectAcrhivedStep = 'DisconnectAcrhivedStep',
  TrustPaymentSuccess = 'TrustPaymentSuccess',
  Error = 'Error',
  ConnectionSuccess = 'ConnectionSuccess',
  ConnectionError = 'ConnectionError',
  DisconnectSuccess = 'DisconnectSuccess',
  DisconnectError = 'DisconnectError',
  RequestErrorStep = 'RequestErrorStep',
}

export enum EServiceName {
  ServiceConnectForm = 'serviceConnectForm',
  ServiceDisconnectForm = 'serviceDisconnectForm',
}

export enum EServiceField {
  DisconnectServiceUrl = 'disconnectServiceUrl',
  ConnectServiceUrl = 'connectServiceUrl',
}
