import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import Picture from 'pages-components/Picture';
import { isEmptyValue } from 'utils/isEmptyValue';
import { CommonModalType } from 'pages/SPN/types';

import { Loader } from '../Loader';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const CommonModal: FC<CommonModalType> = ({ data, animationName }) => {
  if (isEmptyValue(data)) return null;

  const { title, text: description, img } = data;
  const imgPriority = img || (img && !animationName) || (img && animationName);

  return (
    <div className={cn('wrapper')}>
      {imgPriority && <Picture alt="" className={cn('image')} lazy src={img} />}

      {animationName && !imgPriority && (
        <div className={cn('loader')}>
          <Loader name={animationName} />
        </div>
      )}

      <div className={cn('title')}>
        <Text size="size3-m" tagType="h2">
          {title}
        </Text>
      </div>

      {description && (
        <div className={cn('description')}>
          <Text color="grey60" size="size5-r-l">
            {description}
          </Text>
        </div>
      )}
    </div>
  );
};
