import React from 'react';
import classNames from 'classnames/bind';
import { Link, Text } from '@beef/ui-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

type TitleType = {
  onClick?: () => void;
  showMoreLink?: string;
  title: string;
};

export const Title = ({ title, showMoreLink, onClick }: TitleType) => {
  const isMobile = useIsMobileDimension();
  const linkText = isMobile ? 'Все' : 'Посмотреть все';
  return (
    <div className={cn('title')}>
      <Text size="size1-m">{title}</Text>
      {showMoreLink && (
        <Link href={showMoreLink} isNoUnderline onClick={onClick} size="size4-m">
          {linkText}
        </Link>
      )}
    </div>
  );
};
