import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';
import { CloseIcon } from '@beef/ui-kit';

import { Button } from '../Button';
import { ModalProps } from './types';
import { useModal } from './hook';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export type { ModalProps } from './types';

/*
 * Модально окно
 * */
export const Modal: FC<ModalProps> = ({ children, ...props }) => {
  const { container, commonModalRef, overlayRef, isOpen, onClose } = useModal(props);

  // на сервере модалку не отображаем
  if (!container) {
    return null;
  }
  return isOpen ?
      createPortal(
        <div className={cn('overlay')} ref={overlayRef}>
          <div className={cn('modal-wrapper')}>
            <div className={cn('modal')} ref={commonModalRef}>
              {children}
              <Button className={cn('close')} color="white" onClick={onClose} size="s">
                <CloseIcon />
              </Button>
            </div>
          </div>
        </div>,
        container,
      )
    : null;
};
