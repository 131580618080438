import React, { useEffect, useMemo, useState } from 'react';
import { Input, Text } from '@beef/ui-kit';
import { debounce } from '@beef/utils';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import EmptyButton from 'pages-components/EmptyButton';
import Icon from 'pages-components/Icon';
import FoundPoint from 'pages/Maps/components/Controls/FoundPoint';
import OptionsPopup from 'pages/Maps/components/Controls/OptionsPopup';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SearchForm = ({
  analyticsEvents,
  foundPoints,
  onPointChoose,
  searchOnMap,
  searchOnMapError,
  isCarnicaRedesign,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectIndex, setSelectIndex] = useState(-1);

  const onInputChangeDebounced = useMemo(
    () => debounce(3000, (placeholder) => analyticsEvents?.onSearchInputFill?.(placeholder)),
    [analyticsEvents],
  );
  useEffect(() => () => onInputChangeDebounced.cancel(), [analyticsEvents]);

  const onChange = (e) => {
    onInputChangeDebounced(e.currentTarget.placeholder);
    const { value } = e.target;
    searchOnMap(value);
    setSearchValue(value);
    setSelectIndex(-1);
  };

  const onChosePoint = (point) => () => {
    analyticsEvents?.onChoosePoint?.(point.name);
    setSearchValue(point.name);
    onPointChoose(point);
  };

  const onKeyDown = (e) => {
    const direction = {
      38: -1,
      40: 1,
    }[e.keyCode];

    if (e.keyCode === 13) e.preventDefault();

    if (!foundPoints || !foundPoints.length) return;

    if (direction) {
      let newSelectIndex = selectIndex + direction;

      if (newSelectIndex >= foundPoints.length) newSelectIndex = 0;
      if (newSelectIndex < 0) newSelectIndex = foundPoints.length - 1;

      setSelectIndex(newSelectIndex);
      e.preventDefault();
    }

    const point = foundPoints[selectIndex];

    if (e.keyCode === 13 && point) {
      setSearchValue(point.name);
      onPointChoose(point);
    }
  };

  return (
    <form
      className={cx('form')}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className={cx('input')}>
        <Input
          className={cx({
            'carnica-search': isCarnicaRedesign,
          })}
          id="place"
          onChange={onChange}
          onClick={({ target }) => analyticsEvents?.onSearchInputClick?.(target.placeholder)}
          onKeyDown={onKeyDown}
          placeholder={isCarnicaRedesign ? 'город, улица, метро' : 'Улица или станция метро'}
          value={searchValue}
        />
      </div>

      <EmptyButton
        className={cx('button', !searchValue && 'search')}
        onClick={() => {
          setSearchValue('');
        }}
        tabIndex={searchValue ? 0 : -1}
      >
        <Icon name={searchValue ? 'cross' : 'search'} />
      </EmptyButton>
      {foundPoints && searchValue && (
        <OptionsPopup className={cx('foundPoints')}>
          {foundPoints.length > 0 &&
            foundPoints.map((point, index) => (
              <FoundPoint
                {...point}
                key={index}
                onClick={onChosePoint(point)}
                selected={selectIndex === index}
              />
            ))}
          {(searchOnMapError || foundPoints.length === 0) && (
            <div className={cx('error')}>
              <Text color="grey95" size="size5-r-s">
                Ничего не найдено :(
              </Text>
            </div>
          )}
        </OptionsPopup>
      )}
    </form>
  );
};

SearchForm.propTypes = {
  isCarnicaRedesign: PropTypes.bool,
  analyticsEvents: PropTypes.shape({
    onSegmentedControlClick: PropTypes.func,
    onCheckboxClick: PropTypes.func,
    onChoosePoint: PropTypes.func,
    onGeolocationButtonClick: PropTypes.func,
    onSearchInputFill: PropTypes.func,
    onSearchInputClick: PropTypes.func,
    onZoomButtonClick: PropTypes.func,
  }),
  foundPoints: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  onPointChoose: PropTypes.func,
  searchOnMap: PropTypes.func,
  searchOnMapError: PropTypes.bool,
};

export default SearchForm;
