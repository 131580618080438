import { DEFAULT_DEF_CODE } from '../../../constants';

export const mapDropdownOptions = (defCodes) => [
  {
    slug: DEFAULT_DEF_CODE,
    label: '–––',
  },
  ...defCodes.map((item) => ({
    slug: item,
    label: item,
  })),
];
