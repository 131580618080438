import { useEffect } from 'react';

import { useAppSelector } from '../../../store';
import { EDisableNextEvent } from '../../../store/slices/stepperSlice/types';
import { useDisableNext } from '../../../hooks/disabledNextHooks/useDisableNext';

/** Хук для управления состоянием кнопки "Далее" на странице CallbackRequest */
export const useDisableNextButton = () => {
  const currentPhone = useAppSelector((state) => state.total.userPhoneNumber);
  const { disableNext, enableNext, isDisabledNextEvent } = useDisableNext(
    EDisableNextEvent.callbackRequest,
  );

  useEffect(() => {
    disableNext();
    return () => {
      enableNext();
    };
  }, []);

  useEffect(() => {
    if (currentPhone) {
      enableNext();
    }

    if (!currentPhone && !isDisabledNextEvent) {
      disableNext();
    }
  }, [currentPhone, isDisabledNextEvent]);
};
