import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import {
  Button,
  Checkbox,
  Heading,
  Link,
  SmartForm,
  SmartInput,
  Status,
  Tooltip,
} from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import store from 'store';

// Components

import Ctx from 'pages-components/Ctx';
import Captcha from 'pages/Feedback/components/Captcha/Captcha';
import SimOrderBenefits from 'pages-components/SimOrderBenefits/SimOrderBenefits';
import Offerta from 'pages-components/Offerta';
import WarningStatus from 'pages/ProductCard/components/WarningStatus';
import GradientText from 'pages-components/GradientText';
import DeliveryForm from 'widgets/DeliveryForm';

// Utils & Actions
import { unformatPhone, validateFancyNumber } from 'utils/format-string';
import { openOrderPaymentForm } from 'widgets/actions/deliveryForm';

// Styles

import styles from '../SimOrderForm.pcss';

const cx = classNames.bind(styles);

class OrderForm extends PureComponent {
  state = {
    captcha: '',
    deliveryFormError: false,
  };

  onFormSubmit = (needPayment) => () => {
    if (needPayment) {
      this.props.openOrderPaymentForm(
        this.props.orderPaymentFormContent,
        { number: this.props.chosenNumber, price: this.props.chosenNumberPrice || 0 },
        this.props.tariffId,
        unformatPhone(this.props.phone),
      );

      return null;
    }

    const deliveryData = this.getDeliveryFormData();

    if (this.deliveryFormRef && !deliveryData && this.props.deliveryActiveTab !== 'makeRequest') {
      return;
    }

    this.props.onSubmit(deliveryData);
  };

  onDeliveryFormError = () => {
    this.setState({ deliveryFormError: true });
  };

  getDeliveryFormData = () =>
    this.deliveryFormRef && this.deliveryFormRef.getWrappedInstance().onSubmit();

  handleCaptchaChange = (value) => {
    this.setState({ captcha: value });
    if (this.props.onCaptchaChange) this.props.onCaptchaChange(value);
  };

  renderWarning(sameRegion, showWarning) {
    if (showWarning) {
      const { warningData } = this.props;
      return <WarningStatus warningData={warningData} />;
    }

    const link =
      this.props.fancyNumberLinkUrl ||
      '/customers/products/mobile/services/details/nomer-na-vybor/dlya-abonentov/';
    return sameRegion ?
        <Status className={['attention', cx('existingWarning')]} emoji="statusFail">
          Закажите новую SIM с выбранным номером, нажав кнопку ниже. Если вы хотите заменить свой
          текущий номер, перейдите по <Link href={link}>ссылке</Link>.
        </Status>
      : null;
  }

  renderAdditionalDescription = (simOrderForm) =>
    simOrderForm && (
      <div className={cx('textContainer')}>
        <span dangerouslySetInnerHTML={{ __html: simOrderForm.additionalDescription }} />
        {simOrderForm.additionalDescriptionTooltip && (
          <Tooltip>
            <span dangerouslySetInnerHTML={{ __html: simOrderForm.additionalDescriptionTooltip }} />
          </Tooltip>
        )}
      </div>
    );

  render() {
    const {
      onCancel,
      phone,
      chosenNumber,
      errors,
      onPhoneChange,
      sameRegion,
      captchaRequired,
      horizontal,
      description,
      orderNewSimText,
      callMeBackText,
      hideBenefit,
      offerta,
      pdnAgreement,
      conditionalsText,
      convertToSubTitle,
      discountValue,
      showWarning,
      checkBoxContent,
      checkedValue,
      tariffId,
      deliveryForm,
      esim,
      tariffData,
      deliveryActiveTab,
      isSelectedEmployeeDelivery,
      isSelectedFastDelivery,
      toPaymentButtonText,
      paymentAvailable,
      constructorOptions,
    } = this.props;

    const hideForm = deliveryActiveTab === 'esim';
    const { simOrderForm } = pathOr(
      {},
      ['external', 'tariffConnectionPopup', 'data'],
      store.getState(),
    );
    const needPayment =
      deliveryActiveTab === 'delivery' &&
      paymentAvailable &&
      (isSelectedEmployeeDelivery || isSelectedFastDelivery);
    const discountLabel =
      tariffData?.simDiscount?.deliveryOnly && (tariffData.productTypeTitle || {}).discount;
    // eslint-disable-next-line no-nested-ternary
    const submitButtonText =
      needPayment ? toPaymentButtonText
      : sameRegion ? orderNewSimText
      : callMeBackText;

    return (
      <div className={cx({ horizontal })}>
        {discountValue && !tariffData?.simDiscount?.deliveryOnly ?
          <Heading className={cx('heading', convertToSubTitle && 'subTitle')} level="3">
            {Ctx.tariff.title.discountedSim}
            <GradientText className={cx('discount')} text={`${discountValue}%`} />
          </Heading>
        : null}

        {deliveryForm && !this.state.deliveryFormError && (
          <DeliveryForm
            {...deliveryForm}
            chosenNumber={{ number: chosenNumber }}
            constructorId={tariffData.tariffConstructorId}
            constructorOptions={constructorOptions}
            discountLabel={discountLabel}
            esim={esim?.showTab && esim}
            onCancel={onCancel}
            onError={this.onDeliveryFormError}
            optionSocs={tariffData.optionSocs}
            ref={(e) => {
              this.deliveryFormRef = e;
            }}
            tariffId={tariffId}
          />
        )}
        {description && !deliveryForm && (
          <div className={cx('textContainer')} dangerouslySetInnerHTML={{ __html: description }} />
        )}

        {!deliveryForm && !showWarning && this.renderAdditionalDescription(simOrderForm)}

        {checkBoxContent && (
          <div className={cx('selfregAction')}>
            <Checkbox checked={checkedValue} handleChange={this.props.onCheckboxClick} />
            <div className={cx('textsInfo')}>
              <span className={cx('title')}>{checkBoxContent.title}</span>
              {checkBoxContent.legal && (
                <Tooltip className={cx('tooltip')}>
                  <span dangerouslySetInnerHTML={{ __html: checkBoxContent.legal }} />
                </Tooltip>
              )}
            </div>
          </div>
        )}

        {!hideForm && (
          <SmartForm
            className={cx('form')}
            onSubmit={this.onFormSubmit(needPayment)}
            onSubmitFailed={this.getDeliveryFormData}
            serverErrors={errors}
            validator={{
              phone: validateFancyNumber(chosenNumber),
              Captcha: (value) => ({
                status: value && value.length === 5 ? 'ok' : 'fail',
                msg: 'Неверные символы',
              }),
            }}
          >
            {horizontal && this.renderWarning(sameRegion, showWarning)}
            <div className={cx('input')}>
              <SmartInput
                className="bigSize"
                id="simOrder_phone"
                inputId="phone"
                keepInitialValue
                mask="+7 ___ ___ __ __"
                onChange={onPhoneChange}
                size={horizontal ? 'big' : undefined}
                value={phone}
              />
            </div>
            {!horizontal && this.renderWarning(sameRegion)}
            {captchaRequired && (
              <div className={cx('input', 'captcha')}>
                <Captcha
                  captchaKey={this.props.captchaKey}
                  height={horizontal ? 31 : null}
                  inputId="Captcha"
                  name="Captcha"
                  onChange={this.handleCaptchaChange}
                  size={horizontal ? 'big' : null}
                />
                <SmartInput
                  inputId="_captcha_key"
                  name="_captcha_key"
                  type="hidden"
                  value="sim-order-form"
                />
              </div>
            )}
            <div className={cx('button')}>
              <Button className="big" wide>
                {submitButtonText}
              </Button>
            </div>
          </SmartForm>
        )}

        {deliveryForm &&
          !showWarning &&
          !hideForm &&
          this.renderAdditionalDescription(simOrderForm)}

        {!hideBenefit && (
          <SimOrderBenefits
            benefits={deliveryForm ? [] : 'delivery'}
            className={cx('benefits')}
            showAgreement
          />
        )}
        <Offerta
          conditionalsText={conditionalsText}
          offerta={offerta}
          pdnAgreement={pdnAgreement}
        />
      </div>
    );
  }
}

OrderForm.defaultProps = {
  description: 'Оставьте номер, и мы свяжемся с вами для уточнения деталей.',
  orderNewSimText: 'Заказать новую SIM',
  callMeBackText: 'Перезвонить мне',
  toPaymentButtonText: 'К оплате',
  captchaKey: 'sim-order-form',
  discountValue: false,
};

OrderForm.propTypes = {
  phone: PropTypes.string,
  errors: PropTypes.object,
  sameRegion: PropTypes.bool,
  captchaRequired: PropTypes.bool,
  onSubmit: PropTypes.func,
  onPhoneChange: PropTypes.func,
  onCaptchaChange: PropTypes.func,
  horizontal: PropTypes.bool,
  description: PropTypes.string,
  orderNewSimText: PropTypes.string,
  callMeBackText: PropTypes.string,
  hideBenefit: PropTypes.bool,
  captchaKey: PropTypes.string,
  fancyNumberLinkUrl: PropTypes.string,
  discountValue: PropTypes.number,
  checkBoxContent: PropTypes.object,
  checkedValue: PropTypes.bool,
  deliveryActiveTab: PropTypes.string,
  toPaymentButtonText: PropTypes.string,
  isSelectedFastDelivery: PropTypes.bool,
  isSelectedEmployeeDelivery: PropTypes.bool,
  openOrderPaymentForm: PropTypes.func,
  orderPaymentFormContent: PropTypes.object,
  paymentAvailable: PropTypes.bool,
  tariffData: PropTypes.object,
  chosenNumber: PropTypes.string,
  onCancel: PropTypes.func,
  tariffId: PropTypes.number,
  pdnAgreement: PropTypes.string,
  conditionalsText: PropTypes.object,
  convertToSubTitle: PropTypes.bool,
  showWarning: PropTypes.bool,
  deliveryForm: PropTypes.object,
  esim: PropTypes.shape({
    showTab: PropTypes.bool,
  }),
  onCheckboxClick: PropTypes.func,
};

const ConnectedOrderForm = connect(
  (state) => ({
    deliveryActiveTab: pathOr(null, ['deliveryForm', 'deliveryData', 'activeTab'], state.external),
    paymentAvailable: pathOr(
      true,
      ['deliveryForm', 'baseDeliveryDaysData', 'paymentAvailable'],
      state.external,
    ),
    isSelectedEmployeeDelivery: pathOr(
      false,
      ['deliveryForm', 'inputValues', 'isSelectedEmployeeDelivery'],
      state.external,
    ),
    isSelectedFastDelivery: pathOr(
      false,
      ['deliveryForm', 'inputValues', 'isSelectedFastDelivery'],
      state.external,
    ),
    orderPaymentFormContent: pathOr(null, ['orderPaymentForm', 'texts'], state.external),
    tariffData: pathOr({}, ['tariff', 'data'], state.external),
  }),
  { openOrderPaymentForm },
)(OrderForm);

export default ConnectedOrderForm;
