import axios from 'axios';
import { path } from 'ramda';

import { getFilterList, getFilterParams, getUrlParam, setFiltersToUrlState } from '../utils';
import { CHECKBOX_TYPE } from '../constants';
import {
  CHOOSE_POINT,
  GET_POINTS,
  GET_REQUEST_TOGGLE,
  HIDE_POINT_INFO,
  HIDE_POINT_ON_MAP,
  SEARCH_ON_MAP,
  SEARCH_ON_MAP_ERROR,
  SEARCH_REGION_RF,
  SET_CURRENT_LOCATION,
  SET_IS_ANNIVERSARY,
  SET_UPDATE_FILTERS_FLAG,
  TOGGLE_MODE,
  TOGGLE_POINTS_LIST,
  UPDATE_FILTER,
} from './constants';

export const setFiltersMapParams = (filter) => (dispatch) => {
  dispatch({ type: UPDATE_FILTER, payload: filter });
};

export const togglePointsList = (payload) => ({
  type: TOGGLE_POINTS_LIST,
  payload,
});

export const setIsUpdateFilterList = (payload) => ({
  type: SET_UPDATE_FILTERS_FLAG,
  payload,
});

export const toggleMode = (payload) => ({
  type: TOGGLE_MODE,
  payload,
});

export const choosePoint = (payload) => ({
  type: CHOOSE_POINT,
  payload,
});

export const setIsAnniversary = (dispatch) => {
  if (getUrlParam('anniversary') === '1') {
    dispatch({ type: SET_IS_ANNIVERSARY });
  }
};

export const toggleInfo = (payload) => (dispatch) => {
  if (payload) {
    dispatch(choosePoint(payload));
  } else {
    dispatch({
      type: HIDE_POINT_INFO,
    });
  }
};

export const hidePointOnMap = () => (dispatch) => {
  dispatch({
    type: HIDE_POINT_ON_MAP,
  });
};

const mapGeoObjects = (items) =>
  items &&
  items.map(({ GeoObject }) => {
    const { lowerCorner, upperCorner } = GeoObject.boundedBy.Envelope;
    return {
      lats: [+lowerCorner.split(' ')[1], +upperCorner.split(' ')[1]],
      lons: [+lowerCorner.split(' ')[0], +upperCorner.split(' ')[0]],
      name: GeoObject.name,
      description: GeoObject.description,
      id: GeoObject.Point.pos,
    };
  });

/**
 * @description Функция проверяет, существует ли в URL параметр beelineOwnOffices
 * @param appliedOwnerOfficesFilter {string} Значение параметра bt в запросе;
 * @returns { number }
 */

const getOwnerOfficeType = (appliedOwnerOfficesFilter) => {
  // beelineOwnOffices = OfficeType
  if (appliedOwnerOfficesFilter) {
    return CHECKBOX_TYPE.officeType;
  }

  if (getUrlParam('beelineOwnOffices') !== CHECKBOX_TYPE.officeType) {
    return getUrlParam('beelineOwnOffices');
  }

  return undefined;
};

const getBusinessType = (appliedOfficesFilter) => {
  // bt = BusinessType
  if (appliedOfficesFilter) {
    return CHECKBOX_TYPE.businessType;
  }

  if (getUrlParam('bt') !== CHECKBOX_TYPE.businessType) {
    return getUrlParam('bt');
  }

  return undefined;
};

export const setSearchResults = (payload) => (dispatch) => {
  dispatch({ type: SEARCH_ON_MAP, payload });
};

export const searchOnMap = (query) => (dispatch) => {
  window.ymaps
    .geocode(query, {
      json: true,
      results: 4,
      boundedBy: SEARCH_REGION_RF,
      strictBounds: true,
    })
    .then(
      (resp) => {
        const payload = mapGeoObjects(resp.GeoObjectCollection.featureMember);

        dispatch(setSearchResults(payload));
      },
      () => {
        dispatch({ type: SEARCH_ON_MAP_ERROR });
      },
    );
};

// ignoreShopPoints нужен для принудительного отключения точек шопа
export const getPoints =
  ({ bounds, types, ignoreShopPoints = false, sng, sngPoints }) =>
  (dispatch, getState) => {
    if (path(['external', 'maps', 'mapMode'], getState()) === 'coverZone') {
      return;
    }

    if (sng) {
      dispatch({ type: GET_POINTS, payload: sngPoints });
    } else {
      const { appliedFilters = {}, isUpdatedMapFilterLinkEnabled } = getState().external.maps;
      const { appliedOfficesFilter, appliedOwnerOfficesFilter } = appliedFilters;
      const filters =
        isUpdatedMapFilterLinkEnabled ?
          getFilterList(appliedFilters)
        : getFilterParams(appliedFilters);

      axios
        .get('/customers/beeline-map/getpoints/', {
          params: {
            lat1: bounds[0][0],
            lat2: bounds[0][1],
            lon1: bounds[1][0],
            lon2: bounds[1][1],
            bt: getBusinessType(appliedOfficesFilter),
            beelineOwnOffices: getOwnerOfficeType(appliedOwnerOfficesFilter),
            types: types || getUrlParam('types'),
            ...filters,
            ignoreShopPoints: ignoreShopPoints || appliedOwnerOfficesFilter,
          },
        })
        .then(({ data }) => {
          dispatch({ type: GET_POINTS, payload: data });
        });
    }
  };

export const getRequestToggle = (dispatch) => {
  axios.get('/customers/beeline-map/getpoints/').then(({ data }) => {
    dispatch({ type: GET_REQUEST_TOGGLE, payload: data.isUpdatedMapFilterLinkEnabled });
  });
};

export const setCurrentLocation = (payload) => (dispatch) => {
  dispatch({ type: SET_CURRENT_LOCATION, payload });
};

export const setFilters = (payload) => (dispatch) => {
  setFiltersToUrlState(payload);
  dispatch(setIsUpdateFilterList(true));
};
