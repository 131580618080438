import React, { FC } from 'react';
import { InfoBannerPropsType, PageRow, InfoBanner as SmartInfoBanner } from '@beef/smart-kit';
import classNames from 'classnames/bind';
import { withRenderFlag } from '@beef/react';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';

import { ANALYTICS } from 'pages/PromotionsPage/analytics';
import { withElementInView } from 'utils/hocs/withElementInView';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

type BannerPropsType = {
  image: string;
} & InfoBannerPropsType;

type MapStateToPropsType = {
  shouldRender: boolean;
} & BannerPropsType;

const _InfoBanner: FC<BannerPropsType> = ({ elementRef, ...props }) => (
  <PageRow blockSpacersPreset="m" tagName="section">
    <div className={cn('wrapper')} ref={elementRef}>
      <SmartInfoBanner {...props} />
    </div>
  </PageRow>
);

const _InfoBannerWithInView = withElementInView(_InfoBanner)((props: BannerPropsType) => {
  ANALYTICS.BOTTOM_BANNER_VIEW.sendParams({
    title: props.title,
    link: props.button.link,
  });
});

export const InfoBanner = compose(
  // @ts-expect-error state not typed in ts yet
  connect(
    ({ content }): MapStateToPropsType => ({
      ...content.infoBanner,
      button: {
        ...content.infoBanner.button,
        onClick: () =>
          ANALYTICS.BOTTOM_BANNER_CLICK.sendParams({
            title: content.infoBanner.title,
            link: content.infoBanner.button.link,
          }),
      },
      shouldRender: content.infoBanner,
    }),
  ),
  withRenderFlag,
)(_InfoBannerWithInView);

// @ts-expect-error state not typed in ts yet
export const MainBanner = compose(
  connect(
    ({ content }): MapStateToPropsType => ({
      ...content.mainAction,
      button:
        content.mainAction?.button ?
          {
            ...content.mainAction.button,
            onClick: () =>
              ANALYTICS.MAIN_BANNER_CLICK.sendParams({
                title: content.mainAction.title,
                link: content.mainAction.button.link,
              }),
          }
        : undefined,
      shouldRender: content.mainAction,
    }),
  ),
  withRenderFlag,
)(_InfoBanner);
