import { useEffect } from 'react';
import classNames from 'classnames/bind';

import styles from '../styles.pcss';

const cx = classNames.bind(styles);

export const useSideBarOpen = (isSideBarOpen: boolean) => {
  useEffect(() => {
    const bodyRoot = document.body;

    if (isSideBarOpen) {
      bodyRoot.classList.add(cx('fixed'));
      window.isSeenSettingsBlock = false;
      window.isSideBarOpen = true;
    } else {
      window.isSideBarOpen = false;
      bodyRoot.classList.remove(cx('fixed'));
    }
  }, [isSideBarOpen]);
};
