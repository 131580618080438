import React, { FC } from 'react';
import { connect } from 'react-redux';

import { BaseStatus } from 'pages/Services/RedirectServiceCardLanding/components/Status/BaseStatus';
import { RedirectStore } from 'pages/Services/RedirectServiceCardLanding/store';
import { selectRedirectQPContent } from 'pages/Services/RedirectServiceCardLanding/store/redirect/selectors';
import { ErrorStatusProps } from 'pages/Services/RedirectServiceCardLanding/components/Status/types';

const _ErrorStatus: FC<ErrorStatusProps> = (
  props = {
    content: {
      img: {
        url: 'https://static.beeline.ru/upload/images/b2c/partners/smt-going-wrong-cat.svg',
      },
      title: 'Что-то пошло не по плану',
      description: 'Иногда такое случается — обновите страницу',
      buttons: {
        main: 'Повторить',
      },
    },
  },
) => {
  const onClickMain = () => {
    window.location.reload();
  };

  return (
    <div>
      <BaseStatus {...props?.content} hideButtons={props?.hideButtons} onClickMain={onClickMain} />
    </div>
  );
};

const mapStateToProps = (state: RedirectStore) => ({
  content: selectRedirectQPContent(state)?.error,
});

export const ErrorStatus = connect(mapStateToProps)(_ErrorStatus);
