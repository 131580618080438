import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  TRequestParams,
  TUseGetTotalBillBase,
} from '../components/totalBillPageComponents/TotalBill/types';
import { apiGetTotalUrl } from './constants';

/** Тип запроса (params) */
type TFetchTotalBillReq = {
  characterSoc: string;
  contactPhone: null | string;
  endDate?: string;
  flat?: string;
  gbValue: number;
  generation: number;
  houseId?: string;
  isMnp: boolean;
  isNewSim: boolean;
  minValue: number;
  optionsSoc: string;
  servicesInacIdList?: string;
  /* Время прихода мастера для подключения ШПД */
  startDate?: string;
  tariffSoc: string;
};

/** Тип ответа */
export type TFetchTotalBillQueryRes = {
  commonBill: TUseGetTotalBillBase;
  isSuccess: boolean;
  requestParams: TRequestParams;
};

/** Запрос данных по total чеку */
export const getTotalBill = createAsyncThunk<TFetchTotalBillQueryRes, TFetchTotalBillReq>(
  'presetApi/getTotalBill',
  async (params, { signal }) => {
    try {
      return (
        await axios.get<TFetchTotalBillQueryRes>(apiGetTotalUrl, {
          params,
          signal,
        })
      ).data;
    } catch (e) {
      return { isSuccess: false, commonBill: null, requestParams: null };
    }
  },
);
