import { PageRow, SubscriptionCard } from '@beef/smart-kit';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import React from 'react';

import { selectSubscriptionsData } from '../../store/selectors';
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const Subscriptions: React.FC<ReturnType<typeof selectSubscriptionsData>> = ({
  partnerName,
  prefixTitle,
  subscriptions,
}) => (
  <PageRow blockSpacersPreset="m" tagName="section">
    <Text className={cn('title')} size="size1-m">
      {[prefixTitle, partnerName].filter(Boolean).join(' ')}
    </Text>
    <div className={cn('container')}>
      {subscriptions?.map?.((subscription) => <SubscriptionCard {...subscription} />)}
    </div>
  </PageRow>
);
