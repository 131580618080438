import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { ymOrderTariffUpFormBidView } from 'pages/FTTB2021/components/OrderTariffFormPopup/analytics';

import { HeaderContainer } from './HeaderContainer';
import { WarningInfo } from '../WarningAnswers/WarningInfo';
import { TariffDetailsContainer } from './TariffDetailsContainer';
// Selectors
import { getIsConnectedTariff } from '../../../store/selectors/totalSettings';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffDetailsFormLayout = ({ isConnected, handleConvergencePopup, onInit }) => {
  useEffect(() => {
    onInit?.();
  }, []);
  return (
    <div className={cx('wrapper')}>
      <HeaderContainer />
      <div className={cx('wrapper__content')}>
        {!isConnected && <WarningInfo />}
        <TariffDetailsContainer {...{ handleConvergencePopup }} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isConnected: getIsConnectedTariff(state),
});

const mapDispatchToProps = () => ({
  onInit: () => ymOrderTariffUpFormBidView('fmc_bid_view'),
});

TariffDetailsFormLayout.propTypes = {
  isConnected: PropTypes.bool,
  onInit: PropTypes.func,
  handleConvergencePopup: PropTypes.func,
};

export const TariffDetailsForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TariffDetailsFormLayout);
