import axios from 'axios';

export const ModalKeys = {
  tariffChanged: 'tariffChanged',
  requestSent: 'requestSent',
  hasOpenRequest: 'hasOpenRequest',
  weWillCallYouBack: 'weWillCallYouBack',
  notEnoughMoneyOnBalance: 'notEnoughMoneyOnBalance',
  waiveHomeInternet: 'waiveHomeInternet',
  continueWithoutEquipment: 'continueWithoutEquipment',
  somethingWentWrong: 'somethingWentWrong',
  tariffConnection: 'tariffConnection',
  fttbConflictsConnection: 'fttbConflictsConnection',
  fttbConflictsUpdate: 'fttbConflictsUpdate',
  somethingWentWrongAundentificated: 'somethingWentWrongAundentificated',
  requestSentAundentificated: 'requestSentAundentificated',
};

export type ConnectTariffModal = {
  confirmButton: string;
  description: string;
  imageSrc: string;
  rejectButton: string;
  title: string;
};

type PostConnectTariffResponse = {
  content: {
    [key in keyof typeof ModalKeys]?: ConnectTariffModal;
  };
  errorStatusCode: null | number;
  hasFttbConflicts: boolean;
  isExistOrder: boolean;
  isSucceeded: boolean;
  isSuccessed: boolean;
};

type PostConnectTariffRequest = {
  abonentFirstName?: string;
  abonentLastName?: string;
  abonentMiddleName?: string;
  contactPhone?: string;
  flat?: string;
  fttbServices?: any;
  houseId?: number;
};

export const postConnectTariff = async (
  params: PostConnectTariffRequest,
): Promise<PostConnectTariffResponse> => {
  try {
    const { data } = await axios.post<PostConnectTariffResponse>(
      '/api/convergence/connect-tariff',
      params,
      { headers: { 'X-Requested-With': 'XMLHttpRequest' } },
    );
    return data;
  } catch (err) {
    // @ts-expect-error TS2345: Argument of type 'unknown' is not assignable to parameter of type 'string | undefined'.
    throw new Error(err);
  }
};
