import React, { memo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { Header } from './components/Header';
import { Discount } from './components/Discount';
import { OptionsList } from './components/OptionsList';
import { TOptionsItem } from './components/OptionsItem';

const cx = classNames.bind(styles);

export type TBillGroup = {
  discountText: string;
  optionsList: TOptionsItem[];
  title?: string;
  tooltipText?: string;
};

/** Секция подключаемой/отключаемой группы опций в total-чеке */
export const BillGroup = memo<TBillGroup>(({ title, tooltipText, discountText, optionsList }) => (
  <div className={cx('wrapper')}>
    <Header {...{ title, tooltipText }} />
    <Discount discountText={discountText} />
    <OptionsList optionsList={optionsList} />
  </div>
));
