import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import PopupStateless from 'pages-components/Popup';
// import Wizard, { Step } from 'pages-components/Wizard/Wizard';

// Actions
import { openTariffDescriptionPopup } from '../../../actions/tariffConnect';

class TariffDescriptionPopup extends Component {
  render() {
    const { isTariffDescriptionPopupOpen, popupContent } = this.props;

    return (
      <PopupStateless
        onClose={() => this.props.openTariffDescriptionPopup(false)}
        opened={isTariffDescriptionPopupOpen}
      >
        <div dangerouslySetInnerHTML={{ __html: popupContent }} />
      </PopupStateless>
    );
  }
}

TariffDescriptionPopup.propTypes = {
  isTariffDescriptionPopupOpen: PropTypes.bool,
  popupContent: PropTypes.string,

  openTariffDescriptionPopup: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isTariffDescriptionPopupOpen: state.external.isTariffDescriptionPopupOpen,
});

const mapDispatchToProps = {
  openTariffDescriptionPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(TariffDescriptionPopup);
