/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
// Components
import { Switch, Text } from '@beef/ui-kit';

// Utils
import { removePaymentPeriodSymbol } from '../../../../utils';
// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const DetailsCard = ({
  description,
  name,
  price,
  paymentPeriod,
  isSubscriptionStatus,
  onChange,
  checked,
  isLoading,
}) => {
  const ellipsis = description.length > 75 ? '...' : '';
  const cuttingDescription =
    description ?
      `${description.substring(
        0,
        description.length > 75 ? 75 : description.length,
      )}${ellipsis}`.trim()
    : '';

  return (
    <div className={cn('details')}>
      <Text size="size4-m">{name}</Text>
      <div className={cn('details__description')}>
        <Text color="grey50" size="size5-r-l">
          {cuttingDescription}
        </Text>
      </div>
      <div className={cn('details__setup')}>
        <div className={cn('details__price')}>
          <Text size="size2-m">{`${price} ₽`}</Text>
          <Text size="size5-r-s">{removePaymentPeriodSymbol(paymentPeriod)}</Text>
        </div>

        {!isSubscriptionStatus && (
          <Switch
            checked={checked}
            disabled={isLoading}
            isLoading={isLoading}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
};

DetailsCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  paymentPeriod: PropTypes.string,
  isSubscriptionStatus: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
};
