import React from 'react';
import PropTypes from 'prop-types';
import { Emoji } from '@beef/legacy-ui-kit';
import { Text } from '@beef/ui-kit';

// Styles
import classNames from 'classnames/bind';

import styles from './ResultPopup.pcss';

const cx = classNames.bind(styles);

const ResultHeader = ({ title, icon }) => {
  if (!title) {
    return null;
  }

  if (!icon) {
    return (
      <Text size="size1-m" tagType="h1">
        {title}
      </Text>
    );
  }

  const parts = title.split(' ');
  let lastPart = '';
  let titleText = title;
  if (parts.length > 1) {
    lastPart = `&nbsp;${parts.pop()}`;
    titleText = parts.join(' ');
  }

  return (
    <div className={cx('resultHeading')}>
      <Text size="size1-m" tagType="h1">
        {titleText}
        &nbsp;
        <span className={cx('nowrap')}>
          <Text size="size1-m" tagType="span">
            {lastPart}
          </Text>
          <Emoji className="inHeading" path={icon} />
        </span>
      </Text>
    </div>
  );
};

ResultHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default ResultHeader;
