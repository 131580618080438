import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'ramda';

// Components
import { Button, Heading, Link, Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';
import PlainContainer from 'pages-components/PopupContainer/PlainContainer';
import Description from 'pages-components/Descriptions';
import Icon from 'pages-components/Icon';
import Picture from 'pages-components/Picture';
import SocialButtons from 'pages-components/SocialButtons/SocialButtons';
import TariffRowBenefit from 'pages/ProductCard/Tariff/components/TariffRowBenefit';

// Helpers
import { getAppStoreButtonsFromHtml } from 'utils/getAppStoreButtonsFromHtml';

// Styles
import Grid from 'pages-components/Grid';

import { useFetchData } from './hooks/useFetchData';
import styles from './styles.pcss';
import Info from '../Tiles/Info';

const cx = classNames.bind(styles);

const InfoPopup = ({ children, fetchUrl, onClose, className, isLayoutYoung }) => {
  const data = useFetchData(fetchUrl);
  const [showMore, updateShowMore] = useState(false);
  const popupImage = data?.popupImage;
  const content = data?.content || {};
  const guide = content?.guide || [];

  // Код применяется для модальных окон которые приходят из DPC, функция помогает отрисовать кнопки app store
  const popupButtons =
    data?.description && (getAppStoreButtonsFromHtml(data.description) || {}).appButtons;
  const appButtons = data?.isShowAppButtons && (data?.appButtons || popupButtons);
  const services = data?.services || [];
  let contentData = {};

  if (data && data.content) {
    contentData = pick(['buttonText', 'description', 'heading'], content);
  }

  return (
    <PopupStateless
      className={cx('infoPopup', popupImage && 'withImage', !!className && className)}
      onClose={onClose}
      opened
    >
      {popupImage && (
        <div className={cx('popupImage')}>
          <Picture alt="popup-image" src={popupImage} />
        </div>
      )}

      {!data ?
        <Preloader />
      : <PlainContainer {...data} {...contentData} className={className}>
          {children}

          {services.length > 1 && (
            <Grid.ProductParameters className={cx('productParameters')}>
              {services.map((service, i) => (
                <Info
                  absolutePrice={service.price}
                  icon={service.icon}
                  isLayoutYoung={isLayoutYoung}
                  key={i}
                  toolTip={service.toolTip}
                  value={service.name}
                />
              ))}
            </Grid.ProductParameters>
          )}
          {guide.steps && guide.steps.length > 0 && (
            <>
              <Heading className={className} isStyled={false} level={2}>
                {guide.title}
              </Heading>
              <ul className={cx('benefits')}>
                {guide.steps.map((guideItem, i) => (
                  <li className={cx('benefitItem')} key={i}>
                    <TariffRowBenefit {...guideItem} benefitText={i + 1} />
                  </li>
                ))}
              </ul>
            </>
          )}

          {/* Кнопки приложений и предложения */}
          {appButtons && (
            <div className={cx('buttonsGroup')}>
              <SocialButtons data={appButtons} />
            </div>
          )}

          {(data.buttonText || contentData.buttonText) && (
            <Button
              className="big"
              href={data.buttonLink}
              onClick={data.buttonLink || onClose}
              transformer
            >
              {data.buttonText || contentData.buttonText}
            </Button>
          )}

          {data.moreButtonText && data.descriptions && (
            <>
              <div className={cx('moreButton', { showMore })}>
                <Link
                  className={cx('moreButtonLink')}
                  onClick={() => {
                    updateShowMore(!showMore);
                  }}
                >
                  {data.moreButtonText}
                </Link>
                <Icon className={cx('moreButtonIcon')} name="dropdownArrow" />
              </div>
              {showMore && <Description className={cx('description')} items={data.descriptions} />}
            </>
          )}
          {data.copyright && (
            <div className={cx('copyright')} dangerouslySetInnerHTML={{ __html: data.copyright }} />
          )}
        </PlainContainer>
      }
    </PopupStateless>
  );
};

InfoPopup.defaultProps = {
  className: '',
};

InfoPopup.propTypes = {
  children: PropTypes.object,
  fetchUrl: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func,
  isLayoutYoung: PropTypes.bool,
};

export default InfoPopup;
