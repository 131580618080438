import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { AnimatePresence } from 'framer-motion';

import { AlertTile } from './components/AlertTile';
import { usePrepareAlerts } from './hooks/usePrepareAlerts';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Контейнер подсказок ЕК */
export const AlertContainer = memo(() => {
  const { alerts, isLoading } = usePrepareAlerts();

  if (!alerts?.length) {
    return null;
  }

  return !isLoading ?
      <div className={cx('container')}>
        <AnimatePresence>
          {alerts?.map(({ text, popupData }) => (
            <AlertTile key={text} popupData={popupData} text={text} />
          ))}
        </AnimatePresence>
      </div>
    : null;
});
