import { useStore } from 'react-redux';
import { useEffect } from 'react';
// @ts-expect-error ошибка импорта модуля;
import { equals } from 'ramda';

import { throttle } from 'utils/timed-functions';

import { TOptionsSlice } from '../slices/optionsSlice/types';
import { TStepperSlice } from '../slices/stepperSlice/types';
import { TTotalSlice } from '../slices/totalSlice/types';
import { TFttbSlice } from '../slices/fttbSlice/types';
import { TFttbAddressSlice } from '../slices/fttbAddressSlice/types';

const PERSIST_KEY = 'constructor-state';
const PERSIST_INTERVAL = 1500;

/** Тип персистивных данных верификации */
export type TVerificationData = {
  activeCTN?: string;
  baseUrl: string;
  isElkSettings: boolean;
  regionId: number;
};

/** Тип персистивных данных части стора */
export type TPersistData =
  | {
      fttbAddress: TFttbAddressSlice;
      fttbPresets: TFttbSlice;
      options: TOptionsSlice;
      stepper: TStepperSlice;
      total: TTotalSlice;
      verificationData: TVerificationData;
    }
  | undefined;

/** Получение части стейта из sessionStorage с типизацией */
export function loadPersistState(): TPersistData {
  try {
    const serializedState = sessionStorage.getItem(PERSIST_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

/** Сохранение части стейта в sessionStorage */
export function saveState(state: TPersistData) {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(PERSIST_KEY, serializedState);
  } catch {
    // ignore write errors
  }
}

/** Очистка стейта в sessionStorage */
export function clearState() {
  try {
    sessionStorage.removeItem(PERSIST_KEY);
  } catch {
    // ignore write errors
  }
}

/* Функция проверки валидности сохраненного кэша */
export function checkValidPersistState(
  verificationData: TVerificationData,
  constructorPersistState: TPersistData,
) {
  const persistVerificationData = constructorPersistState?.verificationData;

  return equals(verificationData, persistVerificationData);
}

/* Хук с подпиской на изменение стора и сохранением важных частей в sessionStorage */
export const usePersistor = () => {
  const store = useStore();
  useEffect(() => {
    const unsubscribe = store.subscribe(
      throttle(
        () => {
          /* Если проверка данных из кеша закончена, сохраняем данные в sessionStorage */
          if (store.getState().common.isCompleteOfCheckCache) {
            const { isAuthenticated = false, activeCTN = '' } = store.getState().common.userInfo;

            /* Готовим объект верификации для подгрузки кеша */
            const verificationDataItems: TVerificationData = {
              baseUrl: store.getState().common.baseUrl,
              regionId: store.getState().common.userRegion.regionId,
              isElkSettings: store.getState().common.isElkSettings,
            };

            /* Если пользователь авторизован (АЗ), добавляем в объект верификации его активный номер */
            if (isAuthenticated) {
              verificationDataItems.activeCTN = activeCTN;
            }

            saveState({
              total: store.getState().total,
              stepper: store.getState().stepper,
              options: store.getState().options,
              fttbPresets: store.getState().fttbPresets,
              fttbAddress: store.getState().fttbAddress,
              verificationData: verificationDataItems,
            });
          }
        },
        PERSIST_INTERVAL,
        {},
      ),
    );
    return () => unsubscribe();
  }, [store]);
};
