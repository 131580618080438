import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { OptionsItem, TOptionsItem } from '../OptionsItem';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TOptionsList = {
  optionsList: TOptionsItem[];
};

/** Список подключаемых/отключаемых опций и пресетов тарифа */
export const OptionsList = memo<TOptionsList>(({ optionsList }) => (
  <ul className={cx('wrapper')}>
    {optionsList?.map((option) => <OptionsItem key={option.name} {...option} />)}
  </ul>
));
