import { createContext } from 'react';

type TProductContextState = Partial<{
  chosenTab: number | string;
  defaultTabLabel: string;
  isVisiblePanel: boolean;
  partnerSubscriptionsTitle: string;
  subscriptions: Record<string, unknown>[];
  tabsContent: unknown[];
}>;

export const ProductsContext = createContext<TProductContextState>({});
