import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { startsWith } from 'ramda';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Station = ({ name, active, text, selectedStation, inputActive, lineActive }) => {
  const [visible, setVisibility] = useState(lineActive);

  const verifiedInput = startsWith(selectedStation, name.toLowerCase());

  const handleForm = () => {
    setVisibility(inputActive ? verifiedInput : lineActive);
  };

  useEffect(handleForm, [selectedStation, inputActive]);

  return (
    <>
      {visible && (
        <div className={cx('station')}>
          <span className={cx('icon', active ? 'active' : 'inactive')} />
          <div className={cx('content')}>
            <span className={cx('name')} dangerouslySetInnerHTML={{ __html: name }} />
            {text && (
              <span
                className={cx('text', text?.type)}
                dangerouslySetInnerHTML={{ __html: text?.content }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

Station.propTypes = {
  name: PropTypes.string,
  text: PropTypes.shape({
    type: PropTypes.string,
    content: PropTypes.string,
  }),
  selectedStation: PropTypes.string,
  inputActive: PropTypes.bool,
  lineActive: PropTypes.bool,
  active: PropTypes.bool,
};

export default Station;
