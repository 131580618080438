import { composeStoreInitializer, withReduxStore } from '@beef/redux';
import { AnyAction, ThunkDispatch, combineReducers } from '@reduxjs/toolkit';

import { IPartnerServiceCardState, TModal } from '../types';
import {
  onFAQClickAnalyticsThunk,
  onSubscriptionButtonClickThunk,
  onSubscriptionCardClickThunk,
} from './actions/analytics';
import { onMountThunk } from './actions/meta';
import { modalActionThunk } from './actions/modal';
import { analyticsReducer, dataReducer, metaReducer } from './reducers';
import {
  selectBenefitsData,
  selectExtendedBlockData,
  selectFAQContainerData,
  selectHeadBlockData,
  selectIsYandexMulti,
  selectMediaBlockData,
  selectPopupProps,
  selectSubscriptionsData,
  selectYandexBenefitsData,
} from './selectors';
import { modalSlice } from './slices';

// React-Redux
export const mapStateToProps = (state: IPartnerServiceCardState) => {
  const { data, modal } = state;
  const map = {
    benefitsData: selectBenefitsData(data),
    yandexBenefitsData: selectYandexBenefitsData(data),
    mediaBlockData: selectMediaBlockData(data),
    subscriptionsBlockData: selectSubscriptionsData(data),
    extendedBlockData: selectExtendedBlockData(data, () => {}),
    faqBlockData: selectFAQContainerData(data),
    popupProps: selectPopupProps(data, modal),
  };
  // TODO: Selectors
  return {
    ...state,
    ...map,
    isConnected: data.isConnected,
    hasBenefits: map.benefitsData.benefits.length > 0,
    hasYandexBenefits: selectIsYandexMulti(data),
    hasMedia: map.mediaBlockData.length > 0,
    hasSubscriptions: map.subscriptionsBlockData.subscriptions.length > 0,
    hasExtendedData: map.extendedBlockData.content.length > 0,
    hasFaq: map.faqBlockData.hasData,
  };
};

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<IPartnerServiceCardState, {}, AnyAction>,
) => ({
  onClick: (modalType: TModal) => {
    dispatch(modalSlice.actions.openModal(modalType));
  },
  closeModal: () => dispatch(modalSlice.actions.closeModal()),
  openModal: (modalType: TModal) => dispatch(modalSlice.actions.openModal(modalType)),
  confirmModal: () => {
    dispatch(modalActionThunk());
  },
  onMount: () => dispatch(onMountThunk()),
  onModalButtonClick: () => dispatch(onSubscriptionButtonClickThunk()),
  onFaqClick: (id: string) => dispatch(onFAQClickAnalyticsThunk(id)),
  onSubscriptionClick: () => dispatch(onSubscriptionCardClickThunk()),
});

export const mergeProps = (
  mapProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: IPartnerServiceCardState,
) => ({
  ...mapProps,
  ...dispatchProps,
  ...ownProps,
  headBlockData: selectHeadBlockData(mapProps.data, dispatchProps.onModalButtonClick),
  extendedBlockData: selectExtendedBlockData(mapProps.data, dispatchProps.onModalButtonClick),
  faqBlockData: selectFAQContainerData(mapProps.data, dispatchProps.onFaqClick),
  subscriptionsBlockData: selectSubscriptionsData(mapProps.data, dispatchProps.onSubscriptionClick),
});

export type PartnerServiceCardMergeState = ReturnType<typeof mergeProps>;

export const store = withReduxStore(
  composeStoreInitializer(
    combineReducers({
      modal: modalSlice.reducer,
      analytics: analyticsReducer,
      meta: metaReducer,
      data: dataReducer,
    }),
  ),
);
