import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

export type TNullable<T> = T | null;

export type TModal = EModalType;

export type TLayoutType = 'alt' | 'default' | 'multi';

export type TBenefit = {
  description: TNullable<string>;
  icon: TNullable<string>;
  legal: TNullable<string>;
  popupContent: TNullable<string>;
  text: TNullable<string>;
  title: TNullable<string>;
};

export type TPartnerServiceCardDataSubstate = {
  alias: TNullable<string>;
  benefits: TBenefit[];
  bgImageDesktop: TNullable<string>;
  bgImageMobile: TNullable<string>;
  connectButton: TNullable<string>;
  connectedText?: TNullable<string>;
  descriptions: {
    content: {
      label: TNullable<string>;
      legal: TNullable<string>;
      unit: TNullable<string>;
      value: TNullable<string>;
    }[];
    title: TNullable<string>;
  }[];
  extData: {
    content: {
      [K in TModal]: {
        buttonText: TNullable<string>;
        cancelButtonText?: TNullable<string>;
        description: TNullable<string>;
        descriptionTrial?: TNullable<string>;
        icon?: TNullable<string>;
        title: TNullable<string>;
      };
    };
    partnerContent: {
      benefits: {
        title: string;
      };
      endpoint: {
        [key: string]: string;
      };
      heading: {
        button: {
          activate: TNullable<string>;
          connect: TNullable<string>;
          disconnect: TNullable<string>;
        };
      };
      media: {
        [key: string]: {
          background?: TNullable<string>;
          items: {
            icons: string[];
            id: number;
          }[];
          logo?: TNullable<string>;
          subtitle: string;
          title: string;
        }[];
      };
      stepsToConnect: {
        image: string;
        items: {
          description: string;
          title: string;
        }[];
        title: string;
      };
      subscriptions: {
        buttonText: string;
        title: string;
      };
      yandexMultiBenefits: {
        items: [];
        title: string;
      };
    };
  };
  fallbackImage?: TNullable<string>;
  id: TNullable<string>;
  isAuthenticated: boolean;
  isConnected: boolean;
  link: TNullable<string>;
  otherPartnerSubscriptions: {
    bgImageDesktop: string;
    bgImageMobile: string;
    description: TNullable<string>;
    feeParams: {
      key: TNullable<string>;
      label: TNullable<string>;
      legal: TNullable<string>;
      unit: TNullable<string>;
      value: TNullable<string>;
    }[];
    id: number;
    isConnected: boolean;
    link: string;
    partnerName: TNullable<string>;
    subscriptionStatusText: string;
  }[];
  pageSubtitle: TNullable<string>;
  pageTitle: TNullable<string>;
  partnerProduct?: {
    categoryName: TNullable<string>;
    checked: boolean;
    description: TNullable<string>;
    hasTrial: boolean;
    id: TNullable<number>;
    imageUrl: TNullable<string>;
    isSubscriptionStatus: boolean;
    longRcRate: TNullable<number>;
    name: TNullable<string>;
    partnerDetails: TNullable<string>;
    paymentPeriod: TNullable<string>;
    paymentPeriodTrial?: TNullable<string>;
    periodAlias: TNullable<string>;
    price: TNullable<number>;
    subscriptionStatusText: TNullable<string>;
    trialPeriodWithUnit: TNullable<string>;
    trialPrice: TNullable<number>;
    trialPriceWithUnit: TNullable<string>;
    yandexAssigned: TNullable<boolean>;
  };
  partnerTechName?: TNullable<string>;
  productParameters: {
    key: TNullable<string>;
    label: TNullable<string>;
    legal?: TNullable<string>;
    template?: TNullable<string>;
    unit: TNullable<string>;
    value: TNullable<string>;
  }[];
  soc: TNullable<string>;
  subscriptionStatusText: TNullable<string>;
  techName?: TNullable<string>;
  yandexMultiBenefits: {
    items: TBenefit[];
    title: TNullable<string>;
  };
};

export type OptionsType = {
  trial: {
    count: TNullable<number>;
    expireDate: TNullable<string>;
    price: TNullable<number>;
    units: TNullable<string>;
  };
};

export interface IPartnerServiceCardState {
  analytics: {
    faqClickState: Record<string, unknown>;
  };
  data: TPartnerServiceCardDataSubstate;
  meta: {
    hasMounted: boolean;
  };
  modal: {
    isLoading: boolean;
    isModalConfirmed: boolean;
    isModalOpen: boolean;
    modalCancelText: string;
    modalType: string;
    url: string;
  };
}

export type TModalHandler = (
  state: IPartnerServiceCardState,
  dispatch: ThunkDispatch<IPartnerServiceCardState, unknown, AnyAction>,
) => Promise<void>;

export type TYandexFinishActivationResponse = Partial<{
  code: string;
  data: Partial<{ success: boolean }>;
  errorDesc: unknown;
  isSucceeded: boolean;
}>;

export enum ESubscriptionStatus {
  Connect = 'connect',
  Disconnect = 'disconnect',
  Activate = 'activate',
}

export enum EAnalyticsActionType {
  Connect = 'to_connect',
  Disconnect = 'to_disconnect',
  Activate = 'to_activate',
  Unknown = 'unknow',
}

export enum EResponseCode {
  Success = 'RULE_CODE_SUCCESS',
  BalanceError = 'RULE_CODE_BALANCE',
  Region = 'RULE_CODE_REGION',
  Restriction = 'RULE_CODE_SOC',
  Account = 'RULE_CODE_ACCOUNT',
  Duplicate = 'RULE_CODE_DUPLICATE',
  Default = 'DEFAULT',
  UserNotFound = 'USER_NOT_FOUND',
}

export enum EModalType {
  Success = 'requestSuccess',
  Preconnect = 'preConnect',
  Predisconnect = 'preDisconnect',
  Preactivate = 'preActivateYandexSubscription',
  ActivationSuccessExtra = 'activatedYandexSubscriptionWithAnotnerSubscription',
  ActivationSuccess = 'activatedYandexSubscription',
  RequestSuccessDisconnect = 'requestSuccessDisconnect',
  NoBalanceAccountError = 'notBalanceAccountError',
  NoUserRegionError = 'notUserRegionError',
  ForbiddenError = 'forbiddenError',
  AccountError = 'accountError',
  DuplicateAccountError = 'duplicateAccountError',
  DefaultError = 'defaultError',
  ActivationError = 'activationError',
  ActivationDifferentNumberError = 'activateYandexSubscriptionDifferentNumber',
  UserNotFoundError = 'userNotFoundError',
}

export enum EThunkError {
  NoId = 'Subscription does not have id',
  NoModalType = 'No modal type was given',
  NoOAuthEndpoint = 'No OAuth endpoint was found',
  NoPartnerUrl = 'No partner url was found',
}
