import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type DescriptionProps = {
  data: {
    description: string;
    title: string;
  };
};

export const Description = memo<DescriptionProps>(({ data: { title, description } }) => (
  <section className={cx('wrapper')}>
    <Text size="size2-m">{title}</Text>
    <Text color="grey60" size="size6-r">
      {description}
    </Text>
  </section>
));
