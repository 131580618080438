import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { PageFixer } from '@beef/smart-kit';
import { Text } from '@beef/ui-kit';

import BannerDeprecated from 'pages-components/BannerDeprecated';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { useOnScreenSendAnalytics } from 'pages/MnpOrderNew/hooks';

import { Form } from '../Form';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Header = ({ id, isMnpService, data: { backgroundData, title } }) => {
  const isMobile = useIsMobileDimension();
  const nodeYMRef = useOnScreenSendAnalytics(title);

  return (
    <div className={cx('header')} ref={nodeYMRef}>
      {isMobile && (
        <div
          className={cx('bg-image-mobile')}
          style={{
            backgroundImage: `url('${backgroundData.imageMobile}')`,
          }}
        />
      )}
      <PageFixer>
        <BannerDeprecated
          className={cx('header__content-container')}
          data={isMobile ? {} : backgroundData}
        >
          <div className={cx('header__content')}>
            {title && (
              <div className={cx('header__title')}>
                <Text size="size0-b" tagType="h1">
                  {title}
                </Text>
              </div>
            )}
            <div className={cx('form')}>
              <Form isMnpService={isMnpService} tariffId={id} title={title} />
            </div>
          </div>
        </BannerDeprecated>
      </PageFixer>
    </div>
  );
};

Header.propTypes = {
  id: PropTypes.number,
  data: PropTypes.shape({
    backgroundData: PropTypes.shape({
      imageMobile: PropTypes.string,
      imageDesktop: PropTypes.string,
    }),
    buttonText: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
  }),
};
