import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { motion } from 'framer-motion';

import { ESkeletonFloatingTotalTypes } from 'constructorV1/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TSkeletonFloatingTotal = {
  type: ESkeletonFloatingTotalTypes;
};

/** Кастомный скелетон плавающего блока ЕК */
export const SkeletonFloatingTotal = memo<TSkeletonFloatingTotal>(({ type }) => (
  <motion.div className={cx('wrapper', `wrapper__${type}`)} layout />
));
