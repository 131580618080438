import { ChangeEvent, useCallback, useState } from 'react';

import { isFilledPhone } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/utils/isFilledPhone';
import {
  useAppDispatch,
  useAppSelector,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';
import { setUserPhoneNumber } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/totalSlice';
import { getOnlyDigitsPhone } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/utils/getOnlyDigitsPhone';

export const useFmcCheckPhoneInput = () => {
  const dispatch = useAppDispatch();
  const currentPhone = useAppSelector((state) => state.total.userPhoneNumber) || undefined;
  const [value, setValue] = useState(currentPhone);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const phone = e.target.value;
      setValue(phone);
      if (isFilledPhone(phone)) {
        dispatch(setUserPhoneNumber(getOnlyDigitsPhone(phone)));
      } else if (currentPhone) {
        dispatch(setUserPhoneNumber(''));
      }
    },
    [currentPhone],
  );

  return { value, onChange };
};
