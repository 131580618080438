import React from 'react';
import classnames from 'classnames/bind';
import { ActionBar, Button, Modal, Text } from '@beef/ui-kit';
import { connect } from 'react-redux';

import { selectContent } from 'pages/Anniversary/store/selectors';

import styles from './styles.pcss';
import { resetStatus, selectCurrentStatus } from '../../store/status';

const cx = classnames.bind(styles);

const StatusModalLayout = ({ title, image, text, buttonText, onClose, isOpen }) => (
  <Modal
    actionBar={
      <ActionBar size="s">
        <Button onClick={onClose}>{buttonText}</Button>
      </ActionBar>
    }
    isOpen={isOpen}
    onClose={onClose}
    size="s"
  >
    <img alt="" className={cx('image')} src={image} />
    <div className={cx('title')}>
      <Text size="size2-m">{title}</Text>
    </div>
    <Text color="grey60" size="size4-r">
      {text}
    </Text>
  </Modal>
);

const mapStateToProps = (state) => {
  const currentStatus = selectCurrentStatus(state);
  const { status } = selectContent(state);
  return {
    isOpen: !!currentStatus,
    ...(status[currentStatus] || {}),
  };
};

const mapDispatchToProps = {
  onClose: resetStatus,
};

export const StatusModal = connect(mapStateToProps, mapDispatchToProps)(StatusModalLayout);
