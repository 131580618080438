import React, { FC } from 'react';
import { PageRow, TeaserBanner as SmartTeaserBanner, TeaserBannerPropsType } from '@beef/smart-kit';
import classNames from 'classnames/bind';
import { withRenderFlag } from '@beef/react';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { BreadCrumbItemType } from '@beef/ui-kit';

import { Detail } from 'pages/ActionPage/components/Teaser/Detail';
import { ArchiveBanner } from 'pages/ActionPage/components/Teaser/ArchiveTeaser';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

type BannerPropsType = {
  breadcrumbs: BreadCrumbItemType[];
  isArchive: boolean;
} & TeaserBannerPropsType;

type MapStateToPropsType = {
  shouldRender: boolean;
} & BannerPropsType;

const _Teaser: FC<BannerPropsType> = ({
  breadcrumbs,
  text,
  title,
  isArchive,
  titleColor,
  textColor,
  ...props
}) => (
  <PageRow blockSpacersPreset="m" tagName="section">
    {isArchive ?
      <ArchiveBanner breadcrumbs={breadcrumbs} title={title} />
    : <div className={cn('wrapper')}>
        <SmartTeaserBanner
          {...props}
          detailsNode={<Detail {...{ breadcrumbs, text, title, titleColor, textColor }} />}
          withDivider
        />
      </div>
    }
  </PageRow>
);

// @ts-expect-error state not typed in ts yet
export const Teaser = compose(
  connect(
    ({ content }): MapStateToPropsType => ({
      title: content.title,
      isArchive: content.isArchive,
      ...content.actionBanner,
      breadcrumbs: content.breadcrumbs,
      shouldRender: content.actionBanner || content.isArchive,
    }),
  ),
  withRenderFlag,
)(_Teaser);
