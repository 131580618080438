import { MutableRefObject, useEffect, useRef } from 'react';
import { formatOrderData, handleSuccessRequest } from '@beef/layout-kit/utils';
import { setCartData } from '@carnica/smart-kit';

import { headerStore2024 } from 'store';

import { useAppDispatch } from '../../../../store';
import { clearState } from '../../../../store/persistor';
import { useThrowAsyncError } from '../../../../hooks/useThrowAsyncError';
import { useSelectBasketParams } from '../selectors/useSelectBasketParams';
import { TBasketQueryRes, fetchBasketQuery } from '../../../../api/basketQuery';
import { useIsMnpUserType } from '../../../../hooks/useIsMnpUserType';
import { usePrepareDataLayer } from '../../../../tempAnalytics/hooks/usePrepareDataLayer';

/** Возвращает событие запроса в корзину */
export const useConnectBasket = () => {
  /* Добавили any из-за проблем с типизацией dispatch */
  const fetchBasketPromise: MutableRefObject<any> = useRef();
  const dispatch = useAppDispatch();
  const { params } = useSelectBasketParams();
  const throwAsyncError = useThrowAsyncError();
  const pushDataLayer = usePrepareDataLayer();
  const isMnpUser = useIsMnpUserType();

  /* Отмена запроса к корзине при выходе со страницы финального чека  TotalBill */
  useEffect(
    () => () => {
      fetchBasketPromise.current?.abort();
    },
    [],
  );

  const onBasket = async () => {
    /* Преобразует данные для формата корзины */
    const requestData = formatOrderData(params as keyof typeof formatOrderData);
    fetchBasketPromise.current = dispatch(fetchBasketQuery(requestData));
    fetchBasketPromise.current
      ?.unwrap()
      .then((data: TBasketQueryRes) => {
        if (data.id) {
          handleSuccessRequest({
            cartId: data.id,
            items: data.items,
          });

          if (data) {
            headerStore2024().dispatch(setCartData(data));
          }
        }
      })
      .then(() => {
        /* Перед переходом в корзину очищаем стейт в sessionStorage */
        clearState();
        /* Отправляем dataLayer */
        pushDataLayer(isMnpUser ? 'mnp' : 'common');
        if (typeof window !== 'undefined') {
          window.location.href = '/basket/';
        }
      })
      .catch((e: Error) => throwAsyncError(e));
  };
  return { onBasket };
};
