import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Background from './Background/Background';
import styles from './index.pcss';

const cx = classNames.bind(styles);

const BannerDeprecated = ({
  isFamilyEconomyLanding,
  className,
  children,
  data: {
    imageMobile,
    imageDesktop,
    videoSrc,
    loop,
    textStyle,
    blockSize,
    overlay,
    mobileOnly,
    desktopOnly,
    stretchVideo,
  },
}) => (
  <div
    className={cx(
      { wrapper: !isFamilyEconomyLanding },
      [blockSize, textStyle],
      { mobileOnly, desktopOnly, stretchVideo },
      className,
    )}
  >
    <div className={cx('background')}>
      {(imageMobile || imageDesktop || videoSrc) && (
        <Background
          imageDesktop={imageDesktop}
          imageMobile={imageMobile}
          loop={loop}
          overlay={overlay}
          stretchVideo={stretchVideo}
          videoSrc={videoSrc}
        />
      )}
    </div>
    {children}
  </div>
);

BannerDeprecated.defaultProps = {
  data: {
    videoSrc: '',
    loop: false,
    textStyle: '',
    blockPosition: '',
    verticalAlign: '',
    blockSize: '',
    overlay: '',
  },
};

BannerDeprecated.propTypes = {
  data: PropTypes.shape({
    blockSize: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    blockPosition: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    buttonText: PropTypes.string,
    clickable: PropTypes.bool,
    description: PropTypes.string,
    descriptionClass: PropTypes.string,
    imageMobile: PropTypes.string,
    imageDesktop: PropTypes.string,
    link: PropTypes.string,
    loop: PropTypes.bool,
    overlay: PropTypes.string,
    textStyle: PropTypes.oneOf(['', 'dark', 'black', 'light']),
    title: PropTypes.string,
    verticalAlign: PropTypes.array,
    videoSrc: PropTypes.string,
    mobileOnly: PropTypes.bool,
    desktopOnly: PropTypes.bool,
  }),
};

export default BannerDeprecated;
