import { getHasBrowserWindowAPI } from 'utils/window';

import { formatDate, translate } from '../../../../utils/format-string';
import { ymPushParams } from '../../../../utils/ym';

export { getDomainLevels, getFullDomain, getRedirectUrl } from 'utils/url';
export const UNITS = {
  1: 'days',
  2: 'weeks',
  3: 'month',
};

const STATUSES_OF_SUBSCRIPTION = {
  1: 'Бесплатно на ',
  2: 'Следующее списание ',
  3: 'Действует до ',
};

export const getStatusText = (
  trial: Partial<{
    count: null | number;
    expireDate: null | string;
    price: null | number;
    units: null | string;
  }>,
  status: number,
  expireDateOfSubscription: string,
) => {
  const currentTime = new Date().getTime();
  let finalTime: null | number = null;

  if (expireDateOfSubscription) {
    finalTime = new Date(expireDateOfSubscription).getTime();
  }

  if (trial?.expireDate === null && (trial?.price === 0 || trial?.price === undefined)) {
    return `${STATUSES_OF_SUBSCRIPTION[1]}${trial.count} ${translate(
      // @ts-expect-error unknown
      UNITS[trial.units || ''],
      trial.count,
    )}`;
  }

  if (trial?.expireDate === null && trial.price && trial.price > 0) {
    // @ts-expect-error unknown
    return `${trial.count} ${translate(UNITS[trial.units || ''], trial.count)} за ${trial.price}`;
  }

  if (expireDateOfSubscription && finalTime && finalTime > currentTime && status === 1) {
    return `${STATUSES_OF_SUBSCRIPTION[2]}${formatDate(new Date(expireDateOfSubscription))}`;
  }

  if (expireDateOfSubscription && finalTime && finalTime > currentTime && status === 0) {
    return `${STATUSES_OF_SUBSCRIPTION[3]}${formatDate(new Date(expireDateOfSubscription))}`;
  }

  return undefined;
};

export const pushPartnerServiceParams = (payload: any) =>
  ymPushParams({
    services_ev: payload,
  });

export const openChat = () => {
  // TODO: Implement
};

export const getButtonVariant = (isDark: boolean, isMobile: boolean, isConnected: boolean) => {
  const disconnectedVariant = isDark || isMobile ? 'tertiary' : 'tertiary-light';
  return isConnected ? disconnectedVariant : 'main';
};

export const splitPaymentPeriod = (paymentPeriod: string) => {
  const split = (paymentPeriod || '').split('/');
  const currency = split[0];
  const period = split[1];
  return {
    currency,
    period,
  };
};

export const getToken = () => {
  try {
    if (!getHasBrowserWindowAPI()) return '';

    const token = /access_token=([^&]+)/.exec(document.location.hash)?.[1];

    return token || '';
  } catch (e) {
    console.error(e);

    return '';
  }
};

export const getHasErrorInHash = () => {
  try {
    if (!getHasBrowserWindowAPI()) return false;

    return document.location.hash.toLowerCase().includes('error');
  } catch (e) {
    console.warn(e);

    return false;
  }
};

export const convertNumberToInt = (num: number) => num.toFixed(0);
