import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StaticPage from './StaticPage';
import { mapData } from './dataMapper';

class StaticPageWrapper extends Component {
  constructor(props) {
    super(props);
    if (props.data.html) {
      props.data.html = props.data.html.replace(/&lt;(\/?)script/g, '<$1script');
    }

    this.calculatedProps = props;
  }

  componentDidMount() {
    const mapResult = mapData(this.props.data);
    if (mapResult.needMapping) {
      this.calculatedProps = { ...this.props, data: mapResult.mapped };
    }
    this.forceUpdate();
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <StaticPage {...this.calculatedProps} />;
  }
}

StaticPageWrapper.propTypes = {
  data: PropTypes.object,
};

export default StaticPageWrapper;
