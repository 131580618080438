import { createSelector } from '@reduxjs/toolkit';

import { selectTotal } from 'constructorV1/store/selectors/selectTotal';

/** Предоставляет общие параметры для корзины */
export const selectBasketProps = createSelector(selectTotal, (total) => {
  return {
    constructorId: total.constructorId,
    optionsSocs: total.optionsSocs,
    id: total.id,
    userPhoneNumber: total.userPhoneNumber,
  };
});
