import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { BasketIcon } from '@beef/ui-kit';

import { getMiscContent } from '../../../../store/selectors/content';
import { getIsChangeNumber } from '../../../../store/selectors/change';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

const PriceLayout = ({
  cardClassName,
  buyIconClassName,
  changeText,
  buyText,
  children,
  isChangeNumber,
}) => (
  <>
    <span className={cx('price')}>{children}</span>
    <span className={cx('icon', buyIconClassName)}>
      {/* TODO: Move fill to css */}
      <BasketIcon fill="#727281" size={20} />
      <span className={cx('button', cardClassName)}>{isChangeNumber ? changeText : buyText}</span>
    </span>
  </>
);

PriceLayout.propTypes = {
  cardClassName: PropTypes.string,
  buyIconClassName: PropTypes.string,
  buyText: PropTypes.string,
  changeText: PropTypes.string,
  children: PropTypes.node,
};

export const Price = connect((state) => ({
  buyText: getMiscContent(state).buy,
  changeText: getMiscContent(state).change,
  isChangeNumber: getIsChangeNumber(state),
}))(PriceLayout);
