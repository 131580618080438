const filterPartnerSubscriptions = (subscriptions, defaultTab, currentTab) => {
  if (defaultTab === currentTab) {
    return subscriptions;
  }
  return subscriptions.filter((subscription) => subscription.categoryName === currentTab);
};

const changeSubscriptionStatus = (subscriptions, id) =>
  subscriptions.map((subscription) => {
    if (subscription.id === id) {
      return { ...subscription, checked: !subscription.checked };
    }
    return subscription;
  });

export const removePaymentPeriodSymbol = (text) => (text[0] === '₽' ? text.substring(1) : text);

const addBtnUrlPropToErrorObj = (errorObject, codeError) => {
  const hasErrorWithRedirect = Object.keys(codeError).some((code) => code === errorObject.code);
  if (hasErrorWithRedirect) {
    return { ...errorObject, btnUrl: codeError[errorObject.code] };
  }
  return errorObject;
};

const getTabsContent = (products, defaultTab) => {
  const tabs = products?.map((product) => product.categoryName) || [];

  const filteredTabs = tabs.reduce(
    (filteredArray, tab) => {
      if (filteredArray.indexOf(tab) === -1) {
        return [...filteredArray, tab];
      }
      return filteredArray;
    },
    [defaultTab],
  );

  return filteredTabs.map((tab, index) => ({
    id: String(index + 1),
    title: tab,
  }));
};

export {
  addBtnUrlPropToErrorObj,
  changeSubscriptionStatus,
  filterPartnerSubscriptions,
  getTabsContent,
};
