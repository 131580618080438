import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';

import { TSkeleton } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Временное решение до обновления карники */
export const TempSkeleton: FC<TSkeleton> = memo(({ className }) => (
  <div className={cx('skeleton', className)} />
));
