import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Provider, connect } from 'react-redux';
import qs from 'query-string';
import { isEmpty, path, pathOr } from 'ramda';
import { Preloader, Tab, TabContent, Tabs, TabsList } from '@beef/legacy-ui-kit';
import { Button, Text } from '@beef/ui-kit';
import { RequestStatus } from '@beef/smart-kit';
import classNames from 'classnames/bind';

import store from 'store';
// Components
import UssdForm from 'pages-components/UssdForm/UssdForm';
import Wizard, { Step } from 'pages-components/Wizard/Wizard';
import { Form } from 'pages/MnpOrderNew/components/Form';
import MnpCartForm from 'pages/MNPOrder/components/MnpCartForm';
import DivorcingModal from 'pages/ProductCard/Tariff/components/DivorcingModal';
import OrderPaymentForm from 'widgets/OrderPaymentForm';
import { LoginForm, SmsLoginForm } from 'widgets/Authorization';
import { toggleOrderPaymentForm } from 'widgets/actions/orderPaymentForm';
import {
  resetCvmAnalyticsTimer,
  sendCvmAnalyticsEvent,
  sendCvmTariffAnalyticsEvent,
  sendSingleDownsellAnalyticsEvent,
  timerCvmAnalyticsEvent,
} from 'utils/analytics/cvmTariffAnalytics';
import { pushPopupInteractionEvent, pushTariffProcessingCloseEvent } from 'utils/ga';
import { getSubscriptionIds } from 'pages/ProductCard/Tariff/utils/index';
import {
  AUTH_YM_ACTIONS,
  AUTH_YM_EVENTS,
  AUTH_YM_PLACE,
  YMAuthEvent,
} from 'utils/analytics/ymCommonEvents';
import { LoginLinkForm } from 'widgets/Authorization/LoginLinkForm';
import { getRegionSearchParams } from 'pages/ProductCard/utils';
import { NonRegistrationPopup } from 'pages/ProductCard/components/TariffConnectPopup/NonRegistrationPopup';

import SimCartForm from './SimCartForm';
import ConnectResultPopup from './ConnectResultPopup/ConnectResultPopup';
import ChangeTariffForm from './ChangeTariffForm/ChangeTariffForm';
import WaitingPopup from '../WaitingPopup/WaitingPopup';
import ChangeTariffResultPopup from './ChangeTariffResultPopup/ChangeTariffResultPopup';
import { ConnectedSebConnectForm } from './SebConnectForm/SebConnectForm';
import SebDisconnect from './SebConnectForm/SebDisconnect';
import ConfirmNumberPopup from '../ConfirmNumberPopup/ConfirmNumberPopup';
import CounterOfferResultPopup from './CounterOfferResultPopup';
import TariffCardBodyPopup from '../../Tariff/components/TariffCardBodyPopup';
import ContructorCardBodyPopup from '../../Tariff/components/ConstructorCardBodyPopup';
import DownsellPopupNew from '../DownsellPopupNew';
import WarningStatus from '../WarningStatus';
import ConstructorPopup from '../../Tariff/components/Popup';
import {
  checkTariffRequestState,
  connectPostpaidTariff,
  onClosePopup,
  onConfirmPopupClose,
  onDownsellPopupClose,
  onResultPopupClose,
  orderSim,
  resetResultPopup,
  resetSmsForm,
  sendLogin,
  sendPhone,
  setChangeResultPopup,
  showWrongRegionPopup,
  toggleChangeResultPopup,
} from '../../actions/tariffConnect';
import { replaceDownsellTariff } from '../../actions/downsellTariff/replaceDownsellTariff';
import { toggleConfirmPopup } from '../../actions/confirmNumber';
import { changeTariff } from '../../actions/tariffConnect/changeTariff';
import { toggleTariffConnectionPopup } from '../../actions/tariffConnect/toggleTariffConnectionPopup';
import { toggleConnectPopup } from '../../actions/tariffConnect/tariffConnectExtension';
import { toggleConstructor } from '../../actions/tariffConstructor';
import DownsellNewHOC from '../../hoc/DownsellNewHOC';
import DownsellTariffCardBodyPopupHOC from '../../hoc/DownsellTariffCardBodyPopupHOC';
import styles from './TariffConnectPopup.pcss';

const cx = classNames.bind(styles);

class TariffConnectPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: null,
      ignoreDivorcing: false,
      esimData: null,
      isShowNonRegPopup: false,
    };
  }

  componentDidMount() {
    const { previousRegion, currentRegion, needOpenPopup } = getRegionSearchParams();
    if (previousRegion && previousRegion !== currentRegion && needOpenPopup) {
      this.props.showWrongRegionPopup?.(previousRegion);
    }
    const ignoreDivorcing = qs.parse(window.location.search).ignoreDivorcing === 'true';
    this.setState({ ignoreDivorcing });
  }

  componentDidUpdate(prevProps) {
    const { popupIsActive, data } = this.props;
    const { esimData } = this.state;

    if (!popupIsActive && prevProps.popupIsActive) {
      this.setState({ activeTab: null });
      return;
    }

    if (!esimData && !prevProps.data && data && data.esimPopupUrl) {
      this.fetchEsimDescription();
    }

    if (data && !prevProps.data && popupIsActive) {
      pushPopupInteractionEvent({ itemTitle: data?.tariffName, itemSoc: data?.soc });
    }

    if (data && popupIsActive && !prevProps.popupIsActive) {
      pushPopupInteractionEvent({ itemTitle: data?.tariffName, itemSoc: data?.soc });
    }
  }

  /** Функция необходима для CVM аналитики */
  static getDerivedStateFromProps(nextProps, prevState) {
    const { data, popupIsActive } = nextProps;
    const { activeTab } = prevState;
    const isConnectTab = activeTab === 'SebConnectForm' || activeTab === 'ChangeTariffForm';

    // Осознаный костыль для CVM Analytics. Нужен рефакторинг кода ---------------------
    if (popupIsActive && (data || {}).adsButtons && isConnectTab) {
      const dataForAnalytics = {
        subgroupId: data.adsButtons.subgroupId,
        campId: data.adsButtons.campId,
      };

      timerCvmAnalyticsEvent([dataForAnalytics], 'landViewed');
      sendSingleDownsellAnalyticsEvent([dataForAnalytics], 'openChannel');
    } else if ((data || {}).offerType) {
      // Если абонент пришел по клику на upsale тариф из каталога, таймаут не чистим;
      resetCvmAnalyticsTimer();
    }
    return null;
    // -----------------------------------
  }

  onTabClick = (tabName) => this.setState({ activeTab: tabName });

  fetchEsimDescription = () => {
    axios.get(this.props.data.esimPopupUrl).then((res) => {
      if (res.data) {
        this.setState({
          esimData: {
            descriptionList: res.data.descriptionList,
            qrCodeUrl: res.data.qrCodeUrl,
            appButtons: res.data.appButtons,
          },
        });
      }
    });
  };

  /**
   * Возвращает текущий статус метода "gettariffconnectionstatus".
   * @returns {*|(function(*): (*))}
   */
  getRequestStatus = () => {
    const state = store.getState();
    return path(['external', 'tariffConnectionPopup', 'resultPopupData', 'requestStatus'], state);
  };

  getActiveStep = () => {
    let step = 'pending';

    const {
      tariffChangeResultPopup,
      downsellTariffCardModal,
      orderPaymentForm,
      resultPopupData,
      confirmOpened,
      waitingPopup,
      isPending,
      divorcing,
      errorPopupData,
      data,
    } = this.props;

    const result = resultPopupData && Object.keys(resultPopupData).length > 0;

    if (isPending) {
      return step;
    }
    if ((data && !isEmpty(data)) || (errorPopupData && !isEmpty(errorPopupData))) {
      step = 'main';
    }

    if (data?.isConvergence) {
      step = 'resultConvergence';
    }

    if (confirmOpened) {
      step = 'confirm';
    } else if (data?.type === 'actionOk' || (result && step !== 'resultConvergence')) {
      step = 'result';
    } else if (data && data.type) {
      step = 'downsellNew';
    } else if (tariffChangeResultPopup && tariffChangeResultPopup.opened) {
      step = 'changeResult';
    }

    if (divorcing) {
      step = 'divorcing';
    }

    if ((waitingPopup.opened || waitingPopup.data?.step === 'pending') && !result) {
      step = 'waiting';
    }

    if (downsellTariffCardModal && this.props.step === 'downsellTariffCardModal') {
      step = 'downsellTariffCardModal';
    }

    if (downsellTariffCardModal && this.props.step === 'constructorAdsTariffCardModal') {
      step = 'constructorAdsTariffCardModal';
    }

    if (orderPaymentForm.show) {
      step = 'orderPaymentForm';
    }
    return step;
  };

  getActiveTab = (tabs = []) => {
    const { popupIsActive, activeTabName } = this.props;
    const { activeTab } = this.state;
    const tabTypes = tabs.map((item) => item.type);

    if (!popupIsActive) return activeTab;

    if (activeTabName) {
      return activeTabName;
    }

    const paramActiveTab = qs.parse(window.location.search).activeTab;

    if (paramActiveTab) {
      if (tabTypes.includes(paramActiveTab)) return paramActiveTab;
      if (paramActiveTab === 'submit') {
        if (tabTypes.includes('ChangeTariffForm')) return 'ChangeTariffForm';
        if (tabTypes.includes('SebConnectForm')) return 'SebConnectForm';
      }
    }

    if (tabTypes.length && !tabTypes.includes(activeTab)) {
      this.setState({
        activeTab: tabTypes[0],
      });

      return tabTypes[0];
    }

    return activeTab;
  };

  openServicesUrl = () => {
    window.location.href = pathOr('', ['waitingPopup', 'servicesUrl'], this.props.data);
  };

  changeTariff = () => {
    const { changeTariffOfferLanding, data } = this.props;

    const subscriptions = this.props?.constructorParams?.subscriptions || [];
    const partnerIds = getSubscriptionIds(subscriptions);

    if (changeTariffOfferLanding) {
      // Нужно также передавать информацию о том, какие подписки были подключены
      changeTariffOfferLanding(data.tariffName, data.soc, partnerIds);
    } else {
      this.tariffModalActions('activation', partnerIds);
    }
  };

  sendYmOnCloseEvent = () => {
    const isSmsConfirmationTab = this.getActiveTab() === 'SmsConfirmationForm';
    if (!this.props.resultPopupData) {
      if (isSmsConfirmationTab) {
        YMAuthEvent.close(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS, AUTH_YM_ACTIONS.STEP_INPUT_CODE_CLOSE);
      }
      return;
    }
    const {
      resultPopupData: { isWrongRegionError, step },
    } = this.props;
    if (step === AUTH_YM_PLACE.step.submitLogin && isWrongRegionError) {
      if (!isSmsConfirmationTab) {
        YMAuthEvent.close(
          AUTH_YM_EVENTS.CHANGE_TARIFF_PASSWORD,
          AUTH_YM_ACTIONS.CLOSE_WRONG_REGION,
        );
      } else {
        YMAuthEvent.close(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS, AUTH_YM_ACTIONS.CLOSE_WRONG_REGION);
      }
    }
  };

  sendCVMAnalyticsEvent = (state) => {
    const { place } = this.props;
    const data = this.props.data || {};
    const { changeTariffForm: campIds = {}, offerType } = data;
    const requestStatus = pathOr(null, ['resultPopupData', 'requestStatus'], this.props);
    let renderUrlMatch = null;

    // Проверяем откуда пришел пользователь
    if (typeof window !== 'undefined') {
      renderUrlMatch = window.location.href.match('detailsforlk');
    }

    const subgroupId = (campIds || {}).subgroupId || data.subgroupId;
    const campId = (campIds || {}).campId || data.campId;

    if (subgroupId && campId) {
      const arrayIds = [{ subgroupId, campId }];
      const ANALYTICS_STATE = 'activation';

      // Абонент пришел из ЛК
      if (renderUrlMatch) {
        sendCvmAnalyticsEvent(arrayIds, ANALYTICS_STATE, 'offers');
      } else if (!offerType) {
        // Абонент пришел из каталога тарифав по клику на upsale тариф;
        sendCvmAnalyticsEvent(arrayIds, ANALYTICS_STATE, 'catalog');
      } else if (place === 'downsell' && requestStatus !== 'COMPLETE') {
        sendCvmAnalyticsEvent(arrayIds, state, 'downsale');
      } else {
        sendCvmTariffAnalyticsEvent([{ subgroupId, campId, state }]);
      }
    }
  };

  /**
   * НАДО ПЕРЕПИСАТЬ ЭТУ ФУНКЦИЮ, ВСЕ ЭТО ВЫНЕСТИ В HOComponents!!!
   * Функция следит за подтверждением перехода на рекомендованый тариф (согласие с конфликтами),
   * если предложение принято, записываем activation: true, в последующем это состояние
   * будет учитываться при закрытии модального окна, событие close не отправляется если
   * предложение принято и модальное окно закрыто;
   * Функция следит за закрытием модального окна;
   * ----------------------------------------------
   * @params state - Тип события модального окна;
   * @params place - Указатель на место использования функционала для аналитики;
   */
  tariffModalActions = (state, partnerIds = []) => {
    const { place } = this.props;
    const data = this.props.data || {};
    const { tariffName, soc, nonRegPopupData } = data;

    /* Показываем попап с необходимостью заполнить данные регистрации в офисе */
    if (nonRegPopupData) {
      this.setState({
        isShowNonRegPopup: true,
      });
      return;
    }

    const arrayIds = [
      {
        subgroupId: (data.adsButtons || {}).subgroupId,
        campId: (data.adsButtons || {}).campId,
      },
    ];

    // CVM аналитика
    const isDownsellOffer = ['callBack', 'question'].includes(data.type);
    if (!isDownsellOffer) this.sendCVMAnalyticsEvent(state);
    // -------------

    // Downsale place CVM Analytics
    if (data && data.adsButtons) {
      sendCvmAnalyticsEvent(arrayIds, 'refuse', 'downsale');
    }
    // ----------------------------

    if (state === 'activation') {
      this.props.onTariffChange(tariffName, soc, partnerIds);
    }

    if (state === 'close') {
      if (place === 'downsell' && data.buttonUrl && !isDownsellOffer) {
        window.location.href = data.buttonUrl;
      } else {
        this.props.closePopup();
      }
    }
  };

  downsellClose = () => {
    const { place } = this.props;
    const data = this.props.data || {};

    if (data.type === 'question') this.sendCVMAnalyticsEvent('refuse');

    if (place === 'downsell' && data.buttonUrl) {
      window.location.href = data.buttonUrl;
    } else {
      this.props.onDownsellPopupClose();
    }
  };

  confirmPopupClose = () => {
    const { data, tariffData } = this.props;
    const activeTab = this.getActiveTab();
    const step = this.getActiveStep();
    const subgroupId = ((data && data.adsButtons) || {}).subgroupId || tariffData.subgroupId;
    const campId = ((data && data.adsButtons) || {}).campId || tariffData.campId;
    const arrayIds = [{ subgroupId, campId }];
    const isSmsWithCaptchaForm = this.props.step === AUTH_YM_PLACE.form.smsFormWithCaptcha;
    const isChangeTariffSmsMainForm =
      activeTab === AUTH_YM_PLACE.form.smsConfirmationForm &&
      step === AUTH_YM_PLACE.step.main &&
      !isSmsWithCaptchaForm;

    // CVM Analytics ---------------
    if (data && data.adsButtons) {
      sendCvmAnalyticsEvent(arrayIds, 'activation', 'downsale');
    }

    // YM Analytics
    if (isChangeTariffSmsMainForm) {
      YMAuthEvent.close(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS);
    } else if (isSmsWithCaptchaForm) {
      YMAuthEvent.capchaClose(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS);
    }

    // Если абонент пришел из каталога по клику на upsale тариф;
    if (!tariffData.offerType && subgroupId && campId) {
      sendCvmAnalyticsEvent(arrayIds, 'close', 'catalog');
    }
    // ------------------------------

    this.setState({
      isShowNonRegPopup: false,
    });

    this.props.onConfirmPopupClose();
  };

  onResultBackClick = () => {
    const { onResultBackClick, resultPopupData } = this.props;
    onResultBackClick();
    if (!resultPopupData?.isWrongRegionError) return;
    const activeTab = this.getActiveTab();
    const isChangeTariffSms =
      resultPopupData?.step === AUTH_YM_PLACE.step.submitLogin &&
      activeTab === AUTH_YM_PLACE.form.smsConfirmationForm;
    if (isChangeTariffSms) {
      YMAuthEvent.changeNumberWrongRegion(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS);
    } else {
      YMAuthEvent.changeNumberWrongRegion(AUTH_YM_EVENTS.CHANGE_TARIFF_PASSWORD);
    }
  };

  renderPopup() {
    const {
      data,
      errorPopupData,
      isPending,
      isSubmitting,
      closePopup,
      tabs: clearTabs,
      adsButtons,
      form,
      loginLinkForm,
      smsForm,
      contactPhone,
      submitSmsFormPhone,
      sendSmsAgain,
      error,
      ussdForm,
      newNumber,
      simOrderForm = {},
      changeTariffForm,
      postpaidForm,
      sebConnectForm,
      warningData,
      tabsToDisplay,
      constructorParams,
      tariffData: { id, soc, alias, trafficPackage, mnpWidget, simOrderPlace, layoutType },
      newChosenNumber,
      personalPlanData,
    } = this.props;
    const { isShowNonRegPopup } = this.state;
    const step = this.getActiveStep();

    if (isPending && !data) {
      return (
        <div className={cx('loaderWrapper')}>
          <Preloader className="center" />
        </div>
      );
    }

    if (!data) {
      if (!errorPopupData) {
        return <div />;
      }

      return (
        <div className={cx('wrapper')}>
          <div className={cx('header')}>
            <Text size="size1-m">{errorPopupData?.title}</Text>
          </div>
          <div className={cx('error-content')}>
            <Text size="size5-r-s" tagType="p">
              {errorPopupData?.content}
            </Text>
          </div>
          <div className={cx('btn-wrap')}>
            <Button onClick={closePopup}>{errorPopupData?.buttonText}</Button>
          </div>
        </div>
      );
    }

    let tabs = clearTabs;

    if (tabsToDisplay) {
      tabs = clearTabs.filter((item) => tabsToDisplay.indexOf(item.type) >= 0);
    }

    let activeTab = this.getActiveTab(tabs);

    if (layoutType === 'constructor' || layoutType === 'ztariff') {
      // Необходимо для отображения SebConnectForm в тарифе конструктор. BCP-85 (Подробнее)
      if (this.props.activeTab === 'ChangeTariffForm' && activeTab === 'SebConnectForm') {
        activeTab = 'SebConnectForm';
      } else {
        activeTab = this.props.activeTab;
      }
    }
    const isPasswordChangeTariffForm = activeTab !== 'SmsConfirmationForm' && step !== 'result';

    if (data?.nonRegPopupData && isShowNonRegPopup) {
      return (
        <NonRegistrationPopup {...{ ...data.nonRegPopupData, onClose: this.confirmPopupClose }} />
      );
    }

    if (data?.isTariffUpdating && activeTab === 'ChangeTariffForm') {
      return (
        <ConstructorPopup
          changePresetRcRate={data?.changePresetRcRate}
          connectionFee={data?.connectionFee}
          content={this.props.constructorPopupContent}
          discountOffText={data?.discountOffText}
          notificationText={data.notificationText}
          onClick={this.changeTariff}
          refundsSum={data?.refundsSum}
          refusalRefundsDescription={data?.refusalRefundsDescription}
          showDiscountOffText={data?.showDiscountOffText}
          singlePaymentFee={data?.singlePaymentFee}
          singlePaymentText={data?.singlePaymentText}
          tariffName={data.tariffName}
          totalAmountUnit={data.changeTariffForm.totalAmountUnit}
        />
      );
    }

    return (
      <div className={cx('wrapper')}>
        <div className={cx('header')}>
          <Text size="size1-m">{data.header}</Text>
        </div>
        <Tabs activeTab={activeTab} disabled={isPending} onChange={this.onTabClick}>
          <TabsList
            autoScroll
            className={cx('tabsList', { hidden: tabs.length < 2 })}
            inline
            type="calendar"
          >
            {typeof this.props.activeTab === 'undefined' ?
              tabs.map((tab) => (
                <Tab className={cx('tabItem')} id={tab.type} key={tab.type}>
                  <span className={cx('tabText')} dangerouslySetInnerHTML={{ __html: tab.title }} />
                </Tab>
              ))
            : null}
          </TabsList>
          <TabContent className={cx('form')} id="LoginForm">
            {loginLinkForm ?
              <LoginLinkForm {...loginLinkForm} />
            : <LoginForm
                {...form}
                eventType={isPasswordChangeTariffForm ? AUTH_YM_EVENTS.CHANGE_TARIFF_PASSWORD : ''}
                isSubmitting={isSubmitting}
                onSubmit={this.props.onSubmitLoginForm}
              />
            }
          </TabContent>
          {/* изменить свой тариф */}
          <TabContent className={cx('form')} id="SmsConfirmationForm">
            {warningData && <WarningStatus warningData={warningData} />}
            {!warningData &&
              (smsForm && !isPending ?
                <div>
                  <SmsLoginForm
                    error={error}
                    phone={contactPhone}
                    sendSmsAgain={sendSmsAgain}
                    showWarning={!!warningData}
                    step={this.props.step || 'smsFormPhone'}
                    submitSmsFormPhone={submitSmsFormPhone}
                    warningData={warningData}
                    {...form}
                    {...smsForm}
                  />
                </div>
              : <Preloader />)}
          </TabContent>
          <TabContent id="LoginLinkForm">
            {loginLinkForm && <LoginLinkForm {...loginLinkForm} />}
          </TabContent>
          <TabContent className={cx('form')} id="UssdCommandForm">
            {ussdForm && <UssdForm description={ussdForm.ussdText} ussd={ussdForm.ussd} />}
          </TabContent>
          <TabContent className={cx('form')} id="SimOrderForm">
            <SimCartForm
              alias={alias}
              analyticsData={{
                name: data?.tariffName,
                price:
                  this.props.tariffData?.price?.value || this.props?.constructorPopupContent?.price,
                list: this.props?.cartAnalyticsData?.cartAnalyticsList,
                category: this.props?.cartAnalyticsData?.cartAnalyticsCategory,
                numberCategoryName: this.props.cartAnalyticsData?.analyticsNumberCategoryName,
              }}
              availableInShop
              chosenNumber={newNumber || newChosenNumber}
              constructorParams={constructorParams}
              constructorTariffData={{
                tariffConstructorId: data?.tariffConstructorId,
                optionSocs: data?.optionSocs,
                partnersId: data?.chosenSubscriptions,
              }}
              esimData={this.state.esimData}
              fancyNumberModalChoice={this.props.fancyNumberModalChoice}
              id={id}
              note={simOrderForm?.additionalDescription}
              onNumberChange={this.props.onNumberChange}
              onRootCtnChosenIndexChange={this.props.onRootCtnChosenIndexChange}
              openFancyNumberModal={this.props.openFancyNumberModal}
              personalPlanData={personalPlanData}
              rootCtnChosenIndex={this.props.rootCtnChosenIndex}
              sendAnalytics={this.props.sendAnalytics}
              simOrderPlace={simOrderPlace}
              soc={soc}
              trafficPackage={trafficPackage}
            />
          </TabContent>
          <TabContent className={cx({ form: tabs.length > 1 })} id="ChangeTariffForm">
            {changeTariffForm && !isPending ?
              <ChangeTariffForm
                {...changeTariffForm}
                {...adsButtons}
                onCancelClick={this.confirmPopupClose}
                onClick={this.changeTariff}
              />
            : <Preloader />}
          </TabContent>
          <TabContent className={cx('form')} id="PostpaidForm">
            {postpaidForm && !isPending ?
              <SmsLoginForm
                description={postpaidForm.description}
                error={postpaidForm.error || {}}
                phone={contactPhone}
                sendSmsAgain={sendSmsAgain}
                sendSmsButtonText={postpaidForm.buttonText}
                step="smsFormPhone"
                submitSmsFormPhone={(formData) => this.props.onConnectPostpaid(formData.phone)}
              />
            : <Preloader />}
          </TabContent>
          <TabContent className={cx({ form: tabs.length > 1 })} id="SebConnectForm">
            {sebConnectForm && !isPending ?
              <ConnectedSebConnectForm
                changeTariffForm={changeTariffForm}
                {...sebConnectForm}
                {...adsButtons}
                confirmButtonText={
                  (adsButtons && adsButtons.changeTariffButtonText) ||
                  sebConnectForm.confirmButtonText
                }
                flipButtons={!!adsButtons}
                onCancelClick={this.confirmPopupClose}
                onClick={() => this.tariffModalActions('activation')}
              />
            : <Preloader />}
          </TabContent>
          {/* таб переноса номера в билайн */}
          <TabContent className={cx('form')} id="MNPForm">
            {mnpWidget && (
              <div className={cx('mnp-form-wrap')}>
                {mnpWidget.isMnpRedesignEnabled ?
                  <Form
                    analyticsData={{
                      price:
                        this.props.tariffData?.price?.value ||
                        this.props?.constructorPopupContent?.price,
                      list: this.props?.cartAnalyticsData?.mnpAnalyticsPlace,
                      category: this.props?.cartAnalyticsData?.mnpAnalyticsCategory,
                    }}
                    constructorTariffData={{
                      tariffConstructorId: data?.tariffConstructorId,
                      optionSocs: data?.optionSocs,
                      partnersId: data?.chosenSubscriptions,
                    }}
                    description={mnpWidget?.formData?.description}
                    personalPlanData={personalPlanData}
                    sendAnalytics={this.props.sendAnalytics}
                    tariffId={id}
                    userAgreement={mnpWidget?.popup?.map((item) => ({
                      ...item,
                      tooltip: item.tooltipText,
                    }))}
                  />
                : <MnpCartForm
                    analyticsData={{
                      price:
                        this.props.tariffData?.price?.value ||
                        this.props?.constructorPopupContent?.price,
                      list: this.props?.cartAnalyticsData?.mnpAnalyticsPlace,
                      category: this.props?.cartAnalyticsData?.mnpAnalyticsCategory,
                    }}
                    constructorTariffData={{
                      tariffConstructorId: data?.tariffConstructorId,
                      optionSocs: data?.optionSocs,
                      partnersId: data?.chosenSubscriptions,
                    }}
                    description={mnpWidget?.formData?.description}
                    errors={{
                      isBeelineCtn: mnpWidget?.formData?.isBeelineCtnErrorText,
                    }}
                    id={id}
                    personalPlanData={personalPlanData}
                    sendAnalytics={this.props.sendAnalytics}
                    userAgreement={mnpWidget?.popup?.map((item) => ({
                      ...item,
                      tooltip: item.tooltipText,
                    }))}
                    validationUrl={mnpWidget?.endpoint}
                  />
                }
              </div>
            )}
          </TabContent>
        </Tabs>
        {changeTariffForm?.depositMessage && (
          <span className={cx('depositMessage')}>{changeTariffForm.depositMessage}</span>
        )}
      </div>
    );
  }

  render() {
    const {
      closePopup,
      popupIsActive,
      waitingPopup,
      tariffChangeResultPopup,
      tariffRequestId,
      data,
      onNumberConfirmBackClick,
      onTariffConstructorToggle,
      confirmBackLinkText,
      resultPopupData,
      convergentOrderPopup,
      tariffData,
      includeNumber,
      newNumber,
      convergenceData,
    } = this.props;
    const { ignoreDivorcing } = this.state;
    const { confirmButton, rejectButton, description, imageSrc, title } = convergenceData;

    const form = this.props.form || {};
    const step = this.getActiveStep();
    const activeTab = this.getActiveTab();
    const requestStatus = this.getRequestStatus();
    const isChangeTariffSms =
      resultPopupData?.step === 'submitLogin' && activeTab === 'SmsConfirmationForm';

    const divorcingModalData = {
      method: includeNumber && newNumber ? 'SimNumber' : 'Sim',
      tariffName: tariffData.pageTitle,
      marketCode: tariffData.marketCode,
      soc: tariffData.soc,
    };

    const hideCloseButton =
      ((step === 'downsellNew' || step === 'downsellTariffCardModal') &&
        data &&
        data.type !== 'question' &&
        data.type !== 'callBackOk') ||
      step === 'pending';

    if (step === 'divorcing' && ignoreDivorcing) {
      this.props.toggleTariffConnectionPopup();
    }

    return (
      <div>
        <Wizard
          activeStep={step}
          className={cx('popup')}
          hideCloseButton={hideCloseButton}
          onClose={() => {
            this.tariffModalActions('close');
            this.sendYmOnCloseEvent();
          }}
          opened={popupIsActive}
        >
          <Step name="resultConvergence">
            <div className={cx('fmc')}>
              <RequestStatus
                button={{ variant: 'main', text: confirmButton }}
                buttonReject={rejectButton ? { text: rejectButton } : null}
                description={description}
                img={{ src: imageSrc }}
                onConfirm={() => this.tariffModalActions('close')}
                title={title}
              />
            </div>
          </Step>
          <Step name="counteroffer">
            <CounterOfferResultPopup closePopup={closePopup} {...data} />
          </Step>
          <Step name="divorcing">
            <DivorcingModal
              convergentOrderPopup={convergentOrderPopup}
              dataForGA={divorcingModalData}
              tariffName={divorcingModalData.tariffName}
              toggleTariffConnectionPopup={this.props.toggleTariffConnectionPopup}
            />
          </Step>
          <Step name="main" onClose={this.confirmPopupClose}>
            {this.renderPopup()}
          </Step>
          <Step name="pending" onClose={() => {}}>
            <div style={{ minHeight: '300px' }}>
              {data?.hasFutureSimplifiedTariff ?
                <div className={cx('pending-has-futureRequest')}>
                  <div
                    className={cx('pending-title')}
                    dangerouslySetInnerHTML={{ __html: data.waitingPopup?.title }}
                  />
                  <Preloader className={cx('has-future-request-loader')} />
                  <div
                    className={cx('pending-content')}
                    dangerouslySetInnerHTML={{ __html: data.waitingPopup?.content }}
                  />
                </div>
              : <Preloader className="center" />}
            </div>
          </Step>
          <Step
            backLinkText={confirmBackLinkText}
            name="confirm"
            onBackClick={onNumberConfirmBackClick}
          >
            <ConfirmNumberPopup {...form} sendSmsAgain={onNumberConfirmBackClick} />
          </Step>
          <Step name="downsellNew" onClose={this.downsellClose}>
            <DownsellNewHOC>
              <DownsellPopupNew />
            </DownsellNewHOC>
          </Step>
          <Step
            backLinkText="Назад к предложению"
            name="downsellTariffCardModal"
            onClose={this.props.onDownsellPopupClose}
          >
            <DownsellTariffCardBodyPopupHOC>
              <TariffCardBodyPopup />
            </DownsellTariffCardBodyPopupHOC>
          </Step>
          {/* Шаг для отображения попапа Ads для тарифа Конструктор */}
          <Step backLinkText="Назад к предложению" name="constructorAdsTariffCardModal">
            <DownsellTariffCardBodyPopupHOC>
              <ContructorCardBodyPopup />
            </DownsellTariffCardBodyPopupHOC>
          </Step>
          <Step
            backLinkText={resultPopupData && resultPopupData.backButtonText}
            name="result"
            onBackClick={this.onResultBackClick}
            onClose={() => {
              if (resultPopupData?.buttonUrl && resultPopupData?.type === 'tariffWrongRegion') {
                window.location.href = resultPopupData.buttonUrl;
              } else {
                this.props.onResultPopupClose();
              }
            }}
          >
            <ConnectResultPopup
              closePopup={this.props.onResultPopupClose}
              emailSubscriptionTexts={tariffData.emailSubscriptionTexts}
              eventType={
                isChangeTariffSms ?
                  AUTH_YM_EVENTS.CHANGE_TARIFF_SMS
                : AUTH_YM_EVENTS.CHANGE_TARIFF_PASSWORD
              }
              hideModal={closePopup}
              onTariffConstructorOpen={onTariffConstructorToggle}
              sendAnalytics={this.props.sendAnalytics}
              shown={step === 'result'}
            />
          </Step>
          <Step
            backLinkText={this.props.orderPaymentFormBackButtonText || 'Назад'}
            name="orderPaymentForm"
            onBackClick={this.props.onOrderPaymentBackClick}
          >
            <OrderPaymentForm
              orderPlace={tariffData.simOrderPlace}
              soc={tariffData.soc}
              tariffName={tariffData.pageTitle}
            />
          </Step>
          <Step name="waiting" onClose={this.props.onWaitingClose}>
            <WaitingPopup
              closePopup={this.props.onWaitingClose}
              data={waitingPopup}
              interval={waitingPopup.interval}
              onButtonClick={this.openServicesUrl}
              onCheck={() => this.props.onCheckTariffRequestState(tariffRequestId, false)}
              onResult={this.props.onWaitingResult}
              onSecondaryButtonClick={this.props.onWaitingClose}
              onTimeout={() => this.props.onCheckTariffRequestState(tariffRequestId, true)}
              opened={waitingPopup.opened}
              requestStatus={requestStatus}
              timeout={waitingPopup.timeout}
              type="tariff"
            />
          </Step>
          <Step name="changeResult" onClose={this.props.onChangeResultClose}>
            <ChangeTariffResultPopup {...tariffChangeResultPopup} />
          </Step>
        </Wizard>
        <SebDisconnect />
      </div>
    );
  }
}

TariffConnectPopup.defaultProps = {
  data: null,
  error: {},
  errorPopupData: null,
  form: null,
  isPending: false,
  contactPhone: '+7 ',
  popupIsActive: false,
  tabs: [],
  smsForm: null,
  showSmsForm: false,
  step: 'smsFormPhone',
  activeTabName: null,
};

TariffConnectPopup.propTypes = {
  rootCtnChosenIndex: PropTypes.number,
  onRootCtnChosenIndexChange: PropTypes.func,

  /* выбранный таб */
  activeTabName: PropTypes.string,

  /* Данные для аналитики по новой корзине */
  cartAnalyticsData: PropTypes.shape({
    mnpAnalyticsPlace: PropTypes.string,
    mnpAnalyticsCategory: PropTypes.string,
    cartAnalyticsList: PropTypes.string,
    cartAnalyticsCategory: PropTypes.string,
    analyticsNumberCategoryName: PropTypes.string,
  }),

  activeTab: PropTypes.string,
  closePopup: PropTypes.func.isRequired,
  onTariffConstructorToggle: PropTypes.func,
  data: PropTypes.shape({}),
  error: PropTypes.shape({}),
  errorPopupData: PropTypes.shape({}),
  form: PropTypes.shape({}),
  isPending: PropTypes.bool,
  fancyNumberModalChoice: PropTypes.bool,
  contactPhone: PropTypes.string,
  popupIsActive: PropTypes.bool,
  tabs: PropTypes.array,
  adsButtons: PropTypes.shape({}),
  sendSmsAgain: PropTypes.func.isRequired,
  smsForm: PropTypes.shape({}),
  step: PropTypes.string,
  chosenNumberPrice: PropTypes.number,
  submitSmsFormPhone: PropTypes.func.isRequired,
  ussdForm: PropTypes.shape({}),
  newNumber: PropTypes.string,
  includeNumber: PropTypes.bool,
  onSimOrder: PropTypes.func,
  openFancyNumberModal: PropTypes.func,
  simOrderForm: PropTypes.shape({}),
  changeTariffForm: PropTypes.shape({}),
  depositMessage: PropTypes.string,
  postpaidForm: PropTypes.shape({}),
  waitingPopup: PropTypes.shape({}),
  sebConnectForm: PropTypes.shape({}),
  tariffChangeResultPopup: PropTypes.shape({}),
  tariffRequestId: PropTypes.number,
  onNumberChange: PropTypes.func,
  onConnectPostpaid: PropTypes.func,
  onWaitingClose: PropTypes.func,
  onTariffChange: PropTypes.func,
  onCheckTariffRequestState: PropTypes.func,
  onChangeResultClose: PropTypes.func,
  onDownsellConfirm: PropTypes.func,
  onNumberConfirmClose: PropTypes.func,
  onNumberConfirmBackClick: PropTypes.func,
  onSubmitLoginForm: PropTypes.func,
  confirmOpened: PropTypes.bool,
  confirmBackLinkText: PropTypes.string,
  onResultBackClick: PropTypes.func,
  resultPopupData: PropTypes.shape({}),
  isSubmitting: PropTypes.bool,
  restyledUpSaleABEnabled: PropTypes.bool,
  downSellOfferStep: PropTypes.string,
  tariffData: PropTypes.shape({}),
  changeTariffOfferLanding: PropTypes.func,
  convergentOrderPopup: PropTypes.shape({}),
  openTariffConnectPopupModal: PropTypes.func,
  toggleTariffConnectionPopup: PropTypes.func,
  onDownsellPopupClose: PropTypes.func,
  place: PropTypes.string,
  orderPaymentForm: PropTypes.shape({}),
  orderPaymentFormBackButtonText: PropTypes.string,
  downsellTariffCardModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  divorcing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onConfirmPopupClose: PropTypes.func,
  warningData: PropTypes.shape({}),
  tabsToDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onResultPopupClose: PropTypes.func,
  onOrderPaymentBackClick: PropTypes.func,
  onWaitingResult: PropTypes.func,
  showSmsForm: PropTypes.bool,
  newChosenNumber: PropTypes.string,
  showWrongRegionPopup: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { restyledUpSaleABEnabled } = path(['external', 'tariff', 'data'], state);
  const connectionPopup = pathOr({}, ['external', 'tariffConnectionPopup'], state);
  const tabs = [...pathOr([], ['data', 'activationTypes'], connectionPopup)];
  const adsButtons = pathOr(null, ['data', 'adsButtons'], connectionPopup);
  const confirmPopup = pathOr({}, ['external', 'confirmNumberPopup'], state);
  const tariffData = pathOr({}, ['external', 'tariff', 'data'], state);
  const orderPaymentForm = pathOr({}, ['external', 'orderPaymentForm'], state);
  const warningData =
    tariffData.warningData && tariffData.warningData.showInPopup && tariffData.warningData;
  const convergenceData = pathOr({}, ['currentChangeConvergenceTariff'], connectionPopup);

  if (tabs && connectionPopup.isFloatingButtonClicked && !tariffData.isAuthenticated) {
    tabs.splice(1, 0, {
      type: 'MNPForm',
      title: 'Перенести номер в билайн',
    });
  }

  return {
    ...connectionPopup,
    form: path(['data', 'loginForm'], connectionPopup),
    loginLinkForm: path(['data', 'loginLinkForm'], connectionPopup),
    smsForm: path(['data', 'smsConfirmationForm'], connectionPopup),
    ussdForm: path(['data', 'ussdCommandForm'], connectionPopup),
    simOrderForm: path(['data', 'simOrderForm'], connectionPopup),
    mnpConfirmPopup: pathOr({}, ['data', 'mnpConfirmPopup'], connectionPopup),
    changeTariffForm: pathOr({}, ['data', 'changeTariffForm'], connectionPopup),
    postpaidForm: pathOr({}, ['data', 'postpaidForm'], connectionPopup),
    waitingPopup: pathOr({}, ['data', 'waitingPopup'], connectionPopup),
    sebConnectForm: pathOr({}, ['data', 'sebConnectForm'], connectionPopup),
    convergentOrderPopup: pathOr({}, ['convergentOrderPopup'], tariffData),
    tariffChangeResultPopup: connectionPopup.changeResultPopupData,
    esimPopupUrl: tariffData?.esim?.popupUrl,
    orderPaymentForm,
    orderPaymentFormBackButtonText: pathOr(null, ['texts', 'backButtonText'], orderPaymentForm),
    tariffData,
    warningData,
    tabs,
    adsButtons,
    restyledUpSaleABEnabled,
    confirmOpened: confirmPopup.tariff,
    confirmBackLinkText: confirmPopup.backButtonText,
    tabsToDisplay: connectionPopup.tabsToDisplay,
    newChosenNumber: state.external.funcyNumberOrder?.newChosenNumber,
    convergenceData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleConnectPopup,
  toggleTariffConnectionPopup: () => dispatch(toggleTariffConnectionPopup(true)),
  closePopup: () => dispatch(onClosePopup),
  sendSmsAgain: () => dispatch(resetSmsForm()),
  submitSmsFormPhone: (data) => dispatch(sendPhone(data)),
  showWrongRegionPopup: (previousRegion) => dispatch(showWrongRegionPopup(previousRegion)),
  onSimOrder: (data) => dispatch(orderSim(data)),
  onConnectPostpaid: (phone) => dispatch(connectPostpaidTariff(phone)),
  onWaitingClose: () => {
    dispatch(toggleTariffConnectionPopup(false));
    pushTariffProcessingCloseEvent();
  },
  onTariffChange: (tariffName, soc, partnersIds) =>
    dispatch(changeTariff(tariffName, soc, partnersIds)),
  onCheckTariffRequestState: (requestId, isFinalCheck) =>
    dispatch(checkTariffRequestState(requestId, isFinalCheck)),
  onChangeResultClose: () => {
    dispatch(toggleChangeResultPopup(false));
    dispatch(toggleTariffConnectionPopup(false));
  },
  onDownsellConfirm: (url, tariffData, payload, canRetry) =>
    dispatch(replaceDownsellTariff(url, tariffData, payload, canRetry)),
  onConfirmPopupClose: () => dispatch(onConfirmPopupClose),
  onResultPopupClose: (needToBackMainStep) => dispatch(onResultPopupClose(needToBackMainStep)),
  onOrderPaymentBackClick: () => dispatch(toggleOrderPaymentForm(false)),
  onDownsellPopupClose: () => dispatch(onDownsellPopupClose),
  onNumberConfirmClose: () => dispatch(toggleConfirmPopup('tariff', false)),
  onNumberConfirmBackClick: () => {
    YMAuthEvent.changeNumber(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS);
    dispatch(toggleConfirmPopup('tariff', false));
  },
  onSubmitLoginForm: (form, callback) => dispatch(sendLogin(form, callback)),
  onResultBackClick: () => dispatch(resetResultPopup()),
  onWaitingResult: (data) => dispatch(setChangeResultPopup(data)),
  onTariffConstructorToggle: () => dispatch(toggleConstructor),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(TariffConnectPopup);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
