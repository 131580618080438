import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { priceShape } from 'pages/ProductCard/Tariff/containers/tariffPropTypes';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffCost = ({ price }) => (
  <Text size="size4-r">
    <div className={cx('cost')}>
      {price.value && <div className={cx('cost__price')}>{price.value}</div>}
      {price.unit && <div className={cx('cost__price-description')}>{price.unit}</div>}
    </div>
  </Text>
);

TariffCost.propTypes = {
  price: priceShape,
};

export default TariffCost;
