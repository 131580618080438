const ERROR_POPUP_ALIASES = {
  SOMETHING_GOING_WRONG: 'SOMETHING_GOING_WRONG',
  RULE_CODE_DUPLICATE: 'RULE_CODE_DUPLICATE',
  RULE_CODE_ACCOUNT: 'RULE_CODE_ACCOUNT',
  RULE_CODE_SOC: 'RULE_CODE_SOC',
  RULE_CODE_REGION: 'RULE_CODE_REGION',
  RULE_CODE_BALANCE: 'RULE_CODE_BALANCE',
};

const POPUP_STEPS = {
  details: 'details',
  error: 'error',
};

export { ERROR_POPUP_ALIASES, POPUP_STEPS };
