import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

import { HeaderDescription } from './HeaderDescription';

// Selectors
import {
  getAddressRequestDescription,
  getAddressRequestSubTitle,
  getAddressRequestTitle,
  getUserAuthenticated,
} from '../../../store/selectors/userData';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const HeaderContainerLayout = ({ title, subTitle, description, isAuthenticated }) => (
  <div className={cx('container')}>
    {title && (
      <Text size="size2-m" tagType="h2">
        {title}
      </Text>
    )}
    {subTitle && <HeaderDescription {...{ subTitle, description, isAuthenticated }} />}
  </div>
);

const mapStateToProps = (state) => ({
  title: getAddressRequestTitle(state),
  subTitle: getAddressRequestSubTitle(state),
  isAuthenticated: getUserAuthenticated(state),
  description: getAddressRequestDescription(state),
});

HeaderContainerLayout.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};

export const HeaderContainer = connect(mapStateToProps)(HeaderContainerLayout);
