import React, { FC } from 'react';
import { Button } from '@beef/ui-kit';

type TSettingsButton = {
  btnText?: string;
  onClick: () => void;
};

/** Кнопка переходя к настройке ШПД */
export const SettingsButton: FC<TSettingsButton> = ({ btnText, onClick }) =>
  btnText ?
    <Button onClick={onClick} size="l" variant="tertiary">
      {btnText}
    </Button>
  : null;
