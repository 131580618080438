import { IData } from 'constructorV1/types';
import { combineAlertsData } from 'constructorV1/utils/combineAlertsData';

/** Позволяет подготовить данные для инициализации стейта начальными свойствами */
export const dataConverter = ({ data }: IData) => {
  /* Объект layoutData с информацией по тарифу и базовым url тарифа ЕК */
  const { layoutData, baseUrl } = data;
  /* Выборка основных объектов тарифа ЕК, полученных из hydrate */
  const { userInfo, tariffInfo, content, alertsPopupData } = layoutData;
  const { id, soc, benefits, isArchived, isConnected } = tariffInfo;
  /** Подготовка контента по ограничениям и попапам нулевых пресетов */
  const alertsData = combineAlertsData(content.alerts, alertsPopupData);

  return {
    user: { ...userInfo, isArchived, isConnected },
    total: { id, soc },
    common: { content, benefits, alertsData, baseUrl },
    tariffSettings: {
      sections: content.sectionsTitles,
    },
  };
};
