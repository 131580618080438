import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { AboutType } from 'pages/SPN/types';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const About: FC<AboutType> = ({ title, description }) => (
  <div className={cn('wrapper')}>
    <Text size="size1-m" tagType="div">
      {title}
    </Text>
    <Text color="grey60" size="size5-r-l" tagType="div">
      {description}
    </Text>
  </div>
);
