import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import { getCategoriesByPage } from '../../../../store/selectors/content';
import { ScrollToEdgesWrap } from '../../../ScrollToEdgesWrap/index';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const SelectLayout = ({ options, children }) => (
  <ScrollToEdgesWrap>
    <div
      className={cx('categories', {
        'categories--with-tag': options.find((option) => option.withTag),
      })}
    >
      {options.map(children)}
    </div>
  </ScrollToEdgesWrap>
);

SelectLayout.propTypes = {
  children: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape),
};

const mapStateToProps = (state) => ({
  options: getCategoriesByPage(state),
});

export const Select = connect(mapStateToProps)(SelectLayout);
