import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const ImageWrapper = ({ imageUrl }) => (
  <div className={cn('wrapper')}>
    <img alt="" className={cn('image')} src={imageUrl} />
  </div>
);

ImageWrapper.propTypes = {
  imageUrl: PropTypes.string,
};
