import { shallowEqual } from '@beef/utils';

import { getDefaultCharacter } from '../../../constructorPage/TariffInfoTiles/utils';
import { useAppSelector } from '../../../../store';
import { useIsMnpUserType } from '../../../../hooks/useIsMnpUserType';
import { prepareServicesInacIdList } from '../../../../utils/prepareServicesInacIdList';
import { useIsNewSimUserType } from '../../../../hooks/useIsNewSimUserType';

/** Селектор выборки параметров для отправки запроса по total чеку.
 * Содержит соки выбранных пресетов, опций, сок тарифа, аппера и т.п */
export const useSelectParams = () => {
  const {
    tariffsInfo,
    convergenceInfo,
    isConnected,
    tariffSoc,
    optionsSoc: rawOptionsSoc,
    ...params
  } = useAppSelector(
    (state) => ({
      gbValue: state.total.gbValue,
      minValue: state.total.minValue,
      tariffSoc: state.total.tariffSoc,
      optionsSoc: state.total.optionsSoc,
      characterSoc: state.total.characterSoc,
      convergenceInfo: state.total.convergenceInfo,
      isPartnerFmcInternetSelected: state.total.convergenceInfo.isPartnerFmcTariffSelected,
      generation: state.total.generation,
      flat: state.fttbAddress.checkConnectionRes.flat,
      houseId: state.fttbAddress.checkConnectionRes.houseId,
      contactPhone: state.total.userPhoneNumber,
      endDate: state.total.convergenceInfo.totalDateTimeSlot?.endDate,
      startDate: state.total.convergenceInfo.totalDateTimeSlot?.startDate,
      tariffsInfo: state.tariffsInfo,
      isConnected: state.common.userInfo.isConnected,
    }),
    shallowEqual,
  );
  const { tariffInacId = '', servicesInacIdList = [] } = convergenceInfo;

  /* Проверка вариант подключения тарифа - MNP */
  const isMnpUser = useIsMnpUserType();
  /* Проверяем, является ли шаг покупкой новой сим */
  const isNewSimUser = useIsNewSimUserType();

  /* Дефолтный сок тарифа */
  const defaultTariffSoc = getDefaultCharacter(tariffsInfo)?.soc;

  /* Условия при которых пойдет запрос смены тарифа */
  const isChange = isConnected && !isMnpUser && !isNewSimUser;

  const soc = isChange ? defaultTariffSoc : tariffSoc;

  const optionsSoc = rawOptionsSoc?.join(',');

  /** Объект параметров для get-запроса */
  return {
    isMnp: isMnpUser,
    isNewSim: isNewSimUser,
    tariffSoc: soc,
    optionsSoc,
    /* Если в convergenceInfo есть какие-то настройки (servicesInacIdList имеет inacId),
   собираем все inacId ШПД-сервисов для отображения в total-чеке и добавляем к параметрам запроса (в params) */
    servicesInacIdList: prepareServicesInacIdList(tariffInacId, servicesInacIdList),
    ...params,
  };
};
