export type TOptions = {
  fallbackPollingUrl?: string;
  shouldSkipPolling?: boolean;
};

export enum EStep {
  GetMetadata = 'GetMetadata',
  Initiate = 'Initiate',
  Polling = 'Polling',
}

export enum EPollingStatus {
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
}

export type TForm = {
  basketSocs: string[];
  campId: string;
  cancelButtonText: string;
  checkRequestStateUrl: string;
  confirmButtonText: string;
  connectServiceUrl: string;
  dateText: string;
  description: string;
  disconnectDateHint: string;
  expireDays: number;
  isMultiOffer: boolean;
  subgroupId: string;
  warningText: string;
} & { [key: string]: unknown };

export interface IResponseWithView<T extends Record<string, unknown> = { [key: string]: TForm }> {
  camelCase: boolean;
  errors: Record<string, unknown>;
  isSucceeded: boolean;
  view: T & { connectionStatus?: number };
}

type TActionType = {
  isActive: boolean;
  title: string;
  type: string;
};

type TUssdCommandForm = {
  ussd: string;
  ussdText: string;
};
export interface IMetaDataResponse
  extends IResponseWithView<
    {
      activationTypes: TActionType[];
      header: string;
      mnpConfirmPopup: Record<string, unknown>;
      serviceName: string;
      soc: string;
      socs: string;
      tariffId: number;
      token: string;
      ussdCommandForm: TUssdCommandForm;
    } & { [key: string]: TForm }
  > {}

export interface IInitializeResponse
  extends IResponseWithView<
    {
      buttonText: string;
      buttonUrl: string;
      content: string;
      interval: number;
      requestId: number;
      requestStatus: EPollingStatus;
      timeout: number;
      title: string;
    } & { [key: string]: TForm }
  > {}

export interface IPollingResponse
  extends IResponseWithView<
    {
      backButtonText: string;
      buttonResetText: string;
      buttonResetUrl: string;
      buttonText: string;
      buttonUrl: string;
      closeAction: string;
      constructorTexts: string[];
      content: Record<string, unknown>;
      ctn: string;
      description: string;
      interval: number;
      requestId: number;
      requestStatus: EPollingStatus;
      serviceOffer: string;
      serviceOfferDescription: string;
      serviceOfferImage: string;
      serviceOfferUrl: string;
      servicesUrl: string[];
      timeout: number;
      title: string;
      titleIcon: string;
      type: string;
      warningText: string;
    } & { [key: string]: TForm }
  > {}
