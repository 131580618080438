import { useNavigate } from 'react-router-dom';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { useAppDispatch, useAppSelector } from 'constructorV1/store';
import { useConstructorLink } from 'constructorV1/router/hooks/useConstructorLink';
import {
  selectIsChangeTariffLoading,
  selectRequestParams,
} from 'constructorV1/store/selectors/selectCommonBill';
import { useThrowAsyncError } from 'constructorV1/hooks/useThrowAsyncError';
import { changeTariff } from 'constructorV1/store/commonBillSlice/api/changeTariff';

/** Хук подготовки данных для перехода к результирующему запросу подключения/обновления ЕК */
export const useCBillButton = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();
  const navigate = useNavigate();
  const requestParams = useAppSelector(selectRequestParams);
  const isLoading = useAppSelector(selectIsChangeTariffLoading);

  /** Ссылка на страницу результата конструктора */
  const resultLink = useConstructorLink('result');

  const handleClick = () => {
    if (!requestParams) {
      throwAsyncError('Отсутствуют параметры запроса' as unknown as Error);
      return;
    }

    /** Запрос на изменение/обновление тарифа */
    dispatch(changeTariff(requestParams))
      .unwrap()
      .then(() => navigate(resultLink))
      .catch((e: Error) => throwAsyncError(e));
  };
  return { handleClick, isLoading };
};
