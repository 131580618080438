import React, { memo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';
import EmptyButton from 'pages-components/EmptyButton';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const SearchInput = (props) => {
  const {
    placeholder,
    value,

    onChange,
    onClear,

    showClearIcon,
    ...otherProps
  } = props;

  const inputRef = useRef(null);

  const setInFocus = useCallback(() => inputRef.current.focus(), []);
  const onIconClick = setInFocus;
  const onClearClick = useCallback(() => onClear(setInFocus), [onClear, setInFocus]);

  return (
    <div className={cx('wrapper')}>
      <EmptyButton className={cx('searchIconWrap')} onClick={onIconClick}>
        <Icon className={cx('searchIcon')} name="search" />
      </EmptyButton>
      <input
        className={cx('input')}
        onChange={onChange}
        placeholder={placeholder}
        ref={inputRef}
        value={value}
        {...otherProps}
      />
      {showClearIcon && (
        <EmptyButton className={cx('clearIconWrap')} onClick={onClearClick}>
          <Icon className={cx('clearIcon')} name="close-filled" />
        </EmptyButton>
      )}
    </div>
  );
};

SearchInput.defaultProps = { value: '' };

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  showClearIcon: PropTypes.bool,
};

export default memo(SearchInput);
