import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Tag } from '@beef/ui-kit';

import { ymSimMNPAddToCart } from 'utils/analytics/ymCommonEvents';
import { PROMOTION_PLACES } from 'pages/FancyNumber2022/constants';

import { setCurrentNumber } from '../../../store/actions/change';
import { orderNumber } from '../../../store/actions/cart';
import { StyledNumber } from '../../StyledNumber/index';
import { Price } from '../../Price/index';
import { Row } from '../Row/index';
import {
  getPricesByCategory,
  getPricesForChangeNumberByCategory,
  getPromotionContentByPlace,
} from '../../../store/selectors/content';
import { NumberShape, PromotionShape } from '../../../shapes';
import { withNumberRowAnalytics } from '../../../hocs/withNumberRowAnalytics';

const NumberRowLayout = ({
  number,
  price,
  discount,
  promotion,
  onClickNew,
  onClickChange,
  isChangeNumber,
}) => (
  <Row
    isBought={number.isBought}
    isInCart={number.isInCart}
    numberNode={
      <StyledNumber
        color={number.isBought || number.isInCart ? 'grey40' : undefined}
        isSecondary={number.isBought}
        number={number}
        size="size4-r"
      />
    }
    onClick={isChangeNumber ? onClickChange : onClickNew}
    priceNode={<Price discount={discount} price={price} size="size4-r" />}
    tagNode={promotion?.category === number.category && <Tag>{promotion.tag?.tagText}</Tag>}
  />
);

NumberRowLayout.propTypes = {
  onClickNew: PropTypes.func,
  onClickChange: PropTypes.func,
  price: PropTypes.number,
  discount: PropTypes.number,
  number: PropTypes.shape(NumberShape),
  isChangeNumber: PropTypes.bool,
  promotion: PropTypes.shape(PromotionShape),
};

const mapStateToProps = (state, { number, isChangeNumber }) => {
  const { category } = number;

  const { price, discount } = getPricesByCategory(category, state);
  const { price: priceForChangeNumber, discount: discountForChangeNumber } =
    getPricesForChangeNumberByCategory(category, state);
  const promotion = getPromotionContentByPlace(PROMOTION_PLACES.phoneNumber, state);

  return isChangeNumber ?
      {
        isChangeNumber,
        price: priceForChangeNumber,
        discount: discountForChangeNumber,
      }
    : {
        price,
        discount,
        promotion,
      };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClickNew: () => {
    ymSimMNPAddToCart();
    dispatch(orderNumber(ownProps.number));
  },
  onClickChange: () => {
    dispatch(setCurrentNumber(ownProps.number));
  },
});

export const NumberRow = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNumberRowAnalytics,
)(NumberRowLayout);
