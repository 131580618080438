import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';

import { convergentCallbackUpForm } from 'utils/analytics/tariffsYandexMetrica';

import { useOnScreenAnalytics } from '../../tempAnalytics/hooks/useOnScreenAnalytics';
import { DescriptionContainer, TDescriptionContainer } from './components/DescriptionContainer';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TResultConnect = {
  descriptionProps: TDescriptionContainer;
  icon?: string;
};

/** Компонент для отображения какой-либо финальной информации на страницах конструктора/ШПД.
 * Пример: ThanksFTTBPage-компонент */
export const ResultConnect: FC<TResultConnect> = ({ icon, descriptionProps }) => {
  /* Устанавливаем presetsRef на контейнер для отправки аналитики при видимости этого блока */
  const presetsRef = useRef(null);

  /* Отправка аналитики */
  useOnScreenAnalytics({
    ref: presetsRef,
    payload: {
      page_url: window.location.href,
      page_path: window.location.pathname,
      page_title: descriptionProps.title,
      action: 'view',
      item_type: 'page',
    },
  });

  /* Передаём цель при видимости блока */
  useEffect(() => {
    convergentCallbackUpForm();
  }, []);

  return (
    <section className={cx('wrapper')} ref={presetsRef}>
      {icon && <img alt="" className={cx('wrapper__icon')} loading="lazy" src={icon} />}
      <DescriptionContainer {...descriptionProps} />
    </section>
  );
};
