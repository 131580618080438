import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Icons
import ArrowRight from 'pages/FamilyEconomyLanding2021/assets/arrowRight.svg';

// Components
import { Family } from './components/Family';
import { NotificationContent } from './components/NotificationContent';
import { ModalContainer } from './components/ModalContainer';
// Hooks
import { useModal } from './hooks/useModal';
import { useNotification } from './hooks/useNotification';
import { useControlFamily } from './hooks/useControlFamily';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FamilyBox = ({
  showDiscount,
  isChangeTariff,
  isAuthenticated,
  currentDiscountPrice,
  isDisableFamilyButton,
  data: { familyContent, popupContent },
  familyNumberedPriceToggleEnabled,
  currentPricePeriod,
  tabPrice,
}) => {
  const { showNotification, handleNotification } = useNotification(familyContent?.attentionText);
  const { showModal, handleModal, openFamilyModal } = useModal({
    isChangeTariff,
    isAuthenticated,
    isConnected: familyContent.isConnected,
  });
  const { checked, description } = useControlFamily({
    isChangeTariff,
    isConnected: familyContent?.isConnected,
    text: familyContent?.description,
    subText: familyContent?.defaultDescription,
  });

  // если есть тоглл на переключения Пономерной Семьи
  let familyPrice;
  let familyUnit;
  let familyUnitOld;

  if (familyNumberedPriceToggleEnabled) {
    familyPrice = tabPrice;
    familyUnit = currentPricePeriod;
    familyUnitOld = currentPricePeriod;
  } else {
    familyPrice = familyContent.price;
    familyUnit = familyContent.unit;
    familyUnitOld = familyContent.unitOld;
  }

  return (
    <div className={cx('wrapper')}>
      <Family
        checked={checked}
        currentDiscountPrice={currentDiscountPrice}
        description={description}
        icon={<ArrowRight />}
        isDisableFamilyButton={isDisableFamilyButton}
        link={familyContent.link}
        linkText={familyContent.linkText}
        openFamilyModal={openFamilyModal}
        price={familyPrice}
        showDiscount={showDiscount}
        showNotification={showNotification}
        title={familyContent.title}
        unit={familyUnit}
        unitOld={familyUnitOld}
      />
      {showNotification && isDisableFamilyButton && (
        <NotificationContent
          handleNotification={handleNotification}
          text={familyContent?.attentionText}
        />
      )}
      {showModal && (
        <ModalContainer
          buttonText={popupContent.buttonText}
          description={popupContent.description}
          handleModal={handleModal}
          icon={popupContent.icon}
          isOpen={showModal}
          link={popupContent.link}
          title={popupContent.title}
        />
      )}
    </div>
  );
};

FamilyBox.propTypes = {
  showDiscount: PropTypes.bool,
  isChangeTariff: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  currentDiscountPrice: PropTypes.number,
  isDisableFamilyButton: PropTypes.bool,
  data: PropTypes.shape({
    familyContent: PropTypes.shape({
      icon: PropTypes.node,
      link: PropTypes.string,
      unit: PropTypes.string,
      title: PropTypes.string,
      price: PropTypes.number,
      unitOld: PropTypes.string,
      isConnected: PropTypes.bool,
      attentionText: PropTypes.string,
      discount: PropTypes.shape({
        oldPrice: PropTypes.number,
        unit: PropTypes.string,
      }),
      linkText: PropTypes.string,
      description: PropTypes.string,
      defaultDescription: PropTypes.string,
    }),
    attentionText: PropTypes.string,
    popupContent: PropTypes.shape({
      icon: PropTypes.string,
      link: PropTypes.string,
      unit: PropTypes.string,
      title: PropTypes.string,
      buttonText: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  familyNumberedPriceToggleEnabled: PropTypes.bool,
  currentPricePeriod: PropTypes.string,
  tabPrice: PropTypes.number,
};

export { FamilyBox };
