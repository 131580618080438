import React from 'react';

import { useHandleSelectingTime } from './hooks/useHandleSelectingTime';
import { CarouselSlots } from '../CarouselSlots';

export const TimeSlots = () => {
  const props = useHandleSelectingTime();

  return <CarouselSlots {...props} />;
};
