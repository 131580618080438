/** Собирает массив активных inacId сервисов шпд */
export const getFmcActiveServices = (services) =>
  services.reduce((acc: string[], service: any) => {
    /* Если текущий сервис являетя массивом, переходим к его маппингу для поиска активных опций */
    if (Array.isArray(service)) {
      /* Выбираем все активные опции сервиса */
      const activeServices = service.filter((item) => item.isActive);

      /* Выбираем все inacId активных опций сервиса */
      const activeInacId = activeServices.map((item) => item.inacId);

      return [...acc, ...activeInacId];
    }

    /* Если текущий сервис имеет свойство isActive и оно true,
        добавляем inacId в результирующий массив */
    if (service.isActive !== undefined && service.isActive) {
      return [...acc, service.inacId];
    }

    return acc;
  }, []);
