import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { useIsDisabledNext } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/hooks/disabledNextHooks/useIsDisabledNext';
import { useBillButtonText } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/components/commonComponents/Bill/hooks/useBillButtonText';

import { useAppSelector } from '../../../../store';
import { useCountBillSum } from '../hooks/useCountBillSum';
import { useIsLoadingPresetsPrice } from '../../../../hooks/useIsLoadingPresetsPrice';

/** Селектор выборки контента для чека на странице тарифа */
export const useSelectContent = () => {
  const isMobile = useIsMobileDimension();

  /* Получение статуса загрузки пресетов / цены по персонажу (при запросе) */
  const isLoadingPresets = useIsLoadingPresetsPrice();

  const isDisableNext = useIsDisabledNext();
  const buttonText = useBillButtonText();

  /* Получение общих контентных данных */
  const { content } = useAppSelector((state) => state.common);
  const { bill } = content;
  const { unit, title, period, extraText, description } = bill || {};

  /* Подготовка общей суммы абонентной платы по тарифу */
  const { price, oldPrice } = useCountBillSum();

  /** Проп для верхней (total) части чека */
  const totalProps = {
    title,
    isMobile,
    description,
    isLoadingPresets,
    totalSumProps: { unit, period, price, oldPrice },
  };

  /** Проп для нижней (button) части чека */
  const btnProps = { isMobile, extraText, disabled: isDisableNext, nextButton: buttonText };

  return { btnProps, totalProps };
};
