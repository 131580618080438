import axios from 'axios';

// Actions
import { pushTariffConnectProcessingEvent } from 'utils/ga';
import {
  sendCvmAnalyticsEvent,
  sendCvmTariffAnalyticsEvent,
} from 'utils/analytics/cvmTariffAnalytics';
import { poll } from 'utils/timed-functions';
import submitCallbackRequest from 'pages/FTTB/services/submitCallbackRequest';

import { errorConnectionEvent, mobileConnectEvent } from './tariffConnectionAnalytics';
import { setTariffConnectionPopup, setWaitingPopup } from './tariffConnectExtension';
import { recordPayload, showError } from '../actionsApi';
import { trackADSEvent } from '../tariffConnect';
// Utils
import { createRequestInProgressMsg } from '../../utils';
// Analytics
import {
  SET_CONNECTION_RESULT_POPUP,
  SET_TARIFF_CONNECTION_POPUP,
  SET_TARIFF_IN_PROGRESS_POPUP,
} from '../actionTypes';
import { POLLING_STATUS } from '../../constants';
import { standartErrorPopup } from './helper';

// Отправляет аналитику на бэк;
const setAnalyticEvent = (subgroupId, campId, place) => {
  let renderUrlMatch = null;

  // Проверяем откуда пришел абонент:
  if (typeof window !== 'undefined') {
    renderUrlMatch = window.location.href.match('detailsforlk');
  }

  if (subgroupId && campId) {
    const arrayIds = [{ subgroupId, campId }];
    const ANALYTICS_STATE = 'landWantMore';

    if (renderUrlMatch) {
      // Аналитика для прешедшего из ЛК
      sendCvmAnalyticsEvent(arrayIds, ANALYTICS_STATE, 'offers');
      return;
    }

    if (place === 'catalog' || place === 'downsell') {
      const placeResult = place === 'downsell' ? 'downsale' : place;
      sendCvmAnalyticsEvent(arrayIds, ANALYTICS_STATE, placeResult);
    } else {
      sendCvmTariffAnalyticsEvent([{ state: ANALYTICS_STATE, subgroupId, campId }]);
    }
  }
};

const pollingRemoveFR = ({ requestId, timeoutSeconds, intervalSeconds }) =>
  poll(
    () =>
      axios
        .get(`/simply/CheckCancelFutureTariffChange/?requestId=${requestId}`)
        .then(({ data: { isSucceeded } }) => {
          if (isSucceeded) {
            return POLLING_STATUS.complete;
          }
          return POLLING_STATUS.progress;
        })
        .catch(() => POLLING_STATUS.error),
    (status) => status === POLLING_STATUS.progress,
    +intervalSeconds * 1000,
    +timeoutSeconds * 1000,
  );

const getDataConnectPopupUrl = (connectPopupUrl) =>
  axios.post(connectPopupUrl).then(({ data: { isSucceeded, view } }) => {
    if (!isSucceeded) {
      return { error: true };
    }
    return view?.hasFutureSimplifiedTariff ? { error: true } : view;
  });

export const changeTariff = (tariffName, soc, partnersId) => (dispatch, getState) => {
  const state = getState();
  const { external } = state;
  const {
    tariff,
    tariffConnectionPopup,
    tariffConnectionPopup: {
      data,
      constructorId,
      downSellPayload,
      additionalOptions,
      connectPopupUrl,
    },
    tariffConstructor,
  } = external;
  const { place, nextActivationTypes, hasFutureSimplifiedTariff, layoutData } = data;
  const {
    socsOn,
    socsOff,
    offerType,
    optionSocs,
    marketCode,
    fttbServices,
    isChangeMobile,
    isChangeOnlyMobileOptions,
    isNeedCallBackOptions,
    chosenSubscriptions,
    tariffConstructorId,
    updateConnectedTariff,
    connectingConstructor,
    hasChangedConvergenceOptions,
  } = tariff.data;
  const convergenceCtn = layoutData?.convergenceContent?.userInfo?.activeCTN;

  const {
    changeTariffUrl,
    freeActivation,
    rcRatePeriod,
    isMultiOffer,
    isFmcRequest,
    discountSoc,
    basketSocs,
    subgroupId,
    campId,
  } = data.changeTariffForm;
  const downSellConstructorId = downSellPayload && downSellPayload.constructorId;
  const idConstructor =
    downSellConstructorId ||
    tariffConstructor.values.constructorId ||
    constructorId ||
    tariff.data.constructorId ||
    data.changeTariffForm.constructorId ||
    null;

  if (nextActivationTypes) {
    dispatch(
      recordPayload(SET_TARIFF_CONNECTION_POPUP, {
        isPending: true,
        data: {
          ...data,
          activationTypes: nextActivationTypes,
          nextActivationTypes: null,
        },
      }),
    );

    return setTimeout(
      () =>
        dispatch(
          recordPayload(SET_TARIFF_CONNECTION_POPUP, {
            popupIsActive: true,
            isPending: false,
          }),
        ),
      1000,
    );
  }

  dispatch(
    recordPayload(SET_TARIFF_CONNECTION_POPUP, {
      isPending: true,
      data: {
        ...data,
        waitingPopup: {
          ...data.waitingPopup,
          step: 'pending',
          opened: true,
        },
      },
    }),
  );

  dispatch(trackADSEvent('accept'));

  let params = {
    freeActivation: typeof freeActivation === 'boolean' ? freeActivation : null,
    constructorId: tariffConstructorId || idConstructor,
    discountSoc: layoutData?.discountSoc || discountSoc,
    disconnectedSocs: socsOff,
    additionalSocs: socsOn,
    serviceName: tariffName,
    connectingConstructor,
    updateConnectedTariff,
    rcRatePeriod,
    subgroupId,
    basketSocs,
    campId,
    place,
    soc,
    partnersId,
  };

  if (isChangeMobile) {
    params.isChangeMobile = isChangeMobile;
  }

  /**
   * Отправка fttbServices в запрос fmc-connect-tariff (обновление тарифа при наличии конвергенции).
   * Отправляем запрос если: нет моб. изминений (только конвергентные) или есть и моб. и конвергентные изменения.
   */
  if (
    isFmcRequest &&
    (!isChangeOnlyMobileOptions || (isChangeMobile && hasChangedConvergenceOptions))
  ) {
    params.fttbServices = fttbServices;
  }

  if (chosenSubscriptions) {
    params.partnersId = (partnersId || chosenSubscriptions || []).join(',');
  }

  if ((tariff.data.isForYoung && additionalOptions?.chosenSocs) || optionSocs) {
    params.additionalSocs = optionSocs || additionalOptions.chosenSocs;
  }

  if (additionalOptions?.additionalSimCount) {
    params.additionalSimCount = additionalOptions.additionalSimCount;
  }

  if (isMultiOffer !== null) {
    params = {
      isMultiOffer,
      ...params,
    };
  }

  // Если абонент пришел из каталога, иначе по обычному сценарию;
  const analyticsPlace = !offerType ? 'catalog' : (tariffConnectionPopup || {}).place;

  /**
   * connectUpdateConvergenceTariff: новый метод обновления тарифа с подключенным ШПД.
   */
  const connectUpdateConvergenceTariff = () =>
    axios
      .post(changeTariffUrl, params)
      .then((response) => {
        const { view, isSucceeded } = response.data;
        if (isNeedCallBackOptions || !isSucceeded) {
          submitCallbackRequest({
            phoneNumber: `+7${convergenceCtn}`,
            caseTitle: 'support',
          });
        }

        dispatch(setTariffConnectionPopup({ currentChangeConvergenceTariff: view }));
        dispatch(
          recordPayload(SET_TARIFF_CONNECTION_POPUP, {
            isPending: false,
            data: { isConvergence: true },
            resultPopupData: { requestStatus: 'COMPLETE' },
          }),
        );
      })
      .catch((error) => console.error(error));

  const canChangeTariff = () =>
    axios
      .post(changeTariffUrl, params)
      .then((response) => {
        const { view } = response.data;

        if (view.type && view.type === 'multiOfferOk') {
          dispatch(
            setWaitingPopup({
              ...view,
              opened: true,
              step: 'main',
            }),
          );
        }
        return response.data;
      })
      .then((responseData) => {
        const { isSucceeded, view } = responseData;

        if (view?.requestId === -1) {
          dispatch(
            recordPayload(SET_TARIFF_IN_PROGRESS_POPUP, {
              isPending: false,
              errorPopupData: {
                ...createRequestInProgressMsg(view, tariffName, 'подключение тарифа'),
              },
              data: null,
            }),
          );
          return;
        }

        if (isSucceeded) {
          const { requestStatus, requestId } = view;

          dispatch(
            recordPayload(SET_TARIFF_CONNECTION_POPUP, {
              tariffRequestId: requestId,
            }),
          );

          if (requestStatus === 'OPEN' || requestStatus === 'IN_PROGRESS') {
            dispatch(setWaitingPopup({ ...view, opened: true, step: 'main' }));
            pushTariffConnectProcessingEvent({ tariffName, soc, marketCode });
          } else if (requestStatus === 'COMPLETE') {
            dispatch(
              setWaitingPopup({
                result: view,
                opened: true,
                step: 'main',
              }),
            );

            dispatch(mobileConnectEvent(view));

            // CVM Analytics offer from lk

            // ---------------------------
          } else {
            dispatch(recordPayload(SET_CONNECTION_RESULT_POPUP, view));
            dispatch(errorConnectionEvent(view.content));
          }
        } else {
          dispatch(errorConnectionEvent(view.content));
          dispatch(showError(view || {}));
          dispatch(setWaitingPopup({ opened: true, step: 'error' }));

          // Аналитика
          setAnalyticEvent(subgroupId, campId, analyticsPlace);
          // ---------
        }
      })
      .catch(({ response }) => {
        const responseData = response.data || {};

        dispatch(errorConnectionEvent(responseData));
        dispatch(showError(responseData));

        // Аналитика
        setAnalyticEvent(subgroupId, campId, analyticsPlace);
        // ---------
      });

  const removeFR = () => {
    dispatch(
      recordPayload(SET_TARIFF_CONNECTION_POPUP, {
        isPending: true,
        data: {
          ...data,
          waitingPopup: {
            ...data.waitingPopup,
            title: null,
            content: null,
            step: 'pending',
            opened: true,
          },
        },
      }),
    );

    axios
      .post('/simply/CancelFutureTariffChange/')
      .then((response) => {
        const {
          isSucceeded,
          data: { requestId, timeoutSeconds, intervalSeconds, description },
        } = response.data;
        if (!isSucceeded) {
          throw new Error();
        }

        dispatch(
          recordPayload(SET_TARIFF_CONNECTION_POPUP, {
            isPending: true,
            data: {
              ...data,
              waitingPopup: {
                ...data.waitingPopup,
                title: '',
                content: description,
                step: 'pending',
                opened: true,
              },
            },
          }),
        );

        pollingRemoveFR({
          requestId,
          timeoutSeconds,
          intervalSeconds,
          description,
        })
          .then((answer) => {
            if (answer === POLLING_STATUS.error) {
              throw new Error();
            }

            getDataConnectPopupUrl(connectPopupUrl)
              .then((resData) => {
                if (resData?.error) {
                  throw new Error();
                }

                dispatch(
                  recordPayload(SET_TARIFF_CONNECTION_POPUP, {
                    isPending: false,
                    data: {
                      ...resData,
                    },
                  }),
                );
                return Promise.resolve();
              })
              .catch(() => Promise.reject(standartErrorPopup(dispatch)));
          })
          .catch(() => Promise.reject(standartErrorPopup(dispatch)));
      })
      .catch(() => Promise.reject(standartErrorPopup(dispatch)));
  };

  const compileTariffChange = async () => {
    if (hasFutureSimplifiedTariff) {
      await removeFR();
    } else {
      // eslint-disable-next-line no-unused-expressions
      isFmcRequest ? connectUpdateConvergenceTariff() : canChangeTariff();
    }
  };

  return compileTariffChange();
};
