import { ButtonPropsType, LinkPropsType } from '@beef/ui-kit';

import { TModalActionBarProps } from './types';

export const getButtonProps = (
  input: RecursivePartial<TModalActionBarProps['confirmButton']>,
): Partial<ButtonPropsType> & Partial<LinkPropsType> => ({
  onClick: !input?.content?.href ? input?.onClick : undefined,
  variant: input?.content?.variant || 'main',
  buttonVariant: input?.content?.buttonVariant || 'main',
  size: input?.content?.size || 'm',
  href: input?.content?.href,
  children: input?.content?.text || '',
});
