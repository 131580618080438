import { useAppSelector } from '../../../../../store';
import { selectListBlockContent } from '../../../../../store/slices/commonSlice/selectors';

/**
 * Хук для контента блока с условиями
 * переноса номера
 */
export const useListBlock = () => {
  return useAppSelector(selectListBlockContent);
};
