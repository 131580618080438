import cn from 'classnames/bind';

import { CSSAnimatedComponent } from 'pages-components/Animations';

import styles from './styles.pcss';

const cx = cn.bind(styles);

const slideAndFade = () => ({
  enter: cx('slideAndFadeEnter'),
  enterActive: cx('slideAndFadeEnterActive'),
  exit: cx('slideAndFadeExit'),
  exitActive: cx('slideAndFadeExitActive'),
});

const slideAndFadeRight = () => ({
  enter: cx('slideAndFadeRightEnter'),
  enterActive: cx('slideAndFadeRightEnterActive'),
  exit: cx('slideAndFadeRightExit'),
  exitActive: cx('slideAndFadeRightExitActive'),
});

const defaultProps = {
  mountOnEnter: true,
  unmountOnExit: true,
  appear: true,
};

export const SlideAndFade = CSSAnimatedComponent(slideAndFade());
SlideAndFade.defaultProps = defaultProps;

export const SlideAndFadeRight = CSSAnimatedComponent(slideAndFadeRight());
SlideAndFade.defaultProps = defaultProps;
