import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { composeStoreInitializer } from '@beef/redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { commonReducer } from './commonSlice';
// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { tariffSettingsReducer } from './tariffSettingsSlice';
import { totalReducer } from './totalSlice';
import { userReducer } from './userSlice';
// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { commonBillReducer } from './commonBillSlice';

/** Общий reducer ConstructorV1 */
const rootReducer = combineReducers({
  common: commonReducer,
  tariffSettings: tariffSettingsReducer,
  total: totalReducer,
  user: userReducer,
  commonBill: commonBillReducer,
});

export const initializeStore = composeStoreInitializer(
  rootReducer,
) as unknown as typeof configureStore<typeof rootReducer>;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ReturnType<typeof initializeStore>['dispatch'];

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
