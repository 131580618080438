import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { Button, Preloader, Status, Tooltip } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';

// Utils / Analitycs
import { pushOpenTariffConnectPopup } from 'utils/ga';

// Components
import Ctx from 'pages-components/Ctx';

// Utils
import {
  sendCvmAnalyticsEvent,
  sendCvmTariffAnalyticsEvent,
} from 'utils/analytics/cvmTariffAnalytics';
import { TARIFF_LAYOUTS } from 'pages/ProductCard/Tariff/tariffTypes';

// Actions

import { mobTariffDetailConnectYM } from 'utils/analytics/tariffsYandexMetrica';

import { initConnectionPopup } from '../../actions/tariffConnect/tariffConnectExtension';

// Styles
import {
  ChangeTariffBtn,
  ConnectBtnsRow,
  ConnectTariffButton,
  SimOrderButton,
} from '../ConnectButtonsGroup';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Компонент содержит в себе все доступные
 * кнопки подключения для тарифа;
 */
class TariffConnectButton extends PureComponent {
  state = {
    detailsforlk: false,
  };

  componentDidMount() {
    this.setButtonsData();

    if (typeof window !== 'undefined') {
      const renderUrlMatch = window.location.href.match('detailsforlk');
      if (renderUrlMatch) this.setState({ detailsforlk: true });
    }
  }

  setButtonsData = () => {
    if (this.props.onInit) {
      this.props.onInit(this.props.data);
    }
  };

  checkAvailableForConnect = () => {
    const { isConnected, availableForConnect, isArchived } = this.props.data;
    return !isConnected && availableForConnect && !isArchived;
  };

  // CVM Аналитика
  sendAnalyticsEvent = () => {
    const { detailsforlk } = this.state;
    const { offerType, campId, subgroupId } = this.props.data;

    if (campId && subgroupId) {
      const arrayIds = [{ campId, subgroupId }];
      const ANALYTICS_STATE = 'landApproved';

      // Если абонент пришел из ЛК;
      if (detailsforlk) {
        sendCvmAnalyticsEvent(arrayIds, ANALYTICS_STATE, 'offers');
        return;
      }

      // Если абонент пришел из Каталога по клику на upsale тариф;
      if (!offerType) {
        sendCvmAnalyticsEvent(arrayIds, ANALYTICS_STATE, 'catalog');
        return;
      }

      sendCvmTariffAnalyticsEvent([
        {
          campId,
          subgroupId,
          state: ANALYTICS_STATE,
        },
      ]);
    }
  };

  pushButtonClickEvent = (buttonType) => () => {
    this.setButtonsData();

    const { includeNumber, newNumber } = this.props;
    const { pageTitle, marketCode, soc } = this.props.data;
    const method = includeNumber && newNumber ? 'SimNumber' : 'Sim';

    this.sendAnalyticsEvent();

    /** Клик на Подключить, Хочу этот тариф Yandex Metrika */
    mobTariffDetailConnectYM(document.location.href);

    pushOpenTariffConnectPopup({
      isMnp: buttonType === 'mnp',
      tariffName: pageTitle,
      marketCode,
      method,
      soc,
    });
  };

  /**
   * Рендер кнопки подключения тарифа, кнопка содержит в себе модальное окно.
   */
  renderConnectTariffButton = (buttonText, tabsToDisplay, isFloatingButton) => {
    const { includeNumber, newNumber, data, floatingButtonBehavior } = this.props;

    return (
      <ConnectTariffButton
        buttonText={buttonText}
        includeNumber={includeNumber}
        isFloatingButton={floatingButtonBehavior || isFloatingButton}
        newNumber={newNumber}
        onClick={this.pushButtonClickEvent('connect')}
        popupData={data}
        tabsToDisplay={tabsToDisplay}
      />
    );
  };

  /**
   * Рендер кнопки заказа тарифа, кнопка содержит в себе модальное окно;
   */
  renderSimOrderButton = (modifier, color = '') => {
    const { includeNumber, chosenNumber, data } = this.props;
    const { discountValue, isAuthenticated } = data;

    let simButtonText =
      isAuthenticated ? Ctx.tariff.buttonText.simOrderMore : Ctx.tariff.buttonText.simOrder;

    if (discountValue) {
      simButtonText = Ctx.tariff.buttonText.simOrderDiscount;
    }

    return (
      <SimOrderButton
        buttonText={simButtonText}
        color={color}
        includeNumber={includeNumber}
        modifier={modifier}
        number={chosenNumber}
        onClick={this.pushButtonClickEvent('sim')}
        popupData={data}
      />
    );
  };

  renderChangeTariffBtn() {
    const { data } = this.props;

    return (
      <div className={cx('changeTariffBtn')}>
        <ChangeTariffBtn data={data} />
      </div>
    );
  }

  render() {
    const {
      data,
      warningDataCard,
      isPreloader,
      onlyConnectButton,
      connectButtonText,
      renderYoungConnected,
    } = this.props;

    const {
      hideSubscribeButton,
      isAuthenticated,
      availableInShop,
      isConnected,
      warningText,
      isArchived,
      twoOrderBtnsData,
      isOrderBtnsRow,
      connectButton,
      layoutType,
    } = data;

    if (hideSubscribeButton && !isConnected) {
      return null;
    }

    const isAvailableForConnect = this.checkAvailableForConnect();

    const showOneMoreButton =
      availableInShop && isConnected && isAuthenticated && !isArchived && !onlyConnectButton;
    const isStatusFailVisible =
      !isAvailableForConnect && !showOneMoreButton && !availableInShop && !isConnected;
    const isTariffYoung = layoutType === TARIFF_LAYOUTS.young;

    if (renderYoungConnected) {
      return (
        <div className={cx('btnsRow', isTariffYoung && 'youngFont')}>
          <div className={cx('btnsCol')}>
            <Button className="big" href="/customers/products/mobile/profile/#/home" wide>
              {Ctx.tariff.configureBtnText}
            </Button>
          </div>
          <div className={cx('btnsCol')}>{this.renderSimOrderButton('big', 'light')}</div>
        </div>
      );
    }

    return (
      <div className={cx('connectButtons', isTariffYoung && 'youngFont')}>
        {isPreloader ?
          <div className={cx('preloader')}>
            <Preloader />
          </div>
        : <>
            {isConnected && (
              <Status className={['success', 'centeredText', cx('tariffConnected')]}>
                <div className={cx('tariffConnectedContent' && isTariffYoung && 'youngFont')}>
                  {isTariffYoung ?
                    Ctx.tariff.statusText.myTariff
                  : Ctx.tariff.statusText.currentTariff}
                </div>
              </Status>
            )}

            {warningDataCard && (
              <Status className={['attention', cx('warning')]} emoji="pointingHandUp">
                <span
                  className={cx(isTariffYoung && 'youngFont')}
                  dangerouslySetInnerHTML={{ __html: warningDataCard.text }}
                />
                {warningDataCard.tooltip && (
                  <Tooltip>
                    <span dangerouslySetInnerHTML={{ __html: warningDataCard.tooltip }} />
                  </Tooltip>
                )}
              </Status>
            )}

            {/* Кнопка + модальное окно подключение тарифа  */}
            {isAvailableForConnect && !twoOrderBtnsData && (
              <div className={cx('block', isTariffYoung && 'youngFont')}>
                {this.renderConnectTariffButton(connectButtonText)}
              </div>
            )}

            {/* настройка тарифа для молодежного тарифа */}
            {isConnected && isTariffYoung && (
              <div className={cx('block', isTariffYoung && 'youngFont')}>
                <Button className="big" href="/customers/products/mobile/profile/#/home" wide>
                  {Ctx.tariff.configureBtnText}
                </Button>
              </div>
            )}

            {/* Кнопка + модальное окно заказа SIM-карты  */}
            {showOneMoreButton && (
              <div className={cx('block', isTariffYoung && 'youngFont')}>
                {this.renderSimOrderButton(isTariffYoung ? 'light' : '')}
              </div>
            )}

            {twoOrderBtnsData && !isOrderBtnsRow && (
              <div className={cx('block', isTariffYoung && 'youngFont')}>
                {this.renderConnectTariffButton(
                  twoOrderBtnsData.connectBtnText || connectButton,
                  twoOrderBtnsData.buySimTabsToDisplay,
                  true,
                )}
                {isAvailableForConnect && this.renderChangeTariffBtn()}
              </div>
            )}
            {isOrderBtnsRow && twoOrderBtnsData && (
              <div className={cx('block', isTariffYoung && 'youngFont')}>
                <ConnectBtnsRow
                  className={cx('orderBtnsRow')}
                  connectBtnText={twoOrderBtnsData.connectBtnText || connectButton}
                  isShowChangeTariff={isAvailableForConnect}
                  onConnectClick={this.pushButtonClickEvent('connect')}
                  tabsToDisplay={twoOrderBtnsData.buySimTabsToDisplay}
                  tariffData={data}
                />
              </div>
            )}
          </>
        }

        {!isConnected && !isArchived && (
          <div>
            {isStatusFailVisible && (
              <Status className={['attention']} emoji="statusFail">
                <span className={cx(isTariffYoung && 'youngFont')}>
                  {Ctx.tariff.statusText.available}
                </span>
              </Status>
            )}

            {warningText && (
              <Status className={['attention']} emoji="statusFail" text={warningText} />
            )}
          </div>
        )}

        {isArchived && (
          <Status className={['centeredText']}>
            <span className={cx(isTariffYoung && 'youngFont')}>
              {Ctx.tariff.statusText.archived}
            </span>
          </Status>
        )}
      </div>
    );
  }
}

TariffConnectButton.propTypes = {
  connectButtonText: PropTypes.string,
  chosenNumber: PropTypes.string,
  includeNumber: PropTypes.bool,
  newNumber: PropTypes.string,
  onInit: PropTypes.func,
  isPreloader: PropTypes.bool,
  onlyConnectButton: PropTypes.bool,
  floatingButtonBehavior: PropTypes.bool,
  data: PropTypes.shape({
    esim: PropTypes.object,
    twoOrderBtnsData: PropTypes.shape({
      connectBtnText: PropTypes.string,
      buySimTabsToDisplay: PropTypes.array,
    }),
    hideSubscribeButton: PropTypes.bool,
    availableForConnect: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    availableInShop: PropTypes.bool,
    discountValue: PropTypes.any,
    offerCode: PropTypes.string,
    pageTitle: PropTypes.string,
    marketCode: PropTypes.string,
    offerType: PropTypes.string,
    soc: PropTypes.string,
    isConnected: PropTypes.bool,
    warningText: PropTypes.bool,
    isArchived: PropTypes.bool,
    campId: PropTypes.number,
    subgroupId: PropTypes.number,
    isOrderBtnsRow: PropTypes.bool,
    connectButton: PropTypes.string,
  }),
  renderYoungConnected: PropTypes.bool,
  warningDataCard: PropTypes.shape({
    text: PropTypes.string,
    tooltip: PropTypes.string,
  }),
};

const ConnectedComponent = connect(null, (dispatch) => ({
  onInit: (data) => dispatch(initConnectionPopup(data.connectPopupUrl, data.alias, data.soc)),
}))(TariffConnectButton);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
