import classNames from 'classnames/bind';
import React from 'react';

import { Powers } from '../../Powers';
import { TBasePower } from '../types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TPowerContainerProps = {
  activeColor?: string;
  handleModal: (id?: string) => void;
  powers?: Partial<TBasePower>[];
};

export const PowersContainer: React.FC<TPowerContainerProps> = ({
  powers,
  handleModal,
  activeColor: color,
}) => (
  <div className={cx('wrapper')}>
    {powers?.map(({ title, description, icon, linkText, id, abilityIcon }) => (
      <Powers
        abilityIcon={abilityIcon}
        color={color}
        description={description}
        handleModal={() => {
          handleModal(id);
        }}
        icon={icon}
        isCard
        key={id}
        linkText={linkText}
        smallSize
        title={title}
      />
    ))}
  </div>
);
