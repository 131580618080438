import React, { memo } from 'react';
import classNames from 'classnames/bind';

import { BillBanner } from 'pages/ProductCard/Tariff/components/BillBanner';
import {
  ModalContainer,
  ModalHeader,
} from 'pages/ProductCard/Tariff/components/BillModalComponents';

import { useFamilyPopup } from './selectors';
import styles from './styles.pcss';
import { TFamilyBanner } from './types';

const cx = classNames.bind(styles);

export const FamilyContainer = memo(
  ({ bgColor, contentPopup, description, image, title }: TFamilyBanner) => {
    const isShowBanner = title && description;

    const { modalContainerProps, modalHeaderProps, onOpen } = useFamilyPopup({ contentPopup });

    if (!isShowBanner) {
      return null;
    }

    if (modalContainerProps && modalHeaderProps) {
      return (
        <>
          {/*  eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button className={cx('button', 'banner-button')} onClick={onOpen} type="button">
            <BillBanner
              bgColor={bgColor}
              contentProps={{
                description,
                title,
              }}
              image={image}
            />
          </button>
          <ModalContainer {...modalContainerProps}>
            <ModalHeader {...modalHeaderProps} />
          </ModalContainer>
        </>
      );
    }

    return null;
  },
);

export type { TFamilyBanner };
