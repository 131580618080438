import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, pathOr, propEq } from 'ramda';

import { setServiceStatus } from 'pages/ProductCard/actions/redirect';
import { openConfirmation } from 'pages/ProductCard/components/ServiceStatusPopup/actions';

import { ServiceConnectionStatus } from './DefaultStatus';

const RedirectServiceStatus = ({
  isNoneActive,
  onDisconnect,
  isLoading,
  connectedContent,
  ...props
}) => {
  const handleDisconnect = !isNoneActive ? onDisconnect : null;
  let customs = {};
  if (!isLoading) {
    customs = {
      canBeRemoved: true,
      disconnectContent:
        isNoneActive ? <span>Переадресация не настроена</span> : 'Отключить все переадресации',
      connectedContent: isNoneActive ? `${connectedContent}.` : connectedContent,
    };
  }
  return !isLoading ?
      <ServiceConnectionStatus
        {...props}
        {...customs}
        className="redirect"
        onDisconnect={handleDisconnect}
      />
    : null;
};

RedirectServiceStatus.propTypes = {
  isNoneActive: PropTypes.bool,
  onDisconnect: PropTypes.func,
  isLoading: PropTypes.bool,
  connectedContent: PropTypes.object,
};

export default connect(
  ({ external }) => {
    const status = pathOr([], ['redirect', 'serviceStatus'], external);
    const { loaded, serverOptions } = status || {};
    const { name } = find(propEq('active', true))(serverOptions);
    const isNoneActive = name === 'none';
    const isLoading = !loaded;
    return {
      isNoneActive,
      isLoading,
    };
  },
  (dispatch) => ({
    onDisconnect: () => {
      dispatch(setServiceStatus({ disconnect: true }));
      dispatch(openConfirmation({ key: 'redirectService' }));
    },
  }),
)(RedirectServiceStatus);
