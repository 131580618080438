import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { DescriptionBlock } from '../DescriptionBlock';
import { TEquipmentBlock } from './types';
import { PriceBlock } from '../PriceBlock';
import { EquipmentModal } from '../EquipmentModal';
import { usePrepareEquipmentBlock } from './hooks/usePrepareEquipmentBlock';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Блок информацией по оборудования, является частью списка выбора оборудования */
export const EquipmentBlock: FC<TEquipmentBlock> = ({ isActive, equipment }) => {
  const { title, description, infoPrice, contentPopup, showMoreButton } = equipment;
  const { onClick, modalContainerProps } = usePrepareEquipmentBlock({
    buttonText: contentPopup?.buttonText,
    link: contentPopup?.buttonText,
  });

  return (
    <div className={cx('component', { active: isActive })}>
      {/* Заголовок */}
      <Text className={cx('title')} size="size4-m">
        {title}
      </Text>
      {/* Описание с кнопкой */}
      <DescriptionBlock
        description={description}
        hasContentPopup={!!contentPopup}
        onClick={onClick}
        showMoreButton={showMoreButton}
      />
      {/* Блок цены */}
      <PriceBlock {...infoPrice} />
      {/* Модалка */}
      {contentPopup && (
        <EquipmentModal
          description={contentPopup.description}
          modalContainerProps={modalContainerProps}
          title={contentPopup.title}
        />
      )}
    </div>
  );
};
