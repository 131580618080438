import React, { FC } from 'react';
import { Avatar } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCCloseBillIcons = {
  pictures: string[];
};

/** Варианты анимации иконок в результирующем чеке ЕК в свернутом состоянии */
const variantsCIcons = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
};

/** Компонент иконок опций в результирующем чеке ЕК (свернутый) */
export const CCloseBillIcons: FC<TCCloseBillIcons> = ({ pictures = [] }) => (
  <AnimatePresence initial={false}>
    <motion.div className={cx('wrapper')} layout>
      {pictures.map((picture, index) => (
        <motion.div
          animate="visible"
          className={cx('wrapper__picture')}
          custom={index}
          exit="exit"
          initial="hidden"
          key={picture}
          layout
          layoutId={picture}
          variants={variantsCIcons}
        >
          <Avatar className={cx('wrapper__icon')} image={picture} size="s" />
        </motion.div>
      ))}
    </motion.div>
  </AnimatePresence>
);
