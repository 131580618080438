import { createAction } from '@reduxjs/toolkit';

import { setAliasAction } from 'pages/FancyNumber2022/store/actions/content';
import { getActiveFilter } from 'pages/FancyNumber2022/store/selectors/filter';

import { getActiveCategories, getActiveCategoriesArray } from '../selectors/category';
import { updatePageUrl } from './routing';

export const setCategory = createAction('set category');
export const setActiveCategories = createAction('set active categories');

export const toggleActiveCategory = (key) => (dispatch, getState) => {
  dispatch(
    setCategory({
      key,
      value: !getActiveCategories(getState())[key],
    }),
  );
  dispatch(
    setAliasAction(
      getActiveCategoriesArray(getState()).length ?
        getActiveCategoriesArray(getState())[0]
      : getActiveFilter(getState()),
    ),
  );
  updatePageUrl({
    categories: getActiveCategoriesArray(getState()),
  });
};
