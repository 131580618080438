import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { LayoutGroup, motion } from 'framer-motion';

import { AmountTextContent } from '../AmountTextContent';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TAmountText = {
  isWithoutPay?: boolean;
  oldPrice?: number;
  period?: string;
  price?: number;
  title?: string;
  unit?: string;
  withoutPayText?: string;
};

/** Компонент базовой стоимости выбранных пресетов/опций чека ЕК */
export const AmountText: FC<TAmountText> = ({
  title,
  withoutPayText,
  isWithoutPay,
  ...amountContent
}) => {
  return (
    <motion.div className={cx('wrapper')} layout>
      <LayoutGroup>
        {isWithoutPay ?
          /* Выводим инфо текст, если оплата за обновление тарифа не требуется */
          <Text align="left" color="secondary" lineHeight={24} size="m" weight="regular">
            {withoutPayText}
          </Text>
        : /* Выводим инфо со стоимостью обновления/подключения тарифа во всех остальных случаях  */
          <>
            {title && (
              <Text align="left" color="primary" lineHeight={24} size="m" weight="regular">
                {title}
              </Text>
            )}
            <AmountTextContent {...amountContent} />
          </>
        }
      </LayoutGroup>
    </motion.div>
  );
};
