import { emptyArr, emptyObj } from '@beef/utils';
import { createSelector } from '@reduxjs/toolkit';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';

import {
  REQUEST_STATUS,
  highSpeedVerification,
  popupSteps,
} from 'pages/FTTB2021/components/OrderTariffFormPopup/constants';
import { getQueryParams } from 'pages/FTTB2021/components/OrderTariffFormPopup/utils';

import {
  getCurrentFormStep,
  getOrderTariffCommonData,
  getOrderTariffData,
  getOrderTariffTexts,
  getTargetTariffType,
} from './common';

export const getAddressData = (state) => getOrderTariffData(state).userData || emptyObj;
export const getRegion = (state) => getOrderTariffCommonData(state).region;
export const getStatusConnection = (state) => getAddressData(state).statusConnection;
export const getUserPhoneNumber = (state) => getAddressData(state).userPhoneNumber;

export const getFullAddress = (state) => {
  const addressData = getAddressData(state);
  const { label } = getRegion(state);
  const { city, house, flat } = getOrderTariffTexts(state).placeholderAddressTexts;

  return `
  ${city}${label}, 
  ${addressData.street.label || 'улица не выбрана'}, 
  ${house}${addressData.house.label || 'не выбран'}, 
  ${flat}${addressData.flat || 'не выбрана'}
   `;
};

export const getHighSpeedAvailableFlag = createSelector(
  [getStatusConnection],
  (status) =>
    status === REQUEST_STATUS.isHighSpeedAvailable ||
    status === REQUEST_STATUS.isHighSpeedAvailableWithoutCalendarStep,
);

export const getHighSpeedAvailableFlagWithStep = (state) => {
  const statusConnection = getHighSpeedAvailableFlag(state);
  const currentStep = getCurrentFormStep(state);

  if (currentStep === popupSteps.addressRequestForm) {
    return statusConnection ?
        highSpeedVerification.withoutVerification
      : highSpeedVerification.withVerification;
  }

  return highSpeedVerification.withoutVerification;
};

export const getUserPhoneNumbers = (state) => {
  const matched =
    getAddressData(state).userPhoneNumber.match(/(\d{3})(\d{3})(\d{2})(\d{2})/) || emptyArr;

  return `+7 ${matched[1]} ${matched[2]}-${matched[3]}-${matched[4]}`;
};

export const getOrderResult = (state) => getOrderTariffCommonData(state).orderResult || emptyObj;
export const getNumOrder = (state) => getOrderResult(state).numOrder;

export const getCreateOrderDate = (state) => {
  const { date } = getOrderResult(state);
  return format(date, 'DD MMM YYYY, HH:mm', { locale: ru });
};

export const getConnectionAvailableFlag = createSelector(
  [getStatusConnection],
  (status) =>
    status === REQUEST_STATUS.isAvailable ||
    status === REQUEST_STATUS.isHighSpeedAvailable ||
    status === REQUEST_STATUS.isHighSpeedAvailableWithoutCalendarStep ||
    status === REQUEST_STATUS.isExists ||
    status === REQUEST_STATUS.alreadyInUse,
);

export const buildOrderQueryParams = createSelector(
  [getTargetTariffType, getAddressData, getHighSpeedAvailableFlag],
  (tariffData, userData, isHighSpeedAvailable) =>
    getQueryParams({
      inacId: tariffData.inacId,
      street: userData.street.label,
      streetId: userData.street.id,
      house: userData.house.label,
      houseId: userData.house.id,
      flat: userData.flat,
      ...(userData?.userPhoneNumber ? { contactPhone: userData.userPhoneNumber } : {}),
      isHighSpeedAvailable,
    }),
);
