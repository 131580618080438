import { TARIFF_TYPES } from 'pages/FTTB2022/CatalogPage/deprecated/store/blocks/constants';
import { ymOrderTariffAddressView } from 'pages/FTTB2021/components/OrderTariffFormPopup/analytics';

/**
 *
 * @param tariffType
 * @param addressName
 */
export const sendYmOrderTariffAddressView = (tariffType, addressName) => {
  const addressFieldText =
    tariffType === TARIFF_TYPES.presetUp ? 'fmc_address_fill_field' : 'address_fill_field';
  ymOrderTariffAddressView({ [addressFieldText]: addressName }, tariffType);
};
