import React from 'react';
import { FaqPropsInterface } from '@beef/smart-kit';

export interface IWithAnalyticsFAQ extends FaqPropsInterface {
  ym: (s?: string) => void;
}

export function withAnalyticsFAQ(WrappedComponent: React.ComponentType<IWithAnalyticsFAQ>) {
  return ({ onClick, ym, ...props }: IWithAnalyticsFAQ) => {
    const refTabId = React.useRef(props.categories?.[0]?.id);
    const refPanelId = React.useRef('');

    const handleClick = (val: { panelId: string; tabId: string }) => {
      if (val.panelId) {
        refPanelId.current = val.panelId;
        const activeTabData = props.data.find(
          (tab: { categoryId: string }) => tab.categoryId === refTabId.current,
        );
        const activePanelData = activeTabData?.tabs.find(
          // @ts-expect-error unknown
          (tab: { id: string }) => tab.id === val.panelId,
        );
        if (activePanelData) {
          ym(activePanelData.title);
        }
      }
      if (val.tabId) {
        refTabId.current = val.tabId;
        // @ts-expect-error unknown
        const activeTab = props.categories.find((tab: { id: string }) => tab.id === val.tabId);
        if (activeTab) {
          ym(activeTab.title);
        }
      }
      onClick?.(val);
    };

    // @ts-expect-error unknown
    return <WrappedComponent {...props} onClick={handleClick} />;
  };
}
