import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { composeStoreInitializer } from '@beef/redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { commonContentReducer } from './slices/commonContentSlice';
import { tariffServicesReducer } from './slices/tariffServicesSlice';
import { tariffsInfoReducer } from './slices/tariffInfoSlice';
import { stepperReducer } from './slices/stepperSlice';
import { presetsReducer } from './slices/presetsSlice';
import { optionsReducer } from './slices/optionsSlice';
import { totalReducer } from './slices/totalSlice';
import { fttbSliceReducer } from './slices/fttbSlice';
import { fttbAddressReducer } from './slices/fttbAddressSlice';
import { fttbSpecialistVisitReducer } from './slices/fttbSpecialistVisitSlice';

/** Общий reducer ConstructorTariff2023 */
const rootReducer = combineReducers({
  common: commonContentReducer,
  tariffsInfo: tariffsInfoReducer,
  presets: presetsReducer,
  options: optionsReducer,
  tariffServices: tariffServicesReducer,
  total: totalReducer,
  fttbPresets: fttbSliceReducer,
  fttbAddress: fttbAddressReducer,
  stepper: stepperReducer,
  specialistVisit: fttbSpecialistVisitReducer,
});

export const initializeStore = composeStoreInitializer(
  rootReducer,
) as unknown as typeof configureStore<typeof rootReducer>;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ReturnType<typeof initializeStore>['dispatch'];

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
