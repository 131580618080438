import { createSelector } from '@reduxjs/toolkit';

import { selectTotal } from 'constructorV1/store/selectors/selectTotal';
import { selectMapOptions } from 'constructorV1/components/constructorPage/BillContainer/selectors/selectMapOptions';
import { selectBonuses } from 'constructorV1/components/constructorPage/BillContainer/selectors/selectBonuses';

/** Селектор формирования результирующего объекта для компонента Settings ("черный блок") */
export const selectSettingsProps = createSelector(
  selectTotal,
  selectMapOptions,
  selectBonuses,
  (total, activeOptions, bonuses) => {
    /* Выбранные настройки тарифа из total */
    const { gbValue, minValue } = total;

    return {
      /* Параметры Гб/Мин в виде строки */
      presetsText: `${gbValue} гб, ${minValue} мин`,
      /* Выбранные опции тарифа */
      activeOptions,
      /* Объект бонусов по пресетам */
      bonuses,
    };
  },
);
