const ZINDEXES = {
  '2G': 200,
  '3G': 201,
  '4G': 202,
  '4G_Plus': 203,
  NB_IoT: 204,
};
const MARGINS = {
  mobile: { top: 0, left: 0, width: '100%', height: '110px' },
  desktop: { top: 0, left: 0, width: '385px', height: '100%' },
};
const ACCURACY_POINTS = [2.5, 6, 10, 18, 50, 100, 180, 320, 500]; // kilometers

const iconHtml = (name) => `<img src="//static.beeline.ru/upload/images/b2c/maps/${name}" />`;

export default class MapController {
  map = null;

  clusterLayout = null;

  layerCollection = null;

  layers = {};

  init = (callback) => {
    window.ymaps.ready(() => {
      this.map.events.add('boundschange', this.onBoundsChange);
      this.createLayouts();

      this.layerCollection = new window.ymaps.LayerCollection();
      this.map.layers.add(this.layerCollection);

      callback();
    });
  };

  addCoverageLayer = (coverageType) => {
    if (this.layers[coverageType]) return;

    const layer = new window.ymaps.Layer(
      `https://static.beeline.ru/upload/tiles/${coverageType}/current/%z/%x/%y.png`,
      {
        projection: window.ymaps.projection.sphericalMercator,
        tileTransparent: true,
        zIndex: ZINDEXES[coverageType],
        notFoundTile: 'https://static.beeline.ru/upload/images/1x1.png',
      },
    );
    this.layers[coverageType] = layer;
    this.layerCollection.add(layer);
  };

  removeCoverageLayer = (coverageType) => {
    if (!this.layers[coverageType]) return;

    this.layerCollection.remove(this.layers[coverageType]);
    delete this.layers[coverageType];
  };

  createLayouts = () => {
    this.clusterLayout = window.ymaps.templateLayoutFactory.createClass(
      [
        '<div class="ymbPoint ymbPoint-cluster">',
        iconHtml('cluster.svg'),
        '{{ properties.iconContent }}',
        '</div>',
      ].join(''),
    );

    this.pointLayout = window.ymaps.templateLayoutFactory.createClass(
      [
        '<div class="ymbPoint" style="{{ properties.styles }}">',
        iconHtml('{{ properties.iconName }}'),
        '</div>',
      ].join(''),
    );

    this.myPointLayout = window.ymaps.templateLayoutFactory.createClass(
      ['<div class="ymbPoint">', iconHtml('me.svg'), '</div>'].join(''),
    );
  };

  moveToCurrentLocation = (callback) => {
    window.ymaps.geolocation.get().then((result) => {
      const { accuracy, position } = result.geoObjects;
      let zoom = 16;

      for (let index = 0; index < ACCURACY_POINTS.length - 1; index++) {
        if (accuracy < ACCURACY_POINTS[index] * 1000) {
          zoom -= index;
          break;
        }
      }

      this.setCenter(position, zoom);
      callback({
        lat: position[0],
        lon: position[1],
      });
    });
  };

  setMapInstance = (map) => {
    this.map = map;
  };

  setBounds = (lats, lons) => {
    this.map.setBounds([
      [lats[0], lons[0]],
      [lats[1], lons[1]],
    ]);
  };

  setCenter = (coords, zoom) => {
    this.map.setCenter(coords, zoom);
  };

  setMargins = (type) => {
    if (this.marginAccessor) {
      this.marginAccessor.remove();
    }
    this.marginAccessor = this.map.margin.addArea(MARGINS[type]);
  };

  getBounds = (params) => {
    const bounds = this.map?.getBounds(params);

    if (bounds) {
      return [
        [bounds[0][0], bounds[1][0]],
        [bounds[0][1], bounds[1][1]],
      ];
    }
  };

  addEventListener = (type, callback) => {
    this.map.events.add(type, callback);
  };
}
