import { ymPushParams } from 'utils/ym';
import { callOnce } from 'utils/fn';
import { YmElementsViewPusher } from 'utils/analytics/ymElementsViewPusher';

import {
  PROMOTIONS_PAGE_YM_PARAMS,
  YmCardsType,
  YmPayloadType,
  mapPromotionCard,
  wrapPromotionCard,
} from './utils';

export const promotionsCardPusher = new YmElementsViewPusher({
  eventPusher: (cards: any) => {
    const blocks = [...new Set(cards.map(({ block }: YmPayloadType) => block))];
    const isPage = cards.filter(({ isPage: page }: YmPayloadType) => page).length;
    blocks.forEach((block: any) => {
      ymPushParams(
        wrapPromotionCard({
          block,
          type: 'view',
          cards: cards
            .filter((card: YmPayloadType) => card.block === block)
            .map((card: YmPayloadType) => mapPromotionCard(card)),
          isPage,
        }),
      );
    });
  },
  delay: 2000,
});

export const ANALYTICS = {
  MAIN_BANNER_CLICK: {
    sendParams: (payload: YmPayloadType) => {
      ymPushParams(PROMOTIONS_PAGE_YM_PARAMS.MAIN_BANNER_CLICK(payload));
    },
  },
  BOTTOM_BANNER_VIEW: {
    sendParams: callOnce((payload: YmPayloadType) => {
      ymPushParams(PROMOTIONS_PAGE_YM_PARAMS.BOTTOM_BANNER_VIEW(payload));
    }),
  },
  BOTTOM_BANNER_CLICK: {
    sendParams: (payload: YmPayloadType) =>
      ymPushParams(PROMOTIONS_PAGE_YM_PARAMS.BOTTOM_BANNER_CLICK(payload)),
  },
  TAB_CLICK: {
    sendParams: (payload: string) => {
      ymPushParams(PROMOTIONS_PAGE_YM_PARAMS.TAB_CLICK(payload));
    },
  },
  FAQ_VIEW: {
    sendParams: callOnce(() => {
      ymPushParams(PROMOTIONS_PAGE_YM_PARAMS.FAQ_VIEW);
    }),
  },
  FAQ_CLICK: {
    sendParams: (payload: string) => {
      ymPushParams(PROMOTIONS_PAGE_YM_PARAMS.FAQ_CLICK(payload));
    },
  },
  PROMOTION_VIEW: {
    sendParams: ({ block, title, link, isPage }: YmCardsType) => {
      promotionsCardPusher.push({ block, title, link, isPage }, title);
    },
  },
  PROMOTION_CLICK: {
    sendParams: ({ block, title, link, isPage }: YmCardsType) => {
      ymPushParams(PROMOTIONS_PAGE_YM_PARAMS.PROMOTION_CLICK({ block, title, link, isPage }));
    },
  },
  VIEW_ALL_CLICK: {
    sendParams: (payload: string) => {
      ymPushParams(PROMOTIONS_PAGE_YM_PARAMS.VIEW_ALL_CLICK(payload));
    },
  },
};
