import React from 'react';
import classNames from 'classnames/bind';
import { Link, Text } from '@beef/ui-kit';
import { PageFixer } from '@beef/smart-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type ErrorComponentProps = {
  description: string;
  img: string;
  link: string;
  text: string;
  title: string;
};

const ErrorComponent = ({ img, title, description, text, link }: ErrorComponentProps) => (
  <PageFixer size="m">
    <div className={cx('component')}>
      <img alt={`${img}-img`} className={cx('component__img')} height={200} src={img} width={200} />
      <div className={cx('component__title')}>
        <Text size="size2-m">{title}</Text>
      </div>
      <div className={cx('component__description')}>
        <Text color="grey60" size="size5-r-l">
          {description}
        </Text>
      </div>
      {link && (
        <div className={cx('component__link')}>
          <Link buttonSize="m" buttonVariant="main" href={link} isFullWidth variant="button">
            {text}
          </Link>
        </div>
      )}
    </div>
  </PageFixer>
);

export { ErrorComponent };
