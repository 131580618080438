import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Bubble, Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';
import Discount from 'pages-components/Discount';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const BubbleDownsell = ({ icon, text, className }) => (
  <Bubble className={['bottomLeft', className]}>
    <Icon className={cx('icon')} name={icon} />
    <span className={cx('text')} dangerouslySetInnerHTML={{ __html: text }} />
  </Bubble>
);

BubbleDownsell.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
};

/**
 * Сетка для расположения контента в карточке тарифа или услуги.
 * Может принимать как один, так и список дочерних элементов.
 * Выводит в списке друг под другом, разделяя их бордюром.
 */

/**
 * Сетка страницы;
 * --------------
 * @param className - Дополнительный класс;
 * @param subTitle - Подзаголовок, выводится над заголовком;
 * @param burnSubTitle - Меняет цвет заголовка на ораньжевый;
 * @param hideHeadingLine - Скрывает линию под заголовком;
 * @param children - Дочерний элемен;
 * @param title - Заголовок;
 * @param icon - Иконка в заголовке;
 * @param discount - Текст скидки;
 */
const ProductCard = ({
  burnSubTitle,
  hideHeadingLine,
  discount,
  className,
  subTitle,
  children,
  title,
  icon,
}) => (
  <section className={cx('productCard', className)}>
    <div className={cx('productHeading', { withLine: !hideHeadingLine })}>
      {subTitle && (
        <div className={cx('productTypeTitle')}>
          <span
            className={cx('subTitle', burnSubTitle && 'burnSubTitle')}
            dangerouslySetInnerHTML={{ __html: `${subTitle} ` }}
          />
          {discount && (
            <Discount.Label animated className={cx('subTitleDiscountLabel')} label={discount} />
          )}
        </div>
      )}
      {title && (
        <Heading isStyled={false} level={1}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
          {icon && (
            <div className={cx('headingIcon')}>
              <Icon src={icon} />
            </div>
          )}
        </Heading>
      )}
      {children[0]}
    </div>
    <div className={cx('productBody')}>{children[1]}</div>
  </section>
);

ProductCard.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  burnSubTitle: PropTypes.bool,
  hideHeadingLine: PropTypes.bool,
  subTitle: PropTypes.string,
  discount: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
};

/**
 * Сетка параметров продукта;
 * --------------------------
 * @param title - Заголовок блока с сеткой;
 * @param children - Дочернии элементы;
 * @param className - Дополнительный класс;
 * @param parameterNote - Примечание к блоку;
 * @param noItemBorder - не выводить границу у компонента;
 * @param selectedPackagesPrices - цена выбранных пакетов;
 * @param isLayoutYoung - молодежный ли тариф;
 */
const ProductParameters = ({
  title,
  parameterNote,
  selectedPackagesPrices,
  children,
  className,
  noItemBorder,
  isLayoutYoung,
}) => (
  <div className={cx('productParameters', className, isLayoutYoung && 'layoutYoung')}>
    {title && (
      <Heading className={cx('title')} isStyled={false} level={2} tagName="h4">
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Heading>
    )}
    {children.length ?
      <ul className={cx('list', isLayoutYoung && 'layoutYoung')}>
        {children.map((item, index) => (
          <li className={cx('listItem', { noItemBorder })} key={index}>
            {item}
          </li>
        ))}
      </ul>
    : <div className={cx('list')}>
        <div className={cx('listItem')}>{children}</div>
      </div>
    }
    {selectedPackagesPrices > 0 && (
      <div className={cx('selectedPackagesPrices')}>Всё это за {selectedPackagesPrices} ₽</div>
    )}
    {parameterNote && (
      <div className={cx('note')}>
        <div dangerouslySetInnerHTML={{ __html: parameterNote }} />
      </div>
    )}
  </div>
);

ProductParameters.defaultProps = { noItemBorder: false };

ProductParameters.propTypes = {
  title: PropTypes.string,
  parameterNote: PropTypes.string,
  selectedPackagesPrices: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  noItemBorder: PropTypes.bool,
  isLayoutYoung: PropTypes.bool,
};

/**
 * Контейнер для каталога, доступен с отступами и заголовком. Содержит в себе children(контент).
 * Может быть модифицированным доп классом через className
 * @param children - Дочерний компонент;
 * @param title - Заголовок каталога
 * @param subTitle - Подзаголовок;
 * @param level - Тип заголовка
 * @param className - Доп. класс;
 */
const CatalogHeading = ({ children, title, subTitle, level, className }) => (
  <>
    <div className={cx('catalogHeading', className)}>
      <Heading isStyled={false} level={level}>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Heading>
      {subTitle && (
        <div className={cx('subTitle')} dangerouslySetInnerHTML={{ __html: subTitle }} />
      )}
    </div>
    {children}
  </>
);

CatalogHeading.defaultProps = { level: 1 };

CatalogHeading.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  level: PropTypes.number,
  subTitle: PropTypes.string,
};

export default {
  ProductParameters,
  CatalogHeading,
  ProductCard,
};
