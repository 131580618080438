import { createSelector } from '@reduxjs/toolkit';

import {
  selectGbValue,
  selectMinValue,
  selectTotalOptionsSocs,
  selectTotalPrice,
} from 'constructorV1/store/selectors/selectTotal';
import { checkOptionCosts } from 'constructorV1/utils/checkOptionCosts';
import { selectBillContent } from 'constructorV1/store/selectors/selectCommon';
import { selectIsAuthenticated } from 'constructorV1/store/selectors/selectUser';
import { selectOptionsSections } from 'constructorV1/store/selectors/selectTariffSettings';

/** Селектор формирования стоимости выбранных опций/пресетов и доп. платежей */
export const selectTotalAmountProps = createSelector(
  selectBillContent,
  selectTotalPrice,
  selectTotalOptionsSocs,
  selectIsAuthenticated,
  selectGbValue,
  selectMinValue,
  selectOptionsSections,
  (billContent, totalTariffPrice, optionsSocs, isIsAuthenticated, gbValue, minValue, sections) => {
    /* Контентная часть из common */
    const { amountContent, extraAmountContent } = billContent.totalAmountContent;

    /* Расчет стоимости (новая, старая цена) пресетов/опций */
    const { totalPrice, totalOldPrice } = totalTariffPrice;

    /** Флаг определяет выборку нулевых пресетов БЕЗ опций с ценой */
    const isWithoutPay = !gbValue && !minValue && !checkOptionCosts(sections || [], optionsSocs);

    return {
      /* Основные платежи за подключение (стоимость пресетов, опций и доп. опций тарифа) */
      amountProps: {
        /* ОБЩАЯ ЦЕНА ЗА ГБ/МИН/ОПЦИ! */
        price: totalPrice,
        oldPrice: totalOldPrice,
        isWithoutPay,
        ...amountContent,
      },
      /* Доп.платежи за подключение.
       Пока нет понимания, как будем формировать и где будет производиться расчет */
      extraAmountProps: {
        /* Флаг будет определять показ доп. опций.
         Определять этот флаг будем по ответу бека о доп. платежах за тариф */
        isVisible: !isIsAuthenticated && extraAmountContent.price,
        ...extraAmountContent,
      },
    };
  },
);
