import { pushYm } from '../../../tempAnalytics/metricUtils';

/** Функция отправки параметров при клике по табам блока "Домашний интернет и ТВ"
 * В net_speed передаём текст элемента, например, "до 100 Мбит/с" */
export const sendFmcYm = (netSpeed?: string, extraParams: Record<string, string> = {}) => {
  pushYm({
    block_fmc: 'choose_net_package',
    net_speed: netSpeed,
    ...extraParams,
  });
};
