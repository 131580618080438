import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { TBonus } from 'constructorV1/store/tariffSettingsSlice/types';

import { apiChangePacketUrl } from './constants';
import { TCommonRequest } from './types';

/** Тип ответа запроса changePackets пакетов ConstructorV1 */
type TPacket = {
  data: {
    bonuses?: TBonus[];
    constructorId: number;
    price: number;
    priceWithDiscount: number;
  };
};

/** Тип ответа запроса changePackets пакетов ConstructorV1
 *  расширяющий `TPackets` и включающий общие поля запроса */
export type TChangePacketRes = TPacket & TCommonRequest;

/** Запрос на получение мобильных пакетов ConstructorV1 */
export const fetchChangePacket = async (
  config: AxiosRequestConfig,
): Promise<AxiosResponse<TChangePacketRes>> => axios.get(apiChangePacketUrl, config);
