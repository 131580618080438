import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import { Text } from '@beef/ui-kit';
import { Tooltip } from '@beef/legacy-ui-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import SocialButtons from 'pages-components/SocialButtons/SocialButtons';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const EsimDescription = ({ onStoreButtonClick, descriptionList, qrCodeUrl, appButtons }) => {
  const isMobile = useIsMobileDimension();
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    if (qrCodeUrl) {
      QRCode.toDataURL(qrCodeUrl).then((url) => {
        setQrCode(url);
      });
    }
  }, [qrCodeUrl]);

  return (
    <>
      {descriptionList?.length > 0 && (
        <div className={cx('description')}>
          {descriptionList.map(({ text, tooltip }) => (
            <Text color="grey90" key={text} size="size6-r">
              <span dangerouslySetInnerHTML={{ __html: text }} />
              {tooltip && (
                <Tooltip className={cx('tooltip')}>
                  <span dangerouslySetInnerHTML={{ __html: tooltip }} />
                </Tooltip>
              )}
            </Text>
          ))}
        </div>
      )}
      {(qrCode || appButtons) && (
        <div className={cx('links')}>
          {qrCode && !isMobile && (
            <div className={cx('links__section')}>
              <img alt="qr код для подключения есим" src={qrCode} />
            </div>
          )}
          {appButtons && (
            <div className={cx('links__section')}>
              <SocialButtons
                callback={onStoreButtonClick}
                className={cx('app-link')}
                data={appButtons}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

EsimDescription.propTypes = {
  descriptionList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      tooltip: PropTypes.string,
    }),
  ),
  qrCodeUrl: PropTypes.string,
  onStoreButtonClick: PropTypes.func,
  appButtons: PropTypes.object,
};

export default EsimDescription;
