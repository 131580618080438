import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Link } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';

import { isEmptyValue } from '../../../utils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const FiltersMapper = ({ onFilterChange, filtersOptions, appliedFilters }) => {
  const handleFilterChange = useCallback((event) => {
    const {
      target: { checked, name },
    } = event;
    const filterNameInStore = `applied${name}Filter`;

    const payloadObj = {
      [filterNameInStore]: checked,
    };

    onFilterChange(payloadObj);
  }, []);

  if (isEmptyValue(filtersOptions)) {
    return null;
  }

  return (
    <>
      {filtersOptions.map(({ filterSettings }) => {
        if (filterSettings.showFilter === true) {
          return (
            <div className={cx('checkbox')} key={filterSettings.name}>
              <Checkbox
                checked={appliedFilters?.[`applied${filterSettings.name}Filter`] ?? false}
                label={filterSettings.description}
                name={filterSettings.name}
                onChange={(e) => handleFilterChange(e)}
                size="big"
              />

              {!isEmptyValue(filterSettings?.link) && !isEmptyValue(filterSettings?.iconName) && (
                <div className={cx('link-wrap')}>
                  <Link href={filterSettings.link} isNoUnderline target="_blank">
                    <Icon
                      className={cx('checkbox-icon', {
                        'handicapped-icon': filterSettings.iconName === 'handicapped',
                      })}
                      name={filterSettings.iconName}
                    />
                  </Link>
                </div>
              )}

              {isEmptyValue(filterSettings?.link) && !isEmptyValue(filterSettings?.iconName) && (
                <Icon
                  className={cx('checkbox-icon', {
                    'handicapped-icon': filterSettings.iconName === 'handicapped',
                  })}
                  name={filterSettings.iconName}
                />
              )}
            </div>
          );
        }
      })}
    </>
  );
};

FiltersMapper.propTypes = {
  onFilterChange: PropTypes.func,
  filtersOptions: PropTypes.array,
  appliedFilters: PropTypes.object,
};
