import { createAction } from '@reduxjs/toolkit';

import { IPartnerServiceCardState } from 'pages/PartnerServiceCard2022/types';

import { EAction } from '../constants';

type TPartnerProduct = Partial<IPartnerServiceCardState['data']['partnerProduct']>;

export const setPartnerProduct = createAction<TPartnerProduct>(EAction.SetPartnerProduct);
export const setIsConnected = createAction<boolean>(EAction.SetIsConnected);
