import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, path, pathOr } from 'ramda';
import { connect } from 'react-redux';
import { Button, Heading, Preloader, Tab, TabContent, Tabs, TabsList } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { FadeSlide } from 'pages-components/Animations';
import UssdForm from 'pages-components/UssdForm/UssdForm';
import Wizard, { Step } from 'pages-components/Wizard/Wizard';
import ServiceTimeoutPopup from 'pages/ProductCard/components/ServiceTimeoutPopup/ServiceTimeoutPopup';
import { LoginForm, SmsLoginForm } from 'widgets/Authorization';
import SimOrderForm from 'widgets/SimOrderForm/SimOrderForm';
import { sendCvmAnalyticsEvent } from 'utils/analytics/cvmTariffAnalytics';
import { setCookie } from 'utils/cookie';
import SimPurchaseForm from 'pages/ProductCard/components/ServicesExtension/SharingService/AddNumberPopup/SimPurchaseForm';
import { simBuyAction } from 'pages/ProductCard/actions/simBuy';
import { YM_FULL_PARAMS } from 'utils/analytics/offsetLanding';
import { ymSetParams } from 'utils/ym';
import { AUTH_YM_ACTIONS, AUTH_YM_EVENTS, YMAuthEvent } from 'utils/analytics/ymCommonEvents';
import { LoginLinkForm } from 'widgets/Authorization/LoginLinkForm';
import {
  getRegionSearchParams,
  handleRoamingYm,
  roamingYmEventsTypes,
} from 'pages/ProductCard/utils';

import styles from './ServiceConnectPopup.pcss';
import { toggleConfirmPopup } from '../../actions/confirmNumber';
import {
  checkServiceRequestState,
  connectService,
  onConnectResultShown,
  resetPopupResult,
  resetResultPopup,
  resetSmsForm,
  resetToSmsForm,
  sendLogin,
  sendPhone,
  setConnectionResult,
  showWrongRegionPopup,
  toggleServiceConnectionPopup,
  toggleWaitingPopup,
} from '../../actions/serviceConnect';
import WaitingPopup from '../WaitingPopup/WaitingPopup';
import ConfirmNumberPopup from '../ConfirmNumberPopup/ConfirmNumberPopup';
import ConnectServiceForm from './ConnectServiceForm/ConnectServiceForm';
import ConnectedResultPopup from './ConnectResultPopup/ConnectResultPopup';

const cx = classNames.bind(styles);

class ServiceConnectionPopup extends Component {
  state = {
    detailsforlk: false,
  };

  componentDidMount() {
    const renderUrlMatch = window.location.href.match('detailsforlk');
    this.setState({ detailsforlk: !!renderUrlMatch });
  }

  componentDidUpdate(prevProps) {
    const { resultPopupData, onSuccess, alias } = this.props;
    const { previousRegion, currentRegion, needOpenPopup } = getRegionSearchParams();
    if (
      alias &&
      prevProps.alias !== alias &&
      previousRegion &&
      previousRegion !== currentRegion &&
      needOpenPopup
    ) {
      this.props.showWrongRegionPopup?.(previousRegion, alias);
    }
    if (onSuccess && prevProps.resultPopupData !== resultPopupData) {
      if (resultPopupData && Object.keys(resultPopupData).length > 0) {
        onSuccess();
      }
    }
  }

  getCurrentStep = () => {
    let step = 'pending';
    const {
      data,
      confirmOpened,
      resultPopupData,
      isPending,
      waitingPopup,
      serviceProcess,
      mnpPopup,
      error,
    } = this.props;

    if (!isPending && data && Object.keys(data).length > 0) {
      step = 'main';
    }

    if (confirmOpened) {
      step = 'confirm';
    } else if (resultPopupData && Object.keys(resultPopupData).length > 0) {
      step = 'result';
    } else if (waitingPopup.opened || waitingPopup.data?.step === 'pending') {
      step = 'waiting';
    }

    if (serviceProcess === 'timeout') {
      step = 'timeout';
    }

    if (!isEmpty(mnpPopup) && path(['phone', 'type'], error) === 'abonentIsNotBeeline') {
      step = 'mnp';
    }

    return step;
  };

  handleResultClose() {
    const { tariffConnectionPopup, resultPopupData } = this.props;
    const offerType = pathOr(null, ['data', 'type'], tariffConnectionPopup);

    if (
      (resultPopupData &&
        (resultPopupData.type === 'multiOfferOk' || offerType === 'offers') &&
        resultPopupData.buttonUrl &&
        tariffConnectionPopup &&
        tariffConnectionPopup.place === 'downsell') ||
      resultPopupData?.type === 'serviceWrongRegion'
    ) {
      window.location.href = resultPopupData.buttonUrl;
    } else {
      this.props.onResultClose();
      this.props.closePopup();
    }
  }

  handlerOnSubmit = (form, callback) => {
    // -----------------------------------------------------
    // Mark: This code fro AB test. Add cookie for open
    // popup with "?connect=true" and auto connect
    const { autoServiceConnectABtest, ymType } = this.props.service;
    if (autoServiceConnectABtest) {
      const date = new Date();

      setCookie({
        name: 'autoConnectServiceABtest',
        value: 'active',
        ttl: `${date.getDate() + 1}`,
      });
    }
    // -----------------------------------------------------

    handleRoamingYm({
      type: ymType,
      actionType: roamingYmEventsTypes.authFillForm,
      value: form?.login,
    });
    this.props.onSubmitLoginForm(form, callback, ymType);
  };

  onServiceFormConfirm = ({ connectDate, disconnectDate }) => {
    const { detailsforlk } = this.state;
    const { service, ymType } = this.props;

    // CVM Analytics
    const offerData = pathOr(null, ['tariffConnectionPopup', 'data'], this.props);
    if (offerData && offerData.type === 'offers' && offerData.offers.length) {
      sendCvmAnalyticsEvent(
        [
          {
            subgroupId: offerData.offers[0].subgroupId,
            campId: offerData.offers[0].campId,
          },
        ],
        'activation',
        'downsale',
      );
    }
    // -------------

    // CVM Offers Analytics from user from lk
    if (detailsforlk && service.campId && service.subgroupId) {
      sendCvmAnalyticsEvent(
        [
          {
            campId: service.campId,
            subgroupId: service.subgroupId,
          },
        ],
        'activation',
        'offers',
      );
    }
    // ----------------------------------

    this.props.onServiceConnect({ connectDate, disconnectDate, ymType });
  };

  ymAnaliticSend = (val) => {
    const { ymType } = this.props;
    if (val === 'LoginForm') {
      ymSetParams(YM_FULL_PARAMS.YM_POPUP_AUTH_BY_PASS);
    }
    if (val === 'UssdCommandForm') {
      handleRoamingYm({ type: ymType, actionType: roamingYmEventsTypes.phoneClick });
    }
  };

  renderPopup() {
    const {
      activeTab,
      data,
      errorPopupData,
      isPending,
      isSubmitting,
      closePopup,
      tabs,
      form,
      loginLinkForm,
      smsForm,
      step,
      phone,
      submitSmsFormPhone,
      sendSmsAgain,
      error,
      ussdForm,
      serviceConnectForm,
      serviceConnectByDateForm,
      simPurchaseForm,
      ymType,
    } = this.props;

    if (!data) {
      if (!errorPopupData) return <div />;

      return (
        <FadeSlide timeout={{ enter: 150, exit: 100 }}>
          <div className={cx('wrapper')}>
            <Heading className={cx('header')} level={1}>
              <span dangerouslySetInnerHTML={{ __html: errorPopupData.title }} />
            </Heading>
            <p>{errorPopupData.content}</p>
            <Button onClick={closePopup}>{errorPopupData.buttonText}</Button>
          </div>
        </FadeSlide>
      );
    }

    return (
      <div className={cx('wrapper')}>
        <Heading className={cx('header')} level={1}>
          <span dangerouslySetInnerHTML={{ __html: this.props.header || data.header }} />
        </Heading>
        <div className={cx('description')} dangerouslySetInnerHTML={{ __html: data.description }} />
        <Tabs activeTab={activeTab} disable={isPending} onChange={this.ymAnaliticSend}>
          <TabsList className={cx('tabsList', { hidden: tabs.length < 2 })} inline type="calendar">
            {tabs.map((tab) => (
              <Tab className={cx('tabItem')} id={tab.type} key={tab.type}>
                <span className={cx('tabText')} dangerouslySetInnerHTML={{ __html: tab.title }} />
              </Tab>
            ))}
          </TabsList>
          <TabContent className={cx('form')} id="LoginForm">
            {loginLinkForm ?
              <LoginLinkForm {...loginLinkForm} />
            : <LoginForm
                ymType={ymType}
                {...form}
                eventType={
                  this.getCurrentStep() !== 'result' ? AUTH_YM_EVENTS.CONNECT_SERVICE_PASSWORD : ''
                }
                isSubmitting={isSubmitting}
                onSubmit={this.handlerOnSubmit}
              />
            }
          </TabContent>
          <TabContent id="LoginLinkForm">
            {loginLinkForm && <LoginLinkForm {...loginLinkForm} />}
          </TabContent>
          <TabContent className={cx('form')} id="SmsConfirmationForm">
            {smsForm && !isPending ?
              <div>
                <SmsLoginForm
                  error={error}
                  phone={phone}
                  sendSmsAgain={sendSmsAgain}
                  step={step}
                  submitSmsFormPhone={submitSmsFormPhone}
                  {...form}
                  {...smsForm}
                />
              </div>
            : <Preloader />}
          </TabContent>
          <TabContent className={cx('form')} id="UssdCommandForm">
            {ussdForm && <UssdForm description={ussdForm.ussdText} ussd={ussdForm.ussd} />}
          </TabContent>
          <TabContent className={cx({ form: tabs.length > 2 })} id="ServiceConnectForm">
            {serviceConnectForm && !isPending ?
              <ConnectServiceForm
                {...serviceConnectForm}
                onCancel={closePopup}
                onConfirm={this.onServiceFormConfirm}
                showDates={false}
              />
            : <Preloader />}
          </TabContent>
          <TabContent className={cx({ form: tabs.length > 2 })} id="ServiceConnectByDateForm">
            {serviceConnectByDateForm && !isPending ?
              <ConnectServiceForm
                {...serviceConnectByDateForm}
                onCancel={closePopup}
                onConfirm={this.props.onServiceConnect}
                showDates
              />
            : <Preloader />}
          </TabContent>
          <TabContent id="SimPurchaseForm">
            {simPurchaseForm && (
              <SimPurchaseForm onClose={closePopup} onSubmit={simBuyAction} {...simPurchaseForm} />
            )}
          </TabContent>
        </Tabs>
      </div>
    );
  }

  render() {
    const {
      resultPopupData,
      closePopup,
      popupIsActive,
      waitingPopup,
      serviceType,
      phone,
      onNumberConfirmBackClick,
      onResultShown,
      confirmBackLinkText,
      mnpPopup,
    } = this.props;

    const form = this.props.form || {};
    const step = this.getCurrentStep();

    return (
      <div>
        <Wizard
          activeStep={step}
          backLinkText={confirmBackLinkText}
          onClose={closePopup}
          opened={popupIsActive}
        >
          <Step name="main">{this.renderPopup()}</Step>

          <Step
            backLinkText={confirmBackLinkText}
            name="confirm"
            onBackClick={onNumberConfirmBackClick}
          >
            <ConfirmNumberPopup {...form} sendSmsAgain={onNumberConfirmBackClick} />
          </Step>

          <Step
            backLinkText={resultPopupData && resultPopupData.backButtonText}
            name="result"
            onBackClick={() => {
              this.props.onResultBackClick();
              if (resultPopupData?.isWrongRegionError && resultPopupData?.step === 'submitLogin') {
                YMAuthEvent.changeNumberWrongRegion(AUTH_YM_EVENTS.CONNECT_SERVICE_PASSWORD);
              }
            }}
            onClose={() => {
              this.handleResultClose(resultPopupData.closeAction);
              if (resultPopupData?.isWrongRegionError && resultPopupData?.step === 'submitLogin') {
                YMAuthEvent.close(
                  AUTH_YM_EVENTS.CONNECT_SERVICE_PASSWORD,
                  AUTH_YM_ACTIONS.CLOSE_WRONG_REGION,
                );
              }
            }}
          >
            <ConnectedResultPopup
              closePopup={closePopup}
              onButtonClick={() => this.handleResultClose()}
              onMount={onResultShown}
              textVariables={{ number: phone }}
            />
          </Step>

          <Step name="timeout" onClose={closePopup}>
            <ServiceTimeoutPopup onClose={closePopup} type={serviceType} />
          </Step>

          <Step name="pending">
            <Preloader className={cx('plainLoader')} />
          </Step>

          <Step
            backLinkText={mnpPopup?.backButtonText || 'Назад'}
            name="mnp"
            onBackClick={this.props.resetToSmsForm}
          >
            {!isEmpty(mnpPopup) && (
              <SimOrderForm onReset={closePopup} phone={phone} {...mnpPopup} />
            )}
          </Step>

          <Step name="waiting" onClose={closePopup}>
            <WaitingPopup
              data={waitingPopup}
              interval={waitingPopup.interval}
              onButtonClick={this.openServicesUrl}
              onResult={this.props.onWaitingResult}
              onSecondaryButtonClick={this.props.onWaitingClose}
              opened={waitingPopup.opened}
              serviceType={serviceType}
              timeout={waitingPopup.timeout}
              type="services"
            />
          </Step>
        </Wizard>
      </div>
    );
  }
}

ServiceConnectionPopup.defaultProps = {
  activeTab: null,
  data: null,
  error: {},
  errorPopupData: null,
  form: null,
  isPending: false,
  connectedPhone: '+7 ',
  popupIsActive: false,
  tabs: [],
  smsForm: null,
  showSmsForm: false,
  step: 'smsFormPhone',
  mnpPopup: {},
};

ServiceConnectionPopup.propTypes = {
  activeTab: PropTypes.string,
  closePopup: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
  error: PropTypes.shape({}),
  errorPopupData: PropTypes.shape({}),
  form: PropTypes.shape({}),
  isPending: PropTypes.bool,
  phone: PropTypes.string,
  popupIsActive: PropTypes.bool,
  tabs: PropTypes.array,
  sendSmsAgain: PropTypes.func.isRequired,
  smsForm: PropTypes.shape({}),
  step: PropTypes.string,
  submitSmsFormPhone: PropTypes.func.isRequired,
  ussdForm: PropTypes.shape({}),
  serviceConnectForm: PropTypes.shape({}),
  onResultClose: PropTypes.func,
  onWaitingClose: PropTypes.func,
  waitingPopup: PropTypes.shape({}),
  serviceRequestId: PropTypes.number,
  onCheckTariffRequestState: PropTypes.func,
  onServiceConnect: PropTypes.func,
  simOrderForm: PropTypes.shape({}),
  onResultBackClick: PropTypes.func,
  confirmOpened: PropTypes.bool,
  onSubmitLoginForm: PropTypes.func,
  onNumberConfirmClose: PropTypes.func,
  onNumberConfirmBackClick: PropTypes.func,
  onSuccess: PropTypes.func,
  confirmBackLinkText: PropTypes.string,
  serviceConnectByDateForm: PropTypes.shape({}),
  isSubmitting: PropTypes.bool,
  serviceProcess: PropTypes.string,
  service: PropTypes.shape({}),
  mnpPopup: PropTypes.shape({
    use: PropTypes.bool,
    content: PropTypes.shape({}),
    backButtonText: PropTypes.string,
  }),
  tariffConnectionPopup: PropTypes.shape({}),
  resultPopupData: PropTypes.shape({}),
  simPurchaseForm: PropTypes.shape({}),
  header: PropTypes.string,
  serviceType: PropTypes.string,
  resetToSmsForm: PropTypes.func,
  onWaitingResult: PropTypes.func,
  onResultShown: PropTypes.func,
  connectedPhone: PropTypes.string,
  showSmsForm: PropTypes.bool,
  showWrongRegionPopup: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const connectionPopup = pathOr({}, ['external', 'serviceConnectionPopup'], state);
  let tabs = props.tabs || path(['data', 'activationTypes'], connectionPopup);
  const activeTab = tabs && tabs.find((tab) => tab.IsActive);
  const confirmPopup = pathOr({}, ['external', 'confirmNumberPopup'], state);

  if (props.simPurchaseForm) {
    tabs = [...(tabs || []), props.simPurchaseForm.tabContent];
  }

  // Параметры необходимы для флага функционала КО мультиоффера, должен быть редирект при
  // закрытии модального окна результата по клику на крестик;
  const tariffConnectionPopup = pathOr({}, ['external', 'tariffConnectionPopup'], state);

  return {
    ...connectionPopup,
    tariffConnectionPopup,
    activeTab: pathOr(props.defaultTab || activeTab?.type, ['activeTab'], connectionPopup),
    form: path(['data', 'loginForm'], connectionPopup),
    loginLinkForm: path(['data', 'loginLinkForm'], connectionPopup),
    smsForm: path(['data', 'smsConfirmationForm'], connectionPopup),
    ussdForm: path(['data', 'ussdCommandForm'], connectionPopup),
    simPurchaseForm: props.simPurchaseForm && props.simPurchaseForm.formData,
    service: pathOr({}, ['external', 'service'], state),
    serviceConnectForm: path(['data', 'serviceConnectForm'], connectionPopup),
    serviceConnectByDateForm: path(['data', 'serviceConnectByDateForm'], connectionPopup),
    waitingPopup: pathOr({}, ['waitingPopup'], connectionPopup),
    mnpConfirmPopup: pathOr({}, ['data', 'mnpConfirmPopup'], connectionPopup),
    tabs,
    confirmOpened: confirmPopup.service,
    confirmBackLinkText: confirmPopup.backButtonText,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    dispatch(resetPopupResult());
    dispatch(resetSmsForm());
    dispatch(toggleServiceConnectionPopup(false));
  },
  sendSmsAgain: () => dispatch(resetSmsForm()),
  resetToSmsForm: () => dispatch(resetToSmsForm),
  submitSmsFormPhone: (data) => dispatch(sendPhone(data)),
  onResultClose: () => dispatch(resetResultPopup()),
  onWaitingClose: () => dispatch(toggleWaitingPopup(false)),
  onCheckTariffRequestState: (requestId, isFinalCheck) =>
    dispatch(checkServiceRequestState(requestId, isFinalCheck)),
  onServiceConnect: ({ connectDate, disconnectDate }) =>
    dispatch(connectService({ connectDate, disconnectDate })),
  onNumberConfirmClose: () => dispatch(toggleConfirmPopup('service', false)),
  onNumberConfirmBackClick: () => {
    dispatch(toggleConfirmPopup('service', false));
    dispatch(toggleServiceConnectionPopup(true));
  },
  onResultBackClick: () => {
    dispatch(resetResultPopup());
    dispatch(toggleServiceConnectionPopup(true));
  },
  onSubmitLoginForm: (form, callback, ymType) => dispatch(sendLogin(form, callback, ymType)),
  onWaitingResult: (data) => dispatch(setConnectionResult(data)),
  onResultShown: () => dispatch(onConnectResultShown()),
  showWrongRegionPopup: (previousRegion, serviceAlias) =>
    dispatch(showWrongRegionPopup(previousRegion, serviceAlias)),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(ServiceConnectionPopup);

export const createConnectPopup = (stateToProps, dispatchToProps) =>
  connect(
    (state, ownProps) => ({
      ...mapStateToProps(state, ownProps),
      ...(stateToProps && stateToProps(state, ownProps)),
    }),
    (dispatch, ownProps) => ({
      ...mapDispatchToProps(dispatch, ownProps),
      ...(dispatchToProps && dispatchToProps(dispatch, ownProps)),
    }),
  )(ServiceConnectionPopup);

export default ConnectedComponent;
