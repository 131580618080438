import { useEffect, useState } from 'react';

// Constants
import { ymOrderTariffAddressView } from 'pages/FTTB2021/components/OrderTariffFormPopup/analytics';
import { TARIFF_TYPES } from 'pages/FTTB2022/CatalogPage/deprecated/store/blocks/constants';

import { sendYmOrderTariffAddressView } from './helpers';
import { REQUEST_STATUS } from '../../../../../../constants';

/**
 * usePrepareHandlers: хук  формирует функции изменения инпутов Request-формы.
 * @param house
 * @param street
 * @param regionLabel
 * @param setChosenFlat
 * @param setChosenHouse
 * @param setChosenStreet
 * @param setAddressChecked
 * @param userSettingsTariff
 * @param resetSettingsTariff
 * @param currentStatusConnection
 */

export const usePrepareHandlers = ({
  house,
  street,
  regionLabel,
  setChosenFlat,
  setChosenHouse,
  setChosenStreet,
  setAddressChecked,
  userSettingsTariff,
  resetSettingsTariff,
  currentStatusConnection,
  tariffType,
}) => {
  const [houseValue, setHouseValue] = useState('');
  const [streetValue, setStreetValue] = useState('');
  const hasSettingsTariff = !!Object.keys(userSettingsTariff).length;
  const { isNone, isNotAvailable, streetIsNotFound, regionIsNotConnect } = REQUEST_STATUS;
  const incorrectRegion = currentStatusConnection === regionIsNotConnect;

  const onClearFlat = () => {
    setChosenFlat('');
  };

  /**
   * onChangeFlat: получение значения номера квартыры.
   * @param e
   */
  const onChangeFlat = (e) => {
    setChosenFlat(e.target.value);
    const addressFieldText =
      tariffType === TARIFF_TYPES.presetUp ? 'fmc_address_fill_field' : 'address_fill_field';
    ymOrderTariffAddressView({ [addressFieldText]: 'appartment' }, tariffType);
  };

  /**
   * handleChangeHouseInput: управление инпутом номера дома.
   * @param value
   */
  const handleChangeHouseInput = (value) => {
    setHouseValue('');
    setChosenHouse('');
    setChosenFlat('');

    if (value) {
      setHouseValue(value);
      setChosenHouse(houseValue);
      sendYmOrderTariffAddressView(tariffType, 'street');
    }
  };

  /**
   * handleChangeStreetInput: управление инпутом улицы
   * @param value
   */
  const handleChangeStreetInput = (value) => {
    const isDefault = [isNotAvailable, streetIsNotFound]?.includes(currentStatusConnection);
    if (isDefault) {
      setAddressChecked(isNone);
    }

    setStreetValue('');
    setChosenStreet('');
    handleChangeHouseInput('');
    setChosenFlat('');

    if (value) {
      setStreetValue(value);
      setChosenStreet(streetValue);
      sendYmOrderTariffAddressView(tariffType, 'street');
    }
  };

  useEffect(() => {
    if ((!street.label || !house.label) && hasSettingsTariff) {
      resetSettingsTariff();
    }
  }, [house, street, hasSettingsTariff]);

  useEffect(() => {
    if (!regionLabel || !street.label) {
      setStreetValue('');
      setHouseValue('');
    }
  }, [regionLabel]);

  return {
    houseValue,
    streetValue,
    onClearFlat,
    onChangeFlat,
    incorrectRegion,
    handleChangeHouseInput,
    handleChangeStreetInput,
  };
};
