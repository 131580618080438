import React, { FC } from 'react';

import { TariffInfoTiles } from '../TariffInfoTiles';
import { useCheckShowTiles } from './hooks/useCheckShowTiles';

/** Обертка над TariffInfoTiles в рамках единого конструктора
 *  определяет необходимость показа плиток TariffInfoTiles */
export const TariffInfoTilesContainer: FC = () => {
  const isShowInfoTiles = useCheckShowTiles();

  return isShowInfoTiles ? <TariffInfoTiles /> : null;
};
