import { MouseEvent, MutableRefObject, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
// TODO: после выноса ServiceTile в пакет, не забыть поменять импорт!
import { TServiceTile } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/ServiceTile/types';

import { useAppSelector } from '../../../../../../store';
import { TModalHeader } from '../../../../../modalComponents/ModalHeader';
import { setToggleService } from '../../../../../../store/slices/totalSlice';
import { TModalContainer } from '../../../../../modalComponents/ModalContainer';
import { useOnScreenAnalytics } from '../../../../../../tempAnalytics/hooks/useOnScreenAnalytics';
import { TServiceType, getYmPayload, sendPopupYm, sendYm } from '../../../tempYmUtils';
import { prepareServiceTile } from '../../../../../../utils/prepareServiceTile';
import { defineChildren } from '../../../../../../utils/defineChildren';

/** Хук готовит данные для плитки сервиса (ServiceTile), и попапа к ним. */
export const usePrepareServiceTails = (
  service: TServiceTile,
  serviceRef: MutableRefObject<HTMLDivElement | null>,
  type?: TServiceType,
) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobileDimension();
  const servicesInacIdList = useAppSelector(
    (state) => state.total.convergenceInfo?.servicesInacIdList,
  );
  const [isOpen, setIsOpen] = useState(false);

  /* Извлекаем контентные данные */
  const { inacId = '', variant, contentPopup } = service || {};
  const {
    link = '',
    image,
    title,
    extraLink,
    buttonText,
    description,
    detailsInfo,
    extraButtonText,
  } = contentPopup || {};
  const currentVariant = isMobile ? 'vertical' : variant;

  /** Функция закрытия попапа плитки ServiceTile */
  const onClose = () => setIsOpen(false);

  const serviceTile = prepareServiceTile(service);

  /* Отправка аналитики если блок видим больше 2х секунд */
  useOnScreenAnalytics({
    ref: serviceRef,
    payload: getYmPayload(serviceTile.title, type),
  });

  /** Функция переключения состояния модалки плитки ServiceTile (open/close) */
  const handleClick = (
    e?: MouseEvent<HTMLAnchorElement | HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e?.preventDefault();
    setIsOpen((state) => {
      /* Отправка аналитики при показе модалки */
      if (!state) {
        sendYm(serviceTile.title, type);
      }
      return !state;
    });
  };

  /** Функция закрытия модалки плитки ServiceTile (close) */
  const handleClose = (
    e?: MouseEvent<HTMLAnchorElement | HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e?.preventDefault();
    setIsOpen(false);
    /* Отправка аналитики при клике на "Понятно" модалки */
    sendPopupYm(serviceTile.title, 'confirm', type);
  };

  /* Обработчик клика по чекрыжу (переключения сервса в активны/не активный) */
  const handleSwitchClick = () => dispatch(setToggleService(inacId));

  /* Определяем наличие флага isActive в объекте service.
 Если он присутствует, показываем "чекрыжик" */
  const showSwitch = service.isActive !== undefined;

  /* Пропы для компонента плитки сервиса (ServiceTile) */
  const serviceTileProps = {
    ...serviceTile,
    showSwitch,
    handleClick,
    handleSwitchClick,
    variant: currentVariant,
    isActive: servicesInacIdList?.includes(inacId),
  };

  /* Пропы для компонента ChildrenContainer.
  В зависимости от результата работы функции defineChildren вернет ту или иную структуру
   для отображения варианта карточки сервиса */
  const childrenContainerProps = { ...defineChildren(service) };

  /* Функция отправки аналитики при клике на extra кнопку модалього окна */
  const handleExtraPushYm = () => {
    sendPopupYm(serviceTile.title, 'details', type);
  };

  /* Проп для ModalContainer. Содержит текущее состояние модалки, размер и т.п. */
  const modalContainerProps: Omit<TModalContainer, 'children'> = {
    isOpen,
    size: 'm',
    onClose,
    modalActionBarProps: {
      link,
      extraLink,
      buttonText,
      buttonTextExtra: extraButtonText,
      onClick: !link ? handleClose : undefined,
      onClickExtra: !extraLink ? handleClick : handleExtraPushYm,
    },
  };

  /* Проп для контентной части модалки. Содержит картинку, заголовок и описание. */
  const modalHeaderProps = {
    image,
    title,
    description,
    detailsInfo,
  } as TModalHeader;

  return {
    serviceTileProps,
    modalHeaderProps,
    modalContainerProps,
    childrenContainerProps,
  };
};
