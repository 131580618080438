import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Button, PhoneInput, SmartForm } from '@beef/legacy-ui-kit';

import Captcha from 'pages/Feedback/components/Captcha/Captcha';

import LegalWithTooltip from '../../../../../LegalWithTooltip';
import styles from '../../styles.pcss';

const cx = classNames.bind(styles);

export const RenderRequestFrom = ({ data }) => {
  const {
    phoneInputValue,
    changePhone,
    texts,
    showCaptcha,
    reCaptchaModel,
    onPhoneFocus,
    onBackCallBtnClick,
    phoneInputStatus,
  } = data;

  return (
    <SmartForm
      className={cx('wrapper', 'requestForm')}
      onSubmit={onBackCallBtnClick}
      validator={{
        captchaInput: (value) => ({
          status: value.length < 5 ? 'fail' : 'ok',
          msg: value.length < 5 ? 'Пройдите капчу' : '',
        }),
      }}
    >
      {showCaptcha && (
        <div className={cx('reCaptchaWrapper')}>
          <Captcha {...reCaptchaModel} inputId="captchaInput" />
        </div>
      )}
      <div className={cx('container')}>
        <div className={cx('phoneLabel')} dangerouslySetInnerHTML={{ __html: texts.phoneLabel }} />
        <div className={cx('phoneInputWrapper')}>
          <PhoneInput
            error={phoneInputStatus === 'fail' ? texts.wrongPhone : ''}
            keepErrorIndent={false}
            onChange={changePhone}
            onFocus={onPhoneFocus}
            placeholder={null}
            size="big"
            status={phoneInputStatus}
            value={phoneInputValue}
          />
          <div
            className={cx('phoneInputDescription')}
            dangerouslySetInnerHTML={{ __html: texts.phoneInputDescription }}
          />
        </div>
        <div className={cx('backCallBtnWrapper')}>
          <Button className={['big', cx('backCallBtn')]} transformer type="submit">
            <span dangerouslySetInnerHTML={{ __html: texts.backCallBtnText }} />
          </Button>
        </div>
      </div>
      <LegalWithTooltip termsOfUse={texts.termsOfUse} userAgreementText={texts.userAgreementText} />
    </SmartForm>
  );
};

RenderRequestFrom.propTypes = {
  data: PropTypes.shape({
    onPhoneFocus: PropTypes.func,
    onBackCallBtnClick: PropTypes.func,

    phoneInputValue: PropTypes.string,
    changePhone: PropTypes.func,
    showCaptcha: PropTypes.bool,
    captchaToken: PropTypes.string,
    reCaptchaModel: PropTypes.shape({
      captchaKey: PropTypes.string,
      refreshCaptcha: PropTypes.number,
      setCaptchaKey: PropTypes.func,
      onChange: PropTypes.func,
    }),
    phoneInputStatus: PropTypes.string,

    texts: PropTypes.shape({
      phoneLabel: PropTypes.string,
      wrongPhone: PropTypes.string,
      phoneInputDescription: PropTypes.string,
      backCallBtnText: PropTypes.string,
      userAgreementText: PropTypes.string,
      termsOfUse: PropTypes.string,
    }),
  }),
};
