import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { connect } from 'react-redux';
import { Card } from '@beef/smart-kit';
import { Skeleton } from '@beef/ui-kit';

import { LoadingWrap } from 'pages-components/LoadingWrap';

import { getCurrentRandomNumber } from '../../../store/selectors/numbers';
import { NumberShape } from '../../../shapes';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

const RandomNumberWrapLayout = ({ children, number }) => (
  <LoadingWrap
    className={cx('random_wrap')}
    isLoading={!number}
    preloadLayout={<Skeleton className={cx('preload')} />}
  >
    {number && <Card className={cx('random')}>{children}</Card>}
  </LoadingWrap>
);

RandomNumberWrapLayout.propTypes = {
  children: PropTypes.node,
  number: PropTypes.shape(NumberShape),
};

export const RandomNumberWrap = connect((state) => ({
  number: getCurrentRandomNumber(state),
}))(RandomNumberWrapLayout);
