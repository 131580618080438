import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Emoji, Heading } from '@beef/legacy-ui-kit';

import styles from './StatusBlock.pcss';

const cx = classNames.bind(styles);

const StatusBlock = ({ title, content, status }) => (
  <div className={cx('layout')}>
    <div className={cx('mainBlock')}>
      <div className={cx('content')}>
        <Heading className={cx('header')} level={2}>
          {title}
          <Emoji className={cx('emoji')} name={status} />
        </Heading>
        <div className={cx('description')} dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  </div>
);

StatusBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  status: PropTypes.string,
};

export default StatusBlock;
