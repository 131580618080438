import axios from 'axios';
import { path, pathOr } from 'ramda';

import store from 'store';
import { transformRequest } from 'utils/globalAjaxHandler';
import { unformatPhone } from 'utils/format-string';
import generateGUID from 'utils/guid-generator';

import { SET_AUTHORIZATION_POPUP_VALUE } from './actionsTypes';

const setPopupValue = (data) => ({
  type: SET_AUTHORIZATION_POPUP_VALUE,
  payload: data,
});

export const resetSmsForm = () => setPopupValue({ step: 'smsFormPhone', error: {} });

const getAuthorizationPopupData = () => {
  const popupData = pathOr({}, ['external', 'authorizationPopup'], store.getState());

  return axios({
    url: `${popupData.connectPopupUrl}?alias=${popupData.alias}${
      popupData.connect ? `&connect=${encodeURIComponent(popupData.connect)}` : ''
    }`,
    method: 'post',
    data: {},
    responseType: 'json',
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  });
};

export function toggleAuthorizationPopup(popupIsActive = false) {
  return async (dispatch) => {
    dispatch(setPopupValue({ popupIsActive }));

    if (popupIsActive) {
      dispatch(setPopupValue({ isPending: true, data: null }));

      try {
        const { data } = await getAuthorizationPopupData();
        dispatch(
          setPopupValue({
            data: (data.isSucceeded && data.view) || null,
            isPending: false,
          }),
        );
      } catch (err) {
        dispatch(setPopupValue({ data: null, isPending: false }));
      }
    }
  };
}

export const getAuthErrorByCode = (errorCode) => {
  switch (errorCode) {
    case 'NEED_CAPTCH': {
      return {
        step: 'smsFormWithCaptcha',
        error: {
          type: 'needCaptcha',
          msg: 'Введите символы с картинки',
        },
      };
    }

    case 'ERROR_CAPTCH': {
      return {
        step: 'smsFormWithCaptcha',
        error: {
          type: 'needCaptcha',
          msg: 'Введите символы с картинки',
        },
      };
    }

    case 'AUTH_ABANDONBO_ERROR': {
      return {
        step: 'smsFormPhone',
        error: {
          type: 'server',
          msg: 'Доступ в кабинет ограничен',
        },
      };
    }

    case 'AUTH_MAX_TOKENS_BY_PERIOD': {
      return {
        step: 'smsFormPhone',
        error: {
          type: 'authMaxToken',
          msg: `Вы превысили максимальное количество попыток получение одноразового пароля,
              попробуйте повторить позднее.`,
        },
      };
    }

    case 'CTN_NOT_FOUND': {
      return {
        step: 'smsFormPhone',
        error: {
          phone: {
            type: 'abonentIsNotBeeline',
            msg: 'Только номера «билайн»',
            status: 'fail',
          },
        },
      };
    }

    case 'OTHER_REGION_CTN': {
      return {
        step: 'smsFormPhone',
        error: {
          phone: {
            type: 'abonentIsNotBeeline',
            msg: 'Необходимо ввести номер абонента «билайн» своего региона.',
          },
        },
      };
    }

    default: {
      return {
        step: 'smsFormPhone',
        error: {
          type: 'server',
          msg: 'Что-то пошло не так, попробуйте повторить позднее.',
        },
      };
    }
  }
};

export const sendPhone =
  ({ phone, captcha, captchaKey }) =>
  async (dispatch) => {
    const url = path(
      ['external', 'authorizationPopup', 'data', 'smsConfirmationForm', 'oneTimePasswordSmsUrl'],
      store.getState(),
    );

    const login = unformatPhone(phone);
    try {
      await axios.get(url, {
        params: { login, hash: generateGUID(), captcha, captchaKey },
        transformRequest,
      });
      dispatch(setPopupValue({ step: 'smsForm', phone }));
    } catch ({ response }) {
      const code = response && response.data && response.data.ErrorCode;

      dispatch(setPopupValue({ ...getAuthErrorByCode(code), phone }));
    }
  };
