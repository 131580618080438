import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@beef/ui-kit';

import { AUTH_STEPS /* , LOGIN_TYPES */ } from '../../actions/constants';

// пересмотреть не используется ли это еще где в адаптиве и можно убрать

const StandardLinks = (props) => {
  const { step, /* switchForm, loginType, */ resetPassword } = props;
  const data = props.data || {};
  const links = data.links || {};
  const { /* getOneTimePassword, enterByPassword, */ getPassword } = links;

  if (step === AUTH_STEPS.password) {
    return (
      <Link href="#" isNoUnderline onClick={resetPassword} size="size5-b">
        {getPassword}
      </Link>
    );
  }

  return null;
};

StandardLinks.propTypes = {
  data: PropTypes.shape({}),
  step: PropTypes.oneOf(Object.keys(AUTH_STEPS).map((key) => AUTH_STEPS[key])),
  // loginType: PropTypes.oneOf(Object.keys(LOGIN_TYPES).map((key) => LOGIN_TYPES[key])),
  // switchForm: PropTypes.func,
  // recoverPasswordUrl: PropTypes.string,
};

export default StandardLinks;
