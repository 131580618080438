import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import Icon from 'pages-components/Icon';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const BUTTONS_TYPES = ['rightArrow', 'leftArrow'];

const SliderArrows = ({ activeArrow, onClick }) => (
  <>
    {BUTTONS_TYPES.map((item) => (
      <div className={cx('arrow', item, activeArrow[`${item}`] && 'show')} key={item}>
        <EmptyButton
          aria-label="Подвинуть слайдер"
          className={cx('arrowButton')}
          onClick={() => onClick(item)}
        >
          <Icon name="dropdownArrow" />
        </EmptyButton>
      </div>
    ))}
  </>
);

SliderArrows.propTypes = {
  activeArrow: PropTypes.object,
  onClick: PropTypes.func,
};

export default SliderArrows;
