import { emptyObj } from '@beef/utils';
import { createSelector } from '@reduxjs/toolkit';

import { USER_TYPES } from 'pages/FTTB2022/CatalogPage/deprecated/store/constants';

import { TBlockTypeSelector, getSharedData } from '../selectors';
import type { RootState as State, TPageSettings, TUserData } from '../types';

export const getBreadcrumbsData = (state: State) => getSharedData(state).breadcrumbs;
export const getCallbackRequestData = (state: State) => getSharedData(state).callbackRequestData;
export const getPageSettings = (state: State) =>
  getSharedData(state).pageSettings || (emptyObj as TPageSettings);
export const getSelfCallingPopupData = (state: State) => getSharedData(state).selfCallingPopupData;
export const getUserData = (state: State) =>
  getSharedData(state).userData || (emptyObj as TUserData);

export const getPageType = (state: State) => getPageSettings(state).pageType;
export const getActiveCtn = (state: State) => getUserData(state).activeCTN || '';
export const getUserType = (state: State) => getUserData(state).userType;
export const getIsAuthFlag = (state: State) => getPageSettings(state).isAuth;

export const isUserFttb: TBlockTypeSelector<boolean> = createSelector(
  [getUserType],
  (type) => type === USER_TYPES.FTTB_USER,
);

export const isUserFms: TBlockTypeSelector<boolean> = createSelector(
  [getUserType],
  (type) => type === USER_TYPES.FMS_USER,
);
