import React from 'react';
import classNames from 'classnames/bind';
import { useGetHeaderHeight } from '@carnica/smart-kit';
import { useIsMobileDimension } from '@beef/ui-kit';

import { HEADER_DESKTOP_DEFAULT_HEIGHT, HEADER_MOBILE_DEFAULT_HEIGHT } from '../../hooks/constants';
import styles from './styles.pcss';
import { IScreen } from './types';

const cx = classNames.bind(styles);

export const Screen: React.FC<IScreen> = ({
  isActive,
  children,
  isDark,
  transitionDuration = 0.5,
}) => {
  const headerHeight = useGetHeaderHeight();
  const isMobile = useIsMobileDimension();
  const defaultHeight = isMobile ? HEADER_MOBILE_DEFAULT_HEIGHT : HEADER_DESKTOP_DEFAULT_HEIGHT;

  return (
    <section
      className={cx('screen', isActive && 'active', isDark && 'dark')}
      style={{
        paddingTop: `${headerHeight || defaultHeight}px`,
        transitionDuration: `${transitionDuration}s`,
        transitionDelay: isActive ? `${transitionDuration}s` : undefined,
      }}
    >
      {children}
    </section>
  );
};
