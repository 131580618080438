import { translate } from 'utils/format-string';

const floorToTenth = (number) => Math.floor(number * 10) / 10;

export const getSecondsString = (value, whole) =>
  `${Math.floor(value / 60)} ${whole ? translate('minGenetive', Math.floor(value / 60)) : 'мин.'}`;

export const getKbyteString = (value, whole) =>
  value >= 1048576 ?
    `${floorToTenth(value / 1048576)} ${whole ? translate('gbGenetive', Math.floor(value / 1048576)) : 'ГБ'}` // eslint-disable-line
  : value >= 1024 ? `${Math.floor(value / 1024)} Мб`
  : `${value} Кб`;
