import React, { FC } from 'react';

import { PageHeader } from '../../../../commonComponents/PageHeader';
import { useSelectPreChangeProps } from './selectors/useSelectPreChangeProps';

type TPreChangesHeader = {
  isShowTitle: boolean;
};

/** Обертка над заголовком и кнопокй "назад" (на страницу редактирования адреса подключения ШПД) */
export const PreChangesHeader: FC<TPreChangesHeader> = ({ isShowTitle }) => {
  const titleContent = useSelectPreChangeProps();
  const titleContentProps = isShowTitle ? titleContent : {};
  return <PageHeader {...titleContentProps} />;
};
