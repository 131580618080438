import React, { ReactNode, memo } from 'react';
import classNames from 'classnames/bind';
import { PageFixer } from '@beef/smart-kit';
import { Text } from '@beef/ui-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type HeadingProps = {
  actions: ReactNode;
  breadcrumbs: ReactNode;
  description: string;
  title: string;
};

export const Heading = memo<HeadingProps>(
  ({ breadcrumbs, actions, title, description }: HeadingProps) => {
    const isPhone = useIsMobileDimension();
    const isTablet = !isPhone;
    const PageFixerMobileComponent = isTablet ? React.Fragment : PageFixer;

    return (
      <div className={cx('heading-area')}>
        <PageFixerMobileComponent backgroundCustomColor="transparent">
          <div className={cx('heading')}>
            <div className={cx('breadcrumbs')}>{breadcrumbs}</div>
            <div className={cx('heading-content')}>
              <Text size="size0-b">{title}</Text>
              <Text color="grey60" size="size4-r">
                {description}
              </Text>
              <div className={cx('buttons')}>{actions}</div>
            </div>
          </div>
        </PageFixerMobileComponent>
      </div>
    );
  },
);
