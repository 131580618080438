import { withMountListener } from '@beef/react';
import { compose } from '@beef/redux';
import { PageFixer } from '@beef/smart-kit';
import React from 'react';
import { connect } from 'react-redux';

import { PartnerPopup } from './components/PartnerPopup';
import {
  PartnerServiceCardMergeState,
  mapDispatchToProps,
  mapStateToProps,
  mergeProps,
  store,
} from './store';
import { Benefits } from './components/Benefits';
import { ContentBlockWithMedia } from './components/ContentBlockWithMedia';
import { ExtendedStepsBlock } from './components/ExtendedStepsBlock';
import { FAQContainer } from './components/FAQContainer';
import { HeadBlock } from './components/HeadBlock';
import { Subscriptions } from './components/Subscriptions';

const PartnerServiceCard = (props: PartnerServiceCardMergeState) => {
  const { modal, popupProps, closeModal, confirmModal, ...data } = props;
  return (
    <>
      <HeadBlock {...data.headBlockData} />

      <PageFixer size="m">
        {data.hasBenefits && <Benefits {...data.benefitsData} />}
        {data.hasMedia &&
          data.mediaBlockData.map((mediaBlockData) => (
            <ContentBlockWithMedia {...mediaBlockData} />
          ))}
        {data.hasYandexBenefits && <Benefits {...data.yandexBenefitsData} />}
        {data.hasSubscriptions && <Subscriptions {...data.subscriptionsBlockData} />}
        {data.hasExtendedData && <ExtendedStepsBlock {...data.extendedBlockData} />}
        {data.hasFaq && <FAQContainer {...data.faqBlockData} />}
      </PageFixer>

      {modal.isModalOpen && (
        <PartnerPopup
          isLoading={modal.isLoading}
          isModalOpen={modal.isModalOpen}
          onCancel={closeModal}
          onConfirm={confirmModal}
          {...popupProps}
        />
      )}
    </>
  );
};

const ConnectedPartnerServiceCard = compose(
  store,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withMountListener,
)(PartnerServiceCard);
export { ConnectedPartnerServiceCard as PartnerServiceCard2022 };
