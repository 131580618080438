import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Checkbox, Heading, Link, Preloader, Status, TextInput } from '@beef/legacy-ui-kit';
import { SwitchTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames/bind';

import { validateMaskedPhone } from 'utils/validators';
import { isMobileDevice } from 'utils/device';
import {
  pushFTTBOrder,
  pushFloatingCheckAction,
  pushFullFormPopupAction,
  pushTVEUpsaleBlockAction,
} from 'utils/ga';
import { debounce } from 'utils/timed-functions';
import { templateParser } from 'utils/format-string';
import { connectionDataPropTypes, currentStateList as currentStates } from 'pages/FTTB/constants';
import createOrder from 'pages/FTTB/services/createOrder';
import * as checkAddressHelper from 'pages/FTTB/utils/checkAddressHelper';
import {
  sendSuccessCallbackLongFormAnalitics,
  successfullFTTBFormSubmissionMT,
  successfullFTTBFormSubmissionVK,
} from 'utils/analytics/pixelAnalytics';
import { fetchSpecialTariffByHouse as fetchSpecialTariffByHouseAction } from 'pages/FTTB/actions';
import {
  getCreateOrderData,
  mapProductDataFromPromo,
} from 'pages/FTTB/utils/createOrderRequestHelper';
import {
  getConnectionData,
  isInitialLoadConnectionData,
} from 'pages/FTTB/selectors/connectionState';
import {
  getSpecialTariff,
  isSpecialTariffAvailable,
  isSpecialTariffFetched,
} from 'pages/FTTB/selectors/specialTariff';
import CheckAddressHOC from 'pages/FTTB/hoc/CheckAddressHOC';
import OrderBackCall from 'pages/FTTB/StreetCardPage/components/OrderBackCall';

import CheckAddressForm from '../CheckAddressForm';
import BuyTVEUpsale from '../BuyTVEUpsale';
import LegalWithTooltip from '../LegalWithTooltip';
import BigButton from '../BigButton';
import ChooseDateTime from '../ChooseDateTime';
import SuccessFlatConnection from './components/SuccessFlatConnection';
import OrderBtnSection from './components/OrderBtnSection';
import HaveQuestions from './components/HaveQuestions';
import TariffInfo from './components/TariffInfo';
import { SlideDown, SlideDownAndFade, ZoomIn } from './components/Animations';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const defaultConnectingRequestUrl = '/customers/products/home/request/createorder/';

const gaTypes = {
  address: 'checkAddress',
  order: 'fttbOrder',
};

class OrderTariffForm extends PureComponent {
  state = {
    currentState: currentStates.requestForm,
    houseConnectionState: currentStates.none,
    flatConnectionState: currentStates.none,
    // Может быть null, 'street', 'house'
    notFoundAddress: null,
    phoneInput: '+7 ',
    phoneInputStatus: 'ok',
    // Значение может быть 'blur' или 'focus'
    flatInputState: 'blur',
    showSuccessFlatConnection: false,
    hasRequestOnAddress: false,
    callCenterWorkStartTime: '09:00',
    callCenterWorkEndTime: '22:00',
    isChooseDateTime: true,
    choosedDate: null,
    timePeriod: null,
    choosedDateStatus: 'ok',
    timePeriodStatus: 'ok',
    entranceInput: '',
    intercomInput: '',
  };

  componentDidMount() {
    const {
      connectionData,
      checkFlatOnInitial,
      getAllSelectedServicesForTariff,
      promoTariff,
      isFttbWithTv,
    } = this.props;

    if (connectionData) {
      this.checkHouseConnection();
      const { flat } = connectionData;

      if (checkFlatOnInitial && flat && flat.length) this.checkFlatConnection([gaTypes.order]);
    }

    if (promoTariff) {
      const services = getAllSelectedServicesForTariff ? getAllSelectedServicesForTariff() : null;
      pushFTTBOrder('openOrderPopup', promoTariff, services, undefined, 'home', isFttbWithTv);
    }
  }

  componentDidUpdate(prevProps) {
    const { connectionData, isInitialLoadedConnectionData } = this.props;
    const { connectionData: prevConnectionData } = prevProps;

    if (connectionData !== prevConnectionData) {
      this.checkHouseConnection();
      const hasFlat = connectionData && connectionData.flat;
      const prevFlat = prevConnectionData ? prevConnectionData.flat : null;
      if (hasFlat && connectionData.flat !== prevFlat) {
        this.checkFlatConnection(isInitialLoadedConnectionData ? [gaTypes.order] : undefined);
      }
    }
  }

  checkFlatConnection = debounce((pushGATypes = []) => {
    const { connectionData, promoTariff, getAllSelectedServicesForTariff } = this.props;
    const { city, street, house, flat } = connectionData;

    checkAddressHelper
      .checkFlatConnection({ city, street, house, flat })
      .then((data) => {
        const {
          connectionState,
          hasRequestOnAddress,
          callCenterWorkStartTime,
          callCenterWorkEndTime,
        } = data;

        this.setState({ hasRequestOnAddress: false });
        if (hasRequestOnAddress) {
          this.setState({
            hasRequestOnAddress,
            callCenterWorkStartTime,
            callCenterWorkEndTime,

            flatConnectionState: currentStates.none,
            showSuccessFlatConnection: false,
          });
          const services =
            getAllSelectedServicesForTariff ? getAllSelectedServicesForTariff() : null;
          if (pushGATypes.includes(gaTypes.order)) pushFTTBOrder('double', promoTariff, services);
          return;
        }
        switch (connectionState) {
          case checkAddressHelper.flatConnectionStates.error:
          case checkAddressHelper.flatConnectionStates.success: {
            this.onSuccessFlatCheckConnection();
            if (pushGATypes.includes(gaTypes.address))
              pushFloatingCheckAction('address', 'addressFound');
            break;
          }
          case checkAddressHelper.flatConnectionStates.alreadyInUse:
            this.onSuccessFlatCheckConnection();
            if (pushGATypes.includes(gaTypes.address))
              pushFloatingCheckAction('address', 'flatNotAvailable');
            break;
          case checkAddressHelper.flatConnectionStates.partnerConnected:
            this.setState({
              flatConnectionState: currentStates.fail,
              showSuccessFlatConnection: false,
            });
            if (pushGATypes.includes(gaTypes.address))
              pushFloatingCheckAction('address', 'flatNotAvailable');
            break;
          case checkAddressHelper.flatConnectionStates.none:
            this.setState({
              flatConnectionState: currentStates.none,
              showSuccessFlatConnection: false,
            });
            break;

          default:
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, 60);

  onOrderClick = () => {
    pushFullFormPopupAction({ category: 'btnClick', action: 'order' });

    const {
      flatConnectionState,
      phoneInput,
      entranceInput,
      intercomInput,
      choosedDate,
      timePeriod,
      isChooseDateTime,
    } = this.state;

    const { connectionData } = this.props;

    const { streetInvalid, houseInvalid, flatInvalid } = this.validateAddress();
    const phoneInvalid = !validateMaskedPhone(phoneInput);
    const choosedDateInvalid = !choosedDate;
    const timePeriodInvalid = !timePeriod;

    if (flatConnectionState === currentStates.fail) return;

    if (streetInvalid) {
      this.setState({ notFoundAddress: 'street' });
    } else if (houseInvalid) {
      this.setState({ notFoundAddress: 'house' });
    }

    if (phoneInvalid) this.setState({ phoneInputStatus: 'fail' });
    if (choosedDateInvalid) this.setState({ choosedDateStatus: 'fail' });
    if (timePeriodInvalid) this.setState({ timePeriodStatus: 'fail' });

    if (streetInvalid || houseInvalid || phoneInvalid || flatInvalid) return;
    if (!isChooseDateTime && (choosedDateInvalid || timePeriodInvalid)) return;

    const {
      connectingRequestUrl,
      promoTariff,
      tariffRequestData,
      getAllSelectedServicesForTariff,
      getAllExcludedServicesForTariff,
    } = this.props;

    const createOrderUrl = connectingRequestUrl || defaultConnectingRequestUrl;
    const additionalServices = getAllSelectedServicesForTariff?.(promoTariff.alias) || [];
    const excludedServices = getAllExcludedServicesForTariff?.(promoTariff.alias) || [];
    const product =
      tariffRequestData ||
      mapProductDataFromPromo(promoTariff, additionalServices, excludedServices);
    const availableScheduleData =
      isChooseDateTime ? {} : { timePeriod, entrance: entranceInput, intercom: intercomInput };
    const requestData = getCreateOrderData(
      product,
      connectionData,
      availableScheduleData,
      phoneInput,
    );

    this.setState({
      currentState: currentStates.loading,
      flatConnectionState: currentStates.none,
    });

    createOrder(createOrderUrl, requestData).then(({ data }) => {
      successfullFTTBFormSubmissionVK();
      successfullFTTBFormSubmissionMT();
      sendSuccessCallbackLongFormAnalitics();

      window.location.href = data.View.Data.Url;
    });
  };

  onSpecTariffCheck = () => {
    const streetValid = this.checkAddressForm.validateStreet(true) === 'ok';
    const houseValid = this.checkAddressForm.validateHouse(true) === 'ok';
    const flatValid = this.checkAddressForm.validateFlat(true) === 'ok';

    if (streetValid && houseValid && flatValid) {
      this.checkFlatConnection();
    }
  };

  onClearHouse = () => {
    this.setState({
      houseConnectionState: currentStates.none,
      showSuccessFlatConnection: false,
      hasRequestOnAddress: false,
    });
  };

  onHouseChange = () => {
    this.resetNotFoundAddress();
    this.setState({ hasRequestOnAddress: false });
  };

  onConnectHouse = () => {
    this.props.onConnectHouse();
  };

  onOpenBackCallPopup = () => {
    this.props.onOpenBackCallPopup();
  };

  onSuccessFlatCheckConnection = () => {
    this.setState({ flatConnectionState: currentStates.success }, () => {
      if (this.state.flatInputState !== 'blur') return;

      this.checkSuccessFlatConnection();
    });
  };

  onChooseDateTimeChange = () => {
    this.setState((state) => ({
      isChooseDateTime: !state.isChooseDateTime,
    }));
  };

  onDateTimeChange = ({ date, timePeriod }) => {
    this.setState((state) => ({
      choosedDate: date,
      timePeriod,
      choosedDateStatus: date ? 'ok' : state.choosedDateStatus,
      timePeriodStatus: timePeriod ? 'ok' : state.timePeriodStatus,
    }));
  };

  onEntranceChange = (val) => {
    this.setState({ entranceInput: val });
  };

  onIntercomChange = (val) => {
    this.setState({ intercomInput: val });
  };

  onFlatInputFocus = (e) => {
    if (typeof e !== 'object') return;

    const { shouldFetchSpecTariff, connectionData, fetchSpecialTariffByHouse } = this.props;
    this.setState({ flatInputState: 'focus' });

    if (shouldFetchSpecTariff) fetchSpecialTariffByHouse(connectionData.house.id, true);
  };

  onFlatInputBlur = () => {
    this.setState({ flatInputState: 'blur' });
    this.checkSuccessFlatConnection();

    setTimeout(() => this.checkFlatConnection([gaTypes.address, gaTypes.order]), 100);
  };

  onHouseBlur = () => {
    if (this.checkAddressForm.validateHouse() === 'fail') {
      this.setState({ notFoundAddress: 'house' });
      pushFloatingCheckAction('address', 'houseNotFound', true);
    }
  };

  onStreetBlur = () => {
    if (this.checkAddressForm.validateStreet() === 'fail') {
      this.setState({ notFoundAddress: 'street' });
      pushFloatingCheckAction('address', 'streetNotFound', true);
    }
  };

  onPhoneInputChange = (val) => this.setState({ phoneInput: val });

  checkHouseConnection = () => {
    const { house } = this.props.connectionData || {};

    if (!house) return;

    if (house.isConnected) {
      this.successHouseConnectionCheck();
    } else {
      this.failHouseConnectionCheck();
    }
  };

  successHouseConnectionCheck = () => {
    this.setState({
      houseConnectionState: currentStates.success,
      flatConnectionState: currentStates.none,
    });
  };

  failHouseConnectionCheck = () => {
    this.setState({
      houseConnectionState: currentStates.fail,
      flatConnectionState: currentStates.none,
      showSuccessFlatConnection: false,
    });

    const { promoTariff, getAllSelectedServicesForTariff } = this.props;
    const services = getAllSelectedServicesForTariff && getAllSelectedServicesForTariff();
    pushTVEUpsaleBlockAction('show');
    pushFloatingCheckAction('address', 'houseNotFound');
    pushFTTBOrder('sentFail', promoTariff, services, 'notFoundHouse');
  };

  checkSuccessFlatConnection = () => {
    const { flatConnectionState } = this.state;
    if (flatConnectionState === currentStates.success) {
      this.setState({ showSuccessFlatConnection: true });
    }
  };

  resetSuccessFlatConnection = () => {
    this.setState({ showSuccessFlatConnection: false });
  };

  resetNotFoundAddress = () => this.setState({ notFoundAddress: null });

  validateAddress() {
    if (this.checkAddressForm) {
      const streetInvalid = this.checkAddressForm.validateStreet(true) === 'fail';
      const houseInvalid = this.checkAddressForm.validateHouse(true) === 'fail';
      const flatInvalid = this.checkAddressForm.validateFlat(true) === 'fail';

      return { streetInvalid, houseInvalid, flatInvalid };
    }

    const { street, house, flat } = this.props.connectionData;

    const streetInvalid = !street || !street.id;
    const houseInvalid = !house || !house.id;
    const flatInvalid = !flat;

    return { streetInvalid, houseInvalid, flatInvalid };
  }

  renderSuccessFlatConnection(texts) {
    const { data, connectionData, promoTariff } = this.props;
    const { street, house, flat } = connectionData;
    const linkText = templateParser(data.successFlatConnectionTemplate, {
      street: street.label,
      house: house.label,
      flat,
    });

    const textPostfix = templateParser(texts.textPostfixTemplate, {
      tariffName: promoTariff.title,
    });

    return (
      <SuccessFlatConnection
        {...data.successFlatConnectionTexts}
        className={cx('successFlatConnection')}
        linkText={linkText}
        onLinkClick={this.resetSuccessFlatConnection}
        textPostfix={textPostfix}
      />
    );
  }

  renderStreetEmptyListComponent = () => {
    const { data } = this.props;
    pushFloatingCheckAction('address', 'streetNotFound', true);

    return (
      <div className={cx('emptyList')}>
        <div
          className={cx('emptyListLabel')}
          dangerouslySetInnerHTML={{ __html: data.emptyListLabel }}
        />

        <Link className={cx('backCallLink')} onClick={this.onOpenBackCallPopup}>
          <span dangerouslySetInnerHTML={{ __html: data.emptyListBackCall }} />
        </Link>
      </div>
    );
  };

  renderHeading(data = this.props.data) {
    const { pageTitle } = data;
    return (
      <Heading className={cx('formTitle')} level={2} tagName="div">
        <span dangerouslySetInnerHTML={{ __html: pageTitle }} />
      </Heading>
    );
  }

  renderCheckAddress(className) {
    const { onCityChange } = this.props;

    return (
      <div className={cx(className)}>
        <div className={cx('checkAddressInputsWrapper')}>
          <CheckAddressHOC
            onCityChange={onCityChange}
            onClearHouse={this.onClearHouse}
            onHouseChange={this.onHouseChange}
            onStreetChange={this.resetNotFoundAddress}
          >
            <CheckAddressForm
              StreetEmptyListComponent={this.renderStreetEmptyListComponent}
              onFlatInputBlur={this.onFlatInputBlur}
              onFlatInputFocus={this.onFlatInputFocus}
              onHouseBlur={this.onHouseBlur}
              onStreetBlur={this.onStreetBlur}
              ref={(el) => {
                this.checkAddressForm = el;
              }}
              type="bigInputs"
            />
          </CheckAddressHOC>
        </div>

        {this.renderCheckAddressStatuses()}
      </div>
    );
  }

  renderCheckAddressStatuses() {
    const {
      houseConnectionState,
      flatConnectionState,
      notFoundAddress,
      hasRequestOnAddress,
      callCenterWorkStartTime,
      callCenterWorkEndTime,
    } = this.state;
    const { data, connectionData } = this.props;
    const { street, house, flat } = connectionData || {};
    const flatPartnerConnectedText = templateParser(data.flatPartnerConnectedTemplate, {
      street: street && street.label,
      house: house && house.label,
      flat,
    });
    return (
      <>
        {houseConnectionState === currentStates.fail && (
          <Status className={['attention', cx('attentionBlock', 'houseNotAvailable')]}>
            <div
              className={cx('attentionContent')}
              dangerouslySetInnerHTML={{ __html: data.houseConnectionStatusTexts }}
            />

            <Button className={cx('connectHouseBtn')} color="light" onClick={this.onConnectHouse}>
              <span dangerouslySetInnerHTML={{ __html: data.houseConnectionBtnTexts }} />
            </Button>
          </Status>
        )}
        {flatConnectionState === currentStates.fail && (
          <Status className={['attention', cx('attentionBlock')]} emoji="statusFail">
            <div
              className={cx('attentionContent')}
              dangerouslySetInnerHTML={{ __html: flatPartnerConnectedText }}
            />
          </Status>
        )}
        {hasRequestOnAddress && (
          <Status
            className={['attention', cx('attentionBlock', 'flatNotAvailable')]}
            emoji="statusFail"
          >
            <div
              className={cx('attentionContent')}
              dangerouslySetInnerHTML={{
                __html: templateParser(data.hasRequestOnAddressStatusText, {
                  startTime: callCenterWorkStartTime,
                  endTime: callCenterWorkEndTime,
                }),
              }}
            />
          </Status>
        )}
        {notFoundAddress && (
          <Status className={['attention', cx('attentionBlock', 'notFoundAddress')]}>
            <div className={cx('notFoundAddressWrapper')}>
              <div
                dangerouslySetInnerHTML={{
                  __html: notFoundAddress === 'street' ? data.notFoundStreet : data.notFoundHouse,
                }}
              />
              <Button
                className={cx('notFoundAddressBtn')}
                color="light"
                onClick={this.onOpenBackCallPopup}
              >
                <span dangerouslySetInnerHTML={{ __html: data.notFoundAddressBtn }} />
              </Button>
            </div>
          </Status>
        )}
      </>
    );
  }

  renderPhoneInput(className) {
    const { phoneInput, phoneInputStatus } = this.state;
    const { data } = this.props;

    return (
      <div className={className}>
        <div
          className={cx('phoneInputLabel')}
          dangerouslySetInnerHTML={{ __html: data.priceSection.phoneInputLabel }}
        />
        <TextInput
          keepInitialValue
          mask="+7 ___ ___ __ __"
          onChange={this.onPhoneInputChange}
          size="big"
          status={phoneInputStatus}
          value={phoneInput}
        />
      </div>
    );
  }

  renderTariffInfo() {
    const {
      data,
      promoTariff,
      availableScheduleData,
      getAllSelectedServicesForTariff,
      getAllExcludedServicesForTariff,
    } = this.props;

    if (!promoTariff) return null;

    const { chooseDateTimeEnabled } = availableScheduleData;
    const selectedServicesForTariff = getAllSelectedServicesForTariff?.(promoTariff.alias) || [];
    const excludedServices = getAllExcludedServicesForTariff?.(promoTariff.alias) || [];

    return (
      <div className={cx('tariffInfoWrap')}>
        <div>
          <TariffInfo
            additionalServices={selectedServicesForTariff}
            data={data.priceSection}
            excludedServices={excludedServices}
            tariffData={promoTariff}
          />
          {chooseDateTimeEnabled && this.renderChooseDateTimeCheckbox()}
        </div>
        {this.renderChooseDateTime()}
      </div>
    );
  }

  renderChooseDateTimeCheckbox() {
    const { isChooseDateTime } = this.state;
    const { data } = this.props;
    return (
      <div className={cx('chooseDateTimeCheckboxWrap')}>
        <Checkbox
          checked={isChooseDateTime}
          handleChange={this.onChooseDateTimeChange}
          name="choose-date-time-checkbox"
        >
          <span
            className={cx('chooseDateTimeCheckboxText')}
            dangerouslySetInnerHTML={{ __html: data.chooseDateTimeCheckboxText }}
          />
        </Checkbox>
      </div>
    );
  }

  renderChooseDateTime() {
    const { isChooseDateTime, choosedDateStatus, timePeriodStatus } = this.state;
    const { data, connectionData, availableScheduleData } = this.props;

    if (isChooseDateTime) return null;

    const { startDateOffsetInDays, endDateOffsetInDays } = availableScheduleData;

    return (
      <div className={cx('chooseDateTimeWrap')}>
        <ChooseDateTime
          contentData={data.chooseDateTime}
          dateInputStatus={choosedDateStatus}
          endDateOffsetInDays={endDateOffsetInDays}
          houseId={connectionData && connectionData.house && connectionData.house.id}
          onDateTimeChange={this.onDateTimeChange}
          onEntranceChange={this.onEntranceChange}
          onIntercomChange={this.onIntercomChange}
          startDateOffsetInDays={startDateOffsetInDays}
          timeInputStatus={timePeriodStatus}
        />
      </div>
    );
  }

  renderOrderSection() {
    const { houseConnectionState, flatConnectionState } = this.state;
    const { data } = this.props;

    const disabledBtn =
      houseConnectionState === currentStates.fail || flatConnectionState === currentStates.fail;

    return (
      <div className={cx('orderSectionRow')}>
        <div className={cx('orderSectionCol')}>{this.renderPhoneInput(cx('desktop'))}</div>
        <div className={cx('orderSectionCol')}>
          <OrderBtnSection
            data={data.priceSection}
            disabledBtn={disabledBtn}
            onClick={this.onOrderClick}
          />
        </div>
      </div>
    );
  }

  renderSupportSection() {
    const { supportText, supportPhone } = this.props.data.supportSection;
    return (
      <div>
        <div
          className={cx('supportSectionDescription')}
          dangerouslySetInnerHTML={{ __html: supportText }}
        />
        <div className={cx('supportPhoneWrapper')}>
          <img
            alt=""
            className={cx('supportIcon')}
            src="//static.beeline.ru/upload/images/new-shpd-catalog/voprosi/trubka.svg"
          />
          <div className={cx('supportPhone')} dangerouslySetInnerHTML={{ __html: supportPhone }} />
        </div>
      </div>
    );
  }

  renderHaveQuestions() {
    const { haveQuestionText, haveQuestionLinkText } = this.props.data;

    return (
      <HaveQuestions
        linkText={haveQuestionLinkText}
        onOpenBackCallPopup={this.onOpenBackCallPopup}
        text={haveQuestionText}
      />
    );
  }

  renderUpsaleBlock() {
    const { buyTVEData, data } = this.props;

    return (
      <div className={cx('upsaleWrapper')}>
        <BuyTVEUpsale {...buyTVEData} />

        <div className={cx('orderBackCallWrapper')}>
          <OrderBackCall
            className={cx('orderBackCall')}
            descriptionClassName={cx('orderBackCallDescription')}
            titleClassName={cx('orderBackCallTitle')}
            {...data.orderBackCallTexts}
          />
        </div>
      </div>
    );
  }

  renderLegal() {
    const { userAgreementText, termsOfUse } = this.props.data;

    return (
      <LegalWithTooltip
        className={cx('legal')}
        termsOfUse={termsOfUse}
        userAgreementText={userAgreementText}
      />
    );
  }

  renderCheckTariffBtn() {
    const { checkAddressBtnText } = this.props.data.specTariff;

    return (
      <div className={cx('checkSpecTariffBtnWrapper')}>
        <BigButton onClick={this.onSpecTariffCheck} wide>
          <span dangerouslySetInnerHTML={{ __html: checkAddressBtnText }} />
        </BigButton>
      </div>
    );
  }

  renderForm() {
    const { houseConnectionState, showSuccessFlatConnection, hasRequestOnAddress } = this.state;
    const { checkSpecTariff, data } = this.props;

    if (checkSpecTariff) return this.renderSpecialTariffForm();

    return (
      <div className={cx('formWrapper')}>
        {this.renderHeading()}
        {showSuccessFlatConnection &&
          this.renderSuccessFlatConnection(data.successFlatConnectionTexts)}
        {this.renderCheckAddress(cx({ hidden: showSuccessFlatConnection }))}
        {houseConnectionState === currentStates.fail && this.renderUpsaleBlock()}
        {hasRequestOnAddress && this.renderSupportSection()}
        {houseConnectionState !== currentStates.fail && !hasRequestOnAddress && (
          <>
            {this.renderPhoneInput(cx('phoneInput', 'mobile'))}
            {this.renderTariffInfo()}
            {/* {this.renderChooseDateTime()} */}
            {this.renderOrderSection()}
            {this.renderHaveQuestions()}
          </>
        )}
        {this.renderLegal()}
      </div>
    );
  }

  renderSpecialTariffForm() {
    const { data, specTariffIsLoaded, isSpecTariffForHouse } = this.props;
    const { houseConnectionState, showSuccessFlatConnection, hasRequestOnAddress } = this.state;

    // Если адрес уже проверен, а тариф всё ещё не получен рендерим лоадер
    if (showSuccessFlatConnection && !specTariffIsLoaded) return this.renderPreloader();

    const timeout = 300;
    const orderSectionDelay = 700;
    const headingData =
      showSuccessFlatConnection || houseConnectionState === currentStates.fail ?
        data
      : data.specTariff;

    const successConnectionTexts =
      isSpecTariffForHouse ?
        data.specTariffSuccessFlatConnectionTexts.withSpecTariff
      : data.specTariffSuccessFlatConnectionTexts.withoutSpecTariff;

    return (
      <div className={cx('formWrapper')}>
        {this.renderHeading(headingData)}
        <SwitchTransition>
          {showSuccessFlatConnection ?
            <ZoomIn key="SuccessFlatConnection" timeout={timeout}>
              {this.renderSuccessFlatConnection(successConnectionTexts)}
            </ZoomIn>
          : <ZoomIn key="CheckAddress" timeout={timeout}>
              {this.renderCheckAddress()}
            </ZoomIn>
          }
        </SwitchTransition>
        <TransitionGroup>
          <div>
            {houseConnectionState === currentStates.fail && this.renderUpsaleBlock()}
            {hasRequestOnAddress && this.renderSupportSection()}
            {houseConnectionState !== currentStates.fail && !hasRequestOnAddress && (
              <>
                {showSuccessFlatConnection && (
                  <>
                    <SlideDown in timeout={timeout}>
                      <div>
                        {this.renderPhoneInput(cx('phoneInput', 'mobile'))}
                        {this.renderTariffInfo()}
                      </div>
                    </SlideDown>
                    <SlideDownAndFade in timeout={timeout + orderSectionDelay + 100}>
                      <div style={{ transitionDelay: `${orderSectionDelay}ms` }}>
                        {this.renderOrderSection()}
                      </div>
                    </SlideDownAndFade>
                    {this.renderHaveQuestions()}
                  </>
                )}

                {!showSuccessFlatConnection && this.renderCheckTariffBtn()}
              </>
            )}
          </div>
        </TransitionGroup>
        {this.renderLegal()}
      </div>
    );
  }

  renderPreloader() {
    return (
      <div className={cx('loaderWrap')}>
        <Preloader size="100" />
      </div>
    );
  }

  render() {
    const { currentState } = this.state;

    return (
      <>
        {currentState === currentStates.requestForm && this.renderForm()}
        {currentState === currentStates.loading && this.renderPreloader()}
      </>
    );
  }
}

OrderTariffForm.defaultProps = {
  checkFlatOnInitial: true,
  availableScheduleData: {
    chooseDateTimeEnabled: false,
  },
};
OrderTariffForm.propTypes = {
  connectingRequestUrl: PropTypes.string,
  tariffRequestData: PropTypes.object,
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  isFttbWithTv: PropTypes.bool,
  onClose: PropTypes.func,
  onCityChange: PropTypes.func,
  onConnectHouse: PropTypes.func,
  onOpenBackCallPopup: PropTypes.func,
  buyTVEData: PropTypes.object,
  getAllSelectedServicesForTariff: PropTypes.func,
  getAllExcludedServicesForTariff: PropTypes.func,
  availableScheduleData: PropTypes.shape({
    chooseDateTimeEnabled: PropTypes.bool,
    startDateOffsetInDays: PropTypes.number,
    endDateOffsetInDays: PropTypes.number,
  }),

  promoTariff: PropTypes.object,
  connectionData: PropTypes.shape(connectionDataPropTypes),
  isInitialLoadedConnectionData: PropTypes.bool,
  isMobile: PropTypes.bool,
  fetchSpecialTariffByHouse: PropTypes.func,

  // Флаг указывающий на необходимость рендерить layout проверки спецтарифа (с соот-ми текстами)
  checkSpecTariff: PropTypes.bool,
  // Если true, то будет проверяться наличие спецтарифов после проверки дома
  shouldFetchSpecTariff: PropTypes.bool,
  // Признак управалющий состоянием проверки квартиры при иниц-ии компонента
  checkFlatOnInitial: PropTypes.bool,
  specTariffIsLoaded: PropTypes.bool,
  isSpecTariffForHouse: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const { promoTariff, checkSpecTariff } = ownProps;
  const newPromoTariff = checkSpecTariff ? getSpecialTariff(state) || promoTariff : promoTariff;

  return {
    connectionData: getConnectionData(state),
    isInitialLoadedConnectionData: isInitialLoadConnectionData(state),
    isMobile: isMobileDevice(state),
    promoTariff: newPromoTariff,
    specTariffIsLoaded: isSpecialTariffFetched(state),
    isSpecTariffForHouse: isSpecialTariffAvailable(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSpecialTariffByHouse: (houseId, show) =>
    dispatch(fetchSpecialTariffByHouseAction(houseId, show)),
});

export { OrderTariffForm }; // For tests
export default connect(mapStateToProps, mapDispatchToProps)(OrderTariffForm);
