import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import Ctx from 'pages-components/Ctx';
import { pushClickPayButton, pushResult } from 'utils/analytics/payment';

import GooglePayButton from './GooglePayButton';
import ApplePayButton from './ApplePayButton';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

class PaymentButtons extends Component {
  state = {
    shown: null,
    success: null,
    error: null,
    loading: false,
    rendered: false,
  };

  componentDidMount() {
    this.setState({ rendered: true });
  }

  onButtonShown = (type) => () => {
    // Делаем приоритет для ApplePay: если ApplePay уже показан и инициализация GooglePay
    // прошла успешно, оставляем только ApplePay
    if (this.state.shown === 'apple' && type === 'google') {
      return;
    }
    if (this.props.onLoad) {
      this.props.onLoad(type);
    }
    this.setState({ shown: type }, this.props.onShown);
  };

  onPaymentSuccess = (type) => () => {
    this.setState(
      {
        success: type,
        loading: false,
      },
      () => {
        this.props.onSuccess();
        if (this.props.resetOnComplete) {
          this.onReset();
        }
      },
    );
    pushResult(type, 'success');
  };

  onButtonClick = async (buttonType) => {
    pushClickPayButton(buttonType);

    this.setState({ loading: true, error: null });
    await this.props.onClick();
  };

  onPaymentError =
    (type) =>
    ({ isServerError }) => {
      this.setState(
        {
          error: type,
          loading: false,
        },
        () => {
          this.props.onError(isServerError);
          if (this.props.resetOnComplete) {
            this.onReset();
          }
        },
      );
      pushResult(type, 'error');
    };

  onReset = () => {
    this.setState({
      loading: false,
      error: null,
      success: null,
    });
  };

  get renderApplePay() {
    const { applePayEnabled } = this.props;
    const { success, shown } = this.state;
    return applePayEnabled && !success && shown !== 'google';
  }

  get renderGooglePay() {
    const { googlePayEnabled } = this.props;
    const { success, shown, error } = this.state;
    return googlePayEnabled && !success && shown !== 'apple' && error !== 'google';
  }

  renderPaymentButton = (type) => {
    const {
      sum,
      label,
      disabled,
      returnUrl,
      paymentData,
      scrollToPayForm,
      mobileAppFunction,
      mapProcessPaymentReq,
      mapProcessPaymentRes,
      applePayProcessPaymentUrl,
      googlePayProcessPaymentUrl,
      needCreateMobileAppHandler,
    } = this.props;
    const ButtonComponent = type === 'google' ? GooglePayButton : ApplePayButton;
    let paymentType = '';

    if (label === 'eSIM') paymentType = 'Esim';
    if ('ctn' in paymentData) paymentType = 'MobileBalance';
    if ('homeLogin' in paymentData) paymentType = 'FttbBalance';

    return (
      <ButtonComponent
        disabled={disabled}
        hidden={this.state.loading}
        label={label === 'eSIM' ? 'BEELINE' : label}
        mapProcessPaymentReq={mapProcessPaymentReq}
        mapProcessPaymentRes={mapProcessPaymentRes}
        mobileAppFunction={mobileAppFunction}
        needCreateMobileAppHandler={needCreateMobileAppHandler}
        onClick={this.onButtonClick}
        onError={this.onPaymentError(type)}
        onReset={this.onReset}
        onShown={this.onButtonShown(type)}
        onSuccess={this.onPaymentSuccess(type)}
        paymentData={paymentData}
        paymentType={paymentType}
        processPaymentUrl={
          type === 'google' ? googlePayProcessPaymentUrl : applePayProcessPaymentUrl
        }
        returnUrl={
          returnUrl ||
          `${window.location.origin}${window.location.pathname}?params=googlePayConfirm`
        }
        scrollToPayForm={scrollToPayForm}
        sum={sum}
      />
    );
  };

  render() {
    const { locale, large, hideOffero, className } = this.props;
    const { rendered, shown, loading } = this.state;

    if (!rendered) return null;

    const texts = Ctx.paymentForm[locale];

    return (
      <div className={cx('wrapper', { large }, className)}>
        <div className={cx('btn-wrapper')}>
          {this.renderApplePay && this.renderPaymentButton('apple')}
          {this.renderGooglePay && this.renderPaymentButton('google')}
        </div>

        {shown && !hideOffero && (
          <div className={cx('offer-note')}>
            <Text color="grey50" size="size6-r">
              <div
                className={cx('offer-text')}
                dangerouslySetInnerHTML={{
                  __html: this.renderApplePay ? texts.offeroApple : texts.offeroGoogle,
                }}
              />
            </Text>
          </div>
        )}
        {loading && <Preloader className={cx('preloader')} />}
      </div>
    );
  }
}

PaymentButtons.defaultProps = {
  locale: 'RU',
  paymentData: {},
  needCreateMobileAppHandler: false,
};

PaymentButtons.propTypes = {
  large: PropTypes.bool,
  sum: PropTypes.number,
  onShown: PropTypes.func,
  onClick: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  locale: PropTypes.string,
  onSuccess: PropTypes.func,
  returnUrl: PropTypes.string,
  paymentData: PropTypes.object,
  scrollToPayForm: PropTypes.func,
  applePayEnabled: PropTypes.bool,
  googlePayEnabled: PropTypes.bool,
  mobileAppFunction: PropTypes.func,
  mapProcessPaymentRes: PropTypes.func,
  mapProcessPaymentReq: PropTypes.func,
  needCreateMobileAppHandler: PropTypes.bool,
  resetOnComplete: PropTypes.bool,
  applePayProcessPaymentUrl: PropTypes.string,
  googlePayProcessPaymentUrl: PropTypes.string,
  hideOffero: PropTypes.bool,
};

export default PaymentButtons;
