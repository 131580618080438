import React, { memo } from 'react';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { TBannerContent } from './types';

const cx = classNames.bind(styles);

/** Компонент заголовка и описания баннера */
export const BannerContent = memo(({ description, title }: TBannerContent) => (
  <div className={cx('wrapper')}>
    {title && <Text size="size4-m">{title}</Text>}
    {description && (
      <Text color="grey60" size="size5-r-l">
        {description}
      </Text>
    )}
  </div>
));

export type { TBannerContent };
