import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const TopSide = ({ icon, title, description }) => (
  <div className={cx('wrapper')}>
    {icon && (
      <div className={cx('wrapper__icon')}>
        <img
          alt={`${title}-icon`}
          className={cx('wrapper__icon--size')}
          loading="lazy"
          src={icon}
        />
      </div>
    )}
    {title && (
      <div className={cx('wrapper__title')}>
        <Text size="size2-m" tagType="h3">
          {title}
        </Text>
      </div>
    )}
    {description && (
      <Text color="grey60" size="size4-r">
        {description}
      </Text>
    )}
  </div>
);

TopSide.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
