import React, { useMemo } from 'react';

import { useGetDimensionGroup } from 'utils/hooks/useGetDimensionGroup';
import { ScrollableGrid } from 'pages/FTTB2022/ui/common';

export const Slider = ({
  children,
  mobileSize,
  desktopSize,
  tabletSize,
  gridClassName,
  className,
  isNoGrow,
}) => {
  const dimension = useGetDimensionGroup();

  const size = {
    tablet: tabletSize,
    mobile: mobileSize,
    desktop: desktopSize,
  };

  const options = useMemo(
    () => ({
      ...ScrollableGrid.presets.medium,
      maxSize: size[dimension] || desktopSize,
    }),
    [dimension, mobileSize, desktopSize, desktopSize],
  );

  return (
    <ScrollableGrid
      className={className}
      gridClassName={gridClassName}
      ids
      isNoGrow={isNoGrow}
      options={options}
    >
      {children}
    </ScrollableGrid>
  );
};

Slider.Item = ScrollableGrid.Item;
