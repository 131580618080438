import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames/bind';
import { Emoji, Loader } from '@beef/legacy-ui-kit';

import { poll } from 'utils/timed-functions';
import { API_ENDPOINTS, SUBSCRIPTION_STATUSES } from 'utils/partnershipTariffHelper';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PENDING_STATUS = 'FORBIDDEN';

const CheckStatus = ({ pollConfig, onFetchData }) => {
  const [icons] = useState(['chat', 'clock', 'infinitCalls', 'simOrder', 'infinitInternet']);
  useEffect(() => {
    const checkStatus = async () => {
      try {
        const pollResult = await poll(
          async () => {
            try {
              const { data } = await axios.get(API_ENDPOINTS.GET_SUBSCRIPTION_DATA, {
                params: {
                  redirectUrl: window.location.pathname,
                },
              });

              return data;
            } catch (err) {
              return null;
            }
          },
          (data) => data?.errorType === PENDING_STATUS,
          pollConfig.interval,
          pollConfig.timeout,
        );
        onFetchData({
          success: pollResult?.status === SUBSCRIPTION_STATUSES.available,
          link: pollResult.activationRedirectUrl,
        });
      } catch (e) {
        onFetchData({
          success: false,
          link: null,
        });
      }
    };

    checkStatus();
  }, [pollConfig]);

  return (
    <div className={cx('check-status-wrap')}>
      <Loader size="small" status="active">
        {icons.map((name) => (
          <Emoji key={name} name={name} />
        ))}
      </Loader>
    </div>
  );
};

CheckStatus.propTypes = {
  pollConfig: PropTypes.shape({
    interval: PropTypes.number,
    timeout: PropTypes.number,
  }),

  onFetchData: PropTypes.func,
};

export default CheckStatus;
