import { connect } from 'react-redux';

import { VideoModal } from '../../VideoCards/VideoModal';
import { resetCurrentVideo, selectCurrentFinalVideo } from '../../../store/finalStories';

const mapStateToProps = (state) => ({
  youtubeId: selectCurrentFinalVideo(state).video,
});

const mapDispatchToProps = {
  onClose: resetCurrentVideo,
};

export const FinalVideoModal = connect(mapStateToProps, mapDispatchToProps)(VideoModal);
