import React from 'react';
import classNames from 'classnames/bind';
import { ImageCard, InfoCard } from '@beef/smart-kit';
import { PropsWithClassName } from '@beef/ui-kit/types';

import style from './styles.pcss';
import { CardPropsType } from '../types';

const cn = classNames.bind(style);

export const CustomCard = ({
  type = 'imageCard',
  size = 's',
  className,
  ...props
}: PropsWithClassName<CardPropsType>) => (
  <div
    className={cn(
      'card',
      {
        [`card__image--${size}`]: type === 'imageCard',
      },
      className,
    )}
  >
    {type === 'imageCard' && <ImageCard {...props} size={size} />}
    {type === 'infoCard' && <InfoCard {...props} />}
  </div>
);
