import { useRef } from 'react';

import { useTariffOptionsContent } from '../selectors/useTariffOptionsContent';
import { useOnScreenAnalytics } from '../../../../tempAnalytics/hooks/useOnScreenAnalytics';

/* Хук подготовки данных для компонента TariffOptions */
export const usePrepareTariffOptions = () => {
  const optionsRef = useRef(null);

  /* Контентные данные для компонента TariffOptions */
  const { title, options } = useTariffOptionsContent();

  /* Отправка аналитики если блок видим больше 2х секунд */
  useOnScreenAnalytics({ ref: optionsRef, payload: { block_options: 'view' } });

  return { title, options, optionsRef };
};
