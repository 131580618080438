export const constructorV1Pages = {
  main: 'main',
  bill: 'bill',
  result: 'result',
  redirect: 'test-redirect',
} as const;

export const constructorSubPathes = Object.keys(constructorV1Pages)
  .filter((key) => key !== constructorV1Pages.main)
  .map((key) => constructorV1Pages[key as keyof typeof constructorV1Pages]);
