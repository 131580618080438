import React from 'react';

import BlackListExtension from 'pages/ProductCard/components/BlackListService';
import BlackListConnection from 'pages/ProductCard/components/BlackListService/BlackListConnection';
import AlwaysInPositiveButton from 'pages/ProductCard/components/AlwaysInPositiveButton/AlwaysInPositiveButton';
import SecretaryService from 'pages/ProductCard/components/SecretaryService';
import AccessibleInternetButton from 'pages/Services/AссessibleInternet/component/AccessibleInternetButton';
import AccessibleInternetParameters from 'pages/Services/AссessibleInternet/component/AccessibleInternetParameters';
import { onFaqClick } from 'pages/Services/VoWiFiService/utils';
import { useFetchRequest } from 'pages/Services/VoWiFiService/hooks/useFetchRequest';
import { SkeletonLayout } from 'pages/Services/VoWifiServiceCardLanding/components/SkeletonLayout';
import { RedirectServiceCardLanding } from 'pages/Services/RedirectServiceCardLanding';

import VoLTEService from './VoLTEService';
import AlwaysInPositiveExtension from '../ProductCard/components/AlwaysInPositiveExtension/AlwaysInPositiveExtension';
import SmsServiceExtension from '../../components/SmsServiceExtension/SmsServiceExtension';
import ServiceCard from '../ProductCard/Service';
import ConvertToEsimService from './ConvertToEsimService';
import SimReplacement from './SimReplaceServiceCard';
import ScreenProtectionService from './ScreenProtectionService';
import YandexSubscription from './YandexSubscribtion';
import MNPOrder from '../MNPOrder';
import { VoWiFiServiceCardButton, VoWiFiServiceParameters } from './VoWiFiService';
import VoWifiServiceCardLanding from './VoWifiServiceCardLanding';

export const RedirectServiceCard = (props) => <RedirectServiceCardLanding {...props} />;
export const BlackListServiceCard = (props) => (
  <ServiceCard
    {...props}
    connectButtonExtension={BlackListConnection}
    parametersExtension={BlackListExtension}
  />
);

export const SmsServiceCard = (props) => (
  <ServiceCard {...props} parametersExtension={SmsServiceExtension} />
);

export const ConvertToEsimServiceCard = (props) => (
  <ServiceCard {...props} parametersExtension={ConvertToEsimService} />
);

export const SimReplaceServiceCard = (props) => (
  <ServiceCard {...props} parametersExtension={SimReplacement} />
);

export const MNPOrderCard = (props) => <ServiceCard {...props} parametersExtension={MNPOrder} />;

export const SecretaryServiceCard = (props) => (
  <ServiceCard
    {...props}
    divider={false}
    parametersExtension={SecretaryService}
    styleType="secretaryService"
  />
);

export const VoLTEServiceCard = (props) => (
  <ServiceCard {...props} parametersExtension={VoLTEService} />
);

export const AlwaysInPositiveServiceCard = (props) => (
  <ServiceCard
    {...props}
    connectButtonExtension={AlwaysInPositiveButton}
    parametersExtension={AlwaysInPositiveExtension}
  />
);

export const YandexSubscriptionServiceCard = (props) => (
  <ServiceCard {...props} isCustomStyles parametersExtension={YandexSubscription} />
);

export const ScreenProtectionServiceCard = (props) => (
  <ServiceCard {...props} isCustomDivider parametersExtension={ScreenProtectionService} />
);

export const AccessibleInternetServiceCard = (props) => (
  <ServiceCard
    {...props}
    connectButtonExtension={AccessibleInternetButton}
    parametersExtension={AccessibleInternetParameters}
  />
);

export const VoWiFiServiceCard = (props) => {
  const { redesignVoWiFiPageEnabled, landingData, status } = useFetchRequest();

  if (status === 'loading') {
    return <SkeletonLayout />;
  }

  if (status === 'idle') {
    if (redesignVoWiFiPageEnabled) {
      return <VoWifiServiceCardLanding {...landingData} />;
    }

    return (
      <ServiceCard
        {...props}
        connectButtonExtension={VoWiFiServiceCardButton}
        divider={false}
        onFaqSectionClick={onFaqClick}
        parametersExtension={VoWiFiServiceParameters}
      />
    );
  }

  return null;
};

// old component name, new implementation
export { default as TrustedPayments } from './TrustPayment';
export { default as ZeroBalanceSupportServiceCard } from './ZeroBalanceSupport';
