import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames/bind';
import qs from 'query-string';

import { setCookie } from 'utils/cookie';

import { googlePayTransactionCheck } from './utils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

class GooglePayButton extends Component {
  async componentDidMount() {
    this.initGooglePayScript();

    if (this.props.needCreateMobileAppHandler && window?.Android?.onGooglePayButtonClick) {
      this.IS_MOBILE_APP = true;
      this.initMobileAppCallback();
    }

    const params = qs.parse(window.location.search) || {};
    if (params.params === 'googlePayConfirm') {
      try {
        await googlePayTransactionCheck();
        this.props.onSuccess();
        this.props.scrollToPayForm();
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
  }

  pollInterval = null;

  IS_MOBILE_APP = false;

  versions = { apiVersion: 2, apiVersionMinor: 0 };

  basePaymentMethod = {
    parameters: {
      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
      allowedCardNetworks: ['MASTERCARD', 'VISA'],
    },
  };

  paymentMethod = {
    ...this.basePaymentMethod,
    tokenizationSpecification: {
      type: 'PAYMENT_GATEWAY',
      parameters: {},
    },
  };

  paymentsClient = null;

  merchantId = null;

  merchantName = null;

  inProcess = false;

  initGooglePayScript = () => {
    if (window.google && window.google.payments) {
      return this.initGooglePayButton();
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://pay.google.com/gp/p/js/pay.js';
    document.head.appendChild(script);

    this.pollInterval = setInterval(() => {
      if (window.google && window.google.payments) {
        clearInterval(this.pollInterval);
        this.initGooglePayButton();
      }
    }, 300);
  };

  initGooglePayButton = () => {
    this.getPaymentParameters().then(this.onGooglePayLoaded);
  };

  initMobileAppCallback = () => {
    window.postGooglePayData = (paymentData) => {
      if (paymentData.statusCode && paymentData.statusMessage) {
        return this.onError(paymentData);
      }

      this.processPayment(paymentData);
    };
  };

  getPaymentParameters = () => {
    const { paymentType } = this.props;

    return axios.get('/googlepay/GetPaymentInfo/', { params: { paymentType } }).then(({ data }) => {
      this.paymentMethod.tokenizationSpecification.parameters.gateway = data.gateway;
      this.paymentMethod.tokenizationSpecification.parameters.gatewayMerchantId =
        data.gatewayMerchantId;
      this.paymentMethod.type = data.paymentMethod;
      this.basePaymentMethod.type = data.paymentMethod;
      this.merchantId = data.merchantId;
      this.merchantName = data.merchantName;
    });
  };

  getGooglePaymentDataRequest = () => {
    const paymentDataRequest = { ...this.versions };
    paymentDataRequest.allowedPaymentMethods = [this.paymentMethod];
    paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo();
    paymentDataRequest.emailRequired = true;
    paymentDataRequest.merchantInfo = {
      merchantId: this.merchantId,
      merchantName: this.merchantName,
    };
    return paymentDataRequest;
  };

  getGooglePaymentsClient = () => {
    if (this.paymentsClient === null) {
      try {
        this.paymentsClient = new window.google.payments.api.PaymentsClient({
          environment: 'PRODUCTION',
        });
        // eslint-disable-next-line no-empty
      } catch (er) {}
    }
    return this.paymentsClient;
  };

  onGooglePayLoaded = () => {
    const paymentsClient = this.getGooglePaymentsClient();
    paymentsClient
      .isReadyToPay({
        ...this.versions,
        allowedPaymentMethods: [this.basePaymentMethod],
      })
      .then((response) => {
        if (response.result) {
          this.addGooglePayButton();
          this.prefetchGooglePaymentData();
        }
      });
  };

  addGooglePayButton = () => {
    const paymentsClient = this.getGooglePaymentsClient();
    const button = paymentsClient.createButton({ onClick: this.onGooglePaymentButtonClicked });

    this.buttonElement.appendChild(button);
    this.props.onShown();
  };

  getGoogleTransactionInfo = () => ({
    currencyCode: 'RUB',
    totalPriceStatus: 'FINAL',
    totalPrice: `${this.props.sum || 500}.00`,
  });

  prefetchGooglePaymentData = () => {
    const paymentDataRequest = this.getGooglePaymentDataRequest();
    paymentDataRequest.transactionInfo = {
      totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
      currencyCode: 'RUB',
    };
    const paymentsClient = this.getGooglePaymentsClient();
    paymentsClient.prefetchPaymentData(paymentDataRequest);
  };

  onError = () => {
    this.inProcess = false;
    this.props.onError({ isServerError: true });
  };

  onGooglePaymentButtonClicked = async (event) => {
    if (this.inProcess) return;

    const button = event.currentTarget;
    button.disabled = true;
    this.inProcess = true;

    try {
      await this.props.onClick('Google Pay');
    } catch (err) {
      this.props.onReset();
      this.inProcess = false;
      button.disabled = false;
      return;
    }

    const paymentDataRequest = this.getGooglePaymentDataRequest();
    paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo();

    const paymentsClient = this.getGooglePaymentsClient();

    if (this.IS_MOBILE_APP && typeof this.props.mobileAppFunction === 'function') {
      this.props.mobileAppFunction(paymentDataRequest);
    } else {
      paymentsClient
        .loadPaymentData(paymentDataRequest)
        .then(this.processPayment)
        .catch(this.onError);
    }
  };

  processPayment = (googlePayPaymentObject) => {
    const {
      processPaymentUrl,
      paymentData,
      paymentType,
      mapProcessPaymentReq,
      mapProcessPaymentRes,
      returnUrl,
    } = this.props;
    const number = paymentData.ctn || paymentData.homeLogin;

    axios
      .post(
        processPaymentUrl,
        mapProcessPaymentReq({
          googlePayPaymentObject,
          sum: this.props.sum,
          url: returnUrl,
          paymentType,
          number,
        }),
      )
      .then(({ data }) => {
        const resp = mapProcessPaymentRes(data);
        this.inProcess = false;

        if (resp.isSuccess) {
          this.props.onSuccess();
        } else if (resp.confirmUrl) {
          setCookie({ name: 'googlePayToken', value: resp.token });
          setCookie({ name: 'googlePayTransactionId', value: resp.id });

          window.location.href = resp.confirmUrl;
        } else {
          this.props.onError({ isServerError: false });
        }
      })
      .catch(this.onError);
  };

  render() {
    const { disabled, hidden } = this.props;

    return (
      <div
        className={cx('googlePayButton', { disabled, hidden })}
        ref={(e) => {
          this.buttonElement = e;
        }}
      />
    );
  }
}

GooglePayButton.defaultProps = {
  processPaymentUrl: '/googlepay/processPayment',
  mapProcessPaymentReq: (e) => e,
  mapProcessPaymentRes: (e) => e,
  scrollToPayForm: () => {},
};

GooglePayButton.propTypes = {
  needCreateMobileAppHandler: PropTypes.bool,
  onSuccess: PropTypes.func,
  scrollToPayForm: PropTypes.func,
  paymentType: PropTypes.string,
  onShown: PropTypes.func,
  sum: PropTypes.number,
  onError: PropTypes.func,
  onClick: PropTypes.func,
  onReset: PropTypes.func,
  mobileAppFunction: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  processPaymentUrl: PropTypes.string,
  paymentData: PropTypes.shape({
    statusCode: PropTypes.string,
    statusMessage: PropTypes.string,
    ctn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    homeLogin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  mapProcessPaymentReq: PropTypes.func,
  mapProcessPaymentRes: PropTypes.func,
  returnUrl: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default GooglePayButton;
