import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@beef/ui-kit';

import { pushYm } from '../../../../tempAnalytics/metricUtils';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TDescriptionContainer = {
  btnText?: string;
  description?: string;
  onClick?: () => void;
  title?: string;
};

/** Компонент заголовка с описанием и кнопкой управления для ResultConnect */
export const DescriptionContainer: FC<TDescriptionContainer> = ({
  title,
  btnText,
  onClick,
  description,
}) => {
  const handelClick = (buttonText?: string, pageTitle?: string) => {
    /* Отправка метрики по клику "Понятно" на странице благодарности */
    pushYm({
      page_url: window.location.href,
      page_path: window.location.pathname,
      page_title: pageTitle,
      action: 'click',
      item_type: 'button',
      button_text: buttonText,
    });

    onClick?.();
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('wrapper__content')}>
        {title && <Text size="size2-m">{title}</Text>}
        {description && (
          <Text color="grey60" size="size5-r-s">
            {description}
          </Text>
        )}
      </div>
      {btnText && (
        <div className={cx('wrapper__button')}>
          <Button fullWidth onClick={() => handelClick(btnText, title)}>
            {btnText}
          </Button>
        </div>
      )}
    </div>
  );
};
