import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const BubbleMessage = ({ icon, content }) => (
  <div className={cx('component')}>
    <div className={cx('bubble')}>
      {icon && (
        <div className={cx('bubble__img')}>
          <img alt="" src={icon} />
        </div>
      )}
      <div className={cx('bubble__text')} dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </div>
);

BubbleMessage.propTypes = {
  icon: PropTypes.string,
  content: PropTypes.string,
};

export default BubbleMessage;
