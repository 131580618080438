export const objectToFormData = (obj, form, namespace) => {
  const fd = form || new FormData();
  let formKey;

  Object.keys(obj).forEach((property) => {
    const field = obj[property];
    if (obj.hasOwnProperty(property) && field !== null && field !== undefined) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }

      if (field instanceof Date) {
        fd.append(formKey, field.toJSON());
      } else if (typeof field === 'object' && !(field instanceof File)) {
        // if the property is an object, but not a File or Date, use recursivity.
        objectToFormData(field, fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, field);
      }
    }
  });

  return fd;
};
