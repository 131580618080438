import React, { FC, useCallback, useRef } from 'react';
import { ExtendedSteps } from '@beef/landing-constructor';
import { Link } from '@beef/ui-kit';
import { connect } from 'react-redux';

import {
  voWifiChoosingBrandYM,
  voWifiClickedSettingsButtonYM,
} from 'pages/Services/VoWifiServiceCardLanding/analytics';
// Types
import {
  ClickInfoType,
  IVowifiInstructions,
  SupportStatusType,
} from 'pages/Services/VoWifiServiceCardLanding/types';
import { selectSupportStatus } from 'pages/Services/VoWifiServiceCardLanding/store/selectors';

export const _ExtendedStepsContainer: FC<{
  data: IVowifiInstructions;
  supportStatus: SupportStatusType;
}> = ({ data, supportStatus }) => {
  const { title, tabs, sections } = data;

  const contentExtended = sections.map((section) => ({
    actionPanel:
      section?.buttons ?
        <Link
          buttonSize="l"
          buttonVariant="secondary"
          href={section.buttons.secondary.url}
          onClick={() => onButtonClick()}
          target="_blank"
          variant="button"
        >
          {section.buttons.secondary.text}
        </Link>
      : undefined,
    ...section,
  }));

  const currentTabTitle = useRef(tabs?.[0]?.title);

  const onButtonClick = useCallback(() => {
    voWifiClickedSettingsButtonYM(supportStatus);
  }, []);

  const onClickHandle = useCallback(
    (info: ClickInfoType | undefined) => {
      if (info?.tabId) {
        const selectedTab = tabs.find((tab) => tab.id === info?.tabId);
        const selectedTabTitle = selectedTab?.title;
        // ym
        voWifiChoosingBrandYM(selectedTabTitle, supportStatus);
        currentTabTitle.current = selectedTabTitle;
      }
    },
    [tabs],
  );

  return (
    <div id="extended-steps-anchor">
      <ExtendedSteps content={contentExtended} onClick={onClickHandle} tabs={tabs} title={title} />
    </div>
  );
};

export const ExtendedStepsContainer = connect((state) => ({
  supportStatus: selectSupportStatus(state),
}))(_ExtendedStepsContainer);
