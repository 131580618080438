import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button } from '@beef/ui-kit';

import styles from './styles.pcss';

type TButtonTile = {
  buttonText?: string;
  onClick?: () => void;
};
const cx = classNames.bind(styles);

/**
 * Компонент кнопки для плитки тарифа.
 */
export const ButtonTile: FC<TButtonTile> = ({ onClick, buttonText }) =>
  buttonText ?
    <div className={cx('wrapper')}>
      <Button onClick={onClick} size="l" textColor="contentAccent1" type="button" variant="plain">
        {buttonText}
      </Button>
    </div>
  : null;
