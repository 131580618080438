import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import InfoPopup from 'pages-components/InfoPopup';

// Styles
import DigitalBenefit from '../DigitalBenefit';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const DigitalBenefitsList = ({ benefits, fade, showBenefitPicture, showTitleMobile }) => {
  const [benefitIndex, setOpenModal] = useState(null);
  const isOpenInfoPopup = benefitIndex !== null && benefits[benefitIndex].popupUrl;

  return (
    <div className={cx('benefitsList')}>
      {benefits.map((benefit, index) => (
        <div
          className={cx('benefitItem', fade && 'animationBenefit')}
          style={{ animationDelay: fade && `${index * 0.15}s` }}
        >
          <DigitalBenefit
            key={index}
            showTitleMobile={showTitleMobile}
            {...benefit}
            onClick={() => setOpenModal(index)}
            showBenefitPicture={showBenefitPicture}
          />
        </div>
      ))}

      {isOpenInfoPopup && (
        <InfoPopup fetchUrl={benefits[benefitIndex].popupUrl} onClose={() => setOpenModal(null)} />
      )}
    </div>
  );
};

DigitalBenefitsList.propTypes = {
  benefits: PropTypes.array,

  showBenefitPicture: PropTypes.bool,
  showTitleMobile: PropTypes.bool,
  fade: PropTypes.bool,
};

export default DigitalBenefitsList;
