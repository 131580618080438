import { ButtonSizeType, ButtonVariantType } from '@beef/ui-kit';

import { RecursivePartial } from 'utils/types';

export enum EZeroBalanceSupportConstant {
  Timeout = 60000,
  Interval = 5000,
}

// NOTE: Слишком много лишней информации прилетает с сервера
// Нужно оптимизировать

export type TContent = {
  [key: string]: unknown;
  banner: {
    [key: string]: Record<string, unknown>;
    tooltip: {
      connect: string;
      disconnect: string;
    };
  };
  layout: {
    blocks: {
      data: unknown;
      type: string;
    }[];
  };
  polling: {
    interval: number;
    timeout: number;
  };
};

export type TPopup = {
  backButtonText: string;
  bubble: string;
  buttonResetText: string;
  buttonResetUrl: string;
  buttonRetryText: string;
  buttonText: string;
  buttonUrl: string;
  checkDisconnectRequestStatusUrl: string;
  closeAction: string;
  content: string;
  dateText: string;
  description: string;
  requestId: number;
  requestStatus: string;
  title: string;
  titleIcon: string;
  type: string;
  warningText: string;
};

export type TZeroBalanceContextData = {
  alias: string;
  archiveTariffServiceInfo: string;
  archiveText: string;
  authVersion: string;
  availableForConnect: boolean;
  benefits: {
    icon: string;
    legal: string;
    popupContent: string;
    text: string;
    title: string;
  }[];
  bgImageDesktop: string;
  bgImageMobile: string;
  campId: string;
  canBeRemoved: boolean;
  cancelConnectUrl: string;
  connectButton: string;
  connectButtonVersion: string;
  connectPollUrl: string;
  connectPopupUrl: string;
  connectedText: string;
  customServiceParameters: [];
  customServiceParametersHeader: null;
  descriptions: {
    content: {
      label: string;
      legal: string;
      unit: string;
      value: string;
    }[];
    title: string;
  }[];
  disconnectServiceUrl: string;
  errorPopup: TPopup;
  expDate: string;
  extData: {
    content: TContent;
  };
  familyNavigation: string;
  feeType: string;
  id: number;
  interval: number;
  isArchived: boolean;
  isAuthenticated: boolean;
  isAvailableForNextConnection: boolean;
  isConnected: boolean;
  isConnectedLater: boolean;
  isMnpService: boolean;
  link: string;
  mainDescription: string;
  marketCode: string;
  offerCode: string;
  otherPartnerSubscriptions: string;
  pageSubtitle: string;
  pageTitle: string;
  pageTitleBubble: string;
  partnerProduct: string;
  partnerTechName: string;
  productParameters: {
    key: string;
    label: string;
    legal: string;
    template: string;
    unit: string;
    value: string;
  }[];
  productTypeTitle: string;
  requestId: number;
  scrollDownArrow: boolean;
  serviceDisconnectionPopup: TPopup;
  serviceImage: string;
  soc: string;
  subgroupId: string;
  subscriptionStatusText: string;
  techName: string;
  timeout: number;
  timeoutTexts: {
    connectTimeoutText: string;
    disconnectTimeoutText: string;
  };
  titleColor: string;
  warningText: string;
};

export type TRequestStatusKey =
  | 'blockedService'
  | 'blockedServiceDisconnect'
  | 'confirmConnect'
  | 'confirmDisconnect'
  | 'confirmDisconnectArchived'
  | 'disconnectServiceSuccess'
  | 'disconnectStep'
  | 'disconnectStepArchive'
  | 'error'
  | 'errorConnectStep'
  | 'errorDisconnectStep'
  | 'inactiveService'
  | 'requestError'
  | 'success'
  | 'trustPaymentSuccess'
  | 'unavailableService';

export type TRequestStatus = {
  [K in TRequestStatusKey]: {
    button: {
      buttonVariant: ButtonVariantType;
      href: string;
      size: ButtonSizeType;
      text: string;
      variant: ButtonVariantType;
    };
    buttonLink: string;
    buttonReject?: {
      buttonVariant: ButtonVariantType;
      href: string;
      size: ButtonSizeType;
      text: string;
      variant: ButtonVariantType;
    };
    description: string;
    img: string;
    title: string;
  };
} & {
  confirmTexts: {
    buttonText: string;
    buttonUrl: string;
    description: string;
    title: string;
  };
};

export type TZeroBalanceContext = RecursivePartial<{
  content: TContent;
  data: TZeroBalanceContextData;
  requestStatus: TRequestStatus;
  setTrustPaymentData: (data: Record<string, unknown>) => void;
  trustPaymentData: {
    amount: number;
    balanceMax: number;
    balanceMin: number;
    chargeAmount: number;
    curArpu: number;
    curPeriod: number;
    currency: string;
    dueDate: string;
    expPeriod: number;
    message: string;
    messageText: string;
    minArpu: number;
    minPeriod: number;
    numberTp: number;
    status: string;
  };
}>;

export enum ETrustPaymentConstant {
  OneDayInMilliseconds = 1000 * 60 * 60 * 24,
  DefaultExpirationPeriodInDays = 3,
}
