export interface IStoreLinks {
  appGallery: string;
  appStore: string;
  googlePlay: string;
  ruStore: string;
}

export interface IComponentStoreLinks {
  isLightColor?: boolean;
  screenTitle?: string;
  storeLinks: IStoreLinks;
}

export enum OS {
  Android = 'android',
  IOS = 'ios',
}

export enum Stores {
  Android = 'googlePlay',
  Huawei = 'appGallery',
  IOS = 'appStore',
  RuStore = 'ruStore',
}
