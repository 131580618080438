import React from 'react';
import classNames from 'classnames/bind';

import type { IAnalyticsEvents } from 'pages/CarnicaDSLandings/ui/Footer';

import style from './styles.pcss';

const cn = classNames.bind(style);

export interface ISocialLink {
  analyticsEvents?: IAnalyticsEvents;
  name: string;
  srcImage: string;
  url: string;
}

export const SocialLink: React.FC<ISocialLink> = ({ name, srcImage, url, analyticsEvents }) => (
  <li className={cn('social-link', `social-link--${name}`)} key={name}>
    <a
      aria-label={name}
      className={cn('social-link__link', `social-link__link--${name}`)}
      href={url}
      onClick={() => analyticsEvents?.onSocialLinkClick?.(name)}
      rel="nofollow noreferrer"
      target="_blank"
    >
      <img
        alt={`Ссылка на социальную сеть ${name}`}
        className={cn('social-link__img', `social-link__img--${name}`)}
        loading="lazy"
        src={srcImage}
      />
    </a>
  </li>
);
