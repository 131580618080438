import { openAuthModal } from '@beef/layout-kit/utils';

import { postBeepConnect } from '../../api';
import { setStatus } from '../status';
import { selectMetaData } from '../selectors';
import {
  pushAudioConnect,
  pushAudioConnectError,
  pushAudioConnectSuccess,
} from '../../utils/analytics';

export const connectBeep = () => async (dispatch, getState) => {
  pushAudioConnect();

  const { isAuthorized } = selectMetaData(getState());

  if (!isAuthorized) {
    openAuthModal();
    throw new Error('not authorized');
  }

  try {
    await postBeepConnect();
    dispatch(setStatus('success'));
    pushAudioConnectSuccess();
  } catch (err) {
    dispatch(setStatus('error'));
    pushAudioConnectError();
  }
};
