import { useStepNavigation } from './useStepNavigation';
import { EConstructorStep } from '../store/slices/stepperSlice/types';

/** Хук проверяет необходимость нелинейно возвращаться на первый шаг степера */
export const useIsGoToFirstStep = () => {
  const { currentStep } = useStepNavigation();

  /* При уже подключенном шпд нелинейный шаг на стартовый конструктор */
  return currentStep === EConstructorStep.fttbConnectedSettings;
};
