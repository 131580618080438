import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Заглушка с заголовком и описанием */
export const DescriptionSkeleton: FC = () => (
  <section className={cx('wrapper')}>
    <Skeleton className={cx('wrapper__title')} />
    <div className={cx('wrapper__description')}>
      <Skeleton className={cx('wrapper__text')} />
      <Skeleton className={cx('wrapper__text')} />
    </div>
  </section>
);
