import React, { FC, useEffect } from 'react';
import { Modal, Text } from '@beef/ui-kit';
import { connect } from 'react-redux';

import { ModalCancelProps } from 'pages/Services/RedirectServiceCardLanding/components/ModalCancel/types';
import { RedirectStore } from 'pages/Services/RedirectServiceCardLanding/store';
import {
  selectLoadingAction,
  selectRedirectQPContent,
} from 'pages/Services/RedirectServiceCardLanding/store/redirect/selectors';
import { setRedirectData } from 'pages/Services/RedirectServiceCardLanding/store/redirect/asyncActions';
import { resetLoadingAction } from 'pages/Services/RedirectServiceCardLanding/store/redirect';
import { SuccessCancel } from 'pages/Services/RedirectServiceCardLanding/components/Status/SuccessCancel';
import { ErrorStatus } from 'pages/Services/RedirectServiceCardLanding/components/Status/ErrorStatus';
import { ActionBarCancelContent } from 'pages/Services/RedirectServiceCardLanding/components/ModalCancel/ActionBarCancel';

const _ModalCancel: FC<ModalCancelProps> = ({
  onCloseModal,
  content,
  onSetRedirectData,
  loading,
  resetLoading,
}) => {
  useEffect(
    () => () => {
      resetLoading();
    },
    [],
  );

  const ModalContent = () => {
    if (loading === 'succeeded') {
      return <SuccessCancel hideButtons onCloseModal={onCloseModal} />;
    }
    if (loading === 'failed') {
      return <ErrorStatus hideButtons />;
    }

    return <Text size="size2-m">{content.title}</Text>;
  };

  return (
    <Modal
      actionBar={
        <ActionBarCancelContent onCloseModal={onCloseModal} onSetRedirectData={onSetRedirectData} />
      }
      isCloseButtonVisible={false}
      isFixedMinValues
      isOpen
      onClose={onCloseModal}
      size="s"
    >
      {ModalContent()}
    </Modal>
  );
};

const mapStateToProps = (state: RedirectStore) => ({
  loading: selectLoadingAction(state),
  content: selectRedirectQPContent(state)?.unactive,
});

const mapDispatchToProps = {
  onSetRedirectData: setRedirectData,
  resetLoading: resetLoadingAction,
};

export const ModalCancel = connect(mapStateToProps, mapDispatchToProps)(_ModalCancel);
