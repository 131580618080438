import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { BreadCrumbs, Text } from '@beef/ui-kit';
import { Benefit, Benefits, Heading, Link, ScrollContainer } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';
import { isDesktopDevice } from 'utils/device';
import { TARIFF_TYPE, tariffClickYM, tariffTypeYM } from 'utils/analytics/tariffsYandexMetrica';
import Icon from 'pages-components/Icon';
import Picture from 'pages-components/Picture';
import { FloatingPhoneWidget } from 'pages/FTTB2021/components/FloatingPhoneWidget';
import LeaveYourPhonePopupWidget from 'pages/FTTB/components/LeaveYourPhonePopupWidget';
import BackCallPopup from 'pages/FTTB/components/BackCallPopup';
import BackCallSection from 'pages/FTTB/components/BackCallSection';
import Discount from 'pages-components/Discount';
import TariffCard from 'pages-components/TariffCard';
import TariffCardParamsHoc from 'pages-components/TariffCard/hocs/TariffCardParamsHoc';

import styles from './AllInOne.pcss';
import mapData from './mappers';
import { ExtraServices, HeadBanner, Questions, Sections } from './components';

const cx = classNames.bind(styles);

const STOP_FLOATING_BLOCK_POSITION = -100;

class AllInOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mappedData: mapData(props.data),
      showBackCallPopup: false,
    };
  }

  componentDidMount() {
    tariffTypeYM.set(TARIFF_TYPE.conv);

    try {
      window.dataLayer.push({
        event: 'tariffs_list',
        tariffs: this.state.mappedData.tariffs.tariffs.map((item) => item.title),
      });
    } catch (er) {} // eslint-disable-line
  }

  getSubTitleText = (mobile, headingMotto, hasDiscount) => {
    if (hasDiscount) return headingMotto.discountSubTitle;

    return mobile ? headingMotto.textMobile : headingMotto.text;
  };

  handleGoUpClick = () => {
    this.anchor.scrollIntoView(
      {
        behavior: 'smooth',
        block: 'start',
      },
      100,
    );
  };

  handleShowPopupButton = () => {
    this.setState(({ showBackCallPopup }) => ({ showBackCallPopup: !showBackCallPopup }));
  };

  handleTariffCardButtonClick = () => {
    tariffClickYM();
    this.handleShowPopupButton();
  };

  render() {
    const { mobile } = this.props;
    const { mappedData } = this.state;

    const {
      headingMotto = {},
      benefits = [],
      tariffs = {},
      sections = [],
      contacts = [],
      questionsPages = [],
      archive = {},
      callBackRequestData = {},
      backCallData = {},
      selfcallingPopupData = {},
      extraServices = null,
      breadcrumbs = [],
    } = mappedData;

    const { forceShowBackCallPopup } = this.props;

    return (
      <div className={cx('pageWrapper', { mobile })}>
        {!!breadcrumbs.length && (
          <div className={cx('breadcrumbs')}>
            <BreadCrumbs path={breadcrumbs} />
          </div>
        )}
        {headingMotto.showBackImage && (
          <img alt="" className={cx('backImg')} src={headingMotto.backgroundImage} />
        )}
        <div className={cx('headingMotto')}>
          <Heading className={cx('headingText', 'headingTitle')} level={1}>
            {mobile ? headingMotto.headingMobile : headingMotto.heading}
          </Heading>

          {headingMotto?.headBannerData && (
            <HeadBanner
              callBackRequestData={callBackRequestData}
              data={headingMotto.headBannerData}
            />
          )}

          <Heading className={cx('headingText', 'headingSubTitle')} level={2}>
            {!headingMotto?.disableDiscountBadge && !!tariffs.catalogDiscountText && (
              <Discount.Label className={cx('discountLabel')} label={tariffs.catalogDiscountText} />
            )}
            <span
              dangerouslySetInnerHTML={{
                __html: this.getSubTitleText(mobile, headingMotto, !!tariffs.catalogDiscountText),
              }}
            />
          </Heading>

          <Picture
            source={[
              {
                srcSet: headingMotto.imageMobile,
                media: 'max-width: 767px',
              },
            ]}
            src={headingMotto.image}
          />
        </div>

        <div
          ref={(e) => {
            this.anchor = e;
          }}
        />

        {tariffs.tariffs ?
          <div className={cx('tariffsWrapper')}>
            <ScrollContainer handleShouldPlayDemo={() => false}>
              <div className={cx('tariffsList')}>
                {tariffs.tariffs.map((tariff, i) => (
                  <div className={cx('tariff')} key={+i}>
                    <TariffCardParamsHoc
                      {...tariff}
                      buttonGroup={{
                        orderLink: tariff.orderLink,
                        aboutLink: tariff.link,
                        orderButtonText: tariff.orderButtonText,
                        aboutLinkText: tariff.aboutLinkText,
                        backCallPopupButton: {
                          forceShowBackCallPopup,
                          setShowPopup: this.handleTariffCardButtonClick,
                        },
                      }}
                      onButtonClick={(e, type) => {
                        if (type === 'mainButton') {
                          window.location.href = tariff.link;
                        }
                      }}
                      tariffList={tariffs.tariffs}
                    >
                      <TariffCard />
                    </TariffCardParamsHoc>
                  </div>
                ))}
              </div>
            </ScrollContainer>
          </div>
        : null}

        <div className={cx('benefitsWrapper')}>
          <Benefits className={cx('benefits')} showTitles>
            {benefits.map((benefit, i) => (
              <Benefit emoji={benefit.emoji} icon={benefit.icon} key={+i} title={benefit.title}>
                <div className={cx('benefit__text')}>
                  <Text size="size7-r">{benefit.text}</Text>
                </div>
              </Benefit>
            ))}
          </Benefits>
        </div>

        <Sections mobile={mobile} sections={sections} />

        {!!extraServices?.length && <ExtraServices data={extraServices} />}

        <div className={cx('phoneWrapper')}>
          {tariffs.tariffs && (
            <button className={cx('goUpBtn')} onClick={this.handleGoUpClick} type="button">
              <Icon className={cx('goUpArrow')} name="dropdownArrow" />
              <span>Вернуться к выбору тарифа</span>
            </button>
          )}
          {archive.link && (
            <div className={cx('archiveWrapper')}>
              <Heading level={2} tagName="div">
                <span
                  dangerouslySetInnerHTML={{ __html: 'Уже подключили тариф, но не нашли его?' }}
                />
              </Heading>
              <Link className="hideVisitedColor" href={archive.link}>
                <span className={cx('archiveLink')}>{archive.linkText}</span>
              </Link>
            </div>
          )}
          {contacts.number && (
            <div className={cx('desktopOnly')} id="FloatingPhoneWidget-stopPosition">
              <BackCallSection
                backCallPopupData={callBackRequestData}
                data={backCallData}
                isRedesign
                showCaptcha={callBackRequestData.showCaptcha}
              />
            </div>
          )}
        </div>

        <div className={cx('questionsWrap')}>
          <Questions className={cx('questions')} questionsPages={questionsPages} />
        </div>

        {selfcallingPopupData?.showSelfCallingPopup && (
          <LeaveYourPhonePopupWidget
            backCallPopupData={callBackRequestData}
            redesign
            selfCallingPopupData={selfcallingPopupData}
          />
        )}
        {this.state.showBackCallPopup && (
          <BackCallPopup
            data={callBackRequestData}
            isOpen={this.state.showBackCallPopup}
            onClose={this.handleShowPopupButton}
            redesign
            showCaptcha={callBackRequestData?.showCaptcha}
          />
        )}
        <FloatingPhoneWidget
          customCorrectionPosition={STOP_FLOATING_BLOCK_POSITION}
          data={callBackRequestData}
          isFMC
          redesign
          stopBlockId={archive.link ? 'FloatingPhoneWidget-stopPosition' : ''}
        />
      </div>
    );
  }
}

AllInOne.propTypes = {
  data: PropTypes.shape({
    selfcallingPopupData: PropTypes.shape({
      showSelfCallingPopup: PropTypes.bool,
      setPopupTimeout: PropTypes.number,
    }),
    headingMotto: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      headBannerData: PropTypes.object,
      disableDiscountBadge: PropTypes.bool,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    extraServices: PropTypes.array,
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }),
  callBackRequestData: PropTypes.shape({
    showCaptcha: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    texts: PropTypes.object,
  }),
  mobile: PropTypes.bool,
  forceShowBackCallPopup: PropTypes.bool,
};

const ConnectedAllInOne = connect((state) => ({
  mobile: !isDesktopDevice(state),
}))(AllInOne);

export default (props) => (
  <Provider store={store}>
    <ConnectedAllInOne {...props} />
  </Provider>
);
