import { axiosBeelineRequest } from '@beef/utils';

import { TPartnerProductInfoResponse } from './types';

export const fetchYandexProducts = (soc?: string): Promise<TPartnerProductInfoResponse> =>
  axiosBeelineRequest<TPartnerProductInfoResponse>({
    method: 'get',
    url: '/api/partner-subscriptions/yandex-products-list/',
    params: {
      soc,
    },
    headers: {
      'content-type': 'application/json',
    },
  });
