import { type MouseEvent, useCallback, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { TModalHeader } from '../../../modalComponents/ModalHeader';
import { TModalContainer } from '../../../modalComponents/ModalContainer';
import { setGbytes, setMinutes } from '../../../../store/slices/totalSlice';

/** Хук для подготовки данных модального окна информационного баннера под предварительным чеком */
export const useFamilyPopup = () => {
  const dispatch = useAppDispatch();
  const contentPopup = useAppSelector((state) => state.common?.content.familyBanner?.contentPopup);

  const { link, image, title, description, buttonText, preset } = contentPopup || {};

  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const onClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      if (preset?.gb) {
        dispatch(setGbytes(preset.gb));
      }

      if (preset?.min) {
        dispatch(setMinutes(preset.min));
      }

      onClose();
    },
    [preset],
  );

  const modalContainerProps: Omit<TModalContainer, 'children'> | null = useMemo(() => {
    if (contentPopup) {
      return {
        isOpen,
        size: 'm',
        onClose,
        modalActionBarProps: {
          link: link ?? '',
          buttonText,
          onClick,
        },
      };
    }

    return null;
  }, [contentPopup, isOpen]);

  /* Проп для контентной части модалки. Содержит картинку, заголовок и описание.
   * Приведение типа используется из-за опционального наличия попапа. Деструктуризация добавляет
   * неправильный undefined в случае существования contentPopup */
  const modalHeaderProps: TModalHeader | null = useMemo(() => {
    if (contentPopup) {
      return { image, title, description };
    }

    return null;
  }, [contentPopup]);

  return {
    onOpen,
    modalHeaderProps,
    modalContainerProps,
  };
};
