import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Picture from 'pages-components/Picture';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Обновленный компонент Benefit. Может быть как row так и column. Отсутствует фон у картинки и содержит заголовок;
 * @param type - Тип отображения бывает row/column;
 * @param className - Доп класс;
 * @param popupContent - Контент для модального окна вызываемого из бенефита;
 * @param popupUrl - Url по которому запрашиваются данные попапа которые лежат в QP;
 * @param icon - Иконка бенефита;
 * @param title - Заголовок бенефита;
 * @param text - Текст бенефита
 * @param legal - Название ссылки вызова модального окна;
 * @param onClick - callback для кнопки в бенефите;
 */
const Benefit = ({
  type,
  className,
  onClick,
  benefitData: { popupContent, popupUrl, icon, title, text, legal },
}) => (
  <div className={cx('benefit', type, className)}>
    {icon && (
      <div className={cx('icon')}>
        <Picture alt="benefit" src={icon} />
      </div>
    )}
    <div className={cx('info')}>
      {title && <div className={cx('title')} dangerouslySetInnerHTML={{ __html: title }} />}
      {text && <div className={cx('description')} dangerouslySetInnerHTML={{ __html: text }} />}
      {legal && (popupContent || popupUrl) && (
        <Link className={cx('link')} onClick={onClick}>
          <span dangerouslySetInnerHTML={{ __html: legal }} />
        </Link>
      )}
    </div>
  </div>
);

Benefit.defaultProps = { type: 'row' };

Benefit.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  benefitData: PropTypes.shape({
    popupUrl: PropTypes.string,
    popupContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    legal: PropTypes.string,
  }),
};

export default Benefit;
