import { useLayoutEffect, useRef } from 'react';

/** Подготавливает горизонтальный скролл в пикерах чтобы был виден дефолтный пакет */
export const usePreparePresetPicker = () => {
  const ref = useRef<HTMLButtonElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    /* Вычисляем расстояние */
    const elemLeft = ref?.current?.offsetLeft;

    /* Выставляем горизонтальный скролл по видимости активного пресета */
    if (elemLeft && refContainer.current) {
      refContainer.current.scrollTo(elemLeft, 0);
    }
  }, []);
  return { ref, refContainer };
};
