import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TPreviewTariff } from '../components/constructorPage/FmcTabsContainer/types';
import { apiGetFmcPresets } from './constants';

/** Тип параметров запроса метода */
type TFetchFmcPresetsReq = {
  generation: number;
  regionId: number;
  soc: string;
};

/** Тип ответа метода */
export type TFetchFmcPresetsQueryRes = {
  isSuccess?: boolean;
  tariffs?: TPreviewTariff[];
};

/** Запрос данных по доступным пресетам ШПД на главной странице конструктора */
export const fetchFmcPresets = createAsyncThunk<TFetchFmcPresetsQueryRes, TFetchFmcPresetsReq>(
  'presetApi/fetchFmсPresets',
  async (params, { signal }) => {
    try {
      return (
        await axios.get<TFetchFmcPresetsQueryRes>(apiGetFmcPresets, {
          params,
          signal,
        })
      ).data;
    } catch (e) {
      throw new Error();
    }
  },
);
