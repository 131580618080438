import React, { useState } from 'react';
import classNames from 'classnames/bind';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, Link } from '@beef/ui-kit';

import RequestStatus from 'pages-components/RequestStatus';
import { API_ENDPOINTS } from 'utils/partnershipTariffHelper';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const StandaloneDisconnect = ({
  pendingContent,
  disconnectContent,
  redirectUrl,
  standaloneData,
  onSuccess,
  onError,
}) => {
  const [pending, setPending] = useState(false);

  const handleClick = async () => {
    setPending(true);

    try {
      const res = await axios.post(API_ENDPOINTS.STANDALONE_DISCONNECT, {
        id: standaloneData.subscriptionId,
        type: standaloneData.subscriptionType,
      });

      if (res.data.isSuccess && res.data.requestId) {
        onSuccess();
      } else {
        onError();
      }
    } catch (e) {
      console.error(e);
      onError();
    }
  };

  return (
    <>
      <RequestStatus
        data={pending ? pendingContent : disconnectContent}
        fullSize
        pending={pending}
      />
      <div className={cx('controls')}>
        <div className={cx('controls__item')}>
          <Button fullWidth onClick={handleClick} size="l">
            {disconnectContent.acceptButtonText}
          </Button>
        </div>
        <div className={cx('controls__item')}>
          <Link
            buttonSize="l"
            buttonVariant="secondary"
            href={redirectUrl}
            isFullWidth
            variant="button"
          >
            {disconnectContent.declineButtonText}
          </Link>
        </div>
      </div>
    </>
  );
};

StandaloneDisconnect.propTypes = {
  pendingContent: PropTypes.object,
  disconnectContent: PropTypes.object,
  redirectUrl: PropTypes.string,
  standaloneData: PropTypes.shape({
    subscriptionId: PropTypes.string,
    subscriptionType: PropTypes.string,
  }),
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default StandaloneDisconnect;
