/**
 * Проверяем пришел legal или нет, если пришел, передаем строку из объекта;
 * Текст этого поля будет использоватыся в TooltipZ;
 * --------------------------------------------------------
 * @param legal - Объект с текстом и текстовым напрвлением;
 * @param typeName - Имя типа;
 * @return {string}
 */
export const getLegal = (legal, typeName) => (legal && legal.type === typeName ? legal.text : '');

/**
 * Склеевает строку из параметра и еденицы измерения;
 * --------------------------------------------------
 * @param param - Объект с параметрами;
 * @return {string}
 */
export const joinValueUnit = (param) =>
  `${param.value ? param.value : ''} ${param.unit ? param.unit : ''}`;

/**
 * @desc: вычисляет текст для параметра с типом starting-balance в зависимости от стартового баланса
 * @param param - данные о параметре { object }
 * @param description - дефолтное описание { string }
 * @returns {string}
 */
const getStartingParamFullPrice = (param, description) => {
  if (param.youthTariffPrice > 0 && param?.data?.nameForFullPrice) {
    return param.data.nameForFullPrice;
  }
  return description;
};

export const getParams = (type, param, discountParams) => {
  const descriptionTooltip = getLegal(param.legal, 'common');
  let textTooltip = getLegal(param.legal, 'beside-value');
  let description = param.label;
  let objectWithParams = {};

  // Иконка в левой части tiles, в параметре,
  // используется для отрисовки бесконечности;
  const infinitySymbol = param.value && param.value.match(/infinitySymbol/);
  if (infinitySymbol) {
    objectWithParams = {
      valueIcon: {
        iconName: 'black-infinity',
        icon: '//static.beeline.ru/upload/images/icons/alive-ruler-icons/black-infinity.svg',
      },
    };
  }

  switch (type) {
    case 'messengers':
    case 'tethering':
      // Для иконок используем приставку _card, это необходимо для
      // разделения иконок карточки в каталоге и карточки тарифа;
      objectWithParams = {
        outerIcon: {
          icon: `//static.beeline.ru/upload/images/icons/alive-ruler-icons/${param.widgetType}_card.svg`,
          iconName: type,
        },
        text: param.value,
        descriptionTooltip,
        description,
      };

      break;

    case 'rowButton':
      objectWithParams = {
        connected: param.connected,
        text: param.value,
        icon: param.icon,
        descriptionTooltip,
        description,
        textTooltip,
      };
      break;

    case 'starting-balance':
      const youthTariffPrice = Math.max(param.youthTariffPrice, param?.data?.startingBalanceValue);

      const priceText = `${youthTariffPrice} ${param.unit}`;
      objectWithParams = {
        connected: param.connected,
        text: priceText,
        icon: param.icon,
        descriptionTooltip,
        description: getStartingParamFullPrice(param, description),
        textTooltip,
      };
      break;

    case 'withImage':
      objectWithParams = {
        connected: param.connected,
        text: param.value,
        description,
        bottomIcon: {
          src: param.data.image,
          name: 'social',
        },
        descriptionTooltip,
        textTooltip,
      };
      break;

    case 'action':
      objectWithParams = {
        descriptionTooltip,
        text: param.label,
        ...(param.icon && { icon: param.icon }),
      };
      break;

    case 'beeLogo':
      // Если есть value и не иконка infinity ваыводим обычное значение а иначе unit;
      const textTileBeeLogo =
        param && param.value && !infinitySymbol ? joinValueUnit(param) : param.unit || '';

      objectWithParams = {
        ...objectWithParams,
        text: textTileBeeLogo,
        beeLogo: true,
        description,
      };

      break;

    case 'regularButton':
      const isSubText = param.widgetType === 'constructor-minutes' ? '+ настройка минут' : null;

      // В редких случаях, такой финт может
      // использоваться для имитации Tiles заголовка;
      let tilesText = joinValueUnit(param);

      if (!param.value && param.widgetType === 'normal') {
        tilesText = param.label;
        description = '';
      }

      objectWithParams = {
        ...objectWithParams,
        connected: param.connected,
        subText: isSubText,
        descriptionTooltip,
        text: tilesText,
        textTooltip,
        description,
      };

      if (param.widgetType === 'unlim-call' && param.icon) {
        objectWithParams.unlimIcon = {
          icon: param.icon,
        };
      }

      break;

    case 'discountedSim':
    case 'discountedPrice':
      const discount = param.data.simDiscountText;
      const price = param.data.fullPrice;

      textTooltip = param.legal && param.legal.text;

      objectWithParams = {
        text: `${param.value} ${param.unit ? param.unit : ''}`,
        tilesStyle: param.isSimDiscountDeliveryOnly ? 'white' : 'dark',
        textTooltip,
        description,
        discount,
        price,
      };
      break;

    case 'extraValue':
      const { icon } = param.data;
      const value = param.data.value || '';
      const unit = param.unit || '';

      textTooltip = param.legal && param.legal.text;

      objectWithParams = {
        text: `${param.value} ${unit}<br/>${value}`,
        textTooltip,
        description,
        icon,
      };
      break;

    case 'withPopup':
    case 'InfoWithPopup':
      const isAmedia = param.widgetType === 'amediateka';
      const isIconImg = param.widgetType === 'sharing';

      let iconParam = {};
      let image = {};

      if (param.icon && !isAmedia) {
        iconParam = {
          icon: param.icon,
        };
      }

      if (param.icon && isAmedia) {
        image = {
          image: param.icon,
        };
      }

      if (param.icon && isIconImg) {
        iconParam = {
          icon: null,
          iconImg: param.icon,
        };
      }

      objectWithParams = {
        fetchUrl: param.data.contentUrl,
        text: joinValueUnit(param),
        type: 'rowButton',
        description,
        widgetType: param.widgetType,
        ...iconParam,
        ...image,
      };
      break;

    case 'singleRowButton':
      objectWithParams = { text: param.label };
      break;

    case 'strikedOut':
      objectWithParams = {
        value: param.value,
        unit: param.unit,
        entityDesc: discountParams?.entityDesc,
      };

      break;

    case 'bubble':
      objectWithParams = {
        label: param.label,
        iconName: 'present',
      };
      break;
  }

  return objectWithParams;
};

/** Складывает в одну группу элементы с типом regularType */
export const getParamsGroup = (arr) =>
  arr.reduce((prev, curr) => {
    prev.push(getParams('regularButton', curr));
    return prev;
  }, []);

export const PACKS_TITLES = {
  kbyte: 'Мобильный интернет',
  seconds: 'Звонки',
  sms: 'SMS',
};
