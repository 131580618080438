import React from 'react';
import { withDataConverter } from '@beef/react';
import { StoriesV2 } from '@beef/smart-kit';
import classnames from 'classnames/bind';

import { storiesListAdapter } from 'pages/Help2022/adapters';

import styles from './styles.module.pcss';
import { TStories } from './types';

const cx = classnames.bind(styles);

const _StoriesBlock = ({ stories }: TStories) => {
  return (
    <div className={cx('stories')}>
      <StoriesV2 lazy={false} list={stories} />
    </div>
  );
};

export const StoriesBlock = withDataConverter(storiesListAdapter)(_StoriesBlock);
