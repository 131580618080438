import { useSendBillYm } from './useSendBillYm';
import { useSelectContent } from '../selectors/useSelectContent';
import { useStepNavigation } from '../../../../hooks/useStepNavigation';
import { useThrowAsyncError } from '../../../../hooks/useThrowAsyncError';
import { EUserStoryType } from '../../../../store/slices/stepperSlice/types';
import { useConnectBasket } from '../../../totalBillPageComponents/TotalBill/hooks/useConnectBasket';

/** Хук подготовки данных для компонента Bill */
export const usePrepareBillProps = () => {
  const throwAsyncError = useThrowAsyncError();
  const getAnalyticsByStep = useSendBillYm();
  const { nextStep, isLoadingNext } = useStepNavigation();
  const { btnProps, totalProps } = useSelectContent();

  /* Тип текущего пользователя */
  const { userType } = useStepNavigation();

  /* Функция обработчик для кнопки "Оплатить" БЕЗ ШПД настроек */
  const { onBasket } = useConnectBasket();

  /* Обработчик клика на кнопку "Далее" в mini-чеке */
  const onClick = () => {
    /* Подготовка данных и отправка метрики */
    getAnalyticsByStep();

    /* Если пользовательский тип равен 0 ("НЗ без ШПД", всегда будет переход в корзину (onBasket) */
    if (userType === EUserStoryType.notAuthWithoutFTTB) {
      onBasket().catch((e: Error) => throwAsyncError(e));
    } else {
      /* Во всех остальных случаях стандартная логика (nextStep) */
      nextStep();
    }
  };

  const billBtnProps = { onClick, isLoading: isLoadingNext, ...btnProps };

  return { totalProps, billBtnProps };
};
