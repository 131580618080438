import { useCallback } from 'react';

import { TUseMapStateToProps } from 'pages/Services/ZeroBalanceSupport/enhancers/withContext/types';
import { selectBlockedService } from 'pages/Services/ZeroBalanceSupport/selectors';
import { TZeroBalanceContext } from 'pages/Services/ZeroBalanceSupport/types';

import { TModalWrapperProps } from '../../../ModalWrapper/types';
import { EModal } from '../types';

export const useMapStateToProps: TUseMapStateToProps<TZeroBalanceContext, TModalWrapperProps> = ({
  state,
  ownProps: { setCurrentModal },
}) => {
  const modalData = selectBlockedService(state);

  const onClick = useCallback(() => {
    setCurrentModal(EModal.TrustPayment);
  }, [setCurrentModal]);

  const onClose = useCallback(() => {
    setCurrentModal(null);
  }, [setCurrentModal]);

  return {
    onClose,
    setCurrentModal,
    content: {
      modalData,
      actionBarProps: {
        loading: false,
        confirmButton: {
          content: modalData?.button || {},
          onClick,
        },
        rejectButton: {
          content: modalData?.buttonReject || {},
          onClick: onClose,
        },
      },
    },
  };
};
