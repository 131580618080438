import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'constructorV1/store';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { useConstructorLink } from 'constructorV1/router/hooks/useConstructorLink';
import { setCBillData, setCBillIsLoading } from 'constructorV1/store/commonBillSlice';

/** Хук подготовки функции-обработчика для кнопки "Назад" в результирующем чеке ЕК */
export const useCBackButton = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  /** Флаг определяет, является ли текущий просматриваемый экран mobile-формата */
  const isMobile = useIsMobileDimension();

  /** Если пользователь просматривает не mobile-версию страницы, покажем кнопку (в мобиле по просьбе бизнеса кнопка скрывается) */
  const isVisible = !isMobile;

  /** Ссылка для возврата на главную страницу конструктора */
  const mainLink = useConstructorLink('main');

  /** Функция возврата на стартовую страницу */
  const handleClick = () => {
    /* Сбрасываем флаг загрузки в store */
    dispatch(setCBillIsLoading(false));
    /* Сбрасываем подготовленные данные чека в store */
    dispatch(setCBillData(null));
    /* Возвращаемся на главную страницу */
    navigate(mainLink);
  };

  return { isVisible, handleClick };
};
