import { exceptCutOutOptionsSocs } from './exceptCutOutOptionsSocs';
import { collectCutOutOptionsSocs } from './collectCutOutOptionsSocs';
import { TOptionsSlice } from '../../../../store/slices/optionsSlice/types';
import { transformCharacterSoc } from '../../../../utils/prepareOptionsForPassiveAbility';

/** Функция позволяет "вырезать" соки опций-пассивки для исправления бага в корзине.
 * Костыль временный на нашей стороне. Корзина уже работает над целевым решением */
export const cutPassiveOptionsSocs = (
  options: TOptionsSlice,
  optionsSoc: string[],
  characterSoc: string,
) => {
  /** Обратное преобразование characterSoc.
   * Трансформация: POWER11(норма, используется везде) -> poweR11 (только для characterTypes) */
  const rawCharacterSoc = transformCharacterSoc(characterSoc);

  /** Подготовленные соки по выбранному персонажу с признаком isBlocked */
  const cutOutOptionsSocs = collectCutOutOptionsSocs(options, rawCharacterSoc);

  /** Возвращаем массив отфильтрованных соков опций для корзины  */
  return exceptCutOutOptionsSocs(optionsSoc, cutOutOptionsSocs);
};
