import { useState } from 'react';

import { fetchActions } from 'pages/ActionCategoryPage/services/fetchActions';
import { DataType, ResponseType } from 'pages/ActionCategoryPage/types';

export const useGetActions = ({ data, pageInfo, alias }: ResponseType) => {
  const [actions, setActions] = useState<DataType[]>(data);
  const [page, setPage] = useState(pageInfo);
  const [isLoading, setIsLoading] = useState(false);

  const getMoreActions = async () => {
    setIsLoading(true);
    try {
      const newData = await fetchActions(page.pageNumber + 1, alias);
      if (newData?.data?.length) {
        setActions([...actions, ...newData.data]);
      }
      if (newData?.pageInfo) {
        setPage(newData.pageInfo);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { actions, getMoreActions, isLast: actions.length === page.totalCount, isLoading };
};
