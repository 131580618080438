export enum INFO_BLOCK_TYPE {
  login = 'elk',
  move = 'mnp',
  support = 'support',
  topUp = 'balance',
}

export enum BANNER_BLOCK_EVENT {
  click = 'click',
  view = 'view',
}
