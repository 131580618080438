import { useContext } from 'react';

import { getActiveCharacterPassive } from 'pages/ProductCard/Tariff/containers/ConstructorTariff/utils';

import { usePrepareModalContent } from './usePrepareModalContent';
import { ConstructorContext } from '../../../containers/ConstructorTariff/constants';

/** Подготовка данных для PassiveContainer */
export const usePreparePassive = () => {
  /** Через контекст достаем актуальные данные по апперам, активному соку и типу пресета */
  const { characters, activeOptionSoc, isHighPreset, passivePopups } =
    useContext(ConstructorContext);

  /** Пропы для компонента Modal в Passive */
  const { openModal, modalContainerProps } = usePrepareModalContent();

  /** Пропы для блока Passive (плашка под пресетом Гб).
   * Включает в себя общий попап passivePopups (описание пассивок),
   * passiveContentProps (иконка и текст плашки) и backgroundColor (фон плашки) */
  const passiveContainerProps = {
    passivePopups,
    ...getActiveCharacterPassive(characters, activeOptionSoc, isHighPreset),
  };

  /** Общие пропы для компонента Passive */
  const passiveProps = {
    modalContainerProps,
    handleClick: openModal,
    ...passiveContainerProps,
  };

  return { passiveProps };
};
