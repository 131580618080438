import { RedirectStore } from 'pages/Services/RedirectServiceCardLanding/store';
import {
  ContentDpc,
  ContentQp,
  LoadingType,
} from 'pages/Services/RedirectServiceCardLanding/types';

export const selectLoadingInitialData = (state: RedirectStore) => state.redirect?.loading;

export const selectLoadingAction = (state: RedirectStore) =>
  state.redirect?.loadingAction as LoadingType;

export const selectRedirectOptions = (state: RedirectStore) => state.redirect?.options;

export const selectRedirectQPContent = (state: RedirectStore) =>
  state?.content?.contentQp as ContentQp;

export const selectDpcContent = (state: RedirectStore) => state?.content?.contentDpc as ContentDpc;

export const selectAuthenticated = (state: RedirectStore) =>
  selectDpcContent(state)?.isAuthenticated === true;
