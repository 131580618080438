import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@beef/legacy-ui-kit';
import { getSubstate } from '@beef/legacy-beeline-store/utils';
import { Provider, connect } from 'react-redux';
import classNames from 'classnames/bind';

import store from 'store';
import Option from 'pages-components/Option/Option';

import { servicesData, tariffData } from '../../actions/servicesExtension';
import styles from './ServicesExtension.pcss';
import serviceResolver from './servicesResolver';

const cx = classNames.bind(styles);

export class ServicesExtension extends Component {
  componentDidMount() {
    store.dispatch(tariffData.update(this.props.data));
  }

  render() {
    const services = this.props.servicesData;

    if (services && services.length > 0) {
      const options = services.map((service, index) => {
        const serviceSettings = serviceResolver(service.serviceType);

        return (
          <div className={cx('optionWrapper')} key={index}>
            <Option
              active={
                service.isConnected === 'CONNECTED' ||
                service.isConnected === 'IN_PROGRESS' ||
                service.isConnected === true
              }
              bgUrl={service.backgroundImageUrl}
              dark={!!service.dark}
              description={service.isConnected === 'NOT_CONNECTED' ? service.description : null}
              inProgress={service.isConnected === 'IN_PROGRESS'}
              onBodyClick={serviceSettings.onBodyClick}
              onClick={serviceSettings.onClick}
              price={service.price}
              settingsContent={service.isConnected ? serviceSettings.settingsContent : null}
              title={service.title}
            />
            <serviceSettings.component isAuthenticated={this.props.isAuthenticated} />
          </div>
        );
      });

      return (
        <div className={cx('options')}>
          <Heading level={1}>Опции и услуги</Heading>
          {options}
        </div>
      );
    }

    return null;
  }
}

ServicesExtension.propTypes = {
  data: PropTypes.object,
  servicesData: PropTypes.array,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  servicesData: (getSubstate(state, servicesData) || {}).services,
  isAuthenticated: getSubstate(state, tariffData).isAuthenticated,
});

const ConnectedServicesExtension = connect(mapStateToProps)(ServicesExtension);

export default (props) => (
  <Provider store={store}>
    <ConnectedServicesExtension {...props} />
  </Provider>
);
