import { useEffect } from 'react';

// Hooks
import submitCallbackRequest from 'pages/FTTB/services/submitCallbackRequest';
import { useDebounce } from 'utils/hooks/useDebounce';
// Services
import checkConnection from 'pages/FTTB/services/checkConnection';
// Utils
import { composeConnectionData } from 'pages/FTTB2021/components/OrderTariffFormPopup/utils';

import { getConnectionStatus } from '../utils';
// Constants
import { DELAY_TIME, NUMBER_PHONE_LENGTH, REQUEST_STATUS, popupSteps } from '../constants';

/**
 * useAddressVerification: хук верификации адреса пользователя.
 * @param flat
 * @param house
 * @param street
 * @param setStep
 * @param userNumber
 * @param regionLabel
 * @param setIsLoading
 * @param setHighSpeed
 * @param setDefaultTab
 * @param checkAddressType
 * @param setAddressChecked
 * @param currentStatusConnection
 */
export const useAddressVerification = ({
  flat,
  house,
  street,
  setStep,
  userNumber,
  regionLabel,
  setIsLoading,
  setHighSpeed,
  checkAddressType,
  setAddressChecked,
  currentStatusConnection,
}) => {
  const deferredFlat = useDebounce(flat, DELAY_TIME);
  const deferredStreet = useDebounce(street, DELAY_TIME);
  const deferredNumber = useDebounce(userNumber, DELAY_TIME);
  const deferredHouse = useDebounce(house.isConnected, DELAY_TIME);
  const isNotAvailable = currentStatusConnection !== REQUEST_STATUS.isAvailable;

  const handleCheckConnection = () => {
    if (!regionLabel || !street?.label || !house?.label || !flat || !userNumber) {
      return;
    }

    setIsLoading(true);

    const connectionData = composeConnectionData({
      flat,
      house,
      street,
      checkAddressType,
      isConvergentRequest: true,
      region: { label: regionLabel },
    });

    checkConnection(connectionData)
      .then(({ data: { View, IsSucceeded } }) => {
        if (!IsSucceeded) {
          return setStep(popupSteps.connectFail);
        }

        const status = getConnectionStatus({ ...View, setHighSpeed });
        setAddressChecked(status);
        return status;
      })
      .then((status) => {
        if (status === REQUEST_STATUS.isExists) {
          submitCallbackRequest({
            phoneNumber: `+7${userNumber}`,
            caseTitle: 'sale',
          });
          setStep(popupSteps.requestExists);
        }
        if (status === REQUEST_STATUS.isConnected) {
          setStep(popupSteps.connectCompleted);
        }
      })
      .catch(() => {
        setAddressChecked(REQUEST_STATUS.isNotAvailable);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (userNumber.length === NUMBER_PHONE_LENGTH && isNotAvailable) {
      handleCheckConnection();
    }
  }, [deferredStreet, deferredHouse, deferredFlat, deferredNumber, isNotAvailable]);
};
