export default [
  {
    type: 'percent',
    image: '//static.beeline.ru/upload/images/new-shpd-catalog/top/imgs/percent.png',
    length: 4,
  },
  {
    type: 'rub',
    image: '//static.beeline.ru/upload/images/new-shpd-catalog/top/imgs/rub.png',
    length: 4,
  },
  {
    type: 'dot',
    weight: 'small',
    length: 7,
  },
  {
    type: 'dot',
    weight: 'big',
    length: 4,
  },
];
