import React, { FC } from 'react';
import { ActionBar, Button } from '@beef/ui-kit';
import { connect } from 'react-redux';

import { RedirectStore } from 'pages/Services/RedirectServiceCardLanding/store';
import {
  selectLoadingAction,
  selectRedirectQPContent,
} from 'pages/Services/RedirectServiceCardLanding/store/redirect/selectors';
import { ActionBarSettingsProps } from 'pages/Services/RedirectServiceCardLanding/components/ModalSettings/types';
import {
  idFormRedirectSettings,
  optionsListForByConditions,
} from 'pages/Services/RedirectServiceCardLanding/const';
import { useExternalFormSubmit } from 'pages/Services/RedirectServiceCardLanding/hook';

const _ActionBarSettings: FC<ActionBarSettingsProps> = ({
  loading,
  content,
  onCloseModal,
  contentSuccessActive,
  contentError,
  activeTab,
  options,
}) => {
  const { onClick: onClickSubmit } = useExternalFormSubmit(idFormRedirectSettings);
  const onCloseError = () => {
    window.location.reload();
  };

  const disabledActiveButton =
    activeTab === 'redirectByConditions' &&
    optionsListForByConditions.every((option) => options.redirectByConditions[option] === null);

  return (
    <ActionBar size="m">
      {loading === 'succeeded' && (
        <Button onClick={onCloseModal}>{contentSuccessActive?.buttons?.main}</Button>
      )}
      {loading === 'failed' && (
        <Button onClick={onCloseError}>{contentError?.buttons?.main}</Button>
      )}
      {['idle', 'pending'].includes(loading) && (
        <Button
          disabled={disabledActiveButton}
          isLoading={loading === 'pending'}
          onClick={onClickSubmit}
        >
          {content?.buttons?.main}
        </Button>
      )}
    </ActionBar>
  );
};

const mapStateToProps = (state: RedirectStore) => ({
  loading: selectLoadingAction(state),
  contentSuccessActive: selectRedirectQPContent(state)?.successActive,
  contentError: selectRedirectQPContent(state)?.error,
});

export const ActionBarSettings = connect(mapStateToProps)(_ActionBarSettings);
