import React, { FC } from 'react';
import { PageFixer, PageRow } from '@beef/smart-kit';
import classNames from 'classnames/bind';

import { BaseStatus } from 'pages/Services/RedirectServiceCardLanding/components/Status/BaseStatus';
import { MainErrorProps } from 'pages/Services/RedirectServiceCardLanding/components/Status/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

// в случае проблемы с qp показываем захардокеденный текст
export const MainError: FC<MainErrorProps> = (props) => {
  const {
    img = {
      url: 'https://static.beeline.ru/upload/images/b2c/partners/smt-going-wrong-cat.svg',
    },
    title = 'Что-то пошло не по плану',
    description = 'Иногда такое случается — обновите страницу',
    buttons = {
      main: 'Повторить',
    },
  } = props;
  const onClickMain = () => {
    window.location.reload();
  };

  return (
    <div className={cx('wrapper')}>
      <PageFixer size="m">
        <PageRow>
          <BaseStatus
            buttons={buttons}
            description={description}
            img={img}
            onClickMain={onClickMain}
            title={title}
          />
        </PageRow>
      </PageFixer>
    </div>
  );
};
