import { useEffect, useState } from 'react';

export const ClientRenderOnly = ({ children }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setShouldRender(true);
  }, []);

  return shouldRender ? children : null;
};
