import React, { Component } from 'react';

import PopupStateless from 'pages-components/Popup';

import SimOrderForm from './SimOrderForm';

export default class SimOrderPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { opened: false };
  }

  triggerPopup = (opened) => this.setState({ opened });

  render() {
    const { opened } = this.state;

    return (
      <PopupStateless onClose={() => this.triggerPopup(false)} opened={opened}>
        <SimOrderForm onReset={this.closePopup} {...this.props} />
      </PopupStateless>
    );
  }
}
