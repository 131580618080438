import { IAlerts, IAlertsPopupData } from 'constructorV1/types';

/** Объединяет данные контента и тарифа по тексту ограничений и попапам нулевых пресетов */
export const combineAlertsData = (alerts: IAlerts, alertsPopupData: IAlertsPopupData) => {
  const { text: gbText, ...restAlertsGB } = alerts?.gb || {};
  const { text: minText, ...restAlertsMin } = alerts?.min || {};
  const { text: gbMinText, ...restAlertsGbMin } = alerts?.gbMinAll || {};

  const exceptions = ['min', 'gb', 'gbMinAll'];
  const formattedAlerts = Object.keys(alerts).reduce((acc, key) => {
    if (exceptions.includes(key)) return acc;

    const { text, ...rest } = alerts[key as keyof IAlerts];

    const alertDetail = {
      [key]: {
        text,
        popupData: {
          ...rest,
        },
      },
    };

    return { ...acc, ...alertDetail };
  }, {});

  return {
    ...formattedAlerts,
    gb: {
      text: gbText,
      popupData: alertsPopupData?.gb ? { ...alertsPopupData.gb, ...restAlertsGB } : null,
    },
    min: {
      text: minText,
      popupData: alertsPopupData?.min ? { ...alertsPopupData.min, ...restAlertsMin } : null,
    },
    gbMinAll: {
      text: gbMinText,
      popupData: alertsPopupData?.min ? { ...alertsPopupData.min, ...restAlertsGbMin } : null,
    },
  };
};
