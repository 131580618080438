import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import RequestStatus from 'pages-components/RequestStatus';
import { poll } from 'utils/timed-functions';

const STATUS = {
  progress: 'IN_PROGRESS',
  complete: 'COMPLETE',
  error: 'ERROR',
  rejected: 'REJECTED',
  requestent: 'REQUESTSENT',
};

const ConnectionSteps = ({ content, yandexLink, onClose, popupStatus, steps }) => {
  const [currentStep, setCurrentStep] = useState(popupStatus);

  const handleRequest = async () => {
    const soc = 'YANDEX_PD';

    const fetchRequestId = async () => {
      const response = await axios.post(`/yandex-plus/activate?soc=${soc}`);
      if (response?.data.requestId) {
        return response.data.requestId;
      } else {
        setCurrentStep(steps.error);
        throw new Error('no success');
      }
    };

    const requestId = await fetchRequestId();
    if (requestId) {
      await poll(
        async () => {
          const response = await axios.get('/yandex-plus/service-status', {
            params: {
              requestId,
            },
          });
          if (response?.data.requestStatus) {
            const status = response.data.requestStatus;
            if (
              status === STATUS.error ||
              status === STATUS.rejected ||
              status === STATUS.requestent
            ) {
              setCurrentStep(steps.error);
              throw new Error('no success');
            }
            return status;
          } else {
            return null;
          }
        },
        (status) => status !== STATUS.complete && status === STATUS.progress,
        content.pollIntervalTime,
        content.pollTimeOut,
      );
    }
  };

  const onConnectClick = () => {
    setCurrentStep(steps.pending);
    handleRequest()
      .then(() => (window.location.href = yandexLink))
      .catch(() => setCurrentStep(steps.error));
  };

  return (
    <div>
      {currentStep === steps.connect && (
        <RequestStatus data={content.connect} isLeftCentered onClick={onConnectClick} />
      )}
      {currentStep === steps.pending && <RequestStatus data={content.pending} pending />}
      {currentStep === steps.blocked && (
        <RequestStatus data={content.blocked} isLeftCentered onClick={onClose} />
      )}
      {currentStep === steps.isNotEnoughMoney && (
        <RequestStatus data={content.isNotEnoughMoney} isLeftCentered onClick={onClose} />
      )}
      {currentStep === steps.success && (
        <RequestStatus data={content.success} isLeftCentered onClick={onClose} />
      )}
      {currentStep === steps.error && (
        <RequestStatus data={content.error} isLeftCentered onClick={onClose} />
      )}
    </div>
  );
};

ConnectionSteps.propTypes = {
  content: PropTypes.shape({
    success: PropTypes.object,
    error: PropTypes.object,
    connect: PropTypes.object,
    blocked: PropTypes.object,
    isNotEnoughMoney: PropTypes.object,
    pending: PropTypes.object,
    pollIntervalTime: PropTypes.number,
    pollTimeOut: PropTypes.number,
  }),
  onClose: PropTypes.func,
  yandexLink: PropTypes.string,
  popupStatus: PropTypes.string,
  steps: PropTypes.object,
};

export default ConnectionSteps;
