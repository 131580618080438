import { useEffect, useRef } from 'react';
import { useOnScreen } from '@beef/utils';

export const useSetCurrAnchorOnScroll = (anchorId: string) => {
  const ref = useRef(null);
  const inView = useOnScreen(ref);

  useEffect(() => {
    if (inView) {
      localStorage.setItem('currAnchor', `#${anchorId}`);
    }
  }, [inView]);

  return ref;
};
