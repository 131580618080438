import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Components
import { Heading, Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import TariffCard from 'pages-components/TariffCard';

// Hoc
import TariffCardParamsHoc from 'pages-components/TariffCard/hocs/TariffCardParamsHoc';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffsWidget = ({ title, tariffsUrl }) => {
  const [tariffs, setTariffs] = useState(null);
  const getTariffs = async () => {
    try {
      const { data } = await axios.get(tariffsUrl);
      setTariffs(data);
    } catch (ex) {
      setTariffs([]);
    }
  };

  useEffect(() => {
    getTariffs();
  }, []);

  return (
    <div className={cx('component')}>
      {tariffs === null ?
        <Preloader />
      : <>
          {tariffs.length > 0 && (
            <Heading className={cx('heading')} level={2}>
              {title}
            </Heading>
          )}
          {tariffs.length > 0 && (
            <div className={cx('tariffs')}>
              <div>
                {tariffs.map((tariff, index) => (
                  <TariffCardParamsHoc key={index} {...tariff}>
                    <TariffCard className={cx('tariffCard')} clickable />
                  </TariffCardParamsHoc>
                ))}
              </div>
            </div>
          )}
        </>
      }
    </div>
  );
};

TariffsWidget.propTypes = {
  title: PropTypes.string,
  tariffsUrl: PropTypes.string,
};

export default TariffsWidget;
