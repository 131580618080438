import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'constructorV1/store';
import { useConstructorLink } from 'constructorV1/router/hooks/useConstructorLink';
import { selectCBillErrorRequestType } from 'constructorV1/store/selectors/selectCommonBill';
import { setCBillData, setCBillErrorRequestType } from 'constructorV1/store/commonBillSlice';
import {
  selectCBillErrorTypeContent,
  selectCBillSuccessTypeContent,
} from 'constructorV1/store/selectors/selectCommon';

import { openChatScenarios, repeatRequestScenarios } from '../constants';

/** Хук подготовки данных для страницы конфликтов подключения ЕК */
export const useResultContent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  /** Ссылка для перехода на главную страницу ЕК */
  const mainUrl = useConstructorLink('main');

  /** Ссылка для перехода на страницу чека ЕК */
  const billUrl = useConstructorLink('bill');

  /** Контентный объект ошибок проверки конфликтов подключения ЕК */
  const errors = useAppSelector(selectCBillErrorTypeContent);

  /** Контентный объект успеха подключения ЕК */
  const success = useAppSelector(selectCBillSuccessTypeContent);

  /** Текущий тип ошибки */
  const errorType = useAppSelector(selectCBillErrorRequestType);

  /** Текущий контент по типу ошибки/успеха запроса */
  const { title, subTitle, acceptLink, acceptText, declineLink, declineText } =
    errorType ? errors[errorType] : success;

  /** Определяем, имеет ли текущий сценарий возможность открытия чата главной кнопкой */
  const hasOpenChat = Boolean(errorType && openChatScenarios.includes(errorType));

  /** Определяем, имеет ли текущий сценарий возможность повторной отправки запроса главной кнопкой */
  const hasRepeatRequest = Boolean(errorType && repeatRequestScenarios.includes(errorType));

  /** Функция повторной отправки запроса для результирующего чека */
  const sendRepeatRequest = () => {
    /* Сбрасываем подготовленные данные чека в store */
    dispatch(setCBillData(null));

    /* Сбрасываем имеющуюся ошибку */
    dispatch(setCBillErrorRequestType(null));

    /*  Переходим на страницу чека для повторной отправки запроса */
    navigate(billUrl);
  };

  /** Функция открытия чата */
  const openChat = () => {
    const chatWidget = document?.querySelector('#chat-widget button');
    if (chatWidget && chatWidget instanceof HTMLElement && chatWidget?.click) {
      chatWidget.click();
    }
  };

  /** Общая функция-обработчик клика по кнопке.
   * Принимает в качестве параметров ссылку и флаги открытия чата поддержки/повторной отправки запроса */
  const handleClick = (
    link: string,
    isOpenChat: boolean = false,
    isRepeatRequest: boolean = false,
  ) => {
    /* Если есть ссылка, открываем ее в новой вкладке браузера */
    if (link) {
      window.open(link, '_blank');
      return;
    }

    /* Если флаг isOpenChat - true, открываем чат тех.поддержки */
    if (isOpenChat) {
      openChat();
      return;
    }

    /* Если флаг hasReload (sendRequest) - true, отправляем запрос повторно */
    if (isRepeatRequest) {
      sendRepeatRequest();
      return;
    }

    /* Сбрасываем ошибку, если она есть */
    if (errorType) {
      dispatch(setCBillErrorRequestType(null));
    }

    /* Уходим на стартовую страницу ЕК */
    navigate(mainUrl);
  };

  /* Результирующий объект с подготовленными данными */
  return {
    title,
    subTitle,
    acceptText,
    declineText,
    isSuccess: !errorType,
    onDecline: () => handleClick(declineLink),
    onAccept: () => handleClick(acceptLink, hasOpenChat, hasRepeatRequest),
  };
};
