import { getFieldNames } from '../../utils';
import { getFormSlice, getValidateField } from '../reducers';

const initialState = {
  data: {
    shouldScrollIntoView: {
      value: false,
    },
    firstName: {
      value: '',
      error: '',
    },
    lastName: {
      value: '',
      error: '',
    },
    city: {
      value: '',
      error: '',
    },
    cityId: {
      value: '',
      error: '',
    },
    phone: {
      value: '',
      error: '',
    },
    storyTitle: {
      value: '',
      error: '',
    },
    story: {
      value: '',
      error: '',
    },
    personalDataAgreement: {
      value: false,
      error: '',
    },
    rulesAgreement: {
      value: false,
      error: '',
    },
    employeeAgreement: {
      value: false,
      error: '',
    },
    button: {
      value: 'idle',
      error: '',
    },
  },
};

export const getAnketaFieldNames = () => getFieldNames(initialState);

export const getValidateAndSetError = (dispatch, state) => (fieldName, validators) => {
  const validateField = getValidateField(state);
  const value = validateField(fieldName, validators);

  dispatch(setError({ fieldName, value }));
  return value;
};

const anketa = getFormSlice(initialState);

export const { setValue, setError, setHasSubmitted } = anketa.actions;
export default anketa.reducer;
