import { withDataConverter, withRenderFlag } from '@beef/react';
import { compose } from '@beef/utils';
import { memo } from 'react';

import { withObservableState } from 'utils/hocs/withObservableState';

import { selectModalProps } from '../../selectors';
import { TTariffUpModals, TariffUpModals } from '../../store/TariffUpModals';
import { EYandexPopupType } from '../../types';
import { CommonModal } from '../CommonModalContainer';
import { TModalProps } from '../types';
import { onCancel, onConfirm } from './handler';

const converter = (): TModalProps => {
  const modalProps = selectModalProps(EYandexPopupType.Error);

  return {
    ...modalProps,
    onConfirm,
    onCancel,
  };
};

export const ConnectedErrorModal = compose<typeof CommonModal>(
  withObservableState<TTariffUpModals>(TariffUpModals.ObservableState),
  withDataConverter(converter),
  withRenderFlag,
  memo,
)(CommonModal);
