import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { Modal, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from 'pages-components/Icon';
// Components
import { formatPointsData } from 'pages/Maps/utils';
import store from 'store';
import { SegmentedCoverage } from 'pages/Maps/components/SegmentedCoverage';
import { ZoomControls } from 'pages/Maps/components/ZoomControls';

import { BaseStationList } from './components/BaseStationList';
import Controls from './components/Controls';
import CoverageControls from './components/Controls/Coverage';
import ZoomButtons from './components/Controls/ZoomButtons';
import Map from './components/Map';
import { MapBaseStations } from './components/MapBaseStations';
import { ModalActionBar } from './components/ModalActionBar';
import OrderPopup from './components/OrderPopup';
import PointsList from './components/PointsList';
import { UrlFiltersListener } from './components/UrlFiltersListener/index';
import { ORIGINS } from './constants';
// Hocs
import OrderPopupHoc from './hocs/OrderPopupHoc';
// Actions
import { getRequestToggle, setIsAnniversary, toggleInfo, toggleMode } from './store/actions';
// Styles
import styles from './styles.pcss';
// Utils
import MapController from './utils/mapController';

const cx = classNames.bind(styles);

class Maps extends Component {
  constructor(props) {
    super(props);
    this.mapController = new MapController();

    this.state = {
      geolocationLoading: false,
      isLockdownModalOpened: false,
      isOrderPopupOpen: false,
      pointsData: formatPointsData(props.data.texts.pointsData),
      showControlsMenu: false,
      sngPoints: props.sngPoints,
    };
  }

  getChildContext() {
    return {
      mapController: this.mapController,
    };
  }

  componentDidMount() {
    if (this.props.isPopUp) {
      this.props.onToggleInfo(null);
    }

    this.setState((prevState) => ({
      isLockdownModalOpened: !prevState.isLockdownModalOpened,
    }));

    this.props.onMount();
  }

  componentWillUnmount() {
    this.props.onToggleMode('offices');
  }

  onGeolocationButtonClick = () => {
    this.props.analyticsEvents?.onGeolocationButtonClick?.();
    if (this.state.geolocationLoading) return;

    this.setState({ geolocationLoading: true });
    this.mapController.moveToCurrentLocation((location) => {
      this.setState({ geolocationLoading: false });
      this.props.setCurrentLocation(location);
    });
  };

  onZoomButtonClick = (value) => {
    this.props.analyticsEvents?.onZoomButtonClick?.(value);
    const { map } = this.mapController;
    map.setZoom(map.getZoom() + value);
  };

  setCenter = (coords, zoom = 12) => {
    this.mapController.setCenter(coords, zoom);
  };

  setOpenMenu = () =>
    this.setState((prevState) => ({
      showControlsMenu: !prevState.showControlsMenu,
    }));

  /**
   * Функция открывает модальное окно заказа SIM карты
   * @param isOpenPopup - Bool параметр отвечающий за отображение модального окна;
   */
  setOpenPopup = (isOpenPopup) =>
    this.setState({
      isOrderPopupOpen: isOpenPopup,
    });

  /**
   * открывает модальное окно попапа локдауна
   * @param isLockdownModalOpened - Bool параметр отвечающий за отображение модального окна,
   *  модалка показывается сразу при заходе на страницу
   */
  handleLockdownModalOpen = () => {
    this.setState((prevState) => ({
      isLockdownModalOpened: !prevState.isLockdownModalOpened,
    }));
  };

  render() {
    const {
      geolocationLoading,
      isLockdownModalOpened,
      isOrderPopupOpen,
      pointsData,
      showControlsMenu,
      sngPoints,
    } = this.state;
    const {
      analyticsEvents,
      cssClasses,
      data,
      hideMenuButton,
      ignoreShopPoints,
      isPopUp,
      mapMode,
      onMapLoad,
      origin,
      pointsType,
      showBaseStations,
      sng,
      isCarnicaRedesign,
    } = this.props;
    const isLandingPage = origin === ORIGINS.landingPage;
    const CoverageControlsComponent = isCarnicaRedesign ? SegmentedCoverage : CoverageControls;
    const ZoomButtonsComponent = isCarnicaRedesign ? ZoomControls : ZoomButtons;

    return (
      <>
        <UrlFiltersListener />
        {showBaseStations ?
          <MapBaseStations
            mapController={this.mapController}
            params={data.mapParams}
            points={data.texts.anniversary.points}
          />
        : <Map
            controlHeight={!isLandingPage}
            hidePoints={mapMode === 'coverZone'}
            ignoreShopPoints={ignoreShopPoints}
            isPopUp={isPopUp}
            mapMode={mapMode}
            onMapLoad={onMapLoad}
            params={data.mapParams}
            pointsData={pointsData}
            pointsType={pointsType}
            sng={sng}
            sngPoints={sngPoints}
            zoomLimit={data.texts.zoomLimit}
          />
        }
        {data?.lockdownpopup && (
          <Modal
            // eslint-disable-next-line max-len
            actionBar={
              data?.lockdownpopup?.buttons ?
                <ModalActionBar buttons={data?.lockdownpopup?.buttons} size="s" />
              : undefined
            }
            headerBarConfig={{
              headerText: data?.lockdownpopup?.heading,
            }}
            isOpen={isLockdownModalOpened}
            onClose={this.handleLockdownModalOpen}
            size="s"
          >
            <div className={cx('popup__image-wrapper')}>
              <img alt="" className={cx('popup__image')} src={data?.lockdownpopup?.background} />
            </div>
            <Text size="size5-r-s">{data?.lockdownpopup?.text}</Text>
          </Modal>
        )}
        <ZoomButtonsComponent
          className={cx(isCarnicaRedesign ? 'redesign-zoom-buttons' : 'zoom-buttons')}
          loading={geolocationLoading}
          onClick={this.onZoomButtonClick}
          onGetLocationCLick={this.onGeolocationButtonClick}
        />
        {showBaseStations ?
          <BaseStationList
            {...data.texts.anniversary}
            onClick={(point) => {
              this.setCenter([point.lat, point.lon], point.zoom || 10);
            }}
          />
        : <div
            className={cx(isCarnicaRedesign ? 'redesign-wrapper-controls' : 'wrapper-controls', {
              'wrapper-controls--hidden': showControlsMenu,
            })}
          >
            <Controls
              analyticsEvents={analyticsEvents}
              hideMenuButton
              isCarnicaRedesign={isCarnicaRedesign}
              isPopUp={isPopUp}
              mapMode={mapMode}
              officeOptions={data.officeOptions}
              onModeChange={this.props.onToggleMode}
              origin={origin}
              qualityLinkHref={data.texts.qualityLinkHref}
              qualityLinkText={data.texts.qualityLinkText}
              sng={sng}
              sngPoints={sngPoints}
            />
            {mapMode === 'offices' ?
              <PointsList
                filtersOptions={data.texts.filtersOptions}
                holidayScheduleChangesText={data.texts.holidayScheduleChangesText}
                holidaysText={data.texts.holidaysText}
                isLandingPage={isLandingPage}
                isPopUp={isPopUp}
                pointsData={pointsData}
                setOpenPopup={() => this.setOpenPopup(true)}
                showOrderButton={data.texts.showOrderButton}
                sng={sng}
                sngPoints={sngPoints}
              />
            : <CoverageControlsComponent
                analyticsEvents={analyticsEvents}
                className={cssClasses?.coverage}
                coverageTypes={data.texts.coverageTypes}
                mapController={this.mapController}
              />
            }

            {!hideMenuButton && (
              <div className={cx('menu-button', 'menu-button_open')} onClick={this.setOpenMenu}>
                <Icon name="controlsDoubleArrows" />
              </div>
            )}
          </div>
        }
        <div
          className={cx('menu-button', 'menu-button_close', {
            'menu-button_close--active': showControlsMenu,
          })}
          onClick={this.setOpenMenu}
        >
          <Icon className={cx('arrow-map-controls')} name="controlsDoubleArrows" />
        </div>

        {isOrderPopupOpen && (
          <OrderPopupHoc alias={this.props.alias}>
            <OrderPopup onClosePopup={() => this.setOpenPopup(false)} />
          </OrderPopupHoc>
        )}
      </>
    );
  }
}

Maps.propTypes = {
  isCarnicaRedesign: PropTypes.bool,
  analyticsEvents: PropTypes.shape({
    onSegmentedControlClick: PropTypes.func,
    onCheckboxClick: PropTypes.func,
    onChoosePoint: PropTypes.func,
    onGeolocationButtonClick: PropTypes.func,
    onSearchInputFill: PropTypes.func,
    onSearchInputClick: PropTypes.func,
    onZoomButtonClick: PropTypes.func,
  }),
  cssClasses: PropTypes.objectOf(PropTypes.string),
  data: PropTypes.shape({
    mapParams: PropTypes.shape({}),
    officeOptions: PropTypes.shape({}),
    texts: PropTypes.shape({
      coverageTypes: PropTypes.arrayOf(PropTypes.shape({})),
      filtersOptions: PropTypes.arrayOf(PropTypes.shape({})),
      holidayScheduleChangesText: PropTypes.string,
      holidaysText: PropTypes.string,
      orderSimPopup: PropTypes.shape({}),
      pointsData: PropTypes.shape({}),
      showOrderButton: PropTypes.bool,
      zoomLimit: PropTypes.shape({}),
    }),
  }),
  getRequestToggle: PropTypes.func,
  hideMenuButton: PropTypes.bool,
  ignoreShopPoints: PropTypes.bool,
  isPopUp: PropTypes.bool,
  lockdownpopup: PropTypes.shape({
    background: PropTypes.string,
    buttons: PropTypes.arrayOf({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    heading: PropTypes.string,
    text: PropTypes.string,
  }),
  mapMode: PropTypes.string,
  onMapLoad: PropTypes.func,
  onMount: PropTypes.func,
  onToggleInfo: PropTypes.func,
  onToggleMode: PropTypes.func,
  origin: PropTypes.string,
  pointsType: PropTypes.number,
  showBaseStations: PropTypes.bool,
  sng: PropTypes.bool,
};

Maps.childContextTypes = {
  mapController: PropTypes.shape({}),
};

const mapStateToProps = ({ external: { maps = {} } }) => ({
  mapMode: maps.mapMode || 'offices',
  showBaseStations: maps.isAnniversary,
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch(getRequestToggle);
    dispatch(setIsAnniversary);
  },
  onToggleInfo: (data) => dispatch(toggleInfo(data)),
  onToggleMode: (data) => dispatch(toggleMode(data)),
});

export const MapsConnected = connect(mapStateToProps, mapDispatchToProps)(Maps);

export default (props) => (
  <Provider store={store}>
    <MapsConnected {...props} origin={ORIGINS.beelinePage} />
  </Provider>
);
