import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Heading } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import TitleDiscount from '../TitleDiscount';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffCatalogTitle = ({ catalogTitle, subTitle }) => (
  <div className={cx('heading')}>
    <Heading className={cx('pageTitle')} isStyled={false} level={1}>
      <span dangerouslySetInnerHTML={{ __html: catalogTitle }} />
    </Heading>
    {subTitle && (
      <>
        {subTitle.text && (
          <div className={cx('text')} dangerouslySetInnerHTML={{ __html: subTitle.text }} />
        )}
        {subTitle.discount && <TitleDiscount discount={subTitle.discount} unit={subTitle.unit} />}
      </>
    )}
  </div>
);

TariffCatalogTitle.propTypes = {
  catalogTitle: PropTypes.string,
  subTitle: PropTypes.shape({
    text: PropTypes.string,
    unit: PropTypes.string,
    discount: PropTypes.string,
  }),
};

export default TariffCatalogTitle;
