import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { compose } from '@beef/utils';

import { useIsDesktopDimension } from 'utils/hooks/useGetDimensionGroup';
import { defineBlock } from 'pages/FTTB2022/CatalogPage/deprecated/blocks/utils';
import { FttbBlocks } from 'pages/FTTB2022/CatalogPage/deprecated/blocks/constants';
import { CarouselComponent } from 'pages/FTTB2022/CatalogPage/deprecated/components/Carousel';

import styles from './styles.pcss';
import { AlignedGridItem } from './AlignedGridItem';
import { defaultsToPx } from '../../../CatalogPage/deprecated/utils';

const cn = classNames.bind(styles);

const _AlignedGrid = ({ children, levels = 1, itemMaxSize, className, isCarouselEnabled }) => {
  const isDesktop = useIsDesktopDimension();

  return (
    <div
      className={cn('component', className)}
      style={{
        gridTemplateRows: `repeat(${levels}, auto)`,
        gridAutoColumns:
          isDesktop ? itemMaxSize && `minmax(auto, ${defaultsToPx(itemMaxSize)})` : '',
      }}
    >
      <CarouselComponent
        hasCarousel={isCarouselEnabled}
        isPaginate
        itemsCount={children.length}
        spaceBetween={24}
      >
        {children}
      </CarouselComponent>
    </div>
  );
};

_AlignedGrid.Item = AlignedGridItem;
_AlignedGrid.propTypes = {
  children: PropTypes.node,
  levels: PropTypes.number,
  itemMaxSize: PropTypes.number,
  className: PropTypes.string,
  dataLenght: PropTypes.string,
  isCarouselEnabled: PropTypes.bool,
};
export const AlignedGrid = compose(defineBlock(FttbBlocks.TARIFFS))(_AlignedGrid);
