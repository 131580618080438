import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SmartInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Captcha from 'pages/Feedback/components/Captcha/Captcha';

import styles from './Captcha.pcss';

const cx = classNames.bind(styles);

class CaptchaInput extends PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.error) {
      return {
        ...prevState,
        errorShown: true,
      };
    }
  }

  constructor(props) {
    super(props);

    this.state = { errorShown: false };
  }

  setFocus = () => this.captcha.inputElement.input.setFocus();

  setValue = (value) => this.captcha.setValue(value);

  fetchCaptcha = () => {
    this.captcha.fetchCaptcha();
    this.captcha.setValue('');
  };

  handleCaptchaChange = (value) => {
    this.props.onChange('captcha', value);

    if (this.state.errorShown) {
      this.setState({ errorShown: false });
    }
  };

  render() {
    const { errorShown } = this.state;
    const { error, captchaKey, label } = this.props;

    return (
      <div className={cx('captchaWrapper')}>
        {label && <div className={cx('label')} dangerouslySetInnerHTML={{ __html: label }} />}
        <Captcha
          captchaKey={captchaKey}
          msg={error}
          onChange={this.handleCaptchaChange}
          ref={(e) => {
            this.captcha = e;
          }}
          status={errorShown && error ? 'fail' : ''}
        />
        <SmartInput inputId="_captcha_key" name="_catcha_key" type="hidden" value="CAPTCHA_KEY" />
      </div>
    );
  }
}

CaptchaInput.propTypes = {
  error: PropTypes.string,
  captchaKey: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default CaptchaInput;
