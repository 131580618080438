import { useState } from 'react';

import {
  ModalsKeys,
  ModalsValues,
} from 'pages/PclLandingPage/components/modals/ModalsController/types';
import { postActivateLimit, postChangeLimit, postDeactivateLimit } from 'pages/PclLandingPage/api';

export const useModalsController = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState<ModalsValues>(ModalsKeys.activateLimitModal);
  const [isLoading, setIsLoading] = useState(false);
  const debtCodes = ['HAS_DEBT', 'NEGATIVE_BALANCE'];

  const requestWrapper = async (
    request: () => Promise<unknown>,
    successModal: ModalsValues,
    errorModal: ModalsValues,
  ) => {
    setIsLoading(true);
    try {
      const response = await request();
      if (debtCodes.includes(response?.data?.code)) {
        setModal(ModalsKeys.deactivateDebtModal);
      } else {
        setModal(successModal);
      }
    } catch (err) {
      console.error(err);
      setModal(errorModal);
    } finally {
      setIsLoading(false);
    }
  };

  const onActivateLimit = (data) =>
    requestWrapper(
      () => postActivateLimit(data),
      ModalsKeys.successActivateModal,
      ModalsKeys.errorActivateModal,
    );

  const onDeactivateLimit = (data) =>
    requestWrapper(
      () => postDeactivateLimit(data),
      ModalsKeys.successDeactivateModal,
      ModalsKeys.errorDeactivateModal,
    );

  const onChangeLimit = (data) =>
    requestWrapper(
      () => postChangeLimit(data),
      ModalsKeys.successChangeLimitModal,
      ModalsKeys.errorChangeLimitModal,
    );

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onOpenModalClick = (modal: ModalsValues) => () => {
    setModal(modal);
    setIsModalOpen(true);
  };

  const onIncreaseBalanceClick = () => {
    setModal(ModalsKeys.topUpBalanceModal);
  };

  return {
    isModalOpen,
    modal,
    isLoading,
    onActivateLimit,
    onDeactivateLimit,
    onChangeLimit,
    onOpenModalClick,
    onCloseModal,
    onIncreaseBalanceClick,
  };
};
