import { createSelector } from '@reduxjs/toolkit';

import { selectTotalOptionsSocs } from 'constructorV1/store/selectors/selectTotal';
import { selectOptionsSections } from 'constructorV1/store/selectors/selectTariffSettings';
import {
  DEFAULT_EXTRA_OPTIONS_INDEX,
  DEFAULT_INDEX,
  MAX_EXTRA_OPTIONS_LENGTH,
} from 'constructorV1/constants';

/** Селектор определения максимально возможной ширины иконок доп. опций в чеке ЕК */
export const selectIsMaximal = createSelector(
  selectTotalOptionsSocs,
  selectOptionsSections,
  (optionsSocs, sections) => {
    /* Достаем доп. опции из sections. По-умолчанию они приходят под индексом DEFAULT_EXTRA_OPTIONS_INDEX (1) */
    const extraOptions = sections?.[DEFAULT_EXTRA_OPTIONS_INDEX]?.options ?? [];

    if (extraOptions.length) {
      /* Определяем количество активных доп. опций (именно допы интересуют) в optionsSocs */
      const chooseExtraSocs = extraOptions.filter(({ serviceCategory = '' }) =>
        optionsSocs.includes(serviceCategory),
      );

      /* Если количество активных доп. опций больше максимально-допустимого значения
       (MAX_EXTRA_OPTIONS_LENGTH определено дизайнерами) в их в одном ряду или доп. опции отключены, вернем true */
      return (
        chooseExtraSocs?.length > MAX_EXTRA_OPTIONS_LENGTH ||
        chooseExtraSocs?.length === DEFAULT_INDEX
      );
    }
    return false;
  },
);
