import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { InfoIcon, Text, Tooltip } from '@beef/ui-kit';

import styles from '../../TariffContentContainer/styles.pcss';

const cx = classNames.bind(styles);

export const ItemTitle = ({ name, text, showToolTip }) => (
  <div className={cx('wrapper__title')}>
    {name && <Text size="size4-r">{name}</Text>}
    {showToolTip && (
      <Tooltip maxWidth="700" message={text} position="right" variant="secondary">
        <InfoIcon fill="#727281" size="xs" />
      </Tooltip>
    )}
  </div>
);

ItemTitle.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  showToolTip: PropTypes.bool,
};
