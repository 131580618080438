import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SmartInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import PopupStateless from 'pages-components/Popup';
import PlainContainer from 'pages-components/PopupContainer/PlainContainer';
import ButtonWithPreloader from 'pages-components/ButtonWithPreloader';
import SimOrderBenefits from 'pages-components/SimOrderBenefits/SimOrderBenefits';
import Captcha from 'pages/Feedback/components/Captcha/Captcha';
import { maskedPhone as validatePhone } from 'utils/validators';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const CallOrderPopup = ({ closePopup, content, callOrderAction }) => {
  // Hooks
  const [contactNumber, changeContactNumber] = useState('+7');
  const [contactNumberValidate, changeContactNumberValidate] = useState({});
  const [step, setStep] = useState('callOrderPopup');
  const [showCaptcha, toggleShowCaptcha] = useState(false);
  const [captcha, setCaptchaValue] = useState('');
  const [showCaptchaError, toggleShowCaptchaError] = useState(false);
  // Constants
  const captchaKey = 'call-order-form';
  const plainContainerType =
    step === 'callOrderPopup' || step === 'pending' ? 'callOrderPopup' : step;
  const isButtonDisabled =
    showCaptcha &&
    (contactNumber.length !== 16 || captcha.length !== 5 || contactNumber.length !== 16);
  const submit = async () => {
    if (contactNumberValidate.status === 'ok') {
      setStep('pending');
      const {
        nextStep = 'callOrderPopup',
        isCaptchaError,
        isCaptchaRequired,
      } = await callOrderAction(
        content.callOrderPopup.requestUrl,
        contactNumber,
        captcha,
        captchaKey,
        'ServiceCard',
      );

      setStep(nextStep);
      toggleShowCaptchaError(isCaptchaError);
      toggleShowCaptcha(isCaptchaRequired);

      if (!showCaptchaError) setCaptchaValue('');
    }
  };

  return (
    <PopupStateless onClose={closePopup} opened>
      <PlainContainer
        {...content[plainContainerType]}
        className={cx('callOrderPopup')}
        onButtonClick={plainContainerType !== 'callOrderPopup' ? closePopup : null}
      >
        {plainContainerType === 'callOrderPopup' && (
          <div className={cx('formWrap')}>
            <SmartInput
              className={cx('contactNumberInput')}
              keepInitialValue
              mask="+7 ___ ___ __ __"
              name="phone"
              onBlur={() => changeContactNumberValidate(validatePhone(contactNumber))}
              onChange={(value) => changeContactNumber(value)}
              onFocus={() => changeContactNumberValidate({ status: 'ok' })}
              size="big"
              status={contactNumberValidate.status}
              value={contactNumber}
            />
            <div className={cx('submitContainer', showCaptcha && 'withCaptcha')}>
              {showCaptcha && (
                <div className={cx('captchaWrapper')}>
                  <Captcha
                    captchaKey={captchaKey}
                    disabled={step === 'pending'}
                    height={51}
                    inputId="captcha"
                    msg="Введите символы с картинки"
                    name="captcha"
                    onChange={(value) => setCaptchaValue(value)}
                    onFocus={() => toggleShowCaptchaError(false)}
                    refreshCaptcha
                    size="big"
                    status={showCaptchaError ? 'fail' : 'ok'}
                  />
                </div>
              )}
              <ButtonWithPreloader
                buttonText={content.callOrderPopup.buttonText}
                className="big"
                disabled={isButtonDisabled}
                isPreloader={step === 'pending'}
                onClick={submit}
                preloaderClassName={cx('buttonPreloader')}
                transformer
                type="button"
              />
            </div>

            <SimOrderBenefits benefits="delivery" className={cx('deliveryBenefit')} />
          </div>
        )}
      </PlainContainer>
    </PopupStateless>
  );
};

CallOrderPopup.propTypes = {
  closePopup: PropTypes.func,
  callOrderAction: PropTypes.func,
  content: PropTypes.shape({
    callOrderPopup: PropTypes.object,
    errorPopup: PropTypes.object,
    successPopup: PropTypes.object,
  }),
};

export default CallOrderPopup;
