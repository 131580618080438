import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { motion } from 'framer-motion';

import { ESkeletonCBillTypes } from 'constructorV1/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TSkeletonBill = {
  type: ESkeletonCBillTypes;
};

/** Кастомный скелетон результирующего чека ЕК */
export const SkeletonCommonBill = memo<TSkeletonBill>(({ type }) => (
  <motion.div className={cx('wrapper', `wrapper__${type}`)} layout />
));
