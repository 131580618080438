import { useContext, useEffect } from 'react';

// Hooks
import submitCallbackRequest from 'pages/FTTB/services/submitCallbackRequest';
// Constants
import DispatchContext from 'pages/ProductCard/Tariff/containers/ConstructorTariff/constants';
import {
  onClickConvergenceByStepAnalytics,
  ymOrderTariffAddressView,
  ymOrderTariffUpFormBidView,
} from 'pages/FTTB2021/components/OrderTariffFormPopup/analytics';

import { btnTexts, popupSteps } from '../../../constants';

export const usePrepareHandlers = ({
  text,
  step,
  setStep,
  activeTab,
  setLoading,
  userNumber,
  isConnected,
  lastSeenSpeed,
  priceWithUnit,
  activeSettingTab,
  setResetAndClose,
  seamlessUrlParams,
  userSettingsTariff,
  setIsSettledOption,
  setFinishedSettings,
  resetSettingsTariff,
  setIsCallBackLoading,
  setCurrentButtonText,
  hasSeamlessUrlParams,
  setUserTariffSettings,
  handleConvergencePopup,
  setActiveSettingsUserTab,
  setCurrentSecondButtonText,
  setRequestCallBackSLoading,
  isActivateDisconnectConvergent,
  tariffType,
}) => {
  const _dispatch = useContext(DispatchContext);
  const {
    fail,
    connectFail,
    requestExists,
    callBackRequest,
    connectCompleted,
    tariffDetailsForm,
    addressRequestForm,
    confirmCancelConnect,
    upTariffSpeedFromArchive,
  } = popupSteps;
  let isSendRequestForUpSpeed = false;
  const size = step === tariffDetailsForm ? 'l' : 'm';
  const showAgreementText = text && step === addressRequestForm;
  const hasSettingsTariff = !!Object.keys(userSettingsTariff).length;

  const onClose = () => {
    if (!hasSettingsTariff) {
      _dispatch(setUserTariffSettings(null));
    }
    setResetAndClose(step, activeSettingTab, hasSettingsTariff);
    handleConvergencePopup();
    if (step === tariffDetailsForm) {
      ymOrderTariffUpFormBidView({ fmc_bid_speed: lastSeenSpeed });
    }

    ymOrderTariffAddressView('fmc_address_close', tariffType);
  };

  const setFinishSettings = (isSettled = true) => {
    setIsSettledOption(isSettled);
    setActiveSettingsUserTab(activeTab);
    setFinishedSettings(true);
    setLoading(false);
    ymOrderTariffUpFormBidView({ fmc_bid_speed: lastSeenSpeed });
  };

  const onClick = () => {
    const isClose = [connectFail, callBackRequest]?.includes(step);
    const isResetAndClose = [requestExists, connectCompleted]?.includes(step);
    const showTariffDetailsForm = [addressRequestForm, confirmCancelConnect]?.includes(step);

    setLoading(true);

    if (step === upTariffSpeedFromArchive) {
      isSendRequestForUpSpeed = true;
      sentCallBackRequest();
    }

    if (showTariffDetailsForm) {
      setStep(tariffDetailsForm);
    }

    if (isResetAndClose) {
      setResetAndClose();
    }

    if (isClose) {
      onClose();
    }

    if (step === tariffDetailsForm) {
      setFinishSettings();
    }

    onClickConvergenceByStepAnalytics(step, { priceWithUnit });
  };

  const sentCallBackRequest = () => {
    if (step === upTariffSpeedFromArchive) {
      if (isSendRequestForUpSpeed) {
        setCurrentSecondButtonText('');
      } else {
        setStep(tariffDetailsForm);
        return;
      }
    }

    if (step === tariffDetailsForm) {
      if (hasSettingsTariff && isConnected) {
        setStep(confirmCancelConnect);
      } else {
        setResetAndClose();
        resetSettingsTariff();
        handleConvergencePopup();
        _dispatch(setUserTariffSettings(null));
      }
      return;
    }

    setIsCallBackLoading(true);

    submitCallbackRequest({
      phoneNumber: `+7${userNumber}`,
      caseTitle: 'support',
    })
      .then(({ isSucceeded }) => {
        if (isSucceeded) {
          setRequestCallBackSLoading('success');
        } else {
          setRequestCallBackSLoading('fail');
        }
        setCurrentSecondButtonText('');
        setCurrentButtonText(btnTexts.close);

        if (step === connectFail) {
          return;
        }

        setStep(callBackRequest);
      })
      .catch((err) => {
        console.error(err);
        setRequestCallBackSLoading(false);
      })
      .finally(() => setIsCallBackLoading(false));
  };

  // TODO: подумать над вариантом избавления от "свича"
  useEffect(() => {
    if (step === addressRequestForm) {
      setCurrentButtonText(btnTexts.next);
      setCurrentSecondButtonText('');
    }
    if (step === requestExists) {
      setCurrentButtonText(btnTexts.changeAddress);
    }
    if (step === connectCompleted) {
      setCurrentButtonText(btnTexts.checkDiffAddress);
      setCurrentSecondButtonText(btnTexts.callBack);
    }
    if (step === confirmCancelConnect) {
      setCurrentButtonText(btnTexts.revertCancelConfirm);
      setCurrentSecondButtonText(btnTexts.cancelConfirm);
    }
    if (step === tariffDetailsForm) {
      setCurrentButtonText(isConnected ? btnTexts.save : btnTexts.addToTariff);
      if ((isConnected && isActivateDisconnectConvergent) || (!isConnected && hasSettingsTariff)) {
        setCurrentSecondButtonText(btnTexts.cansel);
      } else {
        setCurrentSecondButtonText('');
      }
    }
    if (step === connectFail) {
      sentCallBackRequest();
    }
    if (step === fail) {
      setCurrentButtonText(btnTexts.close);
      setCurrentSecondButtonText('');
    }
    if (step === upTariffSpeedFromArchive) {
      setCurrentButtonText(btnTexts.archiveTariffUpSpeed);
      setCurrentSecondButtonText(btnTexts.archiveCancel);
    }

    setLoading(false);
  }, [step, hasSettingsTariff]);

  /**
   * Автоматически заканчиваем настройку, если получили данные ШПД из query-параметров (hasSeamlessUrlParams)
   */
  useEffect(() => {
    const hasInacId = Object.keys(seamlessUrlParams).includes('inacId');
    if (hasSeamlessUrlParams && hasInacId && step === tariffDetailsForm) {
      setFinishSettings(false);
    }
  }, [step, hasSeamlessUrlParams]);

  return { size, onClick, onClose, showAgreementText, sentCallBackRequest };
};
