import { useState } from 'react';

import {
  ConnectTariffModal,
  ModalKeys,
  postConnectTariff,
} from 'pages/ProductCard/Tariff/components/Check/api';
import submitCallbackRequest from 'pages/FTTB/services/submitCallbackRequest';
import { sendYmConvergentRequest } from 'pages/ProductCard/utils';

const showError = (fieldName: string): void => {
  console.error(`В ответе метода POST /api/convergence/connect-tariff нет поля ${fieldName}`);
};

export type ConnectConvergenceType = {
  data: keyof Record<any, any>;
  getOptionsNames: (arg: any, index?: number) => any[];
  homeInternetOptions: any[];
  joinedOptions: keyof Record<string, any>;
  total: number;
  totalSum: null | number;
};

export const useConnectConvergence = (
  isAuthenticated: boolean,
  requestConvergenceParams: {
    contactPhone: string;
    ctn: string;
  },
  callBackContent: ConnectTariffModal,
  confirmContent: ConnectTariffModal,
  isShowAvailableSettings: boolean,
  handleRefundDiscount: () => void,
) => {
  const [modalContent, setModalContent] = useState<ConnectTariffModal | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmChangeMobilePreset = () => {
    setModalContent(confirmContent);
    setIsModalOpen(true);
  };

  const resetMobilePresetToDefault = () => {
    handleRefundDiscount();
    setIsModalOpen(false);
  };

  const showResultModal = (
    content: Record<string, ConnectTariffModal>,
    modalKey: keyof Record<string, any>,
  ) => {
    const modal = content[modalKey];
    if (modal) {
      setModalContent(modal);
      setIsModalOpen(true);
    } else {
      showError(modalKey);
    }
  };

  const sendCallBackAuthZone = async (ctn: string) => {
    const phoneNumber = `+7${requestConvergenceParams?.ctn || ctn}`;

    await submitCallbackRequest({
      phoneNumber,
      caseTitle: 'support',
    });
    setModalContent(callBackContent);
    setIsModalOpen(true);
  };

  const connectConvergence = async ({
    totalSum,
    data,
    total,
    joinedOptions,
    getOptionsNames,
    homeInternetOptions,
  }: ConnectConvergenceType) => {
    setIsLoading(true);
    const { isExistOrder, isSuccessed, isSucceeded, content, hasFttbConflicts } =
      await postConnectTariff(requestConvergenceParams);
    if (isExistOrder) {
      showResultModal(content, ModalKeys.hasOpenRequest);
    } else if (isSuccessed || isSucceeded) {
      sendYmConvergentRequest({
        sum: totalSum,
        content: data,
        total,
        joinedOptions,
        fttbOptions: getOptionsNames(homeInternetOptions),
      });
      if (hasFttbConflicts) {
        showResultModal(content, ModalKeys.fttbConflictsConnection);
        await submitCallbackRequest({
          phoneNumber: requestConvergenceParams?.contactPhone,
          caseTitle: 'support',
        });
      } else {
        // TODO переименовать ModalKeys.requestSentAundentificated и заменить в контенте
        showResultModal(
          content,
          isAuthenticated ? ModalKeys.requestSent : ModalKeys.requestSentAundentificated,
        );
      }
    } else if (!isSuccessed) {
      showResultModal(content, ModalKeys.somethingWentWrongAundentificated);
    }
    setIsLoading(false);
  };

  return {
    modalContent,
    connectConvergence,
    isModalOpen,
    closeModal,
    isLoading,
    sendCallBackAuthZone,
    confirmChangeMobilePreset,
    resetMobilePresetToDefault,
  };
};
