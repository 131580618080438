import React, { ComponentProps } from 'react';
import {
  useIsDesktopDimension,
  useIsMobileDimension,
  useIsTabletDimension,
} from '@carnica/utils/hooks';
import classNames from 'classnames/bind';

import { Title } from 'constructorV1/components/commonComponents/ConstructorTitle';
import { useAppSelector } from 'constructorV1/store';
import { selectCommonTitle } from 'constructorV1/store/selectors/selectCommon';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MainTitle = () => {
  /* Общий заголовок ConstructorV1 */
  const { size, align, ...titleContentProps } = useAppSelector(selectCommonTitle);
  const isTablet = useIsTabletDimension();
  const isMobile = useIsMobileDimension();
  const isDesktop = useIsDesktopDimension();
  const titleSize: ComponentProps<typeof Title>['size'] =
    // eslint-disable-next-line no-nested-ternary
    isMobile ? 'm'
      // eslint-disable-next-line no-nested-ternary
    : isTablet ? 'l'
    : isDesktop ? 'xl'
    : 'l';
  return <Title {...titleContentProps} className={cx('title')} size={titleSize} />;
};

export default MainTitle;
