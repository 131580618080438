import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TDescription = {
  description: string;
  title: string;
};

/** Компонент заголовка и описания для плитки ServiceTile */
export const Description: FC<TDescription> = ({ title, description }) => (
  <div className={cx('wrapper')}>
    {title && <Text size="size4-m">{title}</Text>}
    {description && (
      <Text color="grey60" size="size5-r-l">
        {description}
      </Text>
    )}
  </div>
);
