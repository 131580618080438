import { MouseEvent, useState } from 'react';

import { selectDiscountPercent } from 'constructorV1/components/tempComponents/AlertContainer/selectors/selectDiscountPercent';
import { useAppSelector } from 'constructorV1/store';
import { TAlertPopupData } from 'constructorV1/store/commonSlice/types';

/** Хук подготовки подсказки ЕК */
export const usePrepareAlertTile = (popupData: TAlertPopupData | null) => {
  const discountPercent = useAppSelector(selectDiscountPercent);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenPopup = (e: MouseEvent<HTMLAnchorElement>) => {
    /* Предотвращаем переход по ссылке */
    e.preventDefault();
    /* Открываем модальное окно, если есть данные для попапа */
    if (popupData) {
      setIsOpen(true);
    }
  };

  const onClose = () => setIsOpen(false);

  return {
    isOpen,
    handleOpenPopup,
    onClose,
    discountPercent,
  };
};
