import { TAlertKeys, TAlertTile } from 'constructorV1/store/commonSlice/types';

type TPrepareSelectedAlerts = {
  alerts: Record<TAlertKeys, TAlertTile> | null;
  discountPercent: null | number;
  gbValue: number;
  isFamilyConnected: boolean;
  minValue: number;
};

/** Подгатавливает ограничения для рендера согласно бизнес требованиям
 * Обращаемся к alerts по ключу. Основная привязка к 0 гб и 0 минут и к семье.
 * Плюс разовая привязка к полю скидки, приходящему из запроса на пакеты */
export const prepareSelectedAlerts = ({
  gbValue,
  minValue,
  isFamilyConnected,
  discountPercent,
  alerts,
}: TPrepareSelectedAlerts) =>
  [
    gbValue === 0 && minValue === 0 && !isFamilyConnected && alerts?.gbMinAll,
    gbValue === 0 && minValue !== 0 && !isFamilyConnected && alerts?.gb,
    gbValue !== 0 && minValue === 0 && !isFamilyConnected && alerts?.min,
    gbValue === 0 && minValue === 0 && isFamilyConnected && alerts?.familyAll,
    gbValue === 0 && minValue !== 0 && isFamilyConnected && alerts?.familyGb,
    gbValue !== 0 && minValue === 0 && isFamilyConnected && alerts?.familyMin,
    discountPercent && alerts?.discount,
  ].filter(Boolean) as TAlertTile[];
