import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { pathOr } from 'ramda';

import store from 'store';

// Actions
import { sendCvmAnalyticsEvent } from 'utils/analytics/cvmTariffAnalytics';

import { replaceDownsellTariff } from '../../actions/downsellTariff/replaceDownsellTariff';

// Utils

class DownsellTariffCardBodyPopupHOC extends Component {
  // CVM Analytics
  setCvmAnalytics = (state) => {
    const { offers } = this.props;
    sendCvmAnalyticsEvent(offers, state, 'downsale');
  };
  // ------------

  handlerConnectTariff = () => {
    const {
      dataView: { connectPopupUrl, constructorId },
    } = this.props;

    this.props.onDownsellConfirm(connectPopupUrl, null, { constructorId });

    this.setCvmAnalytics('landApproved');
  };

  handlerCancelDownsell = () => {
    const {
      dataView: { resetAdsUrl },
    } = this.props;
    this.props.onDownsellConfirm(resetAdsUrl, null, null, false);

    this.setCvmAnalytics('refuse');
  };

  render() {
    const { children, dataView } = this.props;

    return cloneElement(children, {
      connectDownsellTariff: this.handlerConnectTariff,
      cancelDownsellTariff: this.handlerCancelDownsell,
      dataModal: dataView,
    });
  }
}

DownsellTariffCardBodyPopupHOC.propTypes = {
  dataView: PropTypes.shape({
    connectPopupUrl: PropTypes.string,
    constructorId: PropTypes.number,
    resetAdsUrl: PropTypes.string,
  }),
  onDownsellConfirm: PropTypes.func,
  offers: PropTypes.array,
};

const mapStateToProps = ({ external }) => ({
  ...pathOr({}, ['tariffConnectionPopup', 'downsellTariffCardModal'], external),
  offers: pathOr({}, ['tariffConnectionPopup', 'data', 'offers'], external),
});

const ConnectedComponent = connect(mapStateToProps, (dispatch) => ({
  onDownsellConfirm: (url, tariffData, payload, canRetry) =>
    dispatch(replaceDownsellTariff(url, tariffData, payload, canRetry)),
}))(DownsellTariffCardBodyPopupHOC);

export default (props) => (
  <Provider store={store}>
    <ConnectedComponent {...props} />
  </Provider>
);
