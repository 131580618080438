import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

import { ModalContainer, TModalContainer } from '../../../../modalComponents/ModalContainer';
import { ModalHeader, TModalHeader } from '../../../../modalComponents/ModalHeader';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TButtonContainer = {
  children: ReactNode;
  modalContainerProps: Omit<TModalContainer, 'children'> | null;
  modalHeaderProps: TModalHeader | null;
  onOpen: () => void;
};

/** Компонент-обертка над промо-баннером с модальным окном */
export const ButtonContainer: FC<TButtonContainer> = ({
  onOpen,
  children,
  modalHeaderProps,
  modalContainerProps,
}) => (
  <>
    <button className={cx('button', 'banner-button')} onClick={onOpen} type="button">
      {children}
    </button>
    {modalContainerProps && (
      <ModalContainer {...modalContainerProps}>
        <ModalHeader {...modalHeaderProps} />
      </ModalContainer>
    )}
  </>
);
