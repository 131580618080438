import React, { FC } from 'react';

import { InfoList } from '../InfoList';
import { InfoPrice, TInfoPrice } from '../InfoPrice';
import { TItemInfoList } from '../InfoList/components/ItemInfoList';

type TChildrenContainer = {
  infoList?: TItemInfoList[];
  infoPrice?: TInfoPrice;
  variant: 'horizontal' | 'vertical';
};

/** Компонент обертка для вывода плиток доп. услуг ServiceTile */
export const ChildrenContainer: FC<TChildrenContainer> = ({ variant, infoList, infoPrice }) => (
  <>
    <InfoList {...{ infoList, variant }} />
    <InfoPrice {...{ infoPrice, variant }} />
  </>
);
