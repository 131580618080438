import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { AnimalTab } from '../AnimalTab';
import { TUppersTabs } from '../../types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Маппер табов апперов для поапа смены персонажа */
export const UppersTabs: FC<TUppersTabs> = ({ tabs, onClick, activeColor, activeCharacter }) => (
  <div className={cx('wrapper')}>
    {tabs?.map((tab) => (
      <AnimalTab key={tab?.name} {...{ ...tab, onClick, activeColor, activeCharacter }} />
    ))}
  </div>
);
