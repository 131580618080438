import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import FancyNumberWidget from 'pages/ProductCard/components/FancyNumberWidget/FancyNumberWidget';
import FancyNumberWidgetHOC from 'pages/ProductCard/hoc/FancyNumberWidgetHOC';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Tab = ({ id, title, onClick, selected, fancyNumber }) => {
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        onClick(event);
      }
    },
    [onClick],
  );

  return (
    <div
      className={cx('tab', { 'tab--active': selected === id })}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <Text color={selected === id ? 'white' : 'grey95'} size="size5-r-s">
        {title}
      </Text>
      {fancyNumber && (
        <FancyNumberWidgetHOC>
          <FancyNumberWidget
            customClassName={cx('tab-fancynumber', {
              'tab-fancynumber--disabled': selected !== id,
            })}
            refreshNumberClick
          />
        </FancyNumberWidgetHOC>
      )}
    </div>
  );
};

Tab.propTypes = {
  id: PropTypes.number,
  onClick: PropTypes.func,
  title: PropTypes.string,
  selected: PropTypes.number,
  fancyNumber: PropTypes.bool,
};
