import { shallowEqual } from '@beef/utils';

import { useAppSelector } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

/** Хук в рамках первой версии единого конструктора,
 *  определяет необходимость показа плиток способностей */
export const useCheckShowTiles = () => {
  const { tariffsInfo } = useAppSelector(
    (state) => ({
      tariffsInfo: state.tariffsInfo,
    }),
    shallowEqual,
  );

  return tariffsInfo.every((i) => i.advantages && i.name);
};
