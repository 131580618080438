import { openAuthModal } from '@beef/layout-kit/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAnketaFieldNames, getValidateAndSetError, setHasSubmitted } from '.';
import {
  validateAllowedCharacters,
  validateMaskedAlternative,
  validateName,
} from '../../../../../../utils/validators';
import { postAnketa } from '../../api/index';
import { StorageHelper, getHasErrors, processTextWithCustomRegex, retry } from '../../utils';
import { pushAnniversaryEvent } from '../../utils/analytics';
import { openModal } from '../modal';
import {
  getIsAuthorized,
  selectAnketa,
  selectContent,
  selectData,
  selectFormValues,
} from './selectors';

export const validateAnketa = createAsyncThunk('anketa/validate', async (_, thunkApi) => {
  const { dispatch, getState } = thunkApi;
  try {
    const state = selectAnketa(getState());
    const content = selectContent(state);

    const validateAndSetError = getValidateAndSetError(dispatch, state);

    const requiredValidator = ['required', (v) => !v];
    const getLimitValidator = (limit = 30) => ['limit', (v) => v.length > limit];
    const allowedCharactersValidator = [
      'format',
      (v) =>
        new RegExp(content?.story?.regex || '[^a-zA-Zа-яА-ЯёЁ!№%:,.;"\'()?_+= ,\\d-]+').test(v),
    ];
    const nameValidator = ['format', (v) => validateName(v)];
    const phoneValidator = ['format', (v) => !validateMaskedAlternative(v)];

    const fieldNameValidators = {
      firstName: [requiredValidator, nameValidator, getLimitValidator()],
      lastName: [requiredValidator, nameValidator, getLimitValidator()],
      city: [requiredValidator],
      phone: [requiredValidator, phoneValidator],
      storyTitle: [requiredValidator, allowedCharactersValidator, getLimitValidator()],
      story: [requiredValidator, allowedCharactersValidator, getLimitValidator(1500)],
    };

    const hasErrors = getHasErrors(fieldNameValidators, validateAndSetError);

    if (hasErrors) return;

    const isAuthorized = getIsAuthorized(state);

    if (!isAuthorized) {
      // NOTE: Persist values
      getAnketaFieldNames().forEach((fieldName) => {
        // NOTE: Don't persist the button state
        const fieldsToIgnore = ['button'];
        if (fieldsToIgnore.includes(fieldName)) return;
        const value = selectData(state)?.[fieldName]?.value;
        StorageHelper[fieldName] = value;
      });
      openAuthModal();
      return;
    }

    const hasParticipated = state?.meta?.isStorySent;

    if (hasParticipated) {
      dispatch(openModal('hasParticipated'));
      return;
    }

    dispatch(openModal('confirmation'));
  } catch (e) {
    pushAnniversaryEvent({
      error: {
        code: undefined,
        text: e.message,
      },
    });
    dispatch(openModal('error'));
  }
});

export const submitAnketa = createAsyncThunk('anketa/submit', async (_, thunkApi) => {
  const { dispatch, getState } = thunkApi;
  try {
    const state = selectAnketa(getState());
    const content = selectContent(state);
    const strip = content?.story?.strip;

    const { storyTitle, firstName, lastName, city, phone, story } = selectFormValues(state);

    const userStory = processTextWithCustomRegex(story, strip?.value, strip?.placeholder);

    await retry(() =>
      postAnketa(
        processTextWithCustomRegex(storyTitle, strip?.value, strip?.placeholder),
        firstName,
        lastName,
        city,
        userStory,
        phone,
      ),
    );
    pushAnniversaryEvent('story_accepted');

    dispatch(setHasSubmitted(true));
    dispatch(openModal('success'));
  } catch (e) {
    pushAnniversaryEvent({
      error: {
        code: undefined,
        text: e.message,
      },
    });
    dispatch(openModal('error'));
  }
});

export const initializeForm = createAsyncThunk(
  'anketa/initialize-form',
  async (formType, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    try {
      const state = getState()?.form || {};

      const isAuthorized = state?.meta?.isAuthorized;

      if (!isAuthorized) {
        getAnketaFieldNames().forEach((fieldName) => StorageHelper[fieldName]);
        return;
      }

      StorageHelper.personalDataAgreement = true;
      StorageHelper.rulesAgreement = true;
      StorageHelper.employeeAgreement = true;
    } catch (e) {
      pushAnniversaryEvent({
        error: {
          code: undefined,
          text: e.message,
        },
      });
      dispatch(openModal('error'));
    }
  },
);
