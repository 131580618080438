import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text, TextPropsType } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TRightPart = {
  extraUnit: string;
  oldPriceWithUnit?: string;
} & TextPropsType;

/** Правая часть компонента InfoPrice.
 * Включает в себя старую цену и общий unit */
export const RightPart: FC<Partial<TRightPart>> = ({ extraUnit, oldPriceWithUnit }) =>
  extraUnit ?
    <div className={cx('wrapper')}>
      {oldPriceWithUnit && (
        <div className={cx('wrapper__old')}>
          <Text color="grey40" isCrossedOut size="size5-r-s">
            {oldPriceWithUnit}
          </Text>
        </div>
      )}
      {extraUnit && (
        <Text color="grey60" size="size5-r-l">
          {extraUnit}
        </Text>
      )}
    </div>
  : null;
