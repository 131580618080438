import { useCallback, useState } from 'react';

import { DataType } from 'pages/ActionCategoryPage/types';

import { fetchPaginatedActions } from '../services/fetchActions';
import { ConvertedStateType } from '../types';

type UseGetActionsParams = Pick<ConvertedStateType, 'activeYear' | 'data' | 'pageInfo'>;

export const useGetActions = ({ data, pageInfo, activeYear }: UseGetActionsParams) => {
  const [actions, setActions] = useState<DataType[]>(data);
  const [page, setPage] = useState(pageInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchMoreLoading, setFetchMoreLoading] = useState(false);

  const getMoreActions = useCallback(
    async (yearId?: string) => {
      if (!yearId) setFetchMoreLoading(true);
      else setIsLoading(true);
      try {
        const { data: newActions, pageInfo: newPageInfo } = await fetchPaginatedActions(
          yearId || activeYear,
          yearId ? 1 : page.pageNumber + 1,
        );
        if (newActions?.length) {
          setActions((prevActions) => (yearId ? newActions : [...prevActions, ...newActions]));
        }
        if (newPageInfo) setPage(newPageInfo);
      } catch (err) {
        console.error(err);
      } finally {
        if (!yearId) setFetchMoreLoading(false);
        else setIsLoading(false);
      }
    },
    [activeYear, page],
  );

  const refetch = useCallback((yearId: string) => getMoreActions(yearId), [getMoreActions]);
  const fetchMore = useCallback(() => getMoreActions(), [getMoreActions]);

  return {
    actions,
    fetchMore,
    refetch,
    isLast: actions.length === page.totalCount,
    isLoading,
    isFetchMoreLoading,
  };
};
