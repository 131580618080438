import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { InfoBanner, PageFixer, PageRow } from '@beef/smart-kit';
import { connect } from 'react-redux';
import { Button } from '@beef/ui-kit';

import { connectBeep } from '../../store/beep/actions';
import { selectContent } from '../../store/selectors';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

const BeepConnectBannerLayout = ({ button, onSubmit, ...bannerProps }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      await onSubmit();
      setSuccess(true);
    } catch (err) {
      // no action needed
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageFixer>
      <PageRow blockSpacersPreset="m" tagName="section">
        <div className={cx('wrap')}>
          <InfoBanner {...bannerProps}>
            {!success && (
              <Button isLoading={loading} onClick={handleClick} variant={button.variant}>
                {button.text}
              </Button>
            )}
          </InfoBanner>
        </div>
      </PageRow>
    </PageFixer>
  );
};

const mapStateToProps = (state) => selectContent(state).beepConnectBanner;

const mapDispatchToProps = {
  onSubmit: connectBeep,
};

export const BeepConnectBanner = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BeepConnectBannerLayout);
