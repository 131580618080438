import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { TBonus, TPresetsDO } from 'constructorV1/store/tariffSettingsSlice/types';

import { apiGetPacketsUrl } from './constants';
import { TCommonRequest } from './types';

/** Тип ответа запроса changePackets пакетов ConstructorV1 */
type TPackets = {
  data: {
    bonuses?: TBonus[];
    constructorId: number;
    price: number;
    priceWithDiscount: number;
    stepsInternet: TPresetsDO;
    stepsMinutes: TPresetsDO;
  };
};

/** Тип ответа запроса changePackets пакетов ConstructorV1
 *  расширяющий `TPackets` и включающий общие поля запроса */
export type TFetchPacketsRes = TPackets & TCommonRequest;

/** Запрос на изменение мобильных пакетов ConstructorV1 */
export const fetchPackets = async (
  config: AxiosRequestConfig,
): Promise<AxiosResponse<TFetchPacketsRes>> => axios.get(apiGetPacketsUrl, config);
