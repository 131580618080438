import { path } from 'ramda';

import { deviceTypes } from '../pages/src/store/resizeListener';

const isDeviceType = (state, type) => path(['external', 'deviceType'], state) === type;

export const isMobileDevice = (state) => isDeviceType(state, deviceTypes.MOBILE);
export const isTabletDevice = (state) => isDeviceType(state, deviceTypes.TABLET);
export const isDesktopDevice = (state) => isDeviceType(state, deviceTypes.DESKTOP);

export const isIE = () => /(MSIE |Edge|Trident)/.test(window.navigator.userAgent);
