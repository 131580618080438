import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  TFetchFmcPresetsAction,
  TFetchFmcServicesAction,
  TFttbSlice,
  TFttbSliceReducer,
} from './types';
import { fetchFmcPresets } from '../../../api/fmсPresets';
import { fetchFmcServices } from '../../../api/fmcServices';
import { fetchPartnerFmcTariffs } from '../../../api/fetchPartnerFmcTariffs';

const initialState: TFttbSlice = {
  tariffs: [],
  previewTariffs: [],
  isErrorFmcRequest: false,
  requestPresetsHash: null,
  requestServicesHash: null,
  isLoadingFmcRequest: false,
  isPartnerFmcModalWasShown: false,
};

/** Slice доступных пресетов ШПД для главной страницы настройки конструктора */
export const fttbSlice = createSlice<TFttbSlice, TFttbSliceReducer>({
  name: 'fmsPresets',
  initialState,
  reducers: {
    /* установка флага показа попапа партнёрского шпд-тарифа */
    setIsPartnerFmcModalWasShown: (state, { payload }) => {
      state.isPartnerFmcModalWasShown = payload;
    },
    /* Состояние загрузки данных */
    setLoadingFmcRequest: (state, action) => {
      state.isLoadingFmcRequest = action.payload;
    },
    /* Установка хэша запроса на получение previewTariffs. Позволяет предотвратить лишние запросы к бэку */
    setRequestPresetsHash: (state, action) => {
      state.requestPresetsHash = action.payload;
    },
    /* Установка хэша запроса на получение tariffs. Позволяет предотвратить лишние запросы к бэку */
    setRequestServicesHash: (state, action) => {
      state.requestServicesHash = action.payload;
    },
    /* Установка данных в fttbSlice из кеша (persisted) */
    setPersistedFttbSlice: (_, action) => action.payload,
  },
  extraReducers: (builder) => {
    /* Этап получения ПОЛНЫХ настроек ШПД в текущем регионе */
    builder.addCase(
      fetchFmcServices.fulfilled,
      (state, action: PayloadAction<TFetchFmcServicesAction>) => {
        state.isErrorFmcRequest = !action.payload.isSuccess || false;
        state.isLoadingFmcRequest = false;
        state.tariffs = action.payload.tariffs || [];
      },
    );
    builder.addMatcher(
      isAnyOf(fetchFmcPresets.pending, fetchFmcServices.pending, fetchPartnerFmcTariffs.pending),
      (state) => {
        state.isErrorFmcRequest = false;
        state.isLoadingFmcRequest = true;
      },
    );
    builder.addMatcher(
      isAnyOf(fetchFmcPresets.rejected, fetchFmcServices.rejected, fetchPartnerFmcTariffs.rejected),
      (state) => {
        state.isErrorFmcRequest = true;
        state.isLoadingFmcRequest = false;
      },
    );
    /* Этап получения доступных пресетов ШПД в текущем регионе */
    builder.addMatcher(
      isAnyOf(fetchFmcPresets.fulfilled, fetchPartnerFmcTariffs.fulfilled),
      (state, action: PayloadAction<TFetchFmcPresetsAction>) => {
        state.isErrorFmcRequest = !action.payload.isSuccess || false;
        state.isLoadingFmcRequest = false;
        state.previewTariffs = action.payload.tariffs || [];
      },
    );
  },
});

export const {
  setRequestPresetsHash,
  setRequestServicesHash,
  setLoadingFmcRequest,
  setPersistedFttbSlice,
  setIsPartnerFmcModalWasShown,
} = fttbSlice.actions;

export const fttbSliceReducer = fttbSlice.reducer;
