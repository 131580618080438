import React, { memo, useState } from 'react';
import { Modal } from '@beef/ui-kit';

import {
  generateSteps,
  getChosenStep,
} from 'pages/PclLandingPage/components/modals/components/LimitModalLayout/utils';

import {
  useActivateLimitModalData,
  useChangeLimitModalData,
  useDeactivateDebtModalData,
  useDeactivateModalData,
  useErrorModalData,
  useSuccessModalData,
  useTopUpBalanceModalData,
} from '..';
import { ModalContentType, ModalsControllerProps, ModalsKeys } from './types';

export const ModalsController = memo<ModalsControllerProps>(
  ({
    modal,
    isOpen,
    onClose,
    onActivateLimit,
    onDeactivateLimit,
    onIncreaseBalanceClick,
    onChangeLimit,
    data,
    limit,
    isLoading,
    barValues,
    userData,
    payment,
  }) => {
    const [connectedLimit, setConnectedLimit] = useState(0);
    const steps = generateSteps(barValues?.minChange, barValues?.maxChange, barValues?.gap);
    const defaultValue =
      getChosenStep(
        barValues?.minChange,
        barValues?.maxChange,
        barValues?.gap,
        barValues?.chosenValue,
      ) ||
      steps?.length ||
      0;
    const activateLimitModalData = useActivateLimitModalData({
      onActivateLimit,
      data: data.limit,
      limit,
      barValues,
      setConnectedLimit,
      isLoading,
      defaultValue,
    });
    const successActivateModalData = useSuccessModalData({
      onClose,
      data: data.successful,
      limit: connectedLimit,
    });
    const errorActivateModalData = useErrorModalData({ onClose, data: data.error });

    const deactivateLimitModalData = useDeactivateModalData({
      onClose,
      onDeactivateLimit,
      data: data.deactivate,
      isLoading,
    });
    const successDeactivateModalData = useSuccessModalData({
      onClose,
      data: data.deactivateSuccessful,
      limit,
    });
    const errorDeactivateModalData = useErrorModalData({ onClose, data: data.deactivateError });

    const changeLimitModalData = useChangeLimitModalData({
      onChangeLimit,
      data: data.limit,
      limit,
      barValues,
      setConnectedLimit,
      isLoading,
      defaultValue,
    });
    const successChangeLimitModal = useSuccessModalData({
      onClose,
      data: data.updateSuccessful,
      limit: connectedLimit,
    });
    const errorChangeLimitModal = useErrorModalData({ onClose, data: data.updateError });

    const deactivateDebtModalData = useDeactivateDebtModalData({
      onIncreaseBalanceClick,
      onClose,
      data: data.deactivateDebt,
    });
    const topUpBalanceModalData = useTopUpBalanceModalData({ userData, payment });

    const modals: { [key: string]: ModalContentType } = {
      [ModalsKeys.activateLimitModal]: activateLimitModalData,
      [ModalsKeys.successActivateModal]: successActivateModalData,
      [ModalsKeys.errorActivateModal]: errorActivateModalData,

      [ModalsKeys.deactivateLimitModal]: deactivateLimitModalData,
      [ModalsKeys.successDeactivateModal]: successDeactivateModalData,
      [ModalsKeys.errorDeactivateModal]: errorDeactivateModalData,

      [ModalsKeys.changeLimitModal]: changeLimitModalData,
      [ModalsKeys.successChangeLimitModal]: successChangeLimitModal,
      [ModalsKeys.errorChangeLimitModal]: errorChangeLimitModal,
      [ModalsKeys.deactivateDebtModal]: deactivateDebtModalData,
      [ModalsKeys.topUpBalanceModal]: topUpBalanceModalData,
    };

    return (
      <Modal
        actionBar={modals[modal]?.actionBar}
        headerBarConfig={{ headerText: modals[modal]?.title }}
        isOpen={isOpen}
        onClose={onClose}
        size={modals[modal]?.modalSize || 's'}
      >
        {modals[modal]?.content}
      </Modal>
    );
  },
);
