import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Button, Link, useIsMobileDimension } from '@beef/ui-kit';

import { TButton, TButtonExtra } from '../../types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TActionBar = {
  button: TButton;
  extraButton: TButtonExtra;
  onClose: () => void;
};

/** Блок кнопок> */
export const ActionBar: FC<TActionBar> = ({ button, extraButton, onClose }) => {
  const { text, link } = button || {};
  const { text: extraText } = extraButton || {};
  const isMobile = useIsMobileDimension();

  return (
    <div className={cx('component')}>
      {text && link && (
        <Link
          buttonSize="m"
          buttonVariant="main"
          href={link}
          isFullWidth={isMobile}
          variant="button"
        >
          {text}
        </Link>
      )}
      {extraText && (
        <Button fullWidth={isMobile} onClick={onClose} size="m" variant="tertiary">
          {extraText}
        </Button>
      )}
    </div>
  );
};
