import React, { ComponentProps, FC } from 'react';
import classNames from 'classnames/bind';
import { Title as OriginTitle } from '@carnica/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TTitle = ComponentProps<typeof OriginTitle> & {
  withoutExtraPaddings?: boolean;
};

/** Компонент-обертка для Title из carnica/ui-kit с доп. отступами */
export const Title: FC<TTitle> = ({ className, withoutExtraPaddings = false, ...props }) => {
  return <OriginTitle {...props} className={cx({ wrapper: !withoutExtraPaddings }, className)} />;
};
