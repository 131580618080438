import { createReducer } from '@reduxjs/toolkit';

import { setActiveCategories, setCategory } from '../actions/category';

export const category = createReducer(
  {
    active: {},
  },
  {
    [setCategory.type]: (state, action) => {
      state.active[action.payload.key] = action.payload.value;
    },
    [setActiveCategories.type]: (state, action) => {
      action.payload.forEach((key) => {
        state.active[key] = true;
      });
    },
  },
);
