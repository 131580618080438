import React from 'react';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';
import { RequestStatus } from '@beef/smart-kit';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

export const _RenderRequestStatus = ({ texts, onClose }) => (
  <RequestStatus
    button={texts.button}
    description={texts.description}
    img={texts.img}
    onConfirm={onClose}
    title={texts.title}
    withModal
  />
);

_RenderRequestStatus.propTypes = {
  texts: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    img: PropTypes.shape({
      src: PropTypes.string,
    }),
    button: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func,
};

const mapStateToProps = (_state, { texts }) => ({
  shouldRender: !isEmpty(texts),
});

export const RenderRequestStatus = compose(
  connect(mapStateToProps),
  withRenderFlag,
)(_RenderRequestStatus);
