import React from 'react';

import { TModalMap } from './types';

type TWithModalArrayProps<T extends string> = {
  currentModal: T | null;
  setCurrentModal: (modal: T | null) => void;
};

export function createModalContainer<T extends string>(
  ModalComponents: Partial<TModalMap<T>>,
): React.FC<TWithModalArrayProps<T>> {
  return ({ currentModal, setCurrentModal }: TWithModalArrayProps<T>) => (
    <>
      {Object.entries<TModalMap<T>[T] | undefined>(ModalComponents)
        .filter(([modalName, entry]) => currentModal === modalName && entry?.component)
        .map(([modalName, entry]) => {
          const Component = entry?.component as React.FC<{
            setCurrentModal: (modal: T | null, shouldDisableButton?: boolean) => void;
          }>;

          return <Component key={modalName} setCurrentModal={setCurrentModal} />;
        })}
    </>
  );
}
