const ENG_KEYBOARD_STRING = "`qwertyuiop[]asdfghjkl;'zxcvbnm,.{}";
const RU_KEYBOARD_STRING = 'ёйцукенгшщзхъфывапролджэячсмитьбюхъ';

export const reverseKeyboard = (str) => {
  const strLower = str.toLowerCase();
  const result = [];

  for (let i = 0; i < strLower.length; i + 1) {
    const char = strLower[i];

    if (ENG_KEYBOARD_STRING.includes(char)) {
      result.push(RU_KEYBOARD_STRING[ENG_KEYBOARD_STRING.indexOf(char)]);
    } else {
      result.push(char);
    }
  }

  return result.join('');
};
