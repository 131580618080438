import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'ramda';
import { SwitchTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames/bind';

import EmptyButton from 'pages-components/EmptyButton';
import SlidingDown from 'pages-components/SlidingDown';

import { reverseKeyboard } from './utils';
import { SlideAndFade, SlideAndFadeRight } from '../Animations';
import Categories from '../Categories';
import ChannelsList from '../ChannelsList';
import FlatButton from '../FlatButton';
import TvPackagesGroup from '../TvPackagesGroup';
import SearchInput from '../SearchInput';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * @typedef {Object} CurrentListStates
 * @property {string} search
 * @property {string} showAll
 * @property {string} choosedCategory
 */
const currentListStates = {
  search: 'search',
  showAll: 'showAll',
  choosedCategory: 'choosedCategory',
};

const isMatchToSearchString = (str, searchValue) => {
  const normalizedStr = str.toLowerCase();
  return (
    normalizedStr.includes(searchValue) || normalizedStr.includes(reverseKeyboard(searchValue))
  );
};

class ChannelsForm extends PureComponent {
  constructor(props) {
    super(props);

    /** @type {React.RefObject<HTMLDivElement>} */
    this.channelsRef = createRef();

    /**
     * @type {{
     *  searchInputValue: string,
     *  activeCategory: number,
     *  currentListState: CurrentListStates,
     *  activeTvPackages: string[],
     *  showAllPackagesForGroups: string[],
     * }}
     */
    this.state = {
      searchInputValue: '',
      activeCategory: 0,
      currentListState: currentListStates.choosedCategory,

      activeTvPackages: props.initialSelectedTvPackageAliases,
      showAllPackagesForGroups: [],
    };
  }

  onShowAllChannelsClick = () => {
    if (this.isAllChannelsList) {
      this.setState({ currentListState: currentListStates.choosedCategory });
    } else {
      this.setState({ currentListState: currentListStates.showAll });
    }
  };

  onSearchInputChange = ({ target }) => this.setState({ searchInputValue: target.value });

  onSearchInputFocus = () => {
    if (this.isSearchList) return;
    this.setState({ currentListState: currentListStates.search });
  };

  onSearchInputBlur = () => {
    this.setState((state) => {
      if (state.searchInputValue.length) return;

      // eslint-disable-next-line consistent-return
      return { currentListState: currentListStates.choosedCategory };
    });
  };

  onSearchInputClear = (setInFocus) => this.setState({ searchInputValue: '' }, setInFocus);

  onCancelSearchClick = () => {
    this.setState({ searchInputValue: '', currentListState: currentListStates.choosedCategory });
  };

  onTvPackageActiveChange = (tvPackageAlias) => {
    const { activeTvPackages } = this.state;

    const uniqActivePackages = uniq(activeTvPackages);

    const newActiveTvPackages =
      this.isActiveTvPackage(tvPackageAlias) ?
        uniqActivePackages.filter((alias) => alias !== tvPackageAlias)
      : uniqActivePackages.concat(tvPackageAlias);

    this.setState(
      {
        activeTvPackages: newActiveTvPackages,
      },
      () => this.props.onTvPackageActiveChange(newActiveTvPackages),
    );
  };

  onShowAllPackagesClick = (alias) => {
    const { showAllPackagesForGroups } = this.state;
    this.setState({ showAllPackagesForGroups: showAllPackagesForGroups.concat(alias) });
  };

  onChannelsListEntered = () => {
    if (this.isAllChannelsList) {
      this.channelsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  getFiltredChannels = () => {
    const { searchInputValue } = this.state;
    const { allChannels } = this.props.data;

    if (searchInputValue) {
      const value = searchInputValue.trim().toLowerCase();

      return allChannels.filter((channel) => isMatchToSearchString(channel.name, value));
    }

    return allChannels;
  };

  getCurrentTvPackagesGroups = () => {
    const { tvPackagesGroups } = this.props.data;

    if (!tvPackagesGroups) return [];

    if (this.isSearchList) {
      const { searchInputValue } = this.state;
      const value = searchInputValue.trim().toLowerCase();

      return tvPackagesGroups
        .map((tvPackagesGroup) => ({
          ...tvPackagesGroup,
          tvPackages: tvPackagesGroup.tvPackages.reduce((filtredTvPackages, tvPackage) => {
            if (!tvPackage.tvChannels) return filtredTvPackages;

            const filtredTvChannels = tvPackage.tvChannels.filter((channel) =>
              isMatchToSearchString(channel.name, value),
            );
            if (!filtredTvChannels.length) return filtredTvChannels;

            const newTvPackageWithFiltredChannels = {
              ...tvPackage,
              tvChannels: filtredTvChannels,
            };

            return filtredTvPackages.concat(newTvPackageWithFiltredChannels);
          }, []),
        }))
        .filter((tvPackagesGroup) => tvPackagesGroup.tvPackages.length);
    }

    return tvPackagesGroups;
  };

  getCurrentChannels = () => {
    const { categories, allChannels } = this.props.data;
    const { activeCategory } = this.state;

    if (this.isSearchList) return this.getFiltredChannels();
    if (this.isAllChannelsList) return allChannels;

    if (categories) return categories[activeCategory].channels;

    return allChannels;
  };

  getMaxTvPackagesCountForGroup = (alias) => {
    const showAllPackages = this.state.showAllPackagesForGroups.includes(alias);

    if (showAllPackages || this.isSearchList) return Number.MAX_SAFE_INTEGER;

    return this.props.data.maxTvPackagesCount;
  };

  setActiveCategory = (categoryIndex) => this.setState({ activeCategory: categoryIndex });

  get isSearchList() {
    return this.state.currentListState === currentListStates.search;
  }

  get isAllChannelsList() {
    return this.state.currentListState === currentListStates.showAll;
  }

  get channelsListKey() {
    if (this.isSearchList) return 'SearchList';
    if (this.isAllChannelsList) return 'AllChannels';

    return `ChannelsList-${this.state.activeCategory}`;
  }

  get showClearIcon() {
    return this.state.searchInputValue.length > 0;
  }

  isActiveTvPackage = (alias) => this.state.activeTvPackages.includes(alias);

  renderAllChannelsBtn(type) {
    const { allChannelsBtn } = this.props.contentData;

    return (
      <FlatButton
        className={cx('allChannelsBtn', type)}
        isCloseButton={this.isAllChannelsList}
        onClick={this.onShowAllChannelsClick}
      >
        {allChannelsBtn}
      </FlatButton>
    );
  }

  render() {
    const { data, contentData, isWithoutUpsaleSwitch, hideChannels, title } = this.props;

    const {
      searchPlaceholder,
      cancelSearchText,
      notFoundChannelNote,
      notFoundTextPrefix,
      notFoundTextPostfix,
      tvPackagesGroup: tvPackagesGroupContent,
    } = contentData;

    const { channelsCount, channelsLabel, categories } = data;

    const { searchInputValue, activeCategory, activeTvPackages } = this.state;

    const currChannels = this.getCurrentChannels();
    const currTvPackagesGroups = this.getCurrentTvPackagesGroups();

    return (
      <div className={cx('wrapper')}>
        <div className={cx('title')}>
          {title ?
            <span dangerouslySetInnerHTML={{ __html: title }} />
          : <span dangerouslySetInnerHTML={{ __html: `${channelsCount} ${channelsLabel}` }} />}
        </div>
        <div className={cx('searchWrap')}>
          <div className={cx('searchInputWrap')}>
            <SearchInput
              onBlur={this.onSearchInputBlur}
              onChange={this.onSearchInputChange}
              onClear={this.onSearchInputClear}
              onFocus={this.onSearchInputFocus}
              placeholder={searchPlaceholder}
              showClearIcon={this.showClearIcon}
              value={searchInputValue}
            />
          </div>
          <TransitionGroup>
            {this.isSearchList && (
              <SlideAndFadeRight in timeout={200}>
                <EmptyButton className={cx('cancelSearch')} onClick={this.onCancelSearchClick}>
                  <span dangerouslySetInnerHTML={{ __html: cancelSearchText }} />
                </EmptyButton>
              </SlideAndFadeRight>
            )}
          </TransitionGroup>
        </div>
        {this.isSearchList && currChannels.length === 0 ?
          <div className={cx('notFoundChannelsWrap')}>
            <div className={cx('notFoundChannelsText')}>
              <span dangerouslySetInnerHTML={{ __html: notFoundTextPrefix }} />
              <b>{searchInputValue}</b>
              <span dangerouslySetInnerHTML={{ __html: notFoundTextPostfix }} />
            </div>
            <div
              className={cx('notFoundChannelsNote')}
              dangerouslySetInnerHTML={{ __html: notFoundChannelNote }}
            />
          </div>
        : <>
            {!hideChannels && (
              <>
                {categories && categories.length > 0 && (
                  <SlidingDown show={!this.isSearchList}>
                    <div className={cx('categoriesWrap')}>
                      <Categories
                        activeIndex={this.isAllChannelsList ? null : activeCategory}
                        categories={categories}
                        onCategoryClick={this.setActiveCategory}
                      />
                      {this.renderAllChannelsBtn('desktop')}
                    </div>
                  </SlidingDown>
                )}
                <div className={cx('channelsWrap')} ref={this.channelsRef}>
                  <SwitchTransition>
                    <SlideAndFade
                      in
                      key={this.channelsListKey}
                      onEntered={this.onChannelsListEntered}
                      timeout={160}
                    >
                      <ChannelsList channels={currChannels} />
                    </SlideAndFade>
                  </SwitchTransition>
                </div>
                {!this.isSearchList && this.renderAllChannelsBtn('mobile')}
              </>
            )}
            {currTvPackagesGroups && !!currTvPackagesGroups.length && (
              <div className={cx('tvPackagesGroups')}>
                {currTvPackagesGroups.map((tvPackagesGroup) => (
                  <div className={cx('tvPackageGroupWrap')} key={tvPackagesGroup.alias}>
                    <TvPackagesGroup
                      activeTvPackages={activeTvPackages}
                      contentData={tvPackagesGroupContent}
                      forceOpenTvPackageChannels={this.isSearchList}
                      isWithoutUpsaleSwitch={isWithoutUpsaleSwitch}
                      maxTvPackagesCount={this.getMaxTvPackagesCountForGroup(tvPackagesGroup.alias)}
                      onShowAllPackagesClick={this.onShowAllPackagesClick}
                      onTvPackageActiveChange={this.onTvPackageActiveChange}
                      tvPackagesGroup={tvPackagesGroup}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        }
      </div>
    );
  }
}

ChannelsForm.defaultProps = {
  onTvPackageActiveChange: () => {},
  initialSelectedTvPackageAliases: [],
};
ChannelsForm.propTypes = {
  data: PropTypes.shape({
    channelsCount: PropTypes.number,
    channelsLabel: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        channels: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            image: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
          }),
        ),
      }),
    ),
    tvPackagesGroups: PropTypes.arrayOf(
      PropTypes.shape({
        alias: PropTypes.string,
        name: PropTypes.string,
        tvPackages: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            channelsCountLabel: PropTypes.string,
            fee: PropTypes.shape({
              value: PropTypes.string,
              unit: PropTypes.string,
            }),
            alias: PropTypes.string,
            description: PropTypes.string,
            tvChannels: PropTypes.arrayOf(PropTypes.shape({})),
          }),
        ),
      }),
    ),
    allChannels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        image: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
    maxTvPackagesCount: PropTypes.number,
  }),
  isWithoutUpsaleSwitch: PropTypes.bool,
  hideChannels: PropTypes.bool,
  title: PropTypes.string,
  contentData: PropTypes.shape({
    searchPlaceholder: PropTypes.string,
    allChannelsBtn: PropTypes.string,
    notFoundChannelNote: PropTypes.string,
    cancelSearchText: PropTypes.string,
    notFoundTextPrefix: PropTypes.string,
    notFoundTextPostfix: PropTypes.string,
    tvPackagesGroup: PropTypes.shape({}),
  }),
  onTvPackageActiveChange: PropTypes.func,
  initialSelectedTvPackageAliases: PropTypes.arrayOf(PropTypes.string),
};

export default ChannelsForm;
