import React, { Children, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Description, Descriptions } from '@beef/legacy-ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/*
 * Обертка для компонента Descriptions. Поддерживает 2 режима:
 * 1. - Childfree компонент, в который достаточно прокинуть массив items.
 * 2. - Принимает детей и массив items. Для каждого i-го item'а рендерит i-го ребенка.
 *      Можно использовать в случае, когда одно из описаний - кастомный компонент с собственной логикой
 */

const DescriptionsWrapper = forwardRef(
  ({ titleTag, items, children, className, onChangeIndex }, ref) => {
    const childrenArray = Children.toArray(children);

    return items ?
        <div className={cx('component', className)} ref={ref}>
          <Descriptions
            className={cx('descriptions')}
            onChangeIndex={onChangeIndex}
            titleTag={titleTag}
          >
            {items.map(({ title, content }, index) => (
              <Description key={index} title={title}>
                {childrenArray[index] || <div dangerouslySetInnerHTML={{ __html: content }} />}
              </Description>
            ))}
          </Descriptions>
        </div>
      : null;
  },
);

DescriptionsWrapper.propTypes = {
  titleTag: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.any,
  onChangeIndex: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    }),
  ),
};

export default DescriptionsWrapper;
