import { TAlertKeys, TBenefitDO } from 'constructorV1/store/commonSlice/types';

/** Справочник ошибок валидации mnp */
export const errorsType = {
  notSuccess: 'notSuccess',
  failedRequest: 'failedRequest',
  isExistingCtn: 'isExistingCtn',
  isCtnAlreadyInBasket: 'isCtnAlreadyInBasket',
} as const;

/** Справочник блоков-скелетонов чека ЕК */
export enum ESkeletonBillTypes {
  settings = 'settings',
  tabs = 'tabs',
  price = 'price',
  bottom = 'bottom',
}

/** Справочник блоков-скелетонов результирующего чека ЕК */
export enum ESkeletonCBillTypes {
  settings = 'settings',
  button = 'button',
  details = 'details',
}

/** Справочник блоков-скелетонов плавающего блока ЕК */
export enum ESkeletonFloatingTotalTypes {
  price = 'price',
  button = 'button',
}

/** Интерфейс данных из контентной части для ограничений */
interface IAlertItem {
  buttonText: string;
  description?: string;
  text: string;
  title: string;
}

/** Интерфейс для набора ограничений */
export interface IAlerts extends Record<TAlertKeys, IAlertItem> {}

/** Интерфейс части данных ограничений, вычисляемых на бекенде */
export interface IAlertsPopupItem {
  description: string;
  price: null | string;
  unit: null | string;
}

/** Интерфейс данных ограничений, вычисляемых на бекенде */
export interface IAlertsPopupItems {
  items: IAlertsPopupItem[];
}

/** Интерфейс данных ограничений, получаемых от бекенда */
export interface IAlertsPopupData {
  gb: IAlertsPopupItems | null;
  min: IAlertsPopupItems | null;
}

/** Интерфейс для рендера сетевой ошибки */
interface IErrorBoundaryDO {
  btnText: string;
  description: string;
  image: string;
  title: string;
}

/** Интерфейс для заголовков (опций) секций */
export interface ISectionTitle {
  subTitle?: string;
  title: string;
}

/** Интерфейс настроек свайпера (моб/десктоп) */
interface IBenefitsSettings {
  subTitle?: string;
  title: string;
}

/** Интерфейс контентных данных для малого чека ЕК */
interface IBillContent {
  mnpContent: {
    btnText: string;
    description: string;
    mnpValidateErrors: {
      failedRequest: string;
      isCtnAlreadyInBasket: string;
      isExistingCtn: string;
      notSuccess: string;
    };
    title: string;
  };
  simTabs: Array<{
    icon: string;
    id: string;
    subTitle: string;
    title: string;
  }>;
  tariffTabs: {
    tabs: Array<{
      id: string;
      title: string;
    }>;
    title: string;
  };
  totalAmountContent: {
    amountContent: {
      period: string;
      title: string;
      withoutPayText: string;
    };
    btnText: string;
    extraAmountContent: {
      price: number;
      title: string;
    };
  };
}

/** Интерфейс контентных данных для результирующего чека ЕК */
interface ICBillContent {
  btnCText: string;
  btnCWText: string;
  conditionsTitle: string;
  conditionsWTitle: string;
  disablingTitle: string;
  pageTitle: string;
  writeOffTitle: string;
}

/** Интерфейс заголовка результирующего чека ЕК */
interface ICBillContent {
  align: string;
  size: string;
  title: string;
  titleTagName: string;
}

/** Интерфейс контентной части плавающего блока с кнопкой и ценой ЕК (моб) */
interface ICBillContent {
  btnText: string;
  title: string;
  unit: string;
}

/** Интерфейс базовой структуры контентной части финальной страницы ЕК */
interface IResultPageItem {
  acceptLink: string;
  acceptText: string;
  declineLink: string;
  declineText: string;
  subTitle: string;
  title: string;
}

/** Общий интерфейс контентной части финальной страницы ЕК */
interface IResultPage {
  requestErrors: {
    balance_error: IResultPageItem;
    changed_error: IResultPageItem;
    number_is_blocked: IResultPageItem;
    personal_data_error: IResultPageItem;
    something_error: IResultPageItem;
  };
  resultPage: IResultPageItem;
}

/** Общий интерфейс контента */
export interface IContent {
  alerts: IAlerts;
  benefitsSettings: IBenefitsSettings;
  bill: IBillContent;
  commonBill: ICBillContent;
  commonTitle: ICBillContent;
  errorBoundary: IErrorBoundaryDO;
  fag: Record<string, any>;
  floatingTotal: ICBillContent;
  giftBillIcon: string;
  giftIcon: string;
  giftTileIcon: string;
  presetsTitle: string;
  resultPage: IResultPage;
  sectionsTitles: ISectionTitle[];
}

/** Интерфейс бенефитов (УПД) */
interface IBenefits {
  description: string;
  iconUrl: string;
  title: string;
}

/** Интерфейс блока с информацией по тарифу */
interface ITariffInfo {
  benefits: TBenefitDO[];
  id: number;
  isArchived: boolean;
  isConnected: boolean;
  soc: string;
}

/** Интерфейс блока с информацией по юзеру */
interface IUserInfo {
  activeCTN?: string;
  isAuthenticated: boolean;
  isFamilyConnected: boolean;
}

/** Общий интерфейс data */
export interface IData {
  data: {
    baseUrl: string;
    layoutData: {
      alertsPopupData: IAlertsPopupData;
      benefits: IBenefits[];
      content: IContent;
      tariffInfo: ITariffInfo;
      userInfo: IUserInfo;
    };
    layoutType: string;
  };
}
