import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

/**
 * Divides page into two columns and makes right column sticky positioned
 */
export const ColumnsLayout = ({ leftColumn, rightColumn }) => (
  <div className={cx('columns')}>
    <div className={cx('columns_left-wrap')}>{leftColumn}</div>
    <div className={cx('columns_right-wrap')}>
      <div className={cx('columns_right')}>{rightColumn}</div>
    </div>
  </div>
);

ColumnsLayout.propTypes = {
  leftColumn: PropTypes.element,
  rightColumn: PropTypes.element,
};
