import { getMockRequestHandlerWhenAllowed } from 'utils/mock';

import { TPartnerProductInfoResponse } from '../../../CharactersTabs/types';
import { makeRequest } from './makeRequest';

const mockName = 'mock:partner-product-list';
const DefaultUrl = '/api/partner-subscriptions/partner-products-list/';

export const getPartnerProductList = (params: { soc?: string } = {}) => {
  const originalHandler = (url = DefaultUrl) =>
    makeRequest({
      url,
      params,
      headers: {
        'content-type': 'application/json',
      },
    }) as Promise<TPartnerProductInfoResponse>;

  return getMockRequestHandlerWhenAllowed<Promise<TPartnerProductInfoResponse>>({
    originalHandler,
    mockHandler: (url) => originalHandler(url),
    mockName,
  });
};
