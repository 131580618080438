import React, { FC } from 'react';

// TODO: не забыть заменить импорт при выносе FmcTabs в отдельный пакет!
import { FmcTabs } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/FmcTabs';

import { FmcSkeleton } from './components/FmcSkeleton';
import { FmcTabModal } from './components/FmcTabModal';
import { ConvergenceConnected } from '../ConvergenceConnected';
import { FmcLoadingError } from './components/FmcLoadingError';
import { WPContainer } from '../../commonComponents/WPContainer';
import { ArchivedWithoutFttbBlock } from '../ArchivedWithoutFttbBlock';
import { usePrepareFmcTabsContainer } from './hooks/usePrepareFmcTabsContainer';
import { useGetAvailableFmcPresets } from '../../../hooks/useGetAvailableFmcPresets';
import { ArchivedNoTariffUpWithFttbBlock } from '../ArchivedNoTariffUpWithFttbBlock';

/** Компонент-обертка над доступными ШПД пресетов тарифа */
export const FmcTabsContainer: FC = () => {
  const {
    hasFmcRegion,
    isShowFmcTabs,
    fmcTabModalProps,
    hasErrorFmcPresets,
    isLoadingFmcRequest,
    hasPartnerFmcRegion,
    chipsStatelessFmcProps,
    isArchivedNoTariffUpWithtFttb,
    isShowArchivedWithoutFttbBlock,
    isAuthConnectedSettingsFTTBUserType,
  } = usePrepareFmcTabsContainer();

  /* Получаем доступные пресеты ШПД */
  useGetAvailableFmcPresets();

  /* Если пользователь на тарифе не ап с конвергенцией, показываем блок с просьбой перейти на обновленный тариф и
   * блок ConvergenceConnected БЕЗ возможности настроить ШПД */
  if (isArchivedNoTariffUpWithtFttb) {
    return (
      <>
        <ArchivedNoTariffUpWithFttbBlock />
        <ConvergenceConnected withoutButton />
      </>
    );
  }
  /* Если пользователь на архивном тарифе ап без конвергенции, показываем блок */
  if (isShowArchivedWithoutFttbBlock) {
    return <ArchivedWithoutFttbBlock />;
  }

  /* Если пользователь уже является конвергентом, показываем блок подключенной конвергенции */
  if (isAuthConnectedSettingsFTTBUserType) {
    return <ConvergenceConnected />;
  }

  /* 1. Если в регионе доступен ШПД (hasFmcRegion), либо ШПД от партнёров (hasPartnerFmcRegion),
   * отправляем запрос на получение данных по доступным ШПД-пресетам (запрос уходит в хуке useGetAvailableFmcPresets).
   * 2. Если загрузка пресетов в процессе (isLoadingFmcRequest), показываем пользователю скелетон.
   * 3. Если в процессе загрузки пресетов получили ошибку (hasErrorFmcPresets),
   * показываем компонент повторной отправки запроса.
   * 4. Если загрузка прошла без ошибок, показываем основной компонент ШПД-пресетов */
  return hasFmcRegion || hasPartnerFmcRegion ?
      <WPContainer>
        {isLoadingFmcRequest && <FmcSkeleton />}
        {hasErrorFmcPresets && <FmcLoadingError />}
        {isShowFmcTabs && <FmcTabs {...chipsStatelessFmcProps} />}
        {fmcTabModalProps.data && <FmcTabModal {...fmcTabModalProps} />}
      </WPContainer>
    : null;
};
