export const useMainOptionsData = ({ info, total }) => {
  const getCurrentSubOptions = ({ currentSoc, soc, index }) =>
    info?.tariffOptions?.map((items) => {
      if (!items?.subOptions?.length) {
        return currentSoc === items?.soc ? items?.entityName : null;
      }
      return items?.subOptions?.map((item) =>
        currentSoc === item?.soc ?
          `${item?.entityName
            .replace(/Безлимитн[^\s]+\s/, '')
            .concat(soc.length !== index + 1 ? '' : '')}`
        : null,
      );
    });

  const options = Object.values(total.options)
    .filter((items) => items.checked)
    .reduce((acc, { title, soc }, index) => {
      const currentOptions = soc
        .map((item) => getCurrentSubOptions({ currentSoc: item, title, soc, index }))
        ?.flat(2)
        ?.filter((v) => v);
      return [...acc, ...currentOptions];
    }, []);

  const joinedOptions = options.filter((v, i) => options.indexOf(v) === i).join(', ');

  return { joinedOptions };
};
