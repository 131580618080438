import React, { FC } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames/bind';

import { BonusText, TBonusText } from '../BonusText';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TBonus = {
  icon?: string;
} & TBonusText;

/** Варианты анимации бонусов в чеке ЕК */
const variantsBonus = {
  hidden: {
    height: 0,
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: { duration: 0.2, ease: 'linear', type: 'tween', delay: 0.04 },
  },
};

/** Компонент бонусов, получаемых из пресетов */
export const Bonus: FC<TBonus> = ({ icon, title, subTitle, discount }) => (
  <motion.li
    animate="visible"
    className={cx('wrapper')}
    exit="exit"
    initial="hidden"
    layout
    variants={variantsBonus}
  >
    {icon && <img alt="" className={cx('wrapper__icon')} loading="lazy" src={icon} />}
    <BonusText {...{ title, subTitle, discount }} />
  </motion.li>
);
