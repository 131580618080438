import { RecursivePartial } from 'utils/types';

import { IInitializeResponse, IPollingResponse, TOptions } from './types';
import { BaseServicePoller } from './BaseServicePoller';

/**
 * Класс, который содержит в себе логику, необходимую для общения
 * с сервисом подключения и отключения (изначально для услуги "поддержка при нуле")
 *
 * Этапы:
 *
 * 1. Проверка доступности сервиса (step GetMetdata)
 * 2. Обращение к сервису (step Initiate)
 * 3. Поллинг до ответа, ошибки или таймаута (step Polling)
 */
export class ServicePoller<
  PServiceName extends string,
  PServiceField extends string,
> extends BaseServicePoller<PServiceName, PServiceField> {
  public async execute({
    onSuccess,
    onError,
    options,
  }: {
    onError: (error: Error) => void;
    onSuccess: (
      response:
        | RecursivePartial<IInitializeResponse['view']>
        | RecursivePartial<IPollingResponse['view']>,
    ) => void;
    options?: TOptions;
  }) {
    try {
      const meta = await this.getServiceMetaData();

      const initiationResponse = await this.initiate(meta);

      if (options?.shouldSkipPolling) {
        onSuccess(initiationResponse);
        return;
      }

      const response = await this.pollUntilCompletion(initiationResponse, options);

      onSuccess(response);
    } catch (e) {
      onError(e as Error);
    }
  }
}
