import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Button, Modal } from '@carnica/ui-kit';

import { ModalContent, TModalContent } from '../ModalContent';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TModalContainer = {
  buttonActivateText?: string;
  buttonDeactivateText?: string;
  isOpen?: boolean;
  isTurnedOn?: boolean;
  isUnbreakable: boolean;
  onChange: () => void;
  onClick: () => void;
} & TModalContent;

/** Компонент модального окна опции */
export const ModalContainer = memo<TModalContainer>(
  ({
    isOpen = false,
    isTurnedOn,
    entityName,
    onClick,
    onChange,
    modalWindowDescription,
    pictures = [],
    buttonActivateText = '',
    buttonDeactivateText = '',
    isUnbreakable,
  }) => {
    return (
      <Modal
        className={cx('wrapper')}
        closeOptions={{ escape: true, button: true, overlay: true }}
        isOpen={isOpen}
        onClose={onClick}
        portalClassName={cx('modal-portal')}
      >
        <ModalContent {...{ entityName, modalWindowDescription, pictures }} />
        {!isUnbreakable && (
          <Button
            className={cx('button')}
            onClick={onChange}
            view={isTurnedOn ? 'secondary' : 'primary'}
            width="full"
          >
            {isTurnedOn ? buttonDeactivateText : buttonActivateText}
          </Button>
        )}
      </Modal>
    );
  },
);
