import React, { FC } from 'react';
import classNames from 'classnames/bind';
// Components

import MainPackagePrice from 'pages/ProductCard/Tariff/components/Check/MainPackagePrice';

import { InfoBlock } from './InfoBlock';
// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type MainPackageBlockProps = {
  discount: number;
  gbAndMinutesLongRate: number;
  hideTooltipInShortCycle: boolean;
  info?: Record<string, any>;
  mainPackageTitle: string;
};

const MainPackageBlock: FC<MainPackageBlockProps> = ({
  info,
  discount,
  gbAndMinutesLongRate,
  mainPackageTitle,
  hideTooltipInShortCycle,
}) => (
  <div className={cx('component')}>
    <InfoBlock
      hideTooltipInShortCycle={hideTooltipInShortCycle}
      isNotEnoughMoney={info?.isNotEnoughMoney}
      mainPackageTitle={mainPackageTitle}
      tooltipText={info?.tooltipText}
    />
    <MainPackagePrice discount={discount} gbAndMinutesLongRate={gbAndMinutesLongRate} info={info} />
  </div>
);

export { MainPackageBlock };
