import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SmartInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { maskedPhone as validatePhone } from 'utils/validators';
import { pushSimOrderErrorEvent, pushSimOrderEvent } from 'utils/ga';
import SimOrderBenefits from 'pages-components/SimOrderBenefits/SimOrderBenefits';
import PlainContainer from 'pages-components/PopupContainer/PlainContainer';
import ButtonWithPreloader from 'pages-components/ButtonWithPreloader';
import Captcha from 'pages/Feedback/components/Captcha/Captcha';

import TextContainer from '../TextContainer/TextContainer';
import commonStyles from '../SharingService.pcss';
import styles from './SimPurchaseForm.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const SimPurchaseForm = (props) => {
  // Props
  const {
    simPurchaseDescriptionBefore,
    simPurchaseDescriptionAfter,
    onSubmit,
    simPurchaseUrl: requestUrl,
    callback,
    onClose,
    reservePlace,
    phone,
  } = props;
  // Hooks
  const [contactNumber, changeContactNumber] = useState('+7');
  const [contactNumberValidate, changeContactNumberValidate] = useState({});
  const [step, setStep] = useState('simPurchaseForm');
  const [showCaptcha, toggleShowCaptcha] = useState(false);
  const [captcha, setCaptchaValue] = useState('');
  const [showCaptchaError, toggleShowCaptchaError] = useState(false);
  // Constants
  const captchaKey = 'sim-purchase-form';
  const isButtonDisabled =
    showCaptcha &&
    (contactNumber.length !== 16 || captcha.length !== 5 || contactNumber.length !== 16);

  useEffect(() => {
    if (phone) {
      changeContactNumber(phone);
      changeContactNumberValidate(validatePhone(phone));
    }
  }, []);

  const submit = async () => {
    if (contactNumberValidate.status === 'ok') {
      setStep('pending');
      const {
        nextStep = 'simPurchaseForm',
        isCaptchaError,
        isCaptchaRequired,
        orderId,
        price,
      } = await onSubmit(requestUrl, contactNumber, captcha, captchaKey, reservePlace);

      if (nextStep !== 'simPurchaseForm' && nextStep !== 'pending') {
        const GAFunNamesWithAssociatedSteps = {
          successPopup: pushSimOrderEvent,
          errorPopup: pushSimOrderErrorEvent,
        };

        GAFunNamesWithAssociatedSteps[nextStep]({
          method: 'Number',
          orderId,
          price,
        });

        if (callback) {
          callback();
        }
      }

      setStep(nextStep);
      toggleShowCaptchaError(isCaptchaError);
      toggleShowCaptcha(isCaptchaRequired);

      if (!showCaptchaError) setCaptchaValue('');
    }
  };

  if (step === 'successPopup' || step === 'errorPopup') {
    return (
      <PlainContainer
        {...props[step]}
        description={props[step].description.replace('{{NUMBER}}', contactNumber)}
        onButtonClick={onClose}
      />
    );
  }

  return (
    <>
      {simPurchaseDescriptionBefore ?
        <div
          className={cx('description')}
          dangerouslySetInnerHTML={{ __html: simPurchaseDescriptionBefore }}
        />
      : <TextContainer
          className={cx('description')}
          path={['texts', 'simPurchaseForm', 'simPurchaseDescriptionBefore']}
        />
      }

      <div className={cx('formWrap')}>
        <SmartInput
          className={cx('contactNumberInput')}
          keepInitialValue
          mask="+7 ___ ___ __ __"
          name="phone"
          onBlur={() => changeContactNumberValidate(validatePhone(contactNumber))}
          onChange={(value) => changeContactNumber(value)}
          onFocus={() => changeContactNumberValidate({ status: 'ok' })}
          size="big"
          status={contactNumberValidate.status}
          value={contactNumber}
        />
        <div className={cx('submitContainer', showCaptcha && 'withCaptcha')}>
          {showCaptcha && (
            <div className={cx('captchaWrapper')}>
              <Captcha
                captchaKey={captchaKey}
                disabled={step === 'pending'}
                height={51}
                inputId="captcha"
                msg="Введите символы с картинки"
                name="captcha"
                onChange={(value) => setCaptchaValue(value)}
                onFocus={() => toggleShowCaptchaError(false)}
                refreshCaptcha
                size="big"
                status={showCaptchaError ? 'fail' : 'ok'}
              />
            </div>
          )}
          <ButtonWithPreloader
            buttonText="Оформить заказ"
            className="big"
            disabled={isButtonDisabled}
            isPreloader={step === 'pending'}
            onClick={submit}
            preloaderClassName={cx('buttonPreloader')}
            transformer
            type="button"
          />
        </div>
      </div>

      {simPurchaseDescriptionAfter ?
        <div
          className={cx('description')}
          dangerouslySetInnerHTML={{ __html: simPurchaseDescriptionAfter }}
        />
      : <TextContainer
          className={cx('description')}
          path={['texts', 'simPurchaseForm', 'simPurchaseDescriptionAfter']}
        />
      }

      <SimOrderBenefits benefits="delivery" className={cx('links')} showAgreement />
    </>
  );
};

SimPurchaseForm.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  callback: PropTypes.func,
  simPurchaseUrl: PropTypes.string,
  simPurchaseDescriptionBefore: PropTypes.string,
  simPurchaseDescriptionAfter: PropTypes.string,
  reservePlace: PropTypes.string,
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SimPurchaseForm;
