/* Общие параметры */
const dataLayerBaseParams = {
  name: 'Тариф - Up',
};

/* Расширенные для базовых случаев и мнп */
export const dataLayerParams = {
  common: [
    {
      ...dataLayerBaseParams,
      brand: 'Sim - Tariffs',
      category: 'Тарифы/Тарифы для смартфонов',
    },
    {
      id: 'Номер - Бесплатный',
      name: 'Номер - Бесплатный',
      price: 0.0,
      brand: 'Sim - Numbers',
      category: 'Тарифы/Тарифы для смартфонов',
      variant: 'Бесплатный',
    },
  ],
  mnp: [
    {
      ...dataLayerBaseParams,
      brand: 'Sim - MNP',
      category: 'MNP',
    },
    {
      id: 'mnp',
      name: 'mnp',
      price: 0.0,
      brand: 'Sim - MNP',
      category: 'MNP',
      variant: 'mnp',
    },
  ],
};
