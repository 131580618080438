import React, { FC } from 'react';
import classNames from 'classnames/bind';

import { DevicesTitle } from './components/DevicesTitle';
import { DevicesTabs } from './components/DevicesTabs';
import { DevicesInfo } from './components/DevicesInfo';
import { WPContainer } from '../../../../commonComponents/WPContainer';
import { usePrepareModalContent } from './hooks/usePrepareModalContent';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент контентной части попапа рекомендуемых устройств */
export const ModalContent: FC = () => {
  const { title, devicesTabsProps, activeTabContentProps } = usePrepareModalContent();
  return (
    <section className={cx('wrapper')}>
      <DevicesTitle title={title} />
      <WPContainer>
        <DevicesTabs {...devicesTabsProps} />
      </WPContainer>
      <DevicesInfo {...activeTabContentProps} />
    </section>
  );
};
