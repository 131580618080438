import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { AvatarGroup, Text } from '@carnica/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TCOptionsTile = {
  entityName?: string;
  pictures?: Array<{ image: string }>;
};

/** Компонент заголовка и иконок опции для результирующего чека */
export const COptionsTile: FC<TCOptionsTile> = ({ entityName, pictures }) => (
  <div className={cx('wrapper')}>
    {entityName && (
      <Text align="left" color="secondary" size="xs" weight="medium">
        {entityName}
      </Text>
    )}
    {pictures?.length && (
      <AvatarGroup
        className={cx('wrapper__icons', { test: pictures?.length >= 6 })}
        items={pictures}
        size="s"
        wrap
      />
    )}
  </div>
);
