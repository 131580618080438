import React, { useState } from 'react';
import { emptyObj } from '@beef/utils';
import { PhoneInput } from '@beef/ui-kit';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import { Flex } from 'pages/FTTB2022/ui/common';

import styles from './styles.pcss';
import { ActionButton } from './ActionButton';

const cx = classnames.bind(styles);

const mask = { mask: '_', format: '+7 (###) ###-####' };

export const RenderRequestForm = ({
  data,
  isLoading,
  withActionButton,
  formId = 'callback-form',
}) => {
  const {
    phoneInputValue,
    changePhone,
    texts: { redesignPopupTexts = emptyObj } = emptyObj,
    onBackCallBtnClick,
  } = data;

  const [inputError, setInputError] = useState('');

  const validateCtn = () => {
    if (phoneInputValue.length !== 10) {
      setInputError(redesignPopupTexts?.wrongPhone);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateCtn();
    onBackCallBtnClick?.();
  };

  const handleChangePhone = (e) => {
    setInputError('');
    /*
     * в api PhoneInput есть баг, который позволяет вводить номер с +0,
     * для корректной работы валидации +0 добавлена в регулярку
     * */
    changePhone(e.target.value.replace(/\+7|\+0|\D/g, ''));
  };

  return (
    <form className={cx({ withActionButton })} id={formId} onSubmit={handleSubmit}>
      <Flex alignItems="center" className={cx('phoneInputWrapper')}>
        <div>
          <div className={cx('phoneInput')}>
            <PhoneInput
              autoComplete="off"
              errorMessage={inputError}
              maskSettings={mask}
              name="phone"
              onBlur={validateCtn}
              onChange={handleChangePhone}
              placeholder="+7 000 000-00-00"
              type="tel"
              value={phoneInputValue}
            />
          </div>
        </div>
        {withActionButton && (
          <div className={cx('actionButton')}>
            <ActionButton
              buttonText={redesignPopupTexts?.backCallBtnText}
              formId={formId}
              isLoading={isLoading}
              tooltipText={redesignPopupTexts?.termsOfUse}
            />
          </div>
        )}
      </Flex>
    </form>
  );
};

RenderRequestForm.propTypes = {
  data: PropTypes.shape({
    onPhoneFocus: PropTypes.func,
    onBackCallBtnClick: PropTypes.func,

    phoneInputValue: PropTypes.string,
    changePhone: PropTypes.func,
    showCaptcha: PropTypes.bool,
    captchaToken: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    reCaptchaModel: PropTypes.object,
    phoneInputStatus: PropTypes.string,

    texts: PropTypes.shape({
      redesignPopupTexts: PropTypes.shape({
        phoneLabel: PropTypes.string,
        wrongPhone: PropTypes.string,
        phoneInputDescription: PropTypes.string,
        backCallBtnText: PropTypes.string,
        userAgreementText: PropTypes.string,
        termsOfUse: PropTypes.string,
      }),
    }),
  }),
  isLoading: PropTypes.bool,
  withActionButton: PropTypes.bool,
  formId: PropTypes.string,
};
