/**
 * Форматирует номер телефона в формате +7 969 350 08 28
 */
export const formatPhoneNumber = (value?: string) => {
  // Если значение не передано, возвращаем пустую строку
  if (!value) {
    return '';
  }

  // Удаляем все символы, кроме цифр
  let phoneNumber = value.replace(/\D/g, '');

  // Если номер начинается с '7', удаляем первый символ
  if (phoneNumber.startsWith('7')) {
    phoneNumber = phoneNumber.slice(1);
  }

  // Форматируем номер, добавляя пробелы
  return `+7 ${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(
    6,
    8,
  )} ${phoneNumber.slice(8, 10)}`.trim();
};
