import { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';

// Components
import Ctx from 'pages-components/Ctx';

// Utils
import { getMobileOS } from 'utils/detect-mobile';

class TariffsGridHoc extends Component {
  state = {
    isShowButton: true,
    bannerImage: null,
    allTariffVisible: 'hidden',
  };

  componentDidMount() {
    const { data } = this.props;
    // Определение девайса для отрисовки картинки телефона в банере каталога
    let bannerImage = null;
    if (data.appBanner) {
      bannerImage = getMobileOS() === 'ios' ? data.appBanner.imageIos : data.appBanner.imageAndroid;
    }

    this.setState({
      bannerImage,
    });
  }

  handlerShowAllTariffs = () =>
    this.setState((prevState) => ({
      isShowButton: !prevState.isShowButton,
      allTariffVisible: 'visible',
    }));

  /**
   * Фильтрует массыв по указанным параметрам;
   * @param array - Список для фильтрации;
   * @param filterParam - Параметр фильтрации;
   */
  filter = (array, filterParam) => array.filter((item) => item.type === filterParam);

  render() {
    const { bannerImage, isShowButton, allTariffVisible } = this.state;
    const {
      children,
      data,
      tariffs,
      paramsGridView,
      data: { archiveLink, appBanner },
    } = this.props;

    // Фильтруем тарифы от family-restyled тарифов;
    const tariffsArray = this.filter(tariffs, 'tariff');

    return cloneElement(children, {
      archiveLinkName: Ctx.tariffsCatalog.archiveLink,
      title: Ctx.tariffsCatalog.headingMore,
      archiveLink,
      tariffs: tariffsArray,
      isShowButton,
      allTariffVisible,
      paramsGridView,
      handlerShowAllTariffs: this.handlerShowAllTariffs,
      allTariffsButtonName: Ctx.tariffsCatalog.allTariffs,
      appBanner:
        appBanner ?
          {
            ...appBanner,
            bannerImage: bannerImage || data.appBanner.imageAndroid,
            appButtons: {
              ios: {
                link: appBanner?.appButtons?.ios?.link,
                image: appBanner?.appButtons?.ios?.image,
              },
              android: {
                link: appBanner?.appButtons?.android?.link,
                image: appBanner?.appButtons?.android?.image,
              },
            },
            smsFormData: appBanner.smsFormData || null,
            downloadButton: appBanner.downloadButton || null,
          }
        : null,
    });
  }
}

TariffsGridHoc.defaultProps = {
  paramsGridView: false,
};

TariffsGridHoc.propTypes = {
  paramsGridView: PropTypes.bool,
  children: PropTypes.object,
  tariffs: PropTypes.array,
  appBanner: PropTypes.object,
  data: PropTypes.shape({
    archiveLink: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    imageIos: PropTypes.string,
    imageAndroid: PropTypes.string,
    appButtons: PropTypes.shape({
      ios: PropTypes.shape({
        link: PropTypes.string,
        image: PropTypes.string,
      }),
      android: PropTypes.shape({
        link: PropTypes.string,
        image: PropTypes.string,
      }),
    }),
  }),
};

export default TariffsGridHoc;
