import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);
/* eslint-disable react/button-has-type */

const EmptyButton = ({ children, className, ...props }) => (
  <button className={cx('button', className)} {...props}>
    {children}
  </button>
);

export default EmptyButton;
