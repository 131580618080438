import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { Button, ReloadIcon, Text } from '@beef/ui-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';

import { setNextRandomNumber } from '../../../store/actions/numbers';
import { getRandomContent } from '../../../store/selectors/content';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

const HeadingLayout = ({ onButtonClick, title }) => {
  const isMobile = useIsMobileDimension();

  return (
    <div className={cx('heading')}>
      <Text size="size4-m">{title}</Text>
      <Button onClick={onButtonClick} variant="plain">
        <ReloadIcon size={isMobile ? 20 : 24} />
      </Button>
    </div>
  );
};

HeadingLayout.propTypes = {
  onButtonClick: PropTypes.func,
  title: PropTypes.string,
};

const mapStateToProps = (state) => ({
  title: getRandomContent(state).title,
});

const mapDispatchToProps = {
  onButtonClick: setNextRandomNumber,
};

export const Heading = connect(mapStateToProps, mapDispatchToProps)(HeadingLayout);
