import { useMemo } from 'react';
import { shallowEqual } from '@beef/utils';

import { useAppSelector } from '../../../../store';
import { useIsHighPreset } from '../../../../hooks/useIsHighPreset';
import { getAnimalsTabs, getChosenCharacter, getDefaultCharacter } from '../utils';

/** Селектор выборки данных тарифов для TariffInfoTiles.
 * Возвращает объект дефолтного персонажа, его soc и табы доступных тарифов */
export const useSelectInfoTile = (soc: string) => {
  const isHighPreset = useIsHighPreset();

  const { tariffsInfo, characterSoc } = useAppSelector(
    (state) => ({
      tariffsInfo: state.tariffsInfo,
      characterSoc: state.total.characterSoc,
    }),
    shallowEqual,
  );

  /* Default-персонаж.
   * Определяется наличием свойства isActive/isDefault в значении true (в объекте персонажа) */
  const defaultCharacter = getDefaultCharacter(tariffsInfo);

  /* Текущий default soc персонажа */
  const defaultCharacterSoc =
    isHighPreset ? defaultCharacter?.highOptionSoc : defaultCharacter?.optionSoc;

  /* Выборка доступных к показу табов персонажей. Используется в попапе выбора зверушки */
  const availableUppersTabs = getAnimalsTabs(tariffsInfo);

  /* Объект текущего выбранного персонажа */
  const chosenCharacter = useMemo(
    () => getChosenCharacter(tariffsInfo, characterSoc, isHighPreset),
    [characterSoc],
  );

  /* Объект текущего просматриваемого персонажа */
  const preViewCharacter = useMemo(() => getChosenCharacter(tariffsInfo, soc, isHighPreset), [soc]);

  return {
    chosenCharacter,
    preViewCharacter,
    defaultCharacter,
    defaultCharacterSoc,
    availableUppersTabs,
  };
};
