import React from 'react';
import { compose, withReduxStore } from '@beef/redux';
import { connect } from 'react-redux';
import { LandingConstructor } from '@beef/landing-constructor';
import { withDataConverter } from '@beef/react';

import { finalDataConverter } from '../store/dataConverters';
import { initializeFinalLanding } from '../store';
import { selectContent } from '../store/selectors';
import { selectCurrentFinalStory, selectCurrentFinalVideo } from '../store/finalStories';
import { StoryExamples } from '../components/StoryExamples';
import { VideoCards } from '../components/VideoCards';
import { StoryExampleGroups } from '../components/StoryExamples/StoryExampleGroups';
import { RatingFinal } from '../components/Rating/RatingFinal';
import { FinalStoriesList } from '../components/FinalStoriesList';
import { FinalStoryModal } from '../components/FinalStoriesList/FinalStoryModal';
import { FinalVideoModal } from '../components/FinalStoriesList/FinalVideoModal';
import { StatusModal } from '../components/StatusModal';

const customComponents = {
  StoryExampleGroups,
  StoryExamples,
  VideoCards,
  FinalStoriesList,
  RatingFinal,
};

const StoriesFinalLandingLayout = ({ constructorBlocks, showStoryModal, showVideoModal }) => (
  <>
    <LandingConstructor blocks={constructorBlocks} customComponents={customComponents} />
    {showStoryModal && <FinalStoryModal />}
    {showVideoModal && <FinalVideoModal />}
    <StatusModal />
  </>
);

const mapStateToProps = (state) => ({
  constructorBlocks: selectContent(state).blocks,
  showVideoModal: !!selectCurrentFinalVideo(state),
  showStoryModal: !!selectCurrentFinalStory(state),
});

export const StoriesFinalLanding = compose(
  withDataConverter(finalDataConverter),
  withReduxStore(initializeFinalLanding),
  connect(mapStateToProps),
)(StoriesFinalLandingLayout);
