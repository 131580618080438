import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import CheckAddress from 'pages/FTTB/CatalogPage/components/CheckAddress';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const HeadBanner = ({ data, callBackRequestData }) => {
  const { checkAddressData, forceShowBackCallPopup, isFMC } = data;
  return (
    <div className={cx('head-banner--shifted')}>
      <CheckAddress
        backCallPopupData={callBackRequestData}
        data={checkAddressData}
        forceShowBackCallPopup={forceShowBackCallPopup}
        isFMC={isFMC}
        isRedesign
      />
    </div>
  );
};

HeadBanner.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    checkAddressData: PropTypes.object,
    forceShowBackCallPopup: PropTypes.bool,
    isFMC: PropTypes.bool,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  callBackRequestData: PropTypes.object,
};
