import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { Bubble, Heading, Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import store from 'store';

// Components
import Icon from 'pages-components/Icon';

// Actions

import { openTariffDescriptionPopup } from '../../actions/tariffConnect';

// Styles
import TariffDescriptionPopup from './TariffDescriptionPopup/TariffDescriptionPopup';
import styles from './HeadBanner.pcss';

const cx = classNames.bind(styles);

const mapDispatchToProps = {
  openTariffDescriptionPopup,
};

class HeadBanner extends Component {
  render() {
    const { productTypeTitle, pageTitleBubble, mobileImage, reverse, image, title } = this.props;

    const { icon, text, extendedDescription, extendedDescriptionHeader } = pageTitleBubble;

    return (
      <div className={cx('pageHeader')}>
        <div
          className={cx('headImage', 'mobile')}
          style={{
            backgroundImage: `url('${mobileImage}')`,
          }}
        />
        <div
          className={cx('headImage', 'desktop')}
          style={{
            backgroundImage: `url('${image}')`,
          }}
        />
        <div className={cx(reverse && 'reverse', 'headWrapper')}>
          <Bubble className={['bottomLeft', cx('pageBubbleContent')]}>
            <Icon className={cx('icon')} name={icon} />
            <span className={cx('bubbleText')}>{text}</span>
            {extendedDescription && extendedDescriptionHeader && (
              <Link onClick={() => this.props.openTariffDescriptionPopup(true)}>
                {extendedDescriptionHeader}
              </Link>
            )}
          </Bubble>
          <div className={cx('headTitle')}>
            <Heading className={cx('title')} level={1}>
              {productTypeTitle && (
                <span className={cx('productTypeTitle')}>{productTypeTitle}</span>
              )}
              {title}
            </Heading>
          </div>
        </div>
        <TariffDescriptionPopup popupContent={extendedDescription} />
      </div>
    );
  }
}

HeadBanner.propTypes = {
  productTypeTitle: PropTypes.string,
  pageTitleBubble: PropTypes.object,
  mobileImage: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  reverse: PropTypes.bool,

  openTariffDescriptionPopup: PropTypes.func,
};

const HeadBannerComponent = connect(null, mapDispatchToProps)(HeadBanner);

export default (props) => (
  <Provider store={store}>
    <HeadBannerComponent {...props} />
  </Provider>
);
