import { MutableRefObject } from 'react';
import { shallowEqual } from 'react-redux';

import { ymPushEvent } from 'utils/ym';
import { convergentToConnectUpFormYM } from 'utils/analytics/tariffsYandexMetrica';

import { useSelectInfoTile } from '../../components/constructorPage/TariffInfoTiles/selectors/useSelectInfoTile';
import { useOnScreenAnalytics } from './useOnScreenAnalytics';
import { TOptionsListItem } from '../../store/slices/optionsSlice/types';
import { connectMethodTypes, pushYm } from '../metricUtils';
import { useAppSelector } from '../../store';
import { DEFAULT_TAB_TEXT } from '../../components/constructorPage/FmcTabsContainer/constants';

type TUsePrepareTotalYm = {
  ref?: MutableRefObject<HTMLDivElement | null>;
  type: 'bill' | 'error' | 'totalBill';
};

/** Подготавливает данные для отправки метрики с данными total
 * (паект минут гб, опции, шаг, скорость интернета + дополнительные параметры) */
export const usePrepareTotalYm = ({ type, ref }: TUsePrepareTotalYm) => {
  const { optionsSoc, gbValue, minValue, currentTariffTab, netSpeed } =
    useAppSelector(
      (state) => ({
        optionsSoc: state.total.optionsSoc,
        gbValue: state.total.gbValue,
        minValue: state.total.minValue,
        currentTariffTab: state.total.currentTariffTab,
        netSpeed: state.total.convergenceInfo.netSpeed,
      }),
      shallowEqual,
    ) || {};
  const options = useAppSelector((state) => state.options);
  const { chosenCharacter } = useSelectInfoTile('');
  const allOptionsList = options.reduce(
    (acc, item) => [...acc, ...item.optionsList, ...(item.parentOptionsList ?? [])],
    [] as TOptionsListItem[],
  );
  const optionsNamesList = allOptionsList
    .filter((item) => optionsSoc.includes(item.soc))
    .map((item) => item.title);

  /* Справочник источников события и их наименований в метрике */
  const actionType = {
    totalBill: 'megacheck',
    bill: 'go_to_connect',
    error: 'reload',
  } as const;

  /* Метод подключения (перевод) */
  const name = connectMethodTypes[currentTariffTab];

  /* Базовые параметры */
  const params = {
    net_package: gbValue,
    call_package: minValue,
    option_name: optionsNamesList,
    upper_name: chosenCharacter?.name,
  };

  /* Проверяем что выбранный таб блока "Домашний интернет и ТВ" содержит net_speed
   * если shouldAddNetSpeedToParams = true, включаем net_speed в параметры аналитики */
  const shouldAddNetSpeedToParams = netSpeed && netSpeed !== DEFAULT_TAB_TEXT;

  /* Отправляем аниалитику при видимости мегачека  */
  useOnScreenAnalytics({
    ref,
    payload: {
      ...params,
      megacheck: name,
      ...(shouldAddNetSpeedToParams && { net_speed: netSpeed }),
    },
    event: `megacheck_${name}`,
  });

  /* Если мегачек, то добавляет ключ connect в params. Eсли кнопка bill, то
  ключ go_to_connect. если error то reload. Значениями всегда из connectMethodTypes */
  const extParams = {
    ...params,
    ...{ [actionType[type]]: name, ...(shouldAddNetSpeedToParams && { net_speed: netSpeed }) },
  };

  /** Функция отправки метрики по клику на копку */
  return () => {
    if (actionType[type] !== actionType.totalBill) {
      /* Передаём аналитику при клике на кнопку если actionType не мегачек */
      pushYm(extParams);

      /* Передаём цель при клике "Далее" при условии выбора интернет тарифа */
      if (shouldAddNetSpeedToParams) {
        convergentToConnectUpFormYM();
      }
    } else {
      /* Передаём аналитику при клике на кнопку если actionType мегачек */
      pushYm({
        ...params,
        connect: name,
        ...(shouldAddNetSpeedToParams && { net_speed: netSpeed }),
      });
      /* Цель на клик по кнопке "Оплатить"/"Заказать" на странице "Мегачек" */
      ymPushEvent(`connect_${name}`);
    }
    /* Отправляем PushEvent по клику на bill */
    if (type === 'bill') {
      ymPushEvent('MobTariffDetailConnect', { URL9: document.location.href });
    }
  };
};
