import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { pathOr } from 'ramda';
import classNames from 'classnames/bind';

import store from 'store';

import styles from './style.pcss';
import { initBlackList, setOptions } from '../../actions/blackList';
import BlackListParameters from './BlackListParameters';
import BlackListPopup from './BlackListPopup';
import BlackListRecentCalls from './BlackListRecentCalls/BlackListRecentCalls';
import ServiceStatusPopup from '../ServiceStatusPopup/ServiceStatusPopup';

const cx = classNames.bind(styles);

class BlackListServiceExtension extends Component {
  componentDidMount() {
    const serverNumbers = pathOr([], ['data', 'extData', 'blackNumbers'], this.props);
    const maxNumbers = pathOr(40, ['data', 'extData', 'maxNumbers'], this.props);
    store.dispatch(initBlackList(serverNumbers));
    store.dispatch(setOptions({ maxNumbers }));
  }

  render() {
    const { data, className } = this.props;
    const { errorPopup } = data;
    return (
      <Provider store={store}>
        <div className={className}>
          <BlackListParameters data={data} />
          <BlackListPopup />
          <ServiceStatusPopup
            errorContent={errorPopup}
            id="recentCalls"
            loaderClassName={cx('loading')}
            successElement={<BlackListRecentCalls store={store} />}
          />
        </div>
      </Provider>
    );
  }
}

BlackListServiceExtension.propTypes = {
  data: PropTypes.object,
};

export default BlackListServiceExtension;

export { default as BlackListConnection } from './BlackListConnection';
