import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, SmartForm, SmartInput } from '@beef/legacy-ui-kit';
import { Button, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { notEmpty } from 'utils/validators';
import { unformatPhone } from 'utils/format-string';
import { pushSmsFormEvent } from 'utils/analytics/tariffCard';
import { AUTH_YM_EVENTS, SMS_CODE_LENGTH, YMAuthEvent } from 'utils/analytics/ymCommonEvents';
import { handleRoamingYm, roamingYmEventsTypes } from 'pages/ProductCard/utils';

import HiddenInput from '../HiddenInput/HiddenInput';
import styles from './SmsLoginForm.pcss';

const cx = classNames.bind(styles);

class SmsForm extends Component {
  constructor(props) {
    super(props);

    this.state = { codeValue: '' };
  }

  handleSubmit = (_, event) => {
    this.props?.onSubmit();
    event.target.submit();
    const { ymType } = this.props;
    if (ymType) {
      handleRoamingYm({
        type: ymType,
        actionType: roamingYmEventsTypes?.calculationPhone,
        value: 'phone_confirm',
      });
    }
  };

  handleChange = (value) => {
    if (value.length === SMS_CODE_LENGTH) {
      YMAuthEvent.fillSmsInput(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS);
    }
    this.setState({ codeValue: value });

    if (value.length === 5 && !this.gaPushed) {
      this.gaPushed = true;
      pushSmsFormEvent('smsCodeSubmit');
    }
  };

  render() {
    const {
      disabled,
      phone,
      postUrl,
      clientId,
      returnUrl,
      state,
      nonce,
      redirectUrl,
      smsWasSentText,
      sendSmsAgain,
      sendSmsAgainText,
      confirmButtonText,
      notice,
      hidePhone,
    } = this.props;

    if (!postUrl || !phone) {
      return null;
    }

    return (
      <SmartForm
        action={postUrl}
        autoComplete="off"
        className={cx('smsForm')}
        method="post"
        onSubmit={this.handleSubmit}
        validator={{ password: notEmpty }}
      >
        <HiddenInput
          clientId={clientId}
          isSms
          nonce={nonce}
          redirectUrl={redirectUrl}
          returnUrl={returnUrl}
          state={state}
        />
        <input name="login" type="hidden" value={unformatPhone(phone)} />
        {!hidePhone && <div className={cx('phone')}>{phone.replace('(', '').replace(')', '')}</div>}

        {smsWasSentText || notice ?
          <div className={cx('description')}>
            <Text size="size6-r">
              <span dangerouslySetInnerHTML={{ __html: smsWasSentText }} />
              <span dangerouslySetInnerHTML={{ __html: notice }} />
            </Text>
          </div>
        : null}

        <div className={cx('sms', 'sms--align-top')}>
          <div className={cx('smsItem')}>
            <div className={cx('codeField')}>
              <SmartInput
                className={cx('field')}
                filterInput={(value) => value.replace(/\D/g, '')}
                inputId="password"
                maxLength={5}
                name="password"
                onChange={this.handleChange}
                onClick={() => YMAuthEvent.clickPasswordInput(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS)}
                size="big"
                value=""
              />
              {sendSmsAgain && (
                <Link
                  className={cx('sendSmsLink')}
                  onClick={() => {
                    sendSmsAgain?.();
                    YMAuthEvent.repeatSms(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS);
                  }}
                >
                  {sendSmsAgainText}
                </Link>
              )}
            </div>
          </div>
          <div className={cx('smsItem', 'buttonSms')}>
            <Button
              disabled={disabled || !this.state.codeValue || this.state.codeValue.length !== 5}
              fullWidth
              onClick={() => YMAuthEvent.clickAuthButton(AUTH_YM_EVENTS.CHANGE_TARIFF_SMS)}
              size="l"
              type="submit"
            >
              {confirmButtonText}
            </Button>
          </div>
        </div>
      </SmartForm>
    );
  }
}

SmsForm.propTypes = {
  phone: PropTypes.string,
  postUrl: PropTypes.string,
  clientId: PropTypes.string,
  returnUrl: PropTypes.string,
  state: PropTypes.string,
  nonce: PropTypes.string,
  redirectUrl: PropTypes.string,
  smsWasSentText: PropTypes.string,
  sendSmsAgain: PropTypes.func,
  sendSmsAgainText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  notice: PropTypes.string,
  hidePhone: PropTypes.bool,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default SmsForm;
