import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import type { FontSizeType } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export interface IClickableTextProps {
  clickableText: string;
  onClick?: () => void;
  textAfter?: string;
  textBefore?: string;
  textSize?: FontSizeType;
}

export const ClickableText: React.FC<IClickableTextProps> = ({
  textBefore,
  textAfter,
  clickableText,
  onClick,
  textSize = 'size7-r',
}) => (
  <>
    {textBefore && (
      <>
        <Text extraAttributes={{ style: { display: 'inline' } }} size={textSize}>
          {textBefore}
        </Text>{' '}
      </>
    )}

    <Text extraAttributes={{ style: { display: 'inline' } }} size={textSize}>
      <button className={cx('button')} onClick={onClick} type="button">
        {clickableText}
      </button>
    </Text>

    {textAfter && (
      <>
        {' '}
        <Text extraAttributes={{ style: { display: 'inline' } }} size={textSize}>
          {textAfter}
        </Text>
      </>
    )}
  </>
);
