import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, pathOr, prop, propEq } from 'ramda';
import classNames from 'classnames/bind';

import { optionsPopup } from 'pages/ProductCard/actions/redirect';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

class RedirectCustomParameter extends PureComponent {
  renderMultipleSettingPreview(activeOption) {
    const options = ['noAnswer', 'notAvailable', 'busy'].reduce((result, value) => {
      const option = activeOption[value];
      if (option.active) {
        if (result.length) {
          result.push(', ');
        }
        result.push(option.text);
      }
      return result;
    }, []);
    return <span className={cx('link')}>{options.join('')}</span>;
  }

  render() {
    const { options, openPopup } = this.props;
    const activeOption = find(propEq('active', true), options);
    const name = prop('name', activeOption);
    let SettingsElement;
    switch (name) {
      case 'oneNumber':
        SettingsElement = (
          <span className={cx('link')}>
            на <span className={cx('single')}>{activeOption.phone}</span>
          </span>
        );
        break;

      case 'multipleOptions':
        SettingsElement = this.renderMultipleSettingPreview(activeOption);
        break;

      default:
        SettingsElement = <span className={cx('link')}>не настроена</span>;
    }
    return (
      <tr className={cx('phoneRow')} key="redirect">
        <td className={cx('title')}>Переадресация</td>
        <td className={cx('phoneList')}>
          <div
            className={cx('serviceSettings')}
            onClick={() => openPopup({ opened: true })}
            role="button"
            tabIndex="0"
          >
            {SettingsElement}
          </div>
        </td>
      </tr>
    );
  }
}

RedirectCustomParameter.propTypes = {
  options: PropTypes.array,
  openPopup: PropTypes.func,
};

const mapStateToProps = ({ external }) => {
  const options = pathOr([], ['redirect', 'serviceStatus', 'options'], external);
  return {
    options,
  };
};

const mapDispatchToProps = {
  openPopup: optionsPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectCustomParameter);
