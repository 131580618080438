import React from 'react';
import { BehaviorSubject } from 'rxjs';

import { useSharedState } from 'utils/hooks/useSharedState';

/**
 * HOC позволяющий использовать RxJS для менеджмента сосотояния
 */
export function withObservableState<P extends Record<string, unknown>>(
  observable: BehaviorSubject<P>,
) {
  return (Component: React.FC<P>) => {
    const EnhancedComponent: React.FC<P> = (props) => {
      const [state] = useSharedState(observable);
      return <Component {...props} {...state} />;
    };
    return EnhancedComponent;
  };
}
