import React, { FC } from 'react';
import classNames from 'classnames/bind';

// TODO: Не забыть про импорт при выносе в отдельный пакет!
import { TRecommendations } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/tempComponents/PresetPicker/types';

import { RecommendationsItem } from '../RecommendationsItem';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TRecommendationsList = {
  recommendations: TRecommendations[];
};

/** Список рекомендуемых устройств для выбранного пакета Гб  */
export const RecommendationsList: FC<TRecommendationsList> = ({ recommendations }) => (
  <ul className={cx('wrapper')}>
    {recommendations?.map((recommendation, idx) => (
      <RecommendationsItem key={idx} {...recommendation} />
    ))}
  </ul>
);
