import { cloneElement } from 'react';
import { connect } from 'react-redux';

import { DeviceAnalyticsPusher } from 'pages/SearchResultsPage/utils/analytics';
import { useOnScreenEffect } from 'utils/hooks/useOnScreenEffect';
import { getPlaceForAnalytics } from 'pages/SearchResultsPage/store/selectors';

const _DeviceWithAnalytics = ({ children, location }) => {
  DeviceAnalyticsPusher.location = location;
  const name = children.props.deviceData?.name;
  const id = children.props.deviceData?.id;
  const nodeRef = useOnScreenEffect(() => {
    DeviceAnalyticsPusher.pushViewEvent({ [name]: id });
  }, []);

  const handleClick = (e) => {
    children.props.onClick?.(e);
    DeviceAnalyticsPusher.pushClickEvent({ [name]: id });
  };

  return cloneElement(children, { onClick: handleClick, nodeRef });
};

export const DeviceWithAnalytics = connect((state) => ({ location: getPlaceForAnalytics(state) }))(
  _DeviceWithAnalytics,
);
