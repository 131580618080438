import { useState } from 'react';

import { useSelectPassivePopups } from '../selectors/useSelectPassivePopups';

/** Подготовка данных для модального окна Passive */
export const usePrepareModalContent = () => {
  const [isOpen, setIsOpen] = useState(false);

  /* Контент попапа */
  const passivePopups = useSelectPassivePopups();

  /* Достаем название кнопки для попап с информацией по пассивкам */
  const btnText = (passivePopups?.btnText as string) || '';

  /* Функция открытия модального окна для компонента ModalActionBar */
  const openModal = () => setIsOpen(true);

  /* Функция закрытия модального окна */
  const onClose = () => setIsOpen(false);

  /* Пропы для китового компонента Modal */
  const modalProps = { isOpen, onClose };

  /* Пропы для компонента ModalActionBar */
  const modalActionBarProps = { onClick: onClose, btnText };

  /* Общий проп для кмпонента  ModalContainer */
  const modalContainerProps = { modalProps, modalActionBarProps };

  return { openModal, modalContainerProps };
};
