import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatOrderData } from '@beef/layout-kit/utils';

import globalStore from 'store';

export type TBasketQueryReq = ReturnType<typeof formatOrderData>;

export type TBasketQueryRes = Record<string, any>;

export const fetchBasketQuery = createAsyncThunk<TBasketQueryRes, TBasketQueryReq>(
  'presetApi/fetchBasketQuery',
  async (params, { signal, rejectWithValue }) => {
    const { cartApi } = globalStore.getState().external.cartData;
    try {
      return (await cartApi.post(params.apiEndpoint, params.body, { signal })).data;
    } catch (e) {
      rejectWithValue({});
      return {};
    }
  },
);
