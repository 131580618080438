import { createSlice } from '@reduxjs/toolkit';

import { prepareOptionsForPassiveAbility } from '../../../utils/prepareOptionsForPassiveAbility';
import { TOptionsSlice, TOptionsSliceReducer } from './types';
import { setCharacterSoc } from '../totalSlice';

const initialState: TOptionsSlice = [];

/** Slice для опций тарифа */
export const optionsSlice = createSlice<TOptionsSlice, TOptionsSliceReducer>({
  name: 'options',
  initialState,
  reducers: {
    toggleOptionActivity: (state, action) => {
      const targetSoc = action.payload;
      /* Пробегаем по всем группам опций */
      state.forEach((optionGroup) => {
        /* Если targetSoc – родительская опция, обходим их на наличии искомой опции по soc */
        if (optionGroup.parentOptionsList?.some((opt) => opt.soc === targetSoc)) {
          const parentOption = optionGroup.parentOptionsList?.find((opt) => opt.soc === targetSoc);
          if (!parentOption) {
            return;
          }
          /* Новое значение для isActive */
          const newOptionActivity = !parentOption.isActive;
          // /**/ мутируем значения родительской опции
          parentOption.isActive = newOptionActivity;
          /* Помимо родительской опции меняем значения и всех дочерних (если есть) на такое же,
           * если опция не заблокированная */
          optionGroup.optionsList?.forEach((option) => {
            if (option.parentSoc === targetSoc && !option.isBlocked) {
              option.isActive = newOptionActivity;
            }
          });
          /* Завершаем обход, если soc принадлежит родительской группе */
          return;
        }

        /* Обходим дочерние опции */
        optionGroup.optionsList?.forEach((option) => {
          if (option.soc === targetSoc) {
            /* Новое значение для isActive */
            const newOptionActivity = !option.isActive;
            /* Инвертируем значение дочерней опции */
            option.isActive = newOptionActivity;
            /* Если новое значение false и есть родительская опция – отключаем родительскую */
            if (!newOptionActivity && option.parentSoc) {
              const parentOption = optionGroup.parentOptionsList?.find(
                (opt) => opt.soc === option.parentSoc,
              );
              if (parentOption) {
                parentOption.isActive = false;
              }
            }
            /* Если новое значение true и есть родительская опция – проверяем,
             все ли дочерние опции активированы если да, то активируем родительскую опцию */
            if (newOptionActivity && option.parentSoc) {
              const isAllChildActive = optionGroup.optionsList
                .filter((opt) => opt.parentSoc === option.parentSoc)
                .every((opt) => opt.isActive);
              if (isAllChildActive) {
                const parentOption = optionGroup.parentOptionsList?.find(
                  (opt) => opt.soc === option.parentSoc,
                );
                if (parentOption) {
                  parentOption.isActive = true;
                }
              }
            }
          }
        });
      });
    },
    setOptions: (_, action) => action.payload,
    /* Установка данных в optionsSlice из кеша (persisted) */
    setPersistedOptions: (_, action) => action.payload,
  },
  extraReducers: (builder) =>
    builder
      /* Добавляем эффект на изменение сока персонажа, при котором подготавливаем опции */
      .addCase(setCharacterSoc, (state, action) =>
        prepareOptionsForPassiveAbility(state, action.payload),
      ),
});

export const { toggleOptionActivity, setPersistedOptions, setOptions } = optionsSlice.actions;

export const optionsReducer = optionsSlice.reducer;
