// Складывает параметры в одну строку;
import { ctnMaskBeautifier } from 'utils/format-string';

/** Стандартная функция для сложение строки c value */
const joinValueUnit = (param) =>
  `${param.value ? param.value : ''} ${param.unit ? param.unit : ''}`;

/** Определяем стартовый шаг отрисовки модального окна; */
// 1) Первое подключение номера в расчетном периоде:
// параметра freeAvailableChangeCount >= 0, и не вернул массив подключенных номеров favoriteNumbers

// 2) Изменение любимого номера. Платная замена:
// параметра freeAvailableChangeCount = 0, и вернул массив подключенных номеров favoriteNumbers

// 3) Изменение любимого номера. Платная замена. Недостаточно средств на счете:
// параметра freeAvailableChangeCount = 0,
// вернул массив подключенных номеров favoriteNumbers и вернулась сущность wantage

// 4) Изменение любимого номера. Бесплатная замена:
// параметра freeAvailableChangeCount > 0, и вернул массив подключенных номеров favoriteNumbers
const getStep = (data) => {
  const { wantage, freeAvailableChangeCount, favoriteNumbers } = data;

  let step = null;

  if (freeAvailableChangeCount > 0 && !favoriteNumbers.length) {
    step = 'connect';
  } else if (freeAvailableChangeCount === 0 && !favoriteNumbers.length) {
    step = 'reConnect';
  } else if (freeAvailableChangeCount === 0 && favoriteNumbers.length && wantage) {
    step = 'notAllowed';
  } else if (freeAvailableChangeCount === 0 && favoriteNumbers.length) {
    step = 'change';
  } else if (freeAvailableChangeCount > 0 && favoriteNumbers) {
    step = 'changeFree';
  }

  return step;
};

/** Подготавливает данные для отрисовки параметров услуги на странице; */
export const getTilesParams = (data, modalData) => {
  const { productParameters } = data;
  const { monthlyFee, changePrice } = modalData;
  const valueObj = {
    SubscriptionFee: monthlyFee,
    Price: changePrice,
    ChangePrice: changePrice,
  };

  // Подготавливаем массив с параметрами. Необходимо заметить
  // value параметров страницы на данные которые пришли
  // в результате запроса на бэк;
  return productParameters.reduce((arr, productParam) => {
    const paramValueByKey = valueObj[productParam.key];
    const paramValue = paramValueByKey ? `${paramValueByKey} ₽` : joinValueUnit(productParam);

    arr.push({
      description: productParam.label,
      valueTooltip: productParam.legal,
      value: paramValue,
    });
    return arr;
  }, []);
};

/** Подготавливаем обьект который в результате будет записан в state */
export const getFavoriteNumberState = (data) => {
  const { content, favoriteNumbers } = data;

  // Если есть подключенный "Любимый номер" складываем
  // его в объект для отрисовки в контенте;
  let favoriteNumberObj = null;
  if (favoriteNumbers && favoriteNumbers.length) {
    favoriteNumberObj = {
      value: ctnMaskBeautifier(favoriteNumbers[0]),
      description: data.content.favoriteNumber,
    };
  }

  return {
    // Контент модального окна
    modalData: {
      ...data,
      step: getStep(data),
    },
    pageSubTitle: content.pageSubtitle,
    // Контент страницы услуги
    favoriteNumber: favoriteNumberObj,
    favoriteNumberContent: {
      changeButtonText: content.changeButtonText,
      addButtonText: content.addButtonText,
    },
  };
};
