import React from 'react';
import PropTypes from 'prop-types';

const sizes = {
  single: { width: 40, height: 40 },
  white: { width: 135, height: 40 },
  default: { width: 132, height: 40 },
};

export default function Logo(props) {
  const { className, type } = props;
  const src = `//static.beeline.ru/upload/images/b2c/bee-logo/${type}.png`;

  return (
    <img
      alt="Logo"
      className={className}
      height={sizes[type].height}
      role="presentation"
      src={src}
      width={sizes[type].width}
    />
  );
}

Logo.defaultProps = { type: 'default' };

Logo.propTypes = {
  className: PropTypes.any,
  type: PropTypes.oneOf(['single', 'default', 'white']),
};
