import React from 'react';
import PropTypes from 'prop-types';
import { Placemark } from 'react-yandex-maps';

const PlacemarkCircle = ({
  position,
  radius,
  centered,
  iconLayout,
  zIndex,
  properties,
  onClick,
}) => (
  <Placemark
    geometry={[position[0], position[1]]}
    onClick={onClick}
    options={{
      iconLayout,
      zIndex,
      iconOffset: [-radius, centered ? -radius : -radius * 2],
      iconShape: {
        type: 'Circle',
        coordinates: [radius, radius],
        radius,
      },
      cursor: 'pointer',
    }}
    properties={properties}
  />
);

PlacemarkCircle.propTypes = {
  position: PropTypes.array,
  radius: PropTypes.number,
  centered: PropTypes.bool,
  zIndex: PropTypes.number,
  onClick: PropTypes.func,
  properties: PropTypes.object,
  iconLayout: PropTypes.func,
};

export default PlacemarkCircle;
