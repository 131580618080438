import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Button, Emoji, Preloader } from '@beef/legacy-ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ZoomButtons = ({ className, loading, onClick, onGetLocationCLick }) => (
  <div className={cx('component', className)}>
    {[1, -1].map((value) => (
      <Button
        className="default"
        key={value}
        onClick={() => {
          onClick(value);
        }}
      >
        {value > 0 ? '+' : '–'}
      </Button>
    ))}
    <br />
    <Button className="default" onClick={onGetLocationCLick}>
      {loading ?
        <Preloader className={cx('preloader')} />
      : <Emoji name="geolocation" />}
    </Button>
  </div>
);

ZoomButtons.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onGetLocationCLick: PropTypes.func,
};

export default ZoomButtons;
