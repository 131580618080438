import React, { FC } from 'react';
import { compose, withReduxStore } from '@beef/redux';
import { PageFixer, PageRow } from '@beef/smart-kit';
import { withDataConverter } from '@beef/react';

import { initializeStore } from './store/index';
import { dataConverter } from './store/dataConverter';
import { ActionArchive, Breadcrumbs, Title } from './components';

const _ActionArchivePage: FC = () => (
  <PageFixer>
    <Breadcrumbs />
    <PageRow blockSpacersPreset="s" tagName="section">
      <Title />
      <ActionArchive />
    </PageRow>
  </PageFixer>
);

export const ActionArchivePage = compose(
  withDataConverter(dataConverter),
  withReduxStore(initializeStore),
)(_ActionArchivePage);
