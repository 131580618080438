import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { ChipsPropsType, ChipsStateless } from '@beef/ui-kit';

import { useSendFmcTabsYm } from './hooks/useSendFmcTabsYm';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Табы доступных ШПД пресетов тарифа */
export const FmcTabs: FC<ChipsPropsType> = (props) => {
  const presetsRef = useSendFmcTabsYm();

  return (
    <section className={cx('wrapper')} ref={presetsRef}>
      <ChipsStateless className={cx('wrapper__tabs')} {...props} />
    </section>
  );
};
