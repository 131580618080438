import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import Icon from 'pages-components/Icon';
import EmptyButton from 'pages-components/EmptyButton';

import Description from '../Description';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PointItem = ({ place, holidayScheduleChangesText, customName, onClick }) => (
  <EmptyButton className={cx('pointItem')} onClick={onClick}>
    <div className={cx('title')}>
      <Text color="grey95" size="size5-r-s">
        {customName || place.name}
      </Text>
    </div>
    <Description fill={place.line} icon="metro" small text={place.metro} />
    <Description small text={place.address} />
    <Description small text={place.openHours} />
    {place.openHoursHoliday && place.openHoursHolidayB2b && (
      <Description small text={holidayScheduleChangesText} />
    )}
    <Icon className={cx('arrow')} name="dropdownArrow" />
  </EmptyButton>
);

PointItem.propTypes = {
  place: PropTypes.shape({
    name: PropTypes.string,
    metro: PropTypes.string,
    line: PropTypes.string,
    address: PropTypes.string,
    openHours: PropTypes.string,
    openHoursB2b: PropTypes.string,
    openHoursHoliday: PropTypes.string,
    openHoursHolidayB2b: PropTypes.string,
    holidayScheduleChangesText: PropTypes.string,
  }),
  customName: PropTypes.string,
  onClick: PropTypes.func,
};

export default PointItem;
