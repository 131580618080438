import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';
import type { FormHeaderProps } from './types';
import { INDEX_URL, LOGIN_TEXTS } from '../../utils';

const cx = classNames.bind(styles);

export const FormHeader: FC<FormHeaderProps> = ({ className = '' }) => (
  <div className={className}>
    <div className={cx('logo')}>
      <a className={cx('logo-icon')} href={INDEX_URL}>
        <img alt="билайн" src="//static.beeline.ru/upload/images/b2c/bee-logo/single.png" />
      </a>
    </div>
    <Text className={cx('title')} size="size2-m" tagType="h1">
      {LOGIN_TEXTS.FORM_HEADING}
    </Text>
  </div>
);
