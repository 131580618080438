import React, { FC } from 'react';
import { Button } from '@beef/ui-kit';

import { EditIcon } from '../EditIcon';

export type TEditButton = {
  icon?: string;
  onClick: () => void;
};

/** Кнопка возврата на страницу проверки адреса  */
export const EditButton: FC<TEditButton> = ({ icon, onClick }) => (
  <Button
    extraAttributes={{ 'aria-label': 'Кнопка возврата' }}
    icon={<EditIcon icon={icon} />}
    onClick={onClick}
    size="l"
    type="button"
    variant="plain"
  />
);
