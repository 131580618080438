import type { ITrustPaymentContent, ITrustPaymentModel, ITrustPaymentProps } from './types';

export const dataConverter = ({
  data,
}: ITrustPaymentModel<ITrustPaymentContent>): ITrustPaymentProps<ITrustPaymentContent> => {
  if (data && 'isAuthenticated' in data && 'isConnected' in data) {
    // значит новая модель
    return { ...data } as ITrustPaymentProps<ITrustPaymentContent>;
  }

  // старая модель
  return {
    content: data?.content || null,
    isAuthenticated: Boolean(data?.mobile?.login.ctn),
    isConnected: !!data?.mobile?.trustedPaymentStatus.connectedPromisedPayments,
  };
};
