import { Link, Tabs, Text } from '@beef/ui-kit';

import { withCn } from '../enhancers/withCn';

export const NText = withCn(Text);
export const NLink = withCn(Link);
export const NTabs = withCn(Tabs);

export { AlignedGrid } from './AlignedGrid';
export { Anchor } from './Anchor';
export { ButtonLink } from './ButtonLink';
export { ContentLiner } from './ContentLiner';
export { Flex } from './Flex';
export { InjectCSS } from './InjectCSS';
export { RawButtonLink } from './RawButtonLink';
export { ScrollContainerV3 } from './ScrollContainerV3';
export { ScrollableGrid } from './ScrollableGrid';
