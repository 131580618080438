import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import axios from 'axios';
import { Button } from '@beef/legacy-ui-kit';

import {
  initConvertToEsimServiceAuth,
  toggleServiceConnectionPopup,
} from 'pages/ProductCard/actions/serviceConnect';

// Components
import PopupStateless from 'pages-components/Popup';

import ConnectionSteps from './ConnectionSteps';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const STEPS = {
  pending: 'pending',
  auth: 'auth',
  success: 'success',
  connect: 'connect',
  error: 'error',
  blocked: 'blocked',
  isNotEnoughMoney: 'isNotEnoughMoney',
};

const YandexSubscription = ({
  data,
  data: { isConnected, isArchived, warningText, productParameters, isAuthenticated },
  openAuthPopup,
}) => {
  const { content } = data.extData;
  const { buttonText, yandexLink } = content.serviceInfo;
  const [modalOpen, setModalOpen] = useState(false);
  const [popupStatus, setPopupStatus] = useState(STEPS.connect);
  const [buttonVisible, setButtonVisible] = useState(false);

  useEffect(() => {
    setButtonVisible(!warningText && !isConnected && !isArchived);
  }, []);

  const addYandexToParams = (params) =>
    params.reduce((acc, curr) => {
      if (data.soc === 'YANDEX_PD' && curr.key === 'SubscriptionFee') {
        return [
          ...acc,
          curr,
          {
            key: 'SubscriptionFee',
            label: 'Бесплатный промопериод',
            legal: null,
            template: null,
            unit: 'дней',
            value: '90',
          },
        ];
      }

      return [...acc, curr];
    }, []);
  const updateProductParameters = addYandexToParams(productParameters);

  const checkBlock = async () => {
    try {
      const response = await axios.get('/yandex-plus/check-block');
      const { isBlocked, isNotEnoughMoney } = response.data;
      if (isBlocked) {
        setPopupStatus(STEPS.blocked);
        setModalOpen(true);
      }
      if (isNotEnoughMoney) {
        setPopupStatus(STEPS.isNotEnoughMoney);
        setModalOpen(true);
      }
      if (!isNotEnoughMoney && !isBlocked) {
        setPopupStatus(STEPS.connect);
        setModalOpen(true);
      }
    } catch (err) {
      console.error('client routing disabled', err);
    }
  };

  const onButtonClick = () => {
    if (isAuthenticated) {
      checkBlock();
    } else {
      openAuthPopup(content?.auth);
    }
  };

  return (
    <div className={cx('wrap')}>
      <div>
        {updateProductParameters.map((param) => (
          <div className={cx('params__item')} key={param.label}>
            <div className={cx('params__label')}>
              <span>{param.label}</span>
            </div>
            <div className={cx({ price__wrap: param.key === 'SubscriptionFee' })}>
              <span className={cx('price')}>
                {param.value} {param.unit}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div>
        {buttonVisible && (
          <Button className="big" onClick={onButtonClick} wide>
            {buttonText}
          </Button>
        )}
      </div>
      {modalOpen && (
        <PopupStateless onClose={() => setModalOpen(false)} opened>
          <ConnectionSteps
            content={content}
            onClose={() => setModalOpen(false)}
            popupStatus={popupStatus}
            steps={STEPS}
            yandexLink={yandexLink}
          />
        </PopupStateless>
      )}
    </div>
  );
};

YandexSubscription.propType = {
  data: PropTypes.object,
  openAuthPopup: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  openAuthPopup: (extraData) => {
    dispatch(initConvertToEsimServiceAuth('/authorizationpopup/', extraData));
    dispatch(toggleServiceConnectionPopup(true));
  },
});

export default connect(null, mapDispatchToProps)(YandexSubscription);
