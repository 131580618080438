import React, { FC } from 'react';
import { withDataConverter } from '@beef/react';
import { compose, withReduxStore } from '@beef/redux';
import { connect } from 'react-redux';

import { ErrorScreenContainer } from './components/ErrorScreenContainer/index';
import { VoWifiContainer } from './components/VoWifiContainer/index';
import { useInitVoWifiServiceCard } from './hooks/hook';
import { voWifiDataConverter } from './store/dataConverters';
import { initializeVoWifiServiceCardLanding } from './store/index';
import {
  selectContent,
  selectErrorScreenStatus,
  selectErrorScreensState,
  selectIsConnected,
  selectIsError,
  selectSupportStatus,
  selectVowifiFAQ,
  selectVowifiInstructions,
} from './store/selectors';
import { IRoot, RootState } from './types';

export const _VoWifiServiceCardLanding: FC<Partial<IRoot>> = ({
  content,
  isError,
  errorScreenStatus,
  errorScreensState,
  supportStatus,
  vowifiFAQ,
  vowifiInstructions,
  isConnected,
  dispatch,
}) => {
  const { mainData, errorData } = useInitVoWifiServiceCard({
    content,
    supportStatus,
    errorScreenStatus,
    errorScreensState,
    isError,
    vowifiFAQ,
    vowifiInstructions,
    isConnected,
    dispatch,
  });

  if (isError) {
    return <ErrorScreenContainer data={errorData} />;
  }

  return <VoWifiContainer data={mainData} />;
};

const mapStateToProps = (state: RootState) => ({
  content: selectContent(state),
  vowifiFAQ: selectVowifiFAQ(state),
  vowifiInstructions: selectVowifiInstructions(state),
  isError: selectIsError(state),
  errorScreenStatus: selectErrorScreenStatus(state),
  errorScreensState: selectErrorScreensState(state),
  supportStatus: selectSupportStatus(state),
  isConnected: selectIsConnected(state),
});

export default compose(
  withDataConverter(voWifiDataConverter),
  withReduxStore(initializeVoWifiServiceCardLanding),
  connect(mapStateToProps),
)(_VoWifiServiceCardLanding);
