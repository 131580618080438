/* Возможные типы оборудования */
export enum EEquipmentType {
  internetEquipment = 'internetEquipment',
  tvEquipment = 'tvEquipment',
}

/* Возможные типы чекбоксов */
export enum ECheckboxType {
  internetEquipmentCheckbox = 'internetEquipmentCheckbox',
  tvEquipmentCheckbox = 'tvEquipmentCheckbox',
}

export type TSizeType = 'big' | 'small';
