import { ymPushParams } from 'utils/ym';

import { SUPPORT_STATUSES } from './store/constants';

export const voWifiChoosingBrandYM = (payload, support) => {
  if (support === SUPPORT_STATUSES.SUPPORTED) {
    return ymPushParams({ vowifi: { vowifi_on_v2: { brand_choose: payload } } });
  }

  if (support === SUPPORT_STATUSES.NOT_SUPPORTED_WITH_RESTRICTION) {
    return ymPushParams({ vowifi: { vowifi_not_available_v2: { brand_choose: payload } } });
  }

  if (support === SUPPORT_STATUSES.NOT_SUPPORTED) {
    return ymPushParams({ vowifi: { vowifi_update: { brand_choose: payload } } });
  }
};

const voWifiClickedSettingsButtonONYM = () =>
  ymPushParams({ vowifi: { vowifi_on_v2: 'to_settings_bottom' } });
const voWifiClickedSettingsButtonUpdateYM = () =>
  ymPushParams({ vowifi: { vowifi_update: 'to_settings_bottom' } });
// eslint-disable-next-line max-len
const voWifiClickedSettingsButtonNAYM = () =>
  ymPushParams({ vowifi: { vowifi_not_available_v2: 'to_settings_bottom' } });

export const voWifiClickedSettingsButtonYM = (status) => {
  if (status === SUPPORT_STATUSES.SUPPORTED) {
    voWifiClickedSettingsButtonONYM();
    return;
  }

  if (status === SUPPORT_STATUSES.NOT_SUPPORTED_WITH_RESTRICTION) {
    voWifiClickedSettingsButtonNAYM();
    return;
  }

  if (status === SUPPORT_STATUSES.NOT_SUPPORTED) {
    voWifiClickedSettingsButtonUpdateYM();
  }
};

export const voWifiClickedSettingsButtonInTeaserYM = () =>
  ymPushParams({ vowifi: { vowifi_on_v2: 'to_settings' } });

export const voWifiViewScreenYM = (support) => {
  if (support === SUPPORT_STATUSES.SUPPORTED) {
    return ymPushParams({ vowifi: { vowifi_on_v2: 'view_screen' } });
  }

  if (support === SUPPORT_STATUSES.NOT_SUPPORTED_WITH_RESTRICTION) {
    return ymPushParams({ vowifi: { vowifi_not_available_v2: 'view_screen' } });
  }

  if (support === SUPPORT_STATUSES.NOT_SUPPORTED) {
    return ymPushParams({ vowifi: { vowifi_update: 'view_screen' } });
  }
};

export const voWifiGoToShopYM = () => ymPushParams({ vowifi: { vowifi_update: 'to_shop' } });

export const voWifiFaqQuestionYM = (payload, support) => {
  if (support === SUPPORT_STATUSES.SUPPORTED) {
    return ymPushParams({ vowifi: { vowifi_on_v2: { faq_click: payload } } });
  }

  if (support === SUPPORT_STATUSES.NOT_SUPPORTED_WITH_RESTRICTION) {
    return ymPushParams({ vowifi: { vowifi_not_available_v2: { faq_click: payload } } });
  }

  if (support === SUPPORT_STATUSES.NOT_SUPPORTED) {
    return ymPushParams({ vowifi: { vowifi_update: { faq_click: payload } } });
  }
};

export const voWifiGoToStoreYM = (payload) => ymPushParams({ vowifi: { go_to_store: payload } });
export const voWifiGoToAppYM = (payload) => ymPushParams({ vowifi: { go_to_app: payload } });
