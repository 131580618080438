import React, { Children, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/**
 * Обертка для ДВУХ!!! компонентов, регулирующая мобильный и десктопный рендеринг, с учетом серверного рендеринга
 * Как это работает !!!
 * Прокидывается два компонента в качестве детей. Регулируется их отображение и монтирование в DOM.
 * ВАЖНО !!!
 * 1. Первый компонент для отображения на мобиле ( children[0] )
 * 2. Второй компонент для отображения на десктопе ( children[1] )
 * @param endPoint {number} Значение, разделяющее отображение мобилы и десктопа (по умолчанию 768рх)
 * @param keepBoth {boolean} Указывает на необходимость рендерить оба компонента после mount'а
 * @param children {any} Children
 *
 * Пример использования данного компонента смотри после экспорта ↓↓↓
 * Примеры реального использования Command(Ctrl) + Shift + F  😃😃😃
 */

const AdaptiveWrapper = ({ children, endPoint, keepBoth }) => {
  const [wasRendered, rendering] = useState(false);
  const [isMobile, checkScreenWidth] = useState(false);
  const childrenArray = Children.toArray(children);

  useEffect(() => {
    checkScreenWidth(window.innerWidth < endPoint);
    rendering(true);

    const eventResizeListener = () => checkScreenWidth(window.innerWidth < endPoint);
    window.addEventListener('resize', eventResizeListener);

    return () => window.removeEventListener('resize', eventResizeListener);
  }, [endPoint]);

  if (wasRendered && !keepBoth) {
    return isMobile ? childrenArray[0] : childrenArray[1];
  }

  return (
    <>
      <div className={cx('mobileComponent')}>{childrenArray[0]}</div>
      <div className={cx('desktopComponent')}>{childrenArray[1]}</div>
    </>
  );
};

AdaptiveWrapper.defaultProps = { endPoint: 768 };

AdaptiveWrapper.propTypes = {
  endPoint: PropTypes.number,
  keepBoth: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

export default AdaptiveWrapper;

/*
   Пример использования компонента:

   <AdaptiveWrapper endPoint={850}>
      <ComponentForMobile />
      <ComponentForDesktop />
   </AdaptiveWrapper>
*/
