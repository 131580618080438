import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@beef/ui-kit';

import { InputLayout } from './InputLayout';

export const GenericInput = ({ placeholder, mask, defCodeNode, value, onChange }) => (
  <InputLayout defCodeNode={defCodeNode} onReset={() => onChange('')}>
    {({ searchIcon, resetButton }) => (
      <Input
        leftAside={searchIcon}
        maskSettings={mask}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        rightAside={value ? resetButton : null}
        value={value}
      />
    )}
  </InputLayout>
);

GenericInput.propTypes = {
  placeholder: PropTypes.string,
  defCodeNode: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
  mask: PropTypes.shape({
    mask: PropTypes.string,
    format: PropTypes.string,
  }),
};
