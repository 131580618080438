import React, { type ReactNode } from 'react';
import { Modal, ModalPropsType } from '@beef/ui-kit';

import { ModalActionBar, TModalActionBar } from './components/ModalActionBar';

export type TModalContainer = {
  children: ReactNode;
  isOpen: boolean;
  modalActionBarProps: TModalActionBar;
} & ModalPropsType;

/**
 * Контейнер общей модалки для всего конструктора.
 * ModalActionBar компонент, отвечающий за buttons модалки
 */
export const ModalContainer = ({
  size,
  isOpen,
  onClose,
  children,
  modalActionBarProps,
}: TModalContainer) => (
  <Modal
    actionBar={<ModalActionBar {...modalActionBarProps} />}
    isOpen={isOpen}
    onClose={onClose}
    size={size}
  >
    {children}
  </Modal>
);
