import { useRef } from 'react';

// Constants
import { CONNECTED_FTTB_KEY } from '../constants';

/**
 * useConvergenceRequestParams: создает параметры запроса для метода api/convergence/connect-tariff.
 * @param allCheckedConvergenceOptions
 * @param convergence
 */
export const useConvergenceRequestParams = ({
  convergence,
  isConvergence,
  isAuthenticated,
  removalEquipment,
  isNeedCallBackAndRemoveEquipment,
  allCheckedConvergenceOptions,
}) => {
  const cachedFttb = useRef({});
  const hasConnectedConvergencePrest = convergence?.tariff?.isConnected;
  const disableNotConvergenceTypes = ['IPTV_CONSOLE_RENT', 'IptvRent'];
  const mainTvPackage = allCheckedConvergenceOptions?.find((i) => i.groupName === i.inacId);
  let mainTv = null;

  // Исключает из  chId тв приставку
  const hasTvCondition = (i) => i.groupName && !disableNotConvergenceTypes.includes(i.serviceType);
  if (mainTvPackage) {
    // накапливаем chId только для тв, в chId так же входит inacId основного пакета
    const childId = allCheckedConvergenceOptions.reduce((acc, i) => {
      if (hasTvCondition(i)) {
        return [...acc, { chId: i.inacId }];
      }
      return acc;
    }, []);
    mainTv = {
      type: mainTvPackage.serviceType,
      srvId: mainTvPackage.inacId,
      params: {
        packets: childId,
        guID: mainTvPackage.guID,
        serial: mainTvPackage.serial,
        isMain: mainTvPackage.isMain,
        isTve: mainTvPackage.isTve,
      },
    };
  }

  const mainPreset = {
    params: null,
    type: 'IS_PRESET',
    srvId: convergence?.tariff?.inacId,
  };

  const fttb = allCheckedConvergenceOptions.reduce(
    (acc, i) => {
      if (
        hasTvCondition(i) ||
        (isNeedCallBackAndRemoveEquipment &&
          removalEquipment.some((e) => e.serviceType === i.serviceType))
      ) {
        return acc;
      }
      return [
        ...acc,
        {
          type: i.serviceType,
          srvId: i.inacId,
          params: {
            guID: i.guID,
            serial: i.serial,
            isMain: i.isMain,
            isTve: i.isTve,
          },
        },
      ];
    },
    [mainPreset],
  );

  let fttbServices = mainTv ? [...fttb, mainTv] : fttb;

  // Отправка подключенного fttbServices, если пользователь конвергент и изменил пресет ШПД
  if (convergence?.tariff) {
    const cachedKey = `isConnectedFttb--${hasConnectedConvergencePrest}`;
    cachedFttb.current[cachedKey] = fttbServices;

    if (isConvergence && !hasConnectedConvergencePrest) {
      fttbServices = cachedFttb.current[CONNECTED_FTTB_KEY];
    }
  }

  const baseParams = {
    houseId: convergence?.houseId,
    flat: convergence?.flat,
    abonentFirstName: 'С',
    abonentMiddleName: 'сайта',
    abonentLastName: 'заявка',
    fttbServices,
  };

  // eslint-disable-next-line max-len
  const requestConvergenceParams =
    isAuthenticated ?
      { ...baseParams, ctn: convergence?.userNumber }
    : { ...baseParams, contactPhone: convergence?.userNumber };

  return { fttbServices, requestConvergenceParams };
};
