import React from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from '@beef/ui-kit';
import { times } from 'ramda';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

export const PreloadLayout = () => (
  <div className={cn('list')}>
    {times(
      (i) => (
        <Skeleton className={cn('item')} key={i} />
      ),
      8,
    )}
  </div>
);
