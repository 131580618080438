import { createAction } from '@reduxjs/toolkit';

// Utils
import { ymOrderTariffUpFormBidView } from 'pages/FTTB2021/components/OrderTariffFormPopup/analytics';

import { tariffsMapper } from '../../utils';

// Actions
export const setActiveSettingsTab = createAction('SET_ACTIVE_SETTINGS_TAB');
export const setAccessibleTariffs = createAction('SET_ACCESSIBLE_TARIFFS');
export const setCurrentAnimalSoc = createAction('SET_CURRENT_ANIMAL_SOC');
export const setSelectedServices = createAction('SET_SELECTED_SERVICES');
export const setActiveTab = createAction('SET_ACTIVE_TAB');
export const setTotalPrice = createAction('SET_TOTAL_PRICE');

export const getYmFieldsByServiceType = (type) =>
  ({
    0: 'fmc_bid_tv',
    7: 'fmc_bid_ip',
    13: 'fmc_bid_wifi_router',
  })[type];

/**
 * setSelectedTariffServices: action обробатывает клики по "чекрыжам" пользователем
 * и обновляет объекты тарифов, достыпных по указанному адресу.
 * @param inacId
 * @param tariffInacId
 * @param name - Название выбранного сервиса
 * @param fttbServiceType - Тип сервиса
 * @returns {(function(*, *): void)|*}
 */
export const setSelectedTariffServices =
  ({ inacId, tariffInacId, name, fttbServiceType }) =>
  (dispatch, getState) => {
    const allTariffs = getState()?.orderTariffDetailsData?.tariffs;
    dispatch(setSelectedServices(tariffsMapper({ inacId, allTariffs, tariffInacId })));
    const ymField = getYmFieldsByServiceType(fttbServiceType);
    if (ymField) {
      ymOrderTariffUpFormBidView({ [ymField]: name });
    }
  };
