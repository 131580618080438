import React from 'react';
import { Input } from '@beef/ui-kit';

import { useCheckAddressInputsTexts } from '../../selectors/useCheckAddressInputsTexts';
import { useCheckFlatInput } from './hooks/useCheckFlatInput';
import { flatMask } from './constants';

export const FmcFlatInput = () => {
  const { flatPlaceholder, flatTitle } = useCheckAddressInputsTexts();
  const inputProps = useCheckFlatInput();
  const placeholder = !inputProps.disabled ? flatPlaceholder : undefined;
  return (
    <Input label={flatTitle} maskSettings={flatMask} placeholder={placeholder} {...inputProps} />
  );
};
