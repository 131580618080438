import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { ModalContainer } from '../../../components/modalComponents/ModalContainer';
import { TEquipmentModal } from './types';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const EquipmentModal: FC<TEquipmentModal> = ({
  title,
  description,
  modalContainerProps,
}) => (
  <ModalContainer {...modalContainerProps}>
    <>
      {title && (
        <Text className={cx('title')} size="size2-m">
          {title}
        </Text>
      )}
      {description && (
        <Text color="grey60" size="size4-r">
          {description}
        </Text>
      )}
    </>
  </ModalContainer>
);
