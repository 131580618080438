import React, { FC } from 'react';
import { ActionBar, Button } from '@beef/ui-kit';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { ActionBarCancelProps } from 'pages/Services/RedirectServiceCardLanding/components/ModalCancel/types';
import { RedirectStore } from 'pages/Services/RedirectServiceCardLanding/store';
import {
  selectLoadingAction,
  selectRedirectQPContent,
} from 'pages/Services/RedirectServiceCardLanding/store/redirect/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _ActionBarCancel: FC<ActionBarCancelProps> = ({
  loading,
  onSetRedirectData,
  contentUnActive,
  onCloseModal,
  contentCancelActive,
  contentError,
}) => {
  const onCancel = () => {
    onSetRedirectData();
  };

  const onCloseError = () => {
    window.location.reload();
  };

  if (loading === 'succeeded') {
    return (
      <ActionBar size="m">
        <Button onClick={onCloseModal}>{contentCancelActive?.buttons?.main}</Button>
      </ActionBar>
    );
  }

  if (loading === 'failed') {
    return (
      <ActionBar size="m">
        <Button onClick={onCloseError}>{contentError?.buttons?.main}</Button>
      </ActionBar>
    );
  }
  return (
    <ActionBar size="m">
      <Button
        className={cx('active')}
        isLoading={loading === 'pending'}
        onClick={onCancel}
        variant="tertiary"
      >
        {contentUnActive?.buttons?.main}
      </Button>
      <Button onClick={onCloseModal} variant="tertiary">
        {contentUnActive?.buttons?.cancel}
      </Button>
    </ActionBar>
  );
};

const mapStateToProps = (state: RedirectStore) => ({
  loading: selectLoadingAction(state),
  contentUnActive: selectRedirectQPContent(state)?.unactive,
  contentCancelActive: selectRedirectQPContent(state)?.cancelActive,
  contentError: selectRedirectQPContent(state)?.error,
});

export const ActionBarCancelContent = connect(mapStateToProps)(_ActionBarCancel);
