import { emptyArr, shallowEqual } from '@beef/utils';

import { setTotalDateTimeSlot } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/totalSlice';
import { DEFAULT_INDEX } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/constants';
import {
  TSpecialistVisitResponse,
  fetchAvailableSchedule,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/api/fetchAvailableSchedule';
import { useStepNavigation } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/hooks/useStepNavigation';
import {
  useAppDispatch,
  useAppSelector,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';
import {
  setAvailableSchedule,
  setSelectedTime,
  setSelectedTimePeriods,
  setSelectingDate,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/fttbSpecialistVisitSlice';
import { composeAvailableSchedule } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/utils/composeAvailableSchedule';
import { IComposedAvailablePeriod } from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store/slices/fttbSpecialistVisitSlice/types';

/* Хук вызова api и обработки полученных данных для получения доступных слотов монтажника */
export const useHandleAvailableSchedule = () => {
  const dispatch = useAppDispatch();
  const { nextStep } = useStepNavigation();

  const { flat, houseId } = useAppSelector(
    (state) => ({
      flat: state.fttbAddress.checkConnectionRes.flat,
      houseId: state.fttbAddress.checkConnectionRes.houseId,
    }),
    shallowEqual,
  );

  /** Функция-обработчик получения слотов монтажника */
  const onSchedule = async () =>
    dispatch(fetchAvailableSchedule({ flat, houseId }))
      .unwrap()
      .then(({ isSucceeded, view }: TSpecialistVisitResponse) => {
        const { periods } = view;
        /* Условие если в ответе вернулся не пустой массив periods, то отображаем экран с выбором слотов монтажника
   в случае неполучения слотов происходит переход к экрану "Подключение тарифа UP"  */
        if (!periods || !periods.length) {
          nextStep();
          return;
        }

        if (isSucceeded) {
          const preparedData = composeAvailableSchedule(periods);
          /* Получаем первую дату из массива слотов для ее выделения в момент перехода на экран слотов монтажника */
          const [defaultSelectingDate] = preparedData?.map((item) => item.id) || emptyArr;
          const defaultPeriod = preparedData.find(
            (item) => item.label === defaultSelectingDate,
          ) as IComposedAvailablePeriod;

          dispatch(setAvailableSchedule(preparedData));
          dispatch(setSelectingDate(defaultSelectingDate));
          dispatch(setSelectedTimePeriods(defaultPeriod?.timePeriods));
          dispatch(setSelectedTime(defaultPeriod?.timePeriods[DEFAULT_INDEX]));
          /* Установка дефолтной даты прихода монтажника */
          dispatch(
            setTotalDateTimeSlot({
              endDate: defaultPeriod?.timePeriods[DEFAULT_INDEX].endTimeStamp,
              startDate: defaultPeriod?.timePeriods[DEFAULT_INDEX].startTimeStamp,
            }),
          );
        }
      })
      .catch(() => {
        nextStep();
      });
  return { onSchedule };
};
