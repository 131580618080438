import { useEffect, useState } from 'react';

import store from 'store';

export const useIsAuthenticated = () => {
  const [state, setState] = useState(false);

  useEffect(() => {
    const handleChange = () => {
      const { user } = store.getState().external;

      setState(!!user);
    };
    handleChange();

    const unsubscribe = store.subscribe(handleChange);
    return () => unsubscribe();
  }, []);

  return state;
};
