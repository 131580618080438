import React from 'react';
import classNames from 'classnames/bind';
import { useIsMobileDimension } from '@beef/ui-kit';
import { cnTheme } from '@carnica/themes';
import { Modal } from '@carnica/ui-kit';

import { Teaser } from './components/Teaser';
import { AppLayout, Screen } from './layout';
import { IMobileAppLanding2024Props } from './types';
import styles from './styles.pcss';
import { DarkApp, Info, Slides } from './components';
import { Metric, withMetricListeners } from './metric';
import { usePrepareSlides } from './hooks/usePrepareSlides';
import { useModal } from './hooks/useModal';
import { QRCode } from './blocks/DownloadButtons/QRCode';
import { Desktop, Mobile } from './blocks';

const closeOptions = {
  button: true,
  escape: true,
  overlay: true,
};

const cx = classNames.bind(styles);

const MobileAppLanding2024_: React.FC<IMobileAppLanding2024Props> = ({ data: { content } }) => {
  const isMobile = useIsMobileDimension();

  const { isHideModal, isOpenModal, handleClickModal, handleCloseModal } = useModal();

  const { activeScreen, maxLandingHeight, sliderRef, firstNumberOfScreensWithSlider } =
    usePrepareSlides({
      slides: content?.slides,
      isOpenModal,
      pagesToScrollDesktop: content?.pagesToScrollDesktop || 1,
      pagesToScrollMobile: content?.pagesToScrollMobile || 1,
    });

  return (
    <Metric
      events={{
        mobileApp2024Metric: ['handlePageLoad'],
      }}
      mode="render"
    >
      <AppLayout
        className={cx('layout', cnTheme('light'))}
        ref={sliderRef}
        style={{
          height: `${maxLandingHeight}vh`,
        }}
      >
        <div className={cx('scroll-wrap')}>
          <div className={cx('container')}>
            <Screen isActive={activeScreen === 0} transitionDuration={content?.transitionDuration}>
              <Teaser
                {...(content?.teaser || {})}
                fixedButton={content?.fixedButton || {}}
                handleClickModal={handleClickModal}
                handleCloseModal={handleCloseModal}
              />
            </Screen>
            <Screen isActive={activeScreen === 1} transitionDuration={content?.transitionDuration}>
              <Info
                {...content?.checkAndReplenish}
                fixedButton={content?.fixedButton}
                handleClickModal={handleClickModal}
                handleCloseModal={handleCloseModal}
              />
            </Screen>
            <Mobile>
              <Screen
                isActive={activeScreen === 2}
                transitionDuration={content?.transitionDuration}
              >
                <Slides
                  fixedButton={content?.fixedButton}
                  handleClickModal={handleClickModal}
                  handleCloseModal={handleCloseModal}
                  isActive={activeScreen === 2}
                  slides={content?.slides}
                />
              </Screen>
            </Mobile>
            <Desktop>
              {content?.slides?.map((slide, index) => (
                <Screen
                  isActive={activeScreen === index + 2}
                  transitionDuration={content?.transitionDuration}
                >
                  <Info
                    {...slide}
                    fixedButton={content?.fixedButton}
                    handleClickModal={handleClickModal}
                    handleCloseModal={handleCloseModal}
                    isBottomImg={slide.isBottomImg}
                  />
                </Screen>
              ))}
            </Desktop>
            <Screen
              isActive={activeScreen === firstNumberOfScreensWithSlider}
              transitionDuration={content?.transitionDuration}
            >
              <Info
                {...content?.manage}
                fixedButton={content?.fixedButton}
                handleClickModal={handleClickModal}
                handleCloseModal={handleCloseModal}
              />
            </Screen>
            <Screen
              isActive={activeScreen === firstNumberOfScreensWithSlider + 1}
              transitionDuration={content?.transitionDuration}
            >
              <Info
                {...content?.solveEverything}
                fixedButton={content?.fixedButton}
                handleClickModal={handleClickModal}
                handleCloseModal={handleCloseModal}
                isBottomImg
              />
            </Screen>
            <Screen
              isActive={activeScreen === firstNumberOfScreensWithSlider + 2}
              isDark
              transitionDuration={content?.transitionDuration}
            >
              <DarkApp
                {...content?.darkApp}
                fixedButton={content?.fixedButton}
                handleClickModal={handleClickModal}
                handleCloseModal={handleCloseModal}
              />
            </Screen>
          </div>
          {/* Аналитика показа экранов вынесена отдельно, т.к. экраны переключаются через opacity */}
          <div>
            {activeScreen === 0 && (
              <Metric
                data={content?.teaser?.title}
                events={{
                  mobileApp2024Metric: ['handleViewBlock'],
                }}
                mode="render"
              />
            )}
            {activeScreen === 1 && (
              <Metric
                data={content?.checkAndReplenish?.title}
                events={{
                  mobileApp2024Metric: ['handleViewBlock'],
                }}
                mode="render"
              />
            )}

            {!isMobile &&
              content?.slides?.map(
                (slide, index) =>
                  activeScreen === index + 2 && (
                    <Metric
                      data={slide?.title}
                      events={{
                        mobileApp2024Metric: ['handleViewBlock'],
                      }}
                      mode="render"
                    />
                  ),
              )}

            {activeScreen === firstNumberOfScreensWithSlider && (
              <Metric
                data={content?.manage?.title}
                events={{
                  mobileApp2024Metric: ['handleViewBlock'],
                }}
                mode="render"
              />
            )}

            {activeScreen === firstNumberOfScreensWithSlider + 1 && (
              <Metric
                data={content?.solveEverything?.title}
                events={{
                  mobileApp2024Metric: ['handleViewBlock'],
                }}
                mode="render"
              />
            )}

            {activeScreen === firstNumberOfScreensWithSlider + 2 && (
              <Metric
                data={content?.darkApp?.title}
                events={{
                  mobileApp2024Metric: ['handleViewBlock'],
                }}
                mode="render"
              />
            )}
          </div>
        </div>
      </AppLayout>
      {!isHideModal && (
        <Modal
          closeOptions={closeOptions}
          disableScroll={false}
          isOpen={isOpenModal}
          onClose={handleCloseModal}
          portalClassName={cx('modal', isOpenModal && 'modal-active')}
          size="m"
        >
          <Metric
            data={content?.fixedButton?.modal?.title}
            events={{
              mobileApp2024Metric: ['handleModalOpen'],
            }}
            mode="viewport"
            once
          >
            <QRCode {...content?.fixedButton?.modal} />
          </Metric>
        </Modal>
      )}
    </Metric>
  );
};

export const MobileAppLanding2024 = withMetricListeners(MobileAppLanding2024_);
