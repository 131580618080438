import React from 'react';
import PropTypes from 'prop-types';

// Components
import classNames from 'classnames/bind';

import Icon from 'pages-components/Icon';
import Picture from 'pages-components/Picture';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Badge = ({ label, icon, type }) => (
  <div className={cx('nameplateBadge', type)}>
    <span className={cx('label', icon && 'iconLabel')}>
      {label && label}
      {!label && icon && (
        <Icon className={cx('icon')} src={`//static.beeline.ru/upload/images/icons/${icon}.svg`} />
      )}
    </span>
  </div>
);

Badge.defaultProps = { type: 'tawny' };

Badge.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
};

const Inline = ({ label, style }) => <div className={cx('nameplateInline', style)}>{label}</div>;

Inline.propTypes = {
  label: PropTypes.string,
  style: PropTypes.string,
};

const UpperBar = ({ text, iconUrl, type }) => (
  <div className={cx('upperBar', type)}>
    <span dangerouslySetInnerHTML={{ __html: text }} />
    {iconUrl && <Picture alt="" src={iconUrl} />}
  </div>
);

UpperBar.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  iconUrl: PropTypes.string,
};

export default {
  Inline,
  Badge,
  UpperBar,
};
