import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { translate } from 'utils/format-string';

import styles from './ConnectedNumbersInfo.pcss';
import TextContainer from '../TextContainer/TextContainer';

const cx = classNames.bind(styles);

class ConnectedNumbersInfo extends Component {
  renderText(path) {
    const { numbers, maxNumbers, tariffName } = this.props;
    const numbersUnit = translate('number', numbers);

    if (this.props.isConnected || this.props.isPopup) {
      return (
        <TextContainer
          path={path}
          variables={{
            connected: numbers === 1 ? 'Подключен' : 'Подключено',
            connectedNumbersCount: numbers,
            maxNumbers,
            numbersUnit,
          }}
        />
      );
    }

    if (maxNumbers > numbers) {
      const availableToConnectCount = maxNumbers - numbers;
      return (
        <TextContainer
          path={['texts', 'connectMoreDescription']}
          variables={{
            numbers: `${availableToConnectCount}&nbsp;${
              availableToConnectCount === 1 ? 'дополнительным номером' : 'дополнительными номерами'
            }`,
            tariffName: this.props.tariffName,
          }}
        />
      );
    }
    if (maxNumbers < numbers) {
      const numbersToDisconnect = numbers - maxNumbers;
      return (
        <TextContainer
          path={['texts', 'connectFewerDescription']}
          variables={{
            numbers: `${numbersToDisconnect}&nbsp;${translate(
              'additional',
              numbersToDisconnect,
            )} ${translate('number', numbersToDisconnect)}`,
            connected: maxNumbers,
            tariffName,
          }}
        />
      );
    }

    return (
      <TextContainer
        path={['texts', 'connectSameDescription']}
        variables={{
          connected: this.props.numbers === 1 ? 'подключен' : 'подключено',
          numbers: `${numbers}&nbsp;${translate('additional', numbers)} ${translate(
            'number',
            numbers,
          )}`,
        }}
      />
    );
  }

  render() {
    return this.props.numbers > 0 ?
        <div className={cx('connected')}>
          <div className={cx('connected-text')}>
            {this.renderText(['texts', 'shareOptionInfo'])}
          </div>
          {this.props.isConnected && this.props.onConnectedChange ?
            <div className={cx('connected-link')}>
              <Link onClick={this.props.onConnectedChange}>
                <TextContainer path={['texts', 'shareOptionLinkText']} />
              </Link>
            </div>
          : null}
        </div>
      : null;
  }
}

ConnectedNumbersInfo.propTypes = {
  numbers: PropTypes.number,
  maxNumbers: PropTypes.number,
  onConnectedChange: PropTypes.func,
  isConnected: PropTypes.bool,
  isPopup: PropTypes.bool,
  tariffName: PropTypes.string,
};

export default ConnectedNumbersInfo;
