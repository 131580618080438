import React from 'react';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

import { Badge } from 'pages/ProductCard/Tariff/components/Check/SubscriptionFeeBlock/Badge';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface TemporaryDiscountProps {
  activeColor?: string;
  discountPercents: string;
  text: string;
}

const TemporaryDiscount = ({ text, activeColor, discountPercents }: TemporaryDiscountProps) => (
  <div className={cx('container')}>
    <Badge activeColor={activeColor} discount={discountPercents} />
    <Text size="size5-r-s">{text}</Text>
  </div>
);

export { TemporaryDiscount };
