import { useAppSelector } from '../../../../store';

/** Подготовка контента опций */
export const useTariffOptionsContent = () => {
  /* Массив доступных опций тарифа */
  const options = useAppSelector((state) => state.options);

  /* Массив выбранных соков опций тарифа */
  const optionsSocs = useAppSelector((state) => state.total.optionsSoc);

  /* Контентные данные (заголовок) для блока опций */
  const { optionsTitle } = useAppSelector((state) => state.common.content);

  /* Трансформируем цену дочерних опций в 0 рублей, если активна родительская
   * и делаем дочернюю опцию активной, если ее цена равна 0 и активна родительская */
  const preparedOptions = options.map((optionGroup) => ({
    ...optionGroup,
    optionsList: optionGroup.optionsList.map((option) => {
      /* Флаг определяет, является ли текущий выбранный сок опции дочерним */
      const isChildrenOptionSoc = option.parentSoc ? optionsSocs.includes(option.parentSoc) : false;
      const price = isChildrenOptionSoc ? 0 : option.price;
      const isActive = (isChildrenOptionSoc && price === 0) || option.isActive;

      return { ...option, isActive, price };
    }),
  }));
  return { title: optionsTitle, options: preparedOptions };
};
