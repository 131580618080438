import { createSelector } from '@reduxjs/toolkit';

// TODO: избавиться от циклической зависимости
// eslint-disable-next-line import/no-cycle
import { RootState } from 'constructorV1/store';

/** Селектор выборки options ЕК */
export const selectTariffSettings = (state: RootState) => state.tariffSettings;

/** Селектор выборки sections ЕК */
export const selectOptionsSections = createSelector(
  selectTariffSettings,
  (tariffSettings) => tariffSettings.sections,
);

/** Селектор выборки initialData ЕК */
export const selectInitialData = createSelector(
  selectTariffSettings,
  (tariffSettings) => tariffSettings.initialData,
);
