import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { GiftOutlineIcon, Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

type Props = {
  discountDescription: string;
};

export const DiscountBlock: FC<Props> = ({ discountDescription }) => (
  <div className={cx('container')}>
    <GiftOutlineIcon fill="#E6008C" size="xs" />
    <Text color="inherit" size="size5-r-s">
      {discountDescription}
    </Text>
  </div>
);
