import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';

import { ContentPropsType } from './types';
import styles from './styles.pcss';
import { Icon } from '../Icon';

const cx = classNames.bind(styles);

export const Content: FC<ContentPropsType> = ({ title, subTitle, isSuccess }) => {
  return (
    <div className={cx('content')}>
      <Icon isSuccess={isSuccess} />
      <Text align="center" className={cx('title')} color="primary" size="m" tagName="span">
        {title}
      </Text>
      <Text align="center" color="secondary" size="s" tagName="span">
        {subTitle}
      </Text>
    </div>
  );
};
