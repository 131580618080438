import { debounce } from 'utils/timed-functions';

/** Создает объект, который реализает механизм отправки событий аналитики без дублирования и с эффектом накоплением */
export class YmElementsViewPusher {
  /**
   * Конструктор класса
   * @param eventPusher Функция, которая будет вызвана, если есть накопленные события и истек таймер
   * @param delay Задержка между отправкой накопленных событий
   */
  constructor({ eventPusher, delay = 500 }) {
    this.eventPusher = eventPusher;
    this._releaseQueueDebounced = debounce(this._releaseQueue, delay);
  }

  _releaseQueueDebounced() {}

  _queue = [];

  _itemsInQueue = {};

  _totalPushedCount = 0;

  _pushItemInQueue = (item, alias, customIndex) => {
    this._queue.push(item);
    if (!this._itemsInQueue[alias]) {
      this._itemsInQueue[alias] = customIndex ?? Object.keys(this._itemsInQueue).length + 1;
    }
  };

  _releaseQueue() {
    this.eventPusher(this._queue, this._totalPushedCount);
    this._totalPushedCount += this._queue.length;
    this._queue = [];
  }

  /**
   * Добавить событие в очередь на отправку по таймауту
   * @param payload Объект данных события
   * @param alias Идентификатор события
   * @param {boolean=} withResending Включение повторной отправки событий
   * @param {number=} customIndex Пользовательская индексация событий
   */
  push(payload, alias, withResending, customIndex) {
    if (typeof document === 'undefined') return;
    if (this._itemsInQueue[alias] && !withResending) return;
    this._pushItemInQueue(payload, alias, customIndex);
    this._releaseQueueDebounced();
  }

  /**
   * Получить индекс события в очереди по идентификатору
   * @param alias Идентификатор события
   * @returns {*|number} Порядковый номер в очереди
   */
  getItemIndex(alias) {
    return this._itemsInQueue[alias] || 1;
  }

  /**
   * Получить текущую очередь событий
   * @returns {Array} Массив объектов в очереди
   */
  getQueue() {
    return this._queue || [];
  }
}
