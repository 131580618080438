import { ymPushParams } from 'utils/ym';

type Section = {
  content: {
    label: string;
    legal: null | string;
    unit: null | string;
    value: null | string;
  }[];
  title: string;
};
export const onFaqClick = (index: number, sections: Section[], data: object) => {
  const { isConnected } = data;
  const sectionTitleClicked = sections[index].title;
  ymPushParams({
    vowifi: {
      [isConnected ? 'vowifi_on_v1' : 'vowifi_not_available_v1']: {
        faq_click: sectionTitleClicked,
      },
    },
  });
};
