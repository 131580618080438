import axios, { AxiosRequestConfig } from 'axios';

/* Функция проверки доступных тарифов по региону */
export const getRegionTariffs = async ({
  soc,
  regionId,
  generation,
}: {
  generation: number;
  regionId: string;
  soc: string;
}): Promise<{ isSuccess: boolean; tariffs: any[] }> =>
  (
    await axios.get(
      `/customers/products/home/request/getpresetsup/?regionId=${regionId}&soc=${soc}&generation=${generation}`,
      { 'X-Requested-With': 'XMLHttpRequest' } as AxiosRequestConfig,
    )
  ).data;
