import React, { FC } from 'react';
import { ChevronLeftIcon, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { useSelectContent } from '../../selectors/useSelectContent';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Иконка и текст для кнопки возврата на предыдущий шаг */
export const ButtonContent: FC<React.HtmlHTMLAttributes<HTMLButtonElement>> = (props) => {
  const { backButton } = useSelectContent();
  return (
    <button className={cx('wrapper', 'button-reset')} type="button" {...props}>
      <ChevronLeftIcon />
      <Text className={cx('wrapper__text')} size="size6-r">
        {backButton}
      </Text>
    </button>
  );
};
