import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Tab = ({ id, title, onClick, disabled, selected, isInfinite, isConnectedPeriod }) => {
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        onClick(event);
      }
    },
    [onClick],
  );

  const colorChange = useCallback(
    (activeID, currentID, connectPeriod) => {
      if (connectPeriod && !isInfinite) {
        return 'grey40';
      }
      return activeID === currentID ? 'white' : 'grey95';
    },
    [selected, id, isConnectedPeriod],
  );

  return (
    <div
      className={cx('tab', { 'tab--disabled': disabled }, { 'tab--active': selected === id })}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <Text color={colorChange(selected, id, isConnectedPeriod)} size="size5-r-s">
        {title}
      </Text>
    </div>
  );
};

Tab.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isInfinite: PropTypes.bool,
  isConnectedPeriod: PropTypes.bool,
};
