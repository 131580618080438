import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { Link } from '@beef/ui-kit';

import { Heading } from '../Heading';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export type TContentContainer = {
  btnText: string;
  description: string;
  link: string;
  title: string;
};

/** Контейнер для заголовка, описания и кнопки (ссылки) блока */
export const ContentContainer = memo<TContentContainer>(({ link, title, btnText, description }) => (
  <div className={cx('wrapper')}>
    <Heading {...{ title, description }} />
    {link && (
      <div className={cx('wrapper__link')}>
        <Link buttonSize="s" buttonVariant="tertiary" href={link} isFullWidth variant="button">
          {btnText}
        </Link>
      </div>
    )}
  </div>
));
