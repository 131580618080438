import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import { packageItemShape } from 'pages/ProductCard/Tariff/containers/tariffPropTypes';

import ParametersList from '../ParametersList';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AvailablePackages = ({ packages, title }) => (
  <div>
    {title && (
      <div className={cx('heading')}>
        <Text size="size3-m" tagType="h2">
          {title}
        </Text>
      </div>
    )}
    {packages?.length > 0 && (
      <div className={cx('row')}>
        <ParametersList parameters={packages} />
      </div>
    )}
  </div>
);

AvailablePackages.propTypes = {
  /* заголовок для пакетов */
  title: PropTypes.string,
  /* список доступных пакетов */
  packages: PropTypes.arrayOf(packageItemShape),
};

export default AvailablePackages;
