import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import { BoughtMessage, InCartMessage } from './Message/index';
import { Price } from './Price/index';
import styles from './styles.pcss';

const cx = classnames.bind(styles);

export const Row = ({ isBought, isInCart, onClick, numberNode, priceNode, tagNode }) => {
  const isAvailable = !isBought && !isInCart;
  return (
    <button
      className={cx('row', {
        'row--with-tag': !!tagNode,
        'row--not-available': !isAvailable,
      })}
      onClick={isAvailable ? onClick : undefined}
      type="button"
    >
      <span>{numberNode}</span>
      {isAvailable && (
        <Price buyIconClassName={cx('row_buy-icon')} cardClassName={cx('row_cart')}>
          {priceNode}
        </Price>
      )}
      {isAvailable && tagNode && <div className={cx('row_tag')}>{tagNode}</div>}
      {isInCart && <InCartMessage />}
      {isBought && <BoughtMessage />}
    </button>
  );
};

Row.propTypes = {
  isBought: PropTypes.bool,
  isInCart: PropTypes.bool,
  onClick: PropTypes.func,
  numberNode: PropTypes.node,
  priceNode: PropTypes.node,
  tagNode: PropTypes.node,
};
