import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import { Status } from '@beef/legacy-ui-kit';
import { Text } from '@beef/ui-kit';

// Styles
import { ymPushParams } from 'utils/ym';

import styles from './styles.pcss';

const cn = classNames.bind(styles);

const VoWiFiConnected = ({ statusText, descriptionTitle, descriptionBodyText }) => {
  useEffect(() => {
    ymPushParams({ vowifi: { vowifi_on_v1: 'view_screen' } });
  }, []);
  return (
    <div className={cn('block')}>
      <div className={cn('block__status')}>
        <Status className={['success', cn('block__status-wrapper')]} text={statusText} />
      </div>
      <div className={cn('block__description-wrapper')}>
        <div className={cn('block__title')}>
          <Text size="size4-m">{descriptionTitle}</Text>
        </div>
        <Text color="grey50" size="size6-r">
          {descriptionBodyText}
        </Text>
      </div>
    </div>
  );
};

VoWiFiConnected.propTypes = {
  statusText: PropTypes.string,
  descriptionTitle: PropTypes.string,
  descriptionBodyText: PropTypes.string,
};

export { VoWiFiConnected };
