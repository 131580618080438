import { errorTextContent } from './constants';

export const getErrorStatus = ({
  failedRequest,
  notFilledCtn,
  isExistingCtn,
  isCtnAlreadyInBasket,
}) => {
  if (failedRequest) {
    return errorTextContent.failedRequest;
  }
  if (isExistingCtn) {
    return errorTextContent.isExistingCtn;
  }
  if (notFilledCtn) {
    return errorTextContent.notFilledCtn;
  }
  if (isCtnAlreadyInBasket) {
    return errorTextContent.isCtnAlreadyInBasket;
  }

  return errorTextContent.notSuccess;
};
