import React from 'react';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type ResetButtonPropsType = {
  onClick: () => void;
};

export const ResetButton = ({ onClick }: ResetButtonPropsType) => (
  <button className={cx('reset')} onClick={onClick} type="submit">
    <Text color="inherit" size="size5-m">
      очистить
    </Text>
  </button>
);
