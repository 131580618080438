import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SmartInput } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import { maskPhone } from 'utils/format-string';

import styles from './CtnInput.pcss';

const cx = classNames.bind(styles);

export default class CtnInput extends PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.error) {
      return { ...prevState, ctnShown: false, errorShown: true };
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      ctnShown: false,
      errorShown: false,
      numbers: [],
    };
  }

  getNumbers = (callback) => {
    const { ctnList, smsHistory } = this.props;
    const result = {};

    if (ctnList && ctnList.length > 0) {
      ctnList.forEach((ctn) => {
        if (ctn.ctns && ctn.ctns.length > 0) {
          ctn.ctns.forEach((item) => (result[maskPhone(item.formattedText)] = item.nick));
        } else if (ctn.billsGroups && ctn.billsGroups.length > 0) {
          ctn.billsGroups.forEach((billGroup) => {
            billGroup.ctns.forEach((item) => (result[maskPhone(item.formattedText)] = item.nick));
          });
        } else if (ctn.isMobile && ctn.accountText) {
          result[maskPhone(ctn.accountText)] = ctn.nick;
        }
      });
    }

    Object.keys(smsHistory).forEach((ctn) => (result[ctn] = result[ctn] || null));

    const numbers = Object.keys(result).map((ctn) => ({
      ctn,
      nick: result[ctn],
      count: smsHistory[ctn] || 0,
    }));

    return this.setState({ numbers: numbers.sort((a, b) => b.count - a.count) }, callback);
  };

  setFocus = () => this.input.input.inputElement.focus();

  handleInputBlur = () => {
    setTimeout(() => this.setState({ ctnShown: false }), 100);
  };

  handleInputFocus = () => {
    if (this.state.errorShown) {
      this.setState({ errorShown: false });
    }
    this.getNumbers(() => this.setState({ ctnShown: true }));
  };

  handleNumberClick = (value) => {
    this.props.onChange(maskPhone(value));
    this.setState({ ctnShown: false });
  };

  handleChange = (value) => {
    this.props.onChange(value);
  };

  render() {
    const { ctnShown, errorShown } = this.state;
    const { value, error } = this.props;
    const numbers = this.state.numbers.filter((item) => item.ctn.indexOf(value) > -1);

    return (
      <div className={cx('input')}>
        <div className={cx('label')}>Номер адресата</div>
        <SmartInput
          autoComplete="off"
          filterInput={maskPhone}
          msg={error}
          name="ctn"
          onBlur={this.handleInputBlur}
          onChange={this.handleChange}
          onFocus={this.handleInputFocus}
          ref={(e) => (this.input = e)}
          status={errorShown && error ? 'fail' : ''}
          type="tel"
          value={value}
        />
        <div className={cx('numbers', { shown: ctnShown })}>
          {numbers.map((item, key) => (
            <div
              className={cx('number')}
              key={key}
              onClick={() => this.handleNumberClick(item.ctn)}
            >
              <div className={cx('nick')}>{item.nick}</div>
              <div className={cx('ctn')}>{item.ctn}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

CtnInput.propTypes = {
  ctnList: PropTypes.array,
  value: PropTypes.string,
  smsHistory: PropTypes.object,
  error: PropTypes.string,
  onChange: PropTypes.func,
};
