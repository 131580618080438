import React, { FC } from 'react';
import { CashOutlineIcon, Text } from '@beef/ui-kit';
import classNames from 'classnames/bind';

import { BlockInfoPriceProps } from 'pages/Services/RedirectServiceCardLanding/components/BlockInfo/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);
export const BlockInfoPrice: FC<BlockInfoPriceProps> = ({ priceInfo = {} }) => {
  const { label, value, unit } = priceInfo;
  if (!(label && value && unit)) {
    return null;
  }
  return (
    <div className={cx('price')}>
      <div className={cx('price__icon-wrap')}>
        <CashOutlineIcon className={cx('price__icon')} fill="blue50" />
      </div>
      <Text size="size4-r">{label}</Text>
      <div className={cx('price__right')}>
        <Text size="size4-m">{value}</Text>
        <Text color="grey60" crossOutColor="magenta" size="size5-r-s">
          {unit}
        </Text>
      </div>
    </div>
  );
};
