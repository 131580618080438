import { useEffect } from 'react';
import { shallowEqual } from '@beef/utils';

import {
  useAppDispatch,
  useAppSelector,
} from 'pages/ProductCard/Tariff/containers/ConstructorTariff2023/store';

import { useThrowAsyncError } from '../../../../hooks/useThrowAsyncError';
import { cancelIsAvailableToConnect } from '../../../../store/slices/fttbAddressSlice';
import { setConvergenceDataFromCheckConnection } from '../../../../store/slices/totalSlice/index';
import {
  TCheckConnectionReq,
  TCheckConnectionRes,
  getCheckConnection,
} from '../../../../api/fmcAddressCheck/getCheckConnection';

function checkReadyForCheckConnection(
  res: Partial<TCheckConnectionReq>,
): res is TCheckConnectionReq {
  return Object.keys(res).every(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (key) => res[key] !== undefined && res[key] !== '',
  );
}

/* Сбор полного адреса в одну строку для отображения на странице настройки ШПД */
function getFullConnectionAddress({ city, street, house, flat }: TCheckConnectionReq) {
  return `${city}, ${street}, дом ${house}, квартира ${flat}`;
}

/** Хук проверки возможности подключения по указанному адресу */
export const useCheckConnection = () => {
  const dispatch = useAppDispatch();
  const throwAsyncError = useThrowAsyncError();

  /** Необходимые данные для отправки запроса на проверку. Обязательно с shallowEqual */
  const checkConnectionReq = useAppSelector(
    (state) => ({
      flat: state.fttbAddress.checkConnectionRes.flat,
      city: state.fttbAddress.checkConnectionRes.region,
      house: state.fttbAddress.checkConnectionRes.house,
      street: state.fttbAddress.checkConnectionRes.street,
      houseId: state.fttbAddress.checkConnectionRes.houseId,
      cityId: state.fttbAddress.checkConnectionRes.regionId,
      streetId: state.fttbAddress.checkConnectionRes.streetId,
      isConnected: state.fttbAddress.checkConnectionRes.isConnected,
      checkAddressType: state.fttbAddress.checkConnectionRes.checkAddressType,
      isConvergentRequest: state.fttbAddress.checkConnectionRes.isConvergentRequest,
    }),
    shallowEqual,
  ) as TCheckConnectionReq;

  const userPhoneNumber = useAppSelector((state) => state.total.userPhoneNumber);

  const isReadyForCheckConnection =
    checkReadyForCheckConnection(checkConnectionReq) && userPhoneNumber;

  useEffect(() => {
    let promise: any;
    /* При полном заполнении и наличии телефона диспатчим action c проверкой адреса */
    if (isReadyForCheckConnection) {
      promise = dispatch(getCheckConnection(checkConnectionReq));
      promise
        .unwrap()
        .then((res: TCheckConnectionRes) => {
          dispatch(
            setConvergenceDataFromCheckConnection({
              flat: checkConnectionReq.flat,
              houseId: checkConnectionReq.houseId,
              highSpeedAvailable: res.View.HighSpeedAvailable,
              connectionAddress: getFullConnectionAddress(checkConnectionReq),
            }),
          );
        })
        .catch((e: Error) => throwAsyncError(e));
    } else {
      dispatch(cancelIsAvailableToConnect());
    }
    return () => promise?.abort();
  }, [isReadyForCheckConnection, checkConnectionReq]);
};
