import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';

// Components
import { Preloader, Tab, TabsList } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import AddressForm from './AddressForm';
import PickupForm from './PickupForm';
import ESim from './ESim';

// Styles
import styles from './styles.pcss';

// Actions
import {
  fetchDeliveryInfo,
  getDeliveryFormData,
  openOrderPaymentForm,
  setActiveTab,
  setInputValue,
  updatingCurrentDeliveryDaysData,
} from '../actions/deliveryForm';
import { setOrderPaymentFormData } from '../actions/orderPaymentForm';

const cx = classNames.bind(styles);

class DeliveryForm extends Component {
  state = { submitted: false };

  async componentDidMount() {
    const {
      tariffId,
      constructorId,
      optionSocs,
      tabsContent: { delivery },
      tabsContent,
      timeoutUpdatingDeliveryTypes,
      excludeFastDelivery,
      esim,
      paymentFailPopup,
    } = this.props;

    const params = {
      constructorId,
      optionSocs,
      tariffId,
      esim: !!(esim && tabsContent.esim),
      employeeDeliveryPeriods: delivery.employeeDeliveryPeriods,
      fastDeliveryPeriod: delivery.fastDeliveryPeriod,
      expiredPeriodFastDelivery: delivery.expiredPeriodFastDelivery,
      paymentFailPopupContent: paymentFailPopup,
      excludeFastDelivery,
    };

    await this.props.fetchDeliveryInfo(params).catch(this.props.onError);

    this.props.updatingCurrentDeliveryDaysData(timeoutUpdatingDeliveryTypes);
    this.props.setOrderPaymentFormData({ texts: delivery.orderPaymentFormTexts });
  }

  // Вызывается по ref снаружи. Мне кажется так выглядит чище: не приходится засорять родителя
  // подписками на изменения в форме. Просто на сабмите берем данные не сохраняя их в state родителя
  onSubmit = () => {
    this.setState({ submitted: true });

    if (this.props.deliveryData) {
      return this.props.getDeliveryFormData();
    }

    return {};
  };

  onESIMSubmit = (texts) => {
    this.props.openOrderPaymentForm(texts, this.props.chosenNumber, this.props.tariffId);
  };

  replaceAppButtonLink = (link) => {
    return link.replace('{{MARKET_CODE}}', 'VIP').replace('{{SOC}}', this.props.tariffSoc);
  };

  getTabTitle = (id) => {
    const { tabsContent } = this.props;
    if (id === 'makeRequest') {
      return 'Оставить заявку';
    }

    return tabsContent[id]?.title;
  };

  render() {
    const {
      deliveryData,
      inputValues,
      tabsContent,
      onCancel,
      currentCity,
      loading,
      discountLabel,
      esim,
      centered,
    } = this.props;
    const { submitted } = this.state;

    if (loading) {
      return <Preloader className={cx('preloader')} />;
    }

    if (!loading && !deliveryData) return null;

    const {
      points,
      deliveryDays,
      isFastDeliveryAvailable,
      isEmployeeDeliveryAvailable,
      availableTypes,
      activeTab,
    } = deliveryData;
    const texts = tabsContent[activeTab];

    return (
      <div className={cx({ 'delivery-form--centered': centered })}>
        {availableTypes.length > 1 && (
          <TabsList
            activeTab={activeTab}
            autoScroll
            className={cx('tabs')}
            handleTabClick={this.props.setActiveTab}
            type="monorail"
          >
            {availableTypes.map((id) => (
              <Tab className={cx('tab')} id={id} key={id}>
                {this.getTabTitle(id)}
              </Tab>
            ))}
          </TabsList>
        )}

        <div className={cx('form')}>
          {activeTab === 'pickup' && (
            <PickupForm
              points={points}
              setInputValue={this.props.setInputValue}
              texts={texts}
              {...inputValues}
            />
          )}
          {activeTab === 'delivery' && (
            <AddressForm
              currentCity={currentCity}
              deliveryDays={deliveryDays}
              discountLabel={discountLabel}
              fastDeliveryDisabled={!!this.props.additionalSimCount}
              isEmployeeDeliveryAvailable={isEmployeeDeliveryAvailable}
              isFastDeliveryAvailable={isFastDeliveryAvailable}
              onCancel={onCancel}
              setInputValue={this.props.setInputValue}
              submitted={submitted}
              texts={texts}
              {...inputValues}
            />
          )}
          {activeTab === 'esim' && (
            <ESim
              appStoreButtons={{
                ios: {
                  ...esim.appButtons.ios,
                  link: this.replaceAppButtonLink(esim.appButtons.ios.link),
                },
                android: {
                  ...esim.appButtons.android,
                  link: this.replaceAppButtonLink(esim.appButtons.android.link),
                },
              }}
              esim={esim}
              texts={texts}
            />
          )}
        </div>
      </div>
    );
  }
}

DeliveryForm.defaultProps = { timeoutUpdatingDeliveryTypes: 130000 };

DeliveryForm.propTypes = {
  currentCity: PropTypes.string,
  chosenNumber: PropTypes.string,
  discountLabel: PropTypes.string,
  tariffSoc: PropTypes.string,

  loading: PropTypes.bool,
  excludeFastDelivery: PropTypes.bool,
  centered: PropTypes.bool,

  tariffId: PropTypes.number,
  additionalSimCount: PropTypes.number,
  timeoutUpdatingDeliveryTypes: PropTypes.number,

  tabsContent: PropTypes.object,
  inputValues: PropTypes.object,
  deliveryData: PropTypes.object,
  esim: PropTypes.object,
  paymentFailPopup: PropTypes.object,

  onCancel: PropTypes.func,
  setInputValue: PropTypes.func,
  setActiveTab: PropTypes.func,
  onError: PropTypes.func,
  fetchDeliveryInfo: PropTypes.func,
  getDeliveryFormData: PropTypes.func,
  openOrderPaymentForm: PropTypes.func,
  setOrderPaymentFormData: PropTypes.func,
  updatingCurrentDeliveryDaysData: PropTypes.func,
};

const ConnectedDeliveryForm = connect(
  (state) => ({
    currentCity: state.external.regions?.currentRegion?.title || '',
    ...pathOr({}, ['deliveryForm'], state.external),
    tariffSoc: pathOr({}, ['tariff', 'data', 'soc'], state.external),
    additionalSimCount: pathOr(
      null,
      ['tariffConnectionPopup', 'additionalOptions', 'additionalSimCount'],
      state.external,
    ),
  }),
  {
    fetchDeliveryInfo,
    setInputValue,
    setActiveTab,
    getDeliveryFormData,
    openOrderPaymentForm,
    updatingCurrentDeliveryDaysData,
    setOrderPaymentFormData,
  },
  null,
  { forwardRef: true },
)(DeliveryForm);

export default ConnectedDeliveryForm;
