import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Text } from '@beef/ui-kit';
import { connect } from 'react-redux';
import { compose } from '@beef/redux';
import { withRenderFlag } from '@beef/react';

import { getIsLoading, getTotalHits } from 'pages/SearchResultsPage/store/selectors';
import { textFormsProducts, textFormsSearch } from 'pages/SearchResultsPage/utils/constants';

import { declOfNum } from '../../../utils/declOfNum';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const _TotalHits = memo(({ total }) => {
  const totalHitsText = useMemo(() => {
    const index = declOfNum(total);
    return `${textFormsSearch[index]} ${total} ${textFormsProducts[index]}`;
  }, [total]);

  return (
    <div className={cx('container')}>
      <Text color="grey60" size="size5-r-s" tagType="h1">
        {totalHitsText}
      </Text>
    </div>
  );
});

_TotalHits.propTypes = {
  total: PropTypes.number,
};

const mapStateToProps = (state) => ({
  total: getTotalHits(state),
  shouldRender: !getIsLoading(state),
});

export const TotalHits = compose(connect(mapStateToProps), withRenderFlag)(_TotalHits);
