import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/*
  size - изначальный объем пакета
  available - текущий остаток
  increased - прибавка к пакету
  decreased - удаление из пакета
 */

class Scale extends PureComponent {
  getValues = () => {
    const values = this.props;
    const { empty } = this.props;
    const maxValue = (values.size || this.props.defaultMaxSize) / 100;

    const increased = empty ? 0 : values.increased / maxValue;
    const available = empty ? 0 : (values.available - values.decreased) / maxValue + increased;
    const size = empty ? 0 : (values.size - values.decreased) / maxValue + increased;
    const decreased = values.decreased / maxValue + size;

    this.values = { decreased, available, increased, size };
  };

  getColors = (value) => {
    const { colors } = this.props;
    let i = 0;

    for (; i < colors.length; i++) {
      if (value < colors[i].limit) {
        return colors[i];
      }
    }
    return colors[i - 1];
  };

  render() {
    this.getValues();
    const { className, scaleSize, fixZindex } = this.props;
    const { decreased, size, available, increased } = this.values;
    const colors = this.getColors(available / decreased);
    const width = increased > 0 ? 10000 / scaleSize : 100;

    return (
      <div className={cx('component', { fixZindex }, className)} style={{ width: `${width}%` }}>
        <div className={cx('decreased')} style={{ width: '100%' }} />
        <div className={cx('size')} style={{ width: `${size}%` }} />
        <div
          className={cx('available')}
          style={{
            width: `${available}%`,
            backgroundColor: colors.bg,
          }}
        />
        <div
          className={cx('increased', increased === 0 && 'hidden')}
          style={{
            width: `${increased}%`,
            borderColor: colors.border,
          }}
        />
      </div>
    );
  }
}

Scale.defaultProps = {
  empty: false,
  fixZindex: false,
  decreased: 0,
  size: 0,
  available: 0,
  increased: 0,
  colors: [
    { limit: 0.25, bg: '#db443f', border: '#701411' },
    { limit: 0.6, bg: '#ffdc00', border: '#b89f00' },
    { limit: 1, bg: '#5e9929', border: '#3f671f' },
  ],
};

Scale.propTypes = {
  empty: PropTypes.bool,
  fixZindex: PropTypes.bool,
  defaultMaxSize: PropTypes.number.isRequired,
  scaleSize: PropTypes.number.isRequired,
  colors: PropTypes.array,
  decreased: PropTypes.number,
  size: PropTypes.number,
  available: PropTypes.number,
  increased: PropTypes.number,
};

export default Scale;
