import React from 'react';
import axios from 'axios';
import classnames from 'classnames/bind';

import styles from './styles.module.pcss';

const cx = classnames.bind(styles);

const SEARCH_PAGE_URL = '/customers/search';

const clearHtml = (str) => {
  const div = document.createElement('div');
  div.innerHTML = str;
  return div.textContent || div.innerText || '';
};

export const getOptionHref = (query) => `${SEARCH_PAGE_URL}?groups=help&query=${clearHtml(query)}`;

export const getSearchResults = async (query) => {
  let options = [];
  try {
    const { data } = await axios.get(`/searchsuggestion?boldFont=true&startsWith=${query}`);
    options = data;
  } catch (err) {
    return options;
  }

  const resultOptions = options.View.map((option) => ({
    label: <div dangerouslySetInnerHTML={{ __html: option.Title }} />,
    slug: option.Id,
    href: getOptionHref(option.Title),
  }));

  if (resultOptions.length > 0) {
    resultOptions.push({
      label: <div className={cx('all-results')}>Все результаты</div>,
      slug: 'allResults',
      href: getOptionHref(query),
    });
  }

  return resultOptions;
};
