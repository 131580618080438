import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import axios from 'axios';

// Components
import { Preloader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import Ctx from 'pages-components/Ctx';
import { BenefitsSlider } from 'pages-components/Benefits';
import { useMobileOs } from 'utils/hooks/useIsMobile';
import { pushDataLayer } from 'utils/ga';
import { formatCartTariffCatalogData, onTariffCatalogLoadDataLayer } from 'utils/analytics/cart';

import DigitalTariffsCatalog from './containers/DigitalTariffsCatalog';
import TariffsGrid from './containers/TariffsGrid';

// Hoc's
import TariffsGridHoc from './hocs/TariffsGridHoc';

// Utils / Hooks

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffsCatalog = ({ data }) => {
  const [tariffs, updateData] = useState(data.list);
  const [isTariffsListSent, setIsTariffsListSent] = useState(false);

  useEffect(() => {
    if (data && data.xhrNeeded) {
      axios.get(`${data.tariffApiUrl}ajax/`).then((resp) => {
        updateData(resp.data.list);
      });
    }
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined' || isTariffsListSent) {
      return;
    }

    const tariffsIdList =
      tariffs?.length ? tariffs.map((tariff) => tariff.id).filter((item) => item) : undefined;

    if (tariffsIdList) {
      pushDataLayer({
        tariffIdList: tariffsIdList,
      });

      setIsTariffsListSent(true);
    }

    if (tariffs?.length > 0) {
      onTariffCatalogLoadDataLayer(formatCartTariffCatalogData(tariffs));
    }
  }, [tariffs]);

  const device = useMobileOs() ? 'mobile' : 'desktop';
  const catalogTitle = data.pageTitle || Ctx.tariffsCatalog.heading;

  // Отфильтровываем family-restyled тарифы; После проверяем, есть в отфильтрованом массиве digital-tariff;
  // Если есть, мы выбираем для family-restyled тарифы с типом tariff;
  const familyRestyled = tariffs?.filter((tariff) => tariff.type === 'family-restyled')?.[0];
  const catalogBenefits =
    familyRestyled && !isEmpty(familyRestyled.benefits) ? familyRestyled.benefits : null;

  if (data.xhrNeeded && !tariffs) {
    return (
      <div className={cx('preloader')}>
        <Preloader />
      </div>
    );
  }

  return (
    <>
      <DigitalTariffsCatalog
        catalogTitle={catalogTitle}
        device={device}
        isAuthenticated={data.isAuthenticated}
        subTitle={data.subTitle}
        tariffs={familyRestyled}
      />

      {catalogBenefits && (
        <BenefitsSlider benefits={catalogBenefits} className={cx('benefitsSlider')} />
      )}
      <TariffsGridHoc data={data} tariffs={tariffs}>
        <TariffsGrid />
      </TariffsGridHoc>
    </>
  );
};

TariffsCatalog.propTypes = {
  data: PropTypes.shape({
    tariffApiUrl: PropTypes.string,
    xhrNeeded: PropTypes.bool,
    archiveLink: PropTypes.string,
    pageTitle: PropTypes.string,
    list: PropTypes.array,
    benefits: PropTypes.array,
    instantSimOrderAB: PropTypes.object,
    subTitle: PropTypes.object,
  }),
};

export default TariffsCatalog;
