import { clearState } from '../store/persistor';

/** Функция обновления страницы тарифа с очисткой стейта в sessionStorage */
export const onReloadWithCrash = () => {
  clearState();

  if (typeof window !== 'undefined') {
    window.location.reload();
  }
};
