import { emptyObj } from '@beef/utils';
import { createSelector } from '@reduxjs/toolkit';
import deepmerge from 'deepmerge';

import { INFO_BLOCK_TYPE } from 'pages/FTTB2022/CatalogPage/deprecated/store/blocks/infoCards/constants';

import type { RootState as State, TBlock, TContent, TModulesState, TShared } from './types';

export const getContentData = (state: State): TContent => state.content;
export const getSharedData = (state: State): TShared => state.shared || emptyObj;
export const getModulesData = (state: State): TModulesState => state.modules;

export const getCheckAddressModuleData = (state: State) => getModulesData(state).checkAddress;
export const getInfoCardsModuleData = (state: State) => getModulesData(state).infoCards;
export const getPaymentMethods = (state: State): string[] => getSharedData(state).paymentMethods;

const getIsInfoModalOpen = (state: State): boolean => getInfoCardsModuleData(state).isOpen;
const getIsInfoModalType = (state: State): INFO_BLOCK_TYPE => getInfoCardsModuleData(state).type;
export const getCurrentBannerCard = (state: State) => getInfoCardsModuleData(state).current;

export const getIsSupportModalOpen = (state: State): boolean =>
  getIsInfoModalOpen(state) && getIsInfoModalType(state) === INFO_BLOCK_TYPE.support;

export const getIsPaymentModalOpen = (state: State): boolean =>
  getIsInfoModalOpen(state) && getIsInfoModalType(state) === INFO_BLOCK_TYPE.topUp;

export const getDevicesModuleData = (state: State) => getModulesData(state).devices;

export const getFloatingPhoneWidget = (state: State) => getModulesData(state).floatingPhone;

export const getIsFloatingPhoneWidgetOpen = (state: State): boolean =>
  getFloatingPhoneWidget(state).isOpen;
const getActionBarModulesData = (state: State) =>
  getModulesData(state).actionBarWithBackCall || emptyObj;
export const getIsActionBarOpen = (state: State) => getActionBarModulesData(state).isOpen;

/**
 * Композер корневого селектора блоков, позволяет переопределять любые контентные поля со стороны BackEnd'а
 *   подходит под структуру динамических блоков ШПД
 */
export const createMergedBlockSelector = <T extends TBlock, R>(
  getBlockRoot: TBlockTypeSelector<T>,
) =>
  createSelector(
    (state: State): R => getBlockRoot(state)?.content || emptyObj,
    (state: State): R => getBlockRoot(state)?.data || emptyObj,
    (content, data): R =>
      deepmerge(content, data, {
        arrayMerge: (_, incoming) => incoming,
      }),
  );

export type TBlockTypeSelector<R> = (state: State) => R;
