import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'query-string';
import classNames from 'classnames/bind';
import { Button, Emoji, Heading, Preloader } from '@beef/legacy-ui-kit';

import Icon from 'pages-components/Icon';
import { templateParser, unformatPhone } from 'utils/format-string';
import { getGAClientId, pushOrderEvent } from 'utils/ga';
import { trackCPAEvent } from 'utils/track';

import { FANCY_NUMBER_PATH as endpoint } from '../../constants/Endpoints';
import OrderForm from './OrderForm/OrderForm';
import styles from './SimOrderForm.pcss';

const cx = classNames.bind(styles);

class SimOrderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 'form',
      phone: '+7 ',
      sameRegion: false,
      captcha: '',
      captchaRequired: false,
      serverErrors: {},
      texts: {},
    };
  }

  pushDataLayer = (orderId, price) => {
    const number =
      this.props.includeNumber ?
        {
          ordered_itemCatNumber: 'Случайный номер',
          ordered_itemPodCatNumber: 'Бесплатный номер',
        }
      : {};
    pushOrderEvent({
      orderId,
      price,
      ordered_itemCat: 'Number',
      ordered_itemTitle: this.props.tariffTitle,
      ordered_itemSoc: this.props.tariffAlias,
      ordered_itemMethod: 'JustSim',
      ...number,
    });
    trackCPAEvent({
      soc: this.props.tariffAlias,
      type: 'Number',
      orderId,
      price,
    });
  };

  makeOrder = (region, contactNumber) => {
    const { number, includeNumber } = this.props;
    const { captcha } = this.state;

    return axios
      .post(
        `${endpoint}/makeOrder`,
        qs.stringify({
          number: typeof number === 'string' && includeNumber ? unformatPhone(number) : undefined,
          contactNumber,
          dpcId: this.props.tariffId,
          Captcha: captcha || undefined,
      _captcha_key: captcha ? 'sim-order-form' : undefined, // eslint-disable-line
          gaClientId: getGAClientId(),
        }),
        { withCredentials: true },
      )
      .then((resp) => {
        if (resp.data.IsSuccess) {
          this.setState({ step: 'success' });
          this.pushDataLayer(resp.data.OrderId, resp.data.Price);
          return;
        }

        if (resp.data.IsCaptchaError) {
          this.setState({
            step: 'form',
            captcha: '',
            captchaRequired: true,
            serverErrors: { Captcha: { status: 'fail', msg: 'Введите символы', touched: true } },
          });
          return;
        }

        const texts = {
          message: resp.data.Message,
          headerMessage: resp.data.HeaderMessage,
        };

        this.setState({ step: 'error', texts });
      })
      .catch(() => {
        this.setState({ step: 'error' });
      });
  };

  handleSubmit = () => {
    this.setState({ step: 'loading' });

    const phone = unformatPhone(this.state.phone);
    const regionMeta = document.querySelector('meta[name="REGION_CODE"]');
    const region = regionMeta ? regionMeta.content : 'moskovskaya-obl';

    if (this.state.sameRegion) {
      return this.makeOrder(region, phone);
    }

    return axios
      .get(`${endpoint}/isPhoneBelongToCurrentRegion?phone=${phone}`)
      .then((resp) => {
        if (resp.data.result === true) {
          this.setState({ step: 'form', sameRegion: true });
        } else {
          this.makeOrder(region, phone);
        }
      })
      .catch(() => this.setState({ step: 'error' }));
  };

  handlePhoneInput = (phone) => {
    this.setState({
      sameRegion: false,
      phone,
    });
  };

  handleCaptchaInput = (captcha) => this.setState({ captcha, serverErrors: {} });

  resetState = () => {
    this.setState({
      step: 'form',
      phone: '+7 ',
    });

    const { onReset } = this.props;
    if (typeof onReset === 'function') onReset();
  };

  renderForm() {
    const { hidePopupTitle, convertToSubTitle } = this.props;
    const { title } = this.props.texts;
    const { phone, sameRegion, serverErrors, captchaRequired } = this.state;

    return (
      <div className={cx('container')}>
        {!hidePopupTitle && (
          <Heading
            className={cx('formHeading', convertToSubTitle && 'subTitle')}
            level={convertToSubTitle ? 3 : 1}
          >
            <span
              className={cx('headingText')}
              dangerouslySetInnerHTML={{
                __html: title || 'Сохраним ваш номер<br />при переходе в «билайн»',
              }}
            />
          </Heading>
        )}
        <OrderForm
          captchaRequired={captchaRequired}
          chosenNumber={this.props.number}
          deleveryForm={this.props.deliveryForm}
          errors={serverErrors}
          onCaptchaChange={this.handleCaptchaInput}
          onPhoneChange={this.handlePhoneInput}
          onSubmit={this.handleSubmit}
          phone={phone}
          sameRegion={sameRegion}
        />
      </div>
    );
  }

  renderSuccess() {
    return (
      <div className={cx('successMessage')}>
        <button className={cx('clearButton')} onClick={this.resetState}>
          <Icon name="crossThin" />
        </button>
        <Heading className={cx('formHeading')} level={1}>
          <span className={cx('headingText')}>Супер</span>
          <Emoji className={cx('headingSmile')} name="smile-happy" />
        </Heading>
        <Heading className={cx('formSubHeading')} level={3}>
          Наш специалист свяжется с вами по номеру
          <br />
          {this.state.phone} для уточнения деталей
        </Heading>
        <div className={cx('button')}>
          <Button className="big" onClick={this.resetState} wide>
            Вернуться на сайт
          </Button>
        </div>
      </div>
    );
  }

  renderError() {
    const { number } = this.props;
    const { message, headerMessage } = this.state.texts;
    return (
      <div className={cx('successMessage')}>
        <button className={cx('clearButton')} onClick={this.resetState}>
          <Icon name="crossThin" />
        </button>
        <Heading className={cx('formHeading')} level={1}>
          <span
            className={cx('headingText')}
            dangerouslySetInnerHTML={{ __html: headerMessage || 'Упс, что-то пошло не так' }}
          />
          <Emoji className={cx('headingSmile')} name="smile-sad" />
        </Heading>
        <Heading className={cx('formSubHeading')} level={4}>
          <span
            dangerouslySetInnerHTML={{
              __html: templateParser(
                message ||
                  'При отправке запроса на сервер произошла ошибка,<br />наши специалисты уже работают над этим!',
                { number },
              ),
            }}
          />
        </Heading>
        <div className={cx('button')}>
          <Button className="big" onClick={this.resetState} wide>
            Вернуться на сайт
          </Button>
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className={cx('preloaderWrapper')}>
        <Preloader className={cx('preloader')} />
      </div>
    );
  }

  render() {
    const { className } = this.props;
    let component = null;

    switch (this.state.step) {
      case 'form':
        component = this.renderForm();
        break;
      case 'success':
        component = this.renderSuccess();
        break;
      case 'error':
        component = this.renderError();
        break;
      case 'loading':
        component = this.renderLoading();
        break;
    }

    return <div className={cx('container', className)}>{component}</div>;
  }
}

SimOrderForm.defaultProps = {
  hidePopupTitle: false,
  eventAction: 'Покупка SIM в 1 клик',
  tariffAlias: '',
  tariffTitle: '',
  texts: {},
};

SimOrderForm.propTypes = {
  eventAction: PropTypes.string,
  onReset: PropTypes.func,
  texts: PropTypes.object,
  number: PropTypes.string,
  tariffId: PropTypes.number,
  tariffAlias: PropTypes.string,
  tariffTitle: PropTypes.string,
  includeNumber: PropTypes.bool,
  hidePopupTitle: PropTypes.bool,
  convertToSubTitle: PropTypes.bool,
  deliveryForm: PropTypes.object,
};

export default SimOrderForm;
