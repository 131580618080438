import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { TeaserBanner } from '@beef/smart-kit';

import { TrustPaymentContext } from 'pages/Services/TrustPayment/context';

import { BannerDetailsNode } from '../BannerDetailsNode';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Banner = () => {
  const {
    content: { banner },
  } = useContext(TrustPaymentContext);

  return (
    <div className={cx('container')}>
      <TeaserBanner {...banner} detailsNode={<BannerDetailsNode />} />
    </div>
  );
};
