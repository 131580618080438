import { cloneElement } from 'react';
import { connect } from 'react-redux';

import { TapsAnalyticsPusher } from 'pages/SearchResultsPage/utils/analytics';
import { useOnScreenEffect } from 'utils/hooks/useOnScreenEffect';
import { getPlaceForAnalytics } from 'pages/SearchResultsPage/store/selectors';

export const _TapWithAnalytics = ({ children, location }) => {
  TapsAnalyticsPusher.location = location;
  const nodeRef = useOnScreenEffect(() => {
    TapsAnalyticsPusher.pushViewEvent(children.props.tap);
  }, []);

  const handleClick = (e) => {
    children.props.onClick?.(e);
    TapsAnalyticsPusher.pushClickEvent(children.props.tap);
  };

  return cloneElement(children, { onClick: handleClick, nodeRef });
};

export const TapWithAnalytics = connect((state) => ({ location: getPlaceForAnalytics(state) }))(
  _TapWithAnalytics,
);
