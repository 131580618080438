import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { axiosBeelineRequest } from '@beef/utils';

import { useForkRef } from 'pages/CarnicaDSLandings/VirtualAssistant/hooks/useForkRef';

import { useLottie } from './hooks/useLottie';
import { useLottieInteractivity } from './hooks/useLottieInteractivity';
import { LottieComponentProps } from './types';

export const LottiePlayer = forwardRef<HTMLDivElement, LottieComponentProps>((props, ref) => {
  const {
    animationData: animationDataProp,
    animationLink,
    className,
    interactivity,
    lottieRef,
    renderParams,
    style,
    ...lottieProps
  } = props;

  const [animationData, setAnimationData] = useState<Record<string, unknown> | undefined>(
    animationDataProp,
  );

  const wrapperRef = useRef<HTMLDivElement>(null);

  const loadAnimation = async (path: string): Promise<void> => {
    try {
      const data = await axiosBeelineRequest<Record<string, unknown>>({
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
        url: path,
      });
      setAnimationData(data);
    } catch {
      setAnimationData(undefined);
    }
  };

  useEffect(() => {
    if (animationLink) {
      // eslint-disable-next-line no-void
      void loadAnimation(animationLink);
    }
  }, [animationLink]);

  const animationParams = useLottie(wrapperRef, {
    animationData,
    height: style?.height,
    width: style?.width,
    ...lottieProps,
  });

  useEffect(() => {
    if (lottieRef) {
      lottieRef.current = animationParams;
    }
  }, [lottieRef?.current]);

  useLottieInteractivity({
    actions: interactivity?.actions ?? [],
    lottieObj: animationParams,
    mode: interactivity?.mode ?? 'scroll',
    wrapperRef: interactivity?.wrapperRef,
  });

  const nodeRef = useForkRef([ref, wrapperRef]);

  return <div className={className} ref={nodeRef} style={style} {...renderParams} />;
});
