import { withDataConverter, withMountListener } from '@beef/react';
import { compose } from '@beef/redux';
import classNames from 'classnames/bind';
import React, { memo, useCallback } from 'react';

import { withObservableState } from 'utils/hocs/withObservableState';

import { handleYandex } from './actions';
import { converter } from './converter';
import { PowersContainer } from './PowersContainer';
import { TariffUpMetaData } from './store/TariffUpMetaData';
import styles from './styles.pcss';
import { ModalContainer } from './TabModal';
import { useModalActions } from './TabModal/hooks/useModalActions';
import { TabsContainer } from './TabsContainer';
import { EPowerType, TCharactersTabsProps } from './types';
import { YandexSubscriptionModals } from './YandexModal';

const cx = classNames.bind(styles);

const _CharactersTabs: React.FC<Partial<TCharactersTabsProps>> = (props) => {
  const {
    tabs = [],
    title = '',
    onClick,
    content = {},
    isChangeTariff,
    activeOptionSoc = '',
    powers: _powers,
  } = props;

  const { activeColor, isYandex = false, powers = [] } = _powers || {};

  const {
    isOpen,
    handleModal,
    content: popupContent,
  } = useModalActions(activeOptionSoc, content.popups);

  const _handleModal = useCallback(
    async (id?: string) => {
      const shouldShowYandexPopup = id === EPowerType.YandexPower && isYandex;

      if (shouldShowYandexPopup) {
        await handleYandex();
      } else {
        handleModal(id);
      }
    },
    [isYandex, handleModal],
  );

  return (
    <div className={cx('wrapper')}>
      <TabsContainer
        activeOptionSoc={activeOptionSoc}
        isChangeTariff={isChangeTariff}
        onClick={onClick}
        tabs={tabs}
        title={title}
      />
      <PowersContainer activeColor={activeColor} handleModal={_handleModal} powers={powers || []} />
      <ModalContainer content={popupContent} handleModal={handleModal} isOpen={isOpen} />
      <YandexSubscriptionModals />
    </div>
  );
};

export const CharactersTabs = compose<typeof _CharactersTabs>(
  withObservableState(TariffUpMetaData.ObservableState),
  withDataConverter(converter),
  withMountListener,
  memo,
)(_CharactersTabs);
