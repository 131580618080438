import { createSelector } from '@reduxjs/toolkit';

import { selectTariffSettings } from 'constructorV1/store/selectors/selectTariffSettings';
import { selectGiftTileIcon } from 'constructorV1/components/tempComponents/GiftContainer/selectors/selectGiftTileIcon';

/** Селектор выборки подарков */
export const selectGifts = createSelector(
  selectTariffSettings,
  selectGiftTileIcon,
  (tariffSettings, icon) => tariffSettings?.bonuses?.map((item) => ({ ...item, icon })),
);
