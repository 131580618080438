import React, { PureComponent } from 'react';
import { Loader } from '@beef/legacy-ui-kit';
import classNames from 'classnames/bind';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export default class ConnectingPopup extends PureComponent {
  state = { dots: '' };

  intervalId = null;

  componentDidMount() {
    this.intervalId = setInterval(
      () =>
        this.setState({
          dots: this.state.dots.length > 2 ? '' : `${this.state.dots}.`,
        }),
      400,
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    return (
      <div className={this.props.className}>
        <Loader status="active" />
        <div className={cx('text')}>
          Идет обмен
          <span>{this.state.dots}</span>
        </div>
      </div>
    );
  }
}
