import React, { FC } from 'react';

type AnchorTypes = {
  /** id for the anchor */
  anchorId: string;
  /** Top offset for the scroll event */
  scrollMarginTop?: number;
};

/**
 * Якорь, позволяющий подскролливать с учетом высоты шапки
 * или указанной высоты методом window.scrollIntoView
 * @param props ({ anchorId: string, scrollMarginTop?: number })
 */
export const Anchor: FC<AnchorTypes> = ({ anchorId, scrollMarginTop }) => {
  // @ts-expect-error ts config doesn't know about document
  const headerHeight =
    typeof document !== 'undefined' ? document.querySelector('header')?.offsetHeight : 0;

  return <span id={anchorId} style={{ scrollMarginTop: scrollMarginTop || headerHeight }} />;
};
