import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { startsWith } from 'ramda';

// Components
import Station from '../StationComponent';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Line = ({ name, color, selectedStation, inputActive, stations }) => {
  const [active, setActive] = useState(false);
  const [visible, setVisibility] = useState(true);

  const verifiedInput = stations.some((station) =>
    startsWith(selectedStation, station.name.toLowerCase()),
  );

  const handleForm = () => {
    setActive(inputActive && verifiedInput);
    setVisibility(inputActive ? verifiedInput : true);
  };

  useEffect(handleForm, [selectedStation, inputActive]);

  return (
    <>
      {visible && (
        <div className={cx('line')}>
          {color && <span className={cx('point')} style={{ backgroundColor: color }} />}
          {name && (
            <span
              className={cx('lineName', { active })}
              dangerouslySetInnerHTML={{ __html: name }}
              onClick={() => setActive(!active)}
              role="button"
              tabIndex="0"
            />
          )}

          {active && (
            <div className={cx('stationContainer')}>
              {stations?.map((station) => (
                <Station
                  inputActive={inputActive}
                  key={`${name}-${station.name}`}
                  lineActive={active}
                  selectedStation={selectedStation}
                  {...station}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

Line.defaultProps = {
  name: '',
  selectedStation: '',
  stations: [],
};

Line.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  selectedStation: PropTypes.string,
  stations: PropTypes.arrayOf(PropTypes.object),
  inputActive: PropTypes.bool,
};

export default Line;
