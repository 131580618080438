import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

// Components
import { Text } from '@beef/ui-kit';

import { TotalUnitContainer } from './TotalUnitContainer';

// Selectors
import { getTariffDetailsTotalText } from '../../../../store/selectors/modal';

// Styles
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const TariffTotalLayout = ({ title }) => (
  <div className={cx('wrapper')}>
    {title && <Text size="size4-m">{title}</Text>}
    <TotalUnitContainer />
  </div>
);

const mapStateToProps = (state) => ({
  title: getTariffDetailsTotalText(state),
});

TariffTotalLayout.propTypes = {
  title: PropTypes.string,
};

export const TariffTotalContainer = connect(mapStateToProps)(TariffTotalLayout);
