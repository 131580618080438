import { connect } from 'react-redux';

import { selectContent } from '../../store/selectors';
import { HeaderScrollDown } from '../HeaderScrollDown';
import { STORIES_LIST_ALIAS } from '../../constants';

export const HeadingResults = connect((state) => ({
  ...selectContent(state).heading,
  scrollToId: STORIES_LIST_ALIAS,
}))(HeaderScrollDown);
