import React, { useCallback, useState } from 'react';
import { Modal, Notification } from '@beef/ui-kit';
import { RequestStatus } from '@beef/smart-kit';
import PropTypes from 'prop-types';

import {
  composeActionButtonText,
  composeTrustServiceUrl,
} from 'pages/Services/ZeroBalanceSupport/utils';
import { useUserBlockedInfo } from 'pages/Services/ZeroBalanceSupport/hooks/useUserBlockedInfo';
import { ModalActionBar } from 'pages/Services/ZeroBalanceSupport/Component/ModalActionBar';

export const ServiceNotification = ({ isConnected, requestStatus, status }) => {
  const [isOpen, setIsOpen] = useState(false);
  const blockedInfo = useUserBlockedInfo();
  const blocked = !isConnected && blockedInfo;
  const handleButtonClick = useCallback(() => setIsOpen(status?.color === 'grey'), [status?.color]);

  return status ?
      <>
        <Notification
          buttonText={composeActionButtonText(status.color, blocked)}
          href={composeTrustServiceUrl(status.color, blocked)}
          onClick={handleButtonClick}
          shape="square"
          text={status.text}
          variant={status.color === 'black' ? 'primary' : 'secondary'}
        />
        <Modal
          actionBar={
            <ModalActionBar
              confirmButton={requestStatus?.button}
              onConfirm={() => setIsOpen(false)}
            />
          }
          isCentered
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          size="s"
        >
          <RequestStatus description={requestStatus?.description} title={requestStatus?.title} />
        </Modal>
      </>
    : null;
};

ServiceNotification.propTypes = {
  isConnected: PropTypes.bool,
  requestStatus: PropTypes.func,
  status: PropTypes.shape({
    additionalText: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
    name: PropTypes.string,
  }),
};
