import { createAction } from '@reduxjs/toolkit';

// Modal Actions
export const setIsSettled = createAction('SET_IS_SETTLED');
export const setCurrentStep = createAction('SET_CURRENT_STEP');
export const setLoadingStatus = createAction('SET_IS_LOADING');
export const setCallBackStatus = createAction('SET_IS_CALLBACK');
export const setIsFetchRegion = createAction('SET_IS_FETCH_REGION');
export const setBackToPreviousStep = createAction('SET_PREVIOUS_STEP');
export const setButtonText = createAction('SET_CURRENT_STEP_BUTTON_TEXT');
export const callBackRequestLoading = createAction('SET_CALLBACK_STATUS_REQUEST');
export const setSecondButtonText = createAction('SET_CURRENT_STEP_SECOND_BUTTON_TEXT');
