import { shallowEqual } from 'react-redux';

import { useAppSelector } from '../../../../store';
import { breakdownPrice } from '../../../../utils/breakdownPrice';
import { getOptionsCountSum } from '../../../../utils/getOptionsCountSum';
import { useFmcCountPrice } from './useFmcCountPrice';

/** Подсчет общей суммы абонентной платы по тарифу (пресеты, опции) */
export const useCountBillSum = () => {
  /* Высчитываем стоимость ШПД */
  const { convergencePrice, convergenceOldPrice } = useFmcCountPrice();

  /* Получаем данне из стора */
  const { options, optionsSoc, presetsPrice, presetsOldPrice } = useAppSelector(
    (state) => ({
      options: state.options,
      optionsSoc: state.total.optionsSoc,
      presetsPrice: state.total.presetsPrice,
      presetsOldPrice: state.total.presetsOldPrice,
    }),
    shallowEqual,
  );

  /* Подсчитываем общую стоимость старой и новой цены по опциям */
  const { optionsPrice, optionsOldPrice } = getOptionsCountSum(options, optionsSoc);

  /* Переменные для хранения сумм актуальных и старых цен */
  const priceSum = Number(presetsPrice) + Number(optionsPrice) + convergencePrice;
  const oldPriceSum = Number(presetsOldPrice) + Number(optionsOldPrice) + convergenceOldPrice;

  /* Суммируем данные по выбранным пресетам и опциям (новая цена) */
  const totalPrice = breakdownPrice(priceSum);

  /* Проверяем, что старая цена (пресет + опция), больше текущей */
  const isOldPriceMorePrice = oldPriceSum > priceSum;

  /* Суммируем данные по выбранным пресетам и опциям (старя цена) */
  const totalOldPrice = isOldPriceMorePrice ? breakdownPrice(oldPriceSum) : undefined;

  return { price: totalPrice, oldPrice: totalOldPrice };
};
