/**
 *@description хук подготовки данных для кастомного попапа в конструкторах;
 */

const usePrepareContent = ({
  content,
  tariffName,
  refundsSum,
  connectionFee,
  discountOffText,
  totalAmountUnit,
  notificationText,
  singlePaymentFee,
  singlePaymentText,
  changePresetRcRate,
  refusalRefundsDescription,
}) => {
  const data =
    refundsSum ?
      content.popupContent?.popupCashBackConstructor
    : content.popupContent?.popupDefaultConstructor;

  const price =
    content?.chargePresetAmount ? +content?.price + content?.chargePresetAmount : content?.price;
  const currentPrice =
    (singlePaymentText && singlePaymentFee) || connectionFee || (changePresetRcRate ?? price);
  const isRefusalRefundsSave = refusalRefundsDescription && Number(currentPrice) === 0;
  const priceBtn = isRefusalRefundsSave ? currentPrice : `${currentPrice} ${totalAmountUnit}`;
  const btnText = isRefusalRefundsSave ? data?.btnSave : data?.btnText;

  const title = `${data?.title} ${tariffName}`;

  const description =
    singlePaymentText ||
    refusalRefundsDescription ||
    (refundsSum ?
      `${data?.description} ${refundsSum.toFixed(2)} ${totalAmountUnit}`
    : data?.description);

  const subDescription = data?.subDescription;

  const discountText = content.showDiscountOffText && discountOffText && discountOffText;
  const notification = notificationText && content?.showNotificationText && notificationText;
  const showDescriptionLayout = singlePaymentText || description || notification || discountText;

  return {
    title,
    btnText,
    priceBtn,
    description,
    discountText,
    notification,
    subDescription,
    showDescriptionLayout,
  };
};

export { usePrepareContent };
