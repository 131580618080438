import React from 'react';
import { Tabs } from '@beef/ui-kit';
import { connect } from 'react-redux';

import {
  selectCurrentRegionList,
  selectCurrentStoriesList,
  selectRegionsList,
  setCurrentRegionList,
} from '../../store/stories';
import { openStoryFromList } from '../../store/story';
import { pushStoryAction } from '../../utils/analytics';
import { StoryCard } from '../StoryCard';
import { StoriesListLayout } from './StoriesListLayout';

const StoriesListComponent = ({ onSetGroup, groups, currentGroup, stories, onStoryClick }) => (
  <StoriesListLayout
    tabsNode={
      <Tabs
        isScrollable
        onChange={onSetGroup}
        selectedTab={currentGroup}
        tabs={groups}
        variant="secondary"
      />
    }
  >
    {stories.map((story) => (
      <StoryCard
        image={story.image}
        key={story.id}
        onClick={() => onStoryClick(story)}
        text={story.city}
        title={story.author}
      />
    ))}
  </StoriesListLayout>
);

const mapStateToProps = (state) => ({
  groups: selectRegionsList(state),
  currentGroup: selectCurrentRegionList(state),
  stories: selectCurrentStoriesList(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSetGroup: (id) => dispatch(setCurrentRegionList(id)),
  onStoryClick: (story) => {
    dispatch(openStoryFromList(story.id));
    pushStoryAction('read_story', story);
  },
});

export const StoriesList = connect(mapStateToProps, mapDispatchToProps)(StoriesListComponent);
