import React from 'react';
import PropTypes from 'prop-types';

import { templateParser } from 'utils/format-string';
import { throwAsyncError } from 'utils/errors';

const TextWithRegion = (props) => {
  const { tagName: Tag = 'span', className, currentRegion = {}, text, ...otherProps } = props;

  if (!currentRegion.titlePreposition && currentRegion.title) {
    throwAsyncError(
      `Отсутствует titlePreposition для региона ${currentRegion.title}, id=${currentRegion.id}`,
    );
  }

  return (
    <Tag
      className={className}
      dangerouslySetInnerHTML={{
        __html: templateParser(text, {
          regionNamePreposition: currentRegion.titlePreposition || currentRegion.title,
        }),
      }}
      {...otherProps}
    />
  );
};

TextWithRegion.propTypes = {
  tagName: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  currentRegion: PropTypes.shape({
    titlePreposition: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default TextWithRegion;
