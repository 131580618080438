import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { useIsDesktopDimension } from '@beef/ui-kit';

import { Metric } from 'pages/CarnicaDSLandings/MNPLanding/analytics';

import styles from './styles.pcss';
import { useListBlock } from './hooks/useListBlock';
import { CardForm } from '../../../../components/CardForm';

const cx = classNames.bind(styles);

/**
 * Карточка со списком условий для
 * упрощенного переноса номера
 */
export const ListBlock: FC = () => {
  const { icon, list, title } = useListBlock();
  const isDesktop = useIsDesktopDimension();

  return (
    <Metric data={title} events={{ yaMNPMetric: ['handleViewListBlock'] }} mode="viewport">
      <CardForm
        style={isDesktop ? { backgroundImage: `url(${icon})` } : {}}
        wrapperClassName={cx('card')}
      >
        <div className={cx('wrapper')}>
          <Text className={cx('title')} variant="headline-small">
            {title}
          </Text>

          <ul className={cx('list')}>
            {list.map(({ id, text }) => (
              <Text key={id} tagName="li" variant="body-small">
                {text}
              </Text>
            ))}
          </ul>
        </div>
      </CardForm>
    </Metric>
  );
};
